import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { useTranslation } from "react-i18next";
import UNMemberSwitch from "../../Charts/UNMemberSwitch";
import LoadingBar from "../../ui/LoadingBar";
import { cArea1BarChartRegions, cArea2Treaties } from "../../../../config/constants";
import { isRecommendation } from "../../../../lib/fnAreasPRHelper";

export default function LineChart({ references }) {
  const [unMember, setUnMember] = useState(true);
  const { t, i18n } = useTranslation();
  const loading = useRef();
  const loadChart = () => {
    if (loading.current) loading.current.style.display = "block";
    am4core.options.queue = false;

    /* Chart code */
    // Themes begin
    am4core.unuseAllThemes();

    let treatyBodies = cArea2Treaties().filter(tb => !tb.op);

    // Themes end

    // Create chart instance
    let chart = am4core.create("lineChartDiv", am4charts.XYChart);

    // Themes begin

    chart.events.on("ready", () => {
      if (loading.current) loading.current.style.display = "none";
    });
    // Themes end
    chart.maskBullets = false;
    // Create chart instance

    // Let's cut a hole in our Pie chart the size of 40% the radius
    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.dateFormats.setKey("day", "YYYY");
    dateAxis.dateFormats.setKey("week", "YYYY");
    dateAxis.dateFormats.setKey("month", "YYYY");
    dateAxis.dateFormats.setKey("year", "YYYY");
    //dateAxis.gridIntervals.setAll([{ timeUnit: "year", count: 1 }]);
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    //valueAxis.max = 70;

    let seriesArray = [];
    let i = 0;

    for (let tb of treatyBodies) {
      seriesArray.push(createSeries(tb, tb.name, i));
      i++;
    }

    // Create series
    function createSeries(s, name, i) {
      let serie = new am4charts.LineSeries();
      var series = chart.series.push(serie);
      series.dataFields.valueY = s.id;
      series.dataFields.dateX = "date";
      series.name = name;
      series.fill = am4core.color(treatyBodies[i].color);
      series.stroke = treatyBodies[i].color;

      var segment = series.segments.template;
      segment.interactionsEnabled = true;

      var hoverState = segment.states.create("hover");
      hoverState.properties.strokeWidth = 3;

      var dimmed = segment.states.create("dimmed");
      dimmed.properties.stroke = am4core.color("#dadada");

      var data = [];

      let eArray = {};
      let valueArray = {};

      for (let s of treatyBodies) {
        eArray[s.id] = [];
        valueArray[s.id] = 0;
      }
      console.log(s);
      let acc = 0;
      for (let i = 1965; i <= new Date().getFullYear(); i++) {
        eArray[s.id] = references.filter(
          r => r.treaty_body.id == s.id && r.ras && r.ras.slice(0, 4) == i && !r.motherEntry.subjurisdiction
        );

        valueArray[s.id] = acc + eArray[s.id].length;
        acc = valueArray[s.id];
        var dataItem = { date: new Date(i, 1, 1) };

        if (eArray[s.id].length) {
          dataItem["" + s.id] = valueArray[s.id];
        }

        if (!data.length || data[data.length - 1][s.id] !== dataItem["" + s.id] || new Date().getFullYear() === i)
          data.push({
            ...dataItem,
            yearsText: "" + i,
            color: "#AB97CE",
            disabled: false
          });
      }

      series.data = data;
      series.strokeWidth = "3";
      series.tensionX = 1;

      series.tooltipHTML = `
      
      <strong>{yearsText} - {name}: {valueY} ratifications</strong>`;

      series.tooltip.label.padding(10, 10, 10, 10);
      series.tooltip.label.maxWidth = 350;
      series.tooltip.label.wrap = true;

      series.tooltip.getFillFromObject = true;
      //series.tooltip.background.fill = am4core.color("#CEB1BE");

      let bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.strokeWidth = 2;
      bullet.circle.radius = 4;
      bullet.circle.fill = am4core.color("#fff");

      let bullethover = bullet.states.create("hover");
      bullethover.properties.scale = 1.3;
      bullet.propertyFields.disabled = "disabled";

      return serie;
    }

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.scrollable = true;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.snapToSeries = seriesArray;
  };
  useEffect(() => {
    loadChart();
  }, [unMember]);
  return (
    <div>
      <LoadingBar background="#f4f8f8" ref={loading} text={t("Loading chart") + "..."} />
      <div id="lineChartDiv" style={{ minHeight: "450px" }} />
    </div>
  );
}
