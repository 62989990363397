export const introText = (
  <>
    <p>
      Constitutions are the legal texts that collect the most fundamental legal principles of any given State. They
      usually set the organisational basis of the government and establish general rules that laws and regulations
      cannot contravene. Additionally, most constitutions contain a list of fundamental rights and non-discrimination
      provisions.
    </p>
    <p>
      A few States have explicitly included “sexual orientation”, “gender identity” and “gender expression” into their
      non-discrimination clauses to protect people against discrimination. This also means that the entire legal
      framework should abide by that legal principle. However, this is not always the case.
    </p>
    <p>
      Even if local courts can expansively interpret equality provisions, thus triggering protection in State practice
      and in law, in the following list, only those constitutions that spell out these terms in an unambiguous way are
      listed.
    </p>
  </>
);

export const methodologyText = [
  <>
    <div>
      <p>
        <span style={{ fontWeight: 400 }}>
          Three sections cover the different levels of legal protection against discrimination based on sexual
          orientation which we have chosen to focus on in this report, namely: (1) constitutional protection, (2) broad
          protection, and (3) employment protection. For the country to be included in each of these sections, the
          relevant legal basis or authority must explicitly mention sexual orientation (or any equivalent terms, such as
          “sexual preference”, “homosexual orientation”, or “sexual option”).
        </span>
      </p>
      <p>
        These three categories are the only three that follow a rough hierarchical pattern, according to which
        “constitutional protection” is considered the highest level of protection, “broad protection” as the immediate
        next, and “employment protection” as the narrowest of the three. All countries that appear in the
        “constitutional” section appear in both “broad” and “employment” protection sections. This order of precedence
        reflects the hierarchy of laws within the legal frameworks that adopt a written constitution, in that
        constitutional provisions are expected frame and guide the drafting of all other norms of inferior hierarchy. In
        other words, if the constitution prohibits discrimination based on sexual orientation, in theory no legal
        provision in that country can discriminate based on sexual orientation. However, it must be noted that, in
        practice, this is not always the case. The most salient examples that can be cited are the constitutions of
        Bolivia and Ecuador, which prohibit discrimination based on sexual orientation but at the same time restrict the
        right to legal protection for same-sex couples and adoption by same-sex couples, respectively. This legal
        collision is possible as well between the constitution and discriminatory laws that remain unchallenged on the
        books. Additionally, in many countries formal laws are required to implement the rights enshrined in the
        constitution and when no specific action is taken to enact these laws, a constitutional clause may end up being
        a mere expression of desire rather than an enforceable provision (oftentimes referred to as “justiciable
        clauses” as opposed to “programmatic provisions”). For all these reasons, the hierarchy of the legal provisions
        should not always be understood as a stronger or more robust protection. Assessing the effectiveness of the
        protection of each of the legal provisions in this report goes well beyond its scope and would require in-depth
        research at a scale that is unfeasible when covering all 193 UN Member States and more than 45 non-UN member
        jurisdictions. The “broad protection” category includes explicit legal protections against discrimination based
        on sexual orientation in health, education, housing and the provision of goods and services. For a country to be
        included in the main chart and counted as offering “broad” protection, it must provide protection against
        discrimination in at least three (3) different areas (including in employment). Those that have some level of
        protection, but do not accomplish the “three-areas criterion” are included in the “Is there more?” entries. As a
        separate section is dedicated to it, employment protection is not mentioned under this section. With notable
        exceptions, employment protection is regularly among the first protective measures to be enshrined in
        legislation.19 As of December 2020, all 57 UN Member States offering “broad protection” against discrimination
        based on sexual orientation also ensure employment protection, and 24 more offer employment protection only.
        Hence, 81 countries are reported as offering employment protection in the relevant category. ILGA World’s map
        additionally features a fourth category labelled “Limited/Uneven protection”. This category is explained in
        detail in Section 7 below.
      </p>
      <p>&nbsp;</p>
    </div>
  </>
];
