import React from 'react';

const UserCircle = ({size}) => <svg xmlns="http://www.w3.org/2000/svg" style={{width: size,height: size}} viewBox="0 0 20.747 20.55">
        <g id="Group_395" data-name="Group 395" transform="translate(-1533.711 -50.579)">
            <path id="Union_1" data-name="Union 1" d="M70.413,4078.549h-.04a10.379,10.379,0,1,1,.28,0c-.071,0-.143,0-.215,0ZM62,4068.274a8.194,8.194,0,0,0,2.292,5.683A6.292,6.292,0,0,1,69.67,4070h1.537a6.613,6.613,0,0,1,5.46,3.726,8.186,8.186,0,0,0,2.08-5.452,8.374,8.374,0,0,0-16.747,0Z" transform="translate(1473.711 -4007.421)" fill="#79a3a8"/>
            <circle id="Ellipse_266" data-name="Ellipse 266" cx="3.5" cy="3.5" r="3.5" transform="translate(1540.711 54.579)" fill="#79a3a8"/>
        </g>
    </svg>


export default UserCircle;