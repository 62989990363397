import i18n from '../config/i18n'
import dayjs from 'dayjs'

export const i18ndayjs = (date, format) => {
  let newFormat = format
  let posProcessing = e => {
    return e
  }
  if (i18n.language === 'es') {
    require('dayjs/locale/es')
    dayjs.locale('es')
    if (format === 'MMM YYYY')
      posProcessing = s => s && s[0].toUpperCase() + s.slice(1)

    if (format === 'MMMM YYYY')
      posProcessing = s => s && s[0].toUpperCase() + s.slice(1)

    if (format === 'DD MMMM YYYY') newFormat = 'DD [de] MMMM [de] YYYY'
    if (format === 'D MMMM YYYY') newFormat = 'D [de] MMMM [de] YYYY'

    if (format === 'MMMM [es_de] YYYY') newFormat = 'MMMM [de] YYYY'
  } else {
    if (format === 'MMMM [es_de] YYYY') newFormat = 'MMMM YYYY'
    dayjs.locale('en')
  }
  return posProcessing(dayjs(date).format(newFormat))
}
