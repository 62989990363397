var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [101.6559, 2.8808],
            [101.6435, 2.9418],
            [101.7036, 2.9392],
            [101.7248, 2.9074],
            [101.7222, 2.8782],
            [101.6665, 2.8649],
            [101.6559, 2.8808]
          ]
        ]
      },
      properties: {
        name: 'Putrajaya',
        id: 'MY-16',
        CNTRY: 'Malaysia',
        TYPE: 'Federal Territory'
      },
      id: 'MY-16'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [115.2399, 5.3861],
            [115.2342, 5.3453],
            [115.2665, 5.2837],
            [115.2436, 5.2705],
            [115.1561, 5.2652],
            [115.1599, 5.2922],
            [115.1944, 5.3535],
            [115.2399, 5.3861]
          ]
        ]
      },
      properties: {
        name: 'Labuan',
        id: 'MY-15',
        CNTRY: 'Malaysia',
        TYPE: 'Federal Territory'
      },
      id: 'MY-15'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [101.7323, 3.0378],
            [101.7028, 3.03],
            [101.6532, 3.0435],
            [101.6426, 3.0824],
            [101.6028, 3.1328],
            [101.6028, 3.1567],
            [101.6384, 3.2198],
            [101.704, 3.2144],
            [101.7266, 3.2314],
            [101.7522, 3.2015],
            [101.7394, 3.1214],
            [101.754, 3.1008],
            [101.7503, 3.0544],
            [101.7323, 3.0378]
          ]
        ]
      },
      properties: {
        name: 'Kuala Lumpur',
        id: 'MY-14',
        CNTRY: 'Malaysia',
        TYPE: 'Federal Territory'
      },
      id: 'MY-14'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [110.3367, 1.8067],
              [110.3556, 1.7589],
              [110.3533, 1.7189],
              [110.3722, 1.705],
              [110.3771, 1.6602],
              [110.3517, 1.6463],
              [110.3445, 1.7039],
              [110.302, 1.7372],
              [110.3367, 1.8067]
            ]
          ],
          [
            [
              [114.1281, 4.587],
              [114.1725, 4.5716],
              [114.196, 4.5411],
              [114.2372, 4.5295],
              [114.3086, 4.4173],
              [114.3054, 4.3772],
              [114.3288, 4.3524],
              [114.3178, 4.276],
              [114.35, 4.2642],
              [114.3779, 4.2774],
              [114.4125, 4.2665],
              [114.4539, 4.2776],
              [114.4598, 4.2334],
              [114.5003, 4.1578],
              [114.5357, 4.1537],
              [114.5806, 4.0828],
              [114.6375, 4.0622],
              [114.6388, 4.0222],
              [114.6794, 4.0349],
              [114.7483, 4.084],
              [114.8518, 4.2521],
              [114.8269, 4.2835],
              [114.8605, 4.3218],
              [114.8866, 4.3741],
              [114.888, 4.4233],
              [114.8309, 4.4541],
              [114.827, 4.5548],
              [114.8129, 4.6727],
              [114.7912, 4.7089],
              [114.7948, 4.7464],
              [114.8489, 4.7672],
              [114.864, 4.8054],
              [114.9762, 4.8368],
              [115.0068, 4.895],
              [115.0335, 4.8805],
              [115.0063, 4.8482],
              [115.0429, 4.8079],
              [115.0651, 4.6196],
              [115.078, 4.5984],
              [115.1107, 4.4924],
              [115.1106, 4.4054],
              [115.2105, 4.376],
              [115.2493, 4.3789],
              [115.2621, 4.3464],
              [115.2932, 4.3503],
              [115.3394, 4.3115],
              [115.3749, 4.3357],
              [115.3512, 4.391],
              [115.2983, 4.4367],
              [115.2807, 4.5256],
              [115.2959, 4.5823],
              [115.2959, 4.6421],
              [115.2691, 4.6642],
              [115.2606, 4.7545],
              [115.2419, 4.7766],
              [115.2481, 4.811],
              [115.1897, 4.8604],
              [115.1604, 4.9106],
              [115.1997, 4.9379],
              [115.2025, 4.9719],
              [115.2329, 4.9783],
              [115.2554, 4.9354],
              [115.326, 4.9025],
              [115.3661, 4.9065],
              [115.3968, 4.9269],
              [115.4331, 4.9817],
              [115.4547, 4.9857],
              [115.5026, 4.9674],
              [115.5621, 4.996],
              [115.6126, 5.0022],
              [115.6337, 4.9835],
              [115.6659, 4.8392],
              [115.6648, 4.7907],
              [115.6439, 4.7673],
              [115.6098, 4.6847],
              [115.5684, 4.6423],
              [115.5633, 4.5998],
              [115.5816, 4.5733],
              [115.5907, 4.5111],
              [115.5751, 4.4867],
              [115.6066, 4.4276],
              [115.6004, 4.3754],
              [115.6594, 4.3471],
              [115.6803, 4.2976],
              [115.6554, 4.251],
              [115.6459, 4.189],
              [115.6597, 4.1395],
              [115.6842, 4.126],
              [115.6823, 4.0725],
              [115.6562, 3.9876],
              [115.6399, 3.9609],
              [115.5702, 3.9336],
              [115.6281, 3.8702],
              [115.5827, 3.7224],
              [115.5809, 3.6623],
              [115.59, 3.6034],
              [115.6117, 3.5757],
              [115.6193, 3.5102],
              [115.6559, 3.4487],
              [115.5898, 3.4526],
              [115.5543, 3.3822],
              [115.537, 3.3194],
              [115.5373, 3.236],
              [115.5281, 3.2144],
              [115.5707, 3.1822],
              [115.5696, 3.1653],
              [115.5229, 3.1208],
              [115.5217, 3.0651],
              [115.4872, 3.0325],
              [115.438, 3.0291],
              [115.4036, 2.9884],
              [115.3292, 2.9868],
              [115.3217, 3.0183],
              [115.293, 3.0492],
              [115.2793, 3.0055],
              [115.2553, 2.9793],
              [115.1754, 2.9324],
              [115.1668, 2.8773],
              [115.1092, 2.8207],
              [115.1449, 2.8124],
              [115.1435, 2.7528],
              [115.1075, 2.7229],
              [115.1208, 2.6626],
              [115.0971, 2.6242],
              [115.1143, 2.5895],
              [115.1746, 2.606],
              [115.2402, 2.5301],
              [115.2411, 2.499],
              [115.1977, 2.4713],
              [115.1357, 2.4695],
              [115.1019, 2.4383],
              [115.0922, 2.4047],
              [115.0522, 2.4055],
              [114.9945, 2.3523],
              [114.9725, 2.3691],
              [114.9445, 2.323],
              [114.9608, 2.3021],
              [114.9185, 2.2612],
              [114.8176, 2.2518],
              [114.8041, 2.2442],
              [114.7848, 2.1458],
              [114.8181, 2.0896],
              [114.8068, 2.0452],
              [114.8208, 2.0161],
              [114.8667, 2.0355],
              [114.8947, 2.0112],
              [114.8595, 1.9794],
              [114.8542, 1.9479],
              [114.8798, 1.9198],
              [114.8572, 1.8994],
              [114.8242, 1.898],
              [114.7952, 1.8574],
              [114.7589, 1.8661],
              [114.7225, 1.8578],
              [114.6974, 1.8128],
              [114.7131, 1.7828],
              [114.7167, 1.7311],
              [114.7079, 1.6759],
              [114.6805, 1.6487],
              [114.6595, 1.6029],
              [114.6116, 1.5754],
              [114.6018, 1.5376],
              [114.6057, 1.4919],
              [114.5649, 1.4319],
              [114.5372, 1.4356],
              [114.4945, 1.4705],
              [114.4712, 1.4721],
              [114.401, 1.5172],
              [114.3763, 1.4916],
              [114.3066, 1.4674],
              [114.2864, 1.4517],
              [114.2338, 1.4425],
              [114.2132, 1.413],
              [114.1372, 1.464],
              [114.0795, 1.4628],
              [114.0197, 1.445],
              [113.9795, 1.4528],
              [113.9358, 1.4197],
              [113.823, 1.3729],
              [113.828, 1.3353],
              [113.807, 1.3012],
              [113.7045, 1.2623],
              [113.6619, 1.2203],
              [113.6291, 1.2187],
              [113.5969, 1.2598],
              [113.5794, 1.3021],
              [113.5382, 1.3226],
              [113.4934, 1.3143],
              [113.4241, 1.2834],
              [113.3668, 1.3262],
              [113.3552, 1.3559],
              [113.2719, 1.387],
              [113.2197, 1.391],
              [113.1722, 1.3793],
              [113.1353, 1.3917],
              [113.0925, 1.4409],
              [113.015, 1.4084],
              [112.9735, 1.4082],
              [112.9778, 1.4489],
              [113.0309, 1.4727],
              [113.0556, 1.5556],
              [112.9947, 1.5795],
              [112.9769, 1.5661],
              [112.8731, 1.582],
              [112.8423, 1.5401],
              [112.7984, 1.5426],
              [112.7664, 1.5643],
              [112.6943, 1.554],
              [112.6501, 1.5693],
              [112.4771, 1.5752],
              [112.4291, 1.5249],
              [112.3494, 1.5026],
              [112.318, 1.5048],
              [112.2744, 1.47],
              [112.2194, 1.4564],
              [112.1942, 1.4111],
              [112.222, 1.387],
              [112.2038, 1.3368],
              [112.1768, 1.3108],
              [112.1561, 1.243],
              [112.1577, 1.2054],
              [112.1309, 1.1426],
              [112.0881, 1.1429],
              [111.9577, 1.1263],
              [111.8888, 1.0657],
              [111.8725, 1.0093],
              [111.8305, 1.0047],
              [111.7667, 1.0213],
              [111.7247, 1.0121],
              [111.6655, 1.0464],
              [111.6018, 1.0001],
              [111.5596, 0.9922],
              [111.5323, 0.9614],
              [111.5149, 1.0071],
              [111.4807, 1.0355],
              [111.433, 1.0155],
              [111.3818, 1.018],
              [111.3511, 1.0382],
              [111.2522, 1.0678],
              [111.2344, 1.0832],
              [111.1397, 1.0527],
              [111.0598, 1.0466],
              [111.0251, 1.033],
              [110.9095, 1.0273],
              [110.8512, 0.9496],
              [110.8024, 0.9459],
              [110.7472, 0.9021],
              [110.7199, 0.9069],
              [110.665, 0.879],
              [110.6296, 0.9059],
              [110.6217, 0.8725],
              [110.5702, 0.855],
              [110.4874, 0.8782],
              [110.444, 0.9107],
              [110.4383, 0.9427],
              [110.4075, 0.9505],
              [110.3932, 0.9936],
              [110.2817, 0.9956],
              [110.2778, 1.0474],
              [110.2611, 1.0528],
              [110.231, 1.1173],
              [110.185, 1.1819],
              [110.1494, 1.1986],
              [110.0963, 1.1975],
              [110.0569, 1.2654],
              [109.9787, 1.2986],
              [109.9592, 1.3944],
              [109.9239, 1.4219],
              [109.8303, 1.4244],
              [109.8289, 1.4828],
              [109.8006, 1.4695],
              [109.7811, 1.503],
              [109.6518, 1.6353],
              [109.6641, 1.7323],
              [109.6805, 1.7674],
              [109.6717, 1.7909],
              [109.5997, 1.8104],
              [109.5815, 1.847],
              [109.5577, 1.8541],
              [109.538, 1.9216],
              [109.5774, 1.9385],
              [109.5883, 1.9691],
              [109.6184, 1.9872],
              [109.6507, 1.9825],
              [109.6567, 1.902],
              [109.6817, 1.8608],
              [109.7239, 1.8361],
              [109.8428, 1.7875],
              [109.8822, 1.7532],
              [109.8718, 1.7309],
              [109.9386, 1.6864],
              [109.9594, 1.7017],
              [110.1211, 1.6983],
              [110.1978, 1.7064],
              [110.2001, 1.6829],
              [110.2436, 1.667],
              [110.26, 1.6178],
              [110.3067, 1.6519],
              [110.2942, 1.6872],
              [110.3292, 1.7133],
              [110.3361, 1.6706],
              [110.3636, 1.6042],
              [110.4239, 1.5608],
              [110.4256, 1.5351],
              [110.4312, 1.5233],
              [110.4564, 1.5072],
              [110.4856, 1.5178],
              [110.4753, 1.5431],
              [110.5153, 1.5492],
              [110.5283, 1.5489],
              [110.5358, 1.5586],
              [110.5414, 1.5856],
              [110.6106, 1.597],
              [110.7314, 1.5464],
              [110.8012, 1.5775],
              [110.8742, 1.5373],
              [110.9397, 1.522],
              [110.9945, 1.4864],
              [111.0232, 1.4404],
              [111.0674, 1.4015],
              [111.1228, 1.3889],
              [111.1702, 1.3596],
              [111.2185, 1.3913],
              [111.2642, 1.3852],
              [111.2803, 1.342],
              [111.3108, 1.3268],
              [111.3069, 1.3891],
              [111.2609, 1.4152],
              [111.2024, 1.408],
              [111.1728, 1.3875],
              [111.1333, 1.4183],
              [111.0777, 1.4376],
              [111.0577, 1.4567],
              [111.0361, 1.5083],
              [110.9996, 1.5408],
              [111, 1.5806],
              [111.0171, 1.6515],
              [111.04, 1.6784],
              [111.0756, 1.6756],
              [111.1239, 1.7011],
              [111.0862, 1.7566],
              [111.103, 1.7823],
              [111.1134, 1.8483],
              [111.1683, 1.9537],
              [111.1714, 1.9896],
              [111.21, 2.0957],
              [111.2315, 2.0748],
              [111.303, 2.0571],
              [111.3485, 2.0937],
              [111.357, 2.1548],
              [111.3712, 2.1635],
              [111.3767, 2.1875],
              [111.4028, 2.1684],
              [111.4101, 2.1822],
              [111.3986, 2.2028],
              [111.383, 2.2082],
              [111.3834, 2.2196],
              [111.35, 2.2104],
              [111.3536, 2.2292],
              [111.3454, 2.2523],
              [111.3061, 2.287],
              [111.3246, 2.3338],
              [111.3744, 2.34],
              [111.4391, 2.3775],
              [111.4108, 2.4248],
              [111.4122, 2.4561],
              [111.3908, 2.487],
              [111.4636, 2.487],
              [111.3981, 2.5309],
              [111.3978, 2.5608],
              [111.4194, 2.63],
              [111.4303, 2.6978],
              [111.4742, 2.7186],
              [111.4904, 2.7448],
              [111.5981, 2.7942],
              [111.635, 2.8495],
              [111.6577, 2.8301],
              [111.7089, 2.8481],
              [111.8633, 2.8764],
              [111.8856, 2.8736],
              [112, 2.8906],
              [112.1579, 2.9259],
              [112.2758, 2.9611],
              [112.5054, 3.0106],
              [112.5826, 3.0159],
              [112.6212, 3.0387],
              [112.7551, 3.0751],
              [112.8684, 3.0973],
              [112.9233, 3.1137],
              [112.9785, 3.1415],
              [113.0267, 3.1773],
              [113.0742, 3.2439],
              [113.0556, 3.2672],
              [113.1542, 3.3467],
              [113.1943, 3.4162],
              [113.2881, 3.4969],
              [113.3048, 3.5533],
              [113.3725, 3.6214],
              [113.4258, 3.7169],
              [113.4339, 3.7564],
              [113.553, 3.8353],
              [113.6697, 3.9319],
              [113.7618, 4.0293],
              [113.818, 4.1223],
              [113.925, 4.2524],
              [113.964, 4.3227],
              [113.9612, 4.3633],
              [113.9839, 4.3917],
              [113.9971, 4.4433],
              [113.9947, 4.5012],
              [113.9678, 4.5809],
              [113.9831, 4.5941],
              [114.1281, 4.587]
            ]
          ],
          [
            [
              [111.3344, 2.8033],
              [111.387, 2.7261],
              [111.3781, 2.49],
              [111.3964, 2.4609],
              [111.4083, 2.3692],
              [111.3797, 2.3719],
              [111.3631, 2.3475],
              [111.3269, 2.4178],
              [111.3019, 2.4309],
              [111.3105, 2.5131],
              [111.2811, 2.6453],
              [111.2889, 2.7472],
              [111.3136, 2.7928],
              [111.3344, 2.8033]
            ]
          ],
          [
            [
              [111.2493, 2.4384],
              [111.255, 2.4205],
              [111.3269, 2.3925],
              [111.3106, 2.3692],
              [111.3005, 2.3156],
              [111.2762, 2.2816],
              [111.2822, 2.2057],
              [111.2509, 2.1965],
              [111.229, 2.2408],
              [111.1883, 2.2421],
              [111.1917, 2.2913],
              [111.1842, 2.3664],
              [111.2172, 2.4187],
              [111.2493, 2.4384]
            ]
          ],
          [
            [
              [110.4897, 1.6353],
              [110.5128, 1.6283],
              [110.5339, 1.5803],
              [110.5311, 1.5594],
              [110.5211, 1.5511],
              [110.4975, 1.5675],
              [110.4886, 1.5684],
              [110.4739, 1.547],
              [110.4836, 1.5189],
              [110.4572, 1.5081],
              [110.4342, 1.5231],
              [110.4258, 1.5411],
              [110.4692, 1.5919],
              [110.4597, 1.6159],
              [110.4897, 1.6353]
            ]
          ],
          [
            [
              [110.2636, 1.7114],
              [110.302, 1.6525],
              [110.2597, 1.6292],
              [110.2442, 1.6739],
              [110.215, 1.702],
              [110.2636, 1.7114]
            ]
          ],
          [
            [
              [110.5031, 1.7503],
              [110.5256, 1.7236],
              [110.4983, 1.7075],
              [110.4836, 1.6525],
              [110.453, 1.6239],
              [110.4575, 1.5895],
              [110.4078, 1.5842],
              [110.3653, 1.6053],
              [110.3517, 1.6448],
              [110.3794, 1.6606],
              [110.3744, 1.6889],
              [110.4408, 1.7075],
              [110.4436, 1.7303],
              [110.5022, 1.732],
              [110.5031, 1.7503]
            ]
          ],
          [
            [
              [111.3626, 2.204],
              [111.3654, 2.2141],
              [111.3983, 2.2017],
              [111.3894, 2.1808],
              [111.3836, 2.1911],
              [111.3703, 2.1864],
              [111.3705, 2.1703],
              [111.3396, 2.1538],
              [111.3189, 2.1228],
              [111.2475, 2.1522],
              [111.1833, 2.137],
              [111.1584, 2.1588],
              [111.2229, 2.235],
              [111.2464, 2.1928],
              [111.2821, 2.1971],
              [111.2896, 2.2202],
              [111.2831, 2.2824],
              [111.3416, 2.2513],
              [111.3507, 2.2281],
              [111.3473, 2.2084],
              [111.3626, 2.204]
            ]
          ]
        ]
      },
      properties: {
        name: 'Sarawak',
        id: 'MY-13',
        CNTRY: 'Malaysia',
        TYPE: 'State'
      },
      id: 'MY-13'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [117.0522, 7.1908],
              [117.0692, 7.2608],
              [117.0608, 7.2925],
              [117.1228, 7.3153],
              [117.1445, 7.3364],
              [117.2347, 7.3634],
              [117.2825, 7.3484],
              [117.2956, 7.3256],
              [117.2628, 7.2761],
              [117.2834, 7.2325],
              [117.2458, 7.1825],
              [117.1853, 7.1895],
              [117.0606, 7.1258],
              [117.0522, 7.1908]
            ]
          ],
          [
            [
              [116.8947, 7.2092],
              [116.8617, 7.192],
              [116.8658, 7.2428],
              [116.8961, 7.2872],
              [116.9325, 7.2922],
              [116.9894, 7.3294],
              [117.0167, 7.2945],
              [116.9814, 7.2611],
              [116.8947, 7.2092]
            ]
          ],
          [
            [
              [115.6842, 4.126],
              [115.6597, 4.1395],
              [115.6459, 4.189],
              [115.6554, 4.251],
              [115.6803, 4.2976],
              [115.6594, 4.3471],
              [115.6004, 4.3754],
              [115.6066, 4.4276],
              [115.5751, 4.4867],
              [115.5907, 4.5111],
              [115.5816, 4.5733],
              [115.5633, 4.5998],
              [115.5684, 4.6423],
              [115.6098, 4.6847],
              [115.6439, 4.7673],
              [115.6648, 4.7907],
              [115.6659, 4.8392],
              [115.6337, 4.9835],
              [115.6126, 5.0022],
              [115.5621, 4.996],
              [115.5026, 4.9674],
              [115.4547, 4.9857],
              [115.4691, 5.0146],
              [115.5114, 5.0289],
              [115.547, 5.0728],
              [115.5525, 5.14],
              [115.5914, 5.181],
              [115.5464, 5.2039],
              [115.4966, 5.2056],
              [115.3789, 5.2939],
              [115.3608, 5.3215],
              [115.3701, 5.3964],
              [115.4229, 5.4242],
              [115.4935, 5.5002],
              [115.5457, 5.5403],
              [115.5936, 5.6304],
              [115.6208, 5.6148],
              [115.6052, 5.5702],
              [115.6612, 5.5408],
              [115.7799, 5.5329],
              [115.8339, 5.5517],
              [115.8756, 5.5909],
              [115.893, 5.6722],
              [115.903, 5.7513],
              [115.9711, 5.7811],
              [116.0326, 5.8296],
              [116.0477, 5.8692],
              [116.0372, 5.9022],
              [116.0586, 5.9772],
              [116.1093, 5.9983],
              [116.1097, 6.0503],
              [116.0847, 6.1075],
              [116.1411, 6.1439],
              [116.1841, 6.2204],
              [116.2397, 6.2809],
              [116.3208, 6.2995],
              [116.3139, 6.3572],
              [116.3361, 6.3881],
              [116.3667, 6.4003],
              [116.48, 6.4761],
              [116.5034, 6.5363],
              [116.5845, 6.6556],
              [116.6158, 6.6681],
              [116.6428, 6.7078],
              [116.6283, 6.78],
              [116.6506, 6.8147],
              [116.6397, 6.8639],
              [116.6817, 6.8864],
              [116.7058, 6.9296],
              [116.7039, 6.9599],
              [116.7464, 7.0353],
              [116.8128, 6.9725],
              [116.8617, 6.8882],
              [116.8309, 6.8625],
              [116.8571, 6.8235],
              [116.8583, 6.7714],
              [116.8186, 6.7334],
              [116.7844, 6.6648],
              [116.7581, 6.6334],
              [116.755, 6.5789],
              [116.8106, 6.5647],
              [116.8537, 6.5817],
              [116.9098, 6.6625],
              [116.9811, 6.7028],
              [116.9773, 6.7462],
              [117.0129, 6.7879],
              [117.0264, 6.8281],
              [117.0505, 6.8439],
              [117.0556, 6.8776],
              [117.0319, 6.8917],
              [117.0331, 6.9503],
              [117.1011, 6.9975],
              [117.1531, 7.0128],
              [117.1921, 6.9615],
              [117.2502, 6.9448],
              [117.2583, 6.8909],
              [117.2325, 6.8489],
              [117.2462, 6.7595],
              [117.2738, 6.7412],
              [117.2647, 6.7],
              [117.2921, 6.6156],
              [117.388, 6.6034],
              [117.4058, 6.5638],
              [117.4727, 6.5538],
              [117.5131, 6.6245],
              [117.5522, 6.537],
              [117.6056, 6.5093],
              [117.6307, 6.5115],
              [117.7423, 6.4261],
              [117.7231, 6.3878],
              [117.7322, 6.302],
              [117.7069, 6.2303],
              [117.6566, 6.2376],
              [117.6067, 6.205],
              [117.5986, 6.1672],
              [117.635, 6.1156],
              [117.6562, 6.055],
              [117.6247, 6.0267],
              [117.6287, 5.994],
              [117.5842, 5.9752],
              [117.5619, 5.952],
              [117.5958, 5.9059],
              [117.6258, 5.8958],
              [117.6803, 5.9092],
              [117.7297, 5.8972],
              [117.7722, 5.9076],
              [117.7578, 5.9389],
              [117.8458, 5.9364],
              [117.8967, 5.9528],
              [117.8983, 5.9792],
              [117.9475, 6.0183],
              [117.9846, 6.0656],
              [118.0097, 6.06],
              [118.0141, 6.0265],
              [118.0681, 5.95],
              [118.0503, 5.9247],
              [118.1333, 5.8514],
              [118.0308, 5.7889],
              [118.0086, 5.8169],
              [117.9544, 5.794],
              [117.9161, 5.8009],
              [117.8859, 5.7637],
              [117.9156, 5.7364],
              [117.9428, 5.6784],
              [117.9933, 5.6803],
              [118.0572, 5.7214],
              [118.0858, 5.6967],
              [118.1127, 5.7063],
              [118.1617, 5.7536],
              [118.1531, 5.8035],
              [118.2156, 5.8189],
              [118.2406, 5.8414],
              [118.3314, 5.8117],
              [118.3506, 5.8274],
              [118.4058, 5.7886],
              [118.4795, 5.7481],
              [118.5759, 5.6842],
              [118.6056, 5.645],
              [118.6522, 5.6306],
              [118.7094, 5.5642],
              [118.7739, 5.5306],
              [118.8056, 5.4975],
              [118.9217, 5.4378],
              [119.0121, 5.4076],
              [119.0603, 5.401],
              [119.1002, 5.4121],
              [119.1096, 5.4362],
              [119.1589, 5.45],
              [119.2003, 5.4291],
              [119.2411, 5.3886],
              [119.2646, 5.3109],
              [119.2463, 5.2659],
              [119.2695, 5.2353],
              [119.2672, 5.2078],
              [119.2204, 5.1493],
              [119.1697, 5.1089],
              [119.0899, 5.0766],
              [118.9472, 5.0414],
              [118.893, 5.0222],
              [118.8617, 4.9941],
              [118.8047, 4.9839],
              [118.7561, 4.9526],
              [118.6937, 4.9464],
              [118.6693, 4.9331],
              [118.5505, 4.961],
              [118.5154, 4.9227],
              [118.4706, 4.9229],
              [118.4317, 5.0059],
              [118.3903, 5.0203],
              [118.3344, 5.0219],
              [118.3158, 5.0047],
              [118.3033, 4.9604],
              [118.2511, 4.971],
              [118.2046, 4.954],
              [118.1467, 4.8848],
              [118.1315, 4.8406],
              [118.1642, 4.8395],
              [118.1962, 4.7951],
              [118.1986, 4.7581],
              [118.227, 4.7058],
              [118.2658, 4.6613],
              [118.3035, 4.6554],
              [118.3047, 4.6325],
              [118.3692, 4.6283],
              [118.3805, 4.5997],
              [118.4117, 4.5847],
              [118.434, 4.601],
              [118.4625, 4.5691],
              [118.5016, 4.4807],
              [118.5328, 4.5239],
              [118.5921, 4.514],
              [118.6438, 4.4417],
              [118.5612, 4.3711],
              [118.5513, 4.351],
              [118.4852, 4.36],
              [118.4106, 4.3286],
              [118.3813, 4.3455],
              [118.2333, 4.3095],
              [118.1956, 4.291],
              [118.128, 4.2863],
              [118.0769, 4.2545],
              [117.9965, 4.2219],
              [117.9318, 4.2477],
              [117.8787, 4.2433],
              [117.8549, 4.2891],
              [117.8047, 4.3251],
              [117.7606, 4.3337],
              [117.7184, 4.3564],
              [117.6772, 4.3933],
              [117.6756, 4.4241],
              [117.6325, 4.4228],
              [117.5886, 4.3919],
              [117.6281, 4.3477],
              [117.6065, 4.32],
              [117.5531, 4.3185],
              [117.5445, 4.307],
              [117.5417, 4.2958],
              [117.5512, 4.2671],
              [117.5592, 4.2583],
              [117.5319, 4.2359],
              [117.5405, 4.1868],
              [117.5292, 4.1636],
              [117.461, 4.1829],
              [117.4387, 4.2024],
              [117.4242, 4.2428],
              [117.3179, 4.3084],
              [117.248, 4.3725],
              [117.2031, 4.3359],
              [117.1398, 4.3432],
              [117.1034, 4.331],
              [117.0446, 4.3437],
              [117.0247, 4.3203],
              [117, 4.3397],
              [116.9615, 4.3379],
              [116.9005, 4.3612],
              [116.8683, 4.3355],
              [116.8291, 4.3264],
              [116.7863, 4.3396],
              [116.7436, 4.3804],
              [116.7107, 4.3377],
              [116.6642, 4.3459],
              [116.616, 4.3377],
              [116.6101, 4.366],
              [116.5703, 4.401],
              [116.5382, 4.3735],
              [116.5378, 4.3324],
              [116.4449, 4.2891],
              [116.4283, 4.3275],
              [116.358, 4.3833],
              [116.2721, 4.3611],
              [116.2507, 4.3741],
              [116.1702, 4.3843],
              [116.1568, 4.3438],
              [116.116, 4.3328],
              [116.0844, 4.2767],
              [116.041, 4.2914],
              [116.0311, 4.328],
              [116.0007, 4.3476],
              [115.9337, 4.3547],
              [115.9103, 4.388],
              [115.879, 4.3802],
              [115.8698, 4.3197],
              [115.8785, 4.2951],
              [115.8369, 4.2615],
              [115.8072, 4.2226],
              [115.7739, 4.2432],
              [115.7323, 4.1924],
              [115.7062, 4.1908],
              [115.6842, 4.126]
            ]
          ],
          [
            [
              [117.3254, 6.6584],
              [117.3389, 6.6775],
              [117.407, 6.6733],
              [117.3903, 6.7419],
              [117.472, 6.7583],
              [117.5108, 6.6705],
              [117.4807, 6.6431],
              [117.3967, 6.6201],
              [117.3453, 6.6369],
              [117.3254, 6.6584]
            ]
          ],
          [
            [
              [118.5417, 4.617],
              [118.5149, 4.5962],
              [118.4793, 4.6174],
              [118.3714, 4.6477],
              [118.4296, 4.6633],
              [118.4547, 4.7003],
              [118.4947, 4.6589],
              [118.5422, 4.6557],
              [118.5417, 4.617]
            ]
          ],
          [
            [
              [118.7325, 4.471],
              [118.7214, 4.4356],
              [118.657, 4.4428],
              [118.6258, 4.4853],
              [118.6725, 4.4956],
              [118.6936, 4.4711],
              [118.7325, 4.471]
            ]
          ],
          [
            [
              [117.6214, 4.2817],
              [117.6511, 4.2531],
              [117.6167, 4.2275],
              [117.5861, 4.2406],
              [117.5525, 4.2678],
              [117.5469, 4.3075],
              [117.5733, 4.3036],
              [117.6036, 4.2789],
              [117.6214, 4.2817]
            ]
          ]
        ]
      },
      properties: {
        name: 'Sabah',
        id: 'MY-12',
        CNTRY: 'Malaysia',
        TYPE: 'State'
      },
      id: 'MY-12'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [103.4449, 4.184],
            [103.368, 4.1739],
            [103.3222, 4.195],
            [103.3309, 4.1438],
            [103.3068, 4.0743],
            [103.3314, 4.0456],
            [103.3397, 3.9367],
            [103.3059, 3.9083],
            [103.2803, 3.9447],
            [103.2855, 3.9635],
            [103.2084, 4.0339],
            [103.1724, 4.0281],
            [103.1119, 4.0869],
            [103.0672, 4.1103],
            [102.998, 4.1225],
            [102.9419, 4.0955],
            [102.9051, 4.1243],
            [102.9239, 4.1595],
            [102.9197, 4.2096],
            [102.9356, 4.2702],
            [102.9724, 4.3275],
            [103.0143, 4.3339],
            [103.004, 4.3645],
            [103.0237, 4.3959],
            [103.0072, 4.4384],
            [102.9739, 4.4758],
            [102.8896, 4.4673],
            [102.8562, 4.498],
            [102.8434, 4.562],
            [102.8623, 4.5991],
            [102.8865, 4.6096],
            [102.8459, 4.6741],
            [102.7822, 4.6743],
            [102.7263, 4.6876],
            [102.6884, 4.7167],
            [102.6574, 4.7603],
            [102.6678, 4.7778],
            [102.6491, 4.8486],
            [102.5592, 4.9001],
            [102.5161, 4.9034],
            [102.5198, 4.9568],
            [102.5081, 4.9976],
            [102.5215, 5.0275],
            [102.5025, 5.0742],
            [102.5266, 5.1012],
            [102.4851, 5.151],
            [102.4149, 5.1795],
            [102.4191, 5.3022],
            [102.443, 5.3674],
            [102.3885, 5.4123],
            [102.4013, 5.4346],
            [102.3935, 5.5228],
            [102.4086, 5.5537],
            [102.3842, 5.689],
            [102.4736, 5.7713],
            [102.5374, 5.8443],
            [102.6305, 5.782],
            [102.6882, 5.707],
            [102.7262, 5.6728],
            [102.798, 5.6258],
            [102.8469, 5.5774],
            [102.8933, 5.5483],
            [102.9484, 5.5398],
            [102.9893, 5.511],
            [103.1069, 5.3953],
            [103.1861, 5.2689],
            [103.1831, 5.2546],
            [103.2272, 5.1817],
            [103.2833, 5.0656],
            [103.3498, 4.9436],
            [103.425, 4.8148],
            [103.4397, 4.78],
            [103.4218, 4.7662],
            [103.4496, 4.6865],
            [103.4392, 4.6411],
            [103.4767, 4.5266],
            [103.4479, 4.4994],
            [103.455, 4.3967],
            [103.4927, 4.3239],
            [103.4716, 4.2558],
            [103.4418, 4.2303],
            [103.4449, 4.184]
          ]
        ]
      },
      properties: {
        name: 'Terengganu',
        id: 'MY-11',
        CNTRY: 'Malaysia',
        TYPE: 'State'
      },
      id: 'MY-11'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [101.6258, 3.7699],
            [101.6579, 3.7283],
            [101.6858, 3.7111],
            [101.7444, 3.7031],
            [101.7836, 3.6567],
            [101.8113, 3.6092],
            [101.809, 3.552],
            [101.7841, 3.5397],
            [101.7606, 3.4973],
            [101.7897, 3.4207],
            [101.7606, 3.3694],
            [101.8186, 3.2947],
            [101.87, 3.2659],
            [101.9193, 3.2559],
            [101.9455, 3.2078],
            [101.9611, 3.1378],
            [101.9472, 3.0942],
            [101.9699, 3.0445],
            [101.9416, 3.0112],
            [101.8868, 2.8864],
            [101.8703, 2.8663],
            [101.7487, 2.8705],
            [101.7615, 2.7185],
            [101.7577, 2.6872],
            [101.7122, 2.599],
            [101.6091, 2.6325],
            [101.5778, 2.6525],
            [101.4824, 2.6873],
            [101.4613, 2.7083],
            [101.4279, 2.773],
            [101.4043, 2.7962],
            [101.4249, 2.8637],
            [101.4121, 2.8928],
            [101.4394, 2.9654],
            [101.4076, 2.9723],
            [101.3565, 3.0133],
            [101.3612, 3.0618],
            [101.3122, 3.1277],
            [101.2944, 3.2559],
            [101.2647, 3.299],
            [101.1891, 3.3478],
            [101.1777, 3.3782],
            [101.0974, 3.4901],
            [101.0597, 3.5842],
            [101.0359, 3.6183],
            [100.9879, 3.6611],
            [100.9377, 3.6762],
            [100.9162, 3.7259],
            [100.8589, 3.7734],
            [100.8208, 3.7775],
            [100.8091, 3.8036],
            [100.8278, 3.8467],
            [100.9025, 3.8542],
            [100.9683, 3.8226],
            [100.9592, 3.7909],
            [101.0203, 3.8012],
            [101.0569, 3.791],
            [101.1044, 3.7299],
            [101.1226, 3.739],
            [101.1755, 3.7099],
            [101.2451, 3.717],
            [101.2473, 3.672],
            [101.3089, 3.672],
            [101.3456, 3.6933],
            [101.3262, 3.7714],
            [101.3728, 3.795],
            [101.3951, 3.7671],
            [101.4446, 3.7377],
            [101.515, 3.6745],
            [101.5419, 3.6729],
            [101.5743, 3.7007],
            [101.5818, 3.7339],
            [101.6258, 3.7699]
          ],
          [
            [101.6559, 2.8808],
            [101.6665, 2.8649],
            [101.7222, 2.8782],
            [101.7248, 2.9074],
            [101.7036, 2.9392],
            [101.6435, 2.9418],
            [101.6559, 2.8808]
          ],
          [
            [101.7323, 3.0378],
            [101.7503, 3.0544],
            [101.754, 3.1008],
            [101.7394, 3.1214],
            [101.7522, 3.2015],
            [101.7266, 3.2314],
            [101.704, 3.2144],
            [101.6384, 3.2198],
            [101.6028, 3.1567],
            [101.6028, 3.1328],
            [101.6426, 3.0824],
            [101.6532, 3.0435],
            [101.7028, 3.03],
            [101.7323, 3.0378]
          ]
        ]
      },
      properties: {
        name: 'Selangor',
        id: 'MY-10',
        CNTRY: 'Malaysia',
        TYPE: 'State'
      },
      id: 'MY-10'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [100.3715, 6.5371],
            [100.3673, 6.4653],
            [100.3037, 6.4064],
            [100.2813, 6.3699],
            [100.2089, 6.2914],
            [100.1953, 6.2547],
            [100.1702, 6.2913],
            [100.1265, 6.4195],
            [100.1597, 6.4877],
            [100.1567, 6.5774],
            [100.1843, 6.5773],
            [100.1661, 6.6307],
            [100.1811, 6.6545],
            [100.1877, 6.7164],
            [100.2155, 6.7205],
            [100.2356, 6.6942],
            [100.2725, 6.6971],
            [100.3251, 6.6632],
            [100.3137, 6.604],
            [100.3539, 6.5705],
            [100.3715, 6.5371]
          ]
        ]
      },
      properties: {
        name: 'Perlis',
        id: 'MY-09',
        CNTRY: 'Malaysia',
        TYPE: 'State'
      },
      id: 'MY-09'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [100.6155, 4.6492],
              [100.6631, 4.607],
              [100.6486, 4.5917],
              [100.653, 4.567],
              [100.6153, 4.555],
              [100.5817, 4.5681],
              [100.5853, 4.6028],
              [100.6155, 4.6492]
            ]
          ],
          [
            [
              [101.879, 5.8121],
              [101.9003, 5.7617],
              [101.9427, 5.7296],
              [101.9798, 5.6626],
              [101.9666, 5.5982],
              [101.9664, 5.5422],
              [101.9983, 5.5316],
              [101.9949, 5.4746],
              [101.9621, 5.4467],
              [101.9653, 5.4066],
              [101.9196, 5.3566],
              [101.8871, 5.3467],
              [101.8006, 5.3538],
              [101.7517, 5.3685],
              [101.734, 5.3426],
              [101.682, 5.3239],
              [101.6375, 5.3363],
              [101.6171, 5.359],
              [101.5743, 5.3157],
              [101.5699, 5.2889],
              [101.5225, 5.2599],
              [101.5147, 5.2021],
              [101.4921, 5.1679],
              [101.4881, 5.0826],
              [101.4689, 5.0637],
              [101.4528, 5.0003],
              [101.4307, 4.9787],
              [101.4527, 4.949],
              [101.4574, 4.8996],
              [101.4074, 4.8308],
              [101.4145, 4.8092],
              [101.3982, 4.7647],
              [101.3761, 4.7534],
              [101.3859, 4.7162],
              [101.3452, 4.7087],
              [101.3375, 4.6833],
              [101.3854, 4.6095],
              [101.3521, 4.5816],
              [101.3577, 4.5339],
              [101.3825, 4.5172],
              [101.3312, 4.4343],
              [101.36, 4.3945],
              [101.3943, 4.402],
              [101.4415, 4.3742],
              [101.4277, 4.3411],
              [101.4467, 4.3236],
              [101.452, 4.2388],
              [101.4685, 4.211],
              [101.5071, 4.2068],
              [101.4965, 4.1647],
              [101.5073, 4.1261],
              [101.5684, 4.0964],
              [101.5809, 4.0663],
              [101.5554, 4.0185],
              [101.5749, 3.991],
              [101.5766, 3.8985],
              [101.6036, 3.8458],
              [101.5907, 3.7994],
              [101.6258, 3.7699],
              [101.5818, 3.7339],
              [101.5743, 3.7007],
              [101.5419, 3.6729],
              [101.515, 3.6745],
              [101.4446, 3.7377],
              [101.3951, 3.7671],
              [101.3728, 3.795],
              [101.3262, 3.7714],
              [101.3456, 3.6933],
              [101.3089, 3.672],
              [101.2473, 3.672],
              [101.2451, 3.717],
              [101.1755, 3.7099],
              [101.1226, 3.739],
              [101.1044, 3.7299],
              [101.0569, 3.791],
              [101.0203, 3.8012],
              [100.9592, 3.7909],
              [100.9683, 3.8226],
              [100.9025, 3.8542],
              [100.8278, 3.8467],
              [100.8091, 3.8036],
              [100.8189, 3.8574],
              [100.7752, 3.8464],
              [100.7138, 3.8773],
              [100.7003, 3.955],
              [100.704, 3.9917],
              [100.7603, 4.0178],
              [100.7617, 4.0856],
              [100.6945, 4.1589],
              [100.6628, 4.1703],
              [100.6155, 4.1645],
              [100.6025, 4.19],
              [100.6372, 4.2472],
              [100.5911, 4.2564],
              [100.5867, 4.2914],
              [100.5582, 4.3093],
              [100.6062, 4.4245],
              [100.6217, 4.5253],
              [100.6472, 4.545],
              [100.6592, 4.5714],
              [100.6639, 4.6078],
              [100.6225, 4.6483],
              [100.6583, 4.6831],
              [100.6719, 4.7283],
              [100.6428, 4.7589],
              [100.6144, 4.7675],
              [100.6289, 4.8328],
              [100.5861, 4.8445],
              [100.5525, 4.8861],
              [100.5772, 4.8989],
              [100.5347, 4.9325],
              [100.5192, 4.9114],
              [100.4717, 4.9278],
              [100.443, 4.9156],
              [100.4219, 4.9498],
              [100.372, 5.0732],
              [100.36, 5.0881],
              [100.3891, 5.1317],
              [100.4775, 5.1373],
              [100.5034, 5.1473],
              [100.5138, 5.0997],
              [100.5623, 5.0876],
              [100.589, 5.1254],
              [100.6159, 5.1309],
              [100.6319, 5.1689],
              [100.6689, 5.1822],
              [100.6702, 5.2074],
              [100.7011, 5.2466],
              [100.7367, 5.2666],
              [100.7448, 5.3103],
              [100.8138, 5.3177],
              [100.8493, 5.3477],
              [100.8702, 5.4597],
              [100.8904, 5.4956],
              [100.9224, 5.4982],
              [100.9454, 5.5473],
              [100.9293, 5.6173],
              [100.9699, 5.6518],
              [100.9822, 5.7101],
              [100.9523, 5.7453],
              [100.9571, 5.7773],
              [100.9899, 5.7913],
              [101.0301, 5.7477],
              [101.0645, 5.7412],
              [101.1091, 5.6813],
              [101.1323, 5.6369],
              [101.2058, 5.6622],
              [101.2571, 5.7151],
              [101.25, 5.762],
              [101.2633, 5.7972],
              [101.2934, 5.8204],
              [101.3158, 5.808],
              [101.3692, 5.8355],
              [101.3941, 5.8743],
              [101.485, 5.878],
              [101.5398, 5.9186],
              [101.5822, 5.9342],
              [101.636, 5.8796],
              [101.6626, 5.8722],
              [101.6642, 5.8153],
              [101.6934, 5.7813],
              [101.7661, 5.7952],
              [101.8147, 5.7372],
              [101.8305, 5.7876],
              [101.879, 5.8121]
            ]
          ],
          [
            [
              [100.6197, 4.7582],
              [100.6656, 4.7233],
              [100.6408, 4.6764],
              [100.5956, 4.6686],
              [100.5761, 4.7411],
              [100.585, 4.7658],
              [100.6197, 4.7582]
            ]
          ]
        ]
      },
      properties: {
        name: 'Perak',
        id: 'MY-08',
        CNTRY: 'Malaysia',
        TYPE: 'State'
      },
      id: 'MY-08'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [100.3476, 5.5786],
              [100.3911, 5.5835],
              [100.4204, 5.5666],
              [100.5288, 5.559],
              [100.526, 5.3689],
              [100.5502, 5.1425],
              [100.5034, 5.1473],
              [100.4775, 5.1373],
              [100.3891, 5.1317],
              [100.4229, 5.1688],
              [100.4245, 5.2228],
              [100.4053, 5.337],
              [100.3647, 5.3864],
              [100.3805, 5.4634],
              [100.3708, 5.5239],
              [100.3476, 5.5786]
            ]
          ],
          [
            [
              [100.2747, 5.4781],
              [100.3083, 5.4647],
              [100.3119, 5.4378],
              [100.3444, 5.4131],
              [100.3167, 5.3831],
              [100.315, 5.3514],
              [100.2831, 5.2697],
              [100.2281, 5.287],
              [100.1964, 5.3097],
              [100.1803, 5.442],
              [100.2747, 5.4781]
            ]
          ]
        ]
      },
      properties: {
        name: 'Penang',
        id: 'MY-07',
        CNTRY: 'Malaysia',
        TYPE: 'State'
      },
      id: 'MY-07'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [101.3854, 4.6095],
            [101.4089, 4.6142],
            [101.4652, 4.5747],
            [101.4973, 4.6063],
            [101.533, 4.606],
            [101.5958, 4.5518],
            [101.6312, 4.5491],
            [101.6687, 4.5653],
            [101.6789, 4.5881],
            [101.7535, 4.6137],
            [101.7777, 4.6615],
            [101.7754, 4.7279],
            [101.7874, 4.76],
            [101.8122, 4.7603],
            [101.8538, 4.6866],
            [101.8941, 4.6695],
            [101.8973, 4.6268],
            [101.9469, 4.6687],
            [101.9524, 4.7445],
            [101.9961, 4.7564],
            [102.0501, 4.7291],
            [102.0968, 4.7548],
            [102.129, 4.744],
            [102.1325, 4.7183],
            [102.1627, 4.6971],
            [102.1771, 4.6617],
            [102.2155, 4.6294],
            [102.2457, 4.6541],
            [102.2882, 4.6541],
            [102.3372, 4.6373],
            [102.3725, 4.6786],
            [102.3715, 4.7078],
            [102.4447, 4.7132],
            [102.5179, 4.6661],
            [102.5512, 4.7047],
            [102.6096, 4.6967],
            [102.5992, 4.7525],
            [102.618, 4.7764],
            [102.6574, 4.7603],
            [102.6884, 4.7167],
            [102.7263, 4.6876],
            [102.7822, 4.6743],
            [102.8459, 4.6741],
            [102.8865, 4.6096],
            [102.8623, 4.5991],
            [102.8434, 4.562],
            [102.8562, 4.498],
            [102.8896, 4.4673],
            [102.9739, 4.4758],
            [103.0072, 4.4384],
            [103.0237, 4.3959],
            [103.004, 4.3645],
            [103.0143, 4.3339],
            [102.9724, 4.3275],
            [102.9356, 4.2702],
            [102.9197, 4.2096],
            [102.9239, 4.1595],
            [102.9051, 4.1243],
            [102.9419, 4.0955],
            [102.998, 4.1225],
            [103.0672, 4.1103],
            [103.1119, 4.0869],
            [103.1724, 4.0281],
            [103.2084, 4.0339],
            [103.2855, 3.9635],
            [103.2803, 3.9447],
            [103.3059, 3.9083],
            [103.3397, 3.9367],
            [103.3314, 4.0456],
            [103.3068, 4.0743],
            [103.3309, 4.1438],
            [103.3222, 4.195],
            [103.368, 4.1739],
            [103.4449, 4.184],
            [103.4106, 4.1626],
            [103.3868, 4.1179],
            [103.3982, 4.0396],
            [103.4242, 3.9842],
            [103.388, 3.952],
            [103.3678, 3.9169],
            [103.3656, 3.8814],
            [103.3781, 3.8196],
            [103.3417, 3.8056],
            [103.3256, 3.7375],
            [103.3694, 3.6447],
            [103.4364, 3.5592],
            [103.4685, 3.5316],
            [103.4772, 3.4958],
            [103.4442, 3.4547],
            [103.4294, 3.4075],
            [103.4258, 3.3217],
            [103.447, 3.2072],
            [103.4486, 3.125],
            [103.4406, 3.0953],
            [103.4289, 2.9556],
            [103.4569, 2.8581],
            [103.5292, 2.7656],
            [103.6214, 2.6814],
            [103.6303, 2.6664],
            [103.5887, 2.653],
            [103.6198, 2.6041],
            [103.5979, 2.5745],
            [103.59, 2.5299],
            [103.6156, 2.5295],
            [103.6348, 2.4786],
            [103.5865, 2.4617],
            [103.478, 2.5167],
            [103.4533, 2.5472],
            [103.4185, 2.5531],
            [103.3581, 2.5866],
            [103.28, 2.5716],
            [103.145, 2.519],
            [102.9675, 2.5965],
            [102.7932, 2.8015],
            [102.7005, 2.829],
            [102.6852, 2.8824],
            [102.6549, 2.8909],
            [102.4736, 3.0136],
            [102.3944, 3.1117],
            [102.2868, 3.1376],
            [102.1676, 3.1729],
            [102.1415, 3.1976],
            [102.0599, 3.1731],
            [102.0421, 3.2247],
            [101.9918, 3.2311],
            [101.9701, 3.2692],
            [101.9379, 3.2777],
            [101.9193, 3.2559],
            [101.87, 3.2659],
            [101.8186, 3.2947],
            [101.7606, 3.3694],
            [101.7897, 3.4207],
            [101.7606, 3.4973],
            [101.7841, 3.5397],
            [101.809, 3.552],
            [101.8113, 3.6092],
            [101.7836, 3.6567],
            [101.7444, 3.7031],
            [101.6858, 3.7111],
            [101.6579, 3.7283],
            [101.6258, 3.7699],
            [101.5907, 3.7994],
            [101.6036, 3.8458],
            [101.5766, 3.8985],
            [101.5749, 3.991],
            [101.5554, 4.0185],
            [101.5809, 4.0663],
            [101.5684, 4.0964],
            [101.5073, 4.1261],
            [101.4965, 4.1647],
            [101.5071, 4.2068],
            [101.4685, 4.211],
            [101.452, 4.2388],
            [101.4467, 4.3236],
            [101.4277, 4.3411],
            [101.4415, 4.3742],
            [101.3943, 4.402],
            [101.36, 4.3945],
            [101.3312, 4.4343],
            [101.3825, 4.5172],
            [101.3577, 4.5339],
            [101.3521, 4.5816],
            [101.3854, 4.6095]
          ]
        ]
      },
      properties: {
        name: 'Pahang',
        id: 'MY-06',
        CNTRY: 'Malaysia',
        TYPE: 'State'
      },
      id: 'MY-06'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [101.9193, 3.2559],
            [101.9379, 3.2777],
            [101.9701, 3.2692],
            [101.9918, 3.2311],
            [102.0421, 3.2247],
            [102.0599, 3.1731],
            [102.1415, 3.1976],
            [102.1676, 3.1729],
            [102.2868, 3.1376],
            [102.3944, 3.1117],
            [102.4736, 3.0136],
            [102.6549, 2.8909],
            [102.6852, 2.8824],
            [102.7005, 2.829],
            [102.6306, 2.6328],
            [102.6435, 2.6022],
            [102.6138, 2.561],
            [102.6138, 2.4643],
            [102.5906, 2.4178],
            [102.5635, 2.4155],
            [102.4372, 2.4532],
            [102.399, 2.4793],
            [102.361, 2.4882],
            [102.2808, 2.4623],
            [102.2207, 2.4673],
            [102.1721, 2.4938],
            [102.1487, 2.4587],
            [102.0748, 2.4574],
            [101.9983, 2.388],
            [101.9716, 2.3763],
            [101.9682, 2.4007],
            [101.9207, 2.4247],
            [101.8556, 2.42],
            [101.8435, 2.488],
            [101.7986, 2.5423],
            [101.7944, 2.5746],
            [101.7548, 2.6028],
            [101.7122, 2.599],
            [101.7577, 2.6872],
            [101.7615, 2.7185],
            [101.7487, 2.8705],
            [101.8703, 2.8663],
            [101.8868, 2.8864],
            [101.9416, 3.0112],
            [101.9699, 3.0445],
            [101.9472, 3.0942],
            [101.9611, 3.1378],
            [101.9455, 3.2078],
            [101.9193, 3.2559]
          ]
        ]
      },
      properties: {
        name: 'Negeri Sembilan',
        id: 'MY-05',
        CNTRY: 'Malaysia',
        TYPE: 'State'
      },
      id: 'MY-05'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [101.9716, 2.3763],
            [101.9983, 2.388],
            [102.0748, 2.4574],
            [102.1487, 2.4587],
            [102.1721, 2.4938],
            [102.2207, 2.4673],
            [102.2808, 2.4623],
            [102.361, 2.4882],
            [102.399, 2.4793],
            [102.4372, 2.4532],
            [102.5635, 2.4155],
            [102.5906, 2.4178],
            [102.5436, 2.3871],
            [102.5193, 2.3527],
            [102.4983, 2.2207],
            [102.4814, 2.1838],
            [102.5306, 2.1591],
            [102.5156, 2.1185],
            [102.4878, 2.112],
            [102.4703, 2.1078],
            [102.2461, 2.1867],
            [102.1953, 2.2181],
            [102.155, 2.2172],
            [102.1205, 2.2681],
            [102.0781, 2.2958],
            [102.058, 2.34],
            [102.0158, 2.3653],
            [101.9716, 2.3763]
          ]
        ]
      },
      properties: {
        name: 'Malacca',
        id: 'MY-04',
        CNTRY: 'Malaysia',
        TYPE: 'State'
      },
      id: 'MY-04'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [102.5374, 5.8443],
            [102.4736, 5.7713],
            [102.3842, 5.689],
            [102.4086, 5.5537],
            [102.3935, 5.5228],
            [102.4013, 5.4346],
            [102.3885, 5.4123],
            [102.443, 5.3674],
            [102.4191, 5.3022],
            [102.4149, 5.1795],
            [102.4851, 5.151],
            [102.5266, 5.1012],
            [102.5025, 5.0742],
            [102.5215, 5.0275],
            [102.5081, 4.9976],
            [102.5198, 4.9568],
            [102.5161, 4.9034],
            [102.5592, 4.9001],
            [102.6491, 4.8486],
            [102.6678, 4.7778],
            [102.6574, 4.7603],
            [102.618, 4.7764],
            [102.5992, 4.7525],
            [102.6096, 4.6967],
            [102.5512, 4.7047],
            [102.5179, 4.6661],
            [102.4447, 4.7132],
            [102.3715, 4.7078],
            [102.3725, 4.6786],
            [102.3372, 4.6373],
            [102.2882, 4.6541],
            [102.2457, 4.6541],
            [102.2155, 4.6294],
            [102.1771, 4.6617],
            [102.1627, 4.6971],
            [102.1325, 4.7183],
            [102.129, 4.744],
            [102.0968, 4.7548],
            [102.0501, 4.7291],
            [101.9961, 4.7564],
            [101.9524, 4.7445],
            [101.9469, 4.6687],
            [101.8973, 4.6268],
            [101.8941, 4.6695],
            [101.8538, 4.6866],
            [101.8122, 4.7603],
            [101.7874, 4.76],
            [101.7754, 4.7279],
            [101.7777, 4.6615],
            [101.7535, 4.6137],
            [101.6789, 4.5881],
            [101.6687, 4.5653],
            [101.6312, 4.5491],
            [101.5958, 4.5518],
            [101.533, 4.606],
            [101.4973, 4.6063],
            [101.4652, 4.5747],
            [101.4089, 4.6142],
            [101.3854, 4.6095],
            [101.3375, 4.6833],
            [101.3452, 4.7087],
            [101.3859, 4.7162],
            [101.3761, 4.7534],
            [101.3982, 4.7647],
            [101.4145, 4.8092],
            [101.4074, 4.8308],
            [101.4574, 4.8996],
            [101.4527, 4.949],
            [101.4307, 4.9787],
            [101.4528, 5.0003],
            [101.4689, 5.0637],
            [101.4881, 5.0826],
            [101.4921, 5.1679],
            [101.5147, 5.2021],
            [101.5225, 5.2599],
            [101.5699, 5.2889],
            [101.5743, 5.3157],
            [101.6171, 5.359],
            [101.6375, 5.3363],
            [101.682, 5.3239],
            [101.734, 5.3426],
            [101.7517, 5.3685],
            [101.8006, 5.3538],
            [101.8871, 5.3467],
            [101.9196, 5.3566],
            [101.9653, 5.4066],
            [101.9621, 5.4467],
            [101.9949, 5.4746],
            [101.9983, 5.5316],
            [101.9664, 5.5422],
            [101.9666, 5.5982],
            [101.9798, 5.6626],
            [101.9427, 5.7296],
            [101.9003, 5.7617],
            [101.879, 5.8121],
            [101.8838, 5.8371],
            [101.9171, 5.843],
            [101.9411, 5.8669],
            [101.9213, 5.9173],
            [101.9429, 5.9533],
            [101.9455, 5.9866],
            [101.9877, 6.0372],
            [102.0536, 6.0802],
            [102.0929, 6.1467],
            [102.0786, 6.1715],
            [102.0805, 6.2254],
            [102.0943, 6.2414],
            [102.1443, 6.2074],
            [102.2116, 6.2244],
            [102.3479, 6.1636],
            [102.3766, 6.1222],
            [102.4513, 5.9515],
            [102.4833, 5.8978],
            [102.4789, 5.8786],
            [102.5374, 5.8443]
          ]
        ]
      },
      properties: {
        name: 'Kelantan',
        id: 'MY-03',
        CNTRY: 'Malaysia',
        TYPE: 'State'
      },
      id: 'MY-03'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [99.8361, 6.4732],
              [99.8558, 6.4375],
              [99.8938, 6.4146],
              [99.9119, 6.3856],
              [99.9108, 6.3287],
              [99.8531, 6.3097],
              [99.8036, 6.3164],
              [99.7725, 6.2792],
              [99.7361, 6.2589],
              [99.7136, 6.31],
              [99.712, 6.3612],
              [99.6469, 6.3709],
              [99.6412, 6.4358],
              [99.6669, 6.4265],
              [99.705, 6.4372],
              [99.7923, 6.425],
              [99.8361, 6.4732]
            ]
          ],
          [
            [
              [100.9899, 5.7913],
              [100.9571, 5.7773],
              [100.9523, 5.7453],
              [100.9822, 5.7101],
              [100.9699, 5.6518],
              [100.9293, 5.6173],
              [100.9454, 5.5473],
              [100.9224, 5.4982],
              [100.8904, 5.4956],
              [100.8702, 5.4597],
              [100.8493, 5.3477],
              [100.8138, 5.3177],
              [100.7448, 5.3103],
              [100.7367, 5.2666],
              [100.7011, 5.2466],
              [100.6702, 5.2074],
              [100.6689, 5.1822],
              [100.6319, 5.1689],
              [100.6159, 5.1309],
              [100.589, 5.1254],
              [100.5623, 5.0876],
              [100.5138, 5.0997],
              [100.5034, 5.1473],
              [100.5502, 5.1425],
              [100.526, 5.3689],
              [100.5288, 5.559],
              [100.4204, 5.5666],
              [100.3911, 5.5835],
              [100.3476, 5.5786],
              [100.3381, 5.6621],
              [100.3675, 5.7747],
              [100.3536, 5.9427],
              [100.3262, 6.046],
              [100.284, 6.0976],
              [100.2547, 6.191],
              [100.1953, 6.2547],
              [100.2089, 6.2914],
              [100.2813, 6.3699],
              [100.3037, 6.4064],
              [100.3673, 6.4653],
              [100.3715, 6.5371],
              [100.4286, 6.5197],
              [100.49, 6.525],
              [100.5274, 6.4869],
              [100.574, 6.4828],
              [100.6362, 6.4575],
              [100.6837, 6.4535],
              [100.7436, 6.5046],
              [100.7524, 6.4593],
              [100.8105, 6.4443],
              [100.8255, 6.4208],
              [100.8218, 6.3566],
              [100.8473, 6.3539],
              [100.8492, 6.3123],
              [100.8351, 6.2889],
              [100.8537, 6.2438],
              [100.8793, 6.262],
              [100.9051, 6.2453],
              [100.9402, 6.2499],
              [100.9714, 6.2874],
              [101.0194, 6.2537],
              [101.0932, 6.2612],
              [101.1266, 6.1953],
              [101.0757, 6.1704],
              [101.0587, 6.1428],
              [101.1321, 6.1021],
              [101.1027, 6.0549],
              [101.1064, 5.9991],
              [101.1271, 5.9783],
              [101.0755, 5.9179],
              [101.0362, 5.9179],
              [101.0095, 5.8737],
              [101.0123, 5.8321],
              [100.9899, 5.7913]
            ]
          ],
          [
            [
              [99.8297, 6.216],
              [99.8212, 6.1795],
              [99.7732, 6.2026],
              [99.7978, 6.2714],
              [99.8405, 6.2761],
              [99.8648, 6.234],
              [99.8297, 6.216]
            ]
          ]
        ]
      },
      properties: {
        name: 'Kedah',
        id: 'MY-02',
        CNTRY: 'Malaysia',
        TYPE: 'State'
      },
      id: 'MY-02'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [104.1701, 2.897],
              [104.1859, 2.8774],
              [104.2235, 2.7692],
              [104.2124, 2.7193],
              [104.1661, 2.7147],
              [104.1205, 2.7482],
              [104.1172, 2.7832],
              [104.1588, 2.8203],
              [104.1479, 2.8559],
              [104.1701, 2.897]
            ]
          ],
          [
            [
              [102.5156, 2.1185],
              [102.5306, 2.1591],
              [102.4814, 2.1838],
              [102.4983, 2.2207],
              [102.5193, 2.3527],
              [102.5436, 2.3871],
              [102.5906, 2.4178],
              [102.6138, 2.4643],
              [102.6138, 2.561],
              [102.6435, 2.6022],
              [102.6306, 2.6328],
              [102.7005, 2.829],
              [102.7932, 2.8015],
              [102.9675, 2.5965],
              [103.145, 2.519],
              [103.28, 2.5716],
              [103.3581, 2.5866],
              [103.4185, 2.5531],
              [103.4533, 2.5472],
              [103.478, 2.5167],
              [103.5865, 2.4617],
              [103.6348, 2.4786],
              [103.6156, 2.5295],
              [103.59, 2.5299],
              [103.5979, 2.5745],
              [103.6198, 2.6041],
              [103.5887, 2.653],
              [103.6303, 2.6664],
              [103.6678, 2.66],
              [103.7363, 2.6321],
              [103.8153, 2.573],
              [103.8456, 2.5064],
              [103.8175, 2.4897],
              [103.8397, 2.4353],
              [103.9112, 2.3501],
              [103.9355, 2.3439],
              [103.9506, 2.3005],
              [103.9801, 2.257],
              [103.9584, 2.2442],
              [103.9597, 2.2072],
              [104.0176, 2.129],
              [104.0453, 2.067],
              [104.1139, 1.9684],
              [104.105, 1.8894],
              [104.1325, 1.8464],
              [104.1878, 1.7847],
              [104.215, 1.6956],
              [104.2478, 1.6536],
              [104.2539, 1.5636],
              [104.2831, 1.5222],
              [104.2739, 1.5033],
              [104.2942, 1.4475],
              [104.2743, 1.4067],
              [104.2835, 1.3704],
              [104.2444, 1.3689],
              [104.2042, 1.3389],
              [104.1369, 1.3414],
              [104.0889, 1.3675],
              [104.1069, 1.405],
              [104.0411, 1.4614],
              [104.0408, 1.4953],
              [104.0201, 1.5761],
              [104.0101, 1.4672],
              [103.988, 1.4321],
              [103.9601, 1.4262],
              [103.8839, 1.4431],
              [103.8694, 1.4667],
              [103.795, 1.4843],
              [103.7675, 1.4554],
              [103.7162, 1.4649],
              [103.6507, 1.4061],
              [103.6378, 1.3731],
              [103.6063, 1.346],
              [103.5469, 1.3346],
              [103.5152, 1.3052],
              [103.5086, 1.2647],
              [103.4475, 1.3207],
              [103.4026, 1.4463],
              [103.3602, 1.5365],
              [103.3206, 1.5681],
              [103.2108, 1.6115],
              [103.1437, 1.6469],
              [103.0603, 1.7119],
              [103.0039, 1.7381],
              [102.9409, 1.7402],
              [102.8862, 1.7925],
              [102.8958, 1.8104],
              [102.8274, 1.8528],
              [102.7716, 1.8667],
              [102.6894, 1.8668],
              [102.6144, 1.9631],
              [102.5464, 2.0284],
              [102.5281, 2.0725],
              [102.4703, 2.1078],
              [102.4878, 2.112],
              [102.5156, 2.1185]
            ]
          ]
        ]
      },
      properties: {
        name: 'Johor',
        id: 'MY-01',
        CNTRY: 'Malaysia',
        TYPE: 'State'
      },
      id: 'MY-01'
    }
  ]
}
export default map
