import React, { useEffect, useState } from 'react'
import routes from '../../../config/routes'
import { Link, useLocation } from 'react-router-dom'

import './HeaderAreaDropdown.css'
import ChevronIcon from '../icons/ChevronIcon'
import { useTranslation } from 'react-i18next'

const HeaderAreaDropdown = ({ id, section }) => {
  const theRoutes = routes()
  const { i18n, t } = useTranslation()
  const [showDropdown, setShowDropdown] = useState(false)
  const [showSecondDropdown, setShowSecondDropdown] = useState(false)
  const [secondLevelLinks, setSecondLevelLinks] = useState([])
  const [activeItem, setActiveItem] = useState({})
  const [dropdownParent, setDropdownParent] = useState('')

  const location = useLocation()

  const hideDropdowns = () => {
    setShowDropdown(false)
    setShowSecondDropdown(false)
    setSecondLevelLinks([])
  }

  useEffect(() => {
    theRoutes.forEach(item => {
      if (item.path === location.pathname) setActiveItem(item)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const secondLevelLinksArea = {}

  secondLevelLinksArea.a1 = theRoutes
    .filter(item => item.isChild && item.parent === 'area1')
    .map((item, index) => {
      return (
        <Link
          to={item.path}
          key={item.name + index}
          className={`reactSelect__item rsi__small ${
            activeItem.name === item.name ? 'activeItem' : ''
          } ${item.disabled ? 'disabled' : ''}`}
          tabIndex={1}
        >
          {item.subareaNumber && (
            <span className="headerDropdown__item-number">
              {item.subareaNumber > 1
                ? item.subareaNumber - 1
                : item.subareaNumber}
              .
            </span>
          )}
          <span>
            {item.menuName ? item.menuName : item.name}{' '}
            {(item.subareaNumber === 2 || item.subareaNumber === 9) && (
              <em>({t('forthcoming')})</em>
            )}
          </span>
        </Link>
      )
    })

  secondLevelLinksArea.a3 = theRoutes
    .filter(item => item.isChild && item.parent === 'area3')
    .map((item, index) => {
      return (
        <Link
          to={item.path}
          key={item.name + index}
          className={`reactSelect__item rsi__small ${
            activeItem.name === item.name ? 'activeItem' : ''
          } ${item.disabled ? 'disabled' : ''}`}
          tabIndex="0"
        >
          {item.subareaNumber && (
            <span className="headerDropdown__item-number">
              {item.subareaNumber}.
            </span>
          )}
          <span>{item.menuName ? item.menuName : item.name}</span>
        </Link>
      )
    })

  const topLevelLinks = theRoutes
    .filter(
      item =>
        (item.hasChildren && (item.id === 'a1' || item.id === 'a3')) ||
        item.isTopLevel
    )
    .map((item, index) => {
      if (item.id === 'a1' || item.id === 'a3') {
        // these items have children
        return (
          <div
            key={item.name + index}
            tabIndex="0"
            className={`${
              item.isTopLevel
                ? `HeaderDropdown-topLevel hdt-${item.id}`
                : 'HeaderDropdown-item'
            } ${activeItem.parent === item.parentId ? 'activeArea' : ''}`}
            onMouseOver={event => {
              setSecondLevelLinks(secondLevelLinksArea[item.id])
              setDropdownParent(item.id)
              setShowSecondDropdown(true)
            }}
            onFocus={event => {
              setSecondLevelLinks(secondLevelLinksArea[item.id])
              setDropdownParent(item.id)
              setShowSecondDropdown(true)
            }}
          >
            {item.subareaNumber && (
              <span className="headerDropdown__item-number">
                {item.subareaNumber}.
              </span>
            )}
            <span style={{ fontWeight: '700' }}>Area {item.areaNumber}</span>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <span>{item.menuName ? item.menuName : item.areaName}</span>
            <ChevronIcon size={20} />
          </div>
        )
      }

      return (
        <Link
          to={item.path}
          key={item.name + index}
          className={`${activeItem.name === item.name ? 'activeArea' : ''}`}
          onMouseOver={e => hideDropdowns()}
          onFocus={hideDropdowns}
        >
          {item.subareaNumber && (
            <span className="headerDropdown__item-number">
              {item.subareaNumber}.
            </span>
          )}
          <span style={{ fontWeight: '700' }}>
            {t('Area')} {item.areaNumber}
          </span>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <span>{item.menuName ? item.menuName : item.areaName}</span>
        </Link>
      )
    })

  return (
    <li
      key={id}
      className="had__container"
      onMouseOver={() => setShowDropdown(true)}
      onFocus={() => setShowDropdown(true)}
      onMouseLeave={hideDropdowns}
      onBlur={hideDropdowns}
      onClick={hideDropdowns}
    >
      <Link to="/areas" className={section === 'areas' ? 'active' : ''}>
        {t('Areas')}
      </Link>
      {showDropdown && (
        <div className="headerAreaDropdown-container">
          <div className="headerAreaDropdown">{topLevelLinks}</div>
          {showSecondDropdown && (
            <div
              className={`headerSubAreaDropdown ${
                dropdownParent === 'a3' ? 'area3SubDropdown' : ''
              }`}
            >
              {secondLevelLinks}
            </div>
          )}
        </div>
      )}
    </li>
  )
}

export default HeaderAreaDropdown
