import React, { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'

import { useTranslation } from 'react-i18next'
import { cGradientsArea4 } from '../../config/constants'
import useGqlEntriesSpecialProcedures from '../../gql/useGqlEntriesSpecialProcedures'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import useGqlTextComponents from '../../gql/useGqlTextComponents'
import MainLayout from '../../layouts/MainLayout'
import { isRecommendationSP } from '../../lib/fnAreasPRHelper'
import MapTooltip from '../../mocks/components/StyledMapToolTips'

import '../../pages/Area.css'

import AreaContent from '../components/Areas/AreaContent'
import LineChart2 from '../components/Areas/A4/LineChart2'
import MapContainer from '../components/Map/MapContainer'
import microstates from '../components/Map/microstates.json'
import TableContainer from '../components/Table/TableContainer'
import MainSpinner from '../components/ui/MainSpinner'
import { citeA4, columns } from '../config/a4columns'

export default function Area4() {
  const htmlReferences = useRef()
  const [references, setReferences] = useState([])
  const [drillDown, setDrillDown] = useState('0')
  const [loadingMap, setLoadingMap] = useState(false)
  const [currentSP, setCurrentSP] = useState(null)
  const [mentions, setMentions] = useState(1)
  const {
    entriesSpecialProcedures,
    specialProceduresTypes,
    loading,
    error
  } = useGqlEntriesSpecialProcedures()
  const {
    jurisdictions,
    loading: loadingJur,
    error: errorJur,
    jurisdictionsById
  } = useGqlJurisdictions()
  const [mapLegendStepGradient, setMapLegendStepGradient] = useState(null)
  const [manualFilter, setManualFilter] = useState([])
  const { t, i18n } = useTranslation()
  const { textComponents } = useGqlTextComponents('A4')
  const tooltip = e => {
    return ReactDOMServer.renderToStaticMarkup()
  }

  useEffect(() => {
    if (
      !loading &&
      !error &&
      jurisdictions &&
      entriesSpecialProcedures &&
      specialProceduresTypes &&
      specialProceduresTypes.length > 0 &&
      !loadingMap
    ) {
      setLoadingMap(true)
      let referencesTmp = []
      let maxRecommendations = 0
      const n_by_type_template = {}
      for (let tb of specialProceduresTypes) {
        n_by_type_template[tb.id] = 0
      }
      for (let j of jurisdictions) {
        if (j.a2_code === 'G0') {
          continue
        }
        let jurEntries = []
        jurEntries = entriesSpecialProcedures.filter(
          e =>
            (e.motherEntry.jurisdiction.id === j.id ||
              (e.motherEntry.jurisdictions &&
                e.motherEntry.jurisdictions.find(jur => jur.id === j.id))) &&
            (mentions === 1 || isRecommendationSP(e)) &&
            (!currentSP ||
              e.special_procedure_types.find(sp => sp.id == currentSP))
        )

        if (maxRecommendations < jurEntries.length)
          maxRecommendations = jurEntries.length

        let n_by_type = {}

        let total = 0

        for (let jurEntry of jurEntries) {
          for (let sp of jurEntry.special_procedure_types) {
            if (!n_by_type[sp.id]) n_by_type[sp.id] = 1
            else n_by_type[sp.id]++
            total++
          }
        }

        if (jurEntries.length)
          referencesTmp.push({
            id: j.a2_code,
            regions: j.regions,
            entry: {
              motherEntry: { jurisdiction: j },
              n_recommendations: 0
            },

            microstate: microstates.find(ms => ms.A2 === j.a2_code),
            n_recommendations: total,
            reference: '#000',
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <MapTooltip
                entry={{
                  motherEntry: { jurisdiction: j },
                  n_recommendations: total,
                  valT:
                    total +
                    ' / ' +
                    maxRecommendations +
                    ' * ' +
                    cGradientsArea4.length /*Math.floor(
                    (jurEntries.length / maxRecommendations) * (cGradientsArea4.length - 2)
                  ),*/,
                  n_by_type
                }}
                area={'A4'}
                extraProps={{
                  specialProceduresTypes: specialProceduresTypes,
                  currentSP,
                  mentions
                }}
              />
            )
          })
        else
          referencesTmp.push({
            id: j.a2_code,
            regions: j.regions,
            entry: {
              motherEntry: { jurisdiction: j },
              n_recommendations: 0
            },
            microstate: microstates.find(ms => ms.A2 === j.a2_code),
            n_recommendations: jurEntries.length,
            reference: '#000',
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <MapTooltip
                entry={{
                  motherEntry: { jurisdiction: j },
                  n_recommendations: 0,
                  n_by_type
                }}
                area={'A4'}
                extraProps={{
                  specialProceduresTypes: specialProceduresTypes,
                  currentSP,
                  mentions
                }}
              />
            )
          })
      }

      referencesTmp = referencesTmp.map(r => ({
        ...r,

        reference:
          r.n_recommendations === 0
            ? cGradientsArea4[0]
            : r.n_recommendations < 10
            ? cGradientsArea4[1]
            : r.n_recommendations < 30
            ? cGradientsArea4[2]
            : r.n_recommendations < 60
            ? cGradientsArea4[3]
            : cGradientsArea4[4]
      }))
      console.log(referencesTmp)
      setReferences(referencesTmp)
      let labels = []
      labels[0] = 0
      labels[cGradientsArea4.length - 1] = maxRecommendations
      labels = [
        0,
        '1 ' + t('_to_') + ' 10',
        '10 ' + t('_to_') + ' 30',
        '30 ' + t('_to_') + ' 60',
        '60+'
      ]
      setMapLegendStepGradient({
        colors: cGradientsArea4,
        labels,
        title: t('Number of Recommendations (since 2016)')
      })
      setLoadingMap(false)
    }
  }, [
    jurisdictions,
    entriesSpecialProcedures,
    error,
    loading,
    currentSP,
    mentions
  ])

  const [activeTab, setActiveTab] = useState('recsOnly')

  const areaInfo = {
    title: `${t('AREA')} 4`,
    subTitle: `${t('Special Procedures')}`,
    areaName: 'area-4',
    subSubTitle: `${t('Special Procedures')} - ${
      mentions === 1
        ? t('Recommendations and mentions')
        : t('Recommendations only')
    }${
      currentSP && specialProceduresTypes.find(sp => sp.id == currentSP)
        ? ' - ' + specialProceduresTypes.find(sp => sp.id == currentSP).name
        : ''
    }`
  }

  return (
    <MainLayout section="areas">
      <div className="area__page area4__page">
        <section className="selectRegion__section">
          <MapContainer
            references={references}
            htmlReferences={htmlReferences}
            jurisdictionsById={jurisdictionsById}
            jurisdictions={jurisdictions}
            mapLegendStepGradient={mapLegendStepGradient}
            menuVariation="procedures"
            area="A4"
            currentArea="A4"
            extraProps={{
              setCurrentSP,
              currentSP,
              mentions,
              setMentions,
              specialProceduresTypes: specialProceduresTypes
            }}
            modalTitle={t('All Special Procedures')}
            modalContent={textComponents.length && textComponents[0].map}
            title={areaInfo.title}
            subTitle={areaInfo.subTitle}
            subSubTitle={areaInfo.subSubTitle}
            areaName={areaInfo.areaName}
          />
        </section>
        <div className="content__container">
          <AreaContent areaIntro={'A4'} areaLatest={'A4'} />

          <section className="recommendations__section">
            <div className="recs__left">
              <h5>{t('Top Recommending Mandates')}</h5>
              <h4>{t('Recommendations since 2016')}</h4>
              {/* I think This loading should go false after the map and sort are done */}
              {!loading ? (
                <div className="recs__legend">
                  <div className="recs__actions">
                    <button
                      className={`recs__tabBtn ${
                        activeTab === 'recsOnly' ? 'active' : ''
                      }`}
                      onClick={() => setActiveTab('recsOnly')}
                    >
                      {t('Recommendations only')}
                    </button>
                    <button
                      className={`recs__tabBtn ${
                        activeTab === 'recsAndMentions' ? 'active' : ''
                      }`}
                      onClick={() => setActiveTab('recsAndMentions')}
                    >
                      {t('Recommendations and mentions')}
                    </button>
                  </div>
                  {activeTab === 'recsOnly' && (
                    <div className="recommendations__list">
                      {specialProceduresTypes &&
                        specialProceduresTypes.length > 0 &&
                        specialProceduresTypes
                          .slice()
                          .sort((a, b) => {
                            if (a.amount_rec > b.amount_rec) return -1
                            else if (a.amount_rec < b.amount_rec) return 1
                            else if (a.name < b.name) return -1
                            else if (a.name > b.name) return 1
                            else return 0
                          })
                          .map((t, index) => (
                            <div className="recommendations__listItem">
                              <span>{index + 1}</span>
                              <span>{t.name}</span>
                              <span>{t.amount_rec}</span>
                            </div>
                          ))}
                    </div>
                  )}
                  {activeTab === 'recsAndMentions' && (
                    <div className="recommendations__list">
                      {specialProceduresTypes
                        .slice()
                        .sort((a, b) => {
                          if (a.amount_all > b.amount_all) return -1
                          else if (a.amount_all < b.amount_all) return 1
                          else return 0
                        })
                        .map((t, index) => (
                          <div className="recommendations__listItem">
                            <span>{index + 1}</span>
                            <span>{t.name}</span>
                            <span>{t.amount_all}</span>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{ position: 'relative', flexGrow: 1, height: '480px' }}
                >
                  <MainSpinner text={t('Loading chart...')} />
                </div>
              )}
            </div>
            <div className="mainGraphic__section recs__right">
              <h5>{t('Total references from all mandates by year')}</h5>
              <h4>{t('Recommendations and mentions since')} 2016</h4>
              {entriesSpecialProcedures &&
              entriesSpecialProcedures.length > 0 ? (
                <LineChart2 references={entriesSpecialProcedures} />
              ) : (
                <MainSpinner text={t('Loading chart...')} />
              )}
            </div>
          </section>
        </div>

        <section className="table__section">
          <div className="content__container">
            <>
              {specialProceduresTypes && (
                <TableContainer
                  entries={entriesSpecialProcedures}
                  t={t}
                  columns={
                    specialProceduresTypes &&
                    columns(specialProceduresTypes.filter(sp => sp.amount_all))
                  }
                  citeEntry={citeA4}
                  expandable={false}
                  paginate={true}
                  showSubjurSwitch={false}
                  title={t('Area') + ' 4'}
                  subtitle={t('Special Procedures')}
                  manualFilter={manualFilter}
                  initialSortBy={[
                    {
                      id: 'motherEntry.jurisdiction.name',
                      desc: false
                    }
                  ]}
                />
              )}
            </>
          </div>
        </section>
      </div>
    </MainLayout>
  )
}
