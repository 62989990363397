import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import * as d3geoP from 'd3-geo-projection'
import React, { useLayoutEffect, useRef, useState, useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'
import { history } from '../../../App.js'
import am4geodataLangEN from '../../../lang/maps/en'
import am4geodataLangES from '../../../lang/maps/es'
import MapControls from '../MapControls/MapControls'
import StyledMapTooltips from '../StyledMapToolTips'
import LoadingMap from '../ui/LoadingMap'
import './Map.css'
import af from './maps/africaLow'
import am from './maps/americasLow'
import asH from './maps/asiaHigh'
import as from './maps/asiaLow'
import ca from './maps/caribbeanLow'
import gaza from './maps/countries/gazaIllegal.js'
import indonesia from './maps/countries/indonesiaDeath.js'
import nigeria from './maps/countries/nigeriaDeath'
import usaFoe from './maps/countries/usaLowFoe.js'
import wba from './maps/countries/westBankLegal.js'
import eu from './maps/europeLow'
import sa from './maps/latinAmericaLow'
import me from './maps/middleEastLow'
import na from './maps/northAmericaLow'
import oc from './maps/oceaniaLow'
import worldL from './maps/worldLow'
import microstates from './microstates'

import AE from '@amcharts/amcharts4-geodata/uaeLow'
import CN from '../../../components/amcharts4-geodata/0_CNLow.js'
import AR from '../../../components/amcharts4-geodata/0_ARLow.js'
import AT from '@amcharts/amcharts4-geodata/austriaLow'
import AU from '@amcharts/amcharts4-geodata/australiaLow'
import BA from '@amcharts/amcharts4-geodata/bosniaHerzegovinaLow'
import BR from '@amcharts/amcharts4-geodata/brazilLow'
import CA from '@amcharts/amcharts4-geodata/canadaLow'
import DE from '@amcharts/amcharts4-geodata/germanyLow'
import MY from '../../../components/amcharts4-geodata/0_MYLow.js'
import ES from '../../../components/amcharts4-geodata/0_ESLow.js'
import GB from '../../../components/amcharts4-geodata/0_UK2Low.js' // "@amcharts/amcharts4-geodata/ukCountriesLow";
import ID from '@amcharts/amcharts4-geodata/indonesiaLow'
import IN from '../../../components/amcharts4-geodata/0_INLow.js'
import IT from '../../../components/amcharts4-geodata/0_ITLow.js'
import JP from '@amcharts/amcharts4-geodata/japanLow'
import KR from '@amcharts/amcharts4-geodata/southKoreaLow'
import MX from '@amcharts/amcharts4-geodata/mexicoLow'
import NG from '@amcharts/amcharts4-geodata/nigeriaLow'
import PE from '../../../components/amcharts4-geodata/0_PELow.js'
import PH from '../../../components/amcharts4-geodata/0_PHLow.js'
import PS from '@amcharts/amcharts4-geodata/palestineLow'
import US from '../../../components/amcharts4-geodata/usaTerritoriesLow.js'
import { dispatch } from '@svgdotjs/svg.js'

am4core.options.onlyShowOnViewport = false

const mapSubjurNames = subjur => {
  const originalName = subjur.name

  const newNames = {
    'Autonomous City of Buenos Aires': 'Autonomous City\nof Buenos Aires',
    'Ciudad Autónoma de Buenos Aires': 'Ciudad Autónoma\nde Buenos Aires',
    'Santiago del Estero': 'Santiago\ndel Estero',
    'Islas Malvinas (Reino Unido / Argentina)': 'Islas Malvinas',
    'Georgias del Sur y Sandwich (Reino Unido/Argentina)':
      'Islas Georgias y Sandwich del Sur',
    'Northern Ireland': 'Northern\nIreland',
    'Irlanda del Norte': 'Irlanda\ndel Norte',
    'Rio Grande do Norte': 'Rio Grande\ndo Norte',
    'Espírito Santo': 'Espírito\nSanto',
    'Mato Grosso do Sul': 'Mato Grosso\ndo Sul',
    'Rio Grande do Sul': 'Rio Grande\ndo Sul',
    'Rio Grande del Norte': 'Rio Grande\ndel Norte',
    'Espíritu Santo': 'Espíritu\nSanto',
    'Mato Grosso del Sur': 'Mato Grosso\ndel Sul',
    'Rio Grande del Sur': 'Rio Grande\ndel Sul',
    'Rio de Janeiro': 'Rio de\nJaneiro',
    'Metropolitan Municipality de Lima': 'Lima (P)',
    'Mexico City': 'CDMX',
    'Abuja Capital Territory': 'Abuja Capital\nTerritory',
    'Cross River': 'Cross\nRiver',
    'Akwa Ibom': 'Akwa\nIbom',
    'Dadra and Nagar Haveli and Daman and Diu':
      'Dadra and Nagar Haveli\nand Daman and Diu',
    'Jammu and Kashmir': 'Jammu and\nKashmir',
    'Himachal Pradesh': 'Himachal\nPradesh',
    'West Bengal': 'West\nBengal',
    'Andaman and Nicobar Islands': 'Andaman and\nNicobar Islands',
    'Arunachal Pradesh': 'Arunachal\nPradesh',
    'Andhra Pradesh': 'Andhra\nPradesh',
    'North Chungcheong': 'North\nChungcheong',
    'South Chungcheong': 'South\nChungcheong',
    'Chungcheong del Norte': 'Chungcheong\ndel Norte',
    'Chungcheong del Sur': 'Chungcheong\ndel Sur',
    'Taiwan (China)': ' ' //Disable Taiwan China
  }

  return newNames[originalName] ? newNames[originalName] : originalName
}

const Map = React.forwardRef(function Map(
  {
    homeMap = false,
    projection,
    pacificCenter = false,
    seaColor = '#d8e3e5',
    highRes = false,
    showLabels = false,
    landColor = '#CCC',
    strokeColor = '#D8E3E5',
    disabledColor = '#EEE',
    references,
    htmlReferences,
    jurisdictions,
    hideMicrostates = true,
    editor = false,
    drillDown = '0',
    loadingData,
    mapClicked = () => {},
    mapLoaded,
    setMapLoaded,
    currentArea,
    extraProps = { categories: null },
    jurisdictionsWithDrillDown,
    drilledDown,
    setDrilledDown,
    showLoader = true,
    showTooltip = true,
    include,
    fontRatio = 1,
    hideZoom,
    jurSubjurs
  },
  chart
) {
  const loadingChart = useRef()

  const [lowQ, setLowQ] = useState(true)

  const [preventReloadDrillDown, setPreventReloadDrillDown] = useState(true)

  //FOR SETTING THE LABELS LOCATIONS ONLY
  const [textArea, setTextArea] = useState('')
  const [lastState, _setLastState] = useState({})
  const lastStateRef = useRef(lastState)
  const setLastState = data => {
    lastStateRef.current = data
    _setLastState(data)
  }
  const [labelsLocationsEd, _setLabelsLocationsEd] = useState({})
  const labelsLocationsRef = useRef(labelsLocationsEd)
  const setLabelsLocations = data => {
    labelsLocationsRef.current = data
    _setLabelsLocationsEd(data)
  }

  const [labelFontSize, _setLabelFontSize] = useState(3)
  const labelFontSizeRef = useRef(labelFontSize)
  const setLabelFontSize = data => {
    labelFontSizeRef.current = data
    _setLabelFontSize(data)
  }
  const { t, i18n } = useTranslation()
  /*
    { id: "0", name: "Global" },
    { id: "12", name: "Europe" },
    { id: "1", name: "Africa" },
    { id: "2", name: "Asia" },
    { id: 5, name: "NorthAmerica" },
    { id: "13", name: "LatinAmerica" },
    { id: "3", name: "Caribbean" },
    { id: "16", name: "Oceania" },
    { id: "14", name: "SWANA" }
*/
  const originalTooltips = {}

  let preloadCountries = {}

  preloadCountries = {
    AE,
    AR,
    AT,
    AU,
    BA,
    BR,
    CA,
    CN,
    DE,
    ES,
    GB,
    MY,
    ID,
    IN,
    IT,
    JP,
    KR,
    MX,
    NG,
    PE,
    PH,
    PS,
    US
  }

  const drillDownToCountry = (
    countryId,
    templates = [],
    series = [],
    ev = false
  ) => {
    // DISABLE INDONESIA REQUEST FROM LUCAS
    if (ev && ev.target.dataItem.dataContext.id === 'ID') return
    unloadLabels()
    if (ev) {
      if (ev.target.dataItem.dataContext.id === 'US') {
        if (!drillDown || true)
          chart.current.map.zoomToGeoPoint(
            {
              latitude: 40.851857629,
              longitude: -140.84848905
            },
            3.5
          )
        else
          chart.current.map.zoomToGeoPoint(
            {
              latitude: 40.851857629,
              longitude: -100.84848905
            },
            3.5
          )
      } else if (ev.target.dataItem.dataContext.id === 'ES') {
        if (!drillDown || true)
          chart.current.map.zoomToGeoPoint(
            {
              latitude: 40.84848905,
              longitude: -4.851857629
            },
            10.5
          )
        else
          chart.current.map.zoomToGeoPoint(
            {
              latitude: 35.84848905,
              longitude: -4.851857629
            },
            5.5
          )
      } else if (ev.target.dataItem.dataContext.id === 'KR') {
        if (!drillDown || true)
          chart.current.map.zoomToGeoPoint(
            {
              latitude: 37.04,
              longitude: 135.1184
            },
            18.5
          )
      } else if (ev.target.dataItem.dataContext.id === 'GB') {
        if (!drillDown || true)
          chart.current.map.zoomToGeoPoint(
            {
              latitude: 57.352,
              longitude: -5.9982
            },
            13.5
          )
      } else {
        chart.current.map.zoomToMapObject(series[0].getPolygonById(countryId))
      }
    }

    const createChinaMicrostates = (states, region_id) => {
      let placeSeries
      let place
      let circle
      const allPlacesSeries = []
      const microLabelSeries = chart.current.map.series.push(
        new am4maps.MapImageSeries()
      )
      const microLabelTemplate = microLabelSeries.mapImages.template.createChild(
        am4core.Label
      )
      microLabelTemplate.horizontalCenter = 'middle'
      microLabelTemplate.verticalCenter = 'middle'
      microLabelTemplate.fontSize = 3
      microLabelTemplate.nonScaling = false
      microLabelTemplate.interactionsEnabled = false

      placeSeries = chart.current.map.series.push(new am4maps.MapImageSeries())
      allPlacesSeries.push(placeSeries)
      place = placeSeries.mapImages.template
      // place.nonScaling = true;
      place.propertyFields.latitude = 'latitude'
      place.propertyFields.longitude = 'longitude'
      placeSeries.tooltip.getFillFromObject = false
      placeSeries.tooltip.autoTextColor = false
      placeSeries.tooltip.background.fill = am4core.color('#FFF')
      placeSeries.tooltip.label.fill = am4core.color('#000')
      placeSeries.tooltip.label.padding(1, 1, 1, 1)

      placeSeries.data = seriesMicroStates(region_id)
        .filter(state => states.indexOf(state.A2) !== -1)
        .map(state => {
          const ref = references.find(ref => ref.id === state.A2)
          return {
            latitude: state.Lat,
            longitude: state.Long - 0,
            name: t(state['Full name']),
            hcenter: 'right',
            vcenter: 'middle',
            link: ref ? ref.link : null,
            fill:
              include &&
              include.length &&
              true &&
              include.indexOf(state.A2) === -1
                ? disabledColor
                : am4core.color(
                    editor ? '#fff' : ref ? ref.reference : disabledColor
                  ),
            A2: state.A2,
            id: state.A2,
            reference_text: ref && ref.reference_text ? ref.reference_text : '',
            tooltip: ref && ref.tooltip ? ref.tooltip : '',
            fillOpacity: 1
          }
        })
      /*
      circle = place.createChild(am4core.Circle)
      console.log(placeSeries.data)
      circle.radius =
        2 * 1.275 * 1 * ((chart.current.map.innerWidth / 1340) * 1)

      circle.stroke = am4core.color(strokeColor)
      circle.strokeWidth = 0.2 // 1 / regionSeries[regionId].microStateExtraSize;
      // circle.showTooltipOn = "hit"; //To show always
      if (showTooltip) {
        if (references && references.length) circle.tooltipHTML = '{tooltip}'
        else circle.tooltipHTML = '{name}'
      }

      circle.propertyFields.fill = 'fill'
      circle.propertyFields.fillOpacity = 'fillOpacity'

      const hs2 = circle.states.create('hover')
      hs2.properties.fillOpacity = 0.9
      */
    }

    setTimeout(
      () => {
        console.log(preloadCountries, countryId)
        const drillDownCountrySeries = chart.current.map.series.push(
          new am4maps.MapPolygonSeries()
        )
        drillDownCountrySeries.useGeodata = true

        drillDownCountrySeries.geodata = preloadCountries[countryId]
        if (countryId === 'CN') createChinaMicrostates(['HK', 'MO'], '2')
        if (countryId === 'GB') createChinaMicrostates(['IM', 'GG', 'JE'], '11')
        //if (countryId === 'AR') createChinaMicrostates(['FK', 'GS'], '12')

        if (editor) {
          console.log(preloadCountries, countryId)
          references = preloadCountries[countryId].features.map(feat => ({
            id: feat.id,
            reference_text: 'AAA',
            reference: '#F00',
            entry: {
              motherEntry: {
                jurisdiction: { a2_code: countryId },
                subjurisdiction: {
                  id: feat.id,
                  name: jurSubjurs.find(s => s.id === feat.id)
                    ? jurSubjurs.find(s => s.id === feat.id).name
                    : 'AA'
                }
              }
            },
            regions: ['TRUE']
          }))
        }

        references
          .filter(
            r =>
              (r.entry.motherEntry.jurisdiction.a2_code.toUpperCase() ===
                countryId &&
                r.entry.motherEntry.subjurisdiction) ||
              ((r.entry.motherEntry.jurisdiction.id === 'DT-11' ||
                r.entry.motherEntry.jurisdiction.id === 'DT-38') &&
                countryId === 'AR') || // ADD GEWORGIA AND MALVINAS TO ARG
              ((r.entry.motherEntry.jurisdiction.id === 'DT-21' ||
                r.entry.motherEntry.jurisdiction.id === 'DT-19' ||
                r.entry.motherEntry.jurisdiction.id === 'DT-22') &&
                countryId === 'GB') || // ADD GEWORGIA AND MALVINAS TO ARG
              ((r.entry.motherEntry.jurisdiction.id.indexOf('DT-') !== -1 ||
                r.entry.motherEntry.jurisdiction.id.indexOf('S-') !== -1) &&
                drillDownCountrySeries.geodata.features
                  .map(f => f.id)
                  .indexOf(r.entry.motherEntry.jurisdiction.id) !== -1) // INCLUDE DEPENDENT TERRITORIES
          )

          .forEach(obj => {
            originalTooltips[obj.id] = obj.tooltip

            let theTooltip = obj.tooltip

            if (obj.entry.motherEntry.jurisdiction.id === 'S-1') theTooltip = ''

            drillDownCountrySeries.data = [
              ...drillDownCountrySeries.data,
              obj.regions
                ? {
                    // Country in continent
                    id: obj.id,

                    reference_text: obj.reference_text,
                    fill: am4core.color(
                      editor
                        ? '#fff'
                        : obj.reference
                        ? obj.reference
                        : '#999900'
                    ),
                    tooltip: theTooltip || '',
                    fillOpacity: 1,
                    link: obj.link
                  }
                : {
                    // Country outside continent
                    id: obj.id,

                    fill:
                      !hideMicrostates && obj.microstate
                        ? am4core.color(seaColor)
                        : am4core.color(disabledColor),
                    tooltip: '',
                    fillOpacity: 0.8,
                    link: obj.link
                  }
            ]
          })

        drillDownCountrySeries.tooltip.getFillFromObject = false
        drillDownCountrySeries.tooltip.autoTextColor = false
        drillDownCountrySeries.tooltip.background.fill = am4core.color('#FFF')
        drillDownCountrySeries.tooltip.label.fill = am4core.color('#000')
        drillDownCountrySeries.tooltip.label.padding(1, 1, 1, 1)

        drillDownCountrySeries.tooltip.label.wrap = false
        const drillDownCountryTemplate =
          drillDownCountrySeries.mapPolygons.template
        if (showTooltip) {
          drillDownCountryTemplate.tooltipHTML = '{tooltip}'
        }
        drillDownCountryTemplate.fill = disabledColor
        drillDownCountryTemplate.propertyFields.fill = 'fill'
        drillDownCountryTemplate.propertyFields.fillOpacity = 'fillOpacity'
        drillDownCountryTemplate.stroke = am4core.color(strokeColor)
        drillDownCountryTemplate.strokeWidth = 0.4
        drillDownCountryTemplate.strokeOpacity = 1

        const drillDownCountryHover = drillDownCountryTemplate.states.create(
          'hover'
        )
        drillDownCountryHover.properties.fillOpacity = 0.8
        const createSubjurLabels = (except = []) => {
          for (let i = 1; i <= chart.current.map.series.length - 1; i++)
            chart.current.map.series.removeIndex(i).dispose()
          const labelSeries = chart.current.map.series.push(
            new am4maps.MapImageSeries()
          )
          const labelTemplate = labelSeries.mapImages.template.createChild(
            am4core.Label
          )

          labelTemplate.horizontalCenter = 'middle'
          labelTemplate.verticalCenter = 'middle'
          labelTemplate.fontSize = 10 / chart.current.map.zoomLevel
          labelTemplate.fontFamily = "'Lato', sans-serif"
          labelTemplate.textAlign = 'middle'
          labelTemplate.nonScaling = false
          labelTemplate.interactionsEnabled = false
          labelTemplate.wrap = false

          function drawLabels() {
            //Hack for huge drilldown fonts

            let fontSizeRatio = 1
            if (chart.current.map.series.values.length > 3) {
              //world map
              fontSizeRatio = 0.3 //AU, AR
              if (countryId === 'KR') fontSizeRatio = 0.08
              else if (countryId === 'PE') fontSizeRatio = 0.15
              else if (countryId === 'JP') fontSizeRatio = 0.15
              else if (countryId === 'ES') fontSizeRatio = 0.08
              else if (countryId === 'PH') fontSizeRatio = 0.1
              else if (countryId === 'IT') fontSizeRatio = 0.1
              else if (countryId === 'NG') fontSizeRatio = 0.1
              else if (countryId === 'DE') fontSizeRatio = 0.08
              else if (countryId === 'GB') fontSizeRatio = 0.08
              else if (countryId === 'MX') fontSizeRatio = 0.15
            }
            drillDownCountrySeries.mapPolygons.each(function(polygon) {
              const subJurRef = references.find(
                ref =>
                  (ref.entry.motherEntry.subjurisdiction &&
                    ref.entry.motherEntry.subjurisdiction.id ===
                      polygon.dataItem.dataContext.id) ||
                  ref.entry.motherEntry.jurisdiction.a2_code.toUpperCase() ===
                    polygon.dataItem.dataContext.id
              )

              if (
                subJurRef &&
                subJurRef.entry.motherEntry.subjurisdiction &&
                except.indexOf(
                  subJurRef.entry.motherEntry.subjurisdiction.id
                ) !== -1
              )
                return
              const label = labelSeries.mapImages.create()
              const state = polygon.dataItem.dataContext.id.split('-').pop()
              let labelRef = {
                latitude: labelsLocationsRef.current[
                  polygon.dataItem.dataContext.id
                ]
                  ? labelsLocationsRef.current[
                      polygon.dataItem.dataContext.id
                    ][1]
                  : polygon.visualLatitude,
                longitude: labelsLocationsRef.current[
                  polygon.dataItem.dataContext.id
                ]
                  ? labelsLocationsRef.current[
                      polygon.dataItem.dataContext.id
                    ][0]
                  : polygon.visualLongitude
              }
              if (subJurRef && countryId !== 'US' && countryId !== 'PH') {
                labelRef.text = mapSubjurNames(
                  subJurRef.entry.motherEntry.subjurisdiction ||
                    subJurRef.entry.motherEntry.jurisdiction
                )
              } else labelRef.text = state

              //onsole.log(labelRef.text)

              if (subJurRef && subJurRef.entry.motherEntry.subjurisdiction) {
                if (subJurRef.entry.motherEntry.subjurisdiction.label_latitude)
                  labelRef.latitude =
                    subJurRef.entry.motherEntry.subjurisdiction.label_latitude
                if (subJurRef.entry.motherEntry.subjurisdiction.label_longitude)
                  labelRef.longitude =
                    subJurRef.entry.motherEntry.subjurisdiction.label_longitude
                if (subJurRef.entry.motherEntry.subjurisdiction.label_font_size)
                  label.children.getIndex(0).fontSize =
                    subJurRef.entry.motherEntry.subjurisdiction
                      .label_font_size * fontSizeRatio
              }
              let extraLatLong = { lat: 0, long: 0 }
              if (
                subJurRef &&
                subJurRef.entry.motherEntry.jurisdiction.a2_code.toUpperCase() ===
                  'HK'
              ) {
                extraLatLong = { lat: 0.3, long: 4 }
              } else if (
                subJurRef &&
                subJurRef.entry.motherEntry.jurisdiction.a2_code.toUpperCase() ===
                  'MO'
              ) {
                extraLatLong = { lat: -0.3, long: 0 }
              }
              label.latitude = labelRef.latitude + extraLatLong.lat

              label.longitude = labelRef.longitude + extraLatLong.long
              if (
                editor &&
                labelsLocationsRef.current[polygon.dataItem.dataContext.id] &&
                labelsLocationsRef.current[polygon.dataItem.dataContext.id][2]
              )
                label.children.getIndex(0).fontSize =
                  labelsLocationsRef.current[polygon.dataItem.dataContext.id][2]
              label.children.getIndex(0).text = labelRef.text
            })
          }
          drillDownCountrySeries.events.on('ready', drawLabels)
          if (editor) drawLabels()
        }
        if (editor) {
          const subJurClicked = ev => {
            unloadLabels()

            for (let i = 1; i <= chart.current.map.series.length - 1; i++)
              chart.current.map.series.removeIndex(i).dispose()

            let subjurId = ''
            if (!ev.target.dataItem || !ev.target.dataItem.dataContext.id) {
              subjurId = lastStateRef.current
            } else {
              if (ev.event.ctrlKey || ev.event.metaKey) {
                setLastState(ev.target.dataItem.dataContext.id)
              } else {
                subjurId = lastStateRef.current
              }
            }
            createSubjurLabels([subjurId])
            const labelSeries = chart.current.map.series.push(
              new am4maps.MapImageSeries()
            )
            const labelTemplate = labelSeries.mapImages.template.createChild(
              am4core.Label
            )

            labelTemplate.horizontalCenter = 'middle'
            labelTemplate.verticalCenter = 'middle'
            labelTemplate.fontSize =
              (editor &&
              labelsLocationsRef.current[subjurId] &&
              labelsLocationsRef.current[subjurId][2]
                ? labelsLocationsRef.current[subjurId][2]
                : 10) / chart.current.map.zoomLevel
            labelTemplate.fontFamily = "'Lato', sans-serif"
            labelTemplate.textAlign = 'middle'
            labelTemplate.nonScaling = false
            labelTemplate.interactionsEnabled = false
            labelTemplate.wrap = false
            const label = labelSeries.mapImages.create()
            label.latitude = chart.current.map.svgPointToGeo(
              ev.svgPoint
            ).latitude
            label.longitude = chart.current.map.svgPointToGeo(
              ev.svgPoint
            ).longitude

            label.children.getIndex(0).text = 'ACA'
            setLabelsLocations({
              ...labelsLocationsRef.current,
              [subjurId]: [
                label.longitude,
                label.latitude,
                labelFontSizeRef.current
              ]
            })
          }
          chart.current.map.events.on('hit', ev => {
            subJurClicked(ev)
          })
          drillDownCountryTemplate.events.on('hit', function(ev) {
            subJurClicked(ev)
          })
        }
        if (showLabels) {
          createSubjurLabels()
        }

        for (const countriesSeries of chart.current.allSeries) {
          countriesSeries.hide() // removeIndex(chart.current.map.series.indexOf(countriesSeries));

          // Zoom to clicked element
        }
        for (const labels of chart.current.labelSeries) {
          labels.hide() // removeIndex(chart.current.map.series.indexOf(countriesSeries));

          // Zoom to clicked element
        }
        /* if (countryId == "US") {
            chart.current.map.projection = new am4maps.projections.Mercator();
            for (let countriesSeries of series) {
              chart.current.map.series.removeIndex(chart.current.map.series.indexOf(countriesSeries));
              // Zoom to clicked element
            }
                      } */
      },
      ev ? chart.current.map.zoomDuration + 100 : 1
    )

    setDrilledDown(countryId)
    setPreventReloadDrillDown(false)
    // chart.current.map.projection.d3Projection=new am4maps.projections.Orthographic();
  }

  const customActions = async (templates, series, extraProps) => {
    if (extraProps.heatMap === 1 || extraProps.heatMap === 2) {
      // AREA 5
      if (
        extraProps.heatMap === 1 &&
        !localStorage.getItem('heatmap1_clicked')
      ) {
        chart.current.map.openModal(
          t(
            'Click on a State to display the number of SOGIESC recommendations it received and the States that have issued those recommendations during the third UPR cycle. The text of each recommendation can be accessed via the chart down below.'
          ) + '<br /><br /><br /><br />'
        )
        chart.current.map.modal.events.on('closed', function(ev) {
          localStorage.setItem('heatmap1_clicked', 'yes')
        })
      } else if (
        extraProps.heatMap === 2 &&
        !localStorage.getItem('heatmap2_clicked')
      ) {
        chart.current.map.openModal(
          t(
            'Click on a State to display the number of SOGIESC recommendations it has issued and the States to which those recommendations were directed during the third UPR cycle. The text of each recommendation can be accessed via the chart down below.'
          ) + '<br /><br /><br /><br />'
        )
        chart.current.map.modal.events.on('closed', function(ev) {
          localStorage.setItem('heatmap2_clicked', 'yes')
        })
      }
      for (const countriesSeries of series) {
        countriesSeries.data = countriesSeries.data.map(d => {
          return {
            ...d,
            ...(d.id !== 'EH'
              ? { fill: am4core.color('#FFF') }
              : { fill: am4core.color(disabledColor), tooltip: '' })
            /* tooltip:
                extraProps.heatMap === 1
                  ? "Click to show received recommendations"
                  : "Click to show issued recommendations" */
          }
        })
      }
    } else {
      // WEIRD HACK TO LOAD THE "HIT" EVENT ON NEXT LOOP
      //series[0].data = series[0].data
    }

    for (const countriesTemplate of templates) {
      countriesTemplate.events.on('hit', function(ev) {
        loadClickAction(ev, countriesTemplate)
      })
    }
  }

  const [showBtns, setShowBtns] = useState(false)

  const regionSeries = {
    0: {
      map: { low: worldL, high: worldL },
      microStateExtraSize: 1,
      lineSeparation: 0.2
    },
    16: {
      map: { low: oc, high: oc },
      microStateExtraSize: 2,
      fontExtraSize: 2.2,
      deltaLongitude: -162,
      zoomToGeoPoint: {
        latitude: -15.81857629,
        longitude: 164.84848905
      },
      // projection: d3geoP.geoTimes(),
      lineSeparation: 0.4,
      zoomLevel: 2.08
    },
    11: {
      map: { low: eu, high: eu },
      microStateExtraSize: 3,
      projection: new am4maps.projections.Orthographic(),
      deltaLongitude: -9.776,
      deltaLatitude: -39.978,
      lineSeparation: 0.2,
      fontExtraSize: 2,
      zoomToGeoPoint: {
        latitude: 51.851857629,
        longitude: 19.84848905
      },
      zoomLevel: 1.68
    },
    1: {
      map: { low: af, high: af },
      microStateExtraSize: 2.1,
      fontExtraSize: 1.5,
      lineSeparation: 0.3,
      zoomToGeoPoint: {
        latitude: 3,
        longitude: 25
      },
      zoomLevel: 1.14
    },
    2: {
      map: { low: as, high: asH },
      microStateExtraSize: 1,
      zoomToGeoPoint: {
        latitude: 20.851857629,
        longitude: 94.84848905
      },
      zoomLevel: 1.58,
      lineSeparation: 0.25,
      fontExtraSize: 1.1
    },
    15: {
      map: { low: na, high: na },
      microStateExtraSize: 1.5,
      fontExtraSize: 2,
      lineSeparation: 0.3,
      zoomToGeoPoint: {
        latitude: 49.851857629,
        longitude: -90.84848905
      },
      zoomLevel: 1.18
    },
    12: {
      map: { low: sa, high: sa },
      microStateExtraSize: 1,
      fontExtraSize: 1.5,
      d3Projection: d3geoP.geoRobinson(),
      deltaLongitude: 61.577,
      deltaLatitude: -14.185,
      zoomToGeoPoint: {
        latitude: -11.851857629,
        longitude: -55.84848905
      },
      zoomLevel: 1.18,
      lineSeparation: 0.4
    },
    3: {
      map: { low: ca, high: ca },
      microStateExtraSize: 1,
      fontExtraSize: 1,
      projection: new am4maps.projections.Orthographic(),
      deltaLongitude: 61.577,
      deltaLatitude: -14.185,
      zoomToGeoPoint: {
        latitude: 12.40516,
        longitude: -71.20126
      },
      zoomLevel: 3.6,
      lineSeparation: 0.3
    },
    14: {
      map: { low: me, high: me },

      zoomToGeoPoint: {
        latitude: 23.851857629,
        longitude: 21.84848905
      },
      zoomLevel: 2.68,
      fontExtraSize: 1.3,
      microStateExtraSize: 1.8,
      lineSeparation: 0.3
    },
    18: {
      map: { low: am, high: am },
      fontExtraSize: 0.7,
      microStateExtraSize: 1.3,
      lineSeparation: 0.2
    }
  }
  /**
   * Load the map
   */
  const loadMap = async regionId => {
    // LOADING
    //
    am4core.unuseAllThemes()
    am4core.options.queue = true
    if (loadingChart.current) loadingChart.current.style.display = 'flex'

    const x = am4core.create('chartdiv', am4maps.MapChart)

    if (i18n.language === 'es') x.geodataNames = am4geodataLangES
    else x.geodataNames = am4geodataLangEN

    if (homeMap && false) {
      x.homeZoomLevel = 1.142
      const centerHomeCoords = {
        latitude: 9.441376193925251,
        longitude: 11.232323
      }
      x.homeGeoPoint = centerHomeCoords
    }

    if (pacificCenter) {
      x.projection.d3Projection = d3geoP.geoTimes()
      x.deltaLongitude = -162
    } else {
      if (projection === 'Miller') {
        x.projection.d3Projection = d3geoP.geoMiller()
      } else if (projection === 'Mercator') {
        x.projection = new am4maps.projections.Mercator()
      } else if (projection === 'Natural') {
        x.projection.d3Projection = d3geoP.geoNaturalEarth2()
      } else if (projection === 'Azimuthal' || projection === 'Asimuthal') {
        x.projection = new am4maps.projections.AzimuthalEqualArea()
      } else x.projection.d3Projection = d3geoP.geoRobinson()
    }

    x.maxPanOut = 0.5
    if (regionSeries[regionId].zoomToGeoPoint) {
      x.homeZoomLevel = regionSeries[regionId].zoomLevel
      x.homeGeoPoint = regionSeries[regionId].zoomToGeoPoint
      x.minZoomLevel = regionSeries[regionId].zoomLevel
    }

    x.events.on('wheel', function(ev) {
      if (ev.event.ctrlKey) {
        x.chartContainer.wheelable = true
      } else {
        ev.event.preventDefault()
        x.chartContainer.wheelable = false
        window.scrollBy(0, ev.shift.y * 2)
      }
    })
    x.chartContainer.wheelable = false
    /* x.events.on("mappositionchanged", (ev) => {
      //onsole.log(ev.target._zoomGeoPointReal);
      if (ev.target._zoomGeoPointReal.latitude > -4) {
        //onsole.log(ev.target._zoomGeoPointReal);
        x.pan({ x: 0, y: -1 });
        //onsole.log(ev.target._zoomGeoPointReal);
      }
    }); */
    let resolution = 'low'
    if (highRes) resolution = 'high'

    /**
     * Algorithm to shift maps if necessary
     * (i.e. Australia has + and - longitudes, they all need to be same symbl)
     */
    if (regionSeries[regionId].map.deltaLongitude) {
      const newCountries = []
      for (const country of regionSeries[regionId].map.features) {
        let newCoordinates = []
        if (country.geometry.type === 'MultiPolygon') {
          const newSubCoordinates = []
          for (const coords of country.geometry.coordinates) {
            newSubCoordinates.push([
              coords[0].map(coord => {
                let newLong =
                  coord[0] - regionSeries[regionId].map.deltaLongitude
                if (country.geometry.flip) {
                  newLong =
                    360 + coord[0] - regionSeries[regionId].map.deltaLongitude
                }
                return [newLong, coord[1]]
              })
            ])
          }
          newCoordinates = [...newSubCoordinates]
        } else {
          newCoordinates = [
            country.geometry.coordinates[0].map(coord => {
              let newLong = coord[0] - regionSeries[regionId].map.deltaLongitude
              if (country.geometry.flip) {
                newLong =
                  360 + coord[0] - regionSeries[regionId].map.deltaLongitude
              }
              return [newLong, coord[1]]
            })
          ]
        }
        newCountries.push({
          ...country,
          geometry: { ...country.geometry, coordinates: newCoordinates }
        })
      }

      x.geodata = {
        ...regionSeries[regionId].map[resolution],
        features: newCountries
      }
    } else {
      x.geodata = regionSeries[regionId].map[resolution]
    }

    x.zoomStep = 1.2
    // IMPORTANT ALWAYS allSeries[0] should be the maincountries series for drillDown Zoom special polygons
    chart.current = { map: x, allSeries: [], allTemplates: [], labelSeries: [] }

    if (drilledDown)
      drillDownToCountry(
        drilledDown,
        chart.current.allTemplates,
        chart.current.allSeries
      )
    else loadSeries(regionId)

    chart.current.map.events.on('zoomlevelchanged', () => {
      /* if (regionSeries[regionId].zoomLevel && x.zoomLevel <= regionSeries[regionId].zoomLevel) {
            x.goHome();
          } */
      mapClicked()
    })

    chart.current.map.events.on('dragstart', () => {
      mapClicked()
    })

    chart.current.map.events.on('ready', async () => {
      if (loadingChart.current) loadingChart.current.style.display = 'none'

      if (!hideMicrostates && !drilledDown) {
        addMicroStates(regionId, Math.abs(x.east / x.west) * 1.1)
      }

      if (extraProps) {
        await customActions(
          [...chart.current.allTemplates],
          [...chart.current.allSeries],
          { ...extraProps, regionId },
          preloadCountries
        )
      }
      loadLabels(regionId)
      if (setMapLoaded) setTimeout(() => setMapLoaded(true), 1000)
    })

    chart.current.map.events.on('hit', ev => {
      mapClicked()
    })

    // chart.current.map.projection.d3Projection = d3geoP.geoTimes();
  }

  const loadClickAction = (ev, countriesTemplate) => {
    countriesTemplate.interactionsEnabled = true

    if (editor) {
      if (ev.event.ctrlKey || ev.event.metaKey) {
        setLastState(ev.target.dataItem.dataContext.id)
        setLabelFontSize(
          labelsLocationsRef.current[ev.target.dataItem.dataContext.id][2]
        )
      } else if (ev.event.shiftKey) {
        setLabelFontSize(10)
        drillDownToCountry(
          ev.target.dataItem.dataContext.id,
          chart.current.allTemplates,
          chart.current.allSeries,
          ev
        )
      } else {
        setLabelsLocations({
          ...labelsLocationsRef.current,
          [lastStateRef.current]: [
            chart.current.map.svgPointToGeo(ev.svgPoint).longitude,
            chart.current.map.svgPointToGeo(ev.svgPoint).latitude,
            labelFontSizeRef.current
          ]
        })
      }

      return
    }

    //onsole.log('CC')
    if (ev.target.dataItem.dataContext.link)
      history.push(ev.target.dataItem.dataContext.link)

    // Transition to state map of it's U.S.
    const id = ev.target.dataItem.dataContext.id
      .replace('NG-DEATH', 'NG')
      .replace('PS-GZA', 'PS')
      .replace('PS-WBA', 'PS')
    if (
      jurisdictionsWithDrillDown &&
      jurisdictionsWithDrillDown.indexOf(id) !== -1
    ) {
      drillDownToCountry(
        id,
        chart.current.allTemplates,
        chart.current.allSeries,
        ev
      )
    }

    if (extraProps.receivedIssuedRecommendations) {
      if (id === 'EH') return
      for (const countriesSeries of chart.current.allSeries) {
        const jurs = Object.values(jurisdictions)
        if (extraProps.heatMap === 1 || extraProps.heatMap === 2) {
          countriesSeries.data = countriesSeries.data.map(d => {
            return {
              ...d,
              ...(d.id !== 'EH'
                ? { fill: am4core.color('#FFF') }
                : { fill: am4core.color(disabledColor), tooltip: '' })
            }
          })
        }
        if (extraProps.heatMap === 1) {
          countriesSeries.data = countriesSeries.data.map(d => {
            if (
              d.id !== ev.target.dataItem.dataContext.id &&
              (!extraProps.receivedIssuedRecommendations.find(
                j => j.a2_code === ev.target.dataItem.dataContext.id
              ) ||
                !extraProps.receivedIssuedRecommendations
                  .find(j => j.a2_code === ev.target.dataItem.dataContext.id)
                  .received.find(j => j.a2_code === d.id))
            ) {
              return {
                ...d,
                ...(d.id !== 'EH'
                  ? {
                      fill: am4core.color('#FFF'),
                      tooltip: ReactDOMServer.renderToStaticMarkup(
                        <StyledMapTooltips
                          entry={{
                            motherEntry: {
                              jurisdiction: jurs.find(
                                j => j.a2_code === d.id
                              ) || {
                                name: 'ERROR',
                                a2_code: 'ERROR'
                              }
                            },
                            n_recommendations: extraProps.receivedIssuedRecommendations.find(
                              j =>
                                j.a2_code === ev.target.dataItem.dataContext.id
                            )
                              ? extraProps.receivedIssuedRecommendations
                                  .find(
                                    j =>
                                      j.a2_code ===
                                      ev.target.dataItem.dataContext.id
                                  )
                                  .received.filter(j => j.a2_code === d.id)
                                  .length
                              : 0,
                            issued_to: jurs.find(
                              j =>
                                j.a2_code === ev.target.dataItem.dataContext.id
                            ).name
                          }}
                          area={'A5_RECEIVED_CLICK'}
                        />
                      )
                    }
                  : { fill: am4core.color(disabledColor), tooltip: '' })
              }
            } else if (
              extraProps.receivedIssuedRecommendations.find(
                j => j.a2_code === ev.target.dataItem.dataContext.id
              ) &&
              extraProps.receivedIssuedRecommendations
                .find(j => j.a2_code === ev.target.dataItem.dataContext.id)
                .received.find(j => j.a2_code === d.id)
            ) {
              return {
                ...d,
                ...(d.id !== 'EH'
                  ? {
                      fill: am4core.color('#659DB2'),
                      tooltip: ReactDOMServer.renderToStaticMarkup(
                        <StyledMapTooltips
                          entry={{
                            motherEntry: {
                              jurisdiction: jurs.find(j => j.a2_code === d.id)
                            },
                            n_recommendations: extraProps.receivedIssuedRecommendations.find(
                              j =>
                                j.a2_code === ev.target.dataItem.dataContext.id
                            )
                              ? extraProps.receivedIssuedRecommendations
                                  .find(
                                    j =>
                                      j.a2_code ===
                                      ev.target.dataItem.dataContext.id
                                  )
                                  .received.filter(j => j.a2_code === d.id)
                                  .length
                              : 0,
                            issued_to: jurs.find(
                              j =>
                                j.a2_code === ev.target.dataItem.dataContext.id
                            )
                          }}
                          area={'A5_RECEIVED'}
                        />
                      )
                    }
                  : { fill: am4core.color(disabledColor), tooltip: '' })
              }
            } else {
              return {
                ...d,
                ...(d.id !== 'EH'
                  ? {
                      fill: am4core.color('#3F555D'),
                      tooltip: ReactDOMServer.renderToStaticMarkup(
                        <StyledMapTooltips
                          entry={{
                            motherEntry: {
                              jurisdiction: jurs.find(j => j.a2_code === d.id)
                            },
                            n_recommendations: extraProps.receivedIssuedRecommendations.find(
                              j =>
                                j.a2_code === ev.target.dataItem.dataContext.id
                            )
                              ? extraProps.receivedIssuedRecommendations.find(
                                  j =>
                                    j.a2_code ===
                                    ev.target.dataItem.dataContext.id
                                ).received.length
                              : 0,
                            issued_to: jurs.find(
                              j =>
                                j.a2_code === ev.target.dataItem.dataContext.id
                            ).name
                          }}
                          area={'A5_RECEIVED_INI'}
                        />
                      )
                    }
                  : { fill: am4core.color(disabledColor), tooltip: '' })
              }
            }
          })
        } else if (extraProps.heatMap === 2) {
          countriesSeries.data = countriesSeries.data.map(d => {
            if (
              d.id !== ev.target.dataItem.dataContext.id &&
              (!extraProps.receivedIssuedRecommendations.find(
                j => j.a2_code === ev.target.dataItem.dataContext.id
              ) ||
                !extraProps.receivedIssuedRecommendations
                  .find(j => j.a2_code === ev.target.dataItem.dataContext.id)
                  .issued.find(j => j.a2_code === d.id))
            ) {
              return {
                ...d,
                ...(d.id !== 'EH'
                  ? {
                      fill: am4core.color('#FFF'),
                      tooltip: ReactDOMServer.renderToStaticMarkup(
                        <StyledMapTooltips
                          entry={{
                            motherEntry: {
                              jurisdiction: jurs.find(
                                j => j.a2_code === d.id
                              ) || {
                                name: 'ERROR',
                                a2_code: 'ERROR'
                              }
                            },
                            n_recommendations: extraProps.receivedIssuedRecommendations.find(
                              j =>
                                j.a2_code === ev.target.dataItem.dataContext.id
                            )
                              ? extraProps.receivedIssuedRecommendations
                                  .find(
                                    j =>
                                      j.a2_code ===
                                      ev.target.dataItem.dataContext.id
                                  )
                                  .received.filter(j => j.a2_code === d.id)
                                  .length
                              : 0,
                            issued_to: jurs.find(
                              j =>
                                j.a2_code === ev.target.dataItem.dataContext.id
                            ).name
                          }}
                          area={'A5_ISSUED_CLICK'}
                        />
                      )
                    }
                  : { fill: am4core.color(disabledColor), tooltip: '' })
              }
            } else if (
              extraProps.receivedIssuedRecommendations.find(
                j => j.a2_code === ev.target.dataItem.dataContext.id
              ) &&
              extraProps.receivedIssuedRecommendations
                .find(j => j.a2_code === ev.target.dataItem.dataContext.id)
                .issued.find(j => j.a2_code === d.id)
            ) {
              return {
                ...d,
                ...(d.id !== 'EH'
                  ? {
                      fill: am4core.color('#3F555D'),
                      tooltip: ReactDOMServer.renderToStaticMarkup(
                        <StyledMapTooltips
                          entry={{
                            motherEntry: {
                              jurisdiction: jurs.find(j => j.a2_code === d.id)
                            },
                            n_recommendations: extraProps.receivedIssuedRecommendations.find(
                              j =>
                                j.a2_code === ev.target.dataItem.dataContext.id
                            )
                              ? extraProps.receivedIssuedRecommendations
                                  .find(
                                    j =>
                                      j.a2_code ===
                                      ev.target.dataItem.dataContext.id
                                  )
                                  .issued.filter(j => j.a2_code === d.id).length
                              : 0,
                            received_from: jurs.find(
                              j =>
                                j.a2_code === ev.target.dataItem.dataContext.id
                            )
                          }}
                          area={'A5_ISSUED'}
                        />
                      )
                    }
                  : { fill: am4core.color(disabledColor), tooltip: 'BBB' })
              }
            } else {
              return {
                ...d,
                ...(d.id !== 'EH'
                  ? {
                      fill: am4core.color('#659DB2'),
                      tooltip: ReactDOMServer.renderToStaticMarkup(
                        <StyledMapTooltips
                          entry={{
                            motherEntry: {
                              jurisdiction: jurs.find(j => j.a2_code === d.id)
                            },
                            n_recommendations: extraProps.receivedIssuedRecommendations.find(
                              j =>
                                j.a2_code === ev.target.dataItem.dataContext.id
                            )
                              ? extraProps.receivedIssuedRecommendations.find(
                                  j =>
                                    j.a2_code ===
                                    ev.target.dataItem.dataContext.id
                                ).issued.length
                              : 0,
                            received_from: jurs.find(
                              j =>
                                j.a2_code === ev.target.dataItem.dataContext.id
                            ).name
                          }}
                          area={'A5_ISSUED_INI'}
                        />
                      )
                    }
                  : { fill: am4core.color(disabledColor), tooltip: 'CCC' })
              }
            }
          })
        }
      }
    }
  }

  // Load series of countries
  const loadSeries = async regionId => {
    if (regionSeries[regionId].projection)
      chart.current.map.projection = regionSeries[regionId].projection
    else if (regionSeries[regionId].d3Projection) {
      chart.current.map.projection.d3Projection =
        regionSeries[regionId].d3Projection
    }

    if (regionSeries[regionId].panBehavior)
      chart.current.map.panBehavior = regionSeries[regionId].panBehavior
    if (regionSeries[regionId].deltaLongitude) {
      chart.current.map.deltaLongitude = regionSeries[regionId].deltaLongitude
    }
    if (regionSeries[regionId].deltaLatitude)
      chart.current.map.deltaLatitude = regionSeries[regionId].deltaLatitude
    /* if (regionSeries[regionId].zoomToGeoPoint) {
      setTimeout(() => {
        chart.current.map.zoomToGeoPoint(regionSeries[regionId].zoomToGeoPoint, regionSeries[regionId].zoomLevel);
        chart.current.map.minZoomLevel = regionSeries[regionId].zoomLevel;
      }, 1);
    }
*/
    const countriesSeries = chart.current.map.series.push(
      new am4maps.MapPolygonSeries()
    )
    countriesSeries.useGeodata = true
    countriesSeries.hiddenState.transitionDuration = 500
    countriesSeries.defaultState.transitionDuration = 500

    /**
     * Exlude all this objects from all the maps
     */
    countriesSeries.exclude = [
      'AQ',
      'BV',
      'GO',
      'JU',
      'UM-DQ',
      'UM-FQ',
      'UM-HQ',
      'UM-JQ',
      'UM-MQ',
      'UM-WQ',
      'NF',
      'HM',
      'CX',
      'CC',
      'TF',
      'AX'
    ]
    if (include && include.length) countriesSeries.include = include
    countriesSeries.data = [
      ...countriesSeries.data.map(cs => ({ ...cs, tooltip: '' })),
      {
        id: 'EH',
        fill: am4core.color(disabledColor),
        tooltip: ''
      }
    ] // Disable western Sahara

    references
      .filter(r => {
        return (
          (!r.entry.motherEntry ||
            (r.entry.motherEntry.entry_type_id &&
              r.entry.motherEntry.entry_type_id.indexOf('A1') !== -1) ||
            r.entry.motherEntry.jurisdiction.a2_code.toUpperCase() !== 'TW') &&
          (!include ||
            include.length <= 1 ||
            include.indexOf(
              r.entry.motherEntry.jurisdiction.a2_code.toUpperCase()
            ) !== -1)
        )
      })
      .forEach(obj => {
        /* //onsole.log(
            "country",
            obj.entry && obj.entry.motherEntry.jurisdiction.name,
            obj.regions.find((r) => r.id === regionId)
          ); */

        
        originalTooltips[obj.id] = obj.tooltip || ''

        const jurs = Object.values(jurisdictions)

        countriesSeries.data = [
          ...countriesSeries.data,
          obj.regions &&
          (!regionId ||
            regionId === '0' ||
            obj.regions.find(r => r.id === regionId)) &&
          (hideMicrostates || (!hideMicrostates && !obj.microstate))
            ? {
                // Country in continent
                id: obj.id,

                reference_text: obj.reference_text,
                fill: am4core.color(
                  editor ? '#fff' : obj.reference ? obj.reference : '#999900'
                ),
                tooltip: obj.tooltip || '',
                fillOpacity: 1,
                link: obj.link
              }
            : {
                // Country outside continent BOLIVIA ISSUE LEFT IT LIKE REGULAR< TODO RECHECK THIS
                // Country outside continent
                id: obj.id,

                fill:
                  !hideMicrostates && obj.microstate
                    ? am4core.color(seaColor)
                    : am4core.color(disabledColor),
                tooltip: '',
                fillOpacity: 0.8,
                link: obj.link
              }
        ]
      })

    // MISSING REFERENCES DISABLE!
    Object.values(jurisdictions).forEach(j => {
      if (!countriesSeries.data.find(cs => cs.id === j.a2_code)) {
        countriesSeries.data.push({
          id: j.a2_code,
          tooltip: '',
          fill: disabledColor
        })
      }
    })

    countriesSeries.tooltip.getFillFromObject = false
    countriesSeries.tooltip.autoTextColor = false
    countriesSeries.tooltip.background.fill = am4core.color('#FFF')
    countriesSeries.tooltip.label.fill = am4core.color('#000')
    countriesSeries.tooltip.label.padding(1, 1, 1, 1)

    countriesSeries.tooltip.label.wrap = false
    const countriesTemplate = countriesSeries.mapPolygons.template
    if (showTooltip) {
      countriesTemplate.tooltipHTML = '{tooltip}'
    }
    countriesTemplate.fill = disabledColor
    countriesTemplate.propertyFields.fill = 'fill'
    countriesTemplate.propertyFields.fillOpacity = 'fillOpacity'
    countriesTemplate.stroke = am4core.color(strokeColor)
    countriesTemplate.strokeWidth = 0.4
    countriesTemplate.strokeOpacity = 1

    const hoverCountries = countriesTemplate.states.create('hover')
    hoverCountries.properties.fillOpacity = 0.8

    if (editor)
      chart.current.map.events.on('hit', function(ev) {


        if (ev.event.ctrlKey || ev.event.metaKey) {
          setLastState(ev.target.dataItem.dataContext.id)
          setLabelFontSize(
            labelsLocationsRef.current[ev.target.dataItem.dataContext.id][2]
          )
        } else {
          setLabelsLocations({
            ...labelsLocationsRef.current,
            [lastStateRef.current]: [
              chart.current.map.svgPointToGeo(ev.svgPoint).longitude,
              chart.current.map.svgPointToGeo(ev.svgPoint).latitude,
              labelFontSizeRef.current
            ]
          })
        }
      })

    chart.current = {
      ...chart.current,
      allSeries: [...chart.current.allSeries, countriesSeries],
      allTemplates: [...chart.current.allTemplates, countriesTemplate]
    }
    //ACAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
    //countriesTemplate.interactionsEnabled = true
    countriesTemplate.events.on('hit', function(ev) {
      
      loadClickAction(ev, countriesTemplate)
    })

    if (currentArea && currentArea === 'A1-1' && !drilledDown) {
      if (
        (!regionId || regionId === '0' || regionId === '1') &&
        (!include ||
          !include.length ||
          (include && include.indexOf('NG') !== -1))
      )
        loadCustomPolygon(nigeria)
      if (
        (!regionId || regionId === '0' || regionId === '2') &&
        (!include ||
          !include.length ||
          (include && include.indexOf('ID') !== -1))
      )
        loadCustomPolygon(indonesia)
      if (
        (!regionId ||
          regionId === '0' ||
          regionId === '14' ||
          regionId === '2') &&
        (!include ||
          !include.length ||
          (include && include.indexOf('PS') !== -1))
      ) {
        loadCustomPolygon(gaza)
        loadCustomPolygon(wba)
      }
    }

    if (currentArea && currentArea === 'A1-3' && false) {
      // Disable for now as per Feedback
      if (!regionId || regionId === '0' || regionId === '16') {
        loadCustomPolygon(usaFoe)
      }
    }

    /*
    Function to show gray areas when including maps
    */
    if (include && include.length > 1) {
      let regions = []
      for (const a2codeInc of include) {
        regions.push(
          jurisdictions[a2codeInc].regions.find(
            re => ['16', '11', '1', '2', '15', '12', '3'].indexOf(re.id) !== -1
          ).id
        )
      }
      regions = [...new Set(regions)]

      countriesSeries.include = countriesSeries.include.concat(
        Object.values(jurisdictions)
          .filter(jur =>
            jur.regions.map(re => re.id).some(r => regions.includes(r))
          )
          .map(jur => jur.a2_code)
      )

      regions.forEach(r => {
        addMicroStates(r, 1, true)
      })
    }
  }

  const loadCustomPolygon = polygon => {
    const countriesSeries = chart.current.map.series.push(
      new am4maps.MapPolygonSeries()
    )
    countriesSeries.useGeodata = true

    countriesSeries.geodata = polygon

    references.forEach(obj => {
      countriesSeries.data = [
        ...countriesSeries.data,
        {
          // Country in continent
          id: obj.id,
          reference_text: obj.reference_text,
          fill: am4core.color(
            editor ? '#fff' : obj.reference ? obj.reference : '#999900'
          ),
          tooltip: obj.tooltip || '',
          fillOpacity: 1
        }
      ]
    })

    countriesSeries.tooltip.getFillFromObject = false
    countriesSeries.tooltip.autoTextColor = false
    countriesSeries.tooltip.background.fill = am4core.color('#FFF')
    countriesSeries.tooltip.label.fill = am4core.color('#000')
    countriesSeries.tooltip.label.padding(1, 1, 1, 1)

    const countriesTemplate = countriesSeries.mapPolygons.template
    if (showTooltip) {
      countriesTemplate.tooltipHTML = '{tooltip}'
    }

    countriesTemplate.propertyFields.fill = 'fill'
    countriesTemplate.propertyFields.fillOpacity = 'fillOpacity'

    countriesTemplate.stroke = am4core.color(strokeColor)
    countriesTemplate.strokeWidth = 0.4
    countriesTemplate.strokeOpacity = 1
    chart.current.allSeries = [...chart.current.allSeries, countriesSeries]
    chart.current.allTemplates = [
      ...chart.current.allTemplates,
      countriesTemplate
    ]
  }

  const seriesMicroStates = regionId => {
    if (regionId === '0') return microstates
    else
      return microstates.filter(ms =>
        jurisdictions[ms.A2].regions.find(r => r.id === regionId)
      )
  }

  /*
  regionId force only one region
  microRatio TODO
  includeMicro = when using the "include" functionality, if true, only load the ones that are in the include as references,
  
  */
  const addMicroStates = (regionId, microRatio = 1, includeMicro = false) => {
    microRatio = 1 // override
    let placeSeries
    let place
    let circle
    const allPlacesSeries = []

    const microLabelSeries = chart.current.map.series.push(
      new am4maps.MapImageSeries()
    )
    const microLabelTemplate = microLabelSeries.mapImages.template.createChild(
      am4core.Label
    )
    microLabelTemplate.horizontalCenter = 'middle'
    microLabelTemplate.verticalCenter = 'middle'
    microLabelTemplate.fontSize = 3
    microLabelTemplate.nonScaling = false
    microLabelTemplate.interactionsEnabled = false

    const tmpObj = {}

    for (let i = 1; i <= 6; i++) {
      placeSeries = chart.current.map.series.push(new am4maps.MapImageSeries())
      allPlacesSeries.push(placeSeries)
      place = placeSeries.mapImages.template
      // place.nonScaling = true;
      place.propertyFields.latitude = 'latitude'
      place.propertyFields.longitude = 'longitude'
      placeSeries.tooltip.getFillFromObject = false
      placeSeries.tooltip.autoTextColor = false
      placeSeries.tooltip.background.fill = am4core.color('#FFF')
      placeSeries.tooltip.label.fill = am4core.color('#000')
      placeSeries.tooltip.label.padding(1, 1, 1, 1)

      placeSeries.data = seriesMicroStates(regionId)
        .filter(state => state.Size === i)
        .filter(state => {
          if (include && include.length && !includeMicro) {
            return include.indexOf(state.A2) !== -1
          } else return true
        })
        .map(state => {
          const ref = references.find(ref => ref.id === state.A2)

          return {
            latitude: state.Lat,
            longitude:
              state.Long -
              (regionId !== '0' && regionSeries[regionId].map.deltaLongitude
                ? regionSeries[regionId].map.deltaLongitude
                : 0),
            name: t(state['Full name']),
            hcenter: 'right',
            vcenter: 'middle',
            link: ref ? ref.link : null,
            fill:
              include &&
              include.length &&
              includeMicro &&
              include.indexOf(state.A2) === -1
                ? disabledColor
                : am4core.color(
                    editor ? '#fff' : ref ? ref.reference : disabledColor
                  ),
            A2: state.A2,
            id: state.A2,
            reference_text: ref && ref.reference_text ? ref.reference_text : '',
            tooltip: ref && ref.tooltip ? ref.tooltip : '',
            fillOpacity: 1
          }
        })

      circle = place.createChild(am4core.Circle)

      circle.radius =
        i *
        1.275 *
        regionSeries[regionId].microStateExtraSize *
        ((chart.current.map.innerWidth / 1340) * microRatio)

      circle.stroke = am4core.color(strokeColor)
      circle.strokeWidth = 0.2 // 1 / regionSeries[regionId].microStateExtraSize;
      // circle.showTooltipOn = "hit"; //To show always
      if (showTooltip) {
        if (references && references.length) circle.tooltipHTML = '{tooltip}'
        else circle.tooltipHTML = '{name}'
      }

      circle.propertyFields.fill = 'fill'
      circle.propertyFields.fillOpacity = 'fillOpacity'

      const hs2 = circle.states.create('hover')
      hs2.properties.fillOpacity = 0.9
      /*
          if (extraProps) {
            customActions(place, placeSeries, extraProps);
          } */

      chart.current = {
        ...chart.current,
        allSeries: [...chart.current.allSeries, placeSeries],
        allTemplates: [...chart.current.allTemplates, place]
      }

      place.events.on('hit', function(ev) {
        
        loadClickAction(ev, place)
      })
      // circle.tooltip.label.interactionsEnabled = true;
    }
  }

  const getRegionA2s = region => {
    return Object.values(jurisdictions)
      .filter(
        jur =>
          !jur.regions.length ||
          jur.regions.map(r => r.id).indexOf('' + region + '') === -1
      )
      .map(j => j.a2_code)
  }

  const unloadLabels = () => {
    for (const s of chart.current.labelSeries) {
      chart.current.map.series
        .removeIndex(chart.current.map.series.indexOf(s))
        .dispose()
    }
  }

  const loadLabels = regionId => {
    if (drilledDown) {
      return
    }
    let labelProperty = !hideMicrostates ? 'microstate_label_' : 'label_'
    const regionJurs =
      regionId !== '0'
        ? Object.values(jurisdictions)
            .filter(j => j.regions.find(r => r.id === regionId))
            .map(j => j.a2_code)
        : Object.keys(jurisdictions)
    if (showLabels) {
      let labelsLocations = {}

      // polygonSeries.calculateVisualCenter = true;
      const labelSeries = chart.current.map.series.push(
        new am4maps.MapImageSeries()
      )
      const labelTemplate = labelSeries.mapImages.template.createChild(
        am4core.Label
      )
      labelTemplate.horizontalCenter = 'middle'
      labelTemplate.verticalCenter = 'middle'
      labelTemplate.fontSize = 1
      labelTemplate.fontFamily = "'Lato', sans-serif"
      labelTemplate.textAlign = 'middle'
      labelTemplate.nonScaling = false
      labelTemplate.interactionsEnabled = false
      labelTemplate.wrap = false

      let regionsA2

      let tmpObj = {}
      regionJurs.forEach(function(a2) {
        if (!jurisdictions[a2].name) return
        let labelFontPropertyExtra = ''
        if (regionId !== '0' && jurisdictions[a2].drilldown_label_longitude) {
          labelProperty = 'drilldown_label_'
          labelFontPropertyExtra = 'drilldown_'
        } else {
          labelProperty = !hideMicrostates ? 'microstate_label_' : 'label_'
        }
        // let label = labelSeries.mapImages.create();
        const state = jurisdictions[a2].name.split('-').pop()
        /*
          label.latitude = polygon.visualLatitude;
          label.longitude = polygon.visualLongitude;
          label.children.getIndex(0).text = state; */
        console.log(a2, jurisdictions[a2].drilldown_label_longitude)
        if (!jurisdictions[a2]) {
          console.log('WARNING ', a2, ' NOT FOUND')
        } else {
          console.log(
            labelFontPropertyExtra + 'label_font_size',
            jurisdictions[a2][labelFontPropertyExtra + 'label_font_size']
          )
          tmpObj = {
            ...tmpObj,
            [a2]: [
              jurisdictions[a2][labelProperty + 'longitude'] -
                (regionId !== '0' && regionSeries[regionId].map.deltaLongitude
                  ? regionSeries[regionId].map.deltaLongitude
                  : 0),
              jurisdictions[a2][labelProperty + 'latitude'],
              jurisdictions[a2][labelFontPropertyExtra + 'label_font_size']
            ]
          }
        }
      })
      /*
      TODO: remove this unused code?
      microstates.forEach(ms => {
        if (
          regionId !== '0' &&
          jurisdictions[ms.A2].drilldown_label_longitude
        ) {
          labelProperty = 'drilldown_label_'
        } else {
          labelProperty = !hideMicrostates ? 'microstate_label_' : 'label_'
        }
        tmpObj = {
          ...tmpObj,
          [ms.A2]: [
            jurisdictions[ms.A2][labelProperty + 'longitude'] -
              (regionId !== '0' && regionSeries[regionId].map.deltaLongitude
                ? regionSeries[regionId].map.deltaLongitude
                : 0),
            jurisdictions[ms.A2][labelProperty + 'latitude'],
            jurisdictions[ms.A2].label_font_size
          ]
        }
      })
*/
      labelsLocations = {
        ...labelsLocations,
        ...tmpObj,
        ...labelsLocationsEd
      }

      regionJurs
        .filter(a2 => {
          if (include && include.length) {
            return include.indexOf(a2) !== -1
          } else return true
        })
        .forEach(l => {
          if (!labelsLocations[l][1]) return // Fix issue loading countries repeated on the top left
          if (!jurisdictions[l]) console.log('NOOOO', l)
          if (regionJurs.indexOf(l) === -1) return
          //ISSUE WITH FONT FEEDBACK PRELANZAMIENTO, NOT SURE WHAT NEXT LINE WAS DOING
          //if (
          //drillDown === 'Europe' &&
          //(regionsA2.indexOf(l) !== -1 || !labelsLocations[l][1])
          //)
          //return
          const label = labelSeries.mapImages.create()
          label.latitude = labelsLocations[l][1]
          let lineHeight = l === 'MK' ? 2 : 3
          if (jurisdictions[l].label_font_size === 1) lineHeight = 1
          label.latitude = labelsLocations[l][1]
          label.longitude = labelsLocations[l][0]
          let labelPre = ''
          let labelPost = ''

          if (!jurisdictions[l].un_member) {
            labelPre = '[font-style: italic]'
            labelPost = '[/]'
          }
          /* label.children.getIndex(0).html =
            `<div style="padding:10px; font-family: 'Lato', sans-serif; line-height:${lineHeight}px; ${textDecoration}">` +
            jurisdictions[l].map_name.replace(/\/gi, "<br />") +
            "</div>"; */
          const split = jurisdictions[l].map_name
            ? jurisdictions[l].map_name.split('*')
            : jurisdictions[l].name.split('*')
          label.children.getIndex(0).text = labelPre + split[0] + labelPost

          let fontSize = labelsLocations[l][2] * fontRatio

          if (regionSeries[regionId].fontExtraSize) {
            fontSize =
              (parseInt(labelsLocations[l][2]) + 1) *
              regionSeries[regionId].fontExtraSize *
              fontRatio
          }

          if (labelsLocations[l][2])
            label.children.getIndex(0).fontSize = fontSize
          if (split.length > 1) {
            if (split.length >= 2) {
              const labelTmp2 = labelSeries.mapImages.create()
              labelTmp2.latitude =
                labelsLocations[l][1] -
                regionSeries[regionId].lineSeparation * labelsLocations[l][2]
              labelTmp2.longitude = labelsLocations[l][0]
              labelTmp2.children.getIndex(0).text =
                labelPre + split[1] + labelPost
              if (labelsLocations[l][2])
                labelTmp2.children.getIndex(0).fontSize = fontSize
            }
            if (split.length === 3) {
              const labelTmp3 = labelSeries.mapImages.create()
              labelTmp3.latitude =
                labelsLocations[l][1] -
                (regionSeries[regionId].lineSeparation +
                  regionSeries[regionId].lineSeparation) *
                  labelsLocations[l][2]
              labelTmp3.longitude = labelsLocations[l][0]
              labelTmp3.children.getIndex(0).text =
                labelPre + split[2] + labelPost
              if (labelsLocations[l][2])
                labelTmp3.children.getIndex(0).fontSize = fontSize
            }
          }
        })
      chart.current.labelSeries = [...chart.current.labelSeries, labelSeries]
      if (
        Object.keys(labelsLocationsEd).length !==
        Object.keys(labelsLocations).length
      )
        setLabelsLocations(labelsLocations)
    }
  }
  useLayoutEffect(() => {
    if (editor && chart.current) chart.current.map.dispose()
    if (
      !loadingData &&
      (!drilledDown || (drilledDown && !preventReloadDrillDown))
    ) {
      loadMap(drillDown)
      setTimeout(() => setShowBtns(true), 350)
    }
  }, [
    drillDown,
    loadingData,
    showLabels,
    pacificCenter,
    hideMicrostates,
    projection,
    highRes,
    references,
    extraProps.categories,
    drilledDown,
    include,
    fontRatio,
    labelsLocationsEd
  ])

  const zoomIn = () => {
    chart.current.map.zoomIn()
  }
  const zoomOut = () => {
    chart.current.map.zoomOut()
  }
  const resetPerspective = () => {
    if (drilledDown) {
      setDrilledDown(false)
      setPreventReloadDrillDown(true)
    } else {
      setTimeout(() => loadMap(drillDown), 350)
    }

    // chart.current.map.goHome(0);
    //  chart.current.map.zoomToGeoPoint({ latitude: 0, longitude: 50 }, 1);
  }

  useEffect(() => {
    if (
      !mapLoaded ||
      loadingData ||
      !jurisdictionsWithDrillDown ||
      jurisdictionsWithDrillDown.length === 0
    )
      return

    if (
      include &&
      include.length === 1 &&
      jurisdictionsWithDrillDown.includes(include[0])
    ) {
      drillDownToCountry(include[0])
    }
  }, [
    JSON.stringify(include),
    JSON.stringify(jurisdictionsWithDrillDown),
    mapLoaded,
    loadingData
  ])

  useEffect(() => {
    if (labelsLocationsEd && Object.keys(labelsLocationsEd).length)
      setTextArea(
        Object.keys(labelsLocationsEd)
          .sort()
          .filter(l => l)
          .map(l => l + ': ' + labelsLocationsEd[l])
          .join('\n')
      )
  }, [labelsLocationsEd])

  return (
    <div className={'map_' + i18n.language}>
      {editor && textArea && (
        <>
          <textarea
            style={{
              height: '50px',
              width: '500px',
              overflow: 'auto',
              background: '#eee'
            }}
            value={textArea}
          >
            {textArea}
          </textarea>
        </>
      )}
      {editor && Object.keys(lastState).length > 0 && (
        <div>
          ASSIGNING: {lastState} | FONT SIZE: {labelFontSizeRef.current} |
          Rotation: Lat: {chart.current.map.deltaLatitude} - Long:{' '}
          {chart.current.map.deltaLongitude} | Zoom:{' '}
          {chart.current.map.zoomLevel} | Zoom GeoPoint:{' '}
          {chart.current.map.zoomGeoPoint &&
            chart.current.map.zoomGeoPoint.latitude}{' '}
          /{' '}
          {chart.current.map.zoomGeoPoint &&
            chart.current.map.zoomGeoPoint.longitude}
        </div>
      )}

      {editor && (
        <input
          value={labelFontSizeRef.current}
          onChange={e => setLabelFontSize(e.target.value)}
          type="number"
        />
      )}
      {loadingData ? (
        <div className="mapLoader__container">
          <LoadingMap
            text={t('Loading map')}
            img={showLoader && !drilledDown}
          />
          <div id="chartdiv" className="mapDiv" />
        </div>
      ) : (
        <div className="mapLoader__container">
          <LoadingMap
            ref={loadingChart}
            text={t('Loading map')}
            img={showLoader && !drilledDown}
          />
          {showBtns && (
            <MapControls
              zoomIn={zoomIn}
              zoomOut={zoomOut}
              resetPerspective={resetPerspective}
              drilledDown={drilledDown}
              hideZoom={hideZoom}
            />
          )}
          <div id="chartdiv" className="mapDiv" />
        </div>
      )}
    </div>
  )
})

export default Map
