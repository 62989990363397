import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import LoadingBar from '../../ui/LoadingBar'

export default function LineChart2({ references, commitee }) {
  const [unMember, setUnMember] = useState(true)
  const [topicsN, setTopicsN] = useState(10)
  const { t, i18n } = useTranslation()
  const loading = useRef()
  const loadChart = () => {
    if (loading.current) loading.current.style.display = 'block'
    am4core.options.queue = true

    /* Chart code */
    // Themes begin
    am4core.unuseAllThemes()
    
    // Themes end

    // Create chart instance
    let chart = am4core.create('stackedChartDivX2', am4charts.XYChart)

    chart.events.on('ready', () => {
      if (loading.current) loading.current.style.display = 'none'
    })
    chart.maskBullets = false
    let newReferences = []

    let initialYear = references.reduce(
      (acc, obj) =>
        obj.date && parseInt(obj.date.substring(0, 4)) < acc
          ? parseInt(obj.date.substring(0, 4))
          : acc,
      2100
    )

    for (let i = initialYear; i <= new Date().getFullYear() - 2; i++) {
      newReferences.push({
        year: i + '',
        general: references.filter(e => e.date.indexOf(i) !== -1).length
      })
    }

    chart.data = newReferences

    chart.dateFormatter.inputDateFormat = 'yyyy'
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.dateFormats.setKey('day', 'YYYY')

    dateAxis.dateFormats.setKey('week', 'YYYY')

    dateAxis.dateFormats.setKey('month', 'YYYY')

    dateAxis.dateFormats.setKey('year', 'YYYY')
    dateAxis.renderer.minGridDistance = 20
    dateAxis.startLocation = 0
    dateAxis.endLocation = 1
    dateAxis.baseInterval = {
      timeUnit: 'year',
      count: 1
    }

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())

    valueAxis.min = 0
    //valueAxis.max = 800

    let series = chart.series.push(new am4charts.LineSeries())
    series.dataFields.dateX = 'year'
    series.name = null
    series.hiddenInLegend = true

    series.tensionX = 1

    series.dataFields.valueY = 'general'
    series.tooltipHTML = '' + t('Total references') + ': <b>{valueY.value}</b>'
    series.tooltipText = '[#000]{valueY.value}[/]'
    series.tooltip.getFillFromObject = false
    series.tooltip.background.fill = am4core.color('#FFF')
    series.tooltip.background.fillOpacity = 1
    series.tooltip.label.fill = am4core.color('#575757')

    series.tooltip.label.padding(10, 10, 10, 10)
    series.tooltip.label.maxWidth = 350
    series.tooltip.label.wrap = true

    series.strokeWidth = 3

    let bullet = series.bullets.push(new am4charts.CircleBullet())
    bullet.circle.strokeWidth = 2
    bullet.circle.radius = 4
    bullet.circle.fill = am4core.color('#fff')

    let bullethover = bullet.states.create('hover')
    bullethover.properties.scale = 1.3

    chart.cursor = new am4charts.XYCursor()
    chart.cursor.xAxis = dateAxis
    //chart.scrollbarX = new am4core.Scrollbar();

    // Add a legend
    //chart.legend = new am4charts.Legend();
    //chart.legend.position = "top";
  }
  useEffect(() => {
    loadChart()
  }, [unMember])
  return (
    <>
      <div
        style={{
          minHeight: '480px',
          maxHeight: '480px',
          marginBottom: '10px',
          marginTop: '10px'
        }}
      >
        <LoadingBar ref={loading} text={t('Loading chart') + '...'} />
        <div id="stackedChartDivX2" style={{ minHeight: '480px' }} />
      </div>
    </>
  )
}
