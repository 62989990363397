import { useEffect, useState } from "react"

const BackToTopBtn = () => {
    const [visible, setVisible] = useState(false)
    const [scrollTop] = useState(document.documentElement.scrollTop)

    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop

      if (scrolled > 500) {
        setVisible(true)
      } else if (scrolled <= 500) {
        setVisible(false)
      }
    }
    useEffect(() => {
      const onScroll = e => {
        toggleVisible()
      }
      window.addEventListener('scroll', onScroll)

      return () => window.removeEventListener('scroll', onScroll)
    }, [scrollTop])

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }

    return (
      <button
        className={visible ? 'toTop__btn' : 'toTop__btn hidden'}
        onClick={scrollToTop}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="32px"
          viewBox="0 0 24 24"
          width="32px"
          fill="#A0AA9F"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
        </svg>
      </button>
    )
  }

  export default BackToTopBtn;