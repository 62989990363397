import { citeA114 } from '../mocks/config/a114columns'
import { citeA11 } from '../mocks/config/columns'
import i18n from './i18n'

export const cAreasValues = {
  a1_otherPunishmentNo: '1',
  a1_maxPrisonNo: '1',
  a1_deathPenaltyNo: '2',
  a1_maxPrisonYears: '6',
  a1_protectionYes: '1'
}

export function cCharacteristics() {
  return [
    { id: 'so', short: i18n.t('SO'), long: i18n.t('Sexual orientation') },
    { id: 'gi', short: i18n.t('GI'), long: i18n.t('Gender identity') },
    { id: 'ge', short: i18n.t('GE'), long: i18n.t('Gender expression') },
    { id: 'sc', short: i18n.t('SC'), long: i18n.t('Sex characteristics') }
  ]
}

export function advancedSearchTopicsFilter(allTopics) {
  return allTopics.filter(
    t => ['801', '145', '146', '147', '148', '149'].indexOf(t.id) === -1
  )
}

export function cAreas() {
  return [
    { area: '1', name: i18n.t('Legal framework') },
    {
      area: '1-1',
      name: '1. ' + i18n.t('Criminalisation of consensual same-sex sexual acts')
    },
    {
      area: '1-2',
      name: '2. ' + i18n.t('Criminalisation of gender expression')
    },
    {
      area: '1-3',
      name: '3. ' + i18n.t('Legal barriers to freedom of expression')
    },
    {
      area: '1-4',
      name: '4. ' + i18n.t('Legal barriers to freedom of association')
    },
    {
      area: '1-5',
      name: '5. ' + i18n.t('Constitutional protection against discrimination')
    },
    {
      area: '1-6',
      name:
        '6. ' +
        i18n.t(
          'Protection against discrimination in the provision of goods and services'
        )
    },
    {
      area: '1-7',
      name: '7. ' + i18n.t('Protection against discrimination in health')
    },
    {
      area: '1-8',
      name: '8. ' + i18n.t('Protection against discrimination in education')
    },
    { area: '1-9', name: '9. ' + i18n.t('Protection against school bullying') },
    {
      area: '1-10',
      name: '10. ' + i18n.t('Protection against discrimination in employment')
    },
    {
      area: '1-11',
      name: '11. ' + i18n.t('Protection against discrimination in housing')
    },
    { area: '1-12', name: '12. ' + i18n.t('Hate crime law') },
    {
      area: '1-13',
      name:
        '13. ' +
        i18n.t(
          'Prohibition of incitement to violence, hatred or discrimination'
        )
    },
    {
      area: '1-14',
      name: '14. ' + i18n.t('Restrictions on so-called "conversion therapies"')
    },
    {
      area: '1-15',
      name: '15. ' + i18n.t('Same-Sex Marriage and Civil Unions')
    },
    { area: '1-16', name: '16. ' + i18n.t('Adoption by same-sex couples') },
    { area: '1-17', name: '17. ' + i18n.t('Legal gender recognition') },
    {
      area: '1-18',
      name: '18. ' + i18n.t('Restrictions on interventions on intersex minors')
    },
    { area: '2', name: i18n.t('International Human Rights Treaties') },
    { area: '3', name: i18n.t('UN Treaty Bodies') },
    { area: '4', name: i18n.t('UN Special Procedures') },
    { area: '5', name: i18n.t('Universal Periodic Review (UPR)') },
    { area: '6', name: i18n.t('UN Voting Record & Statements') }
  ]
}
export function cProtectionCategories() {
  return [
    { id: 'SO', name: i18n.t('Sexual Orientation') },
    { id: 'GI', name: i18n.t('Gender Identity') },
    { id: 'GE', name: i18n.t('Gender Expression') },
    { id: 'SC', name: i18n.t('Sex Characteristics') }
  ]
}

export function cMenuRegions() {
  return [
    { id: '0', label: i18n.t('Global') },
    { id: '1', label: i18n.t('Africa') },
    { id: '2', label: i18n.t('Asia') },
    { id: '3', label: i18n.t('Caribbean') },
    { id: '11', label: i18n.t('Europe') },
    { id: '18', label: i18n.t('Americas') },
    { id: '16', label: i18n.t('Oceania') },
    { id: '14', label: i18n.t('SWANA') }
  ]
}

export const cArea1BarChartRegions = () => [
  { id: '1', name: i18n.t('Africa') },
  { id: '2', name: i18n.t('Asia') },
  { id: '11', name: i18n.t('Europe') },
  { id: '13', name: i18n.t('LAC') },
  { id: '15', name: i18n.t('N. America') },
  { id: '16', name: i18n.t('Oceania') }
]

export const cArea13MapReferences = () => [
  { id: 3, name: i18n.t('No Known Legal Barriers'), color: '#FFF' },
  { id: 5, name: i18n.t('Unclear'), color: '#CCC' },
  { id: 6, name: i18n.t('Varies'), color: '#EDB795' },

  { id: 1, name: i18n.t('Non-Explicit Legal Barriers'), color: '#F5934B' },
  { id: 2, name: i18n.t('Explicit Legal Barriers'), color: '#D6643A' }
]

export const cArea14MapReferences = () => [
  { id: 4, name: i18n.t('No Known Legal Barriers'), color: '#FFF' },
  { id: 5, name: i18n.t('Unclear'), color: '#CCC', noShow: true },
  { id: 3, name: i18n.t('Legal Barriers Likely to Exist'), color: '#EDB795' },
  { id: 2, name: i18n.t('Non-Explicit Legal Barriers'), color: '#F5934B' },
  { id: 1, name: i18n.t('Explicit Legal Barriers'), color: '#D6643A' },
  { id: 6, name: i18n.t('N/A'), color: '#888', noShow: true }
]

export const cArea114MapReferences = () => [
  { id: 1, name: i18n.t('Restrictions in place'), color: '#4C7584' },

  { id: 3, name: i18n.t('Indirect restrictions in place'), color: '#659DB2' },
  { id: 4, name: i18n.t('Limited'), color: '#4C7584', noShow: true },
  { id: 5, name: i18n.t('Varies'), color: '#9DCADB' },
  { id: 2, name: i18n.t('No restrictions'), color: '#FFF' },
  { id: 6, name: i18n.t('Unclear'), color: '#9DCADB', noShow: true },
  { id: 7, name: i18n.t('N/A'), color: '#9DCADB', noShow: true }
]

export const cArea115MapReferences = () => [
  {
    id: 2,
    name: i18n.t('Same sex marriage and civil unions'),
    color: '#56407C'
  },
  { id: 1, name: i18n.t('Same sex marriage'), color: '#775CA2' },

  { id: 3, name: i18n.t('Civil Union'), color: '#967CC1' },
  { id: 4, name: i18n.t('Some Rights'), color: '#B19BD3', noShow: true },
  { id: 5, name: i18n.t('Varies'), color: '#C6BAE3' },
  { id: 6, name: i18n.t('Unclear'), color: '#C6BAE3', noShow: true },
  { id: 7, name: i18n.t('N/A'), color: '#C6BAE3', noShow: true },
  { id: 8, name: i18n.t('No'), color: '#FFF' }
]

export const cArea116MapReferences = () => [
  { id: 1, name: i18n.t('Joint & Second Parent Adoption'), color: '#775CA2' },
  { id: 2, name: i18n.t('Second Parent Adoption Only'), color: '#967CC1' },
  { id: 3, name: i18n.t('Varies'), color: '#C6BAE3' },

  { id: 5, name: i18n.t('Unclear'), color: '#C6BAE3', noShow: true },
  { id: 6, name: i18n.t('N/A'), color: '#9DCADB', noShow: true },
  { id: 4, name: i18n.t('No'), color: '#FFF' }
]

export const cArea117MapReferences = () => [
  { id: 2, name: i18n.t('Yes'), color: '#F0C85C' },
  { id: 3, name: i18n.t('Varies'), color: '#FFE5A7' },
  { id: 1, name: i18n.t('No'), color: '#FFF' },
  { id: 5, name: i18n.t('No'), color: '#FFF', noShow: true }, // Regressive development
  { id: 4, name: i18n.t('No'), color: '#FFF', noShow: true } // Regressive measure in force
]

export const cArea118MapReferencesRequirements = () => [
  { id: 1, name: i18n.t('Self-ID'), color: '#4C7584' },
  {
    id: 111,
    name: i18n.t('Self-ID (for NB marker only)'),
    color: '#4C7584',
    noShow: true
  },
  { id: 2, name: i18n.t('Varies'), color: '#9DCADB' },
  { id: 3, name: i18n.t('Unclear'), color: '#BFBFBF' },
  { id: 88, name: i18n.t('No data'), color: '#BFBFBF', noShow: true },
  {
    id: 4,
    name: i18n.t('Other reqs. (+)'),
    color: '#EDB795',
    tooltip: i18n.t(
      'Other requirements excluding diagnosis, non-surgical medical requirements, surgery and sterilisation'
    )
  },
  // deprecated
  // { id: 5, name: i18n.t('Diagnosis only'), color: '#F5934B' },
  { id: 6, name: i18n.t('Non-surgical medical reqs.'), color: '#F5934B' },
  {
    id: 7,
    name: i18n.t('Surgery and/or sterilisation req.'),
    color: '#D6643A'
  },
  { id: 8, name: i18n.t('Not possible'), color: '#919191' },
  { id: 99, name: '?', color: '#F00', noShow: true }
]

export const cArea118MapReferencesAge = () => [
  { id: 1, name: i18n.t('Available for children'), color: '#4C7584' },
  { id: 2, name: i18n.t('Varies'), color: '#659DB2' },
  { id: 3, name: i18n.t('Adults only'), color: '#9DCADB' },
  { id: 4, name: i18n.t('Unclear'), color: '#BFBFBF' },
  { id: 88, name: i18n.t('No data'), color: '#BFBFBF', noShow: true },
  { id: 5, name: i18n.t('LGR Not Possible'), color: '#63605E' },
  { id: 99, name: '?', color: '#000', noShow: true }
]

export const cArea118MapReferencesGm = () => [
  { id: 1, name: i18n.t('Available'), color: '#659DB2' },
  { id: 2, name: i18n.t('Varies'), color: '#9DCADB' },
  { id: 3, name: i18n.t('Unclear'), color: '#BFBFBF' },
  { id: 4, name: i18n.t('Binary markers only'), color: '#FFF' },
  { id: 99, name: '?', color: '#000', noShow: true }
]

export const cArea15MapReferences = () => [
  { id: 1, name: 'SO & GI & SC', color: '#ffffff' },
  { id: 2, name: 'SO & GI', color: '#F0DACA' },
  { id: 3, name: 'SO only', color: '#FAC09A' },
  { id: 4, name: 'GI Only', color: '#EB836B' },
  { id: 6, name: 'Varies', color: '#835E58' },
  { id: 7, name: 'None', color: '#672020' },
  { id: 5, name: 'Unclear', color: '#CCC' }
]

export const cArea2MapReferences = () => [
  { id: 1, name: i18n.t('No action'), color: '#F5934B' },
  { id: 2, name: i18n.t('Signed'), color: '#9DCADB' },
  { id: 3, name: i18n.t('Ratified or Adhered'), color: '#659DB2' },
  { id: 4, name: i18n.t('Accepts Individual Complaints'), color: '#476F7E' }
]
export const cArea2MapReferencesAll = () => [
  { id: 1, name: '1 ' + i18n.t('_to_') + ' 6', color: '#9DCADB' },
  { id: 2, name: '7 ' + i18n.t('_to_') + ' 12', color: '#659DB2' },
  { id: 3, name: '13 ' + i18n.t('_to_') + ' 18', color: '#4C7584' }
]

export const cArea2Treaties = () => [
  {
    id: 1,
    name: 'CAT',
    color: '#7D9AD0',
    sub: 1,
    fullname: i18n.t(
      'Convention against Torture and Other Cruel, Inhuman or Degrading Treatment'
    )
  },
  {
    id: 2,
    name: 'CAT-OP',
    op: true,
    noComplaints: true,
    parent: 1,
    fullname: i18n.t(
      'Optional Protocol of the Convention against Torture and Other Cruel, Inhuman or Degrading Treatment'
    ),
    boxName: i18n.t(
      'Op. Protocol of the Convention against Torture and Other Cruel, Inhuman or Degrading Treatment'
    )
  },
  {
    id: 3,
    name: 'CCPR',
    color: '#E5A9DE',
    noComplaints: true,
    sub: 2,
    fullname: i18n.t('International Covenant on Civil and Political Rights')
  },
  {
    id: 4,
    name: 'CCPR-OP1',
    op: true,
    parent: 3,
    fullname: i18n.t(
      'Optional Protocol to the International Covenant on Civil and Political Rights'
    )
  },
  {
    id: 5,
    name: 'CCPR-OP2-DP',
    op: true,
    parent: 3,
    noComplaints: true,
    fullname: i18n.t(
      'Second Optional Protocol to the International Covenant on Civil and Political Rights, aiming at the abolition of the death penalty'
    ),
    boxName: i18n.t('Second Optional Protocol to the ICCPR (death penalty)')
  },
  {
    id: 6,
    name: 'CED',
    color: '#96C9DB',
    fullname: i18n.t(
      'Convention for the Protection of All Persons from Enforced Disappearance'
    )
  },
  {
    id: 7,
    name: 'CEDAW',
    noComplaints: true,
    color: '#7D9AD0',
    sub: 1,
    fullname: i18n.t(
      'Convention on the Elimination of All Forms of Discrimination against Women'
    )
  },
  {
    id: 8,
    name: 'CEDAW-OP',
    parent: 7,
    op: true,
    fullname: i18n.t(
      'Optional Protocol to the Convention on the Elimination of All Forms of Discrimination against Women'
    )
  },
  {
    id: 9,
    name: 'CERD',
    color: '#92C598',
    fullname: i18n.t(
      'Convention on the Elimination of All Forms of Racial Discrimination'
    )
  },
  {
    id: 10,
    name: 'CESCR',
    noComplaints: true,
    color: '#E08AA2',
    sub: 1,
    fullname: i18n.t(
      'International Covenant on Economic, Social and Cultural Rights'
    )
  },
  {
    id: 11,
    name: 'CESCR-OP',
    parent: 10,
    op: true,
    fullname: i18n.t(
      'Optional Protocol to the International Covenant on Economic, Social and Cultural Rights'
    )
  },
  {
    id: 12,
    name: 'CMW',
    color: '#AB97CE',
    fullname: i18n.t(
      'Convention on the Protection of the Rights of All Migrant Workers and Members of Their Families'
    )
  },
  {
    id: 13,
    name: 'CRC',
    noComplaints: true,
    color: '#F5934B',
    sub: 3,
    fullname: i18n.t('Convention on the Rights of the Child')
  },
  {
    id: 14,
    name: 'CRC-OP-AC',
    noComplaints: true,
    op: true,
    parent: 13,
    fullname: i18n.t(
      'Optional Protocol to the Convention on the Rights of the Child on the involvement of children in armed conflict'
    )
  },
  {
    id: 15,
    name: 'CRC-OP-IC',
    op: true,
    parent: 13,
    fullname: i18n.t(
      'Optional Protocol to the Convention on the Rights of the Child on a communications procedure'
    )
  },
  {
    id: 16,
    name: 'CRC-OP-SC',
    noComplaints: true,
    op: true,
    parent: 13,
    fullname: i18n.t(
      'Optional Protocol to the Convention on the Rights of the Child on the sale of children, child prostitution and child pornography'
    ),

    boxName: i18n.t(
      'Optional Protocol to the Convention on the Rights of the Child on the sale of children, child prostitution and child pornography_es_short'
    )
  },
  {
    id: 17,
    name: 'CRPD',
    color: '#B1C691',
    noComplaints: true,
    sub: 1,
    fullname: i18n.t(
      'International Convention on the Rights of Persons with Disabilities'
    )
  },
  {
    id: 18,
    name: 'CRPD-OP',
    op: true,
    parent: 17,
    fullname: i18n.t(
      'Optional Protocol to the Convention on the Rights of Persons with Disabilities'
    )
  }
]

export const cArea3MapReferences = () => [
  { id: 1, name: '0 ' + i18n.t('_to_') + ' 20', color: '#ffffff' },
  { id: 2, name: '20 ' + i18n.t('_to_') + ' 40', color: '#aec9ce' },
  { id: 3, name: '40 ' + i18n.t('_to_') + ' 60', color: '#6ea2b5' },
  { id: 4, name: '60 ' + i18n.t('_to_') + ' 80', color: '#476f7e' },
  { id: 5, name: '80 ' + i18n.t('_to_') + ' 100', color: '#204f60' }
]

export function cMainRegions() {
  return [
    { id: '1', name: i18n.t('Africa'), color: '#F0C85C' },
    { id: '2', name: i18n.t('Asia'), color: '#F5934B' },
    { id: '11', name: i18n.t('Europe'), color: '#AB97CE' },
    { id: '18', name: i18n.t('Americas'), color: '#B1C691' },
    { id: '16', name: i18n.t('Oceania'), color: '#7D9AD0' }
  ]
}

export const cTreatyBodies = () => [
  { id: '1', name: i18n.t('HRCtee'), color: '#E5A9DE' },
  { id: '2', name: i18n.t('CESCR'), color: '#E08AA2' },
  { id: '3', name: i18n.t('CRC'), color: '#F5934B' },
  { id: '4', name: i18n.t('CEDAW'), color: '#F0C85C' },
  { id: '5', name: i18n.t('CRPD'), color: '#B1C691' },
  { id: '6', name: i18n.t('CERD'), color: '#92C598' },
  { id: '7', name: i18n.t('CED'), color: '#96C9DB' },
  { id: '8', name: i18n.t('CAT'), color: '#7D9AD0' },
  { id: '9', name: i18n.t('CMW'), color: '#AB97CE' }
  //{ id: "10", name: i18n.t("SPT") } disabled
]

export const cTreatyBodiesFull = () => [
  { id: '1', name: i18n.t('Human Rights Committee'), acronym: 'HRCtee' },
  {
    id: '2',
    name: i18n.t('Committee on Economic, Social and Cultural Rights'),
    acronym: 'CESCR'
  },
  {
    id: '3',
    name: i18n.t('Committee on the Rights of the Child'),
    acronym: 'CRC'
  },
  {
    id: '4',
    name: i18n.t(
      'Committee on the Elimination of Discrimination against Women'
    ),
    acronym: 'CEDAW'
  },
  {
    id: '5',
    name: i18n.t('Committee on the Rights of Persons with Disabilities'),
    acronym: 'CRPD'
  },
  {
    id: '6',
    name: i18n.t('Committee on the Elimination of Racial Discrimination'),
    acronym: 'CERD'
  },
  {
    id: '7',
    name: i18n.t('Committee on Enforced Disappearances'),
    acronym: 'CED'
  },
  { id: '8', name: i18n.t('Committee against Torture'), acronym: 'CAT' },
  {
    id: '9',
    name: i18n.t('Committee on Migrant Workers'),
    boxName: i18n.t('Committee on Migrant Workers_box'),
    acronym: 'CMW'
  }
  //{ id: "10", name: i18n.t("Subcommittee on Prevention of Torture"), acronym: "SPT" } disabled
]

export const cLegalFrameworksDecrimMechanisms = () => [
  { id: i18n.t('Judicial'), value: i18n.t('Judicial') },
  { id: i18n.t('Legislative'), value: i18n.t('Legislative') },
  { id: i18n.t('Executive'), value: i18n.t('Executive') },
  {
    id: i18n.t('Human Rights Commission'),
    value: i18n.t('Human Rights Commission')
  },
  { id: i18n.t('N/A'), value: i18n.t('N/A') }
]

export const cGradientsArea3 = [
  '#ffffff',
  '#9DCADB',
  '#659DB2',
  '#4C7584',
  '#3F555D'
]

export const cGradientsAreasProtection = [
  '#ffffff',
  '#9DCADB',
  '#659DB2',
  '#4C7584',
  '#3F555D'
]

export const cGradientsArea5 = [
  '#FFF',
  '#9DCADB',
  '#659DB2',
  '#4C7584',
  '#3F555D'
]

export const cGradientsArea4 = [
  '#ffffff',
  '#9DCADB',
  '#659DB2',
  '#4C7584',
  '#3F555D'
]

export const cRecommendationResponses = () => {
  return [
    { id: '1', name: i18n.t('Accepted') },
    { id: '2', name: i18n.t('Noted') },
    { id: '3', name: i18n.t('Partially Accepted') }
  ]
}

export const cVotingTypes = () => {
  return [
    { id: '1', name: i18n.t('Yes (Fav)') },
    { id: '2', name: i18n.t('Yes (Unfav)') },
    { id: '3', name: i18n.t('No (Fav)') },
    { id: '4', name: i18n.t('No (Unfav)') },
    { id: '5', name: i18n.t('Abstained') },
    { id: '6', name: i18n.t('DNV') }
  ]
}

export const cVotingTypesMerged = () => {
  return [
    { id: '1', name: i18n.t('Fav') },
    { id: '2', name: i18n.t('Unfav') },

    { id: '5', name: i18n.t('Abstained') },
    { id: '6', name: i18n.t('DNV') }
  ]
}

export const cSpecialProceduresTypes = () =>
  [
    {
      id: '1',
      name: 'IE Albinism',
      amount_all: 2,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '2',
      name: 'IE Foreign Debt',
      amount_all: 1,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '3',
      name: 'IE International Order',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '4',
      name: 'IE International Solidarity',
      amount_all: 4,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '5',
      name: 'IE Older Persons',
      amount_all: 6,
      amount_rec: 3,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 2
        }
      ]
    },
    {
      id: '6',
      name: 'IE SOGI',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '7',
      name: 'SR Assembly and Assoc.',
      amount_all: 27,
      amount_rec: 7,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 2
        },
        {
          year: 2019,
          amount_rec: 4
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 1
        }
      ]
    },
    {
      id: '8',
      name: 'SR Cultural Rights',
      amount_all: 38,
      amount_rec: 6,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 4
        },
        {
          year: 2019,
          amount_rec: 1
        },
        {
          year: 2018,
          amount_rec: 1
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '9',
      name: 'SR Development',
      amount_all: 2,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '10',
      name: 'SR Disabilities',
      amount_all: 1,
      amount_rec: 1,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 1
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '11',
      name: 'SR Displaced Persons',
      amount_all: 8,
      amount_rec: 2,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 1
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 1
        }
      ]
    },
    {
      id: '12',
      name: 'SR Education',
      amount_all: 2,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '13',
      name: 'SR Environment',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '14',
      name: 'SR Exploit. of Children',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '15',
      name: 'SR Extrajudicial Ex.',
      amount_all: 40,
      amount_rec: 14,
      byYear: [
        {
          year: 2021,
          amount_rec: 2
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 10
        },
        {
          year: 2017,
          amount_rec: 2
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '16',
      name: 'SR FOE',
      amount_all: 13,
      amount_rec: 1,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 1
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '17',
      name: 'SR Food',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '19',
      name: 'SR Health',
      amount_all: 84,
      amount_rec: 18,
      byYear: [
        {
          year: 2021,
          amount_rec: 3
        },
        {
          year: 2020,
          amount_rec: 3
        },
        {
          year: 2019,
          amount_rec: 1
        },
        {
          year: 2018,
          amount_rec: 4
        },
        {
          year: 2017,
          amount_rec: 4
        },
        {
          year: 2016,
          amount_rec: 3
        }
      ]
    },
    {
      id: '20',
      name: 'SR Housing',
      amount_all: 30,
      amount_rec: 11,
      byYear: [
        {
          year: 2021,
          amount_rec: 1
        },
        {
          year: 2020,
          amount_rec: 4
        },
        {
          year: 2019,
          amount_rec: 6
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '21',
      name: 'SR HR Defenders',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '22',
      name: 'SR Human Trafficking',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '23',
      name: 'SR Indep. of Judges',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '24',
      name: 'SR Indigenous',
      amount_all: 2,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '25',
      name: 'SR Leprosy',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '26',
      name: 'SR Migrants',
      amount_all: 3,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '27',
      name: 'SR Minority Issues',
      amount_all: 2,
      amount_rec: 1,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 1
        }
      ]
    },
    {
      id: '28',
      name: 'SR Poverty',
      amount_all: 19,
      amount_rec: 3,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 3
        }
      ]
    },
    {
      id: '29',
      name: 'SR Privacy',
      amount_all: 7,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '30',
      name: 'SR Racism',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '31',
      name: 'SR Religion',
      amount_all: 46,
      amount_rec: 6,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 3
        },
        {
          year: 2018,
          amount_rec: 2
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 1
        }
      ]
    },
    {
      id: '32',
      name: 'SR Slavery',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '33',
      name: 'SR Sanitation',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '34',
      name: 'SR Terrorism',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '35',
      name: 'SR Torture',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '36',
      name: 'SR Truth, Justice & Rep.',
      amount_all: 13,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '37',
      name: 'SR Unilateral Measures',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '38',
      name: 'SR Violence a/Women',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '39',
      name: 'WG African Descent',
      amount_all: 38,
      amount_rec: 5,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 1
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 1
        },
        {
          year: 2016,
          amount_rec: 2
        }
      ]
    },
    {
      id: '40',
      name: 'WG Arbitrary Detention',
      amount_all: 12,
      amount_rec: 2,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 1
        },
        {
          year: 2019,
          amount_rec: 1
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '41',
      name: 'WG Disc. Women & Girls',
      amount_all: 53,
      amount_rec: 12,
      byYear: [
        {
          year: 2021,
          amount_rec: 3
        },
        {
          year: 2020,
          amount_rec: 1
        },
        {
          year: 2019,
          amount_rec: 4
        },
        {
          year: 2018,
          amount_rec: 2
        },
        {
          year: 2017,
          amount_rec: 2
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '42',
      name: 'WG HR & Business',
      amount_all: 12,
      amount_rec: 5,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 2
        },
        {
          year: 2019,
          amount_rec: 3
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '43',
      name: 'WG Involunt. Disapp.',
      amount_all: 4,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '44',
      name: 'WG Mercernaries',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '45',
      name: 'IE Central African Rep.',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '46',
      name: 'IE Mali',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '47',
      name: 'IE Somalia',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '48',
      name: 'SR Belarus',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '49',
      name: 'SR Cambodia',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '50',
      name: 'SR Eritrea',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '51',
      name: 'SR Myanmar',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '52',
      name: 'SR North Korea',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '53',
      name: 'SR Iran',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '54',
      name: 'SR Palestine',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '55',
      name: 'SR Syria',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    },
    {
      id: '56',
      name: 'SR Toxics',
      amount_all: 0,
      amount_rec: 0,
      byYear: [
        {
          year: 2021,
          amount_rec: 0
        },
        {
          year: 2020,
          amount_rec: 0
        },
        {
          year: 2019,
          amount_rec: 0
        },
        {
          year: 2018,
          amount_rec: 0
        },
        {
          year: 2017,
          amount_rec: 0
        },
        {
          year: 2016,
          amount_rec: 0
        }
      ]
    }
  ].map(r => ({ ...r, name: i18n.t(r.name) }))

export const cTreatyBodiesCuTypesFilter = () => [
  {
    name: i18n.t('Comments received from the State Party'),
    hideInFilter: true
  },
  {
    name: i18n.t('Concluding Observation (Mention)')
  },
  {
    name: i18n.t('Concluding Observation (Recomm.)')
  },
  {
    name: i18n.t('General Comment (Mention)'),
    not5: 5,
    notJur: true
  },
  {
    name: i18n.t('General Comment (Recomm.)'),
    not5: 5,
    notJur: true
  },
  {
    name: i18n.t('General Recommendation (Mention)'),
    only5: true,
    notJur: true
  },
  {
    name: i18n.t('General Recommendation (Recomm.)'),
    only5: true,
    notJur: true
  },
  {
    name: i18n.t('Individual Communication'),
    hideInFilter: true
  },
  {
    name: i18n.t('Inquiry Procedure (Mention)'),
    notJur: true
  },
  {
    name: i18n.t('Inquiry Procedure (Recomm.)'),
    notJur: true
  },
  {
    name: i18n.t('List of Issues (LOI, LOIPR, LOT)'),
    value: i18n.t('List of')
  },

  {
    name: i18n.t('Report sent to the State (Metion)'),
    hideInFilter: true
  },
  {
    name: i18n.t('Report sent to the State (Recomm.)'),
    hideInFilter: true
  }
]

export const cTreatyBodiesCuTypes = () => [
  {
    ids: [77],
    name: i18n.t('Comments received from the State Party'),
    hideInFilter: true
  },
  {
    ids: [1, 17, 26, 35, 44, 50, 8, 59, 68],
    name: i18n.t('Concluding Observation (Mention)')
  },
  {
    ids: [51, 45, 60, 36, 27, 69, 2, 18, 9],
    name: i18n.t('Concluding Observation (Recomm.)')
  },
  {
    ids: [3, 10, 19, 37, 46, 52, 61, 70],
    name: i18n.t('General Comment (Mention)'),
    not5: 5,
    notJur: true
  },
  {
    ids: [4, 11, 20, 38, 47, 53, 62, 71],
    name: i18n.t('General Comment (Recomm.)'),
    not5: 5,
    notJur: true
  },
  {
    ids: [28],
    name: i18n.t('General Recommendation (Mention)'),
    only5: true,
    notJur: true
  },
  {
    ids: [29],
    name: i18n.t('General Recommendation (Recomm.)'),
    only5: true,
    notJur: true
  },
  {
    ids: [54, 72, 21, 5, 63, 30, 48, 39, 12],
    name: i18n.t('Individual Communication'),
    hideInFilter: true
  },
  {
    ids: [31, 22, 64, 13, 40, 55],
    name: i18n.t('Inquiry Procedure (Mention)'),
    notJur: true
  },
  {
    ids: [23, 32, 14, 65, 56, 41],
    name: i18n.t('Inquiry Procedure (Recomm.)'),
    notJur: true
  },
  {
    ids: [15, 6, 24, 73, 66, 57, 33, 42],
    name: i18n.t('List of Issues (LOI)')
  },
  {
    ids: [67, 74, 43, 34, 16, 25, 7, 58],
    name: i18n.t('List of Issues prior to reporting (LOIPR)')
  },
  { ids: [49], name: i18n.t('List of Themes (LOT)') },
  {
    ids: [75],
    name: i18n.t('Report sent to the State (Metion)'),
    hideInFilter: true
  },
  {
    ids: [76],
    name: i18n.t('Report sent to the State (Recomm.)'),
    hideInFilter: true
  }
]
export const isInquiryProcedure = id => {
  return (
    [31, 22, 64, 13, 40, 55, 23, 32, 14, 65, 56, 41].indexOf(parseInt(id)) !==
    -1
  )
}
export const cSpecialProceduresCuTypes = () => [
  {
    id: 1,
    name: i18n.t('Report to HRC (Mention)'),
    label: i18n.t('Report to HRC (Mention)')
  },
  {
    id: 2,
    name: i18n.t('Report to HRC (Recomm.)'),
    label: i18n.t('Report to HRC (Recomm.)')
  },
  {
    id: 3,
    name: i18n.t('Report to UNGA (Mention)'),
    label: i18n.t('Report to UNGA (Mention)')
  },
  {
    id: 4,
    name: i18n.t('Report to UNGA (Recomm.)'),
    label: i18n.t('Report to UNGA (Recomm.)')
  },
  {
    id: 5,
    name: i18n.t('Country Visit (Mention)'),
    label: i18n.t('Country Visit (Mention)')
  },
  {
    id: 6,
    name: i18n.t('Country Visit (Recomm.)'),
    label: i18n.t('Country Visit (Recomm.)')
  },
  {
    id: 7,
    name: i18n.t('Communication'),
    label: i18n.t('Communication')
  },
  {
    id: 8,
    name: i18n.t('Individual Comm. (Response)'),
    label: i18n.t('Individual Comm. (Response)'),
    hideInFilter: true
  },
  {
    id: 9,
    name: i18n.t('Follow-Up Report (Mention)'),
    label: i18n.t('Follow-Up Report (Mention)')
  },
  {
    id: 10,
    name: i18n.t('Follow-Up Report (Recomm.)'),
    label: i18n.t('Follow-Up Report (Recomm.)')
  }
]

export const advancedSearchDocumentTypes = () => [
  {
    areas: [3],
    name: i18n.t('Concluding Observations'),
    values: [
      i18n.t('Concluding Observation (Mention)'),
      i18n.t('Concluding Observation (Recomm.)')
    ]
  },
  {
    areas: [3],
    name: i18n.t('General Comments'),
    values: [
      i18n.t('General Comment (Mention)'),
      i18n.t('General Comment (Recomm.)'),
      i18n.t('General Recommendation (Mention)'),
      i18n.t('General Recommendation (Recomm.)')
    ]
  },
  {
    areas: [4],
    name: i18n.t('Individual Communications'),
    values: [
      i18n.t('Individual Communication'),
      i18n.t('Communication'),
      i18n.t('Individual Comm. (Response)')
    ]
  },
  {
    areas: [3],
    name: i18n.t('List of Issues (LOI, LOIPR, LOT)'),
    values: [
      i18n.t('List of Issues (LOI)'),
      i18n.t('List of Issues prior to reporting (LOIPR)'),
      i18n.t('List of Themes (LOT)')
    ]
  },
  {
    areas: [3],
    name: i18n.t('Inquiry Procedure'),
    values: [
      i18n.t('Inquiry Procedure (Mention)'),
      i18n.t('Inquiry Procedure (Recomm.)')
    ]
  },

  {
    areas: [4],
    name: i18n.t('Reports to HRC'),
    values: [
      i18n.t('Report to HRC (Mention)'),
      i18n.t('Report to HRC (Recomm.)')
    ]
  },
  {
    areas: [4],
    name: i18n.t('Reports to UNGA'),
    values: [
      i18n.t('Report to UNGA (Mention)'),
      i18n.t('Report to UNGA (Recomm.)')
    ]
  },
  {
    areas: [4],
    name: i18n.t('Reports on Country Visits'),
    values: [
      i18n.t('Country Visit (Mention)'),
      i18n.t('Country Visit (Recomm.)')
    ]
  },
  {
    areas: [4],
    name: i18n.t('Follow-Up Reports'),
    values: [
      i18n.t('Follow-Up Report (Mention)'),
      i18n.t('Follow-Up Report (Recomm.)')
    ]
  }
]

export const cUnMechanisms = () => [
  {
    id: '67',
    name_en: 'Universal Periodic Review (UPR)',
    name_es: 'Examen Periódico Universal',
    short_name_en: 'Universal Periodic Review',
    short_name_es: 'Examen Periódico Universal'
  },
  {
    id: '1',
    name_en: 'Committee against Torture (CAT)',
    name_es: 'Comité contra la Tortura (CAT)',
    short_name_en: 'CAT',
    short_name_es: 'CAT'
  },
  {
    id: '2',
    name_en:
      'Committee on the Elimination of Discrimination against Women (CEDAW)',
    name_es:
      'Comité para la Eliminación de la Discriminación contra las Mujeres (CEDAW)',
    short_name_en: 'CEDAW',
    short_name_es: 'CEDAW'
  },
  {
    id: '3',
    name_en: 'Committee on Economic, Social and Cultural Rights (CESCR)',
    name_es: 'Comité de Derechos Económicos, Sociales y Culturales (CESCR)',
    short_name_en: 'CESCR',
    short_name_es: 'CESCR'
  },
  {
    id: '4',
    name_en: 'Committee on Enforced Disappearances (CED)',
    name_es: 'Comité contra las Desapariciones Forzadas (CED)',
    short_name_en: 'CEDAW',
    short_name_es: 'CEDAW'
  },
  {
    id: '5',
    name_en: 'Committee on Migrant Workers (CMW)',
    name_es:
      'Comité para la Protección de los Derechos de todes les Trabajadores Migratories y sus Familiares (CMW)',
    short_name_en: 'CMW',
    short_name_es: 'CMW'
  },
  {
    id: '6',
    name_en: 'Committee on the Elimination of Racial Discrimination (CERD)',
    name_es: 'Comité para la Eliminación de la Discriminación Racial (CERD)',
    short_name_en: 'CERD',
    short_name_es: 'CERD'
  },
  {
    id: '7',
    name_en: 'Committee on the Rights of Persons with Disabilities (CRPD)',
    name_es: 'Comité de los Derechos de las Personas con Discapacidad (CRPD)',
    short_name_en: 'CRPD',
    short_name_es: 'CRPD'
  },
  {
    id: '8',
    name_en: 'Committee on the Rights of the Child (CRC)',
    name_es: 'Comité de los Derechos de le Niñe (CRC)',
    short_name_en: 'CRC',
    short_name_es: 'CRC'
  },
  {
    id: '9',
    name_en: 'Human Rights Committee (HRCtee)',
    name_es: 'Comité de Derechos Humanos (HRCtee)',
    short_name_en: 'HRCtee',
    short_name_es: 'HRCtee'
  },
  {
    id: '10',
    name_en: 'Subcommittee on Prevention of Torture (SPT)',
    name_es: 'Subcomité para la Prevención de la Tortura (SPT)',
    short_name_en: 'SPT',
    short_name_es: 'SPT'
  },
  {
    id: '11',
    name_en:
      'Independent Expert on the enjoyment of human rights by persons with albinism',
    name_es:
      'Experte independiente sobre el disfrute de los derechos humanos de las personas con albinismo',
    short_name_en: 'IE Albinism',
    short_name_es: 'EI Albinismo'
  },
  {
    id: '12',
    name_en:
      'Independent Expert on the effects of foreign debt and other related international financial obligations of States on the full enjoyment of all human rights, particularly economic, social and cultural rights',
    name_es:
      'Experte independiente sobre las consecuencias de la deuda externa y las obligaciones financieras internacionales conexas de los Estados para el pleno goce de todos los derechos humanos, sobre todo los derechos económicos, sociales y culturales',
    short_name_en: 'IE Foreign Debt',
    short_name_es: 'EI Deuda Externa'
  },
  {
    id: '13',
    name_en:
      'Independent Expert on the promotion of a democratic and equitable international order',
    name_es:
      'Experte independiente sobre la promoción de un orden internacional democrático y equitativo',
    short_name_en: 'IE International Order',
    short_name_es: 'EI Orden Internacional'
  },
  {
    id: '14',
    name_en: 'Independent Expert on human rights and international solidarity',
    name_es:
      'Experte independiente sobre derechos humanos y solidaridad internacional.',
    short_name_en: 'IE International Solidarity',
    short_name_es: 'EI Solidaridad Internacional'
  },
  {
    id: '15',
    name_en:
      'Independent Expert on the enjoyment of all human rights by older persons',
    name_es:
      'Experte independiente sobre el disfrute de todos los derechos humanos por las personas de edad',
    short_name_en: 'IE Older Persons',
    short_name_es: 'EI Adultes Mayores'
  },
  {
    id: '16',
    name_en:
      'Independent Expert on protection against violence and discrimination based on sexual orientation and gender identity',
    name_es:
      'Experte independiente sobre la protección contra la violencia y la discriminación por motivos de orientación sexual o identidad de género',
    short_name_en: 'IE SOGI',
    short_name_es: 'EI OSIG'
  },
  {
    id: '17',
    name_en: 'Special Rapporteur in the field of cultural rights',
    name_es: 'Relatore especial sobre los derechos culturales',
    short_name_en: 'SR Cultural Rights',
    short_name_es: 'RE Derechos Culturales'
  },
  {
    id: '18',
    name_en: 'Special Rapporteur on the right to development',
    name_es: 'Relatore especial sobre el derecho al desarrollo.',
    short_name_en: 'SR Development',
    short_name_es: 'RE Desarrollo'
  },
  {
    id: '19',
    name_en: 'Special Rapporteur on the rights of persons with disabilities',
    name_es:
      'Relatore especial sobre los derechos de las personas con discapacidad',
    short_name_en: 'SR Disabilities',
    short_name_es: 'RE Discapacidad'
  },
  {
    id: '20',
    name_en:
      'Special Rapporteur on the human rights of internally displaced persons',
    name_es:
      'Relatore especial sobre los derechos humanos de les desplazades internes',
    short_name_en: 'SR Displaced Persons',
    short_name_es: 'RE Pers. Desplazadas'
  },
  {
    id: '21',
    name_en: 'Special Rapporteur on the right to education',
    name_es: 'Relatore especial sobre el derecho a la educación.',
    short_name_en: 'SR Education',
    short_name_es: 'RE Educación'
  },
  {
    id: '22',
    name_en:
      'Special Rapporteur on the issue of human rights obligations relating to the enjoyment of a safe, clean, healthy and sustainable environment',
    name_es:
      'Relatore especial sobre la cuestión de las obligaciones de derechos humanos relacionadas con el disfrute de un entorno seguro, limpio, saludable y sostenible',
    short_name_en: 'SR Environment',
    short_name_es: 'RE Medio Ambiente'
  },
  {
    id: '23',
    name_en:
      'Special Rapporteur on the sale and sexual exploitation of children, including child prostitution, child pornography and other child sexual abuse material',
    name_es:
      'Relatore especial sobre la venta y la explotación sexual de niños, incluidos la prostitución infantil, la utilización de niños en la pornografía y demás material que muestre abusos sexuales de menores',
    short_name_en: 'SR Exploit. of Children',
    short_name_es: 'RE Explot. Niñes'
  },
  {
    id: '24',
    name_en:
      'Special Rapporteur on extrajudicial, summary or arbitrary executions',
    name_es:
      'Relatore especial sobre ejecuciones extrajudiciales, sumarias o arbitrarias.',
    short_name_en: 'SR Extrajudicial Ex.',
    short_name_es: 'RE Ejec. Extrajud.'
  },
  {
    id: '25',
    name_en:
      'Special Rapporteur on the promotion and protection of the right to freedom of opinion and expression',
    name_es:
      'Relatore especial sobre la promoción y protección del derecho a la libertad de opinión y de expresión',
    short_name_en: 'SR FOE',
    short_name_es: 'RE Lib. Expresión'
  },
  {
    id: '26',
    name_en: 'Special Rapporteur on the right to food',
    name_es: 'Relatore especial sobre el derecho a la alimentación',
    short_name_en: 'SR Food',
    short_name_es: 'RE Alimentación'
  },
  {
    id: '27',
    name_en:
      'Special Rapporteur on the implications for human rights of the environmentally sound management and disposal of hazardous substances and wastes',
    name_es:
      'Relatore especial sobre las implicaciones para los derechos humanos de la gestión y eliminación ecológicamente racionales de las sustancias y los desechos peligrosos',
    short_name_en: 'SR Hazardous Waste',
    short_name_es: 'RE Residuos Peligrosos'
  },
  {
    id: '28',
    name_en:
      'Special Rapporteur on the right of everyone to the enjoyment of the highest attainable standard of physical and mental health',
    name_es:
      'Relatore especial sobre el derecho de toda persona al disfrute del más alto nivel posible de salud física y mental',
    short_name_en: 'SR Health',
    short_name_es: 'RE Salud'
  },
  {
    id: '29',
    name_en:
      'Special Rapporteur on adequate housing as a component of the right to an adequate standard of living, and on the right to non-discrimination in this context',
    name_es:
      'Relatore especial sobre una vivienda adecuada como elemento integrante del derecho a un nivel de vida adecuado y sobre el derecho de no discriminación a este respecto',
    short_name_en: 'SR Housing',
    short_name_es: 'RE Vivenda'
  },
  {
    id: '30',
    name_en: 'Special Rapporteur on the situation of human rights defenders',
    name_es:
      'Relatore especial sobre la situación de les defensores de derechos humanos',
    short_name_en: 'SR HR Defenders',
    short_name_es: 'RE Defensores DDHH'
  },
  {
    id: '31',
    name_en:
      'Special Rapporteur on trafficking in persons, especially women and children',
    name_es:
      'Relatore especial sobre la trata de personas, especialmente mujeres y niñes',
    short_name_en: 'SR Human Trafficking',
    short_name_es: 'RE Trafico de Personas'
  },
  {
    id: '32',
    name_en: 'Special Rapporteur on the independence of judges and lawyers',
    name_es:
      'Relatore especial sobre la independencia de les magistrades y abogades',
    short_name_en: 'SR Indep. of Judges',
    short_name_es: 'RE Indep. Jueces'
  },
  {
    id: '33',
    name_en: 'Special Rapporteur on the rights of indigenous peoples',
    name_es: 'Relatore especial sobre los derechos de los pueblos indígenas',
    short_name_en: 'SR Indigenous',
    short_name_es: 'RE Indígenas'
  },
  {
    id: '34',
    name_en:
      'Special Rapporteur on the elimination of discrimination against persons affected by leprosy and their family members',
    name_es:
      'Relatore especial sobre la eliminación de la discriminación contra las personas afectadas por la lepra y sus familiares',
    short_name_en: 'SR Leprosy',
    short_name_es: 'RE Lepra'
  },
  {
    id: '35',
    name_en: 'Special Rapporteur on the human rights of migrants',
    name_es: 'Relatore especial sobre los derechos humanos de les migrantes',
    short_name_en: 'SR Migrants',
    short_name_es: 'RE Migrantes'
  },
  {
    id: '36',
    name_en: 'Special Rapporteur on minority issues',
    name_es: 'Relatore especial sobre cuestiones de las minorías',
    short_name_en: 'SR Minority Issues',
    short_name_es: 'RE Minorías'
  },
  {
    id: '37',
    name_en: 'Special Rapporteur on extreme poverty and human rights',
    name_es:
      'Relatore especial sobre la extrema pobreza y los derechos humanos',
    short_name_en: 'SR Poverty',
    short_name_es: 'RE Pobreza'
  },
  {
    id: '38',
    name_en: 'Special Rapporteur on the right to privacy',
    name_es: 'Relatore especial sobre el derecho a la privacidad',
    short_name_en: 'SR Privacy',
    short_name_es: 'RE Privacidad'
  },
  {
    id: '39',
    name_en:
      'Special Rapporteur on contemporary forms of racism, racial discrimination, xenophobia and related intolerance',
    name_es:
      'Relatore especial sobre las formas contemporáneas de racismo, discriminación racial, xenofobia y formas conexas de intolerancia',
    short_name_en: 'SR Racism',
    short_name_es: 'RE Racismo'
  },
  {
    id: '40',
    name_en: 'Special Rapporteur on freedom of religion or belief',
    name_es: 'Relatore especial sobre la libertad de religión o de creencias',
    short_name_en: 'SR Religion',
    short_name_es: 'RE Religión'
  },
  {
    id: '41',
    name_en:
      'Special Rapporteur on contemporary forms of slavery, including its causes and consequences',
    name_es:
      'Relatore especial sobre las formas contemporáneas de la esclavitud, incluidas sus causas y consecuencias',
    short_name_en: 'SR Slavery',
    short_name_es: 'RE Esclavitud'
  },
  {
    id: '42',
    name_en:
      'Special Rapporteur on the human rights to safe drinking water and sanitation',
    name_es:
      'Relatore especial sobre los derechos humanos al agua potable y el saneamiento',
    short_name_en: 'SR Sanitation',
    short_name_es: 'RE Sanidad'
  },
  {
    id: '43',
    name_en:
      'Special Rapporteur on the promotion and protection of human rights and fundamental freedoms while countering terrorism',
    name_es:
      'Relatore especial sobre la promoción y la protección de los derechos humanos y las libertades fundamentales en la lucha contra el terrorismo',
    short_name_en: 'SR Terrorism',
    short_name_es: 'RE Terrorismo'
  },
  {
    id: '44',
    name_en:
      'Special Rapporteur on torture and other cruel, inhuman or degrading treatment or punishment',
    name_es:
      'Relatore especial sobre la tortura y otros tratos o penas crueles, inhumanos o degradantes',
    short_name_en: 'SR Torture',
    short_name_es: 'RE Tortura'
  },
  {
    id: '45',
    name_en:
      'Special Rapporteur on the promotion of truth, justice, reparation and guarantees of non-recurrence',
    name_es:
      'Relatore especial sobre la promoción de la verdad, la justicia, la reparación y las garantías de no repetición',
    short_name_en: 'SR Truth, Justice & Rep.',
    short_name_es: 'RE Verdad, Just. y Rep.'
  },
  {
    id: '46',
    name_en:
      'Special Rapporteur on the negative impact of unilateral coercive measures on the enjoyment of human rights',
    name_es:
      'Relatore especial sobre las repercusiones negativas de las medidas coercitivas unilaterales en el disfrute de los derechos humanos',
    short_name_en: 'SR Unilateral Measures',
    short_name_es: 'RE Meds. Unilats.'
  },
  {
    id: '47',
    name_en:
      'Special Rapporteur on violence against women, its causes and consequences',
    name_es:
      'Relatore especial sobre la violencia contra las mujeres y las niñas, sus causas y consecuencias',
    short_name_en: 'SR Violence a/Women',
    short_name_es: 'RE Violen. c/Mujeres'
  },
  {
    id: '48',
    name_en: 'Working Group of Experts on People of African Descent',
    name_es: 'Grupo de trabajo de expertes sobre los afrodescendientes',
    short_name_en: 'WG African Descent',
    short_name_es: 'GT Pers. Afrodesc.'
  },
  {
    id: '49',
    name_en: 'Working Group on Arbitrary Detention',
    name_es: 'Grupo de trabajo sobre detención arbitraria.',
    short_name_en: 'WG Arbitrary Detention',
    short_name_es: 'GT Det. Arbitraria'
  },
  {
    id: '50',
    name_en: 'Working Group on discrimination against women and girls',
    name_es:
      'Grupo de trabajo sobre discriminación contra las mujeres y las niñas',
    short_name_en: 'WG Disc. Women & Girls',
    short_name_es: 'GT Discr. Mujeres y Niñas'
  },
  {
    id: '51',
    name_en:
      'Working Group on the issue of human rights and transnational corporations and other business enterprises',
    name_es:
      'Grupo de trabajo sobre la cuestión de los derechos humanos y las empresas transnacionales y otras empresas',
    short_name_en: 'WG HR & Business',
    short_name_es: 'GT DDHH y Empresas'
  },
  {
    id: '52',
    name_en: 'Working Group on Enforced or Involuntary Disappearances',
    name_es:
      'Grupo de trabajo sobre las desapariciones forzadas o involuntarias',
    short_name_en: 'WG Involunt. Disapp.',
    short_name_es: 'GT Desap. Forzadas'
  },
  {
    id: '53',
    name_en:
      'Working Group on the use of mercenaries as a means of violating human rights and impeding the exercise of the right of peoples to self-determination',
    name_es:
      'Grupo de trabajo sobre la utilización de mercenarios como medio de violar los derechos humanos y obstaculizar el ejercicio del derecho de los pueblos a la libre determinación',
    short_name_en: 'WG Mercernaries',
    short_name_es: 'GT Mercenarios'
  },
  {
    id: '54',
    name_en:
      'Independent Expert on the situation of human rights in Central African Republic',
    name_es:
      'Experte independiente sobre la situación de los derechos humanos en la República Centroafricana.',
    short_name_en: 'IE Central African Rep.',
    short_name_es: 'EI Rep. Centroafricana'
  },
  {
    id: '55',
    name_en: 'Independent Expert on the situation of human rights in Mali',
    name_es:
      'Experte independiente sobre la situación de los derechos humanos en Mali.',
    short_name_en: 'IE Mali',
    short_name_es: 'EI Mali'
  },
  {
    id: '56',
    name_en: 'Independent Expert on the situation of human rights in Somalia',
    name_es:
      'Experte independiente sobre la situación de los derechos humanos en Somalia.',
    short_name_en: 'IE Somalia',
    short_name_es: 'EI Somalia'
  },
  {
    id: '57',
    name_en: 'Special Rapporteur on the situation of human rights in Belarus',
    name_es:
      'Relatore especial sobre la situación de los derechos humanos en Belarus',
    short_name_en: 'SR Belarus',
    short_name_es: 'RE Belarus'
  },
  {
    id: '58',
    name_en: 'Special Rapporteur on the situation of human rights in Cambodia',
    name_es:
      'Relatore especial sobre la situación de los derechos humanos en Camboya',
    short_name_en: 'SR Cambodia',
    short_name_es: 'RE Camboya'
  },
  {
    id: '59',
    name_en: 'Special Rapporteur on the situation of human rights in Eritrea',
    name_es:
      'Relatore especial sobre la situación de los derechos humanos en Eritrea',
    short_name_en: 'SR Eritrea',
    short_name_es: 'RE Eritrea'
  },
  {
    id: '60',
    name_en: 'Special Rapporteur on the situation of human rights in Myanmar',
    name_es:
      'Relatore especial sobre la situación de los derechos humanos en Myanmar.',
    short_name_en: 'SR Myanmar',
    short_name_es: 'RE Myanmar'
  },
  {
    id: '61',
    name_en:
      'Special Rapporteur on the situation of human rights in the Democratic People’s Republic of Korea',
    name_es:
      'Relatore especial sobre la situación de los derechos humanos en la República Popular Democrática de Corea',
    short_name_en: 'SR North Korea',
    short_name_es: 'RE Corea del Norte'
  },
  {
    id: '62',
    name_en:
      'Special Rapporteur on the situation of human rights in the Islamic Republic of Iran',
    name_es:
      'Relatore especial sobre la situación de los derechos humanos en la República Islámica de Irán',
    short_name_en: 'SR Iran',
    short_name_es: 'RE Irán'
  },
  {
    id: '63',
    name_en:
      'Special Rapporteur on the situation of human rights in the Palestinian territory occupied since 1967',
    name_es:
      'Relatore especial sobre la situación de los derechos humanos en el territorio palestino ocupado desde 1967',
    short_name_en: 'SR Iran',
    short_name_es: 'RE Irán'
  },
  {
    id: '64',
    name_en:
      'Special Rapporteur on the situation of human rights in the Syrian Arab Republic',
    name_es:
      'Relatore especial sobre la situación de los derechos humanos en la República Árabe Siria',
    short_name_en: 'SR Syria',
    short_name_es: 'RE Siria'
  },
  {
    id: '65',
    name_en: 'Special Rapporteur on toxics and human rights',
    name_es: 'Relator Especial sobre sustancias tóxicas y derechos humanos',
    short_name_en: 'SR Toxics',
    short_name_es: 'RE Toxicos'
  },
  {
    id: '66',
    name_en:
      'Special Rapporteur on the rights to freedom of peaceful assembly and of association',
    name_es:
      'Relatore especial sobre los derechos a la libertad de reunión pacífica y de asociación',
    short_name_en: 'SR Assembly and Assoc.',
    short_name_es: 'RE Reunión y Asoc.'
  }
]
export const cDependentTerritories = {
  'UN-036': ['DT-20', 'DT-23'],
  'UN-047': ['DT-12', 'DT-16'],
  'UN-062': [
    'DT-13',
    'DT-14',
    'DT-17',
    'DT-24',
    'DT-25',
    'DT-27',
    'DT-32',
    'DT-33',
    'DT-35',
    'DT-36',
    'DT-42'
  ],
  'UN-123': ['DT-3', 'DT-5', 'DT-10', 'DT-37'],
  'UN-124': ['DT-9', 'DT-28', 'DT-39'],
  'UN-184': [
    'DT-2',
    'DT-4',
    'DT-6',
    'DT-7',
    'DT-8',
    'DT-11',
    'DT-15',
    'DT-19',
    'DT-21',
    'DT-26',
    'DT-30',
    'DT-34',
    'DT-38',
    'DT-40',
    'DT-22'
  ],
  'UN-185': ['DT-1', 'DT-18', 'DT-29', 'DT-31', 'DT-41']
}

export const cAreaCite = {
  'A1-1': citeA11,
  'A1-14': citeA114
}

export const cJurProfileSpanishAreasNames = {
  1: 'La criminalización de los actos sexuales consensuales entre personas adultas del mismo sexo',
  3: 'Las restricciones a la libertad de expresión en temas de diversidad',
  4: 'Las restricciones a la libertad de asociación para organizaciones que trabajen en temas de diversidad',
  5: 'La protección constitucional contra la discriminación',
  6: 'La protección contra la discriminación en la provisión de bienes y servicios',
  7: 'La protección contra la discriminación en salud',
  8: 'La protección contra la discriminación en la educación',
  9: 'La protección contra el acoso u hostigamiento escolar (bullying)',
  10: 'La protección contra la discriminación en el empleo',
  11: 'La protección contra la discriminación en la vivienda',
  12: 'Las leyes sobre delitos de odio',
  13: 'La prohibición de incitación a la violencia, odio o discriminación',
  14: 'La prohibición de las mal llamadas ”terapias de conversión”'
}

export const cJurProfileSpanishCharacteristics = {
  so: 'la orientación sexual',
  gi: 'la identidad de género',
  ge: 'la expresión de género',
  sc: 'las características sexuales'
}
