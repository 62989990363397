import React from "react";
import { useTranslation } from "react-i18next";
import MainSpinner from "./MainSpinner";

const LoadingBar = React.forwardRef(({ text, background = '' }, loading) => {
  const { t, i18n } = useTranslation();
  // loadingBar's container needs to be positioned 'relative'
  // I moved the text in the inner component
  const styles = {
    container: {
      background: background,
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
    }
  }

  return (
    <div id="loading" style={styles.container} ref={loading} className="loadingBar">
      <MainSpinner text={text || t("Loading") + "..."} />
    </div>
  );
});

export default LoadingBar;
