import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AreaSelectModal from '../../components/AreaSelectModal/AreaSelectModal'

const AddAreaButton = ({ filters, setFilters }) => {
  const [modalShow, setModalShow] = useState(false)
  const [areaSelected, setAreaSelected] = useState(false)

  const { t } = useTranslation()

  const handleClose = () => setModalShow(false)
  const handleShow = () => setModalShow(true)

  useEffect(() => {
    let obj = {}
 
    if (filters && filters.areas_ids) {
      setAreaSelected({ a1: false, subareas: { a1: filters.subareas.a1 } })
    }
  }, [filters])

  return (
    <>
      <button className="ast__addArea-btn" onClick={() => handleShow()}>
        {t('Add Legal Category')}
      </button>
      <AreaSelectModal
        showModal={modalShow}
        handleClose={handleClose}
        areaSelected={areaSelected}
        filters={filters}
        setAreaSelected={setAreaSelected}
        setFilters={setFilters}
        compare={true}
      />
    </>
  )
}

export default AddAreaButton
