import React, { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'
import {
  cArea2MapReferences,
  cArea2MapReferencesAll,
  cArea2Treaties
} from '../../config/constants'
import useGqlEntriesRatification from '../../gql/useGqlEntriesRatification'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import useGqlTextComponents from '../../gql/useGqlTextComponents'
import MainLayout from '../../layouts/MainLayout'

import '../../pages/Area.css'

import AreaContent from '../components/Areas/AreaContent'
import LineChart2 from '../components/Areas/A2/LineChart'
import MapContainer from '../components/Map/MapContainer'
import microstates from '../components/Map/microstates.json'
import StyledMapTooltips from '../components/StyledMapToolTips'
import TableContainer from '../components/Table/TableContainer'
import MainSpinner from '../components/ui/MainSpinner'
import { a2columns, citeA2 } from '../config/a2columns'

export default function Area2() {
  const htmlReferences = useRef()
  const [references, setReferences] = useState([])
  const [drillDown, setDrillDown] = useState('0')
  const [tableEntries, setTableEntries] = useState([])
  const [currentTB, setCurrentTB] = useState(null)
  const { textComponents } = useGqlTextComponents('A2')
  const { entriesRatification, loading, error } = useGqlEntriesRatification()
  const {
    jurisdictions,
    loading: loadingJur,
    error: errorJur,
    jurisdictionsById
  } = useGqlJurisdictions()
  const { t, i18n } = useTranslation()

  const tooltip = e => {
    return ReactDOMServer.renderToStaticMarkup()
  }

  const findReferenceCurrentTB = entries => {
    const currentTBEntry = entries.find(
      e => parseInt(e.treaty_body.id) === currentTB
    )
    let color = cArea2MapReferences().find(ml => ml.id === 1).color
    if (currentTBEntry) {
      if (currentTBEntry.accepted_complaints)
        color = cArea2MapReferences().find(ml => ml.id === 4).color
      else if (currentTBEntry.ras)
        color = cArea2MapReferences().find(ml => ml.id === 3).color
      else if (currentTBEntry.sign)
        color = cArea2MapReferences().find(ml => ml.id === 2).color
    }

    return {
      ...currentTBEntry,
      currentTB: cArea2Treaties().find(tb => tb.id === currentTB),
      color
    }
  }

  useEffect(() => {
    if (!loading && !error && jurisdictions && entriesRatification) {
      let referencesTmp = []
      let entriesByJur = []
      for (let j of jurisdictions) {
        let entry = entriesRatification.filter(
          e => e.motherEntry.jurisdiction.id === j.id
        )

        if (entry && entry.length) {
          referencesTmp.push(entry[0])
          entriesByJur[j.id] = entry
          console.log(entry)
        }
      }

      setReferences(
        referencesTmp.map(e => ({
          id: e.motherEntry.jurisdiction.a2_code,
          regions: e.motherEntry.jurisdiction.regions,
          microstate: microstates.find(
            ms => ms.A2 === e.motherEntry.jurisdiction.a2_code
          ),
          reference: currentTB
            ? findReferenceCurrentTB(
                entriesByJur[e.motherEntry.jurisdiction.id]
              ).color
            : cArea2MapReferencesAll()[
                Math.ceil(
                  entriesByJur[e.motherEntry.jurisdiction.id].filter(e => e.ras)
                    .length / 6
                ) - 1
              ].color,
          reference_text: 'test',
          tooltip: ReactDOMServer.renderToStaticMarkup(
            <StyledMapTooltips
              entry={e}
              title={t('International Human Rights Treaties')}
              area={currentTB ? 'A2X' : 'A2'}
              positive={false}
              extraProps={{
                name: cArea2MapReferences().find(ml => ml.id === parseInt(1))
                  .name,
                bgColor: cArea2MapReferences().find(ml => ml.id === parseInt(1))
                  .color,
                treatyBodies: cArea2Treaties().filter(t => !t.op),
                entriesByJur: entriesByJur[e.motherEntry.jurisdiction.id],
                ...(currentTB &&
                  findReferenceCurrentTB(
                    entriesByJur[e.motherEntry.jurisdiction.id]
                  ))
              }}
            />
          ),
          entry: e
        }))
      )

      setTableEntries(entriesRatification)
    }
  }, [jurisdictions, entriesRatification, error, loading, currentTB])

  const areaInfo = {
    title: `${t('AREA')} 2`,
    subTitle: `${t('International Human Rights Treaties')}`,
    subSubTitle: `${t('International Human Rights Treaties')}`,
    areaName: 'area-2'
  }

  return (
    <MainLayout section="areas">
      <div className="area__page area2__page">
        <section className="selectRegion__section">
          <MapContainer
            references={references}
            htmlReferences={htmlReferences}
            jurisdictionsById={jurisdictionsById}
            jurisdictions={jurisdictions}
            mapLegendItems={
              currentTB
                ? cArea2Treaties().find(t => t.id === currentTB).noComplaints
                  ? cArea2MapReferences().filter(r => r.id !== 4)
                  : cArea2MapReferences()
                : cArea2MapReferencesAll()
            }
            menuVariation="ratifications"
            title={areaInfo.title}
            subTitle={areaInfo.subTitle}
            currentArea="A2"
            subSubTitle={
              areaInfo.subSubTitle +
              (currentTB
                ? ' - ' + cArea2Treaties().find(t => t.id === currentTB).name
                : '')
            }
            areaName={areaInfo.areaName}
            extraProps={{
              currentTB,
              setCurrentTB,
              treatyBodies: cArea2Treaties()
            }}
            modalContent={textComponents.length && textComponents[0].map}
          />
        </section>

        <div className="content__container">

          <AreaContent areaIntro={ 'A2'} areaLatest={ 'A2' } />

          <div className="mainCTA__charts">
            <section className="mainCTA__section">
              <div className="mainGraphic__section">
                <h5>{t('International Human Rights Treaties')}</h5>
                <h4>{t('Timeline')}</h4>
                {references && references.length > 0 ? (
                  <LineChart2 references={entriesRatification} />
                ) : (
                  <>
                    <MainSpinner text={t('Loading chart...')} />
                  </>
                )}
              </div>
            </section>
          </div>
        </div>

        <section className="table__section">
          <div className="content__container">
            {entriesRatification && entriesRatification.length > 0 && (
              <TableContainer
                entries={tableEntries}
                setTableEntries={setTableEntries}
                t={t}
                columns={a2columns()}
                citeEntry={citeA2}
                showSubjurSwitch={false}
                trMainClass={'a13Tr'}
                title={t('Area') + '2'}
                subtitle={t('International Human Rights Treaties')}
                paginate={true}
                initialSortBy={[
                  {
                    id: 'motherEntry.jurisdiction.name',
                    desc: false
                  }
                ]}
                extraSelectHeaderClass={
                  i18n.language === 'es' ? 'area2HeaderAdjust' : ''
                }
                extraSortCellHeaderClass={
                  i18n.language === 'es' ? 'area2HeaderAdjust' : ''
                }
              />
            )}
          </div>
        </section>
      </div>
    </MainLayout>
  )
}
