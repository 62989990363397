import React, {useRef, useState} from "react";

import './SelectYearFilter.css';
import {useOnClickOutside} from "../../../hooks/clickOutside";
import ChevronIcon from "../icons/ChevronIcon";

const  SelectYearFilter = () => {
  const [yearSelected, setYearSelected] = useState('2018');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const refSYF = useRef();

  useOnClickOutside(refSYF, () =>  setDropdownOpen(false));


  const yearsAvailable = ['2018', '2019', '2020', '2021', '2022'];

  return <div className={`yearDropdown__container ${ dropdownOpen ? '' : 'overflow-hidden'}`} onClick={() => setDropdownOpen(!dropdownOpen)} ref={refSYF}>
    <p>{yearSelected} <ChevronIcon size={24} color={'#707070'} /></p>
    <div className={`yearDropdown__list ${ dropdownOpen ? '' : 'hidden'}`} >
      {yearsAvailable.map((item, index) => <p onClick={() => setYearSelected(item)} className={index === 0 ? 'yearSelected' : ''}>{item}</p>)}
    </div>
  </div>
}

export default SelectYearFilter;