var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [116.3686, 34.6409],
            [116.5868, 34.511],
            [116.9561, 34.3938],
            [117.0193, 34.1776],
            [117.1866, 34.0702],
            [117.3471, 34.0911],
            [117.6631, 33.9955],
            [117.7529, 33.887],
            [117.7258, 33.729],
            [118.1056, 33.7655],
            [118.1073, 33.4779],
            [117.9319, 33.2268],
            [118.0343, 33.142],
            [118.201, 33.2024],
            [118.2277, 32.9275],
            [118.2943, 32.7807],
            [118.582, 32.725],
            [118.7476, 32.7444],
            [118.8471, 32.9605],
            [119.0123, 32.9614],
            [119.2043, 32.7263],
            [119.1423, 32.4945],
            [118.7115, 32.6117],
            [118.657, 32.2202],
            [118.5015, 32.1962],
            [118.3877, 32.0832],
            [118.3641, 31.9372],
            [118.6537, 31.6461],
            [118.8595, 31.6243],
            [118.8666, 31.4292],
            [118.6977, 31.3047],
            [118.7836, 31.2325],
            [119.0847, 31.2359],
            [119.1751, 31.305],
            [119.3856, 31.1818],
            [119.6322, 31.1393],
            [119.5702, 30.8389],
            [119.4393, 30.6527],
            [119.2635, 30.5823],
            [119.346, 30.4201],
            [119.2251, 30.2955],
            [118.9528, 30.3641],
            [118.8655, 30.1084],
            [118.8915, 29.9513],
            [118.7524, 29.8459],
            [118.7367, 29.7351],
            [118.547, 29.6149],
            [118.4816, 29.5111],
            [118.1895, 29.3953],
            [118.0089, 29.5798],
            [117.7024, 29.5516],
            [117.5844, 29.587],
            [117.4496, 29.7515],
            [117.2483, 29.9114],
            [117.0679, 29.8346],
            [117.1076, 29.7144],
            [116.8359, 29.5735],
            [116.6471, 29.6399],
            [116.8952, 29.9522],
            [116.7785, 30.0376],
            [116.5857, 30.0563],
            [116.533, 29.9004],
            [116.2564, 29.7846],
            [116.1296, 29.8248],
            [116.0687, 29.9657],
            [116.0601, 30.207],
            [115.8791, 30.3851],
            [115.9087, 30.5205],
            [115.755, 30.6868],
            [115.8625, 30.8684],
            [116.0693, 30.9665],
            [115.8685, 31.1489],
            [115.6955, 31.2032],
            [115.5735, 31.1493],
            [115.3643, 31.405],
            [115.3707, 31.5016],
            [115.4918, 31.6756],
            [115.6706, 31.7817],
            [115.9057, 31.7972],
            [115.9308, 32.1659],
            [115.8751, 32.4904],
            [115.6854, 32.497],
            [115.5584, 32.4202],
            [115.4015, 32.5762],
            [115.1915, 32.5905],
            [115.1913, 32.8568],
            [114.934, 32.9364],
            [114.8899, 33.086],
            [115.14, 33.0864],
            [115.2857, 33.136],
            [115.3579, 33.3288],
            [115.3085, 33.3814],
            [115.4075, 33.5592],
            [115.632, 33.5869],
            [115.5418, 33.8826],
            [115.5943, 34.0339],
            [115.8024, 34.0735],
            [115.9623, 34.0036],
            [115.9786, 33.9077],
            [116.1556, 33.7095],
            [116.4303, 33.8056],
            [116.6427, 33.975],
            [116.5221, 34.1171],
            [116.4977, 34.2978],
            [116.2082, 34.3834],
            [116.1859, 34.5795],
            [116.3686, 34.6409]
          ]
        ]
      },
      properties: {
        name: 'Anhui',
        id: 'CN-AH',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-AH'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [116.7994, 39.61],
            [116.5995, 39.6292],
            [116.3363, 39.452],
            [116.2313, 39.5557],
            [115.9815, 39.5909],
            [115.7501, 39.5117],
            [115.6817, 39.5999],
            [115.476, 39.6536],
            [115.5083, 39.9119],
            [115.4408, 40.0262],
            [115.6063, 40.1188],
            [115.835, 40.1398],
            [115.9552, 40.2618],
            [115.7662, 40.4445],
            [115.7523, 40.5414],
            [115.9046, 40.617],
            [116.0973, 40.6148],
            [116.2322, 40.7868],
            [116.4544, 40.7901],
            [116.4443, 40.9755],
            [116.6669, 40.9767],
            [116.9942, 40.693],
            [117.3061, 40.6539],
            [117.1951, 40.4969],
            [117.2173, 40.3749],
            [117.3797, 40.2268],
            [117.1939, 40.0828],
            [116.7777, 40.028],
            [116.7793, 39.903],
            [116.9143, 39.8509],
            [116.8943, 39.6963],
            [116.7994, 39.61]
          ]
        ]
      },
      properties: {
        name: 'Beijing',
        id: 'CN-BJ',
        TYPE: 'Municipality',
        CNTRY: 'China'
      },
      id: 'CN-BJ'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [108.5145, 32.212],
            [108.8836, 31.9967],
            [109.0299, 31.9669],
            [109.2708, 31.8042],
            [109.2845, 31.7188],
            [109.5767, 31.7332],
            [110.1382, 31.3956],
            [110.1905, 31.1507],
            [110.0757, 30.8041],
            [109.8936, 30.8916],
            [109.7735, 30.8526],
            [109.6424, 30.7188],
            [109.345, 30.5662],
            [109.1346, 30.535],
            [109.1123, 30.6498],
            [108.9559, 30.6227],
            [108.8196, 30.4982],
            [108.6871, 30.589],
            [108.3969, 30.3905],
            [108.5694, 30.2488],
            [108.5166, 29.8705],
            [108.675, 29.6937],
            [108.8993, 29.5969],
            [108.8594, 29.4732],
            [108.9713, 29.3308],
            [109.2286, 29.1224],
            [109.3157, 29.0473],
            [109.232, 28.8789],
            [109.2703, 28.5035],
            [108.9908, 28.1642],
            [108.7562, 28.2008],
            [108.7715, 28.4359],
            [108.617, 28.3308],
            [108.5695, 28.5364],
            [108.3372, 28.6774],
            [108.3831, 28.7922],
            [108.312, 28.971],
            [108.0635, 29.0853],
            [107.843, 28.9623],
            [107.8001, 29.1391],
            [107.4074, 29.1852],
            [107.3646, 29.0104],
            [107.3845, 28.857],
            [107.2347, 28.773],
            [107.124, 28.8911],
            [106.8943, 28.8172],
            [106.8202, 28.5948],
            [106.5183, 28.6754],
            [106.3735, 28.5305],
            [106.3296, 28.5575],
            [106.2133, 28.8964],
            [105.8999, 28.9026],
            [105.7999, 28.9412],
            [105.7163, 29.1037],
            [105.6916, 29.2892],
            [105.4536, 29.2916],
            [105.4311, 29.4067],
            [105.2877, 29.5411],
            [105.4118, 29.683],
            [105.5621, 29.7337],
            [105.7246, 29.8563],
            [105.6878, 30.0427],
            [105.5347, 30.174],
            [105.7256, 30.257],
            [105.8242, 30.4346],
            [106.1394, 30.32],
            [106.2355, 30.1874],
            [106.5605, 30.3069],
            [106.7459, 30.0302],
            [107.0164, 30.0375],
            [107.2276, 30.2274],
            [107.38, 30.4967],
            [107.5058, 30.6402],
            [107.4199, 30.7448],
            [107.5074, 30.8417],
            [107.869, 30.8088],
            [107.9414, 30.9829],
            [108.1785, 31.3348],
            [108.5272, 31.7628],
            [108.261, 31.9713],
            [108.4439, 32.0705],
            [108.5145, 32.212]
          ]
        ]
      },
      properties: {
        name: 'Chongqing',
        id: 'CN-CQ',
        TYPE: 'Municipality',
        CNTRY: 'China'
      },
      id: 'CN-CQ'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [117.186, 23.6203],
            [117.0424, 23.711],
            [116.9199, 24.088],
            [116.9068, 24.365],
            [116.7518, 24.5459],
            [116.7931, 24.6779],
            [116.5944, 24.6497],
            [116.3708, 24.8017],
            [116.1922, 24.8651],
            [116.0665, 24.8527],
            [115.8772, 24.9358],
            [115.8515, 25.2131],
            [116.0071, 25.3253],
            [115.9876, 25.4639],
            [116.0508, 25.6905],
            [116.1744, 25.7774],
            [116.1257, 25.8616],
            [116.3613, 25.968],
            [116.4829, 26.114],
            [116.3945, 26.2717],
            [116.6322, 26.4713],
            [116.5351, 26.5685],
            [116.544, 26.8436],
            [116.6902, 26.9901],
            [117.0421, 27.1016],
            [117.1624, 27.2772],
            [117.0122, 27.6537],
            [117.1981, 27.7158],
            [117.5175, 27.992],
            [117.7274, 27.8067],
            [117.8519, 27.9503],
            [118.1207, 28.0588],
            [118.3419, 28.1003],
            [118.4243, 28.2922],
            [118.7046, 28.3125],
            [118.7924, 28.1233],
            [118.7119, 28.0564],
            [118.8914, 27.7174],
            [118.9007, 27.4676],
            [119.0063, 27.5],
            [119.2397, 27.4246],
            [119.4893, 27.6603],
            [119.625, 27.5967],
            [119.686, 27.4333],
            [119.8712, 27.3058],
            [120, 27.3827],
            [120.2436, 27.4347],
            [120.3405, 27.4004],
            [120.4001, 27.2447],
            [120.4307, 27.1354],
            [120.2604, 27.1132],
            [120.2288, 26.9143],
            [120.1184, 26.9332],
            [120.0443, 26.8269],
            [120.1554, 26.7273],
            [119.9552, 26.6293],
            [119.8356, 26.7092],
            [119.6585, 26.724],
            [119.8721, 26.3749],
            [119.5105, 26.0698],
            [119.7137, 26.0043],
            [119.6238, 25.8982],
            [119.5765, 25.4585],
            [119.3476, 25.5407],
            [119.1456, 25.3923],
            [119.3604, 25.2685],
            [119.2845, 25.1867],
            [118.9762, 25.2262],
            [118.8446, 25.0865],
            [118.9802, 24.9838],
            [118.7412, 24.8501],
            [118.7812, 24.7724],
            [118.6835, 24.6079],
            [118.4518, 24.6457],
            [118.1935, 24.5276],
            [118.1374, 24.2804],
            [117.9449, 24.1642],
            [117.906, 24.0368],
            [117.6935, 24.0157],
            [117.6362, 23.8941],
            [117.2679, 23.7302],
            [117.186, 23.6203]
          ]
        ]
      },
      properties: {
        name: 'Fujian',
        id: 'CN-FJ',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-FJ'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [114.2268, 22.5452],
            [114.042, 22.5057],
            [113.8871, 22.4573],
            [113.7552, 22.7334],
            [113.5453, 22.4281],
            [113.6079, 22.3187],
            [113.4998, 22.0712],
            [113.3704, 21.9902],
            [113.1884, 21.9544],
            [113.1063, 22.0712],
            [112.9243, 21.8549],
            [112.791, 21.9476],
            [112.6107, 21.7659],
            [112.4234, 21.8271],
            [112.4046, 21.7365],
            [112.2646, 21.7018],
            [112.1204, 21.8001],
            [111.7825, 21.6579],
            [111.5965, 21.5335],
            [111.447, 21.5618],
            [111.2873, 21.4918],
            [111.0812, 21.4645],
            [110.8871, 21.3849],
            [110.7724, 21.3971],
            [110.6315, 21.2279],
            [110.4159, 21.193],
            [110.4319, 21.0518],
            [110.2024, 20.9441],
            [110.1954, 20.8604],
            [110.3865, 20.8198],
            [110.5319, 20.4772],
            [110.4379, 20.3285],
            [110.3049, 20.2582],
            [110.1301, 20.2426],
            [109.987, 20.2943],
            [109.9013, 20.4915],
            [109.7423, 20.6329],
            [109.7701, 20.707],
            [109.6654, 20.9004],
            [109.7715, 21.2193],
            [109.8012, 21.4642],
            [109.7493, 21.5716],
            [109.899, 21.6576],
            [109.9793, 21.8799],
            [110.2426, 21.8777],
            [110.351, 21.988],
            [110.3742, 22.1681],
            [110.5045, 22.148],
            [110.7243, 22.2964],
            [110.7076, 22.445],
            [110.765, 22.5837],
            [111.0515, 22.654],
            [111.2121, 22.7502],
            [111.3518, 22.8926],
            [111.4248, 23.0383],
            [111.3512, 23.2877],
            [111.4828, 23.6321],
            [111.611, 23.6455],
            [111.6416, 23.8203],
            [111.7999, 23.8152],
            [111.848, 23.9499],
            [111.8657, 24.2115],
            [112.0551, 24.3572],
            [111.921, 24.6313],
            [112.0159, 24.74],
            [112.1671, 24.9156],
            [112.1449, 25.0196],
            [112.254, 25.1605],
            [112.6509, 25.1316],
            [112.698, 25.0105],
            [112.8553, 24.8968],
            [113.0009, 24.9303],
            [112.9603, 25.1571],
            [112.8558, 25.3333],
            [113.0105, 25.3466],
            [113.2273, 25.5152],
            [113.4383, 25.3618],
            [113.5738, 25.3134],
            [113.9592, 25.4574],
            [114.0343, 25.3632],
            [114.23, 25.2906],
            [114.3819, 25.3176],
            [114.5297, 25.4185],
            [114.7372, 25.2428],
            [114.6437, 25.0775],
            [114.3903, 24.9521],
            [114.3264, 24.7481],
            [114.1695, 24.6731],
            [114.3522, 24.5879],
            [114.4262, 24.4871],
            [114.741, 24.6137],
            [114.8649, 24.5605],
            [114.9392, 24.6502],
            [115.108, 24.6634],
            [115.2794, 24.7544],
            [115.4735, 24.7592],
            [115.567, 24.6224],
            [115.7132, 24.5405],
            [115.8316, 24.5654],
            [115.7517, 24.7464],
            [115.8772, 24.9358],
            [116.0665, 24.8527],
            [116.1922, 24.8651],
            [116.3708, 24.8017],
            [116.5944, 24.6497],
            [116.7931, 24.6779],
            [116.7518, 24.5459],
            [116.9068, 24.365],
            [116.9199, 24.088],
            [117.0424, 23.711],
            [117.186, 23.6203],
            [117.064, 23.6482],
            [116.8846, 23.5554],
            [116.8565, 23.4329],
            [116.7502, 23.3433],
            [116.6494, 23.166],
            [116.5316, 23.1221],
            [116.4962, 22.9377],
            [116.2848, 22.9573],
            [116.1368, 22.838],
            [115.9927, 22.8345],
            [115.8151, 22.7407],
            [115.7574, 22.8455],
            [115.636, 22.8708],
            [115.5163, 22.7557],
            [115.4205, 22.7869],
            [115.0529, 22.7888],
            [114.8824, 22.593],
            [114.7399, 22.6076],
            [114.694, 22.7935],
            [114.5009, 22.6479],
            [114.2268, 22.5452]
          ]
        ]
      },
      properties: {
        name: 'Guangdong',
        id: 'CN-GD',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-GD'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [97.1847, 42.7718],
            [97.8399, 41.6589],
            [97.612, 41.4584],
            [97.9731, 41.0961],
            [98.3329, 40.9144],
            [98.3694, 40.8368],
            [98.256, 40.5251],
            [98.6479, 40.542],
            [98.8689, 40.753],
            [99.1794, 40.8647],
            [99.5683, 40.8488],
            [99.6776, 40.9328],
            [100.0941, 40.8832],
            [100.2268, 40.7219],
            [100.2399, 40.6007],
            [100.0199, 40.397],
            [99.9069, 40.2121],
            [99.6194, 40.064],
            [99.4532, 39.8761],
            [99.7649, 39.886],
            [100.013, 39.74],
            [100.2506, 39.6875],
            [100.3203, 39.5183],
            [100.4996, 39.3983],
            [100.8479, 39.3971],
            [100.8617, 39.1183],
            [101.0159, 38.9572],
            [101.176, 38.9548],
            [101.3275, 38.7828],
            [101.6169, 38.6619],
            [101.7769, 38.6648],
            [102.0805, 38.8969],
            [101.8152, 39.1021],
            [102.2008, 39.1706],
            [102.4558, 39.2541],
            [102.6075, 39.1817],
            [103.0019, 39.1022],
            [103.3614, 39.3461],
            [103.8375, 39.4588],
            [104.0911, 39.4178],
            [104.0525, 39.2974],
            [104.2057, 39.0929],
            [104.1742, 38.9415],
            [104.0319, 38.8912],
            [103.8763, 38.6451],
            [103.4123, 38.4075],
            [103.5445, 38.1553],
            [103.3623, 38.0907],
            [103.4102, 37.8475],
            [103.669, 37.7858],
            [103.8917, 37.5916],
            [104.1781, 37.4081],
            [104.2791, 37.4283],
            [104.6639, 37.4084],
            [104.5935, 37.2738],
            [104.6728, 37.1939],
            [104.8483, 37.2194],
            [104.9721, 37.0464],
            [105.1684, 36.9876],
            [105.1871, 36.8888],
            [105.3302, 36.7923],
            [105.2009, 36.7143],
            [105.4717, 36.2936],
            [105.5047, 36.1476],
            [105.3244, 36.0162],
            [105.33, 35.892],
            [105.4891, 35.7249],
            [105.6428, 35.7435],
            [105.7916, 35.5697],
            [106.0191, 35.5267],
            [106.1749, 35.4363],
            [106.3619, 35.2354],
            [106.4986, 35.3591],
            [106.4391, 35.6941],
            [106.5409, 35.7445],
            [106.813, 35.714],
            [106.9233, 35.8073],
            [106.9105, 36.1201],
            [106.824, 36.2307],
            [106.5062, 36.2947],
            [106.5119, 36.4777],
            [106.4464, 36.5619],
            [106.5161, 36.7282],
            [106.6526, 36.8181],
            [106.5757, 36.9416],
            [106.7812, 37.1556],
            [107.0119, 37.1108],
            [107.1598, 37.1489],
            [107.2714, 37.0823],
            [107.3044, 36.916],
            [107.4803, 36.9062],
            [107.5373, 36.8275],
            [107.8954, 36.7601],
            [107.947, 36.656],
            [108.1473, 36.561],
            [108.3435, 36.556],
            [108.709, 36.3695],
            [108.6448, 36.2621],
            [108.7076, 36.1383],
            [108.6477, 35.9484],
            [108.497, 35.8853],
            [108.5214, 35.6462],
            [108.6181, 35.5523],
            [108.6042, 35.3423],
            [108.4863, 35.2855],
            [108.1721, 35.3104],
            [107.9569, 35.243],
            [107.7824, 35.3032],
            [107.6486, 35.2447],
            [107.8017, 34.9569],
            [107.5277, 34.9124],
            [107.2907, 34.9373],
            [107.1963, 34.8826],
            [106.9161, 35.0952],
            [106.5457, 35.0877],
            [106.489, 34.942],
            [106.5662, 34.8163],
            [106.3091, 34.581],
            [106.5675, 34.4903],
            [106.714, 34.3745],
            [106.5886, 34.1425],
            [106.4977, 34.1064],
            [106.4153, 33.8956],
            [106.4893, 33.8477],
            [106.427, 33.6206],
            [106.183, 33.5481],
            [105.9554, 33.6101],
            [105.8401, 33.4953],
            [105.7922, 33.2619],
            [105.9135, 33.2399],
            [105.9137, 32.996],
            [105.6347, 32.8815],
            [105.4954, 32.9107],
            [105.3776, 32.8755],
            [105.4535, 32.7393],
            [105.1137, 32.594],
            [105.0396, 32.647],
            [104.633, 32.6643],
            [104.3002, 32.8314],
            [104.2854, 32.9417],
            [104.375, 33.111],
            [104.2643, 33.3935],
            [104.0436, 33.6879],
            [103.7662, 33.6603],
            [103.3472, 33.745],
            [103.1501, 33.8118],
            [103.107, 34.1784],
            [102.9476, 34.2936],
            [102.7307, 34.2667],
            [102.4259, 34.0904],
            [102.3858, 33.9735],
            [102.1849, 33.9523],
            [102.335, 33.6202],
            [102.4771, 33.4644],
            [102.2605, 33.4197],
            [102.1044, 33.2687],
            [101.8728, 33.1105],
            [101.7694, 33.2566],
            [101.9389, 33.4457],
            [101.7742, 33.5481],
            [101.6298, 33.501],
            [101.5893, 33.6726],
            [101.2406, 33.6823],
            [101.1532, 33.8412],
            [100.9884, 33.9034],
            [100.7664, 34.1802],
            [100.8974, 34.3797],
            [101.2588, 34.3],
            [101.3206, 34.2487],
            [101.6172, 34.1841],
            [101.7377, 34.0777],
            [101.9486, 34.1077],
            [102.064, 34.2874],
            [102.1813, 34.359],
            [102.1525, 34.5097],
            [102, 34.5437],
            [101.7187, 34.7043],
            [101.9144, 34.743],
            [101.9164, 34.8804],
            [102.1284, 35.0164],
            [102.2791, 35.0586],
            [102.3839, 35.2382],
            [102.2823, 35.3818],
            [102.4276, 35.4305],
            [102.5351, 35.5654],
            [102.7204, 35.5279],
            [102.7566, 35.6199],
            [102.6884, 35.8025],
            [102.7833, 35.8624],
            [102.9482, 35.8366],
            [102.9327, 36.1077],
            [103.0222, 36.257],
            [102.8331, 36.3436],
            [102.719, 36.6153],
            [102.7237, 36.7666],
            [102.4546, 36.9751],
            [102.5897, 37.1668],
            [102.2074, 37.4233],
            [102.0124, 37.4747],
            [102.0752, 37.5738],
            [101.8661, 37.6723],
            [101.7547, 37.5284],
            [101.0965, 37.9439],
            [100.6304, 38.103],
            [100.5936, 38.2468],
            [100.3312, 38.3193],
            [100.2175, 38.4562],
            [100.0812, 38.4097],
            [100.0821, 38.2805],
            [99.6777, 38.4364],
            [99.4464, 38.605],
            [99.3606, 38.7176],
            [98.8181, 39.0839],
            [98.5812, 38.933],
            [98.3831, 39.0286],
            [98.0875, 38.7961],
            [98.0153, 38.8579],
            [97.3465, 39.1666],
            [97.0258, 39.2026],
            [96.9578, 38.8791],
            [96.9922, 38.5905],
            [96.9597, 38.3383],
            [96.7614, 38.1944],
            [96.5475, 38.1005],
            [96.3455, 38.1005],
            [96.1196, 38.2131],
            [95.573, 38.1944],
            [95.3115, 38.2975],
            [94.8837, 38.3068],
            [94.551, 38.2788],
            [94.2895, 38.6152],
            [93.8498, 38.5686],
            [93.6716, 38.7176],
            [93.2794, 38.6711],
            [93.113, 38.8293],
            [92.9229, 38.9036],
            [92.4049, 39.0413],
            [92.5001, 39.1094],
            [92.9506, 39.171],
            [92.9437, 39.4562],
            [92.8751, 39.5485],
            [92.7641, 39.8798],
            [92.918, 40.1037],
            [92.9138, 40.4983],
            [93.354, 40.4725],
            [93.6392, 40.6137],
            [93.8068, 40.8766],
            [94.0027, 41.0895],
            [94.4174, 41.3919],
            [94.6799, 41.6222],
            [95.0816, 41.7793],
            [95.5391, 41.8546],
            [95.6866, 41.8221],
            [95.9897, 41.8943],
            [96.0966, 42.5997],
            [96.3652, 42.7171],
            [96.6747, 42.7425],
            [96.9328, 42.7122],
            [97.1847, 42.7718]
          ]
        ]
      },
      properties: {
        name: 'Gansu',
        id: 'CN-GS',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-GS'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [109.478, 26.0327],
            [109.6363, 26.0489],
            [109.8223, 25.9199],
            [109.9028, 26.1484],
            [110.0813, 26.1712],
            [110.0633, 26.0464],
            [110.2406, 26.0254],
            [110.3723, 26.0975],
            [110.6078, 26.3373],
            [110.7565, 26.2524],
            [110.9925, 26.3237],
            [111.2818, 26.2547],
            [111.1969, 26.0078],
            [111.4347, 25.7724],
            [111.2994, 25.7159],
            [111.3339, 25.6063],
            [111.2913, 25.4444],
            [111.0937, 25.2851],
            [110.9424, 25.051],
            [110.9916, 24.9226],
            [111.263, 25.1457],
            [111.4313, 25.1011],
            [111.4741, 24.8004],
            [111.4325, 24.671],
            [111.5581, 24.6393],
            [111.7002, 24.7857],
            [112.0159, 24.74],
            [111.921, 24.6313],
            [112.0551, 24.3572],
            [111.8657, 24.2115],
            [111.848, 23.9499],
            [111.7999, 23.8152],
            [111.6416, 23.8203],
            [111.611, 23.6455],
            [111.4828, 23.6321],
            [111.3512, 23.2877],
            [111.4248, 23.0383],
            [111.3518, 22.8926],
            [111.2121, 22.7502],
            [111.0515, 22.654],
            [110.765, 22.5837],
            [110.7076, 22.445],
            [110.7243, 22.2964],
            [110.5045, 22.148],
            [110.3742, 22.1681],
            [110.351, 21.988],
            [110.2426, 21.8777],
            [109.9793, 21.8799],
            [109.899, 21.6576],
            [109.7493, 21.5716],
            [109.5772, 21.5731],
            [109.4502, 21.4601],
            [109.1571, 21.403],
            [109.1604, 21.5171],
            [109.0505, 21.6157],
            [108.5688, 21.6668],
            [108.4797, 21.5609],
            [108.3605, 21.6001],
            [107.9538, 21.5384],
            [107.8572, 21.654],
            [107.5259, 21.5892],
            [107.4664, 21.6646],
            [107.2324, 21.7065],
            [107.085, 21.8094],
            [107.0593, 21.9316],
            [106.8115, 21.9734],
            [106.713, 22.0959],
            [106.6918, 22.2767],
            [106.5586, 22.4483],
            [106.6081, 22.6012],
            [106.7334, 22.5905],
            [106.773, 22.8164],
            [106.5975, 22.9332],
            [106.2787, 22.8688],
            [106.1969, 22.9901],
            [106.0027, 22.9921],
            [105.8702, 22.9252],
            [105.7332, 23.0629],
            [105.5721, 23.0724],
            [105.5385, 23.1933],
            [105.5245, 23.2472],
            [105.8229, 23.5183],
            [105.9829, 23.4923],
            [106.1378, 23.5721],
            [106.1888, 23.8274],
            [106.0934, 24.0318],
            [105.9986, 24.132],
            [105.8381, 24.031],
            [105.4828, 24.0255],
            [105.2309, 24.1932],
            [105.0465, 24.4443],
            [104.941, 24.4113],
            [104.7585, 24.4628],
            [104.6118, 24.4121],
            [104.4807, 24.6548],
            [104.53, 24.7344],
            [104.7302, 24.6236],
            [104.8731, 24.7451],
            [105.033, 24.7979],
            [105.0721, 24.9195],
            [105.2081, 24.9985],
            [105.4237, 24.9363],
            [105.5066, 24.8101],
            [105.8076, 24.7063],
            [106.0522, 24.6969],
            [106.1725, 24.7708],
            [106.1478, 24.9648],
            [106.301, 24.9808],
            [106.5841, 25.0927],
            [106.6548, 25.174],
            [106.8887, 25.1899],
            [106.9854, 25.2442],
            [106.9595, 25.437],
            [107.2282, 25.5595],
            [107.4165, 25.3958],
            [107.4708, 25.2166],
            [107.6609, 25.3183],
            [107.6905, 25.2008],
            [107.8464, 25.1236],
            [108.1031, 25.2022],
            [108.1458, 25.4414],
            [108.3161, 25.5332],
            [108.4113, 25.4411],
            [108.6791, 25.5221],
            [108.9341, 25.5586],
            [109.0352, 25.5221],
            [109.0687, 25.7368],
            [109.2333, 25.725],
            [109.478, 26.0327]
          ]
        ]
      },
      properties: {
        name: 'Guangxi Zhuang',
        id: 'CN-GX',
        TYPE: 'Autonomous Region',
        CNTRY: 'China'
      },
      id: 'CN-GX'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [106.3735, 28.5305],
            [106.5183, 28.6754],
            [106.8202, 28.5948],
            [106.8943, 28.8172],
            [107.124, 28.8911],
            [107.2347, 28.773],
            [107.3845, 28.857],
            [107.3646, 29.0104],
            [107.4074, 29.1852],
            [107.8001, 29.1391],
            [107.843, 28.9623],
            [108.0635, 29.0853],
            [108.312, 28.971],
            [108.3831, 28.7922],
            [108.3372, 28.6774],
            [108.5695, 28.5364],
            [108.617, 28.3308],
            [108.7715, 28.4359],
            [108.7562, 28.2008],
            [108.9908, 28.1642],
            [109.2703, 28.5035],
            [109.2688, 28.3185],
            [109.3792, 28.2704],
            [109.2962, 28.0577],
            [109.341, 27.836],
            [109.4327, 27.7438],
            [109.4542, 27.5801],
            [109.2033, 27.4537],
            [109.0566, 27.2931],
            [108.9003, 27.2172],
            [109.0168, 27.0942],
            [109.1643, 27.0758],
            [109.3893, 27.1657],
            [109.5072, 27.0761],
            [109.5506, 26.9505],
            [109.3521, 26.6608],
            [109.404, 26.5403],
            [109.267, 26.3311],
            [109.4349, 26.2421],
            [109.478, 26.0327],
            [109.2333, 25.725],
            [109.0687, 25.7368],
            [109.0352, 25.5221],
            [108.9341, 25.5586],
            [108.6791, 25.5221],
            [108.4113, 25.4411],
            [108.3161, 25.5332],
            [108.1458, 25.4414],
            [108.1031, 25.2022],
            [107.8464, 25.1236],
            [107.6905, 25.2008],
            [107.6609, 25.3183],
            [107.4708, 25.2166],
            [107.4165, 25.3958],
            [107.2282, 25.5595],
            [106.9595, 25.437],
            [106.9854, 25.2442],
            [106.8887, 25.1899],
            [106.6548, 25.174],
            [106.5841, 25.0927],
            [106.301, 24.9808],
            [106.1478, 24.9648],
            [106.1725, 24.7708],
            [106.0522, 24.6969],
            [105.8076, 24.7063],
            [105.5066, 24.8101],
            [105.4237, 24.9363],
            [105.2081, 24.9985],
            [105.0721, 24.9195],
            [105.033, 24.7979],
            [104.8731, 24.7451],
            [104.7302, 24.6236],
            [104.53, 24.7344],
            [104.5448, 24.8269],
            [104.709, 24.9982],
            [104.74, 25.2179],
            [104.6391, 25.3643],
            [104.4306, 25.4827],
            [104.3249, 25.6069],
            [104.516, 26.0947],
            [104.5381, 26.2527],
            [104.6779, 26.3767],
            [104.4823, 26.5838],
            [104.1274, 26.6467],
            [104.0076, 26.5192],
            [103.8114, 26.5392],
            [103.6994, 26.8091],
            [103.7744, 26.9544],
            [103.6271, 27.1194],
            [103.8682, 27.3097],
            [103.9315, 27.4444],
            [104.2532, 27.3051],
            [104.358, 27.4692],
            [104.614, 27.3119],
            [104.8139, 27.3546],
            [104.8638, 27.2937],
            [105.258, 27.52],
            [105.3002, 27.7091],
            [105.4823, 27.7769],
            [105.631, 27.661],
            [105.7265, 27.7109],
            [106.191, 27.7582],
            [106.3319, 27.8259],
            [106.3173, 27.9763],
            [106.1459, 28.1676],
            [105.88, 28.1276],
            [105.8836, 28.2482],
            [105.7748, 28.3357],
            [105.6369, 28.317],
            [105.6179, 28.4881],
            [105.6902, 28.5951],
            [105.8902, 28.6022],
            [105.9691, 28.7551],
            [106.1078, 28.632],
            [106.3735, 28.5305]
          ]
        ]
      },
      properties: {
        name: 'Guizhou',
        id: 'CN-GZ',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-GZ'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [113.7244, 36.3601],
            [114.0117, 36.3535],
            [114.0618, 36.2727],
            [114.4023, 36.2244],
            [114.5924, 36.1201],
            [114.8893, 36.1365],
            [115.0057, 36.0724],
            [115.1205, 36.2114],
            [115.3235, 36.0867],
            [115.4728, 36.1694],
            [115.4404, 36.0132],
            [115.3277, 35.8197],
            [115.8032, 36.0101],
            [115.9177, 35.9697],
            [115.6511, 35.7391],
            [115.4933, 35.7285],
            [115.3447, 35.4886],
            [115.0193, 35.3744],
            [114.8411, 35.175],
            [114.9136, 34.9749],
            [115.1086, 35.011],
            [115.425, 34.7963],
            [115.5314, 34.579],
            [115.8246, 34.5641],
            [116.0955, 34.6067],
            [116.1859, 34.5795],
            [116.2082, 34.3834],
            [116.4977, 34.2978],
            [116.5221, 34.1171],
            [116.6427, 33.975],
            [116.4303, 33.8056],
            [116.1556, 33.7095],
            [115.9786, 33.9077],
            [115.9623, 34.0036],
            [115.8024, 34.0735],
            [115.5943, 34.0339],
            [115.5418, 33.8826],
            [115.632, 33.5869],
            [115.4075, 33.5592],
            [115.3085, 33.3814],
            [115.3579, 33.3288],
            [115.2857, 33.136],
            [115.14, 33.0864],
            [114.8899, 33.086],
            [114.934, 32.9364],
            [115.1913, 32.8568],
            [115.1915, 32.5905],
            [115.4015, 32.5762],
            [115.5584, 32.4202],
            [115.6854, 32.497],
            [115.8751, 32.4904],
            [115.9308, 32.1659],
            [115.9057, 31.7972],
            [115.6706, 31.7817],
            [115.4918, 31.6756],
            [115.3707, 31.5016],
            [115.3643, 31.405],
            [115.2153, 31.4248],
            [115.2298, 31.5558],
            [114.83, 31.4622],
            [114.6227, 31.5912],
            [114.571, 31.768],
            [114.2911, 31.7521],
            [114.124, 31.8422],
            [113.9714, 31.7595],
            [113.8417, 31.8491],
            [113.7347, 32.2503],
            [113.6199, 32.3605],
            [113.4172, 32.2752],
            [113.2064, 32.4297],
            [113.1342, 32.3799],
            [112.8646, 32.4002],
            [112.7576, 32.3476],
            [112.5437, 32.4044],
            [112.3179, 32.3283],
            [112.0528, 32.4743],
            [111.8841, 32.5056],
            [111.7152, 32.6066],
            [111.5667, 32.5964],
            [111.374, 32.8306],
            [111.2442, 32.8862],
            [111.2324, 33.0427],
            [110.9777, 33.2597],
            [111.0243, 33.3411],
            [111.0023, 33.5742],
            [110.8273, 33.6748],
            [110.7721, 33.8019],
            [110.6029, 33.8597],
            [110.6712, 33.9541],
            [110.4366, 34.2472],
            [110.4951, 34.3368],
            [110.3594, 34.5088],
            [110.3803, 34.6045],
            [110.6824, 34.5941],
            [111.221, 34.7919],
            [111.5667, 34.8488],
            [111.6675, 34.9908],
            [111.8223, 35.0725],
            [112.0554, 35.0646],
            [112.0561, 35.2819],
            [112.328, 35.2169],
            [112.9218, 35.2499],
            [113.024, 35.3634],
            [113.2933, 35.4253],
            [113.4905, 35.5306],
            [113.5691, 35.8178],
            [113.6507, 35.8369],
            [113.6471, 36.1234],
            [113.7244, 36.3601]
          ]
        ]
      },
      properties: {
        name: 'Henan',
        id: 'CN-HA',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-HA'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [109.5767, 31.7332],
            [109.6175, 32.105],
            [109.4904, 32.3026],
            [109.6145, 32.5954],
            [109.9057, 32.595],
            [110.0125, 32.5491],
            [110.1599, 32.5959],
            [110.1356, 32.8107],
            [110.0214, 32.8749],
            [109.7589, 32.9097],
            [109.7863, 33.0718],
            [109.4337, 33.1538],
            [109.5021, 33.2323],
            [109.8471, 33.2498],
            [110.2272, 33.1609],
            [110.8127, 33.1527],
            [110.9777, 33.2597],
            [111.2324, 33.0427],
            [111.2442, 32.8862],
            [111.374, 32.8306],
            [111.5667, 32.5964],
            [111.7152, 32.6066],
            [111.8841, 32.5056],
            [112.0528, 32.4743],
            [112.3179, 32.3283],
            [112.5437, 32.4044],
            [112.7576, 32.3476],
            [112.8646, 32.4002],
            [113.1342, 32.3799],
            [113.2064, 32.4297],
            [113.4172, 32.2752],
            [113.6199, 32.3605],
            [113.7347, 32.2503],
            [113.8417, 31.8491],
            [113.9714, 31.7595],
            [114.124, 31.8422],
            [114.2911, 31.7521],
            [114.571, 31.768],
            [114.6227, 31.5912],
            [114.83, 31.4622],
            [115.2298, 31.5558],
            [115.2153, 31.4248],
            [115.3643, 31.405],
            [115.5735, 31.1493],
            [115.6955, 31.2032],
            [115.8685, 31.1489],
            [116.0693, 30.9665],
            [115.8625, 30.8684],
            [115.755, 30.6868],
            [115.9087, 30.5205],
            [115.8791, 30.3851],
            [116.0601, 30.207],
            [116.0687, 29.9657],
            [116.1296, 29.8248],
            [115.923, 29.7247],
            [115.6721, 29.8561],
            [115.5052, 29.8418],
            [115.4652, 29.7422],
            [115.2834, 29.6266],
            [114.897, 29.5332],
            [114.8494, 29.4059],
            [114.6683, 29.399],
            [114.5064, 29.326],
            [114.2623, 29.3552],
            [114.2434, 29.2384],
            [114.0612, 29.2116],
            [113.9402, 29.0483],
            [113.7343, 29.0632],
            [113.597, 29.2641],
            [113.7451, 29.4505],
            [113.6596, 29.6819],
            [113.3703, 29.7031],
            [113.1266, 29.4437],
            [112.9363, 29.4774],
            [112.8843, 29.8006],
            [112.6782, 29.5969],
            [112.4431, 29.6353],
            [112.3624, 29.5394],
            [112.0609, 29.6837],
            [111.9545, 29.8393],
            [111.7403, 29.9214],
            [111.386, 29.9189],
            [111.2447, 30.0434],
            [110.9237, 30.0674],
            [110.7487, 30.1196],
            [110.518, 30.0597],
            [110.5011, 29.9149],
            [110.6366, 29.7772],
            [110.3627, 29.6368],
            [110.1084, 29.7921],
            [109.771, 29.7578],
            [109.7598, 29.6921],
            [109.515, 29.6218],
            [109.3679, 29.4281],
            [109.3467, 29.2923],
            [109.2286, 29.1224],
            [108.9713, 29.3308],
            [108.8594, 29.4732],
            [108.8993, 29.5969],
            [108.675, 29.6937],
            [108.5166, 29.8705],
            [108.5694, 30.2488],
            [108.3969, 30.3905],
            [108.6871, 30.589],
            [108.8196, 30.4982],
            [108.9559, 30.6227],
            [109.1123, 30.6498],
            [109.1346, 30.535],
            [109.345, 30.5662],
            [109.6424, 30.7188],
            [109.7735, 30.8526],
            [109.8936, 30.8916],
            [110.0757, 30.8041],
            [110.1905, 31.1507],
            [110.1382, 31.3956],
            [109.5767, 31.7332]
          ]
        ]
      },
      properties: {
        name: 'Hubei',
        id: 'CN-HB',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-HB'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [117.1939, 40.0828],
              [117.1315, 39.9218],
              [117.1524, 39.6267],
              [116.8943, 39.6963],
              [116.9143, 39.8509],
              [116.7793, 39.903],
              [116.7777, 40.028],
              [117.1939, 40.0828]
            ]
          ],
          [
            [
              [114.1279, 40.7358],
              [113.9663, 40.9815],
              [113.8125, 41.0959],
              [114.0094, 41.2302],
              [113.8636, 41.4366],
              [114.0198, 41.5189],
              [114.2427, 41.5058],
              [114.2052, 41.7976],
              [114.3301, 41.9201],
              [114.4896, 41.9645],
              [114.565, 42.1319],
              [114.752, 42.1028],
              [114.8954, 41.9915],
              [114.8681, 41.7968],
              [114.9397, 41.6119],
              [115.307, 41.6704],
              [115.8224, 41.935],
              [115.9927, 41.8193],
              [116.1898, 41.8625],
              [116.3208, 42.0035],
              [116.5471, 41.9265],
              [116.8734, 42.0165],
              [116.9153, 42.4043],
              [117.0865, 42.4818],
              [117.3704, 42.4605],
              [117.4676, 42.5838],
              [117.7961, 42.5779],
              [118.0127, 42.3931],
              [117.9623, 42.2401],
              [118.2551, 42.0837],
              [118.3304, 41.847],
              [118.1239, 41.74],
              [118.3587, 41.3201],
              [118.8376, 41.3726],
              [118.9434, 41.3162],
              [119.2331, 41.3125],
              [119.0141, 40.9961],
              [118.8891, 40.9563],
              [118.8422, 40.796],
              [119.2703, 40.5336],
              [119.5671, 40.5373],
              [119.6215, 40.2318],
              [119.7414, 40.1895],
              [119.8385, 39.9905],
              [119.5657, 39.9063],
              [119.5307, 39.8109],
              [119.3612, 39.7448],
              [119.2018, 39.3782],
              [118.9981, 39.1962],
              [118.7454, 39.1368],
              [118.6046, 39.1696],
              [118.334, 39.0246],
              [118.2218, 39.0579],
              [118.0674, 39.2212],
              [118.0104, 39.3619],
              [117.8693, 39.4485],
              [117.933, 39.5762],
              [117.6935, 39.5805],
              [117.5488, 39.7726],
              [117.5431, 39.9819],
              [117.7111, 39.9868],
              [117.5417, 40.2283],
              [117.3797, 40.2268],
              [117.2173, 40.3749],
              [117.1951, 40.4969],
              [117.3061, 40.6539],
              [116.9942, 40.693],
              [116.6669, 40.9767],
              [116.4443, 40.9755],
              [116.4544, 40.7901],
              [116.2322, 40.7868],
              [116.0973, 40.6148],
              [115.9046, 40.617],
              [115.7523, 40.5414],
              [115.7662, 40.4445],
              [115.9552, 40.2618],
              [115.835, 40.1398],
              [115.6063, 40.1188],
              [115.4408, 40.0262],
              [115.5083, 39.9119],
              [115.476, 39.6536],
              [115.6817, 39.5999],
              [115.7501, 39.5117],
              [115.9815, 39.5909],
              [116.2313, 39.5557],
              [116.3363, 39.452],
              [116.5995, 39.6292],
              [116.7994, 39.61],
              [116.8563, 39.4271],
              [116.9015, 39.0642],
              [116.7066, 38.933],
              [116.7508, 38.7402],
              [117.0192, 38.7021],
              [117.1021, 38.5865],
              [117.2557, 38.5694],
              [117.5643, 38.6557],
              [117.7032, 38.3835],
              [117.8655, 38.2601],
              [117.7004, 38.0777],
              [117.5744, 38.0684],
              [117.4315, 37.8487],
              [116.7944, 37.8313],
              [116.4523, 37.5129],
              [116.3624, 37.5618],
              [116.1588, 37.3785],
              [115.9789, 37.3328],
              [115.8636, 37.0716],
              [115.6817, 36.8152],
              [115.4805, 36.762],
              [115.3366, 36.613],
              [115.2939, 36.4121],
              [115.416, 36.3235],
              [115.4728, 36.1694],
              [115.3235, 36.0867],
              [115.1205, 36.2114],
              [115.0057, 36.0724],
              [114.8893, 36.1365],
              [114.5924, 36.1201],
              [114.4023, 36.2244],
              [114.0618, 36.2727],
              [114.0117, 36.3535],
              [113.7244, 36.3601],
              [113.5486, 36.4961],
              [113.4725, 36.6404],
              [113.6746, 36.7899],
              [113.7886, 36.9946],
              [113.8257, 37.1671],
              [114.112, 37.5897],
              [114.1334, 37.675],
              [113.9676, 37.8214],
              [113.8275, 38.1583],
              [113.5382, 38.27],
              [113.6063, 38.6455],
              [113.7032, 38.6547],
              [113.8491, 38.8284],
              [113.7623, 38.9693],
              [114.0368, 39.1364],
              [114.1021, 39.0512],
              [114.3446, 39.087],
              [114.5454, 39.5475],
              [114.4016, 39.6506],
              [114.3864, 39.8672],
              [114.0527, 39.918],
              [114.072, 40.1847],
              [114.4446, 40.2582],
              [114.3079, 40.3683],
              [114.2765, 40.5894],
              [114.1279, 40.7358]
            ]
          ]
        ]
      },
      properties: {
        name: 'Hebei',
        id: 'CN-HE',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-HE'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [110.6882, 20.1624],
            [110.7804, 20.0135],
            [110.9387, 20.006],
            [111.034, 19.651],
            [110.7596, 19.4251],
            [110.6761, 19.391],
            [110.5108, 18.9415],
            [110.5323, 18.7929],
            [110.4138, 18.6599],
            [110.2829, 18.6693],
            [110.0985, 18.5254],
            [110.0495, 18.3832],
            [109.7676, 18.3938],
            [109.7457, 18.2457],
            [109.5738, 18.207],
            [109.4574, 18.2826],
            [109.1759, 18.2857],
            [109.106, 18.3623],
            [108.6873, 18.5054],
            [108.6966, 18.7332],
            [108.6229, 18.8399],
            [108.6593, 18.9368],
            [108.6121, 19.1007],
            [108.6347, 19.2916],
            [109.0082, 19.5835],
            [109.1813, 19.6535],
            [109.161, 19.7877],
            [109.3066, 19.9185],
            [109.521, 19.8473],
            [109.5355, 19.9487],
            [109.7034, 20.0063],
            [109.8496, 19.9426],
            [110.0198, 19.9493],
            [110.1593, 20.0618],
            [110.5714, 20.092],
            [110.6882, 20.1624]
          ]
        ]
      },
      properties: {
        name: 'Hainan',
        id: 'CN-HI',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-HI'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [114.042, 22.5057],
            [114.2268, 22.5452],
            [114.2682, 22.419],
            [114.1049, 22.3713],
            [114.042, 22.5057]
          ]
        ]
      },
      properties: {
        name: 'Hong Kong',
        id: 'HK',
        TYPE: 'Special Administrative Region',
        CNTRY: 'China'
      },
      id: 'HK'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [131.276, 43.4787],
            [131.1436, 43.4077],
            [131.0195, 43.5025],
            [130.8139, 43.5045],
            [130.5158, 43.6283],
            [130.3301, 43.948],
            [130.0207, 43.8557],
            [129.9382, 44.0296],
            [129.7325, 43.877],
            [129.4914, 43.8759],
            [129.2126, 43.7836],
            [129.2021, 43.5745],
            [129.0031, 43.5194],
            [128.83, 43.5874],
            [128.6858, 43.8857],
            [128.4628, 44.1571],
            [128.467, 44.3743],
            [128.364, 44.5082],
            [128.203, 44.4244],
            [128.0934, 44.283],
            [128.0962, 44.1313],
            [127.9011, 44.0642],
            [127.7326, 44.0976],
            [127.4741, 44.3984],
            [127.4028, 44.6302],
            [127.0238, 44.5948],
            [126.9961, 44.8791],
            [127.0799, 44.9507],
            [126.9556, 45.1315],
            [126.7892, 45.134],
            [126.556, 45.2468],
            [126.1597, 45.1302],
            [125.9015, 45.1906],
            [125.6954, 45.3345],
            [125.6041, 45.5118],
            [125.3553, 45.3908],
            [125.1274, 45.4051],
            [125.0351, 45.4939],
            [124.5395, 45.407],
            [124.0022, 45.7549],
            [124.0378, 46.0237],
            [123.9534, 46.2845],
            [123.1711, 46.2467],
            [123.012, 46.419],
            [122.9983, 46.5695],
            [123.2711, 46.6588],
            [123.5928, 46.6883],
            [123.6294, 46.8087],
            [123.3574, 46.8649],
            [123.157, 46.7428],
            [123.0072, 46.7173],
            [122.8891, 46.8157],
            [122.842, 47.0528],
            [122.6047, 47.1225],
            [122.388, 47.3442],
            [122.5676, 47.5344],
            [123.1774, 47.8002],
            [123.3065, 47.9624],
            [123.5742, 48.0417],
            [123.9955, 48.3758],
            [124.2667, 48.5297],
            [124.4078, 48.1891],
            [124.573, 48.298],
            [124.5095, 48.3748],
            [124.6066, 48.7464],
            [124.7973, 49.1113],
            [124.8894, 49.1792],
            [125.1077, 49.1245],
            [125.2134, 49.187],
            [125.2605, 49.4513],
            [125.1931, 49.7369],
            [125.2719, 50.1255],
            [125.4222, 50.1974],
            [125.5007, 50.4061],
            [125.8192, 50.5469],
            [125.7489, 50.7502],
            [126.0761, 50.9666],
            [125.8471, 51.2091],
            [125.3435, 51.6222],
            [125.0662, 51.6286],
            [124.8693, 51.3818],
            [124.6248, 51.3254],
            [124.2172, 51.3483],
            [123.9188, 51.2993],
            [123.6546, 51.3177],
            [123.2829, 51.2495],
            [123.0619, 51.3193],
            [122.8476, 51.4763],
            [122.5791, 52.2652],
            [122.1589, 52.5134],
            [121.9407, 52.2972],
            [121.7069, 52.3168],
            [121.6408, 52.4413],
            [121.3157, 52.5695],
            [121.3772, 52.6874],
            [121.5943, 52.8347],
            [121.8091, 53.0641],
            [121.4893, 53.3312],
            [121.859, 53.4232],
            [122.0761, 53.4211],
            [122.3172, 53.4985],
            [122.4314, 53.4415],
            [122.8335, 53.4529],
            [123.257, 53.5608],
            [123.5072, 53.5542],
            [123.8567, 53.4854],
            [124.115, 53.3437],
            [124.2457, 53.3656],
            [124.4519, 53.2086],
            [124.8332, 53.1327],
            [125.2008, 53.1893],
            [125.6004, 53.0746],
            [125.7364, 52.9845],
            [126.3436, 52.3959],
            [126.2983, 52.2068],
            [126.5565, 52.1208],
            [126.4618, 51.9427],
            [126.7235, 51.7134],
            [126.6759, 51.5951],
            [126.7895, 51.4276],
            [126.9744, 51.3199],
            [126.9201, 51.062],
            [127.1118, 50.9339],
            [127.3594, 50.5833],
            [127.2876, 50.4775],
            [127.3413, 50.3171],
            [127.5942, 50.2255],
            [127.4866, 50.0179],
            [127.523, 49.8178],
            [127.6497, 49.7777],
            [127.8025, 49.5913],
            [127.9517, 49.6141],
            [128.2308, 49.5228],
            [128.5355, 49.6018],
            [128.7973, 49.5629],
            [129.0851, 49.3528],
            [129.3841, 49.4315],
            [129.521, 49.4119],
            [129.8446, 49.1676],
            [129.9218, 49.0375],
            [130.2226, 48.867],
            [130.4243, 48.9018],
            [130.6663, 48.8712],
            [130.5246, 48.6138],
            [130.8255, 48.3001],
            [130.6511, 48.1018],
            [130.8945, 47.8964],
            [130.9542, 47.7202],
            [131.2533, 47.7337],
            [131.6972, 47.7074],
            [131.8038, 47.6697],
            [132.5619, 47.7152],
            [132.6662, 47.885],
            [133.1603, 48.1057],
            [133.4784, 48.0691],
            [134.0495, 48.3332],
            [134.3892, 48.3857],
            [134.5697, 48.3663],
            [134.6686, 48.229],
            [134.5506, 48.0244],
            [134.5889, 47.9068],
            [134.7715, 47.7535],
            [134.7533, 47.6786],
            [134.4935, 47.4438],
            [134.334, 47.4364],
            [134.1491, 47.2467],
            [134.0208, 46.8223],
            [134.0147, 46.6544],
            [133.85, 46.471],
            [133.9099, 46.2652],
            [133.6882, 46.1464],
            [133.6633, 45.9379],
            [133.521, 45.8923],
            [133.4054, 45.6073],
            [133.156, 45.4652],
            [133.0853, 45.2362],
            [133.131, 45.1212],
            [132.9727, 45.0199],
            [132.852, 45.0557],
            [132.6968, 44.6901],
            [132.5605, 44.5551],
            [132.3162, 44.6152],
            [132.1116, 44.7407],
            [132.1836, 44.9568],
            [132.0586, 45.2356],
            [131.8267, 45.3088],
            [131.6867, 45.1142],
            [131.2771, 44.9243],
            [131.1004, 44.9302],
            [130.9543, 44.8542],
            [131.1027, 44.6915],
            [131.2931, 44.0797],
            [131.2035, 43.8236],
            [131.276, 43.4787]
          ]
        ]
      },
      properties: {
        name: 'Heilongjiang',
        id: 'CN-HL',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-HL'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [109.2286, 29.1224],
            [109.3467, 29.2923],
            [109.3679, 29.4281],
            [109.515, 29.6218],
            [109.7598, 29.6921],
            [109.771, 29.7578],
            [110.1084, 29.7921],
            [110.3627, 29.6368],
            [110.6366, 29.7772],
            [110.5011, 29.9149],
            [110.518, 30.0597],
            [110.7487, 30.1196],
            [110.9237, 30.0674],
            [111.2447, 30.0434],
            [111.386, 29.9189],
            [111.7403, 29.9214],
            [111.9545, 29.8393],
            [112.0609, 29.6837],
            [112.3624, 29.5394],
            [112.4431, 29.6353],
            [112.6782, 29.5969],
            [112.8843, 29.8006],
            [112.9363, 29.4774],
            [113.1266, 29.4437],
            [113.3703, 29.7031],
            [113.6596, 29.6819],
            [113.7451, 29.4505],
            [113.597, 29.2641],
            [113.7343, 29.0632],
            [113.9402, 29.0483],
            [114.0608, 28.8473],
            [114.144, 28.8257],
            [114.1227, 28.6077],
            [114.2485, 28.3292],
            [114.0857, 28.1861],
            [114.0304, 28.0535],
            [113.7561, 27.9431],
            [113.7577, 27.8026],
            [113.5727, 27.5415],
            [113.6943, 27.3354],
            [113.8665, 27.2883],
            [113.7666, 27.1057],
            [113.9231, 26.9546],
            [113.8347, 26.8074],
            [113.9065, 26.619],
            [114.1171, 26.5927],
            [114.082, 26.4149],
            [113.9364, 26.1688],
            [114.0401, 26.0764],
            [114.0257, 25.9261],
            [113.9096, 25.7256],
            [113.9857, 25.5621],
            [113.9592, 25.4574],
            [113.5738, 25.3134],
            [113.4383, 25.3618],
            [113.2273, 25.5152],
            [113.0105, 25.3466],
            [112.8558, 25.3333],
            [112.9603, 25.1571],
            [113.0009, 24.9303],
            [112.8553, 24.8968],
            [112.698, 25.0105],
            [112.6509, 25.1316],
            [112.254, 25.1605],
            [112.1449, 25.0196],
            [112.1671, 24.9156],
            [112.0159, 24.74],
            [111.7002, 24.7857],
            [111.5581, 24.6393],
            [111.4325, 24.671],
            [111.4741, 24.8004],
            [111.4313, 25.1011],
            [111.263, 25.1457],
            [110.9916, 24.9226],
            [110.9424, 25.051],
            [111.0937, 25.2851],
            [111.2913, 25.4444],
            [111.3339, 25.6063],
            [111.2994, 25.7159],
            [111.4347, 25.7724],
            [111.1969, 26.0078],
            [111.2818, 26.2547],
            [110.9925, 26.3237],
            [110.7565, 26.2524],
            [110.6078, 26.3373],
            [110.3723, 26.0975],
            [110.2406, 26.0254],
            [110.0633, 26.0464],
            [110.0813, 26.1712],
            [109.9028, 26.1484],
            [109.8223, 25.9199],
            [109.6363, 26.0489],
            [109.478, 26.0327],
            [109.4349, 26.2421],
            [109.267, 26.3311],
            [109.404, 26.5403],
            [109.3521, 26.6608],
            [109.5506, 26.9505],
            [109.5072, 27.0761],
            [109.3893, 27.1657],
            [109.1643, 27.0758],
            [109.0168, 27.0942],
            [108.9003, 27.2172],
            [109.0566, 27.2931],
            [109.2033, 27.4537],
            [109.4542, 27.5801],
            [109.4327, 27.7438],
            [109.341, 27.836],
            [109.2962, 28.0577],
            [109.3792, 28.2704],
            [109.2688, 28.3185],
            [109.2703, 28.5035],
            [109.232, 28.8789],
            [109.3157, 29.0473],
            [109.2286, 29.1224]
          ]
        ]
      },
      properties: {
        name: 'Hunan',
        id: 'CN-HN',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-HN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [123.1711, 46.2467],
            [123.9534, 46.2845],
            [124.0378, 46.0237],
            [124.0022, 45.7549],
            [124.5395, 45.407],
            [125.0351, 45.4939],
            [125.1274, 45.4051],
            [125.3553, 45.3908],
            [125.6041, 45.5118],
            [125.6954, 45.3345],
            [125.9015, 45.1906],
            [126.1597, 45.1302],
            [126.556, 45.2468],
            [126.7892, 45.134],
            [126.9556, 45.1315],
            [127.0799, 44.9507],
            [126.9961, 44.8791],
            [127.0238, 44.5948],
            [127.4028, 44.6302],
            [127.4741, 44.3984],
            [127.7326, 44.0976],
            [127.9011, 44.0642],
            [128.0962, 44.1313],
            [128.0934, 44.283],
            [128.203, 44.4244],
            [128.364, 44.5082],
            [128.467, 44.3743],
            [128.4628, 44.1571],
            [128.6858, 43.8857],
            [128.83, 43.5874],
            [129.0031, 43.5194],
            [129.2021, 43.5745],
            [129.2126, 43.7836],
            [129.4914, 43.8759],
            [129.7325, 43.877],
            [129.9382, 44.0296],
            [130.0207, 43.8557],
            [130.3301, 43.948],
            [130.5158, 43.6283],
            [130.8139, 43.5045],
            [131.0195, 43.5025],
            [131.1436, 43.4077],
            [131.276, 43.4787],
            [131.3058, 43.3927],
            [131.0174, 42.8561],
            [130.8212, 42.8719],
            [130.4876, 42.7788],
            [130.5166, 42.609],
            [130.4367, 42.5459],
            [130.2482, 42.7114],
            [130.259, 42.8958],
            [129.8965, 43.002],
            [129.7472, 42.646],
            [129.7406, 42.4643],
            [129.5343, 42.369],
            [129.3991, 42.4482],
            [128.9552, 42.0756],
            [128.5202, 41.9962],
            [128.3126, 42.0368],
            [128.0694, 41.9782],
            [128.1441, 41.7239],
            [128.2973, 41.6339],
            [128.2859, 41.5325],
            [128.1426, 41.3782],
            [127.2526, 41.479],
            [126.8808, 41.7921],
            [126.7853, 41.693],
            [126.6094, 41.6652],
            [126.5859, 41.5533],
            [126.2872, 41.1595],
            [125.9153, 40.911],
            [125.6537, 40.9122],
            [125.7002, 41.1029],
            [125.4387, 41.5998],
            [125.3156, 41.6847],
            [125.2875, 41.9664],
            [125.4794, 42.139],
            [125.3613, 42.1755],
            [125.0683, 42.5034],
            [125.0893, 42.6099],
            [124.857, 42.7995],
            [124.8629, 42.9778],
            [124.7826, 43.111],
            [124.5163, 42.8656],
            [124.3315, 42.9949],
            [124.4104, 43.0769],
            [124.2122, 43.2522],
            [124.0152, 43.2837],
            [123.8465, 43.4149],
            [123.6949, 43.3773],
            [123.6968, 43.3709],
            [123.4308, 43.4212],
            [123.317, 43.4956],
            [123.5354, 43.6234],
            [123.4724, 43.8576],
            [123.3224, 44.0679],
            [123.1371, 44.5172],
            [122.4793, 44.2336],
            [122.2626, 44.2613],
            [122.2889, 44.4653],
            [122.1132, 44.5764],
            [122.0814, 44.9133],
            [122.1569, 45.4358],
            [121.9644, 45.56],
            [121.9663, 45.6933],
            [121.744, 45.7914],
            [121.838, 46.0231],
            [121.9913, 45.9898],
            [122.2461, 45.803],
            [122.3676, 45.9099],
            [122.6791, 45.7228],
            [122.8002, 45.7776],
            [122.78, 46.0675],
            [123.0383, 46.0971],
            [123.1711, 46.2467]
          ]
        ]
      },
      properties: {
        name: 'Jilin',
        id: 'CN-JL',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-JL'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [116.3686, 34.6409],
            [116.4428, 34.9006],
            [116.7794, 34.9441],
            [116.97, 34.8701],
            [117.2026, 34.459],
            [117.3526, 34.5946],
            [117.5565, 34.4672],
            [117.8042, 34.6513],
            [118.0926, 34.6516],
            [118.0748, 34.5596],
            [118.1984, 34.387],
            [118.3997, 34.438],
            [118.4237, 34.6098],
            [118.5194, 34.7174],
            [118.6829, 34.685],
            [118.8581, 34.9522],
            [118.856, 35.0341],
            [119.294, 35.0973],
            [119.2238, 35.0573],
            [119.1715, 34.8577],
            [119.3065, 34.7443],
            [119.786, 34.4682],
            [120.0134, 34.4256],
            [120.2782, 34.2974],
            [120.3407, 34.0949],
            [120.6862, 33.4558],
            [120.7148, 33.2776],
            [120.8917, 33.019],
            [120.8571, 32.7672],
            [120.9048, 32.6176],
            [121.3729, 32.4001],
            [121.434, 32.1243],
            [121.7283, 32.0315],
            [121.8503, 31.9157],
            [121.9285, 31.7418],
            [121.8708, 31.6852],
            [121.629, 31.7371],
            [121.3033, 31.8854],
            [121.0973, 31.7871],
            [121.0845, 31.7215],
            [121.326, 31.5053],
            [121.1518, 31.4409],
            [121.0467, 31.1398],
            [120.8763, 31.132],
            [120.8951, 31.012],
            [120.6831, 30.9541],
            [120.5071, 30.7672],
            [120.3677, 30.955],
            [120.1508, 30.9363],
            [119.9919, 31.0265],
            [119.9089, 31.1753],
            [119.6322, 31.1393],
            [119.3856, 31.1818],
            [119.1751, 31.305],
            [119.0847, 31.2359],
            [118.7836, 31.2325],
            [118.6977, 31.3047],
            [118.8666, 31.4292],
            [118.8595, 31.6243],
            [118.6537, 31.6461],
            [118.3641, 31.9372],
            [118.3877, 32.0832],
            [118.5015, 32.1962],
            [118.657, 32.2202],
            [118.7115, 32.6117],
            [119.1423, 32.4945],
            [119.2043, 32.7263],
            [119.0123, 32.9614],
            [118.8471, 32.9605],
            [118.7476, 32.7444],
            [118.582, 32.725],
            [118.2943, 32.7807],
            [118.2277, 32.9275],
            [118.201, 33.2024],
            [118.0343, 33.142],
            [117.9319, 33.2268],
            [118.1073, 33.4779],
            [118.1056, 33.7655],
            [117.7258, 33.729],
            [117.7529, 33.887],
            [117.6631, 33.9955],
            [117.3471, 34.0911],
            [117.1866, 34.0702],
            [117.0193, 34.1776],
            [116.9561, 34.3938],
            [116.5868, 34.511],
            [116.3686, 34.6409]
          ]
        ]
      },
      properties: {
        name: 'Jiangsu',
        id: 'CN-JS',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-JS'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [116.1296, 29.8248],
            [116.2564, 29.7846],
            [116.533, 29.9004],
            [116.5857, 30.0563],
            [116.7785, 30.0376],
            [116.8952, 29.9522],
            [116.6471, 29.6399],
            [116.8359, 29.5735],
            [117.1076, 29.7144],
            [117.0679, 29.8346],
            [117.2483, 29.9114],
            [117.4496, 29.7515],
            [117.5844, 29.587],
            [117.7024, 29.5516],
            [118.0089, 29.5798],
            [118.1895, 29.3953],
            [118.0282, 29.1789],
            [118.0965, 28.9936],
            [118.258, 28.9243],
            [118.4323, 28.6741],
            [118.4243, 28.2922],
            [118.3419, 28.1003],
            [118.1207, 28.0588],
            [117.8519, 27.9503],
            [117.7274, 27.8067],
            [117.5175, 27.992],
            [117.1981, 27.7158],
            [117.0122, 27.6537],
            [117.1624, 27.2772],
            [117.0421, 27.1016],
            [116.6902, 26.9901],
            [116.544, 26.8436],
            [116.5351, 26.5685],
            [116.6322, 26.4713],
            [116.3945, 26.2717],
            [116.4829, 26.114],
            [116.3613, 25.968],
            [116.1257, 25.8616],
            [116.1744, 25.7774],
            [116.0508, 25.6905],
            [115.9876, 25.4639],
            [116.0071, 25.3253],
            [115.8515, 25.2131],
            [115.8772, 24.9358],
            [115.7517, 24.7464],
            [115.8316, 24.5654],
            [115.7132, 24.5405],
            [115.567, 24.6224],
            [115.4735, 24.7592],
            [115.2794, 24.7544],
            [115.108, 24.6634],
            [114.9392, 24.6502],
            [114.8649, 24.5605],
            [114.741, 24.6137],
            [114.4262, 24.4871],
            [114.3522, 24.5879],
            [114.1695, 24.6731],
            [114.3264, 24.7481],
            [114.3903, 24.9521],
            [114.6437, 25.0775],
            [114.7372, 25.2428],
            [114.5297, 25.4185],
            [114.3819, 25.3176],
            [114.23, 25.2906],
            [114.0343, 25.3632],
            [113.9592, 25.4574],
            [113.9857, 25.5621],
            [113.9096, 25.7256],
            [114.0257, 25.9261],
            [114.0401, 26.0764],
            [113.9364, 26.1688],
            [114.082, 26.4149],
            [114.1171, 26.5927],
            [113.9065, 26.619],
            [113.8347, 26.8074],
            [113.9231, 26.9546],
            [113.7666, 27.1057],
            [113.8665, 27.2883],
            [113.6943, 27.3354],
            [113.5727, 27.5415],
            [113.7577, 27.8026],
            [113.7561, 27.9431],
            [114.0304, 28.0535],
            [114.0857, 28.1861],
            [114.2485, 28.3292],
            [114.1227, 28.6077],
            [114.144, 28.8257],
            [114.0608, 28.8473],
            [113.9402, 29.0483],
            [114.0612, 29.2116],
            [114.2434, 29.2384],
            [114.2623, 29.3552],
            [114.5064, 29.326],
            [114.6683, 29.399],
            [114.8494, 29.4059],
            [114.897, 29.5332],
            [115.2834, 29.6266],
            [115.4652, 29.7422],
            [115.5052, 29.8418],
            [115.6721, 29.8561],
            [115.923, 29.7247],
            [116.1296, 29.8248]
          ]
        ]
      },
      properties: {
        name: 'Jiangxi',
        id: 'CN-JX',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-JX'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [119.8385, 39.9905],
            [119.7414, 40.1895],
            [119.6215, 40.2318],
            [119.5671, 40.5373],
            [119.2703, 40.5336],
            [118.8422, 40.796],
            [118.8891, 40.9563],
            [119.0141, 40.9961],
            [119.2331, 41.3125],
            [119.397, 41.4728],
            [119.2939, 41.7014],
            [119.3141, 41.9654],
            [119.374, 42.0897],
            [119.2773, 42.268],
            [119.5371, 42.3557],
            [119.6001, 42.2554],
            [119.8408, 42.2132],
            [119.8363, 42.1015],
            [120.034, 41.8137],
            [120.1211, 41.7703],
            [120.284, 41.9442],
            [120.4689, 42.0198],
            [120.4612, 42.1031],
            [120.8787, 42.2653],
            [121.0339, 42.2503],
            [121.3255, 42.4543],
            [121.5861, 42.5154],
            [121.7101, 42.4482],
            [121.9071, 42.5875],
            [121.931, 42.6845],
            [122.1976, 42.7249],
            [122.3812, 42.6777],
            [122.3688, 42.8462],
            [122.6183, 42.7733],
            [123.0615, 42.7733],
            [123.243, 42.9897],
            [123.4763, 43.039],
            [123.5778, 42.9977],
            [123.6976, 43.368],
            [123.6949, 43.3773],
            [123.8465, 43.4149],
            [124.0152, 43.2837],
            [124.2122, 43.2522],
            [124.4104, 43.0769],
            [124.3315, 42.9949],
            [124.5163, 42.8656],
            [124.7826, 43.111],
            [124.8629, 42.9778],
            [124.857, 42.7995],
            [125.0893, 42.6099],
            [125.0683, 42.5034],
            [125.3613, 42.1755],
            [125.4794, 42.139],
            [125.2875, 41.9664],
            [125.3156, 41.6847],
            [125.4387, 41.5998],
            [125.7002, 41.1029],
            [125.6537, 40.9122],
            [125.9153, 40.911],
            [125.7747, 40.8868],
            [125.4671, 40.7107],
            [125.0141, 40.5327],
            [124.7426, 40.37],
            [124.3286, 40.063],
            [124.1568, 39.8148],
            [123.8701, 39.8563],
            [123.5493, 39.7993],
            [123.3032, 39.7963],
            [123.1343, 39.6671],
            [122.8059, 39.5907],
            [122.5721, 39.4649],
            [122.3877, 39.4485],
            [122.1138, 39.2193],
            [122.1405, 39.154],
            [121.8256, 38.9585],
            [121.5831, 38.946],
            [121.472, 38.8179],
            [121.3551, 38.8249],
            [121.1974, 38.7224],
            [121.0883, 38.8949],
            [121.6612, 39.0799],
            [121.5909, 39.2732],
            [121.7474, 39.3532],
            [121.3744, 39.5327],
            [121.4593, 39.6323],
            [121.5359, 39.8668],
            [121.8943, 39.9998],
            [122.0835, 40.2912],
            [122.3318, 40.4754],
            [122.1482, 40.6023],
            [122.1574, 40.6808],
            [121.8551, 40.8241],
            [121.557, 40.8402],
            [121.1768, 40.9238],
            [120.8271, 40.6776],
            [120.5843, 40.454],
            [120.445, 40.1968],
            [120.301, 40.1829],
            [119.8385, 39.9905]
          ]
        ]
      },
      properties: {
        name: 'Liaoning',
        id: 'CN-LN',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-LN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [113.5623, 22.1654],
            [113.5901, 22.1279],
            [113.5563, 22.1091],
            [113.5412, 22.1599],
            [113.5623, 22.1654]
          ]
        ]
      },
      properties: {
        name: 'Macau',
        id: 'MO',
        TYPE: 'Special Administrative Region',
        CNTRY: 'China'
      },
      id: 'MO'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [121.4893, 53.3312],
            [121.8091, 53.0641],
            [121.5943, 52.8347],
            [121.3772, 52.6874],
            [121.3157, 52.5695],
            [121.6408, 52.4413],
            [121.7069, 52.3168],
            [121.9407, 52.2972],
            [122.1589, 52.5134],
            [122.5791, 52.2652],
            [122.8476, 51.4763],
            [123.0619, 51.3193],
            [123.2829, 51.2495],
            [123.6546, 51.3177],
            [123.9188, 51.2993],
            [124.2172, 51.3483],
            [124.6248, 51.3254],
            [124.8693, 51.3818],
            [125.0662, 51.6286],
            [125.3435, 51.6222],
            [125.8471, 51.2091],
            [126.0761, 50.9666],
            [125.7489, 50.7502],
            [125.8192, 50.5469],
            [125.5007, 50.4061],
            [125.4222, 50.1974],
            [125.2719, 50.1255],
            [125.1931, 49.7369],
            [125.2605, 49.4513],
            [125.2134, 49.187],
            [125.1077, 49.1245],
            [124.8894, 49.1792],
            [124.7973, 49.1113],
            [124.6066, 48.7464],
            [124.5095, 48.3748],
            [124.573, 48.298],
            [124.4078, 48.1891],
            [124.2667, 48.5297],
            [123.9955, 48.3758],
            [123.5742, 48.0417],
            [123.3065, 47.9624],
            [123.1774, 47.8002],
            [122.5676, 47.5344],
            [122.388, 47.3442],
            [122.6047, 47.1225],
            [122.842, 47.0528],
            [122.8891, 46.8157],
            [123.0072, 46.7173],
            [123.157, 46.7428],
            [123.3574, 46.8649],
            [123.6294, 46.8087],
            [123.5928, 46.6883],
            [123.2711, 46.6588],
            [122.9983, 46.5695],
            [123.012, 46.419],
            [123.1711, 46.2467],
            [123.0383, 46.0971],
            [122.78, 46.0675],
            [122.8002, 45.7776],
            [122.6791, 45.7228],
            [122.3676, 45.9099],
            [122.2461, 45.803],
            [121.9913, 45.9898],
            [121.838, 46.0231],
            [121.744, 45.7914],
            [121.9663, 45.6933],
            [121.9644, 45.56],
            [122.1569, 45.4358],
            [122.0814, 44.9133],
            [122.1132, 44.5764],
            [122.2889, 44.4653],
            [122.2626, 44.2613],
            [122.4793, 44.2336],
            [123.1371, 44.5172],
            [123.3224, 44.0679],
            [123.4724, 43.8576],
            [123.5354, 43.6234],
            [123.317, 43.4956],
            [123.4308, 43.4212],
            [123.6968, 43.3709],
            [123.6976, 43.368],
            [123.5778, 42.9977],
            [123.4763, 43.039],
            [123.243, 42.9897],
            [123.0615, 42.7733],
            [122.6183, 42.7733],
            [122.3688, 42.8462],
            [122.3812, 42.6777],
            [122.1976, 42.7249],
            [121.931, 42.6845],
            [121.9071, 42.5875],
            [121.7101, 42.4482],
            [121.5861, 42.5154],
            [121.3255, 42.4543],
            [121.0339, 42.2503],
            [120.8787, 42.2653],
            [120.4612, 42.1031],
            [120.4689, 42.0198],
            [120.284, 41.9442],
            [120.1211, 41.7703],
            [120.034, 41.8137],
            [119.8363, 42.1015],
            [119.8408, 42.2132],
            [119.6001, 42.2554],
            [119.5371, 42.3557],
            [119.2773, 42.268],
            [119.374, 42.0897],
            [119.3141, 41.9654],
            [119.2939, 41.7014],
            [119.397, 41.4728],
            [119.2331, 41.3125],
            [118.9434, 41.3162],
            [118.8376, 41.3726],
            [118.3587, 41.3201],
            [118.1239, 41.74],
            [118.3304, 41.847],
            [118.2551, 42.0837],
            [117.9623, 42.2401],
            [118.0127, 42.3931],
            [117.7961, 42.5779],
            [117.4676, 42.5838],
            [117.3704, 42.4605],
            [117.0865, 42.4818],
            [116.9153, 42.4043],
            [116.8734, 42.0165],
            [116.5471, 41.9265],
            [116.3208, 42.0035],
            [116.1898, 41.8625],
            [115.9927, 41.8193],
            [115.8224, 41.935],
            [115.307, 41.6704],
            [114.9397, 41.6119],
            [114.8681, 41.7968],
            [114.8954, 41.9915],
            [114.752, 42.1028],
            [114.565, 42.1319],
            [114.4896, 41.9645],
            [114.3301, 41.9201],
            [114.2052, 41.7976],
            [114.2427, 41.5058],
            [114.0198, 41.5189],
            [113.8636, 41.4366],
            [114.0094, 41.2302],
            [113.8125, 41.0959],
            [113.9663, 40.9815],
            [114.1279, 40.7358],
            [114.0551, 40.5274],
            [113.7621, 40.4758],
            [113.5531, 40.3476],
            [113.3099, 40.3203],
            [113.2444, 40.4121],
            [112.8863, 40.3254],
            [112.7439, 40.1669],
            [112.4555, 40.2978],
            [112.3028, 40.2515],
            [111.9746, 39.805],
            [111.9297, 39.6144],
            [111.7606, 39.59],
            [111.4977, 39.6587],
            [111.3481, 39.4287],
            [111.1338, 39.3678],
            [111.0238, 39.5655],
            [110.8722, 39.4964],
            [110.6153, 39.2488],
            [110.5162, 39.3835],
            [109.9528, 39.1873],
            [109.7135, 39.0657],
            [109.6736, 38.9315],
            [109.421, 38.8036],
            [109.3947, 38.7129],
            [109.1539, 38.5589],
            [108.9697, 38.3382],
            [108.9296, 38.1812],
            [109.0519, 38.0861],
            [108.9379, 37.9213],
            [108.7767, 37.943],
            [108.7786, 37.684],
            [108.0144, 37.6659],
            [107.9662, 37.7919],
            [107.6517, 37.8744],
            [107.4088, 37.9487],
            [107.3286, 38.0906],
            [107.1672, 38.1577],
            [107.0041, 38.1214],
            [106.8099, 38.1579],
            [106.5575, 38.2629],
            [106.4983, 38.3561],
            [106.6698, 38.5395],
            [106.6974, 38.7237],
            [106.9593, 38.9505],
            [106.9547, 39.0605],
            [106.7874, 39.2351],
            [106.7837, 39.3647],
            [106.598, 39.3654],
            [106.4821, 39.2908],
            [106.2827, 39.2714],
            [106.1662, 39.1624],
            [106.0755, 38.98],
            [105.9646, 38.9046],
            [105.8464, 38.6313],
            [105.8225, 38.4144],
            [105.8591, 38.2689],
            [105.7637, 38.134],
            [105.8361, 38.0036],
            [105.7565, 37.7993],
            [105.5951, 37.699],
            [105.3955, 37.7094],
            [105.107, 37.633],
            [104.9852, 37.5444],
            [104.7123, 37.5058],
            [104.4094, 37.5156],
            [104.2791, 37.4283],
            [104.1781, 37.4081],
            [103.8917, 37.5916],
            [103.669, 37.7858],
            [103.4102, 37.8475],
            [103.3623, 38.0907],
            [103.5445, 38.1553],
            [103.4123, 38.4075],
            [103.8763, 38.6451],
            [104.0319, 38.8912],
            [104.1742, 38.9415],
            [104.2057, 39.0929],
            [104.0525, 39.2974],
            [104.0911, 39.4178],
            [103.8375, 39.4588],
            [103.3614, 39.3461],
            [103.0019, 39.1022],
            [102.6075, 39.1817],
            [102.4558, 39.2541],
            [102.2008, 39.1706],
            [101.8152, 39.1021],
            [102.0805, 38.8969],
            [101.7769, 38.6648],
            [101.6169, 38.6619],
            [101.3275, 38.7828],
            [101.176, 38.9548],
            [101.0159, 38.9572],
            [100.8617, 39.1183],
            [100.8479, 39.3971],
            [100.4996, 39.3983],
            [100.3203, 39.5183],
            [100.2506, 39.6875],
            [100.013, 39.74],
            [99.7649, 39.886],
            [99.4532, 39.8761],
            [99.6194, 40.064],
            [99.9069, 40.2121],
            [100.0199, 40.397],
            [100.2399, 40.6007],
            [100.2268, 40.7219],
            [100.0941, 40.8832],
            [99.6776, 40.9328],
            [99.5683, 40.8488],
            [99.1794, 40.8647],
            [98.8689, 40.753],
            [98.6479, 40.542],
            [98.256, 40.5251],
            [98.3694, 40.8368],
            [98.3329, 40.9144],
            [97.9731, 41.0961],
            [97.612, 41.4584],
            [97.8399, 41.6589],
            [97.1847, 42.7718],
            [98.0198, 42.6834],
            [98.2479, 42.6378],
            [99.4963, 42.5701],
            [99.995, 42.6476],
            [100.2827, 42.6308],
            [100.3264, 42.6834],
            [100.9116, 42.6453],
            [101.8168, 42.4736],
            [102.0633, 42.2187],
            [102.4146, 42.1405],
            [102.739, 42.1341],
            [103.3221, 41.9075],
            [103.8624, 41.808],
            [104.5437, 41.8838],
            [104.534, 41.6626],
            [104.9449, 41.6453],
            [105.0344, 41.5677],
            [105.306, 41.7346],
            [105.802, 41.9576],
            [106.6837, 42.2593],
            [107.2665, 42.3566],
            [107.4529, 42.4619],
            [107.9123, 42.412],
            [108.2386, 42.4619],
            [108.7824, 42.4098],
            [108.9682, 42.4566],
            [109.2717, 42.4228],
            [109.5476, 42.4717],
            [109.8347, 42.6249],
            [110.1536, 42.6378],
            [110.4491, 42.7974],
            [110.7035, 43.0571],
            [111.0471, 43.3435],
            [111.5745, 43.4895],
            [111.7607, 43.6951],
            [111.9546, 43.7309],
            [112.0028, 43.82],
            [111.8533, 43.9918],
            [111.6316, 44.0635],
            [111.3869, 44.3473],
            [111.5598, 44.7231],
            [111.7404, 44.9621],
            [111.9761, 45.0901],
            [112.4155, 45.0615],
            [112.7417, 44.8748],
            [113.1209, 44.7997],
            [113.4616, 44.7918],
            [113.6238, 44.744],
            [114.0487, 44.9518],
            [114.3792, 45.1653],
            [114.5131, 45.3743],
            [114.752, 45.4531],
            [115, 45.4018],
            [115.3393, 45.3831],
            [115.7211, 45.4385],
            [115.7654, 45.5029],
            [116.2735, 45.7611],
            [116.2621, 45.9503],
            [116.5851, 46.2871],
            [116.9817, 46.3475],
            [117.3657, 46.3487],
            [117.4389, 46.5797],
            [117.8171, 46.5188],
            [118.27, 46.7876],
            [118.5954, 46.7629],
            [118.9783, 46.8182],
            [119.5978, 46.5506],
            [119.9243, 46.7519],
            [119.8039, 46.9232],
            [119.8414, 47.1221],
            [119.7014, 47.1932],
            [119.7161, 47.321],
            [119.4751, 47.3362],
            [119.1517, 47.5394],
            [119.124, 47.7032],
            [118.7758, 47.8066],
            [118.4787, 48.0001],
            [118.1195, 48.0377],
            [117.7598, 47.9901],
            [117.3744, 47.638],
            [117.1083, 47.8099],
            [116.8041, 47.9001],
            [116.4953, 47.8424],
            [116.2562, 47.8767],
            [115.9354, 47.6755],
            [115.5761, 47.9359],
            [115.51, 48.1803],
            [115.8261, 48.2706],
            [115.8215, 48.5317],
            [116.111, 48.7928],
            [116.0557, 48.8835],
            [116.7003, 49.8399],
            [116.948, 49.721],
            [117.2742, 49.6315],
            [117.4785, 49.6284],
            [117.8545, 49.5054],
            [118.5627, 49.9257],
            [119.1975, 50.0121],
            [119.3638, 50.175],
            [119.1785, 50.3457],
            [119.2747, 50.6038],
            [119.4803, 50.7395],
            [119.5144, 50.9066],
            [119.6866, 51.0191],
            [119.9874, 51.4527],
            [120.0369, 51.5965],
            [120.4741, 51.8829],
            [120.6463, 51.9282],
            [120.7781, 52.1584],
            [120.6206, 52.313],
            [120.6818, 52.5426],
            [120.4526, 52.6413],
            [120.0612, 52.584],
            [120.0272, 52.7722],
            [120.3275, 52.899],
            [120.8342, 53.274],
            [121.2186, 53.2813],
            [121.4893, 53.3312]
          ]
        ]
      },
      properties: {
        name: 'Nei Mongol',
        id: 'CN-NM',
        TYPE: 'Autonomous Region',
        CNTRY: 'China'
      },
      id: 'CN-NM'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [104.2791, 37.4283],
            [104.4094, 37.5156],
            [104.7123, 37.5058],
            [104.9852, 37.5444],
            [105.107, 37.633],
            [105.3955, 37.7094],
            [105.5951, 37.699],
            [105.7565, 37.7993],
            [105.8361, 38.0036],
            [105.7637, 38.134],
            [105.8591, 38.2689],
            [105.8225, 38.4144],
            [105.8464, 38.6313],
            [105.9646, 38.9046],
            [106.0755, 38.98],
            [106.1662, 39.1624],
            [106.2827, 39.2714],
            [106.4821, 39.2908],
            [106.598, 39.3654],
            [106.7837, 39.3647],
            [106.7874, 39.2351],
            [106.9547, 39.0605],
            [106.9593, 38.9505],
            [106.6974, 38.7237],
            [106.6698, 38.5395],
            [106.4983, 38.3561],
            [106.5575, 38.2629],
            [106.8099, 38.1579],
            [107.0041, 38.1214],
            [107.1672, 38.1577],
            [107.3286, 38.0906],
            [107.4088, 37.9487],
            [107.6517, 37.8744],
            [107.6246, 37.7817],
            [107.3004, 37.6114],
            [107.2714, 37.0823],
            [107.1598, 37.1489],
            [107.0119, 37.1108],
            [106.7812, 37.1556],
            [106.5757, 36.9416],
            [106.6526, 36.8181],
            [106.5161, 36.7282],
            [106.4464, 36.5619],
            [106.5119, 36.4777],
            [106.5062, 36.2947],
            [106.824, 36.2307],
            [106.9105, 36.1201],
            [106.9233, 35.8073],
            [106.813, 35.714],
            [106.5409, 35.7445],
            [106.4391, 35.6941],
            [106.4986, 35.3591],
            [106.3619, 35.2354],
            [106.1749, 35.4363],
            [106.0191, 35.5267],
            [105.7916, 35.5697],
            [105.6428, 35.7435],
            [105.4891, 35.7249],
            [105.33, 35.892],
            [105.3244, 36.0162],
            [105.5047, 36.1476],
            [105.4717, 36.2936],
            [105.2009, 36.7143],
            [105.3302, 36.7923],
            [105.1871, 36.8888],
            [105.1684, 36.9876],
            [104.9721, 37.0464],
            [104.8483, 37.2194],
            [104.6728, 37.1939],
            [104.5935, 37.2738],
            [104.6639, 37.4084],
            [104.2791, 37.4283]
          ]
        ]
      },
      properties: {
        name: 'Ningxia Hui',
        id: 'CN-NX',
        TYPE: 'Autonomous Region',
        CNTRY: 'China'
      },
      id: 'CN-NX'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [92.4049, 39.0413],
            [92.9229, 38.9036],
            [93.113, 38.8293],
            [93.2794, 38.6711],
            [93.6716, 38.7176],
            [93.8498, 38.5686],
            [94.2895, 38.6152],
            [94.551, 38.2788],
            [94.8837, 38.3068],
            [95.3115, 38.2975],
            [95.573, 38.1944],
            [96.1196, 38.2131],
            [96.3455, 38.1005],
            [96.5475, 38.1005],
            [96.7614, 38.1944],
            [96.9597, 38.3383],
            [96.9922, 38.5905],
            [96.9578, 38.8791],
            [97.0258, 39.2026],
            [97.3465, 39.1666],
            [98.0153, 38.8579],
            [98.0875, 38.7961],
            [98.3831, 39.0286],
            [98.5812, 38.933],
            [98.8181, 39.0839],
            [99.3606, 38.7176],
            [99.4464, 38.605],
            [99.6777, 38.4364],
            [100.0821, 38.2805],
            [100.0812, 38.4097],
            [100.2175, 38.4562],
            [100.3312, 38.3193],
            [100.5936, 38.2468],
            [100.6304, 38.103],
            [101.0965, 37.9439],
            [101.7547, 37.5284],
            [101.8661, 37.6723],
            [102.0752, 37.5738],
            [102.0124, 37.4747],
            [102.2074, 37.4233],
            [102.5897, 37.1668],
            [102.4546, 36.9751],
            [102.7237, 36.7666],
            [102.719, 36.6153],
            [102.8331, 36.3436],
            [103.0222, 36.257],
            [102.9327, 36.1077],
            [102.9482, 35.8366],
            [102.7833, 35.8624],
            [102.6884, 35.8025],
            [102.7566, 35.6199],
            [102.7204, 35.5279],
            [102.5351, 35.5654],
            [102.4276, 35.4305],
            [102.2823, 35.3818],
            [102.3839, 35.2382],
            [102.2791, 35.0586],
            [102.1284, 35.0164],
            [101.9164, 34.8804],
            [101.9144, 34.743],
            [101.7187, 34.7043],
            [102, 34.5437],
            [102.1525, 34.5097],
            [102.1813, 34.359],
            [102.064, 34.2874],
            [101.9486, 34.1077],
            [101.7377, 34.0777],
            [101.6172, 34.1841],
            [101.3206, 34.2487],
            [101.2588, 34.3],
            [100.8974, 34.3797],
            [100.7664, 34.1802],
            [100.9884, 33.9034],
            [101.1532, 33.8412],
            [101.2406, 33.6823],
            [101.5893, 33.6726],
            [101.6298, 33.501],
            [101.7742, 33.5481],
            [101.6503, 33.3231],
            [101.7343, 33.278],
            [101.6472, 33.1146],
            [101.4728, 33.2293],
            [101.1563, 33.1541],
            [101.12, 32.9126],
            [101.2351, 32.8284],
            [101.195, 32.6908],
            [100.9306, 32.6048],
            [100.68, 32.6812],
            [100.5335, 32.5812],
            [100.4011, 32.7577],
            [100.135, 32.7319],
            [100.1092, 32.8592],
            [99.8622, 32.9409],
            [99.7071, 32.7624],
            [99.3603, 32.9118],
            [99.1883, 33.0382],
            [98.8524, 33.1593],
            [98.7627, 33.2852],
            [98.7412, 33.4826],
            [98.6627, 33.6522],
            [98.4661, 33.851],
            [98.0237, 33.9514],
            [97.8226, 33.8596],
            [97.7808, 33.9761],
            [97.3891, 33.8931],
            [97.4295, 33.6889],
            [97.6254, 33.3443],
            [97.4873, 33.1683],
            [97.5438, 33.0458],
            [97.3562, 32.9119],
            [97.4265, 32.7047],
            [97.73, 32.5296],
            [97.6726, 32.4773],
            [97.3694, 32.5286],
            [97.4164, 32.3106],
            [97.2871, 32.2868],
            [97.2496, 32.0839],
            [97.166, 32.0332],
            [96.9547, 32.0684],
            [96.7594, 31.853],
            [96.8387, 31.7269],
            [96.5474, 31.7252],
            [96.318, 31.9429],
            [96.1893, 31.8962],
            [96.1017, 31.7141],
            [95.8695, 31.7226],
            [95.6284, 31.7848],
            [95.521, 31.7514],
            [95.372, 31.9644],
            [95.4334, 32.16],
            [95.3073, 32.2553],
            [95.0809, 32.2504],
            [94.8959, 32.4624],
            [94.6169, 32.6729],
            [94.3628, 32.5231],
            [94.2025, 32.5185],
            [94.1358, 32.4359],
            [93.8126, 32.555],
            [93.654, 32.575],
            [93.5198, 32.4783],
            [93.38, 32.529],
            [93.2464, 32.6629],
            [93.0628, 32.6308],
            [93.0126, 32.7458],
            [92.871, 32.6999],
            [92.4564, 32.7666],
            [92.2649, 32.7219],
            [92.1429, 32.8863],
            [91.9672, 32.8201],
            [91.5611, 33.054],
            [91.3719, 33.2518],
            [90.9829, 33.2427],
            [90.837, 33.1121],
            [90.6993, 33.1419],
            [90.4907, 33.2657],
            [90.3717, 33.2729],
            [90.2384, 33.4065],
            [90.2298, 33.5055],
            [89.9871, 33.6332],
            [89.928, 33.8045],
            [89.7906, 33.8713],
            [89.6432, 34.0539],
            [89.8117, 34.1685],
            [89.8124, 34.5465],
            [89.7279, 34.7257],
            [89.8657, 34.8186],
            [89.8005, 34.9194],
            [89.564, 34.9337],
            [89.5881, 35.1144],
            [89.4519, 35.2317],
            [89.497, 35.3659],
            [89.6881, 35.4218],
            [89.7962, 35.8531],
            [89.4207, 35.9233],
            [89.502, 36.0272],
            [89.7313, 36.0847],
            [89.9507, 36.0777],
            [90.0083, 36.2792],
            [90.233, 36.1652],
            [90.6809, 36.1314],
            [90.8437, 36.0214],
            [90.9739, 36.1067],
            [91.0989, 36.0948],
            [91.022, 36.331],
            [91.0278, 36.5371],
            [90.7301, 36.5914],
            [90.726, 36.8264],
            [90.8473, 36.9191],
            [91.0112, 36.9256],
            [91.1713, 37.0281],
            [91.1855, 37.2129],
            [91.0858, 37.4698],
            [90.9373, 37.4804],
            [90.8741, 37.5799],
            [90.6615, 37.7041],
            [90.5197, 37.7311],
            [90.4043, 37.8858],
            [90.4818, 37.9557],
            [90.3419, 38.1181],
            [90.1503, 38.4332],
            [90.4199, 38.4959],
            [90.7659, 38.6636],
            [91.2963, 38.7534],
            [91.436, 38.8157],
            [91.8633, 38.8828],
            [92.4049, 39.0413]
          ]
        ]
      },
      properties: {
        name: 'Qinghai',
        id: 'CN-QH',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-QH'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [101.7742, 33.5481],
            [101.9389, 33.4457],
            [101.7694, 33.2566],
            [101.8728, 33.1105],
            [102.1044, 33.2687],
            [102.2605, 33.4197],
            [102.4771, 33.4644],
            [102.335, 33.6202],
            [102.1849, 33.9523],
            [102.3858, 33.9735],
            [102.4259, 34.0904],
            [102.7307, 34.2667],
            [102.9476, 34.2936],
            [103.107, 34.1784],
            [103.1501, 33.8118],
            [103.3472, 33.745],
            [103.7662, 33.6603],
            [104.0436, 33.6879],
            [104.2643, 33.3935],
            [104.375, 33.111],
            [104.2854, 32.9417],
            [104.3002, 32.8314],
            [104.633, 32.6643],
            [105.0396, 32.647],
            [105.1137, 32.594],
            [105.4535, 32.7393],
            [105.3776, 32.8755],
            [105.4954, 32.9107],
            [105.5925, 32.6999],
            [105.8231, 32.8219],
            [106.0417, 32.8684],
            [106.1108, 32.7268],
            [106.2702, 32.6679],
            [106.6067, 32.6628],
            [106.738, 32.7339],
            [107.0944, 32.6515],
            [107.0806, 32.5316],
            [107.226, 32.4183],
            [107.3731, 32.5409],
            [107.5206, 32.3847],
            [107.6538, 32.4027],
            [107.846, 32.2086],
            [108.2341, 32.2787],
            [108.476, 32.272],
            [108.5145, 32.212],
            [108.4439, 32.0705],
            [108.261, 31.9713],
            [108.5272, 31.7628],
            [108.1785, 31.3348],
            [107.9414, 30.9829],
            [107.869, 30.8088],
            [107.5074, 30.8417],
            [107.4199, 30.7448],
            [107.5058, 30.6402],
            [107.38, 30.4967],
            [107.2276, 30.2274],
            [107.0164, 30.0375],
            [106.7459, 30.0302],
            [106.5605, 30.3069],
            [106.2355, 30.1874],
            [106.1394, 30.32],
            [105.8242, 30.4346],
            [105.7256, 30.257],
            [105.5347, 30.174],
            [105.6878, 30.0427],
            [105.7246, 29.8563],
            [105.5621, 29.7337],
            [105.4118, 29.683],
            [105.2877, 29.5411],
            [105.4311, 29.4067],
            [105.4536, 29.2916],
            [105.6916, 29.2892],
            [105.7163, 29.1037],
            [105.7999, 28.9412],
            [105.8999, 28.9026],
            [106.2133, 28.8964],
            [106.3296, 28.5575],
            [106.3735, 28.5305],
            [106.1078, 28.632],
            [105.9691, 28.7551],
            [105.8902, 28.6022],
            [105.6902, 28.5951],
            [105.6179, 28.4881],
            [105.6369, 28.317],
            [105.7748, 28.3357],
            [105.8836, 28.2482],
            [105.88, 28.1276],
            [106.1459, 28.1676],
            [106.3173, 27.9763],
            [106.3319, 27.8259],
            [106.191, 27.7582],
            [105.7265, 27.7109],
            [105.631, 27.661],
            [105.4823, 27.7769],
            [105.3002, 27.7091],
            [105.1814, 28.0655],
            [104.9737, 28.0632],
            [104.8757, 27.9124],
            [104.5785, 27.8467],
            [104.3039, 28.0481],
            [104.4383, 28.1212],
            [104.4562, 28.251],
            [104.2534, 28.397],
            [104.2312, 28.6461],
            [103.9494, 28.6059],
            [103.7827, 28.5242],
            [103.8641, 28.3067],
            [103.7084, 28.2063],
            [103.6319, 28.264],
            [103.4474, 28.1281],
            [103.5049, 27.9713],
            [103.4914, 27.8155],
            [103.2744, 27.633],
            [103.1366, 27.4255],
            [102.8818, 27.3033],
            [102.8674, 27.0322],
            [102.9796, 26.7991],
            [103.0565, 26.5382],
            [102.9818, 26.3531],
            [102.7424, 26.2789],
            [102.6267, 26.3392],
            [102.3822, 26.2968],
            [102.0952, 26.0721],
            [101.7987, 26.1627],
            [101.6888, 26.2446],
            [101.6346, 26.4004],
            [101.3949, 26.559],
            [101.4854, 26.6766],
            [101.3621, 26.7757],
            [101.3666, 26.8875],
            [101.1434, 27.0279],
            [101.1663, 27.2062],
            [101.057, 27.2902],
            [100.8792, 27.6114],
            [100.6383, 27.9174],
            [100.5478, 27.8151],
            [100.3077, 27.866],
            [100.0801, 28.042],
            [100.0354, 28.1852],
            [100.1719, 28.224],
            [99.9425, 28.5869],
            [99.6218, 28.8127],
            [99.5191, 28.5855],
            [99.3793, 28.5364],
            [99.3902, 28.2298],
            [99.3123, 28.2189],
            [99.1734, 28.4154],
            [99.1816, 28.5899],
            [99.1077, 28.8095],
            [99.1123, 29.2511],
            [99.052, 29.5601],
            [98.9939, 29.673],
            [99.0711, 29.9314],
            [98.9853, 30.1589],
            [98.9745, 30.3904],
            [98.9035, 30.6915],
            [98.9616, 30.7627],
            [98.7809, 30.8948],
            [98.7989, 31.0099],
            [98.6023, 31.1927],
            [98.6345, 31.3402],
            [98.7801, 31.255],
            [98.8833, 31.3803],
            [98.6209, 31.5934],
            [98.4148, 31.8341],
            [98.432, 32.0136],
            [98.2253, 32.2329],
            [98.1954, 32.3627],
            [97.9691, 32.4749],
            [97.73, 32.5296],
            [97.4265, 32.7047],
            [97.3562, 32.9119],
            [97.5438, 33.0458],
            [97.4873, 33.1683],
            [97.6254, 33.3443],
            [97.4295, 33.6889],
            [97.3891, 33.8931],
            [97.7808, 33.9761],
            [97.8226, 33.8596],
            [98.0237, 33.9514],
            [98.4661, 33.851],
            [98.6627, 33.6522],
            [98.7412, 33.4826],
            [98.7627, 33.2852],
            [98.8524, 33.1593],
            [99.1883, 33.0382],
            [99.3603, 32.9118],
            [99.7071, 32.7624],
            [99.8622, 32.9409],
            [100.1092, 32.8592],
            [100.135, 32.7319],
            [100.4011, 32.7577],
            [100.5335, 32.5812],
            [100.68, 32.6812],
            [100.9306, 32.6048],
            [101.195, 32.6908],
            [101.2351, 32.8284],
            [101.12, 32.9126],
            [101.1563, 33.1541],
            [101.4728, 33.2293],
            [101.6472, 33.1146],
            [101.7343, 33.278],
            [101.6503, 33.3231],
            [101.7742, 33.5481]
          ]
        ]
      },
      properties: {
        name: 'Sichuan',
        id: 'CN-SC',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-SC'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [119.294, 35.0973],
            [118.856, 35.0341],
            [118.8581, 34.9522],
            [118.6829, 34.685],
            [118.5194, 34.7174],
            [118.4237, 34.6098],
            [118.3997, 34.438],
            [118.1984, 34.387],
            [118.0748, 34.5596],
            [118.0926, 34.6516],
            [117.8042, 34.6513],
            [117.5565, 34.4672],
            [117.3526, 34.5946],
            [117.2026, 34.459],
            [116.97, 34.8701],
            [116.7794, 34.9441],
            [116.4428, 34.9006],
            [116.3686, 34.6409],
            [116.1859, 34.5795],
            [116.0955, 34.6067],
            [115.8246, 34.5641],
            [115.5314, 34.579],
            [115.425, 34.7963],
            [115.1086, 35.011],
            [114.9136, 34.9749],
            [114.8411, 35.175],
            [115.0193, 35.3744],
            [115.3447, 35.4886],
            [115.4933, 35.7285],
            [115.6511, 35.7391],
            [115.9177, 35.9697],
            [115.8032, 36.0101],
            [115.3277, 35.8197],
            [115.4404, 36.0132],
            [115.4728, 36.1694],
            [115.416, 36.3235],
            [115.2939, 36.4121],
            [115.3366, 36.613],
            [115.4805, 36.762],
            [115.6817, 36.8152],
            [115.8636, 37.0716],
            [115.9789, 37.3328],
            [116.1588, 37.3785],
            [116.3624, 37.5618],
            [116.4523, 37.5129],
            [116.7944, 37.8313],
            [117.4315, 37.8487],
            [117.5744, 38.0684],
            [117.7004, 38.0777],
            [117.8655, 38.2601],
            [118.1232, 38.1443],
            [118.4743, 38.1121],
            [118.8448, 38.1448],
            [118.9484, 38.0974],
            [119.086, 37.8385],
            [119.0509, 37.6904],
            [118.9279, 37.5304],
            [118.9309, 37.3415],
            [119.2426, 37.059],
            [119.749, 37.1229],
            [119.894, 37.2504],
            [119.8388, 37.3718],
            [120.0848, 37.444],
            [120.294, 37.5818],
            [120.4446, 37.7443],
            [120.742, 37.8324],
            [120.8773, 37.8324],
            [121.0057, 37.7182],
            [121.1385, 37.7123],
            [121.1752, 37.5823],
            [121.37, 37.5757],
            [121.5724, 37.4324],
            [121.9396, 37.4712],
            [122.0737, 37.5368],
            [122.1682, 37.4285],
            [122.4814, 37.431],
            [122.6241, 37.1934],
            [122.4904, 37.1556],
            [122.5357, 37.0249],
            [122.3613, 36.8399],
            [121.9671, 36.9607],
            [121.7518, 36.8457],
            [121.1707, 36.6779],
            [120.9104, 36.4521],
            [120.6865, 36.3985],
            [120.7132, 36.1343],
            [120.4227, 36.0568],
            [120.365, 36.1712],
            [120.1696, 36.2048],
            [120.1045, 36.1012],
            [120.2085, 35.9501],
            [120.051, 35.8784],
            [120.0137, 35.7224],
            [119.9156, 35.6376],
            [119.6399, 35.5674],
            [119.5607, 35.3987],
            [119.4021, 35.2623],
            [119.294, 35.0973]
          ]
        ]
      },
      properties: {
        name: 'Shandong',
        id: 'CN-SD',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-SD'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [121.2649, 30.6895],
              [120.995, 31.0015],
              [120.8951, 31.012],
              [120.8763, 31.132],
              [121.0467, 31.1398],
              [121.1518, 31.4409],
              [121.326, 31.5053],
              [121.4163, 31.4806],
              [121.7453, 31.2608],
              [121.8624, 31.1135],
              [121.9176, 30.8596],
              [121.5299, 30.823],
              [121.2649, 30.6895]
            ]
          ],
          [
            [
              [121.2957, 31.8693],
              [121.6701, 31.6479],
              [121.9677, 31.5418],
              [121.8292, 31.439],
              [121.3785, 31.6221],
              [121.1607, 31.7882],
              [121.2957, 31.8693]
            ]
          ]
        ]
      },
      properties: {
        name: 'Shanghai',
        id: 'CN-SH',
        TYPE: 'Municipality',
        CNTRY: 'China'
      },
      id: 'CN-SH'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [107.6517, 37.8744],
            [107.9662, 37.7919],
            [108.0144, 37.6659],
            [108.7786, 37.684],
            [108.7767, 37.943],
            [108.9379, 37.9213],
            [109.0519, 38.0861],
            [108.9296, 38.1812],
            [108.9697, 38.3382],
            [109.1539, 38.5589],
            [109.3947, 38.7129],
            [109.421, 38.8036],
            [109.6736, 38.9315],
            [109.7135, 39.0657],
            [109.9528, 39.1873],
            [110.5162, 39.3835],
            [110.6153, 39.2488],
            [110.8722, 39.4964],
            [111.0238, 39.5655],
            [111.1338, 39.3678],
            [111.2052, 39.2758],
            [111.1206, 39.0525],
            [110.9749, 38.976],
            [111.0137, 38.8786],
            [110.8831, 38.6506],
            [110.8734, 38.4624],
            [110.5793, 38.3022],
            [110.5026, 38.1799],
            [110.5131, 37.9611],
            [110.5926, 37.9153],
            [110.7857, 37.5511],
            [110.7011, 37.343],
            [110.4252, 36.9641],
            [110.433, 36.6846],
            [110.5001, 36.5812],
            [110.4446, 36.1649],
            [110.6084, 35.6308],
            [110.5161, 35.4053],
            [110.4021, 35.2775],
            [110.2706, 34.9688],
            [110.2429, 34.694],
            [110.3803, 34.6045],
            [110.3594, 34.5088],
            [110.4951, 34.3368],
            [110.4366, 34.2472],
            [110.6712, 33.9541],
            [110.6029, 33.8597],
            [110.7721, 33.8019],
            [110.8273, 33.6748],
            [111.0023, 33.5742],
            [111.0243, 33.3411],
            [110.9777, 33.2597],
            [110.8127, 33.1527],
            [110.2272, 33.1609],
            [109.8471, 33.2498],
            [109.5021, 33.2323],
            [109.4337, 33.1538],
            [109.7863, 33.0718],
            [109.7589, 32.9097],
            [110.0214, 32.8749],
            [110.1356, 32.8107],
            [110.1599, 32.5959],
            [110.0125, 32.5491],
            [109.9057, 32.595],
            [109.6145, 32.5954],
            [109.4904, 32.3026],
            [109.6175, 32.105],
            [109.5767, 31.7332],
            [109.2845, 31.7188],
            [109.2708, 31.8042],
            [109.0299, 31.9669],
            [108.8836, 31.9967],
            [108.5145, 32.212],
            [108.476, 32.272],
            [108.2341, 32.2787],
            [107.846, 32.2086],
            [107.6538, 32.4027],
            [107.5206, 32.3847],
            [107.3731, 32.5409],
            [107.226, 32.4183],
            [107.0806, 32.5316],
            [107.0944, 32.6515],
            [106.738, 32.7339],
            [106.6067, 32.6628],
            [106.2702, 32.6679],
            [106.1108, 32.7268],
            [106.0417, 32.8684],
            [105.8231, 32.8219],
            [105.5925, 32.6999],
            [105.4954, 32.9107],
            [105.6347, 32.8815],
            [105.9137, 32.996],
            [105.9135, 33.2399],
            [105.7922, 33.2619],
            [105.8401, 33.4953],
            [105.9554, 33.6101],
            [106.183, 33.5481],
            [106.427, 33.6206],
            [106.4893, 33.8477],
            [106.4153, 33.8956],
            [106.4977, 34.1064],
            [106.5886, 34.1425],
            [106.714, 34.3745],
            [106.5675, 34.4903],
            [106.3091, 34.581],
            [106.5662, 34.8163],
            [106.489, 34.942],
            [106.5457, 35.0877],
            [106.9161, 35.0952],
            [107.1963, 34.8826],
            [107.2907, 34.9373],
            [107.5277, 34.9124],
            [107.8017, 34.9569],
            [107.6486, 35.2447],
            [107.7824, 35.3032],
            [107.9569, 35.243],
            [108.1721, 35.3104],
            [108.4863, 35.2855],
            [108.6042, 35.3423],
            [108.6181, 35.5523],
            [108.5214, 35.6462],
            [108.497, 35.8853],
            [108.6477, 35.9484],
            [108.7076, 36.1383],
            [108.6448, 36.2621],
            [108.709, 36.3695],
            [108.3435, 36.556],
            [108.1473, 36.561],
            [107.947, 36.656],
            [107.8954, 36.7601],
            [107.5373, 36.8275],
            [107.4803, 36.9062],
            [107.3044, 36.916],
            [107.2714, 37.0823],
            [107.3004, 37.6114],
            [107.6246, 37.7817],
            [107.6517, 37.8744]
          ]
        ]
      },
      properties: {
        name: 'Shaanxi',
        id: 'CN-SN',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-SN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [114.1279, 40.7358],
            [114.2765, 40.5894],
            [114.3079, 40.3683],
            [114.4446, 40.2582],
            [114.072, 40.1847],
            [114.0527, 39.918],
            [114.3864, 39.8672],
            [114.4016, 39.6506],
            [114.5454, 39.5475],
            [114.3446, 39.087],
            [114.1021, 39.0512],
            [114.0368, 39.1364],
            [113.7623, 38.9693],
            [113.8491, 38.8284],
            [113.7032, 38.6547],
            [113.6063, 38.6455],
            [113.5382, 38.27],
            [113.8275, 38.1583],
            [113.9676, 37.8214],
            [114.1334, 37.675],
            [114.112, 37.5897],
            [113.8257, 37.1671],
            [113.7886, 36.9946],
            [113.6746, 36.7899],
            [113.4725, 36.6404],
            [113.5486, 36.4961],
            [113.7244, 36.3601],
            [113.6471, 36.1234],
            [113.6507, 35.8369],
            [113.5691, 35.8178],
            [113.4905, 35.5306],
            [113.2933, 35.4253],
            [113.024, 35.3634],
            [112.9218, 35.2499],
            [112.328, 35.2169],
            [112.0561, 35.2819],
            [112.0554, 35.0646],
            [111.8223, 35.0725],
            [111.6675, 34.9908],
            [111.5667, 34.8488],
            [111.221, 34.7919],
            [110.6824, 34.5941],
            [110.3803, 34.6045],
            [110.2429, 34.694],
            [110.2706, 34.9688],
            [110.4021, 35.2775],
            [110.5161, 35.4053],
            [110.6084, 35.6308],
            [110.4446, 36.1649],
            [110.5001, 36.5812],
            [110.433, 36.6846],
            [110.4252, 36.9641],
            [110.7011, 37.343],
            [110.7857, 37.5511],
            [110.5926, 37.9153],
            [110.5131, 37.9611],
            [110.5026, 38.1799],
            [110.5793, 38.3022],
            [110.8734, 38.4624],
            [110.8831, 38.6506],
            [111.0137, 38.8786],
            [110.9749, 38.976],
            [111.1206, 39.0525],
            [111.2052, 39.2758],
            [111.1338, 39.3678],
            [111.3481, 39.4287],
            [111.4977, 39.6587],
            [111.7606, 39.59],
            [111.9297, 39.6144],
            [111.9746, 39.805],
            [112.3028, 40.2515],
            [112.4555, 40.2978],
            [112.7439, 40.1669],
            [112.8863, 40.3254],
            [113.2444, 40.4121],
            [113.3099, 40.3203],
            [113.5531, 40.3476],
            [113.7621, 40.4758],
            [114.0551, 40.5274],
            [114.1279, 40.7358]
          ]
        ]
      },
      properties: {
        name: 'Shanxi',
        id: 'CN-SX',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-SX'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [117.3797, 40.2268],
            [117.5417, 40.2283],
            [117.7111, 39.9868],
            [117.5431, 39.9819],
            [117.5488, 39.7726],
            [117.6935, 39.5805],
            [117.933, 39.5762],
            [117.8693, 39.4485],
            [118.0104, 39.3619],
            [118.0674, 39.2212],
            [117.916, 39.2077],
            [117.7318, 39.0932],
            [117.7169, 38.9682],
            [117.5721, 38.7535],
            [117.5643, 38.6557],
            [117.2557, 38.5694],
            [117.1021, 38.5865],
            [117.0192, 38.7021],
            [116.7508, 38.7402],
            [116.7066, 38.933],
            [116.9015, 39.0642],
            [116.8563, 39.4271],
            [116.7994, 39.61],
            [116.8943, 39.6963],
            [117.1524, 39.6267],
            [117.1315, 39.9218],
            [117.1939, 40.0828],
            [117.3797, 40.2268]
          ]
        ]
      },
      properties: {
        name: 'Tianjin',
        id: 'CN-TJ',
        TYPE: 'Municipality',
        CNTRY: 'China'
      },
      id: 'CN-TJ'
    },
    
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [96.3652, 42.7171],
            [96.0966, 42.5997],
            [95.9897, 41.8943],
            [95.6866, 41.8221],
            [95.5391, 41.8546],
            [95.0816, 41.7793],
            [94.6799, 41.6222],
            [94.4174, 41.3919],
            [94.0027, 41.0895],
            [93.8068, 40.8766],
            [93.6392, 40.6137],
            [93.354, 40.4725],
            [92.9138, 40.4983],
            [92.918, 40.1037],
            [92.7641, 39.8798],
            [92.8751, 39.5485],
            [92.9437, 39.4562],
            [92.9506, 39.171],
            [92.5001, 39.1094],
            [92.4049, 39.0413],
            [91.8633, 38.8828],
            [91.436, 38.8157],
            [91.2963, 38.7534],
            [90.7659, 38.6636],
            [90.4199, 38.4959],
            [90.1503, 38.4332],
            [90.3419, 38.1181],
            [90.4818, 37.9557],
            [90.4043, 37.8858],
            [90.5197, 37.7311],
            [90.6615, 37.7041],
            [90.8741, 37.5799],
            [90.9373, 37.4804],
            [91.0858, 37.4698],
            [91.1855, 37.2129],
            [91.1713, 37.0281],
            [91.0112, 36.9256],
            [90.8473, 36.9191],
            [90.726, 36.8264],
            [90.7301, 36.5914],
            [91.0278, 36.5371],
            [91.022, 36.331],
            [91.0989, 36.0948],
            [90.9739, 36.1067],
            [90.8437, 36.0214],
            [90.6809, 36.1314],
            [90.233, 36.1652],
            [90.0083, 36.2792],
            [89.9507, 36.0777],
            [89.7313, 36.0847],
            [89.4877, 36.1514],
            [89.2342, 36.2952],
            [89.1286, 36.2534],
            [88.9236, 36.3649],
            [88.7657, 36.3018],
            [88.468, 36.4825],
            [87.9803, 36.4382],
            [87.8895, 36.3707],
            [87.5679, 36.343],
            [87.3762, 36.4168],
            [87.1466, 36.2977],
            [86.7561, 36.2951],
            [86.1958, 36.1294],
            [86.0523, 35.8461],
            [85.9533, 35.7845],
            [85.5241, 35.7117],
            [85.2663, 35.7805],
            [85.0322, 35.747],
            [84.8364, 35.6402],
            [84.4511, 35.5484],
            [84.4498, 35.4787],
            [84.1786, 35.359],
            [84.0567, 35.4115],
            [83.8686, 35.362],
            [83.539, 35.3425],
            [83.2394, 35.4207],
            [83.1237, 35.3992],
            [82.9962, 35.4853],
            [82.9582, 35.6723],
            [82.4124, 35.7083],
            [82.3169, 35.5541],
            [82.0456, 35.4498],
            [82.0134, 35.3287],
            [81.8612, 35.2606],
            [81.5529, 35.2449],
            [81.4363, 35.3591],
            [81.0235, 35.3094],
            [80.6871, 35.34],
            [80.464, 35.4602],
            [80.2935, 35.3607],
            [80.2951, 35.2659],
            [80.0504, 35.0601],
            [79.992, 34.8621],
            [79.7992, 34.4806],
            [79.6442, 34.4486],
            [79.1511, 34.4442],
            [79.0373, 34.3363],
            [78.9274, 34.3716],
            [78.5693, 34.6119],
            [78.4181, 34.6054],
            [78.2316, 34.7168],
            [78.0829, 35.0825],
            [77.997, 35.3941],
            [77.8157, 35.5179],
            [77.4012, 35.4685],
            [77.1867, 35.5283],
            [76.596, 35.7849],
            [76.5673, 35.9178],
            [76.3488, 35.8335],
            [76.2218, 35.851],
            [76.0192, 36.1667],
            [76.0278, 36.3976],
            [75.8524, 36.6753],
            [75.6525, 36.7688],
            [75.4545, 36.7216],
            [75.3889, 36.9101],
            [75.1469, 37.0199],
            [74.9224, 36.9346],
            [74.742, 37.022],
            [74.5692, 37.0339],
            [74.4331, 37.1158],
            [74.4891, 37.2419],
            [74.8061, 37.2138],
            [75.1163, 37.3202],
            [75.1366, 37.4311],
            [74.928, 37.5562],
            [74.8966, 37.6576],
            [74.9146, 38.009],
            [74.7858, 38.1856],
            [74.7792, 38.345],
            [74.8621, 38.5042],
            [74.3656, 38.6549],
            [74.1245, 38.6647],
            [74.0698, 38.5311],
            [73.7812, 38.612],
            [73.7449, 39.0304],
            [73.5577, 39.2592],
            [73.8424, 39.4639],
            [73.938, 39.594],
            [73.8313, 39.7713],
            [74.0388, 40.0887],
            [74.3404, 40.0838],
            [74.7035, 40.3461],
            [74.8281, 40.5248],
            [74.9879, 40.4505],
            [75.2191, 40.4414],
            [75.5931, 40.6588],
            [75.6999, 40.2773],
            [76.0908, 40.4075],
            [76.3318, 40.3596],
            [76.5213, 40.4626],
            [76.6465, 40.6306],
            [76.634, 40.7573],
            [76.7871, 40.8513],
            [76.8641, 41.021],
            [77.0473, 41.0597],
            [77.6638, 40.9986],
            [77.8775, 41.0732],
            [78.0594, 41.0328],
            [78.19, 41.079],
            [78.3855, 41.271],
            [78.3924, 41.4104],
            [78.6399, 41.4696],
            [79.2637, 41.7839],
            [79.6705, 41.7901],
            [79.8446, 42.0174],
            [80.2826, 42.0597],
            [80.2875, 42.2716],
            [80.2085, 42.4277],
            [80.2775, 42.5157],
            [80.1717, 42.6595],
            [80.2583, 42.8284],
            [80.5908, 42.8957],
            [80.3947, 43.047],
            [80.7772, 43.1488],
            [80.7457, 43.4588],
            [80.5197, 43.8342],
            [80.4119, 44.1499],
            [80.3428, 44.4812],
            [80.4824, 44.7328],
            [80.2795, 44.8397],
            [80.0303, 44.8013],
            [79.9604, 44.879],
            [80.053, 45.0139],
            [80.381, 45.0446],
            [80.4702, 45.1178],
            [80.729, 45.1737],
            [80.8907, 45.1305],
            [81.1054, 45.2192],
            [81.6896, 45.3666],
            [81.8183, 45.2049],
            [81.9545, 45.1568],
            [82.2529, 45.236],
            [82.4862, 45.1206],
            [82.5925, 45.2315],
            [82.6036, 45.4316],
            [82.2918, 45.5322],
            [82.308, 45.6715],
            [82.5044, 45.9834],
            [82.529, 46.1841],
            [82.739, 46.5176],
            [82.8073, 46.7496],
            [83.0309, 47.2121],
            [83.3512, 47.174],
            [83.5804, 47.0584],
            [84.0382, 46.9654],
            [84.4292, 47.0104],
            [84.686, 47.0047],
            [84.8059, 46.8267],
            [84.9626, 46.8716],
            [85.2232, 47.0536],
            [85.5297, 47.0594],
            [85.6968, 47.2309],
            [85.7072, 47.3755],
            [85.5416, 47.9394],
            [85.7355, 48.371],
            [85.8507, 48.4278],
            [86.2144, 48.4354],
            [86.5885, 48.543],
            [86.7631, 48.7148],
            [86.8124, 48.8429],
            [86.7232, 48.9906],
            [86.8648, 49.1126],
            [87.1346, 49.1508],
            [87.4183, 49.0831],
            [87.8323, 49.175],
            [87.7667, 48.9368],
            [87.8034, 48.8131],
            [88.0849, 48.707],
            [88.0001, 48.5705],
            [88.3367, 48.4782],
            [88.6079, 48.3342],
            [88.5882, 48.2304],
            [88.8991, 48.1198],
            [89.2066, 47.9519],
            [89.3691, 48.0433],
            [89.5659, 48.0487],
            [89.7964, 47.8181],
            [89.9441, 47.8168],
            [90.3374, 47.657],
            [90.4569, 47.4871],
            [90.5179, 47.2442],
            [90.7343, 47.0015],
            [90.9136, 46.9533],
            [91.0624, 46.7018],
            [91.0777, 46.5639],
            [90.9118, 46.3051],
            [91.0221, 46.0228],
            [90.7043, 45.7093],
            [90.6637, 45.5169],
            [90.7962, 45.3788],
            [90.8787, 45.1919],
            [91.1325, 45.2021],
            [91.5926, 45.0616],
            [92.0165, 45.0693],
            [92.5146, 45.01],
            [93.0698, 45.0185],
            [93.5083, 44.9653],
            [94.1974, 44.6625],
            [94.3256, 44.5271],
            [94.576, 44.4483],
            [94.6955, 44.3477],
            [94.9905, 44.2545],
            [95.4237, 44.2893],
            [95.345, 44.0453],
            [95.5508, 43.9886],
            [95.6897, 43.6797],
            [95.8811, 43.4021],
            [95.894, 43.266],
            [96.3245, 42.8824],
            [96.3652, 42.7171]
          ]
        ]
      },
      properties: {
        name: 'Xinjiang Uygur',
        id: 'CN-XJ',
        TYPE: 'Autonomous Region',
        CNTRY: 'China'
      },
      id: 'CN-XJ'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [78.9274, 34.3716],
            [79.0373, 34.3363],
            [79.1511, 34.4442],
            [79.6442, 34.4486],
            [79.7992, 34.4806],
            [79.992, 34.8621],
            [80.0504, 35.0601],
            [80.2951, 35.2659],
            [80.2935, 35.3607],
            [80.464, 35.4602],
            [80.6871, 35.34],
            [81.0235, 35.3094],
            [81.4363, 35.3591],
            [81.5529, 35.2449],
            [81.8612, 35.2606],
            [82.0134, 35.3287],
            [82.0456, 35.4498],
            [82.3169, 35.5541],
            [82.4124, 35.7083],
            [82.9582, 35.6723],
            [82.9962, 35.4853],
            [83.1237, 35.3992],
            [83.2394, 35.4207],
            [83.539, 35.3425],
            [83.8686, 35.362],
            [84.0567, 35.4115],
            [84.1786, 35.359],
            [84.4498, 35.4787],
            [84.4511, 35.5484],
            [84.8364, 35.6402],
            [85.0322, 35.747],
            [85.2663, 35.7805],
            [85.5241, 35.7117],
            [85.9533, 35.7845],
            [86.0523, 35.8461],
            [86.1958, 36.1294],
            [86.7561, 36.2951],
            [87.1466, 36.2977],
            [87.3762, 36.4168],
            [87.5679, 36.343],
            [87.8895, 36.3707],
            [87.9803, 36.4382],
            [88.468, 36.4825],
            [88.7657, 36.3018],
            [88.9236, 36.3649],
            [89.1286, 36.2534],
            [89.2342, 36.2952],
            [89.4877, 36.1514],
            [89.7313, 36.0847],
            [89.502, 36.0272],
            [89.4207, 35.9233],
            [89.7962, 35.8531],
            [89.6881, 35.4218],
            [89.497, 35.3659],
            [89.4519, 35.2317],
            [89.5881, 35.1144],
            [89.564, 34.9337],
            [89.8005, 34.9194],
            [89.8657, 34.8186],
            [89.7279, 34.7257],
            [89.8124, 34.5465],
            [89.8117, 34.1685],
            [89.6432, 34.0539],
            [89.7906, 33.8713],
            [89.928, 33.8045],
            [89.9871, 33.6332],
            [90.2298, 33.5055],
            [90.2384, 33.4065],
            [90.3717, 33.2729],
            [90.4907, 33.2657],
            [90.6993, 33.1419],
            [90.837, 33.1121],
            [90.9829, 33.2427],
            [91.3719, 33.2518],
            [91.5611, 33.054],
            [91.9672, 32.8201],
            [92.1429, 32.8863],
            [92.2649, 32.7219],
            [92.4564, 32.7666],
            [92.871, 32.6999],
            [93.0126, 32.7458],
            [93.0628, 32.6308],
            [93.2464, 32.6629],
            [93.38, 32.529],
            [93.5198, 32.4783],
            [93.654, 32.575],
            [93.8126, 32.555],
            [94.1358, 32.4359],
            [94.2025, 32.5185],
            [94.3628, 32.5231],
            [94.6169, 32.6729],
            [94.8959, 32.4624],
            [95.0809, 32.2504],
            [95.3073, 32.2553],
            [95.4334, 32.16],
            [95.372, 31.9644],
            [95.521, 31.7514],
            [95.6284, 31.7848],
            [95.8695, 31.7226],
            [96.1017, 31.7141],
            [96.1893, 31.8962],
            [96.318, 31.9429],
            [96.5474, 31.7252],
            [96.8387, 31.7269],
            [96.7594, 31.853],
            [96.9547, 32.0684],
            [97.166, 32.0332],
            [97.2496, 32.0839],
            [97.2871, 32.2868],
            [97.4164, 32.3106],
            [97.3694, 32.5286],
            [97.6726, 32.4773],
            [97.73, 32.5296],
            [97.9691, 32.4749],
            [98.1954, 32.3627],
            [98.2253, 32.2329],
            [98.432, 32.0136],
            [98.4148, 31.8341],
            [98.6209, 31.5934],
            [98.8833, 31.3803],
            [98.7801, 31.255],
            [98.6345, 31.3402],
            [98.6023, 31.1927],
            [98.7989, 31.0099],
            [98.7809, 30.8948],
            [98.9616, 30.7627],
            [98.9035, 30.6915],
            [98.9745, 30.3904],
            [98.9853, 30.1589],
            [99.0711, 29.9314],
            [98.9939, 29.673],
            [99.052, 29.5601],
            [99.1123, 29.2511],
            [98.963, 29.1872],
            [99.0192, 29.0556],
            [98.9209, 28.9783],
            [98.6312, 28.975],
            [98.6881, 28.7364],
            [98.5928, 28.6731],
            [98.6216, 28.5052],
            [98.7531, 28.3383],
            [98.7077, 28.231],
            [98.404, 28.1065],
            [98.359, 28.2933],
            [98.1374, 28.1477],
            [97.8976, 28.3748],
            [97.8005, 28.3349],
            [97.7112, 28.5123],
            [97.5775, 28.5433],
            [97.4729, 28.2738],
            [97.3569, 28.2332],
            [97.0926, 28.3697],
            [96.9965, 28.3162],
            [96.7826, 28.3704],
            [96.6645, 28.4658],
            [96.3709, 28.3943],
            [96.4066, 28.5641],
            [96.616, 28.7786],
            [96.5188, 28.971],
            [96.3467, 29.0322],
            [96.2483, 28.9429],
            [96.1145, 29.0776],
            [96.2602, 29.1601],
            [96.1752, 29.3645],
            [96.0806, 29.4634],
            [95.9571, 29.3827],
            [95.7776, 29.3564],
            [95.5858, 29.218],
            [95.4474, 29.0359],
            [95.2507, 29.1099],
            [94.8718, 29.1843],
            [94.6282, 29.3486],
            [94.5579, 29.2289],
            [94.4312, 29.2268],
            [94.2147, 29.0841],
            [94.1551, 28.9548],
            [93.8441, 28.7075],
            [93.3369, 28.6402],
            [93.1826, 28.44],
            [93.219, 28.3337],
            [93.0236, 28.3032],
            [92.7996, 28.2022],
            [92.6629, 28.0628],
            [92.7395, 27.9894],
            [92.5607, 27.821],
            [92.3159, 27.7782],
            [92.2104, 27.8589],
            [91.9188, 27.7151],
            [91.8242, 27.8104],
            [91.6378, 27.8545],
            [91.3206, 28.0901],
            [91.1025, 27.9687],
            [90.7262, 28.0914],
            [90.4572, 28.037],
            [90.6195, 28.2166],
            [89.9049, 28.3028],
            [89.8012, 28.1757],
            [89.6095, 28.157],
            [89.3537, 27.9632],
            [89.1319, 27.6183],
            [88.9775, 27.5023],
            [88.9534, 27.3983],
            [88.8118, 27.4026],
            [88.7673, 27.5668],
            [88.8466, 27.6688],
            [88.8329, 28.0116],
            [88.6165, 28.1037],
            [88.394, 27.9797],
            [88.1347, 27.9629],
            [87.9183, 27.8163],
            [87.5803, 27.8677],
            [87.234, 27.8173],
            [87.0446, 27.9391],
            [86.7982, 28.0189],
            [86.7031, 28.1078],
            [86.427, 27.9661],
            [86.2159, 27.9879],
            [86.127, 27.9385],
            [85.964, 27.983],
            [85.895, 28.1153],
            [85.5643, 28.3136],
            [85.2531, 28.2819],
            [85.1149, 28.3332],
            [85.1932, 28.6182],
            [85.1314, 28.678],
            [84.8582, 28.5684],
            [84.6907, 28.6129],
            [84.6576, 28.7264],
            [84.419, 28.7373],
            [84.3997, 28.8524],
            [84.2605, 28.9135],
            [84.1178, 29.05],
            [84.1972, 29.2116],
            [83.9181, 29.3134],
            [83.6325, 29.1702],
            [83.4425, 29.2886],
            [83.2384, 29.5829],
            [82.7499, 29.7612],
            [82.4978, 30.0075],
            [82.1977, 30.0924],
            [82.1483, 30.3287],
            [82.0065, 30.3356],
            [81.6344, 30.4334],
            [81.4068, 30.4138],
            [81.3852, 30.2037],
            [81.1985, 30.0299],
            [81.011, 30.2628],
            [80.8113, 30.3199],
            [80.5842, 30.4872],
            [80.211, 30.5823],
            [80.1635, 30.81],
            [79.915, 30.8922],
            [79.7422, 31.0068],
            [79.5758, 30.9543],
            [79.2996, 31.1492],
            [79.1289, 31.4387],
            [78.8795, 31.2707],
            [78.7066, 31.5183],
            [78.8311, 31.6152],
            [78.6914, 31.7933],
            [78.771, 31.9999],
            [78.4392, 32.2464],
            [78.5188, 32.4184],
            [78.4122, 32.5561],
            [78.6257, 32.6071],
            [78.7459, 32.6961],
            [78.7812, 32.4753],
            [78.9772, 32.3427],
            [79.1265, 32.4644],
            [79.3122, 32.4871],
            [79.5454, 32.6074],
            [79.5334, 32.7598],
            [79.3525, 32.979],
            [79.4098, 33.1788],
            [79.1854, 33.1913],
            [78.8232, 33.4835],
            [78.711, 33.6549],
            [78.784, 33.7704],
            [78.7064, 34.0721],
            [78.975, 34.2429],
            [78.9274, 34.3716]
          ]
        ]
      },
      properties: {
        name: 'Xizang (Tibet)',
        id: 'CN-XZ',
        TYPE: 'Autonomous Region',
        CNTRY: 'China'
      },
      id: 'CN-XZ'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [99.1123, 29.2511],
            [99.1077, 28.8095],
            [99.1816, 28.5899],
            [99.1734, 28.4154],
            [99.3123, 28.2189],
            [99.3902, 28.2298],
            [99.3793, 28.5364],
            [99.5191, 28.5855],
            [99.6218, 28.8127],
            [99.9425, 28.5869],
            [100.1719, 28.224],
            [100.0354, 28.1852],
            [100.0801, 28.042],
            [100.3077, 27.866],
            [100.5478, 27.8151],
            [100.6383, 27.9174],
            [100.8792, 27.6114],
            [101.057, 27.2902],
            [101.1663, 27.2062],
            [101.1434, 27.0279],
            [101.3666, 26.8875],
            [101.3621, 26.7757],
            [101.4854, 26.6766],
            [101.3949, 26.559],
            [101.6346, 26.4004],
            [101.6888, 26.2446],
            [101.7987, 26.1627],
            [102.0952, 26.0721],
            [102.3822, 26.2968],
            [102.6267, 26.3392],
            [102.7424, 26.2789],
            [102.9818, 26.3531],
            [103.0565, 26.5382],
            [102.9796, 26.7991],
            [102.8674, 27.0322],
            [102.8818, 27.3033],
            [103.1366, 27.4255],
            [103.2744, 27.633],
            [103.4914, 27.8155],
            [103.5049, 27.9713],
            [103.4474, 28.1281],
            [103.6319, 28.264],
            [103.7084, 28.2063],
            [103.8641, 28.3067],
            [103.7827, 28.5242],
            [103.9494, 28.6059],
            [104.2312, 28.6461],
            [104.2534, 28.397],
            [104.4562, 28.251],
            [104.4383, 28.1212],
            [104.3039, 28.0481],
            [104.5785, 27.8467],
            [104.8757, 27.9124],
            [104.9737, 28.0632],
            [105.1814, 28.0655],
            [105.3002, 27.7091],
            [105.258, 27.52],
            [104.8638, 27.2937],
            [104.8139, 27.3546],
            [104.614, 27.3119],
            [104.358, 27.4692],
            [104.2532, 27.3051],
            [103.9315, 27.4444],
            [103.8682, 27.3097],
            [103.6271, 27.1194],
            [103.7744, 26.9544],
            [103.6994, 26.8091],
            [103.8114, 26.5392],
            [104.0076, 26.5192],
            [104.1274, 26.6467],
            [104.4823, 26.5838],
            [104.6779, 26.3767],
            [104.5381, 26.2527],
            [104.516, 26.0947],
            [104.3249, 25.6069],
            [104.4306, 25.4827],
            [104.6391, 25.3643],
            [104.74, 25.2179],
            [104.709, 24.9982],
            [104.5448, 24.8269],
            [104.53, 24.7344],
            [104.4807, 24.6548],
            [104.6118, 24.4121],
            [104.7585, 24.4628],
            [104.941, 24.4113],
            [105.0465, 24.4443],
            [105.2309, 24.1932],
            [105.4828, 24.0255],
            [105.8381, 24.031],
            [105.9986, 24.132],
            [106.0934, 24.0318],
            [106.1888, 23.8274],
            [106.1378, 23.5721],
            [105.9829, 23.4923],
            [105.8229, 23.5183],
            [105.5245, 23.2472],
            [105.5385, 23.1933],
            [105.3256, 23.3921],
            [105.2254, 23.2707],
            [105.0851, 23.2702],
            [104.8719, 23.1257],
            [104.8616, 22.9473],
            [104.7248, 22.8505],
            [104.5768, 22.8525],
            [104.3628, 22.6916],
            [104.2775, 22.8396],
            [104.1138, 22.8111],
            [104.0388, 22.7235],
            [103.9786, 22.5065],
            [103.6596, 22.7877],
            [103.5315, 22.5927],
            [103.3206, 22.8174],
            [103.2795, 22.6784],
            [103.0722, 22.4474],
            [102.9299, 22.4837],
            [102.8624, 22.6113],
            [102.4997, 22.7802],
            [102.2734, 22.4986],
            [102.2621, 22.4139],
            [101.7912, 22.4963],
            [101.689, 22.4715],
            [101.6329, 22.2863],
            [101.547, 22.2481],
            [101.6234, 21.9694],
            [101.7815, 21.8325],
            [101.7403, 21.3149],
            [101.7056, 21.1494],
            [101.5634, 21.2484],
            [101.2851, 21.1787],
            [101.1976, 21.4118],
            [101.2183, 21.5545],
            [101.1172, 21.6615],
            [101.1265, 21.7754],
            [100.8349, 21.6492],
            [100.6786, 21.483],
            [100.4921, 21.4579],
            [100.3813, 21.5404],
            [100.198, 21.4424],
            [99.9477, 21.8217],
            [100, 21.9757],
            [99.6526, 22.1049],
            [99.4764, 22.1375],
            [99.338, 22.0998],
            [99.1663, 22.1601],
            [99.3895, 22.4994],
            [99.3255, 22.7511],
            [99.5635, 22.9387],
            [99.5113, 23.0837],
            [99.3473, 23.1354],
            [99.1098, 23.0938],
            [99.0575, 23.1725],
            [98.8848, 23.1917],
            [98.923, 23.4232],
            [98.8076, 23.5423],
            [98.8916, 23.618],
            [98.8245, 23.7612],
            [98.7063, 23.8314],
            [98.6786, 23.96],
            [98.8397, 24.0505],
            [98.881, 24.1551],
            [98.6082, 24.0948],
            [98.5359, 24.1296],
            [98.1381, 24.1017],
            [97.8853, 24.0102],
            [97.6496, 23.8445],
            [97.6326, 24],
            [97.7579, 24.1699],
            [97.6803, 24.4455],
            [97.5366, 24.4359],
            [97.5545, 24.7413],
            [97.7114, 24.837],
            [97.7313, 25.07],
            [97.8451, 25.2679],
            [97.9491, 25.2237],
            [98.1389, 25.3897],
            [98.1805, 25.5533],
            [98.3607, 25.562],
            [98.483, 25.8083],
            [98.6376, 25.8043],
            [98.7126, 25.9036],
            [98.5728, 26.1191],
            [98.7372, 26.3846],
            [98.7811, 26.6199],
            [98.7578, 26.9132],
            [98.6869, 27.2138],
            [98.7321, 27.3172],
            [98.7037, 27.5662],
            [98.4676, 27.6901],
            [98.3333, 27.5277],
            [98.2213, 27.7322],
            [98.207, 27.9102],
            [98.1384, 27.9688],
            [98.1374, 28.1477],
            [98.359, 28.2933],
            [98.404, 28.1065],
            [98.7077, 28.231],
            [98.7531, 28.3383],
            [98.6216, 28.5052],
            [98.5928, 28.6731],
            [98.6881, 28.7364],
            [98.6312, 28.975],
            [98.9209, 28.9783],
            [99.0192, 29.0556],
            [98.963, 29.1872],
            [99.1123, 29.2511]
          ]
        ]
      },
      properties: {
        name: 'Yunnan',
        id: 'CN-YN',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-YN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [120.4001, 27.2447],
              [120.3405, 27.4004],
              [120.2436, 27.4347],
              [120, 27.3827],
              [119.8712, 27.3058],
              [119.686, 27.4333],
              [119.625, 27.5967],
              [119.4893, 27.6603],
              [119.2397, 27.4246],
              [119.0063, 27.5],
              [118.9007, 27.4676],
              [118.8914, 27.7174],
              [118.7119, 28.0564],
              [118.7924, 28.1233],
              [118.7046, 28.3125],
              [118.4243, 28.2922],
              [118.4323, 28.6741],
              [118.258, 28.9243],
              [118.0965, 28.9936],
              [118.0282, 29.1789],
              [118.1895, 29.3953],
              [118.4816, 29.5111],
              [118.547, 29.6149],
              [118.7367, 29.7351],
              [118.7524, 29.8459],
              [118.8915, 29.9513],
              [118.8655, 30.1084],
              [118.9528, 30.3641],
              [119.2251, 30.2955],
              [119.346, 30.4201],
              [119.2635, 30.5823],
              [119.4393, 30.6527],
              [119.5702, 30.8389],
              [119.6322, 31.1393],
              [119.9089, 31.1753],
              [119.9919, 31.0265],
              [120.1508, 30.9363],
              [120.3677, 30.955],
              [120.5071, 30.7672],
              [120.6831, 30.9541],
              [120.8951, 31.012],
              [120.995, 31.0015],
              [121.2649, 30.6895],
              [120.9887, 30.5579],
              [120.9079, 30.3724],
              [120.7617, 30.321],
              [120.8665, 30.1574],
              [121.1752, 30.3282],
              [121.3304, 30.3307],
              [121.5082, 30.2096],
              [121.6831, 29.9828],
              [121.7962, 29.9685],
              [121.9849, 29.8171],
              [121.7009, 29.5608],
              [121.4893, 29.5446],
              [121.4485, 29.4143],
              [121.9157, 29.6441],
              [121.9854, 29.4573],
              [121.9065, 29.2973],
              [121.9351, 29.1941],
              [121.6829, 29.1712],
              [121.7284, 28.9507],
              [121.6404, 28.9068],
              [121.619, 28.7046],
              [121.5102, 28.6649],
              [121.584, 28.3107],
              [121.4627, 28.3301],
              [121.3652, 28.2243],
              [121.1201, 28.2821],
              [120.9657, 27.9846],
              [120.6029, 27.5926],
              [120.5197, 27.1957],
              [120.4001, 27.2447]
            ]
          ],
          [
            [
              [122.024, 29.9934],
              [121.9776, 30.1518],
              [122.1007, 30.1582],
              [122.3357, 30.0229],
              [122.024, 29.9934]
            ]
          ]
        ]
      },
      properties: {
        name: 'Zhejiang',
        id: 'CN-ZJ',
        TYPE: 'Province',
        CNTRY: 'China'
      },
      id: 'CN-ZJ'
    }
  ]
}
export default map
