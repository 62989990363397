import { gql, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default async (apolloClient, filters, lang = 'en') => {
  const innerQuery = `motherEntry {
  id
  jurisdiction {
    id
    name
    un_member
    a2_code
    regions{
      id
    }
  }
  subjurisdiction {
    id
    name
  }
  sources {
    id
    order
    section
    original_official_filename
    original_filename
    translated_filename
    enacted
    original_official_language{
      name
      id
    }
    original_official_language2{
      name
      id
    }
    original_language{
      name
      id
    }
    original_language2{
      name
      id
    }
  }
}
jurisdiction {
  id
  name
  a2_code
}
subjurisdiction{
  id
  name
}
area
date_from
date_to
details
topics
type_of_document
subarea
extra_data
generic
`
  const response = await apolloClient.query({
    query: gql(`
query GetEntriesAdvancedSearch($lang: String, $jurs_ids: [String], $areas_ids: [String], $cu_types: [String], $topics: [String], $boolean: String, $date_from: Int, $date_to: Int, $useOr: Boolean) {
advancedSearch(lang: $lang, jurs_ids: $jurs_ids, areas_ids: $areas_ids, cu_types:$cu_types, topics: $topics, boolean: $boolean, date_from: $date_from, date_to: $date_to, useOr: $useOr) {
A1{
  ${innerQuery}
}
A2{
  ${innerQuery}
}
A3{
  ${innerQuery}
}
A4{
  ${innerQuery}
}
A5{
  ${innerQuery}
}
totalAreas
}
}
`),
    variables: {
      areas_ids: filters.areas_ids
        ? filters.areas_ids.concat(
            filters.subareas
              ? Object.keys(filters.subareas)
                  .map(a => filters.subareas[a])
                  .flat()
              : []
          )
        : null,
      jurs_ids: filters.jurs_ids || [],
      topics: filters.topics || [],
      boolean: filters.boolean || '',
      cu_types: filters.cu_types || [],
      date_from: filters.date_from || null,
      date_to: filters.date_to || null,
      useOr: filters.useOr || false,
      lang
    }
  })

  return response
}
