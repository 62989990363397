import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GET_A3_TREATY_BODIES = gql(`
  query GetEntriesTreatyBodies( $lang: String, $jur_id: String) {
    entriesTreatyBodiesComm( lang: $lang, jur_id: $jur_id) {
    id
    motherEntry {
        topics {
          id
          name
        }
        jurisdiction {
          id
          name
          un_member
          a2_code
          regions{
            id
          }
        }
        subjurisdiction {
          id
          name
        }
        sources {
          id
          order
          section
          original_official_filename
          original_filename
          translated_filename
          enacted
          original_official_language{
            name
            id
          }
          original_official_language2{
            name
            id
          }
          original_language{
            name
            id
          }
          original_language2{
            name
            id
          }
        }
      }
      treaty_body {
        id
        name
      }
      session
      summary
      treaty_body_outcome {
        name
        id
      }
      comm_number
      submission_date
      views_date
      petitioners
      un_symbol
      scope {
        id
        name
      }
      topics {
        id
        name
      }
      title
  }
}
`);

function useGqlEntriesTreatyBodiesComm(jur_id = null) {
  const { i18n } = useTranslation();
  const entriesTreatyBodiesComm = useQuery(GET_A3_TREATY_BODIES, {
    variables: { jur_id, lang: i18n.language }
  });

  return {
    entriesTreatyBodiesComm: entriesTreatyBodiesComm.data && entriesTreatyBodiesComm.data.entriesTreatyBodiesComm
  };
}

export default useGqlEntriesTreatyBodiesComm;
