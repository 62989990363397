import IconHome from '../../../components/icons/IconHome'
import WorldIcon from '../icons/WorldIcon'

import './MapControls.css'

export default function MapControls({
  zoomIn,
  zoomOut,
  resetPerspective,
  showDrillDown = true,
  drilledDown = false,
  hideZoom = false
}) {
  return (
    <>
      {!hideZoom && (
        <div className="mapControls__container">
          {showDrillDown && (
            <button
              className="mc__reset-btn"
              onClick={resetPerspective}
              title={drilledDown ? 'Return to world view' : 'Reset map'}
            >
              {drilledDown ? (
                <WorldIcon
                  size={22}
                  title={drilledDown ? 'Return to world view' : 'Reset map'}
                />
              ) : (
                <IconHome
                  size={18}
                  title={drilledDown ? 'Return to world view' : 'Reset map'}
                />
              )}
            </button>
          )}
          <button className="mc__zoom-btn" onClick={zoomIn}>
            +
          </button>
          <button className="mc__zoomOut-btn" onClick={zoomOut}>
            &#8212;
          </button>
        </div>
      )}
    </>
  )
}
