import React, { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'

import { useTranslation } from 'react-i18next'
import useGqlEntriesCsssa from '../../gql/useGqlEntriesCsssa'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import useGqlTextComponents from '../../gql/useGqlTextComponents'
import MainLayout from '../../layouts/MainLayout'
import MapTooltip from '../../mocks/components/StyledMapToolTips'
import '../../pages/Area.css'
import BarChart from '../components/Areas/A1-1/BarChart'
import LineChart from '../components/Areas/A1-1/LineChart'
import AreaContent from '../components/Areas/AreaContent'
import MapContainer from '../components/Map/MapContainer'
import microstates from '../components/Map/microstates.json'
import TableContainer from '../components/Table/TableContainer'
import TalliesSection from '../components/TalliesSection/TalliesSection'
import MainSpinner from '../components/ui/MainSpinner'
import { citeA11, getColumnsA11 } from '../config/columns'

export function useData({ showTooltip = true, useMapReferences = true }) {
  const [references, setReferences] = useState([])
  const [tableEntries, setTableEntries] = useState([])

  const [mapReferences, setMapReferences] = useState([])
  const [talliesNumbers, setTalliesNumbers] = useState([])
  const { entriesCsssa, loading, error } = useGqlEntriesCsssa()
  const {
    jurisdictions,
    loading: loadingJur,
    error: errorJur,
    jurisdictionsById
  } = useGqlJurisdictions()
  const { t } = useTranslation()

  const areaInfo = {
    title: `${t('Area')} 1`,
    subTitle: t('Legal Frameworks'),
    subSubTitle: `${t('Criminalisation of consensual same-sex sexual acts')}`,
    areaName: 'area-1'
  }

  useEffect(() => {
    if (
      !loading &&
      !error &&
      jurisdictions &&
      jurisdictions.length &&
      entriesCsssa &&
      entriesCsssa.length
    ) {
      let referencesTmp = []
      for (let j of jurisdictions) {
        let entry = entriesCsssa.find(
          e => e.motherEntry.jurisdiction.id === j.id
        )
        if (entry) referencesTmp.push(entry)
      }
      referencesTmp = entriesCsssa.map(e => ({
        id:
          e.motherEntry.subjurisdiction && e.motherEntry.subjurisdiction.id
            ? e.motherEntry.subjurisdiction.id
            : e.motherEntry.jurisdiction.a2_code,
        regions: e.motherEntry.jurisdiction.regions,
        microstate: microstates.find(
          ms => ms.A2 === e.motherEntry.jurisdiction.a2_code
        ),
        reference: e.entry_csssa_penalty.map_color,
        reference_text: e.entry_csssa_penalty.name,
        tooltip:
          showTooltip &&
          ReactDOMServer.renderToStaticMarkup(
            <MapTooltip
              entry={e}
              title={t('Criminalisation of consensual same-sex sexual acts')}
              area={'A1-1'}
              positive={e.legal}
              hasSubjur={entriesCsssa.find(
                e2 =>
                  e2.motherEntry.jurisdiction.id ===
                    e.motherEntry.jurisdiction.id &&
                  e2.motherEntry.subjurisdiction
              )}
            />
          ), //tooltip(e),
        entry: e
      }))
      const tmpTallyNumbers = [0, 0, 0]

      for (const entry of entriesCsssa) {
        if (
          !entry.motherEntry.subjurisdiction &&
          entry.motherEntry.jurisdiction.un_member
        ) {
          if (!entry.legal) {
            tmpTallyNumbers[0]++
          }
          tmpTallyNumbers[1] = 2
          if (entry.legal) {
            tmpTallyNumbers[2]++
          }
        }
      }
      tmpTallyNumbers[0] -= 2
      setTalliesNumbers(tmpTallyNumbers)
      setReferences(referencesTmp)
      setMapReferences(
        referencesTmp.concat([
          {
            ...referencesTmp.find(e => e.id === 'NG'),
            id: 'NG-DEATH',
            only_map: true,
            regions: [],
            microstate: null,
            reference: '#A03E13',
            reference_text: t('Death Penalty')
          },
          {
            ...referencesTmp.find(e => e.id === 'ID'),
            id: 'ID-AC',
            only_map: true,
            regions: [],
            microstate: null,
            reference: '#EDB795',
            reference_text: t('Up to 8 years imprisonment')
          }
        ])
      )
    }
  }, [jurisdictions, entriesCsssa, error, loading])

  return {
    entriesCsssa: {
      data: entriesCsssa,
      loading,
      error
    },
    jurisdictions: {
      data: jurisdictions,
      loading: loadingJur,
      error: errorJur,
      jurisdictionsById
    },
    references: useMapReferences ? mapReferences : references,
    setReferences: useMapReferences ? setMapReferences : setReferences,
    mapReferences,
    setMapReferences,
    tableEntries,
    setTableEntries,
    talliesNumbers,
    setTalliesNumbers,
    areaInfo
  }
}

export function Map({
  references,
  htmlReferences,
  jurisdictionsById,
  jurisdictions,
  areaInfo,
  mapLoaded,
  setMapLoaded,
  showTooltip = true,
  textComponents,
  include,
  ...props
}) {
  const { t, i18n } = useTranslation()

  const mapLegendItems = [
    { name: t('No criminalisation'), color: '#ffffff' },
    { name: t('De facto criminalisation'), color: '#F0DACA' },
    { name: t('Up to 8 years imprisonment'), color: '#EDB795' },
    { name: t('10 years to life in prison'), color: '#F5934B' },
    { name: t('Death penalty (possible)'), color: '#D6643A' },
    { name: t('Death penalty'), color: '#A03E13' }
  ]

  return (
    <MapContainer
      references={references}
      currentArea={'A1-1'}
      htmlReferences={htmlReferences}
      jurisdictionsById={jurisdictionsById}
      jurisdictions={jurisdictions}
      mapLegendItems={mapLegendItems}
      setMapLoaded={setMapLoaded}
      title={areaInfo.title}
      subTitle={areaInfo.subTitle}
      subSubTitle={areaInfo.subSubTitle}
      areaName={areaInfo.areaName}
      modalTitle={areaInfo.subSubTitle}
      showTooltip={showTooltip}
      modalContent={
        textComponents && textComponents.length && textComponents[0].map
      }
      include={include}
      {...props}
    />
  )
}
export default function Area11() {
  const htmlReferences = useRef()
  const { textComponents } = useGqlTextComponents('A1-1')
  const [mapLoaded, setMapLoaded] = useState(false)
  
  const { t, i18n } = useTranslation()
  const {
    entriesCsssa: { data: entriesCsssa },
    jurisdictions: { data: jurisdictions, jurisdictionsById },
    references,
    mapReferences,
    tableEntries,
    talliesNumbers,
    setTableEntries,
    areaInfo
  } = useData({ useMapReferences: false })
  const tooltip = e => {
    return ReactDOMServer.renderToStaticMarkup()
  }

  return (
    <MainLayout section="areas" areaInfo={areaInfo}>
      <div className="area__page area1__page A1-1">
        <section className="selectRegion__section">
          <Map
            areaInfo={areaInfo}
            references={mapReferences}
            htmlReferences={htmlReferences}
            jurisdictionsById={jurisdictionsById}
            jurisdictions={jurisdictions}
            mapLoaded={mapLoaded}
            setMapLoaded={setMapLoaded}
            textComponents={textComponents}
          />
        </section>
        <div className="content__container">
          <TalliesSection
            area={'A1-1'}
            canStart={mapLoaded}
            replaceNumbers={talliesNumbers}
          />

          <AreaContent areaIntro={'A1-1'} areaLatest={'A1-1'} />

          <div className="mainCTA__2charts">
            <section className="mainCTA__section50">
              <div className="mainGraphic__section mgs__paddingFix">
                <h5>{t('Criminalisation by region')}</h5>
                <div
                  className="fixChartSymetry"
                  style={{ position: 'relative', flexGrow: 1, height: '400px' }}
                >
                  {references && references.length > 0 ? (
                    <BarChart references={references} />
                  ) : (
                    <MainSpinner text={t('Loading chart...')} />
                  )}
                </div>
              </div>
            </section>

            <section className="mainCTA__section50">
              <div className="mainGraphic__section fixChartSymetry-right">
                <h5>{t('Decriminalisation by year')}</h5>
                {references && references.length > 0 && jurisdictionsById ? (
                  <LineChart
                    references={references.filter(
                      r => !r.entry.motherEntry.subjurisdiction
                    )}
                    jurisdictions={jurisdictionsById}
                  />
                ) : (
                  <MainSpinner text={t('Loading chart...')} />
                )}
              </div>
            </section>
          </div>
        </div>
        <section className="table__section">
          <div className="content__container">
            <TableContainer
              entries={entriesCsssa}
              citeEntry={citeA11}
              paginate={false}
              columns={getColumnsA11()}
              t={t}
              title={t('Area') + ' 1'}
              subtitle={
                t('Legal Frameworks') +
                ' | ' +
                '<span style="font-weight:600">' +
                t('Criminalisation of consensual same-sex sexual acts') +
                '</span>'
              }
              initialSortBy={[
                {
                  id: 'motherEntry.jurisdiction.name',
                  desc: false
                }
              ]}
              showUNSwitch
            />
          </div>
        </section>
      </div>
    </MainLayout>
  )
}
