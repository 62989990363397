import { Accordion } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import pieChartPlaceholder from '../../images/pieChartPlaceholder.png'
import barChartPlaceholder from '../../images/barChartPlaceholder.png'
import useGqlEntriesRecommendations from '../../gql/useGqlEntriesRecommendations'
import { citeA5, columns } from '../config/a5columns'
import { citeA5Cycle, columnsCycle } from '../config/a5columnsCycle'
import Table from '../components/Table/Table'
import { useTranslation } from 'react-i18next'
import BarChartJurProfileArea4 from './charts/BarChartJurProfileArea4'
import PieChartJurProfileArea5 from './charts/PieChartJurProfileArea5'
import StackedAreaChartArea5 from './charts/StackedAreaChartArea5'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import GanttChartArea56 from './charts/GanttChartArea56'
import UPRModal from '../components/JurProfile/UPRModal'

export default function JurProfileArea5({ jur, hide = false }) {
  const [t] = useTranslation()
  const {
    entriesRecommendations,
    entriesRecommendationsIssued,
    entriesCycleDocs,
    loading,
    error
  } = useGqlEntriesRecommendations(jur.id)
  const { hrcMemberships } = useGqlJurisdictions()
  const [tableTab, setTableTab] = useState(1)

  useEffect(() => {
    console.log(
      'RELOADING',
      entriesRecommendations,
      entriesRecommendationsIssued,
      entriesCycleDocs,
      loading
    )
  }, [])
  if (hide) return null
  return (
    <>
      <div className="content__container jurProfile__area3 jurProfile__area6">
        <div className="tb__pieChart-container">
          <h4 className="tb__pieChart-title">
            {t('Recommendations received by {{jur}}', { jur: jur.name })}
          </h4>
          <div className="tb__pieChart-actualContainer">
            {entriesRecommendations &&
            entriesRecommendationsIssued &&
            hrcMemberships &&
            entriesRecommendations.length > 0 &&
            entriesRecommendationsIssued ? (
              <PieChartJurProfileArea5
                entriesRecommendations={entriesRecommendations}
                entriesRecommendationsIssued={entriesRecommendationsIssued}
              />
            ) : !entriesRecommendations || !entriesRecommendationsIssued ? (
              <PieChartJurProfileArea5 isLoading />
            ) : (
              <div style={{ textAlign: 'center' }}>
                {t(
                  '{{jur}} has not received recommendations on sexual, gender or bodily diversity in its third UPR cycle',
                  {
                    jur: jur.name
                  }
                )}
              </div>
            )}
          </div>
        </div>
        <div className="tb__recsContainer">
          <h4 className="tb__recs-title">
            {t('Recommendations issued by {{jur}}', { jur: jur.name })}
          </h4>
          <div className="tb__barChart-actualContainer">
            {entriesRecommendations &&
            entriesRecommendationsIssued &&
            hrcMemberships &&
            entriesRecommendationsIssued.length > 0 ? (
              <StackedAreaChartArea5
                references={entriesRecommendationsIssued}
              />
            ) : !entriesRecommendationsIssued ? (
              <StackedAreaChartArea5 isLoading />
            ) : (
              <div style={{ textAlign: 'center' }}>
                {t(
                  '{{jur}} has not issued recommendations on sexual, gender or bodily diversity during the UPR’s third cycle',
                  {
                    jur: jur.name
                  }
                )}
              </div>
            )}
          </div>
        </div>
        <div className="tb__barChart-container">
          <h4 className="tb__barChart-title">
            {t('{{jur}} Human Rights Council Membership', { jur: jur.name })}
          </h4>
          <span className="tb__barChart-subtitle" />
          <div className="tb__barChart-actualContainer">
            {entriesRecommendations &&
            entriesRecommendationsIssued &&
            hrcMemberships &&
            hrcMemberships.length > 0 &&
            hrcMemberships.filter(m => m.jurisdiction.id === jur.id).length >
              0 ? (
              <GanttChartArea56
                references={hrcMemberships.filter(
                  m => m.jurisdiction.id === jur.id
                )}
              />
            ) : !hrcMemberships ? (
              <GanttChartArea56 isLoading />
            ) : (
              <div style={{ marginTop: '20px' }}>
                {t(
                  '{{jur}} has never been a member of the UN Human Rights Council',
                  { jur: jur.name }
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <section className="section1-jur1 jurProfile__area3">
        <div className="content__container">
          <h4 className="jpjs__area">{t('AREA')} 5</h4>
          <div className="jpjs__titleWithBtn">
            <h2 className="jpjs__title">
              {t('Universal Periodic Review (UPR)')}
            </h2>
            {false && (
              <button className="jps__button btn btn-link">
                <img
                  className="jpsb__countryFlag"
                  src={
                    '/images/flags_svg/' + jur.a2_code.toLowerCase() + '.svg'
                  }
                  alt="Country Flag"
                />
                {jur.name}
              </button>
            )}
          </div>
          <div className="jpjs__tableTabBtns">
            <button
              className={'jpjs__tableTabBtn ' + (tableTab === 1 && 'active')}
              onClick={() => setTableTab(1)}
            >
              {t('Received Recommendations')}
            </button>
            <button
              className={'jpjs__tableTabBtn ' + (tableTab === 2 && 'active')}
              onClick={() => setTableTab(2)}
            >
              {t('Issued Recommendations')}
            </button>
          </div>

          {tableTab === 1 && (
            <div className="area2Table__section table__section">
              <h4 className="a3ts__tableTitle">
                {t('Received Recommendations') + ' ' + t('since') + ' 2017'}
              </h4>
              <div className="content__container">
                <div className="a2ts__body table-responsive">
                  {entriesRecommendations &&
                  entriesRecommendations.length > 0 ? (
                    <Table
                      columns={columns()
                        .filter(
                          c => c.accessor !== 'motherEntry.jurisdiction.name'
                        )
                        .map(c => (c.isHidden ? { ...c, isHidden: false } : c))
                        .map(c =>
                          c.NonExpandableCell
                            ? { ...c, Cell: c.NonExpandableCell }
                            : c
                        )}
                      entries={entriesRecommendations}
                      emptyHeaderBg={'#ecefec'}
                      showFilters={true}
                      citeEntry={citeA5}
                      fixedHeight={false}
                      disableHover={true}
                      showSubjurSwitch={false}
                      disableFirstCol={true}
                      tableContainerExtraClass={'jurTableContainer'}
                      trMainClass={''}
                      t={t}
                    />
                  ) : (
                    <div>
                      {t(
                        '{{jur}} has not received recommendations on sexual, gender or bodily diversity in its third UPR cycle',
                        {
                          jur: jur.name
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {tableTab === 2 && (
            <div className="area2Table__section table__section">
              <h4 className="a3ts__tableTitle">
                {t('Issued Recommendations') + ' ' + t('since') + ' 2017'}
              </h4>
              <div className="content__container">
                <div className="a2ts__body table-responsive">
                  {entriesRecommendationsIssued &&
                  entriesRecommendationsIssued.length > 0 ? (
                    <Table
                      columns={columns()
                        .filter(c => c.accessor !== 'issuing_jurisdiction.name')
                        .map(c => (c.isHidden ? { ...c, isHidden: false } : c))
                        .map(c =>
                          c.NonExpandableCell
                            ? { ...c, Cell: c.NonExpandableCell }
                            : c
                        )}
                      entries={entriesRecommendationsIssued}
                      emptyHeaderBg={'#ecefec'}
                      showFilters={true}
                      fixedHeight={false}
                      citeEntry={citeA5}
                      disableHover={true}
                      showSubjurSwitch={false}
                      disableFirstCol={true}
                      tableContainerExtraClass={'jurTableContainer'}
                      trMainClass={''}
                      t={t}
                    />
                  ) : (
                    <div>
                      {t(
                        '{{jur}} has not issued recommendations on sexual, gender or bodily diversity during the UPR’s third cycle',
                        {
                          jur: jur.name
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {entriesCycleDocs && entriesCycleDocs.length > 0 && tableTab === 1 && (
            <div className="area2Table__section table__section">
              <div className="a3ts__tableTitle">
                <span>{t('UPR - Cycle Documents')}</span> <UPRModal />
              </div>
              <div className="content__container">
                <div className="a2ts__body table-responsive">
                  <Table
                    columns={columnsCycle()
                      .filter(
                        c => c.accessor !== 'motherEntry.jurisdiction.name'
                      )
                      .map(c => (c.isHidden ? { ...c, isHidden: false } : c))
                      .map(c =>
                        c.NonExpandableCell
                          ? { ...c, Cell: c.NonExpandableCell }
                          : c
                      )}
                    entries={entriesCycleDocs}
                    emptyHeaderBg={'#ecefec'}
                    showFilters={true}
                    citeEntry={citeA5Cycle}
                    fixedHeight={false}
                    disableHover={true}
                    showSubjurSwitch={false}
                    disableFirstCol={true}
                    tableContainerExtraClass={'jurTableContainer'}
                    trMainClass={''}
                    t={t}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  )
}
