import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Dropdown.css";
import DropdownMenu from "./DropdownMenu";
import Drop from "./DropdownBoot";
import DropdownPopper from "./DropdownPopper";
import FilterIcon from "../icons/FilterIcon";

function Dropdown({
  title,
  items,
  multiSelect = false,

  filterValue,
  setTableFilter,
  clearTableFilter,
  loading,
  error,
  sortSelected = false,
  search = false,
  width,
  filterLoading = false
}) {
  const [filter, setFilter] = useState("");
  const [listItems, setListItems] = useState(items);
  const [selection, setSelection] = useState(
    filterValue && filterValue.length ? filterValue.map(fv => ({ id: fv, value: fv })) : []
  );
  const [coords, setCoords] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (items) {
      if (filter) setListItems(items.filter(li => li.value.toLowerCase().indexOf(filter) !== -1));
      else setListItems(items);
    }
  }, [items]);

  useEffect(() => {
    if (!isOpen) setFilter("");
  }, [isOpen]);

  const { t } = useTranslation();

  useEffect(() => {
    if (filter) setListItems(items.filter(li => li.value.toLowerCase().indexOf(filter) !== -1));
    else setListItems(items);
  }, [filter]);

  useEffect(() => {
    if (selection) {
      setTableFilter(selection.map(s => s.id));
    }
  }, [selection]);

  //HOOK TO FIX HIDE COLUMNS ISSUE
  useEffect(() => {
    if (filterValue && filterValue.length && !selection.length) setSelection(filterValue.map(fv => ({ id: fv })));
  }, [filterValue]);

  function handleOnClick(item) {
    setFilter("");
    if (inputRef.current) inputRef.current.value = "";
    if (!selection.some(current => current.id === item.id)) {
      if (!multiSelect) {
        setSelection([item]);
      } else if (multiSelect) {
        setSelection([...selection, item].sort((a, b) => a - b));
      }
    } else {
      let selectionAfterRemoval = selection;
      selectionAfterRemoval = selectionAfterRemoval.filter(current => current.id !== item.id);
      setSelection([...selectionAfterRemoval]);
    }
  }

  function isItemInSelection(item) {
    if (selection.some(current => current.id === item.id)) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <DropdownPopper
        buttonText={<FilterIcon size={18} />}
        active={selection.length}
        filterLoading={filterLoading}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      >
        <DropdownMenu
          coords={coords}
          listItems={listItems}
          isItemInSelection={isItemInSelection}
          setFilter={setFilter}
          defaultFilterText={filter}
          handleOnClick={handleOnClick}
          t={t}
          ref={inputRef}
          search={search}
          closeDropdown={() => setIsOpen(false)}
          sortSelected={sortSelected}
          width={width}
          filterLoading={filterLoading}
          clearFilter={e => {
            setSelection([]);
            clearTableFilter("");
            setTimeout(() => {
              setIsOpen(false);
            });
          }}
        />
      </DropdownPopper>
    </div>
  );
}

export default Dropdown;
