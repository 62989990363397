import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import CustomCheckbox from '../Checkbox'

import { tagListSample } from '../../config/a3columns'
import { useTranslation } from 'react-i18next'
import { TagModal } from '../Table/TopicFilter'
import useGqlJurisdictions from '../../../gql/useGqlJurisdictions'
import DropdownJurs from '../Dropdown/DropdownJurs'

const ASJursInput = ({ filters, setFilters }) => {
  let {
    jurisdictions,
    jurisdictionsById,
    loading,
    error,
    regions
  } = useGqlJurisdictions()

  const { t } = useTranslation()

  return (
    <>
      <div className="acp__formControl">
        <label htmlFor="selectJurisdiction">
          {t('Region, group or jurisdiction')}
        </label>

        {jurisdictions && regions && !loading && !error && (
          <DropdownJurs
            setTableFilter={jurs => {
              setFilters(
                [...new Set(jurs)]
                  .filter(j => jurisdictions.find(ju => ju.id === j))
                  .map(j => jurisdictions.find(ju => ju.id === j).id)
              )
            }}
            sortSelected={true}
            title={t('Jurisdictions')}
            filterValue={filters ? filters : []}
            //Items grouped
            items={{
              regions: regions.map(r => ({
                id: r.id,
                icon: (
                  <img
                    src={'/images/region_flags/' + r.a2_code + '.png'}
                    height="12px"
                    alt={r.name + ' flag'}
                  />
                ),
                value: r.name
              })),
              jurisdictions: jurisdictions.map(j => ({
                id: j.id,
                icon: (
                  <img
                    src={
                      '/images/flags_svg/' + j.a2_code.toLowerCase() + '.svg'
                    }
                    height="12px"
                    className="svgFlag"
                    alt={j.name + ' flag'}
                  />
                ),
                value: j.name
              }))
            }}
            jurisdictions={jurisdictions}
            regions={regions}
            loading={loading}
            error={error}
            multiSelect
            search={true}
            clearFilter={true}
            clearFilterExtras={() => setFilters([])}
            buttonText={
              <>
                <button
                  class="advancedSearch__areasBtn"
                  style={{ width: '100%' }}
                >
                  {filters && filters.length > 0 ? (
                    <span>
                      {filters.length} {t('jurisdictions selected')}
                    </span>
                  ) : (
                    <span>{t('All jurisdictions')}</span>
                  )}
                </button>
              </>
            }
            customActive={true}
          />
        )}
      </div>
    </>
  )
}

export default ASJursInput
