import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GET_A1_PROTECTION = gql(`
  query GetEntriesProtection($type: String!, $lang: String, $jur_id: String) {
    entriesProtection(type: $type, lang: $lang, jur_id: $jur_id) {
      id
      motherEntry {
        entry_type_id
        jurisdiction {
          id
          name
          short_name
          un_member
          a2_code
          regions{
            id
          }
        }
        subjurisdiction {
          id
          name
          slug
          label_longitude
          label_latitude
          label_font_size
        }
        footnotes {
          id
          order
          section
          content
        }
        sources {
          id
          order
          section
          original_official_filename
          original_filename
          translated_filename
          enacted
          original_official_language{
            name
            id
          }
          original_official_language2{
            name
            id
          }
          original_language{
            name
            id
          }
          original_language2{
            name
            id
          }
        }
      }
    so_protection_type {
      id
      name
    }

    so_critical_date_1
    so_critical_date_2
    so_legal_framework_decrim_mechanism {
      id
      name
    }
    so_explan

    gi_critical_date_1
    gi_protection_type {
      id
      name
    }
    gi_legal_framework_decrim_mechanism {
      id
      name
    }





    gi_critical_date_2
    gi_explan

    ge_protection_type {
      id
      name
    }
    ge_critical_date_1
    ge_critical_date_2
    ge_legal_framework_decrim_mechanism {
      id
      name
    }
    ge_explan

    sc_protection_type {
      id
      name
    }
    sc_critical_date_1
    sc_critical_date_2
    sc_legal_framework_decrim_mechanism {
      id
      name
    }
    sc_explan


  }
}
`);

function useGqlEntriesProtection(type, jur_id) {
  const { i18n } = useTranslation();
  const entries_protection = useQuery(GET_A1_PROTECTION, { variables: { type, jur_id, lang: i18n.language } });

  return { entriesProtection: entries_protection.data && entries_protection.data.entriesProtection };
}

export default useGqlEntriesProtection;
