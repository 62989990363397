import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MagGlass from "../icons/MagGlass";
import DropdownPopper from "./DropdownPopper";
import "./DropdownSearch.css";
import DropdownSearchMenu from "./DropdownSearchMenu";

function DropdownSearch({ title, setTableFilter, filterLoading = false }) {
  const [filter, setFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [coords, setCoords] = useState({});
  const inputRef = useRef();

  const { t } = useTranslation();

  return (
    <div>
      <DropdownPopper
        buttonText={<MagGlass size={18} className="filterSearchIcon" />}
        active={filter}
        filterLoading={filterLoading}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <DropdownSearchMenu
          coords={coords}
          setFilter={(text) => {
            setFilter(text);
            setTableFilter(text);
          }}
          defaultFilterText={filter}
          t={t}
          ref={inputRef}
          closeDropdown={() => {}}
          clearFilter={() => {
            setFilter("");
            setTableFilter("");
            setIsOpen(false);
          }}
        />
      </DropdownPopper>
    </div>
  );
}

export default DropdownSearch;
