import i18n from '../config/i18n'
import { i18ndayjs } from './fnDates'

function copyToClip(str) {
  function listener(e) {
    e.clipboardData.setData('text/html', str)
    e.clipboardData.setData('text/plain', str.replace(/(<([^>]+)>)/gi, ''))
    e.preventDefault()
  }
  document.addEventListener('copy', listener)
  document.execCommand('copy')
  document.removeEventListener('copy', listener)
}

export const citeEntry = (route, jurName, cite, setToast) => {
  copyToClip(
    '<p>' +
      cite +
      '</p>' +
      (!route
        ? ''
        : `<p><strong>${i18n.t('Source')}</strong>: ${i18n.t(
            'ILGA World'
          )}, <a href="https://database.ilga.org${route.path}"><em>${i18n.t(
            'ILGA World Database'
          )}: ${
            route.areaName ? route.areaName : route.name
          } - ${jurName}</em></a>  (${i18n.t('retrieved')}: ${i18ndayjs(
            new Date(),
            'DD MMMM YYYY'
          )}).</p>`)
  )
  if (setToast) {
    setToast({
      toastText: i18n.t('The entry has been copied to your device clipboard')
    })
  }
  return cite
}
