import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { cTreatyBodiesFull, isInquiryProcedure } from '../../config/constants'
import useGqlEntriesTreatyBodies from '../../gql/useGqlEntriesTreatyBodies'
import useGqlEntriesTreatyBodiesComm from '../../gql/useGqlEntriesTreatyBodiesComm'
import { isRecommendation } from '../../lib/fnAreasPRHelper'
import Table from '../components/Table/Table'
import MainSpinner from '../components/ui/MainSpinner'
import { citeA3, columns as a3columns } from '../config/a3columns'
import { citeA3Comm, columnsComm } from '../config/a3columnsComm'
import { citeA4 } from '../config/a4columns'
import BarChartJurProfileArea3 from './charts/BarChartJurProfileArea3'
import PieChartJurProfileArea3 from './charts/PieChartJurProfileArea3'

export default function JurProfileArea3({ jur, hide = false }) {
  const { entriesTreatyBodies, loading } = useGqlEntriesTreatyBodies(
    null,
    jur.id
  )
  const { entriesTreatyBodiesComm } = useGqlEntriesTreatyBodiesComm(jur.id)
  const [individualCommunications, setIndividualCommunications] = useState(
    false
  )
  const [inquiryProcedures, setInquiryProcedures] = useState(false)

  const [t] = useTranslation()
  if (hide) return null

  return (
    <>
      <div className="content__container jurProfile__area3">
        <div className="tb__recsContainer">
          <h4 className="tb__recs-title">
            <Trans>
              Recommendations received by {{ jur: jur.name }} from Treaty Bodies
              since 2016
            </Trans>
          </h4>
          <div className="tb__recBoxContainer">
            {cTreatyBodiesFull().map(tb => (
              <div className="recBox">
                <p className="recBox__title">
                  {tb.boxName ? tb.boxName : tb.name}
                </p>
                <span className="recBox__count">
                  {entriesTreatyBodies &&
                    entriesTreatyBodies.filter(
                      t => isRecommendation(t) && t.treaty_body.id == tb.id
                    ).length}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="tb__pieChart-container">
          <h4 className="tb__pieChart-title">
            <Trans>
              Recommendations to {{ jur: jur.name }} by Treaty Body since 2016
            </Trans>
          </h4>
          <div className="tb__pieChart-actualContainer tb__pieChart-actualContainer-noflex">
            {entriesTreatyBodies &&
            entriesTreatyBodies.filter(t => isRecommendation(t)).length > 0 ? (
              <PieChartJurProfileArea3 references={entriesTreatyBodies} />
            ) : loading ? (
              <div
                style={{ position: 'relative', height: 'calc(100% - 120px)' }}
              >
                <MainSpinner text={t('Loading chart...')} />
              </div>
            ) : (
              <div className="noRecs">
                <br />
                <br />
                <br />
                {t(
                  '{{jur}} has not received any SOGIESC-related recommendation from UN Treaty Bodies since 2016',
                  {
                    jur: jur.name
                  }
                )}
              </div>
            )}
          </div>
        </div>

        <div className="tb__barChart-container">
          <h4 className="tb__barChart-title">
            {t('Recommendations received by {{jur}} from Treaty Bodies', {
              jur: jur.name
            }) +
              ' ' +
              t('since 2016')}
          </h4>
          <span className="tb__barChart-subtitle">({t('since 2016')})</span>
          <div className="tb__barChart-actualContainer">
            {entriesTreatyBodies &&
            entriesTreatyBodies.filter(t => isRecommendation(t)).length > 0 ? (
              <BarChartJurProfileArea3 references={entriesTreatyBodies} />
            ) : loading ? (
              <div
                style={{ position: 'relative', height: 'calc(100% - 120px)' }}
              >
                <MainSpinner text={t('Loading chart...')} />
              </div>
            ) : (
              <div className="noRecs">
                <br />
                <br />
                <br />
                {t(
                  '{{jur}} has not received any SOGIESC-related recommendation from UN Treaty Bodies since 2016',
                  {
                    jur: jur.name
                  }
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <section className="section1-jur1  jurProfile__area3">
        <div className="content__container">
          <h4 className="jpjs__area">{t('AREA')} 3</h4>
          <div className="jpjs__titleWithBtn">
            <h2 className="jpjs__title">{t('Treaty Bodies')}</h2>
            {false && (
              <button className="jps__button btn btn-link">
                <img
                  className="jpsb__countryFlag"
                  src={
                    '/images/flags_svg/' + jur.a2_code.toLowerCase() + '.svg'
                  }
                  alt="Country Flag"
                />
                {jur.name}
              </button>
            )}
          </div>
          <div className="jpjs__tableTabBtns">
            <button
              className={
                'jpjs__tableTabBtn ' +
                (entriesTreatyBodies && entriesTreatyBodies.length === 0
                  ? 'disabled'
                  : !individualCommunications && !inquiryProcedures && 'active')
              }
              onClick={() => {
                setIndividualCommunications(false)
                setInquiryProcedures(false)
              }}
            >
              {t('Concluding observations')}
            </button>
            <button
              className={
                'jpjs__tableTabBtn  ' +
                (entriesTreatyBodiesComm && entriesTreatyBodiesComm.length === 0
                  ? 'disabled'
                  : individualCommunications && 'active')
              }
              onClick={() =>
                entriesTreatyBodiesComm &&
                entriesTreatyBodiesComm.length > 0 &&
                setIndividualCommunications(true)
              }
            >
              {t('Individual communications')}
            </button>
            <button
              className={
                'jpjs__tableTabBtn  ' +
                (entriesTreatyBodies &&
                entriesTreatyBodies.filter(e =>
                  isInquiryProcedure(e.treaty_body_cu_type.id)
                ).length === 0
                  ? 'disabled'
                  : inquiryProcedures && 'active')
              }
              onClick={() =>
                entriesTreatyBodies &&
                entriesTreatyBodies.filter(e =>
                  isInquiryProcedure(e.treaty_body_cu_type.id)
                ).length > 0 &&
                setInquiryProcedures(true)
              }
            >
              {t('Inquiry Procedure')}
            </button>
          </div>

          {!individualCommunications && !inquiryProcedures && (
            <div className="area2Table__section table__section">
              <h4 className="a3ts__tableTitle">
                {t('Concluding Observations') + ' ' + t('since 2016')}
              </h4>
              <div className="content__container">
                <div className="a2ts__body table-responsive">
                  {entriesTreatyBodies && entriesTreatyBodies.length > 0 ? (
                    <Table
                      columns={a3columns(null, true)
                        .filter(
                          c => c.accessor !== 'motherEntry.jurisdiction.name'
                        )
                        .map(c => ({
                          ...c,
                          ...(c.isHidden && { isHidden: false }),
                          ...(c.NonExpandableCell && {
                            Cell: c.NonExpandableCell
                          }),
                          ...((c.accessor === 'motherEntry.topics' ||
                            c.accessor === 'source') && {
                            className: 'textCenter col140'
                          })
                        }))}
                      entries={entriesTreatyBodies.filter(
                        e => !isInquiryProcedure(e.treaty_body_cu_type.id)
                      )}
                      emptyHeaderBg={'#ecefec'}
                      showFilters={true}
                      citeEntry={citeA3}
                      fixedHeight={true}
                      disableHover={true}
                      disableFirstCol={true}
                      tableContainerExtraClass={'jurTableContainer'}
                      trMainClass={''}
                      t={t}
                      showSubjurSwitch={false}
                    />
                  ) : (
                    <div className="noRecs">
                      <br />

                      {t(
                        'At least since 2016, no UN Treaty Body has made SOGIESC-related mentions or recommendations in their Concluding Observations to periodic reports presented by {{jur}}',
                        {
                          jur: jur.name
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {individualCommunications && (
            <div className="area2Table__section table__section">
              <h4 className="a3ts__tableTitle">
                {t('Individual Communications')}
              </h4>
              <div className="content__container">
                <div className="a2ts__body table-responsive">
                  {entriesTreatyBodiesComm &&
                  entriesTreatyBodiesComm.length > 0 ? (
                    <Table
                      columns={columnsComm()
                        .filter(
                          c => c.accessor !== 'motherEntry.jurisdiction.name'
                        )
                        .map(c => ({
                          ...c,
                          ...(c.isHidden && { isHidden: false }),
                          ...(c.NonExpandableCell && {
                            Cell: c.NonExpandableCell
                          }),
                          ...((c.accessor === 'motherEntry.topics' ||
                            c.accessor === 'source') && {
                            className: 'textCenter col140'
                          })
                        }))}
                      entries={entriesTreatyBodiesComm}
                      emptyHeaderBg={'#ecefec'}
                      showFilters={true}
                      fixedHeight={true}
                      disableHover={true}
                      showSubjurSwitch={false}
                      disableFirstCol={true}
                      citeEntry={citeA3Comm}
                      tableContainerExtraClass={'jurTableContainer'}
                      trMainClass={''}
                      t={t}
                    />
                  ) : (
                    <div className="noRecs">
                      <br />
                      <br />
                      <br />
                      {t(
                        'At least since 2016, no UN Treaty Body has ever decided on an individual communication presented against {{jur}} with SOGIESC-related substantive content',
                        {
                          jur: jur.name
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {inquiryProcedures && (
            <div className="area2Table__section table__section">
              <h4 className="a3ts__tableTitle">{t('Inquiry Procedure')}</h4>
              <div className="content__container">
                <div className="a2ts__body table-responsive">
                  {entriesTreatyBodies &&
                  entriesTreatyBodies.filter(e =>
                    isInquiryProcedure(e.treaty_body_cu_type.id)
                  ).length > 0 ? (
                    <Table
                      columns={a3columns(null, true)
                        .filter(
                          c => c.accessor !== 'motherEntry.jurisdiction.name'
                        )
                        .map(c => ({
                          ...c,
                          ...(c.isHidden && { isHidden: false }),
                          ...(c.NonExpandableCell && {
                            Cell: c.NonExpandableCell
                          }),
                          ...((c.accessor === 'motherEntry.topics' ||
                            c.accessor === 'source') && {
                            className: 'textCenter col140'
                          })
                        }))}
                      entries={entriesTreatyBodies.filter(e =>
                        isInquiryProcedure(e.treaty_body_cu_type.id)
                      )}
                      emptyHeaderBg={'#ecefec'}
                      showFilters={true}
                      citeEntry={citeA3}
                      fixedHeight={true}
                      disableHover={true}
                      disableFirstCol={true}
                      tableContainerExtraClass={'jurTableContainer'}
                      trMainClass={''}
                      t={t}
                      showSubjurSwitch={false}
                    />
                  ) : (
                    <div className="noRecs">
                      <br />

                      {t(
                        'At least since 2016, no UN Treaty Body has made SOGIESC-related mentions or recommendations in their Concluding Observations to periodic reports presented by {{jur}}',
                        {
                          jur: jur.name
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  )
}
