import { Fragment } from 'react'
import { cSpecialProceduresCuTypes } from '../../config/constants'
import i18n from '../../config/i18n'
import { citeEntry } from '../../lib/cite'
import { i18ndayjs } from '../../lib/fnDates'
import AutoTranslateCell from '../components/Table/AutoTranslateCell'
import DateDropdownFilter, {
  ApplyDropdownDateFilter
} from '../components/Table/DateDropdownFilter'
import ExpandableTextCell from '../components/Table/ExpandableTextCell'
import GenericDropdownFilter, {
  ApplyGenericDropdownFilter
} from '../components/Table/GenericDropdownFilter'
import JurCell from '../components/Table/JurCell'
import JurFilter, { ApplyJurFilter } from '../components/Table/JurFilter'
import SourceText from '../components/Table/SourceText'
import TopicFilter, { ApplyTopicFilter } from '../components/Table/TopicFilter'

export const citeA4 = (entry, setToast) => {
  let lines = []
  if (entry.is_auto_translate && i18n.language === 'es') {
    lines = [
      '<p>' +
        entry.text +
        '<br />(Advertencia: esta es una traducción no oficial producida por un robot de traducción; puede contener errores conceptuales).' +
        '</p>'
    ]
  } else {
    lines = ['<p>' + entry.text + '</p>']
  }
  
  //TODO cargar los SP en lugar de los UN MECH
  let sps =
    parseInt(entry.special_procedure_cu_type.id) !== 7
      ? entry.special_procedure_types_concat_full
      : null
  
  lines.push(
    '<p>' +
      (sps ? sps + `, ` : '') +
      `<em><a href="${process.env.REACT_APP_BACKEND_URL +
        '/downloader/download/4/' +
        entry.motherEntry.sources[0].original_filename}" target="_blank">` +
      entry.title +
      '</a></em>, ' +
      (sps ? entry.un_symbol + ', ' : '') +
      i18ndayjs(entry.date, 'D MMMM YYYY') +
      (entry.para ? ', para. ' + entry.para : '') +
      '.</p>'
  )

  citeEntry(null, null, lines.join(''), setToast)
}

export const columns = (
  specialProceduresTypes,
  specialProceduresCuTypes = null
) => [
  {
    Header: i18n.t('Jurisdiction'),
    disableGroupBy: true,
    accessor: 'motherEntry.jurisdiction.name',
    enableRowSpan: true,
    filter: ApplyJurFilter,
    Filter: ({ column }) => {
      return <JurFilter column={column} search={true} />
    },
    Cell: ({ value, row }) => {
      return <JurCell multi={true} row={row} />
    },
    CellSmall: ({ value, row }) => {
      return (
        <div>{row.original.motherEntry.jurisdiction.name.substring(0, 3)}</div>
      )
    },
    style: { minWidth: '100px' }
  },

  {
    Header: i18n.t('Special Procedures'),
    accessor: 'special_procedure_types_concat',
    Cell: ({ value, row }) => {
      //return row.original.special_procedure_types_concat;
      return row.original.special_procedure_types.map(spt => <p>{spt.name}</p>)
    },
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(
        rows,
        'special_procedure_types_concat',
        filterValue,
        false,
        false,
        false
      ),
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width="300px"
          column={column}
          options={specialProceduresTypes
            .map(tb => ({
              id: tb.name,
              value: tb.name
            }))
            .sort((a, b) => (a.value > b.value ? 1 : -1))}
        />
      )
    },
    className: 'textCenter col140',
    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.special_procedure_types_concat >
        rowB.original.special_procedure_types_concat
      )
        return -1
      if (
        rowB.original.special_procedure_types_concat >
        rowA.original.special_procedure_types_concat
      )
        return 1
      return 0
    }
  },
  {
    Header: i18n.t('Date'),
    accessor: 'date',
    Cell: ({ value, row }) => {
      return i18ndayjs(value, 'D MMM YYYY')
    },
    sortDescFirst: true,
    className: 'textCenter col100',
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(rows, ['date'], filterValue),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    }
  },
  {
    Header: i18n.t('Type'),
    accessor: 'special_procedure_cu_type.name',
    className: 'textCenter col125',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          style={{ minWidth: '130px' }}
          column={column}
          options={(specialProceduresCuTypes
            ? specialProceduresCuTypes
            : cSpecialProceduresCuTypes().filter(tb => !tb.hideInFilter)
          ).map(tb => ({ id: tb.name, value: tb.name }))}
        />
      )
    }
  },
  {
    Header: i18n.t('Details'),
    accessor: 'text',
    Cell: ({ value, row }) => {
      if (i18n.language !== 'en' && row.original.is_auto_translate) {
        return <AutoTranslateCell mainText={value} />
      }
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            row={row}
            sources={null}
            unik={row.original.id + '_explan'}
          />
        )
      else return <></>
    },

    NonExpandableCell: ({ value, row }) => {
      if (i18n.language !== 'en' && row.original.is_auto_translate) {
        return <AutoTranslateCell mainText={value} />
      }
      return (
        <SourceText
          text={value}
          skipSources={0}
          sources={row.original.motherEntry.sources}
          unik={row.original.id + '_explan'}
        />
      )
    },
    sortType: (rowA, rowB, id, desc) => {
      if (!rowB.original.general_explan) return null
      if (
        rowA.original.general_explan.replace(/[^\w\s]/gi, '') >
        rowB.original.general_explan.replace(/[^\w\s]/gi, '')
      )
        return -1
      if (
        rowB.original.general_explan.replace(/[^\w\s]/gi, '') >
        rowA.original.general_explan.replace(/[^\w\s]/gi, '')
      )
        return 1
      return 0
    },
    disableSortBy: true,
    className: 'col300'
  },
  {
    Header: i18n.t('Topics'),
    accessor: 'motherEntry.topics',
    Cell: ({ value, row }) => {
      return row.original.motherEntry.topics
        ? row.original.motherEntry.topics.map((topic, index) => (
            <Fragment key={row.original + index}>
              <div className="tableTopic">{topic.name}</div>
              <div className="topicSeparator" />
            </Fragment>
          ))
        : ''
    },
    Filter: ({ column, rows }) => {
      return <TopicFilter column={column} search={true} rows={rows} />
    },
    filter: ApplyTopicFilter,
    className: 'textCenter col140',
    disableSortBy: true
  },
  /*{
    Header: i18n.t("Scope"),
    accessor: "scope.name",
    className: "textCenter col100",
    filter: (rows, columnsId, filterValue) => ApplyGenericDropdownFilter(rows, "scope.name", filterValue),
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width="200px"
          column={column}
          options={[
            { id: i18n.t("General"), value: i18n.t("General") },
            { id: i18n.t("GIE focused"), value: i18n.t("GIE focused") },
            { id: i18n.t("SC focused"), value: i18n.t("SC focused") }
          ]}
          ]}
        />
      );
    }
  },*/
  {
    Header: i18n.t('Source'),
    accessor: 'source',
    Cell: ({ value, row }) => {
      if (row.original.motherEntry.sources)
        return (
          <div className={'tableSources'}>
            {row.original.motherEntry.sources[0] && (
              <div
                className={
                  row.original.special_procedure_cu_type.id === '7'
                    ? 'textCenterW100'
                    : ''
                }
              >
                <a
                  href={
                    process.env.REACT_APP_BACKEND_URL +
                    '/downloader/download/4/' +
                    (row.original.motherEntry.sources.find(
                      s =>
                        s.original_language.id ===
                        (i18n.language === 'en' ? '39' : '41')
                    )
                      ? row.original.motherEntry.sources.find(
                          s =>
                            s.original_language.id ===
                            (i18n.language === 'en' ? '39' : '41')
                        ).original_filename
                      : row.original.motherEntry.sources[0].original_filename)
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {row.original.title}
                </a>
              </div>
            )}
            {row.original.special_procedure_cu_type.id !== '7' && (
              <>
                {' '}
                <div>{row.original.un_symbol}</div>
                <div>
                  {i18n.t('Para')}. {row.original.para}
                </div>
                <div>
                  {i18n.t('Session')}: {row.original.session}
                </div>
                <div>{row.original.text5}</div>
              </>
            )}
          </div>
        )
      else return <></>
    },
    className: 'col140',
    disableSortBy: true,
    disableFilter: true
  }
]
