import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GET_UPCOMING_DEADLINES = gql(`
  query GetUpcomingDeadlines($lang: String, $jur_id: String) {
    upcomingDeadlines(lang: $lang, jur_id: $jur_id) {
      id
      jurisdiction {
        id
        a2_code
        name
        un_member
      }
      date
      un_mechanism{
        id
        name
        short_name
      }
      text
      link1
      link1_text
      link2
      link2_text
      link3
      link3_text
      link4
      link4_text
    }
}
`);

function useGqlUpcomingDeadlines(jur_id) {
  const { i18n } = useTranslation();
  const upcomingDeadlines = useQuery(GET_UPCOMING_DEADLINES, { variables: { jur_id, lang: i18n.language } });

  return { upcomingDeadlines: upcomingDeadlines.data && upcomingDeadlines.data.upcomingDeadlines };
}

export default useGqlUpcomingDeadlines;
