var map = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: { name: "Valencia", id: "ES-VC", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-VC",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.4505, 40.1426],
              [-1.4449, 40.118],
              [-1.4173, 40.0925],
              [-1.3833, 40.0415],
              [-1.3762, 40.0187],
              [-1.2836, 40.0072],
              [-1.2582, 39.9964],
              [-1.1891, 40.0029],
              [-1.1642, 40.0114],
              [-1.1313, 40.017],
              [-1.0825, 40.037],
              [-1.0705, 40.0504],
              [-1.0809, 40.0712],
              [-1.147, 40.1133],
              [-1.2121, 40.1099],
              [-1.2441, 40.117],
              [-1.2529, 40.1432],
              [-1.3131, 40.2024],
              [-1.3228, 40.1719],
              [-1.3164, 40.1498],
              [-1.3516, 40.1295],
              [-1.4505, 40.1426]
            ].reverse()
          ],
          [
            [
              [-1.141, 39.9704],
              [-1.2041, 39.9492],
              [-1.2109, 39.9328],
              [-1.1965, 39.913],
              [-1.2008, 39.8549],
              [-1.2169, 39.8058],
              [-1.2748, 39.7396],
              [-1.2629, 39.6975],
              [-1.279, 39.6782],
              [-1.3142, 39.6699],
              [-1.3698, 39.6882],
              [-1.4183, 39.6544],
              [-1.4554, 39.5873],
              [-1.4747, 39.5695],
              [-1.4997, 39.5647],
              [-1.5126, 39.4579],
              [-1.5107, 39.4341],
              [-1.4881, 39.4025],
              [-1.4199, 39.3816],
              [-1.3829, 39.3654],
              [-1.3351, 39.3329],
              [-1.3115, 39.3425],
              [-1.2955, 39.3308],
              [-1.2169, 39.3164],
              [-1.1772, 39.3146],
              [-1.1626, 39.3058],
              [-1.1749, 39.2781],
              [-1.1765, 39.2319],
              [-1.1975, 39.1756],
              [-1.2265, 39.1513],
              [-1.2396, 39.1144],
              [-1.2551, 39.1064],
              [-1.2641, 39.0763],
              [-1.2629, 39.0459],
              [-1.2256, 39.0243],
              [-1.1596, 38.9541],
              [-1.1436, 38.9285],
              [-1.1078, 38.9286],
              [-0.9756, 38.9416],
              [-0.9394, 38.8992],
              [-0.9239, 38.8914],
              [-0.9359, 38.8553],
              [-0.9282, 38.7845],
              [-0.9604, 38.7734],
              [-0.9416, 38.7305],
              [-0.9152, 38.696],
              [-0.9606, 38.6574],
              [-1.0267, 38.6556],
              [-1.0161, 38.6336],
              [-1.0015, 38.5733],
              [-1.0254, 38.5229],
              [-1.0138, 38.4963],
              [-1.0686, 38.4482],
              [-1.0848, 38.4284],
              [-1.0892, 38.3657],
              [-1.0849, 38.3467],
              [-1.0172, 38.334],
              [-0.9784, 38.3371],
              [-0.9594, 38.3051],
              [-0.9564, 38.2682],
              [-0.9783, 38.2106],
              [-0.998, 38.1766],
              [-1.0274, 38.1468],
              [-1.0149, 38.0828],
              [-0.954, 38.0133],
              [-0.9449, 37.979],
              [-0.9188, 37.9408],
              [-0.8398, 37.8693],
              [-0.784, 37.8448],
              [-0.7631, 37.8472],
              [-0.7516, 37.8974],
              [-0.7057, 37.9405],
              [-0.6993, 37.9666],
              [-0.6576, 37.9839],
              [-0.6495, 38.0106],
              [-0.6482, 38.0529],
              [-0.6335, 38.1437],
              [-0.5925, 38.1885],
              [-0.5357, 38.1876],
              [-0.5109, 38.1985],
              [-0.517, 38.3023],
              [-0.503, 38.3336],
              [-0.4464, 38.3611],
              [-0.4139, 38.3509],
              [-0.4018, 38.4105],
              [-0.3757, 38.4418],
              [-0.3033, 38.4811],
              [-0.239, 38.4989],
              [-0.2246, 38.5078],
              [-0.1733, 38.5156],
              [-0.1547, 38.5337],
              [-0.1095, 38.5328],
              [-0.0974, 38.5239],
              [-0.0598, 38.5513],
              [-0.0577, 38.5892],
              [-0.0156, 38.6269],
              [0.0296, 38.6258],
              [0.0451, 38.6412],
              [0.0707, 38.6387],
              [0.0984, 38.673],
              [0.148, 38.6868],
              [0.1687, 38.711],
              [0.2074, 38.732],
              [0.2314, 38.7315],
              [0.2203, 38.7601],
              [0.1881, 38.78],
              [0.1917, 38.808],
              [0.141, 38.8343],
              [0.1249, 38.8332],
              [0.0925, 38.8543],
              [0.0351, 38.8605],
              [-0.014, 38.8721],
              [-0.0768, 38.9157],
              [-0.14, 38.9745],
              [-0.1992, 39.059],
              [-0.2367, 39.1431],
              [-0.2403, 39.1657],
              [-0.2209, 39.188],
              [-0.2938, 39.3075],
              [-0.3154, 39.3546],
              [-0.3362, 39.4361],
              [-0.3238, 39.4586],
              [-0.3253, 39.4921],
              [-0.3066, 39.5343],
              [-0.2746, 39.5736],
              [-0.2666, 39.6022],
              [-0.2026, 39.6724],
              [-0.1859, 39.7255],
              [-0.0838, 39.8574],
              [-0.0672, 39.8628],
              [-0.0051, 39.9163],
              [0.0084, 39.9644],
              [0.0537, 40.0362],
              [0.0839, 40.0551],
              [0.1072, 40.0557],
              [0.1471, 40.0887],
              [0.1836, 40.1601],
              [0.2321, 40.1977],
              [0.2667, 40.2083],
              [0.2807, 40.2441],
              [0.3307, 40.2789],
              [0.3551, 40.312],
              [0.399, 40.3537],
              [0.4184, 40.3998],
              [0.4487, 40.4391],
              [0.4847, 40.4747],
              [0.5141, 40.5227],
              [0.4383, 40.5474],
              [0.4319, 40.5788],
              [0.387, 40.6068],
              [0.3396, 40.6081],
              [0.2786, 40.6308],
              [0.2682, 40.6583],
              [0.2925, 40.685],
              [0.2624, 40.7055],
              [0.2373, 40.7003],
              [0.2239, 40.7255],
              [0.1699, 40.7336],
              [0.1471, 40.7192],
              [0.1081, 40.7271],
              [0.0746, 40.7152],
              [0.0441, 40.6931],
              [0.0141, 40.7293],
              [-0.0631, 40.7275],
              [-0.1242, 40.7523],
              [-0.1448, 40.785],
              [-0.1967, 40.7836],
              [-0.2255, 40.7525],
              [-0.2395, 40.7156],
              [-0.237, 40.691],
              [-0.2948, 40.6735],
              [-0.3576, 40.6771],
              [-0.3788, 40.6229],
              [-0.3239, 40.6029],
              [-0.2917, 40.6044],
              [-0.2962, 40.5609],
              [-0.2907, 40.5436],
              [-0.3002, 40.5144],
              [-0.2763, 40.4988],
              [-0.2718, 40.4762],
              [-0.3393, 40.4524],
              [-0.3319, 40.4224],
              [-0.2867, 40.3871],
              [-0.2811, 40.3692],
              [-0.3055, 40.3618],
              [-0.3439, 40.3248],
              [-0.3759, 40.3049],
              [-0.3896, 40.2863],
              [-0.3836, 40.2637],
              [-0.4516, 40.2355],
              [-0.511, 40.2309],
              [-0.5485, 40.2443],
              [-0.558, 40.2001],
              [-0.5712, 40.1795],
              [-0.5705, 40.1557],
              [-0.5851, 40.1314],
              [-0.6097, 40.1265],
              [-0.6279, 40.1034],
              [-0.631, 40.0743],
              [-0.6631, 40.0514],
              [-0.7187, 40.0395],
              [-0.7618, 40.0413],
              [-0.7662, 40.012],
              [-0.7863, 39.9902],
              [-0.8343, 39.9774],
              [-0.8365, 39.9241],
              [-0.7951, 39.8814],
              [-0.8283, 39.8715],
              [-0.8665, 39.8472],
              [-0.909, 39.8672],
              [-0.901, 39.8899],
              [-0.9134, 39.9176],
              [-0.9047, 39.9392],
              [-0.9689, 39.974],
              [-1.0033, 39.9808],
              [-1.0247, 39.9735],
              [-1.0551, 39.9792],
              [-1.141, 39.9704]
            ].reverse()
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "País Vasco", id: "ES-PV", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-PV",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.7318, 43.2992],
              [-1.7527, 43.3335],
              [-1.7914, 43.3511],
              [-1.7887, 43.3894],
              [-1.8331, 43.3836],
              [-1.8702, 43.3687],
              [-1.8868, 43.3486],
              [-1.9164, 43.3367],
              [-1.9619, 43.3383],
              [-1.9876, 43.3182],
              [-2.0093, 43.3246],
              [-2.1415, 43.2941],
              [-2.1763, 43.2887],
              [-2.2285, 43.3127],
              [-2.2831, 43.2966],
              [-2.3674, 43.3071],
              [-2.4349, 43.3347],
              [-2.4609, 43.3371],
              [-2.5092, 43.3779],
              [-2.5487, 43.3747],
              [-2.617, 43.3926],
              [-2.6487, 43.4167],
              [-2.691, 43.4072],
              [-2.7385, 43.4306],
              [-2.7522, 43.4573],
              [-2.8048, 43.4315],
              [-2.8657, 43.4314],
              [-2.9026, 43.4389],
              [-2.9454, 43.4366],
              [-2.9574, 43.416],
              [-3.0054, 43.3826],
              [-3.0186, 43.3263],
              [-3.106, 43.3648],
              [-3.1186, 43.3479],
              [-3.1525, 43.354],
              [-3.1567, 43.3019],
              [-3.2176, 43.2875],
              [-3.2244, 43.3091],
              [-3.2781, 43.2903],
              [-3.2938, 43.3008],
              [-3.338, 43.2785],
              [-3.3816, 43.2707],
              [-3.3983, 43.2467],
              [-3.4482, 43.2367],
              [-3.4333, 43.2102],
              [-3.4296, 43.1667],
              [-3.4164, 43.1348],
              [-3.3869, 43.1414],
              [-3.3099, 43.1737],
              [-3.2774, 43.1953],
              [-3.2488, 43.198],
              [-3.2212, 43.1727],
              [-3.1842, 43.1706],
              [-3.1569, 43.1779],
              [-3.1426, 43.1613],
              [-3.1799, 43.1149],
              [-3.1352, 43.0973],
              [-3.1471, 43.035],
              [-3.1735, 43.0184],
              [-3.1458, 43.007],
              [-3.0622, 43.0029],
              [-3.0424, 42.9705],
              [-2.9807, 42.9386],
              [-3.0202, 42.9086],
              [-3.0937, 42.8999],
              [-3.1264, 42.9042],
              [-3.1493, 42.9191],
              [-3.1466, 42.9345],
              [-3.2272, 42.9494],
              [-3.2497, 42.9125],
              [-3.278, 42.9008],
              [-3.2831, 42.8758],
              [-3.2231, 42.828],
              [-3.1912, 42.8693],
              [-3.1496, 42.8604],
              [-3.1239, 42.8918],
              [-3.1055, 42.8792],
              [-3.1579, 42.804],
              [-3.124, 42.7886],
              [-3.145, 42.7566],
              [-3.0807, 42.7654],
              [-3.0171, 42.7365],
              [-2.987, 42.7081],
              [-2.9305, 42.707],
              [-2.9027, 42.6928],
              [-2.8968, 42.6541],
              [-2.8583, 42.6378],
              [-2.8153, 42.5686],
              [-2.7799, 42.58],
              [-2.7648, 42.6221],
              [-2.7313, 42.6171],
              [-2.683, 42.5951],
              [-2.6929, 42.5406],
              [-2.6873, 42.5201],
              [-2.6569, 42.503],
              [-2.5545, 42.4836],
              [-2.5148, 42.49],
              [-2.4772, 42.486],
              [-2.4474, 42.4959],
              [-2.4218, 42.4892],
              [-2.4227, 42.51],
              [-2.3927, 42.522],
              [-2.3902, 42.5596],
              [-2.399, 42.5886],
              [-2.4179, 42.6064],
              [-2.4519, 42.5676],
              [-2.4624, 42.5871],
              [-2.4901, 42.5863],
              [-2.4837, 42.6121],
              [-2.4509, 42.6462],
              [-2.4149, 42.6618],
              [-2.3832, 42.6559],
              [-2.3755, 42.6375],
              [-2.2881, 42.6556],
              [-2.3108, 42.6715],
              [-2.3132, 42.7084],
              [-2.3043, 42.7353],
              [-2.2657, 42.7526],
              [-2.2791, 42.7878],
              [-2.2702, 42.8088],
              [-2.232, 42.8433],
              [-2.242, 42.8861],
              [-2.238, 42.9196],
              [-2.2094, 42.9489],
              [-2.1801, 42.9346],
              [-2.1269, 42.9395],
              [-2.1036, 42.9477],
              [-2.0864, 42.9747],
              [-2.0389, 42.9799],
              [-2.0281, 42.9975],
              [-2.0416, 43.0308],
              [-2.0214, 43.0471],
              [-2.0135, 43.0727],
              [-1.9483, 43.0973],
              [-1.9034, 43.1351],
              [-1.9212, 43.1527],
              [-1.9131, 43.2152],
              [-1.8239, 43.2287],
              [-1.7951, 43.2451],
              [-1.7934, 43.2759],
              [-1.7318, 43.2992]
            ].reverse()
          ],
          [
            [
              [-3.2832, 43.1998],
              [-3.2644, 43.2079],
              [-3.268, 43.2325],
              [-3.248, 43.2606],
              [-3.2652, 43.2676],
              [-3.3035, 43.2596],
              [-3.2915, 43.2399],
              [-3.294, 43.2061],
              [-3.2832, 43.1998]
            ].reverse()
          ],
          [
            [
              [-2.8331, 42.7937],
              [-2.8612, 42.7451],
              [-2.8241, 42.7081],
              [-2.7849, 42.6978],
              [-2.7674, 42.6657],
              [-2.7181, 42.6616],
              [-2.7041, 42.6736],
              [-2.6519, 42.6605],
              [-2.6355, 42.6472],
              [-2.5834, 42.6513],
              [-2.5481, 42.6385],
              [-2.5168, 42.6473],
              [-2.5227, 42.6836],
              [-2.5491, 42.6693],
              [-2.5793, 42.6735],
              [-2.6089, 42.6963],
              [-2.563, 42.7405],
              [-2.5896, 42.7502],
              [-2.6037, 42.77],
              [-2.6513, 42.7666],
              [-2.7319, 42.7932],
              [-2.8331, 42.7937]
            ].reverse()
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "Islas Baleares", id: "ES-IB", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-IB",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.5369, 39.1185],
              [1.5151, 39.1178],
              [1.4983, 39.0994],
              [1.4747, 39.106],
              [1.4182, 39.079],
              [1.3895, 39.0806],
              [1.3575, 39.0698],
              [1.3425, 39.0545],
              [1.2923, 39.0293],
              [1.3014, 38.9697],
              [1.2842, 38.9751],
              [1.2339, 38.9698],
              [1.2158, 38.9583],
              [1.2372, 38.9405],
              [1.2151, 38.8985],
              [1.2442, 38.8678],
              [1.2733, 38.8801],
              [1.3066, 38.8689],
              [1.3456, 38.8717],
              [1.3713, 38.8454],
              [1.4035, 38.8454],
              [1.4063, 38.8818],
              [1.4408, 38.9177],
              [1.4803, 38.9167],
              [1.5301, 38.9464],
              [1.5399, 38.9872],
              [1.5816, 38.9946],
              [1.5839, 39.0102],
              [1.6194, 39.038],
              [1.5966, 39.053],
              [1.5826, 39.0991],
              [1.559, 39.0946],
              [1.5369, 39.1185]
            ].reverse()
          ],
          [
            [
              [1.4405, 38.7488],
              [1.4242, 38.7348],
              [1.3879, 38.7228],
              [1.3823, 38.6939],
              [1.3852, 38.6486],
              [1.4084, 38.6495],
              [1.4443, 38.6854],
              [1.4781, 38.6832],
              [1.5257, 38.6541],
              [1.5745, 38.6527],
              [1.5834, 38.6797],
              [1.565, 38.6933],
              [1.5313, 38.6742],
              [1.4812, 38.7038],
              [1.4729, 38.7207],
              [1.4405, 38.7488]
            ].reverse()
          ],
          [
            [
              [3.2133, 39.9598],
              [3.1821, 39.9525],
              [3.1518, 39.9561],
              [3.1208, 39.9328],
              [3.1003, 39.9369],
              [3.0748, 39.9236],
              [3.0321, 39.9361],
              [2.9845, 39.9097],
              [2.9519, 39.9214],
              [2.933, 39.9031],
              [2.9066, 39.8989],
              [2.8759, 39.879],
              [2.8263, 39.8583],
              [2.7927, 39.8583],
              [2.7766, 39.8421],
              [2.7429, 39.8342],
              [2.6703, 39.7944],
              [2.6672, 39.7778],
              [2.5569, 39.7021],
              [2.5299, 39.7033],
              [2.4629, 39.6535],
              [2.4238, 39.6394],
              [2.3862, 39.6148],
              [2.3697, 39.6151],
              [2.3454, 39.588],
              [2.3536, 39.5649],
              [2.3911, 39.5226],
              [2.4198, 39.5393],
              [2.4532, 39.5374],
              [2.4665, 39.526],
              [2.4599, 39.4999],
              [2.5021, 39.462],
              [2.5373, 39.489],
              [2.5402, 39.5249],
              [2.6208, 39.5459],
              [2.6297, 39.5655],
              [2.6729, 39.5609],
              [2.7285, 39.5315],
              [2.7513, 39.5024],
              [2.7226, 39.4771],
              [2.7478, 39.4377],
              [2.7396, 39.4129],
              [2.7885, 39.3634],
              [2.8302, 39.3569],
              [2.8452, 39.3665],
              [2.9064, 39.358],
              [2.9565, 39.3635],
              [2.9904, 39.3387],
              [2.9848, 39.3221],
              [3.0145, 39.3105],
              [3.0189, 39.2959],
              [3.0549, 39.265],
              [3.0756, 39.2711],
              [3.1193, 39.3131],
              [3.1426, 39.3264],
              [3.1729, 39.3273],
              [3.2047, 39.3512],
              [3.2349, 39.3639],
              [3.2548, 39.4008],
              [3.2782, 39.4245],
              [3.28, 39.452],
              [3.2976, 39.5002],
              [3.3413, 39.5408],
              [3.3672, 39.5512],
              [3.3893, 39.5871],
              [3.3866, 39.6008],
              [3.4063, 39.637],
              [3.4355, 39.6337],
              [3.4544, 39.6555],
              [3.4628, 39.7394],
              [3.3758, 39.7691],
              [3.3474, 39.7903],
              [3.3259, 39.7589],
              [3.2546, 39.7303],
              [3.214, 39.7419],
              [3.1557, 39.7684],
              [3.1302, 39.7912],
              [3.1239, 39.835],
              [3.1808, 39.8422],
              [3.1976, 39.8612],
              [3.1742, 39.8786],
              [3.1419, 39.8655],
              [3.1017, 39.8597],
              [3.0802, 39.8882],
              [3.0933, 39.9116],
              [3.1126, 39.9052],
              [3.1367, 39.9284],
              [3.1562, 39.9237],
              [3.2035, 39.9443],
              [3.2133, 39.9598]
            ].reverse()
          ],
          [[[2.9548, 39.1652], [2.9263, 39.1332], [2.9483, 39.126], [2.9716, 39.1502], [2.9548, 39.1652]].reverse()],
          [
            [
              [4.169, 39.8497],
              [4.2387, 39.8164],
              [4.2736, 39.8095],
              [4.2973, 39.8235],
              [4.3084, 39.8657],
              [4.3068, 39.8976],
              [4.2763, 39.9331],
              [4.2819, 39.95],
              [4.2575, 39.966],
              [4.2532, 39.999],
              [4.2213, 39.9973],
              [4.2043, 40.0193],
              [4.1743, 40.0248],
              [4.1572, 40.0686],
              [4.1124, 40.055],
              [4.0781, 40.0597],
              [3.9603, 40.0616],
              [3.9236, 40.0464],
              [3.9005, 40.0602],
              [3.8815, 40.0536],
              [3.828, 40.0533],
              [3.7966, 40.001],
              [3.8313, 39.9941],
              [3.8359, 39.9612],
              [3.8236, 39.9244],
              [3.9077, 39.9245],
              [3.9391, 39.9388],
              [4.0271, 39.9208],
              [4.08, 39.8964],
              [4.0877, 39.8863],
              [4.169, 39.8497]
            ].reverse()
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "Navarra", id: "ES-NC", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-NC",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.7244, 42.9195],
            [-0.7305, 42.9454],
            [-0.7676, 42.9689],
            [-0.8091, 42.9512],
            [-0.86, 42.9507],
            [-0.9145, 42.9634],
            [-0.944, 42.9534],
            [-0.9968, 42.9749],
            [-1.0067, 42.989],
            [-1.0599, 42.9957],
            [-1.0858, 43.0111],
            [-1.1235, 43.0154],
            [-1.1737, 43.0379],
            [-1.2301, 43.0555],
            [-1.247, 43.0431],
            [-1.31, 43.0696],
            [-1.2908, 43.1158],
            [-1.3199, 43.1133],
            [-1.3462, 43.0912],
            [-1.3431, 43.0545],
            [-1.3542, 43.0286],
            [-1.4403, 43.0463],
            [-1.4734, 43.0884],
            [-1.4146, 43.1277],
            [-1.402, 43.1777],
            [-1.3829, 43.1878],
            [-1.3825, 43.2519],
            [-1.4042, 43.2688],
            [-1.4375, 43.2663],
            [-1.5351, 43.2938],
            [-1.5567, 43.28],
            [-1.5763, 43.2496],
            [-1.6161, 43.2558],
            [-1.6309, 43.2831],
            [-1.6225, 43.3018],
            [-1.6693, 43.3152],
            [-1.7318, 43.2992],
            [-1.7934, 43.2759],
            [-1.7951, 43.2451],
            [-1.8239, 43.2287],
            [-1.9131, 43.2152],
            [-1.9212, 43.1527],
            [-1.9034, 43.1351],
            [-1.9483, 43.0973],
            [-2.0135, 43.0727],
            [-2.0214, 43.0471],
            [-2.0416, 43.0308],
            [-2.0281, 42.9975],
            [-2.0389, 42.9799],
            [-2.0864, 42.9747],
            [-2.1036, 42.9477],
            [-2.1269, 42.9395],
            [-2.1801, 42.9346],
            [-2.2094, 42.9489],
            [-2.238, 42.9196],
            [-2.242, 42.8861],
            [-2.232, 42.8433],
            [-2.2702, 42.8088],
            [-2.2791, 42.7878],
            [-2.2657, 42.7526],
            [-2.3043, 42.7353],
            [-2.3132, 42.7084],
            [-2.3108, 42.6715],
            [-2.2881, 42.6556],
            [-2.3755, 42.6375],
            [-2.3832, 42.6559],
            [-2.4149, 42.6618],
            [-2.4509, 42.6462],
            [-2.4837, 42.6121],
            [-2.4901, 42.5863],
            [-2.4624, 42.5871],
            [-2.4519, 42.5676],
            [-2.4179, 42.6064],
            [-2.399, 42.5886],
            [-2.3902, 42.5596],
            [-2.3927, 42.522],
            [-2.4227, 42.51],
            [-2.4218, 42.4892],
            [-2.3686, 42.4618],
            [-2.3362, 42.4649],
            [-2.2945, 42.4587],
            [-2.2073, 42.414],
            [-2.1752, 42.4073],
            [-2.1357, 42.4209],
            [-2.0793, 42.368],
            [-2.0689, 42.3429],
            [-2.0183, 42.3685],
            [-1.9723, 42.3528],
            [-1.9673, 42.3355],
            [-1.9405, 42.333],
            [-1.91, 42.3119],
            [-1.9154, 42.3002],
            [-1.8658, 42.2827],
            [-1.8508, 42.2587],
            [-1.8148, 42.228],
            [-1.7823, 42.2262],
            [-1.7071, 42.2086],
            [-1.7008, 42.1703],
            [-1.6815, 42.151],
            [-1.719, 42.133],
            [-1.7447, 42.1504],
            [-1.7847, 42.1381],
            [-1.8209, 42.1533],
            [-1.8393, 42.1297],
            [-1.8938, 42.0849],
            [-1.9073, 42.0583],
            [-1.9015, 42.0323],
            [-1.859, 42.0051],
            [-1.8462, 42.008],
            [-1.8358, 41.993],
            [-1.7944, 41.9883],
            [-1.7671, 41.9937],
            [-1.7328, 41.9622],
            [-1.6868, 41.9527],
            [-1.674, 41.9657],
            [-1.6116, 41.9486],
            [-1.5958, 41.9268],
            [-1.5221, 41.9102],
            [-1.5007, 41.9231],
            [-1.4169, 41.9135],
            [-1.3804, 41.9435],
            [-1.3634, 41.984],
            [-1.3315, 42.0254],
            [-1.3079, 42.041],
            [-1.312, 42.0731],
            [-1.3529, 42.0728],
            [-1.3675, 42.106],
            [-1.3983, 42.1242],
            [-1.3927, 42.1805],
            [-1.4143, 42.2012],
            [-1.4155, 42.2236],
            [-1.3921, 42.2736],
            [-1.3994, 42.2895],
            [-1.365, 42.3285],
            [-1.3337, 42.3477],
            [-1.3351, 42.3705],
            [-1.3528, 42.3865],
            [-1.3412, 42.4236],
            [-1.3164, 42.4482],
            [-1.2932, 42.4546],
            [-1.2738, 42.481],
            [-1.2888, 42.5229],
            [-1.2653, 42.5565],
            [-1.2299, 42.542],
            [-1.2037, 42.5488],
            [-1.2048, 42.5722],
            [-1.16, 42.6004],
            [-1.1531, 42.6483],
            [-1.0408, 42.6472],
            [-1.0401, 42.6911],
            [-1.0175, 42.7024],
            [-0.9696, 42.7037],
            [-0.9494, 42.7117],
            [-0.936, 42.7349],
            [-0.8732, 42.7591],
            [-0.8483, 42.7839],
            [-0.856, 42.8468],
            [-0.8196, 42.8681],
            [-0.8163, 42.9023],
            [-0.782, 42.9215],
            [-0.7244, 42.9195]
          ].reverse()
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "Murcia", id: "ES-MC", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-MC",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.3395, 38.0258],
            [-2.2988, 38.0032],
            [-2.2836, 37.9676],
            [-2.2544, 37.9397],
            [-2.189, 37.9057],
            [-2.1724, 37.8886],
            [-2.1223, 37.8996],
            [-2.0993, 37.8773],
            [-2.0731, 37.883],
            [-2.022, 37.8682],
            [-2.006, 37.8797],
            [-1.9757, 37.8696],
            [-1.9956, 37.8435],
            [-2.0095, 37.774],
            [-1.9957, 37.7286],
            [-2.0076, 37.7197],
            [-2.0119, 37.6722],
            [-1.9811, 37.6349],
            [-1.978, 37.6198],
            [-1.938, 37.5678],
            [-1.906, 37.5335],
            [-1.8451, 37.4552],
            [-1.7353, 37.4414],
            [-1.6267, 37.3734],
            [-1.6033, 37.3945],
            [-1.5609, 37.4036],
            [-1.5184, 37.4306],
            [-1.4836, 37.4352],
            [-1.4645, 37.4799],
            [-1.44, 37.4975],
            [-1.4, 37.5096],
            [-1.3638, 37.5428],
            [-1.3258, 37.5622],
            [-1.2658, 37.56],
            [-1.2445, 37.5762],
            [-1.2026, 37.5736],
            [-1.1685, 37.5519],
            [-1.1648, 37.5361],
            [-1.1246, 37.5343],
            [-1.1188, 37.5569],
            [-1.0544, 37.5785],
            [-1.0129, 37.5697],
            [-1.0031, 37.5816],
            [-0.9509, 37.5697],
            [-0.9483, 37.5541],
            [-0.9166, 37.5499],
            [-0.8427, 37.5811],
            [-0.8178, 37.5711],
            [-0.7804, 37.5912],
            [-0.7162, 37.6048],
            [-0.7013, 37.6334],
            [-0.7384, 37.6306],
            [-0.801, 37.6572],
            [-0.8277, 37.6784],
            [-0.8574, 37.7141],
            [-0.8477, 37.7475],
            [-0.801, 37.783],
            [-0.7976, 37.8106],
            [-0.7757, 37.8172],
            [-0.7631, 37.8472],
            [-0.784, 37.8448],
            [-0.8398, 37.8693],
            [-0.9188, 37.9408],
            [-0.9449, 37.979],
            [-0.954, 38.0133],
            [-1.0149, 38.0828],
            [-1.0274, 38.1468],
            [-0.998, 38.1766],
            [-0.9783, 38.2106],
            [-0.9564, 38.2682],
            [-0.9594, 38.3051],
            [-0.9784, 38.3371],
            [-1.0172, 38.334],
            [-1.0849, 38.3467],
            [-1.0892, 38.3657],
            [-1.0848, 38.4284],
            [-1.0686, 38.4482],
            [-1.0138, 38.4963],
            [-1.0254, 38.5229],
            [-1.0015, 38.5733],
            [-1.0161, 38.6336],
            [-1.0267, 38.6556],
            [-1.0533, 38.6668],
            [-1.1164, 38.7106],
            [-1.12, 38.7379],
            [-1.1821, 38.7539],
            [-1.2375, 38.7416],
            [-1.2643, 38.728],
            [-1.287, 38.7044],
            [-1.3429, 38.6769],
            [-1.3668, 38.7034],
            [-1.4203, 38.6783],
            [-1.4487, 38.6495],
            [-1.4441, 38.6346],
            [-1.4526, 38.5823],
            [-1.4876, 38.5631],
            [-1.4977, 38.5361],
            [-1.4805, 38.4838],
            [-1.4738, 38.4069],
            [-1.4787, 38.3762],
            [-1.5416, 38.3433],
            [-1.5585, 38.3255],
            [-1.5897, 38.3107],
            [-1.6692, 38.3113],
            [-1.6922, 38.3443],
            [-1.6828, 38.3595],
            [-1.7242, 38.368],
            [-1.7446, 38.3804],
            [-1.7701, 38.3786],
            [-1.7955, 38.3521],
            [-1.8233, 38.3347],
            [-1.853, 38.3307],
            [-1.8933, 38.3023],
            [-1.9826, 38.2811],
            [-2.0475, 38.3057],
            [-2.0657, 38.2951],
            [-2.084, 38.2637],
            [-2.1139, 38.2373],
            [-2.1712, 38.2245],
            [-2.2124, 38.2035],
            [-2.23, 38.163],
            [-2.2448, 38.1494],
            [-2.2668, 38.1081],
            [-2.3297, 38.0668],
            [-2.3395, 38.0258]
          ].reverse()
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "Madrid", id: "ES-MD", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-MD",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.5389, 41.1637],
              [-3.5889, 41.1587],
              [-3.6313, 41.136],
              [-3.6355, 41.1229],
              [-3.6765, 41.0835],
              [-3.7392, 41.0543],
              [-3.7798, 41.002],
              [-3.8109, 40.9871],
              [-3.8778, 40.9754],
              [-3.919, 40.9412],
              [-3.9527, 40.8946],
              [-3.9468, 40.8679],
              [-3.9655, 40.8348],
              [-3.9615, 40.8209],
              [-3.9786, 40.7872],
              [-4.0173, 40.7817],
              [-4.0701, 40.7932],
              [-4.1609, 40.689],
              [-4.173, 40.6777],
              [-4.1617, 40.6238],
              [-4.2135, 40.6065],
              [-4.2466, 40.6033],
              [-4.2905, 40.6286],
              [-4.2791, 40.6058],
              [-4.2895, 40.5655],
              [-4.3216, 40.5515],
              [-4.3273, 40.5099],
              [-4.3206, 40.4517],
              [-4.3408, 40.4381],
              [-4.3285, 40.4121],
              [-4.4142, 40.4114],
              [-4.4427, 40.3852],
              [-4.439, 40.3504],
              [-4.458, 40.3442],
              [-4.4548, 40.3198],
              [-4.502, 40.3156],
              [-4.5269, 40.3447],
              [-4.5404, 40.3297],
              [-4.5293, 40.2932],
              [-4.5688, 40.2586],
              [-4.5598, 40.2279],
              [-4.5791, 40.2164],
              [-4.5448, 40.1995],
              [-4.4738, 40.2306],
              [-4.4367, 40.2371],
              [-4.4341, 40.2586],
              [-4.3554, 40.3072],
              [-4.3574, 40.2716],
              [-4.3409, 40.2398],
              [-4.2955, 40.2188],
              [-4.2694, 40.2364],
              [-4.259, 40.2579],
              [-4.2281, 40.285],
              [-4.186, 40.2956],
              [-4.1487, 40.25],
              [-4.1025, 40.2424],
              [-4.0725, 40.2657],
              [-3.9863, 40.2088],
              [-3.9535, 40.2131],
              [-3.9143, 40.1913],
              [-3.8658, 40.1877],
              [-3.8335, 40.1617],
              [-3.8069, 40.1776],
              [-3.7818, 40.1603],
              [-3.771, 40.1393],
              [-3.7405, 40.1341],
              [-3.7206, 40.1476],
              [-3.6921, 40.133],
              [-3.6738, 40.141],
              [-3.6393, 40.1188],
              [-3.6074, 40.1084],
              [-3.6032, 40.0926],
              [-3.63, 40.054],
              [-3.6862, 40.0142],
              [-3.712, 40.0099],
              [-3.7358, 39.9806],
              [-3.7483, 39.9515],
              [-3.8557, 39.9392],
              [-3.8752, 39.9104],
              [-3.7996, 39.8876],
              [-3.7453, 39.9259],
              [-3.7391, 39.9391],
              [-3.7042, 39.9534],
              [-3.6915, 39.9473],
              [-3.6502, 39.9644],
              [-3.6208, 39.9667],
              [-3.6321, 39.9877],
              [-3.5941, 40.0015],
              [-3.5888, 40.0129],
              [-3.5267, 40.0187],
              [-3.5106, 40.0455],
              [-3.4174, 40.0445],
              [-3.402, 40.0342],
              [-3.3772, 40.05],
              [-3.3768, 40.0744],
              [-3.3297, 40.0806],
              [-3.3017, 40.0529],
              [-3.274, 40.0475],
              [-3.2061, 40.0636],
              [-3.1787, 40.0915],
              [-3.1607, 40.0647],
              [-3.1212, 40.0617],
              [-3.0926, 40.0682],
              [-3.0535, 40.1056],
              [-3.0732, 40.1309],
              [-3.0959, 40.1412],
              [-3.0765, 40.1654],
              [-3.0854, 40.1862],
              [-3.0809, 40.2295],
              [-3.1125, 40.2864],
              [-3.1602, 40.2534],
              [-3.173, 40.2349],
              [-3.1956, 40.2482],
              [-3.1857, 40.2689],
              [-3.1817, 40.3094],
              [-3.1521, 40.3343],
              [-3.1527, 40.3683],
              [-3.1308, 40.4051],
              [-3.1556, 40.4395],
              [-3.1986, 40.4423],
              [-3.2004, 40.5126],
              [-3.2485, 40.5421],
              [-3.2717, 40.535],
              [-3.2899, 40.5716],
              [-3.3307, 40.5957],
              [-3.3229, 40.6417],
              [-3.3673, 40.6478],
              [-3.3721, 40.6776],
              [-3.4049, 40.6696],
              [-3.4408, 40.6748],
              [-3.4606, 40.6986],
              [-3.4367, 40.7214],
              [-3.4419, 40.7599],
              [-3.4646, 40.7845],
              [-3.4863, 40.7851],
              [-3.4915, 40.8083],
              [-3.4656, 40.8567],
              [-3.4428, 40.8794],
              [-3.4509, 40.906],
              [-3.434, 40.9458],
              [-3.396, 40.9994],
              [-3.4386, 41.0411],
              [-3.4257, 41.0724],
              [-3.4905, 41.1033],
              [-3.5107, 41.1343],
              [-3.5405, 41.1475],
              [-3.5389, 41.1637]
            ].reverse()
          ],
          [
            [
              [-4.2741, 40.6753],
              [-4.2895, 40.6794],
              [-4.3241, 40.6588],
              [-4.3198, 40.6482],
              [-4.2567, 40.6518],
              [-4.2741, 40.6753]
            ].reverse()
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "La Rioja", id: "ES-RI", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-RI",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.8571, 41.9674],
            [-1.8462, 42.008],
            [-1.859, 42.0051],
            [-1.9015, 42.0323],
            [-1.9073, 42.0583],
            [-1.8938, 42.0849],
            [-1.8393, 42.1297],
            [-1.8209, 42.1533],
            [-1.7847, 42.1381],
            [-1.7447, 42.1504],
            [-1.719, 42.133],
            [-1.6815, 42.151],
            [-1.7008, 42.1703],
            [-1.7071, 42.2086],
            [-1.7823, 42.2262],
            [-1.8148, 42.228],
            [-1.8508, 42.2587],
            [-1.8658, 42.2827],
            [-1.9154, 42.3002],
            [-1.91, 42.3119],
            [-1.9405, 42.333],
            [-1.9673, 42.3355],
            [-1.9723, 42.3528],
            [-2.0183, 42.3685],
            [-2.0689, 42.3429],
            [-2.0793, 42.368],
            [-2.1357, 42.4209],
            [-2.1752, 42.4073],
            [-2.2073, 42.414],
            [-2.2945, 42.4587],
            [-2.3362, 42.4649],
            [-2.3686, 42.4618],
            [-2.4218, 42.4892],
            [-2.4474, 42.4959],
            [-2.4772, 42.486],
            [-2.5148, 42.49],
            [-2.5545, 42.4836],
            [-2.6569, 42.503],
            [-2.6873, 42.5201],
            [-2.6929, 42.5406],
            [-2.683, 42.5951],
            [-2.7313, 42.6171],
            [-2.7648, 42.6221],
            [-2.7799, 42.58],
            [-2.8153, 42.5686],
            [-2.8583, 42.6378],
            [-2.8834, 42.6204],
            [-2.9248, 42.622],
            [-2.9339, 42.6341],
            [-3.0043, 42.6447],
            [-3.077, 42.6391],
            [-3.06, 42.5889],
            [-3.1039, 42.5538],
            [-3.1064, 42.5294],
            [-3.0724, 42.5268],
            [-3.0916, 42.4792],
            [-3.0729, 42.4758],
            [-3.0471, 42.4459],
            [-3.0622, 42.428],
            [-3.0544, 42.3753],
            [-3.0649, 42.356],
            [-3.1091, 42.3516],
            [-3.1067, 42.3128],
            [-3.0896, 42.2454],
            [-3.1038, 42.213],
            [-3.1288, 42.1972],
            [-3.0677, 42.1341],
            [-3.0331, 42.0863],
            [-3.004, 42.0828],
            [-2.9416, 42.0888],
            [-2.9319, 42.0792],
            [-2.9132, 42.0218],
            [-2.8836, 42.0089],
            [-2.8532, 42.0304],
            [-2.7982, 42.0467],
            [-2.8034, 42.0789],
            [-2.7914, 42.1085],
            [-2.7684, 42.1243],
            [-2.7291, 42.1224],
            [-2.7072, 42.1032],
            [-2.7098, 42.0833],
            [-2.7567, 42.032],
            [-2.7455, 42.0086],
            [-2.704, 42.0145],
            [-2.6739, 41.9964],
            [-2.6251, 42.0059],
            [-2.5833, 41.9955],
            [-2.5651, 42.014],
            [-2.5488, 42.0516],
            [-2.516, 42.069],
            [-2.5171, 42.1104],
            [-2.4869, 42.1065],
            [-2.4581, 42.115],
            [-2.4411, 42.1357],
            [-2.3485, 42.1448],
            [-2.3208, 42.1438],
            [-2.2823, 42.1301],
            [-2.282, 42.1074],
            [-2.2587, 42.0862],
            [-2.2355, 42.1015],
            [-2.1474, 42.1031],
            [-2.1275, 42.0802],
            [-2.1599, 42.0665],
            [-2.1503, 42.0418],
            [-2.1191, 42.0218],
            [-2.1135, 41.9609],
            [-2.0278, 41.9517],
            [-1.9824, 41.9189],
            [-1.9383, 41.9257],
            [-1.8571, 41.9674]
          ].reverse()
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "Galicia", id: "ES-GA", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-GA",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.0555, 43.4724],
            [-7.0516, 43.5151],
            [-7.0369, 43.5521],
            [-7.1175, 43.5619],
            [-7.1697, 43.5555],
            [-7.1774, 43.5633],
            [-7.2422, 43.568],
            [-7.2894, 43.595],
            [-7.3305, 43.6279],
            [-7.354, 43.6565],
            [-7.355, 43.6723],
            [-7.3948, 43.6811],
            [-7.4757, 43.7091],
            [-7.467, 43.7252],
            [-7.5193, 43.7355],
            [-7.5478, 43.7333],
            [-7.5757, 43.6942],
            [-7.6113, 43.6763],
            [-7.6127, 43.7058],
            [-7.6311, 43.7507],
            [-7.722, 43.7526],
            [-7.7797, 43.7342],
            [-7.7919, 43.7374],
            [-7.8236, 43.702],
            [-7.8659, 43.7],
            [-7.8488, 43.6767],
            [-7.8709, 43.6635],
            [-7.8959, 43.6743],
            [-7.8534, 43.7262],
            [-7.8686, 43.7337],
            [-7.8578, 43.765],
            [-7.9026, 43.7703],
            [-7.9258, 43.7415],
            [-8.0044, 43.7062],
            [-8.0447, 43.7141],
            [-8.0622, 43.7043],
            [-8.0725, 43.6741],
            [-8.1085, 43.6421],
            [-8.1339, 43.6378],
            [-8.1537, 43.6169],
            [-8.2104, 43.5947],
            [-8.2164, 43.5822],
            [-8.2594, 43.5587],
            [-8.2934, 43.5574],
            [-8.2953, 43.5405],
            [-8.3305, 43.5135],
            [-8.3181, 43.4983],
            [-8.3436, 43.4715],
            [-8.2961, 43.4612],
            [-8.248, 43.4783],
            [-8.1958, 43.4866],
            [-8.2008, 43.4606],
            [-8.2301, 43.4575],
            [-8.2542, 43.4673],
            [-8.3111, 43.4554],
            [-8.2603, 43.4241],
            [-8.224, 43.4288],
            [-8.2215, 43.3879],
            [-8.2089, 43.365],
            [-8.2117, 43.3389],
            [-8.252, 43.3547],
            [-8.271, 43.3885],
            [-8.2941, 43.3863],
            [-8.3063, 43.4071],
            [-8.3493, 43.3966],
            [-8.3383, 43.3815],
            [-8.3472, 43.3532],
            [-8.3855, 43.3378],
            [-8.3917, 43.3881],
            [-8.4357, 43.385],
            [-8.4599, 43.3627],
            [-8.4951, 43.3519],
            [-8.5101, 43.3293],
            [-8.5562, 43.3124],
            [-8.5939, 43.322],
            [-8.7064, 43.2923],
            [-8.7733, 43.3012],
            [-8.8264, 43.3317],
            [-8.8453, 43.3207],
            [-8.91, 43.3231],
            [-8.9078, 43.2985],
            [-8.9583, 43.294],
            [-8.9822, 43.2833],
            [-8.9755, 43.2627],
            [-8.9521, 43.2508],
            [-8.9658, 43.2301],
            [-9.0345, 43.2132],
            [-9.0411, 43.1945],
            [-9.08, 43.1826],
            [-9.1258, 43.1963],
            [-9.1736, 43.1891],
            [-9.199, 43.1554],
            [-9.2038, 43.1297],
            [-9.162, 43.1238],
            [-9.1661, 43.1053],
            [-9.2199, 43.1043],
            [-9.2497, 43.0732],
            [-9.2582, 43.0529],
            [-9.2833, 43.0376],
            [-9.2662, 42.997],
            [-9.3015, 42.9231],
            [-9.2623, 42.9227],
            [-9.2249, 42.943],
            [-9.2077, 42.9183],
            [-9.125, 42.9057],
            [-9.1484, 42.873],
            [-9.1285, 42.8441],
            [-9.1042, 42.8357],
            [-9.1069, 42.814],
            [-9.1458, 42.8028],
            [-9.1106, 42.7611],
            [-9.0751, 42.752],
            [-9.0486, 42.7737],
            [-9.0114, 42.7825],
            [-8.9762, 42.7776],
            [-8.9203, 42.7859],
            [-8.9618, 42.7413],
            [-8.9784, 42.7429],
            [-9.0263, 42.7009],
            [-9.0363, 42.6492],
            [-9.0756, 42.5837],
            [-9.0508, 42.5738],
            [-9.0298, 42.5428],
            [-9.0229, 42.5158],
            [-8.982, 42.5443],
            [-8.9872, 42.565],
            [-8.9562, 42.5811],
            [-8.9225, 42.5853],
            [-8.9367, 42.6072],
            [-8.8937, 42.6403],
            [-8.8541, 42.6287],
            [-8.8498, 42.6648],
            [-8.8255, 42.6678],
            [-8.8201, 42.6463],
            [-8.7932, 42.6358],
            [-8.7666, 42.6058],
            [-8.8065, 42.5753],
            [-8.8287, 42.5748],
            [-8.8356, 42.5505],
            [-8.8073, 42.4931],
            [-8.8546, 42.4716],
            [-8.8575, 42.4933],
            [-8.8823, 42.4978],
            [-8.8955, 42.4792],
            [-8.9359, 42.4769],
            [-8.9388, 42.4607],
            [-8.8845, 42.4562],
            [-8.8679, 42.4124],
            [-8.8377, 42.3832],
            [-8.8134, 42.3999],
            [-8.7625, 42.3926],
            [-8.7398, 42.413],
            [-8.6904, 42.4388],
            [-8.6664, 42.4188],
            [-8.697, 42.3933],
            [-8.7353, 42.3749],
            [-8.7516, 42.3419],
            [-8.7861, 42.3278],
            [-8.8222, 42.3378],
            [-8.8197, 42.281],
            [-8.8468, 42.2975],
            [-8.8643, 42.279],
            [-8.8566, 42.2621],
            [-8.7883, 42.2516],
            [-8.7217, 42.2862],
            [-8.7116, 42.2768],
            [-8.6521, 42.2985],
            [-8.662, 42.3112],
            [-8.6109, 42.3442],
            [-8.6298, 42.2862],
            [-8.6607, 42.2859],
            [-8.6766, 42.2665],
            [-8.7032, 42.2605],
            [-8.741, 42.2276],
            [-8.7741, 42.224],
            [-8.7778, 42.2074],
            [-8.815, 42.1896],
            [-8.8181, 42.1349],
            [-8.8345, 42.114],
            [-8.8629, 42.1225],
            [-8.895, 42.1009],
            [-8.8974, 42.0775],
            [-8.8854, 42.0509],
            [-8.887, 41.988],
            [-8.8825, 41.8834],
            [-8.848, 41.8801],
            [-8.8214, 41.9115],
            [-8.7842, 41.918],
            [-8.7507, 41.9398],
            [-8.7461, 41.964],
            [-8.6796, 41.9918],
            [-8.6601, 42.0093],
            [-8.6575, 42.0293],
            [-8.6354, 42.0476],
            [-8.6007, 42.0554],
            [-8.5691, 42.0521],
            [-8.5265, 42.0627],
            [-8.5215, 42.0792],
            [-8.4445, 42.0818],
            [-8.4255, 42.0718],
            [-8.3786, 42.0787],
            [-8.357, 42.0895],
            [-8.3331, 42.0839],
            [-8.2536, 42.1355],
            [-8.1887, 42.1374],
            [-8.1834, 42.0677],
            [-8.153, 42.0776],
            [-8.1071, 42.0768],
            [-8.0843, 42.0471],
            [-8.0885, 42.0148],
            [-8.1295, 42.0047],
            [-8.1625, 41.9809],
            [-8.1756, 41.9549],
            [-8.2164, 41.9101],
            [-8.193, 41.8754],
            [-8.1702, 41.8754],
            [-8.1621, 41.855],
            [-8.1647, 41.8206],
            [-8.1272, 41.8093],
            [-8.0576, 41.8163],
            [-8.0134, 41.8312],
            [-7.9832, 41.8698],
            [-7.9209, 41.8781],
            [-7.9087, 41.9244],
            [-7.8846, 41.9217],
            [-7.8904, 41.8612],
            [-7.874, 41.8509],
            [-7.8446, 41.8744],
            [-7.7896, 41.883],
            [-7.7584, 41.8984],
            [-7.7376, 41.8923],
            [-7.6976, 41.9066],
            [-7.6586, 41.8828],
            [-7.5862, 41.8799],
            [-7.6145, 41.8325],
            [-7.5688, 41.8316],
            [-7.5074, 41.8499],
            [-7.5111, 41.865],
            [-7.4554, 41.8642],
            [-7.4263, 41.8298],
            [-7.39, 41.8409],
            [-7.314, 41.8438],
            [-7.2491, 41.8675],
            [-7.1974, 41.8802],
            [-7.18, 41.9207],
            [-7.1876, 41.9693],
            [-7.1414, 41.9901],
            [-7.0884, 41.9733],
            [-7.0713, 41.9488],
            [-7.0068, 41.9504],
            [-6.9823, 41.9729],
            [-6.9896, 41.9835],
            [-6.963, 42.0307],
            [-6.9743, 42.054],
            [-7.0082, 42.0515],
            [-7.0319, 42.0753],
            [-7.0056, 42.0842],
            [-6.9865, 42.1233],
            [-6.9427, 42.1392],
            [-6.9398, 42.1763],
            [-6.8875, 42.2011],
            [-6.8838, 42.2158],
            [-6.8418, 42.2278],
            [-6.7867, 42.2152],
            [-6.8009, 42.2369],
            [-6.7652, 42.2578],
            [-6.7386, 42.2968],
            [-6.7408, 42.3334],
            [-6.733, 42.3589],
            [-6.7662, 42.3666],
            [-6.7807, 42.3816],
            [-6.8199, 42.3863],
            [-6.8411, 42.4041],
            [-6.8203, 42.4214],
            [-6.8215, 42.4541],
            [-6.8083, 42.4661],
            [-6.8281, 42.4929],
            [-6.8582, 42.4868],
            [-6.9137, 42.5067],
            [-6.9297, 42.5194],
            [-6.9636, 42.513],
            [-6.9748, 42.4921],
            [-7.0015, 42.5035],
            [-7.0286, 42.4972],
            [-7.0479, 42.5075],
            [-7.0707, 42.5368],
            [-7.0546, 42.57],
            [-7.0411, 42.6355],
            [-7.0095, 42.6497],
            [-7.0441, 42.6952],
            [-7.0067, 42.7246],
            [-6.9348, 42.7413],
            [-6.8664, 42.788],
            [-6.8684, 42.8024],
            [-6.8411, 42.8239],
            [-6.8703, 42.8743],
            [-6.8337, 42.8821],
            [-6.8234, 42.9152],
            [-6.8406, 42.9302],
            [-6.8353, 42.9589],
            [-6.8795, 42.9919],
            [-6.9072, 42.9904],
            [-6.9616, 43.027],
            [-6.9663, 42.9936],
            [-6.9957, 43.0212],
            [-6.9655, 43.0357],
            [-6.9668, 43.0566],
            [-6.9391, 43.0819],
            [-6.9129, 43.0751],
            [-6.8291, 43.1234],
            [-6.826, 43.1467],
            [-6.8396, 43.1674],
            [-6.8848, 43.1676],
            [-6.937, 43.1333],
            [-6.9551, 43.136],
            [-6.9791, 43.166],
            [-6.9647, 43.1864],
            [-6.9863, 43.2033],
            [-7.021, 43.2153],
            [-7.0626, 43.2475],
            [-7.07, 43.2688],
            [-7.0642, 43.2998],
            [-7.0912, 43.2999],
            [-7.1298, 43.3306],
            [-7.1367, 43.3837],
            [-7.175, 43.3867],
            [-7.1765, 43.4141],
            [-7.1624, 43.4316],
            [-7.1258, 43.4235],
            [-7.1019, 43.4276],
            [-7.0914, 43.4526],
            [-7.0555, 43.4724]
          ].reverse()
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "Extremadura", id: "ES-EX", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-EX",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.8656, 40.2673],
            [-6.9096, 40.2537],
            [-6.9519, 40.257],
            [-7.0103, 40.2261],
            [-7.029, 40.1847],
            [-7.0172, 40.1767],
            [-7.0137, 40.1365],
            [-6.9985, 40.1177],
            [-6.9749, 40.1195],
            [-6.9383, 40.1084],
            [-6.9156, 40.0694],
            [-6.8807, 40.0414],
            [-6.8629, 40.0098],
            [-6.883, 39.9798],
            [-6.8879, 39.9349],
            [-6.9076, 39.8635],
            [-6.929, 39.852],
            [-6.951, 39.8239],
            [-6.9835, 39.8125],
            [-6.9754, 39.7767],
            [-6.9855, 39.7633],
            [-6.9914, 39.7231],
            [-7.0144, 39.6711],
            [-7.0532, 39.6599],
            [-7.1244, 39.6635],
            [-7.147, 39.6532],
            [-7.2502, 39.6668],
            [-7.2853, 39.6613],
            [-7.3299, 39.6414],
            [-7.3752, 39.6507],
            [-7.4022, 39.6461],
            [-7.4554, 39.6621],
            [-7.5313, 39.6674],
            [-7.5242, 39.6344],
            [-7.4922, 39.5869],
            [-7.4656, 39.5778],
            [-7.4285, 39.5351],
            [-7.3978, 39.5342],
            [-7.3882, 39.5065],
            [-7.3639, 39.4819],
            [-7.3215, 39.4754],
            [-7.2999, 39.4645],
            [-7.2995, 39.4145],
            [-7.323, 39.38],
            [-7.3112, 39.3695],
            [-7.3093, 39.3389],
            [-7.2323, 39.2781],
            [-7.2484, 39.2509],
            [-7.2405, 39.2121],
            [-7.2145, 39.1923],
            [-7.1369, 39.1718],
            [-7.1424, 39.1108],
            [-7.1063, 39.0985],
            [-7.0511, 39.1175],
            [-7.0315, 39.1161],
            [-6.9872, 39.093],
            [-6.9604, 39.0536],
            [-6.9513, 39.0242],
            [-6.9839, 38.9792],
            [-7.0471, 38.9071],
            [-7.029, 38.8752],
            [-7.0593, 38.8556],
            [-7.0901, 38.82],
            [-7.1244, 38.8153],
            [-7.2033, 38.7501],
            [-7.259, 38.7233],
            [-7.2661, 38.6992],
            [-7.2714, 38.6379],
            [-7.2433, 38.6233],
            [-7.2629, 38.5909],
            [-7.2825, 38.5757],
            [-7.3154, 38.5125],
            [-7.3219, 38.4786],
            [-7.3151, 38.4414],
            [-7.2786, 38.4218],
            [-7.2747, 38.3998],
            [-7.2445, 38.3657],
            [-7.2019, 38.3287],
            [-7.1703, 38.282],
            [-7.1467, 38.2688],
            [-7.1373, 38.2306],
            [-7.1124, 38.1942],
            [-7.084, 38.1727],
            [-7.0672, 38.1875],
            [-7.0295, 38.1847],
            [-7.0096, 38.2057],
            [-6.9354, 38.22],
            [-6.9299, 38.206],
            [-6.9023, 38.201],
            [-6.8553, 38.1784],
            [-6.7941, 38.177],
            [-6.8072, 38.11],
            [-6.7703, 38.1099],
            [-6.7589, 38.0923],
            [-6.6902, 38.0993],
            [-6.6612, 38.0919],
            [-6.6228, 38.0961],
            [-6.5821, 38.0525],
            [-6.5834, 38.0224],
            [-6.5592, 38.0281],
            [-6.4703, 38.0117],
            [-6.465, 38.0356],
            [-6.4146, 38.0589],
            [-6.37, 38.0499],
            [-6.3495, 38.0045],
            [-6.3018, 37.9775],
            [-6.2527, 37.9709],
            [-6.2392, 37.959],
            [-6.2035, 37.9619],
            [-6.1813, 37.9416],
            [-6.1156, 37.9842],
            [-6.0956, 37.9831],
            [-6.0336, 37.9972],
            [-6.0185, 37.9908],
            [-5.9466, 38.0025],
            [-5.9375, 38.0401],
            [-5.9082, 38.0678],
            [-5.9297, 38.0957],
            [-5.914, 38.1211],
            [-5.8808, 38.1327],
            [-5.8747, 38.1583],
            [-5.8383, 38.1753],
            [-5.7998, 38.1747],
            [-5.7817, 38.1858],
            [-5.7583, 38.181],
            [-5.7387, 38.1949],
            [-5.6881, 38.1755],
            [-5.6956, 38.1506],
            [-5.7411, 38.1261],
            [-5.7341, 38.0875],
            [-5.7084, 38.0806],
            [-5.6714, 38.0985],
            [-5.6395, 38.1347],
            [-5.5877, 38.1322],
            [-5.5595, 38.1495],
            [-5.5412, 38.1759],
            [-5.5382, 38.1994],
            [-5.5203, 38.2077],
            [-5.5254, 38.2625],
            [-5.5434, 38.2819],
            [-5.5552, 38.3178],
            [-5.5731, 38.3284],
            [-5.5612, 38.3715],
            [-5.5842, 38.386],
            [-5.5722, 38.4275],
            [-5.5196, 38.4632],
            [-5.4955, 38.4589],
            [-5.4453, 38.503],
            [-5.4114, 38.5201],
            [-5.3694, 38.5834],
            [-5.3134, 38.5774],
            [-5.2914, 38.6114],
            [-5.2483, 38.638],
            [-5.1679, 38.6766],
            [-5.1796, 38.7114],
            [-5.1195, 38.7157],
            [-5.1006, 38.7071],
            [-5.0458, 38.7294],
            [-4.9882, 38.7417],
            [-4.9726, 38.76],
            [-4.9419, 38.8535],
            [-4.9141, 38.8867],
            [-4.848, 38.8828],
            [-4.8342, 38.9403],
            [-4.9185, 38.9563],
            [-4.9621, 39.057],
            [-4.9357, 39.0554],
            [-4.8778, 39.0315],
            [-4.8331, 39.0452],
            [-4.8268, 39.0611],
            [-4.8574, 39.0751],
            [-4.8739, 39.0956],
            [-4.8589, 39.12],
            [-4.8546, 39.1492],
            [-4.8077, 39.197],
            [-4.7644, 39.1871],
            [-4.7382, 39.1913],
            [-4.6783, 39.1679],
            [-4.6824, 39.1918],
            [-4.7253, 39.2456],
            [-4.7518, 39.3087],
            [-4.7508, 39.3228],
            [-4.706, 39.3277],
            [-4.702, 39.3662],
            [-4.6685, 39.4233],
            [-4.6913, 39.4491],
            [-4.7567, 39.4144],
            [-4.7751, 39.3966],
            [-4.8108, 39.3956],
            [-4.8702, 39.3673],
            [-4.9508, 39.3913],
            [-5.029, 39.4505],
            [-5.0597, 39.4858],
            [-5.0822, 39.4863],
            [-5.1232, 39.5188],
            [-5.1595, 39.5586],
            [-5.205, 39.5995],
            [-5.1601, 39.6419],
            [-5.1491, 39.6664],
            [-5.1527, 39.6951],
            [-5.1394, 39.7183],
            [-5.1729, 39.7555],
            [-5.1594, 39.7601],
            [-5.1736, 39.7983],
            [-5.2021, 39.7985],
            [-5.253, 39.7524],
            [-5.3162, 39.7704],
            [-5.3112, 39.8277],
            [-5.2836, 39.8566],
            [-5.2947, 39.8736],
            [-5.3257, 39.892],
            [-5.3949, 39.8789],
            [-5.3964, 39.9145],
            [-5.3656, 39.9831],
            [-5.3647, 40.0307],
            [-5.3744, 40.0612],
            [-5.3694, 40.0987],
            [-5.3361, 40.1144],
            [-5.3614, 40.1573],
            [-5.3696, 40.2176],
            [-5.3435, 40.2623],
            [-5.3908, 40.2509],
            [-5.4285, 40.2521],
            [-5.4372, 40.2358],
            [-5.4972, 40.1995],
            [-5.5472, 40.197],
            [-5.6184, 40.2245],
            [-5.6261, 40.2475],
            [-5.6599, 40.2575],
            [-5.6579, 40.2701],
            [-5.6911, 40.2919],
            [-5.7377, 40.2938],
            [-5.7644, 40.2804],
            [-5.7915, 40.2863],
            [-5.7821, 40.3132],
            [-5.7956, 40.3509],
            [-5.8189, 40.3601],
            [-5.8408, 40.3426],
            [-5.8938, 40.3201],
            [-5.904, 40.2951],
            [-5.9239, 40.2813],
            [-6.0067, 40.3053],
            [-6.0268, 40.3384],
            [-6.0968, 40.3534],
            [-6.0818, 40.3643],
            [-6.076, 40.4024],
            [-6.1277, 40.4203],
            [-6.1573, 40.4568],
            [-6.1988, 40.4812],
            [-6.2381, 40.4852],
            [-6.2922, 40.4523],
            [-6.3427, 40.4423],
            [-6.3623, 40.4065],
            [-6.4169, 40.3988],
            [-6.4381, 40.3722],
            [-6.4615, 40.3725],
            [-6.5251, 40.3518],
            [-6.5582, 40.3306],
            [-6.5569, 40.2898],
            [-6.5871, 40.2694],
            [-6.6679, 40.2622],
            [-6.6936, 40.2429],
            [-6.717, 40.2688],
            [-6.7604, 40.2395],
            [-6.8337, 40.2464],
            [-6.8656, 40.2673]
          ].reverse()
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "Cataluña", id: "ES-CT", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-CT",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.5141, 40.5227],
              [0.5388, 40.5676],
              [0.6068, 40.623],
              [0.6632, 40.6264],
              [0.6915, 40.6371],
              [0.7457, 40.6359],
              [0.714, 40.5926],
              [0.6604, 40.592],
              [0.6493, 40.5695],
              [0.6154, 40.5883],
              [0.6044, 40.5689],
              [0.643, 40.555],
              [0.6878, 40.5669],
              [0.7132, 40.5879],
              [0.7591, 40.6423],
              [0.8318, 40.6703],
              [0.8698, 40.6938],
              [0.863, 40.7207],
              [0.8326, 40.7315],
              [0.7822, 40.7746],
              [0.7688, 40.7625],
              [0.7305, 40.768],
              [0.6999, 40.8035],
              [0.7406, 40.8225],
              [0.7439, 40.8374],
              [0.7954, 40.87],
              [0.8009, 40.8818],
              [0.8957, 40.974],
              [0.9314, 40.9927],
              [0.9592, 41.0232],
              [1.0504, 41.0641],
              [1.0831, 41.0633],
              [1.1312, 41.0744],
              [1.1715, 41.0546],
              [1.2012, 41.0947],
              [1.2237, 41.1064],
              [1.2666, 41.1117],
              [1.2979, 41.1267],
              [1.3481, 41.1274],
              [1.4067, 41.1401],
              [1.463, 41.1649],
              [1.54, 41.1839],
              [1.6747, 41.1989],
              [1.7274, 41.2154],
              [1.788, 41.2248],
              [1.814, 41.2361],
              [1.853, 41.2345],
              [1.9286, 41.2616],
              [2.016, 41.265],
              [2.109, 41.2871],
              [2.151, 41.3192],
              [2.1676, 41.3537],
              [2.2313, 41.4157],
              [2.251, 41.4428],
              [2.2946, 41.4686],
              [2.3523, 41.4836],
              [2.4112, 41.5084],
              [2.4605, 41.5394],
              [2.5211, 41.5634],
              [2.7801, 41.6493],
              [2.7876, 41.6687],
              [2.8175, 41.6877],
              [2.8534, 41.6995],
              [2.8887, 41.7014],
              [2.9358, 41.7153],
              [2.9723, 41.753],
              [3.0083, 41.7705],
              [3.0456, 41.7789],
              [3.0706, 41.8159],
              [3.1038, 41.8455],
              [3.1343, 41.847],
              [3.1762, 41.8663],
              [3.2039, 41.8919],
              [3.234, 41.9505],
              [3.2002, 41.9949],
              [3.1941, 42.0261],
              [3.208, 42.0702],
              [3.1759, 42.1118],
              [3.1249, 42.1277],
              [3.114, 42.1605],
              [3.1129, 42.2066],
              [3.127, 42.2398],
              [3.149, 42.2606],
              [3.207, 42.2407],
              [3.2312, 42.2505],
              [3.2782, 42.2542],
              [3.287, 42.3029],
              [3.3214, 42.3202],
              [3.2598, 42.3408],
              [3.1861, 42.35],
              [3.1614, 42.3606],
              [3.1536, 42.3804],
              [3.1671, 42.4152],
              [3.1488, 42.4337],
              [3.1095, 42.4348],
              [3.0863, 42.4253],
              [3.0329, 42.4747],
              [2.9884, 42.4739],
              [2.9672, 42.4652],
              [2.9461, 42.4806],
              [2.9245, 42.4588],
              [2.8397, 42.4586],
              [2.7988, 42.418],
              [2.7708, 42.4111],
              [2.7555, 42.4253],
              [2.7166, 42.4201],
              [2.6747, 42.4045],
              [2.6553, 42.3408],
              [2.5624, 42.3567],
              [2.5414, 42.3331],
              [2.4822, 42.3398],
              [2.4656, 42.3606],
              [2.4143, 42.3909],
              [2.3528, 42.4046],
              [2.3171, 42.4256],
              [2.2902, 42.4229],
              [2.2569, 42.4376],
              [2.2015, 42.4153],
              [2.1556, 42.4232],
              [2.1252, 42.4097],
              [2.114, 42.3908],
              [2.06, 42.3586],
              [1.9984, 42.3534],
              [1.9588, 42.3925],
              [1.9542, 42.4261],
              [1.9315, 42.4534],
              [1.8808, 42.4489],
              [1.839, 42.4819],
              [1.8035, 42.4908],
              [1.7644, 42.4867],
              [1.7256, 42.5014],
              [1.7065, 42.4901],
              [1.664, 42.4915],
              [1.6579, 42.4667],
              [1.5686, 42.4569],
              [1.5508, 42.4324],
              [1.5155, 42.4279],
              [1.4486, 42.4376],
              [1.4438, 42.4952],
              [1.4682, 42.511],
              [1.4434, 42.543],
              [1.4404, 42.5937],
              [1.4115, 42.656],
              [1.3897, 42.668],
              [1.3897, 42.6865],
              [1.3509, 42.7032],
              [1.351, 42.7201],
              [1.2534, 42.7149],
              [1.228, 42.7273],
              [1.1653, 42.7097],
              [1.1338, 42.7282],
              [1.1325, 42.7497],
              [1.1076, 42.7721],
              [1.0733, 42.7825],
              [1.0054, 42.7907],
              [0.9839, 42.7864],
              [0.9645, 42.8045],
              [0.9327, 42.7889],
              [0.9075, 42.7968],
              [0.856, 42.8263],
              [0.8115, 42.8383],
              [0.7785, 42.836],
              [0.708, 42.8616],
              [0.6581, 42.8383],
              [0.6688, 42.8026],
              [0.6493, 42.7788],
              [0.6805, 42.7062],
              [0.6605, 42.6913],
              [0.6951, 42.6582],
              [0.7099, 42.6192],
              [0.7668, 42.6105],
              [0.7489, 42.5523],
              [0.7213, 42.5267],
              [0.7324, 42.5028],
              [0.6972, 42.4719],
              [0.7109, 42.4355],
              [0.7354, 42.4147],
              [0.7454, 42.3847],
              [0.7311, 42.3619],
              [0.7646, 42.3564],
              [0.7418, 42.3334],
              [0.7636, 42.3217],
              [0.7449, 42.2996],
              [0.7358, 42.2527],
              [0.7139, 42.2218],
              [0.6934, 42.15],
              [0.702, 42.1205],
              [0.6662, 42.0409],
              [0.6511, 42.0232],
              [0.6551, 41.9964],
              [0.6261, 41.9897],
              [0.6051, 41.9595],
              [0.5793, 41.9551],
              [0.5649, 41.9314],
              [0.6005, 41.922],
              [0.595, 41.8837],
              [0.6085, 41.8708],
              [0.5603, 41.8532],
              [0.5451, 41.82],
              [0.5151, 41.8207],
              [0.4846, 41.7996],
              [0.4718, 41.7666],
              [0.4006, 41.7573],
              [0.3692, 41.7137],
              [0.328, 41.6789],
              [0.3264, 41.6601],
              [0.3585, 41.6303],
              [0.3508, 41.5992],
              [0.4028, 41.5927],
              [0.4291, 41.6018],
              [0.4325, 41.5654],
              [0.4465, 41.549],
              [0.3983, 41.4905],
              [0.3475, 41.4878],
              [0.3553, 41.4545],
              [0.3492, 41.4257],
              [0.3208, 41.3955],
              [0.3608, 41.3712],
              [0.3739, 41.3539],
              [0.3481, 41.329],
              [0.3868, 41.2768],
              [0.3627, 41.2232],
              [0.3208, 41.2216],
              [0.3028, 41.1623],
              [0.2469, 41.1287],
              [0.2197, 41.1309],
              [0.2016, 41.1036],
              [0.2018, 41.0853],
              [0.2353, 41.0445],
              [0.2638, 41.0263],
              [0.2693, 40.9966],
              [0.2864, 40.9639],
              [0.2491, 40.9073],
              [0.2419, 40.8808],
              [0.2517, 40.8549],
              [0.2776, 40.8212],
              [0.2349, 40.7757],
              [0.189, 40.7507],
              [0.1699, 40.7336],
              [0.2239, 40.7255],
              [0.2373, 40.7003],
              [0.2624, 40.7055],
              [0.2925, 40.685],
              [0.2682, 40.6583],
              [0.2786, 40.6308],
              [0.3396, 40.6081],
              [0.387, 40.6068],
              [0.4319, 40.5788],
              [0.4383, 40.5474],
              [0.5141, 40.5227]
            ].reverse()
          ],
          [[[2.0036, 42.4522], [1.969, 42.479], [1.9476, 42.4587], [2.0036, 42.4522]].reverse()]
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "Islas Canarias", id: "ES-CN", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-CN",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-10.6904268, 33.469416],
              [-10.7366268, 33.470216],
              [-10.7610268, 33.486616],
              [-10.7899268, 33.469216],
              [-10.8510268, 33.449016],
              [-10.8092268, 33.406816],
              [-10.7870268, 33.367916],
              [-10.7692268, 33.357816],
              [-10.7731268, 33.331116],
              [-10.7644268, 33.303916],
              [-10.7208268, 33.232916],
              [-10.6968268, 33.203616],
              [-10.6687268, 33.183516],
              [-10.6639268, 33.147816],
              [-10.6394268, 33.140216],
              [-10.6355268, 33.104616],
              [-10.6135268, 33.092016],
              [-10.5888268, 33.102616],
              [-10.5720268, 33.097416],
              [-10.5238268, 33.122816],
              [-10.4696268, 33.122816],
              [-10.4694268, 33.135616],
              [-10.4206268, 33.172616],
              [-10.3677268, 33.236216],
              [-10.3524268, 33.294116],
              [-10.3154268, 33.356916],
              [-10.3115268, 33.377516],
              [-10.2886268, 33.400016],
              [-10.2972268, 33.448916],
              [-10.2886268, 33.472716],
              [-10.2476268, 33.494916],
              [-10.1970268, 33.541516],
              [-10.1801268, 33.548316],
              [-10.1651268, 33.578416],
              [-10.1298268, 33.592116],
              [-10.0570268, 33.629616],
              [-10.0474268, 33.645816],
              [-10.0661268, 33.676916],
              [-10.0859268, 33.681716],
              [-10.1510268, 33.659116],
              [-10.2001268, 33.671416],
              [-10.2245268, 33.662716],
              [-10.2571268, 33.670116],
              [-10.2644268, 33.649716],
              [-10.3119268, 33.638916],
              [-10.3488268, 33.611316],
              [-10.3579268, 33.581416],
              [-10.3938268, 33.553516],
              [-10.4044268, 33.535116],
              [-10.4249268, 33.532316],
              [-10.4388268, 33.514116],
              [-10.4828268, 33.511816],
              [-10.5314268, 33.488016],
              [-10.6021268, 33.495816],
              [-10.6089268, 33.485116],
              [-10.6904268, 33.469416]
            ].reverse()
          ],
          [
            [
              [-11.8530268, 32.943016],
              [-11.8836268, 32.935416],
              [-11.9178268, 32.911116],
              [-11.9165268, 32.893716],
              [-11.9675268, 32.855516],
              [-11.9998268, 32.845916],
              [-12.0329268, 32.849616],
              [-12.0597268, 32.864516],
              [-12.0814268, 32.850116],
              [-12.0890268, 32.809116],
              [-12.0731268, 32.796216],
              [-12.0516268, 32.797716],
              [-11.9782268, 32.785416],
              [-11.9568268, 32.771716],
              [-11.9374268, 32.742416],
              [-11.9140268, 32.731016],
              [-11.8985268, 32.741016],
              [-11.8998268, 32.765316],
              [-11.8860268, 32.788716],
              [-11.8848268, 32.812316],
              [-11.8549268, 32.824916],
              [-11.8298268, 32.879616],
              [-11.8109268, 32.898216],
              [-11.8217268, 32.922916],
              [-11.8530268, 32.943016]
            ].reverse()
          ],
          [
            [
              [-7.4329268, 34.509116],
              [-7.4509268, 34.504816],
              [-7.4585268, 34.479116],
              [-7.4348268, 34.478016],
              [-7.4185268, 34.494716],
              [-7.4329268, 34.509116]
            ].reverse()
          ],
          [
            [
              [-11.2014268, 33.308716],
              [-11.2445268, 33.298416],
              [-11.2638268, 33.269316],
              [-11.2740268, 33.237416],
              [-11.2751268, 33.195616],
              [-11.2527268, 33.174016],
              [-11.2477268, 33.152616],
              [-11.1985268, 33.119016],
              [-11.1730268, 33.113216],
              [-11.1273268, 33.117116],
              [-11.0890268, 33.140816],
              [-11.0287268, 33.185716],
              [-11.0382268, 33.233916],
              [-11.0663268, 33.253616],
              [-11.1107268, 33.273416],
              [-11.1215268, 33.291316],
              [-11.2014268, 33.308716]
            ].reverse()
          ],
          [
            [
              [-11.8385268, 33.950316],
              [-11.8919268, 33.920016],
              [-11.9078268, 33.896316],
              [-11.9336268, 33.877716],
              [-11.9355268, 33.850916],
              [-11.9090268, 33.819416],
              [-11.8924268, 33.760616],
              [-11.8744268, 33.743716],
              [-11.8387268, 33.679016],
              [-11.8085268, 33.633416],
              [-11.7963268, 33.566316],
              [-11.7614268, 33.550116],
              [-11.7449268, 33.588916],
              [-11.7240268, 33.611816],
              [-11.7138268, 33.637616],
              [-11.6874268, 33.663816],
              [-11.6780268, 33.713616],
              [-11.6983268, 33.769516],
              [-11.6516268, 33.831616],
              [-11.6854268, 33.879416],
              [-11.6871268, 33.896816],
              [-11.7063268, 33.932816],
              [-11.7350268, 33.936516],
              [-11.7859268, 33.925916],
              [-11.8160268, 33.933316],
              [-11.8385268, 33.950316]
            ].reverse()
          ],
          [
            [
              [-9.3472268, 33.274016],
              [-9.3674268, 33.262116],
              [-9.3591268, 33.238816],
              [-9.3968268, 33.219816],
              [-9.4236268, 33.237916],
              [-9.4575268, 33.248016],
              [-9.4738268, 33.239616],
              [-9.5257268, 33.237516],
              [-9.5658268, 33.265116],
              [-9.5984268, 33.251616],
              [-9.6110268, 33.263316],
              [-9.6346268, 33.250316],
              [-9.6359268, 33.188216],
              [-9.6485268, 33.162916],
              [-9.6893268, 33.137816],
              [-9.7186268, 33.112016],
              [-9.7456268, 33.097216],
              [-9.7623268, 33.063416],
              [-9.7558268, 33.042416],
              [-9.7623268, 33.008216],
              [-9.7456268, 32.985816],
              [-9.7146268, 32.927016],
              [-9.6086268, 32.849316],
              [-9.5874268, 32.849716],
              [-9.5278268, 32.827216],
              [-9.4968268, 32.832416],
              [-9.4930268, 32.850616],
              [-9.4175268, 32.885916],
              [-9.3708268, 32.892616],
              [-9.3504268, 32.904316],
              [-9.3395268, 32.940916],
              [-9.3131268, 32.951216],
              [-9.3214268, 32.984716],
              [-9.3028268, 33.028516],
              [-9.3111268, 33.065516],
              [-9.2982268, 33.086816],
              [-9.3159268, 33.122716],
              [-9.3436268, 33.141016],
              [-9.3384268, 33.192416],
              [-9.3567268, 33.221916],
              [-9.3313268, 33.265916],
              [-9.3472268, 33.274016]
            ].reverse()
          ],
          [
            [
              [-7.4361268, 34.383816],
              [-7.4503268, 34.362816],
              [-7.4513268, 34.311816],
              [-7.4331268, 34.318016],
              [-7.4024268, 34.364516],
              [-7.4361268, 34.383816]
            ].reverse()
          ],
          [
            [
              [-7.8216268, 33.850616],
              [-7.8523268, 33.846616],
              [-7.8839268, 33.829516],
              [-7.9058268, 33.827416],
              [-7.9471268, 33.801016],
              [-7.9370268, 33.769116],
              [-7.9672268, 33.709116],
              [-7.9617268, 33.688316],
              [-7.9872268, 33.649616],
              [-8.0265268, 33.573716],
              [-8.0746268, 33.532616],
              [-8.0862268, 33.500616],
              [-8.0900268, 33.462116],
              [-8.1046268, 33.439216],
              [-8.1268268, 33.426416],
              [-8.1404268, 33.381416],
              [-8.1357268, 33.352816],
              [-8.1523268, 33.305716],
              [-8.2009268, 33.267216],
              [-8.2547268, 33.230516],
              [-8.3118268, 33.206816],
              [-8.3543268, 33.196216],
              [-8.4230268, 33.197216],
              [-8.4216268, 33.177016],
              [-8.3355268, 33.151716],
              [-8.2966268, 33.147716],
              [-8.2640268, 33.137516],
              [-8.2489268, 33.143816],
              [-8.2006268, 33.192216],
              [-8.1516268, 33.255316],
              [-8.1286268, 33.266016],
              [-8.0864268, 33.269416],
              [-8.0642268, 33.280616],
              [-8.0033268, 33.294616],
              [-7.9400268, 33.301216],
              [-7.9076268, 33.322716],
              [-7.8764268, 33.321316],
              [-7.8453268, 33.345616],
              [-7.8219268, 33.418416],
              [-7.7768268, 33.503616],
              [-7.7906268, 33.532916],
              [-7.7854268, 33.554616],
              [-7.7958268, 33.574316],
              [-7.7714268, 33.597816],
              [-7.7666268, 33.631116],
              [-7.7545268, 33.647516],
              [-7.7505268, 33.686516],
              [-7.7616268, 33.768716],
              [-7.7588268, 33.787716],
              [-7.7710268, 33.820316],
              [-7.7947268, 33.828016],
              [-7.8216268, 33.850616]
            ].reverse()
          ],
          [
            [
              [-7.3941268, 34.320116],
              [-7.4245268, 34.312416],
              [-7.4499268, 34.262516],
              [-7.4529268, 34.235116],
              [-7.4894268, 34.209616],
              [-7.5317268, 34.228616],
              [-7.5583268, 34.210116],
              [-7.5820268, 34.207316],
              [-7.6376268, 34.172916],
              [-7.6854268, 34.169516],
              [-7.7368268, 34.128316],
              [-7.7572268, 34.102016],
              [-7.7615268, 34.032616],
              [-7.7574268, 34.022016],
              [-7.7883268, 33.989216],
              [-7.8094268, 33.979816],
              [-7.8048268, 33.952816],
              [-7.7513268, 33.955916],
              [-7.7065268, 33.936716],
              [-7.6803268, 33.967616],
              [-7.6633268, 34.000216],
              [-7.6035268, 34.015016],
              [-7.5708268, 34.012416],
              [-7.5248268, 34.042016],
              [-7.4715268, 34.054116],
              [-7.3956268, 34.108916],
              [-7.3972268, 34.126616],
              [-7.3760268, 34.181116],
              [-7.3927268, 34.221416],
              [-7.3555268, 34.251416],
              [-7.3521268, 34.301816],
              [-7.3941268, 34.320116]
            ].reverse()
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "Castilla-La Mancha", id: "ES-CM", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-CM",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.2741, 40.6753],
              [-4.2567, 40.6518],
              [-4.3198, 40.6482],
              [-4.2905, 40.6286],
              [-4.2466, 40.6033],
              [-4.2135, 40.6065],
              [-4.1617, 40.6238],
              [-4.173, 40.6777],
              [-4.1609, 40.689],
              [-4.1842, 40.6978],
              [-4.2519, 40.6883],
              [-4.2741, 40.6753]
            ].reverse()
          ],
          [
            [
              [-3.5389, 41.1637],
              [-3.5405, 41.1475],
              [-3.5107, 41.1343],
              [-3.4905, 41.1033],
              [-3.4257, 41.0724],
              [-3.4386, 41.0411],
              [-3.396, 40.9994],
              [-3.434, 40.9458],
              [-3.4509, 40.906],
              [-3.4428, 40.8794],
              [-3.4656, 40.8567],
              [-3.4915, 40.8083],
              [-3.4863, 40.7851],
              [-3.4646, 40.7845],
              [-3.4419, 40.7599],
              [-3.4367, 40.7214],
              [-3.4606, 40.6986],
              [-3.4408, 40.6748],
              [-3.4049, 40.6696],
              [-3.3721, 40.6776],
              [-3.3673, 40.6478],
              [-3.3229, 40.6417],
              [-3.3307, 40.5957],
              [-3.2899, 40.5716],
              [-3.2717, 40.535],
              [-3.2485, 40.5421],
              [-3.2004, 40.5126],
              [-3.1986, 40.4423],
              [-3.1556, 40.4395],
              [-3.1308, 40.4051],
              [-3.1527, 40.3683],
              [-3.1521, 40.3343],
              [-3.1817, 40.3094],
              [-3.1857, 40.2689],
              [-3.1956, 40.2482],
              [-3.173, 40.2349],
              [-3.1602, 40.2534],
              [-3.1125, 40.2864],
              [-3.0809, 40.2295],
              [-3.0854, 40.1862],
              [-3.0765, 40.1654],
              [-3.0959, 40.1412],
              [-3.0732, 40.1309],
              [-3.0535, 40.1056],
              [-3.0926, 40.0682],
              [-3.1212, 40.0617],
              [-3.1607, 40.0647],
              [-3.1787, 40.0915],
              [-3.2061, 40.0636],
              [-3.274, 40.0475],
              [-3.3017, 40.0529],
              [-3.3297, 40.0806],
              [-3.3768, 40.0744],
              [-3.3772, 40.05],
              [-3.402, 40.0342],
              [-3.4174, 40.0445],
              [-3.5106, 40.0455],
              [-3.5267, 40.0187],
              [-3.5888, 40.0129],
              [-3.5941, 40.0015],
              [-3.6321, 39.9877],
              [-3.6208, 39.9667],
              [-3.6502, 39.9644],
              [-3.6915, 39.9473],
              [-3.7042, 39.9534],
              [-3.7391, 39.9391],
              [-3.7453, 39.9259],
              [-3.7996, 39.8876],
              [-3.8752, 39.9104],
              [-3.8557, 39.9392],
              [-3.7483, 39.9515],
              [-3.7358, 39.9806],
              [-3.712, 40.0099],
              [-3.6862, 40.0142],
              [-3.63, 40.054],
              [-3.6032, 40.0926],
              [-3.6074, 40.1084],
              [-3.6393, 40.1188],
              [-3.6738, 40.141],
              [-3.6921, 40.133],
              [-3.7206, 40.1476],
              [-3.7405, 40.1341],
              [-3.771, 40.1393],
              [-3.7818, 40.1603],
              [-3.8069, 40.1776],
              [-3.8335, 40.1617],
              [-3.8658, 40.1877],
              [-3.9143, 40.1913],
              [-3.9535, 40.2131],
              [-3.9863, 40.2088],
              [-4.0725, 40.2657],
              [-4.1025, 40.2424],
              [-4.1487, 40.25],
              [-4.186, 40.2956],
              [-4.2281, 40.285],
              [-4.259, 40.2579],
              [-4.2694, 40.2364],
              [-4.2955, 40.2188],
              [-4.3409, 40.2398],
              [-4.3574, 40.2716],
              [-4.3554, 40.3072],
              [-4.4341, 40.2586],
              [-4.4367, 40.2371],
              [-4.4738, 40.2306],
              [-4.5448, 40.1995],
              [-4.5791, 40.2164],
              [-4.6166, 40.2001],
              [-4.6553, 40.199],
              [-4.6866, 40.2112],
              [-4.7019, 40.257],
              [-4.698, 40.282],
              [-4.7425, 40.2738],
              [-4.7631, 40.2602],
              [-4.804, 40.2764],
              [-4.8175, 40.2546],
              [-4.8075, 40.2345],
              [-4.9233, 40.1694],
              [-4.9288, 40.1347],
              [-5.0075, 40.109],
              [-5.0188, 40.158],
              [-5.0703, 40.1484],
              [-5.1272, 40.1019],
              [-5.1704, 40.0853],
              [-5.2063, 40.0841],
              [-5.2116, 40.1055],
              [-5.3199, 40.1073],
              [-5.3361, 40.1144],
              [-5.3694, 40.0987],
              [-5.3744, 40.0612],
              [-5.3647, 40.0307],
              [-5.3656, 39.9831],
              [-5.3964, 39.9145],
              [-5.3949, 39.8789],
              [-5.3257, 39.892],
              [-5.2947, 39.8736],
              [-5.2836, 39.8566],
              [-5.3112, 39.8277],
              [-5.3162, 39.7704],
              [-5.253, 39.7524],
              [-5.2021, 39.7985],
              [-5.1736, 39.7983],
              [-5.1594, 39.7601],
              [-5.1729, 39.7555],
              [-5.1394, 39.7183],
              [-5.1527, 39.6951],
              [-5.1491, 39.6664],
              [-5.1601, 39.6419],
              [-5.205, 39.5995],
              [-5.1595, 39.5586],
              [-5.1232, 39.5188],
              [-5.0822, 39.4863],
              [-5.0597, 39.4858],
              [-5.029, 39.4505],
              [-4.9508, 39.3913],
              [-4.8702, 39.3673],
              [-4.8108, 39.3956],
              [-4.7751, 39.3966],
              [-4.7567, 39.4144],
              [-4.6913, 39.4491],
              [-4.6685, 39.4233],
              [-4.702, 39.3662],
              [-4.706, 39.3277],
              [-4.7508, 39.3228],
              [-4.7518, 39.3087],
              [-4.7253, 39.2456],
              [-4.6824, 39.1918],
              [-4.6783, 39.1679],
              [-4.7382, 39.1913],
              [-4.7644, 39.1871],
              [-4.8077, 39.197],
              [-4.8546, 39.1492],
              [-4.8589, 39.12],
              [-4.8739, 39.0956],
              [-4.8574, 39.0751],
              [-4.8268, 39.0611],
              [-4.8331, 39.0452],
              [-4.8778, 39.0315],
              [-4.9357, 39.0554],
              [-4.9621, 39.057],
              [-4.9185, 38.9563],
              [-4.8342, 38.9403],
              [-4.848, 38.8828],
              [-4.9141, 38.8867],
              [-4.9419, 38.8535],
              [-4.9726, 38.76],
              [-4.9882, 38.7417],
              [-5.0458, 38.7294],
              [-5.0237, 38.7261],
              [-5.0016, 38.6943],
              [-4.9296, 38.6812],
              [-4.8833, 38.6865],
              [-4.8688, 38.6808],
              [-4.862, 38.6173],
              [-4.8115, 38.5951],
              [-4.7859, 38.5965],
              [-4.7282, 38.5747],
              [-4.7054, 38.5729],
              [-4.6969, 38.5517],
              [-4.6635, 38.5486],
              [-4.6225, 38.5239],
              [-4.6004, 38.4925],
              [-4.5663, 38.4916],
              [-4.5571, 38.4768],
              [-4.5122, 38.4632],
              [-4.4515, 38.4009],
              [-4.4347, 38.4016],
              [-4.3208, 38.3622],
              [-4.2685, 38.3469],
              [-4.2778, 38.3797],
              [-4.2642, 38.3997],
              [-4.226, 38.3975],
              [-4.1687, 38.3826],
              [-4.0434, 38.3779],
              [-4.0041, 38.3679],
              [-3.915, 38.3678],
              [-3.8577, 38.3731],
              [-3.8312, 38.3917],
              [-3.8242, 38.4146],
              [-3.7811, 38.425],
              [-3.7393, 38.4138],
              [-3.7294, 38.424],
              [-3.6974, 38.4108],
              [-3.6199, 38.3934],
              [-3.5868, 38.4069],
              [-3.5819, 38.4478],
              [-3.5346, 38.4381],
              [-3.5291, 38.4084],
              [-3.4515, 38.4003],
              [-3.3768, 38.4402],
              [-3.3748, 38.474],
              [-3.3074, 38.4782],
              [-3.2764, 38.4577],
              [-3.1798, 38.4506],
              [-3.1282, 38.4396],
              [-3.0658, 38.4774],
              [-3.0047, 38.414],
              [-2.9933, 38.4512],
              [-2.9624, 38.4698],
              [-2.9047, 38.469],
              [-2.8904, 38.4566],
              [-2.8605, 38.4758],
              [-2.8255, 38.4868],
              [-2.7792, 38.512],
              [-2.7647, 38.5317],
              [-2.7327, 38.5135],
              [-2.6748, 38.4957],
              [-2.6406, 38.509],
              [-2.5996, 38.5134],
              [-2.5704, 38.4942],
              [-2.5678, 38.4603],
              [-2.5773, 38.4263],
              [-2.5573, 38.4056],
              [-2.4841, 38.3974],
              [-2.4919, 38.3706],
              [-2.48, 38.3487],
              [-2.4849, 38.3018],
              [-2.441, 38.286],
              [-2.4353, 38.2652],
              [-2.4412, 38.2318],
              [-2.4535, 38.2165],
              [-2.4462, 38.1853],
              [-2.5092, 38.1285],
              [-2.5225, 38.1071],
              [-2.553, 38.0828],
              [-2.5039, 38.068],
              [-2.4794, 38.0534],
              [-2.443, 38.0461],
              [-2.426, 38.0337],
              [-2.3719, 38.0235],
              [-2.3395, 38.0258],
              [-2.3297, 38.0668],
              [-2.2668, 38.1081],
              [-2.2448, 38.1494],
              [-2.23, 38.163],
              [-2.2124, 38.2035],
              [-2.1712, 38.2245],
              [-2.1139, 38.2373],
              [-2.084, 38.2637],
              [-2.0657, 38.2951],
              [-2.0475, 38.3057],
              [-1.9826, 38.2811],
              [-1.8933, 38.3023],
              [-1.853, 38.3307],
              [-1.8233, 38.3347],
              [-1.7955, 38.3521],
              [-1.7701, 38.3786],
              [-1.7446, 38.3804],
              [-1.7242, 38.368],
              [-1.6828, 38.3595],
              [-1.6922, 38.3443],
              [-1.6692, 38.3113],
              [-1.5897, 38.3107],
              [-1.5585, 38.3255],
              [-1.5416, 38.3433],
              [-1.4787, 38.3762],
              [-1.4738, 38.4069],
              [-1.4805, 38.4838],
              [-1.4977, 38.5361],
              [-1.4876, 38.5631],
              [-1.4526, 38.5823],
              [-1.4441, 38.6346],
              [-1.4487, 38.6495],
              [-1.4203, 38.6783],
              [-1.3668, 38.7034],
              [-1.3429, 38.6769],
              [-1.287, 38.7044],
              [-1.2643, 38.728],
              [-1.2375, 38.7416],
              [-1.1821, 38.7539],
              [-1.12, 38.7379],
              [-1.1164, 38.7106],
              [-1.0533, 38.6668],
              [-1.0267, 38.6556],
              [-0.9606, 38.6574],
              [-0.9152, 38.696],
              [-0.9416, 38.7305],
              [-0.9604, 38.7734],
              [-0.9282, 38.7845],
              [-0.9359, 38.8553],
              [-0.9239, 38.8914],
              [-0.9394, 38.8992],
              [-0.9756, 38.9416],
              [-1.1078, 38.9286],
              [-1.1436, 38.9285],
              [-1.1596, 38.9541],
              [-1.2256, 39.0243],
              [-1.2629, 39.0459],
              [-1.2641, 39.0763],
              [-1.2551, 39.1064],
              [-1.2396, 39.1144],
              [-1.2265, 39.1513],
              [-1.1975, 39.1756],
              [-1.1765, 39.2319],
              [-1.1749, 39.2781],
              [-1.1626, 39.3058],
              [-1.1772, 39.3146],
              [-1.2169, 39.3164],
              [-1.2955, 39.3308],
              [-1.3115, 39.3425],
              [-1.3351, 39.3329],
              [-1.3829, 39.3654],
              [-1.4199, 39.3816],
              [-1.4881, 39.4025],
              [-1.5107, 39.4341],
              [-1.5126, 39.4579],
              [-1.4997, 39.5647],
              [-1.4747, 39.5695],
              [-1.4554, 39.5873],
              [-1.4183, 39.6544],
              [-1.3698, 39.6882],
              [-1.3142, 39.6699],
              [-1.279, 39.6782],
              [-1.2629, 39.6975],
              [-1.2748, 39.7396],
              [-1.2169, 39.8058],
              [-1.2008, 39.8549],
              [-1.1965, 39.913],
              [-1.2109, 39.9328],
              [-1.2041, 39.9492],
              [-1.141, 39.9704],
              [-1.1642, 40.0114],
              [-1.1891, 40.0029],
              [-1.2582, 39.9964],
              [-1.2836, 40.0072],
              [-1.3762, 40.0187],
              [-1.3833, 40.0415],
              [-1.4173, 40.0925],
              [-1.4449, 40.118],
              [-1.4505, 40.1426],
              [-1.4396, 40.1961],
              [-1.4749, 40.1845],
              [-1.5098, 40.2026],
              [-1.5417, 40.19],
              [-1.5945, 40.2361],
              [-1.6116, 40.2436],
              [-1.6898, 40.3103],
              [-1.6981, 40.322],
              [-1.7528, 40.3616],
              [-1.8006, 40.413],
              [-1.7792, 40.4272],
              [-1.7354, 40.4728],
              [-1.6928, 40.4884],
              [-1.7021, 40.5353],
              [-1.6931, 40.5758],
              [-1.6352, 40.5793],
              [-1.5988, 40.5621],
              [-1.5467, 40.5961],
              [-1.5643, 40.614],
              [-1.5396, 40.6582],
              [-1.5368, 40.686],
              [-1.5533, 40.7038],
              [-1.5611, 40.7376],
              [-1.5412, 40.7585],
              [-1.5425, 40.8127],
              [-1.5727, 40.8308],
              [-1.6205, 40.8784],
              [-1.6082, 40.9198],
              [-1.6114, 40.9366],
              [-1.6927, 40.995],
              [-1.7578, 41.0464],
              [-1.7721, 41.0678],
              [-1.8164, 41.0947],
              [-1.8703, 41.1096],
              [-1.8896, 41.1325],
              [-1.9285, 41.1367],
              [-1.9495, 41.1668],
              [-1.9664, 41.1706],
              [-2.0072, 41.1563],
              [-2.0553, 41.1478],
              [-2.0561, 41.1261],
              [-2.0772, 41.0843],
              [-2.1435, 41.1067],
              [-2.1786, 41.0857],
              [-2.2326, 41.0983],
              [-2.2611, 41.0707],
              [-2.2908, 41.0741],
              [-2.3216, 41.0602],
              [-2.355, 41.0891],
              [-2.4167, 41.061],
              [-2.4682, 41.0937],
              [-2.4845, 41.1202],
              [-2.5083, 41.1225],
              [-2.519, 41.1527],
              [-2.574, 41.1411],
              [-2.595, 41.1517],
              [-2.5792, 41.1761],
              [-2.5937, 41.2002],
              [-2.5902, 41.2255],
              [-2.6218, 41.2209],
              [-2.6463, 41.2407],
              [-2.6717, 41.2395],
              [-2.7087, 41.2522],
              [-2.7176, 41.274],
              [-2.7612, 41.2731],
              [-2.7773, 41.2543],
              [-2.8185, 41.2573],
              [-2.8656, 41.2742],
              [-2.8696, 41.3175],
              [-2.9073, 41.328],
              [-2.9419, 41.3133],
              [-2.9635, 41.2934],
              [-3.0268, 41.2854],
              [-3.0621, 41.275],
              [-3.0936, 41.2851],
              [-3.2072, 41.3033],
              [-3.2288, 41.2971],
              [-3.2553, 41.2576],
              [-3.2733, 41.2752],
              [-3.2914, 41.2572],
              [-3.3363, 41.2514],
              [-3.3672, 41.2582],
              [-3.4, 41.2526],
              [-3.4026, 41.2112],
              [-3.4425, 41.2129],
              [-3.4586, 41.19],
              [-3.516, 41.1607],
              [-3.5389, 41.1637]
            ].reverse()
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "Castilla y León", id: "ES-CL", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-CL",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-6.8234, 42.9152],
              [-6.8337, 42.8821],
              [-6.8703, 42.8743],
              [-6.8411, 42.8239],
              [-6.8684, 42.8024],
              [-6.8664, 42.788],
              [-6.9348, 42.7413],
              [-7.0067, 42.7246],
              [-7.0441, 42.6952],
              [-7.0095, 42.6497],
              [-7.0411, 42.6355],
              [-7.0546, 42.57],
              [-7.0707, 42.5368],
              [-7.0479, 42.5075],
              [-7.0286, 42.4972],
              [-7.0015, 42.5035],
              [-6.9748, 42.4921],
              [-6.9636, 42.513],
              [-6.9297, 42.5194],
              [-6.9137, 42.5067],
              [-6.8582, 42.4868],
              [-6.8281, 42.4929],
              [-6.8083, 42.4661],
              [-6.8215, 42.4541],
              [-6.8203, 42.4214],
              [-6.8411, 42.4041],
              [-6.8199, 42.3863],
              [-6.7807, 42.3816],
              [-6.7662, 42.3666],
              [-6.733, 42.3589],
              [-6.7408, 42.3334],
              [-6.7386, 42.2968],
              [-6.7652, 42.2578],
              [-6.8009, 42.2369],
              [-6.7867, 42.2152],
              [-6.8418, 42.2278],
              [-6.8838, 42.2158],
              [-6.8875, 42.2011],
              [-6.9398, 42.1763],
              [-6.9427, 42.1392],
              [-6.9865, 42.1233],
              [-7.0056, 42.0842],
              [-7.0319, 42.0753],
              [-7.0082, 42.0515],
              [-6.9743, 42.054],
              [-6.963, 42.0307],
              [-6.9896, 41.9835],
              [-6.9823, 41.9729],
              [-6.9619, 41.9695],
              [-6.944, 41.9442],
              [-6.892, 41.9395],
              [-6.8666, 41.9473],
              [-6.8226, 41.9466],
              [-6.8053, 41.9901],
              [-6.7721, 41.9847],
              [-6.7487, 41.9423],
              [-6.6969, 41.9332],
              [-6.5999, 41.9476],
              [-6.5878, 41.9665],
              [-6.5445, 41.9326],
              [-6.5696, 41.8848],
              [-6.5198, 41.875],
              [-6.5279, 41.819],
              [-6.5436, 41.7955],
              [-6.5554, 41.7542],
              [-6.5528, 41.6925],
              [-6.5113, 41.661],
              [-6.4875, 41.658],
              [-6.4514, 41.6816],
              [-6.3485, 41.6747],
              [-6.2964, 41.659],
              [-6.2568, 41.6346],
              [-6.2369, 41.6087],
              [-6.1996, 41.5918],
              [-6.2071, 41.5618],
              [-6.2513, 41.4978],
              [-6.2826, 41.4793],
              [-6.3055, 41.4498],
              [-6.2957, 41.4373],
              [-6.3325, 41.4052],
              [-6.3162, 41.3899],
              [-6.3448, 41.3789],
              [-6.3685, 41.3929],
              [-6.3901, 41.3844],
              [-6.3772, 41.3589],
              [-6.4149, 41.3476],
              [-6.4374, 41.3062],
              [-6.4677, 41.3008],
              [-6.5781, 41.2387],
              [-6.5869, 41.2526],
              [-6.6292, 41.2428],
              [-6.6452, 41.2483],
              [-6.6916, 41.2071],
              [-6.6989, 41.182],
              [-6.7711, 41.1252],
              [-6.7561, 41.101],
              [-6.8083, 41.0366],
              [-6.8748, 41.0274],
              [-6.9145, 41.0383],
              [-6.9287, 41.0149],
              [-6.8741, 40.9609],
              [-6.8582, 40.9509],
              [-6.8472, 40.9025],
              [-6.8086, 40.8835],
              [-6.8021, 40.846],
              [-6.8242, 40.8441],
              [-6.816, 40.806],
              [-6.8302, 40.7531],
              [-6.7949, 40.6604],
              [-6.81, 40.627],
              [-6.837, 40.593],
              [-6.8429, 40.5691],
              [-6.8012, 40.5507],
              [-6.797, 40.5105],
              [-6.8119, 40.503],
              [-6.8463, 40.4577],
              [-6.8415, 40.422],
              [-6.8266, 40.3997],
              [-6.7943, 40.3726],
              [-6.7908, 40.3327],
              [-6.8393, 40.3126],
              [-6.8643, 40.2936],
              [-6.8656, 40.2673],
              [-6.8337, 40.2464],
              [-6.7604, 40.2395],
              [-6.717, 40.2688],
              [-6.6936, 40.2429],
              [-6.6679, 40.2622],
              [-6.5871, 40.2694],
              [-6.5569, 40.2898],
              [-6.5582, 40.3306],
              [-6.5251, 40.3518],
              [-6.4615, 40.3725],
              [-6.4381, 40.3722],
              [-6.4169, 40.3988],
              [-6.3623, 40.4065],
              [-6.3427, 40.4423],
              [-6.2922, 40.4523],
              [-6.2381, 40.4852],
              [-6.1988, 40.4812],
              [-6.1573, 40.4568],
              [-6.1277, 40.4203],
              [-6.076, 40.4024],
              [-6.0818, 40.3643],
              [-6.0968, 40.3534],
              [-6.0268, 40.3384],
              [-6.0067, 40.3053],
              [-5.9239, 40.2813],
              [-5.904, 40.2951],
              [-5.8938, 40.3201],
              [-5.8408, 40.3426],
              [-5.8189, 40.3601],
              [-5.7956, 40.3509],
              [-5.7821, 40.3132],
              [-5.7915, 40.2863],
              [-5.7644, 40.2804],
              [-5.7377, 40.2938],
              [-5.6911, 40.2919],
              [-5.6579, 40.2701],
              [-5.6599, 40.2575],
              [-5.6261, 40.2475],
              [-5.6184, 40.2245],
              [-5.5472, 40.197],
              [-5.4972, 40.1995],
              [-5.4372, 40.2358],
              [-5.4285, 40.2521],
              [-5.3908, 40.2509],
              [-5.3435, 40.2623],
              [-5.3696, 40.2176],
              [-5.3614, 40.1573],
              [-5.3361, 40.1144],
              [-5.3199, 40.1073],
              [-5.2116, 40.1055],
              [-5.2063, 40.0841],
              [-5.1704, 40.0853],
              [-5.1272, 40.1019],
              [-5.0703, 40.1484],
              [-5.0188, 40.158],
              [-5.0075, 40.109],
              [-4.9288, 40.1347],
              [-4.9233, 40.1694],
              [-4.8075, 40.2345],
              [-4.8175, 40.2546],
              [-4.804, 40.2764],
              [-4.7631, 40.2602],
              [-4.7425, 40.2738],
              [-4.698, 40.282],
              [-4.7019, 40.257],
              [-4.6866, 40.2112],
              [-4.6553, 40.199],
              [-4.6166, 40.2001],
              [-4.5791, 40.2164],
              [-4.5598, 40.2279],
              [-4.5688, 40.2586],
              [-4.5293, 40.2932],
              [-4.5404, 40.3297],
              [-4.5269, 40.3447],
              [-4.502, 40.3156],
              [-4.4548, 40.3198],
              [-4.458, 40.3442],
              [-4.439, 40.3504],
              [-4.4427, 40.3852],
              [-4.4142, 40.4114],
              [-4.3285, 40.4121],
              [-4.3408, 40.4381],
              [-4.3206, 40.4517],
              [-4.3273, 40.5099],
              [-4.3216, 40.5515],
              [-4.2895, 40.5655],
              [-4.2791, 40.6058],
              [-4.2905, 40.6286],
              [-4.3198, 40.6482],
              [-4.3241, 40.6588],
              [-4.2895, 40.6794],
              [-4.2741, 40.6753],
              [-4.2519, 40.6883],
              [-4.1842, 40.6978],
              [-4.1609, 40.689],
              [-4.0701, 40.7932],
              [-4.0173, 40.7817],
              [-3.9786, 40.7872],
              [-3.9615, 40.8209],
              [-3.9655, 40.8348],
              [-3.9468, 40.8679],
              [-3.9527, 40.8946],
              [-3.919, 40.9412],
              [-3.8778, 40.9754],
              [-3.8109, 40.9871],
              [-3.7798, 41.002],
              [-3.7392, 41.0543],
              [-3.6765, 41.0835],
              [-3.6355, 41.1229],
              [-3.6313, 41.136],
              [-3.5889, 41.1587],
              [-3.5389, 41.1637],
              [-3.516, 41.1607],
              [-3.4586, 41.19],
              [-3.4425, 41.2129],
              [-3.4026, 41.2112],
              [-3.4, 41.2526],
              [-3.3672, 41.2582],
              [-3.3363, 41.2514],
              [-3.2914, 41.2572],
              [-3.2733, 41.2752],
              [-3.2553, 41.2576],
              [-3.2288, 41.2971],
              [-3.2072, 41.3033],
              [-3.0936, 41.2851],
              [-3.0621, 41.275],
              [-3.0268, 41.2854],
              [-2.9635, 41.2934],
              [-2.9419, 41.3133],
              [-2.9073, 41.328],
              [-2.8696, 41.3175],
              [-2.8656, 41.2742],
              [-2.8185, 41.2573],
              [-2.7773, 41.2543],
              [-2.7612, 41.2731],
              [-2.7176, 41.274],
              [-2.7087, 41.2522],
              [-2.6717, 41.2395],
              [-2.6463, 41.2407],
              [-2.6218, 41.2209],
              [-2.5902, 41.2255],
              [-2.5937, 41.2002],
              [-2.5792, 41.1761],
              [-2.595, 41.1517],
              [-2.574, 41.1411],
              [-2.519, 41.1527],
              [-2.5083, 41.1225],
              [-2.4845, 41.1202],
              [-2.4682, 41.0937],
              [-2.4167, 41.061],
              [-2.355, 41.0891],
              [-2.3216, 41.0602],
              [-2.2908, 41.0741],
              [-2.2611, 41.0707],
              [-2.2326, 41.0983],
              [-2.1786, 41.0857],
              [-2.1435, 41.1067],
              [-2.0772, 41.0843],
              [-2.0561, 41.1261],
              [-2.0553, 41.1478],
              [-2.0825, 41.1727],
              [-2.1476, 41.1849],
              [-2.1658, 41.2821],
              [-2.1766, 41.2899],
              [-2.1621, 41.3548],
              [-2.124, 41.3708],
              [-2.1155, 41.4248],
              [-2.1005, 41.4452],
              [-2.0356, 41.4271],
              [-2.029, 41.3838],
              [-1.9547, 41.408],
              [-1.9531, 41.461],
              [-1.9805, 41.4858],
              [-1.9781, 41.5265],
              [-1.9665, 41.5451],
              [-1.9945, 41.5824],
              [-1.9682, 41.6056],
              [-1.9239, 41.5986],
              [-1.8967, 41.6336],
              [-1.8744, 41.638],
              [-1.8585, 41.6613],
              [-1.8063, 41.6663],
              [-1.8021, 41.6952],
              [-1.7877, 41.7004],
              [-1.7866, 41.7342],
              [-1.8259, 41.7773],
              [-1.8533, 41.7917],
              [-1.826, 41.818],
              [-1.8184, 41.8534],
              [-1.841, 41.8748],
              [-1.8593, 41.9172],
              [-1.8571, 41.9674],
              [-1.9383, 41.9257],
              [-1.9824, 41.9189],
              [-2.0278, 41.9517],
              [-2.1135, 41.9609],
              [-2.1191, 42.0218],
              [-2.1503, 42.0418],
              [-2.1599, 42.0665],
              [-2.1275, 42.0802],
              [-2.1474, 42.1031],
              [-2.2355, 42.1015],
              [-2.2587, 42.0862],
              [-2.282, 42.1074],
              [-2.2823, 42.1301],
              [-2.3208, 42.1438],
              [-2.3485, 42.1448],
              [-2.4411, 42.1357],
              [-2.4581, 42.115],
              [-2.4869, 42.1065],
              [-2.5171, 42.1104],
              [-2.516, 42.069],
              [-2.5488, 42.0516],
              [-2.5651, 42.014],
              [-2.5833, 41.9955],
              [-2.6251, 42.0059],
              [-2.6739, 41.9964],
              [-2.704, 42.0145],
              [-2.7455, 42.0086],
              [-2.7567, 42.032],
              [-2.7098, 42.0833],
              [-2.7072, 42.1032],
              [-2.7291, 42.1224],
              [-2.7684, 42.1243],
              [-2.7914, 42.1085],
              [-2.8034, 42.0789],
              [-2.7982, 42.0467],
              [-2.8532, 42.0304],
              [-2.8836, 42.0089],
              [-2.9132, 42.0218],
              [-2.9319, 42.0792],
              [-2.9416, 42.0888],
              [-3.004, 42.0828],
              [-3.0331, 42.0863],
              [-3.0677, 42.1341],
              [-3.1288, 42.1972],
              [-3.1038, 42.213],
              [-3.0896, 42.2454],
              [-3.1067, 42.3128],
              [-3.1091, 42.3516],
              [-3.0649, 42.356],
              [-3.0544, 42.3753],
              [-3.0622, 42.428],
              [-3.0471, 42.4459],
              [-3.0729, 42.4758],
              [-3.0916, 42.4792],
              [-3.0724, 42.5268],
              [-3.1064, 42.5294],
              [-3.1039, 42.5538],
              [-3.06, 42.5889],
              [-3.077, 42.6391],
              [-3.0043, 42.6447],
              [-2.9339, 42.6341],
              [-2.9248, 42.622],
              [-2.8834, 42.6204],
              [-2.8583, 42.6378],
              [-2.8968, 42.6541],
              [-2.9027, 42.6928],
              [-2.9305, 42.707],
              [-2.987, 42.7081],
              [-3.0171, 42.7365],
              [-3.0807, 42.7654],
              [-3.145, 42.7566],
              [-3.124, 42.7886],
              [-3.1579, 42.804],
              [-3.1055, 42.8792],
              [-3.1239, 42.8918],
              [-3.1496, 42.8604],
              [-3.1912, 42.8693],
              [-3.2231, 42.828],
              [-3.2831, 42.8758],
              [-3.278, 42.9008],
              [-3.2497, 42.9125],
              [-3.2272, 42.9494],
              [-3.1466, 42.9345],
              [-3.1493, 42.9191],
              [-3.1264, 42.9042],
              [-3.0937, 42.8999],
              [-3.0202, 42.9086],
              [-2.9807, 42.9386],
              [-3.0424, 42.9705],
              [-3.0622, 43.0029],
              [-3.1458, 43.007],
              [-3.1735, 43.0184],
              [-3.1471, 43.035],
              [-3.1352, 43.0973],
              [-3.1799, 43.1149],
              [-3.1426, 43.1613],
              [-3.1569, 43.1779],
              [-3.1842, 43.1706],
              [-3.2212, 43.1727],
              [-3.2488, 43.198],
              [-3.2774, 43.1953],
              [-3.3099, 43.1737],
              [-3.3869, 43.1414],
              [-3.4164, 43.1348],
              [-3.4856, 43.1344],
              [-3.5847, 43.1553],
              [-3.6486, 43.1814],
              [-3.7038, 43.1169],
              [-3.7524, 43.1016],
              [-3.7554, 43.0867],
              [-3.7881, 43.0791],
              [-3.8584, 43.087],
              [-3.8498, 43.0493],
              [-3.8928, 43.0418],
              [-3.9172, 43.0154],
              [-3.947, 43.0066],
              [-3.9613, 42.9904],
              [-3.9895, 42.9307],
              [-3.9725, 42.9111],
              [-3.9426, 42.9095],
              [-3.8907, 42.9251],
              [-3.8853, 42.9488],
              [-3.8646, 42.9548],
              [-3.8405, 42.9398],
              [-3.8655, 42.8988],
              [-3.8928, 42.8892],
              [-3.9126, 42.8667],
              [-3.8734, 42.852],
              [-3.8626, 42.8892],
              [-3.8255, 42.8674],
              [-3.8184, 42.8379],
              [-3.8225, 42.7993],
              [-3.8636, 42.7869],
              [-3.8937, 42.8048],
              [-3.9087, 42.7868],
              [-3.9478, 42.7616],
              [-3.9789, 42.7587],
              [-4.0002, 42.7691],
              [-4.0022, 42.8295],
              [-4.0217, 42.8195],
              [-4.0504, 42.7801],
              [-4.0819, 42.7617],
              [-4.0996, 42.7912],
              [-4.1497, 42.7893],
              [-4.1845, 42.8213],
              [-4.1418, 42.8293],
              [-4.1272, 42.8572],
              [-4.1482, 42.8653],
              [-4.1819, 42.8587],
              [-4.1975, 42.8456],
              [-4.2239, 42.8568],
              [-4.2382, 42.9386],
              [-4.2548, 42.9638],
              [-4.3466, 42.9741],
              [-4.4059, 43.039],
              [-4.4645, 43.0626],
              [-4.4776, 43.0406],
              [-4.5201, 43.0477],
              [-4.558, 43.0204],
              [-4.6107, 43.0314],
              [-4.6394, 43.018],
              [-4.7408, 43.0286],
              [-4.7322, 43.0485],
              [-4.7634, 43.0567],
              [-4.7637, 43.0759],
              [-4.8296, 43.1029],
              [-4.8477, 43.1288],
              [-4.8324, 43.1585],
              [-4.8368, 43.1832],
              [-4.8594, 43.2202],
              [-4.8923, 43.2386],
              [-4.9399, 43.2252],
              [-4.9933, 43.1858],
              [-5.0729, 43.182],
              [-5.0994, 43.1033],
              [-5.1378, 43.1043],
              [-5.1455, 43.1157],
              [-5.2193, 43.0853],
              [-5.2527, 43.0913],
              [-5.2795, 43.0796],
              [-5.3391, 43.085],
              [-5.3625, 43.0967],
              [-5.3823, 43.0875],
              [-5.3973, 43.049],
              [-5.4461, 43.0565],
              [-5.4915, 43.0275],
              [-5.5379, 43.0205],
              [-5.5726, 43.0327],
              [-5.6335, 43.0354],
              [-5.6889, 43.0587],
              [-5.7249, 43.0441],
              [-5.7655, 42.9877],
              [-5.7687, 42.969],
              [-5.8445, 42.9648],
              [-5.8612, 42.9815],
              [-5.8892, 42.981],
              [-5.956, 43.0208],
              [-5.9652, 43.0577],
              [-6.0266, 43.0572],
              [-6.0809, 43.0704],
              [-6.0904, 43.0395],
              [-6.1366, 43.0208],
              [-6.1764, 43.0482],
              [-6.2143, 43.0478],
              [-6.2234, 43.0179],
              [-6.2644, 43.0178],
              [-6.3281, 43.0343],
              [-6.358, 43.0605],
              [-6.4222, 42.9893],
              [-6.4495, 42.936],
              [-6.5113, 42.9283],
              [-6.5436, 42.9134],
              [-6.6038, 42.9171],
              [-6.6363, 42.9263],
              [-6.7232, 42.9172],
              [-6.7504, 42.8926],
              [-6.8234, 42.9152]
            ].reverse()
          ],
          [
            [
              [-2.8331, 42.7937],
              [-2.8612, 42.7451],
              [-2.8241, 42.7081],
              [-2.7849, 42.6978],
              [-2.7674, 42.6657],
              [-2.7181, 42.6616],
              [-2.7041, 42.6736],
              [-2.6519, 42.6605],
              [-2.6355, 42.6472],
              [-2.5834, 42.6513],
              [-2.5481, 42.6385],
              [-2.5168, 42.6473],
              [-2.5227, 42.6836],
              [-2.5491, 42.6693],
              [-2.5793, 42.6735],
              [-2.6089, 42.6963],
              [-2.563, 42.7405],
              [-2.5896, 42.7502],
              [-2.6037, 42.77],
              [-2.6513, 42.7666],
              [-2.7319, 42.7932],
              [-2.8331, 42.7937]
            ].reverse()
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "Cantabria", id: "ES-CB", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-CB",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.1525, 43.354],
              [-3.1925, 43.3646],
              [-3.22, 43.3947],
              [-3.3308, 43.4176],
              [-3.4327, 43.4163],
              [-3.4465, 43.4361],
              [-3.4235, 43.4461],
              [-3.4325, 43.4659],
              [-3.4675, 43.4668],
              [-3.5117, 43.4784],
              [-3.519, 43.4961],
              [-3.5451, 43.5086],
              [-3.5954, 43.5133],
              [-3.6105, 43.4991],
              [-3.6434, 43.4997],
              [-3.6907, 43.4761],
              [-3.7038, 43.4798],
              [-3.808, 43.4211],
              [-3.8337, 43.4435],
              [-3.8007, 43.4625],
              [-3.7797, 43.4644],
              [-3.784, 43.4924],
              [-3.8099, 43.4964],
              [-3.8609, 43.4774],
              [-3.9409, 43.4706],
              [-3.9601, 43.4543],
              [-3.998, 43.4409],
              [-4.0947, 43.4276],
              [-4.174, 43.4019],
              [-4.2555, 43.3974],
              [-4.3215, 43.3897],
              [-4.3542, 43.4032],
              [-4.3719, 43.3925],
              [-4.4022, 43.3978],
              [-4.5126, 43.3937],
              [-4.5371, 43.3473],
              [-4.5173, 43.3129],
              [-4.5226, 43.2789],
              [-4.5998, 43.2989],
              [-4.6571, 43.2649],
              [-4.7045, 43.267],
              [-4.7288, 43.2573],
              [-4.719, 43.2396],
              [-4.7315, 43.1946],
              [-4.7766, 43.1936],
              [-4.8368, 43.1832],
              [-4.8324, 43.1585],
              [-4.8477, 43.1288],
              [-4.8296, 43.1029],
              [-4.7637, 43.0759],
              [-4.7634, 43.0567],
              [-4.7322, 43.0485],
              [-4.7408, 43.0286],
              [-4.6394, 43.018],
              [-4.6107, 43.0314],
              [-4.558, 43.0204],
              [-4.5201, 43.0477],
              [-4.4776, 43.0406],
              [-4.4645, 43.0626],
              [-4.4059, 43.039],
              [-4.3466, 42.9741],
              [-4.2548, 42.9638],
              [-4.2382, 42.9386],
              [-4.2239, 42.8568],
              [-4.1975, 42.8456],
              [-4.1819, 42.8587],
              [-4.1482, 42.8653],
              [-4.1272, 42.8572],
              [-4.1418, 42.8293],
              [-4.1845, 42.8213],
              [-4.1497, 42.7893],
              [-4.0996, 42.7912],
              [-4.0819, 42.7617],
              [-4.0504, 42.7801],
              [-4.0217, 42.8195],
              [-4.0022, 42.8295],
              [-4.0002, 42.7691],
              [-3.9789, 42.7587],
              [-3.9478, 42.7616],
              [-3.9087, 42.7868],
              [-3.8937, 42.8048],
              [-3.8636, 42.7869],
              [-3.8225, 42.7993],
              [-3.8184, 42.8379],
              [-3.8255, 42.8674],
              [-3.8626, 42.8892],
              [-3.8734, 42.852],
              [-3.9126, 42.8667],
              [-3.8928, 42.8892],
              [-3.8655, 42.8988],
              [-3.8405, 42.9398],
              [-3.8646, 42.9548],
              [-3.8853, 42.9488],
              [-3.8907, 42.9251],
              [-3.9426, 42.9095],
              [-3.9725, 42.9111],
              [-3.9895, 42.9307],
              [-3.9613, 42.9904],
              [-3.947, 43.0066],
              [-3.9172, 43.0154],
              [-3.8928, 43.0418],
              [-3.8498, 43.0493],
              [-3.8584, 43.087],
              [-3.7881, 43.0791],
              [-3.7554, 43.0867],
              [-3.7524, 43.1016],
              [-3.7038, 43.1169],
              [-3.6486, 43.1814],
              [-3.5847, 43.1553],
              [-3.4856, 43.1344],
              [-3.4164, 43.1348],
              [-3.4296, 43.1667],
              [-3.4333, 43.2102],
              [-3.4482, 43.2367],
              [-3.3983, 43.2467],
              [-3.3816, 43.2707],
              [-3.338, 43.2785],
              [-3.2938, 43.3008],
              [-3.2781, 43.2903],
              [-3.2244, 43.3091],
              [-3.2176, 43.2875],
              [-3.1567, 43.3019],
              [-3.1525, 43.354]
            ].reverse()
          ],
          [
            [
              [-3.2832, 43.1998],
              [-3.2644, 43.2079],
              [-3.268, 43.2325],
              [-3.248, 43.2606],
              [-3.2652, 43.2676],
              [-3.3035, 43.2596],
              [-3.2915, 43.2399],
              [-3.294, 43.2061],
              [-3.2832, 43.1998]
            ].reverse()
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "Asturias", id: "ES-AS", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-AS",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-4.5126, 43.3937],
            [-4.5605, 43.3928],
            [-4.5821, 43.3994],
            [-4.6274, 43.3971],
            [-4.7351, 43.4155],
            [-4.7653, 43.4288],
            [-4.8241, 43.4353],
            [-4.8384, 43.4457],
            [-4.8714, 43.4423],
            [-4.9293, 43.4616],
            [-5.0129, 43.458],
            [-5.0721, 43.4667],
            [-5.082, 43.4746],
            [-5.1475, 43.4788],
            [-5.2151, 43.4738],
            [-5.2662, 43.519],
            [-5.2946, 43.5354],
            [-5.3892, 43.5316],
            [-5.3997, 43.5525],
            [-5.5276, 43.5461],
            [-5.6237, 43.5534],
            [-5.6918, 43.543],
            [-5.7071, 43.5701],
            [-5.7554, 43.5829],
            [-5.7882, 43.6137],
            [-5.7798, 43.6262],
            [-5.859, 43.6555],
            [-5.8678, 43.6313],
            [-5.9174, 43.6198],
            [-5.9161, 43.6062],
            [-5.9575, 43.5785],
            [-6.0176, 43.5816],
            [-6.0663, 43.5646],
            [-6.1162, 43.5542],
            [-6.1613, 43.5684],
            [-6.1833, 43.5627],
            [-6.2455, 43.5827],
            [-6.3544, 43.5502],
            [-6.3972, 43.5592],
            [-6.4286, 43.552],
            [-6.4593, 43.5635],
            [-6.4879, 43.5488],
            [-6.5909, 43.553],
            [-6.624, 43.5716],
            [-6.6848, 43.5587],
            [-6.7205, 43.5568],
            [-6.7736, 43.5678],
            [-6.7839, 43.5561],
            [-6.8244, 43.565],
            [-6.9151, 43.5729],
            [-6.9962, 43.5513],
            [-7.0232, 43.5594],
            [-7.0265, 43.5257],
            [-7.0432, 43.5075],
            [-7.0555, 43.4724],
            [-7.0914, 43.4526],
            [-7.1019, 43.4276],
            [-7.1258, 43.4235],
            [-7.1624, 43.4316],
            [-7.1765, 43.4141],
            [-7.175, 43.3867],
            [-7.1367, 43.3837],
            [-7.1298, 43.3306],
            [-7.0912, 43.2999],
            [-7.0642, 43.2998],
            [-7.07, 43.2688],
            [-7.0626, 43.2475],
            [-7.021, 43.2153],
            [-6.9863, 43.2033],
            [-6.9647, 43.1864],
            [-6.9791, 43.166],
            [-6.9551, 43.136],
            [-6.937, 43.1333],
            [-6.8848, 43.1676],
            [-6.8396, 43.1674],
            [-6.826, 43.1467],
            [-6.8291, 43.1234],
            [-6.9129, 43.0751],
            [-6.9391, 43.0819],
            [-6.9668, 43.0566],
            [-6.9655, 43.0357],
            [-6.9957, 43.0212],
            [-6.9663, 42.9936],
            [-6.9616, 43.027],
            [-6.9072, 42.9904],
            [-6.8795, 42.9919],
            [-6.8353, 42.9589],
            [-6.8406, 42.9302],
            [-6.8234, 42.9152],
            [-6.7504, 42.8926],
            [-6.7232, 42.9172],
            [-6.6363, 42.9263],
            [-6.6038, 42.9171],
            [-6.5436, 42.9134],
            [-6.5113, 42.9283],
            [-6.4495, 42.936],
            [-6.4222, 42.9893],
            [-6.358, 43.0605],
            [-6.3281, 43.0343],
            [-6.2644, 43.0178],
            [-6.2234, 43.0179],
            [-6.2143, 43.0478],
            [-6.1764, 43.0482],
            [-6.1366, 43.0208],
            [-6.0904, 43.0395],
            [-6.0809, 43.0704],
            [-6.0266, 43.0572],
            [-5.9652, 43.0577],
            [-5.956, 43.0208],
            [-5.8892, 42.981],
            [-5.8612, 42.9815],
            [-5.8445, 42.9648],
            [-5.7687, 42.969],
            [-5.7655, 42.9877],
            [-5.7249, 43.0441],
            [-5.6889, 43.0587],
            [-5.6335, 43.0354],
            [-5.5726, 43.0327],
            [-5.5379, 43.0205],
            [-5.4915, 43.0275],
            [-5.4461, 43.0565],
            [-5.3973, 43.049],
            [-5.3823, 43.0875],
            [-5.3625, 43.0967],
            [-5.3391, 43.085],
            [-5.2795, 43.0796],
            [-5.2527, 43.0913],
            [-5.2193, 43.0853],
            [-5.1455, 43.1157],
            [-5.1378, 43.1043],
            [-5.0994, 43.1033],
            [-5.0729, 43.182],
            [-4.9933, 43.1858],
            [-4.9399, 43.2252],
            [-4.8923, 43.2386],
            [-4.8594, 43.2202],
            [-4.8368, 43.1832],
            [-4.7766, 43.1936],
            [-4.7315, 43.1946],
            [-4.719, 43.2396],
            [-4.7288, 43.2573],
            [-4.7045, 43.267],
            [-4.6571, 43.2649],
            [-4.5998, 43.2989],
            [-4.5226, 43.2789],
            [-4.5173, 43.3129],
            [-4.5371, 43.3473],
            [-4.5126, 43.3937]
          ].reverse()
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "Aragón", id: "ES-AR", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-AR",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.8462, 42.008],
            [-1.8571, 41.9674],
            [-1.8593, 41.9172],
            [-1.841, 41.8748],
            [-1.8184, 41.8534],
            [-1.826, 41.818],
            [-1.8533, 41.7917],
            [-1.8259, 41.7773],
            [-1.7866, 41.7342],
            [-1.7877, 41.7004],
            [-1.8021, 41.6952],
            [-1.8063, 41.6663],
            [-1.8585, 41.6613],
            [-1.8744, 41.638],
            [-1.8967, 41.6336],
            [-1.9239, 41.5986],
            [-1.9682, 41.6056],
            [-1.9945, 41.5824],
            [-1.9665, 41.5451],
            [-1.9781, 41.5265],
            [-1.9805, 41.4858],
            [-1.9531, 41.461],
            [-1.9547, 41.408],
            [-2.029, 41.3838],
            [-2.0356, 41.4271],
            [-2.1005, 41.4452],
            [-2.1155, 41.4248],
            [-2.124, 41.3708],
            [-2.1621, 41.3548],
            [-2.1766, 41.2899],
            [-2.1658, 41.2821],
            [-2.1476, 41.1849],
            [-2.0825, 41.1727],
            [-2.0553, 41.1478],
            [-2.0072, 41.1563],
            [-1.9664, 41.1706],
            [-1.9495, 41.1668],
            [-1.9285, 41.1367],
            [-1.8896, 41.1325],
            [-1.8703, 41.1096],
            [-1.8164, 41.0947],
            [-1.7721, 41.0678],
            [-1.7578, 41.0464],
            [-1.6927, 40.995],
            [-1.6114, 40.9366],
            [-1.6082, 40.9198],
            [-1.6205, 40.8784],
            [-1.5727, 40.8308],
            [-1.5425, 40.8127],
            [-1.5412, 40.7585],
            [-1.5611, 40.7376],
            [-1.5533, 40.7038],
            [-1.5368, 40.686],
            [-1.5396, 40.6582],
            [-1.5643, 40.614],
            [-1.5467, 40.5961],
            [-1.5988, 40.5621],
            [-1.6352, 40.5793],
            [-1.6931, 40.5758],
            [-1.7021, 40.5353],
            [-1.6928, 40.4884],
            [-1.7354, 40.4728],
            [-1.7792, 40.4272],
            [-1.8006, 40.413],
            [-1.7528, 40.3616],
            [-1.6981, 40.322],
            [-1.6898, 40.3103],
            [-1.6116, 40.2436],
            [-1.5945, 40.2361],
            [-1.5417, 40.19],
            [-1.5098, 40.2026],
            [-1.4749, 40.1845],
            [-1.4396, 40.1961],
            [-1.4505, 40.1426],
            [-1.3516, 40.1295],
            [-1.3164, 40.1498],
            [-1.3228, 40.1719],
            [-1.3131, 40.2024],
            [-1.2529, 40.1432],
            [-1.2441, 40.117],
            [-1.2121, 40.1099],
            [-1.147, 40.1133],
            [-1.0809, 40.0712],
            [-1.0705, 40.0504],
            [-1.0825, 40.037],
            [-1.1313, 40.017],
            [-1.1642, 40.0114],
            [-1.141, 39.9704],
            [-1.0551, 39.9792],
            [-1.0247, 39.9735],
            [-1.0033, 39.9808],
            [-0.9689, 39.974],
            [-0.9047, 39.9392],
            [-0.9134, 39.9176],
            [-0.901, 39.8899],
            [-0.909, 39.8672],
            [-0.8665, 39.8472],
            [-0.8283, 39.8715],
            [-0.7951, 39.8814],
            [-0.8365, 39.9241],
            [-0.8343, 39.9774],
            [-0.7863, 39.9902],
            [-0.7662, 40.012],
            [-0.7618, 40.0413],
            [-0.7187, 40.0395],
            [-0.6631, 40.0514],
            [-0.631, 40.0743],
            [-0.6279, 40.1034],
            [-0.6097, 40.1265],
            [-0.5851, 40.1314],
            [-0.5705, 40.1557],
            [-0.5712, 40.1795],
            [-0.558, 40.2001],
            [-0.5485, 40.2443],
            [-0.511, 40.2309],
            [-0.4516, 40.2355],
            [-0.3836, 40.2637],
            [-0.3896, 40.2863],
            [-0.3759, 40.3049],
            [-0.3439, 40.3248],
            [-0.3055, 40.3618],
            [-0.2811, 40.3692],
            [-0.2867, 40.3871],
            [-0.3319, 40.4224],
            [-0.3393, 40.4524],
            [-0.2718, 40.4762],
            [-0.2763, 40.4988],
            [-0.3002, 40.5144],
            [-0.2907, 40.5436],
            [-0.2962, 40.5609],
            [-0.2917, 40.6044],
            [-0.3239, 40.6029],
            [-0.3788, 40.6229],
            [-0.3576, 40.6771],
            [-0.2948, 40.6735],
            [-0.237, 40.691],
            [-0.2395, 40.7156],
            [-0.2255, 40.7525],
            [-0.1967, 40.7836],
            [-0.1448, 40.785],
            [-0.1242, 40.7523],
            [-0.0631, 40.7275],
            [0.0141, 40.7293],
            [0.0441, 40.6931],
            [0.0746, 40.7152],
            [0.1081, 40.7271],
            [0.1471, 40.7192],
            [0.1699, 40.7336],
            [0.189, 40.7507],
            [0.2349, 40.7757],
            [0.2776, 40.8212],
            [0.2517, 40.8549],
            [0.2419, 40.8808],
            [0.2491, 40.9073],
            [0.2864, 40.9639],
            [0.2693, 40.9966],
            [0.2638, 41.0263],
            [0.2353, 41.0445],
            [0.2018, 41.0853],
            [0.2016, 41.1036],
            [0.2197, 41.1309],
            [0.2469, 41.1287],
            [0.3028, 41.1623],
            [0.3208, 41.2216],
            [0.3627, 41.2232],
            [0.3868, 41.2768],
            [0.3481, 41.329],
            [0.3739, 41.3539],
            [0.3608, 41.3712],
            [0.3208, 41.3955],
            [0.3492, 41.4257],
            [0.3553, 41.4545],
            [0.3475, 41.4878],
            [0.3983, 41.4905],
            [0.4465, 41.549],
            [0.4325, 41.5654],
            [0.4291, 41.6018],
            [0.4028, 41.5927],
            [0.3508, 41.5992],
            [0.3585, 41.6303],
            [0.3264, 41.6601],
            [0.328, 41.6789],
            [0.3692, 41.7137],
            [0.4006, 41.7573],
            [0.4718, 41.7666],
            [0.4846, 41.7996],
            [0.5151, 41.8207],
            [0.5451, 41.82],
            [0.5603, 41.8532],
            [0.6085, 41.8708],
            [0.595, 41.8837],
            [0.6005, 41.922],
            [0.5649, 41.9314],
            [0.5793, 41.9551],
            [0.6051, 41.9595],
            [0.6261, 41.9897],
            [0.6551, 41.9964],
            [0.6511, 42.0232],
            [0.6662, 42.0409],
            [0.702, 42.1205],
            [0.6934, 42.15],
            [0.7139, 42.2218],
            [0.7358, 42.2527],
            [0.7449, 42.2996],
            [0.7636, 42.3217],
            [0.7418, 42.3334],
            [0.7646, 42.3564],
            [0.7311, 42.3619],
            [0.7454, 42.3847],
            [0.7354, 42.4147],
            [0.7109, 42.4355],
            [0.6972, 42.4719],
            [0.7324, 42.5028],
            [0.7213, 42.5267],
            [0.7489, 42.5523],
            [0.7668, 42.6105],
            [0.7099, 42.6192],
            [0.6951, 42.6582],
            [0.6605, 42.6913],
            [0.6047, 42.6996],
            [0.5887, 42.6958],
            [0.5271, 42.703],
            [0.5163, 42.6918],
            [0.4838, 42.6986],
            [0.4215, 42.6909],
            [0.3922, 42.7129],
            [0.358, 42.7206],
            [0.3241, 42.7048],
            [0.3198, 42.6833],
            [0.2934, 42.6735],
            [0.2714, 42.6901],
            [0.2586, 42.7158],
            [0.2256, 42.7175],
            [0.1752, 42.7369],
            [0.131, 42.7152],
            [0.0765, 42.7145],
            [0.0592, 42.698],
            [0.0258, 42.7022],
            [-0.003, 42.6848],
            [-0.0628, 42.6949],
            [-0.0682, 42.7165],
            [-0.1069, 42.7203],
            [-0.11, 42.7339],
            [-0.155, 42.7919],
            [-0.1781, 42.7853],
            [-0.2174, 42.7975],
            [-0.2839, 42.8374],
            [-0.3449, 42.8373],
            [-0.3951, 42.7988],
            [-0.4106, 42.8072],
            [-0.4419, 42.795],
            [-0.5086, 42.8264],
            [-0.5228, 42.7991],
            [-0.5975, 42.804],
            [-0.6037, 42.8317],
            [-0.7295, 42.8963],
            [-0.7244, 42.9195],
            [-0.782, 42.9215],
            [-0.8163, 42.9023],
            [-0.8196, 42.8681],
            [-0.856, 42.8468],
            [-0.8483, 42.7839],
            [-0.8732, 42.7591],
            [-0.936, 42.7349],
            [-0.9494, 42.7117],
            [-0.9696, 42.7037],
            [-1.0175, 42.7024],
            [-1.0401, 42.6911],
            [-1.0408, 42.6472],
            [-1.1531, 42.6483],
            [-1.16, 42.6004],
            [-1.2048, 42.5722],
            [-1.2037, 42.5488],
            [-1.2299, 42.542],
            [-1.2653, 42.5565],
            [-1.2888, 42.5229],
            [-1.2738, 42.481],
            [-1.2932, 42.4546],
            [-1.3164, 42.4482],
            [-1.3412, 42.4236],
            [-1.3528, 42.3865],
            [-1.3351, 42.3705],
            [-1.3337, 42.3477],
            [-1.365, 42.3285],
            [-1.3994, 42.2895],
            [-1.3921, 42.2736],
            [-1.4155, 42.2236],
            [-1.4143, 42.2012],
            [-1.3927, 42.1805],
            [-1.3983, 42.1242],
            [-1.3675, 42.106],
            [-1.3529, 42.0728],
            [-1.312, 42.0731],
            [-1.3079, 42.041],
            [-1.3315, 42.0254],
            [-1.3634, 41.984],
            [-1.3804, 41.9435],
            [-1.4169, 41.9135],
            [-1.5007, 41.9231],
            [-1.5221, 41.9102],
            [-1.5958, 41.9268],
            [-1.6116, 41.9486],
            [-1.674, 41.9657],
            [-1.6868, 41.9527],
            [-1.7328, 41.9622],
            [-1.7671, 41.9937],
            [-1.7944, 41.9883],
            [-1.8358, 41.993],
            [-1.8462, 42.008]
          ].reverse()
        ]
      }
    },
    {
      type: "Feature",
      properties: { name: "Andalucía", id: "ES-AN", CNTRY: "Spain", TYPE: "Autonomous Community" },
      id: "ES-AN",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.9299, 38.206],
            [-6.9662, 38.1661],
            [-6.9551, 38.1482],
            [-6.9706, 38.1105],
            [-6.9822, 38.1052],
            [-6.9907, 38.0557],
            [-7.006, 38.0207],
            [-7.0601, 38.0169],
            [-7.0979, 38.0412],
            [-7.1251, 38.0273],
            [-7.1232, 38.0061],
            [-7.1621, 37.9954],
            [-7.2016, 38.001],
            [-7.2586, 37.9807],
            [-7.2498, 37.9642],
            [-7.2552, 37.9253],
            [-7.2813, 37.8959],
            [-7.2806, 37.8764],
            [-7.3234, 37.8145],
            [-7.3769, 37.7821],
            [-7.3943, 37.7649],
            [-7.423, 37.7552],
            [-7.4466, 37.6977],
            [-7.4556, 37.649],
            [-7.4849, 37.6257],
            [-7.4983, 37.5915],
            [-7.5245, 37.5545],
            [-7.5122, 37.5239],
            [-7.4954, 37.5231],
            [-7.4848, 37.4976],
            [-7.4657, 37.4893],
            [-7.47, 37.4693],
            [-7.4571, 37.4522],
            [-7.4616, 37.4132],
            [-7.4414, 37.39],
            [-7.446, 37.3481],
            [-7.4426, 37.3105],
            [-7.4221, 37.2738],
            [-7.4325, 37.2581],
            [-7.4132, 37.2243],
            [-7.4086, 37.1916],
            [-7.3956, 37.174],
            [-7.369, 37.1705],
            [-7.3327, 37.1938],
            [-7.2882, 37.1995],
            [-7.1471, 37.2071],
            [-7.0489, 37.2084],
            [-6.9707, 37.1772],
            [-6.9304, 37.1838],
            [-6.8645, 37.1446],
            [-6.7623, 37.1069],
            [-6.5983, 37.0242],
            [-6.5284, 36.9823],
            [-6.4611, 36.9228],
            [-6.4389, 36.8923],
            [-6.3985, 36.8115],
            [-6.3577, 36.7873],
            [-6.4215, 36.7454],
            [-6.443, 36.7377],
            [-6.4321, 36.7064],
            [-6.3987, 36.6578],
            [-6.3858, 36.6273],
            [-6.2991, 36.6168],
            [-6.2654, 36.5791],
            [-6.2384, 36.5857],
            [-6.2229, 36.5663],
            [-6.226, 36.5482],
            [-6.2504, 36.5205],
            [-6.2876, 36.5202],
            [-6.2491, 36.4493],
            [-6.2337, 36.4347],
            [-6.2095, 36.3852],
            [-6.1866, 36.373],
            [-6.1427, 36.2961],
            [-6.1054, 36.2839],
            [-6.0609, 36.2093],
            [-6.0362, 36.1817],
            [-5.9837, 36.176],
            [-5.9431, 36.1865],
            [-5.917, 36.184],
            [-5.8764, 36.1584],
            [-5.8458, 36.1313],
            [-5.8251, 36.1034],
            [-5.7953, 36.0776],
            [-5.7783, 36.088],
            [-5.7497, 36.0706],
            [-5.7082, 36.0589],
            [-5.6861, 36.0666],
            [-5.6433, 36.0482],
            [-5.608, 36.0107],
            [-5.5747, 36.0143],
            [-5.4857, 36.0534],
            [-5.4639, 36.0495],
            [-5.4302, 36.07],
            [-5.4436, 36.0893],
            [-5.4363, 36.1149],
            [-5.446, 36.152],
            [-5.4193, 36.1784],
            [-5.3925, 36.1799],
            [-5.3383, 36.1563],
            [-5.3154, 36.2312],
            [-5.295, 36.2475],
            [-5.2677, 36.2986],
            [-5.2473, 36.3098],
            [-5.2242, 36.3679],
            [-5.1745, 36.415],
            [-5.1546, 36.415],
            [-5.0861, 36.4479],
            [-5.0343, 36.4606],
            [-5.0054, 36.4589],
            [-4.9348, 36.4993],
            [-4.8681, 36.5073],
            [-4.7428, 36.4841],
            [-4.6912, 36.4919],
            [-4.6768, 36.5028],
            [-4.6386, 36.507],
            [-4.6115, 36.5539],
            [-4.5832, 36.5739],
            [-4.5352, 36.5813],
            [-4.5131, 36.5995],
            [-4.4825, 36.6423],
            [-4.4535, 36.667],
            [-4.4312, 36.7053],
            [-4.3947, 36.7223],
            [-4.3386, 36.7118],
            [-4.2896, 36.714],
            [-4.2467, 36.7096],
            [-4.1047, 36.7235],
            [-4.0684, 36.7478],
            [-4.0207, 36.7433],
            [-3.9563, 36.7256],
            [-3.9198, 36.7404],
            [-3.8834, 36.7406],
            [-3.8343, 36.7538],
            [-3.7534, 36.7378],
            [-3.7263, 36.7282],
            [-3.6772, 36.7294],
            [-3.6571, 36.7465],
            [-3.6021, 36.7439],
            [-3.5794, 36.7232],
            [-3.5558, 36.7147],
            [-3.5268, 36.725],
            [-3.4806, 36.697],
            [-3.4259, 36.6956],
            [-3.3623, 36.7163],
            [-3.3389, 36.738],
            [-3.3054, 36.7377],
            [-3.2443, 36.7538],
            [-3.1464, 36.7449],
            [-3.1152, 36.7507],
            [-3.0713, 36.7451],
            [-3.0212, 36.7463],
            [-2.9766, 36.7375],
            [-2.9411, 36.7516],
            [-2.8993, 36.7385],
            [-2.8595, 36.6983],
            [-2.8167, 36.7046],
            [-2.7736, 36.6805],
            [-2.7004, 36.683],
            [-2.6504, 36.6993],
            [-2.6196, 36.7272],
            [-2.6003, 36.7751],
            [-2.5637, 36.8164],
            [-2.5018, 36.8241],
            [-2.4777, 36.8367],
            [-2.4314, 36.811],
            [-2.3681, 36.8408],
            [-2.3193, 36.8336],
            [-2.2919, 36.8197],
            [-2.2444, 36.7796],
            [-2.1999, 36.727],
            [-2.1846, 36.7194],
            [-2.1215, 36.7329],
            [-2.1053, 36.7627],
            [-2.062, 36.7772],
            [-2.0617, 36.806],
            [-2.025, 36.8316],
            [-1.9993, 36.8403],
            [-2.0032, 36.8813],
            [-1.9322, 36.9387],
            [-1.8988, 36.9473],
            [-1.9008, 36.9888],
            [-1.8785, 37.0143],
            [-1.8753, 37.0362],
            [-1.8499, 37.0713],
            [-1.8461, 37.1057],
            [-1.8336, 37.1192],
            [-1.8242, 37.1688],
            [-1.7951, 37.2305],
            [-1.7737, 37.2402],
            [-1.7568, 37.2646],
            [-1.7067, 37.3067],
            [-1.6943, 37.3315],
            [-1.6694, 37.357],
            [-1.6503, 37.3565],
            [-1.6267, 37.3734],
            [-1.7353, 37.4414],
            [-1.8451, 37.4552],
            [-1.906, 37.5335],
            [-1.938, 37.5678],
            [-1.978, 37.6198],
            [-1.9811, 37.6349],
            [-2.0119, 37.6722],
            [-2.0076, 37.7197],
            [-1.9957, 37.7286],
            [-2.0095, 37.774],
            [-1.9956, 37.8435],
            [-1.9757, 37.8696],
            [-2.006, 37.8797],
            [-2.022, 37.8682],
            [-2.0731, 37.883],
            [-2.0993, 37.8773],
            [-2.1223, 37.8996],
            [-2.1724, 37.8886],
            [-2.189, 37.9057],
            [-2.2544, 37.9397],
            [-2.2836, 37.9676],
            [-2.2988, 38.0032],
            [-2.3395, 38.0258],
            [-2.3719, 38.0235],
            [-2.426, 38.0337],
            [-2.443, 38.0461],
            [-2.4794, 38.0534],
            [-2.5039, 38.068],
            [-2.553, 38.0828],
            [-2.5225, 38.1071],
            [-2.5092, 38.1285],
            [-2.4462, 38.1853],
            [-2.4535, 38.2165],
            [-2.4412, 38.2318],
            [-2.4353, 38.2652],
            [-2.441, 38.286],
            [-2.4849, 38.3018],
            [-2.48, 38.3487],
            [-2.4919, 38.3706],
            [-2.4841, 38.3974],
            [-2.5573, 38.4056],
            [-2.5773, 38.4263],
            [-2.5678, 38.4603],
            [-2.5704, 38.4942],
            [-2.5996, 38.5134],
            [-2.6406, 38.509],
            [-2.6748, 38.4957],
            [-2.7327, 38.5135],
            [-2.7647, 38.5317],
            [-2.7792, 38.512],
            [-2.8255, 38.4868],
            [-2.8605, 38.4758],
            [-2.8904, 38.4566],
            [-2.9047, 38.469],
            [-2.9624, 38.4698],
            [-2.9933, 38.4512],
            [-3.0047, 38.414],
            [-3.0658, 38.4774],
            [-3.1282, 38.4396],
            [-3.1798, 38.4506],
            [-3.2764, 38.4577],
            [-3.3074, 38.4782],
            [-3.3748, 38.474],
            [-3.3768, 38.4402],
            [-3.4515, 38.4003],
            [-3.5291, 38.4084],
            [-3.5346, 38.4381],
            [-3.5819, 38.4478],
            [-3.5868, 38.4069],
            [-3.6199, 38.3934],
            [-3.6974, 38.4108],
            [-3.7294, 38.424],
            [-3.7393, 38.4138],
            [-3.7811, 38.425],
            [-3.8242, 38.4146],
            [-3.8312, 38.3917],
            [-3.8577, 38.3731],
            [-3.915, 38.3678],
            [-4.0041, 38.3679],
            [-4.0434, 38.3779],
            [-4.1687, 38.3826],
            [-4.226, 38.3975],
            [-4.2642, 38.3997],
            [-4.2778, 38.3797],
            [-4.2685, 38.3469],
            [-4.3208, 38.3622],
            [-4.4347, 38.4016],
            [-4.4515, 38.4009],
            [-4.5122, 38.4632],
            [-4.5571, 38.4768],
            [-4.5663, 38.4916],
            [-4.6004, 38.4925],
            [-4.6225, 38.5239],
            [-4.6635, 38.5486],
            [-4.6969, 38.5517],
            [-4.7054, 38.5729],
            [-4.7282, 38.5747],
            [-4.7859, 38.5965],
            [-4.8115, 38.5951],
            [-4.862, 38.6173],
            [-4.8688, 38.6808],
            [-4.8833, 38.6865],
            [-4.9296, 38.6812],
            [-5.0016, 38.6943],
            [-5.0237, 38.7261],
            [-5.0458, 38.7294],
            [-5.1006, 38.7071],
            [-5.1195, 38.7157],
            [-5.1796, 38.7114],
            [-5.1679, 38.6766],
            [-5.2483, 38.638],
            [-5.2914, 38.6114],
            [-5.3134, 38.5774],
            [-5.3694, 38.5834],
            [-5.4114, 38.5201],
            [-5.4453, 38.503],
            [-5.4955, 38.4589],
            [-5.5196, 38.4632],
            [-5.5722, 38.4275],
            [-5.5842, 38.386],
            [-5.5612, 38.3715],
            [-5.5731, 38.3284],
            [-5.5552, 38.3178],
            [-5.5434, 38.2819],
            [-5.5254, 38.2625],
            [-5.5203, 38.2077],
            [-5.5382, 38.1994],
            [-5.5412, 38.1759],
            [-5.5595, 38.1495],
            [-5.5877, 38.1322],
            [-5.6395, 38.1347],
            [-5.6714, 38.0985],
            [-5.7084, 38.0806],
            [-5.7341, 38.0875],
            [-5.7411, 38.1261],
            [-5.6956, 38.1506],
            [-5.6881, 38.1755],
            [-5.7387, 38.1949],
            [-5.7583, 38.181],
            [-5.7817, 38.1858],
            [-5.7998, 38.1747],
            [-5.8383, 38.1753],
            [-5.8747, 38.1583],
            [-5.8808, 38.1327],
            [-5.914, 38.1211],
            [-5.9297, 38.0957],
            [-5.9082, 38.0678],
            [-5.9375, 38.0401],
            [-5.9466, 38.0025],
            [-6.0185, 37.9908],
            [-6.0336, 37.9972],
            [-6.0956, 37.9831],
            [-6.1156, 37.9842],
            [-6.1813, 37.9416],
            [-6.2035, 37.9619],
            [-6.2392, 37.959],
            [-6.2527, 37.9709],
            [-6.3018, 37.9775],
            [-6.3495, 38.0045],
            [-6.37, 38.0499],
            [-6.4146, 38.0589],
            [-6.465, 38.0356],
            [-6.4703, 38.0117],
            [-6.5592, 38.0281],
            [-6.5834, 38.0224],
            [-6.5821, 38.0525],
            [-6.6228, 38.0961],
            [-6.6612, 38.0919],
            [-6.6902, 38.0993],
            [-6.7589, 38.0923],
            [-6.7703, 38.1099],
            [-6.8072, 38.11],
            [-6.7941, 38.177],
            [-6.8553, 38.1784],
            [-6.9023, 38.201],
            [-6.9299, 38.206]
          ].reverse()
        ]
      }
    }
  ]
};
export default map;
