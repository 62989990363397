import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { cArea2Treaties } from '../../config/constants'
import useGqlEntriesRatification from '../../gql/useGqlEntriesRatification'
import Table from '../components/Table/Table'
import { a2columns, citeA2 } from '../config/a2columns'
import './JurProfileAreas.css'

export default function JurProfileArea2({ jur, hide = false }) {
  const sectionDynamic = useRef(null)
  const [t, i18n] = useTranslation()
  const { entriesRatification } = useGqlEntriesRatification(jur.id)

  const onBoxClick = key => {
    window.scrollTo(0, sectionDynamic.current.offsetTop)
  }
  if (hide) return null;
  return (
    <>
      <div className="content__container jurProfile__area2">
        <section className="JurProfileSummary__section">
          <div className="jps__header">
            <h4 className="jps__title">Summary</h4>
            {false && (
              <button className="jps__button btn btn-link">
                <img
                  className="jpsb__countryFlag"
                  src={
                    '/images/flags_svg/' + jur.a2_code.toLowerCase() + '.svg'
                  }
                  alt="Country Flag"
                />
                {jur.name}
              </button>
            )}
          </div>
          {entriesRatification && entriesRatification.length && (
            <div className="jps__body">
              <div className="jps__discriminationSection">
                <div className="infoBox__container">
                  {cArea2Treaties()
                    .filter(r => !r.op)
                    .map(rat => (
                      <div
                        className={
                          'infoSection__box isb-' + (rat.sub ? rat.sub + 1 : 1)
                        }
                      >
                        <h5 className="isb-title">{rat.name}</h5>
                        <div
                          className={
                            !entriesRatification.find(
                              r => parseInt(r.treaty_body.id) === rat.id
                            ) ||
                            !entriesRatification.find(
                              r => parseInt(r.treaty_body.id) === rat.id
                            ).ras
                              ? 'isb__black'
                              : 'isb__white'
                          }
                          key={1}
                          onClick={() => onBoxClick(1)}
                        >
                          <span className="isb-boxTitle">{rat.name}</span>
                          <h6 className="isb-act">
                            {rat.boxName ? rat.boxName : rat.fullname}
                          </h6>
                          <span
                            className={
                              'isb-implementation ' +
                              (!entriesRatification.find(
                                r => parseInt(r.treaty_body.id) === rat.id
                              ) ||
                              !entriesRatification.find(
                                r => parseInt(r.treaty_body.id) === rat.id
                              ).ras
                                ? 'text-no'
                                : 'text-green')
                            }
                          >
                            {!entriesRatification.find(
                              r => parseInt(r.treaty_body.id) === rat.id
                            ) ||
                            !entriesRatification.find(
                              r => parseInt(r.treaty_body.id) === rat.id
                            ).ras
                              ? t('NO')
                              : t('YES')}
                          </span>
                        </div>
                        {cArea2Treaties()
                          .filter(r => r.op && r.parent === rat.id)
                          .map(rat2 => (
                            <div
                              className={
                                !entriesRatification.find(
                                  r => parseInt(r.treaty_body.id) === rat2.id
                                ) ||
                                !entriesRatification.find(
                                  r => parseInt(r.treaty_body.id) === rat2.id
                                ).ras
                                  ? 'isb__black'
                                  : 'isb__white'
                              }
                              key={2}
                              onClick={() => onBoxClick(2)}
                            >
                              <span className="isb-boxTitle">{rat2.name}</span>
                              <h6 className="isb-act">
                                {rat2.boxName ? rat2.boxName : rat2.fullname}
                              </h6>
                              <span
                                className={
                                  'isb-implementation ' +
                                  (!entriesRatification.find(
                                    r => parseInt(r.treaty_body.id) === rat2.id
                                  ) ||
                                  !entriesRatification.find(
                                    r => parseInt(r.treaty_body.id) === rat2.id
                                  ).ras
                                    ? 'text-no'
                                    : 'text-green')
                                }
                              >
                                {!entriesRatification.find(
                                  r => parseInt(r.treaty_body.id) === rat2.id
                                ) ||
                                !entriesRatification.find(
                                  r => parseInt(r.treaty_body.id) === rat2.id
                                ).ras
                                  ? t('NO')
                                  : t('YES')}
                              </span>
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </section>
      </div>

      <section ref={sectionDynamic} className="section1-jur1 section1-area2">
        <div className="content__container">
          <h4 className="jpjs__area">{t('AREA')} 2</h4>
          <div className="jpjs__title">
            <h2 className="jpjs__title">
              {t('International Human Rights Treaties')}
            </h2>
          </div>

          {entriesRatification && (
            <div className="area2Table__section table__section">
              <div className="content__container">
                <div className="a2ts__body table-responsive">
                  {entriesRatification && entriesRatification.length > 0 ? (
                    <Table
                      columns={a2columns()
                        .filter(
                          c => c.accessor !== 'motherEntry.jurisdiction.name'
                        )
                        .map(c => (c.isHidden ? { ...c, isHidden: false } : c))}
                      entries={entriesRatification}
                      emptyHeaderBg={'#ecefec'}
                      showFilters={true}
                      fixedHeight={false}
                      disableHover={true}
                      citeEntry={citeA2}
                      showSubjurSwitch={false}
                      disableFirstCol={true}
                      tableContainerExtraClass={'jurTableContainer'}
                      trMainClass={''}
                      t={t}
                    />
                  ) : (
                    <div>{t('Loading...')}</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  )
}
