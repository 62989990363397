var map = {
  type: "FeatureCollection",
  deltaLongitude: 0,
  features: [
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-178.8765, 71.5769],
              [-177.5322, 71.2631],
              [-177.8218, 71.0675],
              [-179.5066, 70.9235],
              [-180, 70.993],
              [-180, 71.1843],
              [-180, 71.3998],
              [-180, 71.5379],
              [-178.8765, 71.5769]
            ]
          ],
          [[[178.8614, 70.8265], [178.8911, 71.2311], [180, 71.5379], [180, 70.993], [178.8614, 70.8265]]],
          [[[60.4505, 69.935], [59.5783, 69.7385], [58.473, 70.2668], [59.0052, 70.4652], [60.4505, 69.935]]],
          [[[169.201, 69.5805], [167.789, 69.8369], [168.358, 70.0157], [169.375, 69.8826], [169.201, 69.5805]]],
          [
            [
              [50.265, 69.1857],
              [49.6262, 68.8596],
              [48.667, 68.7331],
              [48.279, 69.0403],
              [48.6315, 69.436],
              [49.225, 69.5113],
              [50.265, 69.1857]
            ]
          ],
          [
            [
              [-179.7985, 68.9404],
              [-178.8739, 68.7542],
              [-177.4075, 68.2451],
              [-175.3451, 67.678],
              [-174.4476, 67.1031],
              [-173.6797, 67.1449],
              [-172.6405, 66.9249],
              [-171.7956, 66.9318],
              [-170.192, 66.2014],
              [-170.6663, 65.6214],
              [-172.2115, 65.4252],
              [-172.2133, 65.0482],
              [-172.9239, 64.705],
              [-173.0092, 64.2975],
              [-173.7298, 64.3646],
              [-174.318, 64.6376],
              [-175.3951, 64.8025],
              [-175.8538, 65.0109],
              [-176.0933, 65.471],
              [-177.0563, 65.6137],
              [-178.3103, 65.4849],
              [-178.8794, 65.9364],
              [-179.7283, 65.8039],
              [-179.3521, 65.5167],
              [-180, 65.0672],
              [-180, 65.312],
              [-180, 65.5569],
              [-180, 65.8014],
              [-180, 66.0462],
              [-180, 66.291],
              [-180, 66.5359],
              [-180, 66.7807],
              [-180, 67.0252],
              [-180, 67.2701],
              [-180, 67.5149],
              [-180, 67.7597],
              [-180, 68.0045],
              [-180, 68.2491],
              [-180, 68.4939],
              [-180, 68.7387],
              [-180, 68.9835],
              [-179.7985, 68.9404]
            ]
          ],
          [[[163.635, 58.6033], [163.7611, 59.015], [164.6156, 58.8856], [163.635, 58.6033]]],
          [
            [
              [21.2358, 55.2641],
              [22.8311, 54.8384],
              [22.7663, 54.3568],
              [19.6045, 54.4591],
              [19.9747, 54.9212],
              [20.5202, 54.9949],
              [20.8998, 55.2867],
              [20.9579, 55.279],
              [21.2358, 55.2641]
            ]
          ],
          [
            [
              [142.761, 54.3939],
              [142.9181, 53.7942],
              [143.2235, 53.2961],
              [143.3234, 52.6136],
              [143.1556, 52.0838],
              [143.4552, 51.4715],
              [143.8161, 50.2825],
              [144.272, 49.3113],
              [143.105, 49.1988],
              [142.5741, 48.0719],
              [142.5568, 47.7381],
              [143.0056, 47.2228],
              [143.2821, 46.5592],
              [142.5781, 46.7008],
              [142.2088, 46.0891],
              [141.8302, 46.4508],
              [142.0388, 47.1402],
              [141.9642, 47.5872],
              [142.1817, 48.0133],
              [141.8661, 48.75],
              [142.1422, 49.5692],
              [142.0712, 50.5149],
              [142.2066, 51.2225],
              [141.7223, 51.7363],
              [141.8235, 53.3394],
              [142.5262, 53.4475],
              [142.761, 54.3939]
            ]
          ],
          [[[148.5995, 45.3176], [147.9137, 44.9904], [147.7694, 45.1906], [148.5995, 45.3176]]],
          [
            [
              [96.5265, 81.0755],
              [97.8316, 80.7983],
              [97.1751, 80.241],
              [94.565, 80.1261],
              [93.8725, 80.0102],
              [91.5239, 80.3584],
              [93.2626, 80.7912],
              [93.0651, 80.9885],
              [95.8008, 81.2804],
              [96.5265, 81.0755]
            ]
          ],
          [[[54.719, 81.1159], [57.694, 80.7923], [55.7124, 80.6372], [54.0665, 80.8136], [54.719, 81.1159]]],
          [[[62.168, 80.8347], [61.0511, 80.4186], [59.3044, 80.5216], [59.5921, 80.8165], [62.168, 80.8347]]],
          [
            [
              [97.6744, 80.1582],
              [100.0613, 79.7772],
              [99.4394, 78.8341],
              [97.5555, 78.8266],
              [94.6524, 79.1275],
              [93.0708, 79.4953],
              [94.9871, 80.0968],
              [97.6744, 80.1582]
            ]
          ],
          [
            [
              [102.8848, 79.254],
              [105.146, 78.8188],
              [105.3125, 78.4999],
              [103.7194, 78.2582],
              [101.2043, 78.192],
              [100.2841, 78.6792],
              [101.5905, 79.3504],
              [102.8848, 79.254]
            ]
          ],
          [
            [
              [130.6871, 42.3027],
              [130.5269, 42.5353],
              [130.5771, 42.8118],
              [131.0684, 42.9024],
              [131.262, 43.4333],
              [131.2553, 44.0716],
              [131.0822, 44.9101],
              [131.9775, 45.2439],
              [132.9359, 45.03],
              [133.1858, 45.4947],
              [133.8614, 46.2475],
              [134.1677, 47.3022],
              [134.7523, 47.7155],
              [134.5636, 48.3219],
              [134.2933, 48.3733],
              [133.4686, 48.0972],
              [133.1441, 48.1057],
              [132.5617, 47.7683],
              [131.0027, 47.6915],
              [130.712, 48.1279],
              [130.8043, 48.3414],
              [130.1962, 48.8916],
              [129.5339, 49.3235],
              [128.5267, 49.5943],
              [127.9998, 49.5685],
              [127.5027, 49.8735],
              [127.5901, 50.2091],
              [126.9247, 51.1002],
              [126.313, 52.3997],
              [125.649, 53.0422],
              [124.8122, 53.1339],
              [123.6079, 53.5465],
              [122.338, 53.485],
              [120.704, 53.1718],
              [120.0945, 52.7872],
              [120.6561, 52.5666],
              [120.7497, 52.0965],
              [120.067, 51.6007],
              [119.1921, 50.3797],
              [119.26, 50.0664],
              [118.4517, 49.8444],
              [117.8733, 49.5135],
              [116.6832, 49.8238],
              [116.1346, 50.0109],
              [115.2743, 49.9489],
              [114.743, 50.2337],
              [114.2969, 50.2743],
              [113.1641, 49.7971],
              [113.0557, 49.6162],
              [111.3366, 49.3559],
              [110.8279, 49.1661],
              [110.1997, 49.1704],
              [109.2365, 49.3348],
              [108.6137, 49.3228],
              [108.034, 49.594],
              [107.9479, 49.9247],
              [107.2332, 49.9893],
              [106.7112, 50.3125],
              [106.218, 50.3045],
              [105.3835, 50.4736],
              [103.6328, 50.1387],
              [102.6833, 50.3871],
              [102.2883, 50.585],
              [102.1116, 51.3534],
              [101.3814, 51.4526],
              [100.4687, 51.7261],
              [98.8485, 52.0701],
              [97.947, 51.3483],
              [97.8254, 50.9852],
              [98.2795, 50.5333],
              [98.1032, 50.0779],
              [97.2088, 49.7308],
              [96.986, 49.8828],
              [95.8518, 50.0129],
              [95.5229, 49.9112],
              [94.6147, 50.0237],
              [94.2511, 50.5564],
              [91.8044, 50.6936],
              [89.644, 49.903],
              [89.6542, 49.7175],
              [88.8317, 49.4485],
              [88.1925, 49.4518],
              [87.8143, 49.1624],
              [87.3229, 49.0858],
              [86.6144, 49.6098],
              [86.1807, 49.4993],
              [85.21, 49.6648],
              [84.9893, 50.0615],
              [84.2578, 50.2883],
              [83.9453, 50.7746],
              [83.3571, 50.9945],
              [82.4938, 50.7275],
              [81.4657, 50.7397],
              [80.8771, 51.2815],
              [79.9862, 50.7746],
              [79.4687, 51.493],
              [78.4757, 52.6385],
              [77.7042, 53.3791],
              [76.5758, 53.9424],
              [76.2664, 54.312],
              [74.4518, 53.6472],
              [73.2857, 53.5984],
              [73.3993, 53.8115],
              [72.9141, 54.1073],
              [71.0933, 54.2123],
              [71.1856, 54.5994],
              [70.7382, 55.3052],
              [70.1824, 55.1625],
              [68.9773, 55.3895],
              [68.2062, 55.1609],
              [68.0739, 54.9596],
              [66.2225, 54.6673],
              [65.4767, 54.6233],
              [65.1576, 54.3643],
              [64.4611, 54.3841],
              [63.0739, 54.1053],
              [61.9287, 53.9464],
              [61.3339, 54.0492],
              [60.9797, 53.6217],
              [61.1994, 53.2872],
              [62.0148, 53.1079],
              [61.974, 52.9437],
              [61.0476, 52.9725],
              [60.8021, 52.7448],
              [60.9375, 52.2805],
              [60.0657, 51.9764],
              [60.4647, 51.6513],
              [61.3632, 51.442],
              [61.5851, 51.2298],
              [61.3894, 50.8609],
              [60.6379, 50.6638],
              [59.9552, 50.7992],
              [59.8122, 50.5819],
              [58.8836, 50.6945],
              [58.3594, 51.0638],
              [57.8387, 51.0917],
              [57.4423, 50.8889],
              [57.0117, 51.0651],
              [56.4915, 51.0196],
              [55.7977, 50.6021],
              [55.5424, 50.6017],
              [54.727, 50.9981],
              [54.4216, 50.7804],
              [54.0416, 51.1153],
              [53.3381, 51.4824],
              [52.571, 51.4817],
              [52.3309, 51.6812],
              [51.6091, 51.4839],
              [50.7941, 51.7292],
              [50.2468, 51.2895],
              [49.498, 51.0835],
              [48.8086, 50.6012],
              [48.8432, 50.0131],
              [48.4344, 49.8284],
              [47.706, 50.378],
              [47.3264, 50.2734],
              [46.8022, 49.367],
              [47.0144, 49.0984],
              [46.7028, 48.8055],
              [46.661, 48.4124],
              [47.0042, 48.2846],
              [47.0934, 47.9477],
              [48.1099, 47.7452],
              [48.9595, 46.7744],
              [48.6102, 46.5663],
              [49.2321, 46.3372],
              [48.1592, 45.7371],
              [47.5244, 45.6017],
              [46.7529, 44.4205],
              [47.2301, 44.1923],
              [47.5089, 43.5096],
              [47.4632, 43.0351],
              [48.5729, 41.8446],
              [47.8609, 41.2129],
              [47.2612, 41.315],
              [46.7494, 41.8126],
              [46.4298, 41.8907],
              [45.6388, 42.205],
              [45.7275, 42.4749],
              [45.1602, 42.6751],
              [44.5059, 42.7488],
              [43.9573, 42.5664],
              [43.7824, 42.747],
              [42.4192, 43.2242],
              [41.5807, 43.2193],
              [40.6481, 43.534],
              [39.9782, 43.42],
              [38.7171, 44.2882],
              [38.1814, 44.4196],
              [37.8516, 44.6988],
              [37.4112, 44.7352],
              [36.9411, 45.2896],
              [37.6474, 45.3771],
              [37.9332, 46.0016],
              [38.1836, 46.0948],
              [37.9679, 46.6182],
              [39.2933, 47.1056],
              [38.2142, 47.0914],
              [38.2875, 47.5592],
              [38.9005, 47.8553],
              [39.7359, 47.8447],
              [39.9609, 48.238],
              [39.6866, 49.0079],
              [40.0701, 49.2003],
              [39.7807, 49.5721],
              [39.1748, 49.8559],
              [38.2586, 50.0524],
              [38.0469, 49.9201],
              [37.4228, 50.4115],
              [36.3059, 50.2805],
              [35.4115, 50.5398],
              [35.3121, 51.0438],
              [34.2809, 51.3117],
              [34.3976, 51.7804],
              [33.7353, 52.3448],
              [32.508, 52.3084],
              [31.7636, 52.1012],
              [31.5771, 52.3122],
              [31.4178, 53.196],
              [32.1422, 53.0913],
              [32.7042, 53.3363],
              [31.792, 54.0558],
              [31.4036, 54.1959],
              [31.1213, 54.6484],
              [30.9069, 55.5699],
              [30.2335, 55.8452],
              [29.4824, 55.6845],
              [29.375, 55.9386],
              [28.7948, 55.9426],
              [28.1481, 56.143],
              [27.8063, 56.867],
              [27.797, 57.3169],
              [27.3517, 57.5282],
              [27.753, 57.8411],
              [27.4272, 58.7331],
              [28.0127, 59.4842],
              [28.014, 59.7248],
              [28.9471, 59.8289],
              [29.0692, 60.1915],
              [28.6506, 60.611],
              [27.7978, 60.5362],
              [29.6902, 61.5461],
              [31.2855, 62.5679],
              [31.5341, 62.8855],
              [31.1807, 63.2082],
              [30.0555, 63.689],
              [30.5038, 64.0205],
              [30.1083, 64.3661],
              [30.0728, 64.7649],
              [29.604, 64.9685],
              [29.9032, 66.0911],
              [29.0869, 66.9709],
              [29.9414, 67.5475],
              [29.3439, 68.062],
              [28.4708, 68.4883],
              [28.9657, 69.0219],
              [30.8696, 69.7834],
              [31.9846, 69.9536],
              [32.9417, 69.7519],
              [32.3775, 69.4791],
              [33.6843, 69.3102],
              [35.858, 69.1917],
              [40.3809, 67.8318],
              [40.9663, 67.7135],
              [41.1887, 66.8262],
              [40.5216, 66.4466],
              [39.2889, 66.1321],
              [38.3975, 66.0644],
              [35.5136, 66.3958],
              [34.8247, 66.6111],
              [32.4636, 66.9163],
              [33.5667, 66.321],
              [34.7865, 65.8645],
              [34.4065, 65.3957],
              [34.8034, 64.986],
              [34.8695, 64.5601],
              [35.6472, 64.3783],
              [36.365, 64.0028],
              [37.4423, 63.8135],
              [38.0708, 64.0259],
              [37.9537, 64.3202],
              [37.1835, 64.4085],
              [36.5283, 64.8473],
              [36.883, 65.1724],
              [38.0096, 64.8788],
              [39.7581, 64.577],
              [40.281, 64.998],
              [39.8167, 65.5979],
              [41.4759, 66.1235],
              [42.2106, 66.5197],
              [44.0971, 66.2351],
              [44.4886, 66.6719],
              [44.292, 67.0996],
              [43.7824, 67.2545],
              [44.2254, 67.9956],
              [44.0483, 68.5489],
              [45.8922, 68.4797],
              [46.6903, 67.8489],
              [45.5287, 67.7577],
              [44.9396, 67.4776],
              [45.9859, 66.853],
              [46.6908, 66.8255],
              [47.7091, 67.045],
              [47.8746, 67.5841],
              [48.8778, 67.7315],
              [50.839, 68.3498],
              [52.5502, 68.5924],
              [53.8019, 68.9959],
              [53.9679, 68.2273],
              [54.5614, 68.273],
              [56.0436, 68.649],
              [57.1267, 68.554],
              [58.9191, 69.0039],
              [59.8975, 68.422],
              [60.9335, 68.9868],
              [60.1704, 69.5909],
              [61.0161, 69.8515],
              [63.3616, 69.6753],
              [68.5041, 68.3485],
              [69.1406, 68.9506],
              [68.5427, 68.9671],
              [68.006, 69.48],
              [67.0645, 69.6937],
              [66.9265, 70.0142],
              [67.2847, 70.7386],
              [66.6664, 70.9006],
              [66.9176, 71.2824],
              [68.2693, 71.6828],
              [69.039, 72.67],
              [69.6449, 72.8975],
              [71.5003, 72.9137],
              [72.8121, 72.6915],
              [72.6243, 72.0794],
              [71.9118, 71.5478],
              [72.7317, 70.823],
              [72.5297, 70.1724],
              [72.5275, 69.0805],
              [73.548, 68.5744],
              [73.1521, 67.8651],
              [72.5945, 67.587],
              [71.5514, 66.7605],
              [70.9393, 66.5481],
              [69.877, 66.8455],
              [69.1943, 66.5787],
              [70.3396, 66.3423],
              [71.9172, 66.2467],
              [72.4174, 66.5607],
              [73.7922, 66.9953],
              [74.0745, 67.4141],
              [74.7696, 67.7663],
              [74.3914, 68.4206],
              [74.5796, 68.7511],
              [76.4591, 68.9784],
              [77.2385, 68.4697],
              [77.6509, 68.9029],
              [76.001, 69.2349],
              [74.8149, 69.0907],
              [73.7757, 69.1983],
              [73.56, 69.7072],
              [74.3111, 70.6536],
              [73.0864, 71.4449],
              [73.6719, 71.845],
              [75.7413, 72.2962],
              [76.0325, 71.9103],
              [78.4828, 72.395],
              [79.422, 72.3808],
              [80.7626, 72.0892],
              [81.511, 71.7463],
              [82.9861, 71.7487],
              [82.1835, 72.2376],
              [80.827, 72.4882],
              [80.5096, 73.0861],
              [80.5833, 73.5684],
              [83.5449, 73.6665],
              [86.5913, 73.8943],
              [86.6513, 74.6824],
              [87.6713, 75.1296],
              [90.1851, 75.591],
              [93.5498, 75.854],
              [94.5756, 76.1517],
              [96.879, 75.9311],
              [100.3223, 76.479],
              [100.9899, 76.9904],
              [103.1312, 77.6265],
              [104.0146, 77.7304],
              [105.8944, 77.4889],
              [104.9121, 77.1748],
              [107.4299, 76.9265],
              [108.0278, 76.7185],
              [111.1151, 76.723],
              [112.6194, 76.3836],
              [113.8712, 75.856],
              [113.6137, 75.293],
              [111.8683, 74.7401],
              [109.8402, 74.3219],
              [108.1996, 73.6941],
              [107.2709, 73.621],
              [106.4782, 73.1394],
              [107.7503, 73.1731],
              [109.8553, 73.4726],
              [110.2614, 74.0174],
              [112.1471, 73.7089],
              [114.0607, 73.5846],
              [115.3378, 73.7025],
              [118.4504, 73.5897],
              [118.4304, 73.2466],
              [119.7505, 72.9792],
              [123.1605, 72.955],
              [123.6222, 73.1933],
              [123.3052, 73.5329],
              [124.3883, 73.7549],
              [125.617, 73.5207],
              [127.7406, 73.4817],
              [128.8885, 73.1902],
              [129.4118, 72.3156],
              [128.3589, 72.0883],
              [129.7621, 71.1195],
              [131.1572, 70.7422],
              [131.562, 70.9011],
              [132.5621, 71.8952],
              [134.1029, 71.3789],
              [135.8847, 71.6306],
              [137.3153, 71.3594],
              [138.1183, 71.5663],
              [139.695, 71.7003],
              [139.6014, 72.496],
              [142.0614, 72.7208],
              [145.4856, 72.5422],
              [146.594, 72.3025],
              [148.402, 72.312],
              [149.5015, 72.1644],
              [150.5997, 71.5201],
              [151.5825, 71.2871],
              [152.5089, 70.8345],
              [153.7944, 70.88],
              [155.8953, 71.0955],
              [158.0371, 71.0394],
              [159.3506, 70.7908],
              [160.0062, 70.3096],
              [159.8326, 69.7849],
              [161.5367, 69.3796],
              [162.3757, 69.6491],
              [164.1597, 69.7192],
              [164.5135, 69.6091],
              [166.8204, 69.4995],
              [167.6283, 69.7403],
              [168.303, 69.2716],
              [169.3106, 69.0796],
              [169.6098, 68.7862],
              [170.5375, 68.8254],
              [170.9952, 69.0454],
              [170.5824, 69.5834],
              [170.4869, 70.1076],
              [172.5595, 69.9682],
              [173.2772, 69.8238],
              [176.1075, 69.8604],
              [176.9243, 69.646],
              [178.8485, 69.3872],
              [180, 68.9835],
              [180, 65.0672],
              [178.5196, 64.6029],
              [177.4671, 64.7368],
              [177.433, 64.4445],
              [178.6501, 63.9653],
              [178.7065, 63.5216],
              [179.3324, 63.1902],
              [179.5703, 62.6875],
              [179.1206, 62.3204],
              [177.3513, 62.5874],
              [175.2681, 62.1025],
              [174.5144, 61.8237],
              [173.6235, 61.7161],
              [172.8564, 61.4693],
              [172.3926, 61.0618],
              [170.6081, 60.435],
              [169.9827, 60.067],
              [169.2267, 60.5959],
              [168.1374, 60.5739],
              [166.9642, 60.3069],
              [166.3521, 60.4847],
              [165.0843, 60.0985],
              [163.7802, 60.041],
              [163.41, 59.8349],
              [163.2728, 59.3026],
              [162.1418, 58.4475],
              [161.9602, 58.0768],
              [162.4671, 57.7661],
              [163.2138, 57.6869],
              [162.7792, 57.3577],
              [162.8027, 56.8115],
              [163.2564, 56.6881],
              [163.3354, 56.2325],
              [162.5284, 56.2606],
              [162.085, 56.0897],
              [161.7294, 55.358],
              [162.1058, 54.7521],
              [161.7258, 54.533],
              [160.7728, 54.5414],
              [160.0746, 54.1892],
              [159.8437, 53.7837],
              [159.8975, 53.3807],
              [158.6088, 52.8735],
              [158.4637, 52.3049],
              [158.1037, 51.8095],
              [156.7476, 50.9692],
              [156.5003, 51.475],
              [156.3645, 52.5093],
              [156.0986, 53.0065],
              [155.6201, 54.8646],
              [155.5549, 55.3484],
              [155.9828, 56.6952],
              [156.7285, 57.1522],
              [157.4503, 57.7994],
              [158.2102, 58.0253],
              [159.0367, 58.4239],
              [159.8473, 59.1271],
              [161.7534, 60.1522],
              [162.0037, 60.4201],
              [162.9732, 60.7828],
              [163.71, 60.9168],
              [164.0052, 61.3439],
              [164.2556, 62.6966],
              [163.3318, 62.5508],
              [163.0091, 61.7915],
              [162.3926, 61.6621],
              [160.7666, 60.7533],
              [159.8832, 61.2917],
              [160.3174, 61.7933],
              [159.5525, 61.7194],
              [159.0767, 61.9223],
              [158.07, 61.7536],
              [157.4694, 61.7989],
              [156.6801, 61.4806],
              [156.63, 61.2724],
              [155.8532, 60.7772],
              [154.9707, 60.3766],
              [154.2929, 59.8333],
              [154.5827, 59.5401],
              [155.1669, 59.3601],
              [154.011, 59.0756],
              [153.3611, 59.2147],
              [152.8822, 58.9391],
              [151.99, 59.1601],
              [151.4857, 59.5241],
              [150.4572, 59.5907],
              [149.6427, 59.7705],
              [149.205, 59.4882],
              [147.8746, 59.3881],
              [147.5146, 59.2687],
              [146.4444, 59.4305],
              [145.9317, 59.1983],
              [145.5544, 59.4136],
              [143.192, 59.3701],
              [142.0255, 58.9997],
              [141.7547, 58.7453],
              [140.6849, 58.2122],
              [140.447, 57.8136],
              [140.0022, 57.6875],
              [138.6621, 56.9655],
              [137.6913, 56.1395],
              [136.175, 55.3522],
              [135.2623, 54.9434],
              [135.8514, 54.5838],
              [136.7973, 54.6209],
              [136.6828, 53.9313],
              [137.1555, 53.8217],
              [137.3282, 53.5389],
              [138.2923, 53.5924],
              [138.6608, 53.7449],
              [138.6958, 54.3199],
              [139.7075, 54.2771],
              [140.3471, 53.8126],
              [141.4022, 53.184],
              [141.1696, 52.3684],
              [141.4853, 52.1786],
              [140.6876, 51.2322],
              [140.4763, 50.546],
              [140.5171, 49.596],
              [140.1704, 48.5238],
              [139.3728, 47.8873],
              [139.0012, 47.3835],
              [138.5866, 47.0572],
              [138.3367, 46.5432],
              [137.6855, 45.8183],
              [136.8035, 45.1711],
              [136.1421, 44.4893],
              [135.8745, 44.3734],
              [135.1309, 43.5256],
              [133.7092, 42.83],
              [133.1601, 42.6969],
              [132.4813, 42.9099],
              [132.3344, 43.2388],
              [131.7946, 43.2553],
              [131.1586, 42.6258],
              [130.6871, 42.3027]
            ]
          ],
          [
            [
              [67.7654, 76.2376],
              [66.2824, 75.9837],
              [61.3561, 75.3149],
              [60.2224, 74.7965],
              [58.5347, 74.4988],
              [58.4415, 74.1288],
              [56.9638, 73.3665],
              [55.0067, 73.4539],
              [53.7629, 73.7662],
              [54.6427, 73.9595],
              [57.0876, 75.3837],
              [58.8814, 75.8547],
              [62.9714, 76.2367],
              [65.8629, 76.6133],
              [67.5351, 77.0077],
              [68.4859, 76.9338],
              [68.9, 76.5729],
              [67.7654, 76.2376]
            ]
          ],
          [
            [
              [140.0488, 75.829],
              [140.9442, 75.7004],
              [142.4605, 75.9035],
              [143.6856, 75.8636],
              [145.2552, 75.5857],
              [144.0199, 75.0446],
              [141.9873, 74.9913],
              [139.0993, 74.6566],
              [138.0921, 74.7974],
              [137.0064, 75.235],
              [137.5013, 75.9097],
              [138.8139, 76.1996],
              [140.0488, 75.829]
            ]
          ],
          [
            [
              [146.7951, 75.3709],
              [150.1039, 75.2193],
              [150.6463, 74.9445],
              [149.5969, 74.7725],
              [148.0926, 74.8258],
              [146.1483, 75.1984],
              [146.7951, 75.3709]
            ]
          ],
          [[[113.3873, 74.4005], [112.7823, 74.0951], [111.6428, 74.2729], [112.0845, 74.549], [113.3873, 74.4005]]],
          [
            [
              [142.1848, 73.8958],
              [143.3438, 73.5687],
              [143.1934, 73.2206],
              [140.6627, 73.4519],
              [141.0849, 73.8658],
              [142.1848, 73.8958]
            ]
          ],
          [
            [
              [55.32, 73.3083],
              [56.4293, 73.2011],
              [55.356, 72.4651],
              [55.2978, 71.9354],
              [57.1458, 70.589],
              [54.6009, 70.68],
              [53.7225, 70.8145],
              [53.3638, 71.5416],
              [51.8124, 71.4912],
              [51.5825, 72.0712],
              [52.6221, 72.3009],
              [53.7531, 73.2932],
              [55.32, 73.3083]
            ]
          ]
        ]
      },
      properties: { name: "Russia", id: "RU" },
      id: "RU"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-5.3345, 36.1624], [-5.3383, 36.1123], [-5.3563, 36.1263], [-5.3552, 36.1452], [-5.3345, 36.1624]]
        ]
      },
      properties: { name: "Gibraltar", id: "GI", Continent: "Europe" },
      id: "GI"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[4.2262, 51.3865], [3.9023, 51.2075], [3.35, 51.3776], [4.2262, 51.3865]]],
          [
            [
              [4.2262, 51.3865],
              [3.5872, 51.4541],
              [4.4829, 52.3092],
              [4.769, 52.9411],
              [5.0256, 52.95],
              [5.1205, 52.388],
              [5.5613, 52.5948],
              [5.3754, 53.0956],
              [6.0622, 53.4072],
              [6.8163, 53.4412],
              [7.1974, 53.2824],
              [7.0352, 52.3802],
              [6.7416, 51.9111],
              [5.9484, 51.7625],
              [6.1932, 51.4887],
              [5.8948, 50.9843],
              [5.9937, 50.7504],
              [5.7973, 50.7546],
              [5.694, 50.7748],
              [5.8271, 51.1257],
              [5.5085, 51.2751],
              [4.5035, 51.4746],
              [4.2262, 51.3865]
            ]
          ]
        ]
      },
      properties: { name: "Netherlands", id: "NL", Continent: "Europe" },
      id: "NL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[12.4392, 41.8981], [12.4308, 41.8972], [12.4308, 41.9056], [12.4383, 41.9065], [12.4392, 41.8981]]
        ]
      },
      properties: { name: "Vatican City", id: "VA", Continent: "Europe" },
      id: "VA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.2144, 47.0914],
            [37.5433, 47.0744],
            [37.3399, 46.9168],
            [36.2795, 46.6585],
            [35.8268, 46.6244],
            [34.8495, 46.1899],
            [35.0231, 45.7012],
            [35.5579, 45.3109],
            [36.1705, 45.453],
            [36.5748, 45.3935],
            [36.3933, 45.0654],
            [35.8699, 45.0055],
            [35.4727, 45.0982],
            [35.0877, 44.8027],
            [34.7168, 44.8071],
            [33.9099, 44.3877],
            [33.4507, 44.5537],
            [33.5553, 45.0978],
            [32.9186, 45.3483],
            [32.5081, 45.4036],
            [33.6648, 45.947],
            [33.2024, 46.1758],
            [32.4769, 46.0837],
            [32.0357, 46.2608],
            [31.9746, 46.7088],
            [30.7964, 46.5522],
            [30.5112, 46.1054],
            [30.2194, 45.8666],
            [29.602, 45.6825],
            [29.7061, 45.2599],
            [29.2236, 45.4027],
            [28.7604, 45.2342],
            [28.2125, 45.4502],
            [28.9476, 46.0501],
            [28.9274, 46.4242],
            [29.1462, 46.5267],
            [29.838, 46.3504],
            [29.878, 46.8291],
            [29.5721, 46.9639],
            [29.5493, 47.2467],
            [29.1599, 47.4558],
            [29.1947, 47.8823],
            [28.7741, 48.1194],
            [28.3271, 48.1617],
            [27.5494, 48.4776],
            [26.619, 48.2596],
            [26.1624, 47.9925],
            [25.4641, 47.9107],
            [24.8933, 47.7178],
            [24.5786, 47.9311],
            [24.1778, 47.9059],
            [23.2027, 48.0843],
            [22.8766, 47.9472],
            [22.1322, 48.4053],
            [22.5387, 49.0726],
            [22.6494, 49.5392],
            [23.7121, 50.3774],
            [23.9731, 50.4101],
            [24.1058, 50.8449],
            [23.6053, 51.5179],
            [24.3615, 51.8676],
            [25.9251, 51.9137],
            [27.1424, 51.752],
            [27.2702, 51.6135],
            [28.5992, 51.5427],
            [29.1357, 51.6173],
            [29.3466, 51.3824],
            [30.5332, 51.5964],
            [30.9805, 52.0462],
            [31.7636, 52.1011],
            [32.2831, 52.1141],
            [32.5081, 52.3085],
            [33.7355, 52.3448],
            [34.3978, 51.7806],
            [34.1209, 51.6793],
            [34.2808, 51.3116],
            [35.3122, 51.0438],
            [35.4116, 50.5398],
            [36.3059, 50.2804],
            [37.4229, 50.4114],
            [38.047, 49.9201],
            [38.2588, 50.0524],
            [38.9184, 49.8248],
            [39.1746, 49.856],
            [39.7807, 49.572],
            [40.0702, 49.2003],
            [39.6866, 49.0078],
            [39.9608, 48.2378],
            [39.7358, 47.8445],
            [38.9004, 47.8554],
            [38.2873, 47.5592],
            [38.2144, 47.0914]
          ]
        ]
      },
      properties: { name: "Ukraine", id: "UA", Continent: "Europe" },
      id: "UA"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [41.5103, 41.5175],
              [42.4661, 41.4397],
              [42.7878, 41.5637],
              [43.439, 41.1071],
              [43.7225, 40.7196],
              [43.5691, 40.4821],
              [43.792, 40.0704],
              [44.2894, 40.0405],
              [44.7684, 39.7035],
              [44.8172, 39.6503],
              [44.3891, 39.4223],
              [44.0231, 39.3774],
              [44.4309, 38.357],
              [44.2112, 37.9084],
              [44.5614, 37.7449],
              [44.5737, 37.4355],
              [44.7939, 37.2904],
              [44.2819, 36.978],
              [44.2085, 37.203],
              [43.516, 37.2448],
              [42.7746, 37.3717],
              [42.456, 37.1287],
              [42.2683, 37.2764],
              [41.5155, 37.0891],
              [40.7057, 37.0975],
              [40.0166, 36.826],
              [39.3569, 36.6819],
              [38.7668, 36.6933],
              [38.1915, 36.9015],
              [37.4366, 36.6436],
              [36.6582, 36.8022],
              [36.5375, 36.4576],
              [36.1274, 35.8314],
              [35.8111, 36.3096],
              [36.1881, 36.6589],
              [36.0488, 36.9108],
              [35.5376, 36.597],
              [34.6012, 36.7846],
              [34.0233, 36.3408],
              [33.6946, 36.1817],
              [32.7947, 36.0358],
              [32.3776, 36.1834],
              [32.0221, 36.535],
              [31.3523, 36.801],
              [30.6439, 36.8655],
              [30.484, 36.3105],
              [29.6895, 36.1566],
              [29.1432, 36.397],
              [29.0382, 36.6937],
              [28.4835, 36.8035],
              [28.1338, 37.0299],
              [27.349, 37.0193],
              [27.5198, 37.2491],
              [27.2192, 37.3894],
              [27.0778, 37.6877],
              [27.2324, 37.9786],
              [26.6827, 38.1983],
              [26.8612, 38.3728],
              [26.7636, 38.7099],
              [27.0136, 38.887],
              [26.6818, 39.2922],
              [26.8268, 39.5628],
              [26.1132, 39.4676],
              [26.1813, 39.99],
              [26.7376, 40.4001],
              [27.2848, 40.4559],
              [27.4754, 40.3196],
              [28.2893, 40.4031],
              [28.7877, 40.534],
              [29.3647, 40.8096],
              [29.0456, 41.0074],
              [29.322, 41.2275],
              [29.9193, 41.1506],
              [30.3451, 41.1967],
              [30.8101, 41.0851],
              [31.2548, 41.1076],
              [31.4582, 41.3198],
              [32.3064, 41.7298],
              [33.3817, 42.0176],
              [34.7506, 41.957],
              [35.0068, 42.0634],
              [35.2978, 41.7289],
              [36.0514, 41.6824],
              [36.1793, 41.4266],
              [36.5097, 41.2627],
              [36.7778, 41.3633],
              [37.0661, 41.1844],
              [38.3809, 40.9243],
              [39.4264, 41.1062],
              [40.2653, 40.9612],
              [40.9596, 41.2117],
              [41.5103, 41.5175]
            ]
          ],
          [
            [
              [28.0142, 41.9689],
              [28.3465, 41.4666],
              [29.0571, 41.2297],
              [28.7802, 40.974],
              [28.172, 41.0808],
              [27.4997, 40.9731],
              [27.2579, 40.6875],
              [26.1052, 40.6114],
              [26.0394, 40.7269],
              [26.3311, 40.9546],
              [26.3307, 41.2385],
              [26.6247, 41.4016],
              [26.3211, 41.7166],
              [26.6155, 41.965],
              [27.2948, 42.0796],
              [27.5348, 41.921],
              [28.0142, 41.9689]
            ]
          ]
        ]
      },
      properties: { name: "Turkey", id: "TR", Continent: "Europe" },
      id: "TR"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [24.1559, 65.8052],
              [23.1025, 65.7353],
              [21.5657, 65.4082],
              [21.5741, 65.1258],
              [21.1382, 64.8086],
              [21.4651, 64.3797],
              [20.7624, 63.8679],
              [19.0345, 63.2377],
              [18.4632, 62.8959],
              [17.8954, 62.8304],
              [17.4173, 61.7407],
              [17.1308, 61.5758],
              [17.2512, 60.7009],
              [17.956, 60.5898],
              [18.5572, 60.2536],
              [18.9707, 59.7572],
              [17.8761, 59.2708],
              [18.2853, 59.1093],
              [17.4568, 58.8586],
              [16.6521, 58.4343],
              [16.77, 58.2142],
              [16.5555, 57.8123],
              [16.5278, 57.0681],
              [15.9965, 56.2228],
              [14.7819, 56.1619],
              [14.2155, 55.8325],
              [14.3416, 55.5278],
              [13.3211, 55.3465],
              [12.8856, 55.4115],
              [12.9779, 55.6938],
              [12.5925, 56.1377],
              [12.8834, 56.6178],
              [12.4215, 56.9064],
              [11.4495, 58.1183],
              [11.1472, 58.9887],
              [11.3884, 59.0365],
              [11.7985, 59.2899],
              [11.6806, 59.5923],
              [12.4862, 60.1068],
              [12.5886, 60.4507],
              [12.2941, 61.0027],
              [12.8808, 61.3522],
              [12.1557, 61.7208],
              [12.3033, 62.2856],
              [12.1148, 62.592],
              [12.2181, 63.0006],
              [11.9997, 63.2916],
              [12.6624, 63.9404],
              [13.2996, 64.0747],
              [13.9606, 64.014],
              [14.0774, 64.4641],
              [13.6503, 64.5816],
              [14.4799, 65.3014],
              [14.5432, 66.1294],
              [15.4837, 66.306],
              [15.4231, 66.4899],
              [16.4034, 67.0551],
              [16.1275, 67.4259],
              [17.3246, 68.1038],
              [17.9169, 67.9649],
              [18.3784, 68.5624],
              [19.9696, 68.3565],
              [20.6222, 69.037],
              [21.9976, 68.5207],
              [22.7821, 68.391],
              [23.639, 67.9544],
              [23.4682, 67.4501],
              [23.9884, 66.8107],
              [23.701, 66.4809],
              [24.1559, 65.8052]
            ]
          ],
          [
            [
              [19.0766, 57.836],
              [18.8139, 57.7061],
              [18.7, 57.2427],
              [18.2853, 57.0832],
              [18.1366, 57.5565],
              [18.5374, 57.8305],
              [19.0766, 57.836]
            ]
          ]
        ]
      },
      properties: { name: "Sweden", id: "SE", Continent: "Europe" },
      id: "SE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.5164, 46.4996],
            [15.6362, 46.2006],
            [15.6248, 45.8338],
            [15.2771, 45.7326],
            [15.3395, 45.4671],
            [14.7335, 45.5084],
            [13.8788, 45.4287],
            [13.5778, 45.517],
            [13.7197, 45.5876],
            [13.3994, 46.3177],
            [13.6999, 46.5201],
            [14.5037, 46.417],
            [15.0007, 46.6262],
            [15.9574, 46.6776],
            [16.0928, 46.8633],
            [16.5164, 46.4996]
          ]
        ]
      },
      properties: { name: "Slovenia", id: "SI", Continent: "Europe" },
      id: "SI"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.5387, 49.0726],
            [22.1322, 48.4053],
            [21.767, 48.3383],
            [21.4515, 48.5523],
            [20.4899, 48.5268],
            [20.3339, 48.2956],
            [19.8989, 48.1313],
            [18.7919, 48.0003],
            [18.7242, 47.787],
            [17.7618, 47.7701],
            [17.1474, 48.0059],
            [16.8627, 48.4414],
            [16.9532, 48.5989],
            [17.1884, 48.861],
            [17.7583, 48.8881],
            [18.1608, 49.2575],
            [18.8323, 49.5107],
            [19.4414, 49.5975],
            [19.8022, 49.1924],
            [20.616, 49.3917],
            [21.6396, 49.4121],
            [22.5387, 49.0726]
          ]
        ]
      },
      properties: { name: "Slovakia", id: "SK", Continent: "Europe" },
      id: "SK"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.2421, 46.1086],
            [20.7752, 45.7499],
            [20.7743, 45.4845],
            [21.4919, 45.1224],
            [21.3846, 44.8701],
            [22.093, 44.5423],
            [22.5022, 44.4895],
            [22.7052, 44.2377],
            [22.4209, 44.0075],
            [22.5544, 43.4547],
            [22.9768, 43.1879],
            [22.4666, 42.8425],
            [22.5237, 42.4412],
            [22.344, 42.3139],
            [21.5626, 42.2474],
            [21.753, 42.6698],
            [21.3908, 42.7515],
            [21.0569, 43.0917],
            [20.624, 43.0341],
            [20.3445, 42.828],
            [19.5517, 43.212],
            [19.1944, 43.5333],
            [19.4879, 43.7034],
            [19.1518, 44.3023],
            [19.357, 44.8587],
            [19.0072, 44.8693],
            [19.0551, 45.5272],
            [18.9052, 45.9314],
            [19.5307, 46.1553],
            [20.2421, 46.1086]
          ]
        ]
      },
      properties: { name: "Serbia", id: "RS", Continent: "Europe" },
      id: "RS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[12.4853, 43.9016], [12.3969, 43.9345], [12.4409, 43.9824], [12.5148, 43.953], [12.4853, 43.9016]]
        ]
      },
      properties: { name: "San Marino", id: "SM", Continent: "Europe" },
      id: "SM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.2125, 45.4502],
            [28.7604, 45.2342],
            [29.2236, 45.4027],
            [29.7061, 45.2599],
            [29.5576, 44.8436],
            [29.0483, 44.7574],
            [28.6453, 44.2957],
            [28.5855, 43.7421],
            [28.0499, 43.8225],
            [27.8846, 43.9873],
            [27.0865, 44.1675],
            [26.2161, 44.007],
            [25.497, 43.6709],
            [23.2242, 43.8739],
            [22.7052, 44.2377],
            [22.5022, 44.4895],
            [22.093, 44.5423],
            [21.3846, 44.8701],
            [21.4919, 45.1224],
            [20.7743, 45.4845],
            [20.7752, 45.7499],
            [20.2421, 46.1086],
            [21.1518, 46.3043],
            [22.2904, 47.7279],
            [22.8766, 47.9472],
            [23.2027, 48.0843],
            [24.1778, 47.9059],
            [24.5786, 47.9311],
            [24.8933, 47.7178],
            [25.4641, 47.9107],
            [26.1624, 47.9925],
            [26.619, 48.2596],
            [26.9806, 48.1551],
            [27.3367, 47.6396],
            [28.0718, 46.9787],
            [28.2445, 46.4513],
            [28.0995, 45.9727],
            [28.2125, 45.4502]
          ]
        ]
      },
      properties: { name: "Romania", id: "RO", Continent: "Europe" },
      id: "RO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.4061, 37.1793],
            [-7.8342, 37.0057],
            [-8.5977, 37.1212],
            [-8.8141, 37.4311],
            [-8.9148, 38.5121],
            [-9.4742, 38.7305],
            [-9.3529, 39.2483],
            [-8.8378, 40.1157],
            [-8.6557, 41.0298],
            [-8.8108, 41.6521],
            [-8.777, 41.9407],
            [-8.2132, 42.1337],
            [-7.9207, 41.8837],
            [-7.4037, 41.8336],
            [-6.6182, 41.942],
            [-6.5422, 41.6722],
            [-6.2126, 41.5321],
            [-6.9284, 41.0091],
            [-6.8102, 40.3429],
            [-7.0146, 40.2084],
            [-7.0368, 39.714],
            [-7.4452, 39.5365],
            [-7.0462, 38.9067],
            [-7.3431, 38.4572],
            [-7.1855, 38.0063],
            [-7.5037, 37.5853],
            [-7.4061, 37.1793]
          ]
        ]
      },
      properties: { name: "Portugal", id: "PT", Continent: "Europe" },
      id: "PT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.6045, 54.459],
            [20.2082, 54.4208],
            [22.7663, 54.3568],
            [23.4536, 54.1435],
            [23.4844, 53.9398],
            [23.8874, 53.0274],
            [23.8447, 52.6642],
            [23.197, 52.2569],
            [23.5982, 52.1031],
            [23.6053, 51.5179],
            [24.1058, 50.8449],
            [23.9731, 50.4101],
            [23.7121, 50.3774],
            [22.6494, 49.5392],
            [22.5387, 49.0726],
            [21.6396, 49.4121],
            [20.616, 49.3917],
            [19.8022, 49.1924],
            [19.4414, 49.5975],
            [18.8323, 49.5107],
            [18.562, 49.8792],
            [17.8747, 49.9721],
            [17.1519, 50.3784],
            [16.6794, 50.0974],
            [16.007, 50.6116],
            [15.3541, 50.812],
            [14.8096, 50.859],
            [15.0166, 51.2528],
            [14.6017, 51.8324],
            [14.7529, 52.0817],
            [14.6197, 52.5285],
            [14.1288, 52.8783],
            [14.4145, 53.2834],
            [14.2585, 53.7295],
            [14.2137, 53.8708],
            [14.211, 53.9503],
            [16.1864, 54.2905],
            [16.5599, 54.5539],
            [17.2622, 54.7294],
            [18.3234, 54.8382],
            [18.6697, 54.4309],
            [18.9764, 54.3489],
            [19.6045, 54.459]
          ]
        ]
      },
      properties: { name: "Poland", id: "PL", Continent: "Europe" },
      id: "PL"
    },

    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [20.8982, 80.2498],
              [22.4508, 80.4021],
              [26.8607, 80.16],
              [27.1987, 79.9067],
              [25.6412, 79.403],
              [23.9476, 79.1943],
              [20.8613, 79.3977],
              [18.7251, 79.7607],
              [18.3436, 80.0596],
              [20.8982, 80.2498]
            ]
          ],
          [
            [
              [16.7867, 79.9069],
              [18.5814, 79.5716],
              [19.0894, 79.1571],
              [20.4583, 79.1292],
              [21.3891, 78.7405],
              [19.7687, 78.6228],
              [18.4395, 78.0251],
              [18.2281, 77.5226],
              [17.6234, 77.3993],
              [16.7006, 76.5793],
              [14.3658, 77.2345],
              [14.0045, 77.4453],
              [14.9212, 77.6889],
              [13.6806, 78.028],
              [14.9945, 78.1513],
              [14.6382, 78.4147],
              [13.1502, 78.2376],
              [11.7738, 78.7164],
              [11.2082, 79.1297],
              [10.8659, 79.7966],
              [12.7533, 79.7759],
              [13.384, 79.4808],
              [15.8164, 79.6818],
              [16.7867, 79.9069]
            ]
          ],
          [
            [
              [21.6084, 78.5958],
              [23.1166, 77.9915],
              [24.2384, 77.8985],
              [23.8417, 77.4978],
              [22.8019, 77.2758],
              [22.057, 77.5011],
              [21.6084, 77.9162],
              [20.228, 78.478],
              [21.6084, 78.5958]
            ]
          ],
          [[[19.2551, 70.0664], [19.5926, 69.9701], [18.7844, 69.579], [18.3493, 69.7678], [19.2551, 70.0664]]],
          [[[17.5029, 69.5962], [17.9508, 69.198], [16.9716, 69.138], [17.5029, 69.5962]]],
          [
            [
              [30.8694, 69.7834],
              [28.9656, 69.0218],
              [29.3335, 69.4731],
              [29.1414, 69.6716],
              [27.8899, 70.0618],
              [26.5258, 69.915],
              [26.0116, 69.6527],
              [25.7484, 68.9902],
              [24.9416, 68.5933],
              [23.854, 68.8058],
              [23.324, 68.649],
              [22.4107, 68.7197],
              [21.6219, 69.2708],
              [20.6222, 69.037],
              [19.9696, 68.3565],
              [18.3784, 68.5624],
              [17.9169, 67.9649],
              [17.3246, 68.1038],
              [16.1275, 67.4259],
              [16.4034, 67.0551],
              [15.4231, 66.4899],
              [15.4837, 66.306],
              [14.5432, 66.1294],
              [14.4799, 65.3014],
              [13.6503, 64.5816],
              [14.0774, 64.4641],
              [13.9606, 64.014],
              [13.2996, 64.0747],
              [12.6624, 63.9404],
              [11.9997, 63.2916],
              [12.2181, 63.0006],
              [12.1148, 62.592],
              [12.3033, 62.2856],
              [12.1557, 61.7208],
              [12.8808, 61.3522],
              [12.2941, 61.0027],
              [12.5886, 60.4507],
              [12.4862, 60.1068],
              [11.6806, 59.5923],
              [11.7985, 59.2899],
              [11.3884, 59.0365],
              [10.8347, 59.184],
              [9.6183, 58.9461],
              [8.1663, 58.1452],
              [7.0049, 58.0243],
              [5.7067, 58.5235],
              [5.5173, 58.7265],
              [5.854, 58.9595],
              [5.8452, 59.3534],
              [5.1315, 59.2264],
              [5.3051, 59.6426],
              [5.1196, 59.8337],
              [5.244, 60.5695],
              [5.0493, 60.7075],
              [4.9276, 61.7108],
              [5.5336, 62.3109],
              [6.3531, 62.6113],
              [7.2839, 62.6023],
              [7.0084, 62.9576],
              [8.1004, 63.0909],
              [8.3979, 63.535],
              [9.697, 63.6247],
              [9.7079, 63.8651],
              [10.5653, 64.4183],
              [11.2254, 64.6796],
              [11.3498, 64.9059],
              [12.1597, 65.1788],
              [12.2726, 65.5682],
              [12.9761, 66.0193],
              [13.1045, 66.5394],
              [14.755, 67.4991],
              [14.799, 67.8094],
              [15.3163, 68.0689],
              [16.0646, 68.2],
              [16.6517, 68.6257],
              [17.3909, 68.7994],
              [18.1015, 69.1563],
              [18.2931, 69.4751],
              [18.991, 69.5612],
              [19.1975, 69.7478],
              [20.069, 69.8834],
              [21.1632, 69.8895],
              [21.4005, 70.1745],
              [22.6846, 70.3749],
              [23.0462, 70.1018],
              [24.4199, 70.7021],
              [24.6581, 71.0009],
              [25.7682, 70.8532],
              [27.5972, 71.0912],
              [29.1023, 70.8607],
              [30.9441, 70.2745],
              [29.6016, 69.9767],
              [29.7922, 69.7278],
              [30.8694, 69.7834]
            ]
          ]
        ]
      },
      properties: { name: "Norway", id: "NO", Continent: "Europe" },
      id: "NO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.1944, 43.5333],
            [19.5517, 43.212],
            [20.3445, 42.828],
            [20.0641, 42.5472],
            [19.6546, 42.6285],
            [19.2809, 42.1727],
            [19.3426, 41.8691],
            [18.8943, 42.2492],
            [18.5172, 42.433],
            [18.4363, 42.5595],
            [18.4438, 42.9686],
            [18.9404, 43.4969],
            [19.1944, 43.5333]
          ]
        ]
      },
      properties: { name: "Montenegro", id: "ME", Continent: "Europe" },
      id: "ME"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[14.5661, 35.853], [14.4364, 35.8218], [14.3526, 35.8723], [14.4483, 35.9571], [14.5661, 35.853]]
        ]
      },
      properties: { name: "Malta", id: "MT", Continent: "Europe" },
      id: "MT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.344, 42.3139],
            [22.837, 41.9939],
            [23.0058, 41.717],
            [22.9157, 41.3365],
            [22.4934, 41.1185],
            [21.9295, 41.1071],
            [21.5758, 40.869],
            [20.9642, 40.8501],
            [20.7092, 40.9282],
            [20.4486, 41.5215],
            [20.5659, 41.8739],
            [21.0594, 42.1715],
            [21.5626, 42.2474],
            [22.344, 42.3139]
          ]
        ]
      },
      properties: { name: "North Macedonia", id: "MK", Continent: "Europe" },
      id: "MK"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.2125, 45.4502],
            [28.0995, 45.9727],
            [28.2445, 46.4513],
            [28.0718, 46.9787],
            [27.3367, 47.6396],
            [26.9806, 48.1551],
            [26.619, 48.2596],
            [27.5494, 48.4776],
            [28.3271, 48.1617],
            [28.7741, 48.1194],
            [29.1947, 47.8823],
            [29.1599, 47.4558],
            [29.5493, 47.2467],
            [29.5721, 46.9639],
            [29.878, 46.8291],
            [29.838, 46.3504],
            [29.1462, 46.5267],
            [28.9274, 46.4242],
            [28.9476, 46.0501],
            [28.2125, 45.4502]
          ]
        ]
      },
      properties: { name: "Moldova", id: "MD", Continent: "Europe" },
      id: "MD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[7.4386, 43.7508], [7.378, 43.7315], [7.4144, 43.7711], [7.4386, 43.7508]]]
      },
      properties: { name: "Monaco", id: "MC", Continent: "Europe" },
      id: "MC"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.3515, 57.5281],
            [27.7972, 57.3168],
            [27.8063, 56.867],
            [28.1035, 56.5455],
            [28.1483, 56.143],
            [27.577, 55.7987],
            [27.0527, 55.8306],
            [26.5935, 55.6675],
            [24.9033, 56.3983],
            [24.1207, 56.2642],
            [23.8126, 56.3293],
            [22.0848, 56.4066],
            [21.0462, 56.07],
            [21.0708, 56.8237],
            [21.3508, 57.0177],
            [21.7283, 57.571],
            [22.5544, 57.7241],
            [23.6479, 56.9711],
            [24.4032, 57.3251],
            [24.3228, 57.8707],
            [25.1108, 58.0634],
            [25.991, 57.8382],
            [26.5329, 57.531],
            [27.3515, 57.5281]
          ]
        ]
      },
      properties: { name: "Latvia", id: "LV", Continent: "Europe" },
      id: "LV"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.1163, 50.121],
            [6.4876, 49.7984],
            [6.3439, 49.4527],
            [5.7898, 49.5385],
            [5.7441, 49.9197],
            [6.1163, 50.121]
          ]
        ]
      },
      properties: { name: "Luxembourg", id: "LU", Continent: "Europe" },
      id: "LU"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.5935, 55.6675],
            [26.1751, 55.0033],
            [25.8592, 54.9191],
            [25.5471, 54.3318],
            [24.7685, 53.9745],
            [23.4844, 53.9398],
            [23.4536, 54.1435],
            [22.7663, 54.3568],
            [22.8313, 54.8385],
            [22.5676, 55.0591],
            [22.0723, 55.0637],
            [21.2357, 55.2641],
            [21.0462, 56.07],
            [22.0848, 56.4066],
            [23.8126, 56.3293],
            [24.1207, 56.2642],
            [24.9033, 56.3983],
            [26.5935, 55.6675]
          ]
        ]
      },
      properties: { name: "Lithuania", id: "LT", Continent: "Europe" },
      id: "LT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[9.5801, 47.0573], [9.4878, 47.0621], [9.5278, 47.2707], [9.5801, 47.0573]]]
      },
      properties: { name: "Liechtenstein", id: "LI", Continent: "Europe" },
      id: "LI"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.5626, 42.2474],
            [21.0594, 42.1715],
            [20.5659, 41.8739],
            [20.4855, 42.2233],
            [20.0641, 42.5472],
            [20.3445, 42.828],
            [20.624, 43.0341],
            [21.0569, 43.0917],
            [21.3908, 42.7515],
            [21.753, 42.6698],
            [21.5626, 42.2474]
          ]
        ]
      },
      properties: { name: "Kosovo", id: "XK", Continent: "Europe" },
      id: "XK"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-2.0186, 49.2313], [-2.2359, 49.1764], [-2.2205, 49.2664], [-2.0186, 49.2313]]]
      },
      properties: { name: "Jersey", id: "JE", Continent: "Europe" },
      id: "JE"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [15.5764, 38.2204],
              [15.0996, 37.4584],
              [15.2942, 37.0132],
              [15.1127, 36.688],
              [14.502, 36.7987],
              [14.1429, 37.1037],
              [13.9051, 37.1006],
              [12.4356, 37.82],
              [13.3519, 38.1808],
              [13.6815, 38.0011],
              [14.5059, 38.0455],
              [14.846, 38.1719],
              [15.5764, 38.2204]
            ]
          ],
          [
            [
              [9.6319, 40.8821],
              [9.805, 40.4998],
              [9.5625, 39.1661],
              [9.0563, 39.2394],
              [8.8814, 38.9124],
              [8.4182, 39.2055],
              [8.4714, 40.2928],
              [8.1897, 40.6518],
              [9.283, 41.202],
              [9.6319, 40.8821]
            ]
          ],
          [
            [
              [13.6999, 46.5201],
              [13.3994, 46.3177],
              [13.7197, 45.5876],
              [13.2065, 45.7713],
              [13.0307, 45.6377],
              [12.2744, 45.4458],
              [12.2256, 45.2417],
              [12.5235, 44.9677],
              [12.2484, 44.7223],
              [12.3961, 44.2237],
              [12.6914, 43.9947],
              [13.5641, 43.5711],
              [14.0106, 42.6896],
              [14.8658, 42.0524],
              [15.1686, 41.9338],
              [15.964, 41.9395],
              [16.0124, 41.4354],
              [17.103, 41.0619],
              [17.9551, 40.6554],
              [18.486, 40.1052],
              [18.0778, 39.9369],
              [17.8651, 40.2805],
              [16.9281, 40.458],
              [16.53, 39.8595],
              [16.5977, 39.6389],
              [17.1145, 39.3804],
              [17.1747, 38.9978],
              [16.559, 38.7147],
              [16.5458, 38.4093],
              [16.0568, 37.9417],
              [15.7245, 37.9391],
              [15.9724, 38.7125],
              [16.1969, 38.7591],
              [16.0238, 39.3533],
              [15.6929, 39.99],
              [14.9506, 40.2391],
              [14.7656, 40.6681],
              [14.4594, 40.6329],
              [14.1025, 40.8271],
              [13.7333, 41.2354],
              [13.0886, 41.2438],
              [12.6308, 41.4697],
              [11.6376, 42.2875],
              [11.1678, 42.5353],
              [10.7086, 42.9361],
              [10.0477, 44.0202],
              [9.2891, 44.319],
              [8.7662, 44.4223],
              [8.0815, 43.9187],
              [7.4931, 43.7671],
              [7.6772, 44.0831],
              [6.9675, 44.2804],
              [7.0308, 44.7165],
              [6.6278, 45.1178],
              [7.1534, 45.401],
              [6.8045, 45.8148],
              [7.0211, 45.9257],
              [7.9932, 46.0157],
              [8.4586, 46.2459],
              [8.9538, 45.8299],
              [9.2513, 46.2865],
              [9.9391, 46.3616],
              [10.4529, 46.8651],
              [11.025, 46.7972],
              [11.2443, 46.9755],
              [12.1544, 46.9352],
              [12.3882, 46.7025],
              [13.6999, 46.5201]
            ],
            [[12.4853, 43.9016], [12.5148, 43.953], [12.4409, 43.9824], [12.3969, 43.9345], [12.4853, 43.9016]]
          ]
        ]
      },
      properties: { name: "Italy", id: "IT", Continent: "Europe" },
      id: "IT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-15.543, 66.2284],
            [-14.6982, 66.0203],
            [-14.7577, 65.7558],
            [-13.616, 65.4871],
            [-13.8529, 64.8622],
            [-15.0216, 64.2959],
            [-16.0605, 64.1114],
            [-16.6403, 63.8655],
            [-17.8157, 63.713],
            [-18.6537, 63.4067],
            [-20.1982, 63.5557],
            [-21.2463, 63.9354],
            [-22.607, 63.8372],
            [-21.8329, 64.2054],
            [-21.9503, 64.515],
            [-22.6839, 65.0263],
            [-22.0056, 65.4935],
            [-22.9024, 65.5805],
            [-23.8999, 65.4075],
            [-24.224, 65.4871],
            [-23.7371, 66.0696],
            [-22.4262, 66.4301],
            [-21.3036, 65.8764],
            [-21.4321, 65.4741],
            [-20.9982, 65.4444],
            [-20.3566, 65.7191],
            [-20.3566, 66.0331],
            [-18.7776, 66.1687],
            [-16.7485, 66.1318],
            [-16.5408, 66.4466],
            [-15.9855, 66.5145],
            [-15.543, 66.2284]
          ]
        ]
      },
      properties: { name: "Iceland", id: "IS", Continent: "Europe" },
      id: "IS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.218, 54.0888],
            [-6.0273, 52.927],
            [-6.5612, 52.1888],
            [-7.5274, 52.099],
            [-7.6645, 51.9799],
            [-8.8135, 51.585],
            [-9.2964, 51.4982],
            [-9.5799, 51.6891],
            [-10.3786, 51.8688],
            [-9.9558, 52.1368],
            [-9.5862, 52.5592],
            [-9.2993, 53.0976],
            [-9.7954, 53.3951],
            [-9.996, 54.276],
            [-8.623, 54.3468],
            [-8.764, 54.6814],
            [-8.2747, 55.1463],
            [-7.2468, 55.3531],
            [-7.2185, 55.0921],
            [-8.1182, 54.4142],
            [-7.3554, 54.1213],
            [-6.9364, 54.3744],
            [-6.6498, 54.0587],
            [-6.218, 54.0888]
          ]
        ]
      },
      properties: { name: "Ireland", id: "IE", Continent: "Europe" },
      id: "IE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-4.4121, 54.1854], [-4.7457, 54.1189], [-4.5086, 54.3769], [-4.4121, 54.1854]]]
      },
      properties: { name: "Isle of Man", id: "IM", Continent: "Europe" },
      id: "IM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.1322, 48.4053],
            [22.8766, 47.9472],
            [22.2904, 47.7279],
            [21.1518, 46.3043],
            [20.2421, 46.1086],
            [19.5307, 46.1553],
            [18.9052, 45.9314],
            [18.4377, 45.7673],
            [17.964, 45.7704],
            [17.3105, 45.9963],
            [16.5164, 46.4996],
            [16.0928, 46.8633],
            [16.4531, 47.0065],
            [16.4342, 47.3676],
            [16.7475, 47.6862],
            [17.0666, 47.7074],
            [17.1474, 48.0059],
            [17.7618, 47.7701],
            [18.7242, 47.787],
            [18.7919, 48.0003],
            [19.8989, 48.1313],
            [20.3339, 48.2956],
            [20.4899, 48.5268],
            [21.4515, 48.5523],
            [21.767, 48.3383],
            [22.1322, 48.4053]
          ]
        ]
      },
      properties: { name: "Hungary", id: "HU", Continent: "Europe" },
      id: "HU"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[17.6677, 42.897], [18.4363, 42.5595], [18.5172, 42.433], [17.6677, 42.897]]],
          [
            [
              [18.9052, 45.9314],
              [19.0551, 45.5272],
              [19.0072, 44.8693],
              [18.6627, 45.0775],
              [17.8128, 45.078],
              [16.5305, 45.2169],
              [16.2936, 45.0085],
              [15.7877, 45.1791],
              [15.7369, 44.7658],
              [16.1301, 44.4738],
              [16.214, 44.2149],
              [17.6247, 43.0424],
              [17.5851, 42.9383],
              [16.9031, 43.3923],
              [15.9416, 43.6563],
              [15.1229, 44.2566],
              [15.2701, 44.3836],
              [14.8952, 44.7065],
              [14.8549, 45.0811],
              [14.3859, 45.3421],
              [13.9658, 44.8354],
              [13.6292, 45.108],
              [13.5778, 45.517],
              [13.8788, 45.4287],
              [14.7335, 45.5084],
              [15.3395, 45.4671],
              [15.2771, 45.7326],
              [15.6248, 45.8338],
              [15.6362, 46.2006],
              [16.5164, 46.4996],
              [17.3105, 45.9963],
              [17.964, 45.7704],
              [18.4377, 45.7673],
              [18.9052, 45.9314]
            ]
          ]
        ]
      },
      properties: { name: "Croatia", id: "HR", Continent: "Europe" },
      id: "HR"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [23.8522, 35.5358],
              [24.166, 35.595],
              [24.3128, 35.3639],
              [24.7214, 35.4249],
              [25.476, 35.3058],
              [25.7449, 35.1428],
              [24.7997, 34.9346],
              [24.4639, 35.1605],
              [23.5618, 35.2949],
              [23.8522, 35.5358]
            ]
          ],
          [[[23.4154, 38.9586], [24.0987, 38.6712], [23.759, 38.4014], [23.2519, 38.8013], [23.4154, 38.9586]]],
          [[[26.4103, 39.3296], [26.4687, 38.9727], [25.9066, 39.1392], [26.4103, 39.3296]]],
          [
            [
              [26.0394, 40.7269],
              [25.8556, 40.8439],
              [25.1042, 40.9946],
              [24.7931, 40.8574],
              [24.4771, 40.948],
              [23.8786, 40.5445],
              [23.6645, 40.2238],
              [23.312, 40.2163],
              [22.6296, 40.4953],
              [22.5922, 40.0371],
              [22.9192, 39.6293],
              [22.9658, 39.0306],
              [22.7737, 38.8004],
              [23.2528, 38.6615],
              [23.9669, 38.2748],
              [24.0552, 37.7096],
              [23.5807, 38.0107],
              [23.1938, 37.9592],
              [23.1614, 37.3339],
              [22.765, 37.3933],
              [23.0735, 36.775],
              [22.6081, 36.7798],
              [22.0803, 37.029],
              [21.8922, 36.7372],
              [21.5829, 37.0809],
              [21.5715, 37.5409],
              [21.1448, 37.9193],
              [21.4035, 38.1966],
              [21.825, 38.328],
              [22.7997, 37.9813],
              [23.1218, 38.0731],
              [22.4218, 38.4388],
              [21.4725, 38.3214],
              [21.1131, 38.3847],
              [20.7768, 38.8074],
              [20.7795, 39.0087],
              [20.301, 39.3273],
              [20.0012, 39.7097],
              [20.6574, 40.1175],
              [21.0314, 40.6584],
              [20.9642, 40.8501],
              [21.5758, 40.869],
              [21.9295, 41.1071],
              [22.4934, 41.1185],
              [22.9157, 41.3365],
              [23.6351, 41.387],
              [24.0557, 41.5272],
              [25.2514, 41.2438],
              [26.1355, 41.3857],
              [26.3211, 41.7166],
              [26.6247, 41.4016],
              [26.3307, 41.2385],
              [26.3311, 40.9546],
              [26.0394, 40.7269]
            ]
          ]
        ]
      },
      properties: { name: "Greece", id: "GR", Continent: "Europe" },
      id: "GR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-2.5124, 49.4945], [-2.5475, 49.4286], [-2.6459, 49.4681], [-2.5124, 49.4945]]]
      },
      properties: { name: "Guernsey", id: "GG", Continent: "Europe" },
      id: "GG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.4299, 41.8906],
            [46.1821, 41.6573],
            [46.6189, 41.344],
            [46.4577, 41.0702],
            [45.2808, 41.4494],
            [45.0014, 41.2912],
            [43.439, 41.1071],
            [42.7878, 41.5637],
            [42.4661, 41.4397],
            [41.5103, 41.5175],
            [41.763, 41.9702],
            [41.4888, 42.6593],
            [40.8366, 43.0635],
            [40.5242, 43.1211],
            [39.9784, 43.4199],
            [40.1502, 43.5698],
            [40.6481, 43.5342],
            [41.5806, 43.2191],
            [42.4191, 43.2243],
            [43.7822, 42.7472],
            [43.9571, 42.5665],
            [44.5061, 42.7489],
            [44.8707, 42.7564],
            [45.3437, 42.53],
            [45.7278, 42.4751],
            [45.639, 42.2048],
            [46.4299, 41.8906]
          ]
        ]
      },
      properties: { name: "Georgia", id: "GE", Continent: "Europe" },
      id: "GE"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [9.4803, 42.8056],
              [9.5506, 42.1297],
              [9.1863, 41.3848],
              [8.8075, 41.5883],
              [8.713, 42.5498],
              [9.4803, 42.8056]
            ]
          ],
          [
            [
              [5.7898, 49.5385],
              [6.3439, 49.4527],
              [6.7355, 49.1605],
              [7.4509, 49.1521],
              [8.1347, 48.9735],
              [7.8376, 48.636],
              [7.5845, 48.0643],
              [7.6153, 47.5926],
              [7.0005, 47.3228],
              [6.0601, 46.4283],
              [6.7583, 46.4157],
              [7.0211, 45.9257],
              [6.8045, 45.8148],
              [7.1534, 45.401],
              [6.6278, 45.1178],
              [7.0308, 44.7165],
              [6.9675, 44.2804],
              [7.6772, 44.0831],
              [7.4931, 43.7671],
              [7.4386, 43.7508],
              [7.4144, 43.7711],
              [7.378, 43.7315],
              [6.4942, 43.169],
              [6.1159, 43.0724],
              [5.4066, 43.2288],
              [5.0599, 43.4445],
              [4.7118, 43.3734],
              [4.0526, 43.593],
              [3.259, 43.1931],
              [3.0515, 42.9155],
              [3.2115, 42.4312],
              [2.0325, 42.3538],
              [1.7059, 42.5033],
              [1.4287, 42.596],
              [0.6969, 42.8451],
              [0.6315, 42.6896],
              [-0.0411, 42.6891],
              [-0.2993, 42.8249],
              [-1.2854, 43.0596],
              [-1.7941, 43.4076],
              [-1.4849, 43.5636],
              [-1.2456, 44.5599],
              [-1.0661, 45.806],
              [-1.1463, 46.3115],
              [-1.7866, 46.5148],
              [-2.0595, 46.8104],
              [-2.0189, 47.0377],
              [-3.1588, 47.6947],
              [-3.901, 47.8375],
              [-4.3119, 47.8228],
              [-4.6292, 48.0856],
              [-4.3932, 48.3676],
              [-4.7208, 48.5398],
              [-3.2315, 48.8408],
              [-2.6926, 48.5367],
              [-2.4462, 48.6483],
              [-1.4375, 48.6415],
              [-1.5831, 49.2022],
              [-1.8563, 49.6836],
              [-1.2586, 49.6802],
              [-1.1386, 49.388],
              [-0.1635, 49.2967],
              [0.2777, 49.4633],
              [0.1868, 49.703],
              [1.2454, 49.9981],
              [1.5513, 50.2939],
              [1.5794, 50.7393],
              [1.9125, 50.9907],
              [2.5247, 51.097],
              [2.8397, 50.7118],
              [4.1743, 50.2467],
              [4.1761, 49.9603],
              [4.656, 50.0026],
              [4.8678, 49.7881],
              [5.5072, 49.5107],
              [5.7898, 49.5385]
            ]
          ]
        ]
      },
      properties: { name: "France", id: "FR", Continent: "Europe" },
      id: "FR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-6.6311, 62.2279], [-7.0135, 62.0941], [-7.1721, 62.2856], [-6.6311, 62.2279]]]
      },
      properties: { name: "Faroe Islands", id: "FO", Continent: "Europe" },
      id: "FO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.9656, 69.0218],
            [28.4708, 68.4883],
            [28.6853, 68.1897],
            [29.3441, 68.0621],
            [29.9412, 67.5475],
            [29.087, 66.9709],
            [29.9031, 66.0911],
            [30.0955, 65.6816],
            [29.7158, 65.6246],
            [29.6042, 64.9685],
            [30.0726, 64.765],
            [30.1083, 64.366],
            [30.5038, 64.0206],
            [30.0555, 63.6888],
            [31.1809, 63.2083],
            [31.5343, 62.8855],
            [31.2856, 62.5678],
            [29.6904, 61.546],
            [27.7981, 60.5362],
            [27.2422, 60.5386],
            [25.6566, 60.3331],
            [25.1556, 60.1943],
            [23.7217, 59.9656],
            [22.7935, 60.0767],
            [22.576, 60.3591],
            [21.4361, 60.5964],
            [21.3605, 60.9675],
            [21.6061, 61.5915],
            [21.2564, 61.9897],
            [21.3539, 62.2239],
            [21.1034, 62.623],
            [22.2433, 63.4379],
            [23.2489, 63.8963],
            [24.278, 64.5152],
            [24.5579, 64.8009],
            [25.3727, 65.0096],
            [25.3481, 65.4792],
            [24.1559, 65.8052],
            [23.701, 66.4809],
            [23.9884, 66.8107],
            [23.4682, 67.4501],
            [23.639, 67.9544],
            [22.7821, 68.391],
            [21.9976, 68.5207],
            [20.6222, 69.037],
            [21.6219, 69.2708],
            [22.4107, 68.7197],
            [23.324, 68.649],
            [23.854, 68.8058],
            [24.9416, 68.5933],
            [25.7484, 68.9902],
            [26.0116, 69.6527],
            [26.5258, 69.915],
            [27.8899, 70.0618],
            [29.1414, 69.6716],
            [29.3335, 69.4731],
            [28.9656, 69.0218]
          ]
        ]
      },
      properties: { name: "Finland", id: "FI", Continent: "Europe" },
      id: "FI"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [28.0125, 59.4842],
              [27.4271, 58.7331],
              [27.4882, 58.2702],
              [27.7532, 57.841],
              [27.3515, 57.5281],
              [26.5329, 57.531],
              [25.991, 57.8382],
              [25.1108, 58.0634],
              [24.3228, 57.8707],
              [24.5289, 58.3543],
              [23.7679, 58.3609],
              [23.5094, 58.6587],
              [23.4944, 59.1957],
              [24.3804, 59.4728],
              [25.4439, 59.5211],
              [25.5093, 59.6388],
              [26.9749, 59.4507],
              [28.0125, 59.4842]
            ]
          ],
          [[[22.6173, 58.6213], [23.2928, 58.4835], [22.1529, 58.1153], [22.0816, 58.4781], [22.6173, 58.6213]]]
        ]
      },
      properties: { name: "Estonia", id: "EE", Continent: "Europe" },
      id: "EE"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.7941, 43.4076],
              [-1.2854, 43.0596],
              [-0.2993, 42.8249],
              [-0.0411, 42.6891],
              [0.6315, 42.6896],
              [0.6969, 42.8451],
              [1.4287, 42.596],
              [1.4862, 42.4347],
              [1.7059, 42.5033],
              [2.0325, 42.3538],
              [3.2115, 42.4312],
              [3.248, 41.9443],
              [2.0826, 41.2877],
              [1.0331, 41.0619],
              [0.7145, 40.8228],
              [0.3639, 40.3188],
              [-0.3269, 39.5198],
              [-0.2048, 39.0627],
              [0.2015, 38.7591],
              [-0.3812, 38.4356],
              [-0.6467, 38.1518],
              [-0.822, 37.5809],
              [-1.3274, 37.5611],
              [-1.7976, 37.2329],
              [-2.1116, 36.7767],
              [-2.7877, 36.7147],
              [-3.259, 36.7557],
              [-4.3669, 36.7183],
              [-4.6742, 36.5065],
              [-5.1715, 36.4239],
              [-5.6255, 36.0261],
              [-6.0407, 36.1883],
              [-6.4923, 36.9547],
              [-6.8845, 37.1942],
              [-7.4061, 37.1793],
              [-7.5037, 37.5853],
              [-7.1855, 38.0063],
              [-7.3431, 38.4572],
              [-7.0462, 38.9067],
              [-7.4452, 39.5365],
              [-7.0368, 39.714],
              [-7.0146, 40.2084],
              [-6.8102, 40.3429],
              [-6.9284, 41.0091],
              [-6.2126, 41.5321],
              [-6.5422, 41.6722],
              [-6.6182, 41.942],
              [-7.4037, 41.8336],
              [-7.9207, 41.8837],
              [-8.2132, 42.1337],
              [-8.777, 41.9407],
              [-8.6911, 42.2743],
              [-9.2351, 42.977],
              [-8.8737, 43.3343],
              [-8.5368, 43.3368],
              [-8.2569, 43.5799],
              [-7.6981, 43.7645],
              [-7.262, 43.5948],
              [-5.6659, 43.5825],
              [-4.5231, 43.416],
              [-3.6046, 43.5192],
              [-3.0457, 43.3716],
              [-2.337, 43.3281],
              [-1.7941, 43.4076]
            ]
          ]
        ]
      },
      properties: { name: "Spain", id: "ES", Continent: "Europe" },
      id: "ES"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-6.218, 54.0888],
              [-6.6498, 54.0587],
              [-6.9364, 54.3744],
              [-7.3554, 54.1213],
              [-8.1182, 54.4142],
              [-7.2185, 55.0921],
              [-6.1293, 55.2174],
              [-5.4703, 54.5001],
              [-5.6067, 54.2726],
              [-6.218, 54.0888]
            ]
          ],
          [
            [
              [-3.1096, 58.5154],
              [-3.2124, 58.3212],
              [-3.9902, 57.9591],
              [-3.8681, 57.6002],
              [-3.4029, 57.7081],
              [-2.074, 57.7023],
              [-1.7781, 57.4939],
              [-2.6528, 56.3184],
              [-3.1783, 56.0802],
              [-2.1472, 55.9031],
              [-1.6554, 55.5704],
              [-1.2918, 54.7738],
              [-0.6715, 54.5038],
              [-0.2054, 54.0217],
              [0.559, 52.9671],
              [1.0556, 52.9589],
              [1.6567, 52.7537],
              [1.7468, 52.469],
              [1.5592, 52.0869],
              [0.9554, 51.8078],
              [0.6869, 51.3865],
              [1.415, 51.3633],
              [0.9602, 50.926],
              [0.205, 50.763],
              [-1.5168, 50.7477],
              [-2.5477, 50.6164],
              [-2.9993, 50.7166],
              [-3.4047, 50.6322],
              [-3.7934, 50.2292],
              [-4.1726, 50.3908],
              [-5.3422, 50.2462],
              [-4.5831, 50.7764],
              [-4.1882, 51.1886],
              [-3.136, 51.2048],
              [-4.3864, 51.7412],
              [-4.9024, 51.6263],
              [-5.0882, 51.9959],
              [-4.3831, 52.1971],
              [-3.9804, 52.5417],
              [-4.1111, 53.2189],
              [-3.0646, 53.4268],
              [-2.925, 53.7326],
              [-3.1658, 54.1279],
              [-3.5692, 54.4675],
              [-3.4341, 54.9637],
              [-3.9579, 54.7809],
              [-5.1355, 54.8576],
              [-4.7213, 55.421],
              [-5.2458, 55.9292],
              [-5.6097, 56.0554],
              [-5.3919, 56.5147],
              [-5.9368, 56.6058],
              [-5.562, 57.2329],
              [-5.8182, 57.4361],
              [-5.3383, 58.2388],
              [-4.7156, 58.5101],
              [-3.0531, 58.6347],
              [-3.1096, 58.5154]
            ]
          ]
        ]
      },
      properties: { name: "United Kingdom", id: "GB", Continent: "Europe" },
      id: "GB"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[10.6449, 55.61], [10.785, 55.1334], [9.9888, 55.163], [9.8605, 55.5157], [10.6449, 55.61]]],
          [
            [
              [12.5688, 55.7851],
              [12.215, 55.4665],
              [12.0502, 54.8154],
              [11.6539, 55.1869],
              [11.2865, 55.2044],
              [10.9788, 55.7216],
              [12.219, 56.1187],
              [12.5688, 55.7851]
            ]
          ],
          [
            [
              [9.74, 54.8255],
              [8.67, 54.9036],
              [8.6159, 55.4181],
              [8.1321, 55.5998],
              [8.1642, 56.6069],
              [8.6186, 57.1113],
              [9.5542, 57.2327],
              [9.9624, 57.5811],
              [10.5372, 57.4486],
              [10.296, 56.9991],
              [10.2828, 56.6204],
              [10.8461, 56.5216],
              [10.8563, 56.2954],
              [10.3188, 56.2128],
              [10.183, 55.865],
              [9.5911, 55.493],
              [9.74, 54.8255]
            ]
          ]
        ]
      },
      properties: { name: "Denmark", id: "DK", Continent: "Europe" },
      id: "DK"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.74, 54.8255],
            [10.3605, 54.4384],
            [11.3994, 53.9446],
            [12.1113, 54.1683],
            [12.5754, 54.4673],
            [13.0289, 54.4108],
            [13.8656, 53.8534],
            [14.2585, 53.7295],
            [14.4145, 53.2834],
            [14.1288, 52.8783],
            [14.6197, 52.5285],
            [14.7529, 52.0817],
            [14.6017, 51.8324],
            [15.0166, 51.2528],
            [14.8096, 50.859],
            [14.3692, 50.8985],
            [12.4524, 50.3497],
            [12.277, 50.1814],
            [12.3903, 49.7398],
            [12.6316, 49.4613],
            [13.8146, 48.7668],
            [13.4095, 48.3942],
            [12.8144, 48.1608],
            [12.9537, 47.8909],
            [12.6861, 47.6692],
            [11.7172, 47.5838],
            [11.2979, 47.4246],
            [10.4304, 47.5412],
            [10.3131, 47.3133],
            [9.7488, 47.5756],
            [9.5243, 47.5242],
            [8.6177, 47.7662],
            [8.1984, 47.6068],
            [7.6153, 47.5926],
            [7.5845, 48.0643],
            [7.8376, 48.636],
            [8.1347, 48.9735],
            [7.4509, 49.1521],
            [6.7355, 49.1605],
            [6.3439, 49.4527],
            [6.4876, 49.7984],
            [6.1163, 50.121],
            [6.3646, 50.3162],
            [5.9937, 50.7504],
            [5.8948, 50.9843],
            [6.1932, 51.4887],
            [5.9484, 51.7625],
            [6.7416, 51.9111],
            [7.0352, 52.3802],
            [7.1974, 53.2824],
            [7.2853, 53.6814],
            [8.009, 53.6908],
            [8.5386, 53.5568],
            [8.898, 53.8356],
            [8.9573, 54.5383],
            [8.67, 54.9036],
            [9.74, 54.8255]
          ]
        ]
      },
      properties: { name: "Germany", id: "DE", Continent: "Europe" },
      id: "DE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.8096, 50.859],
            [15.3541, 50.812],
            [16.007, 50.6116],
            [16.6794, 50.0974],
            [17.1519, 50.3784],
            [17.8747, 49.9721],
            [18.562, 49.8792],
            [18.8323, 49.5107],
            [18.1608, 49.2575],
            [17.7583, 48.8881],
            [17.1884, 48.861],
            [16.9532, 48.5989],
            [16.4777, 48.8002],
            [16.0571, 48.7549],
            [15.4033, 48.9573],
            [14.9722, 48.9838],
            [14.6914, 48.5994],
            [14.0493, 48.6024],
            [13.8146, 48.7668],
            [12.6316, 49.4613],
            [12.3903, 49.7398],
            [12.277, 50.1814],
            [12.4524, 50.3497],
            [14.3692, 50.8985],
            [14.8096, 50.859]
          ]
        ]
      },
      properties: { name: "Czechia", id: "CZ", Continent: "Europe" },
      id: "CZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.4632, 35.5937],
            [33.9415, 35.2919],
            [34.0501, 34.9881],
            [32.9418, 34.576],
            [32.4488, 34.7293],
            [32.3169, 34.953],
            [32.9418, 35.3903],
            [33.4587, 35.3358],
            [34.4632, 35.5937]
          ]
        ]
      },
      properties: { name: "Cyprus", id: "CY", Continent: "Europe" },
      id: "CY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.5243, 47.5242],
            [9.5278, 47.2707],
            [9.4878, 47.0621],
            [9.5801, 47.0573],
            [9.9967, 46.8855],
            [10.4529, 46.8651],
            [9.9391, 46.3616],
            [9.2513, 46.2865],
            [8.9538, 45.8299],
            [8.4586, 46.2459],
            [7.9932, 46.0157],
            [7.0211, 45.9257],
            [6.7583, 46.4157],
            [6.0601, 46.4283],
            [7.0005, 47.3228],
            [7.6153, 47.5926],
            [8.1984, 47.6068],
            [8.6177, 47.7662],
            [9.5243, 47.5242]
          ]
        ]
      },
      properties: { name: "Switzerland", id: "CH", Continent: "Europe" },
      id: "CH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.694, 50.7748],
            [5.7973, 50.7546],
            [5.9937, 50.7504],
            [6.3646, 50.3162],
            [6.1163, 50.121],
            [5.7441, 49.9197],
            [5.7898, 49.5385],
            [5.5072, 49.5107],
            [4.8678, 49.7881],
            [4.656, 50.0026],
            [4.1761, 49.9603],
            [4.1743, 50.2467],
            [2.8397, 50.7118],
            [2.5247, 51.097],
            [3.35, 51.3776],
            [3.9023, 51.2075],
            [4.2262, 51.3865],
            [4.5035, 51.4746],
            [5.5085, 51.2751],
            [5.8271, 51.1257],
            [5.694, 50.7748]
          ]
        ]
      },
      properties: { name: "Belgium", id: "BE", Continent: "Europe" },
      id: "BE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.1483, 56.143],
            [28.7948, 55.9426],
            [29.3748, 55.9386],
            [29.4825, 55.6844],
            [30.2335, 55.8452],
            [30.9067, 55.5699],
            [30.9779, 55.0505],
            [30.799, 54.7833],
            [31.1212, 54.6484],
            [31.4038, 54.1958],
            [31.7918, 54.0558],
            [32.7041, 53.3362],
            [32.142, 53.0912],
            [31.4179, 53.196],
            [31.5773, 52.3123],
            [31.7636, 52.1011],
            [30.9805, 52.0462],
            [30.5332, 51.5964],
            [29.3466, 51.3824],
            [29.1357, 51.6173],
            [28.5992, 51.5427],
            [27.2702, 51.6135],
            [27.1424, 51.752],
            [25.9251, 51.9137],
            [24.3615, 51.8676],
            [23.6053, 51.5179],
            [23.5982, 52.1031],
            [23.197, 52.2569],
            [23.8447, 52.6642],
            [23.8874, 53.0274],
            [23.4844, 53.9398],
            [24.7685, 53.9745],
            [25.5471, 54.3318],
            [25.8592, 54.9191],
            [26.1751, 55.0033],
            [26.5935, 55.6675],
            [27.0527, 55.8306],
            [27.577, 55.7987],
            [28.1483, 56.143]
          ]
        ]
      },
      properties: { name: "Belarus", id: "BY", Continent: "Europe" },
      id: "BY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.0072, 44.8693],
            [19.357, 44.8587],
            [19.1518, 44.3023],
            [19.4879, 43.7034],
            [19.1944, 43.5333],
            [18.9404, 43.4969],
            [18.4438, 42.9686],
            [18.4363, 42.5595],
            [17.6677, 42.897],
            [17.5851, 42.9383],
            [17.6247, 43.0424],
            [16.214, 44.2149],
            [16.1301, 44.4738],
            [15.7369, 44.7658],
            [15.7877, 45.1791],
            [16.2936, 45.0085],
            [16.5305, 45.2169],
            [17.8128, 45.078],
            [18.6627, 45.0775],
            [19.0072, 44.8693]
          ]
        ]
      },
      properties: { name: "Bosnia and Herzegovina", id: "BA", Continent: "Europe" },
      id: "BA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.5855, 43.7421],
            [28.4655, 43.3892],
            [27.929, 43.1861],
            [27.889, 42.7498],
            [27.4847, 42.4681],
            [28.0142, 41.9689],
            [27.5348, 41.921],
            [27.2948, 42.0796],
            [26.6155, 41.965],
            [26.3211, 41.7166],
            [26.1355, 41.3857],
            [25.2514, 41.2438],
            [24.0557, 41.5272],
            [23.6351, 41.387],
            [22.9157, 41.3365],
            [23.0058, 41.717],
            [22.837, 41.9939],
            [22.344, 42.3139],
            [22.5237, 42.4412],
            [22.4666, 42.8425],
            [22.9768, 43.1879],
            [22.5544, 43.4547],
            [22.4209, 44.0075],
            [22.7052, 44.2377],
            [23.2242, 43.8739],
            [25.497, 43.6709],
            [26.2161, 44.007],
            [27.0865, 44.1675],
            [27.8846, 43.9873],
            [28.0499, 43.8225],
            [28.5855, 43.7421]
          ]
        ]
      },
      properties: { name: "Bulgaria", id: "BG", Continent: "Europe" },
      id: "BG"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [46.1145, 38.8778],
              [45.4799, 39.0065],
              [44.8172, 39.6503],
              [44.7684, 39.7035],
              [45.0317, 39.765],
              [45.7659, 39.3783],
              [46.1145, 38.8778]
            ]
          ],
          [
            [
              [48.5727, 41.8445],
              [49.1063, 41.3018],
              [49.2262, 41.0263],
              [49.776, 40.5837],
              [50.3068, 40.4124],
              [49.5514, 40.1943],
              [49.3242, 39.6081],
              [49.3629, 39.3492],
              [49.0136, 39.1344],
              [48.851, 38.8154],
              [48.8685, 38.4356],
              [48.593, 38.411],
              [48.0234, 38.8188],
              [48.2423, 38.9789],
              [47.9957, 39.6842],
              [47.773, 39.6486],
              [46.4907, 38.9063],
              [46.5499, 39.2012],
              [46.2019, 39.5945],
              [45.7897, 39.8814],
              [45.9646, 40.2339],
              [45.3762, 40.6383],
              [45.5875, 40.8465],
              [45.0014, 41.2912],
              [45.2808, 41.4494],
              [46.4577, 41.0702],
              [46.6189, 41.344],
              [46.1821, 41.6573],
              [46.4299, 41.8906],
              [46.7494, 41.8124],
              [47.261, 41.3149],
              [47.8609, 41.213],
              [48.3912, 41.602],
              [48.5727, 41.8445]
            ]
          ]
        ]
      },
      properties: { name: "Azerbaijan", id: "AZ", Continent: "Europe" },
      id: "AZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.9532, 48.5989],
            [16.8627, 48.4414],
            [17.1474, 48.0059],
            [17.0666, 47.7074],
            [16.7475, 47.6862],
            [16.4342, 47.3676],
            [16.4531, 47.0065],
            [16.0928, 46.8633],
            [15.9574, 46.6776],
            [15.0007, 46.6262],
            [14.5037, 46.417],
            [13.6999, 46.5201],
            [12.3882, 46.7025],
            [12.1544, 46.9352],
            [11.2443, 46.9755],
            [11.025, 46.7972],
            [10.4529, 46.8651],
            [9.9967, 46.8855],
            [9.5801, 47.0573],
            [9.5278, 47.2707],
            [9.5243, 47.5242],
            [9.7488, 47.5756],
            [10.3131, 47.3133],
            [10.4304, 47.5412],
            [11.2979, 47.4246],
            [11.7172, 47.5838],
            [12.6861, 47.6692],
            [12.9537, 47.8909],
            [12.8144, 48.1608],
            [13.4095, 48.3942],
            [13.8146, 48.7668],
            [14.0493, 48.6024],
            [14.6914, 48.5994],
            [14.9722, 48.9838],
            [15.4033, 48.9573],
            [16.0571, 48.7549],
            [16.4777, 48.8002],
            [16.9532, 48.5989]
          ]
        ]
      },
      properties: { name: "Austria", id: "AT", Continent: "Europe" },
      id: "AT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.4907, 38.9063],
            [46.1145, 38.8778],
            [45.7659, 39.3783],
            [45.0317, 39.765],
            [44.7684, 39.7035],
            [44.2894, 40.0405],
            [43.792, 40.0704],
            [43.5691, 40.4821],
            [43.7225, 40.7196],
            [43.439, 41.1071],
            [45.0014, 41.2912],
            [45.5875, 40.8465],
            [45.3762, 40.6383],
            [45.9646, 40.2339],
            [45.7897, 39.8814],
            [46.2019, 39.5945],
            [46.5499, 39.2012],
            [46.4907, 38.9063]
          ]
        ]
      },
      properties: { name: "Armenia", id: "AM", Continent: "Europe" },
      id: "AM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[1.7059, 42.5033], [1.4862, 42.4347], [1.4287, 42.596], [1.7059, 42.5033]]]
      },
      properties: { name: "Andorra", id: "AD", Continent: "Europe" },
      id: "AD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[19.9894, 60.3512], [20.2588, 60.2611], [19.7459, 60.099], [19.6871, 60.2677], [19.9894, 60.3512]]
        ]
      },
      properties: { name: "Aland Islands", id: "AX", Continent: "Europe" },
      id: "AX"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.0641, 42.5472],
            [20.4855, 42.2233],
            [20.5659, 41.8739],
            [20.4486, 41.5215],
            [20.7092, 40.9282],
            [20.9642, 40.8501],
            [21.0314, 40.6584],
            [20.6574, 40.1175],
            [20.0012, 39.7097],
            [19.8518, 40.0437],
            [19.3979, 40.2849],
            [19.3372, 40.6636],
            [19.4612, 40.933],
            [19.4405, 41.4248],
            [19.5776, 41.7874],
            [19.3426, 41.8691],
            [19.2809, 42.1727],
            [19.6546, 42.6285],
            [20.0641, 42.5472]
          ]
        ]
      },
      properties: { name: "Albania", id: "AL", Continent: "Europe" },
      id: "AL"
    }
  ]
};
export default map;
