import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Dropdown.css";
import DropdownMenu from "./DropdownMenu";
import Drop from "./DropdownBoot";
import DropdownPopper from "./DropdownPopper";
import DropdownMenuDate from "./DropdownMenuDate";
import CalendarIcon from "../icons/CalendarIcon";

function DropdownDate({
  title,
  items,
  multiSelect = false,
  setTableFilter,
  tableFilter,
  loading,
  error,
  sortSelected = false,
  search = false,
  width,
  filterLoading = false
}) {
  const [filter, setFilter] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    setTableFilter(filter);
  }, [filter]);
  /*
  useEffect(() => {
    if (!isOpen) setFilter("");
  }, [isOpen]);*/

  const { t } = useTranslation();

  return (
    <div>
      <DropdownPopper
        buttonText={<CalendarIcon size={18} />}
        active={filter.from || filter.to}
        filterLoading={filterLoading}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      >
        <DropdownMenuDate
          setFilter={setFilter}
          defaultFilterText={filter}
          t={t}
          ref={inputRef}
          search={search}
          tableFilter={tableFilter}
          closeDropdown={() => setIsOpen(false)}
          sortSelected={sortSelected}
          width={width}
          filterLoading={filterLoading}
          clearFilter={(e) => {
            setFilter("");
            setIsOpen(false);
          }}
        />
      </DropdownPopper>
    </div>
  );
}

export default DropdownDate;
