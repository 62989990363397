import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import MainLayout from '../../layouts/MainLayout'

import ReactDOMServer from 'react-dom/server'
import {
  cGradientsArea5,
  cRecommendationResponses
} from '../../config/constants'
import useGqlEntriesRecommendations from '../../gql/useGqlEntriesRecommendations'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import useGqlTextComponents from '../../gql/useGqlTextComponents'
import MapTooltip from '../../mocks/components/StyledMapToolTips'

import '../../pages/Area.css'

import AreaContent from '../components/Areas/AreaContent'
import MapContainer from '../components/Map/MapContainer'
import microstates from '../components/Map/microstates.json'
import TableContainer from '../components/Table/TableContainer'
import { citeA5, columns } from '../config/a5columns'
export default function Area5() {
  const htmlReferences = useRef()
  const [references, setReferences] = useState([])
  const [heatMap, setHeatMap] = useState(0)
  const [loadingMap, setLoadingMap] = useState(false)
  const [mapLegendStepGradient, setMapLegendStepGradient] = useState(null)
  const {
    entriesRecommendations,
    receivedIssuedRecommendations,
    loading,
    error
  } = useGqlEntriesRecommendations()
  const {
    jurisdictions,
    loading: loadingJur,
    error: errorJur,
    jurisdictionsById
  } = useGqlJurisdictions()
  const { t, i18n } = useTranslation()
  const { textComponents } = useGqlTextComponents('A5')
  const tooltip = e => {
    return ReactDOMServer.renderToStaticMarkup()
  }

  useEffect(() => {
    if (
      !loading &&
      !error &&
      jurisdictions &&
      entriesRecommendations &&
      !loadingMap &&
      receivedIssuedRecommendations
    ) {
      setLoadingMap(true)
      let responses = cRecommendationResponses()
      let referencesTmp = []
      let maxRecommendations = 0

      for (let j of jurisdictions) {
        let jurEntries = []
        jurEntries = entriesRecommendations.filter(
          e => e.motherEntry.jurisdiction.id === j.id
        )

        if (maxRecommendations < jurEntries.length)
          maxRecommendations = jurEntries.length

        let n_by_type = {}

        for (let jurEntry of jurEntries) {
          if (!n_by_type[jurEntry.recommendation_response.id])
            n_by_type[jurEntry.recommendation_response.id] = 1
          else n_by_type[jurEntry.recommendation_response.id]++
        }

        if (jurEntries.length)
          referencesTmp.push({
            id: j.a2_code,
            regions: j.regions,
            entry: {
              motherEntry: { jurisdiction: j },
              n_recommendations: 0
            },
            n_absolute: n_by_type['1'],
            microstate: microstates.find(ms => ms.A2 === j.a2_code),
            n_recommendations: n_by_type['1']
              ? (n_by_type['1'] / jurEntries.length) * 100
              : 0,
            n_by_type,
            jurEntriesLength: jurEntries.length,
            reference:
              (n_by_type['1']
                ? (n_by_type['1'] / jurEntries.length) * 100
                : 0) === 0
                ? cGradientsArea5[0]
                : cGradientsArea5.slice(1)[
                    Math.round(
                      ((n_by_type['1']
                        ? (n_by_type['1'] / jurEntries.length) * 100
                        : 0) /
                        100) *
                        (cGradientsArea5.length - 2)
                    )
                  ],
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <MapTooltip
                entry={{
                  motherEntry: { jurisdiction: j },
                  n_recommendations: n_by_type['1']
                    ? (n_by_type['1'] / jurEntries.length) * 100
                    : 0,
                  rolloverColor:
                    (n_by_type['1']
                      ? (n_by_type['1'] / jurEntries.length) * 100
                      : 0) === 0
                      ? cGradientsArea5[0]
                      : cGradientsArea5.slice(1)[
                          Math.round(
                            ((n_by_type['1']
                              ? (n_by_type['1'] / jurEntries.length) * 100
                              : 0) /
                              100) *
                              (cGradientsArea5.length - 2)
                          )
                        ],
                  n_by_type
                }}
                area={
                  heatMap === 1
                    ? 'A5_RECEIVED_CLICK'
                    : heatMap === 2
                    ? 'A5_ISSUED_CLICK'
                    : 'A5'
                }
                extraProps={{ responses: responses, heatMap }}
              />
            )
          })
        else
          referencesTmp.push({
            id: j.a2_code,
            regions: j.regions,
            entry: {
              motherEntry: { jurisdiction: j },
              n_recommendations: 0
            },
            microstate: microstates.find(ms => ms.A2 === j.a2_code),
            n_recommendations: jurEntries.length,
            reference: '#000',
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <MapTooltip
                entry={{
                  motherEntry: { jurisdiction: j },
                  n_recommendations: 0,
                  n_by_type,
                  rolloverColor: '#000'
                }}
                area={
                  heatMap === 1
                    ? 'A5_RECEIVED_CLICK'
                    : heatMap === 2
                    ? 'A5_ISSUED_CLICK'
                    : 'A5'
                }
                extraProps={{ responses: responses, heatMap }}
              />
            )
          })
      }

      referencesTmp = referencesTmp.map(r => {
        const perc = r.n_by_type
          ? Math.round(
              r.n_by_type['1']
                ? (r.n_by_type['1'] / r.jurEntriesLength) * 100
                : 0
            )
          : null
        return {
          ...r,
          reference:
            r.n_recommendations === 0
              ? cGradientsArea5[0]
              : r.n_by_type
              ? (r.n_by_type['1']
                  ? (r.n_by_type['1'] / r.jurEntriesLength) * 100
                  : 0) === 0
                ? cGradientsArea5[0]
                : cGradientsArea5[
                    perc < 1
                      ? 0
                      : perc < 20
                      ? 1
                      : perc < 40
                      ? 2
                      : perc < 60
                      ? 3
                      : 4
                  ]
              : cGradientsArea5.slice(1)[
                  Math.round(
                    (r.n_recommendations / 100) * (cGradientsArea5.length - 2)
                  )
                ]
        }
      })

      console.log(referencesTmp.filter(r => r.id === 'BO'))

      setReferences(referencesTmp)
      let labels = []
      labels[0] = 0
      labels[1] = '1 ' + t('_to_') + ' 20'
      labels[2] = '20 ' + t('_to_') + ' 40'
      labels[3] = '40 ' + t('_to_') + ' 60'
      labels[4] = '60 ' + t('_to_') + ' 80'
      labels[cGradientsArea5.length - 1] = '80 ' + t('_to_') + ' 100'
      setMapLegendStepGradient({
        colors: cGradientsArea5,
        labels,
        title: t('% Acceptance Ratio')
      })
      setLoadingMap(false)
    }
  }, [
    jurisdictions,
    entriesRecommendations,
    error,
    loading,
    receivedIssuedRecommendations,
    heatMap,
    loadingMap
  ])

  const areaInfo = {
    title: `${t('AREA')} 5`,
    subTitle: `${t('Universal Periodic Review (UPR)')}`,
    areaName: 'area-5'
  }

  return (
    <MainLayout section="areas">
      <div
        className={`area__page area5__page ${heatMap ? 'map__alternate' : ''}`}
      >
        <section className="selectRegion__section">
          <MapContainer
            extraProps={{ heatMap, setHeatMap, receivedIssuedRecommendations }}
            menuVariation="miniMapActions"
            references={references}
            currentArea="A5"
            htmlReferences={htmlReferences}
            jurisdictionsById={jurisdictionsById}
            jurisdictions={jurisdictions}
            mapLegendStepGradient={heatMap === 0 && mapLegendStepGradient}
            showRegions={heatMap === 0}
            mapLegendItems={
              heatMap === 1
                ? [
                    { id: 1, name: t('Selected State'), color: '#3F555D' },
                    { id: 2, name: t('Issuing States'), color: '#659DB2' }
                  ]
                : heatMap === 2
                ? [
                    { id: 1, name: t('Selected State'), color: '#659DB2' },
                    { id: 2, name: t('Receiving States'), color: '#3F555D' }
                  ]
                : null
            }
            title={areaInfo.title}
            modalTitle={areaInfo.subTitle}
            subTitle={areaInfo.subTitle}
            subSubTitle={areaInfo.subTitle}
            areaName={areaInfo.areaName}
            modalContent={textComponents.length && textComponents[0].map}
          />
        </section>

        <div className="content__container">
          <AreaContent areaIntro={ 'A5' } areaLatest={ 'A5' } />
        </div>

        <section className="table__section">
          <div className="content__container">
            <TableContainer
              entries={entriesRecommendations}
              t={t}
              columns={columns()}
              citeEntry={citeA5}
              expandable={false}
              paginate={true}
              showSubjurSwitch={false}
              title={t('Area') + '5'}
              disableFirstCol={true}
              subtitle={t('Universal Periodic Review (UPR)')}
              virtual={true}
            />
          </div>
        </section>
      </div>
    </MainLayout>
  )
}
