import {
  AuthenticationDetails,
  CognitoUser,
  CookieStorage,
  CognitoRefreshToken
} from 'amazon-cognito-identity-js'
import React, { createContext, useState } from 'react'
import Pool from '../config/UserPool'
import useGqlUsers from '../gql/useGqlUsers'
import jwtDecode from 'jwt-decode'
import { useEffect } from 'react'
import { gql, useApolloClient } from '@apollo/client'

const AccountContext = createContext()

const Account = props => {
  const [loggedIn, setLoggedIn] = useState(null)
  const [loadingAuth, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState({})
  const [roles, setRoles] = useState([])
  const [toast, setToast] = useState(null)
  const apolloClient = useApolloClient()
  const { setUserLanguage } = useGqlUsers()

  const getCurrentUser = async () => {
    const GET_USER = gql(`
          query GetLoggedUser($initialLogin: Boolean){
          loggedUser(initialLogin: $initialLogin){
                      
                        id
                        pronoun
                        firstname
                        lastname
                        organisation
                        email
                        lang
                      }
                    
                  }
          `)
    const response = await apolloClient.query({
      query: GET_USER,
      variables: { initialLogin: true }
    })

    return response
  }

  const getSession = async () => {
    return await new Promise(async (resolve, reject) => {
      const user = Pool.getCurrentUser()

      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            //nsole.log('REJECTED!!', err)
            //nsole.log('TRY REFRESH SESSION')
            refreshSession()
              .then(async session => {
                //nsole.log('GOOD REFRESH SESSION', err)
                var sessionIdInfo = jwtDecode(session.getIdToken().jwtToken)
                setRoles(sessionIdInfo['cognito:groups'])
                props.setLoggedIn(true)
                setLoggedIn(true)
                setLoading(false)
                try {
                  const u = await getCurrentUser()
                  setCurrentUser(u)
                } catch (e) {
                  setLoggedIn(false)
                  user.signOut()
                  reject('No user serverside1', e)
                }
                resolve(session)
              })
              .catch(err => {
                setLoggedIn(false)
                setLoading(false)
                //nsole.log('BAD REFRESH SESSION', err)
                reject('No user')
              })
          } else {
            var sessionIdInfo = jwtDecode(session.getIdToken().jwtToken)
            setRoles(sessionIdInfo['cognito:groups'])
            props.setLoggedIn(true)
            setLoggedIn(true)
            setLoading(false)
            try {
              const u = await getCurrentUser()
              setCurrentUser(u)
            } catch (e) {
              setLoggedIn(false)
              user.signOut()
              reject('No user serverside2', e)
            }
            resolve(session)
          }
        })
      } else {
        //nsole.log('TRY REFRESH SESSION')
        refreshSession()
          .then(async session => {
            //nsole.log('GOOD REFRESH SESSION', err)
            var sessionIdInfo = jwtDecode(session.getIdToken().jwtToken)
            setRoles(sessionIdInfo['cognito:groups'])
            props.setLoggedIn(true)
            setLoggedIn(true)
            setLoading(false)
            setCurrentUser(await getCurrentUser())
            resolve(session)
          })
          .catch(err => {
            setLoggedIn(false)
            setLoading(false)
            //nsole.log('BAD REFRESH SESSION', err)
            reject('No user')
          })
      }
    })
  }

  const refreshSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser()
      if (!user) reject('No user session on refresh session')

      user.getSession((err, session) => {
        if (err) reject(err)
        else {
          //nsole.log(session.getRefreshToken())
          var token = new CognitoRefreshToken({
            RefreshToken: session.getRefreshToken().token
          })
          user.refreshSession(token, (err, session) => {
            //nsole.log('Token refreshed', err, session)
          })
          resolve(session)
        }
      })
    })

  const logout = async () => {
    setLoggedIn(false)
    props.setLoggedIn(false)
    const user = Pool.getCurrentUser()

    if (user) user.signOut()
  }

  const authenticate = async (Username, Password, remember = false) =>
    await new Promise(async (resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool,
        Storage: new CookieStorage({
          expires: remember ? 1 : 1,
          secure: false,
          domain: process.env.REACT_APP_MAIN_DOMAIN
        })
      })
      const authDetails = new AuthenticationDetails({ Username, Password })

      user.authenticateUser(authDetails, {
        onSuccess: async data => {
          //Check server side now...
          const GET_USER = gql(`
          query GetLoggedUser($initialLogin: Boolean){
          loggedUser(initialLogin: $initialLogin){
                      
                        id
                        firstname
                        lang
                      }
                    
                  }
          `)
          try {
            const response = await apolloClient.query({
              query: GET_USER,
              variables: { initialLogin: true }
            })

            if (!response.error) {
              //nsole.log('onSuccess', data)
              props.setLoggedIn(true)
              setLoggedIn(true)
              resolve(data)
            } else {
              setLoggedIn(false)
              user.signOut()
              reject('Unknown', response.error)
            }
          } catch (e) {
            setLoggedIn(false)
            user.signOut()
            //nsole.log('No user serverside3')
            reject(e)
          }
        },
        onFailure: data => {
          //nsole.log('ON FAILURE', data)
          user.signOut()
          setLoggedIn(false)
          reject(data)
        },
        newPasswordRequired: data => {
          resolve(data)
        }
      })
    })

  useEffect(() => {
    if (currentUser.error && !currentUser.loading && !loadingAuth) {
      setLoggedIn(false)
      setLoading(false)
      //nsole.log('No user (server checked)')
    }
  }, [currentUser])

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        loggedIn,
        logout,
        setUserLanguage,
        loadingAuth,
        currentUser: currentUser?.data?.loggedUser,
        roles,
        refreshSession,
        toast,
        setToast
      }}
    >
      {props.children}
    </AccountContext.Provider>
  )
}

export { Account, AccountContext }
