import DOMPurify from 'dompurify'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IconCancel from '../../../components/icons/IconCancel'
import IconEdit from '../../../components/icons/IconEdit'
import IconSave from '../../../components/icons/IconSave'
import TextComponentsEditor from '../../../components/TextComponentsEditor'
import useGqlTextComponents from '../../../gql/useGqlTextComponents'
import Methodology from '../Methodology/Methodology'
import { AccountContext } from '../../../context/Accounts'
export default function IntroSection({ area, passRef }) {
  const { textComponents, updateTextComponent } = useGqlTextComponents(area)
  const [editButtons, setEditButtons] = useState(false)
  const [editIntro, setEditIntro] = useState(false)
  const [currentText, setCurrentText] = useState('')
  const [t, i18n] = useTranslation()
  const { currentUser } = useContext(AccountContext)
  const saveTextComponent = (area, type, text) => {
    updateTextComponent({
      variables: {
        lang: i18n.language,
        section: area,
        texts: {
          [type]: text
        }
      }
    })
      .then(text => {
        setEditIntro(false)
      })
      .catch(e => console.log('EEE', e))
  }

  return (
    <div
      className={`areaContent__left ${editIntro ? 'showingEdit' : ''}`}
      onMouseEnter={e => setEditButtons(true)}
      onMouseLeave={e => setEditButtons(false)}
    >
      <h5>
        {t('About this section')}
        {currentUser &&
          currentUser.email === 'lucas@ilga.org' &&
          editButtons &&
          !editIntro && (
            <span
              style={{ cursor: 'pointer', position: 'absolute' }}
              onClick={() => {
                setEditIntro(true)
              }}
            >
              <IconEdit />
            </span>
          )}
        {editIntro && (
          <>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => setEditIntro(false)}
            >
              <IconCancel size={24} />
            </span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => saveTextComponent(area, 'intro')}
            >
              <IconSave />
            </span>
          </>
        )}
      </h5>
      {!editIntro ? (
        <div
          ref={passRef}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              textComponents.length && textComponents[0].intro.replace(/href/g, "target='_blank' href"), { ADD_ATTR: ['target'] }
            )
          }}
        />
      ) : (
        <TextComponentsEditor
          variation={'intro'}
          initialText={textComponents.length && textComponents[0].intro}
          save={text => saveTextComponent(area, 'intro', text)}
          cancel={() => setEditIntro(false)}
        />
      )}

      {textComponents.length > 0 &&
        textComponents[0].methodology &&
        textComponents[0].methodology.replace(/(<([^>]+)>)/gi, '') && (
          <Methodology
            area={area}
            body={textComponents.length && textComponents[0].methodology}
          />
        )}
    </div>
  )
}
