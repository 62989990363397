import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import BarLoader from 'react-spinners/PropagateLoader'
import routes from '../../../../config/routes'
import useGqlJurisdictions from '../../../../gql/useGqlJurisdictions'
import { isFiltered } from '../../../../lib/fnHelper'
import { getAreasSelected } from '../../../components/AreaSelectModal/AreaSelectModal'
import ASAreasInput from '../../../components/ASAreasInput/ASAreasInput'
import ASDropdownRangeSelect from '../../../components/ASDropdownRangeSelect'
import ASJursInput from '../../../components/ASJursInput/ASJursInput'
import ASTopicsInput from '../../../components/ASTopicsInput/ASTopicsInput'
import CloseIcon from '../../../components/icons/CloseIcon'
import MagGlass from '../../../components/icons/MagGlass'
import ASCheckBoxDropdown from './ASCheckboxDropdown'

const TheDatePicker = () => {
  const [startDate, setStartDate] = useState(new Date())
  return (
    <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
  )
}

export default function AdvancedSearchForm({
  filters,
  setFilters,
  search,
  setResults,
  loading
}) {
  const { t } = useTranslation()
  const [localFilters, setLocalFilters] = useState(filters)

  const theRoutes = routes()
  let {
    jurisdictions,
    jurisdictionsById,
    loading: loadingJur,
    error,
    regions,
    topics
  } = useGqlJurisdictions()

  return (
    <>
      <div className="acp__formContainer">
        <ASAreasInput filters={localFilters} setFilters={setLocalFilters} />
        <ASTopicsInput filters={localFilters} setFilters={setLocalFilters} />
        <ASJursInput
          filters={localFilters.jurs_ids}
          setFilters={jurs =>
            setLocalFilters({ ...localFilters, jurs_ids: jurs })
          }
        />

        <div className="acp__formControl">
          <label htmlFor="bolleanQuery">{t('Type of Document')}</label>
          <ASCheckBoxDropdown
            filters={localFilters}
            setFilters={setLocalFilters}
          />
        </div>
        <div className="acp__formControl">
          <label htmlFor="bolleanQuery">
            {t('Insert Term or Boolean query')}
          </label>
          <div className="acpfc__doubleHeight hasTermBooleanInput">
            <input
              className="termBooleanInput"
              type="text"
              placeholder={t('Search') + '...'}
              value={localFilters.boolean || ''}
              onChange={e =>
                setLocalFilters({ ...localFilters, boolean: e.target.value })
              }
            />
            <MagGlass size={22} color="#C4BBDD" />
          </div>
        </div>
        <div className="acp__formControl">
          <label htmlFor="dateRange">{t('Date range')}</label>
          <ASDropdownRangeSelect
            filters={localFilters}
            setFilters={setLocalFilters}
          />
        </div>

        {isFiltered(localFilters) && (
          <div className="acpfc__footer">
            {getAreasSelected(localFilters).map(area => (
              <span className="acpfc__selectedLabel acpsl__blue" key={area.id}>
                {theRoutes.find(r => r.id === area.id).name}
                <CloseIcon
                  size={12}
                  onClick={e =>
                    setLocalFilters({
                      ...localFilters,
                      areas_ids: localFilters.areas_ids.filter(s => {
                        return s !== area.id
                      }),
                      subareas: {
                        ...localFilters.subareas,
                        [area.id]: [],
                        ...(area.id.length > 2
                          ? {
                              [area.id.substring(0, 2)]: localFilters.subareas[
                                area.id.substring(0, 2)
                              ].filter(f => f !== area.id)
                            }
                          : {})
                      }
                    })
                  }
                />
              </span>
            ))}

            {localFilters.jurs_ids &&
              localFilters.jurs_ids.map(jur =>
                jurisdictions.find(j => j.id === jur) ? (
                  <span className="acpfc__selectedLabel acpsl__green">
                    {jurisdictions.find(j => j.id === jur).name}
                    <CloseIcon
                      size={12}
                      onClick={() =>
                        setLocalFilters({
                          ...localFilters,
                          jurs_ids: localFilters.jurs_ids.filter(t => t !== jur)
                        })
                      }
                    />
                  </span>
                ) : (
                  <></>
                )
              )}

            {localFilters &&
              localFilters.topics &&
              localFilters.topics.map(topic => (
                <span
                  className="acpfc__selectedLabel acpsl__purple"
                  key={topic}
                >
                  {topics.find(t => t.name === topic).noncase_name}
                  <CloseIcon
                    size={12}
                    onClick={() =>
                      setLocalFilters({
                        ...localFilters,
                        topics: localFilters.topics.filter(t => t !== topic)
                      })
                    }
                  />
                </span>
              ))}
            {localFilters &&
              localFilters.cu_types &&
              localFilters.cu_types.map(cu_type => (
                <span className="acpfc__selectedLabel acpsl__brown">
                  {cu_type}
                  <CloseIcon
                    size={12}
                    onClick={() =>
                      setLocalFilters({
                        ...localFilters,
                        cu_types: localFilters.cu_types.filter(
                          t => t !== cu_type
                        )
                      })
                    }
                  />
                </span>
              ))}
            {localFilters.boolean && (
              <span className="acpfc__selectedLabel acpsl__yellow">
                {localFilters.boolean}
                <CloseIcon
                  size={12}
                  onClick={() =>
                    setLocalFilters({ ...localFilters, boolean: '' })
                  }
                />
              </span>
            )}
            {(localFilters.date_to || localFilters.date_from) && (
              <span className="acpfc__selectedLabel acpsl__orange">
                {localFilters.date_from
                  ? t('From') + ' ' + localFilters.date_from
                  : ''}
                {'  '}
                {localFilters.date_to
                  ? (localFilters.date_from ? t('To_hasta') : t('To_hasta')) +
                    ' ' +
                    localFilters.date_to
                  : ''}
                <CloseIcon
                  size={12}
                  onClick={() =>
                    setLocalFilters({
                      ...localFilters,
                      date_from: '',
                      date_to: ''
                    })
                  }
                />
              </span>
            )}
          </div>
        )}
        {((localFilters.areas_ids &&
          localFilters.areas_ids.indexOf('a3') !== -1) ||
          (localFilters.subareas &&
            localFilters.subareas.a3 &&
            localFilters.subareas.a3.length>0)) && (
          <div className="acp__a3a4a5warning">{t('adv_search_text_a3')}</div>
        )}
        {((localFilters.areas_ids &&
          localFilters.areas_ids.indexOf('a4') !== -1) ||
          (localFilters.subareas &&
            localFilters.subareas.a4 &&
            localFilters.subareas.a4.length>0)) && (
          <div className="acp__a3a4a5warning">{t('adv_search_text_a4')}</div>
        )}
        {((localFilters.areas_ids &&
          localFilters.areas_ids.indexOf('a5') !== -1) ||
          (localFilters.subareas &&
            localFilters.subareas.a5 &&
            localFilters.subareas.a5.length>0)) && (
          <div className="acp__a3a4a5warning">{t('adv_search_text_a5')}</div>
        )}
      </div>
      <div className="acp__actionBar">
        {!loading && (
          <button
            className={
              'btn btn-primary ' + (!isFiltered(localFilters) ? 'disabled' : '')
            }
            disabled={!isFiltered(localFilters)}
            onClick={() => {
              setLocalFilters({})
              setResults(null)
            }}
          >
            {t('Clear')}
          </button>
        )}

        <button
          className="btn btn-primary"
          onClick={() => search(localFilters)}
          style={{ minHeight: '36px' }}
        >
          {loading ? (
            <BarLoader color={'#fff'} loading={true} size={2} />
          ) : (
            t('Search')
          )}
        </button>
      </div>
    </>
  )
}
