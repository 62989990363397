var map = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.1512, 49.2607],
            [-2.0913, 49.2536],
            [-2.0253, 49.2321],
            [-2.0332, 49.1644],
            [-2.0782, 49.1635],
            [-2.1421, 49.1968],
            [-2.2234, 49.1748],
            [-2.2307, 49.2255],
            [-2.2535, 49.2476],
            [-2.2425, 49.259],
            [-2.1826, 49.2495],
            [-2.1512, 49.2607]
          ]
        ]
      },
      properties: { id: "JE", name: "Jersey", TYPE: "British Crown Dependency", CNTRY: "Jersey" },
      id: "JE"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[-2.1718, 49.7293], [-2.1737, 49.7143], [-2.218, 49.7019], [-2.2188, 49.7211], [-2.1718, 49.7293]]],
          [
            [
              [-2.5132, 49.5061],
              [-2.5079, 49.4809],
              [-2.5303, 49.4725],
              [-2.5457, 49.4278],
              [-2.5621, 49.4171],
              [-2.6707, 49.4287],
              [-2.6519, 49.4583],
              [-2.6092, 49.4659],
              [-2.5674, 49.496],
              [-2.5442, 49.492],
              [-2.5132, 49.5061]
            ]
          ]
        ]
      },
      properties: { id: "GG", name: "Guernsey", TYPE: "British Crown Dependency", CNTRY: "Guernsey" },
      id: "GG"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.6654, 51.6173],
              [-2.6835, 51.659],
              [-2.6617, 51.6755],
              [-2.6837, 51.7261],
              [-2.6702, 51.7495],
              [-2.6758, 51.7867],
              [-2.6542, 51.8078],
              [-2.6958, 51.8363],
              [-2.7287, 51.8384],
              [-2.7757, 51.875],
              [-2.8736, 51.9292],
              [-2.9525, 51.9087],
              [-2.9823, 51.9228],
              [-2.9988, 51.9467],
              [-3.0283, 51.9633],
              [-3.0799, 52.0168],
              [-3.0876, 52.0517],
              [-3.1238, 52.0757],
              [-3.1014, 52.1131],
              [-3.1222, 52.132],
              [-3.087, 52.1408],
              [-3.1077, 52.1727],
              [-3.0669, 52.2092],
              [-3.0542, 52.2302],
              [-3.0025, 52.257],
              [-2.9684, 52.2578],
              [-2.9995, 52.2962],
              [-2.9637, 52.3226],
              [-2.9567, 52.3411],
              [-3.0706, 52.3466],
              [-3.1037, 52.3681],
              [-3.1993, 52.4125],
              [-3.2151, 52.4384],
              [-3.1903, 52.4688],
              [-3.1077, 52.4939],
              [-3.0308, 52.4969],
              [-2.9764, 52.5381],
              [-3.0053, 52.5673],
              [-3.0672, 52.5454],
              [-3.0865, 52.5291],
              [-3.124, 52.5665],
              [-3.1163, 52.5863],
              [-3.0742, 52.6067],
              [-3.0657, 52.6336],
              [-3.0358, 52.6494],
              [-3.0279, 52.6869],
              [-3.0098, 52.7108],
              [-2.9674, 52.7199],
              [-2.9615, 52.7337],
              [-2.9985, 52.7561],
              [-3.0575, 52.7662],
              [-3.0822, 52.783],
              [-3.1403, 52.7961],
              [-3.1522, 52.8298],
              [-3.1219, 52.8549],
              [-3.1284, 52.88],
              [-3.0804, 52.9176],
              [-3.0039, 52.9498],
              [-2.9584, 52.9505],
              [-2.933, 52.9343],
              [-2.878, 52.9414],
              [-2.848, 52.9373],
              [-2.7795, 52.9006],
              [-2.7215, 52.9182],
              [-2.7162, 52.9418],
              [-2.7273, 52.9664],
              [-2.7686, 52.9815],
              [-2.8062, 52.985],
              [-2.8505, 53.0172],
              [-2.871, 53.0663],
              [-2.8974, 53.1022],
              [-2.9515, 53.1384],
              [-2.9524, 53.1529],
              [-2.9153, 53.1671],
              [-2.9136, 53.1834],
              [-2.9556, 53.2155],
              [-3.0198, 53.2467],
              [-3.0845, 53.2586],
              [-3.0959, 53.2871],
              [-3.0667, 53.3055],
              [-3.0253, 53.2935],
              [-2.9745, 53.2966],
              [-2.945, 53.2894],
              [-2.9264, 53.3048],
              [-2.8815, 53.2884],
              [-2.8506, 53.2891],
              [-2.7481, 53.3096],
              [-2.7509, 53.3316],
              [-2.7998, 53.321],
              [-2.8865, 53.3336],
              [-2.925, 53.35],
              [-2.9808, 53.3928],
              [-3.0966, 53.5443],
              [-3.0995, 53.572],
              [-3.0218, 53.6528],
              [-2.9517, 53.7081],
              [-2.899, 53.7354],
              [-2.9849, 53.7361],
              [-3.0215, 53.7458],
              [-3.0492, 53.7695],
              [-3.0564, 53.803],
              [-3.0449, 53.8785],
              [-3.0566, 53.9062],
              [-3.0176, 53.932],
              [-2.9257, 53.9527],
              [-2.882, 53.9565],
              [-2.8693, 53.9788],
              [-2.8345, 53.9998],
              [-2.8955, 54.0023],
              [-2.9182, 54.0343],
              [-2.8589, 54.081],
              [-2.8268, 54.0888],
              [-2.796, 54.1252],
              [-2.8509, 54.1846],
              [-2.8103, 54.2119],
              [-2.8209, 54.2217],
              [-2.9047, 54.1876],
              [-2.9204, 54.1621],
              [-2.9842, 54.1531],
              [-3.0113, 54.1741],
              [-3.0082, 54.2003],
              [-3.0447, 54.2083],
              [-3.061, 54.1619],
              [-3.1179, 54.1088],
              [-3.1453, 54.1],
              [-3.1454, 54.0638],
              [-3.1898, 54.0979],
              [-3.241, 54.1121],
              [-3.2331, 54.1592],
              [-3.2068, 54.2089],
              [-3.3068, 54.1919],
              [-3.3285, 54.2047],
              [-3.4074, 54.2777],
              [-3.4041, 54.3186],
              [-3.4163, 54.3449],
              [-3.4402, 54.3518],
              [-3.4743, 54.3935],
              [-3.4977, 54.4058],
              [-3.5919, 54.4831],
              [-3.6342, 54.5129],
              [-3.6116, 54.5299],
              [-3.5903, 54.5646],
              [-3.5638, 54.6428],
              [-3.5007, 54.726],
              [-3.4681, 54.7353],
              [-3.4396, 54.7615],
              [-3.4402, 54.7974],
              [-3.3879, 54.8834],
              [-3.345, 54.9025],
              [-3.2957, 54.8869],
              [-3.2546, 54.8998],
              [-3.3173, 54.9203],
              [-3.2765, 54.9434],
              [-3.2136, 54.9544],
              [-3.1802, 54.9408],
              [-3.1228, 54.9327],
              [-3.0503, 54.9815],
              [-3.015, 55.0515],
              [-2.9538, 55.041],
              [-2.9223, 55.0717],
              [-2.8831, 55.0836],
              [-2.8225, 55.1352],
              [-2.7466, 55.1562],
              [-2.6975, 55.1794],
              [-2.6614, 55.2125],
              [-2.6313, 55.2234],
              [-2.6258, 55.2625],
              [-2.5515, 55.3144],
              [-2.4628, 55.3604],
              [-2.4267, 55.366],
              [-2.3879, 55.355],
              [-2.3595, 55.3625],
              [-2.3251, 55.3981],
              [-2.2722, 55.4189],
              [-2.2114, 55.4333],
              [-2.1829, 55.4624],
              [-2.2127, 55.4903],
              [-2.217, 55.5089],
              [-2.2567, 55.5495],
              [-2.2787, 55.5891],
              [-2.3273, 55.6251],
              [-2.3067, 55.6451],
              [-2.2428, 55.6499],
              [-2.1629, 55.7228],
              [-2.092, 55.7572],
              [-2.0785, 55.7904],
              [-2.0229, 55.8055],
              [-2.0097, 55.7908],
              [-1.8778, 55.6949],
              [-1.8531, 55.6593],
              [-1.8217, 55.6364],
              [-1.7686, 55.6419],
              [-1.7477, 55.6192],
              [-1.6991, 55.613],
              [-1.6308, 55.5851],
              [-1.6308, 55.5373],
              [-1.6029, 55.5236],
              [-1.6113, 55.508],
              [-1.585, 55.4837],
              [-1.5814, 55.4167],
              [-1.5893, 55.3765],
              [-1.5586, 55.3289],
              [-1.5701, 55.2862],
              [-1.5565, 55.255],
              [-1.521, 55.2294],
              [-1.5292, 55.2163],
              [-1.508, 55.1946],
              [-1.5221, 55.1675],
              [-1.496, 55.1346],
              [-1.4801, 55.0854],
              [-1.4596, 55.0786],
              [-1.4129, 55.0003],
              [-1.3702, 54.9755],
              [-1.3558, 54.9043],
              [-1.2968, 54.7799],
              [-1.2755, 54.7484],
              [-1.1926, 54.6938],
              [-1.1647, 54.6528],
              [-1.1988, 54.6216],
              [-1.1467, 54.6185],
              [-1.1381, 54.6466],
              [-1.1036, 54.6247],
              [-1.0421, 54.6171],
              [-0.9935, 54.5981],
              [-0.7879, 54.5608],
              [-0.563, 54.4774],
              [-0.5229, 54.4471],
              [-0.5204, 54.4201],
              [-0.4628, 54.389],
              [-0.4306, 54.3493],
              [-0.418, 54.3039],
              [-0.3907, 54.2835],
              [-0.3976, 54.2692],
              [-0.3628, 54.2425],
              [-0.322, 54.2361],
              [-0.2771, 54.2178],
              [-0.2604, 54.177],
              [-0.2339, 54.1605],
              [-0.1114, 54.1316],
              [-0.0755, 54.1121],
              [-0.1639, 54.0834],
              [-0.1947, 54.0623],
              [-0.2195, 54.0228],
              [-0.1974, 53.9948],
              [-0.1511, 53.8993],
              [-0.0452, 53.8012],
              [0.1335, 53.6426],
              [0.1493, 53.6096],
              [0.0995, 53.6223],
              [0.0562, 53.6413],
              [0.0148, 53.6433],
              [-0.045, 53.6256],
              [-0.0923, 53.631],
              [-0.13, 53.6461],
              [-0.2253, 53.7199],
              [-0.2604, 53.7354],
              [-0.3052, 53.7397],
              [-0.4311, 53.7143],
              [-0.5105, 53.7143],
              [-0.5444, 53.7095],
              [-0.5792, 53.728],
              [-0.637, 53.732],
              [-0.7265, 53.7006],
              [-0.6925, 53.687],
              [-0.6498, 53.7106],
              [-0.5962, 53.7081],
              [-0.5299, 53.6777],
              [-0.4863, 53.6945],
              [-0.3072, 53.7143],
              [-0.2794, 53.7071],
              [-0.1886, 53.6204],
              [-0.144, 53.6066],
              [-0.0959, 53.5771],
              [-0.0466, 53.5704],
              [0.0001, 53.5353],
              [0.096, 53.4883],
              [0.1519, 53.4758],
              [0.1758, 53.4377],
              [0.2195, 53.4195],
              [0.2604, 53.3615],
              [0.3421, 53.2263],
              [0.3562, 53.1885],
              [0.3566, 53.1457],
              [0.3298, 53.0865],
              [0.2825, 53.0749],
              [0.193, 53.0201],
              [0.1608, 53.009],
              [0.0821, 52.9387],
              [0.0221, 52.9126],
              [0.0103, 52.8865],
              [0.0925, 52.8925],
              [0.1709, 52.8621],
              [0.2468, 52.7957],
              [0.2645, 52.8037],
              [0.3255, 52.8032],
              [0.3699, 52.773],
              [0.3792, 52.7908],
              [0.4386, 52.8407],
              [0.4567, 52.892],
              [0.4857, 52.9335],
              [0.5203, 52.9553],
              [0.5696, 52.9693],
              [0.6234, 52.9757],
              [0.6719, 52.9744],
              [0.6847, 52.9864],
              [0.7505, 52.9744],
              [0.8357, 52.9744],
              [0.9684, 52.9475],
              [1.0139, 52.9601],
              [0.9661, 52.9744],
              [1.2747, 52.9292],
              [1.396, 52.8914],
              [1.6442, 52.7758],
              [1.697, 52.731],
              [1.7158, 52.6837],
              [1.7472, 52.6252],
              [1.7476, 52.5328],
              [1.7712, 52.4859],
              [1.7544, 52.4674],
              [1.7293, 52.4156],
              [1.7279, 52.3963],
              [1.6836, 52.3245],
              [1.6305, 52.2705],
              [1.6309, 52.1998],
              [1.5823, 52.0815],
              [1.5037, 52.061],
              [1.4248, 52.0019],
              [1.3323, 51.9401],
              [1.2752, 51.977],
              [1.2507, 51.9596],
              [1.2057, 51.9534],
              [1.1653, 51.967],
              [1.0945, 51.956],
              [1.0955, 51.9457],
              [1.2621, 51.9392],
              [1.2785, 51.9279],
              [1.1996, 51.8777],
              [1.2279, 51.8674],
              [1.2883, 51.8606],
              [1.2764, 51.8448],
              [1.2192, 51.8118],
              [1.1354, 51.7817],
              [1.0654, 51.7753],
              [1.0373, 51.7822],
              [0.9888, 51.8303],
              [0.887, 51.7821],
              [0.8737, 51.7473],
              [0.8235, 51.7338],
              [0.7671, 51.7395],
              [0.6986, 51.72],
              [0.7493, 51.7085],
              [0.853, 51.7189],
              [0.93, 51.7436],
              [0.9474, 51.7252],
              [0.9382, 51.7064],
              [0.9518, 51.6784],
              [0.938, 51.6645],
              [0.9385, 51.625],
              [0.9518, 51.617],
              [0.9245, 51.5883],
              [0.8733, 51.5595],
              [0.8163, 51.5372],
              [0.7713, 51.5283],
              [0.6921, 51.5363],
              [0.6428, 51.5335],
              [0.6234, 51.522],
              [0.5254, 51.5169],
              [0.4561, 51.5056],
              [0.4417, 51.477],
              [0.5348, 51.4917],
              [0.6948, 51.477],
              [0.7219, 51.4563],
              [0.7131, 51.4412],
              [0.641, 51.4442],
              [0.6094, 51.4251],
              [0.5545, 51.4122],
              [0.5859, 51.3911],
              [0.6719, 51.3911],
              [0.7129, 51.3842],
              [0.7004, 51.4098],
              [0.7264, 51.419],
              [0.7393, 51.3879],
              [0.7644, 51.3644],
              [0.9772, 51.3492],
              [1.1009, 51.3732],
              [1.4238, 51.3921],
              [1.4483, 51.3829],
              [1.4391, 51.3501],
              [1.4186, 51.3297],
              [1.383, 51.3298],
              [1.3806, 51.3048],
              [1.4043, 51.2614],
              [1.4131, 51.2217],
              [1.4048, 51.1835],
              [1.3844, 51.1517],
              [1.3572, 51.131],
              [1.2904, 51.1165],
              [1.263, 51.1033],
              [1.2181, 51.101],
              [1.1722, 51.0774],
              [1.1067, 51.0764],
              [1.0669, 51.0644],
              [1.0276, 51.0417],
              [0.9665, 50.9827],
              [0.9798, 50.9182],
              [0.9452, 50.9094],
              [0.8023, 50.9392],
              [0.7625, 50.9309],
              [0.6644, 50.8703],
              [0.6242, 50.8587],
              [0.4075, 50.8293],
              [0.3703, 50.8188],
              [0.3423, 50.7952],
              [0.3081, 50.781],
              [0.271, 50.7474],
              [0.2141, 50.7489],
              [0.1683, 50.7598],
              [0.1221, 50.7608],
              [0.0956, 50.7751],
              [0.0345, 50.781],
              [-0.1732, 50.829],
              [-0.2331, 50.8219],
              [-0.2697, 50.8313],
              [-0.3976, 50.8021],
              [-0.569, 50.8021],
              [-0.7327, 50.7673],
              [-0.7731, 50.766],
              [-0.7663, 50.7471],
              [-0.7899, 50.73],
              [-0.8128, 50.7348],
              [-0.9041, 50.7719],
              [-0.9059, 50.788],
              [-0.8636, 50.8021],
              [-0.8931, 50.8253],
              [-0.9392, 50.8432],
              [-1.0207, 50.8437],
              [-1.0562, 50.783],
              [-1.0828, 50.781],
              [-1.103, 50.7955],
              [-1.0692, 50.8437],
              [-1.0873, 50.8498],
              [-1.1647, 50.8437],
              [-1.1297, 50.8251],
              [-1.1249, 50.7887],
              [-1.1511, 50.7814],
              [-1.1856, 50.7924],
              [-1.1993, 50.8079],
              [-1.2552, 50.8319],
              [-1.3014, 50.8437],
              [-1.3389, 50.8727],
              [-1.4256, 50.9001],
              [-1.3231, 50.8257],
              [-1.3173, 50.8002],
              [-1.404, 50.7916],
              [-1.4049, 50.7747],
              [-1.4919, 50.7573],
              [-1.5247, 50.7611],
              [-1.5612, 50.719],
              [-1.6021, 50.7318],
              [-1.6706, 50.7401],
              [-1.7151, 50.7358],
              [-1.7717, 50.7201],
              [-1.8351, 50.7277],
              [-1.8964, 50.7163],
              [-1.94, 50.6908],
              [-1.9493, 50.7133],
              [-2.022, 50.7201],
              [-2.0356, 50.7133],
              [-2.0025, 50.6813],
              [-1.9528, 50.6766],
              [-1.953, 50.6518],
              [-1.9325, 50.6444],
              [-1.9837, 50.5966],
              [-2.0822, 50.5978],
              [-2.1573, 50.6205],
              [-2.3426, 50.6338],
              [-2.3771, 50.6475],
              [-2.4359, 50.6375],
              [-2.4656, 50.5851],
              [-2.4312, 50.5703],
              [-2.4393, 50.5418],
              [-2.4835, 50.5904],
              [-2.6929, 50.6928],
              [-2.8648, 50.7338],
              [-2.9628, 50.7233],
              [-2.9982, 50.7082],
              [-3.0714, 50.711],
              [-3.1158, 50.688],
              [-3.1665, 50.6928],
              [-3.2598, 50.6745],
              [-3.2956, 50.6375],
              [-3.3682, 50.6171],
              [-3.4075, 50.6213],
              [-3.4673, 50.5856],
              [-3.4949, 50.5482],
              [-3.505, 50.5012],
              [-3.4875, 50.4594],
              [-3.5321, 50.4548],
              [-3.5563, 50.4321],
              [-3.532, 50.4102],
              [-3.4875, 50.4116],
              [-3.4983, 50.3837],
              [-3.5201, 50.3748],
              [-3.5389, 50.3444],
              [-3.5705, 50.3564],
              [-3.5778, 50.334],
              [-3.632, 50.3123],
              [-3.6533, 50.251],
              [-3.6456, 50.226],
              [-3.718, 50.2124],
              [-3.7742, 50.223],
              [-3.789, 50.2124],
              [-3.8263, 50.2342],
              [-3.8842, 50.2805],
              [-3.9499, 50.319],
              [-3.9702, 50.3207],
              [-4.0329, 50.2949],
              [-4.0702, 50.3092],
              [-4.064, 50.3223],
              [-4.1083, 50.3366],
              [-4.1418, 50.369],
              [-4.1655, 50.3764],
              [-4.1915, 50.4176],
              [-4.1794, 50.4531],
              [-4.2347, 50.4321],
              [-4.2161, 50.4157],
              [-4.2285, 50.3974],
              [-4.273, 50.3819],
              [-4.2285, 50.3707],
              [-4.208, 50.3564],
              [-4.215, 50.3231],
              [-4.2523, 50.3564],
              [-4.3377, 50.3707],
              [-4.3837, 50.3675],
              [-4.4562, 50.3382],
              [-4.4953, 50.3284],
              [-4.6598, 50.3223],
              [-4.6872, 50.3427],
              [-4.7521, 50.3303],
              [-4.7554, 50.3017],
              [-4.7834, 50.2779],
              [-4.7765, 50.2608],
              [-4.8002, 50.2296],
              [-4.8497, 50.2346],
              [-4.8873, 50.215],
              [-4.9473, 50.1994],
              [-5.0024, 50.1441],
              [-5.0204, 50.192],
              [-5.053, 50.1958],
              [-5.0646, 50.1584],
              [-5.0585, 50.1427],
              [-5.1061, 50.0951],
              [-5.0753, 50.0905],
              [-5.0565, 50.0606],
              [-5.0981, 50.0144],
              [-5.1465, 50.0106],
              [-5.2104, 49.9667],
              [-5.2495, 50.0001],
              [-5.2527, 50.0278],
              [-5.2884, 50.0677],
              [-5.324, 50.0895],
              [-5.3842, 50.108],
              [-5.4247, 50.1127],
              [-5.4748, 50.1304],
              [-5.5409, 50.1135],
              [-5.5347, 50.0823],
              [-5.5784, 50.0548],
              [-5.6674, 50.0429],
              [-5.7052, 50.0523],
              [-5.6988, 50.0854],
              [-5.7092, 50.1326],
              [-5.6846, 50.1615],
              [-5.511, 50.2195],
              [-5.4891, 50.2198],
              [-5.438, 50.1943],
              [-5.4174, 50.2025],
              [-5.3876, 50.2403],
              [-5.3519, 50.2403],
              [-5.3148, 50.2536],
              [-5.2003, 50.3308],
              [-5.1465, 50.3553],
              [-5.1537, 50.3995],
              [-5.1197, 50.4048],
              [-5.0503, 50.4287],
              [-5.0387, 50.4479],
              [-5.0253, 50.5242],
              [-4.9956, 50.5482],
              [-4.9415, 50.557],
              [-4.9336, 50.5203],
              [-4.861, 50.5192],
              [-4.9219, 50.5549],
              [-4.8957, 50.5859],
              [-4.8212, 50.5892],
              [-4.7925, 50.5952],
              [-4.7493, 50.6444],
              [-4.7519, 50.6701],
              [-4.7281, 50.6723],
              [-4.6573, 50.7111],
              [-4.6378, 50.7412],
              [-4.5773, 50.7747],
              [-4.5501, 50.8096],
              [-4.5549, 50.8978],
              [-4.5438, 50.9392],
              [-4.5227, 50.9727],
              [-4.5264, 51.015],
              [-4.4373, 51.015],
              [-4.3957, 51.0007],
              [-4.3377, 50.9966],
              [-4.3023, 51.0075],
              [-4.2347, 51.0553],
              [-4.2143, 51.086],
              [-4.2319, 51.1267],
              [-4.2098, 51.1728],
              [-4.2285, 51.1924],
              [-4.1523, 51.2116],
              [-3.9695, 51.2224],
              [-3.9132, 51.2334],
              [-3.8409, 51.2334],
              [-3.7692, 51.2477],
              [-3.6398, 51.2264],
              [-3.5593, 51.2281],
              [-3.4342, 51.2098],
              [-3.385, 51.1862],
              [-3.2707, 51.1894],
              [-3.1628, 51.2067],
              [-3.0575, 51.2082],
              [-3.0218, 51.1924],
              [-2.9996, 51.2332],
              [-3.0082, 51.2545],
              [-3.0106, 51.311],
              [-2.9922, 51.3219],
              [-2.9878, 51.3467],
              [-2.9596, 51.3743],
              [-2.953, 51.3985],
              [-2.921, 51.3944],
              [-2.8842, 51.417],
              [-2.8164, 51.4736],
              [-2.7809, 51.4891],
              [-2.7164, 51.4996],
              [-2.6962, 51.5126],
              [-2.6078, 51.6074],
              [-2.5804, 51.6186],
              [-2.5564, 51.6592],
              [-2.4992, 51.6968],
              [-2.465, 51.7259],
              [-2.4049, 51.7412],
              [-2.3808, 51.7619],
              [-2.4821, 51.7429],
              [-2.5221, 51.7064],
              [-2.5817, 51.6817],
              [-2.6654, 51.6173]
            ]
          ],
          [
            [
              [0.9364, 51.371],
              [0.9115, 51.3644],
              [0.7948, 51.3705],
              [0.7576, 51.3864],
              [0.7339, 51.4122],
              [0.7468, 51.4396],
              [0.7669, 51.4479],
              [0.8908, 51.421],
              [0.9325, 51.3977],
              [0.9364, 51.371]
            ]
          ],
          [
            [
              [-0.9388, 50.7884],
              [-1.0207, 50.7884],
              [-0.9726, 50.8298],
              [-0.9478, 50.8178],
              [-0.9599, 50.8021],
              [-0.9388, 50.7884]
            ]
          ],
          [
            [
              [-1.2958, 50.7732],
              [-1.1443, 50.7338],
              [-1.1091, 50.7329],
              [-1.0623, 50.6928],
              [-1.0753, 50.6785],
              [-1.1287, 50.6664],
              [-1.1647, 50.6409],
              [-1.1684, 50.6035],
              [-1.2792, 50.5825],
              [-1.3155, 50.5892],
              [-1.4974, 50.6723],
              [-1.5351, 50.6694],
              [-1.5453, 50.6861],
              [-1.5059, 50.7077],
              [-1.4187, 50.7338],
              [-1.4009, 50.722],
              [-1.3435, 50.7611],
              [-1.2958, 50.7732]
            ]
          ],
          [
            [
              [-4.6557, 53.3198],
              [-4.6263, 53.3209],
              [-4.5809, 53.2855],
              [-4.5585, 53.2528],
              [-4.5819, 53.242],
              [-4.6228, 53.2759],
              [-4.6767, 53.2851],
              [-4.694, 53.3096],
              [-4.6557, 53.3198]
            ]
          ],
          [
            [
              [-3.0845, 53.2586],
              [-3.0198, 53.2467],
              [-2.9556, 53.2155],
              [-2.9136, 53.1834],
              [-2.9153, 53.1671],
              [-2.9524, 53.1529],
              [-2.9515, 53.1384],
              [-2.8974, 53.1022],
              [-2.871, 53.0663],
              [-2.8505, 53.0172],
              [-2.8062, 52.985],
              [-2.7686, 52.9815],
              [-2.7273, 52.9664],
              [-2.7162, 52.9418],
              [-2.7215, 52.9182],
              [-2.7795, 52.9006],
              [-2.848, 52.9373],
              [-2.878, 52.9414],
              [-2.933, 52.9343],
              [-2.9584, 52.9505],
              [-3.0039, 52.9498],
              [-3.0804, 52.9176],
              [-3.1284, 52.88],
              [-3.1219, 52.8549],
              [-3.1522, 52.8298],
              [-3.1403, 52.7961],
              [-3.0822, 52.783],
              [-3.0575, 52.7662],
              [-2.9985, 52.7561],
              [-2.9615, 52.7337],
              [-2.9674, 52.7199],
              [-3.0098, 52.7108],
              [-3.0279, 52.6869],
              [-3.0358, 52.6494],
              [-3.0657, 52.6336],
              [-3.0742, 52.6067],
              [-3.1163, 52.5863],
              [-3.124, 52.5665],
              [-3.0865, 52.5291],
              [-3.0672, 52.5454],
              [-3.0053, 52.5673],
              [-2.9764, 52.5381],
              [-3.0308, 52.4969],
              [-3.1077, 52.4939],
              [-3.1903, 52.4688],
              [-3.2151, 52.4384],
              [-3.1993, 52.4125],
              [-3.1037, 52.3681],
              [-3.0706, 52.3466],
              [-2.9567, 52.3411],
              [-2.9637, 52.3226],
              [-2.9995, 52.2962],
              [-2.9684, 52.2578],
              [-3.0025, 52.257],
              [-3.0542, 52.2302],
              [-3.0669, 52.2092],
              [-3.1077, 52.1727],
              [-3.087, 52.1408],
              [-3.1222, 52.132],
              [-3.1014, 52.1131],
              [-3.1238, 52.0757],
              [-3.0876, 52.0517],
              [-3.0799, 52.0168],
              [-3.0283, 51.9633],
              [-2.9988, 51.9467],
              [-2.9823, 51.9228],
              [-2.9525, 51.9087],
              [-2.8736, 51.9292],
              [-2.7757, 51.875],
              [-2.7287, 51.8384],
              [-2.6958, 51.8363],
              [-2.6542, 51.8078],
              [-2.6758, 51.7867],
              [-2.6702, 51.7495],
              [-2.6837, 51.7261],
              [-2.6617, 51.6755],
              [-2.6835, 51.659],
              [-2.6654, 51.6173],
              [-2.7131, 51.5866],
              [-2.7605, 51.5798],
              [-2.8164, 51.5556],
              [-2.8587, 51.5461],
              [-2.9433, 51.5425],
              [-2.9747, 51.563],
              [-3.0157, 51.5316],
              [-3.1056, 51.4972],
              [-3.1409, 51.4648],
              [-3.1727, 51.4531],
              [-3.1601, 51.4318],
              [-3.1848, 51.3976],
              [-3.2189, 51.4028],
              [-3.2894, 51.3842],
              [-3.5392, 51.3985],
              [-3.5574, 51.4051],
              [-3.6047, 51.4457],
              [-3.6632, 51.4762],
              [-3.7179, 51.4786],
              [-3.7419, 51.5084],
              [-3.7589, 51.5468],
              [-3.7822, 51.5766],
              [-3.8327, 51.6155],
              [-3.8549, 51.6238],
              [-3.9624, 51.6156],
              [-3.9999, 51.5909],
              [-3.9746, 51.563],
              [-4.0656, 51.5576],
              [-4.1216, 51.566],
              [-4.1664, 51.5425],
              [-4.2134, 51.5391],
              [-4.2725, 51.5548],
              [-4.2899, 51.617],
              [-4.2347, 51.6313],
              [-4.139, 51.6323],
              [-4.0913, 51.6399],
              [-4.0702, 51.6623],
              [-4.1463, 51.6671],
              [-4.2005, 51.6859],
              [-4.215, 51.6797],
              [-4.2937, 51.6723],
              [-4.3424, 51.6909],
              [-4.3793, 51.7269],
              [-4.338, 51.7233],
              [-4.3718, 51.7548],
              [-4.4265, 51.7753],
              [-4.4265, 51.7473],
              [-4.4608, 51.7358],
              [-4.5742, 51.7412],
              [-4.6454, 51.7321],
              [-4.6782, 51.7174],
              [-4.6803, 51.6927],
              [-4.7123, 51.6501],
              [-4.7349, 51.6592],
              [-4.7592, 51.645],
              [-4.8483, 51.6463],
              [-4.879, 51.6374],
              [-4.9048, 51.6138],
              [-4.941, 51.5965],
              [-5.0216, 51.616],
              [-5.0512, 51.6569],
              [-5.1005, 51.6647],
              [-5.1134, 51.6853],
              [-5.0366, 51.6784],
              [-5.0287, 51.6929],
              [-4.9882, 51.6859],
              [-4.941, 51.7001],
              [-4.8926, 51.7064],
              [-4.8448, 51.72],
              [-4.8734, 51.7377],
              [-4.879, 51.762],
              [-4.865, 51.7833],
              [-4.9213, 51.78],
              [-4.8921, 51.7394],
              [-4.9106, 51.7182],
              [-5.0099, 51.7064],
              [-5.1492, 51.7181],
              [-5.1807, 51.7064],
              [-5.2301, 51.73],
              [-5.1768, 51.7597],
              [-5.126, 51.7685],
              [-5.1071, 51.7881],
              [-5.1259, 51.856],
              [-5.1644, 51.8668],
              [-5.2358, 51.871],
              [-5.2914, 51.864],
              [-5.2889, 51.9169],
              [-5.2342, 51.923],
              [-5.2042, 51.9423],
              [-5.1195, 51.9589],
              [-5.088, 51.976],
              [-5.0828, 52.0145],
              [-5.0702, 52.0264],
              [-5.015, 52.0253],
              [-4.9617, 52.0075],
              [-4.9137, 52.0148],
              [-4.8323, 52.019],
              [-4.8304, 52.0529],
              [-4.7776, 52.0649],
              [-4.7387, 52.1008],
              [-4.6375, 52.1385],
              [-4.5138, 52.1357],
              [-4.4843, 52.1563],
              [-4.4547, 52.1621],
              [-4.3712, 52.2093],
              [-4.3158, 52.2163],
              [-4.1971, 52.2793],
              [-4.1391, 52.3302],
              [-4.0963, 52.3879],
              [-4.079, 52.453],
              [-4.0565, 52.4879],
              [-4.0626, 52.521],
              [-4.0162, 52.522],
              [-3.9473, 52.5493],
              [-3.9745, 52.5565],
              [-4.0531, 52.5424],
              [-4.1141, 52.5886],
              [-4.1222, 52.6287],
              [-4.1059, 52.6535],
              [-4.0635, 52.6831],
              [-4.0488, 52.7216],
              [-4.1252, 52.7783],
              [-4.1462, 52.8202],
              [-4.1173, 52.8308],
              [-4.1322, 52.8683],
              [-4.1299, 52.8892],
              [-4.1005, 52.9141],
              [-4.2285, 52.9192],
              [-4.3007, 52.9056],
              [-4.3373, 52.892],
              [-4.4066, 52.8925],
              [-4.4462, 52.8759],
              [-4.501, 52.8268],
              [-4.4891, 52.8032],
              [-4.5165, 52.7895],
              [-4.5831, 52.8146],
              [-4.6486, 52.7996],
              [-4.7045, 52.8032],
              [-4.7474, 52.7834],
              [-4.7554, 52.8093],
              [-4.7349, 52.8305],
              [-4.5961, 52.9284],
              [-4.524, 52.9442],
              [-4.4434, 52.9822],
              [-4.4237, 53.0026],
              [-4.3582, 53.0285],
              [-4.3457, 53.0595],
              [-4.3409, 53.1127],
              [-4.3036, 53.1253],
              [-4.2635, 53.1545],
              [-4.2345, 53.164],
              [-4.2005, 53.201],
              [-4.1515, 53.2281],
              [-4.0907, 53.2352],
              [-4.0669, 53.2291],
              [-4.0089, 53.2445],
              [-3.9746, 53.2618],
              [-3.8653, 53.2891],
              [-3.8414, 53.3074],
              [-3.7689, 53.3192],
              [-3.7163, 53.2869],
              [-3.6022, 53.2889],
              [-3.5623, 53.2944],
              [-3.3721, 53.3509],
              [-3.3303, 53.3518],
              [-3.2713, 53.3306],
              [-3.2508, 53.3126],
              [-3.1222, 53.2623],
              [-3.0845, 53.2586]
            ]
          ],
          [
            [
              [-4.2032, 53.2985],
              [-4.1366, 53.3051],
              [-4.1278, 53.3148],
              [-4.0689, 53.3104],
              [-4.0634, 53.2893],
              [-4.0981, 53.2501],
              [-4.1955, 53.2203],
              [-4.2294, 53.1822],
              [-4.2801, 53.155],
              [-4.3439, 53.1321],
              [-4.3924, 53.1383],
              [-4.412, 53.1556],
              [-4.4486, 53.1625],
              [-4.496, 53.1826],
              [-4.4996, 53.2112],
              [-4.5199, 53.2228],
              [-4.571, 53.2755],
              [-4.5587, 53.3633],
              [-4.571, 53.3928],
              [-4.5535, 53.4006],
              [-4.4254, 53.4267],
              [-4.3247, 53.4195],
              [-4.2767, 53.401],
              [-4.2669, 53.369],
              [-4.2337, 53.353],
              [-4.2032, 53.2985]
            ]
          ],
          [
            [
              [-2.9264, 53.3048],
              [-2.945, 53.2894],
              [-2.9745, 53.2966],
              [-3.0253, 53.2935],
              [-3.0667, 53.3055],
              [-3.0959, 53.2871],
              [-3.1354, 53.3338],
              [-3.1852, 53.3773],
              [-3.1864, 53.3928],
              [-3.1573, 53.4088],
              [-3.0942, 53.4174],
              [-3.0583, 53.4348],
              [-3.0122, 53.4167],
              [-2.953, 53.3239],
              [-2.9264, 53.3048]
            ]
          ]
        ]
      },
      properties: { id: "GB-ENW", name: "England and Wales", TYPE: "Country", CNTRY: "United Kingdom" },
      id: "GB-ENW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.2699, 54.0979],
            [-6.2846, 54.1052],
            [-6.3681, 54.0973],
            [-6.3775, 54.0633],
            [-6.4339, 54.0553],
            [-6.4785, 54.0677],
            [-6.564, 54.049],
            [-6.5951, 54.0524],
            [-6.6165, 54.0373],
            [-6.6725, 54.0684],
            [-6.6571, 54.0919],
            [-6.6664, 54.1148],
            [-6.6346, 54.1501],
            [-6.6403, 54.168],
            [-6.7065, 54.1989],
            [-6.7448, 54.1842],
            [-6.7878, 54.203],
            [-6.8239, 54.2324],
            [-6.8377, 54.2605],
            [-6.8822, 54.2773],
            [-6.8567, 54.2928],
            [-6.8797, 54.3416],
            [-6.906, 54.349],
            [-6.922, 54.3727],
            [-6.9841, 54.4031],
            [-7.0493, 54.4115],
            [-7.0785, 54.3947],
            [-7.127, 54.3498],
            [-7.1599, 54.3352],
            [-7.209, 54.2934],
            [-7.1459, 54.2521],
            [-7.1533, 54.2242],
            [-7.2295, 54.2075],
            [-7.2611, 54.1809],
            [-7.2635, 54.141],
            [-7.2974, 54.1259],
            [-7.2955, 54.1651],
            [-7.3332, 54.1427],
            [-7.3103, 54.1147],
            [-7.3902, 54.1212],
            [-7.4147, 54.1457],
            [-7.4396, 54.1469],
            [-7.5023, 54.1251],
            [-7.609, 54.1399],
            [-7.6327, 54.1685],
            [-7.7048, 54.2004],
            [-7.837, 54.2043],
            [-7.8697, 54.2269],
            [-7.8737, 54.2711],
            [-7.895, 54.2936],
            [-7.9508, 54.3009],
            [-7.981, 54.3266],
            [-8.0022, 54.3579],
            [-8.0313, 54.358],
            [-8.0799, 54.3802],
            [-8.156, 54.4391],
            [-8.1502, 54.4694],
            [-8.097, 54.4786],
            [-8.0559, 54.4976],
            [-8.0022, 54.5434],
            [-7.8871, 54.5321],
            [-7.8488, 54.5409],
            [-7.7493, 54.5962],
            [-7.7079, 54.6042],
            [-7.7106, 54.6243],
            [-7.7536, 54.6145],
            [-7.815, 54.6395],
            [-7.838, 54.6312],
            [-7.8643, 54.6491],
            [-7.9066, 54.6613],
            [-7.9071, 54.6867],
            [-7.8459, 54.731],
            [-7.7705, 54.706],
            [-7.7366, 54.7075],
            [-7.6497, 54.7449],
            [-7.6154, 54.7393],
            [-7.5517, 54.7547],
            [-7.5434, 54.7931],
            [-7.4708, 54.8453],
            [-7.4447, 54.8845],
            [-7.449, 54.9203],
            [-7.4074, 54.9594],
            [-7.4053, 55.0036],
            [-7.355, 55.0409],
            [-7.2912, 55.0466],
            [-7.2471, 55.0693],
            [-7.2567, 55.0507],
            [-7.1954, 55.0581],
            [-7.1339, 55.0438],
            [-7.0651, 55.0438],
            [-7.0172, 55.0711],
            [-6.998, 55.1186],
            [-6.9749, 55.1354],
            [-6.9687, 55.1807],
            [-6.9467, 55.1954],
            [-6.8838, 55.1741],
            [-6.7545, 55.1778],
            [-6.6405, 55.2083],
            [-6.5713, 55.2112],
            [-6.5198, 55.2407],
            [-6.4759, 55.2487],
            [-6.4159, 55.2319],
            [-6.3782, 55.2462],
            [-6.3529, 55.2426],
            [-6.2558, 55.2109],
            [-6.2176, 55.2104],
            [-6.16, 55.2219],
            [-6.101, 55.2124],
            [-6.0371, 55.1679],
            [-6.0406, 55.112],
            [-6.0621, 55.0741],
            [-5.9757, 55.053],
            [-5.9702, 55.0258],
            [-5.9883, 54.9887],
            [-5.9303, 54.9709],
            [-5.8863, 54.9408],
            [-5.8693, 54.9104],
            [-5.83, 54.8852],
            [-5.7976, 54.8445],
            [-5.7817, 54.8617],
            [-5.7516, 54.8569],
            [-5.702, 54.8247],
            [-5.6898, 54.7931],
            [-5.6926, 54.7652],
            [-5.7293, 54.7285],
            [-5.8454, 54.7012],
            [-5.8907, 54.6723],
            [-5.9272, 54.633],
            [-5.8949, 54.6048],
            [-5.839, 54.6497],
            [-5.748, 54.6804],
            [-5.6947, 54.6687],
            [-5.5747, 54.6801],
            [-5.5366, 54.6575],
            [-5.5144, 54.5979],
            [-5.4789, 54.5695],
            [-5.4686, 54.5163],
            [-5.4371, 54.4879],
            [-5.4335, 54.4663],
            [-5.4643, 54.4252],
            [-5.4612, 54.3921],
            [-5.4891, 54.3696],
            [-5.502, 54.3397],
            [-5.5277, 54.3453],
            [-5.5471, 54.3791],
            [-5.5767, 54.4028],
            [-5.5717, 54.4256],
            [-5.5487, 54.4428],
            [-5.5474, 54.4891],
            [-5.5753, 54.529],
            [-5.6449, 54.5678],
            [-5.6719, 54.5768],
            [-5.706, 54.5427],
            [-5.64, 54.4958],
            [-5.6446, 54.4721],
            [-5.6256, 54.4411],
            [-5.6614, 54.3796],
            [-5.6221, 54.3767],
            [-5.5797, 54.3837],
            [-5.5505, 54.3654],
            [-5.5407, 54.3273],
            [-5.553, 54.2985],
            [-5.6126, 54.2556],
            [-5.6331, 54.2556],
            [-5.6558, 54.2297],
            [-5.6788, 54.2462],
            [-5.7794, 54.2465],
            [-5.862, 54.2305],
            [-5.8685, 54.1613],
            [-5.8832, 54.1208],
            [-5.912, 54.0913],
            [-5.9538, 54.0787],
            [-6.0405, 54.0364],
            [-6.0796, 54.0346],
            [-6.085, 54.057],
            [-6.1743, 54.0743],
            [-6.1859, 54.0927],
            [-6.2699, 54.0979]
          ]
        ]
      },
      properties: { id: "GB-NIR", name: "Northern Ireland", TYPE: "Province", CNTRY: "United Kingdom" },
      id: "GB-NIR"
    },
    
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-4.6121, 54.057],
            [-4.6564, 54.0638],
            [-4.6897, 54.0815],
            [-4.726, 54.079],
            [-4.7662, 54.0592],
            [-4.7709, 54.0968],
            [-4.7372, 54.1249],
            [-4.7103, 54.2206],
            [-4.664, 54.2335],
            [-4.5984, 54.2766],
            [-4.531, 54.3661],
            [-4.4953, 54.3859],
            [-4.442, 54.4044],
            [-4.3678, 54.419],
            [-4.3513, 54.4138],
            [-4.3776, 54.3619],
            [-4.3638, 54.3243],
            [-4.3247, 54.3102],
            [-4.3119, 54.2872],
            [-4.3483, 54.2595],
            [-4.3656, 54.2351],
            [-4.3992, 54.2221],
            [-4.3908, 54.1947],
            [-4.4755, 54.1531],
            [-4.4746, 54.1408],
            [-4.5293, 54.1191],
            [-4.5498, 54.1007],
            [-4.5909, 54.0911],
            [-4.6121, 54.057]
          ]
        ]
      },
      properties: { id: "IM", name: "Isle of Man", TYPE: "British Crown Dependency", CNTRY: "Isle of Man" },
      id: "IM"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.7758, 60.7916],
              [-0.8085, 60.7786],
              [-0.8017, 60.7448],
              [-0.85, 60.7182],
              [-0.8219, 60.685],
              [-0.9348, 60.6821],
              [-0.9662, 60.6902],
              [-0.9432, 60.726],
              [-0.9262, 60.7847],
              [-0.9388, 60.8001],
              [-0.8603, 60.8376],
              [-0.7955, 60.841],
              [-0.7718, 60.8334],
              [-0.7607, 60.8001],
              [-0.7758, 60.7916]
            ]
          ],
          [
            [
              [-1.0799, 60.7355],
              [-0.9935, 60.7311],
              [-0.9971, 60.6761],
              [-0.9797, 60.6567],
              [-1.0505, 60.6556],
              [-1.0139, 60.6151],
              [-1.0643, 60.6041],
              [-1.0111, 60.5777],
              [-1.0419, 60.553],
              [-1.0207, 60.5155],
              [-1.0317, 60.5007],
              [-1.0801, 60.5052],
              [-1.097, 60.4923],
              [-1.1539, 60.5084],
              [-1.1678, 60.5305],
              [-1.18, 60.6247],
              [-1.1714, 60.646],
              [-1.1381, 60.6492],
              [-1.1115, 60.6811],
              [-1.1139, 60.7147],
              [-1.0799, 60.7355]
            ]
          ],
          [
            [
              [-1.2921, 60.5996],
              [-1.3014, 60.5648],
              [-1.3361, 60.5332],
              [-1.295, 60.4923],
              [-1.3518, 60.4184],
              [-1.297, 60.438],
              [-1.2336, 60.4438],
              [-1.2604, 60.4649],
              [-1.2175, 60.498],
              [-1.1863, 60.4822],
              [-1.1579, 60.4438],
              [-1.2242, 60.4114],
              [-1.1654, 60.4121],
              [-1.1238, 60.4022],
              [-1.0727, 60.4445],
              [-1.0487, 60.4333],
              [-1.1192, 60.3913],
              [-1.0683, 60.3963],
              [-1.0792, 60.355],
              [-1.1572, 60.3508],
              [-1.1516, 60.3373],
              [-1.0753, 60.321],
              [-1.1379, 60.2907],
              [-1.0898, 60.2725],
              [-1.1297, 60.2528],
              [-1.1852, 60.2514],
              [-1.2194, 60.2383],
              [-1.1579, 60.2247],
              [-1.1872, 60.1912],
              [-1.1439, 60.1876],
              [-1.1517, 60.1496],
              [-1.1816, 60.1293],
              [-1.2023, 60.0831],
              [-1.1701, 60.046],
              [-1.2151, 60.0262],
              [-1.1926, 59.9975],
              [-1.243, 59.9769],
              [-1.2746, 59.8821],
              [-1.3165, 59.896],
              [-1.3479, 59.892],
              [-1.376, 59.9086],
              [-1.364, 59.9373],
              [-1.323, 59.9566],
              [-1.3287, 59.9955],
              [-1.3029, 60.028],
              [-1.2877, 60.0756],
              [-1.2626, 60.1151],
              [-1.2677, 60.1422],
              [-1.3124, 60.1806],
              [-1.3577, 60.2443],
              [-1.3761, 60.2379],
              [-1.3976, 60.1927],
              [-1.4308, 60.1849],
              [-1.4554, 60.1562],
              [-1.5273, 60.1929],
              [-1.5482, 60.2297],
              [-1.6151, 60.2157],
              [-1.6697, 60.2396],
              [-1.6902, 60.2851],
              [-1.6574, 60.3062],
              [-1.6029, 60.3135],
              [-1.5558, 60.3066],
              [-1.5284, 60.3135],
              [-1.3964, 60.3218],
              [-1.3743, 60.3084],
              [-1.3435, 60.3476],
              [-1.2843, 60.3635],
              [-1.395, 60.39],
              [-1.3953, 60.4092],
              [-1.439, 60.4308],
              [-1.4381, 60.4605],
              [-1.5002, 60.4852],
              [-1.572, 60.4768],
              [-1.6094, 60.4833],
              [-1.5951, 60.5068],
              [-1.5615, 60.5107],
              [-1.5484, 60.5394],
              [-1.5006, 60.5468],
              [-1.4111, 60.5804],
              [-1.4187, 60.6083],
              [-1.2914, 60.6231],
              [-1.2921, 60.5996]
            ]
          ],
          [
            [
              [-0.8091, 60.6083],
              [-0.7584, 60.6076],
              [-0.7852, 60.5763],
              [-0.8422, 60.5883],
              [-0.9053, 60.5869],
              [-0.9388, 60.6287],
              [-0.8221, 60.6287],
              [-0.8091, 60.6083]
            ]
          ],
          [
            [
              [-0.976, 60.3403],
              [-1.0341, 60.3402],
              [-1.0064, 60.369],
              [-0.9676, 60.381],
              [-0.9112, 60.3823],
              [-0.976, 60.3403]
            ]
          ],
          [
            [
              [-1.0749, 60.1828],
              [-1.0527, 60.1484],
              [-1.0738, 60.1077],
              [-1.1222, 60.1231],
              [-1.1192, 60.1556],
              [-1.1349, 60.1787],
              [-1.0749, 60.1828]
            ]
          ],
          [
            [
              [-2.0481, 60.1292],
              [-2.063, 60.118],
              [-2.1123, 60.138],
              [-2.0879, 60.1555],
              [-2.0532, 60.1555],
              [-2.0481, 60.1292]
            ]
          ],
          [[[-1.6081, 59.5474], [-1.5968, 59.5321], [-1.6455, 59.5141], [-1.6437, 59.5456], [-1.6081, 59.5474]]],
          [
            [
              [-2.9464, 59.3496],
              [-2.9604, 59.3266],
              [-2.8955, 59.303],
              [-2.871, 59.2713],
              [-2.9474, 59.2912],
              [-2.9897, 59.2671],
              [-3.0203, 59.2803],
              [-3.0399, 59.312],
              [-2.9652, 59.3609],
              [-2.9464, 59.3496]
            ]
          ],
          [
            [
              [-2.3955, 59.2951],
              [-2.459, 59.2791],
              [-2.4974, 59.2392],
              [-2.5163, 59.2521],
              [-2.5694, 59.2379],
              [-2.6042, 59.2446],
              [-2.5663, 59.2688],
              [-2.5188, 59.2743],
              [-2.4924, 59.2894],
              [-2.3955, 59.2951]
            ]
          ],
          [
            [
              [-2.7686, 59.2446],
              [-2.7277, 59.2242],
              [-2.7611, 59.1931],
              [-2.7589, 59.1642],
              [-2.7814, 59.1414],
              [-2.8173, 59.1912],
              [-2.786, 59.1968],
              [-2.7919, 59.237],
              [-2.7686, 59.2446]
            ]
          ],
          [
            [
              [-3.0291, 59.1824],
              [-2.9808, 59.1874],
              [-2.9752, 59.1338],
              [-3.0048, 59.128],
              [-3.0656, 59.1336],
              [-3.1147, 59.1584],
              [-3.1024, 59.1903],
              [-3.0492, 59.1968],
              [-3.0291, 59.1824]
            ]
          ],
          [
            [
              [-2.6149, 59.1522],
              [-2.5952, 59.1295],
              [-2.5587, 59.1208],
              [-2.5326, 59.0836],
              [-2.576, 59.0801],
              [-2.6144, 59.1072],
              [-2.6149, 59.1522]
            ]
          ],
          [
            [
              [-3.1562, 59.1422],
              [-3.1216, 59.124],
              [-3.0845, 59.128],
              [-3.0492, 59.1081],
              [-3.0514, 59.0902],
              [-2.9945, 59.0733],
              [-2.9945, 59.0597],
              [-3.0537, 59.0473],
              [-3.1167, 58.9982],
              [-3.0597, 58.9914],
              [-3.012, 59.0174],
              [-2.9744, 58.9991],
              [-2.9331, 59.0113],
              [-2.8647, 58.9748],
              [-2.7902, 58.9923],
              [-2.7819, 58.9739],
              [-2.8376, 58.9504],
              [-2.8119, 58.9291],
              [-2.7754, 58.9287],
              [-2.796, 58.9567],
              [-2.7277, 58.9709],
              [-2.7076, 58.959],
              [-2.7065, 58.9324],
              [-2.785, 58.9107],
              [-2.808, 58.888],
              [-2.8393, 58.8826],
              [-2.915, 58.8991],
              [-2.9736, 58.9538],
              [-3.0703, 58.9287],
              [-3.1012, 58.9301],
              [-3.2028, 58.9159],
              [-3.2279, 58.9287],
              [-3.2353, 58.9726],
              [-3.2074, 59.0051],
              [-3.2666, 59.0071],
              [-3.257, 58.9783],
              [-3.2894, 58.9567],
              [-3.3232, 58.9572],
              [-3.3533, 58.9828],
              [-3.3667, 59.0161],
              [-3.344, 59.0528],
              [-3.3502, 59.098],
              [-3.3238, 59.1274],
              [-3.2793, 59.1435],
              [-3.2311, 59.1484],
              [-3.1562, 59.1422]
            ]
          ],
          [
            [
              [-3.2461, 58.8962],
              [-3.1932, 58.8536],
              [-3.1727, 58.8201],
              [-3.2367, 58.8057],
              [-3.2185, 58.7933],
              [-3.1601, 58.808],
              [-3.1563, 58.7868],
              [-3.2059, 58.779],
              [-3.2993, 58.7791],
              [-3.3278, 58.8183],
              [-3.3788, 58.8536],
              [-3.3735, 58.868],
              [-3.4302, 58.8748],
              [-3.4163, 58.9083],
              [-3.3494, 58.9285],
              [-3.2461, 58.8962]
            ]
          ],
          [
            [
              [-2.8854, 58.8201],
              [-2.9136, 58.8105],
              [-2.9263, 58.7791],
              [-2.911, 58.7487],
              [-2.9399, 58.7307],
              [-2.9878, 58.7574],
              [-2.9744, 58.772],
              [-3.0039, 58.8082],
              [-3.0356, 58.8201],
              [-2.997, 58.8353],
              [-2.907, 58.8381],
              [-2.8854, 58.8201]
            ]
          ],
          [
            [
              [-2.0229, 55.8055],
              [-2.0785, 55.7904],
              [-2.092, 55.7572],
              [-2.1629, 55.7228],
              [-2.2428, 55.6499],
              [-2.3067, 55.6451],
              [-2.3273, 55.6251],
              [-2.2787, 55.5891],
              [-2.2567, 55.5495],
              [-2.217, 55.5089],
              [-2.2127, 55.4903],
              [-2.1829, 55.4624],
              [-2.2114, 55.4333],
              [-2.2722, 55.4189],
              [-2.3251, 55.3981],
              [-2.3595, 55.3625],
              [-2.3879, 55.355],
              [-2.4267, 55.366],
              [-2.4628, 55.3604],
              [-2.5515, 55.3144],
              [-2.6258, 55.2625],
              [-2.6313, 55.2234],
              [-2.6614, 55.2125],
              [-2.6975, 55.1794],
              [-2.7466, 55.1562],
              [-2.8225, 55.1352],
              [-2.8831, 55.0836],
              [-2.9223, 55.0717],
              [-2.9538, 55.041],
              [-3.015, 55.0515],
              [-3.0503, 54.9815],
              [-3.1354, 54.9681],
              [-3.4538, 54.9818],
              [-3.501, 54.9925],
              [-3.5419, 54.9807],
              [-3.5705, 54.9954],
              [-3.5836, 54.9681],
              [-3.5706, 54.9376],
              [-3.5705, 54.8998],
              [-3.5984, 54.878],
              [-3.7176, 54.8808],
              [-3.7392, 54.8598],
              [-3.8443, 54.8663],
              [-3.8579, 54.8445],
              [-3.8238, 54.8247],
              [-3.8857, 54.8065],
              [-3.9642, 54.7719],
              [-4.0429, 54.7695],
              [-4.05, 54.8232],
              [-4.0786, 54.8241],
              [-4.0935, 54.7927],
              [-4.123, 54.773],
              [-4.1739, 54.7923],
              [-4.2091, 54.8262],
              [-4.2397, 54.843],
              [-4.2707, 54.8394],
              [-4.3453, 54.8602],
              [-4.3868, 54.9048],
              [-4.409, 54.903],
              [-4.4265, 54.8695],
              [-4.4029, 54.8247],
              [-4.3476, 54.8073],
              [-4.3581, 54.771],
              [-4.3481, 54.702],
              [-4.3719, 54.6823],
              [-4.4053, 54.6821],
              [-4.4612, 54.7012],
              [-4.5056, 54.7047],
              [-4.5636, 54.7564],
              [-4.5967, 54.7753],
              [-4.7145, 54.8179],
              [-4.7811, 54.8331],
              [-4.7996, 54.8613],
              [-4.8591, 54.8663],
              [-4.9059, 54.8498],
              [-4.9537, 54.81],
              [-4.9479, 54.7773],
              [-4.9302, 54.7626],
              [-4.896, 54.7045],
              [-4.8673, 54.6901],
              [-4.8806, 54.6362],
              [-4.9441, 54.6528],
              [-4.9599, 54.6755],
              [-4.9473, 54.7012],
              [-4.9547, 54.7285],
              [-4.9882, 54.7353],
              [-5.0033, 54.7713],
              [-5.0332, 54.7824],
              [-5.1282, 54.8489],
              [-5.1571, 54.8791],
              [-5.1755, 54.9146],
              [-5.1807, 54.9544],
              [-5.1708, 55.0087],
              [-5.1052, 55.0262],
              [-5.0646, 54.9755],
              [-5.0609, 54.9311],
              [-5.0131, 54.9096],
              [-4.9882, 54.9346],
              [-5.0241, 54.9764],
              [-5.047, 55.0151],
              [-5.0497, 55.049],
              [-5.019, 55.1022],
              [-5.0078, 55.1412],
              [-4.9473, 55.1679],
              [-4.8824, 55.2186],
              [-4.8419, 55.2751],
              [-4.8325, 55.3311],
              [-4.7731, 55.3694],
              [-4.7493, 55.4138],
              [-4.717, 55.4305],
              [-4.6505, 55.4485],
              [-4.6263, 55.4684],
              [-4.6178, 55.4956],
              [-4.6268, 55.5197],
              [-4.6508, 55.5371],
              [-4.6872, 55.5441],
              [-4.6662, 55.5622],
              [-4.6711, 55.5844],
              [-4.722, 55.6192],
              [-4.8181, 55.6372],
              [-4.8106, 55.654],
              [-4.8686, 55.6756],
              [-4.9205, 55.7086],
              [-4.8763, 55.7353],
              [-4.8611, 55.7567],
              [-4.8653, 55.7905],
              [-4.8827, 55.8214],
              [-4.8926, 55.8929],
              [-4.8721, 55.9372],
              [-4.8181, 55.9556],
              [-4.7858, 55.957],
              [-4.7223, 55.9401],
              [-4.506, 55.9191],
              [-4.4817, 55.9283],
              [-4.63, 55.9463],
              [-4.6735, 55.9619],
              [-4.7245, 55.9984],
              [-4.7902, 56.0102],
              [-4.7853, 55.984],
              [-4.828, 55.9823],
              [-4.8623, 56.0021],
              [-4.8654, 56.0667],
              [-4.8263, 56.1242],
              [-4.7554, 56.1884],
              [-4.7799, 56.1915],
              [-4.8517, 56.1126],
              [-4.8806, 56.1199],
              [-4.879, 56.1505],
              [-4.8935, 56.1743],
              [-4.9205, 56.1679],
              [-4.8862, 56.0804],
              [-4.9044, 56.0543],
              [-4.898, 56.0012],
              [-4.9289, 55.9811],
              [-4.9174, 55.9475],
              [-4.9473, 55.9208],
              [-4.9592, 55.8901],
              [-4.9831, 55.871],
              [-5.0264, 55.8737],
              [-5.0568, 55.9103],
              [-5.0708, 55.9587],
              [-5.1238, 55.9863],
              [-5.0782, 55.911],
              [-5.1056, 55.905],
              [-5.1715, 55.9355],
              [-5.2126, 55.9171],
              [-5.2421, 55.8929],
              [-5.2181, 55.8647],
              [-5.2194, 55.8314],
              [-5.3071, 55.8604],
              [-5.3134, 55.8854],
              [-5.3348, 55.8967],
              [-5.3383, 55.9314],
              [-5.3258, 55.9672],
              [-5.3383, 55.9966],
              [-5.3136, 56.0124],
              [-5.2045, 56.1195],
              [-5.1465, 56.1331],
              [-5.095, 56.1574],
              [-5.0434, 56.2163],
              [-5.0646, 56.2293],
              [-5.1089, 56.1747],
              [-5.2281, 56.1292],
              [-5.3109, 56.0581],
              [-5.3457, 56.0512],
              [-5.3686, 56.0094],
              [-5.4476, 56.0094],
              [-5.455, 55.9556],
              [-5.4308, 55.9482],
              [-5.4072, 55.8663],
              [-5.3518, 55.8324],
              [-5.3268, 55.8086],
              [-5.3178, 55.783],
              [-5.3385, 55.7626],
              [-5.3876, 55.7503],
              [-5.4335, 55.721],
              [-5.4612, 55.6881],
              [-5.4686, 55.654],
              [-5.4901, 55.6446],
              [-5.4839, 55.6101],
              [-5.455, 55.5919],
              [-5.4888, 55.5691],
              [-5.4823, 55.5503],
              [-5.5621, 55.4199],
              [-5.515, 55.3737],
              [-5.558, 55.3319],
              [-5.5941, 55.3131],
              [-5.64, 55.3039],
              [-5.6691, 55.3109],
              [-5.7256, 55.2971],
              [-5.7723, 55.3013],
              [-5.7947, 55.3564],
              [-5.7914, 55.3986],
              [-5.7156, 55.4479],
              [-5.7219, 55.5139],
              [-5.7078, 55.5434],
              [-5.7218, 55.5646],
              [-5.6733, 55.6406],
              [-5.6809, 55.6745],
              [-5.6265, 55.7006],
              [-5.5812, 55.7576],
              [-5.5875, 55.7672],
              [-5.6467, 55.7875],
              [-5.668, 55.811],
              [-5.6741, 55.8458],
              [-5.632, 55.8818],
              [-5.6809, 55.8867],
              [-5.6964, 55.916],
              [-5.5868, 56.012],
              [-5.6317, 56.0059],
              [-5.6749, 55.9777],
              [-5.6964, 55.9822],
              [-5.6599, 56.0233],
              [-5.5846, 56.0847],
              [-5.5505, 56.1064],
              [-5.5587, 56.1308],
              [-5.5225, 56.1658],
              [-5.5399, 56.1834],
              [-5.5827, 56.1506],
              [-5.5994, 56.1606],
              [-5.5505, 56.2163],
              [-5.5472, 56.2402],
              [-5.4947, 56.2531],
              [-5.5127, 56.2709],
              [-5.5981, 56.2586],
              [-5.5726, 56.3281],
              [-5.5375, 56.3597],
              [-5.5096, 56.4006],
              [-5.457, 56.4409],
              [-5.4236, 56.45],
              [-5.3229, 56.455],
              [-5.2421, 56.4422],
              [-5.2438, 56.4592],
              [-5.3502, 56.473],
              [-5.3943, 56.4655],
              [-5.4106, 56.5036],
              [-5.3793, 56.5192],
              [-5.4109, 56.5521],
              [-5.3519, 56.6129],
              [-5.2984, 56.6464],
              [-5.3178, 56.6613],
              [-5.2868, 56.675],
              [-5.2482, 56.6741],
              [-5.2189, 56.6887],
              [-5.1606, 56.6959],
              [-5.2383, 56.7166],
              [-5.2221, 56.7415],
              [-5.1547, 56.7823],
              [-5.1791, 56.7893],
              [-5.2023, 56.7691],
              [-5.2335, 56.7607],
              [-5.2421, 56.7365],
              [-5.2685, 56.7131],
              [-5.3402, 56.6931],
              [-5.3881, 56.6552],
              [-5.4344, 56.6429],
              [-5.4487, 56.6246],
              [-5.4933, 56.6064],
              [-5.5334, 56.5729],
              [-5.5996, 56.529],
              [-5.6674, 56.4985],
              [-5.6947, 56.5139],
              [-5.7703, 56.5378],
              [-5.8544, 56.5505],
              [-6.0034, 56.6187],
              [-6.0082, 56.6423],
              [-5.9835, 56.6528],
              [-5.9336, 56.6547],
              [-5.8669, 56.6415],
              [-5.8658, 56.6613],
              [-5.76, 56.7008],
              [-5.8968, 56.6751],
              [-5.9556, 56.6887],
              [-6.0249, 56.6857],
              [-6.0986, 56.697],
              [-6.1617, 56.6782],
              [-6.2228, 56.6955],
              [-6.2153, 56.7296],
              [-6.1805, 56.7546],
              [-6.027, 56.7637],
              [-5.9799, 56.7816],
              [-5.9482, 56.7825],
              [-5.9136, 56.7637],
              [-5.8609, 56.7508],
              [-5.8684, 56.7759],
              [-5.8021, 56.7918],
              [-5.8508, 56.818],
              [-5.8375, 56.8366],
              [-5.7339, 56.8448],
              [-5.7839, 56.8599],
              [-5.7355, 56.8941],
              [-5.7806, 56.8987],
              [-5.8749, 56.8872],
              [-5.8522, 56.9077],
              [-5.8625, 56.9337],
              [-5.8317, 56.9971],
              [-5.786, 57.0206],
              [-5.7293, 57.0238],
              [-5.6883, 56.9897],
              [-5.6256, 56.9766],
              [-5.6071, 56.9885],
              [-5.6855, 57.0096],
              [-5.68, 57.0362],
              [-5.702, 57.0449],
              [-5.7525, 57.0406],
              [-5.7911, 57.0593],
              [-5.7156, 57.1201],
              [-5.6737, 57.119],
              [-5.6459, 57.1295],
              [-5.5583, 57.1004],
              [-5.5846, 57.1337],
              [-5.6684, 57.1477],
              [-5.6738, 57.175],
              [-5.6256, 57.2163],
              [-5.6442, 57.2332],
              [-5.6201, 57.2521],
              [-5.5437, 57.2709],
              [-5.4801, 57.237],
              [-5.4488, 57.2421],
              [-5.5019, 57.2777],
              [-5.4407, 57.3193],
              [-5.465, 57.3249],
              [-5.5062, 57.2982],
              [-5.5585, 57.2814],
              [-5.5994, 57.2792],
              [-5.657, 57.2913],
              [-5.7206, 57.2845],
              [-5.7293, 57.2982],
              [-5.702, 57.3323],
              [-5.6544, 57.354],
              [-5.6132, 57.342],
              [-5.5013, 57.371],
              [-5.4567, 57.3935],
              [-5.4651, 57.4239],
              [-5.5375, 57.3739],
              [-5.5635, 57.3631],
              [-5.6109, 57.3714],
              [-5.6092, 57.3978],
              [-5.64, 57.408],
              [-5.7072, 57.3598],
              [-5.7846, 57.3486],
              [-5.8078, 57.3549],
              [-5.8249, 57.3944],
              [-5.8253, 57.4289],
              [-5.8112, 57.4422],
              [-5.859, 57.4527],
              [-5.8697, 57.5009],
              [-5.8522, 57.5241],
              [-5.8392, 57.5793],
              [-5.7864, 57.5752],
              [-5.6898, 57.5279],
              [-5.5815, 57.5384],
              [-5.5431, 57.5554],
              [-5.6327, 57.5577],
              [-5.6604, 57.5446],
              [-5.7142, 57.5827],
              [-5.7628, 57.634],
              [-5.8059, 57.6426],
              [-5.7908, 57.696],
              [-5.7345, 57.708],
              [-5.705, 57.6901],
              [-5.6852, 57.7114],
              [-5.7205, 57.7334],
              [-5.8038, 57.7438],
              [-5.8013, 57.8004],
              [-5.8074, 57.8439],
              [-5.7895, 57.862],
              [-5.7248, 57.8735],
              [-5.6741, 57.8605],
              [-5.6748, 57.8271],
              [-5.6505, 57.7818],
              [-5.6179, 57.7802],
              [-5.5912, 57.8044],
              [-5.5853, 57.8314],
              [-5.6376, 57.8664],
              [-5.6536, 57.8947],
              [-5.6126, 57.9288],
              [-5.5873, 57.9151],
              [-5.5505, 57.9151],
              [-5.5563, 57.8878],
              [-5.5279, 57.8676],
              [-5.487, 57.8559],
              [-5.449, 57.8691],
              [-5.4371, 57.8977],
              [-5.4149, 57.9081],
              [-5.3695, 57.8975],
              [-5.2939, 57.862],
              [-5.2768, 57.8742],
              [-5.3207, 57.8985],
              [-5.3849, 57.9141],
              [-5.3973, 57.9289],
              [-5.3662, 57.9431],
              [-5.2974, 57.9083],
              [-5.2563, 57.9151],
              [-5.1988, 57.8978],
              [-5.1623, 57.8785],
              [-5.0972, 57.8709],
              [-5.1973, 57.9179],
              [-5.2045, 57.9602],
              [-5.2926, 57.9806],
              [-5.3669, 58.0289],
              [-5.4276, 58.0312],
              [-5.4139, 58.0523],
              [-5.447, 58.0844],
              [-5.4281, 58.098],
              [-5.3443, 58.0755],
              [-5.3008, 58.0701],
              [-5.2768, 58.0864],
              [-5.2905, 58.1206],
              [-5.2751, 58.1545],
              [-5.3315, 58.182],
              [-5.3468, 58.2069],
              [-5.3929, 58.2447],
              [-5.3759, 58.2642],
              [-5.3108, 58.2434],
              [-5.277, 58.2434],
              [-5.2373, 58.2576],
              [-5.1116, 58.2683],
              [-5.0677, 58.2509],
              [-4.982, 58.2509],
              [-5.0099, 58.2714],
              [-5.0501, 58.2599],
              [-5.0964, 58.2768],
              [-5.1601, 58.3266],
              [-5.1732, 58.354],
              [-5.1465, 58.395],
              [-5.0762, 58.4145],
              [-5.1055, 58.4365],
              [-5.0501, 58.4538],
              [-5.1055, 58.4874],
              [-5.1037, 58.5142],
              [-5.028, 58.5441],
              [-5.005, 58.6177],
              [-4.9882, 58.6283],
              [-4.8595, 58.6132],
              [-4.7858, 58.5771],
              [-4.7589, 58.5991],
              [-4.728, 58.5775],
              [-4.66, 58.5559],
              [-4.6673, 58.532],
              [-4.7145, 58.5056],
              [-4.7424, 58.4495],
              [-4.683, 58.4846],
              [-4.6669, 58.5029],
              [-4.6121, 58.5246],
              [-4.5849, 58.5742],
              [-4.5605, 58.5799],
              [-4.4755, 58.5656],
              [-4.4265, 58.5464],
              [-4.4259, 58.4991],
              [-4.3824, 58.5246],
              [-4.317, 58.5457],
              [-4.2573, 58.528],
              [-4.2097, 58.5529],
              [-4.1606, 58.5626],
              [-4.0815, 58.5618],
              [-4.0488, 58.5896],
              [-4.0038, 58.5706],
              [-3.8957, 58.5656],
              [-3.8101, 58.5731],
              [-3.767, 58.5837],
              [-3.6599, 58.6208],
              [-3.5655, 58.6266],
              [-3.5321, 58.6021],
              [-3.447, 58.614],
              [-3.3641, 58.6008],
              [-3.3508, 58.6208],
              [-3.3861, 58.6317],
              [-3.4129, 58.6556],
              [-3.3583, 58.6755],
              [-3.3479, 58.652],
              [-3.3099, 58.6481],
              [-3.2041, 58.6611],
              [-3.1519, 58.6413],
              [-3.0529, 58.6481],
              [-3.0191, 58.6403],
              [-3.0492, 58.5867],
              [-3.1293, 58.5126],
              [-3.1187, 58.4844],
              [-3.0644, 58.483],
              [-3.0533, 58.4658],
              [-3.0771, 58.4495],
              [-3.0863, 58.4142],
              [-3.1298, 58.3693],
              [-3.2205, 58.3056],
              [-3.2623, 58.2926],
              [-3.3498, 58.2761],
              [-3.4381, 58.236],
              [-3.4735, 58.1934],
              [-3.5153, 58.1684],
              [-3.735, 58.0722],
              [-3.8275, 58.0421],
              [-3.8606, 57.9989],
              [-3.9173, 57.9875],
              [-3.991, 57.9604],
              [-4.0301, 57.9532],
              [-3.9883, 57.9083],
              [-4.0251, 57.8681],
              [-4.0702, 57.8742],
              [-4.105, 57.8537],
              [-4.1329, 57.8542],
              [-4.2182, 57.8742],
              [-4.2989, 57.8628],
              [-4.2694, 57.8469],
              [-4.2082, 57.8592],
              [-4.1735, 57.839],
              [-4.1391, 57.8469],
              [-4.0751, 57.8232],
              [-4.0399, 57.8182],
              [-3.9835, 57.8424],
              [-3.9515, 57.8383],
              [-3.9148, 57.8077],
              [-3.8777, 57.8167],
              [-3.7985, 57.8665],
              [-3.7794, 57.8557],
              [-3.79, 57.8307],
              [-3.926, 57.7348],
              [-3.9883, 57.696],
              [-4.0237, 57.6978],
              [-4.0162, 57.7233],
              [-4.043, 57.7373],
              [-4.078, 57.7303],
              [-4.1553, 57.6926],
              [-4.2593, 57.6787],
              [-4.2948, 57.6802],
              [-4.3088, 57.6549],
              [-4.4032, 57.6095],
              [-4.3799, 57.5893],
              [-4.3287, 57.6263],
              [-4.2445, 57.663],
              [-4.2019, 57.675],
              [-4.169, 57.6638],
              [-4.0968, 57.6581],
              [-4.0246, 57.6872],
              [-4.0045, 57.676],
              [-4.0542, 57.6357],
              [-4.0834, 57.6234],
              [-4.1186, 57.593],
              [-4.208, 57.5521],
              [-4.208, 57.5384],
              [-4.2489, 57.4975],
              [-4.1948, 57.4916],
              [-4.1493, 57.5179],
              [-4.1162, 57.5218],
              [-4.0673, 57.55],
              [-4.035, 57.5569],
              [-4.056, 57.5852],
              [-3.9643, 57.593],
              [-3.8683, 57.5879],
              [-3.8375, 57.593],
              [-3.6848, 57.6544],
              [-3.6252, 57.6619],
              [-3.6325, 57.6356],
              [-3.591, 57.6414],
              [-3.5748, 57.6611],
              [-3.5358, 57.6619],
              [-3.5054, 57.6787],
              [-3.4918, 57.7131],
              [-3.4607, 57.7097],
              [-3.4092, 57.7233],
              [-3.2847, 57.7204],
              [-3.2101, 57.694],
              [-3.0787, 57.6694],
              [-2.9945, 57.6755],
              [-2.9161, 57.7028],
              [-2.758, 57.7028],
              [-2.7311, 57.6885],
              [-2.6589, 57.6975],
              [-2.5734, 57.6815],
              [-2.498, 57.6824],
              [-2.4329, 57.6681],
              [-2.3981, 57.6681],
              [-2.3605, 57.6824],
              [-2.2743, 57.6938],
              [-2.21, 57.6755],
              [-2.1841, 57.6785],
              [-2.1081, 57.705],
              [-1.9977, 57.7028],
              [-1.9735, 57.6755],
              [-1.9325, 57.6824],
              [-1.9142, 57.6647],
              [-1.8295, 57.6135],
              [-1.8, 57.5259],
              [-1.7594, 57.4736],
              [-1.8301, 57.4262],
              [-1.85, 57.3944],
              [-1.9545, 57.3411],
              [-1.9803, 57.3193],
              [-2.0447, 57.2265],
              [-2.0685, 57.1746],
              [-2.0714, 57.1356],
              [-2.0493, 57.1188],
              [-2.0658, 57.0998],
              [-2.1562, 57.0207],
              [-2.1869, 56.9847],
              [-2.2001, 56.9487],
              [-2.1864, 56.9145],
              [-2.2199, 56.8726],
              [-2.269, 56.8463],
              [-2.3361, 56.7908],
              [-2.3918, 56.7712],
              [-2.4407, 56.7348],
              [-2.4516, 56.691],
              [-2.4875, 56.6539],
              [-2.4806, 56.6265],
              [-2.53, 56.5808],
              [-2.6238, 56.5434],
              [-2.6409, 56.5224],
              [-2.6998, 56.5036],
              [-2.7277, 56.4696],
              [-2.7648, 56.4757],
              [-3.0606, 56.4517],
              [-3.1339, 56.4269],
              [-3.2382, 56.3677],
              [-3.1496, 56.3756],
              [-2.9503, 56.4319],
              [-2.9307, 56.4481],
              [-2.885, 56.458],
              [-2.8501, 56.442],
              [-2.8148, 56.4404],
              [-2.8009, 56.4089],
              [-2.8216, 56.3762],
              [-2.8028, 56.3454],
              [-2.7686, 56.3323],
              [-2.6772, 56.3284],
              [-2.5768, 56.2839],
              [-2.6301, 56.2485],
              [-2.6483, 56.2287],
              [-2.7202, 56.2163],
              [-2.7836, 56.192],
              [-2.8307, 56.1907],
              [-2.8921, 56.2089],
              [-2.9643, 56.2056],
              [-2.9824, 56.1891],
              [-3.0356, 56.1679],
              [-3.0911, 56.1323],
              [-3.1276, 56.1229],
              [-3.1522, 56.0785],
              [-3.1784, 56.0581],
              [-3.2484, 56.056],
              [-3.3427, 56.0272],
              [-3.421, 56.0249],
              [-3.5826, 56.0517],
              [-3.6715, 56.0508],
              [-3.7454, 56.0722],
              [-3.817, 56.1122],
              [-3.8375, 56.1064],
              [-3.7609, 56.0725],
              [-3.7126, 56.029],
              [-3.6866, 56.0307],
              [-3.6562, 56.0102],
              [-3.5774, 56.017],
              [-3.4054, 55.9897],
              [-3.331, 55.9941],
              [-3.3035, 55.9776],
              [-3.1218, 55.9693],
              [-3.0907, 55.9482],
              [-3.0157, 55.9505],
              [-2.9399, 55.9693],
              [-2.8829, 56.0085],
              [-2.871, 56.0307],
              [-2.8028, 56.0648],
              [-2.6351, 56.0581],
              [-2.5694, 56.0245],
              [-2.5836, 56.0028],
              [-2.5079, 56.0102],
              [-2.4525, 55.9853],
              [-2.4144, 55.9783],
              [-2.3509, 55.9482],
              [-2.3078, 55.935],
              [-2.1468, 55.9173],
              [-2.1288, 55.8898],
              [-2.0802, 55.8694],
              [-2.0229, 55.8055]
            ]
          ],
          [
            [
              [-6.1955, 58.3563],
              [-6.1888, 58.3408],
              [-6.25, 58.313],
              [-6.2538, 58.2934],
              [-6.3179, 58.2683],
              [-6.3183, 58.2447],
              [-6.3477, 58.2211],
              [-6.3109, 58.215],
              [-6.2432, 58.2231],
              [-6.1948, 58.2509],
              [-6.1539, 58.2447],
              [-6.16, 58.2168],
              [-6.2153, 58.1894],
              [-6.2474, 58.1855],
              [-6.2936, 58.1994],
              [-6.3382, 58.1963],
              [-6.4003, 58.2099],
              [-6.3661, 58.1621],
              [-6.373, 58.1411],
              [-6.4412, 58.1274],
              [-6.4747, 58.1052],
              [-6.5238, 58.1069],
              [-6.5813, 58.079],
              [-6.394, 58.1018],
              [-6.3524, 58.0386],
              [-6.4009, 58.0073],
              [-6.4245, 58.0039],
              [-6.5182, 58.0126],
              [-6.5051, 57.9925],
              [-6.4555, 57.9765],
              [-6.4897, 57.9431],
              [-6.5375, 57.9213],
              [-6.5784, 57.9172],
              [-6.6512, 57.9238],
              [-6.6998, 57.9762],
              [-6.7161, 58.025],
              [-6.764, 58.0039],
              [-6.7301, 57.963],
              [-6.674, 57.9288],
              [-6.661, 57.8803],
              [-6.7429, 57.8913],
              [-6.7777, 57.9021],
              [-6.8056, 57.8803],
              [-6.764, 57.8679],
              [-6.7367, 57.8394],
              [-6.8391, 57.8127],
              [-6.8875, 57.7848],
              [-6.894, 57.7665],
              [-6.9808, 57.7351],
              [-7.1019, 57.8115],
              [-7.0793, 57.8127],
              [-6.9623, 57.8688],
              [-6.9626, 57.8947],
              [-6.8391, 57.9288],
              [-6.8971, 57.9356],
              [-6.9521, 57.9567],
              [-7.0363, 57.9606],
              [-7.1128, 57.9902],
              [-7.0718, 58.0119],
              [-7.035, 58.0664],
              [-7.0756, 58.0622],
              [-7.1265, 58.124],
              [-7.1128, 58.182],
              [-7.0377, 58.1846],
              [-7.0561, 58.2257],
              [-7.0309, 58.2447],
              [-6.962, 58.2302],
              [-6.8971, 58.1721],
              [-6.8695, 58.1894],
              [-6.7985, 58.2024],
              [-6.7485, 58.1829],
              [-6.7526, 58.2248],
              [-6.7838, 58.2304],
              [-6.8186, 58.2851],
              [-6.737, 58.3236],
              [-6.7156, 58.3397],
              [-6.6207, 58.3507],
              [-6.5575, 58.3686],
              [-6.5233, 58.3908],
              [-6.3456, 58.4632],
              [-6.2674, 58.512],
              [-6.2432, 58.511],
              [-6.2054, 58.4813],
              [-6.1669, 58.4195],
              [-6.1924, 58.4091],
              [-6.2153, 58.3676],
              [-6.1955, 58.3563]
            ]
          ],
          [
            [
              [-6.8612, 58.2534],
              [-6.802, 58.2261],
              [-6.7912, 58.2099],
              [-6.8733, 58.2099],
              [-6.8862, 58.2476],
              [-6.8612, 58.2534]
            ]
          ],
          [
            [
              [-6.3109, 57.7028],
              [-6.2605, 57.6686],
              [-6.2327, 57.6414],
              [-6.188, 57.6209],
              [-6.1539, 57.5895],
              [-6.1361, 57.4613],
              [-6.1525, 57.4175],
              [-6.1179, 57.3978],
              [-6.1401, 57.3739],
              [-6.1057, 57.3397],
              [-6.1066, 57.3235],
              [-6.0454, 57.3083],
              [-6.0509, 57.2792],
              [-6.0085, 57.2868],
              [-5.9767, 57.2703],
              [-5.9232, 57.2669],
              [-5.9006, 57.2429],
              [-5.8658, 57.2571],
              [-5.8243, 57.2562],
              [-5.7958, 57.2718],
              [-5.7385, 57.2773],
              [-5.7218, 57.2646],
              [-5.6474, 57.2646],
              [-5.6591, 57.2225],
              [-5.6909, 57.1895],
              [-5.7423, 57.1684],
              [-5.7821, 57.1662],
              [-5.8004, 57.1237],
              [-5.8426, 57.108],
              [-5.9189, 57.0501],
              [-5.9534, 57.0341],
              [-6.0067, 57.0284],
              [-6.0304, 57.0449],
              [-6.0002, 57.0839],
              [-6.003, 57.0996],
              [-5.9618, 57.1422],
              [-5.9009, 57.1755],
              [-5.9862, 57.1746],
              [-6.0235, 57.1957],
              [-6.044, 57.161],
              [-6.0738, 57.1318],
              [-6.1014, 57.1352],
              [-6.1129, 57.1883],
              [-6.3183, 57.161],
              [-6.2767, 57.1957],
              [-6.3131, 57.1999],
              [-6.3456, 57.1883],
              [-6.3798, 57.2231],
              [-6.4555, 57.2777],
              [-6.4822, 57.3118],
              [-6.4164, 57.3345],
              [-6.394, 57.3323],
              [-6.3376, 57.3015],
              [-6.3239, 57.3114],
              [-6.4062, 57.3516],
              [-6.4486, 57.3603],
              [-6.4789, 57.3819],
              [-6.5621, 57.3955],
              [-6.5621, 57.3579],
              [-6.5806, 57.3388],
              [-6.6725, 57.3559],
              [-6.7148, 57.3766],
              [-6.7317, 57.417],
              [-6.7732, 57.4341],
              [-6.7838, 57.4564],
              [-6.7224, 57.4564],
              [-6.7471, 57.4879],
              [-6.7065, 57.5109],
              [-6.62, 57.4455],
              [-6.5995, 57.4694],
              [-6.6313, 57.4936],
              [-6.5994, 57.5375],
              [-6.6548, 57.5521],
              [-6.631, 57.5768],
              [-6.6198, 57.6072],
              [-6.5723, 57.5825],
              [-6.5641, 57.5588],
              [-6.5012, 57.5355],
              [-6.4649, 57.5125],
              [-6.4003, 57.521],
              [-6.373, 57.5316],
              [-6.3896, 57.5525],
              [-6.3719, 57.5919],
              [-6.4157, 57.6458],
              [-6.3599, 57.6681],
              [-6.3453, 57.6922],
              [-6.3109, 57.7028]
            ]
          ],
          [
            [
              [-7.1891, 57.6551],
              [-7.1426, 57.6581],
              [-7.0926, 57.6521],
              [-7.0651, 57.634],
              [-7.0992, 57.6073],
              [-7.1711, 57.6414],
              [-7.2021, 57.6135],
              [-7.1644, 57.6099],
              [-7.15, 57.5617],
              [-7.2433, 57.5561],
              [-7.2567, 57.5725],
              [-7.3121, 57.5521],
              [-7.2401, 57.5446],
              [-7.1813, 57.5539],
              [-7.1339, 57.5384],
              [-7.1575, 57.5111],
              [-7.2875, 57.5088],
              [-7.3185, 57.517],
              [-7.3512, 57.5537],
              [-7.4424, 57.5793],
              [-7.4709, 57.5672],
              [-7.5448, 57.593],
              [-7.5222, 57.6084],
              [-7.4766, 57.6619],
              [-7.4144, 57.6483],
              [-7.3966, 57.6353],
              [-7.3325, 57.6619],
              [-7.2779, 57.6496],
              [-7.256, 57.6669],
              [-7.1817, 57.6885],
              [-7.1654, 57.6699],
              [-7.1891, 57.6551]
            ]
          ],
          [
            [
              [-5.9825, 57.4975],
              [-6.0176, 57.4182],
              [-6.0167, 57.3876],
              [-5.9895, 57.3473],
              [-6.0548, 57.3264],
              [-6.0771, 57.3718],
              [-6.0616, 57.4527],
              [-6.0167, 57.4694],
              [-6.0371, 57.4899],
              [-5.9825, 57.4975]
            ]
          ],
          [
            [
              [-7.2925, 57.4853],
              [-7.2363, 57.4763],
              [-7.222, 57.4609],
              [-7.2497, 57.4352],
              [-7.2054, 57.4186],
              [-7.2921, 57.4047],
              [-7.3183, 57.408],
              [-7.3961, 57.434],
              [-7.4076, 57.4456],
              [-7.3893, 57.4799],
              [-7.3696, 57.4893],
              [-7.2925, 57.4853]
            ]
          ],
          [
            [
              [-7.353, 57.408],
              [-7.2636, 57.3739],
              [-7.2764, 57.3647],
              [-7.2226, 57.3398],
              [-7.2504, 57.3319],
              [-7.3316, 57.3609],
              [-7.3711, 57.3876],
              [-7.394, 57.3808],
              [-7.3705, 57.3551],
              [-7.322, 57.3466],
              [-7.2903, 57.3283],
              [-7.2438, 57.3255],
              [-7.2003, 57.291],
              [-7.2332, 57.2743],
              [-7.2711, 57.2163],
              [-7.2577, 57.1798],
              [-7.2711, 57.1479],
              [-7.236, 57.1212],
              [-7.2391, 57.1005],
              [-7.3217, 57.1099],
              [-7.353, 57.0996],
              [-7.3907, 57.1291],
              [-7.4167, 57.1816],
              [-7.4144, 57.2163],
              [-7.4554, 57.2367],
              [-7.435, 57.2519],
              [-7.41, 57.296],
              [-7.3897, 57.312],
              [-7.4281, 57.3876],
              [-7.353, 57.408]
            ]
          ],
          [
            [
              [-5.9254, 57.2917],
              [-5.9586, 57.2845],
              [-6.0078, 57.3036],
              [-5.99, 57.3255],
              [-5.9557, 57.3216],
              [-5.9254, 57.2917]
            ]
          ],
          [[[-6.5159, 57.0507], [-6.6064, 57.0517], [-6.5375, 57.0728], [-6.5159, 57.0507]]],
          [
            [
              [-6.2699, 57.0176],
              [-6.2432, 57.0102],
              [-6.2567, 56.9738],
              [-6.3036, 56.944],
              [-6.3598, 56.9473],
              [-6.3761, 56.9712],
              [-6.4555, 57.004],
              [-6.3642, 57.0557],
              [-6.3252, 57.0586],
              [-6.2729, 57.0402],
              [-6.2699, 57.0176]
            ]
          ],
          [
            [
              [-7.382, 56.9875],
              [-7.4424, 56.963],
              [-7.4766, 56.9419],
              [-7.5568, 56.9531],
              [-7.5165, 56.9812],
              [-7.5243, 57.0102],
              [-7.4571, 57.0316],
              [-7.4492, 57.0586],
              [-7.4144, 57.0449],
              [-7.435, 57.0238],
              [-7.394, 57.0102],
              [-7.382, 56.9875]
            ]
          ],
          [
            [
              [-6.5641, 56.5987],
              [-6.6163, 56.5752],
              [-6.6689, 56.5778],
              [-6.5914, 56.6518],
              [-6.5059, 56.6854],
              [-6.4525, 56.6884],
              [-6.4897, 56.6402],
              [-6.5273, 56.6265],
              [-6.5641, 56.5987]
            ]
          ],
          [
            [
              [-6.0335, 56.6188],
              [-6.0088, 56.5987],
              [-5.9521, 56.5242],
              [-5.8522, 56.5242],
              [-5.8295, 56.5108],
              [-5.7873, 56.5136],
              [-5.7652, 56.4942],
              [-5.7218, 56.4901],
              [-5.6809, 56.4518],
              [-5.6484, 56.4477],
              [-5.6703, 56.3938],
              [-5.7156, 56.4081],
              [-5.7839, 56.3734],
              [-5.7122, 56.3802],
              [-5.72, 56.3528],
              [-5.8087, 56.3189],
              [-5.8482, 56.3136],
              [-5.8801, 56.3256],
              [-5.8535, 56.3437],
              [-5.8972, 56.3529],
              [-5.9148, 56.3355],
              [-6.0375, 56.3052],
              [-6.066, 56.2892],
              [-6.1024, 56.2982],
              [-6.147, 56.2839],
              [-6.2398, 56.2839],
              [-6.2668, 56.2635],
              [-6.3275, 56.2772],
              [-6.3602, 56.3036],
              [-6.3661, 56.3222],
              [-6.3354, 56.3445],
              [-6.2904, 56.3454],
              [-6.2472, 56.3117],
              [-6.2318, 56.3369],
              [-6.1866, 56.3312],
              [-6.1539, 56.3448],
              [-6.085, 56.3529],
              [-6.0399, 56.371],
              [-6.0469, 56.384],
              [-6.1055, 56.3659],
              [-6.1789, 56.3599],
              [-6.2032, 56.3803],
              [-6.1572, 56.4074],
              [-6.1351, 56.4423],
              [-6.0815, 56.4484],
              [-6.0218, 56.4641],
              [-6.004, 56.4797],
              [-6.0167, 56.5036],
              [-6.1274, 56.4744],
              [-6.1621, 56.5092],
              [-6.1949, 56.5233],
              [-6.2885, 56.5323],
              [-6.3382, 56.5521],
              [-6.2904, 56.572],
              [-6.3252, 56.6061],
              [-6.2884, 56.608],
              [-6.1907, 56.6449],
              [-6.119, 56.6539],
              [-6.0583, 56.6402],
              [-6.0335, 56.6188]
            ]
          ],
          [
            [
              [-6.8816, 56.4443],
              [-6.9652, 56.4599],
              [-6.9899, 56.5036],
              [-6.9005, 56.5167],
              [-6.8411, 56.5347],
              [-6.7672, 56.5486],
              [-6.7292, 56.5303],
              [-6.7433, 56.5178],
              [-6.8038, 56.5136],
              [-6.8056, 56.4969],
              [-6.8693, 56.4796],
              [-6.8816, 56.4443]
            ]
          ],
          [[[-6.16, 56.4696], [-6.2375, 56.47], [-6.2637, 56.4832], [-6.2153, 56.5036], [-6.16, 56.4696]]],
          [[[-5.5028, 56.4211], [-5.5362, 56.3922], [-5.558, 56.4143], [-5.5028, 56.4211]]],
          [[[-5.5846, 56.3256], [-5.6217, 56.2761], [-5.6467, 56.2988], [-5.6208, 56.3242], [-5.5846, 56.3256]]],
          [
            [
              [-5.6126, 56.2635],
              [-5.6106, 56.2494],
              [-5.6476, 56.1954],
              [-5.6667, 56.2225],
              [-5.6453, 56.2559],
              [-5.6126, 56.2635]
            ]
          ],
          [[[-5.7252, 56.1628], [-5.7384, 56.1775], [-5.6883, 56.2021], [-5.6737, 56.1789], [-5.7252, 56.1628]]],
          [
            [
              [-5.7802, 56.0207],
              [-5.8968, 55.8889],
              [-5.9454, 55.8586],
              [-5.9621, 55.8048],
              [-6.0266, 55.7961],
              [-6.0624, 55.8095],
              [-6.0781, 55.8349],
              [-6.0869, 55.883],
              [-6.0752, 55.916],
              [-5.9974, 55.9517],
              [-5.9191, 55.9604],
              [-5.91, 55.9765],
              [-5.9807, 55.9699],
              [-5.9956, 55.986],
              [-5.9833, 56.0089],
              [-5.9244, 56.056],
              [-5.8737, 56.0701],
              [-5.7465, 56.1417],
              [-5.6945, 56.1474],
              [-5.6893, 56.1118],
              [-5.7402, 56.0765],
              [-5.7802, 56.0207]
            ]
          ],
          [
            [
              [-6.1334, 56.1126],
              [-6.1656, 56.0815],
              [-6.1743, 56.0438],
              [-6.2492, 56.0313],
              [-6.2543, 56.0446],
              [-6.2327, 56.0816],
              [-6.1818, 56.1195],
              [-6.1334, 56.1126]
            ]
          ],
          [
            [
              [-6.1024, 55.8312],
              [-6.0992, 55.801],
              [-6.0498, 55.7663],
              [-6.0583, 55.7427],
              [-6.0304, 55.7291],
              [-6.0235, 55.6819],
              [-6.0644, 55.6676],
              [-6.1055, 55.6402],
              [-6.16, 55.6267],
              [-6.2153, 55.6267],
              [-6.2228, 55.6055],
              [-6.2619, 55.5838],
              [-6.3112, 55.5832],
              [-6.3252, 55.6055],
              [-6.3192, 55.6282],
              [-6.2432, 55.6601],
              [-6.2771, 55.704],
              [-6.3252, 55.7154],
              [-6.321, 55.7335],
              [-6.25, 55.7768],
              [-6.3004, 55.7858],
              [-6.349, 55.7734],
              [-6.3866, 55.7223],
              [-6.4186, 55.7096],
              [-6.463, 55.6745],
              [-6.5035, 55.6813],
              [-6.4897, 55.7154],
              [-6.4932, 55.7392],
              [-6.4486, 55.7768],
              [-6.4608, 55.8032],
              [-6.4528, 55.8409],
              [-6.4091, 55.8636],
              [-6.3259, 55.8804],
              [-6.3382, 55.852],
              [-6.2291, 55.8973],
              [-6.2043, 55.9166],
              [-6.1401, 55.9414],
              [-6.1202, 55.9294],
              [-6.1024, 55.8312]
            ]
          ],
          [
            [
              [-5.0094, 55.7293],
              [-5.1156, 55.7844],
              [-5.1396, 55.842],
              [-5.1742, 55.8554],
              [-5.2008, 55.8955],
              [-5.1995, 55.9111],
              [-5.1669, 55.9208],
              [-5.1055, 55.8867],
              [-5.0809, 55.8796],
              [-5.0752, 55.8569],
              [-5.0503, 55.8458],
              [-5.0218, 55.8056],
              [-5.0115, 55.7745],
              [-5.0298, 55.7495],
              [-5.0094, 55.7293]
            ]
          ],
          [
            [
              [-5.1178, 55.4398],
              [-5.1636, 55.4349],
              [-5.2631, 55.4472],
              [-5.3457, 55.4827],
              [-5.3594, 55.5373],
              [-5.3457, 55.5503],
              [-5.3891, 55.5902],
              [-5.3979, 55.6142],
              [-5.3929, 55.6466],
              [-5.3552, 55.6896],
              [-5.2632, 55.7228],
              [-5.1983, 55.7043],
              [-5.16, 55.6762],
              [-5.126, 55.6055],
              [-5.1374, 55.592],
              [-5.0942, 55.5666],
              [-5.0919, 55.5441],
              [-5.1252, 55.5369],
              [-5.093, 55.5004],
              [-5.0782, 55.4553],
              [-5.1178, 55.4398]
            ]
          ]
        ]
      },
      properties: { id: "GB-SCT", name: "Scotland", TYPE: "Country", CNTRY: "United Kingdom" },
      id: "GB-SCT"
    }
  ]
};
export default map;
