import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GET_A1_14_CT = gql(`
  query GetEntriesCt( $lang: String, $jur_id: String) {
    entriesAdo(lang: $lang, jur_id: $jur_id) {
           id
    motherEntry {
        entry_type_id 
        topics {
          id
          name
        }
        jurisdiction {
          id
          name
          un_member
          a2_code
          regions{
            id
          }
        }
        subjurisdiction {
          id
          name
          slug
          label_longitude
          label_latitude
          label_font_size
        }
        footnotes {
          id
          order
          section
          content
        }
        sources {
          id
          order
          section
          original_official_filename
          original_filename
          translated_filename
          enacted
          original_official_language{
            name
            id
          }
          original_official_language2{
            name
            id
          }
          original_language{
            name
            id
          }
          original_language2{
            name
            id
          }
        }
      }
    map_type {
      name
      id
    }
    joint_adoption_type {
      name
      id
    }
    joint_critical_date_1
    joint_critical_date_2
    joint_mechanism {
      name
      id
    }
    joint_explan
    second_parent_adoption_type {
      name
      id
    }
    second_parent_critical_date_1
    second_parent_critical_date_2
    second_parent_mechanism {
      name
      id
    }
    second_parent_explan
    }
}
`);

function useGqlEntriesAdo(jur_id = null) {
  const { i18n } = useTranslation();
  const entriesAdo = useQuery(GET_A1_14_CT, {
    variables: { jur_id, lang: i18n.language }
  });

  return {
    entriesAdo: entriesAdo.data && entriesAdo.data.entriesAdo
  };
}

export default useGqlEntriesAdo;
