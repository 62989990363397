import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useGqlJurisdictions from '../../../gql/useGqlJurisdictions'

import DropdownJurs from '../Dropdown/DropdownJurs'

export default function JurFilter({ column }) {
  const { filterValue, setFilter } = column
  const [filterLoading, setFilterLoading] = useState(false)
  const { i18n, t } = useTranslation()
  let {
    jurisdictions,
    jurisdictionsById,
    loading,
    error,
    regions
  } = useGqlJurisdictions()

  const bufferFilter = (options, clear = false) => {
    //nsole.log("BBBB")
    if (options.length || clear) {
      setFilterLoading(true)
      setTimeout(() => {
        setFilter(options)
      }, 300)
    } else if (!options.length && filterValue && filterValue.length && !clear) {
      //When unchecking last one
      setFilterLoading(true)
      setTimeout(() => {
        setFilter(options)
      }, 300)
    } else {
      //if (filterValue && filterValue.length && !clear) {
      //TO FIX HIDE COLUMNS ISSUE
      //nsole.log("AAAA")

      setTimeout(() => setFilter(filterValue), 300)
    }
  }
  useEffect(() => {
    setFilterLoading(false)
  }, [filterValue])
  useEffect(() => {
    if (filterValue && filterValue.length) {
      setFilter(filterValue)
    }
  }, [column])

  useEffect(() => {
    //nsole.log('JUR FILTER RELOASDED')
  }, [])

  return (
    <div>
      {!loading && !error && jurisdictions && regions && (
        <DropdownJurs
          sortSelected={true}
          title={t('Jurisdictions')}
          //Items grouped
          items={{
            regions: regions.map(r => ({
              id: r.id,
              icon: (
                <img
                  src={'/images/region_flags/' + r.a2_code + '.png'}
                  height="12px"
                  alt={r.name + ' flag'}
                />
              ),
              value: r.name
            })),
            jurisdictions: jurisdictions.map(j => ({
              id: j.id,
              icon: (
                <img
                  src={'/images/flags_svg/' + j.a2_code.toLowerCase() + '.svg'}
                  height="12px"
                  className="svgFlag"
                  alt={j.name + ' flag'}
                />
              ),
              value: j.name
            }))
          }}
          jurisdictions={jurisdictions}
          regions={regions}
          loading={loading}
          error={error}
          multiSelect
          search={true}
          clearFilter={true}
          filterValue={filterValue}
          setTableFilter={bufferFilter}
          clearTableFilter={options => bufferFilter(options, true)}
        />
      )}
    </div>
  )
}

export function ApplyJurFilter(rows, columnsId, filterValue) {
  if (filterValue && filterValue.length > 0)
    return rows.filter(row => {
      const newRow = row.original.motherEntry
        ? row.original.motherEntry
        : row.original
      return (
        filterValue.indexOf(newRow.jurisdiction.id) !== -1 ||
        (newRow.jurisdictions &&
          newRow.jurisdictions.length &&
          filterValue.some(r =>
            newRow.jurisdictions.map(j => j.id).includes(r)
          ))
      )
    })
  else {
    return rows
  }
}

export function ApplyJurFilterIssuing(rows, columnsId, filterValue) {
  if (filterValue && filterValue.length > 0)
    return rows.filter(row => {
      return filterValue.indexOf(row.original.issuing_jurisdiction.id) !== -1
    })
  else {
    return rows
  }
}
