import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cMainRegions } from '../../../../config/constants'
import { isRecommendation } from '../../../../lib/fnAreasPRHelper'
import UNMemberSwitch from '../../Charts/UNMemberSwitch'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import LoadingBar from '../../ui/LoadingBar'

export default function LineChart2({ references }) {
  const [unMember, setUnMember] = useState(true)
  const [topicsN, setTopicsN] = useState(10)
  const { t, i18n } = useTranslation()
  const loading = useRef()
  const loadChart = () => {
    if (loading.current) loading.current.style.display = 'block'
    am4core.options.queue = true

    /* Chart code */
    // Themes begin
    am4core.unuseAllThemes()
    
    // Themes end

    // Create chart instance
    let chart = am4core.create('stackedChartDivX2', am4charts.XYChart)

    chart.numberFormatter.numberFormat = '#'

    chart.events.on('ready', () => {
      if (loading.current) loading.current.style.display = 'none'
    })

    let newReferences = []
    let newReferences2 = []
    let amount1 = 0
    let amount2 = 0
    let max = 0
    for (let i = 1998; i <= new Date().getFullYear(); i++) {
      let tmp = references
        .filter(r =>
          unMember ? r.entry.motherEntry.jurisdiction.un_member : true
        )
        .filter(e => {
          return (
            !e.entry.motherEntry.subjurisdiction &&
            e.entry.pnc_type.id === '2' &&
            e.entry.critical_date_1 &&
            (e.entry.critical_date_2
              ? e.entry.critical_date_2 == i
              : e.entry.critical_date_1 == i)
          )
        })
      amount1 += tmp.length
      if (max < amount1) max = amount1
      if (tmp.length) {
        newReferences.push({
          year: i,
          general: amount1,
          countries: tmp.length
            ? '<br/>' +
              tmp
                .map(
                  r =>
                    `<div style="margin-top:2px; font-weight: bold;" ><img
              style="display: inline; height: 12px; width: 15px; vertical-align: text-top; margin-right:5px; margin-left:10px; margin-bottom:4px;"

              class="svgFlag"
              src="/images/flags_svg/${r.entry.motherEntry.jurisdiction.a2_code.toLowerCase()}.svg"
            /> ` +
                    r.entry.motherEntry.jurisdiction.name +
                    `</div>`
                )
                .join('') +
              '<br/>'
            : '',
          disabled: tmp.length ? false : true
        })
      }

      let tmp2 = references
        .filter(r =>
          unMember ? r.entry.motherEntry.jurisdiction.un_member : true
        )
        .filter(e => {
          return (
            !e.entry.motherEntry.subjurisdiction &&
            e.entry.pnc_type.id === '4' &&
            e.entry.critical_date_1 &&
            (e.entry.critical_date_2
              ? e.entry.critical_date_2 == i
              : e.entry.critical_date_1 == i)
          )
        })
      amount2 += tmp2.length
      if (max < amount2) max = amount2
      if (tmp2.length) {
        newReferences2.push({
          year: i,
          general: amount2,
          countries: tmp2.length
            ? '<br/>' +
              tmp2
                .map(
                  r =>
                    `<div style="margin-top:2px; font-weight: bold;" ><img
              style="display: inline; height: 12px; width: 15px; vertical-align: text-top; margin-right:5px; margin-left:10px; margin-bottom:4px;"

              class="svgFlag"
              src="/images/flags_svg/${r.entry.motherEntry.jurisdiction.a2_code.toLowerCase()}.svg"
            /> ` +
                    r.entry.motherEntry.jurisdiction.name +
                    `</div>`
                )
                .join('') +
              '<br/>'
            : '',
          disabled: tmp2.length ? false : true
        })
      }
    }

    //chart.data = newReferences;

    chart.dateFormatter.inputDateFormat = 'yyyy'
    chart.maskBullets = false
    let dateAxis = chart.xAxes.push(new am4charts.ValueAxis())

    dateAxis.renderer.minGridDistance = 60
    dateAxis.startLocation = 0
    dateAxis.endLocation = 1
    dateAxis.baseInterval = {
      timeUnit: 'year',
      count: 1
    }

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())

    valueAxis.max = max + 1

    let series = chart.series.push(new am4charts.LineSeries())
    series.dataFields.valueX = 'year'
    series.name = t('Restrictions on interventions on intersex minors')

    series.data = newReferences
    series.stroke = am4core.color('#F0C85C')

    series.dataFields.valueY = 'general'
    series.tooltipHTML = '{countries}'
    series.tooltipText = '[#000]{valueY.value}[/]'

    series.tooltip.getFillFromObject = false
    series.tooltip.background.fill = am4core.color('#FFF')
    series.tooltip.background.fillOpacity = 1
    series.tooltip.label.fill = am4core.color('#575757')

    series.strokeWidth = 3

    let bullet = series.bullets.push(new am4charts.CircleBullet())
    bullet.circle.strokeWidth = 2
    bullet.circle.radius = 4
    bullet.circle.fill = am4core.color('#fff')

    let bullethover = bullet.states.create('hover')
    bullethover.properties.scale = 1.3

    chart.cursor = new am4charts.XYCursor()
    chart.cursor.snapToSeries = series
    chart.cursor.xAxis = dateAxis
    chart.cursor.maxTooltipDistance = -1
    chart.legend = new am4charts.Legend()
    //chart.scrollbarX = new am4core.Scrollbar();

    // Add a legend
    //chart.legend = new am4charts.Legend();
    //chart.legend.position = "top";
  }
  useEffect(() => {
    loadChart()
  }, [unMember])
  return (
    <>
      <div
        style={{
          minHeight: '468px',
          maxHeight: '468px',
          marginBottom: '10px',
          marginTop: '10px'
        }}
      >
        <LoadingBar ref={loading} text={t('Loading chart') + '...'} />
        <div id="stackedChartDivX2" style={{ minHeight: '465px' }} />
        <UNMemberSwitch unMember={unMember} setUnMember={setUnMember} />
      </div>
    </>
  )
}
