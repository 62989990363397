import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { getPath } from '../../../config/routes'

import './ToolsDropdown.css'

const ToolsDropdown = ({ section }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [activeItem, setActiveItem] = useState({})
  const { i18n, t } = useTranslation()
  const location = useLocation()

  const hideDropdown = () => {
    setShowDropdown(false)
  }

  const targets = [
    { name: t('Advanced Search'), path: getPath('advanced_search'), disabled: false },
    { name: t('Compare Tool'), path: getPath('compare_tool'), disabled: false }
  ]

  useEffect(() => {
    targets.forEach(item => {
      if (item.path === location.pathname) setActiveItem(item)
    })
  }, [])

  const dropdownLinks = targets.map((item, index) => {
    return (
      <Link
        to={item.path}
        key={item.name + index}
        className={`${activeItem.name === item.name ? 'activeArea' : ''} ${
          item.disabled ? 'disabled' : ''
        }`}
      >
        {item.name} {item.disabled && <em>({t('forthcoming')})</em>}
      </Link>
    )
  })

  return (
    <li
      className="td__container"
      onMouseOver={() => setShowDropdown(true)}
      onMouseLeave={hideDropdown}
    >
      <Link to="/tools" className={section === 'tools' ? 'active' : ''}>
        {t('Tools')}
      </Link>

      {showDropdown && (
        <div className={'toolsDropdown-container ' + i18n.language}>
          <div className="toolsDropdown">{dropdownLinks}</div>
        </div>
      )}
    </li>
  )
}

export default ToolsDropdown
