import React from "react";
const DatabaseLogo = () => {
  return (
    <svg
      className="ilgaDatabase menuLogo"
      xmlns="http://www.w3.org/2000/svg"
      width="184.293"
      height="32.71"
      viewBox="0 0 184.293 32.71"
    >
      <g id="Group_788" data-name="Group 788" transform="translate(-357.839 -43.348)">
        <path
          id="Path_663"
          data-name="Path 663"
          d="M254.412,158.977V147.961a.814.814,0,0,1,.946-.946h2.027a.814.814,0,0,1,.946.946v30.457a.814.814,0,0,1-.946.946h-2.027a.814.814,0,0,1-.946-.946v-1.735a11.851,11.851,0,0,1-7.929,3.041,11.915,11.915,0,0,1-8.516-20.3,11.637,11.637,0,0,1,8.516-3.491A11.851,11.851,0,0,1,254.412,158.977Zm-8,.879a7.847,7.847,0,1,0,5.7,2.3A7.7,7.7,0,0,0,246.415,159.856Z"
          transform="translate(123.318 -103.667)"
          fill="#707070"
        />
        <path
          id="Path_664"
          data-name="Path 664"
          d="M292.294,162.944v-1.78a.814.814,0,0,1,.946-.946h2.027a.813.813,0,0,1,.946.946v21.221a.814.814,0,0,1-.946.946H293.24a.814.814,0,0,1-.946-.946V180.65a12.139,12.139,0,0,1-7.93,3.041,11.915,11.915,0,0,1-8.516-20.3,11.638,11.638,0,0,1,8.516-3.491A12.139,12.139,0,0,1,292.294,162.944Zm-8,.879a7.848,7.848,0,1,0,5.7,2.3A7.706,7.706,0,0,0,284.3,163.823Z"
          transform="translate(111.659 -107.634)"
          fill="#707070"
        />
        <path
          id="Path_665"
          data-name="Path 665"
          d="M351.926,162.944v-1.78a.813.813,0,0,1,.946-.946H354.9a.813.813,0,0,1,.946.946v21.221a.814.814,0,0,1-.946.946h-2.028a.814.814,0,0,1-.946-.946V180.65a12.139,12.139,0,0,1-7.93,3.041,11.915,11.915,0,0,1-8.516-20.3A11.638,11.638,0,0,1,344,159.9,12.139,12.139,0,0,1,351.926,162.944Zm-8,.879a7.848,7.848,0,1,0,5.7,2.3A7.7,7.7,0,0,0,343.928,163.823Z"
          transform="translate(93.304 -107.634)"
          fill="#707070"
        />
        <path
          id="Path_666"
          data-name="Path 666"
          d="M375.1,176.683v1.735a.814.814,0,0,1-.946.946H372.13a.814.814,0,0,1-.946-.946V147.961a.814.814,0,0,1,.946-.946h2.028a.814.814,0,0,1,.946.946v11.016a11.854,11.854,0,0,1,7.93-3.041,11.562,11.562,0,0,1,8.493,3.491,11.905,11.905,0,0,1-8.493,20.3A11.909,11.909,0,0,1,375.1,176.683Zm7.863-16.828a7.627,7.627,0,0,0-5.565,2.3,8.1,8.1,0,1,0,5.565-2.3Z"
          transform="translate(81.254 -103.667)"
          fill="#707070"
        />
        <path
          id="Path_667"
          data-name="Path 667"
          d="M428.162,162.944v-1.78a.814.814,0,0,1,.946-.946h2.027a.814.814,0,0,1,.946.946v21.221a.814.814,0,0,1-.946.946h-2.027a.814.814,0,0,1-.946-.946V180.65a12.139,12.139,0,0,1-7.93,3.041,11.915,11.915,0,0,1-8.515-20.3,11.636,11.636,0,0,1,8.515-3.491A12.139,12.139,0,0,1,428.162,162.944Zm-8,.879a7.848,7.848,0,1,0,5.7,2.3A7.7,7.7,0,0,0,420.165,163.823Z"
          transform="translate(69.839 -107.634)"
          fill="#707070"
        />
        <path
          id="Path_668"
          data-name="Path 668"
          d="M447.589,179.7l1.667-1.171a.874.874,0,0,1,1.351.225,2.936,2.936,0,0,0,2.253,1.014,2.662,2.662,0,0,0,1.96-.788,2.444,2.444,0,0,0,.766-1.78q0-2.321-4.844-5.227a11.277,11.277,0,0,1-2.5-1.87,5.147,5.147,0,0,1-1.374-3.626,6.318,6.318,0,0,1,1.96-4.618,6.241,6.241,0,0,1,4.55-1.96,6.842,6.842,0,0,1,4.6,1.8,1.449,1.449,0,0,1,.631.991,1.016,1.016,0,0,1-.383.721l-1.622,1.4a.963.963,0,0,1-.631.316,1.16,1.16,0,0,1-.766-.451,2.7,2.7,0,0,0-1.96-.856,2.465,2.465,0,0,0-1.825.676,2.169,2.169,0,0,0-.631,1.6,2.125,2.125,0,0,0,.451,1.285,11.38,11.38,0,0,0,2.59,1.937,16.259,16.259,0,0,1,4.078,3.267,7.108,7.108,0,0,1,1.6,4.6,6.231,6.231,0,0,1-1.825,4.6,6.612,6.612,0,0,1-4.843,1.915,6.911,6.911,0,0,1-4.889-1.892,2.114,2.114,0,0,1-.856-1.352A.932.932,0,0,1,447.589,179.7Z"
          transform="translate(57.959 -107.634)"
          fill="#707070"
        />
        <path
          id="Path_669"
          data-name="Path 669"
          d="M473.021,173.375a8.208,8.208,0,0,0,2.658,4.55,8.024,8.024,0,0,0,5.316,1.8,12.432,12.432,0,0,0,6.172-1.487,1.119,1.119,0,0,1,.518-.18.8.8,0,0,1,.7.54l.766,1.555a1.28,1.28,0,0,1,.179.563q0,.4-.721.833a14.6,14.6,0,0,1-7.659,2.14,12.09,12.09,0,0,1-8.56-20.522,10.826,10.826,0,0,1,8-3.267,9.968,9.968,0,0,1,7.344,2.951,11.168,11.168,0,0,1,3.244,8.245v1.329a.814.814,0,0,1-.946.947ZM486.875,170a6.368,6.368,0,0,0-1.892-4.168,6.474,6.474,0,0,0-4.776-2,6.828,6.828,0,0,0-4.326,1.441A8.423,8.423,0,0,0,473.111,170Z"
          transform="translate(51.158 -107.634)"
          fill="#707070"
        />
        <path
          id="Path_670"
          data-name="Path 670"
          d="M322.57,157.789h-3.623v-6.15a.814.814,0,0,0-.946-.946h-2.028a.814.814,0,0,0-.946.946v6.15H311.36a.814.814,0,0,0-.946.946V160.4a.813.813,0,0,0,.946.946h3.622c0,.026-.008.044-.008.071v14.688a4.492,4.492,0,0,0,1.224,3.325,4.543,4.543,0,0,0,3.34,1.224H321.1q.877.015.849-.835v-1.8q.029-.864-.849-.849h-.5a1.464,1.464,0,0,1-1.627-1.685V161.418c0-.027-.008-.045-.008-.071h3.6a.814.814,0,0,0,.946-.946v-1.668A.815.815,0,0,0,322.57,157.789Z"
          transform="translate(99.959 -104.799)"
          fill="#707070"
        />
      </g>
    </svg>
  );
};

export default DatabaseLogo;
