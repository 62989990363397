import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePagination, useTable } from 'react-table'
import { AccountContext } from '../../../context/Accounts'
import ChevronIcon from '../icons/ChevronIcon'
import CiteIcon from '../icons/CiteIcon'
import ReportIcon from '../icons/ReportIcon'
import ReportErrorModal from './ReportErrorModal'
import { getPagination } from './Table'

export default function SimpleTable({
  entries = [],
  columns,
  citeEntry,
  extraClasses = { table: 'jurProfileTable' },
  paginate = false,
  withReport
}) {
  const theColumns = React.useMemo(() => columns, [columns])

  const theEntries = React.useMemo(() => entries, [entries])

  const [reportErrorOpen, setReportErrorOpen] = useState(false)

  const { i18n, t } = useTranslation()

  const refTable = useRef()

  const [numberOfPages, setNumberOfPages] = useState([])
  const { setToast } = useContext(AccountContext)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns: theColumns,
      data: theEntries,
      initialState: { pageSize: 100 }
    },
    usePagination
  )

  useEffect(() => {
    setNumberOfPages(getPagination(rows.length, pageSize, 1, pageIndex + 1))
  }, [pageOptions, pageIndex, pageSize])
  if (
    theEntries &&
    theEntries.length > 0 &&
    theColumns &&
    theColumns.length > 0
  )
    return (
      <>
        <div className={'tableContainer jurTableContainer'}>
          <div
            className={'tableArea1-1 tableArea1-1-big tableAreaSimpleTable'}
            ref={refTable}
          >
            <table
              className={'mainTable ' + extraClasses.table}
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={index}
                    className={'tr' + (headerGroups.length - (2 - index))}
                  >
                    {headerGroup.headers.map((column, index0) =>
                      column.id.indexOf('_placeholder') === -1 ? (
                        <th
                          {...column.getHeaderProps()}
                          key={index0}
                          style={column.style}
                        >
                          <div className="titleCellHeader">
                            {column.render('Header')}
                          </div>
                        </th>
                      ) : (
                        <th
                          {...column.getHeaderProps()}
                          style={{
                            backgroundColor: '#ecefec'
                          }}
                          key={index0}
                        />
                      )
                    )}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, indexRow) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} key={indexRow}>
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            key={index}
                            {...cell.getCellProps({
                              className:
                                (cell.column.className
                                  ? cell.column.className
                                  : '') +
                                ' ' +
                                (cell.column.classNameFn
                                  ? cell.column.classNameFn(cell)
                                  : '')
                            })}
                          >
                            {cell.render('Cell')}
                            {index === 0 && withReport && (
                              <div
                                id={'rowActions' + indexRow}
                                className="rowActions"
                              >
                                <span
                                  onClick={e => {
                                    citeEntry(row.original, setToast)
                                  }}
                                  className='citeIcon'
                                >
                                  <CiteIcon size={20} title={t('Cite Entry')} />
                                </span>

                                <span
                                className='reportIcon'
                                  onClick={e =>
                                    setReportErrorOpen(row.original)
                                  }
                                >
                                  <ReportIcon
                                    size={20}
                                    title={t('Report error or send comments')}
                                  />
                                </span>
                              </div>
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {paginate && numberOfPages.length > 1 && (
              <div className="tablePagination">
                <button
                  onClick={() => {
                    previousPage()
                    refTable.current.scrollTo(0, 0)
                  }}
                  disabled={!canPreviousPage}
                >
                  <ChevronIcon size={21} />
                </button>{' '}
                {numberOfPages.map((a, i) =>
                  Number.isInteger(a) ? (
                    <button
                      key={'butt' + a}
                      onClick={() => {
                        gotoPage(a - 1)
                        refTable.current.scrollTo(0, 0)
                      }}
                      disabled={pageIndex + 1 === a}
                      className={pageIndex + 1 === a ? 'active' : ''}
                    >
                      {a}
                    </button>
                  ) : (
                    <>{a}</>
                  )
                )}
                <button
                  onClick={() => {
                    nextPage()
                    refTable.current.scrollTo(0, 0)
                  }}
                  disabled={!canNextPage}
                >
                  <ChevronIcon size={21} />
                </button>{' '}
              </div>
            )}
          </div>
        </div>
        {withReport && (
          <ReportErrorModal
            isOpen={reportErrorOpen}
            setIsOpen={setReportErrorOpen}
          />
        )}
      </>
    )
  else return <></>
}
