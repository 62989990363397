import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GET_A3_TREATY_BODIES = gql(`
  query GetEntriesTreatyBodies($type: Int, $lang: String, $jur_id: String) {
    entriesTreatyBodies(type: $type, lang: $lang, jur_id: $jur_id) {
    id
    motherEntry {
        topics {
          id
          name
        }
        jurisdiction {
          id
          name
          un_member
          a2_code
          regions{
            id
          }
        }
        subjurisdiction {
          id
          name
        }
        sources {
          id
          order
          section
          original_official_filename
          original_filename
          translated_filename
          enacted
          original_official_language{
            name
            id
          }
          original_official_language2{
            name
            id
          }
          original_language{
            name
            id
          }
          original_language2{
            name
            id
          }
        }
      }
    treaty_body {
      id
      name
    }
    session
    date
    text
    is_auto_translate
    cycle
    para
    title
    un_symbol
    treaty_body_cu_type {
      id
      name
    }
    scope {
      id
      name
    }

  }
}
`);

function useGqlEntriesTreatyBodies(type, jur_id = null) {
  const { i18n } = useTranslation();
  const entries_treaty_bodies = useQuery(GET_A3_TREATY_BODIES, { variables: { type, jur_id, lang: i18n.language } });

  return {
    entriesTreatyBodies: entries_treaty_bodies.data && entries_treaty_bodies.data.entriesTreatyBodies,
    loading: entries_treaty_bodies.loading
  };
}

export default useGqlEntriesTreatyBodies;
