import i18n from '../../config/i18n'
import routes from '../../config/routes'
import { citeEntry } from '../../lib/cite'
import { explanSourceLinks } from '../../lib/fnHelper'
import { ApplyColumnFilter } from '../components/Table/ColumnFilter'
import DateDropdownFilter, {
  ApplyDropdownDateFilter
} from '../components/Table/DateDropdownFilter'
import ExpandableTextCell from '../components/Table/ExpandableTextCell'
import GenericDropdownFilter, {
  ApplyGenericDropdownFilter
} from '../components/Table/GenericDropdownFilter'
import JurFilter, { ApplyJurFilter } from '../components/Table/JurFilter'
import SourceText from '../components/Table/SourceText'

const AdoptionDropdown = ({ column }) => (
  <GenericDropdownFilter
    width="150px"
    column={column}
    options={[
      { id: i18n.t('Yes'), value: i18n.t('Yes') },
      { id: i18n.t('No'), value: i18n.t('No') },
      { id: i18n.t('Varies'), value: i18n.t('Varies') },
      { id: i18n.t('Unclear'), value: i18n.t('Unclear') }
    ]}
  />
)

const AdoptionDropdown2 = ({ column }) => (
  <GenericDropdownFilter
    width="150px"
    column={column}
    options={[
      { id: i18n.t('Yes'), value: i18n.t('Yes') },
      { id: i18n.t('No'), value: i18n.t('No') },
      { id: i18n.t('Varies'), value: i18n.t('Varies') },
      { id: i18n.t('Unclear'), value: i18n.t('Unclear') }
    ]}
  />
)

const getBgColorClass = (cell, col) => {
  if (!cell.row.original[col]) return ''
  return cell.row.original[col].id === '1'
    ? 'rowProtectionYes'
    : cell.row.original[col].id === '2'
    ? 'rowProtectionNo'
    : cell.row.original[col].id === '3'
    ? 'rowProtectionVaries'
    : 'rowProtectionUnclear'
}
export const citeA116 = (entry, setToast) => {
  let lines = [
    '<p>' +
      `${
        entry.motherEntry.subjurisdiction
          ? entry.motherEntry.subjurisdiction.name +
            ' (' +
            entry.motherEntry.jurisdiction.name +
            ')'
          : entry.motherEntry.jurisdiction.name
      }: ${
        routes().find(r => r.parent === 'area1' && r.subareaNumber === 16).name
      }` +
      '</p>'
  ]

  if (entry.joint_explan)
    lines.push(
      '<p>' +
        entry.joint_adoption_type.name +
        ': ' +
        explanSourceLinks(entry.joint_explan, entry, 1) +
        '</p>'
    )

  if (entry.second_parent_explan)
    lines.push(
      '<p>' +
        entry.second_parent_adoption_type.name +
        ': ' +
        explanSourceLinks(entry.second_parent_explan, entry, 2) +
        '</p>'
    )

  citeEntry(
    routes().find(r => r.parent === 'area1' && r.subareaNumber === 16),
    entry.motherEntry.subjurisdiction
      ? entry.motherEntry.subjurisdiction.name +
          ' (' +
          entry.motherEntry.jurisdiction.name +
          ')'
      : entry.motherEntry.jurisdiction.name,
    lines.join(''),
    setToast
  )
}
export const a116columns = () => [
  {
    Header: i18n.t('Jurisdiction'),
    disableGroupBy: true,
    accessor: 'motherEntry.jurisdiction.name',
    enableRowSpan: true,
    filter: ApplyJurFilter,
    Filter: ({ column }) => {
      return <JurFilter column={column} search={true} />
    },
    Cell: ({ value, row }) => {
      return (
        <div
          className={
            'jurCell ' +
            (row.original.motherEntry.jurisdiction.id.indexOf('DT') !== -1 ||
            row.original.motherEntry.subjurisdiction
              ? 'dependentTerritory'
              : '')
          }
        >
          <div>
            <img
              className="tableFlag"
              src={
                '/images/flags_svg/' +
                row.original.motherEntry.jurisdiction.a2_code.toLowerCase() +
                '.svg'
              }
              height="16px"
            />
          </div>
          <div>
            {!row.original.motherEntry.subjurisdiction &&
              row.original.motherEntry.jurisdiction.name}
            {row.original.motherEntry.subjurisdiction &&
              '' + row.original.motherEntry.subjurisdiction.name + ''}
          </div>
        </div>
      )
    },
    CellSmall: ({ value, row }) => {
      return (
        <div>{row.original.motherEntry.jurisdiction.name.substring(0, 3)}</div>
      )
    },
    style: { minWidth: '100px' }
  },
  {
    Header: i18n.t('Joint Adoption'),
    accessor: 'joint_adoption_type.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return <AdoptionDropdown column={column} />
    },

    classNameFn: c => getBgColorClass(c, 'joint_adoption_type'),

    className: 'textCenter col90 flexTd',

    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.joint_adoption_type.name >
        rowB.original.joint_adoption_type.name
      )
        return -1
      if (
        rowB.original.joint_adoption_type.name >
        rowA.original.joint_adoption_type.name
      )
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('Critical Date'),
    accessor: 'joint_critical_date_1',
    NeutralHeader: i18n.t('Critical Date'),
    sortDescFirst: true,
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(rows, ['joint_critical_date_1'], filterValue),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    },
    Cell: ({ value, row }) => {
      if (value)
        return (
          value +
          (row.original.joint_critical_date_2
            ? ' - ' + row.original.joint_critical_date_2
            : '')
        )
      else return '-'
    },
    className: 'textCenter col80'
  },
  {
    Header: i18n.t('Mechanism'),
    accessor: 'joint_mechanism.name',
    NeutralHeader: i18n.t('Mechanism'),
    className: 'textCenter col90',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          column={column}
          options={[
            { id: i18n.t('Legislative'), value: i18n.t('Legislative') },
            { id: i18n.t('Executive'), value: i18n.t('Executive') },
            { id: i18n.t('Judicial'), value: i18n.t('Judicial') }
          ]}
        />
      )
    }
  },
  {
    Header: i18n.t('Explanation'),
    accessor: 'joint_explan',
    disableSortBy: true,
    NeutralHeader: i18n.t('Explanation'),
    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            row={row}
            skipSources={
              (
                (row.original.skipField &&
                  row.original[row.original.skipField].match(/\*[^*]*\*/g)) ||
                []
              ).length
            }
            sources={row.original.motherEntry.sources}
            unik={row.original.id + '_explan'}
            footnotes={row.original.motherEntry.footnotes}
          />
        )
      else return <></>
    },
    NonExpandableCell: ({ value, row }) => {
      return (
        <SourceText
          text={value}
          skipSources={
            (
              (row.original.skipField &&
                row.original[row.original.skipField].match(/\*[^*]*\*/g)) ||
              []
            ).length
          }
          sources={row.original.motherEntry.sources}
          unik={row.original.id + '_explan'}
        />
      )
    },
    style: { minWidth: '280px' },
    sortType: (rowA, rowB, id, desc) => {
      if (!rowB.original.joint_explan) return null
      if (
        rowA.original.joint_explan.replace(/[^\w\s]/gi, '') >
        rowB.original.joint_explan.replace(/[^\w\s]/gi, '')
      )
        return -1
      if (
        rowB.original.joint_explan.replace(/[^\w\s]/gi, '') >
        rowA.original.joint_explan.replace(/[^\w\s]/gi, '')
      )
        return 1
      return 0
    }
  },
  {
    Header: i18n.t('Second Parent Adoption'),
    accessor: 'second_parent_adoption_type.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return <AdoptionDropdown2 column={column} />
    },
    classNameFn: c => getBgColorClass(c, 'second_parent_adoption_type'),

    className: 'textCenter col90 flexTd',

    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.second_parent_adoption_type.name >
        rowB.original.second_parent_adoption_type.name
      )
        return -1
      if (
        rowB.original.second_parent_adoption_type.name >
        rowA.original.second_parent_adoption_type.name
      )
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('Critical Date'),
    accessor: 'second_parent_critical_date_1',
    NeutralHeader: i18n.t('Critical Date'),
    sortDescFirst: true,
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(
        rows,
        ['second_parent_critical_date_1'],
        filterValue
      ),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    },
    Cell: ({ value, row }) => {
      if (value)
        return (
          value +
          (row.original.second_parent_critical_date_2
            ? ' - ' + row.original.second_parent_critical_date_2
            : '')
        )
      else return '-'
    },
    className: 'textCenter col80'
  },
  {
    Header: i18n.t('Mechanism'),
    NeutralHeader: i18n.t('Mechanism'),
    accessor: 'second_parent_mechanism.name',
    className: 'textCenter col90',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          column={column}
          options={[
            { id: i18n.t('Legislative'), value: i18n.t('Legislative') },
            { id: i18n.t('Executive'), value: i18n.t('Executive') },
            { id: i18n.t('Judicial'), value: i18n.t('Judicial') }
          ]}
        />
      )
    }
  },
  {
    Header: i18n.t('Explanation'),
    NeutralHeader: i18n.t('Explanation'),
    disableSortBy: true,
    accessor: 'second_parent_explan',

    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            row={row}
            skipSources={
              (row.original.joint_explan.match(/\*[^*]*\*/g) || []).length
            }
            sources={row.original.motherEntry.sources}
            unik={row.original.id + '_explan'}
            footnotes={row.original.motherEntry.footnotes}
          />
        )
      else return <></>
    },
    NonExpandableCell: ({ value, row }) => {
      return (
        <SourceText
          text={value}
          skipSources={
            (row.original.joint_explan.match(/\*[^*]*\*/g) || []).length
          }
          sources={row.original.motherEntry.sources}
          unik={row.original.id + '_explan'}
        />
      )
    },
    style: { minWidth: '280px' },
    sortType: (rowA, rowB, id, desc) => {
      if (!rowB.original.general_explan) return null
      if (
        rowA.original.general_explan.replace(/[^\w\s]/gi, '') >
        rowB.original.general_explan.replace(/[^\w\s]/gi, '')
      )
        return -1
      if (
        rowB.original.general_explan.replace(/[^\w\s]/gi, '') >
        rowA.original.general_explan.replace(/[^\w\s]/gi, '')
      )
        return 1
      return 0
    }
  }
]
