import { Fragment } from 'react'
import { cTreatyBodiesFull } from '../../config/constants'
import i18n from '../../config/i18n'
import { citeEntry } from '../../lib/cite'
import { i18ndayjs } from '../../lib/fnDates'
import { ApplyColumnFilter } from '../components/Table/ColumnFilter'
import DateDropdownFilter, {
  ApplyDropdownDateFilter
} from '../components/Table/DateDropdownFilter'
import ExpandableTextCell from '../components/Table/ExpandableTextCell'
import GenericDropdownFilter, {
  ApplyGenericDropdownFilter
} from '../components/Table/GenericDropdownFilter'
import JurCell from '../components/Table/JurCell'
import JurFilter, { ApplyJurFilter } from '../components/Table/JurFilter'
import SourceText from '../components/Table/SourceText'
import TopicFilter, { ApplyTopicFilter } from '../components/Table/TopicFilter'

export const citeA3Comm = (entry, setToast) => {
  let lines = ['<p>' + entry.summary + '</p>']

  if (entry.motherEntry.sources[0])
    lines.push(
      '<p>' +
        entry.treaty_body.name +
        `, <em><a href="${process.env.REACT_APP_BACKEND_URL +
          '/downloader/download/3/' +
          entry.motherEntry.sources[0].original_filename}" target="_blank">` +
        entry.title +
        `: ${entry.petitioners}</a></em>, ` +
        entry.un_symbol +
        ', ' +
        i18ndayjs(entry.views_date, 'DD MMM YYYY') +
        '</p>'
    )
  else
    lines.push(
      '<p>' +
        entry.treaty_body.name +
        `, <em>` +
        entry.title +
        `: ${entry.petitioners}</em>, ` +
        entry.un_symbol +
        ', ' +
        i18ndayjs(entry.views_date, 'DD MMM YYYY') +
        '.</p>'
    )

  citeEntry(null, null, lines.join(''), setToast)
}

export const columnsComm = () => [
  {
    Header: i18n.t('Jurisdiction'),
    disableGroupBy: true,
    accessor: 'motherEntry.jurisdiction.name',
    enableRowSpan: true,
    filter: ApplyJurFilter,
    Filter: ({ column }) => {
      return <JurFilter column={column} search={true} />
    },
    Cell: ({ value, row }) => {
      return <JurCell row={row} />
    },
    CellSmall: ({ value, row }) => {
      return (
        <div>{row.original.motherEntry.jurisdiction.name.substring(0, 3)}</div>
      )
    },
    style: { minWidth: '100px' }
  },
  {
    Header: i18n.t('Body'),
    accessor: 'treaty_body.name',
    filter: ApplyColumnFilter,
    className: 'textCenter col125 flexTd',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width="500px"
          column={column}
          options={cTreatyBodiesFull().map(tb => ({
            id: tb.name,
            value: tb.name
          }))}
        />
      )
    }
  },
  {
    Header: i18n.t('Name'),
    accessor: 'petitioners',
    className: 'textCenter col100 colHyphens',
    Cell: ({ value, row }) => {
      return value.replace('Nepomnyashchiy','Nepomnya-shchiy')
    },
  },
  {
    Header: i18n.t('Views Date'),
    accessor: 'views_date',
    Cell: ({ value, row }) => {
      return i18ndayjs(value, 'DD MMM YYYY')
    },
    sortDescFirst: true,
    className: 'textCenter col100',
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(rows, ['views_date'], filterValue),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    }
  },
  {
    Header: i18n.t('Summary'),
    accessor: 'summary',
    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            row={row}
            sources={null}
            unik={row.original.id + '_explan'}
          />
        )
      else return <></>
    },
    NonExpandableCell: ({ value, row }) => {
      return (
        <SourceText
          text={value}
          skipSources={0}
          sources={row.original.motherEntry.sources}
          unik={row.original.id + '_explan'}
        />
      )
    },
    sortType: (rowA, rowB, id, desc) => {
      if (!rowB.original.general_explan) return null
      if (
        rowA.original.general_explan.replace(/[^\w\s]/gi, '') >
        rowB.original.general_explan.replace(/[^\w\s]/gi, '')
      )
        return -1
      if (
        rowB.original.general_explan.replace(/[^\w\s]/gi, '') >
        rowA.original.general_explan.replace(/[^\w\s]/gi, '')
      )
        return 1
      return 0
    },
    disableSortBy: true,
    className: 'col270'
  },
  {
    Header: i18n.t('Topics'),
    accessor: 'motherEntry.topics',
    Cell: ({ value, row }) => {
      return row.original.motherEntry.topics
        ? row.original.motherEntry.topics.map((topic, index) => (
            <Fragment key={row.original + index}>
              <div className="tableTopic">{topic.name}</div>
              <div className="topicSeparator" />
            </Fragment>
          ))
        : ''
    },
    Filter: ({ column, rows }) => {
      return <TopicFilter column={column} search={true} rows={rows} />
    },
    filter: ApplyTopicFilter,
    className: 'textCenter col190',
    disableSortBy: true
  },
  {
    Header: i18n.t('Outcome'),
    accessor: 'treaty_body_outcome.name',
    className: 'textCenter col100',
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(rows, 'treaty_body_outcome.name', filterValue),
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width="200px"
          column={column}
          options={[
            { id: i18n.t('Inadmissible'), value: i18n.t('Inadmissible') },
            { id: i18n.t('No violation'), value: i18n.t('No violation') },
            { id: i18n.t('Violation'), value: i18n.t('Violation') }
          ]}
        />
      )
    }
  },
  {
    Header: i18n.t('Source'),
    accessor: 'source',
    Cell: ({ value, row }) => {
      return (
        <div className="tableSources">
          {row.original.motherEntry.sources.find(
            s =>
              s.original_language.id === (i18n.language === 'en' ? '39' : '41')
          ) && (
            <div>
              <a
                href={
                  process.env.REACT_APP_BACKEND_URL +
                  '/downloader/download/3/' +
                  row.original.motherEntry.sources.find(
                    s =>
                      s.original_language.id ===
                      (i18n.language === 'en' ? '39' : '41')
                  ).original_filename
                }
                target="_blank"
                rel="noreferrer"
              >
                {row.original.title}
              </a>
            </div>
          )}
          <div>{row.original.un_symbol}</div>
          <div>
            {i18n.t('Comm')}: {row.original.comm_number}
          </div>
          <div>
            {i18n.t('Session')}: {row.original.session}
          </div>
          <div>{row.original.text5}</div>
        </div>
      )
    },
    className: 'col190',
    disableSortBy: true,
    disableFilter: true
  }
]

export const sampleData = [
  {
    motherEntry: {
      jurisdiction: {
        id: 'UN-024',
        name: 'Brazil',
        un_member: true,
        a2_code: 'BR',
        regions: [
          {
            id: '12',
            __typename: 'Region'
          },
          {
            id: '13',
            __typename: 'Region'
          },
          {
            id: '18',
            __typename: 'Region'
          },
          {
            id: '20',
            __typename: 'Region'
          },
          {
            id: '29',
            __typename: 'Region'
          },
          {
            id: '49',
            __typename: 'Region'
          }
        ],
        __typename: 'Jurisdiction'
      }
    },
    body: 'Human Rights Committee',
    type: 'Concluding Observation (Mention)',
    date: '17 Sep 2020',
    details:
      '(…) While taking note of articles 295 bis 1 and 295 bis 2 of the Criminal Code, the Committee remains concerned that the definition of discrimination does not include such grounds of discrimination as (…) sexual orientation and gender identity, and finds it regrettable that current legislation does not offer victims effective civil and administrative remedies. (…) Moreover, the Committee reiterates its concern with regard to acts of discrimination and stigmatization against lesbian, gay, bisexual and transgender persons and finds it regrettable that sexual activity in private between consenting adults of the same sex remains an offence under article 338 of the Criminal Code (arts. 2, 19, 20 and 26).',
    topics: [
      'F. OF ASSEMBLY',
      'LEGISLATION',
      'REPRODUCTIVE RIGHTS',
      'DISCRIMINATION'
    ],
    scope: 'General',
    text1: 'Concluding observations on the fourth periodic report of France',
    text2: 'CCPR/C/GC/37',
    text3: 'Para. 25',
    text4: '125th Session',
    text5: ' 4th Cycle'
  },
  {
    motherEntry: {
      jurisdiction: {
        id: 'UN-024',
        name: 'Brazil',
        un_member: true,
        a2_code: 'BR',
        regions: [
          {
            id: '12',
            __typename: 'Region'
          },
          {
            id: '13',
            __typename: 'Region'
          },
          {
            id: '18',
            __typename: 'Region'
          },
          {
            id: '20',
            __typename: 'Region'
          },
          {
            id: '29',
            __typename: 'Region'
          },
          {
            id: '49',
            __typename: 'Region'
          }
        ],
        __typename: 'Jurisdiction'
      }
    },
    body: 'Human Rights Committee',
    type: 'Concluding Observation (Mention)',
    date: '17 Sep 2020',
    details:
      '(…) While taking note of articles 295 bis 1 and 295 bis 2 of the Criminal Code, the Committee remains concerned that the definition of discrimination does not include such grounds of discrimination as (…) sexual orientation and gender identity, and finds it regrettable that current legislation does not offer victims effective civil and administrative remedies. (…) Moreover, the Committee reiterates its concern with regard to acts of discrimination and stigmatization against lesbian, gay, bisexual and transgender persons and finds it regrettable that sexual activity in private between consenting adults of the same sex remains an offence under article 338 of the Criminal Code (arts. 2, 19, 20 and 26).',
    topics: [
      'F. OF ASSEMBLY',
      'LEGISLATION',
      'REPRODUCTIVE RIGHTS',
      'DISCRIMINATION'
    ],
    scope: 'General',
    text1: 'Concluding observations on the fourth periodic report of France',
    text2: 'CCPR/C/GC/37',
    text3: 'Para. 25',
    text4: '125th Session',
    text5: ' 4th Cycle'
  },
  {
    motherEntry: {
      jurisdiction: {
        id: 'UN-024',
        name: 'Brazil',
        un_member: true,
        a2_code: 'BR',
        regions: [
          {
            id: '12',
            __typename: 'Region'
          },
          {
            id: '13',
            __typename: 'Region'
          },
          {
            id: '18',
            __typename: 'Region'
          },
          {
            id: '20',
            __typename: 'Region'
          },
          {
            id: '29',
            __typename: 'Region'
          },
          {
            id: '49',
            __typename: 'Region'
          }
        ],
        __typename: 'Jurisdiction'
      }
    },
    body: 'Human Rights Committee',
    type: 'Concluding Observation (Mention)',
    date: '17 Sep 2020',
    details:
      '(…) While taking note of articles 295 bis 1 and 295 bis 2 of the Criminal Code, the Committee remains concerned that the definition of discrimination does not include such grounds of discrimination as (…) sexual orientation and gender identity, and finds it regrettable that current legislation does not offer victims effective civil and administrative remedies. (…) Moreover, the Committee reiterates its concern with regard to acts of discrimination and stigmatization against lesbian, gay, bisexual and transgender persons and finds it regrettable that sexual activity in private between consenting adults of the same sex remains an offence under article 338 of the Criminal Code (arts. 2, 19, 20 and 26).',
    topics: [
      'F. OF ASSEMBLY',
      'LEGISLATION',
      'REPRODUCTIVE RIGHTS',
      'DISCRIMINATION'
    ],
    scope: 'General',
    text1: 'Concluding observations on the fourth periodic report of France',
    text2: 'CCPR/C/GC/37',
    text3: 'Para. 25',
    text4: '125th Session',
    text5: ' 4th Cycle'
  },
  {
    motherEntry: {
      jurisdiction: {
        id: 'UN-024',
        name: 'Brazil',
        un_member: true,
        a2_code: 'BR',
        regions: [
          {
            id: '12',
            __typename: 'Region'
          },
          {
            id: '13',
            __typename: 'Region'
          },
          {
            id: '18',
            __typename: 'Region'
          },
          {
            id: '20',
            __typename: 'Region'
          },
          {
            id: '29',
            __typename: 'Region'
          },
          {
            id: '49',
            __typename: 'Region'
          }
        ],
        __typename: 'Jurisdiction'
      }
    },
    body: 'Human Rights Committee',
    type: 'Concluding Observation (Mention)',
    date: '17 Sep 2020',
    details:
      '(…) While taking note of articles 295 bis 1 and 295 bis 2 of the Criminal Code, the Committee remains concerned that the definition of discrimination does not include such grounds of discrimination as (…) sexual orientation and gender identity, and finds it regrettable that current legislation does not offer victims effective civil and administrative remedies. (…) Moreover, the Committee reiterates its concern with regard to acts of discrimination and stigmatization against lesbian, gay, bisexual and transgender persons and finds it regrettable that sexual activity in private between consenting adults of the same sex remains an offence under article 338 of the Criminal Code (arts. 2, 19, 20 and 26).',
    topics: [
      'F. OF ASSEMBLY',
      'LEGISLATION',
      'REPRODUCTIVE RIGHTS',
      'DISCRIMINATION'
    ],
    scope: 'General',
    text1: 'Concluding observations on the fourth periodic report of France',
    text2: 'CCPR/C/GC/37',
    text3: 'Para. 25',
    text4: '125th Session',
    text5: ' 4th Cycle'
  },
  {
    motherEntry: {
      jurisdiction: {
        id: 'UN-024',
        name: 'Brazil',
        un_member: true,
        a2_code: 'BR',
        regions: [
          {
            id: '12',
            __typename: 'Region'
          },
          {
            id: '13',
            __typename: 'Region'
          },
          {
            id: '18',
            __typename: 'Region'
          },
          {
            id: '20',
            __typename: 'Region'
          },
          {
            id: '29',
            __typename: 'Region'
          },
          {
            id: '49',
            __typename: 'Region'
          }
        ],
        __typename: 'Jurisdiction'
      }
    },
    body: 'Human Rights Committee',
    type: 'Concluding Observation (Mention)',
    date: '17 Sep 2020',
    details:
      '(…) While taking note of articles 295 bis 1 and 295 bis 2 of the Criminal Code, the Committee remains concerned that the definition of discrimination does not include such grounds of discrimination as (…) sexual orientation and gender identity, and finds it regrettable that current legislation does not offer victims effective civil and administrative remedies. (…) Moreover, the Committee reiterates its concern with regard to acts of discrimination and stigmatization against lesbian, gay, bisexual and transgender persons and finds it regrettable that sexual activity in private between consenting adults of the same sex remains an offence under article 338 of the Criminal Code (arts. 2, 19, 20 and 26).',
    topics: [
      'F. OF ASSEMBLY',
      'LEGISLATION',
      'REPRODUCTIVE RIGHTS',
      'DISCRIMINATION'
    ],
    scope: 'General',
    text1: 'Concluding observations on the fourth periodic report of France',
    text2: 'CCPR/C/GC/37',
    text3: 'Para. 25',
    text4: '125th Session',
    text5: ' 4th Cycle'
  },
  {
    motherEntry: {
      jurisdiction: {
        id: 'UN-024',
        name: 'Brazil',
        un_member: true,
        a2_code: 'BR',
        regions: [
          {
            id: '12',
            __typename: 'Region'
          },
          {
            id: '13',
            __typename: 'Region'
          },
          {
            id: '18',
            __typename: 'Region'
          },
          {
            id: '20',
            __typename: 'Region'
          },
          {
            id: '29',
            __typename: 'Region'
          },
          {
            id: '49',
            __typename: 'Region'
          }
        ],
        __typename: 'Jurisdiction'
      }
    },
    body: 'Human Rights Committee',
    type: 'Concluding Observation (Mention)',
    date: '17 Sep 2020',
    details:
      '(…) While taking note of articles 295 bis 1 and 295 bis 2 of the Criminal Code, the Committee remains concerned that the definition of discrimination does not include such grounds of discrimination as (…) sexual orientation and gender identity, and finds it regrettable that current legislation does not offer victims effective civil and administrative remedies. (…) Moreover, the Committee reiterates its concern with regard to acts of discrimination and stigmatization against lesbian, gay, bisexual and transgender persons and finds it regrettable that sexual activity in private between consenting adults of the same sex remains an offence under article 338 of the Criminal Code (arts. 2, 19, 20 and 26).',
    topics: [
      'F. OF ASSEMBLY',
      'LEGISLATION',
      'REPRODUCTIVE RIGHTS',
      'DISCRIMINATION'
    ],
    scope: 'General',
    text1: 'Concluding observations on the fourth periodic report of France',
    text2: 'CCPR/C/GC/37',
    text3: 'Para. 25',
    text4: '125th Session',
    text5: ' 4th Cycle'
  }
]

export const tagListSample = [
  'ACCESS TO JUSTICE',
  'ADOPTION',
  'ADVOCACY',
  'AFFIRMATIVE ACTION',
  'ANAL EXAM',
  'ANTI-GENDER',
  'APOLOGY',
  'ARTS',
  'ASEXUAL',
  'ASSIST. REPROD. TECHS.',
  'ASYLUM',
  'AWARD',
  'AWARENESS RAISING',
  'BACKLASH',
  'BILL',
  'BISEXUAL',
  'BLOOD DONATION',
  'BUDDHISM',
  'BULLYING',
  'BUSINESS',
  'CENSORSHIP',
  'CENSUS',
  'CHILDREN',
  'CHRISTIANITY',
  'CIVIL UNION',
  'COMING OUT',
  'COMP. SEXUALITY EDUC.',
  'CONFERENCE',
  'CONSULTATION',
  'CONVERSION THERAPY',
  'COOP. W. CIVIL SOCIETY',
  'CORRECTIVE RAPE',
  'COVID-19',
  'CRIM. ENFORCEMENT',
  'CRIM. GENDER EXP.',
  'CRIM. OF CSSSA',
  'CULTURE',
  'DATA COLLECTION',
  'DATA PROTECTION',
  'DATING APP',
  'DEATH PENALTY',
  'DEPORTATION',
  'DETENTION',
  'DETRACTION',
  'DIPLOMACY',
  'DISABILITY',
  'DISASTER ASSISTANCE',
  'DISCRIM. (SERVICES)',
  'DISCRIMINATION',
  'EDUCATION',
  'EMPLOYMENT',
  'ENTRAPMENT',
  'F. OF ASSEMBLY',
  'F. OF ASSOC.',
  'F. OF SPEECH',
  'FAMILY',
  'FOLLOW UP',
  'FUNDING',
  'GAY',
  'GENDER AFFIRM. PROCS.',
  'GENDER EXPRESSION',
  'GENDER IDENTITY',
  'GENDER IDEOLOGY',
  'GOVERNMENT',
  'HARASSMENT',
  'HATE CRIME',
  'HATE SPEECH',
  'HEALTH',
  'HEALTH PROFESSIONALS',
  'HIV/AIDS',
  'HOMOPHOBIA',
  'HONOUR CRIME',
  'HOUSING',
  'HUMAN RIGHTS DEFENDER',
  'IMPUNITY',
  'INCITEMENT',
  'INDIGENOUS',
  'INFORMED CONSENT',
  "INT'L LITIGATION",
  'INTERSECTIONALITY',
  'INTERSEX',
  'INTIMATE PART. VIOL.',
  'INTIMIDATION',
  'INVESTIGATION',
  'ISLAM',
  'JUDAISM',
  'JUDICIAL',
  'LEGAL AID',
  'LEGISLATION',
  'LESBIAN',
  'LGR',
  'MARRIAGE',
  'MEDIA',
  'MENTAL HEALTH',
  'MIGRATION',
  'MILITARY',
  'MORALITY DISCOURSE',
  'MSM',
  'NAZI',
  'NHRI',
  'NON-BINARY',
  'OUTING',
  'PANSEXUAL',
  'PDA',
  'POLICE',
  'POLITICAL REPRESENTATION',
  'POLITICS',
  'POVERTY',
  'PREVENTION',
  'PRIDE',
  'PROPAGANDA LAW',
  'PUBLIC POLICY',
  'RACE',
  'REFOULEMENT',
  'RELIGION',
  'REMEMBRANCE',
  'REP. IN MEDIA',
  'REPARATION',
  'REPORT',
  'REPRODUCTIVE RIGHTS',
  'RESEARCH',
  'SAME SEX COUPLE',
  'SANITATION',
  'SCHOOL CURRICULUM',
  'SDG',
  'SENIORS',
  'SEX WORK',
  'SEXUAL ORIENTATION',
  'SEXUAL VIOLENCE',
  'SOCIAL ATTITUDES',
  'SOCIAL MEDIA',
  'SOCIAL SECURITY',
  'SOLIDARITY',
  'SPORTS',
  'STEREOTYPE',
  'STI',
  'SUICIDE',
  'SURROGACY',
  'NON-BIN. GEND. MARKER',
  'TORTURE',
  'TRAININGS',
  'TRANS MEN',
  'TRANS WOMEN',
  'TRANSPHOBIA',
  'UN HR COUNCIL',
  'UN OHCHR',
  'UN SPECIAL PROCEDURES',
  'UN TREATY BODY',
  'UNITED NATIONS',
  'VICTIM SUPPORT',
  'VIOLENCE',
  'WOMEN',
  'YOUTH'
]
