import { useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { decode } from 'html-entities'
import { useApolloClient } from '@apollo/client'
import MainLayout from '../../../layouts/MainLayout'
import CloseIcon from '../../components/icons/CloseIcon'
import ToolsIcon from '../../components/icons/ToolsIcon'
import AddAreaButton from './AddAreaButton'
import AddJurisdicitonButton from './AddJurisdictionButton'
import DoubleScrollbar from 'react-double-scrollbar'
import { useTranslation } from 'react-i18next'
import useGqlCompareTool from '../../../gql/useGqlCompareTool'
import { getAreasSelected } from '../../components/AreaSelectModal/AreaSelectModal'
import './CompareTool.css'
import useGqlJurisdictions from '../../../gql/useGqlJurisdictions'
import ExpandableTextCell from '../../components/Table/ExpandableTextCell'
import SourceText from '../../components/Table/SourceText'
import { NoData } from '../../texts/jur/NoData'

export default function CompareTool() {
  /* change to toggle between screen modes */
  const [hasSelected, setHasSelected] = useState(true)
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState(null)
  const [areas, setAreas] = useState([])
  const [compareResults, setCompareResults] = useState([])
  const [compareSources, setCompareSources] = useState([])
  const [currentTh, setCurrentTh] = useState(-1)
  const [currentTr, setCurrentTr] = useState(-1)
  const apolloClient = useApolloClient()
  const { jurisdictions, error, regions } = useGqlJurisdictions()
  const { t, i18n } = useTranslation()

  useEffect(async () => {
    if (filters) {
      setAreas(
        getAreasSelected(filters, true).sort(
          (a, b) =>
            parseInt(a.id.replace('a1', '')) - parseInt(b.id.replace('a1', ''))
        )
      )

      if (
        filters.subareas &&
        filters.subareas.a1 &&
        filters.subareas.a1.length &&
        filters.jurs_ids &&
        filters.jurs_ids.length
      ) {
        setHasSelected(true)
        setLoading(true)
        const response = await useGqlCompareTool(
          apolloClient,
          filters,
          i18n.language
        )
        const res = {}
        const resSources = {}
        for (const entry of response.data.compareTool) {
          res[entry.area + entry.jurisdiction.a2_code] = {
            preDetails: entry.preDetails,
            details: entry.details,
            posDetails: entry.posDetails
          }
          resSources[entry.area + entry.jurisdiction.a2_code] = entry.sources
        }
        delete res.__typename
        console.log(res)
        setCompareResults(res)
        setCompareSources(resSources)
        console.log(res)
        setLoading(false)
      }

      console.log(filters)
    }
  }, [filters])

  return (
    <MainLayout section="tools">
      <div className="area__page section__page advancedSearch__page compareTool__page">
        <div className="content__container">
          <div className="areaContent__header">
            <div className="ach__iconContainer">
              <ToolsIcon size={32} color="#ffffff" />
            </div>
            <div className="ach__titlesContainer">
              <h2 className="acp__title">{t('Compare Tool')}</h2>
              <p>
                {t(
                  'This tool brings country-specific data side-by-side to compare two or more jurisdictions, or a geopolitical group, and assess how they are faring in any given legal category tracked under Area 1 of the Database.'
                )}
              </p>
              <p>
                {t(
                  'Select a set of countries or a predefined group of countries to determine the rows of your table and select the legal categories of your interest to populate the columns.'
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="acp__content">
          <div className="content__container">
            <div className="acp__content-body">
              <div
                className={`compareTool__tableContainer ${
                  hasSelected ? '' : 'emptyTable'
                }`}
              >
                <table>
                  <thead>
                    <tr
                      style={{ position: 'sticky', top: '0px', zIndex: 10000 }}
                    >
                      {filters &&
                      filters.jurs_ids &&
                      filters.jurs_ids.length ? (
                        <th className="firstCompareTool JurBtnContainer">
                          <AddJurisdicitonButton
                            filters={filters}
                            setFilters={setFilters}
                          />
                        </th>
                      ) : (
                        <th className="firstTh">
                          <></>
                        </th>
                      )}
                      {hasSelected &&
                        areas.length > 0 &&
                        areas.map((area, index) => {
                          return (
                            <th
                              key={'uniqueId' + index}
                              onMouseEnter={e => setCurrentTh(index)}
                              onMouseLeave={e => setCurrentTh(-1)}
                            >
                              Area 1-{`${area.id.replace('a1', '')} | `}
                              {`${area.name}`}
                              <button
                                className="acp__table-deleteCol"
                                style={{
                                  ...(currentTh === index
                                    ? { display: 'unset' }
                                    : { display: 'none' })
                                }}
                                onClick={e =>
                                  setAreas(areas.filter(a => a.id !== area.id))
                                }
                              >
                                <CloseIcon size={16} />
                              </button>
                            </th>
                          )
                        })}
                      <th>
                        <AddAreaButton
                          filters={filters}
                          setFilters={setFilters}
                        />
                        {!hasSelected && (
                          <div className="infoBubble">Select area</div>
                        )}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {hasSelected &&
                      filters &&
                      filters.jurs_ids &&
                      filters.jurs_ids.length > 0 &&
                      filters.jurs_ids.map((jurId, index) => {
                        console.log(filters.jurs_ids)
                        const jurisdiction = jurisdictions.find(
                          jur => jur.id === jurId
                        )
                        return (
                          <tr
                            key={'uniqueId' + jurisdiction.a2_code}
                            onMouseEnter={e =>
                              setCurrentTr(jurisdiction.a2_code)
                            }
                            onMouseLeave={e => setCurrentTr(-1)}
                          >
                            <td className="firstCompareTool">
                              <div className="td-rowContainer">
                                <img
                                  className={'svgFlagNoBorder'}
                                  src={
                                    '/images/flags_svg/' +
                                    jurisdiction.a2_code.toLowerCase() +
                                    '.svg'
                                  }
                                  alt={`${jurisdiction.name} flag`}
                                  height="16px"
                                />{' '}
                                {`${jurisdiction.name}`}
                              </div>
                              <button
                                className="acp__table-deleteCol"
                                style={{
                                  ...(currentTr === jurisdiction.a2_code
                                    ? { display: 'unset' }
                                    : { display: 'none' })
                                }}
                                onClick={e =>
                                  setFilters({
                                    ...filters,
                                    jurs_ids: filters.jurs_ids.filter(
                                      a => a !== jurisdiction.id
                                    )
                                  })
                                }
                              >
                                <CloseIcon size={16} />
                              </button>
                            </td>
                            {hasSelected &&
                              areas &&
                              areas.length > 0 &&
                              areas.map((area, index) => (
                                <td key={area.id + jurisdiction.a2_code}>
                                  <div className="compareCellDiv">
                                    {compareResults[
                                      area.id + jurisdiction.a2_code
                                    ] &&
                                    compareResults[
                                      area.id + jurisdiction.a2_code
                                    ].details.length > 0 ? (
                                      compareResults[
                                        area.id + jurisdiction.a2_code
                                      ].details.map((d, index) => (
                                        <p key={index}>
                                          {compareResults[
                                            area.id + jurisdiction.a2_code
                                          ].preDetails &&
                                            compareResults[
                                              area.id + jurisdiction.a2_code
                                            ].preDetails[index] && (
                                              <span
                                                className="preDetails"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    compareResults[
                                                      area.id +
                                                        jurisdiction.a2_code
                                                    ].preDetails[index]
                                                }}
                                              />
                                            )}
                                          {d && (
                                            <SourceText
                                              row={{ index }}
                                              sources={
                                                compareSources[
                                                  area.id + jurisdiction.a2_code
                                                ] || []
                                              }
                                              text={d}
                                            />
                                          )}
                                        </p>
                                      ))
                                    ) : (
                                      <>
                                        {loading ? (
                                          <></>
                                        ) : parseInt(
                                            area.id.replace('a1', '')
                                          ) < 5 ? (
                                          NoData({ jur: jurisdiction })[
                                            area.id.replace('a1', 'A1-')
                                          ][i18n.language]
                                        ) : parseInt(
                                            area.id.replace('a1', '')
                                          ) <= 17 ? (
                                          NoData({ jur: jurisdiction })[
                                            area.id.replace('a1', 'A1-')
                                          ]['no'][i18n.language]
                                        ) : parseInt(
                                            area.id.replace('a1', '')
                                          ) === 18 ? (
                                          NoData({ jur: jurisdiction })[
                                            'A1-18-A'
                                          ]['nodata'][i18n.language]
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </td>
                              ))}
                          </tr>
                        )
                      })}
                    {(!filters ||
                      !filters.jurs_ids ||
                      !filters.jurs_ids.length) && (
                      <tr className="jurTr">
                        <td className="firstCompareTool JurBtnContainer">
                          <AddJurisdicitonButton
                            filters={filters}
                            setFilters={setFilters}
                          />

                          {!hasSelected && (
                            <div className="infoBubble">
                              {t('Add Jurisdiction')}
                            </div>
                          )}
                        </td>

                        <td />
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
