var map = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.0288, 43.0013],
            [-104.0271, 42.5013],
            [-104.0243, 41.751],
            [-104.0217, 41.0008],
            [-104.6498, 41.0008],
            [-105.5919, 41.0008],
            [-106.22, 41.0008],
            [-107.1621, 41.0008],
            [-108.4183, 41.0008],
            [-109.0463, 41.0008],
            [-110.2986, 41.0008],
            [-111.05, 41.0008],
            [-111.0503, 42.0008],
            [-111.0508, 42.9376],
            [-111.051, 43.5622],
            [-111.0515, 44.4989],
            [-111.0508, 45.001],
            [-109.5167, 45.001],
            [-108.2016, 45.001],
            [-107.3249, 45.001],
            [-106.4482, 45.001],
            [-105.3523, 45.0011],
            [-104.0372, 45.0011],
            [-104.034, 44.2513],
            [-104.032, 43.7513],
            [-104.0288, 43.0013]
          ]
        ]
      },
      properties: { name: "Wyoming", id: "US-WY", CNTRY: "United States of America", TYPE: "State" },
      id: "US-WY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.4776, 39.7228],
            [-79.4879, 39.2109],
            [-79.2935, 39.3115],
            [-79.0752, 39.476],
            [-78.9714, 39.4536],
            [-78.8148, 39.5701],
            [-78.4957, 39.5334],
            [-78.4068, 39.6279],
            [-78.1812, 39.6857],
            [-77.8832, 39.6107],
            [-77.7982, 39.5172],
            [-77.7267, 39.3464],
            [-77.8358, 39.1452],
            [-78.3223, 39.4519],
            [-78.4352, 39.1608],
            [-78.5815, 38.999],
            [-78.7568, 38.9082],
            [-78.8687, 38.8179],
            [-79.0412, 38.7897],
            [-79.2402, 38.4766],
            [-79.3959, 38.4544],
            [-79.5048, 38.4914],
            [-79.5978, 38.5771],
            [-79.6687, 38.5443],
            [-79.7153, 38.404],
            [-79.9027, 38.1807],
            [-79.9717, 38.0461],
            [-80.1882, 37.8242],
            [-80.2853, 37.677],
            [-80.2458, 37.6208],
            [-80.316, 37.5097],
            [-80.4645, 37.4327],
            [-80.5075, 37.469],
            [-80.7198, 37.401],
            [-80.8211, 37.4231],
            [-80.9437, 37.3019],
            [-81.2272, 37.2632],
            [-81.35, 37.3337],
            [-81.5649, 37.2098],
            [-81.6608, 37.2218],
            [-81.8292, 37.2986],
            [-81.913, 37.369],
            [-81.9654, 37.5397],
            [-82.1035, 37.5706],
            [-82.2802, 37.6871],
            [-82.5888, 38.0998],
            [-82.6196, 38.182],
            [-82.5784, 38.2721],
            [-82.6128, 38.4482],
            [-82.4976, 38.4149],
            [-82.3871, 38.434],
            [-82.18, 38.6376],
            [-82.2035, 38.7682],
            [-82.0723, 38.963],
            [-81.7777, 38.9729],
            [-81.8027, 39.086],
            [-81.6571, 39.272],
            [-81.4397, 39.3926],
            [-81.3333, 39.3628],
            [-81.1391, 39.456],
            [-80.9633, 39.5712],
            [-80.8744, 39.6642],
            [-80.8721, 39.7355],
            [-80.6683, 40.1853],
            [-80.6019, 40.3639],
            [-80.6591, 40.563],
            [-80.5191, 40.6469],
            [-80.5195, 39.7225],
            [-79.4776, 39.7228]
          ]
        ]
      },
      properties: { name: "West Virginia", id: "US-WV", CNTRY: "United States of America", TYPE: "State" },
      id: "US-WV"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-86.9919, 45.241],
              [-87.0589, 45.1025],
              [-87.2803, 44.8349],
              [-87.382, 44.8642],
              [-87.3965, 44.9541],
              [-87.2588, 45.138],
              [-87.122, 45.2245],
              [-86.9919, 45.241]
            ]
          ],
          [
            [
              [-90.4077, 46.593],
              [-90.2304, 46.5098],
              [-90.1185, 46.3417],
              [-89.1193, 46.1492],
              [-88.8374, 46.0366],
              [-88.4931, 46.0136],
              [-88.1517, 45.9454],
              [-88.1161, 45.8158],
              [-87.9226, 45.76],
              [-87.8091, 45.686],
              [-87.8152, 45.5059],
              [-87.8654, 45.3677],
              [-87.6964, 45.3829],
              [-87.7208, 45.2427],
              [-87.6058, 45.1086],
              [-87.627, 45.0322],
              [-87.8302, 44.9425],
              [-88.0282, 44.618],
              [-87.929, 44.5594],
              [-87.7409, 44.692],
              [-87.6044, 44.8469],
              [-87.4197, 44.8656],
              [-87.3456, 44.8006],
              [-87.5274, 44.3857],
              [-87.535, 44.1742],
              [-87.6585, 44.059],
              [-87.7238, 43.9073],
              [-87.6997, 43.7237],
              [-87.7741, 43.5989],
              [-87.9017, 43.2449],
              [-87.8934, 43.0411],
              [-87.7871, 42.8092],
              [-87.8121, 42.4972],
              [-88.8402, 42.5028],
              [-89.7408, 42.5078],
              [-90.6506, 42.513],
              [-90.6878, 42.6104],
              [-90.7887, 42.6769],
              [-90.96, 42.7203],
              [-91.0695, 42.7886],
              [-91.1482, 42.9862],
              [-91.1613, 43.1023],
              [-91.1173, 43.3311],
              [-91.2188, 43.3952],
              [-91.2452, 43.5025],
              [-91.2784, 43.7975],
              [-91.32, 43.9362],
              [-91.3827, 43.9908],
              [-91.7165, 44.1265],
              [-91.8409, 44.1941],
              [-92.0552, 44.3998],
              [-92.2403, 44.4621],
              [-92.3669, 44.5522],
              [-92.6436, 44.6454],
              [-92.7992, 44.79],
              [-92.7634, 44.9342],
              [-92.793, 45.0714],
              [-92.7661, 45.2364],
              [-92.6858, 45.3805],
              [-92.7071, 45.4938],
              [-92.8925, 45.5948],
              [-92.8747, 45.7061],
              [-92.6939, 45.909],
              [-92.2926, 46.0844],
              [-92.2917, 46.6607],
              [-92.1069, 46.7624],
              [-91.9874, 46.699],
              [-91.8356, 46.7022],
              [-91.4991, 46.7661],
              [-91.2177, 46.8857],
              [-91.1273, 46.8677],
              [-90.9461, 46.9538],
              [-90.7683, 46.908],
              [-90.8654, 46.7606],
              [-90.923, 46.6032],
              [-90.7369, 46.6888],
              [-90.5361, 46.5995],
              [-90.4077, 46.593]
            ]
          ]
        ]
      },
      properties: { name: "Wisconsin", id: "US-WI", CNTRY: "United States of America", TYPE: "State" },
      id: "US-WI"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-116.8965, 46.0021],
              [-118.2061, 46.0013],
              [-119.0197, 46.0003],
              [-119.1773, 45.9451],
              [-119.4291, 45.9342],
              [-119.9343, 45.8379],
              [-120.156, 45.7622],
              [-120.3515, 45.7252],
              [-120.7158, 45.6898],
              [-121.0452, 45.639],
              [-121.1843, 45.6437],
              [-121.4022, 45.7118],
              [-121.6625, 45.7181],
              [-121.8821, 45.6895],
              [-122.0851, 45.6027],
              [-122.2025, 45.591],
              [-122.6498, 45.6275],
              [-122.7259, 45.6737],
              [-122.8434, 45.9781],
              [-122.942, 46.1149],
              [-123.1209, 46.1787],
              [-123.2206, 46.1536],
              [-123.4648, 46.2712],
              [-123.6884, 46.2999],
              [-123.8957, 46.2678],
              [-124.0727, 46.2795],
              [-124.0502, 46.4906],
              [-123.9461, 46.4325],
              [-123.8892, 46.66],
              [-124.0717, 46.7448],
              [-124.1125, 46.8627],
              [-123.8429, 46.9632],
              [-124.0423, 47.0297],
              [-124.1636, 47.0153],
              [-124.1989, 47.2085],
              [-124.3093, 47.4046],
              [-124.376, 47.6586],
              [-124.46, 47.7843],
              [-124.6211, 47.9042],
              [-124.7016, 48.1516],
              [-124.6326, 48.3751],
              [-124.429, 48.3008],
              [-124.1755, 48.2424],
              [-123.9758, 48.1685],
              [-123.2944, 48.1195],
              [-123.1244, 48.1509],
              [-123.0242, 48.0816],
              [-122.8609, 48.0901],
              [-122.7398, 48.0133],
              [-122.6566, 47.8812],
              [-122.8214, 47.7932],
              [-122.9825, 47.5594],
              [-122.7571, 47.7005],
              [-122.5879, 47.8559],
              [-122.5239, 47.7694],
              [-122.6302, 47.6928],
              [-122.5536, 47.4049],
              [-122.5779, 47.2932],
              [-122.8125, 47.329],
              [-122.9196, 47.2896],
              [-122.9877, 47.1726],
              [-122.7019, 47.1109],
              [-122.6041, 47.167],
              [-122.5422, 47.2756],
              [-122.3538, 47.3716],
              [-122.3683, 47.6039],
              [-122.4105, 47.6526],
              [-122.3929, 47.8206],
              [-122.242, 48.0108],
              [-122.353, 48.1139],
              [-122.494, 48.1304],
              [-122.5203, 48.2291],
              [-122.4085, 48.2939],
              [-122.4884, 48.3743],
              [-122.669, 48.4653],
              [-122.4968, 48.5055],
              [-122.5127, 48.6695],
              [-122.562, 48.7779],
              [-122.653, 48.7639],
              [-122.7888, 48.993],
              [-121.4072, 48.993],
              [-120.5545, 48.993],
              [-119.7017, 48.993],
              [-118.8489, 48.9931],
              [-117.9962, 48.9931],
              [-117.0391, 48.9931],
              [-117.0349, 48.3528],
              [-117.0292, 47.2307],
              [-117.025, 46.4292],
              [-116.943, 46.2321],
              [-116.9478, 46.1243],
              [-116.8965, 46.0021]
            ]
          ],
          [
            [
              [-122.5728, 48.1567],
              [-122.3667, 47.9854],
              [-122.4114, 47.9177],
              [-122.5576, 47.9925],
              [-122.5728, 48.1567]
            ]
          ]
        ]
      },
      properties: { name: "Washington", id: "US-WA", CNTRY: "United States of America", TYPE: "State" },
      id: "US-WA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.4668, 42.7303],
            [-73.2533, 42.7523],
            [-73.2665, 42.8648],
            [-73.247, 43.553],
            [-73.4134, 43.6278],
            [-73.3758, 43.7622],
            [-73.425, 44.0383],
            [-73.4097, 44.1314],
            [-73.321, 44.2689],
            [-73.3097, 44.4597],
            [-73.372, 44.5974],
            [-73.3485, 44.7753],
            [-73.3521, 45.0054],
            [-72.3498, 45.0066],
            [-71.5175, 45.0075],
            [-71.5102, 44.9083],
            [-71.6206, 44.772],
            [-71.5684, 44.6076],
            [-71.6829, 44.4503],
            [-72.0312, 44.3007],
            [-72.115, 43.9655],
            [-72.2967, 43.7149],
            [-72.385, 43.5292],
            [-72.4071, 43.332],
            [-72.4737, 43.0385],
            [-72.5498, 42.8867],
            [-72.4668, 42.7303]
          ]
        ]
      },
      properties: { name: "Vermont", id: "US-VT", CNTRY: "United States of America", TYPE: "State" },
      id: "US-VT"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-75.6592, 37.954],
              [-75.62, 37.9992],
              [-75.3759, 38.025],
              [-75.5964, 37.6311],
              [-75.5871, 37.5587],
              [-75.6989, 37.5164],
              [-75.8121, 37.4252],
              [-75.9343, 37.1519],
              [-75.9973, 37.2638],
              [-75.975, 37.3985],
              [-75.8882, 37.6191],
              [-75.7193, 37.8215],
              [-75.6592, 37.954]
            ]
          ],
          [
            [
              [-77.7267, 39.3464],
              [-77.4789, 39.2208],
              [-77.4809, 39.113],
              [-77.3011, 39.0534],
              [-77.1221, 38.9434],
              [-77.0303, 38.8893],
              [-77.0457, 38.7758],
              [-77.2603, 38.6],
              [-77.3137, 38.3967],
              [-77.232, 38.3401],
              [-77.0468, 38.3566],
              [-76.9063, 38.197],
              [-76.5495, 38.0945],
              [-76.4717, 38.0111],
              [-76.2643, 37.8935],
              [-76.3056, 37.7216],
              [-76.4841, 37.6288],
              [-76.3055, 37.5715],
              [-76.2635, 37.357],
              [-76.401, 37.3861],
              [-76.4011, 37.2126],
              [-76.2833, 37.0527],
              [-76.4008, 36.9913],
              [-76.6718, 37.1729],
              [-76.6339, 37.0475],
              [-76.3995, 36.8899],
              [-76.2442, 36.9526],
              [-75.9994, 36.9127],
              [-75.8575, 36.5506],
              [-75.889, 36.5505],
              [-75.9664, 36.5514],
              [-76.5766, 36.5557],
              [-77.662, 36.5641],
              [-78.3855, 36.5697],
              [-79.4709, 36.5782],
              [-80.5562, 36.5866],
              [-81.6416, 36.595],
              [-81.6589, 36.6105],
              [-81.9053, 36.6186],
              [-81.9525, 36.598],
              [-82.9558, 36.6021],
              [-83.6676, 36.6051],
              [-83.1502, 36.7624],
              [-83.0438, 36.8455],
              [-82.8945, 36.9064],
              [-82.7319, 37.048],
              [-82.6816, 37.1381],
              [-82.3431, 37.2969],
              [-81.9654, 37.5397],
              [-81.913, 37.369],
              [-81.8292, 37.2986],
              [-81.6608, 37.2218],
              [-81.5649, 37.2098],
              [-81.35, 37.3337],
              [-81.2272, 37.2632],
              [-80.9437, 37.3019],
              [-80.8211, 37.4231],
              [-80.7198, 37.401],
              [-80.5075, 37.469],
              [-80.4645, 37.4327],
              [-80.316, 37.5097],
              [-80.2458, 37.6208],
              [-80.2853, 37.677],
              [-80.1882, 37.8242],
              [-79.9717, 38.0461],
              [-79.9027, 38.1807],
              [-79.7153, 38.404],
              [-79.6687, 38.5443],
              [-79.5978, 38.5771],
              [-79.5048, 38.4914],
              [-79.3959, 38.4544],
              [-79.2402, 38.4766],
              [-79.0412, 38.7897],
              [-78.8687, 38.8179],
              [-78.7568, 38.9082],
              [-78.5815, 38.999],
              [-78.4352, 39.1608],
              [-78.3223, 39.4519],
              [-77.8358, 39.1452],
              [-77.7267, 39.3464]
            ]
          ]
        ]
      },
      properties: { name: "Virginia", id: "US-VA", CNTRY: "United States of America", TYPE: "State" },
      id: "US-VA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.0503, 42.0008],
            [-111.05, 41.0008],
            [-110.2986, 41.0008],
            [-109.0463, 41.0008],
            [-109.0465, 40.5009],
            [-109.0465, 39.7508],
            [-109.0465, 39.2508],
            [-109.0466, 38.5008],
            [-109.0467, 37.5009],
            [-109.0467, 37.0008],
            [-109.9829, 37.0015],
            [-110.6071, 37.0018],
            [-111.8555, 37.0027],
            [-112.4798, 37.0031],
            [-113.4161, 37.0037],
            [-114.0402, 37.0041],
            [-114.0408, 37.941],
            [-114.0413, 39.0342],
            [-114.0417, 39.9711],
            [-114.042, 40.7518],
            [-114.0423, 41.3766],
            [-114.0426, 42.0011],
            [-112.7334, 42.001],
            [-111.9854, 42.0009],
            [-111.0503, 42.0008]
          ]
        ]
      },
      properties: { name: "Utah", id: "US-UT", CNTRY: "United States of America", TYPE: "State" },
      id: "US-UT"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-97.1707, 26.1593],
              [-97.2673, 26.3298],
              [-97.4021, 26.8206],
              [-97.3512, 26.8014],
              [-97.2022, 26.2998],
              [-97.1707, 26.1593]
            ]
          ],
          [
            [
              [-94.4842, 33.6485],
              [-94.3779, 33.5661],
              [-94.2947, 33.5875],
              [-94.0473, 33.5545],
              [-94.0413, 33.012],
              [-94.0434, 31.9992],
              [-93.9051, 31.877],
              [-93.8268, 31.7503],
              [-93.8206, 31.604],
              [-93.7376, 31.5138],
              [-93.6658, 31.3226],
              [-93.5578, 31.1804],
              [-93.5306, 31.0462],
              [-93.5792, 30.8238],
              [-93.7202, 30.5582],
              [-93.7503, 30.3448],
              [-93.7107, 30.1128],
              [-93.794, 29.9772],
              [-93.8415, 29.9797],
              [-93.9463, 29.8149],
              [-93.8904, 29.6894],
              [-94.0997, 29.6704],
              [-94.7502, 29.418],
              [-94.5263, 29.548],
              [-94.6053, 29.5678],
              [-94.7782, 29.5478],
              [-94.7244, 29.6552],
              [-94.7419, 29.75],
              [-94.8322, 29.7526],
              [-94.8898, 29.6769],
              [-95.0229, 29.7023],
              [-94.9928, 29.531],
              [-94.8883, 29.3705],
              [-95.1391, 29.1678],
              [-95.1521, 29.0793],
              [-95.2735, 28.9639],
              [-95.8535, 28.6403],
              [-96.0204, 28.5868],
              [-96.2754, 28.6551],
              [-96.4487, 28.5944],
              [-96.5757, 28.7157],
              [-96.64, 28.7088],
              [-96.5246, 28.4888],
              [-96.4211, 28.4573],
              [-96.5618, 28.3671],
              [-96.6764, 28.3413],
              [-96.7736, 28.4216],
              [-96.8069, 28.2202],
              [-96.8917, 28.1576],
              [-96.9667, 28.1895],
              [-97.1565, 28.1444],
              [-97.0731, 27.9861],
              [-97.1714, 27.8795],
              [-97.3741, 27.87],
              [-97.4315, 27.8372],
              [-97.2888, 27.6706],
              [-97.3805, 27.4193],
              [-97.4391, 27.3283],
              [-97.5238, 27.3139],
              [-97.6821, 27.395],
              [-97.6924, 27.2872],
              [-97.4852, 27.2374],
              [-97.4757, 27.1179],
              [-97.5547, 26.9673],
              [-97.4658, 26.6918],
              [-97.4024, 26.3965],
              [-97.1462, 25.9615],
              [-97.2818, 25.9416],
              [-97.3582, 25.8705],
              [-97.4402, 25.8909],
              [-97.5873, 25.9842],
              [-97.8015, 26.042],
              [-98.0828, 26.0644],
              [-98.275, 26.1112],
              [-98.3781, 26.1823],
              [-98.6914, 26.2765],
              [-98.8732, 26.3812],
              [-99.0153, 26.399],
              [-99.1078, 26.4469],
              [-99.1721, 26.5641],
              [-99.2299, 26.7619],
              [-99.3024, 26.8848],
              [-99.4435, 27.0367],
              [-99.4402, 27.1701],
              [-99.51, 27.3403],
              [-99.4842, 27.4673],
              [-99.5053, 27.5483],
              [-99.5953, 27.6358],
              [-99.7543, 27.73],
              [-99.8896, 27.8672],
              [-100.0013, 28.0479],
              [-100.112, 28.173],
              [-100.2961, 28.3277],
              [-100.3318, 28.5026],
              [-100.5498, 28.8214],
              [-100.6364, 28.9728],
              [-100.6587, 29.0686],
              [-100.7546, 29.1825],
              [-101.0163, 29.4007],
              [-101.039, 29.4604],
              [-101.3035, 29.6341],
              [-101.4404, 29.7768],
              [-101.9909, 29.7957],
              [-102.1631, 29.8252],
              [-102.2689, 29.8711],
              [-102.4763, 29.7691],
              [-102.615, 29.7523],
              [-102.7342, 29.644],
              [-102.834, 29.444],
              [-102.892, 29.2164],
              [-103.0228, 29.1322],
              [-103.09, 29.0419],
              [-103.2576, 29.0011],
              [-103.4229, 29.0707],
              [-103.664, 29.2069],
              [-103.8529, 29.2911],
              [-103.9898, 29.3232],
              [-104.1106, 29.3861],
              [-104.3122, 29.5425],
              [-104.4007, 29.5737],
              [-104.504, 29.6776],
              [-104.6223, 29.8543],
              [-104.6813, 29.9905],
              [-104.6813, 30.1344],
              [-104.8359, 30.4477],
              [-104.9788, 30.646],
              [-105.2758, 30.8073],
              [-105.514, 30.9808],
              [-105.8127, 31.241],
              [-106.0241, 31.3978],
              [-106.1481, 31.4508],
              [-106.2558, 31.5447],
              [-106.3469, 31.679],
              [-106.4454, 31.7684],
              [-106.5664, 31.8195],
              [-106.6682, 32.0009],
              [-105.9879, 32.0016],
              [-105.0887, 32.0019],
              [-104.4144, 32.0022],
              [-103.0656, 32.0028],
              [-103.0628, 32.5651],
              [-103.0568, 33.6895],
              [-103.0524, 34.5328],
              [-103.0465, 35.6572],
              [-103.0422, 36.5006],
              [-103.0003, 36.5013],
              [-101.6888, 36.5011],
              [-100.5646, 36.5009],
              [-100.0024, 36.5009],
              [-100.0007, 35.4242],
              [-99.9994, 34.5867],
              [-99.867, 34.5433],
              [-99.6321, 34.3885],
              [-99.405, 34.392],
              [-99.3604, 34.4561],
              [-99.1998, 34.3183],
              [-99.1866, 34.2355],
              [-98.9688, 34.2085],
              [-98.7989, 34.1548],
              [-98.6084, 34.1612],
              [-98.4938, 34.0888],
              [-98.3559, 34.1415],
              [-98.0987, 34.145],
              [-98.0694, 34.0403],
              [-97.9511, 33.9712],
              [-97.9463, 33.8971],
              [-97.8424, 33.8757],
              [-97.6604, 33.9901],
              [-97.4301, 33.8385],
              [-97.2149, 33.8971],
              [-97.1724, 33.7568],
              [-97.119, 33.7347],
              [-96.9777, 33.938],
              [-96.7463, 33.8542],
              [-96.6486, 33.9063],
              [-96.5916, 33.8492],
              [-96.4111, 33.7752],
              [-96.2785, 33.7576],
              [-95.9416, 33.8828],
              [-95.7839, 33.8645],
              [-95.6228, 33.9302],
              [-95.5235, 33.8865],
              [-95.374, 33.8743],
              [-95.2381, 33.9565],
              [-94.9624, 33.8433],
              [-94.8693, 33.7682],
              [-94.7092, 33.6993],
              [-94.4842, 33.6485]
            ]
          ],
          [[[-97.0144, 27.9016], [-96.9787, 28.0138], [-96.8993, 28.1174], [-96.8398, 28.0888], [-97.0144, 27.9016]]]
        ]
      },
      properties: { name: "Texas", id: "US-TX", CNTRY: "United States of America", TYPE: "State" },
      id: "US-TX"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.6676, 36.6051],
            [-82.9558, 36.6021],
            [-81.9525, 36.598],
            [-81.9053, 36.6186],
            [-81.6589, 36.6105],
            [-81.7043, 36.5339],
            [-81.7341, 36.3555],
            [-81.9083, 36.3064],
            [-82.0514, 36.1228],
            [-82.1825, 36.1538],
            [-82.3682, 36.0995],
            [-82.5212, 35.9813],
            [-82.6111, 36.0473],
            [-82.7001, 36.0298],
            [-82.8129, 35.9474],
            [-82.8878, 35.9517],
            [-82.9903, 35.8004],
            [-83.1203, 35.7746],
            [-83.2842, 35.682],
            [-83.5111, 35.5841],
            [-83.7227, 35.5599],
            [-83.8713, 35.5149],
            [-84.0038, 35.4141],
            [-84.0623, 35.286],
            [-84.2324, 35.2606],
            [-84.2871, 35.2008],
            [-84.3246, 34.9881],
            [-85.6236, 35.0009],
            [-86.7465, 35.0114],
            [-87.3883, 35.0174],
            [-88.1904, 35.0249],
            [-88.1733, 34.999],
            [-89.2417, 35],
            [-90.2938, 35.0011],
            [-90.1423, 35.1142],
            [-90.0929, 35.2036],
            [-90.1079, 35.3142],
            [-90.0661, 35.4141],
            [-89.9674, 35.5034],
            [-89.9435, 35.6792],
            [-89.8946, 35.7505],
            [-89.7796, 35.8057],
            [-89.7037, 35.907],
            [-89.7047, 36.0015],
            [-89.6418, 36.1045],
            [-89.6745, 36.2206],
            [-89.5946, 36.2594],
            [-89.5553, 36.3729],
            [-89.5573, 36.5011],
            [-89.4872, 36.5031],
            [-89.4504, 36.5],
            [-88.059, 36.4997],
            [-88.0963, 36.693],
            [-87.8687, 36.675],
            [-87.8391, 36.644],
            [-86.5075, 36.6649],
            [-85.119, 36.6258],
            [-83.7304, 36.5869],
            [-83.6676, 36.6051]
          ]
        ]
      },
      properties: { name: "Tennessee", id: "US-TN", CNTRY: "United States of America", TYPE: "State" },
      id: "US-TN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.5564, 45.9427],
            [-96.6147, 45.8],
            [-96.8136, 45.6592],
            [-96.8455, 45.5959],
            [-96.6961, 45.4318],
            [-96.5298, 45.3715],
            [-96.4537, 45.2973],
            [-96.4537, 44.1747],
            [-96.4537, 43.5012],
            [-96.5986, 43.4976],
            [-96.5416, 43.3735],
            [-96.5554, 43.2316],
            [-96.4627, 43.0992],
            [-96.6218, 42.7296],
            [-96.5387, 42.6586],
            [-96.4809, 42.5112],
            [-96.6328, 42.5687],
            [-96.6755, 42.6376],
            [-96.8045, 42.7014],
            [-97.0197, 42.7602],
            [-97.2387, 42.8606],
            [-97.4167, 42.8811],
            [-97.6981, 42.8747],
            [-97.8809, 42.8496],
            [-97.9649, 42.8058],
            [-98.1433, 42.8523],
            [-98.4417, 43.0011],
            [-99.5294, 43.0012],
            [-100.5677, 43.0012],
            [-101.9522, 43.0012],
            [-102.9905, 43.0013],
            [-104.0288, 43.0013],
            [-104.032, 43.7513],
            [-104.034, 44.2513],
            [-104.0372, 45.0011],
            [-104.0055, 45.001],
            [-104.0111, 45.943],
            [-102.6134, 45.943],
            [-101.6815, 45.943],
            [-100.9827, 45.943],
            [-99.8179, 45.9429],
            [-99.119, 45.9429],
            [-98.1872, 45.9428],
            [-97.2553, 45.9428],
            [-96.5564, 45.9427]
          ]
        ]
      },
      properties: { name: "South Dakota", id: "US-SD", CNTRY: "United States of America", TYPE: "State" },
      id: "US-SD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.5643, 33.8766],
            [-78.8414, 33.7241],
            [-78.9204, 33.6587],
            [-79.1382, 33.4059],
            [-79.1938, 33.2442],
            [-79.276, 33.1354],
            [-79.4199, 33.0426],
            [-79.5871, 33.0009],
            [-79.615, 32.9093],
            [-79.8936, 32.7286],
            [-80.0217, 32.6198],
            [-80.2297, 32.5764],
            [-80.3629, 32.5007],
            [-80.5723, 32.5337],
            [-80.4744, 32.4227],
            [-80.4857, 32.3518],
            [-80.6082, 32.2927],
            [-80.6472, 32.3959],
            [-80.7654, 32.2983],
            [-80.6943, 32.2158],
            [-80.8492, 32.1139],
            [-80.8724, 32.0296],
            [-81.0748, 32.1096],
            [-81.1351, 32.1819],
            [-81.1715, 32.3801],
            [-81.2904, 32.5572],
            [-81.377, 32.6075],
            [-81.4338, 32.728],
            [-81.4897, 32.9356],
            [-81.5782, 33.0686],
            [-81.6992, 33.1267],
            [-81.9329, 33.3899],
            [-82.0058, 33.5229],
            [-82.2083, 33.6636],
            [-82.2566, 33.7492],
            [-82.5894, 34.0176],
            [-82.8186, 34.3661],
            [-82.8477, 34.4369],
            [-83.0528, 34.5108],
            [-83.3556, 34.7084],
            [-83.3167, 34.8057],
            [-83.1149, 35.0015],
            [-82.3561, 35.2016],
            [-81.0874, 35.1557],
            [-81.0452, 35.0656],
            [-80.9338, 35.1136],
            [-80.793, 34.9628],
            [-80.7841, 34.8286],
            [-79.6535, 34.7968],
            [-78.9784, 34.2261],
            [-78.5643, 33.8766]
          ]
        ]
      },
      properties: { name: "South Carolina", id: "US-SC", CNTRY: "United States of America", TYPE: "State" },
      id: "US-SC"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.2338, 41.7065],
            [-71.3305, 41.7622],
            [-71.4265, 41.6333],
            [-71.4438, 41.4536],
            [-71.5229, 41.379],
            [-71.8424, 41.3355],
            [-71.7958, 41.5199],
            [-71.8008, 42.0119],
            [-71.3871, 42.0168],
            [-71.3408, 41.7979],
            [-71.2338, 41.7065]
          ]
        ]
      },
      properties: { name: "Rhode Island", id: "US-RI", CNTRY: "United States of America", TYPE: "State" },
      id: "US-RI"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.699, 41.3573],
            [-74.8415, 41.2686],
            [-74.9123, 41.1552],
            [-75.1229, 40.999],
            [-75.075, 40.8845],
            [-75.1747, 40.7755],
            [-75.189, 40.5959],
            [-75.0975, 40.5432],
            [-74.7349, 40.1545],
            [-75.0742, 39.9835],
            [-75.1729, 39.8948],
            [-75.4211, 39.8154],
            [-75.6345, 39.8395],
            [-75.7848, 39.7224],
            [-76.7079, 39.7224],
            [-78.0927, 39.7226],
            [-79.4776, 39.7228],
            [-80.5195, 39.7225],
            [-80.5191, 40.6469],
            [-80.5203, 41.3816],
            [-80.5203, 41.9896],
            [-80.3346, 42.0408],
            [-79.763, 42.2756],
            [-79.763, 42.0008],
            [-78.66, 42.0002],
            [-77.2814, 41.9995],
            [-76.1786, 41.9988],
            [-75.3514, 41.9983],
            [-75.2389, 41.8922],
            [-75.1218, 41.8534],
            [-75.0226, 41.5528],
            [-74.9354, 41.4744],
            [-74.7566, 41.4239],
            [-74.699, 41.3573]
          ]
        ]
      },
      properties: { name: "Pennsylvania", id: "US-PA", CNTRY: "United States of America", TYPE: "State" },
      id: "US-PA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.2206, 46.1536],
            [-123.1209, 46.1787],
            [-122.942, 46.1149],
            [-122.8434, 45.9781],
            [-122.7259, 45.6737],
            [-122.6498, 45.6275],
            [-122.2025, 45.591],
            [-122.0851, 45.6027],
            [-121.8821, 45.6895],
            [-121.6625, 45.7181],
            [-121.4022, 45.7118],
            [-121.1843, 45.6437],
            [-121.0452, 45.639],
            [-120.7158, 45.6898],
            [-120.3515, 45.7252],
            [-120.156, 45.7622],
            [-119.9343, 45.8379],
            [-119.4291, 45.9342],
            [-119.1773, 45.9451],
            [-119.0197, 46.0003],
            [-118.2061, 46.0013],
            [-116.8965, 46.0021],
            [-116.7647, 45.8729],
            [-116.6168, 45.7992],
            [-116.4771, 45.6411],
            [-116.5492, 45.4567],
            [-116.8395, 44.9851],
            [-116.9489, 44.7702],
            [-117.1189, 44.5783],
            [-117.2017, 44.4362],
            [-117.1397, 44.2903],
            [-116.9532, 44.2453],
            [-116.9113, 44.1574],
            [-116.9607, 44.0791],
            [-116.9576, 43.9642],
            [-117.0205, 43.8132],
            [-117.0189, 43.1165],
            [-117.0167, 42.0006],
            [-117.7627, 42.0006],
            [-119.2547, 42.0006],
            [-120.0008, 42.0006],
            [-121.0555, 42.0006],
            [-122.11, 42.0006],
            [-123.4283, 42.0006],
            [-124.2284, 42.0007],
            [-124.3553, 42.1229],
            [-124.4206, 42.381],
            [-124.4062, 42.5837],
            [-124.5396, 42.8129],
            [-124.4545, 43.0124],
            [-124.3466, 43.3417],
            [-124.288, 43.4098],
            [-124.1487, 43.6918],
            [-124.1307, 44.0556],
            [-124.0992, 44.3337],
            [-124.0474, 44.4255],
            [-124.0592, 44.7778],
            [-123.9486, 45.4009],
            [-123.9294, 45.577],
            [-123.9612, 45.843],
            [-123.9471, 46.1406],
            [-123.9117, 46.1823],
            [-123.6736, 46.1826],
            [-123.5216, 46.2227],
            [-123.4023, 46.155],
            [-123.2206, 46.1536]
          ]
        ]
      },
      properties: { name: "Oregon", id: "US-OR", CNTRY: "United States of America", TYPE: "State" },
      id: "US-OR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.6183, 36.5009],
            [-94.4391, 35.3872],
            [-94.4532, 34.8438],
            [-94.4842, 33.6485],
            [-94.7092, 33.6993],
            [-94.8693, 33.7682],
            [-94.9624, 33.8433],
            [-95.2381, 33.9565],
            [-95.374, 33.8743],
            [-95.5235, 33.8865],
            [-95.6228, 33.9302],
            [-95.7839, 33.8645],
            [-95.9416, 33.8828],
            [-96.2785, 33.7576],
            [-96.4111, 33.7752],
            [-96.5916, 33.8492],
            [-96.6486, 33.9063],
            [-96.7463, 33.8542],
            [-96.9777, 33.938],
            [-97.119, 33.7347],
            [-97.1724, 33.7568],
            [-97.2149, 33.8971],
            [-97.4301, 33.8385],
            [-97.6604, 33.9901],
            [-97.8424, 33.8757],
            [-97.9463, 33.8971],
            [-97.9511, 33.9712],
            [-98.0694, 34.0403],
            [-98.0987, 34.145],
            [-98.3559, 34.1415],
            [-98.4938, 34.0888],
            [-98.6084, 34.1612],
            [-98.7989, 34.1548],
            [-98.9688, 34.2085],
            [-99.1866, 34.2355],
            [-99.1998, 34.3183],
            [-99.3604, 34.4561],
            [-99.405, 34.392],
            [-99.6321, 34.3885],
            [-99.867, 34.5433],
            [-99.9994, 34.5867],
            [-100.0007, 35.4242],
            [-100.0024, 36.5009],
            [-100.5646, 36.5009],
            [-101.6888, 36.5011],
            [-103.0003, 36.5013],
            [-103.0008, 37.0008],
            [-102.0128, 37.0008],
            [-100.8573, 37.0008],
            [-99.4708, 37.0008],
            [-98.7775, 37.0008],
            [-97.8532, 37.0008],
            [-96.6979, 37.0008],
            [-95.5424, 37.0008],
            [-94.618, 37.0008],
            [-94.6183, 36.5009]
          ]
        ]
      },
      properties: { name: "Oklahoma", id: "US-OK", CNTRY: "United States of America", TYPE: "State" },
      id: "US-OK"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.5191, 40.6469],
            [-80.6591, 40.563],
            [-80.6019, 40.3639],
            [-80.6683, 40.1853],
            [-80.8721, 39.7355],
            [-80.8744, 39.6642],
            [-80.9633, 39.5712],
            [-81.1391, 39.456],
            [-81.3333, 39.3628],
            [-81.4397, 39.3926],
            [-81.6571, 39.272],
            [-81.8027, 39.086],
            [-81.7777, 38.9729],
            [-82.0723, 38.963],
            [-82.2035, 38.7682],
            [-82.18, 38.6376],
            [-82.3871, 38.434],
            [-82.4976, 38.4149],
            [-82.6128, 38.4482],
            [-82.7583, 38.5411],
            [-82.8805, 38.7103],
            [-83.0001, 38.714],
            [-83.2035, 38.6388],
            [-83.357, 38.6274],
            [-83.5384, 38.6999],
            [-83.6899, 38.6496],
            [-83.9484, 38.7742],
            [-84.1132, 38.7984],
            [-84.2273, 38.8652],
            [-84.3581, 39.0499],
            [-84.5088, 39.1029],
            [-84.7069, 39.1289],
            [-84.8217, 39.092],
            [-84.8133, 40.0745],
            [-84.803, 41.0506],
            [-84.7961, 41.7015],
            [-84.1301, 41.7204],
            [-83.4543, 41.7456],
            [-82.9108, 41.5382],
            [-82.8308, 41.5797],
            [-82.5244, 41.407],
            [-82.1214, 41.493],
            [-81.7231, 41.5109],
            [-81.4471, 41.6714],
            [-81.2286, 41.7704],
            [-80.8692, 41.8932],
            [-80.5203, 41.9896],
            [-80.5203, 41.3816],
            [-80.5191, 40.6469]
          ]
        ]
      },
      properties: { name: "Ohio", id: "US-OH", CNTRY: "United States of America", TYPE: "State" },
      id: "US-OH"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-72.5098, 40.9861],
              [-72.2875, 41.0241],
              [-72.1019, 41.015],
              [-72.3391, 40.8942],
              [-73.1943, 40.6542],
              [-73.2655, 40.6636],
              [-73.6209, 40.5999],
              [-73.7668, 40.5927],
              [-73.8227, 40.656],
              [-73.929, 40.5988],
              [-74.032, 40.6386],
              [-73.8793, 40.7917],
              [-73.5739, 40.9196],
              [-73.3727, 40.9438],
              [-73.1859, 40.9298],
              [-73.0337, 40.966],
              [-72.5098, 40.9861]
            ]
          ],
          [
            [
              [-73.2533, 42.7523],
              [-73.5072, 42.08],
              [-73.4806, 42.0555],
              [-73.5447, 41.296],
              [-73.4842, 41.219],
              [-73.723, 41.1045],
              [-73.6306, 40.9919],
              [-73.8512, 40.8314],
              [-73.9486, 40.8387],
              [-73.9101, 40.9923],
              [-74.699, 41.3573],
              [-74.7566, 41.4239],
              [-74.9354, 41.4744],
              [-75.0226, 41.5528],
              [-75.1218, 41.8534],
              [-75.2389, 41.8922],
              [-75.3514, 41.9983],
              [-76.1786, 41.9988],
              [-77.2814, 41.9995],
              [-78.66, 42.0002],
              [-79.763, 42.0008],
              [-79.763, 42.2756],
              [-79.5123, 42.391],
              [-79.2092, 42.5552],
              [-79.0626, 42.6797],
              [-78.9182, 42.764],
              [-78.8722, 42.8359],
              [-78.9197, 42.9482],
              [-78.9081, 43.0703],
              [-79.0661, 43.1062],
              [-79.0531, 43.2851],
              [-78.6895, 43.3615],
              [-78.175, 43.3947],
              [-77.8382, 43.3571],
              [-77.529, 43.2674],
              [-77.2709, 43.2945],
              [-76.9764, 43.2774],
              [-76.6928, 43.3609],
              [-76.4728, 43.5073],
              [-76.2026, 43.5746],
              [-76.1984, 43.8744],
              [-76.0987, 43.9671],
              [-76.2378, 44.1832],
              [-75.8322, 44.3973],
              [-75.7919, 44.4971],
              [-75.4013, 44.7722],
              [-75.1795, 44.8994],
              [-74.8565, 45.0039],
              [-73.3521, 45.0054],
              [-73.3485, 44.7753],
              [-73.372, 44.5974],
              [-73.3097, 44.4597],
              [-73.321, 44.2689],
              [-73.4097, 44.1314],
              [-73.425, 44.0383],
              [-73.3758, 43.7622],
              [-73.4134, 43.6278],
              [-73.247, 43.553],
              [-73.2665, 42.8648],
              [-73.2533, 42.7523]
            ]
          ]
        ]
      },
      properties: { name: "New York", id: "US-NY", CNTRY: "United States of America", TYPE: "State" },
      id: "US-NY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.0402, 37.0041],
            [-114.0429, 36.1817],
            [-114.1832, 36.0303],
            [-114.4061, 36.1483],
            [-114.593, 36.1476],
            [-114.7158, 36.085],
            [-114.7413, 36.0136],
            [-114.6871, 35.9173],
            [-114.6451, 35.6305],
            [-114.6486, 35.4759],
            [-114.591, 35.3527],
            [-114.6105, 34.9912],
            [-115.39, 35.599],
            [-115.8979, 35.9954],
            [-116.6596, 36.59],
            [-117.2096, 37.0033],
            [-118.1263, 37.6923],
            [-118.5947, 38.0193],
            [-119.2974, 38.5098],
            [-120.0001, 39.0003],
            [-120.0003, 39.9378],
            [-120.0006, 41.063],
            [-120.0008, 42.0006],
            [-119.2547, 42.0006],
            [-117.7627, 42.0006],
            [-117.0167, 42.0006],
            [-116.2732, 42.0007],
            [-115.1578, 42.0008],
            [-114.0426, 42.0011],
            [-114.0423, 41.3766],
            [-114.042, 40.7518],
            [-114.0417, 39.9711],
            [-114.0413, 39.0342],
            [-114.0408, 37.941],
            [-114.0402, 37.0041]
          ]
        ]
      },
      properties: { name: "Nevada", id: "US-NV", CNTRY: "United States of America", TYPE: "State" },
      id: "US-NV"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.0003, 36.5013],
            [-103.0422, 36.5006],
            [-103.0465, 35.6572],
            [-103.0524, 34.5328],
            [-103.0568, 33.6895],
            [-103.0628, 32.5651],
            [-103.0656, 32.0028],
            [-104.4144, 32.0022],
            [-105.0887, 32.0019],
            [-105.9879, 32.0016],
            [-106.6682, 32.0009],
            [-106.5664, 31.8195],
            [-106.4454, 31.7684],
            [-107.1127, 31.7736],
            [-108.2119, 31.7793],
            [-108.2145, 31.3293],
            [-109.0478, 31.3279],
            [-109.0473, 32.3922],
            [-109.0472, 33.1013],
            [-109.0471, 34.1648],
            [-109.0469, 35.2284],
            [-109.0468, 36.2918],
            [-109.0467, 37.0008],
            [-107.9131, 37.0008],
            [-106.7795, 37.0008],
            [-106.0238, 37.0008],
            [-105.2681, 37.0008],
            [-104.1345, 37.0008],
            [-103.0008, 37.0008],
            [-103.0003, 36.5013]
          ]
        ]
      },
      properties: { name: "New Mexico", id: "US-NM", CNTRY: "United States of America", TYPE: "State" },
      id: "US-NM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9101, 40.9923],
            [-74.0256, 40.7563],
            [-74.2266, 40.608],
            [-74.2415, 40.4563],
            [-74.0498, 40.4298],
            [-73.9576, 40.3283],
            [-74.0283, 40.073],
            [-74.1176, 39.9381],
            [-74.1762, 39.7266],
            [-74.3899, 39.4869],
            [-74.4289, 39.3872],
            [-74.6029, 39.2926],
            [-74.7945, 39.0019],
            [-74.9543, 38.95],
            [-74.8971, 39.1454],
            [-75.0502, 39.2109],
            [-75.1361, 39.2079],
            [-75.3534, 39.3398],
            [-75.5243, 39.4902],
            [-75.5236, 39.6019],
            [-75.4219, 39.7897],
            [-75.1537, 39.8705],
            [-75.0742, 39.9835],
            [-74.7349, 40.1545],
            [-75.0975, 40.5432],
            [-75.189, 40.5959],
            [-75.1747, 40.7755],
            [-75.075, 40.8845],
            [-75.1229, 40.999],
            [-74.9123, 41.1552],
            [-74.8415, 41.2686],
            [-74.699, 41.3573],
            [-73.9101, 40.9923]
          ]
        ]
      },
      properties: { name: "New Jersey", id: "US-NJ", CNTRY: "United States of America", TYPE: "State" },
      id: "US-NJ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.7331, 43.07],
            [-70.8061, 42.8767],
            [-70.9741, 42.8716],
            [-71.3296, 42.7025],
            [-72.4668, 42.7303],
            [-72.5498, 42.8867],
            [-72.4737, 43.0385],
            [-72.4071, 43.332],
            [-72.385, 43.5292],
            [-72.2967, 43.7149],
            [-72.115, 43.9655],
            [-72.0312, 44.3007],
            [-71.6829, 44.4503],
            [-71.5684, 44.6076],
            [-71.6206, 44.772],
            [-71.5102, 44.9083],
            [-71.5175, 45.0075],
            [-71.419, 45.2003],
            [-71.3273, 45.2901],
            [-71.2016, 45.2603],
            [-71.0846, 45.294],
            [-71.0238, 44.419],
            [-70.9557, 43.3894],
            [-70.829, 43.239],
            [-70.7331, 43.07]
          ]
        ]
      },
      properties: { name: "New Hampshire", id: "US-NH", CNTRY: "United States of America", TYPE: "State" },
      id: "US-NH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.4809, 42.5112],
            [-96.3837, 42.4178],
            [-96.3561, 42.2602],
            [-96.2953, 42.1329],
            [-96.1543, 41.9653],
            [-96.0688, 41.7344],
            [-96.0586, 41.5458],
            [-95.933, 41.4254],
            [-95.8836, 41.2875],
            [-95.8339, 40.8896],
            [-95.859, 40.7451],
            [-95.765, 40.6018],
            [-95.6071, 40.2955],
            [-95.4637, 40.198],
            [-95.4343, 40.096],
            [-95.3466, 40.0015],
            [-96.7845, 40.0011],
            [-97.8325, 40.0011],
            [-98.8805, 40.0011],
            [-100.3476, 40.0011],
            [-101.1861, 40.0011],
            [-102.0245, 40.0011],
            [-102.025, 41.0006],
            [-103.2729, 41.0007],
            [-104.0217, 41.0008],
            [-104.0243, 41.751],
            [-104.0271, 42.5013],
            [-104.0288, 43.0013],
            [-102.9905, 43.0013],
            [-101.9522, 43.0012],
            [-100.5677, 43.0012],
            [-99.5294, 43.0012],
            [-98.4417, 43.0011],
            [-98.1433, 42.8523],
            [-97.9649, 42.8058],
            [-97.8809, 42.8496],
            [-97.6981, 42.8747],
            [-97.4167, 42.8811],
            [-97.2387, 42.8606],
            [-97.0197, 42.7602],
            [-96.8045, 42.7014],
            [-96.6755, 42.6376],
            [-96.6328, 42.5687],
            [-96.4809, 42.5112]
          ]
        ]
      },
      properties: { name: "Nebraska", id: "US-NE", CNTRY: "United States of America", TYPE: "State" },
      id: "US-NE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.5564, 45.9427],
            [-97.2553, 45.9428],
            [-98.1872, 45.9428],
            [-99.119, 45.9429],
            [-99.8179, 45.9429],
            [-100.9827, 45.943],
            [-101.6815, 45.943],
            [-102.6134, 45.943],
            [-104.0111, 45.943],
            [-104.0153, 46.5151],
            [-104.0222, 47.4685],
            [-104.0292, 48.422],
            [-104.0339, 48.9932],
            [-103.0728, 48.9932],
            [-102.2201, 48.9932],
            [-100.941, 48.9932],
            [-100.0881, 48.9932],
            [-99.2354, 48.9932],
            [-97.9563, 48.9932],
            [-97.2257, 48.9932],
            [-97.1281, 48.6812],
            [-97.144, 48.5367],
            [-97.1259, 48.1734],
            [-97.0074, 47.9256],
            [-96.988, 47.8204],
            [-96.8588, 47.5883],
            [-96.8183, 46.9741],
            [-96.7701, 46.9197],
            [-96.7867, 46.6482],
            [-96.7309, 46.4728],
            [-96.6175, 46.3271],
            [-96.5608, 46.1382],
            [-96.5564, 45.9427]
          ]
        ]
      },
      properties: { name: "North Dakota", id: "US-ND", CNTRY: "United States of America", TYPE: "State" },
      id: "US-ND"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.9664, 36.5514],
            [-75.9928, 36.4738],
            [-75.8667, 36.2678],
            [-75.883, 36.1757],
            [-76.1479, 36.2793],
            [-76.1501, 36.1458],
            [-76.2274, 36.116],
            [-76.3836, 36.1335],
            [-76.4788, 36.0282],
            [-76.5594, 36.0154],
            [-76.679, 36.0753],
            [-76.7262, 35.9577],
            [-76.3584, 35.953],
            [-76.2065, 35.9912],
            [-76.0698, 35.9704],
            [-76.0836, 35.6905],
            [-76.0012, 35.7222],
            [-75.9789, 35.896],
            [-75.8538, 35.9602],
            [-75.7722, 35.8999],
            [-75.7447, 35.7655],
            [-75.774, 35.647],
            [-75.966, 35.5084],
            [-76.1035, 35.3803],
            [-76.1739, 35.3541],
            [-76.3902, 35.4012],
            [-76.4895, 35.397],
            [-76.5325, 35.5084],
            [-76.7413, 35.4315],
            [-76.5129, 35.2705],
            [-76.6075, 35.1531],
            [-76.6281, 35.0733],
            [-76.7791, 34.9903],
            [-76.745, 34.9409],
            [-76.4568, 34.9894],
            [-76.3622, 34.9364],
            [-76.5169, 34.7773],
            [-76.7071, 34.7521],
            [-76.7331, 34.707],
            [-77.1338, 34.7079],
            [-77.2518, 34.6157],
            [-77.3584, 34.6203],
            [-77.3798, 34.5265],
            [-77.5176, 34.4513],
            [-77.7507, 34.285],
            [-77.9261, 34.0732],
            [-78.0133, 33.9119],
            [-78.4058, 33.9175],
            [-78.5643, 33.8766],
            [-78.9784, 34.2261],
            [-79.6535, 34.7968],
            [-80.7841, 34.8286],
            [-80.793, 34.9628],
            [-80.9338, 35.1136],
            [-81.0452, 35.0656],
            [-81.0874, 35.1557],
            [-82.3561, 35.2016],
            [-83.1149, 35.0015],
            [-84.3246, 34.9881],
            [-84.2871, 35.2008],
            [-84.2324, 35.2606],
            [-84.0623, 35.286],
            [-84.0038, 35.4141],
            [-83.8713, 35.5149],
            [-83.7227, 35.5599],
            [-83.5111, 35.5841],
            [-83.2842, 35.682],
            [-83.1203, 35.7746],
            [-82.9903, 35.8004],
            [-82.8878, 35.9517],
            [-82.8129, 35.9474],
            [-82.7001, 36.0298],
            [-82.6111, 36.0473],
            [-82.5212, 35.9813],
            [-82.3682, 36.0995],
            [-82.1825, 36.1538],
            [-82.0514, 36.1228],
            [-81.9083, 36.3064],
            [-81.7341, 36.3555],
            [-81.7043, 36.5339],
            [-81.6589, 36.6105],
            [-81.6416, 36.595],
            [-80.5562, 36.5866],
            [-79.4709, 36.5782],
            [-78.3855, 36.5697],
            [-77.662, 36.5641],
            [-76.5766, 36.5557],
            [-75.9664, 36.5514]
          ]
        ]
      },
      properties: { name: "North Carolina", id: "US-NC", CNTRY: "United States of America", TYPE: "State" },
      id: "US-NC"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.0111, 45.943],
            [-104.0055, 45.001],
            [-104.0372, 45.0011],
            [-105.3523, 45.0011],
            [-106.4482, 45.001],
            [-107.3249, 45.001],
            [-108.2016, 45.001],
            [-109.5167, 45.001],
            [-111.0508, 45.001],
            [-111.0515, 44.4989],
            [-111.1977, 44.5906],
            [-111.3375, 44.7447],
            [-111.4481, 44.7112],
            [-111.4999, 44.5448],
            [-111.7971, 44.5399],
            [-112.2313, 44.5607],
            [-112.3928, 44.468],
            [-112.716, 44.4981],
            [-112.8155, 44.406],
            [-112.9997, 44.4804],
            [-113.0933, 44.7153],
            [-113.1872, 44.7986],
            [-113.3389, 44.808],
            [-113.4693, 44.93],
            [-113.4711, 45.0729],
            [-113.6971, 45.3049],
            [-113.8023, 45.6011],
            [-113.9427, 45.6815],
            [-114.2977, 45.4876],
            [-114.5292, 45.5757],
            [-114.4958, 45.6858],
            [-114.5337, 45.782],
            [-114.4051, 45.8742],
            [-114.4783, 46.0356],
            [-114.4853, 46.1582],
            [-114.3458, 46.5506],
            [-114.3509, 46.6677],
            [-114.6016, 46.6603],
            [-114.7537, 46.7346],
            [-114.9357, 46.9228],
            [-115.3495, 47.2611],
            [-115.486, 47.3117],
            [-115.7025, 47.4377],
            [-115.6697, 47.4978],
            [-115.7338, 47.7064],
            [-116.049, 48.0047],
            [-116.0486, 48.9931],
            [-115.0115, 48.9931],
            [-114.1587, 48.9931],
            [-113.306, 48.9931],
            [-112.4532, 48.9931],
            [-111.1741, 48.9931],
            [-110.3213, 48.9931],
            [-109.4685, 48.9931],
            [-108.1894, 48.9932],
            [-107.3366, 48.9932],
            [-106.4839, 48.9932],
            [-105.6311, 48.9932],
            [-104.0339, 48.9932],
            [-104.0292, 48.422],
            [-104.0222, 47.4685],
            [-104.0153, 46.5151],
            [-104.0111, 45.943]
          ]
        ]
      },
      properties: { name: "Montana", id: "US-MT", CNTRY: "United States of America", TYPE: "State" },
      id: "US-MT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.1733, 34.999],
            [-88.0848, 34.933],
            [-88.1444, 34.4767],
            [-88.2665, 33.5531],
            [-88.3397, 32.9989],
            [-88.4862, 31.8905],
            [-88.4443, 31.1397],
            [-88.3995, 30.3709],
            [-88.6921, 30.3553],
            [-88.9052, 30.4152],
            [-89.2237, 30.3324],
            [-89.3206, 30.3453],
            [-89.4435, 30.2231],
            [-89.5209, 30.1926],
            [-89.5917, 30.223],
            [-89.6858, 30.4627],
            [-89.82, 30.6111],
            [-89.8353, 30.7308],
            [-89.7354, 31.0012],
            [-90.9366, 31.0012],
            [-91.6565, 31.0018],
            [-91.5964, 31.1099],
            [-91.6308, 31.2176],
            [-91.529, 31.3452],
            [-91.529, 31.4488],
            [-91.4146, 31.64],
            [-91.3725, 31.7737],
            [-91.2865, 31.884],
            [-91.1566, 31.9707],
            [-91.0888, 32.1042],
            [-90.9477, 32.2401],
            [-90.9448, 32.3307],
            [-91.1038, 32.4905],
            [-91.1424, 32.661],
            [-91.0943, 32.6943],
            [-91.136, 32.8089],
            [-91.0962, 32.9377],
            [-91.1499, 33.0159],
            [-91.1068, 33.2179],
            [-91.1633, 33.2945],
            [-91.141, 33.4385],
            [-91.2146, 33.5834],
            [-91.2075, 33.6757],
            [-91.0528, 33.8276],
            [-91.0702, 33.9743],
            [-90.943, 34.0298],
            [-90.9098, 34.1887],
            [-90.8172, 34.2522],
            [-90.718, 34.3916],
            [-90.6354, 34.4139],
            [-90.584, 34.5037],
            [-90.5392, 34.7282],
            [-90.416, 34.8516],
            [-90.3199, 34.8992],
            [-90.2938, 35.0011],
            [-89.2417, 35],
            [-88.1733, 34.999]
          ]
        ]
      },
      properties: { name: "Mississippi", id: "US-MS", CNTRY: "United States of America", TYPE: "State" },
      id: "US-MS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.4419, 40.3795],
            [-91.4998, 40.226],
            [-91.4668, 39.9429],
            [-91.3787, 39.7494],
            [-91.2099, 39.6029],
            [-90.9194, 39.3868],
            [-90.7372, 39.1818],
            [-90.6635, 38.9875],
            [-90.5771, 38.9097],
            [-90.478, 38.9482],
            [-90.2201, 38.8976],
            [-90.1268, 38.8326],
            [-90.3166, 38.4578],
            [-90.374, 38.3097],
            [-90.299, 38.174],
            [-90.1737, 38.0691],
            [-89.823, 37.8841],
            [-89.6233, 37.7473],
            [-89.5234, 37.6445],
            [-89.4619, 37.4341],
            [-89.5029, 37.3005],
            [-89.414, 37.1024],
            [-89.2724, 37.0799],
            [-89.1543, 36.9921],
            [-89.1225, 36.9116],
            [-89.1563, 36.7157],
            [-89.203, 36.6255],
            [-89.3974, 36.5878],
            [-89.4504, 36.5],
            [-89.4872, 36.5031],
            [-89.5573, 36.5011],
            [-89.5553, 36.3729],
            [-89.5946, 36.2594],
            [-89.6745, 36.2206],
            [-89.6418, 36.1045],
            [-89.7047, 36.0015],
            [-90.3811, 35.9928],
            [-90.303, 36.0993],
            [-90.0757, 36.2967],
            [-90.0745, 36.3718],
            [-90.1622, 36.5009],
            [-91.2763, 36.5009],
            [-92.6688, 36.5009],
            [-93.7828, 36.5009],
            [-94.6183, 36.5009],
            [-94.618, 37.0008],
            [-94.6217, 38.1738],
            [-94.6224, 39.1244],
            [-94.7778, 39.1939],
            [-94.8823, 39.3432],
            [-95.0579, 39.509],
            [-95.0754, 39.5973],
            [-94.8933, 39.7818],
            [-94.9841, 39.8911],
            [-95.1162, 39.8721],
            [-95.3466, 40.0015],
            [-95.4343, 40.096],
            [-95.4637, 40.198],
            [-95.6071, 40.2955],
            [-95.765, 40.6018],
            [-95.0109, 40.6058],
            [-93.4973, 40.6145],
            [-92.4882, 40.6202],
            [-91.7316, 40.6247],
            [-91.682, 40.5519],
            [-91.4419, 40.3795]
          ]
        ]
      },
      properties: { name: "Missouri", id: "US-MO", CNTRY: "United States of America", TYPE: "State" },
      id: "US-MO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.3779, 48.6166],
            [-93.0516, 48.6199],
            [-92.8368, 48.5678],
            [-92.5005, 48.4354],
            [-92.4146, 48.2766],
            [-92.1717, 48.3384],
            [-92.0052, 48.3018],
            [-91.8584, 48.1976],
            [-91.5184, 48.0583],
            [-91.2207, 48.1046],
            [-91.0435, 48.1937],
            [-90.8403, 48.2006],
            [-90.7445, 48.1046],
            [-90.3201, 48.0992],
            [-90.0918, 48.1181],
            [-89.9936, 48.0153],
            [-89.6339, 47.9937],
            [-89.8288, 47.9009],
            [-90.1973, 47.7864],
            [-90.5975, 47.6878],
            [-90.9571, 47.5237],
            [-91.3862, 47.2148],
            [-91.6451, 47.0501],
            [-92.0645, 46.8188],
            [-92.1069, 46.7624],
            [-92.2917, 46.6607],
            [-92.2926, 46.0844],
            [-92.6939, 45.909],
            [-92.8747, 45.7061],
            [-92.8925, 45.5948],
            [-92.7071, 45.4938],
            [-92.6858, 45.3805],
            [-92.7661, 45.2364],
            [-92.793, 45.0714],
            [-92.7634, 44.9342],
            [-92.7992, 44.79],
            [-92.6436, 44.6454],
            [-92.3669, 44.5522],
            [-92.2403, 44.4621],
            [-92.0552, 44.3998],
            [-91.8409, 44.1941],
            [-91.7165, 44.1265],
            [-91.3827, 43.9908],
            [-91.32, 43.9362],
            [-91.2784, 43.7975],
            [-91.2452, 43.5025],
            [-92.2258, 43.5018],
            [-93.5267, 43.5016],
            [-94.5023, 43.5015],
            [-95.8031, 43.5013],
            [-96.4537, 43.5012],
            [-96.4537, 44.1747],
            [-96.4537, 45.2973],
            [-96.5298, 45.3715],
            [-96.6961, 45.4318],
            [-96.8455, 45.5959],
            [-96.8136, 45.6592],
            [-96.6147, 45.8],
            [-96.5564, 45.9427],
            [-96.5608, 46.1382],
            [-96.6175, 46.3271],
            [-96.7309, 46.4728],
            [-96.7867, 46.6482],
            [-96.7701, 46.9197],
            [-96.8183, 46.9741],
            [-96.8588, 47.5883],
            [-96.988, 47.8204],
            [-97.0074, 47.9256],
            [-97.1259, 48.1734],
            [-97.144, 48.5367],
            [-97.1281, 48.6812],
            [-97.2257, 48.9932],
            [-95.8242, 48.9932],
            [-95.1621, 48.9918],
            [-95.1554, 49.3697],
            [-94.9394, 49.3495],
            [-94.8543, 49.3046],
            [-94.8426, 49.1192],
            [-94.7051, 48.8086],
            [-94.6208, 48.7426],
            [-94.0552, 48.659],
            [-93.8516, 48.6073],
            [-93.7078, 48.5254],
            [-93.4637, 48.5612],
            [-93.3779, 48.6166]
          ]
        ]
      },
      properties: { name: "Minnesota", id: "US-MN", CNTRY: "United States of America", TYPE: "State" },
      id: "US-MN"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-83.5034, 46.0131],
              [-83.518, 45.9579],
              [-83.7308, 45.943],
              [-83.8657, 45.9792],
              [-83.6082, 46.0966],
              [-83.5034, 46.0131]
            ]
          ],
          [
            [
              [-88.9552, 47.926],
              [-88.97, 47.8813],
              [-89.1568, 47.8408],
              [-89.1466, 47.9526],
              [-88.5883, 48.1585],
              [-88.6247, 48.042],
              [-88.9552, 47.926]
            ]
          ],
          [
            [
              [-88.2219, 47.2106],
              [-88.2368, 47.1589],
              [-88.439, 47.0108],
              [-88.4341, 47.1222],
              [-88.6158, 47.1742],
              [-88.5619, 47.2721],
              [-88.3944, 47.3887],
              [-88.1103, 47.4726],
              [-87.8779, 47.4841],
              [-87.7905, 47.4114],
              [-88.0206, 47.3924],
              [-88.0155, 47.3208],
              [-88.2219, 47.2106]
            ]
          ],
          [
            [
              [-83.4543, 41.7456],
              [-84.1301, 41.7204],
              [-84.7961, 41.7015],
              [-84.7964, 41.7597],
              [-86.0088, 41.7602],
              [-86.8627, 41.7606],
              [-86.6324, 41.9114],
              [-86.4073, 42.2237],
              [-86.2995, 42.4177],
              [-86.2328, 42.65],
              [-86.2215, 42.9005],
              [-86.329, 43.2241],
              [-86.542, 43.6373],
              [-86.4405, 43.7943],
              [-86.4486, 43.9239],
              [-86.5192, 44.0737],
              [-86.2959, 44.3306],
              [-86.2423, 44.5514],
              [-86.237, 44.7202],
              [-86.1259, 44.7477],
              [-86.0576, 44.9164],
              [-85.8578, 44.9615],
              [-85.6893, 45.1281],
              [-85.5877, 45.1614],
              [-85.6047, 44.97],
              [-85.6472, 44.8376],
              [-85.4847, 44.8403],
              [-85.3891, 45.0328],
              [-85.3756, 45.277],
              [-85.2229, 45.3562],
              [-84.9671, 45.3926],
              [-85.0877, 45.5068],
              [-85.0877, 45.6053],
              [-84.9515, 45.7753],
              [-84.772, 45.7948],
              [-84.4414, 45.6762],
              [-84.1893, 45.6111],
              [-84.1277, 45.5324],
              [-83.4785, 45.3389],
              [-83.3972, 45.2735],
              [-83.293, 45.0405],
              [-83.426, 45.059],
              [-83.4361, 44.9614],
              [-83.3278, 44.8692],
              [-83.2943, 44.6834],
              [-83.3305, 44.3751],
              [-83.5125, 44.2707],
              [-83.614, 44.0583],
              [-83.8789, 43.9625],
              [-83.9382, 43.7375],
              [-83.7352, 43.6266],
              [-83.6216, 43.6365],
              [-83.4689, 43.7309],
              [-83.3382, 43.9289],
              [-83.2093, 43.9936],
              [-82.9472, 44.067],
              [-82.7877, 44.0035],
              [-82.6634, 43.8542],
              [-82.5968, 43.6344],
              [-82.4613, 43.081],
              [-82.4173, 43.0173],
              [-82.4883, 42.7395],
              [-82.5453, 42.6247],
              [-82.8677, 42.3852],
              [-83.1048, 42.2865],
              [-83.2618, 41.9463],
              [-83.3621, 41.8799],
              [-83.4543, 41.7456]
            ]
          ],
          [
            [
              [-87.6058, 45.1086],
              [-87.7208, 45.2427],
              [-87.6964, 45.3829],
              [-87.8654, 45.3677],
              [-87.8152, 45.5059],
              [-87.8091, 45.686],
              [-87.9226, 45.76],
              [-88.1161, 45.8158],
              [-88.1517, 45.9454],
              [-88.4931, 46.0136],
              [-88.8374, 46.0366],
              [-89.1193, 46.1492],
              [-90.1185, 46.3417],
              [-90.2304, 46.5098],
              [-90.4077, 46.593],
              [-90.1536, 46.6545],
              [-89.8605, 46.7928],
              [-89.6951, 46.8386],
              [-89.355, 46.882],
              [-89.1547, 46.9886],
              [-88.9481, 47.043],
              [-88.8297, 47.1458],
              [-88.6327, 47.1669],
              [-88.5155, 47.0955],
              [-88.4558, 46.9628],
              [-88.4761, 46.8367],
              [-88.2162, 46.9515],
              [-87.7777, 46.8879],
              [-87.6375, 46.8265],
              [-87.3581, 46.5244],
              [-87.1707, 46.5135],
              [-87.0341, 46.5432],
              [-86.8515, 46.4703],
              [-86.6375, 46.453],
              [-86.531, 46.5244],
              [-86.2312, 46.6563],
              [-85.9467, 46.7022],
              [-85.6161, 46.6906],
              [-85.4415, 46.7066],
              [-85.1385, 46.778],
              [-84.9982, 46.7396],
              [-85.0343, 46.5057],
              [-84.7788, 46.4684],
              [-84.6973, 46.4907],
              [-84.5284, 46.4361],
              [-84.3275, 46.4915],
              [-84.229, 46.3658],
              [-84.2037, 46.247],
              [-84.0408, 46.0882],
              [-83.8941, 46.0014],
              [-83.9788, 45.9615],
              [-84.3409, 45.9998],
              [-84.5212, 45.9945],
              [-84.6315, 46.0526],
              [-84.8019, 45.8744],
              [-85.1006, 46.0462],
              [-85.3859, 46.1087],
              [-85.5551, 46.0782],
              [-85.7078, 45.9876],
              [-85.9594, 45.9407],
              [-86.1229, 45.969],
              [-86.2718, 45.9367],
              [-86.3996, 45.8007],
              [-86.5593, 45.8063],
              [-86.5634, 45.8825],
              [-86.7985, 45.8318],
              [-86.8375, 45.7648],
              [-87.0849, 45.7298],
              [-87.2164, 45.6074],
              [-87.3988, 45.3647],
              [-87.6022, 45.1571],
              [-87.6058, 45.1086]
            ]
          ],
          [[[-84.1329, 46.3622], [-84.2742, 46.4875], [-84.1423, 46.5309], [-84.1329, 46.3622]]]
        ]
      },
      properties: { name: "Michigan", id: "US-MI", CNTRY: "United States of America", TYPE: "State" },
      id: "US-MI"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[-68.1872, 44.3324], [-68.4117, 44.2943], [-68.4095, 44.3643], [-68.2995, 44.4564], [-68.1872, 44.3324]]],
          [
            [
              [-70.7331, 43.07],
              [-70.829, 43.239],
              [-70.9557, 43.3894],
              [-71.0238, 44.419],
              [-71.0846, 45.294],
              [-70.9602, 45.3331],
              [-70.8369, 45.3106],
              [-70.7992, 45.4047],
              [-70.6897, 45.4283],
              [-70.7022, 45.5514],
              [-70.5964, 45.644],
              [-70.421, 45.7382],
              [-70.2963, 45.9061],
              [-70.3045, 46.0573],
              [-70.2483, 46.2509],
              [-70.0672, 46.4411],
              [-70.0076, 46.7089],
              [-69.2429, 47.4629],
              [-69.0502, 47.4267],
              [-69.0486, 47.2736],
              [-68.8286, 47.2034],
              [-68.4804, 47.2858],
              [-68.3109, 47.3545],
              [-68.0968, 47.2748],
              [-67.8068, 47.0828],
              [-67.7959, 46.4984],
              [-67.7847, 45.7018],
              [-67.5957, 45.6207],
              [-67.4327, 45.6031],
              [-67.4937, 45.4741],
              [-67.428, 45.378],
              [-67.4726, 45.2759],
              [-67.367, 45.1738],
              [-67.2496, 45.2007],
              [-67.1248, 45.1695],
              [-67.0804, 44.9891],
              [-67.1067, 44.885],
              [-66.9871, 44.8277],
              [-67.1912, 44.6755],
              [-67.3641, 44.6969],
              [-67.556, 44.6447],
              [-67.653, 44.5624],
              [-67.8392, 44.5763],
              [-67.9071, 44.4537],
              [-68.0566, 44.3843],
              [-68.1173, 44.4907],
              [-68.2774, 44.5074],
              [-68.3738, 44.4451],
              [-68.4507, 44.5076],
              [-68.5214, 44.3803],
              [-68.6121, 44.3105],
              [-68.7939, 44.3818],
              [-68.8473, 44.485],
              [-68.9614, 44.4339],
              [-68.9561, 44.3481],
              [-69.0636, 44.1723],
              [-69.0684, 44.0975],
              [-69.2261, 43.9865],
              [-69.3446, 44.0009],
              [-69.4809, 43.9051],
              [-69.7299, 43.852],
              [-69.7916, 43.8052],
              [-69.9653, 43.8551],
              [-70.1788, 43.7664],
              [-70.2692, 43.6719],
              [-70.2026, 43.6262],
              [-70.5207, 43.3488],
              [-70.6424, 43.1344],
              [-70.7331, 43.07]
            ]
          ]
        ]
      },
      properties: { name: "Maine", id: "US-ME", CNTRY: "United States of America", TYPE: "State" },
      id: "US-ME"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.7848, 39.7224],
            [-75.7071, 38.4551],
            [-75.0376, 38.4556],
            [-75.1342, 38.3843],
            [-75.16, 38.255],
            [-75.2255, 38.2423],
            [-75.3759, 38.025],
            [-75.62, 37.9992],
            [-75.6592, 37.954],
            [-75.8508, 37.9715],
            [-75.7953, 38.0866],
            [-75.928, 38.1693],
            [-75.8639, 38.2612],
            [-76.0513, 38.2796],
            [-76.2117, 38.3613],
            [-76.2948, 38.4947],
            [-76.2642, 38.6],
            [-76.057, 38.6212],
            [-76.175, 38.7068],
            [-76.247, 38.8226],
            [-76.1682, 38.8527],
            [-76.2357, 39.1916],
            [-76.1531, 39.315],
            [-75.9548, 39.4596],
            [-75.9589, 39.585],
            [-76.0629, 39.5611],
            [-76.0972, 39.4331],
            [-76.2764, 39.3227],
            [-76.4056, 39.3039],
            [-76.4209, 39.225],
            [-76.5739, 39.2543],
            [-76.42, 39.0739],
            [-76.5195, 38.8983],
            [-76.5368, 38.7426],
            [-76.5013, 38.5322],
            [-76.4165, 38.4203],
            [-76.4388, 38.3615],
            [-76.3329, 38.1408],
            [-76.5937, 38.2283],
            [-76.8897, 38.2921],
            [-77.0767, 38.4418],
            [-77.2326, 38.4077],
            [-77.2415, 38.4948],
            [-77.0183, 38.7777],
            [-77.0303, 38.8893],
            [-76.9312, 38.8882],
            [-77.042, 39.0117],
            [-77.1221, 38.9434],
            [-77.3011, 39.0534],
            [-77.4809, 39.113],
            [-77.4789, 39.2208],
            [-77.7267, 39.3464],
            [-77.7982, 39.5172],
            [-77.8832, 39.6107],
            [-78.1812, 39.6857],
            [-78.4068, 39.6279],
            [-78.4957, 39.5334],
            [-78.8148, 39.5701],
            [-78.9714, 39.4536],
            [-79.0752, 39.476],
            [-79.2935, 39.3115],
            [-79.4879, 39.2109],
            [-79.4776, 39.7228],
            [-78.0927, 39.7226],
            [-76.7079, 39.7224],
            [-75.7848, 39.7224]
          ]
        ]
      },
      properties: { name: "Maryland", id: "US-MD", CNTRY: "United States of America", TYPE: "State" },
      id: "US-MD"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[-70.5099, 41.3764], [-70.7853, 41.3274], [-70.6736, 41.4485], [-70.5099, 41.3764]]],
          [
            [
              [-70.8061, 42.8767],
              [-70.8291, 42.8254],
              [-70.7357, 42.6693],
              [-70.6614, 42.6166],
              [-70.8312, 42.5526],
              [-71.0462, 42.3311],
              [-70.7383, 42.2289],
              [-70.5489, 41.9386],
              [-70.5147, 41.8033],
              [-70.2955, 41.729],
              [-70.0014, 41.8262],
              [-69.9416, 41.8079],
              [-69.9486, 41.6771],
              [-70.0595, 41.6774],
              [-70.4047, 41.6268],
              [-70.6571, 41.5342],
              [-70.7012, 41.7148],
              [-70.9742, 41.5485],
              [-71.1885, 41.5164],
              [-71.2338, 41.7065],
              [-71.3408, 41.7979],
              [-71.3871, 42.0168],
              [-71.8008, 42.0119],
              [-71.8017, 42.0227],
              [-72.8077, 42.0341],
              [-73.4806, 42.0555],
              [-73.5072, 42.08],
              [-73.2533, 42.7523],
              [-72.4668, 42.7303],
              [-71.3296, 42.7025],
              [-70.9741, 42.8716],
              [-70.8061, 42.8767]
            ]
          ]
        ]
      },
      properties: { name: "Massachusetts", id: "US-MA", CNTRY: "United States of America", TYPE: "State" },
      id: "US-MA"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-89.5209, 30.1926],
              [-89.5884, 30.166],
              [-89.9542, 30.2688],
              [-90.0452, 30.3514],
              [-90.2253, 30.3793],
              [-90.332, 30.2777],
              [-90.4131, 30.1403],
              [-90.1753, 30.0292],
              [-89.9942, 30.0593],
              [-89.894, 30.1258],
              [-89.7374, 30.172],
              [-89.665, 30.1171],
              [-89.8152, 30.0073],
              [-89.7439, 29.9299],
              [-89.5895, 29.9151],
              [-89.4944, 30.0582],
              [-89.3578, 29.921],
              [-89.3545, 29.8202],
              [-89.5307, 29.7721],
              [-89.7209, 29.6193],
              [-89.6748, 29.5387],
              [-89.5137, 29.42],
              [-89.2457, 29.3332],
              [-89.1807, 29.3357],
              [-89.1168, 29.2482],
              [-89.0158, 29.2029],
              [-89.1555, 29.0166],
              [-89.2362, 29.0811],
              [-89.3536, 29.0702],
              [-89.4431, 29.1942],
              [-89.6203, 29.3024],
              [-89.7924, 29.3332],
              [-89.8772, 29.458],
              [-90.1591, 29.5371],
              [-90.0524, 29.4314],
              [-90.1013, 29.1818],
              [-90.2128, 29.1049],
              [-90.3016, 29.2558],
              [-90.3793, 29.295],
              [-90.5862, 29.2715],
              [-90.6775, 29.1507],
              [-90.751, 29.1309],
              [-91.0027, 29.1935],
              [-91.2901, 29.2889],
              [-91.1555, 29.3507],
              [-91.3309, 29.5135],
              [-91.5142, 29.5553],
              [-91.6725, 29.7461],
              [-91.8244, 29.7507],
              [-91.8931, 29.836],
              [-92.0173, 29.8003],
              [-92.1355, 29.6995],
              [-92.084, 29.5928],
              [-92.2608, 29.5569],
              [-92.6713, 29.597],
              [-92.9525, 29.7142],
              [-93.1756, 29.779],
              [-93.6949, 29.7699],
              [-93.8265, 29.7251],
              [-93.8839, 29.81],
              [-93.7731, 29.914],
              [-93.794, 29.9772],
              [-93.7107, 30.1128],
              [-93.7503, 30.3448],
              [-93.7202, 30.5582],
              [-93.5792, 30.8238],
              [-93.5306, 31.0462],
              [-93.5578, 31.1804],
              [-93.6658, 31.3226],
              [-93.7376, 31.5138],
              [-93.8206, 31.604],
              [-93.8268, 31.7503],
              [-93.9051, 31.877],
              [-94.0434, 31.9992],
              [-94.0413, 33.012],
              [-93.3227, 33.0128],
              [-92.0649, 33.0143],
              [-91.1499, 33.0159],
              [-91.0962, 32.9377],
              [-91.136, 32.8089],
              [-91.0943, 32.6943],
              [-91.1424, 32.661],
              [-91.1038, 32.4905],
              [-90.9448, 32.3307],
              [-90.9477, 32.2401],
              [-91.0888, 32.1042],
              [-91.1566, 31.9707],
              [-91.2865, 31.884],
              [-91.3725, 31.7737],
              [-91.4146, 31.64],
              [-91.529, 31.4488],
              [-91.529, 31.3452],
              [-91.6308, 31.2176],
              [-91.5964, 31.1099],
              [-91.6565, 31.0018],
              [-90.9366, 31.0012],
              [-89.7354, 31.0012],
              [-89.8353, 30.7308],
              [-89.82, 30.6111],
              [-89.6858, 30.4627],
              [-89.5917, 30.223],
              [-89.5209, 30.1926]
            ]
          ],
          [[[-91.7937, 29.5007], [-91.9962, 29.5732], [-91.8753, 29.6409], [-91.7677, 29.5847], [-91.7937, 29.5007]]]
        ]
      },
      properties: { name: "Louisiana", id: "US-LA", CNTRY: "United States of America", TYPE: "State" },
      id: "US-LA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.6128, 38.4482],
            [-82.5784, 38.2721],
            [-82.6196, 38.182],
            [-82.5888, 38.0998],
            [-82.2802, 37.6871],
            [-82.1035, 37.5706],
            [-81.9654, 37.5397],
            [-82.3431, 37.2969],
            [-82.6816, 37.1381],
            [-82.7319, 37.048],
            [-82.8945, 36.9064],
            [-83.0438, 36.8455],
            [-83.1502, 36.7624],
            [-83.6676, 36.6051],
            [-83.7304, 36.5869],
            [-85.119, 36.6258],
            [-86.5075, 36.6649],
            [-87.8391, 36.644],
            [-87.8687, 36.675],
            [-88.0963, 36.693],
            [-88.059, 36.4997],
            [-89.4504, 36.5],
            [-89.3974, 36.5878],
            [-89.203, 36.6255],
            [-89.1563, 36.7157],
            [-89.1225, 36.9116],
            [-89.1543, 36.9921],
            [-89.1346, 37.1024],
            [-89.0462, 37.1829],
            [-88.8726, 37.1855],
            [-88.6137, 37.1101],
            [-88.4717, 37.0884],
            [-88.4533, 37.1781],
            [-88.4947, 37.3643],
            [-88.1905, 37.4646],
            [-88.1075, 37.5343],
            [-88.1289, 37.6995],
            [-88.042, 37.7888],
            [-87.9125, 37.8177],
            [-87.906, 37.9019],
            [-87.7145, 37.8996],
            [-87.5995, 37.9465],
            [-87.3725, 37.9234],
            [-87.141, 37.8052],
            [-86.9694, 37.938],
            [-86.7703, 37.9598],
            [-86.6732, 37.889],
            [-86.5487, 37.917],
            [-86.5199, 38.0148],
            [-86.4296, 38.1397],
            [-86.2838, 38.1299],
            [-86.2076, 38.0365],
            [-86.1036, 37.9919],
            [-85.9612, 38.014],
            [-85.8549, 38.215],
            [-85.7884, 38.2852],
            [-85.6464, 38.3473],
            [-85.5464, 38.484],
            [-85.4249, 38.5966],
            [-85.4448, 38.6832],
            [-85.3067, 38.7372],
            [-85.17, 38.6904],
            [-84.9013, 38.7696],
            [-84.8008, 38.8194],
            [-84.8542, 38.9129],
            [-84.8217, 39.092],
            [-84.7069, 39.1289],
            [-84.5088, 39.1029],
            [-84.3581, 39.0499],
            [-84.2273, 38.8652],
            [-84.1132, 38.7984],
            [-83.9484, 38.7742],
            [-83.6899, 38.6496],
            [-83.5384, 38.6999],
            [-83.357, 38.6274],
            [-83.2035, 38.6388],
            [-83.0001, 38.714],
            [-82.8805, 38.7103],
            [-82.7583, 38.5411],
            [-82.6128, 38.4482]
          ]
        ]
      },
      properties: { name: "Kentucky", id: "US-KY", CNTRY: "United States of America", TYPE: "State" },
      id: "US-KY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.618, 37.0008],
            [-95.5424, 37.0008],
            [-96.6979, 37.0008],
            [-97.8532, 37.0008],
            [-98.7775, 37.0008],
            [-99.4708, 37.0008],
            [-100.8573, 37.0008],
            [-102.0128, 37.0008],
            [-102.0157, 37.751],
            [-102.0187, 38.501],
            [-102.0209, 39.0635],
            [-102.0245, 40.0011],
            [-101.1861, 40.0011],
            [-100.3476, 40.0011],
            [-98.8805, 40.0011],
            [-97.8325, 40.0011],
            [-96.7845, 40.0011],
            [-95.3466, 40.0015],
            [-95.1162, 39.8721],
            [-94.9841, 39.8911],
            [-94.8933, 39.7818],
            [-95.0754, 39.5973],
            [-95.0579, 39.509],
            [-94.8823, 39.3432],
            [-94.7778, 39.1939],
            [-94.6224, 39.1244],
            [-94.6217, 38.1738],
            [-94.618, 37.0008]
          ]
        ]
      },
      properties: { name: "Kansas", id: "US-KS", CNTRY: "United States of America", TYPE: "State" },
      id: "US-KS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.7961, 41.7015],
            [-84.803, 41.0506],
            [-84.8133, 40.0745],
            [-84.8217, 39.092],
            [-84.8542, 38.9129],
            [-84.8008, 38.8194],
            [-84.9013, 38.7696],
            [-85.17, 38.6904],
            [-85.3067, 38.7372],
            [-85.4448, 38.6832],
            [-85.4249, 38.5966],
            [-85.5464, 38.484],
            [-85.6464, 38.3473],
            [-85.7884, 38.2852],
            [-85.8549, 38.215],
            [-85.9612, 38.014],
            [-86.1036, 37.9919],
            [-86.2076, 38.0365],
            [-86.2838, 38.1299],
            [-86.4296, 38.1397],
            [-86.5199, 38.0148],
            [-86.5487, 37.917],
            [-86.6732, 37.889],
            [-86.7703, 37.9598],
            [-86.9694, 37.938],
            [-87.141, 37.8052],
            [-87.3725, 37.9234],
            [-87.5995, 37.9465],
            [-87.7145, 37.8996],
            [-87.906, 37.9019],
            [-87.9125, 37.8177],
            [-88.042, 37.7888],
            [-88.0953, 37.8756],
            [-88.0425, 37.9366],
            [-87.9804, 38.2492],
            [-87.8272, 38.3355],
            [-87.7841, 38.4191],
            [-87.6359, 38.5426],
            [-87.5295, 38.7423],
            [-87.5303, 38.9089],
            [-87.6472, 39.1688],
            [-87.6111, 39.2914],
            [-87.5329, 39.3749],
            [-87.5283, 40.2619],
            [-87.5255, 40.8615],
            [-87.5212, 41.7278],
            [-87.4125, 41.6565],
            [-87.2532, 41.6377],
            [-87.0726, 41.6695],
            [-86.8627, 41.7606],
            [-86.0088, 41.7602],
            [-84.7964, 41.7597],
            [-84.7961, 41.7015]
          ]
        ]
      },
      properties: { name: "Indiana", id: "US-IN", CNTRY: "United States of America", TYPE: "State" },
      id: "US-IN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.8121, 42.4972],
            [-87.7975, 42.2113],
            [-87.6947, 42.0844],
            [-87.6021, 41.8592],
            [-87.5212, 41.7278],
            [-87.5255, 40.8615],
            [-87.5283, 40.2619],
            [-87.5329, 39.3749],
            [-87.6111, 39.2914],
            [-87.6472, 39.1688],
            [-87.5303, 38.9089],
            [-87.5295, 38.7423],
            [-87.6359, 38.5426],
            [-87.7841, 38.4191],
            [-87.8272, 38.3355],
            [-87.9804, 38.2492],
            [-88.0425, 37.9366],
            [-88.0953, 37.8756],
            [-88.042, 37.7888],
            [-88.1289, 37.6995],
            [-88.1075, 37.5343],
            [-88.1905, 37.4646],
            [-88.4947, 37.3643],
            [-88.4533, 37.1781],
            [-88.4717, 37.0884],
            [-88.6137, 37.1101],
            [-88.8726, 37.1855],
            [-89.0462, 37.1829],
            [-89.1346, 37.1024],
            [-89.1543, 36.9921],
            [-89.2724, 37.0799],
            [-89.414, 37.1024],
            [-89.5029, 37.3005],
            [-89.4619, 37.4341],
            [-89.5234, 37.6445],
            [-89.6233, 37.7473],
            [-89.823, 37.8841],
            [-90.1737, 38.0691],
            [-90.299, 38.174],
            [-90.374, 38.3097],
            [-90.3166, 38.4578],
            [-90.1268, 38.8326],
            [-90.2201, 38.8976],
            [-90.478, 38.9482],
            [-90.5771, 38.9097],
            [-90.6635, 38.9875],
            [-90.7372, 39.1818],
            [-90.9194, 39.3868],
            [-91.2099, 39.6029],
            [-91.3787, 39.7494],
            [-91.4668, 39.9429],
            [-91.4998, 40.226],
            [-91.4419, 40.3795],
            [-91.3909, 40.3972],
            [-91.3941, 40.5367],
            [-91.339, 40.604],
            [-91.2151, 40.6495],
            [-91.1021, 40.7498],
            [-90.9539, 41.0156],
            [-91.0821, 41.2803],
            [-91.0481, 41.3692],
            [-90.9387, 41.4256],
            [-90.5867, 41.4947],
            [-90.4367, 41.5604],
            [-90.2177, 41.8131],
            [-90.1525, 41.9815],
            [-90.1675, 42.1003],
            [-90.3465, 42.2149],
            [-90.4847, 42.3833],
            [-90.6506, 42.513],
            [-89.7408, 42.5078],
            [-88.8402, 42.5028],
            [-87.8121, 42.4972]
          ]
        ]
      },
      properties: { name: "Illinois", id: "US-IL", CNTRY: "United States of America", TYPE: "State" },
      id: "US-IL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.0515, 44.4989],
            [-111.051, 43.5622],
            [-111.0508, 42.9376],
            [-111.0503, 42.0008],
            [-111.9854, 42.0009],
            [-112.7334, 42.001],
            [-114.0426, 42.0011],
            [-115.1578, 42.0008],
            [-116.2732, 42.0007],
            [-117.0167, 42.0006],
            [-117.0189, 43.1165],
            [-117.0205, 43.8132],
            [-116.9576, 43.9642],
            [-116.9607, 44.0791],
            [-116.9113, 44.1574],
            [-116.9532, 44.2453],
            [-117.1397, 44.2903],
            [-117.2017, 44.4362],
            [-117.1189, 44.5783],
            [-116.9489, 44.7702],
            [-116.8395, 44.9851],
            [-116.5492, 45.4567],
            [-116.4771, 45.6411],
            [-116.6168, 45.7992],
            [-116.7647, 45.8729],
            [-116.8965, 46.0021],
            [-116.9478, 46.1243],
            [-116.943, 46.2321],
            [-117.025, 46.4292],
            [-117.0292, 47.2307],
            [-117.0349, 48.3528],
            [-117.0391, 48.9931],
            [-116.0486, 48.9931],
            [-116.049, 48.0047],
            [-115.7338, 47.7064],
            [-115.6697, 47.4978],
            [-115.7025, 47.4377],
            [-115.486, 47.3117],
            [-115.3495, 47.2611],
            [-114.9357, 46.9228],
            [-114.7537, 46.7346],
            [-114.6016, 46.6603],
            [-114.3509, 46.6677],
            [-114.3458, 46.5506],
            [-114.4853, 46.1582],
            [-114.4783, 46.0356],
            [-114.4051, 45.8742],
            [-114.5337, 45.782],
            [-114.4958, 45.6858],
            [-114.5292, 45.5757],
            [-114.2977, 45.4876],
            [-113.9427, 45.6815],
            [-113.8023, 45.6011],
            [-113.6971, 45.3049],
            [-113.4711, 45.0729],
            [-113.4693, 44.93],
            [-113.3389, 44.808],
            [-113.1872, 44.7986],
            [-113.0933, 44.7153],
            [-112.9997, 44.4804],
            [-112.8155, 44.406],
            [-112.716, 44.4981],
            [-112.3928, 44.468],
            [-112.2313, 44.5607],
            [-111.7971, 44.5399],
            [-111.4999, 44.5448],
            [-111.4481, 44.7112],
            [-111.3375, 44.7447],
            [-111.1977, 44.5906],
            [-111.0515, 44.4989]
          ]
        ]
      },
      properties: { name: "Idaho", id: "US-ID", CNTRY: "United States of America", TYPE: "State" },
      id: "US-ID"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.6506, 42.513],
            [-90.4847, 42.3833],
            [-90.3465, 42.2149],
            [-90.1675, 42.1003],
            [-90.1525, 41.9815],
            [-90.2177, 41.8131],
            [-90.4367, 41.5604],
            [-90.5867, 41.4947],
            [-90.9387, 41.4256],
            [-91.0481, 41.3692],
            [-91.0821, 41.2803],
            [-90.9539, 41.0156],
            [-91.1021, 40.7498],
            [-91.2151, 40.6495],
            [-91.339, 40.604],
            [-91.3941, 40.5367],
            [-91.3909, 40.3972],
            [-91.4419, 40.3795],
            [-91.682, 40.5519],
            [-91.7316, 40.6247],
            [-92.4882, 40.6202],
            [-93.4973, 40.6145],
            [-95.0109, 40.6058],
            [-95.765, 40.6018],
            [-95.859, 40.7451],
            [-95.8339, 40.8896],
            [-95.8836, 41.2875],
            [-95.933, 41.4254],
            [-96.0586, 41.5458],
            [-96.0688, 41.7344],
            [-96.1543, 41.9653],
            [-96.2953, 42.1329],
            [-96.3561, 42.2602],
            [-96.3837, 42.4178],
            [-96.4809, 42.5112],
            [-96.5387, 42.6586],
            [-96.6218, 42.7296],
            [-96.4627, 43.0992],
            [-96.5554, 43.2316],
            [-96.5416, 43.3735],
            [-96.5986, 43.4976],
            [-96.4537, 43.5012],
            [-95.8031, 43.5013],
            [-94.5023, 43.5015],
            [-93.5267, 43.5016],
            [-92.2258, 43.5018],
            [-91.2452, 43.5025],
            [-91.2188, 43.3952],
            [-91.1173, 43.3311],
            [-91.1613, 43.1023],
            [-91.1482, 42.9862],
            [-91.0695, 42.7886],
            [-90.96, 42.7203],
            [-90.7887, 42.6769],
            [-90.6878, 42.6104],
            [-90.6506, 42.513]
          ]
        ]
      },
      properties: { name: "Iowa", id: "US-IA", CNTRY: "United States of America", TYPE: "State" },
      id: "US-IA"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-104.3831, 24.3101],
              [-104.2845, 24.3114],
              [-104.2652, 24.2134],
              [-104.1774, 24.1268],
              [-104.3824, 24.0789],
              [-104.5117, 24.1673],
              [-104.6112, 24.1372],
              [-104.7736, 24.1403],
              [-104.9352, 24.3502],
              [-104.9779, 24.468],
              [-104.7896, 24.4865],
              [-104.6606, 24.5989],
              [-104.5879, 24.6106],
              [-104.4486, 24.4289],
              [-104.3831, 24.3101]
            ]
          ],
          [
            [
              [-103.1906, 23.4686],
              [-103.3462, 23.4499],
              [-103.3649, 23.5339],
              [-103.4429, 23.6407],
              [-103.3063, 23.6623],
              [-103.1401, 23.5406],
              [-103.1906, 23.4686]
            ]
          ],
          [
            [
              [-101.5983, 21.2955],
              [-101.7232, 21.2406],
              [-101.975, 21.3677],
              [-102.0054, 21.4361],
              [-101.9869, 21.7536],
              [-102.0811, 22.0109],
              [-102.1852, 22.2074],
              [-102.0097, 22.3861],
              [-101.8984, 22.5336],
              [-101.9894, 22.7226],
              [-101.9661, 22.8365],
              [-101.9126, 22.8563],
              [-101.6496, 22.7176],
              [-101.1181, 22.5092],
              [-100.9765, 22.3626],
              [-100.9512, 22.2052],
              [-100.8547, 22.1852],
              [-100.8091, 22.0773],
              [-100.6228, 21.9288],
              [-100.6806, 21.842],
              [-100.9356, 21.6752],
              [-101.2573, 21.6022],
              [-101.5405, 21.4154],
              [-101.5983, 21.2955]
            ]
          ],
          [
            [
              [-103.6477, 24.0135],
              [-103.0559, 23.9496],
              [-103.063, 23.8758],
              [-103.2036, 23.8177],
              [-103.4057, 23.8688],
              [-103.744, 23.887],
              [-103.6477, 24.0135]
            ]
          ],
          [
            [
              [-102.7353, 23.6655],
              [-102.7026, 23.6434],
              [-102.4726, 23.6885],
              [-102.3102, 23.6075],
              [-102.1113, 23.4494],
              [-102.2585, 23.311],
              [-102.5132, 23.2544],
              [-102.6741, 23.2779],
              [-102.7268, 23.5038],
              [-102.8968, 23.5291],
              [-102.9899, 23.6271],
              [-102.9487, 23.7785],
              [-102.8591, 23.7906],
              [-102.7353, 23.6655]
            ]
          ],
          [
            [
              [-106.3586, 24.8943],
              [-106.4689, 24.8253],
              [-106.6549, 24.8662],
              [-106.8297, 24.9647],
              [-106.8813, 25.0287],
              [-106.8028, 25.1493],
              [-106.6177, 25.251],
              [-106.3324, 25.2468],
              [-106.268, 25.1064],
              [-106.3586, 24.8943]
            ]
          ],
          [
            [
              [-107.3721, 24.7823],
              [-107.4233, 24.8512],
              [-107.2723, 24.9961],
              [-107.2072, 24.983],
              [-107.2466, 24.8638],
              [-107.3721, 24.7823]
            ]
          ]
        ]
      },
      properties: { name: "Hawaii", id: "US-HI", CNTRY: "United States of America", TYPE: "State" },
      id: "US-HI"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.8724, 32.0296],
            [-80.9234, 31.9449],
            [-81.1133, 31.8786],
            [-81.065, 31.8135],
            [-81.1979, 31.7042],
            [-81.1754, 31.5313],
            [-81.295, 31.3712],
            [-81.2885, 31.2639],
            [-81.4532, 31.0883],
            [-81.5204, 30.8748],
            [-81.5039, 30.7314],
            [-81.6734, 30.7401],
            [-81.9356, 30.8195],
            [-82.0383, 30.7319],
            [-82.0218, 30.5598],
            [-82.0618, 30.4045],
            [-82.1943, 30.3934],
            [-82.2399, 30.5656],
            [-83.3558, 30.631],
            [-83.9933, 30.6683],
            [-84.8839, 30.7209],
            [-85.0073, 31.0017],
            [-85.0674, 31.1176],
            [-85.0927, 31.2958],
            [-85.0549, 31.5726],
            [-85.1248, 31.7768],
            [-85.1242, 31.8805],
            [-85.0347, 32.1473],
            [-84.9297, 32.2466],
            [-84.986, 32.3609],
            [-85.0151, 32.5261],
            [-85.1565, 32.8042],
            [-85.2009, 32.9562],
            [-85.3859, 33.8507],
            [-85.6236, 35.0009],
            [-84.3246, 34.9881],
            [-83.1149, 35.0015],
            [-83.3167, 34.8057],
            [-83.3556, 34.7084],
            [-83.0528, 34.5108],
            [-82.8477, 34.4369],
            [-82.8186, 34.3661],
            [-82.5894, 34.0176],
            [-82.2566, 33.7492],
            [-82.2083, 33.6636],
            [-82.0058, 33.5229],
            [-81.9329, 33.3899],
            [-81.6992, 33.1267],
            [-81.5782, 33.0686],
            [-81.4897, 32.9356],
            [-81.4338, 32.728],
            [-81.377, 32.6075],
            [-81.2904, 32.5572],
            [-81.1715, 32.3801],
            [-81.1351, 32.1819],
            [-81.0748, 32.1096],
            [-80.8724, 32.0296]
          ]
        ]
      },
      properties: { name: "Georgia", id: "US-GA", CNTRY: "United States of America", TYPE: "State" },
      id: "US-GA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.5039, 30.7314],
            [-81.4572, 30.6408],
            [-81.3857, 30.2699],
            [-81.3371, 30.1412],
            [-81.2495, 29.7938],
            [-81.1046, 29.457],
            [-80.9, 29.0498],
            [-80.5241, 28.4861],
            [-80.6229, 28.3203],
            [-80.7317, 28.4628],
            [-80.6884, 28.5785],
            [-80.7659, 28.6328],
            [-80.771, 28.7324],
            [-80.8382, 28.7576],
            [-80.7486, 28.381],
            [-80.6501, 28.1809],
            [-80.2261, 27.2071],
            [-80.1258, 27.083],
            [-80.05, 26.8077],
            [-80.0413, 26.5686],
            [-80.1106, 26.1315],
            [-80.1264, 25.8335],
            [-80.3009, 25.6185],
            [-80.3278, 25.4271],
            [-80.3669, 25.3312],
            [-80.4845, 25.2298],
            [-80.5576, 25.2324],
            [-80.7366, 25.1564],
            [-80.8622, 25.1762],
            [-81.012, 25.1333],
            [-81.1105, 25.138],
            [-81.1674, 25.2285],
            [-81.0976, 25.3192],
            [-81.2272, 25.5834],
            [-81.3451, 25.7318],
            [-81.3649, 25.831],
            [-81.5683, 25.8916],
            [-81.7155, 25.9832],
            [-81.8115, 26.1461],
            [-81.8665, 26.435],
            [-82.0396, 26.5521],
            [-82.0779, 26.7044],
            [-82.0669, 26.8915],
            [-82.1811, 26.9367],
            [-82.1807, 26.8401],
            [-82.29, 26.8708],
            [-82.4413, 27.0597],
            [-82.6204, 27.401],
            [-82.6358, 27.5246],
            [-82.4006, 27.8353],
            [-82.5206, 27.8779],
            [-82.6609, 27.7185],
            [-82.7428, 27.7094],
            [-82.8435, 27.8461],
            [-82.7485, 28.2368],
            [-82.6606, 28.4859],
            [-82.6515, 28.8875],
            [-82.7694, 29.0515],
            [-83.2904, 29.4519],
            [-83.6943, 29.926],
            [-84.0442, 30.1037],
            [-84.3097, 30.0648],
            [-84.3828, 29.9074],
            [-84.55, 29.8979],
            [-84.8005, 29.773],
            [-84.8889, 29.7776],
            [-85.0292, 29.7211],
            [-85.3189, 29.6802],
            [-85.3069, 29.7979],
            [-85.3536, 29.8757],
            [-85.5043, 29.9758],
            [-85.6757, 30.122],
            [-85.6036, 30.2867],
            [-85.7408, 30.2444],
            [-85.7557, 30.1669],
            [-86.1752, 30.3325],
            [-86.4544, 30.3992],
            [-86.24, 30.4291],
            [-86.2574, 30.493],
            [-86.4479, 30.4957],
            [-86.6796, 30.4029],
            [-87.2011, 30.3393],
            [-87.1238, 30.3967],
            [-86.9858, 30.4308],
            [-87.1706, 30.5388],
            [-87.1846, 30.4537],
            [-87.2811, 30.3393],
            [-87.4758, 30.2942],
            [-87.4896, 30.3777],
            [-87.4294, 30.4778],
            [-87.4216, 30.6716],
            [-87.5662, 30.7956],
            [-87.601, 30.8606],
            [-87.5937, 31.0008],
            [-86.6236, 31.0009],
            [-85.9768, 31.001],
            [-85.0073, 31.0017],
            [-84.8839, 30.7209],
            [-83.9933, 30.6683],
            [-83.3558, 30.631],
            [-82.2399, 30.5656],
            [-82.1943, 30.3934],
            [-82.0618, 30.4045],
            [-82.0218, 30.5598],
            [-82.0383, 30.7319],
            [-81.9356, 30.8195],
            [-81.6734, 30.7401],
            [-81.5039, 30.7314]
          ]
        ]
      },
      properties: { name: "Florida", id: "US-FL", CNTRY: "United States of America", TYPE: "State" },
      id: "US-FL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.4211, 39.8154],
            [-75.5876, 39.6407],
            [-75.5738, 39.477],
            [-75.4126, 39.2815],
            [-75.3923, 39.0927],
            [-75.185, 38.8194],
            [-75.0886, 38.7775],
            [-75.1285, 38.6325],
            [-75.0376, 38.4556],
            [-75.7071, 38.4551],
            [-75.7848, 39.7224],
            [-75.6345, 39.8395],
            [-75.4211, 39.8154]
          ]
        ]
      },
      properties: { name: "Delaware", id: "US-DE", CNTRY: "United States of America", TYPE: "State" },
      id: "US-DE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-77.0303, 38.8893], [-77.1221, 38.9434], [-77.042, 39.0117], [-76.9312, 38.8882], [-77.0303, 38.8893]]
        ]
      },
      properties: {
        name: "District of Columbia",
        id: "US-DC",
        CNTRY: "United States of America",
        TYPE: "Federal District"
      },
      id: "US-DC"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.8008, 42.0119],
            [-71.7958, 41.5199],
            [-71.8424, 41.3355],
            [-71.93, 41.341],
            [-72.2652, 41.2917],
            [-72.3711, 41.3122],
            [-72.4794, 41.2758],
            [-72.8472, 41.2659],
            [-72.9248, 41.2851],
            [-73.0237, 41.2165],
            [-73.1822, 41.1759],
            [-73.6306, 40.9919],
            [-73.723, 41.1045],
            [-73.4842, 41.219],
            [-73.5447, 41.296],
            [-73.4806, 42.0555],
            [-72.8077, 42.0341],
            [-71.8017, 42.0227],
            [-71.8008, 42.0119]
          ]
        ]
      },
      properties: { name: "Connecticut", id: "US-CT", CNTRY: "United States of America", TYPE: "State" },
      id: "US-CT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.0245, 40.0011],
            [-102.0209, 39.0635],
            [-102.0187, 38.501],
            [-102.0157, 37.751],
            [-102.0128, 37.0008],
            [-103.0008, 37.0008],
            [-104.1345, 37.0008],
            [-105.2681, 37.0008],
            [-106.0238, 37.0008],
            [-106.7795, 37.0008],
            [-107.9131, 37.0008],
            [-109.0467, 37.0008],
            [-109.0467, 37.5009],
            [-109.0466, 38.5008],
            [-109.0465, 39.2508],
            [-109.0465, 39.7508],
            [-109.0465, 40.5009],
            [-109.0463, 41.0008],
            [-108.4183, 41.0008],
            [-107.1621, 41.0008],
            [-106.22, 41.0008],
            [-105.5919, 41.0008],
            [-104.6498, 41.0008],
            [-104.0217, 41.0008],
            [-103.2729, 41.0007],
            [-102.025, 41.0006],
            [-102.0245, 40.0011]
          ]
        ]
      },
      properties: { name: "Colorado", id: "US-CO", CNTRY: "United States of America", TYPE: "State" },
      id: "US-CO"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-120.0008, 42.0006],
              [-120.0006, 41.063],
              [-120.0003, 39.9378],
              [-120.0001, 39.0003],
              [-119.2974, 38.5098],
              [-118.5947, 38.0193],
              [-118.1263, 37.6923],
              [-117.2096, 37.0033],
              [-116.6596, 36.59],
              [-115.8979, 35.9954],
              [-115.39, 35.599],
              [-114.6105, 34.9912],
              [-114.5575, 34.7946],
              [-114.398, 34.5897],
              [-114.371, 34.4885],
              [-114.1588, 34.3552],
              [-114.1256, 34.2865],
              [-114.3293, 34.142],
              [-114.5091, 33.9039],
              [-114.4993, 33.6858],
              [-114.6026, 33.4699],
              [-114.7017, 33.4178],
              [-114.7044, 33.1697],
              [-114.6481, 33.0717],
              [-114.4909, 32.9837],
              [-114.4775, 32.842],
              [-114.5821, 32.7348],
              [-114.7248, 32.7153],
              [-115.9837, 32.619],
              [-117.1283, 32.5333],
              [-117.1374, 32.6492],
              [-117.2435, 32.6641],
              [-117.2629, 32.9389],
              [-117.3188, 33.1001],
              [-117.4674, 33.2956],
              [-117.7885, 33.5384],
              [-117.9521, 33.6196],
              [-118.0805, 33.7221],
              [-118.2644, 33.7587],
              [-118.4104, 33.744],
              [-118.3929, 33.8583],
              [-118.5062, 34.0175],
              [-118.5989, 34.035],
              [-118.832, 34.0245],
              [-119.1437, 34.1119],
              [-119.2359, 34.1641],
              [-119.2676, 34.2574],
              [-119.606, 34.418],
              [-119.8533, 34.412],
              [-120.053, 34.4693],
              [-120.3965, 34.4596],
              [-120.4812, 34.4716],
              [-120.6447, 34.58],
              [-120.6249, 34.812],
              [-120.663, 34.9493],
              [-120.6336, 35.0764],
              [-120.707, 35.1577],
              [-120.8574, 35.2097],
              [-120.8603, 35.3654],
              [-121.0229, 35.4808],
              [-121.1379, 35.6072],
              [-121.2838, 35.6764],
              [-121.3438, 35.7921],
              [-121.6643, 36.154],
              [-121.8774, 36.331],
              [-121.9186, 36.5723],
              [-121.79, 36.7323],
              [-121.8075, 36.8512],
              [-121.8807, 36.9389],
              [-122.1642, 36.991],
              [-122.3948, 37.2075],
              [-122.4085, 37.3732],
              [-122.4993, 37.5426],
              [-122.5142, 37.772],
              [-122.3841, 37.7886],
              [-122.3697, 37.6559],
              [-122.2286, 37.5639],
              [-122.1242, 37.5438],
              [-122.2222, 37.7321],
              [-122.296, 37.7904],
              [-122.3854, 37.9606],
              [-122.217, 38.0407],
              [-122.0867, 38.0495],
              [-121.7168, 38.0341],
              [-122.0315, 38.1236],
              [-122.1538, 38.0655],
              [-122.3934, 38.1447],
              [-122.4839, 38.1088],
              [-122.4949, 37.9536],
              [-122.4669, 37.8382],
              [-122.7604, 37.9456],
              [-122.9681, 38.097],
              [-122.9866, 38.2772],
              [-123.0461, 38.3051],
              [-123.1212, 38.4493],
              [-123.2897, 38.5359],
              [-123.4248, 38.6757],
              [-123.7011, 38.9073],
              [-123.7196, 39.111],
              [-123.8203, 39.3684],
              [-123.7835, 39.6187],
              [-123.8845, 39.8609],
              [-124.1085, 40.0945],
              [-124.324, 40.2519],
              [-124.3716, 40.4913],
              [-124.2837, 40.7105],
              [-124.2225, 40.775],
              [-124.1331, 40.9698],
              [-124.14, 41.1559],
              [-124.0685, 41.3841],
              [-124.1177, 41.6218],
              [-124.2446, 41.7879],
              [-124.2087, 41.8885],
              [-124.2284, 42.0007],
              [-123.4283, 42.0006],
              [-122.11, 42.0006],
              [-121.0555, 42.0006],
              [-120.0008, 42.0006]
            ]
          ],
          [
            [
              [-120.0436, 33.9188],
              [-120.1671, 33.9181],
              [-120.2519, 34.0138],
              [-120.0718, 34.0265],
              [-120.0436, 33.9188]
            ]
          ],
          [[[-119.8824, 34.0797], [-119.5622, 34.0065], [-119.8096, 33.9678], [-119.8824, 34.0797]]]
        ]
      },
      properties: { name: "California", id: "US-CA", CNTRY: "United States of America", TYPE: "State" },
      id: "US-CA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.0467, 37.0008],
            [-109.0468, 36.2918],
            [-109.0469, 35.2284],
            [-109.0471, 34.1648],
            [-109.0472, 33.1013],
            [-109.0473, 32.3922],
            [-109.0478, 31.3279],
            [-109.9817, 31.3261],
            [-111.042, 31.3243],
            [-111.9905, 31.6203],
            [-112.939, 31.9163],
            [-113.4132, 32.0642],
            [-114.3617, 32.3603],
            [-114.8359, 32.5083],
            [-114.7248, 32.7153],
            [-114.5821, 32.7348],
            [-114.4775, 32.842],
            [-114.4909, 32.9837],
            [-114.6481, 33.0717],
            [-114.7044, 33.1697],
            [-114.7017, 33.4178],
            [-114.6026, 33.4699],
            [-114.4993, 33.6858],
            [-114.5091, 33.9039],
            [-114.3293, 34.142],
            [-114.1256, 34.2865],
            [-114.1588, 34.3552],
            [-114.371, 34.4885],
            [-114.398, 34.5897],
            [-114.5575, 34.7946],
            [-114.6105, 34.9912],
            [-114.591, 35.3527],
            [-114.6486, 35.4759],
            [-114.6451, 35.6305],
            [-114.6871, 35.9173],
            [-114.7413, 36.0136],
            [-114.7158, 36.085],
            [-114.593, 36.1476],
            [-114.4061, 36.1483],
            [-114.1832, 36.0303],
            [-114.0429, 36.1817],
            [-114.0402, 37.0041],
            [-113.4161, 37.0037],
            [-112.4798, 37.0031],
            [-111.8555, 37.0027],
            [-110.6071, 37.0018],
            [-109.9829, 37.0015],
            [-109.0467, 37.0008]
          ]
        ]
      },
      properties: { name: "Arizona", id: "US-AZ", CNTRY: "United States of America", TYPE: "State" },
      id: "US-AZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.7047, 36.0015],
            [-89.7037, 35.907],
            [-89.7796, 35.8057],
            [-89.8946, 35.7505],
            [-89.9435, 35.6792],
            [-89.9674, 35.5034],
            [-90.0661, 35.4141],
            [-90.1079, 35.3142],
            [-90.0929, 35.2036],
            [-90.1423, 35.1142],
            [-90.2938, 35.0011],
            [-90.3199, 34.8992],
            [-90.416, 34.8516],
            [-90.5392, 34.7282],
            [-90.584, 34.5037],
            [-90.6354, 34.4139],
            [-90.718, 34.3916],
            [-90.8172, 34.2522],
            [-90.9098, 34.1887],
            [-90.943, 34.0298],
            [-91.0702, 33.9743],
            [-91.0528, 33.8276],
            [-91.2075, 33.6757],
            [-91.2146, 33.5834],
            [-91.141, 33.4385],
            [-91.1633, 33.2945],
            [-91.1068, 33.2179],
            [-91.1499, 33.0159],
            [-92.0649, 33.0143],
            [-93.3227, 33.0128],
            [-94.0413, 33.012],
            [-94.0473, 33.5545],
            [-94.2947, 33.5875],
            [-94.3779, 33.5661],
            [-94.4842, 33.6485],
            [-94.4532, 34.8438],
            [-94.4391, 35.3872],
            [-94.6183, 36.5009],
            [-93.7828, 36.5009],
            [-92.6688, 36.5009],
            [-91.2763, 36.5009],
            [-90.1622, 36.5009],
            [-90.0745, 36.3718],
            [-90.0757, 36.2967],
            [-90.303, 36.0993],
            [-90.3811, 35.9928],
            [-89.7047, 36.0015]
          ]
        ]
      },
      properties: { name: "Arkansas", id: "US-AR", CNTRY: "United States of America", TYPE: "State" },
      id: "US-AR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.6236, 35.0009],
            [-85.3859, 33.8507],
            [-85.2009, 32.9562],
            [-85.1565, 32.8042],
            [-85.0151, 32.5261],
            [-84.986, 32.3609],
            [-84.9297, 32.2466],
            [-85.0347, 32.1473],
            [-85.1242, 31.8805],
            [-85.1248, 31.7768],
            [-85.0549, 31.5726],
            [-85.0927, 31.2958],
            [-85.0674, 31.1176],
            [-85.0073, 31.0017],
            [-85.9768, 31.001],
            [-86.6236, 31.0009],
            [-87.5937, 31.0008],
            [-87.601, 30.8606],
            [-87.5662, 30.7956],
            [-87.4216, 30.6716],
            [-87.4294, 30.4778],
            [-87.4896, 30.3777],
            [-87.6222, 30.2647],
            [-87.7903, 30.2918],
            [-87.857, 30.4073],
            [-87.9244, 30.4496],
            [-87.9488, 30.627],
            [-88.0324, 30.6813],
            [-88.1355, 30.3666],
            [-88.3995, 30.3709],
            [-88.4443, 31.1397],
            [-88.4862, 31.8905],
            [-88.3397, 32.9989],
            [-88.2665, 33.5531],
            [-88.1444, 34.4767],
            [-88.0848, 34.933],
            [-88.1733, 34.999],
            [-88.1904, 35.0249],
            [-87.3883, 35.0174],
            [-86.7465, 35.0114],
            [-85.6236, 35.0009]
          ]
        ]
      },
      properties: { name: "Alabama", id: "US-AL", CNTRY: "United States of America", TYPE: "State" },
      id: "US-AL"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-109.7289, 18.1814],
              [-109.6772, 18.0601],
              [-109.6058, 17.7895],
              [-109.609, 17.5352],
              [-109.6515, 17.4977],
              [-109.7327, 17.6672],
              [-109.7458, 17.7539],
              [-109.8561, 17.8533],
              [-109.81, 17.98],
              [-109.9165, 18.1145],
              [-110.0171, 18.1601],
              [-109.8616, 18.295],
              [-109.7289, 18.1814]
            ]
          ],
          [[[-124.4083, 14.962], [-124.3534, 14.9], [-124.471, 14.8313], [-124.5075, 14.9198], [-124.4083, 14.962]]],
          [
            [
              [-109.3832, 17.8674],
              [-109.2946, 17.7479],
              [-109.3358, 17.706],
              [-109.3692, 17.4291],
              [-109.4537, 17.3983],
              [-109.4558, 17.6029],
              [-109.5187, 17.8635],
              [-109.4375, 17.9214],
              [-109.3832, 17.8674]
            ]
          ],
          [
            [
              [-121.3651, 15.7931],
              [-121.4732, 15.7321],
              [-121.556, 15.6213],
              [-121.6584, 15.6223],
              [-121.6031, 15.7421],
              [-121.5317, 15.7428],
              [-121.4792, 15.8818],
              [-121.3727, 15.9149],
              [-121.3088, 15.8731],
              [-121.3651, 15.7931]
            ]
          ],
          [
            [
              [-108.9857, 16.6918],
              [-109.0775, 16.7363],
              [-109.1228, 16.8577],
              [-109.0685, 16.8807],
              [-108.9857, 16.6918]
            ]
          ],
          [
            [
              [-108.9449, 17.6714],
              [-109.0098, 17.6706],
              [-108.9786, 17.8365],
              [-108.8818, 17.7022],
              [-108.9449, 17.6714]
            ]
          ],
          [
            [
              [-120.7201, 20.1446],
              [-120.577, 20.098],
              [-120.5709, 19.9342],
              [-120.5284, 19.83],
              [-120.6536, 19.8147],
              [-120.7385, 19.7377],
              [-121.074, 19.8935],
              [-121.1789, 20.026],
              [-120.9673, 20.0328],
              [-120.8401, 20.1442],
              [-120.7201, 20.1446]
            ]
          ],
          [
            [
              [-124.8618, 14.8422],
              [-124.973, 14.9707],
              [-124.8881, 14.9906],
              [-124.8235, 14.9129],
              [-124.8618, 14.8422]
            ]
          ],
          [
            [
              [-108.9566, 17.5016],
              [-109.0466, 17.5586],
              [-108.9997, 17.6275],
              [-108.9045, 17.5875],
              [-108.9566, 17.5016]
            ]
          ],
          [
            [
              [-111.8564, 27.3342],
              [-111.8564, 26.2996],
              [-111.8564, 25.3087],
              [-111.8564, 24.3573],
              [-111.8564, 23.4422],
              [-111.8564, 22.5603],
              [-111.8564, 21.709],
              [-111.8564, 20.8858],
              [-111.8564, 20.0886],
              [-111.6882, 20.0338],
              [-111.6626, 20.0883],
              [-111.4935, 20.0103],
              [-111.3887, 20.1075],
              [-111.1782, 20.1179],
              [-111.2156, 19.9435],
              [-111.0464, 19.8223],
              [-111.0206, 19.7406],
              [-110.6541, 19.3776],
              [-110.5996, 19.1674],
              [-110.379, 19.3279],
              [-110.2963, 19.3294],
              [-110.2568, 19.4127],
              [-110.2567, 19.53],
              [-110.2058, 19.6261],
              [-110.0691, 19.6642],
              [-109.9074, 19.7506],
              [-109.7576, 19.6088],
              [-109.7647, 19.5186],
              [-109.7197, 19.4181],
              [-109.5422, 19.2858],
              [-109.4639, 19.133],
              [-109.3236, 19.0395],
              [-109.1756, 18.8506],
              [-109.1313, 18.7305],
              [-108.7633, 18.0862],
              [-108.8007, 18.0123],
              [-108.6926, 17.9796],
              [-108.7184, 17.8747],
              [-108.6343, 17.8353],
              [-108.6196, 17.7109],
              [-108.5317, 17.7162],
              [-108.3582, 17.5976],
              [-108.2378, 17.5586],
              [-108.1444, 17.4916],
              [-108.1219, 17.4259],
              [-107.9843, 17.3605],
              [-108.0278, 17.1438],
              [-107.989, 16.9308],
              [-108.0517, 16.7695],
              [-108.1502, 16.6562],
              [-108.2758, 16.6406],
              [-108.3457, 16.8474],
              [-108.2781, 16.9648],
              [-108.3002, 17.1926],
              [-108.3739, 17.3277],
              [-108.4302, 17.3591],
              [-108.6054, 17.2773],
              [-108.6355, 17.1394],
              [-108.7236, 17.093],
              [-108.7603, 17.1837],
              [-108.6265, 17.4487],
              [-108.6893, 17.5825],
              [-108.7457, 17.6073],
              [-108.8533, 17.8191],
              [-108.9644, 17.8984],
              [-108.9687, 17.9883],
              [-109.1984, 18.0699],
              [-109.1882, 18.1724],
              [-109.2297, 18.3966],
              [-109.3979, 18.6348],
              [-109.4092, 18.7231],
              [-109.6606, 18.8781],
              [-109.7271, 19.0634],
              [-109.8574, 19.1716],
              [-109.7571, 18.7841],
              [-109.8462, 18.7516],
              [-109.9412, 18.8513],
              [-110.0477, 18.8399],
              [-110.0434, 18.9571],
              [-110.1082, 19.0938],
              [-110.2926, 19.0919],
              [-110.1285, 18.9117],
              [-110.1379, 18.8119],
              [-110.2553, 18.7976],
              [-110.2977, 18.7448],
              [-110.4703, 18.8406],
              [-110.6367, 18.9598],
              [-110.8071, 19.1923],
              [-110.9219, 19.287],
              [-111.2705, 19.4754],
              [-111.4321, 19.5874],
              [-111.3311, 19.6876],
              [-111.3552, 19.8085],
              [-111.5794, 19.7064],
              [-111.8003, 19.7211],
              [-112.092, 19.8679],
              [-112.4018, 19.9453],
              [-112.5418, 19.9525],
              [-112.8448, 19.8965],
              [-113.031, 20.0153],
              [-113.1404, 20.0379],
              [-113.2315, 20.1121],
              [-113.2606, 20.2481],
              [-113.3539, 20.1424],
              [-113.5833, 20.2085],
              [-113.5043, 20.3253],
              [-113.6715, 20.3319],
              [-113.7075, 20.3917],
              [-113.8441, 20.4928],
              [-113.8244, 20.6505],
              [-113.9646, 20.5477],
              [-114.0848, 20.512],
              [-114.1806, 20.5488],
              [-114.2859, 20.4877],
              [-114.3979, 20.5834],
              [-114.4457, 20.4629],
              [-114.4288, 20.2447],
              [-114.3884, 20.1511],
              [-114.3939, 19.9999],
              [-114.4762, 19.8806],
              [-114.6215, 19.8555],
              [-114.7198, 19.9102],
              [-114.8294, 19.8885],
              [-114.9529, 19.7221],
              [-115.0995, 19.6009],
              [-115.2347, 19.5994],
              [-115.2685, 19.509],
              [-115.3684, 19.3891],
              [-115.5115, 19.3902],
              [-115.6426, 19.353],
              [-115.7223, 19.4161],
              [-115.6821, 19.4955],
              [-115.5232, 19.5677],
              [-115.4491, 19.6476],
              [-115.6517, 19.6888],
              [-115.6832, 19.7431],
              [-115.6413, 19.8801],
              [-115.5416, 20.0232],
              [-115.4926, 20.2005],
              [-115.4958, 20.3877],
              [-115.1853, 20.5789],
              [-115.0288, 20.5186],
              [-114.8859, 20.5586],
              [-115.0183, 20.6463],
              [-115.0103, 20.7649],
              [-115.196, 20.7413],
              [-115.2456, 20.7697],
              [-115.4352, 20.6217],
              [-115.6412, 20.5019],
              [-115.6469, 20.3957],
              [-115.8305, 20.2421],
              [-115.8268, 20.1621],
              [-115.9656, 20.0473],
              [-115.9677, 19.886],
              [-116.0028, 19.8353],
              [-116.125, 19.8048],
              [-116.1046, 19.7086],
              [-116.2338, 19.7154],
              [-116.3748, 19.5397],
              [-116.4715, 19.4672],
              [-116.4861, 19.3082],
              [-116.3191, 19.2554],
              [-116.2056, 19.1382],
              [-116.3054, 19.0072],
              [-116.4474, 18.9032],
              [-116.5274, 18.6902],
              [-116.6466, 18.6243],
              [-116.7954, 18.5992],
              [-116.9033, 18.4672],
              [-117.0011, 18.4209],
              [-117.0674, 18.3169],
              [-117.1457, 18.3024],
              [-117.3143, 18.1667],
              [-117.3132, 18.0283],
              [-117.5474, 17.8561],
              [-117.636, 17.8458],
              [-117.7023, 17.7381],
              [-117.8048, 17.7445],
              [-117.8433, 17.6821],
              [-117.9971, 17.6165],
              [-117.9482, 17.4706],
              [-118.1295, 17.344],
              [-118.3882, 17.2372],
              [-118.5431, 17.2281],
              [-118.7195, 17.0857],
              [-118.8726, 17.0594],
              [-118.9441, 16.995],
              [-119.1705, 16.9864],
              [-119.2878, 16.8367],
              [-119.3791, 16.7837],
              [-119.4783, 16.7963],
              [-119.5668, 16.7273],
              [-119.7127, 16.8262],
              [-119.5814, 16.8699],
              [-119.3171, 17.1827],
              [-119.1549, 17.2957],
              [-118.9851, 17.3648],
              [-118.8566, 17.2143],
              [-118.8238, 17.264],
              [-118.6652, 17.27],
              [-118.7464, 17.3556],
              [-118.6629, 17.5423],
              [-118.4806, 17.6934],
              [-118.3035, 17.7713],
              [-118.2003, 17.8768],
              [-118.0891, 17.8367],
              [-118.0841, 17.9876],
              [-117.9642, 18.148],
              [-117.8137, 18.2814],
              [-117.7126, 18.3377],
              [-117.7395, 18.4273],
              [-117.7138, 18.6213],
              [-117.6545, 18.6984],
              [-117.683, 18.8573],
              [-117.5791, 18.9982],
              [-117.4894, 19.0597],
              [-117.5484, 19.151],
              [-117.8587, 18.9977],
              [-117.9577, 18.9987],
              [-118.0285, 19.1333],
              [-118.1192, 19.198],
              [-118.1548, 19.0483],
              [-118.1233, 18.9207],
              [-118.2328, 18.8883],
              [-118.33, 19.0499],
              [-118.4401, 19.1726],
              [-118.5282, 19.1137],
              [-118.6844, 19.2636],
              [-118.8445, 19.1471],
              [-118.9852, 19.1014],
              [-119.0364, 19.0166],
              [-119.1755, 18.9796],
              [-119.1872, 19.2409],
              [-119.2551, 19.3254],
              [-119.2699, 19.4153],
              [-119.2013, 19.6153],
              [-119.3104, 19.8746],
              [-119.3472, 20.0069],
              [-119.4103, 20.0777],
              [-119.463, 19.8811],
              [-119.6917, 19.7852],
              [-119.9342, 19.7595],
              [-120.0174, 19.8193],
              [-120.0134, 19.884],
              [-120.133, 19.9876],
              [-120.2006, 20.0911],
              [-120.3412, 20.1641],
              [-120.3291, 20.2236],
              [-120.4445, 20.2509],
              [-120.251, 20.4891],
              [-120.0975, 20.4236],
              [-120.0607, 20.3752],
              [-119.93, 20.3891],
              [-119.8718, 20.284],
              [-119.7628, 20.3975],
              [-119.8785, 20.5421],
              [-119.9651, 20.4705],
              [-120.1228, 20.4741],
              [-120.3816, 20.5394],
              [-120.3469, 20.6274],
              [-120.5193, 20.633],
              [-120.5636, 20.7688],
              [-120.6393, 20.8405],
              [-120.6008, 20.9011],
              [-120.7078, 21.008],
              [-120.7002, 21.1183],
              [-120.5358, 21.1646],
              [-120.5691, 21.3271],
              [-120.3883, 21.5915],
              [-120.2466, 21.6985],
              [-120.2652, 21.826],
              [-120.206, 21.9843],
              [-120.1113, 22.1258],
              [-120.0049, 22.1597],
              [-119.874, 22.1095],
              [-119.7409, 22.0027],
              [-119.6364, 22.0274],
              [-119.4809, 22.1627],
              [-119.3614, 22.3556],
              [-119.2526, 22.2996],
              [-119.0873, 22.3108],
              [-118.9442, 22.3767],
              [-118.831, 22.5694],
              [-118.9047, 22.892],
              [-119.0451, 23.0342],
              [-118.885, 23.1395],
              [-118.8692, 23.3043],
              [-118.9749, 23.4027],
              [-119.0735, 23.3039],
              [-119.1768, 23.3201],
              [-119.3225, 23.2147],
              [-119.5463, 22.9846],
              [-119.5708, 23.092],
              [-119.6653, 23.022],
              [-119.8658, 23.1465],
              [-120.0742, 23.1432],
              [-120.2842, 23.0499],
              [-120.7228, 23.1423],
              [-120.8422, 23.2529],
              [-120.8188, 23.4046],
              [-120.964, 23.535],
              [-120.8596, 23.5803],
              [-120.8317, 23.652],
              [-120.907, 23.7226],
              [-121.1675, 23.7878],
              [-121.3732, 23.9016],
              [-121.4089, 23.9722],
              [-121.2296, 24.0513],
              [-121.168, 24.131],
              [-121.0512, 24.1449],
              [-120.9361, 24.2836],
              [-120.7481, 24.378],
              [-120.5749, 24.332],
              [-120.5934, 24.4971],
              [-120.4782, 24.5702],
              [-120.3423, 24.5926],
              [-120.2048, 24.6873],
              [-119.9876, 24.7325],
              [-119.8879, 24.6679],
              [-119.9789, 24.4142],
              [-119.8596, 24.3091],
              [-119.5744, 24.3214],
              [-119.4687, 24.2828],
              [-119.1969, 24.285],
              [-119.1054, 24.4422],
              [-118.9799, 24.4173],
              [-118.9514, 24.5094],
              [-119.101, 24.5679],
              [-119.2011, 24.5388],
              [-119.222, 24.6373],
              [-119.3291, 24.7993],
              [-119.4266, 24.8339],
              [-119.4759, 24.9634],
              [-119.3892, 25.0067],
              [-119.3081, 24.8874],
              [-119.2298, 24.691],
              [-119.1175, 24.6103],
              [-118.926, 24.6221],
              [-118.7858, 24.5406],
              [-118.6381, 24.5785],
              [-118.6489, 24.7014],
              [-118.7834, 24.7278],
              [-118.8611, 24.7812],
              [-119.0494, 24.6848],
              [-119.2112, 24.8051],
              [-119.2189, 24.8896],
              [-119.1284, 25.033],
              [-119.2496, 25.0908],
              [-119.3998, 25.0664],
              [-119.6149, 25.0724],
              [-119.8019, 25.1344],
              [-119.8685, 25.2111],
              [-119.9468, 25.4455],
              [-120.0112, 25.5537],
              [-120.4558, 25.9248],
              [-120.6581, 26.0189],
              [-120.7556, 26.1236],
              [-120.875, 26.16],
              [-120.8066, 26.2504],
              [-120.7365, 26.5742],
              [-120.7461, 26.6515],
              [-120.4994, 26.6359],
              [-120.0736, 26.6965],
              [-119.9205, 26.7849],
              [-119.8033, 26.9032],
              [-119.6713, 27.0974],
              [-119.6474, 27.2978],
              [-119.5975, 27.4315],
              [-119.2197, 27.9588],
              [-119.1344, 27.8685],
              [-118.9077, 27.9335],
              [-118.78, 28.0655],
              [-118.5977, 28.2012],
              [-118.5434, 28.1795],
              [-118.5898, 27.9601],
              [-118.5053, 27.9516],
              [-118.4669, 28.1442],
              [-118.5428, 28.2414],
              [-118.4438, 28.3857],
              [-118.3146, 28.4727],
              [-118.2024, 28.3997],
              [-118.07, 28.3973],
              [-118.0218, 28.4371],
              [-117.8505, 28.4411],
              [-117.7119, 28.5324],
              [-117.5673, 28.6777],
              [-117.4219, 28.8953],
              [-117.3114, 28.8688],
              [-117.0791, 28.7693],
              [-116.9973, 28.7044],
              [-117.0766, 28.5841],
              [-117.1973, 28.5197],
              [-117.1006, 28.431],
              [-116.9973, 28.4877],
              [-116.8518, 28.6835],
              [-116.7732, 28.6679],
              [-116.6779, 28.5761],
              [-116.7172, 28.4877],
              [-116.5092, 28.3992],
              [-116.4115, 28.4715],
              [-116.1698, 28.5241],
              [-116.0118, 28.4704],
              [-115.9082, 28.4751],
              [-115.8171, 28.408],
              [-115.901, 28.2598],
              [-115.796, 28.1687],
              [-115.6536, 28.1719],
              [-115.7155, 28.0709],
              [-115.4616, 28.0398],
              [-115.2633, 28.1248],
              [-115.1718, 28.0632],
              [-115.0834, 28.0629],
              [-114.9838, 28.1247],
              [-114.772, 28.1163],
              [-114.567, 28.0375],
              [-114.4933, 27.9459],
              [-114.412, 27.982],
              [-114.2783, 27.9322],
              [-114.2204, 27.8524],
              [-113.9938, 27.8091],
              [-113.8817, 27.8288],
              [-113.5566, 27.7996],
              [-113.3359, 27.6604],
              [-113.1319, 27.636],
              [-113.0606, 27.6882],
              [-112.8242, 27.746],
              [-112.6379, 27.7593],
              [-112.4579, 27.6834],
              [-112.3129, 27.5332],
              [-112.1021, 27.4425],
              [-111.9994, 27.3365],
              [-111.8564, 27.3342]
            ]
          ],
          [
            [
              [-122.5991, 22.4372],
              [-122.448, 22.397],
              [-122.2349, 22.4805],
              [-122.1261, 22.4206],
              [-122.0892, 22.328],
              [-121.9505, 22.2832],
              [-121.8812, 22.223],
              [-121.6302, 22.1954],
              [-121.6784, 22.0939],
              [-121.859, 22.0938],
              [-121.9689, 21.9378],
              [-122.0047, 22.0375],
              [-122.15, 22.1121],
              [-122.269, 22.2455],
              [-122.4573, 22.2945],
              [-122.5772, 22.2163],
              [-122.7147, 22.2788],
              [-122.6989, 22.4837],
              [-122.5991, 22.4372]
            ]
          ],
          [
            [
              [-119.7822, 16.7426],
              [-119.7406, 16.5983],
              [-119.82, 16.5337],
              [-119.9929, 16.531],
              [-120.1305, 16.4176],
              [-120.2574, 16.4129],
              [-120.2801, 16.5233],
              [-120.1539, 16.6838],
              [-120.0636, 16.6951],
              [-119.9205, 16.7772],
              [-119.7822, 16.7426]
            ]
          ],
          [
            [
              [-109.1634, 17.9654],
              [-109.0592, 17.9473],
              [-109.018, 17.8892],
              [-109.0198, 17.7641],
              [-109.1093, 17.6341],
              [-109.2468, 17.6338],
              [-109.2744, 17.8383],
              [-109.3797, 17.9692],
              [-109.3397, 18.0056],
              [-109.1634, 17.9654]
            ]
          ],
          [
            [
              [-113.7579, 20.1892],
              [-113.6642, 20.1507],
              [-113.837, 20.0709],
              [-113.8668, 20.1615],
              [-113.7579, 20.1892]
            ]
          ],
          [[[-109.1418, 17.0774], [-109.2635, 16.9137], [-109.2579, 17.0747], [-109.1418, 17.0774]]],
          [
            [
              [-123.7327, 15.0554],
              [-123.6017, 15.1563],
              [-123.6473, 15.2127],
              [-123.5533, 15.2703],
              [-123.5002, 15.2208],
              [-123.5363, 15.1111],
              [-123.7327, 15.0554]
            ]
          ],
          [
            [
              [-120.8894, 16.1121],
              [-120.829, 15.9687],
              [-120.9441, 15.8679],
              [-121.0792, 15.8289],
              [-121.2093, 15.7538],
              [-121.3103, 15.781],
              [-121.0731, 15.8968],
              [-121.0306, 15.9954],
              [-121.0667, 16.0958],
              [-121.0173, 16.1479],
              [-120.8894, 16.1121]
            ]
          ],
          [
            [
              [-109.6268, 18.6916],
              [-109.4715, 18.6804],
              [-109.481, 18.5159],
              [-109.3646, 18.3253],
              [-109.3605, 18.1722],
              [-109.4221, 18.1494],
              [-109.5404, 17.9984],
              [-109.5954, 18.0201],
              [-109.5585, 18.263],
              [-109.6196, 18.3609],
              [-109.6529, 18.586],
              [-109.6268, 18.6916]
            ]
          ],
          [
            [
              [-108.3215, 17.0448],
              [-108.358, 16.9122],
              [-108.4773, 16.9177],
              [-108.6149, 16.8863],
              [-108.6273, 17.0013],
              [-108.5572, 17.1025],
              [-108.5492, 17.2503],
              [-108.4238, 17.3248],
              [-108.3169, 17.1527],
              [-108.3215, 17.0448]
            ]
          ],
          [
            [
              [-109.2339, 17.5576],
              [-109.1058, 17.5458],
              [-109.0683, 17.4063],
              [-108.9488, 17.3487],
              [-108.8333, 17.1633],
              [-108.8289, 17.0411],
              [-108.7575, 16.9816],
              [-108.7544, 16.887],
              [-108.6732, 16.8776],
              [-108.6748, 16.639],
              [-108.7289, 16.5846],
              [-108.802, 16.6992],
              [-108.9575, 16.7828],
              [-109.0705, 16.9679],
              [-109.0658, 17.1186],
              [-109.1394, 17.1153],
              [-109.1918, 17.2294],
              [-109.1194, 17.304],
              [-109.2215, 17.4399],
              [-109.2339, 17.5576]
            ]
          ],
          [
            [
              [-109.9972, 18.7443],
              [-109.9467, 18.6822],
              [-109.7403, 18.6214],
              [-109.7142, 18.5591],
              [-109.7321, 18.415],
              [-109.6947, 18.3301],
              [-109.7695, 18.2811],
              [-109.9151, 18.3759],
              [-109.9574, 18.2619],
              [-110.0607, 18.2409],
              [-110.1192, 18.3837],
              [-110.2927, 18.5714],
              [-110.2729, 18.6499],
              [-109.9972, 18.7443]
            ]
          ],
          [
            [
              [-116.0517, 18.4777],
              [-115.9154, 18.4951],
              [-115.8801, 18.3659],
              [-115.8113, 18.3225],
              [-115.9745, 18.1775],
              [-116.1844, 18.1034],
              [-116.3674, 17.9566],
              [-116.4401, 17.994],
              [-116.5599, 17.9143],
              [-116.6163, 17.9858],
              [-116.6893, 18.1715],
              [-116.6291, 18.3114],
              [-116.5396, 18.3609],
              [-116.3762, 18.3341],
              [-116.3321, 18.3767],
              [-116.4066, 18.4752],
              [-116.3213, 18.4992],
              [-116.2726, 18.4192],
              [-116.1583, 18.4753],
              [-116.0517, 18.4777]
            ]
          ],
          [
            [
              [-108.7212, 17.418],
              [-108.7284, 17.3175],
              [-108.9143, 17.399],
              [-108.8154, 17.6549],
              [-108.7051, 17.4998],
              [-108.7212, 17.418]
            ]
          ],
          [
            [
              [-114.2038, 20.1898],
              [-114.2299, 20.0363],
              [-114.2789, 20.0413],
              [-114.2519, 20.1961],
              [-114.2038, 20.1898]
            ]
          ],
          [
            [
              [-114.2311, 19.7638],
              [-114.2588, 19.8227],
              [-114.1359, 19.9526],
              [-114.014, 20.1308],
              [-113.967, 20.0578],
              [-114.0839, 19.9381],
              [-114.1477, 19.8148],
              [-114.2311, 19.7638]
            ]
          ],
          [
            [
              [-115.882, 18.8182],
              [-115.8465, 18.8522],
              [-115.7288, 18.7443],
              [-115.7734, 18.6913],
              [-116.0815, 18.5871],
              [-116.2221, 18.6443],
              [-115.9729, 18.8761],
              [-115.882, 18.8182]
            ]
          ]
        ]
      },
      properties: { name: "Alaska", id: "US-AK", CNTRY: "United States of America", TYPE: "State" },
      id: "US-AK"
    },

    {
      type: "Feature",
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [[-98.9968, 20.3257], [-104.6184, 27.044], [-109.1532, 27.044]],
          [[-120.2683, 30.2071], [-112.6861, 30.2071], [-109.1532, 27.044], [-104.5687, 20.6047]]
        ]
      },
      properties: { name: "Divider 1", id: "div1", TYPE: "Divider" },
      id: "div1"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [[-72.0274, 31.886], [-76.1966, 22.212]],
          [[-76.1966, 22.212], [-76.1966, 15.4213]],
          [[-94.9552, 22.212], [-85.1474, 22.212]],
          [[-76.1966, 22.212], [-85.1474, 22.212]],
          [[-69.2668, 22.212], [-76.1966, 22.212]],
          [[-85.1474, 22.212], [-85.1474, 15.4213]],
          [[-85.1474, 22.212], [-94.9552, 24.943]]
        ]
      },
      properties: { name: "Divider 2", id: "div2", TYPE: "Divider" },
      id: "div2"
    }
  ]
};
export default map;
