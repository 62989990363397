var map = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [146.2078, 44.498],
              [146.4364, 44.3757],
              [146.1124, 44.2461],
              [145.7671, 43.9407],
              [145.6665, 43.9991],
              [145.9402, 44.2725],
              [146.1121, 44.5001],
              [146.2078, 44.498]
            ]
          ],
          [
            [
              [148.8256, 45.4562],
              [148.7909, 45.3238],
              [148.5993, 45.3175],
              [148.2623, 45.2169],
              [147.9138, 44.9905],
              [147.658, 44.9771],
              [147.5631, 44.8355],
              [147.31, 44.6774],
              [147.2466, 44.8559],
              [147.4304, 44.9453],
              [147.7695, 45.1907],
              [147.9647, 45.3775],
              [148.0561, 45.2621],
              [148.3241, 45.2826],
              [148.6125, 45.4845],
              [148.8256, 45.4562]
            ]
          ],
          [[[150.3087, 46.2005], [150.1949, 45.9332], [149.8834, 45.7833], [149.6659, 45.8395], [150.3087, 46.2005]]],
          [
            [
              [156.0019, 50.7571],
              [156.1232, 50.6714],
              [156.0446, 50.4516],
              [155.7922, 50.202],
              [155.2435, 50.0945],
              [155.2184, 50.2978],
              [155.6802, 50.4009],
              [155.8845, 50.6841],
              [156.0019, 50.7571]
            ]
          ],
          [[[156.3764, 50.8621], [156.4831, 50.7513], [156.3258, 50.6391], [156.168, 50.732], [156.3764, 50.8621]]],
          [
            [
              [144.6855, 48.8711],
              [144.5361, 48.8936],
              [144.1257, 49.2084],
              [143.7323, 49.3119],
              [143.3825, 49.2906],
              [143.1049, 49.1988],
              [142.574, 48.072],
              [142.5568, 47.7382],
              [142.6703, 47.537],
              [143.0055, 47.2227],
              [143.2178, 46.7949],
              [143.4472, 46.7918],
              [143.5808, 46.3607],
              [143.419, 46.2223],
              [143.2819, 46.5592],
              [142.8292, 46.6053],
              [142.5779, 46.7007],
              [142.4065, 46.5548],
              [142.2087, 46.0891],
              [141.9613, 46.0136],
              [141.83, 46.4508],
              [141.8664, 46.6941],
              [142.0386, 47.1402],
              [141.9645, 47.5872],
              [142.1816, 48.0133],
              [142.1349, 48.2899],
              [141.866, 48.7501],
              [142.0202, 49.0786],
              [142.1424, 49.5692],
              [142.1534, 50.2168],
              [142.0712, 50.5149],
              [142.1007, 50.7766],
              [142.2078, 50.9985],
              [142.2065, 51.2224],
              [142.0058, 51.5206],
              [141.7223, 51.7364],
              [141.6612, 52.273],
              [141.8035, 52.5558],
              [141.8735, 53.0389],
              [141.8234, 53.3393],
              [142.1419, 53.4957],
              [142.3191, 53.4057],
              [142.526, 53.4474],
              [142.5093, 53.5875],
              [142.6429, 53.7368],
              [142.6703, 53.9684],
              [142.3349, 54.2806],
              [142.5515, 54.2788],
              [142.7611, 54.394],
              [142.9761, 54.1411],
              [142.9181, 53.7941],
              [143.2235, 53.2962],
              [143.3245, 52.9631],
              [143.3232, 52.6137],
              [143.172, 52.3492],
              [143.1558, 52.0837],
              [143.3202, 51.5834],
              [143.455, 51.4716],
              [143.8162, 50.2824],
              [144.0479, 49.8957],
              [144.272, 49.3112],
              [144.4319, 49.0511],
              [144.6855, 48.8711]
            ]
          ],
          [
            [
              [138.0313, 55.0531],
              [137.9914, 54.8207],
              [137.6614, 54.6533],
              [137.4627, 54.8734],
              [137.4354, 55.016],
              [137.5774, 55.1971],
              [138.0313, 55.0531]
            ]
          ],
          [
            [
              [166.4793, 54.9499],
              [166.3247, 54.8646],
              [165.9915, 55.1904],
              [165.9313, 55.3515],
              [166.2759, 55.312],
              [166.2482, 55.1654],
              [166.4793, 54.9499]
            ]
          ],
          [
            [
              [164.5726, 59.2211],
              [164.6157, 58.8856],
              [163.9599, 58.7436],
              [163.7266, 58.7986],
              [163.7613, 59.015],
              [164.2016, 59.0961],
              [164.5726, 59.2211]
            ]
          ],
          [[[70.0769, 66.6958], [70.0207, 66.5022], [69.6515, 66.5653], [69.4692, 66.716], [70.0769, 66.6958]]],

          [[[161.1255, 69.1969], [161.1105, 69.4698], [161.5404, 69.4364], [161.1255, 69.1969]]],
          [
            [
              [167.9928, 69.7759],
              [167.7889, 69.8368],
              [168.3581, 70.0157],
              [169.3749, 69.8825],
              [169.2009, 69.5806],
              [168.9157, 69.5713],
              [168.3478, 69.6645],
              [167.9928, 69.7759]
            ]
          ],
          [
            [
              [179.8862, 71.5234],
              [180, 70.9929],
              [179.6475, 70.899],
              [178.7928, 70.822],
              [178.6285, 71.0473],
              [178.8913, 71.2312],
              [179.8862, 71.5234]
            ]
          ],

          [
            [
              [77.378, 72.5654],
              [77.7484, 72.6313],
              [78.365, 72.4825],
              [77.781, 72.3085],
              [77.146, 72.2819],
              [76.903, 72.3654],
              [77.378, 72.5654]
            ]
          ],
          [
            [
              [78.8805, 72.7517],
              [78.6568, 72.8923],
              [79.164, 73.0944],
              [79.5415, 72.9187],
              [79.4302, 72.7106],
              [78.8805, 72.7517]
            ]
          ],
          [[[74.435, 72.9077], [74.1982, 73.1089], [74.7251, 73.1081], [74.435, 72.9077]]],
          [
            [
              [70.1187, 73.0565],
              [69.92, 73.0846],
              [69.9961, 73.3594],
              [70.3499, 73.4777],
              [70.94, 73.5146],
              [71.5895, 73.2832],
              [71.626, 73.174],
              [70.1187, 73.0565]
            ]
          ],
          [
            [
              [139.6859, 73.4258],
              [140.3803, 73.4829],
              [141.0847, 73.8659],
              [141.9319, 73.9149],
              [142.6395, 73.8031],
              [143.3439, 73.5686],
              [143.491, 73.2465],
              [143.1932, 73.2205],
              [141.5967, 73.3107],
              [140.6628, 73.452],
              [140.0269, 73.3614],
              [139.6859, 73.4258]
            ]
          ],
          [[[135.4487, 74.1797], [135.6284, 74.2199], [136.2594, 73.985], [136.0516, 73.9292], [135.4487, 74.1797]]],
          [
            [
              [140.4093, 73.9217],
              [140.1016, 74.1843],
              [140.8491, 74.2737],
              [141.0975, 74.1678],
              [141.0104, 73.9995],
              [140.4093, 73.9217]
            ]
          ],
          [
            [
              [112.0847, 74.549],
              [113.3872, 74.4005],
              [112.7822, 74.0951],
              [112.1959, 74.146],
              [111.6431, 74.2728],
              [112.0847, 74.549]
            ]
          ],
          [
            [
              [146.9246, 75.0625],
              [146.1484, 75.1983],
              [146.4385, 75.5583],
              [146.7949, 75.3708],
              [147.4967, 75.4405],
              [148.4328, 75.4135],
              [148.4749, 75.2723],
              [150.104, 75.2192],
              [150.5302, 75.0999],
              [150.6462, 74.9445],
              [149.5968, 74.7725],
              [148.0926, 74.8257],
              [147.6268, 74.9589],
              [146.9246, 75.0625]
            ]
          ],
          [[[135.9488, 75.4095], [135.4517, 75.3895], [135.6138, 75.7664], [136.1693, 75.6057], [135.9488, 75.4095]]],
          [
            [
              [137.0065, 75.235],
              [137.2895, 75.3486],
              [137.2689, 75.7494],
              [137.5013, 75.9098],
              [138.2075, 76.115],
              [138.8139, 76.1996],
              [139.7434, 75.9531],
              [140.4962, 75.6899],
              [140.944, 75.7004],
              [141.0323, 75.9889],
              [141.4854, 76.1373],
              [142.4605, 75.9034],
              [143.1852, 75.8136],
              [143.6858, 75.8636],
              [145.2551, 75.5857],
              [144.7268, 75.3656],
              [144.8837, 75.2691],
              [144.0198, 75.0445],
              [143.1704, 75.117],
              [142.7296, 75.3377],
              [142.5515, 75.7209],
              [142.0861, 75.6607],
              [142.1512, 75.4576],
              [142.6167, 75.1331],
              [143.1282, 74.9703],
              [142.7784, 74.8677],
              [142.2874, 74.8501],
              [141.9873, 74.9914],
              [140.2677, 74.8468],
              [139.6059, 74.9457],
              [139.3256, 74.6868],
              [139.0992, 74.6567],
              [138.0919, 74.7973],
              [137.6828, 75.0087],
              [137.0065, 75.235]
            ]
          ],
          [
            [
              [130.6872, 42.3029],
              [130.5267, 42.5353],
              [130.4248, 42.7269],
              [130.5769, 42.8117],
              [131.0687, 42.9023],
              [131.2619, 43.4331],
              [131.1741, 43.7047],
              [131.2555, 44.0717],
              [131.0608, 44.6596],
              [130.9675, 44.8],
              [131.0822, 44.9101],
              [131.447, 44.9842],
              [131.6539, 45.2052],
              [131.8518, 45.3269],
              [131.9775, 45.2439],
              [132.9359, 45.0299],
              [133.1134, 45.1308],
              [133.1859, 45.4946],
              [133.4365, 45.6049],
              [133.5129, 45.8787],
              [133.6855, 46.0087],
              [133.7005, 46.1397],
              [133.8613, 46.2476],
              [133.8666, 46.4992],
              [134.0226, 46.7132],
              [134.0385, 46.8584],
              [134.2024, 47.1283],
              [134.1676, 47.3023],
              [134.2907, 47.4137],
              [134.4832, 47.4473],
              [134.7521, 47.7156],
              [134.5658, 48.0228],
              [134.6695, 48.1533],
              [134.5637, 48.3218],
              [134.2933, 48.3734],
              [133.8424, 48.2739],
              [133.4684, 48.0973],
              [133.1442, 48.1056],
              [132.7074, 47.9472],
              [132.4767, 47.7152],
              [131.5569, 47.6822],
              [131.3191, 47.7279],
              [130.9618, 47.7092],
              [130.9153, 47.8428],
              [130.7324, 48.019],
              [130.8045, 48.3414],
              [130.5523, 48.6024],
              [130.5532, 48.8613],
              [130.1963, 48.8916],
              [130.0373, 48.9722],
              [129.7924, 49.1988],
              [129.534, 49.3235],
              [129.0652, 49.3747],
              [128.8191, 49.4638],
              [128.704, 49.6],
              [128, 49.5686],
              [127.7113, 49.6716],
              [127.5024, 49.8736],
              [127.59, 50.209],
              [127.3953, 50.2985],
              [127.3061, 50.4534],
              [127.307, 50.7081],
              [126.9246, 51.1003],
              [126.8055, 51.5057],
              [126.5107, 51.9258],
              [126.3129, 52.3997],
              [126.0163, 52.6103],
              [126.048, 52.7394],
              [125.728, 52.8908],
              [125.6489, 53.0422],
              [125.2257, 53.1659],
              [124.9712, 53.1974],
              [124.8122, 53.1338],
              [124.4659, 53.2299],
              [124.2914, 53.341],
              [123.608, 53.5465],
              [123.1541, 53.5448],
              [122.745, 53.4685],
              [122.338, 53.4849],
              [121.4055, 53.3169],
              [120.9855, 53.2846],
              [120.7041, 53.1718],
              [120.0946, 52.7871],
              [120.0674, 52.6329],
              [120.5212, 52.6151],
              [120.6993, 52.4935],
              [120.6501, 52.396],
              [120.7445, 52.2055],
              [120.6813, 51.973],
              [120.0669, 51.6006],
              [119.5735, 50.9469],
              [119.4455, 50.703],
              [119.2807, 50.5611],
              [119.192, 50.3798],
              [119.3462, 50.2789],
              [119.326, 50.155],
              [119.1475, 50.0133],
              [118.756, 49.963],
              [118.4515, 49.8443],
              [117.8732, 49.5134],
              [117.2456, 49.6248],
              [116.6831, 49.8236],
              [116.5508, 49.9203],
              [116.1347, 50.0108],
              [115.7176, 49.8805],
              [115.2742, 49.9489],
              [115.0036, 50.1388],
              [114.7429, 50.2336],
              [114.2969, 50.2743],
              [113.574, 50.0071],
              [113.164, 49.7971],
              [113.0559, 49.6162],
              [112.6978, 49.5072],
              [112.4951, 49.5323],
              [112.0794, 49.4242],
              [111.3367, 49.3559],
              [110.8279, 49.1662],
              [110.6314, 49.1374],
              [110.4279, 49.2201],
              [110.1999, 49.1704],
              [109.2366, 49.3347],
              [108.6139, 49.3229],
              [108.0338, 49.594],
              [107.9368, 49.6909],
              [107.9477, 49.9247],
              [107.6308, 49.9831],
              [107.2331, 49.9893],
              [106.7111, 50.3125],
              [106.2181, 50.3044],
              [105.8753, 50.4055],
              [105.3835, 50.4736],
              [105.0943, 50.3899],
              [104.4663, 50.3059],
              [104.1798, 50.1693],
              [103.6328, 50.1388],
              [103.3044, 50.2005],
              [103.1616, 50.2908],
              [102.6835, 50.387],
              [102.2885, 50.585],
              [102.1118, 51.3534],
              [101.5708, 51.4672],
              [101.3814, 51.4527],
              [100.4687, 51.726],
              [99.9215, 51.7555],
              [99.7194, 51.8718],
              [99.407, 51.9235],
              [98.8483, 52.0702],
              [98.6405, 51.8012],
              [98.3527, 51.7177],
              [98.22, 51.5057],
              [97.9471, 51.3483],
              [97.8253, 50.9851],
              [98.0301, 50.6448],
              [98.2793, 50.5334],
              [98.2503, 50.3024],
              [98.1031, 50.0779],
              [97.8538, 49.9467],
              [97.5893, 49.9115],
              [97.3599, 49.7415],
              [97.2087, 49.7307],
              [96.9859, 49.8828],
              [96.3154, 49.9012],
              [96.0653, 49.9985],
              [95.8518, 50.0129],
              [95.523, 49.9111],
              [95.0445, 49.9617],
              [94.9302, 50.0438],
              [94.6147, 50.0238],
              [94.3545, 50.222],
              [94.2512, 50.5564],
              [93.1034, 50.6039],
              [92.9412, 50.7782],
              [92.6266, 50.6883],
              [92.3551, 50.8643],
              [92.1925, 50.7006],
              [91.8044, 50.6936],
              [91.3412, 50.4701],
              [91.0213, 50.4154],
              [90.5168, 50.2132],
              [90.0048, 50.0693],
              [89.9772, 49.9843],
              [89.644, 49.903],
              [89.6542, 49.7176],
              [89.18, 49.5323],
              [88.8315, 49.4484],
              [88.6333, 49.4861],
              [88.1925, 49.4519],
              [88.1156, 49.2564],
              [87.8141, 49.1623],
              [87.3228, 49.0858],
              [87.2336, 49.2161],
              [86.9533, 49.322],
              [86.6144, 49.6098],
              [86.6101, 49.7691],
              [86.1807, 49.4993],
              [85.2099, 49.6648],
              [85.0008, 49.8942],
              [84.9894, 50.0614],
              [84.2577, 50.2884],
              [84.1764, 50.5204],
              [83.9452, 50.7747],
              [83.3572, 50.9946],
              [83.0193, 50.8972],
              [82.7609, 50.8933],
              [82.4937, 50.7276],
              [82.0982, 50.7109],
              [81.9334, 50.7662],
              [81.4658, 50.7397],
              [81.388, 50.9565],
              [81.1248, 50.9465],
              [81.1274, 51.1912],
              [80.8769, 51.2815],
              [80.6502, 51.2774],
              [80.4485, 51.1833],
              [80.4234, 50.9465],
              [80.2199, 50.9117],
              [79.9862, 50.7747],
              [79.4685, 51.4931],
              [78.4758, 52.6386],
              [77.8601, 53.2692],
              [77.7041, 53.379],
              [76.576, 53.9425],
              [76.4222, 54.1136],
              [76.6543, 54.1452],
              [76.7887, 54.3219],
              [76.2662, 54.312],
              [75.6571, 54.1059],
              [75.4374, 54.0899],
              [75.3768, 53.9701],
              [75.0524, 53.8269],
              [74.834, 53.8257],
              [74.4517, 53.6473],
              [74.4029, 53.5045],
              [74.069, 53.6115],
              [73.6431, 53.5761],
              [73.4699, 53.4689],
              [73.2858, 53.5984],
              [73.3992, 53.8115],
              [73.6791, 53.9295],
              [73.1193, 53.9809],
              [72.9141, 54.1072],
              [72.3872, 54.1231],
              [72.2694, 54.2722],
              [72.0048, 54.2056],
              [71.3364, 54.1584],
              [71.0934, 54.2123],
              [71.1857, 54.5994],
              [70.9102, 55.1281],
              [70.7383, 55.3051],
              [70.4865, 55.2823],
              [70.1824, 55.1626],
              [69.4929, 55.3568],
              [68.9774, 55.3895],
              [68.5248, 55.2048],
              [68.2062, 55.1608],
              [68.0739, 54.9595],
              [67.6933, 54.8723],
              [66.2225, 54.6673],
              [65.4768, 54.6234],
              [65.2377, 54.5161],
              [65.1577, 54.3643],
              [64.4612, 54.3841],
              [64.0037, 54.2672],
              [63.1911, 54.171],
              [63.0738, 54.1052],
              [62.6326, 54.0694],
              [62.499, 54.0132],
              [62.0402, 54.0027],
              [61.9286, 53.9464],
              [61.334, 54.0492],
              [61.1433, 53.9637],
              [61.1134, 53.7534],
              [60.9798, 53.6216],
              [61.2479, 53.5508],
              [61.1995, 53.2872],
              [61.6596, 53.2283],
              [62.0148, 53.1079],
              [61.9739, 52.9438],
              [61.4008, 52.9961],
              [61.0475, 52.9725],
              [60.8023, 52.7447],
              [60.8212, 52.5698],
              [60.9798, 52.3949],
              [60.9376, 52.2804],
              [60.6705, 52.1508],
              [60.4257, 52.1255],
              [60.2337, 52.0247],
              [59.9919, 52.0503],
              [59.7071, 52.4617],
              [59.037, 52.607],
              [59.0053, 53.1793],
              [59.4083, 53.6475],
              [59.883, 53.9418],
              [59.6294, 54.3087],
              [58.9548, 54.5315],
              [58.2974, 54.298],
              [57.6207, 53.9831],
              [56.9737, 54.4687],
              [56.5466, 54.8472],
              [56.0839, 55.3471],
              [57.1834, 55.4219],
              [58.0983, 55.5504],
              [58.6903, 56.0874],
              [58.5799, 56.5143],
              [57.8276, 56.8591],
              [58.0263, 57.3293],
              [58.7984, 57.8522],
              [59.4317, 58.5187],
              [60.0645, 59.4402],
              [60.5426, 61.0084],
              [61.2167, 64.2825],
              [63.0821, 64.9055],
              [64.8013, 65.7163],
              [66.2023, 66.1717],
              [67.5444, 66.7229],
              [68.269, 67.2858],
              [67.8739, 68.4712],
              [68.3714, 68.3143],
              [68.8293, 68.5674],
              [69.1405, 68.9506],
              [68.5428, 68.9671],
              [68.1174, 69.2362],
              [68.0062, 69.48],
              [67.0645, 69.6938],
              [66.8039, 69.6592],
              [66.9265, 70.0141],
              [67.2394, 70.1081],
              [67.1467, 70.22],
              [67.2847, 70.7385],
              [66.8228, 70.7974],
              [66.6663, 70.9007],
              [66.9177, 71.2824],
              [67.5417, 71.412],
              [68.2695, 71.6827],
              [68.6074, 72.0127],
              [69.039, 72.6699],
              [69.3914, 72.9553],
              [69.645, 72.8975],
              [71.5003, 72.9136],
              [72.4465, 72.7903],
              [72.8121, 72.6915],
              [72.6245, 72.0793],
              [72.2795, 71.6954],
              [71.9116, 71.5478],
              [72.0791, 71.3067],
              [72.5814, 71.1512],
              [72.7317, 70.8229],
              [72.7001, 70.4575],
              [72.4693, 70.2749],
              [72.5295, 70.1725],
              [72.6157, 69.4839],
              [72.5273, 69.0805],
              [72.8121, 68.8153],
              [73.5482, 68.5745],
              [73.1395, 68.1814],
              [73.1523, 67.8652],
              [72.9487, 67.6962],
              [72.5946, 67.587],
              [71.8475, 67.0078],
              [71.3654, 66.9614],
              [71.5513, 66.7604],
              [70.9391, 66.5481],
              [70.3828, 66.6025],
              [69.877, 66.8454],
              [69.0135, 66.7885],
              [69.1945, 66.5787],
              [70.3397, 66.3423],
              [71.1453, 66.3667],
              [71.9173, 66.2467],
              [72.3216, 66.3321],
              [72.4175, 66.5606],
              [73.3416, 66.8068],
              [73.792, 66.9953],
              [74.0747, 67.4141],
              [74.7694, 67.7663],
              [74.7426, 68.0735],
              [74.3915, 68.4206],
              [74.5796, 68.7512],
              [75.1249, 68.8617],
              [76.1076, 68.9757],
              [76.4591, 68.9783],
              [77.2387, 68.4697],
              [77.2483, 67.941],
              [77.1746, 67.7786],
              [77.6751, 67.5897],
              [77.9858, 67.5593],
              [78.9223, 67.589],
              [77.5885, 67.7518],
              [77.5358, 68.0075],
              [77.6645, 68.1904],
              [77.9955, 68.2596],
              [77.6509, 68.9029],
              [76.0007, 69.235],
              [75.4198, 69.2387],
              [74.8147, 69.0906],
              [74.3625, 69.1447],
              [73.9771, 69.1146],
              [73.7758, 69.1983],
              [73.891, 69.418],
              [73.56, 69.7071],
              [73.8303, 70.1758],
              [74.2069, 70.4454],
              [74.3111, 70.6537],
              [73.5073, 71.2635],
              [73.0863, 71.4448],
              [73.6716, 71.8451],
              [74.3111, 71.9578],
              [74.9918, 72.1448],
              [75.0972, 72.4208],
              [74.787, 72.8119],
              [75.3693, 72.7967],
              [75.6035, 72.581],
              [75.7415, 72.2962],
              [75.2471, 71.8134],
              [75.5033, 71.6546],
              [75.3319, 71.3417],
              [76.1106, 71.2184],
              [77.5898, 71.1679],
              [78.0679, 70.9864],
              [78.5259, 70.9117],
              [78.2125, 71.2663],
              [77.4808, 71.3114],
              [76.4332, 71.5524],
              [76.0324, 71.9103],
              [76.8714, 72.0329],
              [77.5507, 71.8423],
              [78.2323, 71.9523],
              [78.0161, 72.0921],
              [77.4927, 72.0717],
              [77.4716, 72.1923],
              [77.9682, 72.3287],
              [78.4828, 72.395],
              [79.4219, 72.3808],
              [79.9537, 72.223],
              [80.7627, 72.0892],
              [80.8563, 71.9701],
              [81.5111, 71.7462],
              [82.0802, 71.7067],
              [82.9859, 71.7486],
              [83.2337, 71.6682],
              [82.9182, 71.4199],
              [82.3228, 71.26],
              [82.2388, 70.9976],
              [82.3359, 70.8075],
              [82.8694, 70.9547],
              [83.0514, 70.8152],
              [83.0298, 70.5805],
              [82.6822, 70.2178],
              [83.497, 70.3453],
              [83.7356, 70.5465],
              [83.5792, 70.766],
              [83.1511, 71.1037],
              [83.5712, 71.5944],
              [83.2003, 71.8748],
              [82.6457, 71.925],
              [82.3192, 72.0718],
              [82.1834, 72.2377],
              [81.283, 72.3588],
              [80.8268, 72.4882],
              [80.6559, 72.7119],
              [80.8418, 72.9492],
              [80.5095, 73.0861],
              [80.3984, 73.3568],
              [80.5834, 73.5684],
              [81.8173, 73.6587],
              [83.5449, 73.6666],
              [84.738, 73.7628],
              [85.4482, 73.7348],
              [85.6116, 73.8218],
              [86.5911, 73.8943],
              [87.0293, 73.8242],
              [86.3657, 73.6196],
              [87.1198, 73.615],
              [87.5711, 73.8108],
              [87.2099, 73.8787],
              [86.5709, 74.2439],
              [86.0014, 74.3159],
              [86.7001, 74.5227],
              [85.7909, 74.6451],
              [86.2014, 74.8162],
              [86.6513, 74.6824],
              [87.419, 74.941],
              [87.1409, 75.0724],
              [87.6713, 75.1296],
              [89.31, 75.4701],
              [90.185, 75.591],
              [91.4796, 75.6496],
              [91.8453, 75.7237],
              [93.5499, 75.854],
              [93.1781, 75.9591],
              [94.5756, 76.1518],
              [95.9198, 76.1131],
              [95.9857, 76.0096],
              [96.8792, 75.9311],
              [97.2053, 76.0188],
              [98.662, 76.2427],
              [99.5629, 76.1093],
              [99.8257, 76.1359],
              [98.8054, 76.4806],
              [100.3223, 76.4791],
              [100.8439, 76.5253],
              [101.0993, 76.7041],
              [100.9898, 76.9904],
              [101.5177, 77.1981],
              [103.1313, 77.6265],
              [104.0145, 77.7305],
              [105.8945, 77.489],
              [106.0594, 77.3906],
              [104.9119, 77.1748],
              [105.7123, 77.0014],
              [106.9418, 77.0344],
              [107.4301, 76.9265],
              [106.639, 76.5734],
              [107.6242, 76.5101],
              [108.0276, 76.7187],
              [109.3694, 76.7492],
              [109.9814, 76.7118],
              [111.1148, 76.723],
              [112.6196, 76.3836],
              [112.7984, 76.1298],
              [113.4276, 76.1122],
              [113.5639, 75.8916],
              [113.8711, 75.856],
              [113.6198, 75.5927],
              [113.6136, 75.2931],
              [112.925, 75.0151],
              [111.8685, 74.7402],
              [111.299, 74.6585],
              [109.8405, 74.3219],
              [109.8106, 74.1693],
              [109.0749, 74.0322],
              [108.1995, 73.6941],
              [107.2709, 73.6211],
              [106.6794, 73.3307],
              [106.1886, 73.308],
              [105.6774, 72.9591],
              [106.0669, 72.9499],
              [106.4781, 73.1395],
              [107.7503, 73.1731],
              [109.0898, 73.3783],
              [109.3311, 73.4873],
              [109.8553, 73.4726],
              [110.4288, 73.6288],
              [110.3885, 73.726],
              [109.7525, 73.7227],
              [109.869, 73.9308],
              [110.2614, 74.0175],
              [111.0559, 73.9393],
              [111.4005, 73.8277],
              [112.1471, 73.709],
              [112.7957, 73.7459],
              [113.4162, 73.6478],
              [113.1569, 73.4594],
              [114.0609, 73.5847],
              [114.8159, 73.6073],
              [115.338, 73.7024],
              [116.4954, 73.6761],
              [117.3085, 73.5992],
              [118.4502, 73.5897],
              [118.4303, 73.2465],
              [119.7505, 72.9791],
              [120.5978, 72.9811],
              [120.9969, 72.9367],
              [121.8863, 72.9609],
              [122.6922, 72.8908],
              [123.1607, 72.9549],
              [123.6221, 73.1933],
              [123.3053, 73.5331],
              [124.3885, 73.7549],
              [124.7963, 73.7119],
              [125.6168, 73.5207],
              [126.2544, 73.5482],
              [126.5529, 73.3348],
              [127.0313, 73.5473],
              [127.7407, 73.4816],
              [128.8885, 73.1902],
              [129.0173, 72.8725],
              [129.2501, 72.7053],
              [128.5492, 72.4957],
              [129.1165, 72.4858],
              [129.4118, 72.3155],
              [129.2836, 72.0921],
              [128.359, 72.0883],
              [129.7621, 71.1195],
              [130.2816, 70.9473],
              [131.1574, 70.7421],
              [131.5622, 70.9011],
              [131.9066, 71.2026],
              [132.2274, 71.6429],
              [132.5624, 71.8952],
              [133.4263, 71.4911],
              [134.1031, 71.3788],
              [134.7025, 71.3867],
              [135.022, 71.515],
              [135.8845, 71.6306],
              [136.4062, 71.5707],
              [137.3155, 71.3595],
              [137.6503, 71.2083],
              [138.1184, 71.5662],
              [139.0048, 71.5562],
              [139.2095, 71.4448],
              [139.9838, 71.4915],
              [139.6951, 71.7003],
              [139.6401, 71.9985],
              [139.8472, 72.1487],
              [139.1405, 72.3298],
              [139.6015, 72.4959],
              [140.4505, 72.4931],
              [141.079, 72.5869],
              [140.8083, 72.8908],
              [141.3101, 72.8578],
              [142.0616, 72.7209],
              [143.5157, 72.6983],
              [145.4857, 72.542],
              [146.253, 72.4423],
              [146.235, 72.3498],
              [144.776, 72.3823],
              [145.0389, 72.2599],
              [146.5941, 72.3026],
              [146.2307, 72.1376],
              [145.7099, 72.2063],
              [145.7568, 71.9413],
              [145.1254, 71.9271],
              [144.9897, 71.7534],
              [145.1887, 71.6959],
              [145.8049, 71.7465],
              [146.3682, 71.922],
              [147.1271, 72.2921],
              [147.4338, 72.3408],
              [148.402, 72.312],
              [149.5015, 72.1644],
              [149.9634, 71.9921],
              [149.8812, 71.8432],
              [149.0488, 71.7959],
              [148.9232, 71.7148],
              [149.857, 71.6017],
              [150.0609, 71.5107],
              [150.5997, 71.5201],
              [150.9675, 71.3806],
              [151.5823, 71.287],
              [152.5086, 70.8346],
              [153.7945, 70.88],
              [155.0294, 71.0343],
              [155.895, 71.0956],
              [157.4473, 71.0745],
              [158.037, 71.0393],
              [159.3505, 70.7909],
              [159.7279, 70.6496],
              [160.0061, 70.3096],
              [159.7293, 69.8702],
              [159.8326, 69.7849],
              [160.911, 69.6063],
              [161.0358, 69.0983],
              [161.5659, 68.9051],
              [161.4802, 69.2016],
              [161.5368, 69.3798],
              [162.3757, 69.6491],
              [163.2014, 69.7149],
              [164.1599, 69.7192],
              [164.5136, 69.6092],
              [165.7608, 69.5845],
              [166.8204, 69.4995],
              [167.6286, 69.7403],
              [168.1497, 69.5775],
              [168.3031, 69.2717],
              [169.3107, 69.0796],
              [169.61, 68.7861],
              [170.5377, 68.8254],
              [170.9951, 69.0453],
              [170.9965, 69.1345],
              [170.5824, 69.5834],
              [170.1606, 69.6265],
              [170.503, 69.8566],
              [170.4871, 70.1077],
              [171.2469, 70.0761],
              [172.5595, 69.9682],
              [173.2772, 69.8239],
              [173.439, 69.9469],
              [173.9478, 69.8742],
              [175.2956, 69.86],
              [175.7509, 69.904],
              [176.1073, 69.8603],
              [176.9242, 69.6461],
              [177.3944, 69.6116],
              [177.9337, 69.4955],
              [178.8486, 69.3872],
              [180, 68.9833],
              [180, 65.0672],
              [179.448, 64.8219],
              [179.1501, 64.7816],
              [178.5195, 64.6029],
              [178.2852, 64.6721],
              [177.7487, 64.7169],
              [177.2231, 64.8617],
              [176.2471, 64.843],
              [176.3512, 64.7053],
              [176.7313, 64.6249],
              [177.0499, 64.7191],
              [177.4669, 64.7367],
              [177.4332, 64.4445],
              [177.9531, 64.2223],
              [178.3814, 64.261],
              [178.4514, 64.0114],
              [178.65, 63.9653],
              [178.7066, 63.5216],
              [179.0279, 63.2823],
              [179.3325, 63.1903],
              [179.2595, 63.0083],
              [179.5109, 62.8629],
              [179.5702, 62.6876],
              [179.1768, 62.4691],
              [179.1207, 62.3203],
              [178.0194, 62.5471],
              [177.3513, 62.5874],
              [177.3597, 62.737],
              [176.9634, 62.6933],
              [177.1593, 62.561],
              [176.7028, 62.5058],
              [176.3288, 62.3461],
              [175.2679, 62.1024],
              [175.1924, 62.0343],
              [174.5143, 61.8238],
              [174.1389, 61.795],
              [173.8222, 61.6794],
              [173.6235, 61.7161],
              [173.1321, 61.4068],
              [172.8562, 61.4692],
              [172.3926, 61.0618],
              [172.067, 60.9157],
              [171.7295, 60.8432],
              [170.9495, 60.5228],
              [170.608, 60.4349],
              [170.3509, 59.9656],
              [169.9827, 60.0671],
              [169.6183, 60.4382],
              [169.2267, 60.596],
              [168.6705, 60.5628],
              [168.1374, 60.574],
              [167.2264, 60.4064],
              [166.9641, 60.3071],
              [166.3317, 59.8724],
              [166.1361, 59.9794],
              [166.3518, 60.4848],
              [165.2853, 60.135],
              [165.0846, 60.0985],
              [165.0191, 59.8608],
              [164.8542, 59.8407],
              [164.6697, 59.9974],
              [164.3765, 60.0581],
              [164.1348, 59.9845],
              [163.7802, 60.041],
              [163.4098, 59.8348],
              [163.2687, 59.52],
              [163.2726, 59.3027],
              [163.0042, 59.0201],
              [162.453, 58.7087],
              [162.1419, 58.4475],
              [161.9604, 58.0768],
              [162.0396, 57.9184],
              [162.4671, 57.7661],
              [162.5221, 57.9041],
              [162.7185, 57.9461],
              [163.1451, 57.8373],
              [163.2137, 57.6868],
              [162.7791, 57.3576],
              [162.8028, 56.8114],
              [162.9219, 56.7226],
              [163.2564, 56.6881],
              [163.3355, 56.2324],
              [163.0472, 56.0448],
              [162.8407, 56.0656],
              [162.6284, 56.2322],
              [162.9756, 56.4491],
              [162.6715, 56.4901],
              [162.4886, 56.3992],
              [162.5282, 56.2605],
              [162.0847, 56.0898],
              [161.7755, 55.655],
              [161.7292, 55.3579],
              [161.8243, 55.1389],
              [162.0799, 54.8861],
              [162.1059, 54.7521],
              [161.7258, 54.5331],
              [161.2938, 54.5206],
              [161.1299, 54.5983],
              [160.773, 54.5414],
              [160.0748, 54.1892],
              [159.9218, 54.0084],
              [159.8436, 53.7838],
              [159.9562, 53.5522],
              [159.8976, 53.3806],
              [160.0022, 53.2749],
              [159.5856, 53.2377],
              [158.9519, 53.0475],
              [158.6087, 52.8734],
              [158.4808, 52.6267],
              [158.4637, 52.3048],
              [158.1038, 51.8095],
              [157.8231, 51.6054],
              [157.5312, 51.48],
              [157.2021, 51.2127],
              [156.7477, 50.9693],
              [156.6708, 51.2269],
              [156.5002, 51.4751],
              [156.4897, 51.9133],
              [156.3644, 52.5094],
              [156.2286, 52.6262],
              [156.0986, 53.0064],
              [155.9048, 53.9281],
              [155.7066, 54.5214],
              [155.62, 54.8646],
              [155.555, 55.3485],
              [155.7162, 56.0723],
              [155.983, 56.6952],
              [156.0674, 56.7815],
              [156.7283, 57.1522],
              [156.9766, 57.4662],
              [156.7916, 57.7479],
              [156.9853, 57.8302],
              [157.4503, 57.7993],
              [157.6665, 58.0199],
              [158.2101, 58.0254],
              [158.6869, 58.2814],
              [159.0367, 58.4239],
              [159.5918, 58.8036],
              [159.8472, 59.1271],
              [160.3503, 59.3941],
              [160.5471, 59.5475],
              [160.8551, 59.627],
              [161.4494, 60.0273],
              [161.7534, 60.1524],
              [162.0035, 60.4202],
              [162.7132, 60.6595],
              [162.9734, 60.7828],
              [163.3522, 60.8003],
              [163.7099, 60.9168],
              [163.6194, 61.1113],
              [164.0052, 61.3439],
              [163.8044, 61.4613],
              [164.0192, 61.7109],
              [164.0742, 62.0451],
              [164.2073, 62.2923],
              [164.5984, 62.4705],
              [165.044, 62.517],
              [164.5673, 62.6755],
              [164.2557, 62.6966],
              [163.3319, 62.5509],
              [163.1636, 62.2598],
              [163.009, 61.7915],
              [163.2577, 61.6994],
              [163.0849, 61.5704],
              [162.3924, 61.6621],
              [162.1885, 61.5408],
              [161.0367, 60.9629],
              [160.7664, 60.7533],
              [160.4821, 60.7399],
              [160.2259, 60.8315],
              [160.3792, 61.0253],
              [159.9495, 61.1286],
              [159.8832, 61.2916],
              [160.1626, 61.5375],
              [160.3174, 61.7932],
              [160.1824, 61.9027],
              [159.5526, 61.7194],
              [159.2951, 61.9141],
              [159.0767, 61.9223],
              [158.5472, 61.8108],
              [158.0699, 61.7535],
              [157.4692, 61.7988],
              [157.0843, 61.6757],
              [156.6799, 61.4806],
              [156.6298, 61.2725],
              [156.056, 60.9956],
              [155.8533, 60.7773],
              [155.428, 60.5498],
              [154.9705, 60.3766],
              [154.5785, 60.095],
              [154.4406, 59.8836],
              [154.2928, 59.8333],
              [154.15, 59.5285],
              [154.5829, 59.5402],
              [155.1669, 59.3602],
              [155.1607, 59.1901],
              [154.7033, 59.1414],
              [154.4581, 59.2165],
              [154.0112, 59.0757],
              [153.6951, 59.2247],
              [153.3612, 59.2148],
              [152.8822, 58.939],
              [152.4005, 59.0263],
              [152.0881, 58.9104],
              [151.3269, 58.8751],
              [151.1213, 59.0825],
              [151.505, 59.164],
              [151.9901, 59.16],
              [152.1043, 59.2906],
              [151.7981, 59.3233],
              [151.4856, 59.5241],
              [150.9828, 59.5714],
              [150.7293, 59.4692],
              [150.4574, 59.5907],
              [149.6426, 59.7705],
              [149.2905, 59.7284],
              [149.0652, 59.6305],
              [149.2048, 59.4881],
              [148.7088, 59.4485],
              [148.9145, 59.2826],
              [148.4912, 59.2622],
              [148.2575, 59.4143],
              [147.8746, 59.3881],
              [147.5148, 59.2686],
              [147.0402, 59.3657],
              [146.4442, 59.4305],
              [146.2738, 59.2214],
              [145.9318, 59.1983],
              [145.7565, 59.3736],
              [145.5543, 59.4136],
              [144.4834, 59.3762],
              [143.8691, 59.4114],
              [143.5241, 59.3437],
              [143.1918, 59.3701],
              [142.5806, 59.2402],
              [142.0256, 58.9996],
              [141.7549, 58.7455],
              [141.347, 58.5281],
              [140.9876, 58.417],
              [140.6847, 58.2122],
              [140.4469, 57.8136],
              [140.0022, 57.6875],
              [139.8617, 57.5493],
              [139.4437, 57.3298],
              [139.1813, 57.2616],
              [138.9656, 57.0881],
              [138.662, 56.9656],
              [138.2176, 56.629],
              [138.0739, 56.433],
              [137.6912, 56.1394],
              [136.7934, 55.6943],
              [136.4603, 55.5767],
              [136.175, 55.3522],
              [135.7506, 55.1606],
              [135.5406, 55.1138],
              [135.2624, 54.9433],
              [135.2579, 54.7315],
              [135.8512, 54.5838],
              [136.7973, 54.6209],
              [136.6826, 53.9312],
              [136.8865, 53.8394],
              [137.1556, 53.8216],
              [137.258, 54.0253],
              [137.0961, 54.1286],
              [137.3775, 54.2824],
              [137.5132, 54.1564],
              [137.6445, 53.8657],
              [137.3282, 53.539],
              [137.7382, 53.5603],
              [138.2529, 53.7264],
              [138.379, 53.9093],
              [138.5692, 53.8188],
              [138.2924, 53.5924],
              [138.4511, 53.537],
              [138.6607, 53.7449],
              [138.7218, 54.0437],
              [138.6958, 54.3199],
              [139.3194, 54.1929],
              [139.7074, 54.277],
              [140.1789, 54.0516],
              [140.3472, 53.8126],
              [140.6874, 53.5963],
              [141.006, 53.4946],
              [141.4024, 53.1839],
              [141.0864, 52.8974],
              [141.2556, 52.8403],
              [141.245, 52.5503],
              [141.1695, 52.3683],
              [141.4854, 52.1787],
              [141.3668, 51.9207],
              [140.9326, 51.6199],
              [140.6874, 51.2323],
              [140.6706, 51.0513],
              [140.5208, 50.8001],
              [140.4765, 50.5459],
              [140.5354, 50.1308],
              [140.4624, 49.9115],
              [140.5173, 49.596],
              [140.309, 49.0537],
              [140.378, 48.964],
              [140.2241, 48.7729],
              [140.1705, 48.5238],
              [139.9983, 48.3236],
              [139.3726, 47.8872],
              [139.0012, 47.3834],
              [138.5868, 47.0573],
              [138.3916, 46.7451],
              [138.3368, 46.5431],
              [138.2103, 46.463],
              [138.1065, 46.2507],
              [137.6855, 45.8182],
              [137.1472, 45.3935],
              [136.8035, 45.1712],
              [136.4603, 44.8222],
              [136.2516, 44.6669],
              [136.1422, 44.4891],
              [135.8745, 44.3736],
              [135.5331, 43.9714],
              [135.4834, 43.8348],
              [135.2601, 43.6845],
              [135.131, 43.5254],
              [134.6919, 43.2907],
              [133.7093, 42.8302],
              [133.5867, 42.8284],
              [133.1601, 42.697],
              [132.9966, 42.8078],
              [132.7091, 42.8759],
              [132.304, 42.8833],
              [132.3343, 43.2389],
              [132.0284, 43.1189],
              [131.7216, 43.2024],
              [131.3934, 42.8223],
              [131.1588, 42.6259],
              [130.7561, 42.6733],
              [130.8339, 42.523],
              [130.6872, 42.3029]
            ]
          ],
          [
            [
              [100.263, 78.6316],
              [100.8756, 78.7836],
              [100.9015, 78.98],
              [101.5905, 79.3503],
              [102.7898, 79.392],
              [102.9507, 79.0559],
              [103.6727, 79.1499],
              [104.4523, 78.88],
              [105.1461, 78.8188],
              [105.3123, 78.4999],
              [104.8328, 78.3527],
              [103.7192, 78.2583],
              [101.2044, 78.1921],
              [100.082, 77.975],
              [99.287, 78.0381],
              [100.0191, 78.3389],
              [100.263, 78.6316]
            ]
          ],
          [
            [
              [93.6035, 79.8167],
              [92.9263, 79.7045],
              [92.1537, 79.6845],
              [91.1263, 79.9051],
              [91.4256, 80.0492],
              [92.1731, 80.0455],
              [93.8035, 79.9046],
              [93.6035, 79.8167]
            ]
          ],
          [
            [
              [97.5872, 80.1683],
              [99.2953, 80.0165],
              [100.0612, 79.7772],
              [99.7818, 79.6282],
              [99.6807, 79.3232],
              [99.1673, 79.3064],
              [99.9294, 78.9616],
              [99.4394, 78.8342],
              [98.4107, 78.7878],
              [97.5555, 78.8264],
              [96.3469, 79.0158],
              [95.7964, 79.0014],
              [94.6525, 79.1275],
              [94.2187, 79.4024],
              [93.0709, 79.4953],
              [94.2569, 79.8297],
              [94.9869, 80.0967],
              [96.4165, 80.1044],
              [97.5872, 80.1683]
            ]
          ],
          [[[89.9758, 81.1131], [90.0699, 81.2138], [91.4779, 81.1839], [91.223, 81.0637], [89.9758, 81.1131]]],
          [
            [
              [91.6374, 80.2698],
              [91.8967, 80.4777],
              [92.5782, 80.5333],
              [93.2625, 80.7912],
              [93.0652, 80.9886],
              [94.6116, 81.1147],
              [95.1596, 81.2711],
              [95.8008, 81.2804],
              [96.7553, 80.9578],
              [97.8315, 80.7983],
              [97.0255, 80.5355],
              [97.1749, 80.2411],
              [94.565, 80.1262],
              [93.8724, 80.0101],
              [92.2016, 80.1793],
              [91.6374, 80.2698]
            ]
          ]
        ]
      },
      properties: { name: "Russia - Asia", id: "RU", Continent: "Asia" },
      id: "RU"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [53.7631, 12.6372],
              [54.1872, 12.6639],
              [54.5111, 12.5528],
              [54.1296, 12.3607],
              [53.7187, 12.3189],
              [53.5983, 12.3422],
              [53.3157, 12.533],
              [53.4309, 12.6635],
              [53.6388, 12.7075],
              [53.7631, 12.6372]
            ]
          ],
          [
            [
              [53.0855, 16.6485],
              [52.5814, 16.4706],
              [52.3279, 16.2938],
              [52.2373, 16.1712],
              [52.1741, 15.9568],
              [52.2176, 15.6553],
              [51.6036, 15.3367],
              [51.3224, 15.2259],
              [50.527, 15.0383],
              [50.1671, 14.8511],
              [49.9064, 14.8278],
              [49.3497, 14.638],
              [49.0482, 14.4565],
              [49.0047, 14.3553],
              [48.6685, 14.0504],
              [48.2779, 13.9977],
              [47.99, 14.0482],
              [47.6337, 13.8584],
              [47.4078, 13.6616],
              [46.9758, 13.5473],
              [46.789, 13.4655],
              [46.502, 13.4153],
              [46.2027, 13.4237],
              [45.6574, 13.3385],
              [45.3937, 13.0669],
              [45.1639, 12.9984],
              [45.0387, 12.816],
              [44.7552, 12.7638],
              [44.6176, 12.8174],
              [44.3583, 12.6692],
              [43.9299, 12.6165],
              [43.4751, 12.8388],
              [43.232, 13.2673],
              [43.2826, 13.6395],
              [43.2343, 13.8588],
              [43.0893, 14.0109],
              [43.0936, 14.2038],
              [43.0211, 14.5549],
              [42.913, 14.8629],
              [42.8554, 15.1327],
              [42.6577, 15.233],
              [42.8001, 15.3719],
              [42.717, 15.6544],
              [42.8396, 16.0323],
              [42.7992, 16.3721],
              [43.1649, 16.6893],
              [43.1165, 16.942],
              [43.1912, 17.3595],
              [43.418, 17.5164],
              [43.5972, 17.4715],
              [43.7133, 17.3656],
              [43.9167, 17.3248],
              [44.1558, 17.3987],
              [44.7469, 17.432],
              [45.148, 17.4277],
              [45.4065, 17.32],
              [46.3105, 17.2311],
              [46.6818, 17.2685],
              [46.7789, 17.2122],
              [46.9758, 16.9534],
              [47.1436, 16.9468],
              [47.442, 17.1116],
              [47.5796, 17.4482],
              [48.172, 18.1567],
              [49.0421, 18.582],
              [49.1923, 18.6217],
              [50.3552, 18.7776],
              [51.9776, 18.9959],
              [52.2874, 18.2968],
              [52.7291, 17.3006],
              [52.8007, 17.2681],
              [53.0855, 16.6485]
            ]
          ]
        ]
      },
      properties: { name: "Yemen", id: "YE", Continent: "Middle East" },
      id: "YE"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[34.2452, 31.2084], [34.1982, 31.3226], [34.4777, 31.5851], [34.5256, 31.5253], [34.2452, 31.2084]]],
          [
            [
              [35.4506, 31.4791],
              [35.1013, 31.3662],
              [34.8728, 31.3965],
              [34.9505, 31.6022],
              [35.198, 31.7762],
              [34.9897, 31.9133],
              [34.9559, 32.1611],
              [35.0652, 32.4603],
              [35.1932, 32.5342],
              [35.3624, 32.5075],
              [35.5513, 32.3953],
              [35.5316, 31.9849],
              [35.5588, 31.7657],
              [35.4506, 31.4791]
            ]
          ]
        ]
      },
      properties: { name: "Palestinian Territories", id: "PS", Continent: "Middle East" },
      id: "PS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [107.9728, 21.508],
            [107.8089, 21.497],
            [107.7074, 21.406],
            [107.4098, 21.2847],
            [107.3544, 21.0553],
            [107.1651, 20.9489],
            [107.0753, 20.9995],
            [106.886, 20.9502],
            [106.6755, 20.9604],
            [106.7533, 20.7349],
            [106.5511, 20.5267],
            [106.5182, 20.2889],
            [106.1657, 19.9922],
            [105.9841, 19.9391],
            [105.8142, 19.5875],
            [105.7909, 19.294],
            [105.6217, 18.9665],
            [105.8884, 18.5025],
            [106.066, 18.3166],
            [106.2395, 18.2208],
            [106.4988, 17.9462],
            [106.479, 17.7194],
            [106.7357, 17.367],
            [107.1202, 17.0553],
            [107.1804, 16.8981],
            [107.3549, 16.7935],
            [107.724, 16.4877],
            [107.8339, 16.3224],
            [108.0294, 16.3312],
            [108.2677, 16.09],
            [108.2861, 15.9892],
            [108.6741, 15.4834],
            [108.8214, 15.378],
            [108.94, 15.0013],
            [109.0845, 14.7162],
            [109.0868, 14.5527],
            [109.1913, 14.2706],
            [109.3034, 13.8561],
            [109.2471, 13.8545],
            [109.2722, 13.2792],
            [109.4239, 12.9562],
            [109.3354, 12.7518],
            [109.2186, 12.6459],
            [109.2155, 12.0728],
            [109.1575, 11.8368],
            [109.1988, 11.7248],
            [109.0397, 11.593],
            [108.9865, 11.3363],
            [108.8205, 11.3153],
            [108.7005, 11.1992],
            [108.5511, 11.1561],
            [108.2716, 10.9342],
            [108.0949, 10.8973],
            [108.0018, 10.7203],
            [107.8453, 10.7],
            [107.4705, 10.4859],
            [107.2618, 10.3986],
            [107.0876, 10.4983],
            [106.813, 10.4332],
            [106.7572, 10.2957],
            [106.7854, 10.1165],
            [106.657, 9.901],
            [106.5077, 9.8212],
            [106.5722, 9.641],
            [106.3779, 9.5562],
            [106.2039, 9.6752],
            [106.1684, 9.3966],
            [105.5013, 9.0933],
            [105.3224, 8.8011],
            [105.1146, 8.6289],
            [104.8922, 8.5832],
            [104.8961, 8.7466],
            [104.8184, 8.8016],
            [104.8145, 9.1856],
            [104.8451, 9.6062],
            [104.9032, 9.8163],
            [105.0921, 9.9007],
            [105.0279, 10.0672],
            [104.8021, 10.2025],
            [104.6637, 10.1696],
            [104.4264, 10.4113],
            [104.5639, 10.5158],
            [104.8505, 10.5347],
            [105.0614, 10.7338],
            [105.0222, 10.8868],
            [105.3149, 10.8455],
            [105.3866, 10.9399],
            [105.7552, 10.9901],
            [106.0988, 10.7976],
            [106.1679, 11.0125],
            [105.8559, 11.2941],
            [105.8514, 11.6352],
            [106.0058, 11.7582],
            [106.3401, 11.6818],
            [106.4105, 11.7385],
            [106.4135, 11.9485],
            [106.7001, 11.9798],
            [106.9303, 12.0777],
            [107.1589, 12.2767],
            [107.506, 12.3643],
            [107.5553, 12.5401],
            [107.4757, 13.0299],
            [107.6057, 13.4379],
            [107.3311, 14.1264],
            [107.3645, 14.3685],
            [107.4485, 14.4513],
            [107.5197, 14.7051],
            [107.4805, 14.9799],
            [107.6533, 15.2549],
            [107.5644, 15.3917],
            [107.3391, 15.5604],
            [107.1896, 15.7472],
            [107.1888, 15.8385],
            [107.3606, 15.9217],
            [107.3966, 16.0428],
            [107.2173, 16.1365],
            [106.9303, 16.3531],
            [106.8324, 16.5263],
            [106.6961, 16.4585],
            [106.5462, 16.6507],
            [106.5024, 16.9543],
            [106.2694, 17.2167],
            [106.0062, 17.4153],
            [105.6915, 17.7378],
            [105.5887, 17.9834],
            [105.4582, 18.1544],
            [105.3333, 18.1896],
            [105.0856, 18.4502],
            [105.115, 18.6787],
            [104.7164, 18.8035],
            [103.8964, 19.34],
            [104.0629, 19.4825],
            [104.0317, 19.6754],
            [104.2603, 19.6855],
            [104.5463, 19.6104],
            [104.7436, 19.7546],
            [104.9283, 20.0182],
            [104.8886, 20.1689],
            [104.6989, 20.2054],
            [104.6188, 20.3746],
            [104.3921, 20.4246],
            [104.5833, 20.6467],
            [104.1952, 20.9137],
            [104.0519, 20.9411],
            [103.6349, 20.6971],
            [103.1044, 20.8914],
            [102.8509, 21.2658],
            [102.9489, 21.5698],
            [102.8452, 21.7351],
            [102.6953, 21.6622],
            [102.5824, 21.9043],
            [102.4875, 21.9579],
            [102.1275, 22.3789],
            [102.237, 22.466],
            [102.4281, 22.7326],
            [102.5174, 22.7406],
            [102.8303, 22.5873],
            [103.0052, 22.4532],
            [103.301, 22.7643],
            [103.4714, 22.5973],
            [103.6204, 22.7819],
            [103.9153, 22.5385],
            [104.0542, 22.7524],
            [104.2123, 22.8096],
            [104.3719, 22.7041],
            [104.527, 22.8044],
            [104.6874, 22.8219],
            [104.796, 22.9111],
            [104.8645, 23.1362],
            [105.2754, 23.3453],
            [105.4947, 23.1805],
            [105.5483, 23.0728],
            [105.8432, 22.923],
            [106.001, 22.9748],
            [106.1837, 22.9551],
            [106.2496, 22.8694],
            [106.5419, 22.908],
            [106.7805, 22.7789],
            [106.5823, 22.5736],
            [106.5362, 22.3956],
            [106.6543, 22.2414],
            [106.7296, 22.0006],
            [107.0064, 21.8934],
            [107.1786, 21.7171],
            [107.3514, 21.609],
            [107.8018, 21.6455],
            [107.9728, 21.508]
          ]
        ]
      },
      properties: { name: "Vietnam", id: "VN", Continent: "Asia" },
      id: "VN"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [70.9466, 42.2484],
              [71.2287, 42.1626],
              [70.842, 42.0194],
              [70.4549, 41.725],
              [70.1811, 41.5716],
              [70.4715, 41.4125],
              [70.646, 41.46],
              [70.7827, 41.2627],
              [71.1105, 41.1524],
              [71.4085, 41.1361],
              [71.4208, 41.3418],
              [71.7926, 41.4129],
              [71.8787, 41.195],
              [72.1644, 41.1739],
              [72.1876, 41.0263],
              [72.3643, 41.0434],
              [72.6583, 40.8698],
              [72.9901, 40.8602],
              [73.1131, 40.7858],
              [72.7738, 40.6505],
              [72.5673, 40.5244],
              [72.4021, 40.578],
              [72.3577, 40.4013],
              [72.131, 40.4387],
              [71.9556, 40.2585],
              [71.6924, 40.1526],
              [71.3048, 40.2871],
              [70.9581, 40.2392],
              [70.6531, 40.2014],
              [70.3828, 40.4537],
              [70.7119, 40.669],
              [70.751, 40.7397],
              [70.4417, 41.0232],
              [70.2923, 40.8913],
              [69.7126, 40.6571],
              [69.4142, 40.7969],
              [69.2064, 40.5666],
              [69.3044, 40.3275],
              [69.2279, 40.1873],
              [68.9515, 40.2225],
              [68.6527, 40.1825],
              [68.8047, 40.0502],
              [68.7353, 39.8362],
              [68.5863, 39.6349],
              [68.3987, 39.5286],
              [67.7193, 39.6218],
              [67.4262, 39.4657],
              [67.3497, 39.2421],
              [67.6165, 39.1502],
              [67.6767, 39.0087],
              [68.1038, 38.9622],
              [68.1486, 38.8905],
              [68.0476, 38.6695],
              [68.1443, 38.383],
              [68.3332, 38.2379],
              [68.2941, 38.0327],
              [68.0875, 37.8353],
              [67.8142, 37.4869],
              [67.7592, 37.1722],
              [67.5172, 37.2667],
              [67.3198, 37.2096],
              [67.0689, 37.3349],
              [66.8276, 37.3713],
              [66.5222, 37.3485],
              [66.5257, 37.7857],
              [66.5745, 38.0107],
              [65.8573, 38.2687],
              [65.613, 38.2384],
              [65.0768, 38.5394],
              [64.6598, 38.7358],
              [64.31, 38.9775],
              [64.1628, 38.9533],
              [63.7207, 39.1884],
              [63.5058, 39.377],
              [62.6506, 39.8586],
              [62.4414, 40.0361],
              [62.3746, 40.3324],
              [62.0952, 40.6834],
              [61.9031, 41.0935],
              [61.4966, 41.2759],
              [61.3292, 41.195],
              [60.7552, 41.246],
              [60.4551, 41.2218],
              [60.0895, 41.3994],
              [60.1383, 41.594],
              [60.0754, 41.7008],
              [60.1071, 41.9078],
              [59.9625, 41.9544],
              [60.0064, 42.1908],
              [59.8583, 42.295],
              [59.4509, 42.2998],
              [59.2765, 42.3565],
              [59.1231, 42.5239],
              [58.8771, 42.5612],
              [58.5887, 42.7783],
              [58.4771, 42.6628],
              [58.2596, 42.6878],
              [58.2341, 42.4479],
              [58.0289, 42.4879],
              [57.8144, 42.1899],
              [57.382, 42.1561],
              [57.2287, 42.0844],
              [56.9641, 41.8564],
              [57.0182, 41.2636],
              [55.9775, 41.322],
              [55.9771, 42.4699],
              [55.9766, 43.6177],
              [55.9757, 44.995],
              [57.4774, 45.3375],
              [58.5549, 45.5555],
              [59.774, 44.9811],
              [61.008, 44.3938],
              [61.1609, 44.1688],
              [61.9906, 43.4924],
              [62.0719, 43.4894],
              [63.207, 43.6282],
              [64.4432, 43.5514],
              [64.9054, 43.7148],
              [65.2702, 43.4177],
              [65.4961, 43.3109],
              [65.8028, 42.8772],
              [66.1003, 42.9906],
              [66.0094, 42.0049],
              [66.4985, 41.9952],
              [66.5381, 41.7412],
              [66.6685, 41.2706],
              [66.814, 41.1423],
              [67.9359, 41.1963],
              [68.113, 41.0289],
              [68.0476, 40.8096],
              [68.2919, 40.6562],
              [68.6008, 40.6597],
              [68.5841, 40.8764],
              [68.7371, 41.042],
              [68.9871, 41.2051],
              [69.0653, 41.3669],
              [69.3685, 41.4903],
              [69.6638, 41.6718],
              [70.0954, 41.8208],
              [70.4158, 42.0787],
              [70.6135, 42.0545],
              [70.715, 42.1688],
              [70.9466, 42.2484]
            ]
          ],
          [[[71.2059, 39.8925], [71.0117, 39.8951], [71.0806, 40.0797], [71.2287, 40.048], [71.2059, 39.8925]]]
        ]
      },
      properties: { name: "Uzbekistan", id: "UZ", Continent: "Asia" },
      id: "UZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [121.0092, 22.6206],
            [120.8975, 22.3789],
            [120.84, 21.925],
            [120.69, 22.0331],
            [120.5816, 22.3565],
            [120.3258, 22.5424],
            [120.2325, 22.7177],
            [120.0722, 23.1497],
            [120.1429, 23.3989],
            [120.1588, 23.7088],
            [120.6299, 24.4787],
            [120.8359, 24.7229],
            [121.0403, 25.0328],
            [121.5937, 25.2754],
            [121.8529, 25.1044],
            [121.9293, 24.9735],
            [121.8199, 24.8244],
            [121.8279, 24.5345],
            [121.6393, 24.1302],
            [121.4771, 23.424],
            [121.2956, 22.967],
            [121.0092, 22.6206]
          ]
        ]
      },
      properties: { name: "Taiwan", id: "TW", Continent: "Asia" },
      id: "TW"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [124.0365, -9.3417],
              [124.4444, -9.1901],
              [124.4132, -9.3144],
              [124.2821, -9.4282],
              [124.0901, -9.4163],
              [124.0365, -9.3417]
            ]
          ],
          [
            [
              [125.0679, -9.5123],
              [124.9686, -9.2947],
              [124.9735, -9.0644],
              [124.9224, -8.9427],
              [125.1782, -8.6478],
              [125.3817, -8.5753],
              [125.8044, -8.4923],
              [126.6197, -8.4597],
              [126.9663, -8.3155],
              [127.2963, -8.4245],
              [126.915, -8.7151],
              [126.6653, -8.7823],
              [126.3823, -8.9577],
              [126.1643, -8.9967],
              [125.9463, -9.1242],
              [125.8405, -9.1303],
              [125.4081, -9.2758],
              [125.0679, -9.5123]
            ]
          ]
        ]
      },
      properties: { name: "Timor-Leste", id: "TL", Continent: "Asia" },
      id: "TL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.5222, 37.3485],
            [66.1082, 37.4148],
            [65.765, 37.5691],
            [65.6415, 37.468],
            [65.555, 37.2513],
            [65.0896, 37.2377],
            [64.8163, 37.1323],
            [64.5108, 36.3408],
            [64.3583, 36.2261],
            [63.8622, 36.012],
            [63.1086, 35.8188],
            [63.1696, 35.6781],
            [63.0567, 35.446],
            [62.6884, 35.2554],
            [62.2529, 35.2501],
            [61.984, 35.4439],
            [61.7195, 35.4192],
            [61.5427, 35.4579],
            [61.3446, 35.6297],
            [61.2619, 35.6197],
            [61.2523, 35.8675],
            [61.1529, 35.9769],
            [61.2123, 36.0991],
            [61.1196, 36.6427],
            [60.3413, 36.6379],
            [60.0627, 36.9631],
            [59.9484, 37.0417],
            [59.5621, 37.1788],
            [59.3675, 37.334],
            [59.2409, 37.5207],
            [58.8155, 37.6833],
            [58.4358, 37.6385],
            [58.2618, 37.6658],
            [58.1089, 37.7831],
            [57.7103, 37.9057],
            [57.4238, 37.9479],
            [57.1939, 38.2164],
            [56.4407, 38.2493],
            [56.2289, 38.0731],
            [55.5785, 38.1],
            [55.3808, 38.0512],
            [55.0753, 37.9022],
            [54.8486, 37.7224],
            [54.7453, 37.5018],
            [54.1916, 37.3326],
            [53.9143, 37.3437],
            [53.8238, 37.9281],
            [53.8686, 38.949],
            [53.6177, 39.2157],
            [53.125, 39.4319],
            [53.2354, 39.6086],
            [53.3896, 39.5365],
            [53.4722, 39.6688],
            [53.4871, 39.9091],
            [53.4041, 39.9601],
            [52.9875, 39.9878],
            [52.8983, 39.9122],
            [52.7445, 40.2198],
            [52.7335, 40.3987],
            [52.85, 40.6857],
            [52.9436, 41.0381],
            [53.1453, 40.8249],
            [53.3329, 40.7828],
            [53.615, 40.8184],
            [53.8699, 40.6488],
            [54.1929, 40.7208],
            [54.3195, 40.8346],
            [54.7101, 40.8909],
            [54.7036, 41.0715],
            [54.592, 41.1932],
            [54.2843, 41.3638],
            [54.0949, 41.5193],
            [53.8462, 42.0915],
            [53.6247, 42.1363],
            [53.1641, 42.0936],
            [52.9699, 41.9763],
            [52.8148, 41.7118],
            [52.8834, 41.6525],
            [52.8301, 41.3418],
            [52.6095, 41.5294],
            [52.494, 41.7803],
            [52.8706, 42.0607],
            [53.2503, 42.2057],
            [53.6854, 42.2967],
            [54.1213, 42.3349],
            [54.6781, 42.0779],
            [54.9035, 41.9192],
            [54.9527, 41.8098],
            [55.2493, 41.4578],
            [55.5451, 41.2627],
            [55.9775, 41.322],
            [57.0182, 41.2636],
            [56.9641, 41.8564],
            [57.2287, 42.0844],
            [57.382, 42.1561],
            [57.8144, 42.1899],
            [58.0289, 42.4879],
            [58.2341, 42.4479],
            [58.2596, 42.6878],
            [58.4771, 42.6628],
            [58.5887, 42.7783],
            [58.8771, 42.5612],
            [59.1231, 42.5239],
            [59.2765, 42.3565],
            [59.4509, 42.2998],
            [59.8583, 42.295],
            [60.0064, 42.1908],
            [59.9625, 41.9544],
            [60.1071, 41.9078],
            [60.0754, 41.7008],
            [60.1383, 41.594],
            [60.0895, 41.3994],
            [60.4551, 41.2218],
            [60.7552, 41.246],
            [61.3292, 41.195],
            [61.4966, 41.2759],
            [61.9031, 41.0935],
            [62.0952, 40.6834],
            [62.3746, 40.3324],
            [62.4414, 40.0361],
            [62.6506, 39.8586],
            [63.5058, 39.377],
            [63.7207, 39.1884],
            [64.1628, 38.9533],
            [64.31, 38.9775],
            [64.6598, 38.7358],
            [65.0768, 38.5394],
            [65.613, 38.2384],
            [65.8573, 38.2687],
            [66.5745, 38.0107],
            [66.5257, 37.7857],
            [66.5222, 37.3485]
          ]
        ]
      },
      properties: { name: "Turkmenistan", id: "TM", Continent: "Asia" },
      id: "TM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.9581, 40.2392],
            [70.9462, 40.1873],
            [70.4514, 40.0489],
            [69.9667, 40.2023],
            [69.5303, 40.0972],
            [69.4318, 39.9096],
            [69.307, 39.9685],
            [69.2288, 39.761],
            [69.2978, 39.5246],
            [69.5988, 39.5738],
            [70.2448, 39.5427],
            [70.6078, 39.5642],
            [70.7335, 39.4135],
            [71.005, 39.4121],
            [71.0648, 39.4934],
            [71.4046, 39.598],
            [71.5461, 39.453],
            [71.7319, 39.4231],
            [71.8062, 39.2759],
            [72.0426, 39.3519],
            [72.2303, 39.2073],
            [72.3581, 39.337],
            [72.6398, 39.3858],
            [73.2353, 39.3744],
            [73.4704, 39.4605],
            [73.6317, 39.449],
            [73.6075, 39.2293],
            [73.7956, 39.0026],
            [73.6958, 38.8544],
            [73.8013, 38.607],
            [74.0259, 38.5398],
            [74.1314, 38.6611],
            [74.2772, 38.6598],
            [74.7452, 38.51],
            [74.8358, 38.4045],
            [74.7896, 38.1034],
            [74.9382, 37.7721],
            [74.8943, 37.6016],
            [75.1188, 37.3858],
            [74.8912, 37.2316],
            [74.6596, 37.3946],
            [74.2596, 37.4153],
            [74.1674, 37.3291],
            [73.7495, 37.2316],
            [73.7209, 37.4183],
            [73.4813, 37.4719],
            [73.2111, 37.4082],
            [72.8956, 37.2676],
            [72.6574, 37.0294],
            [72.359, 36.9833],
            [72.1533, 36.9007],
            [71.8023, 36.6941],
            [71.5975, 36.7328],
            [71.4331, 37.1274],
            [71.5047, 37.6029],
            [71.5803, 37.8644],
            [71.3202, 37.9017],
            [71.3325, 38.1707],
            [71.2555, 38.3069],
            [70.8789, 38.4563],
            [70.6157, 38.3342],
            [70.4175, 38.0753],
            [70.1991, 37.8859],
            [70.2549, 37.7651],
            [70.1885, 37.5822],
            [69.6256, 37.5937],
            [69.492, 37.5532],
            [69.3993, 37.3994],
            [69.4147, 37.2079],
            [69.2649, 37.1081],
            [68.9603, 37.3247],
            [68.6694, 37.2584],
            [68.3868, 37.1375],
            [68.0678, 36.9499],
            [67.9579, 36.9719],
            [67.7592, 37.1722],
            [67.8142, 37.4869],
            [68.0875, 37.8353],
            [68.2941, 38.0327],
            [68.3332, 38.2379],
            [68.1443, 38.383],
            [68.0476, 38.6695],
            [68.1486, 38.8905],
            [68.1038, 38.9622],
            [67.6767, 39.0087],
            [67.6165, 39.1502],
            [67.3497, 39.2421],
            [67.4262, 39.4657],
            [67.7193, 39.6218],
            [68.3987, 39.5286],
            [68.5863, 39.6349],
            [68.7353, 39.8362],
            [68.8047, 40.0502],
            [68.6527, 40.1825],
            [68.9515, 40.2225],
            [69.2279, 40.1873],
            [69.3044, 40.3275],
            [69.2064, 40.5666],
            [69.4142, 40.7969],
            [69.7126, 40.6571],
            [70.2923, 40.8913],
            [70.4417, 41.0232],
            [70.751, 40.7397],
            [70.7119, 40.669],
            [70.3828, 40.4537],
            [70.6531, 40.2014],
            [70.9581, 40.2392]
          ]
        ]
      },
      properties: { name: "Tajikistan", id: "TJ", Continent: "Asia" },
      id: "TJ"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[98.4089, 7.902], [98.2622, 7.9257], [98.3012, 8.1362], [98.4348, 8.0858], [98.4089, 7.902]]],
          [
            [
              [100.1223, 20.3165],
              [100.318, 20.386],
              [100.5191, 20.1776],
              [100.5429, 20.0885],
              [100.3978, 19.7562],
              [100.4204, 19.6447],
              [100.6256, 19.5001],
              [100.9665, 19.6107],
              [101.1547, 19.5797],
              [101.2206, 19.4864],
              [101.1977, 19.3277],
              [101.2865, 18.9772],
              [101.1656, 18.6185],
              [101.0505, 18.4071],
              [101.1375, 18.2867],
              [101.1134, 18.0331],
              [100.9086, 17.5836],
              [101.1054, 17.4794],
              [101.4139, 17.7185],
              [101.6876, 17.8895],
              [101.7747, 18.0331],
              [101.9474, 18.081],
              [102.1013, 18.2103],
              [102.4584, 17.9843],
              [102.7172, 17.8922],
              [103.0513, 18.0283],
              [103.0912, 18.1382],
              [103.2631, 18.2785],
              [103.2522, 18.3733],
              [103.4882, 18.4181],
              [103.7922, 18.3166],
              [103.9495, 18.3193],
              [104.0489, 18.2165],
              [104.4282, 17.6992],
              [104.7397, 17.4614],
              [104.8161, 17.3006],
              [104.7591, 17.0774],
              [104.7507, 16.6476],
              [104.8193, 16.466],
              [104.9497, 16.3399],
              [105.0469, 16.1602],
              [105.3308, 16.038],
              [105.3989, 15.8298],
              [105.6225, 15.6996],
              [105.6156, 15.4879],
              [105.5132, 15.3609],
              [105.4907, 15.1275],
              [105.547, 14.9324],
              [105.4753, 14.5302],
              [105.183, 14.3462],
              [105.074, 14.2276],
              [104.97, 14.366],
              [104.7787, 14.4278],
              [104.4118, 14.3694],
              [104.2277, 14.3959],
              [103.8182, 14.3624],
              [103.6002, 14.4209],
              [103.1995, 14.3325],
              [102.9094, 14.137],
              [102.7293, 13.8422],
              [102.5446, 13.6602],
              [102.5473, 13.5854],
              [102.3195, 13.5398],
              [102.3311, 13.288],
              [102.462, 13.0151],
              [102.4994, 12.6696],
              [102.7555, 12.4263],
              [102.7063, 12.2557],
              [102.7366, 12.0895],
              [102.934, 11.7064],
              [102.7916, 11.8883],
              [102.7626, 12.0122],
              [102.5943, 12.203],
              [102.5402, 12.1089],
              [102.2295, 12.3317],
              [102.0344, 12.5317],
              [101.7237, 12.6894],
              [101.4447, 12.6187],
              [101.09, 12.6735],
              [100.9538, 12.6213],
              [100.8633, 12.7145],
              [100.9458, 13.1873],
              [100.9626, 13.4322],
              [100.6559, 13.5213],
              [100.2357, 13.4844],
              [100.0173, 13.3535],
              [99.9905, 13.2431],
              [100.0902, 13.0459],
              [99.9641, 12.6903],
              [100.0058, 12.3545],
              [99.9887, 12.1709],
              [99.8371, 11.9367],
              [99.7985, 11.7486],
              [99.6271, 11.4629],
              [99.5142, 11.1007],
              [99.4868, 10.8898],
              [99.2848, 10.5691],
              [99.1652, 10.3199],
              [99.1947, 10.1753],
              [99.1609, 9.7336],
              [99.2882, 9.4146],
              [99.254, 9.2652],
              [99.3937, 9.2139],
              [99.7237, 9.3145],
              [99.8354, 9.288],
              [99.9044, 9.1127],
              [99.9607, 8.6711],
              [100.1294, 8.4281],
              [100.2286, 8.4249],
              [100.2792, 8.2685],
              [100.4537, 7.4423],
              [100.2836, 7.5517],
              [100.3175, 7.7161],
              [100.1579, 7.728],
              [100.2049, 7.5008],
              [100.4234, 7.188],
              [100.5864, 7.1761],
              [100.7925, 6.9946],
              [101.0179, 6.861],
              [101.3018, 6.9084],
              [101.4979, 6.8653],
              [101.6144, 6.7542],
              [101.7993, 6.4742],
              [102.1008, 6.2423],
              [102.055, 6.0968],
              [101.8735, 5.8257],
              [101.65, 5.7962],
              [101.5563, 5.9078],
              [101.2571, 5.7896],
              [101.1141, 5.6366],
              [100.9819, 5.7712],
              [101.0755, 5.9566],
              [101.0536, 6.2426],
              [100.8738, 6.2453],
              [100.7939, 6.4259],
              [100.3451, 6.5499],
              [100.1768, 6.672],
              [100.1189, 6.4421],
              [99.8688, 6.7502],
              [99.6961, 6.8763],
              [99.7203, 7.1061],
              [99.5529, 7.2187],
              [99.5967, 7.3554],
              [99.3586, 7.3725],
              [99.3005, 7.5615],
              [99.1833, 7.7184],
              [99.0426, 7.7658],
              [99.051, 7.8876],
              [98.7886, 8.0598],
              [98.7033, 8.2566],
              [98.579, 8.3446],
              [98.4212, 8.1779],
              [98.3056, 8.2263],
              [98.238, 8.4233],
              [98.2419, 8.7681],
              [98.3258, 8.969],
              [98.3716, 9.2903],
              [98.4928, 9.5619],
              [98.5619, 9.8374],
              [98.7025, 10.1902],
              [98.7684, 10.4307],
              [98.757, 10.6613],
              [99.1903, 11.1052],
              [99.4781, 11.6124],
              [99.6147, 11.7811],
              [99.4627, 12.1903],
              [99.4052, 12.5474],
              [99.2197, 12.7399],
              [99.1071, 13.1034],
              [99.1762, 13.2331],
              [99.1367, 13.7164],
              [98.9336, 14.05],
              [98.5703, 14.3603],
              [98.2463, 14.8146],
              [98.191, 15.204],
              [98.5653, 15.4039],
              [98.5584, 15.7687],
              [98.5926, 16.0503],
              [98.818, 16.1809],
              [98.6893, 16.3053],
              [98.4784, 16.7324],
              [98.4388, 16.9755],
              [98.2565, 17.1477],
              [97.7929, 17.6812],
              [97.6983, 17.8336],
              [97.7199, 18.0372],
              [97.5995, 18.3026],
              [97.4509, 18.3597],
              [97.4848, 18.4942],
              [97.7537, 18.6212],
              [97.7058, 18.9318],
              [97.8043, 19.1305],
              [97.817, 19.46],
              [98.1115, 19.7624],
              [98.2389, 19.6908],
              [98.4937, 19.7013],
              [98.9169, 19.773],
              [99.0207, 20.0419],
              [99.1969, 20.1153],
              [99.3995, 20.0933],
              [99.5014, 20.1874],
              [99.4588, 20.3628],
              [99.6385, 20.3201],
              [99.8904, 20.4246],
              [100.1223, 20.3165]
            ]
          ]
        ]
      },
      properties: { name: "Thailand", id: "TH", Continent: "Asia" },
      id: "TH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.3589, 37.1085],
            [42.3501, 37.0606],
            [41.7884, 36.5974],
            [41.4167, 36.5148],
            [41.2963, 36.3834],
            [41.2457, 36.0736],
            [41.3525, 35.81],
            [41.3543, 35.6403],
            [41.2163, 35.2883],
            [41.1943, 34.7688],
            [40.9873, 34.4287],
            [40.6894, 34.3321],
            [39.5644, 33.7683],
            [38.7734, 33.3718],
            [38.0558, 32.9948],
            [36.8183, 32.3171],
            [36.3722, 32.3871],
            [36.0597, 32.5338],
            [35.895, 32.7135],
            [35.7873, 32.7351],
            [35.9134, 32.9495],
            [35.8374, 33.2783],
            [35.8691, 33.432],
            [36.0224, 33.5626],
            [35.9424, 33.6676],
            [36.0922, 33.8315],
            [36.2825, 33.894],
            [36.5853, 34.2213],
            [36.5045, 34.4322],
            [36.3265, 34.5131],
            [36.4328, 34.6137],
            [35.9763, 34.6292],
            [35.8994, 34.852],
            [35.9433, 35.2241],
            [35.9024, 35.4206],
            [35.7644, 35.5713],
            [35.84, 35.8495],
            [35.9675, 35.9101],
            [36.1274, 35.8314],
            [36.3476, 36.0033],
            [36.3757, 36.1712],
            [36.6416, 36.2634],
            [36.5375, 36.4577],
            [36.6583, 36.8022],
            [36.9418, 36.7583],
            [37.0661, 36.6528],
            [37.4365, 36.6436],
            [38.1915, 36.9015],
            [38.4438, 36.8624],
            [38.7668, 36.6932],
            [39.357, 36.6818],
            [40.0166, 36.8259],
            [40.7057, 37.0975],
            [40.9588, 37.1089],
            [41.2646, 37.069],
            [41.5156, 37.0892],
            [42.06, 37.2061],
            [42.2683, 37.2764],
            [42.3589, 37.1085]
          ]
        ]
      },
      properties: { name: "Syria", id: "SY", Continent: "Middle East" },
      id: "SY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[103.9698, 1.3313], [103.82, 1.2654], [103.6503, 1.3256], [103.8177, 1.4473], [103.9698, 1.3313]]
        ]
      },
      properties: { name: "Singapore", id: "SG", Continent: "Asia" },
      id: "SG"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [46.5315, 29.0965],
              [47.4332, 28.9896],
              [47.5831, 28.6279],
              [47.6714, 28.533],
              [48.4422, 28.5428],
              [48.6263, 28.1328],
              [48.7735, 27.9591],
              [48.9067, 27.6291],
              [49.2372, 27.4929],
              [49.1752, 27.4375],
              [49.4051, 27.1812],
              [49.5373, 27.1518],
              [49.7162, 26.9559],
              [49.986, 26.8288],
              [50.0273, 26.5269],
              [50.2141, 26.3085],
              [50.1552, 26.1007],
              [50.0317, 26.1108],
              [50.1306, 25.8467],
              [50.2813, 25.5662],
              [50.4549, 25.4253],
              [50.5582, 25.0868],
              [50.8043, 24.7893],
              [50.966, 24.574],
              [51.2684, 24.6073],
              [51.4182, 24.5309],
              [51.3101, 24.3407],
              [51.568, 24.2862],
              [51.5927, 24.0787],
              [52.5551, 22.933],
              [53.8321, 22.7766],
              [55.1044, 22.6215],
              [55.1861, 22.7041],
              [55.6409, 22.0019],
              [55.3513, 21.1256],
              [54.9773, 19.9958],
              [53.3228, 19.4442],
              [51.9776, 18.9959],
              [50.3552, 18.7776],
              [49.1923, 18.6217],
              [49.0421, 18.582],
              [48.172, 18.1567],
              [47.5796, 17.4482],
              [47.442, 17.1116],
              [47.1436, 16.9468],
              [46.9758, 16.9534],
              [46.7789, 17.2122],
              [46.6818, 17.2685],
              [46.3105, 17.2311],
              [45.4065, 17.32],
              [45.148, 17.4277],
              [44.7469, 17.432],
              [44.1558, 17.3987],
              [43.9167, 17.3248],
              [43.7133, 17.3656],
              [43.5972, 17.4715],
              [43.418, 17.5164],
              [43.1912, 17.3595],
              [43.1165, 16.942],
              [43.1649, 16.6893],
              [42.7992, 16.3721],
              [42.699, 16.7372],
              [42.383, 17.1226],
              [42.2939, 17.435],
              [42.0522, 17.6694],
              [41.7498, 17.8856],
              [41.5076, 18.2559],
              [41.4316, 18.4525],
              [41.2295, 18.6783],
              [41.1161, 19.0821],
              [40.7593, 19.7558],
              [40.616, 19.8221],
              [40.482, 19.9936],
              [40.0808, 20.2661],
              [39.8843, 20.2932],
              [39.6136, 20.5178],
              [39.4915, 20.7372],
              [39.2761, 20.9739],
              [39.0938, 21.3102],
              [39.1474, 21.5194],
              [38.9879, 21.882],
              [39.096, 22.393],
              [39.0621, 22.5921],
              [38.9412, 22.8821],
              [38.7971, 23.0482],
              [38.7061, 23.3052],
              [38.464, 23.7122],
              [38.2887, 23.9109],
              [37.9195, 24.1851],
              [37.5429, 24.2919],
              [37.1808, 24.8201],
              [37.2665, 24.9603],
              [37.1487, 25.2907],
              [36.9207, 25.641],
              [36.7629, 25.7513],
              [36.6754, 26.0387],
              [36.5185, 26.1051],
              [36.2496, 26.5946],
              [35.8519, 27.0706],
              [35.7631, 27.2587],
              [35.5816, 27.4322],
              [35.4238, 27.7337],
              [35.1809, 28.0347],
              [35.078, 28.087],
              [34.6161, 28.1481],
              [34.7796, 28.5075],
              [34.7994, 28.7207],
              [34.9505, 29.3535],
              [36.0158, 29.1904],
              [36.4759, 29.4949],
              [36.755, 29.8663],
              [37.4695, 29.9952],
              [37.6334, 30.3133],
              [37.9802, 30.5001],
              [37.4792, 31.008],
              [36.9584, 31.4918],
              [37.774, 31.6962],
              [38.9971, 32.0074],
              [39.1456, 32.1246],
              [40.3695, 31.9392],
              [42.0741, 31.0802],
              [42.8576, 30.4953],
              [43.7739, 29.8492],
              [44.7166, 29.1936],
              [46.3565, 29.0634],
              [46.5315, 29.0965]
            ]
          ],
          [[[41.9875, 16.7153], [41.7761, 16.8466], [41.948, 16.9363], [41.9875, 16.7153]]]
        ]
      },
      properties: { name: "Saudi Arabia", id: "SA", Continent: "Middle East" },
      id: "SA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [51.2684, 24.6073],
            [50.966, 24.574],
            [50.8043, 24.7893],
            [50.8469, 24.8882],
            [50.7771, 25.1774],
            [50.763, 25.4446],
            [50.8689, 25.6129],
            [51.0033, 25.9815],
            [51.2626, 26.1534],
            [51.543, 25.9021],
            [51.5725, 25.7812],
            [51.4855, 25.5249],
            [51.6019, 25.148],
            [51.587, 24.9646],
            [51.4279, 24.668],
            [51.2684, 24.6073]
          ]
        ]
      },
      properties: { name: "Qatar", id: "QA", Continent: "Middle East" },
      id: "QA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [130.5267, 42.5353],
            [130.6872, 42.3029],
            [130.4578, 42.302],
            [130.0684, 42.0458],
            [129.7564, 41.7122],
            [129.6827, 41.4942],
            [129.7662, 41.3039],
            [129.709, 40.8575],
            [129.3411, 40.7265],
            [129.1096, 40.491],
            [128.7013, 40.3174],
            [128.6107, 40.1978],
            [128.3045, 40.0361],
            [127.9665, 39.9957],
            [127.8673, 39.896],
            [127.5684, 39.7817],
            [127.5487, 39.4614],
            [127.3838, 39.2961],
            [127.3944, 39.2078],
            [127.7863, 39.0839],
            [127.9718, 38.898],
            [128.2491, 38.7455],
            [128.3744, 38.6233],
            [128.1687, 38.3592],
            [128.039, 38.3086],
            [127.1698, 38.3047],
            [127.0094, 38.2406],
            [126.6667, 37.9171],
            [126.6341, 37.7817],
            [126.37, 37.8784],
            [126.203, 37.8288],
            [125.942, 37.8736],
            [125.7693, 37.9853],
            [125.4494, 37.7303],
            [125.311, 37.8433],
            [124.9888, 37.9312],
            [125.1931, 38.0379],
            [124.6909, 38.1294],
            [124.8745, 38.2335],
            [124.8807, 38.3416],
            [125.0674, 38.5565],
            [125.3101, 38.6655],
            [125.1571, 38.8716],
            [125.4097, 39.2882],
            [125.3609, 39.5264],
            [125.1005, 39.5906],
            [124.7753, 39.7579],
            [124.5573, 39.7905],
            [124.3489, 39.907],
            [124.3621, 40.0041],
            [124.3863, 40.1042],
            [124.8895, 40.4597],
            [125.4168, 40.6597],
            [125.7285, 40.8668],
            [125.989, 40.905],
            [126.4904, 41.358],
            [126.6012, 41.6411],
            [126.9036, 41.7812],
            [127.0067, 41.7421],
            [127.1795, 41.5312],
            [127.6879, 41.4402],
            [127.9186, 41.4608],
            [128.1111, 41.3892],
            [128.2908, 41.5628],
            [128.0329, 41.8981],
            [128.0452, 41.9874],
            [128.9236, 42.0383],
            [129.1956, 42.2185],
            [129.314, 42.4136],
            [129.5235, 42.3846],
            [129.7199, 42.4751],
            [129.7792, 42.7766],
            [129.8984, 42.998],
            [130.2407, 42.8918],
            [130.2469, 42.7449],
            [130.5267, 42.5353]
          ]
        ]
      },
      properties: { name: "North Korea", id: "KP", Continent: "Asia" },
      id: "KP"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [153.5362, -11.4762],
              [153.3788, -11.5596],
              [153.2347, -11.4203],
              [153.3067, -11.3562],
              [153.5362, -11.4762]
            ]
          ],
          [
            [[151.0809, -10.0202], [151.2966, -9.957], [151.2307, -10.1946], [150.9591, -10.0927], [151.0809, -10.0202]]
          ],
          [
            [
              [150.5281, -9.3465],
              [150.8788, -9.513],
              [150.8941, -9.6673],
              [150.4362, -9.6247],
              [150.4344, -9.4348],
              [150.5281, -9.3465]
            ]
          ],
          [[[150.3452, -9.4938], [150.1097, -9.3618], [150.2081, -9.2063], [150.3201, -9.2644], [150.3452, -9.4938]]],
          [
            [
              [152.6308, -8.9593],
              [152.8097, -8.9673],
              [152.9955, -9.108],
              [152.8678, -9.2243],
              [152.72, -9.1668],
              [152.6308, -8.9593]
            ]
          ],
          [
            [
              [148.0254, -5.8265],
              [147.8746, -5.7492],
              [147.7811, -5.627],
              [147.8466, -5.4908],
              [148.0548, -5.6116],
              [148.0254, -5.8265]
            ]
          ],
          [
            [
              [155.9575, -6.6869],
              [155.7194, -6.8627],
              [155.5206, -6.8303],
              [155.3441, -6.7217],
              [155.2086, -6.527],
              [155.202, -6.3077],
              [155.01, -6.2096],
              [154.782, -5.9706],
              [154.7094, -5.7474],
              [154.7411, -5.5453],
              [154.9968, -5.54],
              [155.0935, -5.6204],
              [155.2276, -5.8657],
              [155.3726, -5.9745],
              [155.467, -6.1451],
              [155.6385, -6.2212],
              [155.8225, -6.3802],
              [155.9575, -6.6869]
            ]
          ],
          [[[147.1764, -5.4322], [147.0058, -5.307], [147.131, -5.1907], [147.2062, -5.2516], [147.1764, -5.4322]]],
          [[[154.647, -5.4327], [154.5402, -5.1107], [154.6823, -5.0539], [154.727, -5.2183], [154.647, -5.4327]]],
          [
            [
              [151.9159, -4.2968],
              [152.1175, -4.2124],
              [152.4053, -4.3408],
              [152.3636, -4.4906],
              [152.4001, -4.7314],
              [152.2578, -4.9547],
              [152.0134, -5.0039],
              [151.9941, -5.1488],
              [152.1431, -5.3572],
              [152.0772, -5.4582],
              [151.8653, -5.565],
              [151.5151, -5.5523],
              [151.4223, -5.7474],
              [151.2291, -5.9202],
              [151.043, -6.0151],
              [150.8093, -6.0712],
              [150.4282, -6.2764],
              [149.6522, -6.2906],
              [149.3824, -6.0778],
              [149.1266, -6.1275],
              [148.8076, -5.9166],
              [148.4011, -5.7649],
              [148.3448, -5.5448],
              [148.4324, -5.4719],
              [148.5651, -5.5079],
              [148.9991, -5.4846],
              [149.2453, -5.5729],
              [149.4752, -5.5734],
              [149.6316, -5.5163],
              [149.8315, -5.5241],
              [149.9625, -5.4477],
              [150.0451, -5.0345],
              [150.1062, -5.4292],
              [150.2987, -5.5355],
              [150.5192, -5.46],
              [150.6261, -5.5211],
              [150.953, -5.4235],
              [151.1376, -5.1128],
              [151.3269, -4.9604],
              [151.5727, -4.9375],
              [151.671, -4.8835],
              [151.6644, -4.6373],
              [151.552, -4.3456],
              [151.5933, -4.201],
              [151.8197, -4.2168],
              [151.9159, -4.2968]
            ]
          ],
          [
            [
              [140.9762, -9.1189],
              [140.9757, -8.2927],
              [140.9753, -6.9053],
              [140.8624, -6.7397],
              [140.9748, -6.3459],
              [140.9744, -5.0399],
              [140.9739, -4.0234],
              [140.9735, -2.6097],
              [141.1857, -2.6277],
              [141.986, -2.9634],
              [142.2115, -3.0834],
              [142.9054, -3.3208],
              [143.5091, -3.431],
              [144.0158, -3.7835],
              [144.4777, -3.8252],
              [144.6266, -3.9931],
              [144.8433, -4.1012],
              [145.088, -4.349],
              [145.3347, -4.3855],
              [145.7671, -4.8231],
              [145.7929, -5.1779],
              [145.7454, -5.4024],
              [145.8528, -5.4714],
              [146.2056, -5.5448],
              [146.4038, -5.6165],
              [147.0345, -5.9193],
              [147.3768, -5.9503],
              [147.5666, -6.0572],
              [147.8017, -6.3152],
              [147.8452, -6.6627],
              [147.7095, -6.7234],
              [147.3556, -6.7423],
              [147.1192, -6.7217],
              [146.9536, -6.8342],
              [146.9606, -6.9291],
              [147.19, -7.3783],
              [147.7243, -7.8761],
              [148.1269, -8.1033],
              [148.2468, -8.5546],
              [148.4511, -8.6948],
              [148.5831, -9.0517],
              [148.7918, -9.0895],
              [149.0976, -9.017],
              [149.2474, -9.071],
              [149.2032, -9.4067],
              [149.2634, -9.4977],
              [149.4756, -9.5882],
              [149.9735, -9.6607],
              [149.9849, -9.7372],
              [149.7612, -9.8058],
              [149.8746, -10.0131],
              [150.3641, -10.1898],
              [150.6674, -10.2571],
              [150.4889, -10.4258],
              [150.6471, -10.5177],
              [150.4823, -10.6368],
              [150.3201, -10.6548],
              [150.017, -10.5775],
              [149.7541, -10.3533],
              [149.353, -10.2896],
              [148.9368, -10.2548],
              [148.7127, -10.1665],
              [148.3836, -10.1855],
              [148.1501, -10.1072],
              [148.0513, -10.1287],
              [147.7688, -10.0699],
              [147.5533, -9.9125],
              [147.4963, -9.7903],
              [147.299, -9.5794],
              [147.0172, -9.3878],
              [146.9255, -9.2471],
              [146.9132, -9.0916],
              [146.6965, -9.0252],
              [146.456, -8.6439],
              [146.2966, -8.4553],
              [146.1841, -8.2466],
              [146.0334, -8.0765],
              [145.7288, -7.9525],
              [145.4677, -7.9302],
              [145.2875, -7.8615],
              [144.9738, -7.8022],
              [144.7735, -7.6423],
              [144.5097, -7.5671],
              [144.2255, -7.7649],
              [143.898, -7.674],
              [143.942, -7.9443],
              [143.7794, -8.0282],
              [143.5517, -7.9847],
              [143.6137, -8.2004],
              [143.0947, -8.3112],
              [142.7976, -8.345],
              [143.1118, -8.4747],
              [143.3065, -8.661],
              [143.3922, -8.8015],
              [143.3658, -8.9611],
              [142.8591, -9.2024],
              [142.6473, -9.3276],
              [142.2299, -9.1698],
              [141.6217, -9.2115],
              [141.4058, -9.1506],
              [141.1335, -9.2213],
              [140.9762, -9.1189]
            ]
          ],
          [
            [
              [152.9656, -4.7565],
              [152.8915, -4.8325],
              [152.6805, -4.4984],
              [152.6968, -4.2818],
              [152.5983, -3.9949],
              [152.3558, -3.6683],
              [152.1923, -3.5057],
              [151.973, -3.4535],
              [151.5784, -3.1537],
              [150.968, -2.7798],
              [150.848, -2.7798],
              [150.8256, -2.5733],
              [151.1746, -2.7894],
              [151.2264, -2.8704],
              [151.4756, -2.9424],
              [151.8074, -3.1726],
              [152.0332, -3.2513],
              [152.1795, -3.4104],
              [153.0167, -4.1057],
              [153.1327, -4.3526],
              [153.0443, -4.4765],
              [152.9656, -4.7565]
            ]
          ],
          [
            [
              [150.4367, -2.662],
              [150.1655, -2.6602],
              [150.0433, -2.5126],
              [150.2271, -2.3842],
              [150.4296, -2.4704],
              [150.4367, -2.662]
            ]
          ],
          [
            [
              [147.0678, -1.9603],
              [147.4005, -2.0253],
              [147.2062, -2.1822],
              [146.9262, -2.1892],
              [146.7479, -2.1487],
              [146.5465, -2.2084],
              [146.656, -1.9738],
              [147.0678, -1.9603]
            ]
          ]
        ]
      },
      properties: { name: "Papua New Guinea", id: "PG", Continent: "Oceania" },
      id: "PG"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[120.2501, 5.257], [120.1917, 5.1687], [119.8275, 5.1335], [120.2081, 5.3404], [120.2501, 5.257]]],
          [
            [
              [121.1595, 6.0756],
              [121.2179, 5.9425],
              [120.931, 5.8959],
              [120.8984, 6.0072],
              [121.0377, 6.0963],
              [121.1595, 6.0756]
            ]
          ],
          [
            [
              [122.0928, 6.4281],
              [121.9592, 6.4158],
              [121.8089, 6.6136],
              [122.058, 6.7406],
              [122.2883, 6.6391],
              [122.2009, 6.4831],
              [122.0928, 6.4281]
            ]
          ],
          [
            [
              [126.0058, 9.3211],
              [126.1933, 9.2766],
              [126.1921, 9.125],
              [126.3198, 8.8447],
              [126.1415, 8.6271],
              [126.3656, 8.4839],
              [126.4587, 8.203],
              [126.4354, 7.8326],
              [126.57, 7.6774],
              [126.5814, 7.2477],
              [126.4391, 7.0126],
              [126.294, 6.8824],
              [126.1098, 6.4897],
              [126.0799, 6.7331],
              [125.8243, 7.3334],
              [125.6893, 7.263],
              [125.6405, 7.1052],
              [125.401, 6.796],
              [125.3808, 6.69],
              [125.5887, 6.4658],
              [125.6708, 6.225],
              [125.6683, 5.9785],
              [125.456, 5.6643],
              [125.2881, 5.6323],
              [125.2332, 5.8081],
              [125.2688, 6.033],
              [125.1739, 6.0471],
              [125.0355, 5.8708],
              [124.9272, 5.8756],
              [124.6364, 5.9979],
              [124.2132, 6.2334],
              [124.0778, 6.4044],
              [123.9851, 6.9937],
              [124.1908, 7.2671],
              [124.2066, 7.3962],
              [123.9685, 7.6643],
              [123.6656, 7.8176],
              [123.4928, 7.808],
              [123.3909, 7.4076],
              [123.1783, 7.5294],
              [123.0965, 7.7004],
              [122.9897, 7.5465],
              [122.8429, 7.5294],
              [122.7916, 7.7227],
              [122.6162, 7.7631],
              [122.4976, 7.673],
              [122.32, 7.3403],
              [122.1759, 7.0042],
              [122.0981, 6.9137],
              [121.964, 6.9686],
              [121.9245, 7.1998],
              [122.0471, 7.3636],
              [122.1319, 7.8106],
              [122.3371, 8.0286],
              [122.6733, 8.1332],
              [122.9111, 8.1565],
              [122.9963, 8.2206],
              [123.0174, 8.3982],
              [123.1471, 8.5159],
              [123.3414, 8.5704],
              [123.4344, 8.7031],
              [123.6801, 8.6205],
              [123.8493, 8.4329],
              [123.8775, 8.1886],
              [123.997, 8.1592],
              [124.2259, 8.2712],
              [124.3252, 8.5085],
              [124.4513, 8.6064],
              [124.6218, 8.5225],
              [124.7308, 8.563],
              [124.7871, 8.8741],
              [124.8688, 8.9721],
              [125.1413, 8.8689],
              [125.2095, 9.0271],
              [125.4991, 9.0148],
              [125.5337, 9.1409],
              [125.4142, 9.6691],
              [125.521, 9.7591],
              [125.8765, 9.5131],
              [126.0058, 9.3211]
            ]
          ],
          [
            [
              [124.5938, 9.7872],
              [124.36, 9.6299],
              [124.1226, 9.5993],
              [123.9354, 9.6237],
              [123.8173, 9.8171],
              [124.0597, 10.0004],
              [124.1728, 10.1354],
              [124.3358, 10.16],
              [124.5771, 10.0268],
              [124.5938, 9.7872]
            ]
          ],
          [
            [
              [125.6902, 9.9147],
              [125.495, 10.1186],
              [125.5244, 10.3098],
              [125.6466, 10.2451],
              [125.7029, 10.0716],
              [125.6902, 9.9147]
            ]
          ],
          [[[122.6495, 10.4724], [122.5371, 10.6072], [122.7371, 10.6544], [122.6495, 10.4724]]],
          [
            [
              [123.1309, 9.0639],
              [122.9945, 9.0586],
              [122.8666, 9.3197],
              [122.5626, 9.4827],
              [122.411, 9.6941],
              [122.4716, 9.9617],
              [122.7129, 9.9899],
              [122.8557, 10.0866],
              [122.8666, 10.2839],
              [122.8171, 10.5037],
              [122.9585, 10.6982],
              [122.9836, 10.8868],
              [123.2565, 10.994],
              [123.5109, 10.9233],
              [123.5676, 10.7805],
              [123.3435, 10.3256],
              [123.296, 10.1243],
              [123.1619, 9.8642],
              [123.1496, 9.6062],
              [123.3083, 9.3571],
              [123.2288, 9.1215],
              [123.1309, 9.0639]
            ]
          ],
          [
            [
              [123.3703, 9.4497],
              [123.3158, 9.4889],
              [123.4037, 9.8892],
              [123.3861, 9.9671],
              [123.7113, 10.4736],
              [123.9297, 10.9637],
              [124.0533, 10.9258],
              [124.0515, 10.5858],
              [123.9521, 10.3169],
              [123.7886, 10.221],
              [123.6436, 10.0202],
              [123.4933, 9.589],
              [123.3703, 9.4497]
            ]
          ],
          [
            [
              [117.3111, 8.4395],
              [117.2557, 8.541],
              [117.3498, 8.7133],
              [117.8851, 9.2406],
              [118.1149, 9.3469],
              [118.5332, 9.7938],
              [119.0241, 10.3537],
              [119.2241, 10.4772],
              [119.3128, 10.6868],
              [119.2615, 10.8451],
              [119.4654, 11.2937],
              [119.5602, 11.2665],
              [119.5268, 10.9532],
              [119.6841, 10.5515],
              [119.5954, 10.4073],
              [119.2848, 10.2517],
              [119.1915, 10.0611],
              [118.782, 9.9165],
              [118.774, 9.7667],
              [118.5697, 9.4225],
              [118.4348, 9.2563],
              [118.1343, 9.1013],
              [117.9897, 8.8771],
              [117.5163, 8.5384],
              [117.3111, 8.4395]
            ]
          ],
          [
            [
              [124.5748, 11.3429],
              [124.7242, 11.3223],
              [124.9299, 11.3728],
              [125.0266, 11.2115],
              [125.0134, 10.7853],
              [125.1878, 10.5849],
              [125.2533, 10.264],
              [125.1053, 10.2184],
              [125.0266, 10.033],
              [124.781, 10.1678],
              [124.7379, 10.4398],
              [124.7871, 10.7813],
              [124.6627, 10.9619],
              [124.4456, 10.9237],
              [124.3304, 11.5349],
              [124.5748, 11.3429]
            ]
          ],
          [[[124.6086, 11.492], [124.4834, 11.4858], [124.3605, 11.6655], [124.5107, 11.687], [124.6086, 11.492]]],
          [
            [
              [122.4967, 11.6149],
              [122.7266, 11.6079],
              [122.9313, 11.5292],
              [123.1585, 11.5354],
              [123.1198, 11.2868],
              [123.0165, 11.1167],
              [122.8025, 10.9901],
              [122.7699, 10.8234],
              [122.5222, 10.6916],
              [122.1978, 10.6232],
              [121.9882, 10.4583],
              [121.964, 10.8715],
              [122.0507, 11.0973],
              [122.1038, 11.643],
              [121.8911, 11.7907],
              [121.9636, 11.8975],
              [122.291, 11.7723],
              [122.4967, 11.6149]
            ]
          ],
          [[[120.0389, 11.7032], [119.9641, 11.6694], [119.8608, 11.9538], [120.0357, 11.9173], [120.0389, 11.7032]]],
          [
            [
              [120.0998, 12.1677],
              [120.3148, 12.0122],
              [120.0106, 12.0083],
              [119.8661, 12.199],
              [119.8964, 12.3132],
              [120.0998, 12.1677]
            ]
          ],
          [
            [
              [125.2398, 12.5278],
              [125.3201, 12.3216],
              [125.536, 12.1912],
              [125.4569, 11.9524],
              [125.5057, 11.5442],
              [125.6088, 11.3232],
              [125.6282, 11.132],
              [125.2336, 11.1452],
              [125.0341, 11.3412],
              [124.9172, 11.5583],
              [124.9949, 11.7652],
              [124.8843, 11.7754],
              [124.6768, 12.0211],
              [124.4456, 12.1529],
              [124.3256, 12.4037],
              [124.2944, 12.5695],
              [124.566, 12.5264],
              [125.1502, 12.5725],
              [125.2398, 12.5278]
            ]
          ],
          [
            [
              [123.7165, 12.2874],
              [123.9086, 12.1691],
              [124.0405, 11.9666],
              [123.9824, 11.8188],
              [123.612, 12.09],
              [123.4187, 12.1942],
              [123.293, 12.0364],
              [123.2107, 12.1063],
              [123.267, 12.3955],
              [123.2363, 12.5835],
              [123.4627, 12.5009],
              [123.7165, 12.2874]
            ]
          ],
          [
            [
              [120.7046, 13.4796],
              [120.9155, 13.5011],
              [121.1225, 13.3811],
              [121.2029, 13.4326],
              [121.5223, 13.1311],
              [121.4797, 12.837],
              [121.5408, 12.6384],
              [121.4126, 12.4231],
              [121.3941, 12.3005],
              [121.2368, 12.2188],
              [121.1075, 12.3036],
              [120.9221, 12.5118],
              [120.796, 12.7478],
              [120.7634, 12.9697],
              [120.651, 13.1693],
              [120.5082, 13.2603],
              [120.4013, 13.5173],
              [120.7046, 13.4796]
            ]
          ],
          [
            [
              [121.9148, 13.5403],
              [122.1148, 13.4629],
              [122.1223, 13.3649],
              [122.0049, 13.2049],
              [121.8292, 13.3284],
              [121.9148, 13.5403]
            ]
          ],
          [
            [
              [124.3539, 13.632],
              [124.1753, 13.5314],
              [124.0392, 13.6632],
              [124.1235, 13.7907],
              [124.1226, 13.9797],
              [124.225, 14.0777],
              [124.4171, 13.8711],
              [124.3539, 13.632]
            ]
          ],
          [[[122.0335, 15.005], [121.9227, 14.8002], [121.8204, 14.9632], [122.0335, 15.005]]],
          [
            [
              [121.1018, 18.6155],
              [121.5932, 18.3763],
              [121.8459, 18.2951],
              [122.0383, 18.328],
              [122.1469, 18.4867],
              [122.2997, 18.4028],
              [122.294, 18.2345],
              [122.1793, 18.0644],
              [122.1512, 17.7563],
              [122.237, 17.435],
              [122.3877, 17.3068],
              [122.4078, 17.178],
              [122.5191, 17.1248],
              [122.4258, 16.8229],
              [122.2256, 16.4353],
              [122.135, 16.1848],
              [121.7887, 16.0777],
              [121.5953, 15.9335],
              [121.6067, 15.6702],
              [121.3923, 15.3244],
              [121.6956, 14.7373],
              [121.6265, 14.6819],
              [121.6485, 14.4811],
              [121.8005, 14.1138],
              [121.9118, 14.0205],
              [122.2115, 13.93],
              [122.2744, 14.0447],
              [122.2, 14.148],
              [122.4905, 14.3224],
              [122.8561, 14.2508],
              [123.071, 13.9603],
              [123.0596, 13.7889],
              [123.2315, 13.7476],
              [123.4323, 13.9665],
              [123.7263, 13.8844],
              [123.8062, 13.7218],
              [123.6071, 13.7045],
              [123.608, 13.5284],
              [123.8194, 13.2696],
              [123.7851, 13.1104],
              [124.1429, 13.0361],
              [124.1377, 12.7909],
              [124.0597, 12.5672],
              [123.8779, 12.6899],
              [123.9179, 12.9399],
              [123.6265, 12.9118],
              [123.311, 13.0441],
              [123.2956, 13.216],
              [123.1633, 13.4418],
              [122.8636, 13.6171],
              [122.5955, 13.9076],
              [122.4682, 13.8869],
              [122.5081, 13.6571],
              [122.6676, 13.3957],
              [122.6755, 13.2532],
              [122.5152, 13.2603],
              [122.4066, 13.4928],
              [122.073, 13.7886],
              [121.7777, 13.9375],
              [121.5013, 13.8422],
              [121.4464, 13.712],
              [121.2033, 13.6399],
              [120.8407, 13.8848],
              [120.6369, 13.8043],
              [120.6167, 14.1879],
              [120.9221, 14.4934],
              [120.8883, 14.7158],
              [120.708, 14.7764],
              [120.5468, 14.7662],
              [120.5885, 14.4832],
              [120.4387, 14.4534],
              [120.2506, 14.7936],
              [120.0823, 14.8511],
              [120.0366, 15.1147],
              [119.9329, 15.4307],
              [119.8916, 15.8376],
              [119.7689, 16.0081],
              [119.773, 16.2551],
              [119.8309, 16.3267],
              [120.1597, 16.0478],
              [120.3367, 16.0667],
              [120.3894, 16.2218],
              [120.3055, 16.5294],
              [120.3214, 16.7618],
              [120.4088, 16.9556],
              [120.4243, 17.4386],
              [120.3584, 17.6381],
              [120.5996, 18.5077],
              [120.8136, 18.6032],
              [121.1018, 18.6155]
            ]
          ]
        ]
      },
      properties: { name: "Philippines", id: "PH", Continent: "Asia" },
      id: "PH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [77.799, 35.4962],
            [77.0484, 35.1099],
            [77.001, 34.992],
            [76.783, 34.9003],
            [76.5944, 34.7355],
            [76.4569, 34.7562],
            [76.0407, 34.67],
            [75.7094, 34.5031],
            [75.4523, 34.5368],
            [75.1873, 34.6388],
            [74.9522, 34.6458],
            [74.3006, 34.7653],
            [73.9612, 34.6538],
            [73.8123, 34.4221],
            [73.8101, 34.3255],
            [73.9793, 34.1914],
            [73.9498, 34.0187],
            [74.2469, 33.9902],
            [74.2157, 33.8865],
            [74.0008, 33.7884],
            [74.0039, 33.6324],
            [74.1498, 33.5068],
            [73.9942, 33.2422],
            [74.1265, 33.0756],
            [74.3036, 32.9917],
            [74.3546, 32.7689],
            [74.663, 32.7575],
            [74.6578, 32.5189],
            [74.9874, 32.4621],
            [75.2335, 32.3721],
            [75.3332, 32.279],
            [75.2541, 32.1401],
            [74.7395, 31.9488],
            [74.5559, 31.8184],
            [74.5101, 31.713],
            [74.5936, 31.4656],
            [74.518, 31.1856],
            [74.6327, 31.0344],
            [74.2157, 30.7689],
            [73.8993, 30.4353],
            [73.9332, 30.2223],
            [73.8092, 30.0935],
            [73.3817, 29.9345],
            [73.2309, 29.5503],
            [72.903, 29.0292],
            [72.3419, 28.7519],
            [72.1793, 28.4219],
            [71.9477, 28.177],
            [71.8699, 27.9622],
            [71.543, 27.8699],
            [71.1848, 27.8317],
            [70.875, 27.7148],
            [70.7374, 27.7292],
            [70.6289, 27.9376],
            [70.4035, 28.025],
            [70.1446, 27.8493],
            [69.8963, 27.4735],
            [69.568, 27.1743],
            [69.4701, 26.8047],
            [69.7359, 26.6271],
            [70.0594, 26.5787],
            [70.1565, 26.4711],
            [70.0778, 26.0716],
            [70.1002, 25.9103],
            [70.2646, 25.7064],
            [70.6486, 25.667],
            [70.6522, 25.4225],
            [70.8776, 25.0627],
            [71.0481, 24.6878],
            [70.973, 24.4874],
            [71.0442, 24.4],
            [70.7163, 24.2383],
            [70.5793, 24.2787],
            [70.5467, 24.4185],
            [70.098, 24.2875],
            [70.0212, 24.1913],
            [69.7162, 24.1728],
            [69.5593, 24.273],
            [68.7283, 24.2655],
            [68.7243, 23.9645],
            [68.4887, 23.9672],
            [68.1649, 23.8573],
            [67.6687, 23.8112],
            [67.3097, 24.1749],
            [67.2886, 24.3674],
            [67.1717, 24.756],
            [66.7033, 24.8609],
            [66.6989, 25.2262],
            [66.4286, 25.5755],
            [66.219, 25.5895],
            [66.1311, 25.4933],
            [65.6797, 25.3553],
            [65.406, 25.3742],
            [65.0614, 25.311],
            [64.7767, 25.3074],
            [64.6589, 25.184],
            [64.1522, 25.3334],
            [63.7207, 25.3861],
            [63.5563, 25.3532],
            [63.4913, 25.2107],
            [63.1696, 25.2551],
            [63.015, 25.2248],
            [62.5724, 25.2547],
            [62.3153, 25.1347],
            [62.1985, 25.2248],
            [62.0895, 25.155],
            [61.7436, 25.1382],
            [61.5876, 25.202],
            [61.6684, 25.7689],
            [61.7541, 25.8431],
            [61.8104, 26.1653],
            [61.8697, 26.2421],
            [62.2393, 26.3573],
            [62.4393, 26.5607],
            [62.7868, 26.6439],
            [63.1679, 26.6658],
            [63.2501, 26.8793],
            [63.2421, 27.0776],
            [63.1665, 27.2525],
            [62.9156, 27.2181],
            [62.7631, 27.2503],
            [62.8119, 27.4972],
            [62.7398, 28.0021],
            [62.7622, 28.2021],
            [62.5649, 28.2351],
            [62.3531, 28.4149],
            [61.8899, 28.5467],
            [61.6228, 28.7914],
            [61.338, 29.2652],
            [61.3182, 29.3724],
            [60.8436, 29.8584],
            [62.3734, 29.4256],
            [62.4766, 29.4084],
            [63.5677, 29.4981],
            [64.0991, 29.3922],
            [64.266, 29.5069],
            [64.5209, 29.5644],
            [65.0953, 29.5596],
            [66.1772, 29.8356],
            [66.3135, 29.9687],
            [66.2476, 30.0438],
            [66.3056, 30.3211],
            [66.2867, 30.6077],
            [66.3974, 30.9122],
            [66.567, 30.9965],
            [66.7314, 31.1947],
            [66.9243, 31.3055],
            [67.2873, 31.2177],
            [67.453, 31.2348],
            [67.7377, 31.3438],
            [67.5782, 31.5064],
            [68.0172, 31.6782],
            [68.2136, 31.8074],
            [68.443, 31.7547],
            [68.5977, 31.8031],
            [68.8689, 31.6343],
            [69.0829, 31.7384],
            [69.2789, 31.937],
            [69.2415, 32.4336],
            [69.405, 32.6828],
            [69.5017, 33.0199],
            [69.92, 33.1121],
            [70.2611, 33.2888],
            [70.284, 33.3688],
            [70.0567, 33.72],
            [69.8682, 33.8974],
            [69.9947, 34.0516],
            [70.4158, 33.9506],
            [70.8482, 33.9818],
            [71.0517, 34.05],
            [71.0956, 34.3694],
            [70.9655, 34.5307],
            [71.2938, 34.8677],
            [71.4551, 34.9671],
            [71.6207, 35.1833],
            [71.6006, 35.4078],
            [71.4274, 35.8337],
            [71.1852, 36.0419],
            [71.2332, 36.1219],
            [71.6207, 36.4366],
            [71.822, 36.4858],
            [72.1569, 36.7007],
            [72.6231, 36.8294],
            [73.1167, 36.8686],
            [73.7692, 36.8884],
            [74.0017, 36.8233],
            [74.5414, 37.0219],
            [74.6925, 37.036],
            [74.8894, 36.9525],
            [75.0542, 36.9872],
            [75.3468, 36.9134],
            [75.4242, 36.7381],
            [75.6672, 36.742],
            [75.8404, 36.6497],
            [75.9744, 36.3826],
            [75.945, 36.0178],
            [76.0706, 35.9831],
            [76.2517, 35.8108],
            [76.5514, 35.8873],
            [76.5633, 35.773],
            [76.8788, 35.6131],
            [77.4466, 35.4755],
            [77.799, 35.4962]
          ]
        ]
      },
      properties: { name: "Pakistan", id: "PK", Continent: "Asia" },
      id: "PK"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [56.388, 24.9792],
              [56.4895, 24.7163],
              [56.6407, 24.4703],
              [56.9127, 24.1503],
              [57.2199, 23.9227],
              [57.6114, 23.8037],
              [58.1208, 23.7166],
              [58.3246, 23.624],
              [58.5782, 23.6436],
              [58.7729, 23.5171],
              [59.03, 23.1305],
              [59.3112, 22.7933],
              [59.5353, 22.5784],
              [59.8232, 22.5091],
              [59.7999, 22.2204],
              [59.6522, 21.9518],
              [59.5173, 21.7826],
              [59.3714, 21.4987],
              [59.0686, 21.2891],
              [58.8955, 21.1124],
              [58.6907, 20.807],
              [58.4745, 20.4066],
              [58.209, 20.4243],
              [58.1027, 20.5701],
              [57.8619, 20.244],
              [57.7151, 19.6068],
              [57.8118, 19.0171],
              [57.6755, 18.9578],
              [57.1763, 18.9024],
              [56.8261, 18.7535],
              [56.6552, 18.5873],
              [56.551, 18.1659],
              [56.3836, 17.9879],
              [56.2702, 17.9506],
              [55.6141, 17.886],
              [55.4787, 17.8434],
              [55.2551, 17.5854],
              [55.2955, 17.3814],
              [55.0643, 17.0391],
              [54.7717, 16.9645],
              [54.5664, 17.0316],
              [54.0681, 17.0058],
              [53.9543, 16.9178],
              [53.6098, 16.7597],
              [53.2977, 16.7237],
              [53.0855, 16.6485],
              [52.8007, 17.2681],
              [52.7291, 17.3006],
              [52.2874, 18.2968],
              [51.9776, 18.9959],
              [53.3228, 19.4442],
              [54.9773, 19.9958],
              [55.3513, 21.1256],
              [55.6409, 22.0019],
              [55.1861, 22.7041],
              [55.1997, 23.0345],
              [55.5086, 23.7245],
              [55.4682, 23.9412],
              [55.5478, 23.9913],
              [55.985, 24.0638],
              [55.9287, 24.215],
              [55.7609, 24.2427],
              [55.796, 24.868],
              [55.9155, 24.9717],
              [56.0641, 24.7388],
              [56.388, 24.9792]
            ]
          ],
          [
            [
              [56.2979, 25.6506],
              [56.1449, 25.6907],
              [56.1674, 26.0476],
              [56.0804, 26.0624],
              [56.1643, 26.207],
              [56.4297, 26.327],
              [56.3739, 25.8045],
              [56.2979, 25.6506]
            ]
          ]
        ]
      },
      properties: { name: "Oman", id: "OM", Continent: "Middle East" },
      id: "OM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [88.1099, 27.8703],
            [88.1473, 27.749],
            [88.0242, 27.409],
            [87.9842, 27.1338],
            [88.1112, 26.9285],
            [88.1613, 26.7247],
            [88.0549, 26.4298],
            [87.6331, 26.399],
            [87.4133, 26.4227],
            [87.2872, 26.3604],
            [87.0895, 26.4329],
            [87.0165, 26.5555],
            [86.7015, 26.435],
            [86.3662, 26.5744],
            [86.0071, 26.6491],
            [85.7373, 26.6398],
            [85.6999, 26.7814],
            [85.5686, 26.8398],
            [85.293, 26.7409],
            [84.6853, 27.0411],
            [84.6101, 27.2986],
            [84.0911, 27.4915],
            [83.8292, 27.3777],
            [83.4473, 27.4651],
            [83.2896, 27.3711],
            [82.7336, 27.5187],
            [82.6774, 27.6735],
            [82.4511, 27.6721],
            [81.9879, 27.9139],
            [81.8529, 27.8669],
            [81.3106, 28.1761],
            [81.1688, 28.3353],
            [80.7508, 28.5396],
            [80.5869, 28.6495],
            [80.4186, 28.6121],
            [80.0705, 28.8301],
            [80.1303, 29.1004],
            [80.2331, 29.1943],
            [80.2547, 29.4233],
            [80.4019, 29.73],
            [80.5491, 29.8997],
            [80.9077, 30.1722],
            [81.0101, 30.1642],
            [81.1103, 30.0364],
            [81.2553, 30.0935],
            [81.417, 30.3375],
            [81.642, 30.3875],
            [82.0432, 30.327],
            [82.1593, 30.1151],
            [82.4866, 29.9414],
            [82.854, 29.683],
            [83.1555, 29.6128],
            [83.5835, 29.1834],
            [83.936, 29.2792],
            [84.1016, 29.2199],
            [84.2286, 28.9118],
            [84.7142, 28.5959],
            [84.8553, 28.5537],
            [85.1589, 28.5923],
            [85.1225, 28.3159],
            [85.6784, 28.2773],
            [85.8401, 28.1353],
            [85.954, 27.9283],
            [86.0787, 28.0835],
            [86.4088, 27.9283],
            [86.5547, 28.0852],
            [87.0201, 27.9283],
            [87.1414, 27.8382],
            [87.6828, 27.8215],
            [87.8607, 27.8861],
            [88.1099, 27.8703]
          ]
        ]
      },
      properties: { name: "Nepal", id: "NP", Continent: "Asia" },
      id: "NP"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[117.8851, 4.1861], [117.6491, 4.1689], [117.7615, 4.2524], [117.8851, 4.1861]]],
          [
            [
              [102.1008, 6.2423],
              [102.3403, 6.1719],
              [102.5341, 5.8626],
              [102.7902, 5.6446],
              [102.9824, 5.5251],
              [103.1968, 5.2622],
              [103.4157, 4.8501],
              [103.4684, 4.3934],
              [103.4205, 3.9768],
              [103.3621, 3.7694],
              [103.4534, 3.5207],
              [103.4398, 2.9332],
              [103.5373, 2.7749],
              [103.812, 2.5807],
              [104.2189, 1.7228],
              [104.2884, 1.4808],
              [104.1767, 1.3647],
              [104.0761, 1.5299],
              [103.9913, 1.4548],
              [103.6946, 1.4496],
              [103.4802, 1.3296],
              [103.3567, 1.5463],
              [102.8971, 1.7923],
              [102.7275, 1.8555],
              [102.5485, 2.0423],
              [102.1455, 2.2484],
              [101.7813, 2.5736],
              [101.5198, 2.6835],
              [101.2952, 2.8853],
              [101.3542, 3.0114],
              [101.2997, 3.2534],
              [101.0249, 3.6244],
              [100.8514, 3.7769],
              [100.7157, 3.9663],
              [100.7955, 4.023],
              [100.6145, 4.3737],
              [100.6145, 4.6522],
              [100.4731, 5.0442],
              [100.3526, 5.5874],
              [100.3429, 5.9842],
              [100.1189, 6.4421],
              [100.1768, 6.672],
              [100.3451, 6.5499],
              [100.7939, 6.4259],
              [100.8738, 6.2453],
              [101.0536, 6.2426],
              [101.0755, 5.9566],
              [100.9819, 5.7712],
              [101.1141, 5.6366],
              [101.2571, 5.7896],
              [101.5563, 5.9078],
              [101.65, 5.7962],
              [101.8735, 5.8257],
              [102.055, 6.0968],
              [102.1008, 6.2423]
            ]
          ],
          [
            [
              [117.5747, 4.1706],
              [117.2778, 4.2994],
              [117.1006, 4.3372],
              [116.5143, 4.3706],
              [116.4147, 4.3081],
              [116.1347, 4.3552],
              [116.0213, 4.291],
              [115.8961, 4.3486],
              [115.6786, 4.1931],
              [115.5687, 3.9391],
              [115.5445, 3.6336],
              [115.5661, 3.4456],
              [115.4544, 3.0342],
              [115.2466, 3.0258],
              [115.0861, 2.8413],
              [115.0809, 2.6343],
              [115.1789, 2.5232],
              [114.7865, 2.2502],
              [114.8304, 1.98],
              [114.7034, 1.8507],
              [114.5461, 1.4671],
              [114.387, 1.5],
              [113.9023, 1.4346],
              [113.6223, 1.2359],
              [113.0066, 1.4341],
              [112.943, 1.5673],
              [112.4762, 1.559],
              [112.1859, 1.4394],
              [112.0781, 1.1433],
              [111.923, 1.113],
              [111.7697, 0.9995],
              [111.4831, 0.996],
              [111.2867, 1.0435],
              [110.9382, 1.017],
              [110.5057, 0.8619],
              [110.315, 0.996],
              [109.818, 1.4389],
              [109.6542, 1.6152],
              [109.6361, 1.7764],
              [109.539, 1.8961],
              [109.6291, 2.0274],
              [109.7196, 1.8578],
              [109.9846, 1.7176],
              [110.3493, 1.7198],
              [110.6754, 1.5479],
              [110.9399, 1.5173],
              [111.154, 1.7392],
              [111.1702, 1.9022],
              [111.2682, 2.1399],
              [111.1953, 2.2972],
              [111.2418, 2.4356],
              [111.444, 2.3816],
              [111.4431, 2.6343],
              [111.5125, 2.7428],
              [111.7275, 2.8541],
              [112.1186, 2.9147],
              [112.7373, 3.0707],
              [113.0445, 3.2051],
              [113.4461, 3.7405],
              [113.9238, 4.2435],
              [114.064, 4.5925],
              [114.2239, 4.4778],
              [114.3228, 4.2629],
              [114.4168, 4.2559],
              [114.6086, 4.0244],
              [114.7764, 4.1689],
              [114.8401, 4.3934],
              [114.7465, 4.7177],
              [115.0268, 4.8996],
              [115.0268, 4.6914],
              [115.1068, 4.3904],
              [115.2466, 4.3473],
              [115.2667, 4.6337],
              [115.1397, 4.8996],
              [115.3748, 4.9327],
              [115.5199, 5.0492],
              [115.5819, 5.194],
              [115.4672, 5.2542],
              [115.4188, 5.4129],
              [115.5563, 5.5668],
              [115.7967, 5.536],
              [116.0596, 5.8823],
              [116.1387, 6.1292],
              [116.4947, 6.5217],
              [116.7499, 6.9775],
              [116.8329, 6.952],
              [116.8076, 6.6909],
              [116.9129, 6.6596],
              [117.1282, 6.9691],
              [117.2522, 6.9189],
              [117.294, 6.6768],
              [117.6096, 6.5125],
              [117.6956, 6.2726],
              [117.6157, 6.1965],
              [117.6169, 5.9408],
              [117.8178, 5.9404],
              [118.0037, 6.0533],
              [118.1158, 5.8626],
              [118.4563, 5.7632],
              [118.5947, 5.5917],
              [118.9573, 5.4292],
              [119.2236, 5.4125],
              [119.2499, 5.1985],
              [119.1322, 5.1005],
              [118.6716, 4.9643],
              [118.382, 5.0184],
              [118.2604, 4.989],
              [118.1852, 4.8285],
              [118.3245, 4.6686],
              [118.5626, 4.5019],
              [118.4976, 4.3622],
              [118.008, 4.2502],
              [117.7409, 4.3377],
              [117.5747, 4.1706]
            ]
          ],
          [[[117.1419, 7.1681], [117.0641, 7.2605], [117.2641, 7.3515], [117.2395, 7.1844], [117.1419, 7.1681]]]
        ]
      },
      properties: { name: "Malaysia", id: "MY", Continent: "Asia" },
      id: "MY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [116.6831, 49.8236],
            [116.2432, 49.1704],
            [115.954, 48.6895],
            [115.8209, 48.5772],
            [115.7857, 48.2482],
            [115.5251, 48.1309],
            [115.5577, 47.945],
            [115.8983, 47.687],
            [116.2313, 47.858],
            [116.7604, 47.8701],
            [117.0698, 47.8066],
            [117.3507, 47.6521],
            [117.7686, 47.988],
            [118.1469, 48.0289],
            [118.4985, 47.9841],
            [118.7596, 47.7578],
            [119.0821, 47.6543],
            [119.1229, 47.5583],
            [119.711, 47.15],
            [119.8977, 46.8578],
            [119.8674, 46.6723],
            [119.7066, 46.6062],
            [119.1621, 46.6387],
            [118.844, 46.7602],
            [118.7231, 46.6919],
            [118.3087, 46.7172],
            [117.671, 46.5218],
            [117.4055, 46.5706],
            [117.3335, 46.3621],
            [116.8589, 46.3878],
            [116.5622, 46.2898],
            [116.2133, 45.8871],
            [116.2407, 45.7961],
            [115.6808, 45.4584],
            [114.9192, 45.3783],
            [114.739, 45.4197],
            [114.5171, 45.3648],
            [114.4191, 45.2026],
            [114.0305, 44.9424],
            [113.5872, 44.7458],
            [113.0493, 44.8102],
            [112.5966, 44.9176],
            [112.4112, 45.0585],
            [111.8979, 45.0642],
            [111.6211, 44.827],
            [111.4022, 44.3674],
            [111.52, 44.1921],
            [111.8368, 43.935],
            [111.933, 43.7117],
            [111.7709, 43.6647],
            [111.5472, 43.4964],
            [111.0072, 43.3413],
            [110.4003, 42.7735],
            [109.4432, 42.4558],
            [108.6873, 42.4162],
            [108.1714, 42.4474],
            [106.7704, 42.2888],
            [105.8673, 41.9943],
            [105.1971, 41.7381],
            [105.0503, 41.616],
            [104.4984, 41.6586],
            [104.4984, 41.877],
            [103.711, 41.7513],
            [103.0728, 42.0062],
            [102.157, 42.1578],
            [101.9733, 42.2159],
            [101.7141, 42.4655],
            [101.4956, 42.5389],
            [101.0918, 42.5512],
            [99.9839, 42.6773],
            [99.4676, 42.5683],
            [97.719, 42.7361],
            [97.2057, 42.7897],
            [96.3852, 42.7203],
            [96.2995, 42.9286],
            [95.9128, 43.2064],
            [95.842, 43.3834],
            [95.5257, 43.9539],
            [95.3565, 44.0057],
            [95.3504, 44.2782],
            [95.0497, 44.2593],
            [94.7118, 44.3507],
            [94.3647, 44.5194],
            [94.1994, 44.6452],
            [93.9577, 44.6748],
            [93.6563, 44.9007],
            [93.2946, 44.9833],
            [92.7874, 45.0358],
            [92.4236, 45.009],
            [92.0298, 45.0685],
            [91.5842, 45.0765],
            [91.3122, 45.1182],
            [91.0499, 45.2173],
            [90.8771, 45.196],
            [90.6618, 45.5254],
            [90.7097, 45.7308],
            [91.002, 46.0358],
            [90.9118, 46.2707],
            [91.034, 46.529],
            [90.8701, 46.9547],
            [90.7154, 47.0039],
            [90.4961, 47.2854],
            [90.3133, 47.6761],
            [90.1033, 47.7453],
            [90.0281, 47.8775],
            [89.7781, 47.827],
            [89.561, 48.0041],
            [89.0477, 48.0027],
            [88.5762, 48.2201],
            [88.5168, 48.3845],
            [87.9798, 48.5549],
            [88.0278, 48.7355],
            [87.7434, 48.8817],
            [87.8598, 48.9654],
            [87.8141, 49.1623],
            [88.1156, 49.2564],
            [88.1925, 49.4519],
            [88.6333, 49.4861],
            [88.8315, 49.4484],
            [89.18, 49.5323],
            [89.6542, 49.7176],
            [89.644, 49.903],
            [89.9772, 49.9843],
            [90.0048, 50.0693],
            [90.5168, 50.2132],
            [91.0213, 50.4154],
            [91.3412, 50.4701],
            [91.8044, 50.6936],
            [92.1925, 50.7006],
            [92.3551, 50.8643],
            [92.6266, 50.6883],
            [92.9412, 50.7782],
            [93.1034, 50.6039],
            [94.2512, 50.5564],
            [94.3545, 50.222],
            [94.6147, 50.0238],
            [94.9302, 50.0438],
            [95.0445, 49.9617],
            [95.523, 49.9111],
            [95.8518, 50.0129],
            [96.0653, 49.9985],
            [96.3154, 49.9012],
            [96.9859, 49.8828],
            [97.2087, 49.7307],
            [97.3599, 49.7415],
            [97.5893, 49.9115],
            [97.8538, 49.9467],
            [98.1031, 50.0779],
            [98.2503, 50.3024],
            [98.2793, 50.5334],
            [98.0301, 50.6448],
            [97.8253, 50.9851],
            [97.9471, 51.3483],
            [98.22, 51.5057],
            [98.3527, 51.7177],
            [98.6405, 51.8012],
            [98.8483, 52.0702],
            [99.407, 51.9235],
            [99.7194, 51.8718],
            [99.9215, 51.7555],
            [100.4687, 51.726],
            [101.3814, 51.4527],
            [101.5708, 51.4672],
            [102.1118, 51.3534],
            [102.2885, 50.585],
            [102.6835, 50.387],
            [103.1616, 50.2908],
            [103.3044, 50.2005],
            [103.6328, 50.1388],
            [104.1798, 50.1693],
            [104.4663, 50.3059],
            [105.0943, 50.3899],
            [105.3835, 50.4736],
            [105.8753, 50.4055],
            [106.2181, 50.3044],
            [106.7111, 50.3125],
            [107.2331, 49.9893],
            [107.6308, 49.9831],
            [107.9477, 49.9247],
            [107.9368, 49.6909],
            [108.0338, 49.594],
            [108.6139, 49.3229],
            [109.2366, 49.3347],
            [110.1999, 49.1704],
            [110.4279, 49.2201],
            [110.6314, 49.1374],
            [110.8279, 49.1662],
            [111.3367, 49.3559],
            [112.0794, 49.4242],
            [112.4951, 49.5323],
            [112.6978, 49.5072],
            [113.0559, 49.6162],
            [113.164, 49.7971],
            [113.574, 50.0071],
            [114.2969, 50.2743],
            [114.7429, 50.2336],
            [115.0036, 50.1388],
            [115.2742, 49.9489],
            [115.7176, 49.8805],
            [116.1347, 50.0108],
            [116.5508, 49.9203],
            [116.6831, 49.8236]
          ]
        ]
      },
      properties: { name: "Mongolia", id: "MN", Continent: "Asia" },
      id: "MN"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[98.5539, 11.7447], [98.4348, 11.567], [98.3764, 11.7912], [98.5539, 11.7447]]],
          [[[93.6909, 18.684], [93.4875, 18.8673], [93.7445, 18.8655], [93.6909, 18.684]]],
          [[[93.7151, 19.558], [93.9459, 19.4285], [93.7556, 19.3256], [93.6439, 19.4953], [93.7151, 19.558]]],
          [
            [
              [101.1392, 21.5677],
              [100.9278, 21.3659],
              [100.7565, 21.3128],
              [100.5363, 20.9924],
              [100.5649, 20.825],
              [100.4076, 20.8232],
              [100.2494, 20.7306],
              [100.1223, 20.3165],
              [99.8904, 20.4246],
              [99.6385, 20.3201],
              [99.4588, 20.3628],
              [99.5014, 20.1874],
              [99.3995, 20.0933],
              [99.1969, 20.1153],
              [99.0207, 20.0419],
              [98.9169, 19.773],
              [98.4937, 19.7013],
              [98.2389, 19.6908],
              [98.1115, 19.7624],
              [97.817, 19.46],
              [97.8043, 19.1305],
              [97.7058, 18.9318],
              [97.7537, 18.6212],
              [97.4848, 18.4942],
              [97.4509, 18.3597],
              [97.5995, 18.3026],
              [97.7199, 18.0372],
              [97.6983, 17.8336],
              [97.7929, 17.6812],
              [98.2565, 17.1477],
              [98.4388, 16.9755],
              [98.4784, 16.7324],
              [98.6893, 16.3053],
              [98.818, 16.1809],
              [98.5926, 16.0503],
              [98.5584, 15.7687],
              [98.5653, 15.4039],
              [98.191, 15.204],
              [98.2463, 14.8146],
              [98.5703, 14.3603],
              [98.9336, 14.05],
              [99.1367, 13.7164],
              [99.1762, 13.2331],
              [99.1071, 13.1034],
              [99.2197, 12.7399],
              [99.4052, 12.5474],
              [99.4627, 12.1903],
              [99.6147, 11.7811],
              [99.4781, 11.6124],
              [99.1903, 11.1052],
              [98.757, 10.6613],
              [98.7684, 10.4307],
              [98.7025, 10.1902],
              [98.5628, 10.0346],
              [98.4969, 10.1828],
              [98.5231, 10.3532],
              [98.4652, 10.6759],
              [98.6752, 10.9869],
              [98.7447, 11.2405],
              [98.7416, 11.5917],
              [98.8045, 11.7793],
              [98.6366, 11.7385],
              [98.6898, 11.9568],
              [98.6964, 12.2254],
              [98.6243, 12.4407],
              [98.6647, 12.5401],
              [98.576, 13.1618],
              [98.4216, 13.484],
              [98.2454, 13.7332],
              [98.111, 13.7129],
              [98.0727, 13.9863],
              [98.1, 14.1617],
              [97.9984, 14.3352],
              [97.9097, 14.6524],
              [97.8121, 14.859],
              [97.7993, 15.1851],
              [97.7106, 15.8754],
              [97.5845, 16.0191],
              [97.6408, 16.2538],
              [97.6192, 16.5372],
              [97.3758, 16.5228],
              [97.2673, 16.7429],
              [97.1781, 17.0619],
              [96.9701, 17.3173],
              [96.8511, 17.2026],
              [96.9086, 17.0311],
              [96.8107, 16.7785],
              [96.6226, 16.5641],
              [96.3645, 16.5205],
              [96.3241, 16.4446],
              [96.0126, 16.2538],
              [95.7634, 16.1691],
              [95.6795, 15.9769],
              [95.3898, 15.723],
              [95.1772, 15.8257],
              [94.943, 15.8184],
              [94.8911, 15.9792],
              [94.6617, 15.9044],
              [94.6811, 16.1334],
              [94.5875, 16.289],
              [94.4415, 16.0943],
              [94.2991, 16.0072],
              [94.2144, 16.1269],
              [94.2711, 16.5175],
              [94.3532, 16.6401],
              [94.4732, 17.1353],
              [94.5646, 17.3084],
              [94.5887, 17.5695],
              [94.4943, 17.8249],
              [94.431, 18.2014],
              [94.2658, 18.5074],
              [94.2521, 18.6089],
              [94.0702, 18.8936],
              [93.9291, 18.8997],
              [93.7054, 19.0267],
              [93.4928, 19.3696],
              [93.5785, 19.4007],
              [93.825, 19.2386],
              [93.9981, 19.4408],
              [93.8395, 19.5339],
              [93.7393, 19.6969],
              [93.6114, 19.776],
              [93.5819, 19.9097],
              [93.3627, 20.0581],
              [93.0019, 20.0748],
              [92.7914, 20.2116],
              [92.8912, 20.3402],
              [92.7356, 20.5627],
              [92.6077, 20.47],
              [92.3243, 20.7917],
              [92.1797, 21.2934],
              [92.3304, 21.4398],
              [92.5391, 21.3199],
              [92.5936, 21.467],
              [92.5751, 21.9782],
              [92.689, 22.131],
              [92.8542, 22.0102],
              [92.9641, 22.004],
              [93.1619, 22.3604],
              [93.0788, 22.7182],
              [93.164, 23.032],
              [93.3077, 23.0302],
              [93.3662, 23.1321],
              [93.415, 23.6824],
              [93.3073, 24.0221],
              [93.4941, 23.9733],
              [93.6835, 24.0062],
              [94.1277, 23.8762],
              [94.293, 24.3218],
              [94.5839, 24.7674],
              [94.7074, 25.0486],
              [94.5545, 25.2433],
              [94.6226, 25.4098],
              [94.7861, 25.5192],
              [94.9921, 25.7703],
              [95.015, 25.913],
              [95.1324, 26.0414],
              [95.0686, 26.1912],
              [95.0599, 26.4738],
              [95.1288, 26.5972],
              [95.4637, 26.7563],
              [95.905, 27.0464],
              [96.0614, 27.2168],
              [96.666, 27.339],
              [96.8805, 27.1778],
              [97.1038, 27.1632],
              [96.902, 27.4393],
              [96.8999, 27.6441],
              [97.3353, 27.938],
              [97.3227, 28.2179],
              [97.538, 28.5102],
              [97.8649, 28.3634],
              [98.0987, 28.1419],
              [98.1304, 27.9675],
              [98.2991, 27.5499],
              [98.5047, 27.6475],
              [98.651, 27.5725],
              [98.6748, 27.191],
              [98.716, 27.0446],
              [98.732, 26.5837],
              [98.6546, 25.9178],
              [98.4656, 25.789],
              [98.3338, 25.5865],
              [98.1726, 25.5945],
              [98.0996, 25.4155],
              [97.9181, 25.2358],
              [97.8196, 25.252],
              [97.715, 25.0346],
              [97.7238, 24.8421],
              [97.5836, 24.7749],
              [97.5313, 24.4914],
              [97.6666, 24.3802],
              [97.6905, 24.1307],
              [97.5683, 23.9886],
              [97.686, 23.8981],
              [98.0164, 24.0655],
              [98.2125, 24.1108],
              [98.7639, 24.1161],
              [98.6809, 23.8415],
              [98.8321, 23.6247],
              [98.7979, 23.5202],
              [98.8852, 23.3075],
              [98.8638, 23.1914],
              [99.3411, 23.0961],
              [99.5071, 22.959],
              [99.3853, 22.8249],
              [99.3374, 22.498],
              [99.243, 22.3702],
              [99.1929, 22.1258],
              [99.5924, 22.0893],
              [99.918, 22.0277],
              [99.9255, 21.8209],
              [100.0893, 21.558],
              [100.2147, 21.4626],
              [100.3508, 21.5009],
              [100.5315, 21.4578],
              [100.6769, 21.5048],
              [100.8352, 21.6551],
              [101.1207, 21.7461],
              [101.1392, 21.5677]
            ]
          ]
        ]
      },
      properties: { name: "Myanmar", id: "MM", Continent: "Asia" },
      id: "MM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[73.5121, 4.1644], [73.473, 4.1706], [73.5042, 4.2344], [73.5284, 4.2435], [73.5121, 4.1644]]]
      },
      properties: { name: "Maldives", id: "MV", Continent: "Asia" },
      id: "MV"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[113.4786, 22.1957], [113.4944, 22.2414], [113.5468, 22.2243], [113.4786, 22.1957]]]
      },
      properties: { name: "Macau", id: "MO", Continent: "Asia" },
      id: "MO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [79.9822, 9.8127],
            [80.2529, 9.7965],
            [80.376, 9.6422],
            [80.7112, 9.3662],
            [81.1986, 8.6618],
            [81.2267, 8.5054],
            [81.3731, 8.4315],
            [81.4359, 8.1191],
            [81.6653, 7.7825],
            [81.6829, 7.6845],
            [81.8741, 7.2886],
            [81.8613, 6.9014],
            [81.7677, 6.614],
            [81.6376, 6.425],
            [81.3801, 6.2409],
            [80.724, 5.9789],
            [80.4959, 5.9491],
            [80.2674, 6.0097],
            [80.0956, 6.1529],
            [79.8592, 6.8293],
            [79.7924, 7.5857],
            [79.708, 8.0659],
            [79.8091, 8.0502],
            [79.8508, 8.4114],
            [79.9418, 8.6917],
            [79.929, 8.8992],
            [80.0648, 9.0956],
            [80.1184, 9.3268],
            [80.0859, 9.5776],
            [79.9822, 9.8127]
          ]
        ]
      },
      properties: { name: "Sri Lanka", id: "LK", Continent: "Asia" },
      id: "LK"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.8691, 33.432],
            [35.5328, 33.2505],
            [35.4112, 33.0756],
            [35.1088, 33.0835],
            [35.6115, 34.0319],
            [35.648, 34.2481],
            [35.9763, 34.6292],
            [36.4328, 34.6137],
            [36.3265, 34.5131],
            [36.5045, 34.4322],
            [36.5853, 34.2213],
            [36.2825, 33.894],
            [36.0922, 33.8315],
            [35.9424, 33.6676],
            [36.0224, 33.5626],
            [35.8691, 33.432]
          ]
        ]
      },
      properties: { name: "Lebanon", id: "LB", Continent: "Middle East" },
      id: "LB"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [102.1275, 22.3789],
            [102.4875, 21.9579],
            [102.5824, 21.9043],
            [102.6953, 21.6622],
            [102.8452, 21.7351],
            [102.9489, 21.5698],
            [102.8509, 21.2658],
            [103.1044, 20.8914],
            [103.6349, 20.6971],
            [104.0519, 20.9411],
            [104.1952, 20.9137],
            [104.5833, 20.6467],
            [104.3921, 20.4246],
            [104.6188, 20.3746],
            [104.6989, 20.2054],
            [104.8886, 20.1689],
            [104.9283, 20.0182],
            [104.7436, 19.7546],
            [104.5463, 19.6104],
            [104.2603, 19.6855],
            [104.0317, 19.6754],
            [104.0629, 19.4825],
            [103.8964, 19.34],
            [104.7164, 18.8035],
            [105.115, 18.6787],
            [105.0856, 18.4502],
            [105.3333, 18.1896],
            [105.4582, 18.1544],
            [105.5887, 17.9834],
            [105.6915, 17.7378],
            [106.0062, 17.4153],
            [106.2694, 17.2167],
            [106.5024, 16.9543],
            [106.5462, 16.6507],
            [106.6961, 16.4585],
            [106.8324, 16.5263],
            [106.9303, 16.3531],
            [107.2173, 16.1365],
            [107.3966, 16.0428],
            [107.3606, 15.9217],
            [107.1888, 15.8385],
            [107.1896, 15.7472],
            [107.3391, 15.5604],
            [107.5644, 15.3917],
            [107.6533, 15.2549],
            [107.4805, 14.9799],
            [107.5197, 14.7051],
            [107.4146, 14.5628],
            [107.2924, 14.5922],
            [107.1092, 14.4164],
            [106.8201, 14.3149],
            [106.5015, 14.5781],
            [106.3547, 14.4547],
            [105.9789, 14.343],
            [106.1248, 14.0491],
            [106.0669, 13.9211],
            [105.9047, 13.9247],
            [105.7394, 14.0846],
            [105.5316, 14.1559],
            [105.3501, 14.1097],
            [105.183, 14.3462],
            [105.4753, 14.5302],
            [105.547, 14.9324],
            [105.4907, 15.1275],
            [105.5132, 15.3609],
            [105.6156, 15.4879],
            [105.6225, 15.6996],
            [105.3989, 15.8298],
            [105.3308, 16.038],
            [105.0469, 16.1602],
            [104.9497, 16.3399],
            [104.8193, 16.466],
            [104.7507, 16.6476],
            [104.7591, 17.0774],
            [104.8161, 17.3006],
            [104.7397, 17.4614],
            [104.4282, 17.6992],
            [104.0489, 18.2165],
            [103.9495, 18.3193],
            [103.7922, 18.3166],
            [103.4882, 18.4181],
            [103.2522, 18.3733],
            [103.2631, 18.2785],
            [103.0912, 18.1382],
            [103.0513, 18.0283],
            [102.7172, 17.8922],
            [102.4584, 17.9843],
            [102.1013, 18.2103],
            [101.9474, 18.081],
            [101.7747, 18.0331],
            [101.6876, 17.8895],
            [101.4139, 17.7185],
            [101.1054, 17.4794],
            [100.9086, 17.5836],
            [101.1134, 18.0331],
            [101.1375, 18.2867],
            [101.0505, 18.4071],
            [101.1656, 18.6185],
            [101.2865, 18.9772],
            [101.1977, 19.3277],
            [101.2206, 19.4864],
            [101.1547, 19.5797],
            [100.9665, 19.6107],
            [100.6256, 19.5001],
            [100.4204, 19.6447],
            [100.3978, 19.7562],
            [100.5429, 20.0885],
            [100.5191, 20.1776],
            [100.318, 20.386],
            [100.1223, 20.3165],
            [100.2494, 20.7306],
            [100.4076, 20.8232],
            [100.5649, 20.825],
            [100.5363, 20.9924],
            [100.7565, 21.3128],
            [100.9278, 21.3659],
            [101.1392, 21.5677],
            [101.224, 21.2236],
            [101.2817, 21.184],
            [101.5426, 21.2341],
            [101.7047, 21.1502],
            [101.7439, 21.7782],
            [101.6028, 21.9896],
            [101.5241, 22.2537],
            [101.6714, 22.4624],
            [101.8415, 22.3882],
            [102.0247, 22.4396],
            [102.1275, 22.3789]
          ]
        ]
      },
      properties: { name: "Lao People's Democratic Republic", id: "LA", Continent: "Asia" },
      id: "LA"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [48.4422, 28.5428],
              [47.6714, 28.533],
              [47.5831, 28.6279],
              [47.4332, 28.9896],
              [46.5315, 29.0965],
              [46.9054, 29.5376],
              [47.1142, 29.9612],
              [47.3313, 30.0795],
              [47.6728, 30.0957],
              [47.9786, 29.9828],
              [48.0054, 29.836],
              [48.1359, 29.618],
              [47.9698, 29.6167],
              [47.7255, 29.4168],
              [48.0511, 29.3553],
              [48.1834, 28.9791],
              [48.3394, 28.7633],
              [48.4422, 28.5428]
            ]
          ],
          [
            [
              [48.2757, 29.6242],
              [48.1795, 29.6114],
              [48.0814, 29.7986],
              [48.1587, 29.9594],
              [48.3482, 29.7829],
              [48.2757, 29.6242]
            ]
          ]
        ]
      },
      properties: { name: "Kuwait", id: "KW", Continent: "Middle East" },
      id: "KW"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [128.3744, 38.6233],
              [128.6191, 38.1764],
              [129.0515, 37.6776],
              [129.335, 37.2747],
              [129.4184, 37.0589],
              [129.4734, 36.742],
              [129.3926, 36.3227],
              [129.4036, 36.0521],
              [129.5617, 35.9475],
              [129.4189, 35.4979],
              [129.2141, 35.182],
              [128.9799, 35.1015],
              [128.511, 35.101],
              [128.4187, 35.0159],
              [128.4442, 34.8704],
              [128.0944, 34.9336],
              [128.0365, 35.0219],
              [127.7147, 34.9544],
              [127.7424, 34.7825],
              [127.6322, 34.6903],
              [127.5236, 34.8401],
              [127.3895, 34.7434],
              [127.4792, 34.6251],
              [127.3245, 34.463],
              [127.0309, 34.6067],
              [126.8974, 34.4388],
              [126.7545, 34.5118],
              [126.5084, 34.351],
              [126.4817, 34.4938],
              [126.3326, 34.5895],
              [126.421, 34.8233],
              [126.2913, 35.1543],
              [126.4927, 35.5014],
              [126.4881, 35.6469],
              [126.7175, 35.7691],
              [126.6934, 36.0143],
              [126.5405, 36.1663],
              [126.5485, 36.4775],
              [126.3889, 36.651],
              [126.1807, 36.6915],
              [126.217, 36.8708],
              [126.5775, 37.0193],
              [126.8687, 36.9754],
              [126.7462, 37.1933],
              [126.7905, 37.2953],
              [126.6504, 37.4469],
              [126.5634, 37.7163],
              [126.6341, 37.7817],
              [126.6667, 37.9171],
              [127.0094, 38.2406],
              [127.1698, 38.3047],
              [128.039, 38.3086],
              [128.1687, 38.3592],
              [128.3744, 38.6233]
            ]
          ],
          [[[128.7413, 34.7988], [128.6463, 34.7368], [128.4894, 34.8652], [128.722, 35.0137], [128.7413, 34.7988]]],
          [[[126.2338, 34.3703], [126.123, 34.4441], [126.2473, 34.5633], [126.3801, 34.4977], [126.2338, 34.3703]]],
          [
            [
              [126.3269, 33.2238],
              [126.1657, 33.3121],
              [126.3374, 33.4602],
              [126.6956, 33.549],
              [126.9013, 33.5152],
              [126.8728, 33.341],
              [126.5814, 33.2382],
              [126.3269, 33.2238]
            ]
          ]
        ]
      },
      properties: { name: "South Korea", id: "KR", Continent: "Asia" },
      id: "KR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [104.4264, 10.4113],
            [104.2624, 10.5413],
            [103.9372, 10.5867],
            [103.6622, 10.5089],
            [103.532, 10.6047],
            [103.6807, 10.7584],
            [103.7219, 10.8902],
            [103.6542, 11.059],
            [103.5325, 11.147],
            [103.3537, 10.9215],
            [103.1528, 10.9135],
            [103.0912, 11.2111],
            [103.1256, 11.4608],
            [102.934, 11.7064],
            [102.7366, 12.0895],
            [102.7063, 12.2557],
            [102.7555, 12.4263],
            [102.4994, 12.6696],
            [102.462, 13.0151],
            [102.3311, 13.288],
            [102.3195, 13.5398],
            [102.5473, 13.5854],
            [102.5446, 13.6602],
            [102.7293, 13.8422],
            [102.9094, 14.137],
            [103.1995, 14.3325],
            [103.6002, 14.4209],
            [103.8182, 14.3624],
            [104.2277, 14.3959],
            [104.4118, 14.3694],
            [104.7787, 14.4278],
            [104.97, 14.366],
            [105.074, 14.2276],
            [105.183, 14.3462],
            [105.3501, 14.1097],
            [105.5316, 14.1559],
            [105.7394, 14.0846],
            [105.9047, 13.9247],
            [106.0669, 13.9211],
            [106.1248, 14.0491],
            [105.9789, 14.343],
            [106.3547, 14.4547],
            [106.5015, 14.5781],
            [106.8201, 14.3149],
            [107.1092, 14.4164],
            [107.2924, 14.5922],
            [107.4146, 14.5628],
            [107.5197, 14.7051],
            [107.4485, 14.4513],
            [107.3645, 14.3685],
            [107.3311, 14.1264],
            [107.6057, 13.4379],
            [107.4757, 13.0299],
            [107.5553, 12.5401],
            [107.506, 12.3643],
            [107.1589, 12.2767],
            [106.9303, 12.0777],
            [106.7001, 11.9798],
            [106.4135, 11.9485],
            [106.4105, 11.7385],
            [106.3401, 11.6818],
            [106.0058, 11.7582],
            [105.8514, 11.6352],
            [105.8559, 11.2941],
            [106.1679, 11.0125],
            [106.0988, 10.7976],
            [105.7552, 10.9901],
            [105.3866, 10.9399],
            [105.3149, 10.8455],
            [105.0222, 10.8868],
            [105.0614, 10.7338],
            [104.8505, 10.5347],
            [104.5639, 10.5158],
            [104.4264, 10.4113]
          ]
        ]
      },
      properties: { name: "Cambodia", id: "KH", Continent: "Asia" },
      id: "KH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [80.2094, 42.1899],
            [80.216, 42.0322],
            [79.8407, 41.9961],
            [79.766, 41.8985],
            [79.2936, 41.7829],
            [78.7425, 41.5601],
            [78.3624, 41.3717],
            [78.3461, 41.2811],
            [78.1233, 41.0759],
            [77.8152, 41.0557],
            [77.5815, 40.9929],
            [76.9869, 41.0394],
            [76.8238, 40.9823],
            [76.6397, 40.7424],
            [76.4802, 40.4492],
            [76.2583, 40.4308],
            [75.872, 40.3033],
            [75.6563, 40.3293],
            [75.5837, 40.6052],
            [75.241, 40.4805],
            [75.0045, 40.4492],
            [74.6798, 40.3104],
            [74.4117, 40.1373],
            [73.9916, 40.0432],
            [73.8351, 39.8001],
            [73.9147, 39.6064],
            [73.8228, 39.4891],
            [73.6317, 39.449],
            [73.4704, 39.4605],
            [73.2353, 39.3744],
            [72.6398, 39.3858],
            [72.3581, 39.337],
            [72.2303, 39.2073],
            [72.0426, 39.3519],
            [71.8062, 39.2759],
            [71.7319, 39.4231],
            [71.5461, 39.453],
            [71.4046, 39.598],
            [71.0648, 39.4934],
            [71.005, 39.4121],
            [70.7335, 39.4135],
            [70.6078, 39.5642],
            [70.2448, 39.5427],
            [69.5988, 39.5738],
            [69.2978, 39.5246],
            [69.2288, 39.761],
            [69.307, 39.9685],
            [69.4318, 39.9096],
            [69.5303, 40.0972],
            [69.9667, 40.2023],
            [70.4514, 40.0489],
            [70.9462, 40.1873],
            [70.9581, 40.2392],
            [71.3048, 40.2871],
            [71.6924, 40.1526],
            [71.9556, 40.2585],
            [72.131, 40.4387],
            [72.3577, 40.4013],
            [72.4021, 40.578],
            [72.5673, 40.5244],
            [72.7738, 40.6505],
            [73.1131, 40.7858],
            [72.9901, 40.8602],
            [72.6583, 40.8698],
            [72.3643, 41.0434],
            [72.1876, 41.0263],
            [72.1644, 41.1739],
            [71.8787, 41.195],
            [71.7926, 41.4129],
            [71.4208, 41.3418],
            [71.4085, 41.1361],
            [71.1105, 41.1524],
            [70.7827, 41.2627],
            [70.646, 41.46],
            [70.4715, 41.4125],
            [70.1811, 41.5716],
            [70.4549, 41.725],
            [70.842, 42.0194],
            [71.2287, 42.1626],
            [70.9466, 42.2484],
            [70.8926, 42.3398],
            [71.0226, 42.5353],
            [71.2564, 42.7335],
            [71.8167, 42.8219],
            [72.2759, 42.7577],
            [72.7532, 42.6377],
            [72.8551, 42.5608],
            [73.2832, 42.5041],
            [73.422, 42.5934],
            [73.5566, 43.0029],
            [73.6119, 43.0482],
            [74.2091, 43.2402],
            [74.6222, 43.0561],
            [75.0476, 42.9044],
            [75.6356, 42.8144],
            [75.7898, 42.933],
            [76.5092, 42.9189],
            [76.9442, 42.9717],
            [77.2357, 42.9124],
            [77.8017, 42.8952],
            [78.0227, 42.8574],
            [78.5241, 42.8645],
            [78.7917, 42.7911],
            [79.1644, 42.759],
            [79.2031, 42.6663],
            [79.4901, 42.4576],
            [79.9212, 42.4131],
            [80.2094, 42.1899]
          ],
          [[71.2059, 39.8925], [71.2287, 40.048], [71.0806, 40.0797], [71.0117, 39.8951], [71.2059, 39.8925]]
        ]
      },
      properties: { name: "Kyrgyzstan", id: "KG", Continent: "Asia" },
      id: "KG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.3228, 49.0858],
            [86.886, 49.0906],
            [86.7533, 49.0087],
            [86.7177, 48.6971],
            [86.5494, 48.5288],
            [86.2655, 48.4546],
            [85.7492, 48.3849],
            [85.6265, 48.2038],
            [85.5259, 47.9157],
            [85.6564, 47.2549],
            [85.4846, 47.0634],
            [85.2337, 47.0364],
            [84.7859, 46.8307],
            [84.6668, 46.9725],
            [84.339, 46.996],
            [84.016, 46.9707],
            [83.634, 47.043],
            [83.1929, 47.1865],
            [83.0294, 47.1859],
            [83.0043, 47.0336],
            [82.6923, 46.3865],
            [82.5552, 46.1588],
            [82.3262, 45.5201],
            [82.5824, 45.4427],
            [82.6212, 45.2931],
            [82.4788, 45.1237],
            [82.2665, 45.219],
            [81.9892, 45.1617],
            [81.6021, 45.3109],
            [80.8532, 45.1294],
            [80.6348, 45.1263],
            [80.0591, 45.0064],
            [79.8719, 44.8839],
            [79.9971, 44.7974],
            [80.2551, 44.808],
            [80.4555, 44.7462],
            [80.3364, 44.4381],
            [80.3553, 44.0972],
            [80.4959, 43.8924],
            [80.7038, 43.427],
            [80.6678, 43.3101],
            [80.7856, 43.1615],
            [80.39, 43.0429],
            [80.5438, 42.9115],
            [80.2503, 42.7972],
            [80.1651, 42.6654],
            [80.259, 42.2351],
            [80.2094, 42.1899],
            [79.9212, 42.4131],
            [79.4901, 42.4576],
            [79.2031, 42.6663],
            [79.1644, 42.759],
            [78.7917, 42.7911],
            [78.5241, 42.8645],
            [78.0227, 42.8574],
            [77.8017, 42.8952],
            [77.2357, 42.9124],
            [76.9442, 42.9717],
            [76.5092, 42.9189],
            [75.7898, 42.933],
            [75.6356, 42.8144],
            [75.0476, 42.9044],
            [74.6222, 43.0561],
            [74.2091, 43.2402],
            [73.6119, 43.0482],
            [73.5566, 43.0029],
            [73.422, 42.5934],
            [73.2832, 42.5041],
            [72.8551, 42.5608],
            [72.7532, 42.6377],
            [72.2759, 42.7577],
            [71.8167, 42.8219],
            [71.2564, 42.7335],
            [71.0226, 42.5353],
            [70.8926, 42.3398],
            [70.9466, 42.2484],
            [70.715, 42.1688],
            [70.6135, 42.0545],
            [70.4158, 42.0787],
            [70.0954, 41.8208],
            [69.6638, 41.6718],
            [69.3685, 41.4903],
            [69.0653, 41.3669],
            [68.9871, 41.2051],
            [68.7371, 41.042],
            [68.5841, 40.8764],
            [68.6008, 40.6597],
            [68.2919, 40.6562],
            [68.0476, 40.8096],
            [68.113, 41.0289],
            [67.9359, 41.1963],
            [66.814, 41.1423],
            [66.6685, 41.2706],
            [66.5381, 41.7412],
            [66.4985, 41.9952],
            [66.0094, 42.0049],
            [66.1003, 42.9906],
            [65.8028, 42.8772],
            [65.4961, 43.3109],
            [65.2702, 43.4177],
            [64.9054, 43.7148],
            [64.4432, 43.5514],
            [63.207, 43.6282],
            [62.0719, 43.4894],
            [61.9906, 43.4924],
            [61.1609, 44.1688],
            [61.008, 44.3938],
            [59.774, 44.9811],
            [58.5549, 45.5555],
            [57.4774, 45.3375],
            [55.9757, 44.995],
            [55.9766, 43.6177],
            [55.9771, 42.4699],
            [55.9775, 41.322],
            [55.5451, 41.2627],
            [55.2493, 41.4578],
            [54.9527, 41.8098],
            [54.9035, 41.9192],
            [54.6781, 42.0779],
            [54.1213, 42.3349],
            [53.6854, 42.2967],
            [53.2503, 42.2057],
            [52.8706, 42.0607],
            [52.494, 41.7803],
            [52.4623, 42.1007],
            [52.6184, 42.4281],
            [52.5968, 42.7603],
            [52.1842, 42.8684],
            [51.844, 42.9101],
            [51.6164, 43.1585],
            [51.2952, 43.1739],
            [51.3017, 43.4824],
            [51.1396, 43.6484],
            [50.8307, 44.193],
            [50.6847, 44.265],
            [50.331, 44.3257],
            [50.2528, 44.4061],
            [50.2976, 44.5817],
            [50.4092, 44.6243],
            [50.8601, 44.6286],
            [51.1774, 44.5014],
            [51.3765, 44.5414],
            [51.0578, 44.8115],
            [51.0403, 44.9803],
            [51.2499, 45.1219],
            [51.4156, 45.3579],
            [51.7324, 45.3992],
            [52.5309, 45.3988],
            [53.0789, 45.3074],
            [53.0859, 45.4072],
            [52.7739, 45.5728],
            [53.0415, 45.9679],
            [53.1351, 46.1915],
            [53.0785, 46.5475],
            [53.1703, 46.6693],
            [53.0697, 46.856],
            [52.9159, 46.9542],
            [52.483, 46.9907],
            [52.189, 46.8397],
            [51.7447, 46.9338],
            [51.6498, 47.0182],
            [51.1782, 47.11],
            [50.9203, 47.0408],
            [50.5828, 46.8824],
            [50.4193, 46.8797],
            [50.0001, 46.6341],
            [49.3475, 46.5192],
            [49.184, 46.3486],
            [48.6101, 46.5662],
            [48.9595, 46.7745],
            [48.5525, 47.321],
            [48.11, 47.7453],
            [47.482, 47.804],
            [47.2922, 47.7408],
            [47.0936, 47.9476],
            [47.119, 48.1269],
            [47.0043, 48.2845],
            [46.6611, 48.4124],
            [46.6093, 48.5741],
            [46.7029, 48.8054],
            [47.0144, 49.0986],
            [47.018, 49.1998],
            [46.8022, 49.3671],
            [46.8897, 49.6971],
            [46.9916, 49.8527],
            [47.2953, 50.0585],
            [47.3265, 50.2734],
            [47.5036, 50.4028],
            [47.7062, 50.378],
            [48.2247, 49.9317],
            [48.4343, 49.8285],
            [48.7591, 49.9283],
            [48.843, 50.013],
            [48.7007, 50.3538],
            [48.6549, 50.6199],
            [48.8087, 50.6012],
            [49.3233, 50.8517],
            [49.4978, 51.0836],
            [49.8225, 51.1319],
            [50.2466, 51.2895],
            [50.7942, 51.7291],
            [51.1633, 51.6474],
            [51.3443, 51.4753],
            [51.609, 51.4839],
            [52.007, 51.6729],
            [52.3309, 51.6812],
            [52.5709, 51.4817],
            [53.3382, 51.4824],
            [53.6884, 51.2519],
            [54.0417, 51.1152],
            [54.4214, 50.7804],
            [54.7269, 50.9981],
            [55.1953, 50.7446],
            [55.5424, 50.6017],
            [55.7978, 50.6021],
            [56.0496, 50.7135],
            [56.1441, 50.8447],
            [56.4917, 51.0196],
            [56.62, 50.9807],
            [57.0116, 51.0651],
            [57.4422, 50.8889],
            [57.6536, 50.9252],
            [57.8386, 51.0917],
            [58.3593, 51.0638],
            [58.5474, 50.971],
            [58.8837, 50.6944],
            [59.4523, 50.6204],
            [59.523, 50.4929],
            [59.8122, 50.582],
            [59.955, 50.7993],
            [60.1124, 50.834],
            [60.2881, 50.7043],
            [60.6379, 50.6639],
            [60.9424, 50.6955],
            [61.3894, 50.861],
            [61.5849, 51.2299],
            [61.363, 51.4419],
            [60.4648, 51.6514],
            [60.3875, 51.773],
            [60.0675, 51.8908],
            [60.2337, 52.0247],
            [60.4257, 52.1255],
            [60.6705, 52.1508],
            [60.9376, 52.2804],
            [60.9798, 52.3949],
            [60.8212, 52.5698],
            [60.8023, 52.7447],
            [61.0475, 52.9725],
            [61.4008, 52.9961],
            [61.9739, 52.9438],
            [62.0148, 53.1079],
            [61.6596, 53.2283],
            [61.1995, 53.2872],
            [61.2479, 53.5508],
            [60.9798, 53.6216],
            [61.1134, 53.7534],
            [61.1433, 53.9637],
            [61.334, 54.0492],
            [61.9286, 53.9464],
            [62.0402, 54.0027],
            [62.499, 54.0132],
            [62.6326, 54.0694],
            [63.0738, 54.1052],
            [63.1911, 54.171],
            [64.0037, 54.2672],
            [64.4612, 54.3841],
            [65.1577, 54.3643],
            [65.2377, 54.5161],
            [65.4768, 54.6234],
            [66.2225, 54.6673],
            [67.6933, 54.8723],
            [68.0739, 54.9595],
            [68.2062, 55.1608],
            [68.5248, 55.2048],
            [68.9774, 55.3895],
            [69.4929, 55.3568],
            [70.1824, 55.1626],
            [70.4865, 55.2823],
            [70.7383, 55.3051],
            [70.9102, 55.1281],
            [71.1857, 54.5994],
            [71.0934, 54.2123],
            [71.3364, 54.1584],
            [72.0048, 54.2056],
            [72.2694, 54.2722],
            [72.3872, 54.1231],
            [72.9141, 54.1072],
            [73.1193, 53.9809],
            [73.6791, 53.9295],
            [73.3992, 53.8115],
            [73.2858, 53.5984],
            [73.4699, 53.4689],
            [73.6431, 53.5761],
            [74.069, 53.6115],
            [74.4029, 53.5045],
            [74.4517, 53.6473],
            [74.834, 53.8257],
            [75.0524, 53.8269],
            [75.3768, 53.9701],
            [75.4374, 54.0899],
            [75.6571, 54.1059],
            [76.2662, 54.312],
            [76.7887, 54.3219],
            [76.6543, 54.1452],
            [76.4222, 54.1136],
            [76.576, 53.9425],
            [77.7041, 53.379],
            [77.8601, 53.2692],
            [78.4758, 52.6386],
            [79.4685, 51.4931],
            [79.9862, 50.7747],
            [80.2199, 50.9117],
            [80.4234, 50.9465],
            [80.4485, 51.1833],
            [80.6502, 51.2774],
            [80.8769, 51.2815],
            [81.1274, 51.1912],
            [81.1248, 50.9465],
            [81.388, 50.9565],
            [81.4658, 50.7397],
            [81.9334, 50.7662],
            [82.0982, 50.7109],
            [82.4937, 50.7276],
            [82.7609, 50.8933],
            [83.0193, 50.8972],
            [83.3572, 50.9946],
            [83.9452, 50.7747],
            [84.1764, 50.5204],
            [84.2577, 50.2884],
            [84.9894, 50.0614],
            [85.0008, 49.8942],
            [85.2099, 49.6648],
            [86.1807, 49.4993],
            [86.6101, 49.7691],
            [86.6144, 49.6098],
            [86.9533, 49.322],
            [87.2336, 49.2161],
            [87.3228, 49.0858]
          ]
        ]
      },
      properties: { name: "Kazakhstan", id: "KZ", Continent: "Asia" },
      id: "KZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[128.2588, 26.6526], [128.127, 26.552], [127.9261, 26.5555], [128.2166, 26.7972], [128.2588, 26.6526]]],
          [[[129.4528, 28.2092], [129.3227, 28.3595], [129.6417, 28.4108], [129.4528, 28.2092]]],
          [
            [
              [130.6225, 30.2631],
              [130.4455, 30.2649],
              [130.3879, 30.3879],
              [130.4973, 30.4654],
              [130.6736, 30.3669],
              [130.6225, 30.2631]
            ]
          ],
          [
            [
              [130.0825, 32.2293],
              [129.9792, 32.3461],
              [130.0095, 32.5215],
              [130.1678, 32.5408],
              [130.1999, 32.3404],
              [130.0825, 32.2293]
            ]
          ],
          [[[128.6652, 32.7839], [128.8388, 32.7632], [128.8946, 32.6521], [128.6574, 32.6283], [128.6652, 32.7839]]],
          [
            [
              [131.1746, 33.6026],
              [131.4184, 33.5841],
              [131.5828, 33.6527],
              [131.7107, 33.5024],
              [131.5376, 33.2739],
              [131.8965, 33.255],
              [131.8478, 33.1182],
              [131.9492, 33.0471],
              [131.9766, 32.8436],
              [131.7322, 32.5931],
              [131.5648, 32.2231],
              [131.4606, 31.8834],
              [131.4598, 31.6708],
              [131.3376, 31.4049],
              [131.1399, 31.4418],
              [131.0981, 31.2558],
              [130.6859, 31.015],
              [130.7896, 31.269],
              [130.6551, 31.7187],
              [130.5281, 31.4599],
              [130.6446, 31.2672],
              [130.5887, 31.1781],
              [130.2006, 31.2919],
              [130.3219, 31.6013],
              [130.188, 31.7687],
              [130.1946, 32.0909],
              [130.4622, 32.3048],
              [130.6405, 32.619],
              [130.3817, 33.0924],
              [130.2376, 33.1777],
              [130.1269, 33.1047],
              [130.1748, 32.8516],
              [129.8268, 32.7255],
              [129.8971, 33.022],
              [129.5801, 33.2361],
              [129.6597, 33.3647],
              [130.365, 33.6342],
              [130.4838, 33.8347],
              [130.7153, 33.9277],
              [130.9531, 33.8719],
              [131.0581, 33.6728],
              [131.1746, 33.6026]
            ]
          ],
          [
            [
              [134.3571, 34.2565],
              [134.6374, 34.2265],
              [134.6353, 34.0443],
              [134.739, 33.8206],
              [134.3773, 33.6083],
              [134.2428, 33.4395],
              [134.1813, 33.2475],
              [133.9585, 33.4484],
              [133.6319, 33.5111],
              [133.2862, 33.3599],
              [132.9772, 32.8418],
              [132.87, 32.7545],
              [132.692, 32.7759],
              [132.7091, 32.9025],
              [132.4951, 32.9166],
              [132.5113, 33.2931],
              [132.405, 33.331],
              [132.3659, 33.5125],
              [132.6427, 33.6899],
              [132.7843, 33.9928],
              [132.99, 34.0881],
              [133.1341, 33.9273],
              [133.5822, 34.0169],
              [133.7067, 34.2372],
              [134.0759, 34.3585],
              [134.3571, 34.2565]
            ]
          ],
          [[[134.9331, 34.2881], [134.8246, 34.2028], [134.6677, 34.2938], [134.8344, 34.4727], [134.9331, 34.2881]]],
          [[[129.3856, 34.3535], [129.2668, 34.3703], [129.3258, 34.6071], [129.4752, 34.5403], [129.3856, 34.3535]]],
          [[[138.3442, 37.8222], [138.2502, 38.0784], [138.4616, 38.2915], [138.4972, 37.904], [138.3442, 37.8222]]],
          [
            [
              [141.2297, 41.3726],
              [141.4555, 41.4046],
              [141.4001, 41.0961],
              [141.4626, 40.6109],
              [141.7969, 40.2914],
              [141.978, 39.8446],
              [141.9772, 39.4289],
              [141.9007, 39.1116],
              [141.6585, 38.9749],
              [141.5465, 38.7626],
              [141.4674, 38.404],
              [141.1084, 38.3377],
              [140.962, 38.1487],
              [140.9283, 37.9497],
              [141.0364, 37.4675],
              [140.9686, 37.0022],
              [140.7919, 36.8466],
              [140.6272, 36.503],
              [140.5736, 36.2309],
              [140.6218, 36.0596],
              [140.8742, 35.7251],
              [140.6391, 35.661],
              [140.4571, 35.5102],
              [140.4163, 35.2672],
              [140.3147, 35.1551],
              [140.059, 35.0383],
              [139.9206, 34.8994],
              [139.7993, 34.9565],
              [139.8264, 35.2967],
              [140.0866, 35.5406],
              [139.9878, 35.6684],
              [139.8348, 35.6579],
              [139.6503, 35.4091],
              [139.7439, 35.2522],
              [139.6362, 35.1423],
              [139.4745, 35.2984],
              [139.2495, 35.2782],
              [139.1159, 35.0971],
              [139.086, 34.8392],
              [138.9827, 34.6985],
              [138.7612, 34.6994],
              [138.803, 34.9749],
              [138.72, 35.124],
              [138.5772, 35.0862],
              [138.2532, 34.7325],
              [138.1891, 34.5961],
              [137.979, 34.641],
              [137.5435, 34.6643],
              [137.3182, 34.6361],
              [137.2755, 34.7724],
              [136.8849, 34.8057],
              [136.8518, 35.0598],
              [136.6902, 34.9842],
              [136.5333, 34.6784],
              [136.8804, 34.434],
              [136.8536, 34.3241],
              [136.3298, 34.177],
              [135.9162, 33.5617],
              [135.6952, 33.487],
              [135.4526, 33.5533],
              [135.1753, 33.8979],
              [135.1002, 34.2881],
              [135.3849, 34.5004],
              [135.355, 34.6545],
              [135.0417, 34.6308],
              [134.7398, 34.7653],
              [134.5838, 34.7706],
              [134.2081, 34.6976],
              [133.9685, 34.5277],
              [133.4456, 34.4331],
              [133.1424, 34.3026],
              [133.0189, 34.3294],
              [132.6568, 34.2459],
              [132.4213, 34.3535],
              [132.2379, 34.227],
              [132.2019, 34.0319],
              [132.0904, 33.8552],
              [131.7406, 34.0521],
              [131.2325, 33.948],
              [131.0722, 34.0205],
              [130.9187, 33.9756],
              [130.8893, 34.2622],
              [131.004, 34.3927],
              [131.3543, 34.4133],
              [131.6083, 34.6155],
              [131.8562, 34.7263],
              [132.619, 35.3067],
              [132.6977, 35.4183],
              [132.9232, 35.5111],
              [133.2673, 35.5564],
              [133.3767, 35.4588],
              [133.6157, 35.5115],
              [133.86, 35.4948],
              [134.2139, 35.5392],
              [134.4563, 35.628],
              [134.8822, 35.6636],
              [135.1744, 35.7471],
              [135.3269, 35.5252],
              [135.6802, 35.5032],
              [135.9035, 35.6069],
              [136.0955, 35.7678],
              [136.0063, 35.9906],
              [136.1561, 36.223],
              [136.3588, 36.3614],
              [136.6984, 36.742],
              [136.7495, 36.9512],
              [136.7191, 37.1982],
              [136.8434, 37.3823],
              [137.3225, 37.522],
              [137.3374, 37.4376],
              [137.152, 37.283],
              [136.9239, 37.1722],
              [137.0167, 36.837],
              [137.3423, 36.7706],
              [137.5141, 36.9517],
              [137.9131, 37.0646],
              [138.32, 37.2184],
              [138.7094, 37.5607],
              [138.8848, 37.8437],
              [139.2474, 38.009],
              [139.4011, 38.1425],
              [139.5799, 38.5987],
              [139.802, 38.8817],
              [139.9122, 39.2289],
              [140.0366, 39.4112],
              [140.0546, 39.7496],
              [139.9083, 40.0217],
              [140.0141, 40.3148],
              [139.9227, 40.5986],
              [140.0291, 40.7331],
              [140.2813, 40.846],
              [140.386, 41.2297],
              [140.6277, 41.1954],
              [140.6795, 40.8936],
              [140.8008, 40.8342],
              [140.9361, 40.941],
              [141.1185, 40.8821],
              [141.2622, 41.1027],
              [141.2003, 41.2437],
              [140.8003, 41.1387],
              [140.8592, 41.4257],
              [140.937, 41.5053],
              [141.2297, 41.3726]
            ]
          ],
          [
            [
              [143.8242, 44.1169],
              [144.1015, 44.102],
              [144.4821, 43.9494],
              [144.7984, 43.9403],
              [145.3425, 44.334],
              [145.3522, 44.2294],
              [145.1012, 43.7645],
              [145.3407, 43.3021],
              [145.3473, 43.1765],
              [145.0287, 43.0315],
              [144.6305, 42.9466],
              [144.3015, 42.9845],
              [143.9692, 42.8816],
              [143.5808, 42.5986],
              [143.3685, 42.3253],
              [143.2363, 42.0005],
              [142.5084, 42.258],
              [141.8514, 42.5793],
              [141.4067, 42.5467],
              [140.9862, 42.3424],
              [140.7098, 42.5555],
              [140.4804, 42.5591],
              [140.3151, 42.3341],
              [140.4163, 42.2005],
              [140.734, 42.1161],
              [141.1511, 41.8049],
              [140.9999, 41.7377],
              [140.6601, 41.8159],
              [140.3851, 41.5193],
              [140.1486, 41.4235],
              [139.9952, 41.5764],
              [140.1082, 41.913],
              [140.0567, 42.0673],
              [139.895, 42.1899],
              [139.8212, 42.3877],
              [139.8911, 42.6491],
              [140.2241, 42.7954],
              [140.4866, 43.0499],
              [140.3974, 43.1672],
              [140.487, 43.3382],
              [140.781, 43.2147],
              [141.2961, 43.1998],
              [141.4124, 43.3813],
              [141.398, 43.6427],
              [141.6445, 44.0198],
              [141.6612, 44.2637],
              [141.7609, 44.4826],
              [141.7821, 44.7165],
              [141.7192, 44.9411],
              [141.583, 45.156],
              [141.6683, 45.4015],
              [141.9376, 45.5093],
              [142.4162, 45.125],
              [142.704, 44.8192],
              [143.2885, 44.3964],
              [143.8242, 44.1169]
            ]
          ]
        ]
      },
      properties: { name: "Japan", id: "JP", Continent: "Asia" },
      id: "JP"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [39.1456, 32.1246],
            [38.9971, 32.0074],
            [37.774, 31.6962],
            [36.9584, 31.4918],
            [37.4792, 31.008],
            [37.9802, 30.5001],
            [37.6334, 30.3133],
            [37.4695, 29.9952],
            [36.755, 29.8663],
            [36.4759, 29.4949],
            [36.0158, 29.1904],
            [34.9505, 29.3535],
            [34.9734, 29.5553],
            [35.1325, 30.1954],
            [35.1738, 30.5242],
            [35.4392, 31.1324],
            [35.401, 31.2308],
            [35.4506, 31.4791],
            [35.5588, 31.7657],
            [35.5316, 31.9849],
            [35.5513, 32.3953],
            [35.5728, 32.6411],
            [35.7873, 32.7351],
            [35.895, 32.7135],
            [36.0597, 32.5338],
            [36.3722, 32.3871],
            [36.8183, 32.3171],
            [38.0558, 32.9948],
            [38.7734, 33.3718],
            [39.0581, 32.4929],
            [39.041, 32.3057],
            [39.2475, 32.351],
            [39.2928, 32.2437],
            [39.1456, 32.1246]
          ]
        ]
      },
      properties: { name: "Jordan", id: "JO", Continent: "Middle East" },
      id: "JO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.7873, 32.7351],
            [35.5728, 32.6411],
            [35.5513, 32.3953],
            [35.3624, 32.5075],
            [35.1932, 32.5342],
            [35.0652, 32.4603],
            [34.9559, 32.1611],
            [34.9897, 31.9133],
            [35.198, 31.7762],
            [34.9505, 31.6022],
            [34.8728, 31.3965],
            [35.1013, 31.3662],
            [35.4506, 31.4791],
            [35.401, 31.2308],
            [35.4392, 31.1324],
            [35.1738, 30.5242],
            [35.1325, 30.1954],
            [34.9734, 29.5553],
            [34.904, 29.4773],
            [34.6588, 30.1915],
            [34.2452, 31.2084],
            [34.5256, 31.5253],
            [34.4777, 31.5851],
            [34.6786, 31.8957],
            [34.8038, 32.1963],
            [34.922, 32.6142],
            [35.1088, 33.0835],
            [35.4112, 33.0756],
            [35.5328, 33.2505],
            [35.8691, 33.432],
            [35.8374, 33.2783],
            [35.9134, 32.9495],
            [35.7873, 32.7351]
          ]
        ]
      },
      properties: { name: "Israel", id: "IL", Continent: "Middle East" },
      id: "IL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [44.7653, 37.1423],
            [44.8809, 36.7991],
            [45.0342, 36.659],
            [45.0312, 36.5263],
            [45.2408, 36.3557],
            [45.3617, 36.0156],
            [45.6451, 35.9286],
            [45.7234, 35.8363],
            [46.1676, 35.8205],
            [45.9712, 35.5238],
            [46.1118, 35.3217],
            [46.1337, 35.1275],
            [45.9211, 35.0285],
            [45.6781, 34.7984],
            [45.6376, 34.5738],
            [45.5006, 34.5817],
            [45.4377, 34.4151],
            [45.5286, 34.1523],
            [45.3968, 33.9708],
            [45.7383, 33.603],
            [45.8793, 33.6101],
            [46.1456, 33.2295],
            [46.1127, 32.9579],
            [46.2986, 32.9504],
            [46.5702, 32.834],
            [47.1212, 32.4665],
            [47.3713, 32.424],
            [47.5119, 32.1511],
            [47.8297, 31.7942],
            [47.6793, 31.4009],
            [47.6793, 31.0023],
            [48.0107, 30.9896],
            [48.0151, 30.4654],
            [48.331, 30.2855],
            [48.4343, 30.0376],
            [48.5468, 29.9626],
            [48.3544, 29.9569],
            [48.1421, 30.0412],
            [47.9786, 29.9828],
            [47.6728, 30.0957],
            [47.3313, 30.0795],
            [47.1142, 29.9612],
            [46.9054, 29.5376],
            [46.5315, 29.0965],
            [46.3565, 29.0634],
            [44.7166, 29.1936],
            [43.7739, 29.8492],
            [42.8576, 30.4953],
            [42.0741, 31.0802],
            [40.3695, 31.9392],
            [39.1456, 32.1246],
            [39.2928, 32.2437],
            [39.2475, 32.351],
            [39.041, 32.3057],
            [39.0581, 32.4929],
            [38.7734, 33.3718],
            [39.5644, 33.7683],
            [40.6894, 34.3321],
            [40.9873, 34.4287],
            [41.1943, 34.7688],
            [41.2163, 35.2883],
            [41.3543, 35.6403],
            [41.3525, 35.81],
            [41.2457, 36.0736],
            [41.2963, 36.3834],
            [41.4167, 36.5148],
            [41.7884, 36.5974],
            [42.3501, 37.0606],
            [42.3589, 37.1085],
            [42.456, 37.1287],
            [42.7746, 37.3717],
            [43.0927, 37.3674],
            [43.5159, 37.2447],
            [43.8368, 37.2236],
            [44.0134, 37.3133],
            [44.1563, 37.283],
            [44.2019, 37.0518],
            [44.2819, 36.978],
            [44.6058, 37.1762],
            [44.7653, 37.1423]
          ]
        ]
      },
      properties: { name: "Iraq", id: "IQ", Continent: "Middle East" },
      id: "IQ"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [56.188, 26.9216],
              [55.9543, 26.7014],
              [55.8479, 26.7308],
              [55.4238, 26.5832],
              [55.3469, 26.6478],
              [55.7626, 26.8122],
              [56.0742, 26.9835],
              [56.188, 26.9216]
            ]
          ],
          [
            [
              [53.9143, 37.3437],
              [54.1916, 37.3326],
              [54.7453, 37.5018],
              [54.8486, 37.7224],
              [55.0753, 37.9022],
              [55.3808, 38.0512],
              [55.5785, 38.1],
              [56.2289, 38.0731],
              [56.4407, 38.2493],
              [57.1939, 38.2164],
              [57.4238, 37.9479],
              [57.7103, 37.9057],
              [58.1089, 37.7831],
              [58.2618, 37.6658],
              [58.4358, 37.6385],
              [58.8155, 37.6833],
              [59.2409, 37.5207],
              [59.3675, 37.334],
              [59.5621, 37.1788],
              [59.9484, 37.0417],
              [60.0627, 36.9631],
              [60.3413, 36.6379],
              [61.1196, 36.6427],
              [61.2123, 36.0991],
              [61.1529, 35.9769],
              [61.2523, 35.8675],
              [61.2619, 35.6197],
              [61.08, 34.8554],
              [60.9512, 34.6542],
              [60.7263, 34.5179],
              [60.8897, 34.3193],
              [60.6428, 34.3074],
              [60.4854, 34.0947],
              [60.511, 33.6386],
              [60.917, 33.505],
              [60.5606, 33.1376],
              [60.5764, 32.9948],
              [60.8291, 32.2496],
              [60.79, 31.9871],
              [60.7913, 31.6606],
              [60.8542, 31.4831],
              [61.6601, 31.3824],
              [61.755, 31.2853],
              [61.8143, 31.0727],
              [61.7845, 30.8323],
              [61.1038, 30.1286],
              [60.8436, 29.8584],
              [61.3182, 29.3724],
              [61.338, 29.2652],
              [61.6228, 28.7914],
              [61.8899, 28.5467],
              [62.3531, 28.4149],
              [62.5649, 28.2351],
              [62.7622, 28.2021],
              [62.7398, 28.0021],
              [62.8119, 27.4972],
              [62.7631, 27.2503],
              [62.9156, 27.2181],
              [63.1665, 27.2525],
              [63.2421, 27.0776],
              [63.2501, 26.8793],
              [63.1679, 26.6658],
              [62.7868, 26.6439],
              [62.4393, 26.5607],
              [62.2393, 26.3573],
              [61.8697, 26.2421],
              [61.8104, 26.1653],
              [61.7541, 25.8431],
              [61.6684, 25.7689],
              [61.5876, 25.202],
              [61.4118, 25.1022],
              [60.6639, 25.282],
              [60.5105, 25.4371],
              [60.4002, 25.3114],
              [60.0249, 25.3844],
              [59.6162, 25.4032],
              [59.4562, 25.4814],
              [59.0463, 25.4173],
              [58.7979, 25.5544],
              [58.5308, 25.5922],
              [58.2029, 25.5913],
              [57.9366, 25.692],
              [57.3346, 25.7917],
              [57.2058, 26.0369],
              [57.1043, 26.3714],
              [57.0722, 26.6799],
              [56.9825, 26.9053],
              [56.813, 27.0899],
              [56.3559, 27.2001],
              [56.1181, 27.1435],
              [55.9411, 27.0375],
              [55.6502, 26.9778],
              [55.4238, 26.7704],
              [55.2942, 26.7858],
              [54.8956, 26.5564],
              [54.6451, 26.5089],
              [54.2469, 26.6966],
              [54.069, 26.7322],
              [53.7056, 26.7256],
              [53.4546, 26.9431],
              [52.9827, 27.1422],
              [52.6917, 27.3237],
              [52.476, 27.6163],
              [52.1921, 27.7173],
              [52.0308, 27.8242],
              [51.6665, 27.8453],
              [51.5184, 27.9098],
              [51.2789, 28.1314],
              [51.2763, 28.2188],
              [51.0939, 28.5124],
              [51.0622, 28.7264],
              [50.8671, 28.8701],
              [50.8759, 29.0625],
              [50.6751, 29.1465],
              [50.6501, 29.4198],
              [50.1693, 29.9213],
              [50.0713, 30.1986],
              [49.9829, 30.2091],
              [49.5549, 30.0289],
              [49.4301, 30.1309],
              [48.9168, 30.3972],
              [48.919, 30.1208],
              [48.8325, 30.0355],
              [48.5468, 29.9626],
              [48.4343, 30.0376],
              [48.331, 30.2855],
              [48.0151, 30.4654],
              [48.0107, 30.9896],
              [47.6793, 31.0023],
              [47.6793, 31.4009],
              [47.8297, 31.7942],
              [47.5119, 32.1511],
              [47.3713, 32.424],
              [47.1212, 32.4665],
              [46.5702, 32.834],
              [46.2986, 32.9504],
              [46.1127, 32.9579],
              [46.1456, 33.2295],
              [45.8793, 33.6101],
              [45.7383, 33.603],
              [45.3968, 33.9708],
              [45.5286, 34.1523],
              [45.4377, 34.4151],
              [45.5006, 34.5817],
              [45.6376, 34.5738],
              [45.6781, 34.7984],
              [45.9211, 35.0285],
              [46.1337, 35.1275],
              [46.1118, 35.3217],
              [45.9712, 35.5238],
              [46.1676, 35.8205],
              [45.7234, 35.8363],
              [45.6451, 35.9286],
              [45.3617, 36.0156],
              [45.2408, 36.3557],
              [45.0312, 36.5263],
              [45.0342, 36.659],
              [44.8809, 36.7991],
              [44.7653, 37.1423],
              [44.7939, 37.2904],
              [44.5737, 37.4355],
              [44.5614, 37.7448],
              [44.2111, 37.9083],
              [44.4309, 38.357],
              [44.2907, 38.4198],
              [44.2713, 38.836],
              [44.1448, 38.9942],
              [44.1777, 39.145],
              [44.0231, 39.3774],
              [44.3891, 39.4223],
              [44.4559, 39.667],
              [44.5874, 39.7685],
              [44.8378, 39.6292],
              [45.1411, 39.2543],
              [45.4798, 39.0065],
              [46.1702, 38.8689],
              [46.5548, 38.9041],
              [46.8527, 39.1485],
              [46.989, 39.1805],
              [47.1884, 39.341],
              [47.7729, 39.6486],
              [47.9958, 39.6842],
              [48.3222, 39.3994],
              [48.1359, 39.3124],
              [48.1253, 39.1713],
              [48.2743, 39.0992],
              [48.2423, 38.9788],
              [48.0498, 38.9349],
              [48.0234, 38.8189],
              [48.4177, 38.5864],
              [48.5929, 38.411],
              [48.8404, 38.4375],
              [48.9599, 37.8898],
              [49.0812, 37.6675],
              [49.4701, 37.4966],
              [50.1306, 37.4069],
              [50.3376, 37.1489],
              [50.5332, 37.0136],
              [51.1185, 36.7424],
              [51.7623, 36.6145],
              [52.1903, 36.6216],
              [53.3742, 36.8686],
              [53.7679, 36.9301],
              [53.9701, 36.8185],
              [54.0171, 36.9525],
              [53.9143, 37.3437]
            ]
          ]
        ]
      },
      properties: { name: "Iran", id: "IR", Continent: "Middle East" },
      id: "IR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.4922, -7.3774],
            [72.4671, -7.3676],
            [72.4737, -7.3097],
            [72.4355, -7.2305],
            [72.4939, -7.2618],
            [72.4922, -7.3774]
          ]
        ]
      },
      properties: { name: "British Indian Ocean Territory", id: "IO", Continent: "Asia" },
      id: "IO"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [93.89, 6.8311],
              [93.709, 7.0007],
              [93.6843, 7.1835],
              [93.8592, 7.2069],
              [93.9296, 6.9734],
              [93.89, 6.8311]
            ]
          ],
          [
            [
              [92.5027, 10.555],
              [92.3695, 10.5475],
              [92.3528, 10.7514],
              [92.5101, 10.8973],
              [92.5743, 10.7044],
              [92.5027, 10.555]
            ]
          ],
          [
            [
              [92.7229, 11.5358],
              [92.6684, 11.539],
              [92.5339, 11.8733],
              [92.6073, 11.9494],
              [92.6947, 12.2149],
              [92.74, 12.7795],
              [92.8073, 12.8788],
              [92.8573, 13.3583],
              [92.925, 13.4858],
              [93.0626, 13.5455],
              [93.074, 13.2523],
              [92.8863, 12.9421],
              [92.9654, 12.8502],
              [92.99, 12.5383],
              [92.8635, 12.4359],
              [92.8797, 12.2279],
              [92.7479, 11.9928],
              [92.7976, 11.8747],
              [92.7229, 11.5358]
            ]
          ],
          [
            [
              [78.7196, 31.8877],
              [78.6938, 31.7402],
              [78.8027, 31.6179],
              [78.7272, 31.4717],
              [78.8445, 31.3016],
              [79.1073, 31.4027],
              [79.3384, 31.1055],
              [79.3696, 31.0797],
              [79.5656, 30.9491],
              [79.7946, 30.9685],
              [79.9167, 30.8942],
              [79.9246, 30.8889],
              [80.1945, 30.7593],
              [80.1914, 30.5685],
              [80.6823, 30.4148],
              [80.9855, 30.2372],
              [81.0101, 30.1642],
              [80.9077, 30.1722],
              [80.5491, 29.8997],
              [80.4019, 29.73],
              [80.2547, 29.4233],
              [80.2331, 29.1943],
              [80.1303, 29.1004],
              [80.0705, 28.8301],
              [80.4186, 28.6121],
              [80.5869, 28.6495],
              [80.7508, 28.5396],
              [81.1688, 28.3353],
              [81.3106, 28.1761],
              [81.8529, 27.8669],
              [81.9879, 27.9139],
              [82.4511, 27.6721],
              [82.6774, 27.6735],
              [82.7336, 27.5187],
              [83.2896, 27.3711],
              [83.4473, 27.4651],
              [83.8292, 27.3777],
              [84.0911, 27.4915],
              [84.6101, 27.2986],
              [84.6853, 27.0411],
              [85.293, 26.7409],
              [85.5686, 26.8398],
              [85.6999, 26.7814],
              [85.7373, 26.6398],
              [86.0071, 26.6491],
              [86.3662, 26.5744],
              [86.7015, 26.435],
              [87.0165, 26.5555],
              [87.0895, 26.4329],
              [87.2872, 26.3604],
              [87.4133, 26.4227],
              [87.6331, 26.399],
              [88.0549, 26.4298],
              [88.1613, 26.7247],
              [88.1112, 26.9285],
              [87.9842, 27.1338],
              [88.0242, 27.409],
              [88.1473, 27.749],
              [88.1099, 27.8703],
              [88.109, 27.9332],
              [88.6214, 28.0918],
              [88.8038, 28.0069],
              [88.8486, 27.8685],
              [88.7493, 27.5219],
              [88.8913, 27.3162],
              [88.7388, 27.1755],
              [88.8578, 26.9616],
              [89.1484, 26.8161],
              [89.332, 26.8487],
              [89.764, 26.7018],
              [90.123, 26.7545],
              [90.3458, 26.8904],
              [90.7396, 26.7716],
              [91.2863, 26.7901],
              [91.4265, 26.8671],
              [91.6716, 26.802],
              [91.9986, 26.8548],
              [92.0733, 26.9146],
              [91.992, 27.1],
              [92.0835, 27.2907],
              [91.9907, 27.4502],
              [91.7433, 27.4423],
              [91.5948, 27.5574],
              [91.6317, 27.76],
              [91.9775, 27.7301],
              [92.1015, 27.8079],
              [92.4148, 27.8247],
              [92.6644, 27.949],
              [92.7017, 28.1467],
              [92.8819, 28.2281],
              [93.1192, 28.4021],
              [93.2067, 28.5911],
              [93.3609, 28.6539],
              [93.6646, 28.6904],
              [93.9023, 28.8028],
              [94.2934, 29.1448],
              [94.623, 29.3127],
              [94.7698, 29.1759],
              [94.9992, 29.1492],
              [95.2792, 29.0493],
              [95.4202, 29.0543],
              [95.516, 29.2067],
              [96.035, 29.447],
              [96.2349, 29.2454],
              [96.122, 29.0823],
              [96.162, 28.9097],
              [96.4354, 29.0507],
              [96.5813, 28.7633],
              [96.3957, 28.6064],
              [96.2815, 28.4117],
              [96.3663, 28.367],
              [96.6023, 28.46],
              [96.7759, 28.367],
              [97.0752, 28.3691],
              [97.3227, 28.2179],
              [97.3353, 27.938],
              [96.8999, 27.6441],
              [96.902, 27.4393],
              [97.1038, 27.1632],
              [96.8805, 27.1778],
              [96.666, 27.339],
              [96.0614, 27.2168],
              [95.905, 27.0464],
              [95.4637, 26.7563],
              [95.1288, 26.5972],
              [95.0599, 26.4738],
              [95.0686, 26.1912],
              [95.1324, 26.0414],
              [95.015, 25.913],
              [94.9921, 25.7703],
              [94.7861, 25.5192],
              [94.6226, 25.4098],
              [94.5545, 25.2433],
              [94.7074, 25.0486],
              [94.5839, 24.7674],
              [94.293, 24.3218],
              [94.1277, 23.8762],
              [93.6835, 24.0062],
              [93.4941, 23.9733],
              [93.3073, 24.0221],
              [93.415, 23.6824],
              [93.3662, 23.1321],
              [93.3077, 23.0302],
              [93.164, 23.032],
              [93.0788, 22.7182],
              [93.1619, 22.3604],
              [92.9641, 22.004],
              [92.8542, 22.0102],
              [92.689, 22.131],
              [92.5751, 21.9782],
              [92.4917, 22.6852],
              [92.3616, 22.9291],
              [92.3343, 23.3237],
              [92.2461, 23.6837],
              [91.9296, 23.6858],
              [91.938, 23.5047],
              [91.7543, 23.2872],
              [91.7736, 23.1063],
              [91.6198, 22.9797],
              [91.5113, 23.0338],
              [91.4365, 23.1998],
              [91.3153, 23.1045],
              [91.1601, 23.6604],
              [91.2318, 23.9205],
              [91.3671, 24.0933],
              [91.5266, 24.0906],
              [91.6686, 24.19],
              [91.8769, 24.1957],
              [91.9516, 24.3565],
              [92.0641, 24.3745],
              [92.1982, 24.686],
              [92.2513, 24.8948],
              [92.4684, 24.9445],
              [92.0501, 25.1694],
              [91.4801, 25.1422],
              [91.2929, 25.1778],
              [90.4394, 25.1576],
              [89.8141, 25.3053],
              [89.8229, 25.9411],
              [89.7095, 26.1714],
              [89.55, 26.0054],
              [89.2894, 26.0373],
              [89.1083, 26.2022],
              [88.6829, 26.2914],
              [88.5182, 26.5178],
              [88.4404, 26.3696],
              [88.1508, 26.0875],
              [88.0844, 25.8884],
              [88.2527, 25.7899],
              [88.4523, 25.5746],
              [88.5933, 25.4951],
              [88.7691, 25.4903],
              [88.8904, 25.194],
              [88.4562, 25.1883],
              [88.3134, 24.8816],
              [88.1499, 24.9145],
              [88.0233, 24.6276],
              [88.1455, 24.4857],
              [88.6421, 24.3261],
              [88.7335, 24.2308],
              [88.6996, 24.0023],
              [88.567, 23.6744],
              [88.7405, 23.4367],
              [88.7242, 23.2548],
              [88.9282, 23.1866],
              [88.8508, 23.0402],
              [88.9207, 22.632],
              [89.0499, 22.2747],
              [89.0512, 22.0933],
              [89.0195, 21.8336],
              [89.0517, 21.6542],
              [88.8346, 21.6613],
              [88.5665, 21.8323],
              [88.585, 21.6599],
              [88.2536, 21.6222],
              [88.0567, 21.6943],
              [88.1811, 22.0327],
              [88.051, 22.0015],
              [87.9482, 21.8257],
              [87.6779, 21.6538],
              [87.1005, 21.5005],
              [86.8596, 21.2368],
              [86.8421, 21.1062],
              [86.9753, 20.7003],
              [86.7625, 20.4194],
              [86.7502, 20.3131],
              [86.4984, 20.1715],
              [86.2792, 19.9193],
              [85.5747, 19.693],
              [85.5554, 19.867],
              [85.46, 19.896],
              [85.2486, 19.758],
              [85.4416, 19.6267],
              [85.2253, 19.5083],
              [84.7709, 19.1252],
              [84.7498, 19.0501],
              [84.4628, 18.6897],
              [84.1043, 18.2924],
              [83.6543, 18.0696],
              [83.1981, 17.6092],
              [82.9767, 17.4619],
              [82.593, 17.2738],
              [82.3596, 17.0963],
              [82.2819, 16.9358],
              [82.3596, 16.783],
              [82.259, 16.5602],
              [81.762, 16.3299],
              [81.4016, 16.365],
              [81.2386, 16.264],
              [81.1318, 15.9616],
              [80.9789, 15.7586],
              [80.8259, 15.766],
              [80.6467, 15.8952],
              [80.3848, 15.7929],
              [80.2933, 15.7107],
              [80.1013, 15.324],
              [80.0534, 15.0739],
              [80.1654, 14.5778],
              [80.1699, 14.3492],
              [80.1118, 14.2121],
              [80.2441, 13.7731],
              [80.0622, 13.6066],
              [80.3421, 13.3613],
              [80.2292, 12.6903],
              [80.1431, 12.4516],
              [79.9817, 12.2354],
              [79.8583, 11.9889],
              [79.7541, 11.575],
              [79.8487, 11.197],
              [79.8381, 10.3226],
              [79.3907, 10.3062],
              [79.2531, 10.1744],
              [79.258, 10.0351],
              [78.9965, 9.6832],
              [78.9192, 9.4529],
              [78.9794, 9.2687],
              [78.4217, 9.1049],
              [78.1923, 8.8908],
              [78.1264, 8.5115],
              [78.06, 8.3845],
              [77.7704, 8.1898],
              [77.5178, 8.0783],
              [77.3016, 8.1451],
              [76.9667, 8.4069],
              [76.5536, 8.9031],
              [76.4719, 9.1606],
              [76.3246, 9.452],
              [76.4586, 9.5363],
              [76.3462, 9.9222],
              [76.1955, 10.0866],
              [76.2016, 10.2008],
              [75.9225, 10.784],
              [75.7239, 11.3618],
              [75.5248, 11.7029],
              [75.1965, 12.0575],
              [74.9452, 12.5647],
              [74.7703, 13.0774],
              [74.671, 13.6677],
              [74.4983, 14.0465],
              [74.3823, 14.4946],
              [73.9494, 15.0748],
              [73.8844, 15.3064],
              [73.9318, 15.3969],
              [73.7719, 15.5726],
              [73.4761, 16.0544],
              [73.3377, 16.4599],
              [73.2393, 17.1982],
              [73.1562, 17.6218],
              [72.994, 18.0978],
              [72.9171, 18.5763],
              [72.871, 18.6831],
              [73.0054, 19.021],
              [72.8345, 18.9758],
              [72.8117, 19.2988],
              [72.6679, 19.831],
              [72.7093, 20.078],
              [72.8811, 20.563],
              [72.8789, 20.8285],
              [72.8139, 21.1169],
              [72.6925, 21.1775],
              [72.6131, 21.4617],
              [72.8398, 21.6872],
              [72.5432, 21.6968],
              [72.5923, 21.8775],
              [72.5221, 21.9764],
              [72.5902, 22.2783],
              [72.3327, 22.2703],
              [72.2443, 22.0274],
              [72.0369, 21.8234],
              [72.2566, 21.6613],
              [72.254, 21.5312],
              [72.0154, 21.1555],
              [71.5711, 20.9705],
              [71.3966, 20.8699],
              [71.0244, 20.7388],
              [70.719, 20.7406],
              [70.4852, 20.84],
              [70.1274, 21.0948],
              [69.7482, 21.5057],
              [69.1919, 21.9913],
              [69.0087, 22.1965],
              [68.9836, 22.3851],
              [69.1312, 22.4163],
              [69.2763, 22.2854],
              [69.5491, 22.4083],
              [69.819, 22.4519],
              [70.0058, 22.5476],
              [70.1771, 22.5727],
              [70.3274, 22.8158],
              [70.2514, 22.9709],
              [69.8501, 22.8562],
              [69.6643, 22.759],
              [69.2363, 22.8487],
              [68.817, 23.0539],
              [68.6408, 23.1902],
              [68.4176, 23.5711],
              [68.2352, 23.5971],
              [68.1649, 23.8573],
              [68.4887, 23.9672],
              [68.7243, 23.9645],
              [68.7283, 24.2655],
              [69.5593, 24.273],
              [69.7162, 24.1728],
              [70.0212, 24.1913],
              [70.098, 24.2875],
              [70.5467, 24.4185],
              [70.5793, 24.2787],
              [70.7163, 24.2383],
              [71.0442, 24.4],
              [70.973, 24.4874],
              [71.0481, 24.6878],
              [70.8776, 25.0627],
              [70.6522, 25.4225],
              [70.6486, 25.667],
              [70.2646, 25.7064],
              [70.1002, 25.9103],
              [70.0778, 26.0716],
              [70.1565, 26.4711],
              [70.0594, 26.5787],
              [69.7359, 26.6271],
              [69.4701, 26.8047],
              [69.568, 27.1743],
              [69.8963, 27.4735],
              [70.1446, 27.8493],
              [70.4035, 28.025],
              [70.6289, 27.9376],
              [70.7374, 27.7292],
              [70.875, 27.7148],
              [71.1848, 27.8317],
              [71.543, 27.8699],
              [71.8699, 27.9622],
              [71.9477, 28.177],
              [72.1793, 28.4219],
              [72.3419, 28.7519],
              [72.903, 29.0292],
              [73.2309, 29.5503],
              [73.3817, 29.9345],
              [73.8092, 30.0935],
              [73.9332, 30.2223],
              [73.8993, 30.4353],
              [74.2157, 30.7689],
              [74.6327, 31.0344],
              [74.518, 31.1856],
              [74.5936, 31.4656],
              [74.5101, 31.713],
              [74.5559, 31.8184],
              [74.7395, 31.9488],
              [75.2541, 32.1401],
              [75.3332, 32.279],
              [75.2335, 32.3721],
              [74.9874, 32.4621],
              [74.6578, 32.5189],
              [74.663, 32.7575],
              [74.3546, 32.7689],
              [74.3036, 32.9917],
              [74.1265, 33.0756],
              [73.9942, 33.2422],
              [74.1498, 33.5068],
              [74.0039, 33.6324],
              [74.0008, 33.7884],
              [74.2157, 33.8865],
              [74.2469, 33.9902],
              [73.9498, 34.0187],
              [73.9793, 34.1914],
              [73.8101, 34.3255],
              [73.8123, 34.4221],
              [73.9612, 34.6538],
              [74.3006, 34.7653],
              [74.9522, 34.6458],
              [75.1873, 34.6388],
              [75.4523, 34.5368],
              [75.7094, 34.5031],
              [76.0407, 34.67],
              [76.4569, 34.7562],
              [76.5944, 34.7355],
              [76.783, 34.9003],
              [77.001, 34.992],
              [77.0484, 35.1099],
              [77.799, 35.4962],
              [77.8109, 35.4843],
              [78.0473, 35.4496],
              [78.0121, 35.251],
              [78.282, 34.6542],
              [78.6709, 34.5179],
              [78.9363, 34.3523],
              [78.9706, 34.2283],
              [78.7531, 34.0878],
              [78.8018, 33.4997],
              [79.1125, 33.2263],
              [79.1354, 33.1718],
              [79.1086, 33.0224],
              [79.2022, 32.946],
              [79.2167, 32.5641],
              [79.2193, 32.5013],
              [78.9192, 32.358],
              [78.7007, 32.5971],
              [78.3897, 32.5198],
              [78.4959, 32.2156],
              [78.7355, 31.9577],
              [78.7196, 31.8877]
            ]
          ]
        ]
      },
      properties: { name: "India", id: "IN", Continent: "Asia" },
      id: "IN"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [122.9489, -10.9092],
              [122.8456, -10.762],
              [123.0614, -10.6988],
              [123.3708, -10.4746],
              [123.4178, -10.6513],
              [123.2148, -10.806],
              [122.9489, -10.9092]
            ]
          ],
          [
            [
              [120.0124, -9.3746],
              [120.2211, -9.5061],
              [120.2911, -9.648],
              [120.5037, -9.674],
              [120.8329, -10.0373],
              [120.698, -10.2065],
              [120.4391, -10.294],
              [120.1447, -10.1999],
              [119.9306, -9.9666],
              [119.6011, -9.7732],
              [119.3625, -9.7714],
              [119.0857, -9.7068],
              [118.9587, -9.5196],
              [119.1854, -9.3848],
              [119.6147, -9.3527],
              [119.7949, -9.3803],
              [119.9422, -9.3016],
              [120.0124, -9.3746]
            ]
          ],
          [
            [
              [125.0679, -9.5123],
              [124.9634, -9.6655],
              [124.4271, -10.1485],
              [124.1762, -10.1832],
              [123.8572, -10.3437],
              [123.6445, -10.3111],
              [123.6141, -10.2153],
              [123.7161, -10.0787],
              [123.5891, -9.967],
              [123.7092, -9.615],
              [124.0365, -9.3417],
              [124.0901, -9.4163],
              [124.2821, -9.4282],
              [124.4132, -9.3144],
              [124.4444, -9.1901],
              [124.5757, -9.1554],
              [124.9224, -8.9427],
              [124.9735, -9.0644],
              [124.9686, -9.2947],
              [125.0679, -9.5123]
            ]
          ],
          [[[123.3176, -8.3547], [123.0249, -8.3956], [123.1334, -8.254], [123.3176, -8.3547]]],
          [
            [
              [116.6405, -8.614],
              [116.5139, -8.8209],
              [116.3773, -8.929],
              [115.8746, -8.8257],
              [116.0763, -8.7449],
              [116.0614, -8.4373],
              [116.2199, -8.2953],
              [116.4014, -8.2044],
              [116.6466, -8.283],
              [116.7341, -8.3872],
              [116.6405, -8.614]
            ]
          ],
          [[[124.2866, -8.3297], [124.1847, -8.4984], [124.0654, -8.552], [123.9715, -8.3543], [124.2866, -8.3297]]],
          [
            [
              [123.9249, -8.2725],
              [123.7838, -8.2998],
              [123.6292, -8.4227],
              [123.553, -8.5669],
              [123.4107, -8.5862],
              [123.325, -8.4391],
              [123.5298, -8.265],
              [123.7758, -8.1903],
              [123.9249, -8.2725]
            ]
          ],
          [[[138.8953, -8.389], [138.5943, -8.3718], [138.6769, -8.1995], [138.7959, -8.1737], [138.8953, -8.389]]],
          [[[117.5563, -8.367], [117.4908, -8.1833], [117.6693, -8.189], [117.5563, -8.367]]],
          [
            [
              [124.5757, -8.1406],
              [125.1246, -8.2047],
              [125.0969, -8.3529],
              [124.4444, -8.4444],
              [124.4303, -8.1833],
              [124.5757, -8.1406]
            ]
          ],
          [[[127.8237, -8.0985], [128.119, -8.1705], [128.0233, -8.2553], [127.821, -8.1899], [127.8237, -8.0985]]],
          [
            [
              [122.7827, -8.6118],
              [122.4703, -8.7256],
              [122.0942, -8.7445],
              [121.8384, -8.8605],
              [121.6512, -8.8991],
              [121.5848, -8.8209],
              [121.4147, -8.8147],
              [121.3282, -8.9167],
              [121.035, -8.9351],
              [120.5504, -8.8015],
              [120.3196, -8.8204],
              [120.1205, -8.7769],
              [119.9096, -8.8578],
              [119.8077, -8.6975],
              [119.8182, -8.5705],
              [119.9183, -8.4453],
              [120.2313, -8.29],
              [120.6106, -8.2408],
              [121.2767, -8.4781],
              [121.4446, -8.578],
              [121.9663, -8.4549],
              [122.2629, -8.625],
              [122.4333, -8.6008],
              [122.6034, -8.4026],
              [122.8509, -8.3046],
              [122.7581, -8.186],
              [122.8456, -8.0932],
              [122.9779, -8.1521],
              [123.006, -8.3292],
              [122.8114, -8.4813],
              [122.7827, -8.6118]
            ]
          ],
          [
            [
              [118.2428, -8.3178],
              [118.3382, -8.3534],
              [118.4905, -8.2711],
              [118.7482, -8.3313],
              [118.9881, -8.3379],
              [119.0421, -8.5612],
              [119.1295, -8.6681],
              [119.0061, -8.7497],
              [118.4787, -8.8565],
              [118.379, -8.6745],
              [118.19, -8.8403],
              [117.8613, -8.9312],
              [117.7316, -8.9198],
              [117.5079, -9.0077],
              [117.2101, -9.0341],
              [117.0616, -9.0991],
              [116.7886, -9.0063],
              [116.7829, -8.6645],
              [116.8352, -8.5322],
              [117.1647, -8.367],
              [117.3569, -8.4284],
              [117.5669, -8.4268],
              [117.8064, -8.711],
              [117.9694, -8.7283],
              [118.2349, -8.5919],
              [118.1004, -8.4756],
              [117.979, -8.4592],
              [117.8148, -8.3424],
              [117.7553, -8.15],
              [117.9206, -8.0893],
              [118.1175, -8.1222],
              [118.2428, -8.3178]
            ]
          ],
          [
            [
              [115.4478, -8.1552],
              [115.5493, -8.2084],
              [115.7045, -8.407],
              [115.3335, -8.6157],
              [115.236, -8.7976],
              [115.0549, -8.573],
              [114.8419, -8.4284],
              [114.6134, -8.3785],
              [114.5021, -8.2606],
              [114.4754, -8.1196],
              [114.833, -8.1824],
              [114.9983, -8.1744],
              [115.1538, -8.0659],
              [115.4478, -8.1552]
            ]
          ],
          [
            [
              [129.8391, -7.9548],
              [129.7133, -8.041],
              [129.592, -7.9174],
              [129.6088, -7.8036],
              [129.8131, -7.8198],
              [129.8391, -7.9548]
            ]
          ],
          [
            [
              [126.8007, -7.6682],
              [126.813, -7.7377],
              [126.577, -7.8079],
              [126.4719, -7.9504],
              [126.1086, -7.8841],
              [125.9516, -7.9108],
              [125.8435, -7.8168],
              [125.9754, -7.6629],
              [126.2136, -7.7065],
              [126.6095, -7.5715],
              [126.8007, -7.6682]
            ]
          ],
          [
            [
              [138.535, -8.2734],
              [138.2963, -8.4052],
              [137.8718, -8.3797],
              [137.6876, -8.4113],
              [137.6851, -8.2624],
              [137.8327, -7.9324],
              [138.0071, -7.6414],
              [138.2954, -7.4385],
              [138.5441, -7.3795],
              [138.7701, -7.3906],
              [138.9626, -7.5883],
              [138.9893, -7.6959],
              [138.7859, -8.059],
              [138.535, -8.2734]
            ]
          ],
          [
            [
              [131.3253, -7.9995],
              [131.1139, -7.9974],
              [131.0867, -7.8651],
              [131.1376, -7.685],
              [131.2603, -7.4704],
              [131.3495, -7.4253],
              [131.5608, -7.1357],
              [131.6913, -7.4389],
              [131.6245, -7.6264],
              [131.3771, -7.8691],
              [131.3253, -7.9995]
            ]
          ],
          [
            [
              [113.8447, -7.1053],
              [113.6558, -7.1119],
              [113.4707, -7.2182],
              [113.0406, -7.2116],
              [112.7637, -7.14],
              [112.7259, -7.0727],
              [112.8678, -6.9001],
              [113.0673, -6.8803],
              [113.9748, -6.8728],
              [114.0829, -6.9893],
              [113.8447, -7.1053]
            ]
          ],
          [
            [
              [134.5368, -6.4426],
              [134.5044, -6.5916],
              [134.3557, -6.8148],
              [134.2002, -6.9088],
              [134.0591, -6.7692],
              [134.1681, -6.1763],
              [134.415, -6.3868],
              [134.5368, -6.4426]
            ]
          ],
          [
            [
              [107.3738, -6.0075],
              [107.4748, -6.1218],
              [107.6668, -6.2155],
              [108.1375, -6.2967],
              [108.2948, -6.265],
              [108.5158, -6.4712],
              [108.6034, -6.7291],
              [108.6777, -6.7906],
              [109.2941, -6.8666],
              [109.5007, -6.81],
              [109.8211, -6.9023],
              [110.1985, -6.8953],
              [110.4261, -6.9471],
              [110.5835, -6.8057],
              [110.7369, -6.4721],
              [110.972, -6.436],
              [111.1544, -6.6693],
              [111.3421, -6.6997],
              [111.5403, -6.6483],
              [111.7376, -6.7735],
              [111.9898, -6.8061],
              [112.0874, -6.8935],
              [112.5391, -6.9265],
              [112.6486, -7.2214],
              [112.7945, -7.3044],
              [112.7945, -7.5527],
              [113.0137, -7.6577],
              [113.2483, -7.7183],
              [113.7471, -7.703],
              [114.0706, -7.633],
              [114.3826, -7.771],
              [114.4442, -7.8959],
              [114.3849, -8.2633],
              [114.4485, -8.5595],
              [114.1598, -8.6263],
              [113.6923, -8.4781],
              [113.2531, -8.287],
              [113.1336, -8.2884],
              [112.6789, -8.4092],
              [112.115, -8.324],
              [111.5104, -8.305],
              [111.0555, -8.24],
              [110.6072, -8.15],
              [110.0386, -7.8907],
              [109.8528, -7.8282],
              [109.2818, -7.7047],
              [108.7409, -7.6674],
              [108.4517, -7.7965],
              [107.9174, -7.724],
              [107.5469, -7.5421],
              [107.2851, -7.4718],
              [106.6315, -7.4155],
              [106.4548, -7.369],
              [106.4171, -7.2394],
              [106.52, -7.0538],
              [105.9447, -6.8588],
              [105.2551, -6.8355],
              [105.2736, -6.7291],
              [105.4836, -6.7815],
              [105.5812, -6.6711],
              [105.6551, -6.4694],
              [105.7869, -6.457],
              [105.8682, -6.1165],
              [106.0751, -5.9143],
              [106.1657, -5.9649],
              [106.6764, -6.0383],
              [106.8253, -6.0985],
              [107.0117, -6.0084],
              [107.0463, -5.9043],
              [107.3738, -6.0075]
            ]
          ],
          [[[120.5283, -6.2985], [120.4607, -6.2541], [120.4466, -5.8766], [120.549, -5.9693], [120.5283, -6.2985]]],
          [
            [
              [134.7468, -5.707],
              [134.7126, -5.9496],
              [134.7557, -6.1706],
              [134.6378, -6.3653],
              [134.441, -6.335],
              [134.1751, -6.0906],
              [134.3017, -6.0098],
              [134.3413, -5.7127],
              [134.4902, -5.525],
              [134.6164, -5.4388],
              [134.7468, -5.707]
            ]
          ],
          [
            [
              [132.9262, -5.902],
              [132.9376, -5.6824],
              [133.0089, -5.6213],
              [133.1147, -5.3106],
              [133.1727, -5.3483],
              [133.1195, -5.5761],
              [132.9711, -5.736],
              [132.9262, -5.902]
            ]
          ],
          [[[102.3671, -5.4789], [102.1355, -5.3606], [102.1988, -5.2886], [102.3719, -5.3663], [102.3671, -5.4789]]],
          [
            [
              [122.0432, -5.4379],
              [121.9799, -5.4644],
              [121.8085, -5.2566],
              [121.9654, -5.076],
              [122.0621, -5.2213],
              [122.0432, -5.4379]
            ]
          ],
          [
            [
              [122.6452, -5.2692],
              [122.564, -5.3875],
              [122.2831, -5.3193],
              [122.396, -5.0698],
              [122.3346, -4.8466],
              [122.3692, -4.7674],
              [122.7019, -4.6184],
              [122.7594, -4.9339],
              [122.614, -5.1387],
              [122.6452, -5.2692]
            ]
          ],
          [
            [
              [123.1795, -4.5513],
              [123.2032, -4.7661],
              [123.0552, -4.7481],
              [122.9717, -5.1383],
              [123.202, -5.2732],
              [122.9858, -5.3937],
              [122.8121, -5.6714],
              [122.6452, -5.6634],
              [122.5845, -5.5444],
              [122.7665, -5.2103],
              [122.8491, -4.8311],
              [122.8536, -4.6184],
              [122.9471, -4.4427],
              [123.0385, -4.3948],
              [123.1795, -4.5513]
            ]
          ],
          [
            [
              [123.242, -4.113],
              [123.1444, -4.233],
              [122.9945, -4.1478],
              [123.0249, -3.9812],
              [123.2121, -3.9974],
              [123.242, -4.113]
            ]
          ],
          [[[128.2755, -3.6749], [128.1432, -3.7329], [128.0162, -3.6006], [128.2645, -3.5123], [128.2755, -3.6749]]],
          [
            [
              [116.3034, -3.8683],
              [116.0934, -4.0537],
              [116.0767, -3.8177],
              [116.0183, -3.7],
              [116.1171, -3.3397],
              [116.2621, -3.3946],
              [116.3189, -3.7632],
              [116.3034, -3.8683]
            ]
          ],
          [
            [
              [126.8614, -3.0878],
              [127.0256, -3.166],
              [127.2274, -3.391],
              [127.2295, -3.6332],
              [126.9408, -3.7646],
              [126.6865, -3.8234],
              [126.2145, -3.6051],
              [126.0562, -3.4209],
              [126.0263, -3.1705],
              [126.555, -3.0654],
              [126.8614, -3.0878]
            ]
          ],
          [
            [
              [129.7547, -2.8659],
              [129.9844, -2.9767],
              [130.3791, -2.9894],
              [130.5698, -3.1308],
              [130.6713, -3.3915],
              [130.7732, -3.4188],
              [130.8599, -3.5703],
              [130.8054, -3.8578],
              [130.2697, -3.5796],
              [130.0197, -3.4745],
              [129.8444, -3.3274],
              [129.5117, -3.3286],
              [129.4677, -3.4535],
              [128.9676, -3.326],
              [128.8627, -3.235],
              [128.639, -3.4332],
              [128.4192, -3.4161],
              [128.2798, -3.2407],
              [128.0821, -3.184],
              [127.9701, -3.4442],
              [127.8778, -3.2218],
              [128.1986, -2.8659],
              [129.0577, -2.8387],
              [129.1161, -2.937],
              [129.4273, -2.7908],
              [129.7547, -2.8659]
            ]
          ],
          [[[100.4252, -3.1828], [100.204, -2.9867], [100.2455, -2.7833], [100.4546, -3.0017], [100.4252, -3.1828]]],
          [
            [
              [108.207, -2.9977],
              [108.1669, -3.1432],
              [108.0554, -3.2266],
              [107.9411, -3.1292],
              [107.6146, -3.2095],
              [107.5984, -2.8],
              [107.6664, -2.5662],
              [107.8378, -2.5306],
              [108.0743, -2.597],
              [108.2153, -2.6967],
              [108.2905, -2.8298],
              [108.207, -2.9977]
            ]
          ],
          [[[100.204, -2.7406], [100.0151, -2.8198], [100.012, -2.5103], [100.204, -2.7406]]],
          [[[99.8428, -2.3429], [99.6074, -2.2572], [99.5374, -2.1614], [99.6864, -2.0634], [99.8428, -2.3429]]],
          [[[126.0245, -1.7897], [125.9565, -1.9167], [125.4793, -1.94], [125.5205, -1.8007], [126.0245, -1.7897]]],
          [
            [
              [130.3532, -1.6908],
              [130.4252, -1.8046],
              [130.4191, -1.9712],
              [130.2482, -2.0481],
              [129.8866, -1.9861],
              [129.7375, -1.8666],
              [129.9938, -1.7589],
              [130.3532, -1.6908]
            ]
          ],
          [
            [
              [124.9694, -1.7058],
              [125.3201, -1.8098],
              [125.0068, -1.943],
              [124.8346, -1.8942],
              [124.639, -1.9783],
              [124.4175, -2.0054],
              [124.3297, -1.8586],
              [124.3806, -1.6873],
              [124.664, -1.6358],
              [124.9694, -1.7058]
            ]
          ],
          [
            [
              [135.4742, -1.5915],
              [135.8692, -1.642],
              [136.2015, -1.6552],
              [136.39, -1.7215],
              [136.7187, -1.7339],
              [136.8927, -1.7998],
              [136.4607, -1.8903],
              [136.1926, -1.8591],
              [135.8658, -1.7523],
              [135.4873, -1.6684],
              [135.4742, -1.5915]
            ]
          ],
          [
            [
              [106.0457, -1.6693],
              [106.1618, -1.8666],
              [106.2087, -2.1888],
              [106.3656, -2.4647],
              [106.8187, -2.5737],
              [106.679, -2.7042],
              [106.5471, -3.0558],
              [106.25, -2.8941],
              [105.9987, -2.8246],
              [105.9082, -2.6431],
              [105.9394, -2.4937],
              [105.8071, -2.3078],
              [105.7855, -2.1817],
              [105.5527, -2.0789],
              [105.3426, -2.125],
              [105.133, -2.0428],
              [105.191, -1.9172],
              [105.3748, -1.813],
              [105.3734, -1.6574],
              [105.5857, -1.5268],
              [105.91, -1.5049],
              [106.0457, -1.6693]
            ]
          ],
          [
            [
              [128.1528, -1.6604],
              [127.5617, -1.7282],
              [127.3922, -1.6447],
              [127.4568, -1.4536],
              [127.6466, -1.3322],
              [127.9054, -1.439],
              [128.1485, -1.6038],
              [128.1528, -1.6604]
            ]
          ],
          [
            [
              [123.2125, -1.1713],
              [123.1975, -1.2874],
              [123.4349, -1.2369],
              [123.547, -1.3371],
              [123.5122, -1.4474],
              [123.3667, -1.5072],
              [123.1501, -1.3045],
              [122.8904, -1.587],
              [122.8109, -1.4324],
              [122.9081, -1.1824],
              [123.2125, -1.1713]
            ]
          ],
          [
            [
              [109.7103, -1.1806],
              [109.5108, -1.2826],
              [109.4282, -1.2413],
              [109.4761, -0.985],
              [109.7433, -1.0395],
              [109.7103, -1.1806]
            ]
          ],
          [
            [
              [99.1639, -1.7778],
              [99.0716, -1.7835],
              [98.8743, -1.664],
              [98.6018, -1.1977],
              [98.6761, -0.9706],
              [98.869, -0.9156],
              [99.065, -1.2408],
              [99.131, -1.4424],
              [99.2672, -1.6276],
              [99.1639, -1.7778]
            ]
          ],
          [
            [
              [131.0019, -1.3154],
              [130.7821, -1.2557],
              [130.6731, -0.9599],
              [130.8973, -0.8901],
              [131.074, -0.9683],
              [131.0019, -1.3154]
            ]
          ],
          [
            [
              [135.3832, -0.6515],
              [135.7493, -0.7328],
              [135.8934, -0.7262],
              [136.2827, -1.0646],
              [136.3057, -1.1731],
              [136.1105, -1.2171],
              [135.8389, -1.1195],
              [135.747, -0.8233],
              [135.646, -0.8817],
              [135.4316, -0.7692],
              [135.3832, -0.6515]
            ]
          ],
          [
            [
              [140.9735, -2.6097],
              [140.9739, -4.0234],
              [140.9744, -5.0399],
              [140.9748, -6.3459],
              [140.8624, -6.7397],
              [140.9753, -6.9053],
              [140.9757, -8.2927],
              [140.9762, -9.1189],
              [140.6615, -8.8464],
              [140.4896, -8.6205],
              [140.1016, -8.3007],
              [139.9347, -8.1012],
              [139.6494, -8.1253],
              [139.3858, -8.189],
              [139.1929, -8.0861],
              [138.9331, -8.2624],
              [138.8561, -8.145],
              [139.0878, -7.5874],
              [138.9379, -7.4722],
              [138.7476, -7.2517],
              [138.919, -7.2038],
              [138.6013, -6.9366],
              [138.8645, -6.8584],
              [138.698, -6.6258],
              [138.4388, -6.3432],
              [138.2828, -5.8388],
              [138.0661, -5.6758],
              [138.0612, -5.4653],
              [137.7592, -5.2566],
              [137.3063, -5.0144],
              [137.0897, -4.9243],
              [136.619, -4.8188],
              [135.9796, -4.531],
              [135.4501, -4.4432],
              [135.196, -4.4505],
              [134.7539, -4.1957],
              [134.6796, -4.0792],
              [134.3909, -3.91],
              [134.2661, -3.9461],
              [134.1475, -3.7967],
              [133.9738, -3.8182],
              [133.7229, -3.5778],
              [133.6531, -3.3646],
              [133.4153, -3.7321],
              [133.4012, -3.8991],
              [133.2488, -4.0621],
              [132.9688, -4.0946],
              [132.837, -3.9492],
              [132.754, -3.7035],
              [132.87, -3.5511],
              [132.7513, -3.2948],
              [132.3483, -2.9753],
              [132.0539, -2.9142],
              [131.9713, -2.7885],
              [132.2306, -2.6804],
              [132.5754, -2.727],
              [132.725, -2.7894],
              [132.8972, -2.6585],
              [133.0334, -2.4875],
              [133.1911, -2.4378],
              [133.6087, -2.5473],
              [133.9049, -2.3908],
              [133.9215, -2.1021],
              [133.7106, -2.1892],
              [133.4878, -2.2255],
              [133.2251, -2.2141],
              [132.9631, -2.2727],
              [132.5026, -2.2186],
              [132.3079, -2.2424],
              [132.0231, -1.9901],
              [131.9362, -1.7149],
              [131.9305, -1.5599],
              [131.7317, -1.5409],
              [131.2936, -1.3938],
              [131.1178, -1.4552],
              [131.0462, -1.2839],
              [131.1517, -1.2189],
              [131.2541, -1.0071],
              [131.2571, -0.8559],
              [131.8042, -0.7038],
              [132.1287, -0.4537],
              [132.3941, -0.3553],
              [132.6251, -0.3588],
              [133.0769, -0.5122],
              [133.4725, -0.7262],
              [133.9747, -0.7442],
              [134.1115, -0.8466],
              [134.0718, -1.0018],
              [134.2595, -1.363],
              [134.1062, -1.7211],
              [134.1558, -2.1954],
              [134.4599, -2.8325],
              [134.4832, -2.5833],
              [134.6278, -2.5368],
              [134.7021, -2.9336],
              [134.8431, -2.9089],
              [134.8528, -3.1076],
              [134.9169, -3.2501],
              [135.0373, -3.3331],
              [135.3718, -3.3748],
              [135.4865, -3.3449],
              [135.8592, -2.9951],
              [136.0129, -2.734],
              [136.2432, -2.5833],
              [136.39, -2.2736],
              [136.843, -2.1975],
              [137.1709, -2.0257],
              [137.1234, -1.8411],
              [137.3805, -1.6855],
              [137.8064, -1.4834],
              [137.9109, -1.4839],
              [138.1109, -1.6162],
              [138.6497, -1.7911],
              [138.8114, -1.9181],
              [139.0394, -1.9918],
              [139.7897, -2.3482],
              [140.1543, -2.3505],
              [140.2515, -2.412],
              [140.6223, -2.4458],
              [140.7477, -2.607],
              [140.9735, -2.6097]
            ]
          ],
          [[[104.4738, -0.3346], [104.5903, -0.4669], [104.4857, -0.6128], [104.2567, -0.4634], [104.4738, -0.3346]]],
          [
            [
              [127.567, -0.3188],
              [127.6822, -0.4687],
              [127.6048, -0.6103],
              [127.8044, -0.6946],
              [127.88, -0.8084],
              [127.7608, -0.8835],
              [127.6238, -0.7658],
              [127.4625, -0.8057],
              [127.4687, -0.6431],
              [127.2999, -0.5004],
              [127.567, -0.3188]
            ]
          ],
          [[[127.2502, -0.4951], [127.1193, -0.5205], [127.1264, -0.2785], [127.2805, -0.3909], [127.2502, -0.4951]]],
          [[[103.7368, -0.3478], [103.4613, -0.3574], [103.5488, -0.2274], [103.7368, -0.3478]]],
          [
            [
              [130.8133, -0.0042],
              [131.0261, -0.0398],
              [131.2769, -0.1496],
              [131.3399, -0.2908],
              [131.2576, -0.3654],
              [131.0977, -0.3303],
              [130.75, -0.4436],
              [130.6889, -0.2969],
              [130.5509, -0.3663],
              [130.4964, -0.2674],
              [130.2367, -0.2099],
              [130.2948, -0.1013],
              [130.8133, -0.0042]
            ]
          ],
          [[[98.4595, -0.5307], [98.3096, -0.5319], [98.4273, -0.2265], [98.5438, -0.2577], [98.4595, -0.5307]]],
          [[[104.7784, -0.1761], [104.567, -0.2459], [104.4471, -0.1891], [104.5429, 0.0173], [104.7784, -0.1761]]],
          [
            [
              [103.0271, 0.7468],
              [102.5416, 0.8316],
              [102.4536, 0.8893],
              [102.5494, 1.1305],
              [102.7802, 0.9595],
              [103.0025, 0.8589],
              [103.0271, 0.7468]
            ]
          ],
          [
            [
              [103.1664, 0.8704],
              [103.0869, 0.848],
              [102.886, 0.9969],
              [102.7019, 1.0535],
              [102.7902, 1.1657],
              [103.0675, 1.0149],
              [103.1664, 0.8704]
            ]
          ],
          [
            [
              [104.5855, 1.2161],
              [104.6526, 0.9613],
              [104.5753, 0.8321],
              [104.4286, 0.9565],
              [104.2519, 1.0149],
              [104.3618, 1.1814],
              [104.5855, 1.2161]
            ]
          ],
          [
            [
              [102.4273, 0.9899],
              [102.3249, 1.007],
              [102.2287, 1.3481],
              [102.3587, 1.3458],
              [102.4427, 1.2341],
              [102.4273, 0.9899]
            ]
          ],
          [
            [
              [97.4812, 1.4649],
              [97.6983, 1.1837],
              [97.9321, 0.9741],
              [97.8764, 0.6282],
              [97.6839, 0.5961],
              [97.6039, 0.8339],
              [97.4057, 0.9468],
              [97.2967, 1.1871],
              [97.0791, 1.4254],
              [97.2444, 1.4236],
              [97.356, 1.5396],
              [97.4812, 1.4649]
            ]
          ],
          [
            [
              [102.4919, 1.4592],
              [102.2739, 1.453],
              [102.0787, 1.4984],
              [102.0422, 1.6254],
              [102.4695, 1.5098],
              [102.4919, 1.4592]
            ]
          ],
          [
            [
              [124.8891, 0.9956],
              [124.6983, 0.8255],
              [124.4271, 0.4709],
              [124.2782, 0.3983],
              [123.7539, 0.3057],
              [123.2652, 0.3263],
              [123.0825, 0.4859],
              [122.281, 0.4809],
              [121.8423, 0.4366],
              [121.4257, 0.4946],
              [121.0131, 0.4419],
              [120.7007, 0.5148],
              [120.4598, 0.5105],
              [120.349, 0.4494],
              [120.1276, 0.1668],
              [120.0357, -0.0899],
              [120.012, -0.307],
              [120.0973, -0.6502],
              [120.2699, -0.899],
              [120.5173, -1.0395],
              [120.6676, -1.3699],
              [121.0338, -1.4064],
              [121.1484, -1.3393],
              [121.2772, -1.1177],
              [121.5193, -0.8559],
              [121.7378, -0.9257],
              [121.9693, -0.9332],
              [122.2801, -0.7569],
              [122.8887, -0.7551],
              [122.8294, -0.6589],
              [123.1717, -0.5706],
              [123.3799, -0.6488],
              [123.434, -0.7786],
              [123.3783, -1.0044],
              [123.2258, -1.0018],
              [123.0495, -0.8725],
              [122.8078, -0.9661],
              [122.5068, -1.348],
              [122.1579, -1.5936],
              [121.8586, -1.6935],
              [121.7189, -1.8627],
              [121.5729, -1.9058],
              [121.3946, -1.834],
              [121.3488, -1.9457],
              [121.5747, -2.1509],
              [121.7259, -2.208],
              [121.8484, -2.3315],
              [122.0826, -2.7498],
              [122.2919, -2.9076],
              [122.3063, -3.0513],
              [122.3991, -3.2008],
              [122.317, -3.2754],
              [122.2527, -3.6204],
              [122.4342, -3.7399],
              [122.6101, -3.9232],
              [122.6896, -4.0845],
              [122.8773, -4.1091],
              [122.8974, -4.349],
              [122.7221, -4.4106],
              [122.4716, -4.4224],
              [122.2071, -4.4963],
              [122.0541, -4.6202],
              [122.073, -4.7916],
              [121.917, -4.8478],
              [121.5887, -4.7595],
              [121.4868, -4.5811],
              [121.5413, -4.2827],
              [121.6178, -4.0929],
              [121.3128, -3.9192],
              [120.9146, -3.5559],
              [120.9068, -3.4038],
              [121.0544, -3.1669],
              [121.0521, -2.7516],
              [120.9903, -2.6704],
              [120.654, -2.6677],
              [120.3411, -2.8695],
              [120.2537, -3.0531],
              [120.3926, -3.3479],
              [120.4369, -3.7071],
              [120.3627, -4.0858],
              [120.4198, -4.6176],
              [120.2792, -5.1462],
              [120.3912, -5.3928],
              [120.3117, -5.5417],
              [119.9518, -5.5777],
              [119.7171, -5.6933],
              [119.5575, -5.6112],
              [119.3765, -5.4253],
              [119.3603, -5.3141],
              [119.5199, -4.8773],
              [119.5158, -4.7419],
              [119.6116, -4.4238],
              [119.6235, -4.0343],
              [119.4934, -3.7685],
              [119.4943, -3.5541],
              [119.4196, -3.4754],
              [119.2399, -3.4754],
              [118.9947, -3.5379],
              [118.8677, -3.3981],
              [118.8127, -3.1568],
              [118.8584, -2.9288],
              [118.7838, -2.7648],
              [118.8536, -2.6501],
              [119.0923, -2.4832],
              [119.1722, -2.1404],
              [119.3217, -1.9299],
              [119.3089, -1.4082],
              [119.5085, -0.9068],
              [119.7114, -0.681],
              [119.7721, -0.4837],
              [119.7215, -0.0885],
              [119.8656, 0.0403],
              [119.809, 0.2384],
              [119.9135, 0.4449],
              [120.0348, 0.5666],
              [120.0564, 0.6927],
              [120.23, 0.8615],
              [120.2695, 0.9709],
              [120.5164, 0.8177],
              [120.7552, 1.036],
              [120.912, 1.2886],
              [121.0815, 1.3274],
              [121.4398, 1.2145],
              [121.5919, 1.0676],
              [121.8673, 1.0888],
              [122.1082, 1.0307],
              [122.4365, 1.0179],
              [122.8922, 0.8496],
              [122.9599, 0.9231],
              [123.278, 0.9279],
              [123.8467, 0.8382],
              [124.2738, 1.022],
              [124.4109, 1.185],
              [124.534, 1.2307],
              [124.5999, 1.3924],
              [124.788, 1.4676],
              [124.9892, 1.7013],
              [125.165, 1.6434],
              [125.2341, 1.5023],
              [125.1176, 1.3787],
              [125.028, 1.1801],
              [124.8891, 0.9956]
            ]
          ],
          [
            [
              [101.7079, 2.0783],
              [101.7738, 1.9435],
              [101.7198, 1.7892],
              [101.6028, 1.7159],
              [101.468, 1.7593],
              [101.4099, 2.0217],
              [101.6406, 2.1267],
              [101.7079, 2.0783]
            ]
          ],
          [
            [
              [127.7328, 0.848],
              [127.8808, 0.8321],
              [127.9674, 1.0426],
              [128.1608, 1.1577],
              [128.1573, 1.3169],
              [128.424, 1.5173],
              [128.6882, 1.5725],
              [128.7172, 1.3673],
              [128.7026, 1.1064],
              [128.2992, 0.877],
              [128.2609, 0.734],
              [128.6112, 0.5495],
              [128.6912, 0.3601],
              [127.9829, 0.4722],
              [127.8874, 0.2986],
              [127.8887, 0.0494],
              [127.9775, -0.2486],
              [128.0896, -0.4853],
              [128.2536, -0.7319],
              [128.0465, -0.706],
              [127.8887, -0.4238],
              [127.6919, -0.242],
              [127.7086, 0.2884],
              [127.5556, 0.4898],
              [127.5416, 0.6804],
              [127.608, 0.848],
              [127.5205, 0.924],
              [127.4203, 1.2518],
              [127.5371, 1.4671],
              [127.5346, 1.5721],
              [127.6317, 1.8437],
              [127.8997, 2.1378],
              [127.8865, 1.8332],
              [128.0109, 1.7013],
              [128.0118, 1.3317],
              [127.8853, 1.1625],
              [127.6527, 1.014],
              [127.7328, 0.848]
            ]
          ],
          [
            [
              [128.4538, 2.0521],
              [128.2958, 2.0345],
              [128.218, 2.2972],
              [128.3304, 2.4691],
              [128.602, 2.5973],
              [128.6885, 2.4735],
              [128.623, 2.2247],
              [128.4538, 2.0521]
            ]
          ],
          [
            [
              [96.4634, 2.3601],
              [96.0218, 2.5957],
              [95.8087, 2.6554],
              [95.7173, 2.8258],
              [95.806, 2.9159],
              [96.4174, 2.5152],
              [96.4634, 2.3601]
            ]
          ],
          [[[117.8851, 4.1861], [117.9229, 4.0542], [117.7369, 4.0041], [117.6491, 4.1689], [117.8851, 4.1861]]],
          [
            [
              [108.316, 3.6899],
              [108.2431, 3.8107],
              [108.0887, 3.8525],
              [108.0034, 4.0427],
              [108.2021, 4.2005],
              [108.3933, 3.9865],
              [108.3938, 3.8362],
              [108.316, 3.6899]
            ]
          ],
          [
            [
              [117.5747, 4.1706],
              [117.4654, 4.0762],
              [117.7282, 3.7966],
              [117.6301, 3.6363],
              [117.1661, 3.5923],
              [117.3845, 3.3656],
              [117.3524, 3.1937],
              [117.6103, 3.0646],
              [117.639, 2.8251],
              [117.7857, 2.7464],
              [117.886, 2.5419],
              [118.0666, 2.3179],
              [118.0415, 2.2151],
              [117.8816, 2.0608],
              [117.8648, 1.9686],
              [118.0805, 1.7018],
              [118.6392, 1.319],
              [118.9849, 0.9819],
              [118.8923, 0.8866],
              [118.5345, 0.8132],
              [118.1961, 0.8743],
              [118.0164, 1.0396],
              [117.9229, 0.8312],
              [117.7448, 0.7297],
              [117.522, 0.2357],
              [117.4627, -0.3237],
              [117.5489, -0.5544],
              [117.5624, -0.7711],
              [117.2408, -0.9257],
              [117.0035, -1.1877],
              [116.7711, -1.2668],
              [116.7152, -1.3758],
              [116.5544, -1.4741],
              [116.4243, -1.7849],
              [116.4234, -2.0529],
              [116.5658, -2.2994],
              [116.5294, -2.5103],
              [116.3755, -2.5781],
              [116.3536, -2.8325],
              [116.1725, -3.0255],
              [116.1668, -3.1828],
              [115.9563, -3.5949],
              [114.6937, -4.1698],
              [114.6252, -4.1118],
              [114.6058, -3.703],
              [114.536, -3.4948],
              [114.446, -3.4816],
              [114.0825, -3.279],
              [113.9585, -3.3942],
              [113.7954, -3.4565],
              [113.6337, -3.42],
              [113.6303, -3.246],
              [113.5256, -3.184],
              [113.3432, -3.2464],
              [113.034, -2.9336],
              [112.9715, -3.1871],
              [112.6002, -3.4003],
              [112.2851, -3.3212],
              [112.1264, -3.3814],
              [111.9551, -3.5294],
              [111.8224, -3.5322],
              [111.8233, -3.0574],
              [111.6949, -2.8896],
              [111.6254, -2.9758],
              [111.3675, -2.9336],
              [111.0445, -3.0558],
              [110.7031, -3.0211],
              [110.5739, -2.8914],
              [110.2325, -2.9252],
              [110.2241, -2.6889],
              [110.0751, -1.9461],
              [109.96, -1.8627],
              [110.0236, -1.6424],
              [110.0193, -1.3991],
              [109.9384, -1.1811],
              [109.7872, -1.0114],
              [109.4542, -0.8691],
              [109.3338, -0.8752],
              [109.2713, -0.7323],
              [109.3728, -0.6383],
              [109.1605, -0.4947],
              [109.1219, -0.2653],
              [109.1949, -0.0094],
              [109.2572, 0.0314],
              [108.9443, 0.3557],
              [108.9057, 0.7939],
              [108.9584, 1.1349],
              [109.0757, 1.4957],
              [109.2736, 1.7057],
              [109.3785, 1.9228],
              [109.6291, 2.0274],
              [109.539, 1.8961],
              [109.6361, 1.7764],
              [109.6542, 1.6152],
              [109.818, 1.4389],
              [110.315, 0.996],
              [110.5057, 0.8619],
              [110.9382, 1.017],
              [111.2867, 1.0435],
              [111.4831, 0.996],
              [111.7697, 0.9995],
              [111.923, 1.113],
              [112.0781, 1.1433],
              [112.1859, 1.4394],
              [112.4762, 1.559],
              [112.943, 1.5673],
              [113.0066, 1.4341],
              [113.6223, 1.2359],
              [113.9023, 1.4346],
              [114.387, 1.5],
              [114.5461, 1.4671],
              [114.7034, 1.8507],
              [114.8304, 1.98],
              [114.7865, 2.2502],
              [115.1789, 2.5232],
              [115.0809, 2.6343],
              [115.0861, 2.8413],
              [115.2466, 3.0258],
              [115.4544, 3.0342],
              [115.5661, 3.4456],
              [115.5445, 3.6336],
              [115.5687, 3.9391],
              [115.6786, 4.1931],
              [115.8961, 4.3486],
              [116.0213, 4.291],
              [116.1347, 4.3552],
              [116.4147, 4.3081],
              [116.5143, 4.3706],
              [117.1006, 4.3372],
              [117.2778, 4.2994],
              [117.5747, 4.1706]
            ]
          ],
          [
            [
              [126.8165, 4.0335],
              [126.7045, 4.0713],
              [126.8138, 4.2586],
              [126.7225, 4.3441],
              [126.7572, 4.5477],
              [126.8653, 4.48],
              [126.9211, 4.291],
              [126.8165, 4.0335]
            ]
          ],
          [
            [
              [96.4924, 5.2293],
              [96.8427, 5.2745],
              [97.1902, 5.2073],
              [97.4513, 5.2359],
              [97.5876, 5.1703],
              [97.9083, 4.88],
              [98.0209, 4.6351],
              [98.2485, 4.4146],
              [98.2415, 4.1943],
              [98.3074, 4.0928],
              [98.6585, 3.9281],
              [98.7781, 3.7594],
              [99.1511, 3.5809],
              [99.7321, 3.1827],
              [99.9066, 2.988],
              [100.1276, 2.6475],
              [100.4571, 2.2573],
              [100.6853, 2.1201],
              [100.8167, 1.9891],
              [100.8281, 2.2427],
              [101.0461, 2.2573],
              [101.3006, 2.012],
              [101.4051, 1.7576],
              [101.4767, 1.6929],
              [101.7848, 1.6213],
              [102.0203, 1.4421],
              [102.1574, 1.2592],
              [102.2388, 0.9908],
              [102.4695, 0.7794],
              [102.8492, 0.7153],
              [102.9494, 0.6642],
              [103.0663, 0.492],
              [103.4789, 0.4802],
              [103.6727, 0.2889],
              [103.7869, 0.0467],
              [103.4287, -0.1914],
              [103.4314, -0.5332],
              [103.5329, -0.7548],
              [103.721, -0.8867],
              [104.1983, -1.0541],
              [104.3609, -1.0386],
              [104.4471, -1.3626],
              [104.516, -1.8191],
              [104.5687, -1.922],
              [104.8451, -2.0933],
              [104.8259, -2.2344],
              [104.6685, -2.3856],
              [104.6307, -2.5434],
              [104.7358, -2.571],
              [104.8786, -2.4189],
              [105.0257, -2.358],
              [105.3967, -2.3803],
              [105.5821, -2.4919],
              [105.8995, -2.8879],
              [106.0444, -3.1062],
              [106.058, -3.217],
              [105.9016, -3.4104],
              [105.8441, -3.6134],
              [105.9275, -3.8811],
              [105.8313, -4.1632],
              [105.8863, -4.5543],
              [105.8875, -5.0096],
              [105.8163, -5.6767],
              [105.7483, -5.8181],
              [105.6181, -5.7996],
              [105.3492, -5.5492],
              [105.0811, -5.7456],
              [104.6396, -5.5207],
              [104.6184, -5.6415],
              [104.6838, -5.8928],
              [104.6013, -5.9043],
              [104.0669, -5.3861],
              [103.8314, -5.0799],
              [103.4055, -4.8167],
              [103.1388, -4.5964],
              [102.919, -4.4708],
              [102.5375, -4.1522],
              [102.3724, -3.9689],
              [102.1275, -3.5994],
              [101.8178, -3.3778],
              [101.5786, -3.1669],
              [101.3054, -2.7288],
              [101.1186, -2.5877],
              [100.9446, -2.3452],
              [100.8479, -2.1439],
              [100.8553, -1.9343],
              [100.4867, -1.2992],
              [100.3082, -0.8269],
              [99.8601, -0.3136],
              [99.7212, -0.0331],
              [99.5976, 0.1021],
              [99.3344, 0.209],
              [99.1591, 0.3518],
              [98.7961, 1.4948],
              [98.5646, 1.9022],
              [98.0864, 2.1953],
              [97.795, 2.2827],
              [97.7005, 2.3588],
              [97.5911, 2.8466],
              [97.3911, 2.9754],
              [96.9692, 3.5752],
              [96.8009, 3.7083],
              [96.445, 3.8164],
              [96.2301, 4.073],
              [95.988, 4.2634],
              [95.4949, 4.7612],
              [95.2066, 5.2838],
              [95.2277, 5.565],
              [95.396, 5.6287],
              [95.629, 5.6095],
              [95.8411, 5.5144],
              [96.1335, 5.2939],
              [96.4924, 5.2293]
            ]
          ]
        ]
      },
      properties: { name: "Indonesia", id: "ID", Continent: "Asia" },
      id: "ID"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[114.0152, 22.5116], [114.2656, 22.541], [114.2679, 22.2954], [113.8966, 22.4281], [114.0152, 22.5116]]
        ]
      },
      properties: { name: "Hong Kong", id: "HK", Continent: "Asia" },
      id: "HK"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [110.8886, 19.9918],
              [110.9707, 19.8832],
              [111.0138, 19.6552],
              [110.8222, 19.558],
              [110.5721, 19.1717],
              [110.4512, 18.7482],
              [110.2517, 18.6559],
              [110.0483, 18.5052],
              [109.8158, 18.3971],
              [109.7025, 18.2591],
              [109.5192, 18.2186],
              [109.4001, 18.281],
              [109.0296, 18.3676],
              [108.7019, 18.5355],
              [108.6355, 18.9077],
              [108.6499, 19.265],
              [108.6935, 19.3384],
              [109.179, 19.6741],
              [109.2186, 19.8424],
              [109.3149, 19.9044],
              [109.5135, 19.9044],
              [109.651, 19.9843],
              [109.9064, 19.9628],
              [110.1718, 20.0538],
              [110.5884, 20.0727],
              [110.679, 20.1368],
              [110.8886, 19.9918]
            ]
          ],
          [[[122.2958, 29.9635], [122.0242, 30.0135], [122.1109, 30.1397], [122.2844, 30.068], [122.2958, 29.9635]]],
          [
            [
              [121.8625, 31.4923],
              [121.7804, 31.4638],
              [121.3365, 31.6439],
              [121.4639, 31.7564],
              [121.5768, 31.6373],
              [121.8625, 31.4923]
            ]
          ],
          [
            [
              [130.5267, 42.5353],
              [130.2469, 42.7449],
              [130.2407, 42.8918],
              [129.8984, 42.998],
              [129.7792, 42.7766],
              [129.7199, 42.4751],
              [129.5235, 42.3846],
              [129.314, 42.4136],
              [129.1956, 42.2185],
              [128.9236, 42.0383],
              [128.0452, 41.9874],
              [128.0329, 41.8981],
              [128.2908, 41.5628],
              [128.1111, 41.3892],
              [127.9186, 41.4608],
              [127.6879, 41.4402],
              [127.1795, 41.5312],
              [127.0067, 41.7421],
              [126.9036, 41.7812],
              [126.6012, 41.6411],
              [126.4904, 41.358],
              [125.989, 40.905],
              [125.7285, 40.8668],
              [125.4168, 40.6597],
              [124.8895, 40.4597],
              [124.3863, 40.1042],
              [124.3621, 40.0041],
              [124.1055, 39.841],
              [123.3483, 39.7633],
              [123.0324, 39.6736],
              [122.335, 39.3664],
              [122.1209, 39.1519],
              [121.8643, 38.9964],
              [121.6776, 39.0035],
              [121.6498, 38.865],
              [121.3203, 38.8084],
              [121.1634, 38.7314],
              [121.1066, 38.9208],
              [121.6801, 39.1089],
              [121.6279, 39.2201],
              [121.7852, 39.4012],
              [121.2758, 39.3849],
              [121.2671, 39.5448],
              [121.5175, 39.6389],
              [121.5171, 39.845],
              [121.801, 39.9505],
              [122.1912, 40.3583],
              [122.2748, 40.5419],
              [122.1403, 40.6883],
              [121.8591, 40.8421],
              [121.1743, 40.9014],
              [120.7705, 40.5895],
              [120.4792, 40.2308],
              [119.591, 39.9025],
              [119.391, 39.7527],
              [119.2245, 39.4082],
              [118.9124, 39.1665],
              [118.6264, 39.1766],
              [118.2976, 39.0672],
              [118.0406, 39.2272],
              [117.7845, 39.1344],
              [117.6165, 38.8527],
              [117.5576, 38.625],
              [117.7669, 38.3117],
              [118.0151, 38.1834],
              [118.5434, 38.0951],
              [118.8, 38.1268],
              [118.9402, 38.0428],
              [119.0891, 37.7009],
              [118.9906, 37.6416],
              [118.953, 37.3309],
              [119.1119, 37.2008],
              [119.4499, 37.1248],
              [119.7607, 37.1546],
              [119.8827, 37.3511],
              [120.1557, 37.4948],
              [120.3702, 37.7009],
              [120.7504, 37.8341],
              [121.0491, 37.7251],
              [121.2192, 37.6002],
              [121.388, 37.5788],
              [121.6402, 37.4601],
              [121.9645, 37.4451],
              [122.0564, 37.5291],
              [122.3376, 37.4051],
              [122.6025, 37.4267],
              [122.5877, 37.1811],
              [122.4466, 37.0681],
              [122.5195, 36.9468],
              [122.3412, 36.8321],
              [122.1622, 36.9586],
              [121.9328, 36.9595],
              [121.6696, 36.8361],
              [120.9898, 36.5979],
              [120.8957, 36.4441],
              [120.6822, 36.3408],
              [120.6808, 36.1685],
              [120.3935, 36.0539],
              [120.3276, 36.2279],
              [120.0941, 36.1189],
              [120.2195, 35.9352],
              [120.0546, 35.8613],
              [119.8665, 35.6438],
              [119.7198, 35.5889],
              [119.4298, 35.3015],
              [119.3528, 35.1138],
              [119.2157, 35.0119],
              [119.2008, 34.7487],
              [119.3516, 34.7496],
              [119.5826, 34.582],
              [119.9637, 34.4481],
              [120.2015, 34.3255],
              [120.3226, 34.1686],
              [120.5046, 33.6381],
              [120.6158, 33.4906],
              [120.8708, 33.0163],
              [120.853, 32.6612],
              [120.9898, 32.5672],
              [121.2934, 32.4573],
              [121.401, 32.3721],
              [121.4042, 32.2065],
              [121.6744, 32.0508],
              [121.8322, 31.8997],
              [121.866, 31.7032],
              [121.681, 31.7125],
              [121.3519, 31.8588],
              [120.9735, 31.8694],
              [121.0557, 31.7194],
              [121.351, 31.4852],
              [121.6608, 31.3201],
              [121.7857, 31.1627],
              [121.8779, 30.9167],
              [121.5273, 30.841],
              [121.3102, 30.7],
              [120.9972, 30.558],
              [120.8215, 30.3546],
              [120.4496, 30.3875],
              [120.6334, 30.1331],
              [120.9045, 30.1603],
              [121.1595, 30.3014],
              [121.4327, 30.2267],
              [121.678, 29.9788],
              [121.9442, 29.894],
              [121.9056, 29.7797],
              [121.6767, 29.5838],
              [121.9412, 29.6062],
              [121.9684, 29.4906],
              [121.9175, 29.135],
              [121.7175, 29.2564],
              [121.5337, 29.2365],
              [121.5207, 29.1188],
              [121.6653, 29.0107],
              [121.6301, 28.7681],
              [121.4749, 28.6416],
              [121.6019, 28.3665],
              [121.3549, 28.2297],
              [121.2165, 28.3462],
              [121.0988, 28.2904],
              [120.9586, 28.0368],
              [120.6852, 27.7442],
              [120.5876, 27.5807],
              [120.629, 27.4819],
              [120.5397, 27.3184],
              [120.2788, 27.0973],
              [120.1386, 26.8863],
              [120.0868, 26.6715],
              [119.8823, 26.6109],
              [119.8152, 26.7976],
              [119.638, 26.6214],
              [119.7848, 26.5467],
              [119.8811, 26.334],
              [119.4636, 26.0545],
              [119.6485, 25.9187],
              [119.5391, 25.5913],
              [119.6191, 25.4376],
              [119.2631, 25.4683],
              [119.2355, 25.2064],
              [119.025, 25.2235],
              [118.9142, 25.1268],
              [118.909, 24.9286],
              [118.6369, 24.8355],
              [118.7195, 24.7462],
              [118.5605, 24.5801],
              [118.2955, 24.5726],
              [118.0871, 24.6267],
              [118.0059, 24.4817],
              [118.0561, 24.2461],
              [117.8393, 24.0119],
              [117.7414, 24.0146],
              [117.6285, 23.8365],
              [117.4662, 23.8406],
              [117.3309, 23.7088],
              [117.0821, 23.5791],
              [116.9104, 23.6468],
              [116.8607, 23.4534],
              [116.5381, 23.1796],
              [116.4705, 22.9458],
              [116.2516, 22.9814],
              [116.1572, 22.8874],
              [115.8521, 22.8017],
              [115.6403, 22.8535],
              [115.4984, 22.7191],
              [115.1956, 22.817],
              [114.8542, 22.6172],
              [114.6515, 22.7556],
              [114.496, 22.5271],
              [114.2656, 22.541],
              [114.0152, 22.5116],
              [113.828, 22.6069],
              [113.6198, 22.8614],
              [113.5863, 23.0201],
              [113.3311, 22.912],
              [113.553, 22.5939],
              [113.5468, 22.2243],
              [113.4944, 22.2414],
              [113.4786, 22.1957],
              [113.2664, 22.0888],
              [113.0084, 22.1192],
              [112.9838, 21.9381],
              [112.8085, 21.9443],
              [112.5861, 21.7769],
              [112.4947, 21.8181],
              [112.3054, 21.7417],
              [111.9437, 21.8494],
              [111.603, 21.5593],
              [111.3191, 21.4864],
              [111.1004, 21.4847],
              [110.7711, 21.3867],
              [110.5673, 21.2143],
              [110.4583, 21.2307],
              [110.1937, 21.0378],
              [110.1801, 20.8588],
              [110.3652, 20.8373],
              [110.3128, 20.6716],
              [110.5114, 20.5183],
              [110.4496, 20.3553],
              [110.123, 20.2638],
              [109.9384, 20.295],
              [109.9463, 20.4743],
              [109.7917, 20.622],
              [109.8052, 20.7112],
              [109.6624, 20.9169],
              [109.6813, 21.1317],
              [109.7794, 21.3379],
              [109.931, 21.4806],
              [109.5443, 21.5378],
              [109.1487, 21.4257],
              [109.0305, 21.6266],
              [108.7717, 21.6305],
              [108.4803, 21.9043],
              [108.5261, 21.6715],
              [108.3247, 21.6938],
              [108.2461, 21.5584],
              [107.9728, 21.508],
              [107.8018, 21.6455],
              [107.3514, 21.609],
              [107.1786, 21.7171],
              [107.0064, 21.8934],
              [106.7296, 22.0006],
              [106.6543, 22.2414],
              [106.5362, 22.3956],
              [106.5823, 22.5736],
              [106.7805, 22.7789],
              [106.5419, 22.908],
              [106.2496, 22.8694],
              [106.1837, 22.9551],
              [106.001, 22.9748],
              [105.8432, 22.923],
              [105.5483, 23.0728],
              [105.4947, 23.1805],
              [105.2754, 23.3453],
              [104.8645, 23.1362],
              [104.796, 22.9111],
              [104.6874, 22.8219],
              [104.527, 22.8044],
              [104.3719, 22.7041],
              [104.2123, 22.8096],
              [104.0542, 22.7524],
              [103.9153, 22.5385],
              [103.6204, 22.7819],
              [103.4714, 22.5973],
              [103.301, 22.7643],
              [103.0052, 22.4532],
              [102.8303, 22.5873],
              [102.5174, 22.7406],
              [102.4281, 22.7326],
              [102.237, 22.466],
              [102.1275, 22.3789],
              [102.0247, 22.4396],
              [101.8415, 22.3882],
              [101.6714, 22.4624],
              [101.5241, 22.2537],
              [101.6028, 21.9896],
              [101.7439, 21.7782],
              [101.7047, 21.1502],
              [101.5426, 21.2341],
              [101.2817, 21.184],
              [101.224, 21.2236],
              [101.1392, 21.5677],
              [101.1207, 21.7461],
              [100.8352, 21.6551],
              [100.6769, 21.5048],
              [100.5315, 21.4578],
              [100.3508, 21.5009],
              [100.2147, 21.4626],
              [100.0893, 21.558],
              [99.9255, 21.8209],
              [99.918, 22.0277],
              [99.5924, 22.0893],
              [99.1929, 22.1258],
              [99.243, 22.3702],
              [99.3374, 22.498],
              [99.3853, 22.8249],
              [99.5071, 22.959],
              [99.3411, 23.0961],
              [98.8638, 23.1914],
              [98.8852, 23.3075],
              [98.7979, 23.5202],
              [98.8321, 23.6247],
              [98.6809, 23.8415],
              [98.7639, 24.1161],
              [98.2125, 24.1108],
              [98.0164, 24.0655],
              [97.686, 23.8981],
              [97.5683, 23.9886],
              [97.6905, 24.1307],
              [97.6666, 24.3802],
              [97.5313, 24.4914],
              [97.5836, 24.7749],
              [97.7238, 24.8421],
              [97.715, 25.0346],
              [97.8196, 25.252],
              [97.9181, 25.2358],
              [98.0996, 25.4155],
              [98.1726, 25.5945],
              [98.3338, 25.5865],
              [98.4656, 25.789],
              [98.6546, 25.9178],
              [98.732, 26.5837],
              [98.716, 27.0446],
              [98.6748, 27.191],
              [98.651, 27.5725],
              [98.5047, 27.6475],
              [98.2991, 27.5499],
              [98.1304, 27.9675],
              [98.0987, 28.1419],
              [97.8649, 28.3634],
              [97.538, 28.5102],
              [97.3227, 28.2179],
              [97.0752, 28.3691],
              [96.7759, 28.367],
              [96.6023, 28.46],
              [96.3663, 28.367],
              [96.2815, 28.4117],
              [96.3957, 28.6064],
              [96.5813, 28.7633],
              [96.4354, 29.0507],
              [96.162, 28.9097],
              [96.122, 29.0823],
              [96.2349, 29.2454],
              [96.035, 29.447],
              [95.516, 29.2067],
              [95.4202, 29.0543],
              [95.2792, 29.0493],
              [94.9992, 29.1492],
              [94.7698, 29.1759],
              [94.623, 29.3127],
              [94.2934, 29.1448],
              [93.9023, 28.8028],
              [93.6646, 28.6904],
              [93.3609, 28.6539],
              [93.2067, 28.5911],
              [93.1192, 28.4021],
              [92.8819, 28.2281],
              [92.7017, 28.1467],
              [92.6644, 27.949],
              [92.4148, 27.8247],
              [92.1015, 27.8079],
              [91.9775, 27.7301],
              [91.6317, 27.76],
              [91.6057, 27.9517],
              [91.226, 28.0712],
              [91.0779, 27.9745],
              [90.7154, 28.0721],
              [90.3529, 28.0804],
              [90.3481, 28.2439],
              [89.9811, 28.3111],
              [89.5368, 28.1077],
              [88.9475, 27.4639],
              [88.8913, 27.3162],
              [88.7493, 27.5219],
              [88.8486, 27.8685],
              [88.8038, 28.0069],
              [88.6214, 28.0918],
              [88.109, 27.9332],
              [88.1099, 27.8703],
              [87.8607, 27.8861],
              [87.6828, 27.8215],
              [87.1414, 27.8382],
              [87.0201, 27.9283],
              [86.5547, 28.0852],
              [86.4088, 27.9283],
              [86.0787, 28.0835],
              [85.954, 27.9283],
              [85.8401, 28.1353],
              [85.6784, 28.2773],
              [85.1225, 28.3159],
              [85.1589, 28.5923],
              [84.8553, 28.5537],
              [84.7142, 28.5959],
              [84.2286, 28.9118],
              [84.1016, 29.2199],
              [83.936, 29.2792],
              [83.5835, 29.1834],
              [83.1555, 29.6128],
              [82.854, 29.683],
              [82.4866, 29.9414],
              [82.1593, 30.1151],
              [82.0432, 30.327],
              [81.642, 30.3875],
              [81.417, 30.3375],
              [81.2553, 30.0935],
              [81.1103, 30.0364],
              [81.0101, 30.1642],
              [80.9855, 30.2372],
              [80.6823, 30.4148],
              [80.1914, 30.5685],
              [80.1945, 30.7593],
              [79.9246, 30.8889],
              [79.9167, 30.8942],
              [79.7946, 30.9685],
              [79.5656, 30.9491],
              [79.3696, 31.0797],
              [79.3384, 31.1055],
              [79.1073, 31.4027],
              [78.8445, 31.3016],
              [78.7272, 31.4717],
              [78.8027, 31.6179],
              [78.6938, 31.7402],
              [78.7196, 31.8877],
              [78.7355, 31.9577],
              [78.4959, 32.2156],
              [78.3897, 32.5198],
              [78.7007, 32.5971],
              [78.9192, 32.358],
              [79.2193, 32.5013],
              [79.2167, 32.5641],
              [79.2022, 32.946],
              [79.1086, 33.0224],
              [79.1354, 33.1718],
              [79.1125, 33.2263],
              [78.8018, 33.4997],
              [78.7531, 34.0878],
              [78.9706, 34.2283],
              [78.9363, 34.3523],
              [78.6709, 34.5179],
              [78.282, 34.6542],
              [78.0121, 35.251],
              [78.0473, 35.4496],
              [77.8109, 35.4843],
              [77.799, 35.4962],
              [77.4466, 35.4755],
              [76.8788, 35.6131],
              [76.5633, 35.773],
              [76.5514, 35.8873],
              [76.2517, 35.8108],
              [76.0706, 35.9831],
              [75.945, 36.0178],
              [75.9744, 36.3826],
              [75.8404, 36.6497],
              [75.6672, 36.742],
              [75.4242, 36.7381],
              [75.3468, 36.9134],
              [75.0542, 36.9872],
              [74.8894, 36.9525],
              [74.6925, 37.036],
              [74.5414, 37.0219],
              [74.3722, 37.1577],
              [74.7263, 37.2909],
              [74.8912, 37.2316],
              [75.1188, 37.3858],
              [74.8943, 37.6016],
              [74.9382, 37.7721],
              [74.7896, 38.1034],
              [74.8358, 38.4045],
              [74.7452, 38.51],
              [74.2772, 38.6598],
              [74.1314, 38.6611],
              [74.0259, 38.5398],
              [73.8013, 38.607],
              [73.6958, 38.8544],
              [73.7956, 39.0026],
              [73.6075, 39.2293],
              [73.6317, 39.449],
              [73.8228, 39.4891],
              [73.9147, 39.6064],
              [73.8351, 39.8001],
              [73.9916, 40.0432],
              [74.4117, 40.1373],
              [74.6798, 40.3104],
              [75.0045, 40.4492],
              [75.241, 40.4805],
              [75.5837, 40.6052],
              [75.6563, 40.3293],
              [75.872, 40.3033],
              [76.2583, 40.4308],
              [76.4802, 40.4492],
              [76.6397, 40.7424],
              [76.8238, 40.9823],
              [76.9869, 41.0394],
              [77.5815, 40.9929],
              [77.8152, 41.0557],
              [78.1233, 41.0759],
              [78.3461, 41.2811],
              [78.3624, 41.3717],
              [78.7425, 41.5601],
              [79.2936, 41.7829],
              [79.766, 41.8985],
              [79.8407, 41.9961],
              [80.216, 42.0322],
              [80.2094, 42.1899],
              [80.259, 42.2351],
              [80.1651, 42.6654],
              [80.2503, 42.7972],
              [80.5438, 42.9115],
              [80.39, 43.0429],
              [80.7856, 43.1615],
              [80.6678, 43.3101],
              [80.7038, 43.427],
              [80.4959, 43.8924],
              [80.3553, 44.0972],
              [80.3364, 44.4381],
              [80.4555, 44.7462],
              [80.2551, 44.808],
              [79.9971, 44.7974],
              [79.8719, 44.8839],
              [80.0591, 45.0064],
              [80.6348, 45.1263],
              [80.8532, 45.1294],
              [81.6021, 45.3109],
              [81.9892, 45.1617],
              [82.2665, 45.219],
              [82.4788, 45.1237],
              [82.6212, 45.2931],
              [82.5824, 45.4427],
              [82.3262, 45.5201],
              [82.5552, 46.1588],
              [82.6923, 46.3865],
              [83.0043, 47.0336],
              [83.0294, 47.1859],
              [83.1929, 47.1865],
              [83.634, 47.043],
              [84.016, 46.9707],
              [84.339, 46.996],
              [84.6668, 46.9725],
              [84.7859, 46.8307],
              [85.2337, 47.0364],
              [85.4846, 47.0634],
              [85.6564, 47.2549],
              [85.5259, 47.9157],
              [85.6265, 48.2038],
              [85.7492, 48.3849],
              [86.2655, 48.4546],
              [86.5494, 48.5288],
              [86.7177, 48.6971],
              [86.7533, 49.0087],
              [86.886, 49.0906],
              [87.3228, 49.0858],
              [87.8141, 49.1623],
              [87.8598, 48.9654],
              [87.7434, 48.8817],
              [88.0278, 48.7355],
              [87.9798, 48.5549],
              [88.5168, 48.3845],
              [88.5762, 48.2201],
              [89.0477, 48.0027],
              [89.561, 48.0041],
              [89.7781, 47.827],
              [90.0281, 47.8775],
              [90.1033, 47.7453],
              [90.3133, 47.6761],
              [90.4961, 47.2854],
              [90.7154, 47.0039],
              [90.8701, 46.9547],
              [91.034, 46.529],
              [90.9118, 46.2707],
              [91.002, 46.0358],
              [90.7097, 45.7308],
              [90.6618, 45.5254],
              [90.8771, 45.196],
              [91.0499, 45.2173],
              [91.3122, 45.1182],
              [91.5842, 45.0765],
              [92.0298, 45.0685],
              [92.4236, 45.009],
              [92.7874, 45.0358],
              [93.2946, 44.9833],
              [93.6563, 44.9007],
              [93.9577, 44.6748],
              [94.1994, 44.6452],
              [94.3647, 44.5194],
              [94.7118, 44.3507],
              [95.0497, 44.2593],
              [95.3504, 44.2782],
              [95.3565, 44.0057],
              [95.5257, 43.9539],
              [95.842, 43.3834],
              [95.9128, 43.2064],
              [96.2995, 42.9286],
              [96.3852, 42.7203],
              [97.2057, 42.7897],
              [97.719, 42.7361],
              [99.4676, 42.5683],
              [99.9839, 42.6773],
              [101.0918, 42.5512],
              [101.4956, 42.5389],
              [101.7141, 42.4655],
              [101.9733, 42.2159],
              [102.157, 42.1578],
              [103.0728, 42.0062],
              [103.711, 41.7513],
              [104.4984, 41.877],
              [104.4984, 41.6586],
              [105.0503, 41.616],
              [105.1971, 41.7381],
              [105.8673, 41.9943],
              [106.7704, 42.2888],
              [108.1714, 42.4474],
              [108.6873, 42.4162],
              [109.4432, 42.4558],
              [110.4003, 42.7735],
              [111.0072, 43.3413],
              [111.5472, 43.4964],
              [111.7709, 43.6647],
              [111.933, 43.7117],
              [111.8368, 43.935],
              [111.52, 44.1921],
              [111.4022, 44.3674],
              [111.6211, 44.827],
              [111.8979, 45.0642],
              [112.4112, 45.0585],
              [112.5966, 44.9176],
              [113.0493, 44.8102],
              [113.5872, 44.7458],
              [114.0305, 44.9424],
              [114.4191, 45.2026],
              [114.5171, 45.3648],
              [114.739, 45.4197],
              [114.9192, 45.3783],
              [115.6808, 45.4584],
              [116.2407, 45.7961],
              [116.2133, 45.8871],
              [116.5622, 46.2898],
              [116.8589, 46.3878],
              [117.3335, 46.3621],
              [117.4055, 46.5706],
              [117.671, 46.5218],
              [118.3087, 46.7172],
              [118.7231, 46.6919],
              [118.844, 46.7602],
              [119.1621, 46.6387],
              [119.7066, 46.6062],
              [119.8674, 46.6723],
              [119.8977, 46.8578],
              [119.711, 47.15],
              [119.1229, 47.5583],
              [119.0821, 47.6543],
              [118.7596, 47.7578],
              [118.4985, 47.9841],
              [118.1469, 48.0289],
              [117.7686, 47.988],
              [117.3507, 47.6521],
              [117.0698, 47.8066],
              [116.7604, 47.8701],
              [116.2313, 47.858],
              [115.8983, 47.687],
              [115.5577, 47.945],
              [115.5251, 48.1309],
              [115.7857, 48.2482],
              [115.8209, 48.5772],
              [115.954, 48.6895],
              [116.2432, 49.1704],
              [116.6831, 49.8236],
              [117.2456, 49.6248],
              [117.8732, 49.5134],
              [118.4515, 49.8443],
              [118.756, 49.963],
              [119.1475, 50.0133],
              [119.326, 50.155],
              [119.3462, 50.2789],
              [119.192, 50.3798],
              [119.2807, 50.5611],
              [119.4455, 50.703],
              [119.5735, 50.9469],
              [120.0669, 51.6006],
              [120.6813, 51.973],
              [120.7445, 52.2055],
              [120.6501, 52.396],
              [120.6993, 52.4935],
              [120.5212, 52.6151],
              [120.0674, 52.6329],
              [120.0946, 52.7871],
              [120.7041, 53.1718],
              [120.9855, 53.2846],
              [121.4055, 53.3169],
              [122.338, 53.4849],
              [122.745, 53.4685],
              [123.1541, 53.5448],
              [123.608, 53.5465],
              [124.2914, 53.341],
              [124.4659, 53.2299],
              [124.8122, 53.1338],
              [124.9712, 53.1974],
              [125.2257, 53.1659],
              [125.6489, 53.0422],
              [125.728, 52.8908],
              [126.048, 52.7394],
              [126.0163, 52.6103],
              [126.3129, 52.3997],
              [126.5107, 51.9258],
              [126.8055, 51.5057],
              [126.9246, 51.1003],
              [127.307, 50.7081],
              [127.3061, 50.4534],
              [127.3953, 50.2985],
              [127.59, 50.209],
              [127.5024, 49.8736],
              [127.7113, 49.6716],
              [128, 49.5686],
              [128.704, 49.6],
              [128.8191, 49.4638],
              [129.0652, 49.3747],
              [129.534, 49.3235],
              [129.7924, 49.1988],
              [130.0373, 48.9722],
              [130.1963, 48.8916],
              [130.5532, 48.8613],
              [130.5523, 48.6024],
              [130.8045, 48.3414],
              [130.7324, 48.019],
              [130.9153, 47.8428],
              [130.9618, 47.7092],
              [131.3191, 47.7279],
              [131.5569, 47.6822],
              [132.4767, 47.7152],
              [132.7074, 47.9472],
              [133.1442, 48.1056],
              [133.4684, 48.0973],
              [133.8424, 48.2739],
              [134.2933, 48.3734],
              [134.5637, 48.3218],
              [134.6695, 48.1533],
              [134.5658, 48.0228],
              [134.7521, 47.7156],
              [134.4832, 47.4473],
              [134.2907, 47.4137],
              [134.1676, 47.3023],
              [134.2024, 47.1283],
              [134.0385, 46.8584],
              [134.0226, 46.7132],
              [133.8666, 46.4992],
              [133.8613, 46.2476],
              [133.7005, 46.1397],
              [133.6855, 46.0087],
              [133.5129, 45.8787],
              [133.4365, 45.6049],
              [133.1859, 45.4946],
              [133.1134, 45.1308],
              [132.9359, 45.0299],
              [131.9775, 45.2439],
              [131.8518, 45.3269],
              [131.6539, 45.2052],
              [131.447, 44.9842],
              [131.0822, 44.9101],
              [130.9675, 44.8],
              [131.0608, 44.6596],
              [131.2555, 44.0717],
              [131.1741, 43.7047],
              [131.2619, 43.4331],
              [131.0687, 42.9023],
              [130.5769, 42.8117],
              [130.4248, 42.7269],
              [130.5267, 42.5353]
            ]
          ]
        ]
      },
      properties: { name: "China", id: "CN", Continent: "Asia" },
      id: "CN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [91.6317, 27.76],
            [91.5948, 27.5574],
            [91.7433, 27.4423],
            [91.9907, 27.4502],
            [92.0835, 27.2907],
            [91.992, 27.1],
            [92.0733, 26.9146],
            [91.9986, 26.8548],
            [91.6716, 26.802],
            [91.4265, 26.8671],
            [91.2863, 26.7901],
            [90.7396, 26.7716],
            [90.3458, 26.8904],
            [90.123, 26.7545],
            [89.764, 26.7018],
            [89.332, 26.8487],
            [89.1484, 26.8161],
            [88.8578, 26.9616],
            [88.7388, 27.1755],
            [88.8913, 27.3162],
            [88.9475, 27.4639],
            [89.5368, 28.1077],
            [89.9811, 28.3111],
            [90.3481, 28.2439],
            [90.3529, 28.0804],
            [90.7154, 28.0721],
            [91.0779, 27.9745],
            [91.226, 28.0712],
            [91.6057, 27.9517],
            [91.6317, 27.76]
          ]
        ]
      },
      properties: { name: "Bhutan", id: "BT", Continent: "Asia" },
      id: "BT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [115.0268, 4.8996],
            [114.7465, 4.7177],
            [114.8401, 4.3934],
            [114.7764, 4.1689],
            [114.6086, 4.0244],
            [114.4168, 4.2559],
            [114.3228, 4.2629],
            [114.2239, 4.4778],
            [114.064, 4.5925],
            [114.2996, 4.6075],
            [114.5447, 4.7243],
            [114.8405, 4.9468],
            [114.9956, 5.0223],
            [115.0268, 4.8996],
            [115.1397, 4.8996],
            [115.2667, 4.6337],
            [115.2466, 4.3473],
            [115.1068, 4.3904],
            [115.0268, 4.6914],
            [115.0268, 4.8996]
          ]
        ]
      },
      properties: { name: "Brunei", id: "BN", Continent: "Asia" },
      id: "BN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[50.6074, 25.8832], [50.4659, 25.9657], [50.4523, 26.1908], [50.61, 26.1244], [50.6074, 25.8832]]
        ]
      },
      properties: { name: "Bahrain", id: "BH", Continent: "Middle East" },
      id: "BH"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [90.7778, 22.0893],
              [90.515, 22.0651],
              [90.6802, 22.3276],
              [90.6491, 22.5406],
              [90.5652, 22.6181],
              [90.5027, 22.8355],
              [90.6719, 22.8131],
              [90.7369, 22.6386],
              [90.8684, 22.4849],
              [90.8301, 22.1597],
              [90.7778, 22.0893]
            ]
          ],
          [
            [
              [92.5751, 21.9782],
              [92.5936, 21.467],
              [92.5391, 21.3199],
              [92.3304, 21.4398],
              [92.1797, 21.2934],
              [92.3243, 20.7917],
              [92.0562, 21.1748],
              [92.0083, 21.6845],
              [91.9134, 21.8832],
              [91.8501, 22.1574],
              [91.6932, 22.5046],
              [91.4093, 22.7973],
              [91.1514, 22.614],
              [90.9457, 22.5969],
              [90.8266, 22.7212],
              [90.6332, 23.0943],
              [90.4662, 23.0539],
              [90.4351, 22.752],
              [90.5959, 22.4361],
              [90.5893, 22.258],
              [90.3555, 22.0485],
              [90.2882, 21.8991],
              [90.1591, 21.8168],
              [90.0879, 22.0172],
              [89.8119, 21.9834],
              [89.5684, 21.7673],
              [89.452, 21.8213],
              [89.2345, 21.7224],
              [89.0939, 21.8731],
              [89.0512, 22.0933],
              [89.0499, 22.2747],
              [88.9207, 22.632],
              [88.8508, 23.0402],
              [88.9282, 23.1866],
              [88.7242, 23.2548],
              [88.7405, 23.4367],
              [88.567, 23.6744],
              [88.6996, 24.0023],
              [88.7335, 24.2308],
              [88.6421, 24.3261],
              [88.1455, 24.4857],
              [88.0233, 24.6276],
              [88.1499, 24.9145],
              [88.3134, 24.8816],
              [88.4562, 25.1883],
              [88.8904, 25.194],
              [88.7691, 25.4903],
              [88.5933, 25.4951],
              [88.4523, 25.5746],
              [88.2527, 25.7899],
              [88.0844, 25.8884],
              [88.1508, 26.0875],
              [88.4404, 26.3696],
              [88.5182, 26.5178],
              [88.6829, 26.2914],
              [89.1083, 26.2022],
              [89.2894, 26.0373],
              [89.55, 26.0054],
              [89.7095, 26.1714],
              [89.8229, 25.9411],
              [89.8141, 25.3053],
              [90.4394, 25.1576],
              [91.2929, 25.1778],
              [91.4801, 25.1422],
              [92.0501, 25.1694],
              [92.4684, 24.9445],
              [92.2513, 24.8948],
              [92.1982, 24.686],
              [92.0641, 24.3745],
              [91.9516, 24.3565],
              [91.8769, 24.1957],
              [91.6686, 24.19],
              [91.5266, 24.0906],
              [91.3671, 24.0933],
              [91.2318, 23.9205],
              [91.1601, 23.6604],
              [91.3153, 23.1045],
              [91.4365, 23.1998],
              [91.5113, 23.0338],
              [91.6198, 22.9797],
              [91.7736, 23.1063],
              [91.7543, 23.2872],
              [91.938, 23.5047],
              [91.9296, 23.6858],
              [92.2461, 23.6837],
              [92.3343, 23.3237],
              [92.3616, 22.9291],
              [92.4917, 22.6852],
              [92.5751, 21.9782]
            ]
          ]
        ]
      },
      properties: { name: "Bangladesh", id: "BD", Continent: "Asia" },
      id: "BD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [56.2979, 25.6506],
            [56.3638, 25.5694],
            [56.388, 24.9792],
            [56.0641, 24.7388],
            [55.9155, 24.9717],
            [55.796, 24.868],
            [55.7609, 24.2427],
            [55.9287, 24.215],
            [55.985, 24.0638],
            [55.5478, 23.9913],
            [55.4682, 23.9412],
            [55.5086, 23.7245],
            [55.1997, 23.0345],
            [55.1861, 22.7041],
            [55.1044, 22.6215],
            [53.8321, 22.7766],
            [52.5551, 22.933],
            [51.5927, 24.0787],
            [51.568, 24.2862],
            [51.7676, 24.2545],
            [51.7917, 24.0748],
            [51.906, 23.9856],
            [52.2509, 23.9952],
            [52.6483, 24.1548],
            [53.0266, 24.1473],
            [53.3298, 24.0981],
            [53.8932, 24.0769],
            [54.3972, 24.2778],
            [54.5348, 24.5309],
            [54.747, 24.8105],
            [55.0982, 25.0415],
            [55.5227, 25.4982],
            [55.9411, 25.794],
            [56.0804, 26.0624],
            [56.1674, 26.0476],
            [56.1449, 25.6907],
            [56.2979, 25.6506]
          ]
        ]
      },
      properties: { name: "United Arab Emirates", id: "AE", Continent: "Middle East" },
      id: "AE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [74.8912, 37.2316],
            [74.7263, 37.2909],
            [74.3722, 37.1577],
            [74.5414, 37.0219],
            [74.0017, 36.8233],
            [73.7692, 36.8884],
            [73.1167, 36.8686],
            [72.6231, 36.8294],
            [72.1569, 36.7007],
            [71.822, 36.4858],
            [71.6207, 36.4366],
            [71.2332, 36.1219],
            [71.1852, 36.0419],
            [71.4274, 35.8337],
            [71.6006, 35.4078],
            [71.6207, 35.1833],
            [71.4551, 34.9671],
            [71.2938, 34.8677],
            [70.9655, 34.5307],
            [71.0956, 34.3694],
            [71.0517, 34.05],
            [70.8482, 33.9818],
            [70.4158, 33.9506],
            [69.9947, 34.0516],
            [69.8682, 33.8974],
            [70.0567, 33.72],
            [70.284, 33.3688],
            [70.2611, 33.2888],
            [69.92, 33.1121],
            [69.5017, 33.0199],
            [69.405, 32.6828],
            [69.2415, 32.4336],
            [69.2789, 31.937],
            [69.0829, 31.7384],
            [68.8689, 31.6343],
            [68.5977, 31.8031],
            [68.443, 31.7547],
            [68.2136, 31.8074],
            [68.0172, 31.6782],
            [67.5782, 31.5064],
            [67.7377, 31.3438],
            [67.453, 31.2348],
            [67.2873, 31.2177],
            [66.9243, 31.3055],
            [66.7314, 31.1947],
            [66.567, 30.9965],
            [66.3974, 30.9122],
            [66.2867, 30.6077],
            [66.3056, 30.3211],
            [66.2476, 30.0438],
            [66.3135, 29.9687],
            [66.1772, 29.8356],
            [65.0953, 29.5596],
            [64.5209, 29.5644],
            [64.266, 29.5069],
            [64.0991, 29.3922],
            [63.5677, 29.4981],
            [62.4766, 29.4084],
            [62.3734, 29.4256],
            [60.8436, 29.8584],
            [61.1038, 30.1286],
            [61.7845, 30.8323],
            [61.8143, 31.0727],
            [61.755, 31.2853],
            [61.6601, 31.3824],
            [60.8542, 31.4831],
            [60.7913, 31.6606],
            [60.79, 31.9871],
            [60.8291, 32.2496],
            [60.5764, 32.9948],
            [60.5606, 33.1376],
            [60.917, 33.505],
            [60.511, 33.6386],
            [60.4854, 34.0947],
            [60.6428, 34.3074],
            [60.8897, 34.3193],
            [60.7263, 34.5179],
            [60.9512, 34.6542],
            [61.08, 34.8554],
            [61.2619, 35.6197],
            [61.3446, 35.6297],
            [61.5427, 35.4579],
            [61.7195, 35.4192],
            [61.984, 35.4439],
            [62.2529, 35.2501],
            [62.6884, 35.2554],
            [63.0567, 35.446],
            [63.1696, 35.6781],
            [63.1086, 35.8188],
            [63.8622, 36.012],
            [64.3583, 36.2261],
            [64.5108, 36.3408],
            [64.8163, 37.1323],
            [65.0896, 37.2377],
            [65.555, 37.2513],
            [65.6415, 37.468],
            [65.765, 37.5691],
            [66.1082, 37.4148],
            [66.5222, 37.3485],
            [66.8276, 37.3713],
            [67.0689, 37.3349],
            [67.3198, 37.2096],
            [67.5172, 37.2667],
            [67.7592, 37.1722],
            [67.9579, 36.9719],
            [68.0678, 36.9499],
            [68.3868, 37.1375],
            [68.6694, 37.2584],
            [68.9603, 37.3247],
            [69.2649, 37.1081],
            [69.4147, 37.2079],
            [69.3993, 37.3994],
            [69.492, 37.5532],
            [69.6256, 37.5937],
            [70.1885, 37.5822],
            [70.2549, 37.7651],
            [70.1991, 37.8859],
            [70.4175, 38.0753],
            [70.6157, 38.3342],
            [70.8789, 38.4563],
            [71.2555, 38.3069],
            [71.3325, 38.1707],
            [71.3202, 37.9017],
            [71.5803, 37.8644],
            [71.5047, 37.6029],
            [71.4331, 37.1274],
            [71.5975, 36.7328],
            [71.8023, 36.6941],
            [72.1533, 36.9007],
            [72.359, 36.9833],
            [72.6574, 37.0294],
            [72.8956, 37.2676],
            [73.2111, 37.4082],
            [73.4813, 37.4719],
            [73.7209, 37.4183],
            [73.7495, 37.2316],
            [74.1674, 37.3291],
            [74.2596, 37.4153],
            [74.6596, 37.3946],
            [74.8912, 37.2316]
          ]
        ]
      },
      properties: { name: "Afghanistan", id: "AF", Continent: "Asia" },
      id: "AF"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.4061, 37.1793],
            [-7.8343, 37.0057],
            [-8.5977, 37.1211],
            [-8.8141, 37.431],
            [-8.9147, 38.5123],
            [-9.4742, 38.7307],
            [-9.3748, 39.3384],
            [-9.1482, 39.5426],
            [-8.8867, 40.1795],
            [-8.6557, 41.0296],
            [-8.7771, 41.9409],
            [-8.2131, 42.1336],
            [-7.9208, 41.8836],
            [-7.4037, 41.8335],
            [-6.6182, 41.9422],
            [-6.2125, 41.5321],
            [-6.9285, 41.0092],
            [-6.8102, 40.3429],
            [-7.0146, 40.2084],
            [-7.0368, 39.7139],
            [-7.4452, 39.5363],
            [-7.0461, 38.9069],
            [-7.3431, 38.4572],
            [-7.1855, 38.0062],
            [-7.5036, 37.5854],
            [-7.4061, 37.1793]
          ]
        ]
      },
      properties: { name: "Portugal", id: "PT" },
      id: "PT"
    }
  ]
};
export default map;
