import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AccountContext } from '../../context/Accounts'
import useError from '../../hooks/useError'
import LogoComponent from '../components/LogoComponent'
import './Login.css'

export default function LoginModal({
  showLogin,
  handleLoginClose,
  setShowLogin,
  setShowRegister,
  verificationCode = false,
  setVerificationCode = () => {},
  resetPasswordCode = false,
  setResetPasswordCode = () => {}
}) {
  const loginButton = useRef()
  const passwordInput = useRef()
  const passwordInput2 = useRef()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [showPass, setShowPass] = useState(false)
  const [showPass2, setShowPass2] = useState(false)
  const [rememberChecked, setRememberChecked] = useState(true)
  const { authenticate } = useContext(AccountContext)
  const [verifying, setVerifying] = useState(verificationCode)
  const [forgot, setForgot] = useState(false)

  const { addError } = useError()
  const { t, i18n } = useTranslation()

  const resetPassword = async () => {
    if (!email) addError(t('Please enter your e-mail'))
    else {
      let res = await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/users/sendResetPasswordLink',
        { email, lang: i18n.language }
      )
      addError(
        res.data.msg === 'OK'
          ? t(
              'You will receive instructions to create your new password on your e-mail'
            )
          : 'No account found'
      )

      if (res.data.msg === 'OK') setForgot(false)
    }
  }

  const resetPasswordSubmit = async () => {
    if (!password || !password2) addError(t('Please enter your new password'))
    else if (password !== password2)
      addError(t('Both passwords must be the same'))
    else {
      const passRegexp = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
      )

      if (!passRegexp.test(password))
        addError(
          t(
            'Password must have at least one uppercase letter, one lowercase letter and one number'
          )
        )
      else {
        let res = await axios.post(
          process.env.REACT_APP_BACKEND_URL + '/users/resetPassword',
          { resetPasswordCode, password, lang: i18n.language }
        )
        if (res.data.msg === 'OK') {
          setResetPasswordCode(false)
          addError(t('Password has been updated, please login'))
          setPassword('')
        } else if (res.data.msg === 'LINKEXPIRED') {
          setResetPasswordCode(false)
          setForgot(true)
          setPassword('')
          addError(t('Link expired please generate new link'))
        } else addError(t('Error resetting password'))
      }
    }
  }

  useEffect(() => {
    if (showLogin && showLogin === 'demo') {
      setEmail('naftecbr+ilgdemo@gmail.com')
    }
  }, [showLogin])

  useEffect(() => {
    if (passwordInput.current)
      if (showPass) passwordInput.current.type = 'text'
      else passwordInput.current.type = 'password'
  }, [showPass])

  useEffect(() => {
    if (passwordInput2.current)
      if (showPass2) passwordInput2.current.type = 'text'
      else passwordInput2.current.type = 'password'
  }, [showPass2])

  useEffect(() => {
    ;(async () => {
      if (verificationCode) {
        setShowLogin(true)
        setVerifying(true)
        const res = await axios.post(
          process.env.REACT_APP_BACKEND_URL + '/users/verifyUser/',
          {
            verificationCode
          }
        )
        if (res) {
          console.log(res)
          setVerifying(false)
        }
      }
    })()
  }, [verificationCode])

  useEffect(() => {
    ;(async () => {
      if (resetPasswordCode) {
        setShowLogin(true)
      }
    })()
  }, [resetPasswordCode])

  const submitLogin = e => {
    e.preventDefault()
    loginButton.current.disabled = true
    authenticate(email, password, rememberChecked)
      .then(data => {
        if (loginButton.current) loginButton.current.disabled = false
        setVerificationCode(null)
        window.location = '/'
        handleLoginClose()
      })
      .catch(e => {
        console.log(e, 'EEE')
        if (loginButton.current) loginButton.current.disabled = false
        if (e.code) {
          /* i18next-extract-disable-next-line */
          addError(t(e.message))
        } else if (e.message) {
          addError(e.message)
        } else if (e[0] && e[0].message) {
          addError(e[0].message)
        } else {
          addError(t('An unexpected error occurred'))
        }
      })
  }

  const closeModal = () => {
    setResetPasswordCode(false)
    setForgot(false)
    setPassword('')
    setShowLogin(false)
  }

  return (
    <Modal
      show={showLogin}
      className={
        'menu__modal login__modal ' +
        (!verificationCode ? 'fullSize' : 'midSize')
      }
      onExit={() => {
        closeModal()
      }}
      onHide={() => {
        closeModal()
      }}
    >
      <Modal.Header closeButton>
        <LogoComponent />
      </Modal.Header>
      <Modal.Body>
        <div
          className={'loginForm__container ' + (verificationCode && 'success')}
        >
          {!verifying ? (
            <>
              {!verificationCode ? (
                <div className="lf__header">
                  {!resetPasswordCode ? (
                    <>
                      <p className="lf__title">{t('Log in')}</p>
                      {showLogin !== 'demo' ? (
                        <p className="lf__subTitle">
                          {t('or')}{' '}
                          <span
                            onClick={() => {
                              setShowLogin(false)
                              setShowRegister(true)
                            }}
                          >
                            {t('sign up')}
                          </span>
                        </p>
                      ) : (
                        <p className="lf__subTitle">
                          {t('using access password')}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <p className="lf__title">{t('Reset password')}</p>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <div className="lf__header">
                    <p className="lf__title">{t('Success!')}</p>
                  </div>
                  {i18n.language === 'en' ? (
                    <p className="lf__subTitle">
                      <p>
                        We have validated your email account and your
                        application to have access to the full version of the
                        ILGA World Database has now been submitted for
                        consideration.
                      </p>
                    </p>
                  ) : (
                    <p className="lf__subTitle">
                      <p>
                        Hemos validado su cuenta de correo electrónico y su
                        solicitud para tener acceso a la versión completa de la
                        base de datos mundial de ILGA ha sido remitida para su
                        consideración.
                      </p>
                    </p>
                  )}

                  <div className="lf__actions">
                    <Button
                      onClick={() => (window.location = '/' + i18n.language)}
                      variant="primary"
                    >
                      {t('OK, thanks')}
                    </Button>
                  </div>
                </>
              )}

              {!verificationCode && !forgot && !resetPasswordCode ? (
                <>
                  {showLogin !== 'demo' ? (
                    <div className="lfInput__container">
                      <label htmlFor="userEmail">{t('Your email')}</label>
                      <input
                        name="userEmail"
                        type="email"
                        placeholder="e.g.name@mail.com"
                        required
                        key="email"
                        value={email}
                        onChange={e => {
                          setEmail(e.target.value)
                        }}
                      />
                    </div>
                  ) : (
                    <input
                      name="userEmail"
                      type="hidden"
                      required
                      key="email"
                      value="naftecbr+ilgdemo@gmail.com"
                    />
                  )}
                  <div className="lfInput__container">
                    {showLogin !== 'demo' ? (
                      <label htmlFor="userPassword">{t('Your password')}</label>
                    ) : (
                      <label htmlFor="userPassword">
                        {t('Access password')}
                      </label>
                    )}
                    <input
                      name="userPassword"
                      type={showPass ? 'text' : 'password'}
                      ref={passwordInput}
                      placeholder="password"
                      required
                      value={password}
                      onChange={e => {
                        setPassword(e.target.value)
                      }}
                    />
                    <Button
                      className="showPasswordBtn"
                      variant="link"
                      onClick={e => setShowPass(!showPass)}
                    >
                      {!showPass ? t('Show') : t('Hide')}
                    </Button>
                    {showLogin !== 'demo' && (
                      <Link
                        onClick={() => setForgot(true)}
                        style={{ marginTop: '10px', marginLeft: '2px' }}
                      >
                        {t('Forgot your password? Click here.')}
                      </Link>
                    )}
                  </div>
                  {false && (
                    <div className="dd-wrapper">
                      <div className="dd-list-item">
                        <button type="button">
                          <div className="check-container">
                            <input
                              name="rememberUser"
                              type="checkbox"
                              value="1"
                              checked={rememberChecked}
                              onChange={() =>
                                setRememberChecked(!rememberChecked)
                              }
                            />
                            <label
                              onClick={() =>
                                setRememberChecked(!rememberChecked)
                              }
                              htmlFor="rememberUser"
                            >
                              {t(' Remember me')}
                            </label>
                          </div>
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="lf__actions">
                    <Button
                      variant="primary"
                      ref={loginButton}
                      type="submit"
                      onClick={submitLogin}
                    >
                      {t('Log in')}
                    </Button>
                    {false && (
                      <Button variant="link" onClick={resetPassword}>
                        {t('Forgot your password?')}
                      </Button>
                    )}
                  </div>
                </>
              ) : // Forgot password
              !resetPasswordCode ? (
                <>
                  <div className="lfInput__container">
                    <label htmlFor="userEmail">{t('Your email')}</label>
                    <input
                      name="userEmail"
                      type="email"
                      placeholder="e.g.name@mail.com"
                      required
                      key="email"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value)
                      }}
                    />

                    <Link
                      onClick={() => {
                        setForgot(false)
                        setPassword('')
                      }}
                      style={{ marginTop: '10px', marginLeft: '2px' }}
                    >
                      « {t('Back')}
                    </Link>
                  </div>
                  <div className="lf__actions">
                    <Button
                      variant="primary"
                      ref={loginButton}
                      type="submit"
                      onClick={resetPassword}
                    >
                      {t('Reset password')}
                    </Button>
                  </div>
                </>
              ) : (
                !verificationCode && (
                  <>
                    <div className="lfInput__container">
                      <label htmlFor="userPassword">{t('New password')}</label>
                      <input
                        name="userPassword"
                        type={showPass ? 'text' : 'password'}
                        ref={passwordInput}
                        placeholder="password"
                        required
                        value={password}
                        onChange={e => {
                          setPassword(e.target.value)
                        }}
                      />
                      <Button
                        className="showPasswordBtn"
                        variant="link"
                        onClick={e => setShowPass(!showPass)}
                      >
                        {!showPass ? t('Show') : t('Hide')}
                      </Button>
                    </div>
                    <div className="lfInput__container">
                      <label htmlFor="userPassword">
                        {t('Repeat Password')}
                      </label>
                      <input
                        name="userPassword"
                        type={showPass2 ? 'text' : 'password'}
                        ref={passwordInput2}
                        placeholder="password"
                        required
                        value={password2}
                        onChange={e => {
                          setPassword2(e.target.value)
                        }}
                      />
                      <Button
                        className="showPasswordBtn"
                        variant="link"
                        onClick={e => setShowPass2(!showPass2)}
                      >
                        {!showPass ? t('Show') : t('Hide')}
                      </Button>
                    </div>
                    <div className="lf__actions">
                      <Button
                        variant="primary"
                        ref={loginButton}
                        type="submit"
                        onClick={resetPasswordSubmit}
                      >
                        {t('Reset password')}
                      </Button>
                    </div>
                  </>
                )
              )}
            </>
          ) : (
            <>{t('Loading')}...</>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}
