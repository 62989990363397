import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  cCharacteristics,
  cJurProfileSpanishAreasNames,
  cJurProfileSpanishCharacteristics
} from '../../../config/constants'
import routes from '../../../config/routes'
import SourceText from '../../components/Table/SourceText'

export default function SubjurProtection({ eEntry, jur, subareaNumber }) {
  const { i18n, t } = useTranslation()
  return eEntry.isSubjurProfile ? (
    <></>
  ) : (
    <div className="jpjs__sogiesc-columns">
      {cCharacteristics().map(c => {
        let percSub = Math.round((eEntry.n_sub[c.id] / eEntry.sub.length) * 100)
        let percPop = Math.round(
          (eEntry.n_subp[c.id] / eEntry.n_subptotal) * 100
        )
        return (
          <div className="jpjss__column" key={c.short}>
            <div className="jpjss__columnHeader">
              <h4 className="jpjss__columnHeader-title">{c.short}</h4>
              <h5 className="jpjss__columnHeader-subTitle">{c.long}</h5>
            </div>
            <div className="jpjss__columnBody">
              <div className="jpjss__columnProgress">
                <p>{t('Sub-national jurisdictions')}</p>
                <div className="jpjss__cpBar">
                  {eEntry.main[c.id + '_protection_type'].id === '1' ? (
                    <div
                      className="jpjss__cpBar-left bg-blue"
                      style={{
                        width: '100%'
                      }}
                    >
                      100%
                    </div>
                  ) : eEntry.n_sub[c.id] > 0 ? (
                    <>
                      <div
                        className="jpjss__cpBar-left bg-blue"
                        style={{
                          width: percSub + '%'
                        }}
                      >
                        {percSub}%
                      </div>

                      {eEntry.n_sub[c.id] !== eEntry.sub.length && (
                        <div
                          className="jpjss__cpBar-right"
                          style={{
                            width: 100 - percSub + '%'
                          }}
                        >
                          {100 - percSub > 10 ? 100 - percSub + '%' : ''}
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      className="jpjss__cpBar-left"
                      style={{
                        width: '100%',
                        justifyContent: 'start',
                        paddingLeft: '5px',
                        fontWeight: '700'
                      }}
                    >
                      0 %
                    </div>
                  )}
                </div>
                <p>{t('Population')}</p>
                <div className="jpjss__cpBar">
                  {eEntry.main[c.id + '_protection_type'].id === '1' ? (
                    <div
                      className="jpjss__cpBar-left bg-mint"
                      style={{
                        width: '100%'
                      }}
                    >
                      100%
                    </div>
                  ) : eEntry.n_sub[c.id] > 0 ? (
                    <>
                      <div
                        className="jpjss__cpBar-left bg-mint"
                        style={{
                          width:
                            (eEntry.n_sub[c.id] !== eEntry.sub.length
                              ? percPop
                              : 100) + '%'
                        }}
                      >
                        {eEntry.n_sub[c.id] !== eEntry.sub.length
                          ? percPop
                          : 100}
                        %
                      </div>
                      {eEntry.n_sub[c.id] !== eEntry.sub.length && (
                        <div
                          className="jpjss__cpBar-right"
                          style={{
                            width: 100 - percPop + '%'
                          }}
                        >
                          {100 - percPop > 10 ? 100 - percPop + '%' : ''}
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      className="jpjss__cpBar-left"
                      style={{
                        width: '100%',
                        justifyContent: 'start',
                        paddingLeft: '5px',
                        fontWeight: '700'
                      }}
                    >
                      0 %
                    </div>
                  )}
                </div>
              </div>

              <div className="jpjss__columnContent">
                {eEntry.main[c.id + '_explan'] ? (
                  <>
                    <p>
                      <SourceText
                        text={eEntry.main[c.id + '_explan']}
                        sources={eEntry.main.motherEntry.sources}
                      />
                    </p>
                    <p>
                      {Math.round(
                        (eEntry.n_sub[c.id] / eEntry.sub.length) * 100
                      ) !== 100 &&
                        eEntry.main[c.id + '_protection_type'].id !== '1' &&
                        eEntry.n_sub[c.id] > 0 &&
                        (eEntry.main?.motherEntry?.jurisdiction?.id !==
                          'UN-185' ||
                        (eEntry.main?.motherEntry?.entry_type_id !== 'A1-6' &&
                          eEntry.main?.motherEntry?.entry_type_id !== 'A1-7' &&
                          eEntry.main?.motherEntry?.entry_type_id !== 'A1-8' &&
                          eEntry.main?.motherEntry?.entry_type_id !== 'A1-10' &&
                          eEntry.main?.motherEntry?.entry_type_id !== 'A1-11' &&
                          eEntry.main?.motherEntry?.entry_type_id !== 'A1-12' &&
                          eEntry.main?.motherEntry?.entry_type_id !== 'A1-13' &&
                          eEntry.main?.motherEntry?.entry_type_id !== 'A1-15' &&
                          eEntry.main?.motherEntry?.entry_type_id !== 'A1-16' &&
                          eEntry.main?.motherEntry?.entry_type_id !== 'A1-18')
                          ? t(
                              subareaNumber < 12
                                ? 'currently_15_111'
                                : subareaNumber === 12
                                ? 'currently_112'
                                : 'currently_113',
                              {
                                subP: eEntry.n_sub[c.id],
                                subT: eEntry.sub.length,
                                subPP: Math.round(
                                  (eEntry.n_sub[c.id] / eEntry.sub.length) * 100
                                ),
                                subPP2: Math.round(
                                  (eEntry.n_subp[c.id] / eEntry.n_subptotal) *
                                    100
                                )
                              }
                            )
                          : '')}
                    </p>
                  </>
                ) : eEntry.n_sub[c.id] === eEntry.sub.length ? (
                  <>
                    <p>
                      {t(
                        '{{areaName}} based on {{characteristicLongName}} is available in all subnational jurisdictions in {{jurName}}, covering the entirety of the population',
                        {
                          areaName:
                            i18n.language === 'es'
                              ? cJurProfileSpanishAreasNames[subareaNumber]
                              : routes().find(
                                  r => r.subareaNumber === subareaNumber
                                ).name,
                          characteristicLongName:
                            i18n.language === 'es'
                              ? cJurProfileSpanishCharacteristics[c.id]
                              : c.long.toLowerCase(),
                          jurName: jur.name
                        }
                      )}
                      .
                    </p>
                  </>
                ) : eEntry.n_sub[c.id] > 0 ? (
                  <>
                    <p>
                      {t(
                        '{{areaName}} based on {{characteristicLongName}} is not fully available nationwide in {{jurName}}',
                        {
                          areaName:
                            i18n.language === 'es'
                              ? cJurProfileSpanishAreasNames[subareaNumber]
                              : routes().find(
                                  r => r.subareaNumber === subareaNumber
                                ).name,
                          characteristicLongName:
                            i18n.language === 'es'
                              ? cJurProfileSpanishCharacteristics[c.id]
                              : c.long.toLowerCase(),
                          jurName: jur.name
                        }
                      )}
                    </p>
                    <p>
                      {eEntry.main?.motherEntry?.jurisdiction?.id !==
                        'UN-185' ||
                      (eEntry.main?.motherEntry?.entry_type_id !== 'A1-6' &&
                        eEntry.main?.motherEntry?.entry_type_id !== 'A1-7' &&
                        eEntry.main?.motherEntry?.entry_type_id !== 'A1-8' &&
                        eEntry.main?.motherEntry?.entry_type_id !== 'A1-10' &&
                        eEntry.main?.motherEntry?.entry_type_id !== 'A1-11' &&
                        eEntry.main?.motherEntry?.entry_type_id !== 'A1-12' &&
                        eEntry.main?.motherEntry?.entry_type_id !== 'A1-13' &&
                        eEntry.main?.motherEntry?.entry_type_id !== 'A1-15' &&
                        eEntry.main?.motherEntry?.entry_type_id !== 'A1-16' &&
                        eEntry.main?.motherEntry?.entry_type_id !== 'A1-18')
                        ? t(
                            subareaNumber < 12
                              ? 'currently_15_111'
                              : subareaNumber === 12
                              ? 'currently_112'
                              : 'currently_113',
                            {
                              subP: eEntry.n_sub[c.id],
                              subT: eEntry.sub.length,
                              subPP: Math.round(
                                (eEntry.n_sub[c.id] / eEntry.sub.length) * 100
                              ),
                              subPP2: Math.round(
                                (eEntry.n_subp[c.id] / eEntry.n_subptotal) * 100
                              )
                            }
                          )
                        : ''}
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      {t(
                        '{{areaName}} based on {{characteristicLongName}} is not available in {{jurName}}',
                        {
                          areaName:
                            i18n.language === 'es'
                              ? cJurProfileSpanishAreasNames[subareaNumber]
                              : routes().find(
                                  r => r.subareaNumber === subareaNumber
                                ).name,
                          characteristicLongName:
                            i18n.language === 'es'
                              ? cJurProfileSpanishCharacteristics[c.id]
                              : c.long.toLowerCase(),
                          jurName: jur.name
                        }
                      )}
                      .
                    </p>
                    <p>
                      {t(
                        'No such protection is available at the subnational level either'
                      )}
                      .
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
