import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import routes, { getPathLang } from '../config/routes'
import Footer from '../mocks/layouts/Footer'
import Header from '../mocks/layouts/header'

import '../mocks/layouts/SectionColors.css'
import './MainLayout.css'
import '../mocks/layouts/MainLayout.css'
import { createBrowserHistory } from 'history'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast as toastify, Flip } from 'react-toastify'
import { AccountContext } from '../context/Accounts'
import LoginDropdown from '../mocks/AuthModal/LoginDropdown'
import { Button } from 'react-bootstrap'
import MainSpinner from '../components/MainSpinner'
import seo from '../config/seo.json'

export default function MainLayout({
  children,
  section,
  areaInfo,
  title,
  alert,
  verificationCode,
  setVerificationCode,
  verifyAccount,
  resetPasswordCode,
  setResetPasswordCode,
  metaTitle,
  metaDescription
}) {
  const { t, i18n } = useTranslation()
  const history = createBrowserHistory()
  const { toast, setToast, loggedIn } = useContext(AccountContext)

  useEffect(() => {
    let urlLang = 'en'

    urlLang = getPathLang(history.location.pathname.slice(1))

    if (urlLang !== i18n.language) {
      i18n.changeLanguage(urlLang)
    } else if (urlLang != localStorage.getItem('currentLanguage')) {
      localStorage.setItem('currentLanguage', urlLang)
      window.location.reload()
    }
  }, [])

  const { toastText, setToastText, toastDuration } = { ...toast }

  useEffect(() => {
    if (toastText) {
      toastify(<div dangerouslySetInnerHTML={{ __html: toastText }} />, {
        position: 'bottom-right',
        autoClose: toastDuration ? toastDuration : 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Flip
      })

      setToast(null)
    }
    if (
      !loggedIn &&
      loggedIn !== null &&
      process.env.REACT_APP_ALLOW_GUEST !== 'yes'
    ) {
      document.body.style.backgroundColor = '#181818'
    } else {
      document.body.style.backgroundColor = '#fff'
    }
  }, [toastText, loggedIn])

  let seoObj =
    seo[
      Object.keys(seo).find(k => {
        return (
          seo[k]['url_' + i18n.language] === history.location.pathname.slice(1)
        )
      })
    ]

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>
          {(metaTitle
            ? metaTitle
            : i18n.language && seoObj && seoObj['title_' + i18n.language]) +
            ' | ' +
            t('ILGA World Database')}
        </title>
        <link
          rel="canonical"
          href={
            'https://database.ilga.org/' + history.location.pathname.slice(1)
          }
        />
        <meta
          name="description"
          content={
            metaDescription
              ? metaDescription
              : seoObj && seoObj['description_' + i18n.language]
          }
        />
      </Helmet>
      <>
        {loggedIn || process.env.REACT_APP_ALLOW_GUEST === 'yes' ? (
          <>
            <Header
              section={section}
              verificationCode={verificationCode}
              setVerificationCode={setVerificationCode}
              resetPasswordCode={resetPasswordCode}
              setResetPasswordCode={setResetPasswordCode}
            />
            <div
              id="mainContent"
              className={
                section !== 'jurs'
                  ? `section__${section}`
                  : `jurMainContent ${section ? section : ''}`
              }
            >
              {children}
            </div>
            <Footer />
          </>
        ) : loggedIn === null ? (
          <MainSpinner />
        ) : (
          <div className="blockedMainDiv">
            <LoginDropdown
              isBeta={true}
              verificationCode={verificationCode}
              setVerificationCode={setVerificationCode}
              resetPasswordCode={resetPasswordCode}
              setResetPasswordCode={setResetPasswordCode}
            />
          </div>
        )}
      </>
    </>
  )
}
