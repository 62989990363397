import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cMainRegions } from '../../../../config/constants'
import { isRecommendation } from '../../../../lib/fnAreasPRHelper'
import UNMemberSwitch from '../../Charts/UNMemberSwitch'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import LoadingBar from '../../ui/LoadingBar'

export default function LineChart2({ references }) {
  const [unMember, setUnMember] = useState(true)
  const [topicsN, setTopicsN] = useState(10)
  const { t, i18n } = useTranslation()
  const loading = useRef()
  const loadChart = () => {
    if (loading.current) loading.current.style.display = 'block'
    am4core.options.queue = true

    /* Chart code */
    // Themes begin
    am4core.unuseAllThemes()
    
    // Themes end

    // Create chart instance
    let chart = am4core.create('stackedChartDivX2', am4charts.XYChart)
    chart.numberFormatter.numberFormat = '#'
    chart.events.on('ready', () => {
      if (loading.current) loading.current.style.display = 'none'
    })

    let newReferences = []
    let newReferences2 = []
    let amount1 = 0
    let amount2 = 0
    let max = 0

    for (let i = 1989; i <= new Date().getFullYear(); i++) {
      let tmp = references
        .filter(r =>
          unMember ? r.entry.motherEntry.jurisdiction.un_member : true
        )
        .filter(e => {
          return (
            !e.entry.motherEntry.subjurisdiction &&
            e.entry.marriage_type.id === '1' &&
            (e.entry.marriage_critical_date_2
              ? e.entry.marriage_critical_date_2 == i
              : e.entry.marriage_critical_date_1 == i)
          )
        })
      amount1 += tmp.length
      if (max < amount1) max = amount1
      if (tmp.length) {
        newReferences.push({
          year: i + '',
          general: amount1,
          countries: tmp.length
            ? '<br/>' +
              tmp
                .map(
                  r =>
                    `<div style="margin-top:2px; font-weight: bold;" ><img
              style="display: inline; height: 12px; width: 15px; vertical-align: text-top; margin-right:5px; margin-left:10px; margin-bottom:4px;"
              class="svgFlag"
              
              src="/images/flags_svg/${r.entry.motherEntry.jurisdiction.a2_code.toLowerCase()}.svg"
            /> ` +
                    r.entry.motherEntry.jurisdiction.name +
                    `</div>`
                )
                .join('') +
              '<br/>'
            : '',
          disabled: tmp.length ? false : true
        })
      }
      tmp = references
        .filter(r =>
          unMember ? r.entry.motherEntry.jurisdiction.un_member : true
        )
        .filter(e => {
          return (
            !e.entry.motherEntry.subjurisdiction &&
            (e.entry.civil_type.id === '1' || e.entry.civil_type.id === '3') &&
            e.entry.civil_critical_date_1 &&
            (e.entry.civil_critical_date_2
              ? e.entry.civil_critical_date_2 == i
              : e.entry.civil_critical_date_1 == i)
          )
        })
      let tmpOut = references
        .filter(r =>
          unMember ? r.entry.motherEntry.jurisdiction.un_member : true
        )
        .filter(e => {
          return (
            !e.entry.motherEntry.subjurisdiction &&
            e.entry.civil_type.id === '3' &&
            (e.entry.civil_repeal_date_2
              ? e.entry.civil_repeal_date_2 == i
              : e.entry.civil_repeal_date_1 == i)
          )
        })
      amount2 += tmp.length - tmpOut.length
      if (max < amount2) max = amount2
      if (tmp.length || tmpOut.length) {
        newReferences2.push({
          year: i + '',
          countries:
            (tmp.length
              ? '<br/>' +
                tmp
                  .map(
                    r =>
                      `<div style="margin-top:2px; font-weight: bold;" ><img
              style="display: inline; height: 12px; width: 15px; vertical-align: text-top; margin-right:5px; margin-left:10px; margin-bottom:4px;"
              class="svgFlag"
              
              src="/images/flags_svg/${r.entry.motherEntry.jurisdiction.a2_code.toLowerCase()}.svg"
            /> ` +
                      r.entry.motherEntry.jurisdiction.name +
                      `</div>`
                  )
                  .join('') +
                '<br/>'
              : '') +
            (tmpOut.length
              ? (!tmp.length ? '<br/><br/>' : '') +
                '<center>' +
                t('Repealed or Discontinued') +
                '</center>' +
                '<br />' +
                tmpOut
                  .map(
                    r =>
                      `<div style="margin-top:2px; font-weight: bold;" ><img
              style="display: inline; height: 12px; width: 15px; vertical-align: text-top; margin-right:5px; margin-left:10px; margin-bottom:4px;"
              class="svgFlag"
              src="/images/flags_svg/${r.entry.motherEntry.jurisdiction.a2_code.toLowerCase()}.svg"
            /> ` +
                      r.entry.motherEntry.jurisdiction.name +
                      `</div>`
                  )
                  .join('') +
                '<br/>'
              : ''),
          general: amount2,
          disabled: tmp.length || tmpOut.length ? false : true
        })
      }
    }

    //chart.data = newReferences;

    chart.dateFormatter.inputDateFormat = 'yyyy'
    chart.maskBullets = false
    let dateAxis = chart.xAxes.push(new am4charts.ValueAxis())

    dateAxis.renderer.minGridDistance = 60
    dateAxis.startLocation = 0
    dateAxis.endLocation = 1
    dateAxis.baseInterval = {
      timeUnit: 'year',
      count: 1
    }

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())

    valueAxis.min = 0
    valueAxis.max = max + 1
    let series = chart.series.push(new am4charts.LineSeries())
    series.dataFields.valueX = 'year'
    series.name = t('Same-Sex Marriage')

    series.data = newReferences

    series.dataFields.valueY = 'general'
    series.tooltipHTML = '{countries}'
    series.tooltipText = '[#000]{valueY.value}[/]'

    series.tooltip.getFillFromObject = false
    series.tooltip.background.fill = am4core.color('#FFF')
    series.tooltip.background.fillOpacity = 1
    series.tooltip.label.fill = am4core.color('#575757')

    series.strokeWidth = 3

    let bullet = series.bullets.push(new am4charts.CircleBullet())
    bullet.circle.strokeWidth = 2
    bullet.circle.radius = 4
    bullet.circle.fill = am4core.color('#fff')
    bullet.propertyFields.disabled = 'disabled'

    let bullethover = bullet.states.create('hover')
    bullethover.properties.scale = 1.3

    let series2 = chart.series.push(new am4charts.LineSeries())
    series2.dataFields.valueX = 'year'
    series2.name = t('Civil Partnership')

    series2.data = newReferences2

    series2.dataFields.valueY = 'general'
    series2.tooltipHTML = '{countries}'
    series2.tooltipText = '[#000]{valueY.value}[/]'
    series2.tooltip.getFillFromObject = false
    series2.tooltip.background.fill = am4core.color('#FFF')
    series2.tooltip.background.fillOpacity = 1
    series2.tooltip.label.fill = am4core.color('#575757')

    series2.strokeWidth = 3

    let bullet2 = series2.bullets.push(new am4charts.CircleBullet())
    bullet2.circle.strokeWidth = 2
    bullet2.circle.radius = 4
    bullet2.circle.fill = am4core.color('#fff')
    bullet2.propertyFields.disabled = 'disabled'

    let bullethover2 = bullet.states.create('hover')
    bullethover2.properties.scale = 1.3

    chart.cursor = new am4charts.XYCursor()
    chart.cursor.snapToSeries = [series, series2]
    chart.cursor.xAxis = dateAxis
    chart.cursor.maxTooltipDistance = -1
    chart.legend = new am4charts.Legend()
    //chart.scrollbarX = new am4core.Scrollbar();

    // Add a legend
    //chart.legend = new am4charts.Legend();
    //chart.legend.position = "top";
  }
  useEffect(() => {
    loadChart()
  }, [unMember])
  return (
    <>
      <div
        style={{
          minHeight: '488px',
          maxHeight: '488px',
          marginBottom: '10px',
          marginTop: '10px'
        }}
      >
        <LoadingBar ref={loading} text={t('Loading chart') + '...'} />
        <div id="stackedChartDivX2" style={{ minHeight: '450px' }} />
        <UNMemberSwitch
          unMember={unMember}
          setUnMember={setUnMember}
          extraStyles={{ marginTop: '20px' }}
        />
      </div>
    </>
  )
}
