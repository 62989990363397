import Tippy from '@tippyjs/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IconInfo from '../../../components/icons/IconInfo'
import ViewMoreUpdates from '../ViewMoreUpdates/ViewMoreUpdates'
import useGqlLatestUpdates from '../../../gql/useGqlLatestUpdates'
import dayjs from 'dayjs'
import { i18ndayjs } from '../../../lib/fnDates'
import useGqlTextComponents from '../../../gql/useGqlTextComponents'

export default function LatestUpdatesHome({ area, passRef, areaIntro }) {
  const { t } = useTranslation()
  const { latestUpdates, loading, error } = useGqlLatestUpdates()
  const [latestUpdatesArea, setLatestUpdatesArea] = useState([])

  const { textComponents } = useGqlTextComponents(areaIntro)

  useEffect(() => {
    if (latestUpdates)
      setLatestUpdatesArea(
        latestUpdates
          .map(lu => ({
            ...lu,
            latestTime:
              lu.day && lu.month && lu.year && area && area[1] !== '3'
                ? i18ndayjs(
                    lu.year + '-' + lu.month + '-' + lu.day,
                    'MMMM [es_de] YYYY'
                  )
                : lu.month && lu.year
                ? i18ndayjs(
                    lu.year + '-' + lu.month + '-1',
                    'MMMM [es_de] YYYY'
                  )
                : lu.year,
            latestTimeTooltip:
              lu.day && lu.month && lu.year && area && area[1] !== '3'
                ? i18ndayjs(
                    lu.year + '-' + lu.month + '-' + lu.day,
                    'MMMM [es_de] YYYY'
                  )
                : lu.month && lu.year
                ? i18ndayjs(
                    lu.year + '-' + lu.month + '-1',
                    'MMMM [es_de] YYYY'
                  )
                : lu.year
          }))
          .sort((a, b) => a - b)
      )
  }, [latestUpdates])

  useEffect(() => {
    console.log(latestUpdatesArea)
  }, [latestUpdatesArea])

  return (
    <div className="homepage__latestUpdates">
      <div className="hlu__container">
        {latestUpdatesArea &&
          latestUpdatesArea.slice(0, 100).map((u, i) => (
            <div className="hlu__item" key={i}>
              <div className="hlu__topBar">
                <img
                  className="tableFlag"
                  width="18px"
                  height="12px"
                  style={{ verticalAlign: 'baseline', boxShadow: 'unset' }}
                  alt={u.country_name}
                  src={`/images/flags_svg/${u.jurisdiction.a2_code.toLowerCase()}.svg`}
                />
                <span className="country">{u.jurisdiction.name}</span>
              </div>
              <p className="itemContent">{u.comments}</p>
              <p className="itemDate">{u.latestTime}</p>
            </div>
          ))}
      </div>
    </div>
  )
}
/*

*/
