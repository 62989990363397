import { Accordion } from "react-bootstrap";
import React, { useRef } from "react";
import pieChartPlaceholder from "../../images/pieChartPlaceholder.png";
import barChartPlaceholder from "../../images/barChartPlaceholder.png";
import useGqlEntriesVoting from "../../gql/useGqlEntriesVoting";
import Table from "../components/Table/Table";
import { useTranslation } from "react-i18next";
import { columns } from "../config/a6columns";
import infoIconJurProfile from "../../images/infoIconJurProfile.svg";
import GaugeChartArea6 from "./charts/GaugeChartArea6";
import BarChartArea6 from "./charts/BarChartArea6";
import Tippy from "@tippyjs/react";
import useGqlJurisdictions from "../../gql/useGqlJurisdictions";
import GanttChartArea56 from "./charts/GanttChartArea56";

export default function JurProfileArea6({ jur }) {
  const { entriesVoting, loading, error } = useGqlEntriesVoting(jur.id);
  const { hrcMemberships } = useGqlJurisdictions();
  const [t] = useTranslation();
  return (
    <>
      <div className="content__container jurProfile__area3 jurProfile__area6">
        <div className="tb__pieChart-container tb__pieChart-container4">
          <h4 className="tb__pieChart-title">
            <span style={{ verticalAlign: "top" }}>{t("{{jur}}'s UN Voting Score", { jur: jur.name })}</span>{" "}
            <Tippy
              className="helpTippy"
              content={`Explanation coming soon
`}
              distance={"0"}
              allowHTML={true}
              followCursor={"initial"}
              theme={"ilga-help"}
              placement={"top"}
              popperOptions={{ modifiers: [{ name: "flip", enabled: false }] }}
            >
              <a style={{ verticalAlign: "super", textDecoration: "underline", cursor: "pointer" }}>
                <img src={infoIconJurProfile} alt="Read more" className="readMoreTable" />
              </a>
            </Tippy>
          </h4>{" "}
          <div className="tb__pieChart-actualContainer">
            {entriesVoting && entriesVoting.length && <GaugeChartArea6 references={entriesVoting} />}
          </div>
        </div>
        <div className="tb__recsContainer tb__recsContainer4">
          <h4 className="tb__recs-title">{t("{{jur}}'s Voting breakdown", { jur: jur.name })}</h4>
          <div className="tb__barChart-actualContainer">
            {entriesVoting && entriesVoting.length > 0 && <BarChartArea6 references={entriesVoting} />}
          </div>
        </div>
        <div className="tb__barChart-container">
          <h4 className="tb__barChart-title">{t("{{jur}} Human Rights Council Membership", { jur: jur.name })}</h4>
          <span className="tb__barChart-subtitle" />
          <div className="tb__barChart-actualContainer">
            {hrcMemberships &&
            hrcMemberships.length > 0 &&
            hrcMemberships.filter(m => m.jurisdiction.id === jur.id).length > 0 ? (
              <GanttChartArea56 references={hrcMemberships.filter(m => m.jurisdiction.id === jur.id)} />
            ) : (
              <div style={{ marginTop: "20px" }}>
                {t("{{jur}} has never been a member of the UN Human Rights Council")}
              </div>
            )}
          </div>
        </div>
      </div>

      <section className="section1-jur1 jurProfile__area3">
        <div className="content__container">
          <h4 className="jpjs__area">{t("AREA")} 6</h4>
          <div className="jpjs__titleWithBtn">
            <h2 className="jpjs__title">{t("UN Voting Record & Statements")}</h2>
            {false && (
              <button className="jps__button btn btn-link">
                <img
                  className="jpsb__countryFlag"
                  src={"/images/flags_svg/" + jur.a2_code.toLowerCase() + ".svg"}
                  alt="Country Flag"
                />
                {jur.name}
              </button>
            )}
          </div>

          <div className="area2Table__section table__section">
            <div className="content__container">
              <div className="a2ts__body table-responsive">
                {entriesVoting && entriesVoting.length ? (
                  <Table
                    columns={columns()
                      .filter(c => c.accessor !== "motherEntry.jurisdiction.name")
                      .map(c => (c.isHidden ? { ...c, isHidden: false } : c))}
                    entries={entriesVoting}
                    emptyHeaderBg={"#ecefec"}
                    showFilters={true}
                    fixedHeight={false}
                    disableHover={true}
                    showSubjurSwitch={false}
                    disableFirstCol={true}
                    tableContainerExtraClass={"jurTableContainer"}
                    trMainClass={""}
                    t={t}
                  />
                ) : (
                  <div>{t("No data")}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
