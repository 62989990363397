import React from 'react';

const TreatyBodiesIcon = ({ color = '#c9d8d9'}) => <svg id="TreatyBodies" xmlns="http://www.w3.org/2000/svg" width="46" height="41" viewBox="0 0 46 41">
  <circle id="Ellipse_308" data-name="Ellipse 308" cx="6" cy="6" r="6" fill={color}/>
  <circle id="Ellipse_317" data-name="Ellipse 317" cx="6" cy="6" r="6" transform="translate(17)" fill={color} />
  <circle id="Ellipse_311" data-name="Ellipse 311" cx="6" cy="6" r="6" transform="translate(0 15)" fill={color}/>
  <circle id="Ellipse_318" data-name="Ellipse 318" cx="6" cy="6" r="6" transform="translate(17 15)" fill={color}/>
  <circle id="Ellipse_314" data-name="Ellipse 314" cx="6" cy="6" r="6" transform="translate(0 29)" fill={color}/>
  <circle id="Ellipse_319" data-name="Ellipse 319" cx="6" cy="6" r="6" transform="translate(17 29)" fill={color}/>
  <circle id="Ellipse_310" data-name="Ellipse 310" cx="6" cy="6" r="6" transform="translate(34)" fill={color}/>
  <circle id="Ellipse_313" data-name="Ellipse 313" cx="6" cy="6" r="6" transform="translate(34 15)" fill={color}/>
  <circle id="Ellipse_316" data-name="Ellipse 316" cx="6" cy="6" r="6" transform="translate(34 29)" fill={color} />
</svg>

export default TreatyBodiesIcon;





