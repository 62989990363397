import React, { useState } from "react";
import "./DropdownSearch.css";
import { DebounceInput } from "react-debounce-input";

const DropdownSearchMenu = React.forwardRef(
  ({ coords, setFilter, t, closeDropdown, defaultFilterText, clearFilter }, inputRef) => {
    const [currentFilter, setCurrentFilter] = useState(defaultFilterText);
    return (
      <div className="ignore-this-class dd-wrapper dd-wrapper__search">
        {clearFilter && (
          <button
            className="dd-clear-filter"
            onClick={(e) => {
              clearFilter();
            }}
          >
            { t("Clear Filter") }
          </button>
        )}
        {
          <div className="dd-filter">
            <DebounceInput
              autoFocus
              minLength={2}
              debounceTimeout={300}
              value={currentFilter}
              onChange={(e) => {
                setCurrentFilter(e.target.value.toLowerCase());
                setFilter(e.target.value.toLowerCase());
              }}
              placeholder={t("Search") + "..."}
              ref={inputRef}
              onKeyUp={(e) => {
                if (e.key === "Enter" || e.keyCode === 13) {
                  closeDropdown();
                }
              }}
            />
          </div>
        }
      </div>
    );
  }
);

export default DropdownSearchMenu;
