import React from 'react';

const UPRIcon = ({ color = '#E7EDEF' }) => <svg id="UPR" xmlns="http://www.w3.org/2000/svg" width="49.409" height="44.468" viewBox="0 0 49.409 44.468">
  <path id="Path_1794" data-name="Path 1794" d="M46.468,3H6.941A4.955,4.955,0,0,0,2,7.941V42.527a4.955,4.955,0,0,0,4.941,4.941H46.468a4.955,4.955,0,0,0,4.941-4.941V7.941A4.955,4.955,0,0,0,46.468,3Zm0,39.527H6.941V7.941H46.468Z" transform="translate(-2 -3)"  fill={color} fillRule="evenodd"/>
  <path id="Path_1795" data-name="Path 1795" d="M27.2,11.912,24.286,9l-6.5,6.5-2.892-2.912L12,15.481l5.784,5.825Z" transform="translate(15.089 16.347)"  fill={color} fillRule="evenodd"/>
  <rect id="Rectangle_2231" data-name="Rectangle 2231" width="17.471" height="4.368" transform="translate(15.442 11.516)" fill={color}/>
  <rect id="Rectangle_2268" data-name="Rectangle 2268" width="3.64" height="4.368" transform="translate(9.618 11.516)" fill={color}/>
  <rect id="Rectangle_2232" data-name="Rectangle 2232" width="13.831" height="3.64" transform="translate(15.442 19.523)" fill={color}/>
  <rect id="Rectangle_2269" data-name="Rectangle 2269" width="3.64" height="3.64" transform="translate(9.618 19.523)" fill={color}/>
  <rect id="Rectangle_2233" data-name="Rectangle 2233" width="7.28" height="3.64" transform="translate(15.442 26.803)" fill={color}/>
  <rect id="Rectangle_2270" data-name="Rectangle 2270" width="3.64" height="3.64" transform="translate(9.618 26.803)" fill={color}/>
</svg>

export default UPRIcon;



