import React from "react";

const NoteRemoveIcon = ({ size, color = "#E7EDEF", title = "" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ width: size, height: size }} viewBox={`0 0 26 26`}>
    <title>{title}</title>
    <g fill={color}>
      <path d="M19 13C19.7 13 20.37 13.13 21 13.35V9L15 3H5C3.89 3 3 3.89 3 5V19C3 20.11 3.9 21 5 21H13.35C13.13 20.37 13 19.7 13 19C13 15.69 15.69 13 19 13M14 4.5L19.5 10H14V4.5M20.41 19L22.54 21.12L21.12 22.54L19 20.41L16.88 22.54L15.47 21.12L17.59 19L15.47 16.88L16.88 15.47L19 17.59L21.12 15.47L22.54 16.88L20.41 19Z" />
    </g>
  </svg>
);

export default NoteRemoveIcon;
