var map = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: "PS-WBA",
      properties: { name: "Palestinian Territories", id: "PS-WBA" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.4505, 31.4793],
            [35.5589, 31.7656],
            [35.5513, 32.3955],
            [35.1931, 32.5344],
            [34.9561, 32.1611],
            [34.9507, 31.6022],
            [35.4505, 31.4793]
          ].reverse()
        ]
      }
    }
  ]
};

export default map;
