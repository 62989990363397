import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GET_A4_SP = gql(`
  query GetEntriesSpecialProcedures($lang: String, $jur_id: String) {
    entriesSpecialProcedures(lang: $lang, jur_id:$jur_id){
    id
    motherEntry {
        topics {
          id
          name
        }
        jurisdiction {
          id
          name
          un_member
          a2_code
          regions{
            id
          }
        }
        subjurisdiction {
          id
          name
        }
        jurisdictions {
          id
          name
          a2_code
        }
        sources {
          id
          order
          section
          original_official_filename
          original_filename
          translated_filename
          enacted
          original_official_language{
            name
            id
          }
          original_official_language2{
            name
            id
          }
          original_language{
            name
            id
          }
          original_language2{
            name
            id
          }
        }
      }
    special_procedure_types {
      id
      name
      full_name
      mini_name
    }
    special_procedure_types_concat
    special_procedure_types_concat_full
    session
    date
    special_procedure_cu_type {
      id
      name
    }
    text
    para
    is_auto_translate
    scope {
      id
      name
    }
    un_symbol
    title
  }
}
`);

const GET_A4_SP_TYPES = gql(`
  query GetEntriesSpecialProcedures($lang: String, $jur_id: String) {
    specialProceduresTypes(lang: $lang, jur_id:$jur_id){

      id
      name
      mini_name
      amount_all
      amount_rec
      byYear {
        year
        amount_rec
      }
    }
  }
`);

function useGqlEntriesSpecialProcedures(jur_id = null) {
  const { i18n } = useTranslation();
  const entriesSpecialProcedures = useQuery(GET_A4_SP, { variables: { jur_id, lang: i18n.language } });
  const specialProceduresTypes = useQuery(GET_A4_SP_TYPES, { variables: { jur_id, lang: i18n.language } });

  return {
    entriesSpecialProcedures: entriesSpecialProcedures.data && entriesSpecialProcedures.data.entriesSpecialProcedures,
    specialProceduresTypes: specialProceduresTypes.data && specialProceduresTypes.data.specialProceduresTypes,
    loadingSpTypes: specialProceduresTypes.loading,
    loadingSp: entriesSpecialProcedures.loading,
  };
}

export default useGqlEntriesSpecialProcedures;
