import React from "react";
import { Helmet } from "react-helmet-async";
import MainLayout from "../../layouts/MainLayout";
import { Link } from "react-router-dom";

import "./AreaHome.css";
import BookIcon from "../components/icons/BookIcon";
import BubblesIcon from "../components/icons/BubblesIcon";
import TreatiesIcon from "../components/icons/TreatiesIcon";
import TreatyBodiesIcon from "../components/icons/TreatyBodiesIcon";
import UPRIcon from "../components/icons/UPRIcon";
import VotingIcon from "../components/icons/VotingIcon";
import routes from "../../config/routes";
import { useTranslation, Trans } from "react-i18next";
import { getTextsAreas } from "../texts/areas/Main";

export default function Area() {
  const theRoutes = routes();
  const { t, i18n } = useTranslation();

  return (
    <MainLayout section="areas">
      <div className="area__page areaHome__page">
        <div className="content__container">
          <Link to={theRoutes.find(r => r.areaNumber === 1).path} className="areaLinkBox">
            <div className="areaLink__header">
              <p>{t("AREA")} 1</p>
              <h6>
                <Trans>
                  Legal
                  <br />
                  Frameworks
                </Trans>
              </h6>
            </div>
            <div className="areaLink__body">
              <div className="iconContainer">
                <BookIcon color="rgb(201, 216, 217)" />
              </div>
              <p className="areaLink__description">{getTextsAreas()[1][i18n.language]}</p>
            </div>
          </Link>
          <Link to={theRoutes.find(r => r.areaNumber === 2).path} className="areaLinkBox">
            <div className="areaLink__header">
              <p>{t("AREA")} 2</p>
              <h6>
                <Trans>
                  International Human
                  <br />
                  Rights Treaties
                </Trans>
              </h6>
            </div>
            <div className="areaLink__body">
              <div className="iconContainer">
                <TreatiesIcon color="rgb(201, 216, 217)" />
              </div>
              <p className="areaLink__description">{getTextsAreas()[2][i18n.language]}</p>
            </div>
          </Link>
          <Link to={theRoutes.find(r => r.areaNumber === 3).path} className="areaLinkBox">
            <div className="areaLink__header">
              <p>{t("AREA")} 3</p>
              <h6>
                <Trans>
                  UN Treaty
                  <br />
                  Bodies
                </Trans>
              </h6>
            </div>
            <div className="areaLink__body">
              <div className="iconContainer">
                <TreatyBodiesIcon color="rgb(201, 216, 217)" />
              </div>
              <p className="areaLink__description">{getTextsAreas()[3][i18n.language]}</p>
            </div>
          </Link>
          <Link to={theRoutes.find(r => r.areaNumber === 4).path} className="areaLinkBox">
            <div className="areaLink__header">
              <p>{t("AREA")} 4</p>
              <h6>
                <Trans>
                  UN Special
                  <br />
                  Procedures
                </Trans>
              </h6>
            </div>
            <div className="areaLink__body">
              <div className="iconContainer">
                <BubblesIcon color="rgb(201, 216, 217)" />
              </div>
              <p className="areaLink__description">{getTextsAreas()[4][i18n.language]}</p>
            </div>
          </Link>
          <Link to={theRoutes.find(r => r.areaNumber === 5).path} className="areaLinkBox">
            <div className="areaLink__header">
              <p>{t("AREA")} 5</p>
              <h6>
                <Trans>
                  Universal
                  <br />
                  Periodic Review
                </Trans>
              </h6>
            </div>
            <div className="areaLink__body">
              <div className="iconContainer">
                <UPRIcon color="rgb(201, 216, 217)" />
              </div>
              <p className="areaLink__description">{getTextsAreas()[5][i18n.language]}</p>
            </div>
          </Link>
          <Link to={theRoutes.find(r => r.areaNumber === 6).path} className="areaLinkBox">
            <div className="areaLink__header">
              <p>{t("AREA")} 6</p>
              <h6>
                <Trans>
                  UN Voting Record &<br />
                  Statements
                </Trans>
              </h6>
            </div>
            <div className="areaLink__body">
              <div className="iconContainer">
                <VotingIcon color="rgb(201, 216, 217)" />
              </div>
              <p className="areaLink__description">{getTextsAreas()[6][i18n.language]}</p>
            </div>
          </Link>
        </div>
      </div>
    </MainLayout>
  );
}
