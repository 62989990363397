import React, { useContext, useEffect } from 'react'
import { AccountContext } from '../context/Accounts'

export default function UserManagement() {
  const { getSession, refreshSession } = useContext(AccountContext)
  useEffect(() => {
    getSession()
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        //nsole.log("SESSION REFRESH", await refreshSession());
      } catch (e) {
        //nsole.log("NO SESSION", e);
      }
    }, 59000)
    return () => clearInterval(interval)
  }, [refreshSession])

  return <></>
}
