import { citeA114 } from '../config/a114columns'
import { citeA115 } from '../config/a115columns'
import { citeA116 } from '../config/a116columns'
import { citeA117 } from '../config/a117columns'
import { citeA118 } from '../config/a118_gm_columns'
import { citeA13 } from '../config/a13columns'
import { citeA14 } from '../config/a14columns'
import { citeA15 } from '../config/a15columns'
import { citeA3 } from '../config/a3columns'
import { citeA4 } from '../config/a4columns'
import { citeA5 } from '../config/a5columns'
import { citeA11 } from '../config/columns'
import i18n from '../../config/i18n'

export const allCites = (entry, setToast) => {
  let areaNew = entry.area
  let subarea
  if (areaNew.indexOf('-', 3) !== -1)
    areaNew = entry.area.substring(0, areaNew.indexOf('-', 3))
  console.log(entry)
  switch (areaNew) {
    case 'A1-1':
      citeA11(
        {
          ...entry,
          explan: entry.details,
          ...JSON.parse(entry.extra_data)
        },
        setToast
      ) // Convert explan into details
      break
    case 'A1-3':
      citeA13(
        {
          ...entry,
          [entry.area.substring(entry.area.indexOf('-', 3) + 1) +
          '_explan']: entry.details,
          ...JSON.parse(entry.extra_data)
        },
        setToast
      ) // Convert explan into details
      break
    case 'A1-4':
      citeA14(
        { ...entry, explan: entry.details, ...JSON.parse(entry.extra_data) },
        setToast
      ) // Convert explan into details
      break
    case 'A1-5':
    case 'A1-6':
    case 'A1-7':
    case 'A1-8':
    case 'A1-9':
    case 'A1-10':
    case 'A1-11':
    case 'A1-12':
    case 'A1-13':
      subarea = parseInt(entry.area.substring(3, entry.area.indexOf('-', 3)))
      citeA15(
        {
          ...entry,
          [entry.area.substring(entry.area.indexOf('-', 3) + 1) +
          '_explan']: entry.details
        },
        setToast,
        subarea
      ) // Convert explan into details
      break
    case 'A1-14':
      citeA114({ ...entry, explan: entry.details }, setToast) // Convert explan into details
      break
    case 'A1-15':
      citeA115(
        {
          ...entry,
          [entry.area.substring(entry.area.indexOf('-', 3) + 1) +
          '_explan']: entry.details,
          ...JSON.parse(entry.extra_data)
        },
        setToast
      ) // Convert explan into details
      break
    case 'A1-16':
      citeA116(
        {
          ...entry,
          [entry.area.substring(entry.area.indexOf('-', 3) + 1) +
          '_explan']: entry.details,
          ...JSON.parse(entry.extra_data)
        },
        setToast
      ) // Convert explan into details
      break
    case 'A1-17':
      citeA117(
        {
          ...entry,
          explan: entry.details
        },
        setToast
      ) // Convert explan into details
      break
    case 'A1-18':
      if (entry.area === 'A1-18-name_change') {
        citeA118(
          {
            ...entry,
            name_change_explan: entry.details,
            ...JSON.parse(entry.extra_data)
          },
          setToast,
          false
        ) // Convert explan into details
      } else {
        citeA118(
          {
            ...entry,
            gender_marker_explan: entry.details,
            ...JSON.parse(entry.extra_data)
          },
          setToast
        ) // Convert explan into details
      }

      break
    case 'A3-1':
    case 'A3-2':
    case 'A3-3':
    case 'A3-4':
    case 'A3-5':
    case 'A3-6':
    case 'A3-7':
    case 'A3-8':
    case 'A3-9':
    case 'A3-10':
      citeA3(
        { ...entry, text: entry.details, ...JSON.parse(entry.extra_data) },
        setToast
      ) // Convert explan into details
      break
    case 'A4':
      let extra = JSON.parse(entry.extra_data)
      let extraN = extra
      Object.keys(extra).forEach(k => {
        if (k.endsWith('_' + i18n.language))
          extraN = {
            ...extraN,
            [k.substr(0, k.lastIndexOf('_' + i18n.language))]: extra[k]
          }
      })
      citeA4({ ...entry, text: entry.details, ...extraN }, setToast) // Convert explan into details
      break
    case 'A5':
      citeA5(
        {
          ...entry,
          explan: entry.details,
          text: entry.details,
          ...JSON.parse(entry.extra_data)
        },
        setToast
      ) // Convert explan into details
      break
  }
}
