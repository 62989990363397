/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import MainLayout from '../layouts/MainLayout'

import axios from 'axios'
import { useMemo } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import { history } from '../App.js'
import routes, { getPath, getPathLang } from '../config/routes'
import useGqlJurisdictionsDyn from '../gql/useGqlJurisdictions'
import useGqlTimeline from '../gql/useGqlTimeline'
import BackToTopBtn from './components/JurProfile/BackToTopBtn'
import DropdownIndicator from './components/JurProfile/DropdownIndicator'
import NewsItems from './components/JurProfile/NewsItems'
import UpcomingDeadlines from './components/JurProfile/UpcomingDeadlines'
import JurMap from './components/Map/JurMap'
import ReportErrorModal from './components/Table/ReportErrorModal'
import LoadingBar from './components/ui/LoadingBar'
import './JurProfile.css'
import JurProfileArea1 from './JurProfileAreas/JurProfileArea1'
import JurProfileArea2 from './JurProfileAreas/JurProfileArea2'
import JurProfileArea3 from './JurProfileAreas/JurProfileArea3'
import JurProfileArea4 from './JurProfileAreas/JurProfileArea4'
import JurProfileArea5 from './JurProfileAreas/JurProfileArea5'
import JurProfileArea6 from './JurProfileAreas/JurProfileArea6'
import Timeline from './components/JurProfile/Timeline'
import seo from '../config/seo.json'
function useForceUpdate() {
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(0) // integer state
  return () => setValue(value => value + 1) // update the state to force render
}

export default function JurProfile({ subjur = false }) {
  // eslint-disable-next-line no-unused-vars
  const [changeJur, setChangeJur] = useState(true)
  const [currentJur, setCurrentJur] = useState(null)

  const [latestNews, setLatestNews] = useState([])

  const [reportErrorOpen, setReportErrorOpen] = useState(false)

  const { t, i18n } = useTranslation()
  /* IMPORTANT, USING STATIC GQL JURS AS AREA5 KEEPS RELOADING IF NOT*/

  const forceUpdate = useForceUpdate()

  const jurProfile5 = useMemo(() => <JurProfileArea5 jur={currentJur} />, [
    currentJur
  ])

  let { slug } = useParams()

  const {
    jurisdictions,
    loading,
    error,
    loading_subjurs,
    jurSubjurs
  } = useGqlJurisdictionsDyn(null, slug)

  useEffect(() => {
    if (!error && jurisdictions && jurisdictions.length && changeJur) {
      let jur = jurisdictions.find(j => j.slug === slug)

      if (jur) {
        setChangeJur(false)
        setCurrentJur(jur)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jurisdictions, error, loading, changeJur])

  useEffect(() => {
    if (currentJur) {
      setLatestNews([])
      axios
        .get(
          'https://monitor.ilga.org/api/articles/getDbLatestFromJur/' +
            currentJur.id
        )
        .then(res => {
          if (res.data.msg && res.data.msg.length) setLatestNews(res.data.msg)
          else setLatestNews(false)
        })
        .catch(e => {
          console.error('Network error fetchin latest news', e)
        })
      if (
        !currentJur.un_member &&
        ['DT-9', 'DT-20', 'DT-23', 'DT-28', 'N-2', 'DT-31', 'S-1'].indexOf(
          currentJur.id
        ) === -1
      )
        setActiveArea('area1')
    }
  }, [currentJur])

  useEffect(() => {
    if (
      currentJur &&
      getPathLang(window.location.pathname.slice(1)) !== i18n.language
    ) {
      history.push(
        getPath('jur_profile').replace(
          ':slug',
          currentJur['slug_' + i18n.language]
        )
      )
    }
  }, [i18n.language])

  useEffect(() => {
    console.log(jurSubjurs)
  }, [jurSubjurs])

  useEffect(() => {
    console.log(jurisdictions)
  }, [jurisdictions])

  // area change state
  const [activeArea, setActiveArea] = useState('area1')

  const ActiveAreaComponent = React.memo(() => {
    switch (activeArea) {
      case 'area1':
        return (
          <JurProfileArea1
            jur={currentJur}
            setReportErrorOpen={entry => {
              setReportErrorOpen(entry)
            }}
          />
        )
      case 'area2':
        return <JurProfileArea2 jur={currentJur} />
      case 'area3':
        return <JurProfileArea3 jur={currentJur} />
      case 'area4':
        return <JurProfileArea4 jur={currentJur} />
      case 'area5':
        return jurProfile5
      case 'area6DISABLED':
        return <JurProfileArea6 jur={currentJur} />
      default:
        return <JurProfileArea1 jur={currentJur} />
    }
  }, [activeArea])

  return (
    <MainLayout
      section="jurs"
      metaTitle={
        currentJur
          ? seo['jur_profile']['title_' + i18n.language].replace(
              ':slug',
              currentJur.name
            )
          : null
      }
      metaDescription={
        currentJur
          ? seo['jur_profile']['description_' + i18n.language].replace(
              ':slug',
              currentJur.name
            )
          : null
      }
    >
      <div className="jur__page jur-profile__page">
        <ReportErrorModal
          isOpen={reportErrorOpen}
          setIsOpen={setReportErrorOpen}
        />
        {currentJur && !loading && (
          <>
            <section className="jurHero__section">
              <div className="content__container">
                <div className="jurPage__searchBar">
                  <Select
                    components={{
                      ...components,
                      DropdownIndicator
                    }}
                    className="selectJur__select reactSelect"
                    defaultValue={{
                      value: 0,
                      label: t('Search by jurisdiction...')
                    }}
                    maxMenuHeight="500px"
                    options={
                      jurisdictions
                        ? jurisdictions
                            .filter(j => j.a2_code !== 'G0')
                            .map(j => ({
                              value: j.id,
                              label: j.name,
                              a2_code: j.a2_code,
                              slug: j.slug
                            }))
                        : [
                            {
                              value: 0,
                              label: 'Loading...'
                            }
                          ]
                    }
                    placeholder="Change jurisdiction..."
                    onChange={e => {
                      history.push(
                        getPath('jur_profile').replace(
                          ':slug',
                          e.slug.toLowerCase()
                        )
                      )
                      setCurrentJur(jurisdictions.find(j => j.id === e.value))
                      forceUpdate()
                    }}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 })
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    components={{
                      Option: props => (
                        <components.Option {...props}>
                          {props.data.a2_code && (
                            <img
                              src={
                                '/images/flags_svg/' +
                                props.data.a2_code.toLowerCase() +
                                '.svg'
                              }
                              style={{
                                width: 16,
                                verticalAlign: 'baseline'
                              }}
                              alt={props.data.label}
                            />
                          )}{' '}
                          {props.data.label}
                        </components.Option>
                      )
                    }}
                  />
                </div>
                <div className="jh__countryInfo-container">
                  <div className="jh__flagContainer">
                    <img
                      className="jh__countryFlag"
                      src={
                        '/images/flags_svg/' +
                        currentJur.a2_code.toLowerCase() +
                        '.svg'
                      }
                      alt="Country Flag"
                    />
                  </div>
                  <div className="jh__countryInfo">
                    <h1>{currentJur.name}</h1>{' '}
                    <span className="jh__countryStatus">
                      {currentJur.un_member
                        ? t('UN Member')
                        : t('Non UN Member')}
                    </span>
                  </div>
                </div>
                <div className="jh__countryData">
                  {!loading_subjurs && (
                    <JurMap
                      setChangeJur={setChangeJur}
                      jurisdictions={jurisdictions}
                      jur={currentJur}
                      jurSubjurs={jurSubjurs}
                    />
                  )}

                  {!currentJur.un_member && (
                    <div className="jh__countryNewsContainer jh__countryNewsContainerNonUn">
                      <Tabs defaultActiveKey="LatestNews" id="uncontrolled-tab">
                        <Tab
                          className="jh__tab"
                          eventKey="LatestNews"
                          title={t('Latest news')}
                        >
                          <NewsItems news={latestNews} />
                        </Tab>
                      </Tabs>
                    </div>
                  )}
                  {currentJur.un_member && (
                    <div className="jh__countryNewsContainer">
                      <Tabs defaultActiveKey="LatestNews" id="uncontrolled-tab">
                        <Tab
                          className="jh__tab"
                          eventKey="LatestNews"
                          title={t('Latest news')}
                        >
                          <NewsItems news={latestNews} />
                        </Tab>

                        <Tab
                          className="jh__tab"
                          eventKey="UpcomingDeadlines"
                          title={t('Upcoming deadlines')}
                        >
                          <UpcomingDeadlines jur={currentJur} />
                          <button
                            className="btn btn-default"
                            onClick={e =>
                              history.push(
                                routes().find(r => r.id === 'upcoming').path
                              )
                            }
                          >
                            {t('More')}
                          </button>
                        </Tab>
                      </Tabs>
                    </div>
                  )}

                  <Timeline currentJur={currentJur} />
                </div>
              </div>
            </section>

            <section className="samePageLinks__section">
              <div className="content__container">
                <div className="spl__container">
                  {(currentJur.un_member ||
                    ['DT-9', 'DT-20', 'DT-23', 'DT-28', 'N-2', 'DT-31'].indexOf(
                      currentJur.id
                    ) !== -1) && (
                    <>
                      <div
                        onClick={() => setActiveArea('area1')}
                        className={`spl__link ${
                          activeArea === 'area1' ? 'active' : ''
                        }`}
                      >
                        <span className="spl__linkHeader">{t('AREA')} 1</span>
                        {t('Legal Frameworks')}
                      </div>

                      <div
                        onClick={() => setActiveArea('area2')}
                        className={`spl__link ${
                          activeArea === 'area2' ? 'active' : ''
                        }`}
                      >
                        <span className="spl__linkHeader">{t('AREA')} 2</span>
                        {t('International Human Rights Treaties')}
                      </div>
                      <div
                        onClick={() => setActiveArea('area3')}
                        className={`spl__link ${
                          activeArea === 'area3' ? 'active' : ''
                        }`}
                      >
                        <span className="spl__linkHeader">{t('AREA')} 3</span>
                        {t('UN Treaty Bodies')}
                      </div>
                      <div
                        onClick={() => setActiveArea('area4')}
                        className={`spl__link ${
                          activeArea === 'area4' ? 'active' : ''
                        }`}
                      >
                        <span className="spl__linkHeader">{t('AREA')} 4</span>
                        {t('UN Special Procedures')}
                      </div>
                      <div
                        onClick={() => setActiveArea('area5')}
                        className={`spl__link ${
                          activeArea === 'area5' ? 'active' : ''
                        }`}
                      >
                        <span className="spl__linkHeader">{t('AREA')} 5</span>
                        {t('Universal Periodic Review (UPR)')}
                      </div>
                      {false && (
                        <div
                          onClick={() => setActiveArea('area6')}
                          className={`spl__link ${
                            activeArea === 'area6' ? 'active' : ''
                          }`}
                        >
                          <span className="spl__linkHeader">{t('AREA')} 6</span>
                          {t('UN Voting Record & Statements')}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </section>

            <ActiveAreaComponent jur={currentJur} />
            <React.Suspense fallback={null}>
              <JurProfileArea2 hide jur={currentJur} />
              <JurProfileArea3 hide jur={currentJur} />
              <JurProfileArea4 hide jur={currentJur} />
              <JurProfileArea5 hide jur={currentJur} />
            </React.Suspense>
            <BackToTopBtn />
          </>
        )}
      </div>
    </MainLayout>
  )
}
