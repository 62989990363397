import MagGlass from '../icons/MagGlass'
import Select, { components } from 'react-select'

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <MagGlass className="jp__si-icon" size={24} />
    </components.DropdownIndicator>
  )
}

export default DropdownIndicator
