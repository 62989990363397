import { gql, useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const GET_TEXT_COMPONENTS = gql(`
  query GetTextComponents($lang: String,$section: String) {
    textComponents(lang: $lang,section: $section) {
      id
      section
      intro
      map
      methodology
    }
}
`)

const UPDATE_TEXT_COMPONENT = gql(`
  mutation UpdateTextComponent($lang: String,$section: String!, $texts: TextComponentInput!) {
    updateTextComponent(lang: $lang,section: $section, texts: $texts) {
      id
      section
      intro
      map
      methodology
    }
  }
`)

function useGqlTextComponents(section = null) {
  const { i18n } = useTranslation()
  const textComponents = useQuery(GET_TEXT_COMPONENTS, {
    variables: { section, lang: i18n.language }
  })
  const [updateTextComponent] = useMutation(UPDATE_TEXT_COMPONENT)

  return {
    textComponents: textComponents.data
      ? textComponents.data.textComponents
      : [],
    updateTextComponent
  }
}

export default useGqlTextComponents
