import React from "react";

export const customSelectStyles = {
    option: (provided, state) => {
        return {
            ...provided,
            backgroundColor: '#ffffff !important',
            color: '#4E4E4E',
            paddingTop: 8,
            paddingRight: 20,
            paddingBottom: 8,
            paddingLeft: 20,
        }
    },
    control: (provided) => ({
        ...provided,
        // none of react-select's styles are passed to <Control />
        backgroundColor: '#F4F4F4 !important',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
};

export const logoIlga = <svg
            id="Group_469"
            className="ilgaLogo menuLogo"
            data-name="Group 469"
            xmlns="http://www.w3.org/2000/svg"
            width="74.282"
            height="43.508"
            viewBox="0 0 74.282 43.508"
        >
            <g id="Group_467" data-name="Group 467" transform="translate(48.373 34.981)">
                <path
                    id="Path_653"
                    data-name="Path 653"
                    d="M194.8,202.864l-.914,2.71a.325.325,0,0,1-.343.244h-.711a.3.3,0,0,1-.333-.254l-1.288-4.756a.514.514,0,0,1-.015-.114c0-.128.082-.192.249-.192h.93a.3.3,0,0,1,.327.26l.685,2.846a.021.021,0,0,0,.021.015c.006,0,.012,0,.015-.015l.774-2.84a.306.306,0,0,1,.332-.255h.6a.311.311,0,0,1,.338.255l.8,2.788c0,.01.008.015.015.015a.021.021,0,0,0,.021-.015l.638-2.794a.29.29,0,0,1,.323-.26h.929c.166,0,.249.068.249.2a.373.373,0,0,1-.015.1l-1.282,4.756a.307.307,0,0,1-.333.254h-.7a.328.328,0,0,1-.348-.244Z"
                    transform="translate(-191.198 -198.373)"
                    fill="#3d3d3d"
                />
                <path
                    id="Path_654"
                    data-name="Path 654"
                    d="M205.2,200.382a2.608,2.608,0,0,1,1.911.8,2.634,2.634,0,0,1,.8,1.931,2.742,2.742,0,0,1-4.683,1.947,2.745,2.745,0,0,1-.011-3.873A2.686,2.686,0,0,1,205.2,200.382Zm-.036,1.444a1.284,1.284,0,0,0-1.282,1.287,1.251,1.251,0,0,0,.379.919,1.291,1.291,0,0,0,1.823.006,1.293,1.293,0,0,0-.919-2.212Z"
                    transform="translate(-194.651 -198.336)"
                    fill="#3d3d3d"
                />
                <path
                    id="Path_655"
                    data-name="Path 655"
                    d="M212.864,200.7a1.554,1.554,0,0,1,1.028-.316h.441q.307-.01.3.3v.851a.256.256,0,0,1-.3.3h-.451a.9.9,0,0,0-.644.244.819.819,0,0,0-.281.607v2.794q.005.311-.3.3h-.841q-.317.01-.306-.3v-4.715q-.01-.311.306-.3h.758C212.753,200.458,212.85,200.536,212.864,200.7Z"
                    transform="translate(-197.449 -198.336)"
                    fill="#3d3d3d"
                />
                <path
                    id="Path_656"
                    data-name="Path 656"
                    d="M217.21,204.571v-6.843c-.008-.208.095-.308.306-.3h.842q.305-.01.3.3v6.843q.005.311-.3.3h-.842C217.3,204.879,217.2,204.779,217.21,204.571Z"
                    transform="translate(-199.204 -197.427)"
                    fill="#3d3d3d"
                />
                <path
                    id="Path_657"
                    data-name="Path 657"
                    d="M224.728,199.809v-2.081q0-.312.3-.3h.842c.211-.007.314.093.306.3v6.843c.008.208-.095.308-.306.3h-.763q-.3.016-.307-.265v-.031a2.937,2.937,0,0,1-1.387.379,2.741,2.741,0,0,1,0-5.483A2.786,2.786,0,0,1,224.728,199.809ZM223.4,200.9a1.235,1.235,0,0,0-.914.379,1.341,1.341,0,0,0,0,1.879,1.247,1.247,0,0,0,.925.379,1.293,1.293,0,0,0,1.308-1.318,1.287,1.287,0,0,0-.379-.94A1.266,1.266,0,0,0,223.4,200.9Z"
                    transform="translate(-200.269 -197.427)"
                    fill="#3d3d3d"
                />
            </g>
            <g id="Group_468" data-name="Group 468">
                <path
                    id="Path_658"
                    data-name="Path 658"
                    d="M124.64,146.891a3.279,3.279,0,1,1-2.376,5.592,3.175,3.175,0,0,1-.948-2.337,3.122,3.122,0,0,1,.965-2.283A3.167,3.167,0,0,1,124.64,146.891Z"
                    transform="translate(-121.315 -146.891)"
                    fill="#3d3d3d"
                />
                <path
                    id="Path_659"
                    data-name="Path 659"
                    d="M171.753,160.788v-.07q.024-1.237,1.355-1.162h3.453a1.154,1.154,0,0,1,1.352,1.358l-.016,20.007a12.8,12.8,0,0,1-3.9,9.412,12.582,12.582,0,0,1-9.289,3.84,12.717,12.717,0,0,1-6.788-1.984q-1.306-.821-1.306-1.521a1.385,1.385,0,0,1,.467-.908l2.242-2.4a1.365,1.365,0,0,1,.98-.533,1.28,1.28,0,0,1,.723.26,7.09,7.09,0,0,0,3.872,1.25,6.278,6.278,0,0,0,5.018-2.363,5.575,5.575,0,0,0,1.542-3.494.41.41,0,0,0-.023-.116.067.067,0,0,1-.024-.047,13.7,13.7,0,0,1-5.9,1.472,12.319,12.319,0,1,1,.044-24.638A13.211,13.211,0,0,1,171.753,160.788Zm-6.327,4.76a5.582,5.582,0,0,0-4.108,1.689,5.937,5.937,0,0,0,4.168,10.164,5.633,5.633,0,0,0,4.154-1.688,5.72,5.72,0,0,0,1.706-4.217,5.8,5.8,0,0,0-1.7-4.229A5.706,5.706,0,0,0,165.427,165.548Z"
                    transform="translate(-131.113 -150.666)"
                    fill="#3d3d3d"
                />
                <path
                    id="Path_660"
                    data-name="Path 660"
                    d="M211.7,160.976v-.21q0-1.259,1.353-1.208h3.221a1.155,1.155,0,0,1,1.353,1.357l.009,21.182a1.146,1.146,0,0,1-1.353,1.348H213.06q-1.353.042-1.354-1.218v-.163a12.32,12.32,0,0,1-6.393,1.727,12.486,12.486,0,0,1-12.4-12.361,12.369,12.369,0,0,1,12.339-12.273A12.728,12.728,0,0,1,211.7,160.976Zm-6.509,4.573a5.576,5.576,0,0,0-4.106,1.688,5.942,5.942,0,0,0,4.181,10.162,5.631,5.631,0,0,0,4.153-1.688,5.71,5.71,0,0,0,1.7-4.216,5.8,5.8,0,0,0-1.706-4.229A5.708,5.708,0,0,0,205.189,165.549Z"
                    transform="translate(-143.354 -150.666)"
                    fill="#3d3d3d"
                />
                <path
                    id="Path_661"
                    data-name="Path 661"
                    d="M126.508,159.74h-3.779q-1.422-.052-1.382,1.348l0,21.185q-.052,1.4,1.371,1.358h3.78q1.377.052,1.358-1.348l0-21.186Q127.885,159.7,126.508,159.74Z"
                    transform="translate(-121.324 -150.845)"
                    fill="#3d3d3d"
                />
                <path
                    id="Path_662"
                    data-name="Path 662"
                    d="M135.91,148.383a1.157,1.157,0,0,1,1.36-1.36h3.8a1.157,1.157,0,0,1,1.36,1.36V171.3q0,2.744,2.65,2.744h.807q1.43-.024,1.383,1.384v2.931q.048,1.383-1.383,1.36h-2.543a7.4,7.4,0,0,1-5.44-1.994,7.318,7.318,0,0,1-1.994-5.417Z"
                    transform="translate(-125.807 -146.931)"
                    fill="#3d3d3d"
                />
            </g>
        </svg>;

export const logoDatabase = <svg
            className="ilgaDatabase menuLogo"
            xmlns="http://www.w3.org/2000/svg"
            width="184.293"
            height="32.71"
            viewBox="0 0 184.293 32.71"
        >
            <g id="Group_788" data-name="Group 788" transform="translate(-357.839 -43.348)">
                <path
                    id="Path_663"
                    data-name="Path 663"
                    d="M254.412,158.977V147.961a.814.814,0,0,1,.946-.946h2.027a.814.814,0,0,1,.946.946v30.457a.814.814,0,0,1-.946.946h-2.027a.814.814,0,0,1-.946-.946v-1.735a11.851,11.851,0,0,1-7.929,3.041,11.915,11.915,0,0,1-8.516-20.3,11.637,11.637,0,0,1,8.516-3.491A11.851,11.851,0,0,1,254.412,158.977Zm-8,.879a7.847,7.847,0,1,0,5.7,2.3A7.7,7.7,0,0,0,246.415,159.856Z"
                    transform="translate(123.318 -103.667)"
                    fill="#707070"
                />
                <path
                    id="Path_664"
                    data-name="Path 664"
                    d="M292.294,162.944v-1.78a.814.814,0,0,1,.946-.946h2.027a.813.813,0,0,1,.946.946v21.221a.814.814,0,0,1-.946.946H293.24a.814.814,0,0,1-.946-.946V180.65a12.139,12.139,0,0,1-7.93,3.041,11.915,11.915,0,0,1-8.516-20.3,11.638,11.638,0,0,1,8.516-3.491A12.139,12.139,0,0,1,292.294,162.944Zm-8,.879a7.848,7.848,0,1,0,5.7,2.3A7.706,7.706,0,0,0,284.3,163.823Z"
                    transform="translate(111.659 -107.634)"
                    fill="#707070"
                />
                <path
                    id="Path_665"
                    data-name="Path 665"
                    d="M351.926,162.944v-1.78a.813.813,0,0,1,.946-.946H354.9a.813.813,0,0,1,.946.946v21.221a.814.814,0,0,1-.946.946h-2.028a.814.814,0,0,1-.946-.946V180.65a12.139,12.139,0,0,1-7.93,3.041,11.915,11.915,0,0,1-8.516-20.3A11.638,11.638,0,0,1,344,159.9,12.139,12.139,0,0,1,351.926,162.944Zm-8,.879a7.848,7.848,0,1,0,5.7,2.3A7.7,7.7,0,0,0,343.928,163.823Z"
                    transform="translate(93.304 -107.634)"
                    fill="#707070"
                />
                <path
                    id="Path_666"
                    data-name="Path 666"
                    d="M375.1,176.683v1.735a.814.814,0,0,1-.946.946H372.13a.814.814,0,0,1-.946-.946V147.961a.814.814,0,0,1,.946-.946h2.028a.814.814,0,0,1,.946.946v11.016a11.854,11.854,0,0,1,7.93-3.041,11.562,11.562,0,0,1,8.493,3.491,11.905,11.905,0,0,1-8.493,20.3A11.909,11.909,0,0,1,375.1,176.683Zm7.863-16.828a7.627,7.627,0,0,0-5.565,2.3,8.1,8.1,0,1,0,5.565-2.3Z"
                    transform="translate(81.254 -103.667)"
                    fill="#707070"
                />
                <path
                    id="Path_667"
                    data-name="Path 667"
                    d="M428.162,162.944v-1.78a.814.814,0,0,1,.946-.946h2.027a.814.814,0,0,1,.946.946v21.221a.814.814,0,0,1-.946.946h-2.027a.814.814,0,0,1-.946-.946V180.65a12.139,12.139,0,0,1-7.93,3.041,11.915,11.915,0,0,1-8.515-20.3,11.636,11.636,0,0,1,8.515-3.491A12.139,12.139,0,0,1,428.162,162.944Zm-8,.879a7.848,7.848,0,1,0,5.7,2.3A7.7,7.7,0,0,0,420.165,163.823Z"
                    transform="translate(69.839 -107.634)"
                    fill="#707070"
                />
                <path
                    id="Path_668"
                    data-name="Path 668"
                    d="M447.589,179.7l1.667-1.171a.874.874,0,0,1,1.351.225,2.936,2.936,0,0,0,2.253,1.014,2.662,2.662,0,0,0,1.96-.788,2.444,2.444,0,0,0,.766-1.78q0-2.321-4.844-5.227a11.277,11.277,0,0,1-2.5-1.87,5.147,5.147,0,0,1-1.374-3.626,6.318,6.318,0,0,1,1.96-4.618,6.241,6.241,0,0,1,4.55-1.96,6.842,6.842,0,0,1,4.6,1.8,1.449,1.449,0,0,1,.631.991,1.016,1.016,0,0,1-.383.721l-1.622,1.4a.963.963,0,0,1-.631.316,1.16,1.16,0,0,1-.766-.451,2.7,2.7,0,0,0-1.96-.856,2.465,2.465,0,0,0-1.825.676,2.169,2.169,0,0,0-.631,1.6,2.125,2.125,0,0,0,.451,1.285,11.38,11.38,0,0,0,2.59,1.937,16.259,16.259,0,0,1,4.078,3.267,7.108,7.108,0,0,1,1.6,4.6,6.231,6.231,0,0,1-1.825,4.6,6.612,6.612,0,0,1-4.843,1.915,6.911,6.911,0,0,1-4.889-1.892,2.114,2.114,0,0,1-.856-1.352A.932.932,0,0,1,447.589,179.7Z"
                    transform="translate(57.959 -107.634)"
                    fill="#707070"
                />
                <path
                    id="Path_669"
                    data-name="Path 669"
                    d="M473.021,173.375a8.208,8.208,0,0,0,2.658,4.55,8.024,8.024,0,0,0,5.316,1.8,12.432,12.432,0,0,0,6.172-1.487,1.119,1.119,0,0,1,.518-.18.8.8,0,0,1,.7.54l.766,1.555a1.28,1.28,0,0,1,.179.563q0,.4-.721.833a14.6,14.6,0,0,1-7.659,2.14,12.09,12.09,0,0,1-8.56-20.522,10.826,10.826,0,0,1,8-3.267,9.968,9.968,0,0,1,7.344,2.951,11.168,11.168,0,0,1,3.244,8.245v1.329a.814.814,0,0,1-.946.947ZM486.875,170a6.368,6.368,0,0,0-1.892-4.168,6.474,6.474,0,0,0-4.776-2,6.828,6.828,0,0,0-4.326,1.441A8.423,8.423,0,0,0,473.111,170Z"
                    transform="translate(51.158 -107.634)"
                    fill="#707070"
                />
                <path
                    id="Path_670"
                    data-name="Path 670"
                    d="M322.57,157.789h-3.623v-6.15a.814.814,0,0,0-.946-.946h-2.028a.814.814,0,0,0-.946.946v6.15H311.36a.814.814,0,0,0-.946.946V160.4a.813.813,0,0,0,.946.946h3.622c0,.026-.008.044-.008.071v14.688a4.492,4.492,0,0,0,1.224,3.325,4.543,4.543,0,0,0,3.34,1.224H321.1q.877.015.849-.835v-1.8q.029-.864-.849-.849h-.5a1.464,1.464,0,0,1-1.627-1.685V161.418c0-.027-.008-.045-.008-.071h3.6a.814.814,0,0,0,.946-.946v-1.668A.815.815,0,0,0,322.57,157.789Z"
                    transform="translate(99.959 -104.799)"
                    fill="#707070"
                />
            </g>
        </svg>
