import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import LogoComponent from '../LogoComponent'
import './ReportErrorModal.css'
import { useForm, Controller, useWatch } from 'react-hook-form'
import ToolsIcon from '../icons/ToolsIcon'
import MagGlass from '../icons/MagGlass'
import { AccountContext } from '../../../context/Accounts'
import QaIcon from '../icons/QaIcon'
import FeedbackIcon from '../icons/FeedbackIcon'

const ReportErrorModal = ({ title, content, isOpen, setIsOpen }) => {
  const { loggedIn, setUserLanguage, currentUser, logout } = useContext(
    AccountContext
  )
  const { t, i18n } = useTranslation()

  const handleClick = () => setIsOpen(isOpen ? false : true)

  const [showEmail, setShowEmail] = useState(false)

  const [reportOrComment, setReportOrComment] = useState(null)

  const [showSourcesError, setShowSourcesError] = useState(false)

  const [form, setForm] = useState({})

  const { setToast } = useContext(AccountContext)

  const file1 = useRef()
  const file2 = useRef()
  const file3 = useRef()

  const {
    handleSubmit,
    control,
    reset,
    register,
    formState,
    setValue,
    getValues
  } = useForm({
    defaultValues: {}
  })

  const errors = formState.errors

  const handleClose = () => {
    setIsOpen(false)
    reset()
    setReportOrComment(null)
    setShowEmail(false)
    setShowSourcesError(false)
  }

  const noSources = useWatch({ control, name: 'noSources' })
  const file1w = useWatch({ control, name: 'file1' })
  const file2w = useWatch({ control, name: 'file2' })
  const file3w = useWatch({ control, name: 'file3' })

  useEffect(() => {
    if (getValues('noSources') || file1w || file2w || file3w) {
      setShowSourcesError(false)
    }
  }, [noSources, file1w, file2w, file3w])

  const onSubmit = data => {
    const formData = new FormData()

    Object.keys(data).forEach(
      k => k.indexOf('file') === -1 && formData.append(k, data[k])
    )

    if (
      !data.file1[0] &&
      !data.file2[0] &&
      !data.file3[0] &&
      !getValues('noSources')
    ) {
      setShowSourcesError(true)

      return
    } else {
      setShowSourcesError(false)
    }

    formData.append('entry', JSON.stringify(isOpen))

    formData.append('file1', data.file1[0])
    formData.append('file2', data.file2[0])
    formData.append('file3', data.file3[0])

    formData.append('report_comment', reportOrComment)
    /*-formData.append("file2", file2.current.files[0]);
    formData.append("file3", file3.current.files[0]);*/

    axios({
      method: 'post',
      url: process.env.REACT_APP_BACKEND_URL + '/report/report',
      data: formData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        setReportOrComment('thankyou')
        //handleClose()
      })
      .catch(e => {
        console.error('Network error fetchin latest news', e)
      })
  }

  return (
    <Modal
      size="sm"
      className="menu__modal fullSize report__modal"
      show={isOpen}
      onHide={handleClose}
    >
      {' '}
      <Modal.Header closeButton>
        <LogoComponent />
      </Modal.Header>
      <Modal.Body>
        {!reportOrComment ? (
          <div className="selectReport ">
            <div className="content__container">
              <a
                onClick={e => setReportOrComment('comment')}
                className="areaLinkBox"
              >
                <div className="areaLink__header">
                  <h6>{t('Comment')}</h6>
                  <p className="areaLink__description">
                    {t('Is there something we could add?')}
                  </p>
                </div>
                <div className="areaLink__body">
                  <div className="iconContainer">
                    <QaIcon size={72} color="#528598" />
                  </div>
                </div>
              </a>
              <a
                onClick={e => setReportOrComment('report')}
                className="areaLinkBox"
              >
                <div className="areaLink__header">
                  <h6>{t('Report Error')}</h6>
                  <p className="areaLink__description">
                    {t('Did we get something wrong?')}
                  </p>
                </div>
                <div className="areaLink__body">
                  <div className="iconContainer">
                    <FeedbackIcon size={72} color="#528598" />
                  </div>
                </div>
              </a>

              {/*  Disabled for now
        <Link to='/briefing-not-generator' className="areaLinkBox">
          <div className="areaLink__header">
            <h6>Briefing Note  <br/>Generator</h6>
            <p className="areaLink__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id
              lacinia turpis. Sed sit amet auctor diam. Class aptent taciti sociosqu ad litora torquent per.</p>
          </div>
          <div className="areaLink__body">
            <div className="iconContainer">
              <BriefcaseIcon size={72} color='rgb(201, 216, 217)' />
            </div>
          </div>
        </Link>
        */}
            </div>
          </div>
        ) : reportOrComment === 'thankyou' ? (
          <div className="selectReport ">
            <div className="content__container">
              <h5 className="thankyou">
                {t("Thank you for your feedback. We'll look into it shortly")}
              </h5>
            </div>
          </div>
        ) : (
          <div className="loginForm__container reportForm__container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="rf__header">
                <p className="lf__title">
                  {reportOrComment === 'report'
                    ? t('Report error')
                    : t('Comment')}
                </p>
              </div>
              <div className="lfInput__container">
                <label htmlFor="comments">
                  {reportOrComment === 'report'
                    ? t(
                        'I believe this entry has an error that needs to be corrected. Here’s why'
                      )
                    : t(
                        'I believe this entry could be expanded or improved. Here’s how:'
                      )}
                </label>
                <span className="formError">
                  {errors.comments && t('This is a required field ')}
                </span>
                <Controller
                  name="comments"
                  control={control}
                  render={({ field }) => <textarea {...field} />}
                  rules={{ required: true }}
                />
              </div>
              <div className="lfInput__container">
                <label htmlFor="userPassword">
                  {t(
                    'Please upload the sources that support your submission (.pdf or .doc/docx only)'
                  )}
                  :
                </label>
                <div className="sourceInput">
                  <label htmlFor="file-1">{t('Source')} 1:</label>
                  <input
                    name="file-1"
                    id="file-1"
                    ref={file1}
                    type="file"
                    accept=".doc,.docx,.pdf"
                    {...register('file1')}
                  />
                </div>
                <div className="sourceInput">
                  <label htmlFor="file-2">{t('Source')} 2:</label>
                  <input
                    name="file-2"
                    id="file-2"
                    type="file"
                    ref={file2}
                    accept=".doc,.docx,.pdf"
                    {...register('file2')}
                  />
                </div>
                <div className="sourceInput">
                  <label htmlFor="file-3">{t('Source')} 3:</label>
                  <input
                    name="file-3"
                    id="file-3"
                    type="file"
                    ref={file3}
                    accept=".doc,.docx,.pdf"
                    {...register('file3')}
                  />
                </div>
              </div>
              <div className="dd-wrapper">
                <div className="dd-list-item">
                  <button type="button">
                    <div
                      className="check-container"
                      onClick={e => {
                        console.log(getValues('available'))
                        setValue('noSources', !getValues('noSources'))
                      }}
                    >
                      <input
                        name="rememberUser"
                        type="checkbox"
                        {...register('noSources')}
                      />

                      <label htmlFor="rememberUser">
                        {t(
                          'I don’t have sources to support my submission at this time'
                        )}
                        .
                      </label>
                    </div>
                  </button>
                </div>
                <div className="dd-list-item">
                  <button type="button">
                    <div
                      className="check-container"
                      onClick={e => {
                        setShowEmail(!getValues('available') && !loggedIn)
                        setValue('available', !getValues('available'))
                      }}
                    >
                      <input
                        name="rememberUser"
                        type="checkbox"
                        {...register('available')}
                      />

                      <label htmlFor="rememberUser">
                        {t(
                          'I am available to correspond with ILGA World’s Research Team and further discuss this entry'
                        )}
                        .
                      </label>
                    </div>
                  </button>
                </div>
                {showEmail && (
                  <div className="lfInput__container">
                    <br />
                    <label htmlFor="userEmail">{t('Your email')}</label>
                    <input
                      name="userEmail"
                      type="email"
                      placeholder="e.g.name@mail.com"
                      {...register('email')}
                      required
                      key="email"

                    />
                  </div>
                )}
                {showSourcesError && (
                  <span className="formError">{t('no_source_form')}</span>
                )}
              </div>

              <div className="lf__actions">
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  {t('Submit')}
                </Button>
              </div>
            </form>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ReportErrorModal
