import { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'

import { useTranslation } from 'react-i18next'
import { cGradientsAreasProtection as defaultCGradientsAreasProtection } from '../../config/constants'
import { introText, methodologyText } from '../../data/Area15'
import useGqlEntriesProtection from '../../gql/useGqlEntriesProtection'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import MainLayout from '../../layouts/MainLayout'
import { calculateProtectionValue } from '../../lib/fnAreasPRHelper'
import { getColorPercentageStep } from '../../lib/fnHelper'
import '../../pages/Area.css'
import BarChart from '../components/Areas/A1-PR/BarChart'
import LineChart from '../components/Areas/A1-PR/LineChart'
import PieChart from '../components/Areas/A1-PR/PieChart'
import LatestUpdates from '../components/Areas/LatestUpdates'
import MapContainer from '../components/Map/MapContainer'
import microstates from '../components/Map/microstates.json'
import Methodology from '../components/Methodology/Methodology'
import MapTooltip from '../components/StyledMapToolTips.js'
import TableContainer from '../components/Table/TableContainer'
import TalliesSection from '../components/TalliesSection/TalliesSection'
import MainSpinner from '../components/ui/MainSpinner'
import { citeA15, columns } from '../config/a15columns'
import useGqlTextComponents from '../../gql/useGqlTextComponents'
import IntroSection from '../components/Areas/IntroSection'
import AreaContent from '../components/Areas/AreaContent'
export function useData({
  categories,
  showTooltip = true,
  cGradientsAreasProtection = defaultCGradientsAreasProtection
}) {
  const [references, setReferences] = useState([])
  const [tableEntries, setTableEntries] = useState([])
  const [talliesNumbers, setTalliesNumbers] = useState([])

  const { entriesProtection, loading, error } = useGqlEntriesProtection('A1-10')
  const {
    jurisdictions,
    loading: loadingJur,
    error: errorJur,
    jurisdictionsById
  } = useGqlJurisdictions()
  const { t, i18n } = useTranslation()

  const areaInfo = {
    title: `${t('Area')} 1`,
    subTitle: t('Legal Frameworks'),
    subSubTitle: `${t('Protection against discrimination in employment')}`,
    areaName: 'area-1'
  }

  useEffect(() => {
    if (!loading && !error && jurisdictions && entriesProtection) {
      setReferences(
        entriesProtection.map(e => {
          const val = calculateProtectionValue(e, entriesProtection, categories)

          return {
            id:
              e.motherEntry.subjurisdiction && e.motherEntry.subjurisdiction.id
                ? e.motherEntry.subjurisdiction.id
                : e.motherEntry.jurisdiction.a2_code,
            regions: e.motherEntry.jurisdiction.regions,
            microstate: microstates.find(
              ms => ms.A2 === e.motherEntry.jurisdiction.a2_code
            ),
            reference: getColorPercentageStep(
              categories.length
                ? [cGradientsAreasProtection[0]].concat(
                    cGradientsAreasProtection.slice(-categories.length)
                  )
                : cGradientsAreasProtection,
              val,
              'A1-PR',
              cGradientsAreasProtection
            ),
            tooltip:
              showTooltip &&
              ReactDOMServer.renderToStaticMarkup(
                <MapTooltip
                  entry={e}
                  title={areaInfo.subSubTitle}
                  area={'A1-5'}
                  extraProps={{ categories }}
                  hasSubjur={entriesProtection.find(
                    e2 =>
                      e2.motherEntry.jurisdiction.id ===
                        e.motherEntry.jurisdiction.id &&
                      e2.motherEntry.subjurisdiction
                  )}
                />
              ), // tooltip(e),
            entry: e
          }
        })
      )

      const tmpTallyNumbers = [0, 0, 0, 0]

      for (const entry of entriesProtection) {
        if (
          !entry.motherEntry.subjurisdiction &&
          entry.motherEntry.jurisdiction.un_member
        ) {
          if (entry.so_protection_type.id === '1') {
            tmpTallyNumbers[0]++
          }
          if (entry.gi_protection_type.id === '1') {
            tmpTallyNumbers[1]++
          }
          if (entry.ge_protection_type.id === '1') {
            tmpTallyNumbers[2]++
          }
          if (entry.sc_protection_type.id === '1') {
            tmpTallyNumbers[3]++
          }
        }
      }
      setTableEntries(entriesProtection)
      setTalliesNumbers(tmpTallyNumbers)
    }
  }, [jurisdictions, entriesProtection, error, loading, categories])

  return {
    entriesProtection: {
      data: entriesProtection,
      loading,
      error
    },
    jurisdictions: {
      data: jurisdictions,
      loading: loadingJur,
      error: errorJur,
      jurisdictionsById
    },
    references,
    setReferences,
    tableEntries,
    setTableEntries,
    talliesNumbers,
    setTalliesNumbers,
    areaInfo
  }
}

export function Map({
  references,
  htmlReferences,
  jurisdictionsById,
  jurisdictions,
  areaInfo,
  mapLoaded,
  setMapLoaded,
  setCategories,
  categories,
  showTooltip = true,
  cGradientsAreasProtection = defaultCGradientsAreasProtection,
  include,
  ...props
}) {
  const { t, i18n } = useTranslation()

  return (
    <MapContainer
      references={references}
      htmlReferences={htmlReferences}
      jurisdictionsById={jurisdictionsById}
      jurisdictions={jurisdictions}
      mapLegendProtectionGradient={{
        colors: categories.length
          ? [cGradientsAreasProtection[0]].concat(
              cGradientsAreasProtection.slice(-categories.length)
            )
          : cGradientsAreasProtection,
        labels: categories.length
          ? Array.from(Array(categories.length + 1).keys())
          : [0, 1, 2, 3, 4],
        title: t('Number of protected categories')
      }}
      mapLoaded={mapLoaded}
      setMapLoaded={setMapLoaded}
      menuVariation={'categories'}
      extraProps={{ setCategories, categories }}
      currentArea={'A1-5'}
      showTooltip={showTooltip}
      title={areaInfo.title}
      subTitle={areaInfo.subTitle}
      subSubTitle={areaInfo.subSubTitle}
      areaName={areaInfo.areaName}
      include={include}
      {...props}
      
    />
  )
}

export default function Area110() {
  const htmlReferences = useRef()
  const [mapLoaded, setMapLoaded] = useState(false)
  const [categories, setCategories] = useState([])
  const {
    entriesProtection: { data: entriesProtection },
    jurisdictions: { data: jurisdictions, jurisdictionsById },
    references,
    tableEntries,
    talliesNumbers,
    setTableEntries,
    areaInfo
  } = useData({ categories })
  const { textComponents } = useGqlTextComponents('A1-10')
  const { t, i18n } = useTranslation()

  return (
    <MainLayout section="areas">
      <div className="area__page area1__page has__category-selector">
        <section className="selectRegion__section">
          <Map
            areaInfo={areaInfo}
            references={references}
            htmlReferences={htmlReferences}
            jurisdictionsById={jurisdictionsById}
            jurisdictions={jurisdictions}
            mapLoaded={mapLoaded}
            setMapLoaded={setMapLoaded}
            setCategories={setCategories}
            categories
            modalContent={textComponents.length && textComponents[0].map}
          />
        </section>
        <div className="content__container">
          <TalliesSection
            area={'A1-5'}
            canStart={mapLoaded}
            replaceNumbers={talliesNumbers}
            title={t('Protection against discrimination in employment')}
          />

          <AreaContent areaIntro={'A1-10'} areaLatest={'A1-10'} />

          <div className="mainCTA__2charts">


            <div className="mainCTA__section40 mainGraphic__section mgs__pieChart-container">
              <h5>{t('Protection against discrimination in employment')}</h5>
              <h4>{t('Regional breakdown (%)')}</h4>
              {references && references.length > 0 && jurisdictionsById ? (
                <PieChart
                  references={references.filter(
                    r => !r.entry.motherEntry.subjurisdiction
                  )}
                  jurisdictions={jurisdictionsById}
                />
              ) : (
                <>
                  <MainSpinner />
                  {t('Loading chart...')}
                </>
              )}
            </div>
            <div className="mainCTA__section60 mainGraphic__section">
              <h5>{t('Protection against discrimination in employment')}</h5>
              <h4>{t('Timeline')}</h4>
              <div style={{ position: 'relative', flexGrow: 1 }}>
                {references && references.length > 0 ? (
                  <LineChart
                    references={references}
                    series={['SO', 'GI', 'GE', 'SC']}
                  />
                ) : (
                  <MainSpinner text={t('Loading chart...')} />
                )}
              </div>
            </div>
          </div>
          <div className="mainCTA__charts">
            <section className="mainCTA__section">
              <div className="mainGraphic__section">
                <h5>{t('Protection against discrimination in employment')}</h5>
                <h4>{t('Regional breakdown (jurisdictions)')}</h4>
                {references && references.length > 0 ? (
                  <BarChart references={references} />
                ) : (
                  <>
                    <MainSpinner />
                    {t('Loading chart...')}
                  </>
                )}
              </div>
            </section>
          </div>
        </div>
        <section className="table__section">
          <div className="content__container">
            {entriesProtection && entriesProtection.length > 0 && (
              <TableContainer
                entries={tableEntries}
                setEntries={setTableEntries}
                t={t}
                columns={columns()}
                citeEntry={(entry, setToast) => citeA15(entry, setToast, 10)}
                emptyFieldsNoCite={[
                  'so_explan',
                  'gi_explan',
                  'ge_explan',
                  'sc_explan'
                ]}
                trMainClass={'a13Tr'}
                title={t('Area') + '1'}
                subtitle={
                  t('Legal Frameworks') +
                  ' | ' +
                  '<span style="font-weight:700">' +
                  t('Protection against discrimination in employment') +
                  '</span>'
                }
                customColumnHide={{
                  columns: [
                    'so_explan',
                    'gi_explan',
                    'ge_explan',
                    'sc_explan',
                    'so_legal_framework_decrim_mechanism.name',
                    'gi_legal_framework_decrim_mechanism.name',
                    'ge_legal_framework_decrim_mechanism.name',
                    'sc_legal_framework_decrim_mechanism.name'
                  ],
                  text: t('Display explanations')
                }}
                showSubjurSwitch={true}
                paginate={false}
                initialSortBy={[
                  {
                    id: 'motherEntry.jurisdiction.name',
                    desc: false
                  }
                ]}
                showUNSwitch
              />
            )}
          </div>
        </section>
      </div>
    </MainLayout>
  )
}
