import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "../../../hooks/clickOutside";
import imageIcon from "../../../images/imageIcon.png";
import pdfIcon from "../../../images/pdfIcon.png";
import SettingsIcon from "../icons/SettingsIcon";
import "./MapChartSettings.css";

const MapChartSettings = React.forwardRef(
  (
    {
      showLabels,
      highQuality,
      hideMicrostates,
      pacificCenter,
      setShowLabels,
      setHighQuality,
      setHideMicrostates,
      setPacificCenter,
      menuOpen,
      setMenuOpen,
      regional = false,
      onExportPNG,
      drilledDown,
      onExportSVG
    },
    chart
  ) => {
    const refMCS = useRef();

    const { t, i18n } = useTranslation();

    const [isPoppedUp, setIsPoppedUp] = useState(false);

    const hideDropdown = () => {
      setMenuOpen(false);
      setIsPoppedUp(false);
    };

    useEffect(() => {
      setIsPoppedUp(menuOpen);
    }, [menuOpen]);

    useEffect(() => {
      setMenuOpen(isPoppedUp);
    }, [isPoppedUp]);

    useOnClickOutside(refMCS, hideDropdown);

    const handleClick = () => setIsPoppedUp(!isPoppedUp);

    return (
      <div className="MapChartSettings__container" ref={refMCS}>
        <div className={"MapChartSettings__menu " + (isPoppedUp && "showMapSettings")}>
          <div className="mcs__header">
            <div className="mcs__header-left">
              {drilledDown ? <p>{t("SUBJUR Names")}</p> : <p>{t("Country Names")}</p>}

              <div className="mcs__toggle">
                <div className="buttonCountryNames b2">
                  <input
                    type="checkbox"
                    className="checkbox"
                    onChange={e =>
                      setTimeout(() => {
                        setShowLabels(e.target.checked);
                      }, 250)
                    }
                    defaultChecked={showLabels}
                  />
                  <div className={`knobs knobs-${i18n.language}`}>
                    <span>{t("Hide")}</span>
                  </div>
                  <div className="layer" />
                </div>
              </div>
            </div>
            <div className="mcs__header-right">
              <p>{t("Quality")}</p>
              <div className="mcs__toggle">
                <div className="buttonQuality disabled b2">
                  <input
                    type="checkbox"
                    className="checkbox"
                    disabled="disabled"
                    onChange={e =>
                      setTimeout(() => {
                        setHighQuality(e.target.checked);
                      }, 250)
                    }
                    defaultChecked={highQuality}
                  />
                  <div className={"knobs  knobs-" + i18n.language + " disabled"}>
                    <span>{t("SQ")}</span>
                  </div>
                  <div className="layer" />
                </div>
              </div>
            </div>
          </div>
          <div className="mcs__body">
            <div className="mcs__item">
              <div className="mcs__itemTitle">{t("Microstates")}</div>
              <div className="mcs__toggle">
                <div className={"buttonMicrostates b2 " + (drilledDown ? " disabled" : "")}>
                  <input
                    type="checkbox"
                    className="checkbox"
                    disabled={drilledDown}
                    onChange={e =>
                      setTimeout(() => {
                        setHideMicrostates(e.target.checked);
                      }, 250)
                    }
                    defaultChecked={hideMicrostates}
                  />
                  <div className={"knobs knobs-" + i18n.language + (drilledDown ? " disabled" : "")}>
                    <span>{t("Enlarged")}</span>
                  </div>
                  <div className="layer" />
                </div>
              </div>
            </div>
            <div className="mcs__item">
              <div className="mcs__itemTitle">{t("Center")}</div>
              <div className="mcs__toggle">
                <div className={"buttonCenterMap b2 " + (regional || drilledDown ? " disabled" : "")}>
                  <input
                    type="checkbox"
                    className="checkbox"
                    disabled={regional || drilledDown}
                    onChange={e =>
                      setTimeout(() => {
                        setPacificCenter(e.target.checked);
                      }, 250)
                    }
                    defaultChecked={pacificCenter}
                  />
                  <div className={"knobs knobs-" + i18n.language + (regional || drilledDown ? " disabled" : "")}>
                    <span>{t("Atlantic")}</span>
                  </div>
                  <div className="layer" />
                </div>
              </div>
            </div>
            <div className="mcs__item">
              <div className="mcs__itemTitle">Export</div>
              <div className="mcs__links">
                <div className="mcs__links-header">
                  <img src={pdfIcon} alt="PDF Icon" />
                  <span className="mcs__link" onClick={onExportSVG}>
                    {t("Vectorised for large print")}
                  </span>
                </div>
                <div className="mcs__links-body">
                  <img src={imageIcon} alt="Icon" />
                  <div className="mcs__imageLinks-container">
                    <span
                      className="mcs__link"
                      onClick={e => {
                        onExportPNG({ multiplier: 1 });
                      }}
                    >
                      {t("Small")} (1340 x 760)
                    </span>
                    <span
                      className="mcs__link"
                      onClick={e => {
                        onExportPNG({ multiplier: 4 });
                      }}
                    >
                      {t("Med")} (5360 x 3040)
                    </span>
                    <span
                      className="mcs__link"
                      onClick={e => {
                        onExportPNG({ multiplier: 6 });
                      }}
                    >
                      {t("Large")} (8040 x 4560)
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="arrow-down" />
        </div>
        <span onClick={handleClick} className="mapChartSettings__trigger" title={t("Map options")}>
          <SettingsIcon color="#6EA2B5" size={24} />
        </span>
      </div>
    );
  }
);

export default MapChartSettings;
