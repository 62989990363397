import React from "react";
import DropdownSearch from "../Dropdown/DropdownSearch";

export default function ColumnFilter({ column }) {
  const { filterValue, setFilter } = column;

  return (
    <div>
      <DropdownSearch
        title={column.Header}
        items={[]}
        search={true}
        loading={false}
        error={false}
        setTableFilter={setFilter}
        justSearch={true}
      />
    </div>
  );
}

export function ApplyColumnFilter(rows, columnsId, filterValue) {
  if (filterValue && filterValue.length > 0) {
    console.log(columnsId);
    let allFiltered = rows.filter((row) => {
      //concatenate with "" for integers to work
      return (row.values[columnsId[0]] + "").toLowerCase().indexOf(filterValue) !== -1;
    });
    let filterTemp = [...allFiltered];

    allFiltered = allFiltered.map((row) => ({
      ...row,
      ...(row.original.cid && //Become new parent
        !filterTemp.find((r) => r.id === row.original.cid.split(".")[0]) && {
          original: { ...row.original, subRowsLength: 0, cid: "" }
        }),
      ...(!row.original.cid && {
        //Find childs
        original: {
          ...row.original,
          subRowsLength: filterTemp.filter((r) => r.original.cid && r.original.cid.split(".")[0] === row.id).length
        }
      })
    }));

    let noChilds = allFiltered.filter((r) => !r.original.cid);

    for (const row of allFiltered.filter((r) => r.original.cid)) {
      noChilds.splice(noChilds.findIndex((r) => r.id === row.original.cid.split(".")[0]) + 1, 0, row);
    }
    console.log("Nooo", noChilds);
    return noChilds;
  } else {
    return rows;
  }
}
