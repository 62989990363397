import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { cMainRegions } from "../../../config/constants";
import { isRecommendation } from "../../../lib/fnAreasPRHelper";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import LoadingBar from "../../components/ui/LoadingBar";

export default function GaugeChartArea6({ references }) {
  const [unMember, setUnMember] = useState(true);
  const [topicsN, setTopicsN] = useState(10);
  const { t, i18n } = useTranslation();
  const loading = useRef();
  const loadChart = () => {
    if (loading.current) loading.current.style.display = "block";
    am4core.options.queue = true;

    /* Chart code */
    // Themes begin
    am4core.unuseAllThemes();
    
    // Themes end

    // Create chart instance
    let chart = am4core.create("gaugeChartdiv", am4charts.GaugeChart);

    chart.events.on("ready", () => {
      if (loading.current) loading.current.style.display = "none";
    });

    let chartMin = -100;
    let chartMax = 100;

    let data = {
      score: 0,
      gradingData: [
        {
          title: "",
          color: "#ee1f25",
          lowScore: -100,
          highScore: -66
        },
        {
          title: "",
          color: "#f04922",
          lowScore: -66,
          highScore: -33
        },
        {
          title: "",
          color: "#fdae19",
          lowScore: -33,
          highScore: 0
        },

        {
          title: "",
          color: "#b0d136",
          lowScore: 0,
          highScore: 33
        },
        {
          title: "",
          color: "#54b947",
          lowScore: 33,
          highScore: 66
        },
        {
          title: "",
          color: "#0f9747",
          lowScore: 66,
          highScore: 100
        }
      ]
    };

    /**
Grading Lookup
 */
    function lookUpGrade(lookupScore, grades) {
      // Only change code below this line
      for (var i = 0; i < grades.length; i++) {
        if (grades[i].lowScore < lookupScore && grades[i].highScore >= lookupScore) {
          return grades[i];
        }
      }
      return null;
    }

    // create chart

    chart.hiddenState.properties.opacity = 0;
    chart.fontSize = 11;
    chart.innerRadius = am4core.percent(80);
    chart.resizable = true;

    /**
     * Normal axis
     */

    let axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = chartMin;
    axis.max = chartMax;
    axis.strictMinMax = true;
    axis.renderer.radius = am4core.percent(80);
    axis.renderer.inside = true;
    axis.renderer.line.strokeOpacity = 0.1;
    axis.renderer.ticks.template.disabled = false;
    axis.renderer.ticks.template.strokeOpacity = 1;
    axis.renderer.ticks.template.strokeWidth = 0.5;
    axis.renderer.ticks.template.length = 5;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.labels.template.radius = am4core.percent(15);
    axis.renderer.labels.template.fontSize = "0.9em";

    axis.maxPrecision = 0;

    /**
     * Axis for ranges
     */

    let axis2 = chart.xAxes.push(new am4charts.ValueAxis());
    axis2.min = chartMin;
    axis2.max = chartMax;
    axis2.strictMinMax = true;
    axis2.renderer.labels.template.disabled = true;
    axis2.renderer.ticks.template.disabled = true;
    axis2.renderer.grid.template.disabled = false;
    axis2.renderer.grid.template.opacity = 0.5;
    axis2.renderer.labels.template.bent = true;
    axis2.renderer.labels.template.fill = am4core.color("#000");
    axis2.renderer.labels.template.fontWeight = "bold";
    axis2.renderer.labels.template.fillOpacity = 0.3;

    /**
Ranges
*/

    for (let grading of data.gradingData) {
      let range = axis2.axisRanges.create();
      range.axisFill.fill = am4core.color(grading.color);
      range.axisFill.fillOpacity = 0.8;
      range.axisFill.zIndex = -1;
      range.value = grading.lowScore > chartMin ? grading.lowScore : chartMin;
      range.endValue = grading.highScore < chartMax ? grading.highScore : chartMax;
      range.grid.strokeOpacity = 0;
      range.stroke = am4core.color(grading.color).lighten(-0.1);
      range.label.inside = true;
      range.label.text = grading.title.toUpperCase();
      range.label.inside = true;
      range.label.location = 0.5;
      range.label.inside = true;
      range.label.radius = am4core.percent(10);
      range.label.paddingBottom = -5; // ~half font size
      range.label.fontSize = "0.9em";
    }

    let matchingGrade = lookUpGrade(data.score, data.gradingData);

    /**
     * Label 1
     */

    let label = chart.radarContainer.createChild(am4core.Label);
    label.isMeasured = false;
    label.fontSize = "4em";
    label.x = am4core.percent(50);
    label.paddingBottom = 25;
    label.horizontalCenter = "middle";
    label.verticalCenter = "bottom";
    //label.dataItem = data;
    label.text = parseInt(data.score);
    //label.text = "{score}";
    label.fill = am4core.color(matchingGrade.color);

    /**
     * Label 2
     */

    let label2 = chart.radarContainer.createChild(am4core.Label);
    label2.isMeasured = false;
    label2.fontSize = "2em";
    label2.horizontalCenter = "middle";
    label2.verticalCenter = "bottom";
    label2.text = matchingGrade.title.toUpperCase();
    label2.fill = am4core.color(matchingGrade.color);

    /**
     * Hand
     */

    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.axis = axis2;
    hand.innerRadius = am4core.percent(55);
    hand.startWidth = 8;
    hand.pin.disabled = true;
    hand.value = data.score;
    hand.fill = am4core.color("#444");
    hand.stroke = am4core.color("#000");

    hand.events.on("positionchanged", function() {
      label.text = Math.round(axis2.positionToValue(hand.currentPosition));
      let value2 = axis.positionToValue(hand.currentPosition);
      let matchingGrade = lookUpGrade(axis.positionToValue(hand.currentPosition), data.gradingData);
      if (matchingGrade) {
        label2.text = matchingGrade ? matchingGrade.title.toUpperCase() : "";
        label2.fill = am4core.color(matchingGrade.color);
        label2.stroke = am4core.color(matchingGrade.color);
        label.fill = am4core.color(matchingGrade.color);
      }
    });

    setTimeout(function() {
      //let value = chartMin + Math.random() * (chartMax - chartMin);
      let value =
        ((references.filter(r => r.voting_vote_type && (r.voting_vote_type.id === "1" || r.voting_vote_type.id === "3"))
          .length -
          references.filter(r => r.voting_vote_type && (r.voting_vote_type.id === "2" || r.voting_vote_type.id === "4"))
            .length) /
          references.filter(r => r.voting_vote_type).length) *
        100;
      hand.showValue(value, 100, am4core.ease.cubicOut);
    }, 2000);
  };
  useEffect(() => {
    loadChart();
  }, [unMember]);
  return (
    <>
      <div className='chartContainer'
        style={{
          minHeight: "280px",
          maxHeight: "280px",
          marginBottom: "10px",
          marginTop: "10px"
        }}
      >
        <LoadingBar ref={loading} text={t("Loading chart") + "..."} />
        <div id="gaugeChartdiv" style={{ minHeight: "280px", width: "600px" }} />
      </div>
    </>
  );
}
