import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'
import DOMPurify from 'dompurify'
import './UPRModal.css'
import InfoIcon from '../icons/InfoIcon'
import IlgaLogo from '../ui/IlgaLogo'
import DatabaseLogo from '../ui/DatabaseLogo'
import useGqlTextComponents from '../../../gql/useGqlTextComponents'
import TextComponentsEditor from '../../../components/TextComponentsEditor'
import IconEdit from '../../../components/icons/IconEdit'

const UPRModal = React.forwardRef(
  ({ title, content, extraClass, area }, ref) => {
    const { textComponents, updateTextComponent } = useGqlTextComponents(area)
    const [editButtons, setEditButtons] = useState(false)
    const [editIntro, setEditIntro] = useState(false)

    const [t, i18n] = useTranslation()

    const saveTextComponent = (area, type, text) => {
      updateTextComponent({
        variables: {
          lang: i18n.language,
          section: area,
          texts: {
            [type]: text
          }
        }
      })
        .then(text => {
          setEditIntro(false)
        })
        .catch(e => console.log('EEE', e))
    }

    const [isOpen, setIsOpen] = useState(false)

    const handleClick = () => setIsOpen(!isOpen)
    const handleClose = () => setIsOpen(false)

    return (
      <div className={'UPRInfoBtn__container ' + extraClass}>
        <span
          onClick={handleClick}
          className="UPRInfoBtn__trigger"
          title={t('Chart info')}
        >
          <InfoIcon color="#A4CAD1" size={28} />
        </span>

        <Modal
          size="lg"
          className="UPRInfoModal"
          show={isOpen}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <div className="modal__container">
              <div className="comboLogoContainer">
                <IlgaLogo /> <DatabaseLogo />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal__container">
              <div className="UPRInfoActions__container">
                <InfoIcon size={24} />
                <h4>
                  <strong>{t('UPR - Cycle Documents')}</strong>{' '}
                </h4>
              </div>

              <div className="UPRInfoModal__content">
                {' '}
                <p className="cycleDocsParagraph">
                  {t('cycle_docs_title')}:
                  <ol>
                    <li>{t('cycle_docs_i1')},</li>
                    <li>{t('cycle_docs_i2')}</li>
                    <li>{t('cycle_docs_i3')}.</li>
                  </ol>
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
)

export default UPRModal
