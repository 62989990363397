import React, { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'
import { cGradientsArea3, cTreatyBodies } from '../../config/constants'
import useGqlEntriesTreatyBodies from '../../gql/useGqlEntriesTreatyBodies'
import useGqlEntriesTreatyBodiesComm from '../../gql/useGqlEntriesTreatyBodiesComm'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import MainLayout from '../../layouts/MainLayout'
import { isRecommendation } from '../../lib/fnAreasPRHelper'
import MapTooltip from '../../mocks/components/StyledMapToolTips'

import '../../pages/Area.css'

import AreaContent from '../components/Areas/AreaContent'
import BarChart from '../components/Areas/A3/BarChart'
import LineChart from '../components/Areas/A3/LineChart'
import MapContainer from '../components/Map/MapContainer'
import microstates from '../components/Map/microstates.json'
import TableContainer from '../components/Table/TableContainer'
import MainSpinner from '../components/ui/MainSpinner'
import { citeA3, columns } from '../config/a3columns'
import { citeA3Comm, columnsComm } from '../config/a3columnsComm'
import useGqlTextComponents from '../../gql/useGqlTextComponents'

export default function Area3() {
  const htmlReferences = useRef()
  const tableContainerRef = useRef()
  const [references, setReferences] = useState([])
  const [mapBodies, setMapBodies] = useState([])
  const [mapLegendStepGradient, setMapLegendStepGradient] = useState(null)
  const [loadingMap, setLoadingMap] = useState(false)
  const { textComponents } = useGqlTextComponents('A3')
  const [manualFilter, setManualFilter] = useState([])

  const { entriesTreatyBodies, loading, error } = useGqlEntriesTreatyBodies()
  const {
    entriesTreatyBodiesComm,
    loading: loadingComm,
    error: errorComm
  } = useGqlEntriesTreatyBodiesComm()
  const {
    jurisdictions,
    loading: loadingJur,
    error: errorJur,
    jurisdictionsById
  } = useGqlJurisdictions()
  const { t, i18n } = useTranslation()

  const tooltip = e => {
    return ReactDOMServer.renderToStaticMarkup()
  }

  useEffect(() => {
    if (manualFilter.length) tableContainerRef.current.scrollIntoView()
  }, [manualFilter])

  useEffect(() => {
    if (
      !loading &&
      !error &&
      jurisdictions &&
      entriesTreatyBodies &&
      !loadingMap
    ) {
      setLoadingMap(true)
      let referencesTmp = []
      let maxRecommendations = 0
      const n_by_type_template = {}
      for (let tb of cTreatyBodies()) {
        n_by_type_template[tb.id] = 0
      }
      for (let j of jurisdictions) {
        let jurEntries = []
        jurEntries = entriesTreatyBodies.filter(
          e =>
            e.motherEntry.jurisdiction.id === j.id &&
            isRecommendation(e) &&
            (!mapBodies.length || mapBodies.indexOf(e.treaty_body.id) !== -1)
        )

        if (maxRecommendations < jurEntries.length)
          maxRecommendations = jurEntries.length

        let n_by_type = {}

        for (let jurEntry of jurEntries) {
          if (!n_by_type[jurEntry.treaty_body.id])
            n_by_type[jurEntry.treaty_body.id] = 1
          else n_by_type[jurEntry.treaty_body.id]++
        }

        if (jurEntries.length)
          referencesTmp.push({
            id: j.a2_code,
            regions: j.regions,
            entry: {
              motherEntry: {
                jurisdiction: j
              },
              n_recommendations: 0
            },

            microstate: microstates.find(ms => ms.A2 === j.a2_code),
            n_recommendations: jurEntries.length,
            reference: '#000',
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <MapTooltip
                entry={{
                  motherEntry: {
                    jurisdiction: j
                  },
                  n_recommendations: jurEntries.length,
                  n_by_type
                }}
                area={'A3'}
                extraProps={{
                  treatyBodies: cTreatyBodies(),
                  mapBodies
                }}
              />
            )
          })
        else
          referencesTmp.push({
            id: j.a2_code,
            regions: j.regions,
            entry: {
              motherEntry: {
                jurisdiction: j
              },
              n_recommendations: 0
            },
            microstate: microstates.find(ms => ms.A2 === j.a2_code),
            n_recommendations: jurEntries.length,
            reference: '#000',
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <MapTooltip
                entry={{
                  motherEntry: {
                    jurisdiction: j
                  },
                  n_recommendations: 0,
                  n_by_type
                }}
                area={'A3'}
                extraProps={{
                  treatyBodies: cTreatyBodies(),
                  mapBodies
                }}
              />
            )
          })
      }

      referencesTmp = referencesTmp.map(r => ({
        ...r,
        reference:
          r.n_recommendations === 0
            ? cGradientsArea3[0]
            : cGradientsArea3.slice(1)[
                Math.round(
                  (r.n_recommendations / maxRecommendations) *
                    (cGradientsArea3.length - 2)
                )
              ]
      }))

      setReferences(referencesTmp)
      let labels = []
      labels[0] = 0
      labels[cGradientsArea3.length - 1] = maxRecommendations
      setMapLegendStepGradient({
        colors: cGradientsArea3,
        labels,
        title: t('Number of Recommendations (since 2016)')
      })
      setLoadingMap(false)
    }
  }, [jurisdictions, entriesTreatyBodies, error, loading, mapBodies])

  const areaInfo = {
    title: `${t('AREA')} 3`,
    subTitle: `${t('Human Rights Treaty Bodies')}`,
    areaName: 'area-3',
    subSubTitle: t('All Treaty Bodies')
  }

  return (
    <MainLayout section="areas">
      <div className="area__page area3__page">
        <section className="selectRegion__section">
          <MapContainer
            references={references}
            htmlReferences={htmlReferences}
            jurisdictionsById={jurisdictionsById}
            jurisdictions={jurisdictions}
            mapLegendStepGradient={mapLegendStepGradient}
            menuVariation="treatyBodies"
            area="A3"
            currentArea="A3"
            extraProps={{ setMapBodies, mapBodies }}
            mapBodies={mapBodies}
            modalTitle={t('All Treaty Bodies')}
            modalContent={textComponents.length && textComponents[0].map}
            title={areaInfo.title}
            subTitle={areaInfo.subTitle}
            subSubTitle={areaInfo.subSubTitle}
            areaName={areaInfo.areaName}
          />
        </section>
        <div className="content__container">
          <AreaContent areaIntro={'A3'} areaLatest={'A3'} />

          <div className="mainCTA__2charts">
            <section className="mainCTA__section50">
              <div className="mainGraphic__section">
                <h5>{t('Total Recommendations by Body')}</h5>
                <h4>{t('SOGIESC Recommendations since 2016')}</h4>
                <div style={{ position: 'relative', flexGrow: 1 }}>
                  {entriesTreatyBodies && entriesTreatyBodies.length > 0 ? (
                    <BarChart references={entriesTreatyBodies} />
                  ) : (
                    <MainSpinner text={t('Loading chart...')} />
                  )}
                </div>
              </div>
            </section>

            <section className="mainCTA__section50">
              <div className="mainGraphic__section">
                <h5>{t('Total Recommendations by Year')}</h5>
                <h4>{t('SOGIESC Recommendations by body since 2016')}</h4>
                <div style={{ position: 'relative', flexGrow: 1 }}>
                  {entriesTreatyBodies && entriesTreatyBodies.length > 0 ? (
                    <LineChart
                      references={entriesTreatyBodies}
                      treatyBodies={cTreatyBodies()}
                    />
                  ) : (
                    <MainSpinner text={t('Loading chart...')} />
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>

        <section className="table__section">
          <div className="content__container" ref={tableContainerRef}>
            <>
              <TableContainer
                entries={entriesTreatyBodies}
                t={t}
                columns={columns()}
                citeEntry={citeA3}
                expandable={false}
                paginate={true}
                showSubjurSwitch={false}
                title={t('Area') + '3'}
                subtitle={
                  t('Human Rights Treaty Bodies') +
                  ' | ' +
                  '<span style="font-weight:600">' +
                  t('All Treaty Bodies') +
                  '</span>'
                }
                subtitle2={t('All documents (since 2016)')}
                subtitle3={t(
                  'For Individual Communications please see separate chart below.'
                )}
                virtual={true}
                manualFilter={manualFilter}
                initialSortBy={[
                  {
                    id: 'motherEntry.jurisdiction.name',
                    desc: false
                  }
                ]}
              />
            </>
          </div>

          <div className="content__container" ref={tableContainerRef}>
            <>
              <TableContainer
                entries={entriesTreatyBodiesComm}
                t={t}
                columns={columnsComm()}
                citeEntry={citeA3Comm}
                expandable={false}
                paginate={false}
                showSubjurSwitch={false}
                hideIconHeader={true}
                subtitle2={t('Individual Communications (since 2016)')}
                virtual={true}
                manualFilter={manualFilter}
                initialSortBy={[
                  {
                    id: 'motherEntry.jurisdiction.name',
                    desc: false
                  }
                ]}
              />
            </>
          </div>
        </section>
      </div>
    </MainLayout>
  )
}
