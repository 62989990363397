import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cRecommendationResponses } from '../../../config/constants'
import '../../components/Charts/Charts.css'
import LoadingBar from '../../components/ui/LoadingBar'

export default function PieChartJurProfileArea5({
  entriesRecommendations,
  entriesRecommendationsIssued,
  isLoading
}) {
  const [unMember, setUnMember] = useState(true)

  const { t, i18n } = useTranslation()
  const loading = useRef()
  const loadChart = () => {
    if (loading.current) loading.current.style.display = 'block'
    am4core.options.queue = false

    /* Chart code */
    // Themes begin
    am4core.unuseAllThemes()
    

    // Themes end

    // Create chart instance
    let chart = am4core.create('pieChartDiv', am4charts.PieChart)

    // Themes begin

    chart.events.on('ready', () => {
      if (loading.current) loading.current.style.display = 'none'
    })
    // Themes end

    // Create chart instance

    // Let's cut a hole in our Pie chart the size of 40% the radius
    chart.innerRadius = am4core.percent(40)

    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries())
    pieSeries.dataFields.value = 'value'
    pieSeries.dataFields.category = 'category'
    pieSeries.slices.template.stroke = am4core.color('#fff')
    pieSeries.innerRadius = 10
    pieSeries.slices.template.strokeWidth = 0

    pieSeries.slices.template.showOnInit = true
    pieSeries.slices.template.hiddenState.properties.shiftRadius = 1

    pieSeries.slices.template.propertyFields.disabled = 'labelDisabled'
    pieSeries.labels.template.propertyFields.disabled = 'textDisabled'
    pieSeries.ticks.template.disabled = true

    pieSeries.alignLabels = false
    //pieSeries.labels.template.maxWidth = 50;
    pieSeries.labels.template.wrap = true
    //pieSeries.labels.template.dy=150;
    //pieSeries.labels.template.fontSize = 8;

    // Add data

    let total = entriesRecommendations.length

    let responses = cRecommendationResponses()

    for (let response of responses) {
      let val = entriesRecommendations.filter(
        r => r.recommendation_response.id == response.id
      ).length
      if (val)
        pieSeries.data.push({
          category: response.name,
          value: val,
          total: total,
          ...(response.id === '2' && { fill: '#d8a585' }),
          ...(response.id === '1' && { fill: '#91b6c4' }),
          ...(response.id === '3' && { fill: '#efd79c' }),
          percentage: Math.round((val / total) * 100)
        })
    }

    //pieSeries.radius = 0.01;
    // Disable sliding out of slices
    pieSeries.ticks.template.disabled = true
    pieSeries.alignLabels = false
    pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%"
    pieSeries.labels.template.radius = am4core.percent(-20)
    pieSeries.labels.template.fill = am4core.color('black')
    pieSeries.slices.template.propertyFields.fill = 'fill'
    pieSeries.hiddenState.properties.endAngle = -90

    pieSeries.labels.template.text = '{category}\n{percentage}%'
    pieSeries.labels.template.textAlign = 'middle'
    pieSeries.slices.template.tooltipText =
      '{category}: {percentage}% ({val} ' + t('out of') + ' {total})'
    // Add second series
  }
  useEffect(() => {
    console.log('LOADING PIE!')
    !isLoading && loadChart()
  }, [unMember])
  return (
    <div
      className="chartContainer"
      style={{
        minHeight: '400px',
        maxHeight: '400px',
        marginBottom: '10px',
        marginTop: '10px'
      }}
    >
      {isLoading ? (
        <LoadingBar ref={loading} text={t('Loading chart') + '...'} />
      ) : (
        <>
          <LoadingBar ref={loading} text={t('Loading chart') + '...'} />
          <div
            id="pieChartDiv"
            style={{ minHeight: '400px', minWidth: '400px' }}
          />{' '}
        </>
      )}
    </div>
  )
}
