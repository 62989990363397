import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GET_A1_14_CT = gql(`
  query GetEntriesCt( $lang: String, $jur_id: String) {
    entriesCt(lang: $lang, jur_id: $jur_id) {
      id
    motherEntry {
        entry_type_id
        topics {
          id
          name
        }
        jurisdiction {
          id
          name
          un_member
          a2_code
          regions{
            id
          }
        }
        subjurisdiction {
          id
          name
          slug
          label_longitude
          label_latitude
          label_font_size
        }
        footnotes {
          id
          order
          section
          content
        }
        sources {
          id
          order
          section
          original_official_filename
          original_filename
          translated_filename
          enacted
          original_official_language{
            name
            id
          }
          original_official_language2{
            name
            id
          }
          original_language{
            name
            id
          }
          original_language2{
            name
            id
          }
        }
      }
      general_ban_type {
        id
        name
      }
      mechanism {
        id
        name
      }
      critical_date_1
      critical_date_2
      explan
      banned_practice
      banned_practitioners
      ads_ban_type {
        id
        name
      }
      explan_ads
      ref_ban_type {
        name
        id
      }
      explan_ref
      so_value {
        id
        name
      }
      gi_value {
        name
        id
      }
      minors_value {
        name
        id
      }
      vulnerable_adults_value {
        name
        id
      }
      all_adults_value {
        name
        id
      }
    }
}
`);

function useGqlEntriesCt(jur_id = null) {
  const { i18n } = useTranslation();
  const entriesCt = useQuery(GET_A1_14_CT, {
    variables: { jur_id, lang: i18n.language }
  });

  return {
    entriesCt: entriesCt.data && entriesCt.data.entriesCt
  };
}

export default useGqlEntriesCt;
