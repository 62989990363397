import dayjs from 'dayjs'
import i18n from '../config/i18n'

export function occurrences(string, subString, allowOverlapping) {
  string += ''
  subString += ''
  if (subString.length <= 0) return string.length + 1

  var n = 0,
    pos = 0,
    step = allowOverlapping ? 1 : subString.length

  while (true) {
    pos = string.indexOf(subString, pos)
    if (pos >= 0) {
      ++n
      pos += step
    } else break
  }
  return n
}

export function pickTextColorBasedOnBgColorSimple(
  bgColor,
  lightColor,
  darkColor
) {
  var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
  var r = parseInt(color.substring(0, 2), 16) // hexToR
  var g = parseInt(color.substring(2, 4), 16) // hexToG
  var b = parseInt(color.substring(4, 6), 16) // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor
}

export const getDescendantProp = (obj, path) =>
  path.split('.').reduce((acc, part) => acc && acc[part], obj)

export function getColorPercentage(colorA, colorB, ratio) {
  let color2 = colorA.charAt(0) === '#' ? colorA.substring(1, 7) : colorA
  let color1 = colorB.charAt(0) === '#' ? colorB.substring(1, 7) : colorB

  var hex = function(x) {
    x = x.toString(16)
    return x.length === 1 ? '0' + x : x
  }

  var r = Math.ceil(
    parseInt(color1.substring(0, 2), 16) * ratio +
      parseInt(color2.substring(0, 2), 16) * (1 - ratio)
  )
  var g = Math.ceil(
    parseInt(color1.substring(2, 4), 16) * ratio +
      parseInt(color2.substring(2, 4), 16) * (1 - ratio)
  )
  var b = Math.ceil(
    parseInt(color1.substring(4, 6), 16) * ratio +
      parseInt(color2.substring(4, 6), 16) * (1 - ratio)
  )

  return '#' + hex(r) + '' + hex(g) + '' + hex(b)
}

export function getColorPercentageStep(
  colors,
  ratio,
  area = null,
  originalColors
) {
  let amount = colors.length
  let step = Math.floor(ratio * amount)

  if (area === 'A1-PR' && colors.length === 2 && ratio > 0)
    colors[0] = originalColors[2]
  else if (area === 'A1-PR' && colors.length < 5 && ratio > 0)
    colors[0] = originalColors[5 - colors.length]
  if (step === amount) return colors[step - 1]
  else if (!colors[step + 1]) return colors[step]
  else return getColorPercentage(colors[step], colors[step + 1], ratio)
}

export function uniqueByKey(arr, key) {
  return arr.filter(
    (thing, index, self) => index === self.findIndex(t => t[key] === thing[key])
  )
}

// eslint-disable-next-line no-useless-escape
const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi

export function linkify(text) {
  return text.match(urlRegex)
}

export function explanSourceLinks(text, entry, section = 0) {
  let index = 0
  if (!text) return ''
  let sources = section
    ? entry.motherEntry.sources.filter(s => s.section === section)
    : entry.motherEntry.sources
  return text
    .replace(/\n/gi, '<br />')
    .replace(/\*([^*]+)\*/gi, (match, $1) => {
      let source = sources[index++]
      if (source) {
        source =
          source.original_official_filename ||
          source.original_filename ||
          source.translated_filename
      }
      if (source)
        return `<a
                href="${process.env.REACT_APP_BACKEND_URL +
                  '/downloader/download/1/' +
                  source}"
              >${$1}</a>`
      else return $1
    })
}

export function getDateMysqlFormat(date) {
  return (
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2)
  )
}

export function getNumberOfDays(start, end) {
  const date1 = new Date(start)
  const date2 = new Date(end)

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime()

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay)

  return diffInDays
}

export function getNumberOfMonths(d1, d2) {
  let date1 = dayjs(d1)
  let date2 = dayjs(d2)
  let years = date2.diff(date1, 'year', true)
  let months = date2.diff(date1, 'month', true)
  let days = Math.round((months % 1) * 30)
  if (years) months = Math.round((years % 1) * 12)
  return { years: Math.floor(years), months: Math.floor(months), days }
  /*var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months - 1;*/
}

export const isFiltered = filters => {
  return (
    Object.keys(filters).length > 0 &&
    ((filters.topics && filters.topics.length > 0) ||
      (filters.boolean && filters.boolean.trim()) ||
      filters.areas_ids ||
      (filters.jurs_ids && filters.jurs_ids.length > 0) ||
      filters.date_from ||
      filters.date_to)
  )
}

export const daysMonthsYears = date => {
  let difDays = getNumberOfDays(getDateMysqlFormat(new Date()), date)
  if (difDays <= 31)
    return difDays + ' ' + (difDays === 1 ? i18n.t('day') : i18n.t('days'))
  else if (difDays <= 365) {
    difDays = getNumberOfMonths(getDateMysqlFormat(new Date()), date)
    return (
      difDays.months +
      ' ' +
      (difDays.months === 1 ? i18n.t('month') : i18n.t('months')) +
      (difDays.days
        ? ' ' +
          i18n.t('and') +
          ' ' +
          difDays.days +
          ' ' +
          (difDays.days === 1 ? i18n.t('day') : i18n.t('days'))
        : '')
    )
  } else {
    difDays = getNumberOfMonths(getDateMysqlFormat(new Date()), date)
    return (
      difDays.years +
      ' ' +
      (difDays.years === 1 ? i18n.t('year') : i18n.t('years')) +
      (difDays.months
        ? (difDays.days ? ',' : ' ' + i18n.t('and')) +
          ' ' +
          difDays.months +
          ' ' +
          (difDays.months === 1 ? i18n.t('month') : i18n.t('months'))
        : '') +
      (difDays.days
        ? ' ' +
          i18n.t('and') +
          ' ' +
          difDays.days +
          ' ' +
          (difDays.days === 1 ? i18n.t('day') : i18n.t('days'))
        : '')
    )
  }
}
