import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { cSpecialProceduresCuTypes } from '../../config/constants'
import useGqlEntriesRecommendations from '../../gql/useGqlEntriesRecommendations'
import useGqlEntriesSpecialProcedures from '../../gql/useGqlEntriesSpecialProcedures'
import { isRecommendationSP } from '../../lib/fnAreasPRHelper'
import Table from '../components/Table/Table'
import { citeA4, columns } from '../config/a4columns'
import BarChartJurProfileArea4 from './charts/BarChartJurProfileArea4'
import PieChartJurProfileArea4 from './charts/PieChartJurProfileArea4'

export default function JurProfileArea4({ jur, hide = false }) {
  const {
    entriesSpecialProcedures,
    specialProceduresTypes,
    loadingSpTypes,
    loadingSp
  } = useGqlEntriesSpecialProcedures(jur.id)

  const [recOnly, setRecOnly] = useState(true)
  const [spCounts, setSPCounts] = useState(null)
  const [spmCounts, setSPMCounts] = useState(null)
  const [tableTab, setTableTab] = useState(1)
  const [t] = useTranslation()

  useEffect(() => {
    if (entriesSpecialProcedures && entriesSpecialProcedures.length) {
      var counts = entriesSpecialProcedures
        .filter(c => isRecommendationSP(c))
        .reduce((p, c) => {
          for (let t of c.special_procedure_types) {
            var name = t.name
            if (!p.hasOwnProperty(name)) {
              p[name] = 0
            }
            p[name]++
          }
          return p
        }, {})

      var countsExtended = Object.keys(counts).map(k => {
        return { name: k, count: counts[k] }
      })

      setSPCounts(countsExtended)

      var countsM = entriesSpecialProcedures.reduce((p, c) => {
        for (let t of c.special_procedure_types) {
          var name = t.name
          if (!p.hasOwnProperty(name)) {
            p[name] = 0
          }
          p[name]++
        }
        return p
      }, {})

      var countsMExtended = Object.keys(countsM).map(k => {
        return { name: k, count: countsM[k] }
      })

      setSPMCounts(countsMExtended)
    }
  }, [entriesSpecialProcedures])
  if (hide) return null
  return (
    <>
      <div className="content__container jurProfile__area3">
        <div className="tb__recsContainer tb__recsContainer4">
          <h4 className="tb__recs-title">
            {t(
              'Total recommendations received by {{jur}} from Special Procedures',
              { jur: jur.name }
            ) +
              ' ' +
              t('since 2016')}
          </h4>
          <div className="tb__recTabsContainer">
            <Tabs defaultActiveKey="recOnly">
              <Tab eventKey="recOnly" title={t('Recommendations only')}>
                {spCounts !== null &&
                  spCounts
                    .sort((a, b) => {
                      if (a.count > b.count) return -1
                      else if (a.count < b.count) return 1
                      else return 0
                    })
                    .map((t, index) => (
                      <div className="recRow">
                        <p className="recBox__title">{t.name}</p>
                        <span>{t.count}</span>
                      </div>
                    ))}
                {(spCounts === null || spCounts.length === 0) && (
                  <div className="recRow noRecs">
                    {t(
                      '{{jur}} has not received recommendations on sexual, gender or bodily diversity from UN Special Procedures since 2016',
                      {
                        jur: jur.name
                      }
                    )}
                  </div>
                )}
              </Tab>
              <Tab
                eventKey="recAndMentions"
                title={t('Recommendations and mentions')}
              >
                {spmCounts !== null &&
                  spmCounts
                    .sort((a, b) => {
                      if (a.count > b.count) return -1
                      else if (a.count < b.count) return 1
                      else return 0
                    })
                    .map((t, index) => (
                      <div className="recRow">
                        <p className="recBox__title">{t.name}</p>
                        <span>{t.count}</span>
                      </div>
                    ))}
                {spmCounts !== null && spmCounts.length === 0 && (
                  <div className="recRow noRecs">
                    {t(
                      '{{jur}} has not received recommendations or mentions on sexual, gender or bodily diversity from UN Special Procedures since 2016',
                      {
                        jur: jur.name
                      }
                    )}
                  </div>
                )}
              </Tab>
            </Tabs>
          </div>
        </div>

        <div className="tb__pieChart-container tb__pieChart-container4">
          <h4 className="tb__pieChart-title tb__pieChart-title2">
            {t('References to {{jur}} by Special Procedures', {
              jur: jur.name
            }) +
              ' ' +
              t('since 2016')}
          </h4>
          <div className="tb__pieChart-subtitle">
            {t('Recommendations and mentions on {{jur}} since 2016', {
              jur: jur.name
            })}
          </div>
          <div className="tb__pieChart-actualContainer">
            {entriesSpecialProcedures && entriesSpecialProcedures.length > 0 ? (
              <PieChartJurProfileArea4 references={entriesSpecialProcedures} />
            ) : (
              <div className="noRecs">
                <br />
                <br />
                <br />
                {t(
                  '{{jur}} has not received recommendations or mentions on sexual, gender or bodily diversity from UN Special Procedures since 2016',
                  {
                    jur: jur.name
                  }
                )}
              </div>
            )}
          </div>
        </div>

        <div className="tb__barChart-container">
          <h4 className="tb__barChart-title">
            {t('Recommendations received by {{jur}} by Special Procedure', {
              jur: jur.name
            }) +
              ' ' +
              t('since 2016')}
          </h4>
          <span className="tb__barChart-subtitle">({t('since 2016')})</span>
          <div className="tb__barChart-actualContainer">
            {entriesSpecialProcedures &&
            entriesSpecialProcedures.filter(c => isRecommendationSP(c)).length >
              0 ? (
              <BarChartJurProfileArea4 references={entriesSpecialProcedures} />
            ) : entriesSpecialProcedures ? (
              <div className="noRecs">
                <br />
                <br />
                {t(
                  '{{jur}} has not received recommendations or mentions on sexual, gender or bodily diversity from UN Special Procedures since 2016',
                  {
                    jur: jur.name
                  }
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <section className="section1-jur1 jurProfile__area3">
        <div className="content__container">
          <h4 className="jpjs__area">{t('AREA')} 4</h4>
          <div className="jpjs__titleWithBtn">
            <h2 className="jpjs__title">{t('Special Procedures')}</h2>
            {false && (
              <button className="jps__button btn btn-link">
                <img
                  className="jpsb__countryFlag"
                  src={
                    '/images/flags_svg/' + jur.a2_code.toLowerCase() + '.svg'
                  }
                  alt="Country Flag"
                />
                {jur.name}
              </button>
            )}
          </div>
          <div className="jpjs__tableTabBtns">
            <button
              className={'jpjs__tableTabBtn ' + (tableTab === 1 && 'active')}
              onClick={() => setTableTab(1)}
            >
              {t('Country Visits')}
            </button>
            <button
              className={
                'jpjs__tableTabBtn ' +
                (entriesSpecialProcedures &&
                entriesSpecialProcedures.filter(
                  sp =>
                    sp.special_procedure_cu_type.id == 7 ||
                    sp.special_procedure_cu_type.id == 8
                ).length === 0
                  ? 'disabled'
                  : tableTab === 2 && 'active')
              }
              onClick={() =>
                entriesSpecialProcedures &&
                entriesSpecialProcedures.filter(
                  sp =>
                    sp.special_procedure_cu_type.id == 7 ||
                    sp.special_procedure_cu_type.id == 8
                ).length > 0 &&
                setTableTab(2)
              }
            >
              {t('Individual communications')}
            </button>
            <button
              className={
                'jpjs__tableTabBtn ' +
                (entriesSpecialProcedures &&
                entriesSpecialProcedures.filter(
                  sp =>
                    sp.special_procedure_cu_type.id != 7 &&
                    sp.special_procedure_cu_type.id != 8 &&
                    sp.special_procedure_cu_type.id != 5 &&
                    sp.special_procedure_cu_type.id != 6
                ).length === 0
                  ? 'disabled'
                  : tableTab === 3
                  ? 'active'
                  : '')
              }
              onClick={() =>
                entriesSpecialProcedures &&
                entriesSpecialProcedures.filter(
                  sp =>
                    sp.special_procedure_cu_type.id != 7 &&
                    sp.special_procedure_cu_type.id != 8 &&
                    sp.special_procedure_cu_type.id != 5 &&
                    sp.special_procedure_cu_type.id != 6
                ).length > 0 &&
                setTableTab(3)
              }
            >
              {t('Other references')}
            </button>
          </div>
          {tableTab === 1 && (
            <div className="area2Table__section table__section">
              <h4 className="a3ts__tableTitle">
                {t('Country Visits') + ' ' + t('since 2016')}
              </h4>
              <div className="content__container">
                <div className="a2ts__body table-responsive">
                  {entriesSpecialProcedures &&
                  specialProceduresTypes &&
                  specialProceduresTypes.length > 0 &&
                  entriesSpecialProcedures.filter(
                    sp =>
                      sp.special_procedure_cu_type.id == 5 ||
                      sp.special_procedure_cu_type.id == 6
                  ).length ? (
                    <Table
                      columns={
                        specialProceduresTypes &&
                        columns(
                          specialProceduresTypes.filter(sp => sp.amount_all),
                          cSpecialProceduresCuTypes().filter(
                            c => c.id === 5 || c.id === 6
                          )
                        )
                          .filter(
                            c => c.accessor !== 'motherEntry.jurisdiction.name'
                          )
                          .map(c =>
                            c.isHidden ? { ...c, isHidden: false } : c
                          )
                          .map(c =>
                            c.NonExpandableCell
                              ? { ...c, Cell: c.NonExpandableCell }
                              : c
                          )
                      }
                      entries={entriesSpecialProcedures.filter(
                        sp =>
                          sp.special_procedure_cu_type.id == 5 ||
                          sp.special_procedure_cu_type.id == 6
                      )}
                      emptyHeaderBg={'#ecefec'}
                      showFilters={true}
                      fixedHeight={false}
                      citeEntry={citeA4}
                      disableHover={true}
                      showSubjurSwitch={false}
                      disableFirstCol={true}
                      tableContainerExtraClass={'jurTableContainer'}
                      trMainClass={''}
                      t={t}
                    />
                  ) : (
                    <div className="noRecs">
                      {t(
                        'No country visit by UN Special Procedures to {{jur}} contains mentions or recommendations on sexual, gender or bodily diversity',
                        {
                          jur: jur.name
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {tableTab === 2 && (
            <div className="area2Table__section table__section">
              <h4 className="a3ts__tableTitle">
                {t('Individual communications') + ' ' + t('since 2016')}
              </h4>
              <div className="content__container">
                <div className="a2ts__body table-responsive">
                  {entriesSpecialProcedures &&
                  specialProceduresTypes &&
                  specialProceduresTypes.length > 0 &&
                  entriesSpecialProcedures.filter(
                    sp =>
                      sp.special_procedure_cu_type.id == 7 ||
                      sp.special_procedure_cu_type.id == 8
                  ).length ? (
                    <Table
                      columns={columns(
                        specialProceduresTypes.filter(sp => sp.amount_all)
                      )
                        .filter(
                          c => c.accessor !== 'motherEntry.jurisdiction.name'
                        )
                        .map(c => (c.isHidden ? { ...c, isHidden: false } : c))
                        .map(c =>
                          c.NonExpandableCell
                            ? { ...c, Cell: c.NonExpandableCell }
                            : c
                        )}
                      disableHover={true}
                      entries={entriesSpecialProcedures.filter(
                        sp =>
                          sp.special_procedure_cu_type.id == 7 ||
                          sp.special_procedure_cu_type.id == 8
                      )}
                      emptyHeaderBg={'#ecefec'}
                      showFilters={true}
                      fixedHeight={false}
                      citeEntry={citeA4}
                      showSubjurSwitch={false}
                      disableFirstCol={true}
                      tableContainerExtraClass={'jurTableContainer'}
                      trMainClass={''}
                      t={t}
                    />
                  ) : (
                    <div>{t('No data')}</div>
                  )}
                </div>
              </div>
            </div>
          )}

          {tableTab === 3 && (
            <div className="area2Table__section table__section">
              <h4 className="a3ts__tableTitle">
                {t('Other references') + ' ' + t('since 2016')}
              </h4>
              <div className="content__container">
                <div className="a2ts__body table-responsive">
                  {entriesSpecialProcedures &&
                  specialProceduresTypes &&
                  specialProceduresTypes.length > 0 &&
                  entriesSpecialProcedures.filter(
                    sp =>
                      sp.special_procedure_cu_type.id == 1 ||
                      sp.special_procedure_cu_type.id == 2 ||
                      sp.special_procedure_cu_type.id == 3 ||
                      sp.special_procedure_cu_type.id == 4 ||
                      sp.special_procedure_cu_type.id == 9 ||
                      sp.special_procedure_cu_type.id == 10
                  ).length ? (
                    <Table
                      columns={columns(
                        specialProceduresTypes.filter(sp => sp.amount_all),
                        cSpecialProceduresCuTypes().filter(
                          c =>
                            c.id == 1 ||
                            c.id == 2 ||
                            c.id == 3 ||
                            c.id == 4 ||
                            c.id == 9 ||
                            c.id == 10
                        )
                      )
                        .filter(
                          c => c.accessor !== 'motherEntry.jurisdiction.name'
                        )
                        .map(c => (c.isHidden ? { ...c, isHidden: false } : c))
                        .map(c =>
                          c.NonExpandableCell
                            ? { ...c, Cell: c.NonExpandableCell }
                            : c
                        )}
                      entries={entriesSpecialProcedures.filter(
                        sp =>
                          sp.special_procedure_cu_type.id == 1 ||
                          sp.special_procedure_cu_type.id == 2 ||
                          sp.special_procedure_cu_type.id == 3 ||
                          sp.special_procedure_cu_type.id == 4 ||
                          sp.special_procedure_cu_type.id == 9 ||
                          sp.special_procedure_cu_type.id == 10
                      )}
                      emptyHeaderBg={'#ecefec'}
                      showFilters={true}
                      showSort={true}
                      showFooter={true}
                      disableHover={true}
                      citeEntry={citeA4}
                      disableFirstCol={true}
                      tableContainerExtraClass={'jurTableContainer'}
                      trMainClass={''}
                      t={t}
                      showSubjurSwitch={false}
                    />
                  ) : (
                    <div>{t('No data')}</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  )
}
