import React, { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'

import { useTranslation } from 'react-i18next'
import { cArea114MapReferences } from '../../config/constants'
import useGqlEntriesCt from '../../gql/useGqlEntriesCt'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import useGqlTextComponents from '../../gql/useGqlTextComponents'
import MainLayout from '../../layouts/MainLayout'
import '../../pages/Area.css'
import AreaContent from '../components/Areas/AreaContent'
import BarChart from '../components/Areas/A1-14/BarChart'
import LineChart2 from '../components/Areas/A1-14/LineChart2'
import PieChart from '../components/Areas/A1-14/PieChart'
import MapContainer from '../components/Map/MapContainer'
import microstates from '../components/Map/microstates.json'
import MapTooltip from '../components/StyledMapToolTips.js'
import TableContainer from '../components/Table/TableContainer'
import TalliesSection from '../components/TalliesSection/TalliesSection'
import MainSpinner from '../components/ui/MainSpinner'
import { citeA114, columns } from '../config/a114columns'
export default function Area114() {
  const htmlReferences = useRef()
  const [references, setReferences] = useState([])
  const [tableEntries, setTableEntries] = useState([])
  const [talliesNumbers, setTalliesNumbers] = useState([])
  const [mapLoaded, setMapLoaded] = useState(false)
  const { textComponents } = useGqlTextComponents('A1-14')
  const { entriesCt, loading, error } = useGqlEntriesCt()
  const {
    jurisdictions,
    loading: loadingJur,
    error: errorJur,
    jurisdictionsById
  } = useGqlJurisdictions()
  const { t, i18n } = useTranslation()

  const tooltip = e => {
    return ReactDOMServer.renderToStaticMarkup()
  }

  useEffect(() => {
    if (!loading && !error && jurisdictions && entriesCt) {
      let referencesTmp = []

      for (let j of jurisdictions) {
        let entry = entriesCt.find(e => e.motherEntry.jurisdiction.id === j.id)

        if (entry) referencesTmp.push(entry)
      }
      referencesTmp = entriesCt

      setReferences(
        referencesTmp.map(e => {
          return {
            id:
              e.motherEntry.subjurisdiction && e.motherEntry.subjurisdiction.id
                ? e.motherEntry.subjurisdiction.id
                : e.motherEntry.jurisdiction.a2_code,
            regions: e.motherEntry.jurisdiction.regions,
            critical_date_1: e.critical_date_1,
            microstate: microstates.find(
              ms => ms.A2 === e.motherEntry.jurisdiction.a2_code
            ),
            reference: cArea114MapReferences().find(
              ml => ml.id === parseInt(e.general_ban_type.id)
            ).color,
            reference_text: 'test',
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <MapTooltip
                entry={e}
                title={t('Regulation of so-called “conversion therapies”')}
                area={'A1-14'}
                extraProps={{
                  name: cArea114MapReferences().find(
                    ml => ml.id === parseInt(e.general_ban_type.id)
                  ).name,
                  bgColor: cArea114MapReferences().find(
                    ml => ml.id === parseInt(e.general_ban_type.id)
                  ).color
                }}
                positive={e.general_ban_type.id === '1'}
                hasSubjur={entriesCt.find(
                  e2 =>
                    e2.motherEntry.jurisdiction.id ===
                      e.motherEntry.jurisdiction.id &&
                    e2.motherEntry.subjurisdiction
                )}
              />
            ), //tooltip(e),
            entry: e
          }
        })
      )

      let tmpTableEntries = []
      let tmpTallyNumbers = [0, 0, 0]

      for (let entry of entriesCt) {
        let is_na = entry.general_ban_type.id === '6'

        if (
          !entry.motherEntry.subjurisdiction &&
          entry.motherEntry.jurisdiction.un_member
        ) {
          if (entry.general_ban_type.id === '1') {
            tmpTallyNumbers[0]++
          } else if (entry.general_ban_type.id === '5') {
            tmpTallyNumbers[1]++
          } else if (entry.general_ban_type.id === '3') {
            tmpTallyNumbers[2]++
          }
        }
        let subRows = []

        if (!is_na || !subRows.length) {
          tmpTableEntries.push({
            ...entry,
            subRowsLength: subRows.length
          })
          tmpTableEntries = tmpTableEntries.concat(subRows)
        } else {
          //Hack to prevent showing the N/A
          if (subRows.length) {
            subRows[0] = {
              ...subRows[0],
              cid: subRows[0].cid.split('.')[0],
              subRowsLength: subRows.length - 1
            }
            delete subRows[0].cid

            tmpTableEntries = tmpTableEntries.concat(subRows)
          }
        }
      }

      console.log(tmpTableEntries)

      setTableEntries(tmpTableEntries)
      setTalliesNumbers(tmpTallyNumbers)
    } else {
      console.log('BBBB', loading, !error, jurisdictions, entriesCt)
    }
  }, [jurisdictions, entriesCt, error, loading])

  const areaInfo = {
    title: `${t('Area')} 1`,
    subTitle: t('Legal Frameworks'),
    subSubTitle: `${t('Regulation of so-called “conversion therapies”')}`,
    areaName: 'area-1'
  }

  return (
    <MainLayout section="areas">
      <div className="area__page area1__page">
        <section className="selectRegion__section">
          <MapContainer
            references={references}
            htmlReferences={htmlReferences}
            jurisdictionsById={jurisdictionsById}
            jurisdictions={jurisdictions}
            mapLegendItems={cArea114MapReferences()}
            mapLoaded={mapLoaded}
            setMapLoaded={setMapLoaded}
            currentArea={'A1-14'}
            title={areaInfo.title}
            subTitle={areaInfo.subTitle}
            subSubTitle={areaInfo.subSubTitle}
            areaName={areaInfo.areaName}
            modalContent={textComponents.length && textComponents[0].map}
          />
        </section>
        <div className="content__container">
          <TalliesSection
            area={'A1-14'}
            canStart={mapLoaded}
            replaceNumbers={talliesNumbers}
          />

          <AreaContent areaIntro={'A1-14'} areaLatest={'A1-14'} />

          <div className="mainCTA__2charts">
            <section className="mainCTA__section40">
              <div className="mainGraphic__section mgs__pieChart-container">
                <h5>{t('Regulation of so-called “conversion therapies”')}</h5>
                <h4>{t('Regional breakdown (%)')}</h4>
                {references && references.length > 0 && jurisdictionsById ? (
                  <PieChart
                    references={references.filter(
                      r => !r.entry.motherEntry.subjurisdiction
                    )}
                    jurisdictions={jurisdictionsById}
                  />
                ) : (
                  <>
                    <MainSpinner text={t('Loading chart...')} />
                  </>
                )}
              </div>
            </section>
            <section className="mainCTA__section60">
              <div className="mainGraphic__section">
                <h5>{t('Regulation of so-called “conversion therapies”')}</h5>
                <h4>{t('Timeline')}</h4>

                {references && references.length > 0 ? (
                  <LineChart2 references={references} />
                ) : (
                  <MainSpinner text={t('Loading chart...')} />
                )}
              </div>
            </section>
          </div>
          <div className="mainCTA__charts">
            <section className="mainCTA__section">
              <div className="mainGraphic__section">
                <h5>{t('Regulation of so-called “conversion therapies”')}</h5>
                <h4>{t('Regional breakdown (jurisdictions)')}</h4>
                {references && references.length > 0 ? (
                  <BarChart references={references} />
                ) : (
                  <>
                    <MainSpinner text={t('Loading chart...')} />
                  </>
                )}
              </div>
            </section>
          </div>
        </div>
        <section className="table__section">
          <div className="content__container">
            {entriesCt && entriesCt.length > 0 && (
              <TableContainer
                entries={tableEntries}
                setEntries={setTableEntries}
                t={t}
                columns={columns()}
                citeEntry={citeA114}
                emptyFieldsNoCite={['explan']}
                trMainClass={'a13Tr'}
                title={t('Area') + '1'}
                subtitle={
                  t('Legal Frameworks') +
                  ' | ' +
                  '<span style="font-weight:700">' +
                  t('Regulation of so-called “conversion therapies”') +
                  '</span>'
                }
                paginate={false}
                initialSortBy={[
                  {
                    id: 'motherEntry.jurisdiction.name',
                    desc: false
                  }
                ]}
                showUNSwitch
              />
            )}
          </div>
        </section>
      </div>
    </MainLayout>
  )
}
