import React from 'react'
import { cLegalFrameworksDecrimMechanisms } from '../../config/constants'
import i18n from '../../config/i18n'
import routes from '../../config/routes'
import { citeEntry } from '../../lib/cite'
import { explanSourceLinks } from '../../lib/fnHelper'
import DateDropdownFilter, {
  ApplyDropdownDateFilter
} from '../components/Table/DateDropdownFilter'
import EnforcmentModal from '../components/Table/EnforcmentModal'
import ExpandableTextCell from '../components/Table/ExpandableTextCell'
import GenericDropdownFilter, {
  ApplyGenericDropdownFilter
} from '../components/Table/GenericDropdownFilter'
import HelpCell from '../components/Table/HelpCell'
import JurCell from '../components/Table/JurCell'
import JurFilter, { ApplyJurFilter } from '../components/Table/JurFilter'
import SourceText from '../components/Table/SourceText'

export const citeA11 = (entry, setToast) => {
  let index = 0
  citeEntry(
    routes().find(r => r.parent === 'area1' && r.subareaNumber === 1),
    entry.motherEntry.subjurisdiction
      ? entry.motherEntry.subjurisdiction.name +
          ' (' +
          entry.motherEntry.jurisdiction.name +
          ')'
      : entry.motherEntry.jurisdiction.name,
    `<strong>${
      entry.motherEntry.subjurisdiction
        ? entry.motherEntry.subjurisdiction.name +
          ' (' +
          entry.motherEntry.jurisdiction.name +
          ')'
        : entry.motherEntry.jurisdiction.name
    } (${
      entry.legal ? i18n.t('Legal') : i18n.t('Ilegal')
    }):</strong> ${explanSourceLinks(entry.explan, entry)}
`,
    setToast
  )
}

export const getColumnsA11 = () => [
  {
    Header: i18n.t('Jurisdiction'),
    disableGroupBy: true,
    accessor: 'motherEntry.jurisdiction.name',
    filter: ApplyJurFilter,
    Filter: ({ column }) => {
      return <JurFilter column={column} search={true} />
    },
    Cell: ({ value, row }) => {
      return <JurCell row={row} />
    },
    CellSmall: ({ value, row }) => {
      return (
        <div>{row.original.motherEntry.jurisdiction.name.substring(0, 3)}</div>
      )
    },
    style: { minWidth: '100px' }
  },
  {
    Header: i18n.t('Legality'),
    accessor: 'legal',
    filter: React.memo((rows, columnsId, filterValue) => {
      return ApplyGenericDropdownFilter(rows, 'legal', filterValue)
    }, []),
    Cell: ({ value, row }) => {
      return value ? (
        <span>{i18n.t('Legal')}</span>
      ) : (
        <span>{i18n.t('Ilegal')}</span>
      )
    },
    sortType: (rowA, rowB, id, desc) => {
      if (rowA.original[id] > rowB.original[id]) return -1
      if (rowB.original[id] > rowA.original[id]) return 1
      return 0
    },
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width={'150px'}
          column={column}
          options={[
            { id: true, value: i18n.t('Legal') },
            { id: false, value: i18n.t('Illegal') }
          ]}
        />
      )
    },
    className: 'textCenter col125',
    classNameFn: cell => {
      return cell.row.original.legal ? 'rowLegal' : 'rowIlegal'
    }
  },
  {
    Header: i18n.t('Last amendment'),
    sortDescFirst: true,
    accessor: 'last_amendment_1',
    Cell: ({ value, row }) => {
      if (value)
        return (
          value +
          (row.original.last_amendment_2
            ? ' - ' + row.original.last_amendment_2
            : '')
        )
      else return '-'
    },
    className: 'textCenter col125',
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(
        rows,
        ['last_amendment_1', 'last_amendment_2'],
        filterValue
      ),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    }
  },
  {
    Header: i18n.t('Decrim date'),
    accessor: 'decrim_date_1',
    sortDescFirst: true,
    Cell: ({ value, row }) => {
      if (value)
        return (
          value +
          (row.original.decrim_date_2 ? ' - ' + row.original.decrim_date_2 : '')
        )
      else return '-'
    },
    className: 'textCenter col125',
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(
        rows,
        ['decrim_date_1', 'decrim_date_2'],
        filterValue
      ),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    }
  },

  {
    Header: i18n.t('Mechanism'),
    sortDescFirst: true,
    accessor: 'legal_framework_decrim_mechanism.name',
    Cell: ({ value, row }) => {
      return row.original.legal_framework_decrim_mechanism.name
    },
    className: 'textCenter col125 flexTd',
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(
        rows,
        'legal_framework_decrim_mechanism.name',
        filterValue
      ),
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width={'200px'}
          column={column}
          options={cLegalFrameworksDecrimMechanisms().filter(
            t => t.id !== i18n.t('Human Rights Commission')
          )}
        />
      )
    }
  },

  {
    Header: i18n.t('Penalties'),
    columns: [
      {
        Header: i18n.t('Death Penalty'),
        accessor: 'entry_csssa_death_penalty_value.name',
        Cell: ({ value, row }) => {
          if (row.original.entry_csssa_death_penalty_value.id !== '2')
            return (
              <HelpCell
                mainText={row.original.entry_csssa_death_penalty_value.name}
                helpText={row.original.dp}
              />
            )
          else return row.original.entry_csssa_death_penalty_value.name
        },
        filter: (rows, columnsId, filterValue) => {
          return ApplyGenericDropdownFilter(
            rows,
            'entry_csssa_death_penalty_value.name',
            filterValue
          )
        },

        Filter: ({ column }) => {
          return (
            <GenericDropdownFilter
              column={column}
              width={'200px'}
              options={[
                {
                  id: i18n.t('No legal certainty'),
                  value: i18n.t('No legal certainty')
                },
                { id: i18n.t('Yes'), value: i18n.t('Yes') },
                { id: i18n.t('No'), value: i18n.t('No') }
              ]}
            />
          )
        },
        className: 'textCenter col125 flexTd'
      },

      {
        Header: i18n.t('Max Prison'),
        accessor: 'entry_csssa_max_prison_value.name',
        Cell: ({ value, row }) => {
          if (
            row.original.entry_csssa_max_prison_value.id !== '1' &&
            row.original.prison
          )
            return (
              <HelpCell
                mainText={
                  (row.original.entry_csssa_max_prison_value.id === '6'
                    ? row.original.max_prison_years + ' '
                    : '') + row.original.entry_csssa_max_prison_value.name
                }
                helpText={row.original.prison}
              />
            )
          else
            return (
              (row.original.entry_csssa_max_prison_value.id === '6'
                ? row.original.max_prison_years + ' '
                : '') + row.original.entry_csssa_max_prison_value.name
            )
        },
        style: { minWidth: '120px' },
        className: 'textCenter flexTd',
        filter: (rows, columnsId, filterValue) =>
          ApplyGenericDropdownFilter(
            rows,
            'entry_csssa_max_prison_value.name',
            filterValue
          ),
        Filter: ({ column }) => {
          return (
            <GenericDropdownFilter
              column={column}
              width={'150px'}
              options={[
                { id: i18n.t('No'), value: i18n.t('No') },
                { id: i18n.t('N/A'), value: i18n.t('N/A') },
                { id: i18n.t('Varies'), value: i18n.t('Varies') },
                { id: i18n.t('Unknown'), value: i18n.t('Unknown') },
                { id: i18n.t('Years'), value: i18n.t('Time in prison') },
                {
                  id: i18n.t('Life in prison'),
                  value: i18n.t('Life in prison')
                }
              ]}
            />
          )
        }
      },

      {
        Header: i18n.t('Fine'),
        accessor: 'has_fine',
        Cell: ({ value, row }) => {
          if (row.original.has_fine)
            if (row.original.fine)
              return (
                <HelpCell
                  mainText={i18n.t('Yes')}
                  helpText={row.original.fine}
                />
              )
            else return i18n.t('Yes')
          else return i18n.t('No')
        },
        style: { minWidth: '120px' },
        className: 'textCenter flexTd',
        sortType: (rowA, rowB, id, desc) => {
          if (rowA.original[id] > rowB.original[id]) return -1
          if (rowB.original[id] > rowA.original[id]) return 1
          return 0
        },
        filter: (rows, columnsId, filterValue) =>
          ApplyGenericDropdownFilter(rows, 'has_fine', filterValue),
        Filter: ({ column }) => {
          return (
            <GenericDropdownFilter
              width={'150px'}
              column={column}
              options={[
                { id: true, value: i18n.t('Yes') },
                { id: false, value: i18n.t('No') }
              ]}
            />
          )
        }
      },

      {
        Header: i18n.t('Other Punishment'),
        accessor: 'other',
        Cell: ({ value, row }) => {
          if (row.original.entry_csssa_other_punishment_value.id !== '1')
            return (
              <HelpCell
                mainText={row.original.entry_csssa_other_punishment_value.name}
                helpText={row.original.other}
              />
            )
          else return row.original.entry_csssa_other_punishment_value.name
        },
        style: { minWidth: '120px' },
        className: 'textCenter flexTd',
        filter: (rows, columnsId, filterValue) =>
          ApplyGenericDropdownFilter(
            rows,
            'entry_csssa_other_punishment_value.name',
            filterValue,
            true
          ),
        Filter: ({ column }) => {
          return (
            <GenericDropdownFilter
              column={column}
              width={'250px'}
              options={[
                { id: i18n.t('No'), value: i18n.t('No') },
                { id: i18n.t('Forced labour'), value: i18n.t('Forced labour') },
                {
                  id: i18n.t('Forced Psychiatric Treatment'),
                  value: i18n.t('Forced Psychiatric Treatment')
                },
                { id: i18n.t('Flogging'), value: i18n.t('Flogging') },
                { id: i18n.t('Other'), value: i18n.t('Other') }
              ]}
            />
          )
        }
      }
    ]
  },

  {
    Header: i18n.t('Explanation'),
    accessor: 'explan',

    Cell: ({ value, row }) => {
      return (
        <ExpandableTextCell
          value={value}
          row={row}
          sources={row.original.motherEntry.sources}
          footnotes={row.original.motherEntry.footnotes}
          unik={row.original.id + '_explan'}
        />
      )
    },
    NonExpandableCell: ({ value, row }) => {
      return (
        <SourceText
          text={value}
          skipSources={0}
          sources={row.original.motherEntry.sources}
          unik={row.original.id + '_explan'}
        />
      )
    },
    style: { minWidth: '200px', maxWidth: '200px', width: '200px' },
    disableSortBy: true,
    className: 'col550',
    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.explan.replace(/[^\w\s]/gi, '') >
        rowB.original.explan.replace(/[^\w\s]/gi, '')
      )
        return -1
      if (
        rowB.original.explan.replace(/[^\w\s]/gi, '') >
        rowA.original.explan.replace(/[^\w\s]/gi, '')
      )
        return 1
      return 0
    }
  },
  {
    Header: i18n.t('Enforcement'),
    accessor: 'enforcement',

    Cell: ({ value, row }) => {
      if (
        row.original.enforcement &&
        row.original.enforcement.replace(/-/gi, '').trim().length
      )
        return (
          <EnforcmentModal
            body={row.original.enforcement}
            entry_csssa_id={row.original.id}
            title={
              <div>
                <img
                  className="enforcmentFlag"
                  src={
                    '/images/flags_svg/' +
                    row.original.motherEntry.jurisdiction.a2_code.toLowerCase() +
                    '.svg'
                  }
                  height="20px"
                />

                {row.original.motherEntry.jurisdiction.name}
              </div>
            }
          />
        )
      else return ''
    },
    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.enforcement.replace(/[^\w\s]/gi, '') >
        rowB.original.enforcement.replace(/[^\w\s]/gi, '')
      )
        return -1
      if (
        rowB.original.enforcement.replace(/[^\w\s]/gi, '') >
        rowA.original.enforcement.replace(/[^\w\s]/gi, '')
      )
        return 1
      return 0
    },
    className: 'textCenter flexTd',
    style: { minWidth: '100px' },
    disableSortBy: true,
    disableFilter: true
  }
]
