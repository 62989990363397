import React, { useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import IconCancel from './icons/IconCancel'
import IconSave from './icons/IconSave'
import './TextComponentsEditor.css'

const TextComponentsEditor = ({
  initialText = '',
  variation,
  save,
  cancel
}) => {
  const [value, setValue] = useState(initialText)

  const textChange = text => {
    setValue(text)
  }

  const toolbarOptions =
    variation === 'methodology'
      ? [
          [{ header: [false, 2, 3, 6] }, 'bold', 'italic'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' }
          ],
          ['link']
        ]
      : variation === 'intro'
      ? [['bold', 'italic'], [{ list: 'bullet' }], ['link']]
      : [
          [{ header: [false,  6] },'bold', 'italic'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' }
          ],
          ['link']
        ]

  return (
    <div style={{ marginBottom: '70px' }}>
      {save && (
        <div style={{ float: 'right', marginTop: '10px', marginRight: '10px' }}>
          <span style={{ cursor: 'pointer' }} onClick={() => cancel()}>
            <IconCancel size={24} />
          </span>
          <span style={{ cursor: 'pointer' }} onClick={() => save(value)}>
            <IconSave />
          </span>
        </div>
      )}
      <ReactQuill
        theme="snow"
        modules={{ toolbar: toolbarOptions }}
        value={value}
        onChange={textChange}
      />
    </div>
  )
}

export default TextComponentsEditor
