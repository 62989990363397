import React from "react";
import "./Dropdown.css";
import { DebounceInput } from "react-debounce-input";
import CircleSpinner from "../ui/CircleSpinner";

const DropdownMenu = React.forwardRef(
  (
    {
      coords,
      listItems,
      isItemInSelection,
      setFilter,
      handleOnClick,
      t,
      filterLoading,
      sortSelected,
      search,
      closeDropdown,
      clearFilter,
      width = "100%"
    },
    inputRef
  ) => {
    return (
      <div className="ignore-this-class dd-wrapper ">
        {clearFilter && !filterLoading && (
          <button className="dd-clear-filter" onClick={clearFilter}>
            { t("Clear Filter") }
          </button>
        )}
        {filterLoading && (
          <button className="dd-clear-filter">
            <CircleSpinner />{" "}
          </button>
        )}
        {search && (
          <div className="dd-filter">
            <DebounceInput
              autoFocus
              minLength={2}
              debounceTimeout={300}
              onChange={e => {
                setFilter(e.target.value.toLowerCase());
              }}
              placeholder={t("Search") + "..."}
              ref={inputRef}
            />
          </div>
        )}
        <ul className="dd-list" id="dd-menu" style={{ width }}>
          {listItems
            .filter(item => !sortSelected || isItemInSelection(item))
            .map((item, index) => (
              <li className="dd-list-item WhatItem" key={item.id + index}>
                <button disabled={item.disabled} type="button" onClick={() => handleOnClick(item)}>
                  <span className={"check-container " + (item.disabled ? "check-container__disabled" : "")}>
                    <input
                      type="checkbox"
                      disabled={item.disabled}
                      onChange={e => handleOnClick(item)}
                      checked={isItemInSelection(item)}
                    />
                    <label htmlFor={item.value}>
                      {item.icon && item.icon} {item.value}
                    </label>
                  </span>
                </button>
              </li>
            ))}

          {listItems
            .filter(item => sortSelected && !isItemInSelection(item))
            .map(item => (
              <li className="dd-list-item" key={item.id}>
                <button type="button" onClick={() => handleOnClick(item)}>
                  <span>
                    <input
                      name={item.value}
                      type="checkbox"
                      onChange={e => handleOnClick(item)}
                      checked={isItemInSelection(item)}
                    />
                    <label htmlFor={item.value}>
                      {item.icon && item.icon} {item.value}
                    </label>
                  </span>
                </button>
              </li>
            ))}
        </ul>
      </div>
    );
  }
);

export default DropdownMenu;
