import { useRef, useState, useEffect } from 'react'

import { Accordion, Modal } from 'react-bootstrap'
import { DebounceInput } from 'react-debounce-input'
import { useTranslation } from 'react-i18next'
import useGqlJurisdictions from '../../../gql/useGqlJurisdictions'

import CustomCheckbox from '../../components/Checkbox'
import DropdownJurs from '../../components/Dropdown/DropdownJurs'
import './AddJurisdictionButton.css'

const AddJurisdicitonButton = ({ filters, setFilters }) => {
  const [showModal, setShowModal] = useState(false)
  const [active, setActive] = useState('jurisdictions')

  const { jurisdictions, loading, error, regions } = useGqlJurisdictions()

  const [listItems, setListItems] = useState([])

  useEffect(() => {
    if (jurisdictions && regions)
      setListItems({
        regions: regions.map(r => ({
          id: r.id,
          icon: (
            <img
              src={'/images/region_flags/' + r.a2_code + '.png'}
              height="12px"
              alt={r.name + ' flag'}
            />
          ),
          value: r.name
        })),
        jurisdictions: jurisdictions.map(j => ({
          id: j.id,
          icon: (
            <img
              className={'svgFlag'}
              src={'/images/flags_svg/' + j.a2_code.toLowerCase() + '.svg'}
              height="12px"
              alt={j.name + ' flag'}
            />
          ),
          value: j.name
        }))
      })
  }, [jurisdictions, regions])

  const { i18n, t } = useTranslation()

  const inputRef = useRef(null)

  const handleShow = () => {
    setShowModal(true)
  }
  const handleClose = () => {
    setShowModal(false)
  }

  const handleOnClick = () => {
    console.log('clicked')
  }

  return (
    <>
      {jurisdictions && regions && !loading && !error && (
        <DropdownJurs
          setTableFilter={jurs => {
            console.log(jurs)
            setFilters({
              ...filters,
              jurs_ids: [...new Set(jurs.filter(j => isNaN(j)))]
            })
          }}
          filterValue={filters ? filters.jurs_ids : []}
          sortSelected={true}
          title={t('Jurisdictions')}
          //Items grouped
          items={{
            regions: regions.map(r => ({
              id: r.id,
              icon: (
                <img
                  src={'/images/region_flags/' + r.a2_code + '.png'}
                  height="12px"
                  alt={r.name + ' flag'}
                />
              ),
              value: r.name
            })),
            jurisdictions: jurisdictions.map(j => ({
              id: j.id,
              icon: (
                <img
                  src={'/images/flags_svg/' + j.a2_code.toLowerCase() + '.svg'}
                  height="12px"
                  className="svgFlag"
                  alt={j.name + ' flag'}
                />
              ),
              value: j.name
            }))
          }}
          jurisdictions={jurisdictions}
          regions={regions}
          loading={loading}
          error={error}
          multiSelect
          search={true}
          clearFilter={true}
          buttonText={
            <>
              <button className="ast__addArea-btn" style={{ width: '100%' }}>
                <span>{t('Add Jurisdiction')}</span>
              </button>
            </>
          }
          customActive={true}
          compareTool
        />
      )}
      <Modal
        className="advancedSearch__page jurSelect__modal"
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header className="jurSelect__modal-header">
          <button className="dd-clear-filter">{t('Clear Filter')}</button>
          <DebounceInput
            className="dd-searchInput"
            autoFocus
            minLength={2}
            debounceTimeout={300}
            onChange={e => console.log('set filter')}
            placeholder={t('Search')}
            ref={inputRef}
          />
        </Modal.Header>
        <Modal.Body className="asai__modal-body">
          <div className="accordionsContainer">
            <Accordion
              flush={false}
              activeKey={active}
              defaultActiveKey="jurisdictions"
            >
              {Object.keys(listItems).map((group, i) => (
                <Accordion.Item eventKey={group} key={group}>
                  <Accordion.Header
                    onClick={() => {
                      if (active === group) setActive(null)
                      else setActive(group)
                    }}
                  >
                    <h5>
                      {group === 'regions' ? 'Groups and regions' : group}
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    {listItems[group].map(item => (
                      <div className="dd-list-item" key={item.id}>
                        <button
                          type="button"
                          onClick={e => {
                            handleOnClick(item)
                          }}
                        >
                          <CustomCheckbox
                            content={
                              <>
                                {item.icon &&
                                  group === 'jurisdictions' &&
                                  item.icon}
                                {item.value}
                              </>
                            }
                          />
                        </button>
                      </div>
                    ))}
                    {listItems[group].map(item => (
                      <div className="dd-list-item" key={item.id}>
                        <button
                          type="button"
                          onClick={e => {
                            handleOnClick(item)
                          }}
                        >
                          <CustomCheckbox
                            content={
                              <>
                                {item.icon &&
                                  group === 'jurisdictions' &&
                                  item.icon}
                                {item.value}
                              </>
                            }
                          />
                        </button>
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddJurisdicitonButton
