import React, { useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import OpenInNewIcon from './icons/OpenInNewIcon'
import DatabaseLogo from './ui/DatabaseLogo'
import IlgaLogo from './ui/IlgaLogo'
import './Methodology/Methodology.css'
import { useTranslation } from 'react-i18next'
export default function HomePhotoCredits({ showModal, setShowModal }) {
  const { t, i18n } = useTranslation()
  const modalRef = useRef()

  const handleShow = () => setShowModal(true)
  const handleClose = () => setShowModal(false)

  return (
    <Modal
      size="md"
      className="methodologyModal"
      show={showModal}
      onHide={handleClose}
      ref={modalRef}
      onMouseEnter={e => setEditButtons(true)}
      onMouseLeave={e => setEditButtons(false)}
    >
      <Modal.Header closeButton>
        <div className="modal__container">
          <div className="comboLogoContainer">
            <IlgaLogo /> <DatabaseLogo />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="photoCreditsActions__container">
          <h4>{t('Photo Credits')} </h4>
        </div>

        <div className="photoCredits__content" id="photoCredits__content">
          {i18n.language === 'en' ? (
            <>
              ILGA World is proud to have purchased the rights to disseminate
              photographs by queer photographers working with{' '}
              <a
                href="https://agenciapresentes.org/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Agencia Presentes
              </a>
              . Photos by Ariel Gutraich (Argentina), Josean Rivera (Chile),
              Diego Rosales (El Salvador), Milena Pafundi (Mexico), Melissa
              Merino y Gianna Camacho (Peru).
            </>
          ) : (
            <>
              ILGA Mundo se enorgullece de haber adquirido los derechos para
              diseminar fotografías de fotógrafes queer que trabajan con{' '}
              <a
                href="https://agenciapresentes.org/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Agencia Presentes
              </a>
              . Fotos autoría de: Ariel Gutraich (Argentina), Josean Rivera
              (Chile), Diego Rosales (El Salvador), Milena Pafundi (Mexico),
              Melissa Merino y Gianna Camacho (Peru).
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}
