import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../layouts/MainLayout";

import { useTranslation } from "react-i18next";
import { history } from "../../App.js";

import "./JurProfileHome.css";

import useGqlJurisdictions from "../../gql/useGqlJurisdictions";
import ReactDOMServer from "react-dom/server";
import microstates from "../components/Map/microstates.json";

import { cMenuRegions } from "../../config/constants";
import Select, { components } from "react-select";
import MagGlass from "../components/icons/MagGlass";

import Map from "../components/Map/Map.js";
import MapTooltip from "../components/Areas/JurProfile/MapTooltip";
import { Link } from "react-router-dom";
import StyledMapTooltips from "../components/StyledMapToolTips";
import { getPath } from "../../config/routes";

export default function JurProfileHome() {
  const htmlReferences = useRef();
  const [references, setReferences] = useState([]);
  const [drillDown, setDrillDown] = useState("0");
  const [showLabels, setShowLabels] = useState(false);
  const [highQuality, setHighQuality] = useState(false);
  const [hideMicrostates, setHideMicrostates] = useState(false);
  const [pacificCenter, setPacificCenter] = useState(false);
  const [filterJurs, setFilterJurs] = useState(null);

  const { jurisdictions, regions, loading, error, jurisdictionsById } = useGqlJurisdictions();
  const { t, i18n } = useTranslation();

  const tooltip = e => {
    return ReactDOMServer.renderToStaticMarkup();
  };

  useEffect(() => {
    if (!error && jurisdictions && jurisdictions.length) {
      setReferences(
        jurisdictions.map(j => ({
          id: j.a2_code,
          regions: j.regions,
          microstate: microstates.find(ms => ms.A2 === j.a2_code),
          reference: j.un_member ? "#a0aa9f" : "#fff",
          link: getPath("jur_profile").replace(":slug", j.slug.toLowerCase()),
          tooltip: ReactDOMServer.renderToStaticMarkup(
            <StyledMapTooltips entry={{ motherEntry: { jurisdiction: j } }} area={"jur-home"} />
          ), //tooltip(e),
          entry: j
        }))
      );
    }
  }, [jurisdictions, error, loading]);

  const mapLegendItems = [
    { name: t("Non UN Members"), number: 47, color: "#ffffff" },
    { name: t("UN Members"), number: 193, color: "#A0AA9F" }
  ];

  const chart = useRef();

  //change icon to magGlass
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <MagGlass className="MagGlassIcon" size={20} />
      </components.DropdownIndicator>
    );
  };

  return (
    <MainLayout section="jurs">
      <div className="jur__page jur-home__page">
        <section className="jurHero__section">
          <div className="content__container">
            <div className="content__container mapContent__container">
              <Map
                pacificCenter={pacificCenter}
                showLabels={showLabels}
                highRes={highQuality}
                hideMicrostates={hideMicrostates}
                references={references}
                htmlReferences={htmlReferences.current}
                jurisdictions={jurisdictionsById}
                drillDown={drillDown}
                loadingData={!(jurisdictions && references && references.length > 0)}
                ref={chart}
                setMenuOpen={() => {}}
                extraProps={{ links: true }}
              />
              <div className="mapLegend">
                {mapLegendItems.map((item, index) => {
                  return (
                    <div className="mapLegend__item legend175" key={index + item.name}>
                      <span className="stripe" style={{ backgroundColor: item.color }} />
                      <span className="label">{item.name}</span>
                      <span className="count">{item.number}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        <section className="jurSearch__section">
          <div className="content__container">
            <div className="jurSearch__header">
              <Select
                components={{ ...components, DropdownIndicator }}
                className="selectJur__select reactSelect"
                defaultValue={{ value: 0, label: t("Search by jurisdiction...") + "..." }}
                maxMenuHeight="500px"
                options={
                  jurisdictions && jurisdictions.length
                    ? jurisdictions
                        .filter(j => j.a2_code !== "G0")
                        .map(j => ({ value: j.id, label: j.name, a2_code: j.a2_code, slug: j.slug }))
                    : [{ value: 0, label: "Loading..." }]
                }
                placeholder={t("Search by jurisdiction...") + "..."}
                onChange={e => history.push(getPath("jur_profile").replace(":slug", e.slug.toLowerCase()))}
                components={{
                  Option: props => (
                    <components.Option {...props}>
                      {props.data.a2_code && (
                        <img
                          src={"/images/flags_svg/" + props.data.a2_code.toLowerCase() + ".svg"}
                          style={{ width: 16, verticalAlign: "baseline" }}
                          className={"svgFlag"}
                          alt={props.data.label}
                        />
                      )}{" "}
                      {props.data.label}
                    </components.Option>
                  )
                }}
              />
              <Select
                className="selectGroup__select reactSelect"
                defaultValue={{ value: 0, label: t("Search by groups") + "..." }}
                options={
                  regions ? regions.map(j => ({ value: j.id, label: j.name })) : [{ value: 0, label: "Loading..." }]
                }
                maxMenuHeight="500px"
                placeholder={t("Search by groups") + "..."}
                onChange={e => {
                  setFilterJurs(jurisdictions.filter(j => j.regions.find(r => r.id === e.value)).map(j => j.id));
                }}
              />
            </div>

            <h4 className="jpia__title">{t("UN Members")}</h4>

            <div className="jurSearch__countryList">
              {jurisdictions &&
                jurisdictions.length &&
                jurisdictions
                  .slice()
                  .sort((a, b) => {
                    if (
                      a.id
                        .replace("S-", "X-")
                        .replace("N-", "Y-")
                        .replace("DT", "ZT") >
                      b.id
                        .replace("S-", "X-")
                        .replace("N-", "Y-")
                        .replace("DT", "ZT")
                    )
                      return 1;
                    else if (
                      a.id
                        .replace("S-", "X-")
                        .replace("N-", "Y-")
                        .replace("DT", "ZT") <
                      b.id
                        .replace("S-", "X-")
                        .replace("N-", "Y-")
                        .replace("DT", "ZT")
                    )
                      return -1;
                    else return 0;
                  })
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .filter(j => j.a2_code !== "G0")
                  .filter(j => (!filterJurs || filterJurs.indexOf(j.id) !== -1) && j.un_member)
                  .map((item, index) => {
                    return (
                      <button
                        key={index}
                        className="btn btn-default jurSearch__countryBtn"
                        type="button"
                        onClick={e => history.push(getPath("jur_profile").replace(":slug", item.slug.toLowerCase()))}
                        title={item.name + " profile"}
                      >
                        <img
                          src={"/images/flags_svg/" + item.a2_code.toLowerCase() + ".svg"}
                          height="16px"
                          className={"svgFlag"}
                        />
                        <span className="btnText">{item.name}</span>
                      </button>
                    );
                  })}
            </div>

            <h4 className="jpia__title">{t("Non UN Members")}</h4>

            <div className="jurSearch__countryList">
              {jurisdictions &&
                jurisdictions.length &&
                jurisdictions
                  .slice()
                  .sort((a, b) => {
                    if (a.name > b.name) return 1;
                    else if (a.name < b.name) return -1;
                    else return 0;
                  })
                  .filter(j => j.a2_code !== "G0")
                  .filter(j => (!filterJurs || filterJurs.indexOf(j.id) !== -1) && !j.un_member)
                  .map((item, index) => {
                    return (
                      <button
                        key={index}
                        className="btn btn-default jurSearch__countryBtn"
                        type="button"
                        onClick={e => history.push(getPath("jur_profile").replace(":slug", item.slug.toLowerCase()))}
                        title={item.name + " profile"}
                      >
                        <img
                          src={"/images/flags_svg/" + item.a2_code.toLowerCase() + ".svg"}
                          height="16px"
                          className={"svgFlag"}
                        />
                        <span className="btnText">{item.name}</span>
                      </button>
                    );
                  })}
            </div>
          </div>
        </section>
      </div>
    </MainLayout>
  );
}
