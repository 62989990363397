import Tippy from '@tippyjs/react'
import React, { useState } from 'react'

import infoIcon from '../../../images/infoIcon.svg'
import infoIconRedBg from '../../../images/infoIconRedBg.svg'
import infoIconGrayBg from '../../../images/infoIconGrayBg.svg'

import infoIconYellowBg from '../../../images/infoIconYellowBg.svg'

export default function AutoTranslateCell({ mainText }) {
  const [showText, setShowText] = useState(false)
  return (
    <div>
      <p className="translatePlaceholder">
        Naciones Unidas no ha producido una traducción oficial al español de
        este documento. Si desea acceder a una traducción producida por un robot
        de traducción, haga{' '}
        <span
          className="translateShowMore"
          onClick={() => setShowText(!showText)}
        >
          clic aquí
        </span>
        .
      </p>
      {showText && (
        <p>
          {mainText}
          <br />
          <em>
            (Advertencia: esta es una traducción no oficial producida por un
            robot de traducción; puede contener errores conceptuales).
          </em>
        </p>
      )}
    </div>
  )
}
