// i18next-extract-disable
import React, { useRef, useLayoutEffect, useState } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps";
import * as d3geoP from "d3-geo-projection";

import am4geodata_worldHigh from "./maps/worldHigh";
import am4geodata_worldLow from "./maps/worldLow";
import am4geodata_europeHigh from "./maps/europeHigh";
import am4geodata_europeLow from "./maps/europeLow";

import oc from "./maps/oceaniaLow";
import sa from "./maps/latinAmericaLow";
import na from "./maps/northAmericaLow";
import ca from "./maps/caribbeanLow";
import af from "./maps/africaLow";
import as from "./maps/asiaLow";
import me from "./maps/middleEastLow";

import am4geodata_nigeriaHigh from "@amcharts/amcharts4-geodata/nigeriaHigh";
import am4geodata_nigeriaLow from "@amcharts/amcharts4-geodata/nigeriaLow";

import am4geodata_lang_ES from "../../lang/maps/es";
import am4geodata_lang_EN from "../../lang/maps/en";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import * as d3geo from "d3-geo";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";


import microstates from "./microstates";
import MainSpinner from "../MainSpinner";
import { applyInline, computedStyleToInlineStyle } from "../../lib/computedStyles";

function MapOld({
  projection,
  pacific = false,
  seaColor = "rgb(110, 202, 255)",
  highRes = false,
  showLabels = false,
  landColor = "#CCC",
  strokeColor = "#D8E3E5",
  references,
  htmlReferences,
  jurisdictions,
  hideMicro,
  editor = true,
  drillDown = ""
}) {
  const chart = useRef(null);
  const { t, i18n } = useTranslation();
  const [showMicrostates, setShowMicroStates] = useState(!hideMicro);
  const [lowQ, setLowQ] = useState(true);
  const [labels, setLabels] = useState(showLabels);
  const [pacificCenter, setPacificCenter] = useState(pacific);
  const [loading, setLoading] = useState(true);

  //FOR SETTING THE LABELS LOCATIONS ONLY
  const [lastState, _setLastState] = useState({});
  const lastStateRef = useRef(lastState);
  const setLastState = (data) => {
    lastStateRef.current = data;
    _setLastState(data);
  };
  const [labelsLocations, _setLabelsLocations] = useState({});
  const labelsLocationsRef = useRef(labelsLocations);
  const setLabelsLocations = (data) => {
    labelsLocationsRef.current = data;
    _setLabelsLocations(data);
  };

  const [labelFontSize, _setLabelFontSize] = useState(3);
  const labelFontSizeRef = useRef(labelFontSize);
  const setLabelFontSize = (data) => {
    labelFontSizeRef.current = data;
    _setLabelFontSize(data);
  };

  const getRegionA2s = (region) => {
    return Object.values(jurisdictions)
      .filter((jur) => !jur.regions.length || jur.regions.map((r) => r.id).indexOf("" + region + "") === -1)
      .map((j) => j.a2_code);
  };

  const loadMap = () => {
    //LOADING
    setLoading(true);
    let x = am4core.create("chartdiv", am4maps.MapChart);
    x.events.on(
      "ready",
      function (ev) {
        setLoading(false);
      },
      this
    );

    if (drillDown === "Africa" || !drillDown) {
      //Add Western Sahara
      let polygonSeriesEH = x.series.push(new am4maps.MapPolygonSeries());
      polygonSeriesEH.geodata = am4geodata_worldLow;
      polygonSeriesEH.include = ["EH"];
      let polygonTemplateEH = polygonSeriesEH.mapPolygons.template;
      polygonTemplateEH.fill = am4core.color("#DDD");
      polygonTemplateEH.propertyFields.fill = "fill";
      //polygonTemplateEH.tooltipHTML = `Western Sahara (no info)`;
      polygonTemplateEH.stroke = am4core.color(strokeColor);
      polygonTemplateEH.strokeWidth = 0.4;
      polygonTemplateEH.strokeOpacity = 1;
    }

    if (i18n.language === "es") x.geodataNames = am4geodata_lang_ES;
    else x.geodataNames = am4geodata_lang_EN;

    if (pacificCenter) {
      x.projection.d3Projection = d3geoP.geoTimes();
      x.deltaLongitude = -162;
    } else if (drillDown === "Europe") {
      x.projection = new am4maps.projections.Orthographic();
      x.deltaLongitude = -30;
      x.deltaLatitude = -25;
    } else {
      x.projection.d3Projection = d3geoP.geoRobinson();
    }

    if (drillDown === "Europe") {
      if (!lowQ) {
        // Set map definition
        x.geodata = am4geodata_europeHigh;
      } else {
        x.geodata = am4geodata_europeLow;
      }
    } else {
      if (!lowQ) {
        // Set map definition
        x.geodata = am4geodata_worldHigh;
      } else {
        x.geodata = am4geodata_worldLow;
      }
    }

    x.maxPanOut = 0.1;

    // Create regular countriespolygon series
    let polygonSeries = x.series.push(new am4maps.MapPolygonSeries());

    references.forEach((obj) => {
      polygonSeries.data = [
        ...polygonSeries.data,
        {
          id: obj.id,
          reference_text: obj.reference_text,
          fill: am4core.color(obj.reference ? obj.reference : "#999900"),
          tooltip: obj.tooltip
        }
      ];
    });

    let polygonTemplate = polygonSeries.mapPolygons.template;

    if (references && references.length) polygonTemplate.tooltipHTML = `{tooltip}`;
    else polygonTemplate.tooltipHTML = `{name}`;

    polygonTemplate.fill = am4core.color("#FFFFFF");
    polygonTemplate.propertyFields.fill = "fill";
    if (editor)
      polygonTemplate.events.on("hit", function (ev) {
        // zoom to an object
        console.log(ev.target.series);

        if (!ev.event.ctrlKey && !ev.event.metaKey) setLastState(ev.target.dataItem.dataContext.id);

        // get object info
      });
    /*
    let countrySeries = x.series.push(new am4maps.MapPolygonSeries());
    if (!lowQ) {
      // Set map definition
      countrySeries.geodata = am4geodata_nigeriaHigh;
    } else {
      countrySeries.geodata = am4geodata_nigeriaLow;
    }

    let polygonTemplate2 = countrySeries.mapPolygons.template;
    polygonTemplate2.tooltipHTML = `{tooltip}`;

    polygonTemplate2.fill = am4core.color("#FF0000");
    polygonTemplate2.propertyFields.fill = "fill";

    countrySeries.show();
*/
    // Exclude Antartica
    if (showMicrostates) {
      addMicroStates(x);
      polygonSeries.exclude = [
        "AQ",
        "EH",
        "BV",
        "GO",
        "JU",
        "UM-DQ",
        "UM-FQ",
        "UM-HQ",
        "UM-JQ",
        "UM-MQ",
        "UM-WQ",
        "NF",
        "HM",
        "CX",
        "CC",
        "TF",
        "AX"
        //  "BW" //Bissau appears
      ]; //.concat(microstates.map((ms) => ms.A2));

      //.concat(polygonSeries.data.filter((d) => references.find((r) => r.id === d.id)));
    } else
      polygonSeries.exclude = [
        "AQ",
        "EH",
        "BV",
        "GO",
        "JU",
        "UM-DQ",
        "UM-FQ",
        "UM-HQ",
        "UM-JQ",
        "UM-MQ",
        "UM-WQ",
        "NF",
        "HM",
        "CX",
        "CC",
        "TF",
        "AX"
        // "BW" //Bissau appears
      ];

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true;

    //INI TEST CIRCLES

    //LABELS SETUP
    let labelProperty = showMicrostates ? "microstate_label_" : "label_";
    if (labels || editor) {
      //polygonSeries.calculateVisualCenter = true;
      let labelSeries = x.series.push(new am4maps.MapImageSeries());
      let labelTemplate = labelSeries.mapImages.template.createChild(am4core.Label);
      labelTemplate.horizontalCenter = "middle";
      labelTemplate.verticalCenter = "middle";
      labelTemplate.fontSize = 1;
      labelTemplate.fontFamily = "'Lato', sans-serif";
      labelTemplate.textAlign = "middle";
      labelTemplate.nonScaling = false;
      labelTemplate.interactionsEnabled = false;
      labelTemplate.wrap = false;

      polygonSeries.events.on("inited", function () {
        let regionsA2;
        if (drillDown === "Europe") regionsA2 = getRegionA2s(12);
        Object.keys(labelsLocationsRef.current).forEach((l) => {
          if (!jurisdictions[l]) console.log("NOOOO", l);
          if (drillDown === "Europe" && (regionsA2.indexOf(l) !== -1 || !labelsLocationsRef.current[l][1])) return;
          let label = labelSeries.mapImages.create();
          label.latitude = labelsLocationsRef.current[l][1];
          let lineHeight = l === "MK" ? 2 : 3;
          if (jurisdictions[l].label_font_size === 1) lineHeight = 1;
          label.latitude = labelsLocationsRef.current[l][1];
          label.longitude = labelsLocationsRef.current[l][0];
          let labelPre = "";
          let labelPost = "";

          if (!jurisdictions[l].un_member) {
            labelPre = "[font-style: italic]";
            labelPost = "[/]";
          }
          /*label.children.getIndex(0).html =
            `<div style="padding:10px; font-family: 'Lato', sans-serif; line-height:${lineHeight}px; ${textDecoration}">` +
            jurisdictions[l].map_name.replace(/\/gi, "<br />") +
            "</div>";*/
          let split = jurisdictions[l].map_name.split("*");
          label.children.getIndex(0).text = labelPre + split[0] + labelPost;

          let fontSize = labelsLocationsRef.current[l][2];
          if (drillDown === "Europe") fontSize = (labelsLocationsRef.current[l][2] + 1) * 1.5;

          if (labelsLocationsRef.current[l][2]) label.children.getIndex(0).fontSize = fontSize;
          if (split.length > 1) {
            if (split.length >= 2) {
              let labelTmp2 = labelSeries.mapImages.create();
              labelTmp2.latitude = labelsLocationsRef.current[l][1] - 0.2 * labelsLocationsRef.current[l][2];
              labelTmp2.longitude = labelsLocationsRef.current[l][0];
              labelTmp2.children.getIndex(0).text = labelPre + split[1] + labelPost;
              if (labelsLocationsRef.current[l][2]) labelTmp2.children.getIndex(0).fontSize = fontSize;
            }
            if (split.length === 3) {
              let labelTmp3 = labelSeries.mapImages.create();
              labelTmp3.latitude = labelsLocationsRef.current[l][1] - 0.4 * labelsLocationsRef.current[l][2];
              labelTmp3.longitude = labelsLocationsRef.current[l][0];
              labelTmp3.children.getIndex(0).text = labelPre + split[2] + labelPost;
              if (labelsLocationsRef.current[l][2]) labelTmp3.children.getIndex(0).fontSize = fontSize;
            }
          }
        });
        let tmpObj = {};
        polygonSeries.mapPolygons.each(function (polygon) {
          if (!polygon.dataItem.dataContext.name) return;
          if (drillDown && jurisdictions[polygon.dataItem.dataContext.id]["drilldown_label_longitude"]) {
            labelProperty = "drilldown_label_";
          } else {
            labelProperty = showMicrostates ? "microstate_label_" : "label_";
          }
          //let label = labelSeries.mapImages.create();
          let state = polygon.dataItem.dataContext.name.split("-").pop();
          /*
          label.latitude = polygon.visualLatitude;
          label.longitude = polygon.visualLongitude;
          label.children.getIndex(0).text = state;*/
          if (!jurisdictions[polygon.dataItem.dataContext.id]) {
            console.log("WARNING ", polygon.dataItem.dataContext.id, " NOT FOUND");
          } else
            tmpObj = {
              ...tmpObj,
              [polygon.dataItem.dataContext.id]: [
                jurisdictions[polygon.dataItem.dataContext.id][labelProperty + "longitude"],
                jurisdictions[polygon.dataItem.dataContext.id][labelProperty + "latitude"],
                jurisdictions[polygon.dataItem.dataContext.id].label_font_size
              ]
            };
        });
        microstates.forEach((ms) => {
          tmpObj = {
            ...tmpObj,
            [ms.A2]: [
              jurisdictions[ms.A2][labelProperty + "longitude"],
              jurisdictions[ms.A2][labelProperty + "latitude"],
              jurisdictions[ms.A2].label_font_size
            ]
          };
        });
        if (!Object.keys(labelsLocationsRef.current).length)
          setLabelsLocations({
            ...labelsLocationsRef.current,
            ...tmpObj
          });
      });
    }
    if (editor)
      x.seriesContainer.events.on("hit", function (ev) {
        console.log(x.svgPointToGeo(ev.svgPoint).latitude, x.svgPointToGeo(ev.svgPoint).longitude);
        setLabelsLocations({
          ...labelsLocationsRef.current,
          [lastStateRef.current]: [
            x.svgPointToGeo(ev.svgPoint).longitude,
            x.svgPointToGeo(ev.svgPoint).latitude,
            labelFontSizeRef.current
          ]
        });
      });
    polygonTemplate.stroke = am4core.color(strokeColor);
    polygonTemplate.strokeWidth = 0.4;
    polygonTemplate.strokeOpacity = 1;
    // Configure series
    /*let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";

    polygonTemplate.polygon.fillOpacity = 1;

    polygonTemplate.fill = am4core.color(landColor);
    polygonTemplate.stroke = am4core.color("#ccc");*/

    // Create hover state and set alternative fill color
    let hs = polygonTemplate.states.create("hover");
    hs.properties.fill = x.colors.getIndex(0);

    let options = x.exporting.getFormatOptions("pdf");
    options.quality = 1;
    options.scale = 10;
    options.pageOrientation = "landscape";
    options.addURL = false;
    options.minHeight = 590;
    options.minWidth = 800;
    options.imageFormat = "png";
    x.exporting.setFormatOptions("pdf", options);

    let options2 = x.exporting.getFormatOptions("png");
    options2.quality = 0.8;
    options2.scale = 6;

    x.exporting.setFormatOptions("png", options2);

    chart.current = x;
  };

  const loadMap2 = () => {
    //LOADING
    setLoading(true);
    
    let x = am4core.create("chartdiv", am4maps.MapChart);
    x.events.on(
      "ready",
      function (ev) {
        setLoading(false);
      },
      this
    );

    if (i18n.language === "es") x.geodataNames = am4geodata_lang_ES;
    else x.geodataNames = am4geodata_lang_EN;

    x.maxPanOut = 0.1;

    x.zoomStep = 2;

    /*x.homeZoomLevel = 1.5;
    x.homeGeoPoint = {
      latitude: 22.73052,
      longitude: 88.16463
    };*/

    chart.current = { ...chart.current, map: x };

    loadSeries(drillDown || "Oceania");

    /*let countrySeries = x.series.push(new am4maps.MapPolygonSeries());

    countrySeries.geodata = geoDatas.Europe;
    countrySeries.useGeodata = true;

    countrySeries.hidden = true;
*/

    setTimeout(() => {
      /*chart.current = {
        ...chart.current,
        map: x,
        countrySeries,
        chart.current.worldSeries: chart.current.map.series.push(new am4maps.MapPolygonSeries())
      };*/
      //chart.current.worldSeries = chart.current.map.series.push(new am4maps.MapPolygonSeries());
      //chart.current.worldSeries = chart.current.map.series.push(new am4maps.MapPolygonSeries());
      //loadSeries("Asia");
      /*chart.current.worldSeries.hide();
      chart.current.microSeries.forEach((ms) => ms.hide());
      chart.current.countrySeries.show();
          setTimeout(() => {
            chart.current.map.zoomToGeoPoint({ latitude: 51.61286543362238, longitude: 10.3276988958156 }, 3.5);
          }, 1);*/
    }, 3000);
  };

  const seriesMicroStates = (series) => {
    return microstates;
    if (series === "World") return microstates;
    return microstates.filter((ms) => ms["Full name"].indexOf("ch") !== -1);
  };

  const loadSeries = (series) => {
    chart.current.map = am4core.create("chartdiv", am4maps.MapChart);
    if (i18n.language === "es") chart.current.map.geodataNames = am4geodata_lang_ES;
    else chart.current.map.geodataNames = am4geodata_lang_EN;

    chart.current.map.maxPanOut = 0.1;

    chart.current.map.zoomStep = 1.4;

    chart.current.map.series.clear();
    chart.current.worldSeries = chart.current.map.series.push(new am4maps.MapPolygonSeries());
    const geoDatas = {
      World: am4geodata_worldHigh,
      Europe: am4geodata_europeHigh,
      SWANA: me,
      Caribbean: ca,
      LatinAmerica: sa,
      NorthAmerica: na,
      Africa: af,
      Asia: as,
      Oceania: oc
    };
    chart.current.worldSeries.geodata = geoDatas[series];
    if (series === "Europe") {
      chart.current.map.projection = new am4maps.projections.Orthographic();
      chart.current.map.panBehavior = "rotateLongLat";
      chart.current.map.deltaLongitude = -9.776;
      chart.current.map.deltaLatitude = -40.978;
    } else if (series === "SWANA") {
      //Morocco, Western Sahara, Mauritania, Mali, Algeria, Niger, Chad, Libya, Tunisia, Sudan, South Sudan, Egypt, Israel
      chart.current.map.projection = new am4maps.projections.Orthographic();
      chart.current.map.panBehavior = "rotateLongLat";
      chart.current.map.deltaLongitude = -18.639;
      chart.current.map.deltaLatitude = -20.064;
    } else if (series === "Caribbean") {
      // NA DISABLED: USA
      // CA DISABLED: All CA
      // CA: Belize
      // SA: Guyana, Surinam, French Guiana
      // SA DISABLED: Colombia, Venezuela, Brazil, Ecuador

      chart.current.map.projection = new am4maps.projections.Orthographic();
      chart.current.map.panBehavior = "rotateLongLat";
      chart.current.map.deltaLongitude = 61.577;
      chart.current.map.deltaLatitude = -14.185;
      /*chart.current.map.homeZoomLevel = 3.6;
      chart.current.map.homeGeoPoint = {
        latitude: 16.40516,
        longitude: -71.20126
      };*/
      /* chart.current.map.zoomToGeoPoint(

      );*/
      setTimeout(
        () =>
          chart.current.map.zoomToGeoPoint(
            {
              latitude: 12.40516,
              longitude: -71.20126
            },
            3.6
          ),
        1
      );

      //chart.current.map.zoomLevel = 3.6;
    } else if (series === "LatinAmerica") {
      //South America + Central America - Belize (Disabled)
      chart.current.map.d3Projection = d3geoP.geoRobinson();
      //Zoom: 1.3310000000000004 | Zoom GeoPoint: -13.651857629447658 / -55.84848905098336
      setTimeout(
        () =>
          chart.current.map.zoomToGeoPoint(
            {
              latitude: -65.651857629,
              longitude: -55.84848905
            },
            1.38
          ),
        1
      );
    } else if (series === "Oceania") {
      //South America + Central America - Belize (Disabled)

      chart.current.map.projection.d3Projection = d3geoP.geoTimes();
      chart.current.map.deltaLongitude = -202;
      //chart.current.map.deltaLatitude = -2;
      //Zoom: 1.3310000000000004 | Zoom GeoPoint: -13.651857629447658 / -55.84848905098336
      setTimeout(
        () =>
          chart.current.map.zoomToGeoPoint(
            {
              latitude: -65.651857629,
              longitude: -1.84848905
            },
            1
          ),
        1
      );
    } else if (series === "NorthAmerica") {
      chart.current.map.projection = new am4maps.projections.Orthographic();
      //chart.current.map.panBehavior = "rotateLongLat";
      chart.current.map.deltaLongitude = 88.487;
      chart.current.map.deltaLatitude = -24.125;
      //Rotation: Lat: -24.125 - Long: 88.487 | Zoom: 1.3310000000000004 | Zoom GeoPoint: 49.86360081998287 / -95.29596565508211

      /*chart.current.map.homeZoomLevel = 3.6;
      chart.current.map.homeGeoPoint = {
        latitude: 16.40516,
        longitude: -71.20126
      };*/
      /* chart.current.map.zoomToGeoPoint(
        {
          latitude: 16.40516,
          longitude: -71.20126
        },
        3.6
      );*/

      //chart.current.map.zoomLevel = 3.6;
    } else if (series === "Africa") {
      //Rotation: Lat: -2.395 - Long: -22.088 | Zoom: 1.05 | Zoom GeoPoint: 1.7316403151372304 / 18.669924615748837

      // DESHABILITAR MIDDLE EAST y Turkey y Cyprus
      chart.current.map.projection = new am4maps.projections.Orthographic();
      //chart.current.map.panBehavior = "rotateLongLat";
      chart.current.map.deltaLongitude = -22.088;
      chart.current.map.deltaLatitude = -2.395;
    } else if (series === "Asia") {
      // DESHABILITAR Los paises de Europa

      //Rotation: Lat: -7.46 - Long: -85.758 | Zoom: 1.4830822839604516 | Zoom GeoPoint: 20.99836335526946 / 86.097987233665
      /* chart.current.map.projection = new am4maps.projections.Orthographic();
      chart.current.map.panBehavior = "rotateLongLat";
      chart.current.map.deltaLongitude = -81.349;
      chart.current.map.deltaLatitude = -13.685;*/
      // Zoom: 1.7786282013654566 | Zoom GeoPoint: 22.071899209476392 / 88.35023462715407
      chart.current.map.projection.d3Projection = d3geoP.geoRobinson();
      setTimeout(
        () =>
          chart.current.map.zoomToGeoPoint(
            {
              latitude: 22.071899209476392,
              longitude: 175.35023462715407
            },
            2.15
          ),
        1
      );
    } else {
      if (pacificCenter) {
        chart.current.map.projection.d3Projection = d3geoP.geoTimes();
        chart.current.map.deltaLongitude = -162;
      } else {
        chart.current.map.projection.d3Projection = d3geoP.geoRobinson();
      }
    }

    /* -13.685 - Long: -81.349 | Zoom: 1.5 | Zoom GeoPoint: 22.730525676832347 / 88.16463935310252*/

    /*chart.current.map.zoomToGeoPoint(
      {
        latitude: 22.73052,
        longitude: 88.16463
      },
      1.5
    );*/

    references.forEach((obj) => {
      chart.current.worldSeries.data = [
        ...chart.current.worldSeries.data,
        {
          id: obj.id,
          reference_text: obj.reference_text,
          fill:
            showMicrostates && obj.microstate
              ? am4core.color("#000") //d8e3e5
              : am4core.color(obj.reference ? obj.reference : "#999900"),
          tooltip: obj.tooltip,
          hidden: true
        }
      ];
    });

    let worldTemplate = chart.current.worldSeries.mapPolygons.template;
    if (references && references.length) worldTemplate.tooltipHTML = `{tooltip}`;
    else worldTemplate.tooltipHTML = `{name}`;

    worldTemplate.fill = am4core.color("#FFFFFF");
    worldTemplate.propertyFields.fill = "fill";
    worldTemplate.stroke = am4core.color(strokeColor);
    worldTemplate.strokeWidth = 0.4;
    worldTemplate.strokeOpacity = 1;
    if (editor)
      worldTemplate.events.on("hit", function (ev) {
        // zoom to an object
        console.log(
          chart.current.map.svgPointToGeo(ev.svgPoint).latitude,
          chart.current.map.svgPointToGeo(ev.svgPoint).longitude
        );

        if (!ev.event.ctrlKey && !ev.event.metaKey) setLastState(ev.target.dataItem.dataContext.id);

        // get object info
      });

    if (showMicrostates) {
      //addMicroStates(chart.current.map, series);
      chart.current.worldSeries.exclude = [
        "AQ",
        "EH",
        "BV",
        "GO",
        "JU",
        "UM-DQ",
        "UM-FQ",
        "UM-HQ",
        "UM-JQ",
        "UM-MQ",
        "UM-WQ",
        "NF",
        "HM",
        "CX",
        "CC",
        "TF",
        "AX"
        //  "BW" //Bissau appears
      ]; //.concat(microstates.map((ms) => ms.A2));

      //.concat(polygonSeries.data.filter((d) => references.find((r) => r.id === d.id)));
    } else
      chart.current.worldSeries.exclude = [
        "AQ",
        "EH",
        "BV",
        "GO",
        "JU",
        "UM-DQ",
        "UM-FQ",
        "UM-HQ",
        "UM-JQ",
        "UM-MQ",
        "UM-WQ",
        "NF",
        "HM",
        "CX",
        "CC",
        "TF",
        "AX"
        // "BW" //Bissau appears
      ];

    // Make map load polygon (like country names) data from GeoJSON
    chart.current.worldSeries.useGeodata = true;
    //Rotation: Lat: -10.281 - Long: -83.102 | Zoom: 1.1331267001364742 | Zoom GeoPoint: 24.643680233223787 / 83.10163282478507

    /*
    let labelProperty = showMicrostates ? "microstate_label_" : "label_";
    if (labels || editor) {
      //polygonSeries.calculateVisualCenter = true;
      let labelSeries = chart.current.map.series.push(new am4maps.MapImageSeries());
      let labelTemplate = labelSeries.mapImages.template.createChild(am4core.Label);
      labelTemplate.horizontalCenter = "middle";
      labelTemplate.verticalCenter = "middle";
      labelTemplate.fontSize = 1;
      labelTemplate.fontFamily = "'Lato', sans-serif";
      labelTemplate.textAlign = "middle";
      labelTemplate.nonScaling = false;
      labelTemplate.interactionsEnabled = false;
      labelTemplate.wrap = false;

      chart.current.worldSeries.events.on("inited", function () {
        let regionsA2;
        if (drillDown === "Europe") regionsA2 = getRegionA2s(12);
        Object.keys(labelsLocationsRef.current).forEach((l) => {
          if (!jurisdictions[l]) console.log("NOOOO", l);
          if (drillDown === "Europe" && (regionsA2.indexOf(l) !== -1 || !labelsLocationsRef.current[l][1])) return;
          let label = labelSeries.mapImages.create();
          label.latitude = labelsLocationsRef.current[l][1];
          let lineHeight = l === "MK" ? 2 : 3;
          if (jurisdictions[l].label_font_size === 1) lineHeight = 1;
          label.latitude = labelsLocationsRef.current[l][1];
          label.longitude = labelsLocationsRef.current[l][0];
          let labelPre = "";
          let labelPost = "";

          if (!jurisdictions[l].un_member) {
            labelPre = "[font-style: italic]";
            labelPost = "[/]";
          }

          let split = jurisdictions[l].map_name.split("*");
          label.children.getIndex(0).text = labelPre + split[0] + labelPost;

          let fontSize = labelsLocationsRef.current[l][2];
          if (drillDown === "Europe") fontSize = (labelsLocationsRef.current[l][2] + 1) * 1.5;

          if (labelsLocationsRef.current[l][2]) label.children.getIndex(0).fontSize = fontSize;
          if (split.length > 1) {
            if (split.length >= 2) {
              let labelTmp2 = labelSeries.mapImages.create();
              labelTmp2.latitude = labelsLocationsRef.current[l][1] - 0.2 * labelsLocationsRef.current[l][2];
              labelTmp2.longitude = labelsLocationsRef.current[l][0];
              labelTmp2.children.getIndex(0).text = labelPre + split[1] + labelPost;
              if (labelsLocationsRef.current[l][2]) labelTmp2.children.getIndex(0).fontSize = fontSize;
            }
            if (split.length === 3) {
              let labelTmp3 = labelSeries.mapImages.create();
              labelTmp3.latitude = labelsLocationsRef.current[l][1] - 0.4 * labelsLocationsRef.current[l][2];
              labelTmp3.longitude = labelsLocationsRef.current[l][0];
              labelTmp3.children.getIndex(0).text = labelPre + split[2] + labelPost;
              if (labelsLocationsRef.current[l][2]) labelTmp3.children.getIndex(0).fontSize = fontSize;
            }
          }
        });
        let tmpObj = {};
        chart.current.worldSeries.mapPolygons.each(function (polygon) {
          if (!polygon.dataItem.dataContext.name) return;
          if (drillDown && jurisdictions[polygon.dataItem.dataContext.id]["drilldown_label_longitude"]) {
            labelProperty = "drilldown_label_";
          } else {
            labelProperty = showMicrostates ? "microstate_label_" : "label_";
          }
          //let label = labelSeries.mapImages.create();
          let state = polygon.dataItem.dataContext.name.split("-").pop();
          /*
          label.latitude = polygon.visualLatitude;
          label.longitude = polygon.visualLongitude;
          label.children.getIndex(0).text = state;
          if (!jurisdictions[polygon.dataItem.dataContext.id]) {
            console.log("WARNING ", polygon.dataItem.dataContext.id, " NOT FOUND");
          } else
            tmpObj = {
              ...tmpObj,
              [polygon.dataItem.dataContext.id]: [
                jurisdictions[polygon.dataItem.dataContext.id][labelProperty + "longitude"],
                jurisdictions[polygon.dataItem.dataContext.id][labelProperty + "latitude"],
                jurisdictions[polygon.dataItem.dataContext.id].label_font_size
              ]
            };
        });
        microstates.forEach((ms) => {
          tmpObj = {
            ...tmpObj,
            [ms.A2]: [
              jurisdictions[ms.A2][labelProperty + "longitude"],
              jurisdictions[ms.A2][labelProperty + "latitude"],
              jurisdictions[ms.A2].label_font_size
            ]
          };
        });
        if (!Object.keys(labelsLocationsRef.current).length)
          setLabelsLocations({
            ...labelsLocationsRef.current,
            ...tmpObj
          });
      });
    }
    if (editor)
      chart.current.map.seriesContainer.events.on("hit", function (ev) {
        setLabelsLocations({
          ...labelsLocationsRef.current,
          [lastStateRef.current]: [
            chart.current.map.svgPointToGeo(ev.svgPoint).longitude,
            chart.current.map.svgPointToGeo(ev.svgPoint).latitude,
            labelFontSizeRef.current
          ]
        });
      });
      */
    /*let countrySeries = x.series.push(new am4maps.MapPolygonSeries());

    countrySeries.geodata = geoDatas.Europe;
    countrySeries.useGeodata = true;

    countrySeries.hidden = true;
*/
  };

  const addMicroStates = (series) => {
    let placeSeries;
    let place;
    let circle;
    let allPlacesSeries = [];

    let labelSeries = chart.current.map.series.push(new am4maps.MapImageSeries());
    let labelTemplate = labelSeries.mapImages.template.createChild(am4core.Label);
    labelTemplate.horizontalCenter = "middle";
    labelTemplate.verticalCenter = "middle";
    labelTemplate.fontSize = 3;
    labelTemplate.nonScaling = false;
    labelTemplate.interactionsEnabled = false;

    let tmpObj = {};

    for (let i = 1; i <= 6; i++) {
      placeSeries = chart.current.map.series.push(new am4maps.MapImageSeries());
      allPlacesSeries.push(placeSeries);
      place = placeSeries.mapImages.template;
      //place.nonScaling = true;
      place.propertyFields.latitude = "latitude";
      place.propertyFields.longitude = "longitude";

      placeSeries.data = seriesMicroStates(series)
        .filter((state) => state.Size === i)
        .map((state) => {
          let ref = references.find((ref) => ref.id === state.A2);
          return {
            latitude: state.Lat,
            longitude: state.Long,
            name: t(state["Full name"]),
            hcenter: "right",
            vcenter: "middle",
            fill: am4core.color(ref ? ref.reference : "#FFFFFF"),
            A2: state.A2,
            id: state.A2,
            reference_text: ref && ref.reference_text ? ref.reference_text : "",
            tooltip: ref && ref.tooltip ? ref.tooltip : ""
          };
        });

      circle = place.createChild(am4core.Circle);
      let extraSize = 1;
      if (drillDown === "Europe") {
        extraSize = 3;
      }
      circle.radius = i * 1.275 * extraSize;

      circle.stroke = am4core.color(strokeColor);
      //circle.strokeWidth = 1;
      if (references && references.length) circle.tooltipHTML = `{tooltip}`;
      else circle.tooltipHTML = `{name}`;

      circle.propertyFields.fill = "fill";
      if (editor)
        circle.events.on("hit", function (ev) {
          // zoom to an object
          console.log(
            chart.current.map.svgPointToGeo(ev.svgPoint).latitude,
            chart.current.map.svgPointToGeo(ev.svgPoint).longitude
          );
          if (!ev.event.ctrlKey && !ev.event.metaKey) setLastState(ev.target.dataItem.dataContext.id);

          // get object info
        });

      /*
      var label = place.createChild(am4core.Label);
      label.text = "{name}";
      label.horizontalCenter = "middle";
      label.padding(0, 0, 0, 0);
      label.fontSize = 3;
      label.zIndex = 1000;
      label.adapter.add("dy", function (dy, target) {
        var circle = target.parent.children.getIndex(0);
        return -circle.pixelRadius;
      });
      label.adapter.add("dx", function (dy, target) {
        var circle = target.parent.children.getIndex(0);
        return circle.pixelRadius * 2;
      });
*/
      let hs2 = circle.states.create("hover");
      hs2.properties.fill = chart.current.map.colors.getIndex(0);
      //circle.tooltip.label.interactionsEnabled = true;
    }

    //circle.hidden = true;
    chart.current = { ...chart.current, microSeries: allPlacesSeries };
  };

  function savePDF() {
    Promise.all([chart.current.exporting.pdfmake, chart.current.exporting.getImage("png")]).then(function (res) {
      // pdfmake and chart snapshots are ready
      // res[0] contains pdfmake instance
      // res[1] contains shapshot of chart 1
      // etc.
      let pdfMake = res[0];
      // pdfmake is ready
      // Create document template
      let doc = {
        pageSize: "A4",
        pageOrientation: "landscape",
        pageMargins: [30, 130, 30, 30],
        content: []
      };
      doc.content.push({
        image: res[1],
        width: 782
      });
      pdfMake.createPdf(doc).download("report.pdf");
    });
  }

  useLayoutEffect(() => {
    console.log("DRILLLLLL", drillDown);
    if (references) loadMap2();
    /* return () => {
      charts.current.forEach((x) => x.dispose());
    };*/
  }, [i18n.language, showMicrostates, lowQ, labels, references, pacificCenter, labelsLocationsRef.current]);

  useLayoutEffect(() => {
    if (drillDown) loadSeries(drillDown);
    //chart.current.map.zoomToGeoPoint({ latitude: 51.61286543362238, longitude: 10.3276988958156 }, 4, true);
    //setTimeout(()=>loadMap(),500);
    /* return () => {
      charts.current.forEach((x) => x.dispose());
    };*/
  }, [drillDown]);

  return (
    <>
      {editor && Object.keys(lastState).length > 0 && (
        <div>
          ASSIGNING: {lastState} | FONT SIZE: {labelFontSizeRef.current} | Rotation: Lat:{" "}
          {chart.current.map.deltaLatitude} - Long: {chart.current.map.deltaLongitude} | Zoom:{" "}
          {chart.current.map.zoomLevel} | Zoom GeoPoint: {chart.current.map.zoomGeoPoint.latitude} /{" "}
          {chart.current.map.zoomGeoPoint.longitude}
        </div>
      )}
      <div className="mapContainer flex-container center">
        {loading && (
          <div>
            <MainSpinner />
            {t("Loading map...")}
          </div>
        )}
        <div id="chartdiv" className="mapDiv"></div>
      </div>
      {editor && (
        <input value={labelFontSizeRef.current} onChange={(e) => setLabelFontSize(e.target.value)} type="number" />
      )}
      {references && references.length > 0 && (
        <>
          <Link
            to="#"
            onClick={(e) => {
              setPacificCenter(!pacificCenter);
            }}
          >
            {t("Change map center")}
          </Link>{" "}
          <Link
            to="#"
            onClick={(e) => {
              setLabels(!labels);
            }}
          >
            {t("Toggle Labels")}
          </Link>{" "}
          |{" "}
          <Link
            to="#"
            onClick={(e) => {
              setLowQ(!lowQ);
            }}
          >
            {t("Toggle quality")}
          </Link>{" "}
          |{" "}
          <Link
            to="#"
            onClick={(e) => {
              labelsLocationsRef.current = {}; //Reset labels for rebuild!
              setShowMicroStates(!showMicrostates);
            }}
          >
            {t("Toggle microstates")}
          </Link>{" "}
          |{" "}
          <Link
            to="#"
            onClick={async (e) =>
              axios("http://localhost:9000/api/playground/testPupe", {
                method: "post",
                //headers: { "Content-Type": "text/xml" },
                data: {
                  references: applyInline(htmlReferences),
                  svg: (await chart.current.exporting.getSVG()).replace("data:;charset=utf-8,", "")
                },

                responseType: "blob" // Important
              }).then((response) => {
                console.log("YEAAAH", applyInline(htmlReferences));
                FileDownload(response.data, "test.pdf");
              })
            }
          >
            {t("Export Map PDF (HQ)")}
          </Link>{" "}
          |{" "}
          <Link to="#" onClick={(e) => chart.current.exporting.export("png")}>
            {t("Export Map PNG (LQ)")}
          </Link>
        </>
      )}
      {editor && (
        <>
          <br />
          <br />
          <br />
          <div>
            {Object.keys(labelsLocations)
              .sort()
              .map((l) => (
                <div>{l + ": " + labelsLocations[l]}</div>
              ))}
          </div>
        </>
      )}
    </>
  );
}

export default MapOld;
