import React, { useEffect, useRef, useState } from "react";
import routes from "../../../config/routes";
import { Accordion, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import scrollIntoView from "scroll-into-view-if-needed";

import ChevronIcon from "../icons/ChevronIcon";

import { useOnClickOutside } from "../../../hooks/clickOutside";
import "./areaSelect.css";
import useWindowSize from "../../../hooks/useWindowSize";

import { useTranslation } from "react-i18next";

export default function AreaSelect({ currentArea = "A1-1" }) {
  const ref = useRef();

  const [activeItem, setActiveItem] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();

  const { t } = useTranslation();

  const size = useWindowSize();

  const hideDropdown = () => {
    setShowDropdown(false);
  };

  const theRoutes = routes();

  useEffect(() => {
    /* if (ref.current && showDropdown)
      setTimeout(
        () => scrollIntoView(ref.current.getElementsByTagName("div")[0], { block: "nearest", scrollMode: "if-needed" }),
        1000
      );
      */
  }, [showDropdown]);

  useOnClickOutside(ref, hideDropdown);

  useEffect(() => {
    theRoutes.forEach(item => {
      if (item.path === location.pathname) setActiveItem(item);
    });
  }, []);

  const secondLevelLinksArea1 = theRoutes.map((item, index) => {
    if (item.isChild && item.parent === "area1")
      return (
        <Dropdown.Item
          tabIndex={1}
          as="button"
          key={item.name + index}
          eventKey={index}
          className={"reactSelect__item rsi__small"}
        >
          <Link to={item.path}>
            {item.subareaNumber && <span className="reactSelect__item_number">{item.subareaNumber}.</span>}
            <span>{item.name}</span>
          </Link>
        </Dropdown.Item>
      );
  });

  const secondLevelLinksArea3 = theRoutes.map((item, index) => {
    if (item.isChild && item.parent === "area3")
      return (
        <Dropdown.Item
          tabIndex={1}
          as="button"
          key={item.name + index}
          eventKey={index}
          className={"reactSelect__item rsi__small"}
        >
          <Link to={item.path}>
            {item.subareaNumber && <span className="reactSelect__item_number">{item.subareaNumber}.</span>}
            <span>{item.name}</span>
          </Link>
        </Dropdown.Item>
      );
  });

  // NOTE: I added properties to the routes to differentiate between top and lower level links
  const topLevelLinks = theRoutes.map((item, index) => {
    if (item.hasChildren && (item.id === "a1" || item.id === "a3")) {
      //
      return (
        <Accordion
          className="reactSelect__item-topLevel-dd"
          onClick={evt => evt.stopPropagation()}
          defaultActiveKey={
            (item.id === "a1" && activeItem.parent === "area1" && index) ||
            (item.id === "a3" && activeItem.parent === "area3" && index) ||
            (item.defaultOpen && size.height > 950 && index)
          }
          key={index}
        >
          <Accordion.Item eventKey={index}>
            <Accordion.Header>
              {item.name}
              <ChevronIcon size={20} />
            </Accordion.Header>
            <Accordion.Body>{item.id === "a1" ? secondLevelLinksArea1 : secondLevelLinksArea3}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      );
    }

    if (item.isTopLevel) {
      return (
        <Dropdown.Item
          tabIndex={-1}
          as="button"
          key={item.name + index}
          eventKey={index}
          className={item.isTopLevel ? "reactSelect__item-topLevel" : "reactSelect__item rsi__small"}
        >
          <Link to={item.path}>
            {item.subareaNumber && <span className="reactSelect__item_number">{item.subareaNumber}.</span>}
            <span>{item.name}</span>
          </Link>
        </Dropdown.Item>
      );
    }
  });

  return (
    <div
      className="dropdownCombo__container selectArea__select"
      ref={ref}
      onClick={() => setShowDropdown(!showDropdown)}
    >
      <DropdownButton
        className={`reactSelect__link-dropdown ${!showDropdown && "isClosed "}`}
        as={ButtonGroup}
        id={`dropdown-variants-primary`}
        variant="default"
        title={activeItem.name ? activeItem.name : "Quick Links"}
      >
        {topLevelLinks}
      </DropdownButton>
      <ChevronIcon size={24} />
    </div>
  );
}
