import { cArea14MapReferences } from "../../config/constants";
import i18n from "../../config/i18n";
import routes from "../../config/routes";
import { citeEntry } from "../../lib/cite";
import { explanSourceLinks } from "../../lib/fnHelper";
import DateDropdownFilter, { ApplyDropdownDateFilter } from "../components/Table/DateDropdownFilter";
import ExpandableTextCell from "../components/Table/ExpandableTextCell";
import GenericDropdownFilter, { ApplyGenericDropdownFilter } from "../components/Table/GenericDropdownFilter";
import JurFilter, { ApplyJurFilter } from "../components/Table/JurFilter";
import SourceText from "../components/Table/SourceText";

const getBgColorClass = (cell, col) => {
  if (!cell.row.original[col]) return "";
  return cell.row.original[col].id === "4"
    ? "rowProtectionYes"
    : cell.row.original[col].id === "1" || cell.row.original[col].id === "2" || cell.row.original[col].id === "3"
    ? "rowProtectionNo"
    : cell.row.original[col].id === "6"
    ? "rowProtectionVaries"
    : "rowProtectionUnclear";
};

export const citeA14 = (entry, setToast) => {
  let lines = [
    "<p>" +
      `${
        entry.motherEntry.subjurisdiction
          ? entry.motherEntry.subjurisdiction.name + " (" + entry.motherEntry.jurisdiction.name + ")"
          : entry.motherEntry.jurisdiction.name
      }: ${routes().find(r => r.parent === "area1" && r.subareaNumber === 3).name}` +
      "</p>"
  ];

  if (entry.explan)
    lines.push("<p>" + entry.barrier_value.name + ": " + explanSourceLinks(entry.explan, entry) + "</p>");

  citeEntry(
    routes().find(r => r.parent === 'area1' && r.subareaNumber === 4),
    entry.motherEntry.subjurisdiction
      ? entry.motherEntry.subjurisdiction.name +
          ' (' +
          entry.motherEntry.jurisdiction.name +
          ')'
      : entry.motherEntry.jurisdiction.name,
    lines.join(''),
    setToast
  )
};

export const columns = () => [
  {
    Header: i18n.t("Jurisdiction"),
    disableGroupBy: true,
    accessor: "motherEntry.jurisdiction.name",
    enableRowSpan: true,
    filter: ApplyJurFilter,
    Filter: ({ column }) => {
      return <JurFilter column={column} search={true} />;
    },
    Cell: ({ value, row }) => {
      return (
        <div
          className={
            "jurCell " +
            (row.original.motherEntry.jurisdiction.id.indexOf("DT") !== -1 || row.original.motherEntry.subjurisdiction
              ? "dependentTerritory"
              : "")
          }
        >
          <div>
            <img
              className="tableFlag"
              src={"/images/flags_svg/" + row.original.motherEntry.jurisdiction.a2_code.toLowerCase() + ".svg"}
              height="16px"
            />
          </div>
          <div>
            {!row.original.motherEntry.subjurisdiction && row.original.motherEntry.jurisdiction.name}
            {row.original.motherEntry.subjurisdiction && "" + row.original.motherEntry.subjurisdiction.name + ""}
          </div>
        </div>
      );
    },
    CellSmall: ({ value, row }) => {
      return <div>{row.original.motherEntry.jurisdiction.name.substring(0, 3)}</div>;
    },
    style: { minWidth: "100px" }
  },
  {
    Header: i18n.t("Legal Barriers"),
    accessor: "barrier_value.name",
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width="330px"
          column={column}
          options={cArea14MapReferences()
            .filter(o => !o.noShow)
            .map(r => ({ id: r.name, value: r.name }))}
        />
      );
    },

    className: "textCenter col125 flexTd",

    classNameFn: c => getBgColorClass(c, "barrier_value"),
    style: { minWidth: "200px" },
    orderByFn: () => {
      console.log("nanananna");
    },
    sortType: (rowA, rowB, id, desc) => {
      if (rowA.original.barrier_value.name > rowB.original.barrier_value.name) return -1;
      if (rowB.original.barrier_value.name > rowA.original.barrier_value.name) return 1;
      return 0;
    }
  },

  {
    Header: i18n.t("Critical Date"),
    accessor: "critical_date_1",
    sortDescFirst: true,
    filter: (rows, columnsId, filterValue) => ApplyDropdownDateFilter(rows, ["critical_date_1"], filterValue),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />;
    },
    Cell: ({ value, row }) => {
      if (value) return value + (row.original.critical_date_2 ? " - " + row.original.critical_date_2 : "");
      else return "-";
    },
    className: "textCenter col80"
  },
  {
    Header: i18n.t("Mechanism"),
    accessor: "mechanism.name",
    sortDescFirst: true,
    className: "textCenter col125",
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          column={column}
          options={[
            { id: i18n.t("Legislative"), value: i18n.t("Legislative") },
            { id: i18n.t("Executive"), value: i18n.t("Executive") },
            { id: i18n.t("Judicial"), value: i18n.t("Judicial") }
          ]}
        />
      );
    }
  },
  {
    Header: i18n.t("Explanation"),
    accessor: "explan",
    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            row={row}
            sources={row.original.motherEntry.sources}
            unik={row.original.id + "_explan"}
            footnotes={row.original.motherEntry.footnotes}
          />
        );
      else return <></>;
    },
    NonExpandableCell: ({ value, row }) => {
      return (
        <SourceText
          text={value}
          skipSources={0}
          sources={row.original.motherEntry.sources}
          unik={row.original.id + "_explan"}
        />
      );
    },
    style: { minWidth: "300px" },
    sortType: (rowA, rowB, id, desc) => {
      if (!rowB.original.general_explan) return null;
      if (rowA.original.general_explan.replace(/[^\w\s]/gi, "") > rowB.original.general_explan.replace(/[^\w\s]/gi, ""))
        return -1;
      if (rowB.original.general_explan.replace(/[^\w\s]/gi, "") > rowA.original.general_explan.replace(/[^\w\s]/gi, ""))
        return 1;
      return 0;
    },
    disableSortBy: true,
  }
];
