import React from 'react'

export default function JurCell({ row, multi = false, extraClasses = '' }) {
  let jurEntry = row.original.motherEntry
    ? row.original.motherEntry
    : row.original
  return (
    <>
      <div
        className={
          'jurCell ' +
          extraClasses +
          ' ' +
          (jurEntry.jurisdiction.id.indexOf('DT') !== -1 ||
          jurEntry.subjurisdiction
            ? 'dependentTerritory'
            : '')
        }
      >
        <div>
          {!jurEntry.subjurisdiction ? (
            <img
              className="tableFlag"
              src={
                '/images/flags_svg/' +
                jurEntry.jurisdiction.a2_code.toLowerCase() +
                '.svg'
              }
              width="22px"
              height="16px"
              alt=""
            />
          ) : (
            <img
              className="tableFlag"
              alt=""
              src={
                '/images/flags_svg/subjurs/' +
                jurEntry.subjurisdiction.id.toUpperCase() +
                '.svg'
              }
              width="22px"
              height="16px"
              onError={e => {
                e.target.onerror = null
                e.target.src =
                  '/images/flags_svg/' +
                  jurEntry.jurisdiction.a2_code.toLowerCase() +
                  '.svg'
              }}
            />
          )}
        </div>
        <div>
          {!jurEntry.subjurisdiction && jurEntry.jurisdiction.name}
          {jurEntry.subjurisdiction && '' + jurEntry.subjurisdiction.name + ''}
        </div>

        {/*jurEntry.jurisdictions && jurEntry.jurisdictions.length > 0 && (
        <div>
          <br />({jurEntry.jurisdictions.map(j => j.name).join(", ")})
        </div>
      )*/}
      </div>
      {multi &&
        jurEntry.jurisdictions.map(jur => (
          <div
            style={{ marginTop: '8px' }}
            className={
              'jurCell ' +
              (jurEntry.jurisdiction.id.indexOf('DT') !== -1 ||
              jurEntry.subjurisdiction
                ? 'dependentTerritory'
                : '')
            }
          >
            <>
              <img
                className="tableFlag"
                src={'/images/flags_svg/' + jur.a2_code.toLowerCase() + '.svg'}
                width="22px"
                height="16px"
                alt=""
              />
              <div>{jur.name}</div>
            </>
          </div>
        ))}
    </>
  )
}
