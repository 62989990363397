import React from "react";
import { css } from "@emotion/react";
import PropagateLoader from "react-spinners/PropagateLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function CircleSpinner() {
  return <PropagateLoader color={"#b0cbce"} loading={true} css={override} size={"2"} />;
}
