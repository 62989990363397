import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cMainRegions } from '../../../config/constants'
import { isRecommendation } from '../../../lib/fnAreasPRHelper'

import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import LoadingBar from '../../components/ui/LoadingBar'

export default function StackedAreaChartArea5({
  references,
  commitee,
  isLoading
}) {
  const [unMember, setUnMember] = useState(true)
  const [topicsN, setTopicsN] = useState(10)
  const { t, i18n } = useTranslation()

  const loading = useRef()
  const loadChart = () => {
    if (loading.current) loading.current.style.display = 'block'
    am4core.options.queue = false

    /* Chart code */
    // Themes begin
    am4core.unuseAllThemes()
    
    // Themes end

    // Create chart instance
    let chart = am4core.create('stackedChartDivX2', am4charts.XYChart)

    chart.events.on('ready', () => {
      if (loading.current) loading.current.style.display = 'none'
    })

    let newReferences = []

    for (let i = 2017; i <= 2021; i++) {
      let tmpObj = {
        year: i + '',
        all: references.filter(
          e =>
            e.date.indexOf(i) !== -1 &&
            (e.recommendation_upr_scope.id === '1' ||
              (e.recommendation_upr_scope.id === '2' ||
                e.recommendation_upr_scope.id === '4') ||
              (e.recommendation_upr_scope.id === '3' ||
                e.recommendation_upr_scope.id === '4'))
        ).length,
        general: references.filter(
          e => e.date.indexOf(i) !== -1 && e.recommendation_upr_scope.id === '1'
        ).length,
        trans: references.filter(
          e =>
            e.date.indexOf(i) !== -1 &&
            (e.recommendation_upr_scope.id === '2' ||
              e.recommendation_upr_scope.id === '4')
        ).length,
        intersex: references.filter(
          e =>
            e.date.indexOf(i) !== -1 &&
            (e.recommendation_upr_scope.id === '3' ||
              e.recommendation_upr_scope.id === '4')
        ).length
      }
      tmpObj = {
        ...tmpObj,
        disabled: tmpObj.all == 0,
        all: tmpObj.all ? tmpObj.all : null
      }
      newReferences.push(tmpObj)
    }

    chart.data = newReferences

    chart.dateFormatter.inputDateFormat = 'yyyy'
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.dateFormats.setKey('day', 'YYYY')

    dateAxis.dateFormats.setKey('week', 'YYYY')

    dateAxis.dateFormats.setKey('month', 'YYYY')

    dateAxis.dateFormats.setKey('year', 'YYYY')
    dateAxis.renderer.minGridDistance = 60
    dateAxis.startLocation = 0
    dateAxis.endLocation = 1
    dateAxis.baseInterval = {
      timeUnit: 'year',
      count: 1
    }

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())

    valueAxis.maxPrecision = 0

    let series = chart.series.push(new am4charts.LineSeries())
    series.dataFields.dateX = 'year'
    series.name = i18n.t('Recommendations')
    series.stroke = '#967CC1'
    series.dataFields.valueY = 'all'
    series.tooltipHTML =
      "<span style='font-size:14px; color:#000000;'>" +
      i18n.t('Recommendations') +
      ': <b>{valueY.value}</b></span>'
    series.tooltipText = '[#000]{valueY.value}[/]'

    series.fillOpacity = 0
    series.strokeWidth = 3
    //series.stacked = true;
    /*
    let series2 = chart.series.push(new am4charts.LineSeries());
    series2.name = "GIE focused";
    series2.fill = "#E08AA2";
    series2.dataFields.dateX = "year";
    series2.dataFields.valueY = "trans";
    series2.tooltipHTML = "<span style='font-size:14px; color:#000000;'>GIE focused: <b>{valueY.value}</b></span>";
    series2.tooltipText = "[#000]{valueY.value}[/]";
    series2.tooltip.background.fill = am4core.color("#FFF");
    series2.tooltip.getFillFromObject = false;
    series2.tooltip.getStrokeFromObject = true;
    series2.tooltip.background.strokeWidth = 3;
    series2.sequencedInterpolation = true;
    series2.fillOpacity = 0.8;
    series2.stacked = true;
    series2.strokeWidth = 0;

    let series3 = chart.series.push(new am4charts.LineSeries());
    series3.name = "SC focused";
    series3.fill = "#96C9DB";
    series3.dataFields.dateX = "year";
    series3.dataFields.valueY = "intersex";
    series3.tooltipHTML =
      "<span style='font-size:14px; color:#000000;'>SC focused: <b>{valueY.value}</b></span>";
    series3.tooltipText = "[#000]{valueY.value}[/]";
    series3.tooltip.background.fill = am4core.color("#FFF");
    series3.tooltip.getFillFromObject = false;
    series3.tooltip.getStrokeFromObject = true;
    series3.tooltip.background.strokeWidth = 3;
    series3.sequencedInterpolation = true;
    series3.fillOpacity = 0.8;
    series3.defaultState.transitionDuration = 1000;
    series3.stacked = true;
    series3.strokeWidth = 0;
*/
    chart.maskBullets = false

    series.tooltip.label.padding(10, 10, 10, 10)
    series.tooltip.label.maxWidth = 350
    series.tooltip.label.wrap = true

    series.tooltip.getFillFromObject = false

    series.tooltip.background.fill = am4core.color('#967CC1')
    series.tooltip.background.stroke = am4core.color('#fff')
    series.tooltip.background.strokeWidth = 1

    let bullet = series.bullets.push(new am4charts.CircleBullet())
    bullet.circle.strokeWidth = 2
    bullet.circle.radius = 4
    bullet.circle.fill = am4core.color('#fff')

    let bullethover = bullet.states.create('hover')
    bullethover.properties.scale = 1.3
    bullet.propertyFields.disabled = 'disabled'
    chart.cursor = new am4charts.XYCursor()
    chart.cursor.xAxis = dateAxis

    // Add a legend
  }
  useEffect(() => {
    !isLoading && loadChart()
  }, [unMember, isLoading])
  return (
    <>
      <div
        className="chartContainer"
        style={{
          minHeight: '400px',
          maxHeight: '400px',
          marginBottom: '10px',
          marginTop: '10px'
        }}
      >
        {isLoading ? (
          <LoadingBar ref={loading} text={t('Loading chart') + '...'} />
        ) : (
          <>
            <LoadingBar ref={loading} text={t('Loading chart') + '...'} />
            <div id="stackedChartDivX2" style={{ minHeight: '400px' }} />
          </>
        )}
      </div>
    </>
  )
}
