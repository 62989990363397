import { useTranslation } from 'react-i18next'
import useGqlUpcomingDeadlines from '../../../gql/useGqlUpcomingDeadlines'
import { i18ndayjs } from '../../../lib/fnDates'
import {
  daysMonthsYears,
  getDateMysqlFormat,
  getNumberOfDays
} from '../../../lib/fnHelper'

const UpcomingDeadlines = ({ jur }) => {
  const { t, i18n } = useTranslation()
  const { upcomingDeadlines, loading, error } = useGqlUpcomingDeadlines(jur.id)

  return upcomingDeadlines &&
    upcomingDeadlines.filter(e => {
      return getDateMysqlFormat(new Date()) <= e.date
    }).length ? (
    <ul className="jh__tab-itemList list-unstyled">
      {upcomingDeadlines
        .filter(e => {
          return getDateMysqlFormat(new Date()) <= e.date
        })
        .sort((a, b) => a.date < b.date)
        .map(up => {
          const days = getNumberOfDays(getDateMysqlFormat(new Date()), up.date)

          return (
            <li key={up.id}>
              <div className="jhil__header">
                <span className="jhil__deadline">
                  {t('Deadline')}: {i18ndayjs(up.date, 'DD MMM YYYY')}
                </span>{' '}
                |{' '}
                <span
                  className={
                    'jhil__timeRemaining ' +
                    (days < 10
                      ? 'upcomingFontRed'
                      : days < 30
                      ? 'upcomingFontGreen'
                      : 'upcomingFontBlue')
                  }
                >
                  {t('{{days}} remaining', {
                    days: daysMonthsYears(up.date)
                  })}
                </span>
              </div>
              <div className="jhil__body">
                <h5 className="jhil__body-title">{up.un_mechanism.name}</h5>
                <p className="jhil__body-content">{up.text}</p>
              </div>
              {up.link1 && (
                <div className="jhil__footer">
                  <p>
                    {[1, 2, 3, 4]
                      .filter(i => up['link' + i])
                      .map(i => (
                        <a
                          target="_blank"
                          href={up['link' + i]}
                          key={up['link' + i]}
                        >
                          {up['link' + i + '_text']}
                        </a>
                      ))}
                  </p>
                </div>
              )}
            </li>
          )
        })}{' '}
    </ul>
  ) : (
    <div className="noUpcuming">
      <p>
        {t(
          'There are currently no upcoming deadlines for {{jur}} in our records. However, please check the website of the Office of the UN High Commissioner for Human Rights (ohchr.org) for official information on deadlines.',
          { jur: jur.name }
        )}
      </p>
    </div>
  )
}

export default UpcomingDeadlines
