import { gql, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const GET_A6_UN_RESOLUTIONS = gql(`      
query GetUnResolutions($lang: String, $jur_id:String) {
  
  unResolutions(lang: $lang, jur_id:$jur_id) {
    id
    date
resolution_number
hostile_resolution{
  id
  resolution_number
}
voting_instrument_type{
  id
  name
}
voting_category{
  id
  name
}
title
short_explan
mandated_report_url
cs_adoption_url
info_url
}
}
`)

const GET_A6_VOTING = gql(`
  query GetEntriesVoting($lang: String, $jur_id:String) {
    entriesVoting(lang: $lang, jur_id:$jur_id) {
    id
    motherEntry {
        topics {
          id
          name
        }
        jurisdiction {
          id
          name
          un_member
          a2_code
          regions{
            id
          }
        }
        subjurisdiction {
          id
          name
        }
        sources {
          id
          order
          section
          original_official_filename
          original_filename
          translated_filename
          enacted
          original_official_language{
            name
            id
          }
          original_official_language2{
            name
            id
          }
          original_language{
            name
            id
          }
          original_language2{
            name
            id
          }
        }
      }

    un_resolution{
      resolution_number
      hostile_resolution{
        id
        resolution_number
      }
      voting_instrument_type{
        id
        name
      }
      voting_category{
        id
        name
      }
      title
      short_explan
      mandated_report_url
      cs_adoption_url
      info_url
    }
    resolution_title
    date
    session
    un_symbol
    voting_vote_type{
          id
          name
        }
    voting_sponsored_type{
          id
          name
        }
    untv_id
    untv_out


  }
}
`)

function useGqlEntriesVoting(jur_id) {
  const { i18n } = useTranslation()
  const entriesVoting = useQuery(GET_A6_VOTING, {
    variables: { jur_id, lang: i18n.language }
  })
  const unResolutions = useQuery(GET_A6_UN_RESOLUTIONS, {
    variables: { jur_id, lang: i18n.language }
  })

  return {
    entriesVoting: entriesVoting.data && entriesVoting.data.entriesVoting,
    unResolutions: unResolutions.data && unResolutions.data.unResolutions
  }
}

export default useGqlEntriesVoting
