import React, { useEffect, useRef, useState } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import { useTranslation } from 'react-i18next'
import UNMemberSwitch from '../../Charts/UNMemberSwitch'
import LoadingBar from '../../ui/LoadingBar'
import {
  cArea1BarChartRegions,
  cAreasValues
} from '../../../../config/constants'

export default function LineChart({ series, references, initialYear = null }) {
  const [unMember, setUnMember] = useState(true)
  const { t, i18n } = useTranslation()
  const loading = useRef()
  const loadChart = () => {
    if (loading.current) loading.current.style.display = 'block'
    am4core.options.queue = false

    /* Chart code */
    // Themes begin
    am4core.unuseAllThemes()

    if (!initialYear)
      initialYear =
        references.reduce((acc, obj) => {
          if (obj.entry.motherEntry.subjurisdiction) return acc
          let tmpYear1 = 3000
          let tmpYear2 = 3000
          let tmpYear3 = 3000
          let tmpYear4 = 3000

          let tmpYearF = 3000
          if (obj.entry.so_critical_date_2)
            tmpYear1 = obj.entry.so_critical_date_2
          else if (obj.entry.so_critical_date_1)
            tmpYear1 = obj.entry.so_critical_date_1

          if (obj.entry.gi_critical_date_2)
            tmpYear2 = obj.entry.gi_critical_date_2
          else if (obj.entry.gi_critical_date_1)
            tmpYear2 = obj.entry.gi_critical_date_1

          if (obj.entry.ge_critical_date_2)
            tmpYear3 = obj.entry.ge_critical_date_2
          else if (obj.entry.ge_critical_date_1)
            tmpYear3 = obj.entry.ge_critical_date_1

          if (obj.entry.sc_critical_date_2)
            tmpYear4 = obj.entry.sc_critical_date_2
          else if (obj.entry.sc_critical_date_1)
            tmpYear4 = obj.entry.sc_critical_date_1

          tmpYearF = Math.min(tmpYear1, tmpYear2, tmpYear3, tmpYear4)

          return tmpYearF < acc ? tmpYearF : acc
        }, 2100) - 1

    // Themes end

    // Create chart instance
    let chart = am4core.create('lineChartDiv', am4charts.XYChart)

    chart.numberFormatter.numberFormat = '#'

    // Themes begin
    chart.maskBullets = false
    chart.events.on('ready', () => {
      if (loading.current) loading.current.style.display = 'none'
    })

    // Themes end

    // Create chart instance

    // Let's cut a hole in our Pie chart the size of 40% the radius
    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.ValueAxis())

    dateAxis.renderer.minGridDistance = 60
    dateAxis.startLocation = 0
    dateAxis.endLocation = 1

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis())

    
    let seriesArray = []

    for (let serie of series) {
      seriesArray.push(createSeries('value' + serie, serie))
    }

    // Create series
    function createSeries(s, name) {
      let serie = new am4charts.LineSeries()
      var series = chart.series.push(serie)
      series.dataFields.valueY = s
      series.dataFields.valueX = 'date'
      series.name = t(name)
      series.fill = am4core.color('#AB97CE')
      series.stroke = am4core.color(
        s === 'valueSO'
          ? '#AB97CE'
          : s === 'valueGI'
          ? '#96C9DB'
          : s === 'valueGE'
          ? '#E08AA2'
          : '#F0C85C'
      )

      var segment = series.segments.template
      segment.interactionsEnabled = true

      var hoverState = segment.states.create('hover')
      hoverState.properties.strokeWidth = 3

      var dimmed = segment.states.create('dimmed')
      dimmed.properties.stroke = am4core.color('#dadada')

      segment.events.on('over', function(event) {
        processOver(event.target.parent.parent.parent)
      })

      segment.events.on('out', function(event) {
        processOut(event.target.parent.parent.parent)
      })

      var data = []

      let eSO = []
      let eGI = []
      let eGE = []
      let eSC = []

      let valueSO = 0
      let valueGI = 0
      let valueGE = 0
      let valueSC = 0

      for (let i = initialYear; i <= new Date().getFullYear(); i++) {
        eSO = references.filter(
          r =>
            r.entry.so_protection_type &&
            r.entry.so_protection_type.id === cAreasValues.a1_protectionYes &&
            (r.entry.so_critical_date_2
              ? r.entry.so_critical_date_2 === i
              : r.entry.so_critical_date_1 === i) &&
            !r.entry.motherEntry.subjurisdiction &&
            (!unMember || r.entry.motherEntry.jurisdiction.un_member)
        )
        eGI = references.filter(
          r =>
            r.entry.gi_protection_type &&
            r.entry.gi_protection_type.id === cAreasValues.a1_protectionYes &&
            (r.entry.gi_critical_date_2
              ? r.entry.gi_critical_date_2 === i
              : r.entry.gi_critical_date_1 === i) &&
            !r.entry.motherEntry.subjurisdiction &&
            (!unMember || r.entry.motherEntry.jurisdiction.un_member)
        )
        eGE = references.filter(
          r =>
            r.entry.ge_protection_type &&
            r.entry.ge_protection_type.id === cAreasValues.a1_protectionYes &&
            (r.entry.ge_critical_date_2
              ? r.entry.ge_critical_date_2 === i
              : r.entry.ge_critical_date_1 === i) &&
            !r.entry.motherEntry.subjurisdiction &&
            (!unMember || r.entry.motherEntry.jurisdiction.un_member)
        )
        eSC = references.filter(
          r =>
            r.entry.sc_protection_type &&
            r.entry.sc_protection_type.id === cAreasValues.a1_protectionYes &&
            (r.entry.sc_critical_date_2
              ? r.entry.sc_critical_date_2 === i
              : r.entry.sc_critical_date_1 === i) &&
            !r.entry.motherEntry.subjurisdiction &&
            (!unMember || r.entry.motherEntry.jurisdiction.un_member)
        )
        valueSO += eSO.length
        valueGI += eGI.length
        valueGE += eGE.length
        valueSC += eSC.length
        var dataItem = { date: i }

        if (
          (s === 'valueSO' && eSO.length) ||
          (s === 'valueGI' && eGI.length) ||
          (s === 'valueGE' && eGE.length) ||
          (s === 'valueSC' && eSC.length)
        ) {
          dataItem['' + s] =
            s === 'valueSO'
              ? valueSO
              : s === 'valueGI'
              ? valueGI
              : s === 'valueGE'
              ? valueGE
              : valueSC
        }

        let jurs = (s === 'valueSO'
          ? eSO
          : s === 'valueGI'
          ? eGI
          : s === 'valueGE'
          ? eGE
          : eSC
        )
          .map(
            r =>
              `<div style="margin-top:2px" ><img
              style="display: inline; height: 12px; width: 15px; vertical-align: text-top; margin-right:5px; margin-left:10px; margin-bottom:4px;"
              class="svgFlag"
              
              src="/images/flags_svg/${r.entry.motherEntry.jurisdiction.a2_code.toLowerCase()}.svg"
            /> ` +
              r.entry.motherEntry.jurisdiction.name +
              `</div>`
          )
          .join('')
        if (
          !data.length ||
          data[data.length - 1][s] !== dataItem['' + s] ||
          new Date().getFullYear() === i
        )
          data.push({
            ...dataItem,
            jurs: jurs,
            yearsText: i,
            color: '#AB97CE',
            disabled:
              !data.length || data[data.length - 1][s] === dataItem['' + s]
          })
      }
      series.strokeWidth = '3'

      series.tooltip.getFillFromObject = false
      series.tooltip.background.fill = am4core.color('#FFF')
      series.tooltip.background.fillOpacity = 1
      series.tooltip.label.fill = am4core.color('#575757')

      series.data = data
      series.tensionX = 1

      series.tooltipHTML = `
      
      <strong>{jurs}</strong>`

      series.tooltip.label.padding(10, 10, 10, 10)
      series.tooltip.label.maxWidth = 350
      series.tooltip.label.wrap = true

      let bullet = series.bullets.push(new am4charts.CircleBullet())
      bullet.circle.strokeWidth = 2
      bullet.circle.radius = 4
      bullet.circle.fill = am4core.color('#fff')

      let bullethover = bullet.states.create('hover')
      bullethover.properties.scale = 1.3
      bullet.propertyFields.disabled = 'disabled'

      return serie
    }

    chart.legend = new am4charts.Legend()
    chart.legend.position = 'bottom'
    chart.legend.scrollable = true

    chart.cursor = new am4charts.XYCursor()
    chart.cursor.snapToSeries = seriesArray

    // setTimeout(function() {
    //   chart.legend.markers.getIndex(0).opacity = 0.3;
    // }, 3000)
    chart.legend.markers.template.states.create(
      'dimmed'
    ).properties.opacity = 0.3
    chart.legend.labels.template.states.create(
      'dimmed'
    ).properties.opacity = 0.3

    chart.legend.itemContainers.template.events.on('over', function(event) {
      processOver(event.target.dataItem.dataContext)
    })

    chart.legend.itemContainers.template.events.on('out', function(event) {
      processOut(event.target.dataItem.dataContext)
    })

    function processOver(hoveredSeries) {
      hoveredSeries.toFront()

      hoveredSeries.segments.each(function(segment) {
        segment.setState('hover')
      })

      hoveredSeries.legendDataItem.marker.setState('default')
      hoveredSeries.legendDataItem.label.setState('default')

      chart.series.each(function(series) {
        if (series != hoveredSeries) {
          series.segments.each(function(segment) {
            segment.setState('dimmed')
          })
          series.bulletsContainer.setState('dimmed')
          series.legendDataItem.marker.setState('dimmed')
          series.legendDataItem.label.setState('dimmed')
        }
      })
    }

    function processOut() {
      chart.series.each(function(series) {
        series.segments.each(function(segment) {
          segment.setState('default')
        })
        series.bulletsContainer.setState('default')
        series.legendDataItem.marker.setState('default')
        series.legendDataItem.label.setState('default')
      })
    }
  }
  useEffect(() => {
    loadChart()
  }, [unMember])
  return (
    <div>
      <LoadingBar
        background="#f4f8f8"
        ref={loading}
        text={t('Loading chart') + '...'}
      />
      <div id="lineChartDiv" style={{ minHeight: '450px' }} />
      <UNMemberSwitch
        unMember={unMember}
        setUnMember={setUnMember}
        extraStyles={{ marginTop: '20px' }}
      />
    </div>
  )
}
