import React, { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'
import {
  cGradientsArea3,
  cTreatyBodies,
  cTreatyBodiesFull
} from '../../config/constants'
import useGqlEntriesTreatyBodies from '../../gql/useGqlEntriesTreatyBodies'
import useGqlEntriesTreatyBodiesComm from '../../gql/useGqlEntriesTreatyBodiesComm'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import MainLayout from '../../layouts/MainLayout'
import { isRecommendation } from '../../lib/fnAreasPRHelper'

import '../../pages/Area.css'

import AreaContent from '../components/Areas/AreaContent'
import BarChartX from '../components/Areas/A3/BarChartX'
import LineChart from '../components/Areas/A3/LineChart'
import MapContainer from '../components/Map/MapContainer'
import microstates from '../components/Map/microstates.json'
import MapTooltip from '../components/StyledMapToolTips'
import TableContainer from '../components/Table/TableContainer'
import MainSpinner from '../components/ui/MainSpinner'
import { citeA3, columns } from '../config/a3columns'
import { citeA3Comm, columnsComm } from '../config/a3columnsComm'
import useGqlTextComponents from '../../gql/useGqlTextComponents'

export default function Area3X({ subarea }) {
  const htmlReferences = useRef()
  const [references, setReferences] = useState([])
  const [mapLegendStepGradient, setMapLegendStepGradient] = useState(null)
  const [drillDown, setDrillDown] = useState('0')
  const [loadingMap, setLoadingMap] = useState(false)
  const { textComponents } = useGqlTextComponents('A3-' + (subarea + 1))
  const { entriesTreatyBodies, loading, error } = useGqlEntriesTreatyBodies(
    subarea
  )
  const {
    entriesTreatyBodiesComm,
    loading: loadingComm,
    error: errorComm
  } = useGqlEntriesTreatyBodiesComm()
  const {
    jurisdictions,
    loading: loadingJur,
    error: errorJur,
    jurisdictionsById
  } = useGqlJurisdictions()
  const { t, i18n } = useTranslation()

  const tooltip = e => {
    return ReactDOMServer.renderToStaticMarkup()
  }

  useEffect(() => {
    if (
      !loading &&
      !error &&
      jurisdictions &&
      entriesTreatyBodies &&
      !loadingMap
    ) {
      setLoadingMap(true)
      let referencesTmp = []
      let maxRecommendations = 0
      const n_by_type_template = {}
      for (let tb of cTreatyBodies()) {
        n_by_type_template[tb.id] = 0
      }
      for (let j of jurisdictions) {
        let jurEntries = []
        jurEntries = entriesTreatyBodies.filter(
          e =>
            e.motherEntry.jurisdiction.id === j.id &&
            isRecommendation(e) &&
            e.treaty_body.id === '' + subarea
        )

        if (maxRecommendations < jurEntries.length)
          maxRecommendations = jurEntries.length

        let n_by_type = {}

        for (let jurEntry of jurEntries) {
          if (!n_by_type[jurEntry.treaty_body.id])
            n_by_type[jurEntry.treaty_body.id] = 1
          else n_by_type[jurEntry.treaty_body.id]++
        }

        if (jurEntries.length)
          referencesTmp.push({
            id: j.a2_code,
            regions: j.regions,
            entry: {
              motherEntry: {
                jurisdiction: j
              },
              n_recommendations: 0
            },

            microstate: microstates.find(ms => ms.A2 === j.a2_code),
            n_recommendations: jurEntries.length,
            reference: '#000',
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <MapTooltip
                entry={{
                  motherEntry: {
                    jurisdiction: j
                  },
                  n_recommendations: jurEntries.length,
                  n_by_type
                }}
                area={'A3X'}
                positive={true}
              />
            )
          })
        else
          referencesTmp.push({
            id: j.a2_code,
            regions: j.regions,
            entry: {
              motherEntry: {
                jurisdiction: j
              },
              n_recommendations: 0
            },
            microstate: microstates.find(ms => ms.A2 === j.a2_code),
            n_recommendations: jurEntries.length,
            reference: '#000',
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <MapTooltip
                entry={{
                  motherEntry: {
                    jurisdiction: j
                  },
                  n_recommendations: 0,
                  n_by_type
                }}
                area={'A3X'}
                positive={true}
              />
            )
          })
      }

      referencesTmp = referencesTmp.map(r => ({
        ...r,
        reference:
          r.n_recommendations === 0
            ? cGradientsArea3[0]
            : cGradientsArea3.slice(1)[
                Math.round(
                  (r.n_recommendations / maxRecommendations) *
                    (cGradientsArea3.length - 2)
                )
              ]
      }))

      setReferences(referencesTmp)
      let labels = []
      labels[0] = 0
      labels[cGradientsArea3.length - 1] = maxRecommendations
      setMapLegendStepGradient({
        colors: cGradientsArea3,
        labels,
        title: t('Number of Recommendations (since 2016)')
      })
      setLoadingMap(false)
    }
  }, [jurisdictions, entriesTreatyBodies, error, loading])

  const options1 = [
    { value: 'ihrt', label: 'International Human Rights Treaties' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]
  const options2 = [
    { value: 'chocolate', label: 'Global' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]

  const options3 = [
    { value: 'HRC', label: 'Human Rights Committee' },
    {
      value: 'CEDW',
      label: 'Committee on the Elimination of Discrim. against Women'
    },
    { value: 'CAT', label: 'Committee against Torture' },
    {
      value: 'cescr',
      label: 'Committee on Economic, Social and Cultural Rights'
    },
    { value: 'crc', label: 'Committee on the Rights of the Child' },
    {
      value: 'crpd',
      label: 'Committee on the Rights of Persons with Disabilities'
    },
    { value: 'ced', label: 'Committee on Enforced Disappearances' },
    {
      value: 'cerd',
      label: 'Committee on the Elimination of Racial Discrimination'
    },
    {
      value: 'cmwspt',
      label:
        'Committe on Migrant Workers and the Subcommittee on Prevention of Torture'
    }
  ]

  const areaInfo = {
    title: `${t('AREA')} 3`,
    subTitle: `${t('Human Rights Treaty Bodies')}`,
    areaName: 'area-3',
    subSubTitle: cTreatyBodiesFull().find(tb => tb.id == subarea).name
  }

  return (
    <MainLayout section="areas">
      
      <div className="area__page area3__page">
        <section className="selectRegion__section">
          
          <MapContainer
            references={references}
            htmlReferences={htmlReferences}
            jurisdictionsById={jurisdictionsById}
            jurisdictions={jurisdictions}
            mapLegendStepGradient={mapLegendStepGradient}
            area="A3"
            currentArea={'A3-' + subarea}
            modalTitle={t('{{commitee}} ({{acronym}})', {
              commitee: cTreatyBodiesFull().find(tb => tb.id == subarea).name,
              acronym: cTreatyBodiesFull().find(tb => tb.id == subarea).acronym
            })}
            modalContent={textComponents.length && textComponents[0].map}
            title={areaInfo.title}
            subTitle={areaInfo.subTitle}
            subSubTitle={areaInfo.subSubTitle}
            areaName={areaInfo.areaName}
          />
        </section>
        <div className="content__container">
          <AreaContent
            areaIntro={'A3-' + (subarea + 1)}
            areaLatest={'A3-' + subarea}
          />

          <div className="mainCTA__2charts">
            <section className="mainCTA__section50">
              <div className="mainGraphic__section">
                <h5>{t('Regional Breakdown of Recommendations')}</h5>
                <h4>
                  {t('SOGIESC Recommendations by the {{commitee}} since 2016', {
                    commitee: cTreatyBodiesFull().find(tb => tb.id == subarea)
                      .name
                  })}
                </h4>
                <div
                  className="fixChartSymetry"
                  style={{ position: 'relative', flexGrow: 1 }}
                >
                  {entriesTreatyBodies && entriesTreatyBodies.length > 0 ? (
                    <BarChartX
                      references={entriesTreatyBodies.filter(
                        e => e.treaty_body.id == subarea
                      )}
                      commitee={
                        cTreatyBodiesFull().find(tb => tb.id == subarea).name
                      }
                    />
                  ) : (
                    <MainSpinner text={t('Loading chart...')} />
                  )}
                </div>
              </div>
            </section>

            <section className="mainCTA__section50">
              <div className="mainGraphic__section">
                <h5>{t('Total Recommendations by Year')}</h5>
                <h4>
                  {t('SOGIESC Recommendations by the {{commitee}} since 2016', {
                    commitee: cTreatyBodiesFull().find(tb => tb.id == subarea)
                      .name
                  })}
                </h4>
                <div
                  className="fixChartSymetry-right"
                  style={{ position: 'relative', flexGrow: 1 }}
                >
                  {entriesTreatyBodies && entriesTreatyBodies.length > 0 ? (
                    <LineChart
                      references={entriesTreatyBodies.filter(
                        e => e.treaty_body.id == subarea
                      )}
                      treatyBodies={cTreatyBodies()}
                      subarea={subarea}
                      commitee={
                        cTreatyBodiesFull().find(tb => tb.id == subarea).name
                      }
                    />
                  ) : (
                    <MainSpinner text={t('Loading chart...')} />
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>

        <section className="table__section">
          <div className="content__container">
            <div
              id="loading"
              style={{ position: 'relative', minHeight: '400px' }}
            >
              {entriesTreatyBodies && entriesTreatyBodies.length ? (
                <TableContainer
                  entries={entriesTreatyBodies.filter(
                    e => e.treaty_body.id == subarea
                  )}
                  t={t}
                  columns={columns(subarea).filter(
                    c => c.accessor !== 'treaty_body.name'
                  )}
                  expandable={false}
                  showSubjurSwitch={false}
                  citeEntry={citeA3}
                  title={t('Area') + '3'}
                  subtitle={
                    t('Human Rights Treaty Bodies') +
                    ' | ' +
                    '<span style="font-weight:600">' +
                    cTreatyBodiesFull().find(tb => tb.id == subarea).name +
                    '</span>'
                  }
                  subtitle2={t('All documents (since 2016)')}
                  subtitle3={
                    !loadingComm &&
                    entriesTreatyBodiesComm &&
                    entriesTreatyBodiesComm.length > 0 &&
                    entriesTreatyBodiesComm.filter(
                      e => e.treaty_body.id == subarea
                    ).length > 0 &&
                    t(
                      'For Individual Communications please see separate chart below.'
                    )
                  }
                  initialSortBy={[
                    {
                      id: 'motherEntry.jurisdiction.name',
                      desc: false
                    }
                  ]}
                />
              ) : (
                <MainSpinner text={t('Loading table...')} />
              )}
            </div>
          </div>

          <div className="content__container">
            {entriesTreatyBodiesComm &&
              entriesTreatyBodiesComm.length > 0 &&
              entriesTreatyBodiesComm.filter(e => e.treaty_body.id == subarea)
                .length > 0 && (
                <div
                  id="loading"
                  style={{ position: 'relative', minHeight: '400px' }}
                >
                  {entriesTreatyBodiesComm &&
                  entriesTreatyBodiesComm.length > 0 &&
                  entriesTreatyBodiesComm.filter(
                    e => e.treaty_body.id == subarea
                  ).length > 0 ? (
                    <>
                      <TableContainer
                        entries={entriesTreatyBodiesComm.filter(
                          e => e.treaty_body.id == subarea
                        )}
                        t={t}
                        columns={columnsComm().filter(
                          c => c.accessor !== 'treaty_body.name'
                        )}
                        expandable={false}
                        paginate={false}
                        citeEntry={citeA3Comm}
                        showSubjurSwitch={false}
                        hideIconHeader={true}
                        subtitle2={t('Individual Communications')}
                        virtual={true}
                        initialSortBy={[
                          {
                            id: 'motherEntry.jurisdiction.name',
                            desc: false
                          }
                        ]}
                      />
                    </>
                  ) : (
                    loadingComm && <MainSpinner text={t('Loading table...')} />
                  )}
                </div>
              )}
          </div>
        </section>
      </div>
    </MainLayout>
  )
}
