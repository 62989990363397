import React, { useRef } from "react";
import MainLayout from "../layouts/MainLayout";

const DocuFetch = () => {
  let submitBtn = useRef();
  return (
    <MainLayout section="account">
      <div className="mainContent page__home" style={{ paddingTop: "130px", paddingLeft: "110px" }}>
        <h2>DocuFetch</h2>
        <form
          action={process.env.REACT_APP_BACKEND_URL + "/downloader/docFetcher"}
          method="post"
          onSubmit={() => {
            submitBtn.current.disabled = true;
          }}
        >
          <textarea
            name="symbols"
            rows="22"
            cols="100"
            style={{ fontSize: "18px" }}
            placeholder="Add Symbols, one per line, like this:&#10;==========================&#10;A/HRC/45/16/Add.2&#10;A/HRC/42/39/Add.1&#10;A/HRC/39/45/Add.2&#10;A/HRC/27/48/Add.4&#10;A/HRC/16/47/Add.2"
          />
          <br />
          <br />
          <input type="submit" value="Request files" ref={submitBtn} style={{ fontSize: "18px" }} />

          <br />
          <br />
          <input type="Button" value="Restart" style={{ fontSize: "18px" }} onClick={() => window.location.reload(false)} />
        </form>
      </div>
    </MainLayout>
  );
};

export default DocuFetch;
