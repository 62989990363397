import i18n from "../../config/i18n";
import routes from "../../config/routes";
import { citeEntry } from "../../lib/cite";
import { explanSourceLinks } from "../../lib/fnHelper";
import DateDropdownFilter, { ApplyDropdownDateFilter } from "../components/Table/DateDropdownFilter";
import ExpandableTextCell from "../components/Table/ExpandableTextCell";
import GenericDropdownFilter, { ApplyGenericDropdownFilter } from "../components/Table/GenericDropdownFilter";
import HelpCell from "../components/Table/HelpCell";
import JurFilter, { ApplyJurFilter } from "../components/Table/JurFilter";
import SourceText from "../components/Table/SourceText";

const getBgColorClass = (cell, col) => {
  if (!cell.row.original[col]) return "";
  return cell.row.original[col].id === "1"
    ? "rowProtectionYes"
    : cell.row.original[col].id === "2"
    ? "rowProtectionNo"
    : cell.row.original[col].id === "3" || cell.row.original[col].id === "4" || cell.row.original[col].id === "5"
    ? "rowProtectionVaries"
    : "rowProtectionUnclear";
};

export const citeA114 = (entry, setToast) => {
  let lines = [
    "<p>" +
      `${
        entry.motherEntry.subjurisdiction
          ? entry.motherEntry.subjurisdiction.name + " (" + entry.motherEntry.jurisdiction.name + ")"
          : entry.motherEntry.jurisdiction.name
      }: ${routes().find(r => r.parent === "area1" && r.subareaNumber === 14).name}` +
      "</p>"
  ];

  if (entry.explan) lines.push("<p>" + explanSourceLinks(entry.explan, entry) + "</p>");

  citeEntry(
    routes().find(r => r.parent === 'area1' && r.subareaNumber === 14),
    entry.motherEntry.subjurisdiction
      ? entry.motherEntry.subjurisdiction.name +
          ' (' +
          entry.motherEntry.jurisdiction.name +
          ')'
      : entry.motherEntry.jurisdiction.name,
    lines.join(''),
    setToast
  )
};

const BannedDropdown = ({ column, restrictions = false }) => (
  <GenericDropdownFilter
    width="125px"
    column={column}
    options={[
      { id: i18n.t("Yes"), value: i18n.t("Yes") },
      { id: i18n.t("No"), value: i18n.t("No") },
      { id: i18n.t("Indirect"), value: i18n.t("Indirect") },
      { id: i18n.t("Limited"), value: i18n.t("Limited") },
      { id: i18n.t("Varies"), value: i18n.t("Varies") },
      { id: i18n.t("Unclear"), value: i18n.t("Unclear") },
      { id: i18n.t("N/A"), value: i18n.t("N/A") }
    ].filter(o => !restrictions || (o.id !== "Limited" && o.id !== "Unclear" && o.id !== "N/A"))}
  />
);

const ValueDropdown = ({ column }) => (
  <GenericDropdownFilter
    width="125px"
    column={column}
    options={[
      { id: i18n.t("Yes"), value: i18n.t("Yes") },
      { id: i18n.t("No"), value: i18n.t("No") },
      { id: i18n.t("Varies"), value: i18n.t("Varies") },
      { id: i18n.t("Unclear"), value: i18n.t("Unclear") },
      { id: i18n.t("N/A"), value: i18n.t("N/A") }
    ]}
  />
);

export const columns = () => [
  {
    Header: i18n.t("Jurisdiction"),
    disableGroupBy: true,
    accessor: "motherEntry.jurisdiction.name",
    enableRowSpan: true,
    filter: ApplyJurFilter,
    Filter: ({ column }) => {
      return <JurFilter column={column} search={true} />;
    },
    Cell: ({ value, row }) => {
      return (
        <div
          className={
            "jurCell " +
            (row.original.motherEntry.jurisdiction.id.indexOf("DT") !== -1 || row.original.motherEntry.subjurisdiction
              ? "dependentTerritory"
              : "")
          }
        >
          <div>
            <img
              className="tableFlag"
              src={"/images/flags_svg/" + row.original.motherEntry.jurisdiction.a2_code.toLowerCase() + ".svg"}
              height="16px"
            />
          </div>
          <div>
            {!row.original.motherEntry.subjurisdiction && row.original.motherEntry.jurisdiction.name}
            {row.original.motherEntry.subjurisdiction && "" + row.original.motherEntry.subjurisdiction.name + ""}
          </div>
        </div>
      );
    },
    CellSmall: ({ value, row }) => {
      return <div>{row.original.motherEntry.jurisdiction.name.substring(0, 3)}</div>;
    },
    style: { minWidth: "100px" }
  },
  {
    Header: i18n.t("Regulations"),
    accessor: "general_ban_type.name",
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return <BannedDropdown column={column} restrictions={true} />;
    },
    classNameFn: c => getBgColorClass(c, "general_ban_type"),
    className: "textCenter col125 flexTd",

    sortType: (rowA, rowB, id, desc) => {
      if (rowA.original.general_ban_type.name > rowB.original.general_ban_type.name) return -1;
      if (rowB.original.general_ban_type.name > rowA.original.general_ban_type.name) return 1;
      return 0;
    }
  },

  {
    Header: i18n.t("Critical Date"),
    accessor: "critical_date_1",
    sortDescFirst: true,
    filter: (rows, columnsId, filterValue) => ApplyDropdownDateFilter(rows, ["critical_date_1"], filterValue),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />;
    },
    Cell: ({ value, row }) => {
      if (value) return value + (row.original.critical_date_2 ? " - " + row.original.critical_date_2 : "");
      else return "-";
    },
    className: "textCenter col80"
  },
  {
    Header: i18n.t("Mechanism"),
    accessor: "mechanism.name",
    className: "textCenter col125",
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          column={column}
          options={[
            { id: i18n.t("Legislative"), value: i18n.t("Legislative") },
            { id: i18n.t("Executive"), value: i18n.t("Executive") },
            { id: i18n.t("Judicial"), value: i18n.t("Judicial") }
          ]}
        />
      );
    }
  },
  {
    Header: i18n.t("Explanation"),
    accessor: "explan",
    disableSortBy: true,
    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            row={row}
            sources={row.original.motherEntry.sources}
            unik={row.original.id + "_explan"}
            footnotes={row.original.motherEntry.footnotes}
          />
        );
      else return <></>;
    },
    NonExpandableCell: ({ value, row }) => {
      return (
        <SourceText
          text={value}
          skipSources={0}
          sources={row.original.motherEntry.sources}
          unik={row.original.id + "_explan"}
        />
      );
    },
    style: { minWidth: "380px" },
    sortType: (rowA, rowB, id, desc) => {
      if (!rowB.original.general_explan) return null;
      if (rowA.original.general_explan.replace(/[^\w\s]/gi, "") > rowB.original.general_explan.replace(/[^\w\s]/gi, ""))
        return -1;
      if (rowB.original.general_explan.replace(/[^\w\s]/gi, "") > rowA.original.general_explan.replace(/[^\w\s]/gi, ""))
        return 1;
      return 0;
    }
  },
  {
    Header: i18n.t("Banned Practice"),
    accessor: "banned_practice",
    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            row={row}
            sources={row.original.motherEntry.sources}
            unik={row.original.id + "_explan"}
            footnotes={row.original.motherEntry.footnotes}
          />
        );
      else return <></>;
    },
    NonExpandableCell: ({ value, row }) => {
      return (
        <SourceText
          text={value}
          skipSources={0}
          sources={row.original.motherEntry.sources}
          unik={row.original.id + "_explan"}
        />
      );
    },
    className: "col190 flexTd",

    sortType: (rowA, rowB, id, desc) => {
      if (!rowB.original.banned_practice) return null;
      if (
        rowA.original.banned_practice.replace(/[^\w\s]/gi, "") > rowB.original.banned_practice.replace(/[^\w\s]/gi, "")
      )
        return -1;
      if (
        rowB.original.banned_practice.replace(/[^\w\s]/gi, "") > rowA.original.banned_practice.replace(/[^\w\s]/gi, "")
      )
        return 1;
      return 0;
    }
  },
  {
    Header: i18n.t("Banned Practitioners"),
    accessor: "banned_practitioners",
    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            row={row}
            sources={row.original.motherEntry.sources}
            unik={row.original.id + "_explan"}
            footnotes={row.original.motherEntry.footnotes}
          />
        );
      else return <></>;
    },
    NonExpandableCell: ({ value, row }) => {
      return (
        <SourceText
          text={value}
          skipSources={0}
          sources={row.original.motherEntry.sources}
          unik={row.original.id + "_explan"}
        />
      );
    },
    className: "col190 flexTd",

    sortType: (rowA, rowB, id, desc) => {
      if (!rowB.original.banned_practitioners) return null;
      if (
        rowA.original.banned_practitioners.replace(/[^\w\s]/gi, "") >
        rowB.original.banned_practitioners.replace(/[^\w\s]/gi, "")
      )
        return -1;
      if (
        rowB.original.banned_practitioners.replace(/[^\w\s]/gi, "") >
        rowA.original.banned_practitioners.replace(/[^\w\s]/gi, "")
      )
        return 1;
      return 0;
    }
  },
  {
    Header: i18n.t("Is Advertisement Banned?"),
    accessor: "ads_ban_type.name",
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return <BannedDropdown column={column} />;
    },
    classNameFn: c => getBgColorClass(c, "ads_ban_type"),
    Cell: ({ value, row }) => {
      if (row.original.explan_ads)
        return (
          <HelpCell mainText={value} helpText={row.original.explan_ads} bg={getBgColorClass({ row }, "ads_ban_type")} />
        );
      else return row.original.ads_ban_type ? row.original.ads_ban_type.name : "";
    },

    className: "textCenter col140 flexTd",

    sortType: (rowA, rowB, id, desc) => {
      if (rowA.original.ads_ban_type.name > rowB.original.ads_ban_type.name) return -1;
      if (rowB.original.ads_ban_type.name > rowA.original.ads_ban_type.name) return 1;
      return 0;
    }
  },
  {
    Header: i18n.t("Are Referrals Banned?"),
    accessor: "ref_ban_type.name",
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return <BannedDropdown column={column} />;
    },

    classNameFn: c => getBgColorClass(c, "ref_ban_type"),
    Cell: ({ value, row }) => {
      if (row.original.explan_ref)
        return (
          <HelpCell mainText={value} helpText={row.original.explan_ref} bg={getBgColorClass({ row }, "ref_ban_type")} />
        );
      else return row.original.ref_ban_type ? row.original.ref_ban_type.name : "";
    },

    className: "textCenter col140 flexTd",

    sortType: (rowA, rowB, id, desc) => {
      if (rowA.original.ref_ban_type.name > rowB.original.ref_ban_type.name) return -1;
      if (rowB.original.ref_ban_type.name > rowA.original.ref_ban_type.name) return 1;
      return 0;
    }
  },
  {
    Header: i18n.t("SO"),
    accessor: "so_value.name",
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return <ValueDropdown column={column} />;
    },

    classNameFn: c => getBgColorClass(c, "so_value"),

    className: "textCenter col125 flexTd",

    sortType: (rowA, rowB, id, desc) => {
      if (rowA.original.so_value.name > rowB.original.so_value.name) return -1;
      if (rowB.original.so_value.name > rowA.original.so_value.name) return 1;
      return 0;
    }
  },
  {
    Header: i18n.t("GI"),
    accessor: "gi_value.name",
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return <ValueDropdown column={column} />;
    },

    classNameFn: c => getBgColorClass(c, "gi_value"),

    className: "textCenter col125 flexTd",

    sortType: (rowA, rowB, id, desc) => {
      if (rowA.original.gi_value.name > rowB.original.gi_value.name) return -1;
      if (rowB.original.gi_value.name > rowA.original.gi_value.name) return 1;
      return 0;
    }
  },
  {
    Header: i18n.t("Minors"),
    accessor: "minors_value.name",
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return <ValueDropdown column={column} />;
    },

    classNameFn: c => getBgColorClass(c, "minors_value"),

    className: "textCenter col125 flexTd",

    sortType: (rowA, rowB, id, desc) => {
      if (rowA.original.minors_value.name > rowB.original.minors_value.name) return -1;
      if (rowB.original.minors_value.name > rowA.original.minors_value.name) return 1;
      return 0;
    }
  },
  {
    Header: i18n.t("Vulnerable Adults"),
    accessor: "vulnerable_adults_value.name",
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return <ValueDropdown column={column} />;
    },

    classNameFn: c => getBgColorClass(c, "vulnerable_adults_value"),

    className: "textCenter col125 flexTd",

    sortType: (rowA, rowB, id, desc) => {
      if (rowA.original.vulnerable_adults_value.name > rowB.original.vulnerable_adults_value.name) return -1;
      if (rowB.original.vulnerable_adults_value.name > rowA.original.vulnerable_adults_value.name) return 1;
      return 0;
    }
  },
  {
    Header: i18n.t("All Adults"),
    accessor: "all_adults_value.name",
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return <ValueDropdown column={column} />;
    },

    classNameFn: c => getBgColorClass(c, "all_adults_value"),

    className: "textCenter col125 flexTd",

    sortType: (rowA, rowB, id, desc) => {
      if (rowA.original.all_adults_value.name > rowB.original.all_adults_value.name) return -1;
      if (rowB.original.all_adults_value.name > rowA.original.all_adults_value.name) return 1;
      return 0;
    }
  }
];
