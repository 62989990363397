import React, {useState} from 'react'

import './MapYearFilter.css';
import SelectYearFilter from "../Dropdown/SelectYearFilter";
import Switch from "../Switch/Switch";

export default function MapYearFilter() {
  const [toggled, setToggled] = useState(false);

  return (
    <div className="mapYearFilterSwitch customColumnHideSwitch">
      <Switch checked={toggled} toggle={() => setToggled(!toggled)} />
      {!toggled ?
        <p className="label">Filter by Year</p>
        :
        <SelectYearFilter />
      }
    </div>
  )
}