import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'
import DOMPurify from 'dompurify'
import './MapInfoModal.css'
import InfoIcon from '../icons/InfoIcon'
import IlgaLogo from '../ui/IlgaLogo'
import DatabaseLogo from '../ui/DatabaseLogo'
import useGqlTextComponents from '../../../gql/useGqlTextComponents'
import TextComponentsEditor from '../../../components/TextComponentsEditor'
import IconEdit from '../../../components/icons/IconEdit'
import { AccountContext } from '../../../context/Accounts'
const MapInfoModal = React.forwardRef(
  ({ title, content, extraClass, area }, ref) => {
    const { textComponents, updateTextComponent } = useGqlTextComponents(area)
    const [editButtons, setEditButtons] = useState(false)
    const [editIntro, setEditIntro] = useState(false)

    const [t, i18n] = useTranslation()

    const saveTextComponent = (area, type, text) => {
      updateTextComponent({
        variables: {
          lang: i18n.language,
          section: area,
          texts: {
            [type]: text
          }
        }
      })
        .then(text => {
          setEditIntro(false)
        })
        .catch(e => console.log('EEE', e))
    }

    const [isOpen, setIsOpen] = useState(false)
    const { currentUser } = useContext(AccountContext)
    const handleClick = () => setIsOpen(!isOpen)
    const handleClose = () => setIsOpen(false)

    return (
      <div className={'MapInfoBtn__container ' + extraClass}>
        <span
          onClick={handleClick}
          className="MapInfoBtn__trigger"
          title={t('Map Details')}
        >
          <InfoIcon color="#A4CAD1" size={32} />
        </span>

        <Modal
          size="lg"
          className="mapInfoModal"
          show={isOpen}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <div className="modal__container">
              <div className="comboLogoContainer">
                <IlgaLogo /> <DatabaseLogo />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body
            onMouseEnter={e => setEditButtons(true)}
            onMouseLeave={e => setEditButtons(false)}
          >
            <div className="modal__container">
              <div className="mapInfoActions__container">
                <InfoIcon size={24} />
                <h4>
                  <strong>{t('Map Details')}:</strong>{' '}
                  {title ? title : 'example'}{' '}
                  {currentUser &&
                    currentUser.email === 'lucas@ilga.org' &&
                    editButtons &&
                    !editIntro && (
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setEditIntro(true)
                        }}
                      >
                        <IconEdit />
                      </span>
                    )}
                </h4>
              </div>
              {!editIntro ? (
                <div
                  className="mapInfoModal__content"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      textComponents &&
                        textComponents.length &&
                        textComponents[0].map
                        ? textComponents[0].map
                        : content
                    )
                  }}
                />
              ) : (
                <TextComponentsEditor
                  variation={'map'}
                  initialText={textComponents.length && textComponents[0].map}
                  cancel={() => setEditIntro(false)}
                  save={text => saveTextComponent(area, 'map', text)}
                />
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
)

export default MapInfoModal
