import React from "react";

export default function JurCell({ jurisdiction }) {
  return (
    <div className={"jurCellIssuing " + (jurisdiction.id.indexOf("DT") !== -1 ? "dependentTerritory" : "")}>
      <div>
        <img
          className="tableFlag"
          src={"/images/flags_svg/" + jurisdiction.a2_code.toLowerCase() + ".svg"}
          height="16px"
          alt=""
        />
      </div>

      <div>{jurisdiction.name}</div>
    </div>
  );
}
