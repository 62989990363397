var map = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [53.0855, 16.6485],
            [52.3279, 16.2938],
            [52.2176, 15.6553],
            [51.3224, 15.226],
            [49.3497, 14.638],
            [48.6685, 14.0504],
            [47.99, 14.0482],
            [47.4078, 13.6615],
            [46.789, 13.4655],
            [45.6574, 13.3385],
            [45.0387, 12.816],
            [43.9299, 12.6165],
            [43.4751, 12.8388],
            [43.2321, 13.2673],
            [43.2826, 13.6395],
            [42.717, 15.6544],
            [42.7992, 16.372],
            [43.1648, 16.6893],
            [43.1912, 17.3595],
            [43.9167, 17.3248],
            [45.1481, 17.4276],
            [45.4065, 17.3199],
            [46.6818, 17.2685],
            [46.9758, 16.9534],
            [47.442, 17.1116],
            [48.172, 18.1567],
            [49.0421, 18.5821],
            [51.9776, 18.996],
            [52.7291, 17.3006],
            [53.0855, 16.6485]
          ]
        ]
      },
      properties: { name: "Yemen", id: "YE", Continent: "Middle East" },
      id: "YE"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [35.4507, 31.4791],
              [34.9506, 31.6022],
              [34.9559, 32.1611],
              [35.5513, 32.3954],
              [35.4507, 31.4791]
            ]
          ],
          [
            [
              [34.245483, 31.2088355],
              [34.4769773, 31.584187],
              [34.1981058, 31.3227134],
              [34.245483, 31.2088355]
            ].reverse()
          
          ]
        ]
      },

      properties: { name: "Palestinian Territories", id: "PS", Continent: "Middle East" },
      id: "PS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.3589, 37.1085],
            [41.7885, 36.5974],
            [41.4167, 36.5148],
            [41.1943, 34.7689],
            [40.6894, 34.3321],
            [38.7734, 33.3719],
            [36.8183, 32.3172],
            [35.7873, 32.7351],
            [35.869, 33.4321],
            [36.5853, 34.2213],
            [36.4328, 34.6137],
            [35.9763, 34.6291],
            [35.7644, 35.5713],
            [36.1274, 35.8315],
            [36.6583, 36.8022],
            [37.4366, 36.6436],
            [38.1915, 36.9015],
            [39.357, 36.6818],
            [40.7057, 37.0975],
            [42.3589, 37.1085]
          ]
        ]
      },
      properties: { name: "Syria", id: "SY", Continent: "Middle East" },
      id: "SY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.5315, 29.0964],
            [47.4333, 28.9896],
            [47.6714, 28.533],
            [48.4422, 28.5427],
            [48.9067, 27.6291],
            [49.986, 26.8288],
            [50.0317, 26.1108],
            [50.8043, 24.7893],
            [51.2684, 24.6074],
            [51.5681, 24.2861],
            [51.5927, 24.0787],
            [52.5551, 22.9331],
            [55.1043, 22.6215],
            [55.1861, 22.7041],
            [55.6409, 22.0019],
            [54.9773, 19.9958],
            [51.9776, 18.996],
            [49.0421, 18.5821],
            [48.172, 18.1567],
            [47.442, 17.1116],
            [46.9758, 16.9534],
            [46.6818, 17.2685],
            [45.4065, 17.3199],
            [45.1481, 17.4276],
            [43.9167, 17.3248],
            [43.1912, 17.3595],
            [43.1648, 16.6893],
            [42.7992, 16.372],
            [42.2938, 17.4351],
            [41.7498, 17.8855],
            [41.2295, 18.6783],
            [40.7593, 19.7558],
            [39.6136, 20.5179],
            [39.0937, 21.3102],
            [38.9878, 21.8819],
            [39.0959, 22.393],
            [38.464, 23.7122],
            [37.9195, 24.1851],
            [37.5429, 24.2919],
            [37.1808, 24.8201],
            [37.1487, 25.2908],
            [35.1808, 28.0347],
            [34.6162, 28.1481],
            [34.9506, 29.3535],
            [36.0158, 29.1905],
            [36.755, 29.8663],
            [37.4695, 29.9951],
            [37.9802, 30.5],
            [36.9584, 31.4919],
            [38.9971, 32.0073],
            [39.1456, 32.1247],
            [40.3695, 31.9392],
            [42.0741, 31.0801],
            [42.8576, 30.4952],
            [44.7165, 29.1935],
            [46.5315, 29.0964]
          ]
        ]
      },
      properties: { name: "Saudi Arabia", id: "SA", Continent: "Middle East" },
      id: "SA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [51.2684, 24.6074],
            [50.8043, 24.7893],
            [50.763, 25.4445],
            [51.0034, 25.9815],
            [51.543, 25.902],
            [51.6019, 25.1479],
            [51.2684, 24.6074]
          ]
        ]
      },
      properties: { name: "Qatar", id: "QA", Continent: "Middle East" },
      id: "QA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [56.388, 24.9792],
            [56.6407, 24.4703],
            [57.2199, 23.9227],
            [58.5782, 23.6437],
            [59.7999, 22.2203],
            [59.3714, 21.4987],
            [58.8955, 21.1124],
            [58.4745, 20.4066],
            [57.8619, 20.2441],
            [57.7151, 19.6068],
            [57.8118, 19.0171],
            [57.1764, 18.9024],
            [56.6552, 18.5873],
            [56.2702, 17.9505],
            [55.4788, 17.8433],
            [55.0643, 17.0391],
            [54.0681, 17.0057],
            [53.0855, 16.6485],
            [52.7291, 17.3006],
            [51.9776, 18.996],
            [54.9773, 19.9958],
            [55.6409, 22.0019],
            [55.1861, 22.7041],
            [55.1997, 23.0346],
            [55.7609, 24.2426],
            [56.388, 24.9792]
          ]
        ]
      },
      properties: { name: "Oman", id: "OM", Continent: "Middle East" },
      id: "OM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.869, 33.4321],
            [35.1088, 33.0836],
            [35.9763, 34.6291],
            [36.4328, 34.6137],
            [36.5853, 34.2213],
            [35.869, 33.4321]
          ]
        ]
      },
      properties: { name: "Lebanon", id: "LB", Continent: "Middle East" },
      id: "LB"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [48.4422, 28.5427],
            [47.6714, 28.533],
            [47.4333, 28.9896],
            [46.5315, 29.0964],
            [47.1142, 29.9612],
            [47.9786, 29.9828],
            [47.9698, 29.6167],
            [48.4422, 28.5427]
          ]
        ]
      },
      properties: { name: "Kuwait", id: "KW", Continent: "Middle East" },
      id: "KW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [39.1456, 32.1247],
            [38.9971, 32.0073],
            [36.9584, 31.4919],
            [37.9802, 30.5],
            [37.4695, 29.9951],
            [36.755, 29.8663],
            [36.0158, 29.1905],
            [34.9506, 29.3535],
            [34.9734, 29.5552],
            [35.4507, 31.4791],
            [35.5513, 32.3954],
            [35.7873, 32.7351],
            [36.8183, 32.3172],
            [38.7734, 33.3719],
            [39.1456, 32.1247]
          ]
        ]
      },
      properties: { name: "Jordan", id: "JO", Continent: "Middle East" },
      id: "JO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.7873, 32.7351],
            [35.5513, 32.3954],
            [34.9559, 32.1611],
            [34.9506, 31.6022],
            [35.4507, 31.4791],
            [34.9734, 29.5552],
            [34.2453, 31.2084],
            [34.4777, 31.585],
            [35.1088, 33.0836],
            [35.869, 33.4321],
            [35.7873, 32.7351]
          ]
        ]
      },
      properties: { name: "Israel", id: "IL", Continent: "Middle East" },
      id: "IL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [44.7653, 37.1423],
            [45.3617, 36.0156],
            [45.7233, 35.8363],
            [46.1338, 35.1275],
            [45.6781, 34.7983],
            [45.3968, 33.9708],
            [46.1457, 33.2295],
            [46.1127, 32.9579],
            [47.3713, 32.424],
            [47.8296, 31.7942],
            [47.6793, 31.0023],
            [48.0151, 30.4653],
            [48.5468, 29.9626],
            [47.9786, 29.9828],
            [47.1142, 29.9612],
            [46.5315, 29.0964],
            [44.7165, 29.1935],
            [42.8576, 30.4952],
            [42.0741, 31.0801],
            [40.3695, 31.9392],
            [39.1456, 32.1247],
            [38.7734, 33.3719],
            [40.6894, 34.3321],
            [41.1943, 34.7689],
            [41.4167, 36.5148],
            [41.7885, 36.5974],
            [42.3589, 37.1085],
            [42.7746, 37.3718],
            [44.7653, 37.1423]
          ]
        ]
      },
      properties: { name: "Iraq", id: "IQ", Continent: "Middle East" },
      id: "IQ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [48.5468, 29.9626],
            [48.0151, 30.4653],
            [47.6793, 31.0023],
            [47.8296, 31.7942],
            [47.3713, 32.424],
            [46.1127, 32.9579],
            [46.1457, 33.2295],
            [45.3968, 33.9708],
            [45.6781, 34.7983],
            [46.1338, 35.1275],
            [45.7233, 35.8363],
            [45.3617, 36.0156],
            [44.7653, 37.1423],
            [44.5614, 37.7449],
            [44.2112, 37.9083],
            [44.4309, 38.357],
            [44.0231, 39.3774],
            [44.5873, 39.7685],
            [45.4799, 39.0065],
            [46.5548, 38.9041],
            [47.7729, 39.6486],
            [48.2423, 38.9788],
            [48.8404, 38.4374],
            [49.0812, 37.6675],
            [50.1306, 37.4069],
            [51.1185, 36.7424],
            [52.1903, 36.6216],
            [54.0171, 36.9525],
            [53.9143, 37.3436],
            [54.7453, 37.5018],
            [55.0753, 37.9022],
            [56.4407, 38.2493],
            [57.1939, 38.2164],
            [57.4238, 37.9479],
            [58.4358, 37.6385],
            [59.2409, 37.5207],
            [60.3413, 36.6379],
            [61.1196, 36.6427],
            [61.2822, 35.5533],
            [61.08, 34.8554],
            [60.4854, 34.0947],
            [60.5606, 33.1376],
            [60.8291, 32.2495],
            [60.8541, 31.4831],
            [61.6601, 31.3825],
            [61.7844, 30.8322],
            [60.8436, 29.8584],
            [61.3182, 29.3724],
            [61.6227, 28.7914],
            [62.3531, 28.4148],
            [62.7398, 28.0022],
            [62.7631, 27.2503],
            [63.1665, 27.2525],
            [63.1679, 26.6658],
            [62.4392, 26.5608],
            [61.8697, 26.2422],
            [61.6153, 25.2859],
            [61.4118, 25.1022],
            [59.4563, 25.4815],
            [57.796, 25.6533],
            [57.3346, 25.7917],
            [56.9825, 26.9053],
            [56.3559, 27.2002],
            [55.6501, 26.9778],
            [54.8956, 26.5564],
            [53.7056, 26.7256],
            [52.6918, 27.3237],
            [52.4759, 27.6163],
            [51.6665, 27.8453],
            [51.2789, 28.1314],
            [50.65, 29.4198],
            [49.983, 30.2091],
            [49.5549, 30.0289],
            [48.919, 30.1208],
            [48.5468, 29.9626]
          ]
        ]
      },
      properties: { name: "Iran", id: "IR", Continent: "Middle East" },
      id: "IR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [50.6074, 25.8831],
            [50.4659, 25.9657],
            [50.4523, 26.1907],
            [50.61, 26.1244],
            [50.6074, 25.8831]
          ]
        ]
      },
      properties: { name: "Bahrain", id: "BH", Continent: "Middle East" },
      id: "BH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [56.2979, 25.6507],
            [56.388, 24.9792],
            [55.7609, 24.2426],
            [55.1997, 23.0346],
            [55.1861, 22.7041],
            [55.1043, 22.6215],
            [52.5551, 22.9331],
            [51.5927, 24.0787],
            [51.5681, 24.2861],
            [51.906, 23.9856],
            [52.6482, 24.1547],
            [53.8932, 24.077],
            [54.3972, 24.2778],
            [54.7471, 24.8104],
            [55.941, 25.7939],
            [56.0804, 26.0624],
            [56.2979, 25.6507]
          ]
        ]
      },
      properties: { name: "United Arab Emirates", id: "AE", Continent: "Middle East" },
      id: "AE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.8714, 21.997],
            [33.2622, 21.9957],
            [31.4345, 21.9957],
            [28.8005, 21.9949],
            [24.9803, 21.9957],
            [24.9803, 24.0172],
            [24.9803, 27.1606],
            [24.9803, 29.1817],
            [24.7113, 30.1317],
            [24.9736, 30.7764],
            [24.8528, 31.335],
            [25.1503, 31.6549],
            [27.2479, 31.378],
            [29.0725, 30.8305],
            [30.3952, 31.4576],
            [31.1942, 31.5876],
            [32.102, 31.0924],
            [33.6664, 31.1302],
            [34.2127, 31.2924],
            [34.904, 29.4774],
            [34.4, 28.0162],
            [33.547, 27.8985],
            [33.9592, 26.6487],
            [35.1941, 24.4751],
            [35.6246, 24.066],
            [35.5227, 23.4424],
            [35.6976, 22.9462],
            [36.8714, 21.997]
          ]
        ]
      },
      properties: { name: "Egypt", id: "EG", Continent: "Africa" },
      id: "EG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.2197, 35.1042],
            [-1.7956, 34.7522],
            [-1.6792, 33.3187],
            [-1.0657, 32.4683],
            [-1.2753, 32.0891],
            [-2.4484, 32.13],
            [-3.8268, 31.6615],
            [-3.9852, 30.9136],
            [-4.9682, 30.4653],
            [-5.4488, 29.9569],
            [-6.5008, 29.8092],
            [-7.6852, 29.3495],
            [-8.6599, 28.7185],
            [-8.6834, 27.6563],
            [-13.1774, 27.6519],
            [-12.9489, 27.9143],
            [-11.4302, 28.3823],
            [-10.4865, 29.0648],
            [-9.6671, 30.1094],
            [-9.8754, 30.718],
            [-9.8086, 31.4246],
            [-9.2459, 32.5725],
            [-8.5962, 33.1872],
            [-6.901, 33.9691],
            [-6.3532, 34.7759],
            [-5.9249, 35.7858],
            [-5.3974, 35.9299],
            [-5.1053, 35.4676],
            [-4.3299, 35.1613],
            [-3.6932, 35.2799],
            [-2.2197, 35.1042]
          ]
        ]
      },
      properties: { name: "Morocco", id: "MA", Continent: "Africa" },
      id: "MA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.6834, 27.6563],
            [-8.6834, 27.2859],
            [-8.682, 25.9957],
            [-12.0164, 25.9957],
            [-12.0235, 23.4675],
            [-13.0316, 22.9999],
            [-13.0162, 21.3339],
            [-16.9647, 21.3291],
            [-17.0482, 20.8061],
            [-16.9308, 21.8999],
            [-16.5144, 22.3332],
            [-16.2104, 23.0983],
            [-15.5863, 24.073],
            [-15.0388, 24.5489],
            [-14.4139, 26.2536],
            [-13.5758, 26.7348],
            [-13.1774, 27.6519],
            [-8.6834, 27.6563]
          ]
        ]
      },
      properties: { name: "Western Sahara", id: "EH", Continent: "Africa" },
      id: "EH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-12.2807, 14.8089],
            [-12.8584, 15.2427],
            [-13.4095, 16.0592],
            [-13.8685, 16.1479],
            [-14.3, 16.5804],
            [-15.1128, 16.6449],
            [-16.1684, 16.5474],
            [-16.5353, 15.8381],
            [-16.5357, 16.2868],
            [-16.079, 17.5459],
            [-16.0849, 18.521],
            [-16.5147, 19.3621],
            [-16.2106, 20.2278],
            [-16.4298, 20.6523],
            [-17.0482, 20.8061],
            [-16.9647, 21.3291],
            [-13.0162, 21.3339],
            [-13.0316, 22.9999],
            [-12.0235, 23.4675],
            [-12.0164, 25.9957],
            [-8.682, 25.9957],
            [-8.6834, 27.2859],
            [-6.9908, 26.2905],
            [-4.8225, 24.9959],
            [-6.594, 24.9946],
            [-6.1688, 21.2855],
            [-5.9696, 19.5449],
            [-5.6286, 16.5685],
            [-5.36, 16.2829],
            [-5.5125, 15.4967],
            [-9.1769, 15.4962],
            [-10.6965, 15.4228],
            [-10.8956, 15.1504],
            [-11.5027, 15.6368],
            [-11.8727, 14.9952],
            [-12.2807, 14.8089]
          ]
        ]
      },
      properties: { name: "Mauritania", id: "MR", Continent: "Africa" },
      id: "MR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.2275, 19.1428],
            [4.2346, 16.9965],
            [3.8769, 15.7555],
            [3.5042, 15.356],
            [1.3003, 15.2721],
            [0.9602, 14.9869],
            [0.2175, 14.9113],
            [-0.7605, 15.0475],
            [-1.7677, 14.4864],
            [-2.4572, 14.2741],
            [-3.5758, 13.1939],
            [-4.3286, 13.1188],
            [-4.4288, 12.3379],
            [-5.2882, 11.8276],
            [-5.5235, 10.4258],
            [-6.0346, 10.1947],
            [-6.2611, 10.7242],
            [-7.1049, 10.2034],
            [-7.6612, 10.4271],
            [-7.9908, 10.1626],
            [-8.2316, 10.4381],
            [-8.4076, 11.3864],
            [-8.822, 11.6734],
            [-9.0431, 12.4024],
            [-9.8207, 12.0425],
            [-10.2749, 12.2126],
            [-10.6774, 11.8997],
            [-11.3894, 12.4043],
            [-11.3902, 12.9416],
            [-12.0544, 13.6329],
            [-12.0193, 14.2064],
            [-12.2807, 14.8089],
            [-11.8727, 14.9952],
            [-11.5027, 15.6368],
            [-10.8956, 15.1504],
            [-10.6965, 15.4228],
            [-9.1769, 15.4962],
            [-5.5125, 15.4967],
            [-5.36, 16.2829],
            [-5.6286, 16.5685],
            [-5.9696, 19.5449],
            [-6.1688, 21.2855],
            [-6.594, 24.9946],
            [-4.8225, 24.9959],
            [-3.2579, 23.9811],
            [-1.2931, 22.6962],
            [1.1456, 21.1023],
            [1.6853, 20.3785],
            [3.2036, 19.7897],
            [3.4005, 18.9885],
            [4.2275, 19.1428]
          ]
        ]
      },
      properties: { name: "Mali", id: "ML", Continent: "Africa" },
      id: "ML"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.5763, 36.9371],
            [8.2305, 36.5456],
            [8.3944, 35.2039],
            [8.2459, 34.7337],
            [7.5138, 34.0807],
            [7.7629, 33.233],
            [8.3333, 32.5439],
            [9.0439, 32.0724],
            [9.519, 30.2293],
            [9.8424, 28.9668],
            [9.9163, 27.7856],
            [9.7475, 27.3307],
            [9.8592, 26.5519],
            [9.4482, 26.0672],
            [10.001, 25.332],
            [10.2555, 24.5911],
            [11.5075, 24.3143],
            [11.9676, 23.518],
            [10.4146, 22.6017],
            [7.4817, 20.8729],
            [5.8368, 19.479],
            [4.2275, 19.1428],
            [3.4005, 18.9885],
            [3.2036, 19.7897],
            [1.6853, 20.3785],
            [1.1456, 21.1023],
            [-1.2931, 22.6962],
            [-3.2579, 23.9811],
            [-4.8225, 24.9959],
            [-6.9908, 26.2905],
            [-8.6834, 27.2859],
            [-8.6834, 27.6563],
            [-8.6599, 28.7185],
            [-7.6852, 29.3495],
            [-6.5008, 29.8092],
            [-5.4488, 29.9569],
            [-4.9682, 30.4653],
            [-3.9852, 30.9136],
            [-3.8268, 31.6615],
            [-2.4484, 32.13],
            [-1.2753, 32.0891],
            [-1.0657, 32.4683],
            [-1.6792, 33.3187],
            [-1.7956, 34.7522],
            [-2.2197, 35.1042],
            [-1.9132, 35.0945],
            [-0.9176, 35.6684],
            [0.0479, 35.9004],
            [1.2573, 36.5196],
            [2.5932, 36.601],
            [3.7788, 36.8962],
            [4.7579, 36.8967],
            [5.1957, 36.677],
            [6.4867, 37.0857],
            [6.9275, 36.9195],
            [8.5763, 36.9371]
          ]
        ]
      },
      properties: { name: "Algeria", id: "DZ", Continent: "Africa" },
      id: "DZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.9792, 22.9959],
            [15.1822, 21.5233],
            [15.9491, 20.3034],
            [15.735, 19.9039],
            [15.4745, 16.9086],
            [14.3679, 15.7498],
            [13.4481, 14.3805],
            [13.6063, 13.7046],
            [12.6549, 13.3266],
            [12.4633, 13.0937],
            [11.4121, 13.3539],
            [10.1847, 13.2699],
            [9.6161, 12.8103],
            [8.7504, 12.9083],
            [7.7884, 13.3376],
            [6.9372, 13.008],
            [6.3861, 13.6039],
            [5.4919, 13.8729],
            [4.1476, 13.4576],
            [3.948, 12.7751],
            [3.6466, 12.5299],
            [3.5956, 11.6962],
            [2.8784, 12.3678],
            [2.3889, 11.8971],
            [1.9564, 12.7074],
            [1.5653, 12.6354],
            [0.987, 13.0418],
            [0.9778, 13.5516],
            [0.6183, 13.7037],
            [0.1639, 14.4973],
            [0.2175, 14.9113],
            [0.9602, 14.9869],
            [1.3003, 15.2721],
            [3.5042, 15.356],
            [3.8769, 15.7555],
            [4.2346, 16.9965],
            [4.2275, 19.1428],
            [5.8368, 19.479],
            [7.4817, 20.8729],
            [10.4146, 22.6017],
            [11.9676, 23.518],
            [13.4815, 23.18],
            [14.2308, 22.6184],
            [14.9792, 22.9959]
          ]
        ]
      },
      properties: { name: "Niger", id: "NE", Continent: "Africa" },
      id: "NE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.8599, 10.9193],
            [22.4938, 10.9962],
            [21.7257, 10.3665],
            [20.7733, 9.4059],
            [20.3423, 9.1272],
            [19.1457, 9.0161],
            [19.1088, 8.6562],
            [18.4552, 8.0321],
            [17.6497, 7.9838],
            [16.7849, 7.5509],
            [16.3788, 7.6836],
            [15.4802, 7.5237],
            [15.1163, 8.5573],
            [14.3323, 9.2033],
            [13.9772, 9.6919],
            [14.2431, 9.9797],
            [15.5413, 9.9604],
            [15.1321, 10.6482],
            [15.0812, 11.8452],
            [14.516, 12.9794],
            [14.0638, 13.0787],
            [13.6063, 13.7046],
            [13.4481, 14.3805],
            [14.3679, 15.7498],
            [15.4745, 16.9086],
            [15.735, 19.9039],
            [15.9491, 20.3034],
            [15.1822, 21.5233],
            [14.9792, 22.9959],
            [15.9842, 23.445],
            [19.1896, 21.8621],
            [22.0641, 20.4427],
            [23.9805, 19.497],
            [23.9836, 15.7801],
            [23.1051, 15.7023],
            [22.5097, 14.1273],
            [22.1076, 13.7301],
            [22.2284, 13.3293],
            [21.8254, 12.7905],
            [22.3523, 12.6604],
            [22.5562, 11.6695],
            [22.9227, 11.3447],
            [22.8599, 10.9193]
          ]
        ]
      },
      properties: { name: "Chad", id: "TD", Continent: "Africa" },
      id: "TD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.1503, 31.6549],
            [24.8528, 31.335],
            [24.9736, 30.7764],
            [24.7113, 30.1317],
            [24.9803, 29.1817],
            [24.9803, 27.1606],
            [24.9803, 24.0172],
            [24.9803, 21.9957],
            [24.9671, 19.9953],
            [23.9805, 19.9958],
            [23.9805, 19.497],
            [22.0641, 20.4427],
            [19.1896, 21.8621],
            [15.9842, 23.445],
            [14.9792, 22.9959],
            [14.2308, 22.6184],
            [13.4815, 23.18],
            [11.9676, 23.518],
            [11.5075, 24.3143],
            [10.2555, 24.5911],
            [10.001, 25.332],
            [9.4482, 26.0672],
            [9.8592, 26.5519],
            [9.7475, 27.3307],
            [9.9163, 27.7856],
            [9.8424, 28.9668],
            [9.519, 30.2293],
            [10.2164, 30.783],
            [10.1149, 31.4637],
            [10.8264, 32.0807],
            [11.5049, 32.4139],
            [11.5044, 33.182],
            [12.2796, 32.8586],
            [13.2833, 32.9148],
            [15.1765, 32.391],
            [15.4965, 31.6567],
            [16.1231, 31.2642],
            [17.3492, 31.0814],
            [18.1902, 30.7774],
            [18.9365, 30.2904],
            [19.7134, 30.4886],
            [20.1511, 31.0783],
            [19.9261, 31.8175],
            [20.6209, 32.5799],
            [21.6356, 32.9372],
            [23.0907, 32.6186],
            [23.2866, 32.2139],
            [24.6841, 32.0162],
            [25.1503, 31.6549]
          ]
        ]
      },
      properties: { name: "Libya", id: "LY", Continent: "Africa" },
      id: "LY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.5044, 33.182],
            [11.5049, 32.4139],
            [10.8264, 32.0807],
            [10.1149, 31.4637],
            [10.2164, 30.783],
            [9.519, 30.2293],
            [9.0439, 32.0724],
            [8.3333, 32.5439],
            [7.7629, 33.233],
            [7.5138, 34.0807],
            [8.2459, 34.7337],
            [8.3944, 35.2039],
            [8.2305, 36.5456],
            [8.5763, 36.9371],
            [9.6877, 37.3405],
            [10.5715, 36.8796],
            [10.5908, 35.8872],
            [11.1199, 35.2404],
            [10.1184, 34.2802],
            [10.1588, 33.85],
            [11.5044, 33.182]
          ]
        ]
      },
      properties: { name: "Tunisia", id: "TN", Continent: "Africa" },
      id: "TN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.8714, 21.997],
            [37.1505, 21.1036],
            [37.2484, 19.5822],
            [37.4712, 18.8202],
            [38.6094, 18.0051],
            [38.2536, 17.5845],
            [37.4111, 17.0615],
            [37.009, 17.0589],
            [36.9135, 16.2965],
            [36.4267, 15.1319],
            [36.5243, 14.2565],
            [36.1076, 12.7268],
            [35.5961, 12.5374],
            [35.1123, 11.8167],
            [34.9312, 10.8648],
            [34.2756, 10.5282],
            [34.0779, 9.4612],
            [33.892, 10.1991],
            [33.172, 10.8503],
            [33.0732, 11.5916],
            [33.199, 12.2174],
            [32.3354, 11.716],
            [32.4039, 11.0577],
            [31.2248, 9.7991],
            [30.7397, 9.7429],
            [30.0033, 10.2773],
            [28.8444, 9.3259],
            [28.049, 9.3285],
            [27.886, 9.5996],
            [26.5513, 9.5258],
            [25.7981, 10.4205],
            [25.1037, 10.312],
            [24.7851, 9.7745],
            [24.532, 8.8869],
            [24.1471, 8.6654],
            [23.5376, 8.8161],
            [23.6461, 9.8229],
            [22.8599, 10.9193],
            [22.9227, 11.3447],
            [22.5562, 11.6695],
            [22.3523, 12.6604],
            [21.8254, 12.7905],
            [22.2284, 13.3293],
            [22.1076, 13.7301],
            [22.5097, 14.1273],
            [23.1051, 15.7023],
            [23.9836, 15.7801],
            [23.9805, 19.497],
            [23.9805, 19.9958],
            [24.9671, 19.9953],
            [24.9803, 21.9957],
            [28.8005, 21.9949],
            [31.4345, 21.9957],
            [33.2622, 21.9957],
            [36.8714, 21.997]
          ]
        ]
      },
      properties: { name: "Sudan", id: "SD", Continent: "Africa" },
      id: "SD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.0779, 9.4612],
            [34.0945, 8.5823],
            [33.234, 8.3964],
            [33.0126, 7.9517],
            [33.666, 7.6713],
            [34.7107, 6.6605],
            [35.2684, 5.4925],
            [33.9758, 4.2203],
            [33.4894, 3.7549],
            [32.8381, 3.7984],
            [32.0994, 3.529],
            [31.7984, 3.8024],
            [31.1523, 3.7856],
            [30.8386, 3.4904],
            [30.1948, 3.9821],
            [29.6767, 4.5868],
            [29.1511, 4.3882],
            [28.1923, 4.3503],
            [27.4034, 5.1093],
            [27.0835, 5.7768],
            [26.5139, 6.0691],
            [26.3619, 6.6355],
            [25.381, 7.3334],
            [24.8537, 8.1376],
            [24.2916, 8.2914],
            [24.1471, 8.6654],
            [24.532, 8.8869],
            [24.7851, 9.7745],
            [25.1037, 10.312],
            [25.7981, 10.4205],
            [26.5513, 9.5258],
            [27.886, 9.5996],
            [28.049, 9.3285],
            [28.8444, 9.3259],
            [30.0033, 10.2773],
            [30.7397, 9.7429],
            [31.2248, 9.7991],
            [32.4039, 11.0577],
            [32.3354, 11.716],
            [33.199, 12.2174],
            [33.0732, 11.5916],
            [33.172, 10.8503],
            [33.892, 10.1991],
            [34.0779, 9.4612]
          ]
        ]
      },
      properties: { name: "South Sudan", id: "SS", Continent: "Africa" },
      id: "SS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.6094, 18.0051],
            [39.1425, 16.7293],
            [39.5064, 15.5318],
            [40.2042, 15.0141],
            [41.1764, 14.6204],
            [41.6583, 13.9831],
            [42.2451, 13.5877],
            [42.7961, 12.8643],
            [43.1165, 12.7083],
            [42.3783, 12.4666],
            [40.7699, 14.1444],
            [40.2213, 14.431],
            [39.074, 14.6283],
            [38.5045, 14.4244],
            [37.8844, 14.8524],
            [37.5711, 14.1493],
            [37.2572, 14.4538],
            [36.5243, 14.2565],
            [36.4267, 15.1319],
            [36.9135, 16.2965],
            [37.009, 17.0589],
            [37.4111, 17.0615],
            [38.2536, 17.5845],
            [38.6094, 18.0051]
          ]
        ]
      },
      properties: { name: "Eritrea", id: "ER", Continent: "Africa" },
      id: "ER"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.3783, 12.4666],
            [41.7665, 11.589],
            [41.7982, 10.9804],
            [42.9226, 10.9993],
            [42.6594, 10.6214],
            [43.483, 9.3794],
            [44.023, 8.9857],
            [46.9784, 7.9969],
            [47.9781, 7.9969],
            [45.9348, 5.997],
            [44.9406, 4.912],
            [43.9889, 4.9506],
            [43.1257, 4.6448],
            [42.7918, 4.2923],
            [41.8839, 3.9781],
            [41.2207, 3.9439],
            [40.7654, 4.273],
            [39.8426, 3.8516],
            [39.4946, 3.4561],
            [38.0865, 3.649],
            [36.9057, 4.4114],
            [36.0817, 4.4497],
            [35.7632, 4.8078],
            [35.7447, 5.3444],
            [35.2684, 5.4925],
            [34.7107, 6.6605],
            [33.666, 7.6713],
            [33.0126, 7.9517],
            [33.234, 8.3964],
            [34.0945, 8.5823],
            [34.0779, 9.4612],
            [34.2756, 10.5282],
            [34.9312, 10.8648],
            [35.1123, 11.8167],
            [35.5961, 12.5374],
            [36.1076, 12.7268],
            [36.5243, 14.2565],
            [37.2572, 14.4538],
            [37.5711, 14.1493],
            [37.8844, 14.8524],
            [38.5045, 14.4244],
            [39.074, 14.6283],
            [40.2213, 14.431],
            [40.7699, 14.1444],
            [42.3783, 12.4666]
          ]
        ]
      },
      properties: { name: "Ethiopia", id: "ET", Continent: "Africa" },
      id: "ET"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [41.5327, -1.6956],
            [40.9791, -0.8703],
            [40.9644, 2.8145],
            [41.8839, 3.9781],
            [42.7918, 4.2923],
            [43.1257, 4.6448],
            [43.9889, 4.9506],
            [44.9406, 4.912],
            [45.9348, 5.997],
            [47.9781, 7.9969],
            [46.9784, 7.9969],
            [44.023, 8.9857],
            [43.483, 9.3794],
            [42.6594, 10.6214],
            [42.9226, 10.9993],
            [43.2462, 11.4999],
            [43.8531, 10.784],
            [44.3866, 10.4302],
            [44.9428, 10.4364],
            [45.8169, 10.8362],
            [46.5654, 10.7457],
            [47.4051, 11.1738],
            [48.9032, 11.2546],
            [50.11, 11.5292],
            [50.7925, 11.9837],
            [51.2547, 11.8307],
            [51.0842, 11.3355],
            [51.1409, 10.6565],
            [50.8983, 10.2531],
            [50.8249, 9.4282],
            [49.3484, 6.9906],
            [49.0491, 6.1737],
            [47.9755, 4.4967],
            [46.0512, 2.4752],
            [44.3329, 1.3911],
            [43.4676, 0.6216],
            [41.9796, -0.9727],
            [41.5327, -1.6956]
          ]
        ]
      },
      properties: { name: "Somalia", id: "SO", Continent: "Africa" },
      id: "SO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.5221, 37.3484],
            [65.7648, 37.569],
            [65.5549, 37.2512],
            [64.8162, 37.1322],
            [64.5108, 36.3408],
            [63.8623, 36.0123],
            [63.1086, 35.8187],
            [63.0566, 35.4459],
            [62.6105, 35.2333],
            [61.262, 35.6194],
            [61.1195, 36.6426],
            [60.3413, 36.6377],
            [60.0626, 36.9631],
            [59.5623, 37.1788],
            [59.2409, 37.5206],
            [58.8157, 37.6835],
            [58.4357, 37.6387],
            [57.4236, 37.9477],
            [57.1937, 38.2162],
            [56.4409, 38.2495],
            [56.2287, 38.0733],
            [55.5784, 38.0999],
            [55.0755, 37.9024],
            [54.7452, 37.502],
            [53.9142, 37.3435],
            [53.8237, 37.9281],
            [53.8685, 38.9491],
            [53.6177, 39.2159],
            [53.125, 39.432],
            [53.4721, 39.6686],
            [53.4042, 39.9603],
            [52.9874, 39.9878],
            [52.7335, 40.3988],
            [53.1454, 40.825],
            [53.8698, 40.6487],
            [54.7101, 40.8911],
            [54.5921, 41.1934],
            [54.0949, 41.5192],
            [53.8463, 42.0914],
            [52.9701, 41.9764],
            [52.4938, 41.7802],
            [53.2502, 42.2059],
            [54.1211, 42.3351],
            [54.9037, 41.9192],
            [55.5451, 41.2626],
            [55.9774, 41.3221],
            [57.0179, 41.2635],
            [56.9642, 41.8566],
            [57.3819, 42.1562],
            [57.8143, 42.1899],
            [58.2595, 42.688],
            [58.5889, 42.7785],
            [59.4509, 42.2996],
            [59.8584, 42.2951],
            [60.107, 41.9076],
            [60.0897, 41.3994],
            [60.455, 41.2218],
            [61.903, 41.0935],
            [62.6505, 39.8586],
            [64.1628, 38.9535],
            [65.613, 38.2384],
            [65.8572, 38.2686],
            [66.5745, 38.0107],
            [66.5221, 37.3484]
          ]
        ]
      },
      properties: { name: "Turkmenistan", id: "TM" },
      id: "TM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.9579, 40.239],
            [70.4514, 40.049],
            [69.9667, 40.2022],
            [69.5304, 40.0974],
            [69.229, 39.761],
            [69.5987, 39.5736],
            [70.6077, 39.5643],
            [70.7333, 39.4134],
            [71.4045, 39.598],
            [71.8062, 39.2758],
            [72.0428, 39.3521],
            [73.2351, 39.3743],
            [73.6315, 39.4489],
            [73.8015, 38.6068],
            [74.2773, 38.6597],
            [74.7452, 38.5101],
            [74.8912, 37.2317],
            [74.2596, 37.4154],
            [73.7496, 37.2317],
            [73.4814, 37.4718],
            [72.8955, 37.2676],
            [71.8022, 36.6941],
            [71.4329, 37.1273],
            [71.5518, 37.933],
            [71.2558, 38.3068],
            [70.8789, 38.4564],
            [70.2148, 37.9246],
            [70.2512, 37.664],
            [69.4922, 37.553],
            [69.2649, 37.1083],
            [68.9604, 37.3249],
            [68.0677, 36.9498],
            [67.7592, 37.1722],
            [67.8143, 37.4869],
            [68.2941, 38.0329],
            [68.0478, 38.6694],
            [68.1037, 38.9619],
            [67.6767, 39.0086],
            [67.3495, 39.242],
            [67.7193, 39.6216],
            [68.4632, 39.5368],
            [68.6528, 40.1827],
            [69.2281, 40.1875],
            [69.2063, 40.5666],
            [69.7128, 40.6572],
            [70.4417, 41.0234],
            [70.7511, 40.7397],
            [70.3826, 40.4534],
            [70.9579, 40.239]
          ]
        ]
      },
      properties: { name: "Tajikistan", id: "TJ" },
      id: "TJ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.9468, 42.2485],
            [70.8421, 42.0195],
            [70.1811, 41.5716],
            [70.7826, 41.2626],
            [71.4085, 41.1361],
            [71.7924, 41.4131],
            [72.6585, 40.8698],
            [73.113, 40.7859],
            [71.6926, 40.1525],
            [70.9579, 40.239],
            [70.3826, 40.4534],
            [70.7511, 40.7397],
            [70.4417, 41.0234],
            [69.7128, 40.6572],
            [69.2063, 40.5666],
            [69.2281, 40.1875],
            [68.6528, 40.1827],
            [68.4632, 39.5368],
            [67.7193, 39.6216],
            [67.3495, 39.242],
            [67.6767, 39.0086],
            [68.1037, 38.9619],
            [68.0478, 38.6694],
            [68.2941, 38.0329],
            [67.8143, 37.4869],
            [67.7592, 37.1722],
            [67.069, 37.3346],
            [66.5221, 37.3484],
            [66.5745, 38.0107],
            [65.8572, 38.2686],
            [65.613, 38.2384],
            [64.1628, 38.9535],
            [62.6505, 39.8586],
            [61.903, 41.0935],
            [60.455, 41.2218],
            [60.0897, 41.3994],
            [60.107, 41.9076],
            [59.8584, 42.2951],
            [59.4509, 42.2996],
            [58.5889, 42.7785],
            [58.2595, 42.688],
            [57.8143, 42.1899],
            [57.3819, 42.1562],
            [56.9642, 41.8566],
            [57.0179, 41.2635],
            [55.9774, 41.3221],
            [55.9766, 42.929],
            [55.9757, 44.9949],
            [58.5551, 45.5555],
            [61.0081, 44.3939],
            [61.1608, 44.1688],
            [61.9904, 43.4923],
            [63.2071, 43.6281],
            [64.4434, 43.5513],
            [64.9054, 43.7147],
            [65.4963, 43.3108],
            [65.803, 42.8771],
            [66.1004, 42.9907],
            [66.0094, 42.0048],
            [66.4986, 41.9951],
            [66.6686, 41.2706],
            [66.8142, 41.1423],
            [67.9359, 41.1965],
            [68.2919, 40.6563],
            [69.0652, 41.367],
            [70.0954, 41.8206],
            [70.4159, 42.0785],
            [70.9468, 42.2485]
          ]
        ]
      },
      properties: { name: "Uzbekistan", id: "UZ" },
      id: "UZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [74.8912, 37.2317],
            [74.5415, 37.0221],
            [74.0017, 36.8233],
            [73.7691, 36.8885],
            [72.623, 36.8295],
            [71.6206, 36.4366],
            [71.1852, 36.042],
            [71.4276, 35.8338],
            [71.6206, 35.1831],
            [70.9655, 34.5306],
            [71.0516, 34.0498],
            [69.8682, 33.8976],
            [70.2841, 33.3689],
            [69.9201, 33.1123],
            [69.5015, 33.02],
            [69.2414, 32.4336],
            [69.2791, 31.9369],
            [68.869, 31.6342],
            [68.2138, 31.8073],
            [67.5781, 31.5063],
            [67.2874, 31.2178],
            [66.9243, 31.3057],
            [66.3974, 30.9124],
            [66.1772, 29.8355],
            [65.0954, 29.5594],
            [64.521, 29.5643],
            [64.0989, 29.3921],
            [63.5676, 29.4982],
            [62.4765, 29.4085],
            [60.8434, 29.8586],
            [61.104, 30.1285],
            [61.7844, 30.8321],
            [61.6602, 31.3825],
            [60.854, 31.4833],
            [60.8292, 32.2494],
            [60.5606, 33.1376],
            [60.9171, 33.5052],
            [60.5109, 33.6388],
            [60.4856, 34.0947],
            [61.08, 34.8555],
            [61.262, 35.6194],
            [62.6105, 35.2333],
            [63.0566, 35.4459],
            [63.1086, 35.8187],
            [63.8623, 36.0123],
            [64.5108, 36.3408],
            [64.8162, 37.1322],
            [65.5549, 37.2512],
            [65.7648, 37.569],
            [66.5221, 37.3484],
            [67.069, 37.3346],
            [67.7592, 37.1722],
            [68.0677, 36.9498],
            [68.9604, 37.3249],
            [69.2649, 37.1083],
            [69.4922, 37.553],
            [70.2512, 37.664],
            [70.2148, 37.9246],
            [70.8789, 38.4564],
            [71.2558, 38.3068],
            [71.5518, 37.933],
            [71.4329, 37.1273],
            [71.8022, 36.6941],
            [72.8955, 37.2676],
            [73.4814, 37.4718],
            [73.7496, 37.2317],
            [74.2596, 37.4154],
            [74.8912, 37.2317]
          ]
        ]
      },
      properties: { name: "Afghanistan", id: "AF" },
      id: "AF"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.3229, 49.0858],
            [86.7534, 49.0088],
            [86.5496, 48.5287],
            [85.7493, 48.3849],
            [85.526, 47.9157],
            [85.6565, 47.2547],
            [84.786, 46.8308],
            [83.1929, 47.1864],
            [83.0043, 47.0337],
            [82.3264, 45.52],
            [82.4787, 45.1236],
            [81.602, 45.3109],
            [80.059, 45.0064],
            [80.4554, 44.7463],
            [80.3551, 44.0973],
            [80.7857, 43.1616],
            [80.1651, 42.6653],
            [80.2095, 42.1899],
            [79.49, 42.4576],
            [79.1646, 42.759],
            [76.9442, 42.9716],
            [75.0475, 42.9046],
            [74.209, 43.2402],
            [73.5565, 43.0027],
            [73.422, 42.5934],
            [72.8551, 42.561],
            [71.8168, 42.822],
            [71.2567, 42.7337],
            [70.9468, 42.2485],
            [70.4159, 42.0785],
            [70.0954, 41.8206],
            [69.0652, 41.367],
            [68.2919, 40.6563],
            [67.9359, 41.1965],
            [66.8142, 41.1423],
            [66.6686, 41.2706],
            [66.4986, 41.9951],
            [66.0094, 42.0048],
            [66.1004, 42.9907],
            [65.803, 42.8771],
            [65.4963, 43.3108],
            [64.9054, 43.7147],
            [64.4434, 43.5513],
            [63.2071, 43.6281],
            [61.9904, 43.4923],
            [61.1608, 44.1688],
            [61.0081, 44.3939],
            [58.5551, 45.5555],
            [55.9757, 44.9949],
            [55.9766, 42.929],
            [55.9774, 41.3221],
            [55.5451, 41.2626],
            [54.9037, 41.9192],
            [54.1211, 42.3351],
            [53.2502, 42.2059],
            [52.4938, 41.7802],
            [52.5968, 42.7603],
            [51.8439, 42.9104],
            [51.3019, 43.4825],
            [50.8305, 44.1928],
            [50.3311, 44.3255],
            [50.4093, 44.6242],
            [50.8603, 44.6287],
            [51.4156, 45.358],
            [52.5311, 45.3988],
            [52.7739, 45.5728],
            [53.1352, 46.1916],
            [53.1703, 46.6692],
            [52.9159, 46.9542],
            [52.1888, 46.8397],
            [51.1781, 47.11],
            [50.4195, 46.8796],
            [50, 46.6342],
            [49.3475, 46.5192],
            [49.2321, 46.3372],
            [48.6102, 46.5663],
            [48.9595, 46.7744],
            [48.1099, 47.7452],
            [47.0934, 47.9477],
            [47.0042, 48.2846],
            [46.661, 48.4124],
            [46.7028, 48.8055],
            [47.0144, 49.0984],
            [46.8022, 49.367],
            [47.3264, 50.2734],
            [47.706, 50.378],
            [48.4344, 49.8284],
            [48.8432, 50.0131],
            [48.8086, 50.6012],
            [49.498, 51.0835],
            [50.2468, 51.2895],
            [50.7941, 51.7292],
            [51.6091, 51.4839],
            [52.3309, 51.6812],
            [52.571, 51.4817],
            [53.3381, 51.4824],
            [54.0416, 51.1153],
            [54.4216, 50.7804],
            [54.727, 50.9981],
            [55.5424, 50.6017],
            [55.7977, 50.6021],
            [56.4915, 51.0196],
            [57.0117, 51.0651],
            [57.4423, 50.8889],
            [57.8387, 51.0917],
            [58.3594, 51.0638],
            [58.8836, 50.6945],
            [59.8122, 50.5819],
            [59.9552, 50.7992],
            [60.6379, 50.6638],
            [61.3894, 50.8609],
            [61.5851, 51.2298],
            [61.3632, 51.442],
            [60.4647, 51.6513],
            [60.0657, 51.9764],
            [60.9375, 52.2805],
            [60.8021, 52.7448],
            [61.0476, 52.9725],
            [61.974, 52.9437],
            [62.0148, 53.1079],
            [61.1994, 53.2872],
            [60.9797, 53.6217],
            [61.3339, 54.0492],
            [61.9287, 53.9464],
            [63.0739, 54.1053],
            [64.4611, 54.3841],
            [65.1576, 54.3643],
            [65.4767, 54.6233],
            [66.2225, 54.6673],
            [68.0739, 54.9596],
            [68.2062, 55.1609],
            [68.9773, 55.3895],
            [70.1824, 55.1625],
            [70.7382, 55.3052],
            [71.1856, 54.5994],
            [71.0933, 54.2123],
            [72.9141, 54.1073],
            [73.3993, 53.8115],
            [73.2857, 53.5984],
            [74.4518, 53.6472],
            [76.2664, 54.312],
            [76.5758, 53.9424],
            [77.7042, 53.3791],
            [78.4757, 52.6385],
            [79.4687, 51.493],
            [79.9862, 50.7746],
            [80.8771, 51.2815],
            [81.4657, 50.7397],
            [82.4938, 50.7275],
            [83.3571, 50.9945],
            [83.9453, 50.7746],
            [84.2578, 50.2883],
            [84.9893, 50.0615],
            [85.21, 49.6648],
            [86.1807, 49.4993],
            [86.6144, 49.6098],
            [87.3229, 49.0858]
          ]
        ]
      },
      properties: { name: "Kazakhstan", id: "KZ" },
      id: "KZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [110.8887, 19.9918],
              [111.0138, 19.6553],
              [110.5722, 19.1719],
              [110.4514, 18.748],
              [109.5193, 18.2184],
              [108.7016, 18.5354],
              [108.6936, 19.3384],
              [109.6511, 19.9842],
              [110.5886, 20.0726],
              [110.8887, 19.9918]
            ]
          ],
          [
            [
              [130.5269, 42.5353],
              [130.2406, 42.8917],
              [129.8983, 42.9983],
              [129.7199, 42.4749],
              [129.3137, 42.4137],
              [128.9236, 42.0381],
              [128.0451, 41.9875],
              [128.291, 41.5627],
              [128.1112, 41.3891],
              [127.1795, 41.5312],
              [126.9034, 41.7811],
              [125.989, 40.9049],
              [124.8895, 40.4596],
              [124.3621, 40.0042],
              [124.1056, 39.8409],
              [123.3483, 39.7632],
              [122.3349, 39.3663],
              [121.6499, 38.8652],
              [121.7853, 39.401],
              [121.5177, 39.6389],
              [122.2749, 40.5418],
              [121.859, 40.8423],
              [121.1745, 40.9013],
              [120.479, 40.231],
              [119.5912, 39.9026],
              [118.9125, 39.1666],
              [118.2977, 39.0671],
              [117.7845, 39.1346],
              [117.5577, 38.625],
              [118.0149, 38.1834],
              [118.94, 38.0426],
              [119.0891, 37.7008],
              [118.9529, 37.3311],
              [119.45, 37.1247],
              [120.3702, 37.7008],
              [120.7502, 37.834],
              [121.6402, 37.4603],
              [122.6025, 37.4265],
              [122.5195, 36.9467],
              [121.9327, 36.9595],
              [120.9899, 36.5978],
              [120.3933, 36.054],
              [120.0546, 35.8614],
              [119.2156, 35.0118],
              [119.5827, 34.5821],
              [120.2015, 34.3255],
              [120.8709, 33.0165],
              [120.8532, 32.6613],
              [121.4009, 32.3719],
              [121.3521, 31.8588],
              [121.0556, 31.7194],
              [121.6606, 31.3199],
              [121.8781, 30.9168],
              [120.9974, 30.5582],
              [121.1594, 30.3016],
              [121.9442, 29.8941],
              [121.7174, 29.2562],
              [121.602, 28.3667],
              [121.0986, 28.2903],
              [120.2788, 27.0972],
              [119.6484, 25.9186],
              [119.5392, 25.5915],
              [119.0248, 25.2235],
              [118.5605, 24.5803],
              [118.0873, 24.6269],
              [118.0562, 24.246],
              [116.5381, 23.1798],
              [116.4706, 22.9459],
              [115.4985, 22.719],
              [115.1957, 22.8171],
              [114.854, 22.6169],
              [114.2658, 22.541],
              [114.0154, 22.5117],
              [113.6195, 22.8615],
              [113.5467, 22.2241],
              [113.4943, 22.2414],
              [113.4788, 22.1957],
              [113.0083, 22.1193],
              [112.5861, 21.7767],
              [111.9438, 21.8495],
              [111.6029, 21.5592],
              [110.771, 21.3865],
              [110.1935, 21.0376],
              [110.5114, 20.5182],
              [110.123, 20.2639],
              [109.6626, 20.9168],
              [109.9307, 21.4806],
              [109.5441, 21.5378],
              [109.1486, 21.4255],
              [109.0305, 21.6266],
              [108.3247, 21.6937],
              [107.9727, 21.5081],
              [107.3513, 21.6089],
              [106.7294, 22.0004],
              [106.5363, 22.3954],
              [106.7804, 22.779],
              [106.5421, 22.9081],
              [105.8429, 22.9228],
              [105.2752, 23.3454],
              [104.8646, 23.1363],
              [104.7958, 22.9112],
              [104.3719, 22.704],
              [103.3008, 22.7643],
              [103.0051, 22.4532],
              [102.5173, 22.7408],
              [102.1276, 22.379],
              [101.6712, 22.4625],
              [101.5243, 22.2538],
              [101.744, 21.778],
              [101.705, 21.1503],
              [101.2242, 21.2236],
              [101.139, 21.5676],
              [100.2148, 21.4628],
              [99.9179, 22.0279],
              [99.193, 22.126],
              [99.3377, 22.498],
              [99.3408, 23.0959],
              [98.8636, 23.1913],
              [98.7642, 24.116],
              [98.2124, 24.1106],
              [97.686, 23.898],
              [97.5315, 24.4915],
              [97.8196, 25.2519],
              [98.0109, 25.2923],
              [98.6546, 25.9177],
              [98.7318, 26.5836],
              [98.651, 27.5726],
              [98.299, 27.5499],
              [98.0988, 28.1421],
              [97.5377, 28.5101],
              [97.3224, 28.218],
              [96.776, 28.3671],
              [96.4355, 29.0507],
              [96.0352, 29.4471],
              [95.4204, 29.0543],
              [94.7696, 29.1759],
              [94.6231, 29.3126],
              [93.9022, 28.803],
              [93.2067, 28.5909],
              [92.702, 28.147],
              [92.6642, 27.949],
              [91.9775, 27.7301],
              [91.6317, 27.7599],
              [91.6056, 27.9516],
              [90.3529, 28.0804],
              [89.9809, 28.3112],
              [89.537, 28.1075],
              [88.8912, 27.316],
              [88.8485, 27.8686],
              [88.6213, 28.0919],
              [88.1099, 27.8704],
              [87.1413, 27.8385],
              [86.5545, 28.0853],
              [86.4089, 27.9286],
              [85.6783, 28.2775],
              [85.1225, 28.3161],
              [85.1589, 28.5922],
              [84.7141, 28.5957],
              [84.2285, 28.9118],
              [84.1016, 29.2198],
              [83.5835, 29.1834],
              [83.1556, 29.6127],
              [82.8542, 29.6833],
              [82.1591, 30.1152],
              [82.0432, 30.3269],
              [81.4169, 30.3376],
              [81.0103, 30.1645],
              [80.6823, 30.4148],
              [80.1913, 30.5684],
              [79.9245, 30.8889],
              [79.9165, 30.8942],
              [79.3697, 31.0798],
              [79.3386, 31.1055],
              [79.1073, 31.4025],
              [78.8445, 31.3017],
              [78.7198, 31.8876],
              [78.3896, 32.5197],
              [79.2192, 32.5011],
              [79.2165, 32.5641],
              [79.1353, 33.1718],
              [79.1127, 33.2264],
              [78.8019, 33.4999],
              [78.7531, 34.0876],
              [78.9364, 34.3521],
              [78.2821, 34.654],
              [77.8107, 35.4845],
              [77.7992, 35.496],
              [77.4467, 35.4756],
              [76.2518, 35.8108],
              [75.945, 36.0176],
              [75.8403, 36.6497],
              [75.3467, 36.9134],
              [74.5415, 37.0221],
              [74.8912, 37.2317],
              [74.7452, 38.5101],
              [74.2773, 38.6597],
              [73.8015, 38.6068],
              [73.6315, 39.4489],
              [73.9147, 39.6065],
              [73.9915, 40.0433],
              [74.4118, 40.1374],
              [75.0044, 40.4494],
              [75.5837, 40.6052],
              [75.8718, 40.3034],
              [76.4804, 40.4494],
              [76.8239, 40.9821],
              [78.1232, 41.0758],
              [78.3625, 41.3718],
              [79.2938, 41.7829],
              [80.2162, 42.0323],
              [80.2095, 42.1899],
              [80.1651, 42.6653],
              [80.7857, 43.1616],
              [80.3551, 44.0973],
              [80.4554, 44.7463],
              [80.059, 45.0064],
              [81.602, 45.3109],
              [82.4787, 45.1236],
              [82.3264, 45.52],
              [83.0043, 47.0337],
              [83.1929, 47.1864],
              [84.786, 46.8308],
              [85.6565, 47.2547],
              [85.526, 47.9157],
              [85.7493, 48.3849],
              [86.5496, 48.5287],
              [86.7534, 49.0088],
              [87.3229, 49.0858],
              [87.8143, 49.1624],
              [87.9798, 48.5549],
              [88.517, 48.3844],
              [89.0478, 48.0027],
              [89.561, 48.004],
              [90.3134, 47.676],
              [90.4963, 47.2854],
              [90.87, 46.9547],
              [91.0338, 46.529],
              [91.0019, 46.0358],
              [90.6618, 45.5253],
              [90.8771, 45.196],
              [91.5842, 45.0766],
              [92.7876, 45.0357],
              [93.6563, 44.9008],
              [94.7119, 44.3508],
              [95.3502, 44.278],
              [95.9126, 43.2064],
              [96.3854, 42.7204],
              [97.2057, 42.7896],
              [99.4678, 42.5681],
              [99.984, 42.6773],
              [101.7139, 42.4656],
              [101.9731, 42.2157],
              [103.0726, 42.0062],
              [103.7109, 41.7514],
              [104.4984, 41.877],
              [104.4984, 41.6586],
              [105.1971, 41.738],
              [106.7702, 42.2889],
              [108.1712, 42.4474],
              [109.4434, 42.4558],
              [110.4004, 42.7736],
              [111.0072, 43.3414],
              [111.9331, 43.7116],
              [111.4022, 44.3672],
              [111.8981, 45.0641],
              [113.5871, 44.7459],
              [114.0305, 44.9425],
              [114.517, 45.3646],
              [115.6809, 45.4583],
              [116.2407, 45.7961],
              [116.5625, 46.2897],
              [117.3335, 46.3621],
              [118.3088, 46.7172],
              [119.7066, 46.6062],
              [119.8979, 46.8579],
              [119.711, 47.15],
              [118.4983, 47.9841],
              [117.7685, 47.988],
              [117.3508, 47.652],
              [116.7605, 47.87],
              [115.8984, 47.6871],
              [115.5575, 47.945],
              [116.6832, 49.8238],
              [117.8733, 49.5135],
              [118.4517, 49.8444],
              [119.26, 50.0664],
              [119.1921, 50.3797],
              [120.067, 51.6007],
              [120.7497, 52.0965],
              [120.6561, 52.5666],
              [120.0945, 52.7872],
              [120.704, 53.1718],
              [122.338, 53.485],
              [123.6079, 53.5465],
              [124.8122, 53.1339],
              [125.649, 53.0422],
              [126.313, 52.3997],
              [126.9247, 51.1002],
              [127.5901, 50.2091],
              [127.5027, 49.8735],
              [127.9998, 49.5685],
              [128.5267, 49.5943],
              [129.5339, 49.3235],
              [130.1962, 48.8916],
              [130.8043, 48.3414],
              [130.712, 48.1279],
              [131.0027, 47.6915],
              [132.5617, 47.7683],
              [133.1441, 48.1057],
              [133.4686, 48.0972],
              [134.2933, 48.3733],
              [134.5636, 48.3219],
              [134.7523, 47.7155],
              [134.1677, 47.3022],
              [133.8614, 46.2475],
              [133.1858, 45.4947],
              [132.9359, 45.03],
              [131.9775, 45.2439],
              [131.0822, 44.9101],
              [131.2553, 44.0716],
              [131.262, 43.4333],
              [131.0684, 42.9024],
              [130.5771, 42.8118],
              [130.5269, 42.5353]
            ]
          ]
        ]
      },
      properties: { name: "China", id: "CN" },
      id: "CN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [77.7992, 35.496],
            [77.0486, 35.1099],
            [76.5945, 34.7357],
            [75.7093, 34.5031],
            [74.3004, 34.7654],
            [73.9613, 34.6535],
            [74.1499, 33.507],
            [73.9941, 33.2424],
            [74.6578, 32.5189],
            [75.3334, 32.2792],
            [74.5099, 31.7127],
            [74.6329, 31.0345],
            [73.8991, 30.4352],
            [73.809, 30.0934],
            [73.3816, 29.9345],
            [72.903, 29.029],
            [72.342, 28.752],
            [71.8701, 27.9623],
            [70.7373, 27.7293],
            [70.4035, 28.0249],
            [70.1447, 27.8491],
            [69.5681, 27.1744],
            [69.47, 26.8046],
            [70.1567, 26.4713],
            [70.0777, 26.0718],
            [70.2646, 25.7064],
            [70.6521, 25.4228],
            [71.048, 24.6877],
            [71.044, 24.4001],
            [70.5469, 24.4183],
            [69.7163, 24.1728],
            [69.5592, 24.2731],
            [68.7282, 24.2656],
            [68.7242, 23.9646],
            [68.1649, 23.8572],
            [67.6687, 23.811],
            [67.3096, 24.175],
            [67.1715, 24.7561],
            [66.7032, 24.8608],
            [66.6988, 25.2261],
            [66.4284, 25.5755],
            [65.6796, 25.3553],
            [64.7767, 25.3074],
            [64.6591, 25.184],
            [63.7207, 25.3859],
            [63.4912, 25.2106],
            [62.5723, 25.2546],
            [62.3153, 25.1347],
            [61.5878, 25.2022],
            [61.6686, 25.769],
            [61.8697, 26.2422],
            [62.4392, 26.5609],
            [63.1681, 26.6657],
            [63.1667, 27.2525],
            [62.7632, 27.2503],
            [62.7397, 28.0023],
            [62.3531, 28.4146],
            [61.8901, 28.5465],
            [61.6229, 28.7915],
            [61.3184, 29.3725],
            [60.8434, 29.8586],
            [62.4765, 29.4085],
            [63.5676, 29.4982],
            [64.0989, 29.3921],
            [64.521, 29.5643],
            [65.0954, 29.5594],
            [66.1772, 29.8355],
            [66.3974, 30.9124],
            [66.9243, 31.3057],
            [67.2874, 31.2178],
            [67.5781, 31.5063],
            [68.2138, 31.8073],
            [68.869, 31.6342],
            [69.2791, 31.9369],
            [69.2414, 32.4336],
            [69.5015, 33.02],
            [69.9201, 33.1123],
            [70.2841, 33.3689],
            [69.8682, 33.8976],
            [71.0516, 34.0498],
            [70.9655, 34.5306],
            [71.6206, 35.1831],
            [71.4276, 35.8338],
            [71.1852, 36.042],
            [71.6206, 36.4366],
            [72.623, 36.8295],
            [73.7691, 36.8885],
            [74.0017, 36.8233],
            [74.5415, 37.0221],
            [75.3467, 36.9134],
            [75.8403, 36.6497],
            [75.945, 36.0176],
            [76.2518, 35.8108],
            [77.4467, 35.4756],
            [77.7992, 35.496]
          ]
        ]
      },
      properties: { name: "Pakistan", id: "PK" },
      id: "PK"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [80.2095, 42.1899],
            [80.2162, 42.0323],
            [79.2938, 41.7829],
            [78.3625, 41.3718],
            [78.1232, 41.0758],
            [76.8239, 40.9821],
            [76.4804, 40.4494],
            [75.8718, 40.3034],
            [75.5837, 40.6052],
            [75.0044, 40.4494],
            [74.4118, 40.1374],
            [73.9915, 40.0433],
            [73.9147, 39.6065],
            [73.6315, 39.4489],
            [73.2351, 39.3743],
            [72.0428, 39.3521],
            [71.8062, 39.2758],
            [71.4045, 39.598],
            [70.7333, 39.4134],
            [70.6077, 39.5643],
            [69.5987, 39.5736],
            [69.229, 39.761],
            [69.5304, 40.0974],
            [69.9667, 40.2022],
            [70.4514, 40.049],
            [70.9579, 40.239],
            [71.6926, 40.1525],
            [73.113, 40.7859],
            [72.6585, 40.8698],
            [71.7924, 41.4131],
            [71.4085, 41.1361],
            [70.7826, 41.2626],
            [70.1811, 41.5716],
            [70.8421, 42.0195],
            [70.9468, 42.2485],
            [71.2567, 42.7337],
            [71.8168, 42.822],
            [72.8551, 42.561],
            [73.422, 42.5934],
            [73.5565, 43.0027],
            [74.209, 43.2402],
            [75.0475, 42.9046],
            [76.9442, 42.9716],
            [79.1646, 42.759],
            [79.49, 42.4576],
            [80.2095, 42.1899]
          ]
        ]
      },
      properties: { name: "Kyrgyzstan", id: "KG" },
      id: "KG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [78.7198, 31.8876],
            [78.8445, 31.3017],
            [79.1073, 31.4025],
            [79.3386, 31.1055],
            [79.3697, 31.0798],
            [79.9165, 30.8942],
            [79.9245, 30.8889],
            [80.1913, 30.5684],
            [80.6823, 30.4148],
            [81.0103, 30.1645],
            [80.4017, 29.7303],
            [80.0706, 28.8301],
            [80.5868, 28.6494],
            [81.8528, 27.8669],
            [81.9877, 27.9139],
            [82.7335, 27.5189],
            [83.2897, 27.371],
            [84.0909, 27.4913],
            [84.6103, 27.2987],
            [84.6853, 27.0412],
            [85.293, 26.7412],
            [86.3663, 26.5743],
            [86.7014, 26.4349],
            [87.0166, 26.5556],
            [87.2874, 26.3603],
            [88.0549, 26.43],
            [88.1614, 26.7247],
            [87.9843, 27.134],
            [88.1099, 27.8704],
            [88.6213, 28.0919],
            [88.8485, 27.8686],
            [88.8912, 27.316],
            [88.8579, 26.9613],
            [89.7638, 26.7016],
            [90.3458, 26.8903],
            [90.7395, 26.7718],
            [91.9984, 26.8548],
            [92.0836, 27.2907],
            [91.5949, 27.5575],
            [91.6317, 27.7599],
            [91.9775, 27.7301],
            [92.6642, 27.949],
            [92.702, 28.147],
            [93.2067, 28.5909],
            [93.9022, 28.803],
            [94.6231, 29.3126],
            [94.7696, 29.1759],
            [95.4204, 29.0543],
            [96.0352, 29.4471],
            [96.4355, 29.0507],
            [96.776, 28.3671],
            [97.3224, 28.218],
            [97.3353, 27.9379],
            [96.6659, 27.3391],
            [96.0613, 27.217],
            [95.1287, 26.5973],
            [95.1323, 26.0411],
            [94.6227, 25.4099],
            [94.7075, 25.0486],
            [94.2929, 24.3219],
            [94.1277, 23.8763],
            [93.3074, 24.0219],
            [93.4149, 23.6823],
            [93.366, 23.1323],
            [93.0788, 22.7182],
            [93.1618, 22.3604],
            [92.9643, 22.0039],
            [92.575, 21.9782],
            [92.4916, 22.6853],
            [92.3615, 22.929],
            [92.2461, 23.6836],
            [91.938, 23.5047],
            [91.6198, 22.9796],
            [91.3152, 23.1043],
            [91.1603, 23.6605],
            [91.3672, 24.0933],
            [91.8768, 24.1954],
            [92.2514, 24.895],
            [92.0499, 25.1693],
            [90.4394, 25.1578],
            [89.814, 25.3052],
            [89.8229, 25.9413],
            [89.2893, 26.0376],
            [89.1082, 26.2023],
            [88.4402, 26.3696],
            [88.0846, 25.8884],
            [88.4521, 25.5746],
            [88.4561, 25.1884],
            [88.1454, 24.4857],
            [88.7336, 24.2309],
            [88.5671, 23.6743],
            [88.7407, 23.4368],
            [89.0514, 22.0932],
            [89.0518, 21.6541],
            [88.2537, 21.6222],
            [87.9483, 21.8255],
            [87.1005, 21.5006],
            [86.8595, 21.2369],
            [86.9753, 20.7002],
            [86.7503, 20.3131],
            [86.2793, 19.9194],
            [85.5748, 19.693],
            [84.7709, 19.1253],
            [84.1042, 18.2926],
            [83.6541, 18.0697],
            [83.1982, 17.609],
            [82.3597, 17.0963],
            [82.259, 16.56],
            [81.7618, 16.3297],
            [81.2385, 16.264],
            [80.9788, 15.7584],
            [80.6467, 15.8951],
            [80.2934, 15.7109],
            [80.0533, 15.0739],
            [80.1656, 14.5776],
            [80.1119, 14.2123],
            [80.3422, 13.3614],
            [80.229, 12.6902],
            [79.8584, 11.9888],
            [79.7541, 11.5751],
            [79.8486, 11.1969],
            [79.838, 10.3225],
            [79.3905, 10.306],
            [78.9964, 9.6833],
            [78.9795, 9.2687],
            [78.4215, 9.1049],
            [78.1925, 8.8909],
            [78.0602, 8.3844],
            [77.5178, 8.0782],
            [76.9669, 8.4071],
            [76.5536, 8.9029],
            [76.3246, 9.452],
            [76.3463, 9.9221],
            [75.9224, 10.7841],
            [75.724, 11.3616],
            [75.1966, 12.0576],
            [74.7705, 13.0773],
            [74.6711, 13.6676],
            [74.3821, 14.4946],
            [73.9493, 15.0748],
            [73.932, 15.397],
            [73.4761, 16.0544],
            [73.3376, 16.4597],
            [73.1561, 17.6218],
            [72.8711, 18.6832],
            [72.6678, 19.8311],
            [72.8813, 20.5631],
            [72.8138, 21.117],
            [72.5431, 21.6968],
            [72.5901, 22.2783],
            [72.3326, 22.2703],
            [72.037, 21.8233],
            [72.2541, 21.5312],
            [72.0153, 21.1557],
            [71.0245, 20.7388],
            [70.7191, 20.7406],
            [70.1274, 21.0948],
            [69.0088, 22.1966],
            [70.1771, 22.5726],
            [70.2512, 22.9707],
            [69.6644, 22.759],
            [69.2361, 22.8487],
            [68.6408, 23.19],
            [68.1649, 23.8572],
            [68.7242, 23.9646],
            [68.7282, 24.2656],
            [69.5592, 24.2731],
            [69.7163, 24.1728],
            [70.5469, 24.4183],
            [71.044, 24.4001],
            [71.048, 24.6877],
            [70.6521, 25.4228],
            [70.2646, 25.7064],
            [70.0777, 26.0718],
            [70.1567, 26.4713],
            [69.47, 26.8046],
            [69.5681, 27.1744],
            [70.1447, 27.8491],
            [70.4035, 28.0249],
            [70.7373, 27.7293],
            [71.8701, 27.9623],
            [72.342, 28.752],
            [72.903, 29.029],
            [73.3816, 29.9345],
            [73.809, 30.0934],
            [73.8991, 30.4352],
            [74.6329, 31.0345],
            [74.5099, 31.7127],
            [75.3334, 32.2792],
            [74.6578, 32.5189],
            [73.9941, 33.2424],
            [74.1499, 33.507],
            [73.9613, 34.6535],
            [74.3004, 34.7654],
            [75.7093, 34.5031],
            [76.5945, 34.7357],
            [77.0486, 35.1099],
            [77.7992, 35.496],
            [77.8107, 35.4845],
            [78.2821, 34.654],
            [78.9364, 34.3521],
            [78.7531, 34.0876],
            [78.8019, 33.4999],
            [79.1127, 33.2264],
            [79.1353, 33.1718],
            [79.2165, 32.5641],
            [79.2192, 32.5011],
            [78.3896, 32.5197],
            [78.7198, 31.8876]
          ]
        ]
      },
      properties: { name: "India", id: "IN" },
      id: "IN"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [48.5729, 41.8446],
              [49.1064, 41.3017],
              [49.2263, 41.026],
              [49.7758, 40.5839],
              [49.3244, 39.6083],
              [49.363, 39.3495],
              [49.0137, 39.1342],
              [48.8685, 38.4355],
              [48.5929, 38.4111],
              [48.0233, 38.819],
              [48.2422, 38.9788],
              [47.9958, 39.6842],
              [47.773, 39.6486],
              [46.4906, 38.9065],
              [46.5501, 39.2012],
              [45.7897, 39.8812],
              [45.9646, 40.2337],
              [45.376, 40.6381],
              [45.5877, 40.8467],
              [45.0013, 41.291],
              [45.281, 41.4495],
              [46.4577, 41.07],
              [46.6189, 41.3439],
              [46.1821, 41.6573],
              [46.4298, 41.8907],
              [46.7494, 41.8126],
              [47.2612, 41.315],
              [47.8609, 41.2129],
              [48.5729, 41.8446]
            ]
          ],
          [
            [
              [46.1146, 38.8776],
              [45.4798, 39.0063],
              [44.8171, 39.6504],
              [44.7683, 39.7037],
              [45.0315, 39.7649],
              [45.7662, 39.3783],
              [46.1146, 38.8776]
            ]
          ]
        ]
      },
      properties: { name: "Azerbaijan", id: "AZ" },
      id: "AZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.4906, 38.9065],
            [46.1146, 38.8776],
            [45.7662, 39.3783],
            [45.0315, 39.7649],
            [44.7683, 39.7037],
            [44.2893, 40.0406],
            [43.7917, 40.0703],
            [43.5693, 40.4823],
            [43.7225, 40.7193],
            [43.4393, 41.1073],
            [45.0013, 41.291],
            [45.5877, 40.8467],
            [45.376, 40.6381],
            [45.9646, 40.2337],
            [45.7897, 39.8812],
            [46.5501, 39.2012],
            [46.4906, 38.9065]
          ]
        ]
      },
      properties: { name: "Armenia", id: "AM" },
      id: "AM"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [41.5101, 41.5174],
              [42.4663, 41.4398],
              [42.7876, 41.5636],
              [43.4393, 41.1073],
              [43.7225, 40.7193],
              [43.5693, 40.4823],
              [43.7917, 40.0703],
              [44.2893, 40.0406],
              [44.7683, 39.7037],
              [44.8171, 39.6504],
              [44.023, 39.3774],
              [44.4309, 38.3569],
              [44.2112, 37.9081],
              [44.5614, 37.7448],
              [44.7652, 37.1424],
              [44.2818, 36.9782],
              [44.1562, 37.2831],
              [43.5161, 37.2445],
              [42.7748, 37.3719],
              [42.3588, 37.1087],
              [42.2683, 37.2765],
              [41.5154, 37.0892],
              [40.7058, 37.0976],
              [40.0164, 36.8259],
              [39.3568, 36.6817],
              [38.7669, 36.6932],
              [38.1916, 36.9014],
              [37.4365, 36.6435],
              [36.6584, 36.8024],
              [36.1275, 35.8316],
              [35.8927, 35.9164],
              [35.811, 36.3097],
              [36.1883, 36.659],
              [36.0489, 36.9107],
              [35.5375, 36.5969],
              [34.6014, 36.7847],
              [33.6945, 36.1819],
              [32.7947, 36.0358],
              [32.3775, 36.1836],
              [32.0219, 36.5352],
              [31.3525, 36.8011],
              [30.6441, 36.8654],
              [30.4838, 36.3106],
              [29.6893, 36.1565],
              [29.1433, 36.3971],
              [29.0381, 36.6937],
              [28.4837, 36.8037],
              [28.1339, 37.0297],
              [27.3491, 37.0195],
              [27.0779, 37.6875],
              [27.2323, 37.9787],
              [26.6828, 38.1985],
              [26.7636, 38.7098],
              [27.0135, 38.8869],
              [26.6819, 39.2922],
              [26.1133, 39.4675],
              [26.1812, 39.99],
              [26.7378, 40.4002],
              [27.4756, 40.3198],
              [28.2892, 40.4033],
              [29.3648, 40.8094],
              [29.3222, 41.2276],
              [31.2549, 41.1077],
              [31.4582, 41.3199],
              [32.3065, 41.7296],
              [33.3816, 42.0177],
              [34.7505, 41.9569],
              [35.2978, 41.7287],
              [36.0516, 41.6826],
              [36.5097, 41.2626],
              [38.3811, 40.9244],
              [39.4265, 41.1064],
              [40.2654, 40.9612],
              [40.9597, 41.2116],
              [41.5101, 41.5174]
            ]
          ],
          [
            [
              [28.0145, 41.9689],
              [28.3465, 41.4664],
              [29.0572, 41.2298],
              [28.7802, 40.9741],
              [28.172, 41.0806],
              [27.4996, 40.9732],
              [27.2581, 40.6874],
              [26.1053, 40.6114],
              [26.0391, 40.7269],
              [26.6246, 41.4016],
              [26.321, 41.7167],
              [26.6153, 41.9649],
              [27.2949, 42.0794],
              [28.0145, 41.9689]
            ]
          ]
        ]
      },
      properties: { name: "Turkey", id: "TR" },
      id: "TR"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.794, 43.4075],
              [-1.2855, 43.0595],
              [-0.0411, 42.6893],
              [1.4284, 42.5961],
              [1.4861, 42.4345],
              [1.7059, 42.5033],
              [2.0326, 42.3537],
              [3.2116, 42.431],
              [3.248, 41.9445],
              [2.0827, 41.2875],
              [1.0329, 41.062],
              [-0.3269, 39.5199],
              [-0.2049, 39.0627],
              [0.2015, 38.7591],
              [-0.3813, 38.4355],
              [-0.6468, 38.1518],
              [-0.8221, 37.581],
              [-1.3275, 37.561],
              [-1.7975, 37.233],
              [-2.1116, 36.7767],
              [-4.3668, 36.7181],
              [-4.6742, 36.5063],
              [-5.1716, 36.4238],
              [-5.6254, 36.026],
              [-6.0407, 36.1885],
              [-6.4924, 36.9547],
              [-6.8845, 37.1944],
              [-7.4061, 37.1793],
              [-7.5036, 37.5854],
              [-7.1855, 38.0062],
              [-7.3431, 38.4572],
              [-7.0461, 38.9069],
              [-7.4452, 39.5363],
              [-7.0368, 39.7139],
              [-7.0146, 40.2084],
              [-6.8102, 40.3429],
              [-6.9285, 41.0092],
              [-6.2125, 41.5321],
              [-6.6182, 41.9422],
              [-7.4037, 41.8335],
              [-7.9208, 41.8836],
              [-8.2131, 42.1336],
              [-8.7771, 41.9409],
              [-8.691, 42.2743],
              [-9.2352, 42.977],
              [-8.8736, 43.3343],
              [-7.6982, 43.7644],
              [-7.2621, 43.5948],
              [-5.6658, 43.5824],
              [-4.523, 43.416],
              [-3.6046, 43.5194],
              [-3.0455, 43.3716],
              [-2.3371, 43.3281],
              [-1.794, 43.4075]
            ]
          ],
          [
            [
              [3.1454, 39.7902],
              [3.4619, 39.6979],
              [3.0726, 39.3011],
              [2.3713, 39.6131],
              [2.785, 39.8546],
              [3.1454, 39.7902]
            ]
          ],
          [
            [
              [-16.3346, 28.38],
              [-16.6579, 28.0071],
              [-16.9054, 28.3396],
              [-16.3346, 28.38]
            ]
          ]
        ]
      },
      properties: { name: "Spain", id: "ES" },
      id: "ES"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.4061, 37.1793],
            [-7.8343, 37.0057],
            [-8.5977, 37.1211],
            [-8.8141, 37.431],
            [-8.9147, 38.5123],
            [-9.4742, 38.7307],
            [-9.3748, 39.3384],
            [-9.1482, 39.5426],
            [-8.8867, 40.1795],
            [-8.6557, 41.0296],
            [-8.7771, 41.9409],
            [-8.2131, 42.1336],
            [-7.9208, 41.8836],
            [-7.4037, 41.8335],
            [-6.6182, 41.9422],
            [-6.2125, 41.5321],
            [-6.9285, 41.0092],
            [-6.8102, 40.3429],
            [-7.0146, 40.2084],
            [-7.0368, 39.7139],
            [-7.4452, 39.5363],
            [-7.0461, 38.9069],
            [-7.3431, 38.4572],
            [-7.1855, 38.0062],
            [-7.5036, 37.5854],
            [-7.4061, 37.1793]
          ]
        ]
      },
      properties: { name: "Portugal", id: "PT" },
      id: "PT"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [9.632, 40.8822],
              [9.8051, 40.4996],
              [9.5623, 39.1661],
              [9.0563, 39.2394],
              [8.8814, 38.9127],
              [8.418, 39.2056],
              [8.4712, 40.2927],
              [8.1898, 40.6518],
              [9.2831, 41.2018],
              [9.632, 40.8822]
            ]
          ],
          [
            [
              [15.5766, 38.2202],
              [15.0994, 37.4585],
              [15.2943, 37.0133],
              [15.1127, 36.6879],
              [14.5019, 36.7989],
              [12.4356, 37.8198],
              [13.3518, 38.1807],
              [13.6816, 38.0009],
              [14.5059, 38.0453],
              [14.846, 38.1718],
              [15.5766, 38.2202]
            ]
          ],
          [
            [
              [13.6998, 46.5201],
              [13.3993, 46.3177],
              [13.7198, 45.5875],
              [13.2062, 45.7713],
              [12.2745, 45.4459],
              [12.5235, 44.9678],
              [12.2483, 44.7223],
              [12.3961, 44.2238],
              [13.564, 43.5713],
              [14.0106, 42.6898],
              [14.8659, 42.0523],
              [15.1687, 41.9338],
              [15.9641, 41.9396],
              [16.0125, 41.4353],
              [17.1032, 41.062],
              [17.955, 40.6554],
              [18.4859, 40.105],
              [18.0779, 39.9367],
              [17.8649, 40.2803],
              [16.9283, 40.4579],
              [16.5301, 39.8595],
              [16.5976, 39.6389],
              [17.1147, 39.3805],
              [17.1746, 38.9979],
              [16.5589, 38.7147],
              [16.5456, 38.4093],
              [16.0569, 37.9419],
              [15.7244, 37.9392],
              [15.9726, 38.7125],
              [16.1967, 38.7591],
              [16.0236, 39.3535],
              [15.6929, 39.99],
              [14.9507, 40.239],
              [14.7656, 40.6683],
              [14.1024, 40.8272],
              [13.7331, 41.2356],
              [13.0886, 41.244],
              [12.6309, 41.4695],
              [11.6375, 42.2876],
              [10.7084, 42.9361],
              [10.0475, 44.0201],
              [8.766, 44.4223],
              [8.0815, 43.9189],
              [7.4929, 43.7671],
              [7.6771, 44.0831],
              [6.9673, 44.2802],
              [7.0308, 44.7166],
              [6.6277, 45.1178],
              [7.1533, 45.401],
              [7.021, 45.9257],
              [7.9932, 46.0158],
              [8.4584, 46.2458],
              [8.9537, 45.8298],
              [9.2512, 46.2866],
              [9.9392, 46.3616],
              [10.4528, 46.865],
              [11.0249, 46.7971],
              [11.2442, 46.9755],
              [12.1542, 46.9351],
              [12.3881, 46.7025],
              [13.6998, 46.5201]
            ],
            [
              [12.4853, 43.9016],
              [12.5146, 43.9531],
              [12.441, 43.9824],
              [12.397, 43.9344],
              [12.4853, 43.9016]
            ]
          ]
        ]
      },
      properties: { name: "Italy", id: "IT" },
      id: "IT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.5663, 35.8529],
            [14.4363, 35.8219],
            [14.3524, 35.8725],
            [14.4482, 35.9572],
            [14.5663, 35.8529]
          ]
        ]
      },
      properties: { name: "Malta", id: "MT" },
      id: "MT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [47.3027, -11.575],
            [47.282, -11.5746],
            [47.279, -11.5574],
            [47.2971, -11.5543],
            [47.3027, -11.575]
          ]
        ]
      },
      properties: { name: "Glorioso Islands", id: "GO", Continent: "Africa" },
      id: "GO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.7579, -17.0752],
            [42.7417, -17.0744],
            [42.7382, -17.0519],
            [42.7609, -17.0651],
            [42.7579, -17.0752]
          ]
        ]
      },
      properties: { name: "Juan De Nova Island", id: "JU", Continent: "Africa" },
      id: "JU"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.2877, -22.4023],
            [29.663, -22.1465],
            [29.3647, -22.194],
            [29.0373, -21.8112],
            [28.0455, -21.573],
            [27.6697, -21.0642],
            [27.6996, -20.5307],
            [26.1685, -19.5382],
            [25.2589, -17.7937],
            [25.8636, -17.9523],
            [27.0207, -17.9585],
            [27.9326, -16.8963],
            [28.7604, -16.532],
            [28.913, -15.988],
            [29.7299, -15.6447],
            [30.396, -15.643],
            [30.4379, -15.9954],
            [31.2363, -16.024],
            [31.9399, -16.4287],
            [32.9479, -16.7121],
            [32.9932, -18.3597],
            [32.7164, -19.0017],
            [33.0069, -19.8737],
            [32.4777, -20.7129],
            [32.3714, -21.3352],
            [31.2877, -22.4023]
          ]
        ]
      },
      properties: { name: "Zimbabwe", id: "ZW", Continent: "Africa" },
      id: "ZW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.9198, -9.4072],
            [33.351, -9.8619],
            [33.6591, -10.5906],
            [33.2614, -10.8934],
            [33.2262, -11.5349],
            [33.398, -12.49],
            [33.0213, -12.6301],
            [32.9678, -13.2251],
            [32.6724, -13.6105],
            [33.202, -14.0134],
            [30.2317, -14.9904],
            [30.396, -15.643],
            [29.7299, -15.6447],
            [28.913, -15.988],
            [28.7604, -16.532],
            [27.9326, -16.8963],
            [27.0207, -17.9585],
            [25.8636, -17.9523],
            [25.2589, -17.7937],
            [24.275, -17.4812],
            [23.3806, -17.6403],
            [22.1506, -16.5975],
            [21.9797, -15.9554],
            [21.9788, -13.001],
            [23.8434, -13.001],
            [23.9915, -12.4222],
            [23.9665, -10.8714],
            [24.5184, -11.4384],
            [25.1846, -11.2432],
            [25.3494, -11.6234],
            [26.0257, -11.8905],
            [26.9494, -11.8989],
            [28.3575, -12.482],
            [29.014, -13.3688],
            [29.7954, -13.3929],
            [29.7954, -12.1556],
            [29.0641, -12.3488],
            [28.357, -11.4827],
            [28.6392, -10.6693],
            [28.6304, -9.8312],
            [28.4005, -9.2248],
            [28.9722, -8.465],
            [30.7512, -8.1939],
            [31.9425, -9.0538],
            [32.9198, -9.4072]
          ]
        ]
      },
      properties: { name: "Zambia", id: "ZM", Continent: "Africa" },
      id: "ZM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.2877, -22.4023],
            [31.5457, -23.482],
            [31.9861, -24.4607],
            [31.9483, -25.9579],
            [31.4152, -25.7465],
            [30.8034, -26.4135],
            [31.0636, -27.1123],
            [31.9584, -27.3056],
            [32.113, -26.8394],
            [32.886, -26.8496],
            [32.5345, -28.2],
            [32.2857, -28.6214],
            [31.3352, -29.3782],
            [30.2885, -30.9702],
            [28.8562, -32.2943],
            [27.8605, -33.0536],
            [26.4291, -33.7594],
            [24.8273, -34.169],
            [23.5858, -33.9848],
            [22.2455, -34.0692],
            [21.7889, -34.3725],
            [20.5299, -34.463],
            [19.6352, -34.7536],
            [18.8086, -34.1079],
            [17.8783, -32.9614],
            [18.2509, -32.652],
            [18.2105, -31.7423],
            [17.6775, -31.019],
            [16.9501, -29.4036],
            [16.4474, -28.6174],
            [17.056, -28.0312],
            [17.4481, -28.6983],
            [18.1028, -28.8719],
            [19.2459, -28.9017],
            [19.9806, -28.4513],
            [19.9806, -24.777],
            [20.3453, -25.0302],
            [20.8226, -26.1205],
            [20.74, -26.8491],
            [21.6465, -26.8543],
            [22.6402, -26.0713],
            [22.8788, -25.4577],
            [23.266, -25.2666],
            [24.7482, -25.8176],
            [25.5836, -25.6063],
            [25.9123, -24.7476],
            [26.8352, -24.2409],
            [27.1463, -23.5241],
            [28.2102, -22.6936],
            [29.3647, -22.194],
            [29.663, -22.1465],
            [31.2877, -22.4023]
          ],
          [
            [27.8301, -28.9092],
            [27.0518, -29.6642],
            [27.3884, -30.3159],
            [28.0181, -30.642],
            [28.3922, -30.1476],
            [29.098, -29.919],
            [29.3906, -29.2696],
            [28.6255, -28.5813],
            [27.8301, -28.9092]
          ]
        ]
      },
      properties: { name: "South Africa", id: "ZA", Continent: "Africa" },
      id: "ZA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.9029, -1.0022],
            [30.7423, -1.0074],
            [30.51, -1.0672],
            [30.1017, -1.3687],
            [29.5769, -1.388],
            [29.7176, 0.0982],
            [29.9426, 0.8193],
            [31.2526, 2.0445],
            [30.7283, 2.4555],
            [30.8386, 3.4904],
            [31.1523, 3.7856],
            [31.7984, 3.8024],
            [32.0994, 3.529],
            [32.8381, 3.7984],
            [33.4894, 3.7549],
            [33.9758, 4.2203],
            [34.3929, 3.6916],
            [34.9642, 2.0626],
            [34.7876, 1.2307],
            [34.4817, 1.0421],
            [33.943, 0.1738],
            [33.9029, -1.0022]
          ]
        ]
      },
      properties: { name: "Uganda", id: "UG", Continent: "Africa" },
      id: "UG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.9029, -1.0022],
            [35.1976, -1.6794],
            [37.6439, -3.0452],
            [37.7974, -3.6745],
            [39.2221, -4.6922],
            [38.819, -5.8779],
            [38.8741, -6.3314],
            [39.4721, -6.879],
            [39.2884, -7.5179],
            [39.3037, -8.4439],
            [39.7837, -9.9148],
            [40.4635, -10.464],
            [39.989, -10.8209],
            [38.4917, -11.4133],
            [37.9204, -11.2946],
            [37.5416, -11.6752],
            [36.5186, -11.7165],
            [35.785, -11.4533],
            [34.9598, -11.5785],
            [34.5978, -11.0375],
            [34.5244, -10.0299],
            [34.3208, -9.7319],
            [32.9198, -9.4072],
            [31.9425, -9.0538],
            [30.7512, -8.1939],
            [30.3135, -7.2038],
            [29.7097, -6.617],
            [29.4802, -6.0252],
            [29.5941, -5.6512],
            [29.3427, -4.9828],
            [29.4033, -4.4493],
            [30.1469, -4.0854],
            [30.7902, -3.2746],
            [30.5147, -2.9173],
            [30.5534, -2.4001],
            [30.8289, -2.3385],
            [30.8127, -1.5633],
            [30.51, -1.0672],
            [30.7423, -1.0074],
            [33.9029, -1.0022]
          ]
        ]
      },
      properties: { name: "Tanzania", id: "TZ", Continent: "Africa" },
      id: "TZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.5044, 33.182],
            [11.5049, 32.4139],
            [10.8264, 32.0807],
            [10.1149, 31.4637],
            [10.2164, 30.783],
            [9.519, 30.2293],
            [9.0439, 32.0724],
            [8.3333, 32.5439],
            [7.7629, 33.233],
            [7.5138, 34.0807],
            [8.2459, 34.7337],
            [8.3944, 35.2039],
            [8.2305, 36.5456],
            [8.5763, 36.9371],
            [9.6877, 37.3405],
            [10.5715, 36.8796],
            [10.5908, 35.8872],
            [11.1199, 35.2404],
            [10.1184, 34.2802],
            [10.1588, 33.85],
            [11.5044, 33.182]
          ]
        ]
      },
      properties: { name: "Tunisia", id: "TN", Continent: "Africa" },
      id: "TN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.9008, 10.9932],
            [0.78, 10.3599],
            [1.3298, 9.9969],
            [1.6, 9.0499],
            [1.6225, 6.2167],
            [1.1874, 6.0897],
            [0.5256, 6.8508],
            [0.6345, 7.3536],
            [0.4153, 8.6526],
            [0.5291, 9.3584],
            [0.2619, 9.4955],
            [0.3626, 10.2364],
            [-0.0578, 10.6306],
            [-0.0686, 11.1158],
            [0.9008, 10.9932]
          ]
        ]
      },
      properties: { name: "Togo", id: "TG", Continent: "Africa" },
      id: "TG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.8599, 10.9193],
            [22.4938, 10.9962],
            [21.7257, 10.3665],
            [20.7733, 9.4059],
            [20.3423, 9.1272],
            [19.1457, 9.0161],
            [19.1088, 8.6562],
            [18.4552, 8.0321],
            [17.6497, 7.9838],
            [16.7849, 7.5509],
            [16.3788, 7.6836],
            [15.4802, 7.5237],
            [15.1163, 8.5573],
            [14.3323, 9.2033],
            [13.9772, 9.6919],
            [14.2431, 9.9797],
            [15.5413, 9.9604],
            [15.1321, 10.6482],
            [15.0812, 11.8452],
            [14.516, 12.9794],
            [14.0638, 13.0787],
            [13.6063, 13.7046],
            [13.4481, 14.3805],
            [14.3679, 15.7498],
            [15.4745, 16.9086],
            [15.735, 19.9039],
            [15.9491, 20.3034],
            [15.1822, 21.5233],
            [14.9792, 22.9959],
            [15.9842, 23.445],
            [19.1896, 21.8621],
            [22.0641, 20.4427],
            [23.9805, 19.497],
            [23.9836, 15.7801],
            [23.1051, 15.7023],
            [22.5097, 14.1273],
            [22.1076, 13.7301],
            [22.2284, 13.3293],
            [21.8254, 12.7905],
            [22.3523, 12.6604],
            [22.5562, 11.6695],
            [22.9227, 11.3447],
            [22.8599, 10.9193]
          ]
        ]
      },
      properties: { name: "Chad", id: "TD", Continent: "Africa" },
      id: "TD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.5403, -4.6931],
            [55.3834, -4.6091],
            [55.4559, -4.5586],
            [55.5403, -4.6931]
          ]
        ]
      },
      properties: { name: "Seychelles", id: "SC", Continent: "Africa" },
      id: "SC"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.9483, -25.9579],
            [32.113, -26.8394],
            [31.9584, -27.3056],
            [31.0636, -27.1123],
            [30.8034, -26.4135],
            [31.4152, -25.7465],
            [31.9483, -25.9579]
          ]
        ]
      },
      properties: { name: "Eswatini", id: "SZ", Continent: "Africa" },
      id: "SZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.6603, 0.1207],
            [6.5241, 0.3403],
            [6.6867, 0.404],
            [6.75, 0.2437],
            [6.6603, 0.1207]
          ]
        ]
      },
      properties: { name: "Sao Tome and Principe", id: "ST", Continent: "Africa" },
      id: "ST"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [41.5327, -1.6956],
            [40.9791, -0.8703],
            [40.9644, 2.8145],
            [41.8839, 3.9781],
            [42.7918, 4.2923],
            [43.1257, 4.6448],
            [43.9889, 4.9506],
            [44.9406, 4.912],
            [45.9348, 5.997],
            [47.9781, 7.9969],
            [46.9784, 7.9969],
            [44.023, 8.9857],
            [43.483, 9.3794],
            [42.6594, 10.6214],
            [42.9226, 10.9993],
            [43.2462, 11.4999],
            [43.8531, 10.784],
            [44.3866, 10.4302],
            [44.9428, 10.4364],
            [45.8169, 10.8362],
            [46.5654, 10.7457],
            [47.4051, 11.1738],
            [48.9032, 11.2546],
            [50.11, 11.5292],
            [50.7925, 11.9837],
            [51.2547, 11.8307],
            [51.0842, 11.3355],
            [51.1409, 10.6565],
            [50.8983, 10.2531],
            [50.8249, 9.4282],
            [49.3484, 6.9906],
            [49.0491, 6.1737],
            [47.9755, 4.4967],
            [46.0512, 2.4752],
            [44.3329, 1.3911],
            [43.4676, 0.6216],
            [41.9796, -0.9727],
            [41.5327, -1.6956]
          ]
        ]
      },
      properties: { name: "Somalia", id: "SO", Continent: "Africa" },
      id: "SO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-10.2832, 8.4852],
            [-10.6475, 7.7597],
            [-11.5076, 6.9067],
            [-12.4855, 7.3861],
            [-12.8808, 7.8568],
            [-13.2928, 9.0494],
            [-12.756, 9.3733],
            [-12.5016, 9.862],
            [-11.2056, 9.9776],
            [-10.6906, 9.3144],
            [-10.2832, 8.4852]
          ]
        ]
      },
      properties: { name: "Sierra Leone", id: "SL", Continent: "Africa" },
      id: "SL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-5.6923, -15.9977],
            [-5.7751, -15.9567],
            [-5.7078, -15.9062],
            [-5.6923, -15.9977]
          ]
        ]
      },
      properties: { name: "Saint Helena", id: "SH", Continent: "Africa" },
      id: "SH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-12.2807, 14.8089],
            [-12.0193, 14.2064],
            [-12.0544, 13.6329],
            [-11.3902, 12.9416],
            [-11.3894, 12.4043],
            [-12.2911, 12.3277],
            [-13.0828, 12.6332],
            [-13.7294, 12.6736],
            [-15.1963, 12.6802],
            [-15.5749, 12.4904],
            [-16.712, 12.355],
            [-16.7634, 13.0638],
            [-15.8344, 13.1561],
            [-15.5097, 13.5863],
            [-16.5624, 13.5873],
            [-17.1679, 14.6406],
            [-17.147, 14.9223],
            [-16.5353, 15.8381],
            [-16.1684, 16.5474],
            [-15.1128, 16.6449],
            [-14.3, 16.5804],
            [-13.8685, 16.1479],
            [-13.4095, 16.0592],
            [-12.8584, 15.2427],
            [-12.2807, 14.8089]
          ]
        ]
      },
      properties: { name: "Senegal", id: "SN", Continent: "Africa" },
      id: "SN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.0779, 9.4612],
            [34.0945, 8.5823],
            [33.234, 8.3964],
            [33.0126, 7.9517],
            [33.666, 7.6713],
            [34.7107, 6.6605],
            [35.2684, 5.4925],
            [33.9758, 4.2203],
            [33.4894, 3.7549],
            [32.8381, 3.7984],
            [32.0994, 3.529],
            [31.7984, 3.8024],
            [31.1523, 3.7856],
            [30.8386, 3.4904],
            [30.1948, 3.9821],
            [29.6767, 4.5868],
            [29.1511, 4.3882],
            [28.1923, 4.3503],
            [27.4034, 5.1093],
            [27.0835, 5.7768],
            [26.5139, 6.0691],
            [26.3619, 6.6355],
            [25.381, 7.3334],
            [24.8537, 8.1376],
            [24.2916, 8.2914],
            [24.1471, 8.6654],
            [24.532, 8.8869],
            [24.7851, 9.7745],
            [25.1037, 10.312],
            [25.7981, 10.4205],
            [26.5513, 9.5258],
            [27.886, 9.5996],
            [28.049, 9.3285],
            [28.8444, 9.3259],
            [30.0033, 10.2773],
            [30.7397, 9.7429],
            [31.2248, 9.7991],
            [32.4039, 11.0577],
            [32.3354, 11.716],
            [33.199, 12.2174],
            [33.0732, 11.5916],
            [33.172, 10.8503],
            [33.892, 10.1991],
            [34.0779, 9.4612]
          ]
        ]
      },
      properties: { name: "South Sudan", id: "SS", Continent: "Africa" },
      id: "SS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.8714, 21.997],
            [37.1505, 21.1036],
            [37.2484, 19.5822],
            [37.4712, 18.8202],
            [38.6094, 18.0051],
            [38.2536, 17.5845],
            [37.4111, 17.0615],
            [37.009, 17.0589],
            [36.9135, 16.2965],
            [36.4267, 15.1319],
            [36.5243, 14.2565],
            [36.1076, 12.7268],
            [35.5961, 12.5374],
            [35.1123, 11.8167],
            [34.9312, 10.8648],
            [34.2756, 10.5282],
            [34.0779, 9.4612],
            [33.892, 10.1991],
            [33.172, 10.8503],
            [33.0732, 11.5916],
            [33.199, 12.2174],
            [32.3354, 11.716],
            [32.4039, 11.0577],
            [31.2248, 9.7991],
            [30.7397, 9.7429],
            [30.0033, 10.2773],
            [28.8444, 9.3259],
            [28.049, 9.3285],
            [27.886, 9.5996],
            [26.5513, 9.5258],
            [25.7981, 10.4205],
            [25.1037, 10.312],
            [24.7851, 9.7745],
            [24.532, 8.8869],
            [24.1471, 8.6654],
            [23.5376, 8.8161],
            [23.6461, 9.8229],
            [22.8599, 10.9193],
            [22.9227, 11.3447],
            [22.5562, 11.6695],
            [22.3523, 12.6604],
            [21.8254, 12.7905],
            [22.2284, 13.3293],
            [22.1076, 13.7301],
            [22.5097, 14.1273],
            [23.1051, 15.7023],
            [23.9836, 15.7801],
            [23.9805, 19.497],
            [23.9805, 19.9958],
            [24.9671, 19.9953],
            [24.9803, 21.9957],
            [28.8005, 21.9949],
            [31.4345, 21.9957],
            [33.2622, 21.9957],
            [36.8714, 21.997]
          ]
        ]
      },
      properties: { name: "Sudan", id: "SD", Continent: "Africa" },
      id: "SD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.6834, 27.6563],
            [-8.6834, 27.2859],
            [-8.682, 25.9957],
            [-12.0164, 25.9957],
            [-12.0235, 23.4675],
            [-13.0316, 22.9999],
            [-13.0162, 21.3339],
            [-16.9647, 21.3291],
            [-17.0482, 20.8061],
            [-16.9308, 21.8999],
            [-16.5144, 22.3332],
            [-16.2104, 23.0983],
            [-15.5863, 24.073],
            [-15.0388, 24.5489],
            [-14.4139, 26.2536],
            [-13.5758, 26.7348],
            [-13.1774, 27.6519],
            [-8.6834, 27.6563]
          ]
        ]
      },
      properties: { name: "Western Sahara", id: "EH", Continent: "Africa" },
      id: "EH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.51, -1.0672],
            [30.8127, -1.5633],
            [30.8289, -2.3385],
            [30.5534, -2.4001],
            [29.9303, -2.3394],
            [29.8683, -2.7165],
            [29.0145, -2.7204],
            [29.1296, -1.8601],
            [29.5769, -1.388],
            [30.1017, -1.3687],
            [30.51, -1.0672]
          ]
        ]
      },
      properties: { name: "Rwanda", id: "RW", Continent: "Africa" },
      id: "RW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.7974, -21.3397],
            [55.3628, -21.2738],
            [55.3113, -20.9042],
            [55.662, -20.9064],
            [55.7974, -21.3397]
          ]
        ]
      },
      properties: { name: "Reunion", id: "RE", Continent: "Africa" },
      id: "RE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-17.1908, 32.8687],
            [-16.6933, 32.7579],
            [-16.8374, 32.6485],
            [-17.171, 32.7219],
            [-17.1908, 32.8687]
          ]
        ]
      },
      properties: { name: "Madeira", id: "PT", Continent: "Africa" },
      id: "PT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.6063, 13.7046],
            [14.0638, 13.0787],
            [14.1974, 12.384],
            [14.6272, 12.1089],
            [14.5595, 11.4919],
            [13.892, 11.14],
            [12.9296, 9.4261],
            [12.8065, 8.8864],
            [12.4035, 8.5959],
            [11.5519, 6.697],
            [11.1533, 6.4377],
            [10.6062, 7.0631],
            [10.1439, 6.9963],
            [9.0602, 6.0093],
            [8.5561, 4.7551],
            [8.2934, 4.5578],
            [7.1547, 4.5147],
            [6.0763, 4.2906],
            [5.5876, 4.6474],
            [4.8612, 6.0264],
            [4.126, 6.4114],
            [2.7066, 6.3692],
            [2.686, 7.8739],
            [2.7747, 9.0486],
            [3.045, 9.0842],
            [3.8342, 10.6073],
            [3.4875, 11.3953],
            [3.5956, 11.6962],
            [3.6466, 12.5299],
            [3.948, 12.7751],
            [4.1476, 13.4576],
            [5.4919, 13.8729],
            [6.3861, 13.6039],
            [6.9372, 13.008],
            [7.7884, 13.3376],
            [8.7504, 12.9083],
            [9.6161, 12.8103],
            [10.1847, 13.2699],
            [11.4121, 13.3539],
            [12.4633, 13.0937],
            [12.6549, 13.3266],
            [13.6063, 13.7046]
          ]
        ]
      },
      properties: { name: "Nigeria", id: "NG", Continent: "Africa" },
      id: "NG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.9792, 22.9959],
            [15.1822, 21.5233],
            [15.9491, 20.3034],
            [15.735, 19.9039],
            [15.4745, 16.9086],
            [14.3679, 15.7498],
            [13.4481, 14.3805],
            [13.6063, 13.7046],
            [12.6549, 13.3266],
            [12.4633, 13.0937],
            [11.4121, 13.3539],
            [10.1847, 13.2699],
            [9.6161, 12.8103],
            [8.7504, 12.9083],
            [7.7884, 13.3376],
            [6.9372, 13.008],
            [6.3861, 13.6039],
            [5.4919, 13.8729],
            [4.1476, 13.4576],
            [3.948, 12.7751],
            [3.6466, 12.5299],
            [3.5956, 11.6962],
            [2.8784, 12.3678],
            [2.3889, 11.8971],
            [1.9564, 12.7074],
            [1.5653, 12.6354],
            [0.987, 13.0418],
            [0.9778, 13.5516],
            [0.6183, 13.7037],
            [0.1639, 14.4973],
            [0.2175, 14.9113],
            [0.9602, 14.9869],
            [1.3003, 15.2721],
            [3.5042, 15.356],
            [3.8769, 15.7555],
            [4.2346, 16.9965],
            [4.2275, 19.1428],
            [5.8368, 19.479],
            [7.4817, 20.8729],
            [10.4146, 22.6017],
            [11.9676, 23.518],
            [13.4815, 23.18],
            [14.2308, 22.6184],
            [14.9792, 22.9959]
          ]
        ]
      },
      properties: { name: "Niger", id: "NE", Continent: "Africa" },
      id: "NE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.3806, -17.6403],
            [24.275, -17.4812],
            [25.2589, -17.7937],
            [24.3589, -17.9782],
            [23.5806, -18.4529],
            [23.2985, -18.0274],
            [20.9738, -18.3188],
            [20.9707, -22.0001],
            [19.9775, -22.0001],
            [19.9806, -24.777],
            [19.9806, -28.4513],
            [19.2459, -28.9017],
            [18.1028, -28.8719],
            [17.4481, -28.6983],
            [17.056, -28.0312],
            [16.4474, -28.6174],
            [15.7193, -27.9658],
            [15.3413, -27.3865],
            [14.9677, -26.3182],
            [14.8372, -25.0332],
            [14.5015, -24.2022],
            [14.5261, -22.7028],
            [14.3218, -22.1899],
            [13.4503, -20.9169],
            [13.1687, -20.1844],
            [12.4585, -18.927],
            [11.776, -18.0019],
            [11.7431, -17.2497],
            [12.5482, -17.2128],
            [13.101, -16.9675],
            [13.9381, -17.389],
            [16.9136, -17.3916],
            [18.3964, -17.3995],
            [18.8257, -17.7659],
            [21.4167, -18.0006],
            [23.3806, -17.6403]
          ]
        ]
      },
      properties: { name: "Namibia", id: "NA", Continent: "Africa" },
      id: "NA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [45.1802, -12.9764],
            [45.0695, -12.8959],
            [45.1349, -12.7092],
            [45.2232, -12.7519],
            [45.1802, -12.9764]
          ]
        ]
      },
      properties: { name: "Mayotte", id: "YT", Continent: "Africa" },
      id: "YT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.9598, -11.5785],
            [34.6597, -11.5886],
            [34.3608, -12.2104],
            [34.5639, -13.3601],
            [35.2476, -13.8966],
            [35.8474, -14.671],
            [35.7557, -16.0583],
            [35.1672, -16.5601],
            [34.3955, -16.1994],
            [34.2478, -15.8878],
            [34.541, -15.2971],
            [34.3323, -14.4085],
            [33.6362, -14.5685],
            [33.202, -14.0134],
            [32.6724, -13.6105],
            [32.9678, -13.2251],
            [33.0213, -12.6301],
            [33.398, -12.49],
            [33.2262, -11.5349],
            [33.2614, -10.8934],
            [33.6591, -10.5906],
            [33.351, -9.8619],
            [32.9198, -9.4072],
            [34.3208, -9.7319],
            [34.5244, -10.0299],
            [34.5978, -11.0375],
            [34.9598, -11.5785]
          ]
        ]
      },
      properties: { name: "Malawi", id: "MW", Continent: "Africa" },
      id: "MW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [57.6514, -20.4849],
            [57.386, -20.2282],
            [57.5758, -19.9972],
            [57.7921, -20.2125],
            [57.6514, -20.4849]
          ]
        ]
      },
      properties: { name: "Mauritius", id: "MU", Continent: "Africa" },
      id: "MU"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-12.2807, 14.8089],
            [-12.8584, 15.2427],
            [-13.4095, 16.0592],
            [-13.8685, 16.1479],
            [-14.3, 16.5804],
            [-15.1128, 16.6449],
            [-16.1684, 16.5474],
            [-16.5353, 15.8381],
            [-16.5357, 16.2868],
            [-16.079, 17.5459],
            [-16.0849, 18.521],
            [-16.5147, 19.3621],
            [-16.2106, 20.2278],
            [-16.4298, 20.6523],
            [-17.0482, 20.8061],
            [-16.9647, 21.3291],
            [-13.0162, 21.3339],
            [-13.0316, 22.9999],
            [-12.0235, 23.4675],
            [-12.0164, 25.9957],
            [-8.682, 25.9957],
            [-8.6834, 27.2859],
            [-6.9908, 26.2905],
            [-4.8225, 24.9959],
            [-6.594, 24.9946],
            [-6.1688, 21.2855],
            [-5.9696, 19.5449],
            [-5.6286, 16.5685],
            [-5.36, 16.2829],
            [-5.5125, 15.4967],
            [-9.1769, 15.4962],
            [-10.6965, 15.4228],
            [-10.8956, 15.1504],
            [-11.5027, 15.6368],
            [-11.8727, 14.9952],
            [-12.2807, 14.8089]
          ]
        ]
      },
      properties: { name: "Mauritania", id: "MR", Continent: "Africa" },
      id: "MR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.886, -26.8496],
            [32.113, -26.8394],
            [31.9483, -25.9579],
            [31.9861, -24.4607],
            [31.5457, -23.482],
            [31.2877, -22.4023],
            [32.3714, -21.3352],
            [32.4777, -20.7129],
            [33.0069, -19.8737],
            [32.7164, -19.0017],
            [32.9932, -18.3597],
            [32.9479, -16.7121],
            [31.9399, -16.4287],
            [31.2363, -16.024],
            [30.4379, -15.9954],
            [30.396, -15.643],
            [30.2317, -14.9904],
            [33.202, -14.0134],
            [33.6362, -14.5685],
            [34.3323, -14.4085],
            [34.541, -15.2971],
            [34.2478, -15.8878],
            [34.3955, -16.1994],
            [35.1672, -16.5601],
            [35.7557, -16.0583],
            [35.8474, -14.671],
            [35.2476, -13.8966],
            [34.5639, -13.3601],
            [34.3608, -12.2104],
            [34.6597, -11.5886],
            [34.9598, -11.5785],
            [35.785, -11.4533],
            [36.5186, -11.7165],
            [37.5416, -11.6752],
            [37.9204, -11.2946],
            [38.4917, -11.4133],
            [39.989, -10.8209],
            [40.4635, -10.464],
            [40.4029, -11.332],
            [40.5803, -12.6358],
            [40.6401, -14.3901],
            [40.8349, -14.7917],
            [40.5589, -15.4733],
            [39.8448, -16.4358],
            [39.0841, -16.9727],
            [38.145, -17.2426],
            [37.2445, -17.74],
            [36.5405, -18.5179],
            [34.948, -19.813],
            [34.6984, -20.404],
            [35.2679, -21.6512],
            [35.3157, -22.3969],
            [35.5754, -22.9634],
            [35.1559, -24.541],
            [33.3475, -25.2608],
            [32.792, -25.6445],
            [32.886, -26.8496]
          ]
        ]
      },
      properties: { name: "Mozambique", id: "MZ", Continent: "Africa" },
      id: "MZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.2275, 19.1428],
            [4.2346, 16.9965],
            [3.8769, 15.7555],
            [3.5042, 15.356],
            [1.3003, 15.2721],
            [0.9602, 14.9869],
            [0.2175, 14.9113],
            [-0.7605, 15.0475],
            [-1.7677, 14.4864],
            [-2.4572, 14.2741],
            [-3.5758, 13.1939],
            [-4.3286, 13.1188],
            [-4.4288, 12.3379],
            [-5.2882, 11.8276],
            [-5.5235, 10.4258],
            [-6.0346, 10.1947],
            [-6.2611, 10.7242],
            [-7.1049, 10.2034],
            [-7.6612, 10.4271],
            [-7.9908, 10.1626],
            [-8.2316, 10.4381],
            [-8.4076, 11.3864],
            [-8.822, 11.6734],
            [-9.0431, 12.4024],
            [-9.8207, 12.0425],
            [-10.2749, 12.2126],
            [-10.6774, 11.8997],
            [-11.3894, 12.4043],
            [-11.3902, 12.9416],
            [-12.0544, 13.6329],
            [-12.0193, 14.2064],
            [-12.2807, 14.8089],
            [-11.8727, 14.9952],
            [-11.5027, 15.6368],
            [-10.8956, 15.1504],
            [-10.6965, 15.4228],
            [-9.1769, 15.4962],
            [-5.5125, 15.4967],
            [-5.36, 16.2829],
            [-5.6286, 16.5685],
            [-5.9696, 19.5449],
            [-6.1688, 21.2855],
            [-6.594, 24.9946],
            [-4.8225, 24.9959],
            [-3.2579, 23.9811],
            [-1.2931, 22.6962],
            [1.1456, 21.1023],
            [1.6853, 20.3785],
            [3.2036, 19.7897],
            [3.4005, 18.9885],
            [4.2275, 19.1428]
          ]
        ]
      },
      properties: { name: "Mali", id: "ML", Continent: "Africa" },
      id: "ML"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [49.5382, -12.4324],
            [49.9373, -13.0727],
            [50.2352, -14.7319],
            [50.4831, -15.3854],
            [49.449, -17.2404],
            [49.4781, -17.8983],
            [48.607, -20.4577],
            [47.9083, -22.466],
            [47.5581, -23.8743],
            [47.1775, -24.7871],
            [46.7284, -25.1496],
            [45.1155, -25.543],
            [44.0353, -24.9959],
            [43.6873, -24.3577],
            [43.7225, -23.5295],
            [43.3982, -22.8866],
            [43.2668, -22.0493],
            [43.5019, -21.3563],
            [43.8557, -21.0769],
            [44.4045, -19.9219],
            [44.4485, -19.4285],
            [44.0398, -18.2886],
            [43.9796, -17.3916],
            [44.4216, -16.7026],
            [44.4762, -16.2173],
            [46.3316, -15.7138],
            [47.8116, -14.5447],
            [47.9953, -13.9607],
            [48.7965, -13.2673],
            [48.8997, -12.4587],
            [49.5382, -12.4324]
          ]
        ]
      },
      properties: { name: "Madagascar", id: "MG", Continent: "Africa" },
      id: "MG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.2197, 35.1042],
            [-1.7956, 34.7522],
            [-1.6792, 33.3187],
            [-1.0657, 32.4683],
            [-1.2753, 32.0891],
            [-2.4484, 32.13],
            [-3.8268, 31.6615],
            [-3.9852, 30.9136],
            [-4.9682, 30.4653],
            [-5.4488, 29.9569],
            [-6.5008, 29.8092],
            [-7.6852, 29.3495],
            [-8.6599, 28.7185],
            [-8.6834, 27.6563],
            [-13.1774, 27.6519],
            [-12.9489, 27.9143],
            [-11.4302, 28.3823],
            [-10.4865, 29.0648],
            [-9.6671, 30.1094],
            [-9.8754, 30.718],
            [-9.8086, 31.4246],
            [-9.2459, 32.5725],
            [-8.5962, 33.1872],
            [-6.901, 33.9691],
            [-6.3532, 34.7759],
            [-5.9249, 35.7858],
            [-5.3974, 35.9299],
            [-5.1053, 35.4676],
            [-4.3299, 35.1613],
            [-3.6932, 35.2799],
            [-2.2197, 35.1042]
          ]
        ]
      },
      properties: { name: "Morocco", id: "MA", Continent: "Africa" },
      id: "MA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.8301, -28.9092],
            [28.6255, -28.5813],
            [29.3906, -29.2696],
            [29.098, -29.919],
            [28.3922, -30.1476],
            [28.0181, -30.642],
            [27.3884, -30.3159],
            [27.0518, -29.6642],
            [27.8301, -28.9092]
          ]
        ]
      },
      properties: { name: "Lesotho", id: "LS", Continent: "Africa" },
      id: "LS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.1503, 31.6549],
            [24.8528, 31.335],
            [24.9736, 30.7764],
            [24.7113, 30.1317],
            [24.9803, 29.1817],
            [24.9803, 27.1606],
            [24.9803, 24.0172],
            [24.9803, 21.9957],
            [24.9671, 19.9953],
            [23.9805, 19.9958],
            [23.9805, 19.497],
            [22.0641, 20.4427],
            [19.1896, 21.8621],
            [15.9842, 23.445],
            [14.9792, 22.9959],
            [14.2308, 22.6184],
            [13.4815, 23.18],
            [11.9676, 23.518],
            [11.5075, 24.3143],
            [10.2555, 24.5911],
            [10.001, 25.332],
            [9.4482, 26.0672],
            [9.8592, 26.5519],
            [9.7475, 27.3307],
            [9.9163, 27.7856],
            [9.8424, 28.9668],
            [9.519, 30.2293],
            [10.2164, 30.783],
            [10.1149, 31.4637],
            [10.8264, 32.0807],
            [11.5049, 32.4139],
            [11.5044, 33.182],
            [12.2796, 32.8586],
            [13.2833, 32.9148],
            [15.1765, 32.391],
            [15.4965, 31.6567],
            [16.1231, 31.2642],
            [17.3492, 31.0814],
            [18.1902, 30.7774],
            [18.9365, 30.2904],
            [19.7134, 30.4886],
            [20.1511, 31.0783],
            [19.9261, 31.8175],
            [20.6209, 32.5799],
            [21.6356, 32.9372],
            [23.0907, 32.6186],
            [23.2866, 32.2139],
            [24.6841, 32.0162],
            [25.1503, 31.6549]
          ]
        ]
      },
      properties: { name: "Libya", id: "LY", Continent: "Africa" },
      id: "LY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.4863, 7.5583],
            [-8.2966, 7.0741],
            [-8.6036, 6.5077],
            [-7.8886, 6.2348],
            [-7.4542, 5.8414],
            [-7.545, 4.3513],
            [-9.1323, 5.0548],
            [-10.2764, 6.0774],
            [-11.5076, 6.9067],
            [-10.6475, 7.7597],
            [-10.2832, 8.4852],
            [-9.5181, 8.3463],
            [-9.1176, 7.2156],
            [-8.4863, 7.5583]
          ]
        ]
      },
      properties: { name: "Liberia", id: "LR", Continent: "Africa" },
      id: "LR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [41.8839, 3.9781],
            [40.9644, 2.8145],
            [40.9791, -0.8703],
            [41.5327, -1.6956],
            [40.9957, -1.9505],
            [40.6442, -2.5393],
            [40.2223, -2.6883],
            [40.1155, -3.2505],
            [39.4909, -4.4787],
            [39.2221, -4.6922],
            [37.7974, -3.6745],
            [37.6439, -3.0452],
            [35.1976, -1.6794],
            [33.9029, -1.0022],
            [33.943, 0.1738],
            [34.4817, 1.0421],
            [34.7876, 1.2307],
            [34.9642, 2.0626],
            [34.3929, 3.6916],
            [33.9758, 4.2203],
            [35.2684, 5.4925],
            [35.7447, 5.3444],
            [35.7632, 4.8078],
            [36.0817, 4.4497],
            [36.9057, 4.4114],
            [38.0865, 3.649],
            [39.4946, 3.4561],
            [39.8426, 3.8516],
            [40.7654, 4.273],
            [41.2207, 3.9439],
            [41.8839, 3.9781]
          ]
        ]
      },
      properties: { name: "Kenya", id: "KE", Continent: "Africa" },
      id: "KE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.5906, 1.0316],
            [9.8007, 2.3043],
            [9.98, 2.1676],
            [11.3287, 2.1672],
            [11.3353, 1],
            [9.5906, 1.0316]
          ]
        ]
      },
      properties: { name: "Equatorial Guinea", id: "GQ", Continent: "Africa" },
      id: "GQ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-15.0431, 10.9404],
            [-15.3932, 11.2173],
            [-15.5017, 11.724],
            [-16.1383, 11.9173],
            [-16.712, 12.355],
            [-15.5749, 12.4904],
            [-15.1963, 12.6802],
            [-13.7294, 12.6736],
            [-13.7327, 11.7358],
            [-14.683, 11.5086],
            [-15.0431, 10.9404]
          ]
        ]
      },
      properties: { name: "Guinea-Bissau", id: "GW", Continent: "Africa" },
      id: "GW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.7634, 13.0638],
            [-16.5624, 13.5873],
            [-15.5097, 13.5863],
            [-15.8344, 13.1561],
            [-16.7634, 13.0638]
          ]
        ]
      },
      properties: { name: "Gambia", id: "GM", Continent: "Africa" },
      id: "GM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-11.3894, 12.4043],
            [-10.6774, 11.8997],
            [-10.2749, 12.2126],
            [-9.8207, 12.0425],
            [-9.0431, 12.4024],
            [-8.822, 11.6734],
            [-8.4076, 11.3864],
            [-8.2316, 10.4381],
            [-7.9908, 10.1626],
            [-7.8236, 8.4676],
            [-8.2321, 7.5566],
            [-8.4863, 7.5583],
            [-9.1176, 7.2156],
            [-9.5181, 8.3463],
            [-10.2832, 8.4852],
            [-10.6906, 9.3144],
            [-11.2056, 9.9776],
            [-12.5016, 9.862],
            [-12.756, 9.3733],
            [-13.2928, 9.0494],
            [-13.7004, 9.851],
            [-14.427, 10.2483],
            [-14.7757, 10.9316],
            [-15.0431, 10.9404],
            [-14.683, 11.5086],
            [-13.7327, 11.7358],
            [-13.7294, 12.6736],
            [-13.0828, 12.6332],
            [-12.2911, 12.3277],
            [-11.3894, 12.4043]
          ]
        ]
      },
      properties: { name: "Guinea", id: "GN", Continent: "Africa" },
      id: "GN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.0686, 11.1158],
            [-0.0578, 10.6306],
            [0.3626, 10.2364],
            [0.2619, 9.4955],
            [0.5291, 9.3584],
            [0.4153, 8.6526],
            [0.6345, 7.3536],
            [0.5256, 6.8508],
            [1.1874, 6.0897],
            [0.9501, 5.8102],
            [0.2597, 5.7575],
            [-0.7976, 5.2266],
            [-2.0017, 4.7621],
            [-3.114, 5.0887],
            [-3.0867, 5.1286],
            [-3.019, 5.1308],
            [-2.7951, 5.1844],
            [-3.2008, 6.3481],
            [-3.2357, 6.8073],
            [-2.7899, 7.9319],
            [-2.5057, 8.2087],
            [-2.6958, 9.4814],
            [-2.8299, 10.9984],
            [-0.4917, 11.0072],
            [-0.0686, 11.1158]
          ]
        ]
      },
      properties: { name: "Ghana", id: "GH", Continent: "Africa" },
      id: "GH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.2939, 2.1614],
            [13.2741, 1.2408],
            [14.1811, 1.37],
            [14.4343, 0.8114],
            [13.8845, 0.1905],
            [13.8599, -0.2037],
            [14.4809, -0.6181],
            [14.3837, -1.8899],
            [14.0875, -2.4669],
            [12.992, -2.3135],
            [11.5752, -2.3975],
            [11.5379, -2.8368],
            [11.9343, -3.3189],
            [11.13, -3.9161],
            [10.3473, -3.0132],
            [9.6245, -2.367],
            [9.2659, -1.8253],
            [8.8216, -0.7082],
            [9.2971, -0.3514],
            [9.5906, 1.0316],
            [11.3353, 1],
            [11.3287, 2.1672],
            [11.3484, 2.2994],
            [13.2205, 2.2563],
            [13.2939, 2.1614]
          ]
        ]
      },
      properties: { name: "Gabon", id: "GA", Continent: "Africa" },
      id: "GA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.3783, 12.4666],
            [41.7665, 11.589],
            [41.7982, 10.9804],
            [42.9226, 10.9993],
            [42.6594, 10.6214],
            [43.483, 9.3794],
            [44.023, 8.9857],
            [46.9784, 7.9969],
            [47.9781, 7.9969],
            [45.9348, 5.997],
            [44.9406, 4.912],
            [43.9889, 4.9506],
            [43.1257, 4.6448],
            [42.7918, 4.2923],
            [41.8839, 3.9781],
            [41.2207, 3.9439],
            [40.7654, 4.273],
            [39.8426, 3.8516],
            [39.4946, 3.4561],
            [38.0865, 3.649],
            [36.9057, 4.4114],
            [36.0817, 4.4497],
            [35.7632, 4.8078],
            [35.7447, 5.3444],
            [35.2684, 5.4925],
            [34.7107, 6.6605],
            [33.666, 7.6713],
            [33.0126, 7.9517],
            [33.234, 8.3964],
            [34.0945, 8.5823],
            [34.0779, 9.4612],
            [34.2756, 10.5282],
            [34.9312, 10.8648],
            [35.1123, 11.8167],
            [35.5961, 12.5374],
            [36.1076, 12.7268],
            [36.5243, 14.2565],
            [37.2572, 14.4538],
            [37.5711, 14.1493],
            [37.8844, 14.8524],
            [38.5045, 14.4244],
            [39.074, 14.6283],
            [40.2213, 14.431],
            [40.7699, 14.1444],
            [42.3783, 12.4666]
          ]
        ]
      },
      properties: { name: "Ethiopia", id: "ET", Continent: "Africa" },
      id: "ET"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.1968, 28.1691],
            [-14.0034, 28.7066],
            [-13.8276, 28.5849],
            [-13.928, 28.2535],
            [-14.1968, 28.1691]
          ]
        ]
      },
      properties: { name: "Canary Islands", id: "ES", Continent: "Africa" },
      id: "ES"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.6094, 18.0051],
            [39.1425, 16.7293],
            [39.5064, 15.5318],
            [40.2042, 15.0141],
            [41.1764, 14.6204],
            [41.6583, 13.9831],
            [42.2451, 13.5877],
            [42.7961, 12.8643],
            [43.1165, 12.7083],
            [42.3783, 12.4666],
            [40.7699, 14.1444],
            [40.2213, 14.431],
            [39.074, 14.6283],
            [38.5045, 14.4244],
            [37.8844, 14.8524],
            [37.5711, 14.1493],
            [37.2572, 14.4538],
            [36.5243, 14.2565],
            [36.4267, 15.1319],
            [36.9135, 16.2965],
            [37.009, 17.0589],
            [37.4111, 17.0615],
            [38.2536, 17.5845],
            [38.6094, 18.0051]
          ]
        ]
      },
      properties: { name: "Eritrea", id: "ER", Continent: "Africa" },
      id: "ER"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.8714, 21.997],
            [33.2622, 21.9957],
            [31.4345, 21.9957],
            [28.8005, 21.9949],
            [24.9803, 21.9957],
            [24.9803, 24.0172],
            [24.9803, 27.1606],
            [24.9803, 29.1817],
            [24.7113, 30.1317],
            [24.9736, 30.7764],
            [24.8528, 31.335],
            [25.1503, 31.6549],
            [27.2479, 31.378],
            [29.0725, 30.8305],
            [30.3952, 31.4576],
            [31.1942, 31.5876],
            [32.102, 31.0924],
            [33.6664, 31.1302],
            [34.2127, 31.2924],
            [34.904, 29.4774],
            [34.4, 28.0162],
            [33.547, 27.8985],
            [33.9592, 26.6487],
            [35.1941, 24.4751],
            [35.6246, 24.066],
            [35.5227, 23.4424],
            [35.6976, 22.9462],
            [36.8714, 21.997]
          ]
        ]
      },
      properties: { name: "Egypt", id: "EG", Continent: "Africa" },
      id: "EG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.5763, 36.9371],
            [8.2305, 36.5456],
            [8.3944, 35.2039],
            [8.2459, 34.7337],
            [7.5138, 34.0807],
            [7.7629, 33.233],
            [8.3333, 32.5439],
            [9.0439, 32.0724],
            [9.519, 30.2293],
            [9.8424, 28.9668],
            [9.9163, 27.7856],
            [9.7475, 27.3307],
            [9.8592, 26.5519],
            [9.4482, 26.0672],
            [10.001, 25.332],
            [10.2555, 24.5911],
            [11.5075, 24.3143],
            [11.9676, 23.518],
            [10.4146, 22.6017],
            [7.4817, 20.8729],
            [5.8368, 19.479],
            [4.2275, 19.1428],
            [3.4005, 18.9885],
            [3.2036, 19.7897],
            [1.6853, 20.3785],
            [1.1456, 21.1023],
            [-1.2931, 22.6962],
            [-3.2579, 23.9811],
            [-4.8225, 24.9959],
            [-6.9908, 26.2905],
            [-8.6834, 27.2859],
            [-8.6834, 27.6563],
            [-8.6599, 28.7185],
            [-7.6852, 29.3495],
            [-6.5008, 29.8092],
            [-5.4488, 29.9569],
            [-4.9682, 30.4653],
            [-3.9852, 30.9136],
            [-3.8268, 31.6615],
            [-2.4484, 32.13],
            [-1.2753, 32.0891],
            [-1.0657, 32.4683],
            [-1.6792, 33.3187],
            [-1.7956, 34.7522],
            [-2.2197, 35.1042],
            [-1.9132, 35.0945],
            [-0.9176, 35.6684],
            [0.0479, 35.9004],
            [1.2573, 36.5196],
            [2.5932, 36.601],
            [3.7788, 36.8962],
            [4.7579, 36.8967],
            [5.1957, 36.677],
            [6.4867, 37.0857],
            [6.9275, 36.9195],
            [8.5763, 36.9371]
          ]
        ]
      },
      properties: { name: "Algeria", id: "DZ", Continent: "Africa" },
      id: "DZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.2462, 11.4999],
            [42.9226, 10.9993],
            [41.7982, 10.9804],
            [41.7665, 11.589],
            [42.3783, 12.4666],
            [43.1165, 12.7083],
            [43.4101, 12.1898],
            [42.7993, 11.7393],
            [43.2462, 11.4999]
          ]
        ]
      },
      properties: { name: "Djibouti", id: "DJ", Continent: "Africa" },
      id: "DJ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.4442, 15.0076],
            [-23.7055, 14.9609],
            [-23.7008, 15.2712],
            [-23.4442, 15.0076]
          ]
        ]
      },
      properties: { name: "Cape Verde", id: "CV", Continent: "Africa" },
      id: "CV"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.4658, -11.9015],
            [43.2268, -11.7517],
            [43.3792, -11.6141],
            [43.4658, -11.9015]
          ]
        ]
      },
      properties: { name: "Comoros", id: "KM", Continent: "Africa" },
      id: "KM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.6099, 3.4785],
            [18.072, 2.0133],
            [17.9024, 1.1178],
            [17.753, -0.5491],
            [16.8798, -1.2259],
            [16.2154, -2.1781],
            [16.1463, -3.464],
            [14.7793, -4.8456],
            [14.3583, -4.2993],
            [13.4148, -4.8373],
            [13.0728, -4.6352],
            [12.3846, -4.6188],
            [12.0181, -5.0042],
            [11.13, -3.9161],
            [11.9343, -3.3189],
            [11.5379, -2.8368],
            [11.5752, -2.3975],
            [12.992, -2.3135],
            [14.0875, -2.4669],
            [14.3837, -1.8899],
            [14.4809, -0.6181],
            [13.8599, -0.2037],
            [13.8845, 0.1905],
            [14.4343, 0.8114],
            [14.1811, 1.37],
            [13.2741, 1.2408],
            [13.2939, 2.1614],
            [14.4844, 2.1549],
            [15.7416, 1.9153],
            [16.1833, 2.2705],
            [16.6733, 3.5352],
            [17.4379, 3.6846],
            [18.6099, 3.4785]
          ]
        ]
      },
      properties: { name: "Republic of Congo", id: "CG", Continent: "Africa" },
      id: "CG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.4034, 5.1093],
            [28.1923, 4.3503],
            [29.1511, 4.3882],
            [29.6767, 4.5868],
            [30.1948, 3.9821],
            [30.8386, 3.4904],
            [30.7283, 2.4555],
            [31.2526, 2.0445],
            [29.9426, 0.8193],
            [29.7176, 0.0982],
            [29.5769, -1.388],
            [29.1296, -1.8601],
            [29.0145, -2.7204],
            [29.2245, -3.0536],
            [29.2232, -3.9109],
            [29.4033, -4.4493],
            [29.3427, -4.9828],
            [29.5941, -5.6512],
            [29.4802, -6.0252],
            [29.7097, -6.617],
            [30.3135, -7.2038],
            [30.7512, -8.1939],
            [28.9722, -8.465],
            [28.4005, -9.2248],
            [28.6304, -9.8312],
            [28.6392, -10.6693],
            [28.357, -11.4827],
            [29.0641, -12.3488],
            [29.7954, -12.1556],
            [29.7954, -13.3929],
            [29.014, -13.3688],
            [28.3575, -12.482],
            [26.9494, -11.8989],
            [26.0257, -11.8905],
            [25.3494, -11.6234],
            [25.1846, -11.2432],
            [24.5184, -11.4384],
            [23.9665, -10.8714],
            [23.8337, -11.0137],
            [22.5611, -11.056],
            [22.2746, -10.2592],
            [21.8131, -9.4692],
            [21.9058, -8.6935],
            [21.7511, -7.3052],
            [20.6077, -7.278],
            [20.482, -6.916],
            [19.5275, -7.1448],
            [19.3408, -7.9666],
            [17.5359, -8.076],
            [16.9849, -7.2573],
            [16.7177, -6.2413],
            [16.3151, -5.8661],
            [13.068, -5.8648],
            [12.4115, -5.9865],
            [12.2137, -5.7588],
            [12.5222, -5.1489],
            [13.0728, -4.6352],
            [13.4148, -4.8373],
            [14.3583, -4.2993],
            [14.7793, -4.8456],
            [16.1463, -3.464],
            [16.2154, -2.1781],
            [16.8798, -1.2259],
            [17.753, -0.5491],
            [17.9024, 1.1178],
            [18.072, 2.0133],
            [18.6099, 3.4785],
            [18.5677, 4.2576],
            [19.0687, 4.8913],
            [19.6862, 5.1216],
            [20.5581, 4.4625],
            [22.4222, 4.1351],
            [22.7557, 4.647],
            [23.4171, 4.6633],
            [24.3198, 4.9941],
            [25.2496, 5.0245],
            [25.5251, 5.3123],
            [26.822, 5.0622],
            [27.4034, 5.1093]
          ]
        ]
      },
      properties: { name: "Democratic Republic of Congo", id: "CD", Continent: "Africa" },
      id: "CD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [15.4802, 7.5237],
            [15.2459, 7.2635],
            [14.6171, 5.8647],
            [14.7089, 4.6654],
            [15.1286, 3.827],
            [16.0637, 2.9085],
            [16.1833, 2.2705],
            [15.7416, 1.9153],
            [14.4844, 2.1549],
            [13.2939, 2.1614],
            [13.2205, 2.2563],
            [11.3484, 2.2994],
            [11.3287, 2.1672],
            [9.98, 2.1676],
            [9.8007, 2.3043],
            [9.8762, 3.3098],
            [9.4254, 3.9223],
            [9, 4.0915],
            [8.5561, 4.7551],
            [9.0602, 6.0093],
            [10.1439, 6.9963],
            [10.6062, 7.0631],
            [11.1533, 6.4377],
            [11.5519, 6.697],
            [12.4035, 8.5959],
            [12.8065, 8.8864],
            [12.9296, 9.4261],
            [13.892, 11.14],
            [14.5595, 11.4919],
            [14.6272, 12.1089],
            [14.1974, 12.384],
            [14.0638, 13.0787],
            [14.516, 12.9794],
            [15.0812, 11.8452],
            [15.1321, 10.6482],
            [15.5413, 9.9604],
            [14.2431, 9.9797],
            [13.9772, 9.6919],
            [14.3323, 9.2033],
            [15.1163, 8.5573],
            [15.4802, 7.5237]
          ]
        ]
      },
      properties: { name: "Cameroon", id: "CM", Continent: "Africa" },
      id: "CM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-5.5235, 10.4258],
            [-4.6257, 9.7134],
            [-3.2236, 9.8954],
            [-2.6958, 9.4814],
            [-2.5057, 8.2087],
            [-2.7899, 7.9319],
            [-3.2357, 6.8073],
            [-3.2008, 6.3481],
            [-2.7951, 5.1844],
            [-3.019, 5.1308],
            [-4.0373, 5.2302],
            [-5.5646, 5.0895],
            [-7.545, 4.3513],
            [-7.4542, 5.8414],
            [-7.8886, 6.2348],
            [-8.6036, 6.5077],
            [-8.2966, 7.0741],
            [-8.4863, 7.5583],
            [-8.2321, 7.5566],
            [-7.8236, 8.4676],
            [-7.9908, 10.1626],
            [-7.6612, 10.4271],
            [-7.1049, 10.2034],
            [-6.2611, 10.7242],
            [-6.0346, 10.1947],
            [-5.5235, 10.4258]
          ]
        ]
      },
      properties: { name: "Côte d'Ivoire", id: "CI", Continent: "Africa" },
      id: "CI"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.8599, 10.9193],
            [23.6461, 9.8229],
            [23.5376, 8.8161],
            [24.1471, 8.6654],
            [24.2916, 8.2914],
            [24.8537, 8.1376],
            [25.381, 7.3334],
            [26.3619, 6.6355],
            [26.5139, 6.0691],
            [27.0835, 5.7768],
            [27.4034, 5.1093],
            [26.822, 5.0622],
            [25.5251, 5.3123],
            [25.2496, 5.0245],
            [24.3198, 4.9941],
            [23.4171, 4.6633],
            [22.7557, 4.647],
            [22.4222, 4.1351],
            [20.5581, 4.4625],
            [19.6862, 5.1216],
            [19.0687, 4.8913],
            [18.5677, 4.2576],
            [18.6099, 3.4785],
            [17.4379, 3.6846],
            [16.6733, 3.5352],
            [16.1833, 2.2705],
            [16.0637, 2.9085],
            [15.1286, 3.827],
            [14.7089, 4.6654],
            [14.6171, 5.8647],
            [15.2459, 7.2635],
            [15.4802, 7.5237],
            [16.3788, 7.6836],
            [16.7849, 7.5509],
            [17.6497, 7.9838],
            [18.4552, 8.0321],
            [19.1088, 8.6562],
            [19.1457, 9.0161],
            [20.3423, 9.1272],
            [20.7733, 9.4059],
            [21.7257, 10.3665],
            [22.4938, 10.9962],
            [22.8599, 10.9193]
          ]
        ]
      },
      properties: { name: "Central African Republic", id: "CF", Continent: "Africa" },
      id: "CF"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.2589, -17.7937],
            [26.1685, -19.5382],
            [27.6996, -20.5307],
            [27.6697, -21.0642],
            [28.0455, -21.573],
            [29.0373, -21.8112],
            [29.3647, -22.194],
            [28.2102, -22.6936],
            [27.1463, -23.5241],
            [26.8352, -24.2409],
            [25.9123, -24.7476],
            [25.5836, -25.6063],
            [24.7482, -25.8176],
            [23.266, -25.2666],
            [22.8788, -25.4577],
            [22.6402, -26.0713],
            [21.6465, -26.8543],
            [20.74, -26.8491],
            [20.8226, -26.1205],
            [20.3453, -25.0302],
            [19.9806, -24.777],
            [19.9775, -22.0001],
            [20.9707, -22.0001],
            [20.9738, -18.3188],
            [23.2985, -18.0274],
            [23.5806, -18.4529],
            [24.3589, -17.9782],
            [25.2589, -17.7937]
          ]
        ]
      },
      properties: { name: "Botswana", id: "BW", Continent: "Africa" },
      id: "BW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.2175, 14.9113],
            [0.1639, 14.4973],
            [0.6183, 13.7037],
            [0.9778, 13.5516],
            [0.987, 13.0418],
            [1.5653, 12.6354],
            [1.9564, 12.7074],
            [2.3889, 11.8971],
            [1.9802, 11.4185],
            [1.4264, 11.4471],
            [0.9008, 10.9932],
            [-0.0686, 11.1158],
            [-0.4917, 11.0072],
            [-2.8299, 10.9984],
            [-2.6958, 9.4814],
            [-3.2236, 9.8954],
            [-4.6257, 9.7134],
            [-5.5235, 10.4258],
            [-5.2882, 11.8276],
            [-4.4288, 12.3379],
            [-4.3286, 13.1188],
            [-3.5758, 13.1939],
            [-2.4572, 14.2741],
            [-1.7677, 14.4864],
            [-0.7605, 15.0475],
            [0.2175, 14.9113]
          ]
        ]
      },
      properties: { name: "Burkina Faso", id: "BF", Continent: "Africa" },
      id: "BF"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.5956, 11.6962],
            [3.4875, 11.3953],
            [3.8342, 10.6073],
            [3.045, 9.0842],
            [2.7747, 9.0486],
            [2.686, 7.8739],
            [2.7066, 6.3692],
            [1.6225, 6.2167],
            [1.6, 9.0499],
            [1.3298, 9.9969],
            [0.78, 10.3599],
            [0.9008, 10.9932],
            [1.4264, 11.4471],
            [1.9802, 11.4185],
            [2.3889, 11.8971],
            [2.8784, 12.3678],
            [3.5956, 11.6962]
          ]
        ]
      },
      properties: { name: "Benin", id: "BJ", Continent: "Africa" },
      id: "BJ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.5534, -2.4001],
            [30.5147, -2.9173],
            [30.7902, -3.2746],
            [30.1469, -4.0854],
            [29.4033, -4.4493],
            [29.2232, -3.9109],
            [29.2245, -3.0536],
            [29.0145, -2.7204],
            [29.8683, -2.7165],
            [29.9303, -2.3394],
            [30.5534, -2.4001]
          ]
        ]
      },
      properties: { name: "Burundi", id: "BI", Continent: "Africa" },
      id: "BI"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [12.2137, -5.7588],
              [12.0181, -5.0042],
              [12.3846, -4.6188],
              [13.0728, -4.6352],
              [12.5222, -5.1489],
              [12.2137, -5.7588]
            ]
          ],
          [
            [
              [23.9665, -10.8714],
              [23.9915, -12.4222],
              [23.8434, -13.001],
              [21.9788, -13.001],
              [21.9797, -15.9554],
              [22.1506, -16.5975],
              [23.3806, -17.6403],
              [21.4167, -18.0006],
              [18.8257, -17.7659],
              [18.3964, -17.3995],
              [16.9136, -17.3916],
              [13.9381, -17.389],
              [13.101, -16.9675],
              [12.5482, -17.2128],
              [11.7431, -17.2497],
              [11.7505, -15.8319],
              [11.9676, -15.6342],
              [12.5508, -13.4378],
              [12.9832, -12.7761],
              [13.4169, -12.5202],
              [13.7856, -11.8127],
              [13.8476, -11.0547],
              [12.9985, -9.0482],
              [13.3783, -8.3696],
              [12.3025, -6.0928],
              [13.068, -5.8648],
              [16.3151, -5.8661],
              [16.7177, -6.2413],
              [16.9849, -7.2573],
              [17.5359, -8.076],
              [19.3408, -7.9666],
              [19.5275, -7.1448],
              [20.482, -6.916],
              [20.6077, -7.278],
              [21.7511, -7.3052],
              [21.9058, -8.6935],
              [21.8131, -9.4692],
              [22.2746, -10.2592],
              [22.5611, -11.056],
              [23.8337, -11.0137],
              [23.9665, -10.8714]
            ]
          ]
        ]
      },
      properties: { name: "Angola", id: "AO", Continent: "Africa" },
      id: "AO"
    },
    //DISABLE
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [53.0855, 16.6485],
            [52.3279, 16.2938],
            [52.2176, 15.6553],
            [51.3224, 15.226],
            [49.3497, 14.638],
            [48.6685, 14.0504],
            [47.99, 14.0482],
            [47.4078, 13.6615],
            [46.789, 13.4655],
            [45.6574, 13.3385],
            [45.0387, 12.816],
            [43.9299, 12.6165],
            [43.4751, 12.8388],
            [43.2321, 13.2673],
            [43.2826, 13.6395],
            [42.717, 15.6544],
            [42.7992, 16.372],
            [43.1648, 16.6893],
            [43.1912, 17.3595],
            [43.9167, 17.3248],
            [45.1481, 17.4276],
            [45.4065, 17.3199],
            [46.6818, 17.2685],
            [46.9758, 16.9534],
            [47.442, 17.1116],
            [48.172, 18.1567],
            [49.0421, 18.5821],
            [51.9776, 18.996],
            [52.7291, 17.3006],
            [53.0855, 16.6485]
          ]
        ]
      },
      properties: { name: "Yemen", id: "YE", Continent: "Middle East" },
      id: "YE"
    },

    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.3589, 37.1085],
            [41.7885, 36.5974],
            [41.4167, 36.5148],
            [41.1943, 34.7689],
            [40.6894, 34.3321],
            [38.7734, 33.3719],
            [36.8183, 32.3172],
            [35.7873, 32.7351],
            [35.869, 33.4321],
            [36.5853, 34.2213],
            [36.4328, 34.6137],
            [35.9763, 34.6291],
            [35.7644, 35.5713],
            [36.1274, 35.8315],
            [36.6583, 36.8022],
            [37.4366, 36.6436],
            [38.1915, 36.9015],
            [39.357, 36.6818],
            [40.7057, 37.0975],
            [42.3589, 37.1085]
          ]
        ]
      },
      properties: { name: "Syria", id: "SY", Continent: "Middle East" },
      id: "SY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.5315, 29.0964],
            [47.4333, 28.9896],
            [47.6714, 28.533],
            [48.4422, 28.5427],
            [48.9067, 27.6291],
            [49.986, 26.8288],
            [50.0317, 26.1108],
            [50.8043, 24.7893],
            [51.2684, 24.6074],
            [51.5681, 24.2861],
            [51.5927, 24.0787],
            [52.5551, 22.9331],
            [55.1043, 22.6215],
            [55.1861, 22.7041],
            [55.6409, 22.0019],
            [54.9773, 19.9958],
            [51.9776, 18.996],
            [49.0421, 18.5821],
            [48.172, 18.1567],
            [47.442, 17.1116],
            [46.9758, 16.9534],
            [46.6818, 17.2685],
            [45.4065, 17.3199],
            [45.1481, 17.4276],
            [43.9167, 17.3248],
            [43.1912, 17.3595],
            [43.1648, 16.6893],
            [42.7992, 16.372],
            [42.2938, 17.4351],
            [41.7498, 17.8855],
            [41.2295, 18.6783],
            [40.7593, 19.7558],
            [39.6136, 20.5179],
            [39.0937, 21.3102],
            [38.9878, 21.8819],
            [39.0959, 22.393],
            [38.464, 23.7122],
            [37.9195, 24.1851],
            [37.5429, 24.2919],
            [37.1808, 24.8201],
            [37.1487, 25.2908],
            [35.1808, 28.0347],
            [34.6162, 28.1481],
            [34.9506, 29.3535],
            [36.0158, 29.1905],
            [36.755, 29.8663],
            [37.4695, 29.9951],
            [37.9802, 30.5],
            [36.9584, 31.4919],
            [38.9971, 32.0073],
            [39.1456, 32.1247],
            [40.3695, 31.9392],
            [42.0741, 31.0801],
            [42.8576, 30.4952],
            [44.7165, 29.1935],
            [46.5315, 29.0964]
          ]
        ]
      },
      properties: { name: "Saudi Arabia", id: "SA", Continent: "Middle East" },
      id: "SA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [51.2684, 24.6074],
            [50.8043, 24.7893],
            [50.763, 25.4445],
            [51.0034, 25.9815],
            [51.543, 25.902],
            [51.6019, 25.1479],
            [51.2684, 24.6074]
          ]
        ]
      },
      properties: { name: "Qatar", id: "QA", Continent: "Middle East" },
      id: "QA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [56.388, 24.9792],
            [56.6407, 24.4703],
            [57.2199, 23.9227],
            [58.5782, 23.6437],
            [59.7999, 22.2203],
            [59.3714, 21.4987],
            [58.8955, 21.1124],
            [58.4745, 20.4066],
            [57.8619, 20.2441],
            [57.7151, 19.6068],
            [57.8118, 19.0171],
            [57.1764, 18.9024],
            [56.6552, 18.5873],
            [56.2702, 17.9505],
            [55.4788, 17.8433],
            [55.0643, 17.0391],
            [54.0681, 17.0057],
            [53.0855, 16.6485],
            [52.7291, 17.3006],
            [51.9776, 18.996],
            [54.9773, 19.9958],
            [55.6409, 22.0019],
            [55.1861, 22.7041],
            [55.1997, 23.0346],
            [55.7609, 24.2426],
            [56.388, 24.9792]
          ]
        ]
      },
      properties: { name: "Oman", id: "OM", Continent: "Middle East" },
      id: "OM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.869, 33.4321],
            [35.1088, 33.0836],
            [35.9763, 34.6291],
            [36.4328, 34.6137],
            [36.5853, 34.2213],
            [35.869, 33.4321]
          ]
        ]
      },
      properties: { name: "Lebanon", id: "LB", Continent: "Middle East" },
      id: "LB"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [48.4422, 28.5427],
            [47.6714, 28.533],
            [47.4333, 28.9896],
            [46.5315, 29.0964],
            [47.1142, 29.9612],
            [47.9786, 29.9828],
            [47.9698, 29.6167],
            [48.4422, 28.5427]
          ]
        ]
      },
      properties: { name: "Kuwait", id: "KW", Continent: "Middle East" },
      id: "KW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [39.1456, 32.1247],
            [38.9971, 32.0073],
            [36.9584, 31.4919],
            [37.9802, 30.5],
            [37.4695, 29.9951],
            [36.755, 29.8663],
            [36.0158, 29.1905],
            [34.9506, 29.3535],
            [34.9734, 29.5552],
            [35.4507, 31.4791],
            [35.5513, 32.3954],
            [35.7873, 32.7351],
            [36.8183, 32.3172],
            [38.7734, 33.3719],
            [39.1456, 32.1247]
          ]
        ]
      },
      properties: { name: "Jordan", id: "JO", Continent: "Middle East" },
      id: "JO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.7873, 32.7351],
            [35.5513, 32.3954],
            [34.9559, 32.1611],
            [34.9506, 31.6022],
            [35.4507, 31.4791],
            [34.9734, 29.5552],
            [34.2453, 31.2084],
            [34.4777, 31.585],
            [35.1088, 33.0836],
            [35.869, 33.4321],
            [35.7873, 32.7351]
          ]
        ]
      },
      properties: { name: "Israel", id: "IL", Continent: "Middle East" },
      id: "IL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [44.7653, 37.1423],
            [45.3617, 36.0156],
            [45.7233, 35.8363],
            [46.1338, 35.1275],
            [45.6781, 34.7983],
            [45.3968, 33.9708],
            [46.1457, 33.2295],
            [46.1127, 32.9579],
            [47.3713, 32.424],
            [47.8296, 31.7942],
            [47.6793, 31.0023],
            [48.0151, 30.4653],
            [48.5468, 29.9626],
            [47.9786, 29.9828],
            [47.1142, 29.9612],
            [46.5315, 29.0964],
            [44.7165, 29.1935],
            [42.8576, 30.4952],
            [42.0741, 31.0801],
            [40.3695, 31.9392],
            [39.1456, 32.1247],
            [38.7734, 33.3719],
            [40.6894, 34.3321],
            [41.1943, 34.7689],
            [41.4167, 36.5148],
            [41.7885, 36.5974],
            [42.3589, 37.1085],
            [42.7746, 37.3718],
            [44.7653, 37.1423]
          ]
        ]
      },
      properties: { name: "Iraq", id: "IQ", Continent: "Middle East" },
      id: "IQ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [48.5468, 29.9626],
            [48.0151, 30.4653],
            [47.6793, 31.0023],
            [47.8296, 31.7942],
            [47.3713, 32.424],
            [46.1127, 32.9579],
            [46.1457, 33.2295],
            [45.3968, 33.9708],
            [45.6781, 34.7983],
            [46.1338, 35.1275],
            [45.7233, 35.8363],
            [45.3617, 36.0156],
            [44.7653, 37.1423],
            [44.5614, 37.7449],
            [44.2112, 37.9083],
            [44.4309, 38.357],
            [44.0231, 39.3774],
            [44.5873, 39.7685],
            [45.4799, 39.0065],
            [46.5548, 38.9041],
            [47.7729, 39.6486],
            [48.2423, 38.9788],
            [48.8404, 38.4374],
            [49.0812, 37.6675],
            [50.1306, 37.4069],
            [51.1185, 36.7424],
            [52.1903, 36.6216],
            [54.0171, 36.9525],
            [53.9143, 37.3436],
            [54.7453, 37.5018],
            [55.0753, 37.9022],
            [56.4407, 38.2493],
            [57.1939, 38.2164],
            [57.4238, 37.9479],
            [58.4358, 37.6385],
            [59.2409, 37.5207],
            [60.3413, 36.6379],
            [61.1196, 36.6427],
            [61.2822, 35.5533],
            [61.08, 34.8554],
            [60.4854, 34.0947],
            [60.5606, 33.1376],
            [60.8291, 32.2495],
            [60.8541, 31.4831],
            [61.6601, 31.3825],
            [61.7844, 30.8322],
            [60.8436, 29.8584],
            [61.3182, 29.3724],
            [61.6227, 28.7914],
            [62.3531, 28.4148],
            [62.7398, 28.0022],
            [62.7631, 27.2503],
            [63.1665, 27.2525],
            [63.1679, 26.6658],
            [62.4392, 26.5608],
            [61.8697, 26.2422],
            [61.6153, 25.2859],
            [61.4118, 25.1022],
            [59.4563, 25.4815],
            [57.796, 25.6533],
            [57.3346, 25.7917],
            [56.9825, 26.9053],
            [56.3559, 27.2002],
            [55.6501, 26.9778],
            [54.8956, 26.5564],
            [53.7056, 26.7256],
            [52.6918, 27.3237],
            [52.4759, 27.6163],
            [51.6665, 27.8453],
            [51.2789, 28.1314],
            [50.65, 29.4198],
            [49.983, 30.2091],
            [49.5549, 30.0289],
            [48.919, 30.1208],
            [48.5468, 29.9626]
          ]
        ]
      },
      properties: { name: "Iran", id: "IR", Continent: "Middle East" },
      id: "IR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [50.6074, 25.8831],
            [50.4659, 25.9657],
            [50.4523, 26.1907],
            [50.61, 26.1244],
            [50.6074, 25.8831]
          ]
        ]
      },
      properties: { name: "Bahrain", id: "BH", Continent: "Middle East" },
      id: "BH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [56.2979, 25.6507],
            [56.388, 24.9792],
            [55.7609, 24.2426],
            [55.1997, 23.0346],
            [55.1861, 22.7041],
            [55.1043, 22.6215],
            [52.5551, 22.9331],
            [51.5927, 24.0787],
            [51.5681, 24.2861],
            [51.906, 23.9856],
            [52.6482, 24.1547],
            [53.8932, 24.077],
            [54.3972, 24.2778],
            [54.7471, 24.8104],
            [55.941, 25.7939],
            [56.0804, 26.0624],
            [56.2979, 25.6507]
          ]
        ]
      },
      properties: { name: "United Arab Emirates", id: "AE", Continent: "Middle East" },
      id: "AE"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [41.5103, 41.5175],
              [42.4661, 41.4397],
              [42.7878, 41.5637],
              [43.439, 41.1071],
              [43.7225, 40.7196],
              [43.5691, 40.4821],
              [43.792, 40.0704],
              [44.2894, 40.0405],
              [44.7684, 39.7035],
              [44.8172, 39.6503],
              [44.3891, 39.4223],
              [44.0231, 39.3774],
              [44.4309, 38.357],
              [44.2112, 37.9084],
              [44.5614, 37.7449],
              [44.5737, 37.4355],
              [44.7939, 37.2904],
              [44.2819, 36.978],
              [44.2085, 37.203],
              [43.516, 37.2448],
              [42.7746, 37.3717],
              [42.456, 37.1287],
              [42.2683, 37.2764],
              [41.5155, 37.0891],
              [40.7057, 37.0975],
              [40.0166, 36.826],
              [39.3569, 36.6819],
              [38.7668, 36.6933],
              [38.1915, 36.9015],
              [37.4366, 36.6436],
              [36.6582, 36.8022],
              [36.5375, 36.4576],
              [36.1274, 35.8314],
              [35.8111, 36.3096],
              [36.1881, 36.6589],
              [36.0488, 36.9108],
              [35.5376, 36.597],
              [34.6012, 36.7846],
              [34.0233, 36.3408],
              [33.6946, 36.1817],
              [32.7947, 36.0358],
              [32.3776, 36.1834],
              [32.0221, 36.535],
              [31.3523, 36.801],
              [30.6439, 36.8655],
              [30.484, 36.3105],
              [29.6895, 36.1566],
              [29.1432, 36.397],
              [29.0382, 36.6937],
              [28.4835, 36.8035],
              [28.1338, 37.0299],
              [27.349, 37.0193],
              [27.5198, 37.2491],
              [27.2192, 37.3894],
              [27.0778, 37.6877],
              [27.2324, 37.9786],
              [26.6827, 38.1983],
              [26.8612, 38.3728],
              [26.7636, 38.7099],
              [27.0136, 38.887],
              [26.6818, 39.2922],
              [26.8268, 39.5628],
              [26.1132, 39.4676],
              [26.1813, 39.99],
              [26.7376, 40.4001],
              [27.2848, 40.4559],
              [27.4754, 40.3196],
              [28.2893, 40.4031],
              [28.7877, 40.534],
              [29.3647, 40.8096],
              [29.0456, 41.0074],
              [29.322, 41.2275],
              [29.9193, 41.1506],
              [30.3451, 41.1967],
              [30.8101, 41.0851],
              [31.2548, 41.1076],
              [31.4582, 41.3198],
              [32.3064, 41.7298],
              [33.3817, 42.0176],
              [34.7506, 41.957],
              [35.0068, 42.0634],
              [35.2978, 41.7289],
              [36.0514, 41.6824],
              [36.1793, 41.4266],
              [36.5097, 41.2627],
              [36.7778, 41.3633],
              [37.0661, 41.1844],
              [38.3809, 40.9243],
              [39.4264, 41.1062],
              [40.2653, 40.9612],
              [40.9596, 41.2117],
              [41.5103, 41.5175]
            ]
          ],
          [
            [
              [28.0142, 41.9689],
              [28.3465, 41.4666],
              [29.0571, 41.2297],
              [28.7802, 40.974],
              [28.172, 41.0808],
              [27.4997, 40.9731],
              [27.2579, 40.6875],
              [26.1052, 40.6114],
              [26.0394, 40.7269],
              [26.3311, 40.9546],
              [26.3307, 41.2385],
              [26.6247, 41.4016],
              [26.3211, 41.7166],
              [26.6155, 41.965],
              [27.2948, 42.0796],
              [27.5348, 41.921],
              [28.0142, 41.9689]
            ]
          ]
        ]
      },
      properties: { name: "Turkey", id: "TR", Continent: "Europe" },
      id: "TR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.4632, 35.5937],
            [33.9415, 35.2919],
            [34.0501, 34.9881],
            [32.9418, 34.576],
            [32.4488, 34.7293],
            [32.3169, 34.953],
            [32.9418, 35.3903],
            [33.4587, 35.3358],
            [34.4632, 35.5937]
          ]
        ]
      },
      properties: { name: "Cyprus", id: "CY", Continent: "Europe" },
      id: "CY"
    }
  ]
};
export default map;
