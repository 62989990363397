import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDescendantProp } from "../../../lib/fnHelper";
import Dropdown from "../Dropdown/Dropdown";

export default function GenericDropdownFilter({ column, options, title, loading = false, error = false, width }) {
  const { filterValue, setFilter } = column;
  const [filterLoading, setFilterLoading] = useState(false);

  //TO FIX HIDE COLUMNS ISSUE
  const bufferFilter = (options, clear = false) => {
    if (options.length || clear) {
      setFilterLoading(true);
      setTimeout(() => {
        setFilter(options);
      }, 300);
    } else if (!options.length && filterValue && filterValue.length && !clear) {
      //When unchecking last one
      setFilterLoading(true);
      setTimeout(() => {
        setFilter(options);
      }, 300);
    } else {
      //if (filterValue && filterValue.length && !clear) {
      //TO FIX HIDE COLUMNS ISSUE

      setTimeout(() => setFilter(filterValue), 300);
    }
  };

  useEffect(() => {
    setFilterLoading(false);
  }, [filterValue]);

  useEffect(() => {
    if (filterValue && filterValue.length) {
      setFilter(filterValue);
    }
  }, [column]);

  return (
    <div>
      <Dropdown
        title={title}
        items={options}
        loading={loading}
        filterValue={filterValue} //TO FIX HIDE COLUMNS ISSUE
        error={error}
        multiSelect
        setTableFilter={bufferFilter}
        clearTableFilter={options => bufferFilter(options, true)} //TO FIX HIDE COLUMNS ISSUE
        filterLoading={filterLoading}
        width={width}
      />
    </div>
  );
}

export function ApplyGenericDropdownFilter(
  rows,
  columnsId,
  filterValue,
  useOriginal = false,
  showAllSubjurs = false,
  exact = true,
  alwaysFalse
) {
  console.log("FILTERV", filterValue);
  if (filterValue && filterValue.length > 0) {
    let allFiltered = [];
    console.log("FILTERV", filterValue);
    for (let filter of filterValue) {
      console.log("FILTER", filter);
      allFiltered = [
        ...allFiltered,
        ...rows.filter(row => {
          if (alwaysFalse && alwaysFalse(row)) return false;
          //if (!showAllSubjurs && row.original.motherEntry?.subjurisdiction?.id) return true;
          let colValue = row.values[columnsId];
          if (useOriginal) {
            colValue = getDescendantProp(row.original, columnsId);
          }

          if (colValue !== undefined && colValue !== null)
            return typeof colValue.indexOf === "function" && !exact //for true/false conditions
              ? colValue.indexOf(filter) !== -1
              : colValue === filter;

          if (!colValue && filter === null) return true; // FOR NULLS

          return false;
        }),
        ...rows.filter(row => {
          return (
            row.subRows &&
            row.subRows.length &&
            row.subRows.filter(subrow => {
              let colValue = subrow.values[columnsId];
              if (useOriginal) {
                colValue = getDescendantProp(subrow.original, columnsId);
              }
              return typeof colValue.indexOf === "function" && !exact
                ? colValue.indexOf(filter) !== -1
                : colValue === filter;
            })
          );
        })
      ];
    }

    let filterTemp = [...allFiltered];

    allFiltered = allFiltered.map(row => ({
      ...row,
      ...(row.original.cid && //Become new parent
        !filterTemp.find(r => r.id === row.original.cid.split(".")[0]) && {
          original: { ...row.original, subRowsLength: 0, cid: "" }
        }),
      ...(!row.original.cid && {
        //Find childs
        original: {
          ...row.original,
          subRowsLength: filterTemp.filter(r => r.original.cid && r.original.cid.split(".")[0] === row.id).length
        }
      })
    }));

    let noChilds = allFiltered.filter(r => !r.original.cid);

    for (const row of allFiltered.filter(r => r.original.cid)) {
      noChilds.splice(noChilds.findIndex(r => r.id === row.original.cid.split(".")[0]) + 1, 0, row);
    }

    return noChilds;
  } else {
    return rows;
  }
}
