import { gql, useMutation, useQuery } from '@apollo/client'

const GET_USER = gql(`
{
  loggedUser{
    id
    firstname
    lang
  }
}
`)

const SET_CURRENT_USER_LANGUAGE = gql(`
  mutation SetCurrentUserLanguage($id: ID!, $input: String!) {
    setCurrentUserLanguage(id: $id, input: $input) {
      id
      lang
    }
  }
`)

const CREATE_USER = gql(`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`)

const UPDATE_USER = gql(`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstname
      lastname
      pronoun
      email
      organisation
    }
  }
`)

function useGqlUsers() {
  const [setUserLanguage] = useMutation(SET_CURRENT_USER_LANGUAGE)
  const [createUser] = useMutation(CREATE_USER)
  const [updateUser] = useMutation(UPDATE_USER)
  const currentUser = useQuery(GET_USER)
  return { setUserLanguage, currentUser, createUser, updateUser }
}

export default useGqlUsers
