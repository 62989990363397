var map = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-62.6509, -22.2339],
              [-61.7985, -23.1823],
              [-61.033, -23.7557],
              [-59.8924, -24.0932],
              [-59.373, -24.4536],
              [-57.8215, -25.1365],
              [-57.5873, -25.405],
              [-58.2032, -26.3815],
              [-58.1913, -26.6302],
              [-58.6187, -27.1321],
              [-58.1682, -27.2736],
              [-56.4372, -27.5539],
              [-56.1641, -27.3215],
              [-55.7148, -27.4151],
              [-55.4265, -27.0095],
              [-54.7552, -26.5331],
              [-54.6159, -25.576],
              [-53.8912, -25.6687],
              [-53.6713, -26.225],
              [-53.8381, -27.1211],
              [-54.829, -27.5509],
              [-55.1015, -27.8668],
              [-55.7255, -28.2043],
              [-56.9386, -29.5948],
              [-57.609, -30.188],
              [-58.1889, -31.9239],
              [-58.2012, -32.4719],
              [-58.5473, -33.6637],
              [-58.2836, -34.6836],
              [-57.3036, -35.1886],
              [-57.2649, -36.1444],
              [-56.698, -36.4265],
              [-56.6721, -36.8514],
              [-57.3957, -37.7449],
              [-57.5471, -38.0859],
              [-58.1792, -38.4361],
              [-59.8283, -38.8382],
              [-61.1121, -38.9929],
              [-62.0671, -38.9191],
              [-62.287, -39.8955],
              [-62.3951, -40.8909],
              [-62.9589, -41.1097],
              [-63.773, -41.1497],
              [-64.8532, -40.814],
              [-65.1333, -40.8804],
              [-65.0591, -41.9698],
              [-64.4205, -42.4338],
              [-63.5959, -42.4066],
              [-63.6926, -42.8052],
              [-64.1307, -42.8614],
              [-65.2836, -43.63],
              [-65.3614, -44.4773],
              [-65.6387, -45.0077],
              [-66.19, -44.9646],
              [-66.9415, -45.2573],
              [-67.5567, -45.9701],
              [-67.5633, -46.3454],
              [-66.7769, -47.0059],
              [-65.9986, -47.0938],
              [-65.7382, -47.3447],
              [-65.8101, -47.941],
              [-67.0331, -48.6279],
              [-67.6848, -49.2467],
              [-67.7835, -49.8593],
              [-68.9397, -50.3826],
              [-69.2352, -50.9504],
              [-69.0326, -51.6364],
              [-68.4433, -52.3567],
              [-69.9603, -52.0086],
              [-71.9187, -51.9897],
              [-72.4076, -51.541],
              [-72.2762, -50.9104],
              [-72.5098, -50.6076],
              [-73.1529, -50.7386],
              [-73.5763, -49.5828],
              [-72.6144, -48.7927],
              [-72.3549, -48.366],
              [-72.5179, -47.876],
              [-71.9051, -47.2014],
              [-71.6953, -46.5783],
              [-71.7726, -45.7244],
              [-71.3536, -45.2305],
              [-71.5962, -44.9796],
              [-71.2127, -44.4412],
              [-71.8201, -44.3832],
              [-71.6801, -43.9297],
              [-72.1463, -42.9897],
              [-72.1248, -42.2985],
              [-71.7609, -42.1016],
              [-71.9115, -41.6507],
              [-71.9321, -40.6918],
              [-71.6953, -40.3354],
              [-71.7199, -39.6354],
              [-71.4256, -38.9854],
              [-70.9517, -38.7385],
              [-71.1677, -37.762],
              [-71.0556, -36.5236],
              [-70.4048, -36.0618],
              [-70.4703, -35.3261],
              [-70.2899, -34.7328],
              [-69.8524, -34.2244],
              [-69.7979, -33.3987],
              [-70.0198, -33.2717],
              [-70.1697, -32.4719],
              [-70.5852, -31.5692],
              [-70.1613, -30.4403],
              [-69.9563, -30.3581],
              [-70.0269, -29.3241],
              [-69.6568, -28.4139],
              [-69.1743, -27.9244],
              [-68.8463, -27.1536],
              [-68.3462, -27.0279],
              [-68.5811, -26.5181],
              [-68.4145, -26.1538],
              [-68.6002, -25.4859],
              [-68.3842, -25.0921],
              [-68.562, -24.8377],
              [-68.2502, -24.3921],
              [-67.3561, -24.0339],
              [-67.0089, -23.0012],
              [-67.1948, -22.8215],
              [-66.7116, -22.2163],
              [-66.0986, -21.8353],
              [-65.771, -22.0994],
              [-64.6056, -22.2291],
              [-64.374, -22.7613],
              [-63.9763, -22.0726],
              [-62.8433, -21.9975],
              [-62.6509, -22.2339]
            ]
          ],
          [
            [
              [-68.6531, -54.8536],
              [-68.6298, -52.6524],
              [-68.0085, -53.5638],
              [-67.2943, -54.0499],
              [-65.346, -54.8778],
              [-66.5112, -55.0321],
              [-66.9305, -54.9248],
              [-68.6531, -54.8536]
            ]
          ]
        ]
      },
      properties: { name: "Argentina", id: "AR", Continent: "South America" },
      id: "AR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-58.1599, -20.1645],
            [-58.1803, -19.8178],
            [-59.0904, -19.2861],
            [-60.0073, -19.2975],
            [-61.757, -19.6451],
            [-62.2762, -20.5627],
            [-62.2767, -21.0659],
            [-62.6509, -22.2339],
            [-62.8433, -21.9975],
            [-63.9763, -22.0726],
            [-64.374, -22.7613],
            [-64.6056, -22.2291],
            [-65.771, -22.0994],
            [-66.0986, -21.8353],
            [-66.7116, -22.2163],
            [-67.1948, -22.8215],
            [-67.8795, -22.8228],
            [-68.197, -21.3005],
            [-68.5, -20.6119],
            [-68.7452, -20.459],
            [-68.6984, -19.7211],
            [-68.4629, -19.4328],
            [-68.9684, -18.9683],
            [-69.0941, -18.0508],
            [-69.5109, -17.5058],
            [-69.625, -17.2],
            [-69.0207, -16.6423],
            [-69.4184, -15.6034],
            [-69.1726, -15.2369],
            [-69.3747, -14.9632],
            [-68.8803, -14.199],
            [-69.0741, -13.6826],
            [-68.9786, -12.8802],
            [-68.6852, -12.5022],
            [-69.5786, -10.9518],
            [-68.398, -11.0186],
            [-66.7301, -9.9754],
            [-65.3961, -9.7126],
            [-65.39, -11.2463],
            [-64.9925, -11.9753],
            [-64.4205, -12.4398],
            [-63.0675, -12.6692],
            [-62.7653, -12.9975],
            [-62.176, -13.1337],
            [-61.79, -13.5257],
            [-61.077, -13.4897],
            [-60.5066, -13.7898],
            [-60.2989, -14.6186],
            [-60.1756, -16.2692],
            [-58.538, -16.3285],
            [-58.3961, -17.2343],
            [-57.8325, -17.5124],
            [-57.5741, -18.2793],
            [-57.8006, -19.0808],
            [-58.1315, -19.7444],
            [-58.1599, -20.1645]
          ]
        ]
      },
      properties: { name: "Bolivia", id: "BO", Continent: "South America" },
      id: "BO"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-56.4829, 1.9422],
              [-56.1296, 2.2994],
              [-55.0057, 2.593],
              [-54.6163, 2.3267],
              [-54.1301, 2.121],
              [-53.7678, 2.3552],
              [-52.9647, 2.1839],
              [-52.7006, 2.3636],
              [-51.9906, 3.7017],
              [-51.6527, 4.0612],
              [-51.2198, 4.0937],
              [-51.0524, 3.2821],
              [-50.6591, 2.1307],
              [-50.4589, 1.8297],
              [-49.9573, 1.66],
              [-49.8988, 1.1626],
              [-50.2945, 0.8356],
              [-50.8164, 0.1725],
              [-51.2829, -0.085],
              [-51.7026, -0.7622],
              [-51.9475, -1.5866],
              [-50.9177, -1.1151],
              [-50.6901, -1.7615],
              [-50.4033, -2.0156],
              [-49.7195, -1.9263],
              [-49.3137, -1.7317],
              [-48.9913, -1.8301],
              [-48.1151, -0.7376],
              [-47.3981, -0.6264],
              [-46.8112, -0.7798],
              [-45.6447, -1.348],
              [-45.0763, -1.4662],
              [-44.6513, -1.7457],
              [-44.3083, -2.5354],
              [-44.1128, -2.5983],
              [-42.9368, -2.4651],
              [-42.2497, -2.7921],
              [-41.8762, -2.7468],
              [-41.3181, -2.9362],
              [-40.4745, -2.7956],
              [-39.9648, -2.8615],
              [-38.4757, -3.7176],
              [-38.049, -4.2163],
              [-37.1747, -4.9124],
              [-36.5906, -5.0974],
              [-35.5494, -5.1295],
              [-35.2356, -5.5668],
              [-34.8056, -7.2886],
              [-34.8346, -7.9715],
              [-35.3408, -9.2305],
              [-36.3982, -10.4843],
              [-36.9378, -10.8204],
              [-37.412, -11.4972],
              [-37.6886, -12.0997],
              [-38.2397, -12.8441],
              [-38.7879, -12.7826],
              [-39.0894, -13.5881],
              [-38.9422, -14.0306],
              [-39.0597, -14.6551],
              [-38.8804, -15.8645],
              [-39.2153, -17.3156],
              [-39.154, -17.704],
              [-39.4869, -17.9901],
              [-39.74, -18.6396],
              [-39.7, -19.2782],
              [-40.3959, -20.5693],
              [-40.7892, -20.9063],
              [-41.0471, -21.5057],
              [-41.1227, -22.0845],
              [-41.7054, -22.3099],
              [-42.0423, -22.9472],
              [-43.5327, -23.046],
              [-43.866, -22.9102],
              [-44.6372, -23.0557],
              [-45.4232, -23.6854],
              [-45.9721, -23.7957],
              [-46.8672, -24.2365],
              [-47.8767, -24.9976],
              [-48.5451, -25.8159],
              [-48.6776, -26.7032],
              [-48.5543, -27.1962],
              [-48.6209, -28.0756],
              [-48.7997, -28.5752],
              [-49.7461, -29.3632],
              [-50.2994, -30.4258],
              [-51.1572, -30.3642],
              [-51.4637, -31.0529],
              [-51.9724, -31.3838],
              [-52.1273, -32.1678],
              [-52.6522, -33.1376],
              [-53.3707, -33.7423],
              [-53.5313, -33.171],
              [-53.1255, -32.7368],
              [-53.7618, -32.057],
              [-54.5876, -31.4848],
              [-55.8736, -31.0696],
              [-56.1762, -30.6284],
              [-56.8327, -30.1072],
              [-57.609, -30.188],
              [-56.9386, -29.5948],
              [-55.7255, -28.2043],
              [-55.1015, -27.8668],
              [-54.829, -27.5509],
              [-53.8381, -27.1211],
              [-53.6713, -26.225],
              [-53.8912, -25.6687],
              [-54.6159, -25.576],
              [-54.2419, -24.0471],
              [-54.6254, -23.8124],
              [-55.3663, -23.9913],
              [-55.8492, -22.3077],
              [-56.5238, -22.1025],
              [-56.9373, -22.2713],
              [-57.9856, -22.0463],
              [-57.8303, -20.9982],
              [-58.1599, -20.1645],
              [-58.1315, -19.7444],
              [-57.8006, -19.0808],
              [-57.5741, -18.2793],
              [-57.8325, -17.5124],
              [-58.3961, -17.2343],
              [-58.538, -16.3285],
              [-60.1756, -16.2692],
              [-60.2989, -14.6186],
              [-60.5066, -13.7898],
              [-61.077, -13.4897],
              [-61.79, -13.5257],
              [-62.176, -13.1337],
              [-62.7653, -12.9975],
              [-63.0675, -12.6692],
              [-64.4205, -12.4398],
              [-64.9925, -11.9753],
              [-65.39, -11.2463],
              [-65.3961, -9.7126],
              [-66.7301, -9.9754],
              [-68.398, -11.0186],
              [-69.5786, -10.9518],
              [-70.6423, -11.0103],
              [-70.637, -9.4783],
              [-71.238, -9.9661],
              [-72.1815, -10.0039],
              [-72.379, -9.51],
              [-73.2094, -9.4115],
              [-72.9741, -8.9932],
              [-73.5491, -8.3459],
              [-74.0022, -7.5562],
              [-73.7495, -7.3356],
              [-73.758, -6.9058],
              [-73.1375, -6.4659],
              [-73.2355, -6.0985],
              [-72.887, -5.1229],
              [-71.8449, -4.5046],
              [-70.9737, -4.3504],
              [-70.7994, -4.1733],
              [-69.9658, -4.2357],
              [-69.4002, -1.1947],
              [-69.6676, -0.4823],
              [-70.0706, -0.1386],
              [-70.0539, 0.5785],
              [-69.1633, 0.8642],
              [-69.8522, 1.0593],
              [-69.8487, 1.7088],
              [-68.1766, 1.7198],
              [-67.3519, 2.0859],
              [-66.8762, 1.2232],
              [-66.4293, 0.822],
              [-65.1037, 1.1081],
              [-64.1149, 1.6192],
              [-64.0354, 1.9044],
              [-63.394, 2.2225],
              [-63.9242, 2.4524],
              [-64.2188, 3.2047],
              [-64.2213, 3.5875],
              [-64.568, 3.8999],
              [-63.2949, 3.9223],
              [-62.9686, 3.5941],
              [-62.7122, 4.0177],
              [-62.1532, 4.0981],
              [-61.0027, 4.5354],
              [-60.6046, 4.9946],
              [-60.7421, 5.202],
              [-59.9906, 5.0829],
              [-60.1113, 4.5112],
              [-59.8333, 4.476],
              [-59.5512, 3.9333],
              [-59.8544, 3.5875],
              [-59.9942, 2.6901],
              [-59.7564, 1.9004],
              [-59.2313, 1.3757],
              [-58.8626, 1.2034],
              [-58.3407, 1.5875],
              [-57.5943, 1.7044],
              [-57.119, 2.0142],
              [-56.4829, 1.9422]
            ]
          ],
          [
            [
              [-49.6288, -0.2292],
              [-49.1168, -0.1637],
              [-48.3798, -0.3527],
              [-48.9289, -1.4825],
              [-49.5879, -1.7123],
              [-50.5077, -1.7879],
              [-50.7597, -1.2404],
              [-50.6454, -0.2727],
              [-50.2482, -0.1167],
              [-49.6288, -0.2292]
            ]
          ],
          [
            [
              [-51.8326, -1.4337],
              [-51.5461, -0.6497],
              [-51.2765, -1.0215],
              [-51.8326, -1.4337]
            ]
          ]
        ]
      },
      properties: { name: "Brazil", id: "BR", Continent: "South America" },
      id: "BR"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-67.1948, -22.8215],
              [-67.0089, -23.0012],
              [-67.3561, -24.0339],
              [-68.2502, -24.3921],
              [-68.562, -24.8377],
              [-68.3842, -25.0921],
              [-68.6002, -25.4859],
              [-68.4145, -26.1538],
              [-68.5811, -26.5181],
              [-68.3462, -27.0279],
              [-68.8463, -27.1536],
              [-69.1743, -27.9244],
              [-69.6568, -28.4139],
              [-70.0269, -29.3241],
              [-69.9563, -30.3581],
              [-70.1613, -30.4403],
              [-70.5852, -31.5692],
              [-70.1697, -32.4719],
              [-70.0198, -33.2717],
              [-69.7979, -33.3987],
              [-69.8524, -34.2244],
              [-70.2899, -34.7328],
              [-70.4703, -35.3261],
              [-70.4048, -36.0618],
              [-71.0556, -36.5236],
              [-71.1677, -37.762],
              [-70.9517, -38.7385],
              [-71.4256, -38.9854],
              [-71.7199, -39.6354],
              [-71.6953, -40.3354],
              [-71.9321, -40.6918],
              [-71.9115, -41.6507],
              [-71.7609, -42.1016],
              [-72.1248, -42.2985],
              [-72.1463, -42.9897],
              [-71.6801, -43.9297],
              [-71.8201, -44.3832],
              [-71.2127, -44.4412],
              [-71.5962, -44.9796],
              [-71.3536, -45.2305],
              [-71.7726, -45.7244],
              [-71.6953, -46.5783],
              [-71.9051, -47.2014],
              [-72.5179, -47.876],
              [-72.3549, -48.366],
              [-72.6144, -48.7927],
              [-73.5763, -49.5828],
              [-73.1529, -50.7386],
              [-72.5098, -50.6076],
              [-72.2762, -50.9104],
              [-72.4076, -51.541],
              [-71.9187, -51.9897],
              [-69.9603, -52.0086],
              [-68.4433, -52.3567],
              [-69.2409, -52.2055],
              [-69.5606, -52.4217],
              [-70.795, -52.7689],
              [-70.9959, -53.7792],
              [-72.1745, -53.632],
              [-71.4004, -53.1073],
              [-73.0527, -53.2435],
              [-74.0145, -52.6392],
              [-73.5183, -52.0407],
              [-73.9734, -51.7845],
              [-73.8958, -51.3314],
              [-74.8147, -51.0629],
              [-75.0949, -50.681],
              [-74.3337, -49.9744],
              [-74.341, -48.5958],
              [-74.655, -47.7024],
              [-74.152, -46.9742],
              [-75.6567, -46.6104],
              [-74.9246, -46.1595],
              [-75.0665, -45.8752],
              [-74.1579, -45.7671],
              [-73.5497, -45.4836],
              [-73.3625, -44.9783],
              [-72.739, -44.7344],
              [-72.6638, -44.4364],
              [-73.265, -44.1688],
              [-72.9965, -43.6318],
              [-72.7853, -42.3015],
              [-72.4995, -41.9807],
              [-73.0149, -41.5439],
              [-73.5214, -41.7971],
              [-73.9659, -41.1185],
              [-73.6695, -40.0823],
              [-73.2265, -39.2245],
              [-73.6618, -37.6987],
              [-73.6018, -37.1885],
              [-73.216, -37.167],
              [-72.6238, -35.5858],
              [-72.2237, -35.0963],
              [-72.0029, -34.1651],
              [-71.6645, -33.6527],
              [-71.7429, -33.095],
              [-71.4522, -32.6595],
              [-71.6619, -31.1693],
              [-71.6695, -30.3304],
              [-71.4006, -30.1432],
              [-71.3158, -29.6497],
              [-71.5193, -28.9264],
              [-71.1864, -28.3779],
              [-70.6465, -26.3292],
              [-70.7137, -25.7843],
              [-70.4454, -25.1725],
              [-70.5742, -24.6443],
              [-70.4879, -23.7817],
              [-70.5632, -23.057],
              [-70.3316, -22.8487],
              [-70.0875, -21.493],
              [-70.1972, -20.7257],
              [-70.1484, -19.8051],
              [-70.4184, -18.3452],
              [-69.9262, -18.2059],
              [-69.8522, -17.704],
              [-69.5109, -17.5058],
              [-69.0941, -18.0508],
              [-68.9684, -18.9683],
              [-68.4629, -19.4328],
              [-68.6984, -19.7211],
              [-68.7452, -20.459],
              [-68.5, -20.6119],
              [-68.197, -21.3005],
              [-67.8795, -22.8228],
              [-67.1948, -22.8215]
            ]
          ],
          [
            [
              [-73.7734, -43.3457],
              [-74.355, -43.2639],
              [-74.0369, -41.7953],
              [-73.5278, -41.8959],
              [-73.4364, -42.9366],
              [-73.7734, -43.3457]
            ]
          ],
          [
            [
              [-73.7354, -44.3947],
              [-73.7271, -45.1193],
              [-74.0991, -45.325],
              [-74.6178, -44.6478],
              [-73.7354, -44.3947]
            ]
          ],
          [
            [
              [-74.4763, -49.1478],
              [-74.5699, -49.9911],
              [-75.3001, -49.8474],
              [-75.1843, -49.0836],
              [-74.4763, -49.1478]
            ]
          ],
          [
            [
              [-68.6298, -52.6524],
              [-68.6531, -54.8536],
              [-69.7234, -54.7121],
              [-70.4971, -54.8093],
              [-70.8983, -54.3382],
              [-70.8678, -53.8842],
              [-70.0904, -53.4184],
              [-70.3907, -53.0264],
              [-69.7636, -52.7315],
              [-68.6298, -52.6524]
            ]
          ],
          [
            [
              [-69.703, -54.9191],
              [-68.2827, -55.2549],
              [-68.2932, -55.5212],
              [-69.4119, -55.4443],
              [-69.703, -54.9191]
            ]
          ]
        ]
      },
      properties: { name: "Chile", id: "CL", Continent: "South America" },
      id: "CL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.3197, 11.8619],
            [-71.958, 11.6664],
            [-72.2485, 11.1966],
            [-72.6902, 10.8363],
            [-73.0066, 9.7895],
            [-73.3564, 9.227],
            [-72.7963, 9.1088],
            [-72.4166, 8.3819],
            [-72.443, 7.4551],
            [-72.0844, 7.0965],
            [-71.0134, 6.9946],
            [-70.6553, 7.0829],
            [-70.095, 6.9379],
            [-69.4391, 6.135],
            [-68.4716, 6.1565],
            [-67.859, 6.2897],
            [-67.4394, 6.0256],
            [-67.825, 5.2706],
            [-67.8553, 4.5072],
            [-67.6615, 3.8643],
            [-67.311, 3.4161],
            [-67.8347, 2.8927],
            [-67.2109, 2.39],
            [-66.8762, 1.2232],
            [-67.3519, 2.0859],
            [-68.1766, 1.7198],
            [-69.8487, 1.7088],
            [-69.8522, 1.0593],
            [-69.1633, 0.8642],
            [-70.0539, 0.5785],
            [-70.0706, -0.1386],
            [-69.6676, -0.4823],
            [-69.4002, -1.1947],
            [-69.9658, -4.2357],
            [-70.3395, -3.8142],
            [-70.735, -3.7817],
            [-70.0741, -2.7503],
            [-70.9146, -2.2186],
            [-71.3969, -2.3342],
            [-71.7526, -2.1527],
            [-72.3957, -2.4286],
            [-73.1545, -2.2783],
            [-73.1969, -1.8305],
            [-73.4963, -1.6934],
            [-73.6644, -1.2487],
            [-74.2465, -0.9705],
            [-74.8017, -0.2002],
            [-75.2845, -0.1061],
            [-76.311, 0.4485],
            [-76.4945, 0.2353],
            [-77.4229, 0.4252],
            [-77.4677, 0.6361],
            [-78.3121, 1.0461],
            [-78.8597, 1.4552],
            [-78.5769, 1.7739],
            [-78.6171, 2.3065],
            [-78.1201, 2.4884],
            [-77.6701, 2.8786],
            [-77.1667, 3.8621],
            [-77.4273, 4.0603],
            [-77.2862, 4.7217],
            [-77.4018, 5.416],
            [-77.2493, 5.7799],
            [-77.4694, 6.1772],
            [-77.36, 6.5046],
            [-77.901, 7.2292],
            [-76.852, 8.0905],
            [-76.8878, 8.6197],
            [-76.2768, 8.9888],
            [-76.1355, 9.2656],
            [-75.6393, 9.4502],
            [-75.671, 10.1964],
            [-75.446, 10.6108],
            [-74.8446, 11.1096],
            [-74.4544, 10.9892],
            [-74.0593, 11.3407],
            [-73.3135, 11.2959],
            [-72.2749, 11.8892],
            [-71.7146, 12.42],
            [-71.2622, 12.3352],
            [-71.3197, 11.8619]
          ]
        ]
      },
      properties: { name: "Colombia", id: "CO", Continent: "South America" },
      id: "CO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.2845, -0.1061],
            [-75.2632, -0.5557],
            [-75.5706, -1.5313],
            [-76.0898, -2.1333],
            [-76.6791, -2.5627],
            [-77.8608, -2.9819],
            [-78.3473, -3.431],
            [-78.4198, -3.7769],
            [-79.0763, -4.9907],
            [-79.3307, -4.9278],
            [-79.6384, -4.4545],
            [-80.51, -4.0696],
            [-80.1793, -3.8775],
            [-80.3248, -3.388],
            [-79.9216, -3.09],
            [-79.73, -2.5794],
            [-80.45, -2.626],
            [-80.7704, -2.0766],
            [-80.9025, -1.0791],
            [-80.554, -0.8479],
            [-80.4823, -0.368],
            [-80.1334, -0.0059],
            [-80.0884, 0.7846],
            [-78.8996, 1.2061],
            [-78.8597, 1.4552],
            [-78.3121, 1.0461],
            [-77.4677, 0.6361],
            [-77.4229, 0.4252],
            [-76.4945, 0.2353],
            [-76.311, 0.4485],
            [-75.2845, -0.1061]
          ]
        ]
      },
      properties: { name: "Ecuador", id: "EC", Continent: "South America" },
      id: "EC"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-60.2864, -51.4619],
              [-59.268, -51.4272],
              [-59.9214, -51.9695],
              [-60.2864, -51.4619]
            ]
          ],
          [
            [
              [-58.85, -51.2699],
              [-57.8382, -51.7094],
              [-59.068, -52.173],
              [-58.85, -51.2699]
            ]
          ]
        ]
      },
      properties: { name: "Falkland Islands", id: "FK", Continent: "South America" },
      id: "FK"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-51.6527, 4.0612],
            [-51.9906, 3.7017],
            [-52.7006, 2.3636],
            [-52.9647, 2.1839],
            [-53.7678, 2.3552],
            [-54.1301, 2.121],
            [-54.6163, 2.3267],
            [-54.1953, 2.818],
            [-53.9903, 3.5897],
            [-54.3509, 4.0542],
            [-54.4522, 5.0135],
            [-54.156, 5.3589],
            [-53.8473, 5.7825],
            [-52.8992, 5.4248],
            [-52.2905, 4.9419],
            [-51.6527, 4.0612]
          ]
        ]
      },
      properties: { name: "French Guiana", id: "GF", Continent: "South America" },
      id: "GF"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-57.1948, 5.5483],
            [-57.3308, 5.0201],
            [-57.711, 4.9911],
            [-58.0544, 4.1719],
            [-56.7044, 2.0362],
            [-56.4829, 1.9422],
            [-57.119, 2.0142],
            [-57.5943, 1.7044],
            [-58.3407, 1.5875],
            [-58.8626, 1.2034],
            [-59.2313, 1.3757],
            [-59.7564, 1.9004],
            [-59.9942, 2.6901],
            [-59.8544, 3.5875],
            [-59.5512, 3.9333],
            [-59.8333, 4.476],
            [-60.1113, 4.5112],
            [-59.9906, 5.0829],
            [-60.7421, 5.202],
            [-61.3767, 5.9069],
            [-61.1286, 6.2141],
            [-61.1457, 6.6944],
            [-60.3952, 6.9453],
            [-60.719, 7.499],
            [-60.5136, 7.8133],
            [-60.0324, 8.0536],
            [-60.0174, 8.5493],
            [-59.2003, 8.0747],
            [-58.6266, 7.5461],
            [-58.4807, 7.0381],
            [-57.9826, 6.7858],
            [-57.1902, 6.0976],
            [-57.1948, 5.5483]
          ]
        ]
      },
      properties: { name: "Guyana", id: "GY", Continent: "South America" },
      id: "GY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.9658, -4.2357],
            [-70.7994, -4.1733],
            [-70.9737, -4.3504],
            [-71.8449, -4.5046],
            [-72.887, -5.1229],
            [-73.2355, -6.0985],
            [-73.1375, -6.4659],
            [-73.758, -6.9058],
            [-73.7495, -7.3356],
            [-74.0022, -7.5562],
            [-73.5491, -8.3459],
            [-72.9741, -8.9932],
            [-73.2094, -9.4115],
            [-72.379, -9.51],
            [-72.1815, -10.0039],
            [-71.238, -9.9661],
            [-70.637, -9.4783],
            [-70.6423, -11.0103],
            [-69.5786, -10.9518],
            [-68.6852, -12.5022],
            [-68.9786, -12.8802],
            [-69.0741, -13.6826],
            [-68.8803, -14.199],
            [-69.3747, -14.9632],
            [-69.1726, -15.2369],
            [-69.4184, -15.6034],
            [-69.0207, -16.6423],
            [-69.625, -17.2],
            [-69.5109, -17.5058],
            [-69.8522, -17.704],
            [-69.9262, -18.2059],
            [-70.4184, -18.3452],
            [-71.3371, -17.6825],
            [-71.5324, -17.2945],
            [-72.4676, -16.7078],
            [-73.8251, -16.1532],
            [-75.1043, -15.4118],
            [-75.934, -14.6336],
            [-76.2891, -14.1335],
            [-76.1838, -13.5156],
            [-76.8322, -12.3489],
            [-77.1526, -12.0601],
            [-77.2203, -11.6633],
            [-77.6331, -11.288],
            [-77.7362, -10.8367],
            [-78.1855, -10.0892],
            [-78.7623, -8.617],
            [-79.3773, -7.8352],
            [-79.7621, -7.0666],
            [-80.1103, -6.6496],
            [-80.8117, -6.2822],
            [-81.2832, -4.3222],
            [-80.7985, -3.7312],
            [-80.3248, -3.388],
            [-80.1793, -3.8775],
            [-80.51, -4.0696],
            [-79.6384, -4.4545],
            [-79.3307, -4.9278],
            [-79.0763, -4.9907],
            [-78.4198, -3.7769],
            [-78.3473, -3.431],
            [-77.8608, -2.9819],
            [-76.6791, -2.5627],
            [-76.0898, -2.1333],
            [-75.5706, -1.5313],
            [-75.2632, -0.5557],
            [-75.2845, -0.1061],
            [-74.8017, -0.2002],
            [-74.2465, -0.9705],
            [-73.6644, -1.2487],
            [-73.4963, -1.6934],
            [-73.1969, -1.8305],
            [-73.1545, -2.2783],
            [-72.3957, -2.4286],
            [-71.7526, -2.1527],
            [-71.3969, -2.3342],
            [-70.9146, -2.2186],
            [-70.0741, -2.7503],
            [-70.735, -3.7817],
            [-70.3395, -3.8142],
            [-69.9658, -4.2357]
          ]
        ]
      },
      properties: { name: "Peru", id: "PE", Continent: "South America" },
      id: "PE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-58.1599, -20.1645],
            [-57.8303, -20.9982],
            [-57.9856, -22.0463],
            [-56.9373, -22.2713],
            [-56.5238, -22.1025],
            [-55.8492, -22.3077],
            [-55.3663, -23.9913],
            [-54.6254, -23.8124],
            [-54.2419, -24.0471],
            [-54.6159, -25.576],
            [-54.7552, -26.5331],
            [-55.4265, -27.0095],
            [-55.7148, -27.4151],
            [-56.1641, -27.3215],
            [-56.4372, -27.5539],
            [-58.1682, -27.2736],
            [-58.6187, -27.1321],
            [-58.1913, -26.6302],
            [-58.2032, -26.3815],
            [-57.5873, -25.405],
            [-57.8215, -25.1365],
            [-59.373, -24.4536],
            [-59.8924, -24.0932],
            [-61.033, -23.7557],
            [-61.7985, -23.1823],
            [-62.6509, -22.2339],
            [-62.2767, -21.0659],
            [-62.2762, -20.5627],
            [-61.757, -19.6451],
            [-60.0073, -19.2975],
            [-59.0904, -19.2861],
            [-58.1803, -19.8178],
            [-58.1599, -20.1645]
          ]
        ]
      },
      properties: { name: "Paraguay", id: "PY", Continent: "South America" },
      id: "PY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-54.156, 5.3589],
            [-54.4522, 5.0135],
            [-54.3509, 4.0542],
            [-53.9903, 3.5897],
            [-54.1953, 2.818],
            [-54.6163, 2.3267],
            [-55.0057, 2.593],
            [-56.1296, 2.2994],
            [-56.4829, 1.9422],
            [-56.7044, 2.0362],
            [-58.0544, 4.1719],
            [-57.711, 4.9911],
            [-57.3308, 5.0201],
            [-57.1948, 5.5483],
            [-56.9698, 5.9926],
            [-55.9395, 5.7957],
            [-55.8284, 5.9618],
            [-54.8339, 5.9882],
            [-54.0543, 5.808],
            [-54.156, 5.3589]
          ]
        ]
      },
      properties: { name: "Suriname", id: "SR", Continent: "South America" },
      id: "SR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.3707, -33.7423],
            [-53.7853, -34.3804],
            [-54.1685, -34.6709],
            [-54.9024, -34.9328],
            [-55.6732, -34.7755],
            [-56.118, -34.9077],
            [-57.1707, -34.4525],
            [-57.8292, -34.4775],
            [-58.4003, -33.9124],
            [-58.3535, -33.2602],
            [-58.0823, -32.8937],
            [-58.2012, -32.4719],
            [-58.1889, -31.9239],
            [-57.609, -30.188],
            [-56.8327, -30.1072],
            [-56.1762, -30.6284],
            [-55.8736, -31.0696],
            [-54.5876, -31.4848],
            [-53.7618, -32.057],
            [-53.1255, -32.7368],
            [-53.5313, -33.171],
            [-53.3707, -33.7423]
          ]
        ]
      },
      properties: { name: "Uruguay", id: "UY", Continent: "South America" },
      id: "UY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.0174, 8.5493],
            [-60.0324, 8.0536],
            [-60.5136, 7.8133],
            [-60.719, 7.499],
            [-60.3952, 6.9453],
            [-61.1457, 6.6944],
            [-61.1286, 6.2141],
            [-61.3767, 5.9069],
            [-60.7421, 5.202],
            [-60.6046, 4.9946],
            [-61.0027, 4.5354],
            [-62.1532, 4.0981],
            [-62.7122, 4.0177],
            [-62.9686, 3.5941],
            [-63.2949, 3.9223],
            [-64.568, 3.8999],
            [-64.2213, 3.5875],
            [-64.2188, 3.2047],
            [-63.9242, 2.4524],
            [-63.394, 2.2225],
            [-64.0354, 1.9044],
            [-64.1149, 1.6192],
            [-65.1037, 1.1081],
            [-66.4293, 0.822],
            [-66.8762, 1.2232],
            [-67.2109, 2.39],
            [-67.8347, 2.8927],
            [-67.311, 3.4161],
            [-67.6615, 3.8643],
            [-67.8553, 4.5072],
            [-67.825, 5.2706],
            [-67.4394, 6.0256],
            [-67.859, 6.2897],
            [-68.4716, 6.1565],
            [-69.4391, 6.135],
            [-70.095, 6.9379],
            [-70.6553, 7.0829],
            [-71.0134, 6.9946],
            [-72.0844, 7.0965],
            [-72.443, 7.4551],
            [-72.4166, 8.3819],
            [-72.7963, 9.1088],
            [-73.3564, 9.227],
            [-73.0066, 9.7895],
            [-72.6902, 10.8363],
            [-72.2485, 11.1966],
            [-71.958, 11.6664],
            [-71.3197, 11.8619],
            [-71.9468, 11.4146],
            [-71.5942, 10.657],
            [-72.113, 9.8154],
            [-71.6869, 9.0723],
            [-71.298, 9.1259],
            [-71.0528, 9.706],
            [-71.3868, 10.2641],
            [-71.4696, 10.9641],
            [-70.2325, 11.3733],
            [-70.2866, 11.8857],
            [-70.004, 12.1779],
            [-69.6316, 11.4801],
            [-68.8278, 11.4317],
            [-68.3987, 11.161],
            [-68.2339, 10.5691],
            [-67.8716, 10.4719],
            [-66.2471, 10.6323],
            [-65.8518, 10.2575],
            [-64.8503, 10.098],
            [-63.8734, 10.664],
            [-62.7025, 10.7501],
            [-62.8429, 10.4179],
            [-62.5151, 10.1762],
            [-60.7924, 9.3606],
            [-61.0532, 9.0951],
            [-61.3039, 8.41],
            [-60.801, 8.5924],
            [-60.0174, 8.5493]
          ]
        ]
      },
      properties: { name: "Venezuela", id: "VE", Continent: "South America" },
      id: "VE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.8992, 12.4519],
            [-69.9422, 12.4385],
            [-70.0662, 12.5467],
            [-69.9732, 12.5674],
            [-69.8992, 12.4519]
          ]
        ]
      },
      properties: { name: "Aruba", id: "AW", Continent: "Caribbean" },
      id: "AW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.7161, 17.0371],
            [-61.8598, 17.0132],
            [-61.8171, 17.1691],
            [-61.7161, 17.0371]
          ]
        ]
      },
      properties: { name: "Antigua and Barbuda", id: "AG", Continent: "Caribbean" },
      id: "AG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-63.0013, 18.2221],
            [-63.16, 18.1709],
            [-63.1535, 18.2001],
            [-63.026, 18.2698],
            [-63.0013, 18.2221]
          ]
        ]
      },
      properties: { name: "Anguilla", id: "AI", Continent: "Caribbean" },
      id: "AI"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-77.2259, 25.9037],
              [-77.2061, 26.4891],
              [-77.0383, 26.3332],
              [-77.1673, 26.2405],
              [-77.2259, 25.9037]
            ]
          ],
          [
            [
              [-77.7441, 24.7071],
              [-77.7453, 24.4634],
              [-78.0451, 24.2874],
              [-78.2986, 24.754],
              [-78.0334, 25.1433],
              [-77.7441, 24.7071]
            ]
          ],
          [
            [
              [-77.6577, 24.2498],
              [-77.5187, 23.8694],
              [-77.7712, 23.7524],
              [-77.8522, 24.0407],
              [-77.6577, 24.2498]
            ]
          ],
          [
            [
              [-73.0271, 21.1922],
              [-73.1644, 20.9786],
              [-73.6608, 20.9373],
              [-73.5231, 21.1906],
              [-73.0271, 21.1922]
            ]
          ]
        ]
      },
      properties: { name: "Bahamas", id: "BS", Continent: "Caribbean" },
      id: "BS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.8321, 17.8766],
            [-62.8654, 17.9186],
            [-62.7998, 17.9087],
            [-62.8321, 17.8766]
          ]
        ]
      },
      properties: { name: "Saint Barthelemy", id: "BL", Continent: "Caribbean" },
      id: "BL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.894, 15.8907],
            [-89.2374, 15.8944],
            [-89.1615, 17.8148],
            [-89.0505, 17.9996],
            [-88.8064, 17.9653],
            [-88.5231, 18.446],
            [-88.2954, 18.4719],
            [-88.0972, 18.1216],
            [-88.2718, 17.6099],
            [-88.3133, 16.6328],
            [-88.6952, 16.2478],
            [-88.894, 15.8907]
          ]
        ]
      },
      properties: { name: "Belize", id: "BZ", Continent: "Central America" },
      id: "BZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-64.7304, 32.2935],
            [-64.8201, 32.2593],
            [-64.6947, 32.387],
            [-64.7304, 32.2935]
          ]
        ]
      },
      properties: { name: "Bermuda", id: "BM", Continent: "North America" },
      id: "BM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-59.4934, 13.0821],
            [-59.6112, 13.1021],
            [-59.5917, 13.3178],
            [-59.4276, 13.1527],
            [-59.4934, 13.0821]
          ]
        ]
      },
      properties: { name: "Barbados", id: "BB", Continent: "Caribbean" },
      id: "BB"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-55.4589, 51.5365],
              [-55.8149, 51.1912],
              [-55.8714, 50.9075],
              [-56.694, 50.0594],
              [-56.2471, 50.0899],
              [-55.7649, 49.9605],
              [-55.8922, 49.5801],
              [-55.3759, 49.4896],
              [-55.0261, 49.3052],
              [-54.579, 49.4905],
              [-53.9578, 49.4415],
              [-53.5733, 49.1409],
              [-53.8531, 48.8113],
              [-53.7945, 48.5265],
              [-53.0205, 48.5715],
              [-53.5311, 48.2318],
              [-53.695, 47.9209],
              [-52.955, 48.0294],
              [-53.1756, 47.6529],
              [-52.6535, 47.5493],
              [-53.0697, 46.6811],
              [-53.6163, 46.6803],
              [-53.6364, 47.1374],
              [-53.8692, 47.3873],
              [-53.9891, 47.7564],
              [-54.1918, 47.8596],
              [-54.4345, 47.4623],
              [-54.8568, 47.3846],
              [-55.3156, 46.9057],
              [-55.7886, 46.8674],
              [-55.8383, 47.0716],
              [-55.4916, 47.1604],
              [-55.191, 47.4492],
              [-55.8622, 47.53],
              [-56.2212, 47.6716],
              [-56.9524, 47.5744],
              [-58.3332, 47.6768],
              [-59.1167, 47.5709],
              [-59.3624, 47.8653],
              [-58.7106, 48.3247],
              [-58.7163, 48.598],
              [-58.2134, 49.3868],
              [-58.0155, 49.5427],
              [-57.4657, 50.4639],
              [-56.7503, 51.2751],
              [-56.0256, 51.5686],
              [-55.4589, 51.5365]
            ]
          ],
          [
            [
              [-127.1973, 50.6403],
              [-126.701, 50.5155],
              [-125.4821, 50.3169],
              [-124.643, 49.4288],
              [-123.996, 49.224],
              [-123.4971, 48.5822],
              [-123.5947, 48.3336],
              [-124.6894, 48.5976],
              [-125.8286, 49.0915],
              [-125.8354, 49.277],
              [-126.2437, 49.4423],
              [-126.5485, 49.4191],
              [-126.6832, 49.8764],
              [-127.1142, 49.8799],
              [-127.467, 50.1637],
              [-127.8639, 50.1279],
              [-127.906, 50.4452],
              [-128.2675, 50.6091],
              [-127.9182, 50.8606],
              [-127.1973, 50.6403]
            ]
          ],
          [
            [
              [-61.8012, 49.0938],
              [-62.2195, 49.0792],
              [-63.0416, 49.2248],
              [-63.5657, 49.3992],
              [-64.1316, 49.9416],
              [-62.8586, 49.7055],
              [-61.8169, 49.2836],
              [-61.8012, 49.0938]
            ]
          ],
          [
            [
              [-74.7087, 45.0038],
              [-73.558, 45.4249],
              [-73.1597, 46.0101],
              [-71.901, 46.6322],
              [-71.2611, 46.7562],
              [-70.5194, 47.0323],
              [-68.9872, 48.275],
              [-68.2383, 48.6264],
              [-67.5608, 48.8559],
              [-66.1781, 49.2133],
              [-65.5234, 49.2663],
              [-64.8366, 49.1917],
              [-64.2618, 48.9217],
              [-64.2538, 48.5503],
              [-64.7646, 48.2281],
              [-65.2593, 48.0216],
              [-65.9266, 48.1884],
              [-65.6665, 47.6959],
              [-65.2283, 47.8115],
              [-64.7035, 47.7249],
              [-64.912, 47.3687],
              [-65.319, 47.1012],
              [-64.9059, 46.8882],
              [-64.5415, 46.24],
              [-63.2927, 45.7519],
              [-62.7009, 45.7406],
              [-62.4831, 45.6217],
              [-61.9557, 45.868],
              [-61.4926, 45.6871],
              [-61.2836, 45.2355],
              [-62.5139, 44.8436],
              [-63.7611, 44.486],
              [-63.9998, 44.645],
              [-64.2861, 44.5499],
              [-64.3782, 44.3038],
              [-64.8623, 43.8677],
              [-65.7382, 43.5604],
              [-66.1257, 43.8138],
              [-66.0995, 44.3678],
              [-65.6568, 44.7605],
              [-64.9032, 45.1212],
              [-64.4489, 45.2559],
              [-64.898, 45.6262],
              [-65.8847, 45.2232],
              [-66.8725, 45.0673],
              [-67.1248, 45.1693],
              [-67.4725, 45.2756],
              [-67.4329, 45.6028],
              [-67.7845, 45.7017],
              [-67.8067, 47.0831],
              [-68.3109, 47.3546],
              [-68.8289, 47.2032],
              [-69.243, 47.4627],
              [-70.0076, 46.7089],
              [-70.2484, 46.2511],
              [-70.2963, 45.9059],
              [-70.8982, 45.2625],
              [-71.3271, 45.2902],
              [-71.5175, 45.0077],
              [-72.7659, 45.006],
              [-74.7087, 45.0038]
            ]
          ],
          [
            [
              [-63.8111, 46.4687],
              [-63.6813, 46.5618],
              [-63.1294, 46.422],
              [-62.1633, 46.4872],
              [-62.8785, 46.0013],
              [-63.1169, 46.253],
              [-63.5688, 46.2092],
              [-63.8111, 46.4687]
            ]
          ],
          [
            [
              [-61.1054, 45.9448],
              [-60.8062, 45.7383],
              [-60.4722, 45.9466],
              [-60.5051, 46.2038],
              [-59.8648, 46.1598],
              [-60.0155, 45.8804],
              [-60.3861, 45.6544],
              [-61.2836, 45.5736],
              [-61.4955, 45.9413],
              [-60.8701, 46.7967],
              [-60.6166, 46.976],
              [-60.3318, 46.7677],
              [-60.5769, 46.1722],
              [-61.1054, 45.9448]
            ]
          ],
          [
            [
              [-69.489, 83.0166],
              [-66.5916, 82.9443],
              [-61.3924, 82.442],
              [-62.1767, 82.0434],
              [-64.5742, 81.7335],
              [-65.4839, 81.2846],
              [-70.1433, 80.3978],
              [-71.388, 79.7618],
              [-73.2294, 79.644],
              [-73.4663, 79.4952],
              [-75.2594, 79.4207],
              [-75.0939, 79.2039],
              [-76.2557, 79.007],
              [-74.4334, 78.7241],
              [-75.866, 78.0099],
              [-78.0126, 77.9459],
              [-78.0761, 77.5191],
              [-79.4975, 77.196],
              [-79.2207, 76.936],
              [-78.289, 76.978],
              [-78.2845, 76.571],
              [-79.511, 76.3106],
              [-80.6903, 76.1764],
              [-81.1707, 76.5127],
              [-83.8859, 76.4531],
              [-85.1412, 76.3048],
              [-89.5701, 76.492],
              [-89.4996, 76.8265],
              [-88.398, 77.1042],
              [-86.8737, 77.2004],
              [-88.0945, 77.7191],
              [-87.0178, 77.8921],
              [-87.5519, 78.1768],
              [-86.8081, 78.7745],
              [-84.1458, 78.9598],
              [-84.1974, 79.2251],
              [-85.09, 79.6122],
              [-86.4207, 79.8452],
              [-86.3072, 80.3193],
              [-83.7236, 80.2288],
              [-80.0508, 80.5285],
              [-78.3862, 80.7845],
              [-78.6295, 81.0436],
              [-81.007, 80.6552],
              [-83.4014, 80.7139],
              [-85.3072, 80.5258],
              [-89.0616, 80.8295],
              [-89.9475, 81.1725],
              [-88.9785, 81.5415],
              [-91.6474, 81.684],
              [-90.4901, 81.8769],
              [-86.5807, 82.1872],
              [-84.8967, 82.4495],
              [-82.5369, 82.2473],
              [-82.1224, 82.6018],
              [-79.8865, 82.9385],
              [-77.618, 82.8961],
              [-75.7449, 83.0471],
              [-69.9699, 83.116],
              [-69.489, 83.0166]
            ]
          ],
          [
            [
              [-91.8858, 81.1327],
              [-90.6429, 80.5939],
              [-87.6748, 80.3722],
              [-86.9771, 79.8942],
              [-87.1441, 79.6625],
              [-85.5013, 79.5301],
              [-85.0637, 79.3279],
              [-87.8615, 78.7069],
              [-88.791, 78.1926],
              [-90.6143, 78.1499],
              [-92.6783, 78.3891],
              [-94.1627, 78.9943],
              [-92.5473, 79.2825],
              [-95.1032, 79.29],
              [-95.2969, 79.6532],
              [-96.4626, 79.8474],
              [-96.7734, 80.1356],
              [-96.1329, 80.6914],
              [-95.2697, 81.0008],
              [-92.4126, 81.278],
              [-91.8858, 81.1327]
            ]
          ],
          [
            [
              [-103.4261, 79.3156],
              [-101.8726, 79.0883],
              [-99.6093, 78.5828],
              [-99.7515, 78.3031],
              [-99.1663, 77.8567],
              [-100.5859, 77.8916],
              [-101.0743, 78.194],
              [-102.7311, 78.371],
              [-104.3243, 78.2694],
              [-103.5183, 78.7691],
              [-105.5357, 79.0326],
              [-105.3876, 79.3235],
              [-103.4261, 79.3156]
            ]
          ],
          [
            [
              [-96.2044, 78.5312],
              [-94.887, 78.3454],
              [-95.087, 77.9927],
              [-96.9894, 77.806],
              [-98.2549, 78.4292],
              [-98.2121, 78.8044],
              [-96.5871, 78.6871],
              [-96.2044, 78.5312]
            ]
          ],
          [
            [
              [-109.8161, 78.6504],
              [-109.3907, 78.3366],
              [-113.15, 78.4085],
              [-110.4078, 78.7568],
              [-109.8161, 78.6504]
            ]
          ],
          [
            [
              [-110.4581, 78.1035],
              [-109.772, 77.9574],
              [-110.1984, 77.5244],
              [-111.952, 77.3443],
              [-113.1643, 77.5301],
              [-113.2153, 77.9035],
              [-110.4581, 78.1035]
            ]
          ],
          [
            [
              [-95.4845, 77.7919],
              [-93.3009, 77.7398],
              [-93.5197, 77.4745],
              [-95.9871, 77.4838],
              [-95.4845, 77.7919]
            ]
          ],
          [
            [
              [-115.5514, 77.3633],
              [-116.2856, 77.1015],
              [-115.8571, 76.9691],
              [-116.2203, 76.6111],
              [-118.0201, 76.4465],
              [-119.3679, 76.2219],
              [-119.9128, 75.859],
              [-121.9084, 76.0346],
              [-122.7741, 76.2276],
              [-121.6139, 76.4417],
              [-119.0903, 77.305],
              [-117.4185, 77.3174],
              [-116.3627, 77.5429],
              [-115.5514, 77.3633]
            ]
          ],
          [
            [
              [-94.2947, 76.9127],
              [-92.7163, 76.6028],
              [-91.3048, 76.6809],
              [-90.8547, 76.4373],
              [-89.2845, 76.3017],
              [-90.2514, 76.0536],
              [-88.2012, 75.5121],
              [-87.257, 75.6176],
              [-86.0686, 75.5023],
              [-84.6047, 75.6533],
              [-83.9321, 75.8188],
              [-82.1538, 75.8307],
              [-80.3223, 75.629],
              [-79.6601, 75.4494],
              [-79.508, 74.88],
              [-80.2778, 74.5816],
              [-81.8088, 74.4766],
              [-85.0115, 74.6042],
              [-85.8079, 74.4991],
              [-88.4231, 74.4942],
              [-88.7776, 74.7154],
              [-89.8444, 74.5485],
              [-91.9614, 74.793],
              [-92.4282, 75.3828],
              [-92.1852, 75.8467],
              [-93.0918, 76.3543],
              [-95.2739, 76.2646],
              [-96.6396, 76.7029],
              [-96.7584, 76.9718],
              [-95.6383, 77.064],
              [-94.2947, 76.9127]
            ]
          ],
          [
            [
              [-108.2925, 76.0571],
              [-106.8621, 75.9301],
              [-105.6044, 75.9301],
              [-105.481, 75.7456],
              [-105.8625, 75.1916],
              [-107.1534, 74.9272],
              [-108.8311, 75.065],
              [-110.9408, 74.6386],
              [-112.5195, 74.4169],
              [-113.5141, 74.4298],
              [-114.1323, 74.7661],
              [-112.8359, 74.9753],
              [-114.4519, 75.0879],
              [-115.7288, 74.9682],
              [-116.4761, 75.1718],
              [-117.6001, 75.2715],
              [-116.6099, 76.0735],
              [-115.5805, 76.4377],
              [-114.1939, 76.4513],
              [-113.8234, 76.2068],
              [-112.6975, 76.2015],
              [-111.0527, 75.5483],
              [-109.0865, 75.5064],
              [-108.9449, 75.6988],
              [-110.2704, 76.4169],
              [-109.3385, 76.7599],
              [-108.467, 76.7374],
              [-108.2925, 76.0571]
            ]
          ],
          [
            [
              [-97.7009, 76.4664],
              [-97.4096, 75.5522],
              [-97.7994, 75.117],
              [-98.5688, 75.0093],
              [-100.2343, 75.008],
              [-100.7122, 75.4066],
              [-99.9153, 75.6811],
              [-102.5413, 75.5139],
              [-102.2522, 75.7778],
              [-101.4312, 75.9919],
              [-102.1376, 76.285],
              [-100.3879, 76.6138],
              [-99.0238, 76.6147],
              [-97.7009, 76.4664]
            ]
          ],
          [
            [
              [-102.2275, 76.0153],
              [-103.3149, 75.7641],
              [-104.3505, 76.1821],
              [-102.5842, 76.2819],
              [-102.2275, 76.0153]
            ]
          ],
          [
            [
              [-93.5425, 75.0279],
              [-93.5471, 74.691],
              [-94.6973, 74.6421],
              [-96.2702, 74.9202],
              [-96.5659, 75.0989],
              [-95.6708, 75.5284],
              [-94.4272, 75.5932],
              [-93.7508, 75.3492],
              [-93.5425, 75.0279]
            ]
          ],
          [
            [
              [-119.7363, 74.1129],
              [-117.966, 74.266],
              [-117.1989, 74.1711],
              [-115.5108, 73.619],
              [-115.4468, 73.4388],
              [-118.9616, 72.6841],
              [-119.4078, 72.3605],
              [-120.1801, 72.2127],
              [-120.6193, 71.5055],
              [-123.2107, 71.1233],
              [-124.0079, 71.6772],
              [-125.7626, 72.1376],
              [-124.9871, 72.5882],
              [-124.8364, 73.076],
              [-123.7973, 73.7682],
              [-124.6962, 74.3481],
              [-121.3153, 74.53],
              [-119.7363, 74.1129]
            ]
          ],
          [
            [
              [-93.171, 74.1609],
              [-92.2228, 73.9724],
              [-91.0879, 74.0092],
              [-90.3546, 73.8687],
              [-92.1179, 72.7538],
              [-93.3406, 72.8019],
              [-94.0377, 72.0286],
              [-95.1927, 72.0273],
              [-95.251, 72.5022],
              [-95.5476, 72.7816],
              [-95.633, 73.6953],
              [-94.9736, 74.0413],
              [-93.171, 74.1609]
            ]
          ],
          [
            [
              [-100.0019, 73.946],
              [-99.158, 73.7314],
              [-97.582, 73.8877],
              [-97.0018, 73.6666],
              [-97.7958, 73.2852],
              [-97.0518, 72.6369],
              [-96.6133, 71.834],
              [-98.663, 71.302],
              [-99.1671, 71.3674],
              [-100.5943, 72.1526],
              [-101.7241, 72.3151],
              [-102.7139, 72.783],
              [-101.9226, 73.0571],
              [-101.3507, 72.7463],
              [-100.4847, 72.7728],
              [-100.2827, 73.1201],
              [-101.4508, 73.4309],
              [-100.9152, 73.8052],
              [-100.0019, 73.946]
            ]
          ],
          [
            [
              [-86.5895, 71.0106],
              [-85.6438, 71.1525],
              [-84.7898, 71.0933],
              [-84.6995, 71.6313],
              [-85.2506, 71.675],
              [-85.9115, 71.9867],
              [-85.3413, 72.4215],
              [-85.6444, 72.7745],
              [-85.0185, 73.3355],
              [-82.6595, 73.7293],
              [-81.4059, 73.6344],
              [-80.2748, 72.7454],
              [-80.9414, 72.21],
              [-80.116, 72.2144],
              [-79.3233, 72.3909],
              [-78.2873, 72.3596],
              [-78.3503, 72.6002],
              [-77.7533, 72.7247],
              [-76.698, 72.6951],
              [-75.1858, 72.4342],
              [-74.9033, 72.1005],
              [-73.7137, 71.7201],
              [-71.6407, 71.5166],
              [-70.7927, 71.1034],
              [-70.5607, 70.738],
              [-69.95, 70.8447],
              [-69.1686, 70.7641],
              [-68.3582, 70.5231],
              [-67.3638, 70.0343],
              [-67.3368, 69.7209],
              [-68.1139, 69.7544],
              [-68.5128, 69.5776],
              [-66.7709, 69.3366],
              [-67.7518, 69.0386],
              [-68.038, 68.5509],
              [-67.0331, 68.3261],
              [-66.2252, 67.9589],
              [-65.3005, 67.9395],
              [-64.5275, 67.8129],
              [-64.0081, 67.3471],
              [-63.0404, 67.235],
              [-63.2585, 67.0245],
              [-62.3797, 66.9054],
              [-61.9684, 67.0191],
              [-61.2999, 66.6488],
              [-61.8623, 66.313],
              [-61.9914, 66.0354],
              [-62.6591, 65.6399],
              [-63.3642, 65.5431],
              [-63.4019, 65.1185],
              [-64.1522, 65.066],
              [-64.6653, 65.1688],
              [-65.4016, 65.7639],
              [-65.3054, 66.0084],
              [-66.4769, 66.2795],
              [-66.7874, 66.5558],
              [-67.3111, 66.3037],
              [-67.1834, 66.0343],
              [-68.2566, 65.9387],
              [-67.3035, 65.4826],
              [-66.8606, 65.0917],
              [-66.2237, 64.8542],
              [-65.7679, 64.8532],
              [-65.2748, 64.6313],
              [-65.0107, 64.0089],
              [-64.6784, 64.0278],
              [-64.5143, 63.2639],
              [-65.0687, 63.2635],
              [-65.2657, 62.7152],
              [-65.9788, 63.0007],
              [-66.4146, 63.0272],
              [-67.8377, 63.4491],
              [-68.4937, 63.7255],
              [-68.9109, 63.7031],
              [-68.1413, 63.1724],
              [-67.6759, 63.0934],
              [-66.2813, 62.3025],
              [-66.3235, 61.8703],
              [-67.4401, 62.1516],
              [-68.3788, 62.2349],
              [-69.0822, 62.4054],
              [-69.5453, 62.7447],
              [-70.2362, 62.7634],
              [-71.2539, 63.0422],
              [-71.9732, 63.4298],
              [-72.223, 63.7087],
              [-73.4439, 64.4238],
              [-74.5123, 64.6702],
              [-75.0673, 64.4565],
              [-76.7239, 64.2419],
              [-77.7911, 64.3669],
              [-78.1746, 64.618],
              [-78.0957, 64.9389],
              [-77.4475, 65.1619],
              [-77.3266, 65.4532],
              [-75.8284, 65.2266],
              [-73.5607, 65.5427],
              [-74.4342, 66.139],
              [-73.0334, 66.7284],
              [-72.7889, 67.0306],
              [-72.2202, 67.2546],
              [-73.3283, 68.2671],
              [-74.6481, 68.7076],
              [-74.9172, 68.9825],
              [-76.4033, 68.6921],
              [-76.5571, 69.0094],
              [-75.9536, 69.0306],
              [-75.7873, 69.3189],
              [-77.1288, 69.6526],
              [-78.9797, 70.5813],
              [-79.3467, 70.482],
              [-78.8098, 70.1786],
              [-78.8896, 69.9773],
              [-79.6157, 69.8949],
              [-81.0981, 70.0913],
              [-82.1388, 69.841],
              [-83.0913, 70.0039],
              [-83.8593, 69.9629],
              [-86.322, 70.1451],
              [-86.6245, 70.4011],
              [-87.9004, 70.2521],
              [-88.8484, 70.5231],
              [-89.3655, 71.0672],
              [-88.0388, 70.9512],
              [-87.8724, 71.2085],
              [-89.0795, 71.2878],
              [-89.8457, 71.4923],
              [-90.0252, 71.9487],
              [-89.6639, 72.158],
              [-89.8615, 72.4121],
              [-89.2631, 73.0689],
              [-88.705, 73.4036],
              [-87.7197, 73.7227],
              [-86.7688, 73.8339],
              [-85.1106, 73.8083],
              [-86.6294, 72.8708],
              [-86.3417, 72.123],
              [-86.0361, 71.7709],
              [-85.0234, 71.3532],
              [-86.5895, 71.0106]
            ]
          ],
          [
            [
              [-79.5374, 73.6546],
              [-78.2867, 73.6662],
              [-77.2065, 73.4998],
              [-76.2896, 73.0808],
              [-76.4005, 72.8209],
              [-77.836, 72.8968],
              [-79.5006, 72.756],
              [-79.9369, 72.8637],
              [-80.1833, 73.2244],
              [-80.824, 73.3805],
              [-80.8489, 73.7209],
              [-79.5374, 73.6546]
            ]
          ],
          [
            [
              [-105.2889, 72.9197],
              [-106.1799, 73.3042],
              [-106.9216, 73.4799],
              [-106.614, 73.6958],
              [-105.5123, 73.7659],
              [-104.6489, 73.6145],
              [-104.583, 73.3536],
              [-105.2889, 72.9197]
            ]
          ],
          [
            [
              [-114.5216, 72.5932],
              [-113.5002, 72.6942],
              [-112.7539, 72.9859],
              [-111.2698, 72.7137],
              [-110.2053, 72.6615],
              [-110.0086, 72.9838],
              [-108.6983, 72.4995],
              [-108.2105, 71.7509],
              [-107.3062, 71.8949],
              [-107.6956, 72.1495],
              [-108.2382, 73.106],
              [-107.7199, 73.3289],
              [-106.4821, 73.1961],
              [-105.4152, 72.7882],
              [-105.234, 72.4152],
              [-104.3495, 71.434],
              [-104.5697, 71.1038],
              [-103.9536, 70.7626],
              [-102.7503, 70.5221],
              [-101.5625, 70.1351],
              [-100.9732, 70.0296],
              [-100.9351, 69.7152],
              [-101.6477, 69.6985],
              [-102.2343, 69.8424],
              [-102.4466, 69.4764],
              [-101.9757, 69.4071],
              [-101.8571, 69.024],
              [-102.8951, 68.8235],
              [-103.4683, 68.8085],
              [-105.0198, 69.0814],
              [-106.1409, 69.1622],
              [-106.6591, 69.4394],
              [-107.4399, 69.002],
              [-108.3651, 68.9348],
              [-108.9459, 68.7596],
              [-109.9585, 68.6302],
              [-111.311, 68.542],
              [-113.1277, 68.4939],
              [-113.617, 68.8385],
              [-113.694, 69.1949],
              [-115.1592, 69.2646],
              [-116.5136, 69.4248],
              [-117.1042, 69.8044],
              [-117.1354, 70.1001],
              [-114.5922, 70.3125],
              [-112.6378, 70.2251],
              [-112.1143, 70.4466],
              [-113.7574, 70.6907],
              [-115.991, 70.5862],
              [-117.5871, 70.6294],
              [-118.2642, 70.8881],
              [-117.8142, 71.1587],
              [-116.2282, 71.3594],
              [-118.1883, 71.4359],
              [-118.8682, 71.687],
              [-118.9446, 71.9853],
              [-118.2137, 72.2629],
              [-118.3743, 72.534],
              [-117.5518, 72.8311],
              [-114.6382, 73.3726],
              [-114.1641, 73.2702],
              [-114.1091, 72.861],
              [-114.5216, 72.5932]
            ]
          ],
          [
            [
              [-74.7087, 45.0038],
              [-75.1794, 44.8991],
              [-76.4646, 44.0575],
              [-76.8199, 43.6289],
              [-78.7206, 43.6252],
              [-79.172, 43.4665],
              [-79.0369, 42.8025],
              [-80.2474, 42.3659],
              [-81.2778, 42.2088],
              [-82.439, 41.6748],
              [-82.6901, 41.6748],
              [-83.1421, 41.9759],
              [-83.0732, 42.3008],
              [-82.5453, 42.6246],
              [-82.408, 43.0727],
              [-82.1378, 43.5705],
              [-82.5509, 45.3472],
              [-83.5926, 45.8168],
              [-83.6159, 46.1171],
              [-83.9778, 46.0852],
              [-84.1923, 46.5491],
              [-84.5618, 46.4574],
              [-84.876, 46.8999],
              [-87.2078, 47.8485],
              [-88.3783, 48.3031],
              [-89.4554, 47.9959],
              [-90.0918, 48.1183],
              [-90.7443, 48.1045],
              [-90.8401, 48.2007],
              [-91.5183, 48.0582],
              [-92.9962, 48.6118],
              [-93.378, 48.6165],
              [-93.7079, 48.5256],
              [-94.6209, 48.7424],
              [-94.8545, 49.3048],
              [-95.1554, 49.3695],
              [-95.1621, 48.9918],
              [-98.3829, 48.993],
              [-101.3672, 48.993],
              [-103.4991, 48.993],
              [-106.0577, 48.993],
              [-107.763, 48.993],
              [-109.4685, 48.993],
              [-112.0267, 48.993],
              [-115.0116, 48.993],
              [-117.1433, 48.993],
              [-119.7019, 48.993],
              [-122.7887, 48.993],
              [-123.0491, 48.993],
              [-123.3356, 49.4592],
              [-123.8591, 49.4831],
              [-124.1415, 49.7925],
              [-124.4832, 49.8084],
              [-125.21, 50.4763],
              [-126.4046, 50.5301],
              [-126.3973, 50.8067],
              [-127.0579, 50.8678],
              [-127.7082, 51.1511],
              [-127.6449, 51.4785],
              [-127.8189, 51.6039],
              [-127.9022, 52.1509],
              [-128.3578, 52.1587],
              [-128.1088, 52.8579],
              [-128.452, 52.8764],
              [-128.6524, 53.2436],
              [-129.2843, 53.3929],
              [-129.5636, 53.2513],
              [-130.3353, 53.724],
              [-130.0433, 54.1336],
              [-130.4304, 54.4209],
              [-129.8901, 55.1647],
              [-130.0931, 55.6321],
              [-130.0252, 55.8881],
              [-130.4772, 56.2306],
              [-131.5751, 56.5987],
              [-131.866, 56.7931],
              [-133.2754, 58.223],
              [-133.8207, 58.705],
              [-134.9438, 59.2882],
              [-135.0511, 59.5785],
              [-135.476, 59.7932],
              [-136.3218, 59.6051],
              [-136.5788, 59.1523],
              [-137.4386, 58.9032],
              [-137.5933, 59.2263],
              [-139.1854, 60.0836],
              [-139.0795, 60.3435],
              [-139.6765, 60.3285],
              [-139.9733, 60.1829],
              [-141.002, 60.3003],
              [-141.002, 62.3457],
              [-141.002, 64.0989],
              [-141.002, 65.56],
              [-141.002, 67.3132],
              [-141.002, 69.6508],
              [-139.1814, 69.5157],
              [-138.1283, 69.1519],
              [-136.7174, 68.8889],
              [-135.8762, 68.9167],
              [-135.6914, 69.3109],
              [-135.1409, 69.4676],
              [-133.8799, 69.5079],
              [-133.163, 69.4336],
              [-132.8404, 69.6508],
              [-131.934, 69.7532],
              [-130.4985, 70.1429],
              [-129.6482, 69.9977],
              [-130.3538, 69.6557],
              [-128.3867, 69.9603],
              [-127.765, 70.222],
              [-127.1384, 70.2391],
              [-126.6121, 69.7302],
              [-125.9074, 69.4186],
              [-124.7677, 69.9899],
              [-124.1385, 69.6531],
              [-124.426, 69.3794],
              [-123.6093, 69.3771],
              [-123.0258, 69.8102],
              [-122.0701, 69.8163],
              [-120.9625, 69.6605],
              [-120.2926, 69.4205],
              [-118.8687, 69.257],
              [-118.0954, 69.0429],
              [-116.0652, 68.8554],
              [-115.6313, 68.9728],
              [-114.6205, 68.7464],
              [-113.9646, 68.3989],
              [-114.7653, 68.2702],
              [-115.4267, 67.9233],
              [-115.1331, 67.8194],
              [-113.8931, 67.707],
              [-112.4351, 67.6847],
              [-110.9901, 67.7909],
              [-110.0426, 67.977],
              [-109.6303, 67.7329],
              [-109.081, 67.7109],
              [-107.9888, 67.2566],
              [-108.4553, 67.0629],
              [-107.1565, 66.882],
              [-107.9722, 67.732],
              [-107.761, 68.0323],
              [-106.8356, 68.1285],
              [-106.6086, 68.3576],
              [-107.6196, 68.331],
              [-108.2611, 68.1501],
              [-108.6864, 68.2772],
              [-108.3134, 68.6109],
              [-107.4359, 68.6891],
              [-106.1647, 68.9198],
              [-105.5397, 68.7187],
              [-105.4284, 68.4585],
              [-104.1934, 68.0308],
              [-103.4741, 68.1151],
              [-102.692, 67.8114],
              [-101.555, 67.6932],
              [-100.4562, 67.8394],
              [-99.1467, 67.7236],
              [-98.4171, 67.8264],
              [-97.4549, 67.6169],
              [-97.2066, 67.8552],
              [-98.4383, 68.065],
              [-98.2185, 68.3173],
              [-97.266, 68.4528],
              [-96.9769, 68.2556],
              [-96.036, 68.1579],
              [-96.3715, 67.5538],
              [-95.7827, 67.1935],
              [-95.2587, 67.2624],
              [-95.6505, 67.7374],
              [-95.4609, 68.0216],
              [-94.7442, 68.0711],
              [-93.6515, 68.5428],
              [-93.6815, 68.8873],
              [-94.3379, 69.4742],
              [-96.0501, 69.8309],
              [-96.4924, 70.1248],
              [-96.2264, 70.5416],
              [-96.549, 70.8091],
              [-96.4057, 71.2738],
              [-95.2014, 71.9037],
              [-94.3083, 71.7651],
              [-93.2563, 71.461],
              [-92.9487, 71.2623],
              [-92.9816, 70.8523],
              [-92.3883, 70.6506],
              [-91.5639, 70.1782],
              [-92.7508, 69.7139],
              [-91.9119, 69.5311],
              [-90.5547, 69.5042],
              [-90.822, 69.2901],
              [-90.4683, 68.8638],
              [-90.5738, 68.4744],
              [-89.8796, 68.5217],
              [-89.6668, 69.0148],
              [-89.0567, 69.2658],
              [-88.0415, 68.8116],
              [-87.8101, 68.4043],
              [-88.347, 68.2883],
              [-88.1957, 67.766],
              [-87.2664, 67.1838],
              [-86.5362, 67.5161],
              [-85.9527, 68.0724],
              [-85.7339, 68.6298],
              [-85.2751, 68.7411],
              [-84.8622, 69.074],
              [-85.387, 69.2319],
              [-85.5075, 69.8455],
              [-84.2418, 69.8348],
              [-83.6654, 69.6997],
              [-82.6183, 69.6909],
              [-82.6422, 69.4583],
              [-81.3286, 69.1203],
              [-81.2815, 68.6572],
              [-82.5525, 68.4462],
              [-81.9766, 67.8618],
              [-81.2702, 67.4598],
              [-81.4676, 67.0699],
              [-82.1982, 66.7645],
              [-83.4063, 66.3712],
              [-84.4785, 66.1793],
              [-85.6039, 66.5686],
              [-86.6333, 66.5316],
              [-86.585, 66.3218],
              [-86.0429, 66.0226],
              [-87.2915, 65.3547],
              [-87.9701, 65.349],
              [-89.7494, 65.9361],
              [-90.1165, 65.8822],
              [-88.974, 65.3482],
              [-87.0277, 65.198],
              [-87.2804, 64.8263],
              [-88.1056, 64.1833],
              [-88.653, 64.0093],
              [-89.9536, 64.0804],
              [-90.0137, 63.8045],
              [-91.3303, 63.5065],
              [-90.7466, 63.3517],
              [-90.8714, 62.946],
              [-91.449, 62.8039],
              [-92.3612, 62.8193],
              [-93.4208, 61.7058],
              [-93.9127, 61.4815],
              [-94.0679, 61.1385],
              [-94.6468, 60.4163],
              [-94.786, 59.9534],
              [-94.7136, 58.9032],
              [-94.2869, 58.7161],
              [-93.2781, 58.7562],
              [-92.4397, 57.2755],
              [-92.2489, 57.0092],
              [-91.1113, 57.2409],
              [-90.5921, 57.2247],
              [-89.7908, 56.9814],
              [-88.9483, 56.8512],
              [-88.075, 56.4671],
              [-87.5609, 56.0563],
              [-85.8305, 55.6567],
              [-85.061, 55.2856],
              [-83.9107, 55.3148],
              [-82.5774, 55.1488],
              [-82.2194, 54.8138],
              [-82.4181, 54.3561],
              [-82.1413, 53.8176],
              [-82.2605, 52.9613],
              [-81.5994, 52.4324],
              [-81.6613, 52.2938],
              [-80.6579, 51.7584],
              [-80.3681, 51.3298],
              [-79.8361, 51.1736],
              [-79.4975, 51.57],
              [-78.9776, 51.7337],
              [-78.4917, 52.2519],
              [-78.7239, 52.628],
              [-79.1131, 53.7174],
              [-78.9959, 54.0024],
              [-79.2419, 54.0987],
              [-79.6657, 54.6973],
              [-78.909, 54.8817],
              [-77.8911, 55.2362],
              [-77.165, 55.6635],
              [-76.6503, 56.1075],
              [-76.5197, 56.7069],
              [-76.573, 57.1815],
              [-76.8909, 57.7583],
              [-77.157, 58.0192],
              [-78.5152, 58.6822],
              [-78.4302, 58.902],
              [-77.7605, 59.3799],
              [-77.7262, 59.6762],
              [-77.3318, 59.7967],
              [-77.3118, 60.0424],
              [-77.8715, 60.7858],
              [-77.7362, 61.4372],
              [-78.1372, 62.1074],
              [-78.0681, 62.3554],
              [-77.3723, 62.5725],
              [-75.8171, 62.3157],
              [-74.69, 62.1833],
              [-73.63, 62.4543],
              [-72.0816, 61.7282],
              [-71.4227, 61.1588],
              [-70.2792, 61.0688],
              [-69.6403, 60.6896],
              [-69.8054, 59.945],
              [-69.4003, 59.3375],
              [-69.5315, 58.8693],
              [-68.6983, 58.9047],
              [-68.3265, 58.5952],
              [-67.5696, 58.2134],
              [-66.6079, 58.5489],
              [-65.6062, 59.1107],
              [-64.9311, 60.2518],
              [-64.4995, 60.2684],
              [-63.9291, 59.6448],
              [-63.5398, 59.3328],
              [-63.1855, 58.8578],
              [-62.5939, 58.4743],
              [-62.6743, 58.3193],
              [-62.3058, 57.9724],
              [-61.8991, 57.8611],
              [-61.8512, 57.3814],
              [-61.3336, 57.0106],
              [-61.5146, 56.39],
              [-61.0893, 55.8661],
              [-60.8317, 55.9578],
              [-59.9302, 55.2597],
              [-58.997, 55.1492],
              [-58.7801, 54.8385],
              [-58.1951, 54.8659],
              [-57.4048, 54.5706],
              [-57.6993, 54.3865],
              [-58.1517, 54.3504],
              [-58.6331, 54.0497],
              [-59.4967, 53.8342],
              [-59.823, 53.8348],
              [-59.829, 53.5046],
              [-59.3221, 53.6436],
              [-58.9193, 53.8753],
              [-58.3269, 54.052],
              [-58.192, 54.228],
              [-57.4159, 54.1626],
              [-57.149, 53.8476],
              [-57.4895, 53.6331],
              [-57.3316, 53.4688],
              [-56.8408, 53.7394],
              [-56.4651, 53.7649],
              [-56.1104, 53.5877],
              [-55.7978, 53.212],
              [-55.8336, 52.3107],
              [-55.6952, 52.1376],
              [-56.9759, 51.4579],
              [-57.7696, 51.4261],
              [-58.5105, 51.2949],
              [-59.1654, 50.7798],
              [-60.0801, 50.2546],
              [-61.2896, 50.2021],
              [-61.7249, 50.1041],
              [-61.9195, 50.233],
              [-62.7155, 50.3015],
              [-63.2386, 50.2427],
              [-63.7338, 50.3046],
              [-64.8678, 50.2754],
              [-65.2684, 50.32],
              [-66.1255, 50.2011],
              [-66.4954, 50.2118],
              [-66.9412, 49.9938],
              [-67.3718, 49.3484],
              [-68.2821, 49.197],
              [-68.929, 48.8289],
              [-69.3748, 48.3862],
              [-69.775, 48.0979],
              [-69.9056, 47.8322],
              [-70.7057, 47.1397],
              [-71.2679, 46.7959],
              [-71.8796, 46.6873],
              [-72.6804, 46.2877],
              [-72.9808, 46.2096],
              [-73.4766, 45.7387],
              [-74.038, 45.5016],
              [-73.974, 45.3449],
              [-74.7087, 45.0038]
            ]
          ],
          [
            [
              [-97.4395, 69.6424],
              [-96.2999, 69.3444],
              [-95.6855, 68.7357],
              [-96.4015, 68.4709],
              [-97.4721, 68.5439],
              [-99.254, 68.8634],
              [-99.564, 69.0341],
              [-98.456, 69.3343],
              [-98.2681, 69.7544],
              [-97.4395, 69.6424]
            ]
          ],
          [
            [
              [-79.4308, 69.7875],
              [-80.1615, 69.536],
              [-80.6525, 69.7505],
              [-79.4308, 69.7875]
            ]
          ],
          [
            [
              [-79.2108, 68.8453],
              [-78.5968, 69.3705],
              [-78.2289, 69.3039],
              [-79.2108, 68.8453]
            ]
          ],
          [
            [
              [-75.6758, 68.3226],
              [-75.1537, 68.234],
              [-75.1274, 67.5373],
              [-75.7803, 67.2836],
              [-77.0048, 67.2669],
              [-77.3056, 67.7059],
              [-76.7404, 68.2313],
              [-75.6758, 68.3226]
            ]
          ],
          [
            [
              [-73.6218, 67.7837],
              [-74.5734, 67.8287],
              [-74.3795, 68.0935],
              [-73.4352, 67.97],
              [-73.6218, 67.7837]
            ]
          ],
          [
            [
              [-84.9198, 65.2612],
              [-84.6126, 65.4475],
              [-84.0851, 65.2178],
              [-83.4071, 65.1041],
              [-82.6675, 64.78],
              [-82.05, 64.6441],
              [-81.7872, 64.4261],
              [-81.716, 64.022],
              [-80.8291, 64.0901],
              [-80.3021, 63.7621],
              [-81.0463, 63.4615],
              [-82.3782, 63.7066],
              [-82.4672, 63.9268],
              [-83.4943, 64.0994],
              [-83.7283, 63.8133],
              [-84.3076, 63.5857],
              [-84.6331, 63.3093],
              [-85.4953, 63.1388],
              [-85.7143, 63.6581],
              [-87.1889, 63.6725],
              [-86.8862, 63.9237],
              [-86.2519, 64.137],
              [-86.3744, 64.5659],
              [-85.9617, 65.7043],
              [-85.241, 65.7952],
              [-85.2399, 65.51],
              [-84.9198, 65.2612]
            ]
          ],
          [
            [
              [-77.8767, 63.4707],
              [-77.5384, 63.2867],
              [-77.9425, 63.1142],
              [-78.4687, 63.3578],
              [-77.8767, 63.4707]
            ]
          ],
          [
            [
              [-82.0004, 62.9544],
              [-82.1137, 62.6521],
              [-83.0158, 62.2098],
              [-83.6987, 62.1602],
              [-83.8992, 62.4763],
              [-83.3765, 62.905],
              [-82.0004, 62.9544]
            ]
          ],
          [
            [
              [-79.5454, 62.4115],
              [-79.3239, 62.0262],
              [-79.669, 61.6443],
              [-80.2653, 61.8183],
              [-80.0216, 62.3431],
              [-79.5454, 62.4115]
            ]
          ],
          [
            [
              [-78.9355, 56.2664],
              [-79.1755, 55.885],
              [-79.7646, 55.8068],
              [-80.0009, 55.9323],
              [-79.5966, 56.2442],
              [-78.9355, 56.2664]
            ]
          ],
          [
            [
              [-132.6556, 54.1274],
              [-131.7026, 53.9862],
              [-132.0112, 53.2654],
              [-132.5203, 53.1939],
              [-132.7476, 53.3105],
              [-133.0522, 53.7783],
              [-133.0639, 54.1437],
              [-132.6556, 54.1274]
            ]
          ],
          [
            [
              [-128.5527, 52.9395],
              [-128.5099, 52.5186],
              [-129.0847, 52.8227],
              [-129.0331, 53.2798],
              [-128.5527, 52.9395]
            ]
          ],
          [
            [
              [-131.7538, 53.1955],
              [-131.6347, 52.9224],
              [-131.8098, 52.5414],
              [-132.2599, 52.907],
              [-132.3455, 53.1361],
              [-131.7538, 53.1955]
            ]
          ],
          [
            [
              [-80.7317, 52.7473],
              [-81.8392, 52.9576],
              [-81.8472, 53.1863],
              [-81.1359, 53.2058],
              [-80.7317, 52.7473]
            ]
          ]
        ]
      },
      properties: { name: "Canada", id: "CA", Continent: "North America" },
      id: "CA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.6422, 10.917],
            [-83.4481, 10.4661],
            [-82.7783, 9.6693],
            [-82.5636, 9.5765],
            [-82.9402, 9.4494],
            [-82.9171, 8.7406],
            [-82.9975, 8.3676],
            [-82.8793, 8.0705],
            [-83.1232, 8.353],
            [-83.1624, 8.5879],
            [-83.7341, 8.6144],
            [-83.6373, 9.0355],
            [-83.8955, 9.2759],
            [-84.6588, 9.6473],
            [-84.7152, 9.8992],
            [-85.1145, 9.5815],
            [-85.3146, 9.8109],
            [-85.6249, 9.9022],
            [-85.7966, 10.1332],
            [-85.8305, 10.398],
            [-85.6672, 10.7449],
            [-85.7442, 11.0624],
            [-85.6216, 11.1842],
            [-84.9091, 10.9454],
            [-84.7012, 11.0521],
            [-84.3483, 10.9798],
            [-84.1684, 10.7803],
            [-83.8112, 10.7433],
            [-83.6422, 10.917]
          ]
        ]
      },
      properties: { name: "Costa Rica", id: "CR", Continent: "Central America" },
      id: "CR"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.8378, 23.1626],
              [-81.1787, 23.0594],
              [-80.65, 23.1032],
              [-80.365, 22.9434],
              [-80.0753, 22.9424],
              [-79.6766, 22.7429],
              [-79.2756, 22.4075],
              [-78.6867, 22.3668],
              [-78.1432, 22.1096],
              [-77.8652, 21.9002],
              [-77.4975, 21.8717],
              [-76.6476, 21.2842],
              [-76.0735, 21.1336],
              [-75.7229, 21.1106],
              [-75.6429, 20.7337],
              [-74.8827, 20.6508],
              [-74.513, 20.3845],
              [-74.1673, 20.2924],
              [-74.2528, 20.0796],
              [-74.6347, 20.0584],
              [-75.1165, 19.9013],
              [-75.5518, 19.891],
              [-76.1584, 19.9895],
              [-76.9997, 19.8929],
              [-77.7151, 19.8558],
              [-77.5538, 20.0822],
              [-77.2135, 20.3002],
              [-77.2298, 20.644],
              [-77.9976, 20.7152],
              [-78.4909, 21.0537],
              [-78.6367, 21.5159],
              [-78.823, 21.6191],
              [-79.2746, 21.5626],
              [-80.2312, 21.8721],
              [-80.4991, 22.0631],
              [-80.962, 22.053],
              [-81.4413, 22.1836],
              [-81.8164, 22.2001],
              [-81.7572, 22.4665],
              [-81.9034, 22.679],
              [-82.7384, 22.689],
              [-83.3798, 22.2229],
              [-83.9005, 22.1696],
              [-84.031, 21.9432],
              [-84.3265, 22.0742],
              [-84.3611, 22.3787],
              [-84.0448, 22.6662],
              [-83.2577, 22.9677],
              [-82.6658, 23.0436],
              [-82.1014, 23.1906],
              [-81.8378, 23.1626]
            ]
          ],
          [
            [
              [-82.5618, 21.5714],
              [-82.8532, 21.4439],
              [-83.0827, 21.7913],
              [-82.7146, 21.8902],
              [-82.5618, 21.5714]
            ]
          ]
        ]
      },
      properties: { name: "Cuba", id: "CU", Continent: "Caribbean" },
      id: "CU"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.7512, 12.0599],
            [-68.8034, 12.0458],
            [-68.995, 12.142],
            [-69.154, 12.2983],
            [-69.0767, 12.3423],
            [-69.0131, 12.2312],
            [-68.8275, 12.1586],
            [-68.7512, 12.0599]
          ]
        ]
      },
      properties: { name: "Curaçao", id: "CW", Continent: "Caribbean" },
      id: "CW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.3695, 19.349],
            [-81.1306, 19.3469],
            [-81.3035, 19.2719],
            [-81.3695, 19.349]
          ]
        ]
      },
      properties: { name: "Cayman Islands", id: "KY", Continent: "Caribbean" },
      id: "KY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.2818, 15.2495],
            [-61.4811, 15.5252],
            [-61.32, 15.5853],
            [-61.2818, 15.2495]
          ]
        ]
      },
      properties: { name: "Dominica", id: "DM", Continent: "Caribbean" },
      id: "DM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.7682, 18.0391],
            [-71.7618, 18.3414],
            [-72.0006, 18.5978],
            [-71.7433, 18.7329],
            [-71.8071, 18.9866],
            [-71.6569, 19.1306],
            [-71.7793, 19.7185],
            [-71.5578, 19.8955],
            [-70.9543, 19.914],
            [-70.1295, 19.6364],
            [-69.9566, 19.6718],
            [-69.7394, 19.2992],
            [-69.3228, 19.2011],
            [-68.6847, 18.9046],
            [-68.3592, 18.5382],
            [-68.6588, 18.2221],
            [-68.9348, 18.408],
            [-69.7707, 18.4438],
            [-70.1832, 18.2517],
            [-70.4799, 18.2174],
            [-70.6448, 18.336],
            [-71.0279, 18.2729],
            [-71.3958, 17.6465],
            [-71.6319, 17.7732],
            [-71.7682, 18.0391]
          ]
        ]
      },
      properties: { name: "Dominican Republic", id: "DO", Continent: "Caribbean" },
      id: "DO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.3272, 16.2302],
            [-61.522, 16.2279],
            [-61.529, 16.4341],
            [-61.4066, 16.4681],
            [-61.3272, 16.2302]
          ]
        ]
      },
      properties: { name: "Guadeloupe", id: "GP", Continent: "Caribbean" },
      id: "GP"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.7155, 12.0126],
            [-61.7149, 12.1848],
            [-61.6073, 12.2232],
            [-61.6271, 12.0542],
            [-61.7155, 12.0126]
          ]
        ]
      },
      properties: { name: "Grenada", id: "GD", Continent: "Caribbean" },
      id: "GD"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-25.4324, 70.9212],
              [-25.4022, 70.6528],
              [-26.2179, 70.4542],
              [-26.6047, 70.5535],
              [-27.8979, 70.4542],
              [-27.7089, 70.8973],
              [-26.9757, 70.8628],
              [-25.819, 71.0433],
              [-25.4324, 70.9212]
            ]
          ],
          [
            [
              [-18.0004, 75.4075],
              [-17.5861, 74.993],
              [-18.6708, 75.0013],
              [-18.8561, 75.3192],
              [-18.0004, 75.4075]
            ]
          ],
          [
            [
              [-29.953, 83.5649],
              [-25.9475, 83.2899],
              [-24.4702, 82.8772],
              [-21.5207, 82.5956],
              [-25.149, 82.001],
              [-21.3381, 82.0686],
              [-21.1464, 81.6952],
              [-20.0158, 81.5641],
              [-17.2262, 81.4303],
              [-15.5555, 81.8337],
              [-12.193, 81.6492],
              [-11.5288, 81.4241],
              [-14.5035, 80.7633],
              [-16.7607, 80.5731],
              [-15.9326, 80.3953],
              [-16.8684, 80.1984],
              [-19.4292, 80.2579],
              [-20.1384, 79.8033],
              [-19.2838, 79.6828],
              [-19.0749, 79.1522],
              [-20.9558, 78.5551],
              [-21.7477, 77.7905],
              [-20.8624, 77.9119],
              [-20.232, 77.3681],
              [-18.3391, 77.2154],
              [-18.5102, 76.778],
              [-20.0644, 76.9276],
              [-20.942, 76.887],
              [-21.8774, 76.5732],
              [-21.5692, 76.2938],
              [-20.1036, 76.2192],
              [-19.509, 75.7574],
              [-19.4261, 75.23],
              [-20.4849, 75.3143],
              [-20.9056, 75.1568],
              [-19.9852, 74.9749],
              [-19.2252, 74.4796],
              [-19.3693, 74.2841],
              [-22.2706, 74.0298],
              [-20.3673, 73.8485],
              [-20.5096, 73.4931],
              [-21.548, 73.4317],
              [-22.1853, 73.2702],
              [-23.899, 73.3982],
              [-22.0366, 72.9185],
              [-22.2932, 72.1195],
              [-24.069, 72.4987],
              [-24.667, 72.4373],
              [-22.0133, 71.6887],
              [-21.7522, 71.4781],
              [-21.5227, 70.5262],
              [-21.9436, 70.4435],
              [-23.328, 70.4511],
              [-23.7917, 70.5552],
              [-24.3769, 71.1461],
              [-25.8852, 71.5717],
              [-25.7423, 71.1838],
              [-26.718, 70.9508],
              [-27.6886, 70.9936],
              [-28.5409, 70.4766],
              [-28.0149, 70.4026],
              [-26.747, 70.4754],
              [-26.4155, 70.2214],
              [-25.5301, 70.353],
              [-23.1733, 70.1147],
              [-23.0884, 69.883],
              [-23.709, 69.6273],
              [-26.483, 68.6757],
              [-29.2495, 68.2988],
              [-29.8687, 68.3115],
              [-30.9787, 68.0613],
              [-32.1374, 68.3849],
              [-32.2748, 67.9227],
              [-33.1569, 67.6266],
              [-34.1016, 66.7257],
              [-34.6328, 66.4347],
              [-36.3794, 65.8306],
              [-37.754, 65.5931],
              [-38.6366, 65.6243],
              [-39.9611, 65.556],
              [-39.5778, 65.3405],
              [-40.6553, 64.9155],
              [-40.1821, 64.4798],
              [-40.6179, 64.1316],
              [-40.5504, 63.7255],
              [-41.0487, 63.5141],
              [-41.1079, 63.2739],
              [-42.1527, 62.5686],
              [-42.3217, 62.1528],
              [-42.1101, 61.8571],
              [-42.4188, 61.5372],
              [-42.7172, 60.7677],
              [-43.1566, 60.333],
              [-43.3203, 59.9281],
              [-44.0653, 59.9246],
              [-45.3791, 60.2028],
              [-45.5905, 60.5187],
              [-46.0468, 60.616],
              [-46.0116, 61.0969],
              [-46.8744, 60.8162],
              [-47.7963, 60.829],
              [-48.9221, 61.2775],
              [-49.2889, 61.59],
              [-49.5534, 62.2326],
              [-50.319, 62.4732],
              [-50.3383, 62.8286],
              [-50.7434, 63.051],
              [-51.4689, 63.642],
              [-51.4512, 63.9048],
              [-51.7582, 64.2799],
              [-52.0934, 64.4158],
              [-52.2589, 65.1547],
              [-52.761, 65.5908],
              [-53.1988, 65.5939],
              [-53.1065, 65.977],
              [-53.6146, 66.1544],
              [-53.6346, 66.4135],
              [-53.0382, 66.8269],
              [-53.8844, 67.1353],
              [-53.4187, 67.5745],
              [-53.5778, 67.8367],
              [-53.1517, 68.2079],
              [-53.0393, 68.6109],
              [-52.3029, 68.7014],
              [-51.7808, 68.5482],
              [-51.2492, 68.7398],
              [-51.0769, 69.2093],
              [-50.5001, 69.936],
              [-52.2546, 70.0592],
              [-53.023, 70.3018],
              [-54.0144, 70.4215],
              [-54.5011, 70.6572],
              [-54.1657, 70.82],
              [-52.802, 70.7507],
              [-51.5246, 70.4396],
              [-51.1302, 70.9714],
              [-53.117, 71.3131],
              [-52.7495, 71.5016],
              [-53.476, 71.6402],
              [-54.689, 71.3674],
              [-55.594, 71.5536],
              [-55.3156, 72.1107],
              [-55.6016, 72.4537],
              [-54.7906, 72.6417],
              [-54.7378, 72.8725],
              [-55.6685, 73.008],
              [-55.2973, 73.2623],
              [-56.104, 73.5584],
              [-55.9294, 73.8952],
              [-56.7065, 74.2192],
              [-56.8013, 74.6717],
              [-58.6037, 75.3853],
              [-58.5163, 75.6891],
              [-61.3747, 76.1799],
              [-63.4389, 76.3393],
              [-66.826, 75.9689],
              [-68.1489, 76.0669],
              [-69.3728, 76.3318],
              [-69.2523, 76.6862],
              [-70.7929, 76.8689],
              [-70.6037, 77.1938],
              [-68.9784, 77.1951],
              [-68.1355, 77.3797],
              [-66.453, 77.3928],
              [-66.6911, 77.6811],
              [-67.9776, 77.5187],
              [-69.3516, 77.4671],
              [-71.6502, 77.8996],
              [-72.8179, 78.1944],
              [-72.3958, 78.5043],
              [-68.9936, 78.8574],
              [-67.4822, 79.1169],
              [-65.9677, 79.1324],
              [-65.4197, 79.3403],
              [-64.7923, 80.0006],
              [-66.8437, 80.0761],
              [-66.9961, 80.4133],
              [-64.5154, 80.9999],
              [-62.9933, 81.207],
              [-61.436, 81.1337],
              [-61.2028, 81.7468],
              [-58.7172, 82.0933],
              [-54.7259, 82.3514],
              [-52.7756, 82.3215],
              [-50.8943, 81.895],
              [-49.6942, 81.9723],
              [-50.9899, 82.4601],
              [-48.8612, 82.4053],
              [-45.2911, 81.8289],
              [-44.5323, 81.8492],
              [-44.2388, 82.3683],
              [-46.478, 82.9518],
              [-43.1944, 83.255],
              [-41.3002, 83.101],
              [-40.3569, 83.3323],
              [-37.8281, 83.4859],
              [-32.9844, 83.5997],
              [-29.953, 83.5649]
            ]
          ],
          [
            [
              [-52.7312, 69.9448],
              [-52.0452, 69.8071],
              [-52.1127, 69.4892],
              [-53.5782, 69.2566],
              [-53.783, 69.5064],
              [-54.7341, 69.6107],
              [-54.8303, 70.161],
              [-54.3718, 70.3172],
              [-53.3751, 70.2214],
              [-52.7312, 69.9448]
            ]
          ]
        ]
      },
      properties: { name: "Greenland", id: "GL", Continent: "North America" },
      id: "GL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.1615, 17.8148],
            [-89.2374, 15.8944],
            [-88.894, 15.8907],
            [-88.2284, 15.7292],
            [-88.9763, 15.143],
            [-89.2224, 14.8658],
            [-89.1716, 14.6067],
            [-89.3627, 14.4161],
            [-89.8726, 14.0457],
            [-90.0951, 13.7367],
            [-90.6071, 13.9291],
            [-91.1461, 13.9256],
            [-91.8191, 14.2285],
            [-92.2353, 14.5454],
            [-92.0747, 15.0741],
            [-92.187, 15.3208],
            [-91.7367, 16.07],
            [-90.4472, 16.0726],
            [-90.4171, 16.3908],
            [-90.7106, 16.7082],
            [-91.4095, 17.2565],
            [-90.9929, 17.253],
            [-90.9892, 17.8162],
            [-89.1615, 17.8148]
          ]
        ]
      },
      properties: { name: "Guatemala", id: "GT", Continent: "Central America" },
      id: "GT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.1577, 14.9929],
            [-83.415, 15.0083],
            [-84.1143, 14.7311],
            [-84.5377, 14.6336],
            [-84.8603, 14.8099],
            [-85.1614, 14.5251],
            [-85.1797, 14.3433],
            [-85.7869, 13.8444],
            [-86.1513, 13.9945],
            [-86.3318, 13.7698],
            [-86.7337, 13.7636],
            [-86.7106, 13.3135],
            [-87.0592, 12.9917],
            [-87.3374, 12.9789],
            [-87.4893, 13.3532],
            [-87.8145, 13.399],
            [-87.7153, 13.8127],
            [-88.1509, 13.9869],
            [-88.4488, 13.851],
            [-89.0571, 14.3291],
            [-89.3627, 14.4161],
            [-89.1716, 14.6067],
            [-89.2224, 14.8658],
            [-88.9763, 15.143],
            [-88.2284, 15.7292],
            [-87.6183, 15.9098],
            [-86.9072, 15.7628],
            [-86.3567, 15.7831],
            [-85.784, 16.0029],
            [-85.4836, 15.8995],
            [-84.9739, 15.99],
            [-84.2613, 15.8228],
            [-83.7604, 15.2203],
            [-83.3691, 15.24],
            [-83.1577, 14.9929]
          ]
        ]
      },
      properties: { name: "Honduras", id: "HN", Continent: "Central America" },
      id: "HN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.7793, 19.7185],
            [-71.6569, 19.1306],
            [-71.8071, 18.9866],
            [-71.7433, 18.7329],
            [-72.0006, 18.5978],
            [-71.7618, 18.3414],
            [-71.7682, 18.0391],
            [-72.06, 18.2289],
            [-72.8768, 18.152],
            [-73.3853, 18.2513],
            [-73.7474, 18.19],
            [-73.8852, 18.0422],
            [-74.419, 18.3463],
            [-74.2275, 18.6628],
            [-73.5915, 18.5223],
            [-72.7896, 18.4345],
            [-72.3479, 18.6747],
            [-72.8112, 19.0714],
            [-72.7032, 19.4409],
            [-73.0526, 19.6107],
            [-73.1178, 19.904],
            [-72.637, 19.9009],
            [-72.22, 19.7446],
            [-71.7793, 19.7185]
          ]
        ]
      },
      properties: { name: "Haiti", id: "HT", Continent: "Caribbean" },
      id: "HT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.2612, 18.4574],
            [-76.9082, 18.3899],
            [-76.3499, 18.152],
            [-76.2107, 17.9137],
            [-76.5247, 17.8664],
            [-76.8533, 17.9737],
            [-77.3616, 17.8337],
            [-77.7681, 17.8775],
            [-78.2168, 18.4481],
            [-77.8736, 18.5219],
            [-77.2612, 18.4574]
          ]
        ]
      },
      properties: { name: "Jamaica", id: "JM", Continent: "Caribbean" },
      id: "JM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.6307, 17.24],
            [-62.839, 17.3393],
            [-62.7946, 17.4025],
            [-62.7136, 17.3535],
            [-62.6307, 17.24]
          ]
        ]
      },
      properties: { name: "Saint Kitts and Nevis", id: "KN", Continent: "Caribbean" },
      id: "KN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.8952, 13.822],
            [-61.0634, 13.916],
            [-60.8866, 14.0112],
            [-60.8952, 13.822]
          ]
        ]
      },
      properties: { name: "Saint Lucia", id: "LC", Continent: "Caribbean" },
      id: "LC"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-63.0112, 18.0685],
            [-63.123, 18.0685],
            [-63.063, 18.1154],
            [-63.0112, 18.0685]
          ]
        ]
      },
      properties: { name: "Saint Martin", id: "MF", Continent: "Caribbean" },
      id: "MF"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.1461, 25.9617],
            [-97.2252, 25.5852],
            [-97.5072, 25.0143],
            [-97.6676, 24.3898],
            [-97.7659, 23.3061],
            [-97.7453, 22.9424],
            [-97.8579, 22.6247],
            [-97.6376, 21.6037],
            [-97.1214, 20.615],
            [-96.456, 19.8694],
            [-96.2894, 19.3438],
            [-95.9848, 19.0543],
            [-95.821, 18.7549],
            [-95.1819, 18.7006],
            [-94.7979, 18.5143],
            [-94.546, 18.175],
            [-94.189, 18.1952],
            [-93.5524, 18.4306],
            [-92.8849, 18.4685],
            [-92.7101, 18.612],
            [-91.974, 18.716],
            [-91.8031, 18.4703],
            [-91.5339, 18.4565],
            [-91.2754, 18.6247],
            [-91.3429, 18.9007],
            [-90.7394, 19.3521],
            [-90.6931, 19.73],
            [-90.5072, 19.9118],
            [-90.4842, 20.5566],
            [-90.353, 21.0096],
            [-89.8198, 21.2745],
            [-88.8788, 21.4139],
            [-88.1317, 21.6161],
            [-87.251, 21.447],
            [-87.1285, 21.6214],
            [-86.8241, 21.4213],
            [-86.7719, 21.1503],
            [-86.8646, 20.8846],
            [-87.2214, 20.5073],
            [-87.4671, 20.102],
            [-87.4248, 19.5834],
            [-87.7618, 18.4464],
            [-87.8533, 18.269],
            [-88.1967, 18.7195],
            [-88.2954, 18.4719],
            [-88.5231, 18.446],
            [-88.8064, 17.9653],
            [-89.0505, 17.9996],
            [-89.1615, 17.8148],
            [-90.9892, 17.8162],
            [-90.9929, 17.253],
            [-91.4095, 17.2565],
            [-90.7106, 16.7082],
            [-90.4171, 16.3908],
            [-90.4472, 16.0726],
            [-91.7367, 16.07],
            [-92.187, 15.3208],
            [-92.0747, 15.0741],
            [-92.2353, 14.5454],
            [-92.809, 15.1387],
            [-93.916, 16.0535],
            [-94.3741, 16.2848],
            [-94.6615, 16.202],
            [-94.9003, 16.4177],
            [-95.4645, 15.9741],
            [-96.2137, 15.693],
            [-96.5107, 15.6519],
            [-97.1846, 15.9094],
            [-97.7548, 15.9667],
            [-98.1388, 16.2063],
            [-98.5205, 16.3048],
            [-98.7623, 16.5347],
            [-99.6905, 16.7198],
            [-100.0244, 16.9205],
            [-100.8479, 17.2003],
            [-101.6002, 17.6519],
            [-101.847, 17.9225],
            [-102.6995, 18.0623],
            [-103.4415, 18.3255],
            [-103.9125, 18.8283],
            [-104.9385, 19.3093],
            [-105.4821, 19.9763],
            [-105.6694, 20.3854],
            [-105.2602, 20.5789],
            [-105.4922, 20.7765],
            [-105.2369, 21.1195],
            [-105.2086, 21.4906],
            [-105.4313, 21.6183],
            [-105.6492, 21.9876],
            [-105.6454, 22.3271],
            [-105.7917, 22.6278],
            [-106.4024, 23.1957],
            [-106.9353, 23.8809],
            [-107.5273, 24.3598],
            [-107.512, 24.4891],
            [-107.9512, 24.615],
            [-108.3736, 25.194],
            [-108.7508, 25.4244],
            [-109.0286, 25.4805],
            [-109.3849, 25.7272],
            [-109.4255, 26.0326],
            [-109.1997, 26.3055],
            [-109.4829, 26.7106],
            [-109.7547, 26.7032],
            [-109.9441, 27.0793],
            [-110.4777, 27.3227],
            [-110.6155, 27.6538],
            [-110.5297, 27.864],
            [-111.1216, 27.9668],
            [-111.4718, 28.384],
            [-111.6802, 28.4704],
            [-112.162, 29.0186],
            [-112.2232, 29.2695],
            [-112.7383, 29.9853],
            [-112.8247, 30.3001],
            [-113.1104, 30.7936],
            [-113.0467, 31.179],
            [-113.6234, 31.3461],
            [-113.9478, 31.6295],
            [-114.1493, 31.5074],
            [-114.8396, 31.7985],
            [-114.8817, 31.1563],
            [-114.6332, 30.5065],
            [-114.6498, 30.2382],
            [-114.3724, 29.8304],
            [-113.8291, 29.4397],
            [-113.4998, 28.9269],
            [-113.2056, 28.7984],
            [-113.0937, 28.5119],
            [-112.871, 28.4245],
            [-112.7339, 27.8261],
            [-112.329, 27.5232],
            [-112.0092, 26.967],
            [-111.5698, 26.7075],
            [-111.3321, 26.1254],
            [-111.2917, 25.79],
            [-111.0344, 25.5272],
            [-110.6773, 24.7883],
            [-110.7345, 24.5901],
            [-110.547, 24.214],
            [-110.0229, 24.1743],
            [-109.4211, 23.4801],
            [-109.4582, 23.2148],
            [-109.9236, 22.886],
            [-110.086, 23.0051],
            [-110.3628, 23.6049],
            [-110.6298, 23.7374],
            [-111.0361, 24.105],
            [-111.4195, 24.3294],
            [-112.0727, 24.8399],
            [-112.1283, 25.0435],
            [-112.0558, 25.4879],
            [-112.1736, 25.9125],
            [-112.3772, 26.2136],
            [-113.0209, 26.5831],
            [-113.2058, 26.8568],
            [-113.5986, 26.7213],
            [-113.8409, 26.9666],
            [-114.4455, 27.2183],
            [-114.54, 27.4309],
            [-114.9937, 27.7361],
            [-114.3007, 27.8728],
            [-114.0486, 28.4264],
            [-114.1456, 28.6055],
            [-114.9937, 29.3846],
            [-115.6736, 29.7562],
            [-115.8158, 30.304],
            [-115.9959, 30.4144],
            [-116.0623, 30.8041],
            [-116.2965, 30.9704],
            [-116.3333, 31.2026],
            [-116.6621, 31.5647],
            [-116.6209, 31.8516],
            [-116.848, 31.9972],
            [-117.1283, 32.5335],
            [-114.7246, 32.7154],
            [-114.8359, 32.5084],
            [-112.4648, 31.7681],
            [-111.0421, 31.3245],
            [-109.9814, 31.3262],
            [-108.2146, 31.3295],
            [-108.2119, 31.7792],
            [-106.4533, 31.7699],
            [-106.2558, 31.5448],
            [-105.276, 30.8072],
            [-104.9789, 30.6462],
            [-104.6812, 30.1346],
            [-104.5039, 29.6772],
            [-104.1107, 29.3863],
            [-103.2577, 29.0011],
            [-102.892, 29.2164],
            [-102.7342, 29.6441],
            [-102.2689, 29.8709],
            [-101.4404, 29.777],
            [-100.6587, 29.0681],
            [-100.2961, 28.3279],
            [-99.7544, 27.7303],
            [-99.5054, 27.5483],
            [-99.4435, 27.0369],
            [-99.1079, 26.4472],
            [-98.0828, 26.0641],
            [-97.8014, 26.0423],
            [-97.3582, 25.8706],
            [-97.1461, 25.9617]
          ]
        ]
      },
      properties: { name: "Mexico", id: "MX", Continent: "Central America" },
      id: "MX"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.1483, 16.7403],
            [-62.1543, 16.6813],
            [-62.2217, 16.6994],
            [-62.223, 16.7518],
            [-62.1483, 16.7403]
          ]
        ]
      },
      properties: { name: "Montserrat", id: "MS", Continent: "Caribbean" },
      id: "MS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.8261, 14.4946],
            [-61.0636, 14.4669],
            [-61.2197, 14.8045],
            [-60.9184, 14.7352],
            [-60.8261, 14.4946]
          ]
        ]
      },
      properties: { name: "Martinique", id: "MQ", Continent: "Caribbean" },
      id: "MQ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.1577, 14.9929],
            [-83.2992, 14.7492],
            [-83.1879, 14.3402],
            [-83.4123, 13.9962],
            [-83.5673, 13.3205],
            [-83.511, 12.4116],
            [-83.7693, 11.9314],
            [-83.6516, 11.6423],
            [-83.868, 11.2997],
            [-83.6422, 10.917],
            [-83.8112, 10.7433],
            [-84.1684, 10.7803],
            [-84.3483, 10.9798],
            [-84.7012, 11.0521],
            [-84.9091, 10.9454],
            [-85.6216, 11.1842],
            [-85.7442, 11.0624],
            [-85.9613, 11.3316],
            [-86.4688, 11.7385],
            [-86.7554, 12.157],
            [-87.6676, 12.9039],
            [-87.3374, 12.9789],
            [-87.0592, 12.9917],
            [-86.7106, 13.3135],
            [-86.7337, 13.7636],
            [-86.3318, 13.7698],
            [-86.1513, 13.9945],
            [-85.7869, 13.8444],
            [-85.1797, 14.3433],
            [-85.1614, 14.5251],
            [-84.8603, 14.8099],
            [-84.5377, 14.6336],
            [-84.1143, 14.7311],
            [-83.415, 15.0083],
            [-83.1577, 14.9929]
          ]
        ]
      },
      properties: { name: "Nicaragua", id: "NI", Continent: "Central America" },
      id: "NI"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.8793, 8.0705],
            [-82.9975, 8.3676],
            [-82.9171, 8.7406],
            [-82.9402, 9.4494],
            [-82.5636, 9.5765],
            [-82.371, 9.4283],
            [-82.2443, 9.0318],
            [-81.7804, 8.9574],
            [-81.3547, 8.7803],
            [-80.8388, 8.887],
            [-80.547, 9.0818],
            [-80.1273, 9.2103],
            [-79.5775, 9.5977],
            [-78.5043, 9.4063],
            [-78.0829, 9.2362],
            [-77.3741, 8.6586],
            [-77.4072, 8.4273],
            [-77.2123, 8.0339],
            [-77.3507, 7.7055],
            [-77.7441, 7.5369],
            [-77.9009, 7.2292],
            [-78.1701, 7.5441],
            [-78.4216, 8.0608],
            [-78.1138, 8.3795],
            [-78.4693, 8.4466],
            [-78.5139, 8.6279],
            [-79.0864, 8.9971],
            [-79.4417, 9.0059],
            [-79.6877, 8.8509],
            [-79.7506, 8.5955],
            [-80.1259, 8.3499],
            [-80.3687, 8.2891],
            [-80.4582, 8.0767],
            [-80.0753, 7.667],
            [-80.4389, 7.2747],
            [-80.8454, 7.22],
            [-81.0352, 7.7108],
            [-81.2686, 7.6257],
            [-81.504, 7.7213],
            [-81.7278, 8.1377],
            [-82.6796, 8.3218],
            [-82.8793, 8.0705]
          ]
        ]
      },
      properties: { name: "Panama", id: "PA", Continent: "Central America" },
      id: "PA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.1296, 18.445],
            [-65.6289, 18.3811],
            [-65.6208, 18.2425],
            [-65.9706, 17.9741],
            [-66.4084, 17.9507],
            [-67.1968, 17.9941],
            [-67.1585, 18.4993],
            [-66.1296, 18.445]
          ]
        ]
      },
      properties: { name: "Puerto Rico", id: "PR", Continent: "Caribbean" },
      id: "PR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.3627, 14.4161],
            [-89.0571, 14.3291],
            [-88.4488, 13.851],
            [-88.1509, 13.9869],
            [-87.7153, 13.8127],
            [-87.8145, 13.399],
            [-87.9308, 13.1806],
            [-88.512, 13.1837],
            [-89.2775, 13.478],
            [-89.8041, 13.5606],
            [-90.0951, 13.7367],
            [-89.8726, 14.0457],
            [-89.3627, 14.4161]
          ]
        ]
      },
      properties: { name: "El Salvador", id: "SV", Continent: "Central America" },
      id: "SV"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-56.2671, 46.8386],
            [-56.3869, 47.0677],
            [-56.2874, 47.0708],
            [-56.315, 46.9538],
            [-56.2671, 46.8386]
          ]
        ]
      },
      properties: { name: "Saint Pierre and Miquelon", id: "PM", Continent: "North America" },
      id: "PM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-63.123, 18.0685],
            [-63.0112, 18.0685],
            [-63.0231, 18.0191],
            [-63.123, 18.0685]
          ]
        ]
      },
      properties: { name: "Sint Maarten", id: "SX", Continent: "Caribbean" },
      id: "SX"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.6613, 21.7647],
            [-71.8305, 21.7904],
            [-71.806, 21.8521],
            [-71.6685, 21.8336],
            [-71.6613, 21.7647]
          ]
        ]
      },
      properties: { name: "Turks and Caicos Islands", id: "TC", Continent: "Caribbean" },
      id: "TC"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.0122, 10.1344],
            [-61.1743, 10.0777],
            [-61.4994, 10.2687],
            [-61.4646, 10.5393],
            [-61.5919, 10.748],
            [-61.0338, 10.6699],
            [-61.0122, 10.1344]
          ]
        ]
      },
      properties: { name: "Trinidad and Tobago", id: "TT", Continent: "Caribbean" },
      id: "TT"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-134.6801, 58.1617],
              [-134.24, 58.1441],
              [-133.9955, 57.7786],
              [-133.9253, 57.3367],
              [-134.4354, 57.0569],
              [-134.487, 57.4819],
              [-134.66, 57.6378],
              [-134.6801, 58.1617]
            ]
          ],
          [
            [
              [-135.7304, 58.2442],
              [-135.0019, 58.0509],
              [-135.0849, 57.5111],
              [-135.498, 57.6621],
              [-136.0766, 57.6748],
              [-136.5686, 57.9719],
              [-136.5123, 58.0959],
              [-135.7304, 58.2442]
            ]
          ],
          [
            [
              [-152.898, 57.8241],
              [-152.5115, 57.8514],
              [-152.412, 57.4546],
              [-152.7814, 57.4533],
              [-153.0516, 57.2374],
              [-153.7931, 56.9894],
              [-154.3389, 56.921],
              [-154.7059, 57.3351],
              [-154.2815, 57.6378],
              [-153.8183, 57.5954],
              [-152.898, 57.8241]
            ]
          ],
          [
            [
              [-134.9699, 57.3513],
              [-134.6207, 56.7184],
              [-134.63, 56.3022],
              [-135.3307, 56.822],
              [-135.1996, 57.0273],
              [-135.7871, 57.3174],
              [-135.3464, 57.5327],
              [-134.9699, 57.3513]
            ]
          ],
          [
            [
              [-133.3663, 57.0036],
              [-132.9542, 56.8804],
              [-133.2127, 56.4647],
              [-133.6029, 56.464],
              [-133.866, 57.0688],
              [-133.3663, 57.0036]
            ]
          ],
          [
            [
              [-133.9898, 56.8451],
              [-133.8554, 56.5825],
              [-134.1954, 56.4135],
              [-134.3738, 56.8385],
              [-133.9898, 56.8451]
            ]
          ],
          [
            [
              [-132.1122, 56.1097],
              [-132.6598, 56.0783],
              [-132.38, 56.4986],
              [-132.1122, 56.1097]
            ]
          ],
          [
            [
              [-133.5661, 56.3392],
              [-133.203, 56.3199],
              [-132.43, 55.6868],
              [-132.4179, 55.483],
              [-131.9763, 55.2089],
              [-131.9809, 54.805],
              [-132.7823, 55.0481],
              [-133.4471, 55.7968],
              [-133.2416, 55.9208],
              [-133.5661, 56.3392]
            ]
          ],
          [
            [
              [-130.9792, 55.4891],
              [-131.0827, 55.2666],
              [-131.811, 55.2228],
              [-131.6248, 55.8315],
              [-131.2693, 55.9557],
              [-130.9792, 55.4891]
            ]
          ],
          [
            [
              [-163.4762, 54.9806],
              [-163.3582, 54.7357],
              [-164.0735, 54.621],
              [-164.5907, 54.4042],
              [-164.8876, 54.6076],
              [-164.5299, 54.8809],
              [-163.8681, 55.0388],
              [-163.4762, 54.9806]
            ]
          ],
          [
            [
              [-166.6154, 53.901],
              [-166.4442, 53.652],
              [-166.7705, 53.4764],
              [-167.136, 53.5266],
              [-166.978, 53.9627],
              [-166.6154, 53.901]
            ]
          ],
          [
            [
              [-93.378, 48.6165],
              [-92.9962, 48.6118],
              [-91.5183, 48.0582],
              [-90.8401, 48.2007],
              [-90.7443, 48.1045],
              [-90.0918, 48.1183],
              [-89.4554, 47.9959],
              [-88.3783, 48.3031],
              [-87.2078, 47.8485],
              [-84.876, 46.8999],
              [-84.5618, 46.4574],
              [-84.1923, 46.5491],
              [-83.9778, 46.0852],
              [-83.6159, 46.1171],
              [-83.5926, 45.8168],
              [-82.5509, 45.3472],
              [-82.1378, 43.5705],
              [-82.408, 43.0727],
              [-82.5453, 42.6246],
              [-83.0732, 42.3008],
              [-83.1421, 41.9759],
              [-82.6901, 41.6748],
              [-82.439, 41.6748],
              [-81.2778, 42.2088],
              [-80.2474, 42.3659],
              [-79.0369, 42.8025],
              [-79.172, 43.4665],
              [-78.7206, 43.6252],
              [-76.8199, 43.6289],
              [-76.4646, 44.0575],
              [-75.1794, 44.8991],
              [-74.7087, 45.0038],
              [-72.7659, 45.006],
              [-71.5175, 45.0077],
              [-71.3271, 45.2902],
              [-70.8982, 45.2625],
              [-70.2963, 45.9059],
              [-70.2484, 46.2511],
              [-70.0076, 46.7089],
              [-69.243, 47.4627],
              [-68.8289, 47.2032],
              [-68.3109, 47.3546],
              [-67.8067, 47.0831],
              [-67.7845, 45.7017],
              [-67.4329, 45.6028],
              [-67.4725, 45.2756],
              [-67.1248, 45.1693],
              [-67.1065, 44.8855],
              [-67.907, 44.4537],
              [-68.2774, 44.5076],
              [-68.612, 44.3104],
              [-68.9615, 44.434],
              [-69.0682, 44.0976],
              [-69.7917, 43.8054],
              [-70.1789, 43.7661],
              [-70.5208, 43.3486],
              [-70.8291, 42.8251],
              [-70.7357, 42.6692],
              [-71.0462, 42.3312],
              [-70.7382, 42.2292],
              [-70.4046, 41.6271],
              [-70.6572, 41.5344],
              [-71.4264, 41.6333],
              [-71.5227, 41.3791],
              [-72.8471, 41.2656],
              [-73.5831, 41.0219],
              [-74.2267, 40.608],
              [-73.9576, 40.3285],
              [-74.1761, 39.7269],
              [-74.4289, 39.3869],
              [-74.8973, 39.1455],
              [-75.3532, 39.3399],
              [-75.3922, 39.0931],
              [-75.0361, 38.5034],
              [-75.5966, 37.6313],
              [-75.6592, 37.9539],
              [-76.2117, 38.3613],
              [-76.1683, 38.8525],
              [-76.5193, 38.8984],
              [-76.5012, 38.5326],
              [-76.2641, 37.8934],
              [-76.2635, 37.3565],
              [-76.4011, 37.2128],
              [-75.9993, 36.9126],
              [-75.8905, 36.6566],
              [-76.1481, 36.2796],
              [-76.479, 36.0284],
              [-75.7723, 35.9001],
              [-75.7741, 35.6471],
              [-76.1738, 35.3535],
              [-76.5129, 35.2706],
              [-76.7451, 34.9414],
              [-76.7332, 34.707],
              [-77.1339, 34.7082],
              [-77.7508, 34.285],
              [-78.0134, 33.912],
              [-78.5777, 33.8731],
              [-78.9203, 33.6587],
              [-79.276, 33.1355],
              [-80.0218, 32.62],
              [-80.2297, 32.5762],
              [-80.8491, 32.1142],
              [-81.1976, 31.7041],
              [-81.1754, 31.531],
              [-81.5203, 30.8748],
              [-81.2495, 29.7938],
              [-80.8999, 29.0501],
              [-80.5242, 28.486],
              [-80.65, 28.1808],
              [-80.2262, 27.2072],
              [-80.1259, 27.0828],
              [-80.0414, 26.5685],
              [-80.1265, 25.8336],
              [-80.3669, 25.3312],
              [-80.7366, 25.1564],
              [-81.0977, 25.3193],
              [-81.365, 25.8313],
              [-81.7154, 25.9829],
              [-81.8666, 26.4352],
              [-82.4415, 27.0597],
              [-82.6609, 27.7188],
              [-82.8437, 27.8463],
              [-82.6607, 28.4854],
              [-82.6517, 28.8872],
              [-83.2908, 29.4521],
              [-83.6942, 29.9262],
              [-84.0442, 30.1037],
              [-84.3829, 29.9077],
              [-85.3189, 29.6799],
              [-85.3537, 29.8759],
              [-85.7557, 30.1668],
              [-86.1754, 30.3328],
              [-86.2574, 30.493],
              [-86.986, 30.4307],
              [-87.7904, 30.2917],
              [-88.1354, 30.3663],
              [-88.9051, 30.4152],
              [-89.3207, 30.3455],
              [-89.5886, 30.1654],
              [-90.2253, 30.379],
              [-90.413, 30.1407],
              [-89.5895, 29.9151],
              [-89.7209, 29.6194],
              [-89.5138, 29.42],
              [-89.0155, 29.2028],
              [-89.3536, 29.0698],
              [-89.8773, 29.4578],
              [-90.1015, 29.1817],
              [-90.3793, 29.2954],
              [-90.7511, 29.1313],
              [-91.2902, 29.2888],
              [-91.3312, 29.514],
              [-91.8932, 29.8362],
              [-92.2608, 29.5567],
              [-92.6715, 29.5968],
              [-93.1759, 29.7792],
              [-93.6947, 29.7696],
              [-94.0998, 29.6702],
              [-94.8884, 29.3704],
              [-95.2735, 28.9635],
              [-96.0206, 28.5866],
              [-96.4485, 28.5946],
              [-96.5617, 28.3667],
              [-97.1565, 28.1442],
              [-97.1715, 27.8794],
              [-97.3805, 27.4196],
              [-97.524, 27.3141],
              [-97.5546, 26.9676],
              [-97.4023, 26.3968],
              [-97.1461, 25.9617],
              [-97.3582, 25.8706],
              [-97.8014, 26.0423],
              [-98.0828, 26.0641],
              [-99.1079, 26.4472],
              [-99.4435, 27.0369],
              [-99.5054, 27.5483],
              [-99.7544, 27.7303],
              [-100.2961, 28.3279],
              [-100.6587, 29.0681],
              [-101.4404, 29.777],
              [-102.2689, 29.8709],
              [-102.7342, 29.6441],
              [-102.892, 29.2164],
              [-103.2577, 29.0011],
              [-104.1107, 29.3863],
              [-104.5039, 29.6772],
              [-104.6812, 30.1346],
              [-104.9789, 30.6462],
              [-105.276, 30.8072],
              [-106.2558, 31.5448],
              [-106.4533, 31.7699],
              [-108.2119, 31.7792],
              [-108.2146, 31.3295],
              [-109.9814, 31.3262],
              [-111.0421, 31.3245],
              [-112.4648, 31.7681],
              [-114.8359, 32.5084],
              [-114.7246, 32.7154],
              [-117.1283, 32.5335],
              [-117.3187, 33.0998],
              [-117.4675, 33.2957],
              [-118.0806, 33.7222],
              [-118.4105, 33.7442],
              [-118.5063, 34.0175],
              [-119.1437, 34.1119],
              [-119.606, 34.4179],
              [-120.4811, 34.4713],
              [-120.6449, 34.5799],
              [-120.6336, 35.0763],
              [-120.8605, 35.3654],
              [-121.8774, 36.3313],
              [-121.79, 36.7325],
              [-121.8807, 36.9395],
              [-122.1644, 36.9911],
              [-122.3948, 37.2079],
              [-122.4669, 37.8381],
              [-122.9679, 38.0968],
              [-122.9864, 38.2769],
              [-123.7013, 38.9072],
              [-123.8845, 39.8607],
              [-124.3238, 40.2516],
              [-124.3715, 40.491],
              [-124.133, 40.9695],
              [-124.0685, 41.3842],
              [-124.3554, 42.1229],
              [-124.4062, 42.5837],
              [-124.5398, 42.8128],
              [-124.1486, 43.6914],
              [-124.0593, 44.7778],
              [-123.9292, 45.5767],
              [-123.9471, 46.1403],
              [-124.0728, 46.2793],
              [-123.8891, 46.6599],
              [-124.0716, 46.7447],
              [-124.3762, 47.6587],
              [-124.6212, 47.9042],
              [-124.6326, 48.3751],
              [-123.9756, 48.168],
              [-122.8611, 48.0901],
              [-122.6567, 47.8812],
              [-122.242, 48.0105],
              [-122.7887, 48.993],
              [-119.7019, 48.993],
              [-117.1433, 48.993],
              [-115.0116, 48.993],
              [-112.0267, 48.993],
              [-109.4685, 48.993],
              [-107.763, 48.993],
              [-106.0577, 48.993],
              [-103.4991, 48.993],
              [-101.3672, 48.993],
              [-98.3829, 48.993],
              [-95.1621, 48.9918],
              [-95.1554, 49.3695],
              [-94.8545, 49.3048],
              [-94.6209, 48.7424],
              [-93.7079, 48.5256],
              [-93.378, 48.6165]
            ]
          ],
          [
            [
              [-72.5097, 40.9861],
              [-73.1942, 40.6538],
              [-73.7665, 40.593],
              [-73.8792, 40.7916],
              [-73.5736, 40.9195],
              [-72.5097, 40.9861]
            ]
          ],
          [
            [
              [-159.3728, 21.9325],
              [-159.789, 22.0419],
              [-159.5794, 22.2235],
              [-159.3521, 22.2198],
              [-159.3728, 21.9325]
            ]
          ],
          [
            [
              [-157.7994, 21.4567],
              [-158.1105, 21.3185],
              [-158.273, 21.5852],
              [-157.9625, 21.7012],
              [-157.7994, 21.4567]
            ]
          ],
          [
            [
              [-156.487, 20.9323],
              [-156.2776, 20.9517],
              [-156.1071, 20.6451],
              [-156.4382, 20.6177],
              [-156.487, 20.9323]
            ]
          ],
          [
            [
              [-155.5814, 19.0123],
              [-155.8814, 19.0705],
              [-155.8907, 19.3821],
              [-156.0487, 19.7497],
              [-155.8204, 20.0138],
              [-155.8318, 20.2755],
              [-155.1987, 19.9944],
              [-154.8041, 19.5244],
              [-155.0535, 19.3196],
              [-155.3096, 19.2603],
              [-155.5814, 19.0123]
            ]
          ],
          [
            [
              [-141.002, 69.6508],
              [-141.002, 67.3132],
              [-141.002, 65.56],
              [-141.002, 64.0989],
              [-141.002, 62.3457],
              [-141.002, 60.3003],
              [-139.9733, 60.1829],
              [-139.6765, 60.3285],
              [-139.0795, 60.3435],
              [-139.1854, 60.0836],
              [-137.5933, 59.2263],
              [-137.4386, 58.9032],
              [-136.5788, 59.1523],
              [-136.3218, 59.6051],
              [-135.476, 59.7932],
              [-135.0511, 59.5785],
              [-134.9438, 59.2882],
              [-133.8207, 58.705],
              [-133.2754, 58.223],
              [-131.866, 56.7931],
              [-131.5751, 56.5987],
              [-130.4772, 56.2306],
              [-130.0252, 55.8881],
              [-130.1405, 55.585],
              [-130.0366, 55.2979],
              [-130.2141, 55.0261],
              [-130.5754, 54.7696],
              [-130.8497, 54.8077],
              [-131.0479, 55.1577],
              [-130.856, 55.3549],
              [-130.9186, 55.7359],
              [-131.2876, 56.0121],
              [-132.2235, 55.7213],
              [-131.8439, 56.1599],
              [-132.8143, 57.0406],
              [-133.4658, 57.172],
              [-133.5543, 57.6952],
              [-134.0633, 58.2111],
              [-134.7761, 58.4539],
              [-135.3024, 58.2557],
              [-135.8617, 58.5771],
              [-136.1297, 58.3507],
              [-136.5827, 58.2452],
              [-137.5441, 58.581],
              [-138.3525, 59.0873],
              [-139.799, 59.5465],
              [-140.2167, 59.7264],
              [-140.8433, 59.749],
              [-141.6702, 59.9697],
              [-142.9458, 60.0967],
              [-143.8051, 60.013],
              [-144.9012, 60.3353],
              [-145.8988, 60.4782],
              [-146.874, 61.0048],
              [-147.5673, 60.9951],
              [-148.3443, 60.8535],
              [-148.1976, 60.1679],
              [-148.4307, 59.9892],
              [-149.4324, 60.0011],
              [-150.198, 59.5666],
              [-150.5816, 59.5646],
              [-150.9607, 59.244],
              [-151.7384, 59.1885],
              [-151.8499, 59.4064],
              [-151.7346, 59.9882],
              [-151.4515, 60.2028],
              [-151.3217, 60.7426],
              [-150.4412, 61.0237],
              [-150.6124, 61.3013],
              [-151.7342, 60.9108],
              [-151.7504, 60.755],
              [-152.6539, 60.238],
              [-152.7594, 59.9211],
              [-153.1061, 59.8753],
              [-154.0882, 59.3632],
              [-154.1299, 59.12],
              [-153.3271, 58.8843],
              [-154.02, 58.4928],
              [-154.2468, 58.1595],
              [-155.007, 58.0161],
              [-155.7781, 57.5681],
              [-156.4786, 57.3279],
              [-156.4751, 57.105],
              [-157.5785, 56.6341],
              [-158.4145, 56.4355],
              [-158.2757, 56.1963],
              [-158.79, 55.987],
              [-160.0457, 55.7628],
              [-160.4628, 55.5574],
              [-161.0995, 55.4057],
              [-161.7415, 55.3911],
              [-162.3328, 55.0499],
              [-163.3378, 54.8764],
              [-162.1571, 55.7194],
              [-161.2156, 56.0213],
              [-160.8514, 55.7721],
              [-160.5996, 55.8745],
              [-160.3022, 56.3141],
              [-159.2832, 56.6884],
              [-158.3209, 57.2979],
              [-157.6077, 57.6016],
              [-157.5236, 58.4212],
              [-156.9747, 58.7365],
              [-157.142, 58.8777],
              [-158.0221, 58.6402],
              [-158.5032, 58.8504],
              [-159.0828, 58.4698],
              [-159.6703, 58.9113],
              [-160.3631, 59.0511],
              [-161.3615, 58.6699],
              [-161.7555, 58.6119],
              [-161.7886, 59.0163],
              [-162.0233, 59.2837],
              [-161.8287, 59.5888],
              [-162.2425, 60.1784],
              [-162.5709, 59.9896],
              [-163.2194, 59.8457],
              [-163.907, 59.8068],
              [-164.6623, 60.3038],
              [-165.3537, 60.5413],
              [-164.805, 60.8917],
              [-165.566, 61.1023],
              [-166.0787, 61.8033],
              [-165.6128, 61.8695],
              [-165.7074, 62.1002],
              [-165.1944, 62.4736],
              [-164.4089, 63.2153],
              [-164.1076, 63.2616],
              [-163.359, 63.0457],
              [-161.9742, 63.4533],
              [-161.0997, 63.5581],
              [-160.7786, 63.8191],
              [-160.9875, 64.2513],
              [-160.932, 64.5791],
              [-161.1869, 64.9243],
              [-161.7594, 64.8162],
              [-162.8071, 64.3743],
              [-163.7132, 64.5879],
              [-164.8995, 64.4609],
              [-166.1426, 64.5828],
              [-166.4152, 64.9266],
              [-166.8271, 65.0961],
              [-166.6656, 65.3383],
              [-167.404, 65.4222],
              [-168.0883, 65.658],
              [-167.0745, 65.8768],
              [-166.7476, 66.0516],
              [-164.6744, 66.5548],
              [-163.7757, 66.5309],
              [-164.0335, 66.2159],
              [-163.6954, 66.0839],
              [-161.8164, 66.0535],
              [-161.5571, 66.2503],
              [-160.2318, 66.4203],
              [-160.8641, 66.671],
              [-161.3979, 66.5517],
              [-161.8566, 66.7006],
              [-161.9653, 67.0498],
              [-163.0015, 67.0271],
              [-163.7205, 67.1953],
              [-164.1253, 67.6066],
              [-165.3859, 68.0454],
              [-165.9595, 68.1558],
              [-166.3803, 68.425],
              [-166.2092, 68.8854],
              [-164.3025, 68.9362],
              [-163.1615, 69.3878],
              [-162.9521, 69.7581],
              [-161.881, 70.3318],
              [-160.9963, 70.3045],
              [-159.3145, 70.8782],
              [-157.9985, 70.8453],
              [-156.7833, 71.3193],
              [-155.1669, 71.0995],
              [-154.1952, 70.8011],
              [-153.233, 70.9327],
              [-152.233, 70.8103],
              [-151.9446, 70.4519],
              [-151.2249, 70.4188],
              [-149.2693, 70.5009],
              [-147.7052, 70.2175],
              [-145.8234, 70.1601],
              [-145.1972, 70.0088],
              [-143.2184, 70.1165],
              [-141.4078, 69.6535],
              [-141.002, 69.6508]
            ]
          ],
          [
            [
              [-171.4633, 63.64],
              [-170.4304, 63.699],
              [-170.0175, 63.4915],
              [-168.8525, 63.1711],
              [-169.7778, 63.0934],
              [-171.4633, 63.64]
            ]
          ],
          [
            [
              [-152.417, 58.3604],
              [-152.1092, 58.1613],
              [-152.9825, 57.997],
              [-153.3815, 58.0871],
              [-152.6746, 58.4504],
              [-152.417, 58.3604]
            ]
          ],
          [
            [
              [-166.1354, 60.3834],
              [-165.7297, 60.3145],
              [-165.5916, 59.9131],
              [-166.1875, 59.7737],
              [-167.1389, 60.0085],
              [-166.476, 60.3828],
              [-166.1354, 60.3834]
            ]
          ]
        ]
      },
      properties: { name: "United States", id: "US", Continent: "North America" },
      id: "US"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.1745, 13.158],
            [-61.2773, 13.2096],
            [-61.1821, 13.3562],
            [-61.1239, 13.2935],
            [-61.1745, 13.158]
          ]
        ]
      },
      properties: { name: "Saint Vincent and the Grenadines", id: "VC", Continent: "Caribbean" },
      id: "VC"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-64.3953, 18.4645],
            [-64.4259, 18.5131],
            [-64.3245, 18.5174],
            [-64.3953, 18.4645]
          ]
        ]
      },
      properties: { name: "British Virgin Islands", id: "VG", Continent: "Caribbean" },
      id: "VG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-64.7656, 17.7944],
            [-64.6862, 17.7062],
            [-64.889, 17.7019],
            [-64.8849, 17.772],
            [-64.7656, 17.7944]
          ]
        ]
      },
      properties: { name: "US Virgin Islands", id: "VI", Continent: "Caribbean" },
      id: "VI"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.2056, 12.1447],
            [-68.2873, 12.1716],
            [-68.3712, 12.2576],
            [-68.3695, 12.3018],
            [-68.2196, 12.2312],
            [-68.2056, 12.1447]
          ]
        ]
      },
      properties: { name: "Bonair, Saint Eustachius and Saba", id: "BQ", Continent: "Caribbean" },
      id: "BQ"
    }
  ]
};
export default map;
