import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Table from './Table'
import VirtualTable from './VirtualTable'
import companyLogo from '../../../images/ilgaLogo-green.png'
import useWindowSize from '../../../hooks/useWindowSize'
import Fullscreen from '../../../components/icons/Fullscreen'
import MainSpinner from '../../components/ui/MainSpinner'
import BookIcon from '../icons/BookIcon'
import TreatyBodiesIcon from '../icons/TreatyBodiesIcon'
import UPRIcon from '../icons/UPRIcon'
import BubblesIcon from '../icons/BubblesIcon'
import { useTranslation } from 'react-i18next'
import InView from 'react-intersection-observer'
import VotingIcon from '../icons/VotingIcon'
import TreatiesIcon from '../icons/TreatiesIcon'

export default function TableContainer(props) {
  const [fullScreen, setFullScreen] = useState(false)
  const size = useWindowSize()
  const { t, i18n } = useTranslation()
  const [tableLoaded, setTableLoaded] = useState(false)
  const [showUNMembers, setShowUNMembers] = useState(false)
  function onChange(isVisible) {
    if (isVisible) setTableLoaded(true)
  }


  const IconComponent = ({ color }) => {
    switch (props.title.slice(-1)) {
      case '1':
        return <BookIcon color={color} />
      case '2':
        return <TreatiesIcon color={color} />
      case '3':
        return <TreatyBodiesIcon color={color} />
      case '4':
        return <BubblesIcon color={color} />
      case '5':
        return <UPRIcon color={color} />
      case '6':
        return <VotingIcon color={color} />
      default:
        return <BookIcon color={color} />
    }
  }

  return (
    <>
      {props.entries && props.entries.length ? (
        <>
          <InView
            rootMargin="1000px"
            onChange={(inview, entry) => onChange(inview)}
          >
            {({ inView, ref, entry }) => (
              <div className="areaContent__header" ref={ref}>
                {!props.hideIconHeader ? (
                  <>
                    <div className="ach__iconContainer">
                      <IconComponent color={'#D8E3E5'} />
                    </div>
                    <div className="ach__titlesContainer">
                      <h2
                        className="areaContent__title"
                        dangerouslySetInnerHTML={{ __html: props.title }}
                      />
                      <h4
                        className="areaContent__subTitle"
                        dangerouslySetInnerHTML={{ __html: props.subtitle }}
                      />
                    </div>
                  </>
                ) : (
                  <h2 className="areaContent__subTitle">{props.title}</h2>
                )}
              </div>
            )}
          </InView>

          <div className="quickSearch__container">
            <div className="quickSearch__full">
              {props.subtitle2 && <h5>{props.subtitle2}</h5>}
              {props.subtitle3 && <h6>{props.subtitle3}</h6>}
            </div>

            {size && size.width > 1500 && !props.noFullscreen && (
              <div className="ach__fullScreenButtonContainer">
                {!fullScreen ? (
                  <div
                    onClick={e => setFullScreen(true)}
                    className={'fullscreenButton ' + i18n.language}
                  >
                    <Fullscreen size={20} fill="#4e4e4e" /> {t('Fullscreen')}
                  </div>
                ) : (
                  <MainSpinner text={t('Loading table...')} />
                )}
              </div>
            )}
          </div>

          {fullScreen ? (
            <Modal
              show={true}
              className="table__modal  fullSize "
              onExit={() => setFullScreen(false)}
              onHide={() => setFullScreen(false)}
              scrollable={true}
              fullscreen={true}
            >
              <Modal.Header closeButton>
                <img
                  className="menuLogo"
                  src={companyLogo}
                  alt="Ilga database logo"
                />
                <span
                  className="areaContent__subTitle text-left"
                  dangerouslySetInnerHTML={{ __html: props.subtitle }}
                />
              </Modal.Header>
              <Modal.Body style={{ overflow: 'none' }}>
                <React.Suspense
                  fallback={<MainSpinner text={t('Loading table...')} />}
                >
                  <div style={{ display: 'hidden' }}>
                    <Table
                      {...props}
                      paginate={
                        props.paginate || props.paginate === false
                          ? props.paginate
                          : props.entries.length > 280
                      }
                      fullScreen={fullScreen}
                      setFullScreen={setFullScreen}
                      showUNMembers={showUNMembers}
                      setShowUNMembers={setShowUNMembers}
                      showUNSwitch={props.showUNSwitch}
                    />
                  </div>
                </React.Suspense>
              </Modal.Body>
            </Modal>
          ) : (
            <>
              {tableLoaded && (
                <Table
                  {...props}
                  paginate={
                    props.paginate || props.paginate === false
                      ? props.paginate
                      : props.entries.length > 280
                  }
                  fullScreen={fullScreen}
                  setFullScreen={setFullScreen}
                  showUNMembers={showUNMembers}
                  setShowUNMembers={setShowUNMembers}
                  showUNSwitch={props.showUNSwitch}
                />
              )}
            </>
          )}
        </>
      ) : (
        <div id="loading" style={{ position: 'relative', minHeight: '400px' }}>
          <MainSpinner text={t('Loading table...')} />
        </div>
      )}
    </>
  )
}
