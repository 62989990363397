import React, { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'

import { useTranslation } from 'react-i18next'
import { cGradientsAreasProtection } from '../../config/constants'
import useGqlEntriesProtection from '../../gql/useGqlEntriesProtection'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import MainLayout from '../../layouts/MainLayout'
import { calculateProtectionValue } from '../../lib/fnAreasPRHelper'
import { getColorPercentageStep } from '../../lib/fnHelper'

import '../../pages/Area.css'

import AreaContent from '../components/Areas/AreaContent'
import BarChart from '../components/Areas/A1-PR/BarChart'
import LineChart from '../components/Areas/A1-PR/LineChart'
import PieChart from '../components/Areas/A1-PR/PieChart'
import MapContainer from '../components/Map/MapContainer'
import microstates from '../components/Map/microstates.json'
import MapTooltip from '../components/StyledMapToolTips.js'
import TableContainer from '../components/Table/TableContainer'
import TalliesSection from '../components/TalliesSection/TalliesSection'
import MainSpinner from '../components/ui/MainSpinner'
import { citeA15, columns } from '../config/a15columns'
import useGqlTextComponents from '../../gql/useGqlTextComponents'

export default function Area116() {
  const htmlReferences = useRef()
  const [references, setReferences] = useState([])
  const [tableEntries, setTableEntries] = useState([])
  const [talliesNumbers, setTalliesNumbers] = useState([])
  const [mapLoaded, setMapLoaded] = useState(false)
  const [categories, setCategories] = useState([])
  const { textComponents } = useGqlTextComponents('A1-6')
  const { entriesProtection, loading, error } = useGqlEntriesProtection('A1-6')
  const {
    jurisdictions,
    loading: loadingJur,
    error: errorJur,
    jurisdictionsById
  } = useGqlJurisdictions()
  const { t, i18n } = useTranslation()

  const tooltip = e => {
    return ReactDOMServer.renderToStaticMarkup()
  }

  useEffect(() => {
    if (!loading && !error && jurisdictions && entriesProtection) {
      console.log('LOADING 16')
      setReferences(
        entriesProtection.map(e => {
          let val = calculateProtectionValue(e, entriesProtection, categories)
          return {
            id:
              e.motherEntry.subjurisdiction && e.motherEntry.subjurisdiction.id
                ? e.motherEntry.subjurisdiction.id
                : e.motherEntry.jurisdiction.a2_code,
            regions: e.motherEntry.jurisdiction.regions,
            microstate: microstates.find(
              ms => ms.A2 === e.motherEntry.jurisdiction.a2_code
            ),
            reference: getColorPercentageStep(
              categories.length
                ? [cGradientsAreasProtection[0]].concat(
                    cGradientsAreasProtection.slice(-categories.length)
                  )
                : cGradientsAreasProtection,
              val,
              'A1-PR',
              cGradientsAreasProtection
            ),
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <MapTooltip
                entry={e}
                title={t(
                  'Protection against discrimination in the provision of goods and services'
                )}
                area={'A1-5'}
                extraProps={{ categories }}
                hasSubjur={entriesProtection.find(
                  e2 =>
                    e2.motherEntry.jurisdiction.id ===
                      e.motherEntry.jurisdiction.id &&
                    e2.motherEntry.subjurisdiction
                )}
              />
            ), //tooltip(e),
            entry: e
          }
        })
      )

      let tmpTableEntries = []
      let tmpTallyNumbers = [0, 0, 0, 0]

      for (let entry of entriesProtection) {
        if (
          !entry.motherEntry.subjurisdiction &&
          entry.motherEntry.jurisdiction.un_member
        ) {
          if (entry.so_protection_type.id === '1') {
            tmpTallyNumbers[0]++
          }
          if (entry.gi_protection_type.id === '1') {
            tmpTallyNumbers[1]++
          }
          if (entry.ge_protection_type.id === '1') {
            tmpTallyNumbers[2]++
          }
          if (entry.sc_protection_type.id === '1') {
            tmpTallyNumbers[3]++
          }
        }
      }
      setTableEntries(entriesProtection)
      setTalliesNumbers(tmpTallyNumbers)
    }
  }, [jurisdictions, entriesProtection, error, loading, categories])

  const areaInfo = {
    title: `${t('Area')} 1`,
    subTitle: t('Legal Frameworks'),
    subSubTitle: `${t(
      'Protection against discrimination in the provision of goods and services'
    )}`,
    areaName: 'area-1'
  }

  return (
    <MainLayout section="areas">
      <div className="area__page area1__page has__category-selector A1-5">
        <section className="selectRegion__section">
          <MapContainer
            references={references}
            htmlReferences={htmlReferences}
            jurisdictionsById={jurisdictionsById}
            jurisdictions={jurisdictions}
            mapLegendProtectionGradient={{
              colors: categories.length
                ? [cGradientsAreasProtection[0]].concat(
                    cGradientsAreasProtection.slice(-categories.length)
                  )
                : cGradientsAreasProtection,
              labels: categories.length
                ? Array.from(Array(categories.length + 1).keys())
                : [0, 1, 2, 3, 4],
              title: t('Number of protected categories')
            }}
            mapLoaded={mapLoaded}
            setMapLoaded={setMapLoaded}
            menuVariation={'categories'}
            extraProps={{ setCategories, categories }}
            currentArea={'A1-5'}
            title={areaInfo.title}
            subTitle={areaInfo.subTitle}
            subSubTitle={areaInfo.subSubTitle}
            areaName={areaInfo.areaName}
            modalTitle={areaInfo.subSubTitle}
            modalContent={textComponents.length && textComponents[0].map}
          />
        </section>
        <div className="content__container">
          <TalliesSection
            area={'A1-5'}
            title={
              'Protection against discrimination in the provision of goods and services'
            }
            canStart={mapLoaded}
            replaceNumbers={talliesNumbers}
          />

          <AreaContent areaIntro={'A1-6'} areaLatest={'A1-6'} />

          <div className="mainCTA__2charts">


            <div className="mainCTA__section40 mainGraphic__section">
              <h5>
                {t(
                  'Protection against discrimination in the provision of goods and services'
                )}
              </h5>
              <h4>{t('Regional breakdown (%)')}</h4>
              {references && references.length > 0 && jurisdictionsById ? (
                <PieChart
                  references={references.filter(
                    r => !r.entry.motherEntry.subjurisdiction
                  )}
                  jurisdictions={jurisdictionsById}
                />
              ) : (
                <>
                  <MainSpinner />
                  {t('Loading chart...')}
                </>
              )}
            </div>
            <div className="mainCTA__section60 mainGraphic__section">
              <h5>
                {t(
                  'Protection against discrimination in the provision of goods and services'
                )}
              </h5>
              <h4>{t('Timeline')}</h4> <br />
              <br />
              <div style={{ position: 'relative', flexGrow: 1 }}>
                {references && references.length > 0 ? (
                  <LineChart
                    references={references}
                    series={['SO', 'GI', 'GE', 'SC']}
                  />
                ) : (
                  <MainSpinner text={t('Loading chart...')} />
                )}
              </div>
            </div>
          </div>
          <div className="mainCTA__charts">
            <section className="mainCTA__section">
              <div className="mainGraphic__section">
                <h5>
                  {t(
                    'Protection against discrimination in the provision of goods and services'
                  )}
                </h5>
                <h4>{t('Regional breakdown (jurisdictions)')}</h4>
                {references && references.length > 0 ? (
                  <BarChart references={references} />
                ) : (
                  <>
                    <MainSpinner />
                    {t('Loading chart...')}
                  </>
                )}
              </div>
            </section>
          </div>
        </div>
        <section className="table__section">
          <div className="content__container">
            {entriesProtection && entriesProtection.length > 0 && (
              <TableContainer
                entries={tableEntries}
                setEntries={setTableEntries}
                t={t}
                paginate={false}
                columns={columns()}
                citeEntry={(entry, setToast) => citeA15(entry, setToast, 6)}
                emptyFieldsNoCite={[
                  'so_explan',
                  'gi_explan',
                  'ge_explan',
                  'sc_explan'
                ]}
                trMainClass={'a13Tr'}
                customColumnHide={{
                  columns: [
                    'so_explan',
                    'gi_explan',
                    'ge_explan',
                    'sc_explan',
                    'so_legal_framework_decrim_mechanism.name',
                    'gi_legal_framework_decrim_mechanism.name',
                    'ge_legal_framework_decrim_mechanism.name',
                    'sc_legal_framework_decrim_mechanism.name'
                  ],
                  text: t('Display explanations')
                }}
                title={t('Area') + '1'}
                subtitle={
                  t('Legal Frameworks') +
                  ' | ' +
                  '<span style="font-weight:700">' +
                  t(
                    'Protection against discrimination in the provision of goods and services'
                  ) +
                  '</span>'
                }
                showSubjurSwitch={true}
                initialSortBy={[
                  {
                    id: 'motherEntry.jurisdiction.name',
                    desc: false
                  }
                ]}
                showUNSwitch
              />
            )}
          </div>
        </section>
      </div>
    </MainLayout>
  )
}
