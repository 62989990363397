import React, { useState } from "react";
import "./Dropdown.css";
import { DebounceInput } from "react-debounce-input";
import CircleSpinner from "../ui/CircleSpinner";
import InputNumber from "rc-input-number";

const DropdownMenuDate = React.forwardRef(
  (
    {
      coords,
      listItems,
      isItemInSelection,
      setFilter,
      tableFilter,
      handleOnClick,
      t,
      filterLoading,
      sortSelected,
      search,
      closeDropdown,
      clearFilter,
      width = "100%"
    },
    inputRef
  ) => {
    const [from, setFrom] = useState(tableFilter && tableFilter.from ? tableFilter.from : null);

    const [to, setTo] = useState(tableFilter && tableFilter.to ? tableFilter.to : null);

    const clearAll = (e) => {
      setFrom(null);
      setTo(null);

      clearFilter(e);
    };

    const styles = {
      menu: (provided, state) => ({
        ...provided,

        color: state.selectProps.menuColor
      }),

      option: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition, borderBottom: "1px solid #eee", textAlign: "center" };
      }
    };
    return (
      <div className="ignore-this-class dd-wrapper " style={{ width: "250px" }}>
        {clearFilter && !filterLoading && (
          <button className="dd-clear-filter" onClick={clearAll}>
            { t("Clear Filter") }
          </button>
        )}
        {filterLoading && (
          <button className="dd-clear-filter">
            <CircleSpinner />{" "}
          </button>
        )}
        <div className="filterDates__container">
          <label>{t("From")}</label>
          <InputNumber
            min={1950}
            max={new Date().getFullYear()}
            prefixCls="filterDates__input"
            placeholder="YYYY"
            step={1}
            defaultValue={from}
            value={from}
            onChange={(val) => {
              setFrom(val);
              let theFilter = { from: val, to };
              if (val && to < val) {
                setTo(null);
                theFilter = { ...theFilter, to: null };
              }
              setFilter(theFilter);
            }}
          />
          <label>{t("To_hasta")}</label>
          <InputNumber
            min={1950}
            max={new Date().getFullYear()}
            prefixCls="filterDates__input"
            placeholder="YYYY"
            defaultValue={to}
            value={to}
            step={1}
            onChange={(val) => {
              setTo(val);
              let theFilter = { to: val, from };
              if (val && from > val) {
                setFrom(null);
                theFilter = { ...theFilter, from: null };
              }
              setFilter(theFilter);
            }}
          />
        </div>
      </div>
    );
  }
);

export default DropdownMenuDate;
