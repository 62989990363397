import React from 'react';

const BubblesIcon = ({  color = '#c9d8d9'}) => <svg id="SpecialPro" xmlns="http://www.w3.org/2000/svg" width="89.425" height="46.843" viewBox="0 0 89.425 46.843">
  <path id="Path_1823" data-name="Path 1823" d="M35.746,6.555v24.5l-2.241-2.966H6.555V6.555H35.746M36.471,2H5.83A3.841,3.841,0,0,0,2,5.83V28.81a3.841,3.841,0,0,0,3.83,3.83h26.81L40.3,40.3V5.83A3.841,3.841,0,0,0,36.471,2Z" transform="translate(-2 -2)" fill={color}/>
  <path id="Path_1855" data-name="Path 1855" d="M44.159,2H6.684A4.678,4.678,0,0,0,2.023,6.684L2,48.843l9.369-9.369h32.79a4.7,4.7,0,0,0,4.684-4.684V6.684A4.7,4.7,0,0,0,44.159,2Zm0,32.79H9.425L8.043,36.172,6.684,37.53V6.684H44.159ZM23.079,25.421h4.684v4.684H23.079Zm0-14.053h4.684V23.637H23.079Z" transform="translate(40.582 -2)" fill={color}/>
  <rect id="Rectangle_2265" data-name="Rectangle 2265" width="17" height="2" transform="translate(9.871 9)" fill={color}/>
  <rect id="Rectangle_2266" data-name="Rectangle 2266" width="17" height="3" transform="translate(9.871 14)" fill={color}/>
  <rect id="Rectangle_2267" data-name="Rectangle 2267" width="17" height="3" transform="translate(9.871 19)" fill={color}/>
</svg>

export default BubblesIcon;


