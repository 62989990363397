import { lazy } from 'react'
import Area11Mock from '../mocks/areas/Area11'
import Area2Mock from '../mocks/areas/Area2'
import Area3Mock from '../mocks/areas/Area3'
import Area4Mock from '../mocks/areas/Area4'
import Area5Mock from '../mocks/areas/Area5'
import Area6Mock from '../mocks/areas/Area6'
import Area6NewMock from '../mocks/areas/Area6New'
import Area621 from '../mocks/areas/Area621'
import Area622 from '../mocks/areas/Area622'
import Area6SpecificNewMock from '../mocks/areas/Area6SpecificNew'

import JurProfile from '../mocks/JurProfile'
import JurProfileHome from '../mocks/JurProfileHome/JurProfileHome'
import Login from '../pages/Login'

import i18n from '../config/i18n'
import Area13 from '../mocks/areas/Area13'
import Area15 from '../mocks/areas/Area15'
import Area110 from '../mocks/areas/Area110'
import Area from '../mocks/areas/Area'
import Area3X from '../mocks/areas/Area3X'
import Tools from '../mocks/pages/Tools'
import AdvancedSearch from '../mocks/pages/AdvancedSearch/AdvancedSearch'
import CompareToolScreen from '../mocks/pages/CompareTool/CompareTool'
import MapGenerator from '../mocks/pages/MapGenerator/MapGenerator'
import Area16 from '../mocks/areas/Area16'
import Area18 from '../mocks/areas/Area18'
import Area17 from '../mocks/areas/Area17'
import Area14 from '../mocks/areas/Area14'
import Area114 from '../mocks/areas/Area114'
import Area115 from '../mocks/areas/Area115'
import Area117 from '../mocks/areas/Area117'
import Area116 from '../mocks/areas/Area116'
import Area111 from '../mocks/areas/Area111'
import Area112 from '../mocks/areas/Area112'
import Area113 from '../mocks/areas/Area113'
import Area118 from '../mocks/areas/Area118'
import DocuFetch from '../pages/DocuFetch'
import StatsGenerator from '../mocks/pages/StatsGenerator/StatsGenerator'
import StatsGeneratorHome from '../mocks/pages/StatsGenerator/StatsGeneratorHome'
import StatsGeneratorSelectedMock from '../mocks/pages/StatsGenerator/StatsGeneratorSelectedMock'
import SubjurProfile from '../mocks/SubjurProfile'
import seo from './seo.json'

const Home = lazy(() => import('../mocks/pages/Home'))
const About = lazy(() => import('../pages/About'))
const SampleTablePage = lazy(() => import('../mocks/SampleTablePage'))
const AccountProfile = lazy(() => import('../mocks/pages/AccountProfile'))
const LatestUpdates = lazy(() => import('../mocks/pages/LatestUpdates'))
const UpcomingDeadlines = lazy(() => import('../mocks/pages/UpcomingDeadlines'))
const Credits = lazy(() => import('../mocks/pages/Credits'))

/*const Area2 = lazy(() => import("../pages/Area2")); */
import MapEditor from '../mocks/areas/MapEditor'

export const paths = (params = {}) => {
  let area1 = [
    {
      en: seo.a11.url_en,
      es: seo.a11.url_es
    },
    {
      en: seo.a12.url_en,
      es: seo.a12.url_es
    },
    {
      en: seo.a13.url_en,
      es: seo.a13.url_es
    },
    {
      en: 'legal-barriers-to-freedom-of-association',
      es: 'restricciones-a-la-libertad-de-asociacion'
    },
    {
      en: 'constitutional-protection-against-discrimination',
      es: 'proteccion-constitucional-contra-la-discriminacion'
    },
    {
      en:
        'protection-against-discrimination-in-the-provision-of-goods-and-services',
      es:
        'proteccion-contra-la-discriminacion-en-la-provision-de-bienes-y-servicios'
    },
    {
      en: 'protection-against-discrimination-in-health',
      es: 'proteccion-contra-la-discriminacion-en-salud'
    },
    {
      en: 'protection-against-discrimination-in-education',
      es: 'proteccion-contra-la-discriminacion-en-la-educacion'
    },
    {
      en: 'protection-against-school-bullying',
      es: 'proteccion-contra-el-acoso-u-hostigamiento-escolar-bullying'
    },
    {
      en: 'protection-against-discrimination-in-employment',
      es: 'proteccion-contra-la-discriminacion-en-el-empleo'
    },
    {
      en: 'protection-against-discrimination-in-housing',
      es: 'proteccion-contra-la-discriminacion-en-la-vivienda'
    },
    { en: 'hate-crime-law', es: 'leyes-sobre-delitos-de-odio' },
    {
      en: 'prohibition-of-incitement-to-violence-hatred-or-discrimination',
      es: 'incitacion-a-la-violencia-odio-o-discriminacion'
    },
    {
      en: 'restrictions-on-so-called-conversion-therapies',
      es: 'prohibicion-de-las-mal-llamadas-terapias-de-conversion'
    },
    {
      en: 'legal-recognition-of-same-sex-unions',
      es: 'reconocimiento-legal-de-parejas-del-mismo-sexo'
    },
    {
      en: 'adoption-by-same-sex-couples',
      es: 'adopcion-por-parejas-del-mismo-sexo'
    },
    {
      en: 'restrictions-on-interventions-on-intersex-minors',
      es: 'prohibicion-de-cirugias-no-consentidas-en-personas-intersex'
    },
    { en: 'legal-gender-recognition', es: 'reconocimiento-legal-de-genero' }
  ]
  let area3 = [
    { en: 'all-treaty-bodies', es: 'todos-los-organos-det-tratados' },
    {
      en: 'human-rights-committee-hrctee',
      es: 'comite-de-derechos-humanos-hrctee'
    },
    {
      en: 'committee-on-economic-social-and-cultural-rights-cescr',
      es: 'comite-de-derechos-economicos-sociales-y-culturales-cescr'
    },
    {
      en: 'committee-on-the-rights-of-the-child-crc',
      es: 'comite-de-los-derechos-de-le-nine-crc'
    },
    {
      en: 'committee-for-the-elimination-of-discrim-against-women-cedaw',
      es:
        'comite-para-la-eliminacion-de-la-discriminacion-contra-las-mujeres-cedaw'
    },
    {
      en: 'committee-on-the-rights-of-persons-with-disabilities-crpd',
      es: 'comite-de-los-derechos-de-las-personascon-discapacidad-crpd'
    },
    {
      en: 'committee-on-the-elimination-of-racial-discrimination-cerd',
      es: 'comite-para-la-eliminacion-de-la-discriminacion-racial-cerd'
    },
    {
      en: 'committee-on-enforced-dissapareances-ced',
      es: 'comite-contra-las-desapariciones-forzadas-ced'
    },
    { en: 'committee-against-torture-cat', es: 'comite-contra-la-tortura-cat' },
    {
      en: 'committee-on-migrant-workers-cmw',
      es:
        'comite-para-la-proteccion-de-los-derechosde-todes-les-trabajadores-migratories-y-sus-familiares-cmw'
    }
  ]

  return Object.keys(seo)
    .map(id => ({
      id,
      en: seo[id].url_en,
      es: seo[id].url_es
    }))
    .concat(
      {
        id: 'a62-1',
        en: 'un-voting-record-statements-specific-:slug',
        es: 'votacion-ante-naciones-unidas-y-declaraciones-2-1'
      },
      {
        id: 'a62-2',
        en: 'un-voting-record-statements-2-2',
        es: 'votacion-ante-naciones-unidas-y-declaraciones-2-2'
      },
      {
        id: 'a6n',
        en: 'un-voting-record-statements-tmp',
        es: 'votacion-ante-naciones-unidas-y-declaraciones-tmp'
      },
      {
        id: 'a6nres',
        en: 'new-specific-resolution-un-voting-record-statements',
        es:
          'new-specific-resolution-votacion-ante-naciones-unidas-y-declaraciones'
      },
      {
        id: 'map_editor',
        en: 'map-editor',
        es: 'map-editor'
      }
    )
}

export const getPathLang = path => {
  let theRoutes = routes()
  for (let r of theRoutes) {
    if (r.langPaths) {
      if (
        r.langPaths['es'] === path ||
        (r.langPaths['es'].indexOf(':slug') !== -1 &&
          path.indexOf(r.langPaths['es'].replace(':slug', '')) !== -1)
      )
        return 'es'
    }
  }
  return 'en' //default
}

export const getPath = (id, params, lang = null) => {
  return '/' + paths(params).find(p => p.id === id)[lang ? lang : i18n.language]
}

const getLangPaths = id => {
  return paths().find(p => p.id === id)
}

const routes = () => {
  return [
    {
      name: 'Home',
      path: i18n.t('home'),
      langPaths: getLangPaths('home'),
      id: 'home',
      exact: true,
      main: () => <Home />
    },
    {
      name: 'DocuFetch',
      path: '/docu-fetch',
      exact: true,
      ignoreSitemap: true,
      main: () => <DocuFetch />
    },
    {
      name: i18n.t('About us'),
      path: i18n.t('/about'),
      exact: true,
      langPaths: getLangPaths('about'),
      id: 'about',
      admin: false,
      main: () => <About />
    },
    {
      name: 'Sample Table',
      path: '/sample-table',
      exact: true,
      ignoreSitemap: true,
      admin: false,
      main: () => <SampleTablePage />
    },
    {
      name: i18n.t('Areas'),
      path: i18n.t('/areas'),
      langPaths: getLangPaths('areas'),
      id: 'areas',
      exact: true,
      admin: false,
      main: () => <Area />
    },
    {
      name: 'Tools',
      path: i18n.t('/tools'),
      langPaths: getLangPaths('tools'),
      exact: true,
      id: 'tools',
      admin: false,
      main: () => <Tools />
    },

    {
      name: i18n.t('Area 1') + ' | ' + i18n.t('Legal Frameworks'),
      areaNumber: 1,
      areaName: i18n.t('Legal Frameworks'),

      path: getPath('a11'),
      langPaths: getLangPaths('a11'),
      id: 'a1',
      parentId: 'area1',
      exact: true,
      admin: false,
      main: () => <Area11Mock />,
      isArea: true,
      isTopLevel: true,
      hasChildren: true
    },
    {
      name: i18n.t('Criminalisation of consensual same-sex sexual acts'),
      subareaNumber: 1,
      path: getPath('a11'),
      langPaths: getLangPaths('a11'),
      id: 'a11',
      exact: true,
      admin: false,
      main: () => <Area11Mock />,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },

    {
      name: i18n.t('Legal barriers to freedom of expression'),
      path: getPath('a13'),
      langPaths: getLangPaths('a13'),
      id: 'a13',
      subareaNumber: 3,
      exact: true,
      admin: false,
      main: () => <Area13 />,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },
    {
      name: i18n.t('Legal barriers to freedom of association'),
      path: getPath('a14'),
      langPaths: getLangPaths('a14'),
      id: 'a14',
      exact: true,
      admin: false,
      main: () => <Area14 />,
      subareaNumber: 4,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },

    {
      name: i18n.t('Constitutional protection against discrimination'),
      path: getPath('a15'),
      langPaths: getLangPaths('a15'),
      id: 'a15',
      short_name: i18n.t('Constitutional Protection'),
      exact: true,
      admin: false,
      main: () => <Area15 />,
      subareaNumber: 5,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },
    {
      name: i18n.t(
        'Protection against discrimination in the provision of goods and services'
      ),
      path: getPath('a16'),
      langPaths: getLangPaths('a16'),
      id: 'a16',
      short_name: i18n.t('Goods and Services'),
      exact: true,
      admin: false,
      main: () => <Area16 />,
      subareaNumber: 6,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },
    {
      name: i18n.t('Protection against discrimination in health'),
      path: getPath('a17'),
      langPaths: getLangPaths('a17'),
      id: 'a17',
      short_name: i18n.t('Health'),
      exact: true,
      admin: false,
      main: () => <Area17 />,
      subareaNumber: 7,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },
    {
      name: i18n.t('Protection against discrimination in education'),
      path: getPath('a18'),
      langPaths: getLangPaths('a18'),
      id: 'a18',
      short_name: i18n.t('Education'),
      exact: true,
      admin: false,
      main: () => <Area18 />,
      subareaNumber: 8,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },
    {
      name: i18n.t('Protection against school bullying'),
      path: getPath('a19'),
      langPaths: getLangPaths('a19'),
      id: 'a19',
      short_name: i18n.t('Bullying'),
      disabled: true,
      exact: true,
      admin: false,
      main: () => <Area11Mock />,
      subareaNumber: 9,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },
    {
      name: i18n.t('Protection against discrimination in employment'),
      path: getPath('a110'),
      langPaths: getLangPaths('a110'),
      id: 'a110',
      short_name: i18n.t('Employment'),
      exact: true,
      admin: false,
      main: () => <Area110 />,
      subareaNumber: 10,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },
    {
      name: i18n.t('Protection against discrimination in housing'),
      path: getPath('a111'),
      langPaths: getLangPaths('a111'),
      id: 'a111',
      short_name: i18n.t('Housing'),
      exact: true,
      admin: false,
      main: () => <Area111 />,
      subareaNumber: 11,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },
    {
      name: i18n.t('Hate crime law'),
      path: getPath('a112'),
      langPaths: getLangPaths('a112'),
      id: 'a112',
      short_name: i18n.t('Hate crime law'),
      exact: true,
      admin: false,
      main: () => <Area112 />,
      subareaNumber: 12,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },
    {
      name: i18n.t(
        'Prohibition of incitement to violence, hatred or discrimination'
      ),
      path: getPath('a113'),
      langPaths: getLangPaths('a113'),
      id: 'a113',
      exact: true,
      short_name: i18n.t('Prohibition of Incitement'),
      admin: false,
      main: () => <Area113 />,
      subareaNumber: 13,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },
    {
      name: i18n.t('Regulation of so-called “conversion therapies”'),
      path: getPath('a114'),
      langPaths: getLangPaths('a114'),
      id: 'a114',
      exact: true,
      admin: false,
      main: () => <Area114 />,
      subareaNumber: 14,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },
    {
      name: i18n.t('Same-Sex Marriage and Civil Unions'),
      path: getPath('a115'),
      langPaths: getLangPaths('a115'),
      id: 'a115',
      exact: true,
      admin: false,
      main: () => <Area115 />,
      subareaNumber: 15,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },
    {
      name: i18n.t('Adoption by same-sex couples'),
      path: getPath('a116'),
      langPaths: getLangPaths('a116'),
      id: 'a116',
      exact: true,
      admin: false,
      main: () => <Area116 />,
      subareaNumber: 16,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },
    {
      name: i18n.t('Restrictions on interventions on intersex minors'),
      path: getPath('a117'),
      langPaths: getLangPaths('a117'),
      id: 'a117',
      exact: true,
      admin: false,
      main: () => <Area117 />,
      subareaNumber: 17,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },
    {
      name: i18n.t('Legal gender recognition'),
      path: getPath('a118'),
      langPaths: getLangPaths('a118'),
      id: 'a118',
      exact: true,
      admin: false,
      main: () => <Area118 />,
      subareaNumber: 18,
      isArea: true,
      isChild: true,
      parent: 'area1'
    },

    {
      name:
        i18n.t('Area') +
        ' 2 | ' +
        i18n.t('International Human Rights Treaties'),
      areaNumber: 2,
      areaName: i18n.t('International Human Rights Treaties'),
      menuName: i18n.t('International Human Rights Treaties_MENU'),
      path: getPath('a2'),
      langPaths: getLangPaths('a2'),
      id: 'a2',
      exact: true,
      admin: false,
      main: () => <Area2Mock />,
      isArea: true,
      isTopLevel: true
    },
    {
      name: i18n.t('Area') + ' 3 | ' + i18n.t('UN Treaty Bodies'),
      areaNumber: 3,
      areaName: i18n.t('UN Treaty Bodies'),
      path: getPath('a31'),
      langPaths: getLangPaths('a31'),
      id: 'a3',
      parentId: 'area3',
      exact: true,
      admin: false,
      main: () => <Area3Mock />,
      isArea: true,
      isTopLevel: true,
      hasChildren: true
    },
    {
      name: i18n.t('All treaty bodies'),
      path: getPath('a31'),
      langPaths: getLangPaths('a31'),
      id: 'a31',
      exact: true,
      admin: false,
      main: () => <Area3Mock />,
      subareaNumber: 1,
      isArea: true,
      isChild: true,
      parent: 'area3'
    },
    {
      name: i18n.t('Human Rights Committee (HRCtee)'),
      path: getPath('a32'),
      langPaths: getLangPaths('a32'),
      id: 'a32',
      exact: true,
      admin: false,
      main: () => <Area3X subarea={1} />,
      subareaNumber: 2,
      isArea: true,
      isChild: true,
      parent: 'area3'
    },
    {
      name: i18n.t('Committee on Economic, Social and Cultural Rights (CESCR)'),
      path: getPath('a33'),
      langPaths: getLangPaths('a33'),
      id: 'a33',
      exact: true,
      admin: false,
      main: () => <Area3X subarea={2} />,
      subareaNumber: 3,
      isArea: true,
      isChild: true,
      parent: 'area3'
    },
    {
      name: i18n.t('Committee on the Rights of the Child (CRC)'),
      path: getPath('a34'),
      langPaths: getLangPaths('a34'),
      id: 'a34',
      exact: true,
      admin: false,
      main: () => <Area3X subarea={3} />,
      subareaNumber: 4,
      isArea: true,
      isChild: true,
      parent: 'area3'
    },
    {
      name: i18n.t(
        'Committee on the Elimination of Discrim. against Women (CEDAW)'
      ),
      path: getPath('a35'),
      langPaths: getLangPaths('a35'),
      id: 'a35',
      exact: true,
      admin: false,
      main: () => <Area3X subarea={4} />,
      subareaNumber: 5,
      isArea: true,
      isChild: true,
      parent: 'area3'
    },
    {
      name: i18n.t(
        'Committee on the Rights of Persons with Disabilities (CRPD)'
      ),
      path: getPath('a36'),
      langPaths: getLangPaths('a36'),
      id: 'a36',
      exact: true,
      admin: false,
      main: () => <Area3X subarea={5} />,
      subareaNumber: 6,
      isArea: true,
      isChild: true,
      parent: 'area3'
    },
    {
      name: i18n.t(
        'Committee on the Elimination of Racial Discrimination (CERD)'
      ),
      path: getPath('a37'),
      langPaths: getLangPaths('a37'),
      id: 'a37',
      exact: true,
      admin: false,
      main: () => <Area3X subarea={6} />,
      subareaNumber: 7,
      isArea: true,
      isChild: true,
      parent: 'area3'
    },
    {
      name: i18n.t('Committee on Enforced Disappearances (CED)'),
      path: getPath('a38'),
      langPaths: getLangPaths('a38'),
      id: 'a38',
      exact: true,
      admin: false,
      main: () => <Area3X subarea={7} />,
      subareaNumber: 8,
      isArea: true,
      isChild: true,
      parent: 'area3'
    },
    {
      name: i18n.t('Committee against Torture (CAT)'),
      path: getPath('a39'),
      langPaths: getLangPaths('a39'),
      id: 'a39',
      exact: true,
      admin: false,
      main: () => <Area3X subarea={8} />,
      subareaNumber: 9,
      isArea: true,
      isChild: true,
      parent: 'area3'
    },
    {
      name: i18n.t('Committee on Migrant Workers (CMW)'),
      path: getPath('a310'),
      langPaths: getLangPaths('a310'),
      id: 'a310',
      exact: true,
      admin: false,
      main: () => <Area3X subarea={9} />,
      subareaNumber: 10,
      isArea: true,
      isChild: true,
      parent: 'area3'
    },
    {
      name: i18n.t('Area') + ' 4 | ' + i18n.t('UN Special Procedures'),
      areaNumber: 4,
      areaName: i18n.t('UN Special Procedures'),
      path: getPath('a4'),
      langPaths: getLangPaths('a4'),
      exact: true,
      admin: false,
      main: () => <Area4Mock />,
      id: 'a4',
      isArea: true,
      isTopLevel: true
    },
    {
      name:
        i18n.t('Area') + ' 5 | ' + i18n.t('Universal Periodic Review (UPR)'),
      areaNumber: 5,
      areaName: i18n.t('Universal Periodic Review (UPR)'),
      path: getPath('a5'),
      langPaths: getLangPaths('a5'),
      exact: true,
      admin: false,
      id: 'a5',
      main: () => <Area5Mock />,
      isArea: true,
      isTopLevel: true
    },
   
   
   
    {
      name: i18n.t('Jurisdiction Profile'),
      path: getPath('jur_profile'),
      langPaths: getLangPaths('jur_profile'),
      id: 'jur_profile',
      ignoreSitemap: true,
      exact: false,
      main: () => <JurProfile />
    },
    {
      name: i18n.t('Subjurisdiction Profile'),
      path: getPath('subjur_profile'),
      langPaths: getLangPaths('subjur_profile'),
      id: 'subjur_profile',
      ignoreSitemap: true,
      exact: false,
      main: () => <SubjurProfile />
    },
    {
      name: i18n.t('Jur Profile Home', { ns: 'jur' }),
      path: getPath('jur_home'),
      langPaths: getLangPaths('jur_home'),
      id: 'jur_home',
      exact: true,
      admin: false,
      main: () => <JurProfileHome />
    },
    {
      name: i18n.t('Login page'),
      path: '/login',
      exact: true,
      admin: false,
      main: () => <Login />
    },
    /*{
    name: "",
    path: "/international-human-rights-treaties",
    exact: true,
    admin: false,
    main: () => <Area2 />
  },*/
    {
      name: '',
      path: getPath('account'),
      langPaths: getLangPaths('account'),
      id: 'account',
      exact: true,
      admin: true,
      main: () => <AccountProfile />
    },
    {
      name: '',
      path: getPath('latest_updates'),
      langPaths: getLangPaths('latest_updates'),
      exact: true,
      admin: false,
      id: 'latest_updates',
      main: () => <LatestUpdates />
    },
    {
      name: '',
      id: 'upcoming',
      path: getPath('upcoming'),
      langPaths: getLangPaths('upcoming'),
      exact: true,
      admin: false,
      main: () => <UpcomingDeadlines />
    },
    {
      name: '',
      path: getPath('credits'),
      langPaths: getLangPaths('credits'),
      id: 'credits',
      exact: true,
      admin: false,
      main: () => <Credits />
    },
    {
      name: i18n.t('Advanced Search'),
      path: getPath('advanced_search'),
      langPaths: getLangPaths('advanced_search'),
      exact: true,
      id: 'advanced_search',
      admin: false,
      main: () => <AdvancedSearch />
    },

    {
      id: 'compare_tool',
      name: i18n.t('Compare Tool'),
      path: getPath('compare_tool'),
      langPaths: getLangPaths('compare_tool'),
      exact: true,
      admin: false,
      main: () => <CompareToolScreen />
    },
  
    {
      id: 'map_editor',
      name: i18n.t('Map Editor'),
      path: getPath('map_editor'),
      ignoreSitemap: true,
      langPaths: getLangPaths('map_editor'),
      exact: true,
      admin: false,
      main: () => <MapEditor />
    }
  ]
}

export default routes
