import { gql, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const GET_JURISDICTIONS = gql(`
query GetJurs($lang: String){
  jurisdictions(lang: $lang){
    id
    name
    a2_code
    map_name
    short_name
    slug
    slug_en
    slug_es
    label_longitude
    label_latitude
    un_member
    label_font_size
    microstate_size
    microstate_label_longitude
    microstate_label_latitude
    drilldown_label_longitude
    drilldown_label_latitude
    drilldown_label_font_size
    has_subjur_profile
    regions{
      id
    }
  }
}
`)

const GET_REGIONS = gql(`
query GetRegions($lang: String){
  regions(lang: $lang){
    id
    name
    region_type{
      id
      name
    }
  }
}
`)

const GET_HRC_MEMBERSHIPS = gql(`
query GetHrcMemberships($lang: String){
  hrcMemberships(lang: $lang){
    id
    jurisdiction {
      id
      name

    }
    ini
    end
  }
}
`)

const GET_TOPICS = gql(`
query GetTopics($lang: String){
  topics(lang: $lang){
    id
    name
    noncase_name
  }
}
`)

const GET_LANGUAGES = gql(`
query GetLanguages($lang: String){
  languages(lang: $lang){
    id
    name
    name_en
  }
}
`)

const GET_SUBJURS = gql(`
query JurSubjurs($lang: String, $jur_id: String, $slug: String) {
  jurSubjurs(lang: $lang, jur_id: $jur_id, slug: $slug) {
    name
    current_population
    id
    slug
    jurisdiction {
      id
    }
  }
}
`)

const GET_SUBJUR_FROM_SLUG = gql(`
query SubjurFromSlug($lang: String, $slug: String) {
  subjurFromSlug(lang: $lang, slug: $slug) {
    id
    name
    slug
    slug_es
    slug_en
    jurisdiction{
      id
    }
  }
}
`)

function useGqlJurisdictions(jur_id = null, slug = null) {
  const { i18n } = useTranslation()
  const jurisdictions = useQuery(GET_JURISDICTIONS, {
    variables: { lang: i18n.language }
  })
  const regions = useQuery(GET_REGIONS, { variables: { lang: i18n.language } })
  const hrcMemberships = useQuery(GET_HRC_MEMBERSHIPS, {
    variables: { lang: i18n.language }
  })
  const topics = useQuery(GET_TOPICS, { variables: { lang: i18n.language } })
  const languages = useQuery(GET_LANGUAGES, { variables: { lang: i18n.language } })
  const jurSubjurs = useQuery(GET_SUBJURS, {
    variables: { jur_id, slug, lang: i18n.language }
  })

  const subjurFromSlug = useQuery(GET_SUBJUR_FROM_SLUG, {
    variables: { slug, lang: i18n.language }
  })

  return {
    jurisdictions: jurisdictions.data ? jurisdictions.data.jurisdictions : null,
    loading: jurisdictions.loading,
    error: jurisdictions.error,
    jurisdictionsById: jurisdictions.data
      ? Object.assign(
          {},
          ...jurisdictions.data.jurisdictions.map(item => {
            return { [item.a2_code]: item }
          })
        )
      : null,
    regions: regions.data ? regions.data.regions : null,
    hrcMemberships: hrcMemberships.data
      ? hrcMemberships.data.hrcMemberships
      : null,
      topics: topics.data ? topics.data.topics : null,
      languages: languages.data ? languages.data.languages : null,
    jurSubjurs: jurSubjurs.data ? jurSubjurs.data.jurSubjurs : null,
    subjurFromSlug: subjurFromSlug.data
      ? subjurFromSlug.data.subjurFromSlug
      : null,
    loading_subjurs: jurSubjurs.loading
  }
}

export default useGqlJurisdictions
