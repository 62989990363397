import React, { useEffect, useRef } from 'react'
import IntroSection from './IntroSection'
import LatestUpdates from './LatestUpdates'

export default function AreaContent({ areaIntro, areaLatest }) {

  const introContentRef = useRef(null);

  return <section className="areaContent__section">
    <div className="content__container">
      <IntroSection passRef={introContentRef} area={areaIntro} />
      <div className="areaContent__right">
        <LatestUpdates area={areaLatest} areaIntro={areaIntro} passRef={introContentRef} />
      </div>
    </div>
  </section>
}