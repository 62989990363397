import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { cMainRegions } from "../../../config/constants";
import { isRecommendation } from "../../../lib/fnAreasPRHelper";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import LoadingBar from "../../components/ui/LoadingBar";
import { cVotingTypesMerged } from "../../../config/constants";

export default function BarChartArea6({ references }) {
  const [unMember, setUnMember] = useState(true);
  const [topicsN, setTopicsN] = useState(10);
  const { t, i18n } = useTranslation();
  const loading = useRef();
  const loadChart = () => {
    if (loading.current) loading.current.style.display = "block";
    am4core.options.queue = true;

    /* Chart code */
    // Themes begin
    am4core.unuseAllThemes();
    
    // Themes end

    // Create chart instance
    let chart = am4core.create("barChartDivX2", am4charts.XYChart);

    chart.events.on("ready", () => {
      if (loading.current) loading.current.style.display = "none";
    });

    const regions = cMainRegions();

    chart.data = [];
    let i = 0;
    let typeAcc = {};

    for (let vt of cVotingTypesMerged()) {
      typeAcc = { ...typeAcc, [vt.name]: 0 };
    }
    for (let ref of references) {
      if (!ref.voting_vote_type) continue;
      if (ref.voting_vote_type.id == 1 || ref.voting_vote_type.id == 3)
        typeAcc[cVotingTypesMerged().find(t => t.id == 1).name]++;
      if (ref.voting_vote_type.id == 2 || ref.voting_vote_type.id == 4)
        typeAcc[cVotingTypesMerged().find(t => t.id == 2).name]++;
      if (ref.voting_vote_type.id == 5) typeAcc[cVotingTypesMerged().find(t => t.id == 5).name]++;
      if (ref.voting_vote_type.id == 6) typeAcc[cVotingTypesMerged().find(t => t.id == 6).name]++;
      //  else typeAcc[ref.voting_vote_type.name]++;
    }

    
    let types = [];
    for (let type of Object.keys(typeAcc)) {
      types.push({
        name: type,
        amount: typeAcc[type]
      });
    }
    /*types = types.sort((a, b) => {
      if (a.amount > b.amount) return -1;
      else if (a.amount < b.amount) return 1;
      else return 0;
    });*/
    setTopicsN(types.length);
    chart.data = types; //.slice(0, 12);

    chart.tooltip.label.maxWidth = 150;
    chart.tooltip.label.wrap = true;

    // Create axes
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;

    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    categoryAxis.renderer.inversed = true;

    /*
    categoryAxis.renderer.labels.template.adapter.add("dy", function (dy, target) {
      if (target.dataItem && target.dataItem.index & (2 == 2)) {
        return dy + 15;
      }
      return dy;
    });*/

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;

    // Create series
    function createSeries(field, name) {
      // Set up series
      let series = chart.series.push(new am4charts.ColumnSeries());
      

      series.stroke = 0;

      series.name = name;
      series.hiddenInLegend = true;
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "name";
      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;

      series.columns.template.adapter.add("fill", function(fill, target) {
        
        if (target.dataItem && cVotingTypesMerged().find(t => t.name == target.dataItem.categoryY).id == 1) {
          return am4core.color("#96C9DB");
        } else if (target.dataItem && cVotingTypesMerged().find(t => t.name == target.dataItem.categoryY).id == 2) {
          return am4core.color("#E08AA2");
        } else {
          return am4core.color("#CCC");
        }
      });

      series.tooltip.pointerOrientation = "vertical";

      // Configure columns
      series.columns.template.width = am4core.percent(60);

      series.columns.template.tooltipText = "[bold][font-size:14px]{categoryY}: {valueX}[/]";

      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueX}";

      labelBullet.dx = 12;
      //labelBullet.label.hideOversized = true;

      return series;
    }

    createSeries("amount", t("Amount"));

    // Legend
    chart.legend = new am4charts.Legend();
    //chart.legend.reverseOrder = true;
  };
  useEffect(() => {
    loadChart();
  }, [unMember]);
  return (
    <>
      <div className="chartContainer"
        style={{
          minHeight: "250px",
          maxHeight: "250px",
          overflow: "auto",
          marginBottom: "10px",
          marginTop: "10px"
        }}
      >
        <LoadingBar ref={loading} style={{ minHeight: "250px" }} text={t("Loading chart") + "..."} />
        <div id="barChartDivX2" style={{ minHeight: "250px" }} />
      </div>
    </>
  );
}
