import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'

export default function SubjurProfileAccordion({
  entry,
  jur,
  subjur,
  content
}) {
  if (subjur)
    return (
      <Accordion
        className="subJur__box"
        defaultActiveKey={
          entry && entry.sub && entry.sub.length > 0 ? null : '1'
        }
      >
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <img
              className="jpsb__countryFlag"
              src={'/images/flags_svg/' + jur.a2_code.toLowerCase() + '.svg'}
              alt="Country Flag"
            />
            {jur.name}
          </Accordion.Header>
          <Accordion.Body>{content}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    )
  else return content
}
