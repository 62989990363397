import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { isRecommendation } from '../../../../lib/fnAreasPRHelper'
import LoadingBar from '../../ui/LoadingBar'

export default function LineChart({
  treatyBodies,
  references,
  subarea,
  commitee
}) {
  const [unMember, setUnMember] = useState(true)
  const { t, i18n } = useTranslation()
  const loading = useRef()
  const loadChart = () => {
    if (loading.current) loading.current.style.display = 'block'
    am4core.options.queue = false

    /* Chart code */
    // Themes begin
    am4core.unuseAllThemes()

    // Themes end

    // Create chart instance
    let chart = am4core.create('lineChartDiv', am4charts.XYChart)

    // Themes begin

    chart.events.on('ready', () => {
      if (loading.current) loading.current.style.display = 'none'
    })
    // Themes end
    chart.maskBullets = false
    // Create chart instance

    // Let's cut a hole in our Pie chart the size of 40% the radius
    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.renderer.minGridDistance = 20
    dateAxis.startLocation = 0
    dateAxis.endLocation = 1
    dateAxis.dateFormats.setKey('day', 'YYYY')
    dateAxis.dateFormats.setKey('week', 'YYYY')
    dateAxis.dateFormats.setKey('month', 'YYYY')
    dateAxis.dateFormats.setKey('year', 'YYYY')
    //dateAxis.gridIntervals.setAll([{ timeUnit: "year", count: 1 }]);
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.maxPrecision = 0
    valueAxis.min = 0
    
    let seriesArray = []
    let i = 0
    if (!subarea)
      for (let tb of treatyBodies) {
        seriesArray.push(createSeries(tb, tb.name, i))
        i++
      }
    else {
      let tb = treatyBodies.find(tb => tb.id == subarea)
      seriesArray.push(createSeries(tb, tb.name, subarea - 1))
    }

    // Create series
    function createSeries(s, name, i) {
      let serie = new am4charts.LineSeries()
      var series = chart.series.push(serie)
      series.dataFields.valueY = s.id
      series.dataFields.dateX = 'date'
      series.name = name
      series.fill = am4core.color(treatyBodies[i].color)
      series.stroke = treatyBodies[i].color

      var segment = series.segments.template
      segment.interactionsEnabled = true

      var hoverState = segment.states.create('hover')
      hoverState.properties.strokeWidth = 3

      var dimmed = segment.states.create('dimmed')
      dimmed.properties.stroke = am4core.color('#dadada')

      var data = []

      let eArray = {}
      let valueArray = {}

      for (let s of treatyBodies) {
        eArray[s.id] = []
        valueArray[s.id] = 0
      }

      for (let i = 2016; i <= new Date().getFullYear() - 2; i++) {
        eArray[s.id] = references.filter(
          r =>
            r.treaty_body.id == s.id &&
            r.date.slice(0, 4) == i &&
            !r.motherEntry.subjurisdiction &&
            isRecommendation(r)
        )

        valueArray[s.id] = eArray[s.id].length

        var dataItem = { date: new Date(i, 1, 1) }

        dataItem['' + s.id] = valueArray[s.id]

        /* remove if so each year has its own data
        if (
          !data.length ||
          data[data.length - 1][s.id] !== dataItem['' + s.id] ||
          new Date().getFullYear() === i
        ) */
        if (data.length)
          console.log('BBBB', data[data.length - 1][s.id], dataItem['' + s.id])
        data.push({
          ...dataItem,
          yearsText: '' + i,
          color: '#AB97CE',
          disabled: false
        })
      }

      console.log('MMM', data)

      series.data = data
      series.strokeWidth = '3'
      series.tensionX = 1

      series.tooltipHTML = `
      
      <strong>{yearsText} - {name}: {valueY} ${t('recommendations')}</strong>`

      series.tooltip.label.padding(10, 10, 10, 10)
      series.tooltip.label.maxWidth = 350
      series.tooltip.label.wrap = true

      series.tooltip.getFillFromObject = true
      //series.tooltip.background.fill = am4core.color("#CEB1BE");

      let bullet = series.bullets.push(new am4charts.CircleBullet())
      bullet.circle.strokeWidth = 2
      bullet.circle.radius = 4
      bullet.circle.fill = am4core.color('#fff')

      let bullethover = bullet.states.create('hover')
      bullethover.properties.scale = 1.3
      bullet.propertyFields.disabled = 'disabled'

      if (subarea) series.hiddenInLegend = true
      return serie
    }

    chart.legend = new am4charts.Legend()
    chart.legend.position = 'bottom'
    chart.legend.scrollable = true

    chart.cursor = new am4charts.XYCursor()
    chart.cursor.snapToSeries = seriesArray
  }
  useEffect(() => {
    loadChart()
  }, [unMember])
  return (
    <div>
      <LoadingBar
        background="#f4f8f8"
        ref={loading}
        text={t('Loading chart') + '...'}
      />
      <div id="lineChartDiv" style={{ minHeight: '450px' }} />
    </div>
  )
}
