import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { cTreatyBodiesFull } from "../../../config/constants";
import { isRecommendation } from "../../../lib/fnAreasPRHelper";
import "../../components/Charts/Charts.css";
import LoadingBar from "../../components/ui/LoadingBar";

export default function BarChartJurProfileArea3({ references }) {
  const [unMember, setUnMember] = useState(true);
  const { t, i18n } = useTranslation();
  const loading = useRef();
  const loadChart = () => {
    if (loading.current) loading.current.style.display = "block";
    am4core.options.queue = true;

    /* Chart code */
    // Themes begin
    am4core.unuseAllThemes();
    
    // Themes end

    // Create chart instance
    let chart = am4core.create("barChartDiv", am4charts.XYChart);

    let newReferences = references;

    chart.events.on("ready", () => {
      if (loading.current) loading.current.style.display = "none";
    });

    chart.data = [];
    let i = 0;
    for (let tb of cTreatyBodiesFull()) {
      chart.data = [...chart.data, { treaty_body: tb.acronym, full_name: tb.name, amount: 0 }];
      for (let ref of newReferences) {
        if (!ref.motherEntry.subjurisdiction)
          if (isRecommendation(ref) && ref.treaty_body.id == tb.id) {
            chart.data[i]["amount"]++;
          }
      }

      i++;
    }

    chart.tooltip.label.maxWidth = 150;
    chart.tooltip.label.wrap = true;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "treaty_body";
    categoryAxis.renderer.grid.template.location = 0;

    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    categoryAxis.renderer.inversed = true;

    /*
    categoryAxis.renderer.labels.template.adapter.add("dy", function (dy, target) {
      if (target.dataItem && target.dataItem.index & (2 == 2)) {
        return dy + 15;
      }
      return dy;
    });*/

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;

    // Create series
    function createSeries(field, name) {
      // Set up series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.hiddenInLegend = true;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "treaty_body";
      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;

      series.tooltip.pointerOrientation = "vertical";

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText = "[bold][font-size:14px]Recommendations by the {full_name}: {valueY}[/]";

      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      series.fill = am4core.color("#6EA2B5");
      series.stroke = am4core.color("#6EA2B5");
      labelBullet.dy = -10;
      //labelBullet.label.hideOversized = true;

      return series;
    }

    createSeries("amount", t("Amount"));

    // Legend
    chart.legend = new am4charts.Legend();
    //chart.legend.reverseOrder = true;
  };
  useEffect(() => {
    loadChart();
  }, [unMember]);
  return (
    <div className="chartContainer" style={{ minHeight: "400px", position: "relative" }}>
      <LoadingBar background="#ecefec" ref={loading} text={t("Loading chart") + "..."} />
      <div id="barChartDiv" style={{ minHeight: "400px" }} />
    </div>
  );
}
