import React, { useContext, useEffect } from 'react'
import Tippy from '@tippyjs/react'

//import "tippy.js/dist/tippy.css"; // optional
import { hideAll, followCursor } from 'tippy.js'
import './Tippy.css'
import ReactDOMServer from 'react-dom/server'
import reactStringReplace from 'react-string-replace'
import { useTranslation } from 'react-i18next'
import { decode } from 'html-entities'
import PicAsPDF from '../icons/PicAsPDF'

const SourceTooltip = ({ text, singletonTippy, source, parentComponent }) => {
  const { t } = useTranslation()
  if (!source) return <span>{t('Missing source')}</span>
  else {
    const file_types = [
      'original_official_filename',
      'original_filename',
      'translated_filename'
    ]
    const file_types_names = {
      original_official_filename: t('Original Official'),
      original_filename: t('Original'),
      translated_filename: t('Translation')
    }

    return (
      <div className="sourceTooltipBody">
        <div className="sourceTooltipTitle">Source Download</div>
        {file_types
          .filter(file_type => source[file_type])
          .map((file_type, index) => (
            <div className="sourceTooltipLink" key={index}>
              <a
                href={
                  process.env.REACT_APP_BACKEND_URL +
                  '/downloader/download/1/' +
                  source[file_type]
                }
                className="pdfLinkImage"
                onClick={e => {
                  e.stopPropagation()
                  console.log('a')
                }}
                target="_blank"
                rel="noreferrer"
              >
                <PicAsPDF
                  size={28}
                  color={parentComponent === 'jur' ? '#5d9b73' : null}
                />
              </a>
              <a
                href={
                  process.env.REACT_APP_BACKEND_URL +
                  '/downloader/download/1/' +
                  source[file_type]
                }
                className="pdfLink"
                rel="noreferrer"
                target="_blank"
                onClick={e => {
                  e.stopPropagation()
                  console.log('a')
                }}
              >
                {file_types_names[file_type]} (
                {file_type === 'translated_filename'
                  ? t('English')
                  : file_type === 'original_official_filename'
                  ? source.original_official_language.name
                  : source.original_language.name}
                )
              </a>{' '}
            </div>
          ))}
      </div>
    )
  }
}

const FootnoteTooltip = ({ text, singletonTippy, source, parentComponent }) => {

  return (
    <div className="sourceTooltipBody">
      <div
        style={{fontSize: '12px'}}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  )
}

export default function SourceText({
  text,
  singletonTippy,
  sources,
  footnotes = [],
  section,
  skipSources = 0,
  unik,
  parentComponent
}) {
  const theSources = section
    ? sources.filter(s => s.section === section)
    : sources

  const theFootnotes = section
    ? footnotes.filter(s => s.section === section)
    : footnotes

  return (
    <>
      {reactStringReplace(
        reactStringReplace(
          reactStringReplace(text, /\*([^*]+)\*/gi, (match, i) => {
            const currentSource = theSources[(i - 1) / 2]
            return (
              <Tippy
                className="SourceText__container"
                content={
                  <SourceTooltip
                    text={text}
                    source={currentSource}
                    parentComponent={parentComponent}
                  />
                }
                key={i}
                interactive={true}
                interactiveBorder={20}
                delay={100}
                allowHTML={true}
                onShow={instance => {
                  hideAll({ duration: 0 })
                }}
                followCursor={'initial'}
                theme={'ilga-source'}
                plugins={[followCursor]}
                placement={'top'}
                popperOptions={{
                  modifiers: [{ name: 'flip', enabled: false }],
                  strategy: 'fixed'
                }}
              >
                <span
                  className={
                    'sourceTooltip ' +
                    (currentSource &&
                    (currentSource.original_official_filename ||
                      currentSource.original_filename ||
                      currentSource.translated_filename)
                      ? ''
                      : 'missingSource')
                  }
                >
                  {match}
                </span>
              </Tippy>
            )
          }),
          /(\n)/gi,
          (match, i, offset) => (
            <React.Fragment
              key={
                unik +
                offset +
                '_' +
                i +
                Math.floor(Math.random() * 100) +
                'div'
              }
            >
              <br className="brSourceText" />
            </React.Fragment>
          )
        ),
        /_([^_]+)_/gi,
        (match, i) => {
          
          const currentFootnote = theFootnotes[(i - 1) / 2]
          return (
            <>
              {match}
              {currentFootnote && currentFootnote.content && (
                <Tippy
                  className="SourceText__container"
                  content={
                    <FootnoteTooltip
                      text={currentFootnote.content}
                      source={currentFootnote}
                      parentComponent={parentComponent}
                    />
                  }
                  key={i}
                  interactive={true}
                  interactiveBorder={20}
                  delay={100}
                  allowHTML={true}
                  onShow={instance => {
                    hideAll({ duration: 0 })
                  }}
                  followCursor={'initial'}
                  theme={'ilga-source'}
                  plugins={[followCursor]}
                  placement={'top'}
                  popperOptions={{
                    modifiers: [{ name: 'flip', enabled: false }],
                    strategy: 'fixed'
                  }}
                >
                  <span className={'sourceTooltip '}>[{i}]</span>
                </Tippy>
              )}
            </>
          )
        }
      )}
    </>
  )
}
