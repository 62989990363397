import React from 'react'
import { ApplyColumnFilter } from '../components/Table/ColumnFilter'
import DateDropdownFilter, {
  ApplyDropdownDateFilter
} from '../components/Table/DateDropdownFilter'
import ExpandableTextCell from '../components/Table/ExpandableTextCell'
import GenericDropdownFilter, {
  ApplyGenericDropdownFilter
} from '../components/Table/GenericDropdownFilter'
import JurCell from '../components/Table/JurCell'
import JurFilter, { ApplyJurFilter } from '../components/Table/JurFilter'
import SourceText from '../components/Table/SourceText'
import i18n from '../../config/i18n'
import routes from '../../config/routes'
import { explanSourceLinks } from '../../lib/fnHelper'
import { citeEntry } from '../../lib/cite'
import { cLegalFrameworksDecrimMechanisms } from '../../config/constants'

const getBgColorClass = (cell, col) => {
  return cell.row.original[col].id === '1'
    ? 'rowProtectionYes'
    : cell.row.original[col].id === '2'
    ? 'rowProtectionNo'
    : cell.row.original[col].id === '6'
    ? 'rowProtectionVaries'
    : 'rowProtectionUnclear'
}

export const citeA15 = (entry, setToast, subarea) => {
  let lines = [
    '<p>' +
      `${
        entry.motherEntry.subjurisdiction
          ? entry.motherEntry.subjurisdiction.name +
            ' (' +
            entry.motherEntry.jurisdiction.name +
            ')'
          : entry.motherEntry.jurisdiction.name
      }: ${
        routes().find(r => r.parent === 'area1' && r.subareaNumber === subarea)
          .name
      }` +
      '</p>'
  ]

  if (entry.so_explan)
    lines.push(
      '<p>' +
        explanSourceLinks(
          i18n.t('Sexual Orientation') + ': ' + entry.so_explan,
          entry,
          1
        ) +
        '</p>'
    )
  if (entry.gi_explan)
    lines.push(
      '<p>' +
        explanSourceLinks(
          i18n.t('Gender Identity') + ': ' + entry.gi_explan,
          entry,
          2
        ) +
        '</p>'
    )
  if (entry.ge_explan)
    lines.push(
      '<p>' +
        explanSourceLinks(
          i18n.t('Gender Expression') + ': ' + entry.ge_explan,
          entry,
          3
        ) +
        '</p>'
    )
  if (entry.sc_explan)
    lines.push(
      '<p>' +
        explanSourceLinks(
          i18n.t('Sex Characteristics') + ': ' + entry.sc_explan,
          entry,
          4
        ) +
        '</p>'
    )

  citeEntry(
    routes().find(r => r.parent === 'area1' && r.subareaNumber === subarea),
    entry.motherEntry.subjurisdiction
      ? entry.motherEntry.subjurisdiction.name +
          ' (' +
          entry.motherEntry.jurisdiction.name +
          ')'
      : entry.motherEntry.jurisdiction.name,
    lines.join(''),
    setToast
  )
}

export const columns = () => [
  {
    Header: i18n.t('Jurisdiction'),
    disableGroupBy: true,
    accessor: 'motherEntry.jurisdiction.name',
    enableRowSpan: true,
    filter: ApplyJurFilter,
    Filter: ({ column }) => {
      return <JurFilter column={column} search={true} />
    },
    Cell: ({ value, row }) => {
      return (
        <div
          className={
            'jurCell ' +
            (row.original.motherEntry.jurisdiction.id.indexOf('DT') !== -1 ||
            row.original.motherEntry.subjurisdiction
              ? 'dependentTerritory'
              : '')
          }
        >
          <div>
            <img
              className="tableFlag"
              src={
                '/images/flags_svg/' +
                row.original.motherEntry.jurisdiction.a2_code.toLowerCase() +
                '.svg'
              }
              height="16px"
            />
          </div>
          <div>
            {!row.original.motherEntry.subjurisdiction &&
              row.original.motherEntry.jurisdiction.name}
            {row.original.motherEntry.subjurisdiction &&
              '' + row.original.motherEntry.subjurisdiction.name + ''}
          </div>
        </div>
      )
    },
    CellSmall: ({ value, row }) => {
      return (
        <div>{row.original.motherEntry.jurisdiction.name.substring(0, 3)}</div>
      )
    },
    style: { minWidth: '100px' }
  },
  {
    Header: i18n.t('SO'),
    accessor: 'so_protection_type.name',

    Cell: ({ value, row }) => {
      return row.original.so_protection_type.id === '1' ? (
        <span className="">{value}</span>
      ) : (
        <span className="">{value}</span>
      )
    },
    filter: (rows, columnsId, filterValue) => {
      return ApplyGenericDropdownFilter(
        rows,
        'so_protection_type.name',
        filterValue
      )
    },
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          column={column}
          width={'150px'}
          options={[
            { id: i18n.t('Yes'), value: i18n.t('Yes') },
            { id: i18n.t('No'), value: i18n.t('No') },
            { id: i18n.t('Unclear'), value: i18n.t('Unclear') },
            { id: i18n.t('Varies'), value: i18n.t('Varies') },
            { id: i18n.t('N/A'), value: i18n.t('N/A') }
          ]}
        />
      )
    },

    className: 'textCenter flexTd col120',
    classNameFn: c => getBgColorClass(c, 'so_protection_type')
  },

  {
    Header: i18n.t('Critical Date'),
    accessor: 'so_critical_date_1',
    Cell: ({ value, row }) => {
      if (value)
        return (
          value +
          (row.original.so_critical_date_2
            ? ' - ' + row.original.so_critical_date_2
            : '')
        )
      else return '-'
    },
    sortDescFirst: true,
    className: 'textCenter col125',
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(
        rows,
        ['so_critical_date_1', 'so_critical_date_2'],
        filterValue
      ),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    }
  },

  {
    Header: i18n.t('Mechanism'),
    accessor: 'so_legal_framework_decrim_mechanism.name',
    sortDescFirst: true,
    Cell: ({ value, row }) => {
      return row.original.so_legal_framework_decrim_mechanism
        ? row.original.so_legal_framework_decrim_mechanism.name
        : ''
    },
    className: 'textCenter col125 flexTd',
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(
        rows,
        'so_legal_framework_decrim_mechanism.name',
        filterValue
      ),
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width={'200px'}
          column={column}
          options={cLegalFrameworksDecrimMechanisms().filter(
            t => t.id !== i18n.t('Human Rights Commission')
          )}
        />
      )
    }
  },

  {
    Header: i18n.t('Explanation'),
    accessor: 'so_explan',

    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            section={1}
            row={row}
            sources={row.original.motherEntry.sources}
            unik={row.original.id + '_explan'}
            footnotes={row.original.motherEntry.footnotes}
          />
        )
      else return <div className="expandableCell" />
    },

    NonExpandableCell: ({ value, row }) => {
      if (value)
        return (
          <SourceText
            text={value}
            section={
              row.original.section ? row.original.section : 1
            } /* row.original.section for JUR PROFILE */
            sources={row.original.motherEntry.sources}
            unik={row.original.id + '_explan'}
          />
        )
      else return <div className="expandableCell" />
    },
    style: { minWidth: '300px' },
    disableSortBy: true
  },
  {
    Header: i18n.t('GI'),
    accessor: 'gi_protection_type.name',
    Cell: ({ value, row }) => {
      return row.original.gi_protection_type.id === '1' ? (
        <span className="">{value}</span>
      ) : (
        <span className="">{value}</span>
      )
    },
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(rows, 'gi_protection_type.name', filterValue),
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          column={column}
          width={'150px'}
          options={[
            { id: i18n.t('Yes'), value: i18n.t('Yes') },
            { id: i18n.t('No'), value: i18n.t('No') },
            { id: i18n.t('Unclear'), value: i18n.t('Unclear') },
            { id: i18n.t('Varies'), value: i18n.t('Varies') },
            { id: i18n.t('N/A'), value: i18n.t('N/A') }
          ]}
        />
      )
    },
    className: 'textCenter flexTd col120',
    classNameFn: c => getBgColorClass(c, 'gi_protection_type')
  },

  {
    Header: i18n.t('Critical Date'),
    accessor: 'gi_critical_date_1',
    Cell: ({ value, row }) => {
      if (value)
        return (
          value +
          (row.original.gi_critical_date_2
            ? ' - ' + row.original.gi_critical_date_2
            : '')
        )
      else return '-'
    },
    sortDescFirst: true,
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(
        rows,
        ['gi_critical_date_1', 'gi_critical_date_2'],
        filterValue
      ),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    },
    className: 'textCenter col125'
  },
  {
    Header: i18n.t('Mechanism'),
    accessor: 'gi_legal_framework_decrim_mechanism.name',
    sortDescFirst: true,
    Cell: ({ value, row }) => {
      return row.original.gi_legal_framework_decrim_mechanism
        ? row.original.gi_legal_framework_decrim_mechanism.name
        : ''
    },
    className: 'textCenter col125 flexTd',
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(
        rows,
        'gi_legal_framework_decrim_mechanism.name',
        filterValue
      ),
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width={'200px'}
          column={column}
          options={cLegalFrameworksDecrimMechanisms().filter(
            t => t.id !== i18n.t('Human Rights Commission')
          )}
        />
      )
    }
  },
  {
    Header: i18n.t('Explanation'),
    accessor: 'gi_explan',
    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            skipSources={
              (row.original.so_explan.match(/\*[^*]*\*/g) || []).length
            }
            section={2}
            value={value}
            row={row}
            sources={row.original.motherEntry.sources}
            unik={row.original.id + '_explan'}
            footnotes={row.original.motherEntry.footnotes}
          />
        )
      else return <div className="expandableCell" />
    },
    NonExpandableCell: ({ value, row }) => {
      if (value)
        return (
          <SourceText
            text={value}
            skipSources={
              (row.original.so_explan.match(/\*[^*]*\*/g) || []).length
            }
            section={2}
            sources={row.original.motherEntry.sources}
            unik={row.original.id + '_explan'}
          />
        )
      else return <div className="expandableCell" />
    },
    style: { minWidth: '300px' },
    disableSortBy: true
  },
  {
    Header: i18n.t('GE'),
    accessor: 'ge_protection_type.name',
    Cell: ({ value, row }) => {
      return row.original.ge_protection_type.id === '1' ? (
        <span className="">{value}</span>
      ) : (
        <span className="">{value}</span>
      )
    },
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(rows, 'ge_protection_type.name', filterValue),
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          column={column}
          width={'150px'}
          options={[
            { id: i18n.t('Yes'), value: i18n.t('Yes') },
            { id: i18n.t('No'), value: i18n.t('No') },
            { id: i18n.t('Unclear'), value: i18n.t('Unclear') },
            { id: i18n.t('Varies'), value: i18n.t('Varies') },
            { id: i18n.t('N/A'), value: i18n.t('N/A') }
          ]}
        />
      )
    },
    className: 'textCenter flexTd col120',
    classNameFn: c => getBgColorClass(c, 'ge_protection_type')
  },

  {
    Header: i18n.t('Critical Date'),
    accessor: 'ge_critical_date_1',
    Cell: ({ value, row }) => {
      if (value)
        return (
          value +
          (row.original.ge_critical_date_2
            ? ' - ' + row.original.ge_critical_date_2
            : '')
        )
      else return '-'
    },
    sortDescFirst: true,
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(
        rows,
        ['ge_critical_date_1', 'ge_critical_date_2'],
        filterValue
      ),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    },
    className: 'textCenter col125'
  },
  {
    Header: i18n.t('Mechanism'),
    accessor: 'ge_legal_framework_decrim_mechanism.name',
    sortDescFirst: true,
    Cell: ({ value, row }) => {
      return row.original.ge_legal_framework_decrim_mechanism
        ? row.original.ge_legal_framework_decrim_mechanism.name
        : ''
    },
    className: 'textCenter col125 flexTd',
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(
        rows,
        'ge_legal_framework_decrim_mechanism.name',
        filterValue
      ),
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width={'200px'}
          column={column}
          options={cLegalFrameworksDecrimMechanisms().filter(
            t => t.id !== i18n.t('Human Rights Commission')
          )}
        />
      )
    }
  },
  {
    Header: i18n.t('Explanation'),
    accessor: 'ge_explan',
    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            row={row}
            section={3}
            skipSources={
              (row.original.so_explan.match(/\*[^*]*\*/g) || []).length +
              (row.original.gi_explan.match(/\*[^*]*\*/g) || []).length
            }
            sources={row.original.motherEntry.sources}
            unik={row.original.id + '_explan'}
            footnotes={row.original.motherEntry.footnotes}
          />
        )
      else return <div className="expandableCell" />
    },
    NonExpandableCell: ({ value, row }) => {
      if (value)
        return (
          <SourceText
            text={value}
            section={3}
            skipSources={
              (row.original.so_explan.match(/\*[^*]*\*/g) || []).length +
              (row.original.gi_explan.match(/\*[^*]*\*/g) || []).length
            }
            sources={row.original.motherEntry.sources}
            unik={row.original.id + '_explan'}
          />
        )
      else return <div className="expandableCell" />
    },
    style: { minWidth: '300px' },
    disableSortBy: true
  },
  {
    Header: i18n.t('SC'),
    accessor: 'sc_protection_type.name',
    Cell: ({ value, row }) => {
      return row.original.sc_protection_type.id === '1' ? (
        <span className="">{value}</span>
      ) : (
        <span className="">{value}</span>
      )
    },
    filter: (rows, columnsId, filterValue) => {
      return ApplyGenericDropdownFilter(
        rows,
        'sc_protection_type.name',
        filterValue
      )
    },
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          column={column}
          width={'150px'}
          options={[
            { id: i18n.t('Yes'), value: i18n.t('Yes') },
            { id: i18n.t('No'), value: i18n.t('No') },
            { id: i18n.t('Unclear'), value: i18n.t('Unclear') },
            { id: i18n.t('Varies'), value: i18n.t('Varies') },
            { id: i18n.t('N/A'), value: i18n.t('N/A') }
          ]}
        />
      )
    },
    className: 'textCenter flexTd col120 ',
    classNameFn: c => getBgColorClass(c, 'sc_protection_type')
  },

  {
    Header: i18n.t('Critical Date'),
    accessor: 'sc_critical_date_1',
    Cell: ({ value, row }) => {
      if (value)
        return (
          value +
          (row.original.sc_critical_date_2
            ? ' - ' + row.original.sc_critical_date_2
            : '')
        )
      else return '-'
    },
    sortDescFirst: true,
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(
        rows,
        ['sc_critical_date_1', 'sc_critical_date_2'],
        filterValue
      ),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    },
    className: 'textCenter col125'
  },
  {
    Header: i18n.t('Mechanism'),
    accessor: 'sc_legal_framework_decrim_mechanism.name',
    sortDescFirst: true,
    Cell: ({ value, row }) => {
      return row.original.sc_legal_framework_decrim_mechanism
        ? row.original.sc_legal_framework_decrim_mechanism.name
        : ''
    },
    className: 'textCenter col125 flexTd',
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(
        rows,
        'sc_legal_framework_decrim_mechanism.name',
        filterValue
      ),
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width={'200px'}
          column={column}
          options={cLegalFrameworksDecrimMechanisms().filter(
            t => t.id !== i18n.t('Human Rights Commission')
          )}
        />
      )
    }
  },
  {
    Header: i18n.t('Explanation'),
    accessor: 'sc_explan',
    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            row={row}
            section={4}
            skipSources={
              (row.original.so_explan.match(/\*[^*]*\*/g) || []).length +
              (row.original.gi_explan.match(/\*[^*]*\*/g) || []).length +
              (row.original.ge_explan.match(/\*[^*]*\*/g) || []).length
            }
            sources={row.original.motherEntry.sources}
            unik={row.original.id + '_explan'}
            footnotes={row.original.motherEntry.footnotes}
          />
        )
      else return <div className="expandableCell" />
    },
    NonExpandableCell: ({ value, row }) => {
      if (value)
        return (
          <SourceText
            text={value}
            section={4}
            skipSources={
              (row.original.so_explan.match(/\*[^*]*\*/g) || []).length +
              (row.original.gi_explan.match(/\*[^*]*\*/g) || []).length +
              (row.original.ge_explan.match(/\*[^*]*\*/g) || []).length
            }
            sources={row.original.motherEntry.sources}
            unik={row.original.id + '_explan'}
          />
        )
      else return <div className="expandableCell" />
    },
    style: { minWidth: '300px' },
    disableSortBy: true
  }
]
