import React from 'react';

const BookIcon = ({ color = '#c9d8d9' }) => <svg width="55.871" height="43.173" viewBox="0 0 55.871 43.173">
  <g id="Group_744" data-name="Group 744" transform="translate(0)"></g>
  <g id="Group_743" data-name="Group 743" transform="translate(0)">
    <path id="Path_1783-2" data-name="Path 1783" d="M51.792,5.77A29.979,29.979,0,0,0,42.9,4.5c-4.952,0-10.285,1.016-13.968,3.809C25.253,5.516,19.92,4.5,14.968,4.5S4.682,5.516,1,8.309v37.2a1.366,1.366,0,0,0,1.27,1.27c.254,0,.381-.127.635-.127a31,31,0,0,1,12.063-2.794c4.952,0,10.285,1.016,13.968,3.809A30.59,30.59,0,0,1,42.9,43.864,25.849,25.849,0,0,1,54.966,46.53a1.145,1.145,0,0,0,.635.127,1.366,1.366,0,0,0,1.27-1.27V8.309A17.322,17.322,0,0,0,51.792,5.77Zm0,34.284a29.511,29.511,0,0,0-8.889-1.27,30.59,30.59,0,0,0-13.968,3.809V13.389A30.59,30.59,0,0,1,42.9,9.579a29.511,29.511,0,0,1,8.889,1.27Z" transform="translate(-1 -4.5)" fill={color} />
    <path id="Path_1784-2" data-name="Path 1784" d="M24.428,12.809a29.7,29.7,0,0,1,6.349.66V9.609A34.292,34.292,0,0,0,24.428,9,29.223,29.223,0,0,0,13,11.108v4.216A23.576,23.576,0,0,1,24.428,12.809Z" transform="translate(17.475 2.428)" fill={color} />
    <path id="Path_1785-2" data-name="Path 1785" d="M13,13.768v4.216a23.576,23.576,0,0,1,11.428-2.514,29.7,29.7,0,0,1,6.349.66v-3.86a34.292,34.292,0,0,0-6.349-.609A29.69,29.69,0,0,0,13,13.768Z" transform="translate(17.475 6.523)" fill={color}  />
    <path id="Path_1786-2" data-name="Path 1786" d="M24.428,14.33A29.223,29.223,0,0,0,13,16.438v4.216a23.576,23.576,0,0,1,11.428-2.514,29.7,29.7,0,0,1,6.349.66v-3.86A32.245,32.245,0,0,0,24.428,14.33Z" transform="translate(17.475 10.634)" fill={color} />
  </g>
</svg>

export default BookIcon;