var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.778, -6.9523],
            [-77.9363, -6.9973],
            [-77.952, -6.8508],
            [-78.1731, -6.4628],
            [-78.3373, -6.3767],
            [-78.4543, -6.149],
            [-78.5667, -6.0675],
            [-78.563, -5.9979],
            [-78.6772, -5.9012],
            [-78.6772, -5.8132],
            [-78.5279, -5.3554],
            [-78.7299, -5.2321],
            [-78.7299, -5.0559],
            [-78.6305, -4.8166],
            [-78.709, -4.5848],
            [-78.6503, -4.4736],
            [-78.6721, -4.3071],
            [-78.5636, -4.0956],
            [-78.5317, -3.9128],
            [-78.421, -3.7859],
            [-78.3461, -3.435],
            [-78.1164, -3.276],
            [-77.9254, -3.04],
            [-77.8102, -2.9682],
            [-77.767, -3.2613],
            [-77.6764, -3.4492],
            [-77.6339, -3.77],
            [-77.5578, -3.9042],
            [-77.5781, -4.4405],
            [-77.7422, -4.7033],
            [-77.7692, -4.9303],
            [-77.8337, -5.0735],
            [-77.7303, -5.1562],
            [-77.64, -5.3847],
            [-77.7172, -5.4908],
            [-77.7263, -5.7011],
            [-77.5939, -5.9678],
            [-77.5261, -6.0256],
            [-77.2805, -6.0933],
            [-77.1592, -6.3254],
            [-77.3129, -6.4921],
            [-77.3504, -6.6309],
            [-77.4559, -6.7188],
            [-77.5262, -6.6661],
            [-77.6843, -6.6661],
            [-77.7375, -6.7111],
            [-77.7853, -6.9308],
            [-77.778, -6.9523]
          ]
        ]
      },
      properties: {
        name: 'Amazonas',
        id: 'PE-AMA',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-AMA'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.2578, -8.4808],
            [-77.2663, -8.6174],
            [-77.1972, -8.6597],
            [-77.2007, -8.7894],
            [-77.1105, -8.9307],
            [-76.6895, -9.175],
            [-76.7261, -9.2946],
            [-76.8524, -9.3101],
            [-76.9979, -9.6742],
            [-76.836, -9.8142],
            [-76.8151, -10.0062],
            [-76.8936, -10.0943],
            [-76.8727, -10.1618],
            [-76.9315, -10.2868],
            [-77.1254, -10.3607],
            [-77.1786, -10.4953],
            [-77.4232, -10.7195],
            [-77.5885, -10.7486],
            [-77.6003, -10.5772],
            [-77.6826, -10.4689],
            [-77.6002, -10.3691],
            [-77.7049, -10.2914],
            [-77.7838, -10.3683],
            [-77.8174, -10.5574],
            [-77.8946, -10.6125],
            [-78.0695, -10.3219],
            [-78.2558, -9.8725],
            [-78.2463, -9.796],
            [-78.3778, -9.6192],
            [-78.4053, -9.4278],
            [-78.4853, -9.33],
            [-78.5206, -9.1725],
            [-78.6398, -9.0695],
            [-78.6638, -8.9667],
            [-78.5985, -8.8999],
            [-78.5503, -8.7592],
            [-78.2543, -8.6586],
            [-78.1166, -8.3035],
            [-77.9474, -8.1806],
            [-77.867, -8.0645],
            [-77.6733, -8.0272],
            [-77.6159, -8.1414],
            [-77.4889, -8.2358],
            [-77.3256, -8.4958],
            [-77.2578, -8.4808]
          ]
        ]
      },
      properties: {
        name: 'Ancash',
        id: 'PE-ANC',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-ANC'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9071, -14.6748],
            [-73.0269, -14.6301],
            [-73.4469, -14.7334],
            [-73.5431, -14.8071],
            [-73.5935, -14.6825],
            [-73.5987, -14.5041],
            [-73.6406, -14.4276],
            [-73.5411, -14.2643],
            [-73.7475, -13.7871],
            [-73.6902, -13.7424],
            [-73.8214, -13.577],
            [-73.8522, -13.3593],
            [-73.8061, -13.2111],
            [-73.4863, -13.4375],
            [-73.3239, -13.405],
            [-73.3076, -13.3481],
            [-73.2919, -13.3675],
            [-73.2377, -13.4068],
            [-72.9046, -13.397],
            [-72.6178, -13.5481],
            [-72.2778, -13.6973],
            [-72.1619, -13.7886],
            [-72.0878, -13.9231],
            [-72.0802, -14.0958],
            [-72.1143, -14.2181],
            [-72.2745, -14.4139],
            [-72.4676, -14.5064],
            [-72.4861, -14.6742],
            [-72.6134, -14.6983],
            [-72.7682, -14.6572],
            [-72.9071, -14.6748]
          ]
        ]
      },
      properties: {
        name: 'Apurimac',
        id: 'PE-APU',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-APU'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5839, -17.2574],
            [-71.8051, -17.1981],
            [-71.926, -17.0911],
            [-72.2328, -16.9019],
            [-72.3647, -16.7583],
            [-72.4932, -16.6907],
            [-72.7928, -16.6314],
            [-72.9054, -16.5425],
            [-73.235, -16.4117],
            [-73.407, -16.3003],
            [-73.8362, -16.1576],
            [-74.0464, -15.9658],
            [-74.4192, -15.8167],
            [-74.4824, -15.7232],
            [-75.0514, -15.466],
            [-75.1011, -15.417],
            [-75.0465, -15.2676],
            [-74.968, -15.1711],
            [-74.8284, -15.0982],
            [-74.7266, -15.0881],
            [-74.7013, -15.1361],
            [-74.4693, -15.1907],
            [-74.4135, -15.3594],
            [-74.2993, -15.469],
            [-74.11, -15.4472],
            [-74.0792, -15.6077],
            [-73.9337, -15.3616],
            [-73.7819, -15.2905],
            [-73.761, -15.4175],
            [-73.5842, -15.4247],
            [-73.4625, -15.3663],
            [-73.3527, -15.3692],
            [-73.2847, -15.2676],
            [-73.1957, -15.2473],
            [-73.0544, -15.0287],
            [-73.0701, -14.8862],
            [-73.0177, -14.7691],
            [-72.9071, -14.6748],
            [-72.7682, -14.6572],
            [-72.6134, -14.6983],
            [-72.4861, -14.6742],
            [-72.4031, -14.8292],
            [-72.2115, -14.8217],
            [-72.065, -14.8807],
            [-71.9832, -14.6838],
            [-71.8439, -14.8617],
            [-71.8582, -15.0565],
            [-71.6664, -15.1186],
            [-71.5111, -15.0406],
            [-71.4047, -15.0463],
            [-71.2936, -15.1163],
            [-71.2145, -15.0773],
            [-71.2172, -15.3261],
            [-71.1543, -15.3972],
            [-71.0248, -15.4043],
            [-71.0261, -15.4603],
            [-71.0139, -15.5617],
            [-70.8999, -15.6895],
            [-70.9378, -15.7913],
            [-70.8347, -15.9271],
            [-70.8501, -15.9883],
            [-70.8663, -16.2482],
            [-70.9921, -16.4304],
            [-71.1805, -16.3344],
            [-71.1648, -16.4506],
            [-71.3096, -16.6285],
            [-71.3096, -16.7406],
            [-71.4507, -16.8526],
            [-71.3419, -17.06],
            [-71.3705, -17.1645],
            [-71.5839, -17.2574]
          ]
        ]
      },
      properties: {
        name: 'Arequipa',
        id: 'PE-ARE',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-ARE'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7266, -15.0881],
            [-74.6969, -14.9013],
            [-74.7633, -14.8455],
            [-74.8785, -14.6264],
            [-75.0931, -14.606],
            [-75.1506, -14.3868],
            [-75.1245, -14.3255],
            [-75.1053, -14.0315],
            [-74.9675, -14.0549],
            [-74.8575, -14.0242],
            [-74.7999, -13.8811],
            [-74.7901, -13.5878],
            [-74.9295, -13.4501],
            [-74.897, -13.3414],
            [-74.7319, -13.3128],
            [-74.622, -13.1846],
            [-74.4859, -13.1076],
            [-74.4388, -13.0153],
            [-74.3102, -12.9545],
            [-74.4295, -12.4727],
            [-74.5376, -12.3441],
            [-74.5041, -12.2542],
            [-74.3593, -12.1947],
            [-74.1509, -12.2623],
            [-73.9468, -12.2314],
            [-73.9497, -12.2773],
            [-73.8045, -12.5794],
            [-73.6681, -12.6919],
            [-73.5542, -12.8356],
            [-73.4181, -13.159],
            [-73.3083, -13.3472],
            [-73.3076, -13.3481],
            [-73.3239, -13.405],
            [-73.4863, -13.4375],
            [-73.8061, -13.2111],
            [-73.8522, -13.3593],
            [-73.8214, -13.577],
            [-73.6902, -13.7424],
            [-73.7475, -13.7871],
            [-73.5411, -14.2643],
            [-73.6406, -14.4276],
            [-73.5987, -14.5041],
            [-73.5935, -14.6825],
            [-73.5431, -14.8071],
            [-73.4469, -14.7334],
            [-73.0269, -14.6301],
            [-72.9071, -14.6748],
            [-73.0177, -14.7691],
            [-73.0701, -14.8862],
            [-73.0544, -15.0287],
            [-73.1957, -15.2473],
            [-73.2847, -15.2676],
            [-73.3527, -15.3692],
            [-73.4625, -15.3663],
            [-73.5842, -15.4247],
            [-73.761, -15.4175],
            [-73.7819, -15.2905],
            [-73.9337, -15.3616],
            [-74.0792, -15.6077],
            [-74.11, -15.4472],
            [-74.2993, -15.469],
            [-74.4135, -15.3594],
            [-74.4693, -15.1907],
            [-74.7013, -15.1361],
            [-74.7266, -15.0881]
          ]
        ]
      },
      properties: {
        name: 'Ayacucho',
        id: 'PE-AYA',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-AYA'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.9363, -6.9973],
            [-77.8106, -7.2639],
            [-77.7531, -7.4677],
            [-77.9385, -7.6692],
            [-78.0885, -7.6315],
            [-78.2949, -7.7519],
            [-78.3471, -7.6746],
            [-78.3521, -7.535],
            [-78.4048, -7.4999],
            [-78.563, -7.5262],
            [-78.7124, -7.3859],
            [-78.8266, -7.4473],
            [-78.9408, -7.5701],
            [-79.0581, -7.5929],
            [-79.0999, -7.4803],
            [-79.2874, -7.3793],
            [-79.3372, -7.2074],
            [-79.3477, -7.0104],
            [-79.3154, -6.884],
            [-79.1669, -6.7682],
            [-79.1739, -6.707],
            [-79.3162, -6.679],
            [-79.4508, -6.519],
            [-79.4667, -6.385],
            [-79.2482, -6.2063],
            [-79.2147, -6.0421],
            [-79.353, -5.9157],
            [-79.3489, -5.9125],
            [-79.4202, -5.7352],
            [-79.4003, -5.5753],
            [-79.3181, -5.5046],
            [-79.3237, -5.339],
            [-79.3938, -5.168],
            [-79.2319, -4.9639],
            [-79.0114, -4.9722],
            [-78.9286, -4.8683],
            [-78.9184, -4.741],
            [-78.8593, -4.6615],
            [-78.709, -4.5848],
            [-78.6305, -4.8166],
            [-78.7299, -5.0559],
            [-78.7299, -5.2321],
            [-78.5279, -5.3554],
            [-78.6772, -5.8132],
            [-78.6772, -5.9012],
            [-78.563, -5.9979],
            [-78.5667, -6.0675],
            [-78.4543, -6.149],
            [-78.3373, -6.3767],
            [-78.1731, -6.4628],
            [-77.952, -6.8508],
            [-77.9363, -6.9973]
          ]
        ]
      },
      properties: {
        name: 'Cajamarca',
        id: 'PE-CAJ',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-CAJ'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.0279, -12.1501],
            [-77.1757, -12.0709],
            [-77.1411, -12],
            [-77.1856, -11.7059],
            [-77.0821, -11.7135],
            [-77.0768, -11.8939],
            [-76.9774, -12.0795],
            [-77.0279, -12.1501]
          ]
        ]
      },
      properties: {
        name: 'El Callao',
        id: 'PE-CAL',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-CAL'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2849, -11.2909],
            [-72.3059, -11.4967],
            [-72.4524, -11.6825],
            [-72.3659, -11.7907],
            [-72.3216, -11.9764],
            [-72.1436, -12.0846],
            [-72.0546, -12.2339],
            [-71.9761, -12.27],
            [-72.0965, -12.4141],
            [-71.9918, -12.5478],
            [-71.9761, -12.702],
            [-71.9185, -12.7431],
            [-71.5679, -12.6814],
            [-71.4527, -12.8407],
            [-71.2172, -12.9383],
            [-71.1439, -13.2],
            [-71.034, -13.1692],
            [-70.8874, -13.0307],
            [-70.5891, -13.1025],
            [-70.4373, -13.1128],
            [-70.4477, -13.2564],
            [-70.453, -13.3641],
            [-70.8141, -13.789],
            [-70.8979, -13.8248],
            [-70.8926, -14.0498],
            [-70.924, -14.2388],
            [-71.013, -14.4072],
            [-71.0549, -14.5653],
            [-71.1491, -14.6519],
            [-70.9989, -14.8247],
            [-71.0208, -15.0034],
            [-70.9824, -15.3092],
            [-71.0242, -15.3792],
            [-71.0248, -15.4043],
            [-71.1543, -15.3972],
            [-71.2172, -15.3261],
            [-71.2145, -15.0773],
            [-71.2936, -15.1163],
            [-71.4047, -15.0463],
            [-71.5111, -15.0406],
            [-71.6664, -15.1186],
            [-71.8582, -15.0565],
            [-71.8439, -14.8617],
            [-71.9832, -14.6838],
            [-72.065, -14.8807],
            [-72.2115, -14.8217],
            [-72.4031, -14.8292],
            [-72.4861, -14.6742],
            [-72.4676, -14.5064],
            [-72.2745, -14.4139],
            [-72.1143, -14.2181],
            [-72.0802, -14.0958],
            [-72.0878, -13.9231],
            [-72.1619, -13.7886],
            [-72.2778, -13.6973],
            [-72.6178, -13.5481],
            [-72.9046, -13.397],
            [-73.2377, -13.4068],
            [-73.2919, -13.3675],
            [-73.3083, -13.3472],
            [-73.4181, -13.159],
            [-73.5542, -12.8356],
            [-73.6681, -12.6919],
            [-73.8045, -12.5794],
            [-73.9497, -12.2773],
            [-73.9472, -12.2385],
            [-73.7741, -12.2056],
            [-73.6432, -12.3137],
            [-73.4181, -12.1438],
            [-73.371, -12.082],
            [-73.4924, -11.8473],
            [-73.5752, -11.814],
            [-73.5858, -11.6753],
            [-73.5453, -11.5324],
            [-73.4352, -11.3242],
            [-73.1239, -11.2939],
            [-72.9685, -11.244],
            [-72.8339, -11.375],
            [-72.5747, -11.4338],
            [-72.4099, -11.3278],
            [-72.2849, -11.2909]
          ]
        ]
      },
      properties: {
        name: 'Cusco',
        id: 'PE-CUS',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-CUS'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.2578, -8.4808],
            [-77.1396, -8.4197],
            [-77.0173, -8.5295],
            [-76.586, -8.4722],
            [-76.4718, -8.5247],
            [-76.4015, -8.4109],
            [-76.2808, -8.4678],
            [-76.1779, -8.6953],
            [-76.0664, -8.6954],
            [-76.1115, -8.4022],
            [-75.9294, -8.2809],
            [-75.895, -8.3347],
            [-75.9338, -8.5071],
            [-75.9023, -8.616],
            [-75.9011, -8.6155],
            [-75.9202, -8.7905],
            [-75.7973, -9.1069],
            [-75.627, -9.3778],
            [-75.5746, -9.3941],
            [-75.4114, -9.2967],
            [-75.2763, -9.2894],
            [-75.1821, -9.201],
            [-75.1873, -9.097],
            [-75.1143, -8.932],
            [-74.9779, -8.9096],
            [-74.9046, -8.9617],
            [-74.815, -8.6668],
            [-74.7239, -8.6269],
            [-74.5736, -8.6275],
            [-74.5597, -8.7853],
            [-74.6345, -8.9744],
            [-74.6168, -9.1542],
            [-74.5539, -9.2842],
            [-74.6168, -9.3934],
            [-74.6155, -9.4077],
            [-74.7476, -9.5545],
            [-74.8366, -9.6999],
            [-74.8994, -9.7051],
            [-75.0878, -9.8505],
            [-75.2553, -9.757],
            [-75.4629, -9.8969],
            [-75.6636, -10.0633],
            [-75.763, -10.2136],
            [-75.8991, -10.2966],
            [-75.941, -10.4365],
            [-76.2603, -10.3795],
            [-76.3598, -10.3069],
            [-76.5115, -10.3277],
            [-76.7473, -10.4469],
            [-76.9315, -10.2868],
            [-76.8727, -10.1618],
            [-76.8936, -10.0943],
            [-76.8151, -10.0062],
            [-76.836, -9.8142],
            [-76.9979, -9.6742],
            [-76.8524, -9.3101],
            [-76.7261, -9.2946],
            [-76.6895, -9.175],
            [-77.1105, -8.9307],
            [-77.2007, -8.7894],
            [-77.1972, -8.6597],
            [-77.2663, -8.6174],
            [-77.2578, -8.4808]
          ]
        ]
      },
      properties: {
        name: 'Huánuco',
        id: 'PE-HUC',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-HUC'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1053, -14.0315],
            [-75.3129, -14.0344],
            [-75.5271, -13.9047],
            [-75.5624, -13.7543],
            [-75.5545, -13.5746],
            [-75.4819, -13.4786],
            [-75.4804, -13.3641],
            [-75.5746, -13.3384],
            [-75.6653, -13.4069],
            [-75.7763, -13.4117],
            [-75.8625, -13.3486],
            [-75.7787, -13.2205],
            [-75.7546, -13.0247],
            [-75.6113, -12.8202],
            [-75.5757, -12.6121],
            [-75.4961, -12.6121],
            [-75.1611, -12.3034],
            [-75.0617, -12.149],
            [-75.0512, -12.0305],
            [-74.8597, -11.9739],
            [-74.5644, -11.9893],
            [-74.3655, -12.0717],
            [-74.3593, -12.1947],
            [-74.5041, -12.2542],
            [-74.5376, -12.3441],
            [-74.4295, -12.4727],
            [-74.3102, -12.9545],
            [-74.4388, -13.0153],
            [-74.4859, -13.1076],
            [-74.622, -13.1846],
            [-74.7319, -13.3128],
            [-74.897, -13.3414],
            [-74.9295, -13.4501],
            [-74.7901, -13.5878],
            [-74.7999, -13.8811],
            [-74.8575, -14.0242],
            [-74.9675, -14.0549],
            [-75.1053, -14.0315]
          ]
        ]
      },
      properties: {
        name: 'Huancavelica',
        id: 'PE-HUV',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-HUV'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7546, -13.0247],
            [-75.7787, -13.2205],
            [-75.8625, -13.3486],
            [-75.7763, -13.4117],
            [-75.6653, -13.4069],
            [-75.5746, -13.3384],
            [-75.4804, -13.3641],
            [-75.4819, -13.4786],
            [-75.5545, -13.5746],
            [-75.5624, -13.7543],
            [-75.5271, -13.9047],
            [-75.3129, -14.0344],
            [-75.1053, -14.0315],
            [-75.1245, -14.3255],
            [-75.1506, -14.3868],
            [-75.0931, -14.606],
            [-74.8785, -14.6264],
            [-74.7633, -14.8455],
            [-74.6969, -14.9013],
            [-74.7266, -15.0881],
            [-74.8284, -15.0982],
            [-74.968, -15.1711],
            [-75.0465, -15.2676],
            [-75.1011, -15.417],
            [-75.2306, -15.2228],
            [-75.3956, -15.0883],
            [-75.5479, -14.8895],
            [-75.8397, -14.7269],
            [-75.9339, -14.6519],
            [-75.9854, -14.4721],
            [-76.0864, -14.3767],
            [-76.1406, -14.2303],
            [-76.3058, -14.1264],
            [-76.2996, -13.9027],
            [-76.202, -13.6381],
            [-76.1815, -13.4579],
            [-76.2587, -13.267],
            [-76.1802, -13.1436],
            [-75.9237, -12.9793],
            [-75.7546, -13.0247]
          ]
        ]
      },
      properties: { name: 'Ica', id: 'PE-ICA', CNTRY: 'Peru', TYPE: 'Region' },
      id: 'PE-ICA'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.5087, -10.8482],
            [-74.4827, -10.9708],
            [-74.4126, -10.9956],
            [-74.0261, -10.9686],
            [-73.9886, -11.1093],
            [-73.8892, -11.13],
            [-73.8212, -11.0369],
            [-73.818, -10.8483],
            [-73.766, -10.753],
            [-73.6379, -10.7915],
            [-73.5618, -10.873],
            [-73.549, -11.0292],
            [-73.4495, -11.0809],
            [-73.4352, -11.3242],
            [-73.5453, -11.5324],
            [-73.5858, -11.6753],
            [-73.5752, -11.814],
            [-73.4924, -11.8473],
            [-73.371, -12.082],
            [-73.4181, -12.1438],
            [-73.6432, -12.3137],
            [-73.7741, -12.2056],
            [-73.9472, -12.2385],
            [-73.9468, -12.2314],
            [-74.1509, -12.2623],
            [-74.3593, -12.1947],
            [-74.3655, -12.0717],
            [-74.5644, -11.9893],
            [-74.8597, -11.9739],
            [-75.0512, -12.0305],
            [-75.0617, -12.149],
            [-75.1611, -12.3034],
            [-75.4961, -12.6121],
            [-75.5757, -12.6121],
            [-75.6065, -12.4089],
            [-75.5867, -12.3077],
            [-75.6503, -12.1911],
            [-75.6636, -11.997],
            [-75.8442, -12.0069],
            [-76.0572, -11.9358],
            [-76.2118, -11.7408],
            [-76.2508, -11.5643],
            [-76.3528, -11.5484],
            [-76.4829, -11.2988],
            [-76.4951, -11.0564],
            [-76.3754, -11.0628],
            [-76.2917, -11.1196],
            [-76.1713, -11.0576],
            [-76.0247, -11.0369],
            [-76.0247, -10.8093],
            [-75.9253, -10.8507],
            [-75.7735, -10.8196],
            [-75.5066, -10.711],
            [-75.4333, -10.7925],
            [-75.339, -10.794],
            [-75.1454, -10.6799],
            [-74.9465, -10.6851],
            [-74.6316, -10.8498],
            [-74.5087, -10.8482]
          ]
        ]
      },
      properties: {
        name: 'Junin',
        id: 'PE-JUN',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-JUN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1396, -8.4197],
            [-77.2578, -8.4808],
            [-77.3256, -8.4958],
            [-77.4889, -8.2358],
            [-77.6159, -8.1414],
            [-77.6733, -8.0272],
            [-77.867, -8.0645],
            [-77.9474, -8.1806],
            [-78.1166, -8.3035],
            [-78.2543, -8.6586],
            [-78.5503, -8.7592],
            [-78.5985, -8.8999],
            [-78.6638, -8.9667],
            [-78.7261, -8.828],
            [-78.772, -8.5864],
            [-78.9256, -8.4169],
            [-78.9946, -8.2197],
            [-79.1767, -8.0261],
            [-79.3183, -7.9235],
            [-79.4284, -7.7564],
            [-79.4561, -7.657],
            [-79.5874, -7.4625],
            [-79.6489, -7.2408],
            [-79.7089, -7.1815],
            [-79.4795, -6.9585],
            [-79.3477, -7.0104],
            [-79.3372, -7.2074],
            [-79.2874, -7.3793],
            [-79.0999, -7.4803],
            [-79.0581, -7.5929],
            [-78.9408, -7.5701],
            [-78.8266, -7.4473],
            [-78.7124, -7.3859],
            [-78.563, -7.5262],
            [-78.4048, -7.4999],
            [-78.3521, -7.535],
            [-78.3471, -7.6746],
            [-78.2949, -7.7519],
            [-78.0885, -7.6315],
            [-77.9385, -7.6692],
            [-77.7531, -7.4677],
            [-77.8106, -7.2639],
            [-77.9363, -6.9973],
            [-77.778, -6.9523],
            [-77.7731, -6.9669],
            [-77.5969, -7.2022],
            [-77.6289, -7.2824],
            [-77.5395, -7.4636],
            [-77.5018, -7.8046],
            [-77.3814, -7.8515],
            [-77.3539, -8.0257],
            [-77.1571, -7.9732],
            [-76.9902, -8.0345],
            [-76.9023, -8.1659],
            [-76.891, -8.325],
            [-77.0956, -8.3759],
            [-77.1396, -8.4197]
          ]
        ]
      },
      properties: {
        name: 'La Libertad',
        id: 'PE-LAL',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-LAL'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4297, -5.9764],
            [-79.353, -5.9157],
            [-79.2147, -6.0421],
            [-79.2482, -6.2063],
            [-79.4667, -6.385],
            [-79.4508, -6.519],
            [-79.3162, -6.679],
            [-79.1739, -6.707],
            [-79.1669, -6.7682],
            [-79.3154, -6.884],
            [-79.3477, -7.0104],
            [-79.4795, -6.9585],
            [-79.7089, -7.1815],
            [-79.715, -7.1167],
            [-79.9465, -6.8707],
            [-79.98, -6.7686],
            [-80.1164, -6.6454],
            [-80.528, -6.4271],
            [-80.6083, -6.3594],
            [-80.4508, -6.0986],
            [-80.3112, -5.9453],
            [-80.1853, -5.9023],
            [-79.9837, -5.6926],
            [-79.9492, -5.5454],
            [-79.7943, -5.6646],
            [-79.7265, -5.8115],
            [-79.4297, -5.9764]
          ]
        ]
      },
      properties: {
        name: 'Lambayeque',
        id: 'PE-LAM',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-LAM'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.5757, -12.6121],
            [-75.6113, -12.8202],
            [-75.7546, -13.0247],
            [-75.9237, -12.9793],
            [-76.1802, -13.1436],
            [-76.2587, -13.267],
            [-76.2991, -13.2634],
            [-76.4922, -13.0461],
            [-76.52, -12.9036],
            [-76.6372, -12.7494],
            [-76.787, -12.4435],
            [-76.7575, -12.3214],
            [-76.6633, -12.1773],
            [-76.6581, -12.0485],
            [-76.7732, -11.8888],
            [-76.8465, -11.8579],
            [-76.8046, -11.7186],
            [-77.0297, -11.4967],
            [-77.0978, -11.4812],
            [-77.2388, -11.6074],
            [-77.3745, -11.451],
            [-77.6477, -11.2978],
            [-77.6109, -11.1803],
            [-77.6794, -10.9342],
            [-77.7833, -10.75],
            [-77.8946, -10.6125],
            [-77.8174, -10.5574],
            [-77.7838, -10.3683],
            [-77.7049, -10.2914],
            [-77.6002, -10.3691],
            [-77.6826, -10.4689],
            [-77.6003, -10.5772],
            [-77.5885, -10.7486],
            [-77.4232, -10.7195],
            [-77.1786, -10.4953],
            [-77.1254, -10.3607],
            [-76.9315, -10.2868],
            [-76.7473, -10.4469],
            [-76.5772, -10.8172],
            [-76.5613, -11.0004],
            [-76.4951, -11.0564],
            [-76.4829, -11.2988],
            [-76.3528, -11.5484],
            [-76.2508, -11.5643],
            [-76.2118, -11.7408],
            [-76.0572, -11.9358],
            [-75.8442, -12.0069],
            [-75.6636, -11.997],
            [-75.6503, -12.1911],
            [-75.5867, -12.3077],
            [-75.6065, -12.4089],
            [-75.5757, -12.6121]
          ]
        ]
      },
      properties: { name: 'Lima', id: 'PE-LIM', CNTRY: 'Peru', TYPE: 'Region' },
      id: 'PE-LIM'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.787, -12.4435],
            [-76.7997, -12.3903],
            [-77.0279, -12.1501],
            [-76.9774, -12.0795],
            [-77.0768, -11.8939],
            [-77.0821, -11.7135],
            [-77.1856, -11.7059],
            [-77.2388, -11.6074],
            [-77.0978, -11.4812],
            [-77.0297, -11.4967],
            [-76.8046, -11.7186],
            [-76.8465, -11.8579],
            [-76.7732, -11.8888],
            [-76.6581, -12.0485],
            [-76.6633, -12.1773],
            [-76.7575, -12.3214],
            [-76.787, -12.4435]
          ]
        ]
      },
      properties: {
        name: 'Metropolitan Municipality de Lima',
        id: 'PE-LI',
        CNTRY: 'Peru',
        TYPE: 'Municipality'
      },
      id: 'PE-LI'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9872, -7.5288],
            [-74.1562, -7.3606],
            [-74.4545, -7.3084],
            [-74.5785, -7.4779],
            [-74.5514, -7.8505],
            [-74.6482, -7.9924],
            [-74.8366, -8.0342],
            [-75.1088, -7.9872],
            [-75.2338, -8.1199],
            [-75.332, -8.105],
            [-75.4176, -8.1959],
            [-75.4228, -8.4076],
            [-75.5112, -8.4478],
            [-75.6364, -8.3832],
            [-75.8656, -8.6],
            [-75.9023, -8.616],
            [-75.9338, -8.5071],
            [-75.895, -8.3347],
            [-75.9358, -8.2709],
            [-75.9182, -8.1396],
            [-75.8181, -8.0088],
            [-75.922, -7.9382],
            [-76.0011, -7.7976],
            [-76.0535, -7.5407],
            [-76.1346, -7.4598],
            [-76.1595, -7.2352],
            [-75.9885, -7.1051],
            [-76.0149, -6.8066],
            [-75.9664, -6.7582],
            [-75.8206, -6.7761],
            [-75.7569, -6.8288],
            [-75.5203, -6.7916],
            [-75.4934, -6.6836],
            [-75.4787, -6.3041],
            [-75.4262, -6.1651],
            [-75.5667, -6.0507],
            [-75.7553, -6.1271],
            [-75.9934, -6.1082],
            [-76.1467, -6.209],
            [-76.217, -6.1739],
            [-76.3839, -5.9979],
            [-76.4743, -5.9889],
            [-76.6201, -6.0536],
            [-76.7193, -5.9983],
            [-76.8217, -5.7706],
            [-76.9736, -5.6846],
            [-77.26, -5.6522],
            [-77.3719, -5.5267],
            [-77.5419, -5.465],
            [-77.64, -5.3847],
            [-77.7303, -5.1562],
            [-77.8337, -5.0735],
            [-77.7692, -4.9303],
            [-77.7422, -4.7033],
            [-77.5781, -4.4405],
            [-77.5578, -3.9042],
            [-77.6339, -3.77],
            [-77.6764, -3.4492],
            [-77.767, -3.2613],
            [-77.8102, -2.9682],
            [-76.6606, -2.5721],
            [-76.0742, -2.1311],
            [-75.5536, -1.5139],
            [-75.4022, -0.9228],
            [-75.3304, -0.9797],
            [-75.2168, -0.9694],
            [-75.2928, -0.7306],
            [-75.249, -0.6296],
            [-75.2625, -0.5231],
            [-75.4133, -0.4196],
            [-75.4811, -0.2324],
            [-75.6245, -0.172],
            [-75.6158, -0.1065],
            [-75.425, -0.1643],
            [-75.137, -0.0453],
            [-74.9381, -0.1989],
            [-74.7724, -0.2079],
            [-74.7088, -0.3617],
            [-74.6075, -0.3836],
            [-74.3939, -0.5433],
            [-74.3161, -0.7817],
            [-74.2478, -0.8536],
            [-74.2828, -0.9303],
            [-74.2154, -1.0349],
            [-74.0778, -1.0728],
            [-73.868, -1.2086],
            [-73.841, -1.2651],
            [-73.6536, -1.2844],
            [-73.5557, -1.3753],
            [-73.5428, -1.4681],
            [-73.4471, -1.6104],
            [-73.5075, -1.7483],
            [-73.297, -1.8736],
            [-73.197, -1.8065],
            [-73.0904, -1.9236],
            [-73.0582, -2.1455],
            [-73.1318, -2.2463],
            [-73.1153, -2.3288],
            [-72.882, -2.5064],
            [-72.5901, -2.4072],
            [-72.2275, -2.4989],
            [-71.9822, -2.3694],
            [-71.8898, -2.3778],
            [-71.8085, -2.2308],
            [-71.5201, -2.2346],
            [-71.3617, -2.347],
            [-71.1881, -2.3606],
            [-70.859, -2.2253],
            [-70.7075, -2.3253],
            [-70.6147, -2.3253],
            [-70.3638, -2.5031],
            [-70.2954, -2.5013],
            [-70.2089, -2.6094],
            [-70.0308, -2.5737],
            [-70.0675, -2.7556],
            [-70.7242, -3.7797],
            [-70.528, -3.8592],
            [-70.3231, -3.7992],
            [-70.1819, -3.9374],
            [-70.1231, -4.0724],
            [-69.9567, -4.2094],
            [-70.017, -4.3379],
            [-70.1978, -4.3327],
            [-70.3014, -4.25],
            [-70.3202, -4.1397],
            [-70.4294, -4.132],
            [-70.5841, -4.1956],
            [-70.7624, -4.1477],
            [-70.8322, -4.187],
            [-70.9562, -4.3822],
            [-71.0525, -4.4351],
            [-71.1648, -4.4351],
            [-71.2659, -4.4914],
            [-71.5578, -4.5703],
            [-71.8497, -4.559],
            [-72.1528, -4.8069],
            [-72.2988, -4.8632],
            [-72.4559, -4.9645],
            [-72.6131, -5.1334],
            [-72.7591, -5.1897],
            [-72.8714, -5.3698],
            [-72.9387, -5.7522],
            [-73.0734, -5.8647],
            [-73.197, -6.067],
            [-73.051, -6.4714],
            [-73.197, -6.6174],
            [-73.7022, -6.8643],
            [-73.7471, -7.0999],
            [-73.7382, -7.334],
            [-73.9311, -7.3592],
            [-73.9872, -7.5288]
          ]
        ]
      },
      properties: {
        name: 'Loreto',
        id: 'PE-LOR',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-LOR'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.8894, -12.9179],
            [-69.0502, -13.0256],
            [-69.4742, -13.1897],
            [-69.5318, -13.2513],
            [-69.7804, -13.276],
            [-69.9819, -13.241],
            [-70.2279, -13.123],
            [-70.4377, -13.1191],
            [-70.4373, -13.1128],
            [-70.5891, -13.1025],
            [-70.8874, -13.0307],
            [-71.034, -13.1692],
            [-71.1439, -13.2],
            [-71.2172, -12.9383],
            [-71.4527, -12.8407],
            [-71.5679, -12.6814],
            [-71.9185, -12.7431],
            [-71.9761, -12.702],
            [-71.9918, -12.5478],
            [-72.0965, -12.4141],
            [-71.9761, -12.27],
            [-72.0546, -12.2339],
            [-72.1436, -12.0846],
            [-72.3216, -11.9764],
            [-72.3659, -11.7907],
            [-72.4524, -11.6825],
            [-72.3059, -11.4967],
            [-72.2849, -11.2909],
            [-72.2009, -11.2056],
            [-72.1628, -11.0644],
            [-72.1914, -10.9843],
            [-71.9042, -10.9908],
            [-71.4188, -10.9749],
            [-71.3139, -10.8759],
            [-71.2472, -10.5835],
            [-71.147, -10.4749],
            [-71.0373, -10.4325],
            [-70.8417, -10.2373],
            [-70.7846, -10.2106],
            [-70.6287, -9.9654],
            [-70.6314, -11.0092],
            [-70.5264, -10.9357],
            [-70.4519, -11.008],
            [-70.013, -10.9551],
            [-69.926, -10.914],
            [-69.7379, -10.9616],
            [-69.5684, -10.9511],
            [-68.9253, -11.9983],
            [-68.6739, -12.5012],
            [-68.8156, -12.7331],
            [-68.8795, -12.7575],
            [-68.8894, -12.9179]
          ]
        ]
      },
      properties: {
        name: 'Madre de Dios',
        id: 'PE-MDD',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-MDD'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1369, -16.9538],
            [-70.1341, -16.9584],
            [-70.2174, -16.9752],
            [-70.2593, -16.8341],
            [-70.4028, -16.8171],
            [-70.4681, -16.8753],
            [-70.4736, -17.0628],
            [-70.5931, -17.2003],
            [-70.72, -17.2761],
            [-70.837, -17.4953],
            [-70.9083, -17.684],
            [-71.167, -17.8094],
            [-71.3017, -17.7117],
            [-71.3567, -17.622],
            [-71.3993, -17.3994],
            [-71.5839, -17.2574],
            [-71.3705, -17.1645],
            [-71.3419, -17.06],
            [-71.4507, -16.8526],
            [-71.3096, -16.7406],
            [-71.3096, -16.6285],
            [-71.1648, -16.4506],
            [-71.1805, -16.3344],
            [-70.9921, -16.4304],
            [-70.8663, -16.2482],
            [-70.8501, -15.9883],
            [-70.6571, -15.9904],
            [-70.4201, -16.0489],
            [-70.3558, -16.1967],
            [-70.3079, -16.4631],
            [-70.1324, -16.6368],
            [-70.0986, -16.7645],
            [-70.1369, -16.9538]
          ]
        ]
      },
      properties: {
        name: 'Moquegua',
        id: 'PE-MOQ',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-MOQ'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4951, -11.0564],
            [-76.5613, -11.0004],
            [-76.5772, -10.8172],
            [-76.7473, -10.4469],
            [-76.5115, -10.3277],
            [-76.3598, -10.3069],
            [-76.2603, -10.3795],
            [-75.941, -10.4365],
            [-75.8991, -10.2966],
            [-75.763, -10.2136],
            [-75.6636, -10.0633],
            [-75.4629, -9.8969],
            [-75.2553, -9.757],
            [-75.0878, -9.8505],
            [-74.8994, -9.7051],
            [-74.8366, -9.6999],
            [-74.7476, -9.5545],
            [-74.6155, -9.4077],
            [-74.6115, -9.4505],
            [-74.5173, -9.5441],
            [-74.471, -9.7602],
            [-74.4074, -9.8453],
            [-74.3812, -10.0114],
            [-74.2713, -10.1307],
            [-74.2033, -10.141],
            [-74.2451, -10.3432],
            [-74.1457, -10.4728],
            [-74.1675, -10.6011],
            [-74.2875, -10.6517],
            [-74.5087, -10.8482],
            [-74.6316, -10.8498],
            [-74.9465, -10.6851],
            [-75.1454, -10.6799],
            [-75.339, -10.794],
            [-75.4333, -10.7925],
            [-75.5066, -10.711],
            [-75.7735, -10.8196],
            [-75.9253, -10.8507],
            [-76.0247, -10.8093],
            [-76.0247, -11.0369],
            [-76.1713, -11.0576],
            [-76.2917, -11.1196],
            [-76.3754, -11.0628],
            [-76.4951, -11.0564]
          ]
        ]
      },
      properties: {
        name: 'Pasco',
        id: 'PE-PAS',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-PAS'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0879, -4.1028],
            [-81.0009, -4.2229],
            [-80.7241, -4.214],
            [-80.6239, -4.1592],
            [-80.5656, -4.1076],
            [-80.4709, -4.2318],
            [-80.4638, -4.4418],
            [-80.3906, -4.4786],
            [-80.2076, -4.3441],
            [-79.818, -4.4774],
            [-79.6142, -4.4859],
            [-79.5392, -4.5208],
            [-79.445, -4.7974],
            [-79.2857, -4.9646],
            [-79.2319, -4.9639],
            [-79.3938, -5.168],
            [-79.3237, -5.339],
            [-79.3181, -5.5046],
            [-79.4003, -5.5753],
            [-79.4202, -5.7352],
            [-79.3489, -5.9125],
            [-79.4297, -5.9764],
            [-79.7265, -5.8115],
            [-79.7943, -5.6646],
            [-79.9492, -5.5454],
            [-79.9837, -5.6926],
            [-80.1853, -5.9023],
            [-80.3112, -5.9453],
            [-80.4508, -6.0986],
            [-80.6083, -6.3594],
            [-80.7373, -6.3991],
            [-81.1264, -6.1218],
            [-81.1705, -6.0281],
            [-81.1159, -5.9186],
            [-81.0027, -5.9069],
            [-80.9051, -5.7349],
            [-80.9129, -5.6333],
            [-81.0261, -5.426],
            [-81.2642, -5.2734],
            [-81.1315, -5.0269],
            [-81.1705, -4.9564],
            [-81.3891, -4.7489],
            [-81.3266, -4.3651],
            [-81.2577, -4.2514],
            [-81.0879, -4.1028]
          ]
        ]
      },
      properties: {
        name: 'Piura',
        id: 'PE-PIU',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-PIU'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.0745, -16.1589],
            [-68.9822, -16.2914],
            [-69.0414, -16.5476],
            [-69.0124, -16.657],
            [-69.192, -16.7781],
            [-69.3207, -16.9263],
            [-69.4064, -17.0722],
            [-69.5315, -17.1299],
            [-69.6562, -17.2872],
            [-69.7331, -17.2997],
            [-70.0342, -17.1215],
            [-70.1369, -16.9538],
            [-70.0986, -16.7645],
            [-70.1324, -16.6368],
            [-70.3079, -16.4631],
            [-70.3558, -16.1967],
            [-70.4201, -16.0489],
            [-70.6571, -15.9904],
            [-70.8501, -15.9883],
            [-70.8347, -15.9271],
            [-70.9378, -15.7913],
            [-70.8999, -15.6895],
            [-71.0139, -15.5617],
            [-71.0261, -15.4603],
            [-71.0242, -15.3792],
            [-70.9824, -15.3092],
            [-71.0208, -15.0034],
            [-70.9989, -14.8247],
            [-71.1491, -14.6519],
            [-71.0549, -14.5653],
            [-71.013, -14.4072],
            [-70.924, -14.2388],
            [-70.8926, -14.0498],
            [-70.8979, -13.8248],
            [-70.8141, -13.789],
            [-70.453, -13.3641],
            [-70.4477, -13.2564],
            [-70.4377, -13.1191],
            [-70.2279, -13.123],
            [-69.9819, -13.241],
            [-69.7804, -13.276],
            [-69.5318, -13.2513],
            [-69.4742, -13.1897],
            [-69.0502, -13.0256],
            [-68.8894, -12.9179],
            [-68.8766, -13.1673],
            [-68.9598, -13.3872],
            [-68.9792, -13.5661],
            [-69.0628, -13.7078],
            [-68.9751, -13.9662],
            [-68.919, -14.0179],
            [-68.9084, -14.1652],
            [-69.0292, -14.2272],
            [-69.0483, -14.345],
            [-69.3154, -14.6671],
            [-69.3599, -14.76],
            [-69.3814, -14.9648],
            [-69.1371, -15.2276],
            [-69.2683, -15.3269],
            [-69.3387, -15.4517],
            [-69.5175, -15.3952],
            [-69.5531, -15.326],
            [-69.6815, -15.3122],
            [-69.7813, -15.1668],
            [-70.0095, -15.1044],
            [-70.1664, -15.1114],
            [-70.1664, -15.1945],
            [-69.9525, -15.1945],
            [-69.8954, -15.3122],
            [-69.9881, -15.3814],
            [-69.924, -15.5681],
            [-70.1093, -15.6855],
            [-70.0523, -15.8996],
            [-69.9525, -15.8927],
            [-69.7599, -15.9893],
            [-69.5246, -16.0237],
            [-69.5888, -16.1961],
            [-69.5318, -16.2512],
            [-69.3892, -16.2098],
            [-69.3393, -16.2856],
            [-69.1681, -16.265],
            [-69.0745, -16.1589]
          ]
        ]
      },
      properties: { name: 'Puno', id: 'PE-PUN', CNTRY: 'Peru', TYPE: 'Region' },
      id: 'PE-PUN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9358, -8.2709],
            [-75.9294, -8.2809],
            [-76.1115, -8.4022],
            [-76.0664, -8.6954],
            [-76.1779, -8.6953],
            [-76.2808, -8.4678],
            [-76.4015, -8.4109],
            [-76.4718, -8.5247],
            [-76.586, -8.4722],
            [-77.0173, -8.5295],
            [-77.1396, -8.4197],
            [-77.0956, -8.3759],
            [-76.891, -8.325],
            [-76.9023, -8.1659],
            [-76.9902, -8.0345],
            [-77.1571, -7.9732],
            [-77.3539, -8.0257],
            [-77.3814, -7.8515],
            [-77.5018, -7.8046],
            [-77.5395, -7.4636],
            [-77.6289, -7.2824],
            [-77.5969, -7.2022],
            [-77.7731, -6.9669],
            [-77.7853, -6.9308],
            [-77.7375, -6.7111],
            [-77.6843, -6.6661],
            [-77.5262, -6.6661],
            [-77.4559, -6.7188],
            [-77.3504, -6.6309],
            [-77.3129, -6.4921],
            [-77.1592, -6.3254],
            [-77.2805, -6.0933],
            [-77.5261, -6.0256],
            [-77.5939, -5.9678],
            [-77.7263, -5.7011],
            [-77.7172, -5.4908],
            [-77.64, -5.3847],
            [-77.5419, -5.465],
            [-77.3719, -5.5267],
            [-77.26, -5.6522],
            [-76.9736, -5.6846],
            [-76.8217, -5.7706],
            [-76.7193, -5.9983],
            [-76.6201, -6.0536],
            [-76.4743, -5.9889],
            [-76.3839, -5.9979],
            [-76.217, -6.1739],
            [-76.1467, -6.209],
            [-75.9934, -6.1082],
            [-75.7553, -6.1271],
            [-75.5667, -6.0507],
            [-75.4262, -6.1651],
            [-75.4787, -6.3041],
            [-75.4934, -6.6836],
            [-75.5203, -6.7916],
            [-75.7569, -6.8288],
            [-75.8206, -6.7761],
            [-75.9664, -6.7582],
            [-76.0149, -6.8066],
            [-75.9885, -7.1051],
            [-76.1595, -7.2352],
            [-76.1346, -7.4598],
            [-76.0535, -7.5407],
            [-76.0011, -7.7976],
            [-75.922, -7.9382],
            [-75.8181, -8.0088],
            [-75.9182, -8.1396],
            [-75.9358, -8.2709]
          ]
        ]
      },
      properties: {
        name: 'San Martin',
        id: 'PE-SAM',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-SAM'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1341, -16.9584],
            [-70.0342, -17.1215],
            [-69.7331, -17.2997],
            [-69.6562, -17.2872],
            [-69.5011, -17.3789],
            [-69.4997, -17.5053],
            [-69.7045, -17.6815],
            [-69.7987, -17.7267],
            [-69.7359, -18.0574],
            [-69.8301, -18.2375],
            [-69.9295, -18.3276],
            [-70.3588, -18.3875],
            [-70.5231, -18.2489],
            [-70.6496, -18.1936],
            [-71.0161, -17.882],
            [-71.167, -17.8094],
            [-70.9083, -17.684],
            [-70.837, -17.4953],
            [-70.72, -17.2761],
            [-70.5931, -17.2003],
            [-70.4736, -17.0628],
            [-70.4681, -16.8753],
            [-70.4028, -16.8171],
            [-70.2593, -16.8341],
            [-70.2174, -16.9752],
            [-70.1341, -16.9584]
          ]
        ]
      },
      properties: {
        name: 'Tacna',
        id: 'PE-TAC',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-TAC'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6239, -4.1592],
            [-80.7241, -4.214],
            [-81.0009, -4.2229],
            [-81.0879, -4.1028],
            [-80.9885, -3.9603],
            [-80.8826, -3.8913],
            [-80.8172, -3.7468],
            [-80.6022, -3.6201],
            [-80.5323, -3.5102],
            [-80.3764, -3.4728],
            [-80.2913, -3.4999],
            [-80.2324, -3.7927],
            [-80.2707, -3.9228],
            [-80.3563, -3.9936],
            [-80.4654, -3.9853],
            [-80.6239, -4.1592]
          ]
        ]
      },
      properties: {
        name: 'Tumbes',
        id: 'PE-TUM',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-TUM'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.9872, -7.5288],
            [-73.8215, -7.7317],
            [-73.6938, -7.8033],
            [-73.7563, -7.9671],
            [-73.6517, -8.0167],
            [-73.5957, -8.1214],
            [-73.5332, -8.3524],
            [-73.4047, -8.4505],
            [-73.3457, -8.6024],
            [-73.1839, -8.7001],
            [-73.0588, -8.9024],
            [-72.9624, -8.9885],
            [-72.9664, -9.143],
            [-73.2007, -9.4008],
            [-72.73, -9.4131],
            [-72.4022, -9.4836],
            [-72.3015, -9.5332],
            [-72.2525, -9.6569],
            [-72.2604, -9.7592],
            [-72.1508, -9.887],
            [-72.1858, -10.0025],
            [-71.3681, -10.0039],
            [-71.1804, -9.9322],
            [-71.1386, -9.8638],
            [-71.0139, -9.8208],
            [-70.8819, -9.67],
            [-70.5886, -9.4409],
            [-70.5953, -9.7814],
            [-70.6287, -9.9654],
            [-70.7846, -10.2106],
            [-70.8417, -10.2373],
            [-71.0373, -10.4325],
            [-71.147, -10.4749],
            [-71.2472, -10.5835],
            [-71.3139, -10.8759],
            [-71.4188, -10.9749],
            [-71.9042, -10.9908],
            [-72.1914, -10.9843],
            [-72.1628, -11.0644],
            [-72.2009, -11.2056],
            [-72.2849, -11.2909],
            [-72.4099, -11.3278],
            [-72.5747, -11.4338],
            [-72.8339, -11.375],
            [-72.9685, -11.244],
            [-73.1239, -11.2939],
            [-73.4352, -11.3242],
            [-73.4495, -11.0809],
            [-73.549, -11.0292],
            [-73.5618, -10.873],
            [-73.6379, -10.7915],
            [-73.766, -10.753],
            [-73.818, -10.8483],
            [-73.8212, -11.0369],
            [-73.8892, -11.13],
            [-73.9886, -11.1093],
            [-74.0261, -10.9686],
            [-74.4126, -10.9956],
            [-74.4827, -10.9708],
            [-74.5087, -10.8482],
            [-74.2875, -10.6517],
            [-74.1675, -10.6011],
            [-74.1457, -10.4728],
            [-74.2451, -10.3432],
            [-74.2033, -10.141],
            [-74.2713, -10.1307],
            [-74.3812, -10.0114],
            [-74.4074, -9.8453],
            [-74.471, -9.7602],
            [-74.5173, -9.5441],
            [-74.6115, -9.4505],
            [-74.6168, -9.3934],
            [-74.5539, -9.2842],
            [-74.6168, -9.1542],
            [-74.6345, -8.9744],
            [-74.5597, -8.7853],
            [-74.5736, -8.6275],
            [-74.7239, -8.6269],
            [-74.815, -8.6668],
            [-74.9046, -8.9617],
            [-74.9779, -8.9096],
            [-75.1143, -8.932],
            [-75.1873, -9.097],
            [-75.1821, -9.201],
            [-75.2763, -9.2894],
            [-75.4114, -9.2967],
            [-75.5746, -9.3941],
            [-75.627, -9.3778],
            [-75.7973, -9.1069],
            [-75.9202, -8.7905],
            [-75.9011, -8.6155],
            [-75.8656, -8.6],
            [-75.6364, -8.3832],
            [-75.5112, -8.4478],
            [-75.4228, -8.4076],
            [-75.4176, -8.1959],
            [-75.332, -8.105],
            [-75.2338, -8.1199],
            [-75.1088, -7.9872],
            [-74.8366, -8.0342],
            [-74.6482, -7.9924],
            [-74.5514, -7.8505],
            [-74.5785, -7.4779],
            [-74.4545, -7.3084],
            [-74.1562, -7.3606],
            [-73.9872, -7.5288]
          ]
        ]
      },
      properties: {
        name: 'Ucayali',
        id: 'PE-UCA',
        CNTRY: 'Peru',
        TYPE: 'Region'
      },
      id: 'PE-UCA'
    },

  ]
}
export default map
