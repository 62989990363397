import React, { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'
import { cArea117MapReferences } from '../../config/constants'

import useGqlEntriesPnc from '../../gql/useGqlEntriesPnc'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import useGqlTextComponents from '../../gql/useGqlTextComponents'
import MainLayout from '../../layouts/MainLayout'

import '../../pages/Area.css'

import AreaContent from '../components/Areas/AreaContent'
import BarChart from '../components/Areas/A1-17/BarChart'
import LineChart2 from '../components/Areas/A1-17/LineChart2'
import PieChart from '../components/Areas/A1-17/PieChart'
import IntroSection from '../components/Areas/IntroSection'
import LatestUpdates from '../components/Areas/LatestUpdates'
import MapContainer from '../components/Map/MapContainer'
import microstates from '../components/Map/microstates.json'
import MapTooltip from '../components/StyledMapToolTips.js'
import TableContainer from '../components/Table/TableContainer'
import TalliesSection from '../components/TalliesSection/TalliesSection'
import MainSpinner from '../components/ui/MainSpinner'
import { a117columns, citeA117 } from '../config/a117columns'

export default function Area117() {
  const htmlReferences = useRef()
  const [references, setReferences] = useState([])
  const [tableEntries, setTableEntries] = useState([])
  const [talliesNumbers, setTalliesNumbers] = useState([])
  const [mapLoaded, setMapLoaded] = useState(false)
  const { textComponents } = useGqlTextComponents('A1-17')
  const { entriesPnc, loading, error } = useGqlEntriesPnc()
  const {
    jurisdictions,
    loading: loadingJur,
    error: errorJur,
    jurisdictionsById
  } = useGqlJurisdictions()
  const { t, i18n } = useTranslation()

  const tooltip = e => {
    return ReactDOMServer.renderToStaticMarkup()
  }

  useEffect(() => {
    if (!loading && !error && jurisdictions && entriesPnc) {
      let referencesTmp = []

      for (let j of jurisdictions) {
        let entry = entriesPnc.find(e => e.motherEntry.jurisdiction.id === j.id)

        if (entry) referencesTmp.push(entry)
      }
      referencesTmp = entriesPnc

      setReferences(
        referencesTmp.map(e => {
          return {
            id:
              e.motherEntry.subjurisdiction && e.motherEntry.subjurisdiction.id
                ? e.motherEntry.subjurisdiction.id
                : e.motherEntry.jurisdiction.a2_code,
            regions: e.motherEntry.jurisdiction.regions,
            microstate: microstates.find(
              ms => ms.A2 === e.motherEntry.jurisdiction.a2_code
            ),
            reference: cArea117MapReferences().find(
              ml => ml.id === parseInt(e.pnc_type.id)
            ).color,
            reference_text: 'test',
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <MapTooltip
                entry={e}
                title={t('Restrictions on interventions on intersex minors')}
                area={'A1-17'}
                positive={e.pnc_type.id === '2'}
                extraProps={{
                  name: cArea117MapReferences().find(
                    ml => ml.id === parseInt(e.pnc_type.id)
                  ).name,
                  bgColor: cArea117MapReferences().find(
                    ml => ml.id === parseInt(e.pnc_type.id)
                  ).color
                }}
                hasSubjur={entriesPnc.find(
                  e2 =>
                    e2.motherEntry.jurisdiction.id ===
                      e.motherEntry.jurisdiction.id &&
                    e2.motherEntry.subjurisdiction
                )}
              />
            ), //tooltip(e),
            entry: e
          }
        })
      )

      let tmpTableEntries = []
      let tmpTallyNumbers = [0, 0, 0]

      for (let entry of entriesPnc) {
        let is_na = entry.pnc_type.id === '7'

        if (
          !entry.motherEntry.subjurisdiction &&
          entry.motherEntry.jurisdiction.un_member
        ) {
          if (entry.pnc_type.id === '2') {
            tmpTallyNumbers[0]++
          } else if (entry.pnc_type.id === '3') {
            tmpTallyNumbers[1]++
          } else if (entry.pnc_type.id === '1') {
            tmpTallyNumbers[2]++
          }
        }
        let subRows = []

        if (!is_na || !subRows.length) {
          tmpTableEntries.push({
            ...entry,
            subRowsLength: subRows.length
          })
          tmpTableEntries = tmpTableEntries.concat(subRows)
        } else {
          //Hack to prevent showing the N/A
          if (subRows.length) {
            subRows[0] = {
              ...subRows[0],
              cid: subRows[0].cid.split('.')[0],
              subRowsLength: subRows.length - 1
            }
            delete subRows[0].cid

            tmpTableEntries = tmpTableEntries.concat(subRows)
          }
        }
      }

      console.log(tmpTableEntries)

      setTableEntries(tmpTableEntries)
      setTalliesNumbers(tmpTallyNumbers)
    } else {
      console.log('BBBB', loading, !error, jurisdictions, entriesPnc)
    }
  }, [jurisdictions, entriesPnc, error, loading])

  const areaInfo = {
    title: `${t('Area')} 1`,
    subTitle: t('Legal Frameworks'),
    subSubTitle: `${t('Restrictions on interventions on intersex minors')}`,
    areaName: 'area-1'
  }

  return (
    <MainLayout section="areas">
      <div className="area__page area1__page">
        <section className="selectRegion__section">
          <MapContainer
            references={references}
            htmlReferences={htmlReferences}
            jurisdictionsById={jurisdictionsById}
            jurisdictions={jurisdictions}
            mapLegendItems={cArea117MapReferences()}
            mapLoaded={mapLoaded}
            setMapLoaded={setMapLoaded}
            currentArea={'A1-17'}
            title={areaInfo.title}
            subTitle={areaInfo.subTitle}
            subSubTitle={areaInfo.subSubTitle}
            areaName={areaInfo.areaName}
            modalTitle={areaInfo.subSubTitle}
            modalContent={textComponents.length && textComponents[0].map}
          />
        </section>
        <div className="content__container">
          <TalliesSection
            area={'A1-17'}
            canStart={mapLoaded}
            replaceNumbers={talliesNumbers}
          />

          <AreaContent areaIntro={'A1-17'} areaLatest={'A1-17'} />

          <div className="mainCTA__2charts">


            <section className="mainCTA__section40 mainGraphic__section pieChart__height-fix">
                <h5>{t('Restrictions on interventions on intersex minors')}</h5>
                <h4>{t('Regional breakdown (%)')}</h4>
                {references && references.length > 0 && jurisdictionsById ? (
                  <PieChart
                    references={references.filter(
                      r => !r.entry.motherEntry.subjurisdiction
                    )}
                    jurisdictions={jurisdictionsById}
                  />
                ) : (
                  <>
                    <MainSpinner text={t('Loading chart...')} />
                  </>
                )}
            </section>
            <section className="mainCTA__section60">
              <div className="mainGraphic__section">
                <h5>{t('Restrictions on interventions on intersex minors')}</h5>
                <h4>{t('Timeline')}</h4>

                {references && references.length > 0 ? (
                  <LineChart2 references={references} />
                ) : (
                  <MainSpinner text={t('Loading chart...')} />
                )}
              </div>
            </section>
          </div>
          <div className="mainCTA__charts">
            <section className="mainCTA__section">
              <div className="mainGraphic__section">
                <h5>{t('Restrictions on interventions on intersex minors')}</h5>
                <h4>{t('Regional breakdown (jurisdictions)')}</h4>
                {references && references.length > 0 ? (
                  <BarChart references={references} />
                ) : (
                  <>
                    <MainSpinner text={t('Loading chart...')} />
                  </>
                )}
              </div>
            </section>
          </div>
        </div>
        <section className="table__section">
          <div className="content__container">
            {entriesPnc && entriesPnc.length > 0 && (
              <TableContainer
                entries={tableEntries}
                setEntries={setTableEntries}
                t={t}
                columns={a117columns()}
                citeEntry={citeA117}
                emptyFieldsNoCite={['explan']}
                trMainClass={'a13Tr'}
                title={t('Area') + '1'}
                subtitle={
                  t('Legal Frameworks') +
                  ' | ' +
                  '<span style="font-weight:700">' +
                  t('Restrictions on interventions on intersex minors') +
                  '</span>'
                }
                paginate={false}
                initialSortBy={[
                  {
                    id: 'motherEntry.jurisdiction.name',
                    desc: false
                  }
                ]}
                showUNSwitch
              />
            )}
          </div>
        </section>
      </div>
    </MainLayout>
  )
}
