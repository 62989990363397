import { indexOf } from '@amcharts/amcharts4/.internal/core/utils/Array'
import React from 'react'
import { useRef } from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Accordion } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'
import NoteCheckIcon from '../components/icons/NoteCheckIcon'
import NoteRemoveIcon from '../components/icons/NoteRemoveIcon'
import SimpleTable from '../components/Table/SimpleTable'

export default function SubjurList({
  eEntry,
  jur,
  getColumns,
  entriesSplit,
  noData = () => {
    return null
  }
}) {
  const [t, i18n] = useTranslation()
  const [openSub, setOpenSub] = useState(null)
  const [openDep, setOpenDep] = useState(null)
  const [activeKey, setActiveKey] = useState('')

  const subsRef = useRef([])
  const depsRef = useRef([])
  const [subsOpen, setSubsOpen] = useState([])

  useEffect(() => {
    if (eEntry && eEntry.sub.length > 0) {
      subsRef.current = subsRef.current.slice(0, eEntry.sub.length)
    }
    if (eEntry && eEntry.dep.length > 0) {
      depsRef.current = depsRef.current.slice(0, eEntry.dep.length)
    }
  }, [eEntry])

  useEffect(() => {
    if (openSub !== null) {
      subsRef.current.forEach((el, i) => {
        el.children[0].click()
      })
      setOpenSub(null)
    }
  }, [openSub])

  useEffect(() => {
    if (openDep !== null) {
      depsRef.current.forEach((el, i) => {
        el.children[0].click()
      })
      setOpenDep(null)
    }
  }, [openDep])

  return (
    <>
      {eEntry && eEntry.sub.length > 0 && eEntry.showSub && (
        <>
          {eEntry &&
            eEntry.main?.motherEntry?.jurisdiction?.id === 'UN-184' &&
            !eEntry.isSubjurProfile && (
              <h4 className="jpia__title">
                {t('Constituent Countries of the UK')}
              </h4>
            )}
          {!eEntry.isSubjurProfile && (
            <div className="expandCollapse">
              {openSub ? (
                <span onClick={() => setOpenSub(false)}>Collapse all</span>
              ) : (
                <span onClick={() => setOpenSub(true)}>Expand all</span>
              )}
            </div>
          )}
          {eEntry.sub.map((entry, i) => (
            <Accordion
              defaultActiveKey={eEntry.isSubjurProfile && '1'}
              onSelect={e => {
                if (openSub !== null)
                  if ((e !== null && !openSub) || (e === null && openSub)) {
                    setTimeout(
                      () => subsRef.current[i].children[0].click(),
                      100
                    )
                  }
              }}
              key={i}
              className="subJur__box"
            >
              <Accordion.Item eventKey="1" key={i}>
                <Accordion.Header ref={el => (subsRef.current[i] = el)}>
                  <img
                    className="jpsb__countryFlag"
                    src={
                      '/images/flags_svg/' + jur.a2_code.toLowerCase() + '.svg'
                    }
                    alt="Country Flag"
                  />
                  {entry.motherEntry.subjurisdiction.name}
                </Accordion.Header>
                <Accordion.Body>
                  {noData(entry, entry.motherEntry.subjurisdiction) ? (
                    <div className="jpjs__countryBox">
                      <div className="noData">
                        <div>
                          {entry.motherEntry.entry_type_id === 'A1-3' ||
                          entry.motherEntry.entry_type_id === 'A1-4' ? (
                            <NoteCheckIcon size={'50px'} color="#aaa" />
                          ) : (
                            <NoteRemoveIcon size={'50px'} color="#aaa" />
                          )}
                        </div>
                        <div className="noDataText">
                          {noData(entry, entry.motherEntry.subjurisdiction)}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <SimpleTable
                      columns={getColumns(entry)}
                      entries={entriesSplit ? entriesSplit(entry) : [entry]}
                    />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </>
      )}
      {eEntry &&
        eEntry.dep.length > 0 &&
        eEntry.showDep &&
        !eEntry.isSubjurProfile && (
          <>
            {eEntry && eEntry.sub.length > 0 && (
              <h4 className="jpia__title">{t('Dependent Territories')}</h4>
            )}
            <div className="expandCollapse">
              {openDep ? (
                <span onClick={() => setOpenDep(false)}>Collapse all</span>
              ) : (
                <span onClick={() => setOpenDep(true)}>Expand all</span>
              )}
            </div>
            {eEntry.dep.map((entry, i) => (
              <Accordion
                className="subJur__box"
                key={entry.id}
                onSelect={e => {
                  if (openDep !== null)
                    if ((e !== null && !openDep) || (e === null && openDep)) {
                      setTimeout(
                        () => depsRef.current[i].children[0].click(),
                        100
                      )
                    }
                }}
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header ref={el => (depsRef.current[i] = el)}>
                    <img
                      className="jpsb__countryFlag"
                      src={
                        '/images/flags_svg/' +
                        jur.a2_code.toLowerCase() +
                        '.svg'
                      }
                      alt="Country Flag"
                    />
                    {entry.motherEntry.jurisdiction.name}
                  </Accordion.Header>
                  <Accordion.Body>
                    {noData(entry, entry.motherEntry.jurisdiction) ? (
                      <div className="jpjs__countryBox">
                        <div className="noData">
                          <div>
                            {entry.motherEntry.entry_type_id === 'A1-3' ||
                            entry.motherEntry.entry_type_id === 'A1-4' ? (
                              <NoteCheckIcon size={'50px'} color="#aaa" />
                            ) : (
                              <NoteRemoveIcon size={'50px'} color="#aaa" />
                            )}
                          </div>
                          <div className="noDataText">
                            {noData(entry, entry.motherEntry.jurisdiction)}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <SimpleTable
                        columns={getColumns(entry)}
                        entries={entriesSplit ? entriesSplit(entry) : [entry]}
                      />
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </>
        )}
    </>
  )
}
