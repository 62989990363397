import i18n from '../../config/i18n'
import routes from '../../config/routes'
import AutoTranslateCell from '../components/Table/AutoTranslateCell'
import JurCell from '../components/Table/JurCell'
import JurFilter, { ApplyJurFilter } from '../components/Table/JurFilter'
import SourceText from '../components/Table/SourceText'

const getBgColorClass = days => {
  if (days < 10) return 'upcomingFontRed'
  if (days < 30) return 'upcomingFontGreen'
  return 'upcomingFontBlue'
}

export const advancedSearchColumns = (setToast, history) => {
  const theRoutes = routes()
  return [
    {
      Header: i18n.t('Jurisdiction'),
      disableGroupBy: true,
      accessor: 'jurisdiction.name',
      enableRowSpan: true,
      filter: ApplyJurFilter,
      Filter: ({ column }) => {
        return <JurFilter column={column} search={true} />
      },
      Cell: ({ value, row }) => {
        return <JurCell row={row} extraClasses="asJurCell" />
      },
      CellSmall: ({ value, row }) => {
        return <div>{row.original.jurisdiction.name.substring(0, 3)}</div>
      },
      className: 'col170 jurTd'
    },
    {
      Header: i18n.t('Area'),
      disableGroupBy: true,
      accessor: 'area',
      enableRowSpan: true,
      Cell: ({ value, row }) => {
        if (value.charAt(1) === '1') {
          let area = i18n.t('Area') + ' 1 | ' + i18n.t('Legal Frameworks')

          let subarea = theRoutes.find(
            r =>
              r.id ===
              'a' +
                value.charAt(1) +
                (value.indexOf('-') !== -1
                  ? value.charAt(3) +
                    (!isNaN(value.charAt(4)) ? value.charAt(4) : '')
                  : '')
          )
          let subAreaN = subarea.name
          return (
            <>
              <strong style={{ display: 'block', marginBottom: '3px' }}>
                {area}
              </strong>
              {subAreaN}{' '}
              {subarea.id !== 'a118' &&
                (value.indexOf('-gi') !== -1
                  ? i18n.t('based on gender identity')
                  : value.indexOf('-ge') !== -1
                  ? i18n.t('based on gender expression')
                  : value.indexOf('-so') !== -1
                  ? i18n.t('based on sexual orientation')
                  : value.indexOf('-sc') !== -1
                  ? i18n.t('based on sex characteristics')
                  : '')}
            </>
          )
        } else if (value.charAt(1) === '3') {
          let area = i18n.t('Area') + ' 3 | ' + i18n.t('UN Treaty Bodies')

          let subarea = theRoutes.find(
            r =>
              r.id ===
              'a' +
                value.charAt(1) +
                (value.indexOf('-') !== -1 ? parseInt(value.charAt(3)) + 1 : '')
          ).name
          return (
            <>
              <strong style={{ display: 'block', marginBottom: '3px' }}>
                {area}
              </strong>
              {subarea}
            </>
          )
        } else if (value.charAt(1) === '4') {
          let area = i18n.t('Area') + ' 4 | ' + i18n.t('UN Special Procedures')
          const extra = JSON.parse(row.original.extra_data)
          return (
            <>
              <strong style={{ display: 'block', marginBottom: '3px' }}>
                {area}
              </strong>
              {extra.special_procedure_types &&
                extra.special_procedure_types.map(sp => (
                  <>
                    {sp['full_name_' + i18n.language]}
                    <br />
                  </>
                ))}
            </>
          )
        } else if (value.charAt(1) === '5') {
          const area =
            i18n.t('Area') + ' 5 | ' + i18n.t('Universal Periodic Review (UPR)')

          return (
            <>
              <strong style={{ display: 'block', marginBottom: '3px' }}>
                {area}
              </strong>
              {row.original.subarea}
            </>
          )
        }
      },
      className: 'textCenter',
      style: { maxWidth: '178px', width: '178px' }
    },
    {
      Header: i18n.t('Type of Document'),
      disableGroupBy: true,
      accessor: 'type_of_document',
      enableRowSpan: true,
      Cell: ({ value, row }) => {
        if (value) return <div dangerouslySetInnerHTML={{ __html: value }} />
      },
      className: 'textCenter',
      style: { maxWidth: '145px', width: '145px' }
    },
    {
      Header: i18n.t('Date'),
      accessor: 'date_from',

      Cell: ({ value, row }) => {
        if (value)
          return (
            value + (row.original.date_to ? ' - ' + row.original.date_to : '')
          )
        else return '-'
      },

      className: 'textCenter',
      style: { maxWidth: '90px', width: '90px' }
    },
    {
      Header: i18n.t('Details'),
      disableGroupBy: true,
      accessor: 'details',
      enableRowSpan: true,
      Cell: ({ value, row }) => {
        const extra = JSON.parse(row.original.extra_data)
        if (value)
          if (row.original.generic)
            return <div dangerouslySetInnerHTML={{ __html: value }} />
          else if (i18n.language !== 'en' && extra.is_auto_translate) {
            return <AutoTranslateCell mainText={value} />
          } else
            return (
              <SourceText
                text={value}
                row={row}
                section={extra && extra.section}
                sources={row.original.motherEntry.sources}
                unik={row.original.id + '_explan'}
                lines={10}
              />
            )
        else return <></>
      },

      className: ''
    },
    {
      Header: i18n.t('Topics'),
      Cell: ({ value, row }) => {
        let topics = row.original.topics
          ? row.original.topics
          : row.original.motherEntry.topics.map(t => t.name)
        return topics
          ? topics.map((topic, index) => (
              <div class="acp__td-colContainer" key={row.original + index}>
                <div className="acptd__badge">{topic}</div>
                <div className="topicSeparator" />
              </div>
            ))
          : ''
      },
      className: 'textCenter',
      style: { maxWidth: '182px', width: '182px' },
      disableSortBy: true
    }
  ]
}
