import { useTranslation, Trans } from 'react-i18next'
import { cArea2Treaties, cAreasValues } from '../../config/constants'

export default function StyledMapTooltips({
  entry,
  area,
  title,
  positive = false,
  extraProps,
  hasSubjur = false
}) {
  const { t, i18n } = useTranslation()

  const inlineStyles = {
    tooltipContainer: {
      padding: '18px 18px 8px 18px',
      width: '235px',
      boxShadow: '0px 0px 6px 1px #00000019',
      backgroundColor: '#ffffff'
    },
    tooltipHeader: {
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
      marginBottom: '20px',
      marginTop: '10px',
      paddingRight: '20px',
      paddingLeft: '20px'
    },
    tooltipJurisdiction: {
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
      color: '#4e4e4e',
      fontSize: '17px',
      lineHeight: '16px',
      fontWeight: 900,
      marginTop: '6px'
    },
    tooltipSubJurisdiction: {
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
      color: '#4e4e4e',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400,
      marginBottom: '10px',
      marginTop: '2px'
    },
    tooltipTitle: {
      textAlign: 'center',
      color: '#575757',
      fontSize: '14px',
      lineHeight: '16px',
      marginBottom: '20px',
      display: 'block',
      whiteSpace: 'pre-wrap'
    },
    tooltipTitleJur: {
      textAlign: 'center',
      color: '#575757',
      fontSize: '14px',
      lineHeight: '16px',
      marginBottom: '20px',
      marginTop: '30px',
      display: 'block',
      whiteSpace: 'pre-wrap'
    },
    tooltipFlagContainer: {
      backgroundColor: '#FFF',
      textAlign: 'center',
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#000',
      fontSize: '32px',
      lineHeight: '16px',
      verticalAlign: 'middle',
      padding: '0px 0 6px 0px',
      whiteSpace: 'pre-wrap',
      margin: 'auto',
      fontWeight: 700
    },
    tooltipFlag: { display: 'block', height: '15px' },
    tooltipLegality: {
      backgroundColor: positive ? '#91B6C4' : '#D6643A',
      textAlign: 'center',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#ffffff',
      fontSize: '16px',
      lineHeight: '16px',
      padding: '14px 25px',
      whiteSpace: 'pre-wrap',
      fontWeight: 700,
      margin: '0 0 0 0'
    },
    tooltipLegalityWhite: {
      backgroundColor: '#FFF',
      textAlign: 'center',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#000',
      fontSize: '16px',
      lineHeight: '16px',
      padding: '14px 25px',
      whiteSpace: 'pre-wrap',
      fontWeight: 700
    },
    tooltipLegalSince: {
      color: '#575757',
      fontSize: '12px',
      lineHeight: '16px',
      marginTop: '8px',
      marginBottom: '8px',
      textAlign: 'center'
    },

    tooltipLegality118: {
      backgroundColor: positive ? '#91B6C4' : '#D6643A',
      textAlign: 'center',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#ffffff',
      fontSize: '16px',
      lineHeight: '16px',
      padding: '4px 16px',
      whiteSpace: 'pre-wrap',
      fontWeight: 700
    },
    rows: {
      display: 'flex',
      textAlign: 'left',
      justifyContent: 'space-between'
    },
    cols: {
      whiteSpace: 'pre-wrap',
      width: '49%',
      color: '#575757',
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: 700,
      marginTop: '12px'
    },
    textOrange: {
      color: '#D8A585'
    },
    mdRows: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginBottom: '8px'
    },
    mdCols: {
      width: '38%',
      fontSize: '13px',
      lineHeight: '22px',
      fontWeight: 700
    },
    mdBadge: {
      width: '80px',
      flexGrow: 0,
      textAlign: 'center',
      color: '#FFFFFF',
      fontSize: '12px',
      lineHeight: '22px'
    },
    mdYear: {
      fontSize: '12px',
      lineHeight: '22px',
      color: '#575757',
      marginLeft: '10px',
      minWidth: '28px'
    },
    bgRed: {
      backgroundColor: '#D8A585'
    },
    bgGreen: {
      backgroundColor: '#91B6C4'
    },
    bgGray: {
      backgroundColor: '#BFBFBF'
    },

    bgPRYes: {
      backgroundColor: '#91B6C4'
    },
    bgPRNo: {
      backgroundColor: '#D8A585'
    },
    bgPRVaries: {
      backgroundColor: '#EFD79C'
    },
    bgPRUnclear: {
      backgroundColor: '#CBCBCB'
    },

    mdRowsVar3: {
      justifyContent: 'center'
    },
    mdColsVar3: {
      width: 'auto',
      minWidth: '20px',
      marginRight: '10px'
    },
    tt__treatyBodies: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    tttb__heading: {
      color: '#4e4e4e',
      fontSize: '16px',
      lineHeight: '18px',
      marginBottom: '2px'
    },
    tttb__subHeading: {
      color: '#707070',
      fontSize: '12px',
      lineHeight: '12px',
      maxWidth: '200px'
    },
    tttb__list: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      padding: '6px 12px',
      width: '100%'
    },
    tttb__list_unique: {
      justifyContent: 'center'
    },
    tttb__listItem: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '80px',
      margin: '4px 0'
    },
    tttb__listItemCenterd: {
      display: 'flex',
      justifyContent: 'center',
      width: '80px',
      margin: '4px 0'
    },

    tttb__numBox: {
      width: '28px',
      height: '17px',
      backgroundColor: '#E9EFF0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#575757',
      fontSize: '12px'
    },
    tttb__numBox_0: {
      width: '28px',
      height: '17px',
      backgroundColor: '#FFF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#575757',
      fontSize: '12px'
    },
    a2__numBox_No: {
      width: '28px',
      height: '17px',
      backgroundColor: '#D8A585',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#FFF',
      fontSize: '12px'
    },
    a2__numBox_Ras: {
      width: '28px',
      height: '17px',
      backgroundColor: '#91B6C4',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#FFF',
      fontSize: '12px'
    },
    a2__numBox_Sign: {
      width: '28px',
      height: '17px',
      backgroundColor: '#EFD79C',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#707070',
      fontSize: '12px'
    },
    sp__listItem: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      margin: '4px 0'
    },

    sp__numBox: {
      width: '28px',
      height: '17px',
      backgroundColor: '#E9EFF0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#575757',
      fontSize: '12px'
    },

    a2__listItem: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      margin: '4px 0'
    },
    a2__numBoxNo: {
      width: '50%',
      height: '18px',
      backgroundColor: '#D8A585',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      verticalAlign: 'center',
      color: '#FFF',
      fontSize: '12px'
    },
    a2__numBoxYes: {
      width: '50%',
      height: '18px',
      backgroundColor: '#91B6C4',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      verticalAlign: 'center',
      color: '#FFF',
      fontSize: '12px'
    },
    ssu__listItem: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      margin: '4px 0',
      gap: '10px'
    },
    ssu__label: {
      alignItems: 'right',
      textAlign: 'right'
    },
    ssu__numBox: {
      height: '17px',
      textAlign: 'left',
      color: '#575757',
      fontSize: '12px'
    },
    bugfixClass: {
      marginLeft: '5px'
    },
    issuedReceivedClass: {
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
      color: '#6EA2B5',
      marginBottom: '10px'
    },
    issuedReceivedClassGray: {
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
      color: '#707070',
      marginBottom: '12px',
      fontWeight: '900'
    },
    progressBar: {
      width: '100%',
      height: '16px',
      backgroundColor: '#d1d1d1',
      position: 'relative',
      marginBottom: '8px'
    },
    progressIndicator: {
      width: `${
        entry.n_recommendations ? entry.n_recommendations.toFixed(0) : 0
      }%`,
      height: '16px',
      backgroundColor: entry.rolloverColor
    },
    tooltipRecomendations: {
      fontSize: '12px',
      lineHeight: '13px',
      color: '#868686',
      marginTop: '4px'
    },
    tooltipIssuedReceived: {
      backgroundColor: '#D8E3E5',
      textAlign: 'center',
      width: '100%',
      marginBottom: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#707070',
      fontSize: '12px',
      lineHeight: '12px',
      padding: '14px 5px',
      whiteSpace: 'pre-wrap'
    }
  }

  return (
    <div style={inlineStyles.tooltipContainer} className="tooltipContainer">
      <div className="tooltipHeader">
        <div style={inlineStyles.tooltipFlagContainer}>
          {entry.issued_to && entry.issued_to.a2_code && (
            <img
              style={inlineStyles.tooltipFlag}
              className="tootlipFlag"
              alt={entry.motherEntry.jurisdiction.name}
              src={`/images/flags_svg/${entry.motherEntry.jurisdiction.a2_code.toLowerCase()}.svg`}
            />
          )}
          {entry.issued_to && entry.issued_to.a2_code && (
            <>
              <img
                style={inlineStyles.tooltipFlag}
                className="tootlipFlag"
                style={{ boxShadow: 'unset' }}
                alt={entry.issued_to.name}
                src={'/images/rightArrow.svg'}
              />
            </>
          )}
          {entry.received_from && entry.received_from.a2_code && (
            <img
              style={inlineStyles.tooltipFlag}
              className="tootlipFlag"
              alt={entry.received_from.name}
              src={`/images/flags_svg/${entry.received_from.a2_code.toLowerCase()}.svg`}
            />
          )}
          {entry.received_from && entry.received_from.a2_code && (
            <>
              <img
                style={inlineStyles.tooltipFlag}
                className="tootlipFlag"
                alt={entry.received_from.name}
                style={{ boxShadow: 'unset' }}
                src={'/images/rightArrow.svg'}
              />
            </>
          )}
          {entry.issued_to && entry.issued_to.a2_code && (
            <img
              style={inlineStyles.tooltipFlag}
              className="tootlipFlag"
              alt={entry.issued_to.name}
              src={`/images/flags_svg/${entry.issued_to.a2_code.toLowerCase()}.svg`}
            />
          )}

          {(!entry.issued_to || !entry.issued_to.a2_code) && (
            <img
              style={inlineStyles.tooltipFlag}
              className="tootlipFlag"
              alt={entry.motherEntry.jurisdiction.name}
              src={`/images/flags_svg/${entry.motherEntry.jurisdiction.a2_code.toLowerCase()}.svg`}
            />
          )}
        </div>{' '}
        <div
          style={inlineStyles.tooltipJurisdiction}
          className="tooltipJurisdiction"
        >
          {entry.motherEntry.jurisdiction.name}{' '}
        </div>
        <div
          style={inlineStyles.tooltipSubJurisdiction}
          className="tooltipSubJurisdiction"
        >
          {entry.motherEntry.subjurisdiction
            ? ' (' + entry.motherEntry.subjurisdiction.name + ')'
            : hasSubjur
            ? t('Click to display subjur')
            : ''}
        </div>
      </div>
      {title && (
        <div className="tooltipBody">
          <p style={inlineStyles.tooltipTitle} className="tooltipTitle">
            {title}
          </p>
        </div>
      )}
      {area === 'A5' && (
        <div>
          <div className="tooltipBody">
            <p style={inlineStyles.tooltipTitle} className="tooltipTitle">
              {t('Universal Periodic Review (UPR)')}
            </p>
          </div>
          {entry.motherEntry.jurisdiction.un_member ? (
            <>
              {' '}
              <div style={inlineStyles.progressBar} className="progressBar">
                <div
                  style={inlineStyles.progressIndicator}
                  className="progressIndicator"
                />
              </div>
              <p style={{ marginBottom: '4px', textAlign: 'center' }}>
                {entry.n_recommendations.toFixed(0)}%
              </p>
              <p
                style={{
                  fontSize: '12px',
                  lineHeight: '13px',
                  color: '#868686',
                  textAlign: 'center'
                }}
              >
                {' '}
                <Trans>
                  Percentage of accepted <br /> recommendations
                </Trans>
              </p>
            </>
          ) : (
            <>
              <p
                style={{
                  fontSize: '12px',
                  lineHeight: '13px',
                  color: '#868686',
                  textAlign: 'center'
                }}
              >
                <Trans>
                  Non-independent territories do not <br /> directly participate
                  in the UPR
                </Trans>
              </p>
            </>
          )}
        </div>
      )}
      {area === 'A5_RECEIVED_CLICK' && (
        <div className="tooltipBody">
          <div style={inlineStyles.issuedReceivedClass}>
            {t('Click to display received recommendations and issuing States')}
          </div>
        </div>
      )}
      {area === 'A5_ISSUED_CLICK' && (
        <div className="tooltipBody">
          <div style={inlineStyles.issuedReceivedClass}>
            {t('Click to display issued recommendations and receiving States')}
          </div>
        </div>
      )}
      {area === 'A5_ISSUED' && (
        <div className="tooltipBody">
          <div style={inlineStyles.tooltipIssuedReceived}>
            <>
              {t('Received')}{' '}
              <strong>{entry.n_recommendations.toFixed(0)}</strong>{' '}
              {entry.n_recommendations.toFixed(0) == 1
                ? t('recommendation from')
                : t('recommendations from')}
            </>
          </div>
          <div style={inlineStyles.issuedReceivedClassGray}>
            {entry.received_from.name}
          </div>
        </div>
      )}
      {area === 'A5_ISSUED_INI' && (
        <div className="tooltipBody">
          <div style={inlineStyles.tooltipIssuedReceived}>
            {entry.n_recommendations ? (
              <>
                {t('Issued_emitio')}{' '}
                <strong>{entry.n_recommendations.toFixed(0)}</strong>{' '}
                {entry.n_recommendations.toFixed(0) == 1
                  ? t('recommendation')
                  : t('recommendations')}
              </>
            ) : (
              <Trans>
                Issued <strong>no</strong> SOGIESC recommendations
              </Trans>
            )}
          </div>
          {entry.n_recommendations && entry.n_recommendations > 0 ? (
            <div style={inlineStyles.issuedReceivedClass}>
              {t('Receiving States appear highlighted')}
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      {area === 'A5_RECEIVED' && (
        <div className="tooltipBody">
          <div style={inlineStyles.tooltipIssuedReceived}>
            <>
              {t('Issued_emitio')}{' '}
              <strong>{entry.n_recommendations.toFixed(0)}</strong>{' '}
              {entry.n_recommendations.toFixed(0) == 1
                ? t('recommendation to')
                : t('recommendations to')}
            </>
          </div>
          <div style={inlineStyles.issuedReceivedClassGray}>
            {entry.issued_to.name}
          </div>
        </div>
      )}
      {area === 'A5_RECEIVED_INI' && (
        <div className="tooltipBody">
          <div style={inlineStyles.tooltipIssuedReceived}>
            {entry.n_recommendations ? (
              <>
                {t('Received')}{' '}
                <strong>{entry.n_recommendations.toFixed(0)}</strong>{' '}
                {entry.n_recommendations.toFixed(0) == 1
                  ? t('recommendation')
                  : t('recommendations')}
              </>
            ) : (
              <Trans>
                Received <strong>no</strong> SOGIESC recommendations
              </Trans>
            )}
          </div>
          {entry.n_recommendations && entry.n_recommendations > 0 ? (
            <div style={inlineStyles.issuedReceivedClass}>
              {t('Issuing States appear highlighted')}
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      {area === 'jur-home' && (
        <div className="tooltipBody">
          <p style={inlineStyles.tooltipTitleJur} className="tooltipTitleJur">
            {entry.motherEntry.jurisdiction.un_member
              ? t('UN Member')
              : t('Non UN Member')}
          </p>
        </div>
      )}
      {area === 'A1-1' && (
        <>
          <div className="tooltipLegality" style={inlineStyles.tooltipLegality}>
            {entry.legal ? t('LEGAL') : t('ILLEGAL')}
          </div>
          {entry.legal && (
            <div
              style={inlineStyles.tooltipLegalSince}
              className="tooltipLegalSince"
            >
              {entry.decrim_date_1 === 0
                ? t('Always legal')
                : t('Since') +
                  ' ' +
                  (entry.decrim_date_1
                    ? entry.decrim_date_1 +
                      (entry.decrim_date_2 ? ' - ' + entry.decrim_date_2 : '')
                    : t('N/A'))}
            </div>
          )}
          {!entry.legal && (
            <div
              style={inlineStyles.tooltipLegalSince}
              className="tooltipSanctions"
            >
              {entry.entry_csssa_death_penalty_value.id !==
                cAreasValues.a1_deathPenaltyNo && (
                <div style={inlineStyles.rows}>
                  <span style={inlineStyles.cols}>{t('Death Penalty')}</span>
                  <span
                    style={{ ...inlineStyles.cols, ...inlineStyles.textOrange }}
                  >
                    {entry.entry_csssa_death_penalty_value.name}
                  </span>
                </div>
              )}
              {entry.entry_csssa_max_prison_value.id !==
                cAreasValues.a1_maxPrisonNo && (
                <div style={inlineStyles.rows}>
                  <span style={inlineStyles.cols}>{t('Max prision')}</span>
                  <span
                    style={{ ...inlineStyles.cols, ...inlineStyles.textOrange }}
                  >
                    {(entry.entry_csssa_max_prison_value.id ===
                    cAreasValues.a1_maxPrisonYears
                      ? entry.max_prison_years + ' '
                      : '') + entry.entry_csssa_max_prison_value.name}
                  </span>
                </div>
              )}
              {entry.has_fine && (
                <div style={inlineStyles.rows}>
                  <span style={inlineStyles.cols}>{t('Fine')}</span>
                  <span
                    style={{ ...inlineStyles.cols, ...inlineStyles.textOrange }}
                  >
                    Yes
                  </span>
                </div>
              )}
              {entry.entry_csssa_other_punishment_value.id !==
                cAreasValues.a1_otherPunishmentNo && (
                <div style={inlineStyles.rows}>
                  <span style={inlineStyles.cols}>
                    {t('Other punishments')}
                  </span>
                  <span
                    style={{ ...inlineStyles.cols, ...inlineStyles.textOrange }}
                  >
                    {entry.entry_csssa_other_punishment_value.name}
                  </span>
                </div>
              )}
              <div />
            </div>
          )}
        </>
      )}
      {area === 'A2' && (
        <>
          <div style={inlineStyles.tt__treatyBodies}>
            <div
              style={{
                ...inlineStyles.tttb__list,
                ...(extraProps.treatyBodies.length === 1 && {
                  ...inlineStyles.tttb__list_unique
                })
              }}
            >
              {extraProps.treatyBodies.map((tb, index) => (
                <div style={inlineStyles.tttb__listItem} key={index}>
                  <div
                    style={
                      index % 2 === 0 && extraProps.treatyBodies.length === 2
                        ? inlineStyles.bugfixClass
                        : {}
                    }
                  >
                    {tb.name}
                  </div>
                  <div
                    style={
                      !extraProps.entriesByJur.find(
                        t => parseInt(t.treaty_body.id) === tb.id
                      )
                        ? inlineStyles.a2__numBox_No
                        : extraProps.entriesByJur.find(
                            t => parseInt(t.treaty_body.id) === tb.id
                          ).ras
                        ? inlineStyles.a2__numBox_Ras
                        : extraProps.entriesByJur.find(
                            t => parseInt(t.treaty_body.id) === tb.id
                          ).sign
                        ? inlineStyles.a2__numBox_Sign
                        : inlineStyles.a2__numBox_No
                    }
                  >
                    {!extraProps.entriesByJur.find(
                      t => parseInt(t.treaty_body.id) === tb.id
                    )
                      ? i18n.t('No')
                      : extraProps.entriesByJur.find(
                          t => parseInt(t.treaty_body.id) === tb.id
                        ).ras
                      ? i18n.t('RAS')
                      : extraProps.entriesByJur.find(
                          t => parseInt(t.treaty_body.id) === tb.id
                        ).sign
                      ? i18n.t('Sign')
                      : i18n.t('No')}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {area === 'A2X' && (
        <>
          <div style={inlineStyles.tt__treatyBodies}>
            <span style={inlineStyles.tttb__heading}>
              {extraProps.currentTB.name}
            </span>
            <div
              style={{
                ...inlineStyles.tttb__list
              }}
            >
              <div style={inlineStyles.a2__listItem}>
                <div>{t('Signature')}</div>
                <div
                  style={
                    extraProps.sign
                      ? inlineStyles.a2__numBoxYes
                      : inlineStyles.a2__numBoxNo
                  }
                >
                  {extraProps.sign ? extraProps.sign.slice(0, 4) : t('No')}
                </div>
              </div>
              <div style={inlineStyles.a2__listItem}>
                <div>{t('Ratification')}</div>
                <div
                  style={
                    extraProps.ras
                      ? inlineStyles.a2__numBoxYes
                      : inlineStyles.a2__numBoxNo
                  }
                >
                  {extraProps.ras ? extraProps.ras.slice(0, 4) : t('No')}
                </div>
              </div>
              {!cArea2Treaties().find(
                t => t.id === parseInt(extraProps.currentTB.id)
              ).noComplaints && (
                <>
                  <div style={inlineStyles.a2__listItem}>
                    <div>{t('Indiv. Comp.')}</div>
                    <div
                      style={
                        extraProps.accepted_complaints
                          ? inlineStyles.a2__numBoxYes
                          : inlineStyles.a2__numBoxNo
                      }
                    >
                      {extraProps.accepted_complaints
                        ? extraProps.accepted_complaints.slice(0, 4)
                        : t('No')}
                    </div>
                  </div>
                  <div style={inlineStyles.a2__listItem}>
                    <div>{t('Inquiry')}</div>
                    <div
                      style={
                        extraProps.accepted_inquiry
                          ? inlineStyles.a2__numBoxYes
                          : inlineStyles.a2__numBoxNo
                      }
                    >
                      {extraProps.accepted_inquiry
                        ? extraProps.accepted_inquiry.slice(0, 4)
                        : t('No')}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      {/* THIS IS NEW TOOLTIP FOR AREA 3 */}
      {area === 'A3' && (
        <>
          <div style={inlineStyles.tt__treatyBodies}>
            <span style={inlineStyles.tttb__heading}>{t('Treaty Bodies')}</span>
            <span style={inlineStyles.tttb__subHeading}>
              {t('Recommendations')}
            </span>
            <div
              style={{
                ...inlineStyles.tttb__list,
                ...(extraProps.treatyBodies.filter(
                  tb =>
                    !extraProps.mapBodies.length ||
                    extraProps.mapBodies.indexOf(tb.id) !== -1
                ).length === 1 && { ...inlineStyles.tttb__list_unique })
              }}
            >
              {extraProps.treatyBodies
                .filter(
                  tb =>
                    !extraProps.mapBodies.length ||
                    extraProps.mapBodies.indexOf(tb.id) !== -1
                )
                .map((tb, index) => (
                  <div style={inlineStyles.tttb__listItem} key={index}>
                    <div
                      style={
                        index % 2 === 0 && extraProps.treatyBodies.length === 2
                          ? inlineStyles.bugfixClass
                          : {}
                      }
                    >
                      {tb.name}
                    </div>
                    <div
                      style={
                        entry.n_by_type[tb.id]
                          ? inlineStyles.tttb__numBox
                          : inlineStyles.tttb__numBox_0
                      }
                    >
                      {entry.n_by_type[tb.id] ? entry.n_by_type[tb.id] : 0}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
      {area === 'A3X' && (
        <>
          <div
            className="tooltipLegality"
            style={
              entry.n_recommendations
                ? inlineStyles.tooltipLegality
                : inlineStyles.tooltipLegalityWhite
            }
          >
            {entry.n_recommendations}{' '}
            {entry.n_recommendations == 1
              ? t('recommendation')
              : t('recommendations')}
          </div>
          <div
            style={inlineStyles.tooltipLegalSince}
            className="tooltipLegalSince"
          />
        </>
      )}
      {area === 'A4' && (
        <>
          <div style={inlineStyles.tt__treatyBodies}>
            <span style={inlineStyles.tttb__heading}>
              {t('Special Procedures')}
            </span>
            <span style={inlineStyles.tttb__subHeading}>
              {t('Recommendations')}
              {extraProps.mentions === 1 && t(' and Mentions')}
            </span>
            <div
              style={{
                ...inlineStyles.tttb__list
              }}
            >
              {extraProps.specialProceduresTypes
                .filter(
                  tb =>
                    (!extraProps.currentSP || tb.id == extraProps.currentSP) &&
                    entry.n_by_type[tb.id]
                )
                .sort((a, b) => {
                  if (entry.n_by_type[a.id] > entry.n_by_type[b.id]) return -1
                  else if (entry.n_by_type[a.id] < entry.n_by_type[b.id])
                    return 1
                  else return 0
                })
                .slice(0, 6)
                .map((tb, index) => (
                  <div style={inlineStyles.sp__listItem} key={index}>
                    <div
                      style={
                        index % 2 === 0 &&
                        extraProps.specialProceduresTypes.length === 2
                          ? inlineStyles.bugfixClass
                          : {}
                      }
                    >
                      {tb.mini_name}
                    </div>
                    <div style={inlineStyles.sp__numBox}>
                      {entry.n_by_type[tb.id] ? entry.n_by_type[tb.id] : 0}
                    </div>
                  </div>
                ))}
              {extraProps.specialProceduresTypes.filter(
                tb =>
                  (!extraProps.currentSP || tb.id == extraProps.currentSP) &&
                  entry.n_by_type[tb.id]
              ).length > 6 && (
                <div style={inlineStyles.sp__listItem}>
                  <em>
                    ...{t('and')}{' '}
                    {
                      extraProps.specialProceduresTypes.filter(
                        tb =>
                          (!extraProps.currentSP ||
                            tb.id == extraProps.currentSP) &&
                          entry.n_by_type[tb.id]
                      ).length
                    }{' '}
                    {t('more mandates')}
                  </em>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {area === 'A1-3' && (
        <>
          <div
            className="tooltipLegality"
            style={{
              ...inlineStyles.tooltipLegality,
              backgroundColor: extraProps.bgColor,
              ...(extraProps.bgColor === '#FFF' && {
                color: '#707070',
                border: '1px solid #BFBFBF'
              })
            }}
          >
            {entry.entry_foe_barrier_summary_value.name}
          </div>
          <div
            style={inlineStyles.tooltipLegalSince}
            className="tooltipLegalSince"
          >
            {entry.general_critical_date_1
              ? entry.general_critical_date_1 +
                (entry.general_critical_date_2
                  ? ' - ' + entry.general_critical_date_2
                  : '')
              : entry.eductaion_critical_date_1
              ? entry.eductaion_critical_date_1 +
                (entry.eductaion_critical_date_2
                  ? ' - ' + entry.eductaion_critical_date_2
                  : '')
              : entry.media_critical_date_1
              ? entry.media_critical_date_1 +
                (entry.media_critical_date_2
                  ? ' - ' + entry.media_critical_date_2
                  : '')
              : ''}
          </div>
        </>
      )}
      {area === 'A1-4' && (
        <>
          <div
            className="tooltipLegality"
            style={{
              ...inlineStyles.tooltipLegality,
              backgroundColor: extraProps.bgColor,
              ...(extraProps.bgColor === '#FFF' && {
                color: '#707070',
                border: '1px solid #BFBFBF'
              })
            }}
          >
            {entry.barrier_value.name}
          </div>
          <div
            style={inlineStyles.tooltipLegalSince}
            className="tooltipLegalSince"
          >
            {entry.critical_date_1
              ? entry.critical_date_1 +
                (entry.critical_date_2 ? ' - ' + entry.critical_date_2 : '')
              : ''}
          </div>
        </>
      )}

      {area === 'A1-14' && (
        <>
          <div
            className="tooltipLegality"
            style={{
              ...inlineStyles.tooltipLegality,
              backgroundColor: extraProps.bgColor,
              ...(extraProps.bgColor === '#FFF' && {
                color: '#707070',
                border: '1px solid #BFBFBF'
              })
            }}
          >
            {extraProps.name}
          </div>
          <div
            style={inlineStyles.tooltipLegalSince}
            className="tooltipLegalSince"
          >
            {entry.critical_date_1
              ? entry.critical_date_1 +
                (entry.critical_date_2 ? ' - ' + entry.critical_date_2 : '')
              : ''}
          </div>
        </>
      )}

      {area === 'A1-15' && (
        <>
          <div
            className="tooltipLegality"
            style={{
              ...inlineStyles.tooltipLegality,
              backgroundColor: extraProps.bgColor,
              ...(extraProps.bgColor === '#FFF' && {
                color: '#707070',
                border: '1px solid #BFBFBF'
              })
            }}
          >
            {extraProps.name}
          </div>
          <div
            style={inlineStyles.tooltipLegalSince}
            className="tooltipLegalSince"
          >
            {entry.marriage_critical_date_1 && entry.civil_critical_date_1 ? (
              <>
                <div style={inlineStyles.ssu__listItem}>
                  <div style={inlineStyles.ssu__label}>{t('Marriage')}</div>
                  <div style={inlineStyles.ssu__numBox}>
                    {entry.marriage_critical_date_1 +
                      (entry.marriage_critical_date_2
                        ? ' - ' + entry.marriage_critical_date_2
                        : '')}
                  </div>
                </div>
                <div style={inlineStyles.ssu__listItem}>
                  <div style={inlineStyles.ssu__label}>{t('Civil Union')}</div>
                  <div style={inlineStyles.ssu__numBox}>
                    {entry.civil_critical_date_1 +
                      (entry.civil_critical_date_2
                        ? ' - ' + entry.civil_critical_date_2
                        : '')}
                  </div>
                </div>
              </>
            ) : entry.marriage_critical_date_1 ? (
              entry.marriage_critical_date_1 +
              (entry.marriage_critical_date_2
                ? ' - ' + entry.marriage_critical_date_2
                : '')
            ) : entry.civil_critical_date_1 ? (
              entry.civil_critical_date_1 +
              (entry.civil_critical_date_2
                ? ' - ' + entry.civil_critical_date_2
                : '')
            ) : (
              ''
            )}
          </div>
        </>
      )}

      {area === 'A1-16' && (
        <>
          <div
            className="tooltipLegality"
            style={{
              ...inlineStyles.tooltipLegality,
              backgroundColor: extraProps.bgColor,
              ...(extraProps.bgColor === '#FFF' && {
                color: '#707070',
                border: '1px solid #BFBFBF'
              })
            }}
          >
            {extraProps.name}
          </div>
          <div
            style={inlineStyles.tooltipLegalSince}
            className="tooltipLegalSince"
          >
            {entry.joint_critical_date_1 &&
            entry.second_parent_critical_date_1 ? (
              <>
                <div style={inlineStyles.ssu__listItem}>
                  <div style={inlineStyles.ssu__label}>
                    {t('Joint Adoption')}
                  </div>
                  <div style={inlineStyles.ssu__numBox}>
                    {entry.joint_critical_date_1 +
                      (entry.joint_critical_date_2
                        ? ' - ' + entry.joint_critical_date_2
                        : '')}
                  </div>
                </div>
                <div style={inlineStyles.ssu__listItem}>
                  <div style={inlineStyles.ssu__label}>
                    {t('Second Parent Adoption')}
                  </div>
                  <div style={inlineStyles.ssu__numBox}>
                    {entry.second_parent_critical_date_1 +
                      (entry.second_parent_critical_date_2
                        ? ' - ' + entry.second_parent_critical_date_2
                        : '')}
                  </div>
                </div>
              </>
            ) : entry.joint_critical_date_1 ? (
              entry.joint_critical_date_1 +
              (entry.joint_critical_date_2
                ? ' - ' + entry.joint_critical_date_2
                : '')
            ) : entry.second_parent_critical_date_1 ? (
              entry.second_parent_critical_date_1 +
              (entry.second_parent_critical_date_2
                ? ' - ' + entry.second_parent_critical_date_2
                : '')
            ) : (
              ''
            )}
          </div>
        </>
      )}

      {area === 'A1-17' && (
        <>
          <div
            className="tooltipLegality"
            style={{
              ...inlineStyles.tooltipLegality,
              backgroundColor: extraProps.bgColor,
              ...(extraProps.bgColor === '#FFF' && {
                color: '#707070',
                border: '1px solid #BFBFBF'
              })
            }}
          >
            {extraProps.name}
          </div>
          <div
            style={inlineStyles.tooltipLegalSince}
            className="tooltipLegalSince"
          >
            {entry.critical_date_1
              ? entry.critical_date_1 +
                (entry.critical_date_2 ? ' - ' + entry.critical_date_2 : '')
              : ''}
          </div>
        </>
      )}

      {area === 'A1-2' && (
        <div className="tooltipMoreData">
          <div style={inlineStyles.mdRows}>
            <span style={inlineStyles.mdCols}>Procedure</span>
            <span style={{ ...inlineStyles.mdBadge, ...inlineStyles.bgGray }}>
              UNCLEAR
            </span>
          </div>
          <div style={inlineStyles.mdRows}>
            <span style={inlineStyles.mdCols}>Self-ID</span>
            <span style={{ ...inlineStyles.mdBadge, ...inlineStyles.bgRed }}>
              NO
            </span>
            <span style={inlineStyles.mdYear}>2020</span>
          </div>
          <div style={inlineStyles.mdRows}>
            <span style={inlineStyles.mdCols}>Surgery</span>
            <span style={{ ...inlineStyles.mdBadge, ...inlineStyles.bgGreen }}>
              NOT REQ.
            </span>
          </div>
          <div style={inlineStyles.mdRows}>
            <span style={inlineStyles.mdCols}>Diagnosis</span>
            <span style={{ ...inlineStyles.mdBadge, ...inlineStyles.bgGray }}>
              VARIES
            </span>
            <span style={inlineStyles.mdYear}>2020</span>
          </div>
          <div style={inlineStyles.mdRows}>
            <span style={inlineStyles.mdCols}>Self-ID</span>
            <span style={{ ...inlineStyles.mdBadge, ...inlineStyles.bgRed }}>
              NOT AVAIL.
            </span>
            <span style={inlineStyles.mdYear}>2004</span>
          </div>
        </div>
      )}
      {area === 'A1-5' && (
        <div className="tooltipMoreData">
          {(!extraProps.categories.length ||
            extraProps.categories.indexOf('so') !== -1) && (
            <div style={{ ...inlineStyles.mdRows, ...inlineStyles.mdRowsVar3 }}>
              <span
                style={{ ...inlineStyles.mdCols, ...inlineStyles.mdColsVar3 }}
              >
                {t('SO')}
              </span>
              <span
                style={{
                  ...inlineStyles.mdBadge,
                  ...(entry.so_protection_type.id === '1'
                    ? inlineStyles.bgPRYes
                    : entry.so_protection_type.id === '5' ||
                      entry.so_protection_type.id === '7'
                    ? inlineStyles.bgPRUnclear
                    : entry.so_protection_type.id === '6'
                    ? inlineStyles.bgPRVaries
                    : inlineStyles.bgPRNo)
                }}
              >
                {entry.so_protection_type.name}
              </span>
              <span style={inlineStyles.mdYear}>
                {entry.so_critical_date_2
                  ? entry.so_critical_date_2
                  : entry.so_critical_date_1}
              </span>
            </div>
          )}
          {(!extraProps.categories.length ||
            extraProps.categories.indexOf('gi') !== -1) && (
            <div style={{ ...inlineStyles.mdRows, ...inlineStyles.mdRowsVar3 }}>
              <span
                style={{ ...inlineStyles.mdCols, ...inlineStyles.mdColsVar3 }}
              >
                {t('GI')}
              </span>
              <span
                style={{
                  ...inlineStyles.mdBadge,
                  ...(entry.gi_protection_type.id === '1'
                    ? inlineStyles.bgPRYes
                    : entry.gi_protection_type.id === '5' ||
                      entry.gi_protection_type.id === '7'
                    ? inlineStyles.bgPRUnclear
                    : entry.gi_protection_type.id === '6'
                    ? inlineStyles.bgPRVaries
                    : inlineStyles.bgPRNo)
                }}
              >
                {entry.gi_protection_type.name}
              </span>
              <span style={inlineStyles.mdYear}>
                {entry.gi_critical_date_2
                  ? entry.gi_critical_date_2
                  : entry.gi_critical_date_1}
              </span>
            </div>
          )}
          {(!extraProps.categories.length ||
            extraProps.categories.indexOf('ge') !== -1) && (
            <div style={{ ...inlineStyles.mdRows, ...inlineStyles.mdRowsVar3 }}>
              <span
                style={{ ...inlineStyles.mdCols, ...inlineStyles.mdColsVar3 }}
              >
                {t('GE')}
              </span>
              <span
                style={{
                  ...inlineStyles.mdBadge,
                  ...(entry.ge_protection_type.id === '1'
                    ? inlineStyles.bgPRYes
                    : entry.ge_protection_type.id === '5' ||
                      entry.ge_protection_type.id === '7'
                    ? inlineStyles.bgPRUnclear
                    : entry.ge_protection_type.id === '6'
                    ? inlineStyles.bgPRVaries
                    : inlineStyles.bgPRNo)
                }}
              >
                {entry.ge_protection_type.name}
              </span>
              <span style={inlineStyles.mdYear}>
                {entry.ge_critical_date_2
                  ? entry.ge_critical_date_2
                  : entry.ge_critical_date_1}
              </span>
            </div>
          )}
          {(!extraProps.categories.length ||
            extraProps.categories.indexOf('sc') !== -1) && (
            <div style={{ ...inlineStyles.mdRows, ...inlineStyles.mdRowsVar3 }}>
              <span
                style={{ ...inlineStyles.mdCols, ...inlineStyles.mdColsVar3 }}
              >
                {t('SC')}
              </span>
              <span
                style={{
                  ...inlineStyles.mdBadge,
                  ...(entry.sc_protection_type.id === '1'
                    ? inlineStyles.bgPRYes
                    : entry.sc_protection_type.id === '5' ||
                      entry.sc_protection_type.id === '7'
                    ? inlineStyles.bgPRUnclear
                    : entry.sc_protection_type.id === '6'
                    ? inlineStyles.bgPRVaries
                    : inlineStyles.bgPRNo)
                }}
              >
                {entry.sc_protection_type.name}
              </span>
              <span style={inlineStyles.mdYear}>
                {entry.sc_critical_date_2
                  ? entry.sc_critical_date_2
                  : entry.sc_critical_date_1}
              </span>
            </div>
          )}
        </div>
      )}
      {area === 'A1-18' && (
        <>
          {!extraProps.multiLabel || !extraProps.multiLabel.length ? (
            <div
              className="tooltipLegality"
              style={{
                ...inlineStyles.tooltipLegality,
                backgroundColor: extraProps.bgColor,
                ...(extraProps.bgColor === '#FFF' && {
                  color: '#707070',
                  border: '1px solid #BFBFBF'
                }),
                fontSize: '14px'
              }}
            >
              {extraProps.label}
            </div>
          ) : (
            <>
              {extraProps.multiLabel.map(l => (
                <>
                  <div
                    className="tooltipLegality118"
                    style={{
                      ...inlineStyles.tooltipLegality118,
                      backgroundColor: l.color,
                      ...(extraProps.bgColor === '#FFF' && {
                        color: '#CCC',
                        border: '1px solid #CCC'
                      }),
                      fontSize: '14px'
                    }}
                  >
                    {l.label}
                  </div>
                  <div
                    style={{
                      ...inlineStyles.tttb__list,
                      ...(l.values.length === 1 && {
                        ...inlineStyles.tttb__list_unique
                      })
                    }}
                  >
                    {l.values.map((v, index) => (
                      <div
                        style={inlineStyles.tttb__listItemCenterd}
                        key={index}
                      >
                        <div
                          style={
                            index % 2 === 0 && l.values.length === 2
                              ? inlineStyles.bugfixClass
                              : {}
                          }
                        >
                          {v.replace('Prueba de la ', 'Test ')}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ))}
            </>
          )}
        </>
      )}
    </div>
  )
}
