import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cSpecialProceduresTypes } from '../../../config/constants'
import '../../components/Charts/Charts.css'
import LoadingBar from '../../components/ui/LoadingBar'

export default function PieChartJurProfileArea4({ references }) {
  const [unMember, setUnMember] = useState(true)
  const [type, setType] = useState('so')
  const { t, i18n } = useTranslation()
  const loading = useRef()
  const loadChart = () => {
    let newReferences = references
    if (loading.current) loading.current.style.display = 'block'
    am4core.options.queue = true

    /* Chart code */
    // Themes begin
    am4core.unuseAllThemes()
    

    // Themes end

    // Create chart instance
    let chart = am4core.create('pieChartDiv', am4charts.PieChart)

    // Themes begin

    chart.events.on('ready', () => {
      if (loading.current) loading.current.style.display = 'none'
    })
    // Themes end

    // Create chart instance

    // Let's cut a hole in our Pie chart the size of 40% the radius
    chart.innerRadius = am4core.percent(30)

    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries())
    pieSeries.dataFields.value = 'value'
    pieSeries.dataFields.category = 'category'
    pieSeries.slices.template.stroke = am4core.color('#fff')
    pieSeries.innerRadius = 1
    pieSeries.slices.template.strokeWidth = 0
    pieSeries.slices.template.fillOpacity = 0.5

    pieSeries.slices.template.showOnInit = true
    pieSeries.slices.template.hiddenState.properties.shiftRadius = 1

    pieSeries.slices.template.propertyFields.disabled = 'labelDisabled'
    pieSeries.labels.template.propertyFields.disabled = 'textDisabled'
    pieSeries.ticks.template.disabled = true

    pieSeries.alignLabels = false
    //pieSeries.labels.template.maxWidth = 50;
    pieSeries.labels.template.wrap = true
    //pieSeries.labels.template.dy=150;
    //pieSeries.labels.template.fontSize = 8;

    // Add data

    const sps = cSpecialProceduresTypes()
    let total = 0
    const newData = []
    sps.forEach(sp => {
      let val = newReferences.filter(ref =>
        ref.special_procedure_types.find(t => t.id == sp.id)
      ).length
      total += val
    })
    let val8 = 0
    let sum8 = 0

    sps.forEach(sp => {
      let val = newReferences.filter(ref =>
        ref.special_procedure_types.find(t => t.id == sp.id)
      ).length
      if (val && Math.round((val / total) * 100) > 8)
        pieSeries.data.push({
          category: sp.name,
          value: val,
          total: total,
          percentage: Math.round((val / total) * 100)
        })
      else if (val) {
        val8 += val
        sum8++
      }
    })

    if (val8) {
      pieSeries.data.push({
        category: i18n.t('Other {{sum}} mandates', { sum: sum8 }),
        value: val8,
        total: total,
        percentage: Math.round((val8 / total) * 100)
      })
    }

    
    // Disable sliding out of slices

    pieSeries.slices.template.propertyFields.fill = 'fill'
    pieSeries.hiddenState.properties.endAngle = -90

    pieSeries.labels.template.fontSize = 11
    pieSeries.labels.template.maxWidth = 130
    pieSeries.labels.template.wrap = true
    chart.radius = am4core.percent(50)

    pieSeries.labels.template.text = '{category}\n{percentage}%'
    pieSeries.labels.template.textAlign = 'middle'
    pieSeries.slices.template.tooltipText =
      '{category}: {percentage}% ({value} ' + t('out of') + ' {total})'
    // Add second series
  }
  useEffect(() => {
    loadChart()
  }, [unMember, type])
  return (
    <div
      className="chartContainer"
      style={{ minHeight: '500px', position: 'relative' }}
    >
      <LoadingBar ref={loading} text={t('Loading chart') + '...'} />
      <div id="pieChartDiv" style={{ minHeight: '500px', minWidth: '500px' }} />
    </div>
  )
}
