import React, { createRef, useRef, useState } from "react";
import useInterval from "./useInterval";
export const AutoScroller = ({ children, speed, initial, classes, subclasses, pauseOver, unit, subclassesFn, setContainerHeight }) => {
  const [elRefs, setElRefs] = React.useState([]);
  const [items, setItems] = useState(children.slice(0, initial));
  const [last, setLast] = useState(1);
  const [pause, setPause] = useState(false);
  const [marginTop, setMarginTop] = useState(0);
  const mainDiv = useRef();

  React.useEffect(() => {
    // add or remove refs
    setElRefs(elRefs =>
      Array(children.length)
        .fill()
        .map((_, i) => {
          //console.log(elRefs[i].current.clientHeight)
          return elRefs[i] || createRef()
        })
    );
  }, [items.length]);

  const calculateContainerHeight = () => {
    if (elRefs[0] && elRefs[0].current) {
      let containerHeight = 12; // this is to balance for scale of .high element
      for (let i=last-1; i < last+initial-1; i++) {
        containerHeight += elRefs[i].current.getBoundingClientRect().height + 16
      }
      return containerHeight
    } else {
      return 500;
    }

  }

  React.useEffect(() => {
    setTimeout(() => {
      const height = calculateContainerHeight()
      setContainerHeight(height);
    }, 1000)
  }, [elRefs])

  useInterval(() => {
    if (!pause) {
      setItems(children.slice(last, initial + last).concat(children.slice(0, initial)));

      if (last < children.length - initial) {

        mainDiv.current.style.marginTop = marginTop - elRefs[last-1].current.getBoundingClientRect().height - 16 + "px"; //(unit ? -unit * last : -5 * last - 1) + "px";

        setMarginTop(marginTop - elRefs[last-1].current.getBoundingClientRect().height - 16)
        setLast(last => last + 1);
        setContainerHeight(calculateContainerHeight())
      } else {
        setItems(children.slice(0, initial));
        setLast(1);
        setMarginTop(0)
        setContainerHeight(calculateContainerHeight())
      }
    }
  }, speed + speed * Math.random());

  return (
    <div className={`${classes} autoscroll-container`}>
      <div ref={mainDiv} className={'autoScroll__items-container'} style={{ transition: "all 1s ease" }}>
        {children.concat(children.slice(0, 5)).map((el, i) => (
          <div
            ref={elRefs[i]}
            className={(subclasses ? subclasses : "") + " " + (subclassesFn && subclassesFn(i, last))}
            key={i+'monitorNewsItem'}
          >
            {React.cloneElement(el, {
              key: el+i,
              onMouseEnter: e => setPause(true),
              onMouseLeave: e => setPause(false)
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
