import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GET_A1_1_ENTRIES_FOE = gql(`
  query GetEntriesFOE($lang: String) {
    entriesFoe(lang: $lang) {
      id
      motherEntry {
        entry_type_id
        jurisdiction {
          id
          name
          un_member
          a2_code
          regions{
            id
          }
        }
        subjurisdiction {
          id
          name
          slug
          label_longitude
          label_latitude
          label_font_size
        }
        footnotes {
          id
          order
          section
          content
        }
        sources {
          id
          order
          section
          original_official_filename
          original_filename
          translated_filename
          enacted
          original_official_language{
            name
            id
          }
          original_official_language2{
            name
            id
          }
          original_language{
            name
            id
          }
          original_language2{
            name
            id
          }
        }
      }
      general_critical_date_1
      general_critical_date_2
      general_explan
      education_critical_date_1
      education_critical_date_2
      education_explan
      media_critical_date_1
      media_critical_date_2
      media_explan
      entry_foe_barrier_summary_value{
        id
        name
      }
      entry_foe_barrier_general_value{
        id
        name
      }
      general_mechanism{
        id
        name
      }
      entry_foe_barrier_education_value{
        id
        name
      }
      education_mechanism{
        id
        name
      }
      entry_foe_barrier_media_value{
        id
        name
      }
      media_mechanism{
        id
        name
      }
    }
  }
`);

function useGqlEntriesFoe() {
  const { i18n } = useTranslation();
  const entries_foe = useQuery(GET_A1_1_ENTRIES_FOE, { variables: { lang: i18n.language } });
  //const penalties_csssa = useQuery(GET_A1_1_PENALTIES_CSSSA, { variables: { lang: i18n.language } });

  return { entriesFoe: entries_foe.data && entries_foe.data.entriesFoe };
}

export default useGqlEntriesFoe;
