import React from 'react'

const CiteIcon = ({ size, color = '#E7EDEF', title = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size }}
    viewBox="0 0 24 24"
  >
    <title>{title}</title>
    <g fill="currentColor">
      <path d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z" />
    </g>
  </svg>
)

export default CiteIcon
