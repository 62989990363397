import React from 'react'
import MainSpinner from './MainSpinner'
import globeMapImage from '../../../images/loadingMap.png'

const LoadingMap = React.forwardRef(function LoadingMap ({ text, img = true }, loading) {
  const styles = {
    container: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    image: { position: 'static' },
    text: { width: '100%', position: 'absolute', top: '45%', left: 'calc(50%-39px)' }
  }

  return (
    <div id="loading" ref={loading} style={styles.container}>
      {img && <img style={styles.image} src={globeMapImage} alt="map placeholder image" />}
      <p style={styles.text}>{text || 'Loading' + '...'}</p>
      <MainSpinner />
    </div>
  )
})

export default LoadingMap
