import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GET_A1_14_CT = gql(`
  query GetEntriesPnc( $lang: String, $jur_id: String) {
    entriesPnc(lang: $lang, jur_id: $jur_id) {
           id
    motherEntry {
        entry_type_id
        topics {
          id
          name
        }
        jurisdiction {
          id
          name
          un_member
          a2_code
          regions{
            id
          }
        }
        subjurisdiction {
          id
          name
          slug
          label_longitude
          label_latitude
          label_font_size
        }
        footnotes {
          id
          order
          section
          content
        }
        sources {
          id
          order
          section
          original_official_filename
          original_filename
          translated_filename
          enacted
          original_official_language{
            name
            id
          }
          original_official_language2{
            name
            id
          }
          original_language{
            name
            id
          }
          original_language2{
            name
            id
          }
        }
      }
       pnc_type {
      name
      id
    }
    critical_date_1
    mechanism {
      name
      id
    }
    critical_date_2
    explan
    }
}
`);

function useGqlEntriesPnc(jur_id = null) {
  const { i18n } = useTranslation();
  const entriesPnc = useQuery(GET_A1_14_CT, {
    variables: { jur_id, lang: i18n.language }
  });

  return {
    entriesPnc: entriesPnc.data && entriesPnc.data.entriesPnc
  };
}

export default useGqlEntriesPnc;
