import React, { useState, useRef, useEffect } from 'react'

import './VideoSlider.css'

import IconChevronLeft from '../../../components/icons/IconChevronLeft'
import IconChevronRight from '../../../components/icons/IconChevronRight'
import IconPlayClean from '../../../components/icons/IconPlayClean'
import { useTranslation } from 'react-i18next'

// TODO - extract slide in component to achieve noice overlay beehavior
//      - implement slider functionality
//      - only load video on play click?
//      - try to keep other (Slide) component in the same file
//      - last slide should be first but smaller (prev) on load
//      - after everything works make them responsive

const VideoSlide = ({ url, index, extraClassName, next, prev }) => {
  const [showOverlay, setShowOverlay] = useState(true)
  const videoRef = useRef(null)

  const onClickSlide = () => {
    if (extraClassName(index) === 'next') {
      next()
    } else if (extraClassName(index) === 'prev') {
      prev()
    } else {
      setShowOverlay(false)
      videoRef.current.play()
    }
  }

  return (
    <div className={`VideoSlider__slide ${extraClassName(index)}`}>
      <video ref={videoRef} controls onClick={onClickSlide}>
        <source src={`/videos/${url}`} type="video/webm" />
      </video>
      {(showOverlay || extraClassName(index) !== 'active') && (
        <div className="VideSlide__overlay" onClick={onClickSlide}>
          <IconPlayClean size={50} />
        </div>
      )}
    </div>
  )
}

const VideoSlideYoutube = ({
  code,
  index,
  extraClassName,
  next,
  prev,
  title
}) => {
  const [showOverlay, setShowOverlay] = useState(true)
  const videoRef = useRef(null)

  const onClickSlide = () => {
    if (extraClassName(index) === 'next') {
      next()
    } else if (extraClassName(index) === 'prev') {
      prev()
    } else {
      setShowOverlay(false)
      // TODO - chrome bug => first click doesn't play video...
      //        works perfectly afterwards
      videoRef.current.contentWindow.postMessage(
        '{"event":"command","func":"' + 'playVideo' + '","args":""}',
        '*'
      )
    }
  }

  const [calcHeight, setCalcedHeight] = useState(150)
  useEffect(() => {
    const resizeObserver = new ResizeObserver(event => {
      const newHeight = videoRef.current.clientWidth / 1.9225
      setCalcedHeight(newHeight)
    })

    if (videoRef) {
      resizeObserver.observe(videoRef.current)
    }
  }, [videoRef])

  return (
    <div
      style={{ minHeight: calcHeight - 4 + 'px' }}
      className={`VideoSlider__slide SA ${extraClassName(index)}`}
    >
      {extraClassName(index) === 'active' && (
        <h2 className="videoSlider__title">{title}</h2>
      )}
      <iframe
        ref={videoRef}
        className={'theIframe'}
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        height={
          extraClassName(index) === 'active' ? calcHeight + 30 + 'px' : 'auto'
        }
        src={`https://www.youtube.com/embed/${code}?modestbranding=1&autohide=1&autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&enablejsapi=1&version=3&playerapiid=ytplayer${index}`}
      />{' '}
      {showOverlay && extraClassName(index) !== 'active' && (
        <div className="VideSlide__overlay" onClick={onClickSlide}>
          <IconPlayClean size={50} />
        </div>
      )}
    </div>
  )
}

const VideoSlider = () => {
  const { t } = useTranslation()
  const slides = [
    '2Gxrnt9Yzlc',
    'XpqVs_V-XBU',
    '9xndg3AKq3Q',
    'SV7RYA5p-B0',
    'FLSkao4Arc0',
    'GjW11mAO2bY',
    'v5e-U93JpP0',
    '0JyKQptMqPU',
    'e5hdS2LfrQI',
    'H-ZC9LvfPfs',
    'gLi7pcoDarQ'
  ]

  const titles = [
    t('Welcome to the ILGA World Database!'),
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ]

  const [activeSlide, setActiveSlide] = useState(0)

  // Touch events
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  const minSwipeDistance = 50

  const onTouchStart = e => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = e => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe) nextSlide()
    if (isRightSwipe) prevSlide()
  }
  // End of Touch events

  const nextSlide = () => {
    const things = document.querySelectorAll('.theIframe')

    things[activeSlide].contentWindow.postMessage(
      '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
      '*'
    )
    if (activeSlide < slides.length - 1) {
      setActiveSlide(activeSlide + 1)
    } else {
      setActiveSlide(0)
    }
  }

  const prevSlide = () => {
    const things = document.querySelectorAll('.theIframe')

    things[activeSlide].contentWindow.postMessage(
      '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
      '*'
    )
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1)
    } else {
      setActiveSlide(slides.length - 1)
    }
  }

  const extraClassName = idx => {
    if (activeSlide === idx) return 'active'
    if (activeSlide === slides.length - 1 && idx === 0) return 'next'
    if (activeSlide === slides.length - 1 && idx === slides.length - 2)
      return 'prev'
    if (activeSlide === 0 && idx === slides.length - 1) return 'prev'
    if (activeSlide === idx + 1) return 'prev'
    if (activeSlide === idx - 1) return 'next'
    if (slides.length > 3) {
      if (activeSlide === slides.length - 1 && idx === slides.length - 2)
        return ' otherNext'
      if (activeSlide === 0 && idx === slides.length - 2) return ' otherPrev'

      if (activeSlide === 1 && idx === slides.length - 1) return ' otherPrev'
      if (activeSlide === slides.length - 2 && idx === 0) return ' otherNext'
      if (activeSlide === slides.length - 1 && idx === 1) return ' otherNext'

      if (activeSlide === slides.length - 1 && idx === slides.length - 3)
        return ' otherPrev'
      if (activeSlide === idx + 2) return ' otherPrev'
      if (activeSlide === idx - 2) return ' otherNext'
    }
    return 'other'
  }

  return (
    <section>
      <div
        className="VideoSlider__container"
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <button className={'VS__btnPrev'} onClick={prevSlide}>
          <IconChevronLeft />
        </button>
        <button className={'VS__btnNext'} onClick={nextSlide}>
          <IconChevronRight />
        </button>
        {slides.map((url, index) => {
          return (
            <VideoSlideYoutube
              code={url}
              key={'video' + index}
              index={index}
              next={nextSlide}
              prev={prevSlide}
              title={titles[activeSlide]}
              extraClassName={extraClassName}
            />
          )
        })}
      </div>
    </section>
  )
}

export default VideoSlider
