import React, { useState, useRef, useEffect } from "react";
import { useOnClickOutside } from "../../../hooks/clickOutside";
import { cMenuRegions } from "../../../config/constants";
import { useTranslation } from "react-i18next";

import MapIcon from "../icons/MapIcon";

import "./RegionMapSelect.css";

const RegionMapSelect = React.forwardRef(
  (
    {
      showLabels,
      highQuality,
      hideMicrostates,
      pacificCenter,
      setShowLabels,
      setHighQuality,
      setHideMicrostates,
      setPacificCenter,
      menuOpen,
      setMenuOpen,
      setDrillDown,
      drillDown
    },
    chart
  ) => {
    const refRegionMenu = useRef();

    const { t, i18n } = useTranslation();

    const [isPoppedUp, setIsPoppedUp] = useState(false);

    const hideDropdown = () => {
      setMenuOpen(false);
      setIsPoppedUp(false);
    };

    useEffect(() => {
      setIsPoppedUp(menuOpen);
    }, [menuOpen]);

    useEffect(() => {
      setMenuOpen(isPoppedUp);
    }, [isPoppedUp]);

    useOnClickOutside(refRegionMenu, hideDropdown);

    const handleClick = () => setIsPoppedUp(!isPoppedUp);
    const handleClose = () => setIsPoppedUp(false);

    const selectItem = (id) => {
      setDrillDown(id);
      handleClose();
    };

    return (
      <div className="RegionMapSelect__container" ref={refRegionMenu}>
        <div className={"RegionMapSelect__menu " + (isPoppedUp && "showRegionMapSelect")}>
          <div className="RegionMapSelect__menu-container">
            <div className="rms__header">
              <h4 className="rms__title">{t("Regional Maps")}</h4>
            </div>
            <div className="rms__body">
              {cMenuRegions().map((item, index) => (
                <div
                  key={item.label + index}
                  onClick={() => selectItem(item.id)}
                  className={"rms__selectItem " + (drillDown === item.id && "rms__selectItem_selected")}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>
          <div className="arrow-down" />
        </div>
        <span onClick={handleClick} className="RegionMapSelect__trigger" title={t("Regional maps")}>
          <MapIcon color="#6EA2B5" size={36} />
        </span>
      </div>
    );
  }
);

export default RegionMapSelect;
