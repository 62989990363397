var map = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: "PS-GZA",
      properties: { id: 11 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.245483, 31.2088355],
            [34.4769773, 31.584187],
            [34.1981058, 31.3227134],
            [34.245483, 31.2088355]
          ].reverse()
        ]
      }
    }
  ]
};

export default map;
