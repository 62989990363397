import { useTranslation } from 'react-i18next'
import useGqlTimeline from '../../../gql/useGqlTimeline'
import LoadingBar from '../ui/LoadingBar'

const Timeline = ({ currentJur, currentSubjur }) => {
  const { t, i18n } = useTranslation()
  const { jurTimeline, loading } = useGqlTimeline(
    currentJur && !currentSubjur ? currentJur.id : null,
    currentSubjur ? currentSubjur.id : null
  )
  return (
    <div className="jh__timelineContainer">
      <h5>{t('Timeline')}</h5>
      {jurTimeline  ? (
        <ul className="jh__timelineList list-unstyled">
          {jurTimeline.map((entry, i) => (
            <li key={'timeline' + i}>
              <div className="jht__yearDone">
                {entry && entry.year > 0 ? entry.year : ''}
              </div>
              <div className="jht__thingDone">
                {entry &&
                  entry.events.map((ev, i) => (
                    <span key={'ev' + i}>{ev.title}</span>
                  ))}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div
          className="loadingBar__container"
          style={{
            height: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LoadingBar text={'Loading...'} />
        </div>
      )}
    </div>
  )
}

export default Timeline
