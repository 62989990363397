import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Accordion, Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import routes, { getPath, paths } from '../../config/routes'
import { AccountContext } from '../../context/Accounts'
import homepageLogo from '../../images/ilga-logo-clean.svg'
import LoginDropdown from '../AuthModal/LoginDropdown'
import HeaderAreaDropdown from '../components/AreaSelect/HeaderAreaDropdown'
import HamburgerIcon from '../components/icons/HamburgerIcon'
import LogoComponent from '../components/LogoComponent'
import ToolsDropdown from '../components/ToolsDropdown/ToolsDropdown'
import './header.css'
import anthemAwardsLogo from '../../images/anthem_vote.png'

const Header = ({
  section,
  verificationCode,
  setVerificationCode,
  resetPasswordCode,
  setResetPasswordCode
}) => {
  const { loggedIn, setUserLanguage, currentUser, logout } = useContext(
    AccountContext
  )
  const { i18n, t } = useTranslation()
  const [showMenu, setShowMenu] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const [showRegister, setShowRegister] = useState(false)
  const [showSearch, setShowSearch] = useState(false)

  const handleSearchClose = () => setShowSearch(false)

  const handleLoginClose = () => setShowLogin('')
  const handleLoginShow = () => setShowLogin('login')

  const handleRegisterClose = () => setShowRegister(false)

  const handleMenuClose = () => setShowMenu(false)
  const handleMenuShow = () => setShowMenu(true)

  const changeLanguage = useCallback(
    lng => {
      if (lng !== i18n.language) {
        const ps = paths()

        let path = ps.find(
          p => p[i18n.language] === window.location.pathname.slice(1)
        )
        i18n.changeLanguage(lng)

        localStorage.setItem('currentLanguage', lng)
        console.log('PATH', path)
        if (path) window.location = path[lng]
        //else window.location = "/";
      }
    },
    [i18n]
  )
  const location = useLocation()
  const theRoutes = routes()
  const [, setActiveItem] = useState({})
  useEffect(() => {
    theRoutes.forEach(item => {
      if (item.path === location.pathname) setActiveItem(item)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentUser && currentUser.email !== 'naftecbr+ilgdemo@gmail.com') {
      changeLanguage(currentUser.lang)
      if (resetPasswordCode) window.location = '/'
    }
  }, [currentUser, changeLanguage])

  /* dropdown state */

  return (
    <>
      <header
        className={
          `section__${section} ` +
          (section && section === 'jurs' ? 'jur__page' : '')
        }
      >
        <div className="content__container">
          <Link className="logoContainer" to={getPath('home')}>
            {section === 'areas section__homePage' ? (
              <>
                <img src={homepageLogo} alt="Ilga database logo" />
                <img
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    window.open(
                      'https://celebrate.anthemawards.com/'
                    )
                  }}
                  src={anthemAwardsLogo}
                  alt="Antem Awards 2023 Finalist"
                  className="anthemAwardsLogo"
                />
              </>
            ) : (
              <>
                {' '}
                <LogoComponent />{' '}
                <img
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    window.open(
                      'https://celebrate.anthemawards.com/'
                    )
                  }}
                  src={anthemAwardsLogo}
                  alt="Antem Awards 2023 Finalist"
                  className="anthemAwardsLogoInner"
                />
              </>
            )}
          </Link>

          <div className="menuContainer">
            {section === 'areas section__homePage' && (
              <div className="menuHomepage__actions">
                <button onClick={() => window.open('https://ilga.org/')}>
                  ILGA website
                </button>
                <button onClick={() => window.open('https://ilga.org/donate')}>
                  Donate
                </button>
              </div>
            )}
            <div className="menuLeft">
              <ul className="textLinks">
                <HeaderAreaDropdown id="sections" section={section} />
                <li>
                  <Link
                    to={getPath('jur_home')}
                    className={section === 'jurs' ? 'active' : ''}
                  >
                    {t('Jurisdictions')}
                  </Link>
                </li>
                <ToolsDropdown section={section} />
              </ul>
            </div>
            <div className="menuRight">
              <ul className="iconLinks">
                <li>
                  <span
                    onClick={() => {
                      if (loggedIn && currentUser) {
                        setUserLanguage({
                          variables: { id: currentUser.id, input: 'en' }
                        }).then(() => changeLanguage('en'))
                      } else changeLanguage('en')
                    }}
                    style={{
                      ...(i18n.language !== 'en' && { fontWeight: '400' })
                    }}
                  >
                    EN
                  </span>{' '}
                  <span className="darkSlash"> | </span>{' '}
                  <span
                    onClick={() => {
                      if (loggedIn && currentUser) {
                        setUserLanguage({
                          variables: { id: currentUser.id, input: 'es' }
                        }).then(() => changeLanguage('es'))
                        //changeLanguage("es");
                      } else changeLanguage('es')
                    }}
                    style={{
                      ...(i18n.language !== 'es' && { fontWeight: '300' })
                    }}
                  >
                    ES
                  </span>
                </li>
                <LoginDropdown
                  verificationCode={verificationCode}
                  setVerificationCode={setVerificationCode}
                  resetPasswordCode={resetPasswordCode}
                  setResetPasswordCode={setResetPasswordCode}
                />

                <li>
                  <Button variant="link" onClick={handleMenuShow}>
                    <HamburgerIcon size={30} />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      <Modal
        size="xs"
        show={showSearch}
        className="menu__modal"
        onHide={handleSearchClose}
      >
        <Modal.Header closeButton>
          <LogoComponent />
        </Modal.Header>
        <Modal.Body>Quick Search Here?</Modal.Body>
      </Modal>

      <Modal
        size="xl"
        show={showRegister}
        className="menu__modal"
        onHide={handleRegisterClose}
      >
        <Modal.Header closeButton>
          <LogoComponent />
        </Modal.Header>
        <Modal.Body>Register Form Here</Modal.Body>
        <Modal className="Footer">
          <Button variant="outline-light">Login</Button>
        </Modal>
      </Modal>

      <Modal
        size="xl"
        show={showMenu}
        className="menu__modal"
        onHide={handleMenuClose}
      >
        <Modal.Header closeButton>
          <LogoComponent />
        </Modal.Header>
        <Modal.Body>
          <div className="subNav__links">
            <Link className="mainLink" to="/areas">
              {t('Areas')}
            </Link>
            <ul className="list-unstyled">
              <li>
                <Accordion className="menu__accordion" flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {' '}
                      {t('Area')} 1 |&nbsp;<b>{t('Legal Frameworks')}</b>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ol className="list-inside">
                        {theRoutes
                          .filter(
                            item => item.isChild && item.parent === 'area1'
                          )
                          .map((item, index) => {
                            return (
                              <li
                                key={item.path}
                                className={`${item.disabled ? 'disabled' : ''}`}
                              >
                                <Link to={item.path}>
                                  {item.name}
                                  {item.disabled ? (
                                    <em>{' (' + t('forthcoming') + ')'}</em>
                                  ) : (
                                    ''
                                  )}
                                </Link>
                              </li>
                            )
                          })}
                      </ol>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </li>
              <li>
                <Link to={theRoutes.find(item => item.areaNumber === 2).path}>
                  {t('Area')} 2 |{' '}
                  <b>{t('International Human Rights Treaties')}</b>
                </Link>
              </li>
              <li>
                <Accordion className="menu__accordion" flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {' '}
                      {t('Area')} 3 |&nbsp;<b>{t('UN Treaty Bodies')}</b>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ol className="list-inside">
                        <li>
                          <Link
                            to={
                              theRoutes.find(
                                item =>
                                  item.parent === 'area3' &&
                                  item.subareaNumber === 1
                              ).path
                            }
                          >
                            {t('All treaty bodies')}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              theRoutes.find(
                                item =>
                                  item.parent === 'area3' &&
                                  item.subareaNumber === 2
                              ).path
                            }
                          >
                            {t('Human Rights Committee (HRCtee)')}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              theRoutes.find(
                                item =>
                                  item.parent === 'area3' &&
                                  item.subareaNumber === 3
                              ).path
                            }
                          >
                            {t(
                              'Committee on the Elimination of Discrim. against Women (CEDAW)'
                            )}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              theRoutes.find(
                                item =>
                                  item.parent === 'area3' &&
                                  item.subareaNumber === 4
                              ).path
                            }
                          >
                            {t('Committee against Torture (CAT)')}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              theRoutes.find(
                                item =>
                                  item.parent === 'area3' &&
                                  item.subareaNumber === 5
                              ).path
                            }
                          >
                            {t(
                              'Committee on Economic, Social and Cultural Rights (CESCR)'
                            )}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              theRoutes.find(
                                item =>
                                  item.parent === 'area3' &&
                                  item.subareaNumber === 6
                              ).path
                            }
                          >
                            {t('Committee on the Rights of the Child (CRC)')}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              theRoutes.find(
                                item =>
                                  item.parent === 'area3' &&
                                  item.subareaNumber === 7
                              ).path
                            }
                          >
                            {t(
                              'Committee on the Rights of Persons with Disabilities (CRPD)'
                            )}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              theRoutes.find(
                                item =>
                                  item.parent === 'area3' &&
                                  item.subareaNumber === 8
                              ).path
                            }
                          >
                            {t('Committee on Enforced Disappearances (CED)')}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              theRoutes.find(
                                item =>
                                  item.parent === 'area3' &&
                                  item.subareaNumber === 9
                              ).path
                            }
                          >
                            {t(
                              'Committee on the Elimination of Racial Discrimination (CERD)'
                            )}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              theRoutes.find(
                                item =>
                                  item.parent === 'area3' &&
                                  item.subareaNumber === 10
                              ).path
                            }
                          >
                            {t('Committee on Migrant Workers (CMW)')}
                          </Link>
                        </li>
                      </ol>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </li>
              <li>
                <Link to={theRoutes.find(item => item.areaNumber === 4).path}>
                  {t('Area')} 4 | <b>{t('UN Special Procedures')}</b>
                </Link>
              </li>
              <li>
                <Link to={theRoutes.find(item => item.areaNumber === 5).path}>
                  {t('Area')} 5 | <b>{t('Universal Periodic Review (UPR)')}</b>
                </Link>
              </li>
              {/* {<li>
                <Link to={theRoutes.find(item => item.areaNumber === 6).path}>
                  {t("Area")} 6 | <b>{t("UN Voting Record & Statements")}</b>
                </Link>
              </li>} */}
            </ul>
          </div>
          <div className="subNav__links">
            <Link className="mainLink soloLink" to={getPath('jur_home')}>
              {t('Jurisdictions')}
            </Link>
            <ul className="list-unstyled">
              <li>
                <Link to={getPath('jur_home')}>
                  {t('Search by jurisdiction')}
                </Link>
              </li>
            </ul>
            <Link className="mainLink tools" to="/tools">
              {t('Tools')}
            </Link>
            <ul className="list-unstyled">
              <li>
                <Link to={getPath('compare_tool')}>{t('Compare tool')}</Link>
              </li>

              <li>
                <Link to={getPath('advanced_search')}>
                  {t('Advanced search')}
                </Link>
              </li>
            </ul>
          </div>
          <div className="subNav__links">
            <ul className="list-unstyled">
              <li>
                <Link to={getPath('latest_updates')}>
                  {t('Latest updates')}
                </Link>
              </li>
              <li>
                <Link to={getPath('account')}>{t('Account')}</Link>
              </li>
              <li>
                <Link to={getPath('upcoming')}>{t('Upcoming Deadlines')}</Link>
              </li>
              <li>
                <Link to="/credits">{t('Credits')}</Link>
              </li>
              <li>
                <a href="https://ilga.org/about-us/contacts">
                  {t('Contact Us')}
                </a>
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Header
