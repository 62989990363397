var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [122.3067, 6.6453],
            [122.3042, 6.5903],
            [122.2128, 6.5803],
            [122.1817, 6.4525],
            [122.0383, 6.4114],
            [121.956, 6.4066],
            [121.8702, 6.5039],
            [121.8511, 6.5881],
            [121.7942, 6.6064],
            [121.8337, 6.6802],
            [121.9247, 6.6717],
            [121.9917, 6.7367],
            [122.0628, 6.7517],
            [122.1438, 6.67],
            [122.3067, 6.6453]
          ]
        ]
      },
      properties: {
        name: 'Basilan',
        id: 'PH-BAS',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-BAS'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.2539, 15.6185],
            [120.186, 15.5073],
            [120.1632, 15.3605],
            [120.2854, 15.2893],
            [120.3708, 15.1678],
            [120.3577, 15.081],
            [120.4081, 14.9668],
            [120.4394, 14.9492],
            [120.4012, 14.8818],
            [120.2872, 14.8186],
            [120.2133, 14.8158],
            [120.178, 14.7406],
            [120.0828, 14.7872],
            [120.0839, 14.8537],
            [120.0517, 14.8961],
            [120.0656, 15.0164],
            [120.0536, 15.1019],
            [119.9663, 15.347],
            [119.8897, 15.4308],
            [119.9681, 15.5078],
            [119.9037, 15.6213],
            [119.9336, 15.6927],
            [119.8883, 15.8067],
            [119.9908, 15.7923],
            [120.0288, 15.8453],
            [120.0854, 15.8109],
            [120.1487, 15.824],
            [120.186, 15.7268],
            [120.2539, 15.6185]
          ]
        ]
      },
      properties: {
        name: 'Zambales',
        id: 'PH-ZMB',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-ZMB'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.3609, 7.9099],
            [126.3512, 8.0009],
            [126.3002, 8.0654],
            [126.2939, 8.1166],
            [126.2329, 8.1745],
            [126.2212, 8.2295],
            [126.2911, 8.3112],
            [126.2593, 8.3747],
            [126.0985, 8.3793],
            [126.0601, 8.4167],
            [126.025, 8.5681],
            [125.9472, 8.8183],
            [125.8981, 8.8744],
            [125.878, 9.069],
            [125.8345, 9.1441],
            [125.7552, 9.2351],
            [125.7477, 9.3246],
            [125.9178, 9.4951],
            [125.9495, 9.4533],
            [125.8947, 9.4128],
            [125.9689, 9.3684],
            [126.0487, 9.2343],
            [126.1974, 9.2707],
            [126.1584, 9.1037],
            [126.3078, 8.9561],
            [126.3078, 8.8803],
            [126.3356, 8.84],
            [126.2544, 8.7728],
            [126.2122, 8.7111],
            [126.105, 8.6503],
            [126.0828, 8.6114],
            [126.16, 8.5414],
            [126.2086, 8.5581],
            [126.3142, 8.5389],
            [126.3972, 8.4925],
            [126.3486, 8.4289],
            [126.3308, 8.3142],
            [126.39, 8.2919],
            [126.315, 8.2278],
            [126.3444, 8.1839],
            [126.457, 8.2444],
            [126.4159, 8.0548],
            [126.4069, 7.9542],
            [126.3609, 7.9099]
          ]
        ]
      },
      properties: {
        name: 'Surigao del Sur',
        id: 'PH-SUR',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-SUR'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.6128, 15.8321],
            [120.6541, 15.6633],
            [120.7541, 15.6044],
            [120.722, 15.5488],
            [120.7649, 15.4172],
            [120.7392, 15.3381],
            [120.7719, 15.2676],
            [120.5514, 15.259],
            [120.393, 15.199],
            [120.3708, 15.1678],
            [120.2854, 15.2893],
            [120.1632, 15.3605],
            [120.186, 15.5073],
            [120.2539, 15.6185],
            [120.3239, 15.65],
            [120.3926, 15.7655],
            [120.4811, 15.7298],
            [120.6128, 15.8321]
          ]
        ]
      },
      properties: {
        name: 'Tarlac',
        id: 'PH-TAR',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-TAR'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [120.2106, 5.3492],
              [120.2305, 5.2338],
              [120.2189, 5.1472],
              [120.0972, 5.1511],
              [120.04, 5.1814],
              [119.9613, 5.077],
              [119.8608, 5.0471],
              [119.8204, 5.056],
              [119.8301, 5.1492],
              [119.9415, 5.1889],
              [119.9794, 5.2372],
              [120.0297, 5.2322],
              [120.1718, 5.3432],
              [120.2106, 5.3492]
            ]
          ],
          [
            [
              [119.4561, 4.9139],
              [119.495, 4.8356],
              [119.5072, 4.7603],
              [119.4819, 4.6683],
              [119.4447, 4.9022],
              [119.4561, 4.9139]
            ]
          ]
        ]
      },
      properties: {
        name: 'Tawi-Tawi',
        id: 'PH-TAW',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-TAW'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [123.5546, 8.6213],
            [123.5496, 8.2154],
            [123.3823, 8.2148],
            [123.3484, 8.2318],
            [123.2007, 8.2394],
            [123.1931, 8.1061],
            [123.167, 7.9958],
            [123.0778, 7.9983],
            [122.9623, 7.9142],
            [122.9159, 7.9186],
            [122.7533, 7.9434],
            [122.6629, 7.9297],
            [122.5516, 7.8775],
            [122.4515, 7.7946],
            [122.3578, 7.7494],
            [122.276, 7.6285],
            [122.2298, 7.53],
            [122.2187, 7.4609],
            [122.1691, 7.3653],
            [122.1456, 7.2195],
            [122.0719, 7.1495],
            [121.9014, 7.146],
            [121.9736, 7.2486],
            [122.0633, 7.2945],
            [122.0297, 7.3583],
            [122.055, 7.4468],
            [122.1431, 7.5742],
            [122.1165, 7.7224],
            [122.0853, 7.7361],
            [122.1602, 7.8585],
            [122.2264, 7.9083],
            [122.2261, 7.9606],
            [122.3453, 8.0383],
            [122.4619, 8.0722],
            [122.5103, 8.065],
            [122.6286, 8.1131],
            [122.8131, 8.1208],
            [122.9125, 8.1467],
            [122.9958, 8.2142],
            [122.9364, 8.3072],
            [122.9828, 8.3275],
            [122.9799, 8.4139],
            [123.0637, 8.5155],
            [123.1827, 8.5331],
            [123.2694, 8.512],
            [123.3305, 8.5629],
            [123.3502, 8.635],
            [123.4361, 8.7158],
            [123.5128, 8.6214],
            [123.5546, 8.6213]
          ]
        ]
      },
      properties: {
        name: 'Zamboanga del Norte',
        id: 'PH-ZAN',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-ZAN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [122.9372, 7.4011],
              [122.8997, 7.3194],
              [122.8053, 7.2964],
              [122.7875, 7.3456],
              [122.8022, 7.4225],
              [122.8872, 7.4275],
              [122.9372, 7.4011]
            ]
          ],
          [
            [
              [123.0975, 7.705],
              [122.9964, 7.4997],
              [122.9503, 7.4793],
              [122.8894, 7.5397],
              [122.8752, 7.4643],
              [122.7792, 7.4869],
              [122.8125, 7.7444],
              [122.6583, 7.7889],
              [122.5435, 7.7352],
              [122.4903, 7.6319],
              [122.4872, 7.5621],
              [122.3578, 7.4625],
              [122.3409, 7.3138],
              [122.2908, 7.3211],
              [122.2572, 7.1111],
              [122.2053, 7.0688],
              [122.1853, 6.9522],
              [122.1083, 6.8876],
              [121.9379, 6.9641],
              [121.8975, 7.0769],
              [121.9014, 7.146],
              [122.0719, 7.1495],
              [122.1456, 7.2195],
              [122.1691, 7.3653],
              [122.2187, 7.4609],
              [122.2298, 7.53],
              [122.276, 7.6285],
              [122.3578, 7.7494],
              [122.4515, 7.7946],
              [122.5516, 7.8775],
              [122.6629, 7.9297],
              [122.7533, 7.9434],
              [122.9159, 7.9186],
              [122.9158, 7.8319],
              [123.0854, 7.8104],
              [123.0975, 7.705]
            ]
          ]
        ]
      },
      properties: {
        name: 'Zamboanga Sibugay',
        id: 'PH-ZSI',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-ZSI'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.9278, 17.9781],
            [120.9872, 17.9396],
            [121.0102, 17.8437],
            [121.0998, 17.8242],
            [121.1227, 17.6839],
            [121.0915, 17.6668],
            [121.1102, 17.5609],
            [120.998, 17.4914],
            [120.9944, 17.4087],
            [120.9148, 17.2476],
            [120.8583, 17.1882],
            [120.7568, 17.1579],
            [120.6947, 17.1606],
            [120.6826, 17.2557],
            [120.6143, 17.3107],
            [120.5579, 17.3078],
            [120.5388, 17.3576],
            [120.5856, 17.4763],
            [120.4876, 17.545],
            [120.5025, 17.6397],
            [120.5349, 17.7037],
            [120.5818, 17.7992],
            [120.6902, 17.8349],
            [120.7275, 17.8929],
            [120.8332, 17.96],
            [120.9278, 17.9781]
          ]
        ]
      },
      properties: {
        name: 'Abra',
        id: 'PH-ABR',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-ABR'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.4531, 9.4396],
            [125.5617, 9.4585],
            [125.6867, 9.4357],
            [125.7405, 9.391],
            [125.7477, 9.3246],
            [125.7552, 9.2351],
            [125.7675, 9.1636],
            [125.7467, 9.073],
            [125.6959, 8.985],
            [125.6712, 8.8652],
            [125.6396, 8.8089],
            [125.6746, 8.6983],
            [125.4969, 8.7009],
            [125.4375, 8.6602],
            [125.2423, 8.6596],
            [125.2315, 8.8552],
            [125.2362, 8.9572],
            [125.2057, 9.0926],
            [125.2793, 8.9921],
            [125.4275, 8.9756],
            [125.5197, 9.0158],
            [125.535, 9.0722],
            [125.5302, 9.1941],
            [125.4531, 9.4396]
          ]
        ]
      },
      properties: {
        name: 'Agusan del Norte',
        id: 'PH-AGN',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-AGN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.7552, 9.2351],
            [125.8345, 9.1441],
            [125.878, 9.069],
            [125.8981, 8.8744],
            [125.9472, 8.8183],
            [126.025, 8.5681],
            [126.0601, 8.4167],
            [126.0985, 8.3793],
            [126.2593, 8.3747],
            [126.2911, 8.3112],
            [126.2212, 8.2295],
            [126.2329, 8.1745],
            [126.2939, 8.1166],
            [126.3002, 8.0654],
            [126.3512, 8.0009],
            [126.1629, 8],
            [126.0457, 7.935],
            [125.9808, 7.9982],
            [125.6752, 7.9982],
            [125.3816, 7.9855],
            [125.3879, 8.0775],
            [125.3309, 8.3001],
            [125.2612, 8.3705],
            [125.2442, 8.5816],
            [125.2423, 8.6596],
            [125.4375, 8.6602],
            [125.4969, 8.7009],
            [125.6746, 8.6983],
            [125.6396, 8.8089],
            [125.6712, 8.8652],
            [125.6959, 8.985],
            [125.7467, 9.073],
            [125.7675, 9.1636],
            [125.7552, 9.2351]
          ]
        ]
      },
      properties: {
        name: 'Agusan del Sur',
        id: 'PH-AGS',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-AGS'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [122.5756, 11.5517],
            [122.5108, 11.4804],
            [122.3947, 11.4791],
            [122.3309, 11.3847],
            [122.2748, 11.3323],
            [122.2001, 11.3127],
            [122.1907, 11.386],
            [122.1349, 11.4735],
            [122.1548, 11.5332],
            [122.1412, 11.6447],
            [122.1785, 11.7191],
            [122.1619, 11.758],
            [121.9869, 11.8277],
            [121.9338, 11.821],
            [121.8435, 11.7624],
            [121.8803, 11.8153],
            [121.8845, 11.899],
            [121.9585, 11.9372],
            [122.0803, 11.8378],
            [122.2222, 11.8139],
            [122.3095, 11.7349],
            [122.3834, 11.726],
            [122.418, 11.6678],
            [122.4214, 11.5928],
            [122.4961, 11.5956],
            [122.5756, 11.5517]
          ]
        ]
      },
      properties: {
        name: 'Aklan',
        id: 'PH-AKL',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-AKL'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [123.8545, 13.354],
              [123.9303, 13.3278],
              [123.8852, 13.2809],
              [123.8545, 13.354]
            ]
          ],
          [
            [
              [123.94, 13.1181],
              [123.9047, 13.0409],
              [123.8577, 13.0405],
              [123.7686, 12.9858],
              [123.7337, 13.0241],
              [123.6287, 13.0104],
              [123.574, 13.031],
              [123.4804, 12.9889],
              [123.4066, 13.046],
              [123.3215, 13.0057],
              [123.2885, 13.1474],
              [123.3274, 13.1972],
              [123.276, 13.262],
              [123.3874, 13.3408],
              [123.5816, 13.3966],
              [123.5431, 13.5042],
              [123.6008, 13.5233],
              [123.6773, 13.478],
              [123.7466, 13.3219],
              [123.867, 13.2408],
              [123.7747, 13.228],
              [123.7542, 13.1683],
              [123.8029, 13.048],
              [123.879, 13.1432],
              [123.94, 13.1181]
            ]
          ]
        ]
      },
      properties: {
        name: 'Albay',
        id: 'PH-ALB',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-ALB'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [121.8435, 11.7624],
            [121.9338, 11.821],
            [121.9869, 11.8277],
            [122.1619, 11.758],
            [122.1785, 11.7191],
            [122.1412, 11.6447],
            [122.1548, 11.5332],
            [122.1349, 11.4735],
            [122.1907, 11.386],
            [122.2001, 11.3127],
            [122.2017, 11.2148],
            [122.2389, 11.1406],
            [122.3203, 11.0292],
            [122.3132, 10.9584],
            [122.2009, 10.8342],
            [122.1754, 10.7562],
            [122.0736, 10.6902],
            [122.0338, 10.624],
            [122.0111, 10.494],
            [122.0301, 10.4671],
            [121.961, 10.4061],
            [121.9116, 10.4467],
            [121.9142, 10.5047],
            [121.9582, 10.5512],
            [121.9783, 10.6673],
            [121.9233, 10.7638],
            [121.984, 10.9303],
            [122.0539, 11.0322],
            [122.0328, 11.1906],
            [122.0581, 11.3431],
            [122.0519, 11.4681],
            [122.1019, 11.6467],
            [122.0998, 11.7057],
            [121.9178, 11.7674],
            [121.8435, 11.7624]
          ]
        ]
      },
      properties: {
        name: 'Antique',
        id: 'PH-ANT',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-ANT'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.961, 18.4475],
            [121.0436, 18.5292],
            [121.0895, 18.4861],
            [121.249, 18.4307],
            [121.4669, 18.3083],
            [121.4894, 18.2262],
            [121.4609, 18.0504],
            [121.4154, 18.0311],
            [121.3251, 17.8263],
            [121.4316, 17.7356],
            [121.443, 17.6898],
            [121.3894, 17.6413],
            [121.2538, 17.671],
            [121.2274, 17.6243],
            [121.1227, 17.6839],
            [121.0998, 17.8242],
            [121.0102, 17.8437],
            [120.9872, 17.9396],
            [120.9278, 17.9781],
            [120.9589, 18.1987],
            [120.9422, 18.3682],
            [120.961, 18.4475]
          ]
        ]
      },
      properties: {
        name: 'Apayao',
        id: 'PH-APA',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-APA'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [121.4195, 15.2152],
            [121.3982, 15.126],
            [121.3417, 15.0438],
            [121.3419, 14.9973],
            [121.3118, 15.1994],
            [121.3762, 15.4836],
            [121.2817, 15.7705],
            [121.4482, 15.9013],
            [121.8353, 16.2279],
            [122.065, 16.4879],
            [122.1317, 16.5301],
            [122.2722, 16.5236],
            [122.1995, 16.435],
            [122.2248, 16.3564],
            [122.1798, 16.3033],
            [122.2056, 16.2408],
            [122.0703, 16.12],
            [122.0636, 16.2167],
            [121.9361, 16.1256],
            [121.8706, 16.1215],
            [121.7797, 16.062],
            [121.7415, 16.0687],
            [121.6507, 15.9699],
            [121.5548, 15.8984],
            [121.5532, 15.7874],
            [121.6103, 15.7069],
            [121.5304, 15.5451],
            [121.4934, 15.5236],
            [121.4752, 15.4168],
            [121.3736, 15.3124],
            [121.4195, 15.2152]
          ]
        ]
      },
      properties: {
        name: 'Aurora',
        id: 'PH-AUR',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-AUR'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.5778, 14.8311],
            [120.5472, 14.7256],
            [120.6091, 14.4791],
            [120.5637, 14.4234],
            [120.3892, 14.459],
            [120.3742, 14.5247],
            [120.3931, 14.5875],
            [120.3126, 14.6256],
            [120.2484, 14.6919],
            [120.2414, 14.7608],
            [120.2872, 14.8186],
            [120.4012, 14.8818],
            [120.4394, 14.9492],
            [120.47, 14.8792],
            [120.5778, 14.8311]
          ]
        ]
      },
      properties: {
        name: 'Bataan',
        id: 'PH-BAN',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-BAN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [122.0036, 20.489],
            [122.0353, 20.4642],
            [121.9631, 20.4119],
            [121.9497, 20.3478],
            [121.915, 20.3472],
            [121.9264, 20.41],
            [122.0036, 20.489]
          ]
        ]
      },
      properties: {
        name: 'Batanes',
        id: 'PH-BTN',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-BTN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.6213, 14.2213],
            [120.6813, 14.2021],
            [120.7058, 14.1418],
            [120.7648, 14.1386],
            [120.8442, 14.0613],
            [120.9566, 14.0936],
            [121.0427, 14.1544],
            [121.1942, 14.1322],
            [121.216, 14.0277],
            [121.2613, 13.9646],
            [121.2725, 13.8772],
            [121.369, 13.8702],
            [121.4563, 13.8202],
            [121.4321, 13.7617],
            [121.4691, 13.6841],
            [121.3837, 13.668],
            [121.2855, 13.5968],
            [121.1835, 13.6447],
            [121.0789, 13.619],
            [121.0363, 13.6337],
            [121.0612, 13.7208],
            [121.0361, 13.765],
            [120.9272, 13.7622],
            [120.8808, 13.9011],
            [120.7419, 13.9361],
            [120.6531, 13.8606],
            [120.6098, 14.1455],
            [120.6213, 14.2213]
          ]
        ]
      },
      properties: {
        name: 'Batangas',
        id: 'PH-BTG',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-BTG'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.9009, 16.5777],
            [120.8913, 16.4339],
            [120.8455, 16.3209],
            [120.8059, 16.3231],
            [120.759, 16.243],
            [120.7689, 16.2014],
            [120.6553, 16.1877],
            [120.5125, 16.237],
            [120.4693, 16.4974],
            [120.5588, 16.597],
            [120.5804, 16.6592],
            [120.6203, 16.7307],
            [120.6434, 16.8957],
            [120.7755, 16.9348],
            [120.8612, 16.8086],
            [120.8823, 16.7727],
            [120.8823, 16.6014],
            [120.9009, 16.5777]
          ]
        ]
      },
      properties: {
        name: 'Benguet',
        id: 'PH-BEN',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-BEN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [124.4263, 11.7068],
            [124.5367, 11.6753],
            [124.5828, 11.5688],
            [124.6174, 11.5435],
            [124.5775, 11.4725],
            [124.48, 11.4626],
            [124.4423, 11.4876],
            [124.3451, 11.6704],
            [124.4263, 11.7068]
          ]
        ]
      },
      properties: {
        name: 'Biliran',
        id: 'PH-BIL',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-BIL'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [123.8371, 9.6441],
              [123.8697, 9.6042],
              [123.7988, 9.5518],
              [123.7181, 9.5876],
              [123.8371, 9.6441]
            ]
          ],
          [
            [
              [124.2912, 10.1735],
              [124.369, 10.1541],
              [124.4804, 10.0564],
              [124.5473, 10.0403],
              [124.5593, 9.8723],
              [124.5967, 9.8051],
              [124.5906, 9.7533],
              [124.52, 9.7217],
              [124.4844, 9.7606],
              [124.4082, 9.7097],
              [124.3991, 9.6524],
              [124.2577, 9.598],
              [124.1866, 9.6097],
              [124.0583, 9.5886],
              [123.8536, 9.6364],
              [123.8699, 9.7172],
              [123.787, 9.7393],
              [123.7845, 9.7931],
              [123.84, 9.8835],
              [123.9604, 9.9566],
              [124.0261, 9.9675],
              [124.0621, 10.0681],
              [124.0987, 10.0664],
              [124.1524, 10.1536],
              [124.2912, 10.1735]
            ]
          ]
        ]
      },
      properties: {
        name: 'Bohol',
        id: 'PH-BOH',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-BOH'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.2442, 8.5816],
            [125.2612, 8.3705],
            [125.3309, 8.3001],
            [125.3879, 8.0775],
            [125.3816, 7.9855],
            [125.4209, 7.9119],
            [125.4174, 7.8173],
            [125.4428, 7.7649],
            [125.4307, 7.6556],
            [125.3959, 7.6214],
            [125.2524, 7.6437],
            [125.2504, 7.5794],
            [125.2526, 7.5696],
            [125.1879, 7.5171],
            [125.1765, 7.459],
            [125.0889, 7.4305],
            [125.049, 7.3819],
            [124.8796, 7.4048],
            [124.8397, 7.4795],
            [124.8067, 7.6036],
            [124.7538, 7.6532],
            [124.6606, 7.836],
            [124.6312, 7.8464],
            [124.5573, 7.984],
            [124.5326, 8.0923],
            [124.4956, 8.138],
            [124.5604, 8.1587],
            [124.5652, 8.2352],
            [124.6043, 8.3056],
            [124.6174, 8.4247],
            [124.8162, 8.4221],
            [124.8137, 8.4743],
            [124.868, 8.5941],
            [125.0292, 8.5793],
            [125.2442, 8.5816]
          ]
        ]
      },
      properties: {
        name: 'Bukidnon',
        id: 'PH-BUK',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-BUK'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [121.3419, 14.9973],
            [121.3454, 14.8923],
            [121.2585, 14.8413],
            [121.1824, 14.8266],
            [121.1139, 14.7597],
            [121.0077, 14.7479],
            [120.9578, 14.6875],
            [120.9169, 14.6919],
            [120.84, 14.7411],
            [120.6839, 14.7703],
            [120.6199, 14.7977],
            [120.7406, 14.9507],
            [120.8148, 14.9469],
            [120.8872, 15.025],
            [120.8872, 15.0878],
            [120.9272, 15.1335],
            [120.918, 15.2113],
            [121.0212, 15.2524],
            [121.1439, 15.2725],
            [121.2413, 15.2043],
            [121.3118, 15.1994],
            [121.3419, 14.9973]
          ]
        ]
      },
      properties: {
        name: 'Bulacan',
        id: 'PH-BUL',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-BUL'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [122.1905, 17.5481],
              [121.8515, 17.535],
              [121.8211, 17.5181],
              [121.6561, 17.5082],
              [121.4796, 17.6726],
              [121.443, 17.6898],
              [121.4316, 17.7356],
              [121.3251, 17.8263],
              [121.4154, 18.0311],
              [121.4609, 18.0504],
              [121.4894, 18.2262],
              [121.4669, 18.3083],
              [121.249, 18.4307],
              [121.0895, 18.4861],
              [121.0436, 18.5292],
              [120.961, 18.4475],
              [120.9728, 18.5823],
              [121.0344, 18.6204],
              [121.1246, 18.628],
              [121.1977, 18.6083],
              [121.304, 18.5268],
              [121.608, 18.3711],
              [121.8928, 18.2703],
              [122.0113, 18.2878],
              [122.13, 18.3817],
              [122.1472, 18.5074],
              [122.2353, 18.5168],
              [122.2495, 18.4494],
              [122.3139, 18.3922],
              [122.3389, 18.3081],
              [122.3023, 18.2179],
              [122.2167, 18.152],
              [122.1664, 18.0826],
              [122.1902, 18.017],
              [122.1889, 17.9019],
              [122.1372, 17.7931],
              [122.1685, 17.6986],
              [122.1628, 17.6114],
              [122.1905, 17.5481]
            ]
          ],
          [
            [
              [121.9172, 19.0043],
              [121.9921, 18.96],
              [121.9529, 18.9028],
              [121.8372, 18.8204],
              [121.8272, 18.875],
              [121.8662, 18.9735],
              [121.9172, 19.0043]
            ]
          ],
          [
            [
              [121.4093, 19.3927],
              [121.4882, 19.3669],
              [121.5422, 19.2736],
              [121.5119, 19.2439],
              [121.403, 19.283],
              [121.3797, 19.3644],
              [121.4093, 19.3927]
            ]
          ],
          [
            [
              [121.9697, 19.574],
              [121.9947, 19.5122],
              [121.9592, 19.4753],
              [121.8919, 19.5406],
              [121.9697, 19.574]
            ]
          ]
        ]
      },
      properties: {
        name: 'Cagayan',
        id: 'PH-CAG',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-CAG'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [123.0592, 13.8371],
            [122.9417, 13.9298],
            [122.7926, 14.0144],
            [122.4442, 14.1486],
            [122.3676, 14.0652],
            [122.3017, 14.0827],
            [122.3489, 14.1237],
            [122.3325, 14.1986],
            [122.4692, 14.3401],
            [122.5227, 14.345],
            [122.6102, 14.3144],
            [122.7147, 14.339],
            [122.8643, 14.2706],
            [122.9886, 14.1236],
            [123.0435, 14.0989],
            [123.0303, 14.034],
            [123.0839, 13.9817],
            [123.0957, 13.8926],
            [123.0592, 13.8371]
          ]
        ]
      },
      properties: {
        name: 'Camarines Norte',
        id: 'PH-CAN',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-CAN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [123.6008, 13.5233],
            [123.5431, 13.5042],
            [123.5816, 13.3966],
            [123.3874, 13.3408],
            [123.276, 13.262],
            [123.2101, 13.3462],
            [123.1886, 13.4341],
            [123.0481, 13.5082],
            [122.9755, 13.5213],
            [122.8873, 13.5766],
            [122.8237, 13.65],
            [122.8358, 13.7349],
            [122.7493, 13.7908],
            [122.6371, 13.8391],
            [122.6399, 13.883],
            [122.5483, 13.9495],
            [122.7926, 14.0144],
            [122.9417, 13.9298],
            [123.0592, 13.8371],
            [123.046, 13.7764],
            [123.1229, 13.7264],
            [123.2336, 13.7306],
            [123.277, 13.7511],
            [123.3262, 13.8273],
            [123.2945, 13.9268],
            [123.2276, 13.9981],
            [123.3467, 14.0686],
            [123.3433, 14.0136],
            [123.6023, 13.8931],
            [123.6797, 13.8777],
            [123.7305, 13.9202],
            [123.7597, 13.8643],
            [123.8471, 13.8109],
            [123.9414, 13.7957],
            [123.9642, 13.7263],
            [123.8732, 13.7375],
            [123.8023, 13.6877],
            [123.7502, 13.7107],
            [123.5701, 13.7173],
            [123.5315, 13.5664],
            [123.6008, 13.5233]
          ]
        ]
      },
      properties: {
        name: 'Camarines Sur',
        id: 'PH-CAS',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-CAS'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [124.7101, 9.2574],
            [124.7788, 9.2037],
            [124.8066, 9.1211],
            [124.7756, 9.0801],
            [124.6768, 9.1189],
            [124.6352, 9.1699],
            [124.643, 9.233],
            [124.7101, 9.2574]
          ]
        ]
      },
      properties: {
        name: 'Camiguin',
        id: 'PH-CAM',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-CAM'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [123.0971, 11.5445],
            [123.0494, 11.4501],
            [123.0498, 11.3969],
            [122.9366, 11.359],
            [122.9483, 11.3051],
            [122.89, 11.2776],
            [122.852, 11.1919],
            [122.7822, 11.1819],
            [122.7638, 11.2282],
            [122.6517, 11.2118],
            [122.6269, 11.2357],
            [122.4949, 11.1787],
            [122.391, 11.1913],
            [122.3222, 11.2268],
            [122.2389, 11.1406],
            [122.2017, 11.2148],
            [122.2001, 11.3127],
            [122.2748, 11.3323],
            [122.3309, 11.3847],
            [122.3947, 11.4791],
            [122.5108, 11.4804],
            [122.5756, 11.5517],
            [122.6267, 11.5181],
            [122.7156, 11.6122],
            [122.8275, 11.6103],
            [122.8778, 11.5447],
            [122.9894, 11.4833],
            [123.0971, 11.5445]
          ]
        ]
      },
      properties: {
        name: 'Capiz',
        id: 'PH-CAP',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-CAP'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [124.2072, 14.099],
            [124.2715, 14.0168],
            [124.2853, 13.9438],
            [124.3957, 13.8992],
            [124.4215, 13.8471],
            [124.385, 13.7161],
            [124.4163, 13.6644],
            [124.3422, 13.6399],
            [124.2874, 13.5932],
            [124.1717, 13.5311],
            [124.0232, 13.6627],
            [124.0951, 13.7046],
            [124.1358, 13.7954],
            [124.128, 13.8843],
            [124.1481, 13.9266],
            [124.1396, 14.0147],
            [124.2072, 14.099]
          ]
        ]
      },
      properties: {
        name: 'Catanduanes',
        id: 'PH-CAT',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-CAT'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.9696, 14.4755],
            [121.0128, 14.3503],
            [121.0708, 14.3289],
            [121.0427, 14.1544],
            [120.9566, 14.0936],
            [120.8442, 14.0613],
            [120.7648, 14.1386],
            [120.7058, 14.1418],
            [120.6813, 14.2021],
            [120.6213, 14.2213],
            [120.6225, 14.2664],
            [120.6931, 14.281],
            [120.7686, 14.3323],
            [120.9053, 14.4592],
            [120.9696, 14.4755]
          ]
        ]
      },
      properties: {
        name: 'Cavite',
        id: 'PH-CAV',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-CAV'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [124.4388, 10.7178],
              [124.5093, 10.6799],
              [124.4425, 10.6273],
              [124.381, 10.6237],
              [124.3117, 10.5864],
              [124.3159, 10.7094],
              [124.3745, 10.6903],
              [124.4388, 10.7178]
            ]
          ],
          [
            [
              [124.0623, 11.2789],
              [124.0355, 11.1485],
              [123.9828, 11.0733],
              [124.0423, 11.0545],
              [124.0303, 10.9289],
              [124.0548, 10.8816],
              [124.0047, 10.7558],
              [124.0371, 10.5513],
              [123.9659, 10.328],
              [123.8843, 10.289],
              [123.8629, 10.2479],
              [123.7654, 10.2254],
              [123.7562, 10.196],
              [123.641, 10.0839],
              [123.615, 9.9956],
              [123.6088, 9.8746],
              [123.5361, 9.8031],
              [123.5377, 9.7578],
              [123.467, 9.5713],
              [123.3524, 9.4235],
              [123.3021, 9.4164],
              [123.2981, 9.5003],
              [123.3442, 9.7856],
              [123.4014, 9.9042],
              [123.4147, 10.0511],
              [123.5174, 10.1528],
              [123.5824, 10.2643],
              [123.5814, 10.3296],
              [123.6676, 10.4143],
              [123.714, 10.4975],
              [123.704, 10.5438],
              [123.7415, 10.633],
              [123.8319, 10.7351],
              [123.9337, 10.9935],
              [123.9011, 11.0339],
              [123.9664, 11.0842],
              [123.9439, 11.1867],
              [123.9992, 11.272],
              [124.0623, 11.2789]
            ]
          ],
          [
            [
              [123.73, 11.3006],
              [123.8075, 11.1514],
              [123.7208, 11.1375],
              [123.6876, 11.228],
              [123.73, 11.3006]
            ]
          ]
        ]
      },
      properties: {
        name: 'Cebu',
        id: 'PH-CEB',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-CEB'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.1629, 8],
            [126.1473, 7.9711],
            [126.2468, 7.6447],
            [126.2901, 7.4455],
            [126.2957, 7.2707],
            [125.9523, 7.0951],
            [125.9424, 7.0743],
            [125.884, 7.1257],
            [125.8925, 7.1656],
            [125.8378, 7.2535],
            [125.8319, 7.362],
            [125.8342, 7.4614],
            [125.9194, 7.5426],
            [125.9382, 7.6467],
            [125.9032, 7.6908],
            [125.9443, 7.7596],
            [125.9199, 7.8262],
            [125.8669, 7.7667],
            [125.8009, 7.6549],
            [125.7685, 7.7267],
            [125.7034, 7.7826],
            [125.6752, 7.9982],
            [125.9808, 7.9982],
            [126.0457, 7.935],
            [126.1629, 8]
          ]
        ]
      },
      properties: {
        name: 'Compostela Valley',
        id: 'PH-COM',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-COM'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.3512, 8.0009],
            [126.3609, 7.9099],
            [126.3836, 7.8297],
            [126.4252, 7.8284],
            [126.5011, 7.7378],
            [126.5703, 7.7183],
            [126.5525, 7.6364],
            [126.5991, 7.4832],
            [126.5579, 7.4472],
            [126.5701, 7.3265],
            [126.5617, 7.2028],
            [126.5238, 7.1695],
            [126.3986, 6.9905],
            [126.34, 7.0064],
            [126.2906, 6.9044],
            [126.1971, 6.9513],
            [126.1821, 6.876],
            [126.2611, 6.754],
            [126.2198, 6.6348],
            [126.2322, 6.5511],
            [126.2129, 6.5136],
            [126.2278, 6.4292],
            [126.1666, 6.3704],
            [126.0836, 6.5312],
            [126.0927, 6.5982],
            [126.0686, 6.656],
            [126.0969, 6.7375],
            [126.0799, 6.844],
            [125.9767, 6.9286],
            [125.99, 7.0125],
            [125.9424, 7.0743],
            [125.9523, 7.0951],
            [126.2957, 7.2707],
            [126.2901, 7.4455],
            [126.2468, 7.6447],
            [126.1473, 7.9711],
            [126.1629, 8],
            [126.3512, 8.0009]
          ]
        ]
      },
      properties: {
        name: 'Davao Oriental',
        id: 'PH-DAO',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-DAO'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.7146, 7.3002],
            [125.6484, 7.2377],
            [125.6642, 7.1258],
            [125.605, 7.0467],
            [125.5581, 7.0464],
            [125.4867, 6.98],
            [125.4894, 6.9201],
            [125.3922, 6.8031],
            [125.3729, 6.726],
            [125.3875, 6.5997],
            [125.4476, 6.5773],
            [125.3808, 6.5085],
            [125.3042, 6.4627],
            [125.2299, 6.4456],
            [125.1764, 6.4766],
            [125.1779, 6.5057],
            [125.1747, 6.6083],
            [125.1224, 6.6759],
            [125.1208, 6.7563],
            [125.1006, 6.8187],
            [125.255, 6.9567],
            [125.2892, 7.0603],
            [125.2598, 7.1431],
            [125.2559, 7.3333],
            [125.2419, 7.4635],
            [125.2536, 7.5648],
            [125.5054, 7.5584],
            [125.5439, 7.4442],
            [125.5682, 7.4414],
            [125.5706, 7.2421],
            [125.6142, 7.2442],
            [125.6698, 7.3005],
            [125.7146, 7.3002]
          ]
        ]
      },
      properties: {
        name: 'Davao del Sur',
        id: 'PH-DAS',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-DAS'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [125.8319, 7.362],
              [125.7146, 7.3002],
              [125.6698, 7.3005],
              [125.6142, 7.2442],
              [125.5706, 7.2421],
              [125.5682, 7.4414],
              [125.5439, 7.4442],
              [125.5054, 7.5584],
              [125.2536, 7.5648],
              [125.2504, 7.5794],
              [125.2524, 7.6437],
              [125.3959, 7.6214],
              [125.4307, 7.6556],
              [125.4428, 7.7649],
              [125.4174, 7.8173],
              [125.4209, 7.9119],
              [125.3816, 7.9855],
              [125.6752, 7.9982],
              [125.7034, 7.7826],
              [125.7685, 7.7267],
              [125.8009, 7.6549],
              [125.8669, 7.7667],
              [125.9199, 7.8262],
              [125.9443, 7.7596],
              [125.9032, 7.6908],
              [125.9382, 7.6467],
              [125.9194, 7.5426],
              [125.8342, 7.4614],
              [125.8319, 7.362]
            ]
          ],
          [
            [
              [125.7974, 7.1173],
              [125.776, 7.0317],
              [125.7933, 6.9384],
              [125.7231, 6.9645],
              [125.7119, 7.0689],
              [125.6639, 7.0938],
              [125.7074, 7.1975],
              [125.7974, 7.1173]
            ]
          ]
        ]
      },
      properties: {
        name: 'Davao del Norte',
        id: 'PH-DAV',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-DAV'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.322, 5.569],
            [125.3945, 5.6535],
            [125.4876, 5.8506],
            [125.48, 5.9079],
            [125.5341, 6.0575],
            [125.5037, 6.2343],
            [125.4284, 6.3277],
            [125.4446, 6.4132],
            [125.3808, 6.5085],
            [125.4476, 6.5773],
            [125.5197, 6.5551],
            [125.5938, 6.501],
            [125.5808, 6.428],
            [125.6178, 6.4111],
            [125.6715, 6.2601],
            [125.7029, 6.2144],
            [125.7151, 6.1028],
            [125.662, 5.9208],
            [125.5132, 5.7503],
            [125.4978, 5.703],
            [125.4134, 5.5721],
            [125.322, 5.569]
          ]
        ]
      },
      properties: {
        name: 'Davao Occidental',
        id: 'PH-DVO',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-DVO'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.6423, 10.4719],
            [125.6812, 10.392],
            [125.6468, 10.3056],
            [125.6608, 10.1208],
            [125.6981, 10.0589],
            [125.6567, 9.9481],
            [125.5775, 9.9758],
            [125.5753, 10.0433],
            [125.5097, 10.0622],
            [125.4732, 10.1346],
            [125.5125, 10.2428],
            [125.5139, 10.3217],
            [125.5959, 10.3656],
            [125.6423, 10.4719]
          ]
        ]
      },
      properties: {
        name: 'Dinagat Islands',
        id: 'PH-DIN',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-DIN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.1529, 12.2868],
            [125.1957, 12.3716],
            [125.2935, 12.2629],
            [125.3228, 12.2802],
            [125.4383, 12.2736],
            [125.5153, 12.1914],
            [125.455, 12.1647],
            [125.4447, 12.1033],
            [125.4981, 12.0608],
            [125.4264, 11.9558],
            [125.4367, 11.8242],
            [125.4925, 11.6731],
            [125.4383, 11.6133],
            [125.5156, 11.4939],
            [125.5525, 11.4061],
            [125.6331, 11.3569],
            [125.6142, 11.3017],
            [125.5469, 11.2592],
            [125.5336, 11.1989],
            [125.5972, 11.1789],
            [125.6656, 11.1981],
            [125.7633, 11.0328],
            [125.7186, 11.0292],
            [125.6722, 11.0836],
            [125.6605, 11.1417],
            [125.5828, 11.1131],
            [125.5303, 11.1497],
            [125.4053, 11.1003],
            [125.2531, 11.1176],
            [125.2492, 11.317],
            [125.3066, 11.3981],
            [125.2307, 11.4433],
            [125.2063, 11.598],
            [125.2817, 11.6769],
            [125.2696, 11.8533],
            [125.2076, 11.9945],
            [125.1149, 11.9943],
            [125.1123, 12.1658],
            [125.1456, 12.187],
            [125.1529, 12.2868]
          ]
        ]
      },
      properties: {
        name: 'Eastern Samar',
        id: 'PH-EAS',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-EAS'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [122.6602, 10.7535],
            [122.7138, 10.7159],
            [122.7272, 10.6161],
            [122.6819, 10.5005],
            [122.6275, 10.4344],
            [122.5147, 10.4061],
            [122.4742, 10.4804],
            [122.5225, 10.5161],
            [122.5095, 10.581],
            [122.609, 10.6757],
            [122.6602, 10.7535]
          ]
        ]
      },
      properties: {
        name: 'Guimaras',
        id: 'PH-GUI',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-GUI'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [121.562, 17.0319],
            [121.5872, 16.9015],
            [121.5219, 16.8293],
            [121.335, 16.7473],
            [121.2668, 16.6271],
            [120.9009, 16.5777],
            [120.8823, 16.6014],
            [120.8823, 16.7727],
            [120.8612, 16.8086],
            [120.9479, 16.8579],
            [120.9825, 16.9337],
            [120.9713, 16.9763],
            [121.2116, 16.9726],
            [121.322, 17.024],
            [121.4571, 17.0544],
            [121.562, 17.0319]
          ]
        ]
      },
      properties: {
        name: 'Ifugao',
        id: 'PH-IFU',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-IFU'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.9728, 18.5823],
            [120.961, 18.4475],
            [120.9422, 18.3682],
            [120.9589, 18.1987],
            [120.9278, 17.9781],
            [120.8332, 17.96],
            [120.7275, 17.8929],
            [120.6902, 17.8349],
            [120.5818, 17.7992],
            [120.5349, 17.7037],
            [120.5109, 17.7538],
            [120.5315, 17.8838],
            [120.4439, 17.9026],
            [120.4744, 17.9764],
            [120.4775, 18.0817],
            [120.5219, 18.2008],
            [120.6006, 18.3456],
            [120.5625, 18.4925],
            [120.6247, 18.5461],
            [120.7147, 18.5286],
            [120.7722, 18.5431],
            [120.7819, 18.6217],
            [120.8472, 18.6511],
            [120.9117, 18.5633],
            [120.9728, 18.5823]
          ]
        ]
      },
      properties: {
        name: 'Ilocos Norte',
        id: 'PH-ILN',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-ILN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.4439, 17.9026],
            [120.5315, 17.8838],
            [120.5109, 17.7538],
            [120.5349, 17.7037],
            [120.5025, 17.6397],
            [120.4876, 17.545],
            [120.5856, 17.4763],
            [120.5388, 17.3576],
            [120.5579, 17.3078],
            [120.6143, 17.3107],
            [120.6826, 17.2557],
            [120.6947, 17.1606],
            [120.7568, 17.1579],
            [120.8583, 17.1882],
            [120.7806, 17.1104],
            [120.7755, 16.9348],
            [120.6434, 16.8957],
            [120.6203, 16.7307],
            [120.5804, 16.6592],
            [120.5321, 16.7156],
            [120.5495, 16.7695],
            [120.4867, 16.9007],
            [120.4103, 16.9206],
            [120.4469, 16.9802],
            [120.4262, 17.1763],
            [120.4033, 17.2111],
            [120.4647, 17.4078],
            [120.4239, 17.5078],
            [120.344, 17.5761],
            [120.3764, 17.6833],
            [120.4361, 17.7331],
            [120.4026, 17.7949],
            [120.458, 17.825],
            [120.4439, 17.9026]
          ]
        ]
      },
      properties: {
        name: 'Ilocos Sur',
        id: 'PH-ILS',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-ILS'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [122.0301, 10.4671],
            [122.0111, 10.494],
            [122.0338, 10.624],
            [122.0736, 10.6902],
            [122.1754, 10.7562],
            [122.2009, 10.8342],
            [122.3132, 10.9584],
            [122.3203, 11.0292],
            [122.2389, 11.1406],
            [122.3222, 11.2268],
            [122.391, 11.1913],
            [122.4949, 11.1787],
            [122.6269, 11.2357],
            [122.6517, 11.2118],
            [122.7638, 11.2282],
            [122.7822, 11.1819],
            [122.852, 11.1919],
            [122.89, 11.2776],
            [122.9483, 11.3051],
            [122.9366, 11.359],
            [123.0498, 11.3969],
            [123.0494, 11.4501],
            [123.0971, 11.5445],
            [123.1655, 11.4695],
            [123.1522, 11.3931],
            [123.0931, 11.2281],
            [123.1039, 11.1553],
            [123.0306, 11.1678],
            [123.0097, 11.0933],
            [122.9525, 11.0333],
            [122.872, 11.0319],
            [122.7817, 10.9869],
            [122.76, 10.9469],
            [122.7839, 10.8556],
            [122.7278, 10.7818],
            [122.61, 10.7733],
            [122.5815, 10.6873],
            [122.4572, 10.688],
            [122.2074, 10.6385],
            [122.0952, 10.5744],
            [122.0301, 10.4671]
          ]
        ]
      },
      properties: {
        name: 'Iloilo',
        id: 'PH-ILI',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-ILI'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [121.335, 16.7473],
            [121.5219, 16.8293],
            [121.5872, 16.9015],
            [121.562, 17.0319],
            [121.5498, 17.2792],
            [121.6045, 17.3966],
            [121.675, 17.4448],
            [121.6561, 17.5082],
            [121.8211, 17.5181],
            [121.8515, 17.535],
            [122.1905, 17.5481],
            [122.19, 17.5061],
            [122.2864, 17.3403],
            [122.4074, 17.3333],
            [122.4436, 17.2639],
            [122.4122, 17.1835],
            [122.4481, 17.1153],
            [122.5289, 17.0981],
            [122.4666, 16.9766],
            [122.4667, 16.8878],
            [122.2989, 16.5519],
            [122.2722, 16.5236],
            [122.1317, 16.5301],
            [122.0823, 16.5335],
            [121.8267, 16.4211],
            [121.7389, 16.3644],
            [121.5447, 16.6042],
            [121.4042, 16.6102],
            [121.4005, 16.6874],
            [121.335, 16.7473]
          ]
        ]
      },
      properties: {
        name: 'Isabela',
        id: 'PH-ISA',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-ISA'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [121.443, 17.6898],
            [121.4796, 17.6726],
            [121.6561, 17.5082],
            [121.675, 17.4448],
            [121.6045, 17.3966],
            [121.5498, 17.2792],
            [121.4105, 17.3044],
            [121.3412, 17.2813],
            [121.2497, 17.1862],
            [121.1622, 17.1682],
            [121.0865, 17.1933],
            [121.0565, 17.2338],
            [120.9148, 17.2476],
            [120.9944, 17.4087],
            [120.998, 17.4914],
            [121.1102, 17.5609],
            [121.0915, 17.6668],
            [121.1227, 17.6839],
            [121.2274, 17.6243],
            [121.2538, 17.671],
            [121.3894, 17.6413],
            [121.443, 17.6898]
          ]
        ]
      },
      properties: {
        name: 'Kalinga',
        id: 'PH-KAL',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-KAL'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.4103, 16.9206],
            [120.4867, 16.9007],
            [120.5495, 16.7695],
            [120.5321, 16.7156],
            [120.5804, 16.6592],
            [120.5588, 16.597],
            [120.4693, 16.4974],
            [120.5125, 16.237],
            [120.4154, 16.2084],
            [120.3475, 16.282],
            [120.3223, 16.3901],
            [120.3294, 16.4691],
            [120.3034, 16.5017],
            [120.308, 16.6447],
            [120.3379, 16.6845],
            [120.3239, 16.8014],
            [120.3983, 16.8794],
            [120.4103, 16.9206]
          ]
        ]
      },
      properties: {
        name: 'La Union',
        id: 'PH-LUN',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-LUN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [121.4698, 14.5758],
            [121.5588, 14.4089],
            [121.6228, 14.3936],
            [121.6305, 14.2612],
            [121.5648, 14.1708],
            [121.5224, 14.1471],
            [121.4925, 14.0747],
            [121.4312, 14.0648],
            [121.3297, 13.9866],
            [121.2613, 13.9646],
            [121.216, 14.0277],
            [121.1942, 14.1322],
            [121.0427, 14.1544],
            [121.0708, 14.3289],
            [121.0128, 14.3503],
            [121.0558, 14.3785],
            [121.0619, 14.4942],
            [121.1053, 14.5305],
            [121.2194, 14.4744],
            [121.2794, 14.5134],
            [121.3403, 14.4123],
            [121.3058, 14.3285],
            [121.3884, 14.3451],
            [121.3598, 14.4573],
            [121.3755, 14.5186],
            [121.4698, 14.5758]
          ]
        ]
      },
      properties: {
        name: 'Laguna',
        id: 'PH-LAG',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-LAG'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [124.2455, 8.3268],
            [124.3221, 8.3405],
            [124.4719, 8.3314],
            [124.5336, 8.2924],
            [124.5652, 8.2352],
            [124.5604, 8.1587],
            [124.4956, 8.138],
            [124.4662, 8.1603],
            [124.2984, 8.2078],
            [124.2793, 8.0823],
            [124.2141, 8.0158],
            [124.1276, 8.002],
            [124.1116, 7.9048],
            [124.0234, 7.8996],
            [124.0454, 7.8251],
            [124.0009, 7.6942],
            [123.8372, 7.7443],
            [123.8154, 7.7001],
            [123.6919, 7.8128],
            [123.6217, 7.8285],
            [123.6704, 7.9535],
            [123.7484, 7.9993],
            [123.7867, 8.0574],
            [123.8991, 8.1146],
            [124.0175, 8.1978],
            [124.1547, 8.185],
            [124.2211, 8.2111],
            [124.2639, 8.2786],
            [124.2455, 8.3268]
          ]
        ]
      },
      properties: {
        name: 'Lanao del Norte',
        id: 'PH-LAN',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-LAN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [124.4956, 8.138],
            [124.5326, 8.0923],
            [124.5573, 7.984],
            [124.6312, 7.8464],
            [124.6606, 7.836],
            [124.7538, 7.6532],
            [124.8067, 7.6036],
            [124.7003, 7.6398],
            [124.6081, 7.6224],
            [124.5684, 7.6731],
            [124.4961, 7.6786],
            [124.4405, 7.652],
            [124.3654, 7.5678],
            [124.2498, 7.5092],
            [124.1985, 7.421],
            [124.0134, 7.6469],
            [123.9152, 7.6927],
            [123.8154, 7.7001],
            [123.8372, 7.7443],
            [124.0009, 7.6942],
            [124.0454, 7.8251],
            [124.0234, 7.8996],
            [124.1116, 7.9048],
            [124.1276, 8.002],
            [124.2141, 8.0158],
            [124.2793, 8.0823],
            [124.2984, 8.2078],
            [124.4662, 8.1603],
            [124.4956, 8.138]
          ]
        ]
      },
      properties: {
        name: 'Lanao del Sur',
        id: 'PH-LAS',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-LAS'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.1494, 10.6222],
            [125.053, 10.5239],
            [124.9398, 10.5243],
            [124.8932, 10.4044],
            [124.8999, 10.265],
            [124.8474, 10.2538],
            [124.7594, 10.1921],
            [124.7905, 10.3177],
            [124.7325, 10.3763],
            [124.7201, 10.4844],
            [124.7661, 10.5369],
            [124.7707, 10.6213],
            [124.8036, 10.6598],
            [124.7826, 10.7759],
            [124.707, 10.8801],
            [124.6839, 10.9381],
            [124.5952, 11.0135],
            [124.5383, 10.9775],
            [124.551, 10.9297],
            [124.5166, 10.8676],
            [124.3754, 10.9231],
            [124.4093, 10.9824],
            [124.3692, 11.0643],
            [124.4013, 11.1106],
            [124.3876, 11.243],
            [124.3419, 11.3147],
            [124.3413, 11.3624],
            [124.2894, 11.4935],
            [124.315, 11.5636],
            [124.4374, 11.4425],
            [124.537, 11.425],
            [124.5517, 11.3396],
            [124.5848, 11.305],
            [124.7104, 11.3075],
            [124.8016, 11.3753],
            [124.8155, 11.4324],
            [124.9143, 11.4085],
            [124.9789, 11.3694],
            [124.9627, 11.2803],
            [125.0083, 11.2514],
            [125.0075, 11.155],
            [125.0397, 11.0653],
            [125.0347, 10.9331],
            [125.0028, 10.8428],
            [125.0264, 10.7367],
            [125.1494, 10.6222]
          ]
        ]
      },
      properties: {
        name: 'Leyte',
        id: 'PH-LEY',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-LEY'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.0037, 6.7206],
            [124.8855, 6.6565],
            [124.7993, 6.6494],
            [124.7459, 6.782],
            [124.6663, 6.7837],
            [124.6958, 6.8682],
            [124.6583, 6.8834],
            [124.5563, 6.8617],
            [124.5798, 6.7956],
            [124.5109, 6.7232],
            [124.2825, 6.724],
            [124.2653, 6.7535],
            [124.0411, 6.7508],
            [123.9601, 6.7878],
            [123.9567, 6.9108],
            [124.0014, 7.0422],
            [124.0664, 7.1453],
            [124.1158, 7.1514],
            [124.2078, 7.2577],
            [124.1985, 7.421],
            [124.2498, 7.5092],
            [124.3654, 7.5678],
            [124.4405, 7.652],
            [124.4337, 7.5202],
            [124.4865, 7.444],
            [124.3287, 7.1719],
            [124.3731, 7.1656],
            [124.4145, 7.2256],
            [124.5087, 7.2324],
            [124.4284, 7.0867],
            [124.5012, 7.0285],
            [124.5329, 7.0383],
            [124.5861, 6.9696],
            [124.6768, 6.9457],
            [124.7183, 6.9968],
            [124.6992, 7.0489],
            [124.7288, 7.1274],
            [124.7943, 7.1095],
            [124.8077, 7.0004],
            [124.8143, 6.7809],
            [124.8975, 6.7832],
            [125.0037, 6.7206]
          ]
        ]
      },
      properties: {
        name: 'Maguindanao',
        id: 'PH-MAG',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-MAG'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [121.8705, 13.5707],
            [122.0483, 13.5128],
            [122.1277, 13.4552],
            [122.1505, 13.3711],
            [122.047, 13.2767],
            [122.0281, 13.199],
            [121.8658, 13.2847],
            [121.82, 13.3451],
            [121.8104, 13.4472],
            [121.8705, 13.5707]
          ]
        ]
      },
      properties: {
        name: 'Marinduque',
        id: 'PH-MAD',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-MAD'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [123.2372, 12.6012],
              [123.3747, 12.5086],
              [123.4453, 12.517],
              [123.5369, 12.4486],
              [123.6511, 12.3419],
              [123.695, 12.3325],
              [123.7811, 12.2264],
              [123.895, 12.2089],
              [124.0125, 12.0083],
              [124.07, 11.8536],
              [124.0136, 11.7864],
              [123.8603, 11.8992],
              [123.7215, 11.9317],
              [123.75, 11.9831],
              [123.6497, 12.0475],
              [123.5375, 12.2105],
              [123.4289, 12.201],
              [123.3683, 12.1083],
              [123.315, 12.0756],
              [123.2969, 12.0228],
              [123.1594, 11.9061],
              [123.1419, 11.9317],
              [123.1958, 12.0194],
              [123.2172, 12.1319],
              [123.2817, 12.1622],
              [123.2625, 12.2458],
              [123.2228, 12.2681],
              [123.2664, 12.3342],
              [123.2892, 12.4397],
              [123.2506, 12.4572],
              [123.2372, 12.6012]
            ]
          ],
          [
            [
              [123.6247, 12.6936],
              [123.7267, 12.6119],
              [123.77, 12.4936],
              [123.7889, 12.3383],
              [123.7275, 12.4025],
              [123.6339, 12.5431],
              [123.597, 12.6747],
              [123.6247, 12.6936]
            ]
          ],
          [
            [
              [122.9957, 13.1589],
              [123.0448, 13.1357],
              [123.1833, 12.9144],
              [123.2758, 12.8908],
              [123.262, 12.8322],
              [123.1992, 12.8414],
              [123.1078, 12.9244],
              [123.0636, 12.9981],
              [122.9852, 13.0062],
              [122.9282, 13.1131],
              [122.9957, 13.1589]
            ]
          ]
        ]
      },
      properties: {
        name: 'Masbate',
        id: 'PH-MAS',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-MAS'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.9169, 14.6919],
            [120.9578, 14.6875],
            [121.0077, 14.7479],
            [121.1139, 14.7597],
            [121.0934, 14.5682],
            [121.1053, 14.5305],
            [121.0619, 14.4942],
            [121.0558, 14.3785],
            [121.0128, 14.3503],
            [120.9696, 14.4755],
            [120.9853, 14.5603],
            [120.9169, 14.6919]
          ]
        ]
      },
      properties: {
        name: 'Metropolitan Manila',
        id: 'PH-00',
        CNTRY: 'Philippines',
        TYPE: 'Capital Region'
      },
      id: 'PH-00'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [123.6369, 8.0139],
            [123.5634, 8.0453],
            [123.5496, 8.2154],
            [123.5546, 8.6213],
            [123.6029, 8.6518],
            [123.6867, 8.6415],
            [123.7833, 8.5352],
            [123.8378, 8.4271],
            [123.8653, 8.1581],
            [123.7878, 8.1011],
            [123.76, 8.0503],
            [123.7119, 8.0544],
            [123.6369, 8.0139]
          ]
        ]
      },
      properties: {
        name: 'Misamis Occidental',
        id: 'PH-MSC',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-MSC'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.2057, 9.0926],
            [125.2362, 8.9572],
            [125.2315, 8.8552],
            [125.2423, 8.6596],
            [125.2442, 8.5816],
            [125.0292, 8.5793],
            [124.868, 8.5941],
            [124.8137, 8.4743],
            [124.8162, 8.4221],
            [124.6174, 8.4247],
            [124.6043, 8.3056],
            [124.5652, 8.2352],
            [124.5336, 8.2924],
            [124.4719, 8.3314],
            [124.3221, 8.3405],
            [124.2455, 8.3268],
            [124.2828, 8.3958],
            [124.2836, 8.4622],
            [124.3153, 8.5422],
            [124.4492, 8.6239],
            [124.5778, 8.5192],
            [124.6539, 8.51],
            [124.7017, 8.4728],
            [124.753, 8.513],
            [124.7711, 8.5944],
            [124.7403, 8.6933],
            [124.7892, 8.8244],
            [124.7781, 8.9761],
            [124.868, 9.0084],
            [125.0241, 8.9191],
            [125.0778, 8.8289],
            [125.1629, 8.8462],
            [125.1874, 8.9277],
            [125.1715, 9.0103],
            [125.2057, 9.0926]
          ]
        ]
      },
      properties: {
        name: 'Misamis Oriental',
        id: 'PH-MSR',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-MSR'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [121.5498, 17.2792],
            [121.562, 17.0319],
            [121.4571, 17.0544],
            [121.322, 17.024],
            [121.2116, 16.9726],
            [120.9713, 16.9763],
            [120.9825, 16.9337],
            [120.9479, 16.8579],
            [120.8612, 16.8086],
            [120.7755, 16.9348],
            [120.7806, 17.1104],
            [120.8583, 17.1882],
            [120.9148, 17.2476],
            [121.0565, 17.2338],
            [121.0865, 17.1933],
            [121.1622, 17.1682],
            [121.2497, 17.1862],
            [121.3412, 17.2813],
            [121.4105, 17.3044],
            [121.5498, 17.2792]
          ]
        ]
      },
      properties: {
        name: 'Mountain Province',
        id: 'PH-MOU',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-MOU'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [123.336, 10.3826],
            [123.2516, 10.4134],
            [123.1291, 10.4103],
            [123.2009, 10.2679],
            [123.1141, 10.1641],
            [123.0206, 10.0106],
            [122.9912, 9.9099],
            [122.737, 9.589],
            [122.6481, 9.5001],
            [122.6135, 9.4201],
            [122.4806, 9.5647],
            [122.4578, 9.66],
            [122.408, 9.6594],
            [122.3758, 9.8367],
            [122.432, 9.8992],
            [122.4506, 9.9761],
            [122.5064, 9.9867],
            [122.6639, 9.9778],
            [122.7787, 10.0673],
            [122.8675, 10.0961],
            [122.8406, 10.2264],
            [122.8642, 10.3711],
            [122.7955, 10.5227],
            [122.9178, 10.6094],
            [122.9625, 10.7306],
            [122.9528, 10.895],
            [123.0397, 10.9236],
            [123.1959, 11.0036],
            [123.3185, 10.9491],
            [123.4094, 10.9529],
            [123.515, 10.9181],
            [123.5335, 10.8362],
            [123.5688, 10.7944],
            [123.509, 10.7056],
            [123.465, 10.5231],
            [123.3581, 10.4419],
            [123.336, 10.3826]
          ]
        ]
      },
      properties: {
        name: 'Negros Occidental',
        id: 'PH-NEC',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-NEC'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [122.6135, 9.4201],
            [122.6481, 9.5001],
            [122.737, 9.589],
            [122.9912, 9.9099],
            [123.0206, 10.0106],
            [123.1141, 10.1641],
            [123.2009, 10.2679],
            [123.1291, 10.4103],
            [123.2516, 10.4134],
            [123.336, 10.3826],
            [123.3314, 10.2778],
            [123.2853, 10.1845],
            [123.2815, 10.1294],
            [123.2281, 10.0044],
            [123.1512, 9.9049],
            [123.1316, 9.8399],
            [123.1611, 9.708],
            [123.1377, 9.5853],
            [123.1844, 9.48],
            [123.2288, 9.465],
            [123.2608, 9.3811],
            [123.313, 9.3197],
            [123.2849, 9.2096],
            [123.2025, 9.0943],
            [123.1275, 9.0456],
            [123.0746, 9.0666],
            [123.0159, 9.0382],
            [122.942, 9.0753],
            [122.8783, 9.2247],
            [122.8646, 9.3289],
            [122.7947, 9.3631],
            [122.6747, 9.3797],
            [122.6135, 9.4201]
          ]
        ]
      },
      properties: {
        name: 'Negros Oriental',
        id: 'PH-NER',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-NER'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.2526, 7.5696],
            [125.2536, 7.5648],
            [125.2419, 7.4635],
            [125.2559, 7.3333],
            [125.2598, 7.1431],
            [125.2892, 7.0603],
            [125.255, 6.9567],
            [125.1006, 6.8187],
            [125.1208, 6.7563],
            [125.0037, 6.7206],
            [124.8975, 6.7832],
            [124.8143, 6.7809],
            [124.8077, 7.0004],
            [124.7943, 7.1095],
            [124.7288, 7.1274],
            [124.6992, 7.0489],
            [124.7183, 6.9968],
            [124.6768, 6.9457],
            [124.5861, 6.9696],
            [124.5329, 7.0383],
            [124.5012, 7.0285],
            [124.4284, 7.0867],
            [124.5087, 7.2324],
            [124.4145, 7.2256],
            [124.3731, 7.1656],
            [124.3287, 7.1719],
            [124.4865, 7.444],
            [124.4337, 7.5202],
            [124.4405, 7.652],
            [124.4961, 7.6786],
            [124.5684, 7.6731],
            [124.6081, 7.6224],
            [124.7003, 7.6398],
            [124.8067, 7.6036],
            [124.8397, 7.4795],
            [124.8796, 7.4048],
            [125.049, 7.3819],
            [125.0889, 7.4305],
            [125.1765, 7.459],
            [125.1879, 7.5171],
            [125.2526, 7.5696]
          ]
        ]
      },
      properties: {
        name: 'Cotabato',
        id: 'PH-NCO',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-NCO'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.3228, 12.2802],
            [125.2935, 12.2629],
            [125.1957, 12.3716],
            [125.1529, 12.2868],
            [124.9737, 12.2292],
            [124.9059, 12.2537],
            [124.8426, 12.219],
            [124.7139, 12.2909],
            [124.641, 12.2425],
            [124.4829, 12.3353],
            [124.4279, 12.2836],
            [124.3567, 12.2828],
            [124.3088, 12.4437],
            [124.2609, 12.5492],
            [124.3919, 12.5668],
            [124.443, 12.534],
            [124.7347, 12.5146],
            [124.7986, 12.543],
            [124.867, 12.5333],
            [124.8921, 12.5717],
            [125.1122, 12.5636],
            [125.1459, 12.585],
            [125.2917, 12.4689],
            [125.3228, 12.2802]
          ]
        ]
      },
      properties: {
        name: 'Northern Samar',
        id: 'PH-NSA',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-NSA'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.88, 16.1059],
            [121.0265, 16.1261],
            [121.1754, 16.0908],
            [121.198, 15.9081],
            [121.2817, 15.7705],
            [121.3762, 15.4836],
            [121.3118, 15.1994],
            [121.2413, 15.2043],
            [121.1439, 15.2725],
            [121.0212, 15.2524],
            [120.918, 15.2113],
            [120.7872, 15.2046],
            [120.7719, 15.2676],
            [120.7392, 15.3381],
            [120.7649, 15.4172],
            [120.722, 15.5488],
            [120.7541, 15.6044],
            [120.6541, 15.6633],
            [120.6128, 15.8321],
            [120.7436, 15.8526],
            [120.8227, 15.7928],
            [120.858, 15.8208],
            [120.9223, 15.9789],
            [120.88, 16.1059]
          ]
        ]
      },
      properties: {
        name: 'Nueva Ecija',
        id: 'PH-NUE',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-NUE'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.9009, 16.5777],
            [121.2668, 16.6271],
            [121.335, 16.7473],
            [121.4005, 16.6874],
            [121.4042, 16.6102],
            [121.3795, 16.4511],
            [121.4515, 16.4279],
            [121.438, 16.3389],
            [121.2937, 16.2809],
            [121.3125, 16.016],
            [121.4482, 15.9013],
            [121.2817, 15.7705],
            [121.198, 15.9081],
            [121.1754, 16.0908],
            [121.0265, 16.1261],
            [120.88, 16.1059],
            [120.8414, 16.1715],
            [120.7689, 16.2014],
            [120.759, 16.243],
            [120.8059, 16.3231],
            [120.8455, 16.3209],
            [120.8913, 16.4339],
            [120.9009, 16.5777]
          ]
        ]
      },
      properties: {
        name: 'Nueva Vizcaya',
        id: 'PH-NUV',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-NUV'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [121.0703, 12.2981],
              [121.1361, 12.1664],
              [121.0858, 12.1739],
              [121.0372, 12.2253],
              [121.0703, 12.2981]
            ]
          ],
          [
            [
              [120.8922, 13.4989],
              [120.8836, 13.4117],
              [120.7972, 13.2794],
              [120.9832, 13.1928],
              [121.1234, 13.106],
              [121.2415, 13.0628],
              [121.2462, 12.6512],
              [121.2315, 12.3992],
              [121.2424, 12.209],
              [121.2086, 12.2392],
              [121.1111, 12.2439],
              [121.1081, 12.3553],
              [121.0411, 12.3597],
              [120.9256, 12.5056],
              [120.9292, 12.6117],
              [120.8572, 12.7157],
              [120.7909, 12.7324],
              [120.7933, 12.9053],
              [120.7364, 13.0571],
              [120.6528, 13.1889],
              [120.4821, 13.2927],
              [120.4357, 13.4269],
              [120.3452, 13.3821],
              [120.2989, 13.4458],
              [120.3641, 13.515],
              [120.6713, 13.4922],
              [120.7528, 13.464],
              [120.8922, 13.4989]
            ]
          ],
          [
            [
              [120.0924, 13.8646],
              [120.2232, 13.8214],
              [120.2886, 13.7347],
              [120.2219, 13.7092],
              [120.1006, 13.7842],
              [120.0924, 13.8646]
            ]
          ]
        ]
      },
      properties: {
        name: 'Occidental Mindoro',
        id: 'PH-MDC',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-MDC'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [121.2424, 12.209],
            [121.2315, 12.3992],
            [121.2462, 12.6512],
            [121.2415, 13.0628],
            [121.1234, 13.106],
            [120.9832, 13.1928],
            [120.7972, 13.2794],
            [120.8836, 13.4117],
            [120.8922, 13.4989],
            [120.9544, 13.5028],
            [121.0583, 13.4051],
            [121.183, 13.4173],
            [121.3035, 13.3458],
            [121.3789, 13.2396],
            [121.4414, 13.2278],
            [121.4433, 13.1442],
            [121.5089, 13.1512],
            [121.557, 13.0889],
            [121.4865, 13.0179],
            [121.4989, 12.9381],
            [121.4761, 12.7739],
            [121.5489, 12.6694],
            [121.5064, 12.5477],
            [121.4097, 12.4439],
            [121.4131, 12.3972],
            [121.3675, 12.2989],
            [121.2761, 12.2842],
            [121.2424, 12.209]
          ]
        ]
      },
      properties: {
        name: 'Oriental Mindoro',
        id: 'PH-MDR',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-MDR'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [117.0728, 8.075],
              [117.0867, 7.9058],
              [117.0617, 7.8428],
              [117.0117, 7.8036],
              [116.9444, 7.9308],
              [116.95, 7.9997],
              [117.0728, 8.075]
            ]
          ],
          [
            [
              [117.3226, 8.3239],
              [117.3521, 8.2904],
              [117.3463, 8.2097],
              [117.2629, 8.2091],
              [117.2678, 8.3039],
              [117.3226, 8.3239]
            ]
          ],
          [
            [
              [119.8289, 10.6506],
              [119.9047, 10.5981],
              [119.983, 10.6],
              [119.9834, 10.5309],
              [119.9129, 10.4745],
              [119.805, 10.4413],
              [119.7624, 10.464],
              [119.7635, 10.5651],
              [119.8334, 10.6141],
              [119.8289, 10.6506]
            ]
          ],
          [
            [
              [121.0709, 10.9079],
              [121.0656, 10.8086],
              [121.0045, 10.8563],
              [121.0709, 10.9079]
            ]
          ],
          [
            [
              [119.4783, 11.4242],
              [119.5106, 11.3322],
              [119.5684, 11.2916],
              [119.5383, 11.1746],
              [119.5008, 11.1331],
              [119.5697, 11.0069],
              [119.5028, 11.0078],
              [119.4837, 10.875],
              [119.5348, 10.813],
              [119.5994, 10.8225],
              [119.5706, 10.6643],
              [119.6231, 10.6589],
              [119.6547, 10.6075],
              [119.6769, 10.4927],
              [119.5857, 10.4225],
              [119.57, 10.3725],
              [119.4564, 10.3751],
              [119.3232, 10.3117],
              [119.2433, 10.2014],
              [119.1969, 10.0475],
              [118.9731, 9.9839],
              [118.8523, 9.9713],
              [118.7514, 9.9286],
              [118.7417, 9.8408],
              [118.7742, 9.7972],
              [118.695, 9.7125],
              [118.7608, 9.6667],
              [118.6528, 9.5525],
              [118.5382, 9.3898],
              [118.5406, 9.3597],
              [118.3959, 9.2423],
              [118.3442, 9.1778],
              [118.2097, 9.1689],
              [118.1051, 9.1021],
              [118.1028, 9.0519],
              [118.0562, 8.995],
              [118.0032, 8.8801],
              [117.8868, 8.8286],
              [117.8075, 8.7665],
              [117.7418, 8.6872],
              [117.6233, 8.6571],
              [117.5654, 8.6736],
              [117.5005, 8.5004],
              [117.4563, 8.5209],
              [117.263, 8.4074],
              [117.2083, 8.3343],
              [117.1779, 8.3693],
              [117.2147, 8.4307],
              [117.2178, 8.5222],
              [117.3593, 8.7442],
              [117.4245, 8.7583],
              [117.5484, 8.9573],
              [117.6217, 9],
              [117.6644, 9.0747],
              [117.7467, 9.0678],
              [117.7753, 9.1647],
              [117.8614, 9.2092],
              [117.9056, 9.2611],
              [118.02, 9.2437],
              [118.1267, 9.3428],
              [118.127, 9.3972],
              [118.1852, 9.4105],
              [118.1972, 9.4711],
              [118.3288, 9.5814],
              [118.34, 9.6492],
              [118.4061, 9.6634],
              [118.4195, 9.705],
              [118.5109, 9.7601],
              [118.6047, 9.9148],
              [118.6447, 9.9343],
              [118.6599, 9.9986],
              [118.7455, 10.0842],
              [118.8086, 10.0303],
              [118.806, 10.1923],
              [118.9392, 10.2086],
              [118.9897, 10.3014],
              [118.9656, 10.3708],
              [119.0247, 10.4167],
              [119.1047, 10.4339],
              [119.176, 10.4116],
              [119.216, 10.4813],
              [119.3186, 10.5911],
              [119.3433, 10.7356],
              [119.2746, 10.7648],
              [119.2657, 10.8706],
              [119.3453, 10.8003],
              [119.4156, 10.7663],
              [119.413, 10.8716],
              [119.3337, 10.8932],
              [119.303, 11.0075],
              [119.3278, 11.0381],
              [119.4162, 11.0374],
              [119.3957, 11.1681],
              [119.4166, 11.2101],
              [119.4101, 11.3121],
              [119.4733, 11.3635],
              [119.4783, 11.4242]
            ]
          ],
          [
            [
              [119.8147, 11.5197],
              [119.863, 11.5097],
              [119.8697, 11.4522],
              [119.8319, 11.3786],
              [119.7697, 11.4381],
              [119.8346, 11.4575],
              [119.8147, 11.5197]
            ]
          ],
          [
            [
              [119.9061, 11.9761],
              [119.9786, 11.9172],
              [120.0736, 11.7881],
              [120.0495, 11.7181],
              [119.9804, 11.6665],
              [119.9387, 11.7311],
              [119.8692, 11.8956],
              [119.9061, 11.9761]
            ]
          ],
          [
            [
              [120.2486, 11.9842],
              [120.292, 11.8709],
              [120.2469, 11.8481],
              [120.1911, 11.9367],
              [120.2486, 11.9842]
            ]
          ],
          [
            [
              [119.897, 12.3278],
              [119.93, 12.2703],
              [120.0147, 12.2544],
              [120.063, 12.1906],
              [120.1633, 12.1211],
              [120.2468, 12.1317],
              [120.3328, 12.0916],
              [120.3406, 12.0127],
              [120.2998, 11.9813],
              [120.1375, 12.0025],
              [120.0997, 11.9603],
              [119.9789, 12.0086],
              [119.8589, 12.2515],
              [119.897, 12.3278]
            ]
          ]
        ]
      },
      properties: {
        name: 'Palawan',
        id: 'PH-PLW',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-PLW'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [120.7719, 15.2676],
            [120.7872, 15.2046],
            [120.918, 15.2113],
            [120.9272, 15.1335],
            [120.8872, 15.0878],
            [120.8872, 15.025],
            [120.8148, 14.9469],
            [120.7406, 14.9507],
            [120.6199, 14.7977],
            [120.5778, 14.8311],
            [120.47, 14.8792],
            [120.4394, 14.9492],
            [120.4081, 14.9668],
            [120.3577, 15.081],
            [120.3708, 15.1678],
            [120.393, 15.199],
            [120.5514, 15.259],
            [120.7719, 15.2676]
          ]
        ]
      },
      properties: {
        name: 'Pampanga',
        id: 'PH-PAM',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-PAM'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [119.8883, 15.8067],
            [119.9139, 15.8394],
            [119.8625, 15.955],
            [119.7495, 15.9645],
            [119.762, 16.1064],
            [119.7533, 16.1708],
            [119.7833, 16.2394],
            [119.7789, 16.3089],
            [119.8147, 16.3586],
            [119.8822, 16.3936],
            [119.9239, 16.3688],
            [119.9283, 16.25],
            [119.9747, 16.2064],
            [120.0728, 16.1786],
            [120.1111, 16.1452],
            [120.0971, 16.0691],
            [120.1442, 16.0393],
            [120.2236, 16.0347],
            [120.325, 16.0678],
            [120.423, 16.1623],
            [120.4154, 16.2084],
            [120.5125, 16.237],
            [120.6553, 16.1877],
            [120.7689, 16.2014],
            [120.8414, 16.1715],
            [120.88, 16.1059],
            [120.9223, 15.9789],
            [120.858, 15.8208],
            [120.8227, 15.7928],
            [120.7436, 15.8526],
            [120.6128, 15.8321],
            [120.4811, 15.7298],
            [120.3926, 15.7655],
            [120.3239, 15.65],
            [120.2539, 15.6185],
            [120.186, 15.7268],
            [120.1487, 15.824],
            [120.0854, 15.8109],
            [120.0288, 15.8453],
            [119.9908, 15.7923],
            [119.8883, 15.8067]
          ]
        ]
      },
      properties: {
        name: 'Pangasinan',
        id: 'PH-PAN',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-PAN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [121.9653, 14.2028],
              [122.1282, 14.0873],
              [122.1586, 14.0014],
              [121.9854, 14.1115],
              [121.9653, 14.2028]
            ]
          ],
          [
            [
              [121.9501, 15.0578],
              [122.0099, 15.0083],
              [122.021, 14.9205],
              [121.9815, 14.8416],
              [122.0232, 14.8104],
              [122.0372, 14.7148],
              [121.9387, 14.6261],
              [121.9034, 14.6758],
              [121.9376, 14.7117],
              [121.8781, 14.8356],
              [121.8188, 15.0008],
              [121.9501, 15.0578]
            ]
          ],
          [
            [
              [121.3419, 14.9973],
              [121.3417, 15.0438],
              [121.3982, 15.126],
              [121.4195, 15.2152],
              [121.4822, 15.1806],
              [121.5069, 15.0547],
              [121.5676, 14.9682],
              [121.6022, 14.8267],
              [121.6866, 14.701],
              [121.604, 14.6521],
              [121.6605, 14.3969],
              [121.7274, 14.327],
              [121.7592, 14.2453],
              [121.729, 14.1786],
              [121.7636, 14.1308],
              [121.9458, 13.9881],
              [122.0991, 13.9228],
              [122.1899, 13.9152],
              [122.2998, 13.9578],
              [122.2873, 14.0389],
              [122.1921, 14.0933],
              [122.1646, 14.1407],
              [122.2499, 14.2426],
              [122.2597, 14.1733],
              [122.3017, 14.0827],
              [122.3676, 14.0652],
              [122.4442, 14.1486],
              [122.7926, 14.0144],
              [122.5483, 13.9495],
              [122.4498, 13.926],
              [122.5107, 13.8104],
              [122.5243, 13.7328],
              [122.4805, 13.7129],
              [122.4973, 13.6471],
              [122.6506, 13.446],
              [122.6766, 13.3788],
              [122.6766, 13.2743],
              [122.7029, 13.2241],
              [122.5991, 13.1621],
              [122.5045, 13.2428],
              [122.5163, 13.3491],
              [122.4882, 13.4089],
              [122.4128, 13.4851],
              [122.4013, 13.5243],
              [122.3213, 13.5901],
              [122.2077, 13.6017],
              [122.2022, 13.6375],
              [122.0985, 13.7849],
              [122.0542, 13.7748],
              [121.972, 13.8403],
              [121.8912, 13.8622],
              [121.7495, 13.9683],
              [121.707, 13.9224],
              [121.6022, 13.9031],
              [121.4563, 13.8202],
              [121.369, 13.8702],
              [121.2725, 13.8772],
              [121.2613, 13.9646],
              [121.3297, 13.9866],
              [121.4312, 14.0648],
              [121.4925, 14.0747],
              [121.5224, 14.1471],
              [121.5648, 14.1708],
              [121.6305, 14.2612],
              [121.6228, 14.3936],
              [121.5588, 14.4089],
              [121.4698, 14.5758],
              [121.3512, 14.7806],
              [121.3454, 14.8923],
              [121.3419, 14.9973]
            ]
          ]
        ]
      },
      properties: {
        name: 'Quezon',
        id: 'PH-QUE',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-QUE'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [122.1317, 16.5301],
            [122.065, 16.4879],
            [121.8353, 16.2279],
            [121.4482, 15.9013],
            [121.3125, 16.016],
            [121.2937, 16.2809],
            [121.438, 16.3389],
            [121.4515, 16.4279],
            [121.3795, 16.4511],
            [121.4042, 16.6102],
            [121.5447, 16.6042],
            [121.7389, 16.3644],
            [121.8267, 16.4211],
            [122.0823, 16.5335],
            [122.1317, 16.5301]
          ]
        ]
      },
      properties: {
        name: 'Quirino',
        id: 'PH-QUI',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-QUI'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [121.3454, 14.8923],
            [121.3512, 14.7806],
            [121.4698, 14.5758],
            [121.3755, 14.5186],
            [121.3598, 14.4573],
            [121.3884, 14.3451],
            [121.3058, 14.3285],
            [121.3403, 14.4123],
            [121.2794, 14.5134],
            [121.2194, 14.4744],
            [121.1053, 14.5305],
            [121.0934, 14.5682],
            [121.1139, 14.7597],
            [121.1824, 14.8266],
            [121.2585, 14.8413],
            [121.3454, 14.8923]
          ]
        ]
      },
      properties: {
        name: 'Rizal',
        id: 'PH-RIZ',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-RIZ'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [122.5469, 12.5056],
              [122.6556, 12.4836],
              [122.6992, 12.4017],
              [122.6828, 12.3292],
              [122.6345, 12.2839],
              [122.5625, 12.3125],
              [122.5411, 12.3615],
              [122.4496, 12.3974],
              [122.4245, 12.4337],
              [122.4613, 12.4872],
              [122.5469, 12.5056]
            ]
          ],
          [
            [
              [122.1161, 12.6744],
              [122.14, 12.5381],
              [122.1125, 12.4983],
              [122.0886, 12.3131],
              [122.0417, 12.2281],
              [122.0153, 12.0986],
              [121.9667, 12.1564],
              [122.0182, 12.2338],
              [121.9217, 12.3186],
              [121.9422, 12.3925],
              [121.9875, 12.4083],
              [122.0161, 12.4934],
              [122.002, 12.5972],
              [122.0767, 12.6228],
              [122.1161, 12.6744]
            ]
          ]
        ]
      },
      properties: {
        name: 'Romblon',
        id: 'PH-ROM',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-ROM'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [124.7322, 11.7263],
              [124.7552, 11.6775],
              [124.85, 11.6006],
              [124.8232, 11.5295],
              [124.7658, 11.5743],
              [124.7322, 11.7263]
            ]
          ],
          [
            [
              [124.3567, 12.2828],
              [124.4279, 12.2836],
              [124.4829, 12.3353],
              [124.641, 12.2425],
              [124.7139, 12.2909],
              [124.8426, 12.219],
              [124.9059, 12.2537],
              [124.9737, 12.2292],
              [125.1529, 12.2868],
              [125.1456, 12.187],
              [125.1123, 12.1658],
              [125.1149, 11.9943],
              [125.2076, 11.9945],
              [125.2696, 11.8533],
              [125.2817, 11.6769],
              [125.2063, 11.598],
              [125.2307, 11.4433],
              [125.3066, 11.3981],
              [125.2492, 11.317],
              [125.2531, 11.1176],
              [125.1947, 11.1825],
              [125.1503, 11.2756],
              [125.0708, 11.2861],
              [125.0068, 11.2677],
              [124.9842, 11.4214],
              [124.903, 11.47],
              [124.8207, 11.4907],
              [124.8812, 11.5773],
              [124.9472, 11.5717],
              [124.986, 11.6181],
              [124.975, 11.6727],
              [125.0297, 11.715],
              [124.9963, 11.7791],
              [124.9083, 11.74],
              [124.8574, 11.8127],
              [124.7739, 11.8628],
              [124.7894, 11.9041],
              [124.7128, 11.9472],
              [124.6369, 12.0519],
              [124.5278, 12.0631],
              [124.3897, 12.1959],
              [124.3567, 12.2828]
            ]
          ]
        ]
      },
      properties: {
        name: 'Samar',
        id: 'PH-WSA',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-WSA'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [125.322, 5.569],
              [125.2872, 5.6923],
              [125.2383, 5.7181],
              [125.2072, 5.8255],
              [125.2148, 5.8867],
              [125.2721, 5.9174],
              [125.2908, 5.9773],
              [125.2722, 6.0833],
              [125.2398, 6.0973],
              [125.2337, 6.2019],
              [125.2556, 6.2524],
              [125.0757, 6.3409],
              [125.1195, 6.4437],
              [125.1764, 6.4766],
              [125.2299, 6.4456],
              [125.3042, 6.4627],
              [125.3808, 6.5085],
              [125.4446, 6.4132],
              [125.4284, 6.3277],
              [125.5037, 6.2343],
              [125.5341, 6.0575],
              [125.48, 5.9079],
              [125.4876, 5.8506],
              [125.3945, 5.6535],
              [125.322, 5.569]
            ]
          ],
          [
            [
              [125.1016, 5.9548],
              [125.0737, 5.8585],
              [124.9239, 5.8589],
              [124.8231, 5.8889],
              [124.5778, 6.0153],
              [124.5178, 6.0231],
              [124.3521, 6.1184],
              [124.3964, 6.213],
              [124.5444, 6.1485],
              [124.5822, 6.1069],
              [124.6739, 6.1148],
              [124.6746, 6.0735],
              [124.7927, 6.0099],
              [125.0154, 6.0021],
              [125.1016, 5.9548]
            ]
          ]
        ]
      },
      properties: {
        name: 'Sarangani',
        id: 'PH-SAR',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-SAR'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [123.6045, 9.3006],
            [123.6612, 9.2366],
            [123.6561, 9.1894],
            [123.7018, 9.145],
            [123.623, 9.1021],
            [123.5595, 9.1011],
            [123.4521, 9.1932],
            [123.5597, 9.2267],
            [123.6045, 9.3006]
          ]
        ]
      },
      properties: {
        name: 'Siquijor',
        id: 'PH-SIG',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-SIG'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [123.4804, 12.9889],
            [123.574, 13.031],
            [123.6287, 13.0104],
            [123.7337, 13.0241],
            [123.7686, 12.9858],
            [123.8577, 13.0405],
            [123.9047, 13.0409],
            [123.94, 13.1181],
            [124.0655, 13.0332],
            [124.132, 13.0754],
            [124.1575, 13.0184],
            [124.1425, 12.9339],
            [124.156, 12.8099],
            [124.1361, 12.6706],
            [124.0812, 12.5359],
            [123.9897, 12.5444],
            [123.8753, 12.6561],
            [123.8303, 12.8281],
            [123.8636, 12.86],
            [124.017, 12.8767],
            [124.0225, 12.9724],
            [123.8822, 12.9375],
            [123.7961, 12.8625],
            [123.6522, 12.87],
            [123.5292, 12.9372],
            [123.4804, 12.9889]
          ]
        ]
      },
      properties: {
        name: 'Sorsogon',
        id: 'PH-SOR',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-SOR'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.1779, 6.5057],
            [125.1764, 6.4766],
            [125.1195, 6.4437],
            [125.0757, 6.3409],
            [125.2556, 6.2524],
            [125.2337, 6.2019],
            [125.2398, 6.0973],
            [125.1637, 6.105],
            [125.1417, 6.02],
            [125.1016, 5.9548],
            [125.0154, 6.0021],
            [124.7927, 6.0099],
            [124.6746, 6.0735],
            [124.6739, 6.1148],
            [124.5822, 6.1069],
            [124.5444, 6.1485],
            [124.3964, 6.213],
            [124.3917, 6.3207],
            [124.506, 6.374],
            [124.5958, 6.316],
            [124.6455, 6.3995],
            [124.6194, 6.5056],
            [124.6755, 6.5855],
            [124.7593, 6.6398],
            [124.8335, 6.5065],
            [125.1779, 6.5057]
          ]
        ]
      },
      properties: {
        name: 'South Cotabato',
        id: 'PH-SCO',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-SCO'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.1494, 10.6222],
            [125.192, 10.5772],
            [125.1647, 10.5342],
            [125.1792, 10.4293],
            [125.2583, 10.3714],
            [125.2681, 10.2989],
            [125.2203, 10.2472],
            [125.1321, 10.2764],
            [125.1397, 10.1731],
            [125.2128, 10.1281],
            [125.2975, 9.9347],
            [125.256, 9.9138],
            [125.1855, 10.0381],
            [125.135, 10.0622],
            [125.1222, 10.1778],
            [125.0833, 10.2175],
            [125.0247, 10.3683],
            [124.9686, 10.373],
            [124.9775, 10.2131],
            [125.0239, 10.0878],
            [125.028, 10.0048],
            [124.8895, 10.1212],
            [124.7774, 10.1334],
            [124.7594, 10.1921],
            [124.8474, 10.2538],
            [124.8999, 10.265],
            [124.8932, 10.4044],
            [124.9398, 10.5243],
            [125.053, 10.5239],
            [125.1494, 10.6222]
          ]
        ]
      },
      properties: {
        name: 'Southern Leyte',
        id: 'PH-SLE',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-SLE'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.0037, 6.7206],
            [125.1208, 6.7563],
            [125.1224, 6.6759],
            [125.1747, 6.6083],
            [125.1779, 6.5057],
            [124.8335, 6.5065],
            [124.7593, 6.6398],
            [124.6755, 6.5855],
            [124.6194, 6.5056],
            [124.6455, 6.3995],
            [124.5958, 6.316],
            [124.506, 6.374],
            [124.3917, 6.3207],
            [124.3964, 6.213],
            [124.3521, 6.1184],
            [124.3251, 6.1136],
            [124.1853, 6.21],
            [124.1678, 6.2722],
            [124.0555, 6.3775],
            [124.02, 6.5228],
            [124.0522, 6.6272],
            [124.0153, 6.7183],
            [124.0411, 6.7508],
            [124.2653, 6.7535],
            [124.2825, 6.724],
            [124.5109, 6.7232],
            [124.5798, 6.7956],
            [124.5563, 6.8617],
            [124.6583, 6.8834],
            [124.6958, 6.8682],
            [124.6663, 6.7837],
            [124.7459, 6.782],
            [124.7993, 6.6494],
            [124.8855, 6.6565],
            [125.0037, 6.7206]
          ]
        ]
      },
      properties: {
        name: 'Sultan Kudarat',
        id: 'PH-SUK',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-SUK'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [120.851, 5.5873],
              [120.9058, 5.5402],
              [120.858, 5.4893],
              [120.817, 5.5262],
              [120.851, 5.5873]
            ]
          ],
          [
            [
              [121.1111, 6.0914],
              [121.1542, 6.0778],
              [121.2063, 5.9961],
              [121.2797, 6.0194],
              [121.3933, 6.0105],
              [121.4252, 5.959],
              [121.2816, 5.8761],
              [121.1822, 5.9344],
              [121.0856, 5.8917],
              [121.0248, 5.9295],
              [120.9403, 5.8897],
              [120.8733, 5.9553],
              [120.9203, 6.0269],
              [121.0183, 6.0878],
              [121.1111, 6.0914]
            ]
          ],
          [
            [
              [120.5963, 6.3948],
              [120.5783, 6.2506],
              [120.5197, 6.2653],
              [120.5561, 6.3733],
              [120.5963, 6.3948]
            ]
          ]
        ]
      },
      properties: {
        name: 'Sulu',
        id: 'PH-SLU',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-SLU'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [125.9424, 9.7588],
              [125.9906, 9.6625],
              [125.9626, 9.5674],
              [125.8878, 9.6119],
              [125.9265, 9.6549],
              [125.9058, 9.72],
              [125.9424, 9.7588]
            ]
          ],
          [
            [
              [125.9178, 9.4951],
              [125.7477, 9.3246],
              [125.7405, 9.391],
              [125.6867, 9.4357],
              [125.5617, 9.4585],
              [125.4531, 9.4396],
              [125.3909, 9.6561],
              [125.4008, 9.7672],
              [125.4967, 9.7939],
              [125.5902, 9.7197],
              [125.6093, 9.6307],
              [125.7408, 9.5656],
              [125.8458, 9.5411],
              [125.9178, 9.4951]
            ]
          ],
          [
            [
              [126.0619, 10.0639],
              [126.1211, 9.8775],
              [126.1637, 9.786],
              [126.1142, 9.7476],
              [126.0514, 9.7542],
              [125.9486, 9.8353],
              [125.9597, 9.8803],
              [126.0619, 10.0639]
            ]
          ]
        ]
      },
      properties: {
        name: 'Surigao del Norte',
        id: 'PH-SUN',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-SUN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [122.9159, 7.9186],
            [122.9623, 7.9142],
            [123.0778, 7.9983],
            [123.167, 7.9958],
            [123.1931, 8.1061],
            [123.2007, 8.2394],
            [123.3484, 8.2318],
            [123.3823, 8.2148],
            [123.5496, 8.2154],
            [123.5634, 8.0453],
            [123.6369, 8.0139],
            [123.6704, 7.9535],
            [123.6217, 7.8285],
            [123.5658, 7.8517],
            [123.4615, 7.8114],
            [123.4824, 7.7286],
            [123.4635, 7.6296],
            [123.3847, 7.607],
            [123.3369, 7.5536],
            [123.4508, 7.4349],
            [123.416, 7.359],
            [123.2847, 7.4675],
            [123.3013, 7.5303],
            [123.2247, 7.5389],
            [123.1824, 7.5046],
            [123.1158, 7.5139],
            [123.103, 7.5597],
            [123.1962, 7.5914],
            [123.145, 7.6887],
            [123.0975, 7.705],
            [123.0854, 7.8104],
            [122.9158, 7.8319],
            [122.9159, 7.9186]
          ]
        ]
      },
      properties: {
        name: 'Zamboanga del Sur',
        id: 'PH-ZAS',
        CNTRY: 'Philippines',
        TYPE: 'Province'
      },
      id: 'PH-ZAS'
    }
  ]
}
export default map
