import React, { useState } from 'react'

import './ASAreasInput.css'
import AreaSelectModal from '../AreaSelectModal/AreaSelectModal'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import routes from '../../../config/routes'
const ASAreasInput = ({ filters, setFilters, selectOneArea }) => {
  const [modalShow, setModalShow] = useState(false)
  const [areaSelected, setAreaSelected] = useState(false)
  const [numAreas, setNumAreas] = useState(0)
  const [numSubareas, setNumSubareas] = useState(0)
  const theRoutes = routes()
  const handleClose = () => setModalShow(false)
  const handleShow = () => setModalShow(true)

  const { t } = useTranslation()

  useEffect(() => {
    let obj = {}
    
    if (filters && filters.areas_ids) {
      filters.areas_ids.forEach(a => (obj = { ...obj, [a]: true }))
      setAreaSelected({ ...obj })
    } else {
      setAreaSelected(false)
    }
  }, [filters])

  return (
    <>
      <div className="acp__formControl">
        <label htmlFor="selectArea">
          {selectOneArea ? t('Area') : t('Areas')}
        </label>
        <button
          onClick={() => handleShow()}
          className="advancedSearch__areasBtn"
        >
          {selectOneArea && filters.areas_ids && filters.areas_ids.length ? (
            theRoutes.find(r => r.id === filters.areas_ids[0]).name
          ) : (filters && filters.areas_ids && filters.areas_ids.length) ||
            (filters &&
              filters.subareas &&
              ((filters.subareas.a1 && filters.subareas.a1.length) ||
                (filters.subareas.a3 && filters.subareas.a3.length))) ? (
            <span>
              {(isNaN(filters?.areas_ids?.length)
                ? 0
                : filters.areas_ids.length) +
                (isNaN(filters?.subareas?.a1?.length) ||
                filters.areas_ids.indexOf('a1') !== -1
                  ? 0
                  : filters.subareas.a1.length) +
                (isNaN(filters?.subareas?.a3?.length) ||
                filters.areas_ids.indexOf('a3') !== -1
                  ? 0
                  : filters.subareas.a3.length)}{' '}
              {t('areas selected')}
            </span>
          ) : !selectOneArea ? (
            <span> {t('All areas')}</span>
          ) : (
            <span> {t('Select area')}</span>
          )}
        </button>
      </div>

      <AreaSelectModal
        showModal={modalShow}
        handleClose={handleClose}
        areaSelected={areaSelected}
        setAreaSelected={setAreaSelected}
        filters={filters}
        setFilters={setFilters}
        selectOneArea={selectOneArea}
      />
    </>
  )
}

export default ASAreasInput
