import React, { useState, useRef, useCallback, useEffect } from "react";
import { Manager, Reference, Popper } from "react-popper";
import { useOnClickOutside } from "../../../hooks/clickOutside";
import CircleSpinner from "../ui/CircleSpinner";

const modifiers = {
  preventOverflow: {
    padding: 0
  },
  shift: {
    enabled: true
  },
  flip: {
    enabled: true,
    // flipVariationsByContent: true,
    behavior: "flip"
  }
};

const SubMenu = ({ options, listRef, open }) => {
  return (
    <>
      {open ? (
        <Popper placement="left-start" modifiers={modifiers} referenceElement={listRef.current}>
          {({ ref, style, placement, arrowProps }) => (
            <ul ref={ref} style={style} data-placement={placement} className="dropdown-button__sub-list">
              {options.map(item => (
                <li
                  className={`dropdown-button__list-item
                ${(item.name && `dropdown-button__list-item--${item.name}`) || ""}`}
                  key={item.name}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          )}
        </Popper>
      ) : null}
    </>
  );
};

const DropdownPopper = ({
  children,
  buttonText,
  active = false,
  filterLoading,
  setIsOpen,
  isOpen,
  customActive = false
}) => {
  const [dropdownOpen, setDropdownToggle] = useState(false);
  const [subMenus, setSubMenus] = useState({});
  const dropdownListRef = useRef(null);
  const menuRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  useOnClickOutside(menuRef, () => setDropdownToggle(false));

  const setButtonRef = useCallback((node, ref) => {
    dropdownButtonRef.current = node;
    return ref(node);
  }, []);

  const setListRef = useCallback((node, ref) => {
    dropdownListRef.current = node;
    return ref(node);
  }, []);

  const dropdownToggle = () => {
    setDropdownToggle(!dropdownOpen);
  };

  useEffect(() => {
    if (isOpen !== dropdownOpen) setIsOpen(dropdownOpen);
  }, [dropdownOpen]);

  useEffect(() => {
    if (isOpen !== dropdownOpen) setDropdownToggle(isOpen);
  }, [isOpen]);

  const modifiers = {
    preventOverflow: {
      padding: 0
    },
    shift: {
      enabled: true
    },
    flip: {
      enabled: false,
      flipVariationsByContent: false,
      behavior: ["bottom"]
    }
  };

  return (
    <Manager>
      <div ref={menuRef} className="topicFilter">
        <Reference>
          {({ ref }) => (
            <span
              className={!customActive ? (active ? "activeFilter" : "inactiveFilter") : ""}
              onClick={dropdownToggle}
              ref={node => setButtonRef(node, ref)}
            >
              {buttonText}
            </span>
          )}
        </Reference>
        {dropdownOpen && (
          <Popper placement="bottom" modifiers={modifiers} strategy="fixed">
            {({ ref, style, placement, arrowProps }) => (
              <div
                ref={node => setListRef(node, ref)}
                style={style}
                data-placement={placement}
                className={`dropdown-button__list ${(dropdownOpen && "dropdown-button__list--open") || ""}`}
              >
                {children}
              </div>
            )}
          </Popper>
        )}
      </div>
    </Manager>
  );
};

export default DropdownPopper;
