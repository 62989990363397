import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GET_A1_1_ENTRIES_CSSSA = gql(`
  query GetEntriesCSSA($lang: String) {
    entriesCsssa(lang: $lang) {
      id
      legal
      explan
      illegal_since
      last_amendment_1
      last_amendment_2
      decrim_date_1
      decrim_date_2
      entry_csssa_penalty {
        id
        name
        map_color
      }
      legal_framework_decrim_mechanism {
        id
        name
      }
      entry_csssa_death_penalty_value {
        id
        name
      }
      motherEntry {
        entry_type_id
        jurisdiction {
          id
          name
          un_member
          a2_code
          regions{
            id
          }
        }
        subjurisdiction {
          id
          name
          slug
          label_longitude
          label_latitude
          label_font_size
          label_longitude
          label_latitude
          label_font_size
        }
        footnotes {
          id
          order
          section
          content
        }
        sources {
          id
          order
          section
          original_official_filename
          original_filename
          translated_filename
          enacted
          original_official_language{
            name
            id
          }
          original_official_language2{
            name
            id
          }
          original_language{
            name
            id
          }
          original_language2{
            name
            id
          }
        }
      }
      dp
      entry_csssa_max_prison_value {
        id
        name
      }
      max_prison_years
      prison
      has_fine
      fine
      entry_csssa_other_punishment_value{
        id
        name
      }
      other
      explan
      enforcement
    }
  }
`);

const GET_A1_1_ENFORCEMENT = gql(`
query GetEnforcement($lang: String, $entry_csssa_id: Int) {
  enforcement(lang: $lang, entry_csssa_id: $entry_csssa_id) {
    enforcement
    sources {
      id
      text
      pos
    }
  }
}
`);

function useGqlEntriesA1_1_Csssa() {
  const { i18n } = useTranslation();
  const entries_csssa = useQuery(GET_A1_1_ENTRIES_CSSSA, { variables: { lang: i18n.language } });
  //const penalties_csssa = useQuery(GET_A1_1_PENALTIES_CSSSA, { variables: { lang: i18n.language } });

  return { entriesCsssa: entries_csssa.data && entries_csssa.data.entriesCsssa };
}

export function useGqlEnforcement(entry_csssa_id) {
  const { i18n } = useTranslation();
  const enforcement = useQuery(GET_A1_1_ENFORCEMENT, {
    variables: { lang: i18n.language, entry_csssa_id: parseInt(entry_csssa_id) }
  });

  return { enforcement: enforcement.data && enforcement.data.enforcement };
}

export default useGqlEntriesA1_1_Csssa;
