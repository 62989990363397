export const getTextsAreas = options => {
  return {
    1: {
      en:
        "Access, explore, and analyse the legal frameworks in force in 193 UN member States, 47 non-UN Member jurisdictions and numerous sub-national jurisdictions on issues of sexual, gender and bodily diversity.",

      es:
        "Acceda, explore y analice los marcos legales vigentes en 193 Estados miembros de la ONU, 47 jurisdicciones no miembras de la ONU y numerosas jurisdicciones subnacionales sobre temas de diversidad sexual, de género y corporal."
    },
    2: {
      en:
        "Navigate the ratification status of the main UN Human Rights Treaties and check which jurisdictions have accepted individual communication and inquiry procedures.",

      es:
        "Conozca el estado de ratificación de los principales tratados de derechos humanos de Naciones Unidas y verifique qué jurisdicciones han aceptado los mecanismos de comunicaciones individuales y de investigación."
    },
    3: {
      en:
        "Explore all mentions and recommendations issued by UN Treaty Bodies on sexual, gender and bodily diversity through all of their working methods (since 2016).",

      es:
        "Explore todas las menciones y recomendaciones efectuadas por los Órganos de Tratados de la ONU en materia de diversidad sexual, de género y corporal a través de todos sus métodos de trabajo (desde 2016)."
    },
    4: {
      en:
        "All mentions and recommendations issued by UN Special Procedures on sexual, gender and bodily diversity through all of their working methods (since 2016) are made available here.",

      es:
        "Todas las menciones y recomendaciones emitidas por los Procedimientos Especiales de la ONU en materia de diversidad sexual, de género y corporal a través de todos sus métodos de trabajo (desde 2016) están disponibles aquí."
    },
    5: {
      en:
        "Discover all SOGIESC-related recommendations issued during the third cycle of the Universal Periodic Review and the responses to each of them made by receiving States. ",

      es:
        "Descubra todas las recomendaciones en materia de diversidad emitidas durante el tercer ciclo del Examen Periódico Universal y las respuestas de los Estados receptores a cada una de ellas."
    },
    6: {
      en:
        "Find out how UN Member States are voting in UN resolutions on or related to issues of sexual, gender and bodily diveristy. ",

      es:
        "Conozca cómo los Estados miembros de la ONU están votando en resoluciones de la ONU sobre o relacionadas con temas de diversidad sexual, de género y corporal."
    }
  };
};
