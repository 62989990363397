import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./ViewMoreUpdates.css";
import logoImage from "../../../images/logo.png";
import { useTranslation } from "react-i18next";
import { history } from "../../../App";
import { getPath } from "../../../config/routes";

export default function ViewMoreUpdates({ body }) {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const modalRef = useRef();

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleDownloadPDF = () => console.log("start pdf download");

    return (
        <>
            <Button className="moreBtn" variant="default" onClick={() => history.push(getPath('latest_updates'))}>
                <span className="moreBtn__text">{ t("More") }</span>
            </Button>

            <Modal size="lg" className="methodologyModal" show={showModal} onHide={handleClose} ref={modalRef}>
                <Modal.Header closeButton>
                    <img src={logoImage} alt="Ilga world database logo" />
                </Modal.Header>
                <Modal.Body>
                    <div className="methodologyActions__container">
                        <h4>{ t("More Updates") }</h4>
                    </div>
                    <div className="methodologyModal__content">{body}</div>
                    <div className="methodologyActions__container">
                        <Button variant="link" onClick={(e) => modalRef.current.dialog.scroll(0, 0)}>
                            { t("Go to top") }
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
