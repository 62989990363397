import React from "react";

const FeedbackIcon = ({ size, color = "#E7EDEF", title = "" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ width: size, height: size }} viewBox="0 0 24 24">
    <title>{title}</title>
    <g fill={color}>
    <path  d="M13 11H11V5H13M13 15H11V13H13M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2Z" />    </g>
  </svg>
);

export default FeedbackIcon;



