import React from "react";
import BarLoader from "react-spinners/BarLoader";

// This should turn in to a component just for fallback on Suspense
export default function MainSpinner() {
  return (
    <div style={{ height: "100vh", position: 'relative', display: 'flex', alignItems: 'center' }}>
      <BarLoader color={"#5c8493"} loading={true} width={"100%"} />
    </div>
  );
}
