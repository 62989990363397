import i18n from '../../config/i18n'
import routes from '../../config/routes'
import { citeEntry } from '../../lib/cite'
import { explanSourceLinks } from '../../lib/fnHelper'
import DateDropdownFilter, {
  ApplyDropdownDateFilter
} from '../components/Table/DateDropdownFilter'
import ExpandableTextCell from '../components/Table/ExpandableTextCell'
import GenericDropdownFilter, {
  ApplyGenericDropdownFilter
} from '../components/Table/GenericDropdownFilter'
import HelpCell from '../components/Table/HelpCell'
import JurFilter, { ApplyJurFilter } from '../components/Table/JurFilter'
import SourceText from '../components/Table/SourceText'

const getBgColorClass = (cell, col) => {
  if (!cell.row.original[col]) return ''
  return cell.row.original[col].id === '1'
    ? 'rowProtectionYes'
    : cell.row.original[col].id === '2'
    ? 'rowProtectionNo'
    : cell.row.original[col].id === '3' ||
      cell.row.original[col].id === '5' ||
      cell.row.original[col].id === '7'
    ? 'rowProtectionVaries'
    : 'rowProtectionUnclear'
}

const getBgColorClass2 = (cell, col) => {
  if (!cell.row.original[col]) return ''
  return cell.row.original[col].id === '2'
    ? 'rowProtectionYes'
    : cell.row.original[col].id === '1'
    ? 'rowProtectionNo'
    : cell.row.original[col].id === '5' || cell.row.original[col].id === '7'
    ? 'rowProtectionVaries'
    : 'rowProtectionUnclear'
}

const getBgColorClassAvail = (cell, col) => {
  if (!cell.row.original[col]) return ''
  return cell.row.original[col].id === '1'
    ? 'rowProtectionYes'
    : cell.row.original[col].id === '2'
    ? 'rowProtectionNo'
    : cell.row.original[col].id === '5' || cell.row.original[col].id === '6'
    ? 'rowProtectionVaries'
    : 'rowProtectionUnclear'
}

const options = () => ({
  entry_lgr_types: [
    { id: i18n.t('Possible'), value: i18n.t('Possible') },
    { id: i18n.t('Not Possible'), value: i18n.t('Not Possible') },
    { id: i18n.t('Nominally Possible'), value: i18n.t('Nominally Possible') },
    { id: i18n.t('Unclear'), value: i18n.t('Unclear') },
    { id: i18n.t('No data'), value: i18n.t('No data') },
    { id: i18n.t('Varies'), value: i18n.t('Varies') }
  ],
  entry_lgr_established_procedures: [
    { id: i18n.t('Yes'), value: i18n.t('Yes') },
    { id: i18n.t('No'), value: i18n.t('No') },
    { id: i18n.t('Unclear'), value: i18n.t('Unclear') },
    { id: i18n.t('N/A'), value: i18n.t('N/A') }
  ],
  entry_lgr_selfid_values: [
    { id: i18n.t('Yes'), value: i18n.t('Yes') },
    { id: i18n.t('No'), value: i18n.t('No') },
    { id: i18n.t('Unclear'), value: i18n.t('Unclear') },
    { id: i18n.t('Varies'), value: i18n.t('Varies') },
    { id: i18n.t('No data'), value: i18n.t('No data') }
  ],
  entry_lgr_gm_values: [
    { id: i18n.t('Required'), value: i18n.t('Required') },
    { id: i18n.t('Not required'), value: i18n.t('Not required') },
    { id: i18n.t('Unclear'), value: i18n.t('Unclear') },
    { id: i18n.t('N/A'), value: i18n.t('N/A') },
    { id: i18n.t('Varies'), value: i18n.t('Varies') },
    { id: i18n.t('No data'), value: i18n.t('No data') }
  ],
  entry_lgr_gm_avail_values: [
    { id: i18n.t('Available'), value: i18n.t('Available') },
    { id: i18n.t('Not available'), value: i18n.t('Not available') },
    { id: i18n.t('Unclear'), value: i18n.t('Unclear') },
    { id: i18n.t('N/A'), value: i18n.t('N/A') },
    { id: i18n.t('Varies'), value: i18n.t('Varies') },
    { id: i18n.t('Only for Intersex'), value: i18n.t('Only for Intersex') },
    { id: i18n.t('No data'), value: i18n.t('No data') }
  ]
})

const PncDropdown = ({ column, option, width = '330px' }) => (
  <GenericDropdownFilter
    width={width}
    column={column}
    options={options()[option]}
  />
)

export const citeA118 = (entry, setToast, requirements = true) => {
  console.log(entry)
  let lines = [
    '<p>' +
      `${
        entry.motherEntry.subjurisdiction
          ? entry.motherEntry.subjurisdiction.name +
            ' (' +
            entry.motherEntry.jurisdiction.name +
            ')'
          : entry.motherEntry.jurisdiction.name
      }: ${
        routes().find(r => r.parent === 'area1' && r.subareaNumber === 18).name
      }` +
      '</p>'
  ]

  if (entry.name_change_lgr_type)
    lines.push(
      '<p>' +
        i18n.t('Name Change') +
        ': ' +
        entry.name_change_lgr_type.name +
        '. ' +
        explanSourceLinks(entry.name_change_explan, entry) +
        '</p>'
    )

  if (entry.gender_marker_lgr_type)
    lines.push(
      '<p>' +
        i18n.t('Gender Marker Change') +
        ': ' +
        entry.gender_marker_lgr_type.name +
        '. ' +
        explanSourceLinks(entry.gender_marker_explan, entry, 2) +
        '</p>'
    )

  if (requirements) {
    lines.push('<p>' + i18n.t('Breakdown of requirements') + ':' + '</p>')

    if (entry.gm_selfid_value)
      lines.push(
        '<p>' +
          i18n.t('Self-ID') +
          ': ' +
          entry.gm_selfid_value.name +
          '. ' +
          explanSourceLinks(entry.gm_selfid_explan, entry) +
          '</p>'
      )

    if (entry.gm_diagnosis_gm_value)
      lines.push(
        '<p>' +
          i18n.t('Diagnosis') +
          ': ' +
          entry.gm_diagnosis_gm_value.name +
          '. ' +
          explanSourceLinks(entry.gm_diagnosis_explan, entry) +
          '</p>'
      )

    if (entry.gm_hormone_treatment_gm_value)
      lines.push(
        '<p>' +
          i18n.t('Hormone treatment') +
          ': ' +
          entry.gm_hormone_treatment_gm_value.name +
          '. ' +
          explanSourceLinks(entry.gm_hormone_treatment_explan, entry) +
          '</p>'
      )

    if (entry.gm_surgery_gm_value)
      lines.push(
        '<p>' +
          i18n.t('Surgery') +
          ': ' +
          entry.gm_surgery_gm_value.name +
          '. ' +
          explanSourceLinks(entry.gm_surgery_explan, entry) +
          '</p>'
      )

    if (entry.gm_forced_sterilisation_gm_value)
      lines.push(
        '<p>' +
          i18n.t('Forced sterilisation') +
          ': ' +
          entry.gm_forced_sterilisation_gm_value.name +
          '. ' +
          explanSourceLinks(entry.gm_forced_sterilisation_explan, entry) +
          '</p>'
      )

    if (entry.gm_real_life_test_gm_value)
      lines.push(
        '<p>' +
          i18n.t('Real life test') +
          ': ' +
          entry.gm_real_life_test_gm_value.name +
          '. ' +
          explanSourceLinks(entry.gm_real_life_test_explan, entry) +
          '</p>'
      )

    if (entry.gm_expert_testimony_gm_value)
      lines.push(
        '<p>' +
          i18n.t('Expert testimony') +
          ': ' +
          entry.gm_expert_testimony_gm_value.name +
          '. ' +
          explanSourceLinks(entry.gm_expert_testimony_explan, entry) +
          '</p>'
      )

    if (entry.gm_witnesses_gm_value)
      lines.push(
        '<p>' +
          i18n.t('Witnesses') +
          ': ' +
          entry.gm_witnesses_gm_value.name +
          '. ' +
          explanSourceLinks(entry.gm_witnesses_explan, entry) +
          '</p>'
      )

    if (entry.gm_forced_divorce_gm_value)
      lines.push(
        '<p>' +
          i18n.t('Forced divorce') +
          ': ' +
          entry.gm_forced_divorce_gm_value.name +
          '. ' +
          explanSourceLinks(entry.gm_forced_divorce_explan, entry) +
          '</p>'
      )

    if (entry.gm_no_children_gm_value)
      lines.push(
        '<p>' +
          i18n.t('No children') +
          ': ' +
          entry.gm_no_children_gm_value.name +
          '. ' +
          explanSourceLinks(entry.gm_no_children_explan, entry) +
          '</p>'
      )

    if (entry.gm_for_children_gm_avail_value)
      lines.push(
        '<p>' +
          i18n.t('LGR available for children') +
          ': ' +
          entry.gm_for_children_gm_avail_value.name +
          '. ' +
          explanSourceLinks(entry.gm_for_children_explan, entry) +
          '</p>'
      )

    if (entry.gm_non_binary_gm_avail_value)
      lines.push(
        '<p>' +
          i18n.t('Non-Binary Gender Marker') +
          ': ' +
          entry.gm_non_binary_gm_avail_value.name +
          '. ' +
          explanSourceLinks(entry.gm_non_binary_explan, entry) +
          '</p>'
      )
  }
  citeEntry(
    routes().find(r => r.parent === 'area1' && r.subareaNumber === 18),
    entry.motherEntry.subjurisdiction
      ? entry.motherEntry.subjurisdiction.name +
          ' (' +
          entry.motherEntry.jurisdiction.name +
          ')'
      : entry.motherEntry.jurisdiction.name,
    lines.join(''),
    setToast
  )
}

export const a118_gm_columns = () => [
  {
    Header: i18n.t('Jurisdiction'),
    disableGroupBy: true,
    accessor: 'motherEntry.jurisdiction.name',
    enableRowSpan: true,
    filter: ApplyJurFilter,
    Filter: ({ column }) => {
      return <JurFilter column={column} search={true} />
    },
    Cell: ({ value, row }) => {
      return (
        <div
          className={
            'jurCell ' +
            (row.original.motherEntry.jurisdiction.id.indexOf('DT') !== -1 ||
            row.original.motherEntry.subjurisdiction
              ? 'dependentTerritory'
              : '')
          }
        >
          <div>
            <img
              className="tableFlag"
              src={
                '/images/flags_svg/' +
                row.original.motherEntry.jurisdiction.a2_code.toLowerCase() +
                '.svg'
              }
              height="16px"
            />
          </div>
          <div>
            {!row.original.motherEntry.subjurisdiction &&
              row.original.motherEntry.jurisdiction.name}
            {row.original.motherEntry.subjurisdiction &&
              '' + row.original.motherEntry.subjurisdiction.name + ''}
          </div>
        </div>
      )
    },
    CellSmall: ({ value, row }) => {
      return (
        <div>{row.original.motherEntry.jurisdiction.name.substring(0, 3)}</div>
      )
    },
    style: { minWidth: '100px' }
  },
  {
    Header: i18n.t('Gender Marker Change'),
    accessor: 'gender_marker_lgr_type.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <PncDropdown column={column} width="220px" option="entry_lgr_types" />
      )
    },
    classNameFn: c => getBgColorClass(c, 'gender_marker_lgr_type'),

    className: 'textCenter col125 flexTd',

    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.gender_marker_lgr_type.name >
        rowB.original.gender_marker_lgr_type.name
      )
        return -1
      if (
        rowB.original.gender_marker_lgr_type.name >
        rowA.original.gender_marker_lgr_type.name
      )
        return 1
      return 0
    }
  },
  {
    Header: i18n.t('Established Procedure'),
    accessor: 'established_procedure.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <PncDropdown
          column={column}
          option="entry_lgr_established_procedures"
          width="150px"
        />
      )
    },
    classNameFn: c => getBgColorClass(c, 'established_procedure'),

    className: 'textCenter col125 flexTd',

    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.established_procedure.name >
        rowB.original.established_procedure.name
      )
        return -1
      if (
        rowB.original.established_procedure.name >
        rowA.original.established_procedure.name
      )
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('Critical Date'),
    accessor: 'gender_marker_critical_date_1',
    sortDescFirst: true,
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(
        rows,
        ['gender_marker_critical_date_1'],
        filterValue
      ),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    },
    Cell: ({ value, row }) => {
      if (value)
        return (
          value +
          (row.original.gender_marker_critical_date_2
            ? ' - ' + row.original.gender_marker_critical_date_2
            : '')
        )
      else return '-'
    },
    className: 'textCenter col80'
  },
  {
    Header: i18n.t('Mechanism'),
    accessor: 'gender_marker_mechanism.name',
    className: 'textCenter col100',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          column={column}
          options={[
            { id: i18n.t('Legislative'), value: i18n.t('Legislative') },
            { id: i18n.t('Executive'), value: i18n.t('Executive') },
            { id: i18n.t('Judicial'), value: i18n.t('Judicial') }
          ]}
        />
      )
    }
  },
  {
    Header: i18n.t('Explanation'),
    accessor: 'gender_marker_explan',
    disableSortBy: true,
    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            row={row}
            section={2}
            sources={row.original.motherEntry.sources}
            unik={row.original.id + '_explan'}
            footnotes={row.original.motherEntry.footnotes}
          />
        )
      else return <></>
    },
    NonExpandableCell: ({ value, row }) => {
      return (
        <SourceText
          text={value}
          section={2}
          sources={row.original.motherEntry.sources}
          unik={row.original.id + '_explan'}
        />
      )
    },
    style: { minWidth: '550px' },
    sortType: (rowA, rowB, id, desc) => {
      if (!rowB.original.explan) return null
      if (
        rowA.original.gender_marker.replace(/[^\w\s]/gi, '') >
        rowB.original.gender_marker.replace(/[^\w\s]/gi, '')
      )
        return -1
      if (
        rowB.original.gender_marker.replace(/[^\w\s]/gi, '') >
        rowA.original.gender_marker.replace(/[^\w\s]/gi, '')
      )
        return 1
      return 0
    }
  },
  {
    Header: i18n.t('SELF ID'),
    accessor: 'gm_selfid_value.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <PncDropdown
          column={column}
          option="entry_lgr_selfid_values"
          width="150px"
        />
      )
    },
    classNameFn: c => getBgColorClass(c, 'gm_selfid_value'),
    Cell: ({ value, row }) => {
      if (row.original.gm_selfid_explan)
        return (
          <HelpCell
            className="helpCell--a118gm"
            mainText={value}
            helpText={row.original.gm_selfid_explan}
            bg={getBgColorClass({ row }, 'gm_selfid_value')}
          />
        )
      else
        return row.original.gm_selfid_value
          ? row.original.gm_selfid_value.name
          : ''
    },
    className: 'textCenter col110 flexTd',

    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.gm_selfid_value.name > rowB.original.gm_selfid_value.name
      )
        return -1
      if (
        rowB.original.gm_selfid_value.name > rowA.original.gm_selfid_value.name
      )
        return 1
      return 0
    }
  },
  {
    Header: i18n.t('Self-ID Since'),
    accessor: 'gm_selfid_since',
    sortDescFirst: true,
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(rows, ['gm_selfid_since'], filterValue),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    },
    Cell: ({ value, row }) => {
      if (value) return value
      else return '-'
    },
    className: 'textCenter col80'
  },
  {
    Header: i18n.t('Diagnosis'),
    accessor: 'gm_diagnosis_gm_value.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <PncDropdown
          column={column}
          option="entry_lgr_gm_values"
          width="150px"
        />
      )
    },
    classNameFn: c => getBgColorClass2(c, 'gm_diagnosis_gm_value'),
    Cell: ({ value, row }) => {
      if (row.original.gm_diagnosis_explan)
        return (
          <HelpCell
            className="helpCell--a118gm"
            mainText={value}
            helpText={row.original.gm_diagnosis_explan}
            bg={getBgColorClass2({ row }, 'gm_diagnosis_gm_value')}
          />
        )
      else
        return row.original.gm_diagnosis_gm_value
          ? row.original.gm_diagnosis_gm_value.name
          : ''
    },
    className: 'textCenter col125 flexTd',
    isHidden: true,
    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.gm_diagnosis_gm_value.name >
        rowB.original.gm_diagnosis_gm_value.name
      )
        return -1
      if (
        rowB.original.gm_diagnosis_gm_value.name >
        rowA.original.gm_diagnosis_gm_value.name
      )
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('Hormone treatment'),
    accessor: 'gm_hormone_treatment_gm_value.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <PncDropdown
          column={column}
          option="entry_lgr_gm_values"
          width="150px"
        />
      )
    },
    classNameFn: c => getBgColorClass2(c, 'gm_hormone_treatment_gm_value'),
    Cell: ({ value, row }) => {
      if (row.original.gm_hormone_treatment_explan)
        return (
          <HelpCell
            className="helpCell--a118gm"
            mainText={value}
            helpText={row.original.gm_hormone_treatment_explan}
            bg={getBgColorClass2({ row }, 'gm_hormone_treatment_gm_value')}
          />
        )
      else
        return row.original.gm_hormone_treatment_gm_value
          ? row.original.gm_hormone_treatment_gm_value.name
          : ''
    },
    className: 'textCenter col125 flexTd',
    isHidden: true,
    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.gm_hormone_treatment_gm_value.name >
        rowB.original.gm_hormone_treatment_gm_value.name
      )
        return -1
      if (
        rowB.original.gm_hormone_treatment_gm_value.name >
        rowA.original.gm_hormone_treatment_gm_value.name
      )
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('Surgery'),
    accessor: 'gm_surgery_gm_value.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <PncDropdown
          column={column}
          option="entry_lgr_gm_values"
          width="150px"
        />
      )
    },
    classNameFn: c => getBgColorClass2(c, 'gm_surgery_gm_value'),
    Cell: ({ value, row }) => {
      if (row.original.gm_surgery_explan)
        return (
          <HelpCell
            className="helpCell--a118gm"
            mainText={value}
            helpText={row.original.gm_surgery_explan}
            bg={getBgColorClass2({ row }, 'gm_surgery_gm_value')}
          />
        )
      else
        return row.original.gm_surgery_gm_value
          ? row.original.gm_surgery_gm_value.name
          : ''
    },
    className: 'textCenter col125 flexTd',
    isHidden: true,
    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.gm_surgery_gm_value.name >
        rowB.original.gm_surgery_gm_value.name
      )
        return -1
      if (
        rowB.original.gm_surgery_gm_value.name >
        rowA.original.gm_surgery_gm_value.name
      )
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('Forced sterilisation'),
    accessor: 'gm_forced_sterilisation_gm_value.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <PncDropdown
          column={column}
          option="entry_lgr_gm_values"
          width="150px"
        />
      )
    },
    classNameFn: c => getBgColorClass2(c, 'gm_forced_sterilisation_gm_value'),
    Cell: ({ value, row }) => {
      if (row.original.gm_forced_sterilisation_explan)
        return (
          <HelpCell
            className="helpCell--a118gm"
            mainText={value}
            helpText={row.original.gm_forced_sterilisation_explan}
            bg={getBgColorClass2({ row }, 'gm_forced_sterilisation_gm_value')}
          />
        )
      else
        return row.original.gm_forced_sterilisation_gm_value
          ? row.original.gm_forced_sterilisation_gm_value.name
          : ''
    },
    className: 'textCenter col125 flexTd',
    isHidden: true,
    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.gm_forced_sterilisation_gm_value.name >
        rowB.original.gm_forced_sterilisation_gm_value.name
      )
        return -1
      if (
        rowB.original.gm_forced_sterilisation_gm_value.name >
        rowA.original.gm_forced_sterilisation_gm_value.name
      )
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('Real life test'),
    accessor: 'gm_real_life_test_gm_value.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <PncDropdown
          column={column}
          option="entry_lgr_gm_values"
          width="150px"
        />
      )
    },
    classNameFn: c => getBgColorClass2(c, 'gm_real_life_test_gm_value'),
    Cell: ({ value, row }) => {
      if (row.original.gm_real_life_test_explan)
        return (
          <HelpCell
            className="helpCell--a118gm"
            mainText={value}
            helpText={row.original.gm_real_life_test_explan}
            bg={getBgColorClass2({ row }, 'gm_real_life_test_gm_value')}
          />
        )
      else
        return row.original.gm_real_life_test_gm_value
          ? row.original.gm_real_life_test_gm_value.name
          : ''
    },
    className: 'textCenter col125 flexTd',
    isHidden: true,
    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.gm_real_life_test_gm_value.name >
        rowB.original.gm_real_life_test_gm_value.name
      )
        return -1
      if (
        rowB.original.gm_real_life_test_gm_value.name >
        rowA.original.gm_real_life_test_gm_value.name
      )
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('Expert testimony'),
    accessor: 'gm_expert_testimony_gm_value.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <PncDropdown
          column={column}
          option="entry_lgr_gm_values"
          width="150px"
        />
      )
    },
    classNameFn: c => getBgColorClass2(c, 'gm_expert_testimony_gm_value'),
    Cell: ({ value, row }) => {
      if (row.original.gm_expert_testimony_explan)
        return (
          <HelpCell
            className="helpCell--a118gm"
            mainText={value}
            helpText={row.original.gm_expert_testimony_explan}
            bg={getBgColorClass2({ row }, 'gm_expert_testimony_gm_value')}
          />
        )
      else
        return row.original.gm_expert_testimony_gm_value
          ? row.original.gm_expert_testimony_gm_value.name
          : ''
    },
    className: 'textCenter col125 flexTd',
    isHidden: true,
    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.gm_expert_testimony_gm_value.name >
        rowB.original.gm_expert_testimony_gm_value.name
      )
        return -1
      if (
        rowB.original.gm_expert_testimony_gm_value.name >
        rowA.original.gm_expert_testimony_gm_value.name
      )
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('Witnesses'),
    accessor: 'gm_witnesses_gm_value.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <PncDropdown
          column={column}
          option="entry_lgr_gm_values"
          width="150px"
        />
      )
    },
    classNameFn: c => getBgColorClass2(c, 'gm_witnesses_gm_value'),
    Cell: ({ value, row }) => {
      if (row.original.gm_witnesses_explan)
        return (
          <HelpCell
            className="helpCell--a118gm"
            mainText={value}
            helpText={row.original.gm_witnesses_explan}
            bg={getBgColorClass2({ row }, 'gm_witnesses_gm_value')}
          />
        )
      else
        return row.original.gm_witnesses_gm_value
          ? row.original.gm_witnesses_gm_value.name
          : ''
    },
    className: 'textCenter col125 flexTd',
    isHidden: true,
    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.gm_witnesses_gm_value.name >
        rowB.original.gm_witnesses_gm_value.name
      )
        return -1
      if (
        rowB.original.gm_witnesses_gm_value.name >
        rowA.original.gm_witnesses_gm_value.name
      )
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('Forced divorce'),
    accessor: 'gm_forced_divorce_gm_value.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <PncDropdown
          column={column}
          option="entry_lgr_gm_values"
          width="150px"
        />
      )
    },
    classNameFn: c => getBgColorClass2(c, 'gm_forced_divorce_gm_value'),
    Cell: ({ value, row }) => {
      if (row.original.gm_forced_divorce_explan)
        return (
          <HelpCell
            className="helpCell--a118gm"
            mainText={value}
            helpText={row.original.gm_forced_divorce_explan}
            bg={getBgColorClass2({ row }, 'gm_forced_divorce_gm_value')}
          />
        )
      else
        return row.original.gm_forced_divorce_gm_value
          ? row.original.gm_forced_divorce_gm_value.name
          : ''
    },
    className: 'textCenter col125 flexTd',
    isHidden: true,
    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.gm_forced_divorce_gm_value.name >
        rowB.original.gm_forced_divorce_gm_value.name
      )
        return -1
      if (
        rowB.original.gm_forced_divorce_gm_value.name >
        rowA.original.gm_forced_divorce_gm_value.name
      )
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('No children'),
    accessor: 'gm_no_children_gm_value.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <PncDropdown
          column={column}
          option="entry_lgr_gm_values"
          width="150px"
        />
      )
    },
    classNameFn: c => getBgColorClass2(c, 'gm_no_children_gm_value'),
    Cell: ({ value, row }) => {
      if (row.original.gm_no_children_explan)
        return (
          <HelpCell
            className="helpCell--a118gm"
            mainText={value}
            helpText={row.original.gm_no_children_explan}
            bg={getBgColorClass2({ row }, 'gm_no_children_gm_value')}
          />
        )
      else
        return row.original.gm_no_children_gm_value
          ? row.original.gm_no_children_gm_value.name
          : ''
    },
    className: 'textCenter col110 flexTd',
    isHidden: true,
    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.gm_no_children_gm_value.name >
        rowB.original.gm_no_children_gm_value.name
      )
        return -1
      if (
        rowB.original.gm_no_children_gm_value.name >
        rowA.original.gm_no_children_gm_value.name
      )
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('AVAILABLE FOR CHILDREN'),
    accessor: 'gm_for_children_gm_avail_value.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <PncDropdown
          column={column}
          option="entry_lgr_gm_avail_values"
          width="180px"
        />
      )
    },
    classNameFn: c => getBgColorClassAvail(c, 'gm_for_children_gm_avail_value'),
    Cell: ({ value, row }) => {
      if (row.original.gm_for_children_explan)
        return (
          <HelpCell
            className="helpCell--a118gm"
            mainText={value}
            helpText={row.original.gm_for_children_explan}
            bg={getBgColorClassAvail({ row }, 'gm_for_children_gm_avail_value')}
          />
        )
      else
        return row.original.gm_for_children_gm_avail_value
          ? row.original.gm_for_children_gm_avail_value.name
          : ''
    },
    className: 'textCenter col110 flexTd',

    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.gm_for_children_gm_avail_value.name >
        rowB.original.gm_for_children_gm_avail_value.name
      )
        return -1
      if (
        rowB.original.gm_for_children_gm_avail_value.name >
        rowA.original.gm_for_children_gm_avail_value.name
      )
        return 1
      return 0
    }
  },
  {
    Header: i18n.t('NON-BINARY GENDER MARKER'),
    accessor: 'gm_non_binary_gm_avail_value.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <PncDropdown
          column={column}
          option="entry_lgr_gm_avail_values"
          width="180px"
        />
      )
    },
    classNameFn: c => getBgColorClassAvail(c, 'gm_non_binary_gm_avail_value'),
    Cell: ({ value, row }) => {
      if (row.original.gm_non_binary_explan)
        return (
          <HelpCell
            className="helpCell--a118gm"
            mainText={value}
            helpText={row.original.gm_non_binary_explan}
            bg={getBgColorClassAvail({ row }, 'gm_non_binary_gm_avail_value')}
          />
        )
      else
        return row.original.gm_non_binary_gm_avail_value
          ? row.original.gm_non_binary_gm_avail_value.name
          : ''
    },
    className: 'textCenter col110 flexTd',

    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.gm_non_binary_gm_avail_value.name >
        rowB.original.gm_non_binary_gm_avail_value.name
      )
        return -1
      if (
        rowB.original.gm_non_binary_gm_avail_value.name >
        rowA.original.gm_non_binary_gm_avail_value.name
      )
        return 1
      return 0
    }
  }
]
