import React from 'react'
import { useTranslation } from 'react-i18next'

export default function SubjurButtons({ eEntry, jur, show, setEEntry }) {
  const [t, i18n] = useTranslation()
  return (
    <>
      {eEntry && (eEntry.sub.length > 0 || eEntry.dep.length > 0) && (
        <div className="accordion-footer">
          <div className="jpjs__countryBox-actions">
            {/* UK EXCEPTION ALWAYS SHOW*/
            eEntry.sub.length > 0 &&
              eEntry.main?.motherEntry?.jurisdiction?.id !== 'UN-184' &&
              (eEntry.main?.motherEntry?.jurisdiction?.id !== 'UN-185' ||
                (eEntry.main?.motherEntry?.entry_type_id !== 'A1-1' &&
                  eEntry.main?.motherEntry?.entry_type_id !== 'A1-6' &&
                  eEntry.main?.motherEntry?.entry_type_id !== 'A1-7' &&
                  eEntry.main?.motherEntry?.entry_type_id !== 'A1-8' &&
                  eEntry.main?.motherEntry?.entry_type_id !== 'A1-10' &&
                  eEntry.main?.motherEntry?.entry_type_id !== 'A1-11' &&
                  eEntry.main?.motherEntry?.entry_type_id !== 'A1-12' &&
                  eEntry.main?.motherEntry?.entry_type_id !== 'A1-13' &&
                  eEntry.main?.motherEntry?.entry_type_id !== 'A1-15' &&
                  eEntry.main?.motherEntry?.entry_type_id !== 'A1-16' &&
                  eEntry.main?.motherEntry?.entry_type_id !== 'A1-18')) &&
              (eEntry.showSub ? (
                <button
                  className="greenBtn"
                  onClick={() => setEEntry({ ...eEntry, showSub: false })}
                >
                  {t('Hide all sub-national jurisdictions')}
                </button>
              ) : (
                <button
                  className="greenBtn"
                  onClick={() => setEEntry({ ...eEntry, showSub: true })}
                >
                  {t('Show all sub-national jurisdictions')}
                </button>
              ))}
            {eEntry.dep.length > 0 &&
              eEntry.main.motherEntry.jurisdiction.id !== 'UN-036' &&
              (eEntry.showDep ? (
                <button
                  className="greenBtn"
                  onClick={() => setEEntry({ ...eEntry, showDep: false })}
                >
                  {t('Hide dependent territories')}
                </button>
              ) : (
                <button
                  className="greenBtn"
                  onClick={() => setEEntry({ ...eEntry, showDep: true })}
                >
                  {t('Show dependent territories')}
                </button>
              ))}
          </div>
        </div>
      )}
    </>
  )
}
