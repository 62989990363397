import React, { useState, useEffect } from "react";

const CustomCheckbox = ({ content, checked, setChecked = () => {} }) => {
  return (
    <div className="check-container">
      <input
        name="name"
        type="checkbox"
        checked={checked}
        onChange={event => {
          setChecked(!checked);
        }}
      />
      <label
        onClick={event => {
          setChecked(!checked);
        }}
      >
        {content}
      </label>
    </div>
  );
};

export default CustomCheckbox;
