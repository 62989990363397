import React, { useContext, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AccountContext } from '../../context/Accounts'
import useError from '../../hooks/useError'
import LogoComponent from '../components/LogoComponent'

import { useForm, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import './Login.css'
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios'
import { useEffect } from 'react'
import useGqlUsers from '../../gql/useGqlUsers'
import UserPool from '../../config/UserPool'
import { useLayoutEffect } from 'react'

export default function RegisterModal({
  showRegister,
  handleLoginClose,
  setShowRegister,
  setShowLogin
}) {
  const loginButton = useRef()
  const [form, setForm] = useState()
  const firstLoad = useRef(true)
  const passwordInput = useRef(null)
  const passwordInput2 = useRef(null)
  const [recaptcha, setRecaptcha] = useState(null)
  const [showPass, setShowPass] = useState(false)
  const [showPass2, setShowPass2] = useState(false)
  const [resetRecaptcha, setResetRecaptcha] = useState(null)
  const [recaptchaKey, setRecaptchaKey] = useState(1)
  const recaptchaRef = React.createRef()
  const [success, setSuccess] = useState(false)
  const [rememberChecked, setRememberChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const { authenticate } = useContext(AccountContext)
  const { addError } = useError()
  const { t, i18n } = useTranslation()

  const schema = yup.object().shape({
    firstname: yup.string().required(t('Name is required')),
    lastname: yup.string().required(t('Surname is required')),
    email: yup
      .string()
      .required(t('E-mail is required'))
      .email(t('Invalid e-mail')),
    repeatEmail: yup
      .string()
      .oneOf([yup.ref('email'), null], t('E-mails must match')),
    organisation: yup.string().min(3, t('Please insert a valid organisation')),
    why: yup.string().min(14, t('Please provide further details')),
    password: yup
      .string()
      .required(t('Password is required'))
      .min(8, t('Password is too short - should be 8 chars minimum.'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        t(
          'Password must have at least one uppercase letter, one lowercase letter and one number'
        )
      ),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
    // agreeTerms: yup.bool().oneOf([true], "You must agree to the T&C")
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(schema)
  })

  useLayoutEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false
      return
    } else {
      if (rememberChecked)
        setValue('agreeTerms', true, { shouldValidate: true })
      else setValue('agreeTerms', false, { shouldValidate: true })
    }
  }, [rememberChecked])

  useEffect(() => {
    setRecaptcha(recaptchaRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptchaRef])
  useEffect(() => {
    if (recaptchaRef && recaptchaRef.current && showRegister)
      recaptchaRef.current.reset()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetRecaptcha])

  const { createUser } = useGqlUsers()

  const onSubmit = async data => {
    if (!recaptcha) {
      addError(t('Unknown recaptcha error, please refresh page and try again'))
    }

    setLoading(true)

    console.log('SUBMITTTT')

    const token = await recaptcha.executeAsync()

    let res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + '/users/checkCaptcha',
      {
        token
      }
    )

    if (!res.data.success) return
    else setResetRecaptcha(Math.random())
    console.log('Launching signup')
    UserPool.signUp(
      data.email,
      data.password,
      [
        {
          Name: 'family_name',
          Value: data.lastname
        },
        {
          Name: 'given_name',
          Value: data.firstname
        }
      ],
      null,
      async function(err, result) {
        console.log(err, result)
        if (err && err.code) {
          /* i18next-extract-disable-next-line */
          addError(t(err.code))
          setLoading(false)
        } else if (result && result.userSub) {
          delete data.password
          delete data.repeatEmail
          delete data.repeatPassword
          delete data.agreeTerms
          let res = await createUser({
            variables: {
              input: {
                ...data,
                cognito_id: result.userSub,
                lang: i18n.language
              }
            }
          })
          setLoading(false)
          setSuccess(true)
        } else {
          addError(t('An unexpected error occurred'))
          setLoading(false)
        }
      }
    )
  }

  const ResetModal = () => {
    return (
      <Modal.Body>
        <div className="loginForm__container">
          <div className="lf__header">
            <p className="lf__title">Reset</p>
          </div>
          <div className="lfInput__container">
            <label htmlFor="userEmail">{t('Your email')}l</label>
            {errors.email && (
              <div className="error">{errors.email?.message}</div>
            )}
            <input
              name="userEmail"
              type="email"
              placeholder="e.g.name@mail.com"
            />
          </div>
          <div className="lf__actions">
            <Button variant="primary">Reset Password</Button>
            <Button variant="link">Go back to login</Button>
            <p className="actionMsg">An email has been sent to your account</p>
          </div>
        </div>
      </Modal.Body>
    )
  }
  return !showRegister ? (
    <></>
  ) : (
    <Modal
      show={showRegister}
      className={
        'menu__modal login__modal signup__modal ' +
        (!success ? 'fullSize' : 'midSize')
      }
      onExit={() => setShowRegister(false)}
      onHide={() => setShowRegister(false)}
    >
      <ReCAPTCHA
        ref={ref => {
          recaptchaRef.current = ref
        }}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />

      <Modal.Header closeButton={!success}>
        <LogoComponent />
      </Modal.Header>
      <Modal.Body>
        {!success ? (
          <div className="loginForm__container">
            <div className="lf__header">
              <p className="lf__title">{t('Sign Up')}</p>
              <p className="lf__subTitle">
                {t('or')}{' '}
                <span
                  onClick={() => {
                    setShowRegister(false)
                    setShowLogin(true)
                  }}
                >
                  {t('log in')}
                </span>
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit, () => {})}>
              <div className="row">
                <div className="col-5 col-md-3">
                  <div className="lfInput__container">
                    <label htmlFor="pronoun">{t('Your Pronouns')}</label>

                    <input {...register('pronoun')} />
                    {errors.pronoun && (
                      <div className="error">{errors.pronoun?.message}</div>
                    )}
                  </div>
                </div>
                <div className="col-7 col-md-4">
                  <div className="lfInput__container">
                    <label htmlFor="firstname">{t('Your Name')} *</label>

                    <input {...register('firstname')} />
                    {errors.firstname && (
                      <div className="error">{errors.firstname?.message}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="lfInput__container">
                    <label htmlFor="lastname">{t('Your surname')} *</label>

                    <input {...register('lastname')} />
                    {errors.lastname && (
                      <div className="error">{errors.lastname?.message}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="lfInput__container">
                    <label htmlFor="organisation">{t('Organisation')} *</label>

                    <input {...register('organisation')} />
                    {errors.organisation && (
                      <div className="error">
                        {errors.organisation?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="lfInput__container">
                    <label htmlFor="why">
                      {t(
                        'Why do you want to have access to the ILGA World Database?'
                      )}{' '}
                      *
                    </label>

                    <input {...register('why')} />
                    {errors.why && (
                      <div className="error">{errors.why?.message}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="lfInput__container">
                    <label htmlFor="email">{t('Your email')} *</label>

                    <input {...register('email')} />
                    {errors.email && (
                      <div className="error">{errors.email?.message}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="lfInput__container">
                    <label htmlFor="repeatEmail">{t('Repeat email')} *</label>

                    <input {...register('repeatEmail')} />
                    {errors.repeatEmail && (
                      <div className="error">{errors.repeatEmail?.message}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="lfInput__container">
                    <label htmlFor="password">{t('Your password')} *</label>

                    <input {...register('password')} type="password" />
                    <Button
                      className="showPasswordBtn"
                      variant="link"
                      onClick={e => setShowPass2(!showPass2)}
                    />
                    {errors.password && (
                      <div className="error">{errors.password?.message}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="lfInput__container">
                    <label htmlFor="repeatPassword">
                      {t('Repeat password')} *
                    </label>

                    <input {...register('repeatPassword')} type="password" />
                    <Button
                      className="showPasswordBtn"
                      variant="link"
                      onClick={e => setShowPass(!showPass)}
                    />
                    {errors.repeatPassword && (
                      <div className="error">
                        {errors.repeatPassword?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="dd-wrapper">
                <div className="check-container" onClick={() => setRememberChecked(!rememberChecked)}>
                  <div className="dd-list-item">
                    <button type="button">
                      <input
                        {...register("agreeTerms")}
                        type="checkbox"
                        checked={rememberChecked}
                        onChange={() => setRememberChecked(!rememberChecked)}
                      />
                      <label htmlFor="agreeTerms">
                        I agree with the{" "}
                        <a href="/terms-and-conditions" target={"_blank"}>
                          {" "}
                          <span>terms and conditions</span>
                        </a>{" "}
                        *
                      </label>
                    </button>
                  </div>
                </div>
              </div> */}
              {errors.agreeTerms && (
                <div className="error">{errors.agreeTerms?.message}</div>
              )}

              <div className="lf__actions">
                <Button type="submit" variant="primary" disabled={loading}>
                  {t('Sign up')}
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <>
            <div className="loginForm__container success">
              {i18n.language === 'en' ? (
                <p className="lf__subTitle">
                  <p>
                    Thank you for your interest in the ILGA World Database. We
                    have just sent you an email to validate your address.
                  </p>
                </p>
              ) : (
                <p className="lf__subTitle">
                  <p>
                    Gracias por su interés en la Base de datos de ILGA Mundo.
                    Acabamos de enviarle un correo para que valide su dirección.
                  </p>
                </p>
              )}

              <div className="lf__actions">
                <Button
                  onClick={() => setShowRegister(false)}
                  variant="primary"
                  disabled={loading}
                >
                  {t('OK, thanks')}
                </Button>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}
