import { gql, useApolloClient } from '@apollo/client'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { history } from '../../../App.js'
import { advancedSearchDocumentTypes } from '../../../config/constants.js'
import routes from '../../../config/routes'
import { AccountContext } from '../../../context/Accounts'
import useGqlAdvancedSearch from '../../../gql/useGqlAdvancedSearch.js'
import useGqlJurisdictions from '../../../gql/useGqlJurisdictions.js'
import { useOnClickOutside } from '../../../hooks/clickOutside.js'
import MainLayout from '../../../layouts/MainLayout'
import { allCites } from '../../areas/allCites.js'
import FilterIcon from '../../components/icons/FilterIcon'
import MagGlass from '../../components/icons/MagGlass'
import SimpleTable from '../../components/Table/SimpleTable'
import { advancedSearchColumns } from '../../config/advancedSearchColumns'
import './AdvancedSearch.css'
import AdvancedSearchForm from './Components/AdvancedSearchForm'

export default function AdvancedSearch() {
  /* change to toggle between screen modes */
  const [hasSelected, setHasSelected] = useState(true)
  const { t, i18n } = useTranslation()
  const { toast, setToast } = useContext(AccountContext)
  let { regions } = useGqlJurisdictions()

  const [filters, setFilters] = useState({})

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState(null)
  const [resultsIni, setResultsIni] = useState(null)

  const [totalAreas, setTotalAreas] = useState(null)

  const [areaGroupOpen, setAreaGroupOpen] = useState(false)
  const [jurGroupOpen, setJurGroupOpen] = useState(false)

  const [orderBy, setOrderBy] = useState('jur_asc')
  const [groupBy, setGroupBy] = useState('areas')

  const apolloClient = useApolloClient()

  const resultsRef = useRef(null)
  const dropGroupBy = useRef(null)
  const dropOrderBy = useRef(null)

  const theRoutes = routes()

  // empty array for different variation - no results displayed
  const [resultsCollection, setResultsCollection] = useState([
    'Area 1 | Legal Framework',
    'Area 2 | Legal Framework',
    'Area 3 | Legal Framework',
    'Area 4 | Legal Framework',
    'Area 5 | Legal Framework',
    'Area 6 | Legal Framework'
  ])

  useEffect(() => {
    if (resultsIni) {
      organizeResults(resultsIni)
    }
  }, [orderBy, groupBy])

  const organizeResults = res => {
    let tmpRes = group(res)
    for (const key of Object.keys(tmpRes)) {
      if (tmpRes[key] && tmpRes[key].length) {
        tmpRes = { ...tmpRes, [key]: order(tmpRes[key]) }
      }
    }
    console.log(res, tmpRes)
    setResults(tmpRes)
    setJurGroupOpen(false)
    setAreaGroupOpen(false)
  }

  const group = results => {
    let newResults = {}
    if (groupBy === 'areas') {
      for (let key of Object.keys(results)) {
        if (results[key] && results[key].length) {
          for (let entry of results[key]) {
            if (!newResults[entry.area.substring(0, 2).toLowerCase()])
              newResults[entry.area.substring(0, 2).toLowerCase()] = []
            newResults[entry.area.substring(0, 2).toLowerCase()].push(entry)
          }
        }
      }
    } else if (groupBy === 'jurisdictions') {
      for (let key of Object.keys(results)) {
        if (results[key] && results[key].length) {
          for (let entry of results[key]) {
            if (!newResults[entry.motherEntry.jurisdiction.name])
              newResults[entry.motherEntry.jurisdiction.name] = []
            newResults[entry.motherEntry.jurisdiction.name].push(entry)
          }
        }
      }
    } else if (groupBy === 'types') {
      for (let key of Object.keys(results)) {
        if (results[key] && results[key].length) {
          for (let entry of results[key]) {
            const type =
              entry.area === 'A5'
                ? t('UPR recommendation')
                : entry.type_of_document && entry.type_of_document !== '-'
                ? entry.type_of_document
                : 'N/A'
            if (!newResults[type]) newResults[type] = []
            newResults[type].push(entry)
          }
        }
      }
    } else if (groupBy === 'regions') {
      const regs = regions
        .filter(r => r.region_type.id === '5')
        .sort((a, b) => a.name > b.name)
        .map(r => r.id)
      for (let key of Object.keys(results)) {
        if (results[key] && results[key].length) {
          for (let reg of regs) {
            newResults[reg] = results[key].filter(e =>
              e.motherEntry.jurisdiction.regions.some(r => r.id === reg)
            )
          }
        }
      }
    } else if (groupBy === 'un_regions') {
      const regs = regions
        .filter(r => r.region_type.id === '3')
        .sort((a, b) => a.name > b.name)
        .map(r => r.id)
      for (let key of Object.keys(results)) {
        if (results[key] && results[key].length) {
          for (let reg of regs) {
            newResults[reg] = results[key].filter(e =>
              e.motherEntry.jurisdiction.regions.some(r => r.id === reg)
            )
          }
        }
      }
    } else if (groupBy === 'ilga_regions') {
      const regs = regions
        .filter(r => r.region_type.id === '2')
        .sort((a, b) => a.name > b.name)
        .map(r => r.id)
      for (let key of Object.keys(results)) {
        if (results[key] && results[key].length) {
          for (let reg of regs) {
            newResults[reg] = results[key].filter(e =>
              e.motherEntry.jurisdiction.regions.some(r => r.id === reg)
            )
          }
        }
      }
    }
    return newResults
  }

  const order = res => {
    if (orderBy === 'jur_asc' || orderBy === 'jur_desc') {
      return res
        .slice()
        .sort((a, b) => {
          if (
            a.motherEntry.jurisdiction.name < b.motherEntry.jurisdiction.name
          ) {
            return orderBy === 'jur_asc' ? -1 : 1
          }
          if (
            a.motherEntry.jurisdiction.name > b.motherEntry.jurisdiction.name
          ) {
            return orderBy === 'jur_asc' ? 1 : -1
          }
          if (
            a.motherEntry.jurisdiction.name ===
              b.motherEntry.jurisdiction.name &&
            a.motherEntry.subjurisdiction &&
            b.motherEntry.subjurisdiction
          ) {
            if (
              a.motherEntry.subjurisdiction.name <
              b.motherEntry.subjurisdiction.name
            ) {
              return -1
            }
            if (
              a.motherEntry.subjurisdiction.name >
              b.motherEntry.subjurisdiction.name
            ) {
              return 1
            }
          }
          return 0
        })
        .filter(e => e.motherEntry.jurisdiction.a2_code !== 'EH')
    } else if (orderBy === 'date_asc' || orderBy === 'date_desc') {
      return res
        .slice()
        .sort((a, b) => {
          if (a.date_from < b.date_from) {
            return orderBy === 'date_asc' ? -1 : 1
          }
          if (a.date_from > b.date_from) {
            return orderBy === 'date_asc' ? 1 : -1
          }

          return 0
        })
        .filter(e => e.motherEntry.jurisdiction.a2_code !== 'EH')
    }
  }

  const search = async filters => {
    setLoading(true)
    let newCuTypes = []
    if (filters.cu_types && filters.cu_types.length) {
      for (let ct of filters.cu_types) {
        newCuTypes = newCuTypes.concat(
          advancedSearchDocumentTypes().find(dt => dt.name === ct).values
        )
      }
    }
    const response = await useGqlAdvancedSearch(
      apolloClient,
      { ...filters, cu_types: newCuTypes },
      i18n.language
    )
    let res = { ...response.data.advancedSearch }
    delete res.__typename
    setTotalAreas(res.totalAreas)
    delete res.totalAreas
    setResultsIni(res)
    organizeResults(res)
    setLoading(false)
    resultsRef.current.scrollIntoView()
  }

  useOnClickOutside(dropGroupBy, () => setAreaGroupOpen(false))
  useOnClickOutside(dropOrderBy, () => setJurGroupOpen(false))

  return (
    <MainLayout section="tools">
      <div className="area__page section__page advancedSearch__page">
        <div className="content__container">
          <div className="areaContent__header">
            <div className="ach__iconContainer">
              <MagGlass size={32} color="#ffffff" />
            </div>
            <div className="ach__titlesContainer">
              <h2 className="acp__title">{t('Advanced Search')}</h2>
              <p>
                {t(
                  'Welcome to the advanced search engine. With this tool you can search through the whole database across all five thematic areas. You can also search for updates from media outlets, academic papers and other sources through the ILGA World Monitor (login required).'
                )}
              </p>
            </div>
          </div>
          <AdvancedSearchForm
            filters={filters}
            setFilters={setFilters}
            search={search}
            setResults={setResults}
            loading={loading}
          />
        </div>

        {results && Object.keys(results).length === 0 ? (
          <div
            className="acp__content"
            style={resultsCollection.length === 0 ? { minHeight: 'unset' } : {}}
          >
            <div className="content__container">
              <div className="acp__content-body">
                <div>
                  <div className="acp__content-header">
                    <div className="acpch__queryResults-container">
                      <p>Your query generated {0} results</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          results &&
          Object.keys(results).length > 0 && (
            <div
              className="acp__content"
              style={
                resultsCollection.length === 0 ? { minHeight: 'unset' } : {}
              }
              ref={resultsRef}
            >
              <div className="content__container">
                <div className="acp__content-body">
                  {Object.keys(results).length > 0 ? (
                    <div>
                      <div className="acp__content-header">
                        <div className="acpch__queryResults-container">
                          <p>
                            {t(
                              'Your query generated {{ amount }} results from {{totalAreas}} different areas',
                              {
                                amount: Object.keys(results).reduce(
                                  (a, b) =>
                                    a + (results[b] ? results[b].length : 0),
                                  0
                                ),
                                totalAreas
                              }
                            )}
                            :
                          </p>
                        </div>
                        {true && (
                          <>
                            <div className="acp__filter-container">
                              <div
                                className="acpfc__trigger"
                                onClick={() => setAreaGroupOpen(!areaGroupOpen)}
                              >
                                <span>{t('GROUP BY')}</span>
                                <span>
                                  {groupBy === 'areas'
                                    ? t('Areas')
                                    : groupBy === 'jurisdictions'
                                    ? t('Jurisdictions')
                                    : groupBy === 'types'
                                    ? t('Type of Document')
                                    : groupBy === 'regions'
                                    ? t('Region')
                                    : groupBy === 'un_regions'
                                    ? t('UN Region')
                                    : groupBy === 'ilga_regions'
                                    ? t('ILGA Region')
                                    : ''}{' '}
                                  <FilterIcon size={14} />
                                </span>
                              </div>
                              <div
                                className={`acpfc__dropdown ${
                                  areaGroupOpen ? 'open' : ''
                                }`}
                                ref={dropGroupBy}
                              >
                                <ul className="list-unstyled">
                                  <li
                                    onClick={() => {
                                      setGroupBy('areas')
                                    }}
                                  >
                                    {t('Areas')}
                                  </li>
                                  <li
                                    onClick={() => {
                                      setGroupBy('jurisdictions')
                                    }}
                                  >
                                    {t('Jurisdictions')}
                                  </li>
                                  <li
                                    onClick={() => {
                                      setGroupBy('types')
                                    }}
                                  >
                                    {t('Type of document')}
                                  </li>
                                  <li
                                    onClick={() => {
                                      setGroupBy('regions')
                                    }}
                                  >
                                    {t('Region')}
                                  </li>
                                  <li
                                    onClick={() => {
                                      setGroupBy('ilga_regions')
                                    }}
                                  >
                                    {t('ILGA Region')}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="acp__filter-container">
                              <div
                                className="acpfc__trigger"
                                onClick={() => setJurGroupOpen(!jurGroupOpen)}
                              >
                                <span>{t('SORT BY')} </span>
                                <span>
                                  {orderBy === 'jur_asc'
                                    ? t('Jurisdictions asc.')
                                    : orderBy === 'jur_desc'
                                    ? t('Jurisdictions desc.')
                                    : orderBy === 'date_asc'
                                    ? t('Date asc.')
                                    : orderBy === 'date_desc'
                                    ? t('Date desc.')
                                    : ''}{' '}
                                  <FilterIcon size={14} />
                                </span>
                              </div>
                              <div
                                className={`acpfc__dropdown ${
                                  jurGroupOpen ? 'open' : ''
                                }`}
                                ref={dropOrderBy}
                              >
                                <ul className="list-unstyled">
                                  <li
                                    onClick={() => {
                                      setOrderBy('jur_asc')
                                    }}
                                  >
                                    {t('Jurisdiction asc.')}
                                  </li>
                                  <li
                                    onClick={() => {
                                      setOrderBy('jur_desc')
                                    }}
                                  >
                                    {t('Jurisdiction desc.')}
                                  </li>
                                  <li
                                    onClick={() => {
                                      setOrderBy('date_asc')
                                    }}
                                  >
                                    {t('Date asc.')}
                                  </li>
                                  <li
                                    onClick={() => {
                                      setOrderBy('date_desc')
                                    }}
                                  >
                                    {t('Date desc.')}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <Accordion>
                        {Object.keys(results).length > 0 &&
                          Object.keys(results)
                            .sort(
                              (a, b) =>
                                groupBy === 'jurisdictions' &&
                                (a > b ? 1 : a < b ? -1 : 0)
                            )
                            .filter(a => results[a] && results[a].length)
                            .map((item, index) => {
                              return (
                                <Accordion.Item eventKey={index} key={index}>
                                  <Accordion.Header>
                                    <span className="accordDecoration">+</span>
                                    <strong>
                                      {' '}
                                      {groupBy === 'areas' ? (
                                        <>
                                          {theRoutes.find(
                                            r => r.id === item.toLowerCase()
                                          )
                                            ? theRoutes.find(
                                                r => r.id === item.toLowerCase()
                                              ).name
                                            : item}
                                        </>
                                      ) : groupBy === 'jurisdictions' ||
                                        groupBy === 'types' ? (
                                        <>{item}</>
                                      ) : groupBy === 'regions' ||
                                        groupBy === 'un_regions' ||
                                        groupBy === 'ilga_regions' ? (
                                        <>
                                          {regions.find(r => r.id === item)
                                            ? regions.find(r => r.id === item)
                                                .name
                                            : item}
                                        </>
                                      ) : (
                                        <></>
                                      )}{' '}
                                    </strong>{' '}
                                    &nbsp; (
                                    {results[item].length.toLocaleString(
                                      'de-CH'
                                    )}{' '}
                                    {t('entries')})
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="acp__tableContainer">
                                      <SimpleTable
                                        columns={advancedSearchColumns(
                                          setToast,
                                          history
                                        )}
                                        entries={results[item].filter(
                                          e => e.details
                                        )}
                                        citeEntry={allCites}
                                        paginate={true}
                                        extraClasses={{ table: 'acp__table' }}
                                        withReport
                                      />
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              )
                            })}
                      </Accordion>
                    </div>
                  ) : (
                    <div className="acpcb__noContent">
                      <p>
                        Oops! It appears that we have no results for your query.
                      </p>
                      <p>
                        Have you tried expanding the time range for your query?
                        If you are combining several parameters try removing
                        some of them to improve your results.
                      </p>
                      <button className="btn">
                        Clear filters and try again
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </MainLayout>
  )
}
