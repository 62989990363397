import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FilterIcon from '../icons/FilterIcon'
import './Dropdown.css'
import DropdownMenuJurs from './DropdownMenuJurs'
import DropdownPopper from './DropdownPopper'

function DropdownJurs({
  title,
  items,
  multiSelect = false,
  setTableFilter,
  jurisdictions,
  regions,
  loading,
  error,
  sortSelected = false,
  search = false,
  filterLoading = false,
  buttonText = <FilterIcon size={18} />,
  customActive,
  filterValue,
  clearFilterExtras,
  compareTool = false
}) {
  const [filter, setFilter] = useState('')
  const [listItems, setListItems] = useState(items)
  const [isOpen, setIsOpen] = useState(false)
  const [regionSelected, setRegionSelected] = useState(false)

  const [selection, setSelection] = useState(() => {
    return filterValue && filterValue.length
      ? filterValue.map(fv => ({ id: fv, value: fv }))
      : []
  })
  const inputRef = useRef()
  const filterButton = useRef()

  /* DISABLED FOR MAP GENERATOR TEST
  useEffect(() => {
    if (!isOpen) setFilter('')
    else {
      setSelection(
        filterValue && filterValue.length
          ? filterValue.map(fv => ({ id: fv, value: fv }))
          : []
      )
    }
  }, [isOpen])
 */
  const { t } = useTranslation()

  useEffect(() => {
    if ((!filterValue || !filterValue.length) && selection && selection.length)
      setSelection([])
  }, [filterValue])

  useEffect(() => {
    if (filter) {
      let listItemsTmp = {}
      Object.keys(items).forEach(group => {
        listItemsTmp = {
          ...listItemsTmp,
          [group]: items[group].filter(
            li => li.value.toLowerCase().indexOf(filter) !== -1
          )
        }
      })
      setListItems(listItemsTmp)
    } else {
      setListItems(items)
    }
  }, [filter, items])

  useEffect(() => {
    //nsole.log(selection)
    if (selection && selection.length) {
      setTableFilter(selection.map(s => s.id))
    } else {
      setTableFilter([])
    }
  }, [selection])

  //HOOK TO FIX HIDE COLUMNS ISSUE
  useEffect(() => {
    if (filterValue && filterValue.length && (!selection || !selection.length))
      setSelection(filterValue.map(fv => ({ id: fv })))
  }, [filterValue])

  function handleOnClick(item) {
    /*  OLD ONCLICK FUNCTION = remove */
  }

  function isItemInSelection(item) {
    if (selection && selection.some(current => current.id === item.id)) {
      return true
    }
    return false
  }

  let selectIndividual = value => {
    setFilter('')
    inputRef.current.state.value = ''
    let tmpSelection = []
    if (!selection || !selection.some(current => current.id === value.id)) {
      if (!multiSelect) {
        tmpSelection = [value]
      } else if (multiSelect) {
        tmpSelection = [...selection, value].sort((a, b) => a - b)
      }
    } else {
      let selectionAfterRemoval = selection
      selectionAfterRemoval = selectionAfterRemoval.filter(
        current => current.id !== value.id
      )
      tmpSelection = [...selectionAfterRemoval]
    }
    return tmpSelection
  }

  let selectJursFromRegion = (region, checked) => {
    setRegionSelected(true)
    let tmpSelection = selectIndividual(region)

    let newSelection = []
    if (!checked) {
      newSelection = tmpSelection.filter(
        s =>
          s.id !== region.id &&
          jurisdictions
            .filter(j => j.regions.map(r => r.id).indexOf(region.id) !== -1)
            .map(j => j.id)
            .indexOf(s.id) === -1
      ) //remove region
    } else {
      newSelection = [...tmpSelection].concat(
        jurisdictions
          .filter(j => j.regions.map(r => r.id).indexOf(region.id) !== -1)
          .map(j => ({ id: j.id, value: j.name }))
      )
    }
    setSelection(newSelection)
  }

  let selectRegionsFromJur = (jurisdiction, checked) => {
    let tmpSelection = selectIndividual(jurisdiction)
    let newSelection = []
    if (!checked) {
      newSelection = tmpSelection.filter(
        s =>
          s.id !== jurisdiction.id &&
          regions
            .filter(
              reg =>
                jurisdictions
                  .find(j => j.id === jurisdiction.id)
                  .regions.map(r => r.id)
                  .indexOf(reg.id) !== -1
            )
            .map(j => j.id)
            .indexOf(s.id) === -1
      ) //remove region
    } else {
      newSelection = tmpSelection.concat([jurisdiction])
      //Todo select regions from array
    }
    setSelection(newSelection)
  }

  return (
    <div style={{ width: '100%' }}>
      <DropdownPopper
        buttonText={buttonText}
        active={selection && selection.length}
        filterLoading={filterLoading}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        customActive={customActive}
      >
        <DropdownMenuJurs
          listItems={listItems}
          isItemInSelection={isItemInSelection}
          selection={selection}
          setFilter={setFilter}
          defaultFilterText={filter}
          handleOnClick={handleOnClick}
          //Functions to execute when an item of the selected group changes
          extraFunctions={{
            regions: (region, checked) => selectJursFromRegion(region, checked),
            jurisdictions: (jur, checked) => selectRegionsFromJur(jur, checked)
          }}
          regionSelected={regionSelected}
          t={t}
          ref={inputRef}
          width="480px"
          search={search}
          closeDropdown={() => setIsOpen(false)}
          sortSelected={sortSelected}
          compareTool={compareTool}
          clearFilter={e => {
            setSelection([])
            setFilter('')
            setIsOpen(false)
            clearFilterExtras()
          }}
        />
      </DropdownPopper>
    </div>
  )
}

export default DropdownJurs
