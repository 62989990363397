import React from 'react';

const VotingIcon = ({color='#E7EDEF'}) => <svg id="Voting" xmlns="http://www.w3.org/2000/svg" width="91.465" height="41.483" viewBox="0 0 91.465 41.483">
  <path id="Path_1788" data-name="Path 1788" d="M17.933,39.166H36.608a4.122,4.122,0,0,0,3.818-2.531l6.266-11.3a4.1,4.1,0,0,0,.29-1.515v-4.15a4.162,4.162,0,0,0-4.15-4.15H29.74l1.971-9.482.062-.664a3.123,3.123,0,0,0-.913-2.2L28.661,1,14.987,14.674a4.124,4.124,0,0,0-1.2,2.926V35.016A4.162,4.162,0,0,0,17.933,39.166Zm0-21.567,9.005-9.005-2.78,11.08H42.832v4.15L36.608,35.016H17.933ZM3,18.432h9.133V39.166H3Z" transform="translate(-3 -1)" fill={color}/>
  <path id="Path_1856" data-name="Path 1856" d="M14.933,38.166H33.608a4.122,4.122,0,0,0,3.818-2.531l6.266-11.3a4.1,4.1,0,0,0,.29-1.515v-4.15a4.162,4.162,0,0,0-4.15-4.15H26.74l1.971-9.482.062-.664a3.123,3.123,0,0,0-.913-2.2L25.661,0,11.987,13.674a4.124,4.124,0,0,0-1.2,2.926V34.016A4.162,4.162,0,0,0,14.933,38.166Zm0-21.567,9.005-9.005-2.78,11.08H39.832v4.15L33.608,34.016H14.933ZM0,17.432H9.133V38.166H0Z" transform="translate(91.465 41.483) rotate(180)" fill={color}/>
</svg>

export default VotingIcon;






