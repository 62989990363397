import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default function SubJurChartSsu({ eEntry, jur, subArea, noShow }) {
  const [t, i18n] = useTranslation()
  const [texts, setTexts] = useState({})
  console.log('AAA', eEntry.main.motherEntry)
  useEffect(() => {
    if (eEntry && jur) {
      const data = {
        jurName: jur.name,
        n1s: eEntry.n_subs,
        n1t: eEntry.sub.length,
        n1sp: Math.round((eEntry.n_subs / eEntry.sub.length) * 100),
        n1spp: Math.round((eEntry.n_subsp / eEntry.n_subptotal) * 100),
        n1c: eEntry.n_subc,
        n1cp: Math.round((eEntry.n_subc / eEntry.sub.length) * 100),
        n1cpp: Math.round((eEntry.n_subcp / eEntry.n_subptotal) * 100)
      }
      setTexts({
        151:
          eEntry.n_subs < eEntry.sub.length
            ? t(
                '<p>Same sex marriage is not available nationwide in {{jurName}}</p>',
                data
              ) +
              (eEntry.main?.motherEntry?.jurisdiction?.id !==
                'UN-185' ||
              (eEntry.main?.motherEntry?.entry_type_id !== 'A1-6' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-7' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-8' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-10' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-11' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-12' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-13' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-15' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-16' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-18')
                ? t(
                    '<p>Currently, {{n1}} out of {{n1t}} subnational jurisdictions offer this protection ({{n1p}}%), covering approximately {{n1pp}}% of the total population.</p>',
                    data
                  )
                : '')
            : eEntry.n_subs > 0
            ? t(
                '<p>Same sex marriage is available in all subnational jurisdictions in {{jurName}}, covering the entirety of the population.</p>',
                data
              )
            : '',
        152:
          eEntry.n_subc < eEntry.sub.length
            ? t(
                '<p>Civil union is not available nationwide in {{jurName}}</p>',
                data
              ) +
              (eEntry.main?.motherEntry?.jurisdiction?.id !==
                'UN-185' ||
              (eEntry.main?.motherEntry?.entry_type_id !== 'A1-6' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-7' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-8' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-10' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-11' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-12' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-13' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-15' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-16' &&
                eEntry.main?.motherEntry?.entry_type_id !== 'A1-18')
                ? t(
                    '<p>Currently, {{n1c}} out of {{n1t}} subnational jurisdictions offer this protection ({{n1cp}}%), covering approximately {{n1cpp}}% of the total population.</p>',
                    data
                  )
                : '')
            : eEntry.n_subc > 0
            ? t(
                '<p>Civil union is available in all subnational jurisdictions in {{jurName}}, covering the entirety of the population.</p>',
                data
              )
            : ''
      })
    }
  }, [eEntry, jur])

  return (
    <>
      {eEntry && eEntry.sub.length > 0 && eEntry.n_subs > 0 && !noShow && (
        <>
          <div className="jpjs__summaryBoxMain">
            <h2 className="subJurSsuTitle">{t('Same-Sex Marriage')}</h2>
            <div className="jpjs__summaryBoxSsu">
              <div className="sbox__left">
                <p>{t('Sub-national jurisdictions')}</p>
                <div className="jpjs__progressBar">
                  <div
                    className={
                      'jpjspb__left ' + (subArea === 1 ? 'red' : 'blue')
                    }
                    style={{
                      width: (eEntry.n_subs / eEntry.sub.length) * 100 + '%'
                    }}
                  >
                    {Math.round((eEntry.n_subs / eEntry.sub.length) * 100)} %
                  </div>
                  {100 - Math.round((eEntry.n_subs / eEntry.sub.length) * 100) >
                    0 && (
                    <div
                      className="jpjspb__right"
                      style={{
                        width:
                          100 - (eEntry.n_subs / eEntry.sub.length) * 100 + '%'
                      }}
                    >
                      {100 -
                        Math.round(
                          (eEntry.n_subs / eEntry.sub.length) * 100
                        )}{' '}
                      %
                    </div>
                  )}
                </div>
                <p>{t('Population')}</p>
                <div className="jpjs__progressBar">
                  <div
                    className={
                      'jpjspb__left ' + (subArea === 1 ? 'red' : 'green')
                    }
                    style={{
                      width:
                        Math.round(
                          (eEntry.n_subsp / eEntry.n_subptotal) * 100
                        ) + '%'
                    }}
                  >
                    {Math.round((eEntry.n_subsp / eEntry.n_subptotal) * 100)} %
                  </div>
                  {100 -
                    Math.round((eEntry.n_subsp / eEntry.n_subptotal) * 100) >
                    0 && (
                    <div
                      className="jpjspb__right"
                      style={{
                        width:
                          100 -
                          Math.round(
                            (eEntry.n_subsp / eEntry.n_subptotal) * 100
                          ) +
                          '%'
                      }}
                    >
                      {100 -
                        Math.round(
                          (eEntry.n_subsp / eEntry.n_subptotal) * 100
                        )}{' '}
                      %
                    </div>
                  )}
                </div>
              </div>
              <div className="sbox__right">
                <div dangerouslySetInnerHTML={{ __html: texts[151] }} />
              </div>
            </div>
          </div>
          <div className="jpjs__summaryBoxMain">
            <h2 className="subJurSsuTitle">{t('Same-Sex Civil Unions')}</h2>

            <div className="jpjs__summaryBoxSsu">
              <div className="sbox__left">
                <p>{t('Sub-national jurisdictions')}</p>
                <div className="jpjs__progressBar">
                  <div
                    className={
                      'jpjspb__left ' + (subArea === 1 ? 'red' : 'blue')
                    }
                    style={{
                      width: (eEntry.n_subc / eEntry.sub.length) * 100 + '%'
                    }}
                  >
                    {Math.round((eEntry.n_subc / eEntry.sub.length) * 100)} %
                  </div>
                  {100 - Math.round((eEntry.n_subc / eEntry.sub.length) * 100) >
                    0 && (
                    <div
                      className="jpjspb__right"
                      style={{
                        width:
                          100 - (eEntry.n_subc / eEntry.sub.length) * 100 + '%'
                      }}
                    >
                      {100 -
                        Math.round(
                          (eEntry.n_subc / eEntry.sub.length) * 100
                        )}{' '}
                      %
                    </div>
                  )}
                </div>
                <p>{t('Population')}</p>
                <div className="jpjs__progressBar">
                  <div
                    className={
                      'jpjspb__left ' + (subArea === 1 ? 'red' : 'green')
                    }
                    style={{
                      width:
                        Math.round(
                          (eEntry.n_subcp / eEntry.n_subptotal) * 100
                        ) + '%'
                    }}
                  >
                    {Math.round((eEntry.n_subcp / eEntry.n_subptotal) * 100)} %
                  </div>
                  {100 -
                    Math.round((eEntry.n_subcp / eEntry.n_subptotal) * 100) >
                    0 && (
                    <div
                      className="jpjspb__right"
                      style={{
                        width:
                          100 -
                          Math.round(
                            (eEntry.n_subcp / eEntry.n_subptotal) * 100
                          ) +
                          '%'
                      }}
                    >
                      {100 -
                        Math.round(
                          (eEntry.n_subcp / eEntry.n_subptotal) * 100
                        )}{' '}
                      %
                    </div>
                  )}
                </div>
              </div>
              <div className="sbox__right">
                <div dangerouslySetInnerHTML={{ __html: texts[152] }} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
