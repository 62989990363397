import { elementToSVG } from 'dom-to-svg'
import { SVG } from '@svgdotjs/svg.js'
import { fabric } from 'fabric'
import { saveAs } from 'file-saver'

import { staticIlgaLogo } from '../mocks/components/ui/IlgaLogo'

const svgToString = svg => new XMLSerializer().serializeToString(svg)

function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

let _lato = null
async function getLato() {
  if (_lato) return _lato
  _lato = Promise.all([
    fetch('/fonts/lato-400.woff2')
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        return new Promise(resolve => {
          reader.onload = () => resolve(reader.result)
        })
      }),
    fetch('/fonts/lato-700.woff2')
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        return new Promise(resolve => {
          reader.onload = () => resolve(reader.result)
        })
      })
  ])
  return _lato
}

async function addLato(draw) {
  const [lato400, lato700] = await getLato()
  draw.fontface('Lato', `url("${lato400}")`, {
    'font-style': 'normal',
    'font-weight': 400,
    'font-display': 'swap',
    'unicode-range':
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
  })

  draw.fontface('Lato', `url("${lato700}")`, {
    'font-style': 'normal',
    'font-weight': 700,
    'font-display': 'swap',
    'unicode-range':
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
  })

  draw.fontface('Lato700', `url("${lato700}")`, {
    'font-style': 'normal',
    'font-weight': 700,
    'font-display': 'swap',
    'unicode-range':
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
  })
}

export const toString = draw =>
  draw.svg(node => {
    node.attr('svgjs:data', null)
  }, true)

export const open = draw => {
  const blob = new Blob([toString(draw)], {
    type: 'image/svg+xml;charset=utf-8'
  })
  // create an URI pointing to that blob
  const url = URL.createObjectURL(blob)
  window.open(url)
  // so the Garbage Collector can collect the blob
  // win.onload = (evt) => URL.revokeObjectURL(url);
}

export const divToSVG = async container => {
  const svg = elementToSVG(container)
  const draw = SVG(svgToString(svg))
  await addLato(draw)
  draw.find('foreignObject').forEach(node => {
    node.remove()
  })
  // remove the map zoom tool
  draw.find('.mapControls__container').forEach(node => {
    node.remove()
  })
  // remove the crop area
  draw.findOne('.mgMapSection__container > g:first-child').remove()
  return draw
}

export const toSVG = async (container, opts = {}) => {
  const { title, subtitle, parse } = opts

  const map = SVG(svgToString(container.querySelector('#chartdiv svg')))
  const mapBackground = map.findOne('rect')
  const draw = SVG().size(
    mapBackground.width(),
    mapBackground.height() +
      (mapBackground.width() - 1.4142 * mapBackground.height()) / 1.4142
  )

  // error images not found
  map.find('foreignObject').forEach(node => {
    node.remove()
  })

  await addLato(draw)

  // background
  draw.rect(draw.width(), draw.height()).fill('#EEE9DA')

  draw
    .text(title)
    .font({
      fill: 'rgb(78, 78, 78)',
      family: 'Lato, sans-serif',
      size: '22px',
      stretch: '100%',
      weight: 700
    })
    .y(subtitle ? 20 : 30)
    .attr('x', '50%')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle')

  if (subtitle) {
    draw
      .text(subtitle)
      .font({
        fill: 'rgb(78, 78, 78)',
        family: 'Lato, sans-serif',
        size: '18px',
        stretch: '100%'
      })
      .y(50)
      .attr('x', '50%')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
  }

  draw.add(map)
  map.move(null, 155)
  const mapLegend = elementToSVG(container.querySelector('.mapLegend'))

  const legend = SVG(svgToString(mapLegend))
  let realLegendWidth = legend.findOne('.mapLegend').width()

  if (realLegendWidth > 900) {
    legend.width(1000)
    realLegendWidth =
      (legend.width() * realLegendWidth) / legend.viewbox().width
  }

  draw.add(legend)

  const logo = SVG(staticIlgaLogo)
  draw.add(logo)

  draw
    .text(opts.t('This map was downloaded from the ILGA World Database.'))
    .font({
      fill: 'rgb(78, 78, 78)',
      family: 'Lato, sans-serif',
      size: '12px',
      stretch: '100%'
    })
    .attr('x', '1%')
    .y(draw.height() - 55)
    .attr('text-anchor', 'left')
    .attr('dominant-baseline', 'middle')
  draw
    .text(opts.t('It can be printed and reproduced without permission as long'))
    .font({
      fill: 'rgb(78, 78, 78)',
      family: 'Lato, sans-serif',
      size: '12px',
      stretch: '100%'
    })
    .attr('x', '1%')
    .y(draw.height() - 40)
    .attr('text-anchor', 'left')
    .attr('dominant-baseline', 'middle')

  draw
    .text(opts.t('as the content is not modified.'))
    .font({
      fill: 'rgb(78, 78, 78)',
      family: 'Lato, sans-serif',
      size: '12px',
      stretch: '100%'
    })
    .attr('x', '1%')
    .y(draw.height() - 25)
    .attr('text-anchor', 'left')
    .attr('dominant-baseline', 'middle')

  draw
    .text('database.ilga.org')
    .font({
      fill: 'rgb(78, 78, 78)',
      family: 'Lato700, Lato, sans-serif',
      size: '12px',
      'font-weight': '700',
      stretch: '100%'
    })
    .attr('x', opts.language === 'es' ? '230px' : '175px')
    .y(draw.height() - 25)
    .attr('text-anchor', 'left')
    .attr('dominant-baseline', 'middle')

  if (parse) {
    parse({ title, draw, legend, logo })
  } else {
    legend.height(60)
    legend.x(draw.width() / 2 - realLegendWidth / 2)
    legend.y(draw.height() - legend.height() - 85)
    logo.x(draw.width() - logo.width() - 25)
    logo.y(draw.height() - logo.height() - 20)
  }

  return draw
}

export const downloadToImage = async (draw, opts = {}, title) => {
  const width = draw.width()
  const height = draw.height()

  const {
    filename = (title ? `${title} - ` : `export-`) +
      'ILGA World - Downloaded ' +
      new Date().toISOString().split('T')[0],
    format = 'png',
    quality = 1,
    multiplier = 1
  } = opts

  const canvas = new fabric.StaticCanvas('c', {
    preserveObjectStacking: true
  })

  const svg = new Blob([toString(draw)], {
    type: 'image/svg+xml;charset=utf-8'
  })
  const url = URL.createObjectURL(svg)

  const img = await new Promise(resolve => fabric.Image.fromURL(url, resolve))

  //canvas.setBackgroundColor('#fff')

  canvas.setWidth(width)
  canvas.setHeight(height)
  canvas.add(img)

  await new Promise(resolve => setTimeout(resolve, 1))
  canvas.renderAll()

  const dataURL = canvas.toDataURL({
    format,
    quality,
    multiplier,
    width: (opts.width || width) - 75,
    height: (opts.height || height) - 54
  })

  const image = document.createElement('img')
  image.src = dataURL

  const blob = dataURLtoBlob(dataURL)
  saveAs(blob, `${filename}.${format}`)
}

export const downloadToPDF = async (draw, opts = {}, title) => {
  //open(draw) // use open to test the svg
  //return

  const {
    filename = (title ? `${title} - ` : `export-`) +
      'ILGA World - Downloaded ' +
      new Date().toISOString().split('T')[0]
  } = opts

  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/export/pdf`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filename,
      svg: encodeURIComponent(toString(draw))
    })
  })

  const blob = await res.blob()
  saveAs(blob, `${filename}.pdf`)
}
