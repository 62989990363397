var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      id: 'ID-AC',
      properties: {
        name: 'Aceh',
        id: 'ID-AC',
        NAME_ENG: 'Aceh',
        CNTRY: 'Indonesia',
        TYPE: 'Propinsi',
        TYPE_ENG: 'Province'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [97.3308, 2.0373],
              [97.3441, 2.1074],
              [97.2846, 2.227],
              [97.1654, 2.2349],
              [97.3308, 2.0373]
            ].reverse()
          ],
          [
            [
              [98.2645, 4.2995],
              [98.2768, 4.4268],
              [98.12359, 4.6061224],
              [98.0274018, 4.7447466],
              [97.913, 4.8864],
              [97.8435125, 4.9456102],
              [97.6286, 5.1103],
              [97.5148, 5.2494],
              [97.3314518, 5.2398329],
              [97.255067, 5.2398329],
              [97.1871694, 5.2454911],
              [97.1275, 5.2325],
              [96.9811, 5.2705],
              [96.8205, 5.2789],
              [96.5817495, 5.2454911],
              [96.3582534, 5.2652945],
              [96.1107102, 5.3331921],
              [95.9091, 5.4386],
              [95.8882, 5.5031],
              [95.7386, 5.5853],
              [95.598, 5.6295],
              [95.4089, 5.6505],
              [95.2252128, 5.5736626],
              [95.2239, 5.4795],
              [95.2082384, 5.4124059],
              [95.191264, 5.2907561],
              [95.2591616, 5.1832516],
              [95.2836, 5.1183],
              [95.4227, 4.8467],
              [95.5311, 4.6828],
              [95.8283, 4.4206],
              [96.0091, 4.2231],
              [96.1644624, 4.0799163],
              [96.2691378, 3.9950443],
              [96.3916, 3.8589],
              [96.5583, 3.7384],
              [96.7500789, 3.678189],
              [96.8264636, 3.6300949],
              [96.8975, 3.6119],
              [96.9905494, 3.5169323],
              [97.1683, 3.2545],
              [97.2253618, 3.1944189],
              [97.3105, 3.0661],
              [97.4202, 2.9285],
              [97.5825313, 2.8506875],
              [97.6277963, 2.6385076],
              [97.6547, 2.4047],
              [97.7503, 2.2708],
              [97.8716, 2.2403],
              [97.9486, 2.2672],
              [98.1512, 2.1521],
              [98.1865, 2.3143],
              [98.1079, 2.4288],
              [98.0771, 2.5603],
              [98.0976, 2.8201],
              [97.9558, 2.9021],
              [97.9421, 3.2013],
              [98.0241, 3.331],
              [97.9489, 3.3908],
              [97.9062, 3.5549],
              [97.8036, 3.7241],
              [97.9147, 3.8284],
              [97.913, 3.9121],
              [98.0207, 3.9651],
              [98.072, 4.2489],
              [98.2645, 4.2995]
            ].reverse()
          ],
          [
            [
              [96.4322, 2.343],
              [96.4841, 2.3711],
              [96.4313, 2.5033],
              [96.2136, 2.6306],
              [96.1011, 2.7525],
              [95.9964, 2.7778],
              [95.8833, 2.9189],
              [95.7904, 2.9369],
              [95.6969, 2.8189],
              [95.7022, 2.7669],
              [95.8189, 2.6547],
              [96.0594, 2.5786],
              [96.2965, 2.422],
              [96.3321, 2.3526],
              [96.4322, 2.343]
            ].reverse()
          ]
        ]
      }
    }
  ]
}

export default map
