import { Fragment } from 'react'
import i18n from '../../config/i18n'
import { citeEntry } from '../../lib/cite'
import { i18ndayjs } from '../../lib/fnDates'
import DateDropdownFilter, {
  ApplyDropdownDateFilter
} from '../components/Table/DateDropdownFilter'
import ExpandableTextCell from '../components/Table/ExpandableTextCell'
import GenericDropdownFilter, {
  ApplyGenericDropdownFilter
} from '../components/Table/GenericDropdownFilter'
import JurCellIssuing from '../components/Table/JurCellIssuing'
import JurFilter, { ApplyJurFilter } from '../components/Table/JurFilter'
import SourceText from '../components/Table/SourceText'
import TopicFilter, { ApplyTopicFilter } from '../components/Table/TopicFilter'

export const citeA5Cycle = (entry, setToast) => {
  const lines = []

  const iniText1 = i18n.t('Human Rights Council') + ', '
  let iniText2

  lines.push('<p>' + entry.explan.replace(/\n/gi, '<br />') + '</p>')

  if (entry.cycle_cu_type.id === '1') {
    iniText2 = i18n.t(
      'National report submitted in accordance with paragraph 5 of the annex to Human Rights Council resolution 16/21'
    )
  } else if (entry.cycle_cu_type.id === '2') {
    iniText2 =
      i18n.t('Compilation on') +
      ' ' +
      entry.motherEntry.jurisdiction.name +
      ': ' +
      i18n.t(
        'Report of the Office of the United Nations High Commissioner for Human Rights'
      )
  } else if (entry.cycle_cu_type.id === '3') {
    iniText2 =
      i18n.t('Summary of stakeholders’ submissions on') +
      ' ' +
      entry.motherEntry.jurisdiction.name
  }

  if (entry.motherEntry.sources && entry.motherEntry.sources.length > 0)
    lines.push(
      '<p>' +
        iniText1 +
        `<em><a href="${process.env.REACT_APP_BACKEND_URL +
          '/downloader/download/5/' +
          (entry.motherEntry.sources.find(
            s =>
              s.original_language.id === (i18n.language === 'en' ? '39' : '41')
          )
            ? entry.motherEntry.sources.find(
                s =>
                  s.original_language.id ===
                  (i18n.language === 'en' ? '39' : '41')
              ).original_filename
            : entry.motherEntry.sources[0]
                .original_filename)}" target="_blank">${iniText2}</a></em>, ` +
        entry.un_symbol +
        (entry.para
          ? ', para. ' +
            (i18n.language !== 'en'
              ? entry.para.replace(/and/gi, 'y')
              : entry.para)
          : '') +
        '.</p>'
    )

  citeEntry(null, null, lines.join(''), setToast)
}

export const columnsCycle = () => {
  return [
    {
      Header: i18n.t('Cycle'),
      accessor: 'cycle',
      className: 'textCenter col100',
      Cell: ({ value, row }) => {
        return <>{row.original.cycle}</>
      },
      filter: (rows, columnsId, filterValue) => {
        return ApplyGenericDropdownFilter(rows, 'cycle', filterValue)
      },
      Filter: ({ column }) => {
        return (
          <GenericDropdownFilter
            column={column}
            width={'125px'}
            options={[
              { id: '1', value: i18n.t('1st Cycle'), disabled: true },
              { id: '2', value: i18n.t('2nd Cycle'), disabled: true },
              { id: '3', value: i18n.t('3rd Cycle') }
            ]}
          />
        )
      }
    },
    {
      Header: i18n.t('Date'),
      accessor: 'date',
      Cell: ({ value, row }) => {
        return i18ndayjs(value, 'MMMM YYYY')
      },
      sortDescFirst: true,
      className: 'textCenter col100',
      filter: (rows, columnsId, filterValue) =>
        ApplyDropdownDateFilter(rows, ['date'], filterValue),
      Filter: ({ column }) => {
        return <DateDropdownFilter column={column} />
      }
    },
    {
      Header: i18n.t('Type'),
      accessor: 'cycle_cu_type.name',
      className: 'textCenter col125',
      filter: (rows, columnsId, filterValue) =>
        ApplyGenericDropdownFilter(
          rows,
          'cycle_cu_type.name',
          filterValue,
          false,
          false,
          false
        ),
      Filter: ({ column }) => {
        return (
          <GenericDropdownFilter
            column={column}
            options={[
              {
                id: i18n.t('National Report'),
                value: i18n.t('National Report')
              },
              {
                id: i18n.t('Compilation of UN Information'),
                value: i18n.t('Compilation of UN Information')
              },
              {
                id: i18n.t('Summary of Stakeholders'),
                value: i18n.t('Summary of Stakeholders')
              }
            ]}
          />
        )
      }
    },
    {
      Header: i18n.t('Explanation'),
      accessor: 'explan',
      Cell: ({ value, row }) => {
        if (value)
          return (
            <ExpandableTextCell
              value={value}
              row={row}
              sources={null}
              unik={row.original.id + '_explan'}
              nltobr={true}
            />
          )
        else return <></>
      },
      NonExpandableCell: ({ value, row }) => {
        return (
          <SourceText
            text={value}
            skipSources={0}
            sources={row.original.motherEntry.sources}
            unik={row.original.id + '_explan'}
          />
        )
      },
      sortType: (rowA, rowB, id, desc) => {
        if (!rowB.original.general_explan) return null
        if (
          rowA.original.general_explan.replace(/[^\w\s]/gi, '') >
          rowB.original.general_explan.replace(/[^\w\s]/gi, '')
        )
          return -1
        if (
          rowB.original.general_explan.replace(/[^\w\s]/gi, '') >
          rowA.original.general_explan.replace(/[^\w\s]/gi, '')
        )
          return 1
        return 0
      },
      disableSortBy: true,
      className: 'col300'
    },

    {
      Header: i18n.t('Jurisdiction'),
      disableGroupBy: true,
      accessor: 'motherEntry.jurisdiction.name',
      filter: ApplyJurFilter,
      Filter: ({ column }) => {
        return <JurFilter column={column} search={true} />
      },
      Cell: ({ value, row }) => {
        return (
          <JurCellIssuing
            jurisdiction={row.original.motherEntry.jurisdiction}
          />
        )
      },
      CellSmall: ({ value, row }) => {
        return (
          <div>
            {row.original.motherEntry.jurisdiction.name.substring(0, 3)}
          </div>
        )
      },
      className: 'textCenter col125'
    },

    {
      Header: i18n.t('Topics'),
      accessor: 'motherEntry.topics',
      Cell: ({ value, row }) => {
        return row.original.motherEntry.topics
          ? row.original.motherEntry.topics.map((topic, index) => (
              <Fragment key={row.original + index}>
                <div className="tableTopic">{topic.name}</div>
                <div className="topicSeparator" />
              </Fragment>
            ))
          : ''
      },
      Filter: ({ column, rows }) => {
        return <TopicFilter column={column} search={true} rows={rows} />
      },
      filter: ApplyTopicFilter,
      className: 'textCenter col170',
      disableSortBy: true
    },

    {
      Header: i18n.t('Source'),
      accessor: 'source',
      Cell: ({ value, row }) => {
        if (row.original.motherEntry.sources)
          return (
            <div className="tableSources">
              <div>
                {row.original.motherEntry.sources &&
                row.original.motherEntry.sources.length > 0 ? (
                  <a
                    href={
                      process.env.REACT_APP_BACKEND_URL +
                      '/downloader/download/5/' +
                      (row.original.motherEntry.sources.find(
                        s =>
                          s.original_language.id ===
                          (i18n.language === 'en' ? '39' : '41')
                      )
                        ? row.original.motherEntry.sources.find(
                            s =>
                              s.original_language.id ===
                              (i18n.language === 'en' ? '39' : '41')
                          ).original_filename
                        : row.original.motherEntry.sources[0].original_filename)
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {row.original.un_symbol}
                  </a>
                ) : (
                  <> {row.original.un_symbol}</>
                )}
              </div>

              <div>
                {i18n.t('Session')}: {row.original.session}
              </div>
              {row.original.para && (
                <div>
                  {i18n.t('Para')}. {row.original.para}
                </div>
              )}
              <div>{row.original.text5}</div>
            </div>
          )
        else return <></>
      },
      className: 'col125',
      disableSortBy: true,
      disableFilter: true
    }
  ]
}
