import React, { useEffect, useRef, useState } from 'react'
import './Dropdown.css'
import { DebounceInput } from 'react-debounce-input'
import { Accordion } from 'react-bootstrap'
import scrollIntoView from 'scroll-into-view-if-needed'

const DropdownMenuJurs = React.forwardRef(
  (
    {
      handleBlur,
      coords,
      listItems,
      extraFunctions,
      isItemInSelection,
      setFilter,
      handleOnClick,
      t,
      sortSelected,
      search,
      closeDropdown,
      clearFilter,
      width,
      regionSelected,
      compareTool
    },
    inputRef
  ) => {
    const wrapperRef = useRef()
    const [active, setActive] = useState('')

    useEffect(() => {
      if (regionSelected) setActive('regions')
      else if (!compareTool) setActive('jurisdictions')
      setTimeout(() => {
        if (wrapperRef.current)
          scrollIntoView(wrapperRef.current, {
            block: 'nearest',
            scrollMode: 'if-needed'
          })
      }, 600)
    }, [])
    return (
      <div
        className="ignore-this-class dd-wrapper dd-wrapperjur"
        style={{ width }}
        onBlur={handleBlur}
        ref={wrapperRef}
      >
        {clearFilter && (
          <button className="dd-clear-filter" onClick={clearFilter}>
            {t('Clear Filter')}
          </button>
        )}
        {search && (
          <div className="dd-filter">
            <DebounceInput
              className="dd-searchInput"
              autoFocus
              minLength={2}
              debounceTimeout={300}
              onChange={e => {
                setFilter(e.target.value.toLowerCase())
              }}
              placeholder={t('Search')}
              ref={inputRef}
            />
          </div>
        )}

        <div className="accordionsContainer">
          <Accordion flush={false} activeKey={active}>
            {Object.keys(listItems).map((group, i) => (
              <Accordion.Item eventKey={group} key={group}>
                <Accordion.Header
                  onClick={() => {
                    if (active === group) setActive(null)
                    else setActive(group)
                  }}
                >
                  <h5>
                    {group === 'regions'
                      ? t('Groups and regions')
                      : t('Jurisdictions')}
                  </h5>
                </Accordion.Header>
                <Accordion.Body>
                  {listItems[group]
                    .filter(item => !sortSelected || isItemInSelection(item))
                    .map(item => (
                      <div className="dd-list-item" key={item.id}>
                        <button
                          className={isItemInSelection(item)}
                          type="button"
                          onClick={() => {
                            handleOnClick(item)
                            extraFunctions[group](
                              item,
                              !isItemInSelection(item)
                            )
                          }}
                        >
                          <span className="check-container">
                            <input
                              name={item.value}
                              type="checkbox"
                              defaultChecked={isItemInSelection(item)}
                            />
                            <label htmlFor={item.value}>
                              {item.icon &&
                                group === 'jurisdictions' &&
                                item.icon}
                              {item.value}
                            </label>
                          </span>
                        </button>
                      </div>
                    ))}
                  {listItems[group]
                    .filter(item => sortSelected && !isItemInSelection(item))
                    .map(item => (
                      <div className="dd-list-item" key={item.id}>
                        <button
                          type="button"
                          onClick={e => {
                            handleOnClick(item)
                            extraFunctions[group](
                              item,
                              !isItemInSelection(item)
                            )
                          }}
                        >
                          <span className="check-container">
                            <input
                              name={item.value}
                              type="checkbox"
                              defaultChecked={isItemInSelection(item)}
                            />
                            <label htmlFor={item.value}>
                              {item.icon &&
                                group === 'jurisdictions' &&
                                item.icon}
                              {item.value}
                            </label>
                          </span>
                        </button>
                      </div>
                    ))}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    )
  }
)

export default DropdownMenuJurs
