import Tippy from '@tippyjs/react'
import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import { useTranslation } from 'react-i18next'
import IconInfo from '../../../components/icons/IconInfo'
import i18n from '../../../config/i18n'
import './TalliesSection.css'

const TalliesSection = ({
  area = 'A1-1',
  canStart,
  replaceNumbers = null,
  title,
  tooltipNumbers = null
}) => {
  const { t } = useTranslation()
  const tallySectionData = {
    'A1-1': {
      tallyTitle: t('Criminalisation of consensual same-sex<br />sexual acts'),
      tallySubtitle: t('UN MEMBER STATES'),
      tallyItems: [
        { number: 66, numberColor: 'orange', item: t('Criminalise by law') },
        { number: 2, numberColor: 'orange', item: t('Criminalise de facto') },
        { number: 125, numberColor: 'blue', item: t('Do not criminalise') }
      ]
    },
    'A1-3': {
      tallyTitle: t('Legal barriers to freedom of expression'),
      tallySubtitle: t('UN MEMBER STATES'),
      atLeast: true,
      tallyItems: [
        {
          number: 42,
          numberColor: 'orange',
          item: t('With legal barriers (total)')
        },
        {
          number: 8,
          numberColor: 'orange',
          item: t('With specific barriers in education')
        },
        {
          number: 22,
          numberColor: 'orange',
          item: t('With specific barriers in the media')
        }
      ]
    },
    'A1-4': {
      tallyTitle: t('Legal barriers to freedom of association'),
      tallySubtitle: t('UN MEMBER STATES'),
      atLeast: true,
      tallyItems: [
        {
          number: 42,
          numberColor: 'orange',
          item: t('With legal barriers (total)')
        },
        {
          number: 15,
          numberColor: 'orange',
          item: t('With confirmed legal barriers (explicit and non-explicit)')
        },
        {
          number: 58,
          numberColor: 'orange',
          item: t('With legal barriers likely to exist')
        }
      ]
    },
    'A1-5': {
      tallyTitle: title
        ? title
        : t('Constitutional protection against discrimination'),
      tallySubtitle: t('UN MEMBER STATES'),
      tallyItems: [
        {
          number: 11,
          numberColor: 'blue',
          item: t('SO'),
          explanation: t('Sexual Orientation')
        },
        {
          number: 5,
          numberColor: 'blue',
          item: t('GI'),
          explanation: t('Gender Identity')
        },
        {
          number: 0,
          numberColor: 'blue',
          item: t('GE'),
          explanation: t('Gender Expression')
        },
        {
          number: 0,
          numberColor: 'blue',
          item: t('SC'),
          explanation: t('Sex Characteristics')
        }
      ]
    },

    'A1-8': {
      tallyTitle: t('Protection against discrimination in education'),
      tallySubtitle: t('UN MEMBER STATES'),
      tallyItems: [
        {
          number: 11,
          numberColor: 'blue',
          item: t('SO'),
          explanation: t('Sexual Orientation')
        },
        {
          number: 5,
          numberColor: 'blue',
          item: t('GI'),
          explanation: t('Gender Identity')
        },
        {
          number: 0,
          numberColor: 'blue',
          item: t('GE'),
          explanation: t('Gender Expression')
        },
        {
          number: 0,
          numberColor: 'blue',
          item: t('SC'),
          explanation: t('Sex Characteristics')
        }
      ]
    },
    'A1-14': {
      tallyTitle: t('Regulation of so-called “conversion therapies”'),
      tallySubtitle: t('UN MEMBER STATES'),
      tallyItems: [
        {
          number: 4,
          numberColor: 'blue',
          item: t('With nationwide regulations')
        },
        {
          number: 5,
          numberColor: 'blue',
          item: t('With subnational regulations only')
        },
        { number: 6, numberColor: 'blue', item: t('With indirect regulations') }
      ]
    },
    'A1-15': {
      tallyTitle: t('Same-Sex Marriage and Civil Unions'),
      tallySubtitle: t('UN MEMBER STATES'),
      tallyItems: [
        {
          number: 15,
          numberColor: 'blue',
          item: t('With full marriage equality')
        },
        {
          number: 58,
          numberColor: 'blue',
          item: t('With other forms of civil partnership')
        },
        {
          number: 124,
          numberColor: 'blue',
          item: t(
            'With different levels of protection across subnational entities'
          )
        }
      ]
    },
    'A1-16': {
      tallyTitle: t('Adoption by same-sex couples'),
      tallySubtitle: t('UN MEMBER STATES'),
      tallyItems: [
        {
          number: 15,
          numberColor: 'blue',
          item: t('Where joint adoption is legal')
        },
        {
          number: 58,
          numberColor: 'blue',
          item: t('Where second parent adoption is legal')
        }
      ]
    },
    'A1-17': {
      tallyTitle: t('Restrictions on interventions on intersex minors'),
      tallySubtitle: t('UN MEMBER STATES'),
      tallyItems: [
        {
          number: 15,
          numberColor: 'blue',
          item: t('with nationwide restrictions')
        },
        {
          number: 58,
          numberColor: 'blue',
          item: t('with restrictions at the subnational level')
        },
        { number: 58, numberColor: 'orange', item: t('with no restrictions') }
      ]
    },
    'A1-18': {
      tallyTitle: t('Legal gender recognition'),
      tallySubtitle: t('UN MEMBER STATES'),
      atLeast: true,
      tallyItems: [
        {
          number: 11,
          numberColor: 'blue',
          item: t('Self-ID_TALLIES'),
          noLower: true,
          atLeast: ' ',
          tooltip: true
        },
        {
          number: 15,
          numberColor: 'blue',
          item: t('Where non-binary gender markers are avaliable'),
          atLeast: ' ',
          tooltip: true
        },
        {
          number: 11,
          numberColor: 'orange',
          item: t('Where surgery or sterilisation is required'),
          atLeast: t('AT LEAST')
        },
        {
          number: 15,
          numberColor: 'orange',
          item: t('Where a diagnosis is required'),
          atLeast: t('AT LEAST')
        }
      ]
    }
  }
  const numberOfItems = tallySectionData[area].tallyItems.length
  const formattingFn = n => {
    return n === 0 ? '0' : n
  }

  return (
    <section className="tallies__section">
      <div className="tally__container">
        <div className="tallyTopic">
          <h5
            className="tallyTitle"
            dangerouslySetInnerHTML={{
              __html: tallySectionData[area].tallyTitle
            }}
          />

          <h6 className="tallySubtitle">
            {tallySectionData[area].tallySubtitle}
          </h6>
        </div>
        <div className="tallyData__container">
          {tallySectionData[area].tallyItems.map((item, index) => {
            return (
              <div
                className={
                  area === 'A1-18'
                    ? 'td__box-118' + ' td__box-118_' + index
                    : area === 'A1-14' || area === 'A1-15' || area === 'A1-17'
                    ? 'td__box-114'
                    : area === 'A1-3'
                    ? 'td__box-13'
                    : area === 'A1-4'
                    ? 'td__box-14'
                    : 'td__box'
                }
                style={{ width: 100 / 4 + '%' }}
                key={'tallies' + item.item}
              >
                {tallySectionData[area].atLeast && (
                  <span className="td__atLeast">
                    {item.atLeast ? item.atLeast : t('AT LEAST')}
                  </span>
                )}
                <span className={`td__number text-${item.numberColor}`}>
                  {canStart ? (
                    <div>
                      <span id={'tallyN' + index}>-</span>
                      <CountUp
                        start={0}
                        end={
                          replaceNumbers ? replaceNumbers[index] : item.number
                        }
                        duration={Math.ceil(
                          (replaceNumbers
                            ? replaceNumbers[index]
                            : item.number) / 50
                        )}
                        onStart={() =>
                          (document.getElementById(
                            'tallyN' + index
                          ).style.display = 'none')
                        }
                      >
                        -
                      </CountUp>
                    </div>
                  ) : (
                    '-'
                  )}
                </span>
                <span
                  className={
                    (area === 'A1-18'
                      ? 'td__text'
                      : numberOfItems < 4
                      ? 'td__text'
                      : 'td__text tally__4') + (item.noLower ? ' noLower' : '')
                  }
                >
                  {item.item}
                </span>
                {item.explanation && (
                  <span
                    className={'td__atLeast ' + (item.explanationClass || '')}
                  >
                    {item.explanation}
                  </span>
                )}
                {item.tooltip && tooltipNumbers[index] && (
                  <Tippy
                    open
                    className="helpTippy"
                    content={tooltipNumbers[index].map((t, i) => (
                      <li key={i}>{t}</li>
                    ))}
                    distance="0"
                    allowHTML
                    followCursor="initial"
                    maxWidth={i18n.language === 'en' ? '420px' : '450px'}
                    theme="ilga-help"
                    placement="bottom"
                    popperOptions={{
                      modifiers: [{ name: 'flip', enabled: false }]
                    }}
                  >
                    <a
                      style={{
                        verticalAlign: 'super',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        position: 'relative',
                        top: '-1px'
                      }}
                    >
                      <IconInfo size={22} color="#5c8f95" />
                    </a>
                  </Tippy>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default TalliesSection
