import React, { Fragment } from 'react'
import IconCancel from '../../components/icons/IconCancel'
import IconCheck from '../../components/icons/IconCheck'
import IconMinus from '../../components/icons/IconMinus'
import i18n from '../../config/i18n'
import { citeEntry } from '../../lib/cite'
import { i18ndayjs } from '../../lib/fnDates'
import DateDropdownFilter, {
  ApplyDropdownDateFilter
} from '../components/Table/DateDropdownFilter'
import ExpandableTextCell from '../components/Table/ExpandableTextCell'
import GenericDropdownFilter, {
  ApplyGenericDropdownFilter
} from '../components/Table/GenericDropdownFilter'
import HelpCellA5 from '../components/Table/HelpCellA5'
import JurCellIssuing from '../components/Table/JurCellIssuing'
import JurFilter, {
  ApplyJurFilter,
  ApplyJurFilterIssuing
} from '../components/Table/JurFilter'
import SourceText from '../components/Table/SourceText'
import TopicFilter, { ApplyTopicFilter } from '../components/Table/TopicFilter'

export const citeA5 = (entry, setToast) => {
  let lines = [
    '<p>' +
      i18n.t('Universal Periodic Review (UPR) recommendation issued to') +
      ' ' +
      entry.motherEntry.jurisdiction.name +
      ' ' +
      i18n.t('by') +
      ' ' +
      entry.issuing_jurisdiction.name +
      ' (' +
      i18n.t('Cycle') +
      ' ' +
      entry.cycle +
      ', ' +
      i18ndayjs(entry.date, 'MMMM YYYY') +
      ') ' +
      '</p>'
  ]

  lines.push(entry.text + ' (' + entry.recommendation_response.name + ')')
  if (entry.additional_info_en)
    lines.push(
      '<p>' +
        entry.additional_info_en +
        (entry.source_ai_url.indexOf('http') !== -1
          ? ` (<a href="${entry.source_ai_url}">${entry.source_ai_url}</a>)`
          : entry.source_ai_url) +
        '</p>'
    )
  if (entry.motherEntry.sources && entry.motherEntry.sources.length > 0)
    lines.push(
      '<p>' +
        i18n.t('Source') +
        `: <em><a href="${process.env.REACT_APP_BACKEND_URL +
          '/downloader/download/5/' +
          (entry.motherEntry.sources.find(
            s =>
              s.original_language.id === (i18n.language === 'en' ? '39' : '41')
          )
            ? entry.motherEntry.sources.find(
                s =>
                  s.original_language.id ===
                  (i18n.language === 'en' ? '39' : '41')
              ).original_filename
            : entry.motherEntry.sources[0]
                .original_filename)}" target="_blank">${i18n.t(
          'Report of the Working Group on the Universal Periodic Review'
        )}: ${entry.motherEntry.jurisdiction.name}</a></em>, ` +
        entry.un_symbol +
        (entry.reference ? ', para. ' + entry.reference : '') +
        '.</p>'
    )

  citeEntry(null, null, lines.join(''), setToast)
}

const getBgColorClass = (cell, col) => {
  return cell.row.original[col].id === '1'
    ? 'rowProtectionYes'
    : cell.row.original[col].id === '2'
    ? 'rowProtectionNo'
    : cell.row.original[col].id === '3'
    ? 'rowProtectionVaries'
    : 'rowProtectionUnclear'
}

export const columns = () => {
  return [
    {
      Header: i18n.t('Cycle'),
      accessor: 'cycle',
      className: 'textCenter col100',
      Cell: ({ value, row }) => {
        return <>{row.original.cycle}</>
      },
      filter: (rows, columnsId, filterValue) => {
        return ApplyGenericDropdownFilter(rows, 'cycle', filterValue)
      },
      Filter: ({ column }) => {
        return (
          <GenericDropdownFilter
            column={column}
            width={'125px'}
            options={[
              { id: '1', value: i18n.t('1st Cycle'), disabled: true },
              { id: '2', value: i18n.t('2nd Cycle'), disabled: true },
              { id: '3', value: i18n.t('3rd Cycle') }
            ]}
          />
        )
      }
    },
    {
      Header: i18n.t('Date'),
      accessor: 'date',
      Cell: ({ value, row }) => {
        return i18ndayjs(value, 'MMMM YYYY')
      },
      sortDescFirst: true,
      className: 'textCenter col100',
      filter: (rows, columnsId, filterValue) =>
        ApplyDropdownDateFilter(rows, ['date'], filterValue),
      Filter: ({ column }) => {
        return <DateDropdownFilter column={column} />
      }
    },
    {
      Header: i18n.t('Recommendation'),
      accessor: 'text',
      Cell: ({ value, row }) => {
        if (value)
          return (
            <ExpandableTextCell
              value={value}
              row={row}
              sources={null}
              unik={row.original.id + '_explan'}
            />
          )
        else return <></>
      },
      NonExpandableCell: ({ value, row }) => {
        return (
          <SourceText
            text={value}
            skipSources={0}
            sources={row.original.motherEntry.sources}
            unik={row.original.id + '_explan'}
          />
        )
      },
      sortType: (rowA, rowB, id, desc) => {
        if (!rowB.original.general_explan) return null
        if (
          rowA.original.general_explan.replace(/[^\w\s]/gi, '') >
          rowB.original.general_explan.replace(/[^\w\s]/gi, '')
        )
          return -1
        if (
          rowB.original.general_explan.replace(/[^\w\s]/gi, '') >
          rowA.original.general_explan.replace(/[^\w\s]/gi, '')
        )
          return 1
        return 0
      },
      disableSortBy: true,
      className: 'col300'
    },

    {
      Header: i18n.t('Response'),
      accessor: 'recommendation_response.name',

      Cell: ({ value, row }) => {
        return row.original.recommendation_response.id === '1' ? (
          <>
            <IconCheck size={24} />
            <div className="">{value}</div>
            {row.original.additional_info_en && row.original.source_ai_url && (
              <HelpCellA5
                source={
                  row.original.source_ai_url.indexOf('http') !== -1 &&
                  row.original.source_ai_url
                }
                helpText={row.original.additional_info_en}
                bg={getBgColorClass({ row }, 'recommendation_response')}
              />
            )}
          </>
        ) : row.original.recommendation_response.id === '2' ? (
          <>
            <IconCancel size={24} />
            <div className="">{value}</div>
            {row.original.additional_info_en && row.original.source_ai_url && (
              <HelpCellA5
                source={
                  row.original.source_ai_url.indexOf('http') !== -1 &&
                  row.original.source_ai_url
                }
                helpText={row.original.additional_info_en}
                bg={getBgColorClass({ row }, 'recommendation_response')}
              />
            )}
          </>
        ) : row.original.recommendation_response.id === '3' ? (
          <>
            <IconMinus size={24} />
            <div className="">{value}</div>
            {row.original.additional_info_en && row.original.source_ai_url && (
              <HelpCellA5
                source={
                  row.original.source_ai_url.indexOf('http') !== -1 &&
                  row.original.source_ai_url
                }
                helpText={row.original.additional_info_en}
                bg={getBgColorClass({ row }, 'recommendation_response')}
              />
            )}
          </>
        ) : (
          <>
            <div className="">{value}</div>
            {row.original.additional_info_en && row.original.source_ai_url && (
              <HelpCellA5
                source={
                  row.original.source_ai_url.indexOf('http') !== -1 &&
                  row.original.source_ai_url
                }
                helpText={row.original.additional_info_en}
                bg={getBgColorClass({ row }, 'recommendation_response')}
              />
            )}
          </>
        )
      },
      filter: (rows, columnsId, filterValue) => {
        return ApplyGenericDropdownFilter(
          rows,
          'recommendation_response.name',
          filterValue
        )
      },
      Filter: ({ column }) => {
        return (
          <GenericDropdownFilter
            column={column}
            width={'170px'}
            options={[
              { id: i18n.t('Accepted'), value: i18n.t('Accepted') },
              { id: i18n.t('Noted'), value: i18n.t('Noted') },
              {
                id: i18n.t('Partially accepted'),
                value: i18n.t('Partially accepted')
              },
              {
                id: i18n.t('Awaiting response'),
                value: i18n.t('Awaiting response')
              }
            ]}
          />
        )
      },

      className: 'textCenter flexTd col100',
      classNameFn: c => getBgColorClass(c, 'recommendation_response')
    },
    {
      Header: i18n.t('Receiving state'),
      disableGroupBy: true,
      accessor: 'motherEntry.jurisdiction.name',
      filter: ApplyJurFilter,
      Filter: ({ column }) => {
        return <JurFilter column={column} search={true} />
      },
      Cell: ({ value, row }) => {
        return (
          <JurCellIssuing
            jurisdiction={row.original.motherEntry.jurisdiction}
          />
        )
      },
      CellSmall: ({ value, row }) => {
        return (
          <div>
            {row.original.motherEntry.jurisdiction.name.substring(0, 3)}
          </div>
        )
      },
      className: 'textCenter col125'
    },
    {
      Header: i18n.t('Issuing State'),
      disableGroupBy: true,
      accessor: 'issuing_jurisdiction.name',
      filter: ApplyJurFilterIssuing,
      Filter: ({ column }) => {
        return <JurFilter column={column} search={true} />
      },
      Cell: ({ value, row }) => {
        return (
          <JurCellIssuing jurisdiction={row.original.issuing_jurisdiction} />
        )
      },
      CellSmall: ({ value, row }) => {
        return <div>{row.original.issuing_state.name.substring(0, 3)}</div>
      },
      className: 'textCenter col125'
    },
    {
      Header: i18n.t('Topics'),
      accessor: 'motherEntry.topics',
      Cell: ({ value, row }) => {
        return row.original.motherEntry.topics
          ? row.original.motherEntry.topics.map((topic, index) => (
              <Fragment key={row.original + index}>
                <div className="tableTopic">{topic.name}</div>
                <div className="topicSeparator" />
              </Fragment>
            ))
          : ''
      },
      Filter: ({ column, rows }) => {
        return <TopicFilter column={column} search={true} rows={rows} />
      },
      filter: ApplyTopicFilter,
      className: 'textCenter col170',
      disableSortBy: true
    },

    {
      Header: i18n.t('Source'),
      accessor: 'source',
      Cell: ({ value, row }) => {
        return (
          <div className="tableSources">
            {row.original.motherEntry.sources ? (
              <div>
                {row.original.motherEntry.sources &&
                row.original.motherEntry.sources.length > 0 ? (
                  <a
                    href={
                      process.env.REACT_APP_BACKEND_URL +
                      '/downloader/download/5/' +
                      (row.original.motherEntry.sources.find(
                        s =>
                          s.original_language.id ===
                          (i18n.language === 'en' ? '39' : '41')
                      )
                        ? row.original.motherEntry.sources.find(
                            s =>
                              s.original_language.id ===
                              (i18n.language === 'en' ? '39' : '41')
                          ).original_filename
                        : row.original.motherEntry.sources[0].original_filename)
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {row.original.un_symbol}
                  </a>
                ) : (
                  <> {row.original.un_symbol}</>
                )}
              </div>
            ) : (
              <> {row.original.un_symbol}</>
            )}
            <div>
              {i18n.t('Ref')}: {row.original.reference}
            </div>
            <div>
              {i18n.t('Session')}: {row.original.session}
            </div>
            <div>{row.original.text5}</div>
          </div>
        )
      },
      className: 'col125',
      disableSortBy: true,
      disableFilter: true
    }
  ]
}
