import React, { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'

import { useTranslation } from 'react-i18next'
import { cArea13MapReferences } from '../../config/constants'
import useGqlEntriesFoe from '../../gql/useGqlEntriesFoe'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import MainLayout from '../../layouts/MainLayout'
import '../../pages/Area.css'
import BarChart from '../components/Areas/A1-3/BarChart'
import MapContainer from '../components/Map/MapContainer'
import microstates from '../components/Map/microstates.json'
import MapTooltip from '../components/StyledMapToolTips.js'
import TableContainer from '../components/Table/TableContainer'
import TalliesSection from '../components/TalliesSection/TalliesSection'
import MainSpinner from '../components/ui/MainSpinner'
import { citeA13, columns, getTableEntries } from '../config/a13columns'

import useGqlTextComponents from '../../gql/useGqlTextComponents'
import AreaContent from '../components/Areas/AreaContent'
export default function Area13() {
  const htmlReferences = useRef()
  const [references, setReferences] = useState([])
  const [tableEntries, setTableEntries] = useState([])
  const [talliesNumbers, setTalliesNumbers] = useState([])
  const [mapLoaded, setMapLoaded] = useState(false)
  const [filters, setFilters] = useState(null)
  const { textComponents } = useGqlTextComponents('A1-3')
  const { entriesFoe, loading, error } = useGqlEntriesFoe()
  const { jurisdictions, jurisdictionsById } = useGqlJurisdictions()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (!loading && !error && jurisdictions && entriesFoe) {
      let referencesTmp = []

      for (let j of jurisdictions) {
        let entry = entriesFoe.find(e => e.motherEntry.jurisdiction.id === j.id)

        if (entry) referencesTmp.push(entry)
      }
      referencesTmp = entriesFoe

      setReferences(
        referencesTmp.map(e => {
          return {
            id:
              e.motherEntry.subjurisdiction && e.motherEntry.subjurisdiction.id
                ? e.motherEntry.subjurisdiction.id
                : e.motherEntry.jurisdiction.a2_code,
            regions: e.motherEntry.jurisdiction.regions,
            microstate: microstates.find(
              ms => ms.A2 === e.motherEntry.jurisdiction.a2_code
            ),
            reference:
              cArea13MapReferences().find(
                ml => ml.id === parseInt(e.entry_foe_barrier_summary_value.id)
              ) &&
              cArea13MapReferences().find(
                ml => ml.id === parseInt(e.entry_foe_barrier_summary_value.id)
              ).color,
            reference_text: 'test',
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <MapTooltip
                entry={e}
                title={t('Legal barriers to freedom of expression')}
                area={'A1-3'}
                extraProps={{
                  bgColor:
                    cArea13MapReferences().find(
                      ml =>
                        ml.id === parseInt(e.entry_foe_barrier_summary_value.id)
                    ) &&
                    cArea13MapReferences().find(
                      ml =>
                        ml.id === parseInt(e.entry_foe_barrier_summary_value.id)
                    ).color
                }}
                positive={e.entry_foe_barrier_summary_value.id === '3'}
                hasSubjur={entriesFoe.find(
                  e2 =>
                    e2.motherEntry.jurisdiction.id ===
                      e.motherEntry.jurisdiction.id &&
                    e2.motherEntry.subjurisdiction
                )}
              />
            ), //tooltip(e),
            entry: e
          }
        })
      )

      let tmpTableEntries = []
      let tmpTallyNumbers = [0, 0, 0]

      for (let entry of entriesFoe) {
        if (
          !entry.motherEntry.subjurisdiction &&
          entry.motherEntry.jurisdiction.un_member
        ) {
          if (entry.entry_foe_barrier_summary_value.id !== '3') {
            tmpTallyNumbers[0]++
          }
          if (
            entry.entry_foe_barrier_education_value &&
            entry.entry_foe_barrier_education_value.id !== '3'
          ) {
            tmpTallyNumbers[1]++
          }
          if (
            entry.entry_foe_barrier_media_value &&
            entry.entry_foe_barrier_media_value.id !== '3'
          ) {
            tmpTallyNumbers[2]++
          }
        }
        tmpTableEntries = [
          ...tmpTableEntries,
          ...getTableEntries(entry, tmpTableEntries.length)
        ]
      }

      setTableEntries(tmpTableEntries)
      setTalliesNumbers(tmpTallyNumbers)
    }
  }, [jurisdictions, entriesFoe, error, loading])

  const areaInfo = {
    title: `${t('Area')} 1`,
    subTitle: t('Legal Frameworks'),
    subSubTitle: `${t('Legal barriers to freedom of expression')}`,
    areaName: 'area-1'
  }

  return (
    <MainLayout section="areas">
      <div className="area__page area1__page A1-3">
        <section className="selectRegion__section">
          <MapContainer
            references={references}
            htmlReferences={htmlReferences}
            jurisdictionsById={jurisdictionsById}
            jurisdictions={jurisdictions}
            mapLegendItems={cArea13MapReferences()}
            mapLoaded={mapLoaded}
            setMapLoaded={setMapLoaded}
            currentArea={'A1-3'}
            title={areaInfo.title}
            subTitle={areaInfo.subTitle}
            subSubTitle={areaInfo.subSubTitle}
            areaName={areaInfo.areaName}
            modalTitle={areaInfo.subSubTitle}
            modalContent={textComponents.length && textComponents[0].map}
          />
        </section>
        <div className="content__container">
          <TalliesSection
            area={'A1-3'}
            canStart={mapLoaded}
            replaceNumbers={talliesNumbers}
          />

          <AreaContent areaIntro={'A1-3'} areaLatest={'A1-3'} />

          <div className="mainCTA__charts">
            <section className="mainCTA__section">
              <div className="mainGraphic__section">
                <h5>
                  {t('Legal barriers to freedom of expression by region')}
                </h5>
                {references && references.length > 0 ? (
                  <BarChart references={references} />
                ) : (
                  <>
                    <MainSpinner text={t('Loading chart...')} />
                  </>
                )}
              </div>
            </section>
          </div>
        </div>
        <section className="table__section">
          <div className="content__container">
            {entriesFoe && entriesFoe.length > 0 && (
              <TableContainer
                entries={tableEntries}
                setEntries={setTableEntries}
                outterFilters={filters}
                setOutterFilters={setFilters}
                initialEntries={[...tableEntries]}
                t={t}
                columns={columns()}
                iniExpandable
                trMainClass={'a13Tr'}
                citeEntry={citeA13}
                emptyFieldsNoCite={[
                  'general_explan',
                  'education_explan',
                  'media_explan'
                ]}
                title={t('Area') + '1'}
                subtitle={
                  t('Legal Frameworks') +
                  ' | ' +
                  '<span style="font-weight:700">' +
                  t('Legal barriers to freedom of expression') +
                  '</span>'
                }
                paginate={false}
                initialSortBy={[
                  {
                    id: 'motherEntry.jurisdiction.name',
                    desc: false
                  }
                ]}
                showUNSwitch
              />
            )}
          </div>
        </section>
      </div>
    </MainLayout>
  )
}
