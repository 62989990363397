import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GET_A1_4_ENTRIES_FOA = gql(`
  query GetEntriesFOA($lang: String) {
    entriesFoa(lang: $lang) {
      id
      motherEntry {
        entry_type_id
        jurisdiction {
          id
          name
          un_member
          a2_code
          regions{
            id
          }
        }
        subjurisdiction {
          id
          name
          slug
          label_longitude
          label_latitude
          label_font_size
        }
        footnotes {
          id
          order
          section
          content
        }
        sources {
          id
          order
          section
          original_official_filename
          original_filename
          translated_filename
          enacted
          original_official_language{
            name
            id
          }
          original_official_language2{
            name
            id
          }
          original_language{
            name
            id
          }
          original_language2{
            name
            id
          }
        }
      }
      critical_date_1
      critical_date_2
      explan

      barrier_value{
        id
        name
      }
      mechanism{
        id
        name
      }

    }
  }
`);

function useGqlEntriesFoa() {
  const { i18n } = useTranslation();
  const entries_foa = useQuery(GET_A1_4_ENTRIES_FOA, { variables: { lang: i18n.language } });
  //const penalties_csssa = useQuery(GET_A1_1_PENALTIES_CSSSA, { variables: { lang: i18n.language } });

  return { entriesFoa: entries_foa.data && entries_foa.data.entriesFoa };
}

export default useGqlEntriesFoa;
