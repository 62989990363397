import { gql } from '@apollo/client'

const GET_COMPARE_TOOL = gql(`
  query GetCompareTool($lang: String, $jurs_ids: [String], $areas_ids: [String]) {
    compareTool(lang: $lang, jurs_ids: $jurs_ids, areas_ids: $areas_ids) {
      area
      jurisdiction {
        id
        name
        a2_code
      }
      details
      preDetails
      posDetails
      sources {
        id
        order
        section
        original_official_filename
        original_filename
        translated_filename
        enacted
        original_official_language{
          name
        }
        original_official_language2{
          name
        }
        
        original_language{
          name
        }
        original_language2{
          name
        }
      }

  }
}
`)

export default async (apolloClient, filters, lang = 'en') => {
  const response = await apolloClient.query({
    query: GET_COMPARE_TOOL,
    variables: {
      lang,
      areas_ids: filters.areas_ids
        ? filters.areas_ids.concat(
            filters.subareas
              ? Object.keys(filters.subareas)
                  .map(a => filters.subareas[a])
                  .flat()
              : []
          )
        : null,
      jurs_ids: filters.jurs_ids || []
    }
  })

  return response
}
