import Tippy from '@tippyjs/react'
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ReactSwitch from 'react-switch'
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table'
import IconInfo from '../../../components/icons/IconInfo'
import { AccountContext } from '../../../context/Accounts'
import { getDescendantProp } from '../../../lib/fnHelper'
import ChevronIcon from '../icons/ChevronIcon'
import CiteIcon from '../icons/CiteIcon'
import ReportIcon from '../icons/ReportIcon'
import SortAscIcon from '../icons/SortAscIcon'
import SortDescIcon from '../icons/SortDescIcon'
import SubjurIcon from '../icons/SubjurIcon'
import MainSpinner from '../ui/MainSpinner'
import ColumnFilter from './ColumnFilter'
import ReportErrorModal from './ReportErrorModal'
import './Table.css'
import './Tippy.css'

export const DOTS = '...'

const range = (start, end) => {
  let length = end - start + 1
  return Array.from({ length }, (_, idx) => idx + start)
}

export const getPagination = (
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage
) => {
  const totalPageCount = Math.ceil(totalCount / pageSize)

  // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
  const totalPageNumbers = siblingCount + 5

  /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
  if (totalPageNumbers >= totalPageCount) {
    return range(1, totalPageCount)
  }

  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
  const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount)

  /*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
  const shouldShowLeftDots = leftSiblingIndex > 2
  const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

  const firstPageIndex = 1
  const lastPageIndex = totalPageCount

  if (!shouldShowLeftDots && shouldShowRightDots) {
    let leftItemCount = 3 + 2 * siblingCount
    let leftRange = range(1, leftItemCount)

    return [...leftRange, DOTS, totalPageCount]
  }

  if (shouldShowLeftDots && !shouldShowRightDots) {
    let rightItemCount = 3 + 2 * siblingCount
    let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount)
    return [firstPageIndex, DOTS, ...rightRange]
  }

  if (shouldShowLeftDots && shouldShowRightDots) {
    let middleRange = range(leftSiblingIndex, rightSiblingIndex)
    return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
  }
}

export default function Table(props) {
  const {
    entries = [],
    setEntries,
    columns,
    citeEntry,
    emptyFieldsNoCite,
    noReportError,
    iniExpandable = false,
    paginate = false,
    trMainClass = 'tableJurCell',
    tableContainerExtraClass = '',
    onSort = state => {},
    customColumnHide = null,
    customColumnShown = true,
    showSubjurSwitch = true,
    showUNSwitch = false,
    allowFullscreen = false,
    fullScreen,
    setFullScreen,
    emptyHeaderBg,
    showFilters = true,
    showSort = true,
    showFooter = true,
    disableHover = false,
    disableFirstCol = false,
    fixedHeight = true,
    manualFilter,
    largeHeader = false,
    initialSortBy = [],
    setInitialSortBy,
    unique = '',
    noDeleteRow = false,
    showUNMembers,
    setShowUNMembers,
    extraSelectHeaderClass = '',
    extraSortCellHeaderClass = '',
    outterFilters,
    setOutterFilters
  } = props
  const defaultColumn = useMemo(
    () => ({ Filter: ColumnFilter, minWidth: 30, width: 150, maxWidth: 400 }),
    []
  )

  const { setToast } = useContext(AccountContext)

  const [scrolledX, setScrolledX] = useState(false)
  const [expandable, setExpandable] = useState(iniExpandable)
  const [firstLoad, setFirstLoad] = useState(true)
  const [currentSort, setCurrentSort] = useState({
    id: 'motherEntry.jurisdiction.name',
    desc: true
  })
  const [customHidden, setCustomHidden] = useState(customColumnShown)
  const [reportErrorOpen, setReportErrorOpen] = useState(false)
  const [hiddenRows, setHiddenRows] = useState([])
  const [numberOfPages, setNumberOfPages] = useState([])

  const [showSubjurs, setShowSubjurs] = useState([])

  const [showAllSubjurs, setShowAllSubjurs] = useState(false)

  const refTable = useRef()
  const columnListRef = useRef()

  const { i18n, t } = useTranslation()
  useEffect(() => {
    console.log('LOADED TABLE')
  }, [])
  const tableColumns = useMemo(() => {
    setTimeout(() => {
      setFirstLoad(false)
    }, 200)
    if (!firstLoad) return columns
    else return []
  }, [columns, showUNMembers, showAllSubjurs, firstLoad])

  const data = useMemo(() => {
    return entries.map(e => {
      return {
        ...e,
        n_subjurs:
          !showAllSubjurs && !e.motherEntry?.subjurisdiction?.id
            ? entries.filter(
                entry =>
                  entry.motherEntry?.subjurisdiction?.id &&
                  entry.motherEntry.jurisdiction.id ===
                    e.motherEntry.jurisdiction.id
              ).length
            : -1
      }
    })
  }, [entries, showAllSubjurs, manualFilter])

  //useSwipeScroll({ sliderRef: refTable });

  let initialExpanded = null

  if (expandable) {
    initialExpanded = {
      expanded: {
        1: true,
        3: true,
        4: true
      }
    }
  }

  function useInstance(instance) {
    const { allColumns } = instance

    let rowSpanHeaders = []

    allColumns.forEach((column, i) => {
      const { id, enableRowSpan } = column

      if (enableRowSpan !== undefined) {
        rowSpanHeaders = [
          ...rowSpanHeaders,
          { id, topCellValue: null, topCellIndex: 0 }
        ]
      }
    })

    Object.assign(instance, { rowSpanHeaders })
  }

  const sortFunction = useMemo(
    () => (rows, sortFunction, isSortedDesc) => {
      // we split the rows. One for parents, the other for children.
      const newRowOrder = [],
        subRows = []

      // loop through the rows and separate parents and children like ICE
      for (const row of rows) {
        if (row.original.parent)
          subRows.push({
            ...row,
            ...(row.original?.motherEntry?.subjurisdiction?.id && {
              jurisdiction: {
                ...row.original.motherEntry.jurisdiction,
                name: row.original.motherEntry.subjurisdiction.name
              }
            })
          })
        else
          newRowOrder.push({
            ...row,
            values: {
              ...row.values,
              ...(row.original?.motherEntry?.subjurisdiction?.id && {
                jurisdiction: row.original.motherEntry.subjurisdiction.name
              })
            },
            original: {
              ...row.original,
              motherEntry: {
                ...row.original.motherEntry,
                ...(row.original?.motherEntry?.subjurisdiction?.id && {
                  jurisdiction: {
                    ...row.original.motherEntry.jurisdiction,
                    name: row.original.motherEntry.subjurisdiction.name
                  }
                })
              }
            }
          })
      }

      // sort the parents
      newRowOrder.sort(sortFunction[0])

      let subjurRows = newRowOrder.filter(r => r =>
        r.original?.motherEntry?.subjurisdiction?.id
      )
      subjurRows.reverse()

      if (isSortedDesc[0]) newRowOrder.reverse()
      // create a new array to re-unite the parents and children
      let combinedRows = []

      // loop through all of the sorted parents
      for (const row of newRowOrder) {
        if (
          showAllSubjurs ||
          (!showAllSubjurs && !row.original?.motherEntry?.subjurisdiction?.id)
        ) {
          combinedRows.push(row)
        }
      }

      if (!showAllSubjurs)
        for (const row of subjurRows) {
          if (row.original?.motherEntry?.subjurisdiction?.id) {
            combinedRows.splice(
              combinedRows.findIndex(
                r =>
                  !r.original?.motherEntry?.subjurisdiction?.id &&
                  r.original?.motherEntry?.jurisdiction?.id ===
                    row.original?.motherEntry?.jurisdiction?.id
              ) + 1,
              0,
              row
            )
          }
        }
      // return the sorted parents and children
      return combinedRows
    },
    [showAllSubjurs]
  )

  const tableInstance = useTable(
    {
      columns: tableColumns,
      data,
      debug: true,
      ...(!initialSortBy && { orderByFn: sortFunction }),
      defaultColumn,
      disableSortRemove: true,
      autoResetFilters: false,
      ...(!paginate && { manualPagination: true, pageCount: 1 }),

      initialState: {
        sortBy: initialSortBy,
        ...(paginate && { pageSize: 100 }),
        hiddenColumns: !columns
          ? []
          : columns
              .filter(column => {
                return column.isHidden
              })
              .map(column => column.accessor)
      },

      ...(expandable && {
        manualSortBy: false,
        ...(paginate && { pageSize: 100 }),
        initialState: {
          initialExpanded,
          hiddenColumns: !columns
            ? []
            : columns
                .filter(column => {
                  return column.isHidden
                })
                .map(column => column.accessor)
        }
      })
    },
    hooks => {
      /*if (expandable) {
        hooks.push(useExpanded);
      }
      if (paginate) {
        hooks.push(usePagination);
      }*/
      hooks.useInstance.push(useInstance)
    },
    useFilters,

    useGlobalFilter,

    useSortBy,
    useExpanded,
    usePagination,
    useInstance
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    allColumns,
    getToggleHideAllColumnsProps,
    hiddenColumns,
    toggleAllRowsExpanded,
    isAllRowsExpanded,
    rowSpanHeaders,
    setAllFilters,
    setFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize
  } = tableInstance

  const { globalFilter, sortBy, pageIndex, pageSize, filters } = state

  const handleClickDeleteRow = row => {
    let els = document.getElementsByClassName('jurTr' + row.id.split('.')[0])
    if (
      filters.find(f => f.id === 'motherEntry.jurisdiction.name') &&
      row.original?.motherEntry?.jurisdiction?.id
    ) {
      setFilter(
        'motherEntry.jurisdiction.name',
        filters
          .find(f => f.id === 'motherEntry.jurisdiction.name')
          .value.filter(f => f !== row.original?.motherEntry?.jurisdiction?.id)
      )
    }
    setHiddenRows([...hiddenRows, row.id])
    if (els && els.length) {
      for (let el of els) {
        el.style.display = 'none'
      }
    }
  }

  useEffect(() => {
    if (manualFilter && tableColumns.length) {
      for (let mf of manualFilter) {
        setFilter(mf.id, mf.value)
      }
    }
  }, [manualFilter])

  useEffect(() => {
    setNumberOfPages(getPagination(rows.length, pageSize, 1, pageIndex + 1))
  }, [pageOptions, pageIndex, pageSize])

  const handleResetClick = () => {
    allColumns.forEach(item => {
      if (!item.isVisible) {
        item.toggleHidden()
      }
    })
    refTable.current.classList.add('tableArea1-1-big')
    columnListRef.current.classList.add('tableAddColumnsHidden')
  }

  const hoverAction = (direction, element, index, event, parentJurIndex) => {
    if (disableHover) return
    if (direction === 'enter' && element === 'th' && !customColumnHide) {
      let col0 = [...refTable.current.querySelectorAll('[data-column]')].filter(
        el => el.dataset.column === '' + index
      )
      if (index !== 0)
        document.getElementById('deleteCol' + unique + index).style.display =
          'inline'
      for (let el of col0) {
        el.classList.add('hover')
      }
    } else if (direction === 'leave' && element === 'th' && !customColumnHide) {
      let col0 = [...refTable.current.querySelectorAll('[data-column]')].filter(
        el => el.dataset.column === '' + index
      )
      if (index !== 0)
        document.getElementById(
          'deleteCol' + unique + index
        ).style.display = null
      for (let el of col0) {
        el.classList.remove('hover')
      }
    } else if (
      direction === 'enter' &&
      element === 'td' &&
      event.target.closest('tr')
    ) {
      for (let ch of event.target.closest('tr').cells) {
        //ch.style.backgroundColor = "#f4fdff";
        ch.classList.add('firstHover')

        if (document.getElementById('deleteRow' + unique + index)) {
          document.getElementById('deleteRow' + unique + index).style.display =
            'inline'
          ;[
            ...document
              .getElementById('rowActions' + unique + index)
              .getElementsByTagName('g')
          ].forEach(el => (el.style.fill = '#99a7ab'))
        }
        if (
          parentJurIndex &&
          document.getElementById('deleteRow' + unique + parentJurIndex)
        ) {
          document
            .getElementById('deleteRow' + unique + parentJurIndex)
            .parentElement.classList.add('firstHover')
          document.getElementById(
            'deleteRow' + unique + parentJurIndex
          ).style.display = 'inline'
          ;[
            ...document
              .getElementById('rowActions' + unique + parentJurIndex)
              .getElementsByTagName('g')
          ].forEach(el => (el.style.fill = '#99a7ab'))
        }
      }
    } else if (
      direction === 'leave' &&
      element === 'td' &&
      event.target.closest('tr')
    ) {
      for (let ch of event.target.closest('tr').cells) {
        //ch.style.backgroundColor = null;
        ch.classList.remove('firstHover')
        if (document.getElementById('deleteRow' + unique + index)) {
          document.getElementById(
            'deleteRow' + unique + index
          ).style.display = null
          document.getElementById(
            'rowActions' + unique + index
          ).style.display = null
          ;[
            ...document
              .getElementById('rowActions' + unique + index)
              .getElementsByTagName('g')
          ].forEach(el => (el.style.fill = '#E7EDEF'))
        }
        if (
          parentJurIndex &&
          document.getElementById('deleteRow' + parentJurIndex)
        ) {
          document
            .getElementById('deleteRow' + unique + parentJurIndex)
            .parentElement.classList.remove('firstHover')
          document.getElementById(
            'deleteRow' + unique + parentJurIndex
          ).style.display = null
          document.getElementById(
            'rowActions' + unique + parentJurIndex
          ).style.display = null
          ;[
            ...document
              .getElementById('rowActions' + unique + parentJurIndex)
              .getElementsByTagName('g')
          ].forEach(el => (el.style.fill = '#E7EDEF'))
        }
      }
    }
  }

  useLayoutEffect(() => {
    setTimeout(() => {
      if (expandable && !isAllRowsExpanded && rows.length)
        toggleAllRowsExpanded(true)
    }, 1)
  }, [state])

  useEffect(() => {
    console.log(customHidden, customColumnHide)
    if (customColumnHide)
      if (customHidden) {
        allColumns.forEach(item => {
          if (!item.isVisible) {
            item.toggleHidden()
          }
        })
      } else if (!customHidden) {
        allColumns.forEach(item => {
          if (
            item.isVisible &&
            customColumnHide.columns.indexOf(item.id) !== -1
          ) {
            item.toggleHidden()
          }
        })
      }
  }, [customHidden])

  /* useEffect(() => {
    if (expandable) {
      if (!sortBy[0]) return
      if (
        sortBy[0].id !== currentSort.id ||
        sortBy[0].desc !== currentSort.desc
      ) {
        setEntries(
          [...entries]
            .map(e => ({ ...e, subRowsLength: 0, cid: null }))
            .sort((rowA, rowB) => {
              if (
                (getDescendantProp(rowA, sortBy[0].id)
                  ? getDescendantProp(rowA, sortBy[0].id)
                  : '-') >
                (getDescendantProp(rowB, sortBy[0].id)
                  ? getDescendantProp(rowB, sortBy[0].id)
                  : '-')
              )
                return sortBy[0].desc ? 1 : -1
              if (
                (getDescendantProp(rowB, sortBy[0].id)
                  ? getDescendantProp(rowB, sortBy[0].id)
                  : '-') >
                (getDescendantProp(rowA, sortBy[0].id)
                  ? getDescendantProp(rowA, sortBy[0].id)
                  : '-')
              )
                return !sortBy[0].desc ? 1 : -1
              return 0
            })
        )
        setExpandable(false)
      } else return

      setCurrentSort(sortBy[0])
      onSort(sortBy)
    }
  }, [onSort])*/

  return (
    <>
      <div className={'tableContainer ' + tableContainerExtraClass}>
        <div
          className={
            'tableArea1-1 tableArea1-1-big ' +
            (fullScreen ? 'tableAreaFullVh' : fixedHeight ? 'tableAreaPx' : '')
          }
          ref={refTable}
        >
          {firstLoad ? (
            <div style={{ position: 'relative', height: '100%' }}>
              <MainSpinner text={t('Loading...')} />
            </div>
          ) : (
            <table className="mainTable" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, indexHeader) => {
                  let nHeader = headerGroups.length > 1 ? indexHeader : 2 //when only one header use second variant
                  return (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className={'trHeader header-' + nHeader}
                      key={nHeader}
                    >
                      {headerGroup.headers.map((column, index) => {
                        return column.id.indexOf('_placeholder') === -1 ? (
                          <th
                            {...column.getHeaderProps()}
                            className={
                              (index === 0 && !disableFirstCol && !scrolledX
                                ? 'firstTh'
                                : index === 0 && !disableFirstCol && scrolledX
                                ? 'firstThScrolled'
                                : '') +
                              ' ' +
                              column.className
                            }
                            onMouseOver={e =>
                              nHeader !== 0 && hoverAction('enter', 'th', index)
                            }
                            onMouseLeave={e =>
                              nHeader !== 0 && hoverAction('leave', 'th', index)
                            }
                            data-header={index}
                            style={column.style}
                            key={index}
                          >
                            {index !== 0 && nHeader !== 0 && !customColumnHide && (
                              <button
                                id={'deleteCol' + unique + index}
                                className="deleteCol"
                                onClick={e => {
                                  if (expandable) toggleAllRowsExpanded(true)
                                  refTable.current.classList.remove(
                                    'tableArea1-1-big'
                                  )
                                  setTimeout(() => {
                                    columnListRef.current.classList.add(
                                      'tableAddColumns'
                                    )
                                    columnListRef.current.classList.remove(
                                      'tableAddColumnsHidden'
                                    )
                                  }, 700)
                                  column.toggleHidden()
                                }}
                              >
                                x
                              </button>
                            )}
                            <div
                              className={
                                'wrapCellHeader' +
                                (largeHeader ? ' wrapCellHeader--lg' : '')
                              }
                            >
                              <div className="titleCellHeader">
                                {column.render('Header')}
                              </div>
                              {nHeader !== 0 && (
                                <>
                                  {showSort && (
                                    <div
                                      className={
                                        'sortCellHeader ' +
                                        extraSortCellHeaderClass
                                      }
                                    >
                                      {!column.disableSortBy && (
                                        <p
                                          {...column.getSortByToggleProps()}
                                          onClick={e => {
                                            document.body.style.cursor =
                                              'progress'
                                            if (expandable) setExpandable(false)

                                            setTimeout(() => {
                                              column
                                                .getSortByToggleProps()
                                                .onClick(e)
                                              document.body.style.cursor =
                                                'auto'
                                            }, 1)
                                          }}
                                        >
                                          {column.isSorted ? (
                                            column.isSortedDesc ? (
                                              <SortDescIcon size={16} />
                                            ) : (
                                              <SortAscIcon size={16} />
                                            )
                                          ) : (
                                            <SortAscIcon size={16} />
                                          )}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                  {showFilters && (
                                    <div
                                      className={
                                        'selectCellHeader ' +
                                        extraSelectHeaderClass
                                      }
                                      onClick={event => {
                                        //To force the hiding of the hover when opening a modal filter
                                        if (!customColumnHide) {
                                          let col0 = [
                                            ...refTable.current.querySelectorAll(
                                              '[data-column]'
                                            )
                                          ].filter(
                                            el =>
                                              el.dataset.column === '' + index
                                          )
                                          if (index !== 0)
                                            document.getElementById(
                                              'deleteCol' + unique + index
                                            ).style.display = null
                                          for (let el of col0) {
                                            el.classList.remove('hover')
                                          }
                                        }
                                      }}
                                    >
                                      {!column.disableFilter
                                        ? column.render('Filter')
                                        : null}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </th>
                        ) : (
                          <th
                            className={' header-' + nHeader + '-' + index}
                            style={{
                              backgroundColor: emptyHeaderBg
                                ? emptyHeaderBg
                                : '#d8e3e5'
                            }}
                            key={index}
                          />
                        )
                      })}
                    </tr>
                  )
                })}
              </thead>

              <tbody {...getTableBodyProps()}>
                {(paginate ? page : rows).map((row, indexRow) => {
                  prepareRow(row)

                  let parentJurIndex = null
                  if (
                    expandable &&
                    row.original.cid &&
                    row.original.cid.indexOf('.')
                  ) {
                    parentJurIndex = parseInt(row.original.cid.split('.')[0])
                  }

                  if (
                    row.original?.motherEntry?.jurisdiction?.id === 'UN-185' &&
                    (row.original?.motherEntry?.entry_type_id === 'A1-1' ||
                      row.original?.motherEntry?.entry_type_id === 'A1-6' ||
                      row.original?.motherEntry?.entry_type_id === 'A1-7' ||
                      row.original?.motherEntry?.entry_type_id === 'A1-8' ||
                      row.original?.motherEntry?.entry_type_id === 'A1-10' ||
                      row.original?.motherEntry?.entry_type_id === 'A1-11' ||
                      row.original?.motherEntry?.entry_type_id === 'A1-12' ||
                      row.original?.motherEntry?.entry_type_id === 'A1-15' ||
                      row.original?.motherEntry?.entry_type_id === 'A1-16' ||
                      row.original?.motherEntry?.entry_type_id === 'A1-18') &&
                      row.original.motherEntry?.subjurisdiction?.id)
                  
                    return <></>

                  return (
                    <tr
                      {...row.getRowProps()}
                      className={
                        'tableJurCell ' +
                        trMainClass +
                        ' jurTr' +
                        (row.original.cid
                          ? row.original.cid.split('.')[0]
                          : row.id.split('.')[0])
                      }
                      onMouseEnter={e =>
                        hoverAction('enter', 'td', indexRow, e, parentJurIndex)
                      }
                      onMouseLeave={e =>
                        hoverAction('leave', 'td', indexRow, e, parentJurIndex)
                      }
                      id={'tr' + indexRow}
                      key={indexRow + '-' + row.original.id}
                      style={
                        !showAllSubjurs &&
                        row.original.motherEntry?.subjurisdiction?.id &&
                        showSubjurs.indexOf(
                          row.original?.motherEntry?.jurisdiction?.id
                        ) === -1
                          ? { display: 'none', opacity: '0.3' }
                          : showUNMembers &&
                            !row.original?.motherEntry?.jurisdiction?.un_member
                          ? { display: 'none', opacity: '0.3' }
                          : !showAllSubjurs
                          ? { paddingLeft: '32px' }
                          : {}
                      }
                    >
                      {row.cells.map((cell, index) => {
                        if (
                          index === 0 &&
                          expandable &&
                          row.original.cid &&
                          row.original.cid.indexOf('.') !== -1
                        )
                          return null
                        else
                          return (
                            <td
                              rowSpan={
                                index === 0 &&
                                expandable &&
                                (!row.original.cid ||
                                  row.original.cid.indexOf('.') === -1)
                                  ? row.original.subRowsLength + 1
                                  : 1
                              }
                              {...cell.getCellProps({
                                className:
                                  (index === 0 && !disableFirstCol && !scrolledX
                                    ? 'first' //+" first-" + (indexRow % 2) Disabled stripped table for now
                                    : index === 0 &&
                                      !disableFirstCol &&
                                      scrolledX
                                    ? 'firstScrolled'
                                    : '') +
                                  ' ' +
                                  (cell.column.className
                                    ? cell.column.className
                                    : '') +
                                  ' ' +
                                  (cell.column.classNameFn
                                    ? cell.column.classNameFn(cell)
                                    : '')
                              })}
                              data-column={index}
                              key={index}
                              style={
                                !showAllSubjurs &&
                                index === 0 &&
                                row.original.motherEntry?.subjurisdiction?.id
                                  ? { paddingLeft: '32px' }
                                  : {}
                              }
                            >
                              {index === 0 && (
                                <>
                                  {!noDeleteRow && (
                                    <button
                                      id={'deleteRow' + unique + indexRow}
                                      className="deleteRow"
                                      onClick={e => handleClickDeleteRow(row)}
                                    >
                                      x
                                    </button>
                                  )}{' '}
                                  <div
                                    id={'rowActions' + unique + indexRow}
                                    className="rowActions"
                                  >
                                    {(!emptyFieldsNoCite ||
                                      emptyFieldsNoCite.some(
                                        p =>
                                          row.original[p] &&
                                          row.original[p] !== '-'
                                      )) &&
                                      citeEntry && (
                                        <span
                                          onClick={e => {
                                            citeEntry(row.original, setToast)
                                          }}
                                          className={'citeIcon'}
                                        >
                                          <CiteIcon
                                            size={20}
                                            title={t('Cite Entry')}
                                          />
                                        </span>
                                      )}
                                    {!noReportError && (
                                      <span
                                        onClick={e =>
                                          setReportErrorOpen(row.original)
                                        }
                                        className={'reportIcon'}
                                      >
                                        <ReportIcon
                                          size={20}
                                          title={t(
                                            'Report error or send comments'
                                          )}
                                        />
                                      </span>
                                    )}
                                  </div>
                                  {row.original.n_subjurs > 0 &&
                                    (row.original?.motherEntry?.jurisdiction
                                      ?.id !== 'UN-185' ||
                                      (row.original?.motherEntry
                                        ?.entry_type_id !== 'A1-1' &&
                                        row.original?.motherEntry
                                          ?.entry_type_id !== 'A1-6' &&
                                        row.original?.motherEntry
                                          ?.entry_type_id !== 'A1-7' &&
                                        row.original?.motherEntry
                                          ?.entry_type_id !== 'A1-8' &&
                                        row.original?.motherEntry
                                          ?.entry_type_id !== 'A1-10' &&
                                        row.original?.motherEntry
                                          ?.entry_type_id !== 'A1-11' &&
                                        row.original?.motherEntry
                                          ?.entry_type_id !== 'A1-12' &&
                                        row.original?.motherEntry
                                          ?.entry_type_id !== 'A1-15' &&
                                        row.original?.motherEntry
                                          ?.entry_type_id !== 'A1-16' &&
                                        row.original?.motherEntry
                                          ?.entry_type_id !== 'A1-18')) && (
                                      <div
                                        className="showSubJurs"
                                        onClick={e => {
                                          let jurIndex = showSubjurs.indexOf(
                                            row.original?.motherEntry
                                              ?.jurisdiction?.id
                                          )
                                          setShowSubjurs(
                                            jurIndex === -1
                                              ? [
                                                  ...showSubjurs,
                                                  row.original?.motherEntry
                                                    ?.jurisdiction?.id
                                                ]
                                              : [
                                                  ...showSubjurs.slice(
                                                    0,
                                                    jurIndex
                                                  ),
                                                  ...showSubjurs.slice(
                                                    jurIndex + 1
                                                  )
                                                ]
                                          )
                                        }}
                                      >
                                        {showSubjurs.indexOf(
                                          row.original?.motherEntry
                                            ?.jurisdiction?.id
                                        ) === -1 ? (
                                          <>
                                            <SubjurIcon
                                              size={18}
                                              color={'#99a7ab'}
                                            />{' '}
                                            {t('Display SUBJUR')}
                                          </>
                                        ) : (
                                          <>
                                            <SubjurIcon
                                              size={18}
                                              color={'#99a7ab'}
                                            />{' '}
                                            {t('Hide SUBJURS')}
                                          </>
                                        )}
                                      </div>
                                    )}
                                </>
                              )}
                              {index === 0 &&
                              expandable &&
                              row.original.cid &&
                              row.original.cid.indexOf('.') !== -1
                                ? ''
                                : index === 0 && scrolledX
                                ? cell.render('CellSmall')
                                : cell.render('Cell')}
                            </td>
                          )
                      })}
                    </tr>
                  )
                })}
                {rows.length < 3 &&
                  [].fill(0, 0, 3 - rows.length).map(r => (
                    <tr>
                      <td style={{ backgroundColor: '#d8e3e5' }} />
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="tableAddColumnsHidden" ref={columnListRef}>
          <p className="ac_text">
            <span>{t('Add')}</span>
            <br />
            <span>{t('Columns')}</span>
          </p>
          {allColumns
            .filter(c => !c.isVisible)
            .map((col, index, allColumns) => (
              <button
                key={index}
                onClick={e => {
                  if (allColumns.length === 1) {
                    columnListRef.current.classList.remove('tableAddColumns')
                    columnListRef.current.classList.add('tableAddColumnsHidden')
                    refTable.current.classList.add('tableArea1-1-big')
                  }
                  col.toggleHidden()
                }}
                className="addColumn"
              >
                {col.Header}
              </button>
            ))}
          <Button
            onClick={handleResetClick}
            className="tableReset__btn"
            variant="default"
          >
            {t('Reset')}
          </Button>
        </div>
      </div>
      <div className="tableFooter">
        {showFooter && (
          <div className={fullScreen ? 'fullWidthSwitches' : ''}>
            {!firstLoad && (
              <div className="tableTallies">
                <span style={{ verticalAlign: 'super' }}>
                  <span className="tableTallies__pageNum">
                    {
                      rows.filter(
                        r =>
                          (!r.original.cid ||
                            r.original.cid.indexOf('.') === -1) &&
                          (showAllSubjurs ||
                            !r.original.motherEntry?.subjurisdiction?.id) &&
                          hiddenRows.indexOf(r.id) === -1 &&
                          (!showUNMembers ||
                            r.original?.motherEntry?.jurisdiction?.un_member)
                      ).length
                    }
                  </span>{' '}
                  {t('results')}
                </span>{' '}
                <Tippy
                  open={true}
                  className="helpTippy"
                  content={t(
                    'This is the number of entries currently on display on the table above. Apply filters to get personalised tallies'
                  )}
                  distance={'0'}
                  allowHTML={true}
                  followCursor={'initial'}
                  maxWidth={i18n.language === 'en' ? '340px' : '300px'}
                  theme={'ilga-help'}
                  placement={'top'}
                  popperOptions={{
                    modifiers: [{ name: 'flip', enabled: false }]
                  }}
                >
                  <a
                    style={{
                      verticalAlign: 'super',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      position: 'relative',
                      top: '-1px'
                    }}
                  >
                    <IconInfo size={22} color="#5c8f95" />
                  </a>
                </Tippy>
              </div>
            )}
            {customColumnHide && (
              <div className="customColumnHideSwitch">
                <label>
                  <span style={{ verticalAlign: 'super' }}>
                    {customColumnHide.text}
                  </span>{' '}
                  <ReactSwitch
                    onChange={checked => setCustomHidden(checked)}
                    checked={customHidden}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={16}
                    width={32}
                    onColor={'#5c8f95'}
                    offColor={'#4e4e4e'}
                  />
                </label>
              </div>
            )}

            {showUNSwitch && (
              <div className="customColumnHideSwitch">
                <label>
                  <span style={{ verticalAlign: 'super' }}>
                    {t('UN Members Only')}
                  </span>{' '}
                  <ReactSwitch
                    onChange={checked => {
                      if (checked) {
                        setShowUNMembers(true)
                      } else {
                        setShowUNMembers(false)
                      }
                    }}
                    checked={showUNMembers}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={16}
                    width={32}
                    onColor={'#5c8f95'}
                    offColor={'#4e4e4e'}
                  />
                </label>
              </div>
            )}
            {showSubjurSwitch && (
              <div className="customColumnHideSwitch">
                <label>
                  <span style={{ verticalAlign: 'super' }}>
                    {t('Display SUBJUR as independent entries')}
                  </span>{' '}
                  <Tippy
                    className="helpTippy"
                    content={t(
                      'When enabled, this feature allows users to display subnational jurisdictions (SUBJUR) at the same level as national states. This may be of use to sort or filter progress made at the subnational level on an equal footing with that made at the national or federal level in other States.'
                    )}
                    distance={'0'}
                    allowHTML={true}
                    followCursor={'initial'}
                    theme={'ilga-help'}
                    placement={'top'}
                    popperOptions={{
                      modifiers: [{ name: 'flip', enabled: false }]
                    }}
                  >
                    <a
                      style={{
                        verticalAlign: 'super',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        position: 'relative',
                        top: '-1px'
                      }}
                    >
                      <IconInfo size={22} color="#5c8f95" />
                    </a>
                  </Tippy>{' '}
                  <ReactSwitch
                    onChange={checked => {
                      if (checked) {
                        if (setInitialSortBy) setInitialSortBy(sortBy)
                        setShowAllSubjurs(true)
                      } else {
                        if (setInitialSortBy) setInitialSortBy(sortBy)
                        setShowAllSubjurs(false)
                      }
                    }}
                    checked={showAllSubjurs}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={16}
                    width={32}
                    onColor={'#5c8f95'}
                    offColor={'#4e4e4e'}
                  />
                </label>
              </div>
            )}
          </div>
        )}
        {paginate && (
          <div className="tablePagination">
            <button
              onClick={() => {
                previousPage()
                refTable.current.scrollTo(0, 0)
              }}
              disabled={!canPreviousPage}
            >
              <ChevronIcon size={21} />
            </button>{' '}
            {numberOfPages.map((a, i) =>
              Number.isInteger(a) ? (
                <button
                  key={'butt' + a}
                  onClick={() => {
                    gotoPage(a - 1)
                    refTable.current.scrollTo(0, 0)
                  }}
                  disabled={pageIndex + 1 === a}
                  className={pageIndex + 1 === a ? 'active' : ''}
                >
                  {a}
                </button>
              ) : (
                <>{a}</>
              )
            )}
            <button
              onClick={() => {
                nextPage()
                refTable.current.scrollTo(0, 0)
              }}
              disabled={!canNextPage}
            >
              <ChevronIcon size={21} />
            </button>{' '}
          </div>
        )}
      </div>
      <ReportErrorModal
        isOpen={reportErrorOpen}
        setIsOpen={setReportErrorOpen}
      />
    </>
  )
}
