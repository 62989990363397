import React, { useEffect, useState } from 'react'
import routes, { getPath } from '../config/routes'
import useGqlJurisdictions from '../gql/useGqlJurisdictions'

export default function Sitemap() {
  const [urls, setUrls] = useState([])

  const { jurisdictions } = useGqlJurisdictions()

  useEffect(() => {
    if (
      jurisdictions &&
      jurisdictions.length
    ) {
      const urlsTmp = routes()
        .filter(r => !r.ignoreSitemap)
        .map(route =>
          route.langPaths
            ? `<url><loc>https://database.ilga.org/${
                route.langPaths.en[0] === '/'
                  ? route.langPaths.en.substring(1)
                  : route.langPaths.en
              }</loc>
          <lastmod>2023-03-23</lastmod></url>
          <url><loc>https://database.ilga.org/${
            route.langPaths.es[0] === '/'
              ? route.langPaths.es.substring(1)
              : route.langPaths.es
          }</loc>
          <lastmod>2023-03-23</lastmod></url>`
            : `<url><loc>https://database.ilga.org/${
                route.path[0] === '/' ? route.path.substring(1) : route.path
              }</loc>
            <lastmod>2023-03-23</lastmod></url>`
        )

      setUrls(
        urlsTmp.concat(
          jurisdictions
            .filter(j => j.a2_code !== 'G0')
            .map(
              j =>
                ` <url><loc>https://database.ilga.org${getPath(
                    'jur_profile',null,'en'
                  ).replace(
                    ':slug',
                    j.slug_en.toLowerCase()
                  )}</loc><lastmod>2023-03-23</lastmod></url><url><loc>https://database.ilga.org${getPath(
                    'jur_profile',null,'es'
                  ).replace(
                    ':slug',
                    j.slug_es.toLowerCase()
                  )}</loc><lastmod>2023-03-23</lastmod></url>`
            )
        )
      )
    }
  }, [jurisdictions])

  let sitemap = `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
   
        ${urls.join('')}
      
    </urlset>`
  return sitemap
}
