import React from 'react'

import './Switch.css'

const Switch = ({
  toggled,
  toggle,
  calledFrom = '',
  innerText = '',
  className = '',
  disabled = false,
  id = ''
}) => {

  return (
    <div
      className={`switch__container ${calledFrom}__switch ${className} ${disabled &&
        'disabled'}`}
    >
      <label className="switch" htmlFor={id}>
        <input
          onChange={() => {
            if (!disabled) toggle(!toggled)
          }}
          type="checkbox"
          disabled={disabled}
          checked={toggled}
          name={id}
          id={id}
        />
        <span className="slider round" />
      </label>
      {innerText}
    </div>
  )
}

export default Switch;