import React, { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'
import {
  cArea118MapReferencesAge,
  cArea118MapReferencesGm,
  cArea118MapReferencesRequirements
} from '../../config/constants'
import { introText, methodologyText } from '../../data/Area13'
import useGqlEntriesLgr from '../../gql/useGqlEntriesLgr'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import MainLayout from '../../layouts/MainLayout'

import '../../pages/Area.css'

import AreaContent from '../components/Areas/AreaContent'
import BarChart from '../components/Areas/A1-4/BarChart'
import LatestUpdates from '../components/Areas/LatestUpdates'
import BookIcon from '../components/icons/BookIcon'
import MapContainer from '../components/Map/MapContainer'
import microstates from '../components/Map/microstates.json'
import Methodology from '../components/Methodology/Methodology'
import MapTooltip from '../components/StyledMapToolTips.js'
import TableContainer from '../components/Table/TableContainer'
import TalliesSection from '../components/TalliesSection/TalliesSection'
import MainSpinner from '../components/ui/MainSpinner'
import { a118_gm_columns, citeA118 } from '../config/a118_gm_columns'
import { a118_nc_columns } from '../config/a118_nc_columns'
import useGqlTextComponents from '../../gql/useGqlTextComponents'
import IntroSection from '../components/Areas/IntroSection'

export default function Area118() {
  const htmlReferences = useRef()
  const [references, setReferences] = useState([])
  const [tableEntries, setTableEntries] = useState([])
  const [mapOption, setMapOption] = useState(0)
  const [talliesNumbers, setTalliesNumbers] = useState([])
  const [tooltipNumbers, setTooltipNumbers] = useState([])
  const [mapLoaded, setMapLoaded] = useState(false)
  const { textComponents } = useGqlTextComponents('A1-18')
  const { entriesLgr, loading, error } = useGqlEntriesLgr()
  const {
    jurisdictions,
    loading: loadingJur,
    error: errorJur,
    jurisdictionsById
  } = useGqlJurisdictions()
  const { t, i18n } = useTranslation()

  const tooltip = e => {
    return ReactDOMServer.renderToStaticMarkup()
  }

  const getMultiLabel = lgr_entry => {
    let ret = []
    let requirements = [
      {
        name: t('Surgery'),
        val: 'gm_surgery_gm_value'
      },
      {
        name: t('Sterilisation'),
        val: 'gm_forced_sterilisation_gm_value'
      },
      {
        name: t('HRT'),
        val: 'gm_hormone_treatment_gm_value'
      },
      {
        name: t('Diagnosis'),
        val: 'gm_diagnosis_gm_value'
      },
      {
        name: t('Real life test'),
        val: 'gm_real_life_test_gm_value'
      },
      {
        name: t('No Children'),
        val: 'gm_no_children_gm_value'
      },
      {
        name: t('Divorce'),
        val: 'gm_forced_divorce_gm_value'
      },
      {
        name: t('Witnesses'),
        val: 'gm_witnesses_gm_value'
      },
      {
        name: t('Expert Wit.'),
        val: 'gm_expert_testimony_gm_value'
      }
    ]

    let required = null
    //Requirements
    for (let req of requirements) {
      if (lgr_entry[req.val] && lgr_entry[req.val].id == 1) {
        if (!required)
          required = {
            label: t('Requirements'),
            color: '#F5934B',
            values: []
          }
        required.values.push(req.name)
      }
    }

    let varies = null
    for (let req of requirements) {
      if (lgr_entry[req.val] && lgr_entry[req.val].id == 5) {
        if (!varies)
          varies = {
            label: t('Varies'),
            color: '#EFD79C',
            values: []
          }
        varies.values.push(req.name)
      }
    }

    let unclear = null
    for (let req of requirements) {
      if (lgr_entry[req.val] && lgr_entry[req.val].id == 3) {
        if (!unclear)
          unclear = {
            label: t('Unclear'),
            color: '#BFBFBF',
            values: []
          }
        unclear.values.push(req.name)
      }
    }

    if (required) {
      ret.push(required)
    }

    if (varies) {
      ret.push(varies)
    }

    if (unclear) {
      ret.push(unclear)
    }
    // if  (lgr_entry.motherEntry.jurisdiction.a2_code=== 'FR')
    console.log('RET', lgr_entry.motherEntry.jurisdiction.a2_code, ret)

    return ret
  }

  const getReference = (lgr_entry, mapOption) => {
    if (mapOption == 0) {
      if (
        lgr_entry.gender_marker_lgr_type &&
        lgr_entry.gender_marker_lgr_type.id == 6
      )
        return 88
      else if (
        lgr_entry.gender_marker_lgr_type &&
        (lgr_entry.gender_marker_lgr_type.id == 2 ||
          lgr_entry.gender_marker_lgr_type.id == 8)
      )
        return 8
      else if (
        lgr_entry.gender_marker_lgr_type &&
        lgr_entry.gender_marker_lgr_type.id == 4
      )
        return 3
      else if (
        lgr_entry.gender_marker_lgr_type &&
        lgr_entry.gender_marker_lgr_type.id == 7
      )
        return 2
      else if (
        lgr_entry.gender_marker_lgr_type &&
        (lgr_entry.gender_marker_lgr_type.id == 1 ||
          lgr_entry.gender_marker_lgr_type.id == 3)
      ) {
        if (lgr_entry.gm_selfid_value && lgr_entry.gm_selfid_value.id == 5)
          return 2
        if (lgr_entry.gm_selfid_value && lgr_entry.gm_selfid_value.id == 1)
          return 1
        if (lgr_entry.gm_selfid_value && lgr_entry.gm_selfid_value.id == 7)
          return 111
        if (lgr_entry.gm_selfid_value && lgr_entry.gm_selfid_value.id == 3)
          return 3        
        else if (
          (lgr_entry.gm_surgery_gm_value &&
            lgr_entry.gm_surgery_gm_value.id == 1) ||
          (lgr_entry.gm_forced_sterilisation_gm_value &&
            lgr_entry.gm_forced_sterilisation_gm_value.id == 1)
        )
          return 7
        else if (
          (lgr_entry.gm_hormone_treatment_gm_value &&
            lgr_entry.gm_hormone_treatment_gm_value.id == 1) ||
          (lgr_entry.gm_expert_testimony_gm_value &&
            lgr_entry.gm_expert_testimony_gm_value.id == 1) ||
          (lgr_entry.gm_diagnosis_gm_value &&
            lgr_entry.gm_diagnosis_gm_value.id == 1)
        )
          return 6
        else if (
          (lgr_entry.gm_real_life_test_gm_value &&
            lgr_entry.gm_real_life_test_gm_value.id == 1) ||
          (lgr_entry.gm_no_children_gm_value &&
            lgr_entry.gm_no_children_gm_value.id == 1) ||
          (lgr_entry.gm_forced_divorce_gm_value &&
            lgr_entry.gm_forced_divorce_gm_value.id == 1) ||
          (lgr_entry.gm_witnesses_gm_value &&
            lgr_entry.gm_witnesses_gm_value.id == 1)
        )
          return 4
        else if (
          (lgr_entry.gm_diagnosis_gm_value &&
            lgr_entry.gm_diagnosis_gm_value.id == 3) ||
          (lgr_entry.gm_hormone_treatment_gm_value &&
            lgr_entry.gm_hormone_treatment_gm_value.id == 3) ||
          (lgr_entry.gm_surgery_gm_value &&
            lgr_entry.gm_surgery_gm_value.id == 3) ||
          (lgr_entry.gm_forced_sterilisation_gm_value &&
            lgr_entry.gm_forced_sterilisation_gm_value.id == 3) ||
          (lgr_entry.gm_real_life_test_gm_value &&
            lgr_entry.gm_real_life_test_gm_value.id == 3) ||
          (lgr_entry.gm_expert_testimony_gm_value &&
            lgr_entry.gm_expert_testimony_gm_value.id == 3) ||
          (lgr_entry.gm_no_children_gm_value &&
            lgr_entry.gm_no_children_gm_value.id == 3) ||
          (lgr_entry.gm_forced_divorce_gm_value &&
            lgr_entry.gm_forced_divorce_gm_value.id == 3) ||
          (lgr_entry.gm_witnesses_gm_value &&
            lgr_entry.gm_witnesses_gm_value.id == 3)
        )
          return 3
      }
      //
      console.error('FAILED', lgr_entry)
      return 99
    }

    if (mapOption == 1) {
      if (
        lgr_entry.gender_marker_lgr_type &&
        lgr_entry.gender_marker_lgr_type.id == 2
      )
        return 5
      else if (
        lgr_entry.gender_marker_lgr_type &&
        lgr_entry.gender_marker_lgr_type.id == 6
      )
        return 88
      else if (
        lgr_entry.gender_marker_lgr_type &&
        lgr_entry.gender_marker_lgr_type.id == 3
      )
        return 4
      else if (!lgr_entry.gm_for_children_gm_avail_value) return 99
      else if (
        lgr_entry.gm_for_children_gm_avail_value &&
        lgr_entry.gm_for_children_gm_avail_value.id == 7
      )
        return 88
      else if (
        lgr_entry.gm_for_children_gm_avail_value &&
        lgr_entry.gm_for_children_gm_avail_value.id == 5
      )
        return 2
      else if (
        lgr_entry.gm_for_children_gm_avail_value &&
        lgr_entry.gm_for_children_gm_avail_value.id == 3
      )
        return 4
      else if (
        lgr_entry.gm_for_children_gm_avail_value &&
        lgr_entry.gm_for_children_gm_avail_value.id == 2
      )
        return 3
      else if (
        lgr_entry.gm_for_children_gm_avail_value &&
        lgr_entry.gm_for_children_gm_avail_value.id == 1
      )
        return 1
    }
    if (mapOption == 2) {
      if (!lgr_entry.gm_non_binary_gm_avail_value) return 99
      if (
        lgr_entry.gm_non_binary_gm_avail_value &&
        lgr_entry.gm_non_binary_gm_avail_value.id == 2
      )
        return 4
      else if (
        lgr_entry.gm_non_binary_gm_avail_value &&
        lgr_entry.gm_non_binary_gm_avail_value.id == 7
      )
        return 4
      else if (
        lgr_entry.gm_non_binary_gm_avail_value &&
        lgr_entry.gm_non_binary_gm_avail_value.id == 5
      )
        return 2
      else if (
        lgr_entry.gm_non_binary_gm_avail_value &&
        lgr_entry.gm_non_binary_gm_avail_value.id == 3
      )
        return 3
      else if (
        lgr_entry.gm_non_binary_gm_avail_value &&
        lgr_entry.gm_non_binary_gm_avail_value.id == 4
      )
        return 3
      else if (
        lgr_entry.gm_non_binary_gm_avail_value &&
        lgr_entry.gm_non_binary_gm_avail_value.id == 6
      )
        return 1
      else if (
        lgr_entry.gm_non_binary_gm_avail_value &&
        lgr_entry.gm_non_binary_gm_avail_value.id == 1
      )
        return 1
    }

    return 1
  }

  useEffect(() => {
    if (!loading && !error && jurisdictions && entriesLgr) {
      let referencesTmp = []

      for (let j of jurisdictions) {
        let entry = entriesLgr.find(e => e.motherEntry.jurisdiction.id === j.id)

        if (entry) referencesTmp.push(entry)
      }
      referencesTmp = entriesLgr

      setReferences(
        referencesTmp.map(e => {
          return {
            id:
              e.motherEntry.subjurisdiction && e.motherEntry.subjurisdiction.id
                ? e.motherEntry.subjurisdiction.id
                : e.motherEntry.jurisdiction.a2_code,
            regions: e.motherEntry.jurisdiction.regions,
            microstate: microstates.find(
              ms => ms.A2 === e.motherEntry.jurisdiction.a2_code
            ),
            reference: (mapOption === 0
              ? cArea118MapReferencesRequirements()
              : mapOption === 1
              ? cArea118MapReferencesAge()
              : cArea118MapReferencesGm()
            ).find(ml => ml.id === getReference(e, mapOption)).color,
            reference_text: 'test',
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <MapTooltip
                entry={e}
                title={
                  mapOption === 0
                    ? t('Legal Gender Recognition')
                    : mapOption === 1
                    ? t('Age Restrictions')
                    : t('Non-Binary Gender Marker')
                }
                area={'A1-18'}
                extraProps={{
                  mapOption,
                  bgColor: (mapOption === 0
                    ? cArea118MapReferencesRequirements()
                    : mapOption === 1
                    ? cArea118MapReferencesAge()
                    : cArea118MapReferencesGm()
                  ).find(ml => ml.id === getReference(e, mapOption)).color,
                  label: (mapOption === 0
                    ? cArea118MapReferencesRequirements()
                    : mapOption === 1
                    ? cArea118MapReferencesAge()
                    : cArea118MapReferencesGm()
                  ).find(ml => ml.id === getReference(e, mapOption)).name,
                  multiLabel:
                    mapOption === 0 &&
                    getReference(e, mapOption) >= 4 &&
                    getReference(e, mapOption) < 8 &&
                    getMultiLabel(e)
                }}
                positive={e.name_change_lgr_type.id === '2'}
                hasSubjur={entriesLgr.find(
                  e2 =>
                    e2.motherEntry.jurisdiction.id ===
                      e.motherEntry.jurisdiction.id &&
                    e2.motherEntry.subjurisdiction
                )}
              />
            ), //tooltip(e),
            entry: e
          }
        })
      )

      let tmpTableEntries = []
      let tmpTallyNumbers = [0, 0, 0, 0]
      let tmpTooltipNumbers = [[0, 0, 0], [0, 0, 0]]

      for (let entry of entriesLgr) {
        let is_na = entry.name_change_lgr_type.id === '7'

        if (
          !entry.motherEntry.subjurisdiction &&
          entry.motherEntry.jurisdiction.un_member
        ) {
          if (
            entry.gm_selfid_value &&
            (entry.gm_selfid_value.id === '1' ||
              entry.gm_selfid_value.id === '5' ||
              entry.gm_selfid_value.id === '7')
          ) {
            tmpTallyNumbers[0]++
            if (entry.gm_selfid_value.id === '1') tmpTooltipNumbers[0][0]++
            if (entry.gm_selfid_value.id === '7') tmpTooltipNumbers[0][1]++
            if (entry.gm_selfid_value.id === '5') tmpTooltipNumbers[0][2]++
          }

          if (
            entry.gm_non_binary_gm_avail_value &&
            (entry.gm_non_binary_gm_avail_value.id == 1 ||
              entry.gm_non_binary_gm_avail_value.id == 5 ||
              entry.gm_non_binary_gm_avail_value.id == 6)
          ) {
            tmpTallyNumbers[1]++
            if (entry.gm_non_binary_gm_avail_value.id == 1)
              tmpTooltipNumbers[1][0]++
            if (entry.gm_non_binary_gm_avail_value.id == 6)
              tmpTooltipNumbers[1][1]++
            if (entry.gm_non_binary_gm_avail_value.id == 5)
              tmpTooltipNumbers[1][2]++
          }

          if (
            (entry.gm_surgery_gm_value && entry.gm_surgery_gm_value.id == 1) ||
            (entry.gm_forced_sterilisation_gm_value &&
              entry.gm_forced_sterilisation_gm_value.id == 1)
          ) {
            tmpTallyNumbers[2]++
          }

          if (
            entry.gm_diagnosis_gm_value &&
            entry.gm_diagnosis_gm_value.id == 1
          ) {
            tmpTallyNumbers[3]++
          }
        }
        let subRows = []

        if (!is_na || !subRows.length) {
          tmpTableEntries.push({
            ...entry,
            subRowsLength: subRows.length
          })
          tmpTableEntries = tmpTableEntries.concat(subRows)
        } else {
          //Hack to prevent showing the N/A
          if (subRows.length) {
            subRows[0] = {
              ...subRows[0],
              cid: subRows[0].cid.split('.')[0],
              subRowsLength: subRows.length - 1
            }
            delete subRows[0].cid

            tmpTableEntries = tmpTableEntries.concat(subRows)
          }
        }
      }

      tmpTooltipNumbers[0][0] = t("Nationwide Self-ID") + ': ' + tmpTooltipNumbers[0][0]
      tmpTooltipNumbers[0][1] = t("Self-ID for NB markers only") + ': ' + tmpTooltipNumbers[0][1]
      tmpTooltipNumbers[0][2] = t("Self-ID available at the subnational level only") + ': ' + tmpTooltipNumbers[0][2]

      tmpTooltipNumbers[1][0] = t("Non-binary gender marker available nationwide") + ': ' + tmpTooltipNumbers[1][0]
      tmpTooltipNumbers[1][1] = t("Non-binary gender marker for intersex only") + ': ' + tmpTooltipNumbers[1][1]
      tmpTooltipNumbers[1][2] = t("Non-binary gender marker available at the subnational level only") + ': ' + tmpTooltipNumbers[1][2]

      setTableEntries(tmpTableEntries)
      setTalliesNumbers(tmpTallyNumbers)
      setTooltipNumbers(tmpTooltipNumbers)
    }
  }, [jurisdictions, entriesLgr, error, loading, mapOption])

  const areaInfo = {
    title: `${t('Area')} 1`,
    subTitle: t('Legal Frameworks'),
    subSubTitle: `${t('Legal Gender Recognition')}`,
    areaName: 'area-1'
  }

  return (
    <MainLayout section="areas">
      <div className="area__page area1__page area__A1-18">
        <section className="selectRegion__section">
          <MapContainer
            references={references}
            htmlReferences={htmlReferences}
            jurisdictionsById={jurisdictionsById}
            jurisdictions={jurisdictions}
            mapLegendItems={
              mapOption === 0
                ? cArea118MapReferencesRequirements()
                : mapOption === 1
                ? cArea118MapReferencesAge()
                : cArea118MapReferencesGm()
            }
            extraProps={{ mapOption, setMapOption }}
            menuVariation="miniMapActionsLgr"
            mapLoaded={mapLoaded}
            setMapLoaded={setMapLoaded}
            currentArea={'A1-18'}
            title={areaInfo.title}
            subTitle={areaInfo.subTitle}
            subSubTitle={areaInfo.subSubTitle}
            areaName={areaInfo.areaName}
            modalTitle={areaInfo.subSubTitle}
            modalContent={textComponents.length && textComponents[0].map}
          />
        </section>
        <div className="content__container">
          <TalliesSection
            area={'A1-18'}
            canStart={mapLoaded}
            replaceNumbers={talliesNumbers}
            tooltipNumbers={tooltipNumbers}
          />

          <AreaContent areaIntro={'A1-18'} areaLatest={'A1-18'} />

          {false && (
            <div className="mainCTA__charts">
              <section className="mainCTA__section">
                <div className="mainGraphic__section">
                  <h5>
                    {t('Legal barriers to freedom of association by region')}
                  </h5>
                  {false && references && references.length > 0 ? (
                    <BarChart references={references} />
                  ) : (
                    <>
                      <MainSpinner />
                      {t('Loading chart...')}
                    </>
                  )}
                </div>
              </section>
            </div>
          )}
        </div>
        <section className="table__section">
          <div className="content__container">
            <div className="areaContent__header">
              <div className="ach__iconContainer">
                <BookIcon color={'#D8E3E5'} />
              </div>
              <div className="ach__titlesContainer">
                <h2
                  className="areaContent__title"
                  dangerouslySetInnerHTML={{ __html: t('Area') + '1' }}
                />
                <h4
                  className="areaContent__subTitle"
                  dangerouslySetInnerHTML={{
                    __html:
                      t('Legal Frameworks') +
                      ' | ' +
                      t('Legal Gender Recognition')
                  }}
                />
              </div>
            </div>
            {entriesLgr && entriesLgr.length > 0 && (
              <TableContainer
                entries={tableEntries}
                hideIconHeader={true}
                setEntries={setTableEntries}
                citeEntry={citeA118}
                t={t}
                columns={a118_nc_columns()}
                trMainClass={'a13Tr'}
                title={t('18 A. Name Change')}
                subtitle={
                  t('Legal Frameworks') + ' | ' + t('Legal Gender Recognition')
                }
                paginate={false}
                initialSortBy={[
                  {
                    id: 'motherEntry.jurisdiction.name',
                    desc: false
                  }
                ]}
                showUNSwitch
              />
            )}
          </div>

          <div className="content__container" style={{ marginTop: '80px' }}>
            {entriesLgr && entriesLgr.length > 0 && (
              <TableContainer
                entries={tableEntries}
                setEntries={setTableEntries}
                t={t}
                hideIconHeader={true}
                citeEntry={citeA118}
                unique={'2_'}
                customColumnShown={false}
                customColumnHide={{
                  columns: [
                    'gm_no_children_gm_value.name',
                    'gm_forced_divorce_gm_value.name',
                    'gm_witnesses_gm_value.name',
                    'gm_expert_testimony_gm_value.name',
                    'gm_real_life_test_gm_value.name',
                    'gm_forced_sterilisation_gm_value.name',
                    'gm_surgery_gm_value.name',
                    'gm_diagnosis_gm_value.name',
                    'gm_hormone_treatment_gm_value.name'
                  ],
                  text: t('Display requirements')
                }}
                columns={a118_gm_columns()}
                trMainClass={'a13Tr'}
                title={t('18 B. Gender Marker Change')}
                subtitle={
                  t('Legal Frameworks') +
                  ' | ' +
                  '<span style="font-weight:700">' +
                  t('Legal Gender Recognition') +
                  '</span>'
                }
                paginate={false}
                largeHeader={true}
                initialSortBy={[
                  {
                    id: 'motherEntry.jurisdiction.name',
                    desc: false
                  }
                ]}
                showUNSwitch
              />
            )}
          </div>
        </section>
      </div>
    </MainLayout>
  )
}
