import { gql, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const GET_A5_UPR = gql(`
  query GetEntriesRecommendations($lang: String, $jur_id: String) {
    entriesRecommendations(lang: $lang, jur_id: $jur_id) {
    id
    motherEntry {
        topics {
          id
          name
        }
        jurisdiction {
          id
          name
          un_member
          a2_code
          regions{
            id
          }
        }
        subjurisdiction {
          id
          name
        }
        sources {
          id
          order
          section
          original_official_filename
          original_filename
          translated_filename
          enacted
          original_official_language{
            name
            id
          }
          original_official_language2{
            name
            id
          }
          original_language{
            name
            id
          }
          original_language2{
            name
            id
          }
        }
      }
    issuing_jurisdiction {
      id
      a2_code
      name
      un_member

      map_name
      label_longitude
      label_latitude
      label_font_size
      microstate_size
      microstate_label_longitude
      microstate_label_latitude
      drilldown_label_longitude
      drilldown_label_latitude
      drilldown_label_font_size
    }
    cycle
    session
    date
    text
    reference
    recommendation_response {
      id
      name
    }
    additional_info_en
    source_ai_url
    recommendation_upr_scope {
      id
      name
    }
    un_symbol
    source_url
    untv_id
    untv_out
  }
}
`)

const GET_A5_UPR_ISSUED = gql(`
  query GetEntriesRecommendations($lang: String, $jur_id: String) {
    entriesRecommendationsIssued(lang: $lang, jur_id: $jur_id) {
    id
    motherEntry {
        topics {
          id
          name
        }
        jurisdiction {
          id
          name
          un_member
          a2_code
          regions{
            id
          }
        }
        subjurisdiction {
          id
          name
        }
        sources {
          id
          order
          section
          original_official_filename
          original_filename
          translated_filename
          enacted
          original_official_language{
            name
            id
          }
          original_official_language2{
            name
            id
          }
          original_language{
            name
            id
          }
          original_language2{
            name
            id
          }
        }
      }
    issuing_jurisdiction {
      id
      a2_code
      name
      un_member

      map_name
      label_longitude
      label_latitude
      label_font_size
      microstate_size
      microstate_label_longitude
      microstate_label_latitude
      drilldown_label_longitude
      drilldown_label_latitude
      drilldown_label_font_size
    }
    cycle
    session
    date
    text
    reference
    recommendation_response {
      id
      name
    }
    additional_info_en
    source_ai_url
    recommendation_upr_scope {
      id
      name
    }
    un_symbol
    source_url
    untv_id
    untv_out
  }
}
`)

const GET_A5_ISSUED_RECEIVED = gql(`
  query GetEntriesRecommendationsIssuedReceived($lang: String, $jur_id: String) {
    receivedIssuedRecommendations(lang: $lang, jur_id: $jur_id) {
        id
        a2_code
      received {
        a2_code
        id
        name
      }
      issued {
        a2_code
        id
        name
      }
      name
  }
}
`)

const GET_A5_CYCLE = gql(`
  query GetEntriesCycleDocs($lang: String, $jur_id: String) {
    entriesCycleDocs(lang: $lang, jur_id: $jur_id) {
      id
      motherEntry {
        topics {
          id
          name
        }
        jurisdiction {
          id
          name
          un_member
          a2_code
          regions{
            id
          }
        }
        subjurisdiction {
          id
          name
        }
        sources {
          id
          order
          section
          original_official_filename
          original_filename
          translated_filename
          enacted
          original_official_language{
            name
            id
          }
          original_official_language2{
            name
            id
          }
          original_language{
            name
            id
          }
          original_language2{
            name
            id
          }
        }
      }      
      cycle
      session
      para
      date
      explan
      scope {
        id
        name
      }
      cycle_cu_type {
        id
        name
      }
      un_symbol
      source_url
  }
}
`)

function useGqlEntriesRecommendations(jur_id) {
  const { i18n } = useTranslation()
  const entriesRecommendations = useQuery(GET_A5_UPR, {
    variables: { jur_id, lang: i18n.language }
  })
  const entriesRecommendationsIssued = useQuery(GET_A5_UPR_ISSUED, {
    variables: { jur_id, lang: i18n.language }
  })
  const receivedIssuedRecommendations = useQuery(GET_A5_ISSUED_RECEIVED, {
    variables: { jur_id, lang: i18n.language }
  })

  const entriesCycleDocs = useQuery(GET_A5_CYCLE, {
    variables: { jur_id, lang: i18n.language }
  })


  return {
    entriesRecommendations:
      entriesRecommendations.data &&
      entriesRecommendations.data.entriesRecommendations,
    error: entriesRecommendations.error,
    entriesRecommendationsIssued:
      entriesRecommendationsIssued.data &&
      entriesRecommendationsIssued.data.entriesRecommendationsIssued,
    receivedIssuedRecommendations:
      receivedIssuedRecommendations.data &&
      receivedIssuedRecommendations.data.receivedIssuedRecommendations,
    entriesCycleDocs:
      entriesCycleDocs.data && entriesCycleDocs.data.entriesCycleDocs
  }
}

export default useGqlEntriesRecommendations
