import React, { useContext, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { getPath } from '../../config/routes'
import { AccountContext } from '../../context/Accounts'
import UserCircle from '../components/icons/userCircle'
import LogoComponent from '../components/LogoComponent'
import LoginModal from './LoginModal'
import RegisterModal from './RegisterModal'

const LoginDropdown = ({
  verificationCode = false,
  setVerificationCode,
  initialLogin = false,
  initialRegister = false,
  isBeta = false,
  resetPasswordCode = false,
  setResetPasswordCode
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const [showRegister, setShowRegister] = useState(false)
  const { loggedIn, logout, currentUser } = useContext(AccountContext)
  const { t, i18n } = useTranslation()

  const handleLoginClose = () => setShowLogin('')
  const handleLoginShow = () => setShowLogin('login')

  const hideDropdown = () => {
    setShowDropdown(false)
  }

  return (
    <>
      {' '}
      <LoginModal
        variant="login"
        showLogin={showLogin || verificationCode}
        setShowLogin={setShowLogin}
        setShowRegister={setShowRegister}
        handleLoginClose={handleLoginClose}
        verificationCode={verificationCode}
        setVerificationCode={setVerificationCode}
        resetPasswordCode={resetPasswordCode}
        setResetPasswordCode={setResetPasswordCode}
      />
      {showRegister && (
        <RegisterModal
          variant="register"
          showRegister={showRegister}
          setShowLogin={setShowLogin}
          setShowRegister={setShowRegister}
          handleLoginClose={handleLoginClose}
        />
      )}
      {!isBeta ? (
        <li
          className="td__container"
          onMouseOver={() => setShowDropdown(true)}
          onMouseLeave={hideDropdown}
        >
          <Button
            variant="link"
            className={loggedIn ? 'userInitials' : 'userIconCircle'}
            onClick={handleLoginShow}
          >
            {loggedIn && currentUser ? (
              <Link to={'/'}>
                <span>{currentUser.firstname.slice(0, 1)}</span>
              </Link>
            ) : (
              <UserCircle size={22} />
            )}
          </Button>
          {showDropdown && loggedIn && (
            <div className={'toolsDropdown-container account ' + i18n.language}>
              <div className="toolsDropdown">
                <Link
                  to={getPath('home')}
                  onClick={() => {
                    logout()
                  }}
                >
                  {t('Logout')}
                </Link>
              </div>
            </div>
          )}
        </li>
      ) : (
        <div className="blockedContent">
          <div className="logo">
            <LogoComponent />
          </div>
          <p>
            {!i18n.language || i18n.language === 'en' ? (
              <div>
                Welcome to the ILGA World Database. If you wish to access the
                beta version of the database, please request your signup via the
                form below.
              </div>
            ) : (
              <div>
                Sea bienvenide a la Base de datos de ILGA Mundo. Para acceder a
                la versión beta de la base de datos, sírvase solicitar su alta
                mediante el formulario provisto debajo.
              </div>
            )}
            <div className="buttons">
              <Button variant="primary" style={{width:"320px"}} onClick={e => setShowLogin('demo')}>
                {t('I have an access password')}
              </Button>
            </div>
            <div className="buttons">

              <Button variant="primary" onClick={e => setShowLogin(true)}>
                {t('Login')}
              </Button>
              <Button variant="primary" onClick={e => setShowRegister(true)}>
                {t('Register')}
              </Button>
              <br />
              <br />
              <br />
              {!i18n.language || i18n.language === 'en' ? (
                <div
                  className="changeLanguage"
                  onClick={e => i18n.changeLanguage('es')}
                >
                  Cambiar a Español
                </div>
              ) : (
                <div
                  className="changeLanguage"
                  onClick={e => i18n.changeLanguage('en')}
                >
                  Switch to English
                </div>
              )}
            </div>
          </p>
        </div>
      )}
    </>
  )
}

export default LoginDropdown
