var map = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[178.8614, 70.8265], [178.8911, 71.2311], [180, 71.5379], [180, 70.993], [178.8614, 70.8265]]],
          [[[60.4505, 69.935], [59.5783, 69.7385], [58.473, 70.2668], [59.0052, 70.4652], [60.4505, 69.935]]],
          [[[169.201, 69.5805], [167.789, 69.8369], [168.358, 70.0157], [169.375, 69.8826], [169.201, 69.5805]]],
          [
            [
              [50.265, 69.1857],
              [49.6262, 68.8596],
              [48.667, 68.7331],
              [48.279, 69.0403],
              [48.6315, 69.436],
              [49.225, 69.5113],
              [50.265, 69.1857]
            ]
          ],

          [[[163.635, 58.6033], [163.7611, 59.015], [164.6156, 58.8856], [163.635, 58.6033]]],
          [
            [
              [21.2358, 55.2641],
              [22.8311, 54.8384],
              [22.7663, 54.3568],
              [19.6045, 54.4591],
              [19.9747, 54.9212],
              [20.5202, 54.9949],
              [20.8998, 55.2867],
              [20.9579, 55.279],
              [21.2358, 55.2641]
            ]
          ],
          [
            [
              [142.761, 54.3939],
              [142.9181, 53.7942],
              [143.2235, 53.2961],
              [143.3234, 52.6136],
              [143.1556, 52.0838],
              [143.4552, 51.4715],
              [143.8161, 50.2825],
              [144.272, 49.3113],
              [143.105, 49.1988],
              [142.5741, 48.0719],
              [142.5568, 47.7381],
              [143.0056, 47.2228],
              [143.2821, 46.5592],
              [142.5781, 46.7008],
              [142.2088, 46.0891],
              [141.8302, 46.4508],
              [142.0388, 47.1402],
              [141.9642, 47.5872],
              [142.1817, 48.0133],
              [141.8661, 48.75],
              [142.1422, 49.5692],
              [142.0712, 50.5149],
              [142.2066, 51.2225],
              [141.7223, 51.7363],
              [141.8235, 53.3394],
              [142.5262, 53.4475],
              [142.761, 54.3939]
            ]
          ],
          [[[148.5995, 45.3176], [147.9137, 44.9904], [147.7694, 45.1906], [148.5995, 45.3176]]],
          [
            [
              [96.5265, 81.0755],
              [97.8316, 80.7983],
              [97.1751, 80.241],
              [94.565, 80.1261],
              [93.8725, 80.0102],
              [91.5239, 80.3584],
              [93.2626, 80.7912],
              [93.0651, 80.9885],
              [95.8008, 81.2804],
              [96.5265, 81.0755]
            ]
          ],
          [[[54.719, 81.1159], [57.694, 80.7923], [55.7124, 80.6372], [54.0665, 80.8136], [54.719, 81.1159]]],
          [[[62.168, 80.8347], [61.0511, 80.4186], [59.3044, 80.5216], [59.5921, 80.8165], [62.168, 80.8347]]],
          [
            [
              [97.6744, 80.1582],
              [100.0613, 79.7772],
              [99.4394, 78.8341],
              [97.5555, 78.8266],
              [94.6524, 79.1275],
              [93.0708, 79.4953],
              [94.9871, 80.0968],
              [97.6744, 80.1582]
            ]
          ],
          [
            [
              [102.8848, 79.254],
              [105.146, 78.8188],
              [105.3125, 78.4999],
              [103.7194, 78.2582],
              [101.2043, 78.192],
              [100.2841, 78.6792],
              [101.5905, 79.3504],
              [102.8848, 79.254]
            ]
          ],
          [
            [
              [130.6871, 42.3027],
              [130.5269, 42.5353],
              [130.5771, 42.8118],
              [131.0684, 42.9024],
              [131.262, 43.4333],
              [131.2553, 44.0716],
              [131.0822, 44.9101],
              [131.9775, 45.2439],
              [132.9359, 45.03],
              [133.1858, 45.4947],
              [133.8614, 46.2475],
              [134.1677, 47.3022],
              [134.7523, 47.7155],
              [134.5636, 48.3219],
              [134.2933, 48.3733],
              [133.4686, 48.0972],
              [133.1441, 48.1057],
              [132.5617, 47.7683],
              [131.0027, 47.6915],
              [130.712, 48.1279],
              [130.8043, 48.3414],
              [130.1962, 48.8916],
              [129.5339, 49.3235],
              [128.5267, 49.5943],
              [127.9998, 49.5685],
              [127.5027, 49.8735],
              [127.5901, 50.2091],
              [126.9247, 51.1002],
              [126.313, 52.3997],
              [125.649, 53.0422],
              [124.8122, 53.1339],
              [123.6079, 53.5465],
              [122.338, 53.485],
              [120.704, 53.1718],
              [120.0945, 52.7872],
              [120.6561, 52.5666],
              [120.7497, 52.0965],
              [120.067, 51.6007],
              [119.1921, 50.3797],
              [119.26, 50.0664],
              [118.4517, 49.8444],
              [117.8733, 49.5135],
              [116.6832, 49.8238],
              [116.1346, 50.0109],
              [115.2743, 49.9489],
              [114.743, 50.2337],
              [114.2969, 50.2743],
              [113.1641, 49.7971],
              [113.0557, 49.6162],
              [111.3366, 49.3559],
              [110.8279, 49.1661],
              [110.1997, 49.1704],
              [109.2365, 49.3348],
              [108.6137, 49.3228],
              [108.034, 49.594],
              [107.9479, 49.9247],
              [107.2332, 49.9893],
              [106.7112, 50.3125],
              [106.218, 50.3045],
              [105.3835, 50.4736],
              [103.6328, 50.1387],
              [102.6833, 50.3871],
              [102.2883, 50.585],
              [102.1116, 51.3534],
              [101.3814, 51.4526],
              [100.4687, 51.7261],
              [98.8485, 52.0701],
              [97.947, 51.3483],
              [97.8254, 50.9852],
              [98.2795, 50.5333],
              [98.1032, 50.0779],
              [97.2088, 49.7308],
              [96.986, 49.8828],
              [95.8518, 50.0129],
              [95.5229, 49.9112],
              [94.6147, 50.0237],
              [94.2511, 50.5564],
              [91.8044, 50.6936],
              [89.644, 49.903],
              [89.6542, 49.7175],
              [88.8317, 49.4485],
              [88.1925, 49.4518],
              [87.8143, 49.1624],
              [87.3229, 49.0858],
              [86.6144, 49.6098],
              [86.1807, 49.4993],
              [85.21, 49.6648],
              [84.9893, 50.0615],
              [84.2578, 50.2883],
              [83.9453, 50.7746],
              [83.3571, 50.9945],
              [82.4938, 50.7275],
              [81.4657, 50.7397],
              [80.8771, 51.2815],
              [79.9862, 50.7746],
              [79.4687, 51.493],
              [78.4757, 52.6385],
              [77.7042, 53.3791],
              [76.5758, 53.9424],
              [76.2664, 54.312],
              [74.4518, 53.6472],
              [73.2857, 53.5984],
              [73.3993, 53.8115],
              [72.9141, 54.1073],
              [71.0933, 54.2123],
              [71.1856, 54.5994],
              [70.7382, 55.3052],
              [70.1824, 55.1625],
              [68.9773, 55.3895],
              [68.2062, 55.1609],
              [68.0739, 54.9596],
              [66.2225, 54.6673],
              [65.4767, 54.6233],
              [65.1576, 54.3643],
              [64.4611, 54.3841],
              [63.0739, 54.1053],
              [61.9287, 53.9464],
              [61.3339, 54.0492],
              [60.9797, 53.6217],
              [61.1994, 53.2872],
              [62.0148, 53.1079],
              [61.974, 52.9437],
              [61.0476, 52.9725],
              [60.8021, 52.7448],
              [60.9375, 52.2805],
              [60.0657, 51.9764],
              [60.4647, 51.6513],
              [61.3632, 51.442],
              [61.5851, 51.2298],
              [61.3894, 50.8609],
              [60.6379, 50.6638],
              [59.9552, 50.7992],
              [59.8122, 50.5819],
              [58.8836, 50.6945],
              [58.3594, 51.0638],
              [57.8387, 51.0917],
              [57.4423, 50.8889],
              [57.0117, 51.0651],
              [56.4915, 51.0196],
              [55.7977, 50.6021],
              [55.5424, 50.6017],
              [54.727, 50.9981],
              [54.4216, 50.7804],
              [54.0416, 51.1153],
              [53.3381, 51.4824],
              [52.571, 51.4817],
              [52.3309, 51.6812],
              [51.6091, 51.4839],
              [50.7941, 51.7292],
              [50.2468, 51.2895],
              [49.498, 51.0835],
              [48.8086, 50.6012],
              [48.8432, 50.0131],
              [48.4344, 49.8284],
              [47.706, 50.378],
              [47.3264, 50.2734],
              [46.8022, 49.367],
              [47.0144, 49.0984],
              [46.7028, 48.8055],
              [46.661, 48.4124],
              [47.0042, 48.2846],
              [47.0934, 47.9477],
              [48.1099, 47.7452],
              [48.9595, 46.7744],
              [48.6102, 46.5663],
              [49.2321, 46.3372],
              [48.1592, 45.7371],
              [47.5244, 45.6017],
              [46.7529, 44.4205],
              [47.2301, 44.1923],
              [47.5089, 43.5096],
              [47.4632, 43.0351],
              [48.5729, 41.8446],
              [47.8609, 41.2129],
              [47.2612, 41.315],
              [46.7494, 41.8126],
              [46.4298, 41.8907],
              [45.6388, 42.205],
              [45.7275, 42.4749],
              [45.1602, 42.6751],
              [44.5059, 42.7488],
              [43.9573, 42.5664],
              [43.7824, 42.747],
              [42.4192, 43.2242],
              [41.5807, 43.2193],
              [40.6481, 43.534],
              [39.9782, 43.42],
              [38.7171, 44.2882],
              [38.1814, 44.4196],
              [37.8516, 44.6988],
              [37.4112, 44.7352],
              [36.9411, 45.2896],
              [37.6474, 45.3771],
              [37.9332, 46.0016],
              [38.1836, 46.0948],
              [37.9679, 46.6182],
              [39.2933, 47.1056],
              [38.2142, 47.0914],
              [38.2875, 47.5592],
              [38.9005, 47.8553],
              [39.7359, 47.8447],
              [39.9609, 48.238],
              [39.6866, 49.0079],
              [40.0701, 49.2003],
              [39.7807, 49.5721],
              [39.1748, 49.8559],
              [38.2586, 50.0524],
              [38.0469, 49.9201],
              [37.4228, 50.4115],
              [36.3059, 50.2805],
              [35.4115, 50.5398],
              [35.3121, 51.0438],
              [34.2809, 51.3117],
              [34.3976, 51.7804],
              [33.7353, 52.3448],
              [32.508, 52.3084],
              [31.7636, 52.1012],
              [31.5771, 52.3122],
              [31.4178, 53.196],
              [32.1422, 53.0913],
              [32.7042, 53.3363],
              [31.792, 54.0558],
              [31.4036, 54.1959],
              [31.1213, 54.6484],
              [30.9069, 55.5699],
              [30.2335, 55.8452],
              [29.4824, 55.6845],
              [29.375, 55.9386],
              [28.7948, 55.9426],
              [28.1481, 56.143],
              [27.8063, 56.867],
              [27.797, 57.3169],
              [27.3517, 57.5282],
              [27.753, 57.8411],
              [27.4272, 58.7331],
              [28.0127, 59.4842],
              [28.014, 59.7248],
              [28.9471, 59.8289],
              [29.0692, 60.1915],
              [28.6506, 60.611],
              [27.7978, 60.5362],
              [29.6902, 61.5461],
              [31.2855, 62.5679],
              [31.5341, 62.8855],
              [31.1807, 63.2082],
              [30.0555, 63.689],
              [30.5038, 64.0205],
              [30.1083, 64.3661],
              [30.0728, 64.7649],
              [29.604, 64.9685],
              [29.9032, 66.0911],
              [29.0869, 66.9709],
              [29.9414, 67.5475],
              [29.3439, 68.062],
              [28.4708, 68.4883],
              [28.9657, 69.0219],
              [30.8696, 69.7834],
              [31.9846, 69.9536],
              [32.9417, 69.7519],
              [32.3775, 69.4791],
              [33.6843, 69.3102],
              [35.858, 69.1917],
              [40.3809, 67.8318],
              [40.9663, 67.7135],
              [41.1887, 66.8262],
              [40.5216, 66.4466],
              [39.2889, 66.1321],
              [38.3975, 66.0644],
              [35.5136, 66.3958],
              [34.8247, 66.6111],
              [32.4636, 66.9163],
              [33.5667, 66.321],
              [34.7865, 65.8645],
              [34.4065, 65.3957],
              [34.8034, 64.986],
              [34.8695, 64.5601],
              [35.6472, 64.3783],
              [36.365, 64.0028],
              [37.4423, 63.8135],
              [38.0708, 64.0259],
              [37.9537, 64.3202],
              [37.1835, 64.4085],
              [36.5283, 64.8473],
              [36.883, 65.1724],
              [38.0096, 64.8788],
              [39.7581, 64.577],
              [40.281, 64.998],
              [39.8167, 65.5979],
              [41.4759, 66.1235],
              [42.2106, 66.5197],
              [44.0971, 66.2351],
              [44.4886, 66.6719],
              [44.292, 67.0996],
              [43.7824, 67.2545],
              [44.2254, 67.9956],
              [44.0483, 68.5489],
              [45.8922, 68.4797],
              [46.6903, 67.8489],
              [45.5287, 67.7577],
              [44.9396, 67.4776],
              [45.9859, 66.853],
              [46.6908, 66.8255],
              [47.7091, 67.045],
              [47.8746, 67.5841],
              [48.8778, 67.7315],
              [50.839, 68.3498],
              [52.5502, 68.5924],
              [53.8019, 68.9959],
              [53.9679, 68.2273],
              [54.5614, 68.273],
              [56.0436, 68.649],
              [57.1267, 68.554],
              [58.9191, 69.0039],
              [59.8975, 68.422],
              [60.9335, 68.9868],
              [60.1704, 69.5909],
              [61.0161, 69.8515],
              [63.3616, 69.6753],
              [68.5041, 68.3485],
              [69.1406, 68.9506],
              [68.5427, 68.9671],
              [68.006, 69.48],
              [67.0645, 69.6937],
              [66.9265, 70.0142],
              [67.2847, 70.7386],
              [66.6664, 70.9006],
              [66.9176, 71.2824],
              [68.2693, 71.6828],
              [69.039, 72.67],
              [69.6449, 72.8975],
              [71.5003, 72.9137],
              [72.8121, 72.6915],
              [72.6243, 72.0794],
              [71.9118, 71.5478],
              [72.7317, 70.823],
              [72.5297, 70.1724],
              [72.5275, 69.0805],
              [73.548, 68.5744],
              [73.1521, 67.8651],
              [72.5945, 67.587],
              [71.5514, 66.7605],
              [70.9393, 66.5481],
              [69.877, 66.8455],
              [69.1943, 66.5787],
              [70.3396, 66.3423],
              [71.9172, 66.2467],
              [72.4174, 66.5607],
              [73.7922, 66.9953],
              [74.0745, 67.4141],
              [74.7696, 67.7663],
              [74.3914, 68.4206],
              [74.5796, 68.7511],
              [76.4591, 68.9784],
              [77.2385, 68.4697],
              [77.6509, 68.9029],
              [76.001, 69.2349],
              [74.8149, 69.0907],
              [73.7757, 69.1983],
              [73.56, 69.7072],
              [74.3111, 70.6536],
              [73.0864, 71.4449],
              [73.6719, 71.845],
              [75.7413, 72.2962],
              [76.0325, 71.9103],
              [78.4828, 72.395],
              [79.422, 72.3808],
              [80.7626, 72.0892],
              [81.511, 71.7463],
              [82.9861, 71.7487],
              [82.1835, 72.2376],
              [80.827, 72.4882],
              [80.5096, 73.0861],
              [80.5833, 73.5684],
              [83.5449, 73.6665],
              [86.5913, 73.8943],
              [86.6513, 74.6824],
              [87.6713, 75.1296],
              [90.1851, 75.591],
              [93.5498, 75.854],
              [94.5756, 76.1517],
              [96.879, 75.9311],
              [100.3223, 76.479],
              [100.9899, 76.9904],
              [103.1312, 77.6265],
              [104.0146, 77.7304],
              [105.8944, 77.4889],
              [104.9121, 77.1748],
              [107.4299, 76.9265],
              [108.0278, 76.7185],
              [111.1151, 76.723],
              [112.6194, 76.3836],
              [113.8712, 75.856],
              [113.6137, 75.293],
              [111.8683, 74.7401],
              [109.8402, 74.3219],
              [108.1996, 73.6941],
              [107.2709, 73.621],
              [106.4782, 73.1394],
              [107.7503, 73.1731],
              [109.8553, 73.4726],
              [110.2614, 74.0174],
              [112.1471, 73.7089],
              [114.0607, 73.5846],
              [115.3378, 73.7025],
              [118.4504, 73.5897],
              [118.4304, 73.2466],
              [119.7505, 72.9792],
              [123.1605, 72.955],
              [123.6222, 73.1933],
              [123.3052, 73.5329],
              [124.3883, 73.7549],
              [125.617, 73.5207],
              [127.7406, 73.4817],
              [128.8885, 73.1902],
              [129.4118, 72.3156],
              [128.3589, 72.0883],
              [129.7621, 71.1195],
              [131.1572, 70.7422],
              [131.562, 70.9011],
              [132.5621, 71.8952],
              [134.1029, 71.3789],
              [135.8847, 71.6306],
              [137.3153, 71.3594],
              [138.1183, 71.5663],
              [139.695, 71.7003],
              [139.6014, 72.496],
              [142.0614, 72.7208],
              [145.4856, 72.5422],
              [146.594, 72.3025],
              [148.402, 72.312],
              [149.5015, 72.1644],
              [150.5997, 71.5201],
              [151.5825, 71.2871],
              [152.5089, 70.8345],
              [153.7944, 70.88],
              [155.8953, 71.0955],
              [158.0371, 71.0394],
              [159.3506, 70.7908],
              [160.0062, 70.3096],
              [159.8326, 69.7849],
              [161.5367, 69.3796],
              [162.3757, 69.6491],
              [164.1597, 69.7192],
              [164.5135, 69.6091],
              [166.8204, 69.4995],
              [167.6283, 69.7403],
              [168.303, 69.2716],
              [169.3106, 69.0796],
              [169.6098, 68.7862],
              [170.5375, 68.8254],
              [170.9952, 69.0454],
              [170.5824, 69.5834],
              [170.4869, 70.1076],
              [172.5595, 69.9682],
              [173.2772, 69.8238],
              [176.1075, 69.8604],
              [176.9243, 69.646],
              [178.8485, 69.3872],
              [180, 68.9835],
              [180, 65.0672],
              [178.5196, 64.6029],
              [177.4671, 64.7368],
              [177.433, 64.4445],
              [178.6501, 63.9653],
              [178.7065, 63.5216],
              [179.3324, 63.1902],
              [179.5703, 62.6875],
              [179.1206, 62.3204],
              [177.3513, 62.5874],
              [175.2681, 62.1025],
              [174.5144, 61.8237],
              [173.6235, 61.7161],
              [172.8564, 61.4693],
              [172.3926, 61.0618],
              [170.6081, 60.435],
              [169.9827, 60.067],
              [169.2267, 60.5959],
              [168.1374, 60.5739],
              [166.9642, 60.3069],
              [166.3521, 60.4847],
              [165.0843, 60.0985],
              [163.7802, 60.041],
              [163.41, 59.8349],
              [163.2728, 59.3026],
              [162.1418, 58.4475],
              [161.9602, 58.0768],
              [162.4671, 57.7661],
              [163.2138, 57.6869],
              [162.7792, 57.3577],
              [162.8027, 56.8115],
              [163.2564, 56.6881],
              [163.3354, 56.2325],
              [162.5284, 56.2606],
              [162.085, 56.0897],
              [161.7294, 55.358],
              [162.1058, 54.7521],
              [161.7258, 54.533],
              [160.7728, 54.5414],
              [160.0746, 54.1892],
              [159.8437, 53.7837],
              [159.8975, 53.3807],
              [158.6088, 52.8735],
              [158.4637, 52.3049],
              [158.1037, 51.8095],
              [156.7476, 50.9692],
              [156.5003, 51.475],
              [156.3645, 52.5093],
              [156.0986, 53.0065],
              [155.6201, 54.8646],
              [155.5549, 55.3484],
              [155.9828, 56.6952],
              [156.7285, 57.1522],
              [157.4503, 57.7994],
              [158.2102, 58.0253],
              [159.0367, 58.4239],
              [159.8473, 59.1271],
              [161.7534, 60.1522],
              [162.0037, 60.4201],
              [162.9732, 60.7828],
              [163.71, 60.9168],
              [164.0052, 61.3439],
              [164.2556, 62.6966],
              [163.3318, 62.5508],
              [163.0091, 61.7915],
              [162.3926, 61.6621],
              [160.7666, 60.7533],
              [159.8832, 61.2917],
              [160.3174, 61.7933],
              [159.5525, 61.7194],
              [159.0767, 61.9223],
              [158.07, 61.7536],
              [157.4694, 61.7989],
              [156.6801, 61.4806],
              [156.63, 61.2724],
              [155.8532, 60.7772],
              [154.9707, 60.3766],
              [154.2929, 59.8333],
              [154.5827, 59.5401],
              [155.1669, 59.3601],
              [154.011, 59.0756],
              [153.3611, 59.2147],
              [152.8822, 58.9391],
              [151.99, 59.1601],
              [151.4857, 59.5241],
              [150.4572, 59.5907],
              [149.6427, 59.7705],
              [149.205, 59.4882],
              [147.8746, 59.3881],
              [147.5146, 59.2687],
              [146.4444, 59.4305],
              [145.9317, 59.1983],
              [145.5544, 59.4136],
              [143.192, 59.3701],
              [142.0255, 58.9997],
              [141.7547, 58.7453],
              [140.6849, 58.2122],
              [140.447, 57.8136],
              [140.0022, 57.6875],
              [138.6621, 56.9655],
              [137.6913, 56.1395],
              [136.175, 55.3522],
              [135.2623, 54.9434],
              [135.8514, 54.5838],
              [136.7973, 54.6209],
              [136.6828, 53.9313],
              [137.1555, 53.8217],
              [137.3282, 53.5389],
              [138.2923, 53.5924],
              [138.6608, 53.7449],
              [138.6958, 54.3199],
              [139.7075, 54.2771],
              [140.3471, 53.8126],
              [141.4022, 53.184],
              [141.1696, 52.3684],
              [141.4853, 52.1786],
              [140.6876, 51.2322],
              [140.4763, 50.546],
              [140.5171, 49.596],
              [140.1704, 48.5238],
              [139.3728, 47.8873],
              [139.0012, 47.3835],
              [138.5866, 47.0572],
              [138.3367, 46.5432],
              [137.6855, 45.8183],
              [136.8035, 45.1711],
              [136.1421, 44.4893],
              [135.8745, 44.3734],
              [135.1309, 43.5256],
              [133.7092, 42.83],
              [133.1601, 42.6969],
              [132.4813, 42.9099],
              [132.3344, 43.2388],
              [131.7946, 43.2553],
              [131.1586, 42.6258],
              [130.6871, 42.3027]
            ]
          ],
          [
            [
              [67.7654, 76.2376],
              [66.2824, 75.9837],
              [61.3561, 75.3149],
              [60.2224, 74.7965],
              [58.5347, 74.4988],
              [58.4415, 74.1288],
              [56.9638, 73.3665],
              [55.0067, 73.4539],
              [53.7629, 73.7662],
              [54.6427, 73.9595],
              [57.0876, 75.3837],
              [58.8814, 75.8547],
              [62.9714, 76.2367],
              [65.8629, 76.6133],
              [67.5351, 77.0077],
              [68.4859, 76.9338],
              [68.9, 76.5729],
              [67.7654, 76.2376]
            ]
          ],
          [
            [
              [140.0488, 75.829],
              [140.9442, 75.7004],
              [142.4605, 75.9035],
              [143.6856, 75.8636],
              [145.2552, 75.5857],
              [144.0199, 75.0446],
              [141.9873, 74.9913],
              [139.0993, 74.6566],
              [138.0921, 74.7974],
              [137.0064, 75.235],
              [137.5013, 75.9097],
              [138.8139, 76.1996],
              [140.0488, 75.829]
            ]
          ],
          [
            [
              [146.7951, 75.3709],
              [150.1039, 75.2193],
              [150.6463, 74.9445],
              [149.5969, 74.7725],
              [148.0926, 74.8258],
              [146.1483, 75.1984],
              [146.7951, 75.3709]
            ]
          ],
          [[[113.3873, 74.4005], [112.7823, 74.0951], [111.6428, 74.2729], [112.0845, 74.549], [113.3873, 74.4005]]],
          [
            [
              [142.1848, 73.8958],
              [143.3438, 73.5687],
              [143.1934, 73.2206],
              [140.6627, 73.4519],
              [141.0849, 73.8658],
              [142.1848, 73.8958]
            ]
          ],
          [
            [
              [55.32, 73.3083],
              [56.4293, 73.2011],
              [55.356, 72.4651],
              [55.2978, 71.9354],
              [57.1458, 70.589],
              [54.6009, 70.68],
              [53.7225, 70.8145],
              [53.3638, 71.5416],
              [51.8124, 71.4912],
              [51.5825, 72.0712],
              [52.6221, 72.3009],
              [53.7531, 73.2932],
              [55.32, 73.3083]
            ]
          ]
        ]
      },
      properties: { name: "Russia - Asia", id: "RU", Continent: "Asia" },
      id: "RU"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [53.0855, 16.6485],
            [52.3279, 16.2938],
            [52.2176, 15.6553],
            [51.3224, 15.2259],
            [50.527, 15.0383],
            [49.3497, 14.638],
            [48.6685, 14.0504],
            [47.99, 14.0482],
            [47.4078, 13.6616],
            [46.789, 13.4655],
            [45.6574, 13.3385],
            [45.0387, 12.816],
            [44.6176, 12.8174],
            [43.9299, 12.6165],
            [43.4751, 12.8388],
            [43.232, 13.2673],
            [43.2826, 13.6395],
            [43.0893, 14.0109],
            [43.0211, 14.5549],
            [42.717, 15.6544],
            [42.7992, 16.3721],
            [43.1649, 16.6893],
            [43.1912, 17.3595],
            [43.418, 17.5164],
            [43.9167, 17.3248],
            [45.148, 17.4277],
            [45.4065, 17.32],
            [46.6818, 17.2685],
            [46.9758, 16.9534],
            [47.442, 17.1116],
            [47.5796, 17.4482],
            [48.172, 18.1567],
            [49.0421, 18.582],
            [51.9776, 18.9959],
            [52.7291, 17.3006],
            [53.0855, 16.6485]
          ]
        ]
      },
      properties: { name: "Yemen", id: "YE", Continent: "Middle East" },
      id: "YE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.4506, 31.4791],
            [34.9505, 31.6022],
            [34.9559, 32.1611],
            [35.1932, 32.5342],
            [35.5513, 32.3953],
            [35.4506, 31.4791]
          ]
        ]
      },
      properties: { name: "Palestinian Territories", id: "PS", Continent: "Middle East" },
      id: "PS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [107.9728, 21.508],
            [107.4098, 21.2847],
            [107.3544, 21.0553],
            [106.6755, 20.9604],
            [106.5182, 20.2889],
            [105.9841, 19.9391],
            [105.6217, 18.9665],
            [105.8884, 18.5025],
            [106.4988, 17.9462],
            [106.479, 17.7194],
            [107.1804, 16.8981],
            [108.0294, 16.3312],
            [108.8214, 15.378],
            [109.0845, 14.7162],
            [109.3034, 13.8561],
            [109.2722, 13.2792],
            [109.4239, 12.9562],
            [109.2186, 12.6459],
            [109.1988, 11.7248],
            [108.8205, 11.3153],
            [108.2716, 10.9342],
            [107.2618, 10.3986],
            [106.813, 10.4332],
            [106.5722, 9.641],
            [105.5013, 9.0933],
            [105.3224, 8.8011],
            [104.8184, 8.8016],
            [104.8451, 9.6062],
            [105.0279, 10.0672],
            [104.4264, 10.4113],
            [104.8505, 10.5347],
            [105.3866, 10.9399],
            [106.1679, 11.0125],
            [105.8559, 11.2941],
            [105.8514, 11.6352],
            [106.3401, 11.6818],
            [106.4135, 11.9485],
            [107.506, 12.3643],
            [107.4757, 13.0299],
            [107.6057, 13.4379],
            [107.3311, 14.1264],
            [107.5197, 14.7051],
            [107.6533, 15.2549],
            [107.1896, 15.7472],
            [107.3966, 16.0428],
            [106.6961, 16.4585],
            [106.5024, 16.9543],
            [105.6915, 17.7378],
            [105.4582, 18.1544],
            [104.7164, 18.8035],
            [103.8964, 19.34],
            [104.0317, 19.6754],
            [104.5463, 19.6104],
            [104.9283, 20.0182],
            [104.5833, 20.6467],
            [104.0519, 20.9411],
            [103.6349, 20.6971],
            [103.1044, 20.8914],
            [102.6953, 21.6622],
            [102.1275, 22.3789],
            [102.5174, 22.7406],
            [103.0052, 22.4532],
            [103.301, 22.7643],
            [104.3719, 22.7041],
            [104.796, 22.9111],
            [105.2754, 23.3453],
            [105.8432, 22.923],
            [106.5419, 22.908],
            [106.5362, 22.3956],
            [106.7296, 22.0006],
            [107.3514, 21.609],
            [107.9728, 21.508]
          ]
        ]
      },
      properties: { name: "Vietnam", id: "VN", Continent: "Asia" },
      id: "VN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.9466, 42.2484],
            [70.842, 42.0194],
            [70.1811, 41.5716],
            [70.7827, 41.2627],
            [71.4085, 41.1361],
            [71.7926, 41.4129],
            [72.6583, 40.8698],
            [73.1131, 40.7858],
            [71.6924, 40.1526],
            [70.9581, 40.2392],
            [70.3828, 40.4537],
            [70.751, 40.7397],
            [70.4417, 41.0232],
            [69.7126, 40.6571],
            [69.4142, 40.7969],
            [68.5863, 39.6349],
            [67.7193, 39.6218],
            [67.3497, 39.2421],
            [68.0476, 38.6695],
            [68.2941, 38.0327],
            [67.8142, 37.4869],
            [67.7592, 37.1722],
            [66.5222, 37.3485],
            [66.5745, 38.0107],
            [65.613, 38.2384],
            [64.1628, 38.9533],
            [62.6506, 39.8586],
            [61.9031, 41.0935],
            [60.4551, 41.2218],
            [60.0895, 41.3994],
            [60.1071, 41.9078],
            [59.8583, 42.295],
            [59.4509, 42.2998],
            [58.5887, 42.7783],
            [58.2596, 42.6878],
            [57.8144, 42.1899],
            [57.382, 42.1561],
            [56.9641, 41.8564],
            [57.0182, 41.2636],
            [55.9775, 41.322],
            [55.9766, 43.6177],
            [55.9757, 44.995],
            [58.5549, 45.5555],
            [61.008, 44.3938],
            [61.9906, 43.4924],
            [63.207, 43.6282],
            [64.4432, 43.5514],
            [64.9054, 43.7148],
            [66.1003, 42.9906],
            [66.0094, 42.0049],
            [66.4985, 41.9952],
            [66.814, 41.1423],
            [67.9359, 41.1963],
            [68.2919, 40.6562],
            [69.0653, 41.3669],
            [70.9466, 42.2484]
          ]
        ]
      },
      properties: { name: "Uzbekistan", id: "UZ", Continent: "Asia" },
      id: "UZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [121.0092, 22.6206],
            [120.5816, 22.3565],
            [120.3258, 22.5424],
            [120.0722, 23.1497],
            [120.1588, 23.7088],
            [121.0403, 25.0328],
            [121.5937, 25.2754],
            [121.9293, 24.9735],
            [121.2956, 22.967],
            [121.0092, 22.6206]
          ]
        ]
      },
      properties: { name: "Taiwan", id: "TW", Continent: "Asia" },
      id: "TW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [125.0679, -9.5123],
            [124.9224, -8.9427],
            [125.1782, -8.6478],
            [125.8044, -8.4923],
            [126.6197, -8.4597],
            [126.915, -8.7151],
            [125.4081, -9.2758],
            [125.0679, -9.5123]
          ]
        ]
      },
      properties: { name: "Timor-Leste", id: "TL", Continent: "Asia" },
      id: "TL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.5222, 37.3485],
            [65.765, 37.5691],
            [65.555, 37.2513],
            [64.8163, 37.1323],
            [64.5108, 36.3408],
            [63.8622, 36.012],
            [63.1086, 35.8188],
            [63.0567, 35.446],
            [62.2529, 35.2501],
            [61.2619, 35.6197],
            [61.1196, 36.6427],
            [60.3413, 36.6379],
            [60.0627, 36.9631],
            [59.5621, 37.1788],
            [59.2409, 37.5207],
            [58.4358, 37.6385],
            [57.4238, 37.9479],
            [57.1939, 38.2164],
            [56.4407, 38.2493],
            [56.2289, 38.0731],
            [55.5785, 38.1],
            [55.0753, 37.9022],
            [54.7453, 37.5018],
            [53.9143, 37.3437],
            [53.8238, 37.9281],
            [53.8686, 38.949],
            [53.125, 39.4319],
            [53.4722, 39.6688],
            [52.9875, 39.9878],
            [52.7335, 40.3987],
            [53.1453, 40.8249],
            [53.8699, 40.6488],
            [54.7101, 40.8909],
            [54.592, 41.1932],
            [54.0949, 41.5193],
            [53.8462, 42.0915],
            [52.9699, 41.9763],
            [52.494, 41.7803],
            [53.2503, 42.2057],
            [54.1213, 42.3349],
            [54.9035, 41.9192],
            [55.5451, 41.2627],
            [55.9775, 41.322],
            [57.0182, 41.2636],
            [56.9641, 41.8564],
            [57.382, 42.1561],
            [57.8144, 42.1899],
            [58.2596, 42.6878],
            [58.5887, 42.7783],
            [59.4509, 42.2998],
            [59.8583, 42.295],
            [60.1071, 41.9078],
            [60.0895, 41.3994],
            [60.4551, 41.2218],
            [61.9031, 41.0935],
            [62.6506, 39.8586],
            [64.1628, 38.9533],
            [65.613, 38.2384],
            [66.5745, 38.0107],
            [66.5222, 37.3485]
          ]
        ]
      },
      properties: { name: "Turkmenistan", id: "TM", Continent: "Asia" },
      id: "TM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.9581, 40.2392],
            [70.4514, 40.0489],
            [69.9667, 40.2023],
            [69.5303, 40.0972],
            [69.2288, 39.761],
            [69.5988, 39.5738],
            [71.4046, 39.598],
            [72.0426, 39.3519],
            [73.6317, 39.449],
            [73.8013, 38.607],
            [74.7452, 38.51],
            [74.8912, 37.2316],
            [74.2596, 37.4153],
            [73.7495, 37.2316],
            [73.4813, 37.4719],
            [72.8956, 37.2676],
            [71.8023, 36.6941],
            [71.4331, 37.1274],
            [71.5803, 37.8644],
            [71.2555, 38.3069],
            [70.8789, 38.4563],
            [70.1991, 37.8859],
            [70.1885, 37.5822],
            [69.6256, 37.5937],
            [69.2649, 37.1081],
            [68.9603, 37.3247],
            [68.0678, 36.9499],
            [67.7592, 37.1722],
            [67.8142, 37.4869],
            [68.2941, 38.0327],
            [68.0476, 38.6695],
            [67.3497, 39.2421],
            [67.7193, 39.6218],
            [68.5863, 39.6349],
            [69.4142, 40.7969],
            [69.7126, 40.6571],
            [70.4417, 41.0232],
            [70.751, 40.7397],
            [70.3828, 40.4537],
            [70.9581, 40.2392]
          ]
        ]
      },
      properties: { name: "Tajikistan", id: "TJ", Continent: "Asia" },
      id: "TJ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [100.1223, 20.3165],
            [100.5429, 20.0885],
            [100.3978, 19.7562],
            [100.6256, 19.5001],
            [101.1547, 19.5797],
            [101.2865, 18.9772],
            [101.0505, 18.4071],
            [101.1054, 17.4794],
            [102.1013, 18.2103],
            [102.7172, 17.8922],
            [103.0912, 18.1382],
            [103.4882, 18.4181],
            [103.9495, 18.3193],
            [104.8161, 17.3006],
            [104.7507, 16.6476],
            [105.0469, 16.1602],
            [105.6225, 15.6996],
            [105.5132, 15.3609],
            [105.4753, 14.5302],
            [105.183, 14.3462],
            [104.7787, 14.4278],
            [103.1995, 14.3325],
            [102.3195, 13.5398],
            [102.4994, 12.6696],
            [102.7555, 12.4263],
            [102.934, 11.7064],
            [102.7626, 12.0122],
            [101.7237, 12.6894],
            [100.8633, 12.7145],
            [100.9626, 13.4322],
            [100.6559, 13.5213],
            [100.0173, 13.3535],
            [99.9887, 12.1709],
            [99.6271, 11.4629],
            [99.4868, 10.8898],
            [99.1652, 10.3199],
            [99.1609, 9.7336],
            [99.3937, 9.2139],
            [99.7237, 9.3145],
            [99.9607, 8.6711],
            [100.2792, 8.2685],
            [100.5864, 7.1761],
            [101.0179, 6.861],
            [101.4979, 6.8653],
            [102.1008, 6.2423],
            [101.8735, 5.8257],
            [101.2571, 5.7896],
            [101.0536, 6.2426],
            [100.3451, 6.5499],
            [100.1189, 6.4421],
            [99.6961, 6.8763],
            [99.051, 7.8876],
            [98.579, 8.3446],
            [98.238, 8.4233],
            [98.3716, 9.2903],
            [98.7025, 10.1902],
            [98.757, 10.6613],
            [99.1903, 11.1052],
            [99.6147, 11.7811],
            [99.4052, 12.5474],
            [99.2197, 12.7399],
            [99.1367, 13.7164],
            [98.2463, 14.8146],
            [98.191, 15.204],
            [98.5653, 15.4039],
            [98.6893, 16.3053],
            [98.4388, 16.9755],
            [97.7929, 17.6812],
            [97.4848, 18.4942],
            [97.7537, 18.6212],
            [97.817, 19.46],
            [98.2389, 19.6908],
            [98.9169, 19.773],
            [99.8904, 20.4246],
            [100.1223, 20.3165]
          ]
        ]
      },
      properties: { name: "Thailand", id: "TH", Continent: "Asia" },
      id: "TH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.3589, 37.1085],
            [41.7884, 36.5974],
            [41.4167, 36.5148],
            [41.2457, 36.0736],
            [41.3543, 35.6403],
            [41.1943, 34.7688],
            [40.6894, 34.3321],
            [38.7734, 33.3718],
            [36.8183, 32.3171],
            [36.3722, 32.3871],
            [35.7873, 32.7351],
            [35.8691, 33.432],
            [35.9424, 33.6676],
            [36.5853, 34.2213],
            [36.4328, 34.6137],
            [35.9763, 34.6292],
            [35.9433, 35.2241],
            [35.7644, 35.5713],
            [36.1274, 35.8314],
            [36.6583, 36.8022],
            [37.4365, 36.6436],
            [38.1915, 36.9015],
            [38.7668, 36.6932],
            [39.357, 36.6818],
            [40.7057, 37.0975],
            [42.3589, 37.1085]
          ]
        ]
      },
      properties: { name: "Syria", id: "SY", Continent: "Middle East" },
      id: "SY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[103.9698, 1.3313], [103.82, 1.2654], [103.6503, 1.3256], [103.8177, 1.4473], [103.9698, 1.3313]]
        ]
      },
      properties: { name: "Singapore", id: "SG", Continent: "Asia" },
      id: "SG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.5315, 29.0965],
            [47.4332, 28.9896],
            [47.6714, 28.533],
            [48.4422, 28.5428],
            [48.9067, 27.6291],
            [49.4051, 27.1812],
            [49.986, 26.8288],
            [50.2141, 26.3085],
            [50.0317, 26.1108],
            [50.5582, 25.0868],
            [50.8043, 24.7893],
            [51.2684, 24.6073],
            [51.568, 24.2862],
            [51.5927, 24.0787],
            [52.5551, 22.933],
            [55.1044, 22.6215],
            [55.1861, 22.7041],
            [55.6409, 22.0019],
            [54.9773, 19.9958],
            [51.9776, 18.9959],
            [49.0421, 18.582],
            [48.172, 18.1567],
            [47.5796, 17.4482],
            [47.442, 17.1116],
            [46.9758, 16.9534],
            [46.6818, 17.2685],
            [45.4065, 17.32],
            [45.148, 17.4277],
            [43.9167, 17.3248],
            [43.418, 17.5164],
            [43.1912, 17.3595],
            [43.1649, 16.6893],
            [42.7992, 16.3721],
            [42.2939, 17.435],
            [41.7498, 17.8856],
            [41.2295, 18.6783],
            [40.7593, 19.7558],
            [39.6136, 20.5178],
            [39.0938, 21.3102],
            [38.9879, 21.882],
            [39.096, 22.393],
            [38.9412, 22.8821],
            [38.464, 23.7122],
            [37.9195, 24.1851],
            [37.5429, 24.2919],
            [37.1808, 24.8201],
            [37.1487, 25.2907],
            [36.5185, 26.1051],
            [36.2496, 26.5946],
            [35.1809, 28.0347],
            [34.6161, 28.1481],
            [34.9505, 29.3535],
            [36.0158, 29.1904],
            [36.755, 29.8663],
            [37.4695, 29.9952],
            [37.9802, 30.5001],
            [36.9584, 31.4918],
            [38.9971, 32.0074],
            [39.1456, 32.1246],
            [40.3695, 31.9392],
            [42.0741, 31.0802],
            [42.8576, 30.4953],
            [44.7166, 29.1936],
            [46.5315, 29.0965]
          ]
        ]
      },
      properties: { name: "Saudi Arabia", id: "SA", Continent: "Middle East" },
      id: "SA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [51.2684, 24.6073],
            [50.8043, 24.7893],
            [50.763, 25.4446],
            [51.0033, 25.9815],
            [51.543, 25.9021],
            [51.6019, 25.148],
            [51.2684, 24.6073]
          ]
        ]
      },
      properties: { name: "Qatar", id: "QA", Continent: "Middle East" },
      id: "QA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [130.5267, 42.5353],
            [130.6872, 42.3029],
            [130.4578, 42.302],
            [129.7564, 41.7122],
            [129.709, 40.8575],
            [128.3045, 40.0361],
            [127.5684, 39.7817],
            [127.3944, 39.2078],
            [127.7863, 39.0839],
            [128.3744, 38.6233],
            [128.039, 38.3086],
            [127.1698, 38.3047],
            [126.6341, 37.7817],
            [125.942, 37.8736],
            [125.4494, 37.7303],
            [125.1931, 38.0379],
            [124.6909, 38.1294],
            [125.0674, 38.5565],
            [125.4097, 39.2882],
            [125.3609, 39.5264],
            [124.5573, 39.7905],
            [124.3621, 40.0041],
            [124.8895, 40.4597],
            [125.989, 40.905],
            [126.9036, 41.7812],
            [127.1795, 41.5312],
            [128.1111, 41.3892],
            [128.0452, 41.9874],
            [128.9236, 42.0383],
            [129.314, 42.4136],
            [129.7199, 42.4751],
            [129.8984, 42.998],
            [130.5267, 42.5353]
          ]
        ]
      },
      properties: { name: "North Korea", id: "KP", Continent: "Asia" },
      id: "KP"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [155.9575, -6.6869],
              [155.7194, -6.8627],
              [155.3441, -6.7217],
              [155.202, -6.3077],
              [154.782, -5.9706],
              [154.9968, -5.54],
              [155.9575, -6.6869]
            ]
          ],
          [
            [
              [151.9159, -4.2968],
              [152.4053, -4.3408],
              [152.4001, -4.7314],
              [152.0134, -5.0039],
              [152.1431, -5.3572],
              [151.5151, -5.5523],
              [151.2291, -5.9202],
              [150.4282, -6.2764],
              [149.6522, -6.2906],
              [148.4011, -5.7649],
              [148.3448, -5.5448],
              [150.2987, -5.5355],
              [150.953, -5.4235],
              [151.3269, -4.9604],
              [151.671, -4.8835],
              [151.552, -4.3456],
              [151.9159, -4.2968]
            ]
          ],
          [
            [
              [140.9762, -9.1189],
              [140.9748, -6.3459],
              [140.9739, -4.0234],
              [140.9735, -2.6097],
              [141.1857, -2.6277],
              [142.2115, -3.0834],
              [143.5091, -3.431],
              [144.0158, -3.7835],
              [144.4777, -3.8252],
              [145.088, -4.349],
              [145.3347, -4.3855],
              [145.7671, -4.8231],
              [145.8528, -5.4714],
              [146.4038, -5.6165],
              [147.0345, -5.9193],
              [147.3768, -5.9503],
              [147.8017, -6.3152],
              [147.8452, -6.6627],
              [147.1192, -6.7217],
              [146.9606, -6.9291],
              [147.19, -7.3783],
              [147.7243, -7.8761],
              [148.1269, -8.1033],
              [148.2468, -8.5546],
              [148.5831, -9.0517],
              [149.2474, -9.071],
              [149.2032, -9.4067],
              [149.8746, -10.0131],
              [150.6674, -10.2571],
              [150.4823, -10.6368],
              [150.017, -10.5775],
              [149.7541, -10.3533],
              [147.7688, -10.0699],
              [146.6965, -9.0252],
              [146.0334, -8.0765],
              [144.9738, -7.8022],
              [144.5097, -7.5671],
              [143.6137, -8.2004],
              [143.0947, -8.3112],
              [143.3658, -8.9611],
              [142.6473, -9.3276],
              [142.2299, -9.1698],
              [141.1335, -9.2213],
              [140.9762, -9.1189]
            ]
          ],
          [
            [
              [152.9656, -4.7565],
              [152.6805, -4.4984],
              [152.5983, -3.9949],
              [153.0167, -4.1057],
              [153.1327, -4.3526],
              [152.9656, -4.7565]
            ]
          ]
        ]
      },
      properties: { name: "Papua New Guinea", id: "PG", Continent: "Oceania" },
      id: "PG"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [126.0058, 9.3211],
              [126.3198, 8.8447],
              [126.4354, 7.8326],
              [126.5814, 7.2477],
              [126.0799, 6.7331],
              [125.8243, 7.3334],
              [125.401, 6.796],
              [125.6683, 5.9785],
              [125.456, 5.6643],
              [124.6364, 5.9979],
              [124.0778, 6.4044],
              [123.9851, 6.9937],
              [124.2066, 7.3962],
              [123.9685, 7.6643],
              [123.4928, 7.808],
              [123.3909, 7.4076],
              [122.1319, 7.8106],
              [122.3371, 8.0286],
              [122.9111, 8.1565],
              [123.4344, 8.7031],
              [124.2259, 8.2712],
              [124.4513, 8.6064],
              [124.7308, 8.563],
              [124.8688, 8.9721],
              [125.5337, 9.1409],
              [125.521, 9.7591],
              [126.0058, 9.3211]
            ]
          ],
          [[[124.5938, 9.7872], [123.9354, 9.6237], [124.1728, 10.1354], [124.5938, 9.7872]]],
          [
            [
              [123.1309, 9.0639],
              [122.5626, 9.4827],
              [122.4716, 9.9617],
              [122.8557, 10.0866],
              [122.8171, 10.5037],
              [122.9836, 10.8868],
              [123.5676, 10.7805],
              [123.1619, 9.8642],
              [123.3083, 9.3571],
              [123.1309, 9.0639]
            ]
          ],
          [
            [
              [117.3111, 8.4395],
              [117.3498, 8.7133],
              [118.1149, 9.3469],
              [119.0241, 10.3537],
              [119.2615, 10.8451],
              [119.6841, 10.5515],
              [119.1915, 10.0611],
              [118.782, 9.9165],
              [118.4348, 9.2563],
              [117.3111, 8.4395]
            ]
          ],
          [
            [
              [124.5748, 11.3429],
              [124.9299, 11.3728],
              [125.0134, 10.7853],
              [125.2533, 10.264],
              [124.781, 10.1678],
              [124.7871, 10.7813],
              [124.4456, 10.9237],
              [124.5748, 11.3429]
            ]
          ],
          [
            [
              [122.4967, 11.6149],
              [123.1585, 11.5354],
              [122.7699, 10.8234],
              [122.1978, 10.6232],
              [121.964, 10.8715],
              [122.1038, 11.643],
              [122.4967, 11.6149]
            ]
          ],
          [
            [
              [125.2398, 12.5278],
              [125.536, 12.1912],
              [125.4569, 11.9524],
              [125.6282, 11.132],
              [125.2336, 11.1452],
              [124.8843, 11.7754],
              [124.4456, 12.1529],
              [124.2944, 12.5695],
              [125.2398, 12.5278]
            ]
          ],
          [
            [
              [120.7046, 13.4796],
              [121.2029, 13.4326],
              [121.5223, 13.1311],
              [121.5408, 12.6384],
              [121.2368, 12.2188],
              [120.9221, 12.5118],
              [120.5082, 13.2603],
              [120.7046, 13.4796]
            ]
          ],
          [
            [
              [121.1018, 18.6155],
              [121.8459, 18.2951],
              [122.2997, 18.4028],
              [122.1512, 17.7563],
              [122.5191, 17.1248],
              [122.135, 16.1848],
              [121.5953, 15.9335],
              [121.3923, 15.3244],
              [121.6956, 14.7373],
              [121.8005, 14.1138],
              [122.4905, 14.3224],
              [122.8561, 14.2508],
              [123.2315, 13.7476],
              [124.1429, 13.0361],
              [124.0597, 12.5672],
              [123.311, 13.0441],
              [123.1633, 13.4418],
              [122.5955, 13.9076],
              [122.4066, 13.4928],
              [121.7777, 13.9375],
              [121.2033, 13.6399],
              [120.6167, 14.1879],
              [120.8883, 14.7158],
              [120.0823, 14.8511],
              [119.8309, 16.3267],
              [120.1597, 16.0478],
              [120.4088, 16.9556],
              [120.3584, 17.6381],
              [120.5996, 18.5077],
              [121.1018, 18.6155]
            ]
          ]
        ]
      },
      properties: { name: "Philippines", id: "PH", Continent: "Asia" },
      id: "PH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [77.799, 35.4962],
            [77.0484, 35.1099],
            [76.5944, 34.7355],
            [75.7094, 34.5031],
            [74.3006, 34.7653],
            [73.9612, 34.6538],
            [74.1498, 33.5068],
            [73.9942, 33.2422],
            [74.6578, 32.5189],
            [75.3332, 32.279],
            [74.5101, 31.713],
            [74.6327, 31.0344],
            [73.8993, 30.4353],
            [73.8092, 30.0935],
            [73.3817, 29.9345],
            [72.903, 29.0292],
            [72.3419, 28.7519],
            [71.8699, 27.9622],
            [70.7374, 27.7292],
            [70.4035, 28.025],
            [69.568, 27.1743],
            [69.4701, 26.8047],
            [70.1565, 26.4711],
            [70.0778, 26.0716],
            [70.2646, 25.7064],
            [70.6522, 25.4225],
            [71.0481, 24.6878],
            [71.0442, 24.4],
            [70.5467, 24.4185],
            [69.7162, 24.1728],
            [68.7283, 24.2655],
            [68.7243, 23.9645],
            [68.1649, 23.8573],
            [67.6687, 23.8112],
            [67.3097, 24.1749],
            [67.1717, 24.756],
            [66.7033, 24.8609],
            [66.6989, 25.2262],
            [66.4286, 25.5755],
            [65.6797, 25.3553],
            [64.7767, 25.3074],
            [64.6589, 25.184],
            [63.7207, 25.3861],
            [63.4913, 25.2107],
            [62.5724, 25.2547],
            [62.3153, 25.1347],
            [61.5876, 25.202],
            [61.8697, 26.2421],
            [62.4393, 26.5607],
            [63.1679, 26.6658],
            [63.1665, 27.2525],
            [62.7631, 27.2503],
            [62.7398, 28.0021],
            [62.3531, 28.4149],
            [61.6228, 28.7914],
            [61.3182, 29.3724],
            [60.8436, 29.8584],
            [62.4766, 29.4084],
            [63.5677, 29.4981],
            [64.0991, 29.3922],
            [64.5209, 29.5644],
            [65.0953, 29.5596],
            [66.1772, 29.8356],
            [66.3974, 30.9122],
            [66.9243, 31.3055],
            [67.2873, 31.2177],
            [67.5782, 31.5064],
            [68.2136, 31.8074],
            [68.8689, 31.6343],
            [69.2789, 31.937],
            [69.2415, 32.4336],
            [69.5017, 33.0199],
            [70.284, 33.3688],
            [69.8682, 33.8974],
            [71.0517, 34.05],
            [70.9655, 34.5307],
            [71.6207, 35.1833],
            [71.4274, 35.8337],
            [71.1852, 36.0419],
            [71.6207, 36.4366],
            [72.6231, 36.8294],
            [73.7692, 36.8884],
            [74.5414, 37.0219],
            [75.3468, 36.9134],
            [75.8404, 36.6497],
            [75.945, 36.0178],
            [76.2517, 35.8108],
            [77.4466, 35.4755],
            [77.799, 35.4962]
          ]
        ]
      },
      properties: { name: "Pakistan", id: "PK", Continent: "Asia" },
      id: "PK"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [56.388, 24.9792],
            [56.6407, 24.4703],
            [57.2199, 23.9227],
            [58.5782, 23.6436],
            [59.7999, 22.2204],
            [59.3714, 21.4987],
            [58.8955, 21.1124],
            [58.4745, 20.4066],
            [57.8619, 20.244],
            [57.7151, 19.6068],
            [57.8118, 19.0171],
            [57.1763, 18.9024],
            [56.6552, 18.5873],
            [56.551, 18.1659],
            [56.2702, 17.9506],
            [55.4787, 17.8434],
            [55.0643, 17.0391],
            [54.0681, 17.0058],
            [53.6098, 16.7597],
            [53.0855, 16.6485],
            [52.7291, 17.3006],
            [51.9776, 18.9959],
            [54.9773, 19.9958],
            [55.6409, 22.0019],
            [55.1861, 22.7041],
            [55.1997, 23.0345],
            [55.7609, 24.2427],
            [56.0641, 24.7388],
            [56.388, 24.9792]
          ]
        ]
      },
      properties: { name: "Oman", id: "OM", Continent: "Middle East" },
      id: "OM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [88.1099, 27.8703],
            [87.9842, 27.1338],
            [88.1613, 26.7247],
            [88.0549, 26.4298],
            [87.2872, 26.3604],
            [87.0165, 26.5555],
            [86.3662, 26.5744],
            [85.293, 26.7409],
            [84.6853, 27.0411],
            [84.6101, 27.2986],
            [84.0911, 27.4915],
            [83.2896, 27.3711],
            [81.8529, 27.8669],
            [80.5869, 28.6495],
            [80.0705, 28.8301],
            [80.4019, 29.73],
            [81.0101, 30.1642],
            [81.417, 30.3375],
            [82.0432, 30.327],
            [82.1593, 30.1151],
            [83.1555, 29.6128],
            [83.5835, 29.1834],
            [84.1016, 29.2199],
            [84.2286, 28.9118],
            [85.1225, 28.3159],
            [85.6784, 28.2773],
            [86.4088, 27.9283],
            [86.5547, 28.0852],
            [87.1414, 27.8382],
            [88.1099, 27.8703]
          ]
        ]
      },
      properties: { name: "Nepal", id: "NP", Continent: "Asia" },
      id: "NP"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.1008, 6.2423],
              [102.9824, 5.5251],
              [103.4157, 4.8501],
              [103.4684, 4.3934],
              [103.3621, 3.7694],
              [103.4398, 2.9332],
              [103.812, 2.5807],
              [104.2884, 1.4808],
              [103.6946, 1.4496],
              [102.7275, 1.8555],
              [101.2952, 2.8853],
              [101.2997, 3.2534],
              [100.7157, 3.9663],
              [100.6145, 4.6522],
              [100.3526, 5.5874],
              [100.3429, 5.9842],
              [100.1189, 6.4421],
              [100.3451, 6.5499],
              [101.0536, 6.2426],
              [101.2571, 5.7896],
              [101.8735, 5.8257],
              [102.1008, 6.2423]
            ]
          ],
          [
            [
              [117.5747, 4.1706],
              [117.1006, 4.3372],
              [115.8961, 4.3486],
              [115.5687, 3.9391],
              [115.4544, 3.0342],
              [115.0861, 2.8413],
              [115.1789, 2.5232],
              [114.7865, 2.2502],
              [114.8304, 1.98],
              [114.5461, 1.4671],
              [113.9023, 1.4346],
              [113.6223, 1.2359],
              [112.4762, 1.559],
              [111.7697, 0.9995],
              [110.9382, 1.017],
              [110.5057, 0.8619],
              [109.6542, 1.6152],
              [109.6291, 2.0274],
              [109.9846, 1.7176],
              [110.9399, 1.5173],
              [111.154, 1.7392],
              [111.2418, 2.4356],
              [111.7275, 2.8541],
              [113.0445, 3.2051],
              [113.9238, 4.2435],
              [114.064, 4.5925],
              [114.6086, 4.0244],
              [114.8401, 4.3934],
              [114.7465, 4.7177],
              [115.0268, 4.8996],
              [115.1397, 4.8996],
              [115.5199, 5.0492],
              [115.4188, 5.4129],
              [115.7967, 5.536],
              [116.1387, 6.1292],
              [116.4947, 6.5217],
              [117.294, 6.6768],
              [117.6096, 6.5125],
              [117.6169, 5.9408],
              [118.0037, 6.0533],
              [118.5947, 5.5917],
              [119.2236, 5.4125],
              [119.1322, 5.1005],
              [118.1852, 4.8285],
              [118.4976, 4.3622],
              [117.5747, 4.1706]
            ]
          ]
        ]
      },
      properties: { name: "Malaysia", id: "MY", Continent: "Asia" },
      id: "MY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [116.6831, 49.8236],
            [115.5577, 47.945],
            [115.8983, 47.687],
            [116.7604, 47.8701],
            [117.3507, 47.6521],
            [117.7686, 47.988],
            [118.4985, 47.9841],
            [119.711, 47.15],
            [119.7066, 46.6062],
            [118.3087, 46.7172],
            [117.3335, 46.3621],
            [116.5622, 46.2898],
            [116.2407, 45.7961],
            [115.6808, 45.4584],
            [114.5171, 45.3648],
            [113.5872, 44.7458],
            [111.8979, 45.0642],
            [111.4022, 44.3674],
            [111.933, 43.7117],
            [111.0072, 43.3413],
            [110.4003, 42.7735],
            [109.4432, 42.4558],
            [108.1714, 42.4474],
            [106.7704, 42.2888],
            [105.1971, 41.7381],
            [104.4984, 41.877],
            [103.711, 41.7513],
            [103.0728, 42.0062],
            [101.9733, 42.2159],
            [101.7141, 42.4655],
            [99.9839, 42.6773],
            [99.4676, 42.5683],
            [97.2057, 42.7897],
            [96.3852, 42.7203],
            [95.9128, 43.2064],
            [95.3504, 44.2782],
            [94.7118, 44.3507],
            [93.6563, 44.9007],
            [92.7874, 45.0358],
            [91.5842, 45.0765],
            [90.8771, 45.196],
            [90.6618, 45.5254],
            [91.002, 46.0358],
            [90.8701, 46.9547],
            [90.3133, 47.6761],
            [89.561, 48.0041],
            [89.0477, 48.0027],
            [87.9798, 48.5549],
            [87.8141, 49.1623],
            [88.1925, 49.4519],
            [88.8315, 49.4484],
            [89.6542, 49.7176],
            [89.644, 49.903],
            [91.8044, 50.6936],
            [94.2512, 50.5564],
            [94.6147, 50.0238],
            [95.523, 49.9111],
            [95.8518, 50.0129],
            [96.9859, 49.8828],
            [97.2087, 49.7307],
            [98.1031, 50.0779],
            [98.2793, 50.5334],
            [97.8253, 50.9851],
            [97.9471, 51.3483],
            [98.8483, 52.0702],
            [101.3814, 51.4527],
            [102.1118, 51.3534],
            [102.2885, 50.585],
            [103.6328, 50.1388],
            [105.3835, 50.4736],
            [106.7111, 50.3125],
            [107.2331, 49.9893],
            [107.9477, 49.9247],
            [108.0338, 49.594],
            [108.6139, 49.3229],
            [109.2366, 49.3347],
            [110.8279, 49.1662],
            [111.3367, 49.3559],
            [113.0559, 49.6162],
            [113.164, 49.7971],
            [114.2969, 50.2743],
            [115.2742, 49.9489],
            [116.1347, 50.0108],
            [116.6831, 49.8236]
          ]
        ]
      },
      properties: { name: "Mongolia", id: "MN", Continent: "Asia" },
      id: "MN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [101.1392, 21.5677],
            [100.7565, 21.3128],
            [100.2494, 20.7306],
            [100.1223, 20.3165],
            [99.8904, 20.4246],
            [98.9169, 19.773],
            [98.2389, 19.6908],
            [97.817, 19.46],
            [97.7537, 18.6212],
            [97.4848, 18.4942],
            [97.7929, 17.6812],
            [98.4388, 16.9755],
            [98.6893, 16.3053],
            [98.5653, 15.4039],
            [98.191, 15.204],
            [98.2463, 14.8146],
            [99.1367, 13.7164],
            [99.2197, 12.7399],
            [99.4052, 12.5474],
            [99.6147, 11.7811],
            [99.1903, 11.1052],
            [98.757, 10.6613],
            [98.7025, 10.1902],
            [98.4652, 10.6759],
            [98.6752, 10.9869],
            [98.7416, 11.5917],
            [98.576, 13.1618],
            [98.2454, 13.7332],
            [97.8121, 14.859],
            [97.6192, 16.5372],
            [97.3758, 16.5228],
            [97.1781, 17.0619],
            [96.6226, 16.5641],
            [95.7634, 16.1691],
            [95.3898, 15.723],
            [94.943, 15.8184],
            [94.5875, 16.289],
            [94.2144, 16.1269],
            [94.5887, 17.5695],
            [94.431, 18.2014],
            [94.0702, 18.8936],
            [93.7054, 19.0267],
            [93.9981, 19.4408],
            [93.3627, 20.0581],
            [93.0019, 20.0748],
            [92.7356, 20.5627],
            [92.3243, 20.7917],
            [92.1797, 21.2934],
            [92.5936, 21.467],
            [92.5751, 21.9782],
            [92.9641, 22.004],
            [93.1619, 22.3604],
            [93.0788, 22.7182],
            [93.3662, 23.1321],
            [93.3073, 24.0221],
            [94.1277, 23.8762],
            [94.7074, 25.0486],
            [94.6226, 25.4098],
            [95.1324, 26.0414],
            [95.1288, 26.5972],
            [96.0614, 27.2168],
            [96.666, 27.339],
            [97.3353, 27.938],
            [97.3227, 28.2179],
            [97.538, 28.5102],
            [98.0987, 28.1419],
            [98.2991, 27.5499],
            [98.651, 27.5725],
            [98.732, 26.5837],
            [98.6546, 25.9178],
            [97.715, 25.0346],
            [97.5313, 24.4914],
            [97.686, 23.8981],
            [98.2125, 24.1108],
            [98.7639, 24.1161],
            [98.8638, 23.1914],
            [99.3411, 23.0961],
            [99.3374, 22.498],
            [99.1929, 22.1258],
            [99.918, 22.0277],
            [100.2147, 21.4626],
            [100.8352, 21.6551],
            [101.1392, 21.5677]
          ]
        ]
      },
      properties: { name: "Myanmar", id: "MM", Continent: "Asia" },
      id: "MM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[73.5121, 4.1644], [73.473, 4.1706], [73.5042, 4.2344], [73.5284, 4.2435], [73.5121, 4.1644]]]
      },
      properties: { name: "Maldives", id: "MV", Continent: "Asia" },
      id: "MV"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[113.4786, 22.1957], [113.4944, 22.2414], [113.5468, 22.2243], [113.4786, 22.1957]]]
      },
      properties: { name: "Macau", id: "MO", Continent: "Asia" },
      id: "MO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [79.9822, 9.8127],
            [80.2529, 9.7965],
            [80.7112, 9.3662],
            [81.3731, 8.4315],
            [81.4359, 8.1191],
            [81.8741, 7.2886],
            [81.8613, 6.9014],
            [81.6376, 6.425],
            [80.724, 5.9789],
            [80.0956, 6.1529],
            [79.8592, 6.8293],
            [79.7924, 7.5857],
            [79.929, 8.8992],
            [80.1184, 9.3268],
            [79.9822, 9.8127]
          ]
        ]
      },
      properties: { name: "Sri Lanka", id: "LK", Continent: "Asia" },
      id: "LK"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.8691, 33.432],
            [35.4112, 33.0756],
            [35.1088, 33.0835],
            [35.9763, 34.6292],
            [36.4328, 34.6137],
            [36.5853, 34.2213],
            [35.9424, 33.6676],
            [35.8691, 33.432]
          ]
        ]
      },
      properties: { name: "Lebanon", id: "LB", Continent: "Middle East" },
      id: "LB"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [102.1275, 22.3789],
            [102.6953, 21.6622],
            [103.1044, 20.8914],
            [103.6349, 20.6971],
            [104.0519, 20.9411],
            [104.5833, 20.6467],
            [104.9283, 20.0182],
            [104.5463, 19.6104],
            [104.0317, 19.6754],
            [103.8964, 19.34],
            [104.7164, 18.8035],
            [105.4582, 18.1544],
            [105.6915, 17.7378],
            [106.5024, 16.9543],
            [106.6961, 16.4585],
            [107.3966, 16.0428],
            [107.1896, 15.7472],
            [107.6533, 15.2549],
            [107.5197, 14.7051],
            [106.8201, 14.3149],
            [106.5015, 14.5781],
            [105.9789, 14.343],
            [105.9047, 13.9247],
            [105.183, 14.3462],
            [105.4753, 14.5302],
            [105.5132, 15.3609],
            [105.6225, 15.6996],
            [105.0469, 16.1602],
            [104.7507, 16.6476],
            [104.8161, 17.3006],
            [103.9495, 18.3193],
            [103.4882, 18.4181],
            [103.0912, 18.1382],
            [102.7172, 17.8922],
            [102.1013, 18.2103],
            [101.1054, 17.4794],
            [101.0505, 18.4071],
            [101.2865, 18.9772],
            [101.1547, 19.5797],
            [100.6256, 19.5001],
            [100.3978, 19.7562],
            [100.5429, 20.0885],
            [100.1223, 20.3165],
            [100.2494, 20.7306],
            [100.7565, 21.3128],
            [101.1392, 21.5677],
            [101.224, 21.2236],
            [101.7047, 21.1502],
            [101.7439, 21.7782],
            [101.5241, 22.2537],
            [102.1275, 22.3789]
          ]
        ]
      },
      properties: { name: "Lao People's Democratic Republic", id: "LA", Continent: "Asia" },
      id: "LA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [48.4422, 28.5428],
            [47.6714, 28.533],
            [47.4332, 28.9896],
            [46.5315, 29.0965],
            [47.1142, 29.9612],
            [47.6728, 30.0957],
            [47.9786, 29.9828],
            [47.9698, 29.6167],
            [48.4422, 28.5428]
          ]
        ]
      },
      properties: { name: "Kuwait", id: "KW", Continent: "Middle East" },
      id: "KW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [128.3744, 38.6233],
            [128.6191, 38.1764],
            [129.335, 37.2747],
            [129.4734, 36.742],
            [129.4036, 36.0521],
            [129.5617, 35.9475],
            [129.2141, 35.182],
            [128.511, 35.101],
            [128.4442, 34.8704],
            [127.7147, 34.9544],
            [127.0309, 34.6067],
            [126.4817, 34.4938],
            [126.2913, 35.1543],
            [126.7175, 35.7691],
            [126.5485, 36.4775],
            [126.217, 36.8708],
            [126.7462, 37.1933],
            [126.6341, 37.7817],
            [127.1698, 38.3047],
            [128.039, 38.3086],
            [128.3744, 38.6233]
          ]
        ]
      },
      properties: { name: "South Korea", id: "KR", Continent: "Asia" },
      id: "KR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [104.4264, 10.4113],
            [104.2624, 10.5413],
            [103.532, 10.6047],
            [103.7219, 10.8902],
            [103.5325, 11.147],
            [103.1528, 10.9135],
            [103.1256, 11.4608],
            [102.934, 11.7064],
            [102.7555, 12.4263],
            [102.4994, 12.6696],
            [102.3195, 13.5398],
            [103.1995, 14.3325],
            [104.7787, 14.4278],
            [105.183, 14.3462],
            [105.9047, 13.9247],
            [105.9789, 14.343],
            [106.5015, 14.5781],
            [106.8201, 14.3149],
            [107.5197, 14.7051],
            [107.3311, 14.1264],
            [107.6057, 13.4379],
            [107.4757, 13.0299],
            [107.506, 12.3643],
            [106.4135, 11.9485],
            [106.3401, 11.6818],
            [105.8514, 11.6352],
            [105.8559, 11.2941],
            [106.1679, 11.0125],
            [105.3866, 10.9399],
            [104.8505, 10.5347],
            [104.4264, 10.4113]
          ]
        ]
      },
      properties: { name: "Cambodia", id: "KH", Continent: "Asia" },
      id: "KH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [80.2094, 42.1899],
            [80.216, 42.0322],
            [79.2936, 41.7829],
            [78.3624, 41.3717],
            [78.1233, 41.0759],
            [76.8238, 40.9823],
            [76.4802, 40.4492],
            [75.872, 40.3033],
            [75.5837, 40.6052],
            [73.9916, 40.0432],
            [73.9147, 39.6064],
            [73.6317, 39.449],
            [72.0426, 39.3519],
            [71.4046, 39.598],
            [69.5988, 39.5738],
            [69.2288, 39.761],
            [69.5303, 40.0972],
            [69.9667, 40.2023],
            [70.4514, 40.0489],
            [70.9581, 40.2392],
            [71.6924, 40.1526],
            [73.1131, 40.7858],
            [72.6583, 40.8698],
            [71.7926, 41.4129],
            [71.4085, 41.1361],
            [70.7827, 41.2627],
            [70.1811, 41.5716],
            [70.842, 42.0194],
            [70.9466, 42.2484],
            [71.2564, 42.7335],
            [71.8167, 42.8219],
            [72.8551, 42.5608],
            [73.422, 42.5934],
            [73.5566, 43.0029],
            [74.2091, 43.2402],
            [75.0476, 42.9044],
            [76.9442, 42.9717],
            [79.1644, 42.759],
            [79.4901, 42.4576],
            [80.2094, 42.1899]
          ]
        ]
      },
      properties: { name: "Kyrgyzstan", id: "KG", Continent: "Asia" },
      id: "KG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.3228, 49.0858],
            [86.7533, 49.0087],
            [86.5494, 48.5288],
            [85.7492, 48.3849],
            [85.5259, 47.9157],
            [85.6564, 47.2549],
            [84.7859, 46.8307],
            [83.1929, 47.1865],
            [83.0043, 47.0336],
            [82.3262, 45.5201],
            [82.4788, 45.1237],
            [81.6021, 45.3109],
            [80.0591, 45.0064],
            [80.4555, 44.7462],
            [80.3553, 44.0972],
            [80.7856, 43.1615],
            [80.1651, 42.6654],
            [80.2094, 42.1899],
            [79.4901, 42.4576],
            [79.1644, 42.759],
            [76.9442, 42.9717],
            [75.0476, 42.9044],
            [74.2091, 43.2402],
            [73.5566, 43.0029],
            [73.422, 42.5934],
            [72.8551, 42.5608],
            [71.8167, 42.8219],
            [71.2564, 42.7335],
            [70.9466, 42.2484],
            [69.0653, 41.3669],
            [68.2919, 40.6562],
            [67.9359, 41.1963],
            [66.814, 41.1423],
            [66.4985, 41.9952],
            [66.0094, 42.0049],
            [66.1003, 42.9906],
            [64.9054, 43.7148],
            [64.4432, 43.5514],
            [63.207, 43.6282],
            [61.9906, 43.4924],
            [61.008, 44.3938],
            [58.5549, 45.5555],
            [55.9757, 44.995],
            [55.9766, 43.6177],
            [55.9775, 41.322],
            [55.5451, 41.2627],
            [54.9035, 41.9192],
            [54.1213, 42.3349],
            [53.2503, 42.2057],
            [52.494, 41.7803],
            [52.5968, 42.7603],
            [51.844, 42.9101],
            [51.3017, 43.4824],
            [50.8307, 44.193],
            [50.8601, 44.6286],
            [51.4156, 45.3579],
            [52.5309, 45.3988],
            [53.1351, 46.1915],
            [53.1703, 46.6693],
            [52.9159, 46.9542],
            [52.189, 46.8397],
            [51.1782, 47.11],
            [49.184, 46.3486],
            [48.9595, 46.7745],
            [48.11, 47.7453],
            [47.0936, 47.9476],
            [46.6611, 48.4124],
            [47.0144, 49.0986],
            [46.8022, 49.3671],
            [47.3265, 50.2734],
            [47.7062, 50.378],
            [48.4343, 49.8285],
            [48.843, 50.013],
            [48.8087, 50.6012],
            [49.4978, 51.0836],
            [50.2466, 51.2895],
            [50.7942, 51.7291],
            [51.609, 51.4839],
            [52.3309, 51.6812],
            [52.5709, 51.4817],
            [53.3382, 51.4824],
            [54.4214, 50.7804],
            [54.7269, 50.9981],
            [55.7978, 50.6021],
            [56.4917, 51.0196],
            [57.4422, 50.8889],
            [58.3593, 51.0638],
            [58.8837, 50.6944],
            [59.8122, 50.582],
            [61.3894, 50.861],
            [61.363, 51.4419],
            [60.4648, 51.6514],
            [60.2337, 52.0247],
            [60.9376, 52.2804],
            [60.8023, 52.7447],
            [61.1995, 53.2872],
            [60.9798, 53.6216],
            [61.334, 54.0492],
            [61.9286, 53.9464],
            [64.4612, 54.3841],
            [65.1577, 54.3643],
            [65.4768, 54.6234],
            [66.2225, 54.6673],
            [68.0739, 54.9595],
            [68.9774, 55.3895],
            [70.1824, 55.1626],
            [70.7383, 55.3051],
            [71.1857, 54.5994],
            [71.0934, 54.2123],
            [72.9141, 54.1072],
            [73.3992, 53.8115],
            [74.4517, 53.6473],
            [76.2662, 54.312],
            [76.576, 53.9425],
            [77.7041, 53.379],
            [78.4758, 52.6386],
            [79.9862, 50.7747],
            [80.8769, 51.2815],
            [81.4658, 50.7397],
            [82.4937, 50.7276],
            [83.3572, 50.9946],
            [83.9452, 50.7747],
            [84.2577, 50.2884],
            [84.9894, 50.0614],
            [85.2099, 49.6648],
            [86.1807, 49.4993],
            [86.6144, 49.6098],
            [87.3228, 49.0858]
          ]
        ]
      },
      properties: { name: "Kazakhstan", id: "KZ", Continent: "Asia" },
      id: "KZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [131.1746, 33.6026],
              [131.5828, 33.6527],
              [131.8965, 33.255],
              [131.9766, 32.8436],
              [131.7322, 32.5931],
              [131.3376, 31.4049],
              [130.5887, 31.1781],
              [130.1946, 32.0909],
              [130.6405, 32.619],
              [129.5801, 33.2361],
              [130.9531, 33.8719],
              [131.1746, 33.6026]
            ]
          ],
          [
            [
              [134.3571, 34.2565],
              [134.739, 33.8206],
              [134.1813, 33.2475],
              [133.6319, 33.5111],
              [133.2862, 33.3599],
              [132.9772, 32.8418],
              [132.4951, 32.9166],
              [132.3659, 33.5125],
              [132.7843, 33.9928],
              [133.5822, 34.0169],
              [134.0759, 34.3585],
              [134.3571, 34.2565]
            ]
          ],
          [
            [
              [141.2297, 41.3726],
              [141.4626, 40.6109],
              [141.978, 39.8446],
              [141.9007, 39.1116],
              [141.6585, 38.9749],
              [141.4674, 38.404],
              [141.1084, 38.3377],
              [140.9283, 37.9497],
              [140.9686, 37.0022],
              [140.6272, 36.503],
              [140.6218, 36.0596],
              [140.8742, 35.7251],
              [140.4571, 35.5102],
              [140.3147, 35.1551],
              [139.7993, 34.9565],
              [139.6503, 35.4091],
              [139.2495, 35.2782],
              [138.9827, 34.6985],
              [138.5772, 35.0862],
              [138.1891, 34.5961],
              [136.8849, 34.8057],
              [136.8536, 34.3241],
              [136.3298, 34.177],
              [135.9162, 33.5617],
              [135.4526, 33.5533],
              [135.1002, 34.2881],
              [135.3849, 34.5004],
              [134.7398, 34.7653],
              [133.1424, 34.3026],
              [132.2379, 34.227],
              [131.7406, 34.0521],
              [130.9187, 33.9756],
              [131.004, 34.3927],
              [131.3543, 34.4133],
              [132.9232, 35.5111],
              [134.2139, 35.5392],
              [135.1744, 35.7471],
              [135.6802, 35.5032],
              [136.6984, 36.742],
              [137.3423, 36.7706],
              [137.5141, 36.9517],
              [138.32, 37.2184],
              [138.8848, 37.8437],
              [139.4011, 38.1425],
              [140.0366, 39.4112],
              [139.9227, 40.5986],
              [140.2813, 40.846],
              [141.1185, 40.8821],
              [141.2297, 41.3726]
            ]
          ],
          [
            [
              [143.8242, 44.1169],
              [144.7984, 43.9403],
              [145.1012, 43.7645],
              [145.3473, 43.1765],
              [144.6305, 42.9466],
              [143.9692, 42.8816],
              [143.5808, 42.5986],
              [143.2363, 42.0005],
              [141.8514, 42.5793],
              [140.9862, 42.3424],
              [140.3151, 42.3341],
              [140.6601, 41.8159],
              [140.3851, 41.5193],
              [139.8212, 42.3877],
              [139.8911, 42.6491],
              [140.781, 43.2147],
              [141.2961, 43.1998],
              [141.6445, 44.0198],
              [141.7821, 44.7165],
              [141.583, 45.156],
              [141.9376, 45.5093],
              [142.704, 44.8192],
              [143.8242, 44.1169]
            ]
          ]
        ]
      },
      properties: { name: "Japan", id: "JP", Continent: "Asia" },
      id: "JP"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [39.1456, 32.1246],
            [38.9971, 32.0074],
            [36.9584, 31.4918],
            [37.9802, 30.5001],
            [37.4695, 29.9952],
            [36.755, 29.8663],
            [36.0158, 29.1904],
            [34.9505, 29.3535],
            [34.9734, 29.5553],
            [35.4506, 31.4791],
            [35.5513, 32.3953],
            [35.7873, 32.7351],
            [36.3722, 32.3871],
            [36.8183, 32.3171],
            [38.7734, 33.3718],
            [39.1456, 32.1246]
          ]
        ]
      },
      properties: { name: "Jordan", id: "JO", Continent: "Middle East" },
      id: "JO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.7873, 32.7351],
            [35.5513, 32.3953],
            [35.1932, 32.5342],
            [34.9559, 32.1611],
            [34.9505, 31.6022],
            [35.4506, 31.4791],
            [34.9734, 29.5553],
            [34.2452, 31.2084],
            [34.4777, 31.5851],
            [34.6786, 31.8957],
            [35.1088, 33.0835],
            [35.4112, 33.0756],
            [35.8691, 33.432],
            [35.7873, 32.7351]
          ]
        ]
      },
      properties: { name: "Israel", id: "IL", Continent: "Middle East" },
      id: "IL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [44.7653, 37.1423],
            [45.3617, 36.0156],
            [45.7234, 35.8363],
            [46.1337, 35.1275],
            [45.6781, 34.7984],
            [45.3968, 33.9708],
            [46.1456, 33.2295],
            [46.1127, 32.9579],
            [47.3713, 32.424],
            [47.8297, 31.7942],
            [47.6793, 31.0023],
            [48.0107, 30.9896],
            [48.0151, 30.4654],
            [48.5468, 29.9626],
            [47.9786, 29.9828],
            [47.6728, 30.0957],
            [47.1142, 29.9612],
            [46.5315, 29.0965],
            [44.7166, 29.1936],
            [42.8576, 30.4953],
            [42.0741, 31.0802],
            [40.3695, 31.9392],
            [39.1456, 32.1246],
            [38.7734, 33.3718],
            [40.6894, 34.3321],
            [41.1943, 34.7688],
            [41.3543, 35.6403],
            [41.2457, 36.0736],
            [41.4167, 36.5148],
            [41.7884, 36.5974],
            [42.3589, 37.1085],
            [42.7746, 37.3717],
            [43.5159, 37.2447],
            [44.1563, 37.283],
            [44.2819, 36.978],
            [44.7653, 37.1423]
          ]
        ]
      },
      properties: { name: "Iraq", id: "IQ", Continent: "Middle East" },
      id: "IQ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [53.9143, 37.3437],
            [54.7453, 37.5018],
            [55.0753, 37.9022],
            [55.5785, 38.1],
            [56.2289, 38.0731],
            [56.4407, 38.2493],
            [57.1939, 38.2164],
            [57.4238, 37.9479],
            [58.4358, 37.6385],
            [59.2409, 37.5207],
            [59.5621, 37.1788],
            [60.0627, 36.9631],
            [60.3413, 36.6379],
            [61.1196, 36.6427],
            [61.2619, 35.6197],
            [61.08, 34.8554],
            [60.4854, 34.0947],
            [60.511, 33.6386],
            [60.917, 33.505],
            [60.5606, 33.1376],
            [60.8291, 32.2496],
            [60.8542, 31.4831],
            [61.6601, 31.3824],
            [61.7845, 30.8323],
            [61.1038, 30.1286],
            [60.8436, 29.8584],
            [61.3182, 29.3724],
            [61.6228, 28.7914],
            [62.3531, 28.4149],
            [62.7398, 28.0021],
            [62.7631, 27.2503],
            [63.1665, 27.2525],
            [63.1679, 26.6658],
            [62.4393, 26.5607],
            [61.8697, 26.2421],
            [61.5876, 25.202],
            [61.4118, 25.1022],
            [59.4562, 25.4814],
            [58.2029, 25.5913],
            [57.3346, 25.7917],
            [56.9825, 26.9053],
            [56.3559, 27.2001],
            [55.6502, 26.9778],
            [54.8956, 26.5564],
            [53.7056, 26.7256],
            [52.6917, 27.3237],
            [52.476, 27.6163],
            [51.6665, 27.8453],
            [51.2789, 28.1314],
            [51.0622, 28.7264],
            [50.6751, 29.1465],
            [50.6501, 29.4198],
            [49.9829, 30.2091],
            [49.5549, 30.0289],
            [48.919, 30.1208],
            [48.5468, 29.9626],
            [48.0151, 30.4654],
            [48.0107, 30.9896],
            [47.6793, 31.0023],
            [47.8297, 31.7942],
            [47.3713, 32.424],
            [46.1127, 32.9579],
            [46.1456, 33.2295],
            [45.3968, 33.9708],
            [45.6781, 34.7984],
            [46.1337, 35.1275],
            [45.7234, 35.8363],
            [45.3617, 36.0156],
            [44.7653, 37.1423],
            [44.5614, 37.7448],
            [44.2111, 37.9083],
            [44.4309, 38.357],
            [44.0231, 39.3774],
            [44.5874, 39.7685],
            [45.4798, 39.0065],
            [46.5548, 38.9041],
            [47.7729, 39.6486],
            [47.9958, 39.6842],
            [48.2423, 38.9788],
            [48.0234, 38.8189],
            [48.5929, 38.411],
            [48.8404, 38.4375],
            [49.0812, 37.6675],
            [50.1306, 37.4069],
            [50.5332, 37.0136],
            [51.1185, 36.7424],
            [52.1903, 36.6216],
            [54.0171, 36.9525],
            [53.9143, 37.3437]
          ]
        ]
      },
      properties: { name: "Iran", id: "IR", Continent: "Middle East" },
      id: "IR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.4922, -7.3774],
            [72.4671, -7.3676],
            [72.4737, -7.3097],
            [72.4355, -7.2305],
            [72.4939, -7.2618],
            [72.4922, -7.3774]
          ]
        ]
      },
      properties: { name: "British Indian Ocean Territory", id: "IO", Continent: "Asia" },
      id: "IO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [78.7196, 31.8877],
            [78.8445, 31.3016],
            [79.3384, 31.1055],
            [79.3696, 31.0797],
            [79.9167, 30.8942],
            [79.9246, 30.8889],
            [80.1914, 30.5685],
            [81.0101, 30.1642],
            [80.4019, 29.73],
            [80.0705, 28.8301],
            [80.5869, 28.6495],
            [81.8529, 27.8669],
            [83.2896, 27.3711],
            [84.0911, 27.4915],
            [84.6101, 27.2986],
            [84.6853, 27.0411],
            [85.293, 26.7409],
            [86.3662, 26.5744],
            [87.0165, 26.5555],
            [87.2872, 26.3604],
            [88.0549, 26.4298],
            [88.1613, 26.7247],
            [87.9842, 27.1338],
            [88.1099, 27.8703],
            [88.6214, 28.0918],
            [88.8486, 27.8685],
            [88.8913, 27.3162],
            [88.8578, 26.9616],
            [89.764, 26.7018],
            [90.3458, 26.8904],
            [90.7396, 26.7716],
            [91.9986, 26.8548],
            [92.0835, 27.2907],
            [91.6317, 27.76],
            [91.9775, 27.7301],
            [92.6644, 27.949],
            [93.2067, 28.5911],
            [93.9023, 28.8028],
            [94.623, 29.3127],
            [95.4202, 29.0543],
            [96.035, 29.447],
            [96.4354, 29.0507],
            [96.7759, 28.367],
            [97.3227, 28.2179],
            [97.3353, 27.938],
            [96.666, 27.339],
            [96.0614, 27.2168],
            [95.1288, 26.5972],
            [95.1324, 26.0414],
            [94.6226, 25.4098],
            [94.7074, 25.0486],
            [94.1277, 23.8762],
            [93.3073, 24.0221],
            [93.3662, 23.1321],
            [93.0788, 22.7182],
            [93.1619, 22.3604],
            [92.9641, 22.004],
            [92.5751, 21.9782],
            [92.4917, 22.6852],
            [92.2461, 23.6837],
            [91.938, 23.5047],
            [91.6198, 22.9797],
            [91.3153, 23.1045],
            [91.1601, 23.6604],
            [91.3671, 24.0933],
            [91.8769, 24.1957],
            [92.2513, 24.8948],
            [92.0501, 25.1694],
            [90.4394, 25.1576],
            [89.8141, 25.3053],
            [89.8229, 25.9411],
            [88.4404, 26.3696],
            [88.0844, 25.8884],
            [88.4523, 25.5746],
            [88.4562, 25.1883],
            [88.1455, 24.4857],
            [88.7335, 24.2308],
            [88.567, 23.6744],
            [88.7405, 23.4367],
            [89.0512, 22.0933],
            [89.0517, 21.6542],
            [88.2536, 21.6222],
            [87.9482, 21.8257],
            [87.1005, 21.5005],
            [86.8596, 21.2368],
            [86.9753, 20.7003],
            [86.7502, 20.3131],
            [86.2792, 19.9193],
            [85.5747, 19.693],
            [84.7709, 19.1252],
            [84.1043, 18.2924],
            [83.6543, 18.0696],
            [83.1981, 17.6092],
            [82.3596, 17.0963],
            [82.259, 16.5602],
            [81.762, 16.3299],
            [81.2386, 16.264],
            [80.9789, 15.7586],
            [80.6467, 15.8952],
            [80.2933, 15.7107],
            [80.0534, 15.0739],
            [80.1118, 14.2121],
            [80.3421, 13.3613],
            [80.2292, 12.6903],
            [79.8583, 11.9889],
            [79.8381, 10.3226],
            [79.3907, 10.3062],
            [78.9965, 9.6832],
            [78.9794, 9.2687],
            [78.4217, 9.1049],
            [78.1923, 8.8908],
            [78.06, 8.3845],
            [77.5178, 8.0783],
            [76.9667, 8.4069],
            [76.5536, 8.9031],
            [76.3246, 9.452],
            [76.3462, 9.9222],
            [75.7239, 11.3618],
            [75.1965, 12.0575],
            [74.7703, 13.0774],
            [74.671, 13.6677],
            [74.3823, 14.4946],
            [73.9494, 15.0748],
            [73.9318, 15.3969],
            [73.4761, 16.0544],
            [73.3377, 16.4599],
            [73.1562, 17.6218],
            [72.871, 18.6831],
            [72.6679, 19.831],
            [72.8811, 20.563],
            [72.8139, 21.1169],
            [72.5432, 21.6968],
            [72.5902, 22.2783],
            [72.3327, 22.2703],
            [72.0369, 21.8234],
            [72.254, 21.5312],
            [72.0154, 21.1555],
            [71.0244, 20.7388],
            [70.719, 20.7406],
            [70.1274, 21.0948],
            [69.0087, 22.1965],
            [70.1771, 22.5727],
            [70.2514, 22.9709],
            [69.6643, 22.759],
            [69.2363, 22.8487],
            [68.6408, 23.1902],
            [68.1649, 23.8573],
            [68.7243, 23.9645],
            [68.7283, 24.2655],
            [69.7162, 24.1728],
            [70.5467, 24.4185],
            [71.0442, 24.4],
            [71.0481, 24.6878],
            [70.6522, 25.4225],
            [70.2646, 25.7064],
            [70.0778, 26.0716],
            [70.1565, 26.4711],
            [69.4701, 26.8047],
            [69.568, 27.1743],
            [70.4035, 28.025],
            [70.7374, 27.7292],
            [71.8699, 27.9622],
            [72.3419, 28.7519],
            [72.903, 29.0292],
            [73.3817, 29.9345],
            [73.8092, 30.0935],
            [73.8993, 30.4353],
            [74.6327, 31.0344],
            [74.5101, 31.713],
            [75.3332, 32.279],
            [74.6578, 32.5189],
            [73.9942, 33.2422],
            [74.1498, 33.5068],
            [73.9612, 34.6538],
            [74.3006, 34.7653],
            [75.7094, 34.5031],
            [76.5944, 34.7355],
            [77.0484, 35.1099],
            [77.799, 35.4962],
            [77.8109, 35.4843],
            [78.282, 34.6542],
            [78.9363, 34.3523],
            [78.7531, 34.0878],
            [78.8018, 33.4997],
            [79.1125, 33.2263],
            [79.1354, 33.1718],
            [79.2167, 32.5641],
            [79.2193, 32.5013],
            [78.3897, 32.5198],
            [78.7196, 31.8877]
          ]
        ]
      },
      properties: { name: "India", id: "IN", Continent: "Asia" },
      id: "IN"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [120.0124, -9.3746],
              [120.5037, -9.674],
              [120.8329, -10.0373],
              [120.4391, -10.294],
              [119.6011, -9.7732],
              [119.0857, -9.7068],
              [119.1854, -9.3848],
              [120.0124, -9.3746]
            ]
          ],
          [
            [
              [125.0679, -9.5123],
              [124.4271, -10.1485],
              [123.8572, -10.3437],
              [123.5891, -9.967],
              [123.7092, -9.615],
              [124.0365, -9.3417],
              [124.4444, -9.1901],
              [124.9224, -8.9427],
              [125.0679, -9.5123]
            ]
          ],
          [[[116.6405, -8.614], [116.3773, -8.929], [116.0614, -8.4373], [116.4014, -8.2044], [116.6405, -8.614]]],
          [
            [
              [122.7827, -8.6118],
              [121.6512, -8.8991],
              [121.035, -8.9351],
              [119.8077, -8.6975],
              [119.9183, -8.4453],
              [120.6106, -8.2408],
              [121.4446, -8.578],
              [121.9663, -8.4549],
              [122.2629, -8.625],
              [122.7827, -8.6118]
            ]
          ],
          [
            [
              [118.2428, -8.3178],
              [118.9881, -8.3379],
              [119.0061, -8.7497],
              [118.19, -8.8403],
              [117.0616, -9.0991],
              [116.7886, -9.0063],
              [116.8352, -8.5322],
              [117.1647, -8.367],
              [117.5669, -8.4268],
              [118.1175, -8.1222],
              [118.2428, -8.3178]
            ]
          ],
          [[[115.4478, -8.1552], [115.7045, -8.407], [115.236, -8.7976], [114.4754, -8.1196], [115.4478, -8.1552]]],
          [
            [
              [138.535, -8.2734],
              [138.2963, -8.4052],
              [137.6851, -8.2624],
              [138.0071, -7.6414],
              [138.2954, -7.4385],
              [138.7701, -7.3906],
              [138.9893, -7.6959],
              [138.535, -8.2734]
            ]
          ],
          [[[131.3253, -7.9995], [131.2603, -7.4704], [131.6913, -7.4389], [131.3253, -7.9995]]],
          [
            [
              [113.8447, -7.1053],
              [113.4707, -7.2182],
              [112.7637, -7.14],
              [112.8678, -6.9001],
              [113.9748, -6.8728],
              [113.8447, -7.1053]
            ]
          ],
          [[[134.5368, -6.4426], [134.3557, -6.8148], [134.0591, -6.7692], [134.1681, -6.1763], [134.5368, -6.4426]]],
          [
            [
              [107.3738, -6.0075],
              [107.6668, -6.2155],
              [108.2948, -6.265],
              [108.6777, -6.7906],
              [110.4261, -6.9471],
              [110.7369, -6.4721],
              [111.1544, -6.6693],
              [111.5403, -6.6483],
              [112.0874, -6.8935],
              [112.5391, -6.9265],
              [112.7945, -7.5527],
              [113.2483, -7.7183],
              [114.0706, -7.633],
              [114.4442, -7.8959],
              [114.4485, -8.5595],
              [114.1598, -8.6263],
              [113.2531, -8.287],
              [112.6789, -8.4092],
              [111.5104, -8.305],
              [110.6072, -8.15],
              [110.0386, -7.8907],
              [108.7409, -7.6674],
              [108.4517, -7.7965],
              [107.9174, -7.724],
              [107.2851, -7.4718],
              [106.6315, -7.4155],
              [106.52, -7.0538],
              [105.4836, -6.7815],
              [106.0751, -5.9143],
              [106.8253, -6.0985],
              [107.3738, -6.0075]
            ]
          ],
          [[[134.7468, -5.707], [134.7557, -6.1706], [134.441, -6.335], [134.3413, -5.7127], [134.7468, -5.707]]],
          [[[122.6452, -5.2692], [122.3692, -4.7674], [122.7019, -4.6184], [122.6452, -5.2692]]],
          [
            [
              [126.8614, -3.0878],
              [127.2274, -3.391],
              [127.2295, -3.6332],
              [126.6865, -3.8234],
              [126.2145, -3.6051],
              [126.0263, -3.1705],
              [126.8614, -3.0878]
            ]
          ],
          [
            [
              [129.7547, -2.8659],
              [130.3791, -2.9894],
              [130.8599, -3.5703],
              [130.8054, -3.8578],
              [129.8444, -3.3274],
              [129.4677, -3.4535],
              [128.9676, -3.326],
              [128.4192, -3.4161],
              [128.0821, -3.184],
              [128.1986, -2.8659],
              [129.4273, -2.7908],
              [129.7547, -2.8659]
            ]
          ],
          [[[108.207, -2.9977], [107.6146, -3.2095], [107.6664, -2.5662], [108.0743, -2.597], [108.207, -2.9977]]],
          [[[124.9694, -1.7058], [124.4175, -2.0054], [124.3806, -1.6873], [124.9694, -1.7058]]],
          [
            [
              [106.0457, -1.6693],
              [106.3656, -2.4647],
              [106.679, -2.7042],
              [106.5471, -3.0558],
              [105.9987, -2.8246],
              [105.7855, -2.1817],
              [105.133, -2.0428],
              [105.5857, -1.5268],
              [106.0457, -1.6693]
            ]
          ],
          [[[128.1528, -1.6604], [127.5617, -1.7282], [127.6466, -1.3322], [128.1528, -1.6604]]],
          [
            [
              [99.1639, -1.7778],
              [98.8743, -1.664],
              [98.6018, -1.1977],
              [98.869, -0.9156],
              [99.2672, -1.6276],
              [99.1639, -1.7778]
            ]
          ],
          [
            [
              [140.9735, -2.6097],
              [140.9739, -4.0234],
              [140.9748, -6.3459],
              [140.9762, -9.1189],
              [139.9347, -8.1012],
              [138.8561, -8.145],
              [139.0878, -7.5874],
              [138.7476, -7.2517],
              [138.8645, -6.8584],
              [138.4388, -6.3432],
              [138.0612, -5.4653],
              [137.0897, -4.9243],
              [135.9796, -4.531],
              [135.196, -4.4505],
              [134.1475, -3.7967],
              [133.6531, -3.3646],
              [133.2488, -4.0621],
              [132.9688, -4.0946],
              [132.754, -3.7035],
              [132.7513, -3.2948],
              [132.3483, -2.9753],
              [133.1911, -2.4378],
              [133.6087, -2.5473],
              [133.9215, -2.1021],
              [132.9631, -2.2727],
              [132.3079, -2.2424],
              [132.0231, -1.9901],
              [131.9305, -1.5599],
              [131.2936, -1.3938],
              [131.2571, -0.8559],
              [131.8042, -0.7038],
              [132.1287, -0.4537],
              [132.6251, -0.3588],
              [133.4725, -0.7262],
              [133.9747, -0.7442],
              [134.2595, -1.363],
              [134.1062, -1.7211],
              [134.1558, -2.1954],
              [135.0373, -3.3331],
              [135.4865, -3.3449],
              [136.2432, -2.5833],
              [136.39, -2.2736],
              [136.843, -2.1975],
              [137.1234, -1.8411],
              [137.8064, -1.4834],
              [138.6497, -1.7911],
              [139.7897, -2.3482],
              [140.6223, -2.4458],
              [140.9735, -2.6097]
            ]
          ],
          [[[97.4812, 1.4649], [97.9321, 0.9741], [97.8764, 0.6282], [97.4057, 0.9468], [97.4812, 1.4649]]],
          [
            [
              [124.8891, 0.9956],
              [124.4271, 0.4709],
              [123.7539, 0.3057],
              [123.0825, 0.4859],
              [121.8423, 0.4366],
              [121.4257, 0.4946],
              [120.349, 0.4494],
              [120.012, -0.307],
              [120.0973, -0.6502],
              [120.5173, -1.0395],
              [120.6676, -1.3699],
              [121.0338, -1.4064],
              [121.5193, -0.8559],
              [121.9693, -0.9332],
              [122.2801, -0.7569],
              [122.8887, -0.7551],
              [122.5068, -1.348],
              [121.7189, -1.8627],
              [121.8484, -2.3315],
              [122.3991, -3.2008],
              [122.2527, -3.6204],
              [122.8773, -4.1091],
              [122.8974, -4.349],
              [122.2071, -4.4963],
              [121.917, -4.8478],
              [121.4868, -4.5811],
              [121.6178, -4.0929],
              [120.9146, -3.5559],
              [121.0521, -2.7516],
              [120.654, -2.6677],
              [120.2537, -3.0531],
              [120.4369, -3.7071],
              [120.4198, -4.6176],
              [120.3117, -5.5417],
              [119.7171, -5.6933],
              [119.3765, -5.4253],
              [119.6116, -4.4238],
              [119.6235, -4.0343],
              [119.4196, -3.4754],
              [118.9947, -3.5379],
              [118.7838, -2.7648],
              [119.0923, -2.4832],
              [119.3217, -1.9299],
              [119.3089, -1.4082],
              [119.7721, -0.4837],
              [119.809, 0.2384],
              [120.2695, 0.9709],
              [120.5164, 0.8177],
              [120.912, 1.2886],
              [121.5919, 1.0676],
              [122.4365, 1.0179],
              [122.8922, 0.8496],
              [123.278, 0.9279],
              [123.8467, 0.8382],
              [124.2738, 1.022],
              [124.9892, 1.7013],
              [125.2341, 1.5023],
              [124.8891, 0.9956]
            ]
          ],
          [
            [
              [127.7328, 0.848],
              [128.424, 1.5173],
              [128.7026, 1.1064],
              [128.2992, 0.877],
              [128.6912, 0.3601],
              [127.5556, 0.4898],
              [127.4203, 1.2518],
              [127.6317, 1.8437],
              [128.0109, 1.7013],
              [128.0118, 1.3317],
              [127.7328, 0.848]
            ]
          ],
          [
            [
              [117.5747, 4.1706],
              [117.6301, 3.6363],
              [117.1661, 3.5923],
              [117.6103, 3.0646],
              [118.0666, 2.3179],
              [117.8648, 1.9686],
              [118.0805, 1.7018],
              [118.9849, 0.9819],
              [118.5345, 0.8132],
              [117.7448, 0.7297],
              [117.522, 0.2357],
              [117.4627, -0.3237],
              [117.5624, -0.7711],
              [116.5544, -1.4741],
              [116.4234, -2.0529],
              [116.5658, -2.2994],
              [115.9563, -3.5949],
              [114.6937, -4.1698],
              [114.536, -3.4948],
              [114.0825, -3.279],
              [113.7954, -3.4565],
              [113.5256, -3.184],
              [112.9715, -3.1871],
              [112.6002, -3.4003],
              [112.2851, -3.3212],
              [111.8224, -3.5322],
              [111.8233, -3.0574],
              [111.3675, -2.9336],
              [111.0445, -3.0558],
              [110.2325, -2.9252],
              [110.0193, -1.3991],
              [109.7872, -1.0114],
              [109.4542, -0.8691],
              [109.1605, -0.4947],
              [109.2572, 0.0314],
              [108.9443, 0.3557],
              [108.9057, 0.7939],
              [109.0757, 1.4957],
              [109.6291, 2.0274],
              [109.6542, 1.6152],
              [110.5057, 0.8619],
              [110.9382, 1.017],
              [111.7697, 0.9995],
              [112.4762, 1.559],
              [113.6223, 1.2359],
              [113.9023, 1.4346],
              [114.5461, 1.4671],
              [114.8304, 1.98],
              [114.7865, 2.2502],
              [115.1789, 2.5232],
              [115.0861, 2.8413],
              [115.4544, 3.0342],
              [115.5687, 3.9391],
              [115.8961, 4.3486],
              [117.1006, 4.3372],
              [117.5747, 4.1706]
            ]
          ],
          [
            [
              [96.4924, 5.2293],
              [97.4513, 5.2359],
              [97.9083, 4.88],
              [98.2485, 4.4146],
              [98.3074, 4.0928],
              [99.7321, 3.1827],
              [100.4571, 2.2573],
              [101.0461, 2.2573],
              [101.4767, 1.6929],
              [101.7848, 1.6213],
              [102.4695, 0.7794],
              [103.0663, 0.492],
              [103.4789, 0.4802],
              [103.7869, 0.0467],
              [103.4287, -0.1914],
              [103.4314, -0.5332],
              [103.721, -0.8867],
              [104.3609, -1.0386],
              [104.6685, -2.3856],
              [105.3967, -2.3803],
              [106.0444, -3.1062],
              [105.8441, -3.6134],
              [105.8875, -5.0096],
              [105.8163, -5.6767],
              [105.3492, -5.5492],
              [105.0811, -5.7456],
              [104.6396, -5.5207],
              [104.6013, -5.9043],
              [103.8314, -5.0799],
              [102.5375, -4.1522],
              [102.1275, -3.5994],
              [101.5786, -3.1669],
              [100.8479, -2.1439],
              [100.8553, -1.9343],
              [100.3082, -0.8269],
              [99.5976, 0.1021],
              [99.1591, 0.3518],
              [98.7961, 1.4948],
              [98.5646, 1.9022],
              [97.7005, 2.3588],
              [97.5911, 2.8466],
              [96.9692, 3.5752],
              [96.445, 3.8164],
              [95.4949, 4.7612],
              [95.2066, 5.2838],
              [95.629, 5.6095],
              [96.1335, 5.2939],
              [96.4924, 5.2293]
            ]
          ]
        ]
      },
      properties: { name: "Indonesia", id: "ID", Continent: "Asia" },
      id: "ID"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[114.0152, 22.5116], [114.2656, 22.541], [114.2679, 22.2954], [114.0152, 22.5116]]]
      },
      properties: { name: "Hong Kong", id: "HK", Continent: "Asia" },
      id: "HK"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [110.8886, 19.9918],
              [111.0138, 19.6552],
              [110.5721, 19.1717],
              [110.4512, 18.7482],
              [109.5192, 18.2186],
              [108.7019, 18.5355],
              [108.6935, 19.3384],
              [109.651, 19.9843],
              [110.5884, 20.0727],
              [110.8886, 19.9918]
            ]
          ],
          [
            [
              [130.5267, 42.5353],
              [129.8984, 42.998],
              [129.7199, 42.4751],
              [129.314, 42.4136],
              [128.9236, 42.0383],
              [128.0452, 41.9874],
              [128.1111, 41.3892],
              [127.1795, 41.5312],
              [126.9036, 41.7812],
              [125.989, 40.905],
              [124.8895, 40.4597],
              [124.3621, 40.0041],
              [124.1055, 39.841],
              [123.3483, 39.7633],
              [122.335, 39.3664],
              [121.7852, 39.4012],
              [121.5175, 39.6389],
              [122.2748, 40.5419],
              [121.8591, 40.8421],
              [121.1743, 40.9014],
              [120.4792, 40.2308],
              [119.591, 39.9025],
              [118.9124, 39.1665],
              [117.7845, 39.1344],
              [117.5576, 38.625],
              [118.0151, 38.1834],
              [118.9402, 38.0428],
              [119.0891, 37.7009],
              [118.953, 37.3309],
              [119.4499, 37.1248],
              [120.7504, 37.8341],
              [121.6402, 37.4601],
              [122.6025, 37.4267],
              [122.5195, 36.9468],
              [121.9328, 36.9595],
              [120.9898, 36.5979],
              [120.0546, 35.8613],
              [119.2157, 35.0119],
              [119.5826, 34.582],
              [120.2015, 34.3255],
              [120.8708, 33.0163],
              [120.853, 32.6612],
              [121.401, 32.3721],
              [121.3519, 31.8588],
              [121.8779, 30.9167],
              [120.9972, 30.558],
              [121.1595, 30.3014],
              [121.9442, 29.894],
              [121.7175, 29.2564],
              [121.6019, 28.3665],
              [121.0988, 28.2904],
              [120.2788, 27.0973],
              [119.5391, 25.5913],
              [119.025, 25.2235],
              [118.5605, 24.5801],
              [118.0871, 24.6267],
              [118.0561, 24.2461],
              [116.5381, 23.1796],
              [116.4705, 22.9458],
              [115.4984, 22.7191],
              [114.2656, 22.541],
              [114.0152, 22.5116],
              [113.6198, 22.8614],
              [113.5468, 22.2243],
              [113.4944, 22.2414],
              [113.4786, 22.1957],
              [113.0084, 22.1192],
              [112.5861, 21.7769],
              [111.9437, 21.8494],
              [111.603, 21.5593],
              [110.7711, 21.3867],
              [110.1937, 21.0378],
              [110.5114, 20.5183],
              [110.123, 20.2638],
              [109.6624, 20.9169],
              [109.931, 21.4806],
              [108.3247, 21.6938],
              [107.9728, 21.508],
              [107.3514, 21.609],
              [106.7296, 22.0006],
              [106.5362, 22.3956],
              [106.5419, 22.908],
              [105.8432, 22.923],
              [105.2754, 23.3453],
              [104.796, 22.9111],
              [104.3719, 22.7041],
              [103.301, 22.7643],
              [103.0052, 22.4532],
              [102.5174, 22.7406],
              [102.1275, 22.3789],
              [101.5241, 22.2537],
              [101.7439, 21.7782],
              [101.7047, 21.1502],
              [101.224, 21.2236],
              [101.1392, 21.5677],
              [100.8352, 21.6551],
              [100.2147, 21.4626],
              [99.918, 22.0277],
              [99.1929, 22.1258],
              [99.3374, 22.498],
              [99.3411, 23.0961],
              [98.8638, 23.1914],
              [98.7639, 24.1161],
              [98.2125, 24.1108],
              [97.686, 23.8981],
              [97.5313, 24.4914],
              [97.715, 25.0346],
              [98.6546, 25.9178],
              [98.732, 26.5837],
              [98.651, 27.5725],
              [98.2991, 27.5499],
              [98.0987, 28.1419],
              [97.538, 28.5102],
              [97.3227, 28.2179],
              [96.7759, 28.367],
              [96.4354, 29.0507],
              [96.035, 29.447],
              [95.4202, 29.0543],
              [94.623, 29.3127],
              [93.9023, 28.8028],
              [93.2067, 28.5911],
              [92.6644, 27.949],
              [91.9775, 27.7301],
              [91.6317, 27.76],
              [91.6057, 27.9517],
              [90.3529, 28.0804],
              [89.9811, 28.3111],
              [89.5368, 28.1077],
              [88.8913, 27.3162],
              [88.8486, 27.8685],
              [88.6214, 28.0918],
              [88.1099, 27.8703],
              [87.1414, 27.8382],
              [86.5547, 28.0852],
              [86.4088, 27.9283],
              [85.6784, 28.2773],
              [85.1225, 28.3159],
              [84.2286, 28.9118],
              [84.1016, 29.2199],
              [83.5835, 29.1834],
              [83.1555, 29.6128],
              [82.1593, 30.1151],
              [82.0432, 30.327],
              [81.417, 30.3375],
              [81.0101, 30.1642],
              [80.1914, 30.5685],
              [79.9246, 30.8889],
              [79.9167, 30.8942],
              [79.3696, 31.0797],
              [79.3384, 31.1055],
              [78.8445, 31.3016],
              [78.7196, 31.8877],
              [78.3897, 32.5198],
              [79.2193, 32.5013],
              [79.2167, 32.5641],
              [79.1354, 33.1718],
              [79.1125, 33.2263],
              [78.8018, 33.4997],
              [78.7531, 34.0878],
              [78.9363, 34.3523],
              [78.282, 34.6542],
              [77.8109, 35.4843],
              [77.799, 35.4962],
              [77.4466, 35.4755],
              [76.2517, 35.8108],
              [75.945, 36.0178],
              [75.8404, 36.6497],
              [75.3468, 36.9134],
              [74.5414, 37.0219],
              [74.8912, 37.2316],
              [74.7452, 38.51],
              [73.8013, 38.607],
              [73.6317, 39.449],
              [73.9147, 39.6064],
              [73.9916, 40.0432],
              [75.5837, 40.6052],
              [75.872, 40.3033],
              [76.4802, 40.4492],
              [76.8238, 40.9823],
              [78.1233, 41.0759],
              [78.3624, 41.3717],
              [79.2936, 41.7829],
              [80.216, 42.0322],
              [80.2094, 42.1899],
              [80.1651, 42.6654],
              [80.7856, 43.1615],
              [80.3553, 44.0972],
              [80.4555, 44.7462],
              [80.0591, 45.0064],
              [81.6021, 45.3109],
              [82.4788, 45.1237],
              [82.3262, 45.5201],
              [83.0043, 47.0336],
              [83.1929, 47.1865],
              [84.7859, 46.8307],
              [85.6564, 47.2549],
              [85.5259, 47.9157],
              [85.7492, 48.3849],
              [86.5494, 48.5288],
              [86.7533, 49.0087],
              [87.3228, 49.0858],
              [87.8141, 49.1623],
              [87.9798, 48.5549],
              [89.0477, 48.0027],
              [89.561, 48.0041],
              [90.3133, 47.6761],
              [90.8701, 46.9547],
              [91.002, 46.0358],
              [90.6618, 45.5254],
              [90.8771, 45.196],
              [91.5842, 45.0765],
              [92.7874, 45.0358],
              [93.6563, 44.9007],
              [94.7118, 44.3507],
              [95.3504, 44.2782],
              [95.9128, 43.2064],
              [96.3852, 42.7203],
              [97.2057, 42.7897],
              [99.4676, 42.5683],
              [99.9839, 42.6773],
              [101.7141, 42.4655],
              [101.9733, 42.2159],
              [103.0728, 42.0062],
              [103.711, 41.7513],
              [104.4984, 41.877],
              [105.1971, 41.7381],
              [106.7704, 42.2888],
              [108.1714, 42.4474],
              [109.4432, 42.4558],
              [110.4003, 42.7735],
              [111.0072, 43.3413],
              [111.933, 43.7117],
              [111.4022, 44.3674],
              [111.8979, 45.0642],
              [113.5872, 44.7458],
              [114.5171, 45.3648],
              [115.6808, 45.4584],
              [116.2407, 45.7961],
              [116.5622, 46.2898],
              [117.3335, 46.3621],
              [118.3087, 46.7172],
              [119.7066, 46.6062],
              [119.711, 47.15],
              [118.4985, 47.9841],
              [117.7686, 47.988],
              [117.3507, 47.6521],
              [116.7604, 47.8701],
              [115.8983, 47.687],
              [115.5577, 47.945],
              [116.6831, 49.8236],
              [117.8732, 49.5134],
              [118.4515, 49.8443],
              [119.1475, 50.0133],
              [119.192, 50.3798],
              [120.0669, 51.6006],
              [120.6813, 51.973],
              [120.6993, 52.4935],
              [120.0946, 52.7871],
              [120.7041, 53.1718],
              [122.338, 53.4849],
              [123.608, 53.5465],
              [124.8122, 53.1338],
              [125.6489, 53.0422],
              [126.3129, 52.3997],
              [126.9246, 51.1003],
              [127.59, 50.209],
              [127.5024, 49.8736],
              [128, 49.5686],
              [128.704, 49.6],
              [129.534, 49.3235],
              [130.8045, 48.3414],
              [130.9618, 47.7092],
              [132.4767, 47.7152],
              [132.7074, 47.9472],
              [134.2933, 48.3734],
              [134.5637, 48.3218],
              [134.7521, 47.7156],
              [134.1676, 47.3023],
              [133.8613, 46.2476],
              [133.1859, 45.4946],
              [132.9359, 45.0299],
              [131.9775, 45.2439],
              [131.0822, 44.9101],
              [131.2555, 44.0717],
              [131.2619, 43.4331],
              [131.0687, 42.9023],
              [130.5267, 42.5353]
            ]
          ]
        ]
      },
      properties: { name: "China", id: "CN", Continent: "Asia" },
      id: "CN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [91.6317, 27.76],
            [92.0835, 27.2907],
            [91.9986, 26.8548],
            [90.7396, 26.7716],
            [90.3458, 26.8904],
            [89.764, 26.7018],
            [88.8578, 26.9616],
            [88.8913, 27.3162],
            [89.5368, 28.1077],
            [89.9811, 28.3111],
            [90.3529, 28.0804],
            [91.6057, 27.9517],
            [91.6317, 27.76]
          ]
        ]
      },
      properties: { name: "Bhutan", id: "BT", Continent: "Asia" },
      id: "BT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [115.0268, 4.8996],
            [114.7465, 4.7177],
            [114.8401, 4.3934],
            [114.6086, 4.0244],
            [114.064, 4.5925],
            [114.8405, 4.9468],
            [115.0268, 4.8996],
            [115.1397, 4.8996],
            [115.0268, 4.8996]
          ]
        ]
      },
      properties: { name: "Brunei", id: "BN", Continent: "Asia" },
      id: "BN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[50.6074, 25.8832], [50.4659, 25.9657], [50.4523, 26.1908], [50.61, 26.1244], [50.6074, 25.8832]]
        ]
      },
      properties: { name: "Bahrain", id: "BH", Continent: "Middle East" },
      id: "BH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [92.5751, 21.9782],
            [92.5936, 21.467],
            [92.1797, 21.2934],
            [92.3243, 20.7917],
            [92.0562, 21.1748],
            [92.0083, 21.6845],
            [91.6932, 22.5046],
            [91.4093, 22.7973],
            [90.9457, 22.5969],
            [90.4351, 22.752],
            [90.5893, 22.258],
            [90.1591, 21.8168],
            [89.8119, 21.9834],
            [89.2345, 21.7224],
            [89.0512, 22.0933],
            [88.7405, 23.4367],
            [88.567, 23.6744],
            [88.7335, 24.2308],
            [88.1455, 24.4857],
            [88.4562, 25.1883],
            [88.4523, 25.5746],
            [88.0844, 25.8884],
            [88.4404, 26.3696],
            [89.8229, 25.9411],
            [89.8141, 25.3053],
            [90.4394, 25.1576],
            [92.0501, 25.1694],
            [92.2513, 24.8948],
            [91.8769, 24.1957],
            [91.3671, 24.0933],
            [91.1601, 23.6604],
            [91.3153, 23.1045],
            [91.6198, 22.9797],
            [91.938, 23.5047],
            [92.2461, 23.6837],
            [92.4917, 22.6852],
            [92.5751, 21.9782]
          ]
        ]
      },
      properties: { name: "Bangladesh", id: "BD", Continent: "Asia" },
      id: "BD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [56.2979, 25.6506],
            [56.388, 24.9792],
            [56.0641, 24.7388],
            [55.7609, 24.2427],
            [55.1997, 23.0345],
            [55.1861, 22.7041],
            [55.1044, 22.6215],
            [52.5551, 22.933],
            [51.5927, 24.0787],
            [51.568, 24.2862],
            [51.906, 23.9856],
            [52.6483, 24.1548],
            [53.8932, 24.0769],
            [54.3972, 24.2778],
            [54.747, 24.8105],
            [55.9411, 25.794],
            [56.0804, 26.0624],
            [56.2979, 25.6506]
          ]
        ]
      },
      properties: { name: "United Arab Emirates", id: "AE", Continent: "Middle East" },
      id: "AE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [74.8912, 37.2316],
            [74.5414, 37.0219],
            [73.7692, 36.8884],
            [72.6231, 36.8294],
            [71.6207, 36.4366],
            [71.1852, 36.0419],
            [71.4274, 35.8337],
            [71.6207, 35.1833],
            [70.9655, 34.5307],
            [71.0517, 34.05],
            [69.8682, 33.8974],
            [70.284, 33.3688],
            [69.5017, 33.0199],
            [69.2415, 32.4336],
            [69.2789, 31.937],
            [68.8689, 31.6343],
            [68.2136, 31.8074],
            [67.5782, 31.5064],
            [67.2873, 31.2177],
            [66.9243, 31.3055],
            [66.3974, 30.9122],
            [66.1772, 29.8356],
            [65.0953, 29.5596],
            [64.5209, 29.5644],
            [64.0991, 29.3922],
            [63.5677, 29.4981],
            [62.4766, 29.4084],
            [60.8436, 29.8584],
            [61.1038, 30.1286],
            [61.7845, 30.8323],
            [61.6601, 31.3824],
            [60.8542, 31.4831],
            [60.8291, 32.2496],
            [60.5606, 33.1376],
            [60.917, 33.505],
            [60.511, 33.6386],
            [60.4854, 34.0947],
            [61.08, 34.8554],
            [61.2619, 35.6197],
            [62.2529, 35.2501],
            [63.0567, 35.446],
            [63.1086, 35.8188],
            [63.8622, 36.012],
            [64.5108, 36.3408],
            [64.8163, 37.1323],
            [65.555, 37.2513],
            [65.765, 37.5691],
            [66.5222, 37.3485],
            [67.7592, 37.1722],
            [68.0678, 36.9499],
            [68.9603, 37.3247],
            [69.2649, 37.1081],
            [69.6256, 37.5937],
            [70.1885, 37.5822],
            [70.1991, 37.8859],
            [70.8789, 38.4563],
            [71.2555, 38.3069],
            [71.5803, 37.8644],
            [71.4331, 37.1274],
            [71.8023, 36.6941],
            [72.8956, 37.2676],
            [73.4813, 37.4719],
            [73.7495, 37.2316],
            [74.2596, 37.4153],
            [74.8912, 37.2316]
          ]
        ]
      },
      properties: { name: "Afghanistan", id: "AF", Continent: "Asia" },
      id: "AF"
    },
    //DISABLE AFRICA
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[47.3027, -11.575], [47.282, -11.5746], [47.279, -11.5574], [47.2971, -11.5543], [47.3027, -11.575]]
        ]
      },
      properties: { name: "Glorioso Islands", id: "GO", Continent: "Africa" },
      id: "GO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[42.7579, -17.0752], [42.7417, -17.0744], [42.7382, -17.0519], [42.7609, -17.0651], [42.7579, -17.0752]]
        ]
      },
      properties: { name: "Juan De Nova Island", id: "JU", Continent: "Africa" },
      id: "JU"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.2877, -22.4023],
            [29.663, -22.1465],
            [29.3647, -22.194],
            [29.0373, -21.8112],
            [28.0455, -21.573],
            [27.6697, -21.0642],
            [27.6996, -20.5307],
            [26.1685, -19.5382],
            [25.2589, -17.7937],
            [25.8636, -17.9523],
            [27.0207, -17.9585],
            [27.9326, -16.8963],
            [28.7604, -16.532],
            [28.913, -15.988],
            [29.7299, -15.6447],
            [30.396, -15.643],
            [30.4379, -15.9954],
            [31.2363, -16.024],
            [31.9399, -16.4287],
            [32.9479, -16.7121],
            [32.9932, -18.3597],
            [32.7164, -19.0017],
            [33.0069, -19.8737],
            [32.4777, -20.7129],
            [32.3714, -21.3352],
            [31.2877, -22.4023]
          ]
        ]
      },
      properties: { name: "Zimbabwe", id: "ZW", Continent: "Africa" },
      id: "ZW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.9198, -9.4072],
            [33.351, -9.8619],
            [33.6591, -10.5906],
            [33.2614, -10.8934],
            [33.2262, -11.5349],
            [33.398, -12.49],
            [33.0213, -12.6301],
            [32.9678, -13.2251],
            [32.6724, -13.6105],
            [33.202, -14.0134],
            [30.2317, -14.9904],
            [30.396, -15.643],
            [29.7299, -15.6447],
            [28.913, -15.988],
            [28.7604, -16.532],
            [27.9326, -16.8963],
            [27.0207, -17.9585],
            [25.8636, -17.9523],
            [25.2589, -17.7937],
            [24.275, -17.4812],
            [23.3806, -17.6403],
            [22.1506, -16.5975],
            [21.9797, -15.9554],
            [21.9788, -13.001],
            [23.8434, -13.001],
            [23.9915, -12.4222],
            [23.9665, -10.8714],
            [24.5184, -11.4384],
            [25.1846, -11.2432],
            [25.3494, -11.6234],
            [26.0257, -11.8905],
            [26.9494, -11.8989],
            [28.3575, -12.482],
            [29.014, -13.3688],
            [29.7954, -13.3929],
            [29.7954, -12.1556],
            [29.0641, -12.3488],
            [28.357, -11.4827],
            [28.6392, -10.6693],
            [28.6304, -9.8312],
            [28.4005, -9.2248],
            [28.9722, -8.465],
            [30.7512, -8.1939],
            [31.9425, -9.0538],
            [32.9198, -9.4072]
          ]
        ]
      },
      properties: { name: "Zambia", id: "ZM", Continent: "Africa" },
      id: "ZM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.2877, -22.4023],
            [31.5457, -23.482],
            [31.9861, -24.4607],
            [31.9483, -25.9579],
            [31.4152, -25.7465],
            [30.8034, -26.4135],
            [31.0636, -27.1123],
            [31.9584, -27.3056],
            [32.113, -26.8394],
            [32.886, -26.8496],
            [32.5345, -28.2],
            [32.2857, -28.6214],
            [31.3352, -29.3782],
            [30.2885, -30.9702],
            [28.8562, -32.2943],
            [27.8605, -33.0536],
            [26.4291, -33.7594],
            [24.8273, -34.169],
            [23.5858, -33.9848],
            [22.2455, -34.0692],
            [21.7889, -34.3725],
            [20.5299, -34.463],
            [19.6352, -34.7536],
            [18.8086, -34.1079],
            [17.8783, -32.9614],
            [18.2509, -32.652],
            [18.2105, -31.7423],
            [17.6775, -31.019],
            [16.9501, -29.4036],
            [16.4474, -28.6174],
            [17.056, -28.0312],
            [17.4481, -28.6983],
            [18.1028, -28.8719],
            [19.2459, -28.9017],
            [19.9806, -28.4513],
            [19.9806, -24.777],
            [20.3453, -25.0302],
            [20.8226, -26.1205],
            [20.74, -26.8491],
            [21.6465, -26.8543],
            [22.6402, -26.0713],
            [22.8788, -25.4577],
            [23.266, -25.2666],
            [24.7482, -25.8176],
            [25.5836, -25.6063],
            [25.9123, -24.7476],
            [26.8352, -24.2409],
            [27.1463, -23.5241],
            [28.2102, -22.6936],
            [29.3647, -22.194],
            [29.663, -22.1465],
            [31.2877, -22.4023]
          ],
          [
            [27.8301, -28.9092],
            [27.0518, -29.6642],
            [27.3884, -30.3159],
            [28.0181, -30.642],
            [28.3922, -30.1476],
            [29.098, -29.919],
            [29.3906, -29.2696],
            [28.6255, -28.5813],
            [27.8301, -28.9092]
          ]
        ]
      },
      properties: { name: "South Africa", id: "ZA", Continent: "Africa" },
      id: "ZA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.9029, -1.0022],
            [30.7423, -1.0074],
            [30.51, -1.0672],
            [30.1017, -1.3687],
            [29.5769, -1.388],
            [29.7176, 0.0982],
            [29.9426, 0.8193],
            [31.2526, 2.0445],
            [30.7283, 2.4555],
            [30.8386, 3.4904],
            [31.1523, 3.7856],
            [31.7984, 3.8024],
            [32.0994, 3.529],
            [32.8381, 3.7984],
            [33.4894, 3.7549],
            [33.9758, 4.2203],
            [34.3929, 3.6916],
            [34.9642, 2.0626],
            [34.7876, 1.2307],
            [34.4817, 1.0421],
            [33.943, 0.1738],
            [33.9029, -1.0022]
          ]
        ]
      },
      properties: { name: "Uganda", id: "UG", Continent: "Africa" },
      id: "UG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.9029, -1.0022],
            [35.1976, -1.6794],
            [37.6439, -3.0452],
            [37.7974, -3.6745],
            [39.2221, -4.6922],
            [38.819, -5.8779],
            [38.8741, -6.3314],
            [39.4721, -6.879],
            [39.2884, -7.5179],
            [39.3037, -8.4439],
            [39.7837, -9.9148],
            [40.4635, -10.464],
            [39.989, -10.8209],
            [38.4917, -11.4133],
            [37.9204, -11.2946],
            [37.5416, -11.6752],
            [36.5186, -11.7165],
            [35.785, -11.4533],
            [34.9598, -11.5785],
            [34.5978, -11.0375],
            [34.5244, -10.0299],
            [34.3208, -9.7319],
            [32.9198, -9.4072],
            [31.9425, -9.0538],
            [30.7512, -8.1939],
            [30.3135, -7.2038],
            [29.7097, -6.617],
            [29.4802, -6.0252],
            [29.5941, -5.6512],
            [29.3427, -4.9828],
            [29.4033, -4.4493],
            [30.1469, -4.0854],
            [30.7902, -3.2746],
            [30.5147, -2.9173],
            [30.5534, -2.4001],
            [30.8289, -2.3385],
            [30.8127, -1.5633],
            [30.51, -1.0672],
            [30.7423, -1.0074],
            [33.9029, -1.0022]
          ]
        ]
      },
      properties: { name: "Tanzania", id: "TZ", Continent: "Africa" },
      id: "TZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.5044, 33.182],
            [11.5049, 32.4139],
            [10.8264, 32.0807],
            [10.1149, 31.4637],
            [10.2164, 30.783],
            [9.519, 30.2293],
            [9.0439, 32.0724],
            [8.3333, 32.5439],
            [7.7629, 33.233],
            [7.5138, 34.0807],
            [8.2459, 34.7337],
            [8.3944, 35.2039],
            [8.2305, 36.5456],
            [8.5763, 36.9371],
            [9.6877, 37.3405],
            [10.5715, 36.8796],
            [10.5908, 35.8872],
            [11.1199, 35.2404],
            [10.1184, 34.2802],
            [10.1588, 33.85],
            [11.5044, 33.182]
          ]
        ]
      },
      properties: { name: "Tunisia", id: "TN", Continent: "Africa" },
      id: "TN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.9008, 10.9932],
            [0.78, 10.3599],
            [1.3298, 9.9969],
            [1.6, 9.0499],
            [1.6225, 6.2167],
            [1.1874, 6.0897],
            [0.5256, 6.8508],
            [0.6345, 7.3536],
            [0.4153, 8.6526],
            [0.5291, 9.3584],
            [0.2619, 9.4955],
            [0.3626, 10.2364],
            [-0.0578, 10.6306],
            [-0.0686, 11.1158],
            [0.9008, 10.9932]
          ]
        ]
      },
      properties: { name: "Togo", id: "TG", Continent: "Africa" },
      id: "TG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.8599, 10.9193],
            [22.4938, 10.9962],
            [21.7257, 10.3665],
            [20.7733, 9.4059],
            [20.3423, 9.1272],
            [19.1457, 9.0161],
            [19.1088, 8.6562],
            [18.4552, 8.0321],
            [17.6497, 7.9838],
            [16.7849, 7.5509],
            [16.3788, 7.6836],
            [15.4802, 7.5237],
            [15.1163, 8.5573],
            [14.3323, 9.2033],
            [13.9772, 9.6919],
            [14.2431, 9.9797],
            [15.5413, 9.9604],
            [15.1321, 10.6482],
            [15.0812, 11.8452],
            [14.516, 12.9794],
            [14.0638, 13.0787],
            [13.6063, 13.7046],
            [13.4481, 14.3805],
            [14.3679, 15.7498],
            [15.4745, 16.9086],
            [15.735, 19.9039],
            [15.9491, 20.3034],
            [15.1822, 21.5233],
            [14.9792, 22.9959],
            [15.9842, 23.445],
            [19.1896, 21.8621],
            [22.0641, 20.4427],
            [23.9805, 19.497],
            [23.9836, 15.7801],
            [23.1051, 15.7023],
            [22.5097, 14.1273],
            [22.1076, 13.7301],
            [22.2284, 13.3293],
            [21.8254, 12.7905],
            [22.3523, 12.6604],
            [22.5562, 11.6695],
            [22.9227, 11.3447],
            [22.8599, 10.9193]
          ]
        ]
      },
      properties: { name: "Chad", id: "TD", Continent: "Africa" },
      id: "TD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[55.5403, -4.6931], [55.3834, -4.6091], [55.4559, -4.5586], [55.5403, -4.6931]]]
      },
      properties: { name: "Seychelles", id: "SC", Continent: "Africa" },
      id: "SC"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.9483, -25.9579],
            [32.113, -26.8394],
            [31.9584, -27.3056],
            [31.0636, -27.1123],
            [30.8034, -26.4135],
            [31.4152, -25.7465],
            [31.9483, -25.9579]
          ]
        ]
      },
      properties: { name: "Eswatini", id: "SZ", Continent: "Africa" },
      id: "SZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[6.6603, 0.1207], [6.5241, 0.3403], [6.6867, 0.404], [6.75, 0.2437], [6.6603, 0.1207]]]
      },
      properties: { name: "Sao Tome and Principe", id: "ST", Continent: "Africa" },
      id: "ST"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [41.5327, -1.6956],
            [40.9791, -0.8703],
            [40.9644, 2.8145],
            [41.8839, 3.9781],
            [42.7918, 4.2923],
            [43.1257, 4.6448],
            [43.9889, 4.9506],
            [44.9406, 4.912],
            [45.9348, 5.997],
            [47.9781, 7.9969],
            [46.9784, 7.9969],
            [44.023, 8.9857],
            [43.483, 9.3794],
            [42.6594, 10.6214],
            [42.9226, 10.9993],
            [43.2462, 11.4999],
            [43.8531, 10.784],
            [44.3866, 10.4302],
            [44.9428, 10.4364],
            [45.8169, 10.8362],
            [46.5654, 10.7457],
            [47.4051, 11.1738],
            [48.9032, 11.2546],
            [50.11, 11.5292],
            [50.7925, 11.9837],
            [51.2547, 11.8307],
            [51.0842, 11.3355],
            [51.1409, 10.6565],
            [50.8983, 10.2531],
            [50.8249, 9.4282],
            [49.3484, 6.9906],
            [49.0491, 6.1737],
            [47.9755, 4.4967],
            [46.0512, 2.4752],
            [44.3329, 1.3911],
            [43.4676, 0.6216],
            [41.9796, -0.9727],
            [41.5327, -1.6956]
          ]
        ]
      },
      properties: { name: "Somalia", id: "SO", Continent: "Africa" },
      id: "SO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-10.2832, 8.4852],
            [-10.6475, 7.7597],
            [-11.5076, 6.9067],
            [-12.4855, 7.3861],
            [-12.8808, 7.8568],
            [-13.2928, 9.0494],
            [-12.756, 9.3733],
            [-12.5016, 9.862],
            [-11.2056, 9.9776],
            [-10.6906, 9.3144],
            [-10.2832, 8.4852]
          ]
        ]
      },
      properties: { name: "Sierra Leone", id: "SL", Continent: "Africa" },
      id: "SL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-5.6923, -15.9977], [-5.7751, -15.9567], [-5.7078, -15.9062], [-5.6923, -15.9977]]]
      },
      properties: { name: "Saint Helena", id: "SH", Continent: "Africa" },
      id: "SH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-12.2807, 14.8089],
            [-12.0193, 14.2064],
            [-12.0544, 13.6329],
            [-11.3902, 12.9416],
            [-11.3894, 12.4043],
            [-12.2911, 12.3277],
            [-13.0828, 12.6332],
            [-13.7294, 12.6736],
            [-15.1963, 12.6802],
            [-15.5749, 12.4904],
            [-16.712, 12.355],
            [-16.7634, 13.0638],
            [-15.8344, 13.1561],
            [-15.5097, 13.5863],
            [-16.5624, 13.5873],
            [-17.1679, 14.6406],
            [-17.147, 14.9223],
            [-16.5353, 15.8381],
            [-16.1684, 16.5474],
            [-15.1128, 16.6449],
            [-14.3, 16.5804],
            [-13.8685, 16.1479],
            [-13.4095, 16.0592],
            [-12.8584, 15.2427],
            [-12.2807, 14.8089]
          ]
        ]
      },
      properties: { name: "Senegal", id: "SN", Continent: "Africa" },
      id: "SN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.0779, 9.4612],
            [34.0945, 8.5823],
            [33.234, 8.3964],
            [33.0126, 7.9517],
            [33.666, 7.6713],
            [34.7107, 6.6605],
            [35.2684, 5.4925],
            [33.9758, 4.2203],
            [33.4894, 3.7549],
            [32.8381, 3.7984],
            [32.0994, 3.529],
            [31.7984, 3.8024],
            [31.1523, 3.7856],
            [30.8386, 3.4904],
            [30.1948, 3.9821],
            [29.6767, 4.5868],
            [29.1511, 4.3882],
            [28.1923, 4.3503],
            [27.4034, 5.1093],
            [27.0835, 5.7768],
            [26.5139, 6.0691],
            [26.3619, 6.6355],
            [25.381, 7.3334],
            [24.8537, 8.1376],
            [24.2916, 8.2914],
            [24.1471, 8.6654],
            [24.532, 8.8869],
            [24.7851, 9.7745],
            [25.1037, 10.312],
            [25.7981, 10.4205],
            [26.5513, 9.5258],
            [27.886, 9.5996],
            [28.049, 9.3285],
            [28.8444, 9.3259],
            [30.0033, 10.2773],
            [30.7397, 9.7429],
            [31.2248, 9.7991],
            [32.4039, 11.0577],
            [32.3354, 11.716],
            [33.199, 12.2174],
            [33.0732, 11.5916],
            [33.172, 10.8503],
            [33.892, 10.1991],
            [34.0779, 9.4612]
          ]
        ]
      },
      properties: { name: "South Sudan", id: "SS", Continent: "Africa" },
      id: "SS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.8714, 21.997],
            [37.1505, 21.1036],
            [37.2484, 19.5822],
            [37.4712, 18.8202],
            [38.6094, 18.0051],
            [38.2536, 17.5845],
            [37.4111, 17.0615],
            [37.009, 17.0589],
            [36.9135, 16.2965],
            [36.4267, 15.1319],
            [36.5243, 14.2565],
            [36.1076, 12.7268],
            [35.5961, 12.5374],
            [35.1123, 11.8167],
            [34.9312, 10.8648],
            [34.2756, 10.5282],
            [34.0779, 9.4612],
            [33.892, 10.1991],
            [33.172, 10.8503],
            [33.0732, 11.5916],
            [33.199, 12.2174],
            [32.3354, 11.716],
            [32.4039, 11.0577],
            [31.2248, 9.7991],
            [30.7397, 9.7429],
            [30.0033, 10.2773],
            [28.8444, 9.3259],
            [28.049, 9.3285],
            [27.886, 9.5996],
            [26.5513, 9.5258],
            [25.7981, 10.4205],
            [25.1037, 10.312],
            [24.7851, 9.7745],
            [24.532, 8.8869],
            [24.1471, 8.6654],
            [23.5376, 8.8161],
            [23.6461, 9.8229],
            [22.8599, 10.9193],
            [22.9227, 11.3447],
            [22.5562, 11.6695],
            [22.3523, 12.6604],
            [21.8254, 12.7905],
            [22.2284, 13.3293],
            [22.1076, 13.7301],
            [22.5097, 14.1273],
            [23.1051, 15.7023],
            [23.9836, 15.7801],
            [23.9805, 19.497],
            [23.9805, 19.9958],
            [24.9671, 19.9953],
            [24.9803, 21.9957],
            [28.8005, 21.9949],
            [31.4345, 21.9957],
            [33.2622, 21.9957],
            [36.8714, 21.997]
          ]
        ]
      },
      properties: { name: "Sudan", id: "SD", Continent: "Africa" },
      id: "SD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.6834, 27.6563],
            [-8.6834, 27.2859],
            [-8.682, 25.9957],
            [-12.0164, 25.9957],
            [-12.0235, 23.4675],
            [-13.0316, 22.9999],
            [-13.0162, 21.3339],
            [-16.9647, 21.3291],
            [-17.0482, 20.8061],
            [-16.9308, 21.8999],
            [-16.5144, 22.3332],
            [-16.2104, 23.0983],
            [-15.5863, 24.073],
            [-15.0388, 24.5489],
            [-14.4139, 26.2536],
            [-13.5758, 26.7348],
            [-13.1774, 27.6519],
            [-8.6834, 27.6563]
          ]
        ]
      },
      properties: { name: "Western Sahara", id: "EH", Continent: "Africa" },
      id: "EH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.51, -1.0672],
            [30.8127, -1.5633],
            [30.8289, -2.3385],
            [30.5534, -2.4001],
            [29.9303, -2.3394],
            [29.8683, -2.7165],
            [29.0145, -2.7204],
            [29.1296, -1.8601],
            [29.5769, -1.388],
            [30.1017, -1.3687],
            [30.51, -1.0672]
          ]
        ]
      },
      properties: { name: "Rwanda", id: "RW", Continent: "Africa" },
      id: "RW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[55.7974, -21.3397], [55.3628, -21.2738], [55.3113, -20.9042], [55.662, -20.9064], [55.7974, -21.3397]]
        ]
      },
      properties: { name: "Reunion", id: "RE", Continent: "Africa" },
      id: "RE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-17.1908, 32.8687], [-16.6933, 32.7579], [-16.8374, 32.6485], [-17.171, 32.7219], [-17.1908, 32.8687]]
        ]
      },
      properties: { name: "Madeira", id: "PT", Continent: "Africa" },
      id: "PT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.6063, 13.7046],
            [14.0638, 13.0787],
            [14.1974, 12.384],
            [14.6272, 12.1089],
            [14.5595, 11.4919],
            [13.892, 11.14],
            [12.9296, 9.4261],
            [12.8065, 8.8864],
            [12.4035, 8.5959],
            [11.5519, 6.697],
            [11.1533, 6.4377],
            [10.6062, 7.0631],
            [10.1439, 6.9963],
            [9.0602, 6.0093],
            [8.5561, 4.7551],
            [8.2934, 4.5578],
            [7.1547, 4.5147],
            [6.0763, 4.2906],
            [5.5876, 4.6474],
            [4.8612, 6.0264],
            [4.126, 6.4114],
            [2.7066, 6.3692],
            [2.686, 7.8739],
            [2.7747, 9.0486],
            [3.045, 9.0842],
            [3.8342, 10.6073],
            [3.4875, 11.3953],
            [3.5956, 11.6962],
            [3.6466, 12.5299],
            [3.948, 12.7751],
            [4.1476, 13.4576],
            [5.4919, 13.8729],
            [6.3861, 13.6039],
            [6.9372, 13.008],
            [7.7884, 13.3376],
            [8.7504, 12.9083],
            [9.6161, 12.8103],
            [10.1847, 13.2699],
            [11.4121, 13.3539],
            [12.4633, 13.0937],
            [12.6549, 13.3266],
            [13.6063, 13.7046]
          ]
        ]
      },
      properties: { name: "Nigeria", id: "NG", Continent: "Africa" },
      id: "NG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.9792, 22.9959],
            [15.1822, 21.5233],
            [15.9491, 20.3034],
            [15.735, 19.9039],
            [15.4745, 16.9086],
            [14.3679, 15.7498],
            [13.4481, 14.3805],
            [13.6063, 13.7046],
            [12.6549, 13.3266],
            [12.4633, 13.0937],
            [11.4121, 13.3539],
            [10.1847, 13.2699],
            [9.6161, 12.8103],
            [8.7504, 12.9083],
            [7.7884, 13.3376],
            [6.9372, 13.008],
            [6.3861, 13.6039],
            [5.4919, 13.8729],
            [4.1476, 13.4576],
            [3.948, 12.7751],
            [3.6466, 12.5299],
            [3.5956, 11.6962],
            [2.8784, 12.3678],
            [2.3889, 11.8971],
            [1.9564, 12.7074],
            [1.5653, 12.6354],
            [0.987, 13.0418],
            [0.9778, 13.5516],
            [0.6183, 13.7037],
            [0.1639, 14.4973],
            [0.2175, 14.9113],
            [0.9602, 14.9869],
            [1.3003, 15.2721],
            [3.5042, 15.356],
            [3.8769, 15.7555],
            [4.2346, 16.9965],
            [4.2275, 19.1428],
            [5.8368, 19.479],
            [7.4817, 20.8729],
            [10.4146, 22.6017],
            [11.9676, 23.518],
            [13.4815, 23.18],
            [14.2308, 22.6184],
            [14.9792, 22.9959]
          ]
        ]
      },
      properties: { name: "Niger", id: "NE", Continent: "Africa" },
      id: "NE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.3806, -17.6403],
            [24.275, -17.4812],
            [25.2589, -17.7937],
            [24.3589, -17.9782],
            [23.5806, -18.4529],
            [23.2985, -18.0274],
            [20.9738, -18.3188],
            [20.9707, -22.0001],
            [19.9775, -22.0001],
            [19.9806, -24.777],
            [19.9806, -28.4513],
            [19.2459, -28.9017],
            [18.1028, -28.8719],
            [17.4481, -28.6983],
            [17.056, -28.0312],
            [16.4474, -28.6174],
            [15.7193, -27.9658],
            [15.3413, -27.3865],
            [14.9677, -26.3182],
            [14.8372, -25.0332],
            [14.5015, -24.2022],
            [14.5261, -22.7028],
            [14.3218, -22.1899],
            [13.4503, -20.9169],
            [13.1687, -20.1844],
            [12.4585, -18.927],
            [11.776, -18.0019],
            [11.7431, -17.2497],
            [12.5482, -17.2128],
            [13.101, -16.9675],
            [13.9381, -17.389],
            [16.9136, -17.3916],
            [18.3964, -17.3995],
            [18.8257, -17.7659],
            [21.4167, -18.0006],
            [23.3806, -17.6403]
          ]
        ]
      },
      properties: { name: "Namibia", id: "NA", Continent: "Africa" },
      id: "NA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[45.1802, -12.9764], [45.0695, -12.8959], [45.1349, -12.7092], [45.2232, -12.7519], [45.1802, -12.9764]]
        ]
      },
      properties: { name: "Mayotte", id: "YT", Continent: "Africa" },
      id: "YT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.9598, -11.5785],
            [34.6597, -11.5886],
            [34.3608, -12.2104],
            [34.5639, -13.3601],
            [35.2476, -13.8966],
            [35.8474, -14.671],
            [35.7557, -16.0583],
            [35.1672, -16.5601],
            [34.3955, -16.1994],
            [34.2478, -15.8878],
            [34.541, -15.2971],
            [34.3323, -14.4085],
            [33.6362, -14.5685],
            [33.202, -14.0134],
            [32.6724, -13.6105],
            [32.9678, -13.2251],
            [33.0213, -12.6301],
            [33.398, -12.49],
            [33.2262, -11.5349],
            [33.2614, -10.8934],
            [33.6591, -10.5906],
            [33.351, -9.8619],
            [32.9198, -9.4072],
            [34.3208, -9.7319],
            [34.5244, -10.0299],
            [34.5978, -11.0375],
            [34.9598, -11.5785]
          ]
        ]
      },
      properties: { name: "Malawi", id: "MW", Continent: "Africa" },
      id: "MW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[57.6514, -20.4849], [57.386, -20.2282], [57.5758, -19.9972], [57.7921, -20.2125], [57.6514, -20.4849]]
        ]
      },
      properties: { name: "Mauritius", id: "MU", Continent: "Africa" },
      id: "MU"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-12.2807, 14.8089],
            [-12.8584, 15.2427],
            [-13.4095, 16.0592],
            [-13.8685, 16.1479],
            [-14.3, 16.5804],
            [-15.1128, 16.6449],
            [-16.1684, 16.5474],
            [-16.5353, 15.8381],
            [-16.5357, 16.2868],
            [-16.079, 17.5459],
            [-16.0849, 18.521],
            [-16.5147, 19.3621],
            [-16.2106, 20.2278],
            [-16.4298, 20.6523],
            [-17.0482, 20.8061],
            [-16.9647, 21.3291],
            [-13.0162, 21.3339],
            [-13.0316, 22.9999],
            [-12.0235, 23.4675],
            [-12.0164, 25.9957],
            [-8.682, 25.9957],
            [-8.6834, 27.2859],
            [-6.9908, 26.2905],
            [-4.8225, 24.9959],
            [-6.594, 24.9946],
            [-6.1688, 21.2855],
            [-5.9696, 19.5449],
            [-5.6286, 16.5685],
            [-5.36, 16.2829],
            [-5.5125, 15.4967],
            [-9.1769, 15.4962],
            [-10.6965, 15.4228],
            [-10.8956, 15.1504],
            [-11.5027, 15.6368],
            [-11.8727, 14.9952],
            [-12.2807, 14.8089]
          ]
        ]
      },
      properties: { name: "Mauritania", id: "MR", Continent: "Africa" },
      id: "MR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.886, -26.8496],
            [32.113, -26.8394],
            [31.9483, -25.9579],
            [31.9861, -24.4607],
            [31.5457, -23.482],
            [31.2877, -22.4023],
            [32.3714, -21.3352],
            [32.4777, -20.7129],
            [33.0069, -19.8737],
            [32.7164, -19.0017],
            [32.9932, -18.3597],
            [32.9479, -16.7121],
            [31.9399, -16.4287],
            [31.2363, -16.024],
            [30.4379, -15.9954],
            [30.396, -15.643],
            [30.2317, -14.9904],
            [33.202, -14.0134],
            [33.6362, -14.5685],
            [34.3323, -14.4085],
            [34.541, -15.2971],
            [34.2478, -15.8878],
            [34.3955, -16.1994],
            [35.1672, -16.5601],
            [35.7557, -16.0583],
            [35.8474, -14.671],
            [35.2476, -13.8966],
            [34.5639, -13.3601],
            [34.3608, -12.2104],
            [34.6597, -11.5886],
            [34.9598, -11.5785],
            [35.785, -11.4533],
            [36.5186, -11.7165],
            [37.5416, -11.6752],
            [37.9204, -11.2946],
            [38.4917, -11.4133],
            [39.989, -10.8209],
            [40.4635, -10.464],
            [40.4029, -11.332],
            [40.5803, -12.6358],
            [40.6401, -14.3901],
            [40.8349, -14.7917],
            [40.5589, -15.4733],
            [39.8448, -16.4358],
            [39.0841, -16.9727],
            [38.145, -17.2426],
            [37.2445, -17.74],
            [36.5405, -18.5179],
            [34.948, -19.813],
            [34.6984, -20.404],
            [35.2679, -21.6512],
            [35.3157, -22.3969],
            [35.5754, -22.9634],
            [35.1559, -24.541],
            [33.3475, -25.2608],
            [32.792, -25.6445],
            [32.886, -26.8496]
          ]
        ]
      },
      properties: { name: "Mozambique", id: "MZ", Continent: "Africa" },
      id: "MZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.2275, 19.1428],
            [4.2346, 16.9965],
            [3.8769, 15.7555],
            [3.5042, 15.356],
            [1.3003, 15.2721],
            [0.9602, 14.9869],
            [0.2175, 14.9113],
            [-0.7605, 15.0475],
            [-1.7677, 14.4864],
            [-2.4572, 14.2741],
            [-3.5758, 13.1939],
            [-4.3286, 13.1188],
            [-4.4288, 12.3379],
            [-5.2882, 11.8276],
            [-5.5235, 10.4258],
            [-6.0346, 10.1947],
            [-6.2611, 10.7242],
            [-7.1049, 10.2034],
            [-7.6612, 10.4271],
            [-7.9908, 10.1626],
            [-8.2316, 10.4381],
            [-8.4076, 11.3864],
            [-8.822, 11.6734],
            [-9.0431, 12.4024],
            [-9.8207, 12.0425],
            [-10.2749, 12.2126],
            [-10.6774, 11.8997],
            [-11.3894, 12.4043],
            [-11.3902, 12.9416],
            [-12.0544, 13.6329],
            [-12.0193, 14.2064],
            [-12.2807, 14.8089],
            [-11.8727, 14.9952],
            [-11.5027, 15.6368],
            [-10.8956, 15.1504],
            [-10.6965, 15.4228],
            [-9.1769, 15.4962],
            [-5.5125, 15.4967],
            [-5.36, 16.2829],
            [-5.6286, 16.5685],
            [-5.9696, 19.5449],
            [-6.1688, 21.2855],
            [-6.594, 24.9946],
            [-4.8225, 24.9959],
            [-3.2579, 23.9811],
            [-1.2931, 22.6962],
            [1.1456, 21.1023],
            [1.6853, 20.3785],
            [3.2036, 19.7897],
            [3.4005, 18.9885],
            [4.2275, 19.1428]
          ]
        ]
      },
      properties: { name: "Mali", id: "ML", Continent: "Africa" },
      id: "ML"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [49.5382, -12.4324],
            [49.9373, -13.0727],
            [50.2352, -14.7319],
            [50.4831, -15.3854],
            [49.449, -17.2404],
            [49.4781, -17.8983],
            [48.607, -20.4577],
            [47.9083, -22.466],
            [47.5581, -23.8743],
            [47.1775, -24.7871],
            [46.7284, -25.1496],
            [45.1155, -25.543],
            [44.0353, -24.9959],
            [43.6873, -24.3577],
            [43.7225, -23.5295],
            [43.3982, -22.8866],
            [43.2668, -22.0493],
            [43.5019, -21.3563],
            [43.8557, -21.0769],
            [44.4045, -19.9219],
            [44.4485, -19.4285],
            [44.0398, -18.2886],
            [43.9796, -17.3916],
            [44.4216, -16.7026],
            [44.4762, -16.2173],
            [46.3316, -15.7138],
            [47.8116, -14.5447],
            [47.9953, -13.9607],
            [48.7965, -13.2673],
            [48.8997, -12.4587],
            [49.5382, -12.4324]
          ]
        ]
      },
      properties: { name: "Madagascar", id: "MG", Continent: "Africa" },
      id: "MG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.2197, 35.1042],
            [-1.7956, 34.7522],
            [-1.6792, 33.3187],
            [-1.0657, 32.4683],
            [-1.2753, 32.0891],
            [-2.4484, 32.13],
            [-3.8268, 31.6615],
            [-3.9852, 30.9136],
            [-4.9682, 30.4653],
            [-5.4488, 29.9569],
            [-6.5008, 29.8092],
            [-7.6852, 29.3495],
            [-8.6599, 28.7185],
            [-8.6834, 27.6563],
            [-13.1774, 27.6519],
            [-12.9489, 27.9143],
            [-11.4302, 28.3823],
            [-10.4865, 29.0648],
            [-9.6671, 30.1094],
            [-9.8754, 30.718],
            [-9.8086, 31.4246],
            [-9.2459, 32.5725],
            [-8.5962, 33.1872],
            [-6.901, 33.9691],
            [-6.3532, 34.7759],
            [-5.9249, 35.7858],
            [-5.3974, 35.9299],
            [-5.1053, 35.4676],
            [-4.3299, 35.1613],
            [-3.6932, 35.2799],
            [-2.2197, 35.1042]
          ]
        ]
      },
      properties: { name: "Morocco", id: "MA", Continent: "Africa" },
      id: "MA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.8301, -28.9092],
            [28.6255, -28.5813],
            [29.3906, -29.2696],
            [29.098, -29.919],
            [28.3922, -30.1476],
            [28.0181, -30.642],
            [27.3884, -30.3159],
            [27.0518, -29.6642],
            [27.8301, -28.9092]
          ]
        ]
      },
      properties: { name: "Lesotho", id: "LS", Continent: "Africa" },
      id: "LS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.1503, 31.6549],
            [24.8528, 31.335],
            [24.9736, 30.7764],
            [24.7113, 30.1317],
            [24.9803, 29.1817],
            [24.9803, 27.1606],
            [24.9803, 24.0172],
            [24.9803, 21.9957],
            [24.9671, 19.9953],
            [23.9805, 19.9958],
            [23.9805, 19.497],
            [22.0641, 20.4427],
            [19.1896, 21.8621],
            [15.9842, 23.445],
            [14.9792, 22.9959],
            [14.2308, 22.6184],
            [13.4815, 23.18],
            [11.9676, 23.518],
            [11.5075, 24.3143],
            [10.2555, 24.5911],
            [10.001, 25.332],
            [9.4482, 26.0672],
            [9.8592, 26.5519],
            [9.7475, 27.3307],
            [9.9163, 27.7856],
            [9.8424, 28.9668],
            [9.519, 30.2293],
            [10.2164, 30.783],
            [10.1149, 31.4637],
            [10.8264, 32.0807],
            [11.5049, 32.4139],
            [11.5044, 33.182],
            [12.2796, 32.8586],
            [13.2833, 32.9148],
            [15.1765, 32.391],
            [15.4965, 31.6567],
            [16.1231, 31.2642],
            [17.3492, 31.0814],
            [18.1902, 30.7774],
            [18.9365, 30.2904],
            [19.7134, 30.4886],
            [20.1511, 31.0783],
            [19.9261, 31.8175],
            [20.6209, 32.5799],
            [21.6356, 32.9372],
            [23.0907, 32.6186],
            [23.2866, 32.2139],
            [24.6841, 32.0162],
            [25.1503, 31.6549]
          ]
        ]
      },
      properties: { name: "Libya", id: "LY", Continent: "Africa" },
      id: "LY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.4863, 7.5583],
            [-8.2966, 7.0741],
            [-8.6036, 6.5077],
            [-7.8886, 6.2348],
            [-7.4542, 5.8414],
            [-7.545, 4.3513],
            [-9.1323, 5.0548],
            [-10.2764, 6.0774],
            [-11.5076, 6.9067],
            [-10.6475, 7.7597],
            [-10.2832, 8.4852],
            [-9.5181, 8.3463],
            [-9.1176, 7.2156],
            [-8.4863, 7.5583]
          ]
        ]
      },
      properties: { name: "Liberia", id: "LR", Continent: "Africa" },
      id: "LR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [41.8839, 3.9781],
            [40.9644, 2.8145],
            [40.9791, -0.8703],
            [41.5327, -1.6956],
            [40.9957, -1.9505],
            [40.6442, -2.5393],
            [40.2223, -2.6883],
            [40.1155, -3.2505],
            [39.4909, -4.4787],
            [39.2221, -4.6922],
            [37.7974, -3.6745],
            [37.6439, -3.0452],
            [35.1976, -1.6794],
            [33.9029, -1.0022],
            [33.943, 0.1738],
            [34.4817, 1.0421],
            [34.7876, 1.2307],
            [34.9642, 2.0626],
            [34.3929, 3.6916],
            [33.9758, 4.2203],
            [35.2684, 5.4925],
            [35.7447, 5.3444],
            [35.7632, 4.8078],
            [36.0817, 4.4497],
            [36.9057, 4.4114],
            [38.0865, 3.649],
            [39.4946, 3.4561],
            [39.8426, 3.8516],
            [40.7654, 4.273],
            [41.2207, 3.9439],
            [41.8839, 3.9781]
          ]
        ]
      },
      properties: { name: "Kenya", id: "KE", Continent: "Africa" },
      id: "KE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[9.5906, 1.0316], [9.8007, 2.3043], [9.98, 2.1676], [11.3287, 2.1672], [11.3353, 1], [9.5906, 1.0316]]
        ]
      },
      properties: { name: "Equatorial Guinea", id: "GQ", Continent: "Africa" },
      id: "GQ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-15.0431, 10.9404],
            [-15.3932, 11.2173],
            [-15.5017, 11.724],
            [-16.1383, 11.9173],
            [-16.712, 12.355],
            [-15.5749, 12.4904],
            [-15.1963, 12.6802],
            [-13.7294, 12.6736],
            [-13.7327, 11.7358],
            [-14.683, 11.5086],
            [-15.0431, 10.9404]
          ]
        ]
      },
      properties: { name: "Guinea-Bissau", id: "GW", Continent: "Africa" },
      id: "GW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-16.7634, 13.0638], [-16.5624, 13.5873], [-15.5097, 13.5863], [-15.8344, 13.1561], [-16.7634, 13.0638]]
        ]
      },
      properties: { name: "Gambia", id: "GM", Continent: "Africa" },
      id: "GM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-11.3894, 12.4043],
            [-10.6774, 11.8997],
            [-10.2749, 12.2126],
            [-9.8207, 12.0425],
            [-9.0431, 12.4024],
            [-8.822, 11.6734],
            [-8.4076, 11.3864],
            [-8.2316, 10.4381],
            [-7.9908, 10.1626],
            [-7.8236, 8.4676],
            [-8.2321, 7.5566],
            [-8.4863, 7.5583],
            [-9.1176, 7.2156],
            [-9.5181, 8.3463],
            [-10.2832, 8.4852],
            [-10.6906, 9.3144],
            [-11.2056, 9.9776],
            [-12.5016, 9.862],
            [-12.756, 9.3733],
            [-13.2928, 9.0494],
            [-13.7004, 9.851],
            [-14.427, 10.2483],
            [-14.7757, 10.9316],
            [-15.0431, 10.9404],
            [-14.683, 11.5086],
            [-13.7327, 11.7358],
            [-13.7294, 12.6736],
            [-13.0828, 12.6332],
            [-12.2911, 12.3277],
            [-11.3894, 12.4043]
          ]
        ]
      },
      properties: { name: "Guinea", id: "GN", Continent: "Africa" },
      id: "GN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.0686, 11.1158],
            [-0.0578, 10.6306],
            [0.3626, 10.2364],
            [0.2619, 9.4955],
            [0.5291, 9.3584],
            [0.4153, 8.6526],
            [0.6345, 7.3536],
            [0.5256, 6.8508],
            [1.1874, 6.0897],
            [0.9501, 5.8102],
            [0.2597, 5.7575],
            [-0.7976, 5.2266],
            [-2.0017, 4.7621],
            [-3.114, 5.0887],
            [-3.0867, 5.1286],
            [-3.019, 5.1308],
            [-2.7951, 5.1844],
            [-3.2008, 6.3481],
            [-3.2357, 6.8073],
            [-2.7899, 7.9319],
            [-2.5057, 8.2087],
            [-2.6958, 9.4814],
            [-2.8299, 10.9984],
            [-0.4917, 11.0072],
            [-0.0686, 11.1158]
          ]
        ]
      },
      properties: { name: "Ghana", id: "GH", Continent: "Africa" },
      id: "GH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.2939, 2.1614],
            [13.2741, 1.2408],
            [14.1811, 1.37],
            [14.4343, 0.8114],
            [13.8845, 0.1905],
            [13.8599, -0.2037],
            [14.4809, -0.6181],
            [14.3837, -1.8899],
            [14.0875, -2.4669],
            [12.992, -2.3135],
            [11.5752, -2.3975],
            [11.5379, -2.8368],
            [11.9343, -3.3189],
            [11.13, -3.9161],
            [10.3473, -3.0132],
            [9.6245, -2.367],
            [9.2659, -1.8253],
            [8.8216, -0.7082],
            [9.2971, -0.3514],
            [9.5906, 1.0316],
            [11.3353, 1],
            [11.3287, 2.1672],
            [11.3484, 2.2994],
            [13.2205, 2.2563],
            [13.2939, 2.1614]
          ]
        ]
      },
      properties: { name: "Gabon", id: "GA", Continent: "Africa" },
      id: "GA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.3783, 12.4666],
            [41.7665, 11.589],
            [41.7982, 10.9804],
            [42.9226, 10.9993],
            [42.6594, 10.6214],
            [43.483, 9.3794],
            [44.023, 8.9857],
            [46.9784, 7.9969],
            [47.9781, 7.9969],
            [45.9348, 5.997],
            [44.9406, 4.912],
            [43.9889, 4.9506],
            [43.1257, 4.6448],
            [42.7918, 4.2923],
            [41.8839, 3.9781],
            [41.2207, 3.9439],
            [40.7654, 4.273],
            [39.8426, 3.8516],
            [39.4946, 3.4561],
            [38.0865, 3.649],
            [36.9057, 4.4114],
            [36.0817, 4.4497],
            [35.7632, 4.8078],
            [35.7447, 5.3444],
            [35.2684, 5.4925],
            [34.7107, 6.6605],
            [33.666, 7.6713],
            [33.0126, 7.9517],
            [33.234, 8.3964],
            [34.0945, 8.5823],
            [34.0779, 9.4612],
            [34.2756, 10.5282],
            [34.9312, 10.8648],
            [35.1123, 11.8167],
            [35.5961, 12.5374],
            [36.1076, 12.7268],
            [36.5243, 14.2565],
            [37.2572, 14.4538],
            [37.5711, 14.1493],
            [37.8844, 14.8524],
            [38.5045, 14.4244],
            [39.074, 14.6283],
            [40.2213, 14.431],
            [40.7699, 14.1444],
            [42.3783, 12.4666]
          ]
        ]
      },
      properties: { name: "Ethiopia", id: "ET", Continent: "Africa" },
      id: "ET"
    },

    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.6094, 18.0051],
            [39.1425, 16.7293],
            [39.5064, 15.5318],
            [40.2042, 15.0141],
            [41.1764, 14.6204],
            [41.6583, 13.9831],
            [42.2451, 13.5877],
            [42.7961, 12.8643],
            [43.1165, 12.7083],
            [42.3783, 12.4666],
            [40.7699, 14.1444],
            [40.2213, 14.431],
            [39.074, 14.6283],
            [38.5045, 14.4244],
            [37.8844, 14.8524],
            [37.5711, 14.1493],
            [37.2572, 14.4538],
            [36.5243, 14.2565],
            [36.4267, 15.1319],
            [36.9135, 16.2965],
            [37.009, 17.0589],
            [37.4111, 17.0615],
            [38.2536, 17.5845],
            [38.6094, 18.0051]
          ]
        ]
      },
      properties: { name: "Eritrea", id: "ER", Continent: "Africa" },
      id: "ER"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.8714, 21.997],
            [33.2622, 21.9957],
            [31.4345, 21.9957],
            [28.8005, 21.9949],
            [24.9803, 21.9957],
            [24.9803, 24.0172],
            [24.9803, 27.1606],
            [24.9803, 29.1817],
            [24.7113, 30.1317],
            [24.9736, 30.7764],
            [24.8528, 31.335],
            [25.1503, 31.6549],
            [27.2479, 31.378],
            [29.0725, 30.8305],
            [30.3952, 31.4576],
            [31.1942, 31.5876],
            [32.102, 31.0924],
            [33.6664, 31.1302],
            [34.2127, 31.2924],
            [34.904, 29.4774],
            [34.4, 28.0162],
            [33.547, 27.8985],
            [33.9592, 26.6487],
            [35.1941, 24.4751],
            [35.6246, 24.066],
            [35.5227, 23.4424],
            [35.6976, 22.9462],
            [36.8714, 21.997]
          ]
        ]
      },
      properties: { name: "Egypt", id: "EG", Continent: "Africa" },
      id: "EG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.5763, 36.9371],
            [8.2305, 36.5456],
            [8.3944, 35.2039],
            [8.2459, 34.7337],
            [7.5138, 34.0807],
            [7.7629, 33.233],
            [8.3333, 32.5439],
            [9.0439, 32.0724],
            [9.519, 30.2293],
            [9.8424, 28.9668],
            [9.9163, 27.7856],
            [9.7475, 27.3307],
            [9.8592, 26.5519],
            [9.4482, 26.0672],
            [10.001, 25.332],
            [10.2555, 24.5911],
            [11.5075, 24.3143],
            [11.9676, 23.518],
            [10.4146, 22.6017],
            [7.4817, 20.8729],
            [5.8368, 19.479],
            [4.2275, 19.1428],
            [3.4005, 18.9885],
            [3.2036, 19.7897],
            [1.6853, 20.3785],
            [1.1456, 21.1023],
            [-1.2931, 22.6962],
            [-3.2579, 23.9811],
            [-4.8225, 24.9959],
            [-6.9908, 26.2905],
            [-8.6834, 27.2859],
            [-8.6834, 27.6563],
            [-8.6599, 28.7185],
            [-7.6852, 29.3495],
            [-6.5008, 29.8092],
            [-5.4488, 29.9569],
            [-4.9682, 30.4653],
            [-3.9852, 30.9136],
            [-3.8268, 31.6615],
            [-2.4484, 32.13],
            [-1.2753, 32.0891],
            [-1.0657, 32.4683],
            [-1.6792, 33.3187],
            [-1.7956, 34.7522],
            [-2.2197, 35.1042],
            [-1.9132, 35.0945],
            [-0.9176, 35.6684],
            [0.0479, 35.9004],
            [1.2573, 36.5196],
            [2.5932, 36.601],
            [3.7788, 36.8962],
            [4.7579, 36.8967],
            [5.1957, 36.677],
            [6.4867, 37.0857],
            [6.9275, 36.9195],
            [8.5763, 36.9371]
          ]
        ]
      },
      properties: { name: "Algeria", id: "DZ", Continent: "Africa" },
      id: "DZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.2462, 11.4999],
            [42.9226, 10.9993],
            [41.7982, 10.9804],
            [41.7665, 11.589],
            [42.3783, 12.4666],
            [43.1165, 12.7083],
            [43.4101, 12.1898],
            [42.7993, 11.7393],
            [43.2462, 11.4999]
          ]
        ]
      },
      properties: { name: "Djibouti", id: "DJ", Continent: "Africa" },
      id: "DJ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-23.4442, 15.0076], [-23.7055, 14.9609], [-23.7008, 15.2712], [-23.4442, 15.0076]]]
      },
      properties: { name: "Cape Verde", id: "CV", Continent: "Africa" },
      id: "CV"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[43.4658, -11.9015], [43.2268, -11.7517], [43.3792, -11.6141], [43.4658, -11.9015]]]
      },
      properties: { name: "Comoros", id: "KM", Continent: "Africa" },
      id: "KM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.6099, 3.4785],
            [18.072, 2.0133],
            [17.9024, 1.1178],
            [17.753, -0.5491],
            [16.8798, -1.2259],
            [16.2154, -2.1781],
            [16.1463, -3.464],
            [14.7793, -4.8456],
            [14.3583, -4.2993],
            [13.4148, -4.8373],
            [13.0728, -4.6352],
            [12.3846, -4.6188],
            [12.0181, -5.0042],
            [11.13, -3.9161],
            [11.9343, -3.3189],
            [11.5379, -2.8368],
            [11.5752, -2.3975],
            [12.992, -2.3135],
            [14.0875, -2.4669],
            [14.3837, -1.8899],
            [14.4809, -0.6181],
            [13.8599, -0.2037],
            [13.8845, 0.1905],
            [14.4343, 0.8114],
            [14.1811, 1.37],
            [13.2741, 1.2408],
            [13.2939, 2.1614],
            [14.4844, 2.1549],
            [15.7416, 1.9153],
            [16.1833, 2.2705],
            [16.6733, 3.5352],
            [17.4379, 3.6846],
            [18.6099, 3.4785]
          ]
        ]
      },
      properties: { name: "Republic of Congo", id: "CG", Continent: "Africa" },
      id: "CG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.4034, 5.1093],
            [28.1923, 4.3503],
            [29.1511, 4.3882],
            [29.6767, 4.5868],
            [30.1948, 3.9821],
            [30.8386, 3.4904],
            [30.7283, 2.4555],
            [31.2526, 2.0445],
            [29.9426, 0.8193],
            [29.7176, 0.0982],
            [29.5769, -1.388],
            [29.1296, -1.8601],
            [29.0145, -2.7204],
            [29.2245, -3.0536],
            [29.2232, -3.9109],
            [29.4033, -4.4493],
            [29.3427, -4.9828],
            [29.5941, -5.6512],
            [29.4802, -6.0252],
            [29.7097, -6.617],
            [30.3135, -7.2038],
            [30.7512, -8.1939],
            [28.9722, -8.465],
            [28.4005, -9.2248],
            [28.6304, -9.8312],
            [28.6392, -10.6693],
            [28.357, -11.4827],
            [29.0641, -12.3488],
            [29.7954, -12.1556],
            [29.7954, -13.3929],
            [29.014, -13.3688],
            [28.3575, -12.482],
            [26.9494, -11.8989],
            [26.0257, -11.8905],
            [25.3494, -11.6234],
            [25.1846, -11.2432],
            [24.5184, -11.4384],
            [23.9665, -10.8714],
            [23.8337, -11.0137],
            [22.5611, -11.056],
            [22.2746, -10.2592],
            [21.8131, -9.4692],
            [21.9058, -8.6935],
            [21.7511, -7.3052],
            [20.6077, -7.278],
            [20.482, -6.916],
            [19.5275, -7.1448],
            [19.3408, -7.9666],
            [17.5359, -8.076],
            [16.9849, -7.2573],
            [16.7177, -6.2413],
            [16.3151, -5.8661],
            [13.068, -5.8648],
            [12.4115, -5.9865],
            [12.2137, -5.7588],
            [12.5222, -5.1489],
            [13.0728, -4.6352],
            [13.4148, -4.8373],
            [14.3583, -4.2993],
            [14.7793, -4.8456],
            [16.1463, -3.464],
            [16.2154, -2.1781],
            [16.8798, -1.2259],
            [17.753, -0.5491],
            [17.9024, 1.1178],
            [18.072, 2.0133],
            [18.6099, 3.4785],
            [18.5677, 4.2576],
            [19.0687, 4.8913],
            [19.6862, 5.1216],
            [20.5581, 4.4625],
            [22.4222, 4.1351],
            [22.7557, 4.647],
            [23.4171, 4.6633],
            [24.3198, 4.9941],
            [25.2496, 5.0245],
            [25.5251, 5.3123],
            [26.822, 5.0622],
            [27.4034, 5.1093]
          ]
        ]
      },
      properties: { name: "Democratic Republic of Congo", id: "CD", Continent: "Africa" },
      id: "CD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [15.4802, 7.5237],
            [15.2459, 7.2635],
            [14.6171, 5.8647],
            [14.7089, 4.6654],
            [15.1286, 3.827],
            [16.0637, 2.9085],
            [16.1833, 2.2705],
            [15.7416, 1.9153],
            [14.4844, 2.1549],
            [13.2939, 2.1614],
            [13.2205, 2.2563],
            [11.3484, 2.2994],
            [11.3287, 2.1672],
            [9.98, 2.1676],
            [9.8007, 2.3043],
            [9.8762, 3.3098],
            [9.4254, 3.9223],
            [9, 4.0915],
            [8.5561, 4.7551],
            [9.0602, 6.0093],
            [10.1439, 6.9963],
            [10.6062, 7.0631],
            [11.1533, 6.4377],
            [11.5519, 6.697],
            [12.4035, 8.5959],
            [12.8065, 8.8864],
            [12.9296, 9.4261],
            [13.892, 11.14],
            [14.5595, 11.4919],
            [14.6272, 12.1089],
            [14.1974, 12.384],
            [14.0638, 13.0787],
            [14.516, 12.9794],
            [15.0812, 11.8452],
            [15.1321, 10.6482],
            [15.5413, 9.9604],
            [14.2431, 9.9797],
            [13.9772, 9.6919],
            [14.3323, 9.2033],
            [15.1163, 8.5573],
            [15.4802, 7.5237]
          ]
        ]
      },
      properties: { name: "Cameroon", id: "CM", Continent: "Africa" },
      id: "CM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-5.5235, 10.4258],
            [-4.6257, 9.7134],
            [-3.2236, 9.8954],
            [-2.6958, 9.4814],
            [-2.5057, 8.2087],
            [-2.7899, 7.9319],
            [-3.2357, 6.8073],
            [-3.2008, 6.3481],
            [-2.7951, 5.1844],
            [-3.019, 5.1308],
            [-4.0373, 5.2302],
            [-5.5646, 5.0895],
            [-7.545, 4.3513],
            [-7.4542, 5.8414],
            [-7.8886, 6.2348],
            [-8.6036, 6.5077],
            [-8.2966, 7.0741],
            [-8.4863, 7.5583],
            [-8.2321, 7.5566],
            [-7.8236, 8.4676],
            [-7.9908, 10.1626],
            [-7.6612, 10.4271],
            [-7.1049, 10.2034],
            [-6.2611, 10.7242],
            [-6.0346, 10.1947],
            [-5.5235, 10.4258]
          ]
        ]
      },
      properties: { name: "Côte d'Ivoire", id: "CI", Continent: "Africa" },
      id: "CI"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.8599, 10.9193],
            [23.6461, 9.8229],
            [23.5376, 8.8161],
            [24.1471, 8.6654],
            [24.2916, 8.2914],
            [24.8537, 8.1376],
            [25.381, 7.3334],
            [26.3619, 6.6355],
            [26.5139, 6.0691],
            [27.0835, 5.7768],
            [27.4034, 5.1093],
            [26.822, 5.0622],
            [25.5251, 5.3123],
            [25.2496, 5.0245],
            [24.3198, 4.9941],
            [23.4171, 4.6633],
            [22.7557, 4.647],
            [22.4222, 4.1351],
            [20.5581, 4.4625],
            [19.6862, 5.1216],
            [19.0687, 4.8913],
            [18.5677, 4.2576],
            [18.6099, 3.4785],
            [17.4379, 3.6846],
            [16.6733, 3.5352],
            [16.1833, 2.2705],
            [16.0637, 2.9085],
            [15.1286, 3.827],
            [14.7089, 4.6654],
            [14.6171, 5.8647],
            [15.2459, 7.2635],
            [15.4802, 7.5237],
            [16.3788, 7.6836],
            [16.7849, 7.5509],
            [17.6497, 7.9838],
            [18.4552, 8.0321],
            [19.1088, 8.6562],
            [19.1457, 9.0161],
            [20.3423, 9.1272],
            [20.7733, 9.4059],
            [21.7257, 10.3665],
            [22.4938, 10.9962],
            [22.8599, 10.9193]
          ]
        ]
      },
      properties: { name: "Central African Republic", id: "CF", Continent: "Africa" },
      id: "CF"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.2589, -17.7937],
            [26.1685, -19.5382],
            [27.6996, -20.5307],
            [27.6697, -21.0642],
            [28.0455, -21.573],
            [29.0373, -21.8112],
            [29.3647, -22.194],
            [28.2102, -22.6936],
            [27.1463, -23.5241],
            [26.8352, -24.2409],
            [25.9123, -24.7476],
            [25.5836, -25.6063],
            [24.7482, -25.8176],
            [23.266, -25.2666],
            [22.8788, -25.4577],
            [22.6402, -26.0713],
            [21.6465, -26.8543],
            [20.74, -26.8491],
            [20.8226, -26.1205],
            [20.3453, -25.0302],
            [19.9806, -24.777],
            [19.9775, -22.0001],
            [20.9707, -22.0001],
            [20.9738, -18.3188],
            [23.2985, -18.0274],
            [23.5806, -18.4529],
            [24.3589, -17.9782],
            [25.2589, -17.7937]
          ]
        ]
      },
      properties: { name: "Botswana", id: "BW", Continent: "Africa" },
      id: "BW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.2175, 14.9113],
            [0.1639, 14.4973],
            [0.6183, 13.7037],
            [0.9778, 13.5516],
            [0.987, 13.0418],
            [1.5653, 12.6354],
            [1.9564, 12.7074],
            [2.3889, 11.8971],
            [1.9802, 11.4185],
            [1.4264, 11.4471],
            [0.9008, 10.9932],
            [-0.0686, 11.1158],
            [-0.4917, 11.0072],
            [-2.8299, 10.9984],
            [-2.6958, 9.4814],
            [-3.2236, 9.8954],
            [-4.6257, 9.7134],
            [-5.5235, 10.4258],
            [-5.2882, 11.8276],
            [-4.4288, 12.3379],
            [-4.3286, 13.1188],
            [-3.5758, 13.1939],
            [-2.4572, 14.2741],
            [-1.7677, 14.4864],
            [-0.7605, 15.0475],
            [0.2175, 14.9113]
          ]
        ]
      },
      properties: { name: "Burkina Faso", id: "BF", Continent: "Africa" },
      id: "BF"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.5956, 11.6962],
            [3.4875, 11.3953],
            [3.8342, 10.6073],
            [3.045, 9.0842],
            [2.7747, 9.0486],
            [2.686, 7.8739],
            [2.7066, 6.3692],
            [1.6225, 6.2167],
            [1.6, 9.0499],
            [1.3298, 9.9969],
            [0.78, 10.3599],
            [0.9008, 10.9932],
            [1.4264, 11.4471],
            [1.9802, 11.4185],
            [2.3889, 11.8971],
            [2.8784, 12.3678],
            [3.5956, 11.6962]
          ]
        ]
      },
      properties: { name: "Benin", id: "BJ", Continent: "Africa" },
      id: "BJ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.5534, -2.4001],
            [30.5147, -2.9173],
            [30.7902, -3.2746],
            [30.1469, -4.0854],
            [29.4033, -4.4493],
            [29.2232, -3.9109],
            [29.2245, -3.0536],
            [29.0145, -2.7204],
            [29.8683, -2.7165],
            [29.9303, -2.3394],
            [30.5534, -2.4001]
          ]
        ]
      },
      properties: { name: "Burundi", id: "BI", Continent: "Africa" },
      id: "BI"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [12.2137, -5.7588],
              [12.0181, -5.0042],
              [12.3846, -4.6188],
              [13.0728, -4.6352],
              [12.5222, -5.1489],
              [12.2137, -5.7588]
            ]
          ],
          [
            [
              [23.9665, -10.8714],
              [23.9915, -12.4222],
              [23.8434, -13.001],
              [21.9788, -13.001],
              [21.9797, -15.9554],
              [22.1506, -16.5975],
              [23.3806, -17.6403],
              [21.4167, -18.0006],
              [18.8257, -17.7659],
              [18.3964, -17.3995],
              [16.9136, -17.3916],
              [13.9381, -17.389],
              [13.101, -16.9675],
              [12.5482, -17.2128],
              [11.7431, -17.2497],
              [11.7505, -15.8319],
              [11.9676, -15.6342],
              [12.5508, -13.4378],
              [12.9832, -12.7761],
              [13.4169, -12.5202],
              [13.7856, -11.8127],
              [13.8476, -11.0547],
              [12.9985, -9.0482],
              [13.3783, -8.3696],
              [12.3025, -6.0928],
              [13.068, -5.8648],
              [16.3151, -5.8661],
              [16.7177, -6.2413],
              [16.9849, -7.2573],
              [17.5359, -8.076],
              [19.3408, -7.9666],
              [19.5275, -7.1448],
              [20.482, -6.916],
              [20.6077, -7.278],
              [21.7511, -7.3052],
              [21.9058, -8.6935],
              [21.8131, -9.4692],
              [22.2746, -10.2592],
              [22.5611, -11.056],
              [23.8337, -11.0137],
              [23.9665, -10.8714]
            ]
          ]
        ]
      },
      properties: { name: "Angola", id: "AO", Continent: "Africa" },
      id: "AO"
    },
    //DISABLE
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [53.0855, 16.6485],
            [52.3279, 16.2938],
            [52.2176, 15.6553],
            [51.3224, 15.226],
            [49.3497, 14.638],
            [48.6685, 14.0504],
            [47.99, 14.0482],
            [47.4078, 13.6615],
            [46.789, 13.4655],
            [45.6574, 13.3385],
            [45.0387, 12.816],
            [43.9299, 12.6165],
            [43.4751, 12.8388],
            [43.2321, 13.2673],
            [43.2826, 13.6395],
            [42.717, 15.6544],
            [42.7992, 16.372],
            [43.1648, 16.6893],
            [43.1912, 17.3595],
            [43.9167, 17.3248],
            [45.1481, 17.4276],
            [45.4065, 17.3199],
            [46.6818, 17.2685],
            [46.9758, 16.9534],
            [47.442, 17.1116],
            [48.172, 18.1567],
            [49.0421, 18.5821],
            [51.9776, 18.996],
            [52.7291, 17.3006],
            [53.0855, 16.6485]
          ]
        ]
      },
      properties: { name: "Yemen", id: "YE", Continent: "Middle East" },
      id: "YE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[35.4507, 31.4791], [34.9506, 31.6022], [34.9559, 32.1611], [35.5513, 32.3954], [35.4507, 31.4791]]
        ]
      },
      properties: { name: "Palestinian Territories", id: "PS", Continent: "Middle East" },
      id: "PS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.3589, 37.1085],
            [41.7885, 36.5974],
            [41.4167, 36.5148],
            [41.1943, 34.7689],
            [40.6894, 34.3321],
            [38.7734, 33.3719],
            [36.8183, 32.3172],
            [35.7873, 32.7351],
            [35.869, 33.4321],
            [36.5853, 34.2213],
            [36.4328, 34.6137],
            [35.9763, 34.6291],
            [35.7644, 35.5713],
            [36.1274, 35.8315],
            [36.6583, 36.8022],
            [37.4366, 36.6436],
            [38.1915, 36.9015],
            [39.357, 36.6818],
            [40.7057, 37.0975],
            [42.3589, 37.1085]
          ]
        ]
      },
      properties: { name: "Syria", id: "SY", Continent: "Middle East" },
      id: "SY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.5315, 29.0964],
            [47.4333, 28.9896],
            [47.6714, 28.533],
            [48.4422, 28.5427],
            [48.9067, 27.6291],
            [49.986, 26.8288],
            [50.0317, 26.1108],
            [50.8043, 24.7893],
            [51.2684, 24.6074],
            [51.5681, 24.2861],
            [51.5927, 24.0787],
            [52.5551, 22.9331],
            [55.1043, 22.6215],
            [55.1861, 22.7041],
            [55.6409, 22.0019],
            [54.9773, 19.9958],
            [51.9776, 18.996],
            [49.0421, 18.5821],
            [48.172, 18.1567],
            [47.442, 17.1116],
            [46.9758, 16.9534],
            [46.6818, 17.2685],
            [45.4065, 17.3199],
            [45.1481, 17.4276],
            [43.9167, 17.3248],
            [43.1912, 17.3595],
            [43.1648, 16.6893],
            [42.7992, 16.372],
            [42.2938, 17.4351],
            [41.7498, 17.8855],
            [41.2295, 18.6783],
            [40.7593, 19.7558],
            [39.6136, 20.5179],
            [39.0937, 21.3102],
            [38.9878, 21.8819],
            [39.0959, 22.393],
            [38.464, 23.7122],
            [37.9195, 24.1851],
            [37.5429, 24.2919],
            [37.1808, 24.8201],
            [37.1487, 25.2908],
            [35.1808, 28.0347],
            [34.6162, 28.1481],
            [34.9506, 29.3535],
            [36.0158, 29.1905],
            [36.755, 29.8663],
            [37.4695, 29.9951],
            [37.9802, 30.5],
            [36.9584, 31.4919],
            [38.9971, 32.0073],
            [39.1456, 32.1247],
            [40.3695, 31.9392],
            [42.0741, 31.0801],
            [42.8576, 30.4952],
            [44.7165, 29.1935],
            [46.5315, 29.0964]
          ]
        ]
      },
      properties: { name: "Saudi Arabia", id: "SA", Continent: "Middle East" },
      id: "SA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [51.2684, 24.6074],
            [50.8043, 24.7893],
            [50.763, 25.4445],
            [51.0034, 25.9815],
            [51.543, 25.902],
            [51.6019, 25.1479],
            [51.2684, 24.6074]
          ]
        ]
      },
      properties: { name: "Qatar", id: "QA", Continent: "Middle East" },
      id: "QA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [56.388, 24.9792],
            [56.6407, 24.4703],
            [57.2199, 23.9227],
            [58.5782, 23.6437],
            [59.7999, 22.2203],
            [59.3714, 21.4987],
            [58.8955, 21.1124],
            [58.4745, 20.4066],
            [57.8619, 20.2441],
            [57.7151, 19.6068],
            [57.8118, 19.0171],
            [57.1764, 18.9024],
            [56.6552, 18.5873],
            [56.2702, 17.9505],
            [55.4788, 17.8433],
            [55.0643, 17.0391],
            [54.0681, 17.0057],
            [53.0855, 16.6485],
            [52.7291, 17.3006],
            [51.9776, 18.996],
            [54.9773, 19.9958],
            [55.6409, 22.0019],
            [55.1861, 22.7041],
            [55.1997, 23.0346],
            [55.7609, 24.2426],
            [56.388, 24.9792]
          ]
        ]
      },
      properties: { name: "Oman", id: "OM", Continent: "Middle East" },
      id: "OM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.869, 33.4321],
            [35.1088, 33.0836],
            [35.9763, 34.6291],
            [36.4328, 34.6137],
            [36.5853, 34.2213],
            [35.869, 33.4321]
          ]
        ]
      },
      properties: { name: "Lebanon", id: "LB", Continent: "Middle East" },
      id: "LB"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [48.4422, 28.5427],
            [47.6714, 28.533],
            [47.4333, 28.9896],
            [46.5315, 29.0964],
            [47.1142, 29.9612],
            [47.9786, 29.9828],
            [47.9698, 29.6167],
            [48.4422, 28.5427]
          ]
        ]
      },
      properties: { name: "Kuwait", id: "KW", Continent: "Middle East" },
      id: "KW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [39.1456, 32.1247],
            [38.9971, 32.0073],
            [36.9584, 31.4919],
            [37.9802, 30.5],
            [37.4695, 29.9951],
            [36.755, 29.8663],
            [36.0158, 29.1905],
            [34.9506, 29.3535],
            [34.9734, 29.5552],
            [35.4507, 31.4791],
            [35.5513, 32.3954],
            [35.7873, 32.7351],
            [36.8183, 32.3172],
            [38.7734, 33.3719],
            [39.1456, 32.1247]
          ]
        ]
      },
      properties: { name: "Jordan", id: "JO", Continent: "Middle East" },
      id: "JO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.7873, 32.7351],
            [35.5513, 32.3954],
            [34.9559, 32.1611],
            [34.9506, 31.6022],
            [35.4507, 31.4791],
            [34.9734, 29.5552],
            [34.2453, 31.2084],
            [34.4777, 31.585],
            [35.1088, 33.0836],
            [35.869, 33.4321],
            [35.7873, 32.7351]
          ]
        ]
      },
      properties: { name: "Israel", id: "IL", Continent: "Middle East" },
      id: "IL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [44.7653, 37.1423],
            [45.3617, 36.0156],
            [45.7233, 35.8363],
            [46.1338, 35.1275],
            [45.6781, 34.7983],
            [45.3968, 33.9708],
            [46.1457, 33.2295],
            [46.1127, 32.9579],
            [47.3713, 32.424],
            [47.8296, 31.7942],
            [47.6793, 31.0023],
            [48.0151, 30.4653],
            [48.5468, 29.9626],
            [47.9786, 29.9828],
            [47.1142, 29.9612],
            [46.5315, 29.0964],
            [44.7165, 29.1935],
            [42.8576, 30.4952],
            [42.0741, 31.0801],
            [40.3695, 31.9392],
            [39.1456, 32.1247],
            [38.7734, 33.3719],
            [40.6894, 34.3321],
            [41.1943, 34.7689],
            [41.4167, 36.5148],
            [41.7885, 36.5974],
            [42.3589, 37.1085],
            [42.7746, 37.3718],
            [44.7653, 37.1423]
          ]
        ]
      },
      properties: { name: "Iraq", id: "IQ", Continent: "Middle East" },
      id: "IQ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [48.5468, 29.9626],
            [48.0151, 30.4653],
            [47.6793, 31.0023],
            [47.8296, 31.7942],
            [47.3713, 32.424],
            [46.1127, 32.9579],
            [46.1457, 33.2295],
            [45.3968, 33.9708],
            [45.6781, 34.7983],
            [46.1338, 35.1275],
            [45.7233, 35.8363],
            [45.3617, 36.0156],
            [44.7653, 37.1423],
            [44.5614, 37.7449],
            [44.2112, 37.9083],
            [44.4309, 38.357],
            [44.0231, 39.3774],
            [44.5873, 39.7685],
            [45.4799, 39.0065],
            [46.5548, 38.9041],
            [47.7729, 39.6486],
            [48.2423, 38.9788],
            [48.8404, 38.4374],
            [49.0812, 37.6675],
            [50.1306, 37.4069],
            [51.1185, 36.7424],
            [52.1903, 36.6216],
            [54.0171, 36.9525],
            [53.9143, 37.3436],
            [54.7453, 37.5018],
            [55.0753, 37.9022],
            [56.4407, 38.2493],
            [57.1939, 38.2164],
            [57.4238, 37.9479],
            [58.4358, 37.6385],
            [59.2409, 37.5207],
            [60.3413, 36.6379],
            [61.1196, 36.6427],
            [61.2822, 35.5533],
            [61.08, 34.8554],
            [60.4854, 34.0947],
            [60.5606, 33.1376],
            [60.8291, 32.2495],
            [60.8541, 31.4831],
            [61.6601, 31.3825],
            [61.7844, 30.8322],
            [60.8436, 29.8584],
            [61.3182, 29.3724],
            [61.6227, 28.7914],
            [62.3531, 28.4148],
            [62.7398, 28.0022],
            [62.7631, 27.2503],
            [63.1665, 27.2525],
            [63.1679, 26.6658],
            [62.4392, 26.5608],
            [61.8697, 26.2422],
            [61.6153, 25.2859],
            [61.4118, 25.1022],
            [59.4563, 25.4815],
            [57.796, 25.6533],
            [57.3346, 25.7917],
            [56.9825, 26.9053],
            [56.3559, 27.2002],
            [55.6501, 26.9778],
            [54.8956, 26.5564],
            [53.7056, 26.7256],
            [52.6918, 27.3237],
            [52.4759, 27.6163],
            [51.6665, 27.8453],
            [51.2789, 28.1314],
            [50.65, 29.4198],
            [49.983, 30.2091],
            [49.5549, 30.0289],
            [48.919, 30.1208],
            [48.5468, 29.9626]
          ]
        ]
      },
      properties: { name: "Iran", id: "IR", Continent: "Middle East" },
      id: "IR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[50.6074, 25.8831], [50.4659, 25.9657], [50.4523, 26.1907], [50.61, 26.1244], [50.6074, 25.8831]]
        ]
      },
      properties: { name: "Bahrain", id: "BH", Continent: "Middle East" },
      id: "BH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [56.2979, 25.6507],
            [56.388, 24.9792],
            [55.7609, 24.2426],
            [55.1997, 23.0346],
            [55.1861, 22.7041],
            [55.1043, 22.6215],
            [52.5551, 22.9331],
            [51.5927, 24.0787],
            [51.5681, 24.2861],
            [51.906, 23.9856],
            [52.6482, 24.1547],
            [53.8932, 24.077],
            [54.3972, 24.2778],
            [54.7471, 24.8104],
            [55.941, 25.7939],
            [56.0804, 26.0624],
            [56.2979, 25.6507]
          ]
        ]
      },
      properties: { name: "United Arab Emirates", id: "AE", Continent: "Middle East" },
      id: "AE"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [41.5103, 41.5175],
              [42.4661, 41.4397],
              [42.7878, 41.5637],
              [43.439, 41.1071],
              [43.7225, 40.7196],
              [43.5691, 40.4821],
              [43.792, 40.0704],
              [44.2894, 40.0405],
              [44.7684, 39.7035],
              [44.8172, 39.6503],
              [44.3891, 39.4223],
              [44.0231, 39.3774],
              [44.4309, 38.357],
              [44.2112, 37.9084],
              [44.5614, 37.7449],
              [44.5737, 37.4355],
              [44.7939, 37.2904],
              [44.2819, 36.978],
              [44.2085, 37.203],
              [43.516, 37.2448],
              [42.7746, 37.3717],
              [42.456, 37.1287],
              [42.2683, 37.2764],
              [41.5155, 37.0891],
              [40.7057, 37.0975],
              [40.0166, 36.826],
              [39.3569, 36.6819],
              [38.7668, 36.6933],
              [38.1915, 36.9015],
              [37.4366, 36.6436],
              [36.6582, 36.8022],
              [36.5375, 36.4576],
              [36.1274, 35.8314],
              [35.8111, 36.3096],
              [36.1881, 36.6589],
              [36.0488, 36.9108],
              [35.5376, 36.597],
              [34.6012, 36.7846],
              [34.0233, 36.3408],
              [33.6946, 36.1817],
              [32.7947, 36.0358],
              [32.3776, 36.1834],
              [32.0221, 36.535],
              [31.3523, 36.801],
              [30.6439, 36.8655],
              [30.484, 36.3105],
              [29.6895, 36.1566],
              [29.1432, 36.397],
              [29.0382, 36.6937],
              [28.4835, 36.8035],
              [28.1338, 37.0299],
              [27.349, 37.0193],
              [27.5198, 37.2491],
              [27.2192, 37.3894],
              [27.0778, 37.6877],
              [27.2324, 37.9786],
              [26.6827, 38.1983],
              [26.8612, 38.3728],
              [26.7636, 38.7099],
              [27.0136, 38.887],
              [26.6818, 39.2922],
              [26.8268, 39.5628],
              [26.1132, 39.4676],
              [26.1813, 39.99],
              [26.7376, 40.4001],
              [27.2848, 40.4559],
              [27.4754, 40.3196],
              [28.2893, 40.4031],
              [28.7877, 40.534],
              [29.3647, 40.8096],
              [29.0456, 41.0074],
              [29.322, 41.2275],
              [29.9193, 41.1506],
              [30.3451, 41.1967],
              [30.8101, 41.0851],
              [31.2548, 41.1076],
              [31.4582, 41.3198],
              [32.3064, 41.7298],
              [33.3817, 42.0176],
              [34.7506, 41.957],
              [35.0068, 42.0634],
              [35.2978, 41.7289],
              [36.0514, 41.6824],
              [36.1793, 41.4266],
              [36.5097, 41.2627],
              [36.7778, 41.3633],
              [37.0661, 41.1844],
              [38.3809, 40.9243],
              [39.4264, 41.1062],
              [40.2653, 40.9612],
              [40.9596, 41.2117],
              [41.5103, 41.5175]
            ]
          ],
          [
            [
              [28.0142, 41.9689],
              [28.3465, 41.4666],
              [29.0571, 41.2297],
              [28.7802, 40.974],
              [28.172, 41.0808],
              [27.4997, 40.9731],
              [27.2579, 40.6875],
              [26.1052, 40.6114],
              [26.0394, 40.7269],
              [26.3311, 40.9546],
              [26.3307, 41.2385],
              [26.6247, 41.4016],
              [26.3211, 41.7166],
              [26.6155, 41.965],
              [27.2948, 42.0796],
              [27.5348, 41.921],
              [28.0142, 41.9689]
            ]
          ]
        ]
      },
      properties: { name: "Turkey", id: "TR", Continent: "Europe" },
      id: "TR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.4632, 35.5937],
            [33.9415, 35.2919],
            [34.0501, 34.9881],
            [32.9418, 34.576],
            [32.4488, 34.7293],
            [32.3169, 34.953],
            [32.9418, 35.3903],
            [33.4587, 35.3358],
            [34.4632, 35.5937]
          ]
        ]
      },
      properties: { name: "Cyprus", id: "CY", Continent: "Europe" },
      id: "CY"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [22.7663, 54.3568],
              [20.2082, 54.4208],
              [19.6045, 54.459],
              [19.9749, 54.9213],
              [20.5202, 54.9949],
              [20.9, 55.2867],
              [20.958, 55.2791],
              [20.595, 54.9824],
              [21.1887, 54.9352],
              [21.2357, 55.2641],
              [22.0723, 55.0637],
              [22.5676, 55.0591],
              [22.8313, 54.8385],
              [22.7663, 54.3568]
            ]
          ],
          [
            [
              [48.9533, 69.5092],
              [50.2651, 69.1857],
              [49.6261, 68.8597],
              [48.6668, 68.7331],
              [48.32, 69.2694],
              [48.9533, 69.5092]
            ]
          ],
          [
            [
              [48.5727, 41.8445],
              [48.3912, 41.602],
              [47.8609, 41.213],
              [47.261, 41.3149],
              [46.7494, 41.8124],
              [46.4299, 41.8906],
              [45.639, 42.2048],
              [45.7278, 42.4751],
              [45.3437, 42.53],
              [44.8707, 42.7564],
              [44.5061, 42.7489],
              [43.9571, 42.5665],
              [43.7822, 42.7472],
              [42.4191, 43.2243],
              [41.5806, 43.2191],
              [40.6481, 43.5342],
              [40.1502, 43.5698],
              [39.9784, 43.4199],
              [38.7172, 44.2883],
              [38.1814, 44.4196],
              [37.8514, 44.6988],
              [37.4111, 44.7352],
              [36.9413, 45.2895],
              [37.6475, 45.377],
              [37.6123, 45.5649],
              [37.9332, 46.0018],
              [38.1837, 46.0949],
              [37.9678, 46.6183],
              [39.2932, 47.1056],
              [38.4851, 47.1757],
              [38.2144, 47.0914],
              [38.2873, 47.5592],
              [38.9004, 47.8554],
              [39.7358, 47.8445],
              [39.9608, 48.2378],
              [39.6866, 49.0078],
              [40.0702, 49.2003],
              [39.7807, 49.572],
              [39.1746, 49.856],
              [38.9184, 49.8248],
              [38.2588, 50.0524],
              [38.047, 49.9201],
              [37.4229, 50.4114],
              [36.3059, 50.2804],
              [35.4116, 50.5398],
              [35.3122, 51.0438],
              [34.2808, 51.3116],
              [34.1209, 51.6793],
              [34.3978, 51.7806],
              [33.7355, 52.3448],
              [32.5081, 52.3085],
              [32.2831, 52.1141],
              [31.7636, 52.1011],
              [31.5773, 52.3123],
              [31.4179, 53.196],
              [32.142, 53.0912],
              [32.7041, 53.3362],
              [31.7918, 54.0558],
              [31.4038, 54.1958],
              [31.1212, 54.6484],
              [30.799, 54.7833],
              [30.9779, 55.0505],
              [30.9067, 55.5699],
              [30.2335, 55.8452],
              [29.4825, 55.6844],
              [29.3748, 55.9386],
              [28.7948, 55.9426],
              [28.1483, 56.143],
              [28.1035, 56.5455],
              [27.8063, 56.867],
              [27.7972, 57.3168],
              [27.3515, 57.5281],
              [27.7532, 57.841],
              [27.4882, 58.2702],
              [27.4271, 58.7331],
              [28.0125, 59.4842],
              [28.0142, 59.7247],
              [28.9472, 59.8289],
              [29.069, 60.1915],
              [28.6506, 60.6109],
              [27.7981, 60.5362],
              [29.6904, 61.546],
              [31.2856, 62.5678],
              [31.5343, 62.8855],
              [31.1809, 63.2083],
              [30.0555, 63.6888],
              [30.5038, 64.0206],
              [30.1083, 64.366],
              [30.0726, 64.765],
              [29.6042, 64.9685],
              [29.7158, 65.6246],
              [30.0955, 65.6816],
              [29.9031, 66.0911],
              [29.087, 66.9709],
              [29.9412, 67.5475],
              [29.3441, 68.0621],
              [28.6853, 68.1897],
              [28.4708, 68.4883],
              [28.9656, 69.0218],
              [30.8694, 69.7834],
              [31.453, 69.6896],
              [31.9847, 69.9537],
              [32.9418, 69.7517],
              [32.3776, 69.4791],
              [33.6845, 69.3101],
              [35.858, 69.1917],
              [37.7305, 68.692],
              [40.3809, 67.8318],
              [40.9662, 67.7135],
              [41.1887, 66.8261],
              [40.5215, 66.4466],
              [39.2888, 66.1322],
              [38.3976, 66.0645],
              [35.5135, 66.3958],
              [34.8244, 66.6112],
              [32.4637, 66.9162],
              [33.5667, 66.3209],
              [34.3999, 66.1285],
              [34.7866, 65.8646],
              [34.4065, 65.3957],
              [34.8034, 64.9861],
              [34.8697, 64.5601],
              [35.6471, 64.3783],
              [36.3652, 64.0028],
              [37.4423, 63.8134],
              [38.0707, 64.0259],
              [37.9538, 64.3201],
              [37.1834, 64.4084],
              [36.5282, 64.8472],
              [36.8828, 65.1724],
              [37.5284, 65.1083],
              [38.0096, 64.8789],
              [39.7582, 64.577],
              [40.2812, 64.998],
              [39.7982, 65.3498],
              [39.8167, 65.5978],
              [40.6916, 65.9635],
              [41.476, 66.1234],
              [42.2107, 66.5196],
              [44.0969, 66.235],
              [44.4885, 66.6718],
              [44.292, 67.0997],
              [43.7822, 67.2546],
              [44.2253, 67.9955],
              [44.0482, 68.549],
              [45.892, 68.4798],
              [46.6901, 67.8489],
              [45.5286, 67.7577],
              [44.9397, 67.4776],
              [45.9856, 66.8529],
              [46.691, 66.8254],
              [47.7092, 67.0449],
              [47.8745, 67.5842],
              [48.8777, 67.7314],
              [50.839, 68.3499],
              [52.5503, 68.5925],
              [53.8018, 68.9959],
              [53.9679, 68.2273],
              [54.5616, 68.273],
              [56.0435, 68.649],
              [57.1267, 68.554],
              [58.173, 68.8898],
              [58.9192, 69.0038],
              [59.8975, 68.422],
              [60.9336, 68.9869],
              [60.1703, 69.5909],
              [61.0158, 69.8515],
              [63.3616, 69.6753],
              [66.4159, 68.9478],
              [67.0025, 68.8735],
              [67.874, 68.4712],
              [68.2691, 67.2858],
              [67.5443, 66.7228],
              [66.2023, 66.1717],
              [64.8012, 65.7163],
              [63.0821, 64.9055],
              [61.2167, 64.2826],
              [60.5426, 61.0084],
              [60.0644, 59.4402],
              [59.4316, 58.5187],
              [58.7984, 57.8523],
              [58.0263, 57.3293],
              [57.8277, 56.8591],
              [58.5799, 56.5143],
              [58.6903, 56.0873],
              [58.0983, 55.5504],
              [57.1834, 55.4219],
              [56.0839, 55.3472],
              [56.9738, 54.4687],
              [57.6207, 53.9831],
              [58.9548, 54.5315],
              [59.6294, 54.3087],
              [59.8829, 53.9417],
              [59.4083, 53.6475],
              [59.0053, 53.1793],
              [59.037, 52.6069],
              [59.7071, 52.4617],
              [60.0675, 51.8909],
              [60.4648, 51.6513],
              [61.3631, 51.442],
              [61.5849, 51.2298],
              [61.3894, 50.8609],
              [60.638, 50.6639],
              [59.9551, 50.7992],
              [59.8122, 50.5819],
              [58.8836, 50.6944],
              [58.3594, 51.0638],
              [57.8386, 51.0917],
              [57.4422, 50.8889],
              [57.0116, 51.0651],
              [56.4917, 51.0196],
              [55.7978, 50.6022],
              [55.5425, 50.6017],
              [54.7269, 50.9981],
              [54.4215, 50.7803],
              [54.0417, 51.1152],
              [53.3382, 51.4824],
              [52.5708, 51.4817],
              [52.331, 51.6812],
              [51.6089, 51.4839],
              [50.7942, 51.7291],
              [50.2467, 51.2895],
              [49.4978, 51.0836],
              [49.3233, 50.8518],
              [48.8087, 50.6013],
              [48.7007, 50.3538],
              [48.843, 50.0131],
              [48.4343, 49.8284],
              [47.7062, 50.3781],
              [47.3264, 50.2734],
              [46.9916, 49.8526],
              [46.8022, 49.3671],
              [47.0144, 49.0986],
              [46.7029, 48.8055],
              [46.6611, 48.4124],
              [47.0044, 48.2845],
              [47.0935, 47.9476],
              [47.482, 47.8039],
              [48.11, 47.7452],
              [48.5525, 47.321],
              [48.9594, 46.7745],
              [48.6101, 46.5663],
              [49.184, 46.3486],
              [48.2576, 45.7778],
              [47.5243, 45.6016],
              [47.2219, 45.0242],
              [46.7551, 44.6566],
              [46.753, 44.4205],
              [47.2303, 44.1925],
              [47.5088, 43.5096],
              [47.4631, 43.035],
              [48.5727, 41.8445]
            ]
          ],
          [
            [
              [59.6369, 69.721],
              [58.9531, 69.8928],
              [58.4731, 70.2668],
              [59.0053, 70.4652],
              [60.3923, 69.9625],
              [59.6369, 69.721]
            ]
          ],
          [
            [
              [51.5825, 72.0712],
              [52.6218, 72.3008],
              [53.7531, 73.2933],
              [54.9404, 73.3834],
              [56.4293, 73.201],
              [56.0834, 72.7895],
              [55.3561, 72.4651],
              [55.2977, 71.9354],
              [56.043, 71.3457],
              [56.8952, 70.9271],
              [57.1456, 70.5891],
              [54.6012, 70.6801],
              [53.7222, 70.8146],
              [53.3637, 71.5416],
              [51.8124, 71.4913],
              [51.4388, 71.777],
              [51.5825, 72.0712]
            ]
          ],
          [
            [
              [68.8908, 76.6597],
              [68.1653, 76.2849],
              [66.2823, 75.9836],
              [61.356, 75.315],
              [60.2222, 74.7964],
              [58.5347, 74.4989],
              [58.4415, 74.1289],
              [56.9637, 73.3665],
              [56.4302, 73.2972],
              [55.0068, 73.4539],
              [54.3001, 73.3509],
              [53.7627, 73.7662],
              [54.6424, 73.9595],
              [55.5824, 74.6277],
              [56.4987, 74.9571],
              [57.0876, 75.3838],
              [58.8814, 75.8546],
              [61.0536, 76.1199],
              [61.2018, 76.282],
              [62.9714, 76.2367],
              [65.863, 76.6134],
              [67.5352, 77.0078],
              [68.4857, 76.9338],
              [68.8908, 76.6597]
            ]
          ],
          [
            [
              [59.3063, 80.6177],
              [59.5921, 80.8165],
              [61.5977, 80.893],
              [62.0758, 80.617],
              [61.0511, 80.4186],
              [59.3063, 80.6177]
            ]
          ],
          [
            [
              [49.0878, 80.5157],
              [49.2447, 80.8213],
              [50.8012, 80.9141],
              [51.7039, 80.6875],
              [48.8961, 80.3694],
              [47.6323, 80.1121],
              [46.6444, 80.3002],
              [49.0878, 80.5157]
            ]
          ],
          [[[54.3757, 80.7871], [54.7189, 81.116], [57.694, 80.7924], [55.7125, 80.6372], [54.3757, 80.7871]]],
          [[[55.717, 81.1885], [57.0915, 81.5412], [58.3717, 81.3869], [57.451, 81.1356], [55.717, 81.1885]]]
        ]
      },
      properties: { name: "Russia", id: "RU", Continent: "Europe" },
      id: "RU"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-5.3345, 36.1624], [-5.3383, 36.1123], [-5.3563, 36.1263], [-5.3552, 36.1452], [-5.3345, 36.1624]]
        ]
      },
      properties: { name: "Gibraltar", id: "GI", Continent: "Europe" },
      id: "GI"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[4.2262, 51.3865], [3.9023, 51.2075], [3.35, 51.3776], [4.2262, 51.3865]]],
          [
            [
              [4.2262, 51.3865],
              [3.5872, 51.4541],
              [4.4829, 52.3092],
              [4.769, 52.9411],
              [5.0256, 52.95],
              [5.1205, 52.388],
              [5.5613, 52.5948],
              [5.3754, 53.0956],
              [6.0622, 53.4072],
              [6.8163, 53.4412],
              [7.1974, 53.2824],
              [7.0352, 52.3802],
              [6.7416, 51.9111],
              [5.9484, 51.7625],
              [6.1932, 51.4887],
              [5.8948, 50.9843],
              [5.9937, 50.7504],
              [5.7973, 50.7546],
              [5.694, 50.7748],
              [5.8271, 51.1257],
              [5.5085, 51.2751],
              [4.5035, 51.4746],
              [4.2262, 51.3865]
            ]
          ]
        ]
      },
      properties: { name: "Netherlands", id: "NL", Continent: "Europe" },
      id: "NL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[12.4392, 41.8981], [12.4308, 41.8972], [12.4308, 41.9056], [12.4383, 41.9065], [12.4392, 41.8981]]
        ]
      },
      properties: { name: "Vatican City", id: "VA", Continent: "Europe" },
      id: "VA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.2144, 47.0914],
            [37.5433, 47.0744],
            [37.3399, 46.9168],
            [36.2795, 46.6585],
            [35.8268, 46.6244],
            [34.8495, 46.1899],
            [35.0231, 45.7012],
            [35.5579, 45.3109],
            [36.1705, 45.453],
            [36.5748, 45.3935],
            [36.3933, 45.0654],
            [35.8699, 45.0055],
            [35.4727, 45.0982],
            [35.0877, 44.8027],
            [34.7168, 44.8071],
            [33.9099, 44.3877],
            [33.4507, 44.5537],
            [33.5553, 45.0978],
            [32.9186, 45.3483],
            [32.5081, 45.4036],
            [33.6648, 45.947],
            [33.2024, 46.1758],
            [32.4769, 46.0837],
            [32.0357, 46.2608],
            [31.9746, 46.7088],
            [30.7964, 46.5522],
            [30.5112, 46.1054],
            [30.2194, 45.8666],
            [29.602, 45.6825],
            [29.7061, 45.2599],
            [29.2236, 45.4027],
            [28.7604, 45.2342],
            [28.2125, 45.4502],
            [28.9476, 46.0501],
            [28.9274, 46.4242],
            [29.1462, 46.5267],
            [29.838, 46.3504],
            [29.878, 46.8291],
            [29.5721, 46.9639],
            [29.5493, 47.2467],
            [29.1599, 47.4558],
            [29.1947, 47.8823],
            [28.7741, 48.1194],
            [28.3271, 48.1617],
            [27.5494, 48.4776],
            [26.619, 48.2596],
            [26.1624, 47.9925],
            [25.4641, 47.9107],
            [24.8933, 47.7178],
            [24.5786, 47.9311],
            [24.1778, 47.9059],
            [23.2027, 48.0843],
            [22.8766, 47.9472],
            [22.1322, 48.4053],
            [22.5387, 49.0726],
            [22.6494, 49.5392],
            [23.7121, 50.3774],
            [23.9731, 50.4101],
            [24.1058, 50.8449],
            [23.6053, 51.5179],
            [24.3615, 51.8676],
            [25.9251, 51.9137],
            [27.1424, 51.752],
            [27.2702, 51.6135],
            [28.5992, 51.5427],
            [29.1357, 51.6173],
            [29.3466, 51.3824],
            [30.5332, 51.5964],
            [30.9805, 52.0462],
            [31.7636, 52.1011],
            [32.2831, 52.1141],
            [32.5081, 52.3085],
            [33.7355, 52.3448],
            [34.3978, 51.7806],
            [34.1209, 51.6793],
            [34.2808, 51.3116],
            [35.3122, 51.0438],
            [35.4116, 50.5398],
            [36.3059, 50.2804],
            [37.4229, 50.4114],
            [38.047, 49.9201],
            [38.2588, 50.0524],
            [38.9184, 49.8248],
            [39.1746, 49.856],
            [39.7807, 49.572],
            [40.0702, 49.2003],
            [39.6866, 49.0078],
            [39.9608, 48.2378],
            [39.7358, 47.8445],
            [38.9004, 47.8554],
            [38.2873, 47.5592],
            [38.2144, 47.0914]
          ]
        ]
      },
      properties: { name: "Ukraine", id: "UA", Continent: "Europe" },
      id: "UA"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [41.5103, 41.5175],
              [42.4661, 41.4397],
              [42.7878, 41.5637],
              [43.439, 41.1071],
              [43.7225, 40.7196],
              [43.5691, 40.4821],
              [43.792, 40.0704],
              [44.2894, 40.0405],
              [44.7684, 39.7035],
              [44.8172, 39.6503],
              [44.3891, 39.4223],
              [44.0231, 39.3774],
              [44.4309, 38.357],
              [44.2112, 37.9084],
              [44.5614, 37.7449],
              [44.5737, 37.4355],
              [44.7939, 37.2904],
              [44.2819, 36.978],
              [44.2085, 37.203],
              [43.516, 37.2448],
              [42.7746, 37.3717],
              [42.456, 37.1287],
              [42.2683, 37.2764],
              [41.5155, 37.0891],
              [40.7057, 37.0975],
              [40.0166, 36.826],
              [39.3569, 36.6819],
              [38.7668, 36.6933],
              [38.1915, 36.9015],
              [37.4366, 36.6436],
              [36.6582, 36.8022],
              [36.5375, 36.4576],
              [36.1274, 35.8314],
              [35.8111, 36.3096],
              [36.1881, 36.6589],
              [36.0488, 36.9108],
              [35.5376, 36.597],
              [34.6012, 36.7846],
              [34.0233, 36.3408],
              [33.6946, 36.1817],
              [32.7947, 36.0358],
              [32.3776, 36.1834],
              [32.0221, 36.535],
              [31.3523, 36.801],
              [30.6439, 36.8655],
              [30.484, 36.3105],
              [29.6895, 36.1566],
              [29.1432, 36.397],
              [29.0382, 36.6937],
              [28.4835, 36.8035],
              [28.1338, 37.0299],
              [27.349, 37.0193],
              [27.5198, 37.2491],
              [27.2192, 37.3894],
              [27.0778, 37.6877],
              [27.2324, 37.9786],
              [26.6827, 38.1983],
              [26.8612, 38.3728],
              [26.7636, 38.7099],
              [27.0136, 38.887],
              [26.6818, 39.2922],
              [26.8268, 39.5628],
              [26.1132, 39.4676],
              [26.1813, 39.99],
              [26.7376, 40.4001],
              [27.2848, 40.4559],
              [27.4754, 40.3196],
              [28.2893, 40.4031],
              [28.7877, 40.534],
              [29.3647, 40.8096],
              [29.0456, 41.0074],
              [29.322, 41.2275],
              [29.9193, 41.1506],
              [30.3451, 41.1967],
              [30.8101, 41.0851],
              [31.2548, 41.1076],
              [31.4582, 41.3198],
              [32.3064, 41.7298],
              [33.3817, 42.0176],
              [34.7506, 41.957],
              [35.0068, 42.0634],
              [35.2978, 41.7289],
              [36.0514, 41.6824],
              [36.1793, 41.4266],
              [36.5097, 41.2627],
              [36.7778, 41.3633],
              [37.0661, 41.1844],
              [38.3809, 40.9243],
              [39.4264, 41.1062],
              [40.2653, 40.9612],
              [40.9596, 41.2117],
              [41.5103, 41.5175]
            ]
          ],
          [
            [
              [28.0142, 41.9689],
              [28.3465, 41.4666],
              [29.0571, 41.2297],
              [28.7802, 40.974],
              [28.172, 41.0808],
              [27.4997, 40.9731],
              [27.2579, 40.6875],
              [26.1052, 40.6114],
              [26.0394, 40.7269],
              [26.3311, 40.9546],
              [26.3307, 41.2385],
              [26.6247, 41.4016],
              [26.3211, 41.7166],
              [26.6155, 41.965],
              [27.2948, 42.0796],
              [27.5348, 41.921],
              [28.0142, 41.9689]
            ]
          ]
        ]
      },
      properties: { name: "Turkey", id: "TR", Continent: "Europe" },
      id: "TR"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [24.1559, 65.8052],
              [23.1025, 65.7353],
              [21.5657, 65.4082],
              [21.5741, 65.1258],
              [21.1382, 64.8086],
              [21.4651, 64.3797],
              [20.7624, 63.8679],
              [19.0345, 63.2377],
              [18.4632, 62.8959],
              [17.8954, 62.8304],
              [17.4173, 61.7407],
              [17.1308, 61.5758],
              [17.2512, 60.7009],
              [17.956, 60.5898],
              [18.5572, 60.2536],
              [18.9707, 59.7572],
              [17.8761, 59.2708],
              [18.2853, 59.1093],
              [17.4568, 58.8586],
              [16.6521, 58.4343],
              [16.77, 58.2142],
              [16.5555, 57.8123],
              [16.5278, 57.0681],
              [15.9965, 56.2228],
              [14.7819, 56.1619],
              [14.2155, 55.8325],
              [14.3416, 55.5278],
              [13.3211, 55.3465],
              [12.8856, 55.4115],
              [12.9779, 55.6938],
              [12.5925, 56.1377],
              [12.8834, 56.6178],
              [12.4215, 56.9064],
              [11.4495, 58.1183],
              [11.1472, 58.9887],
              [11.3884, 59.0365],
              [11.7985, 59.2899],
              [11.6806, 59.5923],
              [12.4862, 60.1068],
              [12.5886, 60.4507],
              [12.2941, 61.0027],
              [12.8808, 61.3522],
              [12.1557, 61.7208],
              [12.3033, 62.2856],
              [12.1148, 62.592],
              [12.2181, 63.0006],
              [11.9997, 63.2916],
              [12.6624, 63.9404],
              [13.2996, 64.0747],
              [13.9606, 64.014],
              [14.0774, 64.4641],
              [13.6503, 64.5816],
              [14.4799, 65.3014],
              [14.5432, 66.1294],
              [15.4837, 66.306],
              [15.4231, 66.4899],
              [16.4034, 67.0551],
              [16.1275, 67.4259],
              [17.3246, 68.1038],
              [17.9169, 67.9649],
              [18.3784, 68.5624],
              [19.9696, 68.3565],
              [20.6222, 69.037],
              [21.9976, 68.5207],
              [22.7821, 68.391],
              [23.639, 67.9544],
              [23.4682, 67.4501],
              [23.9884, 66.8107],
              [23.701, 66.4809],
              [24.1559, 65.8052]
            ]
          ],
          [
            [
              [19.0766, 57.836],
              [18.8139, 57.7061],
              [18.7, 57.2427],
              [18.2853, 57.0832],
              [18.1366, 57.5565],
              [18.5374, 57.8305],
              [19.0766, 57.836]
            ]
          ]
        ]
      },
      properties: { name: "Sweden", id: "SE", Continent: "Europe" },
      id: "SE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.5164, 46.4996],
            [15.6362, 46.2006],
            [15.6248, 45.8338],
            [15.2771, 45.7326],
            [15.3395, 45.4671],
            [14.7335, 45.5084],
            [13.8788, 45.4287],
            [13.5778, 45.517],
            [13.7197, 45.5876],
            [13.3994, 46.3177],
            [13.6999, 46.5201],
            [14.5037, 46.417],
            [15.0007, 46.6262],
            [15.9574, 46.6776],
            [16.0928, 46.8633],
            [16.5164, 46.4996]
          ]
        ]
      },
      properties: { name: "Slovenia", id: "SI", Continent: "Europe" },
      id: "SI"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.5387, 49.0726],
            [22.1322, 48.4053],
            [21.767, 48.3383],
            [21.4515, 48.5523],
            [20.4899, 48.5268],
            [20.3339, 48.2956],
            [19.8989, 48.1313],
            [18.7919, 48.0003],
            [18.7242, 47.787],
            [17.7618, 47.7701],
            [17.1474, 48.0059],
            [16.8627, 48.4414],
            [16.9532, 48.5989],
            [17.1884, 48.861],
            [17.7583, 48.8881],
            [18.1608, 49.2575],
            [18.8323, 49.5107],
            [19.4414, 49.5975],
            [19.8022, 49.1924],
            [20.616, 49.3917],
            [21.6396, 49.4121],
            [22.5387, 49.0726]
          ]
        ]
      },
      properties: { name: "Slovakia", id: "SK", Continent: "Europe" },
      id: "SK"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.2421, 46.1086],
            [20.7752, 45.7499],
            [20.7743, 45.4845],
            [21.4919, 45.1224],
            [21.3846, 44.8701],
            [22.093, 44.5423],
            [22.5022, 44.4895],
            [22.7052, 44.2377],
            [22.4209, 44.0075],
            [22.5544, 43.4547],
            [22.9768, 43.1879],
            [22.4666, 42.8425],
            [22.5237, 42.4412],
            [22.344, 42.3139],
            [21.5626, 42.2474],
            [21.753, 42.6698],
            [21.3908, 42.7515],
            [21.0569, 43.0917],
            [20.624, 43.0341],
            [20.3445, 42.828],
            [19.5517, 43.212],
            [19.1944, 43.5333],
            [19.4879, 43.7034],
            [19.1518, 44.3023],
            [19.357, 44.8587],
            [19.0072, 44.8693],
            [19.0551, 45.5272],
            [18.9052, 45.9314],
            [19.5307, 46.1553],
            [20.2421, 46.1086]
          ]
        ]
      },
      properties: { name: "Serbia", id: "RS", Continent: "Europe" },
      id: "RS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[12.4853, 43.9016], [12.3969, 43.9345], [12.4409, 43.9824], [12.5148, 43.953], [12.4853, 43.9016]]
        ]
      },
      properties: { name: "San Marino", id: "SM", Continent: "Europe" },
      id: "SM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.2125, 45.4502],
            [28.7604, 45.2342],
            [29.2236, 45.4027],
            [29.7061, 45.2599],
            [29.5576, 44.8436],
            [29.0483, 44.7574],
            [28.6453, 44.2957],
            [28.5855, 43.7421],
            [28.0499, 43.8225],
            [27.8846, 43.9873],
            [27.0865, 44.1675],
            [26.2161, 44.007],
            [25.497, 43.6709],
            [23.2242, 43.8739],
            [22.7052, 44.2377],
            [22.5022, 44.4895],
            [22.093, 44.5423],
            [21.3846, 44.8701],
            [21.4919, 45.1224],
            [20.7743, 45.4845],
            [20.7752, 45.7499],
            [20.2421, 46.1086],
            [21.1518, 46.3043],
            [22.2904, 47.7279],
            [22.8766, 47.9472],
            [23.2027, 48.0843],
            [24.1778, 47.9059],
            [24.5786, 47.9311],
            [24.8933, 47.7178],
            [25.4641, 47.9107],
            [26.1624, 47.9925],
            [26.619, 48.2596],
            [26.9806, 48.1551],
            [27.3367, 47.6396],
            [28.0718, 46.9787],
            [28.2445, 46.4513],
            [28.0995, 45.9727],
            [28.2125, 45.4502]
          ]
        ]
      },
      properties: { name: "Romania", id: "RO", Continent: "Europe" },
      id: "RO"
    },

    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.6045, 54.459],
            [20.2082, 54.4208],
            [22.7663, 54.3568],
            [23.4536, 54.1435],
            [23.4844, 53.9398],
            [23.8874, 53.0274],
            [23.8447, 52.6642],
            [23.197, 52.2569],
            [23.5982, 52.1031],
            [23.6053, 51.5179],
            [24.1058, 50.8449],
            [23.9731, 50.4101],
            [23.7121, 50.3774],
            [22.6494, 49.5392],
            [22.5387, 49.0726],
            [21.6396, 49.4121],
            [20.616, 49.3917],
            [19.8022, 49.1924],
            [19.4414, 49.5975],
            [18.8323, 49.5107],
            [18.562, 49.8792],
            [17.8747, 49.9721],
            [17.1519, 50.3784],
            [16.6794, 50.0974],
            [16.007, 50.6116],
            [15.3541, 50.812],
            [14.8096, 50.859],
            [15.0166, 51.2528],
            [14.6017, 51.8324],
            [14.7529, 52.0817],
            [14.6197, 52.5285],
            [14.1288, 52.8783],
            [14.4145, 53.2834],
            [14.2585, 53.7295],
            [14.2137, 53.8708],
            [14.211, 53.9503],
            [16.1864, 54.2905],
            [16.5599, 54.5539],
            [17.2622, 54.7294],
            [18.3234, 54.8382],
            [18.6697, 54.4309],
            [18.9764, 54.3489],
            [19.6045, 54.459]
          ]
        ]
      },
      properties: { name: "Poland", id: "PL", Continent: "Europe" },
      id: "PL"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [20.8982, 80.2498],
              [22.4508, 80.4021],
              [26.8607, 80.16],
              [27.1987, 79.9067],
              [25.6412, 79.403],
              [23.9476, 79.1943],
              [20.8613, 79.3977],
              [18.7251, 79.7607],
              [18.3436, 80.0596],
              [20.8982, 80.2498]
            ]
          ],
          [
            [
              [16.7867, 79.9069],
              [18.5814, 79.5716],
              [19.0894, 79.1571],
              [20.4583, 79.1292],
              [21.3891, 78.7405],
              [19.7687, 78.6228],
              [18.4395, 78.0251],
              [18.2281, 77.5226],
              [17.6234, 77.3993],
              [16.7006, 76.5793],
              [14.3658, 77.2345],
              [14.0045, 77.4453],
              [14.9212, 77.6889],
              [13.6806, 78.028],
              [14.9945, 78.1513],
              [14.6382, 78.4147],
              [13.1502, 78.2376],
              [11.7738, 78.7164],
              [11.2082, 79.1297],
              [10.8659, 79.7966],
              [12.7533, 79.7759],
              [13.384, 79.4808],
              [15.8164, 79.6818],
              [16.7867, 79.9069]
            ]
          ],
          [
            [
              [21.6084, 78.5958],
              [23.1166, 77.9915],
              [24.2384, 77.8985],
              [23.8417, 77.4978],
              [22.8019, 77.2758],
              [22.057, 77.5011],
              [21.6084, 77.9162],
              [20.228, 78.478],
              [21.6084, 78.5958]
            ]
          ]
        ]
      },
      properties: { name: "Svalbard and Jan Mayen", id: "SJ", Continent: "Europe" },
      id: "SJ"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[19.2551, 70.0664], [19.5926, 69.9701], [18.7844, 69.579], [18.3493, 69.7678], [19.2551, 70.0664]]],
          [[[17.5029, 69.5962], [17.9508, 69.198], [16.9716, 69.138], [17.5029, 69.5962]]],
          [
            [
              [30.8694, 69.7834],
              [28.9656, 69.0218],
              [29.3335, 69.4731],
              [29.1414, 69.6716],
              [27.8899, 70.0618],
              [26.5258, 69.915],
              [26.0116, 69.6527],
              [25.7484, 68.9902],
              [24.9416, 68.5933],
              [23.854, 68.8058],
              [23.324, 68.649],
              [22.4107, 68.7197],
              [21.6219, 69.2708],
              [20.6222, 69.037],
              [19.9696, 68.3565],
              [18.3784, 68.5624],
              [17.9169, 67.9649],
              [17.3246, 68.1038],
              [16.1275, 67.4259],
              [16.4034, 67.0551],
              [15.4231, 66.4899],
              [15.4837, 66.306],
              [14.5432, 66.1294],
              [14.4799, 65.3014],
              [13.6503, 64.5816],
              [14.0774, 64.4641],
              [13.9606, 64.014],
              [13.2996, 64.0747],
              [12.6624, 63.9404],
              [11.9997, 63.2916],
              [12.2181, 63.0006],
              [12.1148, 62.592],
              [12.3033, 62.2856],
              [12.1557, 61.7208],
              [12.8808, 61.3522],
              [12.2941, 61.0027],
              [12.5886, 60.4507],
              [12.4862, 60.1068],
              [11.6806, 59.5923],
              [11.7985, 59.2899],
              [11.3884, 59.0365],
              [10.8347, 59.184],
              [9.6183, 58.9461],
              [8.1663, 58.1452],
              [7.0049, 58.0243],
              [5.7067, 58.5235],
              [5.5173, 58.7265],
              [5.854, 58.9595],
              [5.8452, 59.3534],
              [5.1315, 59.2264],
              [5.3051, 59.6426],
              [5.1196, 59.8337],
              [5.244, 60.5695],
              [5.0493, 60.7075],
              [4.9276, 61.7108],
              [5.5336, 62.3109],
              [6.3531, 62.6113],
              [7.2839, 62.6023],
              [7.0084, 62.9576],
              [8.1004, 63.0909],
              [8.3979, 63.535],
              [9.697, 63.6247],
              [9.7079, 63.8651],
              [10.5653, 64.4183],
              [11.2254, 64.6796],
              [11.3498, 64.9059],
              [12.1597, 65.1788],
              [12.2726, 65.5682],
              [12.9761, 66.0193],
              [13.1045, 66.5394],
              [14.755, 67.4991],
              [14.799, 67.8094],
              [15.3163, 68.0689],
              [16.0646, 68.2],
              [16.6517, 68.6257],
              [17.3909, 68.7994],
              [18.1015, 69.1563],
              [18.2931, 69.4751],
              [18.991, 69.5612],
              [19.1975, 69.7478],
              [20.069, 69.8834],
              [21.1632, 69.8895],
              [21.4005, 70.1745],
              [22.6846, 70.3749],
              [23.0462, 70.1018],
              [24.4199, 70.7021],
              [24.6581, 71.0009],
              [25.7682, 70.8532],
              [27.5972, 71.0912],
              [29.1023, 70.8607],
              [30.9441, 70.2745],
              [29.6016, 69.9767],
              [29.7922, 69.7278],
              [30.8694, 69.7834]
            ]
          ]
        ]
      },
      properties: { name: "Norway", id: "NO", Continent: "Europe" },
      id: "NO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.1944, 43.5333],
            [19.5517, 43.212],
            [20.3445, 42.828],
            [20.0641, 42.5472],
            [19.6546, 42.6285],
            [19.2809, 42.1727],
            [19.3426, 41.8691],
            [18.8943, 42.2492],
            [18.5172, 42.433],
            [18.4363, 42.5595],
            [18.4438, 42.9686],
            [18.9404, 43.4969],
            [19.1944, 43.5333]
          ]
        ]
      },
      properties: { name: "Montenegro", id: "ME", Continent: "Europe" },
      id: "ME"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[14.5661, 35.853], [14.4364, 35.8218], [14.3526, 35.8723], [14.4483, 35.9571], [14.5661, 35.853]]
        ]
      },
      properties: { name: "Malta", id: "MT", Continent: "Europe" },
      id: "MT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.344, 42.3139],
            [22.837, 41.9939],
            [23.0058, 41.717],
            [22.9157, 41.3365],
            [22.4934, 41.1185],
            [21.9295, 41.1071],
            [21.5758, 40.869],
            [20.9642, 40.8501],
            [20.7092, 40.9282],
            [20.4486, 41.5215],
            [20.5659, 41.8739],
            [21.0594, 42.1715],
            [21.5626, 42.2474],
            [22.344, 42.3139]
          ]
        ]
      },
      properties: { name: "North Macedonia", id: "MK", Continent: "Europe" },
      id: "MK"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.2125, 45.4502],
            [28.0995, 45.9727],
            [28.2445, 46.4513],
            [28.0718, 46.9787],
            [27.3367, 47.6396],
            [26.9806, 48.1551],
            [26.619, 48.2596],
            [27.5494, 48.4776],
            [28.3271, 48.1617],
            [28.7741, 48.1194],
            [29.1947, 47.8823],
            [29.1599, 47.4558],
            [29.5493, 47.2467],
            [29.5721, 46.9639],
            [29.878, 46.8291],
            [29.838, 46.3504],
            [29.1462, 46.5267],
            [28.9274, 46.4242],
            [28.9476, 46.0501],
            [28.2125, 45.4502]
          ]
        ]
      },
      properties: { name: "Moldova", id: "MD", Continent: "Europe" },
      id: "MD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[7.4386, 43.7508], [7.378, 43.7315], [7.4144, 43.7711], [7.4386, 43.7508]]]
      },
      properties: { name: "Monaco", id: "MC", Continent: "Europe" },
      id: "MC"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.3515, 57.5281],
            [27.7972, 57.3168],
            [27.8063, 56.867],
            [28.1035, 56.5455],
            [28.1483, 56.143],
            [27.577, 55.7987],
            [27.0527, 55.8306],
            [26.5935, 55.6675],
            [24.9033, 56.3983],
            [24.1207, 56.2642],
            [23.8126, 56.3293],
            [22.0848, 56.4066],
            [21.0462, 56.07],
            [21.0708, 56.8237],
            [21.3508, 57.0177],
            [21.7283, 57.571],
            [22.5544, 57.7241],
            [23.6479, 56.9711],
            [24.4032, 57.3251],
            [24.3228, 57.8707],
            [25.1108, 58.0634],
            [25.991, 57.8382],
            [26.5329, 57.531],
            [27.3515, 57.5281]
          ]
        ]
      },
      properties: { name: "Latvia", id: "LV", Continent: "Europe" },
      id: "LV"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.1163, 50.121],
            [6.4876, 49.7984],
            [6.3439, 49.4527],
            [5.7898, 49.5385],
            [5.7441, 49.9197],
            [6.1163, 50.121]
          ]
        ]
      },
      properties: { name: "Luxembourg", id: "LU", Continent: "Europe" },
      id: "LU"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.5935, 55.6675],
            [26.1751, 55.0033],
            [25.8592, 54.9191],
            [25.5471, 54.3318],
            [24.7685, 53.9745],
            [23.4844, 53.9398],
            [23.4536, 54.1435],
            [22.7663, 54.3568],
            [22.8313, 54.8385],
            [22.5676, 55.0591],
            [22.0723, 55.0637],
            [21.2357, 55.2641],
            [21.0462, 56.07],
            [22.0848, 56.4066],
            [23.8126, 56.3293],
            [24.1207, 56.2642],
            [24.9033, 56.3983],
            [26.5935, 55.6675]
          ]
        ]
      },
      properties: { name: "Lithuania", id: "LT", Continent: "Europe" },
      id: "LT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[9.5801, 47.0573], [9.4878, 47.0621], [9.5278, 47.2707], [9.5801, 47.0573]]]
      },
      properties: { name: "Liechtenstein", id: "LI", Continent: "Europe" },
      id: "LI"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.5626, 42.2474],
            [21.0594, 42.1715],
            [20.5659, 41.8739],
            [20.4855, 42.2233],
            [20.0641, 42.5472],
            [20.3445, 42.828],
            [20.624, 43.0341],
            [21.0569, 43.0917],
            [21.3908, 42.7515],
            [21.753, 42.6698],
            [21.5626, 42.2474]
          ]
        ]
      },
      properties: { name: "Kosovo", id: "XK", Continent: "Europe" },
      id: "XK"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-2.0186, 49.2313], [-2.2359, 49.1764], [-2.2205, 49.2664], [-2.0186, 49.2313]]]
      },
      properties: { name: "Jersey", id: "JE", Continent: "Europe" },
      id: "JE"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [15.5764, 38.2204],
              [15.0996, 37.4584],
              [15.2942, 37.0132],
              [15.1127, 36.688],
              [14.502, 36.7987],
              [14.1429, 37.1037],
              [13.9051, 37.1006],
              [12.4356, 37.82],
              [13.3519, 38.1808],
              [13.6815, 38.0011],
              [14.5059, 38.0455],
              [14.846, 38.1719],
              [15.5764, 38.2204]
            ]
          ],
          [
            [
              [9.6319, 40.8821],
              [9.805, 40.4998],
              [9.5625, 39.1661],
              [9.0563, 39.2394],
              [8.8814, 38.9124],
              [8.4182, 39.2055],
              [8.4714, 40.2928],
              [8.1897, 40.6518],
              [9.283, 41.202],
              [9.6319, 40.8821]
            ]
          ],
          [
            [
              [13.6999, 46.5201],
              [13.3994, 46.3177],
              [13.7197, 45.5876],
              [13.2065, 45.7713],
              [13.0307, 45.6377],
              [12.2744, 45.4458],
              [12.2256, 45.2417],
              [12.5235, 44.9677],
              [12.2484, 44.7223],
              [12.3961, 44.2237],
              [12.6914, 43.9947],
              [13.5641, 43.5711],
              [14.0106, 42.6896],
              [14.8658, 42.0524],
              [15.1686, 41.9338],
              [15.964, 41.9395],
              [16.0124, 41.4354],
              [17.103, 41.0619],
              [17.9551, 40.6554],
              [18.486, 40.1052],
              [18.0778, 39.9369],
              [17.8651, 40.2805],
              [16.9281, 40.458],
              [16.53, 39.8595],
              [16.5977, 39.6389],
              [17.1145, 39.3804],
              [17.1747, 38.9978],
              [16.559, 38.7147],
              [16.5458, 38.4093],
              [16.0568, 37.9417],
              [15.7245, 37.9391],
              [15.9724, 38.7125],
              [16.1969, 38.7591],
              [16.0238, 39.3533],
              [15.6929, 39.99],
              [14.9506, 40.2391],
              [14.7656, 40.6681],
              [14.4594, 40.6329],
              [14.1025, 40.8271],
              [13.7333, 41.2354],
              [13.0886, 41.2438],
              [12.6308, 41.4697],
              [11.6376, 42.2875],
              [11.1678, 42.5353],
              [10.7086, 42.9361],
              [10.0477, 44.0202],
              [9.2891, 44.319],
              [8.7662, 44.4223],
              [8.0815, 43.9187],
              [7.4931, 43.7671],
              [7.6772, 44.0831],
              [6.9675, 44.2804],
              [7.0308, 44.7165],
              [6.6278, 45.1178],
              [7.1534, 45.401],
              [6.8045, 45.8148],
              [7.0211, 45.9257],
              [7.9932, 46.0157],
              [8.4586, 46.2459],
              [8.9538, 45.8299],
              [9.2513, 46.2865],
              [9.9391, 46.3616],
              [10.4529, 46.8651],
              [11.025, 46.7972],
              [11.2443, 46.9755],
              [12.1544, 46.9352],
              [12.3882, 46.7025],
              [13.6999, 46.5201]
            ],
            [[12.4853, 43.9016], [12.5148, 43.953], [12.4409, 43.9824], [12.3969, 43.9345], [12.4853, 43.9016]]
          ]
        ]
      },
      properties: { name: "Italy", id: "IT", Continent: "Europe" },
      id: "IT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-15.543, 66.2284],
            [-14.6982, 66.0203],
            [-14.7577, 65.7558],
            [-13.616, 65.4871],
            [-13.8529, 64.8622],
            [-15.0216, 64.2959],
            [-16.0605, 64.1114],
            [-16.6403, 63.8655],
            [-17.8157, 63.713],
            [-18.6537, 63.4067],
            [-20.1982, 63.5557],
            [-21.2463, 63.9354],
            [-22.607, 63.8372],
            [-21.8329, 64.2054],
            [-21.9503, 64.515],
            [-22.6839, 65.0263],
            [-22.0056, 65.4935],
            [-22.9024, 65.5805],
            [-23.8999, 65.4075],
            [-24.224, 65.4871],
            [-23.7371, 66.0696],
            [-22.4262, 66.4301],
            [-21.3036, 65.8764],
            [-21.4321, 65.4741],
            [-20.9982, 65.4444],
            [-20.3566, 65.7191],
            [-20.3566, 66.0331],
            [-18.7776, 66.1687],
            [-16.7485, 66.1318],
            [-16.5408, 66.4466],
            [-15.9855, 66.5145],
            [-15.543, 66.2284]
          ]
        ]
      },
      properties: { name: "Iceland", id: "IS", Continent: "Europe" },
      id: "IS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.218, 54.0888],
            [-6.0273, 52.927],
            [-6.5612, 52.1888],
            [-7.5274, 52.099],
            [-7.6645, 51.9799],
            [-8.8135, 51.585],
            [-9.2964, 51.4982],
            [-9.5799, 51.6891],
            [-10.3786, 51.8688],
            [-9.9558, 52.1368],
            [-9.5862, 52.5592],
            [-9.2993, 53.0976],
            [-9.7954, 53.3951],
            [-9.996, 54.276],
            [-8.623, 54.3468],
            [-8.764, 54.6814],
            [-8.2747, 55.1463],
            [-7.2468, 55.3531],
            [-7.2185, 55.0921],
            [-8.1182, 54.4142],
            [-7.3554, 54.1213],
            [-6.9364, 54.3744],
            [-6.6498, 54.0587],
            [-6.218, 54.0888]
          ]
        ]
      },
      properties: { name: "Ireland", id: "IE", Continent: "Europe" },
      id: "IE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-4.4121, 54.1854], [-4.7457, 54.1189], [-4.5086, 54.3769], [-4.4121, 54.1854]]]
      },
      properties: { name: "Isle of Man", id: "IM", Continent: "Europe" },
      id: "IM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.1322, 48.4053],
            [22.8766, 47.9472],
            [22.2904, 47.7279],
            [21.1518, 46.3043],
            [20.2421, 46.1086],
            [19.5307, 46.1553],
            [18.9052, 45.9314],
            [18.4377, 45.7673],
            [17.964, 45.7704],
            [17.3105, 45.9963],
            [16.5164, 46.4996],
            [16.0928, 46.8633],
            [16.4531, 47.0065],
            [16.4342, 47.3676],
            [16.7475, 47.6862],
            [17.0666, 47.7074],
            [17.1474, 48.0059],
            [17.7618, 47.7701],
            [18.7242, 47.787],
            [18.7919, 48.0003],
            [19.8989, 48.1313],
            [20.3339, 48.2956],
            [20.4899, 48.5268],
            [21.4515, 48.5523],
            [21.767, 48.3383],
            [22.1322, 48.4053]
          ]
        ]
      },
      properties: { name: "Hungary", id: "HU", Continent: "Europe" },
      id: "HU"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[17.6677, 42.897], [18.4363, 42.5595], [18.5172, 42.433], [17.6677, 42.897]]],
          [
            [
              [18.9052, 45.9314],
              [19.0551, 45.5272],
              [19.0072, 44.8693],
              [18.6627, 45.0775],
              [17.8128, 45.078],
              [16.5305, 45.2169],
              [16.2936, 45.0085],
              [15.7877, 45.1791],
              [15.7369, 44.7658],
              [16.1301, 44.4738],
              [16.214, 44.2149],
              [17.6247, 43.0424],
              [17.5851, 42.9383],
              [16.9031, 43.3923],
              [15.9416, 43.6563],
              [15.1229, 44.2566],
              [15.2701, 44.3836],
              [14.8952, 44.7065],
              [14.8549, 45.0811],
              [14.3859, 45.3421],
              [13.9658, 44.8354],
              [13.6292, 45.108],
              [13.5778, 45.517],
              [13.8788, 45.4287],
              [14.7335, 45.5084],
              [15.3395, 45.4671],
              [15.2771, 45.7326],
              [15.6248, 45.8338],
              [15.6362, 46.2006],
              [16.5164, 46.4996],
              [17.3105, 45.9963],
              [17.964, 45.7704],
              [18.4377, 45.7673],
              [18.9052, 45.9314]
            ]
          ]
        ]
      },
      properties: { name: "Croatia", id: "HR", Continent: "Europe" },
      id: "HR"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [23.8522, 35.5358],
              [24.166, 35.595],
              [24.3128, 35.3639],
              [24.7214, 35.4249],
              [25.476, 35.3058],
              [25.7449, 35.1428],
              [24.7997, 34.9346],
              [24.4639, 35.1605],
              [23.5618, 35.2949],
              [23.8522, 35.5358]
            ]
          ],
          [[[23.4154, 38.9586], [24.0987, 38.6712], [23.759, 38.4014], [23.2519, 38.8013], [23.4154, 38.9586]]],
          [[[26.4103, 39.3296], [26.4687, 38.9727], [25.9066, 39.1392], [26.4103, 39.3296]]],
          [
            [
              [26.0394, 40.7269],
              [25.8556, 40.8439],
              [25.1042, 40.9946],
              [24.7931, 40.8574],
              [24.4771, 40.948],
              [23.8786, 40.5445],
              [23.6645, 40.2238],
              [23.312, 40.2163],
              [22.6296, 40.4953],
              [22.5922, 40.0371],
              [22.9192, 39.6293],
              [22.9658, 39.0306],
              [22.7737, 38.8004],
              [23.2528, 38.6615],
              [23.9669, 38.2748],
              [24.0552, 37.7096],
              [23.5807, 38.0107],
              [23.1938, 37.9592],
              [23.1614, 37.3339],
              [22.765, 37.3933],
              [23.0735, 36.775],
              [22.6081, 36.7798],
              [22.0803, 37.029],
              [21.8922, 36.7372],
              [21.5829, 37.0809],
              [21.5715, 37.5409],
              [21.1448, 37.9193],
              [21.4035, 38.1966],
              [21.825, 38.328],
              [22.7997, 37.9813],
              [23.1218, 38.0731],
              [22.4218, 38.4388],
              [21.4725, 38.3214],
              [21.1131, 38.3847],
              [20.7768, 38.8074],
              [20.7795, 39.0087],
              [20.301, 39.3273],
              [20.0012, 39.7097],
              [20.6574, 40.1175],
              [21.0314, 40.6584],
              [20.9642, 40.8501],
              [21.5758, 40.869],
              [21.9295, 41.1071],
              [22.4934, 41.1185],
              [22.9157, 41.3365],
              [23.6351, 41.387],
              [24.0557, 41.5272],
              [25.2514, 41.2438],
              [26.1355, 41.3857],
              [26.3211, 41.7166],
              [26.6247, 41.4016],
              [26.3307, 41.2385],
              [26.3311, 40.9546],
              [26.0394, 40.7269]
            ]
          ]
        ]
      },
      properties: { name: "Greece", id: "GR", Continent: "Europe" },
      id: "GR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-2.5124, 49.4945], [-2.5475, 49.4286], [-2.6459, 49.4681], [-2.5124, 49.4945]]]
      },
      properties: { name: "Guernsey", id: "GG", Continent: "Europe" },
      id: "GG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.4299, 41.8906],
            [46.1821, 41.6573],
            [46.6189, 41.344],
            [46.4577, 41.0702],
            [45.2808, 41.4494],
            [45.0014, 41.2912],
            [43.439, 41.1071],
            [42.7878, 41.5637],
            [42.4661, 41.4397],
            [41.5103, 41.5175],
            [41.763, 41.9702],
            [41.4888, 42.6593],
            [40.8366, 43.0635],
            [40.5242, 43.1211],
            [39.9784, 43.4199],
            [40.1502, 43.5698],
            [40.6481, 43.5342],
            [41.5806, 43.2191],
            [42.4191, 43.2243],
            [43.7822, 42.7472],
            [43.9571, 42.5665],
            [44.5061, 42.7489],
            [44.8707, 42.7564],
            [45.3437, 42.53],
            [45.7278, 42.4751],
            [45.639, 42.2048],
            [46.4299, 41.8906]
          ]
        ]
      },
      properties: { name: "Georgia", id: "GE", Continent: "Europe" },
      id: "GE"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [9.4803, 42.8056],
              [9.5506, 42.1297],
              [9.1863, 41.3848],
              [8.8075, 41.5883],
              [8.713, 42.5498],
              [9.4803, 42.8056]
            ]
          ],
          [
            [
              [5.7898, 49.5385],
              [6.3439, 49.4527],
              [6.7355, 49.1605],
              [7.4509, 49.1521],
              [8.1347, 48.9735],
              [7.8376, 48.636],
              [7.5845, 48.0643],
              [7.6153, 47.5926],
              [7.0005, 47.3228],
              [6.0601, 46.4283],
              [6.7583, 46.4157],
              [7.0211, 45.9257],
              [6.8045, 45.8148],
              [7.1534, 45.401],
              [6.6278, 45.1178],
              [7.0308, 44.7165],
              [6.9675, 44.2804],
              [7.6772, 44.0831],
              [7.4931, 43.7671],
              [7.4386, 43.7508],
              [7.4144, 43.7711],
              [7.378, 43.7315],
              [6.4942, 43.169],
              [6.1159, 43.0724],
              [5.4066, 43.2288],
              [5.0599, 43.4445],
              [4.7118, 43.3734],
              [4.0526, 43.593],
              [3.259, 43.1931],
              [3.0515, 42.9155],
              [3.2115, 42.4312],
              [2.0325, 42.3538],
              [1.7059, 42.5033],
              [1.4287, 42.596],
              [0.6969, 42.8451],
              [0.6315, 42.6896],
              [-0.0411, 42.6891],
              [-0.2993, 42.8249],
              [-1.2854, 43.0596],
              [-1.7941, 43.4076],
              [-1.4849, 43.5636],
              [-1.2456, 44.5599],
              [-1.0661, 45.806],
              [-1.1463, 46.3115],
              [-1.7866, 46.5148],
              [-2.0595, 46.8104],
              [-2.0189, 47.0377],
              [-3.1588, 47.6947],
              [-3.901, 47.8375],
              [-4.3119, 47.8228],
              [-4.6292, 48.0856],
              [-4.3932, 48.3676],
              [-4.7208, 48.5398],
              [-3.2315, 48.8408],
              [-2.6926, 48.5367],
              [-2.4462, 48.6483],
              [-1.4375, 48.6415],
              [-1.5831, 49.2022],
              [-1.8563, 49.6836],
              [-1.2586, 49.6802],
              [-1.1386, 49.388],
              [-0.1635, 49.2967],
              [0.2777, 49.4633],
              [0.1868, 49.703],
              [1.2454, 49.9981],
              [1.5513, 50.2939],
              [1.5794, 50.7393],
              [1.9125, 50.9907],
              [2.5247, 51.097],
              [2.8397, 50.7118],
              [4.1743, 50.2467],
              [4.1761, 49.9603],
              [4.656, 50.0026],
              [4.8678, 49.7881],
              [5.5072, 49.5107],
              [5.7898, 49.5385]
            ]
          ]
        ]
      },
      properties: { name: "France", id: "FR", Continent: "Europe" },
      id: "FR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-6.6311, 62.2279], [-7.0135, 62.0941], [-7.1721, 62.2856], [-6.6311, 62.2279]]]
      },
      properties: { name: "Faroe Islands", id: "FO", Continent: "Europe" },
      id: "FO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.9656, 69.0218],
            [28.4708, 68.4883],
            [28.6853, 68.1897],
            [29.3441, 68.0621],
            [29.9412, 67.5475],
            [29.087, 66.9709],
            [29.9031, 66.0911],
            [30.0955, 65.6816],
            [29.7158, 65.6246],
            [29.6042, 64.9685],
            [30.0726, 64.765],
            [30.1083, 64.366],
            [30.5038, 64.0206],
            [30.0555, 63.6888],
            [31.1809, 63.2083],
            [31.5343, 62.8855],
            [31.2856, 62.5678],
            [29.6904, 61.546],
            [27.7981, 60.5362],
            [27.2422, 60.5386],
            [25.6566, 60.3331],
            [25.1556, 60.1943],
            [23.7217, 59.9656],
            [22.7935, 60.0767],
            [22.576, 60.3591],
            [21.4361, 60.5964],
            [21.3605, 60.9675],
            [21.6061, 61.5915],
            [21.2564, 61.9897],
            [21.3539, 62.2239],
            [21.1034, 62.623],
            [22.2433, 63.4379],
            [23.2489, 63.8963],
            [24.278, 64.5152],
            [24.5579, 64.8009],
            [25.3727, 65.0096],
            [25.3481, 65.4792],
            [24.1559, 65.8052],
            [23.701, 66.4809],
            [23.9884, 66.8107],
            [23.4682, 67.4501],
            [23.639, 67.9544],
            [22.7821, 68.391],
            [21.9976, 68.5207],
            [20.6222, 69.037],
            [21.6219, 69.2708],
            [22.4107, 68.7197],
            [23.324, 68.649],
            [23.854, 68.8058],
            [24.9416, 68.5933],
            [25.7484, 68.9902],
            [26.0116, 69.6527],
            [26.5258, 69.915],
            [27.8899, 70.0618],
            [29.1414, 69.6716],
            [29.3335, 69.4731],
            [28.9656, 69.0218]
          ]
        ]
      },
      properties: { name: "Finland", id: "FI", Continent: "Europe" },
      id: "FI"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [28.0125, 59.4842],
              [27.4271, 58.7331],
              [27.4882, 58.2702],
              [27.7532, 57.841],
              [27.3515, 57.5281],
              [26.5329, 57.531],
              [25.991, 57.8382],
              [25.1108, 58.0634],
              [24.3228, 57.8707],
              [24.5289, 58.3543],
              [23.7679, 58.3609],
              [23.5094, 58.6587],
              [23.4944, 59.1957],
              [24.3804, 59.4728],
              [25.4439, 59.5211],
              [25.5093, 59.6388],
              [26.9749, 59.4507],
              [28.0125, 59.4842]
            ]
          ],
          [[[22.6173, 58.6213], [23.2928, 58.4835], [22.1529, 58.1153], [22.0816, 58.4781], [22.6173, 58.6213]]]
        ]
      },
      properties: { name: "Estonia", id: "EE", Continent: "Europe" },
      id: "EE"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[-15.4006, 28.1472], [-15.389, 27.8747], [-15.7102, 27.7842], [-15.6828, 28.1542], [-15.4006, 28.1472]]],
          [[[-16.3345, 28.3801], [-16.6579, 28.007], [-16.9054, 28.3396], [-16.3345, 28.3801]]],
          [[[-14.1968, 28.1691], [-14.0034, 28.7067], [-13.8276, 28.5849], [-13.928, 28.2535], [-14.1968, 28.1691]]],
          [
            [
              [3.1456, 39.7901],
              [3.462, 39.6978],
              [3.0726, 39.3009],
              [2.3713, 39.6129],
              [2.7848, 39.8547],
              [3.1456, 39.7901]
            ]
          ],
          [
            [
              [-1.7941, 43.4076],
              [-1.2854, 43.0596],
              [-0.2993, 42.8249],
              [-0.0411, 42.6891],
              [0.6315, 42.6896],
              [0.6969, 42.8451],
              [1.4287, 42.596],
              [1.4862, 42.4347],
              [1.7059, 42.5033],
              [2.0325, 42.3538],
              [3.2115, 42.4312],
              [3.248, 41.9443],
              [2.0826, 41.2877],
              [1.0331, 41.0619],
              [0.7145, 40.8228],
              [0.3639, 40.3188],
              [-0.3269, 39.5198],
              [-0.2048, 39.0627],
              [0.2015, 38.7591],
              [-0.3812, 38.4356],
              [-0.6467, 38.1518],
              [-0.822, 37.5809],
              [-1.3274, 37.5611],
              [-1.7976, 37.2329],
              [-2.1116, 36.7767],
              [-2.7877, 36.7147],
              [-3.259, 36.7557],
              [-4.3669, 36.7183],
              [-4.6742, 36.5065],
              [-5.1715, 36.4239],
              [-5.6255, 36.0261],
              [-6.0407, 36.1883],
              [-6.4923, 36.9547],
              [-6.8845, 37.1942],
              [-7.4061, 37.1793],
              [-7.5037, 37.5853],
              [-7.1855, 38.0063],
              [-7.3431, 38.4572],
              [-7.0462, 38.9067],
              [-7.4452, 39.5365],
              [-7.0368, 39.714],
              [-7.0146, 40.2084],
              [-6.8102, 40.3429],
              [-6.9284, 41.0091],
              [-6.2126, 41.5321],
              [-6.5422, 41.6722],
              [-6.6182, 41.942],
              [-7.4037, 41.8336],
              [-7.9207, 41.8837],
              [-8.2132, 42.1337],
              [-8.777, 41.9407],
              [-8.6911, 42.2743],
              [-9.2351, 42.977],
              [-8.8737, 43.3343],
              [-8.5368, 43.3368],
              [-8.2569, 43.5799],
              [-7.6981, 43.7645],
              [-7.262, 43.5948],
              [-5.6659, 43.5825],
              [-4.5231, 43.416],
              [-3.6046, 43.5192],
              [-3.0457, 43.3716],
              [-2.337, 43.3281],
              [-1.7941, 43.4076]
            ]
          ]
        ]
      },
      properties: { name: "Spain", id: "ES", Continent: "Europe" },
      id: "ES"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-6.218, 54.0888],
              [-6.6498, 54.0587],
              [-6.9364, 54.3744],
              [-7.3554, 54.1213],
              [-8.1182, 54.4142],
              [-7.2185, 55.0921],
              [-6.1293, 55.2174],
              [-5.4703, 54.5001],
              [-5.6067, 54.2726],
              [-6.218, 54.0888]
            ]
          ],
          [
            [
              [-3.1096, 58.5154],
              [-3.2124, 58.3212],
              [-3.9902, 57.9591],
              [-3.8681, 57.6002],
              [-3.4029, 57.7081],
              [-2.074, 57.7023],
              [-1.7781, 57.4939],
              [-2.6528, 56.3184],
              [-3.1783, 56.0802],
              [-2.1472, 55.9031],
              [-1.6554, 55.5704],
              [-1.2918, 54.7738],
              [-0.6715, 54.5038],
              [-0.2054, 54.0217],
              [0.559, 52.9671],
              [1.0556, 52.9589],
              [1.6567, 52.7537],
              [1.7468, 52.469],
              [1.5592, 52.0869],
              [0.9554, 51.8078],
              [0.6869, 51.3865],
              [1.415, 51.3633],
              [0.9602, 50.926],
              [0.205, 50.763],
              [-1.5168, 50.7477],
              [-2.5477, 50.6164],
              [-2.9993, 50.7166],
              [-3.4047, 50.6322],
              [-3.7934, 50.2292],
              [-4.1726, 50.3908],
              [-5.3422, 50.2462],
              [-4.5831, 50.7764],
              [-4.1882, 51.1886],
              [-3.136, 51.2048],
              [-4.3864, 51.7412],
              [-4.9024, 51.6263],
              [-5.0882, 51.9959],
              [-4.3831, 52.1971],
              [-3.9804, 52.5417],
              [-4.1111, 53.2189],
              [-3.0646, 53.4268],
              [-2.925, 53.7326],
              [-3.1658, 54.1279],
              [-3.5692, 54.4675],
              [-3.4341, 54.9637],
              [-3.9579, 54.7809],
              [-5.1355, 54.8576],
              [-4.7213, 55.421],
              [-5.2458, 55.9292],
              [-5.6097, 56.0554],
              [-5.3919, 56.5147],
              [-5.9368, 56.6058],
              [-5.562, 57.2329],
              [-5.8182, 57.4361],
              [-5.3383, 58.2388],
              [-4.7156, 58.5101],
              [-3.0531, 58.6347],
              [-3.1096, 58.5154]
            ]
          ]
        ]
      },
      properties: { name: "United Kingdom", id: "GB", Continent: "Europe" },
      id: "GB"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[10.6449, 55.61], [10.785, 55.1334], [9.9888, 55.163], [9.8605, 55.5157], [10.6449, 55.61]]],
          [
            [
              [12.5688, 55.7851],
              [12.215, 55.4665],
              [12.0502, 54.8154],
              [11.6539, 55.1869],
              [11.2865, 55.2044],
              [10.9788, 55.7216],
              [12.219, 56.1187],
              [12.5688, 55.7851]
            ]
          ],
          [
            [
              [9.74, 54.8255],
              [8.67, 54.9036],
              [8.6159, 55.4181],
              [8.1321, 55.5998],
              [8.1642, 56.6069],
              [8.6186, 57.1113],
              [9.5542, 57.2327],
              [9.9624, 57.5811],
              [10.5372, 57.4486],
              [10.296, 56.9991],
              [10.2828, 56.6204],
              [10.8461, 56.5216],
              [10.8563, 56.2954],
              [10.3188, 56.2128],
              [10.183, 55.865],
              [9.5911, 55.493],
              [9.74, 54.8255]
            ]
          ]
        ]
      },
      properties: { name: "Denmark", id: "DK", Continent: "Europe" },
      id: "DK"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.74, 54.8255],
            [10.3605, 54.4384],
            [11.3994, 53.9446],
            [12.1113, 54.1683],
            [12.5754, 54.4673],
            [13.0289, 54.4108],
            [13.8656, 53.8534],
            [14.2585, 53.7295],
            [14.4145, 53.2834],
            [14.1288, 52.8783],
            [14.6197, 52.5285],
            [14.7529, 52.0817],
            [14.6017, 51.8324],
            [15.0166, 51.2528],
            [14.8096, 50.859],
            [14.3692, 50.8985],
            [12.4524, 50.3497],
            [12.277, 50.1814],
            [12.3903, 49.7398],
            [12.6316, 49.4613],
            [13.8146, 48.7668],
            [13.4095, 48.3942],
            [12.8144, 48.1608],
            [12.9537, 47.8909],
            [12.6861, 47.6692],
            [11.7172, 47.5838],
            [11.2979, 47.4246],
            [10.4304, 47.5412],
            [10.3131, 47.3133],
            [9.7488, 47.5756],
            [9.5243, 47.5242],
            [8.6177, 47.7662],
            [8.1984, 47.6068],
            [7.6153, 47.5926],
            [7.5845, 48.0643],
            [7.8376, 48.636],
            [8.1347, 48.9735],
            [7.4509, 49.1521],
            [6.7355, 49.1605],
            [6.3439, 49.4527],
            [6.4876, 49.7984],
            [6.1163, 50.121],
            [6.3646, 50.3162],
            [5.9937, 50.7504],
            [5.8948, 50.9843],
            [6.1932, 51.4887],
            [5.9484, 51.7625],
            [6.7416, 51.9111],
            [7.0352, 52.3802],
            [7.1974, 53.2824],
            [7.2853, 53.6814],
            [8.009, 53.6908],
            [8.5386, 53.5568],
            [8.898, 53.8356],
            [8.9573, 54.5383],
            [8.67, 54.9036],
            [9.74, 54.8255]
          ]
        ]
      },
      properties: { name: "Germany", id: "DE", Continent: "Europe" },
      id: "DE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.8096, 50.859],
            [15.3541, 50.812],
            [16.007, 50.6116],
            [16.6794, 50.0974],
            [17.1519, 50.3784],
            [17.8747, 49.9721],
            [18.562, 49.8792],
            [18.8323, 49.5107],
            [18.1608, 49.2575],
            [17.7583, 48.8881],
            [17.1884, 48.861],
            [16.9532, 48.5989],
            [16.4777, 48.8002],
            [16.0571, 48.7549],
            [15.4033, 48.9573],
            [14.9722, 48.9838],
            [14.6914, 48.5994],
            [14.0493, 48.6024],
            [13.8146, 48.7668],
            [12.6316, 49.4613],
            [12.3903, 49.7398],
            [12.277, 50.1814],
            [12.4524, 50.3497],
            [14.3692, 50.8985],
            [14.8096, 50.859]
          ]
        ]
      },
      properties: { name: "Czechia", id: "CZ", Continent: "Europe" },
      id: "CZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.4632, 35.5937],
            [33.9415, 35.2919],
            [34.0501, 34.9881],
            [32.9418, 34.576],
            [32.4488, 34.7293],
            [32.3169, 34.953],
            [32.9418, 35.3903],
            [33.4587, 35.3358],
            [34.4632, 35.5937]
          ]
        ]
      },
      properties: { name: "Cyprus", id: "CY", Continent: "Europe" },
      id: "CY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.5243, 47.5242],
            [9.5278, 47.2707],
            [9.4878, 47.0621],
            [9.5801, 47.0573],
            [9.9967, 46.8855],
            [10.4529, 46.8651],
            [9.9391, 46.3616],
            [9.2513, 46.2865],
            [8.9538, 45.8299],
            [8.4586, 46.2459],
            [7.9932, 46.0157],
            [7.0211, 45.9257],
            [6.7583, 46.4157],
            [6.0601, 46.4283],
            [7.0005, 47.3228],
            [7.6153, 47.5926],
            [8.1984, 47.6068],
            [8.6177, 47.7662],
            [9.5243, 47.5242]
          ]
        ]
      },
      properties: { name: "Switzerland", id: "CH", Continent: "Europe" },
      id: "CH"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.694, 50.7748],
            [5.7973, 50.7546],
            [5.9937, 50.7504],
            [6.3646, 50.3162],
            [6.1163, 50.121],
            [5.7441, 49.9197],
            [5.7898, 49.5385],
            [5.5072, 49.5107],
            [4.8678, 49.7881],
            [4.656, 50.0026],
            [4.1761, 49.9603],
            [4.1743, 50.2467],
            [2.8397, 50.7118],
            [2.5247, 51.097],
            [3.35, 51.3776],
            [3.9023, 51.2075],
            [4.2262, 51.3865],
            [4.5035, 51.4746],
            [5.5085, 51.2751],
            [5.8271, 51.1257],
            [5.694, 50.7748]
          ]
        ]
      },
      properties: { name: "Belgium", id: "BE", Continent: "Europe" },
      id: "BE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.1483, 56.143],
            [28.7948, 55.9426],
            [29.3748, 55.9386],
            [29.4825, 55.6844],
            [30.2335, 55.8452],
            [30.9067, 55.5699],
            [30.9779, 55.0505],
            [30.799, 54.7833],
            [31.1212, 54.6484],
            [31.4038, 54.1958],
            [31.7918, 54.0558],
            [32.7041, 53.3362],
            [32.142, 53.0912],
            [31.4179, 53.196],
            [31.5773, 52.3123],
            [31.7636, 52.1011],
            [30.9805, 52.0462],
            [30.5332, 51.5964],
            [29.3466, 51.3824],
            [29.1357, 51.6173],
            [28.5992, 51.5427],
            [27.2702, 51.6135],
            [27.1424, 51.752],
            [25.9251, 51.9137],
            [24.3615, 51.8676],
            [23.6053, 51.5179],
            [23.5982, 52.1031],
            [23.197, 52.2569],
            [23.8447, 52.6642],
            [23.8874, 53.0274],
            [23.4844, 53.9398],
            [24.7685, 53.9745],
            [25.5471, 54.3318],
            [25.8592, 54.9191],
            [26.1751, 55.0033],
            [26.5935, 55.6675],
            [27.0527, 55.8306],
            [27.577, 55.7987],
            [28.1483, 56.143]
          ]
        ]
      },
      properties: { name: "Belarus", id: "BY", Continent: "Europe" },
      id: "BY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.0072, 44.8693],
            [19.357, 44.8587],
            [19.1518, 44.3023],
            [19.4879, 43.7034],
            [19.1944, 43.5333],
            [18.9404, 43.4969],
            [18.4438, 42.9686],
            [18.4363, 42.5595],
            [17.6677, 42.897],
            [17.5851, 42.9383],
            [17.6247, 43.0424],
            [16.214, 44.2149],
            [16.1301, 44.4738],
            [15.7369, 44.7658],
            [15.7877, 45.1791],
            [16.2936, 45.0085],
            [16.5305, 45.2169],
            [17.8128, 45.078],
            [18.6627, 45.0775],
            [19.0072, 44.8693]
          ]
        ]
      },
      properties: { name: "Bosnia and Herzegovina", id: "BA", Continent: "Europe" },
      id: "BA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.5855, 43.7421],
            [28.4655, 43.3892],
            [27.929, 43.1861],
            [27.889, 42.7498],
            [27.4847, 42.4681],
            [28.0142, 41.9689],
            [27.5348, 41.921],
            [27.2948, 42.0796],
            [26.6155, 41.965],
            [26.3211, 41.7166],
            [26.1355, 41.3857],
            [25.2514, 41.2438],
            [24.0557, 41.5272],
            [23.6351, 41.387],
            [22.9157, 41.3365],
            [23.0058, 41.717],
            [22.837, 41.9939],
            [22.344, 42.3139],
            [22.5237, 42.4412],
            [22.4666, 42.8425],
            [22.9768, 43.1879],
            [22.5544, 43.4547],
            [22.4209, 44.0075],
            [22.7052, 44.2377],
            [23.2242, 43.8739],
            [25.497, 43.6709],
            [26.2161, 44.007],
            [27.0865, 44.1675],
            [27.8846, 43.9873],
            [28.0499, 43.8225],
            [28.5855, 43.7421]
          ]
        ]
      },
      properties: { name: "Bulgaria", id: "BG", Continent: "Europe" },
      id: "BG"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [46.1145, 38.8778],
              [45.4799, 39.0065],
              [44.8172, 39.6503],
              [44.7684, 39.7035],
              [45.0317, 39.765],
              [45.7659, 39.3783],
              [46.1145, 38.8778]
            ]
          ],
          [
            [
              [48.5727, 41.8445],
              [49.1063, 41.3018],
              [49.2262, 41.0263],
              [49.776, 40.5837],
              [50.3068, 40.4124],
              [49.5514, 40.1943],
              [49.3242, 39.6081],
              [49.3629, 39.3492],
              [49.0136, 39.1344],
              [48.851, 38.8154],
              [48.8685, 38.4356],
              [48.593, 38.411],
              [48.0234, 38.8188],
              [48.2423, 38.9789],
              [47.9957, 39.6842],
              [47.773, 39.6486],
              [46.4907, 38.9063],
              [46.5499, 39.2012],
              [46.2019, 39.5945],
              [45.7897, 39.8814],
              [45.9646, 40.2339],
              [45.3762, 40.6383],
              [45.5875, 40.8465],
              [45.0014, 41.2912],
              [45.2808, 41.4494],
              [46.4577, 41.0702],
              [46.6189, 41.344],
              [46.1821, 41.6573],
              [46.4299, 41.8906],
              [46.7494, 41.8124],
              [47.261, 41.3149],
              [47.8609, 41.213],
              [48.3912, 41.602],
              [48.5727, 41.8445]
            ]
          ]
        ]
      },
      properties: { name: "Azerbaijan", id: "AZ", Continent: "Europe" },
      id: "AZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.9532, 48.5989],
            [16.8627, 48.4414],
            [17.1474, 48.0059],
            [17.0666, 47.7074],
            [16.7475, 47.6862],
            [16.4342, 47.3676],
            [16.4531, 47.0065],
            [16.0928, 46.8633],
            [15.9574, 46.6776],
            [15.0007, 46.6262],
            [14.5037, 46.417],
            [13.6999, 46.5201],
            [12.3882, 46.7025],
            [12.1544, 46.9352],
            [11.2443, 46.9755],
            [11.025, 46.7972],
            [10.4529, 46.8651],
            [9.9967, 46.8855],
            [9.5801, 47.0573],
            [9.5278, 47.2707],
            [9.5243, 47.5242],
            [9.7488, 47.5756],
            [10.3131, 47.3133],
            [10.4304, 47.5412],
            [11.2979, 47.4246],
            [11.7172, 47.5838],
            [12.6861, 47.6692],
            [12.9537, 47.8909],
            [12.8144, 48.1608],
            [13.4095, 48.3942],
            [13.8146, 48.7668],
            [14.0493, 48.6024],
            [14.6914, 48.5994],
            [14.9722, 48.9838],
            [15.4033, 48.9573],
            [16.0571, 48.7549],
            [16.4777, 48.8002],
            [16.9532, 48.5989]
          ]
        ]
      },
      properties: { name: "Austria", id: "AT", Continent: "Europe" },
      id: "AT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.4907, 38.9063],
            [46.1145, 38.8778],
            [45.7659, 39.3783],
            [45.0317, 39.765],
            [44.7684, 39.7035],
            [44.2894, 40.0405],
            [43.792, 40.0704],
            [43.5691, 40.4821],
            [43.7225, 40.7196],
            [43.439, 41.1071],
            [45.0014, 41.2912],
            [45.5875, 40.8465],
            [45.3762, 40.6383],
            [45.9646, 40.2339],
            [45.7897, 39.8814],
            [46.2019, 39.5945],
            [46.5499, 39.2012],
            [46.4907, 38.9063]
          ]
        ]
      },
      properties: { name: "Armenia", id: "AM", Continent: "Europe" },
      id: "AM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[1.7059, 42.5033], [1.4862, 42.4347], [1.4287, 42.596], [1.7059, 42.5033]]]
      },
      properties: { name: "Andorra", id: "AD", Continent: "Europe" },
      id: "AD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[19.9894, 60.3512], [20.2588, 60.2611], [19.7459, 60.099], [19.6871, 60.2677], [19.9894, 60.3512]]
        ]
      },
      properties: { name: "Aland Islands", id: "AX", Continent: "Europe" },
      id: "AX"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.0641, 42.5472],
            [20.4855, 42.2233],
            [20.5659, 41.8739],
            [20.4486, 41.5215],
            [20.7092, 40.9282],
            [20.9642, 40.8501],
            [21.0314, 40.6584],
            [20.6574, 40.1175],
            [20.0012, 39.7097],
            [19.8518, 40.0437],
            [19.3979, 40.2849],
            [19.3372, 40.6636],
            [19.4612, 40.933],
            [19.4405, 41.4248],
            [19.5776, 41.7874],
            [19.3426, 41.8691],
            [19.2809, 42.1727],
            [19.6546, 42.6285],
            [20.0641, 42.5472]
          ]
        ]
      },
      properties: { name: "Albania", id: "AL", Continent: "Europe" },
      id: "AL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.4061, 37.1793],
            [-7.8343, 37.0057],
            [-8.5977, 37.1211],
            [-8.8141, 37.431],
            [-8.9147, 38.5123],
            [-9.4742, 38.7307],
            [-9.3748, 39.3384],
            [-9.1482, 39.5426],
            [-8.8867, 40.1795],
            [-8.6557, 41.0296],
            [-8.7771, 41.9409],
            [-8.2131, 42.1336],
            [-7.9208, 41.8836],
            [-7.4037, 41.8335],
            [-6.6182, 41.9422],
            [-6.2125, 41.5321],
            [-6.9285, 41.0092],
            [-6.8102, 40.3429],
            [-7.0146, 40.2084],
            [-7.0368, 39.7139],
            [-7.4452, 39.5363],
            [-7.0461, 38.9069],
            [-7.3431, 38.4572],
            [-7.1855, 38.0062],
            [-7.5036, 37.5854],
            [-7.4061, 37.1793]
          ]
        ]
      },
      properties: { name: "Portugal", id: "PT", Continent: "Europe"  },
      id: "PT"
    }
  ]
};
export default map;
