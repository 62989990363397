var map = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: "US-MS",
      properties: { name: "US-MS", id: "Mississippi", CNTRY: "United States of America", TYPE: "State" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-88.1733, 34.999],
              [-89.2418, 35.0],
              [-90.2938, 35.0012],
              [-90.3198, 34.8992],
              [-90.5392, 34.7281],
              [-90.5841, 34.5037],
              [-90.718, 34.3917],
              [-90.8172, 34.2522],
              [-90.9097, 34.1888],
              [-90.9431, 34.0297],
              [-91.0702, 33.9743],
              [-91.0528, 33.8276],
              [-91.2076, 33.6756],
              [-91.2146, 33.5834],
              [-91.1411, 33.4386],
              [-91.1633, 33.2944],
              [-91.1068, 33.2179],
              [-91.15, 33.0159],
              [-91.0961, 32.9376],
              [-91.1424, 32.6611],
              [-91.1038, 32.4905],
              [-90.9447, 32.3307],
              [-90.9477, 32.2401],
              [-91.0887, 32.1042],
              [-91.1566, 31.9707],
              [-91.2864, 31.884],
              [-91.3725, 31.7736],
              [-91.4146, 31.6399],
              [-91.529, 31.4488],
              [-91.529, 31.3451],
              [-91.6307, 31.2176],
              [-91.5963, 31.1099],
              [-91.6565, 31.0018],
              [-91.1769, 31.0012],
              [-89.7353, 31.0011],
              [-89.8354, 30.7308],
              [-89.82, 30.6112],
              [-89.6858, 30.4627],
              [-89.5917, 30.223],
              [-89.521, 30.1926],
              [-89.3005435, 30.2641587],
              [-89.1978261, 30.2935065],
              [-88.9051, 30.4151],
              [-88.6921, 30.3554],
              [-88.3996, 30.3709],
              [-88.4443, 31.1397],
              [-88.4862, 31.8904],
              [-88.3397, 32.9988],
              [-88.1688, 34.292],
              [-88.0847, 34.9331],
              [-88.1733, 34.999]
            ].reverse()
          ]
        ]
      }
    },
    {
      type: "Feature",
      id: "US-OK",
      properties: { name: "US-OK", id: "Oklahoma", CNTRY: "United States of America", TYPE: "State" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-94.6184, 36.5009],
              [-94.6181, 37.0009],
              [-95.5424, 37.0009],
              [-96.4668, 37.0009],
              [-97.1601, 37.0009],
              [-98.0844, 37.0009],
              [-99.0086, 37.0009],
              [-99.9329, 37.0009],
              [-101.0885, 37.0009],
              [-102.0128, 37.0009],
              [-103.0008, 37.0009],
              [-103.0002, 36.5012],
              [-102.0636, 36.5012],
              [-101.314, 36.5011],
              [-100.0023, 36.5009],
              [-100.0005, 35.3045],
              [-99.9995, 34.5867],
              [-99.867, 34.5434],
              [-99.6321, 34.3885],
              [-99.405, 34.392],
              [-99.3603, 34.4561],
              [-99.1999, 34.3183],
              [-99.1866, 34.2355],
              [-98.9688, 34.2086],
              [-98.7989, 34.1547],
              [-98.6085, 34.1612],
              [-98.4938, 34.0888],
              [-98.3559, 34.1414],
              [-98.0987, 34.145],
              [-98.0695, 34.0404],
              [-97.8424, 33.8757],
              [-97.6604, 33.9901],
              [-97.4302, 33.8385],
              [-97.2149, 33.8971],
              [-97.1724, 33.7568],
              [-96.9777, 33.938],
              [-96.7464, 33.8542],
              [-96.5917, 33.8492],
              [-96.4111, 33.7752],
              [-96.2785, 33.7576],
              [-95.9416, 33.8828],
              [-95.7839, 33.8645],
              [-95.6228, 33.9303],
              [-95.3739, 33.8743],
              [-95.238, 33.9565],
              [-94.9624, 33.8433],
              [-94.7091, 33.6994],
              [-94.4841, 33.6485],
              [-94.4616, 34.5178],
              [-94.4391, 35.3872],
              [-94.6184, 36.5009]
            ].reverse()
          ]
        ]
      }
    },
    {
      type: "Feature",
      id: "US-SC",
      properties: { name: "US-SC", id: "South Carolina", CNTRY: "United States of America", TYPE: "State" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-78.5643, 33.8767],
              [-78.9784, 34.2261],
              [-79.6535, 34.7968],
              [-80.7841, 34.8286],
              [-80.793, 34.9628],
              [-80.9337, 35.1136],
              [-81.0451, 35.0657],
              [-81.0874, 35.1556],
              [-82.356, 35.2015],
              [-83.1149, 35.0015],
              [-83.3167, 34.8057],
              [-83.3557, 34.7083],
              [-83.0528, 34.5109],
              [-82.8478, 34.4369],
              [-82.8187, 34.3661],
              [-82.5894, 34.0176],
              [-82.2566, 33.7493],
              [-82.2084, 33.6636],
              [-82.0057, 33.5229],
              [-81.9329, 33.3899],
              [-81.6994, 33.1267],
              [-81.5783, 33.0686],
              [-81.4897, 32.9356],
              [-81.377, 32.6075],
              [-81.2903, 32.5573],
              [-81.1714, 32.3801],
              [-81.1351, 32.1818],
              [-81.0748, 32.1097],
              [-80.8724, 32.0295],
              [-80.8492, 32.114],
              [-80.6942, 32.2157],
              [-80.6588315, 32.2365772],
              [-80.6282609, 32.2536967],
              [-80.5891304, 32.2805989],
              [-80.4857, 32.3517],
              [-80.3690217, 32.4248924],
              [-80.1953804, 32.5349467],
              [-80.0217, 32.6199],
              [-79.8936, 32.7287],
              [-79.615, 32.9093],
              [-79.5411685, 32.961713],
              [-79.42, 33.0425],
              [-79.276, 33.1354],
              [-79.1937, 33.2441],
              [-79.0899457, 33.390925],
              [-78.8307065, 33.7088598],
              [-78.5643, 33.8767]
            ].reverse()
          ]
        ]
      }
    },
    {
      type: "Feature",
      id: "US-TX",
      properties: { name: "US-TX", id: "Texas", CNTRY: "United States of America", TYPE: "State" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-97.1708, 26.1594],
              [-97.3512, 26.8014],
              [-97.4021, 26.8205],
              [-97.2673, 26.3299],
              [-97.1708, 26.1594]
            ].reverse()
          ],
          [
            [
              [-97.0144, 27.9017],
              [-96.8397, 28.0889],
              [-96.8992, 28.1174],
              [-97.0144, 27.9017]
            ].reverse()
          ],
          [
            [
              [-94.4841, 33.6485],
              [-94.7091, 33.6994],
              [-94.9624, 33.8433],
              [-95.238, 33.9565],
              [-95.3739, 33.8743],
              [-95.6228, 33.9303],
              [-95.7839, 33.8645],
              [-95.9416, 33.8828],
              [-96.2785, 33.7576],
              [-96.4111, 33.7752],
              [-96.5917, 33.8492],
              [-96.7464, 33.8542],
              [-96.9777, 33.938],
              [-97.1724, 33.7568],
              [-97.2149, 33.8971],
              [-97.4302, 33.8385],
              [-97.6604, 33.9901],
              [-97.8424, 33.8757],
              [-98.0695, 34.0404],
              [-98.0987, 34.145],
              [-98.3559, 34.1414],
              [-98.4938, 34.0888],
              [-98.6085, 34.1612],
              [-98.7989, 34.1547],
              [-98.9688, 34.2086],
              [-99.1866, 34.2355],
              [-99.1999, 34.3183],
              [-99.3603, 34.4561],
              [-99.405, 34.392],
              [-99.6321, 34.3885],
              [-99.867, 34.5434],
              [-99.9995, 34.5867],
              [-100.0005, 35.3045],
              [-100.0023, 36.5009],
              [-101.314, 36.5011],
              [-102.0636, 36.5012],
              [-103.0002, 36.5012],
              [-103.0422, 36.5006],
              [-103.048, 35.3761],
              [-103.0539, 34.2517],
              [-103.0582, 33.4084],
              [-103.0613, 32.8461],
              [-103.0656, 32.0027],
              [-103.9648, 32.0023],
              [-105.0886, 32.0019],
              [-105.9879, 32.0015],
              [-106.6683, 32.001],
              [-106.5664, 31.8196],
              [-106.4454, 31.7684],
              [-106.2558, 31.5446],
              [-106.148, 31.4508],
              [-106.0813859, 31.3916043],
              [-105.954212, 31.3084522],
              [-105.8319293, 31.2399739],
              [-105.514, 30.9808],
              [-105.2758, 30.8073],
              [-105.0248641, 30.6285609],
              [-104.8695652, 30.4206804],
              [-104.7423913, 30.1369848],
              [-104.6812, 29.9905],
              [-104.6223, 29.8543],
              [-104.5041, 29.6777],
              [-104.4007, 29.5738],
              [-104.3122, 29.5425],
              [-104.1107, 29.3862],
              [-103.7164402, 29.1856261],
              [-103.423, 29.0707],
              [-103.2576, 29.0011],
              [-103.0901, 29.0419],
              [-103.0227, 29.1322],
              [-102.8919, 29.2164],
              [-102.7919837, 29.4350826],
              [-102.7342, 29.644],
              [-102.5816576, 29.7089957],
              [-102.4763, 29.769],
              [-102.269, 29.8712],
              [-101.9909, 29.7958],
              [-101.4405, 29.7768],
              [-101.3035, 29.6341],
              [-101.0910326, 29.4497565],
              [-101.0225543, 29.3666043],
              [-100.7877717, 29.1758435],
              [-100.6586, 29.0686],
              [-100.5497, 28.8214],
              [-100.3964674, 28.4886152],
              [-100.296, 28.3277],
              [-100.1494565, 28.1560065],
              [-100.0014, 28.0479],
              [-99.8896, 27.8673],
              [-99.7543, 27.73],
              [-99.5053, 27.5483],
              [-99.5101, 27.3403],
              [-99.467731, 27.1850826],
              [-99.4436, 27.0366],
              [-99.3576766, 26.8842674],
              [-99.2867527, 26.7424196],
              [-99.1721, 26.5641],
              [-99.1077, 26.4468],
              [-99.0153, 26.399],
              [-98.7878397, 26.3193217],
              [-98.5983, 26.2378],
              [-98.4992527, 26.211713],
              [-98.275, 26.1113],
              [-98.0828, 26.0645],
              [-97.8199728, 25.9818217],
              [-97.6120924, 25.9280174],
              [-97.3582, 25.8705],
              [-97.2574728, 25.9060065],
              [-97.1462, 25.9614],
              [-97.4024, 26.3965],
              [-97.4658, 26.6917],
              [-97.5547, 26.9673],
              [-97.4757, 27.1179],
              [-97.4482337, 27.1899739],
              [-97.4408967, 27.2168761],
              [-97.4286685, 27.2339957],
              [-97.4115489, 27.2633435],
              [-97.396875, 27.3073652],
              [-97.3626359, 27.3954087],
              [-97.2501359, 27.6632076],
              [-97.2183424, 27.7341315],
              [-97.1914402, 27.7977185],
              [-97.1714, 27.8796],
              [-97.0731, 27.9862],
              [-97.1565, 28.1444],
              [-96.9667, 28.1895],
              [-96.8916, 28.1576],
              [-96.8068, 28.2202],
              [-96.7414402, 28.3064141],
              [-96.665625, 28.3186424],
              [-96.5618, 28.3671],
              [-96.5097147, 28.4714957],
              [-96.4828125, 28.5130717],
              [-96.4534647, 28.5448652],
              [-96.4488, 28.5944],
              [-96.282269, 28.5986696],
              [-96.0204, 28.5869],
              [-95.8534, 28.6404],
              [-95.2735, 28.9638],
              [-95.1522, 29.0792],
              [-95.0404891, 29.192963],
              [-94.8883, 29.3705],
              [-94.8766304, 29.36905],
              [-94.8741848, 29.3714957],
              [-94.8668478, 29.376387],
              [-94.8497283, 29.3739413],
              [-94.8350543, 29.3837239],
              [-94.8154891, 29.3837239],
              [-94.8105978, 29.3837239],
              [-94.8008152, 29.3935065],
              [-94.7501, 29.418],
              [-94.0996, 29.6704],
              [-93.8905, 29.6894],
              [-93.9462, 29.815],
              [-93.8414, 29.9798],
              [-93.794, 29.9772],
              [-93.7106, 30.1128],
              [-93.7504, 30.3448],
              [-93.7202, 30.5583],
              [-93.5792, 30.8238],
              [-93.5306, 31.0462],
              [-93.5577, 31.1804],
              [-93.6659, 31.3226],
              [-93.7376, 31.5138],
              [-93.8206, 31.604],
              [-93.8269, 31.7503],
              [-93.905, 31.877],
              [-94.0434, 31.9992],
              [-94.0413, 33.012],
              [-94.0472, 33.5544],
              [-94.2947, 33.5875],
              [-94.3778, 33.5661],
              [-94.4841, 33.6485]
            ].reverse()
          ]
        ]
      }
    }
  ]
};
export default map;
