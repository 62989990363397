import React from 'react'
import { cArea2Treaties } from '../../config/constants'
import i18n from '../../config/i18n'
import routes from '../../config/routes'
import { citeEntry } from '../../lib/cite'
import { i18ndayjs } from '../../lib/fnDates'
import { ApplyColumnFilter } from '../components/Table/ColumnFilter'
import GenericDropdownFilter, {
  ApplyGenericDropdownFilter
} from '../components/Table/GenericDropdownFilter'
import JurFilter, { ApplyJurFilter } from '../components/Table/JurFilter'

export const citeA2 = (entry, setToast) => {
  let lines = [
    '<p>' +
      `${
        entry.motherEntry.subjurisdiction
          ? entry.motherEntry.subjurisdiction.name +
            ' (' +
            entry.motherEntry.jurisdiction.name +
            ')'
          : entry.motherEntry.jurisdiction.name
      }: ${entry.treaty_body.name}` +
      '</p>'
  ]

  lines.push(
    '<p>' + i18n.t('Signed') + ': ' + (entry.sign ? '-' : 'NO') + '</p>'
  )
  lines.push(
    '<p>' +
      i18n.t('Ratified/Adhered') +
      ': ' +
      (entry.ras ? '-' : 'NO') +
      '</p>'
  )
  if (entry.accepted_complaints)
    lines.push(
      '<p>' +
        i18n.t('Accepts individual communications') +
        '? ' +
        i18n.t('Yes') +
        ' (' +
        i18ndayjs(entry.accepted_complaints, 'YYYY') +
        ')' +
        '</p>'
    )
  if (entry.accepted_inquiry)
    lines.push(
      '<p>' +
        i18n.t('Accepts inquiry proceeding') +
        i18n.t('Yes') +
        ' (' +
        i18ndayjs(entry.accepted_inquiry, 'YYYY') +
        ')' +
        '</p>'
    )

  citeEntry(
    routes().find(r => r.areaNumber === 2),
    entry.motherEntry.subjurisdiction
      ? entry.motherEntry.subjurisdiction.name +
          ' (' +
          entry.motherEntry.jurisdiction.name +
          ')'
      : entry.motherEntry.jurisdiction.name,
    lines.join(''),
    setToast
  )
}

const getBgColorClass = (cell, col) => {
  return cell.row.original[col] && cell.row.original[col] !== '-'
    ? 'rowProtectionYes'
    : 'rowProtectionNo'
}

const TreatyDropdown = ({ column }) => (
  <GenericDropdownFilter
    width="330px"
    column={column}
    options={cArea2Treaties().map(t => ({ id: t.fullname, value: t.name }))}
  />
)

export const a2columns = () => [
  {
    Header: i18n.t('Jurisdiction'),
    disableGroupBy: true,
    accessor: 'motherEntry.jurisdiction.name',
    enableRowSpan: true,
    filter: ApplyJurFilter,
    Filter: ({ column }) => {
      return <JurFilter column={column} search={true} />
    },
    Cell: ({ value, row }) => {
      return (
        <div
          className={
            'jurCell ' +
            (row.original.motherEntry.jurisdiction.id.indexOf('DT') !== -1 ||
            row.original.motherEntry.subjurisdiction
              ? 'dependentTerritory'
              : '')
          }
        >
          <div>
            <img
              className="tableFlag"
              src={
                '/images/flags_svg/' +
                row.original.motherEntry.jurisdiction.a2_code.toLowerCase() +
                '.svg'
              }
              height="16px"
            />
          </div>
          <div>
            {!row.original.motherEntry.subjurisdiction &&
              row.original.motherEntry.jurisdiction.name}
            {row.original.motherEntry.subjurisdiction &&
              '' + row.original.motherEntry.subjurisdiction.name + ''}
          </div>
        </div>
      )
    },
    CellSmall: ({ value, row }) => {
      return (
        <div>{row.original.motherEntry.jurisdiction.name.substring(0, 3)}</div>
      )
    },
    style: { minWidth: '100px' }
  },
  {
    Header: i18n.t('Treaty'),
    accessor: 'treaty_body.full_name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return <TreatyDropdown column={column} />
    },

    className: 'col125 flexTd',
    style: { minWidth: '200px' },

    sortType: (rowA, rowB, id, desc) => {
      if (rowA.original.treaty_body.name > rowB.original.treaty_body.name)
        return -1
      if (rowB.original.treaty_body.name > rowA.original.treaty_body.name)
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('Signed'),
    accessor: 'sign',
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(
        rows,
        'sign',
        filterValue,
        false,
        false,
        false
      ),

    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width={'125px'}
          column={column}
          options={[
            { id: '-', value: i18n.t('Yes') },
            { id: null, value: i18n.t('No') }
          ]}
        />
      )
    },
    Cell: ({ value, row }) => {
      if (value) return i18ndayjs(value, 'DD MMM YYYY')
      else return '-'
    },
    className: 'textCenter col80',
    classNameFn: c => getBgColorClass(c, 'sign')
  },

  {
    Header: i18n.t('Ratified'),
    accessor: 'ras',
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(rows, 'ras', filterValue, false, false, false),

    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width={'125px'}
          column={column}
          options={[
            { id: '-', value: i18n.t('Yes') },
            { id: null, value: i18n.t('No') }
          ]}
        />
      )
    },
    Cell: ({ value, row }) => {
      if (value) return i18ndayjs(value, 'DD MMM YYYY')
      else return '-'
    },
    className: 'textCenter col80',
    classNameFn: c => getBgColorClass(c, 'ras')
  },

  {
    Header: i18n.t('Accepts individual complaints?'),
    accessor: 'accepted_complaints',
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(
        rows,
        'accepted_complaints',
        filterValue,
        false,
        false,
        false,
        row =>
          cArea2Treaties().find(
            t => t.id === parseInt(row.original.treaty_body.id)
          ).noComplaints
      ),

    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width={'125px'}
          column={column}
          options={[
            { id: '-', value: i18n.t('Yes') },
            { id: null, value: i18n.t('No') }
          ]}
        />
      )
    },
    Cell: ({ value, row }) => {
      if (
        cArea2Treaties().find(
          t => t.id === parseInt(row.original.treaty_body.id)
        ).noComplaints
      ) {
        return i18n.t('Does not apply')
      }
      if (value) return i18n.t('Yes') + ' (' + i18ndayjs(value, 'YYYY') + ')'
      else return i18n.t('No')
    },
    className: 'textCenter col80',
    classNameFn: c =>
      cArea2Treaties().find(
        t => t.id === parseInt(c.row.original.treaty_body.id)
      ).noComplaints
        ? ''
        : getBgColorClass(c, 'accepted_complaints')
  },

  {
    Header: i18n.t('Accepts inquiry proc.?'),
    accessor: 'accepted_inquiry',
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(
        rows,
        'accepted_inquiry',
        filterValue,
        false,
        false,
        false,
        row =>
          cArea2Treaties().find(
            t => t.id === parseInt(row.original.treaty_body.id)
          ).noComplaints
      ),

    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width={'125px'}
          column={column}
          options={[
            { id: '-', value: i18n.t('Yes') },
            { id: null, value: i18n.t('No') }
          ]}
        />
      )
    },
    Cell: ({ value, row }) => {
      if (
        cArea2Treaties().find(
          t => t.id === parseInt(row.original.treaty_body.id)
        ).noComplaints
      ) {
        return i18n.t('Does not apply')
      }
      if (value) return i18n.t('Yes') + ' (' + i18ndayjs(value, 'YYYY') + ')'
      else return i18n.t('No')
    },
    className: 'textCenter col80',
    classNameFn: c =>
      cArea2Treaties().find(
        t => t.id === parseInt(c.row.original.treaty_body.id)
      ).noComplaints
        ? ''
        : getBgColorClass(c, 'accepted_inquiry')
  }
]
