var map = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: { name: "Nigeria (Sharia)", id: "NG-DEATH" },
      id: "NG-DEATH",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.5873, 10.2659],
            [3.6797, 10.1398],
            [3.9833, 10.1398],
            [4.3446, 9.7394],
            [4.4541, 9.7023],
            [4.4363, 9.4576],
            [4.4531, 9.4279],
            [4.5795, 9.4205],
            [4.6298, 9.3315],
            [4.6634, 9.2945],
            [4.7222, 9.2574],
            [4.7473, 9.1981],
            [4.7724, 9.1461],
            [4.806, 9.1165],
            [5.0, 9.161],
            [5.1515, 9.0942],
            [5.2017, 9.0127],
            [5.2438, 9.0052],
            [5.3533, 8.9904],
            [5.5217, 8.9608],
            [5.6729, 8.8421],
            [5.8242, 8.7457],
            [6.1191, 8.7531],
            [6.2957, 8.6864],
            [6.4211, 8.4713],
            [6.5638, 8.3527],
            [6.6643, 8.2266],
            [6.8082, 8.3823],
            [6.7534, 9.2129],
            [6.981, 9.2351],
            [7.149, 9.1165],
            [7.2932, 9.2796],
            [7.4955, 9.2945],
            [7.5633, 9.3538],
            [7.6053, 9.3241],
            [7.8072, 9.2574],
            [7.9005, 9.3538],
            [7.9761, 9.3167],
            [8.0011, 9.2574],
            [8.0931, 9.1461],
            [8.0926, 9.0646],
            [8.1515, 9.0497],
            [8.2617, 9.1536],
            [8.3964, 9.1239],
            [8.4548, 9.0423],
            [8.5728, 9.0423],
            [8.6668, 9.2425],
            [8.6673, 9.3167],
            [8.6257, 9.3983],
            [8.5337, 9.5021],
            [8.56, 9.643],
            [8.6288, 9.8432],
            [8.6388, 10.0657],
            [8.6651, 10.1918],
            [8.7841, 10.3104],
            [8.9275, 10.3104],
            [8.9601, 10.1695],
            [8.9843, 10.0212],
            [8.9841, 9.9915],
            [9.1446, 10.0286],
            [9.2029, 9.9322],
            [9.2277, 9.8655],
            [9.1677, 9.7394],
            [9.3016, 9.5985],
            [9.5541, 9.5392],
            [9.7483, 9.5837],
            [9.7487, 9.6356],
            [9.8335, 9.6949],
            [9.8175, 9.8061],
            [9.86, 9.8432],
            [10.557, 9.5021],
            [10.6333, 9.5466],
            [10.7342, 9.5243],
            [10.8951, 9.6059],
            [11.0383, 9.5911],
            [11.5696, 9.6059],
            [11.9169, 9.7765],
            [11.9449, 10.0434],
            [12.1728, 10.0657],
            [12.3692, 10.2882],
            [12.6233, 10.3771],
            [12.7086, 10.4587],
            [12.7847, 10.4735],
            [12.9715, 10.5699],
            [13.1311, 10.5106],
            [13.257, 10.4587],
            [13.3425, 10.5477],
            [13.4232, 10.9333],
            [13.6935, 10.9481],
            [13.7783, 10.9778],
            [14.0519, 11.2299],
            [14.3834, 11.3857],
            [14.5877, 11.5043],
            [14.6371863, 11.5278169],
            [14.6482, 11.5933],
            [14.6102, 11.8825],
            [14.6331986, 11.9119664],
            [14.6584541, 11.9584897],
            [14.6358571, 11.9930498],
            [14.6425033, 12.0628348],
            [14.6823804, 12.1572106],
            [14.6066139, 12.1997462],
            [14.5341704, 12.2761773],
            [14.5222073, 12.3253591],
            [14.4331484, 12.3612485],
            [14.2071781, 12.3678947],
            [14.0703, 13.0394],
            [13.6067, 13.6845],
            [13.336, 13.6919],
            [13.1732, 13.5733],
            [13.0543, 13.5436],
            [12.8582, 13.4546],
            [12.8067, 13.4027],
            [12.6703, 13.336],
            [12.6103, 13.2841],
            [12.5586, 13.2173],
            [12.5069, 13.158],
            [12.4638, 13.0987],
            [11.8737, 13.2544],
            [11.5535, 13.3508],
            [11.2576, 13.3731],
            [10.9782, 13.3657],
            [10.6564, 13.3508],
            [10.4866, 13.3063],
            [10.2494, 13.2841],
            [10.1986, 13.2767],
            [9.9856, 13.1506],
            [9.7633, 12.943],
            [9.7032, 12.8614],
            [9.5929, 12.8317],
            [8.7647, 12.8985],
            [8.5463, 13.0542],
            [8.2039688, 13.2475731],
            [8.1094389, 13.2895864],
            [8.0044057, 13.3105931],
            [7.8258492, 13.3368514],
            [7.7282, 13.3286],
            [7.4648, 13.2025],
            [7.2166565, 13.1267849],
            [7.0573, 13.0394],
            [6.8463, 13.1061],
            [6.6874, 13.336],
            [6.4676954, 13.6040851],
            [6.2656513, 13.6678885],
            [5.5485, 13.8773],
            [5.5425464, 13.9018343],
            [5.2433, 13.8254],
            [4.8198, 13.7883],
            [4.4552, 13.6919],
            [4.2771127, 13.5828174],
            [4.2001, 13.4695],
            [4.1238, 13.4546],
            [3.9516, 12.8021],
            [3.6377, 12.5425],
            [3.618, 11.8306],
            [3.4978, 11.2819],
            [3.7083, 11.1261],
            [3.7584, 10.9481],
            [3.7579, 10.8073],
            [3.8167, 10.7405],
            [3.8416, 10.607],
            [3.7135162, 10.4670857],
            [3.6443959, 10.4431594],
            [3.5789, 10.2882],
            [3.5873, 10.2659]
          ].reverse()
        ]
      }
    }
  ]
};

export default map;
