import i18n from "../../config/i18n";
import ExpandableTextCell from "../components/Table/ExpandableTextCell";
import GenericDropdownFilter, { ApplyGenericDropdownFilter } from "../components/Table/GenericDropdownFilter";
import JurFilter, { ApplyJurFilter } from "../components/Table/JurFilter";
import SourceText from "../components/Table/SourceText";

const getBgColorClass = (cell, col) => {
  if (!cell.row.original[col]) return "";
  return cell.row.original[col].id === "1"
    ? "rowProtectionYes"
    : cell.row.original[col].id === "2"
    ? "rowProtectionNo"
    : cell.row.original[col].id === "3" || cell.row.original[col].id === "7"
    ? "rowProtectionVaries"
    : "rowProtectionUnclear";
};

const PncDropdown = ({ column }) => (
  <GenericDropdownFilter
    width="200px"
    column={column}
    options={[
      { id: i18n.t("Possible"), value: i18n.t("Possible") },
      { id: i18n.t("Not Possible"), value: i18n.t("Not Possible") },
      { id: i18n.t("Nominally Possible"), value: i18n.t("Nominally Possible") },
      { id: i18n.t("Unclear"), value: i18n.t("Unclear") },
      { id: i18n.t("No data"), value: i18n.t("No data") },
      { id: i18n.t("Varies"), value: i18n.t("Varies") }
    ]}
  />
);

export const a118_nc_columns = () => [
  {
    Header: i18n.t("Jurisdiction"),
    disableGroupBy: true,
    accessor: "motherEntry.jurisdiction.name",
    enableRowSpan: true,
    filter: ApplyJurFilter,
    Filter: ({ column }) => {
      return <JurFilter column={column} search={true} />;
    },
    Cell: ({ value, row }) => {
      return (
        <div
          className={
            "jurCell " +
            (row.original.motherEntry.jurisdiction.id.indexOf("DT") !== -1 || row.original.motherEntry.subjurisdiction
              ? "dependentTerritory"
              : "")
          }
        >
          <div>
            <img
              className="tableFlag"
              src={"/images/flags_svg/" + row.original.motherEntry.jurisdiction.a2_code.toLowerCase() + ".svg"}
              height="16px"
            />
          </div>
          <div>
            {!row.original.motherEntry.subjurisdiction && row.original.motherEntry.jurisdiction.name}
            {row.original.motherEntry.subjurisdiction && "" + row.original.motherEntry.subjurisdiction.name + ""}
          </div>
        </div>
      );
    },
    CellSmall: ({ value, row }) => {
      return <div>{row.original.motherEntry.jurisdiction.name.substring(0, 3)}</div>;
    },
    style: { minWidth: "100px" }
  },
  {
    Header: i18n.t("Name Change"),
    accessor: "name_change_lgr_type.name",
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return <PncDropdown column={column} />;
    },
    classNameFn: c => getBgColorClass(c, "name_change_lgr_type"),

    className: "textCenter col125 flexTd",
    style: { minWidth: "200px" },

    sortType: (rowA, rowB, id, desc) => {
      if (rowA.original.name_change_lgr_type.name > rowB.original.name_change_lgr_type.name) return -1;
      if (rowB.original.name_change_lgr_type.name > rowA.original.name_change_lgr_type.name) return 1;
      return 0;
    }
  },

  {
    Header: i18n.t("Mechanism"),
    accessor: "name_change_mechanism.name",
    className: "textCenter col125",
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          column={column}
          options={[
            { id: i18n.t("Legislative"), value: i18n.t("Legislative") },
            { id: i18n.t("Executive"), value: i18n.t("Executive") },
            { id: i18n.t("Judicial"), value: i18n.t("Judicial") }
          ]}
        />
      );
    }
  },
  {
    Header: i18n.t("Explanation"),
    accessor: "name_change_explan",
    disableSortBy: true,
    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            row={row}
            section={1}
            sources={row.original.motherEntry.sources}
            unik={row.original.id + "_explan"}
            footnotes={row.original.motherEntry.footnotes}
          />
        );
      else return <></>;
    },
    NonExpandableCell: ({ value, row }) => {
      return (
        <SourceText
          text={value}
          section={1}
          sources={row.original.motherEntry.sources}
          unik={row.original.id + "_explan"}
        />
      );
    },
    style: { minWidth: "300px" },
    sortType: (rowA, rowB, id, desc) => {
      if (!rowB.original.explan) return null;
      if (rowA.original.explan.replace(/[^\w\s]/gi, "") > rowB.original.explan.replace(/[^\w\s]/gi, "")) return -1;
      if (rowB.original.explan.replace(/[^\w\s]/gi, "") > rowA.original.explan.replace(/[^\w\s]/gi, "")) return 1;
      return 0;
    }
  }
];
