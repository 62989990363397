import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { useTranslation } from "react-i18next";
import UNMemberSwitch from "../../Charts/UNMemberSwitch";
import LoadingBar from "../../ui/LoadingBar";
import { cArea1BarChartRegions, cMainRegions } from "../../../../config/constants";

import "../../Charts/Charts.css";

export default function PieChart({ references }) {
  const [unMember, setUnMember] = useState(true);
  const [type, setType] = useState("marriage");
  const { t, i18n } = useTranslation();
  const loading = useRef();
  const loadChart = () => {
    let newReferences = references.filter(r => (unMember ? r.entry.motherEntry.jurisdiction.un_member : true));
    if (loading.current) loading.current.style.display = "block";
    am4core.options.queue = true;

    /* Chart code */
    // Themes begin
    am4core.unuseAllThemes();
    

    // Themes end

    // Create chart instance
    let chart = am4core.create("pieChartDiv", am4charts.PieChart);

    // Themes begin

    chart.events.on("ready", () => {
      if (loading.current) loading.current.style.display = "none";
    });
    // Themes end

    // Create chart instance

    // Let's cut a hole in our Pie chart the size of 40% the radius
    chart.innerRadius = am4core.percent(40);

    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "category";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.innerRadius = 10;
    pieSeries.slices.template.strokeWidth = 0;
    pieSeries.slices.template.fillOpacity = 0.5;

    pieSeries.slices.template.showOnInit = true;
    pieSeries.slices.template.hiddenState.properties.shiftRadius = 1;

    pieSeries.slices.template.propertyFields.disabled = "labelDisabled";
    pieSeries.labels.template.propertyFields.disabled = "textDisabled";
    pieSeries.ticks.template.disabled = true;

    pieSeries.alignLabels = false;
    //pieSeries.labels.template.maxWidth = 50;
    pieSeries.labels.template.wrap = true;
    //pieSeries.labels.template.dy=150;
    //pieSeries.labels.template.fontSize = 8;
    pieSeries.tooltip.getFillFromObject = false;
    pieSeries.tooltip.background.fill = am4core.color("#FFF");
    pieSeries.tooltip.background.fillOpacity = 1;
    pieSeries.tooltip.label.fill = am4core.color("#575757");
    // Add data

    const regions = cMainRegions();

    regions.forEach(r => {
      let total = newReferences.filter(
        ref => ref.entry.motherEntry.jurisdiction.regions.map(reg => reg.id).indexOf(r.id) !== -1
      ).length;
      let val = newReferences.filter(
        ref =>
          ref.entry.pnc_type.id === "2" &&
          ref.entry.motherEntry.jurisdiction.regions.map(reg => reg.id).indexOf(r.id) !== -1
      ).length;
      pieSeries.data.push({
        category: r.name,
        fill: r.color,
        value: val,
        total: total,
        percentage: Math.round((val / total) * 100)
      });
      pieSeries.data.push({
        category: r.name,
        labelDisabled: true,
        textDisabled: true,
        value:
          newReferences.filter(
            ref => ref.entry.motherEntry.jurisdiction.regions.map(reg => reg.id).indexOf(r.id) !== -1
          ).length -
          newReferences.filter(
            ref =>
              ref.entry.pnc_type.id === "2" &&
              ref.entry.motherEntry.jurisdiction.regions.map(reg => reg.id).indexOf(r.id) !== -1
          ).length
      });
    });

    // Disable sliding out of slices

    pieSeries.slices.template.propertyFields.fill = "fill";
    pieSeries.hiddenState.properties.endAngle = -90;

    pieSeries.labels.template.text = "{category}\n{percentage}%";
    pieSeries.labels.template.textAlign = "middle";
    pieSeries.slices.template.tooltipText = "{category}: {percentage}% ({val} " + t("out of") + " {total})";
    // Add second series
    var pieSeries2 = chart.series.push(new am4charts.PieSeries());
    pieSeries2.dataFields.value = "value";
    pieSeries2.dataFields.category = "category";
    pieSeries2.slices.template.states.getKey("hover").properties.shiftRadius = 0;
    pieSeries2.slices.template.states.getKey("hover").properties.scale = 1;
    pieSeries2.slices.template.propertyFields.fill = "fill";

    pieSeries2.slices.template.fillOpacity = 1;
    pieSeries2.slices.template.propertyFields.stroke = "fill";
    pieSeries2.slices.template.strokeWidth = 0;
    pieSeries2.slices.template.tooltipText = "{category}: {percentage}% ({val} " + t("out of") + " {total})";
    pieSeries2.hiddenState.properties.endAngle = -90;
    pieSeries2.tooltip.getFillFromObject = false;
    pieSeries2.tooltip.background.fill = am4core.color("#FFF");
    pieSeries2.tooltip.background.fillOpacity = 1;
    pieSeries2.tooltip.label.fill = am4core.color("#575757");
    // Add data
    pieSeries2.data = regions.map(r => {
      let total = newReferences.filter(
        ref => ref.entry.motherEntry.jurisdiction.regions.map(reg => reg.id).indexOf(r.id) !== -1
      ).length;
      let val = newReferences.filter(
        ref =>
          ref.entry.pnc_type.id === "2" &&
          ref.entry.motherEntry.jurisdiction.regions.map(reg => reg.id).indexOf(r.id) !== -1
      ).length;

      return {
        category: r.name,
        textDisabled: true,
        value: newReferences.filter(
          ref => ref.entry.motherEntry.jurisdiction.regions.map(reg => reg.id).indexOf(r.id) !== -1
        ).length,
        val,
        total,
        fill: r.color,
        percentage: Math.round((val / total) * 100)
      };
    });
    pieSeries2.labels.template.propertyFields.disabled = "textDisabled";
    pieSeries2.ticks.template.propertyFields.disabled = "textDisabled";

    pieSeries.adapter.add("innerRadius", function(innerRadius, target) {
      return am4core.percent(25);
    });

    pieSeries2.adapter.add("innerRadius", function(innerRadius, target) {
      return am4core.percent(5);
    });

    pieSeries.adapter.add("radius", function(innerRadius, target) {
      return am4core.percent(65);
    });

    pieSeries2.adapter.add("radius", function(innerRadius, target) {
      return am4core.percent(25);
    });
  };
  useEffect(() => {
    loadChart();
  }, [unMember, type]);
  return (
    <div className="chartContainer">
      <LoadingBar background="#f4f8f8" ref={loading} text={t("Loading chart") + "..."} />
      <div id="pieChartDiv" style={{ minHeight: "450px" }} />

      <UNMemberSwitch unMember={unMember} setUnMember={setUnMember} extraStyles={{ marginTop: "0px" }} />
    </div>
  );
}
