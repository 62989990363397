import Tippy from '@tippyjs/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IconInfo from '../../../components/icons/IconInfo'
import ViewMoreUpdates from '../ViewMoreUpdates/ViewMoreUpdates'
import useGqlLatestUpdates from '../../../gql/useGqlLatestUpdates'
import dayjs from 'dayjs'
import { i18ndayjs } from '../../../lib/fnDates'
import useGqlTextComponents from '../../../gql/useGqlTextComponents'

export default function LatestUpdates({ area, passRef, areaIntro }) {
  const { t } = useTranslation()
  const { latestUpdates, loading, error } = useGqlLatestUpdates(area)
  const [latestUpdatesArea, setLatestUpdatesArea] = useState([])

  const { textComponents } = useGqlTextComponents(areaIntro)

  useEffect(() => {
    if (latestUpdates && !latestUpdatesArea.length) {
      const luu = latestUpdates
        .slice()
        .filter(a => a.year)
        .sort((a, b) => {
          return (
            new Date(a.year, a.month ? a.month - 1 : 0, a.day ? a.day : 1).getTime() >
            new Date(b.year, b.month ? b.month - 1 : 0, b.day ? b.day : 1).getTime() ? -1 : 1
          )
        })
        .map(lu => ({
          ...lu,
          dd:new Date(lu.year, lu.month ? lu.month - 1 : 0, lu.day ? lu.day : 1),
          latestTime:
            lu.day && lu.month && lu.year && area[1] !== '3'
              ? i18ndayjs(
                  lu.year + '-' + lu.month + '-' + lu.day,
                  'MMMM [es_de] YYYY'
                )
              : lu.month && lu.year
              ? i18ndayjs(lu.year + '-' + lu.month + '-1', 'MMMM [es_de] YYYY')
              : lu.year,
          latestTimeTooltip:
            lu.day && lu.month && lu.year && area[1] !== '3'
              ? i18ndayjs(
                  lu.year + '-' + lu.month + '-' + lu.day,
                  'MMMM [es_de] YYYY'
                )
              : lu.month && lu.year
              ? i18ndayjs(lu.year + '-' + lu.month + '-1', 'MMMM [es_de] YYYY')
              : lu.year
        }))
      setLatestUpdatesArea(luu)
     
    }
  }, [latestUpdates])

  return (
    <div className="latestUpdates">
      <div>
        <h5>
          {t('Latest Updates')}{' '}
          {latestUpdatesArea && latestUpdatesArea[0] && (
            <Tippy
              className="helpTippy"
              content={
                area[1] === '1'
                  ? t(
                      'This section was updated for the last time in {{date}}. Please note that it may take several working days for the Research Team to add a new legal development. If you are looking for more recent updates, the ILGA World Monitor provides access to external sources published within the last 3 to 6 working days and prior. However, if you identify missing information that should have been incorporated, please let us know through the "Send comments" tool (via the dialogue bubble icon on every row).',
                      {
                        date: latestUpdatesArea[0].latestTime
                      }
                    )
                  : area[1] === '3' || area[1] === '3'
                  ? t('This Area was last updated in {{date}}.', {
                      date: latestUpdatesArea[0].latestTimeTooltip
                    })
                  : area[1] === '5'
                  ? t(
                      'This Area was last updated in {{date}} with information on the outcomes for the last sessions from the third cycle of the UPR. Information on the fourth cycle will be uploaded as it unfolds.',
                      {
                        date: latestUpdatesArea[0].latestTimeTooltip
                      }
                    )
                  : ''
              }
              distance={'0'}
              allowHTML={true}
              followCursor={'initial'}
              theme={'ilga-help'}
              placement={'top'}
              popperOptions={{
                modifiers: [{ name: 'flip', enabled: false }]
              }}
            >
              <a
                style={{
                  verticalAlign: 'super',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '-3px',
                  marginLeft: '5px'
                }}
              >
                <IconInfo size={22} color="#5c8f95" />
              </a>
            </Tippy>
          )}
        </h5>
        <ul
          className="list-unstyled"
          style={{
            maxHeight:
              passRef && passRef.current
                ? textComponents.length && textComponents[0].methodology
                  ? passRef.current.clientHeight + 'px'
                  : passRef.current.clientHeight - 40 + 'px'
                : '350px'
          }}
        >
          {latestUpdatesArea &&
            latestUpdatesArea.map((u, i) => (
              <li className="update__item" key={i}>
                <div className="top__row">
                  <img
                    className="flagIcon"
                    alt={u.country_name}
                    src={`/images/flags_svg/${u.jurisdiction.a2_code.toLowerCase()}.svg`}
                  />
                  <span className="country">{u.jurisdiction.name}</span>
                </div>
                <span className="itemContent">{u.comments}</span>
                <span className="itemDate">{u.latestTime}</span>
              </li>
            ))}
        </ul>
      </div>
      <div>
        <ViewMoreUpdates />
      </div>
    </div>
  )
}
