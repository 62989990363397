import Tippy from '@tippyjs/react'
import React from 'react'

import infoIcon from '../../../images/infoIcon.svg'
import infoIconRedBg from '../../../images/infoIconRedBg.svg'
import infoIconGrayBg from '../../../images/infoIconGrayBg.svg'

import infoIconYellowBg from '../../../images/infoIconYellowBg.svg'
import { useTranslation } from 'react-i18next'

export default function HelpCellA5({ source, helpText, bg }) {
  const { t } = useTranslation()
  return (
    <div>
      <Tippy
        className="helpTippy"
        content={
          <>
            {t('Additional information provided by the State')}
            <br />
            <br />
            <div className="area5TooltpipBody">
              {helpText}{' '}
              {source && (
                <a href={source} target="_blank" rel="noreferrer">
                  {t('Source')}: {source.split('/').slice(3).join('/')}
                </a>
              )}
            </div>
          </>
        }
        distance={'0'}
        interactive
        interactiveBorder={20}
        delay={100}
        allowHTML
        maxWidth={'600px'}
        followCursor={'initial'}
        theme={'ilga-a5'}
        placement={'top'}
        popperOptions={{
          strategy: 'fixed',
          modifiers: [{ name: 'flip', enabled: true }]
        }}
      >
        {!bg || bg === 'rowProtectionYes' ? (
          <img src={infoIcon} alt="Read more" className="helpIcon" />
        ) : bg === 'rowProtectionNo' ? (
          <img src={infoIconRedBg} alt="Read more" className="helpIcon" />
        ) : bg === 'rowProtectionUnclear' ? (
          <img src={infoIconGrayBg} alt="Read more" className="helpIcon" />
        ) : (
          <img src={infoIconYellowBg} alt="Read more" className="helpIcon" />
        )}
      </Tippy>
    </div>
  )
}
