import React, { useContext, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import './Methodology.css'
import OpenInNewIcon from '../icons/OpenInNewIcon'
import logoImage from '../../../images/logo.png'
import PicAsPDF from '../icons/PicAsPDF'
import IlgaLogo from '../ui/IlgaLogo'
import DatabaseLogo from '../ui/DatabaseLogo'
import { useTranslation } from 'react-i18next'
import DOMPurify from 'dompurify'
import TextComponentsEditor from '../../../components/TextComponentsEditor'
import useGqlTextComponents from '../../../gql/useGqlTextComponents'
import IconCancel from '../../../components/icons/IconCancel'
import IconSave from '../../../components/icons/IconSave'
import IconEdit from '../../../components/icons/IconEdit'
import { AccountContext } from '../../../context/Accounts'
export default function Methodology({ area }) {
  const [showModal, setShowModal] = useState(false)

  const modalRef = useRef()

  const { textComponents, updateTextComponent } = useGqlTextComponents(area)
  const [editButtons, setEditButtons] = useState(false)
  const [editIntro, setEditIntro] = useState(false)
  const { currentUser } = useContext(AccountContext)
  const [t, i18n] = useTranslation()

  const saveTextComponent = (area, type, text) => {
    updateTextComponent({
      variables: {
        lang: i18n.language,
        section: area,
        texts: {
          [type]: text
        }
      }
    })
      .then(text => {
        setEditIntro(false)
      })
      .catch(e => console.log('EEE', e))
  }

  const handleShow = () => setShowModal(true)
  const handleClose = () => setShowModal(false)

  const handleDownloadPDF = async () => {}
  return (
    <>
      <Button className="methodBtn" variant="default" onClick={handleShow}>
        <span className="methodBtn__text">{t('Methodology')}</span>
        <OpenInNewIcon size={30} />
      </Button>

      <Modal
        size="lg"
        className="methodologyModal"
        show={showModal}
        onHide={handleClose}
        ref={modalRef}
        onMouseEnter={e => setEditButtons(true)}
        onMouseLeave={e => setEditButtons(false)}
      >
        <Modal.Header closeButton>
          <div className="modal__container">
            <div className="comboLogoContainer">
              <IlgaLogo /> <DatabaseLogo />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="methodologyActions__container">
            <h4>
              {t('Methodology')}{' '}
              {currentUser &&
                currentUser.email === 'lucas@ilga.org' &&
                editButtons &&
                !editIntro && (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setEditIntro(true)
                    }}
                  >
                    <IconEdit />
                  </span>
                )}
            </h4>
            {false && (
              <Button variant="link" onClick={handleDownloadPDF}>
                <PicAsPDF size={30} />
              </Button>
            )}
          </div>
          {!editIntro ? (
            <div
              className="methodologyModal__content"
              id="methodologyModal__content"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  textComponents.length && textComponents[0].methodology
                )
              }}
            />
          ) : (
            <TextComponentsEditor
              variation={'methodology'}
              initialText={
                textComponents.length && textComponents[0].methodology
              }
              cancel={() => setEditIntro(false)}
              save={text => saveTextComponent(area, 'methodology', text)}
            />
          )}
          <div className="methodologyActions__container">
            <Button
              key={'btn1'}
              variant="link"
              onClick={e => modalRef.current.dialog.scroll(0, 0)}
            >
              {t('Go to top')}
            </Button>
            {false && (
              <Button key={'btn2'} variant="link" onClick={handleDownloadPDF}>
                <PicAsPDF size={30} />
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
