import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GET_A2_RAT = gql(`
  query GetEntriesRatification( $lang: String, $jur_id: String) {
    entriesRatification(lang: $lang, jur_id: $jur_id) {
        id
        treaty_body {
          name
          id
          full_name
        }
        sign
        ras
        accepted_complaints
        accepted_inquiry
        motherEntry {
          jurisdiction {
            id
            name
            un_member
            a2_code
            regions{
              id
            }
          }
        }
      }
    
}
`);

function useGqlEntriesRatification(jur_id = null) {
  const { i18n } = useTranslation();
  const entriesRatification = useQuery(GET_A2_RAT, {
    variables: { jur_id, lang: i18n.language }
  });

  return {
    entriesRatification: entriesRatification.data && entriesRatification.data.entriesRatification
  };
}

export default useGqlEntriesRatification;
