import React, { useEffect, useRef, useState } from 'react'
import DropdownSearch from '../Dropdown/DropdownSearch'
import { Button, Modal } from 'react-bootstrap'

import ChevronIcon from '../icons/ChevronIcon'
import { useTranslation } from 'react-i18next'
import useGqlJurisdictions from '../../../gql/useGqlJurisdictions'
import FilterIcon from '../icons/FilterIcon'

export const TagModal = ({
  showModal,
  setShowModal,
  topics = [],
  setTopics,
  filterTopics = topics => topics,
  useOr,
  setUseOr,
  rows = [],
  extraClass = '',
  selected
}) => {
  const { i18n, t } = useTranslation()
  const modalRef = useRef()
  const [textFilter, setTextFilter] = useState('')
  const [filters, setFilters] = useState([])
  const [uniqueTopics, setUniqueTopics] = useState([])
  const { topics: allTopics } = useGqlJurisdictions()

  const handleShow = () => setShowModal(true)
  const handleClose = () => setShowModal(false)

  useEffect(() => {
    if (rows.length && !uniqueTopics.length)
      setUniqueTopics(
        rows
          .map(r => {
            return r.original.motherEntry.topics.map(t => t.name).flat()
          })
          .flat()
          .filter((v, i, a) => a.indexOf(v) === i)
      )
  }, [rows])

  useEffect(() => {
    if (!selected || !selected.length) setFilters([])
  }, [selected])

  const handleDownloadPDF = () => console.log('start pdf download')

  const handleOnClick = tag => {
    if (filters.indexOf(tag) === -1) {
      setTextFilter('')
      setFilters([...filters, tag])
    } else {
      setFilters(filters.filter(f => f !== tag))
    }
  }

  const applyFilter = () => {
    setTopics(filters)
    handleClose()
  }
  return (
    <>
      <Modal
        size="xl"
        className={'methodologyModal filter__modal ' + extraClass}
        show={showModal}
        onHide={handleClose}
        ref={modalRef}
        centered={true}
      >
        <Modal.Header className="filterModal__header dd-filter" closeButton>
          <input
            className="filterModal__input"
            placeholder={t('Search') + '...'}
            value={textFilter}
            onChange={e => setTextFilter(e.target.value)}
          />
          <div className="mcs__toggle">
            <div className="buttonAndOr buttonCountryNames b2">
              <input
                type="checkbox"
                className="checkbox"
                defaultChecked={useOr}
                onChange={e => {
                  setUseOr(e.target.checked)
                }}
              />
              <div className={`knobs knobs-${i18n.language}`}>
                <span>{t('And')}</span>
              </div>
              <div className="layer" />
            </div>
          </div>
          <Button
            variant="link"
            className="filterModal__clearFilter"
            onClick={e => {
              setFilters([])
              setTopics([])
              handleClose()
            }}
          >
            {t('Clear Filter')}
          </Button>
        </Modal.Header>
        <Modal.Body className="">
          <div className="topicFilterModal__body">
            {allTopics &&
              allTopics.length > 0 &&
              filterTopics(allTopics)
                .map(topic => topic.name)
                .filter(
                  tag =>
                    (!uniqueTopics.length ||
                      uniqueTopics.indexOf(tag) !== -1) &&
                    (!textFilter ||
                      tag.toLowerCase().indexOf(textFilter.toLowerCase()) !==
                        -1)
                )
                .map((tag, index) => (
                  <span className="check-container" key={tag}>
                    <input
                      type="checkbox"
                      name={tag}
                      onClick={e => handleOnClick(tag)}
                      checked={filters.indexOf(tag) !== -1}
                    />
                    <label htmlFor={tag} onClick={e => handleOnClick(tag)}>
                      {tag}
                    </label>
                  </span>
                ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="filterModal__footer">
          <Button variant="primary" onClick={applyFilter}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default function TopicFilter({ column, rows, extraClass }) {
  const { filterValue, setFilter } = column
  const [showModal, setShowModal] = useState(false)
  const [topics, setTopics] = useState([])
  const [useOr, setUseOr] = useState(true)
  useEffect(() => {
    setFilter([{ name: useOr ? 'OR' : 'AND' }, ...topics])
  }, [topics, useOr])
  return (
    <div className="topicFilter">
      <TagModal
        showModal={showModal}
        setShowModal={setShowModal}
        setTopics={setTopics}
        topics={topics}
        useOr={useOr}
        setUseOr={setUseOr}
        rows={rows}
        extraClass={extraClass}
      />
      <span
        className={topics.length ? 'activeFilter' : 'inactiveFilter'}
        onClick={() => setShowModal(true)}
      >
        <FilterIcon size={18} />
      </span>
      {/*<ChevronIcon size={20} />*/}
    </div>
  )
}

export function ApplyTopicFilter(rows, columnsId, filterValue) {
  if (filterValue && filterValue.length > 1) {
    let andOr = filterValue[0]

    let allFiltered = []
    if (andOr.name === 'OR') {
      for (let filter of filterValue.slice(1)) {
        allFiltered = [
          ...allFiltered,
          ...rows.filter(row => {
            if (row.values[columnsId])
              return row.values[columnsId].find(
                t => t.name.toLowerCase() === filter.toLowerCase()
              )
          })
        ]
      }
    } else {
      allFiltered = rows.filter(row =>
        filterValue
          .slice(1)
          .every(topic =>
            row.values[columnsId].find(
              t => t.name.toLowerCase() === topic.toLowerCase()
            )
          )
      )
    }
    console.log('APPLIED', andOr)
    return allFiltered
  } else {
    return rows
  }
}
