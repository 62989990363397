import LoadingBar from '../ui/LoadingBar'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { i18ndayjs } from '../../../lib/fnDates'
import useGqlJurisdictions from '../../../gql/useGqlJurisdictions'

const NewsItems = ({ news }) => {
  const { t, i18n } = useTranslation()
  const { languages } = useGqlJurisdictions()

  return news === false ? (
    <>{t('No news found')}</>
  ) : news.length ? (
    <>
      <ul className="jh__tab-itemList list-unstyled">
        {news.map((n, i) => (
          <li key={'news' + i}>
            <div className="jhil__header">
              <span className="jhil__timeReleased text-green">
                {i18ndayjs(parseISO(n.da), 'D MMMM YYYY')}
              </span>
            </div>
            <div className="jhil__body">
              <h5 className="jhil__body-title">
                <a href={n.u} target="_blank" rel="noreferrer">
                  {n.th ? n.th : n.h}
                </a>
              </h5>
              {n.th && <p className="jhil__body-content  text-green">{n.h}</p>}
              <p className="jhil__body-content">{n.d}</p>
            </div>
            <div className="jhil__footer">
              <span className="jhil__sourceLabel">{t('Source')}: </span>
              <span className="jhil__sourceName">{n.s}</span>{' '}
              <span className="jhil__sourceLabel">{t('Language')}: </span>
              {languages && (
                <span className="jhil__sourceName">
                  {languages.find(lang => n.l === lang.name_en)
                    ? languages.find(lang => n.l === lang.name_en).name
                    : '-'}
                </span>
              )}
            </div>
          </li>
        ))}
      </ul>
      <button
        className="btn btn-default"
        onClick={() => window.open('https://monitor.ilga.org/', '_blank')}
      >
        {t('Go to Monitor')}
      </button>
    </>
  ) : (
    <div
      className="loadingBar__container"
      style={{
        height: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <LoadingBar text={'Loading...'} />
    </div>
  )
}
export default NewsItems
