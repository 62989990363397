import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js'
const poolData = {
  UserPoolId: 'us-east-1_NuGugsJdY',
  ClientId: '7v5t315kvv5boq3fg2bsrlji70',
  Storage: new CookieStorage({
    secure: false,
    domain: process.env.REACT_APP_MAIN_DOMAIN
  })
}
export default new CognitoUserPool(poolData)
