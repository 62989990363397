import React from "react";
import BarLoader from "react-spinners/ClipLoader";

const styles = {
  container: {
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  text: {
    marginBottom: '4px'
  },
  spinner: {
    margin: '0 auto'
  }
}

export default function MainSpinner({text = ''}) {
  return (
    <div className="mainSpinner" style={styles.container}>
      {text && <span style={styles.text}>{text}</span>}
      <BarLoader color={"#5c8493"} loading={true} style={styles.spinner} size={20}/>
    </div>
  );
}
