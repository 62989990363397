import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import './EnforcmentModal.css'
import logoImage from '../../../images/logo.png'
import IconOpenInNew from '../../../components/icons/IconOpenInNew'
import { useGqlEnforcement } from '../../../gql/useGqlEntriesCsssa'
import { useTranslation } from 'react-i18next'
import reactStringReplace from 'react-string-replace'
import Tippy from '@tippyjs/react'

import parse from 'html-react-parser'
//import "tippy.js/dist/tippy.css"; // optional
import { hideAll, followCursor } from 'tippy.js'
import './Tippy.css'
import MainSpinner from '../ui/MainSpinner'
import PicAsPDF from '../icons/PicAsPDF'

/*
          {enforcement && (
            <div title="Enforcement" className="enforcmentModal__content">
              {reactStringReplace(
                reactStringReplace(enforcement.enforcement, /\[([0-9]+)\]/gi, (match, i) => (
                  <Tippy
                    className="SourceText__container"
                    content={<SourceTooltip text="test" />}
                    key={i}
                    interactive={true}
                    interactiveBorder={20}
                    delay={100}
                    allowHTML={true}
                    onShow={instance => {
                      hideAll({ duration: 0 });
                    }}
                    followCursor={"initial"}
                    theme={"ilga-source"}
                    plugins={[followCursor]}
                    placement={"top"}
                    popperOptions={{ modifiers: [{ name: "flip", enabled: false }], strategy: "fixed" }}
                  >
                    <span className="sourceTooltip">{match}</span>
                  </Tippy>
                )),
                /(\n)/gi,
                (match, i, offset) => (
                  <React.Fragment
                    key={"enf" + entry_csssa_id + offset + "_" + i + Math.floor(Math.random() * 100) + "div"}
                  >
                    <br className="brSourceText" />
                  </React.Fragment>
                )
              )}
            </div>
          )}
*/

export default function EnforcmentModal({ title, body, entry_csssa_id }) {
  const [showModal, setShowModal] = useState(false)

  const modalRef = useRef()

  const { t } = useTranslation()

  const { enforcement } = useGqlEnforcement(entry_csssa_id)

  useEffect(() => {}, [])
  const handleShow = () => setShowModal(true)
  const handleClose = () => setShowModal(false)

  const handleDownloadPDF = () => console.log('start pdf download')

  const SourceTooltip = ({ text, singletonTippy }) => {
    const { t } = useTranslation()

    return (
      <div className="sourceTooltipBody">
        <div
          className="sourceTooltipBody"
          dangerouslySetInnerHTML={{
            __html: text.replace(
              /<a /gi,
              '<a target="_blank" rel="noreferrer" '
            )
          }}
        />
      </div>
    )
  }

  return (
    <>
      <Button
        className="openEnforcmentBtn"
        variant="default"
        onClick={handleShow}
      >
        <span className="openEnforcmentBtn__text">
          <IconOpenInNew size={30} />
        </span>
      </Button>

      <Modal
        size="lg"
        className="enforcmentModal"
        show={showModal}
        onHide={handleClose}
        ref={modalRef}
        centered={true}
      >
        <Modal.Header closeButton className="enforcementHeader">
          <span>{t('Enforcement')}</span>
        </Modal.Header>
        <Modal.Body>
          <div className="enforcmentModal__container">
            <h4>
              {title}{' '}
              <span>
                <strong>{t('Last updated')}</strong>: {t('30 June 2023')}
              </span>{' '}
            </h4>
          </div>
          {enforcement ? (
            <div className="enforcmentModal__content">
              {parse(enforcement.enforcement, {
                replace: domNode => {
                  if (domNode.attribs && domNode.attribs.class === 'replace') {
                    return (
                      <Tippy
                        className="SourceText__container"
                        content={
                          <SourceTooltip
                            text={
                              enforcement.sources.find(
                                s => s.pos == domNode.attribs.id
                              ).text
                            }
                          />
                        }
                        key={domNode.attribs.id}
                        interactive={true}
                        interactiveBorder={20}
                        delay={100}
                        allowHTML={true}
                        onShow={instance => {
                          hideAll({ duration: 0 })
                        }}
                        followCursor={'initial'}
                        theme={'ilga-enforce'}
                        plugins={[followCursor]}
                        placement={'top'}
                        popperOptions={{
                          modifiers: [{ name: 'flip', enabled: false }]
                        }}
                      >
                        <span
                          className="sourceTooltip"
                          style={{ fontSize: '14px' }}
                        >
                          <sup> [{domNode.attribs.id}]</sup>
                        </span>
                      </Tippy>
                    )
                  }
                }
              })}
            </div>
          ) : (
            <MainSpinner />
          )}
          <div className="methodologyActions__container">
            <Button
              key={'btn1'}
              variant="link"
              onClick={e => modalRef.current.dialog.scroll(0, 0)}
            >
              {t('Go to top')}
            </Button>
            {false && (
              <Button key={'btn2'} variant="link" onClick={handleDownloadPDF}>
                <PicAsPDF size={30} />
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
