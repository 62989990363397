var map = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-62.6509, -22.2339],
              [-61.7985, -23.1823],
              [-61.033, -23.7557],
              [-59.8924, -24.0932],
              [-59.373, -24.4536],
              [-57.8215, -25.1365],
              [-57.5873, -25.405],
              [-58.2032, -26.3815],
              [-58.1913, -26.6302],
              [-58.6187, -27.1321],
              [-58.1682, -27.2736],
              [-56.4372, -27.5539],
              [-56.1641, -27.3215],
              [-55.7148, -27.4151],
              [-55.4265, -27.0095],
              [-54.7552, -26.5331],
              [-54.6159, -25.576],
              [-53.8912, -25.6687],
              [-53.6713, -26.225],
              [-53.8381, -27.1211],
              [-54.829, -27.5509],
              [-55.1015, -27.8668],
              [-55.7255, -28.2043],
              [-56.9386, -29.5948],
              [-57.609, -30.188],
              [-58.1889, -31.9239],
              [-58.2012, -32.4719],
              [-58.5473, -33.6637],
              [-58.2836, -34.6836],
              [-57.3036, -35.1886],
              [-57.2649, -36.1444],
              [-56.698, -36.4265],
              [-56.6721, -36.8514],
              [-57.3957, -37.7449],
              [-57.5471, -38.0859],
              [-58.1792, -38.4361],
              [-59.8283, -38.8382],
              [-61.1121, -38.9929],
              [-62.0671, -38.9191],
              [-62.287, -39.8955],
              [-62.3951, -40.8909],
              [-62.9589, -41.1097],
              [-63.773, -41.1497],
              [-64.8532, -40.814],
              [-65.1333, -40.8804],
              [-65.0591, -41.9698],
              [-64.4205, -42.4338],
              [-63.5959, -42.4066],
              [-63.6926, -42.8052],
              [-64.1307, -42.8614],
              [-65.2836, -43.63],
              [-65.3614, -44.4773],
              [-65.6387, -45.0077],
              [-66.19, -44.9646],
              [-66.9415, -45.2573],
              [-67.5567, -45.9701],
              [-67.5633, -46.3454],
              [-66.7769, -47.0059],
              [-65.9986, -47.0938],
              [-65.7382, -47.3447],
              [-65.8101, -47.941],
              [-67.0331, -48.6279],
              [-67.6848, -49.2467],
              [-67.7835, -49.8593],
              [-68.9397, -50.3826],
              [-69.2352, -50.9504],
              [-69.0326, -51.6364],
              [-68.4433, -52.3567],
              [-69.9603, -52.0086],
              [-71.9187, -51.9897],
              [-72.4076, -51.541],
              [-72.2762, -50.9104],
              [-72.5098, -50.6076],
              [-73.1529, -50.7386],
              [-73.5763, -49.5828],
              [-72.6144, -48.7927],
              [-72.3549, -48.366],
              [-72.5179, -47.876],
              [-71.9051, -47.2014],
              [-71.6953, -46.5783],
              [-71.7726, -45.7244],
              [-71.3536, -45.2305],
              [-71.5962, -44.9796],
              [-71.2127, -44.4412],
              [-71.8201, -44.3832],
              [-71.6801, -43.9297],
              [-72.1463, -42.9897],
              [-72.1248, -42.2985],
              [-71.7609, -42.1016],
              [-71.9115, -41.6507],
              [-71.9321, -40.6918],
              [-71.6953, -40.3354],
              [-71.7199, -39.6354],
              [-71.4256, -38.9854],
              [-70.9517, -38.7385],
              [-71.1677, -37.762],
              [-71.0556, -36.5236],
              [-70.4048, -36.0618],
              [-70.4703, -35.3261],
              [-70.2899, -34.7328],
              [-69.8524, -34.2244],
              [-69.7979, -33.3987],
              [-70.0198, -33.2717],
              [-70.1697, -32.4719],
              [-70.5852, -31.5692],
              [-70.1613, -30.4403],
              [-69.9563, -30.3581],
              [-70.0269, -29.3241],
              [-69.6568, -28.4139],
              [-69.1743, -27.9244],
              [-68.8463, -27.1536],
              [-68.3462, -27.0279],
              [-68.5811, -26.5181],
              [-68.4145, -26.1538],
              [-68.6002, -25.4859],
              [-68.3842, -25.0921],
              [-68.562, -24.8377],
              [-68.2502, -24.3921],
              [-67.3561, -24.0339],
              [-67.0089, -23.0012],
              [-67.1948, -22.8215],
              [-66.7116, -22.2163],
              [-66.0986, -21.8353],
              [-65.771, -22.0994],
              [-64.6056, -22.2291],
              [-64.374, -22.7613],
              [-63.9763, -22.0726],
              [-62.8433, -21.9975],
              [-62.6509, -22.2339]
            ]
          ],
          [
            [
              [-68.6531, -54.8536],
              [-68.6298, -52.6524],
              [-68.0085, -53.5638],
              [-67.2943, -54.0499],
              [-65.346, -54.8778],
              [-66.5112, -55.0321],
              [-66.9305, -54.9248],
              [-68.6531, -54.8536]
            ]
          ]
        ]
      },
      properties: { name: "Argentina", id: "AR", Continent: "South America" },
      id: "AR"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-82.5617, 21.5716],
              [-82.8531, 21.4437],
              [-83.0826, 21.7913],
              [-82.7146, 21.8903],
              [-82.5617, 21.5716]
            ]
          ],
          [
            [
              [-81.8375, 23.1629],
              [-81.1785, 23.0595],
              [-80.6501, 23.103],
              [-80.0752, 22.9423],
              [-79.183, 22.3879],
              [-78.6865, 22.367],
              [-77.8651, 21.9005],
              [-77.4973, 21.8717],
              [-76.6475, 21.2844],
              [-75.7229, 21.1108],
              [-75.6428, 20.7335],
              [-74.8826, 20.6505],
              [-74.2529, 20.0797],
              [-75.1165, 19.9012],
              [-76.1586, 19.9896],
              [-77.7151, 19.8555],
              [-77.2135, 20.3003],
              [-77.2297, 20.6438],
              [-77.9974, 20.7153],
              [-78.4908, 21.0536],
              [-78.6366, 21.5157],
              [-79.2745, 21.5627],
              [-80.2313, 21.8721],
              [-80.4992, 22.0634],
              [-80.9619, 22.0528],
              [-81.8162, 22.2001],
              [-81.9034, 22.6791],
              [-82.7382, 22.6893],
              [-83.3796, 22.2228],
              [-84.3264, 22.0745],
              [-84.3612, 22.379],
              [-84.045, 22.6662],
              [-83.2577, 22.9676],
              [-82.1014, 23.1905],
              [-81.8375, 23.1629]
            ]
          ]
        ]
      },
      properties: { name: "Cuba", id: "CU" },
      id: "CU"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-58.1599, -20.1645],
            [-58.1803, -19.8178],
            [-59.0904, -19.2861],
            [-60.0073, -19.2975],
            [-61.757, -19.6451],
            [-62.2762, -20.5627],
            [-62.2767, -21.0659],
            [-62.6509, -22.2339],
            [-62.8433, -21.9975],
            [-63.9763, -22.0726],
            [-64.374, -22.7613],
            [-64.6056, -22.2291],
            [-65.771, -22.0994],
            [-66.0986, -21.8353],
            [-66.7116, -22.2163],
            [-67.1948, -22.8215],
            [-67.8795, -22.8228],
            [-68.197, -21.3005],
            [-68.5, -20.6119],
            [-68.7452, -20.459],
            [-68.6984, -19.7211],
            [-68.4629, -19.4328],
            [-68.9684, -18.9683],
            [-69.0941, -18.0508],
            [-69.5109, -17.5058],
            [-69.625, -17.2],
            [-69.0207, -16.6423],
            [-69.4184, -15.6034],
            [-69.1726, -15.2369],
            [-69.3747, -14.9632],
            [-68.8803, -14.199],
            [-69.0741, -13.6826],
            [-68.9786, -12.8802],
            [-68.6852, -12.5022],
            [-69.5786, -10.9518],
            [-68.398, -11.0186],
            [-66.7301, -9.9754],
            [-65.3961, -9.7126],
            [-65.39, -11.2463],
            [-64.9925, -11.9753],
            [-64.4205, -12.4398],
            [-63.0675, -12.6692],
            [-62.7653, -12.9975],
            [-62.176, -13.1337],
            [-61.79, -13.5257],
            [-61.077, -13.4897],
            [-60.5066, -13.7898],
            [-60.2989, -14.6186],
            [-60.1756, -16.2692],
            [-58.538, -16.3285],
            [-58.3961, -17.2343],
            [-57.8325, -17.5124],
            [-57.5741, -18.2793],
            [-57.8006, -19.0808],
            [-58.1315, -19.7444],
            [-58.1599, -20.1645]
          ]
        ]
      },
      properties: { name: "Bolivia", id: "BO", Continent: "South America" },
      id: "BO"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-56.4829, 1.9422],
              [-56.1296, 2.2994],
              [-55.0057, 2.593],
              [-54.6163, 2.3267],
              [-54.1301, 2.121],
              [-53.7678, 2.3552],
              [-52.9647, 2.1839],
              [-52.7006, 2.3636],
              [-51.9906, 3.7017],
              [-51.6527, 4.0612],
              [-51.2198, 4.0937],
              [-51.0524, 3.2821],
              [-50.6591, 2.1307],
              [-50.4589, 1.8297],
              [-49.9573, 1.66],
              [-49.8988, 1.1626],
              [-50.2945, 0.8356],
              [-50.8164, 0.1725],
              [-51.2829, -0.085],
              [-51.7026, -0.7622],
              [-51.9475, -1.5866],
              [-50.9177, -1.1151],
              [-50.6901, -1.7615],
              [-50.4033, -2.0156],
              [-49.7195, -1.9263],
              [-49.3137, -1.7317],
              [-48.9913, -1.8301],
              [-48.1151, -0.7376],
              [-47.3981, -0.6264],
              [-46.8112, -0.7798],
              [-45.6447, -1.348],
              [-45.0763, -1.4662],
              [-44.6513, -1.7457],
              [-44.3083, -2.5354],
              [-44.1128, -2.5983],
              [-42.9368, -2.4651],
              [-42.2497, -2.7921],
              [-41.8762, -2.7468],
              [-41.3181, -2.9362],
              [-40.4745, -2.7956],
              [-39.9648, -2.8615],
              [-38.4757, -3.7176],
              [-38.049, -4.2163],
              [-37.1747, -4.9124],
              [-36.5906, -5.0974],
              [-35.5494, -5.1295],
              [-35.2356, -5.5668],
              [-34.8056, -7.2886],
              [-34.8346, -7.9715],
              [-35.3408, -9.2305],
              [-36.3982, -10.4843],
              [-36.9378, -10.8204],
              [-37.412, -11.4972],
              [-37.6886, -12.0997],
              [-38.2397, -12.8441],
              [-38.7879, -12.7826],
              [-39.0894, -13.5881],
              [-38.9422, -14.0306],
              [-39.0597, -14.6551],
              [-38.8804, -15.8645],
              [-39.2153, -17.3156],
              [-39.154, -17.704],
              [-39.4869, -17.9901],
              [-39.74, -18.6396],
              [-39.7, -19.2782],
              [-40.3959, -20.5693],
              [-40.7892, -20.9063],
              [-41.0471, -21.5057],
              [-41.1227, -22.0845],
              [-41.7054, -22.3099],
              [-42.0423, -22.9472],
              [-43.5327, -23.046],
              [-43.866, -22.9102],
              [-44.6372, -23.0557],
              [-45.4232, -23.6854],
              [-45.9721, -23.7957],
              [-46.8672, -24.2365],
              [-47.8767, -24.9976],
              [-48.5451, -25.8159],
              [-48.6776, -26.7032],
              [-48.5543, -27.1962],
              [-48.6209, -28.0756],
              [-48.7997, -28.5752],
              [-49.7461, -29.3632],
              [-50.2994, -30.4258],
              [-51.1572, -30.3642],
              [-51.4637, -31.0529],
              [-51.9724, -31.3838],
              [-52.1273, -32.1678],
              [-52.6522, -33.1376],
              [-53.3707, -33.7423],
              [-53.5313, -33.171],
              [-53.1255, -32.7368],
              [-53.7618, -32.057],
              [-54.5876, -31.4848],
              [-55.8736, -31.0696],
              [-56.1762, -30.6284],
              [-56.8327, -30.1072],
              [-57.609, -30.188],
              [-56.9386, -29.5948],
              [-55.7255, -28.2043],
              [-55.1015, -27.8668],
              [-54.829, -27.5509],
              [-53.8381, -27.1211],
              [-53.6713, -26.225],
              [-53.8912, -25.6687],
              [-54.6159, -25.576],
              [-54.2419, -24.0471],
              [-54.6254, -23.8124],
              [-55.3663, -23.9913],
              [-55.8492, -22.3077],
              [-56.5238, -22.1025],
              [-56.9373, -22.2713],
              [-57.9856, -22.0463],
              [-57.8303, -20.9982],
              [-58.1599, -20.1645],
              [-58.1315, -19.7444],
              [-57.8006, -19.0808],
              [-57.5741, -18.2793],
              [-57.8325, -17.5124],
              [-58.3961, -17.2343],
              [-58.538, -16.3285],
              [-60.1756, -16.2692],
              [-60.2989, -14.6186],
              [-60.5066, -13.7898],
              [-61.077, -13.4897],
              [-61.79, -13.5257],
              [-62.176, -13.1337],
              [-62.7653, -12.9975],
              [-63.0675, -12.6692],
              [-64.4205, -12.4398],
              [-64.9925, -11.9753],
              [-65.39, -11.2463],
              [-65.3961, -9.7126],
              [-66.7301, -9.9754],
              [-68.398, -11.0186],
              [-69.5786, -10.9518],
              [-70.6423, -11.0103],
              [-70.637, -9.4783],
              [-71.238, -9.9661],
              [-72.1815, -10.0039],
              [-72.379, -9.51],
              [-73.2094, -9.4115],
              [-72.9741, -8.9932],
              [-73.5491, -8.3459],
              [-74.0022, -7.5562],
              [-73.7495, -7.3356],
              [-73.758, -6.9058],
              [-73.1375, -6.4659],
              [-73.2355, -6.0985],
              [-72.887, -5.1229],
              [-71.8449, -4.5046],
              [-70.9737, -4.3504],
              [-70.7994, -4.1733],
              [-69.9658, -4.2357],
              [-69.4002, -1.1947],
              [-69.6676, -0.4823],
              [-70.0706, -0.1386],
              [-70.0539, 0.5785],
              [-69.1633, 0.8642],
              [-69.8522, 1.0593],
              [-69.8487, 1.7088],
              [-68.1766, 1.7198],
              [-67.3519, 2.0859],
              [-66.8762, 1.2232],
              [-66.4293, 0.822],
              [-65.1037, 1.1081],
              [-64.1149, 1.6192],
              [-64.0354, 1.9044],
              [-63.394, 2.2225],
              [-63.9242, 2.4524],
              [-64.2188, 3.2047],
              [-64.2213, 3.5875],
              [-64.568, 3.8999],
              [-63.2949, 3.9223],
              [-62.9686, 3.5941],
              [-62.7122, 4.0177],
              [-62.1532, 4.0981],
              [-61.0027, 4.5354],
              [-60.6046, 4.9946],
              [-60.7421, 5.202],
              [-59.9906, 5.0829],
              [-60.1113, 4.5112],
              [-59.8333, 4.476],
              [-59.5512, 3.9333],
              [-59.8544, 3.5875],
              [-59.9942, 2.6901],
              [-59.7564, 1.9004],
              [-59.2313, 1.3757],
              [-58.8626, 1.2034],
              [-58.3407, 1.5875],
              [-57.5943, 1.7044],
              [-57.119, 2.0142],
              [-56.4829, 1.9422]
            ]
          ],
          [
            [
              [-49.6288, -0.2292],
              [-49.1168, -0.1637],
              [-48.3798, -0.3527],
              [-48.9289, -1.4825],
              [-49.5879, -1.7123],
              [-50.5077, -1.7879],
              [-50.7597, -1.2404],
              [-50.6454, -0.2727],
              [-50.2482, -0.1167],
              [-49.6288, -0.2292]
            ]
          ],
          [
            [
              [-51.8326, -1.4337],
              [-51.5461, -0.6497],
              [-51.2765, -1.0215],
              [-51.8326, -1.4337]
            ]
          ]
        ]
      },
      properties: { name: "Brazil", id: "BR", Continent: "South America" },
      id: "BR"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-67.1948, -22.8215],
              [-67.0089, -23.0012],
              [-67.3561, -24.0339],
              [-68.2502, -24.3921],
              [-68.562, -24.8377],
              [-68.3842, -25.0921],
              [-68.6002, -25.4859],
              [-68.4145, -26.1538],
              [-68.5811, -26.5181],
              [-68.3462, -27.0279],
              [-68.8463, -27.1536],
              [-69.1743, -27.9244],
              [-69.6568, -28.4139],
              [-70.0269, -29.3241],
              [-69.9563, -30.3581],
              [-70.1613, -30.4403],
              [-70.5852, -31.5692],
              [-70.1697, -32.4719],
              [-70.0198, -33.2717],
              [-69.7979, -33.3987],
              [-69.8524, -34.2244],
              [-70.2899, -34.7328],
              [-70.4703, -35.3261],
              [-70.4048, -36.0618],
              [-71.0556, -36.5236],
              [-71.1677, -37.762],
              [-70.9517, -38.7385],
              [-71.4256, -38.9854],
              [-71.7199, -39.6354],
              [-71.6953, -40.3354],
              [-71.9321, -40.6918],
              [-71.9115, -41.6507],
              [-71.7609, -42.1016],
              [-72.1248, -42.2985],
              [-72.1463, -42.9897],
              [-71.6801, -43.9297],
              [-71.8201, -44.3832],
              [-71.2127, -44.4412],
              [-71.5962, -44.9796],
              [-71.3536, -45.2305],
              [-71.7726, -45.7244],
              [-71.6953, -46.5783],
              [-71.9051, -47.2014],
              [-72.5179, -47.876],
              [-72.3549, -48.366],
              [-72.6144, -48.7927],
              [-73.5763, -49.5828],
              [-73.1529, -50.7386],
              [-72.5098, -50.6076],
              [-72.2762, -50.9104],
              [-72.4076, -51.541],
              [-71.9187, -51.9897],
              [-69.9603, -52.0086],
              [-68.4433, -52.3567],
              [-69.2409, -52.2055],
              [-69.5606, -52.4217],
              [-70.795, -52.7689],
              [-70.9959, -53.7792],
              [-72.1745, -53.632],
              [-71.4004, -53.1073],
              [-73.0527, -53.2435],
              [-74.0145, -52.6392],
              [-73.5183, -52.0407],
              [-73.9734, -51.7845],
              [-73.8958, -51.3314],
              [-74.8147, -51.0629],
              [-75.0949, -50.681],
              [-74.3337, -49.9744],
              [-74.341, -48.5958],
              [-74.655, -47.7024],
              [-74.152, -46.9742],
              [-75.6567, -46.6104],
              [-74.9246, -46.1595],
              [-75.0665, -45.8752],
              [-74.1579, -45.7671],
              [-73.5497, -45.4836],
              [-73.3625, -44.9783],
              [-72.739, -44.7344],
              [-72.6638, -44.4364],
              [-73.265, -44.1688],
              [-72.9965, -43.6318],
              [-72.7853, -42.3015],
              [-72.4995, -41.9807],
              [-73.0149, -41.5439],
              [-73.5214, -41.7971],
              [-73.9659, -41.1185],
              [-73.6695, -40.0823],
              [-73.2265, -39.2245],
              [-73.6618, -37.6987],
              [-73.6018, -37.1885],
              [-73.216, -37.167],
              [-72.6238, -35.5858],
              [-72.2237, -35.0963],
              [-72.0029, -34.1651],
              [-71.6645, -33.6527],
              [-71.7429, -33.095],
              [-71.4522, -32.6595],
              [-71.6619, -31.1693],
              [-71.6695, -30.3304],
              [-71.4006, -30.1432],
              [-71.3158, -29.6497],
              [-71.5193, -28.9264],
              [-71.1864, -28.3779],
              [-70.6465, -26.3292],
              [-70.7137, -25.7843],
              [-70.4454, -25.1725],
              [-70.5742, -24.6443],
              [-70.4879, -23.7817],
              [-70.5632, -23.057],
              [-70.3316, -22.8487],
              [-70.0875, -21.493],
              [-70.1972, -20.7257],
              [-70.1484, -19.8051],
              [-70.4184, -18.3452],
              [-69.9262, -18.2059],
              [-69.8522, -17.704],
              [-69.5109, -17.5058],
              [-69.0941, -18.0508],
              [-68.9684, -18.9683],
              [-68.4629, -19.4328],
              [-68.6984, -19.7211],
              [-68.7452, -20.459],
              [-68.5, -20.6119],
              [-68.197, -21.3005],
              [-67.8795, -22.8228],
              [-67.1948, -22.8215]
            ]
          ],
          [
            [
              [-73.7734, -43.3457],
              [-74.355, -43.2639],
              [-74.0369, -41.7953],
              [-73.5278, -41.8959],
              [-73.4364, -42.9366],
              [-73.7734, -43.3457]
            ]
          ],
          [
            [
              [-73.7354, -44.3947],
              [-73.7271, -45.1193],
              [-74.0991, -45.325],
              [-74.6178, -44.6478],
              [-73.7354, -44.3947]
            ]
          ],
          [
            [
              [-74.4763, -49.1478],
              [-74.5699, -49.9911],
              [-75.3001, -49.8474],
              [-75.1843, -49.0836],
              [-74.4763, -49.1478]
            ]
          ],
          [
            [
              [-68.6298, -52.6524],
              [-68.6531, -54.8536],
              [-69.7234, -54.7121],
              [-70.4971, -54.8093],
              [-70.8983, -54.3382],
              [-70.8678, -53.8842],
              [-70.0904, -53.4184],
              [-70.3907, -53.0264],
              [-69.7636, -52.7315],
              [-68.6298, -52.6524]
            ]
          ],
          [
            [
              [-69.703, -54.9191],
              [-68.2827, -55.2549],
              [-68.2932, -55.5212],
              [-69.4119, -55.4443],
              [-69.703, -54.9191]
            ]
          ]
        ]
      },
      properties: { name: "Chile", id: "CL", Continent: "South America" },
      id: "CL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.3197, 11.8619],
            [-71.958, 11.6664],
            [-72.2485, 11.1966],
            [-72.6902, 10.8363],
            [-73.0066, 9.7895],
            [-73.3564, 9.227],
            [-72.7963, 9.1088],
            [-72.4166, 8.3819],
            [-72.443, 7.4551],
            [-72.0844, 7.0965],
            [-71.0134, 6.9946],
            [-70.6553, 7.0829],
            [-70.095, 6.9379],
            [-69.4391, 6.135],
            [-68.4716, 6.1565],
            [-67.859, 6.2897],
            [-67.4394, 6.0256],
            [-67.825, 5.2706],
            [-67.8553, 4.5072],
            [-67.6615, 3.8643],
            [-67.311, 3.4161],
            [-67.8347, 2.8927],
            [-67.2109, 2.39],
            [-66.8762, 1.2232],
            [-67.3519, 2.0859],
            [-68.1766, 1.7198],
            [-69.8487, 1.7088],
            [-69.8522, 1.0593],
            [-69.1633, 0.8642],
            [-70.0539, 0.5785],
            [-70.0706, -0.1386],
            [-69.6676, -0.4823],
            [-69.4002, -1.1947],
            [-69.9658, -4.2357],
            [-70.3395, -3.8142],
            [-70.735, -3.7817],
            [-70.0741, -2.7503],
            [-70.9146, -2.2186],
            [-71.3969, -2.3342],
            [-71.7526, -2.1527],
            [-72.3957, -2.4286],
            [-73.1545, -2.2783],
            [-73.1969, -1.8305],
            [-73.4963, -1.6934],
            [-73.6644, -1.2487],
            [-74.2465, -0.9705],
            [-74.8017, -0.2002],
            [-75.2845, -0.1061],
            [-76.311, 0.4485],
            [-76.4945, 0.2353],
            [-77.4229, 0.4252],
            [-77.4677, 0.6361],
            [-78.3121, 1.0461],
            [-78.8597, 1.4552],
            [-78.5769, 1.7739],
            [-78.6171, 2.3065],
            [-78.1201, 2.4884],
            [-77.6701, 2.8786],
            [-77.1667, 3.8621],
            [-77.4273, 4.0603],
            [-77.2862, 4.7217],
            [-77.4018, 5.416],
            [-77.2493, 5.7799],
            [-77.4694, 6.1772],
            [-77.36, 6.5046],
            [-77.901, 7.2292],
            [-76.852, 8.0905],
            [-76.8878, 8.6197],
            [-76.2768, 8.9888],
            [-76.1355, 9.2656],
            [-75.6393, 9.4502],
            [-75.671, 10.1964],
            [-75.446, 10.6108],
            [-74.8446, 11.1096],
            [-74.4544, 10.9892],
            [-74.0593, 11.3407],
            [-73.3135, 11.2959],
            [-72.2749, 11.8892],
            [-71.7146, 12.42],
            [-71.2622, 12.3352],
            [-71.3197, 11.8619]
          ]
        ]
      },
      properties: { name: "Colombia", id: "CO", Continent: "South America" },
      id: "CO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.2845, -0.1061],
            [-75.2632, -0.5557],
            [-75.5706, -1.5313],
            [-76.0898, -2.1333],
            [-76.6791, -2.5627],
            [-77.8608, -2.9819],
            [-78.3473, -3.431],
            [-78.4198, -3.7769],
            [-79.0763, -4.9907],
            [-79.3307, -4.9278],
            [-79.6384, -4.4545],
            [-80.51, -4.0696],
            [-80.1793, -3.8775],
            [-80.3248, -3.388],
            [-79.9216, -3.09],
            [-79.73, -2.5794],
            [-80.45, -2.626],
            [-80.7704, -2.0766],
            [-80.9025, -1.0791],
            [-80.554, -0.8479],
            [-80.4823, -0.368],
            [-80.1334, -0.0059],
            [-80.0884, 0.7846],
            [-78.8996, 1.2061],
            [-78.8597, 1.4552],
            [-78.3121, 1.0461],
            [-77.4677, 0.6361],
            [-77.4229, 0.4252],
            [-76.4945, 0.2353],
            [-76.311, 0.4485],
            [-75.2845, -0.1061]
          ]
        ]
      },
      properties: { name: "Ecuador", id: "EC", Continent: "South America" },
      id: "EC"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-60.2864, -51.4619],
              [-59.268, -51.4272],
              [-59.9214, -51.9695],
              [-60.2864, -51.4619]
            ]
          ],
          [
            [
              [-58.85, -51.2699],
              [-57.8382, -51.7094],
              [-59.068, -52.173],
              [-58.85, -51.2699]
            ]
          ]
        ]
      },
      properties: { name: "Falkland Islands", id: "FK", Continent: "South America" },
      id: "FK"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-51.6527, 4.0612],
            [-51.9906, 3.7017],
            [-52.7006, 2.3636],
            [-52.9647, 2.1839],
            [-53.7678, 2.3552],
            [-54.1301, 2.121],
            [-54.6163, 2.3267],
            [-54.1953, 2.818],
            [-53.9903, 3.5897],
            [-54.3509, 4.0542],
            [-54.4522, 5.0135],
            [-54.156, 5.3589],
            [-53.8473, 5.7825],
            [-52.8992, 5.4248],
            [-52.2905, 4.9419],
            [-51.6527, 4.0612]
          ]
        ]
      },
      properties: { name: "French Guiana", id: "GF", Continent: "South America" },
      id: "GF"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-57.1948, 5.5483],
            [-57.3308, 5.0201],
            [-57.711, 4.9911],
            [-58.0544, 4.1719],
            [-56.7044, 2.0362],
            [-56.4829, 1.9422],
            [-57.119, 2.0142],
            [-57.5943, 1.7044],
            [-58.3407, 1.5875],
            [-58.8626, 1.2034],
            [-59.2313, 1.3757],
            [-59.7564, 1.9004],
            [-59.9942, 2.6901],
            [-59.8544, 3.5875],
            [-59.5512, 3.9333],
            [-59.8333, 4.476],
            [-60.1113, 4.5112],
            [-59.9906, 5.0829],
            [-60.7421, 5.202],
            [-61.3767, 5.9069],
            [-61.1286, 6.2141],
            [-61.1457, 6.6944],
            [-60.3952, 6.9453],
            [-60.719, 7.499],
            [-60.5136, 7.8133],
            [-60.0324, 8.0536],
            [-60.0174, 8.5493],
            [-59.2003, 8.0747],
            [-58.6266, 7.5461],
            [-58.4807, 7.0381],
            [-57.9826, 6.7858],
            [-57.1902, 6.0976],
            [-57.1948, 5.5483]
          ]
        ]
      },
      properties: { name: "Guyana", id: "GY", Continent: "South America" },
      id: "GY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.9658, -4.2357],
            [-70.7994, -4.1733],
            [-70.9737, -4.3504],
            [-71.8449, -4.5046],
            [-72.887, -5.1229],
            [-73.2355, -6.0985],
            [-73.1375, -6.4659],
            [-73.758, -6.9058],
            [-73.7495, -7.3356],
            [-74.0022, -7.5562],
            [-73.5491, -8.3459],
            [-72.9741, -8.9932],
            [-73.2094, -9.4115],
            [-72.379, -9.51],
            [-72.1815, -10.0039],
            [-71.238, -9.9661],
            [-70.637, -9.4783],
            [-70.6423, -11.0103],
            [-69.5786, -10.9518],
            [-68.6852, -12.5022],
            [-68.9786, -12.8802],
            [-69.0741, -13.6826],
            [-68.8803, -14.199],
            [-69.3747, -14.9632],
            [-69.1726, -15.2369],
            [-69.4184, -15.6034],
            [-69.0207, -16.6423],
            [-69.625, -17.2],
            [-69.5109, -17.5058],
            [-69.8522, -17.704],
            [-69.9262, -18.2059],
            [-70.4184, -18.3452],
            [-71.3371, -17.6825],
            [-71.5324, -17.2945],
            [-72.4676, -16.7078],
            [-73.8251, -16.1532],
            [-75.1043, -15.4118],
            [-75.934, -14.6336],
            [-76.2891, -14.1335],
            [-76.1838, -13.5156],
            [-76.8322, -12.3489],
            [-77.1526, -12.0601],
            [-77.2203, -11.6633],
            [-77.6331, -11.288],
            [-77.7362, -10.8367],
            [-78.1855, -10.0892],
            [-78.7623, -8.617],
            [-79.3773, -7.8352],
            [-79.7621, -7.0666],
            [-80.1103, -6.6496],
            [-80.8117, -6.2822],
            [-81.2832, -4.3222],
            [-80.7985, -3.7312],
            [-80.3248, -3.388],
            [-80.1793, -3.8775],
            [-80.51, -4.0696],
            [-79.6384, -4.4545],
            [-79.3307, -4.9278],
            [-79.0763, -4.9907],
            [-78.4198, -3.7769],
            [-78.3473, -3.431],
            [-77.8608, -2.9819],
            [-76.6791, -2.5627],
            [-76.0898, -2.1333],
            [-75.5706, -1.5313],
            [-75.2632, -0.5557],
            [-75.2845, -0.1061],
            [-74.8017, -0.2002],
            [-74.2465, -0.9705],
            [-73.6644, -1.2487],
            [-73.4963, -1.6934],
            [-73.1969, -1.8305],
            [-73.1545, -2.2783],
            [-72.3957, -2.4286],
            [-71.7526, -2.1527],
            [-71.3969, -2.3342],
            [-70.9146, -2.2186],
            [-70.0741, -2.7503],
            [-70.735, -3.7817],
            [-70.3395, -3.8142],
            [-69.9658, -4.2357]
          ]
        ]
      },
      properties: { name: "Peru", id: "PE", Continent: "South America" },
      id: "PE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-58.1599, -20.1645],
            [-57.8303, -20.9982],
            [-57.9856, -22.0463],
            [-56.9373, -22.2713],
            [-56.5238, -22.1025],
            [-55.8492, -22.3077],
            [-55.3663, -23.9913],
            [-54.6254, -23.8124],
            [-54.2419, -24.0471],
            [-54.6159, -25.576],
            [-54.7552, -26.5331],
            [-55.4265, -27.0095],
            [-55.7148, -27.4151],
            [-56.1641, -27.3215],
            [-56.4372, -27.5539],
            [-58.1682, -27.2736],
            [-58.6187, -27.1321],
            [-58.1913, -26.6302],
            [-58.2032, -26.3815],
            [-57.5873, -25.405],
            [-57.8215, -25.1365],
            [-59.373, -24.4536],
            [-59.8924, -24.0932],
            [-61.033, -23.7557],
            [-61.7985, -23.1823],
            [-62.6509, -22.2339],
            [-62.2767, -21.0659],
            [-62.2762, -20.5627],
            [-61.757, -19.6451],
            [-60.0073, -19.2975],
            [-59.0904, -19.2861],
            [-58.1803, -19.8178],
            [-58.1599, -20.1645]
          ]
        ]
      },
      properties: { name: "Paraguay", id: "PY", Continent: "South America" },
      id: "PY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-54.156, 5.3589],
            [-54.4522, 5.0135],
            [-54.3509, 4.0542],
            [-53.9903, 3.5897],
            [-54.1953, 2.818],
            [-54.6163, 2.3267],
            [-55.0057, 2.593],
            [-56.1296, 2.2994],
            [-56.4829, 1.9422],
            [-56.7044, 2.0362],
            [-58.0544, 4.1719],
            [-57.711, 4.9911],
            [-57.3308, 5.0201],
            [-57.1948, 5.5483],
            [-56.9698, 5.9926],
            [-55.9395, 5.7957],
            [-55.8284, 5.9618],
            [-54.8339, 5.9882],
            [-54.0543, 5.808],
            [-54.156, 5.3589]
          ]
        ]
      },
      properties: { name: "Suriname", id: "SR", Continent: "South America" },
      id: "SR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.3707, -33.7423],
            [-53.7853, -34.3804],
            [-54.1685, -34.6709],
            [-54.9024, -34.9328],
            [-55.6732, -34.7755],
            [-56.118, -34.9077],
            [-57.1707, -34.4525],
            [-57.8292, -34.4775],
            [-58.4003, -33.9124],
            [-58.3535, -33.2602],
            [-58.0823, -32.8937],
            [-58.2012, -32.4719],
            [-58.1889, -31.9239],
            [-57.609, -30.188],
            [-56.8327, -30.1072],
            [-56.1762, -30.6284],
            [-55.8736, -31.0696],
            [-54.5876, -31.4848],
            [-53.7618, -32.057],
            [-53.1255, -32.7368],
            [-53.5313, -33.171],
            [-53.3707, -33.7423]
          ]
        ]
      },
      properties: { name: "Uruguay", id: "UY", Continent: "South America" },
      id: "UY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.0174, 8.5493],
            [-60.0324, 8.0536],
            [-60.5136, 7.8133],
            [-60.719, 7.499],
            [-60.3952, 6.9453],
            [-61.1457, 6.6944],
            [-61.1286, 6.2141],
            [-61.3767, 5.9069],
            [-60.7421, 5.202],
            [-60.6046, 4.9946],
            [-61.0027, 4.5354],
            [-62.1532, 4.0981],
            [-62.7122, 4.0177],
            [-62.9686, 3.5941],
            [-63.2949, 3.9223],
            [-64.568, 3.8999],
            [-64.2213, 3.5875],
            [-64.2188, 3.2047],
            [-63.9242, 2.4524],
            [-63.394, 2.2225],
            [-64.0354, 1.9044],
            [-64.1149, 1.6192],
            [-65.1037, 1.1081],
            [-66.4293, 0.822],
            [-66.8762, 1.2232],
            [-67.2109, 2.39],
            [-67.8347, 2.8927],
            [-67.311, 3.4161],
            [-67.6615, 3.8643],
            [-67.8553, 4.5072],
            [-67.825, 5.2706],
            [-67.4394, 6.0256],
            [-67.859, 6.2897],
            [-68.4716, 6.1565],
            [-69.4391, 6.135],
            [-70.095, 6.9379],
            [-70.6553, 7.0829],
            [-71.0134, 6.9946],
            [-72.0844, 7.0965],
            [-72.443, 7.4551],
            [-72.4166, 8.3819],
            [-72.7963, 9.1088],
            [-73.3564, 9.227],
            [-73.0066, 9.7895],
            [-72.6902, 10.8363],
            [-72.2485, 11.1966],
            [-71.958, 11.6664],
            [-71.3197, 11.8619],
            [-71.9468, 11.4146],
            [-71.5942, 10.657],
            [-72.113, 9.8154],
            [-71.6869, 9.0723],
            [-71.298, 9.1259],
            [-71.0528, 9.706],
            [-71.3868, 10.2641],
            [-71.4696, 10.9641],
            [-70.2325, 11.3733],
            [-70.2866, 11.8857],
            [-70.004, 12.1779],
            [-69.6316, 11.4801],
            [-68.8278, 11.4317],
            [-68.3987, 11.161],
            [-68.2339, 10.5691],
            [-67.8716, 10.4719],
            [-66.2471, 10.6323],
            [-65.8518, 10.2575],
            [-64.8503, 10.098],
            [-63.8734, 10.664],
            [-62.7025, 10.7501],
            [-62.8429, 10.4179],
            [-62.5151, 10.1762],
            [-60.7924, 9.3606],
            [-61.0532, 9.0951],
            [-61.3039, 8.41],
            [-60.801, 8.5924],
            [-60.0174, 8.5493]
          ]
        ]
      },
      properties: { name: "Venezuela", id: "VE", Continent: "South America" },
      id: "VE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.8941, 15.8908],
            [-89.2373, 15.8944],
            [-89.1615, 17.8148],
            [-88.2956, 18.4722],
            [-88.0972, 18.1215],
            [-88.2716, 17.61],
            [-88.3134, 16.6326],
            [-88.8941, 15.8908]
          ]
        ]
      },
      properties: { name: "Belize", id: "BZ", Continent: "Central America" },
      id: "BZ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.642, 10.9171],
            [-83.4482, 10.4658],
            [-82.5636, 9.5768],
            [-82.94, 9.4493],
            [-82.8793, 8.0708],
            [-83.1626, 8.588],
            [-83.7341, 8.6144],
            [-83.6372, 9.0354],
            [-84.6589, 9.6471],
            [-85.1146, 9.5816],
            [-85.6248, 9.9024],
            [-85.8305, 10.3981],
            [-85.7443, 11.0621],
            [-83.642, 10.9171]
          ]
        ]
      },
      properties: { name: "Costa Rica", id: "CR", Continent: "Central America" },
      id: "CR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.1615, 17.8148],
            [-89.2373, 15.8944],
            [-88.8941, 15.8908],
            [-88.2284, 15.7291],
            [-89.2224, 14.866],
            [-89.3626, 14.416],
            [-90.0952, 13.7366],
            [-91.1461, 13.9256],
            [-92.2353, 14.5452],
            [-92.1872, 15.3209],
            [-91.7365, 16.0701],
            [-90.4472, 16.0728],
            [-90.4171, 16.3909],
            [-90.993, 17.2527],
            [-90.9892, 17.8161],
            [-89.1615, 17.8148]
          ]
        ]
      },
      properties: { name: "Guatemala", id: "GT", Continent: "Central America" },
      id: "GT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.1577, 14.993],
            [-84.1144, 14.7311],
            [-84.9852, 14.7527],
            [-85.7868, 13.8443],
            [-86.1513, 13.9946],
            [-86.7336, 13.7634],
            [-86.7107, 13.3134],
            [-87.3374, 12.979],
            [-87.8144, 13.3991],
            [-87.7153, 13.8127],
            [-88.449, 13.8509],
            [-89.3626, 14.416],
            [-89.2224, 14.866],
            [-88.2284, 15.7291],
            [-87.6182, 15.9097],
            [-86.3567, 15.7832],
            [-85.7839, 16.0029],
            [-84.9738, 15.9902],
            [-84.2614, 15.8227],
            [-83.7604, 15.2202],
            [-83.1577, 14.993]
          ]
        ]
      },
      properties: { name: "Honduras", id: "HN", Continent: "Central America" },
      id: "HN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.2956, 18.4722],
            [-89.1615, 17.8148],
            [-90.9892, 17.8161],
            [-90.993, 17.2527],
            [-90.4171, 16.3909],
            [-90.4472, 16.0728],
            [-91.7365, 16.0701],
            [-92.1872, 15.3209],
            [-92.2353, 14.5452],
            [-92.809, 15.1385],
            [-93.916, 16.0534],
            [-94.9004, 16.4177],
            [-95.4644, 15.9743],
            [-96.5107, 15.6518],
            [-97.7548, 15.9669],
            [-98.7623, 16.5346],
            [-99.6906, 16.7197],
            [-100.8479, 17.2004],
            [-101.847, 17.9224],
            [-102.6995, 18.0626],
            [-103.4416, 18.3254],
            [-103.9124, 18.8281],
            [-104.9386, 19.3093],
            [-105.6694, 20.3856],
            [-105.2369, 21.1195],
            [-105.2086, 21.4908],
            [-105.6492, 21.9878],
            [-105.7918, 22.6277],
            [-106.4024, 23.1959],
            [-106.9354, 23.881],
            [-107.9512, 24.6149],
            [-108.3735, 25.1941],
            [-109.3849, 25.7271],
            [-109.1997, 26.3054],
            [-109.9442, 27.0793],
            [-110.4779, 27.3228],
            [-110.5297, 27.8642],
            [-111.1214, 27.967],
            [-112.1619, 29.0186],
            [-113.1104, 30.7936],
            [-113.0467, 31.179],
            [-114.8397, 31.7986],
            [-114.8818, 31.1566],
            [-114.6498, 30.2381],
            [-113.7555, 29.3676],
            [-113.4998, 28.9268],
            [-112.8709, 28.4245],
            [-112.734, 27.8259],
            [-112.3291, 27.5232],
            [-112.0091, 26.9673],
            [-111.5697, 26.7075],
            [-111.2917, 25.79],
            [-110.6774, 24.7884],
            [-110.5471, 24.2141],
            [-110.0228, 24.1745],
            [-109.421, 23.4802],
            [-109.9235, 22.8861],
            [-110.3627, 23.605],
            [-112.0726, 24.8399],
            [-112.0557, 25.4881],
            [-112.3772, 26.2136],
            [-113.5986, 26.7212],
            [-114.4455, 27.2182],
            [-114.9937, 27.7359],
            [-114.3007, 27.873],
            [-114.0486, 28.4262],
            [-114.9937, 29.3847],
            [-115.6737, 29.7565],
            [-116.0622, 30.8041],
            [-116.6621, 31.5648],
            [-117.1283, 32.5334],
            [-114.7247, 32.7153],
            [-114.8359, 32.5083],
            [-111.0421, 31.3244],
            [-108.2145, 31.3293],
            [-108.2118, 31.7793],
            [-106.4534, 31.77],
            [-104.9179, 30.5835],
            [-104.4004, 29.5737],
            [-103.2577, 29.001],
            [-102.7343, 29.644],
            [-102.2689, 29.8712],
            [-101.4405, 29.7771],
            [-100.6587, 29.0683],
            [-100.2962, 28.3278],
            [-99.5054, 27.5482],
            [-99.4436, 27.0367],
            [-99.1079, 26.4469],
            [-97.3582, 25.8708],
            [-97.2251, 25.5852],
            [-97.6676, 24.3899],
            [-97.8579, 22.6246],
            [-97.6377, 21.6037],
            [-97.1213, 20.615],
            [-96.456, 19.8697],
            [-96.2895, 19.3436],
            [-95.821, 18.7548],
            [-95.1818, 18.7007],
            [-94.5462, 18.1751],
            [-93.5523, 18.4304],
            [-92.8848, 18.4687],
            [-91.9738, 18.7161],
            [-91.8031, 18.4704],
            [-90.7394, 19.352],
            [-90.5071, 19.9118],
            [-90.3531, 21.0096],
            [-89.8198, 21.2746],
            [-88.1317, 21.616],
            [-86.8241, 21.4214],
            [-86.8647, 20.8848],
            [-87.4672, 20.1021],
            [-87.4248, 19.5836],
            [-87.7619, 18.4463],
            [-88.2956, 18.4722]
          ]
        ]
      },
      properties: { name: "Mexico", id: "MX", Continent: "Central America" },
      id: "MX"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.1577, 14.993],
            [-83.1878, 14.34],
            [-83.5673, 13.3205],
            [-83.511, 12.4117],
            [-83.7692, 11.9314],
            [-83.8679, 11.2999],
            [-83.642, 10.9171],
            [-85.7443, 11.0621],
            [-86.4688, 11.7384],
            [-87.3374, 12.979],
            [-86.7107, 13.3134],
            [-86.7336, 13.7634],
            [-86.1513, 13.9946],
            [-85.7868, 13.8443],
            [-84.9852, 14.7527],
            [-84.1144, 14.7311],
            [-83.1577, 14.993]
          ]
        ]
      },
      properties: { name: "Nicaragua", id: "NI", Continent: "Central America" },
      id: "NI"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.8793, 8.0708],
            [-82.94, 9.4493],
            [-82.5636, 9.5768],
            [-82.2441, 9.0319],
            [-81.3549, 8.7805],
            [-80.1273, 9.2103],
            [-79.5775, 9.5979],
            [-78.0829, 9.2362],
            [-77.3741, 8.6583],
            [-77.2124, 8.0339],
            [-77.3508, 7.7056],
            [-78.1702, 7.5439],
            [-78.4215, 8.0607],
            [-78.1139, 8.3797],
            [-79.0864, 8.9971],
            [-79.4417, 9.0059],
            [-79.7506, 8.5955],
            [-80.3687, 8.2892],
            [-80.0752, 7.6669],
            [-80.4388, 7.2749],
            [-80.8455, 7.22],
            [-81.0352, 7.7109],
            [-81.5041, 7.7214],
            [-81.7277, 8.1376],
            [-82.6796, 8.3217],
            [-82.8793, 8.0708]
          ]
        ]
      },
      properties: { name: "Panama", id: "PA", Continent: "Central America" },
      id: "PA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.3626, 14.416],
            [-88.449, 13.8509],
            [-87.7153, 13.8127],
            [-87.8144, 13.3991],
            [-88.512, 13.1838],
            [-90.0952, 13.7366],
            [-89.3626, 14.416]
          ]
        ]
      },
      properties: { name: "El Salvador", id: "SV", Continent: "Central America" },
      id: "SV"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.7791, 19.7183],
            [-71.6571, 19.1306],
            [-71.7683, 18.0391],
            [-72.0599, 18.2286],
            [-72.8767, 18.1518],
            [-73.3851, 18.2513],
            [-73.885, 18.0418],
            [-74.419, 18.3463],
            [-74.2276, 18.6628],
            [-72.7894, 18.4346],
            [-72.3478, 18.6747],
            [-72.8112, 19.0716],
            [-72.7033, 19.4409],
            [-73.0527, 19.6109],
            [-73.1177, 19.9039],
            [-72.6369, 19.9008],
            [-71.7791, 19.7183]
          ]
        ]
      },
      properties: { name: "Haiti", id: "HT" },
      id: "HT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.7683, 18.0391],
            [-71.6571, 19.1306],
            [-71.7791, 19.7183],
            [-71.5578, 19.8954],
            [-70.9542, 19.9141],
            [-70.1294, 19.6362],
            [-69.7394, 19.2993],
            [-68.6848, 18.9047],
            [-68.3594, 18.538],
            [-68.6588, 18.222],
            [-68.9349, 18.408],
            [-69.7707, 18.4435],
            [-70.1831, 18.2517],
            [-71.0278, 18.273],
            [-71.3958, 17.6462],
            [-71.7683, 18.0391]
          ]
        ]
      },
      properties: { name: "Dominican Republic", id: "DO" },
      id: "DO"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-93.378, 48.6165],
              [-92.9962, 48.6118],
              [-91.5183, 48.0582],
              [-90.8401, 48.2007],
              [-90.7443, 48.1045],
              [-90.0918, 48.1183],
              [-89.4554, 47.9959],
              [-88.3783, 48.3031],
              [-87.2078, 47.8485],
              [-84.876, 46.8999],
              [-84.5618, 46.4574],
              [-84.1923, 46.5491],
              [-83.9778, 46.0852],
              [-83.6159, 46.1171],
              [-83.5926, 45.8168],
              [-82.5509, 45.3472],
              [-82.1378, 43.5705],
              [-82.408, 43.0727],
              [-82.5453, 42.6246],
              [-83.0732, 42.3008],
              [-83.1421, 41.9759],
              [-82.6901, 41.6748],
              [-82.439, 41.6748],
              [-81.2778, 42.2088],
              [-80.2474, 42.3659],
              [-79.0369, 42.8025],
              [-79.172, 43.4665],
              [-78.7206, 43.6252],
              [-76.8199, 43.6289],
              [-76.4646, 44.0575],
              [-75.1794, 44.8991],
              [-74.7087, 45.0038],
              [-72.7659, 45.006],
              [-71.5175, 45.0077],
              [-71.3271, 45.2902],
              [-70.8982, 45.2625],
              [-70.2963, 45.9059],
              [-70.2484, 46.2511],
              [-70.0076, 46.7089],
              [-69.243, 47.4627],
              [-68.8289, 47.2032],
              [-68.3109, 47.3546],
              [-67.8067, 47.0831],
              [-67.7845, 45.7017],
              [-67.4329, 45.6028],
              [-67.4725, 45.2756],
              [-67.1248, 45.1693],
              [-67.1065, 44.8855],
              [-67.907, 44.4537],
              [-68.2774, 44.5076],
              [-68.612, 44.3104],
              [-68.9615, 44.434],
              [-69.0682, 44.0976],
              [-69.7917, 43.8054],
              [-70.1789, 43.7661],
              [-70.5208, 43.3486],
              [-70.8291, 42.8251],
              [-70.7357, 42.6692],
              [-71.0462, 42.3312],
              [-70.7382, 42.2292],
              [-70.4046, 41.6271],
              [-70.6572, 41.5344],
              [-71.4264, 41.6333],
              [-71.5227, 41.3791],
              [-72.8471, 41.2656],
              [-73.5831, 41.0219],
              [-74.2267, 40.608],
              [-73.9576, 40.3285],
              [-74.1761, 39.7269],
              [-74.4289, 39.3869],
              [-74.8973, 39.1455],
              [-75.3532, 39.3399],
              [-75.3922, 39.0931],
              [-75.0361, 38.5034],
              [-75.5966, 37.6313],
              [-75.6592, 37.9539],
              [-76.2117, 38.3613],
              [-76.1683, 38.8525],
              [-76.5193, 38.8984],
              [-76.5012, 38.5326],
              [-76.2641, 37.8934],
              [-76.2635, 37.3565],
              [-76.4011, 37.2128],
              [-75.9993, 36.9126],
              [-75.8905, 36.6566],
              [-76.1481, 36.2796],
              [-76.479, 36.0284],
              [-75.7723, 35.9001],
              [-75.7741, 35.6471],
              [-76.1738, 35.3535],
              [-76.5129, 35.2706],
              [-76.7451, 34.9414],
              [-76.7332, 34.707],
              [-77.1339, 34.7082],
              [-77.7508, 34.285],
              [-78.0134, 33.912],
              [-78.5777, 33.8731],
              [-78.9203, 33.6587],
              [-79.276, 33.1355],
              [-80.0218, 32.62],
              [-80.2297, 32.5762],
              [-80.8491, 32.1142],
              [-81.1976, 31.7041],
              [-81.1754, 31.531],
              [-81.5203, 30.8748],
              [-81.2495, 29.7938],
              [-80.8999, 29.0501],
              [-80.5242, 28.486],
              [-80.65, 28.1808],
              [-80.2262, 27.2072],
              [-80.1259, 27.0828],
              [-80.0414, 26.5685],
              [-80.1265, 25.8336],
              [-80.3669, 25.3312],
              [-80.7366, 25.1564],
              [-81.0977, 25.3193],
              [-81.365, 25.8313],
              [-81.7154, 25.9829],
              [-81.8666, 26.4352],
              [-82.4415, 27.0597],
              [-82.6609, 27.7188],
              [-82.8437, 27.8463],
              [-82.6607, 28.4854],
              [-82.6517, 28.8872],
              [-83.2908, 29.4521],
              [-83.6942, 29.9262],
              [-84.0442, 30.1037],
              [-84.3829, 29.9077],
              [-85.3189, 29.6799],
              [-85.3537, 29.8759],
              [-85.7557, 30.1668],
              [-86.1754, 30.3328],
              [-86.2574, 30.493],
              [-86.986, 30.4307],
              [-87.7904, 30.2917],
              [-88.1354, 30.3663],
              [-88.9051, 30.4152],
              [-89.3207, 30.3455],
              [-89.5886, 30.1654],
              [-90.2253, 30.379],
              [-90.413, 30.1407],
              [-89.5895, 29.9151],
              [-89.7209, 29.6194],
              [-89.5138, 29.42],
              [-89.0155, 29.2028],
              [-89.3536, 29.0698],
              [-89.8773, 29.4578],
              [-90.1015, 29.1817],
              [-90.3793, 29.2954],
              [-90.7511, 29.1313],
              [-91.2902, 29.2888],
              [-91.3312, 29.514],
              [-91.8932, 29.8362],
              [-92.2608, 29.5567],
              [-92.6715, 29.5968],
              [-93.1759, 29.7792],
              [-93.6947, 29.7696],
              [-94.0998, 29.6702],
              [-94.8884, 29.3704],
              [-95.2735, 28.9635],
              [-96.0206, 28.5866],
              [-96.4485, 28.5946],
              [-96.5617, 28.3667],
              [-97.1565, 28.1442],
              [-97.1715, 27.8794],
              [-97.3805, 27.4196],
              [-97.524, 27.3141],
              [-97.5546, 26.9676],
              [-97.4023, 26.3968],
              [-97.1461, 25.9617],
              [-97.3582, 25.8706],
              [-97.8014, 26.0423],
              [-98.0828, 26.0641],
              [-99.1079, 26.4472],
              [-99.4435, 27.0369],
              [-99.5054, 27.5483],
              [-99.7544, 27.7303],
              [-100.2961, 28.3279],
              [-100.6587, 29.0681],
              [-101.4404, 29.777],
              [-102.2689, 29.8709],
              [-102.7342, 29.6441],
              [-102.892, 29.2164],
              [-103.2577, 29.0011],
              [-104.1107, 29.3863],
              [-104.5039, 29.6772],
              [-104.6812, 30.1346],
              [-104.9789, 30.6462],
              [-105.276, 30.8072],
              [-106.2558, 31.5448],
              [-106.4533, 31.7699],
              [-108.2119, 31.7792],
              [-108.2146, 31.3295],
              [-109.9814, 31.3262],
              [-111.0421, 31.3245],
              [-112.4648, 31.7681],
              [-114.8359, 32.5084],
              [-114.7246, 32.7154],
              [-117.1283, 32.5335],
              [-117.3187, 33.0998],
              [-117.4675, 33.2957],
              [-118.0806, 33.7222],
              [-118.4105, 33.7442],
              [-118.5063, 34.0175],
              [-119.1437, 34.1119],
              [-119.606, 34.4179],
              [-120.4811, 34.4713],
              [-120.6449, 34.5799],
              [-120.6336, 35.0763],
              [-120.8605, 35.3654],
              [-121.8774, 36.3313],
              [-121.79, 36.7325],
              [-121.8807, 36.9395],
              [-122.1644, 36.9911],
              [-122.3948, 37.2079],
              [-122.4669, 37.8381],
              [-122.9679, 38.0968],
              [-122.9864, 38.2769],
              [-123.7013, 38.9072],
              [-123.8845, 39.8607],
              [-124.3238, 40.2516],
              [-124.3715, 40.491],
              [-124.133, 40.9695],
              [-124.0685, 41.3842],
              [-124.3554, 42.1229],
              [-124.4062, 42.5837],
              [-124.5398, 42.8128],
              [-124.1486, 43.6914],
              [-124.0593, 44.7778],
              [-123.9292, 45.5767],
              [-123.9471, 46.1403],
              [-124.0728, 46.2793],
              [-123.8891, 46.6599],
              [-124.0716, 46.7447],
              [-124.3762, 47.6587],
              [-124.6212, 47.9042],
              [-124.6326, 48.3751],
              [-123.9756, 48.168],
              [-122.8611, 48.0901],
              [-122.6567, 47.8812],
              [-122.242, 48.0105],
              [-122.7887, 48.993],
              [-119.7019, 48.993],
              [-117.1433, 48.993],
              [-115.0116, 48.993],
              [-112.0267, 48.993],
              [-109.4685, 48.993],
              [-107.763, 48.993],
              [-106.0577, 48.993],
              [-103.4991, 48.993],
              [-101.3672, 48.993],
              [-98.3829, 48.993],
              [-95.1621, 48.9918],
              [-95.1554, 49.3695],
              [-94.8545, 49.3048],
              [-94.6209, 48.7424],
              [-93.7079, 48.5256],
              [-93.378, 48.6165]
            ]
          ]
        ]
      },
      properties: { name: "United States", id: "US", Continent: "North America" },
      id: "US"
    }
  ]
};
export default map;
