import Tippy from "@tippyjs/react";
import React from "react";

import infoIcon from "../../../images/infoIcon.svg";
import infoIconRedBg from "../../../images/infoIconRedBg.svg";
import infoIconGrayBg from "../../../images/infoIconGrayBg.svg";

import infoIconYellowBg from "../../../images/infoIconYellowBg.svg";

export default function HelpCell({ mainText, helpText, bg, className = "" }) {
  return (
    <div className={className}>
      {mainText}{" "}
      {helpText && helpText.trim() && (
        <Tippy
          className="helpTippy"
          content={helpText}
          distance={"0"}
          allowHTML={true}
          followCursor={"initial"}
          theme={"ilga-help"}
          placement={"top"}
          popperOptions={{ modifiers: [{ name: "flip", enabled: false }] }}
        >
          {!bg || bg === "rowProtectionYes" ? (
            <img src={infoIcon} alt="Read more" className="helpIcon" />
          ) : bg === "rowProtectionNo" ? (
            <img src={infoIconRedBg} alt="Read more" className="helpIcon" />
          ) : bg === "rowProtectionUnclear" ? (
            <img src={infoIconGrayBg} alt="Read more" className="helpIcon" />
          ) : (
            <img src={infoIconYellowBg} alt="Read more" className="helpIcon" />
          )}
        </Tippy>
      )}
    </div>
  );
}
