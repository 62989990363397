import React from 'react'
import MainLayout from '../../layouts/MainLayout'

import { Link } from 'react-router-dom'
import BookIcon from '../components/icons/BookIcon'
import TreatiesIcon from '../components/icons/TreatiesIcon'
import TreatyBodiesIcon from '../components/icons/TreatyBodiesIcon'
import BubblesIcon from '../components/icons/BubblesIcon'

import './Tools.css'
import UPRIcon from '../components/icons/UPRIcon'
import MagGlass from '../components/icons/MagGlass'
import SettingsIcon from '../components/icons/SettingsIcon'
import ToolsIcon from '../components/icons/ToolsIcon'
import StatsGeneratorIcon from '../components/icons/StatsGeneratorIcon'
import MapIcon from '../components/icons/MapIcon'
import BriefcaseIcon from '../components/icons/BriefcaseIcon'
import { useTranslation } from 'react-i18next'

const Tools = () => {
  const { t } = useTranslation()
  return (
    <MainLayout section="tools">
      <div className="area__page areaHome__page section__tools tools__page">
        <div className="content__container">
          <Link to="/advanced-search" className="areaLinkBox">
            <div className="areaLink__header">
              <h6>
                {t('Advanced Search')}
                <br />
                <em
                  style={{
                    marginTop: '14px',
                    fontWeight: 'normal',
                    fontSize: '14px'
                  }}
                >
                  <br />
                </em>
              </h6>
              <p className="areaLink__description">
                {t(
                  'If you are interested in searching across the whole database by topic, legal category, date, type of document or any other criterion, the Advanced Search will quickly retrieve the content of your interest.'
                )}
              </p>
            </div>
            <div className="areaLink__body">
              <div className="iconContainer">
                <MagGlass size={72} color="rgb(201, 216, 217)" />
              </div>
            </div>
          </Link>
          <Link to="/compare-tool" className="areaLinkBox">
            <div className="areaLink__header">
              <h6>
                {t('Compare Tool')}
                <br />
                <em
                  style={{
                    marginTop: '14px',
                    fontWeight: 'normal',
                    fontSize: '14px'
                  }}
                />
                <br />
              </h6>
              <p className="areaLink__description">
                {t(
                  'If you are interested in comparing two or more countries on how they are faring in any given legal category under Area 1, the compare tool will bring that specific data side-by-side for you.'
                )}
              </p>
            </div>
            <div className="areaLink__body">
              <div className="iconContainer">
                <ToolsIcon size={72} color="#D8D5E3" />
              </div>
            </div>
          </Link>

          <Link to="#" className="areaLinkBox">
            <div className="areaLink__header">
              <h6>
                {t('Map Generator')}
                <br />
                <em
                  style={{
                    marginTop: '14px',
                    fontWeight: 'normal',
                    fontSize: '14px'
                  }}
                >
                  ({t('forthcoming')})
                </em>
              </h6>
              <p className="areaLink__description">
                {t(
                  'If you are interested in creating your own ILGA maps for your advocacy, research or dissemination work, the Map Generator will allow you to produce maps specifically tailored to your needs.'
                )}
              </p>
            </div>
            <div className="areaLink__body">
              <div className="iconContainer">
                <MapIcon size={72} color="rgb(201, 216, 217)" />
              </div>
            </div>
          </Link>
          {/*  Disabled for now
        <Link to='/briefing-not-generator' className="areaLinkBox">
          <div className="areaLink__header">
            <h6>Briefing Note  <br/>Generator</h6>
            <p className="areaLink__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id
              lacinia turpis. Sed sit amet auctor diam. Class aptent taciti sociosqu ad litora torquent per.</p>
          </div>
          <div className="areaLink__body">
            <div className="iconContainer">
              <BriefcaseIcon size={72} color='rgb(201, 216, 217)' />
            </div>
          </div>
        </Link>
        */}
        </div>
      </div>
    </MainLayout>
  )
}

export default Tools
