var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [92.7009, 12.2356],
              [92.7499, 12.1727],
              [92.719, 11.9616],
              [92.7885, 11.9012],
              [92.7557, 11.7024],
              [92.6179, 11.5628],
              [92.5463, 11.8352],
              [92.611, 11.8713],
              [92.6236, 12.1028],
              [92.7009, 12.2356]
            ]
          ],
          [
            [
              [92.5158, 10.8976],
              [92.5877, 10.787],
              [92.5968, 10.6783],
              [92.513, 10.5117],
              [92.4347, 10.5483],
              [92.3803, 10.6675],
              [92.3975, 10.7605],
              [92.5158, 10.8976]
            ]
          ],
          [
            [
              [92.7766, 12.3103],
              [92.8466, 12.1977],
              [92.7391, 12.0948],
              [92.7766, 12.3103]
            ]
          ],
          [
            [
              [93.713, 7.4436],
              [93.7558, 7.3598],
              [93.6484, 7.2425],
              [93.6325, 7.3706],
              [93.713, 7.4436]
            ]
          ],
          [
            [
              [93.38, 8.0247],
              [93.465, 7.93],
              [93.3564, 7.8889],
              [93.3267, 8.0025],
              [93.38, 8.0247]
            ]
          ],
          [
            [
              [92.843, 12.8763],
              [92.873, 12.8833],
              [92.9219, 12.9123],
              [92.9805, 12.7097],
              [92.9684, 12.4967],
              [92.8948, 12.3201],
              [92.6995, 12.3285],
              [92.7075, 12.5969],
              [92.7533, 12.6708],
              [92.7378, 12.8103],
              [92.843, 12.8763]
            ]
          ],
          [
            [
              [92.873, 12.8833],
              [92.843, 12.8763],
              [92.7884, 13.0183],
              [92.8394, 13.3748],
              [92.8717, 13.4714],
              [92.9889, 13.5278],
              [93.0906, 13.3359],
              [93.0322, 13.1789],
              [93.0349, 13.081],
              [92.9347, 13.061],
              [92.8544, 12.9275],
              [92.873, 12.8833]
            ]
          ],
          [
            [
              [93.8481, 7.2403],
              [93.8878, 7.0864],
              [93.9478, 6.9989],
              [93.8972, 6.9022],
              [93.9021, 6.813],
              [93.7866, 6.831],
              [93.7453, 6.9619],
              [93.663, 7.0067],
              [93.6861, 7.19],
              [93.8481, 7.2403]
            ]
          ],
          [
            [
              [92.7878, 9.2441],
              [92.8306, 9.1387],
              [92.7317, 9.1244],
              [92.7208, 9.2136],
              [92.7878, 9.2441]
            ]
          ]
        ]
      },
      properties: {
        name: 'Andaman and Nicobar Islands',
        id: 'IN-AN',
        TYPE: 'Union Territory',
        CNTRY: 'India'
      },
      id: 'IN-AN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [82.2995, 16.7143],
            [82.2576, 16.6901],
            [82.3099, 16.5677],
            [81.9446, 16.3951],
            [81.7204, 16.3101],
            [81.541, 16.359],
            [81.3926, 16.3421],
            [81.2651, 16.281],
            [81.141, 16.0182],
            [81.1457, 15.9737],
            [81.0068, 15.8315],
            [80.9668, 15.7318],
            [80.8221, 15.7129],
            [80.8054, 15.8426],
            [80.6868, 15.8826],
            [80.5374, 15.8548],
            [80.3396, 15.7474],
            [80.2699, 15.6721],
            [80.2007, 15.4654],
            [80.0993, 15.3096],
            [80.0479, 15.0774],
            [80.1146, 14.7313],
            [80.1988, 14.5876],
            [80.1296, 14.1215],
            [80.2446, 13.8196],
            [80.171, 13.6896],
            [80.051, 13.5882],
            [80.1615, 13.4896],
            [80.2365, 13.4879],
            [80.312, 13.4224],
            [80.1521, 13.4789],
            [80.0694, 13.5376],
            [79.9618, 13.4513],
            [79.9255, 13.3359],
            [79.5803, 13.2454],
            [79.5358, 13.3111],
            [79.4289, 13.3236],
            [79.4108, 13.2121],
            [79.3079, 13.0969],
            [79.2183, 13.1356],
            [79.1527, 13.0073],
            [79.0691, 13.0141],
            [78.9796, 13.0765],
            [78.876, 13.0862],
            [78.7024, 13.0562],
            [78.6137, 12.9789],
            [78.582, 12.7708],
            [78.3683, 12.6118],
            [78.2904, 12.6529],
            [78.2207, 12.7526],
            [78.2411, 12.8474],
            [78.3454, 12.9281],
            [78.4358, 12.9007],
            [78.4516, 13.0315],
            [78.5691, 13.1565],
            [78.5775, 13.262],
            [78.3552, 13.3551],
            [78.3908, 13.5781],
            [78.2277, 13.5872],
            [78.0641, 13.6882],
            [78.1135, 13.8409],
            [77.9664, 13.9506],
            [77.8183, 13.9349],
            [77.7785, 13.8129],
            [77.6491, 13.7759],
            [77.5123, 13.6857],
            [77.4245, 13.8351],
            [77.1788, 13.8598],
            [77.1666, 13.7547],
            [76.9926, 13.7426],
            [76.9684, 13.8144],
            [77.0358, 13.9256],
            [76.9311, 14.0227],
            [76.927, 14.1272],
            [77.0198, 14.1727],
            [77.0237, 14.0533],
            [77.1473, 13.9916],
            [77.3262, 14.023],
            [77.3954, 14.1035],
            [77.5004, 14.151],
            [77.4963, 14.2718],
            [77.4423, 14.3097],
            [77.2796, 14.2761],
            [77.1521, 14.3378],
            [77.0496, 14.2396],
            [76.8791, 14.3464],
            [76.8702, 14.4685],
            [76.7602, 14.602],
            [76.8488, 14.804],
            [76.8644, 14.9641],
            [76.7638, 14.9699],
            [76.7969, 15.0908],
            [76.8767, 15.0287],
            [77.0788, 15],
            [77.154, 15.1205],
            [77.1642, 15.2591],
            [77.0616, 15.3218],
            [77.0274, 15.4352],
            [77.0293, 15.6338],
            [77.066, 15.8993],
            [77.2489, 15.9578],
            [77.5095, 15.9208],
            [77.6589, 15.8765],
            [77.7992, 15.8653],
            [77.8877, 15.8959],
            [78.1096, 15.8271],
            [78.238, 15.9291],
            [78.2567, 16.0313],
            [78.4057, 16.081],
            [78.5591, 16.0564],
            [78.617, 16.0989],
            [78.775, 16.0236],
            [78.9054, 16.1819],
            [79.0309, 16.2457],
            [79.2187, 16.2396],
            [79.2253, 16.5082],
            [79.2661, 16.5615],
            [79.4266, 16.5825],
            [79.4972, 16.6305],
            [79.648, 16.662],
            [79.7749, 16.7295],
            [79.9581, 16.642],
            [80.0697, 16.8136],
            [79.9917, 16.8632],
            [80.0459, 16.9653],
            [80.2622, 17.0106],
            [80.3192, 16.871],
            [80.4564, 16.79],
            [80.5628, 16.7628],
            [80.5842, 16.9265],
            [80.4878, 16.9175],
            [80.3582, 16.9707],
            [80.4822, 17.0503],
            [80.5602, 17.138],
            [80.8325, 17.0257],
            [80.9054, 17.2013],
            [80.9912, 17.1797],
            [81.1628, 17.2326],
            [81.1815, 17.3222],
            [81.2672, 17.32],
            [81.3247, 17.3906],
            [81.4157, 17.3616],
            [81.4934, 17.4488],
            [81.5029, 17.5899],
            [81.5766, 17.7261],
            [81.7824, 17.8319],
            [81.7583, 17.8934],
            [81.9518, 17.9921],
            [82.0247, 18.0579],
            [82.1604, 18.0436],
            [82.2673, 17.9864],
            [82.3298, 18.0404],
            [82.3344, 18.3172],
            [82.3613, 18.4099],
            [82.474, 18.5369],
            [82.5231, 18.5039],
            [82.5352, 18.3896],
            [82.6005, 18.3687],
            [82.5807, 18.2701],
            [82.7671, 18.3311],
            [82.7992, 18.4394],
            [82.9327, 18.3514],
            [83.0172, 18.3845],
            [83.051, 18.6542],
            [83.133, 18.7724],
            [83.2017, 18.7422],
            [83.3563, 18.8001],
            [83.4113, 18.8593],
            [83.3053, 18.9897],
            [83.478, 19.0212],
            [83.6044, 19.0882],
            [83.697, 19.0165],
            [83.7871, 19.0084],
            [83.8702, 18.8184],
            [84.1514, 18.7757],
            [84.3322, 18.792],
            [84.336, 18.8667],
            [84.4294, 18.9137],
            [84.4894, 19.0209],
            [84.5918, 19.1239],
            [84.761, 19.0713],
            [84.5829, 18.8763],
            [84.554, 18.7926],
            [84.466, 18.7132],
            [84.3563, 18.5568],
            [84.1257, 18.3516],
            [84.1246, 18.3093],
            [83.7193, 18.1138],
            [83.5726, 18.0182],
            [83.4102, 17.8437],
            [83.3446, 17.7252],
            [83.2135, 17.5909],
            [82.6076, 17.2963],
            [82.3685, 17.1188],
            [82.2976, 17.0329],
            [82.2562, 16.8865],
            [82.3118, 16.8401],
            [82.2995, 16.7143]
          ]
        ]
      },
      properties: {
        name: 'Andhra Pradesh',
        id: 'IN-AP',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-AP'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [95.2376, 26.6863],
            [95.2086, 26.8507],
            [95.2338, 26.8908],
            [95.1955, 27.0429],
            [95.4578, 27.1356],
            [95.5162, 27.2686],
            [95.5901, 27.2295],
            [95.8063, 27.2918],
            [95.9064, 27.2919],
            [96.0184, 27.3679],
            [95.7871, 27.6234],
            [95.7582, 27.7311],
            [95.9273, 27.8873],
            [95.9775, 27.9685],
            [95.8259, 27.9774],
            [95.6067, 27.9578],
            [95.516, 27.8818],
            [95.3844, 27.842],
            [95.3165, 27.8711],
            [95.0312, 27.7748],
            [94.4598, 27.5577],
            [94.2523, 27.6409],
            [94.2591, 27.5226],
            [94.1572, 27.4781],
            [94.0609, 27.3636],
            [93.9565, 27.2948],
            [93.7983, 27.1372],
            [93.6758, 26.9711],
            [93.4894, 26.9385],
            [93.3479, 26.9632],
            [93.0194, 26.9168],
            [92.8724, 27.0077],
            [92.6589, 27.0385],
            [92.5852, 26.9619],
            [92.4596, 26.9633],
            [92.2764, 26.9025],
            [92.1079, 26.8938],
            [92.1033, 27.0077],
            [92.0338, 27.0648],
            [92.0234, 27.1623],
            [92.0545, 27.4001],
            [92.0187, 27.4797],
            [91.6474, 27.4847],
            [91.5577, 27.6335],
            [91.6237, 27.706],
            [91.6282, 27.8009],
            [91.8242, 27.8104],
            [91.9187, 27.7151],
            [92.0554, 27.7591],
            [92.2104, 27.859],
            [92.316, 27.7782],
            [92.5013, 27.8415],
            [92.5607, 27.821],
            [92.7395, 27.9894],
            [92.6629, 28.0628],
            [92.687, 28.1258],
            [92.7996, 28.2022],
            [92.8953, 28.2046],
            [93.0237, 28.3032],
            [93.219, 28.3337],
            [93.1826, 28.44],
            [93.2759, 28.5048],
            [93.3369, 28.6402],
            [93.4911, 28.684],
            [93.6201, 28.6577],
            [93.8441, 28.7074],
            [93.9261, 28.6718],
            [94.0014, 28.7755],
            [93.9949, 28.8414],
            [94.1551, 28.9548],
            [94.2147, 29.0841],
            [94.3569, 29.1526],
            [94.4312, 29.2268],
            [94.5579, 29.2289],
            [94.6282, 29.3486],
            [94.8718, 29.1843],
            [95.0228, 29.1306],
            [95.2507, 29.1099],
            [95.2665, 29.0577],
            [95.4101, 29.0341],
            [95.483, 29.0711],
            [95.5377, 29.214],
            [95.5858, 29.218],
            [95.7776, 29.3564],
            [95.9571, 29.3827],
            [96.0806, 29.4633],
            [96.1858, 29.3445],
            [96.2602, 29.1602],
            [96.1504, 29.0531],
            [96.2483, 28.9428],
            [96.3467, 29.0323],
            [96.4358, 29.0376],
            [96.5188, 28.971],
            [96.616, 28.7786],
            [96.5859, 28.7216],
            [96.4066, 28.564],
            [96.3709, 28.3943],
            [96.5796, 28.4631],
            [96.6646, 28.4658],
            [96.9965, 28.3162],
            [97.0926, 28.3697],
            [97.1739, 28.2991],
            [97.2821, 28.2763],
            [97.3636, 28.1965],
            [97.339, 28.1195],
            [97.4152, 28.0241],
            [97.389, 27.8964],
            [97.2565, 27.9095],
            [97.0286, 27.7491],
            [96.8976, 27.6208],
            [96.9118, 27.4627],
            [97.1717, 27.1368],
            [97.0715, 27.1092],
            [96.89, 27.2369],
            [96.8024, 27.3545],
            [96.6125, 27.3694],
            [96.4707, 27.3125],
            [96.2343, 27.2849],
            [96.0364, 27.196],
            [95.9362, 27.053],
            [95.8079, 27.0205],
            [95.7308, 26.8875],
            [95.6115, 26.8212],
            [95.5328, 26.8312],
            [95.4288, 26.7],
            [95.3026, 26.656],
            [95.2376, 26.6863]
          ]
        ]
      },
      properties: {
        name: 'Arunachal Pradesh',
        id: 'IN-AR',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-AR'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [92.1079, 26.8938],
            [92.2764, 26.9025],
            [92.4596, 26.9633],
            [92.5852, 26.9619],
            [92.6589, 27.0385],
            [92.8724, 27.0077],
            [93.0194, 26.9168],
            [93.3479, 26.9632],
            [93.4894, 26.9385],
            [93.6758, 26.9711],
            [93.7983, 27.1372],
            [93.9565, 27.2948],
            [94.0609, 27.3636],
            [94.1572, 27.4781],
            [94.2591, 27.5226],
            [94.2523, 27.6409],
            [94.4598, 27.5577],
            [95.0312, 27.7748],
            [95.3165, 27.8711],
            [95.3844, 27.842],
            [95.516, 27.8818],
            [95.6067, 27.9578],
            [95.8259, 27.9774],
            [95.9775, 27.9685],
            [95.9273, 27.8873],
            [95.7582, 27.7311],
            [95.7871, 27.6234],
            [96.0184, 27.3679],
            [95.9064, 27.2919],
            [95.8063, 27.2918],
            [95.5901, 27.2295],
            [95.5162, 27.2686],
            [95.4578, 27.1356],
            [95.1955, 27.0429],
            [95.1101, 26.9669],
            [94.9857, 26.9182],
            [94.8862, 26.9325],
            [94.8022, 26.8061],
            [94.6866, 26.7326],
            [94.5451, 26.7109],
            [94.4079, 26.6143],
            [94.3994, 26.5323],
            [94.1865, 26.4605],
            [94.1755, 26.3442],
            [94.108, 26.3264],
            [94.0048, 26.1733],
            [93.9549, 26.0073],
            [93.9794, 25.9197],
            [93.8827, 25.8459],
            [93.7518, 25.9364],
            [93.7055, 25.8486],
            [93.5733, 25.7608],
            [93.4999, 25.6565],
            [93.3318, 25.5468],
            [93.4619, 25.4347],
            [93.474, 25.3093],
            [93.3887, 25.2459],
            [93.3358, 25.0958],
            [93.2488, 25.0188],
            [93.2624, 24.9513],
            [93.1047, 24.6754],
            [93.0005, 24.4032],
            [92.829, 24.3778],
            [92.7652, 24.5209],
            [92.6907, 24.3548],
            [92.5315, 24.1774],
            [92.4366, 24.1524],
            [92.4224, 24.2531],
            [92.2966, 24.2517],
            [92.231, 24.5041],
            [92.2509, 24.6571],
            [92.2983, 24.7365],
            [92.2405, 24.8992],
            [92.2984, 24.92],
            [92.3915, 24.8499],
            [92.5047, 24.9044],
            [92.4259, 25.0318],
            [92.5246, 25.14],
            [92.6217, 25.1177],
            [92.6669, 25.1771],
            [92.8044, 25.2193],
            [92.7606, 25.3353],
            [92.5982, 25.4717],
            [92.6447, 25.5374],
            [92.5017, 25.6229],
            [92.4332, 25.7314],
            [92.229, 25.7162],
            [92.1607, 25.6719],
            [92.1563, 25.9448],
            [92.2412, 26.0626],
            [91.9911, 26.041],
            [91.9221, 25.9995],
            [91.8193, 26.1187],
            [91.7312, 26.0592],
            [91.7202, 25.9541],
            [91.5758, 26.0331],
            [91.4444, 25.841],
            [91.3335, 25.8388],
            [91.2756, 25.7469],
            [91.192, 25.7296],
            [91.1965, 25.859],
            [91.0633, 25.8204],
            [90.9427, 25.9476],
            [90.7474, 25.9573],
            [90.629, 25.9374],
            [90.4773, 26.0153],
            [90.1825, 25.9445],
            [90.1187, 25.9613],
            [89.9446, 25.8245],
            [89.8926, 25.735],
            [90.0173, 25.6022],
            [89.8701, 25.5442],
            [89.8852, 25.6298],
            [89.8145, 25.817],
            [89.8904, 25.9402],
            [89.7188, 26.166],
            [89.7505, 26.3215],
            [89.8573, 26.3845],
            [89.857, 26.7038],
            [90.1461, 26.7549],
            [90.2148, 26.8537],
            [90.3015, 26.8482],
            [90.4097, 26.9019],
            [90.5599, 26.801],
            [90.7123, 26.7676],
            [91.054, 26.7795],
            [91.0966, 26.8198],
            [91.3363, 26.7797],
            [91.4084, 26.8356],
            [91.4928, 26.7898],
            [91.6873, 26.8051],
            [91.8972, 26.9161],
            [92.0455, 26.8475],
            [92.1079, 26.8938]
          ]
        ]
      },
      properties: { name: 'Assam', id: 'IN-AS', TYPE: 'State', CNTRY: 'India' },
      id: 'IN-AS'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [88.1061, 26.5356],
            [88.2262, 26.4762],
            [88.2919, 26.3539],
            [88.1445, 26.2528],
            [87.9681, 26.0813],
            [87.8421, 26.0445],
            [87.8228, 25.8715],
            [87.9109, 25.8518],
            [87.8985, 25.7698],
            [88.0486, 25.6906],
            [88.021, 25.587],
            [87.7591, 25.4051],
            [87.8565, 25.2778],
            [87.7876, 25.2205],
            [87.6841, 25.3101],
            [87.5474, 25.3309],
            [87.4727, 25.2409],
            [87.3235, 25.2231],
            [87.2911, 25.0893],
            [87.1468, 25.0243],
            [87.1581, 24.8856],
            [87.0966, 24.8015],
            [87.0505, 24.6068],
            [86.9301, 24.6429],
            [86.8546, 24.5505],
            [86.7771, 24.6198],
            [86.6684, 24.5606],
            [86.6029, 24.6051],
            [86.5057, 24.5177],
            [86.4522, 24.3686],
            [86.3507, 24.4437],
            [86.304, 24.5583],
            [86.2174, 24.6118],
            [86.1333, 24.6035],
            [86.1244, 24.7052],
            [86.0506, 24.7796],
            [85.9503, 24.7321],
            [85.8778, 24.8003],
            [85.7368, 24.8227],
            [85.5765, 24.603],
            [85.4865, 24.5507],
            [85.2825, 24.5267],
            [85.0533, 24.4151],
            [84.898, 24.3721],
            [84.8802, 24.4628],
            [84.7953, 24.5295],
            [84.4545, 24.3386],
            [84.3359, 24.3958],
            [84.3264, 24.5028],
            [84.1883, 24.5553],
            [84.1098, 24.4814],
            [84.0467, 24.6123],
            [83.9285, 24.5479],
            [83.7167, 24.5049],
            [83.4982, 24.5271],
            [83.5414, 24.6262],
            [83.3868, 24.7842],
            [83.354, 24.8706],
            [83.3574, 25.1242],
            [83.3353, 25.1814],
            [83.5395, 25.3177],
            [83.6224, 25.3295],
            [83.7325, 25.4106],
            [83.7994, 25.411],
            [83.917, 25.5585],
            [84.0765, 25.6375],
            [84.3186, 25.672],
            [84.38, 25.7487],
            [84.4884, 25.6779],
            [84.6202, 25.7941],
            [84.5286, 25.8777],
            [84.4233, 25.8924],
            [84.1673, 26.0255],
            [84.0182, 26.1451],
            [84.1593, 26.2459],
            [84.1714, 26.374],
            [84.072, 26.3845],
            [83.9022, 26.5181],
            [84.0478, 26.5435],
            [84.0818, 26.6428],
            [84.2722, 26.5994],
            [84.3257, 26.6843],
            [84.2523, 26.8087],
            [84.052, 26.8917],
            [84.0408, 27.0447],
            [83.9394, 27.093],
            [83.9788, 27.2004],
            [83.898, 27.2594],
            [83.8585, 27.351],
            [83.8703, 27.4285],
            [84.0294, 27.4336],
            [84.0975, 27.4894],
            [84.2563, 27.4519],
            [84.2959, 27.3842],
            [84.622, 27.3382],
            [84.693, 27.2148],
            [84.6449, 27.0473],
            [84.8319, 27.0193],
            [84.9698, 26.916],
            [85.1977, 26.8666],
            [85.2123, 26.7587],
            [85.3363, 26.7414],
            [85.6367, 26.8719],
            [85.7367, 26.7956],
            [85.7346, 26.6543],
            [85.8521, 26.5684],
            [86.0307, 26.6664],
            [86.1945, 26.6144],
            [86.3293, 26.6193],
            [86.5442, 26.5381],
            [86.7335, 26.422],
            [86.8358, 26.4387],
            [87.0177, 26.5318],
            [87.165, 26.4038],
            [87.3713, 26.4078],
            [87.4691, 26.4401],
            [87.6084, 26.3806],
            [87.7922, 26.4689],
            [87.9941, 26.3922],
            [88.0974, 26.4375],
            [88.1061, 26.5356]
          ]
        ]
      },
      properties: { name: 'Bihar', id: 'IN-BR', TYPE: 'State', CNTRY: 'India' },
      id: 'IN-BR'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [76.8198, 30.6858],
            [76.7263, 30.6959],
            [76.6906, 30.7601],
            [76.8282, 30.7638],
            [76.8198, 30.6858]
          ]
        ]
      },
      properties: {
        name: 'Chandigarh',
        id: 'IN-CH',
        TYPE: 'Union Territory',
        CNTRY: 'India'
      },
      id: 'IN-CH'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [83.3235, 24.1014],
            [83.5067, 24.0282],
            [83.5612, 23.8629],
            [83.6954, 23.8206],
            [83.7147, 23.6825],
            [83.7749, 23.5995],
            [84.0227, 23.5442],
            [83.9658, 23.3812],
            [84.0703, 23.33],
            [84.0337, 23.1384],
            [84.1294, 23.0815],
            [84.148, 22.9639],
            [84.3705, 22.9751],
            [84.2853, 22.7634],
            [84.2206, 22.6689],
            [84.0804, 22.6365],
            [84.0025, 22.5209],
            [84.0416, 22.4641],
            [84.0042, 22.3677],
            [83.861, 22.3438],
            [83.7528, 22.2428],
            [83.6457, 22.2246],
            [83.5384, 22.0554],
            [83.5352, 21.9635],
            [83.5845, 21.8437],
            [83.4909, 21.8145],
            [83.4841, 21.7416],
            [83.3807, 21.6127],
            [83.3346, 21.4955],
            [83.3938, 21.3994],
            [83.27, 21.375],
            [83.1901, 21.1355],
            [83.1199, 21.101],
            [82.9561, 21.1755],
            [82.8792, 21.1491],
            [82.6359, 21.1494],
            [82.6223, 21.0366],
            [82.4857, 20.9035],
            [82.4591, 20.8239],
            [82.3466, 20.8839],
            [82.3351, 20.7585],
            [82.3682, 20.6241],
            [82.324, 20.5539],
            [82.4292, 20.4258],
            [82.3938, 20.3355],
            [82.4136, 20.2022],
            [82.3909, 20.0596],
            [82.5984, 19.9856],
            [82.7075, 19.9902],
            [82.7027, 19.831],
            [82.4424, 19.903],
            [82.3395, 19.83],
            [82.2306, 19.9981],
            [82.1785, 19.978],
            [81.9414, 20.1022],
            [81.8665, 20.0361],
            [81.8496, 19.908],
            [82.0598, 19.7831],
            [82.0461, 19.5386],
            [82.1193, 19.4239],
            [82.183, 19.4176],
            [82.1517, 19.2655],
            [82.2121, 19.0906],
            [82.2396, 18.9104],
            [82.1727, 18.8953],
            [82.1612, 18.7909],
            [81.9677, 18.6906],
            [81.9545, 18.5867],
            [81.7621, 18.4113],
            [81.528, 18.2591],
            [81.4773, 17.97],
            [81.385, 17.8066],
            [81.254, 17.8122],
            [81.1596, 17.8526],
            [81.0325, 17.7897],
            [80.944, 18.081],
            [80.7346, 18.1717],
            [80.7881, 18.2541],
            [80.7265, 18.409],
            [80.6327, 18.5188],
            [80.4835, 18.6281],
            [80.3419, 18.5864],
            [80.2675, 18.718],
            [80.3485, 18.8239],
            [80.2676, 18.9288],
            [80.3249, 19.1415],
            [80.3866, 19.189],
            [80.4756, 19.3351],
            [80.5678, 19.4009],
            [80.6102, 19.3072],
            [80.7492, 19.2867],
            [80.845, 19.3609],
            [80.8921, 19.4657],
            [80.8276, 19.5627],
            [80.6569, 19.6123],
            [80.6658, 19.6922],
            [80.4692, 19.8247],
            [80.5456, 19.9872],
            [80.5404, 20.1098],
            [80.3938, 20.144],
            [80.3829, 20.2417],
            [80.5108, 20.2696],
            [80.6178, 20.3337],
            [80.586, 20.3956],
            [80.6223, 20.6039],
            [80.5455, 20.7654],
            [80.5418, 20.9339],
            [80.4556, 20.9321],
            [80.4238, 21.0096],
            [80.4573, 21.1728],
            [80.6353, 21.2502],
            [80.6565, 21.3307],
            [80.7302, 21.472],
            [80.7185, 21.7118],
            [80.8112, 21.7475],
            [80.8366, 21.9356],
            [80.9108, 22.1192],
            [80.9991, 22.0615],
            [81.0194, 22.2292],
            [81.1138, 22.2944],
            [81.11, 22.4411],
            [81.2184, 22.4516],
            [81.3229, 22.5243],
            [81.4799, 22.4933],
            [81.6158, 22.539],
            [81.6565, 22.632],
            [81.7627, 22.6636],
            [81.7694, 22.8735],
            [81.9402, 22.9571],
            [81.9381, 23.0778],
            [82.0241, 23.0801],
            [82.1511, 23.1417],
            [82.1859, 23.3254],
            [82.0985, 23.3977],
            [81.9761, 23.4131],
            [81.9164, 23.5321],
            [81.8272, 23.5113],
            [81.736, 23.5674],
            [81.607, 23.5069],
            [81.6027, 23.6],
            [81.6873, 23.7214],
            [81.5984, 23.8537],
            [81.6616, 23.9256],
            [81.7827, 23.8118],
            [81.9165, 23.873],
            [82.0454, 23.8205],
            [82.1993, 23.8319],
            [82.3282, 23.8037],
            [82.586, 23.8228],
            [82.6565, 23.9079],
            [82.8078, 23.963],
            [82.9388, 23.8759],
            [83.0866, 23.8782],
            [83.1895, 23.9211],
            [83.3235, 24.1014]
          ]
        ]
      },
      properties: {
        name: 'Chhattisgarh',
        id: 'IN-CT',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-CT'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [70.6738, 20.7635],
              [70.7417, 20.9901],
              [70.887, 20.9452],
              [70.8962, 20.8248],
              [70.8448, 20.7641],
              [70.8885, 20.7097],
              [70.8224, 20.691],
              [70.6738, 20.7635]
            ],
            [
              [73.2163, 20.122],
              [73.1395, 20.0843],
              [72.9732, 20.1307],
              [72.9704, 20.2124],
              [73.1105, 20.2321],
              [73.2163, 20.122]
            ]
          ]
        ]
      },
      properties: {
        name: 'Dadra and Nagar Haveli and Daman and Diu',
        id: 'IN-DNDD',
        TYPE: 'Union Territory',
        CNTRY: 'India'
      },
      id: 'IN-DNDD'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [77.1869, 28.8668],
            [77.3362, 28.6089],
            [77.3377, 28.509],
            [77.2427, 28.4846],
            [77.1658, 28.4085],
            [77.0725, 28.5203],
            [76.8794, 28.5091],
            [76.8376, 28.557],
            [76.9546, 28.7054],
            [76.9423, 28.8207],
            [77.0758, 28.8845],
            [77.1869, 28.8668]
          ]
        ]
      },
      properties: {
        name: 'Delhi',
        id: 'IN-DL',
        TYPE: 'Union Territory',
        CNTRY: 'India'
      },
      id: 'IN-DL'
    },

    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [73.6801, 15.7244],
            [73.9454, 15.7415],
            [74.0279, 15.6046],
            [74.126, 15.6503],
            [74.2552, 15.6542],
            [74.2563, 15.4895],
            [74.3416, 15.2929],
            [74.3023, 15.0399],
            [74.2118, 14.9214],
            [74.0974, 14.8956],
            [73.9668, 15.066],
            [73.8768, 15.3967],
            [73.7646, 15.4946],
            [73.6801, 15.7244]
          ]
        ]
      },
      properties: { name: 'Goa', id: 'IN-GA', TYPE: 'State', CNTRY: 'India' },
      id: 'IN-GA'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [68.3585, 23.8051],
              [68.4262, 23.7948],
              [68.3904, 23.6543],
              [68.2696, 23.6496],
              [68.3585, 23.8051]
            ]
          ],
          [
            [
              [71.1071, 24.6806],
              [71.2977, 24.6076],
              [71.4883, 24.6739],
              [71.6616, 24.6333],
              [71.8765, 24.6752],
              [71.9442, 24.6268],
              [72.0525, 24.7058],
              [72.1654, 24.6145],
              [72.23, 24.6329],
              [72.2951, 24.5384],
              [72.3579, 24.5525],
              [72.4647, 24.4071],
              [72.5446, 24.5069],
              [72.6973, 24.4572],
              [72.7325, 24.3612],
              [72.8681, 24.3658],
              [72.9239, 24.3263],
              [73.0003, 24.4706],
              [73.1083, 24.4263],
              [73.17, 24.3507],
              [73.0827, 24.1849],
              [73.2238, 24.0982],
              [73.4138, 24.0511],
              [73.4247, 23.9311],
              [73.3621, 23.8619],
              [73.4205, 23.7793],
              [73.5773, 23.6553],
              [73.7261, 23.4126],
              [73.8321, 23.4467],
              [73.8943, 23.3371],
              [74.0327, 23.3324],
              [74.1338, 23.2694],
              [74.127, 23.1789],
              [74.246, 23.1882],
              [74.3228, 23.0627],
              [74.4004, 22.8994],
              [74.4789, 22.8586],
              [74.3842, 22.6435],
              [74.2654, 22.6448],
              [74.1533, 22.5174],
              [74.258, 22.4001],
              [74.1911, 22.3219],
              [74.072, 22.3602],
              [74.0591, 22.2858],
              [74.1228, 22.2129],
              [74.0977, 22.0155],
              [74.146, 21.954],
              [73.8105, 21.8234],
              [73.8963, 21.7003],
              [73.8245, 21.5983],
              [73.8608, 21.4958],
              [74.0698, 21.5595],
              [74.1863, 21.5596],
              [74.2497, 21.4629],
              [74.0672, 21.4819],
              [73.9687, 21.3921],
              [73.9485, 21.2975],
              [73.8321, 21.2675],
              [73.8225, 21.1719],
              [73.7526, 21.1141],
              [73.7749, 21.0105],
              [73.9064, 20.9831],
              [73.9495, 20.7423],
              [73.8856, 20.7296],
              [73.8383, 20.6158],
              [73.7482, 20.5673],
              [73.6368, 20.5793],
              [73.4826, 20.7172],
              [73.4027, 20.6426],
              [73.5007, 20.5402],
              [73.4297, 20.4144],
              [73.4299, 20.2062],
              [73.3112, 20.2072],
              [73.2163, 20.122],
              [73.1105, 20.2321],
              [72.9704, 20.2124],
              [72.8745, 20.2258],
              [72.8018, 20.125],
              [72.7376, 20.1365],
              [72.7557, 20.2921],
              [72.8848, 20.5004],
              [72.8976, 20.5871],
              [72.7921, 20.8965],
              [72.5879, 21.2912],
              [72.6282, 21.6168],
              [72.5376, 21.6643],
              [72.5699, 21.8241],
              [72.5024, 21.9551],
              [72.5476, 22.1507],
              [72.5413, 22.2432],
              [72.3621, 22.2052],
              [72.2521, 21.8996],
              [72.2646, 21.7404],
              [72.306, 21.6285],
              [72.2232, 21.4388],
              [72.0821, 21.2457],
              [72.1138, 21.1966],
              [71.9768, 21.121],
              [71.8199, 21.0721],
              [71.5657, 20.9498],
              [71.4404, 20.8698],
              [71.209, 20.8021],
              [71.0776, 20.7368],
              [70.8885, 20.7097],
              [70.8448, 20.7641],
              [70.8962, 20.8248],
              [70.887, 20.9452],
              [70.7417, 20.9901],
              [70.6738, 20.7635],
              [70.439, 20.8527],
              [70.0996, 21.1045],
              [69.7051, 21.539],
              [69.2321, 21.9426],
              [68.9574, 22.2376],
              [68.936, 22.3121],
              [68.9846, 22.4143],
              [69.194, 22.4238],
              [69.1579, 22.3129],
              [69.2326, 22.2579],
              [69.5618, 22.3557],
              [69.6546, 22.3591],
              [69.7282, 22.4737],
              [69.7976, 22.3999],
              [69.9527, 22.5296],
              [70.039, 22.5629],
              [70.1629, 22.5516],
              [70.3032, 22.7576],
              [70.3476, 22.9304],
              [70.2376, 22.9796],
              [70.1321, 22.9246],
              [69.7985, 22.8507],
              [69.706, 22.7404],
              [69.5546, 22.7924],
              [69.4723, 22.7735],
              [69.3551, 22.8287],
              [69.1974, 22.8385],
              [68.8146, 23.0674],
              [68.7593, 23.0804],
              [68.5915, 23.214],
              [68.6368, 23.3468],
              [68.5024, 23.4663],
              [68.4888, 23.6324],
              [68.7168, 23.8576],
              [68.5726, 23.8807],
              [68.5468, 23.9632],
              [68.7492, 23.962],
              [68.7727, 24.2941],
              [68.8477, 24.2084],
              [68.9472, 24.2766],
              [69.0005, 24.2259],
              [69.0929, 24.2691],
              [69.1998, 24.2352],
              [69.3103, 24.2767],
              [69.5012, 24.255],
              [69.6007, 24.2779],
              [69.7246, 24.1751],
              [70.0006, 24.1664],
              [70.119, 24.2907],
              [70.5749, 24.4203],
              [70.5743, 24.2523],
              [70.8058, 24.2227],
              [70.9984, 24.3605],
              [71.0205, 24.6305],
              [71.1071, 24.6806]
            ]
          ]
        ]
      },
      properties: {
        name: 'Gujarat',
        id: 'IN-GJ',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-GJ'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [75.8725, 32.5762],
            [75.9236, 32.6437],
            [75.9125, 32.7571],
            [75.7947, 32.8702],
            [75.8671, 32.9302],
            [75.9458, 32.8851],
            [76.0937, 33.0048],
            [76.2373, 33.0326],
            [76.3917, 33.1867],
            [76.5819, 33.2069],
            [76.7299, 33.1794],
            [76.8772, 33.1142],
            [76.9985, 32.9896],
            [77.1364, 32.9805],
            [77.3565, 32.8253],
            [77.5839, 32.9438],
            [77.7125, 32.9718],
            [77.9151, 32.7661],
            [77.9032, 32.6931],
            [77.9826, 32.5865],
            [78.0934, 32.6621],
            [78.2129, 32.6692],
            [78.3713, 32.7618],
            [78.3888, 32.6232],
            [78.2966, 32.5775],
            [78.3104, 32.4759],
            [78.4108, 32.5117],
            [78.5188, 32.4185],
            [78.4662, 32.284],
            [78.5929, 32.2187],
            [78.5743, 32.1586],
            [78.771, 31.9998],
            [78.6913, 31.7934],
            [78.7344, 31.692],
            [78.8311, 31.6153],
            [78.7066, 31.5184],
            [78.7955, 31.4528],
            [78.829, 31.3302],
            [78.9069, 31.2592],
            [78.9966, 31.1153],
            [78.8714, 31.1073],
            [78.795, 31.2051],
            [78.6477, 31.1947],
            [78.5954, 31.2364],
            [78.4701, 31.2038],
            [78.3563, 31.2909],
            [78.0877, 31.1908],
            [77.8875, 31.1547],
            [77.7964, 30.9698],
            [77.7894, 30.8585],
            [77.6883, 30.7687],
            [77.7791, 30.6638],
            [77.7295, 30.5984],
            [77.7992, 30.5114],
            [77.563, 30.4045],
            [77.5749, 30.3845],
            [77.4164, 30.4084],
            [77.3461, 30.4619],
            [77.2026, 30.4803],
            [77.1518, 30.6905],
            [77.0131, 30.7422],
            [76.9022, 30.8972],
            [76.7699, 30.9064],
            [76.6101, 31.0042],
            [76.6354, 31.213],
            [76.4483, 31.306],
            [76.3591, 31.4156],
            [76.2961, 31.3192],
            [76.1728, 31.3067],
            [76.1535, 31.414],
            [76.0027, 31.6466],
            [75.9218, 31.8172],
            [75.8945, 31.9498],
            [75.6898, 32.0615],
            [75.6226, 32.2347],
            [75.7364, 32.2738],
            [75.8439, 32.3793],
            [75.9322, 32.4168],
            [75.851, 32.5079],
            [75.8725, 32.5762]
          ]
        ]
      },
      properties: {
        name: 'Himachal Pradesh',
        id: 'IN-HP',
        TYPE: 'Union Territory',
        CNTRY: 'India'
      },
      id: 'IN-HP'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [76.7699, 30.9064],
            [76.9022, 30.8972],
            [77.0131, 30.7422],
            [77.1518, 30.6905],
            [77.2026, 30.4803],
            [77.3461, 30.4619],
            [77.4164, 30.4084],
            [77.5749, 30.3845],
            [77.4144, 30.1071],
            [77.2704, 30.0484],
            [77.1802, 29.9062],
            [77.1433, 29.706],
            [77.0856, 29.6008],
            [77.1394, 29.4421],
            [77.1169, 29.3767],
            [77.122, 29.1058],
            [77.2142, 29.006],
            [77.1869, 28.8668],
            [77.0758, 28.8845],
            [76.9423, 28.8207],
            [76.9546, 28.7054],
            [76.8376, 28.557],
            [76.8794, 28.5091],
            [77.0725, 28.5203],
            [77.1658, 28.4085],
            [77.2427, 28.4846],
            [77.3377, 28.509],
            [77.4745, 28.4135],
            [77.4927, 28.2806],
            [77.4791, 28.0442],
            [77.5192, 27.9319],
            [77.2758, 27.8066],
            [77.1265, 27.7765],
            [77.0383, 27.82],
            [76.9705, 27.6571],
            [76.8809, 27.6994],
            [76.9388, 27.919],
            [76.9186, 27.9976],
            [76.9618, 28.144],
            [76.8505, 28.2267],
            [76.7915, 28.1572],
            [76.5385, 28.0391],
            [76.471, 28.1547],
            [76.3768, 28.1381],
            [76.1549, 27.999],
            [76.0881, 27.8685],
            [75.9631, 27.8647],
            [76.0101, 28.0367],
            [75.9361, 28.0935],
            [76.053, 28.2242],
            [75.8892, 28.3937],
            [75.6291, 28.546],
            [75.5557, 28.6146],
            [75.5134, 28.8366],
            [75.4347, 29.0165],
            [75.3807, 29.0703],
            [75.3957, 29.262],
            [75.1082, 29.2268],
            [74.9531, 29.2819],
            [74.9271, 29.3646],
            [74.8411, 29.4041],
            [74.7566, 29.356],
            [74.5967, 29.3622],
            [74.5578, 29.4186],
            [74.6144, 29.5271],
            [74.5665, 29.5635],
            [74.6054, 29.7529],
            [74.4653, 29.7875],
            [74.5536, 29.8656],
            [74.5191, 29.9433],
            [74.6399, 29.9225],
            [74.8012, 29.9926],
            [74.9157, 29.9492],
            [74.9899, 29.8565],
            [75.0655, 29.8791],
            [75.1823, 29.8387],
            [75.231, 29.7521],
            [75.1578, 29.6627],
            [75.2806, 29.6101],
            [75.4443, 29.8079],
            [75.5117, 29.76],
            [75.7059, 29.8086],
            [75.829, 29.8123],
            [75.8793, 29.7471],
            [76.0356, 29.7467],
            [76.1846, 29.8886],
            [76.1909, 30.0164],
            [76.255, 30.1046],
            [76.39, 30.1276],
            [76.5011, 30.0766],
            [76.6267, 30.1057],
            [76.6389, 30.2059],
            [76.584, 30.2565],
            [76.7579, 30.4389],
            [76.8324, 30.4339],
            [76.9134, 30.5997],
            [76.8198, 30.6858],
            [76.8282, 30.7638],
            [76.7699, 30.9064]
          ]
        ]
      },
      properties: {
        name: 'Haryana',
        id: 'IN-HR',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-HR'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [83.4982, 24.5271],
            [83.7167, 24.5049],
            [83.9285, 24.5479],
            [84.0467, 24.6123],
            [84.1098, 24.4814],
            [84.1883, 24.5553],
            [84.3264, 24.5028],
            [84.3359, 24.3958],
            [84.4545, 24.3386],
            [84.7953, 24.5295],
            [84.8802, 24.4628],
            [84.898, 24.3721],
            [85.0533, 24.4151],
            [85.2825, 24.5267],
            [85.4865, 24.5507],
            [85.5765, 24.603],
            [85.7368, 24.8227],
            [85.8778, 24.8003],
            [85.9503, 24.7321],
            [86.0506, 24.7796],
            [86.1244, 24.7052],
            [86.1333, 24.6035],
            [86.2174, 24.6118],
            [86.304, 24.5583],
            [86.3507, 24.4437],
            [86.4522, 24.3686],
            [86.5057, 24.5177],
            [86.6029, 24.6051],
            [86.6684, 24.5606],
            [86.7771, 24.6198],
            [86.8546, 24.5505],
            [86.9301, 24.6429],
            [87.0505, 24.6068],
            [87.0966, 24.8015],
            [87.1581, 24.8856],
            [87.1468, 25.0243],
            [87.2911, 25.0893],
            [87.3235, 25.2231],
            [87.4727, 25.2409],
            [87.5474, 25.3309],
            [87.6841, 25.3101],
            [87.7876, 25.2205],
            [87.7765, 25.0913],
            [87.877, 25.0281],
            [87.9733, 24.8964],
            [87.889, 24.853],
            [87.8873, 24.5627],
            [87.8063, 24.5451],
            [87.81, 24.4066],
            [87.7563, 24.3036],
            [87.6377, 24.2116],
            [87.6896, 24.1498],
            [87.4936, 24.1152],
            [87.4827, 24.0136],
            [87.238, 24.0412],
            [87.2945, 23.8986],
            [87.1242, 23.795],
            [86.895, 23.88],
            [86.8208, 23.8113],
            [86.792, 23.6873],
            [86.6939, 23.6945],
            [86.5287, 23.6289],
            [86.4399, 23.6296],
            [86.358, 23.543],
            [86.372, 23.4826],
            [86.2432, 23.4196],
            [86.0472, 23.4936],
            [85.866, 23.4702],
            [85.878, 23.3237],
            [85.8264, 23.2631],
            [85.915, 23.1296],
            [86.0369, 23.1436],
            [86.2125, 22.9919],
            [86.2983, 23.0132],
            [86.4328, 22.9234],
            [86.4162, 22.7765],
            [86.5066, 22.7015],
            [86.6179, 22.6734],
            [86.6517, 22.5758],
            [86.76, 22.5724],
            [86.8862, 22.2941],
            [86.8001, 22.2136],
            [86.7228, 22.2153],
            [86.6456, 22.2614],
            [86.3527, 22.3454],
            [86.2798, 22.4455],
            [86.022, 22.5205],
            [85.9698, 22.2434],
            [86.0267, 22.1852],
            [85.9054, 21.9666],
            [85.7607, 21.9897],
            [85.6446, 22.0903],
            [85.5396, 22.0866],
            [85.3944, 22.1586],
            [85.3001, 22.1071],
            [85.2309, 22.0003],
            [85.1725, 22.0682],
            [85.0237, 22.112],
            [85.0705, 22.2721],
            [85.0625, 22.4784],
            [84.8938, 22.418],
            [84.5266, 22.42],
            [84.4261, 22.3488],
            [84.2892, 22.3373],
            [84.1525, 22.401],
            [84.1349, 22.4709],
            [84.0025, 22.5209],
            [84.0804, 22.6365],
            [84.2206, 22.6689],
            [84.2853, 22.7634],
            [84.3705, 22.9751],
            [84.148, 22.9639],
            [84.1294, 23.0815],
            [84.0337, 23.1384],
            [84.0703, 23.33],
            [83.9658, 23.3812],
            [84.0227, 23.5442],
            [83.7749, 23.5995],
            [83.7147, 23.6825],
            [83.6954, 23.8206],
            [83.5612, 23.8629],
            [83.5067, 24.0282],
            [83.3235, 24.1014],
            [83.4019, 24.266],
            [83.3759, 24.3146],
            [83.393, 24.5012],
            [83.4982, 24.5271]
          ]
        ]
      },
      properties: {
        name: 'Jharkhand',
        id: 'IN-JH',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-JH'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [78.4108, 32.5117],
            [78.3104, 32.4759],
            [78.2966, 32.5775],
            [78.3888, 32.6232],
            [78.3713, 32.7618],
            [78.2129, 32.6692],
            [78.0934, 32.6621],
            [77.9826, 32.5865],
            [77.9032, 32.6931],
            [77.9151, 32.7661],
            [77.7125, 32.9718],
            [77.5839, 32.9438],
            [77.3565, 32.8253],
            [77.1364, 32.9805],
            [76.9985, 32.9896],
            [76.8772, 33.1142],
            [76.7299, 33.1794],
            [76.5819, 33.2069],
            [76.3917, 33.1867],
            [76.2373, 33.0326],
            [76.0937, 33.0048],
            [75.9458, 32.8851],
            [75.8671, 32.9302],
            [75.7947, 32.8702],
            [75.9125, 32.7571],
            [75.9236, 32.6437],
            [75.8725, 32.5762],
            [75.7115, 32.4182],
            [75.415, 32.3243],
            [75.3223, 32.3373],
            [75.1395, 32.4146],
            [75.0366, 32.4921],
            [74.9793, 32.4478],
            [74.8353, 32.4962],
            [74.6984, 32.4849],
            [74.6422, 32.6111],
            [74.6762, 32.658],
            [74.6142, 32.7577],
            [74.4749, 32.7111],
            [74.3371, 32.7231],
            [74.0946, 32.8637],
            [73.8759, 32.9599],
            [73.7239, 32.9599],
            [73.5717, 33.108],
            [73.6003, 33.208],
            [73.5432, 33.4036],
            [73.5955, 33.4554],
            [73.5575, 33.5828],
            [73.586, 33.6901],
            [73.5147, 33.7498],
            [73.567, 33.8529],
            [73.5289, 33.9758],
            [73.4576, 34.0352],
            [73.4481, 34.2423],
            [73.4006, 34.3409],
            [73.4196, 34.5064],
            [73.4814, 34.5614],
            [73.6668, 34.6126],
            [73.7333, 34.7774],
            [73.9615, 34.7892],
            [74.0186, 34.8245],
            [74.0994, 35.0163],
            [74.0994, 35.0945],
            [74.0138, 35.1648],
            [73.7999, 35.2312],
            [73.7096, 35.3247],
            [73.8237, 35.4687],
            [73.7761, 35.5542],
            [73.6763, 35.5503],
            [73.3008, 35.5969],
            [73.2105, 35.6645],
            [73.1344, 35.835],
            [72.8587, 35.9123],
            [72.7636, 35.8621],
            [72.621, 35.9046],
            [72.6163, 36.0939],
            [72.6828, 36.2442],
            [72.9157, 36.3904],
            [73.1296, 36.5938],
            [73.1724, 36.7011],
            [73.2675, 36.7508],
            [73.8094, 36.7164],
            [73.8759, 36.9075],
            [73.9615, 36.8464],
            [74.0851, 36.8235],
            [74.2325, 36.8999],
            [74.6603, 37.0524],
            [74.8647, 37.0067],
            [74.9217, 36.9533],
            [75.1166, 36.9609],
            [75.264, 37.0296],
            [75.3401, 36.9991],
            [75.6933, 36.5923],
            [76.0117, 36.4465],
            [76.1448, 36.3466],
            [76.3017, 36.3043],
            [76.5014, 36.1463],
            [76.6297, 36.1348],
            [76.7391, 36.0383],
            [76.72, 35.9108],
            [76.8104, 35.7676],
            [77.0956, 35.7366],
            [77.2715, 35.694],
            [77.376, 35.6163],
            [77.4663, 35.4454],
            [77.6327, 35.4298],
            [77.8239, 35.5013],
            [78.1224, 35.5348],
            [78.2188, 35.659],
            [78.3282, 35.7366],
            [78.5611, 35.7947],
            [78.6562, 35.8528],
            [78.8225, 35.8644],
            [79.046, 35.7908],
            [79.103, 35.9185],
            [79.2409, 35.9881],
            [79.4215, 35.8528],
            [79.759, 35.7831],
            [79.9206, 35.8373],
            [80.0395, 35.6784],
            [80.2201, 35.5853],
            [80.2344, 35.3364],
            [80.1393, 35.1687],
            [80.1345, 35.0085],
            [80.0109, 34.7147],
            [79.7619, 34.6157],
            [79.7238, 34.4859],
            [79.5337, 34.4859],
            [79.4861, 34.4505],
            [79.5628, 34.2614],
            [79.5212, 34.1133],
            [79.3638, 34.0045],
            [79.1617, 34.0539],
            [78.9448, 33.9995],
            [78.921, 33.9525],
            [78.9775, 33.8212],
            [79.0845, 33.6747],
            [78.9448, 33.5579],
            [78.919, 33.4097],
            [79.0666, 33.2959],
            [79.075, 33.2286],
            [79.1855, 33.1912],
            [79.4098, 33.1788],
            [79.3524, 32.979],
            [79.5333, 32.7599],
            [79.5454, 32.6074],
            [79.448, 32.5861],
            [79.3122, 32.4871],
            [79.2584, 32.5234],
            [79.1265, 32.4643],
            [79.1045, 32.3717],
            [78.9772, 32.3428],
            [78.7812, 32.4753],
            [78.7459, 32.6961],
            [78.6257, 32.6071],
            [78.5449, 32.6123],
            [78.4108, 32.5117]
          ]
        ]
      },
      properties: {
        name: 'Jammu and Kashmir',
        id: 'IN-JK',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-JK'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [74.126, 15.6503],
            [74.2044, 15.7534],
            [74.3649, 15.7737],
            [74.3919, 15.9012],
            [74.4891, 16.0873],
            [74.5031, 16.2311],
            [74.323, 16.3251],
            [74.3294, 16.5535],
            [74.5514, 16.6332],
            [74.6364, 16.5758],
            [74.6982, 16.7152],
            [74.855, 16.7708],
            [74.9384, 16.941],
            [75.0904, 16.9487],
            [75.2158, 16.8403],
            [75.2884, 16.9548],
            [75.4679, 16.9853],
            [75.4925, 16.9429],
            [75.6746, 16.9603],
            [75.6785, 17.0942],
            [75.628, 17.1702],
            [75.6599, 17.2614],
            [75.6059, 17.3033],
            [75.6356, 17.4775],
            [75.8023, 17.3701],
            [75.9311, 17.3241],
            [76.2444, 17.3747],
            [76.3436, 17.35],
            [76.3732, 17.4344],
            [76.3317, 17.5948],
            [76.4893, 17.6607],
            [76.5237, 17.7589],
            [76.7276, 17.737],
            [76.8028, 17.8683],
            [76.9164, 17.9151],
            [76.9226, 18.1162],
            [76.9541, 18.1849],
            [77.0577, 18.1524],
            [77.3174, 18.4538],
            [77.4101, 18.3885],
            [77.4654, 18.2599],
            [77.55, 18.2914],
            [77.6462, 17.9932],
            [77.5497, 17.818],
            [77.562, 17.7471],
            [77.4503, 17.6913],
            [77.4399, 17.5815],
            [77.6856, 17.5056],
            [77.4516, 17.3705],
            [77.36, 17.1616],
            [77.4626, 17.1041],
            [77.4994, 17.0073],
            [77.4534, 16.9091],
            [77.4642, 16.5842],
            [77.2847, 16.403],
            [77.5916, 16.3375],
            [77.4836, 16.1586],
            [77.5095, 15.9208],
            [77.2489, 15.9578],
            [77.066, 15.8993],
            [77.0293, 15.6338],
            [77.0274, 15.4352],
            [77.0616, 15.3218],
            [77.1642, 15.2591],
            [77.154, 15.1205],
            [77.0788, 15],
            [76.8767, 15.0287],
            [76.7969, 15.0908],
            [76.7638, 14.9699],
            [76.8644, 14.9641],
            [76.8488, 14.804],
            [76.7602, 14.602],
            [76.8702, 14.4685],
            [76.8791, 14.3464],
            [77.0496, 14.2396],
            [77.1521, 14.3378],
            [77.2796, 14.2761],
            [77.4423, 14.3097],
            [77.4963, 14.2718],
            [77.5004, 14.151],
            [77.3954, 14.1035],
            [77.3262, 14.023],
            [77.1473, 13.9916],
            [77.0237, 14.0533],
            [77.0198, 14.1727],
            [76.927, 14.1272],
            [76.9311, 14.0227],
            [77.0358, 13.9256],
            [76.9684, 13.8144],
            [76.9926, 13.7426],
            [77.1666, 13.7547],
            [77.1788, 13.8598],
            [77.4245, 13.8351],
            [77.5123, 13.6857],
            [77.6491, 13.7759],
            [77.7785, 13.8129],
            [77.8183, 13.9349],
            [77.9664, 13.9506],
            [78.1135, 13.8409],
            [78.0641, 13.6882],
            [78.2277, 13.5872],
            [78.3908, 13.5781],
            [78.3552, 13.3551],
            [78.5775, 13.262],
            [78.5691, 13.1565],
            [78.4516, 13.0315],
            [78.4358, 12.9007],
            [78.3454, 12.9281],
            [78.2411, 12.8474],
            [78.2207, 12.7526],
            [78.1207, 12.77],
            [78.0467, 12.8424],
            [77.9814, 12.7956],
            [77.9177, 12.8784],
            [77.7995, 12.7869],
            [77.7314, 12.664],
            [77.5903, 12.6576],
            [77.5785, 12.507],
            [77.6252, 12.4773],
            [77.6038, 12.3538],
            [77.4657, 12.2023],
            [77.7264, 12.1673],
            [77.7684, 12.1056],
            [77.6605, 11.9382],
            [77.4844, 11.9324],
            [77.4193, 11.7507],
            [77.2848, 11.7984],
            [77.1028, 11.7631],
            [77.0044, 11.803],
            [76.8997, 11.7833],
            [76.8165, 11.5972],
            [76.5601, 11.6101],
            [76.5042, 11.698],
            [76.4235, 11.6593],
            [76.184, 11.8638],
            [76.1081, 11.8512],
            [76.1079, 11.9706],
            [75.9944, 11.9233],
            [75.8767, 11.9407],
            [75.7997, 12.0712],
            [75.717, 12.0621],
            [75.5768, 12.1508],
            [75.3666, 12.4516],
            [75.2229, 12.5627],
            [75.1506, 12.6763],
            [74.8671, 12.7545],
            [74.821, 12.8523],
            [74.6951, 13.3477],
            [74.6707, 13.6337],
            [74.6068, 13.8665],
            [74.5015, 14.0226],
            [74.3538, 14.5029],
            [74.3096, 14.5207],
            [74.2665, 14.7149],
            [74.2049, 14.7179],
            [74.1235, 14.8048],
            [74.0974, 14.8956],
            [74.2118, 14.9214],
            [74.3023, 15.0399],
            [74.3416, 15.2929],
            [74.2563, 15.4895],
            [74.2552, 15.6542],
            [74.126, 15.6503]
          ]
        ]
      },
      properties: {
        name: 'Karnataka',
        id: 'IN-KA',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-KA'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [74.8671, 12.7545],
            [75.1506, 12.6763],
            [75.2229, 12.5627],
            [75.3666, 12.4516],
            [75.5768, 12.1508],
            [75.717, 12.0621],
            [75.7997, 12.0712],
            [75.8767, 11.9407],
            [75.9944, 11.9233],
            [76.1079, 11.9706],
            [76.1081, 11.8512],
            [76.184, 11.8638],
            [76.4235, 11.6593],
            [76.3501, 11.5827],
            [76.2258, 11.5644],
            [76.2781, 11.4745],
            [76.4104, 11.4336],
            [76.5402, 11.2924],
            [76.4876, 11.2028],
            [76.623, 11.1856],
            [76.7387, 11.1213],
            [76.6492, 10.9242],
            [76.8175, 10.8618],
            [76.8975, 10.7708],
            [76.8297, 10.5851],
            [76.8073, 10.4149],
            [76.8197, 10.2952],
            [76.9708, 10.2086],
            [77.163, 10.3463],
            [77.2805, 10.2073],
            [77.1991, 9.866],
            [77.2082, 9.7217],
            [77.1519, 9.606],
            [77.3043, 9.593],
            [77.3962, 9.4966],
            [77.2668, 9.2909],
            [77.2518, 9.143],
            [77.1322, 9.0118],
            [77.243, 8.8593],
            [77.1553, 8.7375],
            [77.2652, 8.5375],
            [77.0965, 8.298],
            [76.9749, 8.3851],
            [76.6318, 8.8362],
            [76.5462, 8.9024],
            [76.5187, 9.0185],
            [76.3379, 9.416],
            [76.2374, 9.9654],
            [76.0993, 10.3807],
            [75.9101, 10.7923],
            [75.8576, 11.0184],
            [75.7357, 11.3532],
            [75.6826, 11.4482],
            [75.6174, 11.4771],
            [75.5423, 11.6818],
            [75.4051, 11.8382],
            [75.3554, 11.8596],
            [75.266, 12.006],
            [75.1818, 12.0951],
            [74.9735, 12.4988],
            [74.8671, 12.7545]
          ]
        ]
      },
      properties: {
        name: 'Kerala',
        id: 'IN-KL',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-KL'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [72.7934, 11.258],
            [72.7815, 11.2154],
            [72.7645, 11.1782],
            [72.7812, 11.2391],
            [72.7934, 11.258]
          ]
        ]
      },
      properties: {
        name: 'Lakshadweep',
        id: 'IN-LD',
        TYPE: 'Union Territory',
        CNTRY: 'India'
      },
      id: 'IN-LD'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [74.146, 21.954],
            [74.2897, 21.9358],
            [74.3883, 22.0202],
            [74.5275, 21.9088],
            [74.5112, 21.7267],
            [74.5589, 21.682],
            [74.7911, 21.6106],
            [74.9019, 21.6302],
            [75.0577, 21.5649],
            [75.1151, 21.4596],
            [75.2199, 21.4109],
            [75.5481, 21.3725],
            [75.8881, 21.3996],
            [76.0524, 21.3527],
            [76.0987, 21.3739],
            [76.1727, 21.1883],
            [76.1726, 21.0846],
            [76.3764, 21.0773],
            [76.4874, 21.1961],
            [76.6234, 21.1926],
            [76.6245, 21.3351],
            [76.7842, 21.4665],
            [76.7953, 21.5975],
            [76.9011, 21.6008],
            [77.0723, 21.7229],
            [77.2832, 21.7242],
            [77.2862, 21.7627],
            [77.4755, 21.7715],
            [77.5713, 21.6271],
            [77.567, 21.5298],
            [77.4575, 21.5565],
            [77.487, 21.3777],
            [77.5844, 21.3645],
            [77.7994, 21.4123],
            [77.9388, 21.3869],
            [78.1693, 21.4985],
            [78.1809, 21.5587],
            [78.4226, 21.602],
            [78.5849, 21.4869],
            [78.9318, 21.4874],
            [78.9132, 21.5924],
            [79.0881, 21.5992],
            [79.2287, 21.7239],
            [79.349, 21.6789],
            [79.4886, 21.6741],
            [79.5366, 21.5442],
            [79.7326, 21.6025],
            [79.8571, 21.5309],
            [80.0636, 21.5546],
            [80.1882, 21.6342],
            [80.2602, 21.6202],
            [80.3706, 21.5209],
            [80.4113, 21.4382],
            [80.5932, 21.3248],
            [80.6565, 21.3307],
            [80.6353, 21.2502],
            [80.4573, 21.1728],
            [80.4238, 21.0096],
            [80.4556, 20.9321],
            [80.5418, 20.9339],
            [80.5455, 20.7654],
            [80.6223, 20.6039],
            [80.586, 20.3956],
            [80.6178, 20.3337],
            [80.5108, 20.2696],
            [80.3829, 20.2417],
            [80.3938, 20.144],
            [80.5404, 20.1098],
            [80.5456, 19.9872],
            [80.4692, 19.8247],
            [80.6658, 19.6922],
            [80.6569, 19.6123],
            [80.8276, 19.5627],
            [80.8921, 19.4657],
            [80.845, 19.3609],
            [80.7492, 19.2867],
            [80.6102, 19.3072],
            [80.5678, 19.4009],
            [80.4756, 19.3351],
            [80.3866, 19.189],
            [80.3249, 19.1415],
            [80.2676, 18.9288],
            [80.3485, 18.8239],
            [80.2675, 18.718],
            [80.1108, 18.6762],
            [80.0124, 18.7667],
            [79.932, 18.7844],
            [79.9345, 19.0217],
            [79.8581, 19.1041],
            [79.9432, 19.1706],
            [79.973, 19.401],
            [79.9402, 19.4645],
            [79.7403, 19.6031],
            [79.5426, 19.5485],
            [79.4704, 19.4975],
            [79.369, 19.5712],
            [79.2439, 19.6125],
            [79.1778, 19.4607],
            [79.0184, 19.5399],
            [78.9689, 19.6492],
            [78.8588, 19.6564],
            [78.8456, 19.7584],
            [78.7164, 19.7668],
            [78.6037, 19.8175],
            [78.3977, 19.8364],
            [78.3453, 19.7136],
            [78.2697, 19.6631],
            [78.3089, 19.4564],
            [78.1822, 19.4117],
            [78.1701, 19.2363],
            [78.0457, 19.2708],
            [77.9489, 19.3413],
            [77.8609, 19.3021],
            [77.8584, 19.1808],
            [77.7617, 19.0621],
            [77.7674, 18.9814],
            [77.8537, 18.9523],
            [77.9426, 18.8223],
            [77.8375, 18.8075],
            [77.7365, 18.5548],
            [77.601, 18.4742],
            [77.5195, 18.3493],
            [77.55, 18.2914],
            [77.4654, 18.2599],
            [77.4101, 18.3885],
            [77.3174, 18.4538],
            [77.0577, 18.1524],
            [76.9541, 18.1849],
            [76.9226, 18.1162],
            [76.9164, 17.9151],
            [76.8028, 17.8683],
            [76.7276, 17.737],
            [76.5237, 17.7589],
            [76.4893, 17.6607],
            [76.3317, 17.5948],
            [76.3732, 17.4344],
            [76.3436, 17.35],
            [76.2444, 17.3747],
            [75.9311, 17.3241],
            [75.8023, 17.3701],
            [75.6356, 17.4775],
            [75.6059, 17.3033],
            [75.6599, 17.2614],
            [75.628, 17.1702],
            [75.6785, 17.0942],
            [75.6746, 16.9603],
            [75.4925, 16.9429],
            [75.4679, 16.9853],
            [75.2884, 16.9548],
            [75.2158, 16.8403],
            [75.0904, 16.9487],
            [74.9384, 16.941],
            [74.855, 16.7708],
            [74.6982, 16.7152],
            [74.6364, 16.5758],
            [74.5514, 16.6332],
            [74.3294, 16.5535],
            [74.323, 16.3251],
            [74.5031, 16.2311],
            [74.4891, 16.0873],
            [74.3919, 15.9012],
            [74.3649, 15.7737],
            [74.2044, 15.7534],
            [74.126, 15.6503],
            [74.0279, 15.6046],
            [73.9454, 15.7415],
            [73.6801, 15.7244],
            [73.5904, 15.9124],
            [73.5099, 15.9374],
            [73.3649, 16.4396],
            [73.3162, 16.5135],
            [73.3112, 16.8068],
            [73.2771, 16.8921],
            [73.2943, 16.986],
            [73.2235, 17.2666],
            [73.244, 17.3074],
            [73.186, 17.4418],
            [73.1351, 17.7163],
            [73.0299, 17.9441],
            [72.9709, 18.1321],
            [72.9929, 18.2735],
            [72.8954, 18.4726],
            [72.9285, 18.5418],
            [72.8552, 18.6968],
            [72.9079, 18.9021],
            [73.0029, 19.0015],
            [72.8621, 19.0029],
            [72.7801, 19.1662],
            [72.799, 19.3313],
            [72.7576, 19.376],
            [72.7182, 19.5387],
            [72.7379, 19.5979],
            [72.6643, 19.9329],
            [72.7163, 19.9723],
            [72.7376, 20.1365],
            [72.8018, 20.125],
            [72.8745, 20.2258],
            [72.9704, 20.2124],
            [72.9732, 20.1307],
            [73.1395, 20.0843],
            [73.2163, 20.122],
            [73.3112, 20.2072],
            [73.4299, 20.2062],
            [73.4297, 20.4144],
            [73.5007, 20.5402],
            [73.4027, 20.6426],
            [73.4826, 20.7172],
            [73.6368, 20.5793],
            [73.7482, 20.5673],
            [73.8383, 20.6158],
            [73.8856, 20.7296],
            [73.9495, 20.7423],
            [73.9064, 20.9831],
            [73.7749, 21.0105],
            [73.7526, 21.1141],
            [73.8225, 21.1719],
            [73.8321, 21.2675],
            [73.9485, 21.2975],
            [73.9687, 21.3921],
            [74.0672, 21.4819],
            [74.2497, 21.4629],
            [74.1863, 21.5596],
            [74.0698, 21.5595],
            [73.8608, 21.4958],
            [73.8245, 21.5983],
            [73.8963, 21.7003],
            [73.8105, 21.8234],
            [74.146, 21.954]
          ]
        ]
      },
      properties: {
        name: 'Maharashtra',
        id: 'IN-MH',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-MH'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [89.8701, 25.5442],
            [90.0173, 25.6022],
            [89.8926, 25.735],
            [89.9446, 25.8245],
            [90.1187, 25.9613],
            [90.1825, 25.9445],
            [90.4773, 26.0153],
            [90.629, 25.9374],
            [90.7474, 25.9573],
            [90.9427, 25.9476],
            [91.0633, 25.8204],
            [91.1965, 25.859],
            [91.192, 25.7296],
            [91.2756, 25.7469],
            [91.3335, 25.8388],
            [91.4444, 25.841],
            [91.5758, 26.0331],
            [91.7202, 25.9541],
            [91.7312, 26.0592],
            [91.8193, 26.1187],
            [91.9221, 25.9995],
            [91.9911, 26.041],
            [92.2412, 26.0626],
            [92.1563, 25.9448],
            [92.1607, 25.6719],
            [92.229, 25.7162],
            [92.4332, 25.7314],
            [92.5017, 25.6229],
            [92.6447, 25.5374],
            [92.5982, 25.4717],
            [92.7606, 25.3353],
            [92.8044, 25.2193],
            [92.6669, 25.1771],
            [92.6217, 25.1177],
            [92.5246, 25.14],
            [92.4259, 25.0318],
            [92.2336, 25.097],
            [92.0672, 25.19],
            [91.7073, 25.1588],
            [91.6174, 25.1759],
            [91.4741, 25.1338],
            [91.2738, 25.2043],
            [91.0825, 25.1975],
            [90.867, 25.1561],
            [90.7854, 25.184],
            [90.5167, 25.1751],
            [90.4524, 25.1445],
            [90.3415, 25.1935],
            [90.1152, 25.2269],
            [89.9712, 25.3031],
            [89.8474, 25.3102],
            [89.8551, 25.476],
            [89.8701, 25.5442]
          ]
        ]
      },
      properties: {
        name: 'Meghalaya',
        id: 'IN-ML',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-ML'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [93.474, 25.3093],
            [93.5051, 25.2441],
            [93.608, 25.202],
            [93.6928, 25.3616],
            [93.8089, 25.4656],
            [93.7706, 25.5403],
            [94.0147, 25.5961],
            [94.1387, 25.5237],
            [94.3135, 25.4945],
            [94.5557, 25.5858],
            [94.5574, 25.5127],
            [94.6824, 25.4597],
            [94.6312, 25.3873],
            [94.5854, 25.2157],
            [94.7457, 25.1366],
            [94.7191, 24.9425],
            [94.6378, 24.8423],
            [94.6109, 24.7221],
            [94.4655, 24.5756],
            [94.3834, 24.3794],
            [94.2627, 24.1677],
            [94.2453, 24.0442],
            [94.1614, 23.8528],
            [93.907, 23.9572],
            [93.8159, 23.9299],
            [93.7569, 24.0095],
            [93.6206, 24.0142],
            [93.5151, 23.9472],
            [93.4154, 24.0792],
            [93.3374, 24.0534],
            [93.1009, 24.0451],
            [92.9764, 24.1148],
            [93.0434, 24.3434],
            [93.0005, 24.4032],
            [93.1047, 24.6754],
            [93.2624, 24.9513],
            [93.2488, 25.0188],
            [93.3358, 25.0958],
            [93.3887, 25.2459],
            [93.474, 25.3093]
          ]
        ]
      },
      properties: {
        name: 'Manipur',
        id: 'IN-MN',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-MN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [78.2098, 26.8361],
            [78.3514, 26.8744],
            [78.3897, 26.8249],
            [78.5659, 26.7577],
            [78.7153, 26.8002],
            [78.8649, 26.7047],
            [78.9932, 26.6846],
            [78.9913, 26.5823],
            [79.0609, 26.5033],
            [79.1338, 26.345],
            [79.037, 26.267],
            [79.0032, 26.0818],
            [78.9443, 26.037],
            [78.8205, 25.7994],
            [78.7468, 25.7491],
            [78.8052, 25.6245],
            [78.6486, 25.5656],
            [78.4863, 25.5822],
            [78.4087, 25.5329],
            [78.2955, 25.3748],
            [78.3854, 25.1952],
            [78.3278, 25.0888],
            [78.3275, 24.9996],
            [78.1654, 24.8818],
            [78.2685, 24.6671],
            [78.2669, 24.4473],
            [78.4348, 24.2977],
            [78.5054, 24.3939],
            [78.5784, 24.357],
            [78.6691, 24.245],
            [78.7603, 24.2585],
            [78.8789, 24.2231],
            [78.9669, 24.3536],
            [78.9844, 24.4413],
            [78.8817, 24.6397],
            [78.7769, 24.5931],
            [78.7645, 24.8614],
            [78.622, 24.9649],
            [78.5686, 25.2586],
            [78.6035, 25.417],
            [78.7644, 25.3584],
            [78.7565, 25.4761],
            [78.9164, 25.5208],
            [78.8377, 25.3522],
            [78.8718, 25.166],
            [79.0121, 25.2483],
            [79.1204, 25.1642],
            [79.2384, 25.1164],
            [79.2788, 25.1974],
            [79.2601, 25.3102],
            [79.3459, 25.333],
            [79.4153, 25.2592],
            [79.3991, 25.1135],
            [79.4899, 25.0827],
            [79.5979, 25.1315],
            [79.7456, 25.1439],
            [79.8559, 25.0985],
            [79.8471, 25.2402],
            [79.9967, 25.2686],
            [80.0207, 25.3432],
            [80.1233, 25.3384],
            [80.1939, 25.4162],
            [80.3107, 25.3873],
            [80.304, 25.2889],
            [80.4237, 25.1741],
            [80.3513, 25.1449],
            [80.2669, 25.0303],
            [80.5095, 25.1011],
            [80.6736, 25.0542],
            [80.7996, 25.1464],
            [80.8707, 25.071],
            [80.8349, 24.9345],
            [80.9552, 24.9257],
            [81.1614, 24.9585],
            [81.2616, 25.0685],
            [81.2691, 25.1677],
            [81.4729, 25.1371],
            [81.5664, 25.1957],
            [81.6819, 25.0437],
            [81.8861, 24.9928],
            [81.8964, 24.8925],
            [82.2384, 24.7544],
            [82.2299, 24.6697],
            [82.4092, 24.5978],
            [82.4016, 24.6838],
            [82.6579, 24.7004],
            [82.7628, 24.6457],
            [82.7072, 24.3852],
            [82.7641, 24.2925],
            [82.7084, 24.0808],
            [82.8078, 23.963],
            [82.6565, 23.9079],
            [82.586, 23.8228],
            [82.3282, 23.8037],
            [82.1993, 23.8319],
            [82.0454, 23.8205],
            [81.9165, 23.873],
            [81.7827, 23.8118],
            [81.6616, 23.9256],
            [81.5984, 23.8537],
            [81.6873, 23.7214],
            [81.6027, 23.6],
            [81.607, 23.5069],
            [81.736, 23.5674],
            [81.8272, 23.5113],
            [81.9164, 23.5321],
            [81.9761, 23.4131],
            [82.0985, 23.3977],
            [82.1859, 23.3254],
            [82.1511, 23.1417],
            [82.0241, 23.0801],
            [81.9381, 23.0778],
            [81.9402, 22.9571],
            [81.7694, 22.8735],
            [81.7627, 22.6636],
            [81.6565, 22.632],
            [81.6158, 22.539],
            [81.4799, 22.4933],
            [81.3229, 22.5243],
            [81.2184, 22.4516],
            [81.11, 22.4411],
            [81.1138, 22.2944],
            [81.0194, 22.2292],
            [80.9991, 22.0615],
            [80.9108, 22.1192],
            [80.8366, 21.9356],
            [80.8112, 21.7475],
            [80.7185, 21.7118],
            [80.7302, 21.472],
            [80.6565, 21.3307],
            [80.5932, 21.3248],
            [80.4113, 21.4382],
            [80.3706, 21.5209],
            [80.2602, 21.6202],
            [80.1882, 21.6342],
            [80.0636, 21.5546],
            [79.8571, 21.5309],
            [79.7326, 21.6025],
            [79.5366, 21.5442],
            [79.4886, 21.6741],
            [79.349, 21.6789],
            [79.2287, 21.7239],
            [79.0881, 21.5992],
            [78.9132, 21.5924],
            [78.9318, 21.4874],
            [78.5849, 21.4869],
            [78.4226, 21.602],
            [78.1809, 21.5587],
            [78.1693, 21.4985],
            [77.9388, 21.3869],
            [77.7994, 21.4123],
            [77.5844, 21.3645],
            [77.487, 21.3777],
            [77.4575, 21.5565],
            [77.567, 21.5298],
            [77.5713, 21.6271],
            [77.4755, 21.7715],
            [77.2862, 21.7627],
            [77.2832, 21.7242],
            [77.0723, 21.7229],
            [76.9011, 21.6008],
            [76.7953, 21.5975],
            [76.7842, 21.4665],
            [76.6245, 21.3351],
            [76.6234, 21.1926],
            [76.4874, 21.1961],
            [76.3764, 21.0773],
            [76.1726, 21.0846],
            [76.1727, 21.1883],
            [76.0987, 21.3739],
            [76.0524, 21.3527],
            [75.8881, 21.3996],
            [75.5481, 21.3725],
            [75.2199, 21.4109],
            [75.1151, 21.4596],
            [75.0577, 21.5649],
            [74.9019, 21.6302],
            [74.7911, 21.6106],
            [74.5589, 21.682],
            [74.5112, 21.7267],
            [74.5275, 21.9088],
            [74.3883, 22.0202],
            [74.2897, 21.9358],
            [74.146, 21.954],
            [74.0977, 22.0155],
            [74.1228, 22.2129],
            [74.0591, 22.2858],
            [74.072, 22.3602],
            [74.1911, 22.3219],
            [74.258, 22.4001],
            [74.1533, 22.5174],
            [74.2654, 22.6448],
            [74.3842, 22.6435],
            [74.4789, 22.8586],
            [74.4004, 22.8994],
            [74.3228, 23.0627],
            [74.3912, 23.112],
            [74.5123, 23.0888],
            [74.6692, 23.2014],
            [74.6463, 23.2597],
            [74.5183, 23.3287],
            [74.6134, 23.4614],
            [74.8454, 23.5542],
            [74.9406, 23.7349],
            [74.9057, 23.8739],
            [74.993, 24.0297],
            [74.8811, 24.2135],
            [74.7836, 24.3168],
            [74.8183, 24.462],
            [74.7119, 24.5109],
            [74.7993, 24.7957],
            [74.8928, 24.6554],
            [74.9999, 24.7085],
            [75.0071, 24.7961],
            [74.8586, 24.8131],
            [74.8236, 24.9285],
            [75.0434, 24.8593],
            [75.1189, 24.889],
            [75.1591, 25.0455],
            [75.3456, 25.0428],
            [75.3375, 24.9631],
            [75.2614, 24.8897],
            [75.3057, 24.8133],
            [75.2672, 24.7327],
            [75.4519, 24.6932],
            [75.6133, 24.6884],
            [75.7597, 24.7648],
            [75.9174, 24.5715],
            [75.909, 24.4562],
            [75.7911, 24.4752],
            [75.7269, 24.4052],
            [75.7735, 24.2209],
            [75.7435, 24.1397],
            [75.762, 23.998],
            [75.6692, 24.0336],
            [75.5838, 23.9919],
            [75.5144, 24.0487],
            [75.4565, 23.9208],
            [75.5825, 23.8004],
            [75.7291, 23.8582],
            [75.8786, 23.8846],
            [75.9758, 23.9329],
            [75.9602, 24.0254],
            [76.1347, 24.0953],
            [76.1213, 24.1964],
            [76.3269, 24.2536],
            [76.5314, 24.1634],
            [76.614, 24.2422],
            [76.8011, 24.1204],
            [76.8999, 24.1311],
            [76.9444, 24.2063],
            [76.8402, 24.3387],
            [76.8513, 24.4691],
            [77.0015, 24.4779],
            [77.0647, 24.5699],
            [77.0267, 24.7109],
            [76.8473, 24.7504],
            [76.8016, 24.8199],
            [76.9265, 24.8498],
            [76.8543, 25.0103],
            [76.9937, 25.0799],
            [77.0772, 25.058],
            [77.1696, 25.1137],
            [77.3029, 25.0839],
            [77.3985, 25.1133],
            [77.4064, 25.2263],
            [77.3053, 25.4364],
            [77.2052, 25.3114],
            [77.0755, 25.3397],
            [76.9587, 25.2972],
            [76.6811, 25.3455],
            [76.6025, 25.389],
            [76.5203, 25.5299],
            [76.5293, 25.798],
            [76.6461, 25.9089],
            [76.8063, 25.953],
            [76.8878, 26.0933],
            [76.986, 26.1324],
            [77.124, 26.238],
            [77.2031, 26.2374],
            [77.3347, 26.3572],
            [77.4324, 26.365],
            [77.6677, 26.5083],
            [77.813, 26.5548],
            [77.9922, 26.7027],
            [78.0857, 26.683],
            [78.0998, 26.797],
            [78.2098, 26.8361]
          ]
        ]
      },
      properties: {
        name: 'Madhya Pradesh',
        id: 'IN-MP',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-MP'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [92.2966, 24.2517],
            [92.4224, 24.2531],
            [92.4366, 24.1524],
            [92.5315, 24.1774],
            [92.6907, 24.3548],
            [92.7652, 24.5209],
            [92.829, 24.3778],
            [93.0005, 24.4032],
            [93.0434, 24.3434],
            [92.9764, 24.1148],
            [93.1009, 24.0451],
            [93.3374, 24.0534],
            [93.3987, 23.9303],
            [93.4053, 23.7616],
            [93.4447, 23.6824],
            [93.3658, 23.3545],
            [93.3936, 23.1386],
            [93.2977, 23.0058],
            [93.1329, 23.0475],
            [93.1388, 22.9132],
            [93.0917, 22.7093],
            [93.1444, 22.5989],
            [93.1117, 22.5318],
            [93.1908, 22.4273],
            [93.2086, 22.261],
            [93.1544, 22.1782],
            [93.0466, 22.1976],
            [93.0092, 21.9839],
            [92.8446, 22.0634],
            [92.7179, 22.1526],
            [92.6843, 22.0179],
            [92.6097, 21.9769],
            [92.6139, 22.1729],
            [92.5315, 22.5624],
            [92.5317, 22.6561],
            [92.4476, 22.8936],
            [92.3754, 22.9348],
            [92.3937, 23.0582],
            [92.3611, 23.2375],
            [92.3804, 23.3625],
            [92.3232, 23.4738],
            [92.2953, 23.6927],
            [92.2685, 23.7286],
            [92.2594, 23.815],
            [92.3115, 23.9606],
            [92.3368, 24.1468],
            [92.2966, 24.2517]
          ]
        ]
      },
      properties: {
        name: 'Mizoram',
        id: 'IN-MZ',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-MZ'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [95.1955, 27.0429],
            [95.2338, 26.8908],
            [95.2086, 26.8507],
            [95.2376, 26.6863],
            [95.0672, 26.4615],
            [95.1358, 26.3878],
            [95.1206, 26.1097],
            [95.1857, 26.0803],
            [95.0154, 25.872],
            [95.0526, 25.7626],
            [94.9376, 25.669],
            [94.905, 25.5671],
            [94.8125, 25.4954],
            [94.6824, 25.4597],
            [94.5574, 25.5127],
            [94.5557, 25.5858],
            [94.3135, 25.4945],
            [94.1387, 25.5237],
            [94.0147, 25.5961],
            [93.7706, 25.5403],
            [93.8089, 25.4656],
            [93.6928, 25.3616],
            [93.608, 25.202],
            [93.5051, 25.2441],
            [93.474, 25.3093],
            [93.4619, 25.4347],
            [93.3318, 25.5468],
            [93.4999, 25.6565],
            [93.5733, 25.7608],
            [93.7055, 25.8486],
            [93.7518, 25.9364],
            [93.8827, 25.8459],
            [93.9794, 25.9197],
            [93.9549, 26.0073],
            [94.0048, 26.1733],
            [94.108, 26.3264],
            [94.1755, 26.3442],
            [94.1865, 26.4605],
            [94.3994, 26.5323],
            [94.4079, 26.6143],
            [94.5451, 26.7109],
            [94.6866, 26.7326],
            [94.8022, 26.8061],
            [94.8862, 26.9325],
            [94.9857, 26.9182],
            [95.1101, 26.9669],
            [95.1955, 27.0429]
          ]
        ]
      },
      properties: {
        name: 'Nagaland',
        id: 'IN-NL',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-NL'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [84.0025, 22.5209],
              [84.1349, 22.4709],
              [84.1525, 22.401],
              [84.2892, 22.3373],
              [84.4261, 22.3488],
              [84.5266, 22.42],
              [84.8938, 22.418],
              [85.0625, 22.4784],
              [85.0705, 22.2721],
              [85.0237, 22.112],
              [85.1725, 22.0682],
              [85.2309, 22.0003],
              [85.3001, 22.1071],
              [85.3944, 22.1586],
              [85.5396, 22.0866],
              [85.6446, 22.0903],
              [85.7607, 21.9897],
              [85.9054, 21.9666],
              [86.0267, 22.1852],
              [85.9698, 22.2434],
              [86.022, 22.5205],
              [86.2798, 22.4455],
              [86.3527, 22.3454],
              [86.6456, 22.2614],
              [86.7228, 22.2153],
              [86.832, 22.0951],
              [86.9572, 22.0834],
              [87.0343, 21.9892],
              [87.0302, 21.8653],
              [87.2348, 21.9544],
              [87.2624, 21.8139],
              [87.4437, 21.7609],
              [87.482, 21.6087],
              [87.38, 21.5456],
              [87.2009, 21.5485],
              [86.9811, 21.4108],
              [86.8922, 21.3164],
              [86.8233, 21.1383],
              [86.97, 20.8219],
              [86.8221, 20.7584],
              [86.8188, 20.6389],
              [86.8286, 20.6723],
              [86.9339, 20.7107],
              [87, 20.6953],
              [86.7844, 20.5358],
              [86.7295, 20.4049],
              [86.7808, 20.3458],
              [86.68, 20.2633],
              [86.5536, 20.21],
              [86.3642, 19.9537],
              [85.6636, 19.7391],
              [85.3856, 19.6061],
              [85.1403, 19.4441],
              [84.9126, 19.2596],
              [84.761, 19.0713],
              [84.5918, 19.1239],
              [84.4894, 19.0209],
              [84.4294, 18.9137],
              [84.336, 18.8667],
              [84.3322, 18.792],
              [84.1514, 18.7757],
              [83.8702, 18.8184],
              [83.7871, 19.0084],
              [83.697, 19.0165],
              [83.6044, 19.0882],
              [83.478, 19.0212],
              [83.3053, 18.9897],
              [83.4113, 18.8593],
              [83.3563, 18.8001],
              [83.2017, 18.7422],
              [83.133, 18.7724],
              [83.051, 18.6542],
              [83.0172, 18.3845],
              [82.9327, 18.3514],
              [82.7992, 18.4394],
              [82.7671, 18.3311],
              [82.5807, 18.2701],
              [82.6005, 18.3687],
              [82.5352, 18.3896],
              [82.5231, 18.5039],
              [82.474, 18.5369],
              [82.3613, 18.4099],
              [82.3344, 18.3172],
              [82.3298, 18.0404],
              [82.2673, 17.9864],
              [82.1604, 18.0436],
              [82.0247, 18.0579],
              [81.9518, 17.9921],
              [81.7583, 17.8934],
              [81.6631, 17.8769],
              [81.6111, 17.8154],
              [81.385, 17.8066],
              [81.4773, 17.97],
              [81.528, 18.2591],
              [81.7621, 18.4113],
              [81.9545, 18.5867],
              [81.9677, 18.6906],
              [82.1612, 18.7909],
              [82.1727, 18.8953],
              [82.2396, 18.9104],
              [82.2121, 19.0906],
              [82.1517, 19.2655],
              [82.183, 19.4176],
              [82.1193, 19.4239],
              [82.0461, 19.5386],
              [82.0598, 19.7831],
              [81.8496, 19.908],
              [81.8665, 20.0361],
              [81.9414, 20.1022],
              [82.1785, 19.978],
              [82.2306, 19.9981],
              [82.3395, 19.83],
              [82.4424, 19.903],
              [82.7027, 19.831],
              [82.7075, 19.9902],
              [82.5984, 19.9856],
              [82.3909, 20.0596],
              [82.4136, 20.2022],
              [82.3938, 20.3355],
              [82.4292, 20.4258],
              [82.324, 20.5539],
              [82.3682, 20.6241],
              [82.3351, 20.7585],
              [82.3466, 20.8839],
              [82.4591, 20.8239],
              [82.4857, 20.9035],
              [82.6223, 21.0366],
              [82.6359, 21.1494],
              [82.8792, 21.1491],
              [82.9561, 21.1755],
              [83.1199, 21.101],
              [83.1901, 21.1355],
              [83.27, 21.375],
              [83.3938, 21.3994],
              [83.3346, 21.4955],
              [83.3807, 21.6127],
              [83.4841, 21.7416],
              [83.4909, 21.8145],
              [83.5845, 21.8437],
              [83.5352, 21.9635],
              [83.5384, 22.0554],
              [83.6457, 22.2246],
              [83.7528, 22.2428],
              [83.861, 22.3438],
              [84.0042, 22.3677],
              [84.0416, 22.4641],
              [84.0025, 22.5209]
            ]
          ],
          [
            [
              [86.9339, 20.7107],
              [86.8286, 20.6723],
              [86.8188, 20.6389],
              [86.8221, 20.7584],
              [86.9339, 20.7107]
            ]
          ]
        ]
      },
      properties: {
        name: 'Odisha',
        id: 'IN-OR',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-OR'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [75.8725, 32.5762],
            [75.851, 32.5079],
            [75.9322, 32.4168],
            [75.8439, 32.3793],
            [75.7364, 32.2738],
            [75.6226, 32.2347],
            [75.6898, 32.0615],
            [75.8945, 31.9498],
            [75.9218, 31.8172],
            [76.0027, 31.6466],
            [76.1535, 31.414],
            [76.1728, 31.3067],
            [76.2961, 31.3192],
            [76.3591, 31.4156],
            [76.4483, 31.306],
            [76.6354, 31.213],
            [76.6101, 31.0042],
            [76.7699, 30.9064],
            [76.8282, 30.7638],
            [76.6906, 30.7601],
            [76.7263, 30.6959],
            [76.8198, 30.6858],
            [76.9134, 30.5997],
            [76.8324, 30.4339],
            [76.7579, 30.4389],
            [76.584, 30.2565],
            [76.6389, 30.2059],
            [76.6267, 30.1057],
            [76.5011, 30.0766],
            [76.39, 30.1276],
            [76.255, 30.1046],
            [76.1909, 30.0164],
            [76.1846, 29.8886],
            [76.0356, 29.7467],
            [75.8793, 29.7471],
            [75.829, 29.8123],
            [75.7059, 29.8086],
            [75.5117, 29.76],
            [75.4443, 29.8079],
            [75.2806, 29.6101],
            [75.1578, 29.6627],
            [75.231, 29.7521],
            [75.1823, 29.8387],
            [75.0655, 29.8791],
            [74.9899, 29.8565],
            [74.9157, 29.9492],
            [74.8012, 29.9926],
            [74.6399, 29.9225],
            [74.5191, 29.9433],
            [73.8912, 29.9701],
            [73.9667, 30.1212],
            [73.9663, 30.195],
            [73.8713, 30.3686],
            [73.9837, 30.5005],
            [74.0926, 30.5753],
            [74.2322, 30.7196],
            [74.3161, 30.8476],
            [74.5381, 30.9935],
            [74.5854, 31.0952],
            [74.5046, 31.1385],
            [74.5111, 31.2723],
            [74.6421, 31.46],
            [74.5172, 31.7229],
            [74.6003, 31.886],
            [74.8091, 31.9551],
            [74.8724, 32.0505],
            [75.0049, 32.0287],
            [75.1162, 32.0828],
            [75.2391, 32.0871],
            [75.3631, 32.2347],
            [75.3223, 32.3373],
            [75.415, 32.3243],
            [75.7115, 32.4182],
            [75.8725, 32.5762]
          ]
        ]
      },
      properties: {
        name: 'Punjab',
        id: 'IN-PB',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-PB'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [79.8004, 11.8084],
              [79.7369, 11.9072],
              [79.8376, 11.9406],
              [79.8004, 11.8084]
            ]
          ],
          [
            [
              [79.8512, 10.8137],
              [79.7269, 10.8975],
              [79.8015, 10.9862],
              [79.8546, 10.976],
              [79.8512, 10.8137]
            ]
          ]
        ]
      },
      properties: {
        name: 'Puducherry',
        id: 'IN-PY',
        TYPE: 'Union Territory',
        CNTRY: 'India'
      },
      id: 'IN-PY'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [73.9663, 30.195],
            [73.9667, 30.1212],
            [73.8912, 29.9701],
            [74.5191, 29.9433],
            [74.5536, 29.8656],
            [74.4653, 29.7875],
            [74.6054, 29.7529],
            [74.5665, 29.5635],
            [74.6144, 29.5271],
            [74.5578, 29.4186],
            [74.5967, 29.3622],
            [74.7566, 29.356],
            [74.8411, 29.4041],
            [74.9271, 29.3646],
            [74.9531, 29.2819],
            [75.1082, 29.2268],
            [75.3957, 29.262],
            [75.3807, 29.0703],
            [75.4347, 29.0165],
            [75.5134, 28.8366],
            [75.5557, 28.6146],
            [75.6291, 28.546],
            [75.8892, 28.3937],
            [76.053, 28.2242],
            [75.9361, 28.0935],
            [76.0101, 28.0367],
            [75.9631, 27.8647],
            [76.0881, 27.8685],
            [76.1549, 27.999],
            [76.3768, 28.1381],
            [76.471, 28.1547],
            [76.5385, 28.0391],
            [76.7915, 28.1572],
            [76.8505, 28.2267],
            [76.9618, 28.144],
            [76.9186, 27.9976],
            [76.9388, 27.919],
            [76.8809, 27.6994],
            [76.9705, 27.6571],
            [77.0383, 27.82],
            [77.1265, 27.7765],
            [77.2758, 27.8066],
            [77.3423, 27.653],
            [77.3351, 27.53],
            [77.4296, 27.462],
            [77.4319, 27.3992],
            [77.5745, 27.3267],
            [77.673, 27.2004],
            [77.5574, 27.0369],
            [77.7036, 27.0003],
            [77.4225, 26.867],
            [77.481, 26.7878],
            [77.7158, 26.8813],
            [77.7534, 26.9378],
            [77.8901, 26.8895],
            [78.1113, 26.9532],
            [78.2622, 26.9235],
            [78.2098, 26.8361],
            [78.0998, 26.797],
            [78.0857, 26.683],
            [77.9922, 26.7027],
            [77.813, 26.5548],
            [77.6677, 26.5083],
            [77.4324, 26.365],
            [77.3347, 26.3572],
            [77.2031, 26.2374],
            [77.124, 26.238],
            [76.986, 26.1324],
            [76.8878, 26.0933],
            [76.8063, 25.953],
            [76.6461, 25.9089],
            [76.5293, 25.798],
            [76.5203, 25.5299],
            [76.6025, 25.389],
            [76.6811, 25.3455],
            [76.9587, 25.2972],
            [77.0755, 25.3397],
            [77.2052, 25.3114],
            [77.3053, 25.4364],
            [77.4064, 25.2263],
            [77.3985, 25.1133],
            [77.3029, 25.0839],
            [77.1696, 25.1137],
            [77.0772, 25.058],
            [76.9937, 25.0799],
            [76.8543, 25.0103],
            [76.9265, 24.8498],
            [76.8016, 24.8199],
            [76.8473, 24.7504],
            [77.0267, 24.7109],
            [77.0647, 24.5699],
            [77.0015, 24.4779],
            [76.8513, 24.4691],
            [76.8402, 24.3387],
            [76.9444, 24.2063],
            [76.8999, 24.1311],
            [76.8011, 24.1204],
            [76.614, 24.2422],
            [76.5314, 24.1634],
            [76.3269, 24.2536],
            [76.1213, 24.1964],
            [76.1347, 24.0953],
            [75.9602, 24.0254],
            [75.9758, 23.9329],
            [75.8786, 23.8846],
            [75.7291, 23.8582],
            [75.5825, 23.8004],
            [75.4565, 23.9208],
            [75.5144, 24.0487],
            [75.5838, 23.9919],
            [75.6692, 24.0336],
            [75.762, 23.998],
            [75.7435, 24.1397],
            [75.7735, 24.2209],
            [75.7269, 24.4052],
            [75.7911, 24.4752],
            [75.909, 24.4562],
            [75.9174, 24.5715],
            [75.7597, 24.7648],
            [75.6133, 24.6884],
            [75.4519, 24.6932],
            [75.2672, 24.7327],
            [75.3057, 24.8133],
            [75.2614, 24.8897],
            [75.3375, 24.9631],
            [75.3456, 25.0428],
            [75.1591, 25.0455],
            [75.1189, 24.889],
            [75.0434, 24.8593],
            [74.8236, 24.9285],
            [74.8586, 24.8131],
            [75.0071, 24.7961],
            [74.9999, 24.7085],
            [74.8928, 24.6554],
            [74.7993, 24.7957],
            [74.7119, 24.5109],
            [74.8183, 24.462],
            [74.7836, 24.3168],
            [74.8811, 24.2135],
            [74.993, 24.0297],
            [74.9057, 23.8739],
            [74.9406, 23.7349],
            [74.8454, 23.5542],
            [74.6134, 23.4614],
            [74.5183, 23.3287],
            [74.6463, 23.2597],
            [74.6692, 23.2014],
            [74.5123, 23.0888],
            [74.3912, 23.112],
            [74.3228, 23.0627],
            [74.246, 23.1882],
            [74.127, 23.1789],
            [74.1338, 23.2694],
            [74.0327, 23.3324],
            [73.8943, 23.3371],
            [73.8321, 23.4467],
            [73.7261, 23.4126],
            [73.5773, 23.6553],
            [73.4205, 23.7793],
            [73.3621, 23.8619],
            [73.4247, 23.9311],
            [73.4138, 24.0511],
            [73.2238, 24.0982],
            [73.0827, 24.1849],
            [73.17, 24.3507],
            [73.1083, 24.4263],
            [73.0003, 24.4706],
            [72.9239, 24.3263],
            [72.8681, 24.3658],
            [72.7325, 24.3612],
            [72.6973, 24.4572],
            [72.5446, 24.5069],
            [72.4647, 24.4071],
            [72.3579, 24.5525],
            [72.2951, 24.5384],
            [72.23, 24.6329],
            [72.1654, 24.6145],
            [72.0525, 24.7058],
            [71.9442, 24.6268],
            [71.8765, 24.6752],
            [71.6616, 24.6333],
            [71.4883, 24.6739],
            [71.2977, 24.6076],
            [71.1071, 24.6806],
            [70.9455, 24.9345],
            [70.8943, 25.1441],
            [70.7665, 25.2649],
            [70.6742, 25.3893],
            [70.6879, 25.5174],
            [70.666, 25.6972],
            [70.3997, 25.6638],
            [70.2796, 25.7013],
            [70.0961, 25.9443],
            [70.0826, 26.0739],
            [70.1727, 26.2451],
            [70.1677, 26.5507],
            [70.0662, 26.5993],
            [69.8793, 26.5811],
            [69.5077, 26.7484],
            [69.4837, 26.8062],
            [69.5132, 27.0169],
            [69.5781, 27.1655],
            [69.862, 27.4028],
            [70.0163, 27.556],
            [70.1569, 27.834],
            [70.3662, 28.0082],
            [70.5626, 28.0198],
            [70.6721, 27.9143],
            [70.6784, 27.8391],
            [70.7577, 27.7172],
            [70.8842, 27.6984],
            [71.2729, 27.8419],
            [71.4097, 27.8658],
            [71.6583, 27.8689],
            [71.8974, 27.9574],
            [71.9333, 28.1269],
            [71.9959, 28.2069],
            [72.1844, 28.358],
            [72.302, 28.6648],
            [72.3847, 28.764],
            [72.7113, 28.935],
            [72.9417, 29.0272],
            [73.0893, 29.2491],
            [73.2713, 29.557],
            [73.3914, 29.9379],
            [73.6112, 30.0224],
            [73.7872, 30.0664],
            [73.9663, 30.195]
          ]
        ]
      },
      properties: {
        name: 'Rajasthan',
        id: 'IN-RJ',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-RJ'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [88.7517, 27.1482],
            [88.6844, 27.1802],
            [88.53, 27.1502],
            [88.432, 27.0816],
            [88.3045, 27.1286],
            [88.0858, 27.1415],
            [88.017, 27.2189],
            [88.0826, 27.4264],
            [88.0463, 27.4958],
            [88.1999, 27.7905],
            [88.1239, 27.9509],
            [88.3898, 27.9781],
            [88.6165, 28.1037],
            [88.7511, 28.0733],
            [88.8329, 28.0115],
            [88.8466, 27.6689],
            [88.7671, 27.565],
            [88.8093, 27.4052],
            [88.9224, 27.3263],
            [88.8011, 27.2476],
            [88.7517, 27.1482]
          ]
        ]
      },
      properties: {
        name: 'Sikkim',
        id: 'IN-SK',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-SK'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [77.55, 18.2914],
            [77.5195, 18.3493],
            [77.601, 18.4742],
            [77.7365, 18.5548],
            [77.8375, 18.8075],
            [77.9426, 18.8223],
            [77.8537, 18.9523],
            [77.7674, 18.9814],
            [77.7617, 19.0621],
            [77.8584, 19.1808],
            [77.8609, 19.3021],
            [77.9489, 19.3413],
            [78.0457, 19.2708],
            [78.1701, 19.2363],
            [78.1822, 19.4117],
            [78.3089, 19.4564],
            [78.2697, 19.6631],
            [78.3453, 19.7136],
            [78.3977, 19.8364],
            [78.6037, 19.8175],
            [78.7164, 19.7668],
            [78.8456, 19.7584],
            [78.8588, 19.6564],
            [78.9689, 19.6492],
            [79.0184, 19.5399],
            [79.1778, 19.4607],
            [79.2439, 19.6125],
            [79.369, 19.5712],
            [79.4704, 19.4975],
            [79.5426, 19.5485],
            [79.7403, 19.6031],
            [79.9402, 19.4645],
            [79.973, 19.401],
            [79.9432, 19.1706],
            [79.8581, 19.1041],
            [79.9345, 19.0217],
            [79.932, 18.7844],
            [80.0124, 18.7667],
            [80.1108, 18.6762],
            [80.2675, 18.718],
            [80.3419, 18.5864],
            [80.4835, 18.6281],
            [80.6327, 18.5188],
            [80.7265, 18.409],
            [80.7881, 18.2541],
            [80.7346, 18.1717],
            [80.944, 18.081],
            [81.0325, 17.7897],
            [81.1596, 17.8526],
            [81.254, 17.8122],
            [81.385, 17.8066],
            [81.6111, 17.8154],
            [81.6631, 17.8769],
            [81.7583, 17.8934],
            [81.7824, 17.8319],
            [81.5766, 17.7261],
            [81.5029, 17.5899],
            [81.4934, 17.4488],
            [81.4157, 17.3616],
            [81.3247, 17.3906],
            [81.2672, 17.32],
            [81.1815, 17.3222],
            [81.1628, 17.2326],
            [80.9912, 17.1797],
            [80.9054, 17.2013],
            [80.8325, 17.0257],
            [80.5602, 17.138],
            [80.4822, 17.0503],
            [80.3582, 16.9707],
            [80.4878, 16.9175],
            [80.5842, 16.9265],
            [80.5628, 16.7628],
            [80.4564, 16.79],
            [80.3192, 16.871],
            [80.2622, 17.0106],
            [80.0459, 16.9653],
            [79.9917, 16.8632],
            [80.0697, 16.8136],
            [79.9581, 16.642],
            [79.7749, 16.7295],
            [79.648, 16.662],
            [79.4972, 16.6305],
            [79.4266, 16.5825],
            [79.2661, 16.5615],
            [79.2253, 16.5082],
            [79.2187, 16.2396],
            [79.0309, 16.2457],
            [78.9054, 16.1819],
            [78.775, 16.0236],
            [78.617, 16.0989],
            [78.5591, 16.0564],
            [78.4057, 16.081],
            [78.2567, 16.0313],
            [78.238, 15.9291],
            [78.1096, 15.8271],
            [77.8877, 15.8959],
            [77.7992, 15.8653],
            [77.6589, 15.8765],
            [77.5095, 15.9208],
            [77.4836, 16.1586],
            [77.5916, 16.3375],
            [77.2847, 16.403],
            [77.4642, 16.5842],
            [77.4534, 16.9091],
            [77.4994, 17.0073],
            [77.4626, 17.1041],
            [77.36, 17.1616],
            [77.4516, 17.3705],
            [77.6856, 17.5056],
            [77.4399, 17.5815],
            [77.4503, 17.6913],
            [77.562, 17.7471],
            [77.5497, 17.818],
            [77.6462, 17.9932],
            [77.55, 18.2914]
          ]
        ]
      },
      properties: {
        name: 'Telangana',
        id: 'IN-TG',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-TG'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [78.2207, 12.7526],
            [78.2904, 12.6529],
            [78.3683, 12.6118],
            [78.582, 12.7708],
            [78.6137, 12.9789],
            [78.7024, 13.0562],
            [78.876, 13.0862],
            [78.9796, 13.0765],
            [79.0691, 13.0141],
            [79.1527, 13.0073],
            [79.2183, 13.1356],
            [79.3079, 13.0969],
            [79.4108, 13.2121],
            [79.4289, 13.3236],
            [79.5358, 13.3111],
            [79.5803, 13.2454],
            [79.9255, 13.3359],
            [79.9618, 13.4513],
            [80.0694, 13.5376],
            [80.1521, 13.4789],
            [80.312, 13.4224],
            [80.3449, 13.2732],
            [80.299, 13.1393],
            [80.2549, 12.7832],
            [80.1571, 12.4698],
            [79.8849, 12.0649],
            [79.8376, 11.9406],
            [79.7369, 11.9072],
            [79.8004, 11.8084],
            [79.7571, 11.5637],
            [79.8382, 11.3549],
            [79.8546, 10.976],
            [79.8015, 10.9862],
            [79.7269, 10.8975],
            [79.8512, 10.8137],
            [79.8793, 10.2965],
            [79.7429, 10.2751],
            [79.3857, 10.3168],
            [79.2949, 10.2607],
            [79.229, 10.1443],
            [79.2674, 10.0379],
            [79.1551, 9.9324],
            [79.1218, 9.8546],
            [78.994, 9.7085],
            [78.8982, 9.4801],
            [78.9393, 9.4062],
            [79.0635, 9.3004],
            [78.6579, 9.1924],
            [78.5782, 9.1312],
            [78.4107, 9.1118],
            [78.209, 8.9593],
            [78.1687, 8.8821],
            [78.1693, 8.744],
            [78.1049, 8.6515],
            [78.1304, 8.4971],
            [78.0671, 8.3707],
            [77.804, 8.2482],
            [77.7468, 8.1773],
            [77.5757, 8.1357],
            [77.551, 8.0766],
            [77.316, 8.121],
            [77.0965, 8.298],
            [77.2652, 8.5375],
            [77.1553, 8.7375],
            [77.243, 8.8593],
            [77.1322, 9.0118],
            [77.2518, 9.143],
            [77.2668, 9.2909],
            [77.3962, 9.4966],
            [77.3043, 9.593],
            [77.1519, 9.606],
            [77.2082, 9.7217],
            [77.1991, 9.866],
            [77.2805, 10.2073],
            [77.163, 10.3463],
            [76.9708, 10.2086],
            [76.8197, 10.2952],
            [76.8073, 10.4149],
            [76.8297, 10.5851],
            [76.8975, 10.7708],
            [76.8175, 10.8618],
            [76.6492, 10.9242],
            [76.7387, 11.1213],
            [76.623, 11.1856],
            [76.4876, 11.2028],
            [76.5402, 11.2924],
            [76.4104, 11.4336],
            [76.2781, 11.4745],
            [76.2258, 11.5644],
            [76.3501, 11.5827],
            [76.4235, 11.6593],
            [76.5042, 11.698],
            [76.5601, 11.6101],
            [76.8165, 11.5972],
            [76.8997, 11.7833],
            [77.0044, 11.803],
            [77.1028, 11.7631],
            [77.2848, 11.7984],
            [77.4193, 11.7507],
            [77.4844, 11.9324],
            [77.6605, 11.9382],
            [77.7684, 12.1056],
            [77.7264, 12.1673],
            [77.4657, 12.2023],
            [77.6038, 12.3538],
            [77.6252, 12.4773],
            [77.5785, 12.507],
            [77.5903, 12.6576],
            [77.7314, 12.664],
            [77.7995, 12.7869],
            [77.9177, 12.8784],
            [77.9814, 12.7956],
            [78.0467, 12.8424],
            [78.1207, 12.77],
            [78.2207, 12.7526]
          ]
        ]
      },
      properties: {
        name: 'Tamil Nadu',
        id: 'IN-TN',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-TN'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [92.231, 24.5041],
            [92.2966, 24.2517],
            [92.3368, 24.1468],
            [92.3115, 23.9606],
            [92.2594, 23.815],
            [92.2685, 23.7286],
            [92.1631, 23.7397],
            [92.0707, 23.6447],
            [91.9423, 23.6818],
            [91.9752, 23.4825],
            [91.8473, 23.4002],
            [91.7749, 23.2781],
            [91.8354, 23.0954],
            [91.724, 22.9887],
            [91.5678, 22.9723],
            [91.4597, 23.2547],
            [91.3889, 23.1795],
            [91.3323, 23.2527],
            [91.2538, 23.4897],
            [91.1658, 23.6022],
            [91.1604, 23.7395],
            [91.2246, 23.7401],
            [91.2325, 23.8832],
            [91.378, 24.1095],
            [91.5452, 24.0761],
            [91.6341, 24.1026],
            [91.6602, 24.1876],
            [91.7435, 24.2471],
            [91.9099, 24.1423],
            [91.9741, 24.3886],
            [92.1192, 24.3838],
            [92.231, 24.5041]
          ]
        ]
      },
      properties: {
        name: 'Tripura',
        id: 'IN-TR',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-TR'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [77.563, 30.4045],
            [77.9315, 30.247],
            [77.8385, 30.1123],
            [77.7864, 30.1],
            [77.702, 29.8718],
            [77.829, 29.6693],
            [77.952, 29.7054],
            [77.988, 29.5459],
            [78.1391, 29.6761],
            [78.2314, 29.7041],
            [78.3317, 29.7962],
            [78.4876, 29.7414],
            [78.5273, 29.6244],
            [78.7855, 29.476],
            [78.9229, 29.4562],
            [78.7888, 29.3293],
            [78.9004, 29.1506],
            [79.027, 29.1723],
            [79.2989, 28.9531],
            [79.4014, 28.9323],
            [79.4112, 28.8559],
            [79.7745, 28.8918],
            [79.854, 28.8443],
            [79.9224, 28.7327],
            [80.0136, 28.7374],
            [80.0724, 28.8297],
            [80.1198, 28.8287],
            [80.4528, 28.6288],
            [80.5704, 28.6878],
            [80.7907, 28.5243],
            [81.0815, 28.3848],
            [81.2124, 28.3605],
            [81.3224, 28.1972],
            [81.4457, 28.1621],
            [81.4802, 28.0825],
            [81.704, 27.9865],
            [81.8869, 27.8572],
            [81.9701, 27.9288],
            [82.0697, 27.9234],
            [82.4507, 27.6786],
            [82.7099, 27.7217],
            [82.7579, 27.5866],
            [82.737, 27.5031],
            [82.9316, 27.5009],
            [83.0361, 27.4487],
            [83.1902, 27.454],
            [83.3068, 27.3313],
            [83.4117, 27.4125],
            [83.3909, 27.4795],
            [83.6167, 27.4694],
            [83.8585, 27.351],
            [83.898, 27.2594],
            [83.9788, 27.2004],
            [83.9394, 27.093],
            [84.0408, 27.0447],
            [84.052, 26.8917],
            [84.2523, 26.8087],
            [84.3257, 26.6843],
            [84.2722, 26.5994],
            [84.0818, 26.6428],
            [84.0478, 26.5435],
            [83.9022, 26.5181],
            [84.072, 26.3845],
            [84.1714, 26.374],
            [84.1593, 26.2459],
            [84.0182, 26.1451],
            [84.1673, 26.0255],
            [84.4233, 25.8924],
            [84.5286, 25.8777],
            [84.6202, 25.7941],
            [84.4884, 25.6779],
            [84.38, 25.7487],
            [84.3186, 25.672],
            [84.0765, 25.6375],
            [83.917, 25.5585],
            [83.7994, 25.411],
            [83.7325, 25.4106],
            [83.6224, 25.3295],
            [83.5395, 25.3177],
            [83.3353, 25.1814],
            [83.3574, 25.1242],
            [83.354, 24.8706],
            [83.3868, 24.7842],
            [83.5414, 24.6262],
            [83.4982, 24.5271],
            [83.393, 24.5012],
            [83.3759, 24.3146],
            [83.4019, 24.266],
            [83.3235, 24.1014],
            [83.1895, 23.9211],
            [83.0866, 23.8782],
            [82.9388, 23.8759],
            [82.8078, 23.963],
            [82.7084, 24.0808],
            [82.7641, 24.2925],
            [82.7072, 24.3852],
            [82.7628, 24.6457],
            [82.6579, 24.7004],
            [82.4016, 24.6838],
            [82.4092, 24.5978],
            [82.2299, 24.6697],
            [82.2384, 24.7544],
            [81.8964, 24.8925],
            [81.8861, 24.9928],
            [81.6819, 25.0437],
            [81.5664, 25.1957],
            [81.4729, 25.1371],
            [81.2691, 25.1677],
            [81.2616, 25.0685],
            [81.1614, 24.9585],
            [80.9552, 24.9257],
            [80.8349, 24.9345],
            [80.8707, 25.071],
            [80.7996, 25.1464],
            [80.6736, 25.0542],
            [80.5095, 25.1011],
            [80.2669, 25.0303],
            [80.3513, 25.1449],
            [80.4237, 25.1741],
            [80.304, 25.2889],
            [80.3107, 25.3873],
            [80.1939, 25.4162],
            [80.1233, 25.3384],
            [80.0207, 25.3432],
            [79.9967, 25.2686],
            [79.8471, 25.2402],
            [79.8559, 25.0985],
            [79.7456, 25.1439],
            [79.5979, 25.1315],
            [79.4899, 25.0827],
            [79.3991, 25.1135],
            [79.4153, 25.2592],
            [79.3459, 25.333],
            [79.2601, 25.3102],
            [79.2788, 25.1974],
            [79.2384, 25.1164],
            [79.1204, 25.1642],
            [79.0121, 25.2483],
            [78.8718, 25.166],
            [78.8377, 25.3522],
            [78.9164, 25.5208],
            [78.7565, 25.4761],
            [78.7644, 25.3584],
            [78.6035, 25.417],
            [78.5686, 25.2586],
            [78.622, 24.9649],
            [78.7645, 24.8614],
            [78.7769, 24.5931],
            [78.8817, 24.6397],
            [78.9844, 24.4413],
            [78.9669, 24.3536],
            [78.8789, 24.2231],
            [78.7603, 24.2585],
            [78.6691, 24.245],
            [78.5784, 24.357],
            [78.5054, 24.3939],
            [78.4348, 24.2977],
            [78.2669, 24.4473],
            [78.2685, 24.6671],
            [78.1654, 24.8818],
            [78.3275, 24.9996],
            [78.3278, 25.0888],
            [78.3854, 25.1952],
            [78.2955, 25.3748],
            [78.4087, 25.5329],
            [78.4863, 25.5822],
            [78.6486, 25.5656],
            [78.8052, 25.6245],
            [78.7468, 25.7491],
            [78.8205, 25.7994],
            [78.9443, 26.037],
            [79.0032, 26.0818],
            [79.037, 26.267],
            [79.1338, 26.345],
            [79.0609, 26.5033],
            [78.9913, 26.5823],
            [78.9932, 26.6846],
            [78.8649, 26.7047],
            [78.7153, 26.8002],
            [78.5659, 26.7577],
            [78.3897, 26.8249],
            [78.3514, 26.8744],
            [78.2098, 26.8361],
            [78.2622, 26.9235],
            [78.1113, 26.9532],
            [77.8901, 26.8895],
            [77.7534, 26.9378],
            [77.7158, 26.8813],
            [77.481, 26.7878],
            [77.4225, 26.867],
            [77.7036, 27.0003],
            [77.5574, 27.0369],
            [77.673, 27.2004],
            [77.5745, 27.3267],
            [77.4319, 27.3992],
            [77.4296, 27.462],
            [77.3351, 27.53],
            [77.3423, 27.653],
            [77.2758, 27.8066],
            [77.5192, 27.9319],
            [77.4791, 28.0442],
            [77.4927, 28.2806],
            [77.4745, 28.4135],
            [77.3377, 28.509],
            [77.3362, 28.6089],
            [77.1869, 28.8668],
            [77.2142, 29.006],
            [77.122, 29.1058],
            [77.1169, 29.3767],
            [77.1394, 29.4421],
            [77.0856, 29.6008],
            [77.1433, 29.706],
            [77.1802, 29.9062],
            [77.2704, 30.0484],
            [77.4144, 30.1071],
            [77.5749, 30.3845],
            [77.563, 30.4045]
          ]
        ]
      },
      properties: {
        name: 'Uttar Pradesh',
        id: 'IN-UP',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-UP'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [80.0724, 28.8297],
            [80.0136, 28.7374],
            [79.9224, 28.7327],
            [79.854, 28.8443],
            [79.7745, 28.8918],
            [79.4112, 28.8559],
            [79.4014, 28.9323],
            [79.2989, 28.9531],
            [79.027, 29.1723],
            [78.9004, 29.1506],
            [78.7888, 29.3293],
            [78.9229, 29.4562],
            [78.7855, 29.476],
            [78.5273, 29.6244],
            [78.4876, 29.7414],
            [78.3317, 29.7962],
            [78.2314, 29.7041],
            [78.1391, 29.6761],
            [77.988, 29.5459],
            [77.952, 29.7054],
            [77.829, 29.6693],
            [77.702, 29.8718],
            [77.7864, 30.1],
            [77.8385, 30.1123],
            [77.9315, 30.247],
            [77.563, 30.4045],
            [77.7992, 30.5114],
            [77.7295, 30.5984],
            [77.7791, 30.6638],
            [77.6883, 30.7687],
            [77.7894, 30.8585],
            [77.7964, 30.9698],
            [77.8875, 31.1547],
            [78.0877, 31.1908],
            [78.3563, 31.2909],
            [78.4701, 31.2038],
            [78.5954, 31.2364],
            [78.6477, 31.1947],
            [78.795, 31.2051],
            [78.8714, 31.1073],
            [78.9966, 31.1153],
            [78.9069, 31.2592],
            [79.0478, 31.4704],
            [79.1289, 31.4387],
            [79.2481, 31.2966],
            [79.2849, 31.1701],
            [79.3819, 31.1184],
            [79.4131, 31.0375],
            [79.5013, 31.0391],
            [79.5872, 30.9431],
            [79.7422, 31.0068],
            [79.8549, 30.9789],
            [79.915, 30.8922],
            [80.1573, 30.8127],
            [80.2373, 30.7377],
            [80.2157, 30.579],
            [80.4005, 30.5312],
            [80.5265, 30.4543],
            [80.5842, 30.4872],
            [80.7375, 30.3981],
            [80.9119, 30.2232],
            [80.8792, 30.1285],
            [80.6779, 29.9581],
            [80.6053, 29.9587],
            [80.5545, 29.8492],
            [80.3697, 29.7506],
            [80.4219, 29.6391],
            [80.3513, 29.5189],
            [80.2448, 29.4445],
            [80.3186, 29.3134],
            [80.2734, 29.142],
            [80.146, 29.103],
            [80.1373, 29.0073],
            [80.0601, 28.9163],
            [80.0724, 28.8297]
          ]
        ]
      },
      properties: {
        name: 'Uttarakhand',
        id: 'IN-UT',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-UT'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [88.017, 27.2189],
              [88.0858, 27.1415],
              [88.3045, 27.1286],
              [88.432, 27.0816],
              [88.53, 27.1502],
              [88.6844, 27.1802],
              [88.7517, 27.1482],
              [88.8699, 27.1087],
              [88.9624, 26.9241],
              [89.0903, 26.894],
              [89.1311, 26.8081],
              [89.2556, 26.8169],
              [89.3772, 26.8663],
              [89.4593, 26.8063],
              [89.5589, 26.8156],
              [89.7744, 26.6996],
              [89.857, 26.7038],
              [89.8573, 26.3845],
              [89.7505, 26.3215],
              [89.7188, 26.166],
              [89.6195, 26.178],
              [89.6527, 26.0636],
              [89.5424, 26.0054],
              [89.3525, 26.0105],
              [89.1624, 26.1387],
              [89.1444, 26.3208],
              [88.9644, 26.4631],
              [88.9113, 26.4121],
              [88.9919, 26.3048],
              [88.8192, 26.2597],
              [88.7492, 26.3532],
              [88.5266, 26.4817],
              [88.3775, 26.5935],
              [88.3676, 26.487],
              [88.4833, 26.4611],
              [88.4579, 26.3738],
              [88.3517, 26.2816],
              [88.3525, 26.22],
              [88.1804, 26.1457],
              [88.0868, 25.9125],
              [88.1171, 25.7966],
              [88.2718, 25.7799],
              [88.4567, 25.6605],
              [88.4464, 25.597],
              [88.6498, 25.477],
              [88.8391, 25.4696],
              [88.8437, 25.3624],
              [88.9567, 25.2593],
              [88.923, 25.1671],
              [88.7092, 25.2096],
              [88.5658, 25.1777],
              [88.4459, 25.1963],
              [88.4538, 25.0356],
              [88.3417, 24.939],
              [88.228, 24.9594],
              [88.1756, 24.861],
              [88.0706, 24.7694],
              [88.0106, 24.6667],
              [88.1478, 24.5056],
              [88.333, 24.3832],
              [88.4484, 24.377],
              [88.7347, 24.2752],
              [88.6995, 24.166],
              [88.7315, 23.913],
              [88.6017, 23.8168],
              [88.5617, 23.6421],
              [88.6449, 23.608],
              [88.7574, 23.419],
              [88.7203, 23.2527],
              [88.7967, 23.2156],
              [88.9135, 23.23],
              [88.9616, 23.1899],
              [88.87, 23.0961],
              [88.8734, 23.0044],
              [88.9675, 22.8426],
              [88.9192, 22.7617],
              [88.9593, 22.6907],
              [88.9376, 22.5699],
              [88.8689, 22.5459],
              [88.8461, 22.3883],
              [88.7998, 22.2878],
              [88.6819, 22.21],
              [88.6193, 22.1088],
              [88.5987, 21.9747],
              [88.4197, 21.9066],
              [88.2172, 21.7645],
              [88.1537, 21.9592],
              [88.0531, 22.0103],
              [87.9803, 21.8578],
              [87.7897, 21.695],
              [87.482, 21.6087],
              [87.4437, 21.7609],
              [87.2624, 21.8139],
              [87.2348, 21.9544],
              [87.0302, 21.8653],
              [87.0343, 21.9892],
              [86.9572, 22.0834],
              [86.832, 22.0951],
              [86.7228, 22.2153],
              [86.8001, 22.2136],
              [86.8862, 22.2941],
              [86.76, 22.5724],
              [86.6517, 22.5758],
              [86.6179, 22.6734],
              [86.5066, 22.7015],
              [86.4162, 22.7765],
              [86.4328, 22.9234],
              [86.2983, 23.0132],
              [86.2125, 22.9919],
              [86.0369, 23.1436],
              [85.915, 23.1296],
              [85.8264, 23.2631],
              [85.878, 23.3237],
              [85.866, 23.4702],
              [86.0472, 23.4936],
              [86.2432, 23.4196],
              [86.372, 23.4826],
              [86.358, 23.543],
              [86.4399, 23.6296],
              [86.5287, 23.6289],
              [86.6939, 23.6945],
              [86.792, 23.6873],
              [86.8208, 23.8113],
              [86.895, 23.88],
              [87.1242, 23.795],
              [87.2945, 23.8986],
              [87.238, 24.0412],
              [87.4827, 24.0136],
              [87.4936, 24.1152],
              [87.6896, 24.1498],
              [87.6377, 24.2116],
              [87.7563, 24.3036],
              [87.81, 24.4066],
              [87.8063, 24.5451],
              [87.8873, 24.5627],
              [87.889, 24.853],
              [87.9733, 24.8964],
              [87.877, 25.0281],
              [87.7765, 25.0913],
              [87.7876, 25.2205],
              [87.8565, 25.2778],
              [87.7591, 25.4051],
              [88.021, 25.587],
              [88.0486, 25.6906],
              [87.8985, 25.7698],
              [87.9109, 25.8518],
              [87.8228, 25.8715],
              [87.8421, 26.0445],
              [87.9681, 26.0813],
              [88.1445, 26.2528],
              [88.2919, 26.3539],
              [88.2262, 26.4762],
              [88.1061, 26.5356],
              [88.163, 26.6363],
              [88.1905, 26.7714],
              [88.1375, 26.9853],
              [87.9942, 27.1052],
              [88.017, 27.2189]
            ]
          ],
          [
            [
              [88.137, 21.875],
              [88.1707, 21.7424],
              [88.1092, 21.6258],
              [88.0367, 21.675],
              [88.137, 21.875]
            ]
          ]
        ]
      },
      properties: {
        name: 'West Bengal',
        id: 'IN-WB',
        TYPE: 'State',
        CNTRY: 'India'
      },
      id: 'IN-WB'
    }
  ]
}
export default map
