import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";

import { useTranslation } from "react-i18next";
import { cArea14MapReferences } from "../../config/constants";
import { introText, methodologyText } from "../../data/Area13";
import useGqlEntriesFoa from "../../gql/useGqlEntriesFoa";
import useGqlJurisdictions from "../../gql/useGqlJurisdictions";
import MainLayout from "../../layouts/MainLayout";

import "../../pages/Area.css";

import AreaContent from "../components/Areas/AreaContent"
import BarChart from "../components/Areas/A1-4/BarChart";
import LatestUpdates from "../components/Areas/LatestUpdates";
import MapContainer from "../components/Map/MapContainer";
import microstates from "../components/Map/microstates.json";
import Methodology from "../components/Methodology/Methodology";
import MapTooltip from "../components/StyledMapToolTips.js";
import TableContainer from "../components/Table/TableContainer";
import TalliesSection from "../components/TalliesSection/TalliesSection";
import MainSpinner from "../components/ui/MainSpinner";
import { citeA14, columns } from "../config/a14columns";
import useGqlTextComponents from '../../gql/useGqlTextComponents'
import IntroSection from '../components/Areas/IntroSection'

export default function Area14() {
  const htmlReferences = useRef();
  const [references, setReferences] = useState([]);
  const [tableEntries, setTableEntries] = useState([]);
  const [talliesNumbers, setTalliesNumbers] = useState([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const { textComponents } = useGqlTextComponents('A1-4')
  const { entriesFoa, loading, error } = useGqlEntriesFoa();
  const { jurisdictions, loading: loadingJur, error: errorJur, jurisdictionsById } = useGqlJurisdictions();
  const { t, i18n } = useTranslation();

  const tooltip = e => {
    return ReactDOMServer.renderToStaticMarkup();
  };

  useEffect(() => {
    console.log("AAA");
  }, []);
  useEffect(() => {
    if (!loading && !error && jurisdictions && entriesFoa) {
      let referencesTmp = [];

      for (let j of jurisdictions) {
        let entry = entriesFoa.find(e => e.motherEntry.jurisdiction.id === j.id);

        if (entry) referencesTmp.push(entry);
      }
      referencesTmp = entriesFoa;

      setReferences(
        referencesTmp.map(e => {
          return {
            id:
              e.motherEntry.subjurisdiction && e.motherEntry.subjurisdiction.id
                ? e.motherEntry.subjurisdiction.id
                : e.motherEntry.jurisdiction.a2_code,
            regions: e.motherEntry.jurisdiction.regions,
            microstate: microstates.find(ms => ms.A2 === e.motherEntry.jurisdiction.a2_code),
            reference:
              cArea14MapReferences().find(ml => ml.id === parseInt(e.barrier_value.id)) &&
              cArea14MapReferences().find(ml => ml.id === parseInt(e.barrier_value.id)).color,
            reference_text: "test",
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <MapTooltip
                entry={e}
                title={t("Legal barriers to freedom of association")}
                area={"A1-4"}
                extraProps={{
                  bgColor:
                    cArea14MapReferences().find(ml => ml.id === parseInt(e.barrier_value.id)) &&
                    cArea14MapReferences().find(ml => ml.id === parseInt(e.barrier_value.id)).color
                }}
                positive={e.barrier_value.id === "4"}
                hasSubjur={entriesFoa.find(
                  e2 =>
                    e2.motherEntry.jurisdiction.id === e.motherEntry.jurisdiction.id && e2.motherEntry.subjurisdiction
                )}
              />
            ), //tooltip(e),
            entry: e
          };
        })
      );

      let tmpTableEntries = [];
      let tmpTallyNumbers = [0, 0, 0];

      for (let entry of entriesFoa) {
        let is_na = entry.barrier_value.id === "6";

        if (!entry.motherEntry.subjurisdiction && entry.motherEntry.jurisdiction.un_member) {
          if (entry.barrier_value.id === "1" || entry.barrier_value.id === "2") {
            tmpTallyNumbers[1]++;
          } else if (entry.barrier_value.id === "3") {
            tmpTallyNumbers[2]++;
          }
        }
        tmpTallyNumbers[0] = tmpTallyNumbers[1] + tmpTallyNumbers[2];
        let subRows = [];

        if (!is_na || !subRows.length) {
          tmpTableEntries.push({
            ...entry,
            subRowsLength: subRows.length
          });
          tmpTableEntries = tmpTableEntries.concat(subRows);
        } else {
          //Hack to prevent showing the N/A
          if (subRows.length) {
            subRows[0] = {
              ...subRows[0],
              cid: subRows[0].cid.split(".")[0],
              subRowsLength: subRows.length - 1
            };
            delete subRows[0].cid;

            tmpTableEntries = tmpTableEntries.concat(subRows);
          }
        }
      }
      setTableEntries(tmpTableEntries);
      setTalliesNumbers(tmpTallyNumbers);
    }
  }, [jurisdictions, entriesFoa, error, loading]);

  const areaInfo = {
    title: `${t("Area")} 1`,
    subTitle: t("Legal Frameworks"),
    subSubTitle: `${t("Legal barriers to freedom of association")}`,
    areaName: "area-1"
  };

  return (
    <MainLayout section="areas">
      <div className="area__page area1__page A1-4">
        <section className="selectRegion__section">
          <MapContainer
            references={references}
            htmlReferences={htmlReferences}
            jurisdictionsById={jurisdictionsById}
            jurisdictions={jurisdictions}
            mapLegendItems={cArea14MapReferences()}
            mapLoaded={mapLoaded}
            setMapLoaded={setMapLoaded}
            extraMapLegendItemClass={i18n.language === "es" && "legend150"}
            currentArea={"A1-4"}
            title={areaInfo.title}
            subTitle={areaInfo.subTitle}
            subSubTitle={areaInfo.subSubTitle}
            areaName={areaInfo.areaName}
            modalTitle={areaInfo.subSubTitle}
                        modalContent={textComponents.length && textComponents[0].map}
          />
        </section>
        <div className="content__container">
          <TalliesSection area={"A1-4"} canStart={mapLoaded} replaceNumbers={talliesNumbers} />

          <AreaContent areaIntro={'A1-4'} areaLatest={'A1-4'} />

          <div className="mainCTA__charts">
            <section className="mainCTA__section">
              <div className="mainGraphic__section">
                <h5>{t("Legal barriers to freedom of association by region")}</h5>
                {references && references.length > 0 ? (
                  <BarChart references={references} />
                ) : (
                  <>
                    <MainSpinner text={t("Loading chart...")} />
                  </>
                )}
              </div>
            </section>
          </div>
        </div>
        <section className="table__section">
          <div className="content__container">
            {entriesFoa && entriesFoa.length > 0 && (
              <TableContainer
                entries={tableEntries}
                setEntries={setTableEntries}
                t={t}
                columns={columns()}
                citeEntry={citeA14}
                emptyFieldsNoCite={["explan"]}
                expandable={false}
                trMainClass={"a13Tr"}
                title={t("Area") + "1"}
                subtitle={
                  t("Legal Frameworks") +
                  " | " +
                  '<span style="font-weight:700">' +
                  t("Legal barriers to freedom of association") +
                  "</span>"
                }
                paginate={false}
                initialSortBy={[
                  {
                    id: "motherEntry.jurisdiction.name",
                    desc: false
                  }
                ]}
                showUNSwitch
              />
            )}
          </div>
        </section>
      </div>
    </MainLayout>
  );
}
