import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { cMainRegions } from "../../../../config/constants";
import UNMemberSwitch from "../../Charts/UNMemberSwitch";
import LoadingBar from "../../ui/LoadingBar";

export default function BarChart({ references }) {
  const [unMember, setUnMember] = useState(true);
  const { t, i18n } = useTranslation();
  const loading = useRef();
  const loadChart = () => {
    if (loading.current) loading.current.style.display = "block";
    am4core.options.queue = true;

    /* Chart code */
    // Themes begin
    am4core.unuseAllThemes();
    //
    // Themes end

    // Create chart instance
    let chart = am4core.create("barChartDiv", am4charts.XYChart);

    chart.colors.list = [am4core.color("#96C9DB"), am4core.color("#AB97CE")];

    let newReferences = references.filter(r => (unMember ? r.entry.motherEntry.jurisdiction.un_member : true));

    chart.events.on("ready", () => {
      setTimeout(() => {
        if (loading.current) loading.current.style.display = "none";
      }, 250);
    });

    const regions = cMainRegions();

    chart.data = [];
    let i = 0;
    for (let reg of regions) {
      chart.data = [...chart.data, { region: reg.name, yes: 0, no: 0 }];
      for (let ref of newReferences) {
        if (!ref.entry.motherEntry.subjurisdiction)
          if (ref.regions.find(r => r.id === reg.id)) {
            chart.data[i][ref.entry.marriage_type.id === "1" || ref.entry.civil_type.id === "1" ? "yes" : "no"]++;
          }
      }
      if (!chart.data[i]["yes"]) chart.data[i]["yes"] = null;
      i++;
    }

    chart.tooltip.label.maxWidth = 150;
    chart.tooltip.label.wrap = true;

    // Create axes
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "region";
    categoryAxis.renderer.grid.template.location = 0;

    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    categoryAxis.renderer.inversed = true;

    /*
    categoryAxis.renderer.labels.template.adapter.add("dy", function (dy, target) {
      if (target.dataItem && target.dataItem.index & (2 == 2)) {
        return dy + 15;
      }
      return dy;
    });*/

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;

    // Create series
    function createSeries(field, name) {
      // Set up series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "region";
      series.sequencedInterpolation = true;

      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color("#FFF");
      series.tooltip.background.fillOpacity = 1;
      series.tooltip.label.fill = am4core.color("#575757");
      // Make it stacked
      series.stacked = true;

      series.tooltip.pointerOrientation = "vertical";

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryY}: {valueX}";

      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueX}";
      labelBullet.locationX = 0.5;
      //labelBullet.label.hideOversized = true;

      if (field === "no") {
        labelBullet.label.text = "[bold]{valueX}[/]";
        series.fill = am4core.color("#d8a585");
        series.stroke = am4core.color("#d8a585");
      } else {
        labelBullet.label.text = "{valueX}";
        series.fill = am4core.color("#96C9DB");
        series.stroke = am4core.color("#96C9DB");
      }

      return series;
    }

    createSeries("yes", t("Same-sex unions"));
    createSeries("no", t("No same-sex unions"));

    // Legend
    chart.legend = new am4charts.Legend();
    //chart.legend.reverseOrder = true;
  };
  useEffect(() => {
    loadChart();
  }, [unMember]);
  return (
    <div style={{ minHeight: "400px", position: "relative" }}>
      <LoadingBar background="#f4f8f8" ref={loading} text={t("Loading chart") + "..."} />
      <div id="barChartDiv" style={{ minHeight: "400px" }} />
      <UNMemberSwitch unMember={unMember} setUnMember={setUnMember} />
    </div>
  );
}
