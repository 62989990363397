var map = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-69.8992, 12.4517], [-69.9421, 12.4385], [-70.0662, 12.5466], [-69.9733, 12.5677], [-69.8992, 12.4517]]
        ]
      },
      properties: { name: "Aruba", id: "AW", Continent: "Caribbean" },
      id: "AW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-61.7162, 17.0369], [-61.8596, 17.0132], [-61.8172, 17.1692], [-61.7162, 17.0369]]]
      },
      properties: { name: "Antigua and Barbuda", id: "AG", Continent: "Caribbean" },
      id: "AG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-63.0011, 18.2221], [-63.16, 18.1712], [-63.1534, 18.2002], [-63.0259, 18.27], [-63.0011, 18.2221]]
        ]
      },
      properties: { name: "Anguilla", id: "AI", Continent: "Caribbean" },
      id: "AI"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [[[-77.2258, 25.9038], [-77.2062, 26.4891], [-77.0383, 26.3331], [-77.1673, 26.2404], [-77.2258, 25.9038]]],
          [
            [
              [-77.7439, 24.7072],
              [-77.7452, 24.4637],
              [-78.0449, 24.2875],
              [-78.2987, 24.7537],
              [-78.0333, 25.1431],
              [-77.7439, 24.7072]
            ]
          ],
          [[[-77.6578, 24.2497], [-77.5187, 23.8696], [-77.7711, 23.7522], [-77.8522, 24.0405], [-77.6578, 24.2497]]],
          [[[-73.027, 21.1924], [-73.1646, 20.9788], [-73.6609, 20.9371], [-73.5232, 21.1906], [-73.027, 21.1924]]]
        ]
      },
      properties: { name: "Bahamas", id: "BS", Continent: "Caribbean" },
      id: "BS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-62.8321, 17.8767], [-62.8655, 17.9185], [-62.7996, 17.9088], [-62.8321, 17.8767]]]
      },
      properties: { name: "Saint Barthelemy", id: "BL", Continent: "Caribbean" },
      id: "BL"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-59.4934, 13.0823], [-59.6114, 13.1021], [-59.5916, 13.3178], [-59.4277, 13.1526], [-59.4934, 13.0823]]
        ]
      },
      properties: { name: "Barbados", id: "BB", Continent: "Caribbean" },
      id: "BB"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.8376, 23.1629],
              [-81.1786, 23.0596],
              [-80.6502, 23.1031],
              [-80.365, 22.9432],
              [-80.0752, 22.9423],
              [-79.6766, 22.7428],
              [-79.2756, 22.4075],
              [-78.6865, 22.3671],
              [-78.1431, 22.1095],
              [-77.8652, 21.9004],
              [-77.4974, 21.8718],
              [-76.6474, 21.2842],
              [-76.0735, 21.1335],
              [-75.7228, 21.1107],
              [-75.6429, 20.7336],
              [-74.8826, 20.6506],
              [-74.513, 20.3847],
              [-74.1674, 20.2924],
              [-74.2529, 20.0797],
              [-74.6348, 20.0582],
              [-75.1166, 19.9013],
              [-75.5519, 19.8912],
              [-76.1586, 19.9896],
              [-76.9995, 19.8929],
              [-77.7151, 19.8556],
              [-77.5538, 20.0823],
              [-77.2135, 20.3003],
              [-77.2297, 20.644],
              [-77.9974, 20.7152],
              [-78.4907, 21.0535],
              [-78.6366, 21.5158],
              [-78.823, 21.6191],
              [-79.1892, 21.5528],
              [-79.9104, 21.7426],
              [-80.4993, 22.0634],
              [-80.962, 22.0529],
              [-81.4412, 22.1838],
              [-81.8163, 22.2001],
              [-81.7572, 22.4668],
              [-81.9035, 22.6791],
              [-82.7383, 22.6892],
              [-83.3797, 22.2229],
              [-83.9006, 22.1697],
              [-84.0309, 21.943],
              [-84.3265, 22.0744],
              [-84.3612, 22.3789],
              [-84.045, 22.6663],
              [-83.2577, 22.9678],
              [-82.6658, 23.0434],
              [-82.1015, 23.1906],
              [-81.8376, 23.1629]
            ]
          ],
          [[[-82.5616, 21.5717], [-82.8532, 21.4438], [-83.0826, 21.7914], [-82.7145, 21.8903], [-82.5616, 21.5717]]]
        ]
      },
      properties: { name: "Cuba", id: "CU", Continent: "Caribbean" },
      id: "CU"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.7511, 12.0597],
            [-68.8032, 12.0456],
            [-68.995, 12.1419],
            [-69.1539, 12.2983],
            [-69.0768, 12.3423],
            [-69.0133, 12.2311],
            [-68.8274, 12.1586],
            [-68.7511, 12.0597]
          ]
        ]
      },
      properties: { name: "Curaçao", id: "CW", Continent: "Caribbean" },
      id: "CW"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-81.3696, 19.3489], [-81.2848, 19.3625], [-81.1305, 19.3467], [-81.3037, 19.272], [-81.3696, 19.3489]]
        ]
      },
      properties: { name: "Cayman Islands", id: "KY", Continent: "Caribbean" },
      id: "KY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-61.2818, 15.2492], [-61.481, 15.5252], [-61.3202, 15.5854], [-61.2818, 15.2492]]]
      },
      properties: { name: "Dominica", id: "DM", Continent: "Caribbean" },
      id: "DM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.7682, 18.0389],
            [-71.7618, 18.3412],
            [-72.0005, 18.5979],
            [-71.7432, 18.7328],
            [-71.657, 19.1305],
            [-71.7792, 19.7185],
            [-71.5579, 19.8956],
            [-70.9541, 19.914],
            [-70.1295, 19.6363],
            [-69.9568, 19.6719],
            [-69.7395, 19.2992],
            [-69.3229, 19.2012],
            [-68.6848, 18.9046],
            [-68.3594, 18.5381],
            [-68.6589, 18.2221],
            [-68.9348, 18.408],
            [-69.7707, 18.4436],
            [-70.1831, 18.2516],
            [-70.6447, 18.336],
            [-71.0279, 18.2731],
            [-71.3958, 17.6465],
            [-71.6318, 17.7735],
            [-71.7682, 18.0389]
          ]
        ]
      },
      properties: { name: "Dominican Republic", id: "DO", Continent: "Caribbean" },
      id: "DO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-61.3272, 16.2301], [-61.5221, 16.2279], [-61.5289, 16.434], [-61.4066, 16.4683], [-61.3272, 16.2301]]
        ]
      },
      properties: { name: "Guadeloupe", id: "GP", Continent: "Caribbean" },
      id: "GP"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-61.7155, 12.0127], [-61.7151, 12.1849], [-61.6072, 12.2232], [-61.6272, 12.054], [-61.7155, 12.0127]]
        ]
      },
      properties: { name: "Grenada", id: "GD", Continent: "Caribbean" },
      id: "GD"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.7792, 19.7185],
            [-71.657, 19.1305],
            [-71.7432, 18.7328],
            [-72.0005, 18.5979],
            [-71.7618, 18.3412],
            [-71.7682, 18.0389],
            [-72.06, 18.2287],
            [-72.8767, 18.1518],
            [-73.3852, 18.2511],
            [-73.7473, 18.1901],
            [-73.885, 18.042],
            [-74.419, 18.3461],
            [-74.2276, 18.6629],
            [-73.5915, 18.5223],
            [-72.7895, 18.4344],
            [-72.3478, 18.6748],
            [-72.8112, 19.0716],
            [-72.7034, 19.4407],
            [-73.0527, 19.6108],
            [-73.1178, 19.9039],
            [-72.637, 19.9009],
            [-72.22, 19.7444],
            [-71.7792, 19.7185]
          ]
        ]
      },
      properties: { name: "Haiti", id: "HT", Continent: "Caribbean" },
      id: "HT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.2614, 18.4572],
            [-76.9083, 18.39],
            [-76.3499, 18.1518],
            [-76.5246, 17.8666],
            [-76.8533, 17.9738],
            [-77.3616, 17.8337],
            [-77.7681, 17.8776],
            [-78.2167, 18.448],
            [-77.8735, 18.5218],
            [-77.2614, 18.4572]
          ]
        ]
      },
      properties: { name: "Jamaica", id: "JM", Continent: "Caribbean" },
      id: "JM"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-62.6307, 17.24], [-62.839, 17.3393], [-62.7946, 17.4026], [-62.7137, 17.3533], [-62.6307, 17.24]]
        ]
      },
      properties: { name: "Saint Kitts and Nevis", id: "KN", Continent: "Caribbean" },
      id: "KN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-60.8953, 13.8219], [-61.0636, 13.9159], [-60.8867, 14.0113], [-60.8953, 13.8219]]]
      },
      properties: { name: "Saint Lucia", id: "LC", Continent: "Caribbean" },
      id: "LC"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-63.0112, 18.0688], [-63.1231, 18.0688], [-63.0631, 18.1154], [-63.0112, 18.0688]]]
      },
      properties: { name: "Saint Martin", id: "MF", Continent: "Caribbean" },
      id: "MF"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-62.1484, 16.7403], [-62.1543, 16.6814], [-62.2217, 16.6994], [-62.2231, 16.7517], [-62.1484, 16.7403]]
        ]
      },
      properties: { name: "Montserrat", id: "MS", Continent: "Caribbean" },
      id: "MS"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-60.8263, 14.4947], [-61.0638, 14.467], [-61.2198, 14.8045], [-60.9185, 14.7351], [-60.8263, 14.4947]]
        ]
      },
      properties: { name: "Martinique", id: "MQ", Continent: "Caribbean" },
      id: "MQ"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.1296, 18.4449],
            [-65.6288, 18.3812],
            [-65.6209, 18.2424],
            [-65.9707, 17.9743],
            [-66.4084, 17.9506],
            [-67.1968, 17.9941],
            [-67.1586, 18.4994],
            [-66.1296, 18.4449]
          ]
        ]
      },
      properties: { name: "Puerto Rico", id: "PR", Continent: "Caribbean" },
      id: "PR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-63.1231, 18.0688], [-63.0112, 18.0688], [-63.0231, 18.0191], [-63.1231, 18.0688]]]
      },
      properties: { name: "Sint Maarten", id: "SX", Continent: "Caribbean" },
      id: "SX"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-71.6614, 21.765], [-71.8304, 21.7905], [-71.8062, 21.852], [-71.6684, 21.8336], [-71.6614, 21.765]]
        ]
      },
      properties: { name: "Turks and Caicos Islands", id: "TC", Continent: "Caribbean" },
      id: "TC"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.0121, 10.1344],
            [-61.1743, 10.0778],
            [-61.4993, 10.2689],
            [-61.4648, 10.5392],
            [-61.0339, 10.6701],
            [-61.0121, 10.1344]
          ]
        ]
      },
      properties: { name: "Trinidad and Tobago", id: "TT", Continent: "Caribbean" },
      id: "TT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-61.1745, 13.1579], [-61.2774, 13.2097], [-61.182, 13.3561], [-61.124, 13.2937], [-61.1745, 13.1579]]
        ]
      },
      properties: { name: "Saint Vincent and the Grenadines", id: "VC", Continent: "Caribbean" },
      id: "VC"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[[-64.3953, 18.4647], [-64.426, 18.5131], [-64.3245, 18.5175], [-64.3953, 18.4647]]]
      },
      properties: { name: "British Virgin Islands", id: "VG", Continent: "Caribbean" },
      id: "VG"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [[-64.7657, 17.7946], [-64.6862, 17.7062], [-64.889, 17.7018], [-64.8848, 17.7721], [-64.7657, 17.7946]]
        ]
      },
      properties: { name: "US Virgin Islands", id: "VI", Continent: "Caribbean" },
      id: "VI"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.2058, 12.1445],
            [-68.2873, 12.1718],
            [-68.3712, 12.2574],
            [-68.3695, 12.3018],
            [-68.2196, 12.2311],
            [-68.2058, 12.1445]
          ]
        ]
      },
      properties: { name: "Bonair, Saint Eustachius and Saba", id: "BQ", Continent: "Caribbean" },
      id: "BQ"
    },
    //CA
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.8941, 15.8908],
            [-89.2373, 15.8944],
            [-89.1615, 17.8148],
            [-88.2956, 18.4722],
            [-88.0972, 18.1215],
            [-88.2716, 17.61],
            [-88.3134, 16.6326],
            [-88.8941, 15.8908]
          ]
        ]
      },
      properties: { name: "Belize", id: "BZ", Continent: "Central America" },
      id: "BZ"
    },
    //CA DISABLED
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.642, 10.9171],
            [-83.4482, 10.4658],
            [-82.5636, 9.5768],
            [-82.94, 9.4493],
            [-82.8793, 8.0708],
            [-83.1626, 8.588],
            [-83.7341, 8.6144],
            [-83.6372, 9.0354],
            [-84.6589, 9.6471],
            [-85.1146, 9.5816],
            [-85.6248, 9.9024],
            [-85.8305, 10.3981],
            [-85.7443, 11.0621],
            [-83.642, 10.9171]
          ]
        ]
      },
      properties: { name: "Costa Rica", id: "CR", Continent: "Central America" },
      id: "CR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.1615, 17.8148],
            [-89.2373, 15.8944],
            [-88.8941, 15.8908],
            [-88.2284, 15.7291],
            [-89.2224, 14.866],
            [-89.3626, 14.416],
            [-90.0952, 13.7366],
            [-91.1461, 13.9256],
            [-92.2353, 14.5452],
            [-92.1872, 15.3209],
            [-91.7365, 16.0701],
            [-90.4472, 16.0728],
            [-90.4171, 16.3909],
            [-90.993, 17.2527],
            [-90.9892, 17.8161],
            [-89.1615, 17.8148]
          ]
        ]
      },
      properties: { name: "Guatemala", id: "GT", Continent: "Central America" },
      id: "GT"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.1577, 14.993],
            [-84.1144, 14.7311],
            [-84.9852, 14.7527],
            [-85.7868, 13.8443],
            [-86.1513, 13.9946],
            [-86.7336, 13.7634],
            [-86.7107, 13.3134],
            [-87.3374, 12.979],
            [-87.8144, 13.3991],
            [-87.7153, 13.8127],
            [-88.449, 13.8509],
            [-89.3626, 14.416],
            [-89.2224, 14.866],
            [-88.2284, 15.7291],
            [-87.6182, 15.9097],
            [-86.3567, 15.7832],
            [-85.7839, 16.0029],
            [-84.9738, 15.9902],
            [-84.2614, 15.8227],
            [-83.7604, 15.2202],
            [-83.1577, 14.993]
          ]
        ]
      },
      properties: { name: "Honduras", id: "HN", Continent: "Central America" },
      id: "HN"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.2956, 18.4722],
            [-89.1615, 17.8148],
            [-90.9892, 17.8161],
            [-90.993, 17.2527],
            [-90.4171, 16.3909],
            [-90.4472, 16.0728],
            [-91.7365, 16.0701],
            [-92.1872, 15.3209],
            [-92.2353, 14.5452],
            [-92.809, 15.1385],
            [-93.916, 16.0534],
            [-94.9004, 16.4177],
            [-95.4644, 15.9743],
            [-96.5107, 15.6518],
            [-97.7548, 15.9669],
            [-98.7623, 16.5346],
            [-99.6906, 16.7197],
            [-100.8479, 17.2004],
            [-101.847, 17.9224],
            [-102.6995, 18.0626],
            [-103.4416, 18.3254],
            [-103.9124, 18.8281],
            [-104.9386, 19.3093],
            [-105.6694, 20.3856],
            [-105.2369, 21.1195],
            [-105.2086, 21.4908],
            [-105.6492, 21.9878],
            [-105.7918, 22.6277],
            [-106.4024, 23.1959],
            [-106.9354, 23.881],
            [-107.9512, 24.6149],
            [-108.3735, 25.1941],
            [-109.3849, 25.7271],
            [-109.1997, 26.3054],
            [-109.9442, 27.0793],
            [-110.4779, 27.3228],
            [-110.5297, 27.8642],
            [-111.1214, 27.967],
            [-112.1619, 29.0186],
            [-113.1104, 30.7936],
            [-113.0467, 31.179],
            [-114.8397, 31.7986],
            [-114.8818, 31.1566],
            [-114.6498, 30.2381],
            [-113.7555, 29.3676],
            [-113.4998, 28.9268],
            [-112.8709, 28.4245],
            [-112.734, 27.8259],
            [-112.3291, 27.5232],
            [-112.0091, 26.9673],
            [-111.5697, 26.7075],
            [-111.2917, 25.79],
            [-110.6774, 24.7884],
            [-110.5471, 24.2141],
            [-110.0228, 24.1745],
            [-109.421, 23.4802],
            [-109.9235, 22.8861],
            [-110.3627, 23.605],
            [-112.0726, 24.8399],
            [-112.0557, 25.4881],
            [-112.3772, 26.2136],
            [-113.5986, 26.7212],
            [-114.4455, 27.2182],
            [-114.9937, 27.7359],
            [-114.3007, 27.873],
            [-114.0486, 28.4262],
            [-114.9937, 29.3847],
            [-115.6737, 29.7565],
            [-116.0622, 30.8041],
            [-116.6621, 31.5648],
            [-117.1283, 32.5334],
            [-114.7247, 32.7153],
            [-114.8359, 32.5083],
            [-111.0421, 31.3244],
            [-108.2145, 31.3293],
            [-108.2118, 31.7793],
            [-106.4534, 31.77],
            [-104.9179, 30.5835],
            [-104.4004, 29.5737],
            [-103.2577, 29.001],
            [-102.7343, 29.644],
            [-102.2689, 29.8712],
            [-101.4405, 29.7771],
            [-100.6587, 29.0683],
            [-100.2962, 28.3278],
            [-99.5054, 27.5482],
            [-99.4436, 27.0367],
            [-99.1079, 26.4469],
            [-97.3582, 25.8708],
            [-97.2251, 25.5852],
            [-97.6676, 24.3899],
            [-97.8579, 22.6246],
            [-97.6377, 21.6037],
            [-97.1213, 20.615],
            [-96.456, 19.8697],
            [-96.2895, 19.3436],
            [-95.821, 18.7548],
            [-95.1818, 18.7007],
            [-94.5462, 18.1751],
            [-93.5523, 18.4304],
            [-92.8848, 18.4687],
            [-91.9738, 18.7161],
            [-91.8031, 18.4704],
            [-90.7394, 19.352],
            [-90.5071, 19.9118],
            [-90.3531, 21.0096],
            [-89.8198, 21.2746],
            [-88.1317, 21.616],
            [-86.8241, 21.4214],
            [-86.8647, 20.8848],
            [-87.4672, 20.1021],
            [-87.4248, 19.5836],
            [-87.7619, 18.4463],
            [-88.2956, 18.4722]
          ]
        ]
      },
      properties: { name: "Mexico", id: "MX", Continent: "Central America" },
      id: "MX"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.1577, 14.993],
            [-83.1878, 14.34],
            [-83.5673, 13.3205],
            [-83.511, 12.4117],
            [-83.7692, 11.9314],
            [-83.8679, 11.2999],
            [-83.642, 10.9171],
            [-85.7443, 11.0621],
            [-86.4688, 11.7384],
            [-87.3374, 12.979],
            [-86.7107, 13.3134],
            [-86.7336, 13.7634],
            [-86.1513, 13.9946],
            [-85.7868, 13.8443],
            [-84.9852, 14.7527],
            [-84.1144, 14.7311],
            [-83.1577, 14.993]
          ]
        ]
      },
      properties: { name: "Nicaragua", id: "NI", Continent: "Central America" },
      id: "NI"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.8793, 8.0708],
            [-82.94, 9.4493],
            [-82.5636, 9.5768],
            [-82.2441, 9.0319],
            [-81.3549, 8.7805],
            [-80.1273, 9.2103],
            [-79.5775, 9.5979],
            [-78.0829, 9.2362],
            [-77.3741, 8.6583],
            [-77.2124, 8.0339],
            [-77.3508, 7.7056],
            [-78.1702, 7.5439],
            [-78.4215, 8.0607],
            [-78.1139, 8.3797],
            [-79.0864, 8.9971],
            [-79.4417, 9.0059],
            [-79.7506, 8.5955],
            [-80.3687, 8.2892],
            [-80.0752, 7.6669],
            [-80.4388, 7.2749],
            [-80.8455, 7.22],
            [-81.0352, 7.7109],
            [-81.5041, 7.7214],
            [-81.7277, 8.1376],
            [-82.6796, 8.3217],
            [-82.8793, 8.0708]
          ]
        ]
      },
      properties: { name: "Panama", id: "PA", Continent: "Central America" },
      id: "PA"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.3626, 14.416],
            [-88.449, 13.8509],
            [-87.7153, 13.8127],
            [-87.8144, 13.3991],
            [-88.512, 13.1838],
            [-90.0952, 13.7366],
            [-89.3626, 14.416]
          ]
        ]
      },
      properties: { name: "El Salvador", id: "SV", Continent: "Central America" },
      id: "SV"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-57.1948, 5.5483],
            [-57.3308, 5.0201],
            [-57.711, 4.9911],
            [-58.0544, 4.1719],
            [-56.7044, 2.0362],
            [-56.4829, 1.9422],
            [-57.119, 2.0142],
            [-57.5943, 1.7044],
            [-58.3407, 1.5875],
            [-58.8626, 1.2034],
            [-59.2313, 1.3757],
            [-59.7564, 1.9004],
            [-59.9942, 2.6901],
            [-59.8544, 3.5875],
            [-59.5512, 3.9333],
            [-59.8333, 4.476],
            [-60.1113, 4.5112],
            [-59.9906, 5.0829],
            [-60.7421, 5.202],
            [-61.3767, 5.9069],
            [-61.1286, 6.2141],
            [-61.1457, 6.6944],
            [-60.3952, 6.9453],
            [-60.719, 7.499],
            [-60.5136, 7.8133],
            [-60.0324, 8.0536],
            [-60.0174, 8.5493],
            [-59.2003, 8.0747],
            [-58.6266, 7.5461],
            [-58.4807, 7.0381],
            [-57.9826, 6.7858],
            [-57.1902, 6.0976],
            [-57.1948, 5.5483]
          ]
        ]
      },
      properties: { name: "Guyana", id: "GY", Continent: "South America" },
      id: "GY"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-54.156, 5.3589],
            [-54.4522, 5.0135],
            [-54.3509, 4.0542],
            [-53.9903, 3.5897],
            [-54.1953, 2.818],
            [-54.6163, 2.3267],
            [-55.0057, 2.593],
            [-56.1296, 2.2994],
            [-56.4829, 1.9422],
            [-56.7044, 2.0362],
            [-58.0544, 4.1719],
            [-57.711, 4.9911],
            [-57.3308, 5.0201],
            [-57.1948, 5.5483],
            [-56.9698, 5.9926],
            [-55.9395, 5.7957],
            [-55.8284, 5.9618],
            [-54.8339, 5.9882],
            [-54.0543, 5.808],
            [-54.156, 5.3589]
          ]
        ]
      },
      properties: { name: "Suriname", id: "SR", Continent: "South America" },
      id: "SR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-51.6527, 4.0612],
            [-51.9906, 3.7017],
            [-52.7006, 2.3636],
            [-52.9647, 2.1839],
            [-53.7678, 2.3552],
            [-54.1301, 2.121],
            [-54.6163, 2.3267],
            [-54.1953, 2.818],
            [-53.9903, 3.5897],
            [-54.3509, 4.0542],
            [-54.4522, 5.0135],
            [-54.156, 5.3589],
            [-53.8473, 5.7825],
            [-52.8992, 5.4248],
            [-52.2905, 4.9419],
            [-51.6527, 4.0612]
          ]
        ]
      },
      properties: { name: "French Guiana", id: "GF", Continent: "South America" },
      id: "GF"
    },
    //SA DISABLED
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.3197, 11.8619],
            [-71.958, 11.6664],
            [-72.2485, 11.1966],
            [-72.6902, 10.8363],
            [-73.0066, 9.7895],
            [-73.3564, 9.227],
            [-72.7963, 9.1088],
            [-72.4166, 8.3819],
            [-72.443, 7.4551],
            [-72.0844, 7.0965],
            [-71.0134, 6.9946],
            [-70.6553, 7.0829],
            [-70.095, 6.9379],
            [-69.4391, 6.135],
            [-68.4716, 6.1565],
            [-67.859, 6.2897],
            [-67.4394, 6.0256],
            [-67.825, 5.2706],
            [-67.8553, 4.5072],
            [-67.6615, 3.8643],
            [-67.311, 3.4161],
            [-67.8347, 2.8927],
            [-67.2109, 2.39],
            [-66.8762, 1.2232],
            [-67.3519, 2.0859],
            [-68.1766, 1.7198],
            [-69.8487, 1.7088],
            [-69.8522, 1.0593],
            [-69.1633, 0.8642],
            [-70.0539, 0.5785],
            [-70.0706, -0.1386],
            [-69.6676, -0.4823],
            [-69.4002, -1.1947],
            [-69.9658, -4.2357],
            [-70.3395, -3.8142],
            [-70.735, -3.7817],
            [-70.0741, -2.7503],
            [-70.9146, -2.2186],
            [-71.3969, -2.3342],
            [-71.7526, -2.1527],
            [-72.3957, -2.4286],
            [-73.1545, -2.2783],
            [-73.1969, -1.8305],
            [-73.4963, -1.6934],
            [-73.6644, -1.2487],
            [-74.2465, -0.9705],
            [-74.8017, -0.2002],
            [-75.2845, -0.1061],
            [-76.311, 0.4485],
            [-76.4945, 0.2353],
            [-77.4229, 0.4252],
            [-77.4677, 0.6361],
            [-78.3121, 1.0461],
            [-78.8597, 1.4552],
            [-78.5769, 1.7739],
            [-78.6171, 2.3065],
            [-78.1201, 2.4884],
            [-77.6701, 2.8786],
            [-77.1667, 3.8621],
            [-77.4273, 4.0603],
            [-77.2862, 4.7217],
            [-77.4018, 5.416],
            [-77.2493, 5.7799],
            [-77.4694, 6.1772],
            [-77.36, 6.5046],
            [-77.901, 7.2292],
            [-76.852, 8.0905],
            [-76.8878, 8.6197],
            [-76.2768, 8.9888],
            [-76.1355, 9.2656],
            [-75.6393, 9.4502],
            [-75.671, 10.1964],
            [-75.446, 10.6108],
            [-74.8446, 11.1096],
            [-74.4544, 10.9892],
            [-74.0593, 11.3407],
            [-73.3135, 11.2959],
            [-72.2749, 11.8892],
            [-71.7146, 12.42],
            [-71.2622, 12.3352],
            [-71.3197, 11.8619]
          ]
        ]
      },
      properties: { name: "Colombia", id: "CO", Continent: "South America" },
      id: "CO"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.9658, -4.2359],
            [-70.7995, -4.1733],
            [-70.9737, -4.3504],
            [-71.8448, -4.5044],
            [-72.6987, -5.0673],
            [-72.8871, -5.1228],
            [-72.9799, -5.635],
            [-73.2355, -6.0984],
            [-73.1374, -6.466],
            [-73.758, -6.9059],
            [-73.7496, -7.3356],
            [-74.0021, -7.5562],
            [-73.5491, -8.3459],
            [-72.9741, -8.993],
            [-73.2093, -9.4116],
            [-72.379, -9.5102],
            [-72.1815, -10.0038],
            [-71.238, -9.9661],
            [-70.637, -9.4782],
            [-70.6423, -11.0101],
            [-70.2202, -11.0478],
            [-69.5785, -10.9519],
            [-68.6852, -12.502],
            [-68.9786, -12.8802],
            [-69.0741, -13.6828],
            [-68.8803, -14.199],
            [-69.235, -14.5972],
            [-69.3748, -14.963],
            [-69.1726, -15.2368],
            [-69.4185, -15.6035],
            [-69.2176, -16.149],
            [-68.9134, -16.2618],
            [-69.0208, -16.6422],
            [-69.6249, -17.2002],
            [-69.5108, -17.506],
            [-69.8522, -17.704],
            [-69.9263, -18.206],
            [-70.4184, -18.3454],
            [-71.337, -17.6827],
            [-71.5323, -17.2943],
            [-72.1114, -17.0026],
            [-72.4676, -16.7079],
            [-73.825, -16.153],
            [-74.1471, -15.9124],
            [-75.1043, -15.4117],
            [-75.5336, -14.899],
            [-75.934, -14.6336],
            [-76.2891, -14.1333],
            [-76.1839, -13.5154],
            [-76.8322, -12.3489],
            [-77.1527, -12.0603],
            [-77.2204, -11.6635],
            [-77.6332, -11.288],
            [-77.7362, -10.8365],
            [-78.1856, -10.089],
            [-78.7622, -8.6171],
            [-79.3772, -7.8354],
            [-79.7621, -7.0666],
            [-80.1103, -6.6497],
            [-80.8117, -6.2822],
            [-81.1805, -5.9426],
            [-80.8827, -5.7589],
            [-81.1084, -5.0278],
            [-81.3366, -4.6695],
            [-81.2833, -4.3224],
            [-80.7986, -3.7312],
            [-80.3247, -3.388],
            [-80.1793, -3.8776],
            [-80.51, -4.0694],
            [-80.4885, -4.3939],
            [-80.1396, -4.2962],
            [-79.6385, -4.4547],
            [-79.3308, -4.9279],
            [-79.0763, -4.9905],
            [-78.4198, -3.7769],
            [-78.3472, -3.4311],
            [-77.8607, -2.9819],
            [-76.679, -2.5628],
            [-76.0898, -2.1331],
            [-75.5706, -1.5312],
            [-75.2632, -0.5555],
            [-75.2845, -0.1063],
            [-74.8018, -0.2],
            [-74.4178, -0.5809],
            [-74.2465, -0.9706],
            [-73.6643, -1.2489],
            [-73.4963, -1.6932],
            [-73.1969, -1.8304],
            [-73.1545, -2.2783],
            [-72.3957, -2.4288],
            [-71.7525, -2.1527],
            [-71.3969, -2.3342],
            [-70.9146, -2.2184],
            [-70.0741, -2.7502],
            [-70.7351, -3.7818],
            [-70.3396, -3.8142],
            [-69.9658, -4.2359]
          ]
        ]
      },
      properties: { name: "Peru", id: "PE" },
      id: "PE"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.0174, 8.5493],
            [-60.0324, 8.0536],
            [-60.5136, 7.8133],
            [-60.719, 7.499],
            [-60.3952, 6.9453],
            [-61.1457, 6.6944],
            [-61.1286, 6.2141],
            [-61.3767, 5.9069],
            [-60.7421, 5.202],
            [-60.6046, 4.9946],
            [-61.0027, 4.5354],
            [-62.1532, 4.0981],
            [-62.7122, 4.0177],
            [-62.9686, 3.5941],
            [-63.2949, 3.9223],
            [-64.568, 3.8999],
            [-64.2213, 3.5875],
            [-64.2188, 3.2047],
            [-63.9242, 2.4524],
            [-63.394, 2.2225],
            [-64.0354, 1.9044],
            [-64.1149, 1.6192],
            [-65.1037, 1.1081],
            [-66.4293, 0.822],
            [-66.8762, 1.2232],
            [-67.2109, 2.39],
            [-67.8347, 2.8927],
            [-67.311, 3.4161],
            [-67.6615, 3.8643],
            [-67.8553, 4.5072],
            [-67.825, 5.2706],
            [-67.4394, 6.0256],
            [-67.859, 6.2897],
            [-68.4716, 6.1565],
            [-69.4391, 6.135],
            [-70.095, 6.9379],
            [-70.6553, 7.0829],
            [-71.0134, 6.9946],
            [-72.0844, 7.0965],
            [-72.443, 7.4551],
            [-72.4166, 8.3819],
            [-72.7963, 9.1088],
            [-73.3564, 9.227],
            [-73.0066, 9.7895],
            [-72.6902, 10.8363],
            [-72.2485, 11.1966],
            [-71.958, 11.6664],
            [-71.3197, 11.8619],
            [-71.9468, 11.4146],
            [-71.5942, 10.657],
            [-72.113, 9.8154],
            [-71.6869, 9.0723],
            [-71.298, 9.1259],
            [-71.0528, 9.706],
            [-71.3868, 10.2641],
            [-71.4696, 10.9641],
            [-70.2325, 11.3733],
            [-70.2866, 11.8857],
            [-70.004, 12.1779],
            [-69.6316, 11.4801],
            [-68.8278, 11.4317],
            [-68.3987, 11.161],
            [-68.2339, 10.5691],
            [-67.8716, 10.4719],
            [-66.2471, 10.6323],
            [-65.8518, 10.2575],
            [-64.8503, 10.098],
            [-63.8734, 10.664],
            [-62.7025, 10.7501],
            [-62.8429, 10.4179],
            [-62.5151, 10.1762],
            [-60.7924, 9.3606],
            [-61.0532, 9.0951],
            [-61.3039, 8.41],
            [-60.801, 8.5924],
            [-60.0174, 8.5493]
          ]
        ]
      },
      properties: { name: "Venezuela", id: "VE", Continent: "South America" },
      id: "VE"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-56.4829, 1.9422],
              [-56.1296, 2.2994],
              [-55.0057, 2.593],
              [-54.6163, 2.3267],
              [-54.1301, 2.121],
              [-53.7678, 2.3552],
              [-52.9647, 2.1839],
              [-52.7006, 2.3636],
              [-51.9906, 3.7017],
              [-51.6527, 4.0612],
              [-51.2198, 4.0937],
              [-51.0524, 3.2821],
              [-50.6591, 2.1307],
              [-50.4589, 1.8297],
              [-49.9573, 1.66],
              [-49.8988, 1.1626],
              [-50.2945, 0.8356],
              [-50.8164, 0.1725],
              [-51.2829, -0.085],
              [-51.7026, -0.7622],
              [-51.9475, -1.5866],
              [-50.9177, -1.1151],
              [-50.6901, -1.7615],
              [-50.4033, -2.0156],
              [-49.7195, -1.9263],
              [-49.3137, -1.7317],
              [-48.9913, -1.8301],
              [-48.1151, -0.7376],
              [-47.3981, -0.6264],
              [-46.8112, -0.7798],
              [-45.6447, -1.348],
              [-45.0763, -1.4662],
              [-44.6513, -1.7457],
              [-44.3083, -2.5354],
              [-44.1128, -2.5983],
              [-42.9368, -2.4651],
              [-42.2497, -2.7921],
              [-41.8762, -2.7468],
              [-41.3181, -2.9362],
              [-40.4745, -2.7956],
              [-39.9648, -2.8615],
              [-38.4757, -3.7176],
              [-38.049, -4.2163],
              [-37.1747, -4.9124],
              [-36.5906, -5.0974],
              [-35.5494, -5.1295],
              [-35.2356, -5.5668],
              [-34.8056, -7.2886],
              [-34.8346, -7.9715],
              [-35.3408, -9.2305],
              [-36.3982, -10.4843],
              [-36.9378, -10.8204],
              [-37.412, -11.4972],
              [-37.6886, -12.0997],
              [-38.2397, -12.8441],
              [-38.7879, -12.7826],
              [-39.0894, -13.5881],
              [-38.9422, -14.0306],
              [-39.0597, -14.6551],
              [-38.8804, -15.8645],
              [-39.2153, -17.3156],
              [-39.154, -17.704],
              [-39.4869, -17.9901],
              [-39.74, -18.6396],
              [-39.7, -19.2782],
              [-40.3959, -20.5693],
              [-40.7892, -20.9063],
              [-41.0471, -21.5057],
              [-41.1227, -22.0845],
              [-41.7054, -22.3099],
              [-42.0423, -22.9472],
              [-43.5327, -23.046],
              [-43.866, -22.9102],
              [-44.6372, -23.0557],
              [-45.4232, -23.6854],
              [-45.9721, -23.7957],
              [-46.8672, -24.2365],
              [-47.8767, -24.9976],
              [-48.5451, -25.8159],
              [-48.6776, -26.7032],
              [-48.5543, -27.1962],
              [-48.6209, -28.0756],
              [-48.7997, -28.5752],
              [-49.7461, -29.3632],
              [-50.2994, -30.4258],
              [-51.1572, -30.3642],
              [-51.4637, -31.0529],
              [-51.9724, -31.3838],
              [-52.1273, -32.1678],
              [-52.6522, -33.1376],
              [-53.3707, -33.7423],
              [-53.5313, -33.171],
              [-53.1255, -32.7368],
              [-53.7618, -32.057],
              [-54.5876, -31.4848],
              [-55.8736, -31.0696],
              [-56.1762, -30.6284],
              [-56.8327, -30.1072],
              [-57.609, -30.188],
              [-56.9386, -29.5948],
              [-55.7255, -28.2043],
              [-55.1015, -27.8668],
              [-54.829, -27.5509],
              [-53.8381, -27.1211],
              [-53.6713, -26.225],
              [-53.8912, -25.6687],
              [-54.6159, -25.576],
              [-54.2419, -24.0471],
              [-54.6254, -23.8124],
              [-55.3663, -23.9913],
              [-55.8492, -22.3077],
              [-56.5238, -22.1025],
              [-56.9373, -22.2713],
              [-57.9856, -22.0463],
              [-57.8303, -20.9982],
              [-58.1599, -20.1645],
              [-58.1315, -19.7444],
              [-57.8006, -19.0808],
              [-57.5741, -18.2793],
              [-57.8325, -17.5124],
              [-58.3961, -17.2343],
              [-58.538, -16.3285],
              [-60.1756, -16.2692],
              [-60.2989, -14.6186],
              [-60.5066, -13.7898],
              [-61.077, -13.4897],
              [-61.79, -13.5257],
              [-62.176, -13.1337],
              [-62.7653, -12.9975],
              [-63.0675, -12.6692],
              [-64.4205, -12.4398],
              [-64.9925, -11.9753],
              [-65.39, -11.2463],
              [-65.3961, -9.7126],
              [-66.7301, -9.9754],
              [-68.398, -11.0186],
              [-69.5786, -10.9518],
              [-70.6423, -11.0103],
              [-70.637, -9.4783],
              [-71.238, -9.9661],
              [-72.1815, -10.0039],
              [-72.379, -9.51],
              [-73.2094, -9.4115],
              [-72.9741, -8.9932],
              [-73.5491, -8.3459],
              [-74.0022, -7.5562],
              [-73.7495, -7.3356],
              [-73.758, -6.9058],
              [-73.1375, -6.4659],
              [-73.2355, -6.0985],
              [-72.887, -5.1229],
              [-71.8449, -4.5046],
              [-70.9737, -4.3504],
              [-70.7994, -4.1733],
              [-69.9658, -4.2357],
              [-69.4002, -1.1947],
              [-69.6676, -0.4823],
              [-70.0706, -0.1386],
              [-70.0539, 0.5785],
              [-69.1633, 0.8642],
              [-69.8522, 1.0593],
              [-69.8487, 1.7088],
              [-68.1766, 1.7198],
              [-67.3519, 2.0859],
              [-66.8762, 1.2232],
              [-66.4293, 0.822],
              [-65.1037, 1.1081],
              [-64.1149, 1.6192],
              [-64.0354, 1.9044],
              [-63.394, 2.2225],
              [-63.9242, 2.4524],
              [-64.2188, 3.2047],
              [-64.2213, 3.5875],
              [-64.568, 3.8999],
              [-63.2949, 3.9223],
              [-62.9686, 3.5941],
              [-62.7122, 4.0177],
              [-62.1532, 4.0981],
              [-61.0027, 4.5354],
              [-60.6046, 4.9946],
              [-60.7421, 5.202],
              [-59.9906, 5.0829],
              [-60.1113, 4.5112],
              [-59.8333, 4.476],
              [-59.5512, 3.9333],
              [-59.8544, 3.5875],
              [-59.9942, 2.6901],
              [-59.7564, 1.9004],
              [-59.2313, 1.3757],
              [-58.8626, 1.2034],
              [-58.3407, 1.5875],
              [-57.5943, 1.7044],
              [-57.119, 2.0142],
              [-56.4829, 1.9422]
            ]
          ],
          [
            [
              [-49.6288, -0.2292],
              [-49.1168, -0.1637],
              [-48.3798, -0.3527],
              [-48.9289, -1.4825],
              [-49.5879, -1.7123],
              [-50.5077, -1.7879],
              [-50.7597, -1.2404],
              [-50.6454, -0.2727],
              [-50.2482, -0.1167],
              [-49.6288, -0.2292]
            ]
          ],
          [[[-51.8326, -1.4337], [-51.5461, -0.6497], [-51.2765, -1.0215], [-51.8326, -1.4337]]]
        ]
      },
      properties: { name: "Brazil", id: "BR", Continent: "South America" },
      id: "BR"
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.2845, -0.1061],
            [-75.2632, -0.5557],
            [-75.5706, -1.5313],
            [-76.0898, -2.1333],
            [-76.6791, -2.5627],
            [-77.8608, -2.9819],
            [-78.3473, -3.431],
            [-78.4198, -3.7769],
            [-79.0763, -4.9907],
            [-79.3307, -4.9278],
            [-79.6384, -4.4545],
            [-80.51, -4.0696],
            [-80.1793, -3.8775],
            [-80.3248, -3.388],
            [-79.9216, -3.09],
            [-79.73, -2.5794],
            [-80.45, -2.626],
            [-80.7704, -2.0766],
            [-80.9025, -1.0791],
            [-80.554, -0.8479],
            [-80.4823, -0.368],
            [-80.1334, -0.0059],
            [-80.0884, 0.7846],
            [-78.8996, 1.2061],
            [-78.8597, 1.4552],
            [-78.3121, 1.0461],
            [-77.4677, 0.6361],
            [-77.4229, 0.4252],
            [-76.4945, 0.2353],
            [-76.311, 0.4485],
            [-75.2845, -0.1061]
          ]
        ]
      },
      properties: { name: "Ecuador", id: "EC", Continent: "South America" },
      id: "EC"
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-134.6801, 58.1617],
              [-134.24, 58.1441],
              [-133.9955, 57.7786],
              [-133.9253, 57.3367],
              [-134.4354, 57.0569],
              [-134.487, 57.4819],
              [-134.66, 57.6378],
              [-134.6801, 58.1617]
            ]
          ],
          [
            [
              [-135.7304, 58.2442],
              [-135.0019, 58.0509],
              [-135.0849, 57.5111],
              [-135.498, 57.6621],
              [-136.0766, 57.6748],
              [-136.5686, 57.9719],
              [-136.5123, 58.0959],
              [-135.7304, 58.2442]
            ]
          ],
          [
            [
              [-152.898, 57.8241],
              [-152.5115, 57.8514],
              [-152.412, 57.4546],
              [-152.7814, 57.4533],
              [-153.0516, 57.2374],
              [-153.7931, 56.9894],
              [-154.3389, 56.921],
              [-154.7059, 57.3351],
              [-154.2815, 57.6378],
              [-153.8183, 57.5954],
              [-152.898, 57.8241]
            ]
          ],
          [
            [
              [-134.9699, 57.3513],
              [-134.6207, 56.7184],
              [-134.63, 56.3022],
              [-135.3307, 56.822],
              [-135.1996, 57.0273],
              [-135.7871, 57.3174],
              [-135.3464, 57.5327],
              [-134.9699, 57.3513]
            ]
          ],
          [
            [
              [-133.3663, 57.0036],
              [-132.9542, 56.8804],
              [-133.2127, 56.4647],
              [-133.6029, 56.464],
              [-133.866, 57.0688],
              [-133.3663, 57.0036]
            ]
          ],
          [
            [
              [-133.9898, 56.8451],
              [-133.8554, 56.5825],
              [-134.1954, 56.4135],
              [-134.3738, 56.8385],
              [-133.9898, 56.8451]
            ]
          ],
          [[[-132.1122, 56.1097], [-132.6598, 56.0783], [-132.38, 56.4986], [-132.1122, 56.1097]]],
          [
            [
              [-133.5661, 56.3392],
              [-133.203, 56.3199],
              [-132.43, 55.6868],
              [-132.4179, 55.483],
              [-131.9763, 55.2089],
              [-131.9809, 54.805],
              [-132.7823, 55.0481],
              [-133.4471, 55.7968],
              [-133.2416, 55.9208],
              [-133.5661, 56.3392]
            ]
          ],
          [
            [
              [-130.9792, 55.4891],
              [-131.0827, 55.2666],
              [-131.811, 55.2228],
              [-131.6248, 55.8315],
              [-131.2693, 55.9557],
              [-130.9792, 55.4891]
            ]
          ],
          [
            [
              [-163.4762, 54.9806],
              [-163.3582, 54.7357],
              [-164.0735, 54.621],
              [-164.5907, 54.4042],
              [-164.8876, 54.6076],
              [-164.5299, 54.8809],
              [-163.8681, 55.0388],
              [-163.4762, 54.9806]
            ]
          ],
          [
            [
              [-166.6154, 53.901],
              [-166.4442, 53.652],
              [-166.7705, 53.4764],
              [-167.136, 53.5266],
              [-166.978, 53.9627],
              [-166.6154, 53.901]
            ]
          ],
          [
            [
              [-93.378, 48.6165],
              [-92.9962, 48.6118],
              [-91.5183, 48.0582],
              [-90.8401, 48.2007],
              [-90.7443, 48.1045],
              [-90.0918, 48.1183],
              [-89.4554, 47.9959],
              [-88.3783, 48.3031],
              [-87.2078, 47.8485],
              [-84.876, 46.8999],
              [-84.5618, 46.4574],
              [-84.1923, 46.5491],
              [-83.9778, 46.0852],
              [-83.6159, 46.1171],
              [-83.5926, 45.8168],
              [-82.5509, 45.3472],
              [-82.1378, 43.5705],
              [-82.408, 43.0727],
              [-82.5453, 42.6246],
              [-83.0732, 42.3008],
              [-83.1421, 41.9759],
              [-82.6901, 41.6748],
              [-82.439, 41.6748],
              [-81.2778, 42.2088],
              [-80.2474, 42.3659],
              [-79.0369, 42.8025],
              [-79.172, 43.4665],
              [-78.7206, 43.6252],
              [-76.8199, 43.6289],
              [-76.4646, 44.0575],
              [-75.1794, 44.8991],
              [-74.7087, 45.0038],
              [-72.7659, 45.006],
              [-71.5175, 45.0077],
              [-71.3271, 45.2902],
              [-70.8982, 45.2625],
              [-70.2963, 45.9059],
              [-70.2484, 46.2511],
              [-70.0076, 46.7089],
              [-69.243, 47.4627],
              [-68.8289, 47.2032],
              [-68.3109, 47.3546],
              [-67.8067, 47.0831],
              [-67.7845, 45.7017],
              [-67.4329, 45.6028],
              [-67.4725, 45.2756],
              [-67.1248, 45.1693],
              [-67.1065, 44.8855],
              [-67.907, 44.4537],
              [-68.2774, 44.5076],
              [-68.612, 44.3104],
              [-68.9615, 44.434],
              [-69.0682, 44.0976],
              [-69.7917, 43.8054],
              [-70.1789, 43.7661],
              [-70.5208, 43.3486],
              [-70.8291, 42.8251],
              [-70.7357, 42.6692],
              [-71.0462, 42.3312],
              [-70.7382, 42.2292],
              [-70.4046, 41.6271],
              [-70.6572, 41.5344],
              [-71.4264, 41.6333],
              [-71.5227, 41.3791],
              [-72.8471, 41.2656],
              [-73.5831, 41.0219],
              [-74.2267, 40.608],
              [-73.9576, 40.3285],
              [-74.1761, 39.7269],
              [-74.4289, 39.3869],
              [-74.8973, 39.1455],
              [-75.3532, 39.3399],
              [-75.3922, 39.0931],
              [-75.0361, 38.5034],
              [-75.5966, 37.6313],
              [-75.6592, 37.9539],
              [-76.2117, 38.3613],
              [-76.1683, 38.8525],
              [-76.5193, 38.8984],
              [-76.5012, 38.5326],
              [-76.2641, 37.8934],
              [-76.2635, 37.3565],
              [-76.4011, 37.2128],
              [-75.9993, 36.9126],
              [-75.8905, 36.6566],
              [-76.1481, 36.2796],
              [-76.479, 36.0284],
              [-75.7723, 35.9001],
              [-75.7741, 35.6471],
              [-76.1738, 35.3535],
              [-76.5129, 35.2706],
              [-76.7451, 34.9414],
              [-76.7332, 34.707],
              [-77.1339, 34.7082],
              [-77.7508, 34.285],
              [-78.0134, 33.912],
              [-78.5777, 33.8731],
              [-78.9203, 33.6587],
              [-79.276, 33.1355],
              [-80.0218, 32.62],
              [-80.2297, 32.5762],
              [-80.8491, 32.1142],
              [-81.1976, 31.7041],
              [-81.1754, 31.531],
              [-81.5203, 30.8748],
              [-81.2495, 29.7938],
              [-80.8999, 29.0501],
              [-80.5242, 28.486],
              [-80.65, 28.1808],
              [-80.2262, 27.2072],
              [-80.1259, 27.0828],
              [-80.0414, 26.5685],
              [-80.1265, 25.8336],
              [-80.3669, 25.3312],
              [-80.7366, 25.1564],
              [-81.0977, 25.3193],
              [-81.365, 25.8313],
              [-81.7154, 25.9829],
              [-81.8666, 26.4352],
              [-82.4415, 27.0597],
              [-82.6609, 27.7188],
              [-82.8437, 27.8463],
              [-82.6607, 28.4854],
              [-82.6517, 28.8872],
              [-83.2908, 29.4521],
              [-83.6942, 29.9262],
              [-84.0442, 30.1037],
              [-84.3829, 29.9077],
              [-85.3189, 29.6799],
              [-85.3537, 29.8759],
              [-85.7557, 30.1668],
              [-86.1754, 30.3328],
              [-86.2574, 30.493],
              [-86.986, 30.4307],
              [-87.7904, 30.2917],
              [-88.1354, 30.3663],
              [-88.9051, 30.4152],
              [-89.3207, 30.3455],
              [-89.5886, 30.1654],
              [-90.2253, 30.379],
              [-90.413, 30.1407],
              [-89.5895, 29.9151],
              [-89.7209, 29.6194],
              [-89.5138, 29.42],
              [-89.0155, 29.2028],
              [-89.3536, 29.0698],
              [-89.8773, 29.4578],
              [-90.1015, 29.1817],
              [-90.3793, 29.2954],
              [-90.7511, 29.1313],
              [-91.2902, 29.2888],
              [-91.3312, 29.514],
              [-91.8932, 29.8362],
              [-92.2608, 29.5567],
              [-92.6715, 29.5968],
              [-93.1759, 29.7792],
              [-93.6947, 29.7696],
              [-94.0998, 29.6702],
              [-94.8884, 29.3704],
              [-95.2735, 28.9635],
              [-96.0206, 28.5866],
              [-96.4485, 28.5946],
              [-96.5617, 28.3667],
              [-97.1565, 28.1442],
              [-97.1715, 27.8794],
              [-97.3805, 27.4196],
              [-97.524, 27.3141],
              [-97.5546, 26.9676],
              [-97.4023, 26.3968],
              [-97.1461, 25.9617],
              [-97.3582, 25.8706],
              [-97.8014, 26.0423],
              [-98.0828, 26.0641],
              [-99.1079, 26.4472],
              [-99.4435, 27.0369],
              [-99.5054, 27.5483],
              [-99.7544, 27.7303],
              [-100.2961, 28.3279],
              [-100.6587, 29.0681],
              [-101.4404, 29.777],
              [-102.2689, 29.8709],
              [-102.7342, 29.6441],
              [-102.892, 29.2164],
              [-103.2577, 29.0011],
              [-104.1107, 29.3863],
              [-104.5039, 29.6772],
              [-104.6812, 30.1346],
              [-104.9789, 30.6462],
              [-105.276, 30.8072],
              [-106.2558, 31.5448],
              [-106.4533, 31.7699],
              [-108.2119, 31.7792],
              [-108.2146, 31.3295],
              [-109.9814, 31.3262],
              [-111.0421, 31.3245],
              [-112.4648, 31.7681],
              [-114.8359, 32.5084],
              [-114.7246, 32.7154],
              [-117.1283, 32.5335],
              [-117.3187, 33.0998],
              [-117.4675, 33.2957],
              [-118.0806, 33.7222],
              [-118.4105, 33.7442],
              [-118.5063, 34.0175],
              [-119.1437, 34.1119],
              [-119.606, 34.4179],
              [-120.4811, 34.4713],
              [-120.6449, 34.5799],
              [-120.6336, 35.0763],
              [-120.8605, 35.3654],
              [-121.8774, 36.3313],
              [-121.79, 36.7325],
              [-121.8807, 36.9395],
              [-122.1644, 36.9911],
              [-122.3948, 37.2079],
              [-122.4669, 37.8381],
              [-122.9679, 38.0968],
              [-122.9864, 38.2769],
              [-123.7013, 38.9072],
              [-123.8845, 39.8607],
              [-124.3238, 40.2516],
              [-124.3715, 40.491],
              [-124.133, 40.9695],
              [-124.0685, 41.3842],
              [-124.3554, 42.1229],
              [-124.4062, 42.5837],
              [-124.5398, 42.8128],
              [-124.1486, 43.6914],
              [-124.0593, 44.7778],
              [-123.9292, 45.5767],
              [-123.9471, 46.1403],
              [-124.0728, 46.2793],
              [-123.8891, 46.6599],
              [-124.0716, 46.7447],
              [-124.3762, 47.6587],
              [-124.6212, 47.9042],
              [-124.6326, 48.3751],
              [-123.9756, 48.168],
              [-122.8611, 48.0901],
              [-122.6567, 47.8812],
              [-122.242, 48.0105],
              [-122.7887, 48.993],
              [-119.7019, 48.993],
              [-117.1433, 48.993],
              [-115.0116, 48.993],
              [-112.0267, 48.993],
              [-109.4685, 48.993],
              [-107.763, 48.993],
              [-106.0577, 48.993],
              [-103.4991, 48.993],
              [-101.3672, 48.993],
              [-98.3829, 48.993],
              [-95.1621, 48.9918],
              [-95.1554, 49.3695],
              [-94.8545, 49.3048],
              [-94.6209, 48.7424],
              [-93.7079, 48.5256],
              [-93.378, 48.6165]
            ]
          ],
          [
            [
              [-72.5097, 40.9861],
              [-73.1942, 40.6538],
              [-73.7665, 40.593],
              [-73.8792, 40.7916],
              [-73.5736, 40.9195],
              [-72.5097, 40.9861]
            ]
          ],
          [
            [
              [-159.3728, 21.9325],
              [-159.789, 22.0419],
              [-159.5794, 22.2235],
              [-159.3521, 22.2198],
              [-159.3728, 21.9325]
            ]
          ],
          [
            [
              [-157.7994, 21.4567],
              [-158.1105, 21.3185],
              [-158.273, 21.5852],
              [-157.9625, 21.7012],
              [-157.7994, 21.4567]
            ]
          ],
          [
            [[-156.487, 20.9323], [-156.2776, 20.9517], [-156.1071, 20.6451], [-156.4382, 20.6177], [-156.487, 20.9323]]
          ],
          [
            [
              [-155.5814, 19.0123],
              [-155.8814, 19.0705],
              [-155.8907, 19.3821],
              [-156.0487, 19.7497],
              [-155.8204, 20.0138],
              [-155.8318, 20.2755],
              [-155.1987, 19.9944],
              [-154.8041, 19.5244],
              [-155.0535, 19.3196],
              [-155.3096, 19.2603],
              [-155.5814, 19.0123]
            ]
          ],
          [
            [
              [-141.002, 69.6508],
              [-141.002, 67.3132],
              [-141.002, 65.56],
              [-141.002, 64.0989],
              [-141.002, 62.3457],
              [-141.002, 60.3003],
              [-139.9733, 60.1829],
              [-139.6765, 60.3285],
              [-139.0795, 60.3435],
              [-139.1854, 60.0836],
              [-137.5933, 59.2263],
              [-137.4386, 58.9032],
              [-136.5788, 59.1523],
              [-136.3218, 59.6051],
              [-135.476, 59.7932],
              [-135.0511, 59.5785],
              [-134.9438, 59.2882],
              [-133.8207, 58.705],
              [-133.2754, 58.223],
              [-131.866, 56.7931],
              [-131.5751, 56.5987],
              [-130.4772, 56.2306],
              [-130.0252, 55.8881],
              [-130.1405, 55.585],
              [-130.0366, 55.2979],
              [-130.2141, 55.0261],
              [-130.5754, 54.7696],
              [-130.8497, 54.8077],
              [-131.0479, 55.1577],
              [-130.856, 55.3549],
              [-130.9186, 55.7359],
              [-131.2876, 56.0121],
              [-132.2235, 55.7213],
              [-131.8439, 56.1599],
              [-132.8143, 57.0406],
              [-133.4658, 57.172],
              [-133.5543, 57.6952],
              [-134.0633, 58.2111],
              [-134.7761, 58.4539],
              [-135.3024, 58.2557],
              [-135.8617, 58.5771],
              [-136.1297, 58.3507],
              [-136.5827, 58.2452],
              [-137.5441, 58.581],
              [-138.3525, 59.0873],
              [-139.799, 59.5465],
              [-140.2167, 59.7264],
              [-140.8433, 59.749],
              [-141.6702, 59.9697],
              [-142.9458, 60.0967],
              [-143.8051, 60.013],
              [-144.9012, 60.3353],
              [-145.8988, 60.4782],
              [-146.874, 61.0048],
              [-147.5673, 60.9951],
              [-148.3443, 60.8535],
              [-148.1976, 60.1679],
              [-148.4307, 59.9892],
              [-149.4324, 60.0011],
              [-150.198, 59.5666],
              [-150.5816, 59.5646],
              [-150.9607, 59.244],
              [-151.7384, 59.1885],
              [-151.8499, 59.4064],
              [-151.7346, 59.9882],
              [-151.4515, 60.2028],
              [-151.3217, 60.7426],
              [-150.4412, 61.0237],
              [-150.6124, 61.3013],
              [-151.7342, 60.9108],
              [-151.7504, 60.755],
              [-152.6539, 60.238],
              [-152.7594, 59.9211],
              [-153.1061, 59.8753],
              [-154.0882, 59.3632],
              [-154.1299, 59.12],
              [-153.3271, 58.8843],
              [-154.02, 58.4928],
              [-154.2468, 58.1595],
              [-155.007, 58.0161],
              [-155.7781, 57.5681],
              [-156.4786, 57.3279],
              [-156.4751, 57.105],
              [-157.5785, 56.6341],
              [-158.4145, 56.4355],
              [-158.2757, 56.1963],
              [-158.79, 55.987],
              [-160.0457, 55.7628],
              [-160.4628, 55.5574],
              [-161.0995, 55.4057],
              [-161.7415, 55.3911],
              [-162.3328, 55.0499],
              [-163.3378, 54.8764],
              [-162.1571, 55.7194],
              [-161.2156, 56.0213],
              [-160.8514, 55.7721],
              [-160.5996, 55.8745],
              [-160.3022, 56.3141],
              [-159.2832, 56.6884],
              [-158.3209, 57.2979],
              [-157.6077, 57.6016],
              [-157.5236, 58.4212],
              [-156.9747, 58.7365],
              [-157.142, 58.8777],
              [-158.0221, 58.6402],
              [-158.5032, 58.8504],
              [-159.0828, 58.4698],
              [-159.6703, 58.9113],
              [-160.3631, 59.0511],
              [-161.3615, 58.6699],
              [-161.7555, 58.6119],
              [-161.7886, 59.0163],
              [-162.0233, 59.2837],
              [-161.8287, 59.5888],
              [-162.2425, 60.1784],
              [-162.5709, 59.9896],
              [-163.2194, 59.8457],
              [-163.907, 59.8068],
              [-164.6623, 60.3038],
              [-165.3537, 60.5413],
              [-164.805, 60.8917],
              [-165.566, 61.1023],
              [-166.0787, 61.8033],
              [-165.6128, 61.8695],
              [-165.7074, 62.1002],
              [-165.1944, 62.4736],
              [-164.4089, 63.2153],
              [-164.1076, 63.2616],
              [-163.359, 63.0457],
              [-161.9742, 63.4533],
              [-161.0997, 63.5581],
              [-160.7786, 63.8191],
              [-160.9875, 64.2513],
              [-160.932, 64.5791],
              [-161.1869, 64.9243],
              [-161.7594, 64.8162],
              [-162.8071, 64.3743],
              [-163.7132, 64.5879],
              [-164.8995, 64.4609],
              [-166.1426, 64.5828],
              [-166.4152, 64.9266],
              [-166.8271, 65.0961],
              [-166.6656, 65.3383],
              [-167.404, 65.4222],
              [-168.0883, 65.658],
              [-167.0745, 65.8768],
              [-166.7476, 66.0516],
              [-164.6744, 66.5548],
              [-163.7757, 66.5309],
              [-164.0335, 66.2159],
              [-163.6954, 66.0839],
              [-161.8164, 66.0535],
              [-161.5571, 66.2503],
              [-160.2318, 66.4203],
              [-160.8641, 66.671],
              [-161.3979, 66.5517],
              [-161.8566, 66.7006],
              [-161.9653, 67.0498],
              [-163.0015, 67.0271],
              [-163.7205, 67.1953],
              [-164.1253, 67.6066],
              [-165.3859, 68.0454],
              [-165.9595, 68.1558],
              [-166.3803, 68.425],
              [-166.2092, 68.8854],
              [-164.3025, 68.9362],
              [-163.1615, 69.3878],
              [-162.9521, 69.7581],
              [-161.881, 70.3318],
              [-160.9963, 70.3045],
              [-159.3145, 70.8782],
              [-157.9985, 70.8453],
              [-156.7833, 71.3193],
              [-155.1669, 71.0995],
              [-154.1952, 70.8011],
              [-153.233, 70.9327],
              [-152.233, 70.8103],
              [-151.9446, 70.4519],
              [-151.2249, 70.4188],
              [-149.2693, 70.5009],
              [-147.7052, 70.2175],
              [-145.8234, 70.1601],
              [-145.1972, 70.0088],
              [-143.2184, 70.1165],
              [-141.4078, 69.6535],
              [-141.002, 69.6508]
            ]
          ],
          [
            [
              [-171.4633, 63.64],
              [-170.4304, 63.699],
              [-170.0175, 63.4915],
              [-168.8525, 63.1711],
              [-169.7778, 63.0934],
              [-171.4633, 63.64]
            ]
          ],
          [
            [
              [-152.417, 58.3604],
              [-152.1092, 58.1613],
              [-152.9825, 57.997],
              [-153.3815, 58.0871],
              [-152.6746, 58.4504],
              [-152.417, 58.3604]
            ]
          ],
          [
            [
              [-166.1354, 60.3834],
              [-165.7297, 60.3145],
              [-165.5916, 59.9131],
              [-166.1875, 59.7737],
              [-167.1389, 60.0085],
              [-166.476, 60.3828],
              [-166.1354, 60.3834]
            ]
          ]
        ]
      },
      properties: { name: "United States", id: "US", Continent: "North America" },
      id: "US"
    }
  ]
};
export default map;
