import React, { useEffect, useRef, useState } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import MainSpinner from '../../ui/MainSpinner'
import { useTranslation } from 'react-i18next'
import UNMemberSwitch from '../../Charts/UNMemberSwitch'
import LoadingBar from '../../ui/LoadingBar'

export default function LineChart({ references, jurisdictions }) {
  const [unMember, setUnMember] = useState(true)
  const { t, i18n } = useTranslation()

  const loading = useRef()

  useEffect(() => {
    const loadChart = () => {
      /* Chart code */
      // Themes begin
      am4core.unuseAllThemes()

      // Themes end
      if (loading.current) loading.current.style.display = 'block'

      let chart = am4core.create('lineChartDiv', am4charts.XYChart)

      chart.events.on('ready', () => {
        if (loading.current) loading.current.style.display = 'none'
      })

      let newReferences = references.filter(r =>
        unMember ? r.entry.motherEntry.jurisdiction.un_member : true
      )

      let total = 0
      let dataTmp = []
      for (let i = new Date().getFullYear(); i >= 1990; i--) {
        let n_decrim = newReferences.filter(
          r =>
            r.entry.legal &&
            (r.entry.decrim_date_2
              ? r.entry.decrim_date_2 === i
              : r.entry.decrim_date_1 === i)
        ).length

        let n_crim = newReferences.filter(r => r.entry.illegal_since === i)
          .length

        total += n_decrim - n_crim
        dataTmp[i] = {
          n_decrim,
          n_crim,
          jurs: newReferences
            .filter(
              r =>
                r.entry.legal &&
                (r.entry.decrim_date_2
                  ? r.entry.decrim_date_2 === i
                  : r.entry.decrim_date_1 === i)
            )
            .map(
              r =>
                `<div ><img
              style="display: inline; height: 12px; width: 15px; vertical-align: text-top; margin-right:5px; margin-left:10px;margin-bottom:4px;"
              class="svgFlag"
              
              src="/images/flags_svg/${jurisdictions[
                r.id
              ].a2_code.toLowerCase()}.svg"
            /> ` +
                jurisdictions[r.id].name +
                '</div>'
            )
            .join(''),
          jursCrim: newReferences
            .filter(r => r.entry.illegal_since === i)
            .map(
              r =>
                `<div><img
              style="display: inline; height: 12px; width: 15px; vertical-align: text-top; margin-right:5px; margin-left:10px;margin-bottom:4px;"
              class="svgFlag"
              
              src="/images/flags_svg/${jurisdictions[
                r.id
              ].a2_code.toLowerCase()}.svg"
            /> ` +
                jurisdictions[r.id].name +
                '</div>'
            )
            .join('')
        } //temp value
      }
      let totalPre1990 = newReferences.filter(r => r.entry.legal).length - total

      let data = []

      let totalPre = newReferences.filter(r => !r.entry.legal).length + total

      for (let i = 1990; i <= new Date().getFullYear(); i++) {
        if (!dataTmp[i]) continue
        data.push({
          date: new Date().setFullYear(i),
          value: totalPre - dataTmp[i].n_decrim + dataTmp[i].n_crim,
          yearText: i + '',
          increase: dataTmp[i].n_decrim,
          decrease: dataTmp[i].n_crim,
          jurs: dataTmp[i].jurs
            ? '<br />' + dataTmp[i].jurs + '<br />'
            : '<br />',
          jursCrim: !dataTmp[i].jursCrim.length
            ? ''
            : t('{{num}} jurisdictions criminalised', {
                num: dataTmp[i].n_crim
              }) +
              '<br /><br /><strong>' +
              dataTmp[i].jursCrim +
              '</strong><br />',
          jursDecrim: t('{{num}} jurisdictions decriminalised', {
            num: dataTmp[i].n_decrim
          })
        }) //temp value
        totalPre -= dataTmp[i].n_decrim - dataTmp[i].n_crim
      }

      chart.data = data

      // Create axes
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis())
      dateAxis.dateFormats.setKey('day', 'YYYY')

      dateAxis.dateFormats.setKey('week', 'YYYY')

      dateAxis.dateFormats.setKey('month', 'YYYY')
      
      dateAxis.dateFormats.setKey('year', 'YYYY')
      dateAxis.renderer.minGridDistance = 60
      dateAxis.startLocation = 0
      dateAxis.endLocation = 2
      

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())

      // Create series
      let series = chart.series.push(new am4charts.LineSeries())
      series.dataFields.valueY = 'value'
      series.dataFields.dateX = 'date'
      series.tooltipHTML = `<strong><center>{yearText}</center></strong>
<br />
<center>{jursDecrim}</center>
<strong>{jurs}</strong>
{jursCrim}
<center>({value} ${t('remaining')})</center>
    `

      //valueAxis.min = 0;

      series.tooltip.label.padding(10, 10, 10, 10)
      series.tooltip.label.textAlign = 'left'
      series.tooltip.label.maxWidth = 350
      series.tooltip.label.wrap = true
      series.tooltip.autoTextColor = false
      series.tooltip.getFillFromObject = false
      series.tooltip.background.fill = am4core.color('#FFF')
      series.tooltip.background.fillOpacity = 1
      series.tooltip.label.fill = am4core.color('#575757')
      series.stroke = am4core.color('#7D9AD0')
      series.strokeWidth = 3;

      series.tooltip.pointerOrientation = 'vertical'

      // Make bullets grow on hover
      let bullet = series.bullets.push(new am4charts.CircleBullet())
      bullet.circle.strokeWidth = 2
      bullet.circle.radius = 4
      bullet.circle.fill = am4core.color('#fff')

      let bullethover = bullet.states.create('hover')
      bullethover.properties.scale = 1.3

      chart.cursor = new am4charts.XYCursor()
      chart.cursor.snapToSeries = series
      chart.cursor.xAxis = dateAxis

      //chart.scrollbarY = new am4core.Scrollbar();
      chart.scrollbarX = new am4core.Scrollbar()
    }
    loadChart()
  }, [unMember, references, jurisdictions])
  return (
    <div style={{ position: 'relative' }}>
      <LoadingBar background="#f4f8f8" ref={loading} />
      <div id="lineChartDiv" style={{ height: '400px', width: '100%' }} />
      <UNMemberSwitch unMember={unMember} setUnMember={setUnMember} />
    </div>
  )
}
