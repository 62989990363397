import React from "react";

const SubjurIcon = ({ size, color = "#E7EDEF" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ width: size, height: size }} viewBox="0 0 24 24">
    <g fill={color}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M10 17l5-5-5-5v10z" />
    </g>
  </svg>
);

export default SubjurIcon;
