import React from 'react';

const TreatiesIcon = ({ color = '#c9d8d9'}) => <svg id="Treaties" xmlns="http://www.w3.org/2000/svg" width="53.162" height="57.168" viewBox="0 0 53.162 57.168">
  <path id="Subtraction_1" data-name="Subtraction 1" d="M22.831,42.14H4.682A4.7,4.7,0,0,1,0,37.458V4.682A4.688,4.688,0,0,1,4.682,0H42.14a4.688,4.688,0,0,1,4.682,4.682V20.136H42.14V4.682H4.682V37.458H22.831V42.14Z" fill={color} />
  <rect id="Rectangle_2231" data-name="Rectangle 2231" width="22.765" height="4.401" transform="translate(7.735 9.658)" fill={color}/>
  <rect id="Rectangle_2260" data-name="Rectangle 2260" width="16.557" height="4.401" transform="translate(7.735 17.322)" fill={color}/>
  <rect id="Rectangle_2264" data-name="Rectangle 2264" width="11.728" height="4.401" transform="translate(7.735 25.624)" fill={color} />
  <path id="Union_15" data-name="Union 15" d="M16.987,28.387l-1.623,1.6-1.686-1.663-5.35,9.14-2.219-5.4L0,32.555l4.613-7.671v-5.5L.164,14.994l4.449-4.388V4.388h6.3L15.364,0l4.448,4.388h6.3v6.218l4.448,4.388-4.448,4.388v6L31.022,33.1l-5.961-.819-2.613,5.435Zm5.257,4.377,1.44-3,2.7.371L23.5,25.6h-3.69L18.7,26.7ZM8.7,32.231l3.273-5.592L10.916,25.6H6.9L4.311,29.9l3.318-.265ZM8.047,11.706,4.715,14.994l3.333,3.288V22.2h3.984l3.333,3.288L18.7,22.2H22.68V18.282l3.333-3.288L22.68,11.706V7.786H18.7L15.364,4.5,12.031,7.786H8.047Z" transform="translate(22.141 19.451)" fill={color} />
</svg>

export default TreatiesIcon;


