import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default function SubJurChart({ eEntry, jur, subArea, noShow }) {
  const [t, i18n] = useTranslation()
  const [texts, setTexts] = useState({})
  useEffect(() => {
    if (eEntry && jur) {
      const data = {
        jurName: jur.name,
        n1: eEntry.n_sub,
        n1t: eEntry.sub.length,
        n1p: Math.round((eEntry.n_sub / eEntry.sub.length) * 100),
        n1pp: Math.round((eEntry.n_subp / eEntry.n_subptotal) * 100)
      }
      setTexts({
        1: t(
          'Criminalisation of <b>consensual same-sex sexual acts</b> varies across {{jurName}}, where {{n1}} out of {{n1t}} criminalise these acts.',
          data
        ),
        3:
          eEntry.n_sub < eEntry.sub.length
            ? t(
                '<p>Restrictions to freedom of expression are not in force nationwide in {{jurName}}</p>',
                data
              ) +
              t(
                '<p>However, restrictions of this kind are in force in {{n1}} out of {{n1t}} subnational jurisdictions ({{n1p}}%), covering approximately {{n1pp}}% of the total population.</p>',
                data
              )
            : t(
                '<p>Restrictions to freedom of expression are in force in all subnational jurisdictions in {{jurName}}.</p>',
                data
              ) +
              (eEntry && eEntry?.main?.motherEntry?.jurisdiction?.id === 'UN-036'
                ? t(
                    '<p>For information on specific subnational restrictions (such as in Shanghai), please display subnational jurisdictions with the button provided under the chart.</p>'
                  )
                : ''),
        14:
          t(
            '<p>Regulation of so-called “conversion therapies”  is not available nationwide in {{jurName}}</p>',
            data
          ) +
          (eEntry.n_sub < eEntry.sub.length
            ? t('currently_114', data)
            : eEntry.n_sub > 0
            ? t(
                '<p>However, all subnational jurisdictions offer this protection covering the entirety of the population.</p>',
                data
              )
            : ''),
        15:
          eEntry.n_sub < eEntry.sub.length
            ? t(
                '<p>Legal recognition of same-sex unions  is not available nationwide in {{jurName}}</p>',
                data
              ) +
              t(
                '<p>Currently, {{n1}} out of {{n1t}} subnational jurisdictions offer this protection ({{n1p}}%), covering approximately {{n1pp}}% of the total population.</p>',
                data
              )
            : eEntry.n_sub > 0
            ? t(
                '<p>Same sex marriage is available in all subnational jurisdictions in {{jurName}}, covering the entirety of the population.</p>',
                data
              )
            : '',
        16:
          t(
            '<p>Adoption by same-sex couples is not available nationwide in {{jurName}}</p>',
            data
          ) +
          (eEntry.n_sub < eEntry.sub.length
            ? t(
                '<p>Currently, {{n1}} out of {{n1t}} subnational jurisdictions offer this protection ({{n1p}}%), covering approximately {{n1pp}}% of the total population.</p>',
                data
              )
            : eEntry.n_sub > 0
            ? t(
                '<p>However, all subnational jurisdictions offer this protection covering the entirety of the population.</p>',
                data
              )
            : ''),
        17:
          t(
            '<p>Restrictions of non-vital medical interventions aimed at modifying the sex characteristics of intersex minors without their free, prior and full informed consent is not available nationwide in {{jurName}}</p>',
            data
          ) +
          (eEntry.n_sub < eEntry.sub.length
            ? t(
                '<p>Currently, {{n1}} out of {{n1t}} subnational jurisdictions offer this protection ({{n1p}}%), covering approximately {{n1pp}}% of the total population.</p>',
                data
              )
            : eEntry.n_sub > 0
            ? t(
                '<p>However, all subnational jurisdictions offer this protection covering the entirety of the population.</p>',
                data
              )
            : ''),
        181:
          t(
            '<p>Name change is not available nationwide in {{jurName}}</p>',
            data
          ) +
          (eEntry.n_sub < eEntry.sub.length
            ? t(
                '<p>Currently, {{n1}} out of {{n1t}} subnational jurisdictions offer this protection ({{n1p}}%), covering approximately {{n1pp}}% of the total population.</p>',
                data
              )
            : eEntry.n_sub > 0
            ? t(
                '<p>However, all subnational jurisdictions offer this possibility covering the entirety of the population.</p>',
                data
              )
            : ''),
        182:
          t(
            '<p>Gender Marker Change  is not available nationwide in {{jurName}}</p>',
            data
          ) +
          (eEntry.n_sub < eEntry.sub.length
            ? t(
                '<p>Currently, {{n1}} out of {{n1t}} subnational jurisdictions offer this protection ({{n1p}}%), covering approximately {{n1pp}}% of the total population.</p>',
                data
              )
            : eEntry.n_sub > 0
            ? t(
                '<p>However, all subnational jurisdictions offer this possibility covering the entirety of the population.</p>',
                data
              )
            : '')
      })
    }
  }, [eEntry, jur])

  if (
    eEntry &&
    eEntry.main?.motherEntry?.jurisdiction?.id === 'UN-036' &&
    subArea === 3
  ) {
    eEntry.n_sub = 10
    eEntry.n_subp = 10
    eEntry.n_subptotal = 10
    eEntry.sub = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
  }

  return (
    <>
      {eEntry && eEntry.sub.length > 0 && eEntry.n_sub > 0 && !noShow && (
        <div className="jpjs__summaryBox">
          <div className="sbox__left">
            <p>{t('Sub-national jurisdictions')}</p>
            <div className="jpjs__progressBar">
              <div
                className={'jpjspb__left ' + (subArea === 1 ? 'red' : 'blue')}
                style={{
                  width: (eEntry.n_sub / eEntry.sub.length) * 100 + '%'
                }}
              >
                {Math.round((eEntry.n_sub / eEntry.sub.length) * 100)} %
              </div>
              {100 - Math.round((eEntry.n_sub / eEntry.sub.length) * 100) >
                0 && (
                <div
                  className="jpjspb__right"
                  style={{
                    width: 100 - (eEntry.n_sub / eEntry.sub.length) * 100 + '%'
                  }}
                >
                  {100 - Math.round((eEntry.n_sub / eEntry.sub.length) * 100)} %
                </div>
              )}
            </div>
            <p>{t('Population')}</p>
            <div className="jpjs__progressBar">
              <div
                className={'jpjspb__left ' + (subArea === 1 ? 'red' : 'green')}
                style={{
                  width:
                    Math.round((eEntry.n_subp / eEntry.n_subptotal) * 100) + '%'
                }}
              >
                {Math.round((eEntry.n_subp / eEntry.n_subptotal) * 100)} %
              </div>
              {100 - Math.round((eEntry.n_subp / eEntry.n_subptotal) * 100) >
                0 && (
                <div
                  className="jpjspb__right"
                  style={{
                    width:
                      100 -
                      Math.round((eEntry.n_subp / eEntry.n_subptotal) * 100) +
                      '%'
                  }}
                >
                  {100 - Math.round((eEntry.n_subp / eEntry.n_subptotal) * 100)}{' '}
                  %
                </div>
              )}
            </div>
          </div>
          <div className="sbox__right">
            <div dangerouslySetInnerHTML={{ __html: texts[subArea] }} />
          </div>
        </div>
      )}
    </>
  )
}
