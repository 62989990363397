import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import routes from '../../../config/routes'
import CustomCheckbox from '../Checkbox'

export function getAreasSelected(filters, compare) {
  const areas = []
  const theRoutes = routes()

  if (!compare) {
    if (!filters.areas_ids) return []
    ;['a1', 'a2', 'a3', 'a4', 'a5', 'a6'].forEach(aId => {
      if (aId === 'a1' || aId === 'a3') {
        if (!filters.areas_ids || filters.areas_ids.indexOf(aId) !== -1) {
          areas.push({
            id: aId,
            name: theRoutes.find(r => r.id === aId).name
          })
        } else if (
          filters &&
          filters.subareas &&
          filters.subareas[aId] &&
          filters.subareas[aId].length > 0
        ) {
          filters.subareas[aId].forEach(s => {
            areas.push({ id: s, name: theRoutes.find(r => r.id === s).name })
          })
        }
      } else {
        if (!filters.areas_ids || filters.areas_ids.indexOf(aId) !== -1)
          areas.push({
            id: aId,
            name: theRoutes.find(r => r.id === aId).name
          })
      }
    })
  } else {
    if (
      filters &&
      filters.subareas &&
      filters.subareas['a1'] &&
      filters.subareas['a1'].length > 0
    ) {
      filters.subareas['a1'].forEach(s => {
        areas.push({ id: s, name: theRoutes.find(r => r.id === s).name })
      })
    }
  }

  return areas
}

export default function AreaSelectModal({
  handleClose,
  showModal,
  areaSelected,
  setAreaSelected,
  filters,
  setFilters,
  compare = false,
  selectOneArea
}) {
  const theRoutes = routes()
  const [areaHover, setAreaHover] = useState(compare ? 'a1' : false)

  const { t } = useTranslation()

  useEffect(() => {
    console.log(filters, areaSelected)
  }, [areaSelected, showModal])

  useEffect(() => {
    setAreaSelected({
      ...areaSelected,
      subareas: filters ? filters.subareas : []
    })
  }, [showModal])

  return (
    <Modal
      className="asai__modal advancedSearch__page"
      show={showModal}
      onHide={handleClose}
    >
      <Modal.Header closeButton />
      <Modal.Body className="asai__modal-body">
        {!compare && (
          <div className="asaimb__box-left">
            <h6>Areas</h6>
            <div className="asai__areaItems">
              <ul className="list-unstyled">
                {theRoutes
                  .filter(
                    r =>
                      r.isArea &&
                      !r.isChild &&
                      !r.disabled &&
                      r.id !== 'a2' &&
                      r.id !== 'a6'
                  )
                  .map(a => (
                    <li
                      key={a.id}
                      onMouseEnter={e => {
                        if (a.id === 'a1' || a.id === 'a3') setAreaHover(a.id)
                        else setAreaHover(false)
                      }}
                      onClick={e => {
                        if (a.id === 'a1' || a.id === 'a3') setAreaHover(a.id)
                        else setAreaHover(false)
                      }}
                      className="acp__formControl"
                    >
                      {!selectOneArea ? (
                        <CustomCheckbox
                          content={
                            a.name +
                            ((a.id === 'a1' &&
                              areaSelected.subareas &&
                              areaSelected.subareas[a.id] &&
                              areaSelected.subareas[a.id].length > 0 &&
                              !areaSelected[a.id]) ||
                            (a.id === 'a3' &&
                              areaSelected.subareas &&
                              areaSelected.subareas[a.id] &&
                              areaSelected.subareas[a.id].length > 0 &&
                              !areaSelected[a.id])
                              ? ' (' +
                                areaSelected.subareas[a.id].length +
                                ' ' +
                                t('subareas selected') +
                                ')'
                              : '') +
                            (a.id === 'a1' || a.id === 'a3' ? ' »' : '')
                          }
                          checked={areaSelected[a.id]}
                          setChecked={checked => {
                            setAreaSelected({
                              ...areaSelected,
                              [a.id]: checked,
                              subareas: {
                                ...areaSelected.subareas,
                                ...(checked
                                  ? {
                                      [a.id]: theRoutes
                                        .filter(
                                          r =>
                                            r.isArea &&
                                            !r.disabled &&
                                            ((r.parent === 'area1' &&
                                              a.id === 'a1') ||
                                              (r.parent === 'area3' &&
                                                a.id === 'a3'))
                                        )
                                        .map(a => a.id)
                                    }
                                  : { [a.id]: [] })
                              }
                            })
                          }}
                        />
                      ) : (
                        <div
                          className="no-check-container"
                          onClick={e => {
                            if (a.id !== 'a1' && a.id !== 'a3')
                              selectOneArea(a.id)
                            handleClose()
                          }}
                        >
                          <label>
                            {a.name +
                              (a.id === 'a1' || a.id === 'a3' ? ' »' : '')}
                          </label>
                        </div>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        )}
        {areaHover && (
          <div className="asaimb__box-right">
            <div className="asaimbr__header">
              <h6>{t('Legal Categories')}</h6>
            </div>
            <div className="asaimbr__body">
              <ul className="items list-unstyled">
                {theRoutes
                  .filter(
                    r =>
                      r.id !== 'a31' &&
                      !r.disabled &&
                      r.isArea &&
                      ((r.parent === 'area1' && areaHover === 'a1') ||
                        (r.parent === 'area3' && areaHover === 'a3'))
                  )
                  .map(a => (
                    <li className="acp__formControl" key={a.id}>
                      {!selectOneArea ? (
                        <CustomCheckbox
                          content={a.name}
                          checked={
                            areaSelected[areaHover] ||
                            (areaSelected.subareas &&
                              areaSelected.subareas[areaHover] &&
                              areaSelected.subareas[areaHover].indexOf(a.id) !==
                                -1)
                          }
                          setChecked={checked => {
                            let newArr = []
                            if (checked) {
                              newArr = [
                                ...(areaSelected.subareas &&
                                areaSelected.subareas[areaHover]
                                  ? areaSelected.subareas[areaHover]
                                  : []),
                                a.id
                              ]
                              let parentSelected = false
                              if (
                                a.parent === 'area1' &&
                                newArr.length === 18
                              ) {
                                parentSelected = true
                              }
                              if (a.parent === 'area3' && newArr.length === 9) {
                                parentSelected = true
                              }

                              setAreaSelected({
                                ...areaSelected,
                                [areaHover]: parentSelected,
                                subareas: {
                                  ...(areaSelected.subareas
                                    ? areaSelected.subareas
                                    : {}),
                                  [areaHover]: newArr
                                }
                              })
                            } else {
                              newArr = areaSelected.subareas
                                ? areaSelected.subareas[areaHover].filter(
                                    aa => aa !== a.id
                                  )
                                : []

                              setAreaSelected({
                                ...areaSelected,
                                [areaHover]: false,
                                subareas: {
                                  ...areaSelected.subareas,
                                  [areaHover]: newArr
                                }
                              })
                            }
                          }}
                        />
                      ) : (
                        <div className="no-check-container">
                          <label
                            onClick={e => {
                              selectOneArea(a.id)
                              handleClose()
                            }}
                          >
                            {a.name}
                          </label>
                        </div>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className="asai__modal-footer acp__actionBar">
        <button
          className={'btn btn-primary clear'}
          onClick={() => {
            setAreaSelected({
              a1: false,
              a2: false,
              a3: false,
              a4: false,
              a5: false,
              a6: false,
              subareas: {}
            })
            setAreaHover(false)
          }}
        >
          {t('Clear all')}
        </button>
        {!selectOneArea && (
          <Button
            variant="primary"
            onClick={e => {
              setFilters({
                ...filters,
                areas_ids: Object.keys(areaSelected).filter(
                  a => a !== 'subareas' && areaSelected[a]
                ),
                subareas: areaSelected.subareas
              })
              handleClose()
            }}
          >
            {t('Apply')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
