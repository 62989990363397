import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cMainRegions } from '../../../config/constants'
import { isRecommendation } from '../../../lib/fnAreasPRHelper'

import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import LoadingBar from '../../components/ui/LoadingBar'

export default function GanttChartArea56({ references, commitee, isLoading }) {
  const [unMember, setUnMember] = useState(true)
  const [topicsN, setTopicsN] = useState(10)
  const { t, i18n } = useTranslation()
  const loading = useRef()
  const loadChart = () => {
    if (loading.current) loading.current.style.display = 'block'
    am4core.options.queue = false

    /* Chart code */
    // Themes begin
    am4core.unuseAllThemes()
    
    // Themes end

    // Create chart instance
    var chart = am4core.create('ganttChart', am4charts.XYChart)

    chart.events.on('ready', () => {
      if (loading.current) loading.current.style.display = 'none'
    })

    let newReferences = []

    chart.hiddenState.properties.opacity = 0 // this creates initial fade-in

    chart.paddingRight = 30
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd HH:mm'

    var colorSet = new am4core.ColorSet()
    colorSet.saturation = 0.4

    chart.data = [
      {
        category: 'Membership',
        start: '2010-01-01',
        end: '2012-01-14',
        color: colorSet.getIndex(0).brighten(0),
        task: 'Gathering requirements'
      },
      {
        category: 'Membership',
        start: '2016-01-16',
        end: '2017-01-27',
        color: colorSet.getIndex(0).brighten(0.4),
        task: 'Producing specifications'
      }
    ]

    chart.data = references.map(hrc => {
      return {
        category: '',
        start: hrc.ini,
        end: hrc.end,
        color: am4core.color('#96C9DB'),
        task: hrc.jurisdiction.name + ' ' + t('HRC Membership')
      }
    })

    chart.dateFormatter.dateFormat = 'dd MMM yyyy'
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd'
    chart.zoomOutButton.disabled = true

    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'category'

    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.inversed = true

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.renderer.minGridDistance = 100
    dateAxis.baseInterval = { count: 1, timeUnit: 'day' }
    dateAxis.min = new Date(2006, 6, 19).getTime()
    dateAxis.max = new Date(2022, 1, 1).getTime()
    // dateAxis.max = new Date(2018, 0, 1, 24, 0, 0, 0).getTime();
    //dateAxis.strictMinMax = true;
    dateAxis.renderer.tooltipLocation = 0

    var series1 = chart.series.push(new am4charts.ColumnSeries())
    series1.columns.template.height = am4core.percent(70)
    series1.columns.template.tooltipText =
      '{task} from [bold]{openDateX}[/] to [bold]{dateX}[/]'

    series1.dataFields.openDateX = 'start'
    series1.dataFields.dateX = 'end'
    series1.dataFields.categoryY = 'category'
    series1.columns.template.propertyFields.fill = 'color' // get color from data
    series1.columns.template.propertyFields.stroke = 'color'
    series1.columns.template.strokeOpacity = 1

    //chart.scrollbarX = new am4core.Scrollbar();
  }
  useEffect(() => {
    !isLoading && loadChart()
  }, [unMember])
  return (
    <>
      <div
        className="chartContainer"
        style={{
          minHeight: '180px',
          maxHeight: '180px',
          marginBottom: '10px',
          marginTop: '10px'
        }}
      >
        {isLoading ? (
          <LoadingBar ref={loading} text={t('Loading chart') + '...'} />
        ) : (
          <>
            {' '}
            <LoadingBar ref={loading} text={t('Loading chart') + '...'} />
            <div id="ganttChart" style={{ minHeight: '180px' }} />{' '}
          </>
        )}
      </div>
    </>
  )
}
