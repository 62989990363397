var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-65.1692, -27.9097],
            [-65.2591, -27.9125],
            [-65.3419, -27.8593],
            [-65.5046, -27.9687],
            [-65.5656, -28.0503],
            [-65.6484, -27.9708],
            [-65.6993, -27.8217],
            [-65.8347, -27.7997],
            [-65.9274, -27.6591],
            [-65.9858, -27.4297],
            [-66.0977, -27.3483],
            [-66.1889, -27.326],
            [-66.1421, -27.2282],
            [-65.8693, -26.9074],
            [-65.8679, -26.7022],
            [-66.0232, -26.5962],
            [-66.2145, -26.5488],
            [-66.2178, -26.3985],
            [-66.1528, -26.23],
            [-65.7203, -26.3023],
            [-65.6638, -26.0744],
            [-65.4418, -26.1214],
            [-65.3115, -26.0787],
            [-65.2722, -26.1669],
            [-64.9385, -26.276],
            [-64.8064, -26.2125],
            [-64.4864, -26.2203],
            [-64.5253, -26.4353],
            [-64.4963, -26.676],
            [-64.5874, -26.6779],
            [-64.6674, -26.7997],
            [-64.8145, -27.1836],
            [-64.8876, -27.3232],
            [-64.9386, -27.335],
            [-65.0627, -27.4707],
            [-65.0656, -27.64],
            [-65.0025, -27.7797],
            [-65.0721, -27.8877],
            [-65.1692, -27.9097]
          ]
        ]
      },
      properties: {
        name: 'Tucumán',
        id: 'AR-T',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-T'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.0666, -54.8472],
              [-68.2456, -54.8169],
              [-68.515, -54.8561],
              [-68.6358, -54.7883],
              [-68.6292, -53.998],
              [-68.6239, -53.2764],
              [-68.6186, -52.6506],
              [-68.3322, -52.8931],
              [-68.2575, -52.9992],
              [-68.3918, -53.0136],
              [-68.5436, -53.1264],
              [-68.5436, -53.2294],
              [-68.4306, -53.2925],
              [-68.1798, -53.2861],
              [-68.0867, -53.39],
              [-67.9703, -53.6086],
              [-67.5856, -53.8314],
              [-67.5636, -53.9186],
              [-67.3592, -54.0289],
              [-66.6856, -54.2994],
              [-66.5496, -54.4123],
              [-66.2417, -54.5378],
              [-65.8075, -54.6575],
              [-65.6736, -54.6731],
              [-65.3303, -54.6356],
              [-65.1401, -54.6532],
              [-65.3506, -54.9278],
              [-65.6031, -54.9317],
              [-65.9361, -54.8986],
              [-66.0099, -54.9546],
              [-66.352, -54.9825],
              [-66.4461, -55.0517],
              [-66.6529, -55.0245],
              [-66.8217, -54.9397],
              [-67.0113, -54.9086],
              [-67.4889, -54.8775],
              [-67.7142, -54.887],
              [-68.0666, -54.8472]
            ]
          ],
          [
            [
              [-64.6783, -54.9072],
              [-64.7506, -54.8428],
              [-64.3375, -54.7278],
              [-64.0114, -54.7786],
              [-64.2575, -54.841],
              [-64.4228, -54.8397],
              [-64.6783, -54.9072]
            ]
          ]
        ]
      },
      properties: {
        name: 'Tierra del Fuego',
        id: 'AR-V',
        CNTRY_NAME: 'Argentina',
        TYPE: 'National Territory'
      },
      id: 'AR-V'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-67.3607, -32.3985],
            [-67.3864, -32.3306],
            [-67.3879, -31.8894],
            [-67.352, -31.8483],
            [-66.9711, -31.8911],
            [-66.7305, -31.8766],
            [-66.5767, -31.9183],
            [-66.3629, -31.9369],
            [-66.1994, -31.9215],
            [-66.0575, -31.8717],
            [-65.8696, -31.9056],
            [-65.7596, -31.8855],
            [-65.6361, -31.8914],
            [-65.31, -32.0583],
            [-65.2489, -32.1155],
            [-65.1955, -32.326],
            [-64.9142, -32.3149],
            [-64.8738, -32.5551],
            [-65.006, -33.0002],
            [-65.1305, -33.203],
            [-65.0847, -34.0881],
            [-65.0867, -35.002],
            [-65.0879, -36.0023],
            [-66.6173, -36.0001],
            [-66.6357, -35.9697],
            [-66.5189, -35.4842],
            [-66.5078, -35.1281],
            [-66.5407, -34.9372],
            [-66.5922, -34.8111],
            [-66.7433, -34.6056],
            [-66.8114, -34.39],
            [-66.8236, -34.2364],
            [-66.7589, -34.1611],
            [-66.7665, -34.0096],
            [-66.9415, -33.8188],
            [-66.9808, -33.7142],
            [-67.1531, -33.4304],
            [-67.1924, -33.2187],
            [-67.1968, -32.979],
            [-67.2243, -32.9329],
            [-67.1983, -32.7786],
            [-67.2492, -32.6783],
            [-67.3315, -32.4201],
            [-67.3607, -32.3985]
          ]
        ]
      },
      properties: {
        name: 'San Luis',
        id: 'AR-D',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-D'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.2443, -32.3098],
            [-70.3133, -32.2842],
            [-70.3489, -32.0869],
            [-70.2649, -32.0425],
            [-70.2375, -31.9384],
            [-70.3041, -31.8824],
            [-70.4303, -31.8686],
            [-70.5867, -31.5638],
            [-70.5603, -31.3224],
            [-70.5208, -31.1592],
            [-70.4514, -31.0946],
            [-70.362, -31.0931],
            [-70.295, -31.0297],
            [-70.3317, -30.9314],
            [-70.2692, -30.6783],
            [-70.1339, -30.4397],
            [-69.9385, -30.3726],
            [-69.8292, -30.144],
            [-69.925, -30],
            [-69.9172, -29.7133],
            [-69.9686, -29.6554],
            [-69.965, -29.5355],
            [-70.0347, -29.3521],
            [-70.029, -29.2911],
            [-69.9016, -29.139],
            [-69.8381, -29.1327],
            [-69.7234, -28.7977],
            [-69.746, -28.6699],
            [-69.6693, -28.5756],
            [-69.6554, -28.4009],
            [-69.3475, -28.4788],
            [-69.1564, -28.6192],
            [-69.1096, -28.7588],
            [-68.9144, -28.9322],
            [-68.8906, -29.1036],
            [-68.9511, -29.1911],
            [-69.0267, -29.5731],
            [-68.9777, -29.6367],
            [-68.689, -29.6023],
            [-68.47, -29.6509],
            [-68.3142, -29.7133],
            [-68.0375, -29.912],
            [-68.0108, -29.968],
            [-67.6511, -30.2342],
            [-67.5666, -30.3783],
            [-67.3786, -30.5408],
            [-67.2574, -30.6843],
            [-67.1764, -30.7343],
            [-67.0814, -30.8961],
            [-67.1289, -31.0375],
            [-67.0599, -31.0837],
            [-67.1078, -31.358],
            [-67.0451, -31.533],
            [-66.8519, -31.673],
            [-66.7305, -31.8766],
            [-66.9711, -31.8911],
            [-67.352, -31.8483],
            [-67.3879, -31.8894],
            [-67.3864, -32.3306],
            [-67.3607, -32.3985],
            [-67.4571, -32.2982],
            [-67.5722, -32.258],
            [-67.8197, -32.2444],
            [-68.0223, -32.0682],
            [-68.2263, -32.1082],
            [-68.4685, -32.0843],
            [-68.5009, -32.1812],
            [-68.7339, -32.1522],
            [-68.9315, -32.0712],
            [-69.0186, -32.0814],
            [-69.1239, -31.9733],
            [-69.4421, -32.0513],
            [-69.5986, -32.115],
            [-69.6666, -32.2596],
            [-69.974, -32.3058],
            [-70.2443, -32.3098]
          ]
        ]
      },
      properties: {
        name: 'San Juan',
        id: 'AR-J',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-J'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-60.2944, -33.2567],
            [-60.2827, -33.3167],
            [-60.4157, -33.4683],
            [-60.4787, -33.6269],
            [-60.5845, -33.6371],
            [-60.6713, -33.5771],
            [-60.8464, -33.5499],
            [-60.927, -33.6393],
            [-61.7106, -34.3769],
            [-62.8539, -34.3822],
            [-62.32, -33.6608],
            [-61.9353, -33.1319],
            [-61.7659, -33.0078],
            [-61.7324, -32.8344],
            [-61.8325, -32.6767],
            [-61.8953, -32.5005],
            [-62.0329, -32.3647],
            [-62.0887, -32.2357],
            [-62.2176, -32.1364],
            [-62.2128, -31.9186],
            [-62.2447, -31.7235],
            [-62.132, -31.6422],
            [-61.8472, -30.7286],
            [-62.0064, -30.675],
            [-62.1893, -30.5637],
            [-62.1403, -30.4509],
            [-62.0871, -30.1565],
            [-61.88, -29.0017],
            [-61.71, -28.0005],
            [-60.3931, -28.0022],
            [-59.6561, -28.0011],
            [-58.8569, -27.9993],
            [-58.8911, -28.0979],
            [-59.0696, -28.1792],
            [-59.0583, -28.4247],
            [-59.0843, -28.6316],
            [-59.1995, -29.0314],
            [-59.37, -29.187],
            [-59.4889, -29.2353],
            [-59.5759, -29.3967],
            [-59.5959, -29.6334],
            [-59.6604, -29.8558],
            [-59.5697, -30.0211],
            [-59.67, -30.2719],
            [-59.6509, -30.3378],
            [-59.5829, -30.4322],
            [-59.5858, -30.64],
            [-59.7384, -30.8728],
            [-59.9874, -31.2024],
            [-60.0633, -31.2711],
            [-60.145, -31.425],
            [-60.4329, -31.6935],
            [-60.6494, -31.7384],
            [-60.7089, -32.1583],
            [-60.6786, -32.3233],
            [-60.7445, -32.4897],
            [-60.7411, -32.61],
            [-60.6883, -32.8528],
            [-60.5353, -33.11],
            [-60.3476, -33.1932],
            [-60.2944, -33.2567]
          ]
        ]
      },
      properties: {
        name: 'Santa Fe',
        id: 'AR-S',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-S'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-65.1692, -27.9097],
            [-65.0721, -27.8877],
            [-65.0025, -27.7797],
            [-65.0656, -27.64],
            [-65.0627, -27.4707],
            [-64.9386, -27.335],
            [-64.8876, -27.3232],
            [-64.8145, -27.1836],
            [-64.6674, -26.7997],
            [-64.5874, -26.6779],
            [-64.4963, -26.676],
            [-64.5253, -26.4353],
            [-64.4864, -26.2203],
            [-64.4289, -26.0336],
            [-64.1961, -25.5714],
            [-63.9245, -25.6525],
            [-63.3985, -25.6594],
            [-62.7467, -25.6639],
            [-61.7158, -25.6619],
            [-61.7086, -26.8111],
            [-61.71, -28.0005],
            [-61.88, -29.0017],
            [-62.0871, -30.1565],
            [-62.2583, -29.7692],
            [-63.1945, -29.7808],
            [-63.3906, -29.7707],
            [-63.457, -29.6758],
            [-63.5999, -29.6379],
            [-63.8162, -29.6481],
            [-64.007, -29.5608],
            [-64.0441, -29.4819],
            [-64.2705, -29.4207],
            [-64.8824, -29.5572],
            [-65.0378, -29.2883],
            [-65.0708, -29.0522],
            [-65.0903, -28.7368],
            [-65.1808, -28.6011],
            [-65.0795, -28.4843],
            [-65.0797, -28.2689],
            [-65.1692, -27.9097]
          ]
        ]
      },
      properties: {
        name: 'Santiago del Estero',
        id: 'AR-G',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-G'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.645, -45.9995],
            [-67.5844, -46.0003],
            [-67.6188, -46.1772],
            [-67.5336, -46.4224],
            [-67.338, -46.6253],
            [-67.0997, -46.758],
            [-66.8185, -46.9892],
            [-66.7096, -47.0421],
            [-66.5061, -47.0458],
            [-66.1003, -47.0916],
            [-65.9893, -47.0672],
            [-65.7753, -47.1952],
            [-65.7328, -47.3306],
            [-65.7553, -47.5364],
            [-65.9045, -47.8096],
            [-65.7883, -47.9329],
            [-65.9675, -47.9619],
            [-65.9642, -48.0928],
            [-66.0671, -48.1004],
            [-66.2814, -48.2442],
            [-66.4672, -48.3981],
            [-66.6881, -48.4558],
            [-66.8257, -48.5701],
            [-67.0633, -48.6311],
            [-67.2445, -48.8232],
            [-67.4045, -48.894],
            [-67.5857, -49.0405],
            [-67.6144, -49.1711],
            [-67.7907, -49.3298],
            [-67.7224, -49.3786],
            [-67.69, -49.5228],
            [-67.7881, -49.9041],
            [-68.0614, -50.0733],
            [-68.3783, -50.1327],
            [-68.397, -50.1813],
            [-68.8207, -50.3062],
            [-69.0675, -50.5222],
            [-69.1458, -50.7438],
            [-69.1382, -50.8607],
            [-69.1925, -50.9668],
            [-69.1322, -51.1572],
            [-68.9533, -51.54],
            [-69.0785, -51.577],
            [-68.9228, -51.7011],
            [-68.6875, -52.0222],
            [-68.44, -52.2444],
            [-68.3828, -52.3274],
            [-68.8286, -52.2797],
            [-68.9914, -52.2061],
            [-69.2336, -52.1433],
            [-69.4614, -52.1436],
            [-69.9983, -51.9964],
            [-71.9106, -51.9958],
            [-71.9596, -51.8546],
            [-72.0875, -51.7644],
            [-72.2893, -51.6963],
            [-72.4006, -51.5136],
            [-72.3097, -51.3992],
            [-72.2496, -51.2379],
            [-72.3952, -51.1081],
            [-72.2658, -51.0107],
            [-72.2426, -50.8546],
            [-72.3386, -50.7317],
            [-72.2939, -50.6529],
            [-72.4913, -50.6031],
            [-72.5869, -50.6598],
            [-72.7661, -50.6207],
            [-73.0522, -50.7617],
            [-73.1765, -50.7397],
            [-73.2289, -50.5981],
            [-73.2664, -50.3556],
            [-73.349, -50.2362],
            [-73.5225, -50.1484],
            [-73.47, -50.0017],
            [-73.5542, -49.9407],
            [-73.4465, -49.7909],
            [-73.5154, -49.6051],
            [-73.5823, -49.5409],
            [-73.4648, -49.3114],
            [-73.1361, -49.3042],
            [-73.1768, -49.2218],
            [-72.9825, -48.9784],
            [-72.7814, -48.9381],
            [-72.5521, -48.7766],
            [-72.6038, -48.494],
            [-72.5779, -48.4568],
            [-72.2919, -48.3478],
            [-72.322, -48.0783],
            [-72.4783, -47.9885],
            [-72.5351, -47.9155],
            [-72.4287, -47.721],
            [-72.3296, -47.5959],
            [-72.3136, -47.4261],
            [-72.159, -47.4111],
            [-72.0174, -47.2775],
            [-71.8828, -47.2328],
            [-71.8625, -47.1398],
            [-71.9764, -47.0345],
            [-71.9392, -46.8163],
            [-71.8138, -46.7861],
            [-71.6864, -46.7028],
            [-71.6706, -46.5383],
            [-71.725, -46.4305],
            [-71.766, -46.1171],
            [-71.645, -45.9995]
          ]
        ]
      },
      properties: {
        name: 'Santa Cruz',
        id: 'AR-Z',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-Z'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-63.3985, -25.6594],
            [-63.9245, -25.6525],
            [-64.1961, -25.5714],
            [-64.4289, -26.0336],
            [-64.4864, -26.2203],
            [-64.8064, -26.2125],
            [-64.9385, -26.276],
            [-65.2722, -26.1669],
            [-65.3115, -26.0787],
            [-65.4418, -26.1214],
            [-65.6638, -26.0744],
            [-65.7203, -26.3023],
            [-66.1528, -26.23],
            [-66.2688, -26.229],
            [-66.3611, -26.3714],
            [-66.4358, -26.3634],
            [-66.5288, -26.2685],
            [-66.6592, -26.0447],
            [-66.7872, -25.8944],
            [-66.8033, -25.7213],
            [-66.7422, -25.676],
            [-66.5795, -25.6692],
            [-66.495, -25.6119],
            [-66.4697, -25.4824],
            [-66.5725, -25.269],
            [-67.8069, -25.2833],
            [-68.4859, -25.1625],
            [-68.3556, -25.1212],
            [-68.5674, -24.7819],
            [-68.3907, -24.5037],
            [-68.319, -24.4969],
            [-68.2536, -24.3986],
            [-67.3358, -24.0217],
            [-67.2509, -23.7332],
            [-67.071, -23.8393],
            [-66.86, -24.0613],
            [-66.6875, -24.1342],
            [-66.5219, -24.1596],
            [-66.4968, -24.1252],
            [-66.3779, -23.7753],
            [-66.3697, -23.6586],
            [-66.4055, -23.5468],
            [-66.3711, -23.3806],
            [-66.17, -23.4206],
            [-65.9846, -23.5536],
            [-65.9853, -23.7208],
            [-66.0263, -23.8625],
            [-65.9545, -23.9967],
            [-65.8851, -23.986],
            [-65.7672, -24.0675],
            [-65.7465, -24.1917],
            [-65.5845, -24.4054],
            [-65.2846, -24.5007],
            [-65.1217, -24.5059],
            [-64.9425, -24.5961],
            [-64.8953, -24.5841],
            [-64.8195, -24.4526],
            [-64.6189, -24.615],
            [-64.5232, -24.4928],
            [-64.4103, -24.4254],
            [-64.3263, -24.4182],
            [-64.1789, -24.2478],
            [-64.1582, -24.1779],
            [-64.1814, -23.5072],
            [-64.3711, -23.5039],
            [-64.4742, -23.59],
            [-64.5553, -23.5053],
            [-64.6725, -23.4486],
            [-64.8106, -23.5067],
            [-64.8815, -23.4794],
            [-64.9389, -23.3311],
            [-65.0445, -23.2397],
            [-65.0085, -23.0467],
            [-65.0447, -22.9946],
            [-65.1619, -22.9882],
            [-65.2356, -22.9376],
            [-65.3393, -22.5143],
            [-65.2379, -22.3159],
            [-65.1902, -22.0947],
            [-64.9694, -22.1076],
            [-64.5926, -22.2132],
            [-64.5469, -22.2606],
            [-64.5261, -22.4089],
            [-64.4619, -22.5145],
            [-64.4531, -22.644],
            [-64.3014, -22.8325],
            [-64.2799, -22.6455],
            [-64.2231, -22.5129],
            [-64.1267, -22.4103],
            [-63.9411, -22.0008],
            [-62.8172, -21.9974],
            [-62.7924, -22.1154],
            [-62.5214, -22.3708],
            [-62.3414, -22.4703],
            [-62.3372, -23.1967],
            [-62.3393, -24.1181],
            [-62.3342, -24.3945],
            [-62.6456, -24.7744],
            [-63.3985, -25.6594]
          ]
        ]
      },
      properties: {
        name: 'Salta',
        id: 'AR-A',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-A'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7598, -41.9997],
            [-71.7878, -41.8612],
            [-71.9146, -41.6521],
            [-71.85, -41.5494],
            [-71.899, -41.3567],
            [-71.8614, -41.2417],
            [-71.8592, -41.0113],
            [-71.5325, -41.0352],
            [-71.2794, -41.0958],
            [-71.1533, -41.0569],
            [-71.0341, -40.9235],
            [-71.113, -40.7931],
            [-70.9765, -40.6562],
            [-70.8053, -40.587],
            [-70.6745, -40.5803],
            [-70.5065, -40.5007],
            [-70.3868, -40.5237],
            [-70.1803, -40.4741],
            [-70.0636, -40.3535],
            [-69.9909, -40.1831],
            [-69.9867, -39.9967],
            [-69.8845, -39.9136],
            [-69.3684, -39.7245],
            [-69.2546, -39.5898],
            [-68.9408, -39.4743],
            [-68.806, -39.3013],
            [-68.6796, -39.2215],
            [-68.6138, -39.1199],
            [-68.2542, -38.9695],
            [-68.252, -38.4386],
            [-68.2495, -37.5654],
            [-67.9119, -37.5872],
            [-67.7739, -37.6425],
            [-67.7105, -37.746],
            [-67.7625, -37.8649],
            [-67.8664, -37.9273],
            [-67.8699, -38.0289],
            [-67.7046, -38.1032],
            [-67.6464, -38.2039],
            [-67.5217, -38.2683],
            [-67.1712, -38.2286],
            [-67.1365, -38.346],
            [-66.9686, -38.455],
            [-66.7947, -38.4992],
            [-66.6064, -38.5918],
            [-66.5618, -38.7016],
            [-66.3818, -38.7425],
            [-66.2467, -38.72],
            [-65.9889, -38.737],
            [-65.6722, -38.8206],
            [-65.5874, -38.7822],
            [-65.3739, -38.8396],
            [-65.1656, -38.8045],
            [-64.8031, -38.8162],
            [-64.4567, -38.8565],
            [-64.2559, -38.9084],
            [-63.9858, -39.0225],
            [-63.8811, -39.1006],
            [-63.5065, -39.3142],
            [-63.3885, -39.3257],
            [-63.3866, -39.6903],
            [-63.3881, -40.6553],
            [-63.3614, -40.718],
            [-63.1193, -40.7496],
            [-62.9263, -40.8639],
            [-62.7716, -41.0473],
            [-63.142, -41.1606],
            [-63.8075, -41.1592],
            [-63.9933, -41.053],
            [-64.7228, -40.8322],
            [-64.7776, -40.7311],
            [-64.9838, -40.7238],
            [-65.1302, -40.8442],
            [-65.1793, -40.9906],
            [-65.1675, -41.0908],
            [-65.0665, -41.4402],
            [-64.9917, -41.5174],
            [-65.0273, -41.6406],
            [-64.9943, -41.7681],
            [-65.072, -41.9268],
            [-65.0622, -42.01],
            [-65.1331, -41.9994],
            [-71.7598, -41.9997]
          ]
        ]
      },
      properties: {
        name: 'Río Negro',
        id: 'AR-R',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-R'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.4243, -36.136],
            [-70.3576, -36.2072],
            [-70.3824, -36.314],
            [-70.249, -36.3905],
            [-70.2069, -36.5289],
            [-69.9504, -36.7099],
            [-69.9263, -36.7782],
            [-69.792, -36.8499],
            [-69.8117, -37.0028],
            [-69.6319, -37.1528],
            [-69.4595, -37.1786],
            [-69.2597, -37.1524],
            [-69.079, -37.2187],
            [-69.0263, -37.3378],
            [-68.8743, -37.3864],
            [-68.7711, -37.3696],
            [-68.47, -37.5042],
            [-68.2495, -37.5654],
            [-68.252, -38.4386],
            [-68.2542, -38.9695],
            [-68.6138, -39.1199],
            [-68.6796, -39.2215],
            [-68.806, -39.3013],
            [-68.9408, -39.4743],
            [-69.2546, -39.5898],
            [-69.3684, -39.7245],
            [-69.8845, -39.9136],
            [-69.9867, -39.9967],
            [-69.9909, -40.1831],
            [-70.0636, -40.3535],
            [-70.1803, -40.4741],
            [-70.3868, -40.5237],
            [-70.5065, -40.5007],
            [-70.6745, -40.5803],
            [-70.8053, -40.587],
            [-70.9765, -40.6562],
            [-71.113, -40.7931],
            [-71.0341, -40.9235],
            [-71.1533, -41.0569],
            [-71.2794, -41.0958],
            [-71.5325, -41.0352],
            [-71.8592, -41.0113],
            [-71.8503, -40.9125],
            [-71.9502, -40.727],
            [-71.8593, -40.6435],
            [-71.7799, -40.409],
            [-71.664, -40.3345],
            [-71.8181, -40.2046],
            [-71.7914, -40.115],
            [-71.667, -40.0475],
            [-71.6333, -39.9506],
            [-71.705, -39.7275],
            [-71.709, -39.6267],
            [-71.4926, -39.5992],
            [-71.3878, -39.2404],
            [-71.4186, -38.9606],
            [-71.3608, -38.8889],
            [-71.0978, -38.7661],
            [-70.9461, -38.7492],
            [-70.8619, -38.6901],
            [-70.8257, -38.5559],
            [-70.9716, -38.4155],
            [-71.0132, -38.2277],
            [-70.9983, -38.0702],
            [-71.1799, -37.6965],
            [-71.1231, -37.5882],
            [-71.1087, -37.459],
            [-71.1963, -37.3022],
            [-71.1128, -37.1145],
            [-71.1861, -36.8455],
            [-71.0343, -36.4839],
            [-70.9528, -36.4869],
            [-70.7069, -36.4145],
            [-70.7047, -36.2706],
            [-70.5886, -36.1647],
            [-70.4243, -36.136]
          ]
        ]
      },
      properties: {
        name: 'Neuquén',
        id: 'AR-Q',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-Q'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-55.9653, -27.332],
            [-55.8447, -27.4062],
            [-55.7317, -27.4367],
            [-55.6, -27.3421],
            [-55.5475, -27.1122],
            [-55.4177, -26.9864],
            [-55.2511, -26.9361],
            [-55.0556, -26.8033],
            [-54.9493, -26.7683],
            [-54.9289, -26.6883],
            [-54.8422, -26.6575],
            [-54.6932, -26.4281],
            [-54.641, -26.2143],
            [-54.6604, -25.9763],
            [-54.6045, -25.9436],
            [-54.6234, -25.6486],
            [-54.5464, -25.5703],
            [-54.4525, -25.633],
            [-54.3133, -25.5553],
            [-54.1775, -25.562],
            [-54.1076, -25.4964],
            [-53.8886, -25.6338],
            [-53.8253, -25.8014],
            [-53.8269, -25.9514],
            [-53.6643, -26.1933],
            [-53.65, -26.25],
            [-53.7117, -26.3641],
            [-53.7359, -26.6958],
            [-53.6986, -26.8829],
            [-53.8078, -27.1292],
            [-54.0059, -27.1911],
            [-54.1455, -27.29],
            [-54.2803, -27.4245],
            [-54.5297, -27.4908],
            [-54.6861, -27.5553],
            [-54.8136, -27.5356],
            [-54.9082, -27.7303],
            [-55.0217, -27.8339],
            [-55.1798, -27.8563],
            [-55.3213, -27.9221],
            [-55.4478, -28.0867],
            [-55.6263, -28.141],
            [-55.7512, -28.0517],
            [-55.8337, -27.9219],
            [-55.85, -27.7811],
            [-55.9307, -27.6001],
            [-56.0094, -27.4808],
            [-55.9653, -27.332]
          ]
        ]
      },
      properties: {
        name: 'Misiones',
        id: 'AR-N',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-N'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.4243, -36.136],
            [-70.3961, -36.0756],
            [-70.4114, -35.8931],
            [-70.3438, -35.8113],
            [-70.4185, -35.635],
            [-70.3961, -35.515],
            [-70.422, -35.3645],
            [-70.5503, -35.2967],
            [-70.5405, -35.2101],
            [-70.363, -35.1448],
            [-70.362, -35.0044],
            [-70.2325, -34.6619],
            [-70.0455, -34.4003],
            [-70.0524, -34.2961],
            [-69.8881, -34.2783],
            [-69.8176, -34.2347],
            [-69.905, -33.7785],
            [-69.8697, -33.5553],
            [-69.7749, -33.3872],
            [-69.8036, -33.2789],
            [-69.9274, -33.2408],
            [-70.0004, -33.2986],
            [-70.0956, -33.1794],
            [-70.0996, -33.0519],
            [-69.9933, -32.8762],
            [-70.1449, -32.7591],
            [-70.1708, -32.6278],
            [-70.1466, -32.5063],
            [-70.2443, -32.3098],
            [-69.974, -32.3058],
            [-69.6666, -32.2596],
            [-69.5986, -32.115],
            [-69.4421, -32.0513],
            [-69.1239, -31.9733],
            [-69.0186, -32.0814],
            [-68.9315, -32.0712],
            [-68.7339, -32.1522],
            [-68.5009, -32.1812],
            [-68.4685, -32.0843],
            [-68.2263, -32.1082],
            [-68.0223, -32.0682],
            [-67.8197, -32.2444],
            [-67.5722, -32.258],
            [-67.4571, -32.2982],
            [-67.3607, -32.3985],
            [-67.3315, -32.4201],
            [-67.2492, -32.6783],
            [-67.1983, -32.7786],
            [-67.2243, -32.9329],
            [-67.1968, -32.979],
            [-67.1924, -33.2187],
            [-67.1531, -33.4304],
            [-66.9808, -33.7142],
            [-66.9415, -33.8188],
            [-66.7665, -34.0096],
            [-66.7589, -34.1611],
            [-66.8236, -34.2364],
            [-66.8114, -34.39],
            [-66.7433, -34.6056],
            [-66.5922, -34.8111],
            [-66.5407, -34.9372],
            [-66.5078, -35.1281],
            [-66.5189, -35.4842],
            [-66.6357, -35.9697],
            [-66.6173, -36.0001],
            [-67.4372, -36.0009],
            [-68.2895, -35.9989],
            [-68.2478, -36.1628],
            [-68.2495, -37.5654],
            [-68.47, -37.5042],
            [-68.7711, -37.3696],
            [-68.8743, -37.3864],
            [-69.0263, -37.3378],
            [-69.079, -37.2187],
            [-69.2597, -37.1524],
            [-69.4595, -37.1786],
            [-69.6319, -37.1528],
            [-69.8117, -37.0028],
            [-69.792, -36.8499],
            [-69.9263, -36.7782],
            [-69.9504, -36.7099],
            [-70.2069, -36.5289],
            [-70.249, -36.3905],
            [-70.3824, -36.314],
            [-70.3576, -36.2072],
            [-70.4243, -36.136]
          ]
        ]
      },
      properties: {
        name: 'Mendoza',
        id: 'AR-M',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-M'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-65.1276, -30.0593],
            [-65.402, -30.1403],
            [-65.5231, -30.3998],
            [-65.6614, -30.7992],
            [-65.7672, -31.0617],
            [-65.7596, -31.8855],
            [-65.8696, -31.9056],
            [-66.0575, -31.8717],
            [-66.1994, -31.9215],
            [-66.3629, -31.9369],
            [-66.5767, -31.9183],
            [-66.7305, -31.8766],
            [-66.8519, -31.673],
            [-67.0451, -31.533],
            [-67.1078, -31.358],
            [-67.0599, -31.0837],
            [-67.1289, -31.0375],
            [-67.0814, -30.8961],
            [-67.1764, -30.7343],
            [-67.2574, -30.6843],
            [-67.3786, -30.5408],
            [-67.5666, -30.3783],
            [-67.6511, -30.2342],
            [-68.0108, -29.968],
            [-68.0375, -29.912],
            [-68.3142, -29.7133],
            [-68.47, -29.6509],
            [-68.689, -29.6023],
            [-68.9777, -29.6367],
            [-69.0267, -29.5731],
            [-68.9511, -29.1911],
            [-68.8906, -29.1036],
            [-68.9144, -28.9322],
            [-69.1096, -28.7588],
            [-69.1564, -28.6192],
            [-69.3475, -28.4788],
            [-69.6554, -28.4009],
            [-69.5319, -28.314],
            [-69.3506, -28.1261],
            [-69.296, -27.9968],
            [-69.1932, -27.9704],
            [-69.1206, -27.7743],
            [-68.8736, -27.7957],
            [-68.7503, -27.7632],
            [-68.4979, -27.7357],
            [-68.4293, -27.7811],
            [-68.4452, -27.9892],
            [-68.1381, -28.115],
            [-67.9336, -28.1611],
            [-67.8411, -28.3397],
            [-67.6358, -28.3749],
            [-67.5278, -28.2388],
            [-67.3638, -28.3341],
            [-67.1056, -28.3454],
            [-67.0205, -28.2795],
            [-66.9483, -28.2928],
            [-66.5713, -28.434],
            [-66.4967, -28.4981],
            [-66.3936, -28.7069],
            [-66.3578, -28.8593],
            [-66.1199, -28.9724],
            [-65.8108, -29.2241],
            [-65.7538, -29.3903],
            [-65.6781, -29.4842],
            [-65.1276, -30.0593]
          ]
        ]
      },
      properties: {
        name: 'La Rioja',
        id: 'AR-F',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-F'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-63.3885, -39.3257],
            [-63.5065, -39.3142],
            [-63.8811, -39.1006],
            [-63.9858, -39.0225],
            [-64.2559, -38.9084],
            [-64.4567, -38.8565],
            [-64.8031, -38.8162],
            [-65.1656, -38.8045],
            [-65.3739, -38.8396],
            [-65.5874, -38.7822],
            [-65.6722, -38.8206],
            [-65.9889, -38.737],
            [-66.2467, -38.72],
            [-66.3818, -38.7425],
            [-66.5618, -38.7016],
            [-66.6064, -38.5918],
            [-66.7947, -38.4992],
            [-66.9686, -38.455],
            [-67.1365, -38.346],
            [-67.1712, -38.2286],
            [-67.5217, -38.2683],
            [-67.6464, -38.2039],
            [-67.7046, -38.1032],
            [-67.8699, -38.0289],
            [-67.8664, -37.9273],
            [-67.7625, -37.8649],
            [-67.7105, -37.746],
            [-67.7739, -37.6425],
            [-67.9119, -37.5872],
            [-68.2495, -37.5654],
            [-68.2478, -36.1628],
            [-68.2895, -35.9989],
            [-67.4372, -36.0009],
            [-66.6173, -36.0001],
            [-65.0879, -36.0023],
            [-65.0867, -35.002],
            [-64.6139, -35],
            [-63.3841, -35.0022],
            [-63.3894, -36.072],
            [-63.3861, -39.1209],
            [-63.3885, -39.3257]
          ]
        ]
      },
      properties: {
        name: 'La Pampa',
        id: 'AR-L',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-L'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-65.1902, -22.0947],
            [-65.2379, -22.3159],
            [-65.3393, -22.5143],
            [-65.2356, -22.9376],
            [-65.1619, -22.9882],
            [-65.0447, -22.9946],
            [-65.0085, -23.0467],
            [-65.0445, -23.2397],
            [-64.9389, -23.3311],
            [-64.8815, -23.4794],
            [-64.8106, -23.5067],
            [-64.6725, -23.4486],
            [-64.5553, -23.5053],
            [-64.4742, -23.59],
            [-64.3711, -23.5039],
            [-64.1814, -23.5072],
            [-64.1582, -24.1779],
            [-64.1789, -24.2478],
            [-64.3263, -24.4182],
            [-64.4103, -24.4254],
            [-64.5232, -24.4928],
            [-64.6189, -24.615],
            [-64.8195, -24.4526],
            [-64.8953, -24.5841],
            [-64.9425, -24.5961],
            [-65.1217, -24.5059],
            [-65.2846, -24.5007],
            [-65.5845, -24.4054],
            [-65.7465, -24.1917],
            [-65.7672, -24.0675],
            [-65.8851, -23.986],
            [-65.9545, -23.9967],
            [-66.0263, -23.8625],
            [-65.9853, -23.7208],
            [-65.9846, -23.5536],
            [-66.17, -23.4206],
            [-66.3711, -23.3806],
            [-66.4055, -23.5468],
            [-66.3697, -23.6586],
            [-66.3779, -23.7753],
            [-66.4968, -24.1252],
            [-66.5219, -24.1596],
            [-66.6875, -24.1342],
            [-66.86, -24.0613],
            [-67.071, -23.8393],
            [-67.2509, -23.7332],
            [-67.1509, -23.3919],
            [-67.0008, -23.0028],
            [-67.1836, -22.8216],
            [-67.1245, -22.7196],
            [-67.0126, -22.6342],
            [-67.0225, -22.5239],
            [-66.7747, -22.4269],
            [-66.7367, -22.2275],
            [-66.3454, -22.1166],
            [-66.3069, -22.0769],
            [-66.2363, -21.797],
            [-66.0775, -21.8319],
            [-65.9261, -21.9333],
            [-65.75, -22.1101],
            [-65.1902, -22.0947]
          ]
        ]
      },
      properties: {
        name: 'Jujuy',
        id: 'AR-Y',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-Y'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-62.3393, -24.1181],
            [-62.3372, -23.1967],
            [-62.3414, -22.4703],
            [-62.2504, -22.5253],
            [-62.1547, -22.7561],
            [-62.0257, -22.899],
            [-61.9905, -23.0055],
            [-61.7576, -23.1715],
            [-61.7442, -23.2342],
            [-61.5942, -23.2971],
            [-61.4509, -23.418],
            [-61.2948, -23.4892],
            [-61.1113, -23.6084],
            [-61.0107, -23.8108],
            [-60.6139, -23.8944],
            [-60.5774, -23.9463],
            [-60.2797, -24.0385],
            [-60.0381, -24.0097],
            [-59.4708, -24.3547],
            [-59.3389, -24.4889],
            [-58.9386, -24.6856],
            [-58.8079, -24.7815],
            [-58.4493, -24.8596],
            [-58.3522, -24.9679],
            [-58.1818, -24.9756],
            [-57.8792, -25.0849],
            [-57.7611, -25.1715],
            [-57.6321, -25.3864],
            [-57.5563, -25.44],
            [-57.5767, -25.5494],
            [-57.7489, -25.7351],
            [-57.9008, -25.9551],
            [-57.8601, -25.9979],
            [-58.1447, -26.207],
            [-58.1314, -26.2572],
            [-58.21, -26.4204],
            [-58.2173, -26.5414],
            [-58.1817, -26.6561],
            [-58.3167, -26.8728],
            [-58.3527, -26.8854],
            [-58.4649, -26.8304],
            [-58.6678, -26.6378],
            [-58.87, -26.5003],
            [-58.9755, -26.3905],
            [-59.2117, -26.3286],
            [-59.3894, -26.3093],
            [-59.4482, -26.1658],
            [-59.6651, -26.1308],
            [-59.6983, -26.0136],
            [-59.8653, -25.819],
            [-60.1811, -25.6663],
            [-60.2372, -25.4943],
            [-60.3947, -25.345],
            [-60.5055, -25.2055],
            [-60.8087, -25.0695],
            [-61.1031, -24.8381],
            [-61.2042, -24.6697],
            [-61.4523, -24.6194],
            [-61.6528, -24.4862],
            [-61.7005, -24.4104],
            [-61.8168, -24.3386],
            [-62.3393, -24.1181]
          ]
        ]
      },
      properties: {
        name: 'Formosa',
        id: 'AR-P',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-P'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-60.2944, -33.2567],
            [-60.3476, -33.1932],
            [-60.5353, -33.11],
            [-60.6883, -32.8528],
            [-60.7411, -32.61],
            [-60.7445, -32.4897],
            [-60.6786, -32.3233],
            [-60.7089, -32.1583],
            [-60.6494, -31.7384],
            [-60.4329, -31.6935],
            [-60.145, -31.425],
            [-60.0633, -31.2711],
            [-59.9874, -31.2024],
            [-59.7384, -30.8728],
            [-59.5858, -30.64],
            [-59.5829, -30.4322],
            [-59.6509, -30.3378],
            [-59.3967, -30.3056],
            [-59.2398, -30.3411],
            [-59.0269, -30.2058],
            [-58.8711, -30.2279],
            [-58.6847, -30.1633],
            [-58.573, -30.1558],
            [-58.2297, -30.2499],
            [-58.1181, -30.3872],
            [-57.9925, -30.5983],
            [-57.8064, -30.7482],
            [-57.797, -30.8835],
            [-57.9209, -31.271],
            [-58.0044, -31.4177],
            [-58.0789, -31.4759],
            [-57.9898, -31.5483],
            [-57.9816, -31.6194],
            [-58.0402, -31.7892],
            [-58.2001, -31.8948],
            [-58.146, -31.9931],
            [-58.1823, -32.1568],
            [-58.1016, -32.2546],
            [-58.1016, -32.3256],
            [-58.2211, -32.4864],
            [-58.224, -32.5714],
            [-58.1505, -32.9442],
            [-58.1465, -33.0454],
            [-58.2215, -33.0952],
            [-58.4257, -33.0974],
            [-58.4267, -33.2619],
            [-58.5074, -33.4093],
            [-58.5494, -33.6606],
            [-58.5323, -33.7713],
            [-58.4666, -33.8484],
            [-58.4482, -34.0105],
            [-58.598, -34.0553],
            [-59.0317, -33.8322],
            [-59.2349, -33.7992],
            [-59.4397, -33.7272],
            [-59.4992, -33.6611],
            [-59.6023, -33.6831],
            [-59.7594, -33.6192],
            [-59.8842, -33.5153],
            [-60.1088, -33.4004],
            [-60.2944, -33.2567]
          ]
        ]
      },
      properties: {
        name: 'Entre Ríos',
        id: 'AR-E',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-E'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-59.6509, -30.3378],
            [-59.67, -30.2719],
            [-59.5697, -30.0211],
            [-59.6604, -29.8558],
            [-59.5959, -29.6334],
            [-59.5759, -29.3967],
            [-59.4889, -29.2353],
            [-59.37, -29.187],
            [-59.1995, -29.0314],
            [-59.0843, -28.6316],
            [-59.0583, -28.4247],
            [-59.0696, -28.1792],
            [-58.8911, -28.0979],
            [-58.8569, -27.9993],
            [-58.8181, -27.7081],
            [-58.8521, -27.4744],
            [-58.7228, -27.3736],
            [-58.6046, -27.3169],
            [-58.5056, -27.2886],
            [-58.0111, -27.2703],
            [-57.7296, -27.3071],
            [-57.6169, -27.3717],
            [-57.297, -27.4311],
            [-57.0777, -27.4922],
            [-56.8807, -27.4196],
            [-56.7314, -27.5053],
            [-56.55, -27.4525],
            [-56.3981, -27.5844],
            [-56.275, -27.4684],
            [-56.2681, -27.3894],
            [-56.144, -27.3115],
            [-55.9653, -27.332],
            [-56.0094, -27.4808],
            [-55.9307, -27.6001],
            [-55.85, -27.7811],
            [-55.8337, -27.9219],
            [-55.7512, -28.0517],
            [-55.6263, -28.141],
            [-55.7675, -28.2563],
            [-55.7329, -28.3632],
            [-55.8931, -28.3697],
            [-56.0111, -28.5611],
            [-56.17, -28.7283],
            [-56.2756, -28.7767],
            [-56.4274, -29.0696],
            [-56.625, -29.1744],
            [-56.6958, -29.3433],
            [-57.1039, -29.7622],
            [-57.2799, -29.8057],
            [-57.3255, -29.9783],
            [-57.4869, -30.1322],
            [-57.608, -30.1849],
            [-57.6689, -30.3547],
            [-57.8686, -30.4995],
            [-57.8899, -30.5983],
            [-57.8064, -30.7482],
            [-57.9925, -30.5983],
            [-58.1181, -30.3872],
            [-58.2297, -30.2499],
            [-58.573, -30.1558],
            [-58.6847, -30.1633],
            [-58.8711, -30.2279],
            [-59.0269, -30.2058],
            [-59.2398, -30.3411],
            [-59.3967, -30.3056],
            [-59.6509, -30.3378]
          ]
        ]
      },
      properties: {
        name: 'Corrientes',
        id: 'AR-W',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-W'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-65.0867, -35.002],
            [-65.0847, -34.0881],
            [-65.1305, -33.203],
            [-65.006, -33.0002],
            [-64.8738, -32.5551],
            [-64.9142, -32.3149],
            [-65.1955, -32.326],
            [-65.2489, -32.1155],
            [-65.31, -32.0583],
            [-65.6361, -31.8914],
            [-65.7596, -31.8855],
            [-65.7672, -31.0617],
            [-65.6614, -30.7992],
            [-65.5231, -30.3998],
            [-65.402, -30.1403],
            [-65.1276, -30.0593],
            [-64.9272, -29.8614],
            [-64.9663, -29.5753],
            [-64.8824, -29.5572],
            [-64.2705, -29.4207],
            [-64.0441, -29.4819],
            [-64.007, -29.5608],
            [-63.8162, -29.6481],
            [-63.5999, -29.6379],
            [-63.457, -29.6758],
            [-63.3906, -29.7707],
            [-63.1945, -29.7808],
            [-62.2583, -29.7692],
            [-62.0871, -30.1565],
            [-62.1403, -30.4509],
            [-62.1893, -30.5637],
            [-62.0064, -30.675],
            [-61.8472, -30.7286],
            [-62.132, -31.6422],
            [-62.2447, -31.7235],
            [-62.2128, -31.9186],
            [-62.2176, -32.1364],
            [-62.0887, -32.2357],
            [-62.0329, -32.3647],
            [-61.8953, -32.5005],
            [-61.8325, -32.6767],
            [-61.7324, -32.8344],
            [-61.7659, -33.0078],
            [-61.9353, -33.1319],
            [-62.32, -33.6608],
            [-62.8539, -34.3822],
            [-63.382, -34.3793],
            [-63.3841, -35.0022],
            [-64.6139, -35],
            [-65.0867, -35.002]
          ]
        ]
      },
      properties: {
        name: 'Cordoba',
        id: 'AR-X',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-X'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-58.3463, -34.6336],
            [-58.4655, -34.6879],
            [-58.5331, -34.6094],
            [-58.4697, -34.5397],
            [-58.3463, -34.6336]
          ]
        ]
      },
      properties: {
        name: 'Ciudad de Buenos Aires',
        id: 'AR-C',
        CNTRY_NAME: 'Argentina',
        TYPE: 'District'
      },
      id: 'AR-C'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.645, -45.9995],
            [-71.6498, -45.8836],
            [-71.7537, -45.8491],
            [-71.7922, -45.729],
            [-71.7505, -45.5585],
            [-71.4675, -45.4775],
            [-71.2986, -45.3051],
            [-71.3295, -45.2313],
            [-71.5826, -44.9754],
            [-71.7409, -44.9372],
            [-72.0668, -44.9017],
            [-72.0474, -44.754],
            [-71.8411, -44.7924],
            [-71.6536, -44.7828],
            [-71.5183, -44.7439],
            [-71.3397, -44.8034],
            [-71.2259, -44.7468],
            [-71.232, -44.6634],
            [-71.1088, -44.5353],
            [-71.1957, -44.4303],
            [-71.4011, -44.4004],
            [-71.7786, -44.408],
            [-71.7792, -44.0839],
            [-71.6486, -43.9163],
            [-71.8077, -43.7634],
            [-71.7029, -43.6893],
            [-71.7017, -43.6017],
            [-71.9356, -43.4534],
            [-71.8957, -43.3253],
            [-71.7333, -43.2786],
            [-71.7397, -43.1761],
            [-71.8356, -43.133],
            [-71.9993, -43.1218],
            [-72.1367, -43.0058],
            [-72.102, -42.8547],
            [-72.1389, -42.5907],
            [-72.0448, -42.3689],
            [-72.1304, -42.2854],
            [-72.0384, -42.1492],
            [-71.9072, -42.1762],
            [-71.7266, -42.0951],
            [-71.7598, -41.9997],
            [-65.1331, -41.9994],
            [-65.0622, -42.01],
            [-65.0136, -42.0922],
            [-64.8771, -42.1894],
            [-64.6039, -42.257],
            [-64.4645, -42.2656],
            [-64.6009, -42.4073],
            [-64.4519, -42.4458],
            [-64.1354, -42.4364],
            [-64.0472, -42.3778],
            [-64.0836, -42.2666],
            [-64.2931, -42.2619],
            [-63.8719, -42.1025],
            [-63.7325, -42.1],
            [-63.5869, -42.3318],
            [-63.5793, -42.615],
            [-63.6286, -42.7648],
            [-63.6845, -42.8158],
            [-64.0639, -42.886],
            [-64.2482, -42.7751],
            [-64.1988, -42.6453],
            [-64.3167, -42.5509],
            [-64.454, -42.5072],
            [-64.6183, -42.5191],
            [-64.8397, -42.6352],
            [-64.9538, -42.6611],
            [-64.9895, -42.7946],
            [-64.8057, -42.8415],
            [-64.5939, -42.9412],
            [-64.2965, -42.9912],
            [-64.5914, -43.1167],
            [-64.7692, -43.1492],
            [-65.0338, -43.3116],
            [-65.1324, -43.4593],
            [-65.3268, -43.6618],
            [-65.3163, -43.8272],
            [-65.2288, -44.1113],
            [-65.3086, -44.2075],
            [-65.2495, -44.3131],
            [-65.3875, -44.5217],
            [-65.5967, -44.6167],
            [-65.689, -44.7121],
            [-65.7126, -44.8296],
            [-65.5373, -44.8923],
            [-65.6111, -45.0206],
            [-65.6885, -45.0424],
            [-65.8425, -44.9864],
            [-66.1828, -44.9645],
            [-66.5177, -45.0844],
            [-66.5667, -45.1575],
            [-66.8533, -45.2125],
            [-66.9904, -45.2816],
            [-67.1818, -45.5266],
            [-67.2983, -45.5965],
            [-67.3811, -45.7956],
            [-67.5844, -46.0003],
            [-71.645, -45.9995]
          ]
        ]
      },
      properties: {
        name: 'Chubut',
        id: 'AR-U',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-U'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-58.8569, -27.9993],
            [-59.6561, -28.0011],
            [-60.3931, -28.0022],
            [-61.71, -28.0005],
            [-61.7086, -26.8111],
            [-61.7158, -25.6619],
            [-62.7467, -25.6639],
            [-63.3985, -25.6594],
            [-62.6456, -24.7744],
            [-62.3342, -24.3945],
            [-62.3393, -24.1181],
            [-61.8168, -24.3386],
            [-61.7005, -24.4104],
            [-61.6528, -24.4862],
            [-61.4523, -24.6194],
            [-61.2042, -24.6697],
            [-61.1031, -24.8381],
            [-60.8087, -25.0695],
            [-60.5055, -25.2055],
            [-60.3947, -25.345],
            [-60.2372, -25.4943],
            [-60.1811, -25.6663],
            [-59.8653, -25.819],
            [-59.6983, -26.0136],
            [-59.6651, -26.1308],
            [-59.4482, -26.1658],
            [-59.3894, -26.3093],
            [-59.2117, -26.3286],
            [-58.9755, -26.3905],
            [-58.87, -26.5003],
            [-58.6678, -26.6378],
            [-58.4649, -26.8304],
            [-58.3527, -26.8854],
            [-58.4712, -26.9873],
            [-58.6535, -27.193],
            [-58.6046, -27.3169],
            [-58.7228, -27.3736],
            [-58.8521, -27.4744],
            [-58.8181, -27.7081],
            [-58.8569, -27.9993]
          ]
        ]
      },
      properties: {
        name: 'Chaco',
        id: 'AR-H',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-H'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-65.1692, -27.9097],
            [-65.0797, -28.2689],
            [-65.0795, -28.4843],
            [-65.1808, -28.6011],
            [-65.0903, -28.7368],
            [-65.0708, -29.0522],
            [-65.0378, -29.2883],
            [-64.8824, -29.5572],
            [-64.9663, -29.5753],
            [-64.9272, -29.8614],
            [-65.1276, -30.0593],
            [-65.6781, -29.4842],
            [-65.7538, -29.3903],
            [-65.8108, -29.2241],
            [-66.1199, -28.9724],
            [-66.3578, -28.8593],
            [-66.3936, -28.7069],
            [-66.4967, -28.4981],
            [-66.5713, -28.434],
            [-66.9483, -28.2928],
            [-67.0205, -28.2795],
            [-67.1056, -28.3454],
            [-67.3638, -28.3341],
            [-67.5278, -28.2388],
            [-67.6358, -28.3749],
            [-67.8411, -28.3397],
            [-67.9336, -28.1611],
            [-68.1381, -28.115],
            [-68.4452, -27.9892],
            [-68.4293, -27.7811],
            [-68.4979, -27.7357],
            [-68.7503, -27.7632],
            [-68.8736, -27.7957],
            [-69.1206, -27.7743],
            [-69.0735, -27.6307],
            [-68.9035, -27.3642],
            [-68.8781, -27.2401],
            [-68.8075, -27.1186],
            [-68.6458, -27.1385],
            [-68.3233, -27.0233],
            [-68.2878, -26.9128],
            [-68.5836, -26.5053],
            [-68.5453, -26.282],
            [-68.3999, -26.1594],
            [-68.5011, -25.7183],
            [-68.5967, -25.498],
            [-68.5725, -25.3461],
            [-68.4859, -25.1625],
            [-67.8069, -25.2833],
            [-66.5725, -25.269],
            [-66.4697, -25.4824],
            [-66.495, -25.6119],
            [-66.5795, -25.6692],
            [-66.7422, -25.676],
            [-66.8033, -25.7213],
            [-66.7872, -25.8944],
            [-66.6592, -26.0447],
            [-66.5288, -26.2685],
            [-66.4358, -26.3634],
            [-66.3611, -26.3714],
            [-66.2688, -26.229],
            [-66.1528, -26.23],
            [-66.2178, -26.3985],
            [-66.2145, -26.5488],
            [-66.0232, -26.5962],
            [-65.8679, -26.7022],
            [-65.8693, -26.9074],
            [-66.1421, -27.2282],
            [-66.1889, -27.326],
            [-66.0977, -27.3483],
            [-65.9858, -27.4297],
            [-65.9274, -27.6591],
            [-65.8347, -27.7997],
            [-65.6993, -27.8217],
            [-65.6484, -27.9708],
            [-65.5656, -28.0503],
            [-65.5046, -27.9687],
            [-65.3419, -27.8593],
            [-65.2591, -27.9125],
            [-65.1692, -27.9097]
          ]
        ]
      },
      properties: {
        name: 'Catamarca',
        id: 'AR-K',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-K'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-61.8933, -39.2436],
              [-62.0611, -39.1667],
              [-62.0792, -39.088],
              [-61.89, -39.1405],
              [-61.8933, -39.2436]
            ]
          ],
          [
            [
              [-58.4697, -34.5397],
              [-58.5331, -34.6094],
              [-58.4655, -34.6879],
              [-58.3463, -34.6336],
              [-58.1642, -34.7475],
              [-57.7959, -34.8789],
              [-57.4965, -35.0471],
              [-57.3356, -35.1555],
              [-57.1883, -35.3205],
              [-57.1297, -35.4703],
              [-57.3761, -35.7814],
              [-57.3766, -35.9628],
              [-57.3104, -36.0949],
              [-57.1049, -36.2853],
              [-56.9333, -36.3686],
              [-56.7701, -36.3053],
              [-56.6984, -36.4094],
              [-56.671, -36.5817],
              [-56.6605, -36.8735],
              [-57.0828, -37.4528],
              [-57.484, -37.8304],
              [-57.5779, -38.1383],
              [-57.8364, -38.2931],
              [-58.3011, -38.485],
              [-58.767, -38.5997],
              [-59.0321, -38.6906],
              [-59.2672, -38.725],
              [-59.9092, -38.8442],
              [-60.8239, -38.9728],
              [-61.1622, -38.9989],
              [-61.3908, -38.9804],
              [-61.5403, -39.0133],
              [-61.8181, -38.9861],
              [-62.0232, -38.9431],
              [-62.2083, -38.8164],
              [-62.3517, -38.7901],
              [-62.3817, -38.8591],
              [-62.2789, -38.9559],
              [-62.3369, -39.0787],
              [-62.3275, -39.26],
              [-62.2749, -39.3385],
              [-62.0688, -39.5084],
              [-62.1171, -39.8368],
              [-62.2623, -39.8454],
              [-62.3092, -39.8922],
              [-62.369, -40.2092],
              [-62.4888, -40.3026],
              [-62.4215, -40.4581],
              [-62.2475, -40.6012],
              [-62.2258, -40.6616],
              [-62.3208, -40.8653],
              [-62.7267, -41.0468],
              [-62.7716, -41.0473],
              [-62.9263, -40.8639],
              [-63.1193, -40.7496],
              [-63.3614, -40.718],
              [-63.3881, -40.6553],
              [-63.3866, -39.6903],
              [-63.3885, -39.3257],
              [-63.3861, -39.1209],
              [-63.3894, -36.072],
              [-63.3841, -35.0022],
              [-63.382, -34.3793],
              [-62.8539, -34.3822],
              [-61.7106, -34.3769],
              [-60.927, -33.6393],
              [-60.8464, -33.5499],
              [-60.6713, -33.5771],
              [-60.5845, -33.6371],
              [-60.4787, -33.6269],
              [-60.4157, -33.4683],
              [-60.2827, -33.3167],
              [-60.2944, -33.2567],
              [-60.1088, -33.4004],
              [-59.8842, -33.5153],
              [-59.7594, -33.6192],
              [-59.6023, -33.6831],
              [-59.4992, -33.6611],
              [-59.4397, -33.7272],
              [-59.2349, -33.7992],
              [-59.0317, -33.8322],
              [-58.598, -34.0553],
              [-58.4482, -34.0105],
              [-58.3853, -34.0504],
              [-58.3775, -34.1859],
              [-58.4989, -34.3267],
              [-58.4697, -34.5397]
            ]
          ]
        ]
      },
      properties: {
        name: 'Buenos Aires',
        id: 'AR-B',
        CNTRY_NAME: 'Argentina',
        TYPE: 'Province'
      },
      id: 'AR-B'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-60.8997, -51.0936],
              [-60.8914, -51.1055],
              [-60.8829, -51.1067],
              [-60.8774, -51.1141],
              [-60.8585, -51.1189],
              [-60.8574, -51.1273],
              [-60.8685, -51.1297],
              [-60.8852, -51.1221],
              [-60.8893, -51.1105],
              [-60.9027, -51.1059],
              [-60.9189, -51.0946],
              [-60.8997, -51.0936]
            ]
          ],
          [
            [
              [-60.6878, -51.4833],
              [-60.7167, -51.472],
              [-60.7209, -51.465],
              [-60.7137, -51.464],
              [-60.7008, -51.4694],
              [-60.6857, -51.4724],
              [-60.6802, -51.4767],
              [-60.6878, -51.4833]
            ]
          ],
          [
            [
              [-59.7072, -51.386],
              [-59.6868, -51.3868],
              [-59.6821, -51.3889],
              [-59.6795, -51.3947],
              [-59.6788, -51.4052],
              [-59.709, -51.4174],
              [-59.7202, -51.4189],
              [-59.7215, -51.4129],
              [-59.7269, -51.411],
              [-59.7262, -51.4062],
              [-59.7329, -51.4055],
              [-59.7342, -51.4006],
              [-59.7282, -51.3976],
              [-59.7269, -51.3905],
              [-59.7196, -51.3852],
              [-59.7072, -51.386]
            ]
          ],
          [
            [
              [-59.3788, -51.336],
              [-59.376, -51.3374],
              [-59.3666, -51.3497],
              [-59.3461, -51.3596],
              [-59.3396, -51.3599],
              [-59.3237, -51.3651],
              [-59.3106, -51.367],
              [-59.3043, -51.3743],
              [-59.2894, -51.3738],
              [-59.2801, -51.3792],
              [-59.2664, -51.3798],
              [-59.2605, -51.3844],
              [-59.2378, -51.3879],
              [-59.2312, -51.3959],
              [-59.2127, -51.3993],
              [-59.205, -51.3966],
              [-59.1878, -51.3969],
              [-59.1979, -51.4024],
              [-59.2111, -51.4065],
              [-59.2209, -51.4138],
              [-59.2278, -51.4149],
              [-59.2502, -51.4223],
              [-59.2674, -51.4198],
              [-59.2696, -51.4261],
              [-59.2786, -51.4246],
              [-59.2804, -51.4294],
              [-59.2874, -51.4339],
              [-59.2751, -51.4358],
              [-59.2651, -51.4432],
              [-59.2814, -51.4493],
              [-59.2705, -51.4509],
              [-59.2705, -51.4547],
              [-59.2838, -51.4549],
              [-59.2889, -51.4595],
              [-59.2692, -51.459],
              [-59.2407, -51.4483],
              [-59.222, -51.4425],
              [-59.2112, -51.4375],
              [-59.2023, -51.4385],
              [-59.2259, -51.4529],
              [-59.2524, -51.4656],
              [-59.2817, -51.4782],
              [-59.2864, -51.4849],
              [-59.3069, -51.5023],
              [-59.3225, -51.5141],
              [-59.3318, -51.5196],
              [-59.3416, -51.515],
              [-59.3399, -51.5086],
              [-59.3287, -51.4992],
              [-59.3289, -51.4897],
              [-59.342, -51.4924],
              [-59.3605, -51.5186],
              [-59.358, -51.5232],
              [-59.3464, -51.5171],
              [-59.3418, -51.5181],
              [-59.3369, -51.5247],
              [-59.3401, -51.5299],
              [-59.3738, -51.5617],
              [-59.3757, -51.5693],
              [-59.3814, -51.5733],
              [-59.3881, -51.5861],
              [-59.3835, -51.5926],
              [-59.3934, -51.6026],
              [-59.4048, -51.6051],
              [-59.4231, -51.6148],
              [-59.4342, -51.626],
              [-59.4456, -51.6304],
              [-59.4669, -51.6317],
              [-59.4763, -51.6296],
              [-59.4787, -51.6262],
              [-59.474, -51.6188],
              [-59.4633, -51.6079],
              [-59.4622, -51.5986],
              [-59.4523, -51.59],
              [-59.4433, -51.5902],
              [-59.4405, -51.5851],
              [-59.4464, -51.5838],
              [-59.4551, -51.5874],
              [-59.4626, -51.594],
              [-59.4679, -51.6043],
              [-59.4726, -51.6093],
              [-59.4926, -51.618],
              [-59.4947, -51.6203],
              [-59.5107, -51.6241],
              [-59.5189, -51.6295],
              [-59.5296, -51.6399],
              [-59.5362, -51.6386],
              [-59.5345, -51.6314],
              [-59.5286, -51.6241],
              [-59.5151, -51.6144],
              [-59.5199, -51.6119],
              [-59.5334, -51.6184],
              [-59.5372, -51.6249],
              [-59.5601, -51.6397],
              [-59.5674, -51.647],
              [-59.5858, -51.6555],
              [-59.5834, -51.6611],
              [-59.5668, -51.6532],
              [-59.5491, -51.642],
              [-59.5392, -51.6397],
              [-59.5434, -51.6493],
              [-59.5436, -51.6557],
              [-59.5495, -51.6645],
              [-59.5567, -51.6635],
              [-59.5559, -51.6708],
              [-59.5634, -51.6746],
              [-59.5628, -51.6791],
              [-59.574, -51.6888],
              [-59.575, -51.6829],
              [-59.5843, -51.6866],
              [-59.5799, -51.6967],
              [-59.5874, -51.701],
              [-59.5893, -51.7073],
              [-59.5957, -51.7073],
              [-59.5963, -51.7139],
              [-59.603, -51.724],
              [-59.6125, -51.7242],
              [-59.6269, -51.7335],
              [-59.6262, -51.7398],
              [-59.6199, -51.7444],
              [-59.6456, -51.759],
              [-59.6622, -51.7699],
              [-59.6801, -51.779],
              [-59.7186, -51.8027],
              [-59.7249, -51.7985],
              [-59.7394, -51.8031],
              [-59.7414, -51.8109],
              [-59.7561, -51.8214],
              [-59.7611, -51.8288],
              [-59.7727, -51.8387],
              [-59.7918, -51.8525],
              [-59.8013, -51.8643],
              [-59.8242, -51.8868],
              [-59.8348, -51.9037],
              [-59.8354, -51.9092],
              [-59.8434, -51.9269],
              [-59.844, -51.9328],
              [-59.8404, -51.9379],
              [-59.8485, -51.948],
              [-59.8487, -51.9539],
              [-59.8596, -51.9557],
              [-59.8812, -51.9628],
              [-59.8875, -51.9676],
              [-59.8955, -51.9664],
              [-59.8929, -51.958],
              [-59.9076, -51.9562],
              [-59.9254, -51.9678],
              [-59.9368, -51.9777],
              [-59.9579, -51.9869],
              [-59.9902, -51.9951],
              [-60.0061, -51.9898],
              [-60.0055, -51.9857],
              [-59.9956, -51.9841],
              [-59.9878, -51.9803],
              [-59.9852, -51.9752],
              [-59.9935, -51.971],
              [-59.9973, -51.9624],
              [-60.0031, -51.9565],
              [-60.0037, -51.9523],
              [-60.0232, -51.9544],
              [-60.0253, -51.9586],
              [-60.0366, -51.9595],
              [-60.0499, -51.9482],
              [-60.0497, -51.9422],
              [-60.0567, -51.9375],
              [-60.0707, -51.9314],
              [-60.0767, -51.9455],
              [-60.0731, -51.9533],
              [-60.0808, -51.9554],
              [-60.0756, -51.961],
              [-60.0817, -51.9648],
              [-60.0779, -51.9696],
              [-60.085, -51.9755],
              [-60.0726, -51.9819],
              [-60.0737, -51.9878],
              [-60.0869, -51.9978],
              [-60.094, -51.9988],
              [-60.1399, -51.9945],
              [-60.1558, -51.9951],
              [-60.1785, -51.9975],
              [-60.2229, -52.0065],
              [-60.2233, -51.9991],
              [-60.2063, -51.9902],
              [-60.1908, -51.9853],
              [-60.188, -51.9788],
              [-60.2018, -51.9842],
              [-60.2116, -51.9816],
              [-60.219, -51.9853],
              [-60.2248, -51.9812],
              [-60.2322, -51.9807],
              [-60.2468, -51.9848],
              [-60.2716, -52.0035],
              [-60.2651, -52.0101],
              [-60.2619, -52.0223],
              [-60.264, -52.0323],
              [-60.256, -52.0348],
              [-60.2603, -52.0463],
              [-60.2607, -52.0716],
              [-60.2535, -52.0691],
              [-60.2506, -52.0648],
              [-60.249, -52.0394],
              [-60.2449, -52.029],
              [-60.2438, -52.0201],
              [-60.2378, -52.0085],
              [-60.2277, -52.0069],
              [-60.2272, -52.0119],
              [-60.2378, -52.0178],
              [-60.2385, -52.04],
              [-60.2468, -52.0775],
              [-60.2497, -52.0826],
              [-60.2627, -52.0844],
              [-60.2692, -52.0957],
              [-60.2804, -52.1064],
              [-60.2922, -52.12],
              [-60.3096, -52.1376],
              [-60.3102, -52.1417],
              [-60.3324, -52.1514],
              [-60.347, -52.1491],
              [-60.3605, -52.1405],
              [-60.3574, -52.1336],
              [-60.3643, -52.1244],
              [-60.3714, -52.1268],
              [-60.3624, -52.1321],
              [-60.3661, -52.1456],
              [-60.3468, -52.1517],
              [-60.3425, -52.1583],
              [-60.3438, -52.1627],
              [-60.35, -52.1663],
              [-60.3706, -52.1648],
              [-60.3731, -52.16],
              [-60.3821, -52.1627],
              [-60.3899, -52.159],
              [-60.3901, -52.1499],
              [-60.3854, -52.1411],
              [-60.3929, -52.1348],
              [-60.4278, -52.1428],
              [-60.4428, -52.15],
              [-60.4418, -52.1547],
              [-60.4332, -52.158],
              [-60.4282, -52.1563],
              [-60.4202, -52.159],
              [-60.4211, -52.1639],
              [-60.4305, -52.1682],
              [-60.4391, -52.1688],
              [-60.4501, -52.1642],
              [-60.4564, -52.1577],
              [-60.4769, -52.1494],
              [-60.4834, -52.1489],
              [-60.4996, -52.1387],
              [-60.5107, -52.1277],
              [-60.521, -52.1203],
              [-60.5214, -52.1143],
              [-60.5357, -52.1134],
              [-60.5528, -52.1186],
              [-60.5582, -52.117],
              [-60.5608, -52.1236],
              [-60.5403, -52.1232],
              [-60.5335, -52.1275],
              [-60.5341, -52.1321],
              [-60.5407, -52.1366],
              [-60.5394, -52.1429],
              [-60.5273, -52.1345],
              [-60.525, -52.1294],
              [-60.5181, -52.1286],
              [-60.5005, -52.1438],
              [-60.5003, -52.1508],
              [-60.4878, -52.1505],
              [-60.4791, -52.1566],
              [-60.4679, -52.1609],
              [-60.4653, -52.1705],
              [-60.4696, -52.1758],
              [-60.478, -52.1717],
              [-60.4804, -52.178],
              [-60.4908, -52.1812],
              [-60.4795, -52.1842],
              [-60.4788, -52.1924],
              [-60.4874, -52.1943],
              [-60.4953, -52.2023],
              [-60.5068, -52.2056],
              [-60.5178, -52.2141],
              [-60.5281, -52.2166],
              [-60.5449, -52.2257],
              [-60.5611, -52.2242],
              [-60.5665, -52.2261],
              [-60.5762, -52.2419],
              [-60.5827, -52.239],
              [-60.5905, -52.2248],
              [-60.5956, -52.2259],
              [-60.5865, -52.2425],
              [-60.5863, -52.2493],
              [-60.6016, -52.2455],
              [-60.6092, -52.2492],
              [-60.6191, -52.2506],
              [-60.6333, -52.2393],
              [-60.6376, -52.234],
              [-60.6516, -52.2245],
              [-60.653, -52.2196],
              [-60.6403, -52.214],
              [-60.645, -52.2099],
              [-60.6546, -52.2116],
              [-60.6626, -52.2098],
              [-60.668, -52.2049],
              [-60.668, -52.2005],
              [-60.6524, -52.1943],
              [-60.6471, -52.1903],
              [-60.6374, -52.1913],
              [-60.615, -52.2045],
              [-60.6117, -52.1969],
              [-60.6229, -52.1908],
              [-60.6334, -52.1876],
              [-60.6367, -52.1835],
              [-60.6481, -52.1816],
              [-60.6658, -52.1724],
              [-60.6586, -52.1667],
              [-60.666, -52.1641],
              [-60.6797, -52.1628],
              [-60.6868, -52.1514],
              [-60.6946, -52.1524],
              [-60.7059, -52.1439],
              [-60.7152, -52.1409],
              [-60.7123, -52.1347],
              [-60.6996, -52.1319],
              [-60.7052, -52.1254],
              [-60.6923, -52.1216],
              [-60.6858, -52.1229],
              [-60.6472, -52.1253],
              [-60.6601, -52.1214],
              [-60.6685, -52.1157],
              [-60.6813, -52.1127],
              [-60.6886, -52.1061],
              [-60.7035, -52.1112],
              [-60.7086, -52.1107],
              [-60.7149, -52.1168],
              [-60.7292, -52.1157],
              [-60.7359, -52.1127],
              [-60.7382, -52.1038],
              [-60.7626, -52.1027],
              [-60.7749, -52.1058],
              [-60.7856, -52.1035],
              [-60.7914, -52.0962],
              [-60.8011, -52.0924],
              [-60.801, -52.0987],
              [-60.8073, -52.1022],
              [-60.8174, -52.0999],
              [-60.821, -52.1115],
              [-60.8144, -52.1205],
              [-60.8034, -52.1236],
              [-60.7909, -52.1311],
              [-60.7623, -52.1339],
              [-60.7555, -52.1422],
              [-60.7419, -52.1498],
              [-60.742, -52.1583],
              [-60.7388, -52.16],
              [-60.7273, -52.1577],
              [-60.7225, -52.1614],
              [-60.7093, -52.1612],
              [-60.7102, -52.1651],
              [-60.7032, -52.1696],
              [-60.697, -52.1669],
              [-60.6898, -52.1776],
              [-60.6968, -52.1797],
              [-60.7056, -52.1753],
              [-60.7086, -52.1787],
              [-60.7279, -52.1764],
              [-60.7376, -52.1784],
              [-60.7449, -52.1773],
              [-60.7526, -52.1811],
              [-60.7688, -52.1814],
              [-60.7825, -52.1773],
              [-60.7875, -52.1701],
              [-60.7944, -52.1651],
              [-60.7858, -52.1577],
              [-60.7824, -52.151],
              [-60.7893, -52.145],
              [-60.8002, -52.1442],
              [-60.8144, -52.14],
              [-60.8275, -52.1389],
              [-60.8239, -52.1339],
              [-60.8315, -52.125],
              [-60.8288, -52.1182],
              [-60.8401, -52.1211],
              [-60.8455, -52.1152],
              [-60.8542, -52.1111],
              [-60.8622, -52.1124],
              [-60.8711, -52.1094],
              [-60.8827, -52.1097],
              [-60.8924, -52.1124],
              [-60.9001, -52.1117],
              [-60.9035, -52.1071],
              [-60.9163, -52.1002],
              [-60.9185, -52.0965],
              [-60.9171, -52.0863],
              [-60.9349, -52.0814],
              [-60.9455, -52.0835],
              [-60.9442, -52.0745],
              [-60.9627, -52.0703],
              [-60.9701, -52.0639],
              [-60.9661, -52.0606],
              [-60.967, -52.0539],
              [-60.9732, -52.0549],
              [-60.9877, -52.0461],
              [-60.9905, -52.0522],
              [-60.9774, -52.0592],
              [-60.9757, -52.0653],
              [-60.9848, -52.0763],
              [-60.9927, -52.0736],
              [-60.9972, -52.0679],
              [-61.0081, -52.0695],
              [-61.0175, -52.0673],
              [-61.0096, -52.0586],
              [-61.0169, -52.0565],
              [-61.0196, -52.0493],
              [-61.0135, -52.0362],
              [-61.0162, -52.0302],
              [-61.0129, -52.0134],
              [-61.0185, -52.0101],
              [-61.0406, -52.0154],
              [-61.0495, -52.0121],
              [-61.0463, -52.0056],
              [-61.0363, -52.0043],
              [-61.0273, -52.008],
              [-61.0197, -52.0036],
              [-61.0099, -52.0063],
              [-61.0031, -52.0027],
              [-60.9966, -52.0026],
              [-60.9913, -52.0061],
              [-60.9823, -52.0043],
              [-60.9842, -51.9996],
              [-60.9796, -51.9971],
              [-60.963, -52.0003],
              [-60.966, -52.0048],
              [-60.96, -52.0096],
              [-60.9609, -52.0194],
              [-60.974, -52.0216],
              [-60.9737, -52.0321],
              [-60.9602, -52.038],
              [-60.9482, -52.0361],
              [-60.9426, -52.0395],
              [-60.9307, -52.0365],
              [-60.9206, -52.0384],
              [-60.9035, -52.0308],
              [-60.8943, -52.0317],
              [-60.8828, -52.0275],
              [-60.8741, -52.0334],
              [-60.8687, -52.0318],
              [-60.8622, -52.0351],
              [-60.863, -52.0552],
              [-60.8525, -52.0668],
              [-60.8427, -52.0668],
              [-60.8308, -52.0723],
              [-60.8149, -52.0676],
              [-60.8126, -52.0639],
              [-60.8229, -52.0612],
              [-60.8363, -52.0662],
              [-60.8532, -52.0592],
              [-60.8469, -52.0529],
              [-60.8546, -52.0519],
              [-60.8587, -52.0431],
              [-60.8572, -52.0399],
              [-60.8607, -52.0318],
              [-60.8699, -52.0274],
              [-60.8697, -52.0234],
              [-60.8609, -52.0204],
              [-60.8455, -52.0237],
              [-60.8345, -52.0304],
              [-60.8306, -52.0346],
              [-60.8232, -52.0354],
              [-60.8108, -52.0306],
              [-60.7971, -52.0274],
              [-60.7849, -52.0286],
              [-60.7738, -52.0192],
              [-60.7624, -52.0166],
              [-60.7385, -52.0146],
              [-60.7295, -52.0169],
              [-60.722, -52.0132],
              [-60.7216, -52.0077],
              [-60.7257, -52.0053],
              [-60.7244, -51.9948],
              [-60.7468, -51.9966],
              [-60.7512, -51.9935],
              [-60.7587, -51.9952],
              [-60.7681, -51.9935],
              [-60.77, -51.9889],
              [-60.7875, -51.996],
              [-60.7977, -51.9955],
              [-60.7936, -51.9876],
              [-60.798, -51.9829],
              [-60.8123, -51.9798],
              [-60.8122, -51.9757],
              [-60.824, -51.9694],
              [-60.8303, -51.9615],
              [-60.8289, -51.957],
              [-60.8216, -51.9538],
              [-60.8219, -51.9477],
              [-60.8036, -51.9518],
              [-60.771, -51.9527],
              [-60.7544, -51.9513],
              [-60.7448, -51.9547],
              [-60.7448, -51.961],
              [-60.7327, -51.954],
              [-60.7201, -51.9554],
              [-60.7143, -51.9595],
              [-60.704, -51.9551],
              [-60.695, -51.9547],
              [-60.6802, -51.9581],
              [-60.6843, -51.9665],
              [-60.683, -51.9847],
              [-60.6879, -51.9928],
              [-60.6926, -51.9951],
              [-60.6913, -52.0022],
              [-60.6871, -52.0039],
              [-60.6777, -51.9978],
              [-60.6784, -51.9927],
              [-60.6754, -51.9822],
              [-60.6792, -51.9777],
              [-60.6759, -51.9738],
              [-60.6789, -51.9655],
              [-60.6664, -51.9637],
              [-60.6572, -51.9711],
              [-60.6555, -51.9868],
              [-60.658, -51.9969],
              [-60.6589, -52.0097],
              [-60.6686, -52.0133],
              [-60.6585, -52.016],
              [-60.6566, -52.0234],
              [-60.6433, -52.0204],
              [-60.6371, -52.0147],
              [-60.6481, -52.0089],
              [-60.648, -52.0044],
              [-60.6418, -52.0002],
              [-60.647, -51.9959],
              [-60.6478, -51.9852],
              [-60.645, -51.9799],
              [-60.6508, -51.9695],
              [-60.6492, -51.9655],
              [-60.6341, -51.964],
              [-60.6247, -51.9606],
              [-60.6151, -51.9615],
              [-60.6228, -51.967],
              [-60.6036, -51.9683],
              [-60.5946, -51.9716],
              [-60.5814, -51.972],
              [-60.5693, -51.9753],
              [-60.5651, -51.974],
              [-60.5553, -51.9796],
              [-60.5586, -51.9817],
              [-60.558, -51.9884],
              [-60.562, -51.9956],
              [-60.5738, -52.0004],
              [-60.5797, -52.0109],
              [-60.5725, -52.0094],
              [-60.5673, -52.0034],
              [-60.559, -52.0019],
              [-60.5486, -52.007],
              [-60.543, -52.0154],
              [-60.5384, -52.0117],
              [-60.5411, -52.0071],
              [-60.5567, -52.0002],
              [-60.5578, -51.9952],
              [-60.5511, -51.9832],
              [-60.547, -51.9827],
              [-60.538, -51.9877],
              [-60.5244, -51.99],
              [-60.5179, -51.993],
              [-60.5169, -51.9993],
              [-60.5099, -52.0006],
              [-60.4985, -52.0079],
              [-60.4956, -52.0119],
              [-60.4877, -52.0117],
              [-60.506, -51.9989],
              [-60.5066, -51.995],
              [-60.5206, -51.9882],
              [-60.5383, -51.9844],
              [-60.5463, -51.9789],
              [-60.5457, -51.9747],
              [-60.5359, -51.9739],
              [-60.5344, -51.9697],
              [-60.5186, -51.9719],
              [-60.4962, -51.9715],
              [-60.4795, -51.9673],
              [-60.4585, -51.9656],
              [-60.4363, -51.96],
              [-60.4327, -51.9565],
              [-60.4203, -51.9504],
              [-60.4164, -51.9464],
              [-60.4037, -51.9385],
              [-60.3935, -51.9343],
              [-60.388, -51.9286],
              [-60.3913, -51.9257],
              [-60.3845, -51.9187],
              [-60.3939, -51.9173],
              [-60.404, -51.9233],
              [-60.4129, -51.9251],
              [-60.4193, -51.9234],
              [-60.4272, -51.9278],
              [-60.435, -51.9281],
              [-60.4486, -51.9395],
              [-60.4639, -51.9403],
              [-60.4637, -51.9343],
              [-60.4743, -51.9374],
              [-60.4774, -51.9444],
              [-60.4869, -51.9467],
              [-60.497, -51.9463],
              [-60.5054, -51.9424],
              [-60.5201, -51.947],
              [-60.539, -51.9414],
              [-60.5373, -51.9361],
              [-60.5424, -51.9323],
              [-60.5622, -51.9297],
              [-60.5694, -51.9273],
              [-60.5938, -51.9268],
              [-60.5991, -51.9291],
              [-60.6073, -51.9281],
              [-60.6122, -51.9201],
              [-60.6266, -51.9218],
              [-60.6256, -51.9173],
              [-60.6158, -51.9171],
              [-60.6078, -51.9109],
              [-60.5971, -51.9147],
              [-60.5828, -51.9217],
              [-60.5686, -51.9202],
              [-60.5597, -51.9214],
              [-60.5515, -51.9257],
              [-60.5422, -51.9242],
              [-60.531, -51.9265],
              [-60.5258, -51.9327],
              [-60.5207, -51.9275],
              [-60.5145, -51.9304],
              [-60.5045, -51.9257],
              [-60.4968, -51.9288],
              [-60.4862, -51.9272],
              [-60.4815, -51.9187],
              [-60.4758, -51.9138],
              [-60.4751, -51.907],
              [-60.4844, -51.9082],
              [-60.4808, -51.9008],
              [-60.473, -51.8951],
              [-60.4637, -51.8957],
              [-60.452, -51.886],
              [-60.443, -51.8746],
              [-60.4354, -51.8689],
              [-60.4356, -51.8655],
              [-60.4546, -51.8672],
              [-60.4607, -51.8635],
              [-60.4491, -51.855],
              [-60.4326, -51.8453],
              [-60.4316, -51.8426],
              [-60.4467, -51.8328],
              [-60.4533, -51.8319],
              [-60.4588, -51.8348],
              [-60.4557, -51.839],
              [-60.472, -51.8519],
              [-60.4761, -51.8527],
              [-60.4864, -51.8486],
              [-60.4787, -51.8443],
              [-60.4805, -51.8362],
              [-60.4891, -51.8358],
              [-60.4942, -51.828],
              [-60.4854, -51.813],
              [-60.4741, -51.8249],
              [-60.4664, -51.8244],
              [-60.462, -51.8175],
              [-60.4614, -51.8108],
              [-60.4514, -51.8094],
              [-60.4486, -51.8057],
              [-60.4541, -51.8001],
              [-60.4468, -51.795],
              [-60.448, -51.79],
              [-60.4428, -51.786],
              [-60.4446, -51.7807],
              [-60.4326, -51.7708],
              [-60.4231, -51.773],
              [-60.4112, -51.7701],
              [-60.4193, -51.7835],
              [-60.4063, -51.7839],
              [-60.4039, -51.7789],
              [-60.3936, -51.7696],
              [-60.3841, -51.7709],
              [-60.3858, -51.7652],
              [-60.3807, -51.7567],
              [-60.3729, -51.7619],
              [-60.3688, -51.7593],
              [-60.3593, -51.76],
              [-60.3531, -51.7708],
              [-60.3572, -51.7801],
              [-60.3709, -51.7903],
              [-60.3779, -51.7863],
              [-60.3926, -51.7906],
              [-60.3999, -51.8009],
              [-60.3969, -51.8084],
              [-60.3887, -51.7967],
              [-60.3774, -51.8043],
              [-60.3669, -51.8011],
              [-60.3672, -51.7938],
              [-60.3537, -51.7914],
              [-60.3521, -51.7981],
              [-60.3415, -51.8085],
              [-60.3573, -51.8105],
              [-60.3544, -51.8203],
              [-60.3585, -51.8226],
              [-60.3661, -51.8319],
              [-60.3825, -51.8375],
              [-60.3798, -51.8445],
              [-60.3701, -51.8391],
              [-60.3671, -51.8458],
              [-60.351, -51.8498],
              [-60.3468, -51.848],
              [-60.3339, -51.8552],
              [-60.3389, -51.8633],
              [-60.3252, -51.8592],
              [-60.3286, -51.8525],
              [-60.3188, -51.8513],
              [-60.3289, -51.8451],
              [-60.3187, -51.8324],
              [-60.316, -51.823],
              [-60.3211, -51.818],
              [-60.3217, -51.8127],
              [-60.3151, -51.8116],
              [-60.3052, -51.8192],
              [-60.2948, -51.8211],
              [-60.2895, -51.8283],
              [-60.2823, -51.8237],
              [-60.284, -51.8174],
              [-60.2883, -51.8138],
              [-60.2865, -51.8085],
              [-60.2979, -51.8059],
              [-60.3082, -51.8002],
              [-60.3141, -51.7993],
              [-60.3058, -51.7894],
              [-60.2956, -51.7871],
              [-60.2875, -51.7799],
              [-60.2947, -51.7729],
              [-60.3061, -51.7723],
              [-60.3012, -51.7674],
              [-60.3103, -51.7646],
              [-60.3116, -51.7722],
              [-60.319, -51.7729],
              [-60.3178, -51.7658],
              [-60.3118, -51.7622],
              [-60.3126, -51.7551],
              [-60.2974, -51.7547],
              [-60.2882, -51.7506],
              [-60.2728, -51.748],
              [-60.2532, -51.7423],
              [-60.256, -51.7508],
              [-60.264, -51.7534],
              [-60.2598, -51.7578],
              [-60.2445, -51.7577],
              [-60.2422, -51.7598],
              [-60.2537, -51.7694],
              [-60.2516, -51.7737],
              [-60.2436, -51.7738],
              [-60.2245, -51.7706],
              [-60.2158, -51.7659],
              [-60.2012, -51.7775],
              [-60.2045, -51.7682],
              [-60.2171, -51.7622],
              [-60.2185, -51.7551],
              [-60.2132, -51.7509],
              [-60.2053, -51.7528],
              [-60.2017, -51.7634],
              [-60.2002, -51.7739],
              [-60.1881, -51.7709],
              [-60.1826, -51.7605],
              [-60.1752, -51.7523],
              [-60.1711, -51.744],
              [-60.1709, -51.7382],
              [-60.1749, -51.733],
              [-60.1712, -51.726],
              [-60.1761, -51.7221],
              [-60.1783, -51.7135],
              [-60.1703, -51.7063],
              [-60.1703, -51.7025],
              [-60.1771, -51.6983],
              [-60.1878, -51.6948],
              [-60.1938, -51.6963],
              [-60.2105, -51.6959],
              [-60.228, -51.6904],
              [-60.2345, -51.6908],
              [-60.2463, -51.6955],
              [-60.2532, -51.7069],
              [-60.2599, -51.7119],
              [-60.2699, -51.7151],
              [-60.2812, -51.7127],
              [-60.2941, -51.7126],
              [-60.2999, -51.7088],
              [-60.3038, -51.6974],
              [-60.3139, -51.7046],
              [-60.3348, -51.7102],
              [-60.3639, -51.7093],
              [-60.3617, -51.7147],
              [-60.3664, -51.7223],
              [-60.358, -51.726],
              [-60.3606, -51.7359],
              [-60.3554, -51.7433],
              [-60.3429, -51.7437],
              [-60.3434, -51.7492],
              [-60.3498, -51.7526],
              [-60.3589, -51.7531],
              [-60.3663, -51.7489],
              [-60.3751, -51.7506],
              [-60.3783, -51.7404],
              [-60.3926, -51.7408],
              [-60.3913, -51.7444],
              [-60.4066, -51.7558],
              [-60.4191, -51.7601],
              [-60.4273, -51.7664],
              [-60.4464, -51.7699],
              [-60.4647, -51.7696],
              [-60.4689, -51.765],
              [-60.4617, -51.7576],
              [-60.4679, -51.7557],
              [-60.4715, -51.7683],
              [-60.479, -51.7653],
              [-60.4863, -51.7658],
              [-60.5036, -51.761],
              [-60.5092, -51.767],
              [-60.5182, -51.7674],
              [-60.5275, -51.775],
              [-60.5153, -51.7795],
              [-60.5133, -51.786],
              [-60.5076, -51.788],
              [-60.5063, -51.794],
              [-60.5103, -51.8],
              [-60.521, -51.8026],
              [-60.5309, -51.7965],
              [-60.5465, -51.7972],
              [-60.5537, -51.7939],
              [-60.5516, -51.7871],
              [-60.5548, -51.7767],
              [-60.5436, -51.7704],
              [-60.5469, -51.7667],
              [-60.575, -51.7704],
              [-60.5776, -51.7588],
              [-60.5907, -51.751],
              [-60.5947, -51.745],
              [-60.6151, -51.7264],
              [-60.6231, -51.7241],
              [-60.6347, -51.7058],
              [-60.6328, -51.6983],
              [-60.6463, -51.6845],
              [-60.6457, -51.6739],
              [-60.6361, -51.6645],
              [-60.6239, -51.6662],
              [-60.6149, -51.6728],
              [-60.6019, -51.6752],
              [-60.5946, -51.6726],
              [-60.5569, -51.6886],
              [-60.5482, -51.6946],
              [-60.5341, -51.7003],
              [-60.5061, -51.7012],
              [-60.4944, -51.7039],
              [-60.4871, -51.7036],
              [-60.4712, -51.6989],
              [-60.4586, -51.6996],
              [-60.4184, -51.69],
              [-60.3979, -51.6812],
              [-60.3843, -51.677],
              [-60.3769, -51.6702],
              [-60.3623, -51.6715],
              [-60.3588, -51.6676],
              [-60.3518, -51.6663],
              [-60.3224, -51.6712],
              [-60.3077, -51.6753],
              [-60.3044, -51.674],
              [-60.2897, -51.675],
              [-60.2754, -51.674],
              [-60.2411, -51.6663],
              [-60.2364, -51.6635],
              [-60.2344, -51.6551],
              [-60.2376, -51.6506],
              [-60.222, -51.6505],
              [-60.204, -51.6548],
              [-60.1915, -51.6603],
              [-60.1825, -51.6685],
              [-60.1716, -51.6746],
              [-60.1594, -51.6852],
              [-60.1414, -51.686],
              [-60.1278, -51.6916],
              [-60.1078, -51.6943],
              [-60.0968, -51.6973],
              [-60.0691, -51.712],
              [-60.0656, -51.7179],
              [-60.0607, -51.7179],
              [-60.0614, -51.7083],
              [-60.074, -51.707],
              [-60.081, -51.7035],
              [-60.0868, -51.6926],
              [-60.0871, -51.6843],
              [-60.082, -51.6835],
              [-60.0863, -51.6703],
              [-60.0983, -51.6653],
              [-60.1084, -51.6748],
              [-60.1018, -51.6769],
              [-60.1078, -51.6816],
              [-60.1164, -51.6725],
              [-60.1251, -51.6789],
              [-60.1469, -51.6676],
              [-60.1562, -51.6663],
              [-60.1742, -51.6602],
              [-60.1885, -51.6438],
              [-60.2112, -51.6277],
              [-60.2292, -51.6264],
              [-60.2335, -51.6223],
              [-60.2467, -51.6231],
              [-60.2465, -51.616],
              [-60.238, -51.615],
              [-60.2256, -51.6173],
              [-60.2242, -51.615],
              [-60.2125, -51.6149],
              [-60.2129, -51.6096],
              [-60.2278, -51.6039],
              [-60.2582, -51.6059],
              [-60.2665, -51.605],
              [-60.2831, -51.599],
              [-60.2858, -51.5934],
              [-60.2966, -51.592],
              [-60.2944, -51.585],
              [-60.3024, -51.5745],
              [-60.3098, -51.5765],
              [-60.3275, -51.5768],
              [-60.3599, -51.5743],
              [-60.3729, -51.5708],
              [-60.389, -51.5631],
              [-60.3905, -51.5565],
              [-60.4037, -51.5543],
              [-60.4159, -51.5464],
              [-60.4286, -51.5422],
              [-60.4653, -51.5325],
              [-60.4719, -51.5332],
              [-60.4789, -51.5405],
              [-60.4917, -51.5302],
              [-60.504, -51.5245],
              [-60.4934, -51.5208],
              [-60.5046, -51.5061],
              [-60.5042, -51.5027],
              [-60.4912, -51.495],
              [-60.4795, -51.4951],
              [-60.4717, -51.4913],
              [-60.446, -51.4917],
              [-60.4098, -51.4973],
              [-60.3925, -51.502],
              [-60.3747, -51.5],
              [-60.3736, -51.4836],
              [-60.3883, -51.4811],
              [-60.3896, -51.475],
              [-60.4093, -51.462],
              [-60.4133, -51.4552],
              [-60.4304, -51.4554],
              [-60.4387, -51.4517],
              [-60.4412, -51.4406],
              [-60.4442, -51.4372],
              [-60.4652, -51.4379],
              [-60.4702, -51.439],
              [-60.4791, -51.4454],
              [-60.4859, -51.4479],
              [-60.5041, -51.451],
              [-60.5098, -51.4509],
              [-60.526, -51.4462],
              [-60.5386, -51.4453],
              [-60.5581, -51.4407],
              [-60.585, -51.4406],
              [-60.5966, -51.4339],
              [-60.6015, -51.4254],
              [-60.6148, -51.4182],
              [-60.6292, -51.4184],
              [-60.6427, -51.4147],
              [-60.6372, -51.4085],
              [-60.6211, -51.4029],
              [-60.6202, -51.3994],
              [-60.6257, -51.3871],
              [-60.6237, -51.3794],
              [-60.6382, -51.3774],
              [-60.6422, -51.3725],
              [-60.6559, -51.3661],
              [-60.6545, -51.3583],
              [-60.6408, -51.3573],
              [-60.6262, -51.3616],
              [-60.6055, -51.3637],
              [-60.603, -51.3602],
              [-60.6235, -51.3506],
              [-60.6495, -51.3429],
              [-60.6477, -51.3399],
              [-60.6275, -51.3413],
              [-60.6154, -51.3457],
              [-60.5973, -51.3505],
              [-60.5777, -51.36],
              [-60.5618, -51.3633],
              [-60.5537, -51.3673],
              [-60.5267, -51.3697],
              [-60.5117, -51.374],
              [-60.5018, -51.3784],
              [-60.4791, -51.3866],
              [-60.4697, -51.3918],
              [-60.4497, -51.3973],
              [-60.4385, -51.4026],
              [-60.4316, -51.4035],
              [-60.4199, -51.4103],
              [-60.3969, -51.4176],
              [-60.3746, -51.4352],
              [-60.3705, -51.4405],
              [-60.3507, -51.4498],
              [-60.3211, -51.46],
              [-60.3003, -51.4689],
              [-60.272, -51.4772],
              [-60.2354, -51.4903],
              [-60.2179, -51.4926],
              [-60.2007, -51.4926],
              [-60.1838, -51.4908],
              [-60.1718, -51.4877],
              [-60.1617, -51.4789],
              [-60.143, -51.4803],
              [-60.1477, -51.484],
              [-60.1578, -51.4843],
              [-60.1637, -51.4878],
              [-60.1588, -51.4943],
              [-60.1487, -51.498],
              [-60.1293, -51.4967],
              [-60.122, -51.4987],
              [-60.1064, -51.4928],
              [-60.1063, -51.4987],
              [-60.0947, -51.4986],
              [-60.0844, -51.4943],
              [-60.0802, -51.4833],
              [-60.0722, -51.4799],
              [-60.0675, -51.4829],
              [-60.0593, -51.4793],
              [-60.0549, -51.4856],
              [-60.0471, -51.4867],
              [-60.0405, -51.4769],
              [-60.0108, -51.473],
              [-59.9879, -51.4717],
              [-59.9764, -51.4699],
              [-59.9821, -51.4629],
              [-59.9875, -51.463],
              [-60.0104, -51.4506],
              [-60.0298, -51.449],
              [-60.0381, -51.4504],
              [-60.0692, -51.4497],
              [-60.083, -51.4479],
              [-60.0847, -51.4403],
              [-60.0936, -51.4359],
              [-60.0814, -51.4316],
              [-60.0806, -51.4291],
              [-60.0691, -51.4256],
              [-60.0709, -51.4186],
              [-60.0636, -51.4186],
              [-60.0621, -51.4142],
              [-60.0704, -51.4118],
              [-60.0756, -51.4055],
              [-60.0714, -51.4038],
              [-60.0464, -51.4107],
              [-60.025, -51.4125],
              [-60.0275, -51.4068],
              [-60.0262, -51.4007],
              [-60.035, -51.3979],
              [-60.0382, -51.3944],
              [-60.0427, -51.3827],
              [-60.014, -51.3802],
              [-59.9979, -51.3781],
              [-59.9905, -51.3748],
              [-59.9862, -51.3785],
              [-59.9969, -51.3828],
              [-59.9846, -51.3864],
              [-59.9975, -51.3962],
              [-60.0073, -51.3984],
              [-60.0105, -51.4082],
              [-60.0083, -51.4122],
              [-59.9985, -51.4167],
              [-59.9766, -51.4164],
              [-59.9786, -51.4098],
              [-59.9755, -51.4042],
              [-59.9703, -51.4021],
              [-59.96, -51.4032],
              [-59.9565, -51.393],
              [-59.945, -51.3888],
              [-59.9305, -51.3865],
              [-59.9152, -51.392],
              [-59.8996, -51.3872],
              [-59.9002, -51.382],
              [-59.8955, -51.3782],
              [-59.8781, -51.3794],
              [-59.8655, -51.385],
              [-59.8655, -51.3888],
              [-59.8728, -51.3924],
              [-59.8913, -51.3922],
              [-59.8838, -51.3985],
              [-59.8729, -51.4028],
              [-59.8568, -51.3988],
              [-59.8307, -51.3967],
              [-59.821, -51.3934],
              [-59.8217, -51.4004],
              [-59.8341, -51.4032],
              [-59.861, -51.4051],
              [-59.8655, -51.4095],
              [-59.8503, -51.4094],
              [-59.8277, -51.4127],
              [-59.821, -51.4112],
              [-59.813, -51.4052],
              [-59.7983, -51.4089],
              [-59.7856, -51.4099],
              [-59.7746, -51.4147],
              [-59.7636, -51.4142],
              [-59.759, -51.4047],
              [-59.7535, -51.4091],
              [-59.7366, -51.4087],
              [-59.7389, -51.4155],
              [-59.7272, -51.4167],
              [-59.7254, -51.4224],
              [-59.7381, -51.4241],
              [-59.7448, -51.4232],
              [-59.7632, -51.4272],
              [-59.7686, -51.4324],
              [-59.7698, -51.4401],
              [-59.7779, -51.4358],
              [-59.7708, -51.4294],
              [-59.7752, -51.4278],
              [-59.7995, -51.4331],
              [-59.8069, -51.4372],
              [-59.804, -51.4473],
              [-59.7927, -51.4541],
              [-59.7792, -51.4523],
              [-59.7658, -51.4535],
              [-59.7573, -51.4512],
              [-59.7549, -51.4472],
              [-59.7448, -51.4541],
              [-59.7286, -51.4541],
              [-59.7199, -51.4486],
              [-59.7064, -51.4466],
              [-59.6937, -51.4431],
              [-59.669, -51.4421],
              [-59.6548, -51.4374],
              [-59.6356, -51.4326],
              [-59.6218, -51.4386],
              [-59.6162, -51.4308],
              [-59.6083, -51.4312],
              [-59.5946, -51.4345],
              [-59.5735, -51.4304],
              [-59.5515, -51.4218],
              [-59.5278, -51.4087],
              [-59.5189, -51.4012],
              [-59.5184, -51.3975],
              [-59.4989, -51.3908],
              [-59.4856, -51.3924],
              [-59.4846, -51.3998],
              [-59.489, -51.4018],
              [-59.4856, -51.4071],
              [-59.478, -51.4094],
              [-59.4847, -51.4139],
              [-59.4963, -51.4137],
              [-59.5089, -51.418],
              [-59.537, -51.4354],
              [-59.5504, -51.4353],
              [-59.5711, -51.4377],
              [-59.5861, -51.4417],
              [-59.5961, -51.442],
              [-59.5859, -51.4523],
              [-59.5819, -51.4523],
              [-59.5527, -51.4589],
              [-59.5405, -51.4607],
              [-59.5387, -51.4647],
              [-59.5202, -51.4718],
              [-59.5357, -51.4533],
              [-59.5383, -51.4477],
              [-59.5327, -51.4449],
              [-59.5221, -51.4496],
              [-59.5108, -51.4516],
              [-59.5014, -51.4558],
              [-59.497, -51.4526],
              [-59.4861, -51.4546],
              [-59.4731, -51.4534],
              [-59.4616, -51.4591],
              [-59.4473, -51.4532],
              [-59.4443, -51.4476],
              [-59.4324, -51.4422],
              [-59.4228, -51.4452],
              [-59.4036, -51.4355],
              [-59.3986, -51.4316],
              [-59.3814, -51.4322],
              [-59.3836, -51.427],
              [-59.3933, -51.4194],
              [-59.4196, -51.4116],
              [-59.4338, -51.4033],
              [-59.4511, -51.4027],
              [-59.4672, -51.404],
              [-59.4731, -51.4012],
              [-59.4717, -51.3974],
              [-59.4625, -51.3941],
              [-59.4452, -51.3931],
              [-59.4403, -51.391],
              [-59.432, -51.3825],
              [-59.4319, -51.3781],
              [-59.4439, -51.3653],
              [-59.4504, -51.3553],
              [-59.4472, -51.35],
              [-59.4382, -51.3507],
              [-59.4317, -51.355],
              [-59.4244, -51.3538],
              [-59.4191, -51.3586],
              [-59.4027, -51.3531],
              [-59.4044, -51.3478],
              [-59.4128, -51.3445],
              [-59.4144, -51.3382],
              [-59.4107, -51.3347],
              [-59.4038, -51.3376],
              [-59.3927, -51.3366],
              [-59.3865, -51.3328],
              [-59.3788, -51.336]
            ]
          ],
          [
            [
              [-61.1171, -51.0344],
              [-61.1146, -51.0394],
              [-61.0975, -51.04],
              [-61.0934, -51.0438],
              [-61.092, -51.0532],
              [-61.078, -51.0605],
              [-61.0705, -51.0744],
              [-61.0554, -51.0769],
              [-61.0508, -51.0833],
              [-61.0456, -51.0856],
              [-61.0466, -51.0904],
              [-61.0564, -51.0862],
              [-61.0665, -51.0883],
              [-61.0937, -51.0847],
              [-61.098, -51.0781],
              [-61.1186, -51.0767],
              [-61.1283, -51.0801],
              [-61.1269, -51.073],
              [-61.1347, -51.0731],
              [-61.131, -51.0668],
              [-61.1154, -51.0627],
              [-61.1135, -51.0552],
              [-61.1238, -51.0484],
              [-61.1327, -51.0348],
              [-61.1206, -51.0316],
              [-61.1171, -51.0344]
            ]
          ],
          [
            [
              [-60.7728, -51.5711],
              [-60.7586, -51.5688],
              [-60.7443, -51.5711],
              [-60.7212, -51.58],
              [-60.7178, -51.5867],
              [-60.7346, -51.5874],
              [-60.774, -51.5823],
              [-60.7888, -51.5821],
              [-60.805, -51.5749],
              [-60.8056, -51.5694],
              [-60.7961, -51.5667],
              [-60.7776, -51.5688],
              [-60.7728, -51.5711]
            ]
          ],
          [
            [
              [-59.894, -51.2197],
              [-59.9113, -51.2201],
              [-59.9256, -51.2144],
              [-59.9242, -51.2107],
              [-59.9143, -51.2071],
              [-59.9069, -51.213],
              [-59.8949, -51.2168],
              [-59.894, -51.2197]
            ]
          ],
          [
            [
              [-60.5856, -51.2518],
              [-60.5764, -51.2502],
              [-60.5695, -51.2582],
              [-60.5612, -51.2612],
              [-60.5544, -51.2683],
              [-60.5434, -51.2739],
              [-60.5378, -51.2792],
              [-60.5208, -51.284],
              [-60.5109, -51.2913],
              [-60.503, -51.3036],
              [-60.5142, -51.3096],
              [-60.5125, -51.317],
              [-60.5187, -51.3188],
              [-60.5264, -51.3119],
              [-60.5226, -51.3048],
              [-60.5246, -51.3014],
              [-60.5383, -51.2946],
              [-60.554, -51.3001],
              [-60.571, -51.2955],
              [-60.5802, -51.2895],
              [-60.5867, -51.2815],
              [-60.5986, -51.2741],
              [-60.5959, -51.2664],
              [-60.5997, -51.2591],
              [-60.5936, -51.2586],
              [-60.5976, -51.25],
              [-60.5856, -51.2518]
            ]
          ],
          [
            [
              [-60.4175, -51.604],
              [-60.4214, -51.6095],
              [-60.4178, -51.6159],
              [-60.4076, -51.6201],
              [-60.4136, -51.6252],
              [-60.4308, -51.6218],
              [-60.4309, -51.6161],
              [-60.4366, -51.6148],
              [-60.4497, -51.6046],
              [-60.447, -51.601],
              [-60.4337, -51.6057],
              [-60.4233, -51.6006],
              [-60.4175, -51.604]
            ]
          ],
          [
            [
              [-59.2141, -51.5783],
              [-59.2019, -51.579],
              [-59.2099, -51.588],
              [-59.2193, -51.5859],
              [-59.219, -51.5809],
              [-59.2141, -51.5783]
            ]
          ],
          [
            [
              [-60.8235, -51.5724],
              [-60.8311, -51.5774],
              [-60.837, -51.5694],
              [-60.8334, -51.5663],
              [-60.8208, -51.5646],
              [-60.8235, -51.5724]
            ]
          ],
          [
            [
              [-60.4797, -51.5654],
              [-60.4824, -51.5659],
              [-60.4959, -51.5544],
              [-60.4922, -51.5497],
              [-60.4823, -51.5454],
              [-60.4712, -51.55],
              [-60.4788, -51.5594],
              [-60.4797, -51.5654]
            ]
          ],
          [
            [
              [-59.7005, -51.3302],
              [-59.6934, -51.3342],
              [-59.7004, -51.3366],
              [-59.7012, -51.341],
              [-59.6858, -51.3477],
              [-59.6807, -51.3475],
              [-59.6787, -51.3353],
              [-59.6709, -51.3335],
              [-59.6584, -51.3387],
              [-59.6497, -51.3362],
              [-59.6338, -51.3387],
              [-59.6314, -51.3455],
              [-59.6375, -51.3487],
              [-59.637, -51.3533],
              [-59.6531, -51.3557],
              [-59.6739, -51.3532],
              [-59.6919, -51.3499],
              [-59.7048, -51.345],
              [-59.7099, -51.3339],
              [-59.7085, -51.3286],
              [-59.7005, -51.3302]
            ]
          ],
          [
            [
              [-60.8656, -51.5572],
              [-60.8534, -51.5581],
              [-60.8435, -51.5675],
              [-60.859, -51.5686],
              [-60.8706, -51.565],
              [-60.8751, -51.5617],
              [-60.8736, -51.5558],
              [-60.8656, -51.5572]
            ]
          ],
          [
            [
              [-59.819, -51.3645],
              [-59.8337, -51.3765],
              [-59.8514, -51.373],
              [-59.8594, -51.3749],
              [-59.8651, -51.3728],
              [-59.8626, -51.3649],
              [-59.858, -51.3625],
              [-59.8499, -51.3656],
              [-59.8377, -51.3622],
              [-59.819, -51.3645]
            ]
          ],
          [
            [
              [-60.3703, -51.1491],
              [-60.3779, -51.1539],
              [-60.3926, -51.1562],
              [-60.3982, -51.1554],
              [-60.423, -51.1561],
              [-60.4404, -51.1544],
              [-60.4336, -51.1485],
              [-60.4069, -51.1471],
              [-60.3939, -51.1432],
              [-60.3845, -51.1461],
              [-60.3728, -51.1469],
              [-60.3703, -51.1491]
            ]
          ],
          [
            [
              [-59.6381, -51.3835],
              [-59.6618, -51.3878],
              [-59.6624, -51.3966],
              [-59.6722, -51.3974],
              [-59.6675, -51.3867],
              [-59.652, -51.3824],
              [-59.6381, -51.3835]
            ]
          ],
          [
            [
              [-60.8354, -51.2664],
              [-60.8391, -51.2665],
              [-60.8484, -51.2596],
              [-60.8418, -51.2578],
              [-60.8354, -51.2664]
            ]
          ],
          [
            [
              [-60.1406, -51.4117],
              [-60.1238, -51.4107],
              [-60.1201, -51.4158],
              [-60.1087, -51.4181],
              [-60.1076, -51.4287],
              [-60.1186, -51.4192],
              [-60.1406, -51.4117]
            ]
          ],
          [
            [
              [-60.7094, -51.3339],
              [-60.7032, -51.3325],
              [-60.6982, -51.3357],
              [-60.6889, -51.3354],
              [-60.6815, -51.3422],
              [-60.6715, -51.3453],
              [-60.674, -51.3544],
              [-60.6654, -51.3512],
              [-60.6572, -51.3512],
              [-60.6557, -51.3547],
              [-60.6717, -51.3646],
              [-60.6761, -51.374],
              [-60.6917, -51.3815],
              [-60.7009, -51.3803],
              [-60.7088, -51.3702],
              [-60.7062, -51.366],
              [-60.7071, -51.3536],
              [-60.7135, -51.3507],
              [-60.7269, -51.341],
              [-60.7281, -51.3358],
              [-60.7249, -51.3289],
              [-60.7123, -51.3296],
              [-60.7094, -51.3339]
            ]
          ],
          [
            [
              [-59.668, -51.4331],
              [-59.6686, -51.4282],
              [-59.6602, -51.4263],
              [-59.6616, -51.4327],
              [-59.668, -51.4331]
            ]
          ],
          [
            [
              [-59.6417, -51.4131],
              [-59.6414, -51.4158],
              [-59.6515, -51.4183],
              [-59.6572, -51.4141],
              [-59.6574, -51.4092],
              [-59.6461, -51.4092],
              [-59.6417, -51.4131]
            ]
          ],
          [
            [
              [-59.7466, -51.2487],
              [-59.7421, -51.2618],
              [-59.7355, -51.2635],
              [-59.7277, -51.2556],
              [-59.7221, -51.2569],
              [-59.7235, -51.2658],
              [-59.7176, -51.2647],
              [-59.6977, -51.269],
              [-59.6819, -51.2685],
              [-59.672, -51.27],
              [-59.6662, -51.2671],
              [-59.6511, -51.2632],
              [-59.6436, -51.2632],
              [-59.6362, -51.2663],
              [-59.6308, -51.2639],
              [-59.6132, -51.2653],
              [-59.606, -51.2608],
              [-59.5954, -51.2665],
              [-59.5852, -51.2683],
              [-59.588, -51.2738],
              [-59.5982, -51.2815],
              [-59.5964, -51.287],
              [-59.5892, -51.2857],
              [-59.5937, -51.2985],
              [-59.5948, -51.3064],
              [-59.586, -51.3102],
              [-59.5776, -51.3104],
              [-59.5581, -51.3082],
              [-59.5466, -51.3047],
              [-59.539, -51.2976],
              [-59.539, -51.2884],
              [-59.5361, -51.2826],
              [-59.5431, -51.2779],
              [-59.5404, -51.2744],
              [-59.5199, -51.2812],
              [-59.5098, -51.2826],
              [-59.5099, -51.2781],
              [-59.4958, -51.2719],
              [-59.4724, -51.2793],
              [-59.4707, -51.2842],
              [-59.4744, -51.2873],
              [-59.468, -51.2992],
              [-59.4593, -51.3061],
              [-59.4452, -51.3094],
              [-59.4463, -51.3171],
              [-59.4389, -51.3172],
              [-59.4262, -51.3286],
              [-59.4218, -51.3349],
              [-59.4272, -51.3371],
              [-59.4342, -51.3326],
              [-59.4452, -51.3314],
              [-59.4489, -51.3266],
              [-59.4572, -51.3248],
              [-59.4609, -51.3169],
              [-59.4732, -51.3028],
              [-59.476, -51.3035],
              [-59.4693, -51.3168],
              [-59.479, -51.3271],
              [-59.4786, -51.3312],
              [-59.4635, -51.3369],
              [-59.4556, -51.3428],
              [-59.4575, -51.3483],
              [-59.4647, -51.3472],
              [-59.4782, -51.3412],
              [-59.4856, -51.3423],
              [-59.5049, -51.3413],
              [-59.5084, -51.3449],
              [-59.5169, -51.3459],
              [-59.5227, -51.3428],
              [-59.5336, -51.3425],
              [-59.5434, -51.3447],
              [-59.5779, -51.3416],
              [-59.5936, -51.3343],
              [-59.6005, -51.3228],
              [-59.6019, -51.3146],
              [-59.6158, -51.303],
              [-59.6276, -51.3054],
              [-59.6444, -51.3002],
              [-59.6596, -51.2986],
              [-59.6709, -51.2926],
              [-59.6901, -51.2943],
              [-59.7077, -51.2994],
              [-59.7142, -51.2986],
              [-59.7214, -51.2944],
              [-59.7201, -51.3069],
              [-59.7282, -51.3084],
              [-59.7245, -51.2989],
              [-59.7263, -51.293],
              [-59.7398, -51.2935],
              [-59.7521, -51.2912],
              [-59.7593, -51.2849],
              [-59.7797, -51.2794],
              [-59.7953, -51.2793],
              [-59.8145, -51.2755],
              [-59.8282, -51.2652],
              [-59.8192, -51.2586],
              [-59.8177, -51.2525],
              [-59.7968, -51.2528],
              [-59.7835, -51.2483],
              [-59.7674, -51.2486],
              [-59.7577, -51.2455],
              [-59.7466, -51.2487]
            ]
          ],
          [
            [
              [-58.0495, -51.5696],
              [-58.0576, -51.5684],
              [-58.0938, -51.5658],
              [-58.1028, -51.5577],
              [-58.0871, -51.5541],
              [-58.0793, -51.557],
              [-58.0702, -51.5575],
              [-58.0479, -51.5658],
              [-58.0495, -51.5696]
            ]
          ],
          [
            [
              [-59.7375, -51.3279],
              [-59.7402, -51.3335],
              [-59.7176, -51.3432],
              [-59.6954, -51.3576],
              [-59.6817, -51.3622],
              [-59.6668, -51.3616],
              [-59.6612, -51.3669],
              [-59.6802, -51.3672],
              [-59.6991, -51.3594],
              [-59.7058, -51.3589],
              [-59.7163, -51.3546],
              [-59.7191, -51.3587],
              [-59.7309, -51.3579],
              [-59.7338, -51.3602],
              [-59.7372, -51.3707],
              [-59.7458, -51.368],
              [-59.7554, -51.3677],
              [-59.7558, -51.3596],
              [-59.7664, -51.3573],
              [-59.7733, -51.3509],
              [-59.7788, -51.3547],
              [-59.7928, -51.3533],
              [-59.7998, -51.3569],
              [-59.7895, -51.3637],
              [-59.7902, -51.3677],
              [-59.7978, -51.371],
              [-59.7998, -51.3632],
              [-59.8082, -51.3599],
              [-59.8235, -51.3574],
              [-59.8222, -51.3534],
              [-59.8089, -51.3539],
              [-59.8077, -51.348],
              [-59.7905, -51.3477],
              [-59.777, -51.3412],
              [-59.7703, -51.3407],
              [-59.7636, -51.3456],
              [-59.7465, -51.3457],
              [-59.7489, -51.3405],
              [-59.7636, -51.3402],
              [-59.7632, -51.3359],
              [-59.7691, -51.332],
              [-59.7625, -51.323],
              [-59.7575, -51.328],
              [-59.7466, -51.3295],
              [-59.7486, -51.3179],
              [-59.7411, -51.3172],
              [-59.7375, -51.3279]
            ]
          ],
          [
            [
              [-60.844, -51.2056],
              [-60.8796, -51.2158],
              [-60.8953, -51.2165],
              [-60.9081, -51.2192],
              [-60.9177, -51.2159],
              [-60.8727, -51.2031],
              [-60.8373, -51.1976],
              [-60.8346, -51.1992],
              [-60.844, -51.2056]
            ]
          ],
          [
            [
              [-59.7047, -51.433],
              [-59.7109, -51.434],
              [-59.7123, -51.4246],
              [-59.7043, -51.4243],
              [-59.7047, -51.433]
            ]
          ],
          [
            [
              [-59.8321, -51.3452],
              [-59.8276, -51.3512],
              [-59.8388, -51.3525],
              [-59.8442, -51.3486],
              [-59.8456, -51.3435],
              [-59.8342, -51.3406],
              [-59.8321, -51.3452]
            ]
          ],
          [
            [
              [-61.3001, -51.0204],
              [-61.3128, -51.0167],
              [-61.309, -51.0137],
              [-61.3001, -51.0204]
            ]
          ],
          [
            [
              [-60.7329, -51.1329],
              [-60.7444, -51.1385],
              [-60.7568, -51.1372],
              [-60.7555, -51.1335],
              [-60.7454, -51.1318],
              [-60.7365, -51.128],
              [-60.7329, -51.1329]
            ]
          ],
          [
            [
              [-59.6695, -51.3733],
              [-59.6745, -51.3828],
              [-59.6852, -51.3815],
              [-59.7023, -51.3815],
              [-59.723, -51.3828],
              [-59.7165, -51.3776],
              [-59.6965, -51.376],
              [-59.6796, -51.3766],
              [-59.6695, -51.3733]
            ]
          ],
          [
            [
              [-58.9138, -51.2654],
              [-58.9054, -51.2674],
              [-58.9021, -51.2754],
              [-58.8833, -51.2814],
              [-58.8774, -51.2818],
              [-58.8701, -51.2864],
              [-58.8479, -51.2861],
              [-58.831, -51.2916],
              [-58.8231, -51.3001],
              [-58.8148, -51.299],
              [-58.8136, -51.3057],
              [-58.7965, -51.3055],
              [-58.7879, -51.3072],
              [-58.776, -51.3138],
              [-58.7636, -51.3142],
              [-58.7516, -51.3195],
              [-58.7368, -51.3162],
              [-58.7252, -51.3187],
              [-58.7188, -51.3263],
              [-58.7097, -51.327],
              [-58.7049, -51.3301],
              [-58.6908, -51.3278],
              [-58.6823, -51.3332],
              [-58.6713, -51.336],
              [-58.6623, -51.3349],
              [-58.6589, -51.3308],
              [-58.6467, -51.3301],
              [-58.6393, -51.3256],
              [-58.6298, -51.3351],
              [-58.6207, -51.3356],
              [-58.6262, -51.3286],
              [-58.6272, -51.3199],
              [-58.6341, -51.3199],
              [-58.6351, -51.315],
              [-58.6281, -51.3141],
              [-58.622, -51.3171],
              [-58.6216, -51.3226],
              [-58.6131, -51.3241],
              [-58.5975, -51.3207],
              [-58.5866, -51.3153],
              [-58.5808, -51.3092],
              [-58.5823, -51.3025],
              [-58.565, -51.3011],
              [-58.5554, -51.307],
              [-58.5433, -51.3051],
              [-58.5311, -51.2976],
              [-58.5237, -51.3049],
              [-58.5166, -51.3063],
              [-58.5133, -51.3118],
              [-58.5045, -51.3125],
              [-58.4889, -51.308],
              [-58.4813, -51.31],
              [-58.4712, -51.3066],
              [-58.4654, -51.3016],
              [-58.4603, -51.3055],
              [-58.4635, -51.3127],
              [-58.4566, -51.3169],
              [-58.445, -51.3176],
              [-58.4378, -51.3221],
              [-58.4205, -51.3191],
              [-58.4138, -51.3191],
              [-58.4043, -51.3278],
              [-58.396, -51.3312],
              [-58.3927, -51.337],
              [-58.3818, -51.3349],
              [-58.3779, -51.3379],
              [-58.3849, -51.3493],
              [-58.3588, -51.3625],
              [-58.3454, -51.3654],
              [-58.3494, -51.3723],
              [-58.3345, -51.3805],
              [-58.3335, -51.3889],
              [-58.3405, -51.3939],
              [-58.3335, -51.3994],
              [-58.3184, -51.4236],
              [-58.3282, -51.429],
              [-58.3241, -51.437],
              [-58.3328, -51.44],
              [-58.344, -51.4492],
              [-58.3444, -51.4573],
              [-58.3515, -51.4606],
              [-58.3548, -51.4657],
              [-58.3656, -51.4664],
              [-58.3691, -51.4637],
              [-58.3631, -51.4572],
              [-58.3738, -51.4545],
              [-58.3757, -51.4496],
              [-58.3698, -51.4462],
              [-58.3748, -51.4393],
              [-58.3816, -51.4488],
              [-58.3892, -51.4492],
              [-58.3925, -51.4451],
              [-58.4319, -51.4499],
              [-58.4477, -51.453],
              [-58.4565, -51.4572],
              [-58.4644, -51.452],
              [-58.4673, -51.4435],
              [-58.4759, -51.4366],
              [-58.4775, -51.4306],
              [-58.4743, -51.4239],
              [-58.4791, -51.4158],
              [-58.4799, -51.4059],
              [-58.4826, -51.402],
              [-58.494, -51.3999],
              [-58.5026, -51.414],
              [-58.5061, -51.4235],
              [-58.5115, -51.426],
              [-58.5208, -51.4222],
              [-58.5177, -51.4168],
              [-58.5226, -51.4127],
              [-58.5054, -51.4013],
              [-58.5004, -51.3943],
              [-58.4941, -51.3927],
              [-58.4911, -51.3881],
              [-58.4997, -51.3836],
              [-58.5082, -51.3865],
              [-58.5196, -51.3984],
              [-58.5315, -51.4088],
              [-58.5325, -51.4154],
              [-58.5421, -51.4175],
              [-58.5424, -51.4244],
              [-58.5561, -51.4295],
              [-58.5618, -51.434],
              [-58.5258, -51.4266],
              [-58.5422, -51.4369],
              [-58.5317, -51.4378],
              [-58.5221, -51.4314],
              [-58.5187, -51.4381],
              [-58.5417, -51.4443],
              [-58.5311, -51.4469],
              [-58.5212, -51.4429],
              [-58.5099, -51.4503],
              [-58.5061, -51.4558],
              [-58.521, -51.4612],
              [-58.5275, -51.4584],
              [-58.5263, -51.4717],
              [-58.5338, -51.4738],
              [-58.5494, -51.472],
              [-58.5466, -51.4772],
              [-58.5363, -51.4782],
              [-58.5277, -51.4824],
              [-58.525, -51.4757],
              [-58.5158, -51.4814],
              [-58.5268, -51.4857],
              [-58.5386, -51.4922],
              [-58.5304, -51.4997],
              [-58.5299, -51.509],
              [-58.5168, -51.5026],
              [-58.5186, -51.497],
              [-58.5285, -51.4945],
              [-58.525, -51.4881],
              [-58.5192, -51.4884],
              [-58.5138, -51.493],
              [-58.5055, -51.4829],
              [-58.4971, -51.4806],
              [-58.4943, -51.4709],
              [-58.4901, -51.4694],
              [-58.478, -51.4705],
              [-58.4784, -51.4782],
              [-58.4695, -51.4784],
              [-58.4711, -51.4881],
              [-58.4456, -51.4702],
              [-58.4376, -51.4744],
              [-58.437, -51.4811],
              [-58.4288, -51.4821],
              [-58.4264, -51.4928],
              [-58.415, -51.4914],
              [-58.4156, -51.5041],
              [-58.4211, -51.509],
              [-58.4209, -51.5131],
              [-58.4374, -51.5263],
              [-58.4365, -51.5298],
              [-58.4279, -51.5344],
              [-58.4325, -51.5398],
              [-58.4309, -51.544],
              [-58.4356, -51.5468],
              [-58.4486, -51.5463],
              [-58.4474, -51.5497],
              [-58.4307, -51.5639],
              [-58.4221, -51.5623],
              [-58.4248, -51.5532],
              [-58.4192, -51.5512],
              [-58.4144, -51.5438],
              [-58.4168, -51.5391],
              [-58.4091, -51.53],
              [-58.4013, -51.5312],
              [-58.3994, -51.5373],
              [-58.3911, -51.5384],
              [-58.3965, -51.5481],
              [-58.3911, -51.5537],
              [-58.3797, -51.5469],
              [-58.3741, -51.5534],
              [-58.3808, -51.5576],
              [-58.3745, -51.5636],
              [-58.3634, -51.5598],
              [-58.3555, -51.564],
              [-58.3582, -51.5712],
              [-58.336, -51.569],
              [-58.3506, -51.5566],
              [-58.3459, -51.5523],
              [-58.3335, -51.5522],
              [-58.3357, -51.5445],
              [-58.3324, -51.5399],
              [-58.3178, -51.5483],
              [-58.317, -51.5521],
              [-58.3077, -51.555],
              [-58.2991, -51.5515],
              [-58.2944, -51.5565],
              [-58.2842, -51.5577],
              [-58.2843, -51.5672],
              [-58.2777, -51.5646],
              [-58.2706, -51.5678],
              [-58.2706, -51.5744],
              [-58.2859, -51.582],
              [-58.274, -51.5839],
              [-58.2742, -51.5972],
              [-58.2836, -51.5947],
              [-58.2928, -51.5954],
              [-58.2949, -51.6001],
              [-58.2823, -51.6006],
              [-58.2722, -51.6096],
              [-58.2735, -51.6131],
              [-58.2605, -51.6191],
              [-58.2695, -51.6232],
              [-58.2627, -51.6258],
              [-58.2567, -51.6231],
              [-58.2494, -51.6254],
              [-58.244, -51.6354],
              [-58.2475, -51.6399],
              [-58.2423, -51.6458],
              [-58.2339, -51.6494],
              [-58.2331, -51.6567],
              [-58.2296, -51.6591],
              [-58.2201, -51.6512],
              [-58.2292, -51.6446],
              [-58.2287, -51.6343],
              [-58.2366, -51.6309],
              [-58.2515, -51.618],
              [-58.2503, -51.611],
              [-58.253, -51.6077],
              [-58.2656, -51.6055],
              [-58.2688, -51.6],
              [-58.2592, -51.5949],
              [-58.262, -51.5876],
              [-58.2487, -51.5904],
              [-58.2419, -51.5899],
              [-58.2343, -51.5939],
              [-58.2175, -51.6001],
              [-58.2036, -51.6073],
              [-58.1831, -51.6093],
              [-58.1863, -51.6045],
              [-58.2185, -51.5893],
              [-58.2223, -51.5846],
              [-58.2444, -51.5799],
              [-58.25, -51.5752],
              [-58.2418, -51.5737],
              [-58.2334, -51.569],
              [-58.247, -51.5683],
              [-58.2628, -51.5569],
              [-58.2633, -51.5523],
              [-58.2566, -51.5489],
              [-58.2427, -51.545],
              [-58.2348, -51.5482],
              [-58.225, -51.5448],
              [-58.2278, -51.5412],
              [-58.2155, -51.537],
              [-58.2083, -51.5303],
              [-58.2153, -51.5294],
              [-58.2213, -51.533],
              [-58.2362, -51.5341],
              [-58.2591, -51.5388],
              [-58.2671, -51.5363],
              [-58.2748, -51.5377],
              [-58.283, -51.5357],
              [-58.2848, -51.5314],
              [-58.3055, -51.5326],
              [-58.3078, -51.5242],
              [-58.2976, -51.5118],
              [-58.2898, -51.5064],
              [-58.2942, -51.493],
              [-58.3051, -51.4932],
              [-58.305, -51.5008],
              [-58.3207, -51.5055],
              [-58.3191, -51.5114],
              [-58.3241, -51.5126],
              [-58.3251, -51.5193],
              [-58.3391, -51.5234],
              [-58.3431, -51.5175],
              [-58.3595, -51.5212],
              [-58.3678, -51.5174],
              [-58.3774, -51.5194],
              [-58.3828, -51.5154],
              [-58.3779, -51.5118],
              [-58.376, -51.5048],
              [-58.3796, -51.5006],
              [-58.3661, -51.4983],
              [-58.3691, -51.4917],
              [-58.3802, -51.4945],
              [-58.3897, -51.4869],
              [-58.3758, -51.4822],
              [-58.3624, -51.4807],
              [-58.3465, -51.4731],
              [-58.3375, -51.4781],
              [-58.3337, -51.4764],
              [-58.3434, -51.4695],
              [-58.3367, -51.462],
              [-58.3243, -51.4553],
              [-58.3234, -51.4481],
              [-58.2944, -51.4337],
              [-58.2941, -51.4295],
              [-58.2988, -51.4243],
              [-58.2881, -51.4148],
              [-58.2913, -51.409],
              [-58.2835, -51.407],
              [-58.2788, -51.4025],
              [-58.281, -51.3935],
              [-58.2742, -51.3945],
              [-58.2556, -51.3929],
              [-58.2503, -51.3901],
              [-58.239, -51.3912],
              [-58.2324, -51.3884],
              [-58.2164, -51.3933],
              [-58.2085, -51.3929],
              [-58.2008, -51.3897],
              [-58.1799, -51.3931],
              [-58.1635, -51.3883],
              [-58.1606, -51.3836],
              [-58.151, -51.3856],
              [-58.1387, -51.3836],
              [-58.1117, -51.3846],
              [-58.1005, -51.3888],
              [-58.0838, -51.3809],
              [-58.0617, -51.3758],
              [-58.0587, -51.3822],
              [-58.0454, -51.3862],
              [-58.0432, -51.3808],
              [-58.035, -51.3802],
              [-58.0233, -51.3838],
              [-58.0201, -51.3891],
              [-58.0093, -51.3872],
              [-58.0001, -51.383],
              [-57.986, -51.3847],
              [-57.9848, -51.3784],
              [-57.9714, -51.3762],
              [-57.9647, -51.379],
              [-57.947, -51.3707],
              [-57.9389, -51.3774],
              [-57.9223, -51.3811],
              [-57.9064, -51.3878],
              [-57.9055, -51.3939],
              [-57.898, -51.3923],
              [-57.8915, -51.3955],
              [-57.9028, -51.3997],
              [-57.8974, -51.4065],
              [-57.8897, -51.4114],
              [-57.871, -51.4189],
              [-57.8604, -51.4166],
              [-57.8553, -51.4241],
              [-57.8634, -51.4295],
              [-57.859, -51.4345],
              [-57.8693, -51.4352],
              [-57.878, -51.4424],
              [-57.8746, -51.4506],
              [-57.8643, -51.4546],
              [-57.8525, -51.4539],
              [-57.8459, -51.46],
              [-57.8497, -51.4661],
              [-57.843, -51.4695],
              [-57.8411, -51.4744],
              [-57.8468, -51.4798],
              [-57.8379, -51.4872],
              [-57.8308, -51.4883],
              [-57.8253, -51.4937],
              [-57.8002, -51.4967],
              [-57.7929, -51.5017],
              [-57.7788, -51.506],
              [-57.7718, -51.5125],
              [-57.7622, -51.5178],
              [-57.7508, -51.5218],
              [-57.761, -51.5247],
              [-57.7806, -51.5203],
              [-57.7912, -51.5243],
              [-57.7972, -51.524],
              [-57.807, -51.5193],
              [-57.819, -51.5169],
              [-57.8496, -51.5125],
              [-57.8673, -51.506],
              [-57.8635, -51.4994],
              [-57.8544, -51.498],
              [-57.8463, -51.4937],
              [-57.8505, -51.4857],
              [-57.8569, -51.4863],
              [-57.859, -51.4917],
              [-57.8662, -51.4961],
              [-57.8724, -51.5049],
              [-57.8903, -51.5089],
              [-57.8847, -51.5174],
              [-57.8466, -51.5205],
              [-57.8303, -51.5228],
              [-57.8234, -51.5195],
              [-57.819, -51.5251],
              [-57.8089, -51.5278],
              [-57.7847, -51.5314],
              [-57.7733, -51.5379],
              [-57.7761, -51.5487],
              [-57.7806, -51.5524],
              [-57.7979, -51.5552],
              [-57.8192, -51.5551],
              [-57.8228, -51.5566],
              [-57.8412, -51.5561],
              [-57.857, -51.5537],
              [-57.8785, -51.5534],
              [-57.8861, -51.5515],
              [-57.921, -51.5504],
              [-57.9353, -51.546],
              [-57.9528, -51.5459],
              [-57.9629, -51.5483],
              [-57.9724, -51.5429],
              [-57.9804, -51.5421],
              [-57.9963, -51.5341],
              [-58.0003, -51.5175],
              [-58.0055, -51.5124],
              [-58.0112, -51.5118],
              [-58.0191, -51.5159],
              [-58.0337, -51.5157],
              [-58.0426, -51.5126],
              [-58.0504, -51.5049],
              [-58.0702, -51.5009],
              [-58.0699, -51.504],
              [-58.0581, -51.5104],
              [-58.0494, -51.5184],
              [-58.056, -51.5218],
              [-58.0513, -51.5264],
              [-58.0517, -51.5309],
              [-58.0437, -51.5359],
              [-58.0405, -51.5418],
              [-58.071, -51.5328],
              [-58.0936, -51.5317],
              [-58.0975, -51.5257],
              [-58.1058, -51.5227],
              [-58.1123, -51.5255],
              [-58.1007, -51.5317],
              [-58.1102, -51.5353],
              [-58.1129, -51.5397],
              [-58.1221, -51.5424],
              [-58.1411, -51.5431],
              [-58.1446, -51.5459],
              [-58.1651, -51.5453],
              [-58.1718, -51.5511],
              [-58.1619, -51.5577],
              [-58.1397, -51.561],
              [-58.1122, -51.5696],
              [-58.099, -51.5714],
              [-58.0919, -51.5746],
              [-58.0793, -51.575],
              [-58.0782, -51.5871],
              [-58.0695, -51.5969],
              [-58.0651, -51.5984],
              [-58.0461, -51.597],
              [-58.0256, -51.6001],
              [-57.9981, -51.5989],
              [-57.9849, -51.5925],
              [-57.9734, -51.5986],
              [-57.9669, -51.6001],
              [-57.9571, -51.597],
              [-57.9532, -51.6007],
              [-57.9377, -51.6052],
              [-57.9245, -51.6049],
              [-57.9175, -51.6077],
              [-57.9025, -51.6065],
              [-57.8905, -51.6079],
              [-57.8806, -51.6109],
              [-57.8684, -51.6125],
              [-57.8655, -51.6148],
              [-57.8521, -51.6151],
              [-57.8364, -51.6193],
              [-57.7993, -51.6244],
              [-57.7834, -51.6293],
              [-57.7788, -51.6386],
              [-57.7745, -51.6425],
              [-57.7663, -51.6442],
              [-57.7597, -51.641],
              [-57.7532, -51.6434],
              [-57.7547, -51.6483],
              [-57.7404, -51.6513],
              [-57.7407, -51.6558],
              [-57.7535, -51.6541],
              [-57.7591, -51.6558],
              [-57.7689, -51.6519],
              [-57.7829, -51.6582],
              [-57.7865, -51.6671],
              [-57.7924, -51.665],
              [-57.7993, -51.6691],
              [-57.8068, -51.6685],
              [-57.8177, -51.6609],
              [-57.8186, -51.6555],
              [-57.8261, -51.6561],
              [-57.8314, -51.6605],
              [-57.8219, -51.6642],
              [-57.832, -51.6706],
              [-57.8433, -51.6705],
              [-57.859, -51.6741],
              [-57.865, -51.6797],
              [-57.878, -51.6818],
              [-57.8855, -51.6776],
              [-57.9055, -51.6767],
              [-57.9104, -51.675],
              [-57.9196, -51.6794],
              [-57.9019, -51.6809],
              [-57.8893, -51.6788],
              [-57.8878, -51.6829],
              [-57.8752, -51.6863],
              [-57.8629, -51.6854],
              [-57.8532, -51.6866],
              [-57.8492, -51.6907],
              [-57.8612, -51.6916],
              [-57.9024, -51.6899],
              [-57.928, -51.6907],
              [-57.9262, -51.6936],
              [-57.8988, -51.6951],
              [-57.8897, -51.6976],
              [-57.8739, -51.6975],
              [-57.8451, -51.7016],
              [-57.8386, -51.6975],
              [-57.8341, -51.7014],
              [-57.8235, -51.6996],
              [-57.8185, -51.693],
              [-57.8399, -51.6924],
              [-57.8357, -51.6817],
              [-57.8252, -51.6809],
              [-57.8237, -51.6872],
              [-57.8136, -51.6878],
              [-57.8041, -51.6838],
              [-57.7968, -51.6838],
              [-57.7876, -51.6886],
              [-57.7355, -51.6892],
              [-57.7377, -51.6945],
              [-57.7519, -51.6957],
              [-57.7525, -51.7008],
              [-57.7798, -51.702],
              [-57.7881, -51.7002],
              [-57.7956, -51.7037],
              [-57.7946, -51.7085],
              [-57.8178, -51.7101],
              [-57.8235, -51.7132],
              [-57.8216, -51.7177],
              [-57.8324, -51.7183],
              [-57.8327, -51.7245],
              [-57.8469, -51.7284],
              [-57.8601, -51.7275],
              [-57.867, -51.7245],
              [-57.8695, -51.7301],
              [-57.882, -51.732],
              [-57.8925, -51.7354],
              [-57.9106, -51.7341],
              [-57.9132, -51.7308],
              [-57.9225, -51.7339],
              [-57.9427, -51.7311],
              [-57.9685, -51.7307],
              [-57.972, -51.7372],
              [-57.9964, -51.7376],
              [-58.0173, -51.737],
              [-58.0198, -51.7421],
              [-58.0036, -51.743],
              [-57.9889, -51.7399],
              [-57.9626, -51.7392],
              [-57.9482, -51.7427],
              [-57.9078, -51.7427],
              [-57.8909, -51.7441],
              [-57.8758, -51.7467],
              [-57.8956, -51.7493],
              [-57.8995, -51.752],
              [-57.9328, -51.7509],
              [-57.9361, -51.7485],
              [-57.9462, -51.751],
              [-57.9881, -51.7539],
              [-57.9841, -51.7595],
              [-58.0602, -51.7615],
              [-58.0667, -51.7593],
              [-58.0792, -51.7629],
              [-58.0855, -51.7697],
              [-58.083, -51.775],
              [-58.1234, -51.7714],
              [-58.1411, -51.7705],
              [-58.1624, -51.7675],
              [-58.1772, -51.7617],
              [-58.1781, -51.7535],
              [-58.1713, -51.748],
              [-58.1725, -51.7416],
              [-58.1825, -51.7435],
              [-58.1838, -51.7484],
              [-58.1785, -51.7583],
              [-58.1812, -51.7621],
              [-58.1891, -51.7625],
              [-58.1974, -51.7556],
              [-58.2035, -51.7566],
              [-58.2104, -51.7683],
              [-58.2252, -51.7668],
              [-58.2271, -51.7602],
              [-58.2404, -51.7622],
              [-58.2495, -51.7548],
              [-58.2598, -51.7543],
              [-58.2786, -51.7493],
              [-58.2995, -51.7487],
              [-58.2947, -51.7526],
              [-58.2812, -51.7529],
              [-58.2739, -51.7578],
              [-58.2564, -51.7628],
              [-58.2531, -51.7665],
              [-58.2429, -51.7656],
              [-58.2371, -51.7721],
              [-58.2176, -51.7755],
              [-58.2239, -51.7796],
              [-58.2371, -51.779],
              [-58.2443, -51.7828],
              [-58.2231, -51.786],
              [-58.1989, -51.7808],
              [-58.1812, -51.7809],
              [-58.1535, -51.7874],
              [-58.1403, -51.7866],
              [-58.1273, -51.7885],
              [-58.134, -51.7934],
              [-58.1373, -51.8012],
              [-58.1557, -51.7968],
              [-58.1616, -51.7987],
              [-58.1944, -51.7954],
              [-58.2368, -51.7959],
              [-58.2461, -51.7919],
              [-58.2467, -51.7982],
              [-58.2561, -51.7952],
              [-58.2717, -51.8012],
              [-58.2743, -51.8045],
              [-58.2829, -51.8037],
              [-58.2922, -51.7998],
              [-58.2943, -51.806],
              [-58.2671, -51.8085],
              [-58.2583, -51.8057],
              [-58.2542, -51.8082],
              [-58.2384, -51.8096],
              [-58.2192, -51.8151],
              [-58.1847, -51.8155],
              [-58.2009, -51.8262],
              [-58.2176, -51.8287],
              [-58.2335, -51.8298],
              [-58.2464, -51.8275],
              [-58.2533, -51.8311],
              [-58.2505, -51.8387],
              [-58.2368, -51.8412],
              [-58.232, -51.8439],
              [-58.2316, -51.8624],
              [-58.2489, -51.864],
              [-58.2606, -51.8723],
              [-58.2719, -51.8654],
              [-58.2634, -51.8615],
              [-58.2447, -51.856],
              [-58.2478, -51.8532],
              [-58.2412, -51.8461],
              [-58.2562, -51.8437],
              [-58.2726, -51.8486],
              [-58.291, -51.8499],
              [-58.2863, -51.8558],
              [-58.3003, -51.8561],
              [-58.3008, -51.8596],
              [-58.2917, -51.8619],
              [-58.3095, -51.8663],
              [-58.3311, -51.8666],
              [-58.3261, -51.8707],
              [-58.3314, -51.8778],
              [-58.3458, -51.8785],
              [-58.3551, -51.8809],
              [-58.3645, -51.8804],
              [-58.3897, -51.886],
              [-58.4053, -51.8948],
              [-58.4121, -51.9054],
              [-58.4136, -51.9121],
              [-58.4236, -51.9133],
              [-58.418, -51.9193],
              [-58.451, -51.9196],
              [-58.4607, -51.9224],
              [-58.468, -51.9201],
              [-58.4813, -51.9189],
              [-58.4801, -51.9137],
              [-58.4838, -51.9021],
              [-58.4743, -51.9049],
              [-58.4557, -51.9069],
              [-58.4443, -51.9114],
              [-58.4488, -51.9169],
              [-58.4363, -51.9152],
              [-58.44, -51.9102],
              [-58.4265, -51.9083],
              [-58.4199, -51.9099],
              [-58.415, -51.9054],
              [-58.4318, -51.9036],
              [-58.4261, -51.8995],
              [-58.4136, -51.901],
              [-58.414, -51.8963],
              [-58.4312, -51.897],
              [-58.4451, -51.8961],
              [-58.4508, -51.8985],
              [-58.4675, -51.897],
              [-58.4726, -51.9],
              [-58.4859, -51.8992],
              [-58.4996, -51.8941],
              [-58.4933, -51.8922],
              [-58.491, -51.8875],
              [-58.501, -51.8873],
              [-58.515, -51.8908],
              [-58.5254, -51.8885],
              [-58.5404, -51.8914],
              [-58.5443, -51.8877],
              [-58.5711, -51.8866],
              [-58.5726, -51.8959],
              [-58.5858, -51.8956],
              [-58.5997, -51.8978],
              [-58.6042, -51.8917],
              [-58.6194, -51.8954],
              [-58.629, -51.895],
              [-58.6335, -51.8913],
              [-58.6465, -51.9008],
              [-58.652, -51.8988],
              [-58.6509, -51.8902],
              [-58.6676, -51.8937],
              [-58.6729, -51.898],
              [-58.68, -51.8988],
              [-58.6923, -51.8973],
              [-58.6961, -51.8947],
              [-58.6974, -51.8869],
              [-58.6887, -51.8857],
              [-58.6781, -51.8892],
              [-58.6709, -51.8838],
              [-58.6637, -51.8821],
              [-58.6529, -51.8866],
              [-58.6413, -51.8791],
              [-58.6475, -51.8769],
              [-58.6544, -51.8804],
              [-58.6758, -51.8805],
              [-58.6789, -51.8848],
              [-58.6923, -51.884],
              [-58.697, -51.8817],
              [-58.7039, -51.8883],
              [-58.7175, -51.8883],
              [-58.7414, -51.8856],
              [-58.7402, -51.8803],
              [-58.7497, -51.8766],
              [-58.757, -51.8839],
              [-58.7603, -51.8916],
              [-58.7695, -51.8934],
              [-58.7843, -51.8897],
              [-58.7929, -51.8889],
              [-58.7952, -51.883],
              [-58.8163, -51.8781],
              [-58.8176, -51.8693],
              [-58.8246, -51.8651],
              [-58.8422, -51.8645],
              [-58.8604, -51.8592],
              [-58.8635, -51.8521],
              [-58.8705, -51.8576],
              [-58.8917, -51.8506],
              [-58.9004, -51.8497],
              [-58.9036, -51.8439],
              [-58.9106, -51.8457],
              [-58.9222, -51.838],
              [-58.9372, -51.8343],
              [-58.9371, -51.824],
              [-58.9424, -51.8172],
              [-58.9441, -51.8067],
              [-58.9532, -51.7989],
              [-58.9462, -51.7829],
              [-58.9548, -51.7817],
              [-58.9537, -51.7914],
              [-58.9604, -51.7959],
              [-58.9703, -51.7963],
              [-58.9621, -51.8032],
              [-58.9763, -51.8164],
              [-58.9804, -51.8236],
              [-58.9894, -51.8341],
              [-58.9823, -51.8355],
              [-58.9815, -51.8454],
              [-58.9954, -51.85],
              [-58.9918, -51.8548],
              [-58.9831, -51.8544],
              [-58.9777, -51.8583],
              [-58.9671, -51.8596],
              [-58.9632, -51.865],
              [-58.9523, -51.8702],
              [-58.9411, -51.8697],
              [-58.9193, -51.8737],
              [-58.9141, -51.8775],
              [-58.9133, -51.8844],
              [-58.9018, -51.8899],
              [-58.9008, -51.8935],
              [-58.8914, -51.8965],
              [-58.8808, -51.8947],
              [-58.8778, -51.899],
              [-58.8672, -51.9026],
              [-58.853, -51.9048],
              [-58.8413, -51.9099],
              [-58.8286, -51.9113],
              [-58.8258, -51.9147],
              [-58.8121, -51.9214],
              [-58.8029, -51.924],
              [-58.7894, -51.9249],
              [-58.7819, -51.9274],
              [-58.8016, -51.9348],
              [-58.8033, -51.9397],
              [-58.8124, -51.9359],
              [-58.8204, -51.9299],
              [-58.8297, -51.9273],
              [-58.8383, -51.9149],
              [-58.8458, -51.9154],
              [-58.8533, -51.9114],
              [-58.8573, -51.9151],
              [-58.866, -51.9119],
              [-58.871, -51.9155],
              [-58.8912, -51.9122],
              [-58.8945, -51.9082],
              [-58.9021, -51.907],
              [-58.902, -51.9153],
              [-58.8943, -51.919],
              [-58.8793, -51.9217],
              [-58.8519, -51.9243],
              [-58.8632, -51.9324],
              [-58.8479, -51.9315],
              [-58.8419, -51.9273],
              [-58.8304, -51.9361],
              [-58.8216, -51.9369],
              [-58.8131, -51.9425],
              [-58.8152, -51.9463],
              [-58.8116, -51.9509],
              [-58.804, -51.9455],
              [-58.7847, -51.9477],
              [-58.7873, -51.9559],
              [-58.7777, -51.9599],
              [-58.7736, -51.966],
              [-58.7688, -51.9676],
              [-58.7645, -51.9595],
              [-58.7679, -51.9529],
              [-58.7526, -51.9535],
              [-58.7546, -51.947],
              [-58.7273, -51.944],
              [-58.7117, -51.9462],
              [-58.7058, -51.9511],
              [-58.6918, -51.957],
              [-58.6867, -51.9545],
              [-58.6754, -51.9581],
              [-58.6714, -51.9533],
              [-58.659, -51.9686],
              [-58.6543, -51.9615],
              [-58.6455, -51.9571],
              [-58.638, -51.9599],
              [-58.642, -51.9654],
              [-58.6363, -51.9715],
              [-58.6261, -51.9704],
              [-58.6222, -51.976],
              [-58.635, -51.9827],
              [-58.6272, -51.9861],
              [-58.6266, -51.9895],
              [-58.6425, -51.9917],
              [-58.6247, -52.0001],
              [-58.6172, -52.0054],
              [-58.6056, -52.0012],
              [-58.6005, -52.0076],
              [-58.6021, -52.0109],
              [-58.6151, -52.0133],
              [-58.6211, -52.012],
              [-58.6295, -52.0163],
              [-58.6342, -52.0112],
              [-58.64, -52.0114],
              [-58.647, -52.0174],
              [-58.6582, -52.0205],
              [-58.675, -52.0172],
              [-58.691, -52.0197],
              [-58.6846, -52.0265],
              [-58.6762, -52.0249],
              [-58.6641, -52.0271],
              [-58.6512, -52.0367],
              [-58.6595, -52.0447],
              [-58.6675, -52.0431],
              [-58.6745, -52.0448],
              [-58.6753, -52.0574],
              [-58.6654, -52.0714],
              [-58.6654, -52.0797],
              [-58.6595, -52.0827],
              [-58.6616, -52.09],
              [-58.667, -52.096],
              [-58.6548, -52.1003],
              [-58.6597, -52.1068],
              [-58.6655, -52.1067],
              [-58.6719, -52.113],
              [-58.6685, -52.1187],
              [-58.669, -52.1291],
              [-58.6848, -52.1267],
              [-58.6832, -52.1147],
              [-58.6964, -52.1113],
              [-58.7032, -52.1164],
              [-58.7025, -52.1047],
              [-58.6976, -52.0971],
              [-58.6921, -52.0937],
              [-58.6904, -52.0851],
              [-58.7025, -52.077],
              [-58.715, -52.079],
              [-58.7154, -52.0714],
              [-58.7237, -52.0701],
              [-58.72, -52.0593],
              [-58.7295, -52.0554],
              [-58.7391, -52.0591],
              [-58.7408, -52.0662],
              [-58.7505, -52.0704],
              [-58.7557, -52.0624],
              [-58.7457, -52.0535],
              [-58.7426, -52.0487],
              [-58.748, -52.0425],
              [-58.7574, -52.0373],
              [-58.768, -52.0374],
              [-58.7754, -52.0408],
              [-58.7933, -52.0379],
              [-58.7955, -52.0335],
              [-58.8069, -52.028],
              [-58.8246, -52.0286],
              [-58.8261, -52.0344],
              [-58.8209, -52.0392],
              [-58.8243, -52.0466],
              [-58.8476, -52.0439],
              [-58.856, -52.0453],
              [-58.8617, -52.0425],
              [-58.8686, -52.0444],
              [-58.8743, -52.0347],
              [-58.8798, -52.0337],
              [-58.8838, -52.0404],
              [-58.8776, -52.0482],
              [-58.8769, -52.0577],
              [-58.8826, -52.0653],
              [-58.8711, -52.0707],
              [-58.8734, -52.0762],
              [-58.8819, -52.0764],
              [-58.8835, -52.0708],
              [-58.8967, -52.0664],
              [-58.9005, -52.075],
              [-58.9051, -52.0744],
              [-58.9136, -52.0784],
              [-58.9185, -52.0777],
              [-58.9198, -52.0862],
              [-58.9277, -52.0907],
              [-58.9345, -52.1022],
              [-58.9387, -52.0973],
              [-58.9497, -52.0793],
              [-58.9589, -52.0745],
              [-58.9544, -52.0687],
              [-58.9546, -52.0598],
              [-58.9606, -52.0588],
              [-58.9591, -52.0517],
              [-58.964, -52.051],
              [-58.9711, -52.0576],
              [-58.9704, -52.067],
              [-58.9738, -52.072],
              [-58.9803, -52.0691],
              [-58.9837, -52.076],
              [-58.9966, -52.0767],
              [-58.9968, -52.0706],
              [-59.001, -52.0653],
              [-58.9987, -52.0612],
              [-59.0015, -52.0545],
              [-59.0078, -52.0514],
              [-59.0057, -52.0423],
              [-59.0011, -52.0371],
              [-59.0036, -52.031],
              [-59.0093, -52.0309],
              [-59.0201, -52.0357],
              [-59.021, -52.0283],
              [-59.0263, -52.0172],
              [-59.042, -52.0161],
              [-59.0522, -52.0299],
              [-59.0615, -52.0294],
              [-59.0693, -52.0218],
              [-59.0757, -52.0185],
              [-59.071, -52.0147],
              [-59.0756, -52.0066],
              [-59.0843, -52.0049],
              [-59.1005, -52.0126],
              [-59.097, -52.0165],
              [-59.1051, -52.0195],
              [-59.1207, -52.0069],
              [-59.1213, -52.0035],
              [-59.1149, -51.9953],
              [-59.134, -51.9918],
              [-59.1427, -51.987],
              [-59.1396, -51.9738],
              [-59.1439, -51.9736],
              [-59.1526, -51.9857],
              [-59.1624, -51.9886],
              [-59.1663, -51.9974],
              [-59.1706, -52.0011],
              [-59.1777, -52.0021],
              [-59.193, -52.0009],
              [-59.2157, -52.0019],
              [-59.2349, -51.9978],
              [-59.2433, -51.9979],
              [-59.2469, -51.9881],
              [-59.2722, -51.9874],
              [-59.2725, -51.9935],
              [-59.2786, -51.9991],
              [-59.2889, -52.0009],
              [-59.2864, -52.0075],
              [-59.298, -52.015],
              [-59.2954, -52.0177],
              [-59.2837, -52.01],
              [-59.2759, -52.0084],
              [-59.269, -51.9969],
              [-59.264, -52.0014],
              [-59.2514, -51.9992],
              [-59.25, -52.0045],
              [-59.2436, -52.0074],
              [-59.2359, -52.0061],
              [-59.2258, -52.0098],
              [-59.2121, -52.0111],
              [-59.2016, -52.0106],
              [-59.2029, -52.0167],
              [-59.1906, -52.0182],
              [-59.1944, -52.0256],
              [-59.1941, -52.0339],
              [-59.2075, -52.0408],
              [-59.2018, -52.0424],
              [-59.2002, -52.0513],
              [-59.1939, -52.0547],
              [-59.1833, -52.0517],
              [-59.1677, -52.0432],
              [-59.1609, -52.034],
              [-59.1532, -52.0335],
              [-59.1512, -52.0273],
              [-59.1402, -52.0248],
              [-59.1369, -52.0321],
              [-59.1459, -52.0379],
              [-59.1516, -52.037],
              [-59.152, -52.0488],
              [-59.141, -52.0459],
              [-59.1394, -52.0504],
              [-59.132, -52.0523],
              [-59.1237, -52.0603],
              [-59.1144, -52.0619],
              [-59.1069, -52.0662],
              [-59.131, -52.0736],
              [-59.141, -52.0738],
              [-59.1469, -52.0826],
              [-59.1623, -52.082],
              [-59.1552, -52.0911],
              [-59.1506, -52.0923],
              [-59.14, -52.0829],
              [-59.1328, -52.0897],
              [-59.1202, -52.0905],
              [-59.1222, -52.0963],
              [-59.1161, -52.0982],
              [-59.1058, -52.0889],
              [-59.1005, -52.089],
              [-59.0872, -52.0936],
              [-59.0745, -52.0943],
              [-59.0833, -52.1],
              [-59.079, -52.105],
              [-59.0897, -52.1089],
              [-59.1137, -52.1128],
              [-59.1178, -52.1069],
              [-59.1256, -52.1086],
              [-59.1387, -52.1251],
              [-59.1428, -52.128],
              [-59.1588, -52.1272],
              [-59.1673, -52.1311],
              [-59.1635, -52.1336],
              [-59.1539, -52.1308],
              [-59.1534, -52.1421],
              [-59.1506, -52.1462],
              [-59.1302, -52.1376],
              [-59.1191, -52.1268],
              [-59.1165, -52.1314],
              [-59.1094, -52.1359],
              [-59.1098, -52.1401],
              [-59.1181, -52.148],
              [-59.1092, -52.1482],
              [-59.0979, -52.144],
              [-59.0928, -52.1385],
              [-59.0858, -52.1355],
              [-59.0805, -52.1257],
              [-59.0732, -52.1288],
              [-59.0525, -52.1292],
              [-59.0351, -52.1316],
              [-59.0369, -52.138],
              [-59.0346, -52.1447],
              [-59.05, -52.1442],
              [-59.0535, -52.1496],
              [-59.0483, -52.1539],
              [-59.0765, -52.1548],
              [-59.0816, -52.1617],
              [-59.0676, -52.1681],
              [-59.0809, -52.1728],
              [-59.0931, -52.1689],
              [-59.0974, -52.1752],
              [-59.1053, -52.1778],
              [-59.1097, -52.1861],
              [-59.0959, -52.1931],
              [-59.1008, -52.2007],
              [-59.0935, -52.2006],
              [-59.0897, -52.1947],
              [-59.0809, -52.1878],
              [-59.0682, -52.1872],
              [-59.0579, -52.1902],
              [-59.0464, -52.1864],
              [-59.0406, -52.1936],
              [-59.062, -52.2059],
              [-59.0573, -52.2131],
              [-59.0625, -52.218],
              [-59.0555, -52.2229],
              [-59.0408, -52.2163],
              [-59.0391, -52.2205],
              [-59.0528, -52.2275],
              [-59.057, -52.2312],
              [-59.0367, -52.232],
              [-59.0215, -52.2411],
              [-59.0329, -52.2446],
              [-59.0489, -52.2361],
              [-59.0641, -52.2355],
              [-59.0665, -52.2307],
              [-59.0757, -52.2296],
              [-59.0692, -52.223],
              [-59.0729, -52.2208],
              [-59.0823, -52.2245],
              [-59.0976, -52.2206],
              [-59.1049, -52.2281],
              [-59.1129, -52.2267],
              [-59.1151, -52.22],
              [-59.1211, -52.2168],
              [-59.1331, -52.2145],
              [-59.1355, -52.2092],
              [-59.1544, -52.2122],
              [-59.1639, -52.1971],
              [-59.1707, -52.2001],
              [-59.193, -52.1977],
              [-59.2023, -52.1979],
              [-59.2015, -52.1922],
              [-59.2081, -52.1918],
              [-59.2092, -52.2014],
              [-59.2159, -52.2049],
              [-59.2186, -52.2102],
              [-59.2321, -52.2107],
              [-59.2326, -52.2044],
              [-59.2391, -52.1969],
              [-59.2457, -52.1969],
              [-59.254, -52.1874],
              [-59.2543, -52.1773],
              [-59.2503, -52.173],
              [-59.2545, -52.1676],
              [-59.2519, -52.1614],
              [-59.2574, -52.1587],
              [-59.2711, -52.1608],
              [-59.2689, -52.1529],
              [-59.2781, -52.1529],
              [-59.2828, -52.1578],
              [-59.2883, -52.1578],
              [-59.2916, -52.152],
              [-59.3005, -52.1503],
              [-59.2998, -52.1558],
              [-59.3104, -52.1572],
              [-59.3117, -52.1667],
              [-59.3151, -52.1706],
              [-59.3264, -52.1677],
              [-59.3237, -52.1529],
              [-59.3053, -52.1483],
              [-59.2939, -52.1367],
              [-59.2921, -52.1316],
              [-59.2998, -52.1286],
              [-59.3078, -52.1352],
              [-59.322, -52.1416],
              [-59.3377, -52.141],
              [-59.3498, -52.1478],
              [-59.3552, -52.1405],
              [-59.3587, -52.1286],
              [-59.3544, -52.1233],
              [-59.3672, -52.1175],
              [-59.3743, -52.1221],
              [-59.3703, -52.1309],
              [-59.3754, -52.1318],
              [-59.3833, -52.127],
              [-59.3937, -52.1233],
              [-59.3937, -52.1182],
              [-59.4014, -52.106],
              [-59.4054, -52.1086],
              [-59.4056, -52.1157],
              [-59.4005, -52.1239],
              [-59.4005, -52.1329],
              [-59.3962, -52.1392],
              [-59.3831, -52.1457],
              [-59.4002, -52.1444],
              [-59.4105, -52.1492],
              [-59.4179, -52.1432],
              [-59.4152, -52.1298],
              [-59.4323, -52.126],
              [-59.4349, -52.1194],
              [-59.4415, -52.1224],
              [-59.4398, -52.1307],
              [-59.4345, -52.1362],
              [-59.444, -52.1459],
              [-59.4541, -52.1448],
              [-59.462, -52.141],
              [-59.4672, -52.1486],
              [-59.4633, -52.1502],
              [-59.4518, -52.1481],
              [-59.4402, -52.1542],
              [-59.43, -52.1524],
              [-59.4176, -52.1569],
              [-59.4138, -52.1619],
              [-59.4051, -52.1594],
              [-59.3987, -52.1641],
              [-59.4006, -52.1708],
              [-59.4116, -52.1729],
              [-59.4174, -52.1677],
              [-59.4275, -52.1701],
              [-59.4361, -52.1745],
              [-59.4232, -52.1799],
              [-59.4338, -52.1872],
              [-59.4292, -52.1915],
              [-59.4375, -52.1991],
              [-59.4556, -52.2067],
              [-59.4556, -52.2093],
              [-59.408, -52.1951],
              [-59.4045, -52.1991],
              [-59.4143, -52.2019],
              [-59.4171, -52.207],
              [-59.4299, -52.2174],
              [-59.4296, -52.2245],
              [-59.421, -52.2223],
              [-59.3967, -52.2065],
              [-59.3839, -52.1972],
              [-59.3829, -52.1933],
              [-59.3746, -52.1926],
              [-59.3645, -52.1991],
              [-59.346, -52.1978],
              [-59.3377, -52.1948],
              [-59.3314, -52.2004],
              [-59.3378, -52.2039],
              [-59.3463, -52.2033],
              [-59.3609, -52.2062],
              [-59.3695, -52.2106],
              [-59.3866, -52.2123],
              [-59.3832, -52.2181],
              [-59.3746, -52.219],
              [-59.3668, -52.2244],
              [-59.3542, -52.2262],
              [-59.3441, -52.225],
              [-59.3404, -52.2311],
              [-59.3417, -52.2375],
              [-59.3604, -52.2504],
              [-59.3698, -52.2501],
              [-59.3728, -52.2556],
              [-59.3912, -52.2648],
              [-59.4045, -52.2655],
              [-59.3964, -52.2707],
              [-59.3825, -52.2646],
              [-59.3518, -52.2535],
              [-59.342, -52.2584],
              [-59.3447, -52.2675],
              [-59.3368, -52.2698],
              [-59.3509, -52.2811],
              [-59.3701, -52.2783],
              [-59.3728, -52.2832],
              [-59.3869, -52.2923],
              [-59.3869, -52.3039],
              [-59.3671, -52.3133],
              [-59.3559, -52.3201],
              [-59.3403, -52.3195],
              [-59.3421, -52.3258],
              [-59.3378, -52.3313],
              [-59.3284, -52.3311],
              [-59.3282, -52.3232],
              [-59.3158, -52.3137],
              [-59.3076, -52.3127],
              [-59.301, -52.3194],
              [-59.3086, -52.3229],
              [-59.3155, -52.335],
              [-59.3259, -52.333],
              [-59.3392, -52.3371],
              [-59.3442, -52.3411],
              [-59.3639, -52.3452],
              [-59.3671, -52.3432],
              [-59.3677, -52.335],
              [-59.3828, -52.3348],
              [-59.3829, -52.3313],
              [-59.3722, -52.3262],
              [-59.3746, -52.3197],
              [-59.3976, -52.3217],
              [-59.4079, -52.3168],
              [-59.4189, -52.3179],
              [-59.4222, -52.3125],
              [-59.4078, -52.3091],
              [-59.407, -52.3012],
              [-59.4161, -52.2963],
              [-59.4287, -52.2936],
              [-59.4494, -52.295],
              [-59.4713, -52.2942],
              [-59.479, -52.2984],
              [-59.4951, -52.2974],
              [-59.497, -52.2868],
              [-59.4826, -52.2773],
              [-59.4744, -52.2703],
              [-59.4778, -52.2627],
              [-59.4881, -52.2608],
              [-59.5062, -52.2595],
              [-59.507, -52.255],
              [-59.4932, -52.2517],
              [-59.496, -52.2449],
              [-59.5046, -52.2452],
              [-59.5118, -52.2394],
              [-59.5071, -52.2351],
              [-59.5202, -52.2326],
              [-59.5336, -52.2385],
              [-59.536, -52.2428],
              [-59.5463, -52.2459],
              [-59.5512, -52.245],
              [-59.5593, -52.2387],
              [-59.5758, -52.2458],
              [-59.5796, -52.2428],
              [-59.5775, -52.236],
              [-59.5873, -52.236],
              [-59.5907, -52.2323],
              [-59.5828, -52.2221],
              [-59.573, -52.2216],
              [-59.5705, -52.2157],
              [-59.572, -52.2103],
              [-59.5622, -52.2051],
              [-59.5558, -52.1937],
              [-59.5617, -52.1935],
              [-59.5683, -52.198],
              [-59.5788, -52.1999],
              [-59.5861, -52.1974],
              [-59.5992, -52.2016],
              [-59.6102, -52.1959],
              [-59.6257, -52.1953],
              [-59.6259, -52.1913],
              [-59.6178, -52.1881],
              [-59.6194, -52.1829],
              [-59.6126, -52.1748],
              [-59.6298, -52.1754],
              [-59.6386, -52.1723],
              [-59.6359, -52.1654],
              [-59.6233, -52.1635],
              [-59.6208, -52.1605],
              [-59.6334, -52.1533],
              [-59.6391, -52.1474],
              [-59.6488, -52.1468],
              [-59.6512, -52.155],
              [-59.66, -52.1545],
              [-59.6608, -52.1488],
              [-59.6753, -52.1439],
              [-59.6793, -52.1396],
              [-59.6737, -52.1348],
              [-59.6859, -52.1296],
              [-59.6884, -52.1261],
              [-59.6988, -52.1275],
              [-59.7017, -52.1224],
              [-59.7211, -52.1185],
              [-59.7255, -52.1148],
              [-59.714, -52.108],
              [-59.7044, -52.1045],
              [-59.7064, -52.0986],
              [-59.7024, -52.0933],
              [-59.7094, -52.0911],
              [-59.7165, -52.0933],
              [-59.7219, -52.0912],
              [-59.7236, -52.0849],
              [-59.7146, -52.086],
              [-59.6971, -52.0825],
              [-59.6778, -52.096],
              [-59.6696, -52.0929],
              [-59.6631, -52.0878],
              [-59.6596, -52.097],
              [-59.6729, -52.1106],
              [-59.6611, -52.1123],
              [-59.6509, -52.1036],
              [-59.6322, -52.1008],
              [-59.6343, -52.1067],
              [-59.6335, -52.1149],
              [-59.6183, -52.1134],
              [-59.6185, -52.107],
              [-59.604, -52.1106],
              [-59.6006, -52.1085],
              [-59.6078, -52.0986],
              [-59.6086, -52.0926],
              [-59.6169, -52.0908],
              [-59.6308, -52.0809],
              [-59.6283, -52.0766],
              [-59.6334, -52.0736],
              [-59.6264, -52.0663],
              [-59.6203, -52.0713],
              [-59.6082, -52.0698],
              [-59.6108, -52.079],
              [-59.6015, -52.0815],
              [-59.5941, -52.0722],
              [-59.5882, -52.0702],
              [-59.5772, -52.0623],
              [-59.5738, -52.0665],
              [-59.5623, -52.0685],
              [-59.5586, -52.0645],
              [-59.5626, -52.055],
              [-59.5676, -52.0518],
              [-59.5645, -52.0458],
              [-59.5742, -52.0419],
              [-59.5979, -52.0424],
              [-59.6009, -52.0369],
              [-59.6103, -52.0368],
              [-59.606, -52.0292],
              [-59.6101, -52.0202],
              [-59.5978, -52.0198],
              [-59.5939, -52.0225],
              [-59.5864, -52.0198],
              [-59.5923, -52.0121],
              [-59.6078, -52.0124],
              [-59.6147, -52.0093],
              [-59.6303, -52.009],
              [-59.6381, -52.0068],
              [-59.6475, -51.9948],
              [-59.643, -51.9874],
              [-59.6324, -51.9881],
              [-59.6308, -51.9964],
              [-59.6033, -51.9883],
              [-59.5924, -51.9911],
              [-59.5891, -51.9951],
              [-59.5767, -51.9935],
              [-59.5717, -51.9969],
              [-59.5565, -51.9995],
              [-59.5464, -51.9956],
              [-59.55, -51.992],
              [-59.561, -51.993],
              [-59.5699, -51.9871],
              [-59.58, -51.9838],
              [-59.5932, -51.9849],
              [-59.5927, -51.977],
              [-59.6032, -51.9721],
              [-59.605, -51.968],
              [-59.6169, -51.9689],
              [-59.6216, -51.9596],
              [-59.6268, -51.962],
              [-59.6343, -51.9551],
              [-59.6301, -51.9496],
              [-59.6241, -51.9551],
              [-59.6038, -51.9585],
              [-59.6019, -51.9637],
              [-59.592, -51.9729],
              [-59.5805, -51.9714],
              [-59.5702, -51.9683],
              [-59.5613, -51.9634],
              [-59.5543, -51.9652],
              [-59.5459, -51.9616],
              [-59.5416, -51.9654],
              [-59.5323, -51.9581],
              [-59.5248, -51.9581],
              [-59.5196, -51.9533],
              [-59.5342, -51.9521],
              [-59.5482, -51.9531],
              [-59.5554, -51.9501],
              [-59.5526, -51.9467],
              [-59.5566, -51.942],
              [-59.5716, -51.9499],
              [-59.5772, -51.9491],
              [-59.5797, -51.9434],
              [-59.5864, -51.9417],
              [-59.5942, -51.9438],
              [-59.6053, -51.9429],
              [-59.6112, -51.9381],
              [-59.6066, -51.9295],
              [-59.6071, -51.9194],
              [-59.6045, -51.915],
              [-59.6066, -51.9068],
              [-59.5991, -51.9064],
              [-59.5907, -51.8976],
              [-59.5856, -51.8948],
              [-59.5731, -51.8994],
              [-59.5673, -51.9035],
              [-59.568, -51.9125],
              [-59.555, -51.9118],
              [-59.5491, -51.9166],
              [-59.5441, -51.9152],
              [-59.5369, -51.9214],
              [-59.5312, -51.9155],
              [-59.5255, -51.9171],
              [-59.5159, -51.9243],
              [-59.5156, -51.9272],
              [-59.4964, -51.9275],
              [-59.4995, -51.919],
              [-59.495, -51.9164],
              [-59.4755, -51.9202],
              [-59.4789, -51.9161],
              [-59.4737, -51.912],
              [-59.474, -51.9063],
              [-59.4992, -51.9005],
              [-59.5093, -51.9024],
              [-59.5107, -51.8968],
              [-59.5228, -51.8946],
              [-59.5201, -51.8832],
              [-59.5237, -51.8747],
              [-59.5338, -51.8732],
              [-59.5217, -51.8638],
              [-59.5117, -51.8646],
              [-59.5151, -51.8731],
              [-59.5023, -51.8711],
              [-59.5032, -51.8787],
              [-59.498, -51.8826],
              [-59.4875, -51.8864],
              [-59.4693, -51.8853],
              [-59.4586, -51.8748],
              [-59.4808, -51.8755],
              [-59.485, -51.8715],
              [-59.4737, -51.8637],
              [-59.4654, -51.8622],
              [-59.4607, -51.8571],
              [-59.4762, -51.8558],
              [-59.485, -51.8603],
              [-59.4857, -51.8521],
              [-59.497, -51.8503],
              [-59.5035, -51.8426],
              [-59.4944, -51.8401],
              [-59.5022, -51.8356],
              [-59.4983, -51.8301],
              [-59.4904, -51.8251],
              [-59.4674, -51.8256],
              [-59.4481, -51.815],
              [-59.4466, -51.8182],
              [-59.4537, -51.828],
              [-59.4478, -51.8303],
              [-59.4328, -51.8207],
              [-59.4278, -51.8154],
              [-59.4187, -51.8171],
              [-59.4103, -51.8052],
              [-59.4053, -51.805],
              [-59.4018, -51.8112],
              [-59.4021, -51.8185],
              [-59.4114, -51.8271],
              [-59.4037, -51.8311],
              [-59.3958, -51.826],
              [-59.3946, -51.8349],
              [-59.387, -51.8337],
              [-59.3833, -51.8422],
              [-59.3713, -51.8348],
              [-59.3764, -51.8283],
              [-59.3761, -51.8239],
              [-59.366, -51.8149],
              [-59.3592, -51.8112],
              [-59.3557, -51.805],
              [-59.3561, -51.7974],
              [-59.3597, -51.7937],
              [-59.3497, -51.7854],
              [-59.3493, -51.7788],
              [-59.34, -51.7767],
              [-59.3305, -51.787],
              [-59.3258, -51.7881],
              [-59.3279, -51.796],
              [-59.314, -51.7934],
              [-59.2979, -51.7809],
              [-59.2916, -51.7827],
              [-59.291, -51.7886],
              [-59.2756, -51.7897],
              [-59.2726, -51.788],
              [-59.2812, -51.7817],
              [-59.3006, -51.7626],
              [-59.3033, -51.7529],
              [-59.2812, -51.7496],
              [-59.2788, -51.74],
              [-59.2698, -51.7352],
              [-59.2679, -51.7406],
              [-59.2589, -51.7417],
              [-59.263, -51.7465],
              [-59.2554, -51.749],
              [-59.2621, -51.7579],
              [-59.2495, -51.7588],
              [-59.24, -51.7457],
              [-59.2519, -51.745],
              [-59.249, -51.7378],
              [-59.2485, -51.7289],
              [-59.234, -51.7163],
              [-59.2214, -51.711],
              [-59.2147, -51.7114],
              [-59.2109, -51.7184],
              [-59.2183, -51.726],
              [-59.2092, -51.7312],
              [-59.2016, -51.7281],
              [-59.1997, -51.717],
              [-59.195, -51.7172],
              [-59.1887, -51.7245],
              [-59.1809, -51.7266],
              [-59.1788, -51.7197],
              [-59.1687, -51.7181],
              [-59.1602, -51.7137],
              [-59.162, -51.7021],
              [-59.1552, -51.698],
              [-59.1435, -51.6989],
              [-59.1262, -51.6908],
              [-59.1211, -51.696],
              [-59.1058, -51.6986],
              [-59.097, -51.6958],
              [-59.0988, -51.6908],
              [-59.0848, -51.6855],
              [-59.0729, -51.6898],
              [-59.0576, -51.6843],
              [-59.0587, -51.6907],
              [-59.0483, -51.6943],
              [-59.0474, -51.7062],
              [-59.0593, -51.7131],
              [-59.0556, -51.7183],
              [-59.0487, -51.7186],
              [-59.045, -51.7251],
              [-59.0432, -51.7367],
              [-59.0373, -51.7402],
              [-59.0431, -51.7473],
              [-59.0396, -51.7519],
              [-59.031, -51.752],
              [-59.0269, -51.7676],
              [-59.0432, -51.7684],
              [-59.0435, -51.7731],
              [-59.0507, -51.7748],
              [-59.0478, -51.7795],
              [-59.0497, -51.7854],
              [-59.0432, -51.7964],
              [-59.0352, -51.8009],
              [-59.0464, -51.8037],
              [-59.0481, -51.8111],
              [-59.0536, -51.8138],
              [-59.0513, -51.8197],
              [-59.0375, -51.8204],
              [-59.03, -51.8246],
              [-59.0257, -51.8189],
              [-59.0343, -51.8165],
              [-59.0324, -51.8099],
              [-59.0264, -51.8089],
              [-59.0238, -51.8151],
              [-59.0137, -51.8165],
              [-59.0049, -51.8091],
              [-58.9853, -51.7877],
              [-59.001, -51.7708],
              [-59.0057, -51.7688],
              [-59.0075, -51.7621],
              [-58.9986, -51.755],
              [-59.0074, -51.7527],
              [-59.0065, -51.7434],
              [-59.0114, -51.7378],
              [-59.0131, -51.7298],
              [-59.0098, -51.7263],
              [-59.0157, -51.7182],
              [-59.0144, -51.7154],
              [-59.0195, -51.7044],
              [-59.0383, -51.7084],
              [-59.0434, -51.6942],
              [-59.032, -51.6909],
              [-59.0316, -51.6836],
              [-59.0233, -51.6821],
              [-59.0288, -51.6761],
              [-59.0363, -51.6743],
              [-59.0233, -51.6649],
              [-59.0258, -51.6598],
              [-59.0436, -51.6536],
              [-59.0448, -51.6481],
              [-59.0223, -51.6536],
              [-59.0145, -51.6569],
              [-59.0099, -51.6529],
              [-59.0209, -51.6509],
              [-59.0415, -51.6432],
              [-59.0676, -51.6368],
              [-59.073, -51.6404],
              [-59.0822, -51.6358],
              [-59.0813, -51.6295],
              [-59.0885, -51.6236],
              [-59.1012, -51.6209],
              [-59.1008, -51.6157],
              [-59.1097, -51.6078],
              [-59.1301, -51.6019],
              [-59.1387, -51.597],
              [-59.1493, -51.5959],
              [-59.1553, -51.5915],
              [-59.167, -51.5904],
              [-59.1774, -51.5827],
              [-59.1776, -51.5756],
              [-59.1738, -51.5719],
              [-59.1583, -51.5718],
              [-59.1488, -51.5605],
              [-59.1378, -51.5436],
              [-59.1355, -51.5377],
              [-59.1258, -51.53],
              [-59.115, -51.5271],
              [-59.1134, -51.5241],
              [-59.1162, -51.5089],
              [-59.1289, -51.495],
              [-59.1093, -51.4982],
              [-59.1052, -51.5045],
              [-59.0955, -51.5131],
              [-59.0858, -51.5264],
              [-59.0839, -51.5379],
              [-59.0802, -51.5461],
              [-59.0736, -51.5533],
              [-59.0795, -51.5617],
              [-59.067, -51.5657],
              [-59.0662, -51.5706],
              [-59.0736, -51.5762],
              [-59.0668, -51.5791],
              [-59.0587, -51.5771],
              [-59.0424, -51.5926],
              [-59.0286, -51.5942],
              [-59.038, -51.5847],
              [-59.0354, -51.5793],
              [-59.0277, -51.577],
              [-59.0309, -51.5722],
              [-59.0413, -51.5737],
              [-59.048, -51.5691],
              [-59.0473, -51.557],
              [-59.0347, -51.5573],
              [-59.03, -51.5543],
              [-59.0536, -51.5377],
              [-59.0606, -51.5288],
              [-59.058, -51.5237],
              [-59.0636, -51.5198],
              [-59.066, -51.514],
              [-59.0514, -51.5087],
              [-59.0463, -51.5021],
              [-59.0352, -51.5003],
              [-59.0233, -51.5051],
              [-59.0054, -51.5081],
              [-58.987, -51.5084],
              [-58.9853, -51.5027],
              [-58.9773, -51.5039],
              [-58.966, -51.5144],
              [-58.9491, -51.5078],
              [-58.9555, -51.5049],
              [-58.9656, -51.5084],
              [-58.9701, -51.5028],
              [-58.9866, -51.5004],
              [-58.9886, -51.503],
              [-59.004, -51.5013],
              [-59.0208, -51.4965],
              [-59.0194, -51.4922],
              [-59.0333, -51.4881],
              [-59.0606, -51.4782],
              [-59.0686, -51.4724],
              [-59.087, -51.4691],
              [-59.094, -51.4654],
              [-59.103, -51.4666],
              [-59.1018, -51.4725],
              [-59.1111, -51.4766],
              [-59.1188, -51.4751],
              [-59.1247, -51.4672],
              [-59.1379, -51.467],
              [-59.1433, -51.4597],
              [-59.1389, -51.4549],
              [-59.1398, -51.4456],
              [-59.137, -51.4401],
              [-59.1279, -51.4361],
              [-59.1153, -51.4337],
              [-59.1091, -51.4306],
              [-59.1126, -51.4248],
              [-59.1026, -51.4103],
              [-59.0951, -51.4076],
              [-59.0834, -51.4154],
              [-59.0749, -51.4172],
              [-59.0701, -51.4252],
              [-59.0571, -51.4241],
              [-59.0512, -51.431],
              [-59.0412, -51.4312],
              [-59.0167, -51.4243],
              [-59.0015, -51.4262],
              [-58.9806, -51.4232],
              [-58.9719, -51.427],
              [-58.9746, -51.4345],
              [-58.9815, -51.438],
              [-58.9797, -51.4421],
              [-58.9709, -51.442],
              [-58.9612, -51.4448],
              [-58.9639, -51.4279],
              [-58.9722, -51.4212],
              [-58.9853, -51.4188],
              [-59.0054, -51.4222],
              [-59.0156, -51.42],
              [-59.018, -51.415],
              [-59.0136, -51.4116],
              [-58.9979, -51.4061],
              [-59.0023, -51.4021],
              [-58.9969, -51.3972],
              [-58.9965, -51.3921],
              [-59.0045, -51.3878],
              [-58.9988, -51.3815],
              [-58.9917, -51.3823],
              [-58.9722, -51.3802],
              [-58.9572, -51.3832],
              [-58.949, -51.3819],
              [-58.9387, -51.3862],
              [-58.9332, -51.38],
              [-58.9205, -51.3788],
              [-58.9066, -51.3806],
              [-58.901, -51.3832],
              [-58.8934, -51.3806],
              [-58.8795, -51.3827],
              [-58.8723, -51.3799],
              [-58.864, -51.3718],
              [-58.8624, -51.3625],
              [-58.8595, -51.3595],
              [-58.8613, -51.3525],
              [-58.8749, -51.347],
              [-58.8818, -51.3387],
              [-58.8934, -51.329],
              [-58.9007, -51.3257],
              [-58.9034, -51.3195],
              [-58.9143, -51.3142],
              [-58.9183, -51.3059],
              [-58.9147, -51.3002],
              [-58.9192, -51.2934],
              [-58.9392, -51.2804],
              [-58.9417, -51.2767],
              [-58.9617, -51.2656],
              [-58.9543, -51.2656],
              [-58.9459, -51.2608],
              [-58.9463, -51.2563],
              [-58.9517, -51.2521],
              [-58.9583, -51.2513],
              [-58.9634, -51.2396],
              [-58.9615, -51.2341],
              [-58.9492, -51.2408],
              [-58.9453, -51.2455],
              [-58.9346, -51.2483],
              [-58.9138, -51.2654]
            ]
          ],
          [
            [
              [-59.8498, -51.2503],
              [-59.8443, -51.2553],
              [-59.8371, -51.2574],
              [-59.84, -51.2622],
              [-59.8485, -51.2618],
              [-59.8523, -51.2646],
              [-59.8738, -51.2543],
              [-59.8803, -51.257],
              [-59.8862, -51.2532],
              [-59.8881, -51.2482],
              [-59.897, -51.238],
              [-59.886, -51.2359],
              [-59.8654, -51.241],
              [-59.8565, -51.2492],
              [-59.8498, -51.2503]
            ]
          ],
          [
            [
              [-59.9428, -51.2905],
              [-59.9399, -51.298],
              [-59.9356, -51.3012],
              [-59.9209, -51.3039],
              [-59.9181, -51.3122],
              [-59.8998, -51.3164],
              [-59.9062, -51.3224],
              [-59.9164, -51.3244],
              [-59.9201, -51.3175],
              [-59.9328, -51.3228],
              [-59.9334, -51.3303],
              [-59.9281, -51.3338],
              [-59.9184, -51.3362],
              [-59.9172, -51.3424],
              [-59.9227, -51.3517],
              [-59.9279, -51.3543],
              [-59.946, -51.3541],
              [-59.9495, -51.3505],
              [-59.9614, -51.3491],
              [-59.9793, -51.3438],
              [-59.9995, -51.3338],
              [-60.0045, -51.3297],
              [-60.024, -51.3201],
              [-60.0362, -51.3097],
              [-60.0389, -51.3031],
              [-60.0355, -51.3005],
              [-60.019, -51.2964],
              [-59.9915, -51.2972],
              [-59.9912, -51.2885],
              [-59.9815, -51.2834],
              [-59.9725, -51.2907],
              [-59.9625, -51.287],
              [-59.9603, -51.2817],
              [-59.9513, -51.28],
              [-59.9505, -51.2855],
              [-59.9428, -51.2905]
            ]
          ],
          [
            [
              [-60.8286, -51.1563],
              [-60.823, -51.1628],
              [-60.8257, -51.1658],
              [-60.8431, -51.1692],
              [-60.8529, -51.1747],
              [-60.8621, -51.1743],
              [-60.8515, -51.1564],
              [-60.8429, -51.1585],
              [-60.8356, -51.154],
              [-60.8286, -51.1563]
            ]
          ],
          [
            [
              [-60.4889, -51.4837],
              [-60.5047, -51.4866],
              [-60.5002, -51.4905],
              [-60.5078, -51.4957],
              [-60.5187, -51.499],
              [-60.5243, -51.4926],
              [-60.523, -51.4884],
              [-60.5156, -51.4875],
              [-60.5066, -51.4802],
              [-60.4889, -51.4837]
            ]
          ],
          [
            [
              [-60.4536, -51.3296],
              [-60.4573, -51.341],
              [-60.4665, -51.3359],
              [-60.4649, -51.3302],
              [-60.4588, -51.3274],
              [-60.4536, -51.3296]
            ]
          ],
          [
            [
              [-60.2876, -51.2646],
              [-60.2766, -51.2622],
              [-60.2745, -51.2689],
              [-60.2628, -51.2692],
              [-60.2582, -51.2726],
              [-60.2573, -51.2796],
              [-60.2457, -51.2918],
              [-60.2418, -51.2982],
              [-60.2299, -51.3031],
              [-60.2068, -51.3056],
              [-60.1813, -51.3129],
              [-60.1618, -51.3142],
              [-60.1463, -51.3126],
              [-60.1069, -51.3031],
              [-60.0841, -51.3008],
              [-60.0803, -51.3037],
              [-60.0846, -51.308],
              [-60.0786, -51.3119],
              [-60.0636, -51.3098],
              [-60.0582, -51.3108],
              [-60.0584, -51.3168],
              [-60.0707, -51.3235],
              [-60.0846, -51.3212],
              [-60.0856, -51.3255],
              [-60.0684, -51.3275],
              [-60.0633, -51.3295],
              [-60.0432, -51.3301],
              [-60.0388, -51.3362],
              [-60.0483, -51.3394],
              [-60.0571, -51.353],
              [-60.062, -51.3563],
              [-60.0743, -51.3592],
              [-60.071, -51.3674],
              [-60.0727, -51.3707],
              [-60.0823, -51.3735],
              [-60.085, -51.3774],
              [-60.1069, -51.3854],
              [-60.113, -51.3894],
              [-60.1099, -51.3997],
              [-60.1379, -51.4027],
              [-60.1504, -51.4018],
              [-60.1581, -51.4033],
              [-60.1667, -51.3996],
              [-60.1773, -51.3976],
              [-60.1852, -51.4037],
              [-60.1891, -51.3954],
              [-60.2039, -51.397],
              [-60.2078, -51.4016],
              [-60.2161, -51.403],
              [-60.2249, -51.4107],
              [-60.2521, -51.412],
              [-60.2715, -51.4067],
              [-60.2846, -51.3969],
              [-60.2893, -51.3918],
              [-60.2773, -51.3916],
              [-60.267, -51.3942],
              [-60.2681, -51.3888],
              [-60.2762, -51.3834],
              [-60.2803, -51.3723],
              [-60.2779, -51.3681],
              [-60.2676, -51.3623],
              [-60.2476, -51.3611],
              [-60.2393, -51.3586],
              [-60.229, -51.3501],
              [-60.2299, -51.3454],
              [-60.2372, -51.3424],
              [-60.2257, -51.3357],
              [-60.2195, -51.3391],
              [-60.1985, -51.347],
              [-60.1819, -51.3601],
              [-60.1814, -51.3665],
              [-60.1744, -51.375],
              [-60.1634, -51.376],
              [-60.1755, -51.3823],
              [-60.1761, -51.3885],
              [-60.1679, -51.3895],
              [-60.1631, -51.3937],
              [-60.1481, -51.3888],
              [-60.1332, -51.3892],
              [-60.1392, -51.3788],
              [-60.1237, -51.3809],
              [-60.1222, -51.3708],
              [-60.1161, -51.3632],
              [-60.1254, -51.3577],
              [-60.1338, -51.3586],
              [-60.1398, -51.3526],
              [-60.15, -51.3551],
              [-60.1887, -51.3447],
              [-60.2078, -51.3356],
              [-60.2223, -51.3264],
              [-60.2293, -51.3196],
              [-60.2332, -51.3093],
              [-60.2444, -51.3095],
              [-60.2774, -51.304],
              [-60.2963, -51.2954],
              [-60.3154, -51.282],
              [-60.316, -51.2674],
              [-60.3108, -51.2655],
              [-60.3028, -51.2692],
              [-60.2946, -51.2695],
              [-60.2876, -51.2646]
            ]
          ],
          [
            [
              [-61.2372, -51.0285],
              [-61.2258, -51.0298],
              [-61.2089, -51.0475],
              [-61.1799, -51.0528],
              [-61.168, -51.0602],
              [-61.1664, -51.0665],
              [-61.1799, -51.0695],
              [-61.1999, -51.0638],
              [-61.2114, -51.0552],
              [-61.2136, -51.0494],
              [-61.2282, -51.0507],
              [-61.2375, -51.0436],
              [-61.2493, -51.0408],
              [-61.256, -51.0366],
              [-61.2562, -51.0276],
              [-61.2659, -51.0202],
              [-61.2473, -51.0244],
              [-61.2372, -51.0285]
            ]
          ],
          [
            [
              [-59.8883, -51.2703],
              [-59.8991, -51.2672],
              [-59.8939, -51.2644],
              [-59.8883, -51.2703]
            ]
          ],
          [
            [
              [-59.1827, -51.5858],
              [-59.1889, -51.5856],
              [-59.195, -51.5794],
              [-59.1908, -51.5765],
              [-59.1836, -51.5809],
              [-59.1827, -51.5858]
            ]
          ],
          [
            [
              [-61.4447, -51.0185],
              [-61.4605, -51.0147],
              [-61.4577, -51.0112],
              [-61.4455, -51.0161],
              [-61.4447, -51.0185]
            ]
          ],
          [
            [
              [-59.8912, -51.3744],
              [-59.9, -51.3691],
              [-59.8883, -51.3636],
              [-59.8798, -51.3651],
              [-59.882, -51.3692],
              [-59.8912, -51.3744]
            ]
          ],
          [
            [
              [-59.5994, -51.4014],
              [-59.5913, -51.4055],
              [-59.591, -51.4136],
              [-59.5877, -51.4198],
              [-59.5933, -51.4225],
              [-59.6008, -51.4195],
              [-59.6117, -51.4185],
              [-59.6235, -51.4138],
              [-59.6295, -51.4169],
              [-59.6381, -51.4144],
              [-59.6355, -51.4052],
              [-59.6214, -51.4051],
              [-59.6151, -51.4018],
              [-59.5994, -51.4014]
            ]
          ],
          [
            [
              [-59.6197, -51.3661],
              [-59.5923, -51.3672],
              [-59.595, -51.3769],
              [-59.6124, -51.3769],
              [-59.6263, -51.3801],
              [-59.6478, -51.3768],
              [-59.6531, -51.3808],
              [-59.6631, -51.3801],
              [-59.6665, -51.3727],
              [-59.6555, -51.3711],
              [-59.6482, -51.3651],
              [-59.6197, -51.3661]
            ]
          ],
          [
            [
              [-60.3616, -51.3706],
              [-60.3717, -51.3721],
              [-60.3843, -51.3683],
              [-60.3982, -51.3618],
              [-60.4044, -51.3573],
              [-60.4008, -51.3534],
              [-60.3798, -51.357],
              [-60.3724, -51.3599],
              [-60.3616, -51.3677],
              [-60.3616, -51.3706]
            ]
          ],
          [
            [
              [-60.8752, -51.7605],
              [-60.8712, -51.7617],
              [-60.8513, -51.7615],
              [-60.8459, -51.7597],
              [-60.8406, -51.7639],
              [-60.8438, -51.7772],
              [-60.8405, -51.7855],
              [-60.8357, -51.7911],
              [-60.8463, -51.7982],
              [-60.8376, -51.8041],
              [-60.8427, -51.8098],
              [-60.8535, -51.8144],
              [-60.8597, -51.8198],
              [-60.8516, -51.8286],
              [-60.8567, -51.8341],
              [-60.8616, -51.8447],
              [-60.8565, -51.8531],
              [-60.8604, -51.8587],
              [-60.8611, -51.8664],
              [-60.8576, -51.8704],
              [-60.8601, -51.8792],
              [-60.8495, -51.8835],
              [-60.8511, -51.8877],
              [-60.8771, -51.8909],
              [-60.8826, -51.8944],
              [-60.8829, -51.9042],
              [-60.8759, -51.9011],
              [-60.8694, -51.9016],
              [-60.8667, -51.9061],
              [-60.8531, -51.8971],
              [-60.8443, -51.9007],
              [-60.845, -51.9113],
              [-60.8492, -51.9154],
              [-60.8612, -51.9168],
              [-60.8637, -51.9192],
              [-60.8611, -51.928],
              [-60.8657, -51.9341],
              [-60.884, -51.9361],
              [-60.8946, -51.9322],
              [-60.893, -51.939],
              [-60.878, -51.9403],
              [-60.8765, -51.9452],
              [-60.8683, -51.947],
              [-60.8861, -51.9542],
              [-60.8944, -51.9518],
              [-60.9022, -51.9545],
              [-60.9104, -51.9534],
              [-60.9203, -51.9607],
              [-60.9319, -51.9613],
              [-60.9345, -51.9675],
              [-60.9437, -51.9772],
              [-60.9325, -51.9782],
              [-60.9381, -51.984],
              [-60.9528, -51.9793],
              [-60.9578, -51.9867],
              [-60.9741, -51.9857],
              [-60.9827, -51.9733],
              [-60.9956, -51.9719],
              [-61.0032, -51.9632],
              [-61.0147, -51.9634],
              [-61.022, -51.9589],
              [-61.0103, -51.9563],
              [-61.013, -51.9529],
              [-61.0261, -51.9468],
              [-61.0304, -51.9416],
              [-61.0417, -51.9407],
              [-61.044, -51.9382],
              [-61.0386, -51.9331],
              [-61.0525, -51.9266],
              [-61.052, -51.9221],
              [-61.0446, -51.9155],
              [-61.0505, -51.9134],
              [-61.0606, -51.9215],
              [-61.0634, -51.9274],
              [-61.0741, -51.9286],
              [-61.0808, -51.9224],
              [-61.0736, -51.9174],
              [-61.0825, -51.9073],
              [-61.088, -51.9091],
              [-61.1077, -51.9011],
              [-61.1027, -51.8912],
              [-61.0929, -51.8842],
              [-61.1048, -51.8826],
              [-61.1102, -51.8769],
              [-61.1114, -51.8714],
              [-61.1065, -51.8689],
              [-61.107, -51.8592],
              [-61.1015, -51.855],
              [-61.0885, -51.8522],
              [-61.0773, -51.8571],
              [-61.0688, -51.8522],
              [-61.0688, -51.8484],
              [-61.0831, -51.8467],
              [-61.1018, -51.8519],
              [-61.1092, -51.8517],
              [-61.1062, -51.8426],
              [-61.1318, -51.8526],
              [-61.1371, -51.8499],
              [-61.1368, -51.8379],
              [-61.1417, -51.8336],
              [-61.137, -51.8301],
              [-61.1256, -51.8372],
              [-61.1186, -51.8311],
              [-61.1115, -51.8305],
              [-61.1024, -51.8155],
              [-61.0881, -51.8184],
              [-61.0842, -51.8127],
              [-61.0752, -51.8089],
              [-61.0685, -51.814],
              [-61.0748, -51.8187],
              [-61.0855, -51.8342],
              [-61.07, -51.8244],
              [-61.0593, -51.8248],
              [-61.0538, -51.8296],
              [-61.0386, -51.827],
              [-61.0307, -51.8116],
              [-61.0201, -51.8055],
              [-61.0197, -51.7984],
              [-61.0117, -51.7935],
              [-61.0158, -51.787],
              [-61.0142, -51.7827],
              [-61.0056, -51.7779],
              [-60.9963, -51.7778],
              [-60.9848, -51.7835],
              [-60.9768, -51.7846],
              [-60.9689, -51.7806],
              [-60.9549, -51.7766],
              [-60.9524, -51.7805],
              [-60.9613, -51.7849],
              [-60.9519, -51.7892],
              [-60.9521, -51.7948],
              [-60.9416, -51.8009],
              [-60.9328, -51.7973],
              [-60.9209, -51.7991],
              [-60.9162, -51.8024],
              [-60.92, -51.8122],
              [-60.9155, -51.8168],
              [-60.9284, -51.8177],
              [-60.9396, -51.8152],
              [-60.9432, -51.8205],
              [-60.9372, -51.8257],
              [-60.9515, -51.8286],
              [-60.9617, -51.8333],
              [-60.9684, -51.8407],
              [-60.9786, -51.8484],
              [-60.9952, -51.8499],
              [-61.0064, -51.8598],
              [-61.0034, -51.8727],
              [-61.0156, -51.879],
              [-61.0162, -51.8818],
              [-60.9888, -51.8685],
              [-60.9808, -51.8682],
              [-60.9689, -51.8705],
              [-60.9604, -51.8703],
              [-60.9513, -51.8601],
              [-60.9389, -51.8582],
              [-60.9301, -51.8632],
              [-60.926, -51.8599],
              [-60.9293, -51.8525],
              [-60.9284, -51.8443],
              [-60.9217, -51.8376],
              [-60.9099, -51.8471],
              [-60.9065, -51.8422],
              [-60.909, -51.838],
              [-60.8963, -51.8284],
              [-60.8886, -51.8294],
              [-60.877, -51.8194],
              [-60.8704, -51.8196],
              [-60.8637, -51.8158],
              [-60.8545, -51.8065],
              [-60.8596, -51.8007],
              [-60.8664, -51.8062],
              [-60.8684, -51.8131],
              [-60.877, -51.8017],
              [-60.8787, -51.797],
              [-60.8874, -51.7912],
              [-60.8942, -51.7757],
              [-60.8891, -51.7692],
              [-60.8792, -51.7731],
              [-60.8721, -51.7719],
              [-60.8752, -51.7605]
            ]
          ],
          [
            [
              [-59.6522, -51.7898],
              [-59.6599, -51.7892],
              [-59.6578, -51.7814],
              [-59.638, -51.7684],
              [-59.6217, -51.7608],
              [-59.6171, -51.7621],
              [-59.6324, -51.7782],
              [-59.6522, -51.7898]
            ]
          ],
          [
            [
              [-61.0482, -51.7797],
              [-61.0295, -51.7806],
              [-61.0199, -51.7764],
              [-61.0127, -51.7788],
              [-61.0317, -51.7876],
              [-61.0358, -51.7916],
              [-61.0599, -51.8035],
              [-61.0652, -51.8027],
              [-61.0649, -51.7969],
              [-61.0557, -51.7944],
              [-61.0481, -51.7955],
              [-61.0458, -51.7886],
              [-61.067, -51.7819],
              [-61.0677, -51.777],
              [-61.0607, -51.774],
              [-61.0504, -51.7769],
              [-61.0482, -51.7797]
            ]
          ],
          [
            [
              [-61.072, -51.7932],
              [-61.0801, -51.7929],
              [-61.0859, -51.7849],
              [-61.0828, -51.7795],
              [-61.0676, -51.7867],
              [-61.072, -51.7932]
            ]
          ],
          [
            [
              [-58.0811, -51.7881],
              [-58.0641, -51.7921],
              [-58.0746, -51.7978],
              [-58.0855, -51.7959],
              [-58.0921, -51.7988],
              [-58.102, -51.7893],
              [-58.0811, -51.7881]
            ]
          ],
          [
            [
              [-61.0918, -51.7978],
              [-61.0831, -51.7991],
              [-61.0917, -51.8059],
              [-61.1027, -51.8078],
              [-61.0973, -51.7992],
              [-61.0918, -51.7978]
            ]
          ],
          [
            [
              [-61.1237, -51.7839],
              [-61.113, -51.785],
              [-61.1138, -51.7978],
              [-61.1205, -51.799],
              [-61.1233, -51.7944],
              [-61.1375, -51.7948],
              [-61.1366, -51.7862],
              [-61.1304, -51.7823],
              [-61.1237, -51.7839]
            ]
          ],
          [
            [
              [-61.1875, -51.814],
              [-61.1823, -51.8205],
              [-61.1732, -51.8202],
              [-61.1831, -51.8313],
              [-61.1904, -51.8295],
              [-61.1958, -51.8236],
              [-61.2045, -51.8256],
              [-61.2128, -51.8236],
              [-61.2226, -51.8317],
              [-61.2247, -51.8394],
              [-61.2127, -51.8369],
              [-61.2049, -51.8394],
              [-61.211, -51.8507],
              [-61.2179, -51.8518],
              [-61.2166, -51.8567],
              [-61.2031, -51.8502],
              [-61.1902, -51.8482],
              [-61.1816, -51.8519],
              [-61.1807, -51.8619],
              [-61.1895, -51.8629],
              [-61.193, -51.8737],
              [-61.2063, -51.879],
              [-61.2144, -51.8787],
              [-61.2313, -51.8705],
              [-61.2393, -51.8687],
              [-61.2517, -51.8696],
              [-61.2536, -51.8668],
              [-61.2669, -51.8639],
              [-61.2909, -51.8423],
              [-61.2969, -51.8392],
              [-61.3115, -51.8395],
              [-61.3197, -51.8366],
              [-61.3194, -51.8283],
              [-61.3076, -51.8241],
              [-61.294, -51.8212],
              [-61.2991, -51.8145],
              [-61.2917, -51.8101],
              [-61.2854, -51.8142],
              [-61.2846, -51.8037],
              [-61.2771, -51.8037],
              [-61.2738, -51.8093],
              [-61.265, -51.8176],
              [-61.2554, -51.8226],
              [-61.2436, -51.833],
              [-61.2322, -51.8321],
              [-61.2279, -51.8249],
              [-61.2288, -51.8209],
              [-61.2373, -51.8173],
              [-61.2385, -51.8102],
              [-61.2288, -51.809],
              [-61.2225, -51.8148],
              [-61.213, -51.8126],
              [-61.2175, -51.8045],
              [-61.2127, -51.797],
              [-61.1967, -51.7983],
              [-61.1925, -51.8101],
              [-61.1875, -51.814]
            ]
          ],
          [
            [
              [-61.0376, -51.8106],
              [-61.0435, -51.8089],
              [-61.0412, -51.8043],
              [-61.0323, -51.805],
              [-61.0376, -51.8106]
            ]
          ],
          [
            [
              [-58.7287, -51.9235],
              [-58.7286, -51.9265],
              [-58.7417, -51.9269],
              [-58.7414, -51.9149],
              [-58.7321, -51.9152],
              [-58.7351, -51.9206],
              [-58.7287, -51.9235]
            ]
          ],
          [
            [
              [-59.6605, -51.9671],
              [-59.6725, -51.9761],
              [-59.6819, -51.977],
              [-59.6872, -51.9807],
              [-59.6949, -51.968],
              [-59.7163, -51.9675],
              [-59.716, -51.9586],
              [-59.7054, -51.957],
              [-59.7024, -51.9543],
              [-59.7101, -51.9461],
              [-59.7077, -51.9294],
              [-59.7031, -51.927],
              [-59.6858, -51.9228],
              [-59.6851, -51.9274],
              [-59.6796, -51.9298],
              [-59.6768, -51.9356],
              [-59.695, -51.9455],
              [-59.6896, -51.9496],
              [-59.6769, -51.9511],
              [-59.6712, -51.9571],
              [-59.6614, -51.9623],
              [-59.6605, -51.9671]
            ]
          ],
          [
            [
              [-59.5515, -51.7736],
              [-59.5504, -51.7895],
              [-59.5573, -51.7982],
              [-59.5672, -51.793],
              [-59.5745, -51.7953],
              [-59.5873, -51.7944],
              [-59.5916, -51.7978],
              [-59.6092, -51.7991],
              [-59.6183, -51.8036],
              [-59.6249, -51.793],
              [-59.6252, -51.7869],
              [-59.6183, -51.7798],
              [-59.6034, -51.7749],
              [-59.5989, -51.7789],
              [-59.5799, -51.7743],
              [-59.573, -51.7715],
              [-59.5659, -51.7645],
              [-59.5536, -51.7585],
              [-59.5507, -51.7539],
              [-59.5373, -51.7486],
              [-59.5313, -51.7496],
              [-59.5506, -51.7652],
              [-59.5515, -51.7736]
            ]
          ],
          [
            [
              [-58.4696, -51.9475],
              [-58.4818, -51.9582],
              [-58.4887, -51.9528],
              [-58.5003, -51.9507],
              [-58.4992, -51.9477],
              [-58.4863, -51.9484],
              [-58.476, -51.9454],
              [-58.4696, -51.9475]
            ]
          ],
          [
            [
              [-60.8462, -51.9611],
              [-60.8321, -51.9653],
              [-60.8287, -51.9803],
              [-60.8302, -51.9895],
              [-60.8376, -51.9891],
              [-60.8398, -51.9949],
              [-60.8352, -51.9992],
              [-60.8262, -51.9977],
              [-60.8184, -51.9996],
              [-60.8236, -52.0081],
              [-60.8297, -52.0093],
              [-60.8429, -52.0051],
              [-60.8598, -52.0026],
              [-60.8725, -52.0035],
              [-60.887, -52.0005],
              [-60.8885, -51.9961],
              [-60.8946, -51.9922],
              [-60.9026, -51.9913],
              [-60.9111, -51.9962],
              [-60.9209, -51.9901],
              [-60.913, -51.987],
              [-60.9018, -51.9863],
              [-60.898, -51.9837],
              [-60.8867, -51.9857],
              [-60.8819, -51.9825],
              [-60.8818, -51.9763],
              [-60.8614, -51.9753],
              [-60.8642, -51.9687],
              [-60.8568, -51.9671],
              [-60.8577, -51.9586],
              [-60.8462, -51.9611]
            ]
          ],
          [
            [
              [-59.64, -51.9766],
              [-59.6439, -51.985],
              [-59.6537, -51.9876],
              [-59.6541, -51.9828],
              [-59.659, -51.9772],
              [-59.6433, -51.9752],
              [-59.64, -51.9766]
            ]
          ],
          [
            [
              [-58.4355, -51.9895],
              [-58.4236, -51.9994],
              [-58.4208, -52.0084],
              [-58.43, -52.0127],
              [-58.438, -52.0097],
              [-58.4556, -52.0068],
              [-58.459, -52.0037],
              [-58.4726, -52.0041],
              [-58.4723, -52.0107],
              [-58.4576, -52.0227],
              [-58.478, -52.0278],
              [-58.4763, -52.0337],
              [-58.4519, -52.034],
              [-58.459, -52.0412],
              [-58.4555, -52.0497],
              [-58.4596, -52.0589],
              [-58.4506, -52.0608],
              [-58.4435, -52.0594],
              [-58.4444, -52.0692],
              [-58.4539, -52.0728],
              [-58.4524, -52.0834],
              [-58.4485, -52.0881],
              [-58.4362, -52.0875],
              [-58.4377, -52.0935],
              [-58.4468, -52.0911],
              [-58.4495, -52.0971],
              [-58.4692, -52.096],
              [-58.4812, -52.0917],
              [-58.4849, -52.0874],
              [-58.4815, -52.0834],
              [-58.4686, -52.0828],
              [-58.4635, -52.079],
              [-58.4719, -52.0687],
              [-58.4798, -52.0728],
              [-58.4791, -52.0764],
              [-58.4915, -52.076],
              [-58.5001, -52.0652],
              [-58.5198, -52.0622],
              [-58.5273, -52.0582],
              [-58.5454, -52.0514],
              [-58.5344, -52.0455],
              [-58.5412, -52.0314],
              [-58.5544, -52.0285],
              [-58.5701, -52.0211],
              [-58.5668, -52.0127],
              [-58.5521, -52.0148],
              [-58.5524, -52.0085],
              [-58.5425, -52.006],
              [-58.5348, -52.001],
              [-58.5088, -51.9993],
              [-58.5051, -51.9878],
              [-58.5016, -51.9844],
              [-58.4914, -51.9846],
              [-58.4826, -51.989],
              [-58.4669, -51.9887],
              [-58.468, -51.9965],
              [-58.46, -52.0004],
              [-58.4517, -51.9983],
              [-58.446, -52.0018],
              [-58.4366, -51.9985],
              [-58.4355, -51.9895]
            ]
          ],
          [
            [
              [-58.3887, -51.9972],
              [-58.3763, -51.9965],
              [-58.3717, -51.9988],
              [-58.3818, -52.0046],
              [-58.392, -52.0023],
              [-58.407, -51.997],
              [-58.4015, -51.9934],
              [-58.3887, -51.9972]
            ]
          ],
          [
            [
              [-60.6617, -51.6719],
              [-60.6709, -51.6728],
              [-60.6782, -51.6641],
              [-60.6749, -51.6574],
              [-60.673, -51.6389],
              [-60.6752, -51.6339],
              [-60.6723, -51.6265],
              [-60.676, -51.618],
              [-60.684, -51.6122],
              [-60.6874, -51.6035],
              [-60.6762, -51.6044],
              [-60.665, -51.6154],
              [-60.6652, -51.6244],
              [-60.662, -51.635],
              [-60.6542, -51.649],
              [-60.6469, -51.6527],
              [-60.6448, -51.6575],
              [-60.6515, -51.6654],
              [-60.6617, -51.6719]
            ]
          ],
          [
            [
              [-60.3249, -51.6339],
              [-60.3329, -51.638],
              [-60.3474, -51.6353],
              [-60.3492, -51.6305],
              [-60.3419, -51.6259],
              [-60.3287, -51.6262],
              [-60.3249, -51.6339]
            ]
          ],
          [
            [
              [-61.2057, -51.6664],
              [-61.2112, -51.6658],
              [-61.218, -51.6573],
              [-61.2171, -51.6513],
              [-61.2099, -51.6527],
              [-61.2032, -51.6609],
              [-61.2057, -51.6664]
            ]
          ],
          [
            [
              [-61.0569, -52.0011],
              [-61.0654, -52.0063],
              [-61.0694, -52.0029],
              [-61.0659, -51.9984],
              [-61.0569, -52.0011]
            ]
          ],
          [
            [
              [-61.2278, -51.7458],
              [-61.2406, -51.7443],
              [-61.2372, -51.7376],
              [-61.2291, -51.7394],
              [-61.2278, -51.7458]
            ]
          ],
          [
            [
              [-59.6731, -52.0059],
              [-59.6591, -52.0077],
              [-59.6633, -52.0157],
              [-59.6769, -52.016],
              [-59.6823, -52.0095],
              [-59.6731, -52.0059]
            ]
          ],
          [
            [
              [-60.9338, -52.0135],
              [-60.9213, -52.0105],
              [-60.9246, -52.0173],
              [-60.9383, -52.0197],
              [-60.9486, -52.0165],
              [-60.9413, -52.0124],
              [-60.9338, -52.0135]
            ]
          ],
          [
            [
              [-58.3685, -52.0112],
              [-58.3713, -52.0153],
              [-58.3788, -52.0132],
              [-58.3926, -52.0139],
              [-58.398, -52.0077],
              [-58.3803, -52.0079],
              [-58.3685, -52.0112]
            ]
          ],
          [
            [
              [-61.2276, -51.6932],
              [-61.2161, -51.6919],
              [-61.2008, -51.6989],
              [-61.1965, -51.6916],
              [-61.188, -51.6941],
              [-61.1884, -51.7054],
              [-61.193, -51.7077],
              [-61.2051, -51.7053],
              [-61.2312, -51.7063],
              [-61.2168, -51.7179],
              [-61.2247, -51.7202],
              [-61.2452, -51.7057],
              [-61.2519, -51.703],
              [-61.258, -51.7077],
              [-61.2678, -51.7099],
              [-61.27, -51.7135],
              [-61.2561, -51.7233],
              [-61.2646, -51.7271],
              [-61.2729, -51.7332],
              [-61.2736, -51.7388],
              [-61.2789, -51.7408],
              [-61.2772, -51.7458],
              [-61.2699, -51.7446],
              [-61.2511, -51.7482],
              [-61.2525, -51.7537],
              [-61.2604, -51.7576],
              [-61.2535, -51.7628],
              [-61.2637, -51.7668],
              [-61.2747, -51.7655],
              [-61.2927, -51.7491],
              [-61.2893, -51.7432],
              [-61.2858, -51.7291],
              [-61.2894, -51.7242],
              [-61.2936, -51.7123],
              [-61.3026, -51.7053],
              [-61.3038, -51.693],
              [-61.2966, -51.6943],
              [-61.2906, -51.7012],
              [-61.2776, -51.697],
              [-61.2616, -51.698],
              [-61.2449, -51.6957],
              [-61.2429, -51.6928],
              [-61.2469, -51.6828],
              [-61.2399, -51.6796],
              [-61.2323, -51.6827],
              [-61.2276, -51.6932]
            ]
          ],
          [
            [
              [-58.6214, -52.1428],
              [-58.6335, -52.1413],
              [-58.6277, -52.1343],
              [-58.6283, -52.1304],
              [-58.6434, -52.1249],
              [-58.6436, -52.1127],
              [-58.6391, -52.1037],
              [-58.6297, -52.1096],
              [-58.6283, -52.1138],
              [-58.6345, -52.1185],
              [-58.6314, -52.123],
              [-58.618, -52.1249],
              [-58.6118, -52.1312],
              [-58.6118, -52.1353],
              [-58.6214, -52.1428]
            ]
          ],
          [
            [
              [-59.7229, -52.0812],
              [-59.7268, -52.0834],
              [-59.7402, -52.0775],
              [-59.7413, -52.0673],
              [-59.7315, -52.0582],
              [-59.7356, -52.0524],
              [-59.7315, -52.0466],
              [-59.7232, -52.0432],
              [-59.714, -52.0334],
              [-59.7087, -52.0417],
              [-59.7102, -52.0475],
              [-59.7157, -52.053],
              [-59.7134, -52.0634],
              [-59.7182, -52.0682],
              [-59.7229, -52.0812]
            ]
          ],
          [
            [
              [-58.2321, -51.8028],
              [-58.1948, -51.803],
              [-58.1902, -51.8079],
              [-58.2146, -51.8087],
              [-58.2359, -51.8068],
              [-58.2321, -51.8028]
            ]
          ],
          [
            [
              [-61.1734, -51.8039],
              [-61.1655, -51.8043],
              [-61.1666, -51.8106],
              [-61.1784, -51.8055],
              [-61.1784, -51.798],
              [-61.1723, -51.7991],
              [-61.1734, -51.8039]
            ]
          ],
          [
            [
              [-61.1594, -51.8503],
              [-61.1471, -51.8506],
              [-61.1414, -51.8575],
              [-61.1516, -51.8599],
              [-61.157, -51.8642],
              [-61.1761, -51.8669],
              [-61.1755, -51.8611],
              [-61.1667, -51.854],
              [-61.1637, -51.8443],
              [-61.1561, -51.8468],
              [-61.1594, -51.8503]
            ]
          ],
          [
            [
              [-58.5143, -51.9669],
              [-58.5041, -51.9662],
              [-58.4924, -51.9676],
              [-58.4687, -51.9771],
              [-58.4621, -51.9766],
              [-58.4586, -51.9724],
              [-58.4472, -51.9794],
              [-58.4537, -51.9828],
              [-58.4671, -51.9807],
              [-58.4838, -51.9803],
              [-58.4969, -51.9735],
              [-58.5126, -51.9713],
              [-58.5143, -51.9669]
            ]
          ],
          [
            [
              [-59.5835, -51.8501],
              [-59.5885, -51.8618],
              [-59.5977, -51.8668],
              [-59.6072, -51.8685],
              [-59.6082, -51.8596],
              [-59.6168, -51.8609],
              [-59.6134, -51.8532],
              [-59.6037, -51.8502],
              [-59.5992, -51.8526],
              [-59.5835, -51.8501]
            ]
          ],
          [
            [
              [-59.6255, -51.8774],
              [-59.6307, -51.878],
              [-59.633, -51.8693],
              [-59.6272, -51.8677],
              [-59.6228, -51.8726],
              [-59.6255, -51.8774]
            ]
          ],
          [
            [
              [-58.3079, -51.8796],
              [-58.3157, -51.8793],
              [-58.3167, -51.871],
              [-58.3002, -51.8738],
              [-58.2996, -51.878],
              [-58.3079, -51.8796]
            ]
          ],
          [
            [
              [-61.1448, -51.874],
              [-61.1342, -51.877],
              [-61.1432, -51.8826],
              [-61.1435, -51.8868],
              [-61.1626, -51.8994],
              [-61.1723, -51.9091],
              [-61.1814, -51.9112],
              [-61.1871, -51.9059],
              [-61.1781, -51.8989],
              [-61.1709, -51.8875],
              [-61.1642, -51.8852],
              [-61.1602, -51.8762],
              [-61.1448, -51.874]
            ]
          ],
          [
            [
              [-59.5216, -51.7418],
              [-59.5265, -51.738],
              [-59.5176, -51.7309],
              [-59.5095, -51.7221],
              [-59.5012, -51.7253],
              [-59.509, -51.7345],
              [-59.5216, -51.7418]
            ]
          ],
          [
            [
              [-59.66, -51.8849],
              [-59.6702, -51.8917],
              [-59.6767, -51.8829],
              [-59.664, -51.8752],
              [-59.6581, -51.8765],
              [-59.66, -51.8849]
            ]
          ],
          [
            [
              [-58.552, -51.8925],
              [-58.5352, -51.8989],
              [-58.5244, -51.9013],
              [-58.5239, -51.9085],
              [-58.5348, -51.9109],
              [-58.5395, -51.9094],
              [-58.5432, -51.9026],
              [-58.5499, -51.9013],
              [-58.5542, -51.896],
              [-58.552, -51.8925]
            ]
          ],
          [
            [
              [-58.2878, -51.8684],
              [-58.2766, -51.8687],
              [-58.278, -51.8736],
              [-58.2866, -51.8718],
              [-58.2977, -51.8723],
              [-58.3009, -51.8696],
              [-58.297, -51.8646],
              [-58.2878, -51.8684]
            ]
          ],
          [
            [
              [-61.136, -51.9009],
              [-61.1257, -51.8975],
              [-61.1189, -51.898],
              [-61.1291, -51.9083],
              [-61.1388, -51.9072],
              [-61.1504, -51.914],
              [-61.1613, -51.9116],
              [-61.1627, -51.9049],
              [-61.1529, -51.8976],
              [-61.1372, -51.8912],
              [-61.1347, -51.8931],
              [-61.136, -51.9009]
            ]
          ],
          [
            [
              [-59.6451, -51.9046],
              [-59.6313, -51.9144],
              [-59.6322, -51.9195],
              [-59.6389, -51.9205],
              [-59.6524, -51.9136],
              [-59.6451, -51.9046]
            ]
          ],
          [
            [
              [-58.719, -52.1178],
              [-58.7176, -52.1234],
              [-58.7217, -52.1291],
              [-58.7337, -52.1309],
              [-58.7379, -52.1241],
              [-58.748, -52.1224],
              [-58.748, -52.1187],
              [-58.7386, -52.1172],
              [-58.719, -52.1178]
            ]
          ],
          [
            [
              [-58.8389, -52.1484],
              [-58.852, -52.1537],
              [-58.8557, -52.1596],
              [-58.8553, -52.1667],
              [-58.8578, -52.1739],
              [-58.852, -52.1807],
              [-58.8459, -52.1812],
              [-58.8377, -52.1907],
              [-58.8458, -52.1938],
              [-58.8455, -52.1993],
              [-58.8606, -52.1999],
              [-58.869, -52.2093],
              [-58.8777, -52.2155],
              [-58.8911, -52.2134],
              [-58.8992, -52.2207],
              [-58.9126, -52.2203],
              [-58.9257, -52.2252],
              [-58.9324, -52.2247],
              [-58.9485, -52.2314],
              [-58.9572, -52.2274],
              [-58.962, -52.2305],
              [-58.9734, -52.2319],
              [-58.9927, -52.2287],
              [-59.0043, -52.2333],
              [-59.0127, -52.2347],
              [-59.0147, -52.2296],
              [-59, -52.2228],
              [-59.0053, -52.2143],
              [-59.0006, -52.211],
              [-58.9834, -52.214],
              [-58.9801, -52.2238],
              [-58.9728, -52.2243],
              [-58.9601, -52.2176],
              [-58.9482, -52.2223],
              [-58.9359, -52.2153],
              [-58.925, -52.2028],
              [-58.9206, -52.2063],
              [-58.9112, -52.2037],
              [-58.9048, -52.2048],
              [-58.8968, -52.2103],
              [-58.8935, -52.2046],
              [-58.8873, -52.2005],
              [-58.8752, -52.2018],
              [-58.8663, -52.2006],
              [-58.8658, -52.1953],
              [-58.8708, -52.1909],
              [-58.8666, -52.1861],
              [-58.8663, -52.1736],
              [-58.8745, -52.1674],
              [-58.8666, -52.1649],
              [-58.8617, -52.1582],
              [-58.8633, -52.1515],
              [-58.8542, -52.1491],
              [-58.8551, -52.1406],
              [-58.8529, -52.1369],
              [-58.8403, -52.1293],
              [-58.8398, -52.1359],
              [-58.8358, -52.1399],
              [-58.8389, -52.1484]
            ]
          ],
          [
            [
              [-59.0161, -52.139],
              [-59.0239, -52.1349],
              [-59.0155, -52.1277],
              [-58.9962, -52.1242],
              [-58.9976, -52.1295],
              [-59.0161, -52.139]
            ]
          ],
          [
            [
              [-59.7658, -52.1394],
              [-59.7645, -52.1507],
              [-59.7593, -52.1608],
              [-59.7489, -52.1602],
              [-59.7383, -52.1558],
              [-59.7331, -52.1584],
              [-59.7227, -52.1538],
              [-59.7178, -52.1583],
              [-59.7074, -52.1564],
              [-59.7011, -52.1578],
              [-59.6915, -52.1546],
              [-59.6797, -52.1654],
              [-59.6776, -52.1791],
              [-59.6818, -52.1889],
              [-59.6876, -52.1937],
              [-59.6869, -52.2045],
              [-59.6746, -52.2028],
              [-59.6657, -52.2068],
              [-59.6663, -52.2112],
              [-59.6808, -52.2153],
              [-59.6755, -52.2275],
              [-59.6771, -52.2329],
              [-59.6889, -52.2361],
              [-59.6969, -52.2426],
              [-59.7037, -52.2435],
              [-59.7073, -52.2495],
              [-59.7162, -52.2475],
              [-59.7242, -52.2496],
              [-59.7284, -52.2579],
              [-59.7225, -52.2594],
              [-59.7275, -52.2656],
              [-59.726, -52.2748],
              [-59.7278, -52.2778],
              [-59.7412, -52.2805],
              [-59.7458, -52.2717],
              [-59.7451, -52.2635],
              [-59.7548, -52.2549],
              [-59.7482, -52.2458],
              [-59.7528, -52.2382],
              [-59.7504, -52.2342],
              [-59.7402, -52.2379],
              [-59.7327, -52.2333],
              [-59.7324, -52.2285],
              [-59.7484, -52.214],
              [-59.7384, -52.2122],
              [-59.732, -52.209],
              [-59.7379, -52.2038],
              [-59.7284, -52.1962],
              [-59.7397, -52.1817],
              [-59.7372, -52.1758],
              [-59.7451, -52.1748],
              [-59.7457, -52.1675],
              [-59.7531, -52.1666],
              [-59.761, -52.1713],
              [-59.7778, -52.1736],
              [-59.7839, -52.1658],
              [-59.7857, -52.1585],
              [-59.7983, -52.1583],
              [-59.7986, -52.1533],
              [-59.7884, -52.1481],
              [-59.7881, -52.1415],
              [-59.7762, -52.1379],
              [-59.7773, -52.1283],
              [-59.7679, -52.1266],
              [-59.7688, -52.1365],
              [-59.7658, -52.1394]
            ]
          ],
          [
            [
              [-60.7342, -52.1388],
              [-60.744, -52.1392],
              [-60.75, -52.1329],
              [-60.7418, -52.1274],
              [-60.7326, -52.1345],
              [-60.7342, -52.1388]
            ]
          ],
          [
            [
              [-60.8765, -52.1638],
              [-60.8935, -52.166],
              [-60.9035, -52.1657],
              [-60.9059, -52.1617],
              [-60.8862, -52.1564],
              [-60.8843, -52.1607],
              [-60.8765, -52.1638]
            ]
          ],
          [
            [
              [-60.6629, -52.1817],
              [-60.6771, -52.1804],
              [-60.6745, -52.1757],
              [-60.6624, -52.1773],
              [-60.6629, -52.1817]
            ]
          ],
          [
            [
              [-60.73, -52.1819],
              [-60.7224, -52.1815],
              [-60.7126, -52.1865],
              [-60.7169, -52.1923],
              [-60.723, -52.1917],
              [-60.7259, -52.1856],
              [-60.7457, -52.1854],
              [-60.7456, -52.1819],
              [-60.73, -52.1819]
            ]
          ],
          [
            [
              [-60.4025, -52.1988],
              [-60.4099, -52.2056],
              [-60.4205, -52.2067],
              [-60.4283, -52.2116],
              [-60.433, -52.2097],
              [-60.4378, -52.1964],
              [-60.4296, -52.1961],
              [-60.4177, -52.1993],
              [-60.4125, -52.1982],
              [-60.3969, -52.1892],
              [-60.3907, -52.1901],
              [-60.4025, -52.1988]
            ]
          ],
          [
            [
              [-60.4435, -52.2148],
              [-60.4479, -52.2202],
              [-60.4622, -52.2208],
              [-60.4729, -52.2238],
              [-60.4752, -52.2212],
              [-60.4683, -52.2166],
              [-60.4559, -52.2144],
              [-60.4435, -52.2148]
            ]
          ],
          [
            [
              [-59.6794, -52.2918],
              [-59.6814, -52.3001],
              [-59.6893, -52.3019],
              [-59.7047, -52.3003],
              [-59.7126, -52.3055],
              [-59.7123, -52.3177],
              [-59.7243, -52.3205],
              [-59.7287, -52.329],
              [-59.7427, -52.3322],
              [-59.7442, -52.3352],
              [-59.7389, -52.3407],
              [-59.7287, -52.3455],
              [-59.7334, -52.3545],
              [-59.7516, -52.3676],
              [-59.7626, -52.3674],
              [-59.7753, -52.3631],
              [-59.7796, -52.357],
              [-59.7775, -52.3487],
              [-59.7858, -52.3496],
              [-59.7918, -52.3474],
              [-59.8026, -52.3477],
              [-59.8074, -52.3435],
              [-59.8026, -52.3392],
              [-59.7854, -52.3292],
              [-59.7738, -52.3247],
              [-59.7689, -52.3282],
              [-59.7589, -52.3307],
              [-59.7528, -52.3239],
              [-59.7562, -52.3117],
              [-59.7543, -52.2994],
              [-59.7435, -52.2933],
              [-59.7335, -52.2918],
              [-59.731, -52.3015],
              [-59.7187, -52.2951],
              [-59.6936, -52.2888],
              [-59.6859, -52.292],
              [-59.6743, -52.2853],
              [-59.6716, -52.2886],
              [-59.6794, -52.2918]
            ]
          ],
          [
            [
              [-59.6577, -52.3416],
              [-59.6557, -52.3468],
              [-59.6424, -52.3509],
              [-59.6439, -52.3564],
              [-59.66, -52.3573],
              [-59.6631, -52.3599],
              [-59.677, -52.3606],
              [-59.6857, -52.3669],
              [-59.6821, -52.3713],
              [-59.6883, -52.3791],
              [-59.6997, -52.3801],
              [-59.7081, -52.3775],
              [-59.7182, -52.3791],
              [-59.7267, -52.3756],
              [-59.7355, -52.3671],
              [-59.7271, -52.3635],
              [-59.7242, -52.3585],
              [-59.7125, -52.3575],
              [-59.712, -52.3545],
              [-59.6894, -52.3529],
              [-59.6968, -52.3609],
              [-59.6833, -52.3589],
              [-59.6781, -52.3528],
              [-59.6614, -52.3484],
              [-59.6577, -52.3416]
            ]
          ],
          [
            [
              [-59.0739, -52.4075],
              [-59.0714, -52.4147],
              [-59.0973, -52.4208],
              [-59.1004, -52.4251],
              [-59.1118, -52.4232],
              [-59.1191, -52.4305],
              [-59.1338, -52.4334],
              [-59.1442, -52.4277],
              [-59.1537, -52.4269],
              [-59.1602, -52.4207],
              [-59.1386, -52.4119],
              [-59.1343, -52.4135],
              [-59.1217, -52.4128],
              [-59.1147, -52.407],
              [-59.1006, -52.408],
              [-59.0889, -52.4068],
              [-59.0857, -52.4045],
              [-59.0739, -52.4075]
            ]
          ],
          [
            [
              [-59.0473, -52.4187],
              [-59.0646, -52.4145],
              [-59.0591, -52.4098],
              [-59.0487, -52.4139],
              [-59.0473, -52.4187]
            ]
          ],
          [
            [
              [-58.9122, -52.4357],
              [-58.9297, -52.4354],
              [-58.9222, -52.4302],
              [-58.9045, -52.4247],
              [-58.9039, -52.4272],
              [-58.9122, -52.4357]
            ]
          ]
        ]
      },
      properties: {
        name: 'Falkland Islands / Islas Malvinas',
        id: 'FK',
        CNTRY: 'Falkland Islands / Islas Malvinas',
        TYPE: 'Falkland Islands / Islas Malvinas'
      },
      id: 'FK'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.1034, -54.0658],
            [-36.7039, -54.108],
            [-36.4486, -54.3082],
            [-36.3264, -54.2509],
            [-36.0731, -54.5541],
            [-35.7986, -54.7636],
            [-36.0855, -54.8666],
            [-36.2518, -54.7801],
            [-36.5066, -54.5111],
            [-36.735, -54.4667],
            [-37.0068, -54.3411],
            [-37.6891, -54.0769],
            [-37.3821, -53.9842],
            [-37.1034, -54.0658]
          ]
        ]
      },
      properties: {
        name: 'South Georgia and South Sandwich Islands',
        id: 'GS'
      },
      id: 'GS'
    }
  ]
}
export default map
