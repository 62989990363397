/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import MainLayout from '../layouts/MainLayout'

import { Tab, Tabs } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import useGqlJurisdictionsDyn from '../gql/useGqlJurisdictions'
import JurMap from './components/Map/JurMap'
import './JurProfile.css'
import JurProfileArea1 from './JurProfileAreas/JurProfileArea1'

import axios from 'axios'
import Select, { components } from 'react-select'
import seo from '../config/seo.json'
import { history } from '../App.js'
import { getPath, getPathLang } from '../config/routes'
import BackToTopBtn from './components/JurProfile/BackToTopBtn'
import DropdownIndicator from './components/JurProfile/DropdownIndicator'
import NewsItems from './components/JurProfile/NewsItems'
import Timeline from './components/JurProfile/Timeline'
import ReportErrorModal from './components/Table/ReportErrorModal'

function useForceUpdate() {
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(0) // integer state
  return () => setValue(value => value + 1) // update the state to force render
}

export default function SubjurProfile() {
  // eslint-disable-next-line no-unused-vars
  const [changeJur, setChangeJur] = useState(true)
  const [currentJur, setCurrentJur] = useState(null)

  const [latestNews, setLatestNews] = useState([])

  const [reportErrorOpen, setReportErrorOpen] = useState(false)

  const { t, i18n } = useTranslation()
  /* IMPORTANT, USING STATIC GQL JURS AS AREA5 KEEPS RELOADING IF NOT*/

  const forceUpdate = useForceUpdate()

  let { slug } = useParams()

  const {
    jurisdictions,
    loading,
    error,
    subjurFromSlug
  } = useGqlJurisdictionsDyn(null, slug)

  useEffect(() => {
    if (
      !error &&
      jurisdictions &&
      jurisdictions.length &&
      changeJur &&
      subjurFromSlug
    ) {
      let jur = jurisdictions.find(j => j.id === subjurFromSlug.jurisdiction.id)

      console.log(jur)

      if (jur) {
        if (jur.a2_code === 'ID') {
          window.location = '/'
        } else {
          setChangeJur(false)
          setCurrentJur(jur)
        }
      }
    }
    console.log(subjurFromSlug)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jurisdictions, error, loading, changeJur, subjurFromSlug])

  useEffect(() => {
    if (currentJur) {
      setLatestNews([])
      axios
        .get(
          'https://monitor.ilga.org/api/articles/getDbLatestFromJur/' +
            currentJur.id
        )
        .then(res => {
          if (res.data.msg && res.data.msg.length) setLatestNews(res.data.msg)
          else setLatestNews(false)
        })
        .catch(e => {
          console.error('Network error fetchin latest news', e)
        })
    }
  }, [currentJur])

  useEffect(() => {
    if (
      subjurFromSlug &&
      getPathLang(window.location.pathname.slice(1)) !== i18n.language
    ) {
      history.push(
        getPath('subjur_profile').replace(
          ':slug',
          subjurFromSlug['slug_' + i18n.language]
        )
      )
    }
  }, [i18n.language])

  return (
    <MainLayout
      section="jurs"
      metaTitle={
        subjurFromSlug
          ? seo['subjur_profile']['title_' + i18n.language].replace(
              ':slug',
              subjurFromSlug.name
            )
          : null
      }
      metaDescription={
        subjurFromSlug
          ? seo['subjur_profile']['description_' + i18n.language].replace(
              ':slug',
              subjurFromSlug.name
            )
          : null
      }
    >
      <div className="jur__page jur-profile__page">
        {currentJur && !loading && (
          <>
            <section className="jurHero__section">
              <div className="content__container">
                <div className="jurPage__searchBar">
                  <Select
                    components={{
                      ...components,
                      DropdownIndicator
                    }}
                    className="selectJur__select reactSelect"
                    defaultValue={{
                      value: 0,
                      label: t('Search by jurisdiction...')
                    }}
                    maxMenuHeight="500px"
                    options={
                      jurisdictions
                        ? jurisdictions
                            .filter(j => j.a2_code !== 'G0')
                            .map(j => ({
                              value: j.id,
                              label: j.name,
                              a2_code: j.a2_code,
                              slug: j.slug
                            }))
                        : [
                            {
                              value: 0,
                              label: 'Loading...'
                            }
                          ]
                    }
                    placeholder="Change jurisdiction..."
                    onChange={e => {
                      history.push(
                        getPath('jur_profile').replace(
                          ':slug',
                          e.slug.toLowerCase()
                        )
                      )
                      setCurrentJur(jurisdictions.find(j => j.id === e.value))
                      forceUpdate()
                    }}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 })
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    components={{
                      Option: props => (
                        <components.Option {...props}>
                          {props.data.a2_code && (
                            <img
                              src={
                                '/images/flags_svg/' +
                                props.data.a2_code.toLowerCase() +
                                '.svg'
                              }
                              style={{
                                width: 16,
                                verticalAlign: 'baseline'
                              }}
                              alt={props.data.label}
                            />
                          )}{' '}
                          {props.data.label}
                        </components.Option>
                      )
                    }}
                  />
                </div>
                <div className="jh__countryInfo-container">
                  <div className="jh__flagContainer">
                    <img
                      className="jh__countryFlag"
                      src={
                        '/images/flags_svg/' +
                        currentJur.a2_code.toLowerCase() +
                        '.svg'
                      }
                      alt="Country Flag"
                    />
                  </div>
                  {subjurFromSlug && (
                    <div className="jh__countryInfo">
                      {subjurFromSlug.name} ({currentJur.name})
                    </div>
                  )}
                </div>
                <div className="jh__countryData">
                  {subjurFromSlug && (
                    <JurMap jur={currentJur} subjur={subjurFromSlug} />
                  )}

                  <div className="jh__countryNewsContainer jh__countryNewsContainerNonUn">
                    <Tabs defaultActiveKey="LatestNews" id="uncontrolled-tab">
                      <Tab
                        className="jh__tab"
                        eventKey="LatestNews"
                        title={t('Latest news') + ' (' + currentJur.name + ')'}
                      >
                        <NewsItems news={latestNews} />
                      </Tab>
                    </Tabs>
                  </div>
                  {subjurFromSlug && (
                    <Timeline currentSubjur={subjurFromSlug} />
                  )}
                </div>
              </div>
            </section>
            <section className="samePageLinks__section">
              <div className="content__container">
                <div className="spl__container" />
              </div>
            </section>
            {subjurFromSlug && (
              <JurProfileArea1
                jur={currentJur}
                setReportErrorOpen={entry => setReportErrorOpen(entry)}
                subjurSlug={slug}
              />
            )}

            <ReportErrorModal
              isOpen={reportErrorOpen}
              setIsOpen={setReportErrorOpen}
            />

            <BackToTopBtn />
          </>
        )}
      </div>
    </MainLayout>
  )
}
