import axios from 'axios'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { history } from '../../App.js'
import FadeInSection from '../../components/FadeInSection.js'
import { cGradientsAreasProtection } from '../../config/constants'
import { getPath } from '../../config/routes'
import useGqlEntriesProtection from '../../gql/useGqlEntriesProtection'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import useGqlUpcomingDeadlines from '../../gql/useGqlUpcomingDeadlines'
import MainLayout from '../../layouts/MainLayout'
import { calculateProtectionValue } from '../../lib/fnAreasPRHelper'
import { i18ndayjs } from '../../lib/fnDates'
import {
  getColorPercentageStep,
  getDateMysqlFormat,
  getNumberOfDays,
  getNumberOfMonths
} from '../../lib/fnHelper'
import LatestUpdatesHome from '../components/Areas/LatestUpdatesHome.js'
import { AutoScroller } from '../components/AutoScroller'
import HomePhotoCredits from '../components/HomePhotoCredits.js'
import BookIcon from '../components/icons/BookIcon'
import BubblesIcon from '../components/icons/BubblesIcon'
import MagGlass from '../components/icons/MagGlass'
import MapIcon from '../components/icons/MapIcon'
import ToolsIcon from '../components/icons/ToolsIcon'
import TreatiesIcon from '../components/icons/TreatiesIcon'
import TreatyBodiesIcon from '../components/icons/TreatyBodiesIcon'
import UPRIcon from '../components/icons/UPRIcon'
import VotingIcon from '../components/icons/VotingIcon'
import Map from '../components/Map/Map.js'
import microstates from '../components/Map/microstates.json'
import StyledMapTooltips from '../components/StyledMapToolTips'
import MapTooltip from '../components/StyledMapToolTips'
import DatabaseLogo from '../components/ui/DatabaseLogo'
import IlgaLogo from '../components/ui/IlgaLogo'
import VideoSlider from '../components/VideoSlider/VideoSlider'
import './Home.css'

export default function Home({
  verifyAccount = false,
  resetPasswordLink = true
}) {
  let { code } = useParams()
  const [verificationCode, setVerificationCode] = useState()

  const [resetPasswordCode, setResetPasswordCode] = useState()

  const htmlReferences = useRef()
  const chart = useRef()

  const { t, i18n } = useTranslation()
  const inputRef = useRef(null)
  let {
    jurisdictions,
    jurisdictionsById,
    loading,
    error,
    regions
  } = useGqlJurisdictions()
  const [active, setActive] = useState('jurisdictions')
  const { upcomingDeadlines } = useGqlUpcomingDeadlines()
  const [mapReferences, setMapReferences] = useState([])
  const [references, setReferences] = useState([])
  const [showModal, setShowModal] = useState(false)
  const {
    entriesProtection,
    loading: loadingProtections,
    error: errorProtections
  } = useGqlEntriesProtection('A1-10')
  const [latestNews, setLatestNews] = useState(null)

  // TODO: rollback before commit
  const [listItems, setListItems] = useState({
    // jurisdictions: jurisdictions.map(j => ({
    //   id: j.id,
    //   icon: (
    //     <img
    //       src={"/images/flags_svg/" + j.a2_code.toLowerCase() + ".svg"}
    //       height="12px"
    //       alt={j.name + " flag"}
    //       className={"svgFlag"}
    //     />
    //   ),
    //   value: j.name
    // }))
  })

  useEffect(() => {
    if (verifyAccount && code) {
      if (code.slice(-2) === 'es') i18n.changeLanguage('es')
      setVerificationCode(code)
    }
  }, [verifyAccount])

  useEffect(() => {
    if (resetPasswordLink && code) {
      if (code.slice(-2) === 'es') i18n.changeLanguage('es')
      setResetPasswordCode(code)
    }
  }, [resetPasswordLink])

  useEffect(() => {
    let getNews = async () => {
      let news = await axios.get(
        'https://monitor.ilga.org/api/articles/latest_mou_general'
      )
      if (news && news.data) setLatestNews(news.data)
    }
    getNews()
  }, [])

  useEffect(() => {
    console.log(latestNews)
  }, [latestNews])

  useEffect(() => {
    if (jurisdictions && jurisdictions.length) {
      setListItems({
        jurisdictions: jurisdictions.map((j, i) => ({
          id: j.id,
          icon: (
            <img
              key={i + 'img'}
              src={'/images/flags_svg/' + j.a2_code.toLowerCase() + '.svg'}
              height="18px"
              alt={j.name + ' flag'}
              className={'svgFlag'}
            />
          ),
          value: j.name,
          link: getPath('jur_profile').replace(':slug', j.slug.toLowerCase())
        }))
      })
    }
  }, [jurisdictions])

  useEffect(() => {
    if (!loading && !error && jurisdictions && entriesProtection) {
      setMapReferences(
        entriesProtection.map(e => {
          let val = calculateProtectionValue(e, entriesProtection, [])

          return {
            id:
              e.motherEntry.subjurisdiction && e.motherEntry.subjurisdiction.id
                ? e.motherEntry.subjurisdiction.id
                : e.motherEntry.jurisdiction.a2_code,
            regions: e.motherEntry.jurisdiction.regions,
            microstate: null,
            reference: getColorPercentageStep(
              cGradientsAreasProtection.map(c =>
                c === '#ffffff' || c === '#FFF' ? '81b3b3' : c
              ),
              val,
              'A1-PR',
              cGradientsAreasProtection.map(c =>
                c === '#ffffff' || c === '#FFF' ? '81b3b3' : c
              )
            ),
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <MapTooltip
                entry={e}
                title={t('Protection against discrimination in employment')}
                area={'A1-5'}
                extraProps={{ categories: [] }}
              />
            ), //tooltip(e),
            entry: e
          }
        })
      )
    }
  }, [jurisdictions, entriesProtection, error, loading])
  useEffect(() => {
    if (!error && jurisdictions && jurisdictions.length) {
      setReferences(
        jurisdictions.map(j => ({
          id: j.a2_code,
          regions: j.regions,
          microstate: microstates.find(ms => ms.A2 === j.a2_code),
          reference: j.un_member ? '#a0aa9f' : '#fff',
          link: getPath('jur_profile').replace(':slug', j.slug.toLowerCase()),
          tooltip: ReactDOMServer.renderToStaticMarkup(
            <StyledMapTooltips
              entry={{ motherEntry: { jurisdiction: j } }}
              area={'jur-home'}
            />
          ), //tooltip(e),
          entry: j
        }))
      )
    }
  }, [jurisdictions, error, loading])
  const [mCContainerHeight, setMCContainerHeight] = useState(470)

  const updateContainerHeight = height => {
    setMCContainerHeight(height)
  }

  return (
    <MainLayout
      section="areas section__homePage"
      verificationCode={verificationCode}
      setVerificationCode={setVerificationCode}
      verifyAccount={verifyAccount}
      resetPasswordCode={resetPasswordCode}
      setResetPasswordCode={setResetPasswordCode}
    >
      {!verifyAccount && !resetPasswordCode && (
        <div className="mainContent page__home">
          <HomePhotoCredits showModal={showModal} setShowModal={setShowModal} />
          <section className="hero__section">
            <div className="heroBg__underlay" />
            <div className="container">
              <div className="hero__whiteBox">
                <div className="comboLogoContainer">
                  <IlgaLogo /> <DatabaseLogo />
                </div>
                <p>
                  {t(
                    'A unique knowledge base on laws, human rights bodies, advocacy opportunities, and news related to sexual orientation, gender identity and expression, and sex characteristics issues worldwide.'
                  )}
                </p>
              </div>
            </div>
            <div className="hero__credits" onClick={() => setShowModal(true)}>
              {t('Photo Credits')}
            </div>
          </section>
          <div className="rainbow__bar">
            <span key={'1bar'} />
            <span key={'2bar'} />
            <span key={'3bar'} />
            <span key={'4bar'} />
            <span key={'5bar'} />
            <span key={'6bar'} />
            <span key={'7bar'} />
            <span key={'8bar'} />
            <span key={'9bar'} />
            <span key={'10bar'} />
            <span key={'11bar'} />
            <span key={'12bar'} />
            <span key={'13bar'} />
            <span key={'14bar'} />
            <span key={'15bar'} />
            <span key={'1b6ar'} />
            <span key={'17bar'} />
            <span key={'18bar'} />
          </div>
          <div className="content__container">
            <FadeInSection>
              <div className="leadSection__container">
                <VideoSlider />
                <div className="homepage__updates-container">
                  <h2>{t('Latest Updates')}</h2>
                  <p>
                    {t(
                      'This section lists the latest updates brought into the database in all Areas. The list of updates will progressively grow after the database is launched in 2023.'
                    )}
                  </p>
                  <LatestUpdatesHome />
                </div>
              </div>
            </FadeInSection>
            <FadeInSection>
              <section className="homepage__Areas">
                <div className="homepage__contentBox hpcb__areas">
                  <h2>{t('Areas')}</h2>
                  <div className="homepage__areasSidebar">
                    <p>
                      {t(
                        'Our six Areas give you access to our data according to the field of your preference. Laws and regulations at the national or subnational level, International Treaties, UN Human Rights Mechanisms, and UN Voting Records all made easily accessible, through interactive maps, graphs and charts.'
                      )}
                    </p>
                  </div>
                  <div className="hpcb__areasContainer">
                    <div
                      className="hpcb__areasBox"
                      onClick={() => history.push(getPath('a11'))}
                    >
                      <p className="hpcb__areaSubTitle">{t('Area')} 1</p>
                      <p className="hpcb__areaTitle">{t('Legal Frameworks')}</p>
                      <BookIcon size={42} color="#C9D8D9" />
                    </div>
                    <div
                      className="hpcb__areasBox"
                      onClick={() => history.push(getPath('a2'))}
                    >
                      <p className="hpcb__areaSubTitle">{t('Area')} 2</p>
                      <p className="hpcb__areaTitle">
                        {t('International Human Rights Treaties')}
                      </p>
                      <TreatiesIcon color="#C9D8D9" />
                    </div>
                    <div
                      className="hpcb__areasBox"
                      onClick={() => history.push(getPath('a31'))}
                    >
                      <p className="hpcb__areaSubTitle">{t('Area')} 3</p>
                      <p className="hpcb__areaTitle">{t('UN Treaty Bodies')}</p>
                      <TreatyBodiesIcon color="#C9D8D9" />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        gap:"10px",
                        width: '100%',
                        padding: '16px'
                      }}
                    >
                      <div
                        className="hpcb__areasBox"
                        onClick={() => {
                          history.push(getPath('a4'))
                        }}
                      >
                        <p className="hpcb__areaSubTitle">{t('Area')} 4</p>
                        <p className="hpcb__areaTitle">
                          {t('UN Special Procedures')}
                        </p>
                        <BubblesIcon color="#C9D8D9" />
                      </div>
                      <div
                        className="hpcb__areasBox"
                        onClick={() => history.push(getPath('a5'))}
                      >
                        <p className="hpcb__areaSubTitle">{t('Area')} 5</p>
                        <p className="hpcb__areaTitle">
                          {t('Universal Periodic Review (UPR)')}
                        </p>
                        <UPRIcon color="#C9D8D9" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </FadeInSection>
            <FadeInSection>
              <section className="homepage__Jurisdictions">
                <div className="homeMap">
                  <Map
                    homeMap
                    pacificCenter={false}
                    showLabels={false}
                    highRes={false}
                    hideMicrostates={false}
                    references={references}
                    htmlReferences={htmlReferences}
                    jurisdictions={jurisdictionsById}
                    drillDown={'0'}
                    loadingData={
                      !(jurisdictions && references && references.length > 0)
                    }
                    ref={chart}
                    setMenuOpen={() => {}}
                    extraProps={{ links: true }}
                    hideZoom
                  />
                </div>
                <div className="homepage__Jurisdictions-sidebar">
                  <h2>Jurisdictions</h2>
                  <p>
                    {t(
                      'If you are interested in knowing all about one specific country, you can access our data  in a cross-cutting way, by jurisdiction. Each of the 193 UN Member States and 47 non-independent territories have their own profile with all the information that is relevant to each of them from across Areas. This also includes advocacy opportunities and a selection of the latest SOGIESC-related news coming from the ILGA World Monitor.'
                    )}
                  </p>
                </div>
              </section>
            </FadeInSection>
            <FadeInSection>
              <section className="homepage__Areas homepage__Tools">
                <div className="homepage__contentBox hpcb__areas hpcb__tools">
                  <h2>{t('Tools')}</h2>
                  <div className="homepage__areasSidebar">
                    <p>
                      {t(
                        'Our tools will allow you to interact with our data in different ways, either by searching, comparing or mapping specific countries of your preference.'
                      )}
                    </p>
                  </div>
                  <div className="hpcb__toolsContainer">
                    <div
                      className="hpcb__toolBox"
                      onClick={() => {
                        history.push(getPath('advanced_search'))
                      }}
                    >
                      <p className="hpcb__toolTitle">{t('Advanced Search')}</p>
                      <MagGlass size={66} color="#D8D5E3" />
                      <p>
                        {t(
                          'If you are interested in searching across the whole database by topic, legal category, date, type of document or any other criterion, the Advanced Search will quickly retrieve the content of your interest.'
                        )}
                      </p>
                    </div>
                    <div
                      className="hpcb__toolBox"
                      onClick={() => {
                        history.push(getPath('compare_tool'))
                      }}
                    >
                      <p className="hpcb__toolTitle">{t('Compare Tool')}</p>
                      <ToolsIcon size={66} color="#D8D5E3" />
                      <p>
                        {t(
                          'If you are interested in comparing two or more countries on how they are faring in any given legal category under Area 1, the compare tool will bring that specific data side-by-side for you.'
                        )}
                      </p>
                    </div>

                    <div
                      className="hpcb__toolBox"
                      onClick={() => {
                        //history.push(getPath('map_generator'))
                      }}
                    >
                      <p className="hpcb__toolTitle">{t('Map Generator')}</p>

                      <MapIcon size={66} color="rgb(201, 216, 217)" />
                      <br />
                      <em
                        style={{
                          marginTop: '0px',
                          fontWeight: 'normal',
                          fontSize: '14px'
                        }}
                      >
                        ({t('forthcoming')})
                      </em>
                      <p className="forthcoming">
                        {t(
                          'If you are interested in creating your own ILGA maps for your advocacy, research or dissemination work, the Map Generator will allow you to produce maps specifically tailored to your needs.'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </FadeInSection>

            <FadeInSection>
              <section className="homepage__Areas homepage__Tools">
                <div className="homepage__contentBox hpcb__areas hpcb__deadlines ">
                  <h2>{t('Upcoming Deadlines')}</h2>
                  <div className="homepage__deadlines-container">
                    <div className="homepage__areasSidebar">
                      <p>
                        <p>
                          {t(
                            'Our database tracks deadlines for the submission of shadow reports and other calls for contributions for the benefit of activists who wish to engage with any of the UN mechanisms. A countdown tracker will tell you how much time is left for submissions to Treaty Bodies, Special Procedures and the Universal Periodic Review.'
                          )}
                        </p>{' '}
                      </p>
                    </div>
                    <div className="homepage__deadlines hpcb__toolsContainer">
                      <div className="hd__container">
                        {upcomingDeadlines && (
                          <>
                            {upcomingDeadlines
                              .filter(e => {
                                return getDateMysqlFormat(new Date()) <= e.date
                              })
                              .sort((a, b) =>
                                a.date < b.date ? -1 : a.date > b.date ? 1 : 0
                              )
                              .slice(0, 50)
                              .map(ud => {
                                let difDays = getNumberOfDays(
                                  getDateMysqlFormat(new Date()),
                                  ud.date
                                )

                                const bgColorClass =
                                  difDays < 10
                                    ? 'upcomingFontRed'
                                    : difDays < 30
                                    ? 'upcomingFontGreen'
                                    : 'upcomingFontBlue'

                                if (difDays <= 31)
                                  difDays =
                                    difDays +
                                    ' ' +
                                    (difDays === 1
                                      ? i18n.t('day')
                                      : i18n.t('days'))
                                else if (difDays <= 365) {
                                  difDays = getNumberOfMonths(
                                    getDateMysqlFormat(new Date()),
                                    ud.date
                                  )
                                  difDays =
                                    difDays.months +
                                    ' ' +
                                    (difDays.months === 1
                                      ? i18n.t('month')
                                      : i18n.t('months')) +
                                    (difDays.days
                                      ? ' ' +
                                        i18n.t('and') +
                                        ' ' +
                                        difDays.days +
                                        ' ' +
                                        (difDays.days === 1
                                          ? i18n.t('day')
                                          : i18n.t('days'))
                                      : '')
                                } else {
                                  difDays = getNumberOfMonths(
                                    getDateMysqlFormat(new Date()),
                                    ud.date
                                  )
                                  difDays =
                                    difDays.years +
                                    ' ' +
                                    (difDays.years === 1
                                      ? i18n.t('year')
                                      : i18n.t('years')) +
                                    (difDays.months
                                      ? (difDays.days
                                          ? ','
                                          : ' ' + i18n.t('and')) +
                                        ' ' +
                                        difDays.months +
                                        ' ' +
                                        (difDays.months === 1
                                          ? i18n.t('month')
                                          : i18n.t('months'))
                                      : '') +
                                    (difDays.days
                                      ? ' ' +
                                        i18n.t('and') +
                                        ' ' +
                                        difDays.days +
                                        ' ' +
                                        (difDays.days === 1
                                          ? i18n.t('day')
                                          : i18n.t('days'))
                                      : '')
                                }
                                return (
                                  <div className="hd__item">
                                    <p className="hdi__topBar">
                                      Deadline:{' '}
                                      {i18ndayjs(ud.date, 'DD MMM YYYY')} |{' '}
                                      <span className={bgColorClass}>
                                        {t('{{days}} remaining', {
                                          days: difDays
                                        })}
                                      </span>
                                    </p>
                                    <h4 className="hdi__title">
                                      {ud.un_mechanism.name} -{' '}
                                      {ud.jurisdiction.name}
                                    </h4>
                                    <p className="hdi__content">{ud.text}</p>
                                    {ud.link1 && (
                                      <span className="hdi__footer">
                                        {[1, 2, 3, 4]
                                          .filter(
                                            i =>
                                              ud['link' + i] &&
                                              ud['link' + i].trim()
                                          )
                                          .map(i => (
                                            <a
                                              href={ud['link' + i]}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {ud['link' + i + '_text']}
                                            </a>
                                          ))}
                                      </span>
                                    )}

                                  </div>
                                )
                              })}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </FadeInSection>
          </div>

          <FadeInSection>
            <div className="content__container">
              <section className="homepage__Areas homepage__Monitor">
                <div className="homepage__contentBox hpcb__areas hpcb__monitor">
                  <div className="ilgaMonitor__logo-container">
                    <img
                      className="ilgaMonitor__logo"
                      src="/images/IWM-logo-vect.svg"
                      alt="Ilga World Monitor"
                    />
                  </div>
                  <div className="homepage__areasSidebar homepage__areasSidebar__monitor">
                    <p>
                      {t(
                        'The ILGA World Monitor is an automatic source aggregator created by ILGA World that collects, translates, sorts and labels content generated around the globe relating to SOGIESC issues. It tracks more than 20’000 sources in 70 languages on a daily basis, including media outlets, civil society organisations, NHRIs, academia, detractor organisations and groups, legal databases, and governmental websites.'
                      )}
                    </p>
                    <button
                      className="btn goToMonitorBtn"
                      onClick={() =>
                        (window.location = 'https://monitor.ilga.org/')
                      }
                    >
                      <span className="moreBtn__text">
                        {t('Go to the Monitor')}
                      </span>
                    </button>
                  </div>

                  <div
                    className="monitorCarrousel"
                    style={{ height: mCContainerHeight + 'px' }}
                  >
                    {latestNews &&
                      latestNews.length > 0 &&
                      jurisdictions &&
                      jurisdictions.length > 0 && (
                        <AutoScroller
                          setContainerHeight={updateContainerHeight}
                          speed={3000}
                          initial={5}
                          classes={'updates-container'}
                          pauseOver={true}
                          unit={106}
                          subclasses="update"
                          subclassesFn={(i, last) =>
                            i - 1 - last === 0
                              ? 'update--high'
                              : i - 1 - last === -1 || i - 1 - last === 1
                              ? 'update--mid'
                              : i - 1 - last === -2 || i - 2 - last === 1
                              ? 'update--low update--mid-low'
                              : 'update--low'
                          }
                        >
                          {latestNews
                            .filter(
                              art =>
                                art.j &&
                                jurisdictions.find(
                                  j => j.a2_code === art.j.split(',')[0]
                                )
                            )
                            .map((art, i) => (
                              <Fragment key={i}>
                                {art.j && (
                                  <p className="update__country">
                                    {art.j && (
                                      <img
                                        src={
                                          '/images/flags_svg/' +
                                          art.j.split(',')[0].toLowerCase() +
                                          '.svg'
                                        }
                                        height="18px"
                                        className={'svgFlag'}
                                        style={{ verticalAlign: 'sub' }}
                                      />
                                    )}{' '}
                                    {
                                      jurisdictions.find(
                                        j => j.a2_code === art.j.split(',')[0]
                                      ).name
                                    }{' '}
                                    ·{' '}
                                    <span className="sourceName">{art.s}</span>
                                  </p>
                                )}
                                <h3
                                  onClick={() => window.open(art.u)}
                                  className="update__title"
                                >
                                  {art.th ? art.th : art.h}{' '}
                                </h3>
                              </Fragment>
                            ))}
                        </AutoScroller>
                      )}
                  </div>
                </div>
              </section>
            </div>
          </FadeInSection>
        </div>
      )}
    </MainLayout>
  )
}
