import React, { Fragment } from 'react'
import IconCancel from '../../components/icons/IconCancel'
import IconCheck from '../../components/icons/IconCheck'
import i18n from '../../config/i18n'
import { i18ndayjs } from '../../lib/fnDates'
import DateDropdownFilter, {
  ApplyDropdownDateFilter
} from '../components/Table/DateDropdownFilter'
import GenericDropdownFilter, {
  ApplyGenericDropdownFilter
} from '../components/Table/GenericDropdownFilter'
import JurCell from '../components/Table/JurCell'
import JurFilter, { ApplyJurFilter } from '../components/Table/JurFilter'
import TopicFilter, { ApplyTopicFilter } from '../components/Table/TopicFilter'

const getBgColorClass = (cell, col) => {
  if (!cell.row.original[col]) return ''
  return cell.row.original[col].id === '1' || cell.row.original[col].id === '3'
    ? 'rowProtectionYes'
    : cell.row.original[col].id === '2' || cell.row.original[col].id === '4'
    ? 'rowProtectionNo'
    : cell.row.original[col].id === '5'
    ? 'rowProtectionVaries'
    : 'rowProtectionUnclear'
}

export const columns = () => [
  {
    Header: i18n.t('Instrument'),
    accessor: 'un_resolution.voting_instrument_type.name',
    id: 'instrument_col',
    className: 'textCenter col100',
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(rows, 'instrument_col', filterValue),
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width="200px"
          column={column}
          options={[
            { id: i18n.t('UNGA Resolution'), value: i18n.t('UNGA Resolution') },
            {
              id: i18n.t('UNHRC Resolution'),
              value: i18n.t('UNHRC Resolution')
            },
            { id: i18n.t('UNHRC Statement'), value: i18n.t('UNHRC Statement') }
          ]}
        />
      )
    },
    Cell: ({ value, row }) => {
      if (!row.original.un_resolution.voting_instrument_type) return ''
      return (
        <div>
          <strong>
            {row.original.un_resolution.voting_instrument_type.name}
          </strong>
          <br />
          {row.original.un_resolution.resolution_number}
        </div>
      )
    }
  },
  {
    Header: i18n.t('Date'),
    accessor: 'date',
    Cell: ({ value, row }) => {
      return i18ndayjs(value, 'DD MMM YYYY')
    },
    sortDescFirst: true,
    className: 'textCenter col80',
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(rows, ['date'], filterValue),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    }
  },
  {
    Header: i18n.t('Title'),
    accessor: 'resolution_title',
    style: { maxWidth: '300px', width: '300px' }
  },
  {
    Header: i18n.t('Jurisdiction'),
    disableGroupBy: true,
    accessor: 'motherEntry.jurisdiction.name',
    filter: ApplyJurFilter,
    Filter: ({ column }) => {
      return <JurFilter column={column} search={true} />
    },
    Cell: ({ value, row }) => {
      return <JurCell row={row} />
    },
    CellSmall: ({ value, row }) => {
      return (
        <div>{row.original.motherEntry.jurisdiction.name.substring(0, 3)}</div>
      )
    },
    style: { maxWidth: '125px' },
    className: 'col125'
  },
  {
    Header: i18n.t('Sponsor'),
    accessor: 'voting_sponsored_type.name',
    className: 'textCenter col80',
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(
        rows,
        'voting_sponsored_type.name',
        filterValue
      ),
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width="200px"
          column={column}
          options={[
            { id: i18n.t('Yes (Fav)'), value: i18n.t('Yes (Fav)') },
            { id: i18n.t('Yes (Unfav)'), value: i18n.t('Yes (Unfav)') },
            { id: i18n.t('No'), value: i18n.t('No') }
          ]}
        />
      )
    }
  },
  {
    Header: i18n.t('Vote'),
    accessor: 'voting_vote_type.name',
    className: 'textCenter col80',
    filter: (rows, columnsId, filterValue) =>
      ApplyGenericDropdownFilter(rows, 'voting_vote_type.name', filterValue),
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width="200px"
          column={column}
          options={[
            { id: i18n.t('Yes (Fav)'), value: i18n.t('Yes (Fav)') },
            { id: i18n.t('Yes (Unfav)'), value: i18n.t('Yes (Unfav)') },
            { id: i18n.t('No (Fav)'), value: i18n.t('No (Fav)') },
            { id: i18n.t('No (Unfav)'), value: i18n.t('No (Unfav)') },
            { id: i18n.t('Abstained'), value: i18n.t('Abstained') },
            { id: i18n.t('DNV'), value: i18n.t('DNV') }
          ]}
        />
      )
    },

    Cell: ({ value, row }) => {
      if (row.original.voting_vote_type)
        return row.original.voting_vote_type.id === '1' ||
          row.original.voting_vote_type.id === '3' ? (
          <>
            <IconCheck size={24} />
            <div className="">{value}</div>
          </>
        ) : row.original.voting_vote_type.id === '2' ||
          row.original.voting_vote_type.id === '4' ? (
          <>
            <IconCancel size={24} />
            <div className="">{value}</div>
          </>
        ) : (
          <div className="">{value}</div>
        )
      else return ''
    },
    classNameFn: c => getBgColorClass(c, 'voting_vote_type')
  },
  {
    Header: i18n.t('Topics'),
    accessor: 'motherEntry.topics',
    Cell: ({ value, row }) => {
      return row.original.motherEntry.topics
        ? row.original.motherEntry.topics.map((topic, index) => (
            <Fragment key={row.original + index}>
              <div className="tableTopic">{topic.name}</div>
              <div className="topicSeparator" />
            </Fragment>
          ))
        : ''
    },
    Filter: ({ column }) => {
      return <TopicFilter column={column} search={true} />
    },
    filter: ApplyTopicFilter,
    className: 'textCenter col80',
    disableSortBy: true
  },
  {
    Header: i18n.t('Source'),
    accessor: 'source',
    Cell: ({ value, row }) => {
      if (row.original.motherEntry.sources)
        return (
          <div className="tableSources">
            <div>
              {row.original.motherEntry.sources &&
              row.original.motherEntry.sources.length > 0 ? (
                <a
                  href={
                    process.env.REACT_APP_BACKEND_URL +
                    '/downloader/download/6/' +
                    row.original.motherEntry.sources[0].original_filename
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {row.original.un_symbol}
                </a>
              ) : (
                <> {row.original.un_symbol}</>
              )}
            </div>
            <div>
              {i18n.t('Session')}: {row.original.session}
            </div>
            <div>{row.original.text5}</div>
          </div>
        )
      else return <></>
    },
    className: 'col80',
    disableSortBy: true,
    disableFilter: true
  }
]
