import i18n from '../../config/i18n'
import routes from '../../config/routes'
import { citeEntry } from '../../lib/cite'
import { explanSourceLinks } from '../../lib/fnHelper'
import DateDropdownFilter, {
  ApplyDropdownDateFilter
} from '../components/Table/DateDropdownFilter'
import ExpandableTextCell from '../components/Table/ExpandableTextCell'
import GenericDropdownFilter, {
  ApplyGenericDropdownFilter
} from '../components/Table/GenericDropdownFilter'
import JurFilter, { ApplyJurFilter } from '../components/Table/JurFilter'
import SourceText from '../components/Table/SourceText'

const getBgColorClass = (cell, col) => {
  if (!cell.row.original[col]) return ''
  return cell.row.original[col].id === '1'
    ? 'rowProtectionYes'
    : cell.row.original[col].id === '6'
    ? 'rowProtectionNo'
    : cell.row.original[col].id === '2' || cell.row.original[col].id === '3'
    ? 'rowProtectionVaries'
    : 'rowProtectionUnclear'
}

const SummaryDropdown = ({ column }) => (
  <GenericDropdownFilter
    column={column}
    options={[
      { id: i18n.t('Marriage'), value: i18n.t('Marriage') },
      {
        id: i18n.t('Marriage & Civil Union'),
        value: i18n.t('Marriage & Civil Union')
      },
      { id: i18n.t('Civil Union Only'), value: i18n.t('Civil Union Only') },
      { id: i18n.t('Some Rights'), value: i18n.t('Some Rights') },
      { id: i18n.t('No'), value: i18n.t('No') },
      { id: i18n.t('Varies'), value: i18n.t('Varies') },
      { id: i18n.t('Unclear'), value: i18n.t('Unclear') }
    ]}
  />
)

const MarriageDropdown = ({ column }) => (
  <GenericDropdownFilter
    column={column}
    options={[
      { id: i18n.t('Yes'), value: i18n.t('Yes') },
      {
        id: i18n.t('Varies'),
        value: i18n.t('Varies')
      },
      {
        id: i18n.t('Recognised if performed abroad'),
        value: i18n.t('Recognised if performed abroad')
      },
      { id: i18n.t('Unclear'), value: i18n.t('Unclear') },

      { id: i18n.t('No'), value: i18n.t('No') }
    ]}
  />
)

const CivilDropdown = ({ column }) => (
  <GenericDropdownFilter
    column={column}
    options={[
      { id: i18n.t('Yes'), value: i18n.t('Yes') },
      {
        id: i18n.t('Varies'),
        value: i18n.t('Varies')
      },
      {
        id: i18n.t('Repealed or Discontinued'),
        value: i18n.t('Repealed or Discontinued')
      },
      { id: i18n.t('Unclear'), value: i18n.t('Unclear') },

      { id: i18n.t('No'), value: i18n.t('No') }
    ]}
  />
)

export const citeA115 = (entry, setToast) => {
  let lines = [
    '<p>' +
      `${
        entry.motherEntry.subjurisdiction
          ? entry.motherEntry.subjurisdiction.name +
            ' (' +
            entry.motherEntry.jurisdiction.name +
            ')'
          : entry.motherEntry.jurisdiction.name
      }: ${
        routes().find(r => r.parent === 'area1' && r.subareaNumber === 15).name
      }` +
      '</p>'
  ]

  if (entry.marriage_explan)
    lines.push(
      '<p>' +
        entry.marriage_type.name +
        ': ' +
        explanSourceLinks(entry.marriage_explan, entry, 1) +
        '</p>'
    )

  if (entry.civil_explan)
    lines.push(
      '<p>' +
        entry.civil_type.name +
        ': ' +
        explanSourceLinks(entry.civil_explan, entry, 2) +
        '</p>'
    )

  citeEntry(
    routes().find(r => r.parent === 'area1' && r.subareaNumber === 15),
    entry.motherEntry.subjurisdiction
      ? entry.motherEntry.subjurisdiction.name +
          ' (' +
          entry.motherEntry.jurisdiction.name +
          ')'
      : entry.motherEntry.jurisdiction.name,
    lines.join(''),
    setToast
  )
}

export const a115columns = () => [
  {
    Header: i18n.t('Jurisdiction'),
    disableGroupBy: true,
    accessor: 'motherEntry.jurisdiction.name',
    enableRowSpan: true,
    filter: ApplyJurFilter,
    Filter: ({ column }) => {
      return <JurFilter column={column} search={true} />
    },
    Cell: ({ value, row }) => {
      return (
        <div
          className={
            'jurCell ' +
            (row.original.motherEntry.jurisdiction.id.indexOf('DT') !== -1 ||
            row.original.motherEntry.subjurisdiction
              ? 'dependentTerritory'
              : '')
          }
        >
          <div>
            <img
              className="tableFlag"
              src={
                '/images/flags_svg/' +
                row.original.motherEntry.jurisdiction.a2_code.toLowerCase() +
                '.svg'
              }
              height="16px"
            />
          </div>
          <div>
            {!row.original.motherEntry.subjurisdiction &&
              row.original.motherEntry.jurisdiction.name}
            {row.original.motherEntry.subjurisdiction &&
              '' + row.original.motherEntry.subjurisdiction.name + ''}
          </div>
        </div>
      )
    },
    CellSmall: ({ value, row }) => {
      return (
        <div>{row.original.motherEntry.jurisdiction.name.substring(0, 3)}</div>
      )
    },
    style: { minWidth: '100px' }
  },
  {
    Header: i18n.t('Marriage Equality'),
    NeutralHeader: 'Equality',
    accessor: 'marriage_type.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return <MarriageDropdown column={column} />
    },

    classNameFn: c => getBgColorClass(c, 'marriage_type'),

    className: 'textCenter col100 flexTd',

    sortType: (rowA, rowB, id, desc) => {
      if (rowA.original.marriage_type.name > rowB.original.marriage_type.name)
        return -1
      if (rowB.original.marriage_type.name > rowA.original.marriage_type.name)
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('Critical Date'),
    accessor: 'marriage_critical_date_1',
    NeutralHeader: i18n.t('Critical Date'),
    sortDescFirst: true,
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(rows, ['marriage_critical_date_1'], filterValue),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    },
    Cell: ({ value, row }) => {
      if (value)
        return (
          value +
          (row.original.marriage_critical_date_2
            ? ' - ' + row.original.marriage_critical_date_2
            : '')
        )
      else return '-'
    },
    className: 'textCenter col80'
  },
  {
    Header: i18n.t('Mechanism'),
    NeutralHeader: i18n.t('Mechanism'),
    accessor: 'marriage_mechanism.name',
    className: 'textCenter col90',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          column={column}
          options={[
            { id: i18n.t('Legislative'), value: i18n.t('Legislative') },
            { id: i18n.t('Executive'), value: i18n.t('Executive') },
            { id: i18n.t('Judicial'), value: i18n.t('Judicial') },
            { id: i18n.t('Popular Vote'), value: i18n.t('Popular Vote') }
          ]}
        />
      )
    }
  },
  {
    Header: i18n.t('Explanation'),
    NeutralHeader: i18n.t('Explanation'),
    disableSortBy: true,
    accessor: 'marriage_explan',
    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            row={row}
            section={
              row.original.section ? row.original.section : 1
            }
            sources={row.original.motherEntry.sources}
            unik={row.original.id + '_explan'}
            footnotes={row.original.motherEntry.footnotes}
          />
        )
      else return <></>
    },
    NonExpandableCell: ({ value, row }) => {
      return (
        <SourceText
          text={value}
          section={
            row.original.section ? row.original.section : 1
          }
          sources={row.original.motherEntry.sources}
          unik={row.original.id + '_explan'}
        />
      )
    },
    style: { minWidth: '240px' },
    sortType: (rowA, rowB, id, desc) => {
      if (!rowB.original.general_explan) return null
      if (
        rowA.original.general_explan.replace(/[^\w\s]/gi, '') >
        rowB.original.general_explan.replace(/[^\w\s]/gi, '')
      )
        return -1
      if (
        rowB.original.general_explan.replace(/[^\w\s]/gi, '') >
        rowA.original.general_explan.replace(/[^\w\s]/gi, '')
      )
        return 1
      return 0
    }
  },
  {
    Header: i18n.t('Civil Partnership'),
    accessor: 'civil_type.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return <CivilDropdown column={column} />
    },

    className: 'textCenter col100 flexTd',

    classNameFn: c => getBgColorClass(c, 'civil_type'),
    sortType: (rowA, rowB, id, desc) => {
      if (rowA.original.civil_type.name > rowB.original.civil_type.name)
        return -1
      if (rowB.original.civil_type.name > rowA.original.civil_type.name)
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('Critical Date'),
    accessor: 'civil_critical_date_1',
    sortDescFirst: true,
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(rows, ['civil_critical_date_1'], filterValue),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    },
    Cell: ({ value, row }) => {
      if (row.original.civil_critical_date_1)
        return (
          row.original.civil_critical_date_1 +
          (row.original.civil_critical_date_2
            ? ' - ' + row.original.civil_critical_date_2
            : '')
        )
      else if (row.original.civil_repeal_date_1) {
        return (
          row.original.civil_repeal_date_1 +
          (row.original.civil_repeal_date_2
            ? ' - ' + row.original.civil_repeal_date_2
            : '')
        )
      } else return '-'
    },
    className: 'textCenter col80'
  },
  {
    Header: i18n.t('Mechanism'),
    accessor: 'civil_mechanism.name',
    className: 'textCenter col90',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          column={column}
          options={[
            { id: i18n.t('Legislative'), value: i18n.t('Legislative') },
            { id: i18n.t('Executive'), value: i18n.t('Executive') },
            { id: i18n.t('Judicial'), value: i18n.t('Judicial') },
            { id: i18n.t('Popular Vote'), value: i18n.t('Popular Vote') }
          ]}
        />
      )
    }
  },
  {
    Header: i18n.t('Explanation'),
    accessor: 'civil_explan',
    disableSortBy: true,
    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            row={row}
            section={2}
            sources={row.original.motherEntry.sources}
            unik={row.original.id + '_explan'}
            footnotes={row.original.motherEntry.footnotes}
          />
        )
      else return <></>
    },
    NonExpandableCell: ({ value, row }) => {
      return (
        <SourceText
          text={value}
          section={2}
          sources={row.original.motherEntry.sources}
          unik={row.original.id + '_explan'}
        />
      )
    },
    style: { minWidth: '240px' },
    sortType: (rowA, rowB, id, desc) => {
      if (!rowB.original.general_explan) return null
      if (
        rowA.original.general_explan.replace(/[^\w\s]/gi, '') >
        rowB.original.general_explan.replace(/[^\w\s]/gi, '')
      )
        return -1
      if (
        rowB.original.general_explan.replace(/[^\w\s]/gi, '') >
        rowA.original.general_explan.replace(/[^\w\s]/gi, '')
      )
        return 1
      return 0
    }
  }
]
