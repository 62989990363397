import React, { useEffect, useRef } from 'react'
import { occurrences } from '../../../lib/fnHelper'
import SourceText from './SourceText'
import dompurify from 'dompurify'

export default function ExpandableTextCell({
  value: initialValue,
  row: Row,
  sources,
  footnotes = [],
  skipSources = 0,
  section,
  unik,
  lines = 4,
  nltobr = false
}) {
  // We need to keep and update the state of the cell normally
  const [expandableRun, setExpandableRun] = React.useState(false)
  const [expandableRun2, setExpandableRun2] = React.useState(false)
  const [isShorten, setIsShorten] = React.useState(true)
  const [isExpandable, setIsExpandable] = React.useState(true)
  const [heights, setHeights] = React.useState(0)
  const { index } = Row
  const divCell = useRef()

  // If the initialValue is changed external, sync it up with our state

  React.useEffect(() => {
    if (isShorten) {
      //if (initialValue.length > maxLength)
      divCell.current.classList.remove('expandableCellExpanded')
      divCell.current.classList.add('expandableCell')
    } else {
      divCell.current.classList.remove('expandableCell')
      divCell.current.classList.add('expandableCellExpanded')
    }
  }, [isShorten, initialValue, skipSources, Row])

  useEffect(() => {
    if (!expandableRun) {
      if (divCell.current) {
        setIsExpandable(
          divCell.current.offsetHeight < divCell.current.scrollHeight
        )
        setHeights({
          clampLines: Math.floor(
            (divCell.current.parentElement.clientHeight - 40) / 16
          ),
          textTest:
            divCell.current.offsetHeight +
            ' - ' +
            divCell.current.scrollHeight +
            ' | ' +
            divCell.current.parentElement.clientHeight
        })
      }
    } else if (heights.clampLines && !expandableRun2) {
      setExpandableRun2(true)
      setIsExpandable(
        divCell.current.offsetHeight < divCell.current.scrollHeight - 16
      )
    }

    if (heights.clampLines < 0) {
      //Hack to Individual subjurisdictions entries display
      setHeights({
        clampLines: lines
      })
      setIsExpandable(true)
    }
  }, [expandableRun, expandableRun2])

  useEffect(() => {
    setExpandableRun(true)

    //if (divCell.current && !isExpandable) divCell.current.classList.remove("textClamped3");
  }, [isExpandable])

  return (
    <div
      key={index + 'calmpdiv' + unik}
      ref={divCell}
      className={
        (initialValue.length > 60 ? 'expandableCell ' : '') +
        (isShorten && isExpandable ? 'textClamped3' : 'textUnclamped')
      }
      style={{
        ...(heights.clampLines && { WebkitLineClamp: heights.clampLines })
      }}
      onClick={() => {
        if (isExpandable) setIsShorten(!isShorten)
      }}
    >
      {sources ? (
        <SourceText
          text={
            !initialValue.trim
              ? initialValue
              : nltobr
              ? initialValue.trim().replace(/\n/gi, '<br />')
              : initialValue
                  .trim()
                  .replace(/<br \/>/gi, '\n')
                  .replace(/<br>/gi, '\n')
                  .replace(/<\/p>/gi, '')
                  .replace(/<p>/gi, '')
          }
          sources={sources}
          footnotes={footnotes}
          section={section}
          skipSources={skipSources}
          unik={unik}
        />
      ) : (
        <span
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(
              !initialValue.trim
                ? initialValue
                : nltobr
                ? initialValue.trim().replace(/\n/gi, '<br />')
                : initialValue.trim()
            )
          }}
        />
      )}
      {isExpandable ? (
        <>
          {isShorten ? '...' : ''}{' '}
          {isExpandable && (
            <span className="moreLink" onClick={() => setIsShorten(!isShorten)}>
              {isShorten ? (
                <img src="images/ArrowDropdown.png" />
              ) : (
                <img src="images/ArrowDropdownUp.png" />
              )}
            </span>
          )}{' '}
        </>
      ) : (
        <>
          <span>{/* Hack to prevent Tippy creating an extra box*/}</span>
        </>
      )}
    </div>
  )
}
