import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  advancedSearchDocumentTypes,
  cSpecialProceduresCuTypes,
  cTreatyBodiesCuTypes
} from '../../../../config/constants'
import { useOnClickOutside } from '../../../../hooks/clickOutside'

const ASCheckBoxDropdown = ({ filters, setFilters }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [cuTypes, setCuTypes] = useState([])
  const { t } = useTranslation()
  const theDropdown = useRef(null)
  const hideDropdown = () => {
    setDropdownOpen(false)
  }
  useOnClickOutside(theDropdown, hideDropdown)

  const [demoInputChecked, setDemoInputChecked] = useState(false)

  useEffect(() => {
    if (
      filters.areas_ids &&
      !filters.areas_ids.some(a => a === 'a1' || a === 'a2' || a === 'a6') &&
      (!filters.subareas.a1 || !filters.subareas.a1.length) &&
      (filters.areas_ids.some(a => a === 'a3' || a === 'a4' || a === 'a5') ||
        (filters.subareas.a3 && filters.subareas.a3.length))
    ) {
      let cus = []
      if (
        filters.areas_ids.indexOf('a3') !== -1 ||
        (filters.subareas.a3 && filters.subareas.a3.length)
      )
        cus = cus.concat(
          advancedSearchDocumentTypes().filter(a => a.areas.indexOf(3) !== -1)
        )
      if (filters.areas_ids.indexOf('a4') !== -1)
        cus = cus.concat(
          advancedSearchDocumentTypes().filter(a => a.areas.indexOf(4) !== -1)
        )
      if (filters.areas_ids.indexOf('a5') !== -1)
        cus = cus.concat([{ name: t('UPR Recommendation') }])
      setCuTypes(
        cus.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
      )
    } else setCuTypes([])
  }, [filters])

  return (
    <div
      className={`dropdown-check-list ${dropdownOpen ? 'visible' : ''}`}
      tabIndex="100"
      onClick={() => cuTypes.length && setDropdownOpen(true)}
    >
      <span
        className={'anchor ' + (!cuTypes.length ? 'noCuTypes' : 'yesCuTypes')}
      >
        {!cuTypes.length
          ? t('This filter applies only to Areas 3, 4 and 5')
          : !filters.cu_types || !filters.cu_types.length
          ? t('All types of document')
          : filters.cu_types.length + ' ' + t('types of document')}
      </span>
      <ul className="items" ref={theDropdown}>
        {cuTypes.map(cu => (
          <li>
            <span className="check-container">
              <input
                name="name"
                type="checkbox"
                checked={
                  filters.cu_types && filters.cu_types.indexOf(cu.name) !== -1
                }
              />
              <label
                onClick={event => {
                  event.stopPropagation()
                  setFilters({
                    ...filters,
                    cu_types:
                      filters.cu_types &&
                      filters.cu_types.indexOf(cu.name) === -1
                        ? [
                            ...(filters.cu_types ? filters.cu_types : []),
                            cu.name
                          ]
                        : filters.cu_types
                        ? filters.cu_types.filter(c => c !== cu.name)
                        : []
                  })
                }}
              >
                {cu.name}
              </label>
            </span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ASCheckBoxDropdown
