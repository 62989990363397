import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { cMenuRegions, cTreatyBodies } from '../../../config/constants'
import BookIcon from '../icons/BookIcon'
import BubblesIcon from '../icons/BubblesIcon'
import TreatiesIcon from '../icons/TreatiesIcon'
import TreatyBodiesIcon from '../icons/TreatyBodiesIcon'
import UPRIcon from '../icons/UPRIcon'
import VotingIcon from '../icons/VotingIcon'
import AreaSelect from './AreaSelect'
import { AccountContext } from '../../../context/Accounts'
import IconOpenInNew from '../../../components/icons/IconOpenInNew'

export default function MapMenu({
  currentArea = 'A1-1',
  currentRegion = '0',
  subRegions = [],
  currentSubRegion = null,
  variant,
  calledFrom,
  extraProps,
  title,
  subTitle,
  subSubTitle = '',
  areaName,
  setNewTitle
}) {
  const { t, i18n } = useTranslation()
  const [allSelected, selectAll] = useState(true)
  const [selected, setSelected] = useState([])
  const [SOSelected, selectSO] = useState(false)
  const [GISelected, selectGI] = useState(false)
  const [GESelected, selectGE] = useState(false)
  const [SCSelected, selectSC] = useState(false)
  const { currentUser } = useContext(AccountContext)

  useEffect(() => {
    if (SOSelected && GISelected && GESelected && SCSelected) {
      selectAll(true)
      console.log('ALL')
    } else {
      let categories = []
      if (SOSelected) categories.push('so')
      if (GISelected) categories.push('gi')
      if (GESelected) categories.push('ge')
      if (SCSelected) categories.push('sc')
      if (extraProps && extraProps.setCategories) {
        extraProps.setCategories(categories)
      }
    }
  }, [SOSelected, GISelected, GESelected, SCSelected])

  useEffect(() => {
    if (allSelected) {
      selectSO(false)
      selectGI(false)
      selectGE(false)
      selectSC(false)
      setSelected([])
      if (
        extraProps &&
        extraProps.setCategories &&
        extraProps.categories &&
        extraProps.categories.length
      ) {
        extraProps.setCategories([])
      }
      if (
        extraProps &&
        extraProps.setMapBodies &&
        extraProps.mapBodies &&
        extraProps.mapBodies.length
      ) {
        extraProps.setMapBodies([])
      }
    } else {
    }
  }, [allSelected])

  useEffect(() => {
    if (extraProps && selected.length && extraProps.setMapBodies) {
      extraProps.setMapBodies(selected)
      setNewTitle(
        selected
          .map(tb => cTreatyBodies().find(t => t.id === tb).name)
          .join(' - ')
      )
    } else if (!selected.length) {
      setTimeout(() => {
        selectAll(true)
        setNewTitle(null)
      }, 300)
    }
  }, [selected])

  /* new code */
  const IconComponent = () => {
    switch (areaName) {
      case 'area-1':
        return <BookIcon color="#D8E3E5" />
      case 'area-2':
        return <TreatiesIcon color="#D8E3E5" />
      case 'area-3':
        return <TreatyBodiesIcon color="#D8E3E5" />
      case 'area-4':
        return <BubblesIcon color="#D8E3E5" />
      case 'area-5':
        return <UPRIcon color="#E7EDEF" />
      case 'area-6':
        return <VotingIcon color="#D8E3E5" />
      default:
        console.log('areaName')
        console.log(areaName)
        return <VotingIcon color="#D8E3E5" />
    }
  }

  return (
    <>
      {calledFrom !== 'homepage' && (
        <div className="areaContent__header">
          <div className="ach__iconContainer">
            <IconComponent title={title} />
          </div>
          <div className="ach__titlesContainer">
            <h2
              className="areaContent__title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {areaName === 'area-1' || areaName === 'area-3' ? (
              <h4 className="areaContent__subTitle">
                <span style={{ fontWeight: '900' }}>{subTitle}</span>
                {' | '}
                <span style={{ fontWeight: '600' }}>{subSubTitle}</span>
              </h4>
            ) : (
              <h4
                className="areaContent__subTitle"
                dangerouslySetInnerHTML={{ __html: subTitle }}
              />
            )}
            {((currentUser && currentUser.email === 'lucas@ilga') ||
              process.env.REACT_APP_LOCAL) && (
              <button className="exportButton" onClick={()=>window.open(`${process.env.REACT_APP_BACKEND_URL}/export/areaDocx`)}><IconOpenInNew size={16} /> Export Area</button>
            )}
          </div>
        </div>
      )}

      {variant === 'region' && (
        <>
          <AreaSelect currentArea={currentArea} />
          <Select
            className="selectRegion__select reactSelect"
            defaultValue={cMenuRegions().find(r => r.id === '0')}
            isSearchable={false}
            options={cMenuRegions()}
            placeholder="Select Region..."
          />
        </>
      )}

      {variant === 'treaties' && (
        <Select
          className="selectRegion__select reactSelect"
          defaultValue={cMenuRegions().find(r => r.id === '0')}
          isSearchable={false}
          options={cMenuRegions()}
          placeholder="Treaty..."
        />
      )}

      {variant === 'treatyBodies' && (
        <div className="selectCategory__container selectCategory__container_fullwidth">
          <div className="selectCategory">
            <input
              name="selectAll"
              type="checkbox"
              checked={allSelected}
              onChange={() => {
                if (!allSelected) {
                  selectAll(true)
                }
              }}
            />
            <label
              htmlFor="selectAll"
              onClick={() => {
                if (!allSelected) {
                  selectAll(true)
                }
              }}
            >
              All
            </label>
          </div>
          {cTreatyBodies().map((tb, tbi) => {
            let index = selected.indexOf(tb.id)
            return (
              <div className="selectCategory" key={tbi}>
                <input
                  type="checkbox"
                  checked={!allSelected && index !== -1}
                  onChange={() => {
                    if (index !== -1)
                      setSelected([
                        ...selected.slice(0, index),
                        ...selected.slice(index + 1)
                      ])
                    else setSelected(selected.concat([tb.id]))
                    selectAll(false)
                  }}
                />
                <label
                  onClick={() => {
                    if (index !== -1)
                      setSelected([
                        ...selected.slice(0, index),
                        ...selected.slice(index + 1)
                      ])
                    else setSelected(selected.concat([tb.id]))
                    selectAll(false)
                  }}
                >
                  {tb.name}
                </label>
              </div>
            )
          })}
        </div>
      )}
      {variant === 'miniMapActions' && (
        <>
          <div className="selectCategory__container">
            <div className="selectAllCategories selectCategory">
              <input
                name="selectAll"
                type="checkbox"
                checked={!extraProps.heatMap}
              />
              <label
                htmlFor="selectAll"
                onClick={e => {
                  extraProps.setHeatMap(0)
                }}
              >
                {t('Accepted (%)')}
              </label>
            </div>
            <div className="selectAllCategories selectCategory">
              <input
                name="selectAll"
                type="checkbox"
                checked={extraProps.heatMap === 1}
              />
              <label
                htmlFor="selectAll"
                onClick={e => {
                  extraProps.setHeatMap(1)
                }}
              >
                {t('Received')}
              </label>
            </div>
            <div className="selectAllCategories selectCategory">
              <input
                name="selectAll"
                type="checkbox"
                checked={extraProps.heatMap === 2}
              />
              <label
                htmlFor="selectAll"
                onClick={e => {
                  extraProps.setHeatMap(2)
                }}
              >
                {t('Issued')}
              </label>
            </div>
          </div>
        </>
      )}
      {variant === 'miniMapActionsLgr' && (
        <>
          <div className="selectCategory__container">
            <div className="selectAllCategories selectCategory">
              <input
                name="selectAll"
                type="checkbox"
                checked={!extraProps.mapOption}
              />
              <label
                htmlFor="selectAll"
                onClick={e => {
                  extraProps.setMapOption(0)
                }}
              >
                {t('Requirements')}
              </label>
            </div>
            <div className="selectAllCategories selectCategory">
              <input
                name="selectAll"
                type="checkbox"
                checked={extraProps.mapOption === 1}
              />
              <label
                htmlFor="selectAll"
                onClick={e => {
                  extraProps.setMapOption(1)
                }}
              >
                {t('Age Restrictions')}
              </label>
            </div>
            <div className="selectAllCategories selectCategory">
              <input
                name="selectAll"
                type="checkbox"
                checked={extraProps.mapOption === 2}
              />
              <label
                htmlFor="selectAll"
                onClick={e => {
                  extraProps.setMapOption(2)
                }}
              >
                {t('NB Gender Marker')}
              </label>
            </div>
          </div>
        </>
      )}
      {/* this is designed please dont remove */}
      {variant === 'procedures' && extraProps.specialProceduresTypes && (
        <div className="selectTreaty__select_container">
          <Select
            className="selectTreaty__select reactSelect"
            isSearchable={false}
            options={[
              { value: 1, label: t('Recommendations and mentions') },
              { value: 2, label: t('Recommendations only') }
            ]}
            defaultValue={1}
            placeholder={t('Recommendations and mentions')}
            maxMenuHeight="400px"
            onChange={v => {
              extraProps.setMentions(v.value)
            }}
          />
          <Select
            className="selectTreaty__select reactSelect"
            isSearchable={false}
            options={[{ value: null, label: t('All Procedures') }].concat(
              extraProps.specialProceduresTypes

                .filter(
                  t =>
                    (extraProps.mentions === 1 && t.amount_all) ||
                    (extraProps.mentions === 2 && t.amount_rec)
                )
                .sort((a, b) => {
                  if (a.name < b.name) return -1
                  else if (a.name > b.name) return 1
                  else return 0
                })
                .map(t => ({ value: t.id, label: t.name }))
            )}
            defaultValue={null}
            value={
              extraProps.currentSP &&
              extraProps.specialProceduresTypes.find(
                sp => sp.id == extraProps.currentSP
              )
                ? {
                    label: extraProps.specialProceduresTypes.find(
                      sp => sp.id == extraProps.currentSP
                    ).name,
                    value: extraProps.specialProceduresTypes.find(
                      sp => sp.id == extraProps.currentSP
                    ).id
                  }
                : null
            }
            placeholder={t('All Procedures')}
            maxMenuHeight="400px"
            onChange={v => {
              extraProps.setCurrentSP(v.value)
            }}
          />
        </div>
      )}
      {variant === 'ratifications' && extraProps.treatyBodies && (
        <div className="selectTreaty__select_container">
          <Select
            className="selectTreaty__select reactSelect"
            isSearchable={false}
            options={[{ value: null, label: t('All Treaties') }].concat(
              extraProps.treatyBodies

                .sort((a, b) => {
                  if (a.name < b.name) return -1
                  else if (a.name > b.name) return 1
                  else return 0
                })
                .map(t => ({ value: t.id, label: t.name }))
            )}
            defaultValue={null}
            value={
              extraProps.currentTB &&
              extraProps.treatyBodies.find(sp => sp.id == extraProps.currentTB)
                ? {
                    label: extraProps.treatyBodies.find(
                      sp => sp.id == extraProps.currentTB
                    ).name,
                    value: extraProps.treatyBodies.find(
                      sp => sp.id == extraProps.currentTB
                    ).id
                  }
                : null
            }
            placeholder={t('All Treaties')}
            maxMenuHeight="400px"
            onChange={v => {
              console.log(v)
              extraProps.setCurrentTB(v.value)
            }}
          />
        </div>
      )}
      {subRegions.length > 0 && <></>}

      {variant === 'categories' && (
        <div className="selectCategory__container">
          <div className="selectAllCategories selectCategory">
            <input
              name="selectAll"
              type="checkbox"
              checked={allSelected}
              onChange={() => {
                if (!allSelected) {
                  /*selectSO(false);
                  selectGI(false);
                  selectGE(false);
                  selectSC(false);*/
                  selectAll(!allSelected)
                }
              }}
            />
            <label
              htmlFor="selectAll"
              onClick={() => {
                if (!allSelected) {
                  /*selectSO(false);
                  selectGI(false);
                  selectGE(false);
                  selectSC(false);*/
                  selectAll(!allSelected)
                }
              }}
            >
              {t('All categories')}
            </label>
          </div>
          <div className="selectCategory">
            <input
              name="SO"
              type="checkbox"
              checked={SOSelected}
              onChange={() => {
                selectAll(false)
                selectSC(!SOSelected)
              }}
            />
            <label
              htmlFor="SO"
              onClick={() => {
                selectAll(false)
                selectSO(!SOSelected)
              }}
            >
              {t('SO')}
            </label>
          </div>
          <div className="selectCategory">
            <input
              name="GI"
              type="checkbox"
              checked={GISelected}
              onChange={() => {
                selectAll(false)
                selectSC(!GISelected)
              }}
            />
            <label
              htmlFor="GI"
              onClick={() => {
                selectAll(false)
                selectGI(!GISelected)
              }}
            >
              {t('GI')}
            </label>
          </div>
          <div className="selectCategory">
            <input
              name="GE"
              type="checkbox"
              checked={GESelected}
              onChange={() => {
                selectAll(false)
                selectSC(!GESelected)
              }}
            />
            <label
              htmlFor="GE"
              onClick={() => {
                selectAll(false)
                selectGE(!GESelected)
              }}
            >
              {t('GE')}
            </label>
          </div>
          <div className="selectCategory">
            <input
              name="SC"
              type="checkbox"
              checked={SCSelected}
              onChange={() => {
                selectAll(false)
                selectSC(!SCSelected)
              }}
            />
            <label
              htmlFor="SC"
              onClick={() => {
                selectAll(false)
                selectSC(!SCSelected)
              }}
            >
              {t('SC')}
            </label>
          </div>
        </div>
      )}
    </>
  )
}
