import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GET_A1_14_CT = gql(`
  query GetEntriesLgr( $lang: String, $jur_id: String) {
    entriesLgr(lang: $lang, jur_id: $jur_id) {
           id
    motherEntry {
        entry_type_id
        topics {
          id
          name
        }
        jurisdiction {
          id
          name
          un_member
          a2_code
          regions{
            id
          }
        }
        subjurisdiction {
          id
          name
          slug
          label_longitude
          label_latitude
          label_font_size
        }
        footnotes {
          id
          order
          section
          content
        }
        sources {
          id
          order
          section
          original_official_filename
          original_filename
          translated_filename
          enacted
          original_official_language{
            name
            id
          }
          original_official_language2{
            name
            id
          }
          original_language{
            name
            id
          }
          original_language2{
            name
            id
          }
        }
      }

        name_change_mechanism {
      id
      name
    }
    name_change_critical_date_1
    name_change_lgr_type {
      id
      name
    }
    gender_marker_mechanism {
      id
      name
    }
    gender_marker_lgr_type {
      id
      name
    }
    gender_marker_critical_date_1
    established_procedure {
      id
      name
    }
    gm_selfid_value {
      id
      name
    }
    gm_selfid_since
    gm_diagnosis_gm_value {
      id
      name
    }
    gm_non_binary_explan
    gm_no_children_explan
    gm_for_children_explan
    gm_forced_divorce_explan
    gm_witnesses_explan
    gm_expert_testimony_explan
    gm_real_life_test_explan
    gm_forced_sterilisation_explan
    gm_surgery_explan
    gm_hormone_treatment_explan
    gm_diagnosis_explan
    gm_selfid_explan
    gender_marker_amended_documents
    gender_marker_explan
    name_change_explan
    gm_non_binary_gm_avail_value {
      id
      name
    }
    gm_hormone_treatment_gm_value {
      id
      name
    }
    gm_surgery_gm_value {
      id
      name
    }
    gm_forced_sterilisation_gm_value {
      id
      name
    }
    gm_real_life_test_gm_value {
      id
      name
    }
    gm_for_children_gm_avail_value {
      id
      name
    }
    gm_no_children_gm_value {
      id
      name
    }
    gm_forced_divorce_gm_value {
      id
      name
    }
    gm_witnesses_gm_value {
      id
      name
    }
    gm_expert_testimony_gm_value {
      id
      name
    }
    }
}
`);

function useGqlEntriesLgr(jur_id = null) {
  const { i18n } = useTranslation();
  const entriesLgr = useQuery(GET_A1_14_CT, {
    variables: { jur_id, lang: i18n.language }
  });

  return {
    entriesLgr: entriesLgr.data && entriesLgr.data.entriesLgr
  };
}

export default useGqlEntriesLgr;
