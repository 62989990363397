// SPANISH
var lang = {
  AF: "Afganistán",
  AL: "Albania",
  DZ: "Argelia",
  AD: "Andorra",
  AO: "Angola",
  AG: "Antigua y Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaiyán",
  BS: "Bahamas",
  BH: "Baréin",
  BD: "Bangladés",
  BB: "Barbados",
  BY: "Bielorrusia",
  BE: "Bélgica",
  BZ: "Belice",
  BJ: "Benín",
  BT: "Bután",
  BO: "Bolivia",
  BA: "Bosnia-Herzegovina",
  BW: "Botsuana",
  BR: "Brasil",
  BN: "Brunéi",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Camboya",
  CM: "Camerún",
  CA: "Canadá",
  CV: "Cabo Verde",
  CF: "República Centroafricana",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CO: "Colombia",
  KM: "Comoras",
  CG: "República del Congo",
  CR: "Costa Rica",
  CI: "Costa de Marfil",
  HR: "Croacia",
  CU: "Cuba",
  CY: "Chipre",
  CZ: "República Checa",
  CD: "República Democrática del Congo",
  DK: "Dinamarca",
  DJ: "Yibuti",
  DM: "Dominica",
  DO: "República Dominicana",
  TL: "Timor Oriental",
  EC: "Ecuador",
  EG: "Egipto",
  SV: "El Salvador",
  GQ: "Guinea Ecuatorial",
  ER: "Eritrea",
  EE: "Estonia",
  SZ: "Suazilandia",
  ET: "Etiopía",
  FJ: "Fiyi",
  FI: "Finlandia",
  FR: "Francia",
  GA: "Gabón",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Alemania",
  GH: "Ghana",
  GR: "Grecia",
  GD: "Granada",
  GT: "Guatemala",
  GN: "Guinea",
  GW: "Guinea-Bisáu",
  GY: "Guyana",
  HT: "Haití",
  HN: "Honduras",
  HU: "Hungría",
  IS: "Islandia",
  IN: "India",
  ID: "Indonesia",
  IR: "Irán",
  IQ: "Irak",
  IE: "Irlanda",
  IL: "Israel",
  IT: "Italia",
  JM: "Jamaica",
  JP: "Japón",
  JO: "Jordania",
  KZ: "Kazajistán",
  KE: "Kenia",
  KI: "Kiribati",
  KW: "Kuwait",
  KG: "Kirguistán",
  LA: "Laos",
  LV: "Letonia",
  LB: "Líbano",
  LS: "Lesoto",
  LR: "Liberia",
  LY: "Libia",
  LI: "Liechtenstein",
  LT: "Lituania",
  LU: "Luxemburgo",
  MG: "Madagascar",
  MW: "Malaui",
  MY: "Malasia",
  MV: "Maldivas",
  ML: "Malí",
  MT: "Malta",
  MH: "Islas Marshall",
  MR: "Mauritania",
  MU: "Mauricio",
  MX: "México",
  FM: "Micronesia",
  MD: "Moldavia",
  MC: "Mónaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MA: "Marruecos",
  MZ: "Mozambique",
  MM: "Birmania",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Países Bajos",
  NZ: "Nueva Zelanda",
  NI: "Nicaragua",
  NE: "Níger",
  NG: "Nigeria",
  KP: "Corea del Norte",
  MK: "República de Macedonia",
  NO: "Noruega",
  OM: "Omán",
  PK: "Pakistán",
  PW: "Palaos",
  PA: "Panamá",
  PG: "Papúa Nueva Guinea",
  PY: "Paraguay",
  PE: "Perú",
  PH: "Filipinas",
  PL: "Polonia",
  PT: "Portugal",
  QA: "Catar",
  RO: "Rumania",
  RU: "Rusia",
  RW: "Ruanda",
  KN: "San Cristóbal y Nieves",
  LC: "Santa Lucía",
  VC: "San Vicente y las Granadinas",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tomé y Príncipe",
  SA: "Arabia Saudita",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leona",
  SG: "Singapur",
  SK: "Eslovaquia",
  SI: "Eslovenia",
  SB: "Islas Salomón",
  SO: "Somalia",
  ZA: "Sudáfrica",
  KR: "Corea del Sur",
  SS: "Sudán del Sur",
  ES: "España",
  LK: "Sri Lanka",
  SD: "Sudán",
  SR: "Surinam",
  SE: "Suecia",
  CH: "Suiza",
  SY: "Siria",
  TJ: "Tayikistán",
  TZ: "Tanzania",
  TH: "Tailandia",
  TG: "Togo",
  TO: "Tonga",
  TT: "Trinidad y Tobago",
  TN: "Túnez",
  TR: "Turquía",
  TM: "Turkmenistán",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ucrania",
  AE: "Emiratos Árabes Unidos",
  GB: "Reino Unido",
  US: "Estados Unidos",
  UY: "Uruguay",
  UZ: "Uzbekistán",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Vietnam",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabue",
  TW: "Taiwan (China)",
  XK: "Kosovo",
  PS: "Palestina",
  EH: "República Árabe Saharaui Democrática",
  VA: "Vaticano",
  AS: "Samoa Americana (EE.UU.)",
  AI: "Anguilla (Reino Unido)",
  AW: "Aruba (Países Bajos)",
  BM: "Bermuda (Reino Unido)",
  BQ: "Bonaire; San Eustaquio y Saba (Países Bajos)",
  IO: "Territorio Británico del Océano Índico (Reino Unido)",
  VG: "Islas Vírgenes Británicas (Reino Unido)",
  KY: "Islas Caimán (Reino Unido)",
  CK: "Islas Cook (Nueva Zelanda)",
  CW: "Curazao (Países Bajos)",
  FK: "Islas Malvinas (Reino Unido / Argentina)",
  FO: "Islas Feroe (Dinamarca)",
  GF: "Guyana Francesa (Francia)",
  PF: "Polinesia Francesa (Francia)",
  GI: "Gibraltar (Reino Unido)",
  GL: "Groenlandia (Dinamarca)",
  GP: "Guadalupe (Francia)",
  GU: "Guam (EE.UU.)",
  GG: "Guernsey (Reino Unido)",
  HK: "Hong Kong (SAR China)",
  IM: "Isla de Man (Reino Unido)",
  JE: "Jersey (Reino Unido)",
  MO: "Macao (China)",
  MQ: "Martinica (Francia)",
  YT: "Mayotte (Francia)",
  MS: "Montserrat (Reino Unido)",
  NC: "Nueva Caledonia (Francia)",
  NU: "Niue (Nueva Zelanda)",
  MP: "Islas Marianas del Norte (EE.UU.)",
  PN: "Islas Pitcairn (Reino Unido)",
  PR: "Puerto Rico (EE.UU.)",
  RE: "Reunión (Francia)",
  BL: "San Bartolomé (Francia)",
  SH: "Santa Helena; Ascensión y Tristán da Cunha (Reino Unido)",
  MF: "San Martín (Francia)",
  PM: "San Pedro y Miquelón (Francia)",
  SX: "San Martín (Holanda)",
  GS: "Georgias del Sur y Sandwich (Reino Unido / Argentina)",
  TK: "Tokelau (Nueva Zelanda)",
  TC: "Islas Turks y Caicos (Reino Unido)",
  VI: "Islas Vírgenes (EE.UU.)",
  WF: "Wallis y Futuna (Francia)"
};
export default lang;
