export const introText = (
  <>
    <p>
      This section provides an overview of the jurisdictions where consensual same-sex sexual acts between adults in
      private are criminalised (“illegal”) or those in which they have been legalised (“legal”).
    </p>
    <p>
      Legality of same-sex sexual acts cannot be read as evidence of a safer living environment for people with diverse
      sexual orientations or gender identities or expressions. In many of the States where consensual same-sex sexual
      acts are legal, social stigmatisation of people who are perceived as non-heterosexual or non-cisgender remains
      alarmingly high. In fact, in many of them, early decriminalisation dates can be explained by historical reasons
      completely unrelated to activism or lower hostility towards non-heterosexual forms of sexuality.
    </p>
    <p>
      Information on enforcement of these provisions in each criminalising country can be found in the last column
      (“Enforcement”) of the chart below. This data comes from ILGA World’s report “
      <a href="https://ilga.org/our-identities-under-arrest" target="_blank" rel="noreferrer">
        Our Identities under Arrest
      </a>
      ”.
    </p>
  </>
);

export const methodologyText = (
  <>
    <h5>Terminology: acts, not identities</h5>

    <p>
      In this section, the term &ldquo;criminalisation of consensual same-sex sexual acts&rdquo; is adopted to describe
      the specific type of criminalised conduct that we track in the report. This language focuses on the
      criminalisation of <em>acts</em> and <em>behaviours</em>&mdash;which is the object of criminal law&mdash;as
      opposed to identities or sexual orientations.
    </p>
    <p>
      ILGA World expressly refrains from using certain expressions and ways of framing this issue that other
      stakeholders may favour. This is especially the case of non-specialised media outlets, where the need to summarise
      and avoid complex phrasing or legal jargon for effective communication may justify other terminological decisions.
    </p>
    <p>
      In particular, ILGA World refrains from using expressions such as &ldquo;criminalisation of homosexuality&rdquo;,
      countries &ldquo;where it is illegal to be gay or lesbian&rdquo;, and more technically &ldquo;criminalisation of
      same-sex <em>relations</em>&rdquo;. These terminological decisions are informed by our advocacy work and the need
      to be specific about the content of the provisions that are still in force in all criminalising countries.
    </p>
    <p>
      In defending or justifying these laws, several States have presented arguments that hinge on legal technicalities.
      Although many of these arguments can be easily rebutted with contextual information, oftentimes these capricious
      technical arguments may survive strictly legal assessments. More specifically, countries that still have
      criminalising provisions in place argue that they do not penalise &ldquo;homosexuality&rdquo; or &ldquo;being
      gay&rdquo; <em>per se</em>, and even that they are not applying criminalisation based on the person&rsquo;s sexual
      orientation.
    </p>
    <p>
      For instance, in 2019, Brunei, a UN Member State where consensual same-sex sexual acts can be punished with death
      by stoning, stated during its third UPR cycle that &ldquo;the Sharia Penal Code Order does not criminalize a
      person&rsquo;s status based on sexual orientation or belief, nor does it victimize&rdquo; and stressed that
      &ldquo;Brunei's society regardless of the sexual orientation have continued to live and pursue activities in the
      private space&rdquo;. In the same vein, Barbados explained that although &ldquo;buggery&rdquo; is criminalised by
      Section 9 of the Sexual Offences Act, &ldquo;same-sex relations are not criminalised&rdquo; in their legislation
      &ndash; &ldquo;what is criminalised is buggery&rdquo;.
    </p>
    <p>
      It goes without saying that these provisions clearly target particular communities and identities, even if not
      explicitly. By penalising &ldquo;sodomy&rdquo;, &ldquo;buggery&rdquo; or &ldquo;sexual acts with people of the
      same sex&rdquo;, legal frameworks impose criminal punishments upon one of the activities that is relevant in
      defining such identities. In many places, these acts are even &ldquo;presumed&rdquo; when people are reported or
      arrested under these provisions solely based on their appearance or being in the company of people of the same sex
      at a gathering. Therefore, the result is the same: impeding persons of diverse sexual orientation to live a full
      life free from violence and discrimination.
    </p>

    <h5>Acts involving consenting adults only</h5>

    <p>
      The report tracks the criminalisation and decriminalisation of consensual same-sex sexual acts between adults.
      This criterion also informs the way in which we report on documented cases of enforcement of criminalising laws by
      setting the focus almost exclusively on cases that affect people above 18 years, in line with the standard
      definition for child established under Article 1 of the Convention on the Rights of the Child, regardless of
      whether domestic legislation sets lower ages of consent.
    </p>
    <p>
      Tracking cases of enforcement on consensual same-sex sexual acts is particularly difficult for several reasons.
      When laws criminalise all forms of same-sex sexual acts&mdash;consensual or not&mdash;under the same provision,
      special efforts need to be made to corroborate several aspects of reported cases. Specifically, additional
      information regarding the circumstances of each case and the ages (at the time of the incidents) of those involved
      is always required to ascertain whether any given case reportedly brought under these provisions is actually about
      consensual same-sex sexual acts between adults. In other words, a major challenge in our tracking work is that the
      consensual nature of reported cases might not always be clear when we look at media coverage about this topic
      around the globe.
    </p>
    <p>
      The reporting of cases of arrests or prosecutions for &ldquo;sodomy&rdquo;, for example, include cases involving
      consenting adults and rapists alike. To name only a few examples, in September 2001, a man in his thirties was
      reportedly sentenced to death by stoning &ldquo;for sodomy&rdquo; by an Upper Sharia Court in Kebbi State,
      Nigeria. However, further information on the facts of the case showed that it was actually a case of sexual abuse
      of a seven-year-old boy. Likewise, in September 2003, another adult man was sentenced to death by stoning after he
      was found guilty of "sodomy&rdquo;. However, the victims in this case were again three boys between the ages of
      ten and thirteen years (one of whom was reportedly given six strokes of the cane for accepting money for sexual
      services). Even though the case may have been labelled as a &ldquo;sodomy&rdquo; case, the non-consensual nature
      of the act in question is evinced when specific information on the circumstances of the case becomes public.
      Likewise, in the Caribbean, cases of men prosecuted for &ldquo;buggery&rdquo; often involve men who abused
      underage children.&nbsp;
    </p>
    <p>
      Even more problematic, many cases of rape are labelled as cases brought against &ldquo;homosexuals&rdquo;. To cite
      only one example, in 2018, the Nigerian newspaper, <em>The Independent,</em> published an article entitled
      &ldquo;Nigerian Suspected Homosexual Remanded in Sokoto&rdquo;, reporting on the case of a 22-year-old man who was
      prosecuted for &ldquo;carnal knowledge&rdquo; of a boy &ldquo;against the order of nature&rdquo;. While this is an
      example of a news report containing enough information to discard it completely as an instance of enforcement of
      criminalising laws against consenting adults, these facts are not always available. The lack of key data renders
      monitoring activities through the press particularly difficult, given that corroboration is not always possible.
      This is compounded by the high rates of underreporting of such instances, so the actual number of cases flying
      below our radars is hard to estimate.
    </p>
    <p>
      Furthermore, besides posing difficulties to the tracking of cases, the fact that the same provisions serve as the
      legal basis to prosecute both consensual and non-consensual sexual acts reinforces the troubling conflation of
      homosexuality with sexual predation. For instance, when a staff member of the Barbados Boy Scouts Association
      sexually assaulted a 12-year old member, the head of the Association spoke out against
      &ldquo;homosexuality&rdquo;, as opposed to paedophilia. In 2016, then-Prime Minister Freundel Stuart stated,
      &ldquo;Rape is the offence committed against in a heterosexual relationship and buggery is the offence committed
      in a same-sex relationship&rdquo;.
    </p>
    <p>
      Even if all people reportedly involved are adults, the consensual nature of the act cannot be automatically
      assumed. As explained in the entry for Iran in the special dossier on the death penalty, legal frameworks may
      incentivise people who consented to sexual acts to report them as non-consensual to be spared from harsh
      punishments themselves.
    </p>
    <p>
      In conclusion, it is with special caution that we look into reports of enforcement of criminalising provisions.
      Whenever available information indicates that the relevant case involved minors or the consensual nature of the
      acts is not clear, cases are either discarded or inserted with specific caveats that may cast doubt about the
      actual circumstances of the reported incident.
    </p>

    <h5>Private and public spheres</h5>

    <p>
      Another criterion we follow is whether or not the criminalisation of consensual acts include those which take
      place in private. We do not place under the &ldquo;illegal&rdquo; category States that still keep criminalising
      provisions for same-sex sexual acts committed in public.
    </p>
    <p>
      We are aware that, in the last four decades, the focus on the right to private life and the projection of our
      private life into the public sphere has been the subject of debates informing legal strategies in our quest for
      equality. Seminal cases, including early decisions by the European Court of Human Rights and at the UN in the
      landmark case
      <a href="https://juris.ohchr.org/Search/Details/702">
        <em>Toonen v. Australia</em>
      </a>{" "}
      (1994), hinged mainly around the protection of the right to private life. Later on, there was a shift towards an
      approach based on the right to equality before the law and non-discrimination.
    </p>
    <p>
      The incompatibility of criminalising private consensual sexual acts with international human rights law is now a
      well-established minimum standard that States need to abide by. As this report was idealised to function as an
      advocacy instrument, the original aim was to track laws that States kept in contravention of this principle.
    </p>
    <p>
      However, we understand that in many contexts certain acts&mdash;which do not amount to intercourse and are
      legitimate expressions of love, such as public displays of affection&mdash;can definitely play a role in how
      people of diverse sexual orientations are oppressed and persecuted under the law. Where such information is
      available, we make an effort to identify and emphasise it in the country entry, even if the State is placed under
      the &ldquo;legal&rdquo; section.
    </p>
    <p>
      Last but not least, the process of decriminalisation has not always been clear cut in all States. In other words,
      many countries did not move from full criminalisation to full decriminalisation but opted for gradual changes in
      the way consensual same-sex sexual acts were restricted. While repealing acts in private, many countries kept
      residual provisions penalising crimes such as &ldquo;scandalous sodomy&rdquo; (i.e. Costa Rica), &ldquo;public
      displays of homosexuality&rdquo; (i.e. Cuba), or raised the age of consent to legally engage in same-sex sexual
      activity. These nuances have been captured to a limited extent, but even when we track them the critical date for
      decriminalisation is fixed at the time of decriminalisation of consensual sexual activity between adults in
      private.
    </p>

    <h5>
      <em>De facto</em>
      criminalisation
    </h5>

    <p>
      As a general rule, this report only covers legal aspects and provisions. Thus, it is limited to the law enforced
      in each country, not analysing broader contexts with regard to the social reality. However, one exception could be
      pointed out in relation to our definition of &ldquo;<em>de facto </em>
      criminalisation&rdquo;.
    </p>
    <p>
      While in most cases we only consider that a country criminalises same-sex sexual acts if there is an{" "}
      <em>explicit</em> legal provision in that regard (or terminology widely known to mean the same thing, such as
      &ldquo;acts against nature&rdquo;), there are two States in which we understand that <em>de facto </em>
      criminalisation is in place: Egypt and Iraq. To enter into this category, there must be substantial and consistent
      reports from the ground that provide evidence that persons have been arrested or prosecuted because of their
      actual or perceived sexual orientation or the engagement of same-sex intercourse despite there being no law
      explicitly criminalising such acts or identities. Therefore, we only label a given country under that category
      after identifying a repeating pattern that falls under these listed criteria. We do this so that isolated cases,
      in which a single judge may have applied an unorthodox interpretation of law, are not presumed to represent the
      broad situation within the country.
    </p>
    <p>
      And it is for this same reason that some countries in which we have identified unusual cases of arrest for the
      practice of consensual same-sex activity, have not been categorised as having <em>de facto</em> criminalisation,
      such as in the Central African Republic, Democratic Republic of the Congo, and C&ocirc;te d&rsquo;Ivoire. If the
      situation in such countries changes in the coming years, they might require recategorisation. At the time of
      publication, Indonesia (at the national level), appears to be moving towards becoming a country that could be
      considered for such recategorisation. ILGA World will keep track of unfolding events in provinces that do not have
      criminalising provisions to assess whether the whole country should future be labelled as <em>de facto</em>{" "}
      criminalising.
    </p>

    <h5>Dates of decriminalisation</h5>

    <p>
      A complex issue our team has faced is how best to establish the date of decriminalisation of such acts in each
      country. As we have stipulated, the report tracks criminalisation of consensual same-sex acts between adults in
      private. Thus, the date of decriminalisation should correspond to the year when the last piece of legislation
      criminalising these acts in the country&rsquo;s territory was repealed. As mentioned above, the date of the repeal
      of laws criminalising certain forms of public sexual activity is not taken into account to determine the date of
      decriminalisation.
    </p>

    <h5>Primary forms of criminalisation</h5>

    <p>
      When it concerns criminalisation, the main sources that we look at to ascertain whether the country indeed
      decriminalised are the criminal codes. For that reason, we do not <em>systematically</em> cover other types of
      regulations that might be used to criminalise same-sex sexual activity, although we mention it when it has come to
      our attention (as is the case for Peru or El Salvador).
    </p>
    <p>
      Moreover, we prioritise the year when the country approved a national ban on criminalisation, rather than at the
      subnational level, when defining the main date of the entry. However, we do also indicate when the first
      subnational and the last jurisdiction decriminalised in countries where the process was gradual at the subnational
      level (as in the USA).
    </p>

    <h5>Statehood and decriminalisation</h5>

    <p>
      In this edition, we have decided to incorporate scholarly feedback concerning the definition of the date of
      decriminalisation in countries that suffered periods of colonisation and that became independent under a
      jurisdiction in which there was no prohibition on the practice of same-sex sexual acts. Most of these cases are
      early dates of decriminalisation that took place during the 19th and 20th centuries due to historical reasons
      largely unrelated to human rights activism. In these cases, we had three different options to choose from in order
      to establish the relevant date:
    </p>
    <p>
      The first one, which is mostly what had been applied in previous editions of this report, was to settle the year
      of independence as the one that marked decriminalisation, provided that there was no subsequent enactment of
      criminalising legislation following the independence. This route in essence holds that before a State formally
      exists, it can neither criminalise nor decriminalise anything.
    </p>
    <p>
      Another possibility that has also been applied in past editions was to consider the year of approval of the
      country&rsquo;s first post-independence penal code as the decriminalising milestone. This would reflect that, in
      its first sovereign decision as an independent State regarding criminal laws, the country chose not to penalise
      same-sex sexual acts.
    </p>
    <p>
      However, the above options might lead to some misunderstanding and have indeed been controversial among our
      readership. For example, territories in which such acts were never actually criminalised might be presumed to have
      once enacted penalties for this behaviour if the reader looks to the chart and sees either the date of
      independence or the date of approval of the country&rsquo;s first criminal code. For that reason, in this edition
      we note where countries appear to have never criminalised same-sex acts, and have decided to take as a reference
      any relevant legislation which came into effect prior to a State&rsquo;s formal independence.
    </p>
    <p>
      This has led to a change in the data displayed with regard to a number of African and Asian States. In several
      cases, when investigating previous records of criminalisation, we found no reliable evidence as to whether the
      country actually ever had any criminalising laws. Thus, considering the absence of accurate information, at least
      available in public records, no specific year for decriminalisation was inserted for Burkina Faso, Djibouti,
      Vietnam, and many others.
    </p>

    <h5>Gaps and transitions from colonial laws</h5>

    <p>
      It is important to point out that broad research on the application of colonial law in several regions has been
      conducted, however this has largely been limited to documents available in desktop research and without access to
      local archives.
    </p>
    <p>
      In some cases, as in the former Spanish colonies, it was possible to identify that the law of the colony and the
      metropolis were not implemented in complete synchrony. Therefore, several colonies continued with the application
      of the provision from &ldquo;Las Siete Partidas&rdquo; (which registered the crime of &ldquo;sodomy&rdquo; under
      <a href="https://www.boe.es/biblioteca_juridica/publicacion.php?id=PUB-LH-2011-60&amp;tipo=L&amp;modo=2">
        {" "}
        Title XXI &ndash; <em>Of those who make a sin of lust against nature</em>, <em>Partida</em> No. 7, Volume III
      </a>
      , where it states that, if the act is proved, the person who committed it &ldquo;shall die&rdquo;), even after the
      approval of Spanish codes. As a general rule for countries that were colonised by Spain, when we indicate the year
      for decriminalisation as the one in which the country approved its first Penal Code, its means that we believe
      that the criminalising provisions from "Las Siete Partidas" were still in force until they were completely
      repealed by the new code.
    </p>
    <p>
      In other situations, as in the case of the former French colonies, a dual regime was identified, with an asymmetry
      between the laws applied to natives and to those considered &ldquo;French citizens&rdquo; present in the same
      territory. In view of this, and considering the difficulty of ascertaining when or how the law applied to natives
      because of the legal uncertainty associated with it, we decided to indicate as the date of decriminalisation the
      year in which French laws became valid in such territories, although noting reservations with regard to the
      asymmetry of application.
    </p>
  </>
);
