import { gql, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const GET_TIMELINE = gql(`
  query GetEntriesTimeline($lang: String, $jur_id: String, $subjur_id: String) {
    jurTimeline(lang: $lang, jur_id: $jur_id, subjur_id: $subjur_id) {
      year
      events {
        title
        subtitle
      }

  }
}
`)

function useGqlTimeline(jur_id, subjur_id) {
  const { i18n } = useTranslation()
  const jurTimeline = useQuery(GET_TIMELINE, {
    variables: { jur_id, subjur_id, lang: i18n.language }
  })

  return { jurTimeline: jurTimeline.data && jurTimeline.data.jurTimeline }
}

export default useGqlTimeline
