import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Switch from "react-switch";

export default function UNMemberSwitch({ unMember, setUnMember, extraStyles }) {
  const { t } = useTranslation();
  return (
    <div style={{ display: "flex" }}>
      <label style={{ marginLeft: "auto", ...extraStyles }}>
        <span style={{ verticalAlign: "super" }}>{t("UN Members Only")}</span>{" "}
        <Switch
          onChange={checked => setUnMember(checked)}
          checked={unMember}
          uncheckedIcon={false}
          checkedIcon={false}
          height={16}
          width={32}
          onColor={"#b0cbce"}
          offColor={"#e0e0e0"}
        />
      </label>
    </div>
  );
}
