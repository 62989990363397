import useError from "../hooks/useError";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

export default function ErrorNotification() {
  const { error } = useError();

  useEffect(() => {
    if (error && error.message) toast.info(error.message, { position: "top-center", transition: Flip });
  }, [error]);

  return <ToastContainer />;
}
