import { useEffect, useRef, useState } from 'react'
import { toSVG, downloadToImage, downloadToPDF } from '../../../lib/exporter'
import MapMenu from '../AreaSelect/MapMenu'
import MapChartSettings from '../MapChartSettings/MapChartSettings'
import RegionMapSelect from '../RegionMapSelect/RegionMapSelect'
import Map from './Map'
import MapInfoModal from '../MapInfoModal/MapInfoModal'
import MapYearFilter from '../MapYearFilter/MapYearFilter'
import { i18ndayjs } from '../../../lib/fnDates'
import { useTranslation } from 'react-i18next'
import useGqlLatestUpdates from '../../../gql/useGqlLatestUpdates'

export default function MapContainer({
  references,
  extraReferences,
  htmlReferences,
  jurisdictionsById,
  jurisdictions,
  jurSubjurs,
  mapLegendItems,
  mapLegendGradient,
  mapLegendStepGradient,
  mapLegendProtectionGradient,
  extraMapLegendItemClass = '',
  mapLoaded,
  setMapLoaded,
  currentArea,
  menuVariation,
  extraProps,
  modalTitle,
  heatMap,
  modalContent,
  title,
  subTitle,
  subSubTitle,
  showYearFilter = false,
  areaName,
  showTitle = true,
  showMapChartSettings = true,
  showMapInfoModal = true,
  showLoader = true,
  showTooltip = true,
  showRegions = true,
  include,
  externalHideMicrostates = false,
  externalPacificCenter = false,
  externalShowLabels = false,
  externalProjection = 'Robinson',
  externalFontRatio = 1,
  editor = false
}) {
  const [drillDown, setDrillDown] = useState('0')
  const [showLabels, setShowLabels] = useState(false)
  const [highQuality, setHighQuality] = useState(false)
  const [hideMicrostates, setHideMicrostates] = useState(
    externalHideMicrostates
  )
  const [pacificCenter, setPacificCenter] = useState(externalPacificCenter)
  const [projection, setProjection] = useState(externalProjection)

  const [fontRatio, setFontRatio] = useState(externalFontRatio)

  const [newTitle, setNewTitle] = useState(null)

  const [menuOpen, setMenuOpen] = useState(false)
  const [regionSelectOpen, setRegionSelectOpen] = useState(false)
  const [drilledDown, setDrilledDown] = useState(false)
  const { latestUpdates } = useGqlLatestUpdates(currentArea)

  const [t, i18n] = useTranslation()

  const mapContainerRef = useRef()
  const chart = useRef()

  // HIDES BUTTONS UNTILL MAP AREA IS READY
  const showBtns = jurisdictions && references && references.length > 0
  // const finalReferences = extraReferences ? references.concat(extraReferences) : references;

  const handleExportSVG = async () => {
    setMenuOpen(false)

    const draw = await toSVG(mapContainerRef.current, {
      title: newTitle || subSubTitle,
      subtitle:
        areaName === 'area-3'
          ? i18ndayjs(new Date('2021-12-12'), 'MMMM YYYY')
          : latestUpdates && latestUpdates.length
          ? i18ndayjs(
              latestUpdates[0].year +
                '-' +
                latestUpdates[0].month +
                '-' +
                latestUpdates[0].day,
              'MMMM YYYY'
            )
          : i18ndayjs(
              new Date(new Date().setMonth(new Date().getMonth() - 1)),
              'MMMM YYYY'
            ), //i18ndayjs(new Date('2023-04-02'), 'MMMM YYYY'), // temporary

      t,
      language: i18n.language
    })
    downloadToPDF(draw, { width: 1414, height: 1000 }, newTitle || subSubTitle)
  }

  const handleExportPNG = async opts => {
    setMenuOpen(false)
    console.log('MMMMM', latestUpdates)
    const draw = await toSVG(mapContainerRef.current, {
      title: newTitle || subSubTitle,
      subtitle:
        areaName === 'area-3'
          ? i18ndayjs(new Date('2021-12-12'), 'MMMM YYYY')
          : latestUpdates && latestUpdates.length
          ? i18ndayjs(
              latestUpdates[0].year +
                '-' +
                latestUpdates[0].month +
                '-' +
                latestUpdates[0].day,
              'MMMM YYYY'
            )
          : i18ndayjs(
              new Date(new Date().setMonth(new Date().getMonth() - 1)),
              'MMMM YYYY'
            ), // temporary
      /*
          latestUpdates && latestUpdates.length
          ? i18ndayjs(latestUpdates[0].date, 'MMMM YYYY')
          : i18ndayjs(new Date(), 'MMMM YYYY'), */
      t,
      language: i18n.language
    })
    downloadToImage(
      draw,
      { ...opts, width: 1414, height: 1000 },
      newTitle || subSubTitle
    )
  }

  useEffect(() => {
    setFontRatio(externalFontRatio)
  }, [externalFontRatio])

  useEffect(() => {
    setHideMicrostates(externalHideMicrostates)
  }, [externalHideMicrostates])

  useEffect(() => {
    setPacificCenter(externalPacificCenter)
  }, [externalPacificCenter])

  useEffect(() => {
    setShowLabels(externalShowLabels)
  }, [externalShowLabels])

  useEffect(() => {
    setProjection(externalProjection)
  }, [externalProjection])

  return (
    <div
      className="content__container mapContent__container"
      ref={mapContainerRef}
    >
      {references && (
        <Map
          pacificCenter={pacificCenter}
          showLabels={showLabels}
          highRes={highQuality}
          projection={projection}
          hideMicrostates={hideMicrostates}
          references={references}
          drilledDown={drilledDown}
          setDrilledDown={setDrilledDown}
          jurisdictionsWithDrillDown={[
            ...new Set(
              references
                .filter(
                  r =>
                    r.entry.motherEntry &&
                    r.entry.motherEntry.subjurisdiction &&
                    r.entry.motherEntry.subjurisdiction.id
                )
                .map(r =>
                  r.entry.motherEntry.jurisdiction.a2_code.toUpperCase()
                )
            )
          ]}
          htmlReferences={htmlReferences.current}
          jurisdictions={jurisdictionsById}
          jurSubjurs={jurSubjurs}
          drillDown={showRegions ? drillDown : '0'}
          loadingData={!(jurisdictions && references && references.length > 0)}
          ref={chart}
          mapClicked={() => {
            setMenuOpen(false)
            setRegionSelectOpen(false)
          }}
          mapLoaded
          setMapLoaded={setMapLoaded}
          currentArea={currentArea}
          extraProps={extraProps}
          showLoader={showLoader}
          showTooltip={showTooltip}
          include={include}
          fontRatio={fontRatio}
          editor={editor}
        />
      )}
      {showTitle && !editor && (
        <div className="selectRegion__inputs-container">
          <MapMenu
            variant={menuVariation}
            extraProps={extraProps}
            areaName={areaName}
            title={title}
            subTitle={subTitle}
            subSubTitle={subSubTitle}
            setNewTitle={setNewTitle}
          />
        </div>
      )}
      <div className="mapLegend" ref={htmlReferences}>
        {mapLegendItems &&
          !editor &&
          mapLegendItems.map((item, index) => {
            return !item.noShow ? (
              <div
                className={
                  'mapLegend__item ' +
                  (item.tooltip ? 'cursorQuestion' : '') +
                  ' ' +
                  extraMapLegendItemClass
                }
                key={index + '' + item.name}
                title={item.tooltip ? item.tooltip : ''}
              >
                <span
                  className="stripe"
                  style={{ backgroundColor: item.color }}
                />
                <span className="label">{item.name}</span>
              </div>
            ) : (
              ''
            )
          })}
        {mapLegendGradient && (
          <div className="mapLegend__gradient_container">
            <div className="mapLegendStepGradient__title">
              {mapLegendGradient.title}
            </div>
            <div
              className="mapLegend__gradient"
              style={{
                backgroundImage:
                  'linear-gradient(to right, ' +
                  mapLegendGradient.from +
                  ' , ' +
                  mapLegendGradient.to +
                  ')'
              }}
            />
            <div className="mapLegend__gradient_label_container">
              <div className="mapLegend__gradient_label">0%</div>
              <div className="mapLegend__gradient_label">100%</div>
            </div>
          </div>
        )}
        {mapLegendProtectionGradient && (
          <div className="mapLegendStepGradient">
            <div className="mapLegendStepGradient__title">
              {mapLegendProtectionGradient.title}
            </div>
            <div className="mapLegendStepGradient__steps">
              {// Total number of recommendations
              mapLegendProtectionGradient &&
                mapLegendProtectionGradient.colors &&
                mapLegendProtectionGradient.colors.map((item, index) => {
                  return !item.noShow ? (
                    <div
                      className="mapLegend__item_10"
                      key={index + '' + item.name}
                    >
                      <span
                        className="stripe"
                        style={{ backgroundColor: item }}
                      />
                      <span className="label">
                        {mapLegendProtectionGradient.labels[index]}
                      </span>
                    </div>
                  ) : (
                    ''
                  )
                })}
            </div>
          </div>
        )}
        {mapLegendStepGradient && (
          <div className="mapLegendStepGradient">
            <div className="mapLegendStepGradient__title">
              {mapLegendStepGradient.title}
            </div>
            <div className="mapLegendStepGradient__steps">
              {// Total number of recommendations
              mapLegendStepGradient &&
                mapLegendStepGradient.colors &&
                mapLegendStepGradient.colors.map((item, index) => {
                  return !item.noShow &&
                    (mapLegendStepGradient.labels[0] !==
                      mapLegendStepGradient.labels[
                        mapLegendStepGradient.labels.length - 1
                      ] ||
                      index === 0) ? (
                    <div
                      className="mapLegend__item_10"
                      key={index + '' + item.name}
                    >
                      <span
                        className="stripe"
                        style={{ backgroundColor: item }}
                      />
                      <span className="label">
                        {mapLegendStepGradient.labels[index]}
                      </span>
                    </div>
                  ) : (
                    ''
                  )
                })}
            </div>
          </div>
        )}
      </div>
      {showYearFilter && showBtns && <MapYearFilter />}
      {showMapChartSettings && showBtns && (
        <MapChartSettings
          showLabels={showLabels}
          highQuality={highQuality}
          hideMicrostates={hideMicrostates}
          pacificCenter={pacificCenter}
          setShowLabels={setShowLabels}
          setHighQuality={setHighQuality}
          setHideMicrostates={setHideMicrostates}
          setPacificCenter={setPacificCenter}
          htmlReferences={htmlReferences}
          ref={chart}
          onExportSVG={handleExportSVG}
          onExportPNG={handleExportPNG}
          regional={drillDown !== '0'}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          drilledDown={drilledDown}
          setDrilledDown={setDrilledDown}
        />
      )}
      {showBtns && showRegions && (
        <RegionMapSelect
          showLabels={showLabels}
          highQuality={highQuality}
          hideMicrostates={hideMicrostates}
          pacificCenter={pacificCenter}
          setShowLabels={setShowLabels}
          setHighQuality={setHighQuality}
          setHideMicrostates={setHideMicrostates}
          setPacificCenter={setPacificCenter}
          ref={chart}
          menuOpen={regionSelectOpen}
          setMenuOpen={setRegionSelectOpen}
          setDrillDown={setDrillDown}
          drillDown={drillDown}
        />
      )}
      {showMapInfoModal && showBtns && (
        <MapInfoModal
          title={modalTitle}
          content={modalContent}
          extraClass={!showRegions ? 'noRegions' : ''}
          area={currentArea}
        />
      )}
    </div>
  )
}
