import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const GET_LATEST_UPDATES = gql(`
  query GetLatestUpdates($lang: String,$currentArea: String) {
    latestUpdates(lang: $lang,currentArea: $currentArea) {
      id
      month
      day
      year
      entry_type_id
      comments
      jurisdiction {
        id
        name
        un_member
        a2_code

      }
    }
}
`)

function useGqlLatestUpdates(currentArea) {
  const { i18n } = useTranslation()
  const latestUpdates = useQuery(GET_LATEST_UPDATES, {
    variables: { lang: i18n.language, currentArea }
  })

  return {
    latestUpdates: latestUpdates.data && latestUpdates.data.latestUpdates.slice().sort((a, b) => {
      return (
        new Date(a.year, a.month ? a.month - 1 : 0, a.day ? a.day : 1).getTime() >
        new Date(b.year, b.month ? b.month - 1 : 0, b.day ? b.day : 1).getTime() ? -1 : 1
      )
    })
  }
}

export default useGqlLatestUpdates
