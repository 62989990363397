import { lazy, Suspense, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Modal from 'react-modal'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import './App.css'
import ErrorNotification from './components/ErrorNotification'
import MainSpinner from './components/MainSpinner'
import UserManagement from './components/UserManagement'
import routes from './config/routes'
import { Account } from './context/Accounts'
import ErrorProvider from './context/ErrorProvider'
import ScrollToTop from './lib/ScrollToTop'
/* Needs to be last */
import './Responsive.css'
import Home from './mocks/pages/Home'
import JurProfileArea5 from './mocks/JurProfileAreas/JurProfileArea5'
import LogRocket from 'logrocket'
import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import Sitemap from './pages/Sitemap'

const Auth = lazy(() => import('./admin/Auth'))

Modal.setAppElement('#root')

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{window.location.href}</pre>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

export const history = createBrowserHistory()

function App() {
  const [loggedIn, setLoggedIn] = useState(true)

  useEffect(() => {
    if (process.env.REACT_APP_MAIN_DOMAIN !== 'localhost') {
      LogRocket.init('vxl0xc/ilga-world-database')
    }
  }, [])

  return (
    <Account key={loggedIn} loggedIn={loggedIn} setLoggedIn={setLoggedIn}>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ILGA World Database</title>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <Router history={history}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
            <ErrorProvider>
              <ErrorNotification />
              <ScrollToTop history={history} />
              <UserManagement />

              <Switch>
                {routes().map(route =>
                  route.langPaths ? (
                    [
                      <Route
                        key={'/' + route.langPaths.en}
                        path={'/' + route.langPaths.en}
                        exact={route.exact}
                        component={route.main}
                      />,
                      <Route
                        key={'/' + route.langPaths.es}
                        path={'/' + route.langPaths.es}
                        exact={route.exact}
                        component={route.main}
                      />
                    ]
                  ) : (
                    <Route
                      key={route.path}
                      path={route.path}
                      exact={route.exact}
                      component={route.main}
                    />
                  )
                )}
                <Route
                  key={'/verifyAccount'}
                  path={'/verifyAccount/:code'}
                  exact={true}
                  component={() => <Home verifyAccount={true} />}
                />

<Route
                  key={'/sitemap_gen.xml'}
                  path={'/sitemap_gen.xml'}
                  exact={true}
                  component={() => <Sitemap verifyAccount={true} />}
                />

                <Route
                  key={'/resetPassword'}
                  path={'/resetPassword/:code'}
                  exact={true}
                  component={() => <Home resetPasswordLink={true} />}
                />
                <Route
                  key={'/test'}
                  path={'/test'}
                  exact={true}
                  component={() => (
                    <JurProfileArea5 jur={{ id: 'UN-087', name: 'test' }} />
                  )}
                />
                <Route
                  key={'/admin'}
                  path={'/admin'}
                  exact={true}
                  component={Auth}
                />
                <Redirect to="/en" />
              </Switch>
            </ErrorProvider>
          </ErrorBoundary>
        </Router>
      </HelmetProvider>
    </Account>
  )
}

function TranslatedApp() {
  return (
    <Suspense fallback={<MainSpinner />}>
      <App />
    </Suspense>
  )
}

export default TranslatedApp
