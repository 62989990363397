import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import CustomCheckbox from '../Checkbox'

import './ASTopicsInput.css'
import { tagListSample } from '../../config/a3columns'
import { useTranslation } from 'react-i18next'
import { TagModal } from '../Table/TopicFilter'
import useGqlJurisdictions from '../../../gql/useGqlJurisdictions'
import { advancedSearchTopicsFilter } from '../../../config/constants'

const ASTopicsInput = ({ filters, setFilters }) => {
  const [modalShow, setModalShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [topics, setTopics] = useState([])
  const [useOr, setUseOr] = useState(true)

  const { topics: allTopics } = useGqlJurisdictions()

  const { i18n, t } = useTranslation()
  const modalRef = useRef()
  const [textFilter, setTextFilter] = useState('')

  const handleShow = () => setShowModal(true)

  useEffect(() => {
    setFilters({ ...filters, topics, useOr })
  }, [topics, useOr])

  return (
    <>
      <div className="acp__formControl">
        <label htmlFor="selectTopic">{t('Topics')}</label>
        <button
          name="selectTopic"
          onClick={() => handleShow()}
          className="advancedSearch__areasBtn"
        >
          <span>
            {!filters.topics || !filters.topics.length
              ? t('All Topics')
              : filters.topics.length + ' ' + t('topics selected')}
          </span>
        </button>
      </div>

      <>
        {allTopics && (
          <TagModal
            showModal={showModal}
            setShowModal={setShowModal}
            filterTopics={topics => advancedSearchTopicsFilter(topics)}
            topics={allTopics}
            setTopics={setTopics}
            setUseOr={setUseOr}
            useOr={useOr}
            selected={filters.topics}
            search={true}
            extraClass={'advancedSearch__page'}
          />
        )}
      </>
    </>
  )
}

export default ASTopicsInput
