// range year dropdown
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOnClickOutside } from '../../hooks/clickOutside'

export default function ASDropdownRangeSelect({ filters, setFilters }) {
  // range year dropdown
  const dropdownRangeValues = new Array(new Date().getFullYear() - 1990 + 1)
    .fill(0)
    .map((_, i) => i + 1990)
  const [dropdownRangeFromOpen, setDropdownRangeFromOpen] = useState(false)
  const [dropdownRangeFromSelected, setDropdownRangeFromSelected] = useState(
    'Start'
  )
  const [dropdownRangeToOpen, setDropdownRangeToOpen] = useState(false)

  const dropdownRangeFrom = useRef(null)
  const dropdownRangeTo = useRef(null)

  const [t] = useTranslation()

  const toggleDropdownFromRange = () =>
    setDropdownRangeFromOpen(!dropdownRangeFromOpen)
  const closeDropdownFromRange = () => setDropdownRangeFromOpen(false)
  const toggleDropdownToRange = () =>
    setDropdownRangeToOpen(!dropdownRangeToOpen)
  const closeDropdownToRange = () => setDropdownRangeToOpen(false)
  useOnClickOutside(dropdownRangeFrom, closeDropdownFromRange)
  useOnClickOutside(dropdownRangeTo, closeDropdownToRange)
  return (
    <>
      <div class="acpfc__doubleHeight">
        <span htmlFor="fromYear">{t('From')}</span>
        <div className="purpleDropdown__container" ref={dropdownRangeFrom}>
          <button
            className="purpleDropdown__trigger"
            onClick={toggleDropdownFromRange}
          >
            {filters.date_from}
          </button>
          <ul
            className={`list-unstyled purpleDropdown__items ${
              dropdownRangeFromOpen ? 'open' : ''
            }`}
          >
            <li
              key={-1}
              onClick={() => {
                setFilters({ ...filters, date_from: null })
                setDropdownRangeFromOpen(false)
              }}
              className="purpleDropdown__item"
            >
              {t('No limit')}
            </li>
            {dropdownRangeValues.map(item => (
              <li
                key={item}
                onClick={() => {
                  setFilters({ ...filters, date_from: item })
                  setDropdownRangeFromOpen(false)
                }}
                className="purpleDropdown__item"
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div class="acpfc__doubleHeight">
        <span htmlFor="toYear">{t('To_hasta')}</span>
        <div className="purpleDropdown__container" ref={dropdownRangeTo}>
          <button
            className="purpleDropdown__trigger"
            onClick={toggleDropdownToRange}
          >
            {filters.date_to}
          </button>
          <ul
            className={`list-unstyled purpleDropdown__items ${
              dropdownRangeToOpen ? 'open' : ''
            }`}
          >
            <li
              key={0}
              onClick={() => {
                setFilters({ ...filters, date_to: null })
                setDropdownRangeToOpen(false)
              }}
              className="purpleDropdown__item"
            >
              {t('No limit')}
            </li>
            {dropdownRangeValues.map(item => {
              if (
                (filters.date_from && item > filters.date_from) ||
                !filters.date_from
              ) {
                return (
                  <li
                    key={item}
                    onClick={() => {
                      setFilters({ ...filters, date_to: item })
                      setDropdownRangeToOpen(false)
                    }}
                    className="purpleDropdown__item"
                  >
                    {item}
                  </li>
                )
              } else {
                return null
              }
            })}
          </ul>
        </div>
      </div>
    </>
  )
}
