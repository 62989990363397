import i18n from '../../config/i18n'
import routes from '../../config/routes'
import { citeEntry } from '../../lib/cite'
import { explanSourceLinks } from '../../lib/fnHelper'
import DateDropdownFilter, {
  ApplyDropdownDateFilter
} from '../components/Table/DateDropdownFilter'
import ExpandableTextCell from '../components/Table/ExpandableTextCell'
import GenericDropdownFilter, {
  ApplyGenericDropdownFilter
} from '../components/Table/GenericDropdownFilter'
import JurFilter, { ApplyJurFilter } from '../components/Table/JurFilter'
import SourceText from '../components/Table/SourceText'

export const citeA13 = (entry, setToast) => {
  console.log(entry)
  let lines = [
    '<p>' +
      `${
        entry.motherEntry.subjurisdiction
          ? entry.motherEntry.subjurisdiction.name +
            ' (' +
            entry.motherEntry.jurisdiction.name +
            ')'
          : entry.motherEntry.jurisdiction.name
      }: ${
        routes().find(r => r.parent === 'area1' && r.subareaNumber === 3).name
      }` +
      '</p>'
  ]

  if (
    entry.general_explan &&
    entry.general_explan !== entry.education_explan &&
    entry.general_explan !== entry.education_explan
  )
    lines.push(
      '<p>' +
        entry.entry_foe_barrier_general_value.name +
        ': ' +
        explanSourceLinks(entry.general_explan, entry, 1) +
        '</p>'
    )

  if (entry.education_explan && entry.entry_foe_barrier_education_value)
    lines.push(
      '<p>' +
        entry.entry_foe_barrier_education_value.name +
        ': ' +
        explanSourceLinks(entry.education_explan, entry, 2) +
        '</p>'
    )

  if (entry.media_explan && entry.entry_foe_barrier_media_value)
    lines.push(
      '<p>' +
        entry.entry_foe_barrier_media_value.name +
        ': ' +
        explanSourceLinks(entry.media_explan, entry, 3) +
        '</p>'
    )

  citeEntry(
    routes().find(r => r.parent === 'area1' && r.subareaNumber === 3),
    entry.motherEntry.subjurisdiction
      ? entry.motherEntry.subjurisdiction.name +
          ' (' +
          entry.motherEntry.jurisdiction.name +
          ')'
      : entry.motherEntry.jurisdiction.name,
    lines.join(''),
    setToast
  )
}

const getBgColorClass = (cell, col) => {
  if (!cell.row.original[col]) return ''
  return cell.row.original[col].id === '3'
    ? 'rowProtectionYes'
    : cell.row.original[col].id === '1' || cell.row.original[col].id === '2'
    ? 'rowProtectionNo'
    : cell.row.original[col].id === '6'
    ? 'rowProtectionVaries'
    : 'rowProtectionUnclear'
}

export const getTableEntries = (entry, currentEntry = 0) => {
  if (!entry) return []
  let tmpTableEntries = []
  let is_na =
    entry.entry_foe_barrier_general_value &&
    entry.entry_foe_barrier_general_value.id === '4' &&
    !entry.general_explan

  let subRows = []

  let cid0 = currentEntry || tmpTableEntries.length

  if (entry.entry_foe_barrier_education_value) {
    subRows.push({
      ...entry,
      cid: cid0 + '.' + subRows.length,
      entry_foe_barrier_general_value: entry.entry_foe_barrier_education_value,
      general_mechanism: entry.education_mechanism,
      general_critical_date_1: entry.education_critical_date_1,
      general_critical_date_2: entry.education_critical_date_2,
      general_explan: entry.education_explan,
      section: 2
    })
  }
  if (entry.entry_foe_barrier_media_value) {
    subRows.push({
      ...entry,
      cid: cid0 + '.' + subRows.length,
      entry_foe_barrier_general_value: entry.entry_foe_barrier_media_value,
      general_mechanism: entry.media_mechanism,
      general_critical_date_1: entry.media_critical_date_1,
      general_critical_date_2: entry.media_critical_date_2,
      general_explan: entry.media_explan,
      section: 3
    })
  }
  if (!is_na || !subRows.length) {
    tmpTableEntries.push({
      ...entry,
      subRowsLength: subRows.length
    })
    tmpTableEntries = tmpTableEntries.concat(subRows)
  } else {
    //Hack to prevent showing the N/A
    if (subRows.length) {
      subRows[0] = {
        ...subRows[0],
        cid: subRows[0].cid.split('.')[0],
        subRowsLength: subRows.length - 1
      }
      delete subRows[0].cid

      tmpTableEntries = tmpTableEntries.concat(subRows)
    }
  }
  return tmpTableEntries
}

export const columns = () => [
  {
    Header: i18n.t('Jurisdiction'),
    disableGroupBy: true,
    accessor: 'motherEntry.jurisdiction.name',
    enableRowSpan: true,
    filter: ApplyJurFilter,
    Filter: ({ column }) => {
      return <JurFilter column={column} search={true} />
    },
    Cell: ({ value, row }) => {
      return (
        <div
          className={
            'jurCell ' +
            (row.original.motherEntry.jurisdiction.id.indexOf('DT') !== -1 ||
            row.original.motherEntry.subjurisdiction
              ? 'dependentTerritory'
              : '')
          }
        >
          <div>
            <img
              className="tableFlag"
              src={
                '/images/flags_svg/' +
                row.original.motherEntry.jurisdiction.a2_code.toLowerCase() +
                '.svg'
              }
              height="16px"
            />
          </div>
          <div>
            {!row.original.motherEntry.subjurisdiction &&
              row.original.motherEntry.jurisdiction.name}
            {row.original.motherEntry.subjurisdiction &&
              '' + row.original.motherEntry.subjurisdiction.name + ''}
          </div>
        </div>
      )
    },
    CellSmall: ({ value, row }) => {
      return (
        <div>{row.original.motherEntry.jurisdiction.name.substring(0, 3)}</div>
      )
    },
    style: { minWidth: '100px' }
  },
  {
    Header: i18n.t('Legal Barriers'),
    accessor: 'entry_foe_barrier_general_value.name',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          width={i18n.language === 'en' ? '330px' : '410px'}
          column={column}
          options={[
            {
              id: i18n.t('No Known Legal Barriers'),
              value: i18n.t('No Known Legal Barriers')
            },
            {
              id: i18n.t('General Legal Barriers (Non-Explicit)'),
              value: i18n.t('General Legal Barriers (Non-Explicit)')
            },
            {
              id: i18n.t('General Legal Barriers (Explicit)'),
              value: i18n.t('General Legal Barriers (Explicit)')
            },
            {
              id: i18n.t('Legal Barriers in Education (Non-Explicit)'),
              value: i18n.t('Legal Barriers in Education (Non-Explicit)')
            },
            {
              id: i18n.t('Legal Barriers in Education (Explicit)'),
              value: i18n.t('Legal Barriers in Education (Explicit)')
            },
            {
              id: i18n.t('Legal Barriers in the Media (Non-Explicit)'),
              value: i18n.t('Legal Barriers in the Media (Non-Explicit)')
            },
            {
              id: i18n.t('Legal Barriers in the Media (Explicit)'),
              value: i18n.t('Legal Barriers in the Media (Explicit)')
            },
            { id: i18n.t('Varies'), value: i18n.t('Varies') },
            { id: i18n.t('Unclear'), value: i18n.t('Unclear') }
          ]}
        />
      )
    },
    classNameFn: c => getBgColorClass(c, 'entry_foe_barrier_general_value'),
    className: 'textCenter col125 flexTd',
    style: { minWidth: '200px' },

    sortType: (rowA, rowB, id, desc) => {
      if (
        rowA.original.entry_foe_barrier_general_value.name >
        rowB.original.entry_foe_barrier_general_value.name
      )
        return -1
      if (
        rowB.original.entry_foe_barrier_general_value.name >
        rowA.original.entry_foe_barrier_general_value.name
      )
        return 1
      return 0
    }
  },

  {
    Header: i18n.t('Critical Date'),
    accessor: 'general_critical_date_1',
    sortDescFirst: true,
    filter: (rows, columnsId, filterValue) =>
      ApplyDropdownDateFilter(rows, ['general_critical_date_1'], filterValue),
    Filter: ({ column }) => {
      return <DateDropdownFilter column={column} />
    },
    Cell: ({ value, row }) => {
      if (value)
        return (
          value +
          (row.original.general_critical_date_2
            ? ' - ' + row.original.general_critical_date_2
            : '')
        )
      else return '-'
    },
    className: 'textCenter col80'
  },
  {
    Header: i18n.t('Mechanism'),
    accessor: 'general_mechanism.name',
    className: 'textCenter col125',
    filter: ApplyGenericDropdownFilter,
    Filter: ({ column }) => {
      return (
        <GenericDropdownFilter
          column={column}
          options={[
            { id: i18n.t('Legislative'), value: i18n.t('Legislative') },
            { id: i18n.t('Executive'), value: i18n.t('Executive') },
            { id: i18n.t('Judicial'), value: i18n.t('Judicial') }
          ]}
        />
      )
    }
  },
  {
    Header: i18n.t('Explanation'),
    accessor: 'general_explan',
    Cell: ({ value, row }) => {
      if (value)
        return (
          <ExpandableTextCell
            value={value}
            row={row}
            sources={row.original.motherEntry.sources}
            section={row.original.section ? row.original.section : 1}
            unik={row.original.id + '_explan'}
            footnotes={row.original.motherEntry.footnotes}
          />
        )
      else return <></>
    },
    NonExpandableCell: ({ value, row }) => {
      return (
        <SourceText
          text={value}
          section={row.original.section ? row.original.section : 1}
          sources={row.original.motherEntry.sources}
          unik={row.original.id + '_explan'}
        />
      )
    },
    disableSortBy: true,
    style: { minWidth: '300px' },
    sortType: (rowA, rowB, id, desc) => {
      if (!rowB.original.general_explan) return null
      if (
        rowA.original.general_explan.replace(/[^\w\s]/gi, '') >
        rowB.original.general_explan.replace(/[^\w\s]/gi, '')
      )
        return -1
      if (
        rowB.original.general_explan.replace(/[^\w\s]/gi, '') >
        rowA.original.general_explan.replace(/[^\w\s]/gi, '')
      )
        return 1
      return 0
    }
  }
]
