import { Accordion } from 'react-bootstrap'
import React, { useEffect, useRef, useState } from 'react'
import useGqlEntriesCsssa from '../../gql/useGqlEntriesCsssa'
import useGqlEntriesFoe from '../../gql/useGqlEntriesFoe'
import useGqlEntriesFoa from '../../gql/useGqlEntriesFoa'
import useGqlEntriesProtection from '../../gql/useGqlEntriesProtection'
import useGqlEntriesCt from '../../gql/useGqlEntriesCt'
import useGqlEntriesSsu from '../../gql/useGqlEntriesSsu'
import useGqlEntriesAdo from '../../gql/useGqlEntriesAdo'
import useGqlEntriesPnc from '../../gql/useGqlEntriesPnc'
import useGqlEntriesLgr from '../../gql/useGqlEntriesLgr'
import { history } from '../../App.js'
import { useTranslation } from 'react-i18next'
import { citeA11, getColumnsA11 } from '../config/columns'
import {
  citeA13,
  columns as a13columns,
  getTableEntries
} from '../config/a13columns'
import { citeA14, columns as a14columns } from '../config/a14columns'
import { citeA15, columns as a15columns } from '../config/a15columns'
import { citeA114, columns as a114columns } from '../config/a114columns'
import SimpleTable from '../components/Table/SimpleTable'
import routes, { getPath } from '../../config/routes'
import { a115columns, citeA115 } from '../config/a115columns'
import { a116columns, citeA116 } from '../config/a116columns'
import { a117columns, citeA117 } from '../config/a117columns'
import { a118_nc_columns } from '../config/a118_nc_columns'
import { a118_gm_columns, citeA118 } from '../config/a118_gm_columns'
import {
  cCharacteristics,
  cDependentTerritories,
  cProtectionCategories
} from '../../config/constants'
import { NoData } from '../texts/jur/NoData'

import CiteIcon from '../components/icons/CiteIcon'
import { useContext } from 'react'
import { AccountContext } from '../../context/Accounts'
import ReportIcon from '../components/icons/ReportIcon'
import NoteCheckIcon from '../components/icons/NoteCheckIcon'
import NoteRemoveIcon from '../components/icons/NoteRemoveIcon'
import SubjurChart from './SubjurChart'
import SubjurButtons from './SubjurButtons'
import SubjurList from './SubjurList'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import SubjurProtection from './Area1/SubjurProtection'
import SubJurChartSsu from './SubjurChartSsu'
import SubjurProfileAccordion from '../components/JurProfile/SubjurProfileAccordion'

export default function JurProfileArea1({
  jur,
  hasSubJurs,
  setReportErrorOpen,
  subjurSlug
}) {
  const [t, i18n] = useTranslation()
  // same page links code
  const section1 = useRef(null)
  const section2 = useRef(null)
  const section3 = useRef(null)
  const section4 = useRef(null)
  const section5 = useRef(null)
  const section14 = useRef(null)
  const section15 = useRef(null)
  const section16 = useRef(null)
  const section17 = useRef(null)
  const section18 = useRef(null)

  const [displayingSubjurs, setDisplayingSubjurs] = useState([])
  const [displayingDeps, setDisplayingDeps] = useState([])

  const protectionRef = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ]

  const { setToast } = useContext(AccountContext)

  const [isSubjur, setIsSubjur] = useState(false)

  const { jurSubjurs } = useGqlJurisdictions(jur ? jur.id : null)

  const { entriesCsssa, loading, error } = useGqlEntriesCsssa()
  const { entriesFoe } = useGqlEntriesFoe()
  const { entriesFoa } = useGqlEntriesFoa()
  const { entriesProtection: entriesProtection15 } = useGqlEntriesProtection(
    'A1-5',
    jur.id
  )
  const { entriesProtection: entriesProtection16 } = useGqlEntriesProtection(
    'A1-6',
    jur.id
  )
  const { entriesProtection: entriesProtection110 } = useGqlEntriesProtection(
    'A1-10',
    jur.id
  )
  const { entriesProtection } = useGqlEntriesProtection('ALL', jur.id)
  const { entriesCt } = useGqlEntriesCt()
  const { entriesSsu } = useGqlEntriesSsu()
  const { entriesAdo } = useGqlEntriesAdo()
  const { entriesPnc } = useGqlEntriesPnc()
  const { entriesLgr } = useGqlEntriesLgr()

  const [currentEntry, setCurrentEntry] = useState({})

  const [eCsssa, setECsssa] = useState(null)
  const [eFoe, setEFoe] = useState(null)
  const [eFoa, setEFoa] = useState(null)
  const [eProtection, setEProtection] = useState(null)
  const [eCt, setECt] = useState(null)

  const [eSsu, setESsu] = useState(null)
  const [eAdo, setEAdo] = useState(null)
  const [ePnc, setEPnc] = useState(null)

  const [eLgr, setELgr] = useState(null)

  const protectionTypes = [5, 6, 7, 8, 9, 10, 11, 12, 13]
  const protectionCategories = ['SO', 'GI', 'GE', 'SC']

  const defaultShowSub = subjurSlug

  const getSubDep = entries => {
    return {
      showSub: defaultShowSub || jur.id === 'UN-184',
      isSubjurProfile: subjurSlug,
      showDep: defaultShowSub, //!subjurSlug,
      sub: entries
        .filter(
          e =>
            jur.id === e.motherEntry.jurisdiction.id &&
            e.motherEntry.subjurisdiction
        )
        .filter(
          e => !subjurSlug || e.motherEntry.subjurisdiction.slug === subjurSlug
        )
        .sort((a, b) =>
          a.motherEntry.subjurisdiction.name <
          b.motherEntry.subjurisdiction.name
            ? -1
            : a.motherEntry.subjurisdiction.name >
              b.motherEntry.subjurisdiction.name
            ? 1
            : 0
        ),
      subObj: entries
        .filter(
          e =>
            jur.id === e.motherEntry.jurisdiction.id &&
            e.motherEntry.subjurisdiction
        )
        .filter(
          e => !subjurSlug || e.motherEntry.subjurisdiction.slug === subjurSlug
        )[0],
      dep: entries
        .filter(
          e =>
            cDependentTerritories[jur.id] &&
            cDependentTerritories[jur.id].indexOf(
              e.motherEntry.jurisdiction.id
            ) !== -1
        )
        .sort((a, b) =>
          a.motherEntry.jurisdiction.name < b.motherEntry.jurisdiction.name
            ? -1
            : a.motherEntry.jurisdiction.name > b.motherEntry.jurisdiction.name
            ? 1
            : 0
        )
    }
  }

  useEffect(() => {
    if (entriesCsssa && entriesCsssa.length && jurSubjurs) {
      let entries = {
        main: entriesCsssa.filter(
          e =>
            jur.id === e.motherEntry.jurisdiction.id &&
            !e.motherEntry.subjurisdiction
        )[0],
        ...getSubDep(entriesCsssa)
      }

      setECsssa({
        ...entries,
        n_sub: entries.sub.filter(e => !e.legal).length,
        n_subptotal: jurSubjurs.reduce((o, b) => o + b.current_population, 0),
        n_subp: jurSubjurs
          .filter(
            s =>
              entriesCsssa
                .filter(
                  e =>
                    jur.id === e.motherEntry.jurisdiction.id &&
                    e.motherEntry.subjurisdiction &&
                    !e.legal
                )
                .map(e => e.motherEntry.subjurisdiction.id)
                .indexOf(s.id) !== -1
          )
          .reduce((o, b) => o + b.current_population, 0)
      })
    }
  }, [entriesCsssa, jurSubjurs])

  useEffect(() => {
    if (entriesFoe && entriesFoe.length && jurSubjurs) {
      let entries = {
        main: entriesFoe.filter(
          e =>
            jur.id === e.motherEntry.jurisdiction.id &&
            !e.motherEntry.subjurisdiction
        )[0],
        expanded: getTableEntries(
          entriesFoe.filter(
            e =>
              jur.id === e.motherEntry.jurisdiction.id &&
              !e.motherEntry.subjurisdiction
          )[0]
        ),
        ...getSubDep(entriesFoe)
      }
      setEFoe({
        ...entries,
        n_sub: entries.sub.filter(
          e =>
            (e.entry_foe_barrier_general_value &&
              (e.entry_foe_barrier_general_value.id === '1' ||
                e.entry_foe_barrier_general_value.id === '2')) ||
            (e.entry_foe_barrier_education_value &&
              (e.entry_foe_barrier_education_value.id === '1' ||
                e.entry_foe_barrier_education_value.id === '2')) ||
            (e.entry_foe_barrier_media_value &&
              (e.entry_foe_barrier_media_value.id === '1' ||
                e.entry_foe_barrier_media_value.id === '2'))
        ).length,
        n_subptotal: jurSubjurs.reduce((o, b) => o + b.current_population, 0),
        n_subp: jurSubjurs
          .filter(
            s =>
              entriesFoe
                .filter(
                  e =>
                    jur.id === e.motherEntry.jurisdiction.id &&
                    e.motherEntry.subjurisdiction &&
                    ((e.entry_foe_barrier_general_value &&
                      (e.entry_foe_barrier_general_value.id === '1' ||
                        e.entry_foe_barrier_general_value.id === '2')) ||
                      (e.entry_foe_barrier_education_value &&
                        (e.entry_foe_barrier_education_value.id === '1' ||
                          e.entry_foe_barrier_education_value.id === '2')) ||
                      (e.entry_foe_barrier_media_value &&
                        (e.entry_foe_barrier_media_value.id === '1' ||
                          e.entry_foe_barrier_media_value.id === '2')))
                )
                .map(e => e.motherEntry.subjurisdiction.id)
                .indexOf(s.id) !== -1
          )
          .reduce((o, b) => o + b.current_population, 0)
      })
    }
  }, [entriesFoe, jurSubjurs])

  useEffect(() => {
    if (entriesFoa && entriesFoa.length && jurSubjurs) {
      setEFoa({
        main: entriesFoa.filter(
          e =>
            jur.id === e.motherEntry.jurisdiction.id &&
            !e.motherEntry.subjurisdiction
        )[0],
        ...getSubDep(entriesFoa)
      })
    }
  }, [entriesFoa, jurSubjurs])

  useEffect(() => {
    if (entriesProtection && entriesProtection.length && jurSubjurs) {
      let byType = {}

      let subjurProtectionYes = []
      for (let a of protectionTypes) {
        byType[a] = {
          main: entriesProtection.filter(
            e =>
              jur.id === e.motherEntry.jurisdiction.id &&
              !e.motherEntry.subjurisdiction &&
              e.motherEntry.entry_type_id === 'A1-' + a
          )[0],
          ...getSubDep(
            entriesProtection.filter(
              e =>
                jur.id === e.motherEntry.jurisdiction.id &&
                e.motherEntry.subjurisdiction &&
                e.motherEntry.entry_type_id === 'A1-' + a
            )
          ),
          n_sub: ['so', 'gi', 'ge', 'sc'].reduce(
            (o, c) => ({
              ...o,
              [c]: entriesProtection.filter(
                e =>
                  jur.id === e.motherEntry.jurisdiction.id &&
                  e.motherEntry.subjurisdiction &&
                  e.motherEntry.entry_type_id === 'A1-' + a &&
                  e[c + '_protection_type'] &&
                  e[c + '_protection_type'].id === '1'
              ).length
            }),
            {}
          ),
          n_subptotal: jurSubjurs.reduce((o, b) => o + b.current_population, 0),
          n_subp: ['so', 'gi', 'ge', 'sc'].reduce(
            (o, c) => ({
              ...o,
              [c]: jurSubjurs
                .filter(
                  s =>
                    entriesProtection
                      .filter(
                        e =>
                          jur.id === e.motherEntry.jurisdiction.id &&
                          e.motherEntry.subjurisdiction &&
                          e.motherEntry.entry_type_id === 'A1-' + a &&
                          e[c + '_protection_type'] &&
                          e[c + '_protection_type'].id === '1'
                      )
                      .map(e => e.motherEntry.subjurisdiction.id)
                      .indexOf(s.id) !== -1
                )
                .reduce((o, b) => o + b.current_population, 0)
            }),
            {}
          )
        }
      }
      setEProtection(byType)
    }
  }, [entriesProtection, jurSubjurs])

  useEffect(() => {
    if (
      entriesCsssa &&
      entriesCsssa.length &&
      !currentEntry.entriesCsssa &&
      entriesFoe &&
      entriesFoe.length &&
      !eFoe &&
      entriesFoa &&
      entriesFoa.length &&
      !eFoa &&
      entriesProtection15 &&
      entriesProtection15.length &&
      !currentEntry.entriesProtection15 &&
      entriesProtection110 &&
      entriesProtection110.length &&
      !currentEntry.entriesProtection110
    ) {
      setCurrentEntry({
        ...currentEntry,

        entriesFoe: {
          main: entriesFoe.filter(
            e =>
              jur.id === e.motherEntry.jurisdiction.id &&
              !e.motherEntry.subjurisdiction
          )[0],
          ...getSubDep(entriesFoe)
        },
        entriesFoa: {
          main: entriesFoa.filter(
            e =>
              jur.id === e.motherEntry.jurisdiction.id &&
              !e.motherEntry.subjurisdiction
          )[0],
          ...getSubDep(entriesFoa)
        },
        entriesProtection15: {
          main: entriesProtection15.filter(
            e =>
              jur.id === e.motherEntry.jurisdiction.id &&
              !e.motherEntry.subjurisdiction
          )[0],
          ...getSubDep(entriesProtection15)
        },
        entriesProtection110: {
          main: entriesProtection110.filter(
            e =>
              jur.id === e.motherEntry.jurisdiction.id &&
              !e.motherEntry.subjurisdiction
          )[0],
          ...getSubDep(entriesProtection110)
        }
      })
    }
  }, [
    entriesCsssa,
    entriesFoe,
    entriesFoa,
    entriesProtection15,
    entriesProtection110
  ])

  useEffect(() => {
    if (entriesCt && entriesCt.length && jurSubjurs) {
      let entries = {
        main: entriesCt.filter(
          e =>
            jur.id === e.motherEntry.jurisdiction.id &&
            !e.motherEntry.subjurisdiction
        )[0],
        ...getSubDep(entriesCt)
      }

      setECt({
        ...entries,
        n_sub: entries.sub.filter(
          e => e.general_ban_type && e.general_ban_type.id === '1'
        ).length,
        n_subptotal: jurSubjurs.reduce((o, b) => o + b.current_population, 0),
        n_subp: jurSubjurs
          .filter(
            s =>
              entriesCt
                .filter(
                  e =>
                    jur.id === e.motherEntry.jurisdiction.id &&
                    e.motherEntry.subjurisdiction &&
                    e.general_ban_type.id &&
                    e.general_ban_type.id === '1'
                )
                .map(e => e.motherEntry.subjurisdiction.id)
                .indexOf(s.id) !== -1
          )
          .reduce((o, b) => o + b.current_population, 0)
      })
    }
  }, [entriesCt, jurSubjurs])

  useEffect(() => {
    if (entriesSsu && entriesSsu.length && jurSubjurs) {
      let entries = {
        main: entriesSsu.filter(
          e =>
            jur.id === e.motherEntry.jurisdiction.id &&
            !e.motherEntry.subjurisdiction
        )[0],
        ...getSubDep(entriesSsu)
      }
      setESsu({
        ...entries,
        n_subs: entries.sub.filter(
          e => e.marriage_type && e.marriage_type.id === '1'
        ).length,
        n_subsp: jurSubjurs
          .filter(
            s =>
              entriesSsu
                .filter(
                  e =>
                    jur.id === e.motherEntry.jurisdiction.id &&
                    e.motherEntry.subjurisdiction &&
                    e.marriage_type &&
                    e.marriage_type.id === '1'
                )
                .map(e => e.motherEntry.subjurisdiction.id)
                .indexOf(s.id) !== -1
          )
          .reduce((o, b) => o + b.current_population, 0),
        n_subc: entries.sub.filter(e => e.civil_type && e.civil_type.id === '1')
          .length,
        n_subcp: jurSubjurs
          .filter(
            s =>
              entriesSsu
                .filter(
                  e =>
                    jur.id === e.motherEntry.jurisdiction.id &&
                    e.motherEntry.subjurisdiction &&
                    e.civil_type &&
                    e.civil_type.id === '1'
                )
                .map(e => e.motherEntry.subjurisdiction.id)
                .indexOf(s.id) !== -1
          )
          .reduce((o, b) => o + b.current_population, 0),
        n_subptotal: jurSubjurs.reduce((o, b) => o + b.current_population, 0)
      })
    }
  }, [entriesSsu, jurSubjurs])

  useEffect(() => {
    if (entriesAdo && entriesAdo.length && jurSubjurs) {
      setEAdo({
        main: entriesAdo.filter(
          e =>
            jur.id === e.motherEntry.jurisdiction.id &&
            !e.motherEntry.subjurisdiction
        )[0],
        ...getSubDep(entriesAdo)
      })
    }
  }, [entriesAdo, jurSubjurs])

  useEffect(() => {
    if (entriesPnc && entriesPnc.length && jurSubjurs) {
      let entries = {
        main: entriesPnc.filter(
          e =>
            jur.id === e.motherEntry.jurisdiction.id &&
            !e.motherEntry.subjurisdiction
        )[0],
        ...getSubDep(entriesPnc)
      }
      setEPnc({
        ...entries,
        n_sub: entries.sub.filter(e => e.pnc_type && e.pnc_type.id === '2')
          .length,
        n_subptotal: jurSubjurs.reduce((o, b) => o + b.current_population, 0),
        n_subp: jurSubjurs
          .filter(
            s =>
              entriesPnc
                .filter(
                  e =>
                    jur.id === e.motherEntry.jurisdiction.id &&
                    e.motherEntry.subjurisdiction &&
                    e.pnc_type &&
                    e.pnc_type.id === '1'
                )
                .map(e => e.motherEntry.subjurisdiction.id)
                .indexOf(s.id) !== -1
          )
          .reduce((o, b) => o + b.current_population, 0)
      })
    }
  }, [entriesPnc, jurSubjurs])

  useEffect(() => {
    if (entriesLgr && entriesLgr.length && jurSubjurs) {
      let entries = {
        main: entriesLgr.filter(
          e =>
            jur.id === e.motherEntry.jurisdiction.id &&
            !e.motherEntry.subjurisdiction
        )[0],
        ...getSubDep(entriesLgr)
      }
      setELgr({
        ...entries,
        n_sub: entries.sub.filter(
          e => e.name_change_lgr_type && e.name_change_lgr_type.id === '1'
        ).length,
        n_sub_gm: entries.sub.filter(
          e => e.gender_marker_lgr_type && e.gender_marker_lgr_type.id === '1'
        ).length,
        n_subptotal: jurSubjurs.reduce((o, b) => o + b.current_population, 0),
        n_subp: jurSubjurs
          .filter(
            s =>
              entriesLgr
                .filter(
                  e =>
                    jur.id === e.motherEntry.jurisdiction.id &&
                    e.motherEntry.subjurisdiction &&
                    e.name_change_lgr_type &&
                    e.name_change_lgr_type.id === '1'
                )
                .map(e => e.motherEntry.subjurisdiction.id)
                .indexOf(s.id) !== -1
          )
          .reduce((o, b) => o + b.current_population, 0),
        n_subp_gm: jurSubjurs
          .filter(
            s =>
              entriesLgr
                .filter(
                  e =>
                    jur.id === e.motherEntry.jurisdiction.id &&
                    e.motherEntry.subjurisdiction &&
                    e.gender_marker_lgr_type &&
                    e.gender_marker_lgr_type.id === '1'
                )
                .map(e => e.motherEntry.subjurisdiction.id)
                .indexOf(s.id) !== -1
          )
          .reduce((o, b) => o + b.current_population, 0)
      })
    }
  }, [entriesLgr, jurSubjurs])

  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)

  const LoadingBox = () => <p className="jps__status" />

  const theRoutes = routes()

  return (
    <>
      <div className="content__container">
        <section className="JurProfileSummary__section">
          <div className="jps__header">
            <h4 className="jps__title">{t('Summary')}</h4>
            {isSubjur && (
              <button className="jps__button btn btn-link">
                <img
                  className="jpsb__countryFlag"
                  src={
                    '/images/flags_svg/' + jur.a2_code.toLowerCase() + '.svg'
                  }
                  alt="Country Flag"
                />
                {jur.name}
              </button>
            )}
          </div>
          <div className="jps__body">
            <div className="jps__summaryBoxes">
              <div
                className={
                  'jps__sb sb-' +
                  (eCsssa &&
                  eCsssa[
                    eCsssa.isSubjurProfile && eCsssa.subObj ? 'subObj' : 'main'
                  ]
                    ? eCsssa[
                        eCsssa.isSubjurProfile && eCsssa.subObj
                          ? 'subObj'
                          : 'main'
                      ].legal
                      ? 'white'
                      : 'dark'
                    : 'gray')
                }
                onClick={() => scrollToRef(section1)}
              >
                <p className="jps__header">
                  <span>1.</span>{' '}
                  {t('Criminalisation of consensual same-sex sexual acts')}
                </p>
                {eCsssa &&
                eCsssa[
                  eCsssa.isSubjurProfile && eCsssa.subObj ? 'subObj' : 'main'
                ] ? (
                  <>
                    {eCsssa[
                      eCsssa.isSubjurProfile && eCsssa.subObj
                        ? 'subObj'
                        : 'main'
                    ].legal ? (
                      <p className="jps__status green-text">{t('LEGAL')}</p>
                    ) : (
                      <p className="jps__status illegal yellow-text">
                        {t('ILLEGAL')}
                      </p>
                    )}
                    <p className="jps__statusSet">
                      {eCsssa[
                        eCsssa.isSubjurProfile && eCsssa.subObj
                          ? 'subObj'
                          : 'main'
                      ].legal
                        ? t('Since')
                        : t('Last known amendment')}{' '}
                      {eCsssa[
                        eCsssa.isSubjurProfile && eCsssa.subObj
                          ? 'subObj'
                          : 'main'
                      ].legal
                        ? eCsssa[
                            eCsssa.isSubjurProfile && eCsssa.subObj
                              ? 'subObj'
                              : 'main'
                          ].decrim_date_1
                        : eCsssa[
                            eCsssa.isSubjurProfile && eCsssa.subObj
                              ? 'subObj'
                              : 'main'
                          ].illegal_since
                        ? eCsssa[
                            eCsssa.isSubjurProfile && eCsssa.subObj
                              ? 'subObj'
                              : 'main'
                          ].illegal_since
                        : eCsssa[
                            eCsssa.isSubjurProfile && eCsssa.subObj
                              ? 'subObj'
                              : 'main'
                          ].last_amendment_1
                        ? eCsssa[
                            eCsssa.isSubjurProfile && eCsssa.subObj
                              ? 'subObj'
                              : 'main'
                          ].last_amendment_1
                        : ''}
                    </p>
                  </>
                ) : (
                  <LoadingBox />
                )}
              </div>

              <div
                className={
                  'jps__sb sb-' +
                  (eFoe &&
                  eFoe[eFoe.isSubjurProfile && eFoe.subObj ? 'subObj' : 'main']
                    ? eFoe[
                        eFoe.isSubjurProfile && eFoe.subObj ? 'subObj' : 'main'
                      ].entry_foe_barrier_summary_value.id === '3'
                      ? 'white'
                      : 'dark'
                    : 'gray')
                }
                onClick={() => scrollToRef(section3)}
              >
                <p className="jps__header">
                  <span>2.</span> {t('Restrictions to freedom of expression')}
                </p>
                {eFoe &&
                eFoe[
                  eFoe.isSubjurProfile && eFoe.subObj ? 'subObj' : 'main'
                ] ? (
                  <>
                    {eFoe[
                      eFoe.isSubjurProfile && eFoe.subObj ? 'subObj' : 'main'
                    ].entry_foe_barrier_summary_value.id === '3' ? (
                      <p className="jps__status green-text">
                        {eFoe[
                          eFoe.isSubjurProfile && eFoe.subObj
                            ? 'subObj'
                            : 'main'
                        ].entry_foe_barrier_summary_value.name.toUpperCase()}
                      </p>
                    ) : (
                      <p className="jps__status yellow-text">
                        {eFoe[
                          eFoe.isSubjurProfile && eFoe.subObj
                            ? 'subObj'
                            : 'main'
                        ].entry_foe_barrier_summary_value.name.toUpperCase()}
                      </p>
                    )}

                    <p className="jps__statusSet">
                      {eFoe[
                        eFoe.isSubjurProfile && eFoe.subObj ? 'subObj' : 'main'
                      ].general_critical_date_1
                        ? eFoe[
                            eFoe.isSubjurProfile && eFoe.subObj
                              ? 'subObj'
                              : 'main'
                          ].general_critical_date_1
                        : ''}
                    </p>
                  </>
                ) : (
                  <LoadingBox />
                )}
              </div>

              <div
                className={
                  'jps__sb sb-' +
                  (eFoa &&
                  eFoa[eFoa.isSubjurProfile && eFoa.subObj ? 'subObj' : 'main']
                    ? eFoa[
                        eFoa.isSubjurProfile && eFoa.subObj ? 'subObj' : 'main'
                      ].barrier_value.id === '4'
                      ? 'white'
                      : 'dark'
                    : 'gray')
                }
                onClick={() => scrollToRef(section4)}
              >
                <p className="jps__header">
                  <span>3.</span> {t('Restrictions to freedom of association')}
                </p>
                {eFoa &&
                eFoa[
                  eFoa.isSubjurProfile && eFoa.subObj ? 'subObj' : 'main'
                ] ? (
                  <>
                    {eFoa[
                      eFoa.isSubjurProfile && eFoa.subObj ? 'subObj' : 'main'
                    ].barrier_value.id === '4' ? (
                      <p className="jps__status green-text">
                        {eFoa[
                          eFoa.isSubjurProfile && eFoa.subObj
                            ? 'subObj'
                            : 'main'
                        ].barrier_value.name.toUpperCase()}
                      </p>
                    ) : (
                      <p className="jps__status yellow-text">
                        {eFoa[
                          eFoa.isSubjurProfile && eFoa.subObj
                            ? 'subObj'
                            : 'main'
                        ].barrier_value.name.toUpperCase()}
                      </p>
                    )}
                    <p className="jps__statusSet">
                      {eFoa[
                        eFoa.isSubjurProfile && eFoa.subObj ? 'subObj' : 'main'
                      ].critical_date_1
                        ? eFoa[
                            eFoa.isSubjurProfile && eFoa.subObj
                              ? 'subObj'
                              : 'main'
                          ].critical_date_1
                        : ''}
                    </p>
                  </>
                ) : (
                  <LoadingBox />
                )}
              </div>
            </div>

            <div className="jps__discriminationSection">
              <h6 className="jpsd__title">
                {t('Protection against discrimination')}{' '}
                <span className="longLine" />
              </h6>
              <div className="jpsd__boxContainer">
                {protectionTypes.map((pt, index) =>
                  pt !== 9 ? (
                    <div
                      className="jpsd__box"
                      key={index}
                      onClick={() => scrollToRef(protectionRef[index])}
                    >
                      <div className="jpsd__boxHeader">
                        <p className="jpsd__boxTitle">
                          <span>{pt - 1}.</span>{' '}
                          {
                            theRoutes.find(r => r.subareaNumber === pt)
                              .short_name
                          }
                        </p>
                      </div>
                      <div className="jpsd__boxBody">
                        {protectionCategories.map((cat, i) =>
                          eProtection &&
                          eProtection[pt] &&
                          eProtection[pt][
                            eProtection[pt].isSubjurProfile &&
                            eProtection[pt].subObj
                              ? 'subObj'
                              : 'main'
                          ] &&
                          eProtection[pt][
                            eProtection[pt].isSubjurProfile &&
                            eProtection[pt].subObj
                              ? 'subObj'
                              : 'main'
                          ][cat.toLowerCase() + '_protection_type'] ? (
                            <div className="jpsd__stripeColumn" key={i}>
                              <div className="jpsdsc__name">{t(cat)}</div>
                              <div
                                className={
                                  'jpsdsc__bar ' +
                                  (eProtection[pt][
                                    eProtection[pt].isSubjurProfile &&
                                    eProtection[pt].subObj
                                      ? 'subObj'
                                      : 'main'
                                  ][cat.toLowerCase() + '_protection_type']
                                    .id === '1'
                                    ? 'green'
                                    : eProtection[pt][
                                        eProtection[pt].isSubjurProfile &&
                                        eProtection[pt].subObj
                                          ? 'subObj'
                                          : 'main'
                                      ][cat.toLowerCase() + '_protection_type']
                                        .id === '2'
                                    ? 'yellow'
                                    : 'gray')
                                }
                              />
                              <div
                                className={
                                  'jpsdsc__status ' +
                                  (eProtection[pt][
                                    eProtection[pt].isSubjurProfile &&
                                    eProtection[pt].subObj
                                      ? 'subObj'
                                      : 'main'
                                  ][cat.toLowerCase() + '_protection_type']
                                    .id === '1'
                                    ? 'jpsdsc__status_bold'
                                    : '')
                                }
                              >
                                {
                                  eProtection[pt][
                                    eProtection[pt].isSubjurProfile &&
                                    eProtection[pt].subObj
                                      ? 'subObj'
                                      : 'main'
                                  ][cat.toLowerCase() + '_protection_type'].name
                                }{' '}
                                {eProtection[pt][
                                  eProtection[pt].isSubjurProfile &&
                                  eProtection[pt].subObj
                                    ? 'subObj'
                                    : 'main'
                                ][cat.toLowerCase() + '_critical_date_1']
                                  ? eProtection[pt][
                                      eProtection[pt].isSubjurProfile &&
                                      eProtection[pt].subObj
                                        ? 'subObj'
                                        : 'main'
                                    ][cat.toLowerCase() + '_critical_date_1']
                                  : ''}
                              </div>
                            </div>
                          ) : (
                            <div className="jpsd__stripeColumn" key={i}>
                              <div className="jpsdsc__name">{cat}</div>
                              <div className={'jpsdsc__bar gray'} />
                              <div className="jpsdsc__status" />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="jpsd__box sb-gray"
                      style={{ backgroundColor: '#e3e3e3' }}
                      key={index}
                    >
                      <div
                        className="jpsd__boxHeader"
                        style={{ color: '#a5a4a4' }}
                      >
                        <p
                          className="jpsd__boxTitle"
                          style={{ color: '#a5a4a4' }}
                        >
                          <span>{pt - 1}.</span>{' '}
                          {
                            theRoutes.find(r => r.subareaNumber === pt)
                              .short_name
                          }
                        </p>
                      </div>
                      <p
                        className="jps__status"
                        style={{
                          color: '#a5a4a4',
                          paddingTop: '20px',
                          fontWeight: 'bolder'
                        }}
                      >
                        {t('Forthcoming')}
                      </p>
                    </div>
                  )
                )}
              </div>

              <div className="jpsd__boxContainer">
                <div className="jps__summaryBoxes">
                  <div
                    onClick={() => scrollToRef(section14)}
                    className={
                      'jps__sb sb-' +
                      (eCt &&
                      eCt[eCt.isSubjurProfile && eCt.subObj ? 'subObj' : 'main']
                        ? eCt[
                            eCt.isSubjurProfile && eCt.subObj
                              ? 'subObj'
                              : 'main'
                          ].general_ban_type.id === '1' ||
                          eCt[
                            eCt.isSubjurProfile && eCt.subObj
                              ? 'subObj'
                              : 'main'
                          ].general_ban_type.id === '3' ||
                          eCt[
                            eCt.isSubjurProfile && eCt.subObj
                              ? 'subObj'
                              : 'main'
                          ].general_ban_type.id === '4' ||
                          eCt[
                            eCt.isSubjurProfile && eCt.subObj
                              ? 'subObj'
                              : 'main'
                          ].general_ban_type.id === '5'
                          ? 'white'
                          : 'dark'
                        : 'gray')
                    }
                  >
                    <p className="jps__header">
                      <span>13.</span>{' '}
                      {t('Regulation of so-called “conversion therapies”')}
                    </p>
                    {eCt &&
                    eCt[
                      eCt.isSubjurProfile && eCt.subObj ? 'subObj' : 'main'
                    ] ? (
                      <>
                        {eCt[
                          eCt.isSubjurProfile && eCt.subObj ? 'subObj' : 'main'
                        ].general_ban_type.id === '1' ||
                        eCt[
                          eCt.isSubjurProfile && eCt.subObj ? 'subObj' : 'main'
                        ].general_ban_type.id === '3' ||
                        eCt[
                          eCt.isSubjurProfile && eCt.subObj ? 'subObj' : 'main'
                        ].general_ban_type.id === '4' ||
                        eCt[
                          eCt.isSubjurProfile && eCt.subObj ? 'subObj' : 'main'
                        ].general_ban_type.id === '5' ? (
                          <p className="jps__status jps__status2digit green-text">
                            {eCt[
                              eCt.isSubjurProfile && eCt.subObj
                                ? 'subObj'
                                : 'main'
                            ].general_ban_type.name.toUpperCase()}
                          </p>
                        ) : (
                          <p className="jps__status  jps__status2digit yellow-text">
                            {eCt[
                              eCt.isSubjurProfile && eCt.subObj
                                ? 'subObj'
                                : 'main'
                            ].general_ban_type.name.toUpperCase()}
                          </p>
                        )}
                        <p className="jps__statusSet jps__statusSet2digit">
                          {eCt[
                            eCt.isSubjurProfile && eCt.subObj
                              ? 'subObj'
                              : 'main'
                          ].critical_date_1
                            ? eCt[
                                eCt.isSubjurProfile && eCt.subObj
                                  ? 'subObj'
                                  : 'main'
                              ].critical_date_1
                            : ''}
                        </p>
                      </>
                    ) : (
                      <LoadingBox />
                    )}
                  </div>
                  <div
                    onClick={() => scrollToRef(section15)}
                    className={
                      'jps__sb sb-' +
                      (eSsu &&
                      eSsu[
                        eSsu.isSubjurProfile && eSsu.subObj ? 'subObj' : 'main'
                      ]
                        ? parseInt(
                            eSsu[
                              eSsu.isSubjurProfile && eSsu.subObj
                                ? 'subObj'
                                : 'main'
                            ].summary_type.id
                          ) < 5
                          ? 'white'
                          : 'dark'
                        : 'gray')
                    }
                  >
                    <p className="jps__header">
                      <span>14.</span> {t('Same-sex unions')}
                    </p>
                    {eSsu &&
                    eSsu[
                      eSsu.isSubjurProfile && eSsu.subObj ? 'subObj' : 'main'
                    ] ? (
                      <>
                        {parseInt(
                          eSsu[
                            eSsu.isSubjurProfile && eSsu.subObj
                              ? 'subObj'
                              : 'main'
                          ].summary_type.id
                        ) < 5 ? (
                          <p className="jps__status jps__status2digit green-text">
                            {eSsu[
                              eSsu.isSubjurProfile && eSsu.subObj
                                ? 'subObj'
                                : 'main'
                            ].summary_type.name.toUpperCase()}
                          </p>
                        ) : (
                          <p className="jps__status  jps__status2digit yellow-text">
                            {eSsu[
                              eSsu.isSubjurProfile && eSsu.subObj
                                ? 'subObj'
                                : 'main'
                            ].summary_type.name.toUpperCase()}
                          </p>
                        )}
                        {eSsu[
                          eSsu.isSubjurProfile && eSsu.subObj
                            ? 'subObj'
                            : 'main'
                        ].marriage_critical_date_1 && (
                          <p className="jps__statusSet jps__statusSet2digit">
                            {t('Marriage') +
                              ' ' +
                              eSsu[
                                eSsu.isSubjurProfile && eSsu.subObj
                                  ? 'subObj'
                                  : 'main'
                              ].marriage_critical_date_1}
                          </p>
                        )}
                        {eSsu[
                          eSsu.isSubjurProfile && eSsu.subObj
                            ? 'subObj'
                            : 'main'
                        ].civil_critical_date_1 && (
                          <p className="jps__statusSet jps__statusSet2digit">
                            {t('Civil Union') +
                              ' ' +
                              eSsu[
                                eSsu.isSubjurProfile && eSsu.subObj
                                  ? 'subObj'
                                  : 'main'
                              ].civil_critical_date_1}
                          </p>
                        )}
                      </>
                    ) : (
                      <LoadingBox />
                    )}
                  </div>
                  <div
                    onClick={() => scrollToRef(section16)}
                    className={
                      'jps__sb sb-' +
                      (eAdo && eAdo.main
                        ? parseInt(eAdo.main.map_type.id) < 4
                          ? 'white'
                          : 'dark'
                        : 'gray')
                    }
                  >
                    <p className="jps__header">
                      <span>15.</span> {t('Adoption')}
                    </p>
                    {eAdo &&
                    eAdo[
                      eAdo.isSubjurProfile && eAdo.subObj ? 'subObj' : 'main'
                    ] ? (
                      <>
                        {parseInt(
                          eAdo[
                            eAdo.isSubjurProfile && eAdo.subObj
                              ? 'subObj'
                              : 'main'
                          ].map_type.id
                        ) < 4 ? (
                          <p className="jps__status jps__status2digit green-text">
                            {eAdo[
                              eAdo.isSubjurProfile && eAdo.subObj
                                ? 'subObj'
                                : 'main'
                            ].map_type.name.toUpperCase()}
                          </p>
                        ) : (
                          <p className="jps__status  jps__status2digit yellow-text">
                            {eAdo[
                              eAdo.isSubjurProfile && eAdo.subObj
                                ? 'subObj'
                                : 'main'
                            ].map_type.name.toUpperCase()}
                          </p>
                        )}
                        {eAdo[
                          eAdo.isSubjurProfile && eAdo.subObj
                            ? 'subObj'
                            : 'main'
                        ].joint_critical_date_1 && (
                          <p className="jps__statusSet jps__statusSet2digit">
                            {t('Joint Adoption') +
                              ' ' +
                              eAdo[
                                eAdo.isSubjurProfile && eAdo.subObj
                                  ? 'subObj'
                                  : 'main'
                              ].joint_critical_date_1}
                          </p>
                        )}
                        {eAdo[
                          eAdo.isSubjurProfile && eAdo.subObj
                            ? 'subObj'
                            : 'main'
                        ].second_parent_critical_date_1 && (
                          <p className="jps__statusSet jps__statusSet2digit">
                            {t('Second Parent') +
                              ' ' +
                              eAdo[
                                eAdo.isSubjurProfile && eAdo.subObj
                                  ? 'subObj'
                                  : 'main'
                              ].joint_critical_date_1}
                          </p>
                        )}
                      </>
                    ) : (
                      <LoadingBox />
                    )}
                  </div>
                  <div
                    onClick={() => scrollToRef(section17)}
                    className={
                      'jps__sb sb-' +
                      (ePnc &&
                      ePnc[
                        ePnc.isSubjurProfile && ePnc.subObj ? 'subObj' : 'main'
                      ]
                        ? ePnc[
                            ePnc.isSubjurProfile && ePnc.subObj
                              ? 'subObj'
                              : 'main'
                          ].pnc_type.id === '2'
                          ? 'white'
                          : 'dark'
                        : 'gray')
                    }
                  >
                    <p className="jps__header">
                      <span>16.</span>{' '}
                      {t('Restrictions on interventions on intersex minors')}
                    </p>
                    {ePnc &&
                    ePnc[
                      ePnc.isSubjurProfile && ePnc.subObj ? 'subObj' : 'main'
                    ] ? (
                      <>
                        {ePnc[
                          ePnc.isSubjurProfile && ePnc.subObj
                            ? 'subObj'
                            : 'main'
                        ].pnc_type.id === '2' ? (
                          <p className="jps__status jps__status2digit green-text">
                            {ePnc[
                              ePnc.isSubjurProfile && ePnc.subObj
                                ? 'subObj'
                                : 'main'
                            ].pnc_type.name.toUpperCase()}
                          </p>
                        ) : (
                          <p className="jps__status  jps__status2digit yellow-text">
                            {ePnc[
                              ePnc.isSubjurProfile && ePnc.subObj
                                ? 'subObj'
                                : 'main'
                            ].pnc_type.name.toUpperCase()}
                          </p>
                        )}
                        {ePnc[
                          ePnc.isSubjurProfile && ePnc.subObj
                            ? 'subObj'
                            : 'main'
                        ].critical_date_1 && (
                          <p className="jps__statusSet jps__statusSet2digit">
                            {
                              ePnc[
                                ePnc.isSubjurProfile && ePnc.subObj
                                  ? 'subObj'
                                  : 'main'
                              ].critical_date_1
                            }
                          </p>
                        )}
                      </>
                    ) : (
                      <LoadingBox />
                    )}
                  </div>
                </div>
                <div className="jps__summaryRow">
                  <h6 className="jps__summaryRow-heading">
                    17. {t('Legal gender recognition')}
                  </h6>
                  {eLgr &&
                    eLgr[
                      eLgr.isSubjurProfile && eLgr.subObj ? 'subObj' : 'main'
                    ] && (
                      <div className="jps__summaryBoxes">
                        <div
                          className={
                            'jpssr__sb sb-' +
                            (eLgr &&
                            eLgr[
                              eLgr.isSubjurProfile && eLgr.subObj
                                ? 'subObj'
                                : 'main'
                            ]
                              ? eLgr[
                                  eLgr.isSubjurProfile && eLgr.subObj
                                    ? 'subObj'
                                    : 'main'
                                ].gender_marker_lgr_type.id === '1'
                                ? 'white'
                                : eLgr[
                                    eLgr.isSubjurProfile && eLgr.subObj
                                      ? 'subObj'
                                      : 'main'
                                  ].gender_marker_lgr_type.id === '6'
                                ? 'nodata'
                                : 'dark'
                              : 'gray')
                          }
                        >
                          {eLgr &&
                            eLgr[
                              eLgr.isSubjurProfile && eLgr.subObj
                                ? 'subObj'
                                : 'main'
                            ] && (
                              <>
                                <p className="jpssr__header">
                                  {t('Procedure')}
                                </p>
                                {eLgr[
                                  eLgr.isSubjurProfile && eLgr.subObj
                                    ? 'subObj'
                                    : 'main'
                                ].gender_marker_lgr_type.id === '1' ? (
                                  <p className="jpssr__status green-text">
                                    {eLgr[
                                      eLgr.isSubjurProfile && eLgr.subObj
                                        ? 'subObj'
                                        : 'main'
                                    ].gender_marker_lgr_type.name.toUpperCase()}
                                  </p>
                                ) : (
                                  <p className="jpssr__status  yellow-text">
                                    {eLgr[
                                      eLgr.isSubjurProfile && eLgr.subObj
                                        ? 'subObj'
                                        : 'main'
                                    ].gender_marker_lgr_type.name.toUpperCase()}
                                  </p>
                                )}
                                <p className="jpssr__statusSet">
                                  {
                                    eLgr[
                                      eLgr.isSubjurProfile && eLgr.subObj
                                        ? 'subObj'
                                        : 'main'
                                    ].gender_marker_critical_date_1
                                  }
                                </p>
                              </>
                            )}
                        </div>
                        {eLgr &&
                          eLgr[
                            eLgr.isSubjurProfile && eLgr.subObj
                              ? 'subObj'
                              : 'main'
                          ] &&
                          eLgr[
                            eLgr.isSubjurProfile && eLgr.subObj
                              ? 'subObj'
                              : 'main'
                          ].gm_selfid_value && (
                            <div
                              className={
                                'jpssr__sb sb-' +
                                (eLgr &&
                                eLgr[
                                  eLgr.isSubjurProfile && eLgr.subObj
                                    ? 'subObj'
                                    : 'main'
                                ]
                                  ? eLgr[
                                      eLgr.isSubjurProfile && eLgr.subObj
                                        ? 'subObj'
                                        : 'main'
                                    ].gm_selfid_value.id === '1'
                                    ? 'white'
                                    : eLgr[
                                        eLgr.isSubjurProfile && eLgr.subObj
                                          ? 'subObj'
                                          : 'main'
                                      ].gm_selfid_value.id === '6'
                                    ? 'nodata'
                                    : 'dark'
                                  : 'gray')
                              }
                            >
                              <p className="jpssr__header">{t('Self-ID')}</p>
                              {eLgr[
                                eLgr.isSubjurProfile && eLgr.subObj
                                  ? 'subObj'
                                  : 'main'
                              ].gm_selfid_value.id === '1' ? (
                                <p className="jpssr__status green-text">
                                  {eLgr[
                                    eLgr.isSubjurProfile && eLgr.subObj
                                      ? 'subObj'
                                      : 'main'
                                  ].gm_selfid_value.name.toUpperCase()}
                                </p>
                              ) : (
                                <p className="jpssr__status  yellow-text">
                                  {eLgr[
                                    eLgr.isSubjurProfile && eLgr.subObj
                                      ? 'subObj'
                                      : 'main'
                                  ].gm_selfid_value.name.toUpperCase()}
                                </p>
                              )}
                            </div>
                          )}
                        {eLgr &&
                          eLgr[
                            eLgr.isSubjurProfile && eLgr.subObj
                              ? 'subObj'
                              : 'main'
                          ] &&
                          eLgr[
                            eLgr.isSubjurProfile && eLgr.subObj
                              ? 'subObj'
                              : 'main'
                          ].gm_surgery_gm_value && (
                            <div
                              className={
                                'jpssr__sb sb-' +
                                (eLgr &&
                                eLgr[
                                  eLgr.isSubjurProfile && eLgr.subObj
                                    ? 'subObj'
                                    : 'main'
                                ]
                                  ? eLgr[
                                      eLgr.isSubjurProfile && eLgr.subObj
                                        ? 'subObj'
                                        : 'main'
                                    ].gm_surgery_gm_value.id === '2'
                                    ? 'white'
                                    : eLgr[
                                        eLgr.isSubjurProfile && eLgr.subObj
                                          ? 'subObj'
                                          : 'main'
                                      ].gm_surgery_gm_value.id === '6'
                                    ? 'nodata'
                                    : 'dark'
                                  : 'gray')
                              }
                            >
                              <p className="jpssr__header">{t('Surgery')}</p>
                              {eLgr[
                                eLgr.isSubjurProfile && eLgr.subObj
                                  ? 'subObj'
                                  : 'main'
                              ].gm_surgery_gm_value.id === '2' ? (
                                <p className="jpssr__status green-text">
                                  {eLgr[
                                    eLgr.isSubjurProfile && eLgr.subObj
                                      ? 'subObj'
                                      : 'main'
                                  ].gm_surgery_gm_value.name.toUpperCase()}
                                </p>
                              ) : (
                                <p className="jpssr__status  yellow-text">
                                  {eLgr[
                                    eLgr.isSubjurProfile && eLgr.subObj
                                      ? 'subObj'
                                      : 'main'
                                  ].gm_surgery_gm_value.name.toUpperCase()}
                                </p>
                              )}
                            </div>
                          )}
                        {eLgr &&
                          eLgr[
                            eLgr.isSubjurProfile && eLgr.subObj
                              ? 'subObj'
                              : 'main'
                          ] &&
                          eLgr[
                            eLgr.isSubjurProfile && eLgr.subObj
                              ? 'subObj'
                              : 'main'
                          ].gm_diagnosis_gm_value && (
                            <div
                              className={
                                'jpssr__sb sb-' +
                                (eLgr &&
                                eLgr[
                                  eLgr.isSubjurProfile && eLgr.subObj
                                    ? 'subObj'
                                    : 'main'
                                ]
                                  ? eLgr[
                                      eLgr.isSubjurProfile && eLgr.subObj
                                        ? 'subObj'
                                        : 'main'
                                    ].gm_diagnosis_gm_value.id === '2'
                                    ? 'white'
                                    : eLgr[
                                        eLgr.isSubjurProfile && eLgr.subObj
                                          ? 'subObj'
                                          : 'main'
                                      ].gm_diagnosis_gm_value.id === '6'
                                    ? 'nodata'
                                    : 'dark'
                                  : 'gray')
                              }
                            >
                              <p className="jpssr__header">{t('Diagnosis')}</p>
                              {eLgr[
                                eLgr.isSubjurProfile && eLgr.subObj
                                  ? 'subObj'
                                  : 'main'
                              ].gm_diagnosis_gm_value.id === '2' ? (
                                <p className="jpssr__status green-text">
                                  {eLgr[
                                    eLgr.isSubjurProfile && eLgr.subObj
                                      ? 'subObj'
                                      : 'main'
                                  ].gm_diagnosis_gm_value.name.toUpperCase()}
                                </p>
                              ) : (
                                <p className="jpssr__status  yellow-text">
                                  {eLgr[
                                    eLgr.isSubjurProfile && eLgr.subObj
                                      ? 'subObj'
                                      : 'main'
                                  ].gm_diagnosis_gm_value.name.toUpperCase()}
                                </p>
                              )}
                            </div>
                          )}
                        {eLgr &&
                          eLgr[
                            eLgr.isSubjurProfile && eLgr.subObj
                              ? 'subObj'
                              : 'main'
                          ] &&
                          eLgr[
                            eLgr.isSubjurProfile && eLgr.subObj
                              ? 'subObj'
                              : 'main'
                          ].gm_non_binary_gm_avail_value && (
                            <div
                              className={
                                'jpssr__sb sb-' +
                                (eLgr &&
                                eLgr[
                                  eLgr.isSubjurProfile && eLgr.subObj
                                    ? 'subObj'
                                    : 'main'
                                ]
                                  ? eLgr[
                                      eLgr.isSubjurProfile && eLgr.subObj
                                        ? 'subObj'
                                        : 'main'
                                    ].gm_non_binary_gm_avail_value.id === '1'
                                    ? 'white'
                                    : eLgr[
                                        eLgr.isSubjurProfile && eLgr.subObj
                                          ? 'subObj'
                                          : 'main'
                                      ].gm_non_binary_gm_avail_value.id === '7'
                                    ? 'nodata'
                                    : 'dark'
                                  : 'gray')
                              }
                            >
                              <p className="jpssr__header">
                                {t('Non-binary markers')}
                              </p>
                              {eLgr[
                                eLgr.isSubjurProfile && eLgr.subObj
                                  ? 'subObj'
                                  : 'main'
                              ].gm_non_binary_gm_avail_value.id === '1' ? (
                                <p className="jpssr__status green-text">
                                  {eLgr[
                                    eLgr.isSubjurProfile && eLgr.subObj
                                      ? 'subObj'
                                      : 'main'
                                  ].gm_non_binary_gm_avail_value.name.toUpperCase()}
                                </p>
                              ) : (
                                <p className="jpssr__status  yellow-text">
                                  {eLgr[
                                    eLgr.isSubjurProfile && eLgr.subObj
                                      ? 'subObj'
                                      : 'main'
                                  ].gm_non_binary_gm_avail_value.name.toUpperCase()}
                                </p>
                              )}
                            </div>
                          )}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section ref={section1} className="section1-jur1">
        <div className="content__container">
          <h4 className="jpjs__area">{t('AREA')} 1</h4>
          <div className="jpjs__titleWithBtn">
            <h2 className="jpjs__title">{t('Legal Frameworks')}</h2>
            {isSubjur && (
              <button className="jps__button btn btn-link">
                <img
                  className="jpsb__countryFlag"
                  src={
                    '/images/flags_svg/' + jur.a2_code.toLowerCase() + '.svg'
                  }
                  alt="Country Flag"
                />
                {jur.name}
              </button>
            )}
          </div>
        </div>

        <div className="jpjs__innerArea">
          <div className="jpjs__innerArea-header">
            <div className="content__container">
              <h4 className="jpia__title">
                1. {t('Criminalisation of consensual same-sex sexual acts')}
              </h4>
              <div className="jurCiteBox">
                <span
                  onClick={e => {
                    citeA11(eCsssa.main, setToast)
                  }}
                >
                  <CiteIcon
                    color={'#707070'}
                    size={25}
                    title={t('Cite Entry')}
                  />
                </span>
                <span onClick={e => setReportErrorOpen(eCsssa.main)}>
                  <ReportIcon
                    color={'#707070'}
                    size={25}
                    title={t('Report error or send comments')}
                  />
                </span>
                {subjurSlug && (
                  <button
                    className="jps__button btn btn-link"
                    onClick={e =>
                      history.push(
                        getPath('jur_profile').replace(
                          ':slug',
                          jur.slug.toLowerCase()
                        )
                      )
                    }
                  >
                    {t('Back to {{jur}} profile', { jur: jur.name })}
                  </button>
                )}
                <button
                  className="jps__button btn btn-link"
                  onClick={e => history.push(getPath('a11'))}
                >
                  {t('Go to world data')}
                </button>
              </div>
            </div>
          </div>

          <div className="jpjs__innerArea-body">
            <div className="content__container">
              {eCsssa && !subjurSlug && (
                <SubjurChart
                  eEntry={eCsssa}
                  jur={jur}
                  subArea={1}
                  noShow={eCsssa.n_sub === eCsssa.sub.length}
                />
              )}
              {eCsssa && eCsssa.main && (
                <SubjurProfileAccordion
                  subjur={subjurSlug}
                  entry={eCsssa}
                  jur={jur}
                  content={
                    <div className="jpjs__countryBox">
                      <div className="accordion">
                        <div className="a2ts__body table-responsive">
                          {eCsssa && eCsssa.main && (
                            <SimpleTable
                              columns={getColumnsA11()
                                .filter(
                                  c =>
                                    !eCsssa.main.legal ||
                                    c.accessor === 'legal' ||
                                    c.accessor === 'decrim_date_1' ||
                                    c.accessor ===
                                      'legal_framework_decrim_mechanism.name' ||
                                    c.accessor === 'explan' ||
                                    (eCsssa.main.enforcement &&
                                      c.accessor === 'enforcement')
                                )
                                .filter(
                                  c =>
                                    eCsssa.main.legal ||
                                    (!eCsssa.main.legal &&
                                      c.accessor !== 'decrim_date_1' &&
                                      c.accessor !==
                                        'legal_framework_decrim_mechanism.name')
                                )
                                .filter(
                                  c =>
                                    c.accessor !==
                                    'motherEntry.jurisdiction.name'
                                )
                                .map(c => ({
                                  ...c,
                                  ...(eCsssa.main.legal &&
                                    c.accessor === 'explan' && {
                                      ...c,
                                      style: {
                                        minWidth: '70%',
                                        maxWidth: '70%',
                                        width: '70%'
                                      }
                                    }),
                                  ...(!eCsssa.main.legal &&
                                    c.accessor !== 'explan' &&
                                    c.accessor !== 'enforcement' && {
                                      ...c,
                                      className: 'textCenter col100',
                                      style: {
                                        minWidth: '100px',
                                        maxWidth: '100px',
                                        width: '100px'
                                      }
                                    }),
                                  ...(!eCsssa.main.legal &&
                                    c.accessor === 'enforcement' && {
                                      ...c,
                                      className: 'textCenter col110',
                                      style: {
                                        minWidth: '110px',
                                        maxWidth: '110px',
                                        width: '110px'
                                      }
                                    }),
                                  ...(c.columns && {
                                    columns: c.columns.map(c => ({
                                      ...c,
                                      className: 'textCenter col100',
                                      style: {
                                        minWidth: '100px',
                                        maxWidth: '100px',
                                        width: '100px'
                                      }
                                    }))
                                  }),
                                  ...(c.isHidden && { ...c, isHidden: false })
                                }))
                                .map(c =>
                                  c.NonExpandableCell
                                    ? { ...c, Cell: c.NonExpandableCell }
                                    : c
                                )}
                              entries={[eCsssa.main]}
                            />
                          )}
                        </div>
                        {!subjurSlug && (
                          <SubjurButtons
                            eEntry={eCsssa}
                            setEEntry={setECsssa}
                          />
                        )}
                      </div>
                    </div>
                  }
                />
              )}
              <SubjurList
                getColumns={entry =>
                  getColumnsA11()
                    .filter(
                      c =>
                        !entry.legal ||
                        c.accessor === 'legal' ||
                        c.accessor === 'decrim_date_1' ||
                        c.accessor ===
                          'legal_framework_decrim_mechanism.name' ||
                        c.accessor === 'explan'
                    )
                    .filter(
                      c =>
                        entry.legal ||
                        (!entry.legal &&
                          c.accessor !== 'decrim_date_1' &&
                          c.accessor !==
                            'legal_framework_decrim_mechanism.name')
                    )
                    .filter(c => c.accessor !== 'motherEntry.jurisdiction.name')
                    .map(c => (c.isHidden ? { ...c, isHidden: false } : c))
                    .map(c => ({
                      ...c,
                      ...(eCsssa.main.legal &&
                        c.accessor === 'explan' && {
                          ...c,
                          style: {
                            minWidth: '70%',
                            maxWidth: '70%',
                            width: '70%'
                          }
                        }),
                      ...(!eCsssa.main.legal &&
                        c.accessor !== 'explan' &&
                        c.accessor !== 'enforcement' && {
                          ...c,
                          className: 'textCenter col100',
                          style: {
                            minWidth: '100px',
                            maxWidth: '100px',
                            width: '100px'
                          }
                        }),
                      ...(!eCsssa.main.legal &&
                        c.accessor === 'enforcement' && {
                          ...c,
                          className: 'textCenter col110',
                          style: {
                            minWidth: '110px',
                            maxWidth: '110px',
                            width: '110px'
                          }
                        }),
                      ...(c.columns && {
                        columns: c.columns.map(c => ({
                          ...c,
                          className: 'textCenter col100',
                          style: {
                            minWidth: '100px',
                            maxWidth: '100px',
                            width: '100px'
                          }
                        }))
                      }),
                      ...(c.isHidden && { ...c, isHidden: false })
                    }))
                    .map(c =>
                      c.NonExpandableCell
                        ? { ...c, Cell: c.NonExpandableCell }
                        : c
                    )
                }
                eEntry={eCsssa}
                jur={jur}
              />
            </div>
          </div>
        </div>
      </section>

      <section ref={section3} className="section3-jur1">
        <div className="jpjs__innerArea">
          <div className="jpjs__innerArea-header">
            <div className="content__container">
              <h4 className="jpia__title">
                2. {t('Legal barriers to freedom of expression')}
              </h4>
              <div className="jurCiteBox">
                <>
                  {eFoe &&
                  eFoe.main &&
                  eFoe.main.entry_foe_barrier_general_value.id === '3' &&
                  !eFoe.main.general_explan ? (
                    <></>
                  ) : (
                    <span
                      onClick={e => {
                        citeA13(eFoe.main, setToast)
                      }}
                    >
                      <CiteIcon
                        color={'#707070'}
                        size={25}
                        title={t('Cite Entry')}
                      />
                    </span>
                  )}
                  <span onClick={e => setReportErrorOpen(eFoe.main)}>
                    <ReportIcon
                      color={'#707070'}
                      size={25}
                      title={t('Report error or send comments')}
                    />
                  </span>
                </>

                {subjurSlug && (
                  <button
                    className="jps__button btn btn-link"
                    onClick={e =>
                      history.push(
                        getPath('jur_profile').replace(
                          ':slug',
                          jur.slug.toLowerCase()
                        )
                      )
                    }
                  >
                    {t('Back to {{jur}} profile', { jur: jur.name })}
                  </button>
                )}

                <button
                  className="jps__button btn btn-link"
                  onClick={e =>
                    history.push(
                      theRoutes.find(r => r.subareaNumber === 3).path
                    )
                  }
                >
                  {t('Go to world data')}
                </button>
              </div>
            </div>
          </div>
          <div className="jpjs__innerArea-body">
            <div className="content__container">
              {!subjurSlug && (
                <SubjurChart eEntry={eFoe} jur={jur} subArea={3} />
              )}
              {eFoe && eFoe.main && (
                <SubjurProfileAccordion
                  entry={eFoe}
                  subjur={subjurSlug}
                  jur={jur}
                  content={
                    <div className="jpjs__countryBox">
                      {eFoe &&
                      eFoe.main &&
                      eFoe.main.entry_foe_barrier_general_value.id === '3' &&
                      !eFoe.main.general_explan ? (
                        <div className="noData">
                          <div>
                            <NoteCheckIcon size={'50px'} color="#aaa" />
                          </div>
                          <div className="noDataText">
                            {NoData({ jur })['A1-3'][i18n.language]}
                          </div>
                        </div>
                      ) : (
                        <div className="a2ts__body table-responsive">
                          {eFoe && eFoe.main && (
                            <>
                              <SimpleTable
                                columns={a13columns()
                                  .filter(
                                    c =>
                                      c.accessor !==
                                      'motherEntry.jurisdiction.name'
                                  )
                                  .map(c =>
                                    c.isHidden ? { ...c, isHidden: false } : c
                                  )
                                  .map(c =>
                                    c.NonExpandableCell
                                      ? { ...c, Cell: c.NonExpandableCell }
                                      : c
                                  )}
                                entries={eFoe.expanded}
                              />
                            </>
                          )}
                        </div>
                      )}
                      {!subjurSlug && (
                        <SubjurButtons eEntry={eFoe} setEEntry={setEFoe} />
                      )}
                    </div>
                  }
                />
              )}
              <SubjurList
                noData={(entry, jur) =>
                  entry &&
                  entry.entry_foe_barrier_general_value.id === '3' &&
                  !entry.entry_foe_barrier_media_value &&
                  !entry.entry_foe_barrier_education_value &&
                  NoData({ jur })['A1-3'][i18n.language]
                }
                getColumns={entry =>
                  a13columns()
                    .filter(c => c.accessor !== 'motherEntry.jurisdiction.name')
                    .map(c => (c.isHidden ? { ...c, isHidden: false } : c))
                    .map(c =>
                      c.NonExpandableCell
                        ? { ...c, Cell: c.NonExpandableCell }
                        : c
                    )
                }
                eEntry={eFoe}
                entriesSplit={e => getTableEntries(e)}
                jur={jur}
              />
            </div>
          </div>
        </div>
      </section>

      <section ref={section4} className="section4-jur1">
        <div className="jpjs__innerArea">
          <div className="jpjs__innerArea-header">
            <div className="content__container">
              <h4 className="jpia__title">
                3. {t('Legal barriers to freedom of association')}
              </h4>
              <div className="jurCiteBox">
                <>
                  {eFoa &&
                  eFoa.main &&
                  eFoa.main.barrier_value.id === '4' &&
                  !eFoa.main.explan ? (
                    <></>
                  ) : (
                    <span
                      onClick={e => {
                        citeA14(eFoa.main, setToast)
                      }}
                    >
                      <CiteIcon
                        color={'#707070'}
                        size={25}
                        title={t('Cite Entry')}
                      />
                    </span>
                  )}
                  <span onClick={e => setReportErrorOpen(eFoa.main)}>
                    <ReportIcon
                      color={'#707070'}
                      size={25}
                      title={t('Report error or send comments')}
                    />
                  </span>
                </>

                {subjurSlug && (
                  <button
                    className="jps__button btn btn-link"
                    onClick={e =>
                      history.push(
                        getPath('jur_profile').replace(
                          ':slug',
                          jur.slug.toLowerCase()
                        )
                      )
                    }
                  >
                    {t('Back to {{jur}} profile', { jur: jur.name })}
                  </button>
                )}
                <button
                  className="jps__button btn btn-link"
                  onClick={e =>
                    history.push(
                      theRoutes.find(r => r.subareaNumber === 4).path
                    )
                  }
                >
                  {t('Go to world data')}
                </button>
              </div>
            </div>
          </div>
          <div className="jpjs__innerArea-body">
            <div className="content__container">
              <SubjurProfileAccordion
                entry={eFoa}
                subjur={subjurSlug}
                jur={jur}
                content={
                  <div className="jpjs__countryBox">
                    {eFoa &&
                    eFoa.main &&
                    eFoa.main.barrier_value.id === '4' &&
                    !eFoa.main.explan ? (
                      <div className="noData">
                        <div>
                          <NoteCheckIcon size={'50px'} color="#aaa" />
                        </div>
                        <div className="noDataText">
                          {NoData({ jur })['A1-4'][i18n.language]}
                        </div>
                      </div>
                    ) : (
                      <div className="a2ts__body table-responsive">
                        {eFoa && eFoa.main && (
                          <>
                            <SimpleTable
                              columns={a14columns()
                                .filter(
                                  c =>
                                    c.accessor !==
                                    'motherEntry.jurisdiction.name'
                                )
                                .map(c =>
                                  c.isHidden ? { ...c, isHidden: false } : c
                                )
                                .map(c =>
                                  c.NonExpandableCell
                                    ? { ...c, Cell: c.NonExpandableCell }
                                    : c
                                )}
                              entries={[eFoa.main]}
                            />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </section>

      {protectionTypes.map((pt, index) =>
        pt !== 9 ? (
          <section
            ref={protectionRef[index]}
            key={index}
            className="section5-jur1"
          >
            <div className="jpjs__innerArea">
              <div className="jpjs__innerArea-header">
                <div className="content__container">
                  <h4 className="jpia__title">
                    {' '}
                    <span>{pt - 1}.</span>{' '}
                    {theRoutes.find(r => r.subareaNumber === pt).name}
                  </h4>
                  {eProtection && eProtection[pt] && (
                    <div className="jurCiteBox">
                      <>
                        {' '}
                        {eProtection[pt].main &&
                        eProtection[pt].main &&
                        [
                          'so_protection_type',
                          'gi_protection_type',
                          'ge_protection_type',
                          'sc_protection_type'
                        ].every(p => eProtection[pt].main[p].id === '2') ? (
                          <></>
                        ) : eProtection[pt].main &&
                          eProtection[pt].main &&
                          [
                            'so_protection_type',
                            'gi_protection_type',
                            'ge_protection_type',
                            'sc_protection_type'
                          ].some(p => eProtection[pt].main[p].id === '6') ? (
                          <></>
                        ) : (
                          <span
                            onClick={e => {
                              citeA15(eProtection[pt].main, setToast, pt)
                            }}
                          >
                            <CiteIcon
                              color={'#707070'}
                              size={25}
                              title={t('Cite Entry')}
                            />
                          </span>
                        )}
                        <span
                          onClick={e =>
                            setReportErrorOpen(eProtection[pt].main)
                          }
                        >
                          <ReportIcon
                            color={'#707070'}
                            size={25}
                            title={t('Report error or send comments')}
                          />
                        </span>
                      </>

                      {subjurSlug && (
                        <button
                          className="jps__button btn btn-link"
                          onClick={e =>
                            history.push(
                              getPath('jur_profile').replace(
                                ':slug',
                                jur.slug.toLowerCase()
                              )
                            )
                          }
                        >
                          {t('Back to {{jur}} profile', { jur: jur.name })}
                        </button>
                      )}
                      <button
                        className="jps__button btn btn-link"
                        onClick={e =>
                          history.push(
                            theRoutes.find(r => r.subareaNumber === pt).path
                          )
                        }
                      >
                        {t('Go to world data')}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="jpjs__innerArea-body">
                <div className="content__container">
                  {eProtection && eProtection[pt] && (
                    <>
                      {eProtection[pt].main &&
                      eProtection[pt].main &&
                      [
                        'so_protection_type',
                        'gi_protection_type',
                        'ge_protection_type',
                        'sc_protection_type'
                      ].every(p => eProtection[pt].main[p].id === '2') &&
                      [
                        'so_explan',
                        'gi_explan',
                        'ge_explan',
                        'sc_explan'
                      ].every(p => !eProtection[pt].main[p]) ? (
                        <div className="jpjs__countryBox">
                          <div className="noData">
                            <div>
                              <NoteRemoveIcon size={'50px'} color="#aaa" />
                            </div>
                            <div className="noDataText">
                              {NoData({ jur })['A1-' + pt]['no'][i18n.language]}
                            </div>
                          </div>
                        </div>
                      ) : eProtection[pt].main &&
                        eProtection[pt].main &&
                        !eProtection[pt].isSubjurProfile &&
                        (eProtection[pt].sub && eProtection[pt].sub.length) &&
                        [
                          'so_protection_type',
                          'gi_protection_type',
                          'ge_protection_type',
                          'sc_protection_type'
                        ].some(p => eProtection[pt].main[p].id === '6') ? (
                        <div className="jpjs__countryBox">
                          <div className="noData">
                            <SubjurProtection
                              eEntry={eProtection[pt]}
                              jur={jur}
                              subareaNumber={pt}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          {eProtection[pt].main && eProtection[pt].main && (
                            <SubjurProfileAccordion
                              entry={eProtection[pt]}
                              subjur={subjurSlug}
                              jur={jur}
                              content={
                                <div className="jpjs__countryBox">
                                  <div className="a2ts__body table-responsive">
                                    <SimpleTable
                                      columns={[
                                        {
                                          Header: t('Category'),
                                          accessor: 'type',
                                          Cell: ({ value, row }) => {
                                            return (
                                              <>
                                                <span className="protectionCategoryId">
                                                  {t(value)}
                                                </span>
                                                <br />
                                                {row.original.name}
                                              </>
                                            )
                                          },
                                          className:
                                            'textCenter col190Protection jur'
                                        }
                                      ].concat(
                                        a15columns()
                                          .filter(
                                            c =>
                                              c.accessor !==
                                                'motherEntry.jurisdiction.name' &&
                                              c.accessor.indexOf('gi_') ===
                                                -1 &&
                                              c.accessor.indexOf('sc_') ===
                                                -1 &&
                                              c.accessor.indexOf('ge_') === -1
                                          )
                                          .map(c => {
                                            let res = c.isHidden
                                              ? { ...c, isHidden: false }
                                              : c.accessor ===
                                                'so_protection_type.name'
                                              ? { ...c, Header: 'Protection' }
                                              : c

                                            res =
                                              c.accessor.indexOf('explan') ===
                                              -1
                                                ? {
                                                    ...res,
                                                    className:
                                                      'textCenter col190Protection'
                                                  }
                                                : res

                                            return res
                                          })
                                          .map(c =>
                                            c.NonExpandableCell
                                              ? {
                                                  ...c,
                                                  Cell: c.NonExpandableCell
                                                }
                                              : c
                                          )
                                      )}
                                      entries={['so_', 'gi_', 'ge_', 'sc_'].map(
                                        c => {
                                          let tmpObj = {
                                            ...eProtection[pt].main,
                                            type: c
                                              .replace('_', '')
                                              .toUpperCase(),
                                            name: cProtectionCategories().find(
                                              pc =>
                                                pc.id ===
                                                c.replace('_', '').toUpperCase()
                                            ).name,
                                            ...(c === 'so_'
                                              ? { section: 1 }
                                              : c === 'gi_'
                                              ? { section: 2 }
                                              : c === 'ge_'
                                              ? {
                                                  section: 3
                                                }
                                              : { section: 4 })
                                          }
                                          for (let key of Object.keys(
                                            eProtection[pt].main
                                          )) {
                                            if (key.indexOf(c) !== -1) {
                                              tmpObj = {
                                                ...tmpObj,
                                                [key.replace(
                                                  c,
                                                  'so_'
                                                )]: eProtection[pt].main[key]
                                              }
                                            }
                                          }
                                          return tmpObj
                                        }
                                      )}
                                    />
                                  </div>
                                </div>
                              }
                            />
                          )}
                        </>
                      )}
                      {!subjurSlug && (
                        <SubjurButtons
                          eEntry={eProtection[pt]}
                          setEEntry={e =>
                            setEProtection({ ...eProtection, [pt]: e })
                          }
                        />
                      )}
                    </>
                  )}
                  {eProtection && eProtection[pt] && (
                    <SubjurList
                      getColumns={entry =>
                        [
                          {
                            Header: t('Category'),
                            accessor: 'type',
                            Cell: ({ value, row }) => {
                              return (
                                <>
                                  <span className="protectionCategoryId">
                                    {t(value)}
                                  </span>
                                  <br />
                                  {row.original.name}
                                </>
                              )
                            },
                            className: 'textCenter col190Protection jur'
                          }
                        ].concat(
                          a15columns()
                            .filter(
                              c =>
                                c.accessor !==
                                  'motherEntry.jurisdiction.name' &&
                                c.accessor.indexOf('gi_') === -1 &&
                                c.accessor.indexOf('sc_') === -1 &&
                                c.accessor.indexOf('ge_') === -1
                            )
                            .map(c => {
                              let res = c.isHidden
                                ? { ...c, isHidden: false }
                                : c.accessor === 'so_protection_type.name'
                                ? { ...c, Header: 'Protection' }
                                : c

                              res =
                                c.accessor.indexOf('explan') === -1
                                  ? {
                                      ...res,
                                      className: 'textCenter col190Protection'
                                    }
                                  : res

                              return res
                            })
                            .map(c =>
                              c.NonExpandableCell
                                ? { ...c, Cell: c.NonExpandableCell }
                                : c
                            )
                        )
                      }
                      eEntry={eProtection[pt]}
                      noData={(entry, jursubjur) => {
                        if (
                          entry &&
                          [
                            'so_protection_type',
                            'gi_protection_type',
                            'ge_protection_type',
                            'sc_protection_type'
                          ].every(p => entry[p].id === '2')
                        )
                          return NoData({ jur: jursubjur })['A1-' + pt]['no'][
                            i18n.language
                          ]
                      }}
                      entriesSplit={entry =>
                        ['so_', 'gi_', 'ge_', 'sc_'].map(c => {
                          let tmpObj = {
                            ...entry,
                            type: c.replace('_', '').toUpperCase(),
                            name: cProtectionCategories().find(
                              pc => pc.id === c.replace('_', '').toUpperCase()
                            ).name,
                            ...(c === 'so_'
                              ? { section: 1 }
                              : c === 'gi_'
                              ? { section: 2 }
                              : c === 'ge_'
                              ? {
                                  section: 3
                                }
                              : { section: 4 })
                          }
                          for (let key of Object.keys(entry)) {
                            if (key.indexOf(c) !== -1) {
                              tmpObj = {
                                ...tmpObj,
                                [key.replace(c, 'so_')]: entry[key]
                              }
                            }
                          }
                          return tmpObj
                        })
                      }
                      jur={jur}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section ref={section2} className="section2-jur1" key={index}>
            <div className="jpjs__innerArea">
              <div className="jpjs__innerArea-header">
                <div className="content__container">
                  <h4 className="jpia__title">
                    <span>{pt - 1}.</span>{' '}
                    {theRoutes.find(r => r.subareaNumber === pt).name}
                  </h4>
                </div>
              </div>
              <div className="jpjs__innerArea-body">
                <div className="content__container">
                  <div className="jpjs__countryBox">
                    {' '}
                    <p>{t('Forthcoming')}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      )}

      <section ref={section14} className="section14-jur1">
        <div className="jpjs__innerArea">
          <div className="jpjs__innerArea-header">
            <div className="content__container">
              <h4 className="jpia__title">
                13. {t('Regulation of so-called “conversion therapies”')}
              </h4>

              <div className="jurCiteBox">
                <>
                  {eCt &&
                  eCt.main &&
                  (eCt.main.general_ban_type.id === '2' ||
                    [('so_value', 'gi_value')].every(
                      p => eCt.main[p] && eCt.main[p].id === '2'
                    )) ? (
                    <></>
                  ) : eCt &&
                    eCt.main &&
                    ['so_value', 'gi_value'].some(
                      p => eCt.main[p] && eCt.main[p].id === '5'
                    ) ? (
                    <></>
                  ) : (
                    <span
                      onClick={e => {
                        citeA114(eCt.main, setToast)
                      }}
                    >
                      <CiteIcon
                        color={'#707070'}
                        size={25}
                        title={t('Cite Entry')}
                      />
                    </span>
                  )}
                  <span onClick={e => setReportErrorOpen(eCt.main)}>
                    <ReportIcon
                      color={'#707070'}
                      size={25}
                      title={t('Report error or send comments')}
                    />
                  </span>
                </>

                {subjurSlug && (
                  <button
                    className="jps__button btn btn-link"
                    onClick={e =>
                      history.push(
                        getPath('jur_profile').replace(
                          ':slug',
                          jur.slug.toLowerCase()
                        )
                      )
                    }
                  >
                    {t('Back to {{jur}} profile', { jur: jur.name })}
                  </button>
                )}
                <button
                  className="jps__button btn btn-link"
                  onClick={e =>
                    history.push(
                      theRoutes.find(r => r.subareaNumber === 14).path
                    )
                  }
                >
                  {t('Go to world data')}
                </button>
              </div>
            </div>
          </div>
          <div className="jpjs__innerArea-body">
            <div className="content__container">
              {eCt && !subjurSlug && (
                <SubjurChart
                  eEntry={eCt}
                  jur={jur}
                  subArea={14}
                  noShow={
                    !eCt || !eCt.main || eCt.main.general_ban_type.id === '1'
                  }
                />
              )}
              {eCt && eCt.main && (
                <SubjurProfileAccordion
                  entry={eCt}
                  subjur={subjurSlug}
                  jur={jur}
                  content={
                    <div className="jpjs__countryBox">
                      {eCt &&
                      eCt.main &&
                      !eCt.explan &&
                      eCt.main.general_ban_type.id === '2' ? (
                        <div className="noData">
                          <div>
                            <NoteRemoveIcon size={'50px'} color="#aaa" />
                          </div>
                          <div className="noDataText">
                            {NoData({ jur })['A1-14']['no'][i18n.language]}
                          </div>
                        </div>
                      ) : (
                        <div className="a2ts__body table-responsive">
                          {eCt && eCt.main && (
                            <SimpleTable
                              columns={a114columns()
                                .filter(
                                  c =>
                                    c.accessor !==
                                    'motherEntry.jurisdiction.name'
                                )
                                .map(c =>
                                  c.isHidden ? { ...c, isHidden: false } : c
                                )
                                .map(c =>
                                  c.NonExpandableCell
                                    ? { ...c, Cell: c.NonExpandableCell }
                                    : c
                                )}
                              entries={[eCt.main]}
                            />
                          )}
                        </div>
                      )}
                      {!subjurSlug && (
                        <SubjurButtons eEntry={eCt} setEEntry={setECt} />
                      )}
                    </div>
                  }
                />
              )}
              <SubjurList
                getColumns={entry =>
                  a114columns()
                    .filter(c => c.accessor !== 'motherEntry.jurisdiction.name')
                    .map(c => (c.isHidden ? { ...c, isHidden: false } : c))
                    .map(c =>
                      c.NonExpandableCell
                        ? { ...c, Cell: c.NonExpandableCell }
                        : c
                    )
                }
                eEntry={eCt}
                jur={jur}
                noData={(entry, jur) =>
                  entry.general_ban_type.id === '2' &&
                  !entry.explan && (
                    <div className="noData">
                      <div className="noDataText">
                        {NoData({ jur })['A1-14']['no'][i18n.language]}
                      </div>
                    </div>
                  )
                }
              />
            </div>
          </div>
        </div>
      </section>

      <section ref={section15} className="section14-jur1">
        <div className="jpjs__innerArea">
          <div className="jpjs__innerArea-header">
            <div className="content__container">
              <h4 className="jpia__title">
                14. {t('Same-Sex Marriage and Civil Unions')}
              </h4>

              <div className="jurCiteBox">
                <>
                  {' '}
                  {eSsu &&
                  eSsu.main &&
                  eSsu.main.marriage_type.id === '6' &&
                  eSsu.main.civil_type.id === '6' ? (
                    <></>
                  ) : eSsu &&
                    eSsu.main &&
                    (eSsu.main.marriage_type.id === '2' ||
                      eSsu.main.civil_type.id === '2') ? (
                    <></>
                  ) : (
                    <span
                      onClick={e => {
                        citeA115(eSsu.main, setToast)
                      }}
                    >
                      <CiteIcon
                        color={'#707070'}
                        size={25}
                        title={t('Cite Entry')}
                      />
                    </span>
                  )}
                  <span onClick={e => setReportErrorOpen(eSsu.main)}>
                    <ReportIcon
                      color={'#707070'}
                      size={25}
                      title={t('Report error or send comments')}
                    />
                  </span>
                </>

                {subjurSlug && (
                  <button
                    className="jps__button btn btn-link"
                    onClick={e =>
                      history.push(
                        getPath('jur_profile').replace(
                          ':slug',
                          jur.slug.toLowerCase()
                        )
                      )
                    }
                  >
                    {t('Back to {{jur}} profile', { jur: jur.name })}
                  </button>
                )}
                <button
                  className="jps__button btn btn-link"
                  onClick={e =>
                    history.push(
                      theRoutes.find(r => r.subareaNumber === 15).path
                    )
                  }
                >
                  {t('Go to world data')}
                </button>
              </div>
            </div>
          </div>
          <div className="jpjs__innerArea-body">
            <div className="content__container">
              {eSsu && eSsu.main && (
                <SubjurProfileAccordion
                  entry={eSsu}
                  subjur={subjurSlug}
                  jur={jur}
                  content={
                    <div className="jpjs__countryBox">
                      <div className="noData">
                        <SubJurChartSsu
                          eEntry={eSsu}
                          jur={jur}
                          subArea={15}
                          noShow={
                            !(
                              eSsu &&
                              eSsu.main &&
                              (eSsu.main.marriage_type.id === '2' ||
                                eSsu.main.civil_type.id === '2')
                            )
                          }
                        />
                      </div>
                      {eSsu &&
                      eSsu.main &&
                      !eSsu.main.marriage_explan &&
                      !eSsu.main.civil_explan &&
                      eSsu.main.marriage_type.id === '6' &&
                      eSsu.main.civil_type.id === '6' ? (
                        <div className="noData">
                          <div>
                            <NoteRemoveIcon size={'50px'} color="#aaa" />
                          </div>
                          <div className="noDataText">
                            {NoData({ jur })['A1-15']['no'][i18n.language]}
                          </div>
                        </div>
                      ) : (
                        <div className="a2ts__body table-responsive">
                          {eSsu && eSsu.main && (
                            <SimpleTable
                              columns={[
                                {
                                  Header: 'TYPE OF UNION',
                                  accessor: 'type',
                                  Cell: ({ value, row }) => {
                                    return <>{row.original.name}</>
                                  },
                                  className: 'textCenter col190Protection jur'
                                }
                              ].concat(
                                a115columns()
                                  .filter(
                                    c =>
                                      c.accessor !==
                                        'motherEntry.jurisdiction.name' &&
                                      c.accessor.indexOf('civil_') === -1
                                  )
                                  .map(c =>
                                    c.NeutralHeader
                                      ? { ...c, Header: c.NeutralHeader }
                                      : c
                                  )
                                  .map(c =>
                                    c.isHidden
                                      ? { ...c, isHidden: false }
                                      : c.accessor === 'marriage_type.name'
                                      ? { ...c, Header: 'Value' }
                                      : c
                                  )
                                  .map(c =>
                                    c.NonExpandableCell
                                      ? { ...c, Cell: c.NonExpandableCell }
                                      : c
                                  )
                              )}
                              entries={['marriage_', 'civil_'].map(c => {
                                let tmpObj = {
                                  ...eSsu.main,
                                  type: c.replace('_', '').toUpperCase()
                                }
                                for (let key of Object.keys(eSsu.main)) {
                                  if (key.indexOf(c) !== -1) {
                                    tmpObj = {
                                      ...tmpObj,
                                      ...(c === 'marriage_'
                                        ? {
                                            section: 1
                                          }
                                        : {
                                            section: 2
                                          }),
                                      [key.replace(c, 'marriage_')]: eSsu.main[
                                        key
                                      ],
                                      name:
                                        c === 'marriage_'
                                          ? t('Marriage').toUpperCase()
                                          : t('Civil Union').toUpperCase()
                                    }
                                  }
                                }
                                return tmpObj
                              })}
                            />
                          )}
                        </div>
                      )}
                      {!subjurSlug && (
                        <SubjurButtons eEntry={eSsu} setEEntry={setESsu} />
                      )}
                    </div>
                  }
                />
              )}
              <SubjurList
                getColumns={entry =>
                  [
                    {
                      Header: 'TYPE OF UNION',
                      accessor: 'type',
                      Cell: ({ value, row }) => {
                        return <>{row.original.name}</>
                      },
                      className: 'textCenter col190Protection jur'
                    }
                  ].concat(
                    a115columns()
                      .filter(
                        c =>
                          c.accessor !== 'motherEntry.jurisdiction.name' &&
                          c.accessor.indexOf('civil_') === -1
                      )
                      .map(c =>
                        c.NeutralHeader ? { ...c, Header: c.NeutralHeader } : c
                      )
                      .map(c =>
                        c.isHidden
                          ? { ...c, isHidden: false }
                          : c.accessor === 'marriage_type.name'
                          ? { ...c, Header: 'Value' }
                          : c
                      )
                      .map(c =>
                        c.NonExpandableCell
                          ? { ...c, Cell: c.NonExpandableCell }
                          : c
                      )
                  )
                }
                eEntry={eSsu}
                entriesSplit={entry =>
                  ['marriage_', 'civil_'].map(c => {
                    let tmpObj = {
                      ...entry,
                      type: c.replace('_', '').toUpperCase()
                    }
                    for (let key of Object.keys(entry)) {
                      if (key.indexOf(c) !== -1) {
                        tmpObj = {
                          ...tmpObj,
                          ...(c === 'marriage_'
                            ? {
                                section: 1
                              }
                            : {
                                section: 2
                              }),
                          [key.replace(c, 'marriage_')]: entry[key],
                          name:
                            c === 'marriage_'
                              ? t('Marriage').toUpperCase()
                              : t('Civil Union').toUpperCase()
                        }
                      }
                    }
                    return tmpObj
                  })
                }
                jur={jur}
              />
            </div>
          </div>
        </div>
      </section>

      <section ref={section16} className="section14-jur1">
        <div className="jpjs__innerArea">
          <div className="jpjs__innerArea-header">
            <div className="content__container">
              <h4 className="jpia__title">
                15. {t('Adoption by same-sex couples')}
              </h4>
              <div className="jurCiteBox">
                <>
                  {eAdo &&
                  eAdo.main &&
                  eAdo.main.joint_adoption_type.id === '2' &&
                  eAdo.main.second_parent_adoption_type.id === '2' ? (
                    <></>
                  ) : eAdo &&
                    eAdo.main &&
                    (eAdo.main.joint_adoption_type.id === '3' ||
                      eAdo.main.second_parent_adoption_type.id === '3') ? (
                    <></>
                  ) : (
                    <span
                      onClick={e => {
                        citeA116(eAdo.main, setToast)
                      }}
                    >
                      <CiteIcon
                        color={'#707070'}
                        size={25}
                        title={t('Cite Entry')}
                      />
                    </span>
                  )}
                  <span onClick={e => setReportErrorOpen(eAdo.main)}>
                    <ReportIcon
                      color={'#707070'}
                      size={25}
                      title={t('Report error or send comments')}
                    />
                  </span>
                </>

                {subjurSlug && (
                  <button
                    className="jps__button btn btn-link"
                    onClick={e =>
                      history.push(
                        getPath('jur_profile').replace(
                          ':slug',
                          jur.slug.toLowerCase()
                        )
                      )
                    }
                  >
                    {t('Back to {{jur}} profile', { jur: jur.name })}
                  </button>
                )}
                <button
                  className="jps__button btn btn-link"
                  onClick={e =>
                    history.push(
                      theRoutes.find(r => r.subareaNumber === 16).path
                    )
                  }
                >
                  {t('Go to world data')}
                </button>
              </div>
            </div>
          </div>
          <div className="jpjs__innerArea-body">
            <div className="content__container">
              {eAdo && !subjurSlug && (
                <SubjurChart
                  eEntry={eAdo}
                  jur={jur}
                  noShow={
                    !eAdo ||
                    !eAdo.main ||
                    !(
                      eAdo.main.joint_adoption_type.id === '3' ||
                      eAdo.main.second_parent_adoption_type.id === '3'
                    )
                  }
                />
              )}
              {eAdo && eAdo.main && (
                <SubjurProfileAccordion
                  entry={eAdo}
                  subjur={subjurSlug}
                  jur={jur}
                  content={
                    <div className="jpjs__countryBox">
                      {eAdo &&
                      eAdo.main &&
                      !eAdo.main.joint_explan &&
                      !eAdo.main.second_parent_explan &&
                      eAdo.main.joint_adoption_type.id === '2' &&
                      eAdo.main.second_parent_adoption_type.id === '2' ? (
                        <div className="noData">
                          <div>
                            <NoteRemoveIcon size={'50px'} color="#aaa" />
                          </div>
                          <div className="noDataText">
                            {NoData({ jur })['A1-16']['no'][i18n.language]}
                          </div>
                        </div>
                      ) : (
                        <div className="a2ts__body table-responsive">
                          {eAdo && eAdo.main && (
                            <SimpleTable
                              entriese={[eAdo.main]}
                              entries={['joint_', 'second_parent_'].map(c => {
                                let tmpObj = {
                                  ...eAdo.main,
                                  name: i18n
                                    .t(c)
                                    .toUpperCase()
                                    .replace('JOINT_', 'JOINT ADOPTION'),
                                  ...(c === 'second_parent_' && {
                                    skipField: 'joint_explan'
                                  })
                                }

                                for (let key of Object.keys(eAdo.main)) {
                                  if (key.indexOf(c) !== -1) {
                                    tmpObj = {
                                      ...tmpObj,
                                      [key.replace(c, 'joint_')]: eAdo.main[key]
                                    }
                                  }
                                }
                                return tmpObj
                              })}
                              columns={[
                                {
                                  Header: i18n.t('Category'),
                                  accessor: 'type',
                                  Cell: ({ value, row }) => {
                                    return <>{row.original.name}</>
                                  },
                                  className: 'textCenter col190Protection jur'
                                }
                              ].concat(
                                a116columns()
                                  .filter(
                                    c =>
                                      c.accessor !==
                                        'motherEntry.jurisdiction.name' &&
                                      c.accessor.indexOf('second_parent_') ===
                                        -1
                                  )
                                  .map(c =>
                                    c.NeutralHeader
                                      ? { ...c, Header: c.NeutralHeader }
                                      : c
                                  )
                                  .map(c =>
                                    c.isHidden
                                      ? { ...c, isHidden: false }
                                      : c.accessor ===
                                        'joint_adoption_type.name'
                                      ? { ...c, Header: 'Value' }
                                      : c
                                  )
                                  .map(c =>
                                    c.NonExpandableCell
                                      ? { ...c, Cell: c.NonExpandableCell }
                                      : c
                                  )
                              )}
                            />
                          )}
                        </div>
                      )}
                      {!subjurSlug && (
                        <SubjurButtons eEntry={eAdo} setEEntry={setEAdo} />
                      )}
                    </div>
                  }
                />
              )}
              <SubjurList
                getColumns={entry =>
                  [
                    {
                      Header: i18n.t('Category'),
                      accessor: 'type',
                      Cell: ({ value, row }) => {
                        return <>{row.original.name}</>
                      },
                      className: 'textCenter col190Protection jur'
                    }
                  ].concat(
                    a116columns()
                      .filter(
                        c =>
                          c.accessor !== 'motherEntry.jurisdiction.name' &&
                          c.accessor.indexOf('second_parent_') === -1
                      )
                      .map(c =>
                        c.NeutralHeader ? { ...c, Header: c.NeutralHeader } : c
                      )
                      .map(c =>
                        c.isHidden
                          ? { ...c, isHidden: false }
                          : c.accessor === 'joint_adoption_type.name'
                          ? { ...c, Header: 'Value' }
                          : c
                      )
                      .map(c =>
                        c.NonExpandableCell
                          ? { ...c, Cell: c.NonExpandableCell }
                          : c
                      )
                  )
                }
                eEntry={eAdo}
                entriesSplit={entry =>
                  ['joint_', 'second_parent_'].map(c => {
                    let tmpObj = {
                      ...entry,
                      name: i18n
                        .t(c)
                        .toUpperCase()
                        .replace('JOINT_', 'JOINT ADOPTION'),
                      ...(c === 'second_parent_' && {
                        skipField: 'joint_explan'
                      })
                    }
                    for (let key of Object.keys(entry)) {
                      if (key.indexOf(c) !== -1) {
                        tmpObj = {
                          ...tmpObj,
                          [key.replace(c, 'joint_')]: entry[key]
                        }
                      }
                    }
                    return tmpObj
                  })
                }
                jur={jur}
              />
            </div>
          </div>
        </div>
      </section>

      <section ref={section17} className="section14-jur1">
        <div className="jpjs__innerArea">
          <div className="jpjs__innerArea-header">
            <div className="content__container">
              <h4 className="jpia__title">
                16.{' '}
                {t(
                  'Restrictions of non-vital medical interventions aimed at modifying the sex characteristics of intersex minors without their free, prior and full informed consent'
                )}
              </h4>
              <div className="jurCiteBox">
                <>
                  {ePnc &&
                  ePnc.main &&
                  ePnc.main.pnc_type.id === '1' &&
                  !ePnc.main.explan ? (
                    <></>
                  ) : ePnc && ePnc.main && ePnc.main.pnc_type.id === '3' ? (
                    <></>
                  ) : (
                    <span
                      onClick={e => {
                        citeA117(ePnc.main, setToast)
                      }}
                    >
                      <CiteIcon
                        color={'#707070'}
                        size={25}
                        title={t('Cite Entry')}
                      />
                    </span>
                  )}
                  <span onClick={e => setReportErrorOpen(ePnc.main)}>
                    <ReportIcon
                      color={'#707070'}
                      size={25}
                      title={t('Report error or send comments')}
                    />
                  </span>
                </>

                {subjurSlug && (
                  <button
                    className="jps__button btn btn-link"
                    onClick={e =>
                      history.push(
                        getPath('jur_profile').replace(
                          ':slug',
                          jur.slug.toLowerCase()
                        )
                      )
                    }
                  >
                    {t('Back to {{jur}} profile', { jur: jur.name })}
                  </button>
                )}
                <button
                  className="jps__button btn btn-link"
                  onClick={e =>
                    history.push(
                      theRoutes.find(r => r.subareaNumber === 17).path
                    )
                  }
                >
                  {t('Go to world data')}
                </button>
              </div>
            </div>
          </div>
          <div className="jpjs__innerArea-body">
            <div className="content__container">
              {ePnc && !subjurSlug && (
                <SubjurChart
                  eEntry={ePnc}
                  jur={jur}
                  subArea={17}
                  noShow={!ePnc || !ePnc.main || ePnc.main.pnc_type.id !== '3'}
                />
              )}
              {ePnc && ePnc.main && (
                <SubjurProfileAccordion
                  entry={ePnc}
                  subjur={subjurSlug}
                  jur={jur}
                  content={
                    <div className="jpjs__countryBox">
                      {ePnc &&
                      ePnc.main &&
                      ePnc.main.pnc_type.id === '1' &&
                      !ePnc.main.explan ? (
                        <div className="noData">
                          <div>
                            <NoteRemoveIcon size={'50px'} color="#aaa" />
                          </div>
                          <div className="noDataText">
                            {NoData({ jur })['A1-17']['no'][i18n.language]}
                          </div>
                        </div>
                      ) : (
                        <div className="a2ts__body table-responsive">
                          {ePnc && ePnc.main && (
                            <SimpleTable
                              columns={a117columns()
                                .filter(
                                  c =>
                                    c.accessor !==
                                    'motherEntry.jurisdiction.name'
                                )
                                .map(c =>
                                  c.isHidden ? { ...c, isHidden: false } : c
                                )}
                              entries={[ePnc.main]}
                            />
                          )}
                        </div>
                      )}
                      {!subjurSlug && (
                        <SubjurButtons eEntry={ePnc} setEEntry={setEPnc} />
                      )}
                    </div>
                  }
                />
              )}
              <SubjurList
                getColumns={entry =>
                  a117columns()
                    .filter(c => c.accessor !== 'motherEntry.jurisdiction.name')
                    .map(c => (c.isHidden ? { ...c, isHidden: false } : c))
                }
                eEntry={ePnc}
                jur={jur}
                noData={(entry, jur) =>
                  entry.pnc_type.id === '1' &&
                  !entry.explan && (
                    <div className="noData">
                      <div className="noDataText">
                        {NoData({ jur })['A1-17']['no'][i18n.language]}
                      </div>
                    </div>
                  )
                }
              />
            </div>
          </div>
        </div>
      </section>

      <section ref={section18} className="section18-jur1">
        <div className="jpjs__innerArea">
          <div className="jpjs__innerArea-header">
            <div className="content__container">
              <h4 className="jpia__title">
                17. {t('Legal Gender Recognition')}
              </h4>
              <div className="jurCiteBox">
                <>
                  {' '}
                  {eLgr &&
                  eLgr.main &&
                  eLgr.main.name_change_lgr_type.id === '6' ? (
                    <></>
                  ) : eLgr &&
                    eLgr.main &&
                    eLgr.main.name_change_lgr_type.id === '2' ? (
                    <></>
                  ) : eLgr &&
                    eLgr.main &&
                    eLgr.main.name_change_lgr_type.id === '7' ? (
                    <></>
                  ) : (
                    <span
                      onClick={e => {
                        citeA118(eLgr.main, setToast)
                      }}
                    >
                      <CiteIcon
                        color={'#707070'}
                        size={25}
                        title={t('Cite Entry')}
                      />
                    </span>
                  )}
                  <span onClick={e => setReportErrorOpen(eLgr.main)}>
                    <ReportIcon
                      color={'#707070'}
                      size={25}
                      title={t('Report error or send comments')}
                    />
                  </span>
                </>

                {subjurSlug && (
                  <button
                    className="jps__button btn btn-link"
                    onClick={e =>
                      history.push(
                        getPath('jur_profile').replace(
                          ':slug',
                          jur.slug.toLowerCase()
                        )
                      )
                    }
                  >
                    {t('Back to {{jur}} profile', { jur: jur.name })}
                  </button>
                )}
                <button
                  className="jps__button btn btn-link"
                  onClick={e =>
                    history.push(
                      theRoutes.find(r => r.subareaNumber === 18).path
                    )
                  }
                >
                  {t('Go to world data')}
                </button>
              </div>
            </div>
          </div>
          <div className="jpjs__innerArea-body">
            <div className="content__container">
              <h6 className="jps__summaryRow-subHeading">
                17 A. {t('Name change')}
              </h6>
              {eLgr && !subjurSlug && (
                <SubjurChart
                  eEntry={eLgr}
                  jur={jur}
                  subArea={181}
                  noShow={
                    !eLgr ||
                    !eLgr.main ||
                    eLgr.main.name_change_lgr_type.id !== '7'
                  }
                />
              )}
              {eLgr && eLgr.main && (
                <SubjurProfileAccordion
                  entry={eLgr}
                  subjur={subjurSlug}
                  jur={jur}
                  content={
                    <div className="jpjs__countryBox">
                      {eLgr &&
                      eLgr.main &&
                      !eLgr.main.name_change_explan &&
                      eLgr.main.name_change_lgr_type.id === '6' ? (
                        <div className="noData">
                          <div>
                            <NoteRemoveIcon size={'50px'} color="#aaa" />
                          </div>
                          <div className="noDataText">
                            {
                              NoData({ jur })['A1-18-A']['nodata'][
                                i18n.language
                              ]
                            }
                          </div>
                        </div>
                      ) : eLgr &&
                        eLgr.main &&
                        !eLgr.main.name_change_explan &&
                        eLgr.main.name_change_lgr_type.id === '2' ? (
                        <div className="noData">
                          <div>
                            <NoteRemoveIcon size={'50px'} color="#aaa" />
                          </div>
                          <div className="noDataText">
                            {
                              NoData({ jur })['A1-18-A']['notpossible'][
                                i18n.language
                              ]
                            }
                          </div>
                        </div>
                      ) : (
                        <div className="a2ts__body table-responsive">
                          {eLgr && eLgr.main && (
                            <SimpleTable
                              columns={a118_nc_columns()
                                .filter(
                                  c =>
                                    c.accessor !==
                                    'motherEntry.jurisdiction.name'
                                )
                                .map(c =>
                                  c.isHidden ? { ...c, isHidden: false } : c
                                )
                                .map(c =>
                                  c.NonExpandableCell
                                    ? {
                                        ...c,
                                        Cell: c.NonExpandableCell
                                      }
                                    : c
                                )}
                              entries={[eLgr.main]}
                            />
                          )}
                        </div>
                      )}
                      {!subjurSlug && (
                        <SubjurButtons eEntry={eLgr} setEEntry={setELgr} />
                      )}
                    </div>
                  }
                />
              )}
              <SubjurList
                getColumns={entry =>
                  a118_nc_columns()
                    .filter(c => c.accessor !== 'motherEntry.jurisdiction.name')
                    .map(c => (c.isHidden ? { ...c, isHidden: false } : c))
                    .map(c =>
                      c.NonExpandableCell
                        ? {
                            ...c,
                            Cell: c.NonExpandableCell
                          }
                        : c
                    )
                }
                eEntry={eLgr}
                jur={jur}
              />
              <h6 className="jps__summaryRow-subHeading">
                17 B. {t('Gender Marker Change')}
              </h6>
              {eLgr &&
                !subjurSlug &&
                eLgr.main?.motherEntry?.jurisdiction?.id !== 'UN-185' && (
                  <SubjurChart
                    eEntry={{
                      ...eLgr,
                      n_sub: eLgr.n_sub_gm,
                      n_subp: eLgr.n_subp_gm
                    }}
                    jur={jur}
                    subArea={182}
                    noShow={
                      !eLgr ||
                      !eLgr.main ||
                      eLgr.main.gender_marker_lgr_type.id !== '7'
                    }
                  />
                )}
              {eLgr && eLgr.main && (
                <SubjurProfileAccordion
                  entry={eLgr}
                  subjur={subjurSlug}
                  jur={jur}
                  content={
                    <div className="jpjs__countryBox">
                      {eLgr &&
                      eLgr.main &&
                      !eLgr.main.gender_marker_explan &&
                      eLgr.main.gender_marker_lgr_type.id === '6' ? (
                        <div className="noData">
                          <div>
                            <NoteRemoveIcon size={'50px'} color="#aaa" />
                          </div>
                          <div className="noDataText">
                            {
                              NoData({ jur })['A1-18-B']['nodata'][
                                i18n.language
                              ]
                            }
                          </div>
                        </div>
                      ) : eLgr &&
                        eLgr.main &&
                        !eLgr.main.gender_marker_explan &&
                        eLgr.main.gender_marker_lgr_type.id === '2' ? (
                        <div className="noData">
                          <div>
                            <NoteRemoveIcon size={'50px'} color="#aaa" />
                          </div>
                          <div className="noDataText">
                            {
                              NoData({ jur })['A1-18-B']['notpossible'][
                                i18n.language
                              ]
                            }
                          </div>
                        </div>
                      ) : (
                        <div className="a2ts__body table-responsive">
                          {eLgr && eLgr.main && (
                            <SimpleTable
                              columns={a118_gm_columns()
                                .filter(
                                  c =>
                                    c.accessor !==
                                    'motherEntry.jurisdiction.name'
                                )
                                .map(c =>
                                  c.isHidden ? { ...c, isHidden: false } : c
                                )
                                .map(c =>
                                  c.NonExpandableCell
                                    ? {
                                        ...c,
                                        Cell: c.NonExpandableCell
                                      }
                                    : c
                                )}
                              entries={[eLgr.main]}
                            />
                          )}
                        </div>
                      )}
                      {!subjurSlug && (
                        <SubjurButtons eEntry={eLgr} setEEntry={setELgr} />
                      )}
                    </div>
                  }
                />
              )}
              <SubjurList
                getColumns={entry =>
                  a118_gm_columns()
                    .filter(c => c.accessor !== 'motherEntry.jurisdiction.name')
                    .map(c => (c.isHidden ? { ...c, isHidden: false } : c))
                    .map(c =>
                      c.NonExpandableCell
                        ? {
                            ...c,
                            Cell: c.NonExpandableCell
                          }
                        : c
                    )
                }
                eEntry={eLgr}
                jur={jur}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
