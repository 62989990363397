import React, { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'

import { useTranslation } from 'react-i18next'
import useGqlEntriesCsssa from '../../gql/useGqlEntriesCsssa'
import useGqlJurisdictions from '../../gql/useGqlJurisdictions'
import useGqlTextComponents from '../../gql/useGqlTextComponents'
import MainLayout from '../../layouts/MainLayout'
import MapTooltip from '../../mocks/components/StyledMapToolTips'
import '../../pages/Area.css'
import BarChart from '../components/Areas/A1-1/BarChart'
import LineChart from '../components/Areas/A1-1/LineChart'
import IntroSection from '../components/Areas/IntroSection'
import LatestUpdates from '../components/Areas/LatestUpdates'
import MapContainer from '../components/Map/MapContainer'
import microstates from '../components/Map/microstates.json'
import TableContainer from '../components/Table/TableContainer'
import TalliesSection from '../components/TalliesSection/TalliesSection'
import MainSpinner from '../components/ui/MainSpinner'
import { citeA11, getColumnsA11 } from '../config/columns'
import AreaContent from '../components/Areas/AreaContent'
import MapOld from '../../components/Map/MapOld'

export function useData({ showTooltip = true, useMapReferences = true }) {
  const [references, setReferences] = useState([])
  const [tableEntries, setTableEntries] = useState([])

  const [mapReferences, setMapReferences] = useState([])
  const [talliesNumbers, setTalliesNumbers] = useState([])
  const { entriesCsssa, loading, error } = useGqlEntriesCsssa()
  const {
    jurisdictions,
    loading: loadingJur,
    error: errorJur,
    jurisdictionsById,
    jurSubjurs
  } = useGqlJurisdictions()
  const { t } = useTranslation()

  const areaInfo = {
    title: ``,
    subTitle: 'MAP EDITOR',
    subSubTitle: ``,
    areaName: 'area-1'
  }

  useEffect(() => {
    if (
      !loading &&
      !error &&
      jurisdictions &&
      jurisdictions.length &&
      jurSubjurs &&
      jurSubjurs.length &&
      entriesCsssa &&
      entriesCsssa.length
    ) {
      let referencesTmp = []
      for (let j of jurisdictions) {
        let entry = entriesCsssa.find(
          e => e.motherEntry.jurisdiction.id === j.id
        )
        if (entry) referencesTmp.push(entry)
      }
      referencesTmp = entriesCsssa.map(e => ({
        id:
          e.motherEntry.subjurisdiction && e.motherEntry.subjurisdiction.id
            ? e.motherEntry.subjurisdiction.id
            : e.motherEntry.jurisdiction.a2_code,
        regions: e.motherEntry.jurisdiction.regions,
        microstate: microstates.find(
          ms => ms.A2 === e.motherEntry.jurisdiction.a2_code
        ),
        reference: e.entry_csssa_penalty.map_color,
        reference_text: e.entry_csssa_penalty.name,
        tooltip:
          showTooltip &&
          ReactDOMServer.renderToStaticMarkup(
            <MapTooltip
              entry={e}
              title={t('Criminalisation of consensual same-sex sexual acts')}
              area={'A1-1'}
              positive={e.legal}
              hasSubjur={entriesCsssa.find(
                e2 =>
                  e2.motherEntry.jurisdiction.id ===
                    e.motherEntry.jurisdiction.id &&
                  e2.motherEntry.subjurisdiction
              )}
            />
          ), //tooltip(e),
        entry: e
      }))
      const tmpTallyNumbers = [0, 0, 0]

      for (const entry of entriesCsssa) {
        if (
          !entry.motherEntry.subjurisdiction &&
          entry.motherEntry.jurisdiction.un_member
        ) {
          if (!entry.legal) {
            tmpTallyNumbers[0]++
          }
          tmpTallyNumbers[1] = 2
          if (entry.legal) {
            tmpTallyNumbers[2]++
          }
        }
      }
      tmpTallyNumbers[0] -= 2
      setTalliesNumbers(tmpTallyNumbers)
      setReferences(referencesTmp)
      setMapReferences(referencesTmp)
    }
  }, [jurisdictions, jurSubjurs, entriesCsssa, error, loading])

  return {
    entriesCsssa: {
      data: entriesCsssa,
      loading,
      error
    },
    jurisdictions: {
      data: jurisdictions,
      loading: loadingJur,
      error: errorJur,
      jurisdictionsById,
      jurSubjurs
    },
    references: useMapReferences ? mapReferences : references,
    setReferences: useMapReferences ? setMapReferences : setReferences,
    mapReferences,
    setMapReferences,
    tableEntries,
    setTableEntries,
    talliesNumbers,
    setTalliesNumbers,
    areaInfo
  }
}

export function Map({
  references,
  htmlReferences,
  jurisdictionsById,
  jurisdictions,
  jurSubjurs,
  areaInfo,
  mapLoaded,
  setMapLoaded,
  showTooltip = true,
  textComponents,
  include,
  ...props
}) {
  const { t, i18n } = useTranslation()

  const mapLegendItems = [
    { name: t('No criminalisation'), color: '#ffffff' },
    { name: t('De facto criminalisation'), color: '#F0DACA' },
    { name: t('Up to 8 years imprisonment'), color: '#EDB795' },
    { name: t('10 years to life in prison'), color: '#F5934B' },
    { name: t('Death penalty (possible)'), color: '#D6643A' },
    { name: t('Death penalty'), color: '#A03E13' }
  ]

  return (
    <MapContainer
      references={references}
      currentArea={'A1-E'}
      htmlReferences={htmlReferences}
      jurisdictionsById={jurisdictionsById}
      jurSubjurs={jurSubjurs}
      jurisdictions={jurisdictions}
      mapLegendItems={mapLegendItems}
      setMapLoaded={setMapLoaded}
      title={areaInfo.title}
      subTitle={areaInfo.subTitle}
      subSubTitle={areaInfo.subSubTitle}
      areaName={areaInfo.areaName}
      modalTitle={areaInfo.subSubTitle}
      showTooltip={showTooltip}
      editor
      modalContent={
        textComponents && textComponents.length && textComponents[0].map
      }
      include={include}
      {...props}
    />
  )
}
export default function Area11() {
  const htmlReferences = useRef()
  const { textComponents } = useGqlTextComponents('A1-1')
  const [mapLoaded, setMapLoaded] = useState(false)

  const { t, i18n } = useTranslation()
  const {
    entriesCsssa: { data: entriesCsssa },
    jurisdictions: { data: jurisdictions, jurisdictionsById, jurSubjurs },
    references,
    mapReferences,
    tableEntries,
    talliesNumbers,
    setTableEntries,
    areaInfo
  } = useData({ useMapReferences: false })
  const tooltip = e => {
    return ReactDOMServer.renderToStaticMarkup()
  }

  return (
    <MainLayout section="areas" areaInfo={areaInfo}>
      <div className="area__page area1__page A1-1">
        <section className="selectRegion__section">
          <Map
            areaInfo={areaInfo}
            references={mapReferences}
            htmlReferences={[]}
            showTooltip={false}
            jurisdictionsById={jurisdictionsById}
            jurisdictions={jurisdictions}
            jurSubjurs={jurSubjurs}
            mapLoaded={mapLoaded}
            setMapLoaded={setMapLoaded}
            textComponents={textComponents}
            jurisdictionsWithDrillDown={['US', 'AR']}
          />
        </section>
      </div>
      <br />
      <br />
      <br />
    </MainLayout>
  )
}
