import React, { useRef, useLayoutEffect, useState } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import * as d3geoP from 'd3-geo-projection'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import { useTranslation } from 'react-i18next'
import { history } from '../../../App.js'
import am4geodata_lang_ES from '../../../lang/maps/es'
import am4geodata_lang_EN from '../../../lang/maps/en'

import './Map.css'
import MainSpinner from '../ui/MainSpinner'
import LoadingBar from '../ui/LoadingBar'

import LoadingMap from '../ui/LoadingMap'

import './JurMap.scoped.css'
import { getPath } from '../../../config/routes.js'

const JurMap = React.forwardRef(
  (
    {
      jur,
      jurSubjurs,
      highRes,
      loadingData,
      subjur,
      jurisdictions,
      setChangeJur
    },
    chart
  ) => {
    const [lowQ, setLowQ] = useState(true)

    //FOR SETTING THE LABELS LOCATIONS ONLY
    const [lastState, _setLastState] = useState({})
    const lastStateRef = useRef(lastState)
    const setLastState = data => {
      lastStateRef.current = data
      _setLastState(data)
    }

    const [labelFontSize, _setLabelFontSize] = useState(3)
    const labelFontSizeRef = useRef(labelFontSize)
    const setLabelFontSize = data => {
      labelFontSizeRef.current = data
      _setLabelFontSize(data)
    }
    const { t, i18n } = useTranslation()
    /*
    { id: "0", name: "Global" },
    { id: "12", name: "Europe" },
    { id: "1", name: "Africa" },
    { id: "2", name: "Asia" },
    { id: 5, name: "NorthAmerica" },
    { id: "13", name: "LatinAmerica" },
    { id: "3", name: "Caribbean" },
    { id: "16", name: "Oceania" },
    { id: "14", name: "SWANA" }
*/

    useLayoutEffect(() => {
      console.log(jurSubjurs)
      /**
       * Load the map
       */
      const loadMap = async regionId => {
        //LOADING
        
        am4core.options.queue = true
        let tt = null
        try {
          tt = await import('../../../components/amcharts4-geodata/0_' +
            jur.a2_code +
            'Low')
        } catch (e) {}

        if (tt) {
          let x = am4core.create('chartdiv', am4maps.MapChart)

          if (i18n.language === 'es') x.geodataNames = am4geodata_lang_ES
          else x.geodataNames = am4geodata_lang_EN

          x.maxPanOut = 0
          x.seriesContainer.draggable = false
          x.seriesContainer.resizable = false
          if (jur.a2_code === 'RU') {
            x.homeZoomLevel = 2
            x.maxZoomLevel = 2
            x.minZoomLevel = 2
          } else {
            x.maxZoomLevel = 1
          }

          /*x.events.on("mappositionchanged", (ev) => {
      console.log(ev.target._zoomGeoPointReal);
      if (ev.target._zoomGeoPointReal.latitude > -4) {
        console.log(ev.target._zoomGeoPointReal);
        x.pan({ x: 0, y: -1 });
        console.log(ev.target._zoomGeoPointReal);
      }
    });*/
          let resolution = 'low'
          if (highRes) resolution = 'high'

          //x.deltaLatitude = 20;

          /**
           * Algorithm to shift maps if necessary
           * (i.e. Australia has + and - longitudes, they all need to be same symbl)
           */
          let countriesSeries = x.series.push(new am4maps.MapPolygonSeries())
          countriesSeries.useGeodata = true
          countriesSeries.hiddenState.transitionDuration = 5000
          countriesSeries.defaultState.transitionDuration = 5000
          if (subjur) countriesSeries.include = [subjur.id]

          let polygonTemplate = countriesSeries.mapPolygons.template
          polygonTemplate.tooltipText = '{name}'
          polygonTemplate.fill = am4core.color('#a0aa9f')

          polygonTemplate.stroke = am4core.color('#dde0d4')

          if (jur.has_subjur_profile) {
            polygonTemplate.events.on('hit', function(ev) {
              // DISABLE INDONESIA and USA REQUEST FROM LUCAS
              if (jur.a2_code === 'ID' || jur.a2_code === 'US') return
              console.log(ev.target.dataItem.dataContext.id)
              if (ev.target.dataItem.dataContext.id.indexOf('-') !== -1) {
                const subjurTmp = jurSubjurs.find(
                  j => j.id === ev.target.dataItem.dataContext.id
                )
                history.push(
                  getPath('subjur_profile').replace(
                    ':slug',
                    subjurTmp.slug.toLowerCase()
                  )
                )
              } else {
                const subjurTmp = jurisdictions.find(
                  j => j.a2_code === ev.target.dataItem.dataContext.id
                )
                console.log(jurisdictions, ev.target.dataItem.dataContext.id)
                history.push(
                  getPath('jur_profile').replace(
                    ':slug',
                    subjurTmp.slug.toLowerCase()
                  )
                )
                setChangeJur(true)
              }
            })
          }
          //polygonTemplate.strokeWidth = 1;

          x.useGeodata = true
          if (jur.a2_code === 'CA') {
            x.projection.d3Projection = d3geoP.geoRobinson()
          } else {
            x.projection = new am4maps.projections.Mercator()
          }
          x.geodata = tt.default
          if (jur.a2_code === 'RU') {
            x.deltaLongitude = 250
          }

          //chart.current = { map: x };

          // chart.current.map.projection.d3Projection = d3geoP.geoTimes();
        }
      }

      if (!loadingData) loadMap()
    }, [loadingData, jur, jurSubjurs])

    return (
      <div className="jurMapContainer">
        {loadingData ? (
          <>
            <LoadingMap text={t('Loading map') + '...'} />
          </>
        ) : (
          <>
            <div id="chartdiv" className="mapDiv" />
          </>
        )}
      </div>
    )
  }
)

export default JurMap
