import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { uniqueByKey } from "../../../lib/fnHelper";
import DropdownDate from "../Dropdown/DropdownDate";

export default function DateDropdownFilter({ column, options, title, loading = false, error = false, width }) {
  const { filterValue, setFilter } = column;
  const [filterLoading, setFilterLoading] = useState(false);
  const { i18n, t } = useTranslation();

  const bufferFilter = (options) => {
    setFilterLoading(true);
    setTimeout(() => setFilter(options), 300);
  };

  useEffect(() => {
    setFilterLoading(false);
  }, [filterValue]);

  return (
    <div>
      <DropdownDate
        title={title}
        items={options}
        loading={loading}
        error={error}
        multiSelect
        setTableFilter={bufferFilter}
        tableFilter={filterValue}
        filterLoading={filterLoading}
        width={width}
      />
    </div>
  );
}

export function ApplyDropdownDateFilter(rows, columnsId, filterValue) {
  if (filterValue && (filterValue.from || filterValue.to)) {
    let allFiltered = [];
    console.log(filterValue);
    for (let col of columnsId) {
      allFiltered = [
        ...allFiltered,
        ...rows.filter((row) => {
          if (row.original[col]) {
            let year = row.original[col];
            if (year.length > 4) year = dayjs(row.original[col]).format("YYYY");
            return (year >= filterValue.from || !filterValue.from) && (year <= filterValue.to || !filterValue.to);
          } else return false;
        }),
        ...rows.filter((row) => {
          return (
            row.subRows &&
            row.subRows.length &&
            row.subRows.filter((subrow) => {
              if (subrow.original[col]) {
                let year = subrow.original[col];
                if (year.length > 4) year = dayjs(year).format("YYYY");
                return (year >= filterValue.from || !filterValue.from) && (year <= filterValue.to || !filterValue.to);
              } else return false;
            })
          );
        })
      ];
    }
    allFiltered = uniqueByKey(allFiltered, "id");
    let filterTemp = [...allFiltered];

    allFiltered = allFiltered.map((row) => ({
      ...row,
      ...(row.original.cid && //Become new parent
        !filterTemp.find((r) => r.id === row.original.cid.split(".")[0]) && {
          original: { ...row.original, subRowsLength: 0, cid: "" }
        }),
      ...(!row.original.cid && {
        //Find childs
        original: {
          ...row.original,
          subRowsLength: filterTemp.filter((r) => r.original.cid && r.original.cid.split(".")[0] === row.id).length
        }
      })
    }));

    let noChilds = allFiltered.filter((r) => !r.original.cid);

    for (const row of allFiltered.filter((r) => r.original.cid)) {
      noChilds.splice(noChilds.findIndex((r) => r.id === row.original.cid.split(".")[0]) + 1, 0, row);
    }

    return noChilds;
  } else {
    return rows;
  }
}
