import React from "react";

export const staticIlgaLogo = `<svg
  id="Group_469"
  className="ilgaLogo menuLogo"
  data-name="Group 469"
  xmlns="http://www.w3.org/2000/svg"
  width="92.853"
  height="53.75"
  viewBox="0 0 74.282 43.508"
>
  <g id="Group_467" data-name="Group 467" transform="translate(48.373 34.981)">
    <path
      id="Path_653"
      data-name="Path 653"
      d="M194.8,202.864l-.914,2.71a.325.325,0,0,1-.343.244h-.711a.3.3,0,0,1-.333-.254l-1.288-4.756a.514.514,0,0,1-.015-.114c0-.128.082-.192.249-.192h.93a.3.3,0,0,1,.327.26l.685,2.846a.021.021,0,0,0,.021.015c.006,0,.012,0,.015-.015l.774-2.84a.306.306,0,0,1,.332-.255h.6a.311.311,0,0,1,.338.255l.8,2.788c0,.01.008.015.015.015a.021.021,0,0,0,.021-.015l.638-2.794a.29.29,0,0,1,.323-.26h.929c.166,0,.249.068.249.2a.373.373,0,0,1-.015.1l-1.282,4.756a.307.307,0,0,1-.333.254h-.7a.328.328,0,0,1-.348-.244Z"
      transform="translate(-191.198 -198.373)"
      fill="#3d3d3d"
    />
    <path
      id="Path_654"
      data-name="Path 654"
      d="M205.2,200.382a2.608,2.608,0,0,1,1.911.8,2.634,2.634,0,0,1,.8,1.931,2.742,2.742,0,0,1-4.683,1.947,2.745,2.745,0,0,1-.011-3.873A2.686,2.686,0,0,1,205.2,200.382Zm-.036,1.444a1.284,1.284,0,0,0-1.282,1.287,1.251,1.251,0,0,0,.379.919,1.291,1.291,0,0,0,1.823.006,1.293,1.293,0,0,0-.919-2.212Z"
      transform="translate(-194.651 -198.336)"
      fill="#3d3d3d"
    />
    <path
      id="Path_655"
      data-name="Path 655"
      d="M212.864,200.7a1.554,1.554,0,0,1,1.028-.316h.441q.307-.01.3.3v.851a.256.256,0,0,1-.3.3h-.451a.9.9,0,0,0-.644.244.819.819,0,0,0-.281.607v2.794q.005.311-.3.3h-.841q-.317.01-.306-.3v-4.715q-.01-.311.306-.3h.758C212.753,200.458,212.85,200.536,212.864,200.7Z"
      transform="translate(-197.449 -198.336)"
      fill="#3d3d3d"
    />
    <path
      id="Path_656"
      data-name="Path 656"
      d="M217.21,204.571v-6.843c-.008-.208.095-.308.306-.3h.842q.305-.01.3.3v6.843q.005.311-.3.3h-.842C217.3,204.879,217.2,204.779,217.21,204.571Z"
      transform="translate(-199.204 -197.427)"
      fill="#3d3d3d"
    />
    <path
      id="Path_657"
      data-name="Path 657"
      d="M224.728,199.809v-2.081q0-.312.3-.3h.842c.211-.007.314.093.306.3v6.843c.008.208-.095.308-.306.3h-.763q-.3.016-.307-.265v-.031a2.937,2.937,0,0,1-1.387.379,2.741,2.741,0,0,1,0-5.483A2.786,2.786,0,0,1,224.728,199.809ZM223.4,200.9a1.235,1.235,0,0,0-.914.379,1.341,1.341,0,0,0,0,1.879,1.247,1.247,0,0,0,.925.379,1.293,1.293,0,0,0,1.308-1.318,1.287,1.287,0,0,0-.379-.94A1.266,1.266,0,0,0,223.4,200.9Z"
      transform="translate(-200.269 -197.427)"
      fill="#3d3d3d"
    />
  </g>
  <g id="Group_468" data-name="Group 468">
    <path
      id="Path_658"
      data-name="Path 658"
      d="M124.64,146.891a3.279,3.279,0,1,1-2.376,5.592,3.175,3.175,0,0,1-.948-2.337,3.122,3.122,0,0,1,.965-2.283A3.167,3.167,0,0,1,124.64,146.891Z"
      transform="translate(-121.315 -146.891)"
      fill="#3d3d3d"
    />
    <path
      id="Path_659"
      data-name="Path 659"
      d="M171.753,160.788v-.07q.024-1.237,1.355-1.162h3.453a1.154,1.154,0,0,1,1.352,1.358l-.016,20.007a12.8,12.8,0,0,1-3.9,9.412,12.582,12.582,0,0,1-9.289,3.84,12.717,12.717,0,0,1-6.788-1.984q-1.306-.821-1.306-1.521a1.385,1.385,0,0,1,.467-.908l2.242-2.4a1.365,1.365,0,0,1,.98-.533,1.28,1.28,0,0,1,.723.26,7.09,7.09,0,0,0,3.872,1.25,6.278,6.278,0,0,0,5.018-2.363,5.575,5.575,0,0,0,1.542-3.494.41.41,0,0,0-.023-.116.067.067,0,0,1-.024-.047,13.7,13.7,0,0,1-5.9,1.472,12.319,12.319,0,1,1,.044-24.638A13.211,13.211,0,0,1,171.753,160.788Zm-6.327,4.76a5.582,5.582,0,0,0-4.108,1.689,5.937,5.937,0,0,0,4.168,10.164,5.633,5.633,0,0,0,4.154-1.688,5.72,5.72,0,0,0,1.706-4.217,5.8,5.8,0,0,0-1.7-4.229A5.706,5.706,0,0,0,165.427,165.548Z"
      transform="translate(-131.113 -150.666)"
      fill="#3d3d3d"
    />
    <path
      id="Path_660"
      data-name="Path 660"
      d="M211.7,160.976v-.21q0-1.259,1.353-1.208h3.221a1.155,1.155,0,0,1,1.353,1.357l.009,21.182a1.146,1.146,0,0,1-1.353,1.348H213.06q-1.353.042-1.354-1.218v-.163a12.32,12.32,0,0,1-6.393,1.727,12.486,12.486,0,0,1-12.4-12.361,12.369,12.369,0,0,1,12.339-12.273A12.728,12.728,0,0,1,211.7,160.976Zm-6.509,4.573a5.576,5.576,0,0,0-4.106,1.688,5.942,5.942,0,0,0,4.181,10.162,5.631,5.631,0,0,0,4.153-1.688,5.71,5.71,0,0,0,1.7-4.216,5.8,5.8,0,0,0-1.706-4.229A5.708,5.708,0,0,0,205.189,165.549Z"
      transform="translate(-143.354 -150.666)"
      fill="#3d3d3d"
    />
    <path
      id="Path_661"
      data-name="Path 661"
      d="M126.508,159.74h-3.779q-1.422-.052-1.382,1.348l0,21.185q-.052,1.4,1.371,1.358h3.78q1.377.052,1.358-1.348l0-21.186Q127.885,159.7,126.508,159.74Z"
      transform="translate(-121.324 -150.845)"
      fill="#3d3d3d"
    />
    <path
      id="Path_662"
      data-name="Path 662"
      d="M135.91,148.383a1.157,1.157,0,0,1,1.36-1.36h3.8a1.157,1.157,0,0,1,1.36,1.36V171.3q0,2.744,2.65,2.744h.807q1.43-.024,1.383,1.384v2.931q.048,1.383-1.383,1.36h-2.543a7.4,7.4,0,0,1-5.44-1.994,7.318,7.318,0,0,1-1.994-5.417Z"
      transform="translate(-125.807 -146.931)"
      fill="#3d3d3d"
    />
  </g>
</svg>`

const IlgaLogo = () => {
  return (
    <svg
      id="Group_469"
      className="ilgaLogo menuLogo"
      data-name="Group 469"
      xmlns="http://www.w3.org/2000/svg"
      width="74.282"
      height="43.508"
      viewBox="0 0 74.282 43.508"
    >
      <g id="Group_467" data-name="Group 467" transform="translate(48.373 34.981)">
        <path
          id="Path_653"
          data-name="Path 653"
          d="M194.8,202.864l-.914,2.71a.325.325,0,0,1-.343.244h-.711a.3.3,0,0,1-.333-.254l-1.288-4.756a.514.514,0,0,1-.015-.114c0-.128.082-.192.249-.192h.93a.3.3,0,0,1,.327.26l.685,2.846a.021.021,0,0,0,.021.015c.006,0,.012,0,.015-.015l.774-2.84a.306.306,0,0,1,.332-.255h.6a.311.311,0,0,1,.338.255l.8,2.788c0,.01.008.015.015.015a.021.021,0,0,0,.021-.015l.638-2.794a.29.29,0,0,1,.323-.26h.929c.166,0,.249.068.249.2a.373.373,0,0,1-.015.1l-1.282,4.756a.307.307,0,0,1-.333.254h-.7a.328.328,0,0,1-.348-.244Z"
          transform="translate(-191.198 -198.373)"
          fill="#3d3d3d"
        />
        <path
          id="Path_654"
          data-name="Path 654"
          d="M205.2,200.382a2.608,2.608,0,0,1,1.911.8,2.634,2.634,0,0,1,.8,1.931,2.742,2.742,0,0,1-4.683,1.947,2.745,2.745,0,0,1-.011-3.873A2.686,2.686,0,0,1,205.2,200.382Zm-.036,1.444a1.284,1.284,0,0,0-1.282,1.287,1.251,1.251,0,0,0,.379.919,1.291,1.291,0,0,0,1.823.006,1.293,1.293,0,0,0-.919-2.212Z"
          transform="translate(-194.651 -198.336)"
          fill="#3d3d3d"
        />
        <path
          id="Path_655"
          data-name="Path 655"
          d="M212.864,200.7a1.554,1.554,0,0,1,1.028-.316h.441q.307-.01.3.3v.851a.256.256,0,0,1-.3.3h-.451a.9.9,0,0,0-.644.244.819.819,0,0,0-.281.607v2.794q.005.311-.3.3h-.841q-.317.01-.306-.3v-4.715q-.01-.311.306-.3h.758C212.753,200.458,212.85,200.536,212.864,200.7Z"
          transform="translate(-197.449 -198.336)"
          fill="#3d3d3d"
        />
        <path
          id="Path_656"
          data-name="Path 656"
          d="M217.21,204.571v-6.843c-.008-.208.095-.308.306-.3h.842q.305-.01.3.3v6.843q.005.311-.3.3h-.842C217.3,204.879,217.2,204.779,217.21,204.571Z"
          transform="translate(-199.204 -197.427)"
          fill="#3d3d3d"
        />
        <path
          id="Path_657"
          data-name="Path 657"
          d="M224.728,199.809v-2.081q0-.312.3-.3h.842c.211-.007.314.093.306.3v6.843c.008.208-.095.308-.306.3h-.763q-.3.016-.307-.265v-.031a2.937,2.937,0,0,1-1.387.379,2.741,2.741,0,0,1,0-5.483A2.786,2.786,0,0,1,224.728,199.809ZM223.4,200.9a1.235,1.235,0,0,0-.914.379,1.341,1.341,0,0,0,0,1.879,1.247,1.247,0,0,0,.925.379,1.293,1.293,0,0,0,1.308-1.318,1.287,1.287,0,0,0-.379-.94A1.266,1.266,0,0,0,223.4,200.9Z"
          transform="translate(-200.269 -197.427)"
          fill="#3d3d3d"
        />
      </g>
      <g id="Group_468" data-name="Group 468">
        <path
          id="Path_658"
          data-name="Path 658"
          d="M124.64,146.891a3.279,3.279,0,1,1-2.376,5.592,3.175,3.175,0,0,1-.948-2.337,3.122,3.122,0,0,1,.965-2.283A3.167,3.167,0,0,1,124.64,146.891Z"
          transform="translate(-121.315 -146.891)"
          fill="#3d3d3d"
        />
        <path
          id="Path_659"
          data-name="Path 659"
          d="M171.753,160.788v-.07q.024-1.237,1.355-1.162h3.453a1.154,1.154,0,0,1,1.352,1.358l-.016,20.007a12.8,12.8,0,0,1-3.9,9.412,12.582,12.582,0,0,1-9.289,3.84,12.717,12.717,0,0,1-6.788-1.984q-1.306-.821-1.306-1.521a1.385,1.385,0,0,1,.467-.908l2.242-2.4a1.365,1.365,0,0,1,.98-.533,1.28,1.28,0,0,1,.723.26,7.09,7.09,0,0,0,3.872,1.25,6.278,6.278,0,0,0,5.018-2.363,5.575,5.575,0,0,0,1.542-3.494.41.41,0,0,0-.023-.116.067.067,0,0,1-.024-.047,13.7,13.7,0,0,1-5.9,1.472,12.319,12.319,0,1,1,.044-24.638A13.211,13.211,0,0,1,171.753,160.788Zm-6.327,4.76a5.582,5.582,0,0,0-4.108,1.689,5.937,5.937,0,0,0,4.168,10.164,5.633,5.633,0,0,0,4.154-1.688,5.72,5.72,0,0,0,1.706-4.217,5.8,5.8,0,0,0-1.7-4.229A5.706,5.706,0,0,0,165.427,165.548Z"
          transform="translate(-131.113 -150.666)"
          fill="#3d3d3d"
        />
        <path
          id="Path_660"
          data-name="Path 660"
          d="M211.7,160.976v-.21q0-1.259,1.353-1.208h3.221a1.155,1.155,0,0,1,1.353,1.357l.009,21.182a1.146,1.146,0,0,1-1.353,1.348H213.06q-1.353.042-1.354-1.218v-.163a12.32,12.32,0,0,1-6.393,1.727,12.486,12.486,0,0,1-12.4-12.361,12.369,12.369,0,0,1,12.339-12.273A12.728,12.728,0,0,1,211.7,160.976Zm-6.509,4.573a5.576,5.576,0,0,0-4.106,1.688,5.942,5.942,0,0,0,4.181,10.162,5.631,5.631,0,0,0,4.153-1.688,5.71,5.71,0,0,0,1.7-4.216,5.8,5.8,0,0,0-1.706-4.229A5.708,5.708,0,0,0,205.189,165.549Z"
          transform="translate(-143.354 -150.666)"
          fill="#3d3d3d"
        />
        <path
          id="Path_661"
          data-name="Path 661"
          d="M126.508,159.74h-3.779q-1.422-.052-1.382,1.348l0,21.185q-.052,1.4,1.371,1.358h3.78q1.377.052,1.358-1.348l0-21.186Q127.885,159.7,126.508,159.74Z"
          transform="translate(-121.324 -150.845)"
          fill="#3d3d3d"
        />
        <path
          id="Path_662"
          data-name="Path 662"
          d="M135.91,148.383a1.157,1.157,0,0,1,1.36-1.36h3.8a1.157,1.157,0,0,1,1.36,1.36V171.3q0,2.744,2.65,2.744h.807q1.43-.024,1.383,1.384v2.931q.048,1.383-1.383,1.36h-2.543a7.4,7.4,0,0,1-5.44-1.994,7.318,7.318,0,0,1-1.994-5.417Z"
          transform="translate(-125.807 -146.931)"
          fill="#3d3d3d"
        />
      </g>
    </svg>
  );
};

export default IlgaLogo;
