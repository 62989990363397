export const calculateProtectionValue = (entry, entries, onlyTypes) => {
  let types = ["so", "gi", "ge", "sc"];
  if (onlyTypes.length) {
    types = onlyTypes;
  }
  let val = 0;
  for (let t of types) {
    val += entry[t + "_protection_type"].id === "1" ? 1 : 0;
    if (entry[t + "_protection_type"].id === "6") {
      let subJurEntries = entries.filter(
        e => e.motherEntry.jurisdiction.id === entry.motherEntry.jurisdiction.id && e.motherEntry.subjurisdiction
      );
      for (let subjurEntry of subJurEntries) {
        val += subjurEntry[t + "_protection_type"].id === "1" ? 1 / subJurEntries.length : 0;
      }
    }
  }
  return val / (onlyTypes.length ? onlyTypes.length : 4);
};

export const isRecommendation = entry => {
  return entry.treaty_body_cu_type.name.toLowerCase().indexOf("(recom") !== -1;
};

export const isRecommendationSP = entry => {
  if (entry.special_procedure_cu_type)
    return entry.special_procedure_cu_type.name.toLowerCase().indexOf("(recom") !== -1;
  else return entry.toLowerCase().indexOf("(recom") !== -1; //just sending the string
};
