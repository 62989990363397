import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { useTranslation } from "react-i18next";
import UNMemberSwitch from "../../Charts/UNMemberSwitch";
import LoadingBar from "../../ui/LoadingBar";
import { cArea1BarChartRegions, cMainRegions } from "../../../../config/constants";

export default function BarChart({ references, noTitle = "No protection" }) {
  const [unMember, setUnMember] = useState(true);
  const { t, i18n } = useTranslation();
  const loading = useRef();
  const loadChart = () => {
    if (loading.current) loading.current.style.display = "block";
    am4core.options.queue = false;

    /* Chart code */
    // Themes begin
    //am4core.unuseAllThemes();
    
    // Themes end

    // Create chart instance
    let chart = am4core.create("barChartDiv", am4charts.XYChart);

    let newReferences = references.filter(r => (unMember ? r.entry.motherEntry.jurisdiction.un_member : true));

    chart.events.on("ready", () => {
      if (loading.current) loading.current.style.display = "none";
    });

    const regions = cArea1BarChartRegions();

    chart.data = [];
    let i = 0;
    let j = 0;
    let ptypes = ["so", "gi", "ge", "sc"];
    let ptypesN = {
      so: t("Sexual orientation"),
      gi: t("Gender Identity"),
      ge: t("Gender Expression"),
      sc: t("Sex characteristics")
    };
    let pcolorsN = {
      so: "#AB97CE",
      gi: "#96C9DB",
      ge: "#E08AA2",
      sc: "#F0C85C"
    };
    const regs = cMainRegions();
    for (let reg of regs) {
      let data = { region: reg.name };
      for (let type of ptypes) {
        data = {
          ...data,
          region_name: reg.name,
          region: reg.name + "_" + type,
          [type + "yes"]: 0,
          [type + "no"]: 0
        };

        for (let ref of newReferences) {
          if (!ref.entry.motherEntry.subjurisdiction)
            if (ref.regions.find(r => r.id === reg.id)) {
              //              console.log(data[parseInt(ref.entry[type + "_protection_type"].id) > 5 ? type + "no" : type + "yes"]);
              data[parseInt(ref.entry[type + "_protection_type"].id) !== 1 ? type + "no" : type + "yes"]++;
            }
        }
        //if (!chart.data[i][type + "yes"]) chart.data[i][type + "yes"] = null;
        //if (!chart.data[i][type + "no"]) chart.data[i][type + "no"] = null;

        i++;
      }
      j++;
      chart.data.push(data);
    }

    chart.tooltip.label.maxWidth = 150;
    chart.tooltip.label.wrap = true;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "region";

    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 5;

    categoryAxis.renderer.inversed = false;

    categoryAxis.renderer.cellStartLocation = 0.15;
    categoryAxis.renderer.cellEndLocation = 0.85;

    var axisLabels = categoryAxis.renderer.labels.template;
    /*axisLabels.fontSize = 17;
    axisLabels.fontWeight = "bold";
    axisLabels.fontFamily = "Tahoma";
    axisLabels.fill = "#ff0000"; // labels color*/
    axisLabels.html = "{region_name}";

    /*
    categoryAxis.renderer.labels.template.adapter.add("dy", function (dy, target) {
      if (target.dataItem && target.dataItem.index & (2 == 2)) {
        return dy + 15;
      }
      return dy;
    });*/

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;

    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;

    valueAxis.renderer.minGridDistance = 20;
    valueAxis.renderer.minWidth = 35;

    valueAxis.min = 0;

    // Create series
    function createSeries(field, name, stacked) {
      // Set up series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.nameA = "lkkk";
      series.dataFields.valueY = field;
      series.dataFields.valueYes = 123;

      series.dataFields.categoryX = "region";

      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;
      //series.clustered = true;

      series.columns.template.propertyFields.dx = "dx";

      series.tooltip.pointerOrientation = "vertical";

      // Configure columns
      series.columns.template.width = am4core.percent(70);
      series.tooltip.label.textAlign = "middle";
      series.columns.template.tooltipText =
        "[bold]{region_name}[/] - [font-size:14px]" +
        ptypesN[field[0] + field[1]] +
        "\n{valueY} " +
        (field[2] === "y" ? " " + t("jurisdictions with protection") : " " + t("jurisdictions with no protection"));

      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color("#FFF");
      series.tooltip.background.fillOpacity = 1;
      series.tooltip.label.fill = am4core.color("#575757");

      series.columns.template.strokeWidth = 0;
      if (field[2] === "y") series.columns.template.fill = pcolorsN[field[0] + field[1]] + "";
      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.dy = -8;

      series.stacked = stacked;

      if (field.indexOf("yes") !== -1) {
        labelBullet.label.text = "[bold #537c8b]{" + field[0] + field[1] + "yes}[/]";
        series.fill = am4core.color("#6EA2B5");
        series.stroke = am4core.color("#6EA2B5");
      } else {
        labelBullet.label.text = "";
        series.fill = am4core.color("#eee");
        series.stroke = am4core.color("#eee");
      }
      //series.legendSettings.labelText = "";

      if (field.indexOf("so") !== -1) series.legendSettings.labelText = t("SO");
      if (field.indexOf("gi") !== -1) series.legendSettings.labelText = t("GI");
      if (field.indexOf("ge") !== -1) series.legendSettings.labelText = t("GE");
      if (field.indexOf("sc") !== -1) series.legendSettings.labelText = t("SC");
      if (field.indexOf("no") !== -1 && field.indexOf("sc") === -1) series.hiddenInLegend = true;
      else if (field.indexOf("no") !== -1) {
        series.legendSettings.labelText = noTitle;
      }
      return series;
    }

    createSeries("soyes", t("Wwcpl"));
    createSeries("sono", t("Wowcpl"), true);
    createSeries("giyes", t("Wwcpl"));
    createSeries("gino", t("Wowcpl"), true);
    createSeries("geyes", t("Wwcpl"));
    createSeries("geno", t("Wowcpl"), true);
    createSeries("scyes", t("Wwcpl"));
    createSeries("scno", t("Wowcpl"), true);

    // Legend
    chart.legend = new am4charts.Legend();
    //chart.legend.reverseOrder = true;
    //chart.legend.labels.template.disabled = true;
    //chart.legend.disabled = true;
    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;
    chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
  };
  useEffect(() => {
    loadChart();
  }, [unMember]);
  return (
    <div style={{ minHeight: "400px", position: "relative" }}>
      <LoadingBar background="#f4f8f8" ref={loading} text={t("Loading chart") + "..."} />
      <div id="barChartDiv" style={{ minHeight: "400px" }} />
      <UNMemberSwitch unMember={unMember} setUnMember={setUnMember} />
    </div>
  );
}
