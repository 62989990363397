import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import routes, { getPath } from '../../config/routes'
import { history } from '../../App'

const Footer = () => {
  const { i18n, t } = useTranslation()
  const theRoutes = routes()
  return (
    <footer>
      <div className="footer__content">
        <div className="footer__siteMap">
          <div className="fc__areas">
            <Link to="/area-11" className="fc__topLink">
              {t('Areas')}
            </Link>
            <ul className="list-unstyled fc__lowerLinks">
              <li>
                <Link to={theRoutes.find(item => item.areaNumber === 1).path}>
                  {t('Area')} 1 | {t('Legal Frameworks')}
                </Link>
              </li>
              <li>
                <Link to={theRoutes.find(item => item.areaNumber === 2).path}>
                  {t('Area')} 2 | {t('International Human Rights Treaties')}
                </Link>
              </li>
              <li>
                <Link to={theRoutes.find(item => item.areaNumber === 3).path}>
                  {t('Area')} 3 | {t('UN Treaty Bodies')}
                </Link>
              </li>
              <li>
                <Link to={theRoutes.find(item => item.areaNumber === 4).path}>
                  {t('Area')} 4 | {t('UN Special Procedures')}
                </Link>
              </li>
              <li>
                <Link to={theRoutes.find(item => item.areaNumber === 5).path}>
                  {t('Area')} 5 | {t('Universal Periodic Review (UPR)')}
                </Link>
              </li>
             
            </ul>
          </div>
          <div className="fc__jurs">
            <Link to="#" className="fc__topLink">
              {t('Jurisdictions')}
            </Link>
            <ul className="list-unstyled fc__lowerLinks">
              <li>
                <Link to={getPath('jur_home')}>
                  {t('Search by jurisdiction')}
                </Link>
              </li>
            </ul>

            <Link to="#" className="fc__topLink">
              {t('Tools')}
            </Link>
            <ul className="list-unstyled fc__lowerLinks">
              <li>
                <Link to={getPath('compare_tool')}>{t('Compare tool')}</Link>
              </li>


              <li>
                <Link to={getPath('advanced_search')}>
                  {t('Advanced search')}
                </Link>
              </li>
             
            </ul>
          </div>
          <div className="fc__moreLinks">
            <Link to="/latest-updates" className="fc__topLink">
              {t('Latest updates')}
            </Link>
            <Link
              to={routes().find(r => r.id === 'upcoming').path}
              className="fc__topLink"
            >
              {t('Upcoming Deadlines')}
            </Link>
            <a
              href={t('https://ilga.org/about-us/contacts')}
              className="fc__topLink"
            >
              {t('Contact us')}
            </a>
          </div>
        </div>
        <ul className="footer__links">
          <li>
            <a href="https://www.ilga.org" target="_blank" rel="noreferrer">
              {t('ILGA World Website')}
            </a>
          </li>
          <li>
            <a href="https://monitor.ilga.org" target="_blank" rel="noreferrer">
              {t('ILGA World Monitor')}
            </a>
          </li>

          <li>
            <Link to={getPath('credits')}>{t('Credits')}</Link>
          </li>
        </ul>
        <p className="footer__copy">
          <span>
            © {t('Copyright')} 2017-{new Date().getFullYear()}
          </span>{' '}
          {t('ILGA World')} -{' '}
          {t(
            'The International Lesbian, Gay, Bisexual, Trans and Intersex Association'
          )}
        </p>
      </div>
    </footer>
  )
}

export default Footer
