export const introText = (
  <>
    <p>
      This section covers laws and regulations that have been created or used to restrict the right to freedom of
      expression in relation to SOGIESC issues.
    </p>
    <p>
      Governments employ multiple ways to curtail discussions about SOGIESC topics, including by criminalising offences
      against morality and religion, limiting sex education curriculums, prohibiting promotion or “propaganda” of
      “non-traditional sexual relations”, prosecution for LGBT+ symbols under public manifestation and pornography laws,
      blocking thematic websites and publications, among many other ways.{" "}
    </p>
    <p>
      The chart below should be interpreted with a note that the section covers primarily legislative instruments.
      However, the freedom of expression on SOGIESC issues may be limited as an effect of generally repressive
      governmental policies on any type of freedom of expression, criminalisation of homosexuality, wide-spread societal
      homophobia and even self-censorship.
    </p>
  </>
);

export const methodologyText = [
  <div key={"mText"}>
    <p>
      <span style={{ fontWeight: 400 }}>
        The limitations on freedom of expression may take many forms: from the laws explicitly naming issues of sexual
        and gender diversity to the norms containing vague language relating to public morality, and apparently
        unrelated laws which are used to restrict free exchange of ideas on LGBT topics.
      </span>
    </p>
    <p>
      <span style={{ fontWeight: 400 }}>
        In tracking and systematising these laws, it is problematic to try to strictly set apart legal restrictions
        related to issues of sexual orientation from those that relate to gender identity and gender expression.
        Legislators use a plethora of legal proxies to target LGBT issues, from ambiguous “non-traditional sexual
        relationships” and “gender theory” to offensive terms describing issues of sexuality which, in practice, are
        used to target people of diverse gender identities and expressions. Therefore, dividing the laws based on
        whether they target sexual orientation only or combined with other characteristics has little practical value.
      </span>
    </p>
    <p>
      <span style={{ fontWeight: 400 }}>
        We classify countries into two main tiers based on the explicitness of the language used in the legal barriers
        to freedom of expression as they relate to our communities.
      </span>
    </p>
    <p>&nbsp;</p>

    <h5>a. Explicit legal barriers</h5>

    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: 400 }}>
        These entries include countries that have legislative or other governmental rules and regulations that
        explicitly outlaw forms of expression related to sexual and gender diversity issues.
      </span>
    </p>
    <p>
      <span style={{ fontWeight: 400 }}>
        We take a note of non-ambiguous targeting because they play an important role in both elucidating and
        crystallising an official position with regard to sexual and gender diversity issues. Moreover, such explicit
        language eliminates the interpretational gap that provides space for certain forms of legal advocacy.
      </span>
    </p>
    <p>
      <span style={{ fontWeight: 400 }}>
        It is enough for a country to have at least one legislative act explicitly limiting freedom of expression on
        SOGIE issues to be treated as a jurisdiction limiting the freedom of expression of LGBT+ people.
      </span>
    </p>
    <p>&nbsp;</p>

    <h5>b. Non-explicit legal barriers</h5>

    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: 400 }}>
        These entries include countries that have interpretations of legal provisions, religious norms, and
        law-enforcement practices which target but do not explicitly refer to sexual and gender diversity issues. It is
        noteworthy that the language of legislative provision does not correlate with the frequency or severity of its
        enforcement.
      </span>
    </p>
    <p>&nbsp;</p>
  </div>
];
