var map = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [8.7167, 46.1032],
            [8.7241, 46.0195],
            [8.5671, 45.9003],
            [8.5921, 45.8328],
            [8.5504, 45.7803],
            [8.6866, 45.635],
            [8.7173, 45.5088],
            [8.7858, 45.484],
            [8.8437, 45.3929],
            [8.7486, 45.373],
            [8.7138, 45.2996],
            [8.6322, 45.3478],
            [8.5257, 45.341],
            [8.555, 45.2387],
            [8.5413, 45.1694],
            [8.7045, 45.0139],
            [8.8968, 45.0504],
            [8.9024, 45.0061],
            [8.9766, 44.9664],
            [9.0021, 44.9072],
            [9.1078, 44.8046],
            [9.2144, 44.751],
            [9.2094, 44.6836],
            [9.2003, 44.6155],
            [9.1329, 44.5748],
            [9.0457, 44.62],
            [9.0145, 44.6678],
            [8.9245, 44.6752],
            [8.8816, 44.6345],
            [8.9155, 44.5609],
            [8.6205, 44.5843],
            [8.5585, 44.5024],
            [8.4032, 44.5085],
            [8.3667, 44.4679],
            [8.2514, 44.5282],
            [8.1951, 44.4781],
            [8.2219, 44.4323],
            [8.1451, 44.3807],
            [8.0657, 44.2145],
            [8.095, 44.1694],
            [8.0066, 44.1026],
            [7.9364, 44.1],
            [7.7609, 44.1404],
            [7.7114, 44.064],
            [7.665, 44.1312],
            [7.5603, 44.1562],
            [7.3551, 44.1154],
            [7.1859, 44.2012],
            [7.0054, 44.2356],
            [6.9933, 44.28],
            [6.8861, 44.3623],
            [6.8915, 44.4214],
            [6.8549, 44.5307],
            [6.9312, 44.5729],
            [6.9474, 44.6518],
            [7.0304, 44.7268],
            [7.0216, 44.822],
            [6.9349, 44.8607],
            [6.8628, 44.849],
            [6.7502, 44.9041],
            [6.7435, 45.013],
            [6.6727, 45.0186],
            [6.6254, 45.1043],
            [6.7671, 45.1587],
            [6.8466, 45.1351],
            [6.9452, 45.169],
            [7.0004, 45.2191],
            [7.0683, 45.2108],
            [7.1348, 45.2818],
            [7.11, 45.3269],
            [7.1871, 45.405],
            [7.1022, 45.4663],
            [7.2212, 45.4706],
            [7.2677, 45.5119],
            [7.3777, 45.5159],
            [7.465, 45.5763],
            [7.5656, 45.5907],
            [7.7318, 45.5498],
            [7.936, 45.636],
            [7.9329, 45.7257],
            [7.861, 45.7926],
            [7.861, 45.9168],
            [7.9056, 45.9969],
            [7.9865, 45.9953],
            [8.0306, 46.0989],
            [8.1032, 46.1106],
            [8.1644, 46.1793],
            [8.0757, 46.259],
            [8.209, 46.3077],
            [8.2888, 46.4066],
            [8.3622, 46.4502],
            [8.4686, 46.4432],
            [8.4639, 46.3322],
            [8.4258, 46.2977],
            [8.4699, 46.2309],
            [8.5294, 46.2173],
            [8.6101, 46.1216],
            [8.7167, 46.1032]
          ]
        ]
      },
      properties: {
        name: 'Piemonte',
        id: 'IT-21',
        NAME_ENG: 'Piedmont',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-21'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [7.861, 45.9168],
            [7.861, 45.7926],
            [7.9329, 45.7257],
            [7.936, 45.636],
            [7.7318, 45.5498],
            [7.5656, 45.5907],
            [7.465, 45.5763],
            [7.3777, 45.5159],
            [7.2677, 45.5119],
            [7.2212, 45.4706],
            [7.1022, 45.4663],
            [7, 45.5044],
            [6.9997, 45.6393],
            [6.9151, 45.6512],
            [6.8079, 45.7234],
            [6.8201, 45.8366],
            [6.9963, 45.8718],
            [7.0426, 45.9235],
            [7.1042, 45.8581],
            [7.2564, 45.8883],
            [7.2922, 45.9213],
            [7.3842, 45.8962],
            [7.5399, 45.9564],
            [7.6623, 45.9778],
            [7.7977, 45.9178],
            [7.861, 45.9168]
          ]
        ]
      },
      properties: {
        name: "Valle d'Aosta",
        id: 'IT-23',
        NAME_ENG: 'Aosta Valley',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-23'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.4555, 46.5314],
            [10.6229, 46.4489],
            [10.6348, 46.4006],
            [10.5684, 46.3265],
            [10.5887, 46.2468],
            [10.5443, 46.1905],
            [10.5468, 46.109],
            [10.4819, 46.0537],
            [10.4554, 45.9766],
            [10.5116, 45.9175],
            [10.504, 45.8301],
            [10.5682, 45.7845],
            [10.7361, 45.8439],
            [10.8458, 45.8318],
            [10.6295, 45.6064],
            [10.6516, 45.4453],
            [10.7217, 45.4112],
            [10.7025, 45.3054],
            [10.7885, 45.3141],
            [10.8486, 45.2552],
            [10.9379, 45.2311],
            [11.0748, 45.0989],
            [11.4223, 44.9629],
            [11.4267, 44.9486],
            [11.3052, 44.9613],
            [11.161, 44.9404],
            [11.0749, 44.9617],
            [10.869, 44.9182],
            [10.7496, 44.9472],
            [10.6868, 44.9841],
            [10.6311, 44.927],
            [10.5259, 44.9129],
            [10.3602, 44.9658],
            [10.2152, 45.0306],
            [10.0966, 45.0209],
            [9.993, 45.1283],
            [9.8774, 45.1175],
            [9.8139, 45.0763],
            [9.718, 45.0584],
            [9.6365, 45.0831],
            [9.443, 45.0929],
            [9.3749, 45.0495],
            [9.3186, 44.939],
            [9.2736, 44.8964],
            [9.3617, 44.8231],
            [9.2866, 44.7692],
            [9.3259, 44.6902],
            [9.2094, 44.6836],
            [9.2144, 44.751],
            [9.1078, 44.8046],
            [9.0021, 44.9072],
            [8.9766, 44.9664],
            [8.9024, 45.0061],
            [8.8968, 45.0504],
            [8.7045, 45.0139],
            [8.5413, 45.1694],
            [8.555, 45.2387],
            [8.5257, 45.341],
            [8.6322, 45.3478],
            [8.7138, 45.2996],
            [8.7486, 45.373],
            [8.8437, 45.3929],
            [8.7858, 45.484],
            [8.7173, 45.5088],
            [8.6866, 45.635],
            [8.5504, 45.7803],
            [8.5921, 45.8328],
            [8.5671, 45.9003],
            [8.7241, 46.0195],
            [8.7167, 46.1032],
            [8.8509, 46.0742],
            [8.8297, 45.9884],
            [8.8959, 45.9334],
            [8.9552, 45.843],
            [9.0349, 45.8176],
            [9.0748, 45.8985],
            [9.0186, 45.9276],
            [9.0045, 46.0357],
            [9.0742, 46.0647],
            [9.0704, 46.1196],
            [9.1798, 46.1699],
            [9.2968, 46.3269],
            [9.2735, 46.3709],
            [9.276, 46.4969],
            [9.3618, 46.5102],
            [9.4618, 46.3762],
            [9.5183, 46.327],
            [9.6323, 46.2865],
            [9.7092, 46.2926],
            [9.907, 46.3813],
            [9.9969, 46.3522],
            [9.9998, 46.2846],
            [10.1233, 46.2249],
            [10.1771, 46.2569],
            [10.1059, 46.3327],
            [10.1671, 46.41],
            [10.0406, 46.4454],
            [10.0451, 46.5404],
            [10.1896, 46.6272],
            [10.2597, 46.6113],
            [10.2968, 46.5509],
            [10.4555, 46.5314]
          ]
        ]
      },
      properties: {
        name: 'Lombardia',
        id: 'IT-25',
        NAME_ENG: 'Lombardy',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-25'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [12.4789, 46.6791],
            [12.3786, 46.6203],
            [12.1904, 46.6238],
            [12.0732, 46.643],
            [11.9989, 46.5342],
            [11.8164, 46.4991],
            [11.8856, 46.4525],
            [11.8197, 46.3765],
            [11.8441, 46.2701],
            [11.9309, 46.2482],
            [11.8961, 46.1222],
            [11.69, 46.0937],
            [11.708, 46.0454],
            [11.6724, 45.9641],
            [11.5903, 45.9682],
            [11.501, 46.0105],
            [11.377, 45.9828],
            [11.3459, 45.9209],
            [11.2562, 45.9202],
            [11.2393, 45.8534],
            [11.175, 45.7846],
            [11.1288, 45.6894],
            [11.06, 45.7174],
            [10.9447, 45.6763],
            [10.8456, 45.7185],
            [10.898, 45.8092],
            [10.8458, 45.8318],
            [10.7361, 45.8439],
            [10.5682, 45.7845],
            [10.504, 45.8301],
            [10.5116, 45.9175],
            [10.4554, 45.9766],
            [10.4819, 46.0537],
            [10.5468, 46.109],
            [10.5443, 46.1905],
            [10.5887, 46.2468],
            [10.5684, 46.3265],
            [10.6348, 46.4006],
            [10.6229, 46.4489],
            [10.4555, 46.5314],
            [10.4924, 46.6116],
            [10.4033, 46.6353],
            [10.4023, 46.7335],
            [10.445, 46.7568],
            [10.4779, 46.8574],
            [10.547, 46.8362],
            [10.6659, 46.8742],
            [10.7867, 46.7948],
            [10.8854, 46.7616],
            [11.0201, 46.7658],
            [11.1137, 46.9293],
            [11.3199, 46.9913],
            [11.4041, 46.9638],
            [11.4795, 47.0107],
            [11.5366, 46.9824],
            [11.6186, 47.0122],
            [11.7098, 46.9911],
            [11.836, 46.9921],
            [11.9146, 47.0334],
            [12.1816, 47.0925],
            [12.2034, 47.0286],
            [12.1198, 47.0076],
            [12.2145, 46.8741],
            [12.3065, 46.8398],
            [12.3806, 46.7182],
            [12.4789, 46.6791]
          ]
        ]
      },
      properties: {
        name: 'Trentino-Alto Adige',
        id: 'IT-32',
        NAME_ENG: 'Trentino-South Tyrol',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-32'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [12.7293, 46.6346],
            [12.7309, 46.5575],
            [12.5297, 46.4645],
            [12.4953, 46.4134],
            [12.3547, 46.3188],
            [12.3203, 46.2664],
            [12.443, 46.2079],
            [12.4961, 46.1527],
            [12.4873, 46.1042],
            [12.3962, 46.0394],
            [12.4285, 45.946],
            [12.5057, 45.9248],
            [12.5532, 45.8295],
            [12.6538, 45.8088],
            [12.782, 45.8551],
            [12.9595, 45.8223],
            [13.0623, 45.6564],
            [13.1, 45.6414],
            [12.9095, 45.6175],
            [12.7688, 45.5463],
            [12.3959, 45.4291],
            [12.3303, 45.3551],
            [12.2928, 45.24],
            [12.3345, 45.1646],
            [12.3289, 45.0914],
            [12.4976, 44.9609],
            [12.4847, 44.8989],
            [12.4022, 44.8939],
            [12.3514, 44.8217],
            [12.282, 44.9123],
            [12.1419, 44.93],
            [12.0972, 44.9727],
            [11.9665, 44.9875],
            [11.8012, 44.9778],
            [11.6284, 44.8895],
            [11.541, 44.9332],
            [11.4267, 44.9486],
            [11.4223, 44.9629],
            [11.0748, 45.0989],
            [10.9379, 45.2311],
            [10.8486, 45.2552],
            [10.7885, 45.3141],
            [10.7025, 45.3054],
            [10.7217, 45.4112],
            [10.6516, 45.4453],
            [10.6295, 45.6064],
            [10.8458, 45.8318],
            [10.898, 45.8092],
            [10.8456, 45.7185],
            [10.9447, 45.6763],
            [11.06, 45.7174],
            [11.1288, 45.6894],
            [11.175, 45.7846],
            [11.2393, 45.8534],
            [11.2562, 45.9202],
            [11.3459, 45.9209],
            [11.377, 45.9828],
            [11.501, 46.0105],
            [11.5903, 45.9682],
            [11.6724, 45.9641],
            [11.708, 46.0454],
            [11.69, 46.0937],
            [11.8961, 46.1222],
            [11.9309, 46.2482],
            [11.8441, 46.2701],
            [11.8197, 46.3765],
            [11.8856, 46.4525],
            [11.8164, 46.4991],
            [11.9989, 46.5342],
            [12.0732, 46.643],
            [12.1904, 46.6238],
            [12.3786, 46.6203],
            [12.4789, 46.6791],
            [12.5636, 46.6517],
            [12.6717, 46.6592],
            [12.7293, 46.6346]
          ]
        ]
      },
      properties: {
        name: 'Veneto',
        id: 'IT-34',
        NAME_ENG: 'Veneto',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-34'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [13.1, 45.6414],
            [13.0623, 45.6564],
            [12.9595, 45.8223],
            [12.782, 45.8551],
            [12.6538, 45.8088],
            [12.5532, 45.8295],
            [12.5057, 45.9248],
            [12.4285, 45.946],
            [12.3962, 46.0394],
            [12.4873, 46.1042],
            [12.4961, 46.1527],
            [12.443, 46.2079],
            [12.3203, 46.2664],
            [12.3547, 46.3188],
            [12.4953, 46.4134],
            [12.5297, 46.4645],
            [12.7309, 46.5575],
            [12.7293, 46.6346],
            [13.1666, 46.5863],
            [13.238, 46.5492],
            [13.4248, 46.5547],
            [13.7145, 46.5182],
            [13.6852, 46.436],
            [13.5912, 46.4348],
            [13.3735, 46.2941],
            [13.4217, 46.2323],
            [13.5273, 46.2168],
            [13.6407, 46.1347],
            [13.4975, 46.0554],
            [13.4727, 46.0026],
            [13.6349, 45.9353],
            [13.5737, 45.8527],
            [13.7933, 45.7462],
            [13.8635, 45.6603],
            [13.918, 45.6325],
            [13.7955, 45.5802],
            [13.7499, 45.6804],
            [13.63, 45.7705],
            [13.5522, 45.724],
            [13.3824, 45.6743],
            [13.2823, 45.712],
            [13.1562, 45.7018],
            [13.1, 45.6414]
          ]
        ]
      },
      properties: {
        name: 'Friuli-Venezia Giulia',
        id: 'IT-36',
        NAME_ENG: 'Friuli-Venezia Giulia',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-36'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [7.7114, 44.064],
            [7.7609, 44.1404],
            [7.9364, 44.1],
            [8.0066, 44.1026],
            [8.095, 44.1694],
            [8.0657, 44.2145],
            [8.1451, 44.3807],
            [8.2219, 44.4323],
            [8.1951, 44.4781],
            [8.2514, 44.5282],
            [8.3667, 44.4679],
            [8.4032, 44.5085],
            [8.5585, 44.5024],
            [8.6205, 44.5843],
            [8.9155, 44.5609],
            [8.8816, 44.6345],
            [8.9245, 44.6752],
            [9.0145, 44.6678],
            [9.0457, 44.62],
            [9.1329, 44.5748],
            [9.2003, 44.6155],
            [9.2988, 44.6108],
            [9.3602, 44.5752],
            [9.4862, 44.5605],
            [9.5029, 44.5313],
            [9.4378, 44.4177],
            [9.5821, 44.4275],
            [9.6829, 44.3623],
            [9.8593, 44.2343],
            [10.0672, 44.1002],
            [10.0185, 44.045],
            [9.9644, 44.0365],
            [9.8815, 44.0806],
            [9.8324, 44.0486],
            [9.7025, 44.1269],
            [9.5668, 44.1821],
            [9.4895, 44.237],
            [9.4031, 44.2519],
            [9.3714, 44.291],
            [9.2342, 44.3464],
            [8.7516, 44.4239],
            [8.5012, 44.3235],
            [8.4411, 44.2728],
            [8.4261, 44.1943],
            [8.2701, 44.1381],
            [8.1749, 43.9538],
            [7.9464, 43.8438],
            [7.8015, 43.8205],
            [7.6714, 43.7745],
            [7.5358, 43.7784],
            [7.4938, 43.8514],
            [7.5653, 43.9417],
            [7.6506, 43.974],
            [7.7114, 44.064]
          ]
        ]
      },
      properties: {
        name: 'Liguria',
        id: 'IT-42',
        NAME_ENG: 'Liguria',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-42'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [12.7506, 43.969],
            [12.7322, 43.8673],
            [12.6724, 43.8237],
            [12.59, 43.8834],
            [12.4926, 43.9163],
            [12.4363, 43.9562],
            [12.4102, 43.9034],
            [12.3561, 43.8741],
            [12.2818, 43.7649],
            [12.281, 43.7653],
            [12.0735, 43.741],
            [11.9846, 43.7621],
            [11.9122, 43.8114],
            [11.8212, 43.8143],
            [11.7123, 43.8765],
            [11.7176, 43.9197],
            [11.6461, 43.9892],
            [11.7408, 44.0873],
            [11.6408, 44.1096],
            [11.6135, 44.1588],
            [11.5192, 44.1558],
            [11.4257, 44.2358],
            [11.2807, 44.1546],
            [11.2133, 44.0981],
            [11.0879, 44.0902],
            [11.0039, 44.1109],
            [10.9133, 44.0615],
            [10.7522, 44.1555],
            [10.6436, 44.1573],
            [10.5929, 44.1152],
            [10.4648, 44.231],
            [10.3007, 44.2872],
            [10.2542, 44.2705],
            [10.1444, 44.3539],
            [10.0967, 44.3467],
            [9.9913, 44.4056],
            [9.924, 44.4747],
            [9.8197, 44.4665],
            [9.7531, 44.3915],
            [9.6829, 44.3623],
            [9.5821, 44.4275],
            [9.4378, 44.4177],
            [9.5029, 44.5313],
            [9.4862, 44.5605],
            [9.3602, 44.5752],
            [9.2988, 44.6108],
            [9.2003, 44.6155],
            [9.2094, 44.6836],
            [9.3259, 44.6902],
            [9.2866, 44.7692],
            [9.3617, 44.8231],
            [9.2736, 44.8964],
            [9.3186, 44.939],
            [9.3749, 45.0495],
            [9.443, 45.0929],
            [9.6365, 45.0831],
            [9.718, 45.0584],
            [9.8139, 45.0763],
            [9.8774, 45.1175],
            [9.993, 45.1283],
            [10.0966, 45.0209],
            [10.2152, 45.0306],
            [10.3602, 44.9658],
            [10.5259, 44.9129],
            [10.6311, 44.927],
            [10.6868, 44.9841],
            [10.7496, 44.9472],
            [10.869, 44.9182],
            [11.0749, 44.9617],
            [11.161, 44.9404],
            [11.3052, 44.9613],
            [11.4267, 44.9486],
            [11.541, 44.9332],
            [11.6284, 44.8895],
            [11.8012, 44.9778],
            [11.9665, 44.9875],
            [12.0972, 44.9727],
            [12.1419, 44.93],
            [12.282, 44.9123],
            [12.3514, 44.8217],
            [12.2922, 44.8499],
            [12.2508, 44.7645],
            [12.2486, 44.6493],
            [12.2868, 44.5959],
            [12.2847, 44.4726],
            [12.3633, 44.2586],
            [12.3976, 44.2091],
            [12.5284, 44.0993],
            [12.694, 43.985],
            [12.7506, 43.969]
          ]
        ]
      },
      properties: {
        name: 'Emilia-Romagna',
        id: 'IT-45',
        NAME_ENG: 'Emilia-Romagna',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-45'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [9.6829, 44.3623],
              [9.7531, 44.3915],
              [9.8197, 44.4665],
              [9.924, 44.4747],
              [9.9913, 44.4056],
              [10.0967, 44.3467],
              [10.1444, 44.3539],
              [10.2542, 44.2705],
              [10.3007, 44.2872],
              [10.4648, 44.231],
              [10.5929, 44.1152],
              [10.6436, 44.1573],
              [10.7522, 44.1555],
              [10.9133, 44.0615],
              [11.0039, 44.1109],
              [11.0879, 44.0902],
              [11.2133, 44.0981],
              [11.2807, 44.1546],
              [11.4257, 44.2358],
              [11.5192, 44.1558],
              [11.6135, 44.1588],
              [11.6408, 44.1096],
              [11.7408, 44.0873],
              [11.6461, 43.9892],
              [11.7176, 43.9197],
              [11.7123, 43.8765],
              [11.8212, 43.8143],
              [11.9122, 43.8114],
              [11.9846, 43.7621],
              [12.0735, 43.741],
              [12.281, 43.7653],
              [12.2843, 43.7635],
              [12.33, 43.7076],
              [12.2307, 43.6545],
              [12.2138, 43.6112],
              [12.1504, 43.5301],
              [12.1443, 43.4787],
              [12.0599, 43.4387],
              [12.1372, 43.3315],
              [12.0942, 43.2365],
              [11.9246, 43.1474],
              [11.9821, 43.0493],
              [11.8962, 42.8368],
              [11.8479, 42.8388],
              [11.7466, 42.7852],
              [11.8203, 42.7427],
              [11.7841, 42.6712],
              [11.6759, 42.5795],
              [11.5608, 42.5144],
              [11.6169, 42.4373],
              [11.4842, 42.4362],
              [11.448, 42.3762],
              [11.2086, 42.4077],
              [11.1872, 42.3617],
              [11.0935, 42.3916],
              [11.1643, 42.437],
              [11.1901, 42.5118],
              [11.1219, 42.5581],
              [11.0853, 42.6291],
              [11.0045, 42.6546],
              [10.9433, 42.7411],
              [10.7908, 42.7724],
              [10.7666, 42.8177],
              [10.7738, 42.9097],
              [10.6206, 42.9547],
              [10.4952, 42.9323],
              [10.5385, 43.0891],
              [10.5256, 43.2479],
              [10.4249, 43.3991],
              [10.3302, 43.4736],
              [10.2685, 43.678],
              [10.2753, 43.7472],
              [10.2394, 43.8705],
              [10.1411, 43.979],
              [10.0185, 44.045],
              [10.0672, 44.1002],
              [9.8593, 44.2343],
              [9.6829, 44.3623]
            ]
          ],
          [
            [
              [10.4192, 42.8681],
              [10.4363, 42.7791],
              [10.3033, 42.7597],
              [10.2393, 42.7264],
              [10.1225, 42.7417],
              [10.1043, 42.7895],
              [10.2751, 42.8187],
              [10.3561, 42.7995],
              [10.4192, 42.8681]
            ]
          ]
        ]
      },
      properties: {
        name: 'Toscana',
        id: 'IT-52',
        NAME_ENG: 'Tuscany',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-52'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [12.2138, 43.6112],
            [12.3354, 43.611],
            [12.4211, 43.5382],
            [12.4982, 43.5217],
            [12.6222, 43.4182],
            [12.7073, 43.425],
            [12.7939, 43.3368],
            [12.8848, 43.116],
            [12.8963, 42.963],
            [13.053, 42.9207],
            [13.1605, 42.8312],
            [13.2597, 42.7881],
            [13.192, 42.7319],
            [13.1774, 42.6678],
            [13.0642, 42.6226],
            [12.8807, 42.6001],
            [12.8928, 42.5634],
            [12.7727, 42.5126],
            [12.6813, 42.442],
            [12.5284, 42.3623],
            [12.5124, 42.4011],
            [12.4157, 42.4255],
            [12.2753, 42.5076],
            [12.2305, 42.6333],
            [12.1662, 42.6728],
            [12.0322, 42.6445],
            [11.9263, 42.7117],
            [11.8962, 42.8368],
            [11.9821, 43.0493],
            [11.9246, 43.1474],
            [12.0942, 43.2365],
            [12.1372, 43.3315],
            [12.0599, 43.4387],
            [12.1443, 43.4787],
            [12.1504, 43.5301],
            [12.2138, 43.6112]
          ]
        ]
      },
      properties: {
        name: 'Umbria',
        id: 'IT-55',
        NAME_ENG: 'Umbria',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-55'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [13.915, 42.8942],
            [13.7089, 42.8505],
            [13.6999, 42.8188],
            [13.5752, 42.8004],
            [13.4979, 42.7388],
            [13.3597, 42.6935],
            [13.2879, 42.7389],
            [13.192, 42.7319],
            [13.2597, 42.7881],
            [13.1605, 42.8312],
            [13.053, 42.9207],
            [12.8963, 42.963],
            [12.8848, 43.116],
            [12.7939, 43.3368],
            [12.7073, 43.425],
            [12.6222, 43.4182],
            [12.4982, 43.5217],
            [12.4211, 43.5382],
            [12.3354, 43.611],
            [12.2138, 43.6112],
            [12.2307, 43.6545],
            [12.33, 43.7076],
            [12.2843, 43.7635],
            [12.2818, 43.7649],
            [12.3561, 43.8741],
            [12.4102, 43.9034],
            [12.4926, 43.9163],
            [12.59, 43.8834],
            [12.6724, 43.8237],
            [12.7322, 43.8673],
            [12.7506, 43.969],
            [12.9052, 43.9247],
            [13.3049, 43.6708],
            [13.4523, 43.6112],
            [13.5494, 43.6046],
            [13.6266, 43.5488],
            [13.6224, 43.5041],
            [13.842, 43.1065],
            [13.915, 42.8942]
          ]
        ]
      },
      properties: {
        name: 'Marche',
        id: 'IT-57',
        NAME_ENG: 'Marche',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-57'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [13.9779, 41.463],
            [13.9038, 41.4229],
            [13.8718, 41.3628],
            [13.892, 41.2971],
            [13.7629, 41.2227],
            [13.6294, 41.2605],
            [13.5518, 41.2048],
            [13.3773, 41.2815],
            [13.2927, 41.2968],
            [13.1585, 41.2702],
            [13.0468, 41.2267],
            [12.9897, 41.3258],
            [12.848, 41.4122],
            [12.7663, 41.4079],
            [12.6722, 41.4553],
            [12.6193, 41.4463],
            [12.5546, 41.5322],
            [12.4164, 41.6559],
            [12.3447, 41.7015],
            [12.2235, 41.7429],
            [12.2053, 41.8291],
            [12.1443, 41.913],
            [11.9179, 42.0359],
            [11.8346, 42.0268],
            [11.6542, 42.2767],
            [11.548, 42.3408],
            [11.448, 42.3762],
            [11.4842, 42.4362],
            [11.6169, 42.4373],
            [11.5608, 42.5144],
            [11.6759, 42.5795],
            [11.7841, 42.6712],
            [11.8203, 42.7427],
            [11.7466, 42.7852],
            [11.8479, 42.8388],
            [11.8962, 42.8368],
            [11.9263, 42.7117],
            [12.0322, 42.6445],
            [12.1662, 42.6728],
            [12.2305, 42.6333],
            [12.2753, 42.5076],
            [12.4157, 42.4255],
            [12.5124, 42.4011],
            [12.5284, 42.3623],
            [12.6813, 42.442],
            [12.7727, 42.5126],
            [12.8928, 42.5634],
            [12.8807, 42.6001],
            [13.0642, 42.6226],
            [13.1774, 42.6678],
            [13.192, 42.7319],
            [13.2879, 42.7389],
            [13.3597, 42.6935],
            [13.4105, 42.6405],
            [13.3693, 42.5741],
            [13.1928, 42.5861],
            [13.1793, 42.4768],
            [13.1173, 42.4418],
            [13.1952, 42.3878],
            [13.2402, 42.2706],
            [13.3379, 42.2217],
            [13.306, 42.1287],
            [13.2488, 42.1265],
            [13.1208, 42.1778],
            [13.0304, 42.1205],
            [13.0548, 42.0163],
            [13.1413, 42.0146],
            [13.386, 41.9035],
            [13.361, 41.8701],
            [13.5062, 41.7996],
            [13.5494, 41.7596],
            [13.6491, 41.8099],
            [13.7646, 41.7478],
            [13.8956, 41.7336],
            [13.9443, 41.6858],
            [13.9831, 41.6607],
            [14.0288, 41.5247],
            [13.9779, 41.463]
          ],
          [
            [12.4577, 41.9059],
            [12.4504, 41.9066],
            [12.4466, 41.9018],
            [12.4541, 41.9001],
            [12.4577, 41.9059]
          ]
        ]
      },
      properties: {
        name: 'Lazio',
        id: 'IT-62',
        NAME_ENG: 'Lazio',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-62'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.7853, 42.0689],
            [14.7803, 42.0298],
            [14.68, 41.96],
            [14.5845, 41.8363],
            [14.4916, 41.7614],
            [14.4463, 41.8388],
            [14.2841, 41.9103],
            [14.1479, 41.8279],
            [14.1938, 41.7481],
            [14.0701, 41.7362],
            [14.0216, 41.6791],
            [13.9443, 41.6858],
            [13.8956, 41.7336],
            [13.7646, 41.7478],
            [13.6491, 41.8099],
            [13.5494, 41.7596],
            [13.5062, 41.7996],
            [13.361, 41.8701],
            [13.386, 41.9035],
            [13.1413, 42.0146],
            [13.0548, 42.0163],
            [13.0304, 42.1205],
            [13.1208, 42.1778],
            [13.2488, 42.1265],
            [13.306, 42.1287],
            [13.3379, 42.2217],
            [13.2402, 42.2706],
            [13.1952, 42.3878],
            [13.1173, 42.4418],
            [13.1793, 42.4768],
            [13.1928, 42.5861],
            [13.3693, 42.5741],
            [13.4105, 42.6405],
            [13.3597, 42.6935],
            [13.4979, 42.7388],
            [13.5752, 42.8004],
            [13.6999, 42.8188],
            [13.7089, 42.8505],
            [13.915, 42.8942],
            [13.9905, 42.7205],
            [14.1128, 42.5595],
            [14.2494, 42.4482],
            [14.4102, 42.3593],
            [14.5052, 42.2549],
            [14.6137, 42.2013],
            [14.7143, 42.1747],
            [14.7185, 42.1043],
            [14.7853, 42.0689]
          ]
        ]
      },
      properties: {
        name: 'Abruzzo',
        id: 'IT-65',
        NAME_ENG: 'Abruzzo',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-65'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [15.1367, 41.9267],
            [15.0993, 41.8343],
            [15.1209, 41.721],
            [15.1039, 41.6718],
            [15.0217, 41.6224],
            [14.9585, 41.6443],
            [14.938, 41.5286],
            [15.0073, 41.4851],
            [14.8668, 41.4277],
            [14.7885, 41.4527],
            [14.7549, 41.4102],
            [14.5101, 41.3839],
            [14.4794, 41.4206],
            [14.3232, 41.4549],
            [14.2399, 41.4983],
            [14.1204, 41.507],
            [14.0423, 41.3934],
            [13.9779, 41.463],
            [14.0288, 41.5247],
            [13.9831, 41.6607],
            [13.9443, 41.6858],
            [14.0216, 41.6791],
            [14.0701, 41.7362],
            [14.1938, 41.7481],
            [14.1479, 41.8279],
            [14.2841, 41.9103],
            [14.4463, 41.8388],
            [14.4916, 41.7614],
            [14.5845, 41.8363],
            [14.68, 41.96],
            [14.7803, 42.0298],
            [14.7853, 42.0689],
            [14.9002, 42.0212],
            [14.9976, 42.0077],
            [15.0751, 41.9445],
            [15.1367, 41.9267]
          ]
        ]
      },
      properties: {
        name: 'Molise',
        id: 'IT-67',
        NAME_ENG: 'Molise',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-67'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [13.9779, 41.463],
              [14.0423, 41.3934],
              [14.1204, 41.507],
              [14.2399, 41.4983],
              [14.3232, 41.4549],
              [14.4794, 41.4206],
              [14.5101, 41.3839],
              [14.7549, 41.4102],
              [14.7885, 41.4527],
              [14.8668, 41.4277],
              [15.0073, 41.4851],
              [15.0984, 41.4318],
              [15.0715, 41.3326],
              [15.2004, 41.2871],
              [15.2621, 41.1989],
              [15.2067, 41.1519],
              [15.2907, 41.0914],
              [15.4005, 41.1062],
              [15.5424, 41.0551],
              [15.5692, 40.9965],
              [15.5251, 40.9097],
              [15.4594, 40.8682],
              [15.3773, 40.8668],
              [15.3827, 40.7235],
              [15.4984, 40.6664],
              [15.4507, 40.6093],
              [15.522, 40.558],
              [15.586, 40.4429],
              [15.6983, 40.3843],
              [15.734, 40.3039],
              [15.7911, 40.2912],
              [15.7958, 40.2219],
              [15.7281, 40.1999],
              [15.7055, 40.1166],
              [15.6432, 40.044],
              [15.6265, 40.0734],
              [15.5023, 40.065],
              [15.4196, 39.992],
              [15.3535, 40.0006],
              [15.2703, 40.0747],
              [15.1279, 40.171],
              [15.0367, 40.1701],
              [14.9851, 40.2195],
              [14.9133, 40.2365],
              [14.9417, 40.3346],
              [14.9929, 40.4008],
              [14.8749, 40.5878],
              [14.771, 40.671],
              [14.6912, 40.6331],
              [14.626, 40.6475],
              [14.5732, 40.6119],
              [14.4799, 40.6256],
              [14.3226, 40.568],
              [14.3379, 40.629],
              [14.4778, 40.6967],
              [14.2734, 40.8433],
              [14.1583, 40.8156],
              [14.044, 40.8566],
              [14.0222, 40.9148],
              [13.9221, 41.0181],
              [13.8947, 41.0895],
              [13.7629, 41.2227],
              [13.892, 41.2971],
              [13.8718, 41.3628],
              [13.9038, 41.4229],
              [13.9779, 41.463]
            ]
          ],
          [
            [
              [13.8687, 40.7597],
              [13.9547, 40.7419],
              [13.9515, 40.6997],
              [13.8519, 40.7059],
              [13.8687, 40.7597]
            ]
          ]
        ]
      },
      properties: {
        name: 'Campania',
        id: 'IT-72',
        NAME_ENG: 'Campania',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-72'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.8596, 40.3826],
            [16.8032, 40.4563],
            [16.7187, 40.4799],
            [16.7046, 40.551],
            [16.7212, 40.7136],
            [16.5749, 40.762],
            [16.4987, 40.7606],
            [16.4307, 40.7007],
            [16.2577, 40.8224],
            [16.2003, 40.9181],
            [16.0886, 40.9211],
            [15.9795, 40.9563],
            [16.0386, 41.0376],
            [15.9496, 41.1078],
            [15.8707, 41.1366],
            [15.7682, 41.0833],
            [15.5622, 41.0915],
            [15.5424, 41.0551],
            [15.4005, 41.1062],
            [15.2907, 41.0914],
            [15.2067, 41.1519],
            [15.2621, 41.1989],
            [15.2004, 41.2871],
            [15.0715, 41.3326],
            [15.0984, 41.4318],
            [15.0073, 41.4851],
            [14.938, 41.5286],
            [14.9585, 41.6443],
            [15.0217, 41.6224],
            [15.1039, 41.6718],
            [15.1209, 41.721],
            [15.0993, 41.8343],
            [15.1367, 41.9267],
            [15.3401, 41.9174],
            [15.4268, 41.9008],
            [15.6204, 41.9277],
            [15.7667, 41.917],
            [16.0166, 41.9491],
            [16.0916, 41.9382],
            [16.1874, 41.8837],
            [16.1896, 41.7743],
            [16.0652, 41.6924],
            [15.9015, 41.6156],
            [15.9044, 41.5323],
            [15.9532, 41.4629],
            [16.0206, 41.4261],
            [16.2034, 41.3623],
            [16.2615, 41.3279],
            [16.4196, 41.283],
            [16.5841, 41.2081],
            [16.7778, 41.1616],
            [16.9587, 41.0973],
            [17.0924, 41.0643],
            [17.2805, 40.971],
            [17.3513, 40.9054],
            [17.4793, 40.8284],
            [17.7097, 40.7582],
            [17.8306, 40.6944],
            [17.9401, 40.6845],
            [18.0224, 40.6425],
            [18.0359, 40.5673],
            [18.1261, 40.4937],
            [18.2407, 40.4448],
            [18.4237, 40.2947],
            [18.5176, 40.1027],
            [18.4356, 40.0228],
            [18.3904, 39.9069],
            [18.3909, 39.8167],
            [18.3456, 39.7884],
            [18.2708, 39.8329],
            [18.1714, 39.849],
            [18.0459, 39.9276],
            [18.0164, 40.0063],
            [18.0098, 40.1066],
            [17.9199, 40.1924],
            [17.9065, 40.2538],
            [17.8573, 40.285],
            [17.6804, 40.3048],
            [17.5092, 40.2946],
            [17.2258, 40.408],
            [17.2252, 40.4802],
            [17.1135, 40.5188],
            [17.039, 40.5103],
            [16.9011, 40.4349],
            [16.8596, 40.3826]
          ]
        ]
      },
      properties: {
        name: 'Puglia',
        id: 'IT-75',
        NAME_ENG: 'Apulia',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-75'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [15.5424, 41.0551],
            [15.5622, 41.0915],
            [15.7682, 41.0833],
            [15.8707, 41.1366],
            [15.9496, 41.1078],
            [16.0386, 41.0376],
            [15.9795, 40.9563],
            [16.0886, 40.9211],
            [16.2003, 40.9181],
            [16.2577, 40.8224],
            [16.4307, 40.7007],
            [16.4987, 40.7606],
            [16.5749, 40.762],
            [16.7212, 40.7136],
            [16.7046, 40.551],
            [16.7187, 40.4799],
            [16.8032, 40.4563],
            [16.8596, 40.3826],
            [16.7826, 40.2983],
            [16.6889, 40.1434],
            [16.6442, 40.1184],
            [16.4078, 40.1225],
            [16.3971, 40.0166],
            [16.3376, 39.935],
            [16.2698, 39.9366],
            [16.0442, 39.8994],
            [16.0287, 39.9924],
            [15.8191, 40.0019],
            [15.7546, 39.9249],
            [15.6432, 40.044],
            [15.7055, 40.1166],
            [15.7281, 40.1999],
            [15.7958, 40.2219],
            [15.7911, 40.2912],
            [15.734, 40.3039],
            [15.6983, 40.3843],
            [15.586, 40.4429],
            [15.522, 40.558],
            [15.4507, 40.6093],
            [15.4984, 40.6664],
            [15.3827, 40.7235],
            [15.3773, 40.8668],
            [15.4594, 40.8682],
            [15.5251, 40.9097],
            [15.5692, 40.9965],
            [15.5424, 41.0551]
          ]
        ]
      },
      properties: {
        name: 'Basilicata',
        id: 'IT-77',
        NAME_ENG: 'Basilicata',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-77'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [15.7546, 39.9249],
            [15.8191, 40.0019],
            [16.0287, 39.9924],
            [16.0442, 39.8994],
            [16.2698, 39.9366],
            [16.3376, 39.935],
            [16.3971, 40.0166],
            [16.4078, 40.1225],
            [16.6442, 40.1184],
            [16.6003, 40.0424],
            [16.6268, 39.9556],
            [16.4882, 39.7984],
            [16.5281, 39.7194],
            [16.5173, 39.6672],
            [16.6164, 39.6218],
            [16.7733, 39.62],
            [16.8654, 39.5395],
            [17.0244, 39.4827],
            [17.0815, 39.4205],
            [17.158, 39.4001],
            [17.1072, 39.2551],
            [17.1496, 39.2069],
            [17.1114, 39.1075],
            [17.1697, 38.9526],
            [17.0952, 38.8916],
            [16.9796, 38.9376],
            [16.7767, 38.8939],
            [16.606, 38.8112],
            [16.5347, 38.7112],
            [16.5571, 38.68],
            [16.5826, 38.4708],
            [16.5737, 38.4275],
            [16.4635, 38.3372],
            [16.3222, 38.2902],
            [16.1661, 38.1345],
            [16.1464, 38.0299],
            [16.064, 37.9229],
            [15.9202, 37.9291],
            [15.7604, 37.9172],
            [15.6776, 37.9545],
            [15.6352, 38.0173],
            [15.6558, 38.1378],
            [15.6348, 38.2315],
            [15.8018, 38.2807],
            [15.8352, 38.3629],
            [15.9161, 38.4713],
            [15.9307, 38.5459],
            [15.8269, 38.6188],
            [15.8715, 38.673],
            [15.9877, 38.7223],
            [16.1025, 38.7113],
            [16.1833, 38.748],
            [16.2219, 38.8469],
            [16.2163, 38.9249],
            [16.156, 38.9477],
            [16.097, 39.0326],
            [16.0654, 39.1432],
            [16.0501, 39.3019],
            [15.9964, 39.4392],
            [15.9251, 39.5254],
            [15.8736, 39.554],
            [15.8145, 39.6762],
            [15.7908, 39.863],
            [15.7546, 39.9249]
          ]
        ]
      },
      properties: {
        name: 'Calabria',
        id: 'IT-78',
        NAME_ENG: 'Calabria',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-78'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [11.9559, 36.8387],
              [12.0524, 36.799],
              [11.9905, 36.738],
              [11.9258, 36.8019],
              [11.9559, 36.8387]
            ]
          ],
          [
            [
              [15.5587, 38.2994],
              [15.6059, 38.2745],
              [15.5774, 38.1916],
              [15.497, 38.0746],
              [15.3067, 37.8781],
              [15.2133, 37.755],
              [15.2201, 37.711],
              [15.1762, 37.6312],
              [15.1775, 37.5765],
              [15.0865, 37.4861],
              [15.0979, 37.3211],
              [15.2057, 37.2934],
              [15.1861, 37.1886],
              [15.2338, 37.1174],
              [15.2956, 37.106],
              [15.3005, 37.0153],
              [15.1524, 36.9134],
              [15.0947, 36.799],
              [15.1397, 36.6846],
              [15.0803, 36.6479],
              [15.0178, 36.7012],
              [14.9576, 36.6925],
              [14.8774, 36.7302],
              [14.7808, 36.7034],
              [14.6899, 36.7201],
              [14.6375, 36.7601],
              [14.4932, 36.7857],
              [14.3799, 36.9605],
              [14.2625, 37.0559],
              [14.0695, 37.1096],
              [13.8749, 37.1013],
              [13.8223, 37.1443],
              [13.7502, 37.1494],
              [13.66, 37.194],
              [13.5542, 37.2843],
              [13.4492, 37.2928],
              [13.4022, 37.332],
              [13.272, 37.3894],
              [13.1649, 37.4935],
              [13.0192, 37.4946],
              [12.9615, 37.5638],
              [12.7788, 37.5799],
              [12.674, 37.5593],
              [12.6072, 37.64],
              [12.5197, 37.6591],
              [12.4249, 37.8005],
              [12.4852, 37.8738],
              [12.4574, 37.9071],
              [12.4937, 37.983],
              [12.5704, 38.0686],
              [12.6033, 38.0639],
              [12.7687, 38.151],
              [12.811, 38.079],
              [12.9, 38.0245],
              [13.077, 38.0875],
              [13.052, 38.1383],
              [13.1077, 38.1927],
              [13.1908, 38.1695],
              [13.3172, 38.2243],
              [13.3665, 38.1812],
              [13.3825, 38.1078],
              [13.4467, 38.0937],
              [13.5369, 38.1128],
              [13.5677, 38.0391],
              [13.7128, 37.977],
              [13.8632, 37.991],
              [14.0228, 38.0421],
              [14.2864, 38.0082],
              [14.4786, 38.0346],
              [14.639, 38.0752],
              [14.7482, 38.1673],
              [14.8901, 38.1742],
              [15.0443, 38.1541],
              [15.099, 38.1236],
              [15.1748, 38.1566],
              [15.2434, 38.2181],
              [15.3992, 38.2325],
              [15.5587, 38.2994]
            ]
          ]
        ]
      },
      properties: {
        name: 'Sicilia',
        id: 'IT-82',
        NAME_ENG: 'Sicily',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-82'
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [8.3704, 39.1135],
              [8.4252, 39.1088],
              [8.4666, 39.0546],
              [8.4444, 38.969],
              [8.3594, 39.0369],
              [8.3704, 39.1135]
            ]
          ],
          [
            [
              [8.3083, 39.1823],
              [8.3057, 39.0993],
              [8.2187, 39.1476],
              [8.3083, 39.1823]
            ]
          ],
          [
            [
              [9.2315, 41.2577],
              [9.2866, 41.1831],
              [9.4244, 41.1772],
              [9.548, 41.1329],
              [9.5415, 41.0599],
              [9.61, 41.0002],
              [9.5636, 40.9203],
              [9.6602, 40.8748],
              [9.6726, 40.7846],
              [9.7546, 40.6724],
              [9.7551, 40.5964],
              [9.8288, 40.5284],
              [9.7675, 40.3918],
              [9.6906, 40.3417],
              [9.6274, 40.2589],
              [9.6542, 40.1471],
              [9.7332, 40.0637],
              [9.6869, 39.9764],
              [9.6969, 39.8533],
              [9.6701, 39.783],
              [9.6789, 39.7129],
              [9.6469, 39.6521],
              [9.647, 39.4615],
              [9.5989, 39.348],
              [9.6033, 39.2899],
              [9.5582, 39.1303],
              [9.4375, 39.1212],
              [9.2962, 39.2118],
              [9.2095, 39.2259],
              [9.0672, 39.1975],
              [9.0176, 39.1496],
              [9.0453, 39.0554],
              [8.9961, 38.9862],
              [8.8578, 38.8774],
              [8.6217, 38.9445],
              [8.5722, 38.9797],
              [8.5639, 39.0445],
              [8.4837, 39.0883],
              [8.3673, 39.2129],
              [8.4238, 39.2703],
              [8.419, 39.3322],
              [8.372, 39.3759],
              [8.4106, 39.4233],
              [8.3846, 39.471],
              [8.4512, 39.5471],
              [8.4922, 39.7235],
              [8.5457, 39.7971],
              [8.5238, 39.9031],
              [8.4395, 39.8758],
              [8.3968, 39.9069],
              [8.412, 40.0187],
              [8.489, 40.0796],
              [8.456, 40.1759],
              [8.482, 40.2856],
              [8.3786, 40.3595],
              [8.4009, 40.4096],
              [8.2997, 40.5893],
              [8.2042, 40.5669],
              [8.1451, 40.6226],
              [8.2061, 40.6838],
              [8.1336, 40.7303],
              [8.1678, 40.7651],
              [8.2338, 40.9107],
              [8.3143, 40.8451],
              [8.4561, 40.8205],
              [8.5622, 40.8323],
              [8.6954, 40.914],
              [8.7932, 40.925],
              [9.012, 41.1238],
              [9.1072, 41.1336],
              [9.1679, 41.1688],
              [9.1644, 41.2358],
              [9.2315, 41.2577]
            ]
          ]
        ]
      },
      properties: {
        name: 'Sardegna',
        id: 'IT-88',
        NAME_ENG: 'Sardinia',
        CNTRY: 'Italy',
        TYPE: 'Region'
      },
      id: 'IT-88'
    }
  ]
}
export default map
