export const NoData = options => {
  return {
    'A1-1': {
      en: '',
      es: ''
    },
    'A1-3': {
      en:
        'To the best of ILGA World’s knowledge, there are no laws or regulations that specifically target freedom of expression with regard to SOGIESC issues in ' +
        options.jur.name +
        '. This does not necessarily mean that local communities can freely and effectively share and access information on sexual, gender, or bodily diversity, as various other factors may restrict this right in practice. For more information please read the methodology section for this legal category.',
      es:
        'En lo que ILGA Mundo ha podido recabar, no existirían leyes ni reglamentos que restrinjan de manera específica la libertad de expresión en materia de diversidad sexual, de género o corporal en ' +
        options.jur.name +
        '. Esto no significa necesariamente que las comunidades locales puedan compartir y acceder libre y efectivamente a información sobre diversidad sexual, de género o corporal, ya que otros factores pueden restringir este derecho en la práctica. Para obtener más información, lea la sección de metodología para esta categoría legal.'
    },
    'A1-4': {
      en:
        'To the best of ILGA World’s knowledge, there are no laws or regulations that specifically restrict the registration or operation of civil society organisations working on issues of sexual, gender or bodily diversity in ' +
        options.jur.name +
        '. This does not necessarily mean that local communities can freely and effectively register and operate organisations, as various other factors may restrict this right in practice. For more information please read the methodology section for this legal category.',
      es:
        'En lo que ILGA Mundo ha podido recabar, no existen leyes o reglamentos que restrinjan específicamente el registro o el funcionamiento de las organizaciones de la sociedad civil que trabajan en temas de diversidad sexual, de género o corporal en ' +
        options.jur.name +
        '. Esto no significa necesariamente que las comunidades locales puedan registrar y operar organizaciones libre y efectivamente, ya que otros factores pueden restringir este derecho en la práctica. Para obtener más información, lea la sección de metodología para esta categoría legal.'
    },
    'A1-5': {
      no: {
        en:
          'The Constitution of ' +
          options.jur.name +
          ' does not explicitly include “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics” as protected grounds of discrimination.',
        es:
          'La Constitución de ' +
          options.jur.name +
          ' no incluye explícitamente a la “orientación sexual”, la “identidad de género”, la “expresión de género” o las “características sexuales” como motivos protegidos de discriminación.'
      },
      varies: {
        en:
          'The Constitution of ' +
          options.jur.name +
          ' does not explicitly include “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics” as protected grounds of discrimination. However, such protection is found in constitutions at the sub-national level.',
        es:
          'La Constitución de ' +
          options.jur.name +
          ' no incluye explícitamente a la “orientación sexual”, la “identidad de género”, la “expresión de género” o las “características sexuales” como motivos protegidos de discriminación. Sin embargo, dicha protección existe en constituciones a nivel subnacional.'
      }
    },
    'A1-6': {
      no: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' do not offer protection against discrimination based on “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics” in the provision of goods and services.',
        es:
          'En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente en ' +
          options.jur.name +
          ' no ofrece protección contra la discriminación basada en la “orientación sexual”, la “identidad de género”, la “expresión de género” o las “características sexuales” en la provisión de bienes y servicios.'
      },
      varies: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' do not offer nationwide protection against discrimination based on “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics” in the provision of goods and services. However, some level of protection can be found at the sub-national level.',
        es:
          'En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente en ' +
          options.jur.name +
          ' no ofrece, a nivel nacional, protección contra la discriminación basada en la “orientación sexual”, la “identidad de género”, la “expresión de género” o las “características sexuales” en la provisión de bienes y servicios. Sin embargo, cierto nivel de protección existe a nivel subnacional.'
      }
    },
    'A1-7': {
      no: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' do not offer protection against discrimination based on “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics” in healthcare.',
        es:
          'En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente en ' +
          options.jur.name +
          ' no ofrece protección contra la discriminación basada en la “orientación sexual”, la “identidad de género”, la “expresión de género” o las “características sexuales” en materia de salud.'
      },
      varies: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' do not offer nationwide protection against discrimination based on “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics” in healthcare. However, some level of protection can be found at the sub-national level.',
        es:
          'En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente en ' +
          options.jur.name +
          ' no ofrece, a nivel nacional, protección contra la discriminación basada en la “orientación sexual”, la “identidad de género”, la “expresión de género” o las “características sexuales” en materia de salud. Sin embargo, cierto nivel de protección existe a nivel subnacional.'
      }
    },
    'A1-8': {
      no: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' do not offer protection against discrimination based on “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics” in education.',
        es:
          'En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente en ' +
          options.jur.name +
          ' no ofrece protección contra la discriminación basada en la “orientación sexual”, la “identidad de género”, la “expresión de género” o las “características sexuales” en materia de educación.'
      },
      varies: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' do not offer nationwide protection against discrimination based on “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics” in education. However, some level of protection can be found at the sub-national level.',
        es:
          'En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente en ' +
          options.jur.name +
          ' no ofrece, a nivel nacional, protección contra la discriminación basada en la “orientación sexual”, la “identidad de género”, la “expresión de género” o las “características sexuales” en materia de educación. Sin embargo, cierto nivel de protección existe a nivel subnacional.'
      }
    },
    'A1-10': {
      no: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' do not offer protection against discrimination based on “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics” in employment.',
        es:
          'En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente en ' +
          options.jur.name +
          ' no ofrece protección contra la discriminación basada en la “orientación sexual”, la “identidad de género”, la “expresión de género” o las “características sexuales” en materia de empleo.'
      },
      varies: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' do not offer nationwide protection against discrimination based on “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics” in employment. However, some level of protection can be found at the sub-national level.',
        es:
          'En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente en ' +
          options.jur.name +
          ' no ofrece, a nivel nacional, protección contra la discriminación basada en la “orientación sexual”, la “identidad de género”, la “expresión de género” o las “características sexuales” en materia de empleo. Sin embargo, cierto nivel de protección existe a nivel subnacional.'
      }
    },
    'A1-11': {
      no: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' do not offer protection against discrimination based on “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics” in housing.',
        es:
          'En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente en ' +
          options.jur.name +
          ' no ofrece protección contra la discriminación basada en la “orientación sexual”, la “identidad de género”, la “expresión de género” o las “características sexuales” en materia de vivienda.'
      },
      varies: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' do not offer nationwide protection against discrimination based on “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics” in housing. However, some level of protection can be found at the sub-national level.',
        es:
          'En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente en ' +
          options.jur.name +
          ' no ofrece, a nivel nacional, protección contra la discriminación basada en la “orientación sexual”, la “identidad de género”, la “expresión de género” o las “características sexuales” en materia de vivienda. Sin embargo, cierto nivel de protección existe a nivel subnacional.'
      }
    },
    'A1-12': {
      no: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' neither aggravate penalties for crimes committed on the basis of “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics” nor do they explicitly consider such crimes as “hate crimes”. ',
        es:
          'En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente en ' +
          options.jur.name +
          ' no agrava las penas a los delitos cometidos por motivos de “orientación sexual”, la “identidad de género”, la “expresión de género” o las “características sexuales” ni tampoco considera de manera explícita a estos delitos como “delitos de odio”.'
      },
      varies: {
        en:
          'To the best of ILGA World’s knowledge, laws in force at the national level in ' +
          options.jur.name +
          ' neither aggravate penalties for crimes committed on the basis of “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics” nor do they explicitly consider such crimes as “hate crimes”. However, such provisions can be found at the sub-national level.',
        es:
          'En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente en ' +
          options.jur.name +
          ' no agrava las penas impuestas a los delitos cometidos por motivos de “orientación sexual”, “identidad de género”, “expresión de género” o “características sexuales” ni tampoco considera de manera explícita a estos delitos como “delitos de odio”. Sin embargo, este tipo de disposiciones existe a nivel subnacional.'
      }
    },
    'A1-13': {
      no: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' do not prohibit incitement to hatred, violence or discrimination on the basis of “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics”. ',
        es:
          'En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente en ' +
          options.jur.name +
          ' no prohibe la incitación al odio, la discriminación o la violencia por motivos de “orientación sexual”, “identidad de género”, “expresión de género” o “características sexuales”.'
      },
      varies: {
        en:
          'To the best of ILGA World’s knowledge, laws in force at the national level in ' +
          options.jur.name +
          ' do not prohibit incitement to hatred, violence or discrimination on the basis of “sexual orientation”, “gender identity”, “gender expression” or “sex characteristics”. However, such provisions can be found at the sub-national level.',
        es:
          'En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente en ' +
          options.jur.name +
          ' no prohibe la incitación al odio, la discriminación o la violencia por motivos de “orientación sexual”, “identidad de género”, “expresión de género” o “características sexuales”. Sin embargo, este tipo de disposiciones existe a nivel subnacional.'
      }
    },
    'A1-14': {
      no: {
        en: (
          <>
            <p>
              To the best of ILGA World’s knowledge, laws in force in{' '}
              {options.jur.name} do not regulate or restrict sexual orientation,
              gender identity or gender expression change efforts (SOGIECE),
              regularly known as “conversion therapies”.
            </p>
            <p>
              For more information on this type of pseudoscientific practices,
              read ILGA World’s thematic report “<a
                href="https://ilga.org/Conversion-therapy-report-ILGA-World-Curbing-Deception"
                target="_blank"
                rel="noreferrer"
              >
                Curbing deception
              </a>” (2020).
            </p>
          </>
        ),

        es: (
          <>
            <p>
              En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente
              en {options.jur.name} no regula ni restringe los esfuerzos de
              cambio de orientación sexual, identidad de género o expresión de
              género (ECOSIEG), comúnmente conocidos como “terapias de
              conversión”.
            </p>
            <p>
              Para mayor información sobre este tipo de prácticas
              pseudocientíficas puede leer el informe temático de ILGA Mundo “<a
                href="https://ilga.org/Conversion-therapy-report-ILGA-World-Curbing-Deception"
                target="_blank"
                rel="noreferrer"
              >
                Poniéndole límites al engaño
              </a>” (2020).
            </p>
          </>
        )
      },
      varies: {
        en: (
          <>
            <p>
              To the best of ILGA World’s knowledge, laws in force at the
              national level in {options.jur.name} do not regulate or restrict
              sexual orientation, gender identity or gender expression change
              efforts (SOGIECE), regularly known as “conversion therapies”.
              However, such provisions can be found at the sub-national level.
            </p>
            <p>
              For more information on this type of pseudoscientific practices,
              read ILGA World’s thematic report “<a
                href="https://ilga.org/Conversion-therapy-report-ILGA-World-Curbing-Deception"
                target="_blank"
                rel="noreferrer"
              >
                Curbing deception
              </a>” (2020).
            </p>
          </>
        ),
        es: (
          <>
            <p>
              En lo que ILGA Mundo ha podido recabar, el marco jurídico vigente
              a nivel nacional en {options.jur.name}
              no regula ni restringe los esfuerzos de cambio de orientación
              sexual, identidad de género o expresión de género (ECOSIEG),
              comúnmente conocidos como “terapias de conversión”. Sin embargo,
              este tipo de disposiciones existe a nivel subnacional.
            </p>
            <p>
              Para mayor información sobre este tipo de prácticas
              pseudocientíficas puede leer el informe temático de ILGA Mundo “<a
                href="https://ilga.org/Conversion-therapy-report-ILGA-World-Curbing-Deception"
                target="_blank"
                rel="noreferrer"
              >
                Poniéndole límites al engaño
              </a>” (2020).
            </p>
          </>
        )
      }
    },
    'A1-15': {
      no: {
        en:
          'To the best of ILGA World’s knowledge, civil unions or marriage are not legally available for same-sex couples in ' +
          options.jur.name +
          '.',
        es:
          'En lo que ILGA Mundo ha podido recabar, las parejas del mismo sexo no pueden unirse civilmente ni contraer matrimonio en ' +
          options.jur.name +
          '.'
      },
      varies: {
        en:
          'To the best of ILGA World’s knowledge, civil unions or marriage are not legally available for same-sex couples at the national level in ' +
          options.jur.name +
          '. However, legal provisions offering some level of protection are available at the subnational level.',
        es:
          'En lo que ILGA Mundo ha podido recabar, las parejas del mismo sexo no pueden unirse civilmente ni contraer matrimonio en ' +
          options.jur.name +
          '. Sin embargo, existen disposiciones legales que ofrecen cierto nivel de protección a nivel subnacional.'
      }
    },
    'A1-16': {
      no: {
        en:
          'To the best of ILGA World’s knowledge, neither joint adoption nor second parent adoption are legally available for same-sex couples in ' +
          options.jur.name +
          '.',
        es:
          'En lo que ILGA Mundo ha podido recabar, en ' +
          options.jur.name +
          ' las parejas del mismo sexo no pueden legalmente adoptar niñes, ni de manera conjunta ni a le hije de su cónyuge. '
      },
      varies: {
        en:
          'To the best of ILGA World’s knowledge, neither joint adoption nor second parent adoption are legally available for same-sex couples in ' +
          options.jur.name +
          '. However, legal provisions offering some level of protection are available at the subnational level.',
        es:
          'En lo que ILGA Mundo ha podido recabar, en ' +
          options.jur.name +
          ' las parejas del mismo sexo no pueden legalmente adoptar niñes, ni de manera conjunta ni a le hije de su cónyuge. Sin embargo, existen disposiciones legales que ofrecen cierto nivel de protección a nivel subnacional.'
      }
    },
    'A1-17': {
      no: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' do not restrict medically unnecessary interventions aimed to modify the sex characteristics of intersex minors without their free, prior and full informed consent.',
        es:
          'En lo que ILGA Mundo ha podido recabar, las leyes vigentes en ' +
          options.jur.name +
          ' no restringen las intervenciones médicamente innecesarias destinadas a modificar las características sexuales de niñes intersex sin su consentimiento libre, previo y plenamente informado.'
      },
      varies: {
        en:
          'To the best of ILGA World’s knowledge, laws in force at the national level in ' +
          options.jur.name +
          ' do not restrict medically unnecessary interventions aimed to modify the sex characteristics of intersex minors without their free, prior and full informed consent. However, legal provisions offering some level of protection are available at the subnational level.',
        es:
          'En lo que ILGA Mundo ha podido recabar, las leyes vigentes en ' +
          options.jur.name +
          ' no restringen las intervenciones médicamente innecesarias destinadas a modificar las características sexuales de niñes intersex sin su consentimiento libre, previo y plenamente informado. Sin embargo, existen disposiciones legales que ofrecen cierto nivel de protección a nivel subnacional.'
      }
    },
    'A1-18-A': {
      nodata: {
        en:
          'ILGA World could not verify if name change is legally possible in ' +
          options.jur.name +
          '. We are currently working to gather this data. ',
        es:
          'ILGA World no ha podido verificar si el cambio de nombre es posible en ' +
          options.jur.name +
          '. Estamos trabajando para recopilar estos datos.'
      },
      notpossible: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' do not allow for changes in the name of a person in their identification documents.',
        es:
          'En lo que ILGA Mundo ha podido recabar, las leyes vigentes en ' +
          options.jur.name +
          ' no permiten el cambio de nombre en los documentos de identidad.'
      },
      varies: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' do not allow for changes in the name of a person in their identification documents. However, legal provisions offering this possibility are available at the subnational level.',
        es:
          'En lo que ILGA Mundo ha podido recabar, las leyes vigentes a nivel nacional en ' +
          options.jur.name +
          ' no permiten el cambio de nombre en los documentos de identidad. Sin embargo, existen disposiciones legales que ofrecen dicha posibilidad a nivel subnacional.'
      }
    },
    'A1-18-B': {
      nodata: {
        en:
          'ILGA World could not verify if gender marker change is legally possible in ' +
          options.jur.name +
          '. We are currently working to gather this data. ',
        es:
          'ILGA World no ha podido verificar si el cambio de marcador de género es posible en ' +
          options.jur.name +
          '. Estamos trabajando para recopilar estos datos.'
      },
      notpossible: {
        en:
          'To the best of ILGA World’s knowledge, laws in force in ' +
          options.jur.name +
          ' do not allow for gender marker change in identification documents.',
        es:
          'En lo que ILGA Mundo ha podido recabar, las leyes vigentes en ' +
          options.jur.name +
          ' no permiten el cambio de marcador de género en los documentos de identidad.'
      },
      varies: {
        en:
          'To the best of ILGA World’s knowledge, laws in force at the national level in ' +
          options.jur.name +
          ' do not allow for gender marker change in identification documents. However, legal provisions offering this possibility are available at the subnational level.',
        es:
          'En lo que ILGA Mundo ha podido recabar, las leyes vigentes a nivel nacional en ' +
          options.jur.name +
          ' no permiten el cambio de marcador de género en los documentos de identidad. Sin embargo, existen disposiciones legales que ofrecen dicha posibilidad a nivel subnacional.'
      }
    }
  }
}
